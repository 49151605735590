import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { UserService } from '../../servicies/user.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { UserRequestModel } from 'src/app/shared/models/request/user-request-model';
import { RoleResponseModel } from 'src/app/shared/models/response/role-response-model';
import { UserBranchResponseModel } from 'src/app/shared/models/response/user-branch-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserRoleResponseModel } from 'src/app/shared/models/response/user-role-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { ModuleFeatureResponseModel } from 'src/app/shared/models/response/settings/settings-permission-response-model';
import { UserPermissionResponseModel } from 'src/app/shared/models/response/user-permission-response-model';
import { UserPermissions } from 'src/app/shared/enums';

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.sass']
})
export class UserManageComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  title: string;
  role: { label: string, value: string }[];
  branch: { label: string, value: string }[];
  permission: { label: string, value: string }[];
  userResponseModel: UserResponseModel;
  userRequestModel: UserRequestModel;
  userBranchesRequest: UserBranchResponseModel[];
  userPermissionsRequest: UserPermissionResponseModel[];
  userId: string;
  isEditMode: boolean;
  isActive: boolean;
  roles: RoleResponseModel[];
  selectedRole: RoleResponseModel;
  //selectedRole: { label: string, value: string };
  selectedBranch: { label: string, value: string }[];
  selectedPermission: { label: string, value: string }[];
  loggedInUser: number;
  searchRequestModel: SearchRequestModel;
  branchArray: BranchResponseModel[];
  clientId: number;
  isVoiceCallRecordingDisplay: boolean = true;
  decrypteduserId: number;

  formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    role: 'role',
    branch: 'branch',
    email: 'email',
    phoneNumber: 'phoneNumber',

    brightreeInternalUserId: 'brightreeInternalUserId',
    permission: 'permission'



  };

  validationMessages = {
    firstName: {
      required: 'First name is required',
      validCharacters: 'Enter valid first name'
    },
    lastName: {
      required: 'Last name is required',
      validCharacters: 'Enter valid last name'
    },
    role: {
      required: 'Role is required',
    },
    branch: {
      multiSelectRequired: 'Branch is required',
    },
    phoneNumber: {
      required: 'Phone number is required',
      // invalid: 'Enter valid phone number'
    },
    brightreeInternalUserId: {
      required: 'Brightree Internal User Id is required',
      validNumber: 'Enter valid brightree Internal User Id',
    },

    permission: {
      multiSelectRequired: 'Permission is required',
    },
  };

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    messageService: MessageService,
    private webStorageService: WebStorageService) {
    super(messageService);

    var clientdetail = this.webStorageService.getClientConfig();
    this.clientId = Number(clientdetail.companyId);

    var channelArray: Array<Number> = [9,12,7,13,14,15,18,20,21,1008]; //Allow to use Nums and Preprod clients

    this.isVoiceCallRecordingDisplay = channelArray.includes(this.clientId) ? true : false;

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
  }
  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    await this.getRoles();
    await this.getBranches();

    await this.getPermissions();




    this.isEditMode = false;
    this.isActive = true;
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.userId = params['id'];
        this.getUser(params['id']);
      });
    this.title = this.isEditMode ? 'Edit User' : 'Add User';


    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.loggedInUser = currentUserToken.userId;
    }
    else {
      this.loggedInUser = 0;
    }


  }

  onCancel() {
    // this.buildForm;
    // // add pagedRequestModel as state in navigation while redirecting to maintain state
    // this.router.navigate(['/admin/users'], { state: { searchRequestModel: this.searchRequestModel } });

    this.userResponseModel = null;
    //this.form.reset();
    this.router.navigate(['/admin/users'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack() {
    this.router.navigate(['/admin/users'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onSubmit() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const userRequestModel = this.getValuesFromForm();

    if (userRequestModel.userBranch != null) {
      const branch = [];
      this.webStorageService.deleteUserBrances();
      userRequestModel.userBranch.forEach((result) => {
        const selectedBranch = this.branchArray.find(x => x.crmBranchId == result.crmBranchId);
        branch.push(selectedBranch);
      });
      this.webStorageService.storeUserBrances(branch);
    }


    userRequestModel.appUserInfoId = this.decrypteduserId ? this.decrypteduserId : 0;
    userRequestModel.enAppUserInfoId = this.userResponseModel?.enAppUserInfoId ?? "";
    const manageUser = this.isEditMode ? this.userService.UpdateUser(userRequestModel) : this.userService.AddUser(userRequestModel);

    this.loading = true;
    manageUser.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess && !result.message) {
          this.showSuccessMessage(this.isEditMode ? 'User is updated successfully' : 'User is created successfully');
          this.onCancel();
        }
        else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  OnRoleChange(role) {
    this.selectedRole = role.value;
  }

  onBranchChange(branch) {
    // this.selectedBranch = branch.value;
  }

  onPermissionChange(event) {
    let itemValue = event.itemValue;
    let values: any[] = this.form.get(this.formFields.permission).value;
    let tempselectedPermission = this.selectedPermission;
    this.selectedPermission = [];

    if (itemValue.label.toLowerCase() == UserPermissions.AllowToListenOtherUsersRecordings && tempselectedPermission.find(x => x.label.toLowerCase() == UserPermissions.AccessToRecordingLogs) == undefined) {
      const tempuserbranch = { label: itemValue.label, value: itemValue.value };
      this.selectedPermission.push(tempuserbranch);

      var otherValue = this.permission.find(x => x.label.toLowerCase() == UserPermissions.AccessToRecordingLogs);
      if (otherValue) {
        const tempuserbranch1 = { label: otherValue.label, value: otherValue.value };
        this.selectedPermission.push(tempuserbranch1);
        this.form.get(this.formFields.permission).setValue(this.selectedPermission);
      }
    }
    else if (itemValue.label.toLowerCase() == UserPermissions.AccessToRecordingLogs && tempselectedPermission.find(x => x.label.toLowerCase() == UserPermissions.AllowToListenOtherUsersRecordings) != undefined) {
      this.showWarningMessage("You cannot opt ​​out of this permission because you have chosen to access othe user's call recording's log.");
      const tempuserbranch = { label: itemValue.label, value: itemValue.value };
      this.selectedPermission.push(tempuserbranch);
      var otherValue = this.permission.find(x => x.label.toLowerCase() == UserPermissions.AllowToListenOtherUsersRecordings);
      if (otherValue) {
        const tempuserbranch1 = { label: otherValue.label, value: otherValue.value };
        this.selectedPermission.push(tempuserbranch1);
        this.form.get(this.formFields.permission).setValue(this.selectedPermission);
      }
    }

    tempselectedPermission.forEach(element => {
      if (element.label.toLowerCase() != UserPermissions.AccessToRecordingLogs && element.label.toLowerCase() != UserPermissions.AllowToListenOtherUsersRecordings) {
        this.selectedPermission.push(element);
      }
    });
  }


  private getUser(userId: string) {
    if (!userId) {
      return;
    }

    this.isEditMode = true;
    this.loading = true;

    this.userService.GetUserById(userId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserResponseModel>) => {
        this.processResult<UserResponseModel>(result, () => {
          this.userResponseModel = result.responseModel;
          this.decrypteduserId = this.userResponseModel.appUserInfoId;
          this.setValuesToForm();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getRoles() {

    this.progress.next(true);
    await this.userService.getAllRolesUsers()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.roles = result.responseModel;

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getBranches() {
    this.progress.next(true);
    await this.userService.getAllBranches()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.branch = [];
        this.branchArray = result.responseModel;
        this.branchArray.forEach((value: BranchResponseModel) => {
          this.branch.push({ label: value.crmBranchName, value: value.crmBranchId.toString() });
        });
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getPermissions() {
    this.progress.next(true);
    await this.userService.getAllPermissions()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.permission = [];
        const permissionArray = result.responseModel;
        permissionArray.forEach((value: ModuleFeatureResponseModel) => {
          if (!this.isVoiceCallRecordingDisplay &&
            (value.appModuleFeatureName.toLowerCase() == UserPermissions.AllowToListenOtherUsersRecordings || value.appModuleFeatureName.toLowerCase() == UserPermissions.AccessToRecordingLogs)) {
            return;
          }
          this.permission.push({ label: value.appModuleFeatureName, value: value.appModuleFeatureId.toString() });
        });
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getValuesFromForm(): UserRequestModel {

    const form = this.form;
    const userRequestModel = new UserRequestModel();
    let userBranch = new UserBranchResponseModel();
    let userPermission = new UserPermissionResponseModel();
    // const userRole = new UserRoleResponseModel();

    // this.selectedRole = form.get(this.formFields.role).value;
    // this.selectedBranch = form.get(this.formFields.branch).value;

    userRequestModel.appUserFname = form.get(this.formFields.firstName).value;
    userRequestModel.appUserLname = form.get(this.formFields.lastName).value;
    userRequestModel.appRoleId = form.get(this.formFields.role).value.appRoleId;
    userRequestModel.appRoleName = form.get(this.formFields.role).value.appRoleName;
    userRequestModel.appUserEmail = form.get(this.formFields.email).value;
    if (form.get(this.formFields.brightreeInternalUserId).value)
      userRequestModel.crmBrightreeUserId = Number(form.get(this.formFields.brightreeInternalUserId).value);
    this.formFields.brightreeInternalUserId
    userRequestModel.isActive = this.isActive;
    userRequestModel.modifiedByUserInfoId = this.loggedInUser;
    // userRequestModel.appUserPhoneNumber = form.get(this.formFields.phoneNumber).value;
    var tempPhone = form.get(this.formFields.phoneNumber).value;
    if (tempPhone.indexOf('-') > -1) {
      userRequestModel.appUserPhoneNumber = tempPhone.split('-').join('');
    } else {
      userRequestModel.appUserPhoneNumber = tempPhone;
    }

    // // this was used when dropdown was used in html
    // userRequestModel.crmBranchId = Number(this.selectedBranch.value);
    // userRequestModel.crmBranchName = this.selectedBranch.label;

    //if (this.selectedBranch !== undefined) {
    //  this.userBranchesRequest = [];
    //  this.selectedBranch.forEach((result) => {
    //    userBranch = new UserBranchResponseModel();
    //    userBranch.appUserId = this.userId ? parseInt(this.userId, 10) : 0;
    //    userBranch.crmBranchId = parseInt(result.value, 10);
    //    this.userBranchesRequest.push(userBranch);
    //  });
    //  userRequestModel.userBranch = this.userBranchesRequest;
    //}

    if (form.get(this.formFields.branch).value) {

      let values: any[] = form.get(this.formFields.branch).value;
      this.userBranchesRequest = [];
      values.forEach((result) => {

        userBranch = new UserBranchResponseModel();
        userBranch.appUserId = this.userId ? parseInt(this.userId, 10) : 0;
        userBranch.crmBranchId = parseInt(result.value, 10);
        this.userBranchesRequest.push(userBranch);
      });
      userRequestModel.userBranch = this.userBranchesRequest;
    }

    if (form.get(this.formFields.permission).value) {

      let values: any[] = form.get(this.formFields.permission).value;
      this.userPermissionsRequest = [];
      values.forEach((result) => {

        userPermission = new UserPermissionResponseModel();
        userPermission.appUserId = this.userId ? parseInt(this.userId, 10) : 0;
        //userPermission.appUserModuleFeatureGroupId = parseInt(result.userPermission.appModuleFeatureGroupId, 10);
        userPermission.appUserModuleFeatureId = parseInt(result.value, 10);
        this.userPermissionsRequest.push(userPermission);
      });
      userRequestModel.userPermission = this.userPermissionsRequest;
    }

    // // this should work but throws error while going to angular service
    // userRole.appUserInfoId = Number(this.userId);
    // userRole.appRoleId = Number(this.selectedRole.value);
    // userRequestModel.userRole = userRole;


    // // this works
    // if (this.selectedRole) {
    //   userRequestModel.userRole = this.selectedRole.label.toString();
    // }
    // if (this.selectedBranch) {
    //   userRequestModel.userBranch = this.selectedBranch.label.toString();
    // }

    // userRequestModel.userRole.role.appRoleName = this.selectedRole.label.toString();
    // userRequestModel.userRole.role.appRoleId = Number(this.selectedRole.value);
    // userRequestModel.userBranch.crmBranch.crmBranchName = this.selectedBranch.label.toString();
    // userRequestModel.userBranch.appUserBranchId = Number(this.selectedBranch.value);

    // userRequestModel.UserRole = form.get(this.formFields.role).value;
    // userRequestModel.UserBranch = form.get(this.formFields.branch).value;
    // userRequestModel.UserRole.Role.AppRoleName = form.get(this.formFields.role).value;
    // userRequestModel.UserBranch.Branch.CrmBranchName = form.get(this.formFields.branch).value;

    return userRequestModel;
  }

  private setValuesToForm() {
    //this.form.reset();

    if (!this.userResponseModel) {
      return;
    }

    const form = this.form;

    this.isActive = this.userResponseModel.isActive;
    form.get(this.formFields.firstName).setValue(this.userResponseModel.appUserFname);
    form.get(this.formFields.lastName).setValue(this.userResponseModel.appUserLname);
    form.get(this.formFields.email).setValue(this.userResponseModel.appUserEmail);
    form.get(this.formFields.phoneNumber).setValue(this.userResponseModel.appUserPhoneNumber);
    form.get(this.formFields.brightreeInternalUserId).setValue(this.userResponseModel.crmBrightreeUserId);
    var role = this.roles.find(x => x.appRoleId == this.userResponseModel.userRole[0].role.appRoleId);
    form.get(this.formFields.role).setValue(role);

    // tslint:disable-next-line: max-line-length
    //this.selectedRole = { label: this.userResponseModel.userRole[0].role.appRoleName, value: this.userResponseModel.userRole[0].role.appRoleId };
    // tslint:disable-next-line: max-line-length
    // this.selectedBranch[0] = { label: this.userResponseModel.userBranch[0].crmBranch.crmBranchName, value: this.userResponseModel.userBranch[0].crmBranch.crmBranchId};

    if (this.userResponseModel.userBranch.length === 1) {
      this.selectedBranch = [];
      this.selectedBranch[0] = {
        label: this.userResponseModel.userBranch[0].crmBranch.crmBranchName.replace('Sandwich', '').trim(),
        value: this.userResponseModel.userBranch[0].crmBranch.crmBranchId.toString()
      };
    } else if (this.userResponseModel.userBranch.length >= 1) {
      this.selectedBranch = [];
      this.userResponseModel.userBranch.forEach((result) => {

        const tempuserbranch = { label: result.crmBranch.crmBranchName.replace('Sandwich', '').trim(), value: result.crmBranchId.toString() };
        this.selectedBranch.push(tempuserbranch);
      });
    }
    form.get(this.formFields.branch).setValue(this.selectedBranch);
    // form.get(this.formFields.role).setValue(this.userResponseModel.userRole.Role.appRoleName);
    // form.get(this.formFields.branch).setValue(this.userResponseModel.userBranch.branch.crmBranchName);

    if (this.userResponseModel.userPermission.length === 1) {
      this.selectedPermission = [];
      this.selectedPermission[0] = {
        label: this.userResponseModel.userPermission[0].userPermission.appModuleFeatureName.trim(),
        value: this.userResponseModel.userPermission[0].userPermission.appModuleFeatureId.toString()
      };
    } else if (this.userResponseModel.userPermission.length >= 1) {
      this.selectedPermission = [];
      this.userResponseModel.userPermission.forEach((result) => {
        if (!this.isVoiceCallRecordingDisplay &&
          (result.userPermission.appModuleFeatureName.toLowerCase() == UserPermissions.AllowToListenOtherUsersRecordings || result.userPermission.appModuleFeatureName.toLowerCase() == UserPermissions.AccessToRecordingLogs)) {
          return;
        }

        const tempuserbranch = { label: result.userPermission.appModuleFeatureName.trim(), value: result.appUserModuleFeatureId.toString() };
        this.selectedPermission.push(tempuserbranch);
      });
    }
    form.get(this.formFields.permission).setValue(this.selectedPermission);
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.firstName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.lastName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.role, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.branch, this.formBuilder.control('', { validators: [CustomValidator.multiSelectRequired()] }));
    // formGroup.setValidators(CustomValidator.multiSelectRequired());

    // tslint:disable-next-line: max-line-length
    //formGroup.addControl(this.formFields.branch, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.multiSelectRequired()], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    // formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.brightreeInternalUserId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.permission, this.formBuilder.control(''));
    this.form = formGroup;
  }

}
