import { SettingsRequestModel } from "./settings-request-model";

export class SettingsPluginRequestModel {
  appPluginId: number;
  appPluginSettingsDisplayValue: string;
  appPluginSettingsKey: string;
  appPluginSettingsValue: string;
  appPluginDescription: string;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}

