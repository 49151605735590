import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
@Component({
  selector: 'app-appointment-confirm-dialog',
  templateUrl: './appointment-confirm-dialog.component.html',
  styleUrls: ['./appointment-confirm-dialog.component.sass']
})
export class AppointmentConfirmDialogComponent implements OnInit {
  appointment: AppointmentInfoReponseModel;
  appDateFormat: DateFormatModel;
  facility: FacilityInfoResponseModel;
  schAppointmentDateStartTime: Date;
  therapistName: string;
  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
  }

  ngOnInit() {
    this.appointment = this.dynamicDialogConfig.data.appointment;
    this.appDateFormat = this.dynamicDialogConfig.data.appDateFormat;
    this.facility = this.dynamicDialogConfig.data.facility;
    this.schAppointmentDateStartTime = this.dynamicDialogConfig.data.schAppointmentDateStartTime;
    this.therapistName = this.dynamicDialogConfig.data.therapistName;
  }
  onYes() {
    this.dynamicDialogRef.close(true);
  }

  onNo() {
    this.dynamicDialogRef.close(false);
  }
}
