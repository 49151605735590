import { Time } from '@angular/common';

export class FacilityCustomAvailabilityResponseModel {
  crmFacilityCustomAvailabilityId: number;
  crmIsRecurring: boolean;
  crmFacilityId: number;
  crmIsWeekly: boolean;
  crmIsDaily: boolean;
  crmIsYearly: boolean;
  crmIsMonthly: boolean;
  crmFacilityCustomDays: string;
  crmRecurringFrequency: number;

  crmFacilityCustomAvailEndDate: Date;


  crmFacilityCustomAvailStartDate: Date;
  crmFacilityCustomAvailEndTime: Date;
  crmFacilityCustomAvailStartTime: Date;
  crmFacilityCustomAvailEndTimeDate: Date;
  crmFacilityCustomAvailStartTimeDate: Date;
  crmFacilityCustomAvailEndTimeString: string;
  crmFacilityCustomAvailStartTimeString: string;
  modifiedByUserInfoId: number;
  isActive: boolean;
  dateTimeLastModified: Date;
  dateTimeCreated: Date;
  isShutdown: boolean;
  isHoliday: boolean;
  crmBreakEndTime: Time;
  crmBreakStartTime: Time;
  crmBreakStartTimeDate: Date;
  crmBreakEndTimeDate: Date;
  crmBreakEndTimeString: string;
  crmBreakStartTimeString: string;
  facilityCustomAvailStartTimeDate: Date;
  facilityCustomAvailEndTimeDate: Date;
  crmFacilityCustomAvailEndDateString: string;
  crmFacilityCustomAvailStartDateString: string;
}
