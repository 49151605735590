<app-patient-header></app-patient-header>
<section class="main-patient-outer">
    <div class="main-patient-outer__left" [ngClass]="activeStepNo==4?'hide-box':''">
        <div class="patient-sidebar">
            <div class="patient-sidebar__appointment-booking">
                <span class="patient-sidebar__appt-booking">Appointment Booking for</span>
                <h2 class="patient-sidebar__appt-patient-name">
                    "{{patientName}}"
                </h2>
            </div>
            <div class="patient-sidebar__clinic-visit">
                <h3 class="patient-sidebar__title">{{appointmentTitle}}</h3>
                <a class="patient-sidebar__back" href="javascript:void(0);" (click)="onBack()" *ngIf="activeStepNo!=4">
                    <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 6.35L7.28671 0.5V3.75C18.0217 3.75 19.2602 10.0407 18.962 13.5C18.5112 11.7547 18.3019 8.95 7.28671 8.95V12.2L1 6.35Z"
                            stroke="#868686" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Back to All Appointment Types
                </a>
            </div>

            <div *ngIf="patientBookingTypeId=='F' || patientBookingTypeId=='T'" class="patient-sidebar__steps-outer" [ngClass]="activeStepNo==2?'first-steps-complete'
                :activeStepNo==3?'first-steps-complete second-steps-complete'
                :activeStepNo==4?'first-steps-complete second-steps-complete third-steps-complete':''">
            
                <ul class="steps">
                    <li class="steps__item" [ngClass]="activeStepNo==1?'active':''">
                        <span class="steps__number">
                            <span>1</span>
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>                                
                        </span>
                        <span class="steps__texts">
                            <small>Step 1</small>
                            {{appointmentSelectLabel}}
                        </span>
                    </li>
                    <li class="steps__item" [ngClass]="activeStepNo==2?'active':''">
                        <span class="steps__number">
                            <span>2</span>
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span class="steps__texts">
                            <small>Step 2</small>
                            Select Date & Time
                        </span>
                    </li>
                    <li class="steps__item" [ngClass]="activeStepNo==3?'active':''">
                        <span class="steps__number">
                            <span>3</span>
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span class="steps__texts">
                            <small>Step 3</small>
                            Appointment Summary
                        </span>
                    </li>
                </ul>

                <div class="process-bg">
                    <div class="process-bar"></div>
                </div>
            </div>

            <div *ngIf="patientBookingTypeId=='R' || patientBookingTypeId=='SG'" class="patient-sidebar__steps-outer remote-steps" [ngClass]="activeStepNo==2?'first-steps-complete'
                :activeStepNo==3?'first-steps-complete second-steps-complete'
                :activeStepNo==4?'first-steps-complete second-steps-complete third-steps-complete':''">
            
                <ul class="steps">
                    <li class="steps__item" [ngClass]="activeStepNo==1?'active':''">
                        <span class="steps__number">
                            <span>1</span>
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span class="steps__texts">
                            <small>Step 1</small>
                            Select Date & Time
                        </span>
                    </li>
                    <li class="steps__item" [ngClass]="activeStepNo==2?'active':''">
                        <span class="steps__number">
                            <span>2</span>
                            <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                        <span class="steps__texts">
                            <small>Step 2</small>
                            Appointment Summary
                        </span>
                    </li>
                </ul>

                <div class="process-bg">
                    <div class="process-bar"></div>
                </div>
            </div>

        </div>
    </div>

    <div class="main-patient-outer__right">
        <!-- Select facility -->
        <div class="select-facility-block" [ngClass]="activeStepNo==1 && patientBookingTypeId == 'F'?'active':'inactive'">
            <app-search-nearby-facility (activeStepNoEmit)="GetChildData($event)" (isFacilityOrTherapistSelectionChangedEmit)="GetFacilityOrTherapistSlectionChangeRequest($event)"></app-search-nearby-facility>
        </div>

        <!-- Select Therapist -->
        <div class="select-therapist-block" [ngClass]="activeStepNo==1 && patientBookingTypeId == 'T'?'active':'inactive'">
            <app-choose-therapist (activeStepNoEmit)="GetChildData($event)" (isFacilityOrTherapistSelectionChangedEmit)="GetFacilityOrTherapistSlectionChangeRequest($event)"></app-choose-therapist>
        </div>

        <!-- Select Date & time -->
        <div class="select-datetime-block" [ngClass]="(activeStepNo==1 && (patientBookingTypeId == 'R' || patientBookingTypeId == 'SG')) || 
                                                             (activeStepNo==2 && (patientBookingTypeId == 'F' || patientBookingTypeId == 'T'))?'active':'inactive'">
            <app-confirm-date-time-slot (activeStepNoEmit)="GetChildData($event)" (patientBookAppointmentRequestEmit)="GetAppoitmentBookRequest($event)"
            (isFacilityOrTherapistSelectionChangedEmit)="GetFacilityOrTherapistSlectionChangeRequest($event)"
            [isFacilityOrTherapistSelectionChanged]="isFacilityOrTherapistSelectionChanged"></app-confirm-date-time-slot>
        </div>

        <!-- Appointment Summary -->
        <div class="select-appointmetn-summary-block" [ngClass]="(activeStepNo==2 && (patientBookingTypeId == 'R' || patientBookingTypeId == 'SG')) || 
                                                                 (activeStepNo==3 && (patientBookingTypeId == 'F' || patientBookingTypeId == 'T'))?'active':'inactive'"
                                                        *ngIf="(activeStepNo==2 && (patientBookingTypeId == 'R' || patientBookingTypeId == 'SG')) || 
                                                        (activeStepNo==3 && (patientBookingTypeId == 'F' || patientBookingTypeId == 'T'))">
            <app-appointment-summary (activeStepNoEmit)="GetChildData($event)" [patientBookAppointmentRequest]="patientBookAppointmentRequest" ></app-appointment-summary>
        </div>

        <!-- Appointment Booked -->
        <!-- <div class="appointmetn-booked-block" [ngClass]="(activeStepNo==4 || isAppoitmentBooked)?'active':'inactive'" *ngIf="activeStepNo==4">
            <app-appointment-confirmation></app-appointment-confirmation>
        </div> -->

    </div>
</section>
