<div class="login">
  <div class="login__header">
    <div class="login__header__left">

      <div class="login__header__logo ">
        <img src="assets/img/appoint360-logo.png" height="80" width="105" title="Appoint360" alt="Appoint360">
      </div>
      <!-- CLient Logo-->
      <div class="login__header__client-logo">
        <div class="login__header__client-logo__client ">
          <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" title="{{imageTitle}}" height="65">
        </div>
      </div>
    </div>
  </div>
  <div class="login__content">
    <div class="login__content__left">
      <div class="login-img">
        <img src="assets/img/login-img.png" class="login__content__left__img" alt="login img" height="400">
      </div>
    </div>
    <div class="login__content__right">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <h2>{{title}}</h2>
        <p>Enter your email address to reset your password</p>
        <div class="form__group ">
          <input id="login-input" class="form__control" formControlName="email" type="email" er="Email" required
            pInputText>
          <span *ngIf="hasError(formFields.email,'required') || hasError(formFields.email,'whitespace')"
            class="error-msg">{{commonValidationMessages.email.required}}</span>
          <span *ngIf="hasError(formFields.email,'email')"
            class="error-msg">{{commonValidationMessages.email.email}}</span>
        </div>
        <div class="form__group login-btn">
          <button class="btn btn--primary ">Send Email</button>
          <div class="form__group forgot-password">
            <a [routerLink]="['/login']" routerLinkActive="active">Back to Login</a>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="login__footer">
    <div class="login__footer__left mobile-support">
      <h4>Application best view in 1024 + resolutions</h4>
      <p><strong>For Windows OS:</strong> Google Chrome, Mozilla Firefox, Microsoft Edge browsers.</p>
      <p><strong>For Mac OS:</strong> Safari, Google Chrome, Mozilla Firefox browsers.</p>
    </div>
    
    <div class="login__footer__right">
      <span>Powered By<img src="assets/img/appoint360-logo.svg" alt="Appoint360" title="Appoint360" width="20"
          height="13">Appoint360</span>
    </div>
  </div>
</div>