export class SettingsRequestModel {
  schSettingId: number;
  schSettingDisplayValue: string;
  schSettingKey: string;
  schSettingValue: string;
  schSettingDescription: string;
  schSettingIsEnable: Boolean;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}
