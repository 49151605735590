<div class="brightree-outer">

  <div class="status-mapping">
    <div class="add-buttons-for-license">
      <h3>Cancellation Reasons</h3>
      <a href="javascript:void(0);" pTooltip="Add Cancellation Reason" tooltipPosition="left" class="add-plus"
        (click)="CancellationStatusInfoButton()">
        <img src="assets/img/add-plus.svg" width="17" alt="filters">
      </a>
    </div>

    <form class="form"
      [ngClass]="eMedAssistCancellation ? 'eMedAssistBrightree-form-active' : 'eMedAssistBrightree-form-hide'"
      [formGroup]="formCancellation" autocomplete="off">
      <div class="col">

        <div class="form__group">
          <label class="form__label">Cancellation Reason</label>
          <input type="text" formControlName="cancellationReason" class="form__control" value="Ready for Schedule"
            pInputText>
          <div *ngIf="formCancellationSubmitted && fCancellation.cancellationReason.errors" class="invalid-feedback">
            <div *ngIf="fCancellation.cancellationReason.errors.required">
              {{validationMessages.cancellationReason.required}}
            </div>

          </div>
        </div>

        <div class="form__group button-box">
          <div class="btns">
            <button class="btn btn--primary" (click)="onCancellationReasonAdd()">{{cancellationStatusBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onCancellationCancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>

    <div class="table-outer-main branchs-settings">
      <p-table #nsdl class="table-outer" [value]="cancellationReasonsResponseModel" [scrollable]="true"
        responsiveLayout="scroll">

        <ng-template pTemplate="header">
          <tr>
            <th class="table-outer__th">Reason</th>
            <th class="table-outer__th" width="300">Status</th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="80">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-wipStatus>
          <!-- *ngFor="let wipStatus of cancellationReasonsResponseModel" -->
          <tr>
            <td class="table-outer__td">{{wipStatus.salesOrderVoidReason}}</td>
            <td class="table-outer__td">{{wipStatus.isActive? 'Active': 'Inactive'}}</td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)" (click)="op.toggle($event)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>

              <p-overlayPanel class="table-action-popup" appendTo="body" #op>
                <ul class="quick-link-menu">
                  <li>
                    <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                      (click)="onCancellationStatusEdit(wipStatus); op.hide()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit</a>
                  </li>
                  <li> <a class="delete-row" href="javascript:void(0)" (click)="onCancellationDelete(wipStatus); op.hide()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete</a></li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="status-mapping classification-settings">
    <div class="add-buttons-for-license">

      <div class="add-buttons-for-license">
        <h3> Void Reasons</h3>

        <a href="javascript:void(0);" pTooltip="Add Void Reasons" tooltipPosition="left" class="add-plus"
          (click)="ClassificationInfoButton()">
          <img src="assets/img/add-plus.svg" width="17" alt="filters">
        </a>
      </div>
    </div>

    <form class="form"
      [ngClass]="eMedAssistVoid ? 'classificationSettings-form-active' : 'classificationSettings-form-hide'"
      [formGroup]="formVoid">
      <div class="col">

        <div class="form__group">
          <label class="form__label">Reason</label>
          <input type="text" formControlName="voidReason" class="form__control" pInputText>
          <div *ngIf="formVoidReasonSubmitted && fVoid.voidReason.errors" class="invalid-feedback">
            <div *ngIf="fVoid.voidReason.errors.required || fVoid.voidReason.errors">
              {{validationMessages.voidReason.required}}
            </div>

          </div>
        </div>

        <div class="form__group button-box">
          <div class="btns">
            <button class="btn btn--primary" (click)="onVoidAdd()">{{voidBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onVoidCancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>

    <div class="table-outer-main branchs-settings">
      <p-table #nsdl class="table-outer" [value]="voidReasonsResponseModel" [scrollable]="true"
        responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th class="table-outer__th">Reason</th>
            <th class="table-outer__th" width="300">Status</th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="80">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-classification>
          <!-- *ngFor="let classification of voidReasonsResponseModel" -->
          <tr>
            <td class="table-outer__td">{{classification.salesOrderVoidReason}}</td>
            <td class="table-outer__td">{{classification.isActive? 'Active': 'Inactive'}}</td>

            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)" (click)="op1.toggle($event)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>

              <p-overlayPanel class="table-action-popup" appendTo="body" #op1>
                <ul class="quick-link-menu">
                  <li>
                    <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                      (click)="onVoidEdit(classification); op1.hide()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit</a>
                  </li>
                  <li> <a class="delete-row" href="javascript:void(0)" (click)="onVoidDelete(classification); op1.hide()">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete</a></li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>
