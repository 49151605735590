export class KPIAddReportRequestModel {
    KpiuserReportPrefrenceID: number;
    ReportName: string;
    UserInfoID: number;
    KPIReportId: number;
    LoggedInUserId: number;
    ReportSortOrder: number;
    OprationType: string;
    UserReportFilterPrefrencesList: UserReportFilterPrefrenceRequestModel[];
    SelectedXAxisList: KPIReportVisualizationModel[];
    ColumnType: string;
    CombinedReportAverageBy: number;
}

export class UserReportFilterPrefrenceRequestModel {
    KpiUserReportFilterPrefrenceId: number;
    KpiUserReportPrefrenceId: number;
    FilterName: string;
    FilterTimePeriod: string;
    FilterValues: string;
    modifiedByUserInfoId: number;
    DateTimeCreated:Date;
    DateTimeLastModified:Date;
}

export class KPIReportSortOrderRequestModel {
    kpiuserReportPreferenceId: number;
    kpireportSortOrder: number;
}

export class KPIReportVisualizationModel {
    kpiUserReportPrefrenceId: number;
    kpiXAxisFieldName: string;
    kpiXAxisFieldOrder: number;
}
