<div class="content-area" id="capture">
  <div class="dashboard-outer">
    <div class="deshboard-top">
      <div class="title-block">
        <h1>Dashboard <span>({{statusViewOfSlectedDay}})</span></h1>
        <div class="deshboard-top__switch-button" data-html2canvas-ignore="true">
          <!-- <span class="switchBtn">
            <p-inputSwitch [(ngModel)]="checked" (onChange)="showTodaysStatus()"></p-inputSwitch>
            Today’s Status View
          </span> -->
        </div>
      </div>
    </div>

    <div class="dashboard">
      <div class="dashboard__filters"  data-html2canvas-ignore="true">
        <div class="dashboard__filters-left">
          Filter by:
          <!-- <p-calendar class="dashboard-calendar" placeholder="MM/DD/YYYY" [(ngModel)]="TodayDates" dateFormat="mm/dd/yy" [showIcon]="true"></p-calendar> -->
          <p-calendar class="dashboard-calendar" [(ngModel)]="viewDate" appendTo="body"
            dateFormat="{{appDateFormat.primeFormat}}" [numberOfMonths]="1" [showIcon]="true"
            [maxDate]="maxDateToDisplay" (ngModelChange)="OnDateChange()">
          </p-calendar>
          <!-- <span class="weekday-span">( {{viewDate | date:'EEEE'}} )</span> -->
        </div>
        <div class="filter-box">
          <button class="btn btn--secondary" style="    margin: 0 1.5rem 0 0; height: 35px; line-height: 35px;" (click)="onExport()">Export</button>
          <span class="loading">(As of {{ TodayDates }})</span>
          <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074"
                stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square" />
              <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3"
                stroke-miterlimit="10" stroke-linecap="square" />
              <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3"
                stroke-miterlimit="10" stroke-linecap="square" />
            </svg>
            <span class="loading">Refresh</span>
          </a>
        </div>
      </div>

      <div class="today-dashboard-chart">
        <div class="today-dashboard-chart__box">
          <div class="today-dashboard-chart__new-sales-orders">
            <div class="today-dashboard-chart__new-sales-orders-left">
              <div class="booked-appointments">
                <h2><a href="javascript:void(0);" (click)="bookedAppointmentClick()">{{bookedAppointmentsCnt}}</a></h2>
                <span>Booked Appointments</span>
              </div>
              <div class="new-sales-orders">
                <div class="dash-blocks chart-box">
                  <div class="dash-blocks__top">
                    <div class="count-chart" id="DMEPatientNewSalesOrder"></div>
                  </div>
                  <div class="dash-blocks__bottom">New Sales Orders</div>
                </div>
                
              </div>


              <!-- <div id="newSalesOrders" class="new-sales-orders-chart"></div>
              <div id="bookedAppointments" class="new-sales-orders-chart"></div> -->
            </div>
            <div class="today-dashboard-chart__new-sales-orders-right">
              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top">
                  <div class="count-chart" id="DMEPatientScheduled"></div>
                </div>
                <div class="dash-blocks__bottom">Scheduled</div>
              </div>
              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top ">
                  <div class="count-chart" id="DMEPatientRescheduled"></div>
                </div>
                <div class="dash-blocks__bottom">Rescheduled</div>
              </div>

              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top ">
                  <div class="count-chart" id="DMEPatientCanceled"></div>
                </div>
                <div class="dash-blocks__bottom">Canceled</div>
              </div>

              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top ">
                  <div class="count-chart" id="DMEPatientNoShow"></div>
                </div>
                <div class="dash-blocks__bottom">No Show</div>
              </div>

              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top ">
                  <div class="count-chart" id="DMEPatientComplete"></div>
                </div>
                <div class="dash-blocks__bottom">Complete</div>
              </div>

              <div class="dash-blocks chart-box">
                <div class="dash-blocks__top ">
                  <div class="count-chart" id="DMEPatientVoid"></div>
                </div>
                <div class="dash-blocks__bottom">Void vs Void Review</div>
              </div>

              <!-- <div class="dash-blocks">
                <div class="dash-blocks__top"><a href="javascript:void(0);" (click)="noShowAppointmentClick()">{{noShowCnt}}</a></div>
                <div class="dash-blocks__bottom">No Show</div>
              </div> -->
              <!--               <div class="dash-blocks">
                              <div class="dash-blocks__top"><a href="javascript:void(0);" (click)="completeAppointmentClick()">{{completedCnt}}</a></div>
                              <div class="dash-blocks__bottom">Completed</div>
                            </div>
                            <div class="dash-blocks">
                              <div class="dash-blocks__top"><a href="javascript:void(0);" (click)="voidAppointmentClick()">{{voidCnt}}</a></div>
                              <div class="dash-blocks__bottom">Void</div>
                            </div> -->
            </div>
          </div>

          <div class="today-dashboard-chart__today-appointment">
            <!-- <div class="booked-appointments">
              <h2>{{todaysScheduledApptCnt}}</h2>
              <span>Today's Scheduled Appointment</span>
            </div> -->
            <div class="today-dashboard-chart__today-appointment-title">
              <h2><a href="javascript:void(0);" (click)="todayAppointmentClick()">{{todaysScheduledApptCnt}}</a></h2>
              <span>{{todaysDate}} Scheduled Appointment</span>
            </div>
            <div class="today-dashboard-chart__today-appointment-status">The Status of
              <strong>{{todaysScheduledApptCnt}}</strong> Scheduled Appointments</div>
            
              <!-- Today's Appointments Booked -->
            <div id="TodayAppointmentBooked" class="today-chart-block"></div>
          </div>

        </div>

      </div>

    </div>
  </div>
</div>