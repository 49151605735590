export class PatientInfoRequestModel {
  patientid: string;
  email: string;
  phone: string;
  mobilephone: string;
  btNoteID: number;
  isProvisional: boolean;
  isUpdateByPatient: boolean;
  branchId: number;
  crmSalesorderId: number;
  crmSalesorderAppId: string;
  schAppointmentId: number;
}
