import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { Subject } from 'rxjs';
import { SettingsService } from '../../../servicies/settings.service';
import { SettingsBrightreeResponseModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { WipStatus } from 'src/app/shared/models/common/wip-status';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Dropdown } from 'primeng/dropdown';
import { InvetoryLocationResponseModel } from '../../../../shared/models/response/invetory-location-response-model';
import { Editor } from "primeng/editor";
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';


@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.sass']
})
export class ClassificationComponent extends BaseComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  title = 'Brightree Settings';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  includeWeekend: boolean;
  allowedmaxAppointment: boolean;
  loggedInUserId: number;
  switchActiveInactive: boolean;
  statusInformation: boolean;
  statusBtnTitle: string = "Update";
  statusEditMode: boolean;

  Classifications: SettingsClassificationTypeResponseModel[];
  scrollableCols: any[];

  formStatusSubmitted: boolean = false;
  loadingClassification: boolean = false;
  classificationTypeOptions: WIPStatesResponseModel[];
  classificationType: SettingsClassificationTypeResponseModel[];
  brightreeStatusOptions: WIPStatesResponseModel[];
  wipAssignedToOptions: WIPStatesResponseModel[];
  InvetoryLocationOptions: InvetoryLocationResponseModel[];
  classificationDurationOptions = [
    { title: '15 Minutes', value: 15 },
    { title: '30 Minutes', value: 30 },
    { title: '45 Minutes', value: 45 },
    { title: '60 Minutes', value: 60 },
    { title: '75 Minutes', value: 75 },
    { title: '90 Minutes', value: 90 },
    { title: '105 Minutes', value: 105 },
    { title: '120 Minutes', value: 120 }];

  classificationInformation: boolean;
  classificationBtnTitle: string = "Update";
  classificationEditMode: boolean;
  formClassificationSubmitted: boolean = false;
  selectedClassificationModel: SettingsClassificationTypeResponseModel;
  selectedEMedAssistStatus: WIPStatesResponseModel;
  selectedBrightreeStatus: WIPStatesResponseModel;
  selectedClassificationType: WIPStatesResponseModel;
  selectedWipAssignedTo: WIPStatesResponseModel;
  selectedDuration: { title: string, value: number };
  selectedFacilityDuration: { title: string, value: number };
  selectedRemoteDuration: { title: string, value: number };
  selectedRoadhomeDuration: { title: string, value: number };
  selectedInvetoryLocation: InvetoryLocationResponseModel;
  isRemote: boolean = false;
  isFacility: boolean = false
  formFields = {
    classification: 'classification',
    brightreeStatus: 'brightreeStatus',
    displayName: 'displayName',
    duration: 'duration',
    facilityduration: 'facilityduration',
    remoteduration: 'remoteduration',
    roadhomeduration: 'roadhomeduration',
    wipAssignedToKey: 'wipAssignedToKey',
    blackOutDays: 'blackOutDays',
    description: 'description',
    invetoryLocation: 'invetoryLocation',
    maxNoOfAppointment: 'maxNoOfAppointment',
    classificationDocuments: 'classificationDocuments'
  };

  validationMessages = {

    classification: {
      required: 'Classification is required',
      // validCharacters: 'Enter valid classification'
    },
    brightreeStatus: {
      required: 'Brightree Status is required',
      // validCharacters: 'Enter valid brightree status'
    },
    displayName: {
      required: 'Display name is required',
      validCharacters: 'Enter valid display name'
    },
    duration: {
      required: 'Duration is required',
      // validCharacters: 'Enter valid duration'
    },
    facilityduration: {
      required: 'Duration for facility is required',
      // validCharacters: 'Enter valid duration'
    },
    remoteduration: {
      required: 'Duration for remote setup is required',
      // validCharacters: 'Enter valid duration'
    },
    roadhomeduration: {
      required: 'Duration for Road/home visit is required',
      // validCharacters: 'Enter valid duration'
    },

    wipAssignedToKey: {
      required: 'WIP Assigned To is required',
      // validCharacters: 'Enter valid brightree status'
    },
    blackOutDays: {
      required: 'Black Out Days is required',
      validNumber: 'Enter valid Black Out Days',
    },
    description: {
      required: 'Description is required',
      validCharacters: 'Enter valid description'
    },
    invetoryLocation: {
      required: 'Invetory Location is required',
    },
    maxNoOfAppointment: {
      required: 'No. of Appointment is required',
      validNumber: 'Enter valid No.of Appointment',
      numberGreaterThanZero: 'No. of Appointment should be greater than zero',
    },
  };

  ClassificationSettings: boolean;
  displayClassification: any;

  constructor(
    private dialogueService: DialogService,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    private _cdr: ChangeDetectorRef,
    messageService: MessageService) {
    super(messageService);
    this.selectedBrightreeStatus = new WIPStatesResponseModel();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.buildForm();
  }

  get fClassificationType() { return (<FormGroup>this.form.get('formClassificationType')).controls; }

  get formClassificationType() {
    return this.form.get('formClassificationType');
  }

  ngOnInit() {

    this.getInvetoryLocation();
    this.getClassificationSettings();
    this.getWipStatusBT();
    this.getAssignedToPerson();
    this.getClassificationTypeBT();
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  onIncludeWeekend(status) {


    this.includeWeekend = status.checked;

    if (status)
      this.includeWeekend = status.checked;
  }

  onAllowedmaxAppointment(status) {
    if (status) {

      this.allowedmaxAppointment = status.checked;
      var form = this.formClassificationType;
      if (status.checked) {
        form.get(this.formFields.maxNoOfAppointment).setValidators([
          Validators.required,
          CustomValidator.whitespace,
          CustomValidator.onlyNumbers,
        ]);
        form.get(this.formFields.maxNoOfAppointment).updateValueAndValidity();
      }
      else {
        form.get(this.formFields.maxNoOfAppointment).clearValidators();
        form.get(this.formFields.maxNoOfAppointment).updateValueAndValidity();
      }
    }

  }

  onClassificationAdd() {
    this.formClassificationSubmitted = true;
    if (this.formClassificationType.invalid) {
      this.markFormAsTouched();
      return;
    }

    const classificationModel = this.getValuesFromClassificationForm();

    let manageClassification = this.settingsService.addNewClassificationType(classificationModel);
    if (this.classificationEditMode) {
      classificationModel.crmClassificationTypeId = this.selectedClassificationModel.crmClassificationTypeId;
      manageClassification = this.settingsService.updateClassificationType(classificationModel);
    }

    this.loading = true;

    manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.getClassificationSettings();

          this.showSuccessMessage(this.classificationEditMode ? 'Classification type information has been updated successfully.'
            : 'Classification type information has been added successfully.');
          this.selectedClassificationModel = new SettingsClassificationTypeResponseModel();
          this.classificationEditMode = false;
          this.resetValuesToClassificationForm();
          // this.getUser(this.userInfoId);

          // this.getClassificationTypeBT();
          this.resetClassificationDropdown();
          this.resetBrightreeStatusDropdown();
          this._cdr.detectChanges();
          this.classificationInformation = !this.classificationInformation;
          this.ClassificationSettings = !this.ClassificationSettings;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
    this.displayClassification = false;
  }

  onClassificationEdit(classification: SettingsClassificationTypeResponseModel) {
    const form = this.formClassificationType;
    this.classificationBtnTitle = "Update";
    this.classificationInformation = true;
    this.classificationEditMode = true;
    this.ClassificationSettings = true;
    this.formClassificationType.reset();
    this.selectedClassificationModel = classification;
    form.get(this.formFields.classification).clearValidators();
    form.get(this.formFields.classification).updateValueAndValidity();

    this.setValuesToClassificationForm();
    this.displayClassification = !this.displayClassification;
  }

  onClassificationDelete(classification: SettingsClassificationTypeResponseModel) {
    this.resetValuesToClassificationForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete 'Status ${classification.crmClassificationType}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      //const index = this.licenseResponseModel.indexOf(license, 0);
      //if (index > -1) {
      //  this.licenseResponseModel.splice(index, 1);
      //}
      this.loading = true;
      classification.isActive = false;
      const manageClassification = this.settingsService.activeInactiveClassificationType(classification);
      manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Classification type information has been deleted successfully.');
            this.getClassificationSettings();
            this.resetClassificationDropdown();
            this.resetBrightreeStatusDropdown();

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  onClassificationCancel() {
    this.resetValuesToClassificationForm();
    this.classificationInformation = !this.classificationInformation;
    this.ClassificationSettings = !this.ClassificationSettings;
    this.displayClassification = !this.displayClassification;
  }

  resetClassificationDropdown() {
    this.classificationTypeOptions = new Array<WIPStatesResponseModel>();
    this.getClassificationTypeBT();
  }

  resetBrightreeStatusDropdown() {
    this.brightreeStatusOptions = new Array<WIPStatesResponseModel>();
    this.getWipStatusBT();
  }

  ClassificationInfoButton() {
    this.classificationBtnTitle = "Add";
    this.classificationInformation = true;
    this.allowedmaxAppointment = false;
    this.resetValuesToClassificationForm();
    this.ClassificationSettings = !this.ClassificationSettings;
    this.displayClassification = !this.displayClassification;
  }

  private getValuesFromClassificationForm(): SettingsClassificationTypeResponseModel {

    const form = this.formClassificationType;
    const classification = new SettingsClassificationTypeResponseModel();
    classification.crmClassificationType = this.selectedClassificationType.wipStatesName;
    classification.crmClassificaitonduration = this.selectedDuration.value;
    classification.crmFacilityClassificationDuration = this.selectedFacilityDuration.value;
    classification.crmRemoteClassificationDuration = this.selectedRemoteDuration.value;
    classification.crmRoadHomeClassificationDuration = this.selectedRoadhomeDuration.value;
    classification.crmDescription = form.get(this.formFields.description).value;
    classification.crmClassificationDisplayName = form.get(this.formFields.displayName).value;
    classification.modifiedByUserInfoId = this.loggedInUserId;
    classification.crmWIPAssignedToKey = this.selectedWipAssignedTo ? Number(this.selectedWipAssignedTo.wipStatesId) : 0;
    classification.crmWIPStatusNameBT = this.selectedBrightreeStatus.wipStatesName;
    classification.crmBlackOutDays = Number(form.get(this.formFields.blackOutDays).value);
    classification.crmIsIncludeWeekend = this.includeWeekend;
    classification.crmInvetoryLocationId = this.selectedInvetoryLocation ? Number(this.selectedInvetoryLocation.invetoryLocationId) : 0;
    classification.crmIsEnableAppointmentMaxLimit = this.allowedmaxAppointment;
    classification.crmMaxNoOfAppointment = Number(form.get(this.formFields.maxNoOfAppointment).value);
    classification.excludeBlackoutDaysFacility = this.isFacility;
    classification.excludeBlackoutDaysRemote = this.isRemote;
    classification.crmClassificationDocuments = form.get(this.formFields.classificationDocuments).value;
    return classification;
  }

  private setValuesToClassificationForm() {
    if (!this.selectedClassificationModel) {
      return;
    }

    const form = this.formClassificationType;

    this.selectedClassificationType = new WIPStatesResponseModel();
    this.selectedClassificationType = {
      wipStatesName: this.selectedClassificationModel.crmClassificationType,
      wipStatesId: this.selectedClassificationModel.crmClassificationTypeId
    };

    this.selectedDuration = this.classificationDurationOptions.find(x => x.value === this.selectedClassificationModel.crmClassificaitonduration);
    form.get(this.formFields.duration).setValue(this.selectedDuration);

    this.selectedFacilityDuration = this.classificationDurationOptions.find(x => x.value === this.selectedClassificationModel.crmFacilityClassificationDuration);
    form.get(this.formFields.facilityduration).setValue(this.selectedFacilityDuration);


    this.selectedRemoteDuration = this.classificationDurationOptions.find(x => x.value === this.selectedClassificationModel.crmRemoteClassificationDuration);
    form.get(this.formFields.remoteduration).setValue(this.selectedRemoteDuration);


    this.selectedRoadhomeDuration = this.classificationDurationOptions.find(x => x.value === this.selectedClassificationModel.crmRoadHomeClassificationDuration);
    form.get(this.formFields.roadhomeduration).setValue(this.selectedRoadhomeDuration);



    form.get(this.formFields.description).setValue(this.selectedClassificationModel.crmDescription);
    form.get(this.formFields.displayName).setValue(this.selectedClassificationModel.crmClassificationDisplayName);

    form.get(this.formFields.blackOutDays).setValue(this.selectedClassificationModel.crmBlackOutDays);
    this.includeWeekend = this.selectedClassificationModel.crmIsIncludeWeekend;

    this.selectedBrightreeStatus = this.brightreeStatusOptions.find(x => x.wipStatesName === this.selectedClassificationModel.crmWIPStatusNameBT);
    form.get(this.formFields.brightreeStatus).setValue(this.selectedBrightreeStatus);

    this.selectedWipAssignedTo = this.wipAssignedToOptions.find(x => x.wipStatesId === this.selectedClassificationModel.crmWIPAssignedToKey);
    form.get(this.formFields.wipAssignedToKey).setValue(this.selectedWipAssignedTo);

    this.selectedInvetoryLocation = this.InvetoryLocationOptions.find(x => x.invetoryLocationId === this.selectedClassificationModel.crmInvetoryLocationId);
    form.get(this.formFields.invetoryLocation).setValue(this.selectedInvetoryLocation);

    form.get(this.formFields.maxNoOfAppointment).setValue(this.selectedClassificationModel.crmMaxNoOfAppointment);
    this.allowedmaxAppointment = this.selectedClassificationModel.crmIsEnableAppointmentMaxLimit;

    this.isRemote = this.selectedClassificationModel.excludeBlackoutDaysRemote;
    this.isFacility = this.selectedClassificationModel.excludeBlackoutDaysFacility;

    form.get(this.formFields.classificationDocuments).setValue(this.selectedClassificationModel.crmClassificationDocuments);

    var form1 = this.formClassificationType;
    if (this.allowedmaxAppointment) {
      form1.get(this.formFields.maxNoOfAppointment).setValidators([
        Validators.required,
        CustomValidator.whitespace,
        CustomValidator.onlyNumbers,

      ]);
      form1.get(this.formFields.maxNoOfAppointment).updateValueAndValidity();
    }
    else {
      form1.get(this.formFields.maxNoOfAppointment).clearValidators();
      form1.get(this.formFields.maxNoOfAppointment).updateValueAndValidity();
    }
  }

  resetValuesToClassificationForm() {
    const form = this.formClassificationType;
    form.get(this.formFields.classification).setValue('');
    form.get(this.formFields.brightreeStatus).setValue('');
    form.get(this.formFields.duration).setValue('');
    form.get(this.formFields.facilityduration).setValue('');
    form.get(this.formFields.remoteduration).setValue('');
    form.get(this.formFields.roadhomeduration).setValue('');
    form.get(this.formFields.description).setValue('');
    form.get(this.formFields.displayName).setValue('');
    /*this.selectedDuration = { title: '30', value: 30 };*/
    form.get(this.formFields.blackOutDays).setValue('');
    form.get(this.formFields.duration).setValue('')
    form.get(this.formFields.wipAssignedToKey).setValue('')
    form.get(this.formFields.invetoryLocation).setValue('');
    form.get(this.formFields.maxNoOfAppointment).setValue('');
    form.get(this.formFields.classificationDocuments).setValue('');
    this.classificationEditMode = false;
    this.formClassificationSubmitted = false;

    form.reset();
    //form.get(this.formFields.classification).setValidators([Validators.required]);
    //form.get(this.formFields.brightreeStatus).setValidators([Validators.required]);
  }

  private async getWipStatusBT() {
    this.loading = true;
    await this.settingsService.getWipStatusBT()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {
          this.brightreeStatusOptions = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAssignedToPerson() {
    this.loading = true;

    await this.settingsService.getAssignedToPerson()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {

          this.wipAssignedToOptions = result.responseModel;

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getClassificationSettings() {
    this.loadingClassification = true;

    this.settingsService.GetClassificationTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingClassification = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.classificationType = result.responseModel;
          this._cdr.detectChanges();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getClassificationTypeBT() {
    this.loading = true;
    this.settingsService.getClassificationTypeBT()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {
          this.classificationTypeOptions = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getInvetoryLocation() {
    this.loading = true;
    await this.settingsService.getInvetoryLocation()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<InvetoryLocationResponseModel[]>) => {
        this.processResult<any>(result, () => {
          this.InvetoryLocationOptions = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private buildForm() {
    const formClassificationGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formClassificationGroup.addControl(this.formFields.classification, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formClassificationGroup.addControl(this.formFields.displayName, this.formBuilder.control('',
      {
        validators: [Validators.required, CustomValidator.whitespace],
        updateOn: Constants.formControlUpdateStrategy.blur
      }));
    formClassificationGroup.addControl(this.formFields.brightreeStatus, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formClassificationGroup.addControl(this.formFields.duration, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formClassificationGroup.addControl(this.formFields.facilityduration, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formClassificationGroup.addControl(this.formFields.remoteduration, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formClassificationGroup.addControl(this.formFields.roadhomeduration, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // tslint:disable-next-line: max-line-length
    formClassificationGroup.addControl(this.formFields.description, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    formClassificationGroup.addControl(this.formFields.wipAssignedToKey, this.formBuilder.control('',
      {
        validators: [],
      }));
    formClassificationGroup.addControl(this.formFields.blackOutDays, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));

    formClassificationGroup.addControl(this.formFields.invetoryLocation, this.formBuilder.control('',
      {
        validators: [],
      }));

    formClassificationGroup.addControl(this.formFields.maxNoOfAppointment, this.formBuilder.control('',
      {
        validators: [CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.change
      }));

    formClassificationGroup.addControl(this.formFields.classificationDocuments, this.formBuilder.control('',
      {
        validators: [],
      }));

    this.form = this.formBuilder.group({
      formClassificationType: formClassificationGroup
    });
  }

  getInventoryLocationName(_id: number): string {
    let _name: string = "";
    if (Number(_id) > 0) {
      let InvetoryLocationModel: InvetoryLocationResponseModel;
      InvetoryLocationModel = this.InvetoryLocationOptions.find(x => x.invetoryLocationId === Number(_id));
      _name = InvetoryLocationModel ? InvetoryLocationModel.invetoryLocationName : '';
    }

    return _name;
  }

  handleRemoteChange(id, e) {
    let isChecked = e.checked;
    this.UpdateClassificationBlackOutStatus(id, true, false, isChecked);
  }

  handleFacilityChange(id, e) {
    let isChecked = e.checked;
    this.UpdateClassificationBlackOutStatus(id, false, true, isChecked);
  }

  UpdateClassificationBlackOutStatus(classificationId, isExcludeBlackoutDaysRemote, isExcludeBlackoutDaysFacility, value) {
    if (value) {
      this.dialogueService.open(ConfirmDialogComponent, {
        data:
        {
          message: `Are you sure you want to exclude blackout days effect?'`,
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          if (isExcludeBlackoutDaysRemote) {
            this.classificationType.find(x => x.crmClassificationTypeId == classificationId).excludeBlackoutDaysRemote = false;
          }
          if (isExcludeBlackoutDaysFacility) {
            this.classificationType.find(x => x.crmClassificationTypeId == classificationId).excludeBlackoutDaysFacility = false;
          }
          return;
        }
        this.loading = true;
        var classification = new SettingsClassificationTypeResponseModel();
        classification.crmClassificationTypeId = classificationId;
        classification.isExcludeBlackoutDaysRemote = isExcludeBlackoutDaysRemote;
        classification.isExcludeBlackoutDaysFacility = isExcludeBlackoutDaysFacility;
        classification.ExcludeBlackOutValue = value;
        const manageClassification = this.settingsService.UpdateClassificationBlackOutStatus(classification);
        manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
          .subscribe((result: Result) => {
            if (result && result.isSuccess) {
              // this.getUser(this.userInfoId);
              this.showSuccessMessage('Classification type information has been updated successfully.');
              this.getClassificationSettings();

            } else {
              this.loading = false;
              this.showErrorMessage(result.message);
            }

          }, (httpResponseError) => {
            this.loading = false;
            this.showErrorMessage(httpResponseError.message);
          });
      });
    }
    else {
      this.loading = true;
      var classification = new SettingsClassificationTypeResponseModel();
      classification.crmClassificationTypeId = classificationId;
      classification.isExcludeBlackoutDaysRemote = isExcludeBlackoutDaysRemote;
      classification.isExcludeBlackoutDaysFacility = isExcludeBlackoutDaysFacility;
      classification.ExcludeBlackOutValue = value;
      const manageClassification = this.settingsService.UpdateClassificationBlackOutStatus(classification);
      manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Classification type information has been updated successfully.');
            //this.getClassificationSettings();

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });

    }

  }

}
