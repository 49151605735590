<!-- Title block -->
<div class="page-title">
	<div class="page-title__title-left">
		<span *ngIf="patientBookingTypeId=='R' || patientBookingTypeId=='SG'">Step 1/2</span>
		<span *ngIf="patientBookingTypeId=='F' || patientBookingTypeId=='T'">Step 2/3</span>
		<h1>Select Date & Time
		<!-- Clinic Content-->
		<p *ngIf="patientBookingTypeId =='F'" class="paragraph">Clinic Visit {{duration}}. <span  tooltip="{{description}}"><i class="info-new"></i></span></p>

		<!-- Remote Content-->
		<p *ngIf="patientBookingTypeId =='R'" class="paragraph">Remote setup {{duration}}. <span tooltip="{{description}}"><i class="info-new"></i></span></p>

		<!-- therapist Content-->
		<p *ngIf="patientBookingTypeId =='T'" class="paragraph">Book therapist {{duration}}. <span tooltip="{{description}}"><i class="info-new"></i></span></p>

		<!-- Self Guided Content-->
		<p *ngIf="patientBookingTypeId =='SG'" class="paragraph">Self Guided {{duration}}. <span tooltip="{{description}}"><i class="info-new"></i></span></p>
		
		</h1>
	</div>
	<div class="page-title__next-btn">
		<button class="btn btn--secondary" (click)="onBack()" *ngIf="patientBookingTypeId == 'F' || patientBookingTypeId == 'T'">
			<svg class="mr-10 ml-0" width="14" height="13" viewBox="0 0 14 13" fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path d="M4.33334 1.33331L1 4.56249L4.33334 7.79167" stroke="white" stroke-width="1.6"
					stroke-linecap="round" stroke-linejoin="round" />
				<path d="M1 4.56247L6.33335 4.56247C10.0154 4.56247 13 7.45387 13 11.0208V11.6667"
					stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
			Back
		</button>

		<button class="btn btn--get-started" (click)="onNext()" [disabled]="!this.selectedStartDate || !this.selectedEndDate">
			Next
			<svg class="ml-10 mr-0" width="15" height="14" viewBox="0 0 15 14" fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path d="M9.82978 1.73608L13.2258 5.02601L9.82978 8.31594" stroke="white" stroke-width="1.6"
					stroke-linecap="round" stroke-linejoin="round" />
				<path d="M13.2258 5.026L7.79214 5.026C4.04086 5.026 1.00003 7.9718 1.00003 11.6059V12.2638"
					stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</button>
	</div>
</div>

<!-- Filter block -->

<div class="scroll-calendar-timeslot">
	<!-- {{calendarView}} -->
	<!-- Calenadar -->
	<div class="timeslot-calendar">
		<!-- <a href="javascript:void(0)" class="calendar-prev-arrow p-datepicker-prev">
			<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M-1.60761e-06 20.5714L8.75 12L-1.08937e-07 3.42857L1.75 8.36419e-07L14 12L1.75 24L-1.60761e-06 20.5714Z" fill="#B1A6E6"/>
			</svg>						
		</a> -->		
		<div class="timeslot-calendar__calendar-clumn">
			<p-calendar class="desktopview" [(ngModel)]="facilitySlotDate"
				dateFormat="mm/dd/yy" [minDate]="minAllowDateToBookAppoitment" (onSelect)="onDateSelection()"
				[numberOfMonths]="3" [maxDate]="maxAllowDateToBookAppoitment" [showOtherMonths]="false"
				[selectOtherMonths]="true" [inline]="true"></p-calendar>
			
			<p-calendar class="largetabletview" [(ngModel)]="facilitySlotDate"
				dateFormat="mm/dd/yy" [minDate]="minAllowDateToBookAppoitment" (onSelect)="onDateSelection()"
				[numberOfMonths]="2" [maxDate]="maxAllowDateToBookAppoitment" [showOtherMonths]="false"
				[selectOtherMonths]="true" [inline]="true"></p-calendar>

			<p-calendar class="mobileview" [(ngModel)]="facilitySlotDate"
				dateFormat="mm/dd/yy" [minDate]="minAllowDateToBookAppoitment" (onSelect)="onDateSelection()"
				[numberOfMonths]="1" [maxDate]="maxAllowDateToBookAppoitment" [showOtherMonths]="false"
				[selectOtherMonths]="true" [inline]="true"></p-calendar>

		</div>						
		<!-- <a href="javascript:void(0)" class="calendar-next-arrow p-datepicker-next">
			<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M-1.60761e-06 20.5714L8.75 12L-1.08937e-07 3.42857L1.75 8.36419e-07L14 12L1.75 24L-1.60761e-06 20.5714Z" fill="#B1A6E6"/>
			</svg>						
		</a> -->
	</div>

	<!-- Facility block -->
	<div class="facility-content" *ngIf="!loading">
		<div class="facility-content__date-time">
			<div class="date-time-title" *ngIf="facilitySlotDate">Timeslots <span>({{facilitySlotDate | date:'longDate'}})</span></div>
			<label class="timezone-name">
				(<strong>{{selectedTimeZone.displayName}}:</strong> {{selectedTimeZone.displayCode}})
			</label>
		</div>

		<div class="mobile-scroll">
			<div class="timeslot-block" [ngClass]="showsidebar ? 'remotesetup':'full-width' ">
				<div *ngIf="patientBookingTypeId !='F'">
					<div class="time-picker-panel" *ngFor="let item of displaySlots;">
						<ng-container *ngIf="patientBookingTypeId =='T'">
							<div class="time-picker-panel__therapist-name">
								{{crmFacilityName}}
								<!-- {{item.facilityName}}-->
								{{item.date | date:'EEEE'}} - {{item.date | date:'longDate'}}
								<span class="time-picker-panel__available-for">(Available for {{item.facilityName}} <i style="margin-right: 3px;"  *ngIf="displayAddressToolTip" class="info-new" placement="top" tooltip="{{item.facilityAddress}}"></i> {{item.remoteFacilityName}} )</span>
							</div>
							<div class="time-picker-panel__row">
								<a class="time-picker-panel__items" href="javascript:void(0)" [attr.disabled]="item1.isBlock1"
									 (click)="onTimeSelect(item1.displayStartTime,item1.dayTime,item1,item)" [ngClass]="item1.isDisabled? 'hidden-none'
									: item1.isBlock1 && !item1.isBlock && !item1.isBreak ? 'hidden-none'
									: item1.isBlock ? 'hidden-none'
									: item1.isBreak ? 'hidden-none'
									: item1.isSelected ? 'time-picker-panel__time active'
									: isSlotDisabled(item1.displayStartTime)? 'hidden-none'
									:'time-picker-panel__time'" *ngFor="let item1 of item.time;" tooltip="{{item1.slotType}}" placement="top">
									{{item1.displayStartTime| date:'hh:mm a'}}
								</a>
								<!--: item1.slotType == 'Remote Setup' ? 'time-picker-panel__time remote-setup'-->
							</div>
						</ng-container>
						<ng-container *ngIf="patientBookingTypeId !='T'">
							<div class="time-picker-panel__therapist-name">
								{{crmFacilityName}}
							</div>
							<!-- <div class="time-picker-panel__therapist-name">
								{{item.date | date:'EEEE'}} - {{item.date | date:'longDate'}} 
							</div> -->

							<div class="time-picker-panel__row">
								<a class="time-picker-panel__items" href="javascript:void(0)" [attr.disabled]="item1.isBlock1"
									(click)="onTimeSelect(item1.displayStartTime,item1.dayTime,item1,item)" [ngClass]="item1.isDisabled ? 'hidden-none'
									: item1.isBlock1 && !item1.isBlock && !item1.isBreak ? 'hidden-none'
									: item1.isBlock ? 'hidden-none'
									: item1.isBreak ? 'hidden-none'
									: item1.isSelected ? 'time-picker-panel__time active'
									: isSlotDisabled(item1.displayStartTime)? 'hidden-none' :'time-picker-panel__time'"
									*ngFor="let item1 of item.time;">
									{{item1.displayStartTime| date:'hh:mm a'}}
								</a>
							</div>
						</ng-container>
					</div>

					<span *ngIf="isGlobalHoliday">
                        {{globalHolidayMsg}}.
                    </span>
					<div *ngIf="facilitySlotForFilter?.length == 0 && displaySlots.length == 0 && loading == false">
						There are no time-slots available on the selected day, please select a different
						day or location
					</div>
				</div>
				<div *ngIf="patientBookingTypeId =='F'">
					<div *ngFor="let item of facilityDisplaySlot;">
						<div class="time-picker-panel">
							<ng-container>
								<div class="time-picker-panel__therapist-name">
									{{item?.facilityname}}
								</div>
								<!-- <div class="time-picker-panel__therapist-name">
									{{item.date | date:'EEEE'}} - {{item.date | date:'longDate'}} 
								</div> -->
								<div>
								<div class="time-picker-panel__row">
									<a class="time-picker-panel__items" href="javascript:void(0)" [attr.disabled]="item1.isBlock1"
										(click)="onTimeSelect(item1.displayStartTime,item1.dayTime,item1,item)" [ngClass]="item1.isDisabled ? 'hidden-none'
										: item1.isBlock1 && !item1.isBlock && !item1.isBreak ? 'hidden-none'
										: item1.isBlock ? 'hidden-none'
										: item1.isBreak ? 'hidden-none'
										: item1.isSelected ? 'time-picker-panel__time active'
										: isSlotDisabled(item1.displayStartTime)? 'hidden-none' :'time-picker-panel__time'"
										*ngFor="let item1 of item?.displaySlot?.time;">
										{{item1.displayStartTime| date:'hh:mm a'}}
									</a>
								</div>
							</div>
							</ng-container>

							<span *ngIf="item.isGlobalHoliday">
								{{item.globalHolidayMsg}}.
							</span>
							<div *ngIf="item?.facilitySlotForFilter?.length == 0 && !item?.displaySlot && !item?.isAllSlotsBooked && !loading && !loadingCustom">
								There are no time-slots available on the selected day, please select a different
								day or location
							</div>
							<div *ngIf="item?.isAllSlotsBooked && !loading && !loadingCustom">
								There are no time-slots available on the selected day, please select a different
								day or location
							</div>
						</div>
					</div>
					
					<div *ngIf="facilityDisplaySlot?.length == 0 && !loading && !loadingCustom">
						There are no time-slots available on the selected day, please select a different
						day or location
					</div>
				</div>
		
				<div style="margin-bottom: 2rem;" *ngIf="selectedFacility?.length == 0 && patientBookingTypeId !=='T' && patientBookingTypeId !=='R' && !loading && !loadingCustom"> There is no
					Facilities Available , Please request a Call Back
				</div>
				<div style="margin-bottom: 2rem;" *ngIf="selectedFacility?.length == 0 && patientBookingTypeId =='R' && !loading && !loadingCustom">
					There is no therapist available having licensed in the state of the Patient’s address. 
					If you want us to reach for your appointment then call us at <a href="tel:{{DMENumber}}">{{DMENumber | phone}}</a>.
				</div>
			</div>

			<!-- Right SIdebar-->
			<div class="remote-setup-right" *ngIf="showsidebar">
				<!-- Remote / Facility  setup appt info Therapist -->
				<div *ngIf="isTherapistRemoteAndFaciltyType" class="facility-info">
					<h3 class="facility-info__title-top" *ngIf="displaySelectedStartDate">Select any Appointment
						Type
					</h3>
					<div class="select-type-remote">
						<p-radioButton name="group1" value="ClinicVisit" label="Clinic Visit" [ngClass]="!isDrFacilityVisitOnly?'disable':''"
							[(ngModel)]="selectedAppointmentTypePopUp"
							(click)="handleFacilityVisitOptions('ClinicVisit')">
						</p-radioButton>
						<p-radioButton name="group1" value="Telehealth" label="Remote Setup" [ngClass]="!isDrRemoteVisitOnly?'disable':''"
							[(ngModel)]="selectedAppointmentTypePopUp "
							(click)="handleFacilityVisitOptions('Telehealth')">
						</p-radioButton>
					</div>
				</div>
				
				<!-- Ficility appt info -->
				<div *ngIf="(isTherapistFacilityType && patientBookingTypeId =='T')" class="facility-info">
					<h3 class="facility-info__title">{{crmFacilityName}}</h3>
					<div class="facility-info__facility-miles">{{crmFacilityDistanceInMile}}</div>
					<address class="facility-info__address">
						<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M8.09271 5.06006C7.50368 5.06006 6.92787 5.23272 6.43811 5.55621C5.94834 5.8797 5.56662 6.33948 5.34121 6.87743C5.11579 7.41537 5.05681 8.0073 5.17173 8.57838C5.28664 9.14946 5.57029 9.67402 5.9868 10.0857C6.40331 10.4975 6.93398 10.7779 7.51169 10.8914C8.08941 11.005 8.68823 10.9467 9.23243 10.7239C9.77662 10.5011 10.2418 10.1238 10.569 9.63963C10.8963 9.15549 11.0709 8.5863 11.0709 8.00404C11.07 7.22352 10.756 6.47522 10.1976 5.92331C9.63931 5.3714 8.88231 5.06095 8.09271 5.06006ZM8.09271 9.47603C7.7982 9.47603 7.51029 9.3897 7.26541 9.22795C7.02053 9.06621 6.82967 8.83632 6.71696 8.56734C6.60425 8.29837 6.57476 8.0024 6.63222 7.71687C6.68968 7.43133 6.8315 7.16905 7.03976 6.96318C7.24801 6.75732 7.51335 6.61713 7.8022 6.56033C8.09106 6.50353 8.39047 6.53269 8.66257 6.6441C8.93467 6.75551 9.16724 6.94418 9.33086 7.18624C9.49449 7.42831 9.58182 7.71291 9.58182 8.00404C9.58137 8.3943 9.42433 8.76844 9.14517 9.04439C8.866 9.32035 8.48751 9.47558 8.09271 9.47603Z"
								fill="#868686" />
							<path
								d="M13.8156 2.34254C12.4006 0.94417 10.5117 0.11348 8.51359 0.0107968C6.51544 -0.0918867 4.54965 0.540715 2.9956 1.78651C1.44155 3.0323 0.409411 4.80297 0.0983971 6.75678C-0.212617 8.71058 0.219536 10.709 1.31145 12.3664L6.93781 20.9045C7.06301 21.0945 7.23421 21.2506 7.43587 21.3587C7.63754 21.4667 7.86329 21.5233 8.09268 21.5233C8.32206 21.5233 8.54782 21.4667 8.74948 21.3587C8.95115 21.2506 9.12234 21.0945 9.24755 20.9045L14.8741 12.3664C15.8873 10.8289 16.335 8.99395 16.1423 7.16898C15.9496 5.34402 15.1281 3.64 13.8156 2.34254ZM13.6264 11.5629L8.0927 19.9601L2.55895 11.5629C0.865092 8.99249 1.22839 5.55263 3.42277 3.38337C4.03603 2.77715 4.76408 2.29626 5.56535 1.96818C6.36661 1.64009 7.22541 1.47122 8.0927 1.47122C8.95999 1.47122 9.81879 1.64009 10.6201 1.96818C11.4213 2.29626 12.1494 2.77715 12.7626 3.38337C14.957 5.55263 15.3203 8.99249 13.6264 11.5629Z"
								fill="#868686" />
						</svg>

						{{crmFacilityAddress}}
					</address>

					<div class="facility-info__timing">
						<svg width="22" height="20" viewBox="0 0 22 20" fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								d="M21.3114 7.10209H15.062C14.9546 7.10209 14.8667 7.18997 14.8667 7.29738V8.46915C14.8667 8.57656 14.9546 8.66444 15.062 8.66444H21.3114C21.4188 8.66444 21.5067 8.57656 21.5067 8.46915V7.29738C21.5067 7.18997 21.4188 7.10209 21.3114 7.10209ZM18.0402 10.4221H15.062C14.9546 10.4221 14.8667 10.51 14.8667 10.6174V11.7891C14.8667 11.8966 14.9546 11.9844 15.062 11.9844H18.0402C18.1477 11.9844 18.2355 11.8966 18.2355 11.7891V10.6174C18.2355 10.51 18.1477 10.4221 18.0402 10.4221ZM9.89404 4.91724H8.83701C8.68566 4.91724 8.5636 5.0393 8.5636 5.19065V11.2448C8.5636 11.3326 8.6051 11.4132 8.67589 11.4645L12.3108 14.1156C12.4329 14.2035 12.6037 14.1791 12.6916 14.057L13.319 13.2001V13.1977C13.4069 13.0756 13.38 12.9048 13.258 12.8169L10.165 10.5808V5.19065C10.1675 5.0393 10.043 4.91724 9.89404 4.91724Z"
								fill="#6F64A7" />
							<path
								d="M17.889 13.4956H16.478C16.3412 13.4956 16.2119 13.5664 16.1386 13.6835C15.8286 14.1742 15.4673 14.6283 15.0523 15.0433C14.337 15.7585 13.5046 16.32 12.5794 16.7106C11.62 17.1158 10.602 17.3209 9.55234 17.3209C8.50019 17.3209 7.48222 17.1158 6.52528 16.7106C5.60007 16.32 4.76763 15.7585 4.05237 15.0433C3.3371 14.328 2.77563 13.4956 2.38505 12.5704C1.97981 11.6134 1.77475 10.5954 1.77475 9.5433C1.77475 8.49115 1.97981 7.47562 2.38505 6.51624C2.77563 5.59104 3.3371 4.7586 4.05237 4.04333C4.76763 3.32807 5.60007 2.7666 6.52528 2.37601C7.48222 1.97077 8.50263 1.76571 9.55234 1.76571C10.6045 1.76571 11.6225 1.97077 12.5794 2.37601C13.5046 2.7666 14.337 3.32807 15.0523 4.04333C15.4673 4.45833 15.8286 4.91239 16.1386 5.40307C16.2119 5.52024 16.3412 5.59104 16.478 5.59104H17.889C18.0574 5.59104 18.1648 5.41527 18.0891 5.26636C16.4975 2.10016 13.2702 0.0422446 9.66707 0.000744623C4.39169 -0.0651671 0.00978138 4.25327 1.66777e-05 9.52377C-0.00974802 14.804 4.26963 19.0883 9.5499 19.0883C13.1995 19.0883 16.4804 17.0231 18.0891 13.8202C18.1648 13.6713 18.055 13.4956 17.889 13.4956Z"
								fill="#6F64A7" />
						</svg>
						<span *ngIf="facilityAvailableWeekDays?.length>0 && selectedFacilityWeekDayMinDate">
							<strong>{{selectedFacilityWeekDayMinDate?.startTime | date: 'EEE'
								}} - {{selectedFacilityWeekDayMaxDate?.startTime | date: 'EEE' }}</strong>
							{{selectedFacilityWeekDayMinDate?.startTime | date: 'h:mm aaa' |
							lowercase}}-{{selectedFacilityWeekDayMinDate?.endTime | date: 'h:mm aaa' | lowercase}}
						</span>
					</div>
				</div>

				<!-- Remote setup appt info -->
				<div *ngIf="(isTherapistRemoteType && patientBookingTypeId =='T') || (patientBookingTypeId =='R')"
					class="facility-info">
					<h3 class="facility-info__title">Choose any option for remote setup </h3>
					<div class="select-type-remote">
						<ul>
							<li *ngFor="let item of remoteAppointmentTypeModel">
								<p-radioButton name="group5" value="{{item.crmRemoteAppointmentType}}"
									label="{{item.crmRemoteAppointmentTitle}}"
									[(ngModel)]="selectedRemoteAppointmentType"
									(onClick)="manageRemoteAppointment(item.crmRemoteAppointmentTitle)">
								</p-radioButton>
							</li>
						</ul>
					</div>
					
					<div class="facility-info__note">
						<p><strong>Note :</strong></p>
						<p *ngFor="let item of remoteAppointmentTypeModel">With {{item.crmRemoteAppointmentTitle}}
							<ng-container *ngIf="item.crmRemoteAppointmentType == 'OnlineMeeting'">
								booking, you will receive the meeting link in email.
							</ng-container>
							<ng-container *ngIf="item.crmRemoteAppointmentType == 'Telemed'">
								booking, you can book an appointment and therapist will call you.
							</ng-container>
						</p>
					</div>
				</div>

				<!-- Self Guided -->
				<div *ngIf="patientBookingTypeId =='SG'" class="facility-info">
					<div class="facility-info__note">
						<p><strong>Note :</strong></p>
						<p>With Self Guided booking, you will receive the meeting link in email.</p>
					</div>
				</div>
			</div>
		</div>

		
	</div>
</div>

<div class="loader-block" *ngIf="loading || loadingCustom" style="z-index: 99999999 !important;">
	<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
	</p-progressSpinner>
</div>
