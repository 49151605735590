import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Result } from 'src/app/shared/models/response/result';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { WebStorageService } from '../services/web-storage.service';
const MINUTES_UNITL_AUTO_LOGOUT = 60 // in Minutes
const CHECK_INTERVALL = 1000 // in ms

@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  private unsubscriber = new Subject<boolean>();
  loading: boolean;
  
  constructor(private webStorageService: WebStorageService,
    private featureGroupService: FeatureGroupService,
    private authenticationService: AuthenticationService,
    private ngZone: NgZone) {
    this.webStorageService.storeIsSessionValue(Date.now());
    this.check();
    this.initListener();
    this.initInterval();
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  reset() {
    if (!location.href.includes('login')) {
      this.webStorageService.storeIsSessionValue(Date.now());
    }
  }

  check() {
    if (this.webStorageService.getIsSessionValue()) {
      const now = Date.now();
      const timeleft = this.webStorageService.getIsSessionValue() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;

      this.ngZone.run(() => {
        if (isTimeout) {
          let r = confirm("Your session is timed out. Do you want to continue?");
          if (r) {
            this.webStorageService.storeIsSessionValue(Date.now());
          }
          else {
            this.onLogout();
          }
        }
      });
    }
  }

  onLogout() {

    this.featureGroupService.logout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.authenticationService.signOut();
        }

      }, (httpResponseError) => {
        
      });
  }

}


