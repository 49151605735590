import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { Constants } from '../constants';
import { UserAuthResponseModel, UserAuthResponseModelMain, UserAuthTokenResponse } from '../models/response/access-token-response-model';
import { Result, ResultOfT } from '../models/response/result';
import { AuthenticationRequest } from '../models/request/authentication-request-model';
import { ConfigResponseModel } from '../models/response/client-config-response';

import { BaseService } from './base.service';
import { WebStorageService } from './web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends BaseService {
  private isSessionAlive: boolean;
  private unsubscriber = new Subject<boolean>();
  onSignInOut: Subject<string>;
  loading: boolean;
  constructor(httpService: HttpClient,
    private router: Router,
    webStorageService: WebStorageService,
    private featureGroupService: FeatureGroupService) {
    super(httpService, webStorageService);

    this.isSessionAlive = true;
    this.onSignInOut = new Subject<string>();
  }

  isAuthenticated(redirectToSignIn: boolean = false) {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    if (currentUserToken) {
      const currentDate = new Date();
      const expiresOn = new Date(currentUserToken.expires);
      if (expiresOn < currentDate) {
        //this.showSessionTimeoutDialog();
        return false;
      }

      this.isSessionAlive = true;
      return true;
    }
    else {
      /*
        When user has opened website in two tabs and user do sign out manually
        from one tab and in the other tab trying to access any resource at that time
        token won't be found in local storage but token is required because accessing
        few property of token so whenever this kind of scenario comes in picture
        redirect to sign in page to prevent error cause
      */
      //if (redirectToSignIn)
      //  this.showSessionTimeoutDialog();

      return false;
    }
  }

  getCurrentUserToken(): UserAuthResponseModel {
    return this.webStorageService.getCurrentUserToken();
  }

  getCurrentUserAuthToken(): UserAuthTokenResponse {
    return this.webStorageService.getCurrentUserAuthToken();
  }

  signIn(requestmodel: AuthenticationRequest) {


    return this.post<ResultOfT<UserAuthResponseModelMain>>('authentication/authenticate', requestmodel);
  }

  getClinetDetails(domainUrl: string) {
    if (!this.webStorageService.getClientConfig()) {
      return this.get<ResultOfT<ConfigResponseModel>>('authentication/config?url=' + domainUrl + "&rid=" + Math.random().toString(), new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'clientUrl': domainUrl,
        }));
    }
    else {
      return this.get<ResultOfT<ConfigResponseModel>>('authentication/config?url=' + domainUrl + "&rid=" + Math.random().toString(), new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'clientId': this.webStorageService.getClientConfig().companyGuid,
        }));
    }
  }
  getClinetDetailsCustom(domainUrl: string) {
    if (!this.webStorageService.getClientConfig()) {
      return this.get<ResultOfT<ConfigResponseModel>>('authentication/configcustom?url=' + domainUrl + "&rid=" + Math.random().toString(), new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'clientUrl': domainUrl,
          'forceRefresh': 'true'
        }));
    }
    else {
      return this.get<ResultOfT<ConfigResponseModel>>('authentication/configcustom?url=' + domainUrl + "&rid=" + Math.random().toString(), new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'clientId': this.webStorageService.getClientConfig().companyGuid,
          'forceRefresh': 'true'
        }));
    }
  }

  signOut(redirectUrl: string = null) {
    try {

      this.featureGroupService.applicationlogout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => { });


      this.featureGroupService.logout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            this.webStorageService.deleteCurrentUserToken();
            //this.webStorageService.deleteCurrentUserAuthToken();
            this.webStorageService.deleteIsSession();
            this.webStorageService.deleteIsSessionValue();
            this.webStorageService.deleteRandomNumber();
            this.webStorageService.deleteFeatureGroups();
            this.webStorageService.deleteUserBranch();
            this.webStorageService.deleteSelectedFacilies();
            this.webStorageService.deleteAppointmentConfirmation();
            this.webStorageService.deletePermissions();
            this.webStorageService.deleteDateFormat();
            this.webStorageService.deleteGlobalSearch();
            this.webStorageService.deleteSearchedRequestModel();
            this.webStorageService.deleteUserBrances();
            this.webStorageService.removedefaultModeofAppointment();
            this.webStorageService.deleteBrightreeSettings();
            this.webStorageService.deleteClientConfig();

           

            if (redirectUrl)
              this.router.navigate(['/login'], { queryParams: { returnUrl: redirectUrl } });
            else
              this.router.navigate(['/login']);

            this.onSignInOut.next(Constants.userType.none);
          }
        });

    } catch (e) {
      this.webStorageService.deleteCurrentUserToken();
      this.webStorageService.deleteIsSession();
      this.webStorageService.deleteIsSessionValue();
      this.webStorageService.deleteRandomNumber();
      this.webStorageService.deleteFeatureGroups();
      this.webStorageService.deleteUserBranch();
      this.webStorageService.deleteSelectedFacilies();
      this.webStorageService.deleteAppointmentConfirmation();
      this.webStorageService.deletePermissions();
      this.webStorageService.deleteDateFormat();
      this.webStorageService.deleteGlobalSearch();
      this.webStorageService.deleteSearchedRequestModel();
      this.webStorageService.deleteBrightreeSettings();
      this.webStorageService.deleteClientConfig();
      this.router.navigate(['/login']);
    }
  }

  notAuthorized() {
    this.router.navigate(['/401']);
  }

  getRedirectUrlAfterSignIn(userType: string, url: string) {
    let webStore = this.webStorageService.getClientConfig();
    if (userType === Constants.userType.Therapist && webStore.isGoogleMap) {
      return '/therapist/my-appointments-new';
    }
    else if (userType === Constants.userType.Therapist && !webStore.isGoogleMap) {
      return '/therapist/my-appointments';
    }
    else if (userType) {
      return '/admin/' + url;
    }
    else {
      return '/login';
    }


    //     if (userType === Constants.userType.Admin)
    //       return '/admin/dashboard';
    //     else if (userType === Constants.userType.CSOperator)
    //       return '/admin/dashboard';
    //     else if (userType === Constants.userType.SuperAdmin) {
    //       return '/admin/dashboard';
    //  }
    //     else if (userType === Constants.userType.Therapist)
    //       return '/therapist/my-appointments';
    //     else
    //       return '/login';
    //     // return '/admin/dashboard';
  }

  //private showSessionTimeoutDialog() {
  //  if (this.isSessionAlive) {
  //    this.isSessionAlive = false;
  //    this.dialogService.showSessionTimeoutDialog(Constants.sessionTimeOutMessage)
  //      .subscribe(() => {
  //        this.signOut();
  //      });
  //  }
  //}

}
