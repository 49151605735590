<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}
      <!-- <span class="switchBtn">
        <p-inputSwitch [(ngModel)]="isActive"></p-inputSwitch>{{isActive ? 'Active' : 'Inactive'}}
      </span> -->
    </h1>

    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>
  <div class="form-content-outer">

    <div class="form-outer">
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="col">
          <div class="form__group">
            <label class="form__label">First Name</label>
            <input formControlName="firstName" type="text" class="form__control" pInputText maxlength="70">
            <span class="invalid-feedback" *ngIf="hasError(formFields.firstName, 'required') || hasError(formFields.firstName, 'whitespace')">{{validationMessages.firstName.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.firstName, 'validCharacters')">{{validationMessages.firstName.validCharacters}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Last Name</label>
            <input formControlName="lastName" type="text" class="form__control" pInputText maxlength="70">
            <span class="invalid-feedback" *ngIf="hasError(formFields.lastName, 'required') || hasError(formFields.lastName, 'whitespace')">{{validationMessages.lastName.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.lastName, 'validCharacters')">{{validationMessages.lastName.validCharacters}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">Role</label>
            <p-dropdown class="select-box" [options]="roles" placeholder="Select Role"
                        optionLabel="appRoleName" dataKey="appRoleName" (onChange)="OnRoleChange($event)"
                        [showClear]="true"
                        formControlName="role"></p-dropdown>

            <span class="invalid-feedback" *ngIf="hasError(formFields.role, 'required')">{{validationMessages.role.required}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Branch</label>
            <p-multiSelect class="select-box" [options]="branch" formControlName="branch"
                           (onChange)="onBranchChange($event)" dataKey="label" placeholder="Select Branch"
                           optionLabel="label" selectedItemsLabel="{0} Branches Selected"></p-multiSelect>
            <span class="invalid-feedback" *ngIf="hasError(formFields.branch, 'multiSelectRequired')">{{validationMessages.branch.multiSelectRequired}}</span>

          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">Email</label>
            <input formControlName="email" type="text" class="form__control" pInputText maxlength="255">
            <span class="invalid-feedback" *ngIf="hasError(formFields.email,'required')">{{commonValidationMessages.email.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.email,'email')">{{commonValidationMessages.email.email}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Phone Number</label>
            <!-- <input formControlName="phoneNumber" type="text" class="form__control" pInputText> -->
            <p-inputMask mask="999-999-9999" formControlName="phoneNumber" type="text" class="form__phone" pInputText maxlength="15"></p-inputMask>
            <span class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber,'required')||hasError(formFields.phoneNumber,'whitespace')">{{validationMessages.phoneNumber.required}}</span>
            <!-- <span *ngIf="hasError(formFields.phoneNumber,'validPhoneNumber')">{{validationMessages.phoneNumber.invalid}}</span> -->
          </div>


        </div>
       
        <div class="col">
          <div class="form__group">
            <label class="form__label">Brightree Internal User Id</label>
            <input formControlName="brightreeInternalUserId" type="text" class="form__control" pInputText maxlength="10">
            <span class="invalid-feedback" *ngIf="hasError(formFields.brightreeInternalUserId,'required')">{{validationMessages.brightreeInternalUserId.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.brightreeInternalUserId,'validNumber')">{{validationMessages.brightreeInternalUserId.validNumber}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Permissions</label>
            <p-multiSelect class="select-box" [options]="permission" formControlName="permission"
                           dataKey="label" placeholder="Select Permissions"
                           optionLabel="label" selectedItemsLabel="{0} Permissions Selected" (onChange)="onPermissionChange($event)"></p-multiSelect>
            <span class="invalid-feedback" *ngIf="hasError(formFields.permission, 'multiSelectRequired')">{{validationMessages.permission.multiSelectRequired}}</span>

          </div>
        </div>
          <div class="col">
            <div class="form__group">

              <span class="switchBtn">
                <p-inputSwitch [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{isActive ? 'Active' : 'Inactive'}}
              </span>
            </div>
          </div>

          <div class="btns">
            <button class="btn btn--primary" (click)="onSubmit()" type="submit">Submit</button>
            <button class="btn btn--secondary" (click)="onCancel()" type="button">Cancel</button>
            <!-- <button class="btn btn--secondary" [routerLink]="['/users']" >Cancel</button> -->
          </div>
</form>
    </div>

  </div>
</div>
<!-- End Content -->
