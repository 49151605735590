<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="title-block__right">
      <a href="javascript:void(0);" class="bulk-upload" [routerLink]='["/admin/voice-call-recording/bulk-upload"]'>
        Bulk Upload
      </a>
    </div>
  </div>

  <div class="title-block mt-0">
    <div class="title-block__left"><button class="btn btn--primary mb-0" (click)="onUploadButtonClick()">Multiple Uploads</button></div>
    <div class="title-block__right">

      <div class="from__group list-search-outer">
        <div class="search-box">
          <input type="text" class="form__control" pInputText placeholder="Rec ID, SO / Patient ID"
                 (keyup.enter)="onSearchButtonClick()" #searchBox>
                
          <button *ngIf="searchBox.value !=''" class="clear-btn" type="button" (click)="searchBox.value ='';onSearchButtonClick();">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button" (click)="onSearchButtonClick()" pToolTip="Rec ID, SO / Patient ID, Caller, Phone No">
            <i class="search-new"></i>
          </button>
        </div>
      </div>

      <button class="btn btn--secondary ml-20" (click)="ExportVoiceCallRecordingDetails()">Export</button>

      <a href="javascript:void(0);" pTooltip="Filters" tooltipPosition="right"
         class="filter-block filter-show-hide" (click)="overlyFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>

  <div class="page-scroll voice-call-recording-list">
    <div class="appointments-scroll-inner-side">
      <p-table #table sortMode="single" 
              class="table-outer"
               [sortOrder]="1" sortField="SchAppointmentCallId" [paginator]="totalRecords>0?true:false" [rows]="getTotalRows()"
               [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" [first]="searchRequestModel.FirstRecordIndex" [value]="salesOrders"
               (onLazyLoad)="onLazyLoad($event)" [(selection)]="selectedsalesOrders" selectionMode="" (selectionChange)="onSelectionChange($event)"
               [columns]="cols" [scrollable]="true" scrollHeight="calc(100vh - 297px)"
               dataKey="schAppointmentCallId" scrollDirection="both">

          <ng-template pTemplate="header" let-columns>
            <tr *ngIf="totalRecords > 0">
              <th class="table-outer__th table-checkbox-column" pFrozenColumn>
                <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
              </th>
              <th class="table-outer__th recid" pFrozenColumn pSortableColumn="SchAppointmentCallId">Rec. ID <p-sortIcon field="schAppointmentCallId"></p-sortIcon></th>
              <th class="table-outer__th soid-patientid" pFrozenColumn pSortableColumn="CrmSalesOrderAppId">SO / Patient ID <p-sortIcon field="crmSalesOrderAppId"></p-sortIcon></th>
              <th class="table-outer__th callername" pFrozenColumn pSortableColumn="AppCallerName">Caller <p-sortIcon field="appCallerName"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="SchAppointmentCallToNumber">To Phone <p-sortIcon field="schAppointmentCallToNumber"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="SchAppointmentCallDateTime">Rec. Date & Time <p-sortIcon field="schAppointmentCallDateTime"></p-sortIcon></th>
              <th class="table-outer__th">Audio </th>
              <th class="table-outer__th" pSortableColumn="SchRecordingUploadType">Upload Status <p-sortIcon field="schRecordingUploadType"></p-sortIcon></th>
              <th class="table-outer__th table-dot-column">Upload</th>
            </tr>
            
          </ng-template>

          <ng-template pTemplate="body" let-salesorder let-columns="columns">
           <tr>
              <td class="table-outer__td table-checkbox-column" pFrozenColumn>                
                  <span class="so-is-checkbox">
                    <!-- <i *ngIf="salesorder?.schRecordingFileStage == 3" class="lock-new disabled" pTooltip="Recording Removed"></i>
                    <i *ngIf="salesorder?.schRecordingFileStage == 0" class="lock-new disabled" pTooltip="Recording file not yet processed"></i> -->
                    <!-- <i *ngIf="salesorder?.schRecordingFileStage == 2" class="lock-new disabled" pTooltip="Recording file already uploaded"></i> -->
                    <!-- <i *ngIf="isUploadDisabled(salesorder) && salesorder?.schRecordingFileStage == 1" class="lock-new disabled" pTooltip="You can only upload 3 month older data"></i> -->
                    
                    <span class="lock-icon disabled" pTooltip="Recording Removed" *ngIf="salesorder?.schRecordingFileStage == 3">
                      <svg id="_x31__x2C_5" fill="#868686" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z"></path><path _ngcontent-lij-c189="" d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z"></path><path _ngcontent-lij-c189="" d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z"></path>
                        <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z"></path>
                      </svg>
                    </span>
                    <span class="lock-icon disabled" pTooltip="Recording file not yet processed" *ngIf="salesorder?.schRecordingFileStage == 0">
                      <svg id="_x31__x2C_5" fill="#868686" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z"></path><path _ngcontent-lij-c189="" d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z"></path><path _ngcontent-lij-c189="" d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z"></path>
                        <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z"></path>
                      </svg>
                    </span>
                    <span class="lock-icon disabled" pTooltip="Recording file already uploaded" *ngIf="salesorder?.schRecordingFileStage == 2">
                      <svg id="_x31__x2C_5" fill="#868686" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z"></path><path _ngcontent-lij-c189="" d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z"></path><path _ngcontent-lij-c189="" d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z"></path>
                        <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z"></path>
                      </svg>
                    </span>
                    <span class="lock-icon disabled" pTooltip="You can only upload 3 month older data" *ngIf="isUploadDisabled(salesorder) && salesorder?.schRecordingFileStage == 1">
                      <svg id="_x31__x2C_5" fill="#868686" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                        <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z"></path><path _ngcontent-lij-c189="" d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z"></path><path _ngcontent-lij-c189="" d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z"></path>
                        <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z"></path>
                      </svg>
                    </span>
                    <p-tableCheckbox class="table-check-first"  *ngIf="!isRowDisabled(salesorder)" [disabled]="isRowDisabled(salesorder)"
                                    [value]="salesorder"></p-tableCheckbox>
                  </span>                
              </td>
              <td class="table-outer__td recid" pFrozenColumn>
                <span pTooltip="{{salesorder.schAppointmentCallId}}" class="text-ellipsis">
                  {{salesorder.schAppointmentCallId}}
                </span>
              </td>
              <td class="table-outer__td soid-patientid" pFrozenColumn>
                <span class="text-ellipsis" pTooltip="{{salesorder.crmSalesOrderAppId}} / {{salesorder.crmPatientId}}">
                  <a (click)="onNavigateBrightTreeSalesOrderUrl(salesorder.crmSalesOrderAppId)"
                  href="javascript:void(0)">{{salesorder.crmSalesOrderAppId}}</a> / 
                  <a (click)="onNavigateBrightTreePatientUrl(salesorder.crmPatientId)"
                    href="javascript:void(0)">{{salesorder.crmPatientId}}</a>
                </span>
              </td>
              <td class="table-outer__td callername" pFrozenColumn>
                <span pTooltip="{{salesorder.appCallerName}}" class="text-ellipsis">
                  {{salesorder.appCallerName}}
                </span>
              </td>
              <td class="table-outer__td" >
                <span pTooltip="{{salesorder.schAppointmentCallToNumber}}" class="text-ellipsis">
                  {{salesorder.schAppointmentCallToNumber}}
                </span>
              </td>
              <td class="table-outer__td" >
                <span pTooltip="{{salesorder['schAppointmentCallDateTime'] | date:appDateFormat.dateFormat}} {{salesorder['schAppointmentCallDateTime'] |  date:'shortTime' | lowercase}}" class="text-ellipsis">
                  {{salesorder['schAppointmentCallDateTime'] | date:appDateFormat.dateFormat}} {{salesorder['schAppointmentCallDateTime'] |  date:'shortTime' | lowercase}}
                </span>
              </td>
              <td class="table-outer__td" >
                <a href="javascript:void(0);" class="play-icon" (click)="onPlayButtonClick(salesorder);" *ngIf="salesorder?.schRecordingFileStage != 0 &&  salesorder?.schRecordingFileStage != 3">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="26" height="26" rx="5" fill="#6F64A7" />
                    <path d="M19 13L9 7M9 7V19V7ZM19 13L9 19L19 13Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  Play
                </a>
              </td>
              <td class="table-outer__td" >
                <span pTooltip="{{salesorder['schRecordingUploadType']==null ? 'Pending': salesorder['schRecordingUploadType']}}" class="text-ellipsis">
                  {{salesorder['schRecordingUploadType']==null ? 'Pending': salesorder['schRecordingUploadType']}}
                </span>
              </td>
              <td class="table-outer__td table-dot-column" >
                <a href="javascript:void(0)" class="upload-icon disabled" *ngIf="isUploadDisabled(salesorder) || salesorder?.schRecordingFileStage != 1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path d="M26.052 15.998c-.086 0-.17 0-.252.002.132-.658.2-1.33.2-2 0-5.514-4.486-10-10-10-5.51 0-10 4.442-10.006 9.898l.016.202C2.584 14.59 0 17.556 0 21c0 3.492 2.516 6.496 6 7h5a1 1 0 0 0 0-2l-4.854.01C3.822 25.668 2 23.466 2 21c0-2.456 1.844-4.57 4.292-4.92l.86-.124a1 1 0 0 0 .858-.99l-.016-1.064C8 9.544 11.59 6 16 6c4.412 0 8 3.588 8 8 0 .542-.054 1.088-.164 1.618l-.264 1.272c-.066.318.028.646.248.884.22.236.536.358.864.308.022-.002.648-.084 1.368-.084C28.23 17.998 30 19.792 30 22c0 2.206-1.794 4-4 4h-7c-2.8 0-2.99-1.678-3-2v-6.636l2.366 2.364a1 1 0 0 0 1.414-1.414l-4.024-4.022a1.002 1.002 0 0 0-.712-.292c-.016 0-.028-.008-.044-.008a.987.987 0 0 0-.724.318l-4.028 4.024a1 1 0 0 0 1.414 1.414L14 17.412V24c0 1.382 1.044 4 5 4h7c3.308 0 6-2.692 6-6s-2.668-6.002-5.948-6.002z" />
                  </svg>
                </a>
                <a href="javascript:void(0)" class="upload-icon" *ngIf="!isUploadDisabled(salesorder) && salesorder?.schRecordingFileStage == 1" pTooltip="Click here to upload recording file on FTP" (click)="OnUpload(salesorder)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                    <path d="M26.052 15.998c-.086 0-.17 0-.252.002.132-.658.2-1.33.2-2 0-5.514-4.486-10-10-10-5.51 0-10 4.442-10.006 9.898l.016.202C2.584 14.59 0 17.556 0 21c0 3.492 2.516 6.496 6 7h5a1 1 0 0 0 0-2l-4.854.01C3.822 25.668 2 23.466 2 21c0-2.456 1.844-4.57 4.292-4.92l.86-.124a1 1 0 0 0 .858-.99l-.016-1.064C8 9.544 11.59 6 16 6c4.412 0 8 3.588 8 8 0 .542-.054 1.088-.164 1.618l-.264 1.272c-.066.318.028.646.248.884.22.236.536.358.864.308.022-.002.648-.084 1.368-.084C28.23 17.998 30 19.792 30 22c0 2.206-1.794 4-4 4h-7c-2.8 0-2.99-1.678-3-2v-6.636l2.366 2.364a1 1 0 0 0 1.414-1.414l-4.024-4.022a1.002 1.002 0 0 0-.712-.292c-.016 0-.028-.008-.044-.008a.987.987 0 0 0-.724.318l-4.028 4.024a1 1 0 0 0 1.414 1.414L14 17.412V24c0 1.382 1.044 4 5 4h7c3.308 0 6-2.692 6-6s-2.668-6.002-5.948-6.002z" />
                  </svg>
                </a>
              </td>
           </tr>
          </ng-template>

        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
        <ng-template pTemplate="paginatorright" class="table-pagination">
          <span class="table-pagination__select">
            <p-dropdown class="select-box select-branch" [options]="pageSiges" [(ngModel)]="selectedpage"
                        id="branchDropdown" placeholder="Select Page" [showClear]="true" optionLabel="name"
                        (onChange)="onPageChange($event)"></p-dropdown>
          </span>
        </ng-template>
      </p-table>
      <div class="no-records-found-appointment" *ngIf="!loading && !dataExpressionloading && totalRecords <= 0" [attr.colspan]="NsheduledTableDefaltColumns">
        No records found
      </div>

      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
</div>

<!-- filter -->
<p-overlayPanel class="overly-filter" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">
      <div class="form__group">
        <label class="form__label">Status</label>
        <p-dropdown class="select-box filter-status" #statusDropdown [options]="callRecordingStatus" [(ngModel)]="selectedCallRecordingStatus"
                    (onChange)="onStatusChange($event)" placeholder="Status" optionLabel="label" [showClear]="true"></p-dropdown>
      </div>
      <div class="form__group">
        <label class="form__label">From</label>
        <p-calendar class="datepicker" [(ngModel)]="FromDate" [showIcon]="true" placeholder="MM/DD/YYYY" dateFormat="mm/dd/yy" [maxDate]="maxDate">
        </p-calendar>

      </div>
      <div class="form__group">
        <label class="form__label">To</label>
        <p-calendar class="datepicker" [(ngModel)]="ToDate" [showIcon]="true" placeholder="MM/DD/YYYY" dateFormat="mm/dd/yy" [maxDate]="maxDate">
        </p-calendar>
      </div>

    </div>
    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)" (click)="onClearFilterSearch()">Clear</a>
    </div>
  </div>
</p-overlayPanel>


<!-- Voice call recording popup -->
<div *ngIf="audioPlayerPopup">
<p-dialog [(visible)]="audioPlayerPopup" styleClass="display-close-icon voice-call-popup" [modal]="true" appendTo="body">
  <app-audio-player *ngIf="audioPlayerPopup" [appointmentRecordingSID]='appointmentRecordingSID' [appoitmentCallerName]='appointmentCallerName' [appoitmentCallDateTime]='appointmentCallDateTime'
  (audioPlayerPopup) ="GetChildData($event)">
  </app-audio-player> 
</p-dialog>
</div>
