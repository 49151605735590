import { Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker, MapDirectionsService } from '@angular/google-maps';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../components/base.component';
import { RoadHomeVisitHistoryRequestModel, RoadHomeVisitHistoryResponseModel } from '../models/request/roadHomeVisitHistory-request';
import { ResultOfT } from '../models/response/result';
import { UserInfoResponseModel } from '../models/response/userInfo-response-model';
import { TherapistService } from '../services/therapist.service';
@Component({
  selector: 'app-google-map-shared',
  templateUrl: './google-map-shared.component.html',
  styleUrls: ['./google-map-shared.component.sass']
})
export class GoogleMapSharedComponent extends BaseComponent implements OnInit {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  private unsubscriber = new Subject<boolean>();
  therapistInfo: RoadHomeVisitHistoryResponseModel[];
  display: any;
  loading: boolean = false;
  center: google.maps.LatLngLiteral;

  zoom = 4;
  markerPositions: google.maps.LatLngLiteral[] = [];
  circleCenter: google.maps.LatLngLiteral;
  vertices: google.maps.LatLngLiteral[];
  radius: number;
  markers = [];
  infoContent = ''
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  waypts = [];
  directionsResults: Observable<google.maps.DirectionsResult | undefined>;
  // circleOptions: google.maps.CircleOptions = {
  //   strokeColor: '#FF0000',
  //   strokeOpacity: 0.8,
  //   strokeWeight: 2,
  //   fillColor: '#FF0000',
  //   fillOpacity: 0.35,
  //   editable: false,
  //   draggable: false
  // };
  rendererOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: false
  };

  latitude: number;
  longitude: number;
  address: string;
  private geoCoder;

  constructor(private therapistService: TherapistService,
    messageService: MessageService,
    private mapDirectionsService: MapDirectionsService) {
    super(messageService);

  }

  async ngOnInit() {
    await this.getTherapist();
    this.setCurrentLocation();
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }


  openInfoWindow(marker: MapMarker, content) {
    if (this.infoWindow != undefined) {
      this.infoContent = content
      this.infoWindow.open(marker)
    }
  }


  private async getTherapist() {
    // this.therapistService.getRoadHomeHistory(Number(31), '2022-11-01')
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .subscribe((result: ResultOfT<RoadHomeVisitHistoryResponseModel[]>) => {
    //     this.processResult<RoadHomeVisitHistoryResponseModel[]>(result, () => {
    //       if (result.isSuccess) {
    //         if (result.responseModel != undefined && result.responseModel != null) {
    //           this.therapistInfo = result.responseModel;
    //           this.setTherapistPinPoint();
    //         }
    //       }
    //     });
    //   }, (httpResponseError) => {
    //     this.showErrorMessage(httpResponseError.message);
    //   });
  }

  private async setTherapistPinPoint() {
    for (let i = 0; i < this.therapistInfo.length; i++) {
      this.waypts.push({
        location: ({
          lat: Number(this.therapistInfo[i].patientAddressLat),
          lng: Number(this.therapistInfo[i].patientAddressLong),
        }),
        stopover: true
      });
      // navigator.geolocation.getCurrentPosition((position) => {
      //   let theralat = ''//parseFloat(this.therapistInfo.therapistContactInfo[0].crmAddressLat).toFixed(4);
      //   let theralng = ''//parseFloat(this.therapistInfo.therapistContactInfo[0].crmAddressLong).toFixed(4);
      //   this.center = {
      //     lat: Number(theralat),
      //     lng: Number(theralng)
      //   };
      //   //this.addTherapistMarker();
      //   //this.addPatientsMarker();
      //   //this.setWayPoints() 
      //   
      // });
    }
    this.setDirection();
  }

  addTherapistMarker() {
    this.markers.push({
      position: {
        lat: this.center.lat,
        lng: this.center.lng,
      },
      label: {
        // color: 'red',
        // text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: '',//this.therapistInfo.therapistContactInfo[0].crmAddress + ',' + this.therapistInfo.therapistContactInfo[0].crmZipCode,
      options: {
        animation: google.maps.Animation.DROP,
      },
    });
    this.circleCenter = {
      lat: this.center.lat,
      lng: this.center.lng
    };
    this.radius = 112654; //70 miles to meter
  }


  addPatientsMarker() {
    this.markers.push({
      position: {
        lat: 42.3387453,
        lng: -71.2092982,
      },
      label: {
        // color: 'red',
        // text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: '',//this.therapistInfo.therapistContactInfo[0].crmAddress + ',' + this.therapistInfo.therapistContactInfo[0].crmZipCode,
      options: {
        animation: google.maps.Animation.DROP,
      },
    });
    this.markers.push({
      position: {
        lat: 42.373611,
        lng: -71.110558,
      },
      label: {
        // color: 'red',
        // text: 'Marker label ' + (this.markers.length + 1),
      },
      title: 'Marker title ' + (this.markers.length + 1),
      info: '',//this.therapistInfo.therapistContactInfo[0].crmAddress + ',' + this.therapistInfo.therapistContactInfo[0].crmZipCode,
      options: {
        animation: google.maps.Animation.DROP,
      },
    });
  }
  setWayPoints() {
    this.waypts.push({
      location: ({
        lat: 42.3387453,
        lng: -71.2092982,
      }),
      stopover: true
    });
    this.waypts.push({
      location: ({
        lat: 42.373611,
        lng: -71.110558,
      }),
      stopover: true
    });
    this.waypts.push({
      location: ({
        lat: 42.370460,
        lng: -71.268270,
      }),
      stopover: true
    });
    this.waypts.push({
      location: ({
        lat: 42.489030,
        lng: -71.170880,
      }),
      stopover: true
    });

  }
  setDirection() {
    //this.setWayPoints();
    const request: google.maps.DirectionsRequest = {
      origin: {
        lat: 42.4961,
        lng: -71.1256
      },
      destination: {
        lat: 42.4961,
        lng: -71.1256
      },
      waypoints: this.waypts,
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: true,
      provideRouteAlternatives: true,

    };
    this.directionsResults = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    
    });
  }
}
