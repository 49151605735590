import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment-confirmation';
import { MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { fromEvent, Subject } from 'rxjs';
import { NgAddToCalendarService, ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { finalize, takeUntil } from 'rxjs/operators';
import { FeatureGroupService } from 'src/app/shared/services/seetings.service';
import { CustomSettingsResponseModel, SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { BranchInfoContactModel, SalesOrderLocalModel } from '../../shared/models/response/sales-order-current';
import { Router } from '@angular/router';
import { MouseEvent } from '@agm/core';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { PatientService } from '../services/patient.service';
import { TimezonePipe } from 'src/app/pipes/timezone.pipe';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { SettingsService } from '../services/settings.service';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import * as moment from 'moment';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';

@Component({
  selector: 'app-reschedule-appointment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.sass']
})
export class RescheduleAppointmentComponent extends BaseComponent implements OnInit, OnDestroy {

  public googleCalendarEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;

  selectedName: { name: string; code: string; };
  appointmentStartDate: string | number | Date;
  durationInMinutes: number;
  appointmentEndDate: string | number | Date;
  description: string;

  zoom: number = 8;

  RescheduleAppointment: boolean = false;
  CancelAppointment: boolean = false;
  Addtocalendar: { name: string; code: string; }[];
  patientBookingTypeId: string;
  showFacilityAddress: boolean;
  isTherapistRemoteType: any;

  loading: boolean;
  private progress = new Subject<boolean>();
  private unsubscriber = new Subject<boolean>();
  appointment: AppointmentInfoReponseModel;
  salesOrder: SalesOrderInfoResponseModel;
  scheduledApptDuration: string;
  patientName: string = "";
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  patientLatitude: number;
  patientLongitude: number;
  patientAddress: string;
  dueAmount: number;
  schAppointmentId: number;
  appointmentType: string = '';
  dateFormat: string;
  salesOrderData: SalesOrderResponseModel;
  therapistName: string = "";
  facilityName: string = "";
  facilityAddress: string = "";
  facilityLatitude: number;
  facilityLongitude: number;
  distance: string = "";
  patientTimezone: string;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  isAllowToCancelAppointment: boolean = true;
  DMENumber: string;
  markers: marker[] = [];
  bookAppointmentLabelName: string = "Book Your Appointment";
  cancelnote: string;
  ShowDueAmount: boolean = false;
  ShowDeliveryNote: boolean = false;
  appointmentTitle: string = '';
  isBranchAllowSelfScheduleAppointments: boolean = false;
  settingResult: CustomSettingsResponseModel;

  constructor(
    messageService: MessageService,
    private webStorageService: WebStorageService,
    private addToCalendarService: NgAddToCalendarService,
    private patientService: PatientService,
    private settingsService: SettingsService,
    private settingGroupService: FeatureGroupService,
    private sanitizer: DomSanitizer, private router: Router
  ) {
    super(messageService);
    this.salesOrderData = this.webStorageService.getSalesOrderData();
    this.DMENumber = this.webStorageService.getDMENumber();

  }

  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
    if (!this.DMENumber) {
      this.getDMENumber();
    }
    this.Addtocalendar = [
      { name: 'Select Calendar', code: 'S' },
      { name: 'Google', code: 'G' },
      { name: 'Yahoo', code: 'Y' },
      { name: 'Outlook', code: 'O' }
    ];

    this.schAppointmentId = this.salesOrderData?.schAppointmentId;

    this.settingResult = this.webStorageService.getPatientSalesOrderSettings();
    if (this.settingResult == undefined || this.settingResult == null) {
      this.GetPatientSalesOrdersSetings();
    }

    if (this.settingResult) {
      this.dateFormat = this.settingResult.dateFormat;
      if (this.settingResult.showDueAmount.toLowerCase() === "true")
        this.ShowDueAmount = true;
      else
        this.ShowDueAmount = false;

      if (this.settingResult.showDeliveryNote.toLowerCase() === "true")
        this.ShowDeliveryNote = true;
      else
        this.ShowDeliveryNote = false;
    }

    // this.getDateFormat();
    // this.getDueAmontVisible();
    this.isAllowToCancelAppointment = this.salesOrderData?.crmSalesorderStatus.toLowerCase() === Constants.booked ? true : false;
    if (this.isAllowToCancelAppointment)
      this.bookAppointmentLabelName = "Reschedule Appointment";
    else
      this.bookAppointmentLabelName = "Book Your Appointment";
    if (this.schAppointmentId == 0) {
      this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
      if (this.salesOrderData.patientInfo.crmPatientEmailAddress) {
        this.patientEmail = this.salesOrderData.patientInfo.crmPatientEmailAddress;
      }
      else {
        this.patientEmail = "";
      }
      if (this.salesOrderData.patientInfo.crmPatientPhoneNo) {
        this.patientPhone = this.salesOrderData.patientInfo.crmPatientPhoneNo;
      }
      else {
        this.patientPhone = "";
      }
      if (this.salesOrderData.patientInfo.crmPatientMobileNumber) {
        this.patientMobileNumber = this.salesOrderData.patientInfo.crmPatientMobileNumber;
      }
      else {
        this.patientMobileNumber = "";
      }
    }
    else {
      this.getAppointmentInfo(this.schAppointmentId);
    }
    this.getWipStatusBTmapping();
  }

  onEventClick() {

    if (this.selectedName.code === "S") {
      return;
    }
    this.newEvent = {
      title: 'Appointment booked',
      start: new Date(this.appointmentStartDate),
      duration: this.durationInMinutes,
      end: new Date(this.appointmentEndDate),
      address: this.facilityAddress,
      description: this.description
    };

    if (this.selectedName.code === "G")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.google, this.newEvent), '_blank');
    else if (this.selectedName.code === "O")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlookLive, this.newEvent), '_blank');
    else if (this.selectedName.code === "Y")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.yahoo, this.newEvent), '_blank');
    else
      return;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(["/patient/home/" + url], { queryParams: { utm_source: 'rescheduleappointment' } });
  }

  onCancel() {
    this.loadingCustom = true;
    this.CancelAppointment = false;
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.modifiedByUserInfoId = this.salesOrderData.patientInfo.crmPatientId;
    cancelRequestModel.schAppointmentId = this.salesOrderData.schAppointmentId;
    cancelRequestModel.schCancelReason = "Other";
    cancelRequestModel.schComments = this.cancelnote;

    let cancelAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    cancelAppointmentRequestBT.IsUpdateToBrightree = false;
    cancelAppointmentRequestBT.IsDeliveryNoteOverride = true;
    cancelAppointmentRequestBT.IsOrderNoteOverride = true;
    cancelAppointmentRequestBT.OrderNote = null;
    cancelAppointmentRequestBT.DeliveryNote = null;
    cancelAppointmentRequestBT.PractitionerName = null;
    cancelAppointmentRequestBT.PractitionerId = 0;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    cancelAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    cancelAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {

        cancelAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        cancelAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        cancelAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        cancelAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    cancelRequestModel.salesOrderUpdateRequestModelBT = cancelAppointmentRequestBT;
    cancelRequestModel.isCancelledByPatient = true;
    this.loading = true;
    this.patientService.CancelAppointment(cancelRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {

        if (result.responseModel) {
          this.showSuccessMessage("Appointment has been canceled.");
          this.loadingCustom = false;
          //this.router.navigate(["/patient/cancelled-appointment/"]);
          let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
          this.router.navigate(["/patient/home/" + url], { queryParams: { utm_source: 'email' } });
        }
        else {
          this.loadingCustom = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  rescheduleAppointmentBtn() {
    this.RescheduleAppointment = true;
  }

  cancelAppointmentBtn() {
    this.CancelAppointment = true;
  }

  private async getDateFormat() {
    await this.settingGroupService.getSettings(Constants.SettingKeys.dateFormat)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)

              this.dateFormat = result.responseModel.schSettingValue;
          }
        });
      });
  }

  private getAppointmentInfo(AppoinmentId) {
    this.loadingCustom = true;
    this.loading = true;
    this.progress.next(true);
    this.patientService.GetAppointmentById(AppoinmentId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentInfoReponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointment = result.responseModel;
              this.salesOrder = result.responseModel.salesOrderInfo;
              this.isBranchAllowSelfScheduleAppointments = this.appointment.crmBranchIsAllowSelfScheduleAppointments;
              this.scheduledApptDuration = this.appointment.schAppointmentDuration == 60 || this.appointment.schAppointmentDuration == 1 ? '1 Hour' : this.appointment.schAppointmentDuration.toString() + ' Minutes'
              this.patientName = this.salesOrder.patientInfo.crmPatientFirstName + " " + this.salesOrder.patientInfo.crmPatientLastName;
              if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "";
              }
              if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }
              if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }
              this.patientLatitude = this.salesOrder.patientInfo.crmLatitude;
              this.patientLongitude = this.salesOrder.patientInfo.crmLongitude;
              if (this.appointment.schIsBillingAddress)
                this.patientAddress = this.salesOrder.patientInfo.crmPatientAddress;
              else
                this.patientAddress = this.salesOrder.patientInfo.crmPatientDeliveryAddress;

              this.patientTimezone = new TimezonePipe(this.webStorageService).transform(this.appointment?.appointmentDetailInfo.crmPatientTimeZone);
              if (this.appointment.schAppointmentType != 'Telehealth') {
                this.therapistName = this.appointment?.appointmentDetailInfo?.therapistInfo?.appUserFname + ' ' + this.appointment?.appointmentDetailInfo?.therapistInfo?.appUserLname;
                this.facilityName = this.appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityName;
                this.facilityAddress = this.appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityAddress;
                this.facilityLatitude = this.appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityLatitude;
                this.facilityLongitude = this.appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityLongitude;
                this.distance = "0 Miles";

                this.markers = [
                  {
                    lat: this.facilityLatitude,
                    lng: this.facilityLongitude,
                    label: this.facilityName,
                    draggable: false,
                    type: 'F',
                    address: this.facilityAddress
                  },
                  {
                    lat: this.patientLatitude,
                    lng: this.patientLongitude,
                    label: this.patientName,
                    draggable: false,
                    type: 'P',
                    address: this.patientAddress
                  }
                ]
              }
              this.schAppointmentId = AppoinmentId;
              this.dueAmount = this.appointment?.salesOrderInfo?.amountDue;
              this.appointmentTitle = this.appointment?.schAppointmentTypeDisplayName;
              let onlineAppointmentTypeResponseModel = this.webStorageService.getOnlineAppointmentTypeNames();
              if (onlineAppointmentTypeResponseModel?.length > 0) {
                if (this.appointment?.schAppointmentTypeDisplayName == 'Facility Visit') {
                  let FacilityVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
                  if (FacilityVisit) {
                    this.appointmentTitle = FacilityVisit.crmOnlineAppointmentTitle;
                  }
                }
              }
            }
          }
          this.loading = false;
          this.loadingCustom = false;
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getDueAmontVisible() {
    await this.settingGroupService.getSettings(Constants.SettingKeys.DueAmount)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              if (result.responseModel.schSettingValue.toLowerCase() === "true")
                this.ShowDueAmount = true;
              else
                this.ShowDueAmount = false;
            }
          }
        });
      });

    let result = this.webStorageService.getPatientSalesOrderSettings();
    if (result) {
      if (result.showDueAmount.toLowerCase() === "true")
        this.ShowDueAmount = true;
      else
        this.ShowDueAmount = false;
    }

  }

  private async GetPatientSalesOrdersSetings() {
    await this.settingsService.getPatientSalesOrdersSetings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<CustomSettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              // this.LinkExpiryDays = result.responseModel.linkExpiryDays;
              // this.isBillingAddress = result.responseModel.isBillingAddress;
              // this.dateFormat = result.responseModel.dateFormat;
              // this.dtFormat = result.responseModel.dtFormat;
              // this.timeZone = result.responseModel.timeZone;
              // this.isMapView = result.responseModel.isMapView;

              // this.appDateFormat = new DateFormatModel();
              // this.appDateFormat.dateFormat = this.dateFormat;
              // this.appDateFormat.timeZone = this.timeZone;
              // this.appDateFormat.primeFormat = this.dtFormat;
              // this.webStorageService.storeDateFormat(this.appDateFormat);
              this.webStorageService.storePatientSalesOrderSettings(result.responseModel);
              this.settingResult = this.webStorageService.getPatientSalesOrderSettings();
            }
          }
        });
      });
  }

  /****** Google Map *******/
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  private async getDMENumber() {
    if (!this.salesOrderData) {
      return
    }
    await this.settingsService.getAllBranchNumbers(this.salesOrderData.crmBranchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<BranchNumbersResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.DMENumber = result.responseModel.dmephoneNumber;
              //this.DMEeMail = result.responseModel.dmeemail;
              this.webStorageService.saveDMENumber(this.DMENumber);
              var branchInfoContactModel = new BranchInfoContactModel();
              if (this.webStorageService.getBranchContactInfoById()) {
                this.webStorageService.deleteBranchContactInfoById();
              }
              branchInfoContactModel.branchid = this.salesOrderData.crmBranchId;
              branchInfoContactModel.DMEEmail = result?.responseModel?.dmeemail;
              branchInfoContactModel.DMENumber = result?.responseModel?.dmephoneNumber;
              branchInfoContactModel.DMEBranch = result?.responseModel?.branch?.crmBranchName;
              this.webStorageService.storeBranchContactInfo(branchInfoContactModel);
            }
          }
        });
      });
  }
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  type: string;
  address: string;
}
