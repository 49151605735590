import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { ReportService } from '../../servicies/report.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { SearchRequestModelReport } from 'src/app/shared/models/request/search-request-model';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';

@Component({
  selector: 'app-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.sass']
})
export class ExportReportComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  SSelectedDateRange: any;
  searchRequest: SearchRequestModelReport;
  appDateFormat: DateFormatModel;
  callocales: any;
  invalidDates: moment.Moment[] = [];

  sRanges = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Today: [moment(), moment()],
    // Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    // 'This Month': [moment().startOf('month'), moment().endOf('month')],
    // 'Next 7 Days': [moment(), moment().add(6, 'days')],
    // 'Next 30 Days': [moment(), moment().add(29, 'days')],
    // 'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  };

  tooltips = [
    { date: moment(), text: 'Today is just unselectable' },
    { date: moment().add(2, 'days'), text: 'Yeeeees!!!' },
  ];

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  datesUpdated(range): void {
    this.SSelectedDateRange = range;
  }


  constructor(messageService: MessageService,
    private ReportService: ReportService, private router: Router, private webStorageService: WebStorageService,) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.callocales = {
      format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Apply', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
    }
  }

  ngOnInit() {
  }

  onExport() {
    this.searchRequest = new SearchRequestModelReport();
    this.searchRequest.dictionaryFilter = {};
    if (this.SSelectedDateRange) {
      if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
        this.loading = true;
        let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
        let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

        if (startDate && endDate) {

          if (moment(startDate).toDate() > moment(endDate).toDate()) {
            this.showWarningMessage("From Date should not be greater than To Date.");
            this.loading = false;
            return;
          }
          if (moment(endDate).toDate() > moment(new Date()).toDate()) {
            this.showWarningMessage("End Date should not be greater than today's Date.");
            this.loading = false;
            this.OnClear();
            return;
          }
        }

        if (startDate && endDate) {
          this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

          this.searchRequest.searchFromDateString = startDate;
          this.searchRequest.searchToDateString = endDate;
        }
        this.ReportService.ExportSaleOrderHistroty(this.searchRequest)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
          .subscribe((result: Blob) => {
            const downloadedFile = new Blob([result], { type: result.type });
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            a.download = 'SaleOrderHistory.csv';
            document.body.appendChild(a);
            a.target = '_blank';
            a.href = URL.createObjectURL(downloadedFile);
            document.body.removeChild(a);
            a.click();
          }, (httpResponseMessage) => {
            this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
          });
      }
      else {
        this.loading = false;
        this.showErrorMessage("Export Date is required.");
      }
    }
  }

  OnClear() {
    this.SSelectedDateRange = null;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }

  onBack() {
    this.router.navigate(['admin/reports']);
  }

}
