<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>

    <div class="title-block__right">
      <button *ngIf="(enableWorkingHrPopup || isEditMode)" class="btn btn--primary"
        (click)="showDialogCustomeDate(facilityId,timeZone)">
        Working Hours</button>

      <button *ngIf="(!isEditMode && !enableWorkingHrPopup)" [disabled]="true" class="btn btn--primary"
        (click)="showDialogCustomeDate(facilityId,timeZone)">
        Working Hours</button>

      <a class="back-btn" href="javascript:void(0);" (click)="onBack()">
        <i class="back-new"></i> Back
      </a>
    </div>

  </div>

  <div class="form-content-outer">
    <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
    <div class="facility-info">

      <div class="therapist-profile mt-0">
        <div class="therapist-profile__avatar">
          <div class="therapist-profile__img-block">
            <div *ngIf="!isImageSaved" class="therapist-profile__img-block__img">
              <img src="assets/img/building-placeholder.svg" width="60" alt="Avatar">
            </div>

            <div *ngIf="isImageSaved" class="therapist-profile__img-block__img">
              <img [(src)]="cardImageBase64" alt="Avatar">
            </div>
          </div>

          <div class="therapist-profile__change-picture">
            <span>Change Picture</span>
            <input type="file" placeholder="Choose file" (change)="uploadLogoFile($event)">
          </div>
        </div>

        <!-- <div *ngIf="isImageSaved" class="therapist-profile__img-block__img">
          <img [(src)]="cardImageBase64" alt="Avatar">
        </div> -->
      </div>

      <div class="form-outer">
        <form class="form" [formGroup]="form" autocomplete="off">

          <div class="form__group">
            <label class="form__label">Facility Name</label>
            <input #fname type="text" formControlName="facility" class="form__control" pInputText maxlength="100" />
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.facility, 'required') || hasError(formFields.facility, 'whitespace')">{{validationMessages.facility.required}}</span>
          </div>
          <!--<span *ngIf="hasError(formFields.facility, 'validCharacters')">{{validationMessages.facility.validCharacters}}</span>-->

          <div class="form__group">
            <label class="form__label">Description<span class="msg-info"> (Max 2000 Characters)</span></label>
            <ckeditor [editor]="Editor" formControlName="bioEditorText"
              [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }"></ckeditor>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.bioEditorText, 'required') || hasError(formFields.bioEditorText, 'whitespace')">{{validationMessages.bioEditorText.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.bioEditorText, 'maxlength')">
              {{validationMessages.bioEditorText.maxlength}}
            </span>
          </div>
          <div class="col">
            <div class="form__group">
              <p-checkbox name="groupname" [(ngModel)]="remoteAssistant" label="Is Remote Setup Facility" binary="true"
                [ngModelOptions]="{standalone: true}" (click)="onRemoteAssistantSelect(remoteAssistant)">
              </p-checkbox>
              <!-- <span  class="invalid-feedback" *ngIf="hasError(formFields.room, 'whitespace') || hasError(formFields.room, 'validNumber')">{{validationMessages.zipCode.validNumber}}</span> -->
            </div>
            <div class="form__group">
              <span class="switchBtn" style="margin: 1rem 0 -2rem 2px;">
                <p-inputSwitch [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"></p-inputSwitch> {{isActive
                ? 'Active' : 'Inactive'}}
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form__group" *ngIf="!remoteAssistant">
              <label class="form__label">Address Line 1</label>
              <textarea class="form__textarea" formControlName="address" pInputTextarea maxlength="500"></textarea>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.address, 'required') || hasError(formFields.address, 'whitespace')">{{validationMessages.address.required}}</span>
            </div>
            <div class="form__group" *ngIf="!remoteAssistant">
              <label class="form__label">Address Line 2</label>
              <textarea class="form__textarea" formControlName="address2" pInputTextarea maxlength="500"></textarea>
              <!--<span class="invalid-feedback" *ngIf="hasError(formFields.address2, 'required') || hasError(formFields.address2, 'whitespace')">{{validationMessages.address.required}}</span>-->
            </div>

          </div>
          <div class="col" *ngIf="!remoteAssistant">
            <div class="form__group">
              <label class="form__label">City</label>
              <input type="text" formControlName="city" class="form__control" pInputText maxlength="100" />
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.city, 'required') || hasError(formFields.city, 'whitespace')">{{validationMessages.cpPerson.city}}</span>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.city, 'validCharacters')">{{validationMessages.cpPerson.validCharacters}}</span>
            </div>
            <div class="form__group">
              <label class="form__label">State</label>
              <p-dropdown class="select-box" formControlName="state" [(ngModel)]="selectedState" [options]="stateModel"
                placeholder="Select" optionLabel="appStateName" [showClear]="true"></p-dropdown>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.state, 'required') || hasError(formFields.state, 'whitespace')">{{validationMessages.cpPerson.state}}</span>
            </div>
          </div>
          <div class="col" *ngIf="!remoteAssistant">
            <div class="form__group">
              <label class="form__label">Zip Code</label>
              <input type="text" formControlName="zipCode" class="form__control" pInputText maxlength="6" />
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.zipCode, 'required') || hasError(formFields.zipCode, 'whitespace')">{{validationMessages.zipCode.required}}</span>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.zipCode, 'validNumber')">{{validationMessages.zipCode.validNumber}}</span>
            </div>
            <div class="form__group">
              <label class="form__label">Branch</label>
              <p-dropdown class="select-box" formControlName="branchName" [options]="branch"
                [(ngModel)]="selectedBranch" (onChange)="onBranchChange($event)" placeholder="Select"
                optionLabel="label" [showClear]="true">
              </p-dropdown>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.branchName, 'required')">{{validationMessages.branchName.required}}</span>
            </div>
          </div>
          <div class="col" *ngIf="!remoteAssistant">
            <div class="form__group">
              <label class="form__label">Contact Person First Name</label>
              <input type="text" formControlName="cpFName" class="form__control" pInputText maxlength="100" />
              <!--<span class="invalid-feedback" *ngIf="hasError(formFields.cpFName, 'required') || hasError(formFields.cpFName, 'whitespace')">{{validationMessages.cpPerson.fNameRequired}}</span>-->
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.cpFName, 'validCharacters')">{{validationMessages.cpPerson.validCharacters}}</span>
            </div>
            <div class="form__group">
              <label class="form__label">Contact Person Last Name</label>
              <input type="text" formControlName="cpLName" class="form__control" pInputText maxlength="100" />
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.cpLName, 'validCharacters')">{{validationMessages.cpPerson.validCharacters}}</span>
            </div>
          </div>
          <div class="col" *ngIf="!remoteAssistant">
            <div class="form__group">
              <label class="form__label">Contact Person Email</label>
              <input type="text" formControlName="email" class="form__control" pInputText maxlength="100" />
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.email,'email')">{{validationMessages.email.email}}</span>
            </div>
            <div class="form__group">
              <label class="form__label">Contact Person Phone Number</label>
              <p-inputMask mask="999-999-9999" formControlName="cpPhnNumber" class="form__phone" pInputText
                maxlength="15"></p-inputMask>
            </div>

          </div>
          <div class="col multibox">
            <div class="form__group">
              <label class="form__label">Doctor Exclusion</label>

              <p-autoComplete [(ngModel)]="excludedDoctor" class="autocomplete-control"
                [ngModelOptions]="{standalone: true}" [suggestions]="results" (completeMethod)="search($event)"
                [forceSelection]="true" [minLength]="1" placeholder="Doctors" field="label" [multiple]="true">
              </p-autoComplete>

            </div>
            <div class="form__group">
              <label class="form__label">Therapist Exclusion</label>

              <p-autoComplete [(ngModel)]="excludedTherapist" class="autocomplete-control"
                [ngModelOptions]="{standalone: true}" [suggestions]="resultsTherapists"
                (completeMethod)="searchTherapist($event)" [forceSelection]="true" [minLength]="1"
                placeholder="Therapists" field="label" [multiple]="true">
              </p-autoComplete>
            </div>
            <div class="form__group insurance">
              <label class="form__label">Insurance Exclusion</label>
              <p-multiSelect class="select-box" [options]="insuanceInfo" formControlName="insuanceInfo" [filter]="true"
                dataKey="label" placeholder="Select Insurance Company" optionLabel="label"
                selectedItemsLabel="{0} Insurances Selected"></p-multiSelect>

              <a class="last-box__add-btn" pTooltip="Add Insurance" href="javascript:void(0)" (click)="AddInsurance()">
                <i class="ic plus"></i>
              </a>
            </div>
            <div class="form__group" *ngIf="!remoteAssistant">
              <label class="form__label">Time Zone</label>
              <p-dropdown class="select-box" [options]="timeZoneList" formControlName="timeZone"
                [(ngModel)]="selectedTimeZone" (onChange)="onTimeZoneChange($event.value)" placeholder="Select"
                optionLabel="appStandardTimeZoneName" [showClear]="true">
              </p-dropdown>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.timeZone, 'required')">{{validationMessages.timeZone.required}}</span>
            </div>
          </div>
          <div class="col" *ngIf="isViewSelfScheduleAppointments">
            <div class="form__group">
              <label class="form__label">Self Scheduling Permission</label>
              <div style="width: 100%;" class="switchBtn">
                <p-inputSwitch [(ngModel)]="isAllowSelfScheduleAppointments"
                  [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{isAllowSelfScheduleAppointments ? 'Active' :
                'Inactive'}}
              </div>
            </div>
          </div>

          <div class="set-custom-date-btn">
            <button class="btn btn--primary" type="submit" (click)="onSubmit()">Submit</button>
            <button class="btn btn--secondary" type="button" (click)="onCancel()">Cancel</button>
          </div>


        </form>
      </div>

    </div>
  </div>


  <ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
    <div class="loader-block">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
        animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </ng-template>

  <div class="loader-block" *ngIf="loadingCustom">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
  <!-- </p-scrollPanel> -->

</div>
<!-- End Content -->


<!-- New Popup for working hours-->

<p-dialog [(visible)]="dialogCustomeDate" [modal]="true" styleClass="working-hours-popup">
  <a href="javascript:void(0)" class="popup-close" (click)="hideDialogCustomeDate()"></a>
  <div class="working-hours-popup-outer">
    <h2 *ngIf="displayFacilityName" class="working-hours-popup-outer__title">Working Hours
      <span>({{crmFacilityName}})</span></h2>
    <div class="working-hours-popup-outer__working-hours-form">
      <!-- <div class="working-hours-popup-outer__header">
        <div class="working-hours-popup-outer__header-column"></div>
        <div class="working-hours-popup-outer__header-column flex-box">
          <span class="working-hours-popup-outer__checkbox">
            <strong>All</strong>
            <p-checkbox name="groupname" value="All" [(ngModel)]="selectedValues"></p-checkbox>
          </span>
          <span class="working-hours-popup-outer__dropdown">
            <strong>Working Day</strong>
            <p-dropdown class="select-box facility-working-hours-dropdown" panelStyleClass="days-onoff" appendTo="body" [options]="Day" placeholder="Select" optionLabel="name"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffMon" (onChange)="changeDayOnOffMon()">
            </p-dropdown>
          </span>
        </div>
        <div class="working-hours-popup-outer__header-column">
          <strong>Start Time</strong>
          <p-dropdown class="select-box facility-working-hours-dropdown" panelStyleClass="facility-time" [filter]="true" appendTo="body" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>          
        </div>
        <div class="working-hours-popup-outer__header-column">
          <strong>End Time</strong>
          <p-dropdown class="select-box facility-working-hours-dropdown" panelStyleClass="facility-time" [filter]="true" appendTo="body" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
        </div>
      </div> -->

      <div class="working-hours-popup-outer__content">
        <!-- Monday Row-->
        <div class="working-hours-popup-outer__content-row" *ngFor="let item of WeekDayList">
          <div class="working-hours-popup-outer__content-column"><strong>{{item.name}}</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
              <!-- <p-checkbox name="groupname" value="Monday" [(ngModel)]="selectedValues"></p-checkbox> -->

              <p-dropdown  class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="item.isOn" (onChange)="changeDayOnOff(item)">
              </p-dropdown>
            </div>

          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [options]="item.StartTimeSelectionList" optionLabel="name"
                  [(ngModel)]="item.DayWorkingHour.fromTime" id="startTimeDropdown" placeholder="Select" optionValue="name"
                  [filter]="true" [disabled]="item.isOn?.code == 0" (onChange)="changeDayOnOff(item)"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [options]="item.EndTimeSelectionList" optionLabel="name"
                  [(ngModel)]="item.DayWorkingHour.toTime" id="startTimeDropdown" placeholder="Select" optionValue="name"
                  [filter]="true" [disabled]="item.isOn?.code == 0" (onChange)="changeDayOnOff(item)"></p-dropdown>
          </div>
        </div>
        
        <!-- <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Tuesday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Tuesday" [(ngModel)]="selectedValues"></p-checkbox>

             <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffTue" (onChange)="changeDayOnOffTue()">
            </p-dropdown>
            </div>

          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        </div>
        
        <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Wednesday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Wednesday" [(ngModel)]="selectedValues"></p-checkbox>
             
             <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffWed" (onChange)="changeDayOnOffWed()">
              </p-dropdown>
            </div>

          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        </div>
        
        <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Thursday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Thursday" [(ngModel)]="selectedValues"></p-checkbox>
             <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
              [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffThu" (onChange)="changeDayOnOffThu()">
              </p-dropdown>             
            </div>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        </div>
        
        <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Friday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Friday" [(ngModel)]="selectedValues"></p-checkbox>
              
              <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffFri" (onChange)="changeDayOnOffFri()">
              </p-dropdown>
            </div>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        </div>
        
        <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Saturday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Saturday" [(ngModel)]="selectedValues"></p-checkbox>              
              <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff" [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
                (onChange)="changeDayOnOffSat()" [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffSat">
              </p-dropdown>
            </div>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        
        </div>
        
        <div class="working-hours-popup-outer__content-row">
          <div class="working-hours-popup-outer__content-column"><strong>Sunday</strong></div>
          <div class="working-hours-popup-outer__content-column">
            <div class="checkbox-dropdown">
             <p-checkbox name="groupname" value="Sunday" [(ngModel)]="selectedValues"></p-checkbox>
             <p-dropdown class="select-box" appendTo="body" panelStyleClass="days-onoff"  [options]="Day" placeholder="Select" optionLabel="name" [showClear]="true"
                (onChange)="changeDayOnOffSun()" [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffSun">
              </p-dropdown>
            </div>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
          <div class="working-hours-popup-outer__content-column">
            <p-dropdown class="select-box" appendTo="body" panelStyleClass="facility-time" [filter]="true" [options]="Times" placeholder="Select"  optionLabel="name"></p-dropdown>
          </div>
        
        </div> -->
      </div>

      <div class="working-hours-popup-outer__button">
        <button class="btn btn--primary" type="submit" (click)="OnWorkingHoursSubmit()">Save</button>
        <button class="btn btn--secondary" type="button" (click)="hideDialogCustomeDate()">Cancel</button>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Old Popup-->
<!-- <p-dialog styleClass="working-hours-popup">
  <a href="javascript:void(0)" class="popup-close" (click)="hideDialogCustomeDate()"></a>

  <div class="search-box-outer set-custom-date-popup">
    <h2 *ngIf="displayFacilityName" class="filter-title">Working Hours : {{crmFacilityName}}</h2>
    <div class="form-outer">
      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Mon</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffMon" (onChange)="changeDayOnOffMon()">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="MonWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffMon.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="MonWorkingHours.toTime" [format]="12" [disabled]="DayOnOffMon.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>
          <span class="invalid-feedback" *ngIf="CheckTime('M')">Enter validtime range</span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Tue</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffTue" (onChange)="changeDayOnOffTue()">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="TueWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffTue.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">
            </ngx-timepicker-field>
          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="TueWorkingHours.toTime" [format]="12" [disabled]="DayOnOffTue.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">
            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('M')">Enter validtime range</span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Wed</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffWed" (onChange)="changeDayOnOffWed()">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="WedWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffWed.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="WedWorkingHours.toTime" [format]="12" [disabled]="DayOnOffWed.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('W')">
            Enter valid
            time range
          </span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Thu</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffThu" (onChange)="changeDayOnOffThu()">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="ThuWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffThu.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="ThuWorkingHours.toTime" [format]="12" [disabled]="DayOnOffThu.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">
            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('Th')">
            Enter valid
            time range
          </span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Fri</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffFri" (onChange)="changeDayOnOffFri()">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="FriWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffFri.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="FriWorkingHours.toTime" [format]="12" [disabled]="DayOnOffFri.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('F')">
            Enter valid
            time range
          </span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Sat</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            (onChange)="changeDayOnOffSat()" [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffSat">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="SatWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffSat.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="SatWorkingHours.toTime" [format]="12" [disabled]="DayOnOffSat.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('S')">
            Enter valid
            time range
          </span>
        </div>
      </div>

      <div class="working-schedule-block__row">
        <div class="working-schedule-block__column first-column">Sun</div>
        <div class="working-schedule-block__column second-column">
          <p-dropdown class="select-box" [options]="Day" placeholder="Day On" optionLabel="name" [showClear]="true"
            (onChange)="changeDayOnOffSun()" [ngModelOptions]="{standalone: true}" [(ngModel)]="DayOnOffSun">
          </p-dropdown>
        </div>
        <div class="working-schedule-block__column thrard-column">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="SunWorkingHours.fromTime" [format]="12"
              [disabled]="DayOnOffSun.code == 0" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="SunWorkingHours.toTime" [format]="12" [disabled]="DayOnOffSun.code == 0"
              minutesGap="15" [controlOnly]="true" [ngModelOptions]="{standalone: true}">
            </ngx-timepicker-field>
          </div>

          <span class="invalid-feedback" *ngIf="CheckTime('Sun')">
            Enter valid time range
          </span>
        </div>
      </div>

      <div class="working-schedule-block__row" *ngIf="false">
        <p-checkbox [(ngModel)]="includeBreakHours" binary="true" [ngModelOptions]="{standalone: true}"
          label="Break hours?"></p-checkbox>
      </div>

      <div class="working-schedule-block__row" *ngIf="false">
        <div class="working-schedule-block__column first-column" style="width: 100px;">Break Hours</div>
        <div class="working-schedule-block__column thrard-column break-hours-include">

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="breakHours.fromTime" [format]="12" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>

          </div>
          <div class="to">to</div>

          <div class="date-timer">
            <ngx-timepicker-field [(ngModel)]="breakHours.toTime" [format]="12" minutesGap="15" [controlOnly]="true"
              [ngModelOptions]="{standalone: true}">

            </ngx-timepicker-field>
          </div>

          <div *ngIf="includeBreakHours">
            <span class="invalid-feedback" *ngIf="CheckTime('B')">
              Enter valid time
              range
            </span>
          </div>
        </div>
      </div>
      <br>
      <div class="working-schedule-block__row popup-btn">
        <button class="btn btn--primary" type="submit" (click)="OnWorkingHoursSubmit()">Submit</button>
        <button class="btn btn--secondary" type="button" (click)="hideDialogCustomeDate()">Cancel</button>
      </div>
    </div>

    <div *ngIf="false" class="set-custom-date">
      <h3>Set Custom Date
        <a href="javascript:void(0)" (click)="clickEvent()">
          <i class="ic plus"></i>
        </a>
      </h3>

      <div class="set-custom-date-block">
        <div class="set-customdate" [ngClass]="CustomEvent ? 'show' : 'hide'">
          <input type="hidden" [(ngModel)]="selectedFacilityCustomAvailabilityId">
          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Event
            </div>
            <div class="set-custom-date-block__column">
              <p-dropdown class="select-box" [options]="SpecialEvent" placeholder="Select" optionLabel="name"
                [showClear]="true" [(ngModel)]="selectedSpecialEvent" [ngModelOptions]="{standalone: true}"
                dataKey="name" (onChange)="onSpecialEventChange($event)">
              </p-dropdown>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && splEventError)">{{validationMessages.customSplEvent.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Event Series
            </div>
            <div class="set-custom-date-block__column">
              <ul class=" event-series">
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="isDaily" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="isDaily ? 'active' : '' "
                    (click)="onChangeEventSeries($event.target)">Daily</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="isWeekly" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="isWeekly ? 'active' : '' "
                    (click)="onChangeEventSeries($event.target)">Weekly</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="isMonthly" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="isMonthly ? 'active' : '' "
                    (click)="onChangeEventSeries($event.target)">Monthly</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="isYearly" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="isYearly ? 'active' : '' "
                    (click)="onChangeEventSeries($event.target)">Yearly</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="once" ngDefaultControl [ngModelOptions]="{standalone: true}"
                    [ngClass]="once ? 'active' : '' " (click)="onChangeEventSeries($event.target)">Once</a>
                </li>
              </ul>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && splEventseriesError)">{{validationMessages.customSplEventSeries.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Every
            </div>
            <div class="set-custom-date-block__column">
              <p-dropdown class="select-box" [options]="Occurrence" optionLabel="name" placeholder="Select"
                [(ngModel)]="selectedOccurrence" [ngModelOptions]="{standalone: true}"
                (onChange)="onEventOccurrenceChange($event)">
              </p-dropdown>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && selectedOccurrenceError)">{{validationMessages.customOccurEvent.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Day of Week
            </div>
            <div class="set-custom-date-block__column">
              <ul class="day-of-week">
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="MonDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="MonDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Mon</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="TueDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="TueDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Tue</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="WedDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="WedDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Wed</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="ThuDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="ThuDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Thu</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="FriDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="FriDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Fri</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="SatDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="SatDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Sat</a>
                </li>
                <li>
                  <a href="javascript:void(0)" [(ngModel)]="SunDayOfWeek" ngDefaultControl
                    [ngModelOptions]="{standalone: true}" [ngClass]="SunDayOfWeek ? 'active' : '' "
                    (click)="onChangeDayOfWeek($event.target)">Sun</a>
                </li>
              </ul>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && dayofWeekError)">{{validationMessages.dayOfWeek.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Start Date
            </div>
            <div class="set-custom-date-block__column">
              <span class="datepikar">
                <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customStartDate"
                  [ngModelOptions]="{standalone: true}"></p-calendar>
              </span>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && customAvailStartDateError)">{{validationMessages.customAvailStartDate.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              End Date
            </div>
            <div class="set-custom-date-block__column">
              <span class="datepikar">
                <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customEndDate"
                  [ngModelOptions]="{standalone: true}"></p-calendar>
              </span>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && customAvailEndDateError)">{{validationMessages.customAvailEndDate.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              Start Time
            </div>
            <div class="set-custom-date-block__column">
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
              <span class="invalid-feedback" *ngIf="CheckTime('C')">Enter valid time range</span>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && customAvailStartTimeError)">{{validationMessages.customAvailStartTime.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-block__row">
            <div class="set-custom-date-block__column first-column">
              End Time
            </div>
            <div class="set-custom-date-block__column">
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
              <span class="invalid-feedback" *ngIf="CheckTime('C')">Enter valid time range</span>
              <span class="invalid-feedback"
                *ngIf="(displayFacilityName && customAvailEndTimeError)">{{validationMessages.customAvailEndTime.required}}</span>
            </div>
          </div>

          <div class="set-custom-date-btn set-btn">
            <button class="btn btn--primary mb-0" (click)="onAddCustom()">
              {{customAvailabilityTitle}}
            </button>
            <button class="btn btn--secondary mb-0" (click)="onCustomCancel()">
              Clear
            </button>
          </div>
        </div>
        <div class="addcustome-table">
          <table>
            <tr *ngFor="let customAvailability of multipleCustomAvailability; let last = last; let first = first;">
              <td>
                <strong *ngIf="customAvailability.crmRecurringFrequency == 1">Once</strong>
                <strong *ngIf="customAvailability.crmIsDaily">Daily</strong>
                <strong *ngIf="customAvailability.crmIsWeekly">Weekly</strong>
                <strong *ngIf="customAvailability.crmIsMonthly">Monthly</strong>
                <strong *ngIf="customAvailability.crmIsYearly">Yearly</strong>
              </td>
              <td>Every : {{customAvailability.crmRecurringFrequency}}</td>
              <td>
                <div *ngFor="let customday of customAvailability.crmFacilityCustomDays?.split(',')">
                  <div [ngSwitch]="customday">
                    <div *ngSwitchCase="1">Mon</div>
                    <div *ngSwitchCase="2">Tue</div>
                    <div *ngSwitchCase="4">Wed</div>
                    <div *ngSwitchCase="8">Thu</div>
                    <div *ngSwitchCase="16">Fri</div>
                    <div *ngSwitchCase="32">Sat</div>
                    <div *ngSwitchCase="64">Sun</div>
                  </div>
                </div>
              </td>
              <td>
                <div *ngIf="customAvailability.isHoliday">Holiday</div>
                <div *ngIf="customAvailability.isShutdown">Shut down</div>
                <div *ngIf="!customAvailability.isHoliday && !customAvailability.isShutdown">Working</div>
              </td>
              <td>
                {{customAvailability.crmFacilityCustomAvailStartDate | date:appDateFormat.dateFormat}}
              </td>
              <td>
                {{customAvailability.crmFacilityCustomAvailEndDate | date:appDateFormat.dateFormat }}
              </td>
              <td>
                {{customAvailability.facilityCustomAvailStartTimeDate | date: 'hh:mm a'}}
              </td>
              <td>
                {{customAvailability.facilityCustomAvailEndTimeDate | date: 'hh:mm a'}}
              </td>
              <td>
                <a class="form__vertical__table__add-btn" href="javascript:void(0)"
                  *ngIf="customAvailability.crmFacilityCustomAvailabilityId > 0"
                  (click)="onEditCustomAvailability(customAvailability)"><i class="edit-icon-new"></i></a>
                <a class="form__vertical__table__add-btn" href="javascript:void(0)"
                  (click)="onDeleteCustomAvailability(customAvailability)"><i class="delete-icon-new"></i></a>
              </td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>
</p-dialog> -->