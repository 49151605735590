import { FacilitySearchResponseModel } from 'src/app/shared/models/response/facility-search-response';
import { WipStatusBTmapping } from '../common/wip-status';
import { AppointmentConfirmation } from '../response/appointment-confirmation';
import { SalesOrderLocalModel } from '../response/sales-order-current';
import { SalesOrderResponseModel } from '../response/sales-order-response';

export class PatientBookAppointmentRequest {
    isRescheduleAppointment: boolean;  
    schAppointmentId: number;
    displaySelectedStartDate: Date;
    selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
    description: string;
    duration: string;
    therapistName: string = '';
    isTherapistRemoteType: boolean = false;
    isTherapistRemoteAndFaciltyType: boolean = false;
    isTherapistFacilityType: boolean = false;
    crmFacilityDistanceInMile: string;
    crmFacilityAddress: string;
    crmFacilityAddressLine1: string;
    crmFacilityAddressLine2: string;
    crmFacilityCity: string;
    crmFacilityState: string;
    crmFacilityCountry: string;
    crmFacilityZipCode: string;
    crmFacilityName: string;
    crmfacilityLatitude: number;
    crmfacilityLongitude: number;
    crmBioData: string;
    selectedRemoteAppointmentType: string = '';
    isProvisional: boolean;
    selectedAppointmentTypePopUp: string = '';
    selectedFacilityLocalcrmFacilityId: number = 0;
    selectedFacilityLocalcrmRemoteFacilityId: number = 0;
    selectedFacilityLocal: FacilitySearchResponseModel;
    selectedStartDate: Date;
    selectedEndDate: Date;
    selectedOrigStartDate: Date;
    selectedOrigEndDate: Date;
    selectedTherapistId: string;
    wipStatusBTMappingResponseModel: WipStatusBTmapping[];
    isBrightreeSystemAppUpdate: boolean = false;
    isUpdatePODStatusToBrightree: boolean = false;
    isAppendOrderNoteToBrightree: boolean = false;
    isDefaultWIPNeededDateAppointmentdate: boolean = false;
    patientTimeZone: string = "";
    selectedFacilityTimeZone: string = "";
    dueAmount: number;
    salesOrderLocal: SalesOrderLocalModel;
    salesOrderData: SalesOrderResponseModel;
    appointmentConfirmation: AppointmentConfirmation;
  }