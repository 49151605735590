<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="title-block__right">
      <a href="javascript:void(0);" class="filter-show-hide" (click)="QuickAction.toggle($event)" pTooltip="Actions"
         tooltipPosition="left">
        <svg width="21" height="5" viewBox="0 0 21 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>

      <p-overlayPanel #QuickAction appendTo="body" class="search-filter">
        <div class="quick-link-menu enable-disable-patient">
          <ul>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="SelectedTherapistSelfScheduleAppointmentStatus(true)">Enable Patient's Self Scheduling</a></li>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="SelectedTherapistSelfScheduleAppointmentStatus(false)">Disable Patient's Self Scheduling</a></li>
          </ul>
        </div>
      </p-overlayPanel>

      <a href="javascript:void(0);" pTooltip="Add Therapist" tooltipPosition="left" class="add-plus"
         routerLink="/admin/therapists/add"><img src="assets/img/add-plus.svg" width="17" alt="filters"></a>

      <a href="javascript:void(0);" pTooltip="Filters" tooltipPosition="right" class="filter-block filter-show-hide"
         (click)="overlyFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>

  <div class="page-scroll admin-user">
    <div class="table-outer-main therapist-table">
      <p-table #nsdl class="table-outer" [value]="therapists" responsiveLayout="scroll"
               [paginator]="totalRecords>0?true:false" sortMode="single" sortField="AppUserFname"
               [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="1" [(selection)]="selectedTherapists" selectionMode="" [rows]="getTotalRows()"
               [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn class="table-outer__th table-checkbox-column" width="30">
              <span class="table-checkbox">
                <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
              </span>
            </th>
            <th pFrozenColumn class="table-outer__th" width="200" pSortableColumn="AppUserLname">
              Therapist<p-sortIcon field="AppUserLname"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="200" pSortableColumn="CrmFacilityName">
              Facility<p-sortIcon field="CrmFacilityName"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="400">License State</th>
            <th class="table-outer__th status-column" width="100" pSortableColumn="IsActive">
              Status<p-sortIcon field="IsActive"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="200" pSortableColumn="AppUserRegistrationStatus">
              Registration
              Status<p-sortIcon field="AppUserRegistrationStatus"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="160">Patient's Self Scheduling</th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="120">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-therapist>
          <tr>
            <td pFrozenColumn class="table-outer__td table-checkbox-column" style="vertical-align: middle;">
              <p-tableCheckbox [value]="therapist"></p-tableCheckbox>
            </td>
            <td pFrozenColumn class="table-outer__td">
              <span class="link">
                <a (click)="onView(therapist.enUserInfoId)">{{therapist.appUserLname}}, {{therapist.appUserFname}}</a>
              </span>
            </td>
            <td class="table-outer__td">{{therapist.crmFacilityName}}</td>
            <td class="table-outer__td status-column">{{therapist.crmState}}</td>
            <td class="table-outer__td">
              <span [ngClass]="therapist.isActive ? 'user-active' : 'user-inactive'">{{therapist.isActive ? 'Active' : 'Inactive'}}</span>
            </td>
            <td class="table-outer__td">{{therapist.appUserRegistrationStatus}}</td>
            <td class="table-outer__td">
              <span class="switchBtn">
                <p-inputSwitch id="{{therapist.crmIsAllowSelfScheduleAppointments}}"
                               [(ngModel)]="therapist.crmIsAllowSelfScheduleAppointments"
                               (onChange)="handleTherapistSelfScheduleAppointmentStatusChange(therapist.appUserInfoId,$event)">
                </p-inputSwitch>
              </span>
            </td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)"
                   (click)="selectTherapist($event,therapist,therapistsAction)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>

              <p-overlayPanel appendTo="body" class="table-action-popup therapistsAction NotScheduledAction"
                              #therapistsAction>
                <ul class="quick-link-menu">
                  <li>
                    <a href="javascript:void(0)" class="edit-row" (click)="onEdit(selectedTherapist.enUserInfoId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                           viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                                d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                                transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                                d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                                transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit
                    </a>
                  </li>
                  <li *ngIf="selectedTherapist?.isActive">
                    <a href="javascript:void(0)" class="delete-row" (click)="onDelete(selectedTherapist.enUserInfoId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                                d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                                fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                                fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                                d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                                fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                                d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                                d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                                d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="history-row" (click)="onViewHistory(selectedTherapist.enUserInfoId)">
                      <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
                        <path d="M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7c2,0,3.9,0.9,5.2,2.3l-1.8,0.3c-0.5,0.1-0.9,0.6-0.8,1.2c0.1,0.5,0.5,0.8,1,0.8
              c0.1,0,0.1,0,0.2,0l4.2-0.8c0.5-0.1,0.9-0.6,0.8-1.2L20,3.5c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5,0.1-0.9,0.6-0.8,1.2l0.3,1.7
              C16.6,4,14.4,3,12,3C8.7,3,5.8,4.8,4.2,7.5c0,0,0,0,0,0c0,0,0,0,0,0C3.4,8.9,3,10.4,3,12s0.4,3.1,1.2,4.5c0,0,0,0,0,0c0,0,0,0,0,0
              C5,17.9,6.1,19,7.5,19.8c0,0,0,0,0,0c0,0,0,0,0,0C8.9,20.6,10.4,21,12,21c0.4,0,0.8-0.3,0.9-0.6C13,20.3,13,20.1,13,20
              c0-0.1,0-0.3-0.1-0.4C12.8,19.3,12.4,19,12,19z" />
                        <circle cx="20" cy="12" r="1" />
                        <circle cx="18.9" cy="16" r="1" transform="rotate(-60 18.928 16)" />
                        <circle cx="16" cy="18.9" r="1" transform="rotate(-30 15.999 18.929)" />
                        <path d="M13,11.6V9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.3,0.1,0.5,0.3,0.7l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
              c0.4-0.4,0.4-1,0-1.4L13,11.6z" />
                      </svg>
                      History
                    </a>
                  </li>
                </ul>
              </p-overlayPanel>

            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="!loading && totalRecords <= 0">
            <tr>
              <td class="no-records-found-therapist" [attr.colspan]="6">
                {{noRecordsFoundMessage}}
              </td>

            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>

      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>

  </div>
</div>


<p-overlayPanel class="overly-filter" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">
      <div class="form__group">
        <label class="form__label">Search</label>
        <div class="search-box">
          <input type="text" class="form__control" pInputText placeholder="Therapist" #therapistBox style="width:auto"
                 (keyup.enter)="onSearchButtonClick()">
          <button *ngIf="therapistBox.value !=''" class="clear-btn" type="button" (click)="therapistBox.value =''">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button">
            <i class="search-new"></i>
          </button>
        </div>
      </div>
      <div class="form__group" *ngIf="false">
        <label class="form__label">Branch</label>
        <p-dropdown class="select-box filter-status" [options]="branchResponseModel" [(ngModel)]="selectedbranch"
                    id="branchDropdown" placeholder="Select Branch" optionLabel="crmBranchName" [showClear]="true"
                    (onChange)="onBranchChange($event)"></p-dropdown>
      </div>
      <div class="form__group">
        <label class="form__label">Status</label>
        <p-dropdown class="select-box filter-status" #statusDropdown [options]="therapistStatus" placeholder="Status"
                    [(ngModel)]="selectedStatus" optionLabel="label" [showClear]="true" (onChange)="onStatusChange($event)">
        </p-dropdown>
      </div>
    </div>

    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
         (click)="onClearFilterSearch();onFilterSearch();">Clear</a>
    </div>
  </div>
</p-overlayPanel>

<p-dialog [(visible)]="ConfirmToChangeSelfScheulingStatus" styleClass="display-close-icon" [modal]="true" appendTo="body" [closable]="false">
  <div class="popup-content-outer confirm-invite-patients">
    <div>
      <div *ngIf="linkedFacilitiesWithCurrentTherapistCount > 0">
        <h2>Therapist(s) Not Allowed for Patient's Self Scheduling</h2>
        <div class="confirm-invite-patients__content-part" *ngIf="isEnableSelfSchedulingForSelected">
          <p>You have selected {{selectedTherapistsCount}} Therapist(s), however you can't enable Patient's Self Scheduling for selected Therapist(s) because the linked Facility(es) of selected Therapist(s) are not enabled for Patient's Self Scheduling yet.</p>
          <p><strong>Please enable below mentioned Facility(es) first to enable Patient's Self Scheduling for Therapist(s).</strong></p>
        </div>

        <div class="popup-table-outer">
          <table class="custom-table">
            <thead>
              <tr>
                <th>Facility Name</th>
                <th>Therapist Name</th>
              </tr>
            </thead>
            <tbody *ngFor="let linkedFacilitiesWithCurrentTherapist of linkedFacilitiesWithCurrentTherapistActiveDetails">
              <tr>
                <td>{{linkedFacilitiesWithCurrentTherapist.linkedFacilities}}</td>
                <td>{{linkedFacilitiesWithCurrentTherapist.appUserLname | titlecase}}, {{linkedFacilitiesWithCurrentTherapist.appUserFname | titlecase}}</td>
              </tr>
            </tbody>

          </table>
        </div>

        <div class="confirm-invite-patients__buttons-box">
          <a href="javascript:void(0)" class="btn btn--primary" (click)="CancelSelfSchedulingChanges(true)">Ok</a>
        </div>
      </div>

      <div *ngIf="linkedFacilitiesWithCurrentTherapistCount == 0">
        <h2>Confirmation of Patient's Self Scheduling</h2>
        <div class="confirm-invite-patients__content-part" *ngIf="!isEnableSelfSchedulingForSelected">
          <p>Are you sure you want to disable Patient's Self Scheduling for Therapist(s)?</p>
        </div>
        <div class="confirm-invite-patients__content-part" *ngIf="isEnableSelfSchedulingForSelected">
          <p>Are you sure you want to enable Patient's Self Scheduling for Therapist(s)?</p>
        </div>

        <div class="confirm-invite-patients__buttons-box">
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="CancelSelfSchedulingChanges(true)">No, Cancel</a>
          <button class="btn btn--primary" (click)="ConfirmSelfSchedulingChanges()">Yes, Confirm</button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
