import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { PatientService } from '../services/patient.service';
import { SettingsService } from '../services/settings.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { PatientBookAppointmentRequest } from 'src/app/shared/models/request/patient-book-appointment-request-model';
import { takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';

@Component({
  selector: 'app-patient-appointment-booking',
  templateUrl: './patient-appointment-booking.component.html',
  styleUrls: ['./patient-appointment-booking.component.sass']
})
export class PatientAppointmentBookingComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  patientName: string='';
  appDateFormat: DateFormatModel;
  value: number = 16;
  patientBookingTypeId: string = '';
  salesOrderData: SalesOrderResponseModel;
  appointmentTitle: string;
  appointmentSelectLabel: string;
  activeStepNo: number=1;  
  patientBookAppointmentRequest: PatientBookAppointmentRequest;
  @Input() isAppoitmentBooked:boolean=false;
  isFacilityOrTherapistSelectionChanged: boolean=false;
  
  constructor(private patientService: PatientService, private appointmentService: AppointmentService,
    private router: Router, messageService: MessageService, private _cdr: ChangeDetectorRef,
    private settingService: SettingsService, private webStorageService: WebStorageService,) { 

    this.appDateFormat = this.webStorageService.getDateFormat()
    this.patientBookingTypeId=this.webStorageService.getPatientBookingTypeId();

    this.salesOrderData = this.webStorageService.getSalesOrderData();
    this.patientName = this.salesOrderData?.patientInfo?.crmPatientFirstName + " " + this.salesOrderData?.patientInfo?.crmPatientLastName;
    
    let onlineAppointmentTypeResponseModel = this.webStorageService.getOnlineAppointmentTypeNames();

    if (onlineAppointmentTypeResponseModel?.length > 0) {
      if(this.patientBookingTypeId=="R"){
        let RemoteSetup = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
        if (RemoteSetup) {
          this.appointmentTitle = RemoteSetup.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Remote';
        }
      }
      else if(this.patientBookingTypeId=="T"){
        let TherapistVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
        if (TherapistVisit) {
          this.appointmentTitle = TherapistVisit.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Therapist';
        }
      }
      else if(this.patientBookingTypeId=="SG"){
        let SelfGuided = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
        if (SelfGuided) {
          this.appointmentTitle = SelfGuided.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Self Guided';
        }
      }
      else if(this.patientBookingTypeId=="F"){
        let FacilityVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
        if (FacilityVisit) {
          this.appointmentTitle = FacilityVisit.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Clinic';
        }
      }
    }
    this.activeStepNo=1;
  }

  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    if(this.salesOrderData.schAppointmentId==0)
      this.router.navigate(['patient/home/' + url]);
    else
    this.router.navigate(["/patient/home/" + url],{ queryParams: { utm_source: 'rescheduleappointment' } });
  }


  onNext(){

  }

  GetChildData(data){
    this.activeStepNo=data;
  }

  GetAppoitmentBookRequest(data){
    this.patientBookAppointmentRequest=data;
  }

  GetFacilityOrTherapistSlectionChangeRequest(data){
    this.isFacilityOrTherapistSelectionChanged=data;
    this._cdr.detectChanges();
  }
}
