// export class BellNotificationInAppResponseModel {
//   appBellNotificationInAppId: number;
//   appBellNotificationInAppDetailsId: number;
//   appBellNotificationDate: Date;
//   appBellNotificationType: string;
//   appBellNotificationSubType: string;
//   branchId: number;
//   appNotificationMsg: string;
//   bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummary[];
// }

export class BellNotificationInAppResponseModel {
  appBellNotificationInAppId: number;
  appBellNotificationDate: Date;
  bellNotificationInAppDetail: BellNotificationInAppDetailResponseModel[];
}

export class BellNotificationInAppDetailResponseModel {
  appBellNotificationInAppDetailsId: number;
  appBellNotificationInAppId: number;
  appBellNotificationType: string;
  appBellNotificationSubType: string;
  branchId: number;
  appNotificationMsg: string;
  appShortName: string;
  appFirstName: string;
  appLastName: string;
  appTotalSyncRecords: number;
  appBellNotificationTime: Date;
  appIsRead: boolean;
  bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[];
}

export class BellNotificationInAppDetailsSummaryResponseModel{
  appBellNotificationInAppDetailsSummaryId: number;
  appBellNotificationInAppDetailsId: number;
  appBellNotificationInAppId: number;
  appSyncHistoryId: number;
  appSalesOrderId: string;
  appSalesOrderAppId: string;
  schAppointmentId: number;
  appPatientId: number=0;
  appTherapistFirstName: string;
  appTherapistLastName: string;
  appAppointmentStatus: string;
  appIsDeliveryAddressConfigured: boolean;
  appIsPhoneNoConfigured: boolean;
  appIsEmailIdConfigured: boolean;
  appIsClssificationDetailsConfigured: boolean;
  appIsBranchDetailsConfigured: boolean;
  appNotificationSendId: number;
  appNotificationType: string;
  appNotificationEnabled: boolean;
}

export class ImportSalesOrderByIds {
    salesOrderId: string;
    status: string;
    branchId: number;
    appUserinfoid: number=0;
    modifiedByUserInfoId: number=0;
    dateTimeCreated: Date;
    dateTimeLastModified: Date;
    appSyncHistoryId: number=0;
    appSalesOrderAppId: string;
    appPatientId: number=0;
    isPatientDeliveryAddressConfigured: boolean;
    isPatientPhoneNoConfigured: boolean;
    isPatientEmailIdConfigured: boolean;
    isClassificationDetailsConfigured: boolean;
    isBranchDetailsConfigured: boolean;
    appUserFullName: string;
  }

  export class BellNotificationInAppReadStatusRequestModel{
    appBellNotificationInAppId:number;
    appUserInfoId: number;
    appIsRead: boolean;
    modifiedByUserInfoId: number;
  }

  export class BellNotificationRequestModel{
    userInfoId:number;
    userPermissions: string;
    branchId: number;
    isWeekView: boolean;
  }
