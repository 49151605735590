import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Dropdown } from 'primeng/dropdown';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { HolidayService } from 'src/app/admin/servicies/holiday.service';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';


@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.sass'],
  providers: [DialogService]
})
export class HolidayComponent extends BaseComponent implements OnInit {
  @ViewChild('eMedAssistDropdown') eMedAssistDropdown: Dropdown;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  statusBtnTitle: string = "Add";
  enableToAddHoliday: boolean;
  loggedInUserId: number;
  holidayList: HolidayResponse[];
  filterHolidayList: HolidayResponse[];
  selectedHolidayModel: HolidayResponse;
  selectedHolidayYear: QuickFilters;
  filterSelectedHolidayYear: QuickFilters;
  selectedToHolidayYear: QuickFilters;
  year: QuickFilters[];
  filterYear: QuickFilters[];
  toYear: QuickFilters[];
  appDateFormat: DateFormatModel;
  noRecordFound: boolean = false;
  displayHolidayPopup: boolean = false;
  isOveride: boolean = false;
  copyHolidayList: HolidayResponse[] = [];
  selectedHolidayList: number[] = [];
  FillHolidayYear: QuickFilters;
  formFields = {
    eMedAssistStatus: 'eMedAssistStatus',
    brightreeStatus: 'brightreeStatus',
    displayName: 'displayName',

  };

  validationMessages = {
    eMedAssistStatus: {
      required: 'Appoint360 Status is required',
      // validCharacters: 'Enter valid eMedAssist Status'
    },
    brightreeStatus: {
      required: 'Brightree Status is required',
      // validCharacters: 'Enter valid brightree status'
    },
    displayName: {
      required: 'Display name is required',
      validCharacters: 'Enter valid display name'
    },

  };
  constructor(
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    messageService: MessageService,
    private router: Router,
    private holidayService: HolidayService,
    private dialougeService: DialogService,
  ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }

  get fStatus() { return (<FormGroup>this.form.get('formHoliday')).controls; }



  get formHoliday() {
    return this.form.get('formHoliday');
  }
  ngOnInit() {
    this.getHolidays();
    this.buildForm();
  }

  StatusInfoButton() {
    this.statusBtnTitle = "Add";
    this.enableToAddHoliday = !this.enableToAddHoliday;
  }

  private buildForm() {
    const formHolidayGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formHolidayGroup.addControl(this.formFields.eMedAssistStatus, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // tslint:disable-next-line: max-line-length
    formHolidayGroup.addControl(this.formFields.brightreeStatus, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // tslint:disable-next-line: max-line-length
    formHolidayGroup.addControl(this.formFields.displayName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formHoliday: formHolidayGroup,
    });
  }

  onCheck(status) {
    this.isOveride = status.checked;
  }

  onFilterYearChange(event) {
    if (event.value) {
      this.filterHolidayList = this.holidayList.filter(where => where.appHolidayYear == parseInt(event.value.code));
      this.loading = true;
      this.holidayService.GetHolidaysByYear(event.value.code)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<HolidayResponse[]>) => {
          this.processResult<any>(result, () => {
            this.copyHolidayList = result.responseModel;
            this.setFromToYear(Number(event.value.code));
          });
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  onYearSelection(event) {
    if (event.value) {
      this.loading = true;

      this.toYear = new Array<QuickFilters>()
      this.toYear = this.year.filter(x => x.code > event.value.code);

      if (event.value.code > this.selectedToHolidayYear.code) {
        let _YearValue = Number(this.selectedToHolidayYear.code) - 1;
        this.FillHolidayYear = new QuickFilters();
        this.FillHolidayYear.code = _YearValue.toString();
        this.FillHolidayYear.name = _YearValue.toString();
        this.selectedHolidayYear = { ... this.FillHolidayYear };

        this.showErrorMessage('From Year Should Be Less Than To Year');
        return;
      }

      this.holidayService.GetHolidaysByYear(event.value.code)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<HolidayResponse[]>) => {
          this.processResult<any>(result, () => {
            this.copyHolidayList = result.responseModel;
          });
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  onToYearSelection(event) {
    if (event.value.code < this.selectedHolidayYear.code) {
      let _YearValue = Number(this.selectedHolidayYear.code) + 1;
      this.FillHolidayYear = new QuickFilters();
      this.FillHolidayYear.code = _YearValue.toString();
      this.FillHolidayYear.name = _YearValue.toString();
      this.selectedToHolidayYear = { ... this.FillHolidayYear };
      this.showErrorMessage('To Year Should Be Greater Than From Year');
      return;
    }
  }

  checkAllCheckBox(ev) {
    var list = new Array<number>();
    if (ev.checked) {
      var that = this;
      this.copyHolidayList.forEach(function (value) {
        list.push(value.appHolidayId);
      });
    }
    this.selectedHolidayList = list;
  }

  isAllCheckBoxChecked() {
    //return this.selectedHolidayList.every(p => p.checked);
  }

  onChangeCheckBoxIndividual(event, item) {
  }

  onCopyPreviousYearHoliday() {
    if (!this.selectedHolidayYear || !this.selectedToHolidayYear) {
      this.showErrorMessage('From year and To year is required');
      return;
    }
    else if (this.selectedHolidayList.length <= 0) {
      this.showErrorMessage('Select holidays from list');
      return;
    }
    else if (this.selectedHolidayYear.code > this.selectedToHolidayYear.code) {
      this.showErrorMessage('From Year Should Be Less Than To Year');
      return;
    }
    else if (this.selectedToHolidayYear.code < this.selectedHolidayYear.code) {
      this.showErrorMessage('To Year Should Be Greater Than From Year');
      return;
    }

    this.loading = true;
    this.holidayService.CopyPreviousYearHoliday(parseInt(this.selectedHolidayYear.code), parseInt(this.selectedToHolidayYear.code), this.isOveride, this.selectedHolidayList.toString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<HolidayResponse[]>) => {
        this.processResult<any>(result, () => {
          this.holidayList = result.responseModel;
          this.filterHolidayList = this.holidayList.filter(where => where.appHolidayYear == new Date().getFullYear());
          this.prepareFilterYearDropdown();
          this.prepareYearDropdown();
          this.noRecordFound = this.holidayList.length == 0 ? true : false;
          this.displayHolidayPopup = false;
          this.showSuccessMessage('Holidays copied successfully');
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getHolidays() {
    this.loading = true;
    await this.holidayService.GetAllHolidays()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<HolidayResponse[]>) => {
        this.processResult<any>(result, () => {
          this.holidayList = result.responseModel;
          this.filterHolidayList = this.holidayList.filter(where => where.appHolidayYear == new Date().getFullYear());
          this.noRecordFound = this.filterHolidayList.length == 0 ? true : false;
          this.prepareFilterYearDropdown();
          this.prepareYearDropdown();
          if (this.filterSelectedHolidayYear != undefined) {
            this.setFromToYear(Number(this.filterSelectedHolidayYear.code));
            this.fillHolidaysList(Number(this.filterSelectedHolidayYear.code));
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  prepareFilterYearDropdown() {
    if (this.holidayList) {
      var sortHolidayList = this.holidayList.sort((a, b) => (a.appHolidayYear > b.appHolidayYear) ? 1 : ((b.appHolidayYear > a.appHolidayYear) ? -1 : 0));
      var year: number = sortHolidayList[0].appHolidayYear;
      var currentYear: number = sortHolidayList[sortHolidayList.length - 1].appHolidayYear;

      this.filterYear = new Array<QuickFilters>()
      for (let i = year; i <= currentYear; i++) {
        var item = new QuickFilters();
        item.name = i.toString();
        item.code = i.toString();
        this.filterYear.push(item);

        if (i == new Date().getFullYear())
          this.filterSelectedHolidayYear = item;
      }
    }
  }

  prepareYearDropdown() {
    if (this.holidayList) {
      var sortHolidayList = this.holidayList.sort((a, b) => (a.appHolidayYear > b.appHolidayYear) ? 1 : ((b.appHolidayYear > a.appHolidayYear) ? -1 : 0));
      var year: number = sortHolidayList[0].appHolidayYear;
      var currentYear: number = sortHolidayList[sortHolidayList.length - 1].appHolidayYear;

      this.year = new Array<QuickFilters>();
      for (let i = year; i <= (currentYear + 1); i++) {
        var item = new QuickFilters();
        item.name = i.toString();
        item.code = i.toString();
        this.year.push(item);
      }
      this.toYear = new Array<QuickFilters>()
      this.toYear = this.year;
    }
  }

  onStatusEdit(holiday: HolidayResponse) {
    this.statusBtnTitle = "Update";
    this.formHoliday.reset();
    this.selectedHolidayModel = holiday;
    this.setValuesToStatusForm();
  }

  private async setValuesToStatusForm() {
    if (!this.selectedHolidayModel) {
      return;
    }
    const form = this.formHoliday;
    this.selectedHolidayYear = { name: this.selectedHolidayModel.appHolidayYear.toString(), code: this.selectedHolidayModel.appHolidayYear.toString() };

    this.year.push(this.selectedHolidayYear);
    this.eMedAssistDropdown.options = this.year;

    form.get(this.formFields.displayName).setValue(this.selectedHolidayModel.appHolidayName);
  }

  onEdit(appHolidayId: number) {
    this.router.navigate(['/admin/settings/application/holiday/edit/', appHolidayId], { state: { searchRequestModel: this.selectedHolidayModel } });
  }

  onDelete(appHolidayId: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.holidayService.DeleteHoliday(appHolidayId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Holiday deleted successfully');
            this.getHolidays();
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });

  }

  fillHolidaysList(year: number) {
    this.filterHolidayList = this.holidayList.filter(where => where.appHolidayYear == year);
    this.loading = true;
    this.holidayService.GetHolidaysByYear(year)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<HolidayResponse[]>) => {
        this.processResult<any>(result, () => {
          this.copyHolidayList = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  setFromToYear(year: number) {
    let _nextYear = year + 1;
    this.selectedHolidayYear = { name: year.toString(), code: year.toString() };
    this.selectedToHolidayYear = { name: _nextYear.toString(), code: _nextYear.toString() };

    this.toYear = new Array<QuickFilters>()
    this.toYear = this.year.filter(x => x.code > this.selectedHolidayYear.code);
  }

  clearSelection() {
    this.selectedHolidayList = [];
  }

  SetValuesWithLIstonPopup() {
    this.setFromToYear(Number(this.filterSelectedHolidayYear.code));
    this.fillHolidaysList(Number(this.filterSelectedHolidayYear.code));
    this.selectedHolidayList = [];
  }
}
