import { UserBranchResponseModel } from './user-branch-response-model';
import { UserPermissionResponseModel } from './user-permission-response-model';
import { UserRoleResponseModel } from './user-role-response-model';

export class UserResponseModel {
  appUserInfoId: number;
  appUserEmail: string;
  appUserFname: string;
  appUserLname: string;
  appUserPhoneNumber: string;
  appUserpassword: string;
  appUserIsReset: boolean;
  appUserStatus: boolean;
  appUserPasswordExpiry: Date;
  dateTimeUserLastLogin: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  appIsLoggedIn: boolean;
  message: string;
  // userBranch: UserBranchResponseModel;
  userRole: UserRoleResponseModel;
  appRoleName: string;
  appRoleId: number;
  crmBranchName: string;
  crmBranchId: number;
  appPermissionName: string;
  appPermissionId: number;
  appUserRegistrationStatus: string;
  crmBrightreeUserId: number;
  appUserOldPassword: string;
  userBranch: UserBranchResponseModel[];
  userRoles: UserRoleResponseModel[];
  userPermission: UserPermissionResponseModel[];
  enAppUserInfoId: string;
}
