enum EnumRequestIdentifier {
  None,
  SalesOrderRequest,
  PatientRequest,
  AppointmentRequest,
  FacilityRequest,
  TherapistRequest,
}

interface Dictionary<T> {
  [Key: string]: T;
}

export class SearchRequestModel {
  crmBranchId: number;
  appUserInfoId: number;
  enumRequestIdentifer: EnumRequestIdentifier;
  SortByColumnName: string;
  SortOrder: string;
  IsAscendingOrder: boolean;
  PageSize: number;
  CurrentPageIndex: number;
  FirstRecordIndex: number;
  searchFromDate: Date;
  searchToDate: Date;
  totalRecords: number;
  dictionaryFilter: Dictionary<string> = {};
  selectedTimeZone: string;
  isFromDashboard: boolean = false;
  isUnreconciled: boolean = false;
  isUpcoming: boolean = false;
  selectedPrimaryInsurance: string;
  crmPatientId: number | 0;
  selectedClassificationType: string;
  AppointmentStatus: string;
  BookingSearchFromDate: Date;
  BookingSearchToDate: Date;
  SearchRequestFromDate: Date;
  SearchRequestToDate: Date;
  isRescheduled: boolean = false;
  scheduledBy: string;
  isTodaysBooking: boolean = false;
  BookingSearchFromDateString: string
  BookingSearchToDateString: string
  DisplayTitle: string;
  enappUserInfoId: string;

}

export class SearchRequestModelForNearByPatients {
  crmFacilityId: number;
  searchText: string;
  crmTherapistId: number;
  isStartFromDefaultFacility: boolean;
  isStartFromHomeAddress: boolean;
  appointmentDate: string;
  isBillingAddress: boolean;
  selectedApptType: string;
}

export class SearchRequestModelForReport {
  crmBranchId: number;
  appUserInfoId: number;
  enumRequestIdentifer: EnumRequestIdentifier;
  sortByColumnName: string;
  sortOrder: string;
  isAscendingOrder: boolean;
  pageSize: number;
  currentPageIndex: number;
  firstRecordIndex: number;
  searchFromDate: Date;
  searchToDate: Date;
  dictionaryFilter: Dictionary<string> = {};
  noOfCalls: number;
}

export class SearchRequestModelDashboard {
  facilityId: number;
  month: number;
  appUserInfoId: number;
  branchId: number;
  searchFromDate: Date;
  searchToDate: Date;
  branchIds: number[];
  asOfDate: Date;
}

export class SearchRequestModelReport {
  appointmentStatus: string;
  isUnreconciled: boolean;
  branchId: number[];
  appointmentTypeId: number[];
  appointmentBookedById: number[];
  therapistId: number;
  sortByColumnName: string;
  sortOrder: string;
  isAscendingOrder: boolean;
  pageSize: number;
  currentPageIndex: number;
  firstRecordIndex: number;
  searchFromDate: Date;
  searchToDate: Date;
  searchFromDateString: string;
  searchToDateString: string;
  searchFromApptDate: Date;
  SearchToApptDate: Date;
  searchFromApptDateString: string;
  searchToApptDateString: string;
  searchFromDate2String: string;
  searchToDate2String: string;
  dictionaryFilter: Dictionary<string> = {};
  selectedTimeZone: string[];
  selectedReconsileTypeId: number[];
  selectedClassificationType: number[];
  processType: string;
  // numberOfCalls: number;
}

export class ProvisionalSearchRequestModel {
  patientID: number;
  firstName: string;
  lastName: string;
  dob: string;
  PageSize: number;
  CurrentPageIndex: number;
  FirstRecordIndex: number;
}
