import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { SearchRequestModel, SearchRequestModelForReport, SearchRequestModelReport } from 'src/app/shared/models/request/search-request-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { FailedToSyncSalesOrderReport, ReportSummaryResponseModel } from 'src/app/shared/models/response/report-summary-response-model';
import { AppointmentTypeResponseModel } from 'src/app/shared/models/response/appointment/appointment-Type-response-model';
import { BehaviorSubject } from 'rxjs';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { AppointmentHistoryResponseModel } from '../../shared/models/response/appointment/appointment-history-response-model';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
@Injectable({
  providedIn: 'root'
})
export class ReportService extends BaseService {
  private ReportTitle = new BehaviorSubject('');
  currentTitle = this.ReportTitle.asObservable();
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getAllAppointmentType() {
    return this.get<ResultOfT<AppointmentTypeResponseModel[]>>('AppointmentType/GetAllAppointmentType');
  }

  getScheduledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetScheduledAppointments', searchRequestModel);
  }

  getCancelledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetCancelledAppointments', searchRequestModel);
  }

  getNoShowAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetNoShowAppointments', searchRequestModel);
  }

  getAllUsers() {
    return this.get<ResultOfT<UserResponseModel[]>>('Users/GetAllUsers');
  }

  GetVoidAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetVoidAppointments', searchRequestModel);
  }
  getCompletedAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetCompletedAppointments', searchRequestModel);
  }
  getUnreconciledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetUnreconciledAppointments', searchRequestModel);
  }
  ExportAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportAppointments', searchRequestModel);
  }

  ExportRescheduledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportRescheduledAppointments', searchRequestModel);
  }
  ExportAwaitingAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportAwaitingAppointments', searchRequestModel);
  }

  ExportVoidAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportVoidAppointments', searchRequestModel);
  }
  getAwaitingAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetAwaitingAppointments', searchRequestModel);
  }


  getAllMissingEmailPhoneNumberSalesOrders(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetAllMissingEmailPhoneNumberSalesOrders', searchRequestModel);
  }

  ExportGetAllMissingEmailPhoneNumberSalesOrders(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportGetAllMissingEmailPhoneNumberSalesOrders', searchRequestModel);
  }


  getRescheduledAppointments(searchRequestModel: SearchRequestModelReport) {
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetRescheduledAppointments', searchRequestModel)
  }


  // scheduledAppointments(searchRequestModel: SearchRequestModel) {
  //   // tslint:disable-next-line: max-line-length
  //   return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetPagedListBookedAppointments', searchRequestModel);
  // }

  // cancelledAppointments(searchRequestModel: SearchRequestModel) {
  //   // tslint:disable-next-line: max-line-length
  //   return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetPagedListCancelledAppointments', searchRequestModel);
  // }

  // completedAppointments(searchRequestModel: SearchRequestModel) {
  //   // tslint:disable-next-line: max-line-length
  //   return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Resort/GetPagedListCompletedAppointments', searchRequestModel);
  // }

  // awaitingAppointment(searchRequestModel: SearchRequestModelForReport) {
  //   // tslint:disable-next-line: max-line-length
  //   return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetPagedListAwaitingAppointments', searchRequestModel);
  // }

  changeTitle(title: string) {
    this.ReportTitle.next(title);
  }

  getBookedAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetBookedAppointments', searchRequestModel);
  }
  getVoiceCallLog(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<AppointmentHistoryResponseModel>>>('Report/GetVoiceCallLog', searchRequestModel);
  }

  ExportVoiceCallLog(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportGetVoiceCallLog', searchRequestModel);
  }
  ExportBookedAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportBookedAppointments', searchRequestModel);
  }

  GetReconciledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>>('Report/GetReconciledAppointments', searchRequestModel);
  }

  ExportReconciledAppointments(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportReconciledAppointments', searchRequestModel);
  }

  ExportSaleOrderHistroty(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('Report/ExportSaleOrderHistory', searchRequestModel);
  }

  ExportCalenderWithFacility(slotDuration: string, fromDate: string, toDate: string, crmFacilityiIds: string) {
    var url = 'Report/ExportCalenderWithFacility?slotDuration=' + slotDuration;

    if (fromDate) {
      url = url + '&fromDate=' + fromDate;
    }
    if (toDate) {
      url = url + '&toDate=' + toDate;
    }
    if (crmFacilityiIds) {
      url = url + '&crmFacilityiIds=' + crmFacilityiIds;

    }
    
    return this.downloadCsvFile(url);
  }

  ExportCalenderWithTherapist(fromDate: string, toDate: string, crmTherapistsId: string) {
    var url = 'Report/ExportCalenderWithTherapist?fromDate=' + fromDate;
    if (toDate) {
      url = url + '&toDate=' + toDate;
    }
    if (crmTherapistsId) {
      url = url + '&crmTherapistsId=' + crmTherapistsId;

    }
    
    return this.downloadCsvFile(url);
  }

  getfailedtoSyncSalesOrders(searchRequestModel: SearchRequestModelReport) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<PagedResponseModel<FailedToSyncSalesOrderReport>>>('Report/GetFailedToAutoSyncSalesOrder', searchRequestModel);
  }

  ExportFailedToAutoSyncSalesOrder(searchRequestModel: SearchRequestModelReport) {
    return this.downloadCsvFile('Report/ExportFailedToAutoSyncSalesOrder', searchRequestModel);
  }
}
