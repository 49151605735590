import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Subject } from 'rxjs';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserService } from 'src/app/admin/servicies/user.service';
import { ResultOfT } from 'src/app/shared/models/response/result';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.sass']
})
export class UserDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private userId: string;
  title = 'User Detail';
  searchRequestModel: SearchRequestModel;
  userDetail: UserResponseModel;
  toggleLabel: string = '+ More';

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              messageService: MessageService) {
    super(messageService);
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
  }
  ngOnDestroy(){
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
     }
  }

  ngOnInit() {
    this.userDetail = new UserResponseModel();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.userId = params['id'];
        this.getUserDetail();
      });
  }

  onEdit() {
    this.router.navigate(['/admin/users/edit/', this.userId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack() {
    this.router.navigate(['admin/users'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  BranchName: boolean = false;
  BranchNameclickEvent(){
      this.BranchName = !this.BranchName;
      this.toggleLabel = this.BranchName === false ? '+ More' : '- Less';
  }

  private getUserDetail() {
    if (!this.userId) {
      return;
    }
    this.loading = true;
    this.userService.GetUserById(this.userId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserResponseModel>) => {
        this.processResult<UserResponseModel>(result, () => {
          this.userDetail = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
