import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { AppointmentDetailComponent } from './appointments/appointment-detail/appointment-detail.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ViewByDateComponent } from './calendar/view-by-date/view-by-date.component';
import { UsersComponent } from './users/users.component';
import { UserManageComponent } from './users/user-manage/user-manage.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { PatientComponent } from './patients/patient.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { DoctorManageComponent } from './doctors/doctor-manage/doctor-manage.component';
import { DoctorDetailComponent } from './doctors/doctor-detail/doctor-detail.component';
import { TherapistsComponent } from './therapists/therapists.component';
import { TherapistDetailComponent } from './therapists/therapist-detail/therapist-detail.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { FacilityManageComponent } from './facilities/facility-manage/facility-manage.component';
import { FacilityDetailComponent } from './facilities/facility-detail/facility-detail.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { SettingsComponent } from './settings/settings.component';
import { ApplicationComponent } from './settings/application/application.component';
import { PluginsComponent } from './settings/plugins/plugins.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { SearchResultsComponent } from '../admin/search-results/search-results.component';
import { UniquePipe } from 'src/app/pipes/unique.pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { TableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog'
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule as inlineCalendar } from 'primeng/calendar';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { PatientInfoDialogueComponentComponent } from './appointments/patient-info-dialogue-component/patient-info-dialogue-component.component';
import { SalesOrderDetailComponent } from './appointments/sales-order-detail/sales-order-detail.component';
import { SalesorderCallLogsComponent } from './appointments/salesorder-call-logs/salesorder-call-logs.component';
import { AgmCoreModule } from '@agm/core';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SpinnerModule } from 'primeng/spinner';
import { GeneralComponent } from './settings/general/general.component'
import { OnlineBookingComponent } from './settings/application/online-booking/online-booking.component';
import { AppointmentComponent } from './settings/application/appointment/appointment.component';
import { SmsComponent } from './settings/plugins/sms/sms.component';
import { FtpComponent } from './settings/plugins/ftp/ftp.component';
import { VoiceCallComponent } from './settings/plugins/voice-call/voice-call.component';
import { VoiceMailComponent } from './settings/plugins/voice-mail/voice-mail.component';
import { CrmAppComponent } from './settings/plugins/crm-app/crm-app.component';
import { TherapistComponent } from './settings/notifications/therapist/therapist.component';
import { StaffComponent } from './settings/notifications/staff/staff.component'
import { EditorModule } from 'primeng/editor';
import { ViewAppointmentByTherapistModule } from 'src/app/admin/calendar/therapist-day-view-scheduler/module';
import { FileUploadModule } from 'primeng/fileupload';

import { AppoinmentCallListComponent } from './appointments/appoinment-call-list/appoinment-call-list.component';
import { MyChangePasswordComponent } from './users/my-change-password/my-change-password.component';
import { MyProfileComponent } from './users/my-profile/my-profile.component'
import { AdminComponent } from './AdminComponent';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { TwillioCallService } from 'src/app/admin/servicies/twillioCall.service';
import { ViewAvailabilityComponent } from './therapists/view-availability/view-availability.component';
import { ManageAvailabilityComponent } from './therapists/manage-availability/manage-availability.component';
import { ViewTherapistAvailabilityComponent } from './calendar/view-therapist-availability/view-therapist-availability.component';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AppointmentConfirmDialogComponent } from './calendar/appointment-confirm-dialog/appointment-confirm-dialog.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MatTableModule } from '@angular/material/table';
import { BrightreeNewComponent } from './settings/brightree-new/brightree-new.component';
import { InputMaskModule } from 'primeng/inputmask';
import { MatSortModule } from '@angular/material/sort';
import { AppointmetsCancelledComponent } from './appointments/appointmets-cancelled/appointmets-cancelled.component';
import { PermissionsNewComponent } from './settings/application/permissions-new/permissions-new.component';

import { SyncRcmSalesorderComponent } from './import-export/sync-rcm-salesorder/sync-rcm-salesorder.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ManagePermissionsComponent } from './settings/application/permissions-new/manage-permissions/manage-permissions.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ClassificationComponent } from './settings/brightree-new/classification/classification.component';
import { CancelVoidReasonsComponent } from './settings/brightree-new/cancel-void-reasons/cancel-void-reasons.component';
import { AddInsuranceDialogComponent } from './facilities/add-insurance-dialog/add-insurance-dialog.component';
import { TherapistManageNewComponent } from './therapists/therapist-manage-new/therapist-manage-new.component';
import { ReminderSettingComponent } from './settings/notifications/reminder-setting/reminder-setting.component';
import { HolidayComponent } from './settings/application/holiday/holiday.component';
import { HolidayManageComponent } from './settings/application/holiday/holiday-manage/holiday-manage.component';
import { BookAppointmentInfoComponent } from './book-appointment-info/book-appointment-info.component';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmAppointmentComponent } from './book-appointment-info/confirm-appointment/confirm-appointment.component';
import { AppointmentThankyouComponent } from './book-appointment-info/appointment-thankyou/appointment-thankyou.component';
import { SyncSalesorderByidComponent } from './import-export/sync-salesorder-byid/sync-salesorder-byid.component';
import { BranchComponent } from './settings/brightree-new/branch/branch.component';
import { SalesOrderHistoryComponent } from './appointments/sales-order-history/sales-order-history.component';
import { ReimportSalesorderByidComponent } from './import-export/reimport-salesorder-byid/reimport-salesorder-byid.component';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { OnBoardingComponent } from './settings/on-boarding/on-boarding.component';
import { VoiceCallRecordingsComponent } from './voice-call-recordings/voice-call-recordings.component';
import { ProductService1 } from './voice-call-recordings/productservice1';
import { BulkUploadComponent } from './voice-call-recordings/bulk-upload/bulk-upload.component';
import { ExportReportComponent } from './reports/export-report/export-report.component';
import { AppointmentNoshowComponent } from './appointments/appointment-noshow/appointment-noshow.component';
import { AppointmentCancelOrderComponent } from './appointments/appointment-cancel-order/appointment-cancel-order.component';

import { SearchPatientBrightreeDetailComponent } from './search-patient-brightree-detail/search-patient-brightree-detail.component';

import { PatientNoteComponent } from './settings/application/patient-note/patient-note.component';

import { TimeslotAvailabilitySummaryComponent } from './reports/timeslot-availability-summary/timeslot-availability-summary.component';
import { ViewAllNotificationsComponent } from './view-all-notifications/view-all-notifications.component';

import {DragDropModule} from '@angular/cdk/drag-drop';



import { GoogleMapsModule } from '@angular/google-maps'
import { WeekViewTherapistModule } from './calendar/week-by-therapist-scheduler/module';
import { AppointmentUrgentCallbackComponent } from './appointments/appointment-urgent-callback/appointment-urgentcallback.component'

import { AutosyncSalesorderComponent } from './reports/autosync-salesorder/autosync-salesorder.component';


import { KpiReportComponent } from './reports/kpi-report/kpi-report.component'
import { TherapistAuditLogsComponent } from './therapists/therapist-audit-logs/therapist-audit-logs.component';
import { TodayDashboardComponent } from './dashboard/today-dashboard/today-dashboard.component'
import { TodaysDashboardListingComponent  } from '../admin/todays-dashboard-listing/todays-dashboard-listing.component';


import { ViewTherapistAvailabilityV1Component } from './calendar/view-therapist-availability-v1/view-therapist-availability-v1.component';
import { ViewAppointmentByTherapistV1Module } from './calendar/therapist-day-view-scheduler-v1/module-v1';
import { WeekViewTherapistV1Module } from './calendar/week-by-therapist-scheduler-v1/module-v1';



@NgModule({
  declarations: [AdminComponent, DashboardComponent, AppointmentsComponent, AppointmentDetailComponent, CalendarComponent,
    ViewByDateComponent,UsersComponent, UserManageComponent, UserDetailComponent, DoctorsComponent, PatientComponent,
    DoctorManageComponent, DoctorDetailComponent, TherapistsComponent,TherapistDetailComponent, FacilitiesComponent,
    FacilityManageComponent, FacilityDetailComponent, ImportExportComponent,
    ReportsComponent, ReportDetailComponent, SettingsComponent, ApplicationComponent,
    PluginsComponent, NotificationsComponent, SearchResultsComponent,
    PatientInfoDialogueComponentComponent, SalesOrderDetailComponent, SalesorderCallLogsComponent,
    GeneralComponent,OnlineBookingComponent, AppointmentComponent,
    SmsComponent, FtpComponent, VoiceCallComponent, VoiceMailComponent, CrmAppComponent, TherapistComponent,
    StaffComponent, UniquePipe, AppoinmentCallListComponent, MyChangePasswordComponent, MyProfileComponent, AppointmentConfirmDialogComponent,
    ViewAvailabilityComponent, ManageAvailabilityComponent, ViewTherapistAvailabilityComponent, BrightreeNewComponent, AppointmetsCancelledComponent, PermissionsNewComponent, 
    SyncRcmSalesorderComponent, ManagePermissionsComponent, ClassificationComponent, CancelVoidReasonsComponent,
    AddInsuranceDialogComponent, TherapistManageNewComponent, ReminderSettingComponent,
    HolidayComponent, HolidayManageComponent, BookAppointmentInfoComponent, ConfirmAppointmentComponent,
    AppointmentThankyouComponent, SyncSalesorderByidComponent, BranchComponent, SalesOrderHistoryComponent, ReimportSalesorderByidComponent, OnBoardingComponent, VoiceCallRecordingsComponent, 
    BulkUploadComponent, ExportReportComponent, AppointmentNoshowComponent, AppointmentCancelOrderComponent,SearchPatientBrightreeDetailComponent,PatientNoteComponent, TimeslotAvailabilitySummaryComponent,
    KpiReportComponent,
    TodayDashboardComponent,TodaysDashboardListingComponent,
    ViewAllNotificationsComponent,AppointmentUrgentCallbackComponent, AutosyncSalesorderComponent,
    ViewTherapistAvailabilityV1Component, 
    TherapistAuditLogsComponent,
    

    

 ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    DropdownModule,
    SliderModule,
    MultiSelectModule,
    ScrollPanelModule,
    CheckboxModule,
    MenuModule,
    ButtonModule,
    OverlayPanelModule,
    DialogModule,
    DynamicDialogModule,
    TooltipModule,
    RadioButtonModule,
    CalendarModule,
    EditorModule,
    PanelMenuModule,
    InputSwitchModule,
    inlineCalendar,
    AccordionModule,
    AutoCompleteModule,
    SpinnerModule,
    ViewAppointmentByTherapistModule,
    FileUploadModule,
    SelectButtonModule,
    InputMaskModule,
    CKEditorModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgAddToCalendarModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDk2D-JTREqWQ_RwnCtkwUTeX81Q_D8hig',
      libraries: ['places']
    }),
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Okay',
      firstDay: 3,
    }),
    MatTableModule,
    NgxMaterialTimepickerModule,
    SidebarModule,

    PipesModule,
    GoogleMapsModule,


    DragDropModule,
    WeekViewTherapistModule,
    ViewAppointmentByTherapistV1Module,
    WeekViewTherapistV1Module

  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule,
    TabViewModule,
    TableModule,
    DropdownModule,
    SliderModule,
    MultiSelectModule,
    ScrollPanelModule,
    CheckboxModule,
    MenuModule,
    ButtonModule,
    OverlayPanelModule,
    DialogModule,
    DynamicDialogModule,
    TooltipModule,
    RadioButtonModule,
    CalendarModule,
    PanelMenuModule,
    InputSwitchModule,
    inlineCalendar,
    AccordionModule,
    AutoCompleteModule,
    FileUploadModule,
    EditorModule,
    SelectButtonModule,
    InputMaskModule,

    MatSortModule,
    SidebarModule,
    DragDropModule
  ],
  providers: [UniquePipe, GlobalSearchService, TwillioCallService,ProductService1],
  entryComponents:
    [
      PatientInfoDialogueComponentComponent,
      SalesorderCallLogsComponent,
      
      AppointmentConfirmDialogComponent,
      AddInsuranceDialogComponent,
      BookAppointmentInfoComponent
    ]
})
export class AdminModule { }
