import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
export class AppointmentRequestModel {
  SchAppointmentId: number;
  SchAppointmentTypeId: number;
  CrmSalesorderId: number;
  SchAppointmentDateStartTime: Date;
  SchAppointmentDateEndTime: Date;
  SchAppointmentOrigDateStartTime: Date;
  SchAppointmentOrigDateEndTime: Date;
  SchAppointmentDateStartTimeString: string;
  SchAppointmentDateEndTimeString: string;
  SchAppointmentOrigDateStartTimeString: string;
  SchAppointmentOrigDateEndTimeString: string;
  CrmFacilityId: number;
  CrmRemoteFacilityId: number = 0;
  CrmTherapistId: number;
  CrmPatientPhoneNo: string;
  CrmPatientEmailAddress: string;
  CrmPatientId: number;
  IsAppointmentBookedByPatient: boolean;
  SchAppointmentBookedBy: number;
  SchReminderOpted: boolean;
  isBrightreeSystemAppUpdate: boolean;
  SchComments: string;
  SchAppointmentStatus: number;
  SchAppointmentTypeName: string;
  PatientTimeZone: string;
  SchIsBillingAddress: boolean;
  SchRemoteAppointmentType: string;
  salesOrderUpdateRequestModelBT: BTSalesOrderUpdateRequestModel;
  CrmFacilityTimeZone: string;
  CrmFacilityName: string;
  AppointmentDuration: string;
  CrmTherapistName: string;
  AmountDue: number;
  btNoteId: number | 0;
  isProvisional: boolean;
  SchSmscommunicationType: string;
  SchSmscommunicationNumber: string;
  SchCommunicationEmailAddress: string;
  SchIsReminderOptedSms: boolean;
  SchIsReminderOptedEmail: boolean;
}
