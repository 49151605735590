<div class="cd-popup confirm-availability-outer" role="alert">
  <div class="cd-popup-container text-center confirm-availability"
    *ngIf="dynamicDialogConfig.data.action == 'Add' || dynamicDialogConfig.data.action == 'Edit' || dynamicDialogConfig.data.action == 'Validation'">
    <h2 class="confirm-availability__title-box">
      <svg class="alert-icon" *ngIf="dynamicDialogConfig.data.action == 'Validation'|| dynamicDialogConfig.data.ErrorCode == '6'
      || dynamicDialogConfig.data.isAllReccuringDatesMatched" width="26" height="22" viewBox="0 0 26 22" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 22.0003L12.5295 0.358398L25.0591 22.0003H0ZM3.92972 19.7222H21.1294L12.5295 4.9146L3.92972 19.7222ZM12.5295 18.5832C12.8523 18.5832 13.123 18.4738 13.3417 18.2551C13.5604 18.0364 13.6693 17.7661 13.6686 17.4441C13.6686 17.1214 13.5592 16.8511 13.3405 16.6331C13.1218 16.4152 12.8515 16.3058 12.5295 16.3051C12.2068 16.3051 11.9365 16.4144 11.7185 16.6331C11.5006 16.8518 11.3913 17.1222 11.3905 17.4441C11.3905 17.7669 11.4998 18.0376 11.7185 18.2563C11.9372 18.475 12.2076 18.5839 12.5295 18.5832ZM11.3905 15.166H13.6686V9.47079H11.3905V15.166Z"
          fill="#525252" />
      </svg>
      {{dynamicDialogConfig.data.popupHeader}}
    </h2>

    <div class="confirm-availability__content-box">

      <p class="confirm-availability__availability-has-already"
        *ngIf="dynamicDialogConfig.data.action != 'Validation' && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.popupHeader != 'Confirm Edit Event' && dynamicDialogConfig.data.ErrorCode != '6'">
        Availability has already been configured during the
        selected period.</p>
      <p class="confirm-availability__between-selected-period"
        *ngIf="(appointemntCount > 0 || blockCount > 0 || customCounts > 0) && dynamicDialogConfig.data.action != 'Validation' && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.ErrorCode != '6'">
        Between selected period, the following event(s) are planned, and any actions executed will not be applicable to
        the
        specified dates.</p>
      <!-- <p class="confirm-availability__between-selected-period"
        *ngIf="dynamicDialogConfig.data.isAllReccuringDatesMatched">Between selected period, the following events are
        planned.</p> -->
      <div class="confirm-availability__table-block"
        *ngIf="(appointemntCount > 0 || blockCount > 0 || customCounts > 0) && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '6'">

        <div class="confirm-availability__table-box">
          <table class="confirm-availability__custom-tables">
            <thead>
              <tr>
                <th class="confirm-availability__th">Event Type</th>
                <th class="confirm-availability__th count">Count</th>
                <th class="confirm-availability__th">Dates</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="appointemntCount > 0">
                <td class="confirm-availability__td">Appointment(s)</td>
                <td class="confirm-availability__td count">{{appointemntCount}}</td>
                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{appointemntDatesStr }}
                    <p *ngIf="isAppointmentMore">...</p>
                    <!-- pTooltip="{{appointemtnDates.join (',') }}"  -->
                    <strong *ngIf="isAppointmentMore"
                      (click)="appointemtnDatesMoreDates.toggle($event)">+{{appointemtnDates.length}}
                      more</strong>

                    <p-overlayPanel class="table-action-popup" styleClass="more-action" #appointemtnDatesMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of appointemtnDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
              <tr *ngIf="blockCount > 0">
                <td class="confirm-availability__td">Block Slot(s)</td>
                <td class="confirm-availability__td count">{{blockCount}}</td>

                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{blockSlotDateStr }}
                    <p *ngIf="isBlockMore">...</p>
                    <!-- pTooltip="{{blockSlotDates.join (',') }}" -->
                    <strong *ngIf="isBlockMore"
                      (click)="blockSlotDatesMoreDates.toggle($event)">+{{blockSlotDates.length}}
                      more</strong>

                    <p-overlayPanel class="table-action-popup " styleClass="more-action" #blockSlotDatesMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of blockSlotDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
              <tr *ngIf="customCounts > 0">
                <td class="confirm-availability__td">Custom Availability(s)</td>
                <td class="confirm-availability__td count">{{customCounts}}</td>

                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{customDateStr }}
                    <p *ngIf="isCustomMore">...</p>
                    <!-- pTooltip="{{customDates.join (',') }}" -->
                    <strong *ngIf="isCustomMore" (click)="customDatesMoreDates.toggle($event)">+{{customDates.length}}
                      more</strong>

                    <p-overlayPanel class="table-action-popup " styleClass="more-action" #customDatesMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of customDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="confirm-availability__table-block"
        *ngIf="previousAvailabilityReponses && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.ErrorCode != '6'">
        <h3>Existing Availability(s)</h3>
        <div class="confirm-availability__table-box">
          <table class="confirm-availability__custom-tables">
            <thead>
              <tr>
                <th class="confirm-availability__th">Availability Type</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Confirm Edit Event'">Date</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader != 'Confirm Edit Event'">Date Range</th>
                <th class="confirm-availability__th">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let avail of previousAvailabilityReponses">
                <td class="confirm-availability__td">{{avail?.appointmentType}}</td>
                <td class="confirm-availability__td">{{avail?.dateRange}}
                  <span class="weekdays">{{avail?.selectedDays}}</span>
                </td>
                <td class="confirm-availability__td">{{avail?.time}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="confirm-availability__table-block"
        *ngIf="currentAvailabilityReponses && dynamicDialogConfig.data.action == 'Edit' && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.ErrorCode != '6'">
        <h3>Proposed Availability</h3>
        <div class="confirm-availability__table-box">
          <table class="confirm-availability__custom-tables">
            <thead>
              <tr>
                <th class="confirm-availability__th">Availability Type</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Confirm Edit Event'">Date</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader != 'Confirm Edit Event'">Date Range</th>
                <th class="confirm-availability__th">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="confirm-availability__td">{{currentAvailabilityReponses?.appointmentType}}</td>
                <td class="confirm-availability__td">{{currentAvailabilityReponses?.dateRange}}
                  <span class="weekdays">{{currentSelectedWeekDays}}</span>
                </td>
                <td class="confirm-availability__td">{{currentAvailabilityReponses?.time}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <p *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation'
      && !dynamicDialogConfig.data.isAllReccuringDatesMatched">You have option to manage new availability by choosing
        any of the following actions:</p>
      <p class="confirm-availability__merge-with-existing" *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation' 
        && dynamicDialogConfig.data.ErrorCode != '6' && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <strong>Merge with Existing:</strong> Choose this option if you wish to combine new availability with the
        existing ones & create new availability(s).
      </p>
      <p class="confirm-availability__override-existing" *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation'
      && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <strong>Override Existing:</strong> Choose this option if you wish to replace the existing availability(s) with
        a new one.
      </p>
      <p class="confirm-availability__override-existing" *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation'
      && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <strong>Note:</strong> If break hours(s) in the existing availability overlap with the new availability, it will be removed and replaced with new break hour(s)
        according to new/proposed availability(s).
      </p>
      <p *ngIf="dynamicDialogConfig.data.action == 'Edit' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' 
        && dynamicDialogConfig.data.ErrorCode != '6' && !dynamicDialogConfig.data.isAllReccuringDatesMatched
        && dynamicDialogConfig.data.popupHeader == 'Confirm Edit Event'">
        Upon confirmation, the existing availability will be removed as per proposed availability.</p>

      <p *ngIf="dynamicDialogConfig.data.action == 'Edit' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' 
        && dynamicDialogConfig.data.ErrorCode != '6' && !dynamicDialogConfig.data.isAllReccuringDatesMatched
        && dynamicDialogConfig.data.popupHeader != 'Confirm Edit Event'">
        Upon confirmation, the existing availability will be updated/removed as per the proposed availability.</p>

      <p *ngIf="dynamicDialogConfig.data.action == 'Edit' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' 
        && dynamicDialogConfig.data.ErrorCode != '6' && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        Would you
        like to proceed?</p>
      <p class="confirm-availability__notes" *ngIf="(dynamicDialogConfig.data.action == 'Add' || dynamicDialogConfig.data.action == 'Edit')
        && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' && (appointemntCount > 0 || blockCount > 0 || customCounts > 0)
        && !dynamicDialogConfig.data.isAllReccuringDatesMatched && dynamicDialogConfig.data.ErrorCode != '6'">
        <strong>Note:</strong> Modification to the current availability is permitted only for days without any booked
        appointment(s), block slot(s), or custom availability(s).
      </p>
      <p class="confirm-availability__between-selected-period"
        *ngIf="(dynamicDialogConfig.data.action == 'Validation' || dynamicDialogConfig.data.isAllReccuringDatesMatched) && !dynamicDialogConfig.data.isBreak">
        During the selected period, appointments/block slots are added for each day. Therefore, the system will not
        allow to perform this action.
      </p>
      <p class="confirm-availability__between-selected-period"
        *ngIf="dynamicDialogConfig.data.action == 'Validation' && dynamicDialogConfig.data.isBreak">
        The selected break hours are conflicting with the existing appointment time or block slots. 
        Therefore, the system will not allow to perform this action.
      </p>
      <p class="confirm-availability__between-selected-period" *ngIf="dynamicDialogConfig.data.ErrorCode == '6'">
        The proposed availability is overlapping the existing availability(s), the system will not allow to perform this action.
      </p>
      <p *ngIf="dynamicDialogConfig.data.ErrorCode == '6'">
        We recommend you to reschedule or delete existing event(s) & try again.
      </p>
      <p *ngIf="dynamicDialogConfig.data.action == 'Validation' || dynamicDialogConfig.data.isAllReccuringDatesMatched">
        It is recommended to try again once the existing appointment(s) are rescheduled and block slot(s) are deleted.
      </p>

      <p class="confirm-availability__notes" *ngIf="dynamicDialogConfig.data.ErrorCode == '5'">
        <strong>Note:</strong> Either the 'Road/Home Visit' has already been configured, or you are currently attempting
        to configure it for the chosen period. The 'Merge with existing' functionality is not available in this case. We
        recommend either overriding the current availability or deleting/updating the existing availability before
        attempting the configuration again.
      </p>
    </div>



    <div class="cd-buttons">
      <button class="btn btn--secondary mr-2" (click)="onNo()">{{dynamicDialogConfig.data.cancelButtonText}}</button>
      <div
        *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <button class="btn btn--primary mr-2" (click)="OnMerge()">{{dynamicDialogConfig.data.updateButtonText}}</button>
      </div>
      <div *ngIf="dynamicDialogConfig.data.action == 'Edit' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5' 
        && dynamicDialogConfig.data.ErrorCode != '6' && !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <button class="btn btn--primary mr-2"
          (click)="OnUpdate()">{{dynamicDialogConfig.data.updateButtonText}}</button>
      </div>
      <div
        *ngIf="dynamicDialogConfig.data.action == 'Add' && dynamicDialogConfig.data.action != 'Validation' && dynamicDialogConfig.data.ErrorCode != '5'&& !dynamicDialogConfig.data.isAllReccuringDatesMatched">
        <button class="btn btn--primary mr-2" (click)="onOverRide()">Override Existing</button>
      </div>
      <div *ngIf="dynamicDialogConfig.data.ErrorCode == '5'">
        <button class="btn btn--primary mr-2" (click)="RoadHomeOverRide()">Override Existing</button>
      </div>
    </div>
  </div>


  <div class="cd-popup-container text-center confirm-availability" *ngIf="dynamicDialogConfig.data.action == 'Delete'">
    <h2 class="confirm-availability__title-box">
      <svg class="alert-icon" *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Alert'" width="26" height="22"
        viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 22.0003L12.5295 0.358398L25.0591 22.0003H0ZM3.92972 19.7222H21.1294L12.5295 4.9146L3.92972 19.7222ZM12.5295 18.5832C12.8523 18.5832 13.123 18.4738 13.3417 18.2551C13.5604 18.0364 13.6693 17.7661 13.6686 17.4441C13.6686 17.1214 13.5592 16.8511 13.3405 16.6331C13.1218 16.4152 12.8515 16.3058 12.5295 16.3051C12.2068 16.3051 11.9365 16.4144 11.7185 16.6331C11.5006 16.8518 11.3913 17.1222 11.3905 17.4441C11.3905 17.7669 11.4998 18.0376 11.7185 18.2563C11.9372 18.475 12.2076 18.5839 12.5295 18.5832ZM11.3905 15.166H13.6686V9.47079H11.3905V15.166Z"
          fill="#525252" />
      </svg>

      {{dynamicDialogConfig.data.popupHeader}}
    </h2>

    <div class="confirm-availability__content-box">
      <!-- <p>{{dynamicDialogConfig.data.message}}</p> -->
      <!-- <p>Availability has already been configured during the specified period.</p> -->
      <p class="confirm-availability__between-selected-period"
        *ngIf="(appointemntCount > 0 || blockCount > 0 || customCounts > 0) && dynamicDialogConfig?.data?.popupHeader != 'Alert'">
        Between selected period, the following event(s) are planned and any actions executed will not be applicable to
        the specified dates.</p>
      <!-- <p class="confirm-availability__between-selected-period"
        *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Alert'">Between selected period, the following events are
        planned.</p> -->
      <div class="confirm-availability__table-block"
        *ngIf="(appointemntCount > 0 || blockCount > 0 || customCounts > 0) && dynamicDialogConfig?.data?.popupHeader != 'Alert'">
        <div class="confirm-availability__table-box">
          <table class="confirm-availability__custom-tables">
            <thead>
              <tr>
                <th class="confirm-availability__th">Event Type</th>
                <th class="confirm-availability__th count">Count</th>
                <th class="confirm-availability__th">Dates</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="appointemntCount > 0">
                <td class="confirm-availability__td">Appointment(s)</td>
                <td class="confirm-availability__td count">{{appointemntCount}}</td>
                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{appointemntDatesStr }}
                    <p *ngIf="isAppointmentMore">...</p>

                    <!-- pTooltip="{{appointemtnDates.join(',')}}" -->
                    <strong *ngIf="isAppointmentMore"
                      (click)="appointemntCountMoreDates.toggle($event)">+{{appointemtnDates.length}}
                      more</strong>
                    <p-overlayPanel class="table-action-popup" styleClass="more-action" #appointemntCountMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of appointemtnDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
              <tr *ngIf="blockCount > 0">
                <td class="confirm-availability__td">Block Slot(s)</td>
                <td class="confirm-availability__td count">{{blockCount}}</td>

                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{blockSlotDateStr }}
                    <p *ngIf="isBlockMore">...</p>
                    <!-- pTooltip="{{blockSlotDates.join(',')}}" -->
                    <strong *ngIf="isBlockMore"
                      (click)="blockSlotDatesMoreDates.toggle($event)">+{{blockSlotDates.length}}
                      more</strong>

                    <p-overlayPanel class="table-action-popup" styleClass="more-action" #blockSlotDatesMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of blockSlotDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
              <tr *ngIf="customCounts > 0">
                <td class="confirm-availability__td">Custom Availability(s)</td>
                <td class="confirm-availability__td count">{{customCounts}}</td>

                <td class="confirm-availability__td">
                  <div class="more-dates">
                    {{customDateStr }}
                    <p *ngIf="isCustomMore">...</p>
                    <!-- pTooltip="{{customDates.join(',')}}"  -->
                    <strong *ngIf="isCustomMore" (click)="customDatesMoreDates.toggle($event)">+{{customDates.length}}
                      more</strong>

                    <p-overlayPanel class="table-action-popup" styleClass="more-action" #customDatesMoreDates
                      [dismissable]="true">
                      <ul class="quick-link-menu">
                        <li class="quick-link-menu__item" *ngFor="let item of customDates">{{item}}</li>
                      </ul>
                    </p-overlayPanel>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="confirm-availability__table-block" *ngIf="dynamicDialogConfig?.data?.updateButtonText?.length > 0">
        <h3>Existing Availability</h3>
        <div class="confirm-availability__table-box">
          <table class="confirm-availability__custom-tables">
            <thead>
              <tr>
                <th class="confirm-availability__th">Availability Type</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Confirm Delete Event'">Date</th>
                <th class="confirm-availability__th"
                  *ngIf="dynamicDialogConfig?.data?.popupHeader != 'Confirm Delete Event'">Date Range</th>
                <th class="confirm-availability__th">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="confirm-availability__td">{{dynamicDialogConfig.data?.appointmentType}}</td>
                <td class="confirm-availability__td">{{dynamicDialogConfig.data?.dateRange}}
                  <span class="weekdays">{{previousSelectedWeekDays}}</span>
                </td>
                <td class="confirm-availability__td">{{dynamicDialogConfig.data?.time}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <p *ngIf="dynamicDialogConfig?.data?.updateButtonText?.length > 0">Upon confirmation, the existing availability
        will be deleted. Would you like to proceed?</p>
      <p class="confirm-availability__between-selected-period"
        *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Alert'">During the selected period, appointments/block slots
        are added for each day. Therefore, the system will not allow to perform this action.
      </p>
      <p *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Alert'">
        It is recommended to try again once the existing appointment(s) are rescheduled and block slot(s) are deleted.
      </p>
      <p class="confirm-availability__notes" *ngIf="(appointemntCount > 0 || blockCount > 0 || customCounts > 0) &&
        dynamicDialogConfig?.data?.updateButtonText?.length > 0">
        <strong>Note:</strong> The system allows changes to the existing availability for the days without any booked
        appointment(s), block slot(s), or custom availability(s).
      </p>
    </div>



    <div class="cd-buttons">
      <button class="btn btn--secondary mr-2" (click)="onNo()">{{dynamicDialogConfig.data.cancelButtonText}}</button>
      <button class="btn btn--primary mr-2" (click)="OnDelete()"
        *ngIf="dynamicDialogConfig?.data?.updateButtonText?.length > 0">{{dynamicDialogConfig.data.updateButtonText}}</button>
    </div>
  </div>
</div>