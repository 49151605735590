import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TherapistService } from '../../servicies/therapist.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';

@Component({
  selector: 'app-view-availability',
  templateUrl: './view-availability.component.html',
  styleUrls: ['./view-availability.component.sass'],
  providers: [DialogService]
})
export class ViewAvailabilityComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private therapistId: number;
  searchRequestModel: SearchRequestModel;
  hasPageHistory: boolean;
  totalRecords: number;
  totalPages: number;
  title = '';
  availability: TherapistCustomAvailabilityResponseModel[] = [];
  selectedTherapist: TherapistCustomAvailabilityResponseModel;
  appDateFormat: DateFormatModel;
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private therapistService: TherapistService,
    messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private dialougeService: DialogService) {
    super(messageService);

    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      if (currentNavigationState.searchRequestModel) {
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        this.hasPageHistory = true;
      } else {
        this.initializeSearchRequestModel();
      }
    }
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngAfterViewInit(): void {
    this.hasPageHistory = false;
  }

  async ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = Number(params['id']);
      });
    this.initializeSearchRequestModel();
    await this.getTherapist();
  }

  ManageAvailability() {
    this.router.navigate(['admin/therapists/manageavailability', "0", this.therapistId]);
  }

  private initializeSearchRequestModel() {
    if (this.hasPageHistory)
      return;
    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = 'crmTherapistName';
  }

  getTotalRows() {
    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }
    return this.totalRecords;
  }

  onBack() {
    this.router.navigate(['admin/therapists/edit', this.therapistId]);
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    this.getTherapistsAvailability();
  }

  getTherapistsAvailability() {
    
    this.loading = true;
    this.searchRequestModel.appUserInfoId = this.therapistId;
    this.therapistService.getAvailabilityNew(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<TherapistCustomAvailabilityResponseModel>>) => {
        this.processResult<PagedResponseModel<TherapistCustomAvailabilityResponseModel>>(result, () =>
        {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.availability = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize) {
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }

        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  selectTherapist(event, therapist: TherapistCustomAvailabilityResponseModel, overlaypanel: OverlayPanel) {
    this.selectedTherapist = therapist;
    overlaypanel.toggle(event);
  }

  onView(Id: number) {
    this.router.navigate(['/admin/calendar/view-therapist-availability/' + Id]);
  }

  onEdit(Id: number, Id1: number) {
    this.router.navigate(['/admin/therapists/manageavailability/', Id, Id1]);
  }

  onDelete(Id: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.therapistService.delAvailabilityNew(Id)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage("Availability deleted successfully.");
            this.getTherapistsAvailability();
          } else {
            this.showWarningMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });
  }

  GetDays(days: string) {
    
    days = days.replace(/,\s*$/, "");
    var returnString = '';
    switch (days.split(',')[0]) {
      case '1': {
        returnString = 'Mon';
        break;
      }
      case '2': {
        returnString = 'Tue';
        break;
      }
      case '4': {
        returnString = 'Wed';
        break;
      }
      case '8': {
        returnString = 'Thu';
        break;
      }
      case '16': {
        returnString = 'Fri';
        break;
      }
      case '32': {
        returnString = 'Sat';
        break;
      }
      case '64': {
        returnString = 'Sun';
        break;
      }
      default: {
        break;
      }
    }
    if (days.split(',').length > 1) {
      switch (days.split(',')[days.split(',').length - 1]) {
        case '1': {
          returnString += ' - Mon';
          break;
        }
        case '2': {
          returnString += ' - Tue';
          break;
        }
        case '4': {
          returnString += ' - Wed';
          break;
        }
        case '8': {
          returnString += ' - Thu';
          break;
        }
        case '16': {
          returnString += ' - Fri';
          break;
        }
        case '32': {
          returnString += ' - Sat';
          break;
        }
        case '64': {
          returnString += ' - Sun';
          break;
        }
        default: {
          break;
        }
      }
    }
    return returnString;
  }

  private async getTherapist() {
    if (!this.therapistId) {
      return;
    }
    
    this.progress.next(true);
    await this.therapistService.getTherapistById(this.therapistId.toString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results || !results.responseModel) {
          return;
        }
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
            this.title = capitalizeFirstLetter(results.responseModel.appUserFname) + ' ' + capitalizeFirstLetter(results.responseModel.appUserLname);
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onSearchButtonClick() {
    this.searchRequestModel.dictionaryFilter = {};
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    this.resetPageIndex();
    this.getTherapistsAvailability();
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }


}
