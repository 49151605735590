<div class="plugin-settings-outer">
  <p-tabView class="appointments-tab-outer setting-tab-menu admin-application-setting">
    <div class="title-block">
      <h1>{{title}}</h1>
      <div class="title-block__right">
        <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/settings']"><i class="back-new"></i>
          Back</a>
      </div>
    </div>
    <p-tabPanel header="Email Service">
      <div class="form-content-outer">

        <div class="form-outer">
          <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="col">
              <div class="form__group">
                <label class="form__label">From Email Address</label>
                <input type="text" formControlName="fromEmailAddress" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.fromEmailAddress, 'required') || hasError(formFields.fromEmailAddress, 'whitespace')">{{validationMessages.fromEmailAddress.required}}</span>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.fromEmailAddress, 'email')">{{validationMessages.fromEmailAddress.email}}</span>
              </div>
              <div class="form__group">
                <label class="form__label">From Name</label>
                <input type="text" formControlName="fromName" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.fromName, 'required') || hasError(formFields.fromName, 'whitespace')">{{validationMessages.fromName.required}}</span>
                <!--<span *ngIf="hasError(formFields.fromName, 'validCharacters')">{{validationMessages.fromName.validCharacters}}</span>-->
              </div>
            </div>
            <div class="col">
              <div class="form__group">
                <label class="form__label">SMTP Host</label>
                <input type="text" formControlName="smtpHost" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpHost, 'required') || hasError(formFields.smtpHost, 'whitespace')">{{validationMessages.smtpHost.required}}</span>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpHost, 'validHostName')">{{validationMessages.smtpHost.validHostName}}</span>
              </div>
              <div class="form__group">
                <label class="form__label">SMTP Port</label>
                <input type="text" formControlName="smtpPort" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpPort, 'required') || hasError(formFields.smtpPort, 'whitespace')">{{validationMessages.smtpPort.required}}</span>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpPort, 'validNumber')">{{validationMessages.smtpPort.validNumber}}</span>
              </div>
            </div>
            <div class="col">
              <div class="form__group">
                <label class="form__label">SMTP Username</label>
                <input type="text" formControlName="smtpUsername" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpUsername, 'required') || hasError(formFields.smtpUsername, 'whitespace')">{{validationMessages.smtpUsername.required}}</span>
              </div>
              <div class="form__group">
                <label class="form__label">SMTP Password</label>
                <input type="password" formControlName="smtpPassword" class="form__control" pInputText>
                <span class="invalid-feedback"
                  *ngIf="hasError(formFields.smtpPassword, 'required') || hasError(formFields.smtpPassword, 'whitespace')">{{validationMessages.smtpPassword.required}}</span>
              </div>
            </div>
            <div class="col">
              <div class="form__group">
                <label class="form__label">Email Send Start Time</label>
                <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="24" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
              <div class="form__group">
                <label class="form__label">Email Send End Time</label>
                <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="24" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
              <span class="time-note" style=" margin: -20px 0 2rem 0 !important; display: table;">Please do not use the 0 prefixes in Hours and Minutes</span>
            </div>

            <div class="form__group">
              <label class="form__label">Type of Encryption</label>
              <div class="radios">
                <p-radioButton formControlName="typeOfEncryption" value="None" label="None" inputId="opt1">
                </p-radioButton>
                <p-radioButton formControlName="typeOfEncryption" value="SSL/TLS" label="SSL/TLS" inputId="opt2">
                </p-radioButton>
                <p-radioButton formControlName="typeOfEncryption" value="STARTTLS" label="STARTTLS" inputId="opt3">
                </p-radioButton>
              </div>
              <small>For most servers SSL/TLS is the recommended option</small>
            </div>


            <div class="btns">
              <button class="btn btn--primary" routerLink="/admin/settings/plugins">Save</button>
              <button class="btn btn--secondary" routerLink="/admin/settings">Cancel</button>
            </div>
          </form>
        </div>


      </div>
    </p-tabPanel>

    <p-tabPanel header="SMS">
      <app-sms></app-sms>
    </p-tabPanel>

    <p-tabPanel header="Voice Call">
      <app-voice-call></app-voice-call>
    </p-tabPanel>

    <p-tabPanel header="Voice Mail">
      <app-voice-mail></app-voice-mail>
    </p-tabPanel>

    <p-tabPanel header="CRM App">
      <app-crm-app></app-crm-app>
    </p-tabPanel>

    <p-tabPanel header="FTP" *ngIf="isVoiceCallRecordingDisplay">
      <app-ftp></app-ftp>
    </p-tabPanel>
  </p-tabView>
</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
