<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
      </div>
    <div class="title-block__right" style="z-index:999 !important">
      <a class="btn btn--primary" style="margin-right: 1.5rem" [routerLink]="['/admin/importexport/SyncRcmSalesOrder']">
        Sync Sales Order(s)
      </a>
      <a class="btn btn--primary" style="margin-right: 1.5rem" [routerLink]="['/admin/importexport/SyncSalesOrderById']">
        Sync Sales Order By Id
      </a>
      <a class="btn btn--primary" [routerLink]="['/admin/importexport/ReImportSalesOrderById']">
        Re-Import Sales Order
      </a>
    </div>
    </div>


    <div class="sync-page-outer">

      <div class="sync-page-outer__export">
        <div class="sync-page-outer__export__block">
          <div class="sync-page-outer__export__title">
            <h2>Export</h2>
            <a class="filter-action" href="javascript:void(0);" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'" (click)="clickEventFilter()">Export History</a>
          </div>

          <div class="sync-page-outer__block">
            <div class="from__group">
              <label class="form__label sales-orders-lable">Sales Orders</label>
              <div class="sync-page-outer__export__column-right">

                <div class="sync-page-outer__export__column" *ngIf="false">
                  <div class="form__group appointments">
                    <label class="form__label">Appointments</label>
                    <p-dropdown class="select-box" [options]="Status" placeholder="Status" optionLabel="name"
                                [showClear]="true"></p-dropdown>
                  </div>
                </div>

                <div class="sync-page-outer__export__column">
                  <div class="sales-opders__block__right">
                    <div class="form__group">
                      <label class="form__label">From</label>
                      <p-calendar class="datepicker" dateFormat="{{appDateFormat.primeFormat}}" [(ngModel)]="fromDate"
                                  [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                    <div class="form__group">
                      <label class="form__label">To</label>
                      <p-calendar class="datepicker" dateFormat="{{appDateFormat.primeFormat}}" [(ngModel)]="toDate"
                                  [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                  </div>
                </div>

                <div class="sync-page-outer__export__column">
                  <button class="btn btn--primary sales-opders__block--btn" (click)="export()">Export</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tablet-scroll" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'">
        <div class="table-outer-main">
          <h3>Export history</h3>
          <div class="history-table">
            <p-table class="table-outer admin-tablet" #nsdl [value]="exportResponseModel"
                     [paginator]="totalRecords>0?true:false" sortMode="single" sortField="DateTimeCreated"
                     [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="-1" [rows]="getTotalRows()"
                     [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="table-outer__th" width="600" pSortableColumn="FilePath">
                    File Name<p-sortIcon field="FilePath"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="StartDate">
                    Selected Date Range<p-sortIcon field="StartDate"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="DateTimeCreated">
                    Exported Date & Time<p-sortIcon field="DateTimeCreated"></p-sortIcon>
                  </th>
                  <!--<th pSortableColumn="EndDate">To Date<p-sortIcon field="EndDate"></p-sortIcon></th>-->
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-export>
                <tr>
                  <td class="table-outer__td">
                    <span class="link">
                      <a href="javascript:void(0)"
                         (click)="downloadFromHistory(export.exportHistoryId,export.filePath)">
                        {{export.filePath}}
                      </a>
                    </span>
                  </td>
                  <td class="table-outer__td">
                    <span class="facilityName">
                      {{export.startDate | date:appDateFormat.dateFormat}} -
                      {{export.endDate | date:appDateFormat.dateFormat}}
                    </span>
                  </td>
                  <td class="table-outer__td">
                    <span class="facilityName">
                      {{export.dateTimeCreated | date:appDateFormat.dateFormat}}
                      {{export.dateTimeCreated | date:'shortTime':appDateFormat.timeZone | uppercase}}
                    </span>
                  </td>
                  <!--<td> <span class="facilityName"></span> </td>-->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr class="primeng-table__body">
                  <td colspan="4" class="table-outer__td text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft" class="table-pagination">
                <span class="table-pagination__records">
                  Total Records : &nbsp;<strong> {{totalRecords}}</strong>
                </span>
              </ng-template>
            </p-table>

            <div class="loader-block" *ngIf="loading">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                                 animationDuration=".8s">
              </p-progressSpinner>
            </div>
          </div>
        </div>
      </div>

    </div>


  </div>
