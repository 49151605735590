import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WebStorageService } from './web-storage.service';

export abstract class BaseService {
  private httpClient: HttpClient;
  webStorageService: WebStorageService
  constructor(httpClient: HttpClient, webStorageService: WebStorageService) {
    this.httpClient = httpClient;
    this.webStorageService = webStorageService;
  }

  /**
   * Get
   * @param url
   */
  protected get<T>(url: string, httpHeaders: HttpHeaders = null): Observable<T> {
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const clientConfig = this.webStorageService.getClientConfig();
    if (httpHeaders == null) {
      httpHeaders = new HttpHeaders();
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
      //httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', clientConfig.isAuditLogEnabled.toString());

    }
    else {
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    return this.httpClient.get<T>(this.getApiUrl(url),
      {
        headers: httpHeaders
      });
  }

  protected getRefresh<T>(url: string, httpHeaders: HttpHeaders = null): Observable<T> {
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const clientConfig = this.webStorageService.getClientConfig();
    if (httpHeaders == null) {
      httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set('forceRefresh', 'true');
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
      //httpHeaders = httpHeaders.set('Access-Control-Allow-Origin', clientConfig.isAuditLogEnabled.toString());

    }
    else {
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    return this.httpClient.get<T>(this.getApiUrl(url),
      {
        headers: httpHeaders
      });
  }


  //protected getConfig<T>(url: string): Observable<T> {
  //  return this.httpClient.get<T>(this.getConfigUrl(url));
  //}

  /**
   * Post
   * @param url
   * @param body
   * @param httpHeaders
   */
  protected post<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {


    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const clientConfig = this.webStorageService.getClientConfig();
    if (httpHeaders == null) {
      httpHeaders = new HttpHeaders();
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    else {
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    return this.httpClient.post<T>(this.getApiUrl(url), body,
      {
        headers: httpHeaders
      });
  }

  protected postRefresh<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {


    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const clientConfig = this.webStorageService.getClientConfig();
    if (httpHeaders == null) {
      httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set('forceRefresh', 'true');


      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    else {
      if (loggedInUser)
        httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      if (clientConfig && clientConfig.isAuditLogEnabled)
        httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
      else
        httpHeaders = httpHeaders.set('isAuditLogEnable', 'false');
    }
    return this.httpClient.post<T>(this.getApiUrl(url), body,
      {
        headers: httpHeaders
      });
  }

  protected postCustomUrl<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const clientConfig = this.webStorageService.getClientConfig();
    if (httpHeaders == null) {
      httpHeaders = new HttpHeaders();
      httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
    }
    else {
      httpHeaders = httpHeaders.set('loggedInUsername', loggedInUser.userFullName);
      httpHeaders = httpHeaders.set('isAuditLogEnable', clientConfig.isAuditLogEnabled.toString());
    }
    return this.httpClient.post<T>(url, body,
      {
        headers: httpHeaders
      });
  }

  //protected postconfig<T>(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<T> {
  //  return this.httpClient.post<T>(this.getConfigUrl(url), body,
  //    {
  //      headers: httpHeaders
  //    });
  //}

  protected downloadBlobFile(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<Blob> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'blob'
      });
  }

  protected downloadTextFile(url: string, body: any, httpHeaders: HttpHeaders = null): Observable<string> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'text'
      });
  }

  protected downloadCsvFile(url: string, body: any = null, httpHeaders: HttpHeaders = null): Observable<Blob> {
    return this.httpClient.post(this.getApiUrl(url), body,
      {
        headers: httpHeaders,
        responseType: 'blob'
      });
  }

  private getApiUrl(url: string): string {
    return environment.apiBaseUrl + url;
  }

  //private getConfigUrl(url: string): string {
  //  return environment.baseUrl + url;
  //}
}
