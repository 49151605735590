<div class="application-outer">

  <div class="form-outer">
    <form class="form" [formGroup]="form" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Expiry Period of Appointment Link</label>
          <input type="text" formControlName="expiryPeriodOfAppointmentLink" class="form__control" pInputText
            maxlength="2" (keypress)="numberOnly($event)">
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.expiryPeriodOfAppointmentLink, 'required') || hasError(formFields.expiryPeriodOfAppointmentLink, 'whitespace')">{{validationMessages.expiryPeriodOfAppointmentLink.required}}</span>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.expiryPeriodOfAppointmentLink, 'positiveInt')">{{validationMessages.expiryPeriodOfAppointmentLink.validNumber}}</span>
        </div>
        <div class="form__group">
          <!-- <label class="form__label">Pause Duration between calls (In Seconds)</label> -->
          <label class="form__label">Pause time between call (In sec.)</label>
          <input type="text" formControlName="pause" class="form__control" pInputText maxlength="2"
            (keypress)="numberOnly($event)">
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.pause, 'required') || hasError(formFields.pause, 'whitespace')">{{validationMessages.pause.required}}</span>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.pause, 'positiveInt')">{{validationMessages.pause.validNumber}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Allow Therapist to Update Appointment Status </label>
          <div class="radios">
            <p-radioButton formControlName="allowTherapistToUpdateAppointmentStatus" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowTherapistToUpdateAppointmentStatus" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow Therapist to Update License & Certificate details </label>
          <div class="radios">
            <p-radioButton formControlName="allowTherapistToUpdateLicense" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowTherapistToUpdateLicense" value="False" label="No">
            </p-radioButton>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Allow update into brightree system from appt. confirmation</label>
          <div class="radios">
            <p-radioButton formControlName="allowUpdateAppointmentToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowUpdateAppointmentToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <!-- <label class="form__label">Patient's Default Appointment Location</label> -->
          <label class="form__label">Patient's Default Appointment Address</label>
          <div class="radios">
            <p-radioButton formControlName="patientsdefaultappointmentlocation" value="BillingAddress" label="Billing">
            </p-radioButton>
            <p-radioButton formControlName="patientsdefaultappointmentlocation" value="DeliveryAddress"
              label="Delivery">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <!-- <label class="form__label">Patient's Default Appointment Location</label> -->
          <label class="form__label">Default WIP Needed Date</label>
          <div class="radios">
            <p-radioButton [(ngModel)]="defaultwipneededdate" formControlName="defaultWIPNeededdate"
              value="AppointmentDate" label="Appointment">
            </p-radioButton>
            <p-radioButton [(ngModel)]="defaultwipneededdate" formControlName="defaultWIPNeededdate" value="CurrentDate"
              label="Current">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Enable the Black out Days for Rescheduled Appointment</label>
          <div class="radios">
            <p-radioButton formControlName="allowBlackoutForAppointment" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowBlackoutForAppointment" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Default Mode of Appointment</label>
          <div class="full-width mb-15">
            <p-radioButton formControlName="defaultModeofAppointment" value="RemoteSetup" label="Remote Setup">
            </p-radioButton>
          </div>
          <div class="full-width mb-15">
            <p-radioButton formControlName="defaultModeofAppointment" value="FacilityVisit" label="Facility Visit">
            </p-radioButton>
          </div>
          <div class="full-width">
            <p-radioButton formControlName="defaultModeofAppointment" value="RoadHomeVisit" label="Road/Home Visit">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Enable the auto reconcile of Appointment</label>
          <div class="radios">
            <p-radioButton formControlName="autoReconcileOfAppointment" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="autoReconcileOfAppointment" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow to use same online link for multiple Therapists</label>
          <div class="radios">
            <p-radioButton formControlName="sameOnlineLinkForMultipleRT" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="sameOnlineLinkForMultipleRT" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group" *ngIf="false">
          <label class="form__label">Allow to send POD into brightree system from appt. confirmation</label>
          <div class="radios">
            <p-radioButton formControlName="allowUpdatePODStatusToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowUpdatePODStatusToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group" *ngIf="false">
          <label class="form__label">Allow to append Order Note into brightree system from appt. confirmation</label>
          <div class="radios">
            <p-radioButton formControlName="allowAppendOrderNoteToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowAppendOrderNoteToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow DME to Update Order Note into brightree</label>
          <div class="radios">
            <p-radioButton formControlName="allowDMEtoUpdateOrderNoteToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowDMEtoUpdateOrderNoteToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow DME to Update Delivery Note into brightree</label>
          <div class="radios">
            <p-radioButton formControlName="allowDMEtoUpdateDeliveryNoteToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowDMEtoUpdateDeliveryNoteToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow DME to Update Delivery Address into brightree</label>
          <div class="radios">
            <p-radioButton formControlName="allowDMEtoUpdateDeliveryAddressToBrightTree" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowDMEtoUpdateDeliveryAddressToBrightTree" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Allow DME to Update Wip Status on Rescheduled</label>
          <div class="radios">
            <p-radioButton formControlName="allowDMEtoUpdateWipStatusRescheduled" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="allowDMEtoUpdateWipStatusRescheduled" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Check therapist state license for Remote & Road/Home appointment</label>
          <div class="radios">
            <p-radioButton formControlName="checkTherapistStateLicense" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton formControlName="checkTherapistStateLicense" value="False" label="No">
            </p-radioButton>
          </div>
        </div>
        <div class="form__group enable-remote">
          <label class="form__label">Enable the Remote Appointment</label>
          <div class="clearfix" style="max-width: 100%; width: 100%;"></div>
          <div class="full-width">
            <div class="input-edit-table" [ngClass]="telemedEditStatus ? 'edit-box' : ''">
              <p-checkbox name="group7" binary="true" label="" [(ngModel)]="telemedSelected"
                formControlName="telemedSelected"></p-checkbox>
              <input type="text" class="form__control" formControlName="telemedTitle" pInputText maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditTelemedTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>
                <a href="javascript:void(0);" (click)="saveTelemedTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelTelemedTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.telemedTitle, 'required') || hasError(formFields.telemedTitle, 'whitespace')">{{validationMessages.telemedTitle.required}}</span>
            </div>
          </div>

          <div class="full-width">
            <div class="input-edit-table" [ngClass]="OnlineMeetingEditStatus ? 'edit-box' : ''">
              <p-checkbox name="group7" binary="true" label="" [(ngModel)]="OnlineMeetingSelected"
                formControlName="onlineMeetingSelected"></p-checkbox>
              <input type="text" class="form__control" formControlName="onlineMeetingTitle" pInputText
                maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditOnlineMeetingTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>

                <a href="javascript:void(0);" (click)="saveOnlineMeetingTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelOnlineMeetingTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.onlineMeetingTitle, 'required') || hasError(formFields.onlineMeetingTitle, 'whitespace')">{{validationMessages.onlineMeetingTitle.required}}</span>
            </div>
          </div>

          <div class="full-width">
            <div class="input-edit-table" [ngClass]="SelfGuidedEditStatus ? 'edit-box' : ''">
              <p-checkbox name="group7" binary="true" label="" [(ngModel)]="SelfGuidedSelected"
                formControlName="selfGuidedSelected"></p-checkbox>
              <input type="text" class="form__control" formControlName="selfGuidedTitle" pInputText maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditSelfGuidedTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>

                <a href="javascript:void(0);" (click)="saveSelfGuidedTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelSelfGuidedTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.selfGuidedTitle, 'required') || hasError(formFields.selfGuidedTitle, 'whitespace')">{{validationMessages.selfGuidedTitle.required}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="btn btn--primary" (click)="onSubmit()" type="submit">Save</button>
        <button class="btn btn--secondary" routerLink="/admin/settings">Cancel</button>
      </div>
    </form>
  </div>


</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
