<!-- Start Header -->
<app-therapist-header></app-therapist-header>
<!-- End Header -->

<section class="therapist-module-outer">

  <div class="therapist-title-block">
    <h1 class="therapist-title-block__title">{{title}}</h1>
    <div class="therapist-title-block__right-block">
      <button class="btn btn--primary" (click)="back()">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.6111 1.40283L1 4.90109L4.6111 8.39935" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 4.90106L6.77777 4.90106C10.7666 4.90106 14 8.0334 14 11.8976V12.5972" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        My Appointments</button>
    </div>

    <div class="therapist-title-block__calendra-next-prev">
     <div class="calendra-prev-arrow" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            <i class="arrow-prev"></i>
      </div>
      <div class="btn btn--primary" mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay()"
          [(viewDate)]="viewDate" (click)="dateChange()">
          Today
      </div>

      <div class="calendra-next-arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
        <i class="arrow-next"></i>
      </div>
      <div class="calendar-day">
        {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
      </div>
      
  </div>

  </div>

  <div class="therapist-content-block">
    <div class="view-by-available-slots">
  
      <ng-template #monthCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <!--<span class="add-icon" (click)="ManageAvailability(day.date)"> <i class="ic plus small" tooltip="Add Therapist Availability"></i> </span>-->
          <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
        <div class="available-slots_event" *ngFor="let item of day.events">
          <small *ngIf="item.isHoliday && item.isFullDay && !item.isGlobalHoliday"
            [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
            tooltip="{{ item.title1 }}">{{ item.title }}</small>

          <small *ngIf="item.isHoliday && !item.isFullDay  && !item.isGlobalHoliday"
            [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
            tooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}"> {{
            item.title }} </small>

          <small *ngIf="!item.isHoliday  && !item.isGlobalHoliday"
                 [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
                 tooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}">
            {{item.title}}
          </small>
          
           <small *ngIf="item.isGlobalHoliday" class="view-by-available-slots_event" >{{ item.title }}</small>
        </div>
      </ng-template>

      <div class="view-by-available-slots__scroll day-view" [ngSwitch]="view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
          [cellTemplate]="monthCellTemplate">
        </mwl-calendar-month-view>

      </div>

    </div>
    <ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>

    <div class="loader-block" *ngIf="loadingCustom">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
        animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </div>

</section>

<!-- <div class="landscape-msg">
  <p>Application does not support landscape mode</p>
</div> -->