import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef, Input, Output, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, Inject, LOCALE_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { ViewByDateResponseTranspose, ViewByDateResponseDataTrans, ViewByDateResponseTransposeModel, ViewByDateResponseModel } from 'src/app/shared/models/response/appointment/available-slots'
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { AvailableSlots } from 'src/app/shared/models/response/appointment/available-slots';
import { SelectItem } from 'primeng/api';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserService } from 'src/app/admin/servicies/user.service';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';
import { FacilityService } from 'src/app/admin/servicies/facility.service';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { DatePipe } from '@angular/common';
//import { debug } from 'util';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-view-by-date',
  templateUrl: './view-by-date.component.html',
  styleUrls: ['./view-by-date.component.sass']
})

export class ViewByDateComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  title = 'Calendar';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  viewDate: Date = new Date();
  currentUserBranch: number;
  @Input() dayStartHour: number = 9;
  @Input() dayEndHour: number = 17;
  @Input() hourSegments: number = 1;
  @Input() hourSegmentHeight: number = 85;

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = true;
  Facility: { name: string; code: string; }[];
  SelectFacility: { name: string; code: string; }[];
  rowGroupMetadata: {};
  cars: any;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  viewByDateResponse: ViewByDateResponseDataTrans[] = [];
  distinctDates: Date[] = [];
  displayedColumns: string[] = ['facilityName', 'slotSun', 'slotMon', 'slotTue', 'slotWed', 'slotThu', 'slotFri', 'slotSat'];
  dataSource = new MatTableDataSource<ViewByDateResponseDataTrans>();
  appDateFormat: DateFormatModel;
  selectedSlot: number = 30;
  availableSlots: AvailableSlots[] = [];
  weekStartsOn: number = DAYS_OF_WEEK.SUNDAY;
  minutes: SelectItem[];
  loadingCustom: boolean = false;
  selectedMinute: SelectItem;
  slotResult: ViewByDateResponseModel[];
  viewByDateResponseDataTrans: ViewByDateResponseModel[];
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  selectedbranches: BranchResponseModel[];
  loggedInUserId: number;
  selectedFacility: FacilityResponseModelDropdown;
  selectedAppointmentFacilities: FacilityResponseModelDropdown[];
  selectedFacilities: FacilityResponseModelDropdown[];
  facilityResponseModel: FacilityResponseModelDropdown[];
  facilityId: number;
  selectFacilityString: string = "0";
  selectBranchString: string = "0";
  isShowHomeVisit: boolean = false;
  dtDateMon: Date;
  dtDateTue: Date;
  dtDateWed: Date;
  dtDateThu: Date;
  dtDateFri: Date;
  dtDateSat: Date;
  dtDateSun: Date;
  availableSlotText = 'Available Slots';
  selectedTimeZone: TimeZoneAvailableResponseModel[];
  timeZoneList: TimeZoneAvailableResponseModel[];
  selectedArrayTimeZone: string = null;
  isSun: boolean = false;
  isMon: boolean = false;
  isTue: boolean = false;
  isWed: boolean = false;
  isThu: boolean = false;
  isFri: boolean = false;
  isSat: boolean = false
  isNewManageAvailability: boolean = false;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];

  constructor(private router: Router,
    private appointmentService: AppointmentService,
    private webStorageService: WebStorageService,
    private globalSearchService: GlobalSearchService,
    private userService: UserService,
    protected cdr: ChangeDetectorRef,
    private facilityService: FacilityService,
    messageService: MessageService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.calendarActions = [
      { name: 'View By Date', code: 'ViewByDate' },
      //{ name: 'View By Available Slots', code: 'ViewByAvailableSlots' },
      //{ name: 'View By Facility', code: 'ViewByFacility' },
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'Week View By Therapist', code: 'WeekByTherapist' },
    ];
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.minutes = [
      { label: "30 Minutes", value: 30 },
      { label: "60 Minutes", value: 60 }
    ];
    this.selectedMinute = { label: "30 Minutes", value: 30 };
    this.timeZoneList = this.webStorageService.getTimeZoneList();

    var companyInfo = this.webStorageService.getClientConfig();
    this.isNewManageAvailability = companyInfo.isNewManageAvailability;

    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];
  }

  RedirectcalendarActions() {
    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByFacility") {
      this.router.navigate(['/admin/calendar/view-by-facility']);
    }
    else if (this.selectedcalendarActions.code == "ViewByDate") {
      this.router.navigate(['/admin/calendar/view-by-date']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && !this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/view-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/view-therapist-appointments-v1']);
    }
    else if (this.selectedcalendarActions.code == "WeekByTherapist" && !this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/week-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "WeekByTherapist" && this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/week-therapist-appointments-v1']);
    }
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  private async getAvailableSlots() {
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.appointmentService.GetViewByDate(selectedDate, this.currentUserBranch)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<ViewByDateResponseTranspose>(result, () => {
          if (result.responseModel.viewByDateResponseDataTrans.length > 0) {
            this.viewByDateResponse = result.responseModel.viewByDateResponseDataTrans;
            this.distinctDates = result.responseModel.dtDates.filter(
              (thing, i, arr) => arr.findIndex(t => t === thing) === i
            ).sort((a, b) => {
              return (a > b) ? 1 : -1
            });;
            this.updateRowGroupMetaData();
          }
          else {

          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllAvailableSlots() {
    let todayDate = new Date();
    this.loadingCustom = true;
    this.progress.next(true);
    let selectBranchString = "";
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.appointmentService.GetAllAvailableSlotsMonthWise(this.selectedSlot, selectedDate, selectBranchString, this.selectFacilityString, this.isShowHomeVisit, this.selectedArrayTimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<ViewByDateResponseTranspose>(result, () => {
          if (result.responseModel && result.responseModel.viewByDateResponseDataTrans && result.responseModel.viewByDateResponseDataTrans.length > 0) {

            this.dataSource = new MatTableDataSource(result.responseModel.viewByDateResponseDataTrans);
            this.dataSource.sort = this.sort;
            //this.dataSource.sort = this.sort;
            this.distinctDates = result.responseModel.dtDates.filter(
              (thing, i, arr) => arr.findIndex(t => t === thing) === i
            ).sort((a, b) => {
              return (a > b) ? 1 : -1
            });
            this.cdr.detectChanges();

            this.distinctDates.forEach((value) => {
              switch (new Date(value).getDay()) {
                case 0: {
                  this.dtDateSun = new Date(value);
                  if (this.dtDateSun >= todayDate) {
                    this.isSun = true;
                  }
                  break;
                }
                case 1: {
                  this.dtDateMon = new Date(value);
                  if (this.dtDateMon >= todayDate) {
                    this.isMon = true;
                  }
                  break;
                }
                case 2: {
                  this.dtDateTue = new Date(value);
                  if (this.dtDateTue >= todayDate) {
                    this.isTue = true;
                  }
                  break;
                }
                case 3: {
                  this.dtDateWed = new Date(value);
                  if (this.dtDateWed >= todayDate) {
                    this.isWed = true;
                  }
                  break;
                }
                case 4: {
                  this.dtDateThu = new Date(value);
                  if (this.dtDateThu >= todayDate) {
                    this.isWed = true;
                  }
                  break;
                }
                case 5: {
                  this.dtDateFri = new Date(value);
                  if (this.dtDateFri >= todayDate) {
                    this.isFri = true;
                  }
                  break;
                }
                case 6: {
                  this.dtDateSat = new Date(value);
                  if (this.dtDateSat >= todayDate) {
                    this.isSat = true;
                  }
                  break;
                }
                default: {
                  break;
                }
              }
            });



          }
          else {
            this.showWarningMessage('No Data found.');
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }
  private async getAllAvailableSlotsViewByDate() {
    this.viewByDateResponse = [];
    this.loadingCustom = true;
    this.progress.next(true);
    let selectBranchString = "";
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.appointmentService.GetAllAvailableSlotsViewByDate(selectedDate, selectBranchString, this.selectFacilityString, this.isShowHomeVisit, this.selectedArrayTimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<ViewByDateResponseTransposeModel>(result, () => {
          if (result.responseModel && result.responseModel.viewByDateResponseDataTrans && result.responseModel.viewByDateResponseDataTrans.length > 0) {


            let resultData: ViewByDateResponseModel[] = result.responseModel.viewByDateResponseDataTrans;
            this.viewByDateResponseDataTrans = result.responseModel.viewByDateResponseDataTrans;
            if (this.selectedSlot == 30) {
              resultData.forEach(x => {
                let res = new ViewByDateResponseDataTrans();
                res.dtDateSun = x.dtDateSun;
                res.dtDateMon = x.dtDateMon;
                res.dtDateTue = x.dtDateTue;
                res.dtDateWed = x.dtDateWed;
                res.dtDateThu = x.dtDateThu;
                res.dtDateFri = x.dtDateFri;
                res.dtDateSat = x.dtDateSat;
                res.slotSun = x.slot30Sun;
                res.slotMon = x.slot30Mon;
                res.slotTue = x.slot30Tue;
                res.slotWed = x.slot30Wed;
                res.slotThu = x.slot30Thu;
                res.slotFri = x.slot30Fri;
                res.slotSat = x.slot30Sat;
                res.facilityId = x.facilityId;
                res.therapistId = x.therapistId;
                res.therapistName = x.therapistName;
                res.facilityName = x.facilityName;
                res.selected = x.selected;
                res.timeZone = x.timeZone;

                this.viewByDateResponse.push(res);

              });
            }
            else if (this.selectedSlot == 60) {
              resultData.forEach(x => {
                let res = new ViewByDateResponseDataTrans();
                res.dtDateSun = x.dtDateSun;
                res.dtDateMon = x.dtDateMon;
                res.dtDateTue = x.dtDateTue;
                res.dtDateWed = x.dtDateWed;
                res.dtDateThu = x.dtDateThu;
                res.dtDateFri = x.dtDateFri;
                res.dtDateSat = x.dtDateSat;
                res.slotSun = x.slot60Sun;
                res.slotMon = x.slot60Mon;
                res.slotTue = x.slot60Tue;
                res.slotWed = x.slot60Wed;
                res.slotThu = x.slot60Thu;
                res.slotFri = x.slot60Fri;
                res.slotSat = x.slot60Sat;
                res.facilityId = x.facilityId;
                res.therapistId = x.therapistId;
                res.therapistName = x.therapistName;
                res.facilityName = x.facilityName;
                res.selected = x.selected;
                res.timeZone = x.timeZone;

                this.viewByDateResponse.push(res);

              });
            }
            this.slotResult
            this.dataSource = new MatTableDataSource(this.viewByDateResponse);
            this.dataSource.sort = this.sort;
            //this.dataSource.sort = this.sort;
            this.distinctDates = result.responseModel.dtDates.filter(
              (thing, i, arr) => arr.findIndex(t => t === thing) === i
            ).sort((a, b) => {
              return (a > b) ? 1 : -1
            });
            this.cdr.detectChanges();

            this.distinctDates.forEach((value) => {
              switch (new Date(value).getDay()) {
                case 0: {
                  this.dtDateSun = new Date(value);
                  break;
                }
                case 1: {
                  this.dtDateMon = new Date(value);
                  break;
                }
                case 2: {
                  this.dtDateTue = new Date(value);
                  break;
                }
                case 3: {
                  this.dtDateWed = new Date(value);
                  break;
                }
                case 4: {
                  this.dtDateThu = new Date(value);
                  break;
                }
                case 5: {
                  this.dtDateFri = new Date(value);
                  break;
                }
                case 6: {
                  this.dtDateSat = new Date(value);
                  break;
                }
                default: {
                  break;
                }
              }
            });



          }
          else {
            this.showWarningMessage('No Data found.');
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }
  async ngOnInit() {
    this.availableSlotText;
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.selectedFacilities = [];
    this.selectedcalendarActions = this.calendarActions.find(x => x.code == 'ViewByDate');
    this.Facility = [
      { name: 'View By Facility', code: 'NY' },
      { name: 'View By Therapist', code: 'NY' },
      { name: 'View By Date', code: 'NY' },
      { name: 'View By Available Slots', code: 'NY' }
    ];
    this.SelectFacility = [
      { name: 'Facility 1', code: 'NY' },
      { name: 'Facility 2', code: 'NY' },
      { name: 'Facility 3', code: 'NY' }
    ];
    await this.getBranches();
    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {
      if (branchId != null && branchId >= 0) {
        this.currentUserBranch = branchId;
        this.selectBranchString = branchId.toString();
        let branchIds: string[] = new Array<string>();
        branchIds.push(branchId.toString());
        this.getAllFacilitiesBranch(branchIds);
        this.setBranchSettings();
        this.cdr.detectChanges();
      }
      else {
        this.selectBranchString = "0";
        let selectBranchString = "";
        let branchIds: string[] = new Array<string>();
        this.selectedbranches.forEach(value => {
          this.selectBranchString += value.crmBranchId + ",";
          branchIds.push(value.crmBranchId.toString());
        });
        this.getAllFacilitiesBranch(branchIds);
      }

    });

    this.getAllAvailableSlots();
    //this.getAllAvailableSlotsViewByDate();
  }

  ngAfterViewInit(): void {

    this.dataSource.sort = this.sort;
  }

  updateRowGroupMetaData() {

    this.rowGroupMetadata = {};
    if (this.viewByDateResponse) {
      for (let i = 0; i < this.viewByDateResponse.length; i++) {
        let rowData = this.viewByDateResponse[i];
        let facilityId = rowData.facilityId;
        let therapistId = rowData.therapistId;
        if (i == 0) {
          this.rowGroupMetadata[facilityId] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.viewByDateResponse[i - 1];
          let previousFacilityId = previousRowData.facilityId;
          let previousTherapistId = previousRowData.therapistId;
          if (facilityId === previousFacilityId && therapistId !== previousTherapistId)
            this.rowGroupMetadata[facilityId].size++;
          else
            if (!this.rowGroupMetadata[facilityId])
              this.rowGroupMetadata[facilityId] = { index: i, size: 1 };
        }
      }
    }
  }

  async dateChange() {
    await this.getAllAvailableSlots();
    //await this.getAllAvailableSlotsViewByDate();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.globalSearchService.changeGlobalBranch)
      this.globalSearchService.changeGlobalBranch.next(null);

    if (this.progress)
      this.progress.complete();
  }

  onSlotChange() {

    this.selectedSlot = this.selectedMinute.value;
    this.getAllAvailableSlots();

    //this.viewByDateResponse = [];
    //this.selectedSlot = this.selectedMinute.value;
    //if (this.selectedSlot == 30) {
    //  this.viewByDateResponseDataTrans.forEach(x => {
    //    let res = new ViewByDateResponseDataTrans();
    //    res.dtDateSun = x.dtDateSun;
    //    res.dtDateMon = x.dtDateMon;
    //    res.dtDateTue = x.dtDateTue;
    //    res.dtDateWed = x.dtDateWed;
    //    res.dtDateThu = x.dtDateThu;
    //    res.dtDateFri = x.dtDateFri;
    //    res.dtDateSat = x.dtDateSat;
    //    res.slotSun = x.slot30Sun;
    //    res.slotMon = x.slot30Mon;
    //    res.slotTue = x.slot30Tue;
    //    res.slotWed = x.slot30Wed;
    //    res.slotThu = x.slot30Thu;
    //    res.slotFri = x.slot30Fri;
    //    res.slotSat = x.slot30Sat;
    //    res.facilityId = x.facilityId;
    //    res.therapistId = x.therapistId;
    //    res.therapistName = x.therapistName;
    //    res.facilityName = x.facilityName;
    //    res.selected = x.selected;
    //    res.timeZone = x.timeZone;

    //    this.viewByDateResponse.push(res);

    //  });
    //}
    //else if (this.selectedSlot == 60) {
    //  this.viewByDateResponseDataTrans.forEach(x => {
    //    let res = new ViewByDateResponseDataTrans();
    //    res.dtDateSun = x.dtDateSun;
    //    res.dtDateMon = x.dtDateMon;
    //    res.dtDateTue = x.dtDateTue;
    //    res.dtDateWed = x.dtDateWed;
    //    res.dtDateThu = x.dtDateThu;
    //    res.dtDateFri = x.dtDateFri;
    //    res.dtDateSat = x.dtDateSat;
    //    res.slotSun = x.slot60Sun;
    //    res.slotMon = x.slot60Mon;
    //    res.slotTue = x.slot60Tue;
    //    res.slotWed = x.slot60Wed;
    //    res.slotThu = x.slot60Thu;
    //    res.slotFri = x.slot60Fri;
    //    res.slotSat = x.slot60Sat;
    //    res.facilityId = x.facilityId;
    //    res.therapistId = x.therapistId;
    //    res.therapistName = x.therapistName;
    //    res.facilityName = x.facilityName;
    //    res.selected = x.selected;
    //    res.timeZone = x.timeZone;

    //    this.viewByDateResponse.push(res);

    //  });
    //}
    //this.slotResult
    //this.dataSource = new MatTableDataSource(this.viewByDateResponse);
    //this.dataSource.sort = this.sort;
    ////this.dataSource.sort = this.sort;

    //this.cdr.detectChanges();

    //this.distinctDates.forEach((value) => {
    //  switch (new Date(value).getDay()) {
    //    case 0: {
    //      this.dtDateSun = new Date(value);
    //      break;
    //    }
    //    case 1: {
    //      this.dtDateMon = new Date(value);
    //      break;
    //    }
    //    case 2: {
    //      this.dtDateTue = new Date(value);
    //      break;
    //    }
    //    case 3: {
    //      this.dtDateWed = new Date(value);
    //      break;
    //    }
    //    case 4: {
    //      this.dtDateThu = new Date(value);
    //      break;
    //    }
    //    case 5: {
    //      this.dtDateFri = new Date(value);
    //      break;
    //    }
    //    case 6: {
    //      this.dtDateSat = new Date(value);
    //      break;
    //    }
    //    default: {
    //      break;
    //    }
    //  }
    //});
  }

  async recdirectToFacilityCal(date, facilityId, timeZone) {
    let datepass = moment(date).format('YYYY-MM-DD');
    // if(timeZone == undefined || timeZone == ''){
    //   timeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone).orgcode;
    // }
    if (this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/view-therapist-appointments-v1/' + datepass + '/' + facilityId]);
    }
    else {
      this.router.navigate(['/admin/calendar/view-therapist-appointments/' + datepass + '/' + facilityId]);
    }

  }

  private async getBranches() {

    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result && result.responseModel) {
          this.branchResponseModel = result.responseModel;
          let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
          if (selectedBranch)
            this.selectedbranch = selectedBranch;
          else
            this.selectedbranch = this.branchResponseModel[0];

          this.setBranchSettings();
          this.cdr.detectChanges();
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setBranchSettings() {

    this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
    if (selectedBranch !== undefined) {

      if (selectedBranch.crmBranchId !== 0) {
        //this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
        this.selectedbranches = this.branchResponseModel.filter(x => x.crmBranchId === selectedBranch.crmBranchId);
      }
    } else {
      this.selectedbranches = this.branchResponseModel;
    }
  }

  async onBranchChange(event) {

    this.loadingCustom = true;
    //this.isShowHomeVisit = false;//59330
    this.selectBranchString = "";
    if (this.selectedbranches.length > 0) {
      await this.getAllFacilitiesBranches(this.selectedbranches);
    } else {
      await this.getAllFacilitiesBranch([]);
    }
    if (event.value) {
      if (this.selectedbranches.length > 0) {
        this.selectedbranches.forEach((value) => {
          this.selectBranchString += value.crmBranchId + ",";
        });
      }
      else {
        this.selectBranchString = "0";
      }
      // if (this.selectedAppointmentFacilities && this.selectedAppointmentFacilities.length > 0) {
      //   this.selectedAppointmentFacilities.forEach((value) => {
      //     this.selectFacilityString += value.crmFacilityId + ",";
      //   });
      // }
      // else {
      //   this.selectFacilityString = "0";
      // }
      this.loadingCustom = false;
      //this.getAllAvailableSlots();
    }
    //console.log(this.selectBranchString);
  }

  onFacilityChange(event) {

    this.loadingCustom = true;
    this.selectFacilityString = "";
    //this.selectBranchString = "";
    //this.isShowHomeVisit = false;//59330
    if (event.value) {
      if (this.selectedAppointmentFacilities.length > 0) {
        this.selectedAppointmentFacilities.forEach((value) => {
          this.selectFacilityString += value.crmFacilityId + ",";
        });
      }
      else {
        this.selectFacilityString = "0";
      }

      // if (this.selectedbranches.length > 0) {
      //   this.selectedbranches.forEach((value) => {
      //     this.selectBranchString += value.crmBranchId + ",";
      //   });
      // }
      // else {
      //   this.selectBranchString = "0";
      // }
      this.loadingCustom = false;
      //this.getAllAvailableSlots();
    }
    //console.log(this.selectFacilityString);
  }

  private async getAllFacilitiesBranch(branchId: Array<string>) {
    this.progress.next(true);
    if (this.facilityResponseModel != undefined) {
      return;
    }
    await this.facilityService.getAllFacilitiesBranch(branchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel)
          this.facilityResponseModel = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllFacilitiesBranches(branches: BranchResponseModel[]) {
    this.progress.next(true);
    await this.facilityService.getAllFacilitiesBranches(branches)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel) {
          this.facilityResponseModel = result.responseModel;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onCheck() {
    this.selectedAppointmentFacilities = null;
    this.selectFacilityString = "0";
    this.selectBranchString = "0";
    //this.getAllAvailableSlots();
  }


  onFilterSearch() {
    this.getAllAvailableSlots();
  }

  onClearFilterSearch() {
    this.selectedAppointmentFacilities = new Array<FacilityResponseModelDropdown>();
    this.selectedbranches = this.branchResponseModel;
    this.selectBranchString = "0";
    this.selectFacilityString = "0";
    this.isShowHomeVisit = false;
    this.selectedTimeZone = [];
    this.selectedArrayTimeZone = "";
    //this.getAllAvailableSlots();

  }

  onTimeZoneChange(event) {
    let timeZones = new Array<string>();
    if (event !== undefined && event != null) {
      this.selectedTimeZone.forEach((value: TimeZoneAvailableResponseModel) => {
        timeZones.push(value.appTimeZoneOffSet);
      });
      let arrayTimeZone = timeZones.join();
      this.selectedArrayTimeZone = arrayTimeZone;
    }
  }
  isCheck(date) {
    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
    let cuurentDate = moment(comparetime).toDate();
    let CheckDate = moment(date).toDate();
    if (CheckDate < cuurentDate) {
      return false;
    }
    return true;
  }
  RefreshPage() {
    this.getAllAvailableSlots();
  }
}


