import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { DoctorService } from '../servicies/doctor.service';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Router, ActivatedRoute } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search';
import { tr } from 'date-fns/locale';
@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.sass'],
  providers: [DialogService]
})
export class DoctorsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox', { static: true }) searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  title = 'Doctors';
  private hasPageHistory: boolean;
  doctorStatus: { label: string, status: boolean }[];
  selectedStatus: { label: string, status: boolean };
  doctors: DoctorResponseModel[];
  searchRequestModel: SearchRequestModel;
  totalRecords: number;
  totalPages: number;
  selectedDoctor: DoctorResponseModel;
  isFacilityIncluded: boolean = false;

  //global search
  searchText: string;
  searchFor: string;
  isGlobalSearch: boolean = false;
  orderObj: any;

  constructor(private doctorService: DoctorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    messageService: MessageService,
    private globalSearchService: GlobalSearchService,
    private dialougeService: DialogService) {
    super(messageService);

    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.selectedStatus = { label: 'All', status: true };
      // if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] != null) {
      //   // this.selectedStatus = { label: this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'true' ? 'Active' : 'Inactive' , status : Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) };
      //   // tslint:disable-next-line: max-line-length
      //   this.selectedStatus = { label: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) === true ? 'Active' : 'Inactive', status: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) };
      // } else {
      //   // this.selectedStatus = { label : 'All' , status : true };
      //   this.selectedStatus = { label: 'Active', status: true };
      // }
      if (this.searchRequestModel.dictionaryFilter['FacilityIncluded'] != null) {
        let isFacilityIncluded = this.searchRequestModel.dictionaryFilter['FacilityIncluded'];
        this.isFacilityIncluded = (isFacilityIncluded == "true");;
      }
      this.hasPageHistory = true;
    }
  }

  ngOnInit() {
    this.initializeSearchRequestModel();
    this.doctorStatus = [
      { label: 'All', status: true },
      { label: 'Active', status: true },
      { label: 'Inactive', status: false },
    ];
    if (this.searchRequestModel) {
      if (this.searchRequestModel.dictionaryFilter['SearchText'] !== null) {
        // tslint:disable-next-line: max-line-length
        this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'] !== undefined ? this.searchRequestModel.dictionaryFilter['SearchText'] : '';
      }
      if (this.searchRequestModel.dictionaryFilter['FacilityIncluded'] == null) {
        this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = this.isFacilityIncluded === true ? 'true' : 'false';
      }
    }
  }

  ngAfterViewInit() {
    // this.setFilters();
    this.hasPageHistory = false;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  onView(docotrId: string) {
    this.router.navigate(['/admin/doctors/detail/', docotrId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getDoctors(true);
  }
  // private setFilters()
  // {
  //   if (!this.hasPageHistory)
  //     return;

  //   if (this.orderPagedRequestModel.name)
  //     this.searchBoxName.nativeElement.value = this.orderPagedRequestModel.name;

  //   if (this.orderPagedRequestModel.companyName)
  //     this.searchBoxCompany.nativeElement.value = this.orderPagedRequestModel.companyName;

  //   switch (this.orderPagedRequestModel.isCorporateAccount)
  //   {
  //     case null:

  //       this.accountTypeDropdown.nativeElement.value = -1;
  //       break;

  //     case false:

  //       this.accountTypeDropdown.nativeElement.value = 0;
  //       break;

  //     case true:

  //       this.accountTypeDropdown.nativeElement.value = 1;
  //       break;
  //   }
  // }

  onSearchButtonClick() {
    // this.searchRequestModel.dictionaryFilter = {};
    // this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // if (this.selectedStatus) {
    //   this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedStatus.status ? "true" : "false";
    // }
    // if (this.isFacilityIncluded === true) {
    //   this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';
    // }

    // this.resetPageIndex();
    // this.getDoctors(true);
  }

  onStatusChange(event) {
    // this.searchRequestModel.dictionaryFilter = {};
    // if (this.searchBox.nativeElement.value) {
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // }

    if (event.value != null) {
      if (event.value.label !== "All")
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status.toString();
      else
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
    }

    // if (this.isFacilityIncluded === true) {
    //   this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';
    // }

    // this.resetPageIndex();
    // this.getDoctors(true);
  }

  onCheck() {
    if (this.isFacilityIncluded === true) {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';

      // if (this.searchBox.nativeElement.value) {
      //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // }

      // if (this.selectedStatus != null) {
      //   if (this.selectedStatus.label !== 'All') {
      //     this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedStatus.status ? 'true' : 'false';
      //   }
      // }

      // this.resetPageIndex();
      // this.getDoctors(true);
    } else {
      //this.searchRequestModel.dictionaryFilter = {};

      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'false';
      //this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = '';

      // if (this.searchBox.nativeElement.value) {
      //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // }

      // if (this.selectedStatus != null) {
      //   if (this.selectedStatus.label !== 'All') {
      //     this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedStatus.status ? 'true' : 'false';
      //   }
      // }

      // this.resetPageIndex();
      // this.getDoctors(true);
    }
  }

  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getDoctors(true);
  }

  onEdit(doctorId: number) {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else {
      this.searchRequestModel.dictionaryFilter['SearchText'] = '';
    }

    if (this.isFacilityIncluded === true) {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';
    }
    else {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'false';
    }

    if (this.selectedStatus) {
      if (this.selectedStatus.label !== "All")
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedStatus.status ? "true" : "false";
      else
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
    }

    this.router.navigate(['/admin/doctors/edit/', doctorId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onDelete(doctorId: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.doctorService.removeDoctor(doctorId.toString())
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Doctor deleted successfully.');
            this.getDoctors(true);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });
  }

  selectDoctor(event, doctor: DoctorResponseModel, overlaypanel: OverlayPanel) {
    this.selectedDoctor = doctor;
    overlaypanel.toggle(event);
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {
    if (this.hasPageHistory) {
      return;
    }
    this.searchRequestModel = new SearchRequestModel();
    if (this.isGlobalSearch && this.searchFor == 'D') {
      this.searchRequestModel.dictionaryFilter = {};
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "CrmDoctorFirstName";

    if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
      this.selectedStatus = { label: 'All', status: true };
    }
  }

  private getDoctors(isRefresh: boolean) {
    this.loading = true;
    this.doctorService.getAllDoctors(this.searchRequestModel, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<DoctorResponseModel>>) => {
        this.processResult<PagedResponseModel<DoctorResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse)
              {
                  this.doctors = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize) {
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
          
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onFilterSearch() {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else {
      this.searchRequestModel.dictionaryFilter['SearchText'] = '';
    }


    if (this.isFacilityIncluded === true) {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';
    }

    if (this.isFacilityIncluded === false) {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'false';
    }


    if (this.selectedStatus) {
      if (this.selectedStatus.label !== "All")
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedStatus.status ? "true" : "false";
      else
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
    }


    this.resetPageIndex();
    this.getDoctors(true);
  }

  onClearFilterSearch() {
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value = '';
    this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
    this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = '';
    this.selectedStatus = this.doctorStatus[0];
    this.isFacilityIncluded = false;
  }
}
