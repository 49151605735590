import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { SearchNearbyFacilityComponent } from './search-nearby-facility/search-nearby-facility.component';
import { ConfirmDateTimeSlotComponent } from './confirm-date-time-slot/confirm-date-time-slot.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { InitiateShippingComponent } from './initiate-shipping/initiate-shipping.component';
import { TeleHealthBookingComponent } from './tele-health-booking/tele-health-booking.component';

import { ChooseTherapistComponent } from './choose-therapist/choose-therapist.component';
import { AppointmentSummaryComponent } from './appointment-summary/appointment-summary.component';
import { RescheduleAppointmentComponent } from './reschedule-appointment/reschedule-appointment.component';
import { CancelledComponent } from './cancelled/cancelled.component';
import { PatientAppointmentBookingComponent } from './patient-appointment-booking/patient-appointment-booking.component';

const routes: Routes = [
  {
    path: 'home/:id',
    component: LandingComponent
  },
  {
    path: 'choose-facility',
    component: SearchNearbyFacilityComponent
  },  
  {
    path: 'choose-date-time',
    component: ConfirmDateTimeSlotComponent
  },
  {
    path: 'appointment-summary',
    component: AppointmentSummaryComponent
  },
  {
    path: 'appointment-confirmation',
    component: AppointmentConfirmationComponent
  },
  {
    path: 'initiate-shipping/:id',
    component: InitiateShippingComponent
  },
  {
    path: 'telehealth',
    component: TeleHealthBookingComponent
  },
  {
    path: 'choose-therapist',
    component: ChooseTherapistComponent
  },
  {
    path: 'booked-appointment',
    component: RescheduleAppointmentComponent
  },
  {
    path: 'cancelled-appointment',
    component: CancelledComponent
  },
  {
    path: 'appointment-booking',
    component: PatientAppointmentBookingComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
