import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { SettingsService } from '../../servicies/settings.service';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { DynamicCols } from 'src/app/shared/models/common/dynamic-cols';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model'
import { AppointmentsCountsResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model'
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { AppointmentCallListResponseModel } from 'src/app/shared/models/request/appointment-call-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { TwillioCallService } from '../../servicies/twillioCall.service';

import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { Constants } from 'src/app/shared/constants';
import { FeatureGroupResponseModel, featureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component'
declare const Twilio: any;
import * as moment from 'moment';
import { Table, TableHeaderCheckbox } from "primeng/table";
import { SalesorderCallLogsComponent } from '../salesorder-call-logs/salesorder-call-logs.component';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
@Component({
  selector: 'app-appoinment-call-list',
  templateUrl: './appoinment-call-list.component.html',
  styleUrls: ['./appoinment-call-list.component.sass'],
  providers: [DialogService]
})
export class AppoinmentCallListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  isLockedAppointments: boolean = false;
  selectedStatus: WIPStatesResponseModel;
  totalRecords: number;
  selectedsalesOrdersCount: number;
  totalPages: number;
  searchRequestModel: SearchRequestModel;
  salesOrders: SalesOrderSummaryResponseModel[];
  selectedsalesOrders: SalesOrderSummaryResponseModel[];
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderCountsResponseModel: SalesOrderCountsResponseModel;
  salesOrderCountModels: SalesOrdersCountModel[];
  appointmentCallListRequestModel: AppointmentCallListResponseModel[];
  dynamicNonSColumns: DynamicCols[];
  selectedNonSColumns: DynamicCols[];
  selectedSalesOrder: SalesOrderSummaryResponseModel;
  voidReasonsResponseModel: CancelVoidResponseModel[];
  reasonsResponseModel: CancelVoidResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  selectedTab: string;
  duration: string;
  pauseTime: number;
  dataExpressionloading: boolean = false;
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  salesOrderType: string;
  AppointmentOrderType: string;
  callConfigModel: CallConfigModel;
  callPopup: boolean = false;
  callnote: string;
  calldate: Date;
  calltime: Date;
  isCallStarted: boolean = false;
  isDisabled: boolean = true;
  features: featureResponseModel[];
  //Scheduled
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  voidFrom: string = "";
  totalAppointmentRecords: number;
  totalAppointmentPages: number;
  customloading: boolean = false;
  //totalRecords: number;
  //totalPages: number;
  searchAppointmentRequestModel: SearchRequestModel;
  appointmentsalesOrderStatus: WIPStatesResponseModel[];
  appointments: AppointmentSummaryResponseModel[];
  selectedAppointments: AppointmentSummaryResponseModel[];
  selectedAppointment: AppointmentSummaryResponseModel;
  appointmentsCounts: AppointmentsCountsResponseModel;
  selectedAppointmentStatus: WIPStatesResponseModel;
  dynamicColumns: DynamicCols[];
  selectedColumns: DynamicCols[];
  appointmentCountModels: SalesOrdersCountModel[];
  data1: any;
  @ViewChild("table") private _table: Table;
  @ViewChild('searchBoxAppointment') searchBoxAppointment: ElementRef;
  @ViewChild('statusDropdownAppointment') statusDropdownAppointment: ElementRef;
  @ViewChild("headerCheckBox") private _headerCheckBox: TableHeaderCheckbox;

  index: number = 0;
  connection: any;
  callSID: string;
  callId: number;
  device: any;
  mySubscription: any;
  isAppointmentDisabled: boolean = false;
  selectedNSFilter: QuickFilters;
  quickNSFilters: QuickFilters[];
  selectedSFilter: QuickFilters;
  quickSFilters: QuickFilters[];
  searchFromNSDate: Date;
  searchToNSDate: Date;
  searchFromSDate: Date;
  searchToSDate: Date;
  customSDate: boolean;
  customNSDate: boolean;
  NsheduledTableDefaltColumns: number = 8;
  sheduledTableDefaltColumns: number = 6;
  scheduleappointment: boolean = true;
  schedulecall: boolean = false;
  actionType: string;
  scheduleAction: string;
  selectedAppointmentType: string;
  loggedInUserId: number;
  autoCall: boolean = false;
  currentCallIndex: number = 0;
  currentUserBranch: number;
  appointmentCallResponseModel: AppointmentCallResponseModel;
  appDateFormat: DateFormatModel;
  defaultModeofAppointment: string = "";
  //Freeze

  cols: any[];
  frozencols: any[];
  displayedColumns: any[];
  columnNames: any[];

  //Freeze

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private settingsService: SettingsService,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    private formBuilder: FormBuilder) {
    super(messageService);

    //Not schduled  Parameters
    this.totalRecords = 0;
    this.totalPages = 1;
    this.salesOrderType = 'SalesOrderRequest';
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.currentUserBranch = this.webStorageService.getUserBranch();

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {

      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.index = Number(currentNavigationState.selectedTab);
      this.isAppointmentDisabled = currentNavigationState.isBookAppintment ? currentNavigationState.isBookAppintment : 'false';
      this.hasPageHistory = true;

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.mySubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
        }
      });
    }

    this.appDateFormat = this.webStorageService.getDateFormat();
    this.getPauseTime();
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
  }

  async ngOnInit() {
    this.buildForm();
    //await this.getDefaultModeofAppointment();
    this.cols = [
      { freezCoulmn: true, fieldNo: 12, field: 'action', header: '', active: true, fixedColumn: true, sortfieldcolumn: '', visible: true },
      { freezCoulmn: true, field: 'crmSalesOrderAPPId', header: 'S/O ID', active: true, fixedColumn: true },
      { freezCoulmn: true, field: 'crmPatientName', header: 'Patient Details', active: true, fixedColumn: true, sortfieldcolumn: 'crmPatientName' },
      { freezCoulmn: true, field: 'crmClassification', header: 'Classification Type', active: true, fixedColumn: false, sortfieldcolumn: 'crmClassification' },
      { freezCoulmn: true, field: 'crmSalesorderStatus', header: 'Status', active: true, fixedColumn: true, sortfieldcolumn: 'CrmSalesorderStatus' },



      { freezCoulmn: false, fieldNo: 2, field: 'crmDoctorName', sortFieldName: 'CrmDoctorName', header: 'Doctor', visible: true, style: 'width:8em', type: 'string', },
      { freezCoulmn: false, field: 'crmBranch', header: 'Branch', active: true, fixedColumn: false, sortfieldcolumn: 'crmBranch' },

      { freezCoulmn: false, field: 'schAppointmentCount', header: 'No of Calls', active: true, fixedColumn: false, sortfieldcolumn: 'SchAppointmentCount' },

    ];

    //this.frozencols = [
    //  { fieldNo: 12, field: 'action', header: '', active: true, fixedColumn: true, sortfieldcolumn: '', visible: true },
    //  { field: 'crmSalesOrderAPPId', header: 'S/O ID', active: true, fixedColumn: true },
    //  { field: 'crmPatientName', header: 'Patient Details', active: true, fixedColumn: true, sortfieldcolumn: 'crmPatientName' },
    //  { field: 'crmClassification', header: 'Classification', active: true, fixedColumn: false, sortfieldcolumn: 'crmClassification' },
    //  { field: 'crmSalesorderStatus', header: 'Status', active: true, fixedColumn: true, sortfieldcolumn: 'CrmSalesorderStatus' },
    //];

    await this.getWipStatusBTmapping();
    await this.getCancelVoidReasons();
    this.selectedSalesOrder = new SalesOrderSummaryResponseModel();
    this.initializeSearchRequestModel();
    //await this.getCallConfig();
    //await this.getDeviceConnectionForCall();

    this.webStorageService.deleteRedirectFrom();
    this.setPermissionforActions();
  }
  onBack() {
    this.router.navigate(['admin/appointments'], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.selectedTab, isBookAppintment: false } });
  }
  ngAfterViewInit() {
    this.hasPageHistory = false;


    const frozenBody: HTMLElement | null = document.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea: HTMLElement | null = document.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody && scrollableArea) {
      frozenBody.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
        const canScrollUp = 0 < scrollableArea.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea.scrollTop -= scrollDelta;
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    if (this.progress)
      this.progress.complete();
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize)
      return this.searchRequestModel.PageSize;

    return this.totalRecords;
  }
  async getWipStatusBTmapping() {

    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onLazyLoad(event: LazyLoadEvent) {

    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {

      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getsalesorders();
  }
  showCallLogsDialog(CallLogs, PatientName) {

    const ref = this.dialougeService.open(SalesorderCallLogsComponent, {
      showHeader: true,
      closable: true,
      data: {
        callLogs: CallLogs,
        patientName: PatientName
      },
    });

  }
  selectNonScheduleSalesOrder(event, selSalesOrder: SalesOrderSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedSalesOrder = selSalesOrder;
    overlaypanel.toggle(event);
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  AutoCalls(checkCall: string) {

    if (checkCall === 'A' && this.autoCall === true) {
      if (this.salesOrders[this.currentCallIndex]) {
        if (this.salesOrders[this.currentCallIndex].crmSalesorderStatus == 'No Show' || this.salesOrders[this.currentCallIndex].crmSalesorderStatus == 'Cancel')
          this.showApptDialog(this.salesOrders[this.currentCallIndex].crmSalesOrderId, this.salesOrders[this.currentCallIndex].schAppointmentId, this.salesOrders[this.currentCallIndex].schAppointmentCallListId);
        else
          this.showDialog(this.salesOrders[this.currentCallIndex].crmSalesOrderId, this.salesOrders[this.currentCallIndex].schAppointmentCallListId);
      }
      else
        this.autoCall = false;
    }
    if (checkCall === 'S') {
      this.autoCall = true;
      if (this.salesOrders[this.currentCallIndex]) {
        if (this.salesOrders[this.currentCallIndex].crmSalesorderStatus == 'No Show' || this.salesOrders[this.currentCallIndex].crmSalesorderStatus == 'Cancel')
          this.showApptDialog(this.salesOrders[this.currentCallIndex].crmSalesOrderId, this.salesOrders[this.currentCallIndex].schAppointmentId, this.salesOrders[this.currentCallIndex].schAppointmentCallListId);
        else
          this.showDialog(this.salesOrders[this.currentCallIndex].crmSalesOrderId, this.salesOrders[this.currentCallIndex].schAppointmentCallListId);
      }
      else
        this.autoCall = false;
    }
  }

  StopCall() {
    this.autoCall = false;
    this.webStorageService.deleteAutoCallIndex();
    if (this.device) {
      this.device.disconnectAll();
    }
  }


  onSelectionChange() {
    if (this.selectedsalesOrders.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  UnLockAppoinments() {

    if (!this.selectedsalesOrders || this.selectedsalesOrders.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }

    let appointmentUpdateStatus: AppointmentCallListResponseModel[] = new Array();
    this.selectedsalesOrders.forEach(value => {

      let appointmentUpdateStatusRequestModel = new AppointmentCallListResponseModel();
      appointmentUpdateStatusRequestModel.SchAppointmentCallListId = value.schAppointmentCallListId;
      appointmentUpdateStatusRequestModel.SchAppointmentId = 0;
      appointmentUpdateStatusRequestModel.CrmSalesorderId = value.crmSalesOrderId;
      appointmentUpdateStatusRequestModel.AppUserInfoId = this.loggedInUserId;
      appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    })

    this.loading = true;
    this.appointmentService.UnLockAppointments(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Sales order has been unlocked.');
          this.resetPageIndex();
          this.isDisabled = true;
          this.getsalesorders();
          this.selectedsalesOrders = [];
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  UnLockAppoinment(selectedSalesOrder: SalesOrderSummaryResponseModel) {

    let appointmentUpdateStatus: AppointmentCallListResponseModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentCallListResponseModel();
    appointmentUpdateStatusRequestModel.SchAppointmentCallListId = selectedSalesOrder.schAppointmentCallListId;
    appointmentUpdateStatusRequestModel.SchAppointmentId = 0;
    appointmentUpdateStatusRequestModel.CrmSalesorderId = selectedSalesOrder.crmSalesOrderId;
    appointmentUpdateStatusRequestModel.AppUserInfoId = this.loggedInUserId;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);


    this.loading = true;
    this.appointmentService.UnLockAppointments(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.resetPageIndex();
          this.getsalesorders();
          this._cdr.detectChanges();
          this.showSuccessMessage('Sales order has been unlocked.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  private initializeSearchRequestModel() {

    if (this.hasPageHistory && this.index === 0)
      return;


    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.crmBranchId = this.currentUserBranch;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
  }

  onSearchButtonClick() {
    this.searchRequestModel.dictionaryFilter = {}
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    this.resetPageIndex();
    this.getsalesorders();
  }

  private getsalesorders() {
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.loading = true;
    this.dataExpressionloading = true;
    this.appointmentService.GetLockedSalesOrders(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                let lockedAppointments = this.salesOrders.filter(x => x.schIsLocked == true);
                if (lockedAppointments.length > 0)
                  this.isLockedAppointments = true;
                else
                  this.isLockedAppointments = false;

                // if (this.salesOrders.length <= 0)
                //this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index } });

                this.loading = false;
                this.dataExpressionloading = false;
              }
            }
          }

        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }

  private async getCallConfig() {
    this.progress.next(true);
    await this.settingsService.getCallConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<CallConfigModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.callConfigModel = result.responseModel;
            }
          }

        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async getDeviceConnectionForCall() {
    await this.http.get(this.callConfigModel.twiMLFunctionURL).subscribe((data) => {

      this.data1 = data;
      this.device = new Twilio.Device(this.data1.token, {
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ['opus', 'pcmu'],
        // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
        // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
        // a second time and sending the tone twice. This will be default in 2.0.
        fakeLocalDTMF: true,
        // Use `enableRingingState` to enable the device to emit the `ringing`
        // state. The TwiML backend also needs to have the attribute
        // `answerOnBridge` also set to true in the `Dial` verb. This option
        // changes the behavior of the SDK to consider a call `ringing` starting
        // from the connection to the TwiML backend to when the recipient of
        // the `Dial` verb answers.
        enableRingingState: true
      });
      this.device.on('ready', function (device) {
        console.log('Twilio.Device Ready!');
      });
      this.device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
      this.device.on('connect', function (conn) {
        console.log('Successfully established call!');
      });
      this.device.on('disconnect', function (conn) {
        console.log('Call ended.');
      });
      this.device.on('ringing', function (conn) {
        console.log('still ringing...');
      });

    });
  }

  Phonecall(phoneNumber: string, selectedSalesOrder: SalesOrderSummaryResponseModel) {
    this.actionType = "";
    this.selectedAppointmentType = "";
    this.actionChange();
    this.callId = 0;
    this.callSID = '';
    this.selectedSalesOrder = selectedSalesOrder;
    this.selectedSalesOrder = selectedSalesOrder;
    this.duration = this.selectedSalesOrder.crmClassificaitonduration == 1 || this.selectedSalesOrder.crmClassificaitonduration == 60 ? "1 Hour" : this.selectedSalesOrder.crmClassificaitonduration.toString() + " Minutes";
    this.callPopup = true;
    if (this.device) {
      console.log('connection is null. Initiating the call');

      this._cdr.detectChanges();
      let that = this;

      var params = { "To": phoneNumber };
      let connection = this.device.connect(params);
      connection.on('ringing', function () {
        console.log('Ringing...');
      });
      connection.on('accept', function (conn) {
        that.isCallStarted = true;
        let twillioDeviceConnection = new TwilioDeviceObject();
        twillioDeviceConnection = new TwilioDeviceObject();
        twillioDeviceConnection.connection = connection;
        twillioDeviceConnection.device = that.device;
        twillioDeviceConnection.isCallinProgress = true;
        twillioDeviceConnection.status = connection.status();
        that.twillioCallService.twillioCall.next(twillioDeviceConnection);
      });
      connection.on('error', function (error) {
        that.isCallStarted = false;
        that.device.disconnectAll();
        //that.selectedFacilities = new FacilityInfoResponseModel();
        that.disconnectCall(error);
        console.log('Device Error: ' + error.message);
      });
    }
  }

  disconnectCall(error) {
    //this.SchedulePopup = false;
    //this.callPopup = false;
    this.isCallStarted = false;
    this._cdr.detectChanges();
    this.showWarningMessage(error.message);
  }

  async Hangup() {
    console.log('Hanging up...');
    if (this.device) {
      this.device.disconnectAll();
      if (this.callId) {
        await this.UpdateCall();
      }
      else {
        await this.SaveCall("V");
      }
    }
  }

  async HangUpCall() {
    if (this.device) {
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
  }

  async HangupVoiceMail() {
    console.log('Hanging up...');
    if (this.device) {
      this.device.disconnectAll();
      if (this.callId) {
        await this.UpdateCall();
      }
      else {
        await this.SaveCall("V");
        await this.SendVoiceMail();
      }
    }
  }

  actionChange() {
    this.calldate = null;
    this.calltime = null;
    this.callnote = '';
    if (this.actionType === "S") {
      this.schedulecall = false;
      this.scheduleappointment = true;
    }
    else if (this.actionType === "C") {
      this.schedulecall = true;
      this.scheduleappointment = false;
    }
    else {
      this.schedulecall = false;
      this.scheduleappointment = false;
    }
  }

  async bookAppointment() {
    if (this.actionType === "S") {
      if (this.callId) {
        await this.UpdateCall();
      }
      else {
        await this.SaveCall(this.actionType);
      }

      if (this.autoCall === true) {
        this.webStorageService.deleteAutoCallIndex();
        this.webStorageService.storeAutoCallIndex(0);
      }
      this.webStorageService.storeRedirectFrom('lock');
      if (this.selectedAppointmentType == "FacilityVisit")
        this.router.navigate(['/admin/book-appointment/confirm-date-time-slot/', this.selectedSalesOrder.crmSalesOrderId]);
      else if (this.selectedAppointmentType == "HomeVisit")
        this.router.navigate(['/admin/book-appointment/home-visit-confirm-date-time-slot/', this.selectedSalesOrder.crmSalesOrderId]);
      else
        this.router.navigate(['/admin/book-appointment/telehealth-confirm-date-time-slot/', this.selectedSalesOrder.crmSalesOrderId]);
    }
    else if (this.actionType === "C") {
      if (!this.calldate) {
        this.showWarningMessage('Please select next call due date');
        return;
      }
      else if (!this.calltime) {
        this.showWarningMessage('Please select next call due time');
        return;
      }
      else if (!this.callnote) {
        this.showWarningMessage('Please add a call note');
        return;
      }
      if (this.device) {
        this.device.disconnectAll();
      }
      if (this.callId) {
        await this.UpdateCall();
      }
      else {
        await this.SaveCall(this.actionType);
      }
    }
    else if (this.actionType === "V") {
      if (this.device) {
        this.device.disconnectAll();
      }
      if (this.callId) {
        await this.UpdateCall();
      }
      else {
        await this.SaveCall(this.actionType);
      }
      await this.SendVoiceMail();
    }
  }

  async SaveCall(saveDate: string) {
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (this.connection)
      this.callSID = this.connection.parameters.CallSid;
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      if (saveDate !== "S") {
        if (this.calldate && this.calltime) {
          this.calltime = new Date(this.calldate.toDateString() + ' ' + this.calltime.toTimeString());
          this.appointmentCallResponseModel.schNextCallDueDate = this.calltime;
        }
        else {
          this.calltime = new Date(new Date().setDate(new Date().getDate() + 3));
          this.appointmentCallResponseModel.schNextCallDueDate = this.calltime;
        }
        if (this.callnote)
          this.appointmentCallResponseModel.schAppointmentCallNote = this.callnote;
        else
          this.appointmentCallResponseModel.schAppointmentCallNote = "Not answered";
      }
      this.appointmentCallResponseModel.crmSalesOrderId = this.selectedSalesOrder.crmSalesOrderId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallBy = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallByPerson = currentUserToken.userFullName;
      this.appointmentCallResponseModel.schAppointmentCallByRole = currentUserToken.roleName;
      this.appointmentCallResponseModel.schAppointmentCallListId = this.selectedSalesOrder.schAppointmentCallListId;
      this.appointmentCallResponseModel.callSID = this.callSID;
      await this.appointmentService.SaveCall(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.callId = result.responseModel.schAppointmentCallId;
              this.callPopup = false;
              if (this.autoCall) {
                this.salesOrders.splice(0, 1);
              }
              else {
                var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == this.selectedSalesOrder.schAppointmentCallListId)
                if (index > 0) {
                  this.salesOrders.splice(index, 1);
                }
              }
              this.totalRecords = this.totalRecords - 1;
              this._cdr.detectChanges();
            }
          }
          else {
            this.showWarningMessage('Error occurred.');
          }

        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async UpdateCall() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (currentUserToken && currentUserAuthToken.token) {
      if (this.actionType !== "S") {
        if (this.calldate && this.calltime) {
          this.calltime = new Date(this.calldate.toDateString() + ' ' + this.calltime.toTimeString());
          this.appointmentCallResponseModel.schNextCallDueDate = this.calltime;
        }
        else {
          this.calltime = new Date(new Date().setDate(new Date().getDate() + 3));
          this.appointmentCallResponseModel.schNextCallDueDate = this.calltime;
        }
        if (this.callnote)
          this.appointmentCallResponseModel.schAppointmentCallNote = this.callnote;
        else
          this.appointmentCallResponseModel.schAppointmentCallNote = "Not answered";
      }
      this.appointmentCallResponseModel.schAppointmentCallId = this.callId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.callSID = this.callSID;
      await this.appointmentService.UpdateCallStatus(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.responseModel) {
            this.showSuccessMessage('Next call noted');
            this.callPopup = false;
            this.salesOrders.splice(0, 1);
            this.totalRecords = this.totalRecords - 1;
            this._cdr.detectChanges();
          }
          else {
            this.showWarningMessage('Error occurred.');
          }

        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async SendVoiceMail() {
    await this.appointmentService.SendVoiceMail(this.callId.toString(), this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Voice mail Sent.');
          this.callPopup = false;
          this.salesOrders.splice(0, 1);
          this.totalRecords = this.totalRecords - 1;
          this._cdr.detectChanges();
        }
        else {
          this.showWarningMessage('Error occurred.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getPauseTime() {
    await this.settingsService.getSettings(Constants.SettingKeys.PauseTime)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null)
            this.pauseTime = Number(result.responseModel.schSettingValue) * 1000;
        }
      });
  }

  showDialog(crmSalesOrderId, callListId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: 0,
        isAppointmentAppoinmentLockPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      this.HangUpCall();
      var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == callListId)
      if (index >= 0) {
        this.salesOrders.splice(index, 1);
        this.Unlock(callListId);
      }
      //this.getsalesorders();
    });
  }

  showApptDialog(crmSalesOrderId, schAppointmentId, callListId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: false,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isAppointmentAppoinmentLockPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      //this.HangUpCall();
      var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == callListId)
      if (index >= 0) {
        this.salesOrders.splice(index, 1);
        this.Unlock(callListId);
      }
      //this.getsalesorders();
    });
  }

  showAppointmentDialog(crmSalesOrderId, schAppointmentId, callListId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isAppointmentAppoinmentPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();
      var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == callListId)
      if (index >= 0) {
        this.salesOrders.splice(index, 1);
        this.Unlock(callListId);
      }
      //this.getsalesorders();
    });

  }


  showDialogSchedule(crmSalesOrderId, callListId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();
      var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == callListId)
      if (index >= 0) {
        this.salesOrders.splice(index, 1);
        this.Unlock(callListId);
      }
      //this.getsalesorders();
    });
  }

  showVoidSalesOrderDialog(crmSalesOrderId, callListId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: false,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: true,
        isCancelOrder: false,
        isCall: false,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();
      var index = this.salesOrders.findIndex(x => x.schAppointmentCallListId == callListId)
      if (index >= 0) {
        this.salesOrders.splice(index, 1);
        this.Unlock(callListId);
      }
      //this.getsalesorders();
    });
  }


  get fVoid() { return (<FormGroup>this.form.get('formVoidAppointment')).controls; }

  get formVoidAppointment() {
    return this.form.get('formVoidAppointment');
  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const form = this.formVoidAppointment;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.voidReason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    return cancelRequestModel;
  }

  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.reasonsResponseModel = result.responseModel;
              const myClonedArray = [];
              this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
              this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  formFields = {

    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };

  validationMessages = {

    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },


  };


  private buildForm() {

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formVoidAppointment: formVoidGroup
    });
  }

  cancelVoidAppointment() {
    const form = this.formVoidAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    this.displayVoidAppointment = false;
  }

  voidAppointment(voidFrom: string) {

    this.formVoidAppointmentSubmitted = true;
    if (this.formVoidAppointment.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromVoidForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.isRescheduledRequest ? this.voidschAppointmentId : this.voidSalesOrderId;
    cancelRequestModel.voidFrom = voidFrom;

    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.DeliveryTechnicianId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (cancelRequestModel.voidFrom === 'P') {

      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;
    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.VoidAppointment(cancelRequestModel) : this.appointmentService.VoidSalesOrder(cancelRequestModel);
    //this.appointmentService.CancelAppointment(cancelRequestModel)
    this.customloading = true;
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.customloading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            this.showSuccessMessage("Appointment has been voided");
            const form = this.formVoidAppointment;
            //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
            form.get(this.formFields.voidReason).setValue('');
            form.get(this.formFields.voidReasonComments).setValue('');
            form.reset();
            this.getsalesorders();
            this.voidschAppointmentId = null; this.voidSalesOrderId = null;
            this.displayVoidAppointment = false;
            this._cdr.detectChanges();
            this.customloading = false;
          }
        }
        else {
          this.customloading = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in voiding your appointment.");
        }
      });

  }
  formVoidAppointmentSubmitted: boolean = false;
  displayVoidAppointment: boolean;
  cancelschAppointmentId: number;
  voidSalesOrderId: number;
  voidschAppointmentId: number;
  isRescheduledRequest: boolean;

  showVoidAppointment(voidFrom, schAppointmentId) {
    this.voidFrom = voidFrom;
    this.isBrightreeSystemAppUpdateSetting = false;
    this.voidschAppointmentId = schAppointmentId;
    if (voidFrom === 'P') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }


    this.isRescheduledRequest = true;
    this.displayVoidAppointment = true;
  }

  showVoidSalesOrder(voidFrom, crmSalesOrderId) {

    this.isBrightreeSystemAppUpdateSetting = false;
    this.formVoidAppointmentSubmitted = false;
    this.voidSalesOrderId = crmSalesOrderId;
    this.voidFrom = voidFrom;
    if (voidFrom === 'P') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    this.isRescheduledRequest = false;
    this.displayVoidAppointment = true;
  }


  onBTSelect(status: any) {
    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }



  onView(salesOrderId: string) {
    // this.router.navigate(['/admin/appointments/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel } });
    this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled } });
  }

  Unlock(data: number) {
    this.progress.next(true);
    this.appointmentService.UnLockSalesOrder(data)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {

          this.getsalesorders();
          if (this.autoCall === true) {
            this.currentCallIndex = 0;
            setTimeout(() => { this.AutoCalls('A'); }, this.pauseTime);
          }
          //this.showSuccessMessage('Sales order unlocked successfully.');          
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }

  //Permission Check
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isAppointmentVoidPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features && this.features.length > 0) {

      let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
      if (inPer && inPer.isActive == true)
        this.isSendInvitationPermission = true;

      let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
      if (inPer1 && inPer1.isActive == true) {
        this.isAppointmentScheduledPermission = true;
        this.isAppointmentRescheduledPermission = true;
      }

      let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
      if (inPer2 && inPer2.isActive == true)
        this.isAppointmentCancelPermission = true;

      //let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
      //if (inPer3 && inPer3.isActive == true)
      //  this.isAppointmentRescheduledPermission = true;


      let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
      if (inPer4 && inPer4.isActive == true)
        this.isAppointmentMovedPermission = true;

      let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
      if (inPer5 && inPer5.isActive == true)
        this.isAppointmentOverduePermission = true;

      let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
      if (inPer6 && inPer6.isActive == true)
        this.isAppointmentNoshowPermission = true;

      let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
      if (inPer7 && inPer7.isActive == true)
        this.isAppointmentCompletedPermission = true;

      let inPer8 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentvoid');
      if (inPer8 && inPer8.isActive == true)
        this.isAppointmentVoidPermission = true;

    }
  }
}
