import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
export class AppointmentUpdateStatusRequestModel {
  SchAppointmentId: number;
  CrmSalesOrderId: number;
  SchAppointmentStatus: number;
  CrmFacilityId: number;
  CrmTherapistId: number;
  SchComments: string;
  modifiedByUserInfoId: number;
  voidFrom: string;
  schIsUnreconciledAppointment: boolean;
  salesOrderUpdateRequestModelBT: BTSalesOrderUpdateRequestModel;
  isProvisional: boolean;
  schCancelReason: string;
  isUpdateByTherapist: boolean;
  TherapistName: string;
  dateTimeLastModified:Date
}
