import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { DoctorService } from '../../servicies/doctor.service';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { DoctorFacilityResponseModel } from 'src/app/shared/models/response/doctor-facility-response-model';

@Component({
  selector: 'app-doctor-manage',
  templateUrl: './doctor-manage.component.html',
  styleUrls: ['./doctor-manage.component.sass']
})
export class DoctorManageComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private doctorId: string;
  title = 'Edit Doctor';
  isActive: boolean;
  facilities: { label: string, value: string }[];
  selectedFacilities: { label: string, value: string }[];
  patientAtDocLocation: boolean;
  searchRequestModel: SearchRequestModel;
  doctorResponseModel: DoctorResponseModel;
  doctorFacilities: DoctorFacilityResponseModel[];
  isFacilityVisitSelected: boolean= false;
  isRemoteVisitSelected: boolean= false;
  isRoadHomeVisitSelected: boolean= false;
  isSubmitForm:boolean=false;

  formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNumber: 'phoneNumber',
    facilities: 'facilities'
  };

  validationMessages =
    {
      firstName: {
        required: 'First name is required',
        validCharacters: 'First name should contain only characters'
      },
      lastName: {
        required: 'Last name is required',
        validCharacters: 'Last name should contain only characters'
      },
      phoneNumber: {
        required: 'Phone Number is required',
        // invalid: 'Phone Number is invalid'
      },
      facilities: {
        required: 'Kindly select at least one Facility'
      },
      AppointmentModeSelectionRequired: {
        required: 'Kindly select at least one Appointment mode'
      },
    };

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private doctorService: DoctorService,
              private facilityService: FacilityService,
              messageService: MessageService) {
    super(messageService);
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
  }

  async ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.doctorId = params['id'];
        this.getDoctor(params['id']);
      });
    this.buildForm();
    await this.getFacilities();
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  onCancel() {
    this.doctorResponseModel = null;
    this.isSubmitForm = false;
    this.form.reset();

    // if (redirectToListing)
    this.router.navigate(['admin/doctors'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack() {
    this.router.navigate(['admin/doctors'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onFacilityChange(facility) {
    // this.selectedFacilities = facility.value;
  }

  onSubmit() {
    this.isSubmitForm = true;
    if (this.form.invalid || (this.patientAtDocLocation && this.selectedFacilities?.length==0)
     || (!this.isFacilityVisitSelected && !this.isRemoteVisitSelected && !this.isRoadHomeVisitSelected)) {
      this.markFormAsTouched();
      return;
    }

    const doctorResponseModel = this.getValuesFromForm();
    
    this.loading = true;
    this.doctorService.updateDoctor(doctorResponseModel)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    .subscribe((result: Result) => {
      if (result && result.isSuccess) {
        this.showSuccessMessage('Doctor is updated successfully');
        this.form.reset();
        this.onCancel();
      } else {
        this.showErrorMessage(result.message);
      }
    }, (httpResponseError) => {
      this.showErrorMessage(httpResponseError.message);
    });
  }

  private async getFacilities() {
    this.progress.next(true);
    // await this.facilityService.getAllFacilityDropDown()
    await this.facilityService.GetFacilitiesWithDoctorExclusion(parseInt(this.doctorId, 10))
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    .toPromise().then((result) => {
      if (!result) {
        return;
      }
      if (result.isSuccess) {
        if (result.responseModel != undefined && result.responseModel != null) {
          this.facilities = [];
          const facilityArray = result.responseModel.slice(0, 100);
          facilityArray.forEach((value: FacilityResponseModelDropdown) => {
            this.facilities.push({ label: value.crmFacilityName, value: value.crmFacilityId.toString() });
          });
        }
      }
    })
    .catch((httpResponseError) => {
      this.showErrorMessage(httpResponseError.message);
    });
  }

  private getDoctor(doctorId: string) {
    if (!doctorId) {
      return;
    }
    this.loading = true;
    this.doctorService.getDoctorById(Number(doctorId))
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<DoctorResponseModel>) => {
        this.processResult<DoctorResponseModel>(result, () =>
        {
          if (result.isSuccess)
          {
            if (result.responseModel != undefined && result.responseModel != null)
            {
              this.doctorResponseModel = result.responseModel;
              this.setValuesToForm();
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getValuesFromForm(): DoctorResponseModel {
     const form = this.form;
     const doctorResponseModel = new DoctorResponseModel();
     let doctorFacility = new DoctorFacilityResponseModel();

     doctorResponseModel.crmDoctorId = Number(this.doctorId);
     doctorResponseModel.crmDoctorFirstName = form.get(this.formFields.firstName).value;
     doctorResponseModel.crmDoctorLastName = form.get(this.formFields.lastName).value;
     doctorResponseModel.crmDoctorContactEmailAddress = form.get(this.formFields.email).value;
     doctorResponseModel.isActive = this.isActive;
     doctorResponseModel.crmIsPatientAllowedOnlyOnMyFacility = this.patientAtDocLocation;
    //  doctorResponseModel.crmDoctorContactNo = form.get(this.formFields.phoneNumber).value;
    doctorResponseModel.crmIsFacilityVisitSelected= this.isFacilityVisitSelected;
    doctorResponseModel.crmIsRemoteVisitSelected= this.isRemoteVisitSelected;
    doctorResponseModel.crmIsRoadHomeVisitSelected= this.isRoadHomeVisitSelected;

     var tempPhone = form.get(this.formFields.phoneNumber).value;
     if(tempPhone != '___-___-____') {
      if (tempPhone.indexOf('-') > -1) {
        doctorResponseModel.crmDoctorContactNo = tempPhone.split('-').join('');
      } else {
        doctorResponseModel.crmDoctorContactNo = tempPhone;
      }
    } else {
      doctorResponseModel.crmDoctorContactNo = '';
    }

     if (this.selectedFacilities !== undefined) {
       this.doctorFacilities = [];
       this.selectedFacilities.forEach((result) => {
         doctorFacility = new DoctorFacilityResponseModel();
         doctorFacility.crmDoctorId = Number(this.doctorId);
         doctorFacility.crmFacilityId = Number(result.value);
         this.doctorFacilities.push(doctorFacility);
       });
       doctorResponseModel.doctorFacility = this.doctorFacilities;
     }

     return doctorResponseModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.doctorResponseModel) {
      return;
    }

    this.doctorId = this.doctorResponseModel.crmDoctorId.toString();
    const form = this.form;
    form.get(this.formFields.firstName).setValue(this.doctorResponseModel.crmDoctorFirstName);
    form.get(this.formFields.lastName).setValue(this.doctorResponseModel.crmDoctorLastName);
    form.get(this.formFields.email).setValue(this.doctorResponseModel.crmDoctorContactEmailAddress);
    form.get(this.formFields.phoneNumber).setValue(this.doctorResponseModel.crmDoctorContactNo);
    // this.doctorResponseModel.doctorFacility.forEach((facilityResponseModel) => {
    //   form.get(this.formFields.facilities).setValue(facilityResponseModel.crmFacility.crmFacilityName);
    // });

    if (this.doctorResponseModel.facility.length === 1 ) {
      this.selectedFacilities = [];
      this.selectedFacilities[0] = {label: this.doctorResponseModel.facility[0].crmFacilityName,
                                    value: this.doctorResponseModel.facility[0].crmFacilityId.toString()};
    } else if (this.doctorResponseModel.facility.length >= 1) {
      this.selectedFacilities = [];
      this.doctorResponseModel.facility.forEach((result) => {
        const tempDocFacility = {label: result.crmFacilityName, value: result.crmFacilityId.toString()};
        this.selectedFacilities.push(tempDocFacility);
      });
    }
    else{
      this.selectedFacilities = [];
    }
    this.isActive = this.doctorResponseModel.isActive;
    this.patientAtDocLocation = this.doctorResponseModel.crmIsPatientAllowedOnlyOnMyFacility;
    this.isFacilityVisitSelected = this.doctorResponseModel.crmIsFacilityVisitSelected;
    this.isRemoteVisitSelected = this.doctorResponseModel.crmIsRemoteVisitSelected;
    this.isRoadHomeVisitSelected = this.doctorResponseModel.crmIsRoadHomeVisitSelected;
  }

  private buildForm() {

    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.firstName, this.formBuilder.control('', { validators: [ CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.lastName, this.formBuilder.control('', { validators: [ CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [ CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [ CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    // formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [ CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }
}
