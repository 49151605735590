import { AbstractControl, ValidationErrors, ValidatorFn, FormGroup } from '@angular/forms';

export class CustomValidator {
  static whitespace(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const controlValue = control.value;
      if (controlValue.toString().trim().length > 0)
        return null;

      return { whitespace: true };
    }
    else
      return null;
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { email: true };
    }
    else
      return null;
  }

  static onlyCharacters(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^[a-zA-Z\s]+$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validCharacters: true };
    }
    else
      return null;
  }

  static alphaNumeric(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^[\w.]+$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validAlphaNumeric: true };
    }
    else
      return null;
  }

  static onlyNumbers(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const NUMBER_REGEXP = /^-?[\d.]+(?:e-?\d+)?$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validNumber: true };
    }
    else
      return null;
  }

  static onlyNumbersGreaterThanZero(control: AbstractControl): ValidationErrors | null {
 
    if (control.value) {
      if (control.value && Number(control.value)>0)
        return null;

      return { numberGreaterThanZero: true };
    }
    else
      return null;
  }

  static hostName(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const NUMBER_REGEXP = /^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/;
      if (NUMBER_REGEXP.test(control.value))
        return null;

      return { validHostName: true };
    }
    else
      return null;
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const PHONE_REGEXP = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
      if (PHONE_REGEXP.test(control.value))
        return null;

      return { validPhoneNumber: true };
    }
    else
      return null;
  }

  static URL(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const PHONE_REGEXP = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
      if (PHONE_REGEXP.test(control.value))
        return null;

      return { validURL: true };
    }
    else
      return null;
  }
  static ZoomURL(control: AbstractControl): ValidationErrors | null {
   
    if (control.value) {
      const zoom_REGEXP = /^https:\/\/\S*\.zoom\.us\/j\/\S*\?pwd=[^\s;]*$/;
      const url_REGEXP = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
      if (zoom_REGEXP.test(control.value) || url_REGEXP.test(control.value))
      {
          return null;
      }

      return { validZoomURL: true };
    }
    else
      return null;
  }


  static passwordPattern(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validStrongPassword: true };
    }
    else
      return null;
  }

  static creditCardNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const TEXT_REGEXP = /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { validCreditCardNumber: true };
    }
    else
      return null;
  }

  static positiveDecimalNumber(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      // const TEXT_REGEXP = /^\+?(0|[1-9]\d*)$/;
      const TEXT_REGEXP = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
      if (TEXT_REGEXP.test(control.value))
        return null;

      return { positiveInt: true };
    }
    else
      return null;
  }

  static range(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      if (Number(control.value) <= 180 && Number(control.value) > 0)
        return null;

      return { range: true };
    }
    else
      return null;
  }

  static multiSelectRequired(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {

      const selections = control.value;
      const isValid = selections.length > 0;
      const msg = 'selection required';

      return isValid ? null : { 'multiSelectRequired': { msg } };
    };
  }

  static passwordMatch(passwordFieldId: string, confirmPasswordFieldId: string): ValidatorFn {
    return (control: FormGroup): { [key: string]: boolean } | null => {
      var passwordField = control.controls[passwordFieldId];
      var confirmPasswordField = control.controls[confirmPasswordFieldId]
      if (passwordField == null || confirmPasswordField == null)
        return null;

      const password: string = passwordField.value;
      const confirmPassword: string = confirmPasswordField.value;

      if (password && confirmPassword) {
        if (password == confirmPassword)
          return null;

        return { validPasswordMatch: true };
      }
      else
        return null;
    };
  }
}
