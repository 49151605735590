export class TherapistDefaultFacilityResponseModel {
  crmTherapistDefaultFacility: number;
  crmTherapistId: number;
  crmFacilityId: number;
  crmIsDefault: boolean;
  isActive: boolean;
  crmCoverageArea: number;
  crmIsAllowSelfScheduleAppointments: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}
