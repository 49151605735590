import { AppointmentDetailResponse } from "./therapist-custom-availability-response-model";

export class DeleteAvailabilitySeriesModel {
    seriesAppointmentDetailModels: SeriesAppointmentDetailModel[];
    availabilitySeries: AvailabilitySeriesModel[];
}
export class SeriesAppointmentDetailModel {
    schAppiontmentId: number;
    schappoinmentOrigDate: Date;
    schAppointmentDateStartTime: Date;
}
export class AvailabilitySeriesModel {
    availId: number;
    crmTherpistId: number;
    custDateId: number;
    startTime: Date;
    endTime: Date
    appointmentType: string;
    startTimeStr: string;
    endTimeStr: string;
    timeZone: string;
    startTimeRange: Date;
    endTimeRange: Date;
    selectedDays: string;
    appointmentDetail: AppointmentDetailResponse;
    isAllReccuringDatesMatched: boolean;
    isRecurring: boolean;
    recurringType:number;
    recurringTypeMessage: string;
}