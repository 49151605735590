<div class="content-area admin-calendar-outer">
  <div class="title-block">
    <div class="title-block__left">
      <h1>Calendar </h1>
    </div>

    <div class="calendar-top-right">

      <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
          <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
          <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
        </svg> 
        <span class="loading">Refresh</span>
      </a> 
      
      <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
                  [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()" placeholder="Select Calendar View"
                  optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>

      <a href="javascript:void(0);" class="filter-block filter-show-hide" (click)="clanderFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>

  <p-overlayPanel appendTo="body" class="table-action-popup calendar-action-menu facilities-action"
                  styleClass="facilities-action" #BlockSlot [dismissable]="true">
    <ul class="quick-link-menu calendar-quick-menu">
      <li *ngIf="!isRemoteAndFacility">
        <a href="javascript:void(0)" (click)="getPatients(facilityId,false,0)">
          Fill Slot
        </a>
      </li>
      <li *ngIf="isRemoteAndFacility">
        <a href="javascript:void(0)" (click)="getPatients(facilityId,true,1)">
          Fill Slot For Facility Visit
        </a>
      </li>
      <li *ngIf="isRemoteAndFacility">
        <a href="javascript:void(0)" (click)="getPatients(remoteFacilityId,false,2)">
          Fill Slot For Remote Setup
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="setBlock()">
          Block Slot
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="setBreak()">
          Break Hours
        </a>
      </li>
    </ul>
  </p-overlayPanel>

  <p-overlayPanel appendTo="body" class="table-action-popup calendar-action-menu facilities-action"
                  styleClass="facilities-action" #ConextMenu [dismissable]="true">
    <ul class="quick-link-menu calendar-quick-menu">
      <li>
        <a href="javascript:void(0)" (click)="showRescheduleDialogPopup()">
          Reschedule
        </a>
      </li>
      <li *ngIf="selectedAppointment?.crmAppointmentType=='Telehealth' && isChangeRemoteAppointmentType && ((selectedAppointment?.schRemoteAppointmentType| lowercase) =='telemed' || (selectedAppointment?.schRemoteAppointmentType| lowercase) =='onlinemeeting')">
        <a (click)="convertAppointmentType()">
          Change To {{convertedRemoteAppointmentType}}
        </a>
      </li>

    </ul>
  </p-overlayPanel>

  <div class="calendra-top-btn">
    <div class="btn-group calendra-next-prev-btn">

      <div class="time-zone-filter">
        <a class="time-zone-filter__click" href="javascript:void(0)" (click)="timeZoneOverlay.show($event)">
          {{timeZoneCode}} ({{timeZoneCodeTherapistCount}}) <img src="assets/img/down-arrow.svg" width="12"
                                                                 alt="down-arrow">
        </a>


        <p-overlayPanel appendTo="body" class="table-action-popup timezone-filter-overly" #timeZoneOverlay
                        [dismissable]="true">

          <!--<ul class="quick-link-menu timezine-overly">
            <li *ngFor="let zone of timeZoneList;">
              <a href="javascript:void(0)" value="zone.appTimeZoneOffSet" (click)="ontimeZoneChange(zone.appTimeZoneOffSet);timeZoneOverlay.hide()">
                {{zone.appDisplayTimeZoneCode}}
                <span *ngIf="zone.appDisplayTimeZoneCode=='EST'">({{ ESTTherapistCount}})</span>
                <span *ngIf="zone.appDisplayTimeZoneCode=='CST'">({{ CSTTherapistCount}})</span>
                <span *ngIf="zone.appDisplayTimeZoneCode=='MST'">({{ MSTTherapistCount}})</span>
                <span *ngIf="zone.appDisplayTimeZoneCode=='PST'">({{ PSTTherapistCount}})</span>

              </a>
            </li>
          </ul>-->

          <ul class="quick-link-menu timezine-overly" *ngIf="defaultTimeZoneCode=='EST'">
            <li>
              <a href="javascript:void(0)" value="UTC-5" (click)="ontimeZoneChange('UTC-5');timeZoneOverlay.hide()">
                EST
                ({{ ESTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-6" (click)="ontimeZoneChange('UTC-6');timeZoneOverlay.hide()">
                CST
                ({{ CSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-7" (click)="ontimeZoneChange('UTC-7');timeZoneOverlay.hide()">
                MST
                ({{ MSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UMST-7" (click)="ontimeZoneChange('UMST-7');timeZoneOverlay.hide()">
                AMST
                ({{ AMSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-8" (click)="ontimeZoneChange('UTC-8');timeZoneOverlay.hide()">
                PST
                ({{ PSTTherapistCount}})
              </a>
            </li>
          </ul>
          <ul class="quick-link-menu timezine-overly" *ngIf="defaultTimeZoneCode=='PST'">
            <li>
              <a href="javascript:void(0)" value="UTC-8" (click)="ontimeZoneChange('UTC-8');timeZoneOverlay.hide()">
                PST
                ({{ PSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-7" (click)="ontimeZoneChange('UTC-7');timeZoneOverlay.hide()">
                MST
                ({{ MSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UMST-7" (click)="ontimeZoneChange('UMST-7');timeZoneOverlay.hide()">
                AMST
                ({{ AMSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-6" (click)="ontimeZoneChange('UTC-6');timeZoneOverlay.hide()">
                CST
                ({{ CSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-5" (click)="ontimeZoneChange('UTC-5');timeZoneOverlay.hide()">
                EST
                ({{ ESTTherapistCount}})
              </a>
            </li>
          </ul>
          <ul class="quick-link-menu timezine-overly" *ngIf="defaultTimeZoneCode=='MST'">
            <li>
              <a href="javascript:void(0)" value="UTC-7" (click)="ontimeZoneChange('UTC-7');timeZoneOverlay.hide()">
                MST
                ({{ MSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UMST-7" (click)="ontimeZoneChange('UMST-7');timeZoneOverlay.hide()">
                AMST
                ({{ AMSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-8" (click)="ontimeZoneChange('UTC-8');timeZoneOverlay.hide()">
                PST
                ({{ PSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-6" (click)="ontimeZoneChange('UTC-6');timeZoneOverlay.hide()">
                CST
                ({{ CSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-5" (click)="ontimeZoneChange('UTC-5');timeZoneOverlay.hide()">
                EST
                ({{ ESTTherapistCount}})
              </a>
            </li>
          </ul>

          <ul class="quick-link-menu timezine-overly" *ngIf="defaultTimeZoneCode=='AMST'">
            <li>
              <a href="javascript:void(0)" value="UMST-7" (click)="ontimeZoneChange('UMST-7');timeZoneOverlay.hide()">
                AMST
                ({{ AMSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-7" (click)="ontimeZoneChange('UTC-7');timeZoneOverlay.hide()">
                MST
                ({{ MSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-8" (click)="ontimeZoneChange('UTC-8');timeZoneOverlay.hide()">
                PST
                ({{ PSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-6" (click)="ontimeZoneChange('UTC-6');timeZoneOverlay.hide()">
                CST
                ({{ CSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-5" (click)="ontimeZoneChange('UTC-5');timeZoneOverlay.hide()">
                EST
                ({{ ESTTherapistCount}})
              </a>
            </li>

          </ul>
          <ul class="quick-link-menu timezine-overly" *ngIf="defaultTimeZoneCode=='CST'">
            <li>
              <a href="javascript:void(0)" value="UTC-6" (click)="ontimeZoneChange('UTC-6');timeZoneOverlay.hide()">
                CST
                ({{ CSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-5" (click)="ontimeZoneChange('UTC-5');timeZoneOverlay.hide()">
                EST
                ({{ ESTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-7" (click)="ontimeZoneChange('UTC-7');timeZoneOverlay.hide()">
                MST
                ({{ MSTTherapistCount}})
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" value="UTC-8" (click)="ontimeZoneChange('UTC-8');timeZoneOverlay.hide()">
                PST
                ({{ PSTTherapistCount}})
              </a>
            </li>




          </ul>

        </p-overlayPanel>
      </div>

      <div class="mwl-calendar">
        <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="'day'" [(viewDate)]="viewDate"
             (viewDateChange)="GetNextDayAvailability()">
          <i class="arrow-prev"></i>
        </div>

        <div class="btn btn--primary" mwlCalendarToday [(viewDate)]="viewDate"
             (viewDateChange)="GetNextDayAvailability()">
          Today
        </div>

        <div class="btn btn--arrow next" mwlCalendarNextView [view]="'day'" [(viewDate)]="viewDate"
             [ngClass]="nextBtnDisabled?'disable':''" (viewDateChange)="GetNextDayAvailability()">
          <i class="arrow-next"></i>
        </div>

        <div class="calendar-day">
          <p-calendar class="datepicker therapist-select-date" [readonlyInput]="true" [(ngModel)]="viewDate" appendTo="body"
                      dateFormat="{{appDateFormat.primeFormat}}" [numberOfMonths]="1" [showIcon]="true"
                      [maxDate]="maxDateToDisplay" (onSelect)="GetNextDayAvailability()">
          </p-calendar>
          <span class="weekday-span">( {{viewDate | date:'EEEE'}} )</span>
        </div>
      </div>
    </div>

    <div class="calendar-right-sidebar">
      <button class="btn btn--primary  reassign-btn" [disabled]="isDisabled || isDisabledReassignment" (click)="ReassignAppointmentDialog()">
        <i class="reassign-appointment-icon"></i> Reassign Appointment(s)
      </button>
      <button class="btn btn--primary" [disabled]="isDisabled" (click)="updatemenu.toggle($event)">
        <i class="update-icon"></i> Update Status
      </button>
      <p-menu #updatemenu [popup]="true" class="update-status-menu" [model]="appointmentsalesOrderStatusMenuitems">
      </p-menu>
    </div>

  </div>
  <!--<div class="calendra-top-btn__next-day-appointment">

  </div>-->
  <div *ngIf="isBlankMessageshow" class="calendar-outer no-records-found">
    Facilities not found with associated therapists for selected branch.
  </div>

  <div *ngIf="isBlankMessageforTherapistShow" class="calendar-outer no-records-found">
    Therapist(s) not available for selected facility.
  </div>

  <div *ngIf="isHoliday" class="calendar-outer no-records-found">
    {{holidayMessage}}
  </div>

  <div *ngIf="!isBlankMessageshow && !isBlankMessageforTherapistShow && !isHoliday" class="calendar-outer">
    <!--<div class="loader-block" *ngIf="loading">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s"></p-progressSpinner>
    </div>-->

    <div class="details-pages admin-availability-calandar admin-availability-calandar--view-by-facility">
      <div class="admin-availabilty-calc">
        <div class="admin-availabilty-calc__left">
          <div class="therapist-availabilty-calc__left__block view-by-facility">
            <!--< <div class="ui-g-12 ui-md-12">
            p-scrollPanel [style]="{width: '100%', height: '100%'}">-->
            <div class="therapist-availabilty-calc">
              <div class="therapist-availabilty-calc__left">
                <div class="horizontal-scroll" *ngIf="events$ | async; else loading; let events">

                  <ng-template #currentTimeMarkerTemplate let-columnDate="columnDate"
                               let-dayStartHour="dayStartHour" let-dayStartMinute="dayStartMinute" let-dayEndHour="dayEndHour"
                               let-dayEndMinute="dayEndMinute" let-isVisible="isVisible" let-topPx="topPx">
                    <div class="cal-current-time-marker" *ngIf="isVisible && showMarker" [style.top.px]="topPx">
                    </div>
                  </ng-template>

                  <mwl-day-view-scheduler *ngIf="users.length>0" [viewDate]="viewDate" [events]="events"
                                          [users]="users" [patientBrighttreeURL]="patientBrighttreeURL"
                                          [salesOrderBrighttreeURL]="salesOrderBrighttreeURL"
                                          (eventClicked)="handleEvent('Clicked', $event.event)"
                                          (eventTimesChanged)="eventTimesChanged($event)"
                                          (popupCalled)="showDialogPopup($event,ConextMenu,BlockSlot)"
                                          (selectedAppoinmentCalled)="selectedAppoinmentCalled($event)"
                                          (removeSlotCalled)="removeSlotCalled($event,events)"
                                          (editSlotCalled)="editSlotCalled($event,events)" (removeBreakCalled)="removeBreakCalled($event)"
                                          (editBreakCalled)="editBreakCalled($event,events)"
                                          (selectedRowAppoinmentCalled)="selectedRowAppoinmentCalled($event)" [refresh]="refresh"
                                          [dayStartHour]="dayStartHour" [dayStartMinute]="dayStartMinute" [dayEndHour]="dayEndHour"
                                          [dayEndMinute]="dayEndMinute" [hourSegments]="4" [hourSegmentHeight]="130"
                                          [selectedAppointment]="selectedAppointments"
                                          [currentTimeMarkerTemplate]="currentTimeMarkerTemplate"
                                          (hourSegmentClickedOverrited)="hourSegmentClick($event,BlockSlot,ConextMenu)"
                                          (ontimeZoneChange)=ontimeZoneChange($event) [timeZoneCode]="timeZoneCode">

                  </mwl-day-view-scheduler>

                </div>
              </div>
            </div>
            <!--</p-scrollPanel
            </div>-->

          </div>
        </div>

      </div>

      <!--</div>-->
    </div>
    <ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>
    <div class="loader-block" *ngIf="loadingCustom" style="z-index:99999999999">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
    <!--[ngStyle]="{'display': loadingBlockSlotBrakHours ? 'block' : 'block'}"-->
    <div class="loader-block" *ngIf="loadingBlockSlotBrakHours" style="z-index:99999999999 !important">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </div>
</div>


<!-- filter -->
<p-overlayPanel class="overly-filter" #clanderFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">
      <div class="road-show-checkbox">
        <p-checkbox name="groupname" [(ngModel)]="isShowHomeVisit" (change)="onCheck()" label="Road/Home Appt"
                    binary="true"></p-checkbox>
      </div>
      <div class="form__group">
        <label class="form__label">Branch</label>
        <p-multiSelect class="select-box select-branch filter-status" [(ngModel)]="selectedbranches"
                       [options]="branchResponseModel" defaultLabel="Select branch" (onChange)="onBranchChange($event)"
                       optionLabel="crmBranchName" [filter]="true" selectedItemsLabel="{0} Branch(s) Selected"></p-multiSelect>
      </div>
      <div class="form__group">
        <label class="form__label">Facility</label>
        <p-multiSelect class="select-box select-branch filter-status" [(ngModel)]="selectedAppointmentFacilities"
                       [options]="facilityResponseModel" defaultLabel="Select Facility" (onChange)="onFacilityChange($event)"
                       optionLabel="crmFacilityName" [filter]="true" selectedItemsLabel="{0} facility(s) Selected"></p-multiSelect>
      </div>
      <div class="form__group">
        <label class="form__label">Therapist</label>
        <p-multiSelect class="select-box select-branch filter-status" [(ngModel)]="selectedTherapists"
                       [options]="therapistResponseModel" defaultLabel="Select Therapist" (onChange)="onTherapistChange($event)"
                       optionLabel="appUserFullname" [filter]="true" selectedItemsLabel="{0} therapist(s) Selected"></p-multiSelect>
      </div>
    </div>
    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
         (click)="onClearFilterSearch();onFilterSearch();">Clear</a>
    </div>
  </div>
</p-overlayPanel>

<!-- Next day Appointment Popup -->
<p-overlayPanel class="next-day-appointment-popup" #NextdayAppointment [showCloseIcon]="true">
  <div class="next-day-appointment">
    <div class="form__group" style="margin: 1rem 0 0 0;">
      <label class="form__label">Facilities</label>
      <p-multiSelect class="select-box" [(ngModel)]="selectedFacilities" [filter]="true" [virtualScroll]="true"
                     [ngModelOptions]="{standalone: true}" optionLabel="crmFacilityName" [options]="facilityResponseModel"
                     dataKey="crmFacilityName" placeholder="Select Facilities" selectedItemsLabel="{0} Facilities Selected">
      </p-multiSelect>
    </div>
    <div class="set-custom-date-btn">
      <button class="btn btn--primary" (click)="sendSchedule($event,NextdayAppointment)">Send</button>
    </div>
  </div>
</p-overlayPanel>


<!-- Next day Appointment Popup -->
<p-dialog [(visible)]="ReassignAppointment" class="reassignAppointment-outer" [modal]="true" (onHide)="cancel()">
  <div class="reassign-appointment">
    <h3>Reassign Appointment of "{{selectedUser?.name}}" on "{{viewDate | date:appDateFormat.dateFormat}}"</h3>

    <div class="reassign-appointment__facility">

      <table mat-table [dataSource]="dataSource" class="reassign-appointment__facility__table mat-elevation-z8" matSort>

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef sticky>
            <div class="checkbox-text">
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="checkbox-text">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </div>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="crmSalesorderAppId">
          <th mat-header-cell *matHeaderCellDef> S.O. ID. </th>
          <td mat-cell *matCellDef="let element">{{element?.salesOrderInfo?.crmSalesorderAppId}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="crmPatientName">
          <th mat-header-cell *matHeaderCellDef> Patient </th>
          <td mat-cell *matCellDef="let element">
            <span class="patient">
              {{element?.salesOrderInfo?.patientInfo?.crmPatientLastName}},
              {{element?.salesOrderInfo.patientInfo?.crmPatientFirstName}}
              <p>{{element?.salesOrderInfo?.patientInfo?.crmRcmPatientid}}</p>
            </span>

          </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="schAppointmentDateStartTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
          <td mat-cell *matCellDef="let element">
            {{
            element?.schAppointmentDateStartTime | date:'shortTime' |
            lowercase
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="crmPatientTimeZone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Time Zone </th>
          <td mat-cell *matCellDef="let element">
            {{element?.appointmentDetailInfo?.crmPatientTimeZone | timezone}}
          </td>
        </ng-container>

        <ng-container matColumnDef="availableReAssgTherapist">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Available Therapist</th>
          <td mat-cell mat-cell *matCellDef="let element"
              (click)="GetAllAvailableTherapistForReassignmentInline(element)">
            <mat-select class="mat-select-box" (selectionChange)="onReassignTherapistChange(element,$event)"
                        [ngModel]="selectedtherapistAvailabilityForReassignResponseModel" placeholder="Select Therapist">
              <mat-option *ngFor="let therapist of getavailableThrepaist(element?.schAppointmentId)" [value]="therapist">
                {{therapist.appUserFullname}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="isSetTounAvailability">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Set UnAvailability for <br>{{selectedUser?.name}}</th>
          <td mat-cell *matCellDef="let row">
            <div class="checkbox-text">
              <mat-checkbox (change)="onisMakeUnAvailable(row,$event)">
              </mat-checkbox>
            </div>
          </td>
          <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header>Set UnAvailability for <br>{{selectedUser?.name}}</th>
          <td mat-cell *matCellDef="let row">
            <div class="checkbox-text text-center">
              <mat-checkbox [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </div>
          </td> -->
        </ng-container>

        <!-- Symbol Column -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <!-- <h3 class="second-tile">Available Therapist on "{{viewDate | date:appDateFormat.dateFormat}}"</h3> -->
    <!-- <div class="reassign-appointment__drop-dwon-call">
      <div class="reassign-appointment__drop-dwon-call__dropDwon">
        <p-dropdown class="select-box drop-dwon-bottom" [options]="therapistAvailabilityForReassignResponseModel"
          name="Therapist" placeholder="Select Therapist"
          [(ngModel)]="selectedtherapistAvailabilityForReassignResponseModel" optionLabel="appUserFullname"
          [showClear]="true" [filter]="true" (onChange)="onReassignTherapistChange($event)">
        </p-dropdown>
      </div>
      <button *ngIf="selectedtherapistAvailabilityForReassignResponseModel?.appUserPhoneNumber && !isCallStarted"
        class="btn btn--secondary small"
        (click)="TherapistPhonecall(selectedtherapistAvailabilityForReassignResponseModel?.appUserPhoneNumber)">Call
        Therapist</button>
      <button *ngIf="selectedtherapistAvailabilityForReassignResponseModel?.appUserPhoneNumber && isCallStarted"
        class="btn btn--secondary--modify small" (click)="TherapistHangup()">Hang Up</button>
    </div> -->

    <div class="reassign-appointment__therapist-available"
         *ngIf="selectedtherapistAvailabilityForReassignResponseModel">
      <p *ngIf="selectedtherapistAvailabilityForReassignResponseModel?.isAvailable && selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels.length==1">
        "<strong>{{selectedtherapistAvailabilityForReassignResponseModel?.appUserFullname}}</strong>" is available at
        "<strong>{{selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels[0].crmFacilityName}}</strong>"
        from
        "<strong>
          {{
          selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels[0].crmStartTime |
          date:'shortTime' | lowercase
          }} to
          {{
          selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels[0].crmEndTime |
          date:'shortTime' | lowercase
          }}
        </strong>"
      </p>
      <div class="available-therapist"
           *ngIf="selectedtherapistAvailabilityForReassignResponseModel?.isAvailable && selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels.length>1">
        "<strong>{{selectedtherapistAvailabilityForReassignResponseModel?.appUserFullname}}</strong>" is available at
        <b *ngFor="let item of selectedtherapistAvailabilityForReassignResponseModel?.therapistAvailabilityModels; let i = index; let last = last;">
          <strong>"{{item.crmFacilityName}}"</strong> from "<strong>
            {{item.crmStartTime | date:'shortTime' | lowercase}}
            to {{item.crmEndTime | date:'shortTime' | lowercase}}
          </strong>"
          <b *ngIf="!last"> and </b>
        </b>

      </div>
      <b class="available-therapist" *ngIf="selectedtherapistAvailabilityForReassignResponseModel?.isAvailable ==false">
        Availability for <strong>"{{selectedtherapistAvailabilityForReassignResponseModel?.appUserFullname}}</strong>"
        is not set."
      </b>

    </div>
    <!-- <div class="reassign-appointment__therapist-available">
      <span class="checkbox-unavailable ">
        <p-checkbox binary="true" [(ngModel)]="checked"></p-checkbox>
        <p>Do you want to make "<strong>{{selectedUser?.name}}</strong>" unavailable on "<strong>{{viewDate |
            date:appDateFormat.dateFormat}}</strong>"?</p>
      </span>
    </div> -->
    <div class="reassign-appointment__btns">
      <button class="btn btn--primary" type="submit" (click)="ReassignAppointmentClick();"> Submit </button>
    </div>
  </div>
</p-dialog>


<p-dialog appendTo="body" class="fill-slot-popup" [(visible)]="twoFacilityPopup" [modal]="true">
  <div class="select-options-facility">
    <div class="select-options-facility__title">
      <h2>Choose Appontment Type</h2>
    </div>
    <div class="select-options-facility__content">
      <div class="form__group">
        <p-radioButton name="groupname" value="val1" label="Facility Visit"></p-radioButton>
        <p-radioButton name="groupname" value="val2" label="Remote Setup"></p-radioButton>
      </div>
      <div class="btn-block">
        <button class="btn btn--primary">Confirm</button>
        <a class="btn btn--secondary clear-filter" href="javascript:void(0)">Cancel</a>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Fill Slot Popup-->
<p-dialog appendTo="body" class="fill-slot-popup" [(visible)]="fillSlotPopup" [modal]="true">
  <div class="fill-slot-popup__outer">
    <div class="therapist-availabilty__block">
      <div class="fill-slot-popup__title">
        <div class="choose-type" *ngIf="isRemoteAndFacility">
          <h2>Select Appointment Type</h2>
          <div class="form__group">
            <p-radioButton name="groupname" value="FacilityVisit" label="Facility Visit" [(ngModel)]="selectedApptType"
                           (click)="getPatients(facilityId,true,1)">
            </p-radioButton>
            <p-radioButton name="groupname" value="RemoteSetup" label="Remote Setup" [(ngModel)]="selectedApptType"
                           (click)="getPatients(remoteFacilityId,false,2)"></p-radioButton>
          </div>
        </div>

        <h2>Search Nearby Patient</h2>
      </div>
      <div class="search-fiter view-therapist-appointments-filter">
        <div class="form__group">
          <div class="search-box">
            <input type="text" class="form__control" pInputText placeholder="Patient Name, DOB, Zip Code"
                   [(ngModel)]="searchText" (keyup)="textChange()" (keyup.enter)="SearchPatient()">

            <button class="search-btn" pButton type="button" *ngIf="showClear" (click)="searchCloseClick()">
              <i class="ic cancel"></i>
            </button>
            <button class="search-btn" pButton type="button" (click)="SearchPatient()">
              <i class="search-new"></i>
            </button>
          </div>

          <div class="filter-btn">
            <a href="javascript:void(0);" pTooltip="Filter" (click)="filterBox.show($event);">
              <img src="assets/img/filter.svg" width="22" alt="filter">
            </a>
          </div>
        </div>

        <p-overlayPanel #filterBox [dismissable]="true" [showCloseIcon]="true" styleClass="dme-therapist-search-by-filters">
          <div class="filter-checkbox choose-branch">
            <h3>Search By Filters</h3>
            <div class="filter-checkbox__branch">
              <label class="form__label">Branch</label>
              <span class="switchBtn" style="margin: 0 0 8px 1px;">
                <p-radioButton name="group1" [value]="true" label="All Branch" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllBranchFilterSelected">
                </p-radioButton>
                <p-radioButton name="group1" [value]="false" label="Choose Branch" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllBranchFilterSelected">
                </p-radioButton>
              </span>
              <p class="filter-checkbox__info" *ngIf="isAllBranchFilterSelected">All available Branches will be selected with this.</p>
              <p-multiSelect class="select-box" [(ngModel)]="selectedFilterBranches" [ngModelOptions]="{standalone: true}"
              *ngIf="!isAllBranchFilterSelected" optionLabel="crmBranchName" [options]="branchResponseModel"
                dataKey="crmBranchId" placeholder="Select Branches" selectedItemsLabel="{0} Branches Selected"
                [filter]="true"></p-multiSelect>
                <span class="invalid-feedback" *ngIf="isFilterSubmit && !isAllBranchFilterSelected && selectedFilterBranches?.length==0">Kindly select at least one Branch</span>
            </div>
            <div class="filter-checkbox__branch">
              <label class="form__label">Classification</label>
              <span class="switchBtn" style="margin: 0 0 8px 1px;">
                <p-radioButton name="group1" [value]="true" label="All Classification" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllClssificationFilterSelected">
                </p-radioButton>
                <p-radioButton name="group1" [value]="false" label="Choose Classification" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllClssificationFilterSelected">
                </p-radioButton>
              </span>
              <p  class="filter-checkbox__info" *ngIf="isAllClssificationFilterSelected">All available Classifications will be selected with this.</p>
              <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                *ngIf="!isAllClssificationFilterSelected" [(ngModel)]="selectedClassificationType"
                [options]="filterClassificationList" placeholder="Select Classifications" optionLabel="crmClassificationDisplayName"
                [showToggleAll]="false"
                selectedItemsLabel="{0} Classifications Selected"></p-multiSelect>
              <span class="invalid-feedback" *ngIf="isFilterSubmit && !isAllClssificationFilterSelected && selectedClassificationType?.length==0">Kindly select at least one Classification</span>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="group1" value="ReadyForScheduling" label="Ready for Scheduling"

                [(ngModel)]="selectedAppointmentValues" inputId="ny" ></p-checkbox>

            </div>
            <div class="ui-g-12">
              <p-checkbox name="group1" value="NoShow" label="No Show" [(ngModel)]="selectedAppointmentValues"

                inputId="sf"></p-checkbox>


            </div>
            <div class="ui-g-12">
              <p-checkbox name="group1" value="Cancel" label="Cancel" [(ngModel)]="selectedAppointmentValues"
                inputId="la" ></p-checkbox>
            </div>

            <div class="ui-g-12 buttons">
              <button class="btn btn--secondary  mb-0" style="margin-right: 1.5rem;" (click)="onClearFilter()">Clear</button>
              <button class="btn btn--primary mb-0" (click)="onAppointmentChange(filterBox)">Search</button>
            </div>

          </div>
        </p-overlayPanel>
      </div>

      <div class="fill-slot-popup__content">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
          <div class="fill-slot-popup__content__block" *ngFor="let patient of nearByPatients">
            <div class="fill-slot-popup__content__block__link">
              <div class="fill-slot-popup__content__block__top">
                <h3 class="title">
                  <a href="javascript:void(0)" class="link"
                     (click)="showScheduleDialog(patient.crmSalesOrderId,patient.schAppointmentId,patient.crmPatientAllowedOnlyOnMyFacility)">
                    {{patient.crmPatientName}} <ng-container *ngIf="!selectedFacility.isRemoteAssistance">
                      |
                      {{patient.crmPatientTimeZone | timezone}}
                    </ng-container>
                  </a>
                </h3>
                <span *ngIf="!selectedFacility.isRemoteAssistance"
                      class="miles">{{patient.crmPatientDistanceInMiles}}</span>
              </div>
              <div class="fill-slot-popup__content__block__bottom">
                <span class="status-block-box"
                      [ngClass]="patient.crmSalesorderStatus=='ReadyForScheduling'?'ready-for-scheduling':patient.crmSalesorderStatus=='Cancel'?'cancel':patient.crmSalesorderStatus=='NoShow'?'no-show':''">{{patient.crmSalesorderStatusDisplay}}</span>

                <span class="phone-call">({{patient.crmPatientCallCount}}) Call</span>

              </div>
            </div>
          </div>
          <div class="fill-slot-popup__content__block" *ngIf="nearByPatients?.length==0">
            Patient(s) not found in therapist's licensed states.
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Block Slot Popup-->
<p-dialog class="fill-slot-popup" appendTo="body" [(visible)]="blockSlotPopup" [modal]="true">
  <div class="fill-slot-popup__outer">
    <div class="therapist-availabilty__block">
      <div class="fill-slot-popup__title">
        <h2>{{labelFor}} for {{selectedtherapist}}</h2>
        <div class="fill-slot-popup__title__timeslot">
          <span class="time">
            {{searchDate|date:appDateFormat.dateFormat}}
            <!--<small>&nbsp;|&nbsp; EST</small>-->
          </span>
        </div>
      </div>

      <div class="fill-slot-popup__content fill-slot-popup__content--block-slot-content">
        <div class="block-slot-outer">
          <div class="search-box" *ngIf="isBlock">
            <label class="label">Description</label>
            <textarea pInputTextarea class="form__control" [(ngModel)]="blockDesc" maxlength="250"></textarea>
          </div>
          <div class="col">
            <div class="form__group">
              <span class="event-series">
                Start Time
              </span>
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
            </div>
            <div class="form__group">
              <span class="event-series">End Time</span>
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
            </div>
          </div>

          <span class="time-note" style=" margin: -20px 0 2rem 0 !important; display: table;">Please do not use the 0 prefixes in Hours and Minutes</span>


          <div class="set-custom-date-btn">
            <button class="btn btn--primary" [disabled]="isButtonDisabled" type="button" (click)="onSave()">Update</button>
            <button class="btn btn--secondary" (click)="closeSideBar()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-dialog>


<!-- No Show Appointment Popup-->
<p-dialog [(visible)]="displayNoShowAppointment" styleClass="display-close-icon" [modal]="true" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>No Show Appointment</h2>
    <div class="schedulecall">

      <div class="col">
        <div class="form__group full-width">
          <label class="form__label">Are you sure you want to no show appointment ?</label>
        </div>
      </div>
      <div class="schedulecall">
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Comment</label>

            <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonCommentsNoShow"
                      maxlength="100"></textarea>
          </div>
        </div>
        <div class="col">
          <div class="check receive-reminder wip-state-will-not-update">
            <p-checkbox name="groupnamenoshow" label="If box checked, the WIP State will not be updated in Brightree"
                        [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)" [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
        </div>

        <div class="appointment-calcel-btn">
          <button class="btn btn--primary" (click)="CompleteNoShowAppointment()">Submit</button>
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideNoShowPopup()">Cancel</a>
        </div>
      </div>

    </div>
  </div>
</p-dialog>


<!-- Completed Appointment Popup-->
<p-dialog [(visible)]="displayCompleteAppointment" styleClass="display-close-icon" [modal]="true" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>Complete Appointment</h2>
    <div class="schedulecall">

      <div class="schedulecall">
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Are you sure you want to complete appointment ?</label>
          </div>
        </div>
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Comment</label>

            <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonCommentsComplete"
                      maxlength="100"></textarea>
          </div>
        </div>
        <div class="col">
          <div class="check receive-reminder wip-state-will-not-update">
            <p-checkbox name="groupnamecomplete" label="If box checked, the WIP State will not be updated in Brightree"
                        [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)" [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
        </div>

        <div class="appointment-calcel-btn">
          <button class="btn btn--primary" (click)="CompleteNoShowAppointment()">Submit</button>
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideCompletePopup()">Cancel</a>
        </div>
      </div>

    </div>
  </div>
</p-dialog>


<!-- 41466:This is to restrict No Show and Complete appointment status -->
<p-dialog [(visible)]="ConfirmSetStatusChange" styleClass="display-close-icon" [modal]="true" appendTo="body">
  <div class="popup-content-outer confirm-invite-patients">
    <div *ngIf="restrictedApptFromNoShow?.length != totalSelectedNoShowAppointments">
      <h2>Confirm Setting {{statusType}} Status to Appointments</h2>
      <div *ngIf="restrictedApptFromNoShow?.length==0">
        <div class="confirm-invite-patients__content-part">
          <p>
            You are about to set {{statusType}} status to {{totalSelectedNoShowAppointments}} appointment(s). Please click <strong>
              “Yes,
              confirm”
            </strong> to set {{statusType}} status to {{totalSelectedNoShowAppointments}} appointment(s).
          </p>
        </div>
      </div>

      <div *ngIf="restrictedApptFromNoShow?.length>0">
        <div class="confirm-invite-patients__content-part">
          <p>
            You have selected {{totalSelectedNoShowAppointments}} patient(s), but {{restrictedApptFromNoShow?.length}} of them are not eligible for {{statusType}} status, because Appointments time is not due yet.
          </p>
          <p *ngIf="restrictedApptFromNoShow?.length>0">
            Please click <strong>
              “Yes,
              confirm”
            </strong> to set {{statusType}} status to {{
totalSelectedNoShowAppointments -
                restrictedApptFromNoShow?.length
            }} patient(s).
          </p>
          <p><strong>The following patient(s) appointments are not eligible to set {{statusType}} Status:</strong></p>
        </div>
        <div class="popup-table-outer">
          <table class="custom-table">
            <thead>
              <tr>
                <th>S/O ID</th>
                <th>Patient Name</th>
                <th>Appointment Time</th>
                <!-- <th>Time Zone</th> -->
              </tr>
            </thead>
            <tbody *ngFor="let restrictedSalesOrder of restrictedApptFromNoShow">
              <tr>
                <td>{{restrictedSalesOrder.crmSalesOrderAPPId}}</td>
                <td>{{restrictedSalesOrder.crmPatientName | titlecase}}</td>
                <td>{{restrictedSalesOrder.schAppointmentDateTime }}</td>
                <!-- <td>{{restrictedSalesOrder.crmPatientTimeZone }}</td> -->
              </tr>
            </tbody>

          </table>
        </div>
      </div>

      <div class="confirm-invite-patients__buttons-box">
        <a href="javascript:void(0)" class="btn btn--secondary" (click)="ConfirmSetStatusCancel()">No, Cancel</a>
        <button class="btn btn--primary" (click)="ConfirmSetStatusAppoinments()">Yes, Confirm</button>
      </div>
    </div>

    <div *ngIf="restrictedApptFromNoShow?.length == totalSelectedNoShowAppointments">
      <h2>Can't Set {{statusType}} Status To Selected Appointments</h2>
      <div class="confirm-invite-patients__content-part">
        <p>You can't set {{statusType}} status to the selected appointments because the Appointment time is not due yet.</p>
      </div>

      <div class="confirm-invite-patients__buttons-box">
        <button class="btn btn--primary" (click)="ConfirmSetStatusCancel()">Ok</button>
      </div>
    </div>
  </div>
</p-dialog>
