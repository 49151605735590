export class WIPStatesResponseModel{

    wipStatesId:number;
    wipStatesName:string;
    
 }

export class SalesOrderStatusResponseModel{

    wipsatatus:WIPStatesResponseModel[];
}

