import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedRoutingModule } from './shared-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { TitleComponent } from './title/title.component';
//import { SetPasswordComponent } from './set-password/set-password.component';

//import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { MessageService } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule as inlineCalendar } from 'primeng/calendar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TherapistHeaderComponent } from './header/therapist-header/therapist-header.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarComponent } from './sidebar/SidebarComponent';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { FooterComponent } from 'src/app/shared/footer/footer.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { NotificationViewAllComponent } from './notification/notification.component';
import {MenuModule} from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputMaskModule } from 'primeng/inputmask';
import { PatientHeaderComponent } from './patient-header/patient-header.component';
import { PatientFooterComponent } from './patient-footer/patient-footer.component';
import { BookAppoinmentDialogComponent } from 'src/app/shared/components/book-appoinment-dialog/book-appoinment-dialog.component';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { SupportRequestComponent } from './support-request/support-request.component';
import { SupportComponent } from './support/support.component';
import { PageUnauthorizedComponent } from './page-unauthorized/page-unauthorized.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { SearchPatientBrightreeComponent } from './search-patient-brightree/search-patient-brightree.component';
import { RadioButtonModule } from 'primeng/radiobutton';

import { GoogleMapSharedComponent } from './google-map-shared/google-map-shared.component';
import { GoogleMapsModule } from '@angular/google-maps'

import { PhonePipe } from 'src/app/pipes/usnumberformat.pipe';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { FilterPipe } from '../pipes/FilterPipe';
import { MobileDetectionService } from './services/mobiledetection.service';
import { EmailLogsComponent } from './email-logs/email-logs.component';
import { SmsLogsComponent } from './sms-logs/sms-logs.component';

import { UpdateStatusOfAppointmentComponent } from './components/update-status-of-appointment/update-status-of-appointment.component';
import { UpdateStatusOfAppointmentsComponent } from './components/update-status-of-appointments/update-status-of-appointments.component';

import { InputSwitchModule } from 'primeng/inputswitch';

import { OverrideMergeDialogComponent } from 'src/app/shared/components/override-merge-dialog/override-merge-dialog.component';
import { LegendsInfoComponent } from './legends-info/legends-info.component';
import { PipesModule } from '../pipes/pipes/pipes.module';

export const MyDefaultTooltipOptions: TooltipOptions = {
  'placement': 'right'
}
@NgModule({
  declarations: [BookAppoinmentDialogComponent, PatientHeaderComponent,
    PatientFooterComponent, ConfirmDialogComponent, InformationDialogComponent,
    TherapistHeaderComponent, ProfileComponent, ChangePasswordComponent,
    SidebarComponent, HeaderComponent, FooterComponent,
    NotificationViewAllComponent, TitleComponent,
        SupportRequestComponent, SupportComponent, PageUnauthorizedComponent, AudioPlayerComponent, SearchPatientBrightreeComponent, GoogleMapSharedComponent, PhonePipe, FilterPipe, EmailLogsComponent, SmsLogsComponent, UpdateStatusOfAppointmentComponent, UpdateStatusOfAppointmentsComponent, OverrideMergeDialogComponent,
        LegendsInfoComponent],

  imports: [
    CommonModule,
    SharedRoutingModule,
    CheckboxModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
    PanelMenuModule,
    DropdownModule,
    ScrollPanelModule,
    OverlayPanelModule,
    inlineCalendar,
    ProgressSpinnerModule,
    DialogModule,
    DynamicDialogModule,
    MenuModule,
    MultiSelectModule,
    InputMaskModule,
    RadioButtonModule,
    GoogleMapsModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    TabViewModule,
    InputSwitchModule,
    PipesModule,
  ],
  providers: [
    CookieService,
    MessageService,
    MobileDetectionService
  ],

  exports: [
    CheckboxModule,
    SidebarModule,
    ToastModule,
    PanelMenuModule,
    DropdownModule,
    ScrollPanelModule,
    OverlayPanelModule,
    TherapistHeaderComponent,
    ProfileComponent,
    ChangePasswordComponent,
    PatientHeaderComponent,
    PatientFooterComponent,
    inlineCalendar,
    DialogModule,
    DynamicDialogModule,
    ProgressSpinnerModule,
    SidebarComponent, HeaderComponent, FooterComponent,
    MultiSelectModule,
    InputMaskModule,
    AudioPlayerComponent,
    InputMaskModule,
    RadioButtonModule,
    PhonePipe,
    TabViewModule,
    FilterPipe,
    EmailLogsComponent, 
    SmsLogsComponent,
    InputSwitchModule,
    LegendsInfoComponent
  ],
  entryComponents:
    [
      ConfirmDialogComponent,
      BookAppoinmentDialogComponent,
      InformationDialogComponent

    ]
})
export class SharedModule { }
