<app-patient-header></app-patient-header>
<section class="main-patient-outer">
    <div class="cancelled-box-outer">
        <div class="cancelled-box-outer__box">
            <h1 class="cancelled-box-outer__title">Hi, {{patientName | titlecase}}!</h1>
            <div class="cancelled-box-outer__cancelled-text">Your {{appointmentTitle}} appointment has been successfully cancelled.</div>
            <p class="cancelled-box-outer__dic">Please click on button below if you want to book the appointment again.</p>

            <button class="btn btn--primary"  (click)="bookAppointment()">Book Your Appointment</button>
        </div>
        <div class="cancelled-box-outer__appt-queries">
            If you have any queries or concerns then call us at <a href="tel:{{DMENumber}}">{{DMENumber | phone}}</a>.
        </div>
    </div>
</section>
<app-patient-footer></app-patient-footer>
