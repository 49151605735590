export class FacilitySearchRequestModel {
  radius: number;
  crmSalesOrderId: number;
  crmFacilityId: number;
  crmPatientId: number;
  schAppointmentDate: Date;
  schAppointmentDateStartTime: Date;
  schAppointmentDateEndTime: Date;
  crmPatientZipCode: string;
  crmState: string;
  crmCityTown: string;
  crmTherapistId: number;
  schAppointmentDateStartTimeString: string;
  schAppointmentDateEndTimeString: string;
  isBillingAddress: boolean;
  isAppointment: boolean = false;
  crmFacilityiIdsString: string;
  searchText: string;
  facilityZipCode: string;
  patientTimeZone: string;
  CrmSalesorderClassificationType: number;
}


