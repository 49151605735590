import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
//ConfirmDate
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/admin/servicies/notification.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
//import { HeaderComponent } from 'src/app/shared/header/header.component';
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ResultOfT } from '../../../shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { SettingsService } from '../../servicies/settings.service';
import { WipStatusBTmapping } from '../../../shared/models/common/wip-status';
declare const Twilio: any;
import { ReminderSettingResponseModel } from 'src/app/shared/models/response/settings/settings-reminder-model'
import { AppointmentConfirmationInfo } from 'src/app/shared/models/response/appointment/appointment-confirmation-info';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { InvetoryLocationResponseModel } from '../../../shared/models/response/invetory-location-response-model';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import * as _ from 'lodash';
import { TimezonePipe } from '../../../pipes/timezone.pipe';
import { RoadHomeVisitHistoryRequestModel } from 'src/app/shared/models/request/roadHomeVisitHistory-request';
@Component({
  selector: 'app-confirm-appointment',
  templateUrl: './confirm-appointment.component.html',
  styleUrls: ['./confirm-appointment.component.sass'],
  providers: [DialogService]
})
export class ConfirmAppointmentComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() appointmentConfirmationInfo: AppointmentConfirmationInfo;
  @Input() salesOrder: SalesOrderInfoResponseModel;
  @Input() isBrightreeSystemAppUpdate: boolean;
  @Input() crmPatientPhoneNumber: string;
  @Input() crmPatientEmailAddress: string;
  @Input() crmPatientMobileNumber: string;
  @Input() crmSalesorderDeliveryNote: string;
  @Input() crmSalesorderOrderNote: string;
  @Input() twillioDeviceConnectionObject: TwilioDeviceObject;
  @Input() crmInvetoryLocationName: string;
  @Input() crmPatientTimezone: string;
  @Input() crmFacilityTimeZone: string;

  @Input() isPODStatusBrightreeSystemAppUpdate: boolean;
  @Input() isAppendOrderNoteBrightreeSystemAppUpdate: boolean;


  @Input() roadHomeVisitHistory: RoadHomeVisitHistoryRequestModel[];


  @Output() appintmentBooked: EventEmitter<number> = new EventEmitter();
  callConfigModel: CallConfigModel;
  appDateFormat: DateFormatModel;
  appointment: AppointmentInfoReponseModel;
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  selectedContactNumber: string = "";
  schedulerResponseModel: SchedulerResponseModel[];
  callnote: string;
  calldate: Date;
  calltime: string;
  callId: number;
  smsTextNumber: string;
  customStartTime: string;
  isValid: boolean = false;
  loggedInUserId: number;
  reminderFrequency: string;
  remindersModel: ReminderSettingResponseModel[];
  reminderOpted: boolean = false;
  schReminderOpted: boolean = false;
  schIsReminderOptedSms: boolean = false;
  schIsReminderOptedType: string = 'Both';
  schIsReminderOptedEmail: boolean = false;
  schIsReminderOptedBoth: boolean = false;
  isSalesOrderUpdated: boolean = false;
  loadingCustom: boolean;
  reschduleDate: Date;
  //Confirm Date Screen Code
  appointmentRequest: AppointmentRequestModel;
  appointmentRequestBT: BTSalesOrderUpdateRequestModel;
  reminderDays: string;
  monthsToShow: number;
  daysToShow: number;
  isSession: boolean = true;
  sessionObject: { isSession: boolean, isUpdated: boolean, appointmentDate: Date };
  crmFirstAvailableStartDate: Date;
  isTwoNumberExist: boolean;
  isPhoneNumberExist: boolean;
  // Added Paramater for new Pop up function
  OpenCheckOld: boolean = false;
  ScheduledDateStatus: boolean;
  ScheduledTimeStatus: boolean;
  WIPStateStatus: any;
  WIPDateNeededStatus: boolean;
  PractitionerIDStatus: boolean;
  PractitionerNameStatus: any;
  OrderNoteStatus: boolean;
  DeliveryNoteStatus: boolean;

  OrderNote: string;
  DeliveryNote: string;

  value: Date;
  customStartDate: Date;
  deliveryNoteOverwrite: boolean = true;
  orderNoteOverwrite: boolean = true;
  isDefaultWIPNeededDateAppointmentdate: boolean = false;
  updateWipStateOnRescheduled: boolean = false;
  DefaultWIPAssignedToKey: number;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  reasonsResponseModel: CancelVoidResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  distinctWipStatusBTMappingResponseModel: WipStatusBTmapping[];
  selectedwipStatusBTMappingResponseModel: WipStatusBTmapping;

  brightreeEmailAddress: boolean;
  brightreePhone1: boolean;
  brightreePhone2: boolean;
  displayOrderNote: boolean;
  displayDeliveryNote: boolean;
  patientDetials: boolean;
  patientdetialsEdit: boolean;
  patientdetialsDeliveryNote: boolean;
  patientdetialsEditOrderNone: any;
  currentAppointmentDetailsBox: boolean;
  displayScheduleCallBack: boolean;

  visibleSidebar1;
  displayCancelAppointment: boolean;
  displayVoidAppointment: boolean;
  confirmDetialsPage: boolean;
  thankyouPage: boolean;
  //crmPatientPhoneNumber: string;
  //crmPatientMobileNumber: string;
  clientId: number;
  isUpdateSalesOrderDeliveryNote: boolean = true;
  isProvisional: boolean;
  isGoogleMap: boolean = false;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private webStorageService: WebStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private userService: UserService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    @Inject(LOCALE_ID) private locale: string) {

    super(messageService);


    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    var clientdetail = this.webStorageService.getClientConfig();
    this.clientId = Number(clientdetail.companyId);

    var channelArray: Array<Number> = [5, 6];
    this.isUpdateSalesOrderDeliveryNote = channelArray.includes(this.clientId) ? false : true;
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
    this.isGoogleMap = provisionalSalesOrder.isGoogleMap;

  }
  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === 'appointmentConfirmationInfo') {

        if (changes[property].currentValue)
          this.appointmentConfirmationInfo = changes[property].currentValue;

        if (this.appointmentConfirmationInfo) {

          this.appointmentRequestBT.IsUpdateToBrightree = this.isBrightreeSystemAppUpdate;
          this.appointmentRequestBT.IsUpdatePODStatusToBrightree = this.isPODStatusBrightreeSystemAppUpdate;
          this.appointmentRequestBT.IsAppendOrderNoteToBrightree = this.isAppendOrderNoteBrightreeSystemAppUpdate;
          this.appointmentRequestBT.IsDeliveryNoteOverride = true;
          this.appointmentRequestBT.IsOrderNoteOverride = true;
          this.appointmentRequestBT.OrderNote = this.appointmentConfirmationInfo.OrderNote;
          this.appointmentRequestBT.DeliveryNote = this.appointmentConfirmationInfo.DeliveryNote;


          this.schReminderOpted = this.appointmentConfirmationInfo.schReminderOpted;
          this.schIsReminderOptedSms = this.appointmentConfirmationInfo.schIsReminderOptedSms;
          this.schIsReminderOptedEmail = this.appointmentConfirmationInfo.schIsReminderOptedEmail;

          if (this.schIsReminderOptedSms && this.schIsReminderOptedEmail)
            this.schIsReminderOptedType = "Both";
          else if (!this.schIsReminderOptedSms && !this.schIsReminderOptedEmail)
            this.schIsReminderOptedType = "Both";
          else if (this.schIsReminderOptedSms)
            this.schIsReminderOptedType = "SMS";
          else
            this.schIsReminderOptedType = "Email";


          this.OrderNote = this.appointmentConfirmationInfo.OrderNote;
          this.DeliveryNote = this.appointmentConfirmationInfo.DeliveryNote;

          this.appointmentRequestBT.PractitionerName = this.appointmentConfirmationInfo.crmTherapistname;
          this.appointmentRequestBT.PractitionerId = this.appointmentConfirmationInfo.crmPractionerId ? Number(this.appointmentConfirmationInfo.crmPractionerId) : 0;
          this.appointmentRequestBT.DeliveryTechnicianId = this.appointmentConfirmationInfo.crmDeliveryTechnicianId ? Number(this.appointmentConfirmationInfo.crmDeliveryTechnicianId) : 0;
          this.appointmentRequestBT.ScheduledDeliveryStartDateString = this.appointmentConfirmationInfo.appointmentStartDate.toDateString();
          this.appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.appointmentConfirmationInfo.appointmentStartDate.toTimeString(), 'hh:mm A').format("hh:mm A");
          this.appointmentRequestBT.ScheduledDeliveryStartDate = this.appointmentConfirmationInfo.appointmentStartDate;
          this.isDefaultWIPNeededDateAppointmentdate = this.appointmentConfirmationInfo.isDefaultWIPNeededDateAppointmentdate;
          if (this.isDefaultWIPNeededDateAppointmentdate) {
            this.appointmentRequestBT.WIPDateNeededString = moment(this.appointmentConfirmationInfo.appointmentStartDate).toDate().toDateString();
            this.appointmentRequestBT.WIPDateNeeded = moment(this.appointmentConfirmationInfo.appointmentStartDate).toDate();
          } else {
            this.appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
            this.appointmentRequestBT.WIPDateNeeded = moment().toDate();
          }
          this.appointmentRequestBT.EmailAddress = this.salesOrder.patientInfo.crmPatientEmailAddress;
          this.patientEmail = this.appointmentRequestBT.EmailAddress;
          let AppoinmentType: string = this.appointmentConfirmationInfo.selectedAppointmentTypePopUp;
          this.updateWipStateOnRescheduled = this.appointmentConfirmationInfo.UpdateWipStateOnRescheduled;
          AppoinmentType = AppoinmentType.replace(' ', '');
          if (this.appointmentConfirmationInfo.WIPStateAssignmentType && this.appointmentConfirmationInfo.WIPStateAssignmentType.toLowerCase() == "wipstate") {
            if (AppoinmentType.toLowerCase() == "telehealth") {
              if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
                let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'bookedremote')
                if (selectedWipStaus) {
                  this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
                  //this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
                  this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
                }
                else {
                  let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
                  if (selectedWipStaus)
                    this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
                  this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
                }
              }
            }
            else {
              if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
                let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
                if (selectedWipStaus) {
                  this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
                  //this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
                  this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
                }
                else {
                  let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'bookedremote')
                  if (selectedWipStaus)
                    this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
                  this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
                }
              }
            }

          }
          else {

            if (this.appointmentConfirmationInfo.crmWIPAssignedToKey) {
              this.appointmentRequestBT.WIPAssignedToKey = this.appointmentConfirmationInfo.crmWIPAssignedToKey;
              this.DefaultWIPAssignedToKey = this.appointmentConfirmationInfo.crmWIPAssignedToKey;
            }
            if (this.appointmentConfirmationInfo.crmWIPStatusNameBT) {
              if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
                let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameBt.toLowerCase() == this.appointmentConfirmationInfo.crmWIPStatusNameBT.toLowerCase())
                if (selectedWipStaus) {
                  this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
                }
              }
            }
          }
          if (this.selectedwipStatusBTMappingResponseModel) {
            this.appointmentRequestBT.WIPStateName = this.selectedwipStatusBTMappingResponseModel.crmWipstatusNameBt;
            this.appointmentRequestBT.WIPStateKey = this.selectedwipStatusBTMappingResponseModel.wipStatusRCMId;
            // this.appointmentRequestBT.IsUpdateToBrightree = this.selectedwipStatusBTMappingResponseModel.crmIsUpdateToBrigthree;
            //this.appointmentRequestBT.WIPAssignedToKey = this.selectedwipStatusBTMappingResponseModel.crmWIPAssignedToKey;
          }

          if (this.appointmentConfirmationInfo.schSMSCommunicationType == "Phone") {
            this.crmPatientPhoneNumber = this.appointmentConfirmationInfo.schSMSCommunicationNumber == undefined ? this.appointmentRequestBT.PhoneNumber : this.appointmentConfirmationInfo.schSMSCommunicationNumber;
            this.appointmentRequestBT.PhoneNumber = this.appointmentConfirmationInfo.schSMSCommunicationNumber == undefined ? this.appointmentRequestBT.PhoneNumber : this.appointmentConfirmationInfo.schSMSCommunicationNumber;
            this.appointmentRequestBT.IsMobileNumberUpdate = false;
          }
          else {
            this.crmPatientMobileNumber = this.appointmentConfirmationInfo.schSMSCommunicationNumber == undefined ? this.appointmentRequestBT.PhoneNumber : this.appointmentConfirmationInfo.schSMSCommunicationNumber;
            this.appointmentRequestBT.PhoneNumber = this.appointmentConfirmationInfo.schSMSCommunicationNumber == undefined ? this.appointmentRequestBT.PhoneNumber : this.appointmentConfirmationInfo.schSMSCommunicationNumber;
            this.appointmentRequestBT.IsMobileNumberUpdate = true;
          }
        }
      }
      if (property === 'salesOrder') {

        if (changes[property].currentValue)
          this.salesOrder = changes[property].currentValue;

        if (this.salesOrder) {
          if (!this.crmPatientMobileNumber && !this.crmPatientPhoneNumber)
            this.isPhoneNumberExist = false;
          else
            this.isPhoneNumberExist = true;


          if (this.crmPatientMobileNumber && this.crmPatientPhoneNumber)
            this.isTwoNumberExist = true;
          else
            this.isTwoNumberExist = false;


          if (this.crmPatientMobileNumber) {
            this.smsTextNumber = this.crmPatientMobileNumber;
            //this.crmPatientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientMobileNumber;
          }

          else {
            this.smsTextNumber = this.crmPatientPhoneNumber;
            //this.crmPatientPhoneNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientPhoneNumber;

          }

          //this.crmPatientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
          //this.crmPatientPhoneNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;
          this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;
          this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;

          this.OrderNote = this.salesOrder.crmSalesorderOrderNote;
          this.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;

        }
      }
      if (property === 'crmPatientMobileNumber') {

        if (changes[property].currentValue)
          this.crmPatientMobileNumber = changes[property].currentValue;

        if (this.crmPatientMobileNumber) {

          if (!this.crmPatientMobileNumber && !this.crmPatientPhoneNumber)
            this.isPhoneNumberExist = false;
          else
            this.isPhoneNumberExist = true;


          if (this.crmPatientMobileNumber && this.crmPatientPhoneNumber)
            this.isTwoNumberExist = true;
          else
            this.isTwoNumberExist = false;


          if (this.crmPatientMobileNumber) {
            this.smsTextNumber = this.crmPatientMobileNumber;
            //this.crmPatientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientMobileNumber;
          }

          else if (this.crmPatientPhoneNumber) {
            this.smsTextNumber = this.crmPatientPhoneNumber;
            //this.crmPatientPhoneNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientPhoneNumber;

          }

        }
      }

      if (property === 'crmPatientPhoneNumber') {

        if (changes[property].currentValue)
          this.crmPatientPhoneNumber = changes[property].currentValue;

        if (this.crmPatientPhoneNumber) {

          if (!this.crmPatientMobileNumber && !this.crmPatientPhoneNumber)
            this.isPhoneNumberExist = false;
          else
            this.isPhoneNumberExist = true;


          if (this.crmPatientMobileNumber && this.crmPatientPhoneNumber)
            this.isTwoNumberExist = true;
          else
            this.isTwoNumberExist = false;


          if (this.crmPatientMobileNumber) {
            this.smsTextNumber = this.crmPatientMobileNumber;
            //this.crmPatientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientMobileNumber;
          }

          else if (this.crmPatientPhoneNumber) {
            this.smsTextNumber = this.crmPatientPhoneNumber;
            //this.crmPatientPhoneNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;
            this.appointmentRequestBT.PhoneNumber = this.crmPatientPhoneNumber;

          }

        }
      }

      if (property === 'crmPatientEmailAddress') {

        if (changes[property].currentValue)
          this.crmPatientEmailAddress = changes[property].currentValue;
        if (this.crmPatientEmailAddress) {
          this.appointmentRequestBT.EmailAddress = this.crmPatientEmailAddress;
          this.patientEmail = this.appointmentRequestBT.EmailAddress;
        }
      }
      if (property === 'crmSalesorderDeliveryNote') {

        if (changes[property].currentValue)
          this.crmSalesorderDeliveryNote = changes[property].currentValue;
        if (this.crmSalesorderDeliveryNote) {
          this.appointmentRequestBT.DeliveryNote = this.crmSalesorderDeliveryNote;
        }
      }

      if (property === 'crmSalesorderOrderNote') {

        if (changes[property].currentValue)
          this.crmSalesorderOrderNote = changes[property].currentValue;
        if (this.crmSalesorderOrderNote) {
          this.appointmentRequestBT.OrderNote = this.crmSalesorderOrderNote;
        }
      }
      if (property === 'isBrightreeSystemAppUpdate') {

        if (changes[property].currentValue)
          this.isBrightreeSystemAppUpdate = changes[property].currentValue;
        if (this.isBrightreeSystemAppUpdate) {
          this.appointmentRequestBT.IsUpdateToBrightree = this.isBrightreeSystemAppUpdate;
        }
      }
      if (property === 'isPODStatusBrightreeSystemAppUpdate') {

        if (changes[property].currentValue)
          this.isPODStatusBrightreeSystemAppUpdate = changes[property].currentValue;
        if (this.isPODStatusBrightreeSystemAppUpdate) {
          this.appointmentRequestBT.IsUpdatePODStatusToBrightree = this.isPODStatusBrightreeSystemAppUpdate;
        }
      }
      if (property === 'isAppendOrderNoteBrightreeSystemAppUpdate') {

        if (changes[property].currentValue)
          this.isAppendOrderNoteBrightreeSystemAppUpdate = changes[property].currentValue;
        if (this.isAppendOrderNoteBrightreeSystemAppUpdate) {
          this.appointmentRequestBT.IsAppendOrderNoteToBrightree = this.isAppendOrderNoteBrightreeSystemAppUpdate;
        }
      }
    }
  }

  appintmentBookedHandler() {
    this.thankyouPage = false;
    this.appintmentBooked.emit();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();

  }
  async ngOnInit() {
    this.sessionObject = {
      isSession: this.webStorageService.getIsSession(),
      isUpdated: false,
      appointmentDate: null
    };
    //await this.getCallConfig();
    await this.getWipStatusBTmapping();
    //this.isAppointmentAppoinmentPage = this.config.data.isAppointmentAppoinmentPage;
    //this.isAppointmentAppoinmentDetailPage = this.config.data.isAppointmentAppoinmentDetailPage;
    //this.isAppointmentSalesOrderDetailPage = this.config.data.isAppointmentSalesOrderDetailPage;
    //this.isAppointmentAppoinmentLockPage = this.config.data.isAppointmentAppoinmentLockPage;

  }

  private async getCallConfig() {
    this.progress.next(true);
    await this.settingsService.getCallConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<CallConfigModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.callConfigModel = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
              this.distinctWipStatusBTMappingResponseModel = _.uniqBy(this.wipStatusBTMappingResponseModel, 'crmWipstatusNameBt');
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0 && rminutes > 0)
      return rhours + " Hour " + rminutes + " Minutes";
    else if (rhours > 0)
      return rhours + " Hour";
    else
      return rminutes + " Minutes";
  }


  // Confirm Appointment popup
  ScheduledDate() {
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }

  SaveScheduledDate() {
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }
  CancelScheduledDate() {

    this.appointmentRequestBT.ScheduledDeliveryStartDate = this.appointmentConfirmationInfo.appointmentStartDate;
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }

  ScheduledTime() {
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  SaveScheduledTime() {
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  CancelScheduledTime() {

    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.appointmentConfirmationInfo.appointmentStartDate.toTimeString(), 'hh:mm A').format("hh:mm A");
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  WIPStateClick() {
    this.WIPStateStatus = !this.WIPStateStatus;
  }

  SaveWIPStateClick() {
    this.WIPStateStatus = !this.WIPStateStatus;
  }

  CancelWIPStateClick() {
    let AppoinmentType: string = this.appointmentConfirmationInfo.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');
    if (this.appointmentConfirmationInfo.WIPStateAssignmentType && this.appointmentConfirmationInfo.WIPStateAssignmentType.toLowerCase() == "wipstate") {
      if (AppoinmentType.toLowerCase() == "telehealth") {
        if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
          let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'bookedremote')
          if (selectedWipStaus) {
            this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
            //this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
            this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
          }
          else {
            let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
            if (selectedWipStaus)
              this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
            this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
          }
        }
      }
      else {
        if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
          let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
          if (selectedWipStaus) {
            this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
            //this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
            this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
          }
          else {
            let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'bookedremote')
            if (selectedWipStaus)
              this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
            this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
          }
        }
      }

    }
    else {
      if (this.appointmentConfirmationInfo.crmWIPAssignedToKey) {
        this.appointmentRequestBT.WIPAssignedToKey = this.appointmentConfirmationInfo.crmWIPAssignedToKey;
      }
      if (this.appointmentConfirmationInfo.crmWIPStatusNameBT) {
        if (this.distinctWipStatusBTMappingResponseModel != null && this.distinctWipStatusBTMappingResponseModel != undefined) {
          let selectedWipStaus = this.distinctWipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameBt.toLowerCase() == this.appointmentConfirmationInfo.crmWIPStatusNameBT.toLowerCase())
          if (selectedWipStaus) {
            this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
          }
        }
      }
    }
    if (this.selectedwipStatusBTMappingResponseModel) {
      this.appointmentRequestBT.WIPStateName = this.selectedwipStatusBTMappingResponseModel.crmWipstatusNameBt;
      this.appointmentRequestBT.WIPStateKey = this.selectedwipStatusBTMappingResponseModel.wipStatusRCMId;
    }

    this.WIPStateStatus = !this.WIPStateStatus;

  }

  WIPDateNeededClick() {
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }

  SaveWIPDateNeededClick() {
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }
  CancelWIPDateNeededClick() {
    this.appointmentRequestBT.WIPDateNeeded = moment().toDate();
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }

  PractitionerIDClick() {
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }

  SavePractitionerIDClick() {
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }
  CancelPractitionerIDClick() {
    this.appointmentRequestBT.PractitionerId = this.appointmentConfirmationInfo.crmPractionerId;
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }

  PractitionerNameClick() {
    this.PractitionerNameStatus = !this.PractitionerNameStatus;
  }

  OverwriteBTPatientMobileNoClick(btMobileNo: any, isSubmit: boolean) {

    if (isSubmit) {
      const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);

      if (btMobileNo.length < 10 || !TEXT_REGEXP.test(btMobileNo)) {
        this.showWarningMessage('Please add valid phone number.');
        return;
      }

      this.appointmentRequestBT.PhoneNumber = this.crmPatientMobileNumber = btMobileNo;
    }
    else {
      this.crmPatientMobileNumber = this.appointmentRequestBT.PhoneNumber;
    }

    this.brightreePhone2 = !this.brightreePhone2;
  }
  CancelPatientMobileNoClick() {
    this.appointmentRequestBT.PractitionerName = this.appointmentConfirmationInfo.crmTherapistname;
    this.brightreePhone2 = !this.brightreePhone2;
  }

  OrderNoteClick() {
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  SaveOrderNoteClick() {
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  CancelOrderNoteClick() {
    this.appointmentRequestBT.OrderNote = "";
    this.appointmentRequestBT.IsOrderNoteOverride = true;
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  DeliveryNoteClick() {
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  SaveDeliveryNoteClick() {
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  CancelDeliveryNoteClick() {
    this.appointmentRequestBT.DeliveryNote = "";
    this.appointmentRequestBT.IsDeliveryNoteOverride = true;
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  brightreeEmailClick(isSave: boolean) {
    if (isSave) {
      const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

      if (this.brightreeEmailAddress && !TEXT_REGEXP.test(this.patientEmail)) {
        this.showWarningMessage('Please add proper email address.');
        return;
      }
      this.appointmentRequestBT.EmailAddress = this.patientEmail;
    }
    else {
      this.patientEmail = this.appointmentRequestBT.EmailAddress;
    }

    this.brightreeEmailAddress = !this.brightreeEmailAddress;
  }

  OverwriteBTPatientPhoneNoClick(btPhoneNo: any, isSubmit: boolean) {
    if (isSubmit) {
      const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);

      if (btPhoneNo.length < 10 || !TEXT_REGEXP.test(btPhoneNo)) {
        this.showWarningMessage('Please add valid phone number.');
        return;
      }
      this.crmPatientPhoneNumber = btPhoneNo;
    }
    else {
      this.crmPatientPhoneNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;
    }

    this.brightreePhone1 = !this.brightreePhone1;
  }

  onClickBTMobileNoChange() {
    this.brightreePhone2 = !this.brightreePhone2;
  }

  showDialogOrderNote() {
    this.displayOrderNote = true;
  }
  SaveDialogOrderNote() {
    this.displayOrderNote = false;
  }
  CancelDialogOrderNote() {
    this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;
    this.displayOrderNote = false;
  }


  showDialogDeliveryNote() {
    this.displayDeliveryNote = true;
  }
  SaveDialogDeliveryNote() {
    this.displayDeliveryNote = false;
  }
  CancelDialogDeliveryNote() {
    this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
    this.displayDeliveryNote = false;
  }



  onBookAppointment() {
    //console.log('Confirm  Appointment');
    if (!this.appointmentConfirmationInfo.appointmentStartDate || !this.appointmentConfirmationInfo.appointmentEndDate) {
      this.showWarningMessage("Please select appointment date & time.");
      return;
    }
    let AppoinmentType: string = this.appointmentConfirmationInfo.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');
    if (AppoinmentType == "HomeVisit" && !this.appointmentConfirmationInfo.therapistId) {
      this.showWarningMessage("Please select therapist.");
      return;
    }
    if (AppoinmentType == "Telehealth" && !this.appointmentConfirmationInfo.selectedAppointmentTypePopUp) {
      this.showWarningMessage("Please select remote setup type.");
      return;
    }



    let facilityId: number = null;
    if (AppoinmentType != "HomeVisit")
      facilityId = this.appointmentConfirmationInfo.facilityId;

    this.appointmentRequest = new AppointmentRequestModel();
    this.appointmentRequest.CrmFacilityId = facilityId;
    this.appointmentRequest.SchAppointmentTypeName = AppoinmentType;
    this.appointmentRequest.CrmPatientEmailAddress = this.salesOrder.patientInfo.crmPatientEmailAddress;
    this.appointmentRequest.CrmPatientId = parseInt(this.salesOrder.crmPatientId, 10);
    this.appointmentRequest.CrmPatientPhoneNo = this.appointmentRequestBT.PhoneNumber ? this.appointmentRequestBT.PhoneNumber : this.salesOrder.patientInfo.crmPatientMobileNumber ? this.salesOrder.patientInfo.crmPatientMobileNumber : this.salesOrder.patientInfo.crmPatientPhoneNo;
    this.appointmentRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.appointmentRequest.IsAppointmentBookedByPatient = false;
    this.appointmentRequest.SchAppointmentBookedBy = this.loggedInUserId;
    this.appointmentRequest.CrmTherapistId = this.appointmentConfirmationInfo.therapistId;
    this.appointmentRequest.SchIsBillingAddress = this.appointmentConfirmationInfo.isBillingAddress;
    //this.appointmentRequest.SchAppointmentDateStartTimeString = moment(this.selectedStartDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    //this.appointmentRequest.SchAppointmentDateEndTimeString = moment(this.selectedEndDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");

    this.appointmentRequest.SchAppointmentDateStartTimeString = this.appointmentConfirmationInfo.appointmentStartDate.toDateString() + ' ' + this.appointmentConfirmationInfo.appointmentStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateEndTimeString = this.appointmentConfirmationInfo.appointmentEndDate.toDateString() + ' ' + this.appointmentConfirmationInfo.appointmentEndDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateStartTime = this.appointmentConfirmationInfo.appointmentStartDate;
    this.appointmentRequest.SchAppointmentDateEndTime = this.appointmentConfirmationInfo.appointmentEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTime = this.appointmentConfirmationInfo.appointmentOrigStartDate;
    this.appointmentRequest.SchAppointmentOrigDateEndTime = this.appointmentConfirmationInfo.appointmentOrigEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTimeString = this.appointmentConfirmationInfo.appointmentOrigStartDate.toDateString() + ' ' + this.appointmentConfirmationInfo.appointmentOrigStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentOrigDateEndTimeString = this.appointmentConfirmationInfo.appointmentOrigEndDate.toDateString() + ' ' + this.appointmentConfirmationInfo.appointmentOrigEndDate.toTimeString().split(' ')[0];


    this.appointmentRequest.SchAppointmentId = Number(this.appointmentConfirmationInfo.schAppointmentId);
    this.appointmentRequest.PatientTimeZone = this.crmPatientTimezone;
    this.appointmentRequest.CrmFacilityTimeZone = this.crmFacilityTimeZone;

    this.appointmentRequest.CrmFacilityName = this.appointmentConfirmationInfo.facilityName;
    this.appointmentRequest.AppointmentDuration = this.appointmentConfirmationInfo.duration;
    this.appointmentRequest.CrmTherapistName = this.appointmentConfirmationInfo.crmTherapistname;
    this.appointmentRequest.AmountDue = this.salesOrder.amountDue;
    //this.appointmentConfirmationInfo.appointmentTimeZoneShortName = new TimezonePipe(this.webStorageService).transform(this.crmFacilityTimeZone);
    this.appointmentConfirmationInfo.appointmentTimeZoneShortName = new TimezonePipe(this.webStorageService).transform(this.crmPatientTimezone);
    if (AppoinmentType == "Telehealth") {
      this.appointmentRequest.SchRemoteAppointmentType = this.appointmentConfirmationInfo.schRemoteAppointmentType;
    }

    this.appointmentRequestBT.PractitionerId = Number(this.appointmentRequestBT.PractitionerId);
    this.appointmentRequestBT.DeliveryTechnicianId = Number(this.appointmentRequestBT.DeliveryTechnicianId);
    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.appointmentRequestBT.ScheduledDeliveryStartTimeString, 'hh:mm A').format("HH:mm");
    if (this.appointmentRequestBT.WIPDateNeeded)
      this.appointmentRequestBT.WIPDateNeededString = this.appointmentRequestBT.WIPDateNeeded.toDateString();
    if (this.selectedwipStatusBTMappingResponseModel) {
      this.appointmentRequestBT.WIPStateName = this.selectedwipStatusBTMappingResponseModel.crmWipstatusNameBt;
      this.appointmentRequestBT.WIPStateKey = this.selectedwipStatusBTMappingResponseModel.wipStatusRCMId;

      if (this.appointmentConfirmationInfo.WIPStateAssignmentType && this.appointmentConfirmationInfo.WIPStateAssignmentType.toLowerCase() == "wipstate") {
        this.appointmentRequestBT.WIPAssignedToKey = this.selectedwipStatusBTMappingResponseModel.crmWIPAssignedToKey;
      }
      else {
        this.appointmentRequestBT.WIPAssignedToKey = this.DefaultWIPAssignedToKey ? this.DefaultWIPAssignedToKey : 0;
      }
    }

    if (this.appointmentConfirmationInfo.isRescheduledRequest && this.updateWipStateOnRescheduled == false) {
      this.appointmentRequestBT.WIPStateName = '';
      this.appointmentRequestBT.WIPStateKey = 0;
      this.appointmentRequestBT.WIPAssignedToKey = 0;
      this.appointmentRequestBT.WIPDateNeededString = '';
      // this.appointmentRequestBT.WIPDateNeeded = null;
    }
    this.appointmentRequestBT.OrderNote = this.appointmentRequestBT.OrderNote;
    this.appointmentRequestBT.DeliveryNote = this.appointmentRequestBT.DeliveryNote;

    if (!this.isUpdateSalesOrderDeliveryNote) {
      this.appointmentRequestBT.OrderNote = '';
      this.appointmentRequestBT.DeliveryNote = '';
    }
    this.appointmentRequestBT.EmailAddress = this.appointmentRequestBT.EmailAddress;
    this.patientEmail = this.appointmentRequestBT.EmailAddress;
    this.appointmentRequestBT.crmPatientMobileNumber = this.crmPatientMobileNumber;
    this.appointmentRequestBT.crmPatientPhoneNumber = this.crmPatientPhoneNumber;
    this.appointmentRequestBT.InvetoryLocationId = this.salesOrder ? Number(this.salesOrder.crmInvetoryLocationId) : 0;
    this.appointmentRequest.salesOrderUpdateRequestModelBT = this.appointmentRequestBT;

    this.appointmentRequest.SchReminderOpted = this.reminderOpted;

    this.appointmentRequest.SchReminderOpted = this.schReminderOpted;
    if (this.schIsReminderOptedType == "Both") {
      this.appointmentRequest.SchIsReminderOptedSms = true;
      this.appointmentRequest.SchIsReminderOptedEmail = true;
    }

    else if (this.schIsReminderOptedType == "SMS") {
      this.appointmentRequest.SchIsReminderOptedSms = true;
      this.appointmentRequest.SchIsReminderOptedEmail = false;
    }

    else {
      this.appointmentRequest.SchIsReminderOptedSms = false;
      this.appointmentRequest.SchIsReminderOptedEmail = true;
    }

    this.appointmentRequest.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;

    if (!this.appointmentRequestBT.IsUpdateToBrightree && !this.isBrightreeSystemAppUpdate) {
      this.appointmentRequest.salesOrderUpdateRequestModelBT.IsUpdateToBrightree = true;
    }
    else if (this.appointmentRequestBT.IsUpdateToBrightree && !this.isBrightreeSystemAppUpdate) {
      this.appointmentRequest.salesOrderUpdateRequestModelBT.IsUpdateToBrightree = false;
    }
    else if (this.appointmentRequestBT.IsUpdateToBrightree && this.isBrightreeSystemAppUpdate) {
      this.appointmentRequest.salesOrderUpdateRequestModelBT.IsUpdateToBrightree = true;
    }


    this.appointmentRequest.salesOrderUpdateRequestModelBT.IsUpdatePODStatusToBrightree = this.isPODStatusBrightreeSystemAppUpdate;
    this.appointmentRequest.salesOrderUpdateRequestModelBT.IsAppendOrderNoteToBrightree = this.isAppendOrderNoteBrightreeSystemAppUpdate;

    this.appointmentRequest.btNoteId = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;

    this.appointmentRequest.isProvisional = this.isProvisional;
    this.appointmentRequest.SchSmscommunicationType = (this.appointmentRequestBT.IsMobileNumberUpdate || this.appointmentRequestBT.IsMobileNumberUpdate == undefined) ? "Mobile" : "Phone";
    this.appointmentRequest.SchSmscommunicationNumber = (this.appointmentRequestBT.IsMobileNumberUpdate || this.appointmentRequestBT.IsMobileNumberUpdate == undefined) ? this.crmPatientMobileNumber : this.crmPatientPhoneNumber;
    this.appointmentRequest.SchSmscommunicationNumber = this.appointmentRequest.SchSmscommunicationNumber.replace(/[^\d]/g, '');
    this.appointmentRequest.SchCommunicationEmailAddress = this.patientEmail;

    let appointmentRequest = this.appointmentConfirmationInfo.isRescheduledRequest ? this.appointmentService.ReScheduleAppointment(this.appointmentRequest) : this.appointmentService.ScheduleAppointment(this.appointmentRequest);
    this.loadingCustom = true;
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.isSalesOrderUpdated = true;
          this.thankyouPage = true;
          let oldAppointmentId = this.appointmentConfirmationInfo.isRescheduledRequest ? this.appointmentConfirmationInfo.schAppointmentId : 0;
          let IsRescheduled = this.appointmentConfirmationInfo.isRescheduledRequest;
          this.roadHomeVisitHistory?.forEach(element => {
            element.IsRescheduled = IsRescheduled;
          });
          let newAppointmentId = this.appointmentConfirmationInfo.isRescheduledRequest ? this.appointmentConfirmationInfo.schAppointmentId : result.message
          if (this.isGoogleMap && this.roadHomeVisitHistory[0]?.IsRoadHomeAppointment) {
            this.addRoadHomeVisitHistory(oldAppointmentId, newAppointmentId);
          }
        }
        else {
          this.appointmentRequestBT.OrderNote = this.OrderNote;
          this.appointmentRequestBT.DeliveryNote = this.DeliveryNote;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in scheduling your appointment. Please contact administrator");
        }
      });

  }

  clickpatientDetials() {
    this.patientDetials = !this.patientDetials;
  }

  patientdetialsEditFeilds() {
    this.patientdetialsEdit = !this.patientdetialsEdit;
  }

  patientdetialsEditFeildsOrderNone() {
    this.patientdetialsEditOrderNone = !this.patientdetialsEditOrderNone;
  }

  patientdetialsEditFeildsDeliveryNote() {
    this.patientdetialsDeliveryNote = !this.patientdetialsDeliveryNote;
  }

  currentAppointmentDetails() {
    this.currentAppointmentDetailsBox = !this.currentAppointmentDetailsBox;
  }

  scheduleCallBack() {
    this.displayScheduleCallBack = true;
  }

  cancelAppointmentpopup() {
    this.displayCancelAppointment = true;
  }

  VoidAppointmentpopup() {
    this.displayVoidAppointment = true;
  }

  UpdateBrightTreePhoneNo(isMobileUpdte: boolean, phoneNo: any) {
    this.appointmentRequestBT.IsMobileNumberUpdate = isMobileUpdte;
    this.appointmentRequestBT.PhoneNumber = phoneNo;
  }

  onBTSelect(status: boolean) {
    // if (status) {
    //   this.appointmentRequestBT.IsUpdateToBrightree = false;
    // }
    // else {
    //   this.appointmentRequestBT.IsUpdateToBrightree = true;
    // }

  }

  addRoadHomeVisitHistory(oldAppointmentId, newappointmentId) {
    if (this.roadHomeVisitHistory) {

      let updateItem = this.roadHomeVisitHistory.find(x => x.CrmAppointmentId === Number(oldAppointmentId));
      let index = this.roadHomeVisitHistory.indexOf(updateItem);
      if (index != undefined && index != -1) {
        this.roadHomeVisitHistory[index].OldAppointmentID = Number(oldAppointmentId);
        this.roadHomeVisitHistory[index].CrmAppointmentId = Number(newappointmentId);
        this.roadHomeVisitHistory[index].CrmTherapistId = this.appointmentConfirmationInfo.therapistId;
      }
      else {
        this.roadHomeVisitHistory[0].OldAppointmentID = Number(oldAppointmentId);
        this.roadHomeVisitHistory[0].CrmAppointmentId = Number(newappointmentId);
        this.roadHomeVisitHistory[0].CrmTherapistId = this.appointmentConfirmationInfo.therapistId;
      }
      // this.roadHomeVisitHistory.OldAppointmentID = Number(oldAppointmentId);
      // this.roadHomeVisitHistory.CrmAppointmentId = Number(newappointmentId);
      // this.roadHomeVisitHistory.CrmTherapistId = this.appointmentConfirmationInfo.therapistId
      this.appointmentService.AddRoadHomeVisitHistory(this.roadHomeVisitHistory)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

            }
          }
        });
    }

  }

}
