import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../servicies/appointment.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { Table, TableHeaderCheckbox } from "primeng/table";
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { TwillioCallService } from '../servicies/twillioCall.service';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserService } from 'src/app/admin/servicies/user.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SettingsService } from '../servicies/settings.service';

@Component({
    selector: 'app-todays-dashboard-listing',
    templateUrl: './todays-dashboard-listing.component.html',
    styleUrls: ['./todays-dashboard-listing.component.sass'],
    providers: [DialogService]
})

export class TodaysDashboardListingComponent extends BaseComponent implements OnInit {

    totalRecords: number;
    selectedsalesOrdersCount: number;
    totalPages: number;
    NsheduledTableDefaltColumns: number = 6;
    appDateFormat: DateFormatModel;
    searchRequestModel: SearchRequestModel;
    searchAppointmentRequestModel: SearchRequestModel;
    salesOrders: SalesOrderSummaryResponseModel[];
    selectedsalesOrders: SalesOrderSummaryResponseModel[];
    title = 'Appointments';
    isAppointmentDisabled: boolean = false;
    private hasPageHistory: boolean;
    loggedInUserId: number;
    searchText: string;
    currentUserBranch: number;
    currentSelectedNSUserBranch: number;
    currentSelectedSUserBranch: number;
    private unsubscriber = new Subject<boolean>();
    private progress = new Subject<boolean>();
    salesOrder: SalesOrderInfoResponseModel;
    istodaysSummaryDashboard: boolean = false;
    @ViewChild('searchBox') searchBox: ElementRef;

    constructor(private router: Router,
        private webStorageService: WebStorageService,
        private dialougeService: DialogService,
        private appointmentService: AppointmentService,
        private globalSearchService: GlobalSearchService,
        private twillioCallService: TwillioCallService,
        messageService: MessageService, private _cdr: ChangeDetectorRef,
        private userService: UserService, private settingsService: SettingsService, private formBuilder: FormBuilder) {
        super(messageService);
        this.totalRecords = 0;
        this.totalPages = 1;
        this.loading = false;
        this.appDateFormat = this.webStorageService.getDateFormat();
        const loggedInUser = this.webStorageService.getCurrentUserToken();
        this.loggedInUserId = loggedInUser.userId;
        this.currentUserBranch = this.webStorageService.getUserBranch();
        const currentNavigationState = this.router.getCurrentNavigation().extras.state;
        if (currentNavigationState && currentNavigationState.istodaysSummaryDashboard) {
            this.searchAppointmentRequestModel = currentNavigationState.searchRequestModel;
            this.istodaysSummaryDashboard = currentNavigationState.istodaysSummaryDashboard;
        }
    }

    async ngOnInit() {
        this.initializeSearchRequestModel();
        this.salesOrder = new SalesOrderInfoResponseModel();
        this.salesOrder.patientInfo = new PatientInfoResponseModel();
        this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
        this.getsalesorders();
    }

    onView(salesOrderId: number, apptId: number) {
        if (apptId == 0)
            this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: 0, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: 0, redirectFromSearch: false } });
        else
            this.router.navigate(['/admin/appointments/detail/', apptId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: 1, selectedfilterIndex: 1, redirectFromtoDaysList: true } });
    }

    getTotalRows() {
        // Set page size to 'totalRecords' when page size is 'All'

        if (this.searchRequestModel.PageSize)
            return this.searchRequestModel.PageSize;

        return this.totalRecords;
    }

    private initializeSearchRequestModel() {

        if (this.hasPageHistory)
            return;

        this.searchText = this.webStorageService.getGlobalSearch();
        this.searchRequestModel = new SearchRequestModel();
        this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
        if (this.searchText) {
            this.searchRequestModel.dictionaryFilter = {}
            this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
        }
        //this.webStorageService.deleteGlobalSearch();

        this.searchRequestModel.appUserInfoId = this.loggedInUserId;
        this.searchRequestModel.FirstRecordIndex = 0;
        this.searchRequestModel.CurrentPageIndex = 0;
        this.searchRequestModel.PageSize = +this.pageSizes[0];
        this.searchRequestModel.SortOrder = 'ASC';
        this.searchRequestModel.SortByColumnName = "crmPatientName";
        if (this.istodaysSummaryDashboard) {
            this.searchRequestModel.dictionaryFilter = {};
            this.searchRequestModel.isTodaysBooking = this.searchAppointmentRequestModel.isTodaysBooking;
            this.searchRequestModel.AppointmentStatus = this.searchAppointmentRequestModel.AppointmentStatus;
            this.searchRequestModel.BookingSearchFromDateString = this.searchAppointmentRequestModel.BookingSearchFromDateString;
            this.searchRequestModel.BookingSearchToDateString = this.searchAppointmentRequestModel.BookingSearchToDateString;
            this.searchRequestModel.scheduledBy = this.searchAppointmentRequestModel.scheduledBy;
            this.title = this.searchAppointmentRequestModel.DisplayTitle;
            if (this.searchAppointmentRequestModel.isRescheduled) {
                this.searchRequestModel.isRescheduled = true;
            }
        }
    }

    onLazyLoad(event: LazyLoadEvent) {

        if (!this.hasPageHistory) {

            this.searchRequestModel.FirstRecordIndex = event.first;
            this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
            this.searchRequestModel.PageSize = 20;
            this.searchRequestModel.SortByColumnName = event.sortField;
            this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
            this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
        }

        this.getsalesorders();
    }

    onSelectionChange(selection: any[]) {

        for (let i = selection.length - 1; i >= 0; i--) {
            let data = selection[i];
        }
        this.selectedsalesOrders = selection;
    }

    private getsalesorders() {
        this.loading = true;
        this.appointmentService.GetTodaysDashboardListing(this.searchRequestModel)
            .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
            .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
                this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
                    if (result && result.isSuccess) {
                        if (result.responseModel != undefined && result.responseModel != null) {
                            const pagedResponse = result.responseModel;

                            if (pagedResponse) {
                                this.salesOrders = pagedResponse.results;
                                this.totalRecords = pagedResponse.totalRecords;
                                this.totalPages = pagedResponse.totalPages;
                                if (this.salesOrders != null)
                                    this.salesOrders.forEach(x => {
                                        if (x.crmSalesorderStatus == "Complete" || x.crmSalesorderStatus == "Void") {
                                            x.isStatusCompleted = true;
                                        }
                                    })
                                if (!this.searchRequestModel.PageSize)
                                    this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                                this.loading = false;
                            }
                        }
                    }
                });

            }, (httpResponseError) => {

                this.showErrorMessage(httpResponseError.message);
                this.loading = false;
            });
    }

    onBack() {
        //this.router.navigate(['admin/today-dashboard']);
        
         this.router.navigate(['admin/today-dashboard'], { state: {  searchRequestModel: this.searchRequestModel } });
    }

    onSearchButtonClick(IsClear: boolean) {
        if (!this.searchRequestModel.isFromDashboard) {
            this.searchRequestModel.dictionaryFilter = {}
        }
        if (this.searchBox.nativeElement.value)
            this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
        else
            this.searchRequestModel.dictionaryFilter = {}

        this.resetPageIndex();
        this.getsalesorders();

    }
    private resetPageIndex() {
        this.searchRequestModel.CurrentPageIndex = 0;
        this.searchRequestModel.FirstRecordIndex = 0;
    }

    ExportAppointments() {
        if (this.loading)
            this.loading = false;

        this.loading = true;
        this._cdr.detectChanges();
        this.appointmentService.ExportTodaysDashboardListing(this.searchRequestModel)
            .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
            .subscribe((result: Blob) => {
                const downloadedFile = new Blob([result], { type: result.type });

                const a = document.createElement('a');
                a.setAttribute('style', 'display:none;');
                a.download = 'Appointments.csv';
                //a.download = 'ExportData_' + '.csv';
                document.body.appendChild(a);
                a.target = '_blank';
                a.href = URL.createObjectURL(downloadedFile);
                document.body.removeChild(a);
                a.click();
            }, (httpResponseMessage) => {
                this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
            });
    }

}