import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { HolidayService } from 'src/app/admin/servicies/holiday.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FacilityResponseModel, FacilityResponseModelDropdownList } from '../../../../../shared/models/response/facility/facility-response-model';
import { FacilityService } from '../../../../servicies/facility.service';

@Component({
  selector: 'app-holiday-manage',
  templateUrl: './holiday-manage.component.html',
  styleUrls: ['./holiday-manage.component.sass']
})
export class HolidayManageComponent extends BaseComponent implements OnInit, OnDestroy {
  title: string = 'Add Holiday';
  @ViewChild('displayName') displayName: ElementRef;
  private appHolidayId: number;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  statusBtnTitle: string = "Submit";
  enableToAddHoliday: boolean;
  loggedInUserId: number;
  holidayList: HolidayResponse[];
  selectedHolidayModel: HolidayResponse;
  selectedHolidayYear: QuickFilters;
  year: QuickFilters[];
  holidayYearReq: boolean = false;
  displayNameReq: boolean = false;
  formStatusSubmitted: boolean = false;
  startDateReq: boolean = false;
  endDateReq: boolean = false;
  startTimeReq: boolean = false;
  endTimeReq: boolean = false;
  customStartDate: Date;
  customStartTime: string;
  customEndDate: Date;
  customEndTime: string;
  holidayDetail: HolidayResponse;
  radioSelectionCheck: boolean = false;
  facilities: FacilityResponseModel[];
  selectedFacility: FacilityResponseModel[];
  selectFacilityString: string = "0";
  startYearDate: Date;
  endYearDate: Date;

  validationMessages = {
    displayName: {
      required: 'Display Name is Required.',
    },
    year: {
      required: 'Year is Required.',
    },
    startDate: {
      required: 'Start Date is Required.',

    },
    endDate: {
      required: 'End Date is Required',
      validation: 'End Date should be larger than Start Date.'
    },
    startTime: {
      required: 'Start Time is Required.',
    },
    endTime: {
      required: 'End Time is Required.',
      validation: 'End Time should be larger than Start Time.'
    },
    eventSelection: {
      required: 'Selection is Required.',
    }

  };
  enableTime: boolean = false;
  startEndDateReq: boolean = false;
  startEndTimeReq: boolean = false;
  loadingCustom: boolean = false;
  selectedEvent: string;
  isEditMode: boolean = false;
  constructor(
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private holidayService: HolidayService
  ) {
    super(messageService);

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.year = [
      { name: new Date().getFullYear().toString(), code: new Date().getFullYear().toString() },
      { name: (new Date().getFullYear() + 1).toString(), code: (new Date().getFullYear() + 1).toString() },

    ];
  }
  SetEvents1(value: number) {
    if (value == 1) {
      this.enableTime = false;
    }
    else {
      this.enableTime = true;
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngOnInit() {
    //this.startYearDate = moment().startOf('year').toDate();
    //this.endYearDate = moment().endOf('year').toDate();
    this.holidayDetail = new HolidayResponse();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.appHolidayId = params['id'];
        this.getFacilities();

      });

    if (this.appHolidayId > 0) {
      this.isEditMode = true;
    }
    this.title = this.isEditMode ? 'Edit Holiday' : 'Add Holiday';
  }

  onYearSelection(event) {
    if (event.value) {
      this.startYearDate = moment().startOf('year').toDate();
      this.startYearDate.setFullYear(parseInt(event.value.code));

      this.endYearDate = moment().endOf('year').toDate();
      this.endYearDate.setFullYear(parseInt(event.value.code));

      //this.customStartDate = this.startYearDate;
    }
  }

  private async getFacilities() {

    this.progress.next(true);
    await this.facilityService.getAllFacilityDropDown()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.facilities = result.responseModel;
        this.getUserDetail();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onFacilityChange(event) {

    this.loadingCustom = true;
    this.selectFacilityString = "";
    if (event.value) {
      if (this.selectedFacility.length > 0) {
        this.selectedFacility.forEach((value) => {
          this.selectFacilityString += value.crmFacilityId + ",";
        });
      }
      else {
        this.selectFacilityString = "0";
      }
      this.loadingCustom = false;
    }
    //console.log(this.selectFacilityString);
  }

  onSubmit() {
    this.loadingCustom = true;
    this.formStatusSubmitted = true;
    let result = this.validate();
    if (!result.includes(false)) {
      const holidayResponseModel = this.setValuesOnModel();

      const holiday = this.isEditMode ? this.holidayService.UpdateHoliday(holidayResponseModel) : this.holidayService.AddHoliday(holidayResponseModel);
      this.loading = true;
      holiday.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          this.loading = false;
          if (result && result.isSuccess && !result.message) {
            this.showSuccessMessage(this.isEditMode ? 'Holiday is updated successfully' : 'Holiday is created successfully');
            this.onCancel();
          }
          else {
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  validate() {
    var isFieldReq = new Array<boolean>();
    this.displayNameReq = this.holidayYearReq = this.startDateReq = this.endDateReq = this.startTimeReq = this.endTimeReq = this.startEndDateReq = this.startEndTimeReq = this.radioSelectionCheck = false;
    if (this.displayName.nativeElement.value == '') {
      this.displayNameReq = true;
      isFieldReq.push(false);
    }
    if (this.selectedHolidayYear == undefined) {
      this.holidayYearReq = true;
      isFieldReq.push(true);
    }
    isFieldReq = this.validateDateTime(isFieldReq)
    return isFieldReq;
  }

  validateDateTime(isFieldReq: any) {
    if ((this.customStartDate == undefined || this.customStartDate == null) && (this.customEndDate == undefined || this.customEndDate == null)) {
      if (this.customStartDate == undefined || this.customStartDate == null) {
        isFieldReq.push(false);
        this.startDateReq = true;
      }
      if (this.customEndDate == undefined || this.customEndDate == null) {
        isFieldReq.push(false);
        this.endDateReq = true;
      }
    }

    if ((this.customStartDate != undefined || this.customStartDate != null) && (this.customEndDate != undefined || this.customEndDate != null)) {
      let customEndDate = (this.customEndDate.getMonth() + 1).toString() + '/' + this.customEndDate.getDate().toString() + '/' + this.customEndDate.getFullYear().toString();
      let customStartDate = (this.customStartDate.getMonth() + 1).toString() + '/' + this.customStartDate.getDate().toString() + '/' + this.customStartDate.getFullYear().toString();
      if (moment(this.customEndDate.toDateString()).format("yyyy-MM-DD") < moment(this.customStartDate.toDateString()).format("yyyy-MM-DD")) {
        isFieldReq.push(false);
        this.startEndDateReq = true;
      }
    }
    // if(this.selectedEvent == undefined){
    //    isFieldReq.push(false);
    //   this.radioSelectionCheck = true;
    // }

    if (this.enableTime) {

      if (this.customStartTime == undefined || this.customStartTime == '' || this.customStartTime == null || this.customEndTime == undefined || this.customEndTime == '' || this.customEndTime == null) {
        if (this.customStartTime == null || this.customStartTime == undefined) {
          isFieldReq.push(false);
          this.startTimeReq = true;
        }

        if (this.customEndTime == null || this.customEndTime == undefined) {
          isFieldReq.push(false);
          this.endTimeReq = true;
        }
      }
      if (this.customStartTime != undefined || this.customStartTime != '' || this.customStartTime != null || this.customEndTime != undefined || this.customEndTime != '' || this.customEndTime != null) {
        let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
        if (new Date(commonDate + ' ' + this.customEndTime) < new Date(commonDate + ' ' + this.customStartTime)) {
          isFieldReq.push(false);
          this.startEndTimeReq = true;
        }
      }
    }
    return isFieldReq;
  }


  SetDays() {
    if (this.customStartDate && this.customEndDate) {
      var startDate = this.customStartDate.getDate();
      var endDate = this.customEndDate.getDate();

    }
  }

  private getUserDetail() {
    if (!this.appHolidayId) {
      return;
    }
    this.loading = true;
    this.holidayService.GetHolidayById(this.appHolidayId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<HolidayResponse>) => {
        this.processResult<HolidayResponse>(result, () => {
          this.holidayDetail = result.responseModel;
          this.setValuesOnPage();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  setValuesOnPage() {
    this.year.forEach(value => {
      if (this.holidayDetail.appHolidayYear.toString() == value.code.toString()) {
        this.selectedHolidayYear = value;
      }
    });
    this.customStartDate = new Date(this.holidayDetail.appHolidayStartDateTime);
    this.customEndDate = new Date(this.holidayDetail.appHolidayEndDateTime);
    this.customStartTime = this.holidayDetail.appHolidayStartDateTime.toString().split("T")[1].split(':')[0] + ':' + this.holidayDetail.appHolidayStartDateTime.toString().split('T')[1].split(':')[1];
    this.customEndTime = this.holidayDetail.appHolidayEndDateTime.toString().split('T')[1].split(':')[0] + ':' + this.holidayDetail.appHolidayEndDateTime.toString().split('T')[1].split(':')[1];
    if (this.holidayDetail.isFullDay) {
      this.selectedEvent = '1';
      this.enableTime = false;
    }
    else {
      this.selectedEvent = '0';
      this.enableTime = true;
    }

    if (this.holidayDetail.excludeFacilitiesIds) {
      var facilityIds = this.holidayDetail.excludeFacilitiesIds.substr(0, this.holidayDetail.excludeFacilitiesIds.length - 1).split(',').map(Number);
      this.selectedFacility = this.facilities.filter(a => facilityIds.includes(a.crmFacilityId));
    }
    this.startYearDate = moment([this.selectedHolidayYear.code]).startOf('year').toDate();
    this.endYearDate = moment([this.selectedHolidayYear.code]).endOf('year').toDate();
  }

  setValuesOnModel() {
    const holidayResponseModel = new HolidayResponse();
    holidayResponseModel.appHolidayId = this.appHolidayId > 0 ? this.appHolidayId : 0;
    holidayResponseModel.appHolidayName = this.displayName.nativeElement.value;
    holidayResponseModel.appHolidayYear = Number(this.selectedHolidayYear.code);

    holidayResponseModel.appHolidayStartDateTime = new Date(moment(this.customStartDate).format("yyyy-MM-DD"));
    holidayResponseModel.appHolidayEndDateTime = new Date(moment(this.customEndDate).format("yyyy-MM-DD"));

    holidayResponseModel.appHolidayStartDateString = moment(this.customStartDate.toDateString()).format("yyyy-MM-DD");
    holidayResponseModel.appHolidayEndDateString = moment(this.customEndDate.toDateString()).format("yyyy-MM-DD");

    holidayResponseModel.appHolidayStartTimeString = moment("04:00", 'hh:mm A').format("HH:mm");
    holidayResponseModel.appHolidayEndTimeString = moment("23:58", 'hh:mm A').format("HH:mm");
    // if (this.selectedEvent == '1') {
    //   holidayResponseModel.appHolidayStartDateTime = new Date(moment(this.customStartDate).format("yyyy-MM-DD"));
    //   holidayResponseModel.appHolidayEndDateTime = new Date(moment(this.customEndDate).format("yyyy-MM-DD"));

    //    //string value for Date
    //   holidayResponseModel.appHolidayStartDateString = moment(this.customStartDate.toDateString()).format("yyyy-MM-DD"); 
    //   holidayResponseModel.appHolidayEndDateString = moment(this.customEndDate.toDateString()).format("yyyy-MM-DD");

    //   //string value for Time
    //   //holidayResponseModel.appHolidayStartTimeString = moment("06:00", 'hh:mm A').format("HH:mm");
    //   //holidayResponseModel.appHolidayEndTimeString = moment("23:58", 'hh:mm A').format("HH:mm");

    //   //string value
    //   //holidayResponseModel.appHolidayStartDateTimeString = moment(this.customStartDate.toDateString()).format("yyyy-MM-DD");
    //   //holidayResponseModel.appHolidayEndDateTimeString = moment(this.customEndDate.toDateString()).format("yyyy-MM-DD");
    // }
    // else {
    //   holidayResponseModel.appHolidayStartDateTime = new Date(moment(this.customStartDate).format("yyyy-MM-DD") + ' ' + moment(this.customStartTime, 'hh:mm A').format("HH:mm"));
    //   holidayResponseModel.appHolidayEndDateTime = new Date(moment(this.customEndDate).format("yyyy-MM-DD") + ' ' + moment(this.customEndTime, 'hh:mm A').format("HH:mm"));

    //   //string value for Date
    //   holidayResponseModel.appHolidayStartDateString = moment(this.customStartDate.toDateString()).format("yyyy-MM-DD"); 
    //   holidayResponseModel.appHolidayEndDateString = moment(this.customEndDate.toDateString()).format("yyyy-MM-DD");

    //   //sreing value for Time
    //   //holidayResponseModel.appHolidayStartTimeString = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    //   //holidayResponseModel.appHolidayEndTimeString = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

    //   //string value for time
    //   //holidayResponseModel.appHolidayStartDateTimeString = moment(this.customStartDate.toDateString()).format("yyyy-MM-DD") + ' ' + this.customStartTime;
    //   //holidayResponseModel.appHolidayEndDateTimeString = moment(this.customEndDate.toDateString()).format("yyyy-MM-DD") + ' ' + this.customEndTime;
    // }
    //holidayResponseModel.isFullDay = this.selectedEvent == '1' ? true : false;
    holidayResponseModel.isFullDay = true;
    holidayResponseModel.isActive = true;
    holidayResponseModel.modifiedByUserInfoId = this.loggedInUserId;

    if (this.selectFacilityString != "0") {
      holidayResponseModel.excludeFacilitiesIds = this.selectFacilityString;
    }

    return holidayResponseModel;
  }

  onCancel() {
    this.holidayDetail = null;
    this.holidayService._setTabIndex(4);
    this.router.navigate(['/admin/settings/application'], { state: { searchRequestModel: this.holidayDetail } });
  }

}
