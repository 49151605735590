
export class DateFormat {
  monthFormatId: number;
  displayValue: string;
  settingValue: string;
}

export class sendDateFormat {
  dtFormats: string;
}
