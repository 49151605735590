import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/admin/servicies/notification.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { NotificationInAppDetailResponseModel } from 'src/app/shared/models/response/notificationinapp-detail-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import * as moment from 'moment';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationViewAllComponent extends BaseComponent implements OnInit, OnDestroy {

  title='Notifications';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  notificationInAppResponseModel: Array<NotificationInAppDetailResponseModel>;
  loggedInUserId: number;

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getNotifications();
  }

  private getNotifications() {
    this.loading = true;
    
    this.notificationService.getNotifications(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.notificationInAppResponseModel = result.responseModel;
          //this.ReadInAppNotifications(this.notificationInAppResponseModel);
          

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  getNotificationDate(notificationDate: Date) {
    
    var notificationDateWithoutTime = moment(notificationDate).format('MM-DD-YYYY'); //new Date(notificationDate).toLocaleDateString();
    var currentDate = new Date();

    if (notificationDateWithoutTime == (moment(currentDate).format('MM-DD-YYYY'))) {

      var diff = new Date().getTime() - new Date(notificationDate).getTime();
      var duration = this.msToTime(diff);
      return duration;
    } else {
      return moment(notificationDate).format('MM-DD-YYYY HH:mm:ss A');
    }

  }

  msToTime(millisec) {
    var seconds = parseInt((millisec / 1000).toFixed(0));
    var minutes = parseInt((millisec / (1000 * 60)).toFixed(0));
    var hours = parseInt((millisec / (1000 * 60 * 60)).toFixed(0));
    //var days = parseInt((millisec / (1000 * 60 * 60 * 24)).toFixed(0));

    if (seconds < 60) {
      return "1m ago";
    } else if (minutes < 60) {
      return minutes + "m ago";
    } else if (hours < 24) {
      return hours + "h ago";
    }
  }

  ReadInAppNotifications(notificationInAppDetailResponseModel: Array<NotificationInAppDetailResponseModel>) {
    this.loading = true;
    
    this.notificationService.readInAppNotification(notificationInAppDetailResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
