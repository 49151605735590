import { Component, OnInit, ElementRef, ViewChild, OnChanges, Input, SimpleChanges, Renderer2, EventEmitter, AfterViewInit } from '@angular/core';
import { CdkDragDrop, CdkDragEnter, CdkDragMove, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KPIAddReportRequestModel, KPIReportSortOrderRequestModel, KPIReportVisualizationModel, UserReportFilterPrefrenceRequestModel } from 'src/app/shared/models/request/kpiaddreport-request-model';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { SettingsService } from '../../servicies/settings.service';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { UserService } from '../../servicies/user.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { KPIReportService } from '../../servicies/kpireport.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { KPIReportFiltersResponseModel } from 'src/app/shared/models/response/kpi-report-filters-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import * as Highcharts from 'highcharts';
import * as $ from 'jquery'

import More from 'highcharts/highcharts-more';
More(Highcharts);
import AnnotationsModule from 'highcharts/modules/annotations'
AnnotationsModule(Highcharts);
//Load drilldown module for implement parent- child chart
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
// Load the exporting module.
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);

// Load the export-data module to view chart data in Table view.
import ExportData from 'highcharts/modules/export-data'
ExportData(Highcharts);
//Load highchart 3d module to display chart in 3d
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);
import highstock from 'highcharts/modules/stock';
import { KPIReportSearchRequestModel, KPIReportModel, MonthModel, WeekModel, YearModel, KPIUserPrefrenceResponseModel, KPIReportDataTableResponse, CombinedChartModel } from 'src/app/shared/models/response/kpi-report-response-model';
import html2canvas from 'html2canvas';
import jspdf, { HTMLOptions } from 'jspdf';
import * as moment from 'moment';
import { KPIReportResponseModel } from 'src/app/shared/models/response/role-feature-modeul-permission';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { report } from 'process';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { Table } from "primeng/table";
highstock(Highcharts);


@Component({
  selector: 'app-kpi-report',
  templateUrl: './kpi-report.component.html',
  styleUrls: ['./kpi-report.component.sass'],
  providers: [DialogService]
})
export class KpiReportComponent extends BaseComponent implements OnInit {
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };
  public items: Array<number> = [1];
  yeardata!: any;
  movingReportData!: any;
  CloseSidebar: boolean = false;
  formBlock: boolean;
  columnTabsMenu: boolean;
  setColumnsOne: any;
  setColumnsTwo: any;
  setColumnsThree: any;
  currentStyles: {};
  AppointmentStatusDropdown: { name: string; value: string, isChecked: boolean }[];
  TimePeriodDropdown: { name: string; isChecked: boolean }[];
  TimePeriodMonthDropdown: { name: string; value: number; isChecked: boolean }[];
  TimePeriodWeekDropdown: { name: string; value: number; isChecked: boolean }[];
  TimePeriodDayDropdown: { name: string; value: number; isChecked: boolean }[];
  YAxisCategoryDropdown: { name: string; value: string, isChecked: boolean, orderBy: number }[];
  CombinedYAxisCategoryDropdown: { name: string; value: string, isChecked: boolean, orderBy: number }[];
  CombinedReportAverageByDropdown: { name: string; value: number, isChecked: boolean }[];
  ComparativeChartTypeDropdown: { name: string; value: string, isChecked: boolean }[];
  movies: any[];
  chartBlock = [''];
  AddNewReport: boolean = false;
  RenameBox: boolean = false;
  NoreportsAvailable: boolean = false;
  MinimizeMaximize: boolean = false;
  loggedInUserId: number;
  kpiAddReportRequestModel: KPIAddReportRequestModel;
  private progress = new Subject<boolean>();
  private unsubscriber = new Subject<boolean>();
  kpiReportMasterDetails: KPIReportResponseModel[];
  kpiReportFiltersResponseModel: KPIReportFiltersResponseModel;
  selectedSchedulerCnt: number;
  selectedClassificationsCnt: number;
  selectedBranchCnt: number;
  selectedFacilityCnt: number;
  selectedTherapistCnt: number;
  selectedInsuranceCnt: number;
  selectedAppointmentTypeCnt: number;
  selectedYearsCnt: number;
  selectedMonthsCnt: number;
  selectedWeeksCnt: number;
  selectedDaysCnt: number;
  appointmentStatusCnt: number;

  isAllSchedulerSelect: boolean = false;
  isAllClassificationsSelect: boolean = false;
  isAllBranchSelect: boolean = false;
  isAllFacilitySelect: boolean = false;
  isAllTherapistSelect: boolean = false;
  isAllInsuranceSelect: boolean = false;
  isAllAppointmentTypeSelect: boolean = false;
  isAllYearsSelect: boolean = false;
  isAllMonthsSelect: boolean = false;
  isAllAppointmentStatus: boolean = false;
  //isAllYAxisStatus: boolean = false;

  searchRequestModel!: KPIReportSearchRequestModel;
  kpiReportModel: KPIReportModel[];
  selectedReportItem: any;
  selectedKPIReportTypeToCreate: KPIReportResponseModel;
  kpiReportDataTable: KPIReportDataTableResponse[];
  //kpiReportMovingAverageDataTable: KPIReportMovingAverageDataTableResponse[];
  IsDataTable: boolean = false;
  Highcharts = Highcharts;
  reportName: string;
  isMovingAverage: boolean = false;
  @Input() kpiLoading: boolean = false;
  appDateFormat: DateFormatModel;
  editScreen: boolean;
  roleId: number;
  selectedReportLayout: number = 2;
  isSubmitReport: boolean = false;
  pageSiges: QuickFilters[];
  selectedpage: QuickFilters;
  selectedCategory: any = null;
  selectedComparativeChartType: any = null;
  defaultCategory: string = 'Year'
  defaultComparativeChartType: string = 'Column';
  todo: any = null;
  defaultTodo: any = null;
  defaultTodoForCombinedReport: any = null;
  SelectedVisualizationFieldTexts: string = 'Select';
  defaultCombinedReportAverageBy: number = 3;
  selectedCombinedReportAverageBy: any = null;
  isAllowed: boolean = false;
  FilteredYAxisCategoryDropdown: any = null;
  FilterCombinedYAxisCategoryDropdown: any = null;
  Permission: boolean = false;
  hasParameterError: boolean = false;
  hasMinParameterError: boolean = false;
  hasInvalidChar: boolean = false;

  @Input() refreshGrid: boolean = false;
  quickMenu: any;
  hideOnEscape: boolean = false;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private webStorageService: WebStorageService,
    private userService: UserService,
    private kpiReportService: KPIReportService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService,
    private settingsService: SettingsService,
    private dialougeService: DialogService, private renderer: Renderer2) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.appDateFormat = this.webStorageService.getDateFormat();
    var permission = this.webStorageService.getCurrentUserToken();
    this.roleId = permission.roleId;
  }

  formFields = {
    reportName: 'reportName',
    //yaxisCategory: 'yaxisCategory',
    timePeriod: 'timePeriod',
    chartType: 'chartType',
    //visualization: 'visualization',
    averageBy: 'averageBy'
  };
  validationMessages = {
    reportNameValidation: {
      required: 'Report Name is required',
    }
  }

  ngOnInit(): void {
    this.buildForm();
    this.pageSiges = [
      { name: '20', code: '20' },
      { name: '25', code: '25' },
      { name: '50', code: '50' },
      { name: '100', code: '100' },
    ];
    this.selectedpage = { name: '20', code: '20' };

    this.AppointmentStatusDropdown = [
      { name: 'Scheduled Appointments', value: 'Scheduled Appointments', isChecked: false },
      { name: 'Rescheduled Appointments', value: 'Rescheduled Appointments', isChecked: false },
      { name: 'Booked Appointments', value: 'Booked Appointments', isChecked: false },
      { name: 'Cancelled Appointments', value: 'Cancel Appointments', isChecked: false },
      { name: 'Noshow Appointments', value: 'NoShow Appointments', isChecked: false },
      { name: 'Completed Appointments', value: 'Completed Appointments', isChecked: false },
      { name: 'Unreconciled Appointments', value: 'Unreconciled Appointments', isChecked: false },
      { name: 'Awaiting Appointments', value: 'Awaiting Appointments', isChecked: false },
      { name: 'Reconciled Appointments', value: 'Reconciled Appointments', isChecked: false },
      { name: 'Void Appointments', value: 'Void Appointments', isChecked: false },
      // { name: 'Void Review Appointments', value:'VoidReview Appointments', isChecked: false },
    ];

    this.YAxisCategoryDropdown = [
      { name: 'Appointment Status', value: 'Status', isChecked: false, orderBy: 1 },
      { name: 'Appointment Types', value: 'SchAppointmentType', isChecked: false, orderBy: 2 },
      { name: 'Branches', value: 'CrmBranch', isChecked: false, orderBy: 3 },
      { name: 'Classifications', value: 'CrmClassification', isChecked: false, orderBy: 4 },
      { name: 'Facilities', value: 'CrmFacilityName', isChecked: false, orderBy: 5 },
      { name: 'Insurances', value: 'Insurance', isChecked: false, orderBy: 6 },
      { name: 'Month', value: 'Month', isChecked: false, orderBy: 7 },
      { name: 'Schedulers', value: 'SchAppointmentBookedByPerson', isChecked: false, orderBy: 8 },
      { name: 'Therapists', value: 'AppTherapistName', isChecked: false, orderBy: 9 },
      { name: 'Year', value: 'Year', isChecked: false, orderBy: 10 },
    ];


    this.CombinedYAxisCategoryDropdown = [
      { name: 'Appointment Date', value: 'AppDate', isChecked: true, orderBy: 1 },
      { name: 'Appointment Status', value: 'Status', isChecked: false, orderBy: 2 },
      { name: 'Appointment Types', value: 'SchAppointmentType', isChecked: false, orderBy: 3 },
      { name: 'Branches', value: 'CrmBranch', isChecked: false, orderBy: 4 },
      { name: 'Classifications', value: 'CrmClassification', isChecked: false, orderBy: 5 },
      { name: 'Facilities', value: 'CrmFacilityName', isChecked: false, orderBy: 6 },
      { name: 'Insurances', value: 'Insurance', isChecked: false, orderBy: 7 },
      { name: 'Schedulers', value: 'SchAppointmentBookedByPerson', isChecked: false, orderBy: 8 },
      { name: 'Therapists', value: 'AppTherapistName', isChecked: false, orderBy: 9 },
      { name: 'Year', value: 'CrmYear', isChecked: false, orderBy: 10 },
      { name: 'Quarter', value: 'CrmQuarter', isChecked: false, orderBy: 11 },
    ];

    this.CombinedReportAverageByDropdown = [
      { name: '1', value: 1, isChecked: false },
      { name: '2', value: 2, isChecked: false },
      { name: '3', value: 3, isChecked: false },
      { name: '4', value: 4, isChecked: false },
      { name: '5', value: 5, isChecked: false },
      { name: '6', value: 6, isChecked: false },
      { name: '7', value: 7, isChecked: false },
      { name: '8', value: 8, isChecked: false },
      { name: '9', value: 9, isChecked: false },
      { name: '10', value: 10, isChecked: false },
    ];

    this.ComparativeChartTypeDropdown = [
      { name: 'Column Chart', value: 'Normal', isChecked: false },
      { name: 'Stacked Column Chart', value: 'Stack', isChecked: false }
    ];

    this.defaultTodo = [
      // { name: 'Appointment Status', value: 'Status', isChecked: false },
      // { name: 'Classifications', value: 'CrmClassification', isChecked: false },
      // { name: 'Branches', value: 'CrmBranch', isChecked: false },
      // { name: 'Facilities', value: 'CrmFacilityName', isChecked: false },
    ];

    this.defaultTodoForCombinedReport = [
      { name: 'Appointment Date', value: 'AppDate', isChecked: true },
      // { name: 'Appointment Status', value: 'Status', isChecked: false }
    ];

    this.clearFilters();
    this.todo = [... this.defaultTodo];
    const currentYear = new Date().getFullYear() + 1;
    const last5Years = [];
    for (let i = 0; i < 5; i++) {
      last5Years.push({ name: (currentYear - i).toString(), isChecked: false });
    }
    this.TimePeriodDropdown = last5Years;

    this.TimePeriodMonthDropdown = [
      { name: 'January', value: 1, isChecked: false },
      { name: 'February', value: 2, isChecked: false },
      { name: 'March', value: 3, isChecked: false },
      { name: 'April', value: 4, isChecked: false },
      { name: 'May', value: 5, isChecked: false },
      { name: 'June', value: 6, isChecked: false },
      { name: 'July', value: 7, isChecked: false },
      { name: 'August', value: 8, isChecked: false },
      { name: 'September', value: 9, isChecked: false },
      { name: 'October', value: 10, isChecked: false },
      { name: 'November', value: 11, isChecked: false },
      { name: 'December', value: 12, isChecked: false }
    ];

    this.selectedCategory = this.defaultCategory;
    this.selectedCombinedReportAverageBy = this.defaultCombinedReportAverageBy;
    //this.selectedComparativeChartType = this.defaultComparativeChartType;

    // this.TimePeriodWeekDropdown = [];
    // for (let weekNumber = 1; weekNumber <= 52; weekNumber++) {
    //   const weekName = `Week ${weekNumber}`;
    //   this.TimePeriodWeekDropdown.push({ name: weekName, value: weekNumber, isChecked: false });
    // }

    // this.TimePeriodDayDropdown = [];
    // for (let day = 1; day <= 31; day++) {
    //   this.TimePeriodDayDropdown.push({ name: day.toString(), value: day, isChecked: false });
    // }
    this.getUserReportLayoutPrefrences();
    this.getKPIReportMasterDetails();
    this.getKPIReportFilters();
    this.GetKPIUserPrefrencesReportList();
    //this.GetKPIReportDetails();


    // let highchartMenu = document.querySelector('.xyz-report-block__chart');

    // if (window.getComputedStyle(highchartMenu).display === "block") {
    //   highchartMenu.classList.add('xyz-report-block__chart-active');
    // }

    $(document).on('click', '.kpi-report-layout__sidebar .scrollDiv, .quick-link-menu .scrollDiv', function (e) {
      e.preventDefault();
      // var tghsh = $(this).attr('rel');
      var doffset = $('#ScrollTop1').offset().top - 150;
      $('.kpi-report-layout__left').animate({ scrollTop: doffset }, 500, function () {
        location.hash = '';
      });
      // return false;
    });
  }

  ngOnChanges() {
    if (this.refreshGrid) {
      this.GetKPIUserPrefrencesReportList();
    }
  }

  private async getUserReportLayoutPrefrences() {
    this.progress.next(true);
    await this.kpiReportService.GetUserReportLayoutPrefrences(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (!result) {
          this.setColumnWidth(this.selectedReportLayout);
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.selectedReportLayout = result.responseModel;
          this.setColumnWidth(this.selectedReportLayout);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getKPIReportMasterDetails() {
    this.progress.next(true);
    await this.kpiReportService.GetKPIReportMasterDetailWithPermission(this.roleId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.kpiReportMasterDetails = result.responseModel;
          if (this.kpiReportMasterDetails.length > 0)
            this.isAllowed = true;
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getKPIReportFilters() {
    this.progress.next(true);
    await this.kpiReportService.getKPIReportFilters()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<KPIReportFiltersResponseModel>) => {
        this.processResult<KPIReportFiltersResponseModel>(result, () => {
          if (!result) {
            return;
          }
          if (result.responseModel != undefined && result.responseModel != null) {
            this.kpiReportFiltersResponseModel = result.responseModel;
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onCheckboxChanged(selectedTab: string) {
    if (selectedTab == 'scheduler') {
      this.selectedSchedulerCnt = this.kpiReportFiltersResponseModel.kpiSchedulers.filter(x => x.isChecked).length;
      if (this.selectedSchedulerCnt == this.kpiReportFiltersResponseModel.kpiSchedulers.length) {
        this.isAllSchedulerSelect = true;
      }
      else {
        this.isAllSchedulerSelect = false;
      }
    }
    else if (selectedTab == 'classification') {
      this.selectedClassificationsCnt = this.kpiReportFiltersResponseModel.kpiClassifications.filter(x => x.isChecked).length;
      if (this.selectedClassificationsCnt == this.kpiReportFiltersResponseModel.kpiClassifications.length) {
        this.isAllClassificationsSelect = true;
      }
      else {
        this.isAllClassificationsSelect = false;
      }
    }
    else if (selectedTab == 'branch') {
      this.selectedBranchCnt = this.kpiReportFiltersResponseModel.kpiBranches.filter(x => x.isChecked).length;
      if (this.selectedBranchCnt == this.kpiReportFiltersResponseModel.kpiBranches.length) {
        this.isAllBranchSelect = true;
      }
      else {
        this.isAllBranchSelect = false;
      }
    }
    else if (selectedTab == 'facility') {
      this.selectedFacilityCnt = this.kpiReportFiltersResponseModel.kpiFacilities.filter(x => x.isChecked).length;
      if (this.selectedFacilityCnt == this.kpiReportFiltersResponseModel.kpiFacilities.length) {
        this.isAllFacilitySelect = true;
      }
      else {
        this.isAllFacilitySelect = false;
      }
    }
    else if (selectedTab == 'therapist') {
      this.selectedTherapistCnt = this.kpiReportFiltersResponseModel.kpiTherapist.filter(x => x.isChecked).length;
      if (this.selectedTherapistCnt == this.kpiReportFiltersResponseModel.kpiTherapist.length) {
        this.isAllTherapistSelect = true;
      }
      else {
        this.isAllTherapistSelect = false;
      }
    }
    else if (selectedTab == 'insurance') {
      this.selectedInsuranceCnt = this.kpiReportFiltersResponseModel.kpiInsurances.filter(x => x.isChecked).length;
      if (this.selectedInsuranceCnt == this.kpiReportFiltersResponseModel.kpiInsurances.length) {
        this.isAllInsuranceSelect = true;
      }
      else {
        this.isAllInsuranceSelect = false;
      }
    }
    else if (selectedTab == 'appointmenttype') {
      this.selectedAppointmentTypeCnt = this.kpiReportFiltersResponseModel.kpiAppointmentTypes.filter(x => x.isChecked).length;
      if (this.selectedAppointmentTypeCnt == this.kpiReportFiltersResponseModel.kpiAppointmentTypes.length) {
        this.isAllAppointmentTypeSelect = true;
      }
      else {
        this.isAllAppointmentTypeSelect = false;
      }
    }
    else if (selectedTab == 'appointmentstatus') {
      let selectedAppointmentStatus = this.AppointmentStatusDropdown.filter(x => x.isChecked).length;
      this.appointmentStatusCnt = selectedAppointmentStatus;
      if (selectedAppointmentStatus == this.AppointmentStatusDropdown.length) {
        this.isAllAppointmentStatus = true;
      }
      else {
        this.isAllAppointmentStatus = false;
      }
    }
    else if (selectedTab == 'year') {
      this.selectedYearsCnt = this.TimePeriodDropdown.filter(x => x.isChecked).length;
      if (this.selectedYearsCnt == this.TimePeriodDropdown.length) {
        this.isAllYearsSelect = true;
      }
      else {
        this.isAllYearsSelect = false;
      }
    }
    else if (selectedTab == 'month') {
      this.selectedMonthsCnt = this.TimePeriodMonthDropdown.filter(x => x.isChecked).length;
      if (this.selectedMonthsCnt == this.TimePeriodMonthDropdown.length) {
        this.isAllMonthsSelect = true;
      }
      else {
        this.isAllMonthsSelect = false;
      }
    }
    // else if (selectedTab == 'week') {
    //   this.selectedWeeksCnt = this.TimePeriodWeekDropdown.filter(x => x.isChecked).length;
    // }
    // else if (selectedTab == 'day') {
    //   this.selectedDaysCnt = this.TimePeriodDayDropdown.filter(x => x.isChecked).length;
    // }
  }

  getSelectionCount(selectedTab: string) {
    let reportItem = this.kpiReportModel.filter(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId);
    this.clearFilters();
    if (reportItem != undefined) {
      this.clearDefaultFilterSelections(selectedTab);
      reportItem.forEach(value => {
        if (selectedTab == 'filter') {
          if (value.selectedSchedulers != undefined && value.selectedSchedulers.length > 0) {
            this.selectedSchedulerCnt = value.selectedSchedulers.split(',').length;
            if (this.selectedSchedulerCnt == this.kpiReportFiltersResponseModel.kpiSchedulers.length) {
              this.isAllSchedulerSelect = true;
            }
            else {
              this.isAllSchedulerSelect = false;
            }
            value.selectedSchedulers.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(x => {
                if (x.userInfoId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedClassification != undefined && value.selectedClassification.length > 0) {
            this.selectedClassificationsCnt = value.selectedClassification.split(',').length;
            if (this.selectedClassificationsCnt == this.kpiReportFiltersResponseModel.kpiClassifications.length) {
              this.isAllClassificationsSelect = true;
            }
            else {
              this.isAllClassificationsSelect = false;
            }
            value.selectedClassification.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiClassifications.forEach(x => {
                if (x.crmClassificationTypeId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedBranches != undefined && value.selectedBranches.length > 0) {
            this.selectedBranchCnt = value.selectedBranches.split(',').length;
            if (this.selectedBranchCnt == this.kpiReportFiltersResponseModel.kpiBranches.length) {
              this.isAllBranchSelect = true;
            }
            else {
              this.isAllBranchSelect = false;
            }
            value.selectedBranches.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiBranches.forEach(x => {
                if (x.crmBranchId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedFacility != undefined && value.selectedFacility.length > 0) {
            this.selectedFacilityCnt = value.selectedFacility.split(',').length;
            if (this.selectedFacilityCnt == this.kpiReportFiltersResponseModel.kpiFacilities.length) {
              this.isAllFacilitySelect = true;
            }
            else {
              this.isAllFacilitySelect = false;
            }
            value.selectedFacility.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiFacilities.forEach(x => {
                if (x.facilityId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedTherapist != undefined && value.selectedTherapist.length > 0) {
            this.selectedTherapistCnt = value.selectedTherapist.split(',').length;
            if (this.selectedTherapistCnt == this.kpiReportFiltersResponseModel.kpiTherapist.length) {
              this.isAllTherapistSelect = true;
            }
            else {
              this.isAllTherapistSelect = false;
            }
            value.selectedTherapist.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiTherapist.forEach(x => {
                if (x.therapistId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedInsurance != undefined && value.selectedInsurance.length > 0) {
            this.selectedInsuranceCnt = value.selectedInsurance.split(',').length;
            if (this.selectedInsuranceCnt == this.kpiReportFiltersResponseModel.kpiInsurances.length) {
              this.isAllInsuranceSelect = true;
            }
            else {
              this.isAllInsuranceSelect = false;
            }
            value.selectedInsurance.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiInsurances.forEach(x => {
                if (x.crmPolicyHolderName == value) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedAppointmentTypes != undefined && value.selectedAppointmentTypes.length > 0) {
            this.selectedAppointmentTypeCnt = value.selectedAppointmentTypes.split(',').length;
            if (this.selectedAppointmentTypeCnt == this.kpiReportFiltersResponseModel.kpiAppointmentTypes.length) {
              this.isAllAppointmentTypeSelect = true;
            }
            else {
              this.isAllAppointmentTypeSelect = false;
            }
            value.selectedAppointmentTypes.split(',').forEach(value => {
              this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(x => {
                if (x.appointmentTypeId == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }
          if (value.selectedAppointmentStatus != undefined && value.selectedAppointmentStatus.length > 0) {
            this.appointmentStatusCnt = value.selectedAppointmentStatus.split(',').length;
            if (this.appointmentStatusCnt == this.AppointmentStatusDropdown.length) {
              this.isAllAppointmentStatus = true;
            }
            else {
              this.isAllAppointmentStatus = false;
            }
            value.selectedAppointmentStatus.split(',').forEach(value => {
              this.AppointmentStatusDropdown.forEach(x => {
                if (x.value.toLowerCase() == value.toLowerCase()) {
                  x.isChecked = true;
                }
              });
            });
          }
          if (value.selectedYear != undefined && value.selectedYear.length > 0) {
            this.selectedYearsCnt = value.selectedYear.split(',').length;
            if (this.selectedYearsCnt == this.TimePeriodDropdown.length) {
              this.isAllYearsSelect = true;
            }
            else {
              this.isAllYearsSelect = false;
            }
            value.selectedYear.split(',').forEach(value => {
              this.TimePeriodDropdown.forEach(x => {
                if (x.name.toLowerCase() == value.toLowerCase()) {
                  x.isChecked = true;
                }
              });
            });
          }

          if (value.selectedMonth != undefined && value.selectedMonth.length > 0) {
            this.selectedMonthsCnt = value.selectedMonth.split(',').length;
            if (this.selectedMonthsCnt == this.TimePeriodMonthDropdown.length) {
              this.isAllMonthsSelect = true;
            }
            else {
              this.isAllMonthsSelect = false;
            }
            value.selectedMonth.split(',').forEach(value => {
              this.TimePeriodMonthDropdown.forEach(x => {
                if (x.value == Number(value)) {
                  x.isChecked = true;
                }
              });
            });
          }
        }
        else if (selectedTab == 'timeperiod') {
          // if (value.selectedWeeks != undefined) {
          //   this.selectedWeeksCnt = value.selectedWeeks.split(',').length;
          //   value.selectedWeeks.split(',').forEach(value => {
          //     this.TimePeriodWeekDropdown.forEach(x => {
          //       if (x.value == Number(value)) {
          //         x.isChecked = true;
          //       }
          //     });
          //   });
          // }

          // if (value.selectedDays != undefined) {
          //   this.selectedDaysCnt = value.selectedDays.split(',').length;
          //   value.selectedDays.split(',').forEach(value => {
          //     this.TimePeriodDayDropdown.forEach(x => {
          //       if (x.value == Number(value)) {
          //         x.isChecked = true;
          //       }
          //     });
          //   });
          // }
        }
        else if (selectedTab == 'visualization') {
          this.todo = [];
          if (value.orgReportName != 'combined') {
            if (value?.selectedXAxisList.length > 0) {
              value.selectedXAxisList.forEach(item => {
                let result = this.YAxisCategoryDropdown.filter(x => x.value == item.kpiXAxisFieldName);
                if (result != undefined && result != null) {
                  this.todo.push(result[0]);
                }
              });
            }
            else {
              this.todo = [... this.defaultTodo];
            }
          }
          else {
            if (value?.selectedXAxisList.length > 0) {
              value.selectedXAxisList.forEach(item => {
                let result = this.CombinedYAxisCategoryDropdown.filter(x => x.value == item.kpiXAxisFieldName);
                if (result != undefined && result != null) {
                  this.todo.push(result[0]);
                }
              });
            }
            else {
              this.todo = this.defaultTodoForCombinedReport;
            }
          }
          if (value.orgReportName != 'combined') {
            if (value?.selectedXAxisList.length > 0) {
              value.selectedXAxisList.forEach(item => {
                this.FilteredYAxisCategoryDropdown = this.FilteredYAxisCategoryDropdown.filter(x => x.value != item.kpiXAxisFieldName);
              });
            }
          } else if (value?.selectedXAxisList.length > 0) {
            value.selectedXAxisList.forEach(item => {
              this.FilterCombinedYAxisCategoryDropdown = this.FilterCombinedYAxisCategoryDropdown.filter(x => x.value != item.kpiXAxisFieldName);
            });
          }
        }
        else if (selectedTab == 'columnChart') {
          if (value?.columnType != null && value?.columnType.length > 0) {
            this.ComparativeChartTypeDropdown.forEach(x => {
              if (x.value == value.columnType) {
                x.isChecked = true;
              }
            });
          }
        }
      });
    }
  }

  clearDefaultFilterSelections(selectedTab: string) {
    this.AddNewReport = false;
    if (selectedTab == 'filter') {
      this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiClassifications.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiBranches.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiFacilities.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiTherapist.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiInsurances.forEach(x => { x.isChecked = false; });
      this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(x => { x.isChecked = false; });
      this.AppointmentStatusDropdown.forEach(x => { x.isChecked = false; });
      this.isAllAppointmentStatus = false;

      this.selectedSchedulerCnt = 0;
      this.selectedClassificationsCnt = 0;
      this.selectedBranchCnt = 0;
      this.selectedFacilityCnt = 0;
      this.selectedTherapistCnt = 0;
      this.selectedInsuranceCnt = 0;
      this.selectedAppointmentTypeCnt = 0;
      this.appointmentStatusCnt = 0;

      this.isAllSchedulerSelect = false;
      this.isAllClassificationsSelect = false;
      this.isAllBranchSelect = false;
      this.isAllFacilitySelect = false;
      this.isAllTherapistSelect = false;
      this.isAllInsuranceSelect = false;
      this.isAllAppointmentTypeSelect = false;

      this.TimePeriodDropdown.forEach(x => { x.isChecked = false; });
      this.TimePeriodMonthDropdown.forEach(x => { x.isChecked = false; });
      // this.TimePeriodWeekDropdown.forEach(x => { x.isChecked = false; });
      // this.TimePeriodDayDropdown.forEach(x => { x.isChecked = false; });

      this.selectedYearsCnt = 0;
      this.selectedMonthsCnt = 0;
      // this.selectedWeeksCnt = 0;
      // this.selectedDaysCnt = 0;

      this.isAllYearsSelect = false;
      this.isAllMonthsSelect = false;
    }
    else if (selectedTab == 'timeperiod') {
      this.TimePeriodDropdown.forEach(x => { x.isChecked = false; });
    }
    else if (selectedTab == 'visualization') {
      if (this.selectedReportItem?.orgReportName != 'combined') {
        this.todo = [... this.defaultTodo];
      }
      else {
        this.todo = [... this.defaultTodoForCombinedReport];
      }
    }
    else if (selectedTab == 'columnChart') {
      this.ComparativeChartTypeDropdown.forEach(x => { x.isChecked = false; });
    }
  }

  onReportSelection(item: any) {
    this.selectedReportItem = item;

  }

  onFilterSave(event, element) {
    var filterRequest = Array<UserReportFilterPrefrenceRequestModel>();
    let schedulers = '';
    let classifications = '';
    let branches = '';
    let facilities = '';
    let therapists = '';
    let insurances = '';
    let appointmentTypes = '';
    let appointmentStatus = '';
    let years = '';
    let months = '';

    //AppointmentSatus
    var selectedAppointmentStatus = this.AppointmentStatusDropdown.filter(x => x.isChecked);
    if (selectedAppointmentStatus != undefined && selectedAppointmentStatus.length > 0) {
      var kpiReportFilterPreferanceList = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList.FilterName = "AppointmentStatus";
      kpiReportFilterPreferanceList.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList.DateTimeLastModified = new Date();

      selectedAppointmentStatus.forEach(element => {
        appointmentStatus += element.value.toString() + ',';
      });
      appointmentStatus = appointmentStatus.substring(0, appointmentStatus.length - 1);
      kpiReportFilterPreferanceList.FilterValues = appointmentStatus;
      filterRequest.push(kpiReportFilterPreferanceList);
    }
    else {
      this.AppointmentStatusDropdown.forEach(x => { x.isChecked = false; });
      this.selectedAppointmentTypeCnt = 0;
      this.isAllAppointmentStatus = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedAppointmentStatus = '';
        }
      });
    }

    //Schedulers
    var selectedSchedulers = this.kpiReportFiltersResponseModel?.kpiSchedulers.filter(x => x.isChecked);
    if (selectedSchedulers != undefined && selectedSchedulers.length > 0) {
      var kpiReportFilterPreferanceList = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList.FilterName = "KPISchedulers";
      kpiReportFilterPreferanceList.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList.DateTimeLastModified = new Date();

      selectedSchedulers.forEach(element => {
        schedulers += element.userInfoId.toString() + ',';
      });
      schedulers = schedulers.substring(0, schedulers.length - 1);
      kpiReportFilterPreferanceList.FilterValues = schedulers;
      filterRequest.push(kpiReportFilterPreferanceList);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(x => { x.isChecked = false; });
      this.selectedSchedulerCnt = 0;
      this.isAllSchedulerSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedSchedulers = '';
        }
      });
    }

    //Classifications
    var seectedClassifications = this.kpiReportFiltersResponseModel.kpiClassifications.filter(x => x.isChecked);
    if (seectedClassifications != undefined && seectedClassifications.length > 0) {
      var kpiReportFilterPreferanceList1 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList1.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList1.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList1.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList1.FilterName = "KPIClassifications";
      kpiReportFilterPreferanceList1.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList1.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList1.DateTimeLastModified = new Date();

      seectedClassifications.forEach(element => {
        classifications += element.crmClassificationTypeId + ',';
      });
      classifications = classifications.substring(0, classifications.length - 1);
      kpiReportFilterPreferanceList1.FilterValues = classifications.toString();
      filterRequest.push(kpiReportFilterPreferanceList1);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiClassifications.forEach(x => { x.isChecked = false; });
      this.selectedClassificationsCnt = 0;
      this.isAllClassificationsSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedClassification = '';
        }
      });
    }

    //Branches
    var selectedBranches = this.kpiReportFiltersResponseModel.kpiBranches.filter(x => x.isChecked);
    if (selectedBranches != undefined && selectedBranches.length > 0) {
      var kpiReportFilterPreferanceList2 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList2.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList2.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList2.FilterName = "KPIBranches";
      kpiReportFilterPreferanceList2.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList2.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList2.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList2.DateTimeLastModified = new Date();

      selectedBranches.forEach(element => {
        branches += element.crmBranchId.toString() + ',';
      });
      branches = branches.substring(0, branches.length - 1);
      kpiReportFilterPreferanceList2.FilterValues = branches;
      filterRequest.push(kpiReportFilterPreferanceList2);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiBranches.forEach(x => { x.isChecked = false; });
      this.selectedBranchCnt = 0;
      this.isAllBranchSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedBranches = '';
        }
      });
    }

    //Facilities
    var selectedFacilities = this.kpiReportFiltersResponseModel.kpiFacilities.filter(x => x.isChecked);
    if (selectedFacilities != undefined && selectedFacilities.length > 0) {
      var kpiReportFilterPreferanceList3 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList3.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList3.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList3.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList3.FilterName = "KPIFacilities";
      kpiReportFilterPreferanceList3.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList3.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList3.DateTimeLastModified = new Date();

      selectedFacilities.forEach(element => {
        facilities += element.facilityId.toString() + ',';
      });
      facilities = facilities.substring(0, facilities.length - 1);
      kpiReportFilterPreferanceList3.FilterValues = facilities;
      filterRequest.push(kpiReportFilterPreferanceList3);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiFacilities.forEach(x => { x.isChecked = false; });
      this.selectedFacilityCnt = 0;
      this.isAllFacilitySelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedFacility = '';
        }
      });
    }

    //Therapists
    var selectedTherapists = this.kpiReportFiltersResponseModel.kpiTherapist.filter(x => x.isChecked);
    if (selectedTherapists != undefined && selectedTherapists.length > 0) {
      var kpiReportFilterPreferanceList4 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList4.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList4.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList4.FilterName = "KPITherapist";
      kpiReportFilterPreferanceList4.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList4.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList4.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList4.DateTimeLastModified = new Date();

      selectedTherapists.forEach(element => {
        therapists += element.therapistId.toString() + ',';
      });
      therapists = therapists.substring(0, therapists.length - 1);
      kpiReportFilterPreferanceList4.FilterValues = therapists;
      filterRequest.push(kpiReportFilterPreferanceList4);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiTherapist.forEach(x => { x.isChecked = false; });
      this.selectedTherapistCnt = 0;
      this.isAllTherapistSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedTherapist = '';
        }
      });
    }

    //Insurances
    var selectedInsurances = this.kpiReportFiltersResponseModel.kpiInsurances.filter(x => x.isChecked);
    if (selectedInsurances != undefined && selectedInsurances.length > 0) {
      var kpiReportFilterPreferanceList5 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList5.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList5.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList5.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList5.FilterName = "KPIInsurance";
      kpiReportFilterPreferanceList5.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList5.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList5.DateTimeLastModified = new Date();

      selectedInsurances.forEach(element => {
        insurances += element.crmPolicyHolderName.toString() + ',';
      });
      insurances = insurances.substring(0, insurances.length - 1);
      kpiReportFilterPreferanceList5.FilterValues = insurances;
      filterRequest.push(kpiReportFilterPreferanceList5);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiInsurances.forEach(x => { x.isChecked = false; });
      this.selectedInsuranceCnt = 0;
      this.isAllInsuranceSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedInsurance = '';
        }
      });
    }

    //AppointmentTypes
    var selectedAppointmentTypes = this.kpiReportFiltersResponseModel.kpiAppointmentTypes.filter(x => x.isChecked);
    if (selectedAppointmentTypes != undefined && selectedAppointmentTypes.length > 0) {
      var kpiReportFilterPreferanceList6 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList6.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList6.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList6.FilterTimePeriod = '0';
      kpiReportFilterPreferanceList6.FilterName = "KPIAppointmentTypes";
      kpiReportFilterPreferanceList6.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList6.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList6.DateTimeLastModified = new Date();

      selectedAppointmentTypes.forEach(element => {
        appointmentTypes += element.appointmentTypeId + ',';
      });
      appointmentTypes = appointmentTypes.substring(0, appointmentTypes.length - 1);
      kpiReportFilterPreferanceList6.FilterValues = appointmentTypes.toString();
      filterRequest.push(kpiReportFilterPreferanceList6);
    }
    else {
      this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(x => { x.isChecked = false; });
      this.selectedAppointmentTypeCnt = 0;
      this.isAllAppointmentTypeSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedAppointmentTypes = '';
        }
      });
    }

    var selectedYears = this.TimePeriodDropdown.filter(x => x.isChecked);
    if (selectedYears != undefined && selectedYears.length > 0) {
      var kpiReportFilterPreferanceList = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList.FilterName = "TimePeriod";
      kpiReportFilterPreferanceList.FilterTimePeriod = 'Year';
      kpiReportFilterPreferanceList.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList.DateTimeLastModified = new Date();

      selectedYears.forEach(element => {
        years += element.name.toString() + ',';
      });
      years = years.substring(0, years.length - 1);
      kpiReportFilterPreferanceList.FilterValues = years;
      filterRequest.push(kpiReportFilterPreferanceList);
    }
    else {
      this.TimePeriodDropdown.forEach(x => { x.isChecked = false; });
      this.selectedYearsCnt = 0;
      this.isAllYearsSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedYear = '';
        }
      });
    }

    //Months
    var seectedMonths = this.TimePeriodMonthDropdown.filter(x => x.isChecked);
    if (seectedMonths != undefined && seectedMonths.length > 0) {
      var kpiReportFilterPreferanceList1 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList1.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList1.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList1.FilterName = "TimePeriod";
      kpiReportFilterPreferanceList1.FilterTimePeriod = 'Month';
      kpiReportFilterPreferanceList1.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList1.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList1.DateTimeLastModified = new Date();

      seectedMonths.forEach(element => {
        months += element.value + ',';
      });
      months = months.substring(0, months.length - 1);
      kpiReportFilterPreferanceList1.FilterValues = months.toString();
      filterRequest.push(kpiReportFilterPreferanceList1);
    }
    else {
      this.TimePeriodMonthDropdown.forEach(x => { x.isChecked = false; });
      this.selectedMonthsCnt = 0;
      this.isAllMonthsSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedMonth = '';
        }
      });
    }

    if (filterRequest.length == 0) {
      element.hide(event);
      this.DeleteKPIReportFilterPrefrences(this.selectedReportItem.kpiUserReportPrefrenceId, 'Filter');
      this.onRefreshReport(true, this.selectedReportItem.kpiUserReportPrefrenceId);
      return;
    }

    element.hide(event);
    const kpifilterresult = this.kpiReportService.AddKPIReportUserReportFilterPrefrences(filterRequest);

    this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, true);
    kpifilterresult.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          const index = this.kpiReportModel.findIndex(object => object.kpiUserReportPrefrenceId === this.selectedReportItem.kpiUserReportPrefrenceId);
          if (index === -1) {
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;
            reportModel.selectedAppointmentStatus = appointmentStatus;
            reportModel.selectedYear = years;
            reportModel.selectedMonth = months;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = schedulers;
            reportModel.selectedClassification = classifications;
            reportModel.selectedBranches = branches;
            reportModel.selectedFacility = facilities;
            reportModel.selectedTherapist = therapists;
            reportModel.selectedInsurance = insurances;
            reportModel.selectedAppointmentTypes = appointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = this.selectedReportItem.selectedXAxisList;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          else {
            this.kpiReportModel.splice(index, 1);
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;;
            reportModel.selectedAppointmentStatus = appointmentStatus;
            reportModel.selectedYear = years;
            reportModel.selectedMonth = months;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = schedulers;
            reportModel.selectedClassification = classifications;
            reportModel.selectedBranches = branches;
            reportModel.selectedFacility = facilities;
            reportModel.selectedTherapist = therapists;
            reportModel.selectedInsurance = insurances;
            reportModel.selectedAppointmentTypes = appointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = this.selectedReportItem.selectedXAxisList;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          if (this.kpiReportModel != null) {
            this.kpiReportModel.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }
          element.hide(event);
          this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
          this.showSuccessMessage('Filters Applied Successfully.');
          this.onRefreshReport(true, this.selectedReportItem.kpiUserReportPrefrenceId);
        } else {
          this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onFilterClose(event, element) {
    element.hide(event);
  }

  onTimePeriodSave(event, element) {
    var filterRequest = Array<UserReportFilterPrefrenceRequestModel>();
    let years = '';
    let months = '';

    //Years
    var selectedYears = this.TimePeriodDropdown.filter(x => x.isChecked);
    if (selectedYears != undefined && selectedYears.length > 0) {
      var kpiReportFilterPreferanceList = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList.FilterName = "TimePeriod";
      kpiReportFilterPreferanceList.FilterTimePeriod = 'Year';
      kpiReportFilterPreferanceList.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList.DateTimeLastModified = new Date();

      selectedYears.forEach(element => {
        years += element.name.toString() + ',';
      });
      years = years.substring(0, years.length - 1);
      kpiReportFilterPreferanceList.FilterValues = years;
      filterRequest.push(kpiReportFilterPreferanceList);
    }
    else {
      this.TimePeriodDropdown.forEach(x => { x.isChecked = false; });
      this.selectedYearsCnt = 0;
      this.isAllYearsSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedYear = '';
        }
      });
    }

    //Months
    var seectedMonths = this.TimePeriodMonthDropdown.filter(x => x.isChecked);
    if (seectedMonths != undefined && seectedMonths.length > 0) {
      var kpiReportFilterPreferanceList1 = new UserReportFilterPrefrenceRequestModel();
      kpiReportFilterPreferanceList1.KpiUserReportFilterPrefrenceId = 0;
      kpiReportFilterPreferanceList1.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportFilterPreferanceList1.FilterName = "TimePeriod";
      kpiReportFilterPreferanceList1.FilterTimePeriod = 'Month';
      kpiReportFilterPreferanceList1.modifiedByUserInfoId = 0;
      kpiReportFilterPreferanceList1.DateTimeCreated = new Date();
      kpiReportFilterPreferanceList1.DateTimeLastModified = new Date();

      seectedMonths.forEach(element => {
        months += element.value + ',';
      });
      months = months.substring(0, months.length - 1);
      kpiReportFilterPreferanceList1.FilterValues = months.toString();
      filterRequest.push(kpiReportFilterPreferanceList1);
    }
    else {
      this.TimePeriodMonthDropdown.forEach(x => { x.isChecked = false; });
      this.selectedMonthsCnt = 0;
      this.isAllMonthsSelect = false;
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedMonth = '';
        }
      });
    }

    // //Weeks
    // var selectedWeeks = this.TimePeriodWeekDropdown.filter(x => x.isChecked);
    // if (selectedWeeks != undefined && selectedWeeks.length > 0) {
    //   var kpiReportFilterPreferanceList2 = new UserReportFilterPrefrenceRequestModel();
    //   kpiReportFilterPreferanceList2.KpiUserReportFilterPrefrenceId = 0;
    //   kpiReportFilterPreferanceList2.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
    //   kpiReportFilterPreferanceList2.FilterName = "TimePeriod";
    //   kpiReportFilterPreferanceList2.FilterTimePeriod = 'Week';
    //   kpiReportFilterPreferanceList2.modifiedByUserInfoId = 0;
    //   kpiReportFilterPreferanceList2.DateTimeCreated = new Date();
    //   kpiReportFilterPreferanceList2.DateTimeLastModified = new Date();

    //   let weeks = '';
    //   selectedWeeks.forEach(element => {
    //     weeks += element.value.toString() + ',';
    //   });
    //   weeks = weeks.substring(0, weeks.length - 1);
    //   kpiReportFilterPreferanceList2.FilterValues = weeks;
    //   filterRequest.push(kpiReportFilterPreferanceList2);
    // }

    // //Days
    // var selectedDays = this.TimePeriodDayDropdown.filter(x => x.isChecked);
    // if (selectedDays != undefined && selectedDays.length > 0) {
    //   var kpiReportFilterPreferanceList3 = new UserReportFilterPrefrenceRequestModel();
    //   kpiReportFilterPreferanceList3.KpiUserReportFilterPrefrenceId = 0;
    //   kpiReportFilterPreferanceList3.KpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
    //   kpiReportFilterPreferanceList3.FilterName = "TimePeriod";
    //   kpiReportFilterPreferanceList3.FilterTimePeriod = 'Day';
    //   kpiReportFilterPreferanceList3.modifiedByUserInfoId = 0;
    //   kpiReportFilterPreferanceList3.DateTimeCreated = new Date();
    //   kpiReportFilterPreferanceList3.DateTimeLastModified = new Date();
    //   let days = ''
    //   selectedDays.forEach(element => {
    //     days += element.value.toString() + ',';
    //   });
    //   days = days.substring(0, days.length - 1);
    //   kpiReportFilterPreferanceList3.FilterValues = days;
    //   filterRequest.push(kpiReportFilterPreferanceList3);
    // }

    if (filterRequest.length == 0) {
      this.DeleteKPIReportFilterPrefrences(this.selectedReportItem.kpiUserReportPrefrenceId, 'TimePeriod');
      this.onRefreshReport(true, this.selectedReportItem.kpiUserReportPrefrenceId);
      element.hide(event);
      return;
    }

    const kpifilterresult = this.kpiReportService.AddKPIReportUserReportFilterPrefrences(filterRequest);

    this.loading = true;
    kpifilterresult.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {

          const index = this.kpiReportModel.findIndex(object => object.kpiUserReportPrefrenceId === this.selectedReportItem.kpiUserReportPrefrenceId);
          if (index === -1) {
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;
            reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
            reportModel.selectedYear = years;
            reportModel.selectedMonth = months;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
            reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
            reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
            reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
            reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
            reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
            reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = this.selectedReportItem.selectedXAxisList;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          else {
            this.kpiReportModel.splice(index, 1);
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;;
            reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
            reportModel.selectedYear = years;
            reportModel.selectedMonth = months;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
            reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
            reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
            reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
            reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
            reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
            reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = this.selectedReportItem.selectedXAxisList;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          if (this.kpiReportModel != null) {
            this.kpiReportModel.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }

          element.hide(event);
          this.showSuccessMessage('Time Period Applied Successfully.');
          this.onRefreshReport(true, this.selectedReportItem.kpiUserReportPrefrenceId);
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onTimePeriodClose(event, element) {
    element.hide(event);
  }

  onAppointmentStatusSave(event, element) {
    if (this.todo.length < 2) {
      this.showErrorMessage("Please select min 2 Parameter field(s) to generate report.");
      return;
    }

    if (this.AddNewReport) {
      this.SelectedVisualizationFieldTexts = 'Select';
      if (this.todo != undefined && this.todo.length > 0) {
        this.SelectedVisualizationFieldTexts = this.todo.map(x => x.name).join(', ');
      }
      element.hide(event);
      return;
    }

    var VisualizationRequest = Array<KPIReportVisualizationModel>();
    let index: number = 0;
    this.todo.forEach(item => {
      index += 1;
      var kpiReportVisualization = new KPIReportVisualizationModel();
      kpiReportVisualization.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
      kpiReportVisualization.kpiXAxisFieldName = item.value;
      kpiReportVisualization.kpiXAxisFieldOrder = index;
      VisualizationRequest.push(kpiReportVisualization);
    });
    this.selectedCategory = null;
    element.hide(event);
    var result = this.kpiReportService.AddEditKPIReportVisualization(VisualizationRequest);

    // var filterRequest = Array<UserReportFilterPrefrenceRequestModel>();
    // let appointmentStatus = '';

    // const kpifilterresult = this.kpiReportService.AddKPIReportUserReportFilterPrefrences(filterRequest);

    this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, true);
    result.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          const index = this.kpiReportModel.findIndex(object => object.kpiUserReportPrefrenceId === this.selectedReportItem.kpiUserReportPrefrenceId);
          if (index === -1) {
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;
            reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
            reportModel.selectedYear = this.selectedReportItem.selectedYear;
            reportModel.selectedMonth = this.selectedReportItem.selectedMonth;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
            reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
            reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
            reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
            reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
            reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
            reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = VisualizationRequest;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          else {
            this.kpiReportModel.splice(index, 1);
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
            reportModel.reportName = this.selectedReportItem.reportName;;
            reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
            reportModel.selectedYear = this.selectedReportItem.selectedYear;
            reportModel.selectedMonth = this.selectedReportItem.selectedMonth;
            reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
            reportModel.selectedDays = this.selectedReportItem.selectedDays;
            reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
            reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
            reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
            reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
            reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
            reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
            reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
            reportModel.orgReportName = this.selectedReportItem.orgReportName;
            reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
            reportModel.isDataTableView = this.selectedReportItem.isDataTableView;
            reportModel.kpiReportSearchRequestModel = this.selectedReportItem.kpiReportSearchRequestModel;
            reportModel.totalRecords = this.selectedReportItem.totalRecords;
            reportModel.totalPages = this.selectedReportItem.totalPages;
            reportModel.selectedpage = this.selectedReportItem.selectedpage;
            reportModel.pageSiges = this.selectedReportItem.pageSiges;
            reportModel.selectedXAxisList = VisualizationRequest;
            reportModel.columnType = this.selectedReportItem.columnType;
            reportModel.combinedReportAverageBy = this.selectedReportItem.combinedReportAverageBy;
            this.kpiReportModel.push(reportModel);
          }
          if (this.kpiReportModel != null) {
            this.kpiReportModel.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }

          element.hide(event);
          this.clearFilters();
          this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
          this.showSuccessMessage('Parameters Fields Saved Successfully.');
          this.onRefreshReport(true, this.selectedReportItem.kpiUserReportPrefrenceId);
        } else {
          this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.isReportLoading(this.selectedReportItem.kpiUserReportPrefrenceId, false);
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onAppointmentStatusClose(event, element) {
    this.selectedCategory = null;
    this.clearFilters();
    element.hide(event);
  }
  clearFilters() {
    this.FilteredYAxisCategoryDropdown = [...this.YAxisCategoryDropdown];
    this.FilterCombinedYAxisCategoryDropdown = [...this.CombinedYAxisCategoryDropdown];
  }

  sidebarCloseEvent() {
    this.CloseSidebar = !this.CloseSidebar;
    this.formBlock = !this.formBlock;
    this.columnTabsMenu = !this.columnTabsMenu;
    this.editScreen = !this.editScreen;
  }
  editSettingClickEvent() {
    this.CloseSidebar = !this.CloseSidebar;
    this.formBlock = !this.formBlock;
    this.columnTabsMenu = !this.columnTabsMenu;
    this.editScreen = !this.editScreen;

    // try {
    //   const errorField = this.renderer.selectRootElement('.kpi-report-layout__sidebar');
    //   errorField.scrollIntoView();
    //   } catch (err) {

    // }
  }

  async saveSettingClickEvent() {
    this.CloseSidebar = !this.CloseSidebar;
    this.formBlock = !this.formBlock;
    this.columnTabsMenu = !this.columnTabsMenu;
    this.editScreen = !this.editScreen;
    await this.updateKPIReportSortOrder();
    this.AddEditUserReportLayoutPrefrences();
    this.GetKPIUserPrefrencesReportList();
  }

  async updateKPIReportSortOrder() {
    const kpiReportSortOrderRequestModel: KPIReportSortOrderRequestModel[] = [];
    this.kpiReportModel.forEach(value => {
      let kpiReportSortOrder = new KPIReportSortOrderRequestModel();
      kpiReportSortOrder.kpiuserReportPreferenceId = value.kpiUserReportPrefrenceId;
      kpiReportSortOrder.kpireportSortOrder = value.reportSortOrder;
      kpiReportSortOrderRequestModel.push(kpiReportSortOrder);
    });

    await this.kpiReportService.UpdateKPIReportSortOrder(kpiReportSortOrderRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (result != null) {
          this.loading = false;
          this.showSuccessMessage('Layout Changes Saved Successfully.');
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  tab: any = 'tab2';
  tab1: any;
  tab2: any;
  tab3: any;
  Clicked: boolean;
  columnWidth: number = 49.3;
  columnClass: any = 'two-column';

  setColumnWidth(check) {
    this.selectedReportLayout = check;
    if (check == 1) {
      this.columnClass = 'one-column'
      //this.columnWidth = 100
      this.tab = 'tab1';
    } else if (check == 2) {
      this.columnClass = 'two-column'
      //this.columnWidth = 50
      this.tab = 'tab2';
    } else if (check == 3) {
      this.columnClass = 'three-column'
      //this.columnWidth = 33.333
      this.tab = 'tab3';
    }
    this.GetKPIUserPrefrencesReportList();
  }


  // setColumnOne(){
  //   this.columnWidth = 100
  // }
  // setColumntwo(){
  //   this.columnWidth = 49.2
  // }
  // setColumnthree(){
  //   this.columnWidth = 32.2
  // }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.movies, event.previousIndex, event.currentIndex);    
  // }

  clickEventAddNewReport(selectedKPIReportType: any) {
    this.AddNewReport = !this.AddNewReport;
    this.selectedKPIReportTypeToCreate = selectedKPIReportType;
    this.buildForm();
    if (selectedKPIReportType?.orgReportName == 'combined') {
      this.todo = [... this.defaultTodoForCombinedReport];
      this.FilterCombinedYAxisCategoryDropdown = this.FilterCombinedYAxisCategoryDropdown.filter(x => x.value != "AppDate")
    }
    else {
      this.todo = [... this.defaultTodo];
      this.FilterCombinedYAxisCategoryDropdown = [...this.CombinedYAxisCategoryDropdown];
    }



    this.SelectedVisualizationFieldTexts = 'Select';
    if (this.todo != undefined && this.todo.length > 0) {
      this.SelectedVisualizationFieldTexts = this.todo.map(x => x.name).join(', ');
    }
  }

  clickEventcloseForm() {
    this.resetFormValues();
    this.AddNewReport = !this.AddNewReport;
  }

  clickEventRenameReport() {
    this.kpiReportModel.forEach(x => {
      x.isRenameClicked = false;
    })
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
        x.isRenameClicked = true;
        this.reportName = x.reportName;
      }
    })
  }

  validateReportName(reportname: string) {
    const pattern = /[!@&#()%\-$=<>?\\\/]/;
    if (this.reportName != 'undefined') {
      return !pattern.test(reportname);
    }
    return true;
  }
  SaveRenameReport() {

    this.reportName = this.reportName.trimLeft();
    this.reportName = this.reportName.trimRight();
    if ((this.reportName == '') || !this.validateReportName(this.reportName)) {
      if (this.reportName == '')
        this.showWarningMessage('Report name should not be empty.');
      else
        this.showWarningMessage('Report name contains invalid character.');

      //this.CancelledRenameReport();
      return;
    }
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
        x.isRenameClicked = false;
      }
    })
    this.UpdateReportName(this.selectedReportItem.kpiUserReportPrefrenceId)
  }
  CancelledRenameReport() {
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
        x.isRenameClicked = false;
        this.reportName = '';
      }
    })
  }

  sidebarAddNewReport(selectedKPIReportType: any) {
    //alert(1)
    //this.NoreportsAvailable = !this.NoreportsAvailable;
    this.selectedKPIReportTypeToCreate = selectedKPIReportType;
    this.formBlock = true;
    this.AddNewReport = true;


    if (selectedKPIReportType?.orgReportName == 'combined') {
      this.todo = [... this.defaultTodoForCombinedReport];
      this.FilterCombinedYAxisCategoryDropdown = this.FilterCombinedYAxisCategoryDropdown.filter(x => x.value != "AppDate")
    }
    else {
      this.todo = [... this.defaultTodo];
      this.FilterCombinedYAxisCategoryDropdown = [...this.CombinedYAxisCategoryDropdown];
    }
    this.SelectedVisualizationFieldTexts = 'Select';
    if (this.todo != undefined && this.todo.length > 0) {
      this.SelectedVisualizationFieldTexts = this.todo.map(x => x.name).join(', ');
    }

    // var highchart = document.querySelector(".xyz-report-block__chart svg");
    // highchart.setAttribute("viewBox", "0 -60 700 460");
  }


  /***** Minimize and Maximize Window ******/
  MaximizeWindow(item: any) {
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == item.kpiUserReportPrefrenceId)
        x.isMaxMinimizeReport = !x.isMaxMinimizeReport;
    });

    this.MinimizeMaximize = !this.MinimizeMaximize
    this.GetKPIUserPrefrencesReportList();
    // if (!this.MinimizeMaximize) {
    //   this.GetKPIUserPrefrencesReportList();
    //   this.loading = false;
    // }
  }

  /***** Add & remove Column  ******/
  createNewReport() {
    //this.items.push(this.items.length + 1);
    this.isSubmitReport = true;
    this.hasParameterError = false;
    this.hasMinParameterError = false;
    this.hasInvalidChar = false;
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    var kpiRequestModel = this.getValuesFromForm();
    kpiRequestModel.ReportName = kpiRequestModel.ReportName.trimLeft();
    kpiRequestModel.ReportName = kpiRequestModel.ReportName.trimRight();
    if (!this.validateReportName(kpiRequestModel.ReportName)) {
      //this.showWarningMessage('Report name contains invalid character.');
      this.markFormAsTouched();
      this.hasInvalidChar = true;
      return;
    }

    if (kpiRequestModel.SelectedXAxisList.length < 2) {
      if (kpiRequestModel.SelectedXAxisList.length == 0)
        this.hasParameterError = true;
      else
        this.hasMinParameterError = true;
      this.markFormAsTouched();
      return;
    }
    this.hasParameterError = false;
    this.hasInvalidChar = false;
    this.hasMinParameterError = false;
    const kpiresult = this.kpiReportService.AddKPIReport(kpiRequestModel);

    this.loading = true;
    kpiresult.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.showSuccessMessage('New Report Added Successfully');
          this.AddNewReport = !this.AddNewReport;
          this.selectedKPIReportTypeToCreate = new KPIReportResponseModel();
          //this.form.reset(this.form.value);
          this.resetFormValues();
          this.GetKPIUserPrefrencesReportList();
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  removeReport() {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to delete this report ?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.DeleteKPIReport(this.selectedReportItem.kpiUserReportPrefrenceId);
      var removeReport = this.kpiReportModel.findIndex(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId)
      if (removeReport != -1)
        this.kpiReportModel.splice(removeReport, 1);

      if (this.kpiReportModel?.length > 0) {
        this.NoreportsAvailable = false;
      }
      else {
        this.NoreportsAvailable = true;
      }
    });
  }

  /***** Drag & Drop ******/
  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };


    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.kpiReportModel, dragIndex, dropIndex);

      // Update the reportSortOrder property based on the new order
      this.kpiReportModel.forEach((item, index) => {
        item.reportSortOrder = index; // +1 to make it 1-based
      });
    }
  }

  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  private getValuesFromForm(): KPIAddReportRequestModel {
    let reportSortOrder = 1;
    if (this.kpiReportModel?.length > 0) {
      const highestReportOrderValue = this.kpiReportModel.reduce((prev, current) => {
        return (prev.reportSortOrder > current.reportSortOrder) ? prev : current;
      });
      if (highestReportOrderValue != undefined) {
        reportSortOrder = Number(highestReportOrderValue.reportSortOrder) + 1;
      }
    }

    const form = this.form;
    var kpiaddRequestModel = new KPIAddReportRequestModel();
    kpiaddRequestModel.ReportName = form.get(this.formFields.reportName).value;
    kpiaddRequestModel.UserInfoID = this.loggedInUserId;
    kpiaddRequestModel.KPIReportId = this.selectedKPIReportTypeToCreate.kpiReportId;
    kpiaddRequestModel.LoggedInUserId = this.loggedInUserId;
    kpiaddRequestModel.ReportSortOrder = reportSortOrder;

    if (this.selectedKPIReportTypeToCreate.orgReportName == 'column') {
      var Status = form.get(this.formFields.chartType).value;
      kpiaddRequestModel.ColumnType = Status;
    }
    else if (this.selectedKPIReportTypeToCreate.orgReportName == 'combined') {
      kpiaddRequestModel.CombinedReportAverageBy = form.get(this.formFields.averageBy).value;
    }
    var VisualizationRequest = Array<KPIReportVisualizationModel>();
    let index: number = 0;
    this.todo.forEach(item => {
      index += 1;
      var kpiReportVisualization = new KPIReportVisualizationModel();
      kpiReportVisualization.kpiUserReportPrefrenceId = 0;
      kpiReportVisualization.kpiXAxisFieldName = item.value;
      kpiReportVisualization.kpiXAxisFieldOrder = index;
      VisualizationRequest.push(kpiReportVisualization);
    });
    kpiaddRequestModel.SelectedXAxisList = VisualizationRequest;

    let appointmentTypes = '';
    let appointmentStatus = '';
    var filterRequest = Array<UserReportFilterPrefrenceRequestModel>();


    // Appointment Status
    var kpiReportFilterPreferanceList2 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList2.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList2.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList2.FilterName = 'AppointmentStatus';
    kpiReportFilterPreferanceList2.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList2.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList2.DateTimeCreated = new Date();

    this.AppointmentStatusDropdown.forEach(element => {
      appointmentStatus += element.value.toString() + ',';
    });
    appointmentStatus = appointmentStatus.substring(0, appointmentStatus.length - 1);
    kpiReportFilterPreferanceList2.FilterValues = appointmentStatus;
    filterRequest.push(kpiReportFilterPreferanceList2);

    // Appointment Type
    var kpiReportFilterPreferanceList3 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList3.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList3.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList3.FilterName = 'KPIAppointmentTypes';
    kpiReportFilterPreferanceList3.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList3.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList3.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(element => {
      appointmentTypes += element.appointmentTypeId + ',';
    });
    appointmentTypes = appointmentTypes.substring(0, appointmentTypes.length - 1);
    kpiReportFilterPreferanceList3.FilterValues = appointmentTypes;
    filterRequest.push(kpiReportFilterPreferanceList3);

    // Insurance
    let scheduler = '';
    var kpiReportFilterPreferanceList4 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList4.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList4.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList4.FilterName = 'KPIInsurance';
    kpiReportFilterPreferanceList4.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList4.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList4.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiInsurances.forEach(element => {
      scheduler += element.crmPolicyHolderName + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList4.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList4);

    // Therapist
    scheduler = '';
    var kpiReportFilterPreferanceList5 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList5.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList5.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList5.FilterName = 'KPITherapist';
    kpiReportFilterPreferanceList5.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList5.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList5.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiTherapist.forEach(element => {
      scheduler += element.therapistId + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList5.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList5);

    // Facilities
    scheduler = '';
    var kpiReportFilterPreferanceList6 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList6.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList6.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList6.FilterName = 'KPIFacilities';
    kpiReportFilterPreferanceList6.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList6.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList6.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiFacilities.forEach(element => {
      scheduler += element.facilityId + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList6.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList6);

    // Branches
    scheduler = '';
    var kpiReportFilterPreferanceList7 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList7.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList7.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList7.FilterName = 'KPIBranches';
    kpiReportFilterPreferanceList7.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList7.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList7.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiBranches.forEach(element => {
      scheduler += element.crmBranchId + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList7.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList7);

    //Classification
    scheduler = '';
    var kpiReportFilterPreferanceList8 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList8.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList8.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList8.FilterName = 'KPIClassifications';
    kpiReportFilterPreferanceList8.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList8.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList8.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiClassifications.forEach(element => {
      scheduler += element.crmClassificationTypeId + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList8.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList8);

    //Schedulers
    scheduler = '';
    var kpiReportFilterPreferanceList9 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList9.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList9.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList9.FilterName = 'KPISchedulers';
    kpiReportFilterPreferanceList9.FilterTimePeriod = '0';
    kpiReportFilterPreferanceList9.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList9.DateTimeCreated = new Date();

    this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(element => {
      scheduler += element.userInfoId + ',';
    });
    scheduler = scheduler.substring(0, scheduler.length - 1);
    kpiReportFilterPreferanceList9.FilterValues = scheduler;
    filterRequest.push(kpiReportFilterPreferanceList9);


    //Month
    let months = '';
    var kpiReportFilterPreferanceList10 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList10.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList10.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList10.FilterName = "TimePeriod";
    kpiReportFilterPreferanceList10.FilterTimePeriod = 'Month';
    kpiReportFilterPreferanceList10.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList10.DateTimeCreated = new Date();
    kpiReportFilterPreferanceList10.DateTimeLastModified = new Date();

    this.TimePeriodMonthDropdown.forEach(element => {
      months += element.value + ',';
    });
    months = months.substring(0, months.length - 1);
    kpiReportFilterPreferanceList10.FilterValues = months.toString();
    filterRequest.push(kpiReportFilterPreferanceList10);

    // // let status = '';
    // // form.get(this.formFields.yaxisCategory).value.forEach(element => {
    // //   status += element.value + ',';
    // // });
    // // status = status.substring(0, status.length - 1);
    // let yaxisCategory = form.get(this.formFields.yaxisCategory).value;
    // kpiReportFilterPreferanceList.FilterValues = yaxisCategory.value;
    // filterRequest.push(kpiReportFilterPreferanceList);

    var kpiReportFilterPreferanceList1 = new UserReportFilterPrefrenceRequestModel();
    kpiReportFilterPreferanceList1.KpiUserReportFilterPrefrenceId = 0;
    kpiReportFilterPreferanceList1.KpiUserReportPrefrenceId = 0;
    kpiReportFilterPreferanceList1.FilterName = "TimePeriod";
    kpiReportFilterPreferanceList1.FilterTimePeriod = 'Year';
    kpiReportFilterPreferanceList1.modifiedByUserInfoId = 0;
    kpiReportFilterPreferanceList1.DateTimeCreated = new Date();
    kpiReportFilterPreferanceList1.DateTimeLastModified = new Date();
    let time = ''
    form.get(this.formFields.timePeriod).value.forEach(element => {
      time += element.name + ',';
    });
    time = time.substring(0, time.length - 1);
    kpiReportFilterPreferanceList1.FilterValues = time;
    filterRequest.push(kpiReportFilterPreferanceList1);
    kpiaddRequestModel.UserReportFilterPrefrencesList = filterRequest;
    return kpiaddRequestModel;
  }

  async GetKPIUserPrefrencesReportList() {
    this.loading = true;
    await this.kpiReportService.getKPIUserPrefrencesReportList(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          var result1 = result.responseModel;
          if (result1 != null) {
            result1.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }
          // if (this.kpiReportModel != undefined) {
          //   const index = this.kpiReportModel.findIndex(object => object.kpiUserReportPrefrenceId === this.selectedReportItem.kpiUserReportPrefrenceId);
          //   if (index === -1) {
          //     let reportModel = new KPIReportModel();
          //     reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
          //     reportModel.reportName = this.selectedReportItem.reportName;
          //     reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
          //     reportModel.selectedYear = this.selectedReportItem.selectedYear;
          //     reportModel.selectedMonth = this.selectedReportItem.selectedMonth;
          //     reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
          //     reportModel.selectedDays = this.selectedReportItem.selectedDays;
          //     reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
          //     reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
          //     reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
          //     reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
          //     reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
          //     reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
          //     reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
          //     reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
          //     this.kpiReportModel.push(reportModel);
          //   }
          //   else {
          //     this.kpiReportModel.splice(index, 1);
          //     let reportModel = new KPIReportModel();
          //     reportModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;
          //     reportModel.reportName = this.selectedReportItem.reportName;;
          //     reportModel.selectedAppointmentStatus = this.selectedReportItem.selectedAppointmentStatus;
          //     reportModel.selectedYear = this.selectedReportItem.selectedYear;
          //     reportModel.selectedMonth = this.selectedReportItem.selectedMonth;
          //     reportModel.selectedWeeks = this.selectedReportItem.selectedWeeks;
          //     reportModel.selectedDays = this.selectedReportItem.selectedDays;
          //     reportModel.selectedSchedulers = this.selectedReportItem.selectedSchedulers;
          //     reportModel.selectedClassification = this.selectedReportItem.selectedClassification;
          //     reportModel.selectedBranches = this.selectedReportItem.selectedBranches;
          //     reportModel.selectedFacility = this.selectedReportItem.selectedFacility;
          //     reportModel.selectedTherapist = this.selectedReportItem.selectedTherapist;
          //     reportModel.selectedInsurance = this.selectedReportItem.selectedInsurance;
          //     reportModel.selectedAppointmentTypes = this.selectedReportItem.selectedAppointmentTypes;
          //     reportModel.reportSortOrder = this.selectedReportItem.reportSortOrder;
          //     this.kpiReportModel.push(reportModel);
          //   }
          // }
          //else {
          let kpiReportSearchRequestModel = new SearchRequestModel();
          kpiReportSearchRequestModel.appUserInfoId = this.loggedInUserId;
          kpiReportSearchRequestModel.FirstRecordIndex = 0;
          kpiReportSearchRequestModel.CurrentPageIndex = 0;
          kpiReportSearchRequestModel.PageSize = +this.pageSizes[0];

          this.kpiReportModel = new Array<KPIReportModel>();
          result1.forEach(x => {
            let reportModel = new KPIReportModel();
            reportModel.kpiUserReportPrefrenceId = x.kpiuserReportPrefrenceID;
            reportModel.reportName = x.reportName;
            reportModel.orgReportName = x.orgReportName;
            reportModel.reportSortOrder = Number(x.reportSortOrder);
            reportModel.columnType = x.columnType;
            reportModel.combinedReportAverageBy = x.combinedReportAverageBy == null ? this.defaultCombinedReportAverageBy : x.combinedReportAverageBy;
            reportModel.selectedXAxisList = x.kpiReportVisualizationList.sort((a, b) => Number(a.kpiXAxisFieldOrder) - Number(b.kpiXAxisFieldOrder));
            x.userReportFilterPrefrencesList.forEach(y => {
              if (y.filterName == "AppointmentStatus") {
                reportModel.selectedAppointmentStatus = y.filterValues
                if (reportModel.selectedAppointmentStatus.length > 0) {
                  reportModel.selectedAppointmentStatus.split(',').forEach(value => {
                    this.AppointmentStatusDropdown?.forEach(x => {
                      if (x.value.toLowerCase() == value.toLowerCase()) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "TimePeriod") {
                if (y.filterTimePeriod == "Year") {
                  reportModel.selectedYear = y.filterValues;
                  if (reportModel.selectedYear.length > 0) {
                    reportModel.selectedYear.split(',').forEach(value => {
                      this.TimePeriodDropdown?.forEach(x => {
                        if (x.name.toLowerCase() == value.toLowerCase()) {
                          x.isChecked = true;
                        }
                      });
                    });
                  }
                }
                else if (y.filterTimePeriod == "Month") {
                  reportModel.selectedMonth = y.filterValues;
                  if (reportModel.selectedMonth.length > 0) {
                    reportModel.selectedMonth.split(',').forEach(value => {
                      this.TimePeriodMonthDropdown?.forEach(x => {
                        if (x.value == Number(value)) {
                          x.isChecked = true;
                        }
                      });
                    });
                  }
                }
                // if (y.filterTimePeriod == "Week") {
                //   reportModel.selectedWeeks = y.filterValues;
                //   reportModel.selectedWeeks.split(',').forEach(value => {
                //     this.TimePeriodWeekDropdown?.forEach(x => {
                //       if (x.value == Number(value)) {
                //         x.isChecked = true;
                //       }
                //     });
                //   });
                // }
                // if (y.filterTimePeriod == "Day") {
                //   reportModel.selectedDays = y.filterValues;
                //   reportModel.selectedDays.split(',').forEach(value => {
                //     this.TimePeriodDayDropdown?.forEach(x => {
                //       if (x.value == Number(value)) {
                //         x.isChecked = true;
                //       }
                //     });
                //   });
                // }
              }
              if (y.filterName == "KPISchedulers") {
                reportModel.selectedSchedulers = y.filterValues;
                if (reportModel.selectedSchedulers.length > 0) {
                  reportModel.selectedSchedulers.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiSchedulers.forEach(x => {
                      if (x.userInfoId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPIClassifications") {
                reportModel.selectedClassification = y.filterValues
                if (reportModel.selectedClassification.length > 0) {
                  reportModel.selectedClassification.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiClassifications.forEach(x => {
                      if (x.crmClassificationTypeId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPIBranches") {
                reportModel.selectedBranches = y.filterValues
                if (reportModel.selectedBranches.length > 0) {
                  reportModel.selectedBranches.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiBranches.forEach(x => {
                      if (x.crmBranchId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPIFacilities") {
                reportModel.selectedFacility = y.filterValues
                if (reportModel.selectedFacility.length > 0) {
                  reportModel.selectedFacility.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiFacilities.forEach(x => {
                      if (x.facilityId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPITherapist") {
                reportModel.selectedTherapist = y.filterValues
                if (reportModel.selectedTherapist.length > 0) {
                  reportModel.selectedTherapist.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiTherapist.forEach(x => {
                      if (x.therapistId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPIInsurance") {
                reportModel.selectedInsurance = y.filterValues
                if (reportModel.selectedInsurance.length > 0) {
                  reportModel.selectedInsurance.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiInsurances.forEach(x => {
                      if (x.crmPolicyHolderName == value) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
              if (y.filterName == "KPIAppointmentTypes") {
                reportModel.selectedAppointmentTypes = y.filterValues
                if (reportModel.selectedAppointmentTypes.length > 0) {
                  reportModel.selectedAppointmentTypes.split(',').forEach(value => {
                    this.kpiReportFiltersResponseModel?.kpiAppointmentTypes.forEach(x => {
                      if (x.appointmentTypeId == Number(value)) {
                        x.isChecked = true;
                      }
                    });
                  });
                }
              }
            });

            reportModel.pageSiges = this.pageSiges;
            reportModel.selectedpage = this.selectedpage;
            reportModel.kpiReportSearchRequestModel = kpiReportSearchRequestModel;
            reportModel.totalRecords = 0;
            reportModel.totalPages = 1;

            this.kpiReportModel.push(reportModel);
            //this.GetKPIReportDataTable(x.kpiuserReportPrefrenceID);
            this.GetKPIReportDetails(reportModel.kpiUserReportPrefrenceId);
          })
          //}
          if (result1?.length > 0) {
            this.NoreportsAvailable = false;
          }
          else {
            this.NoreportsAvailable = true;
          }
        }
      }), (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      };
  }

  GetKPIReportDetails(kpiUserReportPrefrenceId: number) {

    this.searchRequestModel = new KPIReportSearchRequestModel();
    if (this.kpiReportModel == undefined) {
      return
    }

    let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);

    this.searchRequestModel.years = reportFilters.selectedYear != undefined ? reportFilters.selectedYear : null;
    this.searchRequestModel.months = reportFilters.selectedMonth != undefined ? reportFilters.selectedMonth : null;
    this.searchRequestModel.weeks = reportFilters.selectedDays != undefined ? reportFilters.selectedDays : null;
    this.searchRequestModel.appointments = reportFilters.selectedAppointmentStatus != undefined ? reportFilters.selectedAppointmentStatus : null;;
    this.searchRequestModel.classification = reportFilters.selectedClassification != undefined ? reportFilters.selectedClassification : null;;
    this.searchRequestModel.scheduler = reportFilters.selectedSchedulers != undefined ? reportFilters.selectedSchedulers : null;;
    this.searchRequestModel.branch = reportFilters.selectedBranches != undefined ? reportFilters.selectedBranches : null;;
    this.searchRequestModel.facilities = reportFilters.selectedFacility != undefined ? reportFilters.selectedFacility : null;;
    this.searchRequestModel.therapist = reportFilters.selectedTherapist != undefined ? reportFilters.selectedTherapist : null;;
    this.searchRequestModel.insurance = reportFilters.selectedInsurance != undefined ? reportFilters.selectedInsurance : null;;
    this.searchRequestModel.typeofappointments = reportFilters.selectedAppointmentTypes != undefined ? reportFilters.selectedAppointmentTypes : null;;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.xAxisList = reportFilters.selectedXAxisList;
    this.searchRequestModel.columnType = reportFilters.columnType;
    this.searchRequestModel.combinedReportAverageBy = reportFilters.combinedReportAverageBy;
    this.searchRequestModel.kpiUserReportPrefrenceId = kpiUserReportPrefrenceId;

    //this.loading = true;
    this.isReportLoading(kpiUserReportPrefrenceId, true);
    if (reportFilters.orgReportName == 'line') {
      this.kpiReportService.GetKPIReportDetails(this.searchRequestModel)
        .subscribe((result) => {
          if (result != null) {
            //this.loading = false;
            this.isReportLoading(kpiUserReportPrefrenceId, false);
            this.yeardata = result.responseModel;

            if (this.MinimizeMaximize) {
              this.kpiReportModel.forEach(x => {
                if (x.kpiUserReportPrefrenceId == this.selectedReportItem?.kpiUserReportPrefrenceId)
                  x.isMaxMinimizeReport = this.MinimizeMaximize;
              });
            }

            this.BindMultilevelChart(this.yeardata, kpiUserReportPrefrenceId, reportFilters.orgReportName);
          }
        });
    }
    else if (reportFilters.orgReportName == 'column') {
      this.kpiReportService.GetKPIReportDetails(this.searchRequestModel)
        .subscribe((result) => {
          if (result != null) {
            //this.loading = false;
            this.isReportLoading(kpiUserReportPrefrenceId, false);
            this.yeardata = result.responseModel;

            if (this.MinimizeMaximize) {
              this.kpiReportModel.forEach(x => {
                if (x.kpiUserReportPrefrenceId == this.selectedReportItem?.kpiUserReportPrefrenceId)
                  x.isMaxMinimizeReport = this.MinimizeMaximize;
              });
            }

            this.BindColumnChart(this.yeardata, kpiUserReportPrefrenceId, reportFilters.orgReportName, reportFilters.columnType);
          }
        });
    }
    else {
      this.kpiReportService.GetKPIMovingReportDetails(this.searchRequestModel)
        .subscribe((result) => {
          if (result != null) {
            //this.loading = false;
            this.isReportLoading(kpiUserReportPrefrenceId, false);
            this.movingReportData = result.responseModel;

            if (this.MinimizeMaximize) {
              this.kpiReportModel.forEach(x => {
                if (x.kpiUserReportPrefrenceId == this.selectedReportItem?.kpiUserReportPrefrenceId)
                  x.isMaxMinimizeReport = this.MinimizeMaximize;
              });
            }

            this.BindMovingReportChart(this.movingReportData, kpiUserReportPrefrenceId);
          }
        });
    }
  }

  isReportLoading(kpiUserReportPrefrenceId: number, loading: boolean) {
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId)
        x.isLoading = loading;
    });
  }

  BindMultilevelChart(lst: YearModel[], kpiUserReportPrefrenceId: number, chartType: string) {
    const series: any[] = [];
    const drilldownSeries: any[] = [];

    let yaxisRange = lst
      .map(item => item.status)
      .filter((value, index, self) => self.indexOf(value) === index);

    let xaxisRange = lst
      .map(item => item.year)
      .filter((value, index, self) => self.indexOf(value) === index);

    yaxisRange.forEach(value => {
      const data: any[] = [];

      xaxisRange.forEach(value1 => {
        const filteredData = lst.filter(x => x.status == value && x.year == value1);
        const tempData = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
        if (filteredData[0]?.month != undefined) {
          data.push({
            name: value1,
            y: tempData,
            drilldown: value + '_' + value1
          });
        }
        else {
          data.push({
            name: value1,
            y: tempData,
          });
        }

        const uniqueMonthsSet = new Set();
        const tempdrilldownData = [];

        filteredData[0]?.month?.forEach(monthData => {
          const month = monthData.month;

          if (!uniqueMonthsSet.has(month)) {
            uniqueMonthsSet.add(month);

            if (monthData.level3 != undefined) {
              tempdrilldownData.push({
                name: month,
                y: monthData.count, //filteredData[0]?.month.filter(x => x.month == month).length,
                //id: value + '_' + value1 + '_' + month,
                drilldown: value + '_' + value1 + '_' + month
              });
            }
            else {
              tempdrilldownData.push({
                name: month,
                y: monthData.count,
              });
            }

            var drillData = monthData.level3; //filteredData[0].appointmentDetails.filter(x => x.status == month && x.year == value1);
            if (drillData != null) {
              const tempdrilldownData1 = [];
              drillData.forEach(value3 => {
                tempdrilldownData1.push({
                  name: value3.month,
                  y: value3.count //drillData.filter(x => x.month == value3.month).length,
                  //drilldown: value + '_' + value1 + '_' + month
                });
              });

              drilldownSeries.push({
                name: value + ' / ' + value1 + ' / ' + month,
                id: value + '_' + value1 + '_' + month,
                data: tempdrilldownData1
              });
            }
          }
        });
        let sortData = this.sortMonthData(tempdrilldownData);
        drilldownSeries.push({
          name: value + ' / ' + value1,
          id: value + '_' + value1,
          data: tempdrilldownData

        });
      });

      series.push({
        name: value,
        data: data
      });
    });

    const options: any = {
      colors: [
        '#FF6384',
        '#36A2EB',
        '#4BF266',
        '#9966FF',
        '#4BC0C0',
        '#FF9F40',
        '#C9CBCF',
        '#A38331'
      ],
      chart: {
        type: chartType,
        zoomType: 'x',
        panning: true,
        panKey: 'shift'
      },
      title: {
        text: ''
        // text: 'Appointments'
      },
      xAxis: {
        autoRotation: [-45, -90],
        labels: {
          style: {
            // "textOverflow": "none",
            // "white-space": "nowrap",
            //"overflow": "false",
            fontSize: '12px',
            fontWeight: '500',
            'fontFamily': '"Work Sans", sans-serif',
          }
        },
        type: 'category',
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Appointment(s)'
        }
      },

      tooltip: {
        format: '<b>{point.key}</b><br/>{series.name}: {point.y}'
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0.1,
          //fontSize: 14
        },

        series: {
          dataLabels: {
            enabled: true,
            y: -1
          }
        }

      },
      series: series,
      exporting: {
        sourceWidth: 1200,
        sourceHeight: 600,
        enabled: false
      },
      drilldown: {
        activeAxisLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'italic'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'italic'
        },
        series: drilldownSeries
      },
      credits: {
        enabled: false
      },
    };

    Highcharts.chart('mchart-line' + kpiUserReportPrefrenceId, options);
  }

  sortMonthData(monthData: { name: string, y: number }[]): { name: string, y: number }[] {
    const monthOrder = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    return monthData.sort((a, b) => {
      return monthOrder.indexOf(a.name.split('-')[0]) - monthOrder.indexOf(b.name.split('-')[0]);
    });
  }

  BindColumnChart(lst: YearModel[], kpiUserReportPrefrenceId: number, chartType: string, ChartView: any) {
    const series: any[] = [];
    const drilldownSeries: any[] = [];

    let yaxisRange = lst
      .map(item => item.year)
      .filter((value, index, self) => self.indexOf(value) === index);

    let xaxisRange = lst
      .map(item => item.status)
      .filter((value, index, self) => self.indexOf(value) === index);

    yaxisRange.forEach(value => {
      const data: any[] = [];

      xaxisRange.forEach(value1 => {
        const filteredData = lst.filter(x => x.year == value && x.status == value1);
        const tempData = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
        if (filteredData[0]?.month != undefined) {
          data.push({
            name: value1,
            y: tempData,
            drilldown: value + '_' + value1
          });
        }
        else {
          data.push({
            name: value1,
            y: tempData,
          });
        }

        const uniqueMonthsSet = new Set();
        const tempdrilldownData = [];

        filteredData[0]?.month?.forEach(monthData => {
          const month = monthData.month;

          if (!uniqueMonthsSet.has(month)) {
            uniqueMonthsSet.add(month);

            if (monthData.level3 != undefined) {
              tempdrilldownData.push({
                name: month,
                y: monthData.count,
                drilldown: value + '_' + value1 + '_' + month
              });
            }
            else {
              tempdrilldownData.push({
                name: month,
                y: monthData.count,
              });
            }

            var drillData = monthData.level3;
            if (drillData != null) {
              const tempdrilldownData1 = [];
              drillData.forEach(value3 => {
                tempdrilldownData1.push({
                  name: value3.month,
                  y: value3.count,
                });
              });

              drilldownSeries.push({
                name: value + ' / ' + value1 + ' / ' + month,
                id: value + '_' + value1 + '_' + month,
                data: tempdrilldownData1
              });
            }
          }
        });
        drilldownSeries.push({
          name: value + ' / ' + value1,
          id: value + '_' + value1,
          data: tempdrilldownData
        });
      });

      series.push({
        name: value,
        data: data
      });
    });

    if (ChartView == 'Stack') {
      const options: any = {
        colors: [
          '#FF6384',
          '#36A2EB',
          '#4BF266',
          '#9966FF',
          '#4BC0C0',
          '#FF9F40',
          '#C9CBCF',
          '#A38331'
        ],
        chart: {
          type: chartType,
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
          // text: 'Appointments'
        },
        xAxis: {
          labels: {
            autoRotation: [-45, -90],
            style: {
              // "textOverflow": "none",
              // "white-space": "nowrap",
              //"overflow": "false",
              fontSize: '12px',
              fontWeight: '500',
              'fontFamily': '"Work Sans", sans-serif',
            }
          },
          type: 'category',
          min: 0
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total Appointment(s)'
          },
          stackLabels: {
            enabled: true
          }
        },

        tooltip: {
          format: '<b>{point.key}</b><br/>{series.name}: {point.y}',
          pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            },
            pointPadding: 0.2,
            borderWidth: 0.1,
            //fontSize: 14
          },

          series: {
            dataLabels: {
              enabled: true,
              y: -1
            }
          }

        },
        series: series,
        exporting: {
          sourceWidth: 1200,
          sourceHeight: 600,
          enabled: false
        },
        drilldown: {
          activeAxisLabelStyle: {
            textDecoration: 'none',
            fontStyle: 'italic'
          },
          activeDataLabelStyle: {
            textDecoration: 'none',
            fontStyle: 'italic'
          },
          series: drilldownSeries
        },
        credits: {
          enabled: false
        },

      };

      Highcharts.chart('mchart-line' + kpiUserReportPrefrenceId, options);
    }
    else {
      const options: any = {
        colors: [
          '#FF6384',
          '#36A2EB',
          '#4BF266',
          '#9966FF',
          '#4BC0C0',
          '#FF9F40',
          '#C9CBCF',
          '#A38331'
        ],
        chart: {
          type: chartType,
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
          // text: 'Appointments'
        },
        xAxis: {
          autoRotation: [-45, -90],
          labels: {
            style: {
              // "textOverflow": "none",
              // "white-space": "nowrap",
              //"overflow": "false",
              fontSize: '12px',
              fontWeight: '500',
              'fontFamily': '"Work Sans", sans-serif',
            }
          },
          type: 'category',
          min: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total Appointment(s)'
          }
        },

        tooltip: {
          format: '<b>{point.key}</b><br/>{series.name}: {point.y}'
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0.1,
            //fontSize: 14
          },

          series: {
            dataLabels: {
              enabled: true,
              y: -1
            }
          }

        },
        series: series,
        exporting: {
          sourceWidth: 1200,
          sourceHeight: 600,
          enabled: false
        },
        drilldown: {
          series: drilldownSeries,
          activeAxisLabelStyle: {
            textDecoration: 'none',
            fontStyle: 'italic'
          },
          activeDataLabelStyle: {
            textDecoration: 'none',
            fontStyle: 'italic'
          },
        },
        credits: {
          enabled: false
        },

      };

      Highcharts.chart('mchart-line' + kpiUserReportPrefrenceId, options);
    }
  }

  BindMovingReportChart(lst: CombinedChartModel[], kpiUserReportPrefrenceId: number) {
    const series: any[] = [];

    let yaxisRange = lst
      .map(item => item.status)
      .filter((value, index, self) => self.indexOf(value) === index);

    let xaxisRange = lst
      .map(item => item.appDate)
      .filter((value, index, self) => self.indexOf(value) === index);

    yaxisRange.forEach(value => {
      const data: any[] = [];

      xaxisRange.forEach(value1 => {
        const filteredData = lst.filter(x => x.status == value && x.appDate == value1);
        const tempData = filteredData.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
        //const tempData1 = filteredData[0].moving;
        data.push({
          type: 'column',
          name: moment(value1).format('MM-DD-YYYY'),
          y: tempData,
          //drilldown: value + '_' + value1
        });
      });

      series.push({
        type: 'column',
        name: value,
        data: data
      });
    });

    //const data1: any[] = [];
    // var tempData: any;
    // xaxisRange.forEach(value1 => {
    //   const filteredData = lst.filter(x => x.appDate == value1);
    //   tempData = filteredData.map(x => x.moving);
    //   //const tempData1 = filteredData[0].moving;
    //   data1.push({
    //     type: 'line',
    //     step: 'center',
    //     name: value1,
    //     data: tempData,
    //     marker: {
    //       lineWidth: 2,
    //       lineColor: Highcharts.getOptions().colors[3],
    //       fillColor: 'white'
    //     }
    //   });
    // });

    const dateToSumCountMap = lst.reduce((map, x) => {
      const { appDate, moving } = x;
      if (!map.has(appDate)) {
        map.set(appDate, { sum: 0, count: 0 });
      }
      const entry = map.get(appDate);
      entry.sum += moving;
      entry.count++;
      return map;
    }, new Map());

    const averages = Array.from(dateToSumCountMap.entries()).map(([appDate, { sum, count }]) => ({
      appDate,
      average: sum / count,
    }));

    averages.forEach(x => {
      x.average = parseFloat(x.average.toFixed(2));
    })

    series.push({
      type: 'spline',
      step: 'center',
      name: 'Average',
      data: averages.map(x => x.average),
      marker: {
        lineWidth: 3,
        lineColor: Highcharts.getOptions().colors[3],
        fillColor: 'white'
      }
    });

    const options: any = {
      colors: [
        '#FF6384',
        '#36A2EB',
        '#4BF266',
        '#9966FF',
        '#4BC0C0',
        '#FF9F40',
        '#C9CBCF'
      ],
      chart: {
        type: 'column',
        zoomType: 'x',
        panning: true,
        panKey: 'shift'
      },
      title: {
        text: ''
        // text: 'Appointments'
      },
      xAxis: {
        autoRotation: [-45, -90],
        labels: {
          style: {
            // "textOverflow": "none",
            // "white-space": "nowrap",
            // "overflow": "false",
            fontSize: '12px',
            fontWeight: '500',
            'fontFamily': '"Work Sans", sans-serif',
          }
        },

        type: 'category',

      },
      drilldown: {
        activeAxisLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'italic'
        },
        activeDataLabelStyle: {
          textDecoration: 'none',
          fontStyle: 'italic'
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Total Appointment(s)'
        }
      },
      tooltip: {
        // headerFormat: '<div class="chart-tooltip-outer"> <div class="chart-tooltip-outer__title" style="font-size:10px;">{point.key}</div> ',
        // pointFormat: '<div class="chart-tooltip-outer__content"><span class="chart-tooltip-outer__text" style="color:{series.color};padding:0">{series.name}: </span>' +
        //   '<span class="chart-tooltip-outer__number" style="padding:0"><b>{point.y}</b></span></div>',
        // footerFormat: '</div>',
        // shared: true,
        // useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0.1,
        },

        // series: {
        //   dataLabels: {
        //     enabled: true,
        //     y: -1
        //   }
        // }

      },
      series: series,
      exporting: {
        sourceWidth: 1200,
        sourceHeight: 600,
        enabled: false
      },
      credits: {
        enabled: false
      },
    };

    Highcharts.chart('mchart-line' + kpiUserReportPrefrenceId, options);
  }

  GetKPIReportDataTable(kpiUserReportPrefrenceId: number, isForceReload: boolean) {
    // this.IsDataTable = true;
    this.searchRequestModel = new KPIReportSearchRequestModel();
    if (this.kpiReportModel == undefined) {
      return
    }
    let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);

    this.searchRequestModel.years = reportFilters.selectedYear;
    this.searchRequestModel.months = reportFilters.selectedMonth;
    this.searchRequestModel.weeks = reportFilters.selectedDays;
    this.searchRequestModel.appointments = reportFilters.selectedAppointmentStatus;
    this.searchRequestModel.classification = reportFilters.selectedClassification;
    this.searchRequestModel.scheduler = reportFilters.selectedSchedulers;
    this.searchRequestModel.branch = reportFilters.selectedBranches;
    this.searchRequestModel.facilities = reportFilters.selectedFacility;
    this.searchRequestModel.therapist = reportFilters.selectedTherapist;
    this.searchRequestModel.insurance = reportFilters.selectedInsurance;
    this.searchRequestModel.typeofappointments = reportFilters.selectedAppointmentTypes;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.pageSize = reportFilters.kpiReportSearchRequestModel?.PageSize == undefined ? +this.pageSizes[0] : reportFilters.kpiReportSearchRequestModel?.PageSize;
    this.searchRequestModel.currentPageIndex = Number.isNaN(reportFilters.kpiReportSearchRequestModel?.CurrentPageIndex) ? 0 : reportFilters.kpiReportSearchRequestModel?.CurrentPageIndex;
    this.searchRequestModel.xAxisList = reportFilters.selectedXAxisList;
    this.searchRequestModel.columnType = reportFilters.columnType;
    this.searchRequestModel.combinedReportAverageBy = reportFilters.combinedReportAverageBy;
    this.searchRequestModel.kpiUserReportPrefrenceId = kpiUserReportPrefrenceId;

    this.isReportLoading(kpiUserReportPrefrenceId, true);

    if (reportFilters.orgReportName == 'combined') {
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId)
          x.isDataTableView = true;
      })
      var isDatatableEmpty = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);
      if ((isDatatableEmpty && isDatatableEmpty.kpiReportDataTable == undefined) || isForceReload) {
        this.kpiReportService.GetKPIReportMovingAverageDataTable(this.searchRequestModel)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
          .subscribe((result: ResultOfT<PagedResponseModel<CombinedChartModel>>) => {
            this.processResult<PagedResponseModel<CombinedChartModel>>(result, () => {
              if (result.isSuccess) {
                if (result.responseModel != undefined && result.responseModel != null) {
                  const pagedResponse = result.responseModel;
                  if (pagedResponse) {
                    this.isReportLoading(kpiUserReportPrefrenceId, false);
                    this.kpiReportModel.forEach(x => {
                      if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId) {
                        x.kpiMovingReportDataTable = pagedResponse.results;
                        x.totalRecords = pagedResponse.totalRecords;
                        x.totalPages = pagedResponse.totalPages;
                        // x.kpiReportSearchRequestModel.FirstRecordIndex = 0;
                        // x.kpiReportSearchRequestModel.CurrentPageIndex = 0;
                        if (!x.kpiReportSearchRequestModel.PageSize) {
                          x.kpiReportSearchRequestModel.PageSize = pagedResponse.totalRecords;
                        }
                      }
                    });
                  }
                }
              }
            });
          }, (httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
            this.isReportLoading(kpiUserReportPrefrenceId, false);
          });
      }
      else {
        this.isReportLoading(kpiUserReportPrefrenceId, false);
      }

    }
    else {
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId)
          x.isDataTableView = true;
      })
      var isDatatableEmpty = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);
      if ((isDatatableEmpty && isDatatableEmpty.kpiReportDataTable == undefined) || isForceReload) {
        this.kpiReportService.GetKPIReportDataTable(this.searchRequestModel)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
          .subscribe((result: ResultOfT<PagedResponseModel<KPIReportDataTableResponse>>) => {
            this.processResult<PagedResponseModel<KPIReportDataTableResponse>>(result, () => {
              if (result.isSuccess) {
                if (result.responseModel != undefined && result.responseModel != null) {
                  const pagedResponse = result.responseModel;
                  if (pagedResponse) {
                    this.isReportLoading(kpiUserReportPrefrenceId, false);
                    this.kpiReportModel.forEach(x => {
                      if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId) {
                        x.kpiReportDataTable = pagedResponse.results;
                        x.totalRecords = pagedResponse.totalRecords;
                        x.totalPages = pagedResponse.totalPages;
                        // x.kpiReportSearchRequestModel.FirstRecordIndex = 0;
                        // x.kpiReportSearchRequestModel.CurrentPageIndex = 0;
                        if (!x.kpiReportSearchRequestModel.PageSize) {
                          x.kpiReportSearchRequestModel.PageSize = pagedResponse.totalRecords;
                        }
                      }
                    });
                  }
                }
              }
            });
          }, (httpResponseError) => {
            this.isReportLoading(kpiUserReportPrefrenceId, false);
            this.showErrorMessage(httpResponseError.message);
          });
      }
      else {
        this.isReportLoading(kpiUserReportPrefrenceId, false);
      }
    }
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});
    formGroup.addControl(this.formFields.reportName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.chartType, this.formBuilder.control('', {
      validators: Validators.compose([
        this.selectedKPIReportTypeToCreate?.orgReportName == 'column' ? Validators.required : null
      ])
    }));
    formGroup.addControl(this.formFields.timePeriod, this.formBuilder.control('', { validators: [Validators.required] }));
    //formGroup.addControl(this.formFields.visualization, this.formBuilder.control('', { validators: [Validators.required] }));
    formGroup.addControl(this.formFields.averageBy, this.formBuilder.control('', {
      validators: Validators.compose([
        this.selectedKPIReportTypeToCreate?.orgReportName == 'combined' ? Validators.required : null
      ])
    }));
    this.form = formGroup;
  }
  get hasChartTypeError() {
    return (
      this.form.get('chartType').touched &&
      this.form.get('chartType').errors &&
      this.form.get('chartType').errors.required
    )
  }
  get hasAverageByChartTypeError() {
    return (
      this.form.get('averageBy').touched &&
      this.form.get('averageBy').errors &&
      this.form.get('averageBy').errors.required
    )
  }
  get hastimePeriodError() {
    return (
      this.form.get('timePeriod').touched &&
      this.form.get('timePeriod').errors &&
      this.form.get('timePeriod').errors.required
    )
  }
  // get hasVisualizationError() {
  //   return (
  //     this.form.get('visualization').touched &&
  //     this.form.get('visualization').errors &&
  //     this.form.get('visualization').errors.required
  //   )
  //}

  showDataTable() {
    this.GetKPIReportDataTable(this.selectedReportItem.kpiUserReportPrefrenceId, true)
  }
  hideDataTable() {
    //this.IsDataTable = false;
    this.GetKPIReportDetails(this.selectedReportItem.kpiUserReportPrefrenceId);
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId)
        x.isDataTableView = false;
    })
  }


  downloadPNG() {
    let chartId = 'mchart-line' + this.selectedReportItem?.kpiUserReportPrefrenceId;

    let charts: any = this.Highcharts?.charts?.filter(x => x != undefined);
    charts.forEach(item => {
      if (item.renderTo.id == chartId) {
        let chart = item;
        chart.exportChart(
          {
            type: 'image/png',
            filename: this.selectedReportItem?.reportName,
            width: 600,
          },
          {
            title: {
              text: ''
            }
          }
        );
      }
    });
  }

  ExportCSV() {

    this.searchRequestModel = new KPIReportSearchRequestModel();
    if (this.kpiReportModel == undefined) {
      return
    }
    let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId);

    this.searchRequestModel.years = reportFilters.selectedYear;
    this.searchRequestModel.months = reportFilters.selectedMonth;
    this.searchRequestModel.weeks = reportFilters.selectedDays;
    this.searchRequestModel.appointments = reportFilters.selectedAppointmentStatus;
    this.searchRequestModel.classification = reportFilters.selectedClassification;
    this.searchRequestModel.scheduler = reportFilters.selectedSchedulers;
    this.searchRequestModel.branch = reportFilters.selectedBranches;
    this.searchRequestModel.facilities = reportFilters.selectedFacility;
    this.searchRequestModel.therapist = reportFilters.selectedTherapist;
    this.searchRequestModel.insurance = reportFilters.selectedInsurance;
    this.searchRequestModel.typeofappointments = reportFilters.selectedAppointmentTypes;
    this.searchRequestModel.reportName = reportFilters.orgReportName;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.xAxisList = reportFilters.selectedXAxisList;
    this.searchRequestModel.columnType = reportFilters.columnType;
    this.searchRequestModel.combinedReportAverageBy = reportFilters.combinedReportAverageBy;
    this.searchRequestModel.kpiUserReportPrefrenceId = this.selectedReportItem.kpiUserReportPrefrenceId;

    this.isMovingAverage = reportFilters.orgReportName == 'combained' ? true : false;
    this.loading = true
    this.kpiReportService.ExportKPIReportDataTable(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId);
        a.download = reportFilters.reportName + '_Report_' + new Date().getMilliseconds().toString() + '.csv';

        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.loading = false
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  ExportPDF() {
    this.exportData1(this.selectedReportItem.kpiUserReportPrefrenceId)
  }

  exportData1(kpiUserReportPrefrenceId: number) {
    this.searchRequestModel = new KPIReportSearchRequestModel();
    if (this.kpiReportModel == undefined) {
      return
    }
    let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);

    this.searchRequestModel.years = reportFilters.selectedYear;
    this.searchRequestModel.months = reportFilters.selectedMonth;
    this.searchRequestModel.weeks = reportFilters.selectedDays;
    this.searchRequestModel.appointments = reportFilters.selectedAppointmentStatus;
    this.searchRequestModel.classification = reportFilters.selectedClassification;
    this.searchRequestModel.scheduler = reportFilters.selectedSchedulers;
    this.searchRequestModel.branch = reportFilters.selectedBranches;
    this.searchRequestModel.facilities = reportFilters.selectedFacility;
    this.searchRequestModel.therapist = reportFilters.selectedTherapist;
    this.searchRequestModel.insurance = reportFilters.selectedInsurance;
    this.searchRequestModel.typeofappointments = reportFilters.selectedAppointmentTypes;
    this.searchRequestModel.reportName = reportFilters.orgReportName;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.isMovingAverage = reportFilters.orgReportName == 'combined' ? true : false;
    this.loading = true
    if (this.isMovingAverage) {
      this.kpiReportService.GetKPIReportMovingAverageDataTableString(this.searchRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .toPromise()
        .then((result) => {
          this.processResult<any[]>(result, () => {
            if (result.responseModel.length > 0) {
              let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId);
              let jsPdf = new jspdf('p', 'px', 'a4');
              let kpiReportName = reportFilters.reportName + '_' + 'Report.pdf'
              //const opt: HTMLOptions = {
              //  callback: function (jsPdf) {
              //    jsPdf.save(kpiReportName);
              //  },
              //  margin: [5, 5, 5, 5],
              //  autoPaging: "text",
              //  html2canvas: {
              //    allowTaint: true,
              //    letterRendering: true,
              //    logging: false,
              //    scale: .3,
              //    width: 1200
              //  },
              //  width: 1500,
              //  windowWidth: 1500,
              //};
              //jsPdf.html(result.responseModel, opt);


              const doc = new jspdf('p', 'px', 'a4');
              let pageHeight = doc.internal.pageSize.getHeight();

              const pages = result.responseModel;
              this.addPageToPdf(doc, kpiReportName, pages)

            }
          });
        }).catch((httpResponseError) => {
          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    }
    else {
      this.kpiReportService.GetKPIReportDataTableString(this.searchRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { }))
        .toPromise()
        .then((result) => {
          this.processResult<any[]>(result, () => {
            if (result.responseModel.length > 0) {
              debugger;
              let reportFilters = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId);
              let jsPdf = new jspdf('p', 'px', 'a4');
              let kpiReportName = reportFilters.reportName + '_' + 'Report.pdf'
              //const opt: HTMLOptions = {
              //  callback: function (jsPdf) {
              //    jsPdf.save(kpiReportName);
              //  },
              //  margin: [5, 5, 5, 5],
              //  autoPaging: "text",
              //  html2canvas: {
              //    allowTaint: true,
              //    letterRendering: true,
              //    logging: false,
              //    scale: .3,
              //    width: 1200
              //  },
              //  width: 1500,
              //  windowWidth: 1500,
              //};
              //jsPdf.html(result.responseModel, opt);


              const doc = new jspdf('p', 'px', 'a4');
              let pageHeight = doc.internal.pageSize.getHeight();

              const pages = result.responseModel;
              this.addPageToPdf(doc, kpiReportName, pages)




            }
          });
        }).catch((httpResponseError) => {
          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  addPageToPdf(pdf, fileName, pages, pageIndex = 0) {
    let that = this;
    if (pages.length === 0) {
      pdf.save(fileName);
      this.loading = false;
      return;
    }
    pdf.html(pages.at(0), {
      y: pdf.internal.pageSize.getHeight() * pageIndex,
      callback(pdf) {
        pdf.addPage();
        that.addPageToPdf(pdf, fileName, pages.slice(1), ++pageIndex);
      },

      margin: [5, 5, 5, 5],
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: .3,
        width: 1500
      },
      width: 1500,
      windowWidth: 1500,

    });
  }

  UpdateReportName(kpiUserReportPrefrenceId) {
    this.loading = true;
    this.kpiReportService.UpdateReportName(kpiUserReportPrefrenceId, this.reportName).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess && !result.message) {
          this.showSuccessMessage('Name Updated successfully');
          this.kpiReportModel.forEach(x => {
            if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId) {
              x.reportName = this.reportName;
            }
          });
          this.reportName = '';
          if (this.kpiReportModel != null) {
            this.kpiReportModel.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }
          this.onRefreshReport(true, kpiUserReportPrefrenceId);
        }
        else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  DeleteKPIReport(kpiUserReportPrefrenceId: number) {
    this.loading = true;
    this.kpiReportService.DeleteKPIReport(kpiUserReportPrefrenceId).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess && !result.message) {
          this.showSuccessMessage('KPI Report Deleted successfully');
          //this.GetKPIReportDetails(kpiUserReportPrefrenceId);
        }
        else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  DeleteKPIReportFilterPrefrences(kpiUserReportPrefrenceId: number, filterName: string) {
    this.loading = true;
    this.kpiReportService.DeleteKPIReportFilterPrefrences(kpiUserReportPrefrenceId, filterName).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess && !result.message) {
          //this.showSuccessMessage('KPI Report Deleted successfully');
          this.onRefreshReport(true, kpiUserReportPrefrenceId);
        }
        else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  copyReport() {
    const index = this.kpiReportModel.findIndex(object => object.kpiUserReportPrefrenceId === this.selectedReportItem.kpiUserReportPrefrenceId);
    let reportModel = new KPIReportModel();
    let reportDetail = this.kpiReportMasterDetails.filter(x => x.orgReportName == this.selectedReportItem.orgReportName)
    if (reportDetail != null)
      this.selectedKPIReportTypeToCreate = reportDetail[0];

    const form = this.form;
    form.get(this.formFields.reportName).setValue(this.selectedReportItem.reportName + '_copy');

    this.selectedCategory = this.selectedReportItem.selectedCategory;
    this.clearDefaultFilterSelections("timeperiod");
    this.selectedReportItem?.selectedYear?.split(',').forEach(value => {
      this.TimePeriodDropdown.forEach(x => {
        if (x.name.toLowerCase() == value.toLowerCase()) {
          x.isChecked = true;
        }
      });
    });

    if (this.selectedReportItem.orgReportName == 'column') {
      form.get(this.formFields.chartType).setValue(this.selectedReportItem.columnType);
    }

    if (this.selectedReportItem.orgReportName == 'combined') {
      form.get(this.formFields.averageBy).setValue(this.selectedReportItem.combinedReportAverageBy);
    }

    this.todo = [];
    if (this.selectedReportItem?.orgReportName != 'combined') {
      this.selectedReportItem.selectedXAxisList.forEach(item => {
        let result = this.YAxisCategoryDropdown.filter(x => x.value == item.kpiXAxisFieldName);
        if (result != undefined && result != null) {
          this.todo.push(result[0]);
        }
      });
    }
    else {
      this.selectedReportItem.selectedXAxisList.forEach(item => {
        let result = this.CombinedYAxisCategoryDropdown.filter(x => x.value == item.kpiXAxisFieldName);
        if (result != undefined && result != null) {
          this.todo.push(result[0]);
        }
      });
    }
    this.SelectedVisualizationFieldTexts = 'Select';
    if (this.todo != undefined && this.todo.length > 0) {
      this.SelectedVisualizationFieldTexts = this.todo.map(x => x.name).join(', ');
    }

    //form.get(this.formFields.yaxisCategory).setValue(this.YAxisCategoryDropdown.filter(x => x.isChecked));
    form.get(this.formFields.timePeriod).setValue(this.TimePeriodDropdown.filter(x => x.isChecked));

    this.formBlock = true;
    this.AddNewReport = true;
  }

  menuClicked(item: any) {
    this.IsDataTable = item.isDataTableView
  }

  resetFormValues() {
    this.form.reset();
    this.isSubmitReport = false;
    if (this.selectedKPIReportTypeToCreate?.orgReportName == 'combined') {
      this.todo = [... this.defaultTodoForCombinedReport];
    }
    else {
      this.todo = [... this.defaultTodo];
    }
    this.SelectedVisualizationFieldTexts = 'Select';
    if (this.todo != undefined && this.todo.length > 0) {
      this.SelectedVisualizationFieldTexts = this.todo.map(x => x.name).join(', ');
    }
    this.selectedCategory = null;
  }

  AddEditUserReportLayoutPrefrences() {
    this.loading = true;
    this.kpiReportService.AddEditUserReportLayoutPrefrences(this.selectedReportLayout, this.loggedInUserId).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  GetUserReportLayoutPrefrences() {
    this.loading = true;
    this.kpiReportService.AddEditUserReportLayoutPrefrences(this.selectedReportLayout, this.loggedInUserId).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  selectAllCheckbox(selectedTab: string, FilterType: string, isChecked: any) {
    if (selectedTab == 'filter') {
      if (FilterType == 'scheduler') {
        this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'classification') {
        this.kpiReportFiltersResponseModel.kpiClassifications.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'branch') {
        this.kpiReportFiltersResponseModel.kpiBranches.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'facility') {
        this.kpiReportFiltersResponseModel.kpiFacilities.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'therapist') {
        this.kpiReportFiltersResponseModel.kpiTherapist.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'insurance') {
        this.kpiReportFiltersResponseModel.kpiInsurances.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'appointmenttype') {
        this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(x => { x.isChecked = isChecked.checked; });
      }
    }
    else if (selectedTab == 'appointmentstatus') {
      if (FilterType == 'appointmentstatus') {
        this.AppointmentStatusDropdown.forEach(x => { x.isChecked = isChecked.checked; });
      }
    }
    else if (selectedTab == 'timeperiod') {
      if (FilterType == 'year') {
        this.TimePeriodDropdown.forEach(x => { x.isChecked = isChecked.checked; });
      }
      else if (FilterType == 'month') {
        this.TimePeriodMonthDropdown.forEach(x => { x.isChecked = isChecked.checked; });
      }
    }
    else if (selectedTab == 'appointmentstatus') {
      if (FilterType == 'appointmentstatus') {
        this.AppointmentStatusDropdown.forEach(x => { x.isChecked = isChecked.checked; });
      }
    }
    this.onCheckboxChanged(FilterType);
  }

  onFilterClear(selectedTab: string, FilterType: string) {
    if (selectedTab == 'filter') {
      if (FilterType == 'scheduler') {
        this.kpiReportFiltersResponseModel.kpiSchedulers.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'classification') {
        this.kpiReportFiltersResponseModel.kpiClassifications.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'branch') {
        this.kpiReportFiltersResponseModel.kpiBranches.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'facility') {
        this.kpiReportFiltersResponseModel.kpiFacilities.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'therapist') {
        this.kpiReportFiltersResponseModel.kpiTherapist.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'insurance') {
        this.kpiReportFiltersResponseModel.kpiInsurances.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'appointmenttype') {
        this.kpiReportFiltersResponseModel.kpiAppointmentTypes.forEach(x => { x.isChecked = false; });
      }
    }
    else if (selectedTab == 'appointmentstatus') {
      if (FilterType == 'appointmentstatus') {
        this.AppointmentStatusDropdown.forEach(x => { x.isChecked = false; });
      }
    }
    else if (selectedTab == 'timeperiod') {
      if (FilterType == 'year') {
        this.TimePeriodDropdown.forEach(x => { x.isChecked = false; });
      }
      else if (FilterType == 'month') {
        this.TimePeriodMonthDropdown.forEach(x => { x.isChecked = false; });
      }
    }
    this.onCheckboxChanged(FilterType);
  }

  onLazyLoad(event: LazyLoadEvent, kpiUserReportPrefrenceId: number) {
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId) {
        x.kpiReportSearchRequestModel.FirstRecordIndex = event.first;
        x.kpiReportSearchRequestModel.CurrentPageIndex = event.first / event.rows;
        x.kpiReportSearchRequestModel.PageSize = Number(x.selectedpage.code);//event.rows;

        x.kpiReportSearchRequestModel.SortByColumnName = event.sortField;
        x.kpiReportSearchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
        x.kpiReportSearchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
      }
    })

    this.GetKPIReportDataTable(kpiUserReportPrefrenceId, true);
  }

  onPageChange(event) {
    if (event.value) {
      this.kpiReportModel.forEach(x => {
        if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
          x.selectedpage = x.pageSiges.find(x => x.code == event.value.code);
          x.kpiReportSearchRequestModel.PageSize = Number(x.selectedpage.code);
          this.resetPageIndex();
          this.GetKPIReportDataTable(this.selectedReportItem.kpiUserReportPrefrenceId, true);
        }
      });
    }
  }

  private resetPageIndex() {
    this.kpiReportModel.forEach(x => {
      if (x.kpiUserReportPrefrenceId == this.selectedReportItem.kpiUserReportPrefrenceId) {
        x.kpiReportSearchRequestModel.CurrentPageIndex = 0;
        x.kpiReportSearchRequestModel.FirstRecordIndex = 0;
      }
    });
  }

  getTotalRows(kpiUserReportPrefrenceId: number) {
    let result = this.kpiReportModel.find(x => x?.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);
    if (result != null) {
      if (result.kpiReportSearchRequestModel != null && result.kpiReportSearchRequestModel != undefined) {
        return (result.kpiReportSearchRequestModel?.PageSize == undefined || Number.isNaN(result.kpiReportSearchRequestModel?.PageSize)) ? 20 : result.kpiReportSearchRequestModel?.PageSize;
      }

      return result.totalRecords;
    }
  }

  onRefreshReport(isForceRefresh, kpiUserReportPrefrenceId: number) {
    let kpiModel = this.kpiReportModel.find(x => x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId);
    if (kpiModel.isDataTableView) {
      this.GetKPIReportDataTable(this.selectedReportItem.kpiUserReportPrefrenceId, isForceRefresh);
    }
    else {
      this.GetKPIReportDetails(this.selectedReportItem.kpiUserReportPrefrenceId);
    }
  }
  onYAxisCategoryClick(selectedCategory) {

  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  onVisualizationAdd() {
    if (this.selectedCategory != undefined && this.selectedCategory.value.length > 0) {
      if (((this.AddNewReport && this.selectedKPIReportTypeToCreate?.orgReportName == 'combined') ||
        (!this.AddNewReport && this.selectedReportItem?.orgReportName == 'combined'))
        && this.todo.length == 2) {
        this.showErrorMessage("You can add max 2 Parameters.");
        return;
      }
      else if (this.todo.length == 4) {
        this.showErrorMessage("You can add max 4 Parameters.");
        return;
      }

      var result = this.todo.find(x => x.value == this.selectedCategory.value);
      if (result == undefined) {
        this.todo.push(this.selectedCategory);
      }
      else {
        this.showErrorMessage(this.selectedCategory.name + " Field already added on Parameters list.");
      }
      this.selectedCategory = null;
    }
  }

  onVisualizationRemove(item: any, iscombinted: boolean) {
    if (this.todo != undefined && this.todo.length > 0) {
      var index = this.todo.findIndex(x => x.value == item.value);
      if (index >= 0) {
        this.todo.splice(index, 1);
        if (iscombinted == false) {
          let result = this.FilteredYAxisCategoryDropdown.filter(x => x.orderBy == item.orderBy);
          if ((result == null) || (result.length == 0)) {
            this.FilteredYAxisCategoryDropdown.push(item);
            this.FilteredYAxisCategoryDropdown.sort((a, b) => (a.orderBy < b.orderBy ? -1 : 1));
          }
        }
        else {
          let result = this.FilterCombinedYAxisCategoryDropdown.filter(x => x.orderBy == item.orderBy);
          if ((result == null) || (result.length == 0)) {
            this.FilterCombinedYAxisCategoryDropdown.push(item);
            this.FilterCombinedYAxisCategoryDropdown.sort((a, b) => (a.orderBy < b.orderBy ? -1 : 1));
          }
        }
      }

    }
  }

  onColumnChartChanged(item: any, event, element) {
    this.UpdateColumnChartType(this.selectedReportItem.kpiUserReportPrefrenceId, item.value, event, element);
  }

  UpdateColumnChartType(kpiUserReportPrefrenceId: number, columnChartType: string, event, element) {
    element.hide(event);
    this.isReportLoading(kpiUserReportPrefrenceId, true);
    this.kpiReportService.UpdateColumnChartType(kpiUserReportPrefrenceId, columnChartType).pipe(takeUntil(this.unsubscriber),
      finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess && !result.message) {
          this.ComparativeChartTypeDropdown.forEach(x => {
            if (x.value == columnChartType) {
              x.isChecked = true;
            }
            else {
              x.isChecked = false;
            }
          });

          this.kpiReportModel.forEach(x => {
            if (x.kpiUserReportPrefrenceId == kpiUserReportPrefrenceId) {
              x.columnType = columnChartType;
            }
          });
          if (this.kpiReportModel != null) {
            this.kpiReportModel.sort((a, b) => Number(a.reportSortOrder) - Number(b.reportSortOrder));
          }
          element.hide(event);
          this.isReportLoading(kpiUserReportPrefrenceId, false);
          this.onRefreshReport(true, kpiUserReportPrefrenceId);
        }
        else {
          this.isReportLoading(kpiUserReportPrefrenceId, false);
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.isReportLoading(kpiUserReportPrefrenceId, false);
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onAverageChange(kpiUserReportPrefrenceId: number) {
    this.onRefreshReport(true, kpiUserReportPrefrenceId);
  }

  getSelectedChartType(columnType: string) {
    let result = this.ComparativeChartTypeDropdown.filter(x => x.value == columnType).map(x => x.name);
    if (result != undefined && result != null) {
      return result;
    }
  }

  formatNumber(value: number): string {
    if (value % 1 !== 0) {
      return value.toFixed(2);
    } else {
      return value.toString();
    }
  }


  toggleDropdown(condition) {
    var dropdown = document.querySelector('hidestackmenu');
    var dropdown = document.querySelector('.kpi-quick-link-menu');
    if (condition) {
      dropdown.setAttribute("disabled", "disabled");
    } else {
      dropdown.removeAttribute("disabled");
    }
  }


}
