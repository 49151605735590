import { Component, OnInit } from '@angular/core';
import { AppointmentCallResponseModel } from 'src/app/shared/models/response/appointment/appointment-call-response-model'
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
@Component({
  selector: 'app-salesorder-call-logs',
  templateUrl: './salesorder-call-logs.component.html',
  styleUrls: ['./salesorder-call-logs.component.sass']
})
export class SalesorderCallLogsComponent extends BaseComponent implements OnInit {
  callLogs: AppointmentCallResponseModel[];
  patientName: string;
  appDateFormat: DateFormatModel;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, messageService: MessageService, private webStorageService: WebStorageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit() {
   
    this.callLogs = this.config.data.callLogs;
    this.patientName = this.config.data.patientName;
  }
  close() {
    this.ref.close();
  }
}
