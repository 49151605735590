import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { Subject } from 'rxjs';
import { ProvisionalSearchRequestModel, SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DialogService } from 'primeng/dynamicdialog';

import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
declare const Twilio: any;
import * as moment from 'moment';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';

import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { SalesOrderInBTResponse } from 'src/app/shared/models/response/sales-order-bt-response';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component'
import { Constants } from 'src/app/shared/constants';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { AppointmentEmailHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-emalhistory-response-model';
import { AppointmentSMSHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-smshistory-response-model';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { RefreshPatientInfoResponseModel } from '../../../shared/models/response/refreshPatientInfo-response-model';
import { AppointmentHistoryResponseModel } from '../../../shared/models/response/appointment/appointment-history-response-model';
import { CancelVoidResponseModel, SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { parse } from 'querystring';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppointmentCancelRequest } from '../../../shared/models/request/appointment-cancel-request';
import { Location } from '@angular/common';
import { ImportexportService } from '../../servicies/importexport.service';
import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';
import { UpdateStatusOfAppointmentsComponent } from 'src/app/shared/components/update-status-of-appointments/update-status-of-appointments.component';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };


@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.sass'],
  providers: [DialogService]
})

export class AppointmentDetailComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  appointmentId: string;
  salesOrder: SalesOrderInfoResponseModel;
  appointment: AppointmentInfoReponseModel;
  isAppointment: boolean;
  searchAppointmentRequestModel: SearchRequestModel;
  selectedTab: string;
  selectedfilterSIndex: number;
  expandedIndex: number = -1;
  salesOrderStatus: WIPStatesResponseModel[];
  selectedAppointmentOrderStatus: WIPStatesResponseModel;
  defaultselectedAppointmentOrderStatus: WIPStatesResponseModel;
  appointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel[];
  appointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel[];
  remoteAppointmentTypeResponseModel: Array<RemoteAppointmentTypeModel>;
  salesOrderType: string;
  selectedAppointmentType: string;
  schAppointmentType: string;
  IsRescheduleFacilityOption: boolean = false;
  rescheduleFacilityOption: string;
  title = 'Appointment Detail';
  notScheduledStatus: { name: string; code: string; }[];
  duration: string;
  scheduledApptDuration: string;
  appDateFormat: DateFormatModel;
  isTelehealth: boolean;
  callId: number;
  isDataLoaded: boolean = false;
  scheduleAppointment: boolean = false;
  voiceMailAppointment: boolean = false;
  appointmentNextCall: boolean = false;
  isCallStarted: boolean = false;
  connection: any;
  callSID: string;
  device: any;
  callConfigModel: CallConfigModel;
  data1: any;
  event1: any;
  overlaypanel: OverlayPanel;
  crmPatientPhoneNo: string;
  SchedulePopup: boolean = false;
  schedulecall: boolean = false;
  callnote: string;
  calldate: Date;
  calltime: Date;
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  appointmentCallResponseModel: AppointmentCallResponseModel;
  EmailEditStatus: boolean;
  PhoneEditStatus: boolean;
  MobileEditStatus: boolean = false;
  AppointmentBookingPopup: boolean = false;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  salesOrderId: number;
  schAppointmentId: number;
  selectedAppointmentTypePopUp: string;
  actionType: string = "S";
  loggedInUserId: number;
  brightreeInternalUserId: number;
  cancelReasons: { name: string; code: string; }[];
  selectedCancelReason: { name: string; code: string; };
  indexActionTab: number = 0;
  formCancelSubmitted: boolean = false;
  formAppointmentCallSubmitted: boolean = false;
  formEmailSubmitted: boolean = false;
  formPhoneNumberSubmitted: boolean = false;
  formVoidAppointmentSubmitted: boolean = false;
  appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
  loadingEmail: boolean = false;
  isCall: false;
  redirectFromSearch: boolean = false;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  smsdisplay: boolean;
  emaildisplay: boolean;
  convertedRemoteAppointmentType: string;
  isChangeRemoteAppointmentType: boolean = false;
  refreshPatientInfoResponseModel: RefreshPatientInfoResponseModel;
  ClassificationTypeEditStatus: any;
  classificationType: SettingsClassificationTypeResponseModel[];
  selectedClassificationType = new SettingsClassificationTypeResponseModel();
  crmClassification: string = "";
  displayVoidAppointment: boolean = false;
  displayCancelAppointment: boolean = false;
  AppointmentHistoryPopup: boolean = false;
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];
  salesOrderHistoryResponseModel: AppointmentHistoryResponseModel[];
  loadingHistory: boolean;
  soHistorydisplay: boolean;
  SoHistory: boolean;
  timeZone: { name: string; code: string; shortName: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; shortName: string; displayName: string; countryName: string };
  reasonsResponseModel: any;
  returnSaleOrderStatus: string = "";
  selectedVoidstatus: string = "";
  isProvisional: boolean;
  provisionalsearchRequestModel = new ProvisionalSearchRequestModel();
  defaultModeofAppointment: string = "";
  isAllowToSendInvite: boolean = false;
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  displayNoShowAppointment: boolean;
  displayCompleteAppointment: boolean;
  reasonCommentsNoShow: string;
  reasonCommentsComplete: string;
  searchRequestModel: SearchRequestModel;
  isStatusCompleted: boolean = false;
  isRescheduleButtonHide: boolean = false;
  SMSSidebarDisplay: boolean = false;
  EmailSidebarDisplay: boolean = false;
  //41466
  timeZoneList: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];
  selectedPatientTimeZones: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
  bCanSetNoShowStatus: boolean = false;
  updateStatusforminfo: statusInfo;
  restrictedApptForStatus: { crmSalesOrderAPPId: string, crmPatientName: string, schAppointmentDateTime: Date, crmPatientTimeZone: string }[];
  smsLogCount: number;
  emailLogCount: number;
  redirectFromtoDaysList: boolean = false;

  constructor(private router: Router,
    private appointmentService: AppointmentService,
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private dialougeService: DialogService,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private _location: Location,
    private formBuilder: FormBuilder,
    private importExportService: ImportexportService
  ) {
    super(messageService);
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];
    //41466
    this.timeZoneList = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.appointmentSMSHistoryResponseModel = new Array();
    this.appointmentEmailHistoryResponseModel = new Array();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchAppointmentRequestModel) {
      this.searchAppointmentRequestModel = currentNavigationState.searchAppointmentRequestModel;
      this.selectedTab = currentNavigationState.selectedTab;
      this.selectedfilterSIndex = currentNavigationState.selectedfilterIndex;
      this.redirectFromSearch = currentNavigationState.redirectFromSearch;
      this.redirectFromtoDaysList = currentNavigationState.redirectFromtoDaysList;
    }
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.setPermissionforActions();
    this.getRemoteAppointmentType();
    this.getClassificationSettings();
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
  }
  async ngOnInit() {

    this.expandedIndex = -1;
    this.salesOrderType = 'AppointmentRequest';
    this.appointmentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.returnSaleOrderStatus = this.activatedRoute.snapshot.paramMap.get('isback');
    this.buildForm();
    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.appointmentDetailInfo.facilityInfo = new FacilityInfoResponseModel();
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();

    this.selectedAppointmentOrderStatus = new WIPStatesResponseModel();
    await this.getWipStatusBTmapping();
    await this.getSalesOrderStatus();
    this.isTelehealth = false;
    this.getAppointmentInfo(this.appointmentId);
    this.webStorageService.deleteRedirectFrom();
    await this.GetRCMConfigurationUrl();
    await this.getCancelVoidReasons();
  }
  async getRemoteAppointmentType() {
    this.loading = true;
    this.settingsService.getRemoteAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.remoteAppointmentTypeResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  async getWipStatusBTmapping() {

    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        console.error(httpResponseError.message);
        this.showErrorMessage(httpResponseError.message);
      });

  }
  EditEamil() {
    this.EmailEditStatus = !this.EmailEditStatus;
  }
  saveEamil() {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    //if (!this.patientEmail) {
    //  this.showWarningMessage('Please add email address');
    //  return;
    //}
    if (this.patientEmail && !TEXT_REGEXP.test(this.patientEmail)) {
      this.showWarningMessage('Please add proper email address.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Email?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.appointment.salesOrderInfo.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Email updated successfully');
            this.EmailEditStatus = !this.EmailEditStatus;
            this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });

  }

  EditPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;

  }

  savePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    //if (!this.patientPhone) {
    //  this.showWarningMessage('Please add phone number');
    //  return;
    //}
    if (this.patientPhone.length < 10 || !TEXT_REGEXP.test(this.patientPhone)) {
      this.showWarningMessage('Please add valid phone number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Phone Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      if (this.patientPhone.indexOf('-') > -1) {
        this.patientPhone = this.patientPhone.split('-').join('');
      } else {
        this.patientPhone = this.patientPhone;
      }
      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.appointment.salesOrderInfo.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('Phone updated successfully')
            this.PhoneEditStatus = !this.PhoneEditStatus;
            this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result && result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });


  }

  EditMobilePhone() {
    this.MobileEditStatus = !this.MobileEditStatus;

  }
  OrderNoteStatus: boolean = false;
  DeliveryNoteStatus: boolean = false;
  displayOrderNote: boolean = false;
  displayDeliveryNote: boolean = false;
  loadingOrderNote: boolean = false;
  loadingDeliveryNote: boolean = false;
  OrderNoteClick() {
    this.OrderNoteStatus = !this.OrderNoteStatus;
    this.displayOrderNote = true;
  }
  SaveOrderNoteClick() {
    if (!this.appointmentRequestBT.OrderNote) {
      this.showWarningMessage("Please enter order note.");
      return false;
    }

    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.CrmSalesOrderId = Number(this.appointment.crmSalesorderId);
    appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = this.appointmentRequestBT.OrderNote;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = null;
    appointmentRequestBT.WIPDateNeeded = null;
    appointmentRequestBT.WIPStateName = null;
    appointmentRequestBT.WIPStateKey = 0;
    this.loadingOrderNote = true;
    this.appointmentService.AddOrderDeliveryNote(appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingOrderNote = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          this.displayOrderNote = false;
          if (result.responseModel != undefined && result.responseModel != null) {
            let resultModel: SalesOrderInBTResponse = result.responseModel;
            if (resultModel.isSucesss) {

              this.appointment.salesOrderInfo.crmSalesorderOrderNote = resultModel.orderNote;
              // this.appointment.salesOrderInfo.crmSalesorderDeliveryNote = resultModel.deliveryNote;
              this.showSuccessMessage("Order Note has been saved successfully.");
            }

            else {
              this.appointmentRequestBT.OrderNote = this.appointment.salesOrderInfo.crmSalesorderOrderNote;
              this.showErrorMessage("There was problem in saving Order Note.");
            }
          }
        }
        else {
          this.displayOrderNote = false;
          this.loadingOrderNote = false
          if (result && result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in adding.");
        }
      });

    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  CancelOrderNoteClick() {
    //this.appointmentRequestBT.OrderNote = "";
    this.displayOrderNote = false;
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }
  DeliveryNoteClick() {
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
    this.displayDeliveryNote = true;
  }

  SaveDeliveryNoteClick() {
    if (!this.appointmentRequestBT.DeliveryNote) {
      this.showWarningMessage("Please enter delivery note.");
      return false;
    }
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.CrmSalesOrderId = Number(this.appointment.crmSalesorderId);
    appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = this.appointmentRequestBT.DeliveryNote;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = null;
    appointmentRequestBT.WIPDateNeeded = null;
    appointmentRequestBT.WIPStateName = null;
    appointmentRequestBT.WIPStateKey = 0;
    this.loadingDeliveryNote = true;
    this.appointmentService.AddOrderDeliveryNote(appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingDeliveryNote = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          this.displayDeliveryNote = false;
          if (result.responseModel) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let resultModel: SalesOrderInBTResponse = result.responseModel;
              if (resultModel.isSucesss) {

                this.appointment.salesOrderInfo.crmSalesorderDeliveryNote = resultModel.deliveryNote;
                this.showSuccessMessage("Delivery Note has been saved successfully.");
              }
              else {
                this.appointmentRequestBT.DeliveryNote = this.appointment.salesOrderInfo.crmSalesorderDeliveryNote;
                this.showErrorMessage("There was problem in saving delivery note.");
              }
            }

          }
          else {
            this.displayDeliveryNote = false;
            this.loadingDeliveryNote = false
            if (result && result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem in saving delivery note.");
          }
        }
      });

    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  CancelDeliveryNoteClick() {
    //this.appointmentRequestBT.DeliveryNote = "";
    this.displayDeliveryNote = false;
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }


  saveMobilePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    //if (!this.patientMobileNumber) {
    //  this.showWarningMessage('Please provide mobile number');
    //  return;
    //}
    if (this.patientMobileNumber.length < 10 || !TEXT_REGEXP.test(this.patientMobileNumber)) {
      this.showWarningMessage('Please provide valid mobile number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update mobile Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      if (this.patientMobileNumber.indexOf('-') > -1) {
        this.patientMobileNumber = this.patientMobileNumber.split('-').join('');
      } else {
        this.patientMobileNumber = this.patientMobileNumber;
      }

      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.appointment.salesOrderInfo.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Mobile Number updated successfully')
            this.MobileEditStatus = !this.MobileEditStatus;
            this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your mobile Number. Please contact administrator");
            return;
          }
        });
    });

  }


  cancelEmail() {
    this.EmailEditStatus = !this.EmailEditStatus;
    this.patientEmail = this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress;
  }
  cancelPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;
    this.patientPhone = this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo;
  }

  cancelMobilePhone() {
    if (this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber) {
      this.patientMobileNumber = this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber;
    }
    else {
      this.patientMobileNumber = "";
    }
    this.MobileEditStatus = !this.MobileEditStatus;
  }

  onBack(returnSaleOrderStatus) {
    if (returnSaleOrderStatus == "void") {
      this.router.navigate(['admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchAppointmentRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
    }

    else if (returnSaleOrderStatus == "noshow") {
      this.router.navigate(['admin/appointments/view-noshow-appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
    }
    else if (returnSaleOrderStatus == "cancel") {
      this.router.navigate(['admin/appointments/view-cancel-orders'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
    }
    else if (returnSaleOrderStatus == "urgentcalls") {
      this.router.navigate(['admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
    }
    else if (returnSaleOrderStatus == "pos") {
      this.provisionalsearchRequestModel = new ProvisionalSearchRequestModel();
      this.provisionalsearchRequestModel.patientID = Number(this.appointment.salesOrderInfo.crmPatientId);
      this.router.navigate(['admin/appointments/patients-detail'], { state: { provisionalsearchRequestModel: this.provisionalsearchRequestModel } })
    }
    else if (returnSaleOrderStatus == "notification") {
      this._location.back();
    }
    else if (this.redirectFromtoDaysList) {
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchAppointmentRequestModel, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else {

      if (!this.redirectFromSearch)
        this.router.navigate(['admin/appointments'], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
      else
        this.router.navigate(['admin/appointments/search-results'], { state: { searchRequestModel: this.searchAppointmentRequestModel, selectedTab: this.selectedTab, isBookAppintment: false, selectedfilterIndex: this.selectedfilterSIndex } });
    }

  }

  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.responseModel != undefined && result.responseModel != null) {
            this.reasonsResponseModel = result.responseModel;
            const myClonedArray = [];
            this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
            this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
            this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Cancel');
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const cancelRequestModel = new AppointmentCancelRequest();
    const form = this.formVoidAppointment;
    if (form != undefined && form != null) {
      var reason = form.get(this.formFields.voidReason).value;
      cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
      cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    }
    return cancelRequestModel;
  }

  private getValuesFromCancelForm(): AppointmentCancelRequest {
    const form = this.formCancel;
    const cancelRequestModel = new AppointmentCancelRequest();
    if (form != undefined && form != null) {
      var reason = form.get(this.formFields.reason).value;
      cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
      cancelRequestModel.schComments = form.get(this.formFields.reasonComments).value;
    }
    return cancelRequestModel;
  }

  private getValuesFromUpdateStautsdForm(): AppointmentCancelRequest {
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.schCancelReason = this.updateStatusforminfo?.reason;
    cancelRequestModel.schComments = this.updateStatusforminfo?.reasonComment;
    return cancelRequestModel;
  }


  cancelVoidAppointmentUpdate(status: string) {

    let displaystatus;
    if (status == "void review" || status == "void") {
      displaystatus = "voided";
      this.formVoidAppointmentSubmitted = true;
      /* if (this.formVoidAppointment.invalid) {
        this.markFormAsTouched();
        return;
      } */
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false;
    }
    else if (status == "cancel") {
      displaystatus = "canceled";
      this.formCancelSubmitted = true;
      /* if (this.formCancel.invalid) {
        this.markFormAsTouched();
        return;
      } */
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false;
    } else if (status == "noshow") {

      displaystatus = 'no show';

    }
    else if (status == "complete") {

      displaystatus = 'completed';
    }

    // TOD: remove call getValuesFromCancelForm
    let cancelRequestModel = status == "void review" || status == "void" ? this.getValuesFromUpdateStautsdForm() : this.getValuesFromUpdateStautsdForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = Number(this.appointmentId);
    cancelRequestModel.crmSalesOrderId = Number(this.salesOrderId);
    cancelRequestModel.SalesOrderStatus = status == "void review" ? "VoidReview" : status == "void" ? "Void" : "Cancel";

    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = Number(this.appointmentId);
    //appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(valueOfEvent.wipStatesId);
    appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = this.appointment.schIsUnreconciledAppointment;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;

    this.loading = true;
    const cancelRequestList = [];

    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (status == "void review") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    } else if (status == "void") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    else if (status == "cancel") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    if (status.toLowerCase() == 'noshow') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

    }
    if (status.toLowerCase() == 'complete') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;

    cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;
    cancelRequestModel.salesOrderUpdateRequestModelBT.btNoteId = this.salesOrder.btNoteID;
    cancelRequestModel.isProvisional = this.isProvisional;
    cancelRequestList.push(cancelRequestModel);
    let appointmentRequest = this.appointmentService.UpdateSalesOrderByWipStatus(cancelRequestList);
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {

          if (status == "cancel") {
            const formcan = this.formCancel;
            formcan.get(this.formFields.reason).setValue('');
            formcan.get(this.formFields.reasonComments).setValue('');
            formcan.reset();
          }
          else {
            const formvoid = this.formVoidAppointment;
            formvoid.get(this.formFields.voidReason).setValue('');
            formvoid.get(this.formFields.voidReasonComments).setValue('');
            formvoid.reset();
          }

          this.displayCancelAppointment = false;
          this.displayVoidAppointment = false;
          this.showSuccessMessage('Appointment has been ' + displaystatus + ' successfully.');
          this.loading = false
          this.getAppointmentInfo(this.appointmentId);

        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });
  }

  //41466: After marking No Show it allow user to change the availability of that therapist 
  isAllowToSetNoShowStatus(): boolean {
    this.restrictedApptForStatus = [];

    // get the correct appointment time based on Patient timezone
    this.selectedPatientTimeZones = this.timeZoneList.find(x => x.orgcode == this.appointment.appointmentDetailInfo.crmPatientTimeZone);
    var orgappDateTime = moment.tz(this.appointment.schAppointmentDateStartTime, this.selectedPatientTimeZones.countryName);

    //convert appointment time to local timezone
    this.selectedPatientTimeZones = this.timeZoneList.find(x => x.code == this.appDateFormat.timeZone);
    //var appDate = orgappDateTime.tz("America/New_York").format('DD-MMM-yyyy hh:mm:ss'); 
    var appDate = orgappDateTime.tz(this.selectedPatientTimeZones.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    var appDateTime = moment(appDate).toDate();

    // get curretn date time from local timezone
    var date = moment(new Date()).tz(this.selectedPatientTimeZones.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    var currentDateTime = moment(date).toDate();

    console.log(appDateTime);
    console.log(currentDateTime);

    // 8905 remove check for future date
    /* if (moment(this.appointment.schAppointmentDateStartTime).isAfter(moment(Date()).toDate())) {
      let schAppointmentDateTime: Date = this.appointment.schAppointmentDateStartTime;
      let crmSalesOrderAPPId: string = this.appointment.salesOrderInfo.crmSalesorderAppId;
      let crmPatientName: string = this.appointment.salesOrderInfo.patientInfo.crmPatientLastName + ', ' + this.appointment.salesOrderInfo.patientInfo.crmPatientFirstName;
      let crmPatientTimeZone: string = this.appointment.appointmentDetailInfo.crmPatientTimeZone;
      this.restrictedApptForStatus.push({ crmPatientName, crmSalesOrderAPPId, schAppointmentDateTime, crmPatientTimeZone });
      return false;
    } */

    return  true; 

  }

  NoShowAppointment(status: string) {
    let displaystatus;
    if (status.toLowerCase() == 'complete') {

      status = 'complete';
      displaystatus = 'completed';
    }
    if (status.toLowerCase() == 'noshow') {
      status = 'no show';
      displaystatus = 'no show';
    }
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (status.toLowerCase() == 'no show') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

    }

    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;

    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = Number(this.appointmentId);
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(this.selectedAppointmentOrderStatus.wipStatesId);
    appointmentUpdateStatusRequestModel.SchComments = this.updateStatusforminfo?.reasonComment;//this.reasonCommentsNoShow;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = false;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);


    this.loading = true;
    this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Appointment has been ' + displaystatus + ' successfully.');
          //this.showSuccessMessage('Status has been updated successfully.');
          this.displayNoShowAppointment = false;
          this.reasonCommentsNoShow = '';
          this.getAppointmentInfo(this.appointmentId);
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  CompleteAppointment(status: string) {
    let displaystatus;

    if (status.toLowerCase() == 'complete') {

      status = 'complete';
      displaystatus = 'completed';
    }
    if (status.toLowerCase() == 'no show') {
      status = 'no show';
      displaystatus = 'no show';
    }

    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;

    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = Number(this.appointmentId);
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(this.selectedAppointmentOrderStatus.wipStatesId);
    appointmentUpdateStatusRequestModel.SchComments = this.updateStatusforminfo?.reasonComment;//this.reasonCommentsComplete;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = false;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);


    this.loading = true;
    this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Appointment has been ' + displaystatus + ' successfully.');
          this.displayCompleteAppointment = false;
          this.reasonCommentsComplete = '';
          this.getAppointmentInfo(this.appointmentId);

        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }



  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formCancel: formGroup,
      formVoidAppointment: formVoidGroup
    });
  }
  get fCancel() { return (<FormGroup>this.form.get('formCancel')).controls; }

  get formCancel() {
    if (this.form != undefined) {
      return this.form.get('formCancel');
    }
  }
  hideAppointmentpopup() {
    const form = this.formCancel;
    form.get(this.formFields.reason).setValue('');
    form.get(this.formFields.reasonComments).setValue('');
    form.reset();
    this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
    this.displayCancelAppointment = false;
  }

  validationMessages = {
    reason: {
      required: 'Cancel reason is required',
    },
    reasonComments: {
      required: 'Comment is required',
    },
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },
  };
  formFields = {
    reason: 'reason',
    reasonComments: 'reasonComments',
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };
  get fVoid() {

    return (<FormGroup>this.form.get('formVoidAppointment')).controls;
  }

  get formVoidAppointment() {
    if (this.form != undefined) {
      return this.form.get('formVoidAppointment');
    } return;


  }
  cancelVoidAppointment() {

    const form = this.formVoidAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.displayVoidAppointment = false;
    this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
  }

  onStatusChange(event) {

    let valueOfEvent = event.value;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();

    let status: string = valueOfEvent.wipStatesName;
    let statusUpdated: string = valueOfEvent.wipStatesName;
    //41466: thia code is just return pop up for appointment is not due yet 
    /* if ((status.toLowerCase() == 'no show') || (status.toLowerCase() == 'complete')) {

      if ((this.appointment != undefined && this.appointment.appointmentDetailInfo.crmPatientTimeZone != undefined &&
        this.appointment.appointmentDetailInfo.crmPatientTimeZone != "")) {

        if (!this.isAllowToSetNoShowStatus()) {

          if (status.toLowerCase() == 'no show') {
            //delete
            status = 'no show';
            statusUpdated = 'no show';
            this.reasonCommentsNoShow = '';
            this.displayNoShowAppointment = true;
            this.displayCancelAppointment = false;
            this.displayVoidAppointment = false;
            let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
            if (selectedWipStaus) {
              this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
              this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
            }
            // delete
            this.hideNoShowPopup();
            this.showWarningMessage('No Show status can not be set, because Appointment time is not due yet.');
            this.hideNoShowPopup();
          }
          if (status.toLowerCase() == 'complete') {
            this.displayCompleteAppointment = false;
            this.hideCompletePopup();
            this.showWarningMessage('Complete status can not be set, because Appointment time is not due yet.');
          }
          this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
          return;
        }
      } 
    } */
    let action: string = "Void";
    let popupHeader: string = 'Update Void Status of Appointment';
    if (status.toLowerCase() == 'void') {
      status = 'void';
      statusUpdated = 'voided';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (status.toLowerCase() == 'void review') {
      status = 'void review';
      action = "Void review";
      popupHeader = 'Update Void Review Status of Appointment';
      statusUpdated = 'voided for review';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }

    else if (status.toLowerCase() == 'booked') {
      status = 'book';
      statusUpdated = 'booked';
    }
    else if (status.toLowerCase() == 'complete') {
      status = 'complete';
      statusUpdated = 'completed';
      //this.displayCompleteAppointment = true;
      this.displayCancelAppointment = false;
      this.displayVoidAppointment = false;
      this.reasonCommentsComplete = "";
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      let isAllow = this.isAllowToSetNoShowStatus();
      if (!isAllow) {
        // let message:string  = `The Complete status cannot be assigned to the selected appointments as the appointment time has not yet arrived.`;
        // this.showWarningMessage(message);
        this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
          data: {
            action: 'Alert',
            popupHeader: 'Alert',
            IsAllowToComplete: isAllow,
            message: 'The Complete status cannot be assigned to the selected appointments as the appointment time has not yet arrived.'
          },
        }).onClose.subscribe((response: any) => {
          if (response.action == 'Cancel') {
            return;
          }
        });

      }
      else {
        this.dialougeService.open(UpdateStatusOfAppointmentsComponent, {
          data: {
            action: 'Complete',
            popupHeader: 'Update Complete Status of Appointment',
            updateButtonText: 'Yes, Update',
            cancelButtonText: 'No, Cancel',
            restrictedApptForStatus: this.restrictedApptForStatus, //1
            totalSelectedItem: 1,
            isMultiSelected: 'False',
            isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
          },
        }).onClose.subscribe((response: any) => {
          if (response.action == 'Cancel') {
            this.hideCompletePopup();
          }
          if (response.action == 'Confirm') {
            this.updateStatusforminfo = response;
            if (this.restrictedApptForStatus.length == 0)
              this.CompleteAppointment(status.toLowerCase());
          }

        });
      }
    }
    else if (status == "Cancel") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      //this.displayCancelAppointment = true;
      this.displayVoidAppointment = false;
      this.displayNoShowAppointment = false;
      this.displayCompleteAppointment = false;
      action = "Cancel";
      popupHeader = 'Update Cancel Status of Appointment';
      this.callUpdateStatusForm(action, popupHeader, status);
      return;
    }

    if (status.toLowerCase() == 'no show') {
      status = 'no show';
      statusUpdated = 'no show';
      this.reasonCommentsNoShow = '';
      //this.displayNoShowAppointment = true;
      this.displayCancelAppointment = false;
      this.displayVoidAppointment = false;
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      let isAllow = this.isAllowToSetNoShowStatus();
      if (!isAllow) {
        // let message: string = `The No Show status cannot be assigned to the selected appointments as the appointment time has not yet arrived.`;
        // this.showWarningMessage(message);
        // return;
        this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
          data: {
            action: 'Alert',
            popupHeader: 'Alert',
            IsAllowToComplete: isAllow,
            message: 'The No Show status cannot be assigned to the selected appointments as the appointment time has not yet arrived.'
          },
        }).onClose.subscribe((response: any) => {
          if (response.action == 'Cancel') {
            return;
          }
        });
      }
      else {
        this.dialougeService.open(UpdateStatusOfAppointmentsComponent, {
          data: {
            action: 'No Show',
            popupHeader: 'Update No Show Status of Appointment',
            updateButtonText: 'Yes, Update',
            cancelButtonText: 'No, Cancel',
            restrictedApptForStatus: this.restrictedApptForStatus, // new otherwise 1
            totalSelectedItem: 1,
            isMultiSelected: 'False',
            isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
          },
        }).onClose.subscribe((response: any) => {
          if (response.action == 'Cancel') {
            this.hideNoShowPopup();
          }
          if (response.action == 'Confirm') {
            this.updateStatusforminfo = response;
            if (this.restrictedApptForStatus.length == 0)
              this.NoShowAppointment(status.toLowerCase());
          }

        });
      }
    }

    if (status == "void" || status == "void review") {
      this.displayCancelAppointment = false;
      //this.displayVoidAppointment = true;
      this.displayNoShowAppointment = false;
      this.displayCompleteAppointment = false;
      this.selectedVoidstatus = status;
      this.callUpdateStatusForm(action, popupHeader, status);

      return;
    }
    else if (status == "Cancel") {
      //this.displayCancelAppointment = true;
      this.displayVoidAppointment = false;
      this.displayNoShowAppointment = false;
      this.displayCompleteAppointment = false;
      action = "Cancel";
      popupHeader = 'Update Cancel Status of Appointment';
      this.callUpdateStatusForm(action, popupHeader, status);
      return;
    }


    //let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    //appointmentRequestBT.IsUpdateToBrightree = false;
    //appointmentRequestBT.IsDeliveryNoteOverride = true;
    //appointmentRequestBT.IsOrderNoteOverride = true;
    //appointmentRequestBT.OrderNote = null;
    //appointmentRequestBT.DeliveryNote = null;
    //appointmentRequestBT.PractitionerName = null;
    //appointmentRequestBT.PractitionerId = 0;
    //appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    //appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    //appointmentRequestBT.ScheduledDeliveryStartDate = null;
    //appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    //appointmentRequestBT.WIPDateNeeded = moment().toDate();

    //if (status.toLowerCase() == 'complete') {
    //  if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
    //    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
    //    if (selectedWipStaus) {
    //      appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
    //      appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
    //      appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
    //      appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
    //    }
    //  }

    //}
    //if (status.toLowerCase() == 'no show') {
    //  if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
    //    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
    //    if (selectedWipStaus) {
    //      appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
    //      appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
    //      appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
    //      appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
    //    }
    //  }
    //}

    //let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    //appointmentUpdateStatusRequestModel.SchAppointmentId = Number(this.appointmentId);
    //appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(valueOfEvent.wipStatesId);
    //appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = this.appointment.schIsUnreconciledAppointment;
    //appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    //appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;
    //appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;

    //appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    //appointmentUpdateStatusRequestModel.isProvisional = this.isProvisional;
    //appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);

    //this.dialougeService.open(ConfirmDialogComponent, {
    //  data:
    //  {
    //    message: 'Are you sure you want to ' + status + ' appointment ?',
    //  },
    //  header: 'Confirmation'
    //}).onClose.subscribe((response: boolean) => {
    //  if (!response) {
    //    return;
    //    this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
    //  }
    //  this.loading = true;
    //this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
    //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //    .subscribe((result: ResultOfT<boolean>) => {
    //      this.processResult<boolean>(result, () => {
    //        this.showSuccessMessage('Appointment has been ' + statusUpdated + ' successfully.');

    //        this.getAppointmentInfo(this.appointmentId);
    //        //this.showSuccessMessage('Status has been updated successfully.');
    //      });

    //    }, (httpResponseError) => {
    //      this.showErrorMessage(httpResponseError.message);
    //    });
    //});


  }

  callUpdateStatusForm(action: string, header: string, status: string) {
    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data: {
        action: action,
        popupHeader: header,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: action == 'Cancel' ? this.cancellationReasonsResponseModel : this.voidReasonsResponseModel,
        isMultiSelected: 'False',
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {
      if (action == 'Cancel') {
        if (response.action == 'Confirm') {
          this.updateStatusforminfo = response;
          this.cancelVoidAppointmentUpdate(status.toLowerCase());
        }
        if (response.action == 'Cancel') {
          this.hideAppointmentpopup();
        }
      }
      else { //void/void review
        if (response.action == 'Confirm') {
          this.updateStatusforminfo = response;
          this.cancelVoidAppointmentUpdate(status.toLowerCase());
        }
        if (response.action == 'Cancel') {
          this.cancelVoidAppointment();
        }
      }
    });
    /** End Code */
  }

  hideNoShowPopup() {
    this.reasonCommentsNoShow = '';
    this.displayNoShowAppointment = false;
    this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
  }

  hideCompletePopup() {
    this.reasonCommentsComplete = "";
    this.displayCompleteAppointment = false;
    this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
  }

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }

  //Permission Check
  features: featureResponseModel[];
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  setPermissionforActions() {

    this.features = this.webStorageService.getPermissions();
    if (this.features != null && this.features != undefined) {
      if (this.features && this.features.length > 0) {

        let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
        if (inPer && inPer.isActive == true)
          this.isSendInvitationPermission = true;

        let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
        if (inPer1 && inPer1.isActive == true) {
          this.isAppointmentScheduledPermission = true;
          this.isAppointmentRescheduledPermission = true;

        }

        let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
        if (inPer2 && inPer2.isActive == true)
          this.isAppointmentCancelPermission = true;

        //let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
        //if (inPer3 && inPer3.isActive == true)
        //  this.isAppointmentRescheduledPermission = true;


        let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
        if (inPer4 && inPer4.isActive == true)
          this.isAppointmentMovedPermission = true;

        let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
        if (inPer5 && inPer5.isActive == true)
          this.isAppointmentOverduePermission = true;

        let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
        if (inPer6 && inPer6.isActive == true)
          this.isAppointmentNoshowPermission = true;

        let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
        if (inPer7 && inPer7.isActive == true)
          this.isAppointmentCompletedPermission = true;
      }
    }
  }


  showScheduleAppointment() {
    if (!this.appointment.salesOrderInfo.crmIsRemoteVisitSelected && !this.appointment.salesOrderInfo.crmIsFacilityVisitSelected && !this.appointment.salesOrderInfo.crmIsRoadHomeVisitSelected) {
      this.showWarningMessage("Doctor is not allowed to Schedule/Reschedule appointment. Please enable Doctor availability from Resource->Doctors section.");
      return;
    }
    this.salesOrderId = Number(this.appointment.crmSalesorderId);
    this.isAppointment = true;
    this.indexActionTab = 0;

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: this.salesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: true,
        isAppointment: true,
        schAppointmentId: this.appointmentId,
        isAppointmentAppoinmentDetailPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.getAppointmentInfo(this.appointmentId);
      //this.HangUpCall();

    });


  }

  private async getSalesOrderStatus() {
    this.progress.next(true);
    await this.appointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrderStatus = result.responseModel;
              var index = this.salesOrderStatus.findIndex(x => x.wipStatesName.toLowerCase() == 'booked');
              if (index >= 0) {
                this.salesOrderStatus.splice(index, 1);
              }
              //var index1 = this.salesOrderStatus.findIndex(x => x.wipStatesName.toLowerCase() == 'void');
              //if (index1 >= 0) {
              //  this.salesOrderStatus.splice(index1, 1);
              //}

              index = this.salesOrderStatus.findIndex(x => x.wipStatesName.toLowerCase() == 'unreconcile');
              if (index >= 0) {
                this.salesOrderStatus.splice(index, 1);
              }

              if (!this.isAppointmentNoshowPermission) {
                index = this.salesOrderStatus.findIndex(x => x.wipStatesName.toLowerCase() == 'no show');
                if (index >= 0) {
                  this.salesOrderStatus.splice(index, 1);
                }
              }
              if (!this.isAppointmentCompletedPermission) {
                index = this.salesOrderStatus.findIndex(x => x.wipStatesName.toLowerCase() == 'complete');
                if (index >= 0) {
                  this.salesOrderStatus.splice(index, 1);
                }
              }

              if (this.returnSaleOrderStatus == "void") {

                this.salesOrderStatus = this.salesOrderStatus.filter(x => x.wipStatesName.toLowerCase() == 'void');

              }

            }
          }
        });
      }).catch((httpResponseError) => {
        console.error(httpResponseError.message);
        this.showErrorMessage(httpResponseError.message);
      });
  }


  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0 && rminutes > 0)
      return rhours + " Hour " + rminutes + " Minutes";
    else if (rhours > 0)
      return rhours + " Hour";
    else
      return rminutes + " Minutes";
  }

  private getAppointmentInfo(AppoinmentId) {
    this.loading = true;
    this.isDataLoaded = false;
    this.progress.next(true);
    this.appointmentService.GetAppointmentById(AppoinmentId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentInfoReponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.appointment = result.responseModel;
              this.salesOrder = this.appointment.salesOrderInfo;
              this.salesOrderId = this.salesOrder.crmSalesorderId;
              if (this.salesOrder.crmSalesorderStatus == "Complete" || this.salesOrder.crmSalesorderStatus == "Void") {
                this.isStatusCompleted = true;
                this.isRescheduleButtonHide = false;
              }
              else {
                this.isRescheduleButtonHide = true;
              }
              if (this.appointment != undefined && this.appointment.appointmentDetailInfo.crmPatientTimeZone != undefined && this.appointment.appointmentDetailInfo.crmPatientTimeZone != "") {
                var dateTime = moment.tz(this.appointment.schAppointmentDateStartTime, "America/New_York");
                this.selectedTimeZone = this.timeZone.find(x => x.code == this.appointment.appointmentDetailInfo.crmPatientTimeZone);
                var date = dateTime.tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss');
                // this.appointment.schAppointmentDateStartTime = moment(date).toDate();
              }
              this.isAppointment = true;

              if (this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "-";
              }
              if (this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }

              if (this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }


              let isTelehealth = result.responseModel.salesOrderInfo.crmIsTelehealth;
              this.isTelehealth = isTelehealth;
              this.crmPatientPhoneNo = this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo;
              //this.crmClassification = this.salesOrder.crmClassification;
              //this.crmClassification = this.salesOrder.crmClassification;

              if (this.classificationType != null && this.classificationType != undefined) {
                this.selectedClassificationType = this.classificationType.find(x => x.crmClassificationTypeId == this.appointment.salesOrderInfo.crmSalesorderClassificationType);
              }


              //this.duration = this.appointment.salesOrderInfo.crmClassificationDuration == 60 || this.appointment.salesOrderInfo.crmClassificationDuration == 1 ? '1 Hour' : this.appointment.salesOrderInfo.crmClassificationDuration.toString() + ' Minutes'
              this.duration = this.timeConvert(this.appointment.salesOrderInfo.crmClassificationDuration);
              this.scheduledApptDuration = this.appointment.schAppointmentDuration + ' Minutes';
              //this.scheduledApptDuration = this.timeConvert(this.appointment.schAppointmentDuration);

              let objselected = this.salesOrderStatus.filter(x => x.wipStatesName.replace(/\s/g, "") === this.appointment.schWIPStatus);
              if (objselected.length > 0) {

                this.selectedAppointmentOrderStatus = objselected[0];
                this.defaultselectedAppointmentOrderStatus = objselected[0];
              }
              this.selectedAppointmentTypePopUp = this.appointment.schAppointmentType;
              if (this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth')
                this.schAppointmentType = 'Remote Setup';
              else if (this.selectedAppointmentTypePopUp.toLowerCase() == 'home visit')
                this.schAppointmentType = 'Road/Home Appt';
              else
                this.schAppointmentType = this.selectedAppointmentTypePopUp;
              this.appointmentRequestBT.OrderNote = this.appointment.salesOrderInfo.crmSalesorderOrderNote;
              this.appointmentRequestBT.DeliveryNote = this.appointment.salesOrderInfo.crmSalesorderDeliveryNote;

              if (this.appointment.schAppointmentType != null && this.appointment.schAppointmentType != undefined) {
                if (this.appointment.schAppointmentType.toLowerCase() == 'Telehealth'.toLowerCase()) {
                  if (this.remoteAppointmentTypeResponseModel != null && this.remoteAppointmentTypeResponseModel != undefined) {
                    if (this.remoteAppointmentTypeResponseModel.length > 0) {
                      if (this.appointment.schRemoteAppointmentType != null && this.appointment.schRemoteAppointmentType != undefined) {
                        if (this.appointment.schRemoteAppointmentType.toLowerCase() == 'Telemed'.toLowerCase()) {




                          let OnlineMeeting = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'OnlineMeeting' && x.isActive == true)

                          if (OnlineMeeting) {
                            this.convertedRemoteAppointmentType = OnlineMeeting.crmRemoteAppointmentTitle;

                            if (this.appointment.schWIPStatus.toLowerCase() == 'booked') {
                              this.isChangeRemoteAppointmentType = true;
                            } else {
                              this.isChangeRemoteAppointmentType = false;
                            }
                          }
                          else
                            this.isChangeRemoteAppointmentType = false;

                        }
                        else if (this.appointment.schRemoteAppointmentType.toLowerCase() == 'OnlineMeeting'.toLowerCase()) {
                          let telemed = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'Telemed' && x.isActive == true)
                          if (telemed) {
                            this.convertedRemoteAppointmentType = telemed.crmRemoteAppointmentTitle;

                            if (this.appointment.schWIPStatus.toLowerCase() == 'booked') {
                              this.isChangeRemoteAppointmentType = true;
                            } else {
                              this.isChangeRemoteAppointmentType = false;
                            }
                          }
                          else
                            this.isChangeRemoteAppointmentType = false;
                        }
                      }
                    }
                  }
                }
              }

              if (!this.appointment.crmBranchIsAllowSelfScheduleAppointments) {
                this.isAllowToSendInvite = false;
              }
              else if (this.salesOrder.crmSalesorderStatus.toLowerCase() != 'complete' && this.salesOrder.crmSalesorderStatus.toLowerCase() != 'void') {
                this.isAllowToSendInvite = true;
              }

            }
          }

          this.loading = false;
          this.isDataLoaded = true;
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.isDataLoaded = true;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  convertAppointmentType() {
    let appointment = this.appointment;
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to change appointment type from ' + this.appointment.schRemoteAppointmentTypeDisplayName + ' to ' + this.convertedRemoteAppointmentType + '?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }
      this.loading = true;
      this.appointmentService.ConvertRemoteAppointment(appointment.schAppointmentId, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('Appointment type has been changed successfully.');
            this.getAppointmentInfo(this.appointmentId);
          });

        }, (httpResponseError) => {

          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  RefreshAppointmentPatientInfo() {
    //this.isDataLoaded = false;
    //this.progress.next(true);
    // this.appointmentService.RefreshAppointmentPatientInfo(parseInt(this.appointmentId))
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    //   .toPromise()
    //   .then((result) => {
    //     this.processResult<RefreshPatientInfoResponseModel>(result, () => {
    //       if (result.isSuccess) {
    //         if (result.responseModel != undefined && result.responseModel != null) {
    //           this.refreshPatientInfoResponseModel = result.responseModel;
    //           this.isAppointment = true;
    //           this.salesOrder = this.appointment.salesOrderInfo;
    //           if (this.refreshPatientInfoResponseModel.crmPatientEmailAddress) {
    //             this.patientEmail = this.refreshPatientInfoResponseModel.crmPatientEmailAddress;
    //           }
    //           else {
    //             this.patientEmail = "-";
    //           }
    //           if (this.refreshPatientInfoResponseModel.crmPatientPhoneNo) {
    //             this.patientPhone = this.refreshPatientInfoResponseModel.crmPatientPhoneNo;
    //           }
    //           else {
    //             this.patientPhone = "";
    //           }

    //           if (this.refreshPatientInfoResponseModel.crmPatientMobileNumber) {
    //             this.patientMobileNumber = this.refreshPatientInfoResponseModel.crmPatientMobileNumber;
    //           }
    //           else {
    //             this.patientMobileNumber = "";
    //           }


    //           this.crmPatientPhoneNo = this.refreshPatientInfoResponseModel.crmPatientPhoneNo;



    //           this.appointmentRequestBT.OrderNote = this.refreshPatientInfoResponseModel.crmSalesorderOrderNote;
    //           this.appointmentRequestBT.DeliveryNote = this.refreshPatientInfoResponseModel.crmSalesorderDeliveryNote;
    //         }
    //       }
    //       //this.loading = false;
    //       //this.isDataLoaded = true;
    //     });
    //   }).catch((httpResponseError) => {
    //     //this.loading = false;
    //     //this.isDataLoaded = true;
    //     this.showErrorMessage(httpResponseError.message);
    //   });

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update the latest information from Brightree?',
        message1: 'If you click on "Yes" button, then all the information will be updated as per the Brightree database.',
      },
      header: 'Sync Details from Brightree'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.loading = true;
      this.importExportService.RefreshSalesOrderById(this.salesOrder.crmSalesorderAppId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<RefreshPatientInfoResponseModel>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.refreshPatientInfoResponseModel = result.responseModel;

                if (this.refreshPatientInfoResponseModel.isSuccess) {
                  this.getAppointmentInfo(this.appointmentId);
                }
                else {
                  let status = this.refreshPatientInfoResponseModel.status;

                  if (status && status == "Already Exist") {
                    this.showWarningMessage("Sales order already exist");
                  }
                  if (status && status == "Error") {
                    this.showErrorMessage("Error occurred while syncing details from Brightree. ");
                  }
                  if (status && status == "Not Found") {
                    this.showWarningMessage("Sales order not found in Brightree.");
                  }

                }
                this.loading = false;
              }
              else
                this.loading = false;
            }
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          //this.isDataLoaded = true;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }


  RefreshAppointmentHistory() {
    //this.loading = true;
    //this.isDataLoaded = false;
    //this.progress.next(true);
    this.appointmentService.RefreshAppointmentHistory(parseInt(this.appointmentId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.appointment.appointmentHistoryInfo = result.responseModel;
            }
          }
          //this.loading = false;
          //this.isDataLoaded = true;
        });
      }).catch((httpResponseError) => {
        //this.loading = false;
        //this.isDataLoaded = true;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private GetNotificationSMSLogsBySalesOrderId(SalesOrderId) {

    this.loadingEmail = true;
    //this.smsdisplay = true;
    this.appointmentService.GetNotificationSMSLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentSMSHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentSMSHistoryResponseModel = result.responseModel;
              this.smsLogCount = this.appointmentSMSHistoryResponseModel.filter(x => !x.isSMSSubscribe).length;
              if (this.appointmentSMSHistoryResponseModel.length > 0)
                // this.smsdisplay = true;
                this.SMSSidebarDisplay = true;
              else {
                // this.smsdisplay = false;
                this.SMSSidebarDisplay = false;
                this.loadingEmail = false;
                this.showInfoMessage("No record found");
              }
            }

          }
          this.loadingEmail = false;

        });
      }).catch((httpResponseError) => {
        this.loadingEmail = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private GetNotificationEmailLogsBySalesOrderId(SalesOrderId) {
    // this.emaildisplay = true;
    this.loadingEmail = true;

    this.appointmentService.GetNotificationEmailLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentEmailHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentEmailHistoryResponseModel = result.responseModel;
              this.emailLogCount = this.appointmentEmailHistoryResponseModel.filter(x => !x.isEmailSubscribe).length;
              if (this.appointmentEmailHistoryResponseModel.length > 0)
                // this.emaildisplay = true;
                this.EmailSidebarDisplay = true;
              else {
                // this.emaildisplay = false;
                this.EmailSidebarDisplay = false;
                this.loadingEmail = false;
                this.showInfoMessage("No record found");
              }
            }
          }
          this.loadingEmail = false;

        });
      }).catch((httpResponseError) => {
        this.loading = false;

        this.showErrorMessage(httpResponseError.message);
      });
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  Collapse(index: number) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  onPreferenceChangeSMS(schIsReminderOptedSms: any) {
    this.appointment.schIsReminderOptedSms = schIsReminderOptedSms;

  }

  onPreferenceChangeEmail(schIsReminderOptedEmail: any) {
    this.appointment.schIsReminderOptedEmail = schIsReminderOptedEmail;
  }

  getPluginConfiguration() {
    this.loading = true;
    this.settingsService.getPluginConfiguration(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = JSON.parse(result.responseModel.appPluginSettingsValue);
              this.patientBrighttreeURL = jsonData.PatientBrighttreeURL;
              this.salesOrderBrighttreeURL = jsonData.SalesOrderBrighttreeURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }
  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }


        });
      }, (httpResponseError) => {
        console.error(httpResponseError.message);
        this.showErrorMessage(httpResponseError.message);
      });

  }
  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {

    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {

    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(patienKeyUrl, '_blank')
  }


  smsDialog() {
    this.GetNotificationSMSLogsBySalesOrderId(this.appointment.crmSalesorderId);
  }

  emailDialog() {
    this.GetNotificationEmailLogsBySalesOrderId(this.appointment.crmSalesorderId);
  }

  SMSSidebar() {
    //this.SMSSidebarDisplay = true;
    this.GetNotificationSMSLogsBySalesOrderId(this.appointment.crmSalesorderId);
  }

  EmailSidebar() {
    //this.EmailSidebarDisplay = true;
    this.GetNotificationEmailLogsBySalesOrderId(this.appointment.crmSalesorderId);
  }

  EditClassificationType() {

    this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
    console.log(this.selectedClassificationType);
  }

  cancelClassificationType() {
    this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
    if (this.classificationType != null && this.classificationType != undefined) {
      this.selectedClassificationType = this.classificationType.find(x => x.crmClassificationTypeId == this.appointment.salesOrderInfo.crmSalesorderClassificationType);
    }

  }

  saveClassificationType() {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Classification?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      let settingsClassificationTypeResponseModel = new SettingsClassificationTypeResponseModel();
      settingsClassificationTypeResponseModel = this.selectedClassificationType
      this.appointmentService.updateClassificationType(this.appointment.crmSalesorderId.toString(), settingsClassificationTypeResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<number>) => {
          this.processResult<number>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.showSuccessMessage('Classification type has been changed successfully.');
                this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
                this.appointment.salesOrderInfo.crmSalesorderClassificationType = result.responseModel;
              }
            }
          });

        }, (httpResponseError) => {

          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    });


  }


  private getClassificationSettings() {
    this.loading = true;
    this.settingsService.GetClassificationTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.classificationType = result.responseModel.filter(x => x.crmClassificationDisplayName !== null && x.crmClassificationType != null);
              console.log(this.classificationType);
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onClassificationTypeChange(event) {
    this.selectedClassificationType = event.value;
    this.crmClassification = this.selectedClassificationType.crmClassificationDisplayName;
    //this.salesOrder.crmSalesorderClassificationType = this.selectedClassificationType.crmSalesorderClassificationType;
  }

  // HistoryDialog() {
  //   this.soHistorydisplay = true;
  // }

  HistoryEvent() {
    this.SoHistory = !this.SoHistory;
    this.soHistorydisplay = true;
  }
  HistoryEventClose() {
    this.SoHistory = !this.SoHistory;
    this.soHistorydisplay = false;
  }

  saleOrderExportHistory(saleorderId: string) {
    const formData = new FormData();
    formData.append('saleorderId', saleorderId.toString());
    this.loading = true;
    this.appointmentService.ExportSaleOrderHistroty(formData)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'SaleOrderHistory.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  SendInvitationforAppointment() {
    let salesOrderIds: any[];
    salesOrderIds = new Array()
    salesOrderIds.push(this.salesOrderId.toString());
    this.loading = true;
    this.appointmentService.SendInvitationForNotScheduled(salesOrderIds)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Invitation for appointment booking has been sent.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }



  HistoryPopup() {
    this.AppointmentHistoryPopup = true;
  }

  ResendSMS(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel) {
    this.loadingEmail = true;
    selectedAppointmentSMSHistoryResponseModel.appNotificationSmstoNumber = '3143383298';
    this.appointmentService.resendSMS(selectedAppointmentSMSHistoryResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.loadingEmail = false;
            this.showSuccessMessage('SMS has been sent.');
          }
        }
        else {
          this.loadingEmail = false;
        }
      });
  }

  ResendEmail(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel) {
    this.loadingEmail = true;
    selectedAppointmentEmailHistoryResponseModel.appNotificationEmailToEmail = 'anand.dhanani@analytix.com';
    this.appointmentService.resendEmail(selectedAppointmentEmailHistoryResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.loadingEmail = false;
            this.showSuccessMessage('Email has been sent.');
          }
        }
        else {
          this.loadingEmail = false;
        }
      });
  }
}

