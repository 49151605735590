export class TherapistFacilityResponseModel {

  crmTherapistFacilityId: number;
  crmTherapistId: number;
  crmFacilityId: number;
  crmTherapistFacilityIsAllowed: boolean;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}
