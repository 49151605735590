<div class="content-area">
  <div class="title-block">
    <h1>Assigned Calls</h1>

    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <div class="appointments-table-filer">
    <div class="appointments-table-filer__appit-legend">
      <ul>
        <li><span class="legend ready-for-scheduling"> Ready For Scheduling</span></li>
        <li><span class="legend upcoming"> Upcoming</span></li>
        <li><span class="legend no-show"> No Show</span></li>
        <li><span class="legend upcoming"> Complete </span></li>
        <li><span class="legend cancel"> Cancel</span></li>
      </ul>
    </div>

    <div class="appointments-table-filer__sort-by">
      <a href="javascript:void(0);" class="filter-show-hide" (click)="Op1.toggle($event)">
        <svg _ngcontent-nok-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle _ngcontent-nok-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nok-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nok-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>
      <p-overlayPanel #Op1 appendTo="body" class="search-filter">
        <div class="quick-link-menu">
          <ul>
            <li *ngIf="!autoCall">
              <a class="invite-schedule" href="javascript:void(0)" (click)="AutoCalls('S')">
                Start
                Auto Call
              </a>
            </li>
            <li *ngIf="autoCall">
              <a class="invite-schedule" href="javascript:void(0)" (click)="StopCall()">
                Stop Auto
                Call
              </a>
            </li>
            <li>
              <a class="invite-schedule" href="javascript:void(0)" (click)="UnLockAppoinments()">Unassign Calls</a>
            </li>
          </ul>
        </div>
      </p-overlayPanel>

      <a href="javascript:void(0);" class="filter-block filter-block filter-show-hide"
         (click)="tableFilterScheduled.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>

      <p-overlayPanel #tableFilterScheduled class="search-filter" [dismissable]="true" [showCloseIcon]="true">

        <div class="search-box-outer appit-shheduled-search">
          <h2 class="filter-title">Filters</h2>

          <div class="from__group full-width">
            <label class="form__label">Search </label>
            <div class="search-box">
              <input type="text" class="form__control" pInputText placeholder="S/O ID, Patient & DOB" #searchBox
                     style="width:auto" (keyup.enter)="onSearchButtonClick()">

              <button class="search-btn " pButton type="button" (click)="onSearchButtonClick()">
                <i class="search-new"></i>
              </button>
            </div>
          </div>

          <div class="btn-block">
            <button class="btn btn--primary">Search</button>
            <a class="btn btn--secondary clear-filter" href="javascript:void(0)">
              Clear
            </a>
          </div>

        </div>
      </p-overlayPanel>

    </div>
  </div>

  <!-- page-scroll appointments-list add-table-column-->
  <div class="page-scroll appointments-list assigned-calls-table">
    <!--<p-scrollPanel [style]="{width: '100%', height: '100%'}">-->
    <p-table #table class="table-outer appt-not-scheduled" sortMode="single" [sortOrder]="1" sortField="crmPatientName"
              responsiveLayout="scroll" [paginator]="totalRecords>0?true:false" [rows]="getTotalRows()" [totalRecords]="totalRecords"
             [lazy]="true" [customSort]="true" [first]="searchRequestModel?.FirstRecordIndex" [value]="salesOrders"
             (onLazyLoad)="onLazyLoad($event)" [(selection)]="selectedsalesOrders" (selectionChange)="onSelectionChange()"
             selectionMode="" [columns]="cols" [scrollable]="true" scrollHeight="calc(100vh - 297px)" dataKey="crmSalesOrderId"
             scrollDirection="both">


      <ng-template pTemplate="header" let-columns>
        <tr *ngIf="totalRecords > 0">
          <th class="table-outer__th appt-action-column" pFrozenColumn></th>
          <th class="table-outer__th appt-so-id" pFrozenColumn>
            <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
            <span class="header-soid__name">S/O ID</span>
          </th>
          <th class="table-outer__th appt-patient-name" pFrozenColumn pSortableColumn="crmPatientName">
            Patient
            details
            <p-sortIcon field="crmPatientName"></p-sortIcon>
          </th>
          <th class="table-outer__th" pFrozenColumn pSortableColumn="crmClassification">
            Classification Type
            <p-sortIcon field="crmClassification"></p-sortIcon>
          </th>
          <th class="table-outer__th appt-status" pFrozenColumn pSortableColumn="crmSalesorderStatus">
            Status
            <p-sortIcon field="crmSalesorderStatus"></p-sortIcon>
          </th>

          <th class="table-outer__th" pSortableColumn="crmDoctorName">
            Doctor
            <p-sortIcon field="crmDoctorName"></p-sortIcon>
          </th>
          <th class="table-outer__th" pSortableColumn="crmBranch">
            Branch
            <p-sortIcon field="crmBranch"></p-sortIcon>
          </th>
          <th class="table-outer__th" pSortableColumn="SchAppointmentCount">
            No Of Calls
            <p-sortIcon field="SchAppointmentCount"></p-sortIcon>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-salesorder let-columns="columns">
        <tr>
          <td class="table-outer__td appt-action-column" pFrozenColumn>
            <a class="actionmenu-btn" href="javascript:void(0)"
               (click)="selectNonScheduleSalesOrder($event,salesorder,NotScheduledAction)">
              <svg id="Layer_1" class="appt-action-icon" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 29.96 122.88">
                <path class="cls-1"
                      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Zm0,92.93a15,15,0,1,1-15,15,15,15,0,0,1,15-15Zm0-46.47a15,15,0,1,1-15,15,15,15,0,0,1,15-15Z" />
              </svg>
            </a>

            <p-overlayPanel class="table-action-popup NotScheduledAction appointment-Action" #NotScheduledAction>
              <ul class="quick-link-menu">
                <li *ngIf="isAppointmentScheduledPermission && salesorder.crmSalesorderStatus != 'No Show'&& salesorder.crmSalesorderStatus != 'Cancel'">
                  <a href="javascript:void(0)"
                     (click)="showDialogSchedule(selectedSalesOrder.crmSalesOrderId,selectedSalesOrder.schAppointmentCallListId)">
                    <svg version="1.1" id="Capa_1" fill="#6F64A7" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.867 477.867"
                         style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                    <g>
                    <g>
                    <path d="M426.667,51.2h-51.2V17.067C375.467,7.641,367.826,0,358.4,0s-17.067,7.641-17.067,17.067V51.2h-204.8V17.067
                                C136.533,7.641,128.892,0,119.467,0S102.4,7.641,102.4,17.067V51.2H51.2C22.923,51.2,0,74.123,0,102.4v324.267
                                c0,28.277,22.923,51.2,51.2,51.2h375.467c28.277,0,51.2-22.923,51.2-51.2V102.4C477.867,74.123,454.944,51.2,426.667,51.2z
                                M443.733,426.667c0,9.426-7.641,17.067-17.067,17.067H51.2c-9.426,0-17.067-7.641-17.067-17.067V204.8h409.6V426.667z
                                M443.733,170.667h-409.6V102.4c0-9.426,7.641-17.067,17.067-17.067h51.2v34.133c0,9.426,7.641,17.067,17.067,17.067
                                s17.067-7.641,17.067-17.067V85.333h204.8v34.133c0,9.426,7.641,17.067,17.067,17.067s17.067-7.641,17.067-17.067V85.333h51.2
                                c9.426,0,17.067,7.641,17.067,17.067V170.667z" />
                        </g>
                      </g>
                    <g>
                    <g>
                    <path d="M352.98,243.934c-6.614-6.388-17.099-6.388-23.712,0L204.8,368.401l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132,0.419
                                c-6.388,6.614-6.388,17.099,0,23.713l68.267,68.267c6.665,6.662,17.468,6.662,24.132,0l136.533-136.533
                                C359.947,261.286,359.759,250.482,352.98,243.934z" />
                        </g>
                      </g>
                    </svg>
                    Schedule
                  </a>
                </li>
                <li *ngIf="isAppointmentScheduledPermission && salesorder.crmSalesorderStatus == 'No Show' || salesorder.crmSalesorderStatus == 'Cancel'">
                  <a href="javascript:void(0)"
                     (click)="showAppointmentDialog(selectedSalesOrder.crmSalesOrderId,selectedSalesOrder.schAppointmentId,selectedSalesOrder.schAppointmentCallListId)">
                    <svg version="1.1" id="Capa_1" fill="#6F64A7" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.867 477.867"
                         style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
                    <g>
                    <g>
                    <path d="M426.667,51.2h-51.2V17.067C375.467,7.641,367.826,0,358.4,0s-17.067,7.641-17.067,17.067V51.2h-204.8V17.067
                                    C136.533,7.641,128.892,0,119.467,0S102.4,7.641,102.4,17.067V51.2H51.2C22.923,51.2,0,74.123,0,102.4v324.267
                                    c0,28.277,22.923,51.2,51.2,51.2h375.467c28.277,0,51.2-22.923,51.2-51.2V102.4C477.867,74.123,454.944,51.2,426.667,51.2z
                                    M443.733,426.667c0,9.426-7.641,17.067-17.067,17.067H51.2c-9.426,0-17.067-7.641-17.067-17.067V204.8h409.6V426.667z
                                    M443.733,170.667h-409.6V102.4c0-9.426,7.641-17.067,17.067-17.067h51.2v34.133c0,9.426,7.641,17.067,17.067,17.067
                                    s17.067-7.641,17.067-17.067V85.333h204.8v34.133c0,9.426,7.641,17.067,17.067,17.067s17.067-7.641,17.067-17.067V85.333h51.2
                                    c9.426,0,17.067,7.641,17.067,17.067V170.667z" />
                        </g>
                      </g>
                    <g>
                    <g>
                    <path d="M352.98,243.934c-6.614-6.388-17.099-6.388-23.712,0L204.8,368.401l-56.201-56.201c-6.78-6.548-17.584-6.36-24.132,0.419
                                    c-6.388,6.614-6.388,17.099,0,23.713l68.267,68.267c6.665,6.662,17.468,6.662,24.132,0l136.533-136.533
                                    C359.947,261.286,359.759,250.482,352.98,243.934z" />
                        </g>
                      </g>
                    </svg>
                    Reschedule
                  </a>
                </li>
                <li *ngIf="selectedSalesOrder.isScheduled">
                  <a href="javascript:void(0)" (click)="showVoidAppointment('P',selectedSalesOrder.schAppointmentId)">
                    <svg id="void-ic-new" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                      <path id="Path_1" data-name="Path 1"
                            d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM4,24A20,20,0,0,1,36.755,8.6L8.6,36.755A19.914,19.914,0,0,1,4,24ZM24,44a19.911,19.911,0,0,1-12.552-4.439L39.56,11.45A19.989,19.989,0,0,1,24,44Z"
                            fill="#6f64a7" />
                    </svg>
                    Void
                  </a>
                </li>
                <li *ngIf="!selectedSalesOrder.isScheduled">
                  <a href="javascript:void(0)" (click)="showVoidSalesOrder('P',selectedSalesOrder.crmSalesOrderId)">
                    <svg id="void-ic-new" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                      <path id="Path_1" data-name="Path 1"
                            d="M24,0A24,24,0,1,0,48,24,24,24,0,0,0,24,0ZM4,24A20,20,0,0,1,36.755,8.6L8.6,36.755A19.914,19.914,0,0,1,4,24ZM24,44a19.911,19.911,0,0,1-12.552-4.439L39.56,11.45A19.989,19.989,0,0,1,24,44Z"
                            fill="#6f64a7" />
                    </svg>
                    Void
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)"
                     (click)="showDialog(selectedSalesOrder.crmSalesOrderId,selectedSalesOrder.schAppointmentCallListId)">
                    <svg version="1.1" id="Capa_1" fill="#6F64A7" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 473.806 473.806"
                         style="enable-background:new 0 0 473.806 473.806;" xml:space="preserve">
                    <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                                c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                                c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                                c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                                c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                                c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                                c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                                C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                                c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                                c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                                c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                                c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                                c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                                c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                                l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                                C420.456,377.706,420.456,388.206,410.256,398.806z" />
                    <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
                                c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
                                S248.656,111.506,256.056,112.706z" />
                    <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
                                c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
                                c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z" />
                    </svg>
                    Call
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" (click)="UnLockAppoinment(selectedSalesOrder)">
                    <svg id="_x31__x2C_5" fill="#6F64A7" enable-background="new 0 0 24 24" height="512"
                         viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                      <path d="m18.75 24h-13.5c-1.24 0-2.25-1.009-2.25-2.25v-10.5c0-1.241 1.01-2.25 2.25-2.25h13.5c1.24 0 2.25 1.009 2.25 2.25v10.5c0 1.241-1.01 2.25-2.25 2.25zm-13.5-13.5c-.413 0-.75.336-.75.75v10.5c0 .414.337.75.75.75h13.5c.413 0 .75-.336.75-.75v-10.5c0-.414-.337-.75-.75-.75z" />
                      <path d="m17.25 10.5c-.414 0-.75-.336-.75-.75v-3.75c0-2.481-2.019-4.5-4.5-4.5s-4.5 2.019-4.5 4.5v3.75c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-3.75c0-3.309 2.691-6 6-6s6 2.691 6 6v3.75c0 .414-.336.75-.75.75z" />
                      <path d="m12 17c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-2.5c-.275 0-.5.224-.5.5s.225.5.5.5.5-.224.5-.5-.225-.5-.5-.5z" />
                      <path d="m12 20c-.414 0-.75-.336-.75-.75v-2.75c0-.414.336-.75.75-.75s.75.336.75.75v2.75c0 .414-.336.75-.75.75z" />
                    </svg>
                    Unlock
                  </a>
                </li>
              </ul>
            </p-overlayPanel>

          </td>
          <td class="table-outer__td appt-so-id" pFrozenColumn>
            <span class="table-checkbox">
              <p-tableCheckbox [value]="salesorder"></p-tableCheckbox>
              <span class="text-ellipsis" pTooltip="{{salesorder.crmSalesOrderAPPId}}"
                    tooltipPosition="top">{{salesorder.crmSalesOrderAPPId}}</span>
            </span>

          </td>
          <td class="table-outer__td appt-patient-name" pFrozenColumn>
            <span class="patient">
              <a class="text-ellipsis cursor-pointer-none" href="javascript:void(0)"
                 pTooltip="{{salesorder.crmPatientName}}" tooltipPosition="top">
                {{salesorder.crmPatientName}}
              </a>
              <span class="bright-tree-patient-url">
                {{salesorder['crmPatientId']}} | {{salesorder['crmPatientBirthdate'] | date:appDateFormat.dateFormat}}
              </span>
            </span>

          </td>
          <td class="table-outer__td" pFrozenColumn>
            <span class="text-ellipsis"
                  pTooltip="{{salesorder.crmClassification}}">{{salesorder.crmClassification}}</span>
          </td>

          <td class="table-outer__td appt-status" pFrozenColumn>
            <p [ngClass]="salesorder.crmSalesorderStatus=='Cancel'?'cancel':salesorder.crmSalesorderStatus=='No Show'?'no-show':'ready-for-scheduling'"
               class="status-block-box">
            </p>
          </td>
          <td class="table-outer__td">
            <span class="text-ellipsis" pTooltip="{{salesorder.crmBranch}}">{{salesorder.crmDoctorName}}</span>
          </td>

          <td class="table-outer__td">
            <span class="text-ellipsis" pTooltip="{{salesorder.crmBranch}}">{{salesorder.crmBranch}}</span>
          </td>

          <td class="table-outer__td">
            {{salesorder.SchAppointmentCount}}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorleft" class="table-pagination">
        <span class="table-pagination__records">
          Total Records : &nbsp;<strong> {{totalRecords}}</strong>
        </span>
      </ng-template>

    </p-table>
    <!--</p-scrollPanel>-->

    <div class="no-records-found-appointment" *ngIf=" !loading && !dataExpressionloading && totalRecords <= 0"
         [attr.colspan]="7">
      No records found
    </div>
  </div>

  <p-dialog [(visible)]="displayVoidAppointment" styleClass="display-close-icon" [modal]="true" appendTo="body">
    <div class="popup-content-outer cancel-appointment">
      <h2>Void Appointment</h2>
      <div class="schedulecall">
        <form class="form" [formGroup]="formVoidAppointment" autocomplete="off">
          <div class="schedulecall">

            <div class="col">
              <div class="form__group full-width">
                <label class="form__label">Reason</label>
                <p-dropdown class="select-box" [options]="voidReasonsResponseModel" formControlName="voidReason"
                            placeholder="Select Reason" optionLabel="salesOrderVoidReason" [showClear]="true"></p-dropdown>
                <div *ngIf="formVoidAppointmentSubmitted && fVoid.voidReason.errors" class="invalid-feedback-error">
                  <span *ngIf="fVoid.voidReason.errors.required || fVoid.voidReason.errors.whitespace">
                    {{validationMessages.voidReason.required}}
                  </span>

                </div>
              </div>
            </div>
            <div class="col">
              <div class="form__group full-width">
                <label class="form__label">Comment</label>
                <textarea class="form__textarea" pInputTextarea formControlName="voidReasonComments"
                          maxlength="100"></textarea>
                <div *ngIf="formVoidAppointmentSubmitted && fVoid.voidReasonComments.errors"
                     class="invalid-feedback-error">
                  <span *ngIf="fVoid.voidReasonComments.errors.required || fVoid.voidReasonComments.errors.whitespace">
                    {{validationMessages.voidReasonComments.required}}
                  </span>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="check receive-reminder wip-state-will-not-update">
                <p-checkbox name="groupnamevoid" label="If box checked, the WIP State will not be updated in Brightree"
                            [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)" [ngModelOptions]="{standalone: true}">
                </p-checkbox>
              </div>
            </div>
            <div class="call-popup-appointment">
              <button class="btn btn--primary" (click)="voidAppointment(voidFrom)">Submit</button>
              <a href="javascript:void(0)" class="btn btn--secondary" (click)="cancelVoidAppointment()">Cancel</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </p-dialog>

  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
  <div class="loader-block" *ngIf="customloading" style="z-index: 99999999 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</div>
<router-outlet></router-outlet>
