import { Component, OnInit, Inject, LOCALE_ID, ViewChild, ChangeDetectionStrategy, Input, HostListener } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DAYS_IN_WEEK, SchedulerViewDay, SchedulerViewHour, SchedulerViewHourSegment, CalendarSchedulerEvent, SchedulerDateFormatter, SchedulerEventTimesChangedEvent, CalendarSchedulerViewComponent } from 'angular-calendar-scheduler';
import { CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { TheraistAppointmentService } from 'src/app/therapist/services/theraistappointment.service';
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { map, takeUntil, finalize } from 'rxjs/operators';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { Constants } from 'src/app/shared/constants';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { EventAction } from 'calendar-utils';
import { Accordion } from 'primeng/accordion';
import { TimezonePipe } from 'src/app/pipes/timezone.pipe';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';


const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  //blue: {
  //  primary: '#1e90ff',
  //  secondary: '#D1E8FF',
  //},
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  purple: {
    primary: '#e3deff',
    secondary: '#e3deff',
  },
  blue: {
    primary: '#EDEAFF',
    secondary: '#EDEAFF',
  },
  grey: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  lightGrey: {
    primary: '#dbdbdb',
    secondary: '#dbdbdb',
  },
  darkGrey: {
    primary: '#cbcbcb',
    secondary: '#cbcbcb',
  },
  transparent: {
    primary: '#ffffff00',
    secondary: '#ffffff00',
  },
  orange: {
    primary: '#F9F9F9',
    secondary: '#F9F9F9',
  },
  blokSlot: {
    primary: '#d1e8ff',
    secondary: '#d1e8ff',
  },
};


@Component({
  selector: 'app-week-view-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './week-view-calendar.component.html',
  styleUrls: ['./week-view-calendar.component.sass'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: SchedulerDateFormatter
    },
    DialogService]
})


export class WeekViewCalendarComponent extends BaseComponent implements OnInit {
  @Input() weekStartsOn: number = DAYS_OF_WEEK.SUNDAY;
  schedulerList: SchedulerResponseModel[];
  appointmentInfoReponseModel: SchedulerResponseModel;
  appointment: AppointmentInfoReponseModel;
  duration: string;
  comments: string;
  wipStatus: any;
  wipStatesId: number;
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderStatusCurrent: WIPStatesResponseModel[];
  salesOrderType: string = 'AppointmentRequest';
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  brightreeInternalUserId: number;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  _patientTimezone: string = '';
  CalendarView = CalendarView;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  locale: string = 'en';
  hourSegments: number = 2;
  weekStarts: number = DAYS_OF_WEEK.SUNDAY;
  startsWithToday: boolean = false;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = [];
  weekendDays: number[] = [0, 6];
  dayStartHour: number = 7;
  dayEndHour: number = 19;

  // minDate: Date = new Date();
  // maxDate: Date = endOfDay(addMonths(new Date(), 1));

  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;

  prevBtnDisabled: boolean = true;
  nextBtnDisabled: boolean = true;

  therapistId: number;
  therapistName: string;
  appDateFormat: DateFormatModel;
  selectedDayViewDate: Date;
  en: any;
  currentSelectedView: any;
  dateFormat: string = '';
  primeFormat: string = '';
  timeZone: string = '';
  Customloading: boolean = false;
  isShowModel: boolean = false;
  isShowStatusDropdown: boolean = false;
  canStatusIsUpate: boolean = false;
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  twoFacilityPopup: boolean;
  activeState: boolean[] = [true, false, false, false];
  facilityTimeZone: string = '';
  weekStartDate: string;
  weekEndDate: string;
  currentYear: string;
  isGoogleMap: boolean = false;
  myOptions = {
    'placement': 'right',
    'show-delay': 500
  };

  actions: EventAction[] = [
    {
      label: '<span class="valign-center edit-btn-block"><i class="edit-icon-new"></i></span>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.editEvent(Number(event.id));
      },
    }
  ];

  events: CalendarEvent[];

  @ViewChild('calendarScheduler', { static: false }) calendarScheduler: CalendarSchedulerViewComponent;
  @ViewChild('accordion') accordion: Accordion;

  timeZoneList: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string };

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  sneakPeak: boolean;

  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(event.target.innerWidth <= 767 && this.sneakPeak) {
      this.router.navigate(['/my-appointments-new']);
    }
  }


  constructor(
    private webStorageService: WebStorageService,
    private router: Router,
    messageService: MessageService,
    private titleService: Title,
    private theraistAppointmentService: TheraistAppointmentService,
    @Inject(LOCALE_ID) locale: string,
    private featureGroupService: FeatureGroupService,
  ) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;

    this.appDateFormat = this.webStorageService.getDateFormat();
    this.loading = false;
    this.titleService.setTitle("Appoint360");

    this.locale = locale;

    this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
      segment.isDisabled = false;
      /*segment.backgroundColor = "#fff";*/
    }).bind(this);

    this.eventModifier = ((event: CalendarSchedulerEvent): void => {
      event.isDisabled = false;
    }).bind(this);

    this.calendarActions = [
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'View By Week', code: 'ViewByWeek' }
    ];

    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
  }

  setView(view: CalendarView) {
    this.view = view;
  }


  ngOnInit(): void {
    this.timeZoneList = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST', orgcode: 'UTC+5', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST', orgcode: 'UTC-5', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST', orgcode: 'UTC-6', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MST', orgcode: 'UTC-7', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST', orgcode: 'UTC-8', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.selectedcalendarActions = this.calendarActions.find(x => x.code == 'ViewByWeek');

    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: this.appDateFormat.dateFormat,
      weekHeader: 'Wk'
    };

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.therapistName = currentUserToken.userFullName;
      this.facilityTimeZone = currentUserToken.crmTimeZone;
    }

    else {
      this.therapistId = 0;
      this.therapistName = '';
      this.facilityTimeZone = 'EST';
    }

    this.events = [];
    this.getGlobalTimeZone();
    this.getSalesOrderStatus();
    this.getWipStatusBTmapping();
    this.GetRCMConfigurationUrl();
    //this.fetchEvents();

    let currentDate = new Date(this.weekcurrentDate.replace(/-/g, "/"));
    let weekStart = moment(currentDate).startOf('week').toDate(); 
    let weekEnd = moment(currentDate).endOf('week').toDate();
    this.weekStartDate = moment(weekStart).format("MMMM DD");
    this.weekEndDate = moment(weekEnd).format("MMMM DD");
    this.currentYear = moment(weekEnd).format('YYYY');
  }

  private async getGlobalTimeZone() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.TimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.selectedTimeZone = this.timeZoneList.find(x => x.code == result.responseModel.schSettingValue);
              document.getElementsByClassName("material-icons md-32")[0].innerHTML = this.facilityTimeZone;
            }
          }
        });
      });
  }

  private async getSalesOrderStatus() {
    this.loading = true;
    await this.theraistAppointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          this.salesOrderStatus = result.responseModel;

          //this.salesOrderStatusCurrent = this.salesOrderStatus.
          //  filter(x => x.wipStatesName.toLowerCase() != 'booked'
          //    && x.wipStatesName.toLowerCase() != 'void'
          //    && x.wipStatesName.toLowerCase() != 'unreconcile');
          this.salesOrderStatusCurrent = this.salesOrderStatus.
            filter(x => x.wipStatesName.toLowerCase() == 'complete'
              || x.wipStatesName.toLowerCase() == 'no show'
            );
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private GetRCMConfigurationUrl() {
    this.loading = true;
    this.featureGroupService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.responseModel) {
            let jsonData = result.responseModel;
            this.patientBrighttreeURL = jsonData.patientRCMURL;
            this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            this.fetchEvents();
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async getWipStatusBTmapping() {
    await this.featureGroupService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatusBTMappingResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async fetchEvents() {
    this.events = [];
    let lst: SchedulerResponseModel[] = [];

    await this.theraistAppointmentService.GetWeekWiseAppointmentsOfTherapists(this.therapistId, this.weekcurrentDate)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            this.schedulerList = result.responseModel;
            lst = result.responseModel;
            this.SetMinMaxHourForCalander(lst);
            this.FillEvents(lst);
          }
        });
        this.refresh.next();
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  FillEvents(lst: SchedulerResponseModel[]): void {
    let lstCalendarSchedulerEvent: CalendarEvent[] = [];
    if (lst.length > 0) {
      lst.forEach(obj => {
        let is15min: boolean = false;
        var then = moment(obj.start);
        var now = moment(obj.end);
        let Duration = moment.duration(now.diff(then));
        let diff = now.diff(then, 'minutes')
        if (Number(diff) <= 15) {
          is15min = true;
        }

        obj.is15minSlot = is15min;
        let eventObject = obj;
        eventObject.startLabel = moment(obj.startLabel, 'YYYY-MM-DD HH:mm').toDate();
        eventObject.endLabel = moment(obj.endLabel, 'YYYY-MM-DD HH:mm').toDate();
        var duration = moment.duration(moment(obj.endLabel).diff(moment(obj.startLabel)));
        let durationMin = duration.asMinutes();
        eventObject.duration = duration.asMinutes().toString();
        if (durationMin == 15) {
          eventObject.className = 'fifteen-mintues'
        }
        else if (durationMin == 30) {
          eventObject.className = 'thirty-mintues'
        }
        else if (durationMin == 45) {
          eventObject.className = 'forty-five-mintues'
        }
        else if (durationMin == 60) {
          eventObject.className = 'sixty-mintues'
        }
        else if (durationMin == 75) {
          eventObject.className = 'seventy-five-minutes'
        }
        else if (durationMin == 90) {
          eventObject.className = 'ninety-minutes'
        }
        else if (durationMin == 105) {
          eventObject.className = 'one-hundred-five-minutes'
        }
        else if (durationMin == 120) {
          eventObject.className = 'one-hundred-twenty-minutes'
        }
        //let duration = moment(obj.startLabel).diff(moment(obj.endLabel), 'minutes');
        lstCalendarSchedulerEvent.push({
          id: String(obj.id),
          start: moment(obj.start, 'YYYY-MM-DD HH:mm').toDate(),
          end: moment(obj.end, 'YYYY-MM-DD HH:mm').toDate(),
          title: obj.title,
          meta: {
            eventObject,
          },
          color: obj.id == 0 ? colors.grey : obj.id == -1 ? colors.blokSlot : colors.blue,
          actions: Number(obj.id) > 0 ? this.actions : null,
        });

      });
    }
    if (lstCalendarSchedulerEvent.length > 0)
      this.events = lstCalendarSchedulerEvent;
  }

  SetMinMaxHourForCalander(lstScheduler: SchedulerResponseModel[]) {
    let StartHour: number = this.dayStartHour;
    let EndHour: number = this.dayEndHour;

    lstScheduler.forEach(function (item) {
      var _startTime = moment(item.start, 'YYYY-MM-DD HH:mm').toDate();
      var _endTime = moment(item.end, 'YYYY-MM-DD HH:mm').toDate();

      let _StartHour: number = _startTime.getHours();
      let _EndHour: number = _endTime.getHours();

      if (_StartHour < StartHour)
        StartHour = _StartHour;

      if (_EndHour > EndHour)
        EndHour = _EndHour;
    });


    if (StartHour < this.dayStartHour)
      this.dayStartHour = StartHour;

    if (EndHour > this.dayEndHour)
      this.dayEndHour = EndHour;

  }

  ngAfterViewInit(): void {
    //const elements = document.getElementsByClassName("material-icons md-32");
    //while (elements[0])
    //  elements[0].parentNode.removeChild(elements[0])
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
  }

  myAvailability() {
    this.router.navigate(['/therapist/my-availability']);
  }

  viewDaysOptionChanged(viewDays: number): void {
    this.calendarScheduler.setViewDays(viewDays);
  }

  changeDate(date: Date): void {
    this.viewDate = date;
    this.dateOrViewChanged();
  }

  changeView(view: CalendarView): void {
    this.view = view;
    this.dateOrViewChanged();
  }

  dateOrViewChanged(): void {
    this.fetchEvents();
  }

  dateChange(): void {
    var weekcurrentDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    let currentDate = new Date(this.weekcurrentDate.replace(/-/g, "/"));
    let weekStart = moment(currentDate).startOf('week').toDate(); 
    let weekEnd = moment(currentDate).endOf('week').toDate();
    this.weekStartDate = moment(weekStart).format("MMMM DD");
    this.weekEndDate = moment(weekEnd).format("MMMM DD");
    this.currentYear = moment(weekEnd).format('YYYY');
  }

  get weekcurrentDate(): string {
    return this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
  }

  // private isDateValid(date: Date): boolean {
  //   return date >= this.minDate && date <= this.maxDate;
  // }

  viewDaysChanged(viewDays: number): void {
    this.viewDays = viewDays;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
  }

  hourClicked(hour: SchedulerViewHour): void {
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {
  }

  eventTimesChanged({ event, newStart, newEnd, type }: SchedulerEventTimesChangedEvent): void {
    const ev: CalendarEvent = this.events.find(e => e.id === event.id);
    ev.start = newStart;
    ev.end = newEnd;
    this.refresh.next();
  }

  async editEvent(AppoinmentId: number) {
    this.canStatusIsUpate = false;
    if (AppoinmentId > 0) {
      this._patientTimezone = '';
      this.Customloading = true;
      await this.theraistAppointmentService.GetAppointmentById(AppoinmentId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.Customloading = false))
        .toPromise()
        .then((result) => {
          this.processResult<any>(result, () => {
            let apt = result.responseModel;
            this.appointment = apt;
            this.duration = this.appointment.schAppointmentDuration.toString() + " Minutes";
            this.wipStatus = this.appointment.schWIPStatus;
            this.comments = this.appointment.appointmentDetailInfo.schComments;
            if (this.appointment.appointmentDetailInfo.crmPatientTimeZone != undefined && this.appointment.appointmentDetailInfo.crmPatientTimeZone != '') {
              this._patientTimezone = new TimezonePipe(this.webStorageService).transform(this.appointment.appointmentDetailInfo.crmPatientTimeZone);
            }

            this.checkStatusDropdown();
            this.isShowModel = true;
            this.twoFacilityPopup = true;
          });
          this.refresh.next();
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  deleteEvent(event: CalendarSchedulerEvent): void {
    this.events = this.events.filter(item => item.id !== event.id);
  }

  closeSideBar() {
    this.isShowModel = false;
    this.twoFacilityPopup = false;
    this.currentSelectedView = [];
  }

  checkStatusDropdown() {
    if (this.wipStatus.toLowerCase() != 'booked' && this.wipStatus.toLowerCase() != 'void' && this.wipStatus.toLowerCase() != 'unreconcile')
      this.isShowStatusDropdown = false;
    else
      this.isShowStatusDropdown = true;
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(patienKeyUrl, '_blank')
  }

  changeWipStatus(event: any) {
    this.wipStatus = event.target.value;
    let result = [... this.salesOrderStatusCurrent.filter(item => item.wipStatesName === this.wipStatus)];
    if (result.length != null && result.length > 0) {
      this.wipStatesId = Number(result[0].wipStatesId);
      this.canStatusIsUpate = true;
    }
    else {
      this.wipStatesId = 0;
      this.canStatusIsUpate = false;
    }
  }

  UpdateStatusWithNote(schAppointmentId: number, CrmFacilityId: number) {
    if (this.wipStatus != "0" && this.canStatusIsUpate && this.isShowStatusDropdown) {
      this.onStatusSelection(schAppointmentId, CrmFacilityId);
      this.twoFacilityPopup = false;
      this.isShowModel = false;
      this.currentSelectedView = [];
    }
    else {
      if (this.comments != "") {
        this.onAppointmentComment(schAppointmentId);
        this.twoFacilityPopup = false;
        this.isShowModel = false;
        this.currentSelectedView = [];
      }
      else {
        this.showWarningMessage("Please set appointment note.");
      }
    }
  }

  async onStatusSelection(SchAppointmentId: number, CrmFacilityId: number) {
    let status = this.wipStatus;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    let resResult = false;
    let statusId = this.wipStatesId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = SchAppointmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = statusId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = CrmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.therapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    this.Customloading = true;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    await this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(true); this.Customloading = false; }))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            this.updateAppointmentComment(SchAppointmentId);
          }
        });
      }).catch((httpResponseError) => {
        this.Customloading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async onAppointmentComment(SchAppointmentId: number) {
    let AppoinmentId = SchAppointmentId;
    if (AppoinmentId > 0) {
      this.Customloading = true;
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
      appointmentUpdateStatusRequestModel.SchComments = this.comments;
      appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
      await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            if (result.responseModel) {
              this.showSuccessMessage("Appointment Note has been updated.");
              this.Customloading = false;
              this.refresh.next();
            }
          });
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  async updateAppointmentComment(SchAppointmentId: number) {
    let AppoinmentId = SchAppointmentId;
    if (AppoinmentId > 0) {
      this.Customloading = true;
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
      appointmentUpdateStatusRequestModel.SchComments = this.comments;
      await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            if (result.responseModel) {
              this.showSuccessMessage("Appointment Status and Note has been updated.");
              this.Customloading = false;
              this.refresh.next();
            }
          });
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  RedirectcalendarActions() {
    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByWeek")
      this.router.navigate(['/therapist/my-appointments-week']);
    else if (this.selectedcalendarActions.code == "ViewByDate")
      this.router.navigate(['/therapist/my-appointments']);
  }

  closePopup() {
    //console.log('calling on close');
    if (this.accordion && this.accordion.tabs) {
      this.accordion.tabs.forEach(tab => tab.selected = false);
    }
  }

  back(){
    if (this.isGoogleMap) {
      this.router.navigate(['/therapist/my-appointments-new']);
    }
    else {
      this.router.navigate(['/therapist/my-appointments']);
    }
  }


}
