import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';

export class AppointmentCancelRequest {
  schAppointmentId: number;
  schCancelReason: string;
  schComments: string;
  //voidFrom = A, To be voided
  //voidFrom = P, Permanant void
  voidFrom: string;
  crmSalesOrderId: number;
  modifiedByUserInfoId: number;
  SalesOrderStatus: string;
  isProvisional: boolean;
  isCancelledByPatient: boolean;
  salesOrderUpdateRequestModelBT: BTSalesOrderUpdateRequestModel;
}
