import { FacilityInfoResponseModel } from './../facility/facilityinfo-response-model'
import { TherapistInfoResponseModel } from './../therapist/therapistinfo-response-model'
export class AppointmentDetailResponseModel {
  schAppointmentDetailId: number;
  schAppointmentId: number;
  schComments: string;
  crmFacilityId: number;
  crmTherapistId: number;
  crmNewTherapistId: number;
  schAppointmentWipstatusId: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  schAppointmentDateStartTime: Date;
  schAppointmentDateEndTime: Date;
  facilityInfo: FacilityInfoResponseModel;
  therapistInfo: TherapistInfoResponseModel;
  crmFacilityTimeZone: string;
  crmPatientTimeZone: string;
  schSMSCommunicationType:string;
  schSMSCommunicationNumber:string;
  schCommunicationEmailAddress:string;

}
