<!-- Start Content -->
<div class="content-area therapist-profile">
  <div class="title-block">
    <h1>
      {{title}}
    </h1>

  </div>
  <app-profile class="therapist-profile-contact"></app-profile>
</div>
<!-- End Content -->



