import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Result, ResultOfT } from '../models/response/result';
import { UserResponseModel } from '../models/response/user-response-model';
import { WebStorageService } from './web-storage.service';
import { TimeZoneAvailableResponseModel } from '../models/response/time-zone-available-response-model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(httpClinet: HttpClient, webStorageService: WebStorageService) {
    super(httpClinet, webStorageService);
  }


  forgotPassword(email: string) {
    return this.get<Result>('Users/ForgotPassword/' + email);
  }

  setPassword(userResponseModel: UserResponseModel) {

    return this.post<Result>('Users/SetPassword/', userResponseModel);
  }

  /* validateOldPassword(userInfoId: number, password: string) {
    return this.get<Result>('Users/validateOldPassword/' + userInfoId + '/' + password);
  } */

  validateOldPassword(userResponseModel: UserResponseModel) {
    return this.post<Result>('Users/validateOldPassword/',userResponseModel);
  }

  changePassword(userResponseModel: UserResponseModel) {
    return this.post<Result>('Users/ChangePassword/', userResponseModel);
  }

  GetTimeZoneListAsync(){
    return this.get<ResultOfT<TimeZoneAvailableResponseModel[]>>('Users/GetTimeZoneAsync');
  }
}
