<div class="cd-popup" role="alert">
  <h2>{{dynamicDialogConfig.header}}</h2>
  <div class="cd-popup-container text-center">
    <p>{{dynamicDialogConfig.data.message}}</p>
    <p *ngIf="dynamicDialogConfig.data?.message1">{{dynamicDialogConfig.data.message1}}</p>
    <div class="cd-buttons">
      <button class="btn  btn--secondary" (click)="onNo()">No</button>
      <button class="btn btn--primary mr-2" (click)="onYes()">Yes</button>
    </div>
  </div>
</div>
