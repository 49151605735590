<div class="content-area">
  <div class="dashboard-outer">
    <div class="deshboard-top">
      <div class="title-block">
        <!-- <h1>{{title}} <span *ngIf="titleBranch"> &nbsp;({{titleBranch}})</span></h1> -->
        <h1>{{title}}</h1>
        <div class="deshboard-top__switch-button">
          <!-- <span class="switchBtn">
            <p-inputSwitch [(ngModel)]="checked" (onChange)="showTodaysSummary()"></p-inputSwitch>
            Today’s Status View
          </span> -->


          <div class="filter-box">
            <span class="loading">(As of {{ todayDate }})</span>
            <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
              <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
                <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
                <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
              </svg> 
              <span class="loading">Refresh</span>
            </a>
          </div>

          
        </div>
      </div>
    </div>

    <div class="dashboard">
      <!-- <div class="dashboard__filters">
        <div class="filter-box">
          <span class="loading">(As of {{ todayDate }})</span>
          <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
            <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
              <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
              <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
            </svg> 
            <span class="loading">Refresh</span>
          </a>
        </div>
      </div> -->

      <div class="dashboard-block">
        <div class="dashboard-block__row">
          <div class="dashboard-block__row__column" *ngIf="isUrgentCallDisplay">
            <div class="column-box">
              <div class="column-box__top">
                <a href="javascript:void(0);" class="column-box__top__link" (click)="toNotScheduledUrgent()">
                  <h3 class="column-box__top__link__number loading">{{notScheduledNormal}}</h3>
                  <span class="column-box__top__link__text-label loading">Urgent Calls</span>
                </a>
              </div>
              <div class="column-box__bottom">
                <span class="column-box__bottom__box" (click)="toNotScheduledListingCallback()">
                  <span class="call-back loading"><img pTooltip="Call back by patient" src="/assets/img/d-call-ic.svg" width="22" height="22" alt="callback"></span>
                  <span class="loading">{{normalCallbackCount}}</span>
                </span>

                <span class="column-box__bottom__box" (click)="toNotScheduledListingTodayCallback()">
                  <span class="call-back loading"><img src="/assets/img/today-call.svg" pTooltip="Today Schedule call back" width="22" height="22" alt="callback"></span>
                  <span class="loading">{{urgentCallbackRequest?.todayScheduledCallbackCount}}</span>
                </span>

                <span class="column-box__bottom__box" (click)="toNotScheduledListingFutureCallback()">
                  <span class="call-back loading"><img src="/assets/img/future-call.svg" pTooltip="Future Schedule call back" width="24" height="24" alt="callback"></span>
                  <span class="loading">{{urgentCallbackRequest?.futureCallbackCount}}</span>
                </span>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading1">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>
          
          <div class="dashboard-block__row__column tp-wrapper" >
            <a href="javascript:void(0);" class="data-flip loading" (click)="toggleFlip()" pTooltip="Data Flip"><img
                src="/assets/img/d-flip-ic.svg" width="22" alt="Data"></a>

            <div class="column-box">
              <div class="column-box__top">
                <a href="javascript:void(0);" class="column-box__top__link" (click)="toNotScheduled()">
                  <h3 class="column-box__top__link__number loading">{{notScheduledTelehealthCall?.total}}</h3>
                  <span class="column-box__top__link__text-label loading">To Be Booked</span>
                </a>
              </div>

              <div class="tp-box" [@flipState]="flip">
                <div
                  [ngClass]="!isTobeBookedDaysDisplay ? 'tp-box__side tp-box__front column-box__bottom':'tp-box__side tp-box__back column-box__bottom' ">
                  <span class="column-box__bottom__box" (click)="toNotScheduledFirstCall()">
                    <span class="column-box__bottom__box__text-label loading">Awaiting <br>First Call</span>
                    <span class="loading">{{notScheduledTelehealthCall?.firstCall}}</span>
                  </span>
                  <span class="column-box__bottom__box" (click)="toNotScheduledSecondCall()">
                    <span class="column-box__bottom__box__text-label loading">Awaiting <br>Second Call</span>
                    <span class="loading">{{notScheduledTelehealthCall?.secondCall}}</span>
                  </span>
                  <span class="column-box__bottom__box" (click)="toNotScheduledThirdCall()">
                    <span class="column-box__bottom__box__text-label loading">Awaiting <br>Third Call</span>
                    <span class="loading">{{notScheduledTelehealthCall?.thirdCall}}</span>
                  </span>
                </div>

                <div
                  [ngClass]="isTobeBookedDaysDisplay ? 'tp-box__side tp-box__front column-box__bottom':'tp-box__side tp-box__back column-box__bottom' ">
                  <span class="column-box__bottom__box" (click)="lessThan3Call('2')">
                    <span class="column-box__bottom__box__text-label loading">3 <br>Day's</span>
                    <span class="loading">{{notScheduledTelehealthCall?.lessthan3}}</span>
                  </span>
                  <span class="column-box__bottom__box" (click)="lessThan3Call('3')">
                    <span class="column-box__bottom__box__text-label loading">3 - 7 <br>Day's</span>
                    <span class="loading">{{notScheduledTelehealthCall?.between3to7}}</span>
                  </span>
                  <span class="column-box__bottom__box" (click)="lessThan3Call('7')">
                    <span class="column-box__bottom__box__text-label loading">7+ <br>Day's</span>
                    <span class="loading">{{notScheduledTelehealthCall?.morethan7}}</span>
                  </span>
                </div>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading1">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>
          
          <div class="dashboard-block__row__column" *ngIf="isNoShowDisplay" >
            <div class="column-box" >

              <div class="no-show-block">

                <div class="no-show-block__top">
                  <a href="javascript:void(0);" class="no-show-block__link" (click)="toNoShowListingPage()">
                    <h3 class="no-show-block__number loading">{{noShowAppointmentsCount?.appointmentCount}}</h3>
                    <span class="no-show-block__text-label loading">No Show</span>
                  </a>
                </div>

                <div class="no-show-block__bottom">
                  <div class="no-show-block__box" (click)="toNoShowListingHome()">
                    <span class="loading"><img pTooltip="Road/Home Appt" src="/assets/img/d-home-ic.svg" width="21"
                        height="21" alt="Road / Home"></span>
                    <span class="loading">{{noShowAppointmentsCount?.homeVisitCount}}</span>
                  </div>
                  <div class="no-show-block__box" (click)="toNoShowListingFacility()">
                    <span class="loading"><img pTooltip="Facility Visit" src="/assets/img/d-facility-ic.svg"
                        width="20" height="20" alt="Facility Visit"></span>
                    <span class="loading">{{noShowAppointmentsCount?.facilityVisitCount}}</span>
                  </div>
                  <div class="no-show-block__box" (click)="toNoShowListingTelehealth()">
                    <span class="loading"><img pTooltip="Remote Setup" tooltipPosition="left"
                        src="/assets/img/d-remote-setup-ic.svg" width="23" height="23" alt="Remote Setup"></span>
                    <span class="loading">{{noShowAppointmentsCount?.remoteCount}}</span>
                  </div>
                </div>

              </div>

              <div class="cancaled-block">

                <div class="no-show-block__top">
                  <a href="javascript:void(0);" class="no-show-block__link" (click)="toCancelListingPage()">
                    <h3 class="no-show-block__number loading">{{cancelledAppts?.appointmentCount}}</h3>
                    <span class="no-show-block__text-label loading">Cancelled</span>
                  </a>
                </div>

                <div class="no-show-block__bottom">
                  <div class="no-show-block__box" (click)="toCancelListingHome()">
                    <span class="loading" pTooltip="Road/Home Appt"><img src="/assets/img/d-home-ic.svg" width="21"
                        height="21" alt="Road / Home"></span>
                    <span class="loading">{{cancelledAppts?.homeVisitCount}}</span>
                  </div>
                  <div class="no-show-block__box" (click)="toCancelListingFacility()">
                    <span class="loading" pTooltip="Facility Visit"><img src="/assets/img/d-facility-ic.svg"
                        width="20" height="20" alt="Facility Visit"></span>
                    <span class="loading">{{cancelledAppts?.facilityVisitCount}}</span>
                  </div>
                  <div class="no-show-block__box" (click)="toCancelListingTelehealth()">
                    <span class="loading" pTooltip="Remote Setup"><img tooltipPosition="left"
                        src="/assets/img/d-remote-setup-ic.svg" width="23" height="23" alt="Remote Setup"></span>
                    <span class="loading">{{cancelledAppts?.remoteCount}}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading3">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>

          <div class="dashboard-block__row__column" *ngIf="isNoShowDisplay">
            <h3 class="box-four">&nbsp;</h3>
            <div class="column-box">
              <div class="column-box__top">
                <a href="javascript:void(0);" class="column-box__top__link" (click)="toOpenListingPage()">
                  <h3 class="column-box__top__link__number loading">{{openAppointmentsCount?.appointmentCount}}</h3>
                  <span class="column-box__top__link__text-label loading">Scheduled</span>
                </a>
              </div>
              <div class="column-box__bottom">
                <div class="column-box__bottom__box" (click)="toOpenListingHome()">
                  <span class="loading"><img pTooltip="Road/Home Appt" src="/assets/img/d-home-ic.svg" width="21"
                      height="21" alt="Road / Home"></span>
                  <span class="loading">{{openAppointmentsCount?.homeVisitCount}}</span>
                </div>
                <div class="column-box__bottom__box" (click)="toOpenListingFacility()">
                  <span class="loading"><img pTooltip="Facility Visit" src="/assets/img/d-facility-ic.svg"
                      width="20" height="20" alt="Facility Visit"></span>
                  <span class="loading">{{openAppointmentsCount?.facilityVisitCount}}</span>
                </div>
                <div class="column-box__bottom__box" (click)="toOpenListingTelehealth()">
                  <span class="loading"><img pTooltip="Remote Setup" src="/assets/img/d-remote-setup-ic.svg"
                      width="23" height="23" alt="Remote Setup"></span>
                  <span class="loading">{{openAppointmentsCount?.remoteCount}}</span>
                </div>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading4">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>

          <div class="dashboard-block__row__column">
            <div class="column-box potential-void">
              <div class="column-box__top border-none">
                <a href="javascript:void(0);" class="column-box__top__link" (click)="toPotentialVoid()">
                  <h3 class="column-box__top__link__number loading">{{potentialVoid?.appointmentCount}}</h3>
                  <span class="column-box__top__link__text-label loading">Void / Hold</span>
                </a>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading5">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>

          <div class="dashboard-block__row__column">
            <div class="column-box">
              <div class="column-box__top">
                <a href="javascript:void(0);" class="column-box__top__link" (click)="toUnReconListingPage()">
                  <h3 class="column-box__top__link__number loading">{{unReconciledAppts?.appointmentCount}}</h3>
                  <span class="column-box__top__link__text-label loading">Unreconciled Appointments</span>
                </a>
              </div>
              <div class="column-box__bottom">
                <div class="column-box__bottom__box" (click)="toUnReconListingHome()">
                  <span class="loading"><img pTooltip="Road/Home Appt" src="/assets/img/d-home-ic.svg" width="21"
                      height="21" alt="Road / Home"></span>
                  <span class="loading">{{unReconciledAppts?.homeVisitCount}}</span>
                </div>
                <div class="column-box__bottom__box" (click)="toUnReconListingFacility()">
                  <span class="loading"><img pTooltip="Facility Visit" src="/assets/img/d-facility-ic.svg"
                      width="20" height="20" alt="Facility Visit"></span>
                  <span class="loading">{{unReconciledAppts?.facilityVisitCount}}</span>
                </div>
                <div class="column-box__bottom__box" (click)="toUnReconListingTelehealth()">
                  <span class="loading"><img pTooltip="Remote Setup" tooltipPosition="left"
                      src="/assets/img/d-remote-setup-ic.svg" width="23" height="23" alt="Remote Setup"></span>
                  <span class="loading">{{unReconciledAppts?.remoteCount}}</span>
                </div>
              </div>
            </div>
            <!-- <div class="loader-block" *ngIf="loading6">
              <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
              </p-progressSpinner>
            </div> -->
          </div>
        </div>
      </div>


      <!-- <p-tabView class="dme-dashboard-tab">
        <p-tabPanel header="Today’s Appointments">
          Today’s Appointments Dashboard
        </p-tabPanel>
        <p-tabPanel header="Summary">
          Summary Dashboard
        </p-tabPanel>
        
      </p-tabView> -->
    </div>
  </div>


</div>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>