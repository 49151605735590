<div class="legends-sidebar">
    <h3 class="legends-sidebar__title">Legends</h3>
    <!-- <p class="legends-sidebar__desc">We need to show the legends & definations of new colors, terms & icons</p> -->
    
    <div class="legends-box">
        <div class="legends-box__left">
            <ul class="legends-box__legends-color">
                <li class="legends-box__legends-color-items">
                    <span class="remote-setup">Remote Setup</span>
                </li>
                <li class="legends-box__legends-color-items ">
                    <span class="facility-visit">Facility Visit</span>
                </li>
                <li class="legends-box__legends-color-items">
                    <span class="both-facility-remote">Both Facility & Remote</span>
                </li>
                <li class="legends-box__legends-color-items">
                    <span class="road-home-visit">Road / Home Visit</span>
                </li>
                <li class="legends-box__legends-color-items">
                    <span class="office-not-available">Office / Not Available</span>
                </li>

                <li class="legends-box__legends-color-items break-hours-item">
                    <span class="break-hours">Break Hours</span>
                </li>

                <li class="legends-box__legends-color-items block-slots-item">
                    <span class="block-slots">Block slots</span>
                </li>
            </ul>
        </div>
        <div class="legends-box__right">
            <ul class="legends-box__icon-info">
                <li class="legends-box__icon-info-item">
                    <svg _ngcontent-khv-c408="" width="15" height="18" viewBox="0 0 15 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path _ngcontent-khv-c408=""
                            d="M10.115 14.4392C9.5787 14.4392 9.121 14.2497 8.74187 13.8705C8.36336 13.4926 8.1741 13.0356 8.1741 12.4993C8.1741 11.963 8.36367 11.5053 8.74279 11.1262C9.1213 10.7477 9.5787 10.5584 10.115 10.5584C10.6513 10.5584 11.1087 10.748 11.4872 11.1271C11.8657 11.5056 12.0549 11.963 12.0549 12.4993C12.0549 13.0356 11.8654 13.493 11.4862 13.8715C11.1083 14.25 10.6513 14.4392 10.115 14.4392ZM0.130859 17.1909V2.51516H3.2357V0.469727H4.22357V2.51516H10.7846V0.469727H11.7018V2.51516H14.8066V17.1909H0.130859ZM1.0481 16.2737H13.8894V7.66545H1.0481V16.2737Z"
                            fill="#6F64A7"></path>
                    </svg>
                    Event
                </li>

                <li class="legends-box__icon-info-item">
                    <svg _ngcontent-khv-c408="" width="17" height="14" viewBox="0 0 17 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path _ngcontent-khv-c408=""
                            d="M14.2372 4.70012C13.7292 3.53244 12.8196 2.56812 11.6485 1.9557C10.4774 1.34329 9.10965 1.11675 7.75608 1.31099C6.4025 1.50522 5.13814 2.10946 4.15776 3.03062C3.17738 3.95178 2.53537 5.13876 2.33063 6.40872M1.21761 3.74342L1.95849 6.50843L4.93556 5.71073M2.69937 9.27344C3.20734 10.4411 4.11695 11.4054 5.28809 12.0179C6.45923 12.6303 7.82693 12.8568 9.18051 12.6626C10.5341 12.4683 11.7984 11.8641 12.7788 10.9429C13.7592 10.0218 14.4012 8.8348 14.606 7.56485M15.719 10.2301L14.9781 7.46513L12.001 8.26284"
                            stroke="#6F64A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                    Series
                </li>
    
                <li class="legends-box__icon-info-item">
                    <svg _ngcontent-iom-c407="" width="17" height="16" viewBox="0 0 17 16" fill="none"
                        xmlns=" http://www.w3.org/2000/svg">
                        <g _ngcontent-iom-c407="" id="Group 117">
                            <path _ngcontent-iom-c407="" id="Vector"
                                d="M14.2372 5.54241C13.7292 4.37473 12.8196 3.41041 11.6485 2.79799C10.4774 2.18557 9.10965 1.95903 7.75608 2.15327C6.4025 2.34751 5.13814 2.95175 4.15776 3.87291C3.17738 4.79407 2.53537 5.98105 2.33063 7.25101M1.21761 4.58571L1.95849 7.35072L4.93556 6.55301M2.69937 10.1157C3.20734 11.2834 4.11695 12.2477 5.28809 12.8602C6.45923 13.4726 7.82693 13.6991 9.18051 13.5049C10.5341 13.3106 11.7984 12.7064 12.7788 11.7852C13.7592 10.8641 14.4012 9.67709 14.606 8.40713M15.719 11.0724L14.9781 8.30742L12.001 9.10512"
                                stroke="#6F64A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <line _ngcontent-iom-c407="" id="Line 83" x1="15.6387" y1="0.586482" x2="1.06646" y2="15.1587"
                                stroke="#6F64A7" stroke-width="1.5"></line>
                        </g>
                    </svg>
                    Main Series changes not applied to day(s)
                </li>
    
              
            </ul>
        </div>
        <div class="legends-box__bottom"></div>
    </div>

    <!-- <div class="legends-sidebar__info-box">
        <h4 class="legends-sidebar__sub-title">Color information</h4>
        <ul class="legends-sidebar__legends-color">
            <li class="legends-sidebar__legends-color-items">
                <span class="remote-setup">Remote Setup</span>
            </li>
            <li class="legends-sidebar__legends-color-items ">
                <span class="facility-visit">Facility Visit</span>
            </li>
            <li class="legends-sidebar__legends-color-items">
                <span class="both-facility-remote">Both Facility & Remote</span>
            </li>
            <li class="legends-sidebar__legends-color-items">
                <span class="road-home-visit">Road / Home Visit</span>
            </li>
            <li class="legends-sidebar__legends-color-items">
                <span class="office-not-available">Office / Not Available</span>
            </li>
        </ul>

        <h4 class="legends-sidebar__sub-title">Icon information</h4>
        <ul class="legends-sidebar__icon-info">
            <li class="legends-sidebar__icon-info-item">
                <svg _ngcontent-khv-c408="" width="17" height="14" viewBox="0 0 17 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path _ngcontent-khv-c408=""
                        d="M14.2372 4.70012C13.7292 3.53244 12.8196 2.56812 11.6485 1.9557C10.4774 1.34329 9.10965 1.11675 7.75608 1.31099C6.4025 1.50522 5.13814 2.10946 4.15776 3.03062C3.17738 3.95178 2.53537 5.13876 2.33063 6.40872M1.21761 3.74342L1.95849 6.50843L4.93556 5.71073M2.69937 9.27344C3.20734 10.4411 4.11695 11.4054 5.28809 12.0179C6.45923 12.6303 7.82693 12.8568 9.18051 12.6626C10.5341 12.4683 11.7984 11.8641 12.7788 10.9429C13.7592 10.0218 14.4012 8.8348 14.606 7.56485M15.719 10.2301L14.9781 7.46513L12.001 8.26284"
                        stroke="#6F64A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                Series icon
            </li>

            <li class="legends-sidebar__icon-info-item">
                <svg _ngcontent-iom-c407="" width="17" height="16" viewBox="0 0 17 16" fill="none"
                    xmlns=" http://www.w3.org/2000/svg">
                    <g _ngcontent-iom-c407="" id="Group 117">
                        <path _ngcontent-iom-c407="" id="Vector"
                            d="M14.2372 5.54241C13.7292 4.37473 12.8196 3.41041 11.6485 2.79799C10.4774 2.18557 9.10965 1.95903 7.75608 2.15327C6.4025 2.34751 5.13814 2.95175 4.15776 3.87291C3.17738 4.79407 2.53537 5.98105 2.33063 7.25101M1.21761 4.58571L1.95849 7.35072L4.93556 6.55301M2.69937 10.1157C3.20734 11.2834 4.11695 12.2477 5.28809 12.8602C6.45923 13.4726 7.82693 13.6991 9.18051 13.5049C10.5341 13.3106 11.7984 12.7064 12.7788 11.7852C13.7592 10.8641 14.4012 9.67709 14.606 8.40713M15.719 11.0724L14.9781 8.30742L12.001 9.10512"
                            stroke="#6F64A7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <line _ngcontent-iom-c407="" id="Line 83" x1="15.6387" y1="0.586482" x2="1.06646" y2="15.1587"
                            stroke="#6F64A7" stroke-width="1.5"></line>
                    </g>
                </svg>
                Not applied of Series
            </li>

            <li class="legends-sidebar__icon-info-item">
                <svg _ngcontent-khv-c408="" width="15" height="18" viewBox="0 0 15 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path _ngcontent-khv-c408=""
                        d="M10.115 14.4392C9.5787 14.4392 9.121 14.2497 8.74187 13.8705C8.36336 13.4926 8.1741 13.0356 8.1741 12.4993C8.1741 11.963 8.36367 11.5053 8.74279 11.1262C9.1213 10.7477 9.5787 10.5584 10.115 10.5584C10.6513 10.5584 11.1087 10.748 11.4872 11.1271C11.8657 11.5056 12.0549 11.963 12.0549 12.4993C12.0549 13.0356 11.8654 13.493 11.4862 13.8715C11.1083 14.25 10.6513 14.4392 10.115 14.4392ZM0.130859 17.1909V2.51516H3.2357V0.469727H4.22357V2.51516H10.7846V0.469727H11.7018V2.51516H14.8066V17.1909H0.130859ZM1.0481 16.2737H13.8894V7.66545H1.0481V16.2737Z"
                        fill="#6F64A7"></path>
                </svg>
                Event icon
            </li>
        </ul>
    </div> -->
</div>