<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="title-block__right">
      <!-- <a class="invite-schedule" href="javascript:void(0)" (click)="SendInvitation()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path
            d="M60.86,13.26a1,1,0,0,0,0-.28s0,0,0,0,0-.05,0-.08a1,1,0,0,0-.11-.19l-.11-.11-.08-.08-.06,0-.14-.07-.17-.06-.15,0h-.3L3.86,24.49a1,1,0,0,0-.25,1.86l15.56,8.11,2.51,15.73a1,1,0,0,0,.09.28l0,.06a1,1,0,0,0,.19.24l0,0a1,1,0,0,0,.22.14h.06l.06,0a1,1,0,0,0,.3,0h0A1,1,0,0,0,23,51l.09,0,.14-.06,10.92-6.72,11.23,7.38a1,1,0,0,0,1.49-.49l14-37.42a1,1,0,0,0,.06-.32S60.86,13.28,60.86,13.26Zm-35,23.91a1,1,0,0,0-.15.14l0,0a1,1,0,0,0-.17.31l0,0-2.56,8L21.18,34.36l28.13-14.5ZM52.65,15.89,20.08,32.68,7,25.85ZM24.44,47.77l2.62-8.24L32.27,43Zm21,1.42-10.68-7h0l-6.46-4.24L57.75,16.1Z"
            data-name="Layer 2" />
        </svg>
        Send Next Day Appointments</a> -->
      <a href="javascript:void(0);" class="filter-show-hide" (click)="QuickAction.toggle($event)" pTooltip="Actions"
        tooltipPosition="left">
        <svg width="21" height="5" viewBox="0 0 21 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>

      <p-overlayPanel #QuickAction appendTo="body" class="search-filter">
        <div class="quick-link-menu enable-disable-patient">
          <ul>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="SendInvitation()">Send Next Day Appointments</a></li>
            <li><a class="invite-schedule" href="javascript:void(0)"
                (click)="SelectedFacilitiesSelfScheduleAppointmentStatus(true)">Enable Patient's Self Scheduling</a></li>
            <li><a class="invite-schedule" href="javascript:void(0)"
                (click)="SelectedFacilitiesSelfScheduleAppointmentStatus(false)">Disable Patient's Self Scheduling</a></li>
          </ul>
        </div>
      </p-overlayPanel>

      <a href="javascript:void(0);" pTooltip="Add Facility" tooltipPosition="left" class="add-plus"
        routerLink="/admin/facilities/add"><img src="assets/img/add-plus.svg" width="17" alt="filters"></a>
      <a href="javascript:void(0);" class="filter-block filter-show-hide" pTooltip="Filters" tooltipPosition="right"
        (click)="overlyFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>


  <div class="page-scroll admin-facility">
    <div class="table-outer-main facility-table">
      <p-table #nsdl class="table-outer" responsiveLayout="scroll" [paginator]="totalRecords>0?true:false" sortMode="single"
        sortField="CrmFacilityContactPersonFirstName" [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="1"
        [value]="facilities" [(selection)]="selectedFacilities" selectionMode="" [rows]="getTotalRows()"
        [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)"
        responsiveLayout="scroll">
        <ng-template pTemplate="header">

          <tr>
            <th pFrozenColumn class="table-outer__th table-checkbox-column" width="30">
              <span class="table-checkbox">
                <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
              </span>
            </th>
            <th pFrozenColumn class="table-outer__th" width="400" pSortableColumn="CrmFacilityName">
              Facility<p-sortIcon field="CrmFacilityName"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="250" pSortableColumn="CrmFacilityContactPersonFirstName">
              Contact Person<p-sortIcon field="CrmFacilityContactPersonFirstName"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="200" pSortableColumn="IsRemoteAssistance">
              Remote Setup<p-sortIcon field="IsRemoteAssistance">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" width="80" pSortableColumn="CrmState">
              State<p-sortIcon field="CrmState"></p-sortIcon>
            </th>
            <th class="table-outer__th status-column" width="80" pSortableColumn="CrmTimeZone">Time Zone<p-sortIcon
                field="CrmTimeZone"></p-sortIcon></th>
            <th class="table-outer__th status-column" width="80" pSortableColumn="IsActive">
              Status<p-sortIcon field="IsActive"></p-sortIcon>
            </th>
            <th class="table-outer__th" width="100">Patient's Self Scheduling</th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="120">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-facility>

          <tr>
            <td pFrozenColumn class="table-outer__td table-checkbox-column" style="vertical-align: middle;">
              <p-tableCheckbox [value]="facility"></p-tableCheckbox>
            </td>
            <td pFrozenColumn class="table-outer__td">
              <span class="link">
                <a (click)="onView(facility.crmFacilityId)">{{facility?.crmFacilityName}}</a>
                <span class="sub-text">{{facility?.crmBranchName}}</span>
              </span>
            </td>
            <td class="table-outer__td">{{facility?.crmFacilityContactPersonFirstName}}
              {{facility?.crmFacilityContactPersonLastName}}</td>
            <td class="table-outer__td">{{facility?.isRemoteAssistance ? 'Yes' : 'No'}}</td>
            <td class="table-outer__td">{{facility?.isRemoteAssistance ? '-' : facility?.crmState }}</td>
            <td class="table-outer__td status-column">{{facility?.crmTimeZone | timezone}}</td>
            <td class="table-outer__td status-column"><span
                [ngClass]="facility.isActive ? 'user-active' : 'user-inactive'">{{facility?.isActive? 'Active' :
                'Inactive'}}</span></td>
            <td class="table-outer__td">
              <span class="switchBtn">
                <p-inputSwitch id="{{facility.crmIsAllowSelfScheduleAppointments}}"
                  [(ngModel)]="facility.crmIsAllowSelfScheduleAppointments"
                  (onChange)="handleFacilitySelfScheduleAppointmentStatusChange(facility.crmFacilityId,$event)">
                </p-inputSwitch>
              </span>
            </td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">

              <div class="action-column">

                <a class="actionmenu-btn" href="javascript:void(0)"
                  (click)="selectFacility($event,facility,facilitiesAction)">
                  <!-- (click)="facilitiesAction.toggle($event)"> -->
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>
              <p-overlayPanel appendTo="body" class="table-action-popup facilities-action NotScheduledAction"
                #facilitiesAction>
                <ul class="quick-link-menu">
                  <li *ngIf="selectedFacility?.crmFacilityContactEmailAddress">
                    <a href="javascript:void(0)" class="email-row" (click)="onEmail(selectedFacility?.crmFacilityId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="41.924" height="33" viewBox="0 0 41.924 33">
                        <g id="email-ic-new" transform="translate(0.502 -7.5)">
                          <g id="Group_5" data-name="Group 5" transform="translate(-0.001 8)">
                            <g id="Group_1" data-name="Group 1">
                              <path id="Path_3" data-name="Path 3"
                                d="M37.51,40H3.41A3.745,3.745,0,0,1,0,36V12A3.745,3.745,0,0,1,3.41,8h34.1a3.745,3.745,0,0,1,3.41,4V36A3.745,3.745,0,0,1,37.51,40ZM3.41,10A1.874,1.874,0,0,0,1.7,12V36A1.874,1.874,0,0,0,3.41,38h34.1a1.874,1.874,0,0,0,1.709-2V12a1.874,1.874,0,0,0-1.709-2Z"
                                transform="translate(0.001 -8)" fill="#6e64a7" stroke="#6e64a7" stroke-width="1" />
                            </g>
                            <g id="Group_2" data-name="Group 2" transform="translate(6 7.999)">
                              <path id="Path_4" data-name="Path 4"
                                d="M20.46,29.191,6.367,17.84a1.156,1.156,0,0,1-.238-1.383.794.794,0,0,1,.5-.435.686.686,0,0,1,.613.135L20.46,26.809,33.681,16.16a.686.686,0,0,1,.613-.135.794.794,0,0,1,.5.435,1.156,1.156,0,0,1-.238,1.383Z"
                                transform="translate(-5.999 -15.999)" fill="#6e64a7" stroke="#6e64a7"
                                stroke-width="1" />
                            </g>
                            <g id="Group_3" data-name="Group 3" transform="translate(5 18)">
                              <path id="Path_5" data-name="Path 5"
                                d="M6,34a1,1,0,0,1-.556-1.832l9-6a1,1,0,1,1,1.11,1.664l-9,6A1,1,0,0,1,6,34Z"
                                transform="translate(-4.999 -26)" fill="#6e64a7" stroke="#6e64a7" stroke-width="1" />
                            </g>
                            <g id="Group_4" data-name="Group 4" transform="translate(24.923 18)">
                              <path id="Path_6" data-name="Path 6"
                                d="M42,34a1,1,0,0,1-.554-.168l-9-6a1,1,0,1,1,1.11-1.664l9,6A1,1,0,0,1,42,34Z"
                                transform="translate(-32.001 -26)" fill="#6e64a7" stroke="#6e64a7" stroke-width="1" />
                            </g>
                          </g>
                        </g>
                      </svg>
                      Email
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="edit-row" (click)="onEdit(selectedFacility?.crmFacilityId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit
                    </a>
                  </li>
                  <li *ngIf="selectedFacility?.isActive">
                    <a href="javascript:void(0)" class="delete-row" (click)="onDelete(selectedFacility?.crmFacilityId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete
                    </a>
                  </li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <ng-container *ngIf="totalRecords <= 0">
            <tr class="primeng-table__body" *ngIf="!loading">
              <td colspan="6" class="text-center no-records-found-facilities">{{noRecordsFoundMessage}}</td>
            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>

      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
</div>


<p-overlayPanel class="overly-filter" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">

      <div class="form__group">
        <label class="form__label">Search</label>
        <div class="search-box">
          <input type="text" class="form__control" pInputText placeholder="Facility / Contact Person" #searchBox
            style="width:auto" (keyup.enter)="onSearchButtonClick()">
          <button *ngIf="searchBox.value !=''" class="clear-btn" type="button" (click)="searchBox.value =''">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button">
            <i class="search-new"></i>
          </button>
        </div>
      </div>

      <div class="form__group">
        <label class="form__label">Branch</label>
        <p-dropdown class="select-box filter-status" [options]="branchResponseModel" [(ngModel)]="selectedbranch"
          id="branchDropdown" placeholder="Select Branch" optionLabel="crmBranchName" [showClear]="true"
          (onChange)="onBranchChange($event)"></p-dropdown>
      </div>

      <div class="form__group">
        <label class="form__label">Status</label>
        <p-dropdown class="select-box filter-status" #statusDropdown [options]="facilityStatus"
          [(ngModel)]="selectedStatus" (onChange)="onStatusChange($event)" placeholder="Status" optionLabel="label"
          [showClear]="true"></p-dropdown>
      </div>

    </div>
    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
        (click)="onClearSearchFilter();onFilterSearch()">Clear</a>
    </div>
  </div>
</p-overlayPanel>

<p-dialog [(visible)]="ConfirmToChangeSelfScheulingStatus" styleClass="display-close-icon" [modal]="true" appendTo="body" [closable]="false">
  <div class="popup-content-outer confirm-invite-patients">
    <div>
      <div *ngIf="linkedTherapistWithCurrentFacilityCount > 0">
        <h2>Confirmation of Patient's Self Scheduling</h2>
        <div class="confirm-invite-patients__content-part" *ngIf="!isEnableSelfSchedulingForSelected">
          <p>You have selected {{selectedFacilitiesCount}} Facility(es) to disable Patient's Self Scheduling. However the selected Facility(es) are linked with Therapist(s) mentioned below.</p>
          <p>If you click on <strong>"Yes, Confirm"</strong> then, the Patient's Self Scheduling for the linked Therapist(s) will also be disabled.</p>
        </div>

        <div class="confirm-invite-patients__content-part" *ngIf="isEnableSelfSchedulingForSelected">
          <p>You have selected {{selectedFacilitiesCount}} Facility(es) to enable Patient's Self Scheduling. However the selected Facility(es) are linked with Therapist(s) mentioned below.</p>
          <p>If you click on <strong>"Yes, Confirm"</strong> then, the Patient's Self Scheduling for the linked Therapist(s) will also be enabled.</p>
        </div>

        <div class="popup-table-outer">
          <table class="custom-table">
            <thead>
              <tr>
                <th>Therapist Name</th>
                <th>Facility Name</th>
              </tr>
            </thead>
            <tbody *ngFor="let linkedTherapistWithCurrentFacility of linkedTherapistWithCurrentFacilityActiveDetails">
              <tr>
                <td>{{linkedTherapistWithCurrentFacility.appUserLname | titlecase}}, {{linkedTherapistWithCurrentFacility.appUserFname | titlecase}}</td>
                <td>{{linkedTherapistWithCurrentFacility.linkedFacilities}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div *ngIf="linkedTherapistWithCurrentFacilityCount == 0">
        <h2>Confirmation of Patient's Self Scheduling</h2>
        <div class="confirm-invite-patients__content-part" *ngIf="!isEnableSelfSchedulingForSelected">
          <p>Are you sure you want to disable Patient's Self Scheduling for Facility(es)?</p>
        </div>
        <div class="confirm-invite-patients__content-part" *ngIf="isEnableSelfSchedulingForSelected">
          <p>Are you sure you want to enable Patient's Self Scheduling for Facility(es)?</p>
        </div>
      </div>

      <div class="confirm-invite-patients__buttons-box">
        <a href="javascript:void(0)" class="btn btn--secondary" (click)="CancelSelfSchedulingChanges(true)">No, Cancel</a>
        <button class="btn btn--primary" (click)="ConfirmSelfSchedulingChanges()">Yes, Confirm</button>
      </div>
    </div>
  </div>
</p-dialog>