<div class="patient-info callLogs-info">
  <div class="patient-info-top">
    <div class="patient-info-top__left">
      <h2>{{patientName}}</h2>
    </div>
    <div [ngClass]="i==0 ? 'callLogs-block':'callLogs-block last'" *ngFor="let callLog of callLogs; let i = index">

      <span class="details-group">
        <label class="details-label">Date</label>
        <span class="details-desc">{{callLog.schAppointmentCallDateTime | date:appDateFormat.dateFormat:appDateFormat.timeZone}} {{callLog.schAppointmentCallDateTime  | date:'shortTime':appDateFormat.timeZone | uppercase}}</span>
      </span>
      <span class="details-group">
        <label class="details-label">Next Call Date</label>
        <span class="details-desc">{{callLog.schNextCallDueDate  | date:appDateFormat.dateFormat:appDateFormat.timeZone}} {{callLog.schNextCallDueDate  | date:'shortTime':appDateFormat.timeZone | uppercase}}</span>
      </span>
      <span class="details-group">
        <label class="details-label">{{callLog.schAppointmentCallByRole}}</label>
        <span class="details-desc">{{callLog.schAppointmentCallByPerson}}</span>
      </span>

      <span class="details-group note">
        <label class="details-label">Notes</label>
        <span class="details-desc">{{callLog.schAppointmentCallNote}}</span>
      </span>

    </div>
   
    <div class="close-btn">
      <button class="btn btn--secondary" (click)="close()">Close</button>
    </div>
  </div>
</div>
