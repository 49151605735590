import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../custom-validator';
import { Constants } from '../constants';
import { MessageService } from 'primeng/api';
import { UserService } from '../services/user.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { BaseComponent } from '../components/base.component';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { resetFakeAsyncZone } from '@angular/core/testing';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit, OnDestroy {

  title = 'Forgot Password';
  private unsubscriber = new Subject<boolean>();
  formFields = {
    email: 'email'
  };

  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    private sanitizer: DomSanitizer) {
    super(messageService);
    this.buildForm();
  }

  ngOnInit() {
    this.configResponseModel = this.webStorageService.getClientConfig();
    this.imageData = 'data:image/png;base64, ' + this.configResponseModel.companyLogo;
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
    this.imagealt = this.configResponseModel.companyName;
    this.imageTitle = this.configResponseModel.companyName;
  }


  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const email = this.form.get(this.formFields.email).value;
    await this.userService.forgotPassword(email)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        
        if (result.isSuccess) {
          this.loading = false;
          this.showSuccessMessage("Reset password link successfully sent on your email address.");
          this.router.navigate(['/login']);
        }
        else {
          if (result.message.toLowerCase() === "user does not exist")
            this.showWarningMessage("User is not registered.");
          else
            this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    this.form = formGroup;
  }

}
