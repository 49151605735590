<google-map
        height="600px" 
        width="1000px" 
        [center]="center" 
        [options]="options"
        [zoom]="zoom" 
        (mapMousemove)="move($event)">
    <map-marker 
        #mapmarker 
        *ngFor="let marker of markers" 
        [position]="marker.position" 
        [label]="marker.label"
        [title]="marker.title" 
        [options]="marker.options" 
        (mapClick)="openInfoWindow(mapmarker, marker.info)">
    </map-marker>
    <map-circle [center]="circleCenter" [radius]="radius" ></map-circle>
    <map-info-window>{{ infoContent }}</map-info-window>
    <!-- <map-polyline [path]="vertices"></map-polyline> -->
    <map-directions-renderer *ngIf="(directionsResults | async) as directionsResults"
                           [directions]="directionsResults" [options]="rendererOptions"></map-directions-renderer>
</google-map>
<!-- (mapClick)="addMarker($event)" -->
<!-- [options]="circleOptions" -->
<div>Latitude: {{display?.lat}}</div>
<div>Longitude: {{display?.lng}}</div>