import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FacilityService } from '../../servicies/facility.service';
import { ReportService } from '../../servicies/report.service';

@Component({
  selector: 'app-timeslot-availability-summary',
  templateUrl: './timeslot-availability-summary.component.html',
  styleUrls: ['./timeslot-availability-summary.component.sass']
})
export class TimeslotAvailabilitySummaryComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  facilityList: FacilityResponseModelDropdown[];
  selectedFacility: FacilityResponseModelDropdown[];
  index: number = 0;
  defaultMinutes: any;
  therapists: QuickFilters[];
  selectedTherapist: QuickFilters[];
  crmselectedTimeZone: string = 'UTC-5';
  clientId: number;
  FSelectedDateRange: any;
  TSelectedDateRange: any;
  appDateFormat: DateFormatModel;
  callocales: any;
  invalidDates: moment.Moment[] = [];
  currentUserBranch: number;

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }


  datesUpdated(range): void {
    this.FSelectedDateRange = range;
    this.TSelectedDateRange = range;
  }

  constructor(messageService: MessageService,
    private ReportService: ReportService,
    private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService) {
    super(messageService);
    this.currentUserBranch = this.webStorageService.getUserBranch();
    const currentClientConfig = this.webStorageService.getClientConfig();

    if (currentClientConfig && currentClientConfig.companyId) {
      this.clientId = parseInt(currentClientConfig.companyId);
      if (this.clientId == 10) {
        this.crmselectedTimeZone = 'UTC-8';
      }
    }

    this.appDateFormat = this.webStorageService.getDateFormat();
    this.callocales = {
      format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Apply', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
    }
  }

  async ngOnInit() {
    this.getTherapists()
    await this.getFacilities();
  }

  onFacilityExport() {

    let selectFacilityString = "";
    if (!this.FSelectedDateRange.startDate && !this.FSelectedDateRange.endDate) {
      this.loading = false;
      this.showErrorMessage("Export Date is required.");
      return;
    }

    if (this.selectedFacility == undefined) {
      this.showErrorMessage("Please select facility.");
      return;
    }
    if (this.index == 0 && this.defaultMinutes == undefined) {
      this.showErrorMessage("Please select slot duration.");
      return;
    }

    let startDate = moment(this.FSelectedDateRange.startDate).format("yyyy-MM-DD");
    let endDate = moment(this.FSelectedDateRange.endDate).format("yyyy-MM-DD");

    if (startDate && endDate) {

      if (moment(startDate).toDate() > moment(endDate).toDate()) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        this.loading = false;
        return;
      }
    }

    this.loading = true;

    this.selectedFacility.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
    });

    this.ReportService.ExportCalenderWithFacility(this.defaultMinutes.toString(), startDate, endDate, selectFacilityString)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'FacilityAvailabilitySummary.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  onTherapistExport() {
    let selectTherapistString = "";

    if (!this.TSelectedDateRange.startDate && !this.TSelectedDateRange.endDate) {
      this.loading = false;
      this.showErrorMessage("Export Date is required.");
      return;
    }

    if (this.selectedTherapist == undefined) {
      this.showErrorMessage("Please select therapist.");
      return;
    }

    let startDate = moment(this.TSelectedDateRange.startDate).format("yyyy-MM-DD");
    let endDate = moment(this.TSelectedDateRange.endDate).format("yyyy-MM-DD");

    if (startDate && endDate) {

      if (moment(startDate).toDate() > moment(endDate).toDate()) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        this.loading = false;
        return;
      }
    }


    this.selectedTherapist.forEach(value => {
      selectTherapistString += value.code + ",";
    });
    this.loading = true;
    this.ReportService.ExportCalenderWithTherapist(startDate, endDate, selectTherapistString)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'TherapistAvailabilitySummary.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  public async getFacilities() {
    this.progress.next(true);
    await this.facilityService.getAllFacilityDropDown()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.facilityList = result.responseModel;
          this.selectedFacility = this.facilityList;
          if (this.facilityList.length > 0) {
            let roadHome = new FacilityResponseModelDropdown();
            roadHome.crmFacilityId = 9999;
            roadHome.crmFacilityName = 'Road/Home Appt';
            this.facilityList.push(roadHome);
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBack() {
    this.router.navigate(['admin/reports']);
  }

  private async getTherapists() {
    this.progress.next(true);
    await this.facilityService.getAllTherapist()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.therapists = [];
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            const therapistArray = result.responseModel;
            therapistArray.forEach((value: UserResponseModel) => {
              this.therapists.push({ name: value.appUserLname + ", " + value.appUserFname, code: value.appUserInfoId.toString() });
            });
            this.selectedTherapist = this.therapists;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  tabChangeHelper(e) {
    this.TSelectedDateRange = null;
    this.FSelectedDateRange = null;
    this.index = e.index;
    if (e.index == 0) {
      this.selectedFacility = null;
      this.selectedFacility = this.facilityList;
    }
    else {
      this.selectedTherapist = null;
      this.selectedTherapist = this.therapists;
    }
  }
}
