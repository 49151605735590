<app-patient-header></app-patient-header>
<section class="on-demand-support-outer">
    <div class="title-row">
        <div class="title-row__left">
            <h1>On Demand Support Appointment</h1>
        </div>
    </div>

    <div class="sub-title">
        <h2>Lorem ipsum dolor sit amet, consectetur adipiscing</h2>
        <P>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam ligula pharetra, tristique cras mattis non mauris. Nisi commodo <br>tellus pharetra, egestas et. Morbi aliquam aliquam, diam dictum. Diam ligula pharetra, tristique cras mattis non mauris. </P>
    </div>

    <div class="patient-block">
        <h3>Are you new patient or existing patient?</h3>
        <a href="javascript:void(0);" class="btn btn--primary">New Patient</a>
        <a href="javascript:void(0);" class="btn btn--secondary">Existing Patient</a>
    </div>

</section>

