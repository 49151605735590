import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize, retry } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsBrightreeRequestModel } from 'src/app/shared/models/request/settings/setting-brightree-request-model';
import { SettingsBrightreeResponseModel, SettingsBrightreeModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { NotificationTemplate, StaffRoleId } from 'src/app/shared/enums';
import { CommonFunctions } from 'src/app/shared/common-functions';
import { SettingsNotificationResponseModel, NotificationTemplateModel } from 'src/app/shared/models/response/settings/settings-notification-response-model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SARemiderLeadTime, NotSARemiderLeadTime, RoleId } from 'src/app/shared/enums';
import { SettingsGeneralRequestModel } from 'src/app/shared/models/request/settings/settings-general-request-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.sass']
})
export class StaffComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsNotificationResponseModel: SettingsNotificationResponseModel[];
  settingsBrightreeRequestModel: SettingsBrightreeRequestModel;
  loggedInUserId: number;
  emailTextArea: string;
  emailTitle: string;
  selectedEmailTemplate: SettingsNotificationResponseModel;
  enumStaffRoleDisplay = StaffRoleId;
  staffRole = [];
  selectedRole: any;
  subject: string;
  formFields = {
    subject: 'subject',
    emailTextArea: 'emailTextArea',
    smsTextArea: 'smsTextArea'
  };
  validationMessages = {
    subject: {
      required: 'Subject is required',
    },
    emailTextArea: {
      required: 'Email message is required',
    }
  };
  enumModel: EnumModel[] = [];
  selectedEnumModel: EnumModel;
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    this.getNotificationFields();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.staffRole = CommonFunctions.enumSelector(this.enumStaffRoleDisplay);
    var index = this.staffRole.findIndex(where => where.title == "1");
    this.selectedRole = this.staffRole[index];
    this.getAllNotifications();
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }


  staffRoleIdOnChange(event) {
    this.selectedRole = event.value;
    this.settingsNotificationResponseModel.forEach(function (setting) {
      var templ;

      if (event.value.title == RoleId.DMEAdmin)
        templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.DMEAdmin);
      else if (event.value.title == RoleId.CSOperator)
        templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.CSOperator);
      else if (event.value.title == RoleId.SuperAdmin)
        templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.SuperAdmin);

      var templEmail = templ.find(where => where.appEnumnotificationType === NotificationTemplate.Email && where.isActive === true);
      setting.appNotificationInEmail = templEmail ? true : false;

      //var templSMS = templ.find(where => where.appEnumnotificationType === NotificationTemplate.SMS && where.isActive === true);
      //setting.appNotificationInSms = templSMS ? true : false;
    })
  }


  private getNotificationFields() {
    this.loading = true;
    this.settingsService.getNotificationFields()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<EnumModel[]>) => {
        this.processResult<any>(result, () => {
          this.enumModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  emailReset() {
    this.emailTextArea = "";
  }

  emailCancel(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
  }

  emailToggel(event, appNotificationTitle, template: SettingsNotificationResponseModel, overlaypanel: OverlayPanel) {
    this.formEmail.reset();
    const form = this.formEmail;
    this.emailTitle = this.formatString(appNotificationTitle);
    this.selectedEmailTemplate = template;
    var templateEmail = template.notificationTemplate.find(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == parseInt(this.selectedRole.title, 10));
    if (templateEmail) {
      form.get(this.formFields.subject).setValue(templateEmail.appNotificationTemplateSubject);
      form.get(this.formFields.emailTextArea).setValue(templateEmail.appNotificationTemplateFormat);
    }
    else {
      var notificationRequestModel = new NotificationTemplateModel();
      notificationRequestModel.appNotificationId = template.appNotificationId;
      notificationRequestModel.appRoleId = this.selectedRole.title;
      notificationRequestModel.appEnumnotificationType = NotificationTemplate.Email;
      notificationRequestModel.appNotificationTemplateFormat = "";
      notificationRequestModel.appNotificationTemplateSubject = template.appNotificationEventName;
      notificationRequestModel.isActive = false;
      notificationRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      template.notificationTemplate.push(notificationRequestModel);
      this.selectedEmailTemplate = template;
    }
    this.settingsNotificationResponseModel.find(where => where.appModuleId == this.selectedEmailTemplate.appModuleId).appNotificationInEmail = template.appNotificationInEmail;
    //if (template.appNotificationInEmail)
    overlaypanel.toggle(event);
  }


  OnEmailTemplateSave(event, overlaypanel: OverlayPanel) {
    
    this.formEmailSubmitted = true;
    if (this.formEmail.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.formEmailSubmitted = false;
    
    const form = this.formEmail;

    //var quill = this.textEditor.getQuill()
    let emailtext = form.get(this.formFields.emailTextArea).value;
    let subject = form.get(this.formFields.subject).value;

    var indexTemplate = this.selectedEmailTemplate.notificationTemplate.findIndex(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == parseInt(this.selectedRole.title, 10));
    var indexFeature = this.settingsNotificationResponseModel.findIndex(where => where.appModuleId == this.selectedEmailTemplate.appModuleId);

    //if (this.selectedEmailTemplate.appNotificationInEmail) {
    this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = emailtext;
    this.selectedEmailTemplate.notificationTemplate[indexTemplate].isActive = this.selectedEmailTemplate.appNotificationInEmail;
    this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateSubject = subject;
    this.selectedEmailTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
    this.selectedEmailTemplate.modifiedByUserInfoId = this.loggedInUserId;
    //}
    //else {
    //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = this.emailTextArea;
    //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].isActive = false;
    //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateSubject = this.subject;
    //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
    //  this.selectedEmailTemplate.modifiedByUserInfoId = this.loggedInUserId;
    //}

    this.settingsNotificationResponseModel[indexFeature].notificationTemplate[indexTemplate] = this.selectedEmailTemplate.notificationTemplate[indexTemplate];
    // overlaypanel.toggle(event);
    overlaypanel.hide();
  }

  private getAllNotifications() {
    this.loading = true;

    this.settingsService.getAllNotificationsByRole(1)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.settingsNotificationResponseModel = result.responseModel;
          this.settingsNotificationResponseModel.forEach(function (setting) {
            var templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.DMEAdmin);
            var templEmail = templ.find(where => where.appEnumnotificationType === NotificationTemplate.Email && where.isActive === true);
            setting.appNotificationInEmail = templEmail ? true : false;
          });
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  emailCheckClick(template: SettingsNotificationResponseModel) {

    var indexTemplate = template.notificationTemplate.findIndex(where => where.appEnumnotificationType === NotificationTemplate.Email
      && where.appRoleId === parseInt(this.selectedRole.title, 10));
    var indexFeature = this.settingsNotificationResponseModel.findIndex(where => where.appNotificationId === template.appNotificationId);

    if (indexTemplate >= 0) {
      if (template.appNotificationInEmail) {
        template.notificationTemplate[indexTemplate].isActive = true;
        template.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsNotificationResponseModel[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      } else {
        template.notificationTemplate[indexTemplate].isActive = false;
        template.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsNotificationResponseModel[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      }
    } else {
      if (template.appNotificationInEmail) {
        template.notificationTemplate[0].isActive = true;
        template.notificationTemplate[0].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsNotificationResponseModel[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      } else {
        template.notificationTemplate[0].isActive = false;
        template.notificationTemplate[0].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsNotificationResponseModel[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      }
    }

    this.settingsNotificationResponseModel[indexFeature].notificationTemplate[indexTemplate] = template.notificationTemplate[indexTemplate];
  }

  onSave() {
    const manageApplicationGeneral = this.settingsService.UpdateNotificationSettings(this.settingsNotificationResponseModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage('Staff notification settings is updated successfully');
          this.getAllNotifications();
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onChange(selectedTags) {
    const form = this.formEmail;
    //var quill = this.textEditor.getQuill()
    let emailtext = form.get(this.formFields.emailTextArea).value + ' ';
    emailtext = emailtext + ' ' + selectedTags.value.name;
    form.get(this.formFields.emailTextArea).setValue(emailtext);

  }

  get fEmail() { return (<FormGroup>this.form.get('formEmail')).controls; }

  get formEmail() {
    return this.form.get('formEmail');
  }
  formEmailSubmitted: boolean = false;

  private buildForm() {
    const formGroup = this.formBuilder.group({});
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.subject, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.emailTextArea, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formEmail: formGroup
    });
  }

}
