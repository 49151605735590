<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{titleName !=''?titleName:title}}</h1>
      <a *ngIf="title == 'Edit Therapist'" class="filter-action" href="javascript:void(0);"
        (click)="availability.toggle($event)">
        <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>

      <!-- <a class="filter-action" href="javascript:void(0);"  (click)="clickEventFilter()"><i  class="ic dot"></i></a> -->
      <!-- <a class="filter-action" href="javascript:void(0);" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'" (click)="clickEventFilter()">Actions</a> -->
    </div>
    <div class="title-block__right">

      <div *ngIf="isNewManageAvailability && this.showSetAvailButton">
        <a  class="btn btn--secondary history-btn" (click)="onViewHistory()" >
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
            <path d="M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7c2,0,3.9,0.9,5.2,2.3l-1.8,0.3c-0.5,0.1-0.9,0.6-0.8,1.2c0.1,0.5,0.5,0.8,1,0.8
              c0.1,0,0.1,0,0.2,0l4.2-0.8c0.5-0.1,0.9-0.6,0.8-1.2L20,3.5c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5,0.1-0.9,0.6-0.8,1.2l0.3,1.7
              C16.6,4,14.4,3,12,3C8.7,3,5.8,4.8,4.2,7.5c0,0,0,0,0,0c0,0,0,0,0,0C3.4,8.9,3,10.4,3,12s0.4,3.1,1.2,4.5c0,0,0,0,0,0c0,0,0,0,0,0
              C5,17.9,6.1,19,7.5,19.8c0,0,0,0,0,0c0,0,0,0,0,0C8.9,20.6,10.4,21,12,21c0.4,0,0.8-0.3,0.9-0.6C13,20.3,13,20.1,13,20
              c0-0.1,0-0.3-0.1-0.4C12.8,19.3,12.4,19,12,19z" />
            <circle cx="20" cy="12" r="1" />
            <circle cx="18.9" cy="16" r="1" transform="rotate(-60 18.928 16)" />
            <circle cx="16" cy="18.9" r="1" transform="rotate(-30 15.999 18.929)" />
            <path d="M13,11.6V9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.3,0.1,0.5,0.3,0.7l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
              c0.4-0.4,0.4-1,0-1.4L13,11.6z" />
          </svg>
          History
        </a>
      </div>

      &nbsp;

      <button  class="btn btn--primary" (click)="SetAvailabilityPopupClick()">
        Manage
        Availability
      </button>
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <p-overlayPanel #availability [dismissable]="true" [showCloseIcon]="true">
    <div class="overly-filter__outer">
      <h2 class="filter-title">
        {{therapistResponseModel?.appUserLname || 'Not Available'}},
        {{therapistResponseModel?.appUserFname|| ''}}
      </h2>
      <div class="overly-filter__scroll">
        <div class="input-edit-table">
          <img src="assets/img/phone-call.svg" class="callback" width="22" height="22" alt="callback">
          {{therapistResponseModel?.appUserPhoneNumber | slice:0:3}}<span
            *ngIf="therapistResponseModel?.appUserPhoneNumber">-</span>{{
          therapistResponseModel?.appUserPhoneNumber
          |
          slice:3:6
          }}<span *ngIf="therapistResponseModel?.appUserPhoneNumber">-</span>{{
          therapistResponseModel?.appUserPhoneNumber
          |
          slice:6:10
          }}
          <span *ngIf="!therapistResponseModel">Not Available</span>
        </div>
        <div class="input-edit-table">
          <img src="assets/img/email-new.svg" class="callback" width="24" height="24" alt="callback">
          {{therapistResponseModel?.appUserEmail || 'Not Available'}}
        </div>

        <div class="input-edit-table">
          <img src="assets/img/d-facility-ic.svg" width="20" height="20" alt="Facility Visit">
          {{defaultFacility || 'Not Available'}}
        </div>
      </div>
    </div>
  </p-overlayPanel>

  <div class="form-content-outer">

    <div class="therapist-tab-info">
      <p-tabView styleClass="admin-therapists-manage" [activeIndex]="indexTab" (onChange)="handleChange($event)">
        <p-tabPanel header="Bio Information">
          <ng-template pTemplate="header">
            <img class="facility-ic" src="assets/img/bio-info-ic.svg" width="23" height="23" alt="Bio Info">
            <span>Bio Information</span>
          </ng-template>

          <div class="bio-info-tab">
            <div class="therapist-profile mt-0">
              <div class="therapist-profile__avatar">
                <div class="therapist-profile__img-block">
                  <div *ngIf="!isImageSaved" class="therapist-profile__img-block__img">
                    <img src="assets/img/profile-img.png" width="40" alt="Avatar">
                  </div>

                  <div *ngIf="isImageSaved" class="therapist-profile__img-block__img">
                    <img [(src)]="cardImageBase64" alt="Avatar">
                  </div>
                </div>

                <div class="therapist-profile__change-picture">
                  <span>Change Picture</span>
                  <input type="file" placeholder="Choose file" (change)="uploadLogoFile($event)">
                </div>
              </div>
            </div>

            <div class="form-outer">
              <form class="form therapist-tab-info-form" [formGroup]="formPersonal" autocomplete="off">
                <h3 class="first">Bio Information</h3>

                <div class="col">
                  <div class="form__group">
                    <label class="form__label">First Name</label>
                    <input formControlName="firstName" type="text" class="form__control" pInputText maxlength="100">
                    <div *ngIf="formSubmitted && fPersonal.firstName.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.firstName.errors.required || fPersonal.firstName.errors.whitespace">
                        {{validationMessages.firstName.required}}
                      </div>
                      <div *ngIf="fPersonal.firstName.errors.validCharacters">
                        {{validationMessages.firstName.validCharacters}}
                      </div>
                    </div>

                  </div>
                  <div class="form__group">
                    <label class="form__label">Last Name</label>
                    <input formControlName="lastName" type="text" class="form__control" pInputText maxlength="100">
                    <div *ngIf="formSubmitted && fPersonal.lastName.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.lastName.errors.required || fPersonal.lastName.errors.whitespace">
                        {{validationMessages.lastName.required}}
                      </div>
                      <div *ngIf="fPersonal.lastName.errors.validCharacters">
                        {{validationMessages.lastName.validCharacters}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form__group">
                    <label class="form__label">Email</label>
                    <input formControlName="email" type="text" class="form__control" pInputText maxlength="100">
                    <div *ngIf="formSubmitted && fPersonal.email.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.email.errors.required || fPersonal.email.errors.whitespace">
                        {{validationMessages.email.required}}
                      </div>
                      <div *ngIf="fPersonal.email.errors.email">
                        {{validationMessages.email.email}}
                      </div>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label">Phone Number</label>
                    <p-inputMask mask="999-999-9999" formControlName="phoneNumber" type="text" class="form__phone"
                      pInputText maxlength="15"></p-inputMask>
                    <div *ngIf="formSubmitted && fPersonal.phoneNumber.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.phoneNumber.errors.required || fPersonal.phoneNumber.errors.whitespace">
                        {{validationMessages.phoneNumber.required}}
                      </div>

                    </div>

                  </div>
                </div>
                <div class="col" *ngIf="false">
                  <div class="form__group">
                    <label class="form__label">Practitioner Id</label>
                    <input type="text" class="form__control" formControlName="practitionerId" pInputText
                      maxlength="9" />
                    <div *ngIf="formSubmitted && fPersonal.practitionerId.errors" class="invalid-feedback">
                      <div
                        *ngIf="fPersonal.practitionerId.errors.required || fPersonal.practitionerId.errors.whitespace">
                        {{validationMessages.practitionerId.required}}
                      </div>
                      <div *ngIf="fPersonal.practitionerId.errors.validNumber ">
                        {{validationMessages.practitionerId.validNumber}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col col--address mt-15">
                  <h3 class="first">Home Address</h3>
                  <div class="form__group">
                    <label class="form__label">Address</label>
                    <textarea class="form__textarea" formControlName="address" pInputTextarea></textarea>
                    <div *ngIf="formSubmitted && fPersonal.address.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.address.errors.required || fPersonal.address.errors.whitespace">
                        {{validationMessages.address.required}}
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form__group">
                    <label class="form__label">City</label>
                    <input type="text" class="form__control" formControlName="city" pInputText />
                    <div *ngIf="formSubmitted && fPersonal.city.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.city.errors.required || fPersonal.city.errors.whitespace">
                        {{validationMessages.city.required}}
                      </div>

                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label">State</label>
                    <p-dropdown class="select-box" [options]="statsModel" [(ngModel)]="selectedStateBio"
                      placeholder="Select State" formControlName="state" optionLabel="appStateName" [showClear]="true">
                    </p-dropdown>
                    <div *ngIf="formSubmitted && fPersonal.state.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.state.errors.required || fPersonal.state.errors.whitespace">
                        {{validationMessages.state.required}}
                      </div>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label">Zip Code</label>
                    <input type="text" class="form__control" formControlName="zipcode" pInputText maxlength="15" />
                    <div *ngIf="formSubmitted && fPersonal.zipcode.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.zipcode.errors.required || fPersonal.zipcode.errors.whitespace">
                        {{validationMessages.zipcode.required}}
                      </div>
                      <div *ngIf="fPersonal.zipcode.errors.validNumber ">
                        {{validationMessages.zipcode.validNumber}}
                      </div>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label">Brightree Internal User Id</label>
                    <input type="text" class="form__control" formControlName="brightreeInternalUserId" pInputText
                      maxlength="15" />
                    <div *ngIf="formSubmitted && fPersonal.brightreeInternalUserId.errors" class="invalid-feedback">
                      <div
                        *ngIf="fPersonal.brightreeInternalUserId.errors.required || fPersonal.brightreeInternalUserId.errors.whitespace">
                        {{validationMessages.brightreeInternalUserId.required}}
                      </div>
                      <div *ngIf="fPersonal.brightreeInternalUserId.errors.validNumber ">
                        {{validationMessages.brightreeInternalUserId.validNumber}}
                      </div>
                    </div>
                  </div>
                  <div class="form__group">
                    <label class="form__label">Delivery Technician Id</label>
                    <input type="text" class="form__control" formControlName="deliveryTechnicianId" pInputText
                      maxlength="15" />
                    <div *ngIf="formSubmitted && fPersonal.deliveryTechnicianId.errors" class="invalid-feedback">
                      <div
                        *ngIf="fPersonal.deliveryTechnicianId.errors.required || fPersonal.deliveryTechnicianId.errors.whitespace">
                        {{validationMessages.deliveryTechnicianId.required}}
                      </div>
                      <div *ngIf="fPersonal.deliveryTechnicianId.errors.validNumber ">
                        {{validationMessages.deliveryTechnicianId.validNumber}}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <h3 class="first">Biodata / Skills</h3> -->
                <div class="col col--address">
                  <div class="form__group">
                    <label class="form__label">Biodata / Skills</label>
                    <ckeditor [editor]="Editor" formControlName="bioEditorText"
                      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }"></ckeditor>
                    <div *ngIf="formSubmitted && fPersonal.bioEditorText.errors" class="invalid-feedback">
                      <div *ngIf="fPersonal.bioEditorText.errors.required || fPersonal.bioEditorText.errors.whitespace">
                        {{validationMessages.bioEditorText.required}}
                      </div>

                    </div>
                  </div>
                </div>

                <div class="set-custom-date-btn">
                  <button class="btn btn--primary" (click)="onSubmit()">Submit</button>
                  <button class="btn btn--secondary" (click)="onResetPersonalForm()">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </p-tabPanel>
        
        <p-tabPanel header="License information" [disabled]="isTabDisabled">
          <ng-template pTemplate="header">

            <img class="facility-ic" src="assets/img/license-ic.svg" alt="medical-certificate" width="25" height="25">
            License Information
          </ng-template>

          <div class="form-outer">
            <div class="form therapist-tab-info-form">
              <h3 class="first">License Information</h3>

              <div class="col">
                <div class="form__vertical__table license-table">
                  <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="isLicenseChecked"
                    label="Notify before licenses expire" (onChange)="onNotifyLicenseCheck($event)">
                  </p-checkbox>
                </div>
              </div>

              <form class="form license-information" [formGroup]="formLicense" (ngSubmit)="onLicenseAdd()"
                autocomplete="off">
                <div class="col">
                  <div class="form__group LicenseState">
                    <label class="form__label">License State</label>
                    <p-dropdown class="select-box" [options]="statsModel" [(ngModel)]="selectedState"
                      placeholder="Select State" formControlName="licenseState" optionLabel="appStateName"
                      [showClear]="true"></p-dropdown>
                    <div *ngIf="formLicenseSubmitted && fLicense.licenseState.errors" class="invalid-feedback">
                      <div *ngIf="fLicense.licenseState.errors.required || fLicense.licenseState.errors.whitespace">
                        {{validationMessages.licenseState.required}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col">
                  <div class="form__group">
                    <label class="form__label">License No.</label>
                    <input type="text" class="form__control" pInputText formControlName="licenseNumber"
                      maxlength="50" />
                    <div *ngIf="formLicenseSubmitted && fLicense.licenseNumber.errors" class="invalid-feedback">
                      <div *ngIf="fLicense.licenseNumber.errors.required || fLicense.licenseNumber.errors.whitespace">
                        {{validationMessages.licenseNumber.required}}
                      </div>
                    </div>
                  </div>
                  <div class="form__group form__group--plus">
                    <label class="form__label">License Expiry</label>
                    <!--<input type="text" class="form__control" pInputText formControlName="licenseExpiry" />-->
                    <p-calendar class="datepicker license-expiry-date" placeholder="Expiry Date" [minDate]="minimumDate"
                      dateFormat="mm/dd/yy" yearRange="2018:2050" [yearNavigator]="true" [showIcon]="true"
                      [(ngModel)]="licenseExpiryDate" formControlName="licenseExpiry">
                    </p-calendar>

                    <div *ngIf="formLicenseSubmitted && fLicense.licenseExpiry.errors" class="invalid-feedback">
                      <div *ngIf="fLicense.licenseExpiry.errors.required || fLicense.licenseExpiry.errors.whitespace">
                        {{validationMessages.licenseExpiry.required}}
                      </div>
                    </div>
                    <a class="add-btn" href="javascript:void(0)" (click)="onLicenseAdd()">
                      <i class="ic plus"></i>
                    </a>
                  </div>
                </div>
              </form>

              <div class="form__vertical__table license-table" *ngIf="licenseResponseModel?.length>0">
                <table>
                  <tr>
                    <!-- <th></th> -->
                    <th>License State</th>
                    <th>License No.</th>
                    <th>License Expiry</th>
                    <th width="75">Action</th>
                  </tr>
                  <tr *ngFor="let license of licenseResponseModel; let last = last; let first = first;">
                    <!-- <td>
                      <p-checkbox binary="true" value="{{license.licenceNumber}}" [ngModel]="ngModelForLicense(license)" (onChange)="onCheck(license,$event)" ></p-checkbox>
                    </td> -->
                    <td><strong>{{license?.stateMaster?.appStateName}}</strong></td>
                    <td>{{license?.licenceNumber}}</td>
                    <td>{{license?.expiryDate | date:appDateFormat.dateFormat}}</td>
                    <td>
                      <div class="action-column">
                        <a class="actionmenu-btn" href="javascript:void(0)" (click)="userAction1.toggle($event)">
                          <!-- <i class="ic dot"></i> -->
                          <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                          </svg>
                        </a>
                      </div>

                      <p-overlayPanel class="table-action-popup" appendTo="body" #userAction1>
                        <ul class="quick-link-menu">
                          <li>
                            <a class="edit-row" href="javascript:void(0)" (click)="onLicenseEdit(license)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                                viewBox="0 0 103.901 98.574">
                                <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                                  <path id="Path_3" data-name="Path 3"
                                    d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                                    transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="8" />
                                  <path id="Path_4" data-name="Path 4"
                                    d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                                    transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="8" />
                                </g>
                              </svg>
                              Edit
                            </a>
                          </li>
                          <li>
                            <a class="delete-row" href="javascript:void(0)" (click)="onLicenseDelete(license)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                                <g id="delete-icon-new" transform="translate(-3 -1)">
                                  <path id="Path_1" data-name="Path 1"
                                    d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                                    fill="#6f64a7" />
                                  <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                                    fill="#6f64a7" />
                                  <path id="Path_3" data-name="Path 3"
                                    d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                                    fill="#6f64a7" />
                                  <path id="Path_4" data-name="Path 4"
                                    d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                                  <path id="Path_5" data-name="Path 5"
                                    d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                                  <path id="Path_6" data-name="Path 6"
                                    d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                                </g>
                              </svg>
                              Delete
                            </a>
                          </li>
                        </ul>
                      </p-overlayPanel>
                    </td>
                  </tr>
                </table>
              </div>


              <div class="set-custom-date-btn">
                <button class="btn btn--primary" (click)="onLicenseSubmit()"
                  [disabled]="disablebutton.LicenseButton">Submit</button>
                <button class="btn btn--secondary" (click)="reValuesToLicenseForm()">Cancel</button>
              </div>
            </div>
          </div>

        </p-tabPanel>

        <p-tabPanel header="Service Coverage" [disabled]="isTabDisabled">
          <ng-template pTemplate="header">
            <img class="facility-ic" src="assets/img/service-coverage-ic.svg" alt="doctor-icons" width="27" height="27">
            <span>Service Coverage</span>           
          </ng-template>

          <div class="form-outer">
            <form class="form therapist-tab-info-form" [formGroup]="formService" autocomplete="off">
              <h3 class="first">Service Coverage</h3>
              <div class="col col--service-coverage">
                <div class="form__group">
                  <label class="form__label">Default Facility

                  <span
                  style="color:red"
                  pTooltip="The therapist is required to holds the state license of the state of default facility.">
                  <svg style="margin: 0 0 -3px 5px;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                    viewBox="0 0 18 18" fill="none">
                    <path
                      d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
                      fill="#3E3E3E" />
                    <path
                      d="M10.0463 7.4115L7.47001 7.73438L7.37776 8.16188L7.88401 8.25525C8.21476 8.334 8.28001 8.45325 8.20801 8.78288L7.37776 12.6844C7.15951 13.6935 7.49588 14.1683 8.28676 14.1683C8.89988 14.1683 9.61201 13.8848 9.93488 13.4955L10.0339 13.0275C9.80888 13.2255 9.48038 13.3043 9.26213 13.3043C8.95276 13.3043 8.84026 13.0871 8.92013 12.7046L10.0463 7.4115ZM10.125 5.0625C10.125 5.36087 10.0065 5.64702 9.7955 5.85799C9.58452 6.06897 9.29838 6.1875 9.00001 6.1875C8.70164 6.1875 8.41549 6.06897 8.20451 5.85799C7.99353 5.64702 7.87501 5.36087 7.87501 5.0625C7.87501 4.76413 7.99353 4.47798 8.20451 4.267C8.41549 4.05603 8.70164 3.9375 9.00001 3.9375C9.29838 3.9375 9.58452 4.05603 9.7955 4.267C10.0065 4.47798 10.125 4.76413 10.125 5.0625Z"
                      fill="#3E3E3E" />
                  </svg>
                </span>
              </label>

                  <p-dropdown class="select-box" formControlName="facilityList" [(ngModel)]="selectedFacility"
                    placeholder="Select" optionLabel="crmFacilityName" [options]="facilityList"
                    dataKey="crmFacilityName" [showClear]="true">
                  </p-dropdown>
                  <div *ngIf="formServiceSubmitted && fService.facilityList.errors" class="invalid-feedback">
                    <div *ngIf="fService.facilityList.errors.required">
                      {{validationMessages.facilityList.required}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <!-- <h3>Set Coverage Area for Road Show/Home Appointment</h3> -->
                <div class="form__group flex">
                  <!-- <label class="form__label">Set Coverage Area for Road Show/Home Appointment</label> -->
                  <label class="form__label">Road Show / Home Appointment Coverage Area</label>
                  <div style="width: 100%;">
                    <input formControlName="coverageArea" type="text" class="form__control" pInputText
                      style="max-width: 100px; margin-right: 1rem;" maxlength="4" />
                    <span>Miles</span>
                  </div>
                  <div *ngIf="formServiceSubmitted && fService.coverageArea.errors" class="invalid-feedback">
                    <div *ngIf="fService.coverageArea.errors.required || fService.coverageArea.errors.whitespace">
                      {{validationMessages.coverageArea.required}}
                    </div>
                    <div *ngIf="fService.coverageArea.errors.validNumber">
                      {{validationMessages.coverageArea.validNumber}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="isViewSelfScheduleAppointments">
                <!-- <h3>Set Coverage Area for Road Show/Home Appointment</h3> -->
                <div class="form__group flex">
                  <!-- <label class="form__label">Set Coverage Area for Road Show/Home Appointment</label> -->
                  <label class="form__label">Self Scheduling Permission</label>
                  <div style="width: 100%;" class="switchBtn">
                    <!-- <input formControlName="coverageArea" type="text" class="form__control" pInputText
                      style="max-width: 100px; margin-right: 1rem;" maxlength="4" />
                    <span>Miles</span> -->
                    <p-inputSwitch [(ngModel)]="isAllowSelfScheduleAppointments"
                      [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{isAllowSelfScheduleAppointments ?
                    'Active'
                    : 'Inactive'}}
                  </div>
                </div>
              </div>

              <div class="set-custom-date-btn">
                <button class="btn btn--primary" (click)="onServiceSubmit()">Submit</button>
                <button class="btn btn--secondary" (click)="onResetServieForm()">Cancel</button>
              </div>
            </form>

          </div>


        </p-tabPanel>

        <p-tabPanel header="Online Meeting Setup" [disabled]="isTabDisabled">
          <ng-template pTemplate="header">
            <img class="facility-ic" src="assets/img/online-meetin-ic.svg" alt="customer-service" width="23"
              height="23">
            <span>Online Meeting Setup</span>
          </ng-template>

          <div class="form-outer">

            <form class="form therapist-tab-info-form" [formGroup]="formMeeting" autocomplete="off">
              <h3 class="first">Online Meeting Setup</h3>
              <div class="col col--address">
                <div class="form__group">
                  <label class="form__label">Online Meeting Link</label>
                  <input type="text" class="form__control" formControlName="meetingUrl" pInputText />
                  <div *ngIf="formMeetingSubmitted && fMeeting.meetingUrl.errors" class="invalid-feedback">
                    <div *ngIf="fMeeting.meetingUrl.errors.required || fMeeting.meetingUrl.errors.whitespace">
                      {{validationMessages.meetingUrl.required}}
                    </div>
                    <div *ngIf="fMeeting.meetingUrl.errors.validZoomURL">
                      {{validationMessages.meetingUrl.validZoomURL}}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col">
                <div class="form__group meeting-input">
                  <label class="form__label">Meeting ID</label>
                  <input type="text" class="form__control" formControlName="meetingId" pInputText />
                  <!-- <div *ngIf="formMeetingSubmitted && fMeeting.meetingId.errors" class="invalid-feedback">
                    <div *ngIf="fMeeting.meetingId.errors.validNumber">
                      {{validationMessages.meetingId.validNumber}}
                    </div>
                  </div> -->
                </div>


                <div class="form__group meeting-input">
                  <label class="form__label">Passcode</label>
                  <input type="password" class="form__control" formControlName="meetingPasscode" pInputText />
                </div>
              </div>

              <div class="set-custom-date-btn">
                <button class="btn btn--primary" (click)="onMeetingSubmit()">Submit</button>
                <button class="btn btn--secondary" (click)="onResetOnlineMeeingForm()">Cancel</button>
              </div>
            </form>
          </div>

        </p-tabPanel>

        <p-tabPanel header="Device Certification" [disabled]="isTabDisabled">
          <ng-template pTemplate="header">
            <img class="facility-ic" src="assets/img/device-certification-ic.svg" alt="device-certification" width="24"
              height="24">
            <span>Device Certification</span>
          </ng-template>


          <div class="form-outer">
            <div class="form therapist-tab-info-form">
              <h3>Device Certification</h3>
              <div class="col">
                <form class="form license-information" [formGroup]="formDevice" (ngSubmit)="onCertificationAdd()"
                  autocomplete="off">
                  <div class="col">
                    <div class="form__group">
                      <label class="form__label">Device Name</label>
                      <!-- <p-dropdown class="select-box" [options]="device" placeholder="Select" optionLabel="name"
                                  formControlName="deviceName" [(ngModel)]="selectedDevice" [showClear]="true">
                      </p-dropdown> -->
                      <p-multiSelect class="select-box" [options]="device" placeholder="Select" optionLabel="name"
                        (onChange)="OnDeviceNameChange($event)" formControlName="deviceName"
                        [(ngModel)]="selectedDevices" selectedItemsLabel="{0} Devices Selected">
                      </p-multiSelect>
                      <div *ngIf="formDeviceSubmitted && fDevice.deviceName.errors" class="invalid-feedback">
                        <div *ngIf="fDevice.deviceName.errors.required || fDevice.deviceName.errors.whitespace">
                          {{validationMessages.deviceName.required}}
                        </div>
                      </div>
                    </div>
                    <div class="form__group form__group--plus">
                      <label class="form__label">Certificate</label>
                      <input type="text" class="form__control" pInputText formControlName="deviceCertificate"
                        maxlength="50" />
                      <div *ngIf="formDeviceSubmitted && fDevice.deviceCertificate.errors" class="invalid-feedback">
                        <div
                          *ngIf="fDevice.deviceCertificate.errors.required || fDevice.deviceCertificate.errors.whitespace">
                          {{validationMessages.deviceCertificate.required}}
                        </div>
                      </div>
                      <a class="add-btn" href="javascript:void(0)" (click)="onCertificationAdd()">
                        <i class="ic plus"></i>
                      </a>
                    </div>
                  </div>
                </form>
                <div class="form__vertical__table" *ngIf="certificationResponseModel?.length>0">
                  <table>
                    <tr>
                      <th>Device Name</th>
                      <th>Certificate</th>
                      <th width="75">Action</th>
                    </tr>
                    <tr *ngFor="let certification of certificationResponseModel; let last = last; let first = first;">
                      <td><strong> {{certification?.deviceName}}</strong></td>
                      <td>{{certification?.certificateNumber}}</td>
                      <td>
                        <div class="action-column">
                          <a class="actionmenu-btn" href="javascript:void(0)" (click)="userAction.toggle($event)">
                            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            </svg>
                          </a>
                        </div>

                        <!-- <a class="form__vertical__table__add-btn" href="javascript:void(0)"
                          (click)="onCertificationEdit(certification)">
                          <i class="edit-icon-new"></i>
                        </a>
                        <a class="form__vertical__table__add-btn" href="javascript:void(0)"
                          (click)="onCertificationDelete(certification)">
                          <i class="ic delete"></i>
                        </a> -->

                        <p-overlayPanel class="table-action-popup" appendTo="body" #userAction>
                          <ul class="quick-link-menu">
                            <li>
                              <!-- <a href="javascript:void(0)" (click)="onCertificationEdit(certification)">
                                <i class="edit-icon-new"></i>Edit
                              </a> -->

                              <a class="edit-row" href="javascript:void(0)"
                                (click)="onCertificationEdit(certification)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                                  viewBox="0 0 103.901 98.574">
                                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                                    <path id="Path_3" data-name="Path 3"
                                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                                      transform="translate(-202.246)" fill="none" stroke="#6f64a7"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                                    <path id="Path_4" data-name="Path 4"
                                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                                  </g>
                                </svg>
                                Edit
                              </a>
                            </li>
                            <li>
                              <!-- <a href="javascript:void(0)" (click)="onCertificationDelete(certification)">
                                <i class="delete-icon-new"></i>Delete
                              </a> -->

                              <a class="delete-row" href="javascript:void(0)"
                                (click)="onCertificationDelete(certification)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                                  <g id="delete-icon-new" transform="translate(-3 -1)">
                                    <path id="Path_1" data-name="Path 1"
                                      d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                                      fill="#6f64a7" />
                                    <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                                      fill="#6f64a7" />
                                    <path id="Path_3" data-name="Path 3"
                                      d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                                      fill="#6f64a7" />
                                    <path id="Path_4" data-name="Path 4"
                                      d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                                    <path id="Path_5" data-name="Path 5"
                                      d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                                    <path id="Path_6" data-name="Path 6"
                                      d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                                  </g>
                                </svg>
                                Delete
                              </a>
                            </li>
                          </ul>
                        </p-overlayPanel>
                      </td>
                    </tr>
                  </table>
                </div>


              </div>
              <div class="set-custom-date-btn">
                <button class="btn btn--primary" (click)="onDeviceSubmit()"
                  [disabled]="disablebutton.DeviceButton">Submit</button>
                <button class="btn btn--secondary" (click)="reSetValuesToDeviceForm()">Cancel</button>
              </div>
            </div>

          </div>

        </p-tabPanel>
      </p-tabView>
    </div>
    <div class="loader-block" *ngIf="loading" style="z-index: 99999999 !important;">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
        animationDuration=".8s">
      </p-progressSpinner>
    </div>
    <!-- <div class="therapist-info-content">
        Therapist info content
    </div> -->
    <!--=====================-->
    <!--- End New Component -->
    <!--=====================-->
    <!-- </p-scrollPanel> -->
  </div>
</div>

<!-- End Content -->
<p-dialog [(visible)]="SetAvailabilityPopup" [modal]="true" class="patient-popup">
  <div class="set-availability-popup">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">

      <p-tabView [activeIndex]="index">
        <div class="popup-set-availability">
          <p-tabPanel header="Add" (click)="onResetIndex(0)">
            <ng-template pTemplate="content">

              <div class="working-redio-btn">
                <ul>
                  <li class="event-series">
                    <span class="event-series">
                      <p-radioButton name="group1" [ngModelOptions]="{standalone: true}" value="W"
                        label="Business Hours" [(ngModel)]="selectedWorking" (click)="SetWorking()"></p-radioButton>
                      <p-radioButton name="group1" [ngModelOptions]="{standalone: true}" value="N" label="Custom"
                        [(ngModel)]="selectedWorking" (click)="SetWorking()">
                      </p-radioButton>
                    </span>
                  </li>
                </ul>
              </div>

              <div class="working-redio-btn" *ngIf="selectedWorking=='W'">
                <ul>
                  <li class="event-series">
                    <span class="event-series">
                      <p-checkbox [(ngModel)]='isRemoteAssistant' (onChange)="onRemoteAssistantChange($event)"
                        binary="true" label="Remote Assistant"></p-checkbox>
                    </span>
                  </li>
                  <li class="event-series">
                    <span class="event-series">
                      <p-checkbox [(ngModel)]='isRoadShow' binary="true" label="Road Show"
                        (onChange)="onRoadShowChange($event)"></p-checkbox>
                    </span>
                  </li>
                </ul>
              </div>

              <div class="popup-facility-block">
                <span class="event-series">Facility</span>
                <span class="event-series-right">
                  <p-dropdown class="select-box" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFacilities"
                    placeholder="Select" optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityName"
                    [showClear]="true">
                  </p-dropdown>
                </span>

                <!-- <div class="set-custom-date-btn">
                  <button class="btn btn--primary" (click)="onSave()" type="button">Submit</button>
                </div> -->
              </div>

              <div class="working-schedule__set-custom-date">
                <div class="set-custom-date-block">
                  <div class="custom-event">
                    <div class="custom-event__left">
                      <ul>
                        <li class="event-series" *ngIf="selectedWorking=='N'">
                          <span class="event-series">
                            Event Description
                          </span>
                          <span class="event-series-select">
                            <input type="text" class="form__control" pInputText maxlength="20"
                              [(ngModel)]="description">
                          </span>
                        </li>
                        <li class="event-series" *ngIf="isRemoteAssistant || isRoadShow || selectedWorking=='N'">
                          <span class="event-series">
                            Event Series
                          </span>
                          <span class="event-series-select">
                            <ul>
                              <li *ngFor="let item of eventSeries">
                                <a href="javascript:void(0)" ngDefaultControl
                                  [ngClass]="item.isSelected ? 'active' : '' "
                                  (click)="onChangeEventSeries(item)">{{item.name}}</a>
                              </li>
                            </ul>
                          </span>
                        </li>
                        <li class="event-series" *ngIf="isRemoteAssistant || isRoadShow || selectedWorking=='N'">
                          <span class="event-series">
                            Every
                          </span>
                          <span class="event-series-select">
                            <p-dropdown class="select-box" [options]="Occurrence" optionLabel="name"
                              placeholder="Select" [(ngModel)]="selectedOccurrence"
                              [ngModelOptions]="{standalone: true}" (onChange)="onEventOccurrenceChange($event)">
                            </p-dropdown>
                          </span>
                        </li>
                        <li class="event-series">
                          <span class="event-series">
                            Day of Week
                          </span>
                          <span class="event-series-select">
                            <ul>
                              <li *ngFor="let item of weekDays">
                                <a href="javascript:void(0)" ngDefaultControl (click)="onChangeDayOfWeek(item)"
                                  [ngClass]="item.isSelected ? 'active' : '' ">{{item.name}}</a>
                              </li>
                            </ul>
                          </span>
                        </li>
                        <li class="event-series">
                          <span class="event-series">
                            Start Date
                          </span>
                          <span class="event-series-select">
                            <span class="datepikar">
                              <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customStartDate"
                                [ngModelOptions]="{standalone: true}"></p-calendar>
                              <small>End Date</small>
                              <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customEndDate"
                                [ngModelOptions]="{standalone: true}"></p-calendar>
                            </span>
                          </span>
                        </li>
                        <li class="event-series">
                          <span class="event-series">
                            Start Time
                          </span>
                          <span class="event-series-select select2">
                            <span class="datepikar">
                              <p-calendar class="date-pikar date-timer" [showIcon]="true" [timeOnly]="true"
                                hourFormat="12" [(ngModel)]="customStartTime" [ngModelOptions]="{standalone: true}">
                              </p-calendar>
                              <small class="end-time">End Time</small>
                              <p-calendar class="date-pikar date-timer last" [showIcon]="true" [timeOnly]="true"
                                hourFormat="12" [(ngModel)]="customEndTime" [ngModelOptions]="{standalone: true}">
                              </p-calendar>
                            </span>
                          </span>
                        </li>
                        <li class="event-series">
                          <span class="event-series">
                            Break Start Time
                          </span>
                          <span class="event-series-select select2">
                            <span class="datepikar">
                              <p-calendar class="date-pikar date-timer" [showIcon]="true" [timeOnly]="true"
                                hourFormat="12" [(ngModel)]="breakStartTime" [ngModelOptions]="{standalone: true}">
                              </p-calendar>
                              <small class="end-time">Break End Time</small>
                              <p-calendar class="date-pikar date-timer last" [showIcon]="true" [timeOnly]="true"
                                hourFormat="12" [(ngModel)]="breakEndTime" [ngModelOptions]="{standalone: true}">
                              </p-calendar>
                            </span>
                          </span>
                        </li>

                      </ul>

                      <div class="set-custom-date-btn">
                        <button class="btn btn--primary" (click)="onSave()" type="button">
                          Submit
                        </button>
                        <!-- <button class="btn btn--primary" (click)="onAddAvailability()">Add</button> -->
                        <button class="btn btn--secondary" (click)="onCustomCancel()">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel header="View" (click)="onResetIndex(1)">
            <ng-template pTemplate="content">
              <div class="add-btn-block" *ngIf="false">
                <button class="btn btn--primary add-btn" (click)="onAdd()">Add</button>
              </div>
              <div class="popup-tables">
                <h2 *ngIf="multiAvailability?.length>0">Business Hours</h2>
                <div class="popup-table" style="margin-bottom: 2.5rem !important;" *ngIf="multiAvailability?.length>0">
                  <table class="table">
                    <tr>
                      <th>
                        <strong>Facility</strong>
                      </th>
                      <th>
                        <strong>Week Days</strong>
                      </th>
                      <th>
                        <strong>Start Date</strong>
                      </th>
                      <th>
                        <strong>Start Time</strong>
                      </th>
                      <th>
                        <strong>End Date</strong>
                      </th>
                      <th>
                        <strong>End Time</strong>
                      </th>
                      <th>
                        <strong>Break Start Time</strong>
                      </th>
                      <th>
                        <strong>Break End Time</strong>
                      </th>

                      <th>
                        <strong>Remote Assistant</strong>
                      </th>
                      <th>
                        <strong>Road Show</strong>
                      </th>
                      <th>
                        <strong>Actions</strong>
                      </th>
                    </tr>
                    <tr
                      *ngFor="let customAvailability of multiAvailability; let last = last; let first = first;let i=index">
                      <td>
                        <strong>{{customAvailability?.crmFacilityName}}</strong>
                      </td>
                      <td>
                        <strong>{{customAvailability.dayOfWeek}}</strong>
                      </td>
                      <td>{{customAvailability.crmTherpistAvailFromDate | date}}</td>
                      <td>
                        {{customAvailability.crmTherpistAvailFromTimeDate| date: 'hh:mm a' }}
                      </td>
                      <td>{{customAvailability.crmTherpistAvailToDate | date}}</td>
                      <td>{{customAvailability.crmTherpistAvailToTimeDate | date: 'hh:mm a'}}</td>
                      <td>{{customAvailability.crmBreakStartTimeDate | date: 'hh:mm a' }}</td>
                      <td>{{customAvailability.crmBreakEndTimeDate | date: 'hh:mm a'}}</td>
                      <td>
                        <div>
                          {{customAvailability.isRemoteAssistant?'Remote Assistant':''}}
                        </div>
                      </td>
                      <td>
                        <div>{{customAvailability.crmIsRoadShow?'Road Show':''}}</div>
                      </td>
                      <td>
                        <div class="action-column">
                          <a class="actionmenu-btn" href="javascript:void(0)" (click)="userAction2.toggle($event)">
                            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            </svg>
                          </a>
                        </div>
                        <!--<a class="form__vertical__table__add-btn" href="javascript:void(0)"
                        (click)="onCustomEdit(customAvailability,i)"><i class="edit-icon-new"></i></a>-->
                        <!-- <a  href="javascript:void(0)" (click)="onDelete(i)"><i
                            class="ic delete"></i></a> -->

                        <p-overlayPanel class="table-action-popup" appendTo="body" #userAction2>
                          <ul class="quick-link-menu">
                            <li>
                              <a class="edit-row" href="javascript:void(0)"
                                (click)="onCustomEdit(customAvailability,i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                                  viewBox="0 0 103.901 98.574">
                                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                                    <path id="Path_3" data-name="Path 3"
                                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                                      transform="translate(-202.246)" fill="none" stroke="#6f64a7"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                                    <path id="Path_4" data-name="Path 4"
                                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="8" />
                                  </g>
                                </svg>
                                Edit
                              </a>
                            </li>
                            <li>
                              <a class="delete-row" href="javascript:void(0)" (click)="onDelete(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                                  <g id="delete-icon-new" transform="translate(-3 -1)">
                                    <path id="Path_1" data-name="Path 1"
                                      d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                                      fill="#6f64a7" />
                                    <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                                      fill="#6f64a7" />
                                    <path id="Path_3" data-name="Path 3"
                                      d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                                      fill="#6f64a7" />
                                    <path id="Path_4" data-name="Path 4"
                                      d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                                    <path id="Path_5" data-name="Path 5"
                                      d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                                    <path id="Path_6" data-name="Path 6"
                                      d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                                  </g>
                                </svg>
                                Delete</a>
                            </li>
                          </ul>
                        </p-overlayPanel>
                      </td>
                    </tr>
                  </table>
                </div>
                <h2 *ngIf="multipleTherapistCustomAvailability?.length>0">Custom</h2>
                <div class="popup-table" *ngIf="multipleTherapistCustomAvailability?.length>0">
                  <table class="table">
                    <tr>
                      <th>
                        <strong>Facility</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Event Series</strong>
                      </th>
                      <th>
                        <strong>Occurrence</strong>
                      </th>
                      <th>
                        <strong>WeekDays</strong>
                      </th>
                      <th>
                        <strong>Start Date</strong>
                      </th>
                      <th>
                        <strong>Start Time</strong>
                      </th>
                      <th>
                        <strong>End Date</strong>
                      </th>
                      <th>
                        <strong>End Time</strong>
                      </th>
                      <th>
                        <strong>Break Start Time</strong>
                      </th>
                      <th>
                        <strong>Break End Time</strong>
                      </th>
                      <th>
                        <strong>Holiday</strong>
                      </th>
                      <th>
                        <strong>Remote Assistant</strong>
                      </th>
                      <th>
                        <strong>Road Show</strong>
                      </th>
                      <th>
                        <strong>Actions</strong>
                      </th>
                    </tr>
                    <tr
                      *ngFor="let customAvailability of multipleTherapistCustomAvailability; let last = last; let first = first;let i=index">
                      <td>
                        <strong>{{customAvailability?.crmFacilityName}}</strong>
                      </td>
                      <td>
                        <strong>{{customAvailability?.description}}</strong>
                      </td>
                      <td>
                        <strong>{{customAvailability?.eventName}}</strong>
                      </td>
                      <td>{{customAvailability.crmRecurringFrequency}}</td>
                      <td>

                        <strong>{{customAvailability.dayOfWeek}}</strong>

                      </td>
                      <td>{{customAvailability.crmTherapistCustomStartDate | date}}</td>
                      <td>
                        {{customAvailability.crmTherapistCustomStartTimeDate| date: 'hh:mm a' }}
                      </td>
                      <td>{{customAvailability.crmTherapistCustomEndDate | date}}</td>
                      <td>
                        {{customAvailability.crmTherapistCustomEndTimeDate | date: 'hh:mm a'}}
                      </td>
                      <td>{{customAvailability.crmBreakStartTimeDate | date: 'hh:mm a' }}</td>
                      <td>{{customAvailability.crmBreakEndTimeDate | date: 'hh:mm a'}}</td>
                      <td>
                        <div>{{customAvailability.crmIsHoliday?'Holiday':''}}</div>
                      </td>
                      <td>
                        <div>
                          {{customAvailability.isRemoteAssistant?'Remote Assistant':''}}
                        </div>
                      </td>
                      <td>
                        <div>{{customAvailability.crmIsRoadShow?'Road Show':''}}</div>
                      </td>
                      <td>
                        <!--<a class="form__vertical__table__add-btn" href="javascript:void(0)"
                        (click)="onCustomEdit(customAvailability,i)"><i class="edit-icon-new"></i></a>-->
                        <a class="form__vertical__table__add-btn" href="javascript:void(0)"
                          (click)="onCustomDelete(i)"><i class="delete-icon-new"></i></a>
                      </td>
                    </tr>
                  </table>
                </div>
                <br>

              </div>
            </ng-template>
          </p-tabPanel>
        </div>
      </p-tabView>

      <div class="working-schedule">
      </div>
    </p-scrollPanel>
  </div>
</p-dialog>
