<!-- Start Content -->
<div class="content-area admin-calendar-outer">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="calendar-top-right" *ngIf="false">
      <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
                  [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()" placeholder="Select Calendar View"
                  optionLabel="name" [showClear]="true"  appendTo="body"></p-dropdown>
    </div>
    <div class="table-filter__sort-by">
      <a href="javascript:void(0);" pTooltip="Therapist Availability" tooltipPosition="left" class="add-plus" (click)="ManageAvailability(null)"><img src="assets/img/add-plus.svg" width="17" alt="filters"></a>
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>

  </div>
  <div class="calendra-top-btn">
    <div class="btn-group calendra-next-prev-btn">

      <div class="mwl-calendar">
        <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
             (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
          <i class="arrow-prev"></i>
        </div>
        <div class="btn btn--primary" mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay()"
             [(viewDate)]="viewDate" (click)="dateChange()">
          Today
        </div>

        <div class="btn btn--arrow next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
             (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
          <i class="arrow-next"></i>
        </div>
        <h4>
          {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}
        </h4>
      </div>
    </div>
    <div class="calendra-top-btn__facility-box" *ngIf="false">
      <p-dropdown class="select-box select-facility month-calendar" [options]="facilities"
                  [(ngModel)]="selectedFacility" placeholder="Facility" optionLabel="crmFacilityName" (onChange)="dateChange()">
      </p-dropdown>
    </div>
  </div>

  <div class="calendar-outer">
    <div class="view-by-available-slots">

      <ng-template #monthCellTemplate let-day="day" let-locale="locale">
        <div class="cal-cell-top">
          <span class="add-icon" *ngIf="EnableForAvaliablity(day.events)" (click)="ManageAvailability(day.date)"> <i class="ic plus small" pTooltip="Add Therapist Availability"></i> </span>
          <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
        </div>
        <div *ngFor="let item of day.events">
          <div class="available-slots_event" *ngIf="!item.isGlobalHoliday" (click)="onEventClick($event,item,RescheduleAppointment,item.title1)">
            <small *ngIf="item.isHoliday && item.isFullDay"
                   [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
                   pTooltip="{{ item.title1 }}">{{ item.title }}</small>

            <small *ngIf="item.isHoliday && !item.isFullDay"
                   [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
                   pTooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}">{{ item.title }}</small>

            <small *ngIf="!item.isHoliday"
                   [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event office':'view-by-available-slots_event'"
                   pTooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}">{{ item.title }}</small>
          </div>

          <div class="available-slots_event" *ngIf="item.isGlobalHoliday">
            <small class="view-by-available-slots_event">{{ item.title }}</small>
          </div>
        </div>
      </ng-template>

      <p-overlayPanel class="table-action-popup facilities-action" #RescheduleAppointment [dismissable]="true"
                      (onHide)="closeOverlay()">
        <ul class="quick-link-menu">
          <li>
            <a href="javascript:void(0)" (click)="EditSeries($event, RescheduleAppointment)"><i class="edit-icon-new"></i>Edit Series</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="Edit($event, RescheduleAppointment)"><i class=" edit-icon-new"></i>Edit this Event</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="Delete($event, RescheduleAppointment)"><i class="delete-icon-new"></i>Delete this Event</a>
          </li>
        </ul>
      </p-overlayPanel>

      <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->

      <div [ngSwitch]="view" class="day-view">
        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                                 [cellTemplate]="monthCellTemplate">
        </mwl-calendar-month-view>

      </div>
      <!-- </p-scrollPanel> -->
    </div>
    <ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>

    <div class="loader-block" *ngIf="loadingCustom===true" style="z-index:99999999 !important">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </div>

  <p-dialog class="therapist-availability-popup" [(visible)]="showPopup" [modal]="true">
    <div class="popup-content therapist-availability-set">
      <div class="therapist-availability-set__header">
        <h2>Therapist Availability</h2>
        <span class="therapist-availability-set__event-series">
          <p-radioButton name="group3" value="A" label="Available" [(ngModel)]="selectedEvent1"
                         (click)="setEventsExtended()"></p-radioButton>
          <p-radioButton name="group3" value="NA" label="Not Available" [(ngModel)]="selectedEvent1"
                         (click)="setEventsExtended()"></p-radioButton>
        </span>
      </div>

      <div class="therapist-availability-set__content">

        <div class="therapist-availability-set__col" *ngIf="selectedEvent1=='A'">
          <div class="redio-btn-tab">
            <div class="redio-btn-tab__border">
              <p-radioButton name="group2" value="WOT" label="Facility & Remote Setup" [(ngModel)]="selectedEvent"
                             (click)="SetEvents()"></p-radioButton>
              <p-radioButton name="group2" value="R" label="Road/Home Appt" [(ngModel)]="selectedEvent"
                             (click)="SetEvents()"></p-radioButton>
              <p-radioButton name="group2" value="O" label="Office" [(ngModel)]="selectedEvent" (click)="SetEvents()">
              </p-radioButton>
            </div>
          </div>
        </div>
        <div class="scroll-bar-outer">

          <p-scrollPanel styleClass="therapist-availabilitycontent-scroll-bar" [style]="{width: '100%', height: '100%'}">
            <div class="therapist-availability-set__col" *ngIf="selectedEvent=='WOT'">
              <div class="select-facility">
                <p-radioButton name="group4" value="FacilityOnly" label="Facility"
                               (click)="SetEventsFacilityOption()" [(ngModel)]="RemoteAndFacility"></p-radioButton>
                <p-radioButton name="group4" value="RemoteOnly" label="Remote Setup"
                               (click)="SetEventsFacilityOption()" [(ngModel)]="RemoteAndFacility"></p-radioButton>
                <p-radioButton name="group4" value="Both" label="Both Facility & Remote" (click)="SetEventsFacilityOption()"
                               [(ngModel)]="RemoteAndFacility"></p-radioButton>
              </div>

              <div class="therapist-availability-set__two-column">
                <div class="form__group" *ngIf="RemoteAndFacility=='FacilityOnly'">
                  <label class="form__label">Facility</label>
                  <p-dropdown class="select-box"  appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                              optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                              (onChange)="setFacilityName();getTime();">
                  </p-dropdown>
                </div>

                <div class="form__group" *ngIf="RemoteAndFacility=='RemoteOnly'">
                  <label class="form__label">Remote Facility</label>
                  <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacilityRemote" placeholder="Select"
                              optionLabel="crmFacilityName" [options]="facilitiesRemote" dataKey="crmFacilityId" [showClear]="true">
                  </p-dropdown>
                </div>

                <div class="form__group" *ngIf="RemoteAndFacility=='Both'">
                  <label class="form__label">Facility</label>
                  <p-dropdown class="select-box"  appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                              optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                              (onChange)="setFacilityName();getTime()">
                  </p-dropdown>
                </div>

                <div class="form__group" *ngIf="RemoteAndFacility=='Both'">
                  <label class="form__label">Remote Facility</label>
                  <p-dropdown class="select-box"  appendTo="body" [(ngModel)]="selectedFacilityRemote" placeholder="Select"
                              optionLabel="crmFacilityName" [options]="facilitiesRemote" dataKey="crmFacilityId" [showClear]="true">
                  </p-dropdown>
                </div>
              </div>
            </div>
            <div class="therapist-availability-set__col" *ngIf="selectedEvent=='R'">
              <div class="select-facility">
                <strong style="margin-bottom: 1.2rem; display: inline-block;">Appointment Start Location</strong>
                <br />
                <p-radioButton name="group5" value="isStartFromDefaultFacility" (click)="SetFacilityEventsForRoadShowOption()" label="Facility"
                               [(ngModel)]="AppointmentStartLocation"></p-radioButton>

                <p-radioButton name="group5" value="isStartFromHomeAddress" (click)="SetFacilityEventsForRoadShowOption()" label="Home Address"
                               [(ngModel)]="AppointmentStartLocation"></p-radioButton>

              </div>
              <div class="therapist-availability-set__two-column" *ngIf="AppointmentStartLocation=='isStartFromDefaultFacility'">
                <div class="form__group">
                  <label class="form__label">Facility</label>
                  <p-dropdown class="select-box"  appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                              optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                              (onChange)="setFacilityName();getTime()">
                  </p-dropdown>
                </div>

              </div>
            </div>
            <div class="therapist-availability-set__col event-description" *ngIf="selectedEvent1 == 'NA' || selectedEvent == 'O'">
              <div class="form__group" style="display: block;">
                <label class="form__label">Event Description</label>
                <input type="text" class="form__control" pInputText maxlength="50" [(ngModel)]="description">
              </div>
            </div>

            <div class="therapist-availability-set__col" *ngIf="selectedEvent1 == 'NA'">
              <div class="form__group">
                <p-checkbox name="groupFull" binary="true" label="Full Day?" [(ngModel)]="isFullDay"></p-checkbox>
              </div>
            </div>

            <div class="therapist-availability-set__two-column justify-content">

              <div class="therapist-availability-set__date-time">
                <strong>Date</strong>
                <div class="datepikar">
                  <div class="date-pika">
                    <label>From</label>
                    <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customStartDate"
                                [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                                [disabled]="isEditEvent" appendTo="body"></p-calendar>
                  </div>
                  <div class="date-pika">
                    <label>To</label>

                    <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customEndDate"
                                [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                                [disabled]="isEditEvent" appendTo="body"></p-calendar>

                  </div>
                </div>

              </div>

              <div class="therapist-availability-set__date-time" *ngIf="!isFullDay">
                <strong>Time<ng-container *ngIf="selectedFacilityTimeZone != '' && (RemoteAndFacility=='FacilityOnly' || RemoteAndFacility=='Both')"> <small style="color: #474747;font-weight: 400;"> | {{selectedFacilityTimeZone | timezone}}</small></ng-container></strong>
                <div class="datepikar">
                  <div class="date-pika">
                    <label>From</label>
                    <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                          [ngModelOptions]="{standalone: true}">

                    </ngx-timepicker-field>
                  </div>
                  <div class="date-pika">
                    <label>To</label>
                    <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                          [ngModelOptions]="{standalone: true}">

                    </ngx-timepicker-field>
                  </div>
                </div>
                <span class="time-note">Please do not use the 0 prefixes in Hours and Minutes</span>
              </div>
            </div>

            <div class="therapist-availability-set__col">

              <ul class="days">
                <li>
                  <strong>Days</strong>
                </li>
                <li class="event-series">
                  <strong>Weekday</strong>
                  <span class="event-series-right">
                    <ul>
                      <li class="day-check-box" *ngFor="let item of weekDays; let i = index">

                        <a *ngIf="i<5" class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                           [ngClass]="item.isSelected ? 'active' : '' ">
                          <p-checkbox name="group[i]" binary="true" label="{{item.displayName}}" value="val1"
                                      [(ngModel)]="item.isSelected"></p-checkbox>
                        </a>
                      </li>
                    </ul>
                  </span>
                </li>

                <li class="event-series border-bottom">
                  <strong>Weekend</strong>
                  <span class="event-series-right">
                    <ul class="weekend-ul">
                      <li [ngClass]="i>4 ? 'day-check-box' : ''" *ngFor="let item of weekDays; let i = index">
                        <a *ngIf="i>4" class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                           [ngClass]="item.isSelected ? 'active' : '' ">
                          <p-checkbox name="group[i]" binary="true" label="{{item.displayName}}" value="val1"
                                      [(ngModel)]="item.isSelected"></p-checkbox>
                        </a>
                      </li>


                    </ul>
                  </span>
                </li>
              </ul>
            </div>

            <div class="therapist-availability-set__col therapist-availability-set__col--break-hours-block" *ngIf="isBreakHoursDisplayed">
              <span class="event-series">
                <p-checkbox value="R" label="Break Hours?" binary="true" [(ngModel)]="isBreakHours"></p-checkbox>
              </span>

              <div class="therapist-availability-set__col" *ngIf="selectedEvent1 != 'NA' && isBreakHours">
                <div class="therapist-availability-set__date-time break-hours">
                  <div class="datepikar">
                    <label>From</label>
                    <ngx-timepicker-field [(ngModel)]="breakStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                          [ngModelOptions]="{standalone: true}">

                    </ngx-timepicker-field>
                  </div>

                  <div class="datepikar">
                    <label>To</label>
                    <ngx-timepicker-field [(ngModel)]="breakEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                          [ngModelOptions]="{standalone: true}">

                    </ngx-timepicker-field>
                  </div>
                </div>
              </div>
            </div>

            <div class="therapist-availability-set__col therapist-availability-set__col--set-frequency" *ngIf="isSetRecurrenceDisplayed  && !isEditEvent">
              <span class="event-series">
                <p-checkbox value="R" label="Set Frequency?" binary="true" [(ngModel)]="selecteRec"></p-checkbox>
              </span>
            </div>

            <div class="therapist-availability-set__col" *ngIf="selecteRec">
              <ul class="selecteRec-event-series">
                <li class="event-series">
                  <span class="event-series-right">
                    <ul>
                      <li class="day-check-box" *ngFor="let item of eventSeries;">
                        <a class="days-of-weeks" href="javascript:void(0)" [ngClass]="item.isSelected ? 'active' : '' ">
                          <p-radioButton name="group1" label="{{item.name}}" (click)="onChangeEventSeries(item)"
                                         value="{{item.value}}" [(ngModel)]="selectedValue"></p-radioButton>
                        </a>
                      </li>
                    </ul>
                  </span>
                </li>
              </ul>
            </div>

            <div class="therapist-availability-set__col">
              <span class="event-series-select set-frequency">
                <div class="form__group" *ngIf="selecteRec">
                  <p-dropdown class="select-box  drop-dwon-bottom"  appendTo="body" [options]="Occurrence" optionLabel="name" placeholder="Select"
                              [(ngModel)]="selectedOccurrence" [ngModelOptions]="{standalone: true}"
                              (onChange)="onEventOccurrenceChange($event)"></p-dropdown>
                </div>


                <div class="form__group" *ngIf="isExactDate && selecteRec">
                  <!-- <label class="form__label">Exact Day</label> -->
                  <p-dropdown class="select-box  drop-dwon-bottom"  appendTo="body" [options]="ExactDate" optionLabel="name" placeholder="Select"
                              [(ngModel)]="selectedExactDate" (onChange)="onExactDateChange()" [ngModelOptions]="{standalone: true}">
                  </p-dropdown>
                </div>
              </span>
            </div>
            <div *ngIf="selecteRec" class="event-date-of-week-month">{{eventText}}</div>
          </p-scrollPanel>

        </div>
      </div>

      <div class="set-custom-date-btn">
        <button class="btn btn--primary" (click)="onSave()" type="button">Submit</button>
        <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
      </div>

    </div>
  </p-dialog>

</div>
<!-- End Content -->
