import { Component, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject, forkJoin, fromEvent } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { SalesOrderService } from 'src/app/patient/services/salesorder.service';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { GetPatientDetailsRequestModel } from 'src/app/shared/models/request/get-patient-details-request';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SalesOrderLocalModel, BranchInfoContactModel } from 'src/app/shared/models/response/sales-order-current';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { SettingsResponseModel, CustomSettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { OnlineAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.sass'],
})

export class LandingComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  settingsResponseModel: Array<SettingsResponseModel>;
  domainUrl: string = '';
  imageUrl: SafeResourceUrl;
  strPatientIdUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  patinetrequest: GetPatientDetailsRequestModel;
  salesOrderId: string;
  screenLoaded: boolean;
  isHeaderLoaded: boolean;
  patientId: string;
  strURL: string;
  salesOrderData: SalesOrderResponseModel;
  patientName: string;
  description: string;
  duration: string;
  dueAmount: number;
  patientURLgenDate: Date;
  salesorderids: SalesOrderLocalModel;
  branchInfoContactModel: BranchInfoContactModel;
  appointmentType: string = '';
  DMENumber: string;
  isFacilityApt: boolean = false;
  isFacilityAptMessage: boolean = false;
  ShowDueAmount: boolean;
  LinkExpiryDays: number;
  DMEeMail: string;
  validSalesOrder: boolean = true;
  teleHealthPopup: boolean;
  telehealthSuccess: boolean;
  telehealthBooked: boolean;
  isremoteBookingOn: boolean = false;
  isClinicBookingOn: boolean = false;
  disclaimerPopup: boolean = false;
  start: boolean = true;
  emailToProviderSuccess: boolean = false;
  isLoaded: boolean = false;
  isCallBack: boolean = false;
  source: string;
  isExpiry: boolean;
  selectAppointment: boolean;
  FaclityVisitTitle: string = 'Faclity Visit';
  RemoteSetupTitle: string = 'Remote Setup';
  TherapistVisitTitle: string = 'Therapist Visit';
  SelfGuidedTitle: string = 'Self-guided';
  FacilityVisitSelected: boolean = false;
  RemoteSetupSelected: boolean = false;
  TherapistVisitSelected: boolean = false;
  SelfGuidedSelected: boolean = false;
  isActiveAppointmentAvailable: boolean = false;
  appDateFormat: DateFormatModel;
  dateFormat: string = '';
  timeZone: string = '';
  dtFormat: string = '';
  isBillingAddress: boolean = true;
  isMapView: boolean = false;
  onlineAppointmentTypeResponseModel: Array<OnlineAppointmentTypeModel>;
  isAppoitmentBooked: boolean = false;
  isRescheduledRequest: boolean = false;
  isAppoitmentCompletedOrVoid: boolean = false;
  bookAppointmentLabelName: string = "Book Your Appointment";

  isDeliveryAddressAvailable: boolean = false;
  isDrFacilityVisitOnly: boolean = false;
  isDrRemoteVisitOnly: boolean = false;
  isDrRoadHomeVisitOnly: boolean = false;
  availableAppointmentTypeCount: number = 0;
  isBranchAllowSelfScheduleAppointments: boolean = false;


  disclaimerClick() {
    this.disclaimerPopup = true;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    //do nothing
  }

  constructor(
    private webStorageService: WebStorageService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private appointmentService: AppointmentService,
    private formBuilder: FormBuilder,
    private salesorderService: SalesOrderService,
    private router: Router,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private settingService: SettingsService,
    messageService: MessageService) {
    super(messageService);

    //this.getTimeZone();
    //this.getInviteLinkExpiry();
    //this.getDateFormat();
    //this.getSettingsForDeliveryAddress(Constants.SettingKeys.PatientsDefaultAppointmentLocation);


    this.webStorageService.deleteAppointmentConfirmation();
    this.webStorageService.deleteSalesOrderId();
    this.webStorageService.deleteSelectedFacilies();
    this.webStorageService.deleteDateFormat();
  }

  async ngOnInit() {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });

    this.screenLoaded = false;
    this.isHeaderLoaded = false;
    this.activatedRoute.queryParams.subscribe(params => {
      this.source = params["utm_source"];
      var ticks = params["utm_till"];
      if (ticks) {

        var ticksToMicrotime = ticks / 10000;
        var epochMicrotimeDiff = Math.abs(new Date(0, 0, 1).setFullYear(1));
        var tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
        this.patientURLgenDate = tickDate;
        if (this.patientURLgenDate < new Date(2023, 1, 1)) {
          this.isExpiry = true;
          this.screenLoaded = true;
          this.validSalesOrder = false;
          return false;
        }

      }
      //this.patientURLgenDate = moment(moment.unix(params["utm_till"]).format('YYYY-MM-DD')).toDate();
    });

    //this.loading = true;
    this.teleHealthPopup = false;
    this.loading = true;
    this.loadingCustom = true;
    this.domainUrl = location.origin.replace(Constants.httpReplace, "").replace(Constants.httpsReplace, "");
    this.strURL = location.href;

    this.patinetrequest = new GetPatientDetailsRequestModel();
    if (this.strURL.includes('?')) {
      this.strPatientIdUrl = this.strURL.substring(this.strURL.lastIndexOf('/') + 1).split('?')[0];
    }
    else
      this.strPatientIdUrl = this.strURL.substring(this.strURL.lastIndexOf('/') + 1);
    this.patinetrequest.strLink = this.strPatientIdUrl + Constants.equalTo;
    this.webStorageService.storePatientBookingUrl(location.href);
    await this.getClinetDetails();

    await this.getSalesOrderId();

    if (this.source == "email" || this.source == "reschedule" || this.source == "cancel") {
      if (this.webStorageService.getPatientBookingTypeId()) {
        this.webStorageService.deletePatientBookingTypeId();
      };
    }
    else {
      this.appointmentType = this.webStorageService.getPatientBookingTypeId() == null ? '' : this.webStorageService.getPatientBookingTypeId();
    }

    if (this.source == "rescheduleappointment" || this.source == "cancelappointment") {
      this.isRescheduledRequest = true;
      this.bookAppointmentLabelName = "Reschedule Appointment";
    }
    else {
      this.isRescheduledRequest = false;
      this.bookAppointmentLabelName = "Book Your Appointment";
    }

    this.webStorageService.deleteWipStatusBtmap();
    let TimeZone = this.settingService.GetTimeZoneListAsync();
    let WipStatusBtmapping = this.settingsService.getWipStatusBtmapping();
    forkJoin([TimeZone, WipStatusBtmapping]).subscribe(results => {
      if (results[0].isSuccess) {
        if (results[0].responseModel)
          this.webStorageService.storeTimeZoneList(results[0].responseModel);
      }
      if (results[1].isSuccess) {
        if (results[1].responseModel)
          this.webStorageService.storeWipStatusBtmap(results[1].responseModel);
      }
    });

    this.start = false;
    //this.loading = false;
    this.isLoaded = true;


  }

  EmailToProvider() {

    this.loading = true;
    this.appointmentService.SendEmailToProvider(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.emailToProviderSuccess = true;
          //this.showSuccessMessage('Your request has been sent to respective provider.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});
  }

  RequestForCallBack(strType: string) {
    this.loading = true;
    this.salesorderService.AddTelehealthSalesOrder(this.salesOrderId, strType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (strType === "T") {
            this.telehealthSuccess = true;
            this.teleHealthPopup = false;
            this.validSalesOrder = false;
            this.isCallBack = false;
          }
          else {
            this.isCallBack = true;
            this.telehealthSuccess = false;
            this.teleHealthPopup = false;
            this.validSalesOrder = false;
          }
          this.showSuccessMessage('Your request to call back  has been sent.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});
  }

  private async getClinetDetails() {
    await this.authenticationService.getClinetDetails(this.domainUrl)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ConfigResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.isHeaderLoaded = true;
              this.imageData = Constants.imagePrefix + result.responseModel.companyLogo;
              this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
              this.imagealt = result.responseModel.companyName;
              this.imageTitle = result.responseModel.companyName;
              this.webStorageService.storeClientConfig(result.responseModel);
              this.GetPatientSalesOrdersSetings();
            }
          }
        });
      });
  }

  private async getDMENumber() {
    if(!this.salesOrderData){
      return
    }
    await this.settingsService.getAllBranchNumbers(this.salesOrderData.crmBranchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<BranchNumbersResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.DMENumber = result.responseModel.dmephoneNumber;
              this.DMEeMail = result.responseModel.dmeemail;
              this.webStorageService.saveDMENumber(this.DMENumber);
              this.branchInfoContactModel = new BranchInfoContactModel();
              if (this.webStorageService.getBranchContactInfoById()) {
                this.webStorageService.deleteBranchContactInfoById();
              }
              this.branchInfoContactModel.branchid = this.salesOrderData.crmBranchId;
              this.branchInfoContactModel.DMEEmail = result?.responseModel?.dmeemail;
              this.branchInfoContactModel.DMENumber = result?.responseModel?.dmephoneNumber;
              this.branchInfoContactModel.DMEBranch = result?.responseModel?.branch?.crmBranchName;
              this.webStorageService.storeBranchContactInfo(this.branchInfoContactModel);
            }
          }
        });
      });
  }



  private async getActiveOnlineAppointmentType() {
    await this.settingsService.getActiveOnlineAppointmentType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<OnlineAppointmentTypeModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.onlineAppointmentTypeResponseModel = result.responseModel;
            }
          }
        });
      });
  }

  private async getOnlineAppointmentType() {
    this.loading = true;
    await this.settingsService.getOnlineAppointmentType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.onlineAppointmentTypeResponseModel = result.responseModel;
          const form = this.form;

          this.isActiveAppointmentAvailable = this.onlineAppointmentTypeResponseModel?.length > 0 ? true : false;

          var activeAppt = this.onlineAppointmentTypeResponseModel.filter(x => x.isActive == true);
          if (activeAppt?.length > 0)
            this.isActiveAppointmentAvailable = true;
          else
            this.isActiveAppointmentAvailable = false;

          if (this.onlineAppointmentTypeResponseModel?.length > 0) {
            let FacilityVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
            if (FacilityVisit) {
              this.FaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
              this.FacilityVisitSelected = FacilityVisit.isActive;
            }

            let RemoteSetup = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
            if (RemoteSetup) {
              this.RemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
              this.RemoteSetupSelected = RemoteSetup.isActive;
            }
            let TherapistVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
            if (TherapistVisit) {
              this.TherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
              this.TherapistVisitSelected = TherapistVisit.isActive;
            }
            let SelfGuided = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
            if (SelfGuided) {
              this.SelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
              this.SelfGuidedSelected = SelfGuided.isActive;
            }
          }
          this.screenLoaded = true;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getApplicationSettings() {
    this.loading = true;
    await this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.settingsResponseModel = result.responseModel;
          let facilityOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.FacilityApt);
          this.isClinicBookingOn = facilityOnOff.schSettingValue.toLowerCase() == 'true'
          let bookingRemoteOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.BookingRemoteApt);
          this.isremoteBookingOn = bookingRemoteOnOff.schSettingValue.toLowerCase() == 'true'
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }



  private async getInviteLinkExpiry() {
    await this.settingsService.getSettings(Constants.SettingKeys.PatientInvitationLinkExpiry)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.LinkExpiryDays = parseInt(result.responseModel.schSettingValue, 10);
          }
        });
      });
  }

  private async GetPatientSalesOrdersSetings() {
    await this.settingsService.getPatientSalesOrdersSetings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<CustomSettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.LinkExpiryDays = result.responseModel.linkExpiryDays;
              this.isBillingAddress = result.responseModel.isBillingAddress;
              this.dateFormat = result.responseModel.dateFormat;
              this.dtFormat = result.responseModel.dtFormat;
              this.timeZone = result.responseModel.timeZone;
              this.isMapView = result.responseModel.isMapView;

              this.appDateFormat = new DateFormatModel();
              this.appDateFormat.dateFormat = this.dateFormat;
              this.appDateFormat.timeZone = this.timeZone;
              this.appDateFormat.primeFormat = this.dtFormat;
              this.webStorageService.storeDateFormat(this.appDateFormat);
              this.webStorageService.storePatientSalesOrderSettings(result.responseModel);
            }
          }
        });
      });
  }




  private async getSalesOrderId() {
    await this.salesorderService.getSalesOrderId(this.patinetrequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<GetPatientDetailsRequestModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.salesOrderId = result.responseModel.strLink.split(Constants.seprator)[0];
              this.patientId = result.responseModel.strLink.split(Constants.seprator)[1];
              this.getSalesOrderDataExtended();
            }
          }
        });
      });
  }



  private async getSalesOrderDataExtended() {

    this.loading = true;
    await this.salesorderService.getSalesOrderDetails(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.loadingCustom = false;
              this.salesOrderData = result.responseModel;
              this.isBranchAllowSelfScheduleAppointments = this.salesOrderData.crmBranchIsAllowSelfScheduleAppointments;
              this.salesorderids = new SalesOrderLocalModel();
              if (this.webStorageService.getSalesOrderId()) {
                this.webStorageService.deleteSalesOrderId();
              }
              this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
              this.webStorageService.storePatientName(this.patientName);
              this.description = this.salesOrderData.crmClassificationDescription;
              this.dueAmount = this.salesOrderData.amountDue;
              this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
              this.salesorderids.zipCode = this.isBillingAddress ? this.salesOrderData.patientInfo.crmPatientZipCode : this.salesOrderData.crmDeliveryAddressZipcode;
              this.salesorderids.latitude = this.salesOrderData.patientInfo.crmLatitude;
              this.salesorderids.longitude = this.salesOrderData.patientInfo.crmLongitude;
              this.salesorderids.patientAddress = this.salesOrderData.patientInfo.crmPatientAddress;
              this.salesorderids.crmClassificationDescription = this.salesOrderData.crmClassificationDescription;
              this.salesorderids.crmClassificationDuration = this.salesOrderData.crmClassificationDuration
              this.salesorderids.patientId = this.patientId;
              this.salesorderids.salesOrderId = this.salesOrderId;
              this.salesorderids.patientName = this.patientName;
              this.salesorderids.DMENumber = this.DMENumber;
              this.salesorderids.domainUrl = this.domainUrl;
              this.salesorderids.strURL = this.strPatientIdUrl;
              this.salesorderids.patientEmail = this.salesOrderData.patientInfo.crmPatientEmailAddress;
              this.salesorderids.patientPhone = this.salesOrderData.patientInfo.crmPatientPhoneNo;
              this.salesorderids.patientMobile = this.salesOrderData.patientInfo.crmPatientMobileNumber;
              this.salesorderids.branchid = this.salesOrderData.crmBranchId;
              this.salesorderids.crmBillingAddressTimeZone = this.salesOrderData.patientInfo.crmBillingAddressTimeZone;
              this.salesorderids.crmDeliveryAddressTimeZone = this.salesOrderData.crmDeliveryAddressTimeZone;
              this.webStorageService.storeSalesOrderId(this.salesorderids);
              this.webStorageService.storeSalesOrderData(this.salesOrderData);

              this.DMENumber = this.salesOrderData.crmBranchContactNumber;
              this.DMEeMail = this.salesOrderData.crmBranchContactEmailAddress;

              if (this.salesOrderData.crmSalesorderStatus.toLowerCase() != Constants.booked)
                this.bookAppointmentLabelName = "Book Your Appointment";

              if (this.salesOrderData.crmDoctorId != undefined && this.salesOrderData.crmDoctorId != null) {
                if (this.salesOrderData.crmIsFacilityVisitSelected)
                  this.isDrFacilityVisitOnly = true;

                if (this.salesOrderData.crmIsRemoteVisitSelected)
                  this.isDrRemoteVisitOnly = true;

                if (this.salesOrderData.crmIsRoadHomeVisitSelected)
                  this.isDrRoadHomeVisitOnly = true;
              }
              else {
                this.isDrFacilityVisitOnly = true;
                this.isDrRemoteVisitOnly = true;
                this.isDrRoadHomeVisitOnly = true;
              }

              if (!this.DMENumber) {
                setTimeout(() => {
                  this.getDMENumber();
                }, 1000);
              }
              else {
                this.webStorageService.saveDMENumber(this.DMENumber);
                this.branchInfoContactModel = new BranchInfoContactModel();
                if (this.webStorageService.getBranchContactInfoById()) {
                  this.webStorageService.deleteBranchContactInfoById();
                }
                this.branchInfoContactModel.branchid = this.salesOrderData.crmBranchId;
                this.branchInfoContactModel.DMEEmail = this.salesOrderData.crmBranchContactEmailAddress;
                this.branchInfoContactModel.DMENumber = this.salesOrderData.crmBranchContactNumber;
                this.branchInfoContactModel.DMEBranch = this.salesOrderData?.crmBranch;
                this.webStorageService.storeBranchContactInfo(this.branchInfoContactModel);
              }


              if (this.isExpiry == true) {
                this.validSalesOrder = false;
                this.screenLoaded = true;
                return false;
              }

              if (!this.isBranchAllowSelfScheduleAppointments && !this.isRescheduledRequest &&
                ((this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.booked && this.salesOrderData.schAppointmentId != 0)
                  || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.cancel
                  || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.noshow || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.voidreview)) {

              }
              else if (!this.isBranchAllowSelfScheduleAppointments) {
                this.validSalesOrder = false;
                this.screenLoaded = true;
                return false;
              }

              if (!this.isBillingAddress && (!this.salesOrderData.crmPatientDeliveryAddress || this.salesOrderData.crmPatientDeliveryAddress == '')) {
                this.isDeliveryAddressAvailable = false;
                this.telehealthBooked = true;
                this.validSalesOrder = false;
                this.isCallBack = false;
              }
              else {
                this.isDeliveryAddressAvailable = true;
                if (!this.isRescheduledRequest &&
                  ((this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.booked && this.salesOrderData.schAppointmentId != 0)
                    || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.cancel
                    || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.noshow || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.voidreview)) {
                  this.isAppoitmentBooked = true;
                  this.screenLoaded = true;
                  this.router.navigate(['/patient/booked-appointment/']);
                }
                else if (this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.complete || this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.void) {
                  this.isAppoitmentBooked = false;
                  this.isAppoitmentCompletedOrVoid = true;
                  if (this.salesOrderData.crmIsTelehealth) {
                    this.telehealthBooked = true;
                    this.validSalesOrder = false;
                    this.isCallBack = false;
                  }
                  else if (this.salesOrderData.crmIsCallBack) {
                    this.validSalesOrder = false;
                    this.telehealthBooked = false;
                    this.isCallBack = true;
                  }
                  else {
                    this.validSalesOrder = false;
                    this.telehealthBooked = false;
                    this.isCallBack = false;
                  }
                  this.screenLoaded = true;
                }
                else if (this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.readyForSchedule || this.salesOrderData.schAppointmentId === 0 || this.isRescheduledRequest) {
                  if (this.salesOrderData.crmIsTelehealth) {
                    this.telehealthBooked = true;
                    this.validSalesOrder = false;
                    this.isCallBack = false;
                  }
                  else if (this.salesOrderData.crmIsCallBack) {
                    this.validSalesOrder = false;
                    this.telehealthBooked = false;
                    this.isCallBack = true;
                  }
                  if (!this.salesOrderData.crmIsTelehealth && !this.salesOrderData.crmIsCallBack) {
                    this.validSalesOrder = true;
                    this.telehealthBooked = false;
                    this.isCallBack = false;
                  }
                  //else {
                  //  this.RequestForCallBack('C');
                  //}

                  if (this.validSalesOrder) {
                    this.settingsService.getOnlineAppointmentType()
                      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
                      .subscribe((result: ResultOfT<any>) => {
                        this.processResult<any>(result, () => {
                          this.onlineAppointmentTypeResponseModel = result.responseModel;
                          const form = this.form;

                          this.isActiveAppointmentAvailable = this.onlineAppointmentTypeResponseModel?.length > 0 ? true : false;

                          var activeAppt = this.onlineAppointmentTypeResponseModel.filter(x => x.isActive == true);

                          if (activeAppt?.length > 0)
                            this.isActiveAppointmentAvailable = true;
                          else {

                            this.isActiveAppointmentAvailable = false;
                            this.validSalesOrder = false;
                            this.isBranchAllowSelfScheduleAppointments = false;
                          }

                          if (this.onlineAppointmentTypeResponseModel?.length > 0) {
                            let FacilityVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
                            if (FacilityVisit) {
                              this.FaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
                              this.FacilityVisitSelected = FacilityVisit.isActive;
                              if (this.FacilityVisitSelected == true)
                                this.availableAppointmentTypeCount = this.availableAppointmentTypeCount + 1;
                            }

                            let RemoteSetup = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
                            if (RemoteSetup) {
                              this.RemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
                              this.RemoteSetupSelected = RemoteSetup.isActive;
                              if (this.RemoteSetupSelected == true)
                                this.availableAppointmentTypeCount = this.availableAppointmentTypeCount + 1;
                            }
                            let TherapistVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
                            if (TherapistVisit) {
                              this.TherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
                              this.TherapistVisitSelected = TherapistVisit.isActive;
                              if (this.TherapistVisitSelected == true)
                                this.availableAppointmentTypeCount = this.availableAppointmentTypeCount + 1;
                            }
                            let SelfGuided = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
                            if (SelfGuided) {
                              this.SelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
                              this.SelfGuidedSelected = SelfGuided.isActive;
                              if (this.SelfGuidedSelected == true)
                                this.availableAppointmentTypeCount = this.availableAppointmentTypeCount + 1;
                            }

                            if (this.availableAppointmentTypeCount == 1 && this.FacilityVisitSelected == true) {
                              this.onClick('F');
                            }
                            else if (this.availableAppointmentTypeCount == 1 && this.RemoteSetupSelected == true) {
                              this.onClick('R');
                            }
                            else if (this.availableAppointmentTypeCount == 1 && this.TherapistVisitSelected == true) {
                              this.onClick('T');
                            }
                            else if (this.availableAppointmentTypeCount == 1 && this.SelfGuidedSelected == true) {
                              this.onClick('SG');
                            }

                            this.webStorageService.deleteOnlineAppointmentTypeNames();
                            this.webStorageService.storeOnlineAppointmentTypeNames(this.onlineAppointmentTypeResponseModel);
                          }
                          this.screenLoaded = true;
                        });
                      }, (httpResponseError) => {
                        this.showErrorMessage(httpResponseError.message);
                      });
                  }
                  else {
                    this.screenLoaded = true;
                  }
                  this.isAppoitmentBooked = false;

                }
                else {
                  this.validSalesOrder = false;
                  this.telehealthBooked = false;
                  this.isCallBack = false;
                  this.screenLoaded = true;
                  this.isAppoitmentBooked = false;
                }
              }
            }
          }
        });
      });
  }






  private async getSalesOrderData() {

    this.loading = true;
    await this.salesorderService.getSalesOrderDetails(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.loadingCustom = false;
              this.salesOrderData = result.responseModel;
              this.salesorderids = new SalesOrderLocalModel();
              if (this.webStorageService.getSalesOrderId()) {
                this.webStorageService.deleteSalesOrderId();
              }
              this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
              this.description = this.salesOrderData.crmClassificationDescription;
              this.dueAmount = this.salesOrderData.amountDue;
              this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
              this.salesorderids.zipCode = this.isBillingAddress ? this.salesOrderData.patientInfo.crmPatientZipCode : this.salesOrderData.crmDeliveryAddressZipcode;
              this.salesorderids.latitude = this.salesOrderData.patientInfo.crmLatitude;
              this.salesorderids.longitude = this.salesOrderData.patientInfo.crmLongitude;
              this.salesorderids.patientAddress = this.salesOrderData.patientInfo.crmPatientAddress;
              this.salesorderids.crmClassificationDescription = this.salesOrderData.crmClassificationDescription;
              this.salesorderids.crmClassificationDuration = this.salesOrderData.crmClassificationDuration
              this.salesorderids.patientId = this.patientId;
              this.salesorderids.salesOrderId = this.salesOrderId;
              this.salesorderids.patientName = this.patientName;
              this.salesorderids.DMENumber = this.DMENumber;
              this.salesorderids.domainUrl = this.domainUrl;
              this.salesorderids.strURL = this.strPatientIdUrl;
              this.salesorderids.patientEmail = this.salesOrderData.patientInfo.crmPatientEmailAddress;
              this.salesorderids.patientPhone = this.salesOrderData.patientInfo.crmPatientPhoneNo;
              this.salesorderids.patientMobile = this.salesOrderData.patientInfo.crmPatientMobileNumber;
              this.salesorderids.branchid = this.salesOrderData.crmBranchId;
              this.salesorderids.crmBillingAddressTimeZone = this.salesOrderData.patientInfo.crmBillingAddressTimeZone;
              this.salesorderids.crmDeliveryAddressTimeZone = this.salesOrderData.crmDeliveryAddressTimeZone;
              this.webStorageService.storeSalesOrderId(this.salesorderids);
              this.webStorageService.storeSalesOrderData(this.salesOrderData);

              this.DMENumber = this.salesOrderData.crmBranchContactNumber;
              this.DMEeMail = this.salesOrderData.crmBranchContactEmailAddress;

              if (!this.DMENumber)
                this.getDMENumber();
              else {
                this.webStorageService.saveDMENumber(this.DMENumber);
                this.branchInfoContactModel = new BranchInfoContactModel();
                if (this.webStorageService.getBranchContactInfoById()) {
                  this.webStorageService.deleteBranchContactInfoById();
                }
                this.branchInfoContactModel.branchid = this.salesOrderData.crmBranchId;
                this.branchInfoContactModel.DMEEmail = this.salesOrderData.crmBranchContactNumber;
                this.branchInfoContactModel.DMENumber = this.salesOrderData.crmBranchContactEmailAddress;
                this.branchInfoContactModel.DMEBranch = this.salesOrderData?.crmBranch;
                this.webStorageService.storeBranchContactInfo(this.branchInfoContactModel);
              }
              if (this.salesOrderData.crmSalesorderStatus.toLowerCase() === Constants.readyForSchedule) {

                if (this.salesOrderData.crmIsTelehealth) {
                  this.telehealthBooked = true;
                  this.validSalesOrder = false;
                  this.isCallBack = false;
                }
                else if (this.salesOrderData.crmIsCallBack) {
                  this.validSalesOrder = false;
                  this.telehealthBooked = false;
                  this.isCallBack = true;
                }
                if (!this.salesOrderData.crmIsTelehealth && !this.salesOrderData.crmIsCallBack) {
                  this.validSalesOrder = true;
                  this.telehealthBooked = false;
                  this.isCallBack = false;
                }
                else {
                  this.RequestForCallBack('C');
                }

                if (this.validSalesOrder) {
                  this.getOnlineAppointmentType();
                }
                else {
                  this.screenLoaded = true;
                }


              }
              else {
                this.validSalesOrder = false;
                this.telehealthBooked = false;
                this.isCallBack = false;
                this.screenLoaded = true;
              }
            }
          }
        });
      });
  }

  private async getSettingsForDeliveryAddress(constantkey: any) {
    this.loading = true;
    await this.settingService.getSettings(constantkey)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              if (constantkey.toLowerCase() == Constants.SettingKeys.PatientsDefaultAppointmentLocation.toLowerCase()) {
                if (result.responseModel.schSettingValue.toLowerCase() == 'billingaddress') {
                  this.isBillingAddress = true;
                }
                else {
                  this.isBillingAddress = false;
                }
              }

          }
        });
      });
  }


  onClick(value: string) {
    this.appointmentType = value;

    if (value === 'F') {
      this.teleHealthPopup = false;
      this.isFacilityAptMessage = false;
      this.selectAppointment = !this.selectAppointment;
    }
    else {
      this.teleHealthPopup = true;
      this.isFacilityAptMessage = false;
      this.selectAppointment = !this.selectAppointment;
    }
  }

  onBookAppointment() {
    if (this.appointmentType === 'F') {
      this.isFacilityAptMessage = false;
      this.webStorageService.deletePatientBookingTypeId();
      this.webStorageService.storePatientBookingTypeId(this.appointmentType);
      // if(this.isMapView){
      //   this.router.navigate(['/patient/choose-facility-map']);
      // }
      // else{
      //   this.router.navigate(['/patient/appointment-booking']);
      // }
      this.router.navigate(['/patient/appointment-booking']);
      this.selectAppointment = !this.selectAppointment;
    }
    if (this.appointmentType === 'R' || this.appointmentType === 'SG') {
      this.webStorageService.deletePatientBookingTypeId();
      this.webStorageService.storePatientBookingTypeId(this.appointmentType);
      //this.router.navigate(['/patient/choose-date-time']);
      this.router.navigate(['/patient/appointment-booking']);
      this.selectAppointment = !this.selectAppointment;

    }
    if (this.appointmentType === 'T') {
      this.webStorageService.deletePatientBookingTypeId();
      this.webStorageService.storePatientBookingTypeId(this.appointmentType);
      //this.router.navigate(['/patient/choose-therapist']);
      this.router.navigate(['/patient/appointment-booking']);
      this.selectAppointment = !this.selectAppointment;
    }
    else {
      this.teleHealthPopup = true;
      this.isFacilityAptMessage = false;
      this.selectAppointment = !this.selectAppointment;
    }
  }

  handleTooltipEvents(event) {
    //console.log(event);
  }

  private async getDateFormat() {
    await this.settingsService.getDateFormatFromSetting(Constants.dateTimeFormat)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.dateFormat = result.responseModel.schSettingValue;
              this.dtFormat = result.responseModel.dtNgFormat;

            }
          }
        });
      });
  }

  private async getTimeZone() {
    await this.settingsService.getSettingsByKey(Constants.timeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.timeZone = result.responseModel.schSettingValue;
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  // selectAppointmentType(){
  //   this.selectAppointment = !this.selectAppointment;       
  // }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.webStorageService.deleteWipStatusBtmap();
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.webStorageService.storeWipStatusBtmap(result.responseModel);
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }
  private async GetTimeZoneListAsync() {
    await this.settingService.GetTimeZoneListAsync()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<TimeZoneAvailableResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.webStorageService.storeTimeZoneList(result.responseModel);
          }
        });
      });
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/patient/home/' + url])
    );
  }

}
