import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Subject, forkJoin } from 'rxjs';
import { DashboardService } from '../servicies/dashboard.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { NoOfCallResponseModel } from 'src/app/shared/models/response/report-summary-response-model';
import { AppointmentCountForDashboardResponseModel, NotScheduledCallCountResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { SearchRequestModelDashboard, SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { FacilityService } from '../servicies/facility.service';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserService } from '../servicies/user.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { isThisHour } from 'date-fns/esm';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass'],
  animations: [
    trigger('flipState', [
      state('active', style({
        transform: 'rotateY(179deg)'
      })),
      state('inactive', style({
        transform: 'rotateY(0)'
      })),
      transition('active => inactive', animate('500ms ease-out')),
      transition('inactive => active', animate('500ms ease-in'))
    ])
  ]
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  title = 'Dashboard';

  facilitiesCount: number;
  therapistsTotalCount: number;
  doctorsCount: number;
  csOperatorsCount: number;
  notScheduledAppointmentCount: number;
  notScheduledAppointmentCountFollowUp: number;
  potentialVoidNormal: number;
  notScheduledNormal: number;
  normalCallbackCount: number;
  noOfNotScheduledAppointmentCalls: NoOfCallResponseModel;
  noOfNoShowAppointmentCalls: NoOfCallResponseModel;
  notAssignedToFacilityCount: number;
  licenseExpiredCount: number;
  // notScheduledAppointmentAndTelehealth: AppointmentCountForDashboardResponseModel;
  notScheduledTelehealthCall: NotScheduledCallCountResponseModel;
  urgentCallbackRequest: NotScheduledCallCountResponseModel;
  potentialVoid: NotScheduledCallCountResponseModel;
  openAppointmentsCount: AppointmentCountForDashboardResponseModel;
  unReconciledAppts: AppointmentCountForDashboardResponseModel;
  cancelledAppts: AppointmentCountForDashboardResponseModel;
  noShowAppointmentsCount: AppointmentCountForDashboardResponseModel;
  fromDateFilter: Date;
  toDateFilter: Date;
  facilityList: FacilityResponseModelDropdown[];
  selectedFacility: FacilityResponseModelDropdown;
  months: { name: string, value: number }[];
  selectedMonth: { name: string, value: number };
  searchRequestModel: SearchRequestModelDashboard;
  therapistsCount: number;
  currentUserBranch: number;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel[];
  loggedInUserId: number;
  titleBranch: string;
  todayDate: string;
  searchRequestForRedirection: SearchRequestModel;
  currentUserId: number;
  appointmentTypes: QuickFilters[];
  appDateFormat: DateFormatModel;
  iNoShowId: number = 5;
  iBookedId: number = 2;
  iCancelId: number = 4;
  clientId: number;
  isUrgentCallDisplay: boolean = true;
  isNoShowDisplay: boolean = true;
  isScheduledDisplay: boolean = true;
  isTobeBookedDaysDisplay: boolean = false;
  urgentCallsLoading = false;
  tobebookedLoading = false;
  changesPage: { name: string; code: string; }[];
  enloggedInUserId: string;
  checked: boolean = false;

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService,
    private userService: UserService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService) {
    super(messageService);

    var currentUserToken = this.webStorageService.getCurrentUserToken();
    this.currentUserId = currentUserToken.userId;
    this.loggedInUserId = currentUserToken.userId
    this.enloggedInUserId = currentUserToken.enuserId;
    this.currentUserBranch = this.webStorageService.getUserBranch();
    var clientdetail = this.webStorageService.getClientConfig();
    this.clientId = Number(clientdetail.companyId);
    var channelArray: Array<Number> = [5, 6];

    this.isUrgentCallDisplay = true;
    this.isNoShowDisplay = true;
    this.isScheduledDisplay = true;
    this.isTobeBookedDaysDisplay = channelArray.includes(this.clientId) ? true : false;

    if (this.currentUserBranch == null || this.currentUserBranch == undefined) {
      this.currentUserBranch = 0;
    }

    this.appDateFormat = this.webStorageService.getDateFormat();
    //this.getStatus();
    let rNumber = this.webStorageService.getRandomNumber();
    if (rNumber <= 0)
      this.webStorageService.storeRandomNumber(0);
  }

  async ngOnInit() {

    this.changesPage = [
      { name: 'Switch Todays Status', code: '' },
      { name: 'Switch to Summay View', code: '' }

    ];

    // const $el = document.querySelector(".dashboard");
    // setTimeout(() => {
    // $el.classList.remove("skeleton");
    // $el
    // .querySelectorAll(".loading")
    // .forEach((el) => el.classList.remove("loading"));  
    // }, 3000);


    const $el = document.querySelector(".dashboard");
    $el.classList.remove("skeleton");


    await this.getStatus();

    this.months = [
      { name: 'Jan', value: 1 },
      { name: 'Feb', value: 2 },
      { name: 'Mar', value: 3 },
      { name: 'Apr', value: 4 },
      { name: 'May', value: 5 },
      { name: 'Jun', value: 6 },
      { name: 'Jul', value: 7 },
      { name: 'Aug', value: 8 },
      { name: 'Sep', value: 9 },
      { name: 'Oct', value: 10 },
      { name: 'Nov', value: 11 },
      { name: 'Dec', value: 12 },
    ];
    //this.todayDate = new Date();
    this.searchRequestModel = new SearchRequestModelDashboard();
    this.initializeSearchForRedirection();
    this.initializeSearchData();
    await this.GetCountNotScheduledCalls(false);
    await this.GetUrgentCallBackRequestCount(false);
    await this.getCountNoShowAppointment(false);
    await this.getCountOpenAppointment(false);
    await this.GetPotentialVoid(false);
    await this.GetTherapistDashboardStatistics(false);
    await this.getUnReconcileOrders(false);
    await this.getCancelled(false);

    //await this.getBranches();
    // await this.getAppointments();
    // await this.getTherapist();
    //await this.getNotScheduledAppointmentsWithCalls();
    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null) {
        this.currentUserBranch = branchId;
        this.initializeSearchData();
        this.GetCountNotScheduledCalls(true);
        this.GetUrgentCallBackRequestCount(true);
        this.getCountNoShowAppointment(true);
        this.getCountOpenAppointment(true);
        this.GetPotentialVoid(true);
        this.GetTherapistDashboardStatistics(true);
        this.getUnReconcileOrders(true);
        this.getCancelled(true);

      } else {
        //this.getAllFacilitiesBranch('0');
      }
    });
  }

  ngAfterViewInit() {
    if (!this.isTobeBookedDaysDisplay) {
      //this.toggleFlip();
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
      //this.globalSearchService.changeGlobalBranch.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  onFacilityChange(event) {
    if (event.value != null) {
      this.searchRequestModel.facilityId = event.value.crmFacilityId;
    }
  }

  async onMonthChange(event) {
    if (event.value != null) {
      this.searchRequestModel.month = event.value.value;
      this.initializeMonthSearchData();
      //await this.getAppointments();
      await this.getCountOpenAppointment(true);
      await this.getCountNoShowAppointment(true);
      await this.GetPotentialVoid(true);
      //await this.getTherapist();
      //await this.getNotScheduledAppointmentsWithCalls();
    }
  }

  onSelectFromDate(event) {
    if (event != null) {
      this.searchRequestModel.searchFromDate = event;
    }
  }
  onSelectToDate(event) {
    if (event != null) {
      this.searchRequestModel.searchToDate = event;
    }
  }

  async onBranchChange(event) {
    if (this.selectedbranch.length > 0) {
      await this.getAllFacilitiesBranches(this.selectedbranch);
    } else {
      await this.getAllFacilitiesBranch([]);
    }
  }

  // selectAsOfDate(event) {
  //   this.searchRequestModel.asOfDate = this.todayDate;
  //   this.getAppointments();
  // }

  async OnSearch() {
    let startDate = this.fromDateFilter
    let endDate = this.toDateFilter;
    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    this.initializeSearchData();
    // await this.getAppointments();
    await this.getCountOpenAppointment(false);
    await this.getCountNoShowAppointment(false);
    await this.GetPotentialVoid(false);
    //await this.getTherapist();
    await this.GetTherapistDashboardStatistics(false);
    // await this.getNotScheduledAppointmentsWithCalls();
    await this.GetCountNotScheduledCalls(false);
    await this.GetUrgentCallBackRequestCount(false);
  }

  async OnClear() {
    this.fromDateFilter = null;
    this.toDateFilter = null;
    this.selectedMonth = { name: '', value: 0 };
    this.selectedFacility = new FacilityResponseModelDropdown();
    this.selectedbranch = new Array<BranchResponseModel>();
    // if (this.currentUserBranch == 0) {
    //   this.selectedbranch = new Array<BranchResponseModel>();
    // }
    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
    if (selectedBranch !== undefined) {
      if (selectedBranch.crmBranchId !== 0) {
        this.selectedbranch[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
        // this.branchResponseModel = this.selectedbranch;
      }
      // else {
      //   this.selectedbranch = new Array<BranchResponseModel>();
      // }
    } else {
      this.selectedbranch = this.branchResponseModel;
    }
    if (this.selectedbranch.length > 0) {
      await this.getAllFacilitiesBranches(this.selectedbranch);
    } else {
      await this.getAllFacilitiesBranch([]);
    }
    this.initializeSearchData();

    //await this.getAppointments();
    await this.getCountOpenAppointment(false);
    await this.getCountNoShowAppointment(false);
    await this.GetPotentialVoid(false);
    //await this.getTherapist();
    await this.GetTherapistDashboardStatistics(false);
    //await this.getNotScheduledAppointmentsWithCalls();
    await this.GetCountNotScheduledCalls(false);
    await this.GetUrgentCallBackRequestCount(false);
  }

  initializeSearchForRedirection() {
    this.searchRequestForRedirection = new SearchRequestModel();
    this.searchRequestForRedirection.crmBranchId = this.currentUserBranch;
    this.searchRequestForRedirection.appUserInfoId = this.currentUserId;
    this.searchRequestForRedirection.dictionaryFilter = {};
    this.searchRequestForRedirection.appUserInfoId = this.loggedInUserId;
    this.searchRequestForRedirection.FirstRecordIndex = 0;
    this.searchRequestForRedirection.CurrentPageIndex = 0;
    this.searchRequestForRedirection.PageSize = +this.pageSizes[0];
    this.searchRequestForRedirection.SortOrder = 'ASC';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.searchRequestForRedirection.CurrentPageIndex = 0;
    this.searchRequestForRedirection.FirstRecordIndex = 0;
    this.searchRequestForRedirection.isFromDashboard = true;
  }

  toFacilityList() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "CrmFacilityName";
    this.searchRequestForRedirection.dictionaryFilter['ActiveStatus'] = 'true';
    this.router.navigate(['/admin/facilities'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }

  toTherapistList() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "AppUserFname";
    this.searchRequestForRedirection.dictionaryFilter['ActiveStatus'] = 'true';
    this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }

  toDoctorList() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "CrmDoctorFirstName";
    this.searchRequestForRedirection.dictionaryFilter['ActiveStatus'] = 'true';
    this.router.navigate(['/admin/doctors'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }

  toUserList() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "AppUserFname";
    this.searchRequestForRedirection.dictionaryFilter['SearchRole'] = 'CS Operator';
    this.router.navigate(['/admin/users'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }

  toNotScheduled() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.searchRequestForRedirection.dictionaryFilter['NoCallBack'] = 'false';
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 4 } });
  }





  toNotScheduledFirstCall() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'First';
    this.searchRequestForRedirection.dictionaryFilter['NoCallBack'] = 'false';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false } });


  }

  toNotScheduledSecondCall() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'Second';
    this.searchRequestForRedirection.dictionaryFilter['NoCallBack'] = 'false';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false } });


  }


  toNotScheduledThirdCall() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'Third';
    this.searchRequestForRedirection.dictionaryFilter['NoCallBack'] = 'false';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false } });


  }

  toNoShowFirstCall() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'First';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: false } });
  }

  toNoShowSecondCall() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'Second';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: false } });
  }

  toNoShowThirdCall() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentCallFilter'] = 'Third';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: false } });
  }

  toNotScheduledNormal() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['Telehealth'] = 'false';
    this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallback'] = 'false';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false } });
  }

  toNotScheduledListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['TelehealthDash'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false, selectedfilterIndex: 1 } });
  }


  toNotScheduledUrgent() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.searchRequestForRedirection.dictionaryFilter['CallBack'] = 'true';
    //this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 4 } });
    //this.router.navigate(['/admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 4 } });

    this.router.navigate(['//admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection } });


  }

  toNotScheduledListingCallback() {

    this.initializeSearchForRedirection();
    //this.searchRequestForRedirection.dictionaryFilter['CallBack'] = 'true';
    this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallbackDash'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    //this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false, selectedfilterIndex: 1 } });
    this.router.navigate(['/admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
    //this.router.navigate(['/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection} });

  }

  toNotScheduledListingTodayCallback() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['TodayUrgentCallBack'] = 'true';
    //this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallbackDash'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    //this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false, selectedfilterIndex: 1 } });
    this.router.navigate(['/admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection } });
    //this.router.navigate(['/admin/appointments/view-urgentcallbacks']);
  }

  toNotScheduledListingFutureCallback() {

    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['FutureUrgentCallBack'] = 'true';
    //this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallbackDash'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    //this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false, selectedfilterIndex: 1 } });
    this.router.navigate(['/admin/appointments/view-urgentcallbacks'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }


  toOpenListingPage() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iBookedId.toString();
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 1 } });
  }

  toOpenListingHome() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iBookedId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Home Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toOpenListingFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iBookedId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Facility Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toOpenListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iBookedId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Telehealth';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toNoShowListingPage() {
    this.initializeSearchForRedirection();

    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-noshow-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0 } });
  }



  toCancelListingPage() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-cancel-orders'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toCancelListingHome() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iCancelId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Home Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancel-orders'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toCancelListingFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iCancelId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Facility Visit';

    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancel-orders'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toCancelListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iCancelId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Telehealth';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancel-orders'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toNoShowListingHome() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Home Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-noshow-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toNoShowListingFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Facility Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-noshow-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toNoShowListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iNoShowId.toString();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Telehealth';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-noshow-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true } });
  }

  toNotAssignedtoFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = 'AppUserFname';
    this.searchRequestForRedirection.dictionaryFilter['NotAssignedTherapistFilter'] = 'true';
    this.searchRequestForRedirection.dictionaryFilter['ActiveStatus'] = 'true';
    this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }

  toLicenseExpiredTherapist() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = 'AppUserFname';
    this.searchRequestForRedirection.dictionaryFilter['TherapistLicenseExpiredFilter'] = 'true';
    this.searchRequestForRedirection.dictionaryFilter['ActiveStatus'] = 'true';
    this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestForRedirection } });
  }
  private initializeMonthSearchData() {

    this.fromDateFilter = null;
    this.toDateFilter = null;
    this.selectedFacility = new FacilityResponseModelDropdown();
    this.selectedbranch = new Array<BranchResponseModel>();
    this.searchRequestModel.branchId = this.currentUserBranch;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.facilityId = this.selectedFacility ? this.selectedFacility.crmFacilityId : 0;
    this.searchRequestModel.month = this.selectedMonth ? this.selectedMonth.value : 0;
    this.searchRequestModel.searchFromDate = this.fromDateFilter ? this.fromDateFilter : null;
    this.searchRequestModel.searchToDate = this.toDateFilter ? this.toDateFilter : null;
    // this.searchRequestModel.asOfDate = this.todayDate;
    if (this.selectedbranch !== undefined && this.selectedbranch.length > 0) {
      this.searchRequestModel.branchIds = new Array<number>();
      this.selectedbranch.forEach((result) => {
        this.searchRequestModel.branchIds.push(result.crmBranchId);
      });
    } else {
      this.searchRequestModel.branchIds = new Array<number>();
      this.searchRequestModel.branchIds.push(this.currentUserBranch);
    }
  }

  private initializeSearchData() {

    this.searchRequestModel.branchId = this.currentUserBranch;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.facilityId = this.selectedFacility ? this.selectedFacility.crmFacilityId : 0;
    this.searchRequestModel.month = this.selectedMonth ? this.selectedMonth.value : 0;
    this.searchRequestModel.searchFromDate = this.fromDateFilter ? this.fromDateFilter : null;
    this.searchRequestModel.searchToDate = this.toDateFilter ? this.toDateFilter : null;
    // this.searchRequestModel.asOfDate = this.todayDate;
    if (this.selectedbranch !== undefined && this.selectedbranch.length > 0) {
      this.searchRequestModel.branchIds = new Array<number>();
      this.selectedbranch.forEach((result) => {
        this.searchRequestModel.branchIds.push(result.crmBranchId);
      });
    } else {
      this.searchRequestModel.branchIds = new Array<number>();
      this.searchRequestModel.branchIds.push(this.currentUserBranch);
    }
  }

  private SetSelectedBranch() {
    let CurrentBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
    let CurrentBranchex = this.branchResponseModel.filter(x => x.crmBranchId == this.currentUserBranch);
    // this.selectedbranch = this.currentUserBranch == 0 ? this.selectedbranch : CurrentBranchex;
    this.titleBranch = this.currentUserBranch == 0 ? '' : CurrentBranch.crmBranchName;
    let selectedBranch = this.branchResponseModel.filter(x => x.crmBranchId != 0);
    if (selectedBranch !== undefined) {
      this.branchResponseModel = selectedBranch;
      // this.selectedbranch = this.branchResponseModel;
    }
    this.selectedbranch = this.currentUserBranch == 0 ? this.branchResponseModel : CurrentBranchex;

  }

  private async getBranches() {
    this.selectedbranch = new Array<BranchResponseModel>();
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            //this.SetSelectedBranch();
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllFacilitiesBranch(branchId: string[]) {
    this.progress.next(true);
    await this.facilityService.getAllFacilitiesBranch(branchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.facilityList = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllFacilitiesBranches(branches: BranchResponseModel[]) {
    this.progress.next(true);
    await this.facilityService.getAllFacilitiesBranches(branches)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.facilityList = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getFacilities() {
    this.progress.next(true);
    await this.facilityService.getAllFacilityDropDown()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.facilityList = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getResourceData() {
    this.loading = true;
    this.progress.next(true);
    // tslint:disable-next-line: max-line-length
    await forkJoin<ResultOfT<number>, ResultOfT<number>, ResultOfT<number>, ResultOfT<number>>(this.dashboardService.getCountFacilities(),
      this.dashboardService.getCountDoctors(), this.dashboardService.GetCountTotalTherapists(), this.dashboardService.getCountCSOperators())
      // await forkJoin<ResultOfT<number>, ResultOfT<number>, ResultOfT<number>, ResultOfT<number>>(facilityCount, doctorCount, therapistCount, csOperatorCount)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        if (results[0].responseModel != undefined && results[0].responseModel != null)
          this.facilitiesCount = results[0].responseModel;
        if (results[1].responseModel != undefined && results[1].responseModel != null)
          this.doctorsCount = results[1].responseModel;
        if (results[2].responseModel != undefined && results[2].responseModel != null)
          this.therapistsTotalCount = results[2].responseModel;
        if (results[3].responseModel != undefined && results[3].responseModel != null)
          this.csOperatorsCount = results[3].responseModel;
        this.loading = false;
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  //New Code For individual Calls to revove fork join
  private async GetCountNotScheduledCalls(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.GetCountNotScheduledCalls(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        this.loading = false;
        if (!results) {
          return;
        }

        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.notScheduledTelehealthCall = results.responseModel;
            //this.notScheduledNormal = this.notScheduledTelehealthCall.telehealthCallbackCount + this.notScheduledTelehealthCall.normalCallbackCount + this.notScheduledTelehealthCall.futureCallbackCount + this.notScheduledTelehealthCall.todayScheduledCallbackCount;
            //this.notScheduledNormal = this.notScheduledNormal > 0 ? this.notScheduledNormal : Number(0);
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetUrgentCallBackRequestCount(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.GetUrgentCallBackRequestCount(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        this.loading = false;
        if (!results) {
          return;
        }

        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.urgentCallbackRequest = results.responseModel;
            /*this.notScheduledNormal = this.urgentCallbackRequest.normalCallbackCount;*/
            this.notScheduledNormal = this.urgentCallbackRequest.normalCallbackCount + this.urgentCallbackRequest.todayScheduledCallbackCount + this.urgentCallbackRequest.futureCallbackCount
            // this.normalCallbackCount = this.urgentCallbackRequest.normalCallbackCount - (this.urgentCallbackRequest.todayScheduledCallbackCount + this.urgentCallbackRequest.futureCallbackCount)
            this.normalCallbackCount = this.urgentCallbackRequest.normalCallbackCount;
            this.notScheduledNormal = this.notScheduledNormal > 0 ? this.notScheduledNormal : Number(0);

            this.normalCallbackCount = this.normalCallbackCount > 0 ? this.normalCallbackCount : Number(0);
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private async getCountOpenAppointment(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.getCountOpenAppointment(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.openAppointmentsCount = results.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private async getCountNoShowAppointment(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.getCountNoShowAppointment(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.noShowAppointmentsCount = results.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetPotentialVoid(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.GetPotentialVoid(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.potentialVoid = results.responseModel;
            this.potentialVoidNormal = this.potentialVoid.appointmentCount - (this.potentialVoid.normalCallbackCount + this.potentialVoid.telehealthCallbackCount)
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  //End  Code For individual Calls to revove fork join
  private async getAppointments() {
    this.loading = true;
    this.progress.next(true);
    // tslint:disable-next-line: max-line-length
    await forkJoin<ResultOfT<AppointmentCountForDashboardResponseModel>, ResultOfT<AppointmentCountForDashboardResponseModel>, ResultOfT<NotScheduledCallCountResponseModel>>

      (
        this.dashboardService.getCountOpenAppointment(this.currentUserBranch, false, this.enloggedInUserId),
        this.dashboardService.getCountNoShowAppointment(this.currentUserBranch, false, this.enloggedInUserId),
        this.dashboardService.GetPotentialVoid(this.currentUserBranch, false, this.enloggedInUserId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        this.loading = false;
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        // this.notScheduledAppointmentAndTelehealth = results[0].responseModel;
        //if (results[0].responseModel) {
        //  this.notScheduledTelehealthCall = results[0].responseModel;
        //  this.notScheduledNormal = this.notScheduledTelehealthCall.telehealthCallbackCount + this.notScheduledTelehealthCall.normalCallbackCount;
        //}
        if (results[0].responseModel != null && results[0].responseModel != undefined)
          this.openAppointmentsCount = results[0].responseModel;
        if (results[1].responseModel != null && results[1].responseModel != undefined)
          this.noShowAppointmentsCount = results[1].responseModel;
        if (results[2].responseModel != null && results[2].responseModel != undefined) {
          this.potentialVoid = results[2].responseModel;
          this.potentialVoidNormal = this.potentialVoid.appointmentCount - (this.potentialVoid.normalCallbackCount + this.potentialVoid.telehealthCallbackCount)
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getTherapist() {
    // this.initializeSearchData();
    this.searchRequestModel.month = 0;
    this.progress.next(true);
    await forkJoin<ResultOfT<number>, ResultOfT<number>, ResultOfT<number>>
      (this.dashboardService.getCountTherapists(this.searchRequestModel),
        this.dashboardService.getCountTherapistsNotAssignedFacility(this.searchRequestModel),
        this.dashboardService.getCountTherapistsLisenceExpired(this.searchRequestModel))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        if (results[0].responseModel != null && results[0].responseModel != undefined)
          this.therapistsCount = results[0].responseModel;
        if (results[1].responseModel != null && results[1].responseModel != undefined)
          this.notAssignedToFacilityCount = results[1].responseModel;
        if (results[2].responseModel != null && results[2].responseModel != undefined)
          this.licenseExpiredCount = results[2].responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private async GetTherapistDashboardStatistics(isRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.GetTherapistDashboardStatistics(this.currentUserBranch, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.therapistsCount = results.responseModel.therapistCount;
            this.notAssignedToFacilityCount = results.responseModel.therapistNotAssignedToFacilitycount
            this.licenseExpiredCount = results.responseModel.therapistExpiredLicenseCount;
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private async getNotScheduledAppointmentsWithCalls() {
    this.searchRequestModel.month = 0;
    this.progress.next(true);
    await forkJoin<ResultOfT<NoOfCallResponseModel>, ResultOfT<NoOfCallResponseModel>>
      (
        this.dashboardService.getCallsCountNotScheduledAppointments(this.currentUserBranch),
        this.dashboardService.getAppointemtCallsCountNoShowAppointments(this.searchRequestModel))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        if (results[0].responseModel != null && results[0].responseModel != undefined) {
          this.noOfNotScheduledAppointmentCalls = results[0].responseModel;
        }
        if (results[1].responseModel != null && results[1].responseModel != undefined)
          this.noOfNoShowAppointmentCalls = results[1].responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getUnReconcileOrders(isRefresh: boolean) {
    this.loading = true;
    this.searchRequestModel.month = 0;
    this.progress.next(true);
    await this.dashboardService.getUnReconciledAppointments(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.unReconciledAppts = results.responseModel;
            this.todayDate = this.unReconciledAppts.dtRefreshDate;
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getStatus() {
    this.loading = true;
    this.progress.next(true);
    await this.dashboardService.getStatus()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess) {
          if (results.responseModel != null && results.responseModel != undefined && results.responseModel.length > 0) {
            var iNoShow = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow');
            if (iNoShow != null && iNoShow != undefined) {
              this.iNoShowId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow').crmWipstatusId;
            }

            var iBooked = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked');
            if (iBooked != null && iBooked != undefined) {
              this.iBookedId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked').crmWipstatusId;
            }
            var iCancel = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel');
            if (iCancel != null && iCancel != undefined) {
              this.iCancelId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel').crmWipstatusId;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getCancelled(isRefresh: boolean) {
    this.loading = true;
    this.searchRequestModel.month = 0;
    this.progress.next(true);
    await this.dashboardService.getCountCancelSalesOrder(this.currentUserBranch, isRefresh, this.enloggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled

        if (!results) {
          return;
        }
        this.loading = false;
        if (results.isSuccess)
          if (results.responseModel != undefined && results.responseModel != null) {
            this.cancelledAppts = results.responseModel;
          }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  toUnReconListingPage() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = '-999';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 4 } });
  }

  toUnReconListingHome() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = '-999';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Home Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toUnReconListingFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = '-999';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Facility Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toUnReconListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = '-999';
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Telehealth';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true } });
  }

  toPotentialVoid() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toPotentialVoidNormal() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['Telehealth'] = 'false';
    this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallbackDash'] = 'false';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toPotentialVoidCallBack() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['ClinicAppointmentCallbackDash'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toPotentialVoidTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['Telehealth'] = 'true';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0 } });
  }

  toCancelled() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentStatus'] = this.iCancelId.toString();
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toCancelledListingHome() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Home Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toCancelledListingFacility() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Facility Visit';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 0 } });
  }
  toCancelledListingTelehealth() {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['AppointmentType'] = 'Telehealth';
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/view-cancelled-appointments'], { state: { searchAppointmentRequestModel: this.searchRequestForRedirection, selectedTab: 1, isBookAppintment: true, selectedfilterIndex: 0 } });
  }

  async RefreshPage() {
    this.webStorageService.deleteRandomNumber();
    let irndNumber = Math.floor((Math.random() * 100) + 1);
    this.webStorageService.storeRandomNumber(irndNumber);
    await this.GetCountNotScheduledCalls(true);
    await this.GetUrgentCallBackRequestCount(true);
    await this.getCountNoShowAppointment(true);
    await this.getCountOpenAppointment(true);
    await this.GetPotentialVoid(true);
    await this.GetTherapistDashboardStatistics(true);
    await this.getUnReconcileOrders(true);
    await this.getCancelled(true);
    this.showInfoMessage("Dashboard data is refreshed");
  }

  flip: string = 'inactive';

  toggleFlip() {
    this.flip = (this.flip == 'inactive') ? 'active' : 'inactive';
  }

  lessThan3Call(value: string) {
    this.initializeSearchForRedirection();
    this.searchRequestForRedirection.dictionaryFilter['SelectedDate'] = value;
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: false } });
  }
  showTodaysSummary() {
    if (this.checked) {
      this.router.navigate(['admin/today-dashboard'], { state: { todaysSummary: true } });
    }
  }

}
