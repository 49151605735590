import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { InputTextareaModule } from 'primeng/inputtextarea';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };


@Component({
  selector: 'app-update-status-of-appointment',
  templateUrl: './update-status-of-appointment.component.html',
  styleUrls: ['./update-status-of-appointment.component.sass']
})
export class UpdateStatusOfAppointmentComponent implements OnInit {

  reasonsResponseModel: CancelVoidResponseModel[];
  action:string;
  reasonComment:string = '';
  isBrightreeSystemAppUpdate: boolean = true;
  reason: CancelVoidResponseModel;
  restrictedApptForStatus: { crmSalesOrderAPPId: string, crmPatientName: string, schAppointmentDateTime: Date }[]; 
  totalSelectedItem: number;
  isRestrctedMessageOnly: boolean = false;
  formSubmitted: boolean = false;
  popupHeader:string;
  message:string = 'You are about to update the status of the selected appointment(s) to';

  validationMessages = {

    reason: {
      required: 'Reason is required',
    },
    reasonComment: {
      required: 'Comment is required',
    },
  };


  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
  }
  
  ngOnInit(): void {

    //this.reason = new CancelVoidResponseModel();
    if (this.dynamicDialogConfig) {
      if (this.dynamicDialogConfig?.data?.reasonsResponseModel != undefined){
        this.reasonsResponseModel = this.dynamicDialogConfig.data.reasonsResponseModel;  
       //this.reason = this.reasonsResponseModel[0];
      }
      if (this.dynamicDialogConfig?.data?.isBrightreeSystemAppUpdate != undefined) 
        this.isBrightreeSystemAppUpdate = this.dynamicDialogConfig.data.isBrightreeSystemAppUpdate;
      
      this.popupHeader = this.dynamicDialogConfig?.data?.popupHeader;
      if(this.popupHeader.includes('Sales'))
        this.message =  'You are about to update the status of the selected sales order(s) to';
     // this.popupHeader = this.popupHeader.replace('appointment(s)', 'Appointment(s)');      
      if (this.dynamicDialogConfig?.data?.isMultiSelected != undefined) {
        if (this.dynamicDialogConfig?.data?.isMultiSelected == 'False'){         
          this.popupHeader = this.popupHeader.replace('Appointment(s)', 'Appointment');
          this.message = this.message.replace('appointment(s)', 'appointment');  
          this.popupHeader = this.popupHeader.replace('Order(s)', 'Order'); 
          this.message = this.message.replace('order(s)', 'order');       
        }
      }  
      if (this.dynamicDialogConfig?.data?.action != undefined) {
        this.action = this.dynamicDialogConfig.data.action;  
        if (this.action.toLowerCase() == 'void review') {
          this.dynamicDialogConfig.data.action = 'Void Review';
          //this.popupHeader = this.popupHeader.replace('review', 'Review');
        }

          //for No Show and Complete
        if (this.action == 'No Show' || this.action == 'Complete') {
          if (this.dynamicDialogConfig?.data?.restrictedApptForStatus != undefined)
            this.restrictedApptForStatus = this.dynamicDialogConfig.data.restrictedApptForStatus;     
        
          if (this.dynamicDialogConfig?.data?.totalSelectedItem != undefined)
            this.totalSelectedItem = this.dynamicDialogConfig.data.totalSelectedItem;

            if (this.restrictedApptForStatus.length  == this.totalSelectedItem)
              this.isRestrctedMessageOnly = true;
        }
      }

    }    
    
  }


  onChange(event) {
    if (event.value) {
      this.reason = event.value;
    }
  }


  onCancel() {
    var status= new statusInfo();
    status.action= 'Cancel';
    status.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;
    status.reasonComment = this.reasonComment;
    if ((this.action.toLowerCase() != 'no show') && (this.action.toLowerCase() != 'complete'))
      status.reason = this.reason?.salesOrderVoidReason;
    this.dynamicDialogRef.close(status);    
  }

  onSubmit() {  
    this. formSubmitted = true;    
    if (this.reasonComment == '' || this.reason == undefined)
      return;
    var status= new statusInfo();
    status.action= 'Confirm';
    status.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;
    status.reasonComment = this.reasonComment;
    status.reason = this.reason?.salesOrderVoidReason;
    this.dynamicDialogRef.close(status);
  }  

}
