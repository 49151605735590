import { CompanySettingsRequestModel } from '../company-seetings-request-model';
import { SettingsRequestModel } from './settings-request-model';

export class SettingsGeneralRequestModel {
  companyId: number;
  companyName: string;
  companyCode: string;
  companyAddress: string;
  companyWebsite: string;
  companyPhone: string;
  companyContactFirstName: string;
  companyContactLastName: string;
  companyContactPhone: string;
  companyContactEmail: string;
  companyBillingAddress: string;
  isActive?: Boolean;
  modifiedByUserInfoId?: number;
  dateTimeCreated?: Date;
  dateTimeLastModified?: Date;
  companyLogo: string;
  companyFavicon: string;
  companySettings: Array<CompanySettingsRequestModel>;
  settingRequest:Array<SettingsRequestModel>;
}
