import { Pipe, PipeTransform } from '@angular/core';
import { TimeZoneAvailableResponseModel } from '../shared/models/response/time-zone-available-response-model';
import { WebStorageService } from '../shared/services/web-storage.service';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  timeZoneList: TimeZoneAvailableResponseModel[];
  constructor( 
    private webStorageService: WebStorageService){
      this.timeZoneList = this.webStorageService.getTimeZoneList();
  }

  transform(timezone) {
    if (timezone) {
      timezone =timezone.replace(" ", "");
      let shortCode = "";
      shortCode = this.timeZoneList.filter(x => x.appTimeZoneOffSet == timezone)[0].appDisplayTimeZoneCode;
      return shortCode;
    } else
      return "";
  }
}
