<div class="login">
  <div class="login__header">
    <div class="login__header__left">
      <div class="login__header__logo ">
        <img src="assets/img/appoint360-logo.png" height="80" width="105" title="Appoint360" alt="Appoint360">
      </div>

      <div class="login__header__client-logo">
        <div class="login__header__client-logo__client ">
          <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" height="65" title="{{imageTitle}}">
        </div>
      </div>
    </div>
  </div>
  <div class="login__content">
    <div class="login__content__left">
      <div class="login-img">
        <img src="assets/img/login-img.png" class="login__content__left__img" alt="login img" height="400">
      </div>
    </div>
    <div class="login__content__right">
      <form [formGroup]="form" (ngSubmit)="buttonSubmit.focus(); onSubmit();" class="form" autocomplete="off" *ngIf="!isRestrictMobileUsage">
        <h2>{{title}}</h2>
        <p>Enter your credentials below</p>
        <div class="form__group ">
          <input id="login-input" class="form__control error" placeholder="Email Id" formControlName="username" type="email" er="Email"
            pInputText>
          <span class="error-msg"
            *ngIf="hasError(formFields.username,'required')">{{commonValidationMessages.email.required}}</span>
        </div>
        <div class="form__group ">
          <input id="login-input-pass" class="form__control error" placeholder="Password" type="password" formControlName="password"
            er="Password" pInputText>
          <span class="error-msg"
            *ngIf="hasError(formFields.password,'required')">{{commonValidationMessages.password.required}}</span>
        </div>
        <div class="form__group login-btn">
          <p-checkbox name="groupname" value="val1" label="Remember Me" formControlName="rememberMe"
            binary="true"></p-checkbox>
          <button class="btn btn--primary" #buttonSubmit type="submit">
            Login
            <span class="login-loader" *ngIf="loading || loadingCustom"></span>
          </button>
        </div>
        <div class="form__group forgot-password">
          <a [routerLink]="['/forgot-password']" routerLinkActive="active">Forgot Password?</a>
          <a [routerLink]="['/support-request']" routerLinkActive="active">Need Help?</a>
        </div>
      </form>

      <div class="mobiles-not-support" *ngIf="isRestrictMobileUsage">
        <div class="mobiles-not-support__block">
            <h1>Not supporting in Mobile Browsers</h1>
            <p>Appoint360 application doesn't support in mobile browsers. <br>
                Please open Appoint360 URL in desktop browsers for the best user experience.</p>
            <button class="btn btn--primary" (click)="backToLogin()">
              Back To Login
          </button>
        </div>
    </div>
    </div>
  </div>
  <div class="login__footer">
    <div class="login__footer__left mobile-support" *ngIf="!isMobile">
      <h4>Appoint360 is best viewed on resolution width 1024 or greater</h4>
      <p><strong>For Windows OS:</strong> Google Chrome 88+, Mozilla Firefox 84+, Microsoft Edge 101+ browsers.</p>
      <p><strong>For Mac OS:</strong> Safari 13.1+, Google Chrome 88+, Mozilla Firefox 84+ browsers.</p>
    </div>
    <!-- <p class="login__footer__left mobile-support">*Application best view in 1280px + resolutions in <strong>Google Chrome, Mozilla Firefox & Microsoft Edge</strong> browsers.</p> -->

    <div class="login__footer__right">
      <span>Powered By<img src="assets/img/appoint360-logo.svg" alt="Appoint360" title="Appoint360" width="20"
          height="13">Appoint360</span>
    </div>
  </div>
</div>