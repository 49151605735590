import { Injectable } from '@angular/core';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserRequestModel } from 'src/app/shared/models/request/user-request-model';
import { RoleResponseModel } from 'src/app/shared/models/response/role-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { map } from 'rxjs/operators';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import * as moment from 'moment';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { ModuleFeatureResponseModel } from 'src/app/shared/models/response/settings/settings-permission-response-model';
@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  GetAllUsers(searchRequestModel: SearchRequestModel, isRefresh: boolean) {

    var url = 'Users/GetUsers';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;

    if (searchRequestModel.appUserInfoId) {
      url = url + '&appUserInfoId=' + searchRequestModel.appUserInfoId;
    }
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }

    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    if (searchRequestModel.dictionaryFilter) {
      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<UserResponseModel>>>(url);


    //return this.post<ResultOfT<PagedResponseModel<UserResponseModel>>>('Users/GetUsers', searchRequestModel);
  }

  GetUserById(id: string) {
    return this.get<ResultOfT<UserResponseModel>>('Users/GetUser/' + id);
  }

  AddUser(userResponseModel: UserRequestModel) {
    return this.post<ResultOfT<UserResponseModel>>('Users/Save', userResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateUser(userResponseModel: UserRequestModel) {
    return this.post<ResultOfT<UserResponseModel>>('Users/Update', userResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  deleteUser(userId: string, status: boolean) {
    return this.get<ResultOfT<UserResponseModel>>('Users/ActiveInactive/' + userId + '/' + status)
      .pipe(map((result) => {
        return result;
      }));
  }
  // deleteUser(userId: string, status: boolean) {
  //   return this.post<ResultOfT<UserResponseModel>>('Users/ActiveInactive', { userId, status })
  //     .pipe(map((result) => {
  //       return result;
  //     }));
  // }

  getAllRoles() {
    return this.get<ResultOfT<RoleResponseModel[]>>('Roles/GetRoles');
  }

  getAllRolesUsers() {
    return this.get<ResultOfT<RoleResponseModel[]>>('Roles/GetRolesUser');
  }

  getAllBranches() {
    return this.get<ResultOfT<BranchResponseModel[]>>('Users/GetBranches');
  }

  getAllPermissions() {
    return this.get<ResultOfT<ModuleFeatureResponseModel[]>>('Users/GetPermissions');
  }

  getUserBranches(appUserId: number) {
    return this.get<ResultOfT<BranchResponseModel[]>>('Users/GetUserBranches?UserId=' + appUserId);
  }

  getAllTimeZones() {
    return this.get<ResultOfT<TimeZoneAvailableResponseModel[]>>('Users/GetTimeZone');
  }
}
