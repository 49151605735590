import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ResultOfT } from '../models/response/result';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class BaseComponent {
  private messageService: MessageService;

  private defaultSuccessMessageLifeTime: number;
  private defaultWarningMessageLifeTime: number;
  private defaultErrorMessageLifeTime: number;

  loading: boolean;
  loadingCustom: boolean;
  form: FormGroup;
  pageSizes: string[] = ['20'];
  noRecordsFoundMessage = 'No records found'; 

  commonValidationMessages =
    {
      email: {
        required: 'Email ID is required',
        email: 'Email ID must be a valid email address',
        confirmRequired: 'Confirm Email ID is required',
        emailMatch: 'Email ID do not match'
      },
      username: {
        required: 'Username is required'
      },
      password: {
        required: 'Password is required',
        minlength: 'Password should be at least 6 characters',
        maxlength: 'Password should not be more than 12 characters'
      }
    };

  constructor(messageService: MessageService) {
    this.loading = false;
    this.messageService = messageService;
    this.defaultSuccessMessageLifeTime = environment.successMessageLifeTime;
    this.defaultWarningMessageLifeTime = environment.warningMessageLifeTime;
    this.defaultErrorMessageLifeTime = environment.errorMessageLifeTime;
  }

  processResult<T>(result: ResultOfT<T>, onSuccess: () => void = null, onError: () => void = null) {
    // To handle when request is cancelled
    if (!result)
      return;

    if (result.isSuccess) {
      if (onSuccess)
        onSuccess();
    }
    else {
      if (onError)
        onError();
      else {
        if (this.messageService)
          this.showErrorMessage(result.message);
      }
    }
  }

  markFormAsTouched() {
    Object.keys(this.form.controls).forEach((key) => {
      this.form.get(key).markAsTouched();
    });
  }

  hasError(controlName: string, validator: string) {
    const control = this.form.get(controlName);
    if (!control)
      return false;

    return (control.touched || control.dirty) && control.hasError(validator);
  }

  showSuccessMessage(message: string, title: string = 'Success Message', lifeTime?: number) {
    this.messageService.clear();
    if (!lifeTime)
      lifeTime = this.defaultSuccessMessageLifeTime;

    this.messageService.add({
      severity: 'success',

      detail: message,
      life: lifeTime
    });
  }

  showWarningMessage(message: string, title: string = 'Warn Message', lifeTime?: number) {
    this.messageService.clear();
    if (!lifeTime)
      lifeTime = this.defaultWarningMessageLifeTime;

    this.messageService.add({
      severity: 'warn',
      detail: message,
      life: lifeTime
    });
  }

  showErrorMessage(message: string, title: string = 'Error Message', lifeTime?: number) {
    if (!message) {
      return;
    }

    if (message.indexOf('Cannot read properties of undefined') > -1) {
      return;
    }
    if (message.indexOf('Cannot read property of undefined') > -1) {
      return;
    }
    if (message.indexOf('ViewDestroyedError') > -1) {
      return;
    }
    if (message.indexOf('Cannot read property \'responseModel\' of undefined') > -1) {
      return;
    }

    if (message.indexOf('t is undefined') > -1) {
      return;
    }


    this.messageService.clear();
    if (!lifeTime)
      lifeTime = this.defaultErrorMessageLifeTime;

    this.messageService.add({
      severity: 'error',
      detail: message,
      life: lifeTime
    });
  }
  showInfoMessage(message: string, title: string = 'Info Message', lifeTime?: number) {
    this.messageService.clear();
    if (!lifeTime)
      lifeTime = this.defaultErrorMessageLifeTime;

    this.messageService.add({
      severity: 'info',
      summary: title,
      detail: message,
      life: lifeTime
    });
  }

  getErrorMessage(httpResponseError: HttpErrorResponse) {
    if (httpResponseError.error && httpResponseError.error.exceptionMessage)
      return httpResponseError.error.exceptionMessage;

    return httpResponseError.message;
  }
}
