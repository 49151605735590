import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { HttpClient } from '@angular/common/http';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ExportResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { SalesOrderSynceRequestModel, SalesOrderAddSyncRequest } from 'src/app/shared/models/request/sync-sales-order-search-request-model';
import { SyncSalesOrderStatus, SyncHistory } from 'src/app/shared/models/response/sync-sales-order-status';
import { WipStatusBTResponseModel } from 'src/app/shared/models/response/wip-statusbt-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { OnBoardProcessHistoryModel } from '../../shared/models/response/settings/settings-onboarding-response-model';
import { ImportSalesOrderByIds } from 'src/app/shared/models/response/bell-notification-response-model';
import { RetrySyncSalesOrderRequestModel, RetrySyncSalesOrderStatusResposeModel } from '../../shared/models/request/retry-sync-sales-order-request-model';
@Injectable({
  providedIn: 'root'
})
export class ImportexportService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  importFacilityFile(path: string) {
    const filePath = {
      // tslint:disable-next-line: object-literal-shorthand
      filePath: path
    };
    return this.get<ResultOfT<string>>('Facility/ImportFacility/');
  }

  importDoctorFile(path: string) {
    const filePath = {
      // tslint:disable-next-line: object-literal-shorthand
      filePath: path
    };
    return this.get<ResultOfT<any>>('Doctor/ImportDoctor');
  }

  importTherapistFile(path: string) {
    const filePath = {
      // tslint:disable-next-line: object-literal-shorthand
      filePath: path
    };
    return this.get<ResultOfT<string>>('Therapist/ImportTherapist');
  }

  importSalesOrderFiles(path: string[]) {
    // const filePath = {
    //   filePath: path
    // };
    return this.post<ResultOfT<string>>('RCMProvider/ImportSalesOrder', path);
  }

  downloadFacilityTemplate() {
    return this.downloadCsvFile('Facility/DownloadFacilityTemplate');
  }

  downloadDoctorTemplate() {
    return this.downloadCsvFile('Doctor/DownloadDoctorTemplate');
  }

  downloadTherapistTemplate() {
    return this.downloadCsvFile('Therapist/DownloadTherapistTemplate');
  }

  downloadSalesOrderTemplate() {
    return this.downloadCsvFile('Appointment/DownloadSalesOrderTemplate');
  }

  downloadSalesOrderItemTemplate() {
    return this.downloadCsvFile('Appointment/DownloadSalesOrderItemTemplate');
  }

  downloadInsuranceTemplate() {
    return this.downloadCsvFile('Appointment/DownloadInsuranceTemplate');
  }

  downloadExportData(searchRequestModel: SearchRequestModel) {
    return this.downloadCsvFile('Appointment/GetExportData', searchRequestModel);
  }

  downloadfromHistory(id: number) {
    return this.downloadCsvFile('Appointment/DownloadFromHistory', id);
  }

  getHistory(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<ExportResponseModel>>>('Appointment/GetHistory', searchRequestModel);
  }
  synceRealTimeSalesOrder(salesOrderSearchRequestModel: SalesOrderSynceRequestModel) {
    return this.post<ResultOfT<SyncSalesOrderStatus>>('RCMProvider/SyncRealTimeRCMSalesOrder', salesOrderSearchRequestModel);
  }
  //synceRealTimeManualSalesOrder(salesOrderSearchRequestModel: SalesOrderSynceRequestModel) {

  //  return this.postCustomUrl<ResultOfT<SyncSalesOrderStatus>>(environment.AzureFunctionURL, salesOrderSearchRequestModel, new HttpHeaders({ timeout: `${900000}` }) );
  //}

  AddSyncRequest(salesOrderSearchRequestModel: SalesOrderAddSyncRequest) {
    return this.post<ResultOfT<string>>('RCMProvider/AddSyncRequest', salesOrderSearchRequestModel);
  }

  GetSyncHistory() {
    return this.get<ResultOfT<SyncHistory[]>>('RCMProvider/GetSyncHistory');
  }

  GetWipStatus() {
    return this.get<ResultOfT<WipStatusBTResponseModel[]>>('Settings/GetWipStatus');
  }

  SyncBySalesOrderId(brightreeId: string, isWipStatus: boolean, isSendInvitationLink: boolean) {
    return this.get<ResultOfT<any>>('RCMProvider/SyncSalesOrderById?brightreeId=' + brightreeId + '&isWipStatus=' + isWipStatus + '&isReImport=' + false + '&isSendInvitationLink=' + isSendInvitationLink);
  }

  RefreshSalesOrderById(brightreeId: string) {
    return this.get<ResultOfT<any>>('RCMProvider/RefreshSalesOrderById?brightreeId=' + brightreeId);
  }

  ReImportSalesOrderById(brightreeId: string, isWipStatus: boolean) {
    return this.get<ResultOfT<any>>('RCMProvider/SyncSalesOrderById?brightreeId=' + brightreeId + '&isWipStatus=' + isWipStatus + '&isReImport=' + true);
  }

  AddBellSalesOrderSyncNotification(importSalesOrderByIds: ImportSalesOrderByIds[]) {
    return this.post<ResultOfT<boolean>>('RCMProvider/AddBellSalesOrderSyncNotification', importSalesOrderByIds);
  }
  //on boarding//

  processImportData(fileName: string, moduleName: string, processType: boolean) {
    return this.get<ResultOfT<string>>('OnBoarding/ProcessImportData?fileName=' + fileName + "&onBoardModule=" + moduleName + "&processType=" + processType);
  }

  onBoardWipStateTemplateDownload(body: any) {
    //return this.post<ResultOfT<Blob>>('OnBoarding/DownloadTemplate', body);
    return this.downloadBlobFile('OnBoarding/DownloadWipStateTemplate', body);
  }

  onBoardDoctorTemplateDownload(body: any) {
    //return this.post<ResultOfT<Blob>>('OnBoarding/DownloadTemplate', body);
    return this.downloadBlobFile('OnBoarding/DownloadAppointMentTemplate', body);
  }


  onBoardAppoTemplateDownload(body: any) {
    //return this.post<ResultOfT<Blob>>('OnBoarding/DownloadTemplate', body);
    return this.downloadBlobFile('OnBoarding/DownloadAppointMentTemplate', body);
  }

  onBoardAppoFileDownload(body: any) {
    //return this.post<ResultOfT<Blob>>('OnBoarding/DownloadTemplate', body);
    return this.downloadBlobFile('OnBoarding/DownloadAppointMentFile', body);
  }

  GetOnBoardHistory(searchRequestModel: SearchRequestModel, isRefresh: boolean) {
    var url = 'OnBoarding/OnBoardHistory';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;

    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }
    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    return this.get<ResultOfT<OnBoardProcessHistoryModel[]>>(url);
  }

  UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId: string, CrmUpdatedBy: number) {
    return this.get<ResultOfT<any>>('RCMProvider/UpdateSalesOrderAppointmentDetailStatus?BrightreeInfoId=' + BrightreeInfoId + '&CrmUpdatedBy=' + CrmUpdatedBy);
  }


  RetrySalesOrderAppointmentDetailStatus(retrySyncSalesOrderRequestModel: RetrySyncSalesOrderRequestModel) {
    return this.post<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>('RCMProvider/RetrySalesOrderAppointmentDetailStatus', retrySyncSalesOrderRequestModel);
  }

  RetrySyncSalesOrderByIds(retrySyncSalesOrderRequestModel: RetrySyncSalesOrderRequestModel) {
    return this.post<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>('RCMProvider/RetrySyncSalesOrderByIds', retrySyncSalesOrderRequestModel);
  }
  InactiveSalesOrderAppointmentDetailStatus(retrySyncSalesOrderRequestModel: RetrySyncSalesOrderRequestModel) {
    return this.post<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>('RCMProvider/InactiveSalesOrderAppointmentDetailStatus', retrySyncSalesOrderRequestModel);
  }

  InactiveFailedSalesOrderByIds(retrySyncSalesOrderRequestModel: RetrySyncSalesOrderRequestModel) {
    return this.post<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>('RCMProvider/InactiveFailedSalesOrderByIds', retrySyncSalesOrderRequestModel);
  }



  //on boarding//
}
