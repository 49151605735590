export class ConfigResponseModel {
  companyId: string;
  companyName: string;
  companyCode: string;
  companyAddress: string;
  companyWebsite: string;
  companyPhone: string;
  companyContactFirstName: string;
  companyContactLastName: string;
  companyContactPhone: string;
  companyContactEmail: string;
  companyDomainUrl: string;
  companyApiurl: string;
  companyLogo: string;
  companyFavicon: string;
  isAuditLogEnabled: boolean = false;
  newThemeCss: string;
  salesOrderMethod:string;
  companyTermsAndCondition: string;
  isGoogleMap: boolean;

  isKPIAccess:boolean;

  isNewManageAvailability:boolean;

  companyGuid: string;



}
