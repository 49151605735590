export class AddressUpdateRequestModel {
  isUpdateToBrightree: boolean;
  CrmSalesOrderId: number;
  CrmPatientId: number;
  CrmDeliveryAddressLine1: string;
  CrmDeliveryAddressLine2: string;
  CrmDeliveryAddressCity: string;
  CrmDeliveryAddressState: string;
  CrmDeliveryAddressStateFullName: string;
  CrmDeliveryAddressCountry: string;
  CrmDeliveryAddressZipcode: string;
  ModifiedByUserInfoId: number;
  CrmDeliveryAddressTimeZone: string;
  btNoteId: number;
  isProvisional: boolean;
}
