
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';

export class TherapistLicenseResponseModel {
  therapistLicenseInfoId: number;
  therapistId: number;
  stateMasterId: number;
  isActive: boolean;
  licenceNumber: string;
  expiryDate: Date;
  expiryDateString: string;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  isLicenseCheck: boolean;

  stateMaster: StateMasterResponseModel
}
