import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarEventTitleFormatter } from 'angular-calendar';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { TherapistService } from 'src/app/admin/servicies/therapist.service';
import { AvailableSlots, HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots'
import { TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots'
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { TherapistCustomDates } from 'src/app/shared/models/request/therapist/therapist-custom-dates';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Location } from '@angular/common';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdownList, FacilityResponseModelDefaultTime } from 'src/app/shared/models/response/facility/facility-response-model';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import * as moment from 'moment';

@Component({
  selector: 'app-view-therapist-availability',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './view-therapist-availability.component.html',
  styleUrls: ['./view-therapist-availability.component.sass'],
  providers: [DialogService,
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    },
  ]
})
export class ViewTherapistAvailabilityComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  availableSlots: AvailableSlots[] = [];
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  title = "";
  therapistId: any;
  therapitsAvailability: TherapistAvailability[] = [];
  appFormatDate: DateFormatModel;
  therapistCustomDates: TherapistCustomDates;
  availableId: number = 0;
  customId: number;
  customDate: Date;
  startTime: Date;
  endTime: Date;
  event: string;
  overlaypanel: OverlayPanel;
  editEvent: boolean = false;
  eventText: string = "";
  selectedValue: string = "";
  defaultFacility: number = 0;
  defaultFacilityName: string = "";
  AppointmentStartLocation: string = "";
  isFullDay: boolean = false;
  isEditEvent: boolean = false;
  loadingCustom: boolean = false;
  SelectFacility1: string = 'Both Facility & Remote';
  holidays: any[] = [];
  timeselectorOpen = false;
  selectedFacilityTimeZone: string = "";

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  currentUserBranch: number;
  Facility: any[];
  SelectFacility: any[];
  RemoteAndFacility: string = '';
  //Add Edit Events

  showPopup: boolean = false;
  allfacilities: FacilityResponseModelDropdownList[];
  allfacilitiesRoadHome: FacilityResponseModelDropdownList[];
  facilities: FacilityResponseModelDropdownList[];
  selectedFacility: FacilityResponseModelDropdownList;
  facilitiesRemote: FacilityResponseModelDropdownList[];
  selectedFacilityRemote: FacilityResponseModelDropdownList;
  time: FacilityResponseModelDefaultTime;
  weekDays: EnumModel[] = [];
  selectedWeekDays: EnumModel[] = [];
  selectedWeekDay: EnumModel;
  eventSeries: EnumModel[];
  prevEventSeries: EnumModel;
  selectedEventSeries: EnumModel;
  therapistAvailability: TherapistCustomAvailabilityResponseModel;
  breakStartTime: string;
  breakEndTime: string;
  description: string;
  currentUserToken: UserAuthResponseModel;
  customStartDate: Date;
  customStartTime: string;
  customEndDate: Date;
  customEndTime: string;
  loggedInUserId: number;
  selectedEvent: string;
  selectedEvent1: string;
  selectFacility: string;
  selecteRec: boolean;
  isBreakHours: boolean = false;
  isBreakHoursDisplayed: boolean = false;
  isSetRecurrenceDisplayed: boolean = false;
  isFacilityDisplayed: boolean;
  isExactDate: boolean = false;
  errorcode: number;
  selectedOccurrence: { name: string, code: number };
  OrgtherapistId: number = 0;
  Occurrence = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
  ];
  selectedExactDate: { name: string, code: number };
  ExactDate = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
    { name: '6', code: 6 },
    { name: '7', code: 7 },
    { name: '8', code: 8 },
    { name: '9', code: 9 },
    { name: '10', code: 10 },
    { name: '11', code: 11 },
    { name: '12', code: 12 },
    { name: '13', code: 13 },
    { name: '14', code: 14 },
    { name: '15', code: 15 },
    { name: '16', code: 16 },
    { name: '17', code: 17 },
    { name: '18', code: 18 },
    { name: '19', code: 19 },
    { name: '20', code: 20 },
    { name: '21', code: 21 },
    { name: '22', code: 22 },
    { name: '23', code: 23 },
    { name: '24', code: 24 },
    { name: '25', code: 25 },
    { name: '26', code: 26 },
    { name: '27', code: 27 },
    { name: '28', code: 28 },
    { name: '29', code: 29 },
    { name: '30', code: 30 },
    { name: '31', code: 31 }
  ];



  //Add Edit Events

  constructor(private modal: NgbModal,
    private router: Router,
    private location: Location,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    protected cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private therapistService: TherapistService,
    private dialougeService: DialogService,
    private facilityService: FacilityService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.calendarActions = [
      { name: 'View By Facility', code: 'ViewByFacility' },
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'View By Available Slots', code: 'ViewByAvailableSlots' }
    ];
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.appFormatDate = this.webStorageService.getDateFormat();
    if (this.webStorageService.getCurrentUserToken())
      this.loggedInUserId = this.webStorageService.getCurrentUserToken().userId;
    this.getDaysOfWeek();
    this.getEventSeries()
  }

  async ngOnInit() {

    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = params['id'];
      });
    await this.getFacilities();
    await this.getTherapist();
    await this.getFacilitiesRoadHomeVisit();
    await this.getTherapistAvailability(true);
    await this.getHolidayList(false);

    this.cdr.detectChanges();
    this.refresh.next();
  }

  async getFacilities() {

    this.progress.next(true);
    this.facilityService.getAllFacilityDropDownforAvailability(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.allfacilities = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async getFacilitiesRoadHomeVisit() {
    this.progress.next(true);
    this.facilityService.GetAllFacilitiesForRoadHomeVisit(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.allfacilitiesRoadHome = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  setFacilityName() {
    this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
  }

  async getTime() {
    this.progress.next(true);
    var day = 1;
    if (new Date().getDay() == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = new Date().getDay() - 1;
      day = Math.pow(2, power);
    }
    let FacilityTimeZone = null;
    if (this.selectedFacilityTimeZone != null && this.selectedFacilityTimeZone != undefined) {
      FacilityTimeZone = this.selectedFacilityTimeZone;
    }
    if (FacilityTimeZone == null || FacilityTimeZone == "") {
      FacilityTimeZone = "null";
    }
    this.facilityService.getTimeWithTimeZone(this.selectedFacility.crmFacilityId, day, FacilityTimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result || !result.responseModel) {
          return;
        }
        this.time = result.responseModel;

        this.customStartTime = this.time.dtStart;
        this.customEndTime = this.time.dtEnd;
        //this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getFacilitiesPopupeExt(isRemote: boolean) {

    this.facilities = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    if (this.availableId <= 0 && !isRemote) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.getTime();
      }
    }
    //this.selectedFacility = this.facilities[0];
  }

  getFacilitiesForRoadHomeVisit() {
    this.facilities = this.allfacilitiesRoadHome;
    if (this.availableId <= 0) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.getTime();
      }
    }
    //this.selectedFacility = this.facilities[0];
  }


  getFacilitiesPopupeRemote(isRemote: boolean) {

    this.facilitiesRemote = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    if (this.availableId <= 0 && !isRemote) {
      if (this.defaultFacility > 0) {
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.getTime();
      }
    }
  }
  SetEvents() {

    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    this.isFullDay = false;
    switch (this.selectedEvent) {
      case "WOT":
        this.getFacilitiesPopupeExt(false);
        this.getFacilitiesPopupeRemote(true);
        this.isBreakHoursDisplayed = true;
        this.isExactDate = false;
        this.isSetRecurrenceDisplayed = true;
        break;
      case "R":
        this.getFacilitiesForRoadHomeVisit();
        this.isSetRecurrenceDisplayed = true;
        this.isBreakHoursDisplayed = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";

        break;
      case "O":
        this.isSetRecurrenceDisplayed = true;
        this.isBreakHoursDisplayed = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;

    }
  }
  SetEventsFacilityOption() {

    //this.AppointmentStartLocation = '';
    //this.isBreakHoursDisplayed = false;
    //this.isSetRecurrenceDisplayed = false;
    switch (this.RemoteAndFacility) {
      case "FacilityOnly":
        this.getFacilitiesPopupeExt(false);
        break;
      case "RemoteOnly":
        this.getFacilitiesPopupeRemote(true);
        break;
      case "Both":
        this.getFacilitiesPopupeExt(false);
        this.getFacilitiesPopupeRemote(true);
        break;
      default:
        this.getFacilitiesPopupeExt(false);
        //this.getFacilitiesForRoadHomeVisit()
        break;
    }
  }
  SetFacilityEventsForRoadShowOption() {
    this.getFacilitiesForRoadHomeVisit();
  }


  setEventsExtended() {
    if (this.selectedEvent1 == "A") {
      this.RemoteAndFacility = "FacilityOnly";
      this.selectedEvent = "WOT";
    }
    this.SetEvents1();
  }
  SetEvents1() {
    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    this.isFullDay = false;
    switch (this.selectedEvent1) {
      case "A":
        this.getFacilitiesPopupeExt(false);
        this.getFacilitiesPopupeRemote(true);
        this.isBreakHoursDisplayed = true;
        this.isExactDate = false;
        this.isSetRecurrenceDisplayed = true;
        break;
      case "NA":
        this.selectedEvent = null;
        this.isSetRecurrenceDisplayed = true;
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;

    }
  }

  getDaysOfWeek() {
    this.progress.next(true);
    this.therapistService.getDaysOfWeek()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.weekDays = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setEventText() {

    if (this.selectedOccurrence && this.selectedEventSeries) {
      if (this.selectedEventSeries.value == 2) {
        if (this.selectedOccurrence.code == 1) {
          this.eventText = 'Every Week';
        }
        else {
          this.eventText = 'Every ' + this.selectedOccurrence.code.toString() + ' Weeks';
        }
      }
      if (this.selectedEventSeries.value == 3) {
        if (this.selectedOccurrence.code == 1) {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st of Every Month';
          else if (this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd of Every Month';
          else if (this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd of Every Month';
          else
            this.eventText = this.selectedExactDate.code + 'th of Every Month';
        }
        else {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else if (this.selectedExactDate && this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else if (this.selectedExactDate && this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else
            this.eventText = this.selectedExactDate.code + 'th of Every ' + this.selectedOccurrence.code.toString() + ' Months';
        }
      }
      if (this.selectedEventSeries.value == 4) {
        if (this.selectedExactDate && this.selectedOccurrence.code == 1) {
          if (this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st Jan of Every Year';
          else if (this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd Jan of Every Year';
          else if (this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd Jan of Every Year';
          else
            this.eventText = this.selectedExactDate.code + 'th Jan of Every Year';
        }
        else {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else if (this.selectedExactDate && this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else if (this.selectedExactDate && this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else
            this.eventText = this.selectedExactDate.code + 'th Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
        }
      }
    }
  }

  getEventSeries() {
    this.progress.next(true);
    this.therapistService.getEventSeries()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.eventSeries = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onChangeEventSeries(item: EnumModel) {
    // 
    this.selectedEventSeries = item;
    item.isSelected = true;
    this.selectedEventSeries.isSelected = true;
    switch (item.name) {
      case "Weekly":
        this.isExactDate = false;
        break;
      case "Monthly":
        this.isExactDate = true;
        break;
      case "Yearly":
        this.isExactDate = true;
        break;
      default:
        break;

    }
    this.setEventText();
  }

  onChangeDayOfWeek(item: EnumModel) {
    item.isSelected = !item.isSelected;
  }

  onEventOccurrenceChange(occurrence) {
    this.selectedOccurrence = occurrence.value;
    this.setEventText();
  }

  onExactDateChange() {
    this.setEventText();
  }

  getAvailability() {
    if (this.availableId && this.availableId <= 0) {
      return;
    }
    this.progress.next(true);

    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.getAvailabilityNewById(this.availableId, this.customId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.therapistAvailability = result.responseModel;
          this.setValuesToForm();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async setValuesToForm() {
    this.therapistId = this.therapistAvailability.enUserInfoId;
    if (this.therapistAvailability.crmIsHoliday) {
      this.selectedEvent1 = "NA";
      this.SetEvents1();
    }
    else if (this.therapistAvailability.crmIsRoadShow) {
      this.selectedEvent1 = "A";
      this.selectedEvent = "R";

      if (this.therapistAvailability.isStartFromDefaultFacility)
        this.AppointmentStartLocation = 'isStartFromDefaultFacility';
      else if (this.therapistAvailability.isStartFromHomeAddress)
        this.AppointmentStartLocation = 'isStartFromHomeAddress';
      else
        this.AppointmentStartLocation = '';

      this.SetEvents();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
      if (this.therapistAvailability.isStartFromDefaultFacility) {
        this.getFacilitiesRoadHomeVisit();
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }
    }
    else if (this.therapistAvailability.isOffice) {
      this.selectedEvent1 = "A";
      this.selectedEvent = "O";
      this.SetEvents();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
    }
    else {
      if (this.therapistAvailability.isRemoteAndFacility) {
        this.RemoteAndFacility = "Both";
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.SetEvents();
        this.selecteRec = false;
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmRemoteFacilityId);
        this.SetEventsFacilityOption();
      }
      else if (this.therapistAvailability.isRemoteAssistant) {
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.RemoteAndFacility = "RemoteOnly";
        this.SetEvents();
        this.SetEventsFacilityOption();
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
      }
      else {
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.RemoteAndFacility = "FacilityOnly";
        this.SetEvents();
        this.selecteRec = false;
        this.SetEventsFacilityOption();
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }


    }
    if (this.therapistAvailability.crmIsRecurring) {
      this.selecteRec = this.therapistAvailability.crmIsRecurring;
    }
    if (this.therapistAvailability.crmBreakEndTimeString) {
      this.breakEndTime = this.therapistAvailability.crmBreakEndTimeString.toString().split(':')[0] + ':' + this.therapistAvailability.crmBreakEndTimeString.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistAvailability.crmBreakStartTimeString) {
      this.breakStartTime = this.therapistAvailability.crmBreakStartTimeString.toString().split(':')[0] + ':' + this.therapistAvailability.crmBreakStartTimeString.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }

    this.description = this.therapistAvailability.crmDescription;
    this.selectedOccurrence = this.Occurrence.find(x => x.code == this.therapistAvailability.crmRecurringFrequency);
    this.customEndDate = new Date(this.therapistAvailability.crmTherapistCustomEndDate);
    this.customEndTime = this.therapistAvailability.crmTherapistCustomEndTimeString.split(':')[0] + ':' + this.therapistAvailability.crmTherapistCustomEndTimeString.split(':')[1];
    this.customStartDate = new Date(this.therapistAvailability.crmTherapistCustomStartDate);
    this.customStartTime = this.therapistAvailability.crmTherapistCustomStartTimeString.split(':')[0] + ':' + this.therapistAvailability.crmTherapistCustomStartTimeString.split(':')[1];
    if (this.therapistAvailability.exactDay) {
      this.selectedExactDate = this.ExactDate.find(x => x.code == this.therapistAvailability.exactDay);
      this.isExactDate = true;
    }
    this.weekDays.forEach((value) => {

      if (this.therapistAvailability.crmCustomDays.split(',').length > 0) {
        this.therapistAvailability.crmCustomDays.split(',').forEach((item) => {
          if (item == value.value.toString()) {
            value.isSelected = true;

          }
        });
      }
    });

    this.selectedValue = this.therapistAvailability.crmRecurringType.toString();
    this.eventSeries.forEach((value) => {
      if (this.therapistAvailability.crmRecurringType == value.value) {
        value.isSelected = true;
        this.selectedEventSeries = value;
      }
    });

    if (this.selectedEventSeries) {
      switch (this.selectedEventSeries.name) {
        case "Weekly":
          this.isExactDate = false;
          break;
        case "Monthly":
          this.isExactDate = true;
          break;
        case "Yearly":
          this.isExactDate = true;
          break;
        default:
          break;

      }
    }
    this.setEventText();
    this.isFullDay = this.therapistAvailability.isFullDay;
    this.isEditEvent = false;
    this.showPopup = true;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  async onSave() {
    debugger;
    if (!this.validateForm()) {
      return;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeString = this.isBreakHours ? moment(this.breakEndTime, 'hh:mm A').format("HH:mm") : null;
    requestModel.crmBreakStartTimeString = this.isBreakHours ? moment(this.breakStartTime, 'hh:mm A').format("HH:mm") : null;
    requestModel.isStartFromDefaultFacility = this.selectedEvent == "R" && this.AppointmentStartLocation == 'isStartFromDefaultFacility' ? true : false;
    requestModel.isStartFromHomeAddress = this.selectedEvent == "R" && this.AppointmentStartLocation == 'isStartFromHomeAddress' ? true : false;
    requestModel.crmDescription = this.selectedEvent1 == "NA" || this.selectedEvent == "O" ? this.description : "";
    if (this.selectedEvent == "WOT" && (this.RemoteAndFacility == 'FacilityOnly' || this.RemoteAndFacility == 'Both')) {
      requestModel.crmFacilityId = this.selectedFacility.crmFacilityId;
      requestModel.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
    }
    else if (this.selectedEvent == "WOT" && this.RemoteAndFacility == 'RemoteOnly') {
      requestModel.crmFacilityId = this.selectedFacilityRemote.crmFacilityId;
      var selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
      if (selectedFacilityTimeZone != null && selectedFacilityTimeZone != undefined) {
        if (selectedFacilityTimeZone.crmTimeZone != null && selectedFacilityTimeZone.crmTimeZone != undefined && selectedFacilityTimeZone.crmTimeZone != "") {
          requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
        }
        else {
          requestModel.selectedFacilityTimeZone = "UTC-5";
        }
      }
      else {
        requestModel.selectedFacilityTimeZone = "UTC-5";
      }

    }
    else {
      requestModel.crmFacilityId = null;
      if (this.selectedEvent == 'R') {
        if (requestModel.isStartFromDefaultFacility) {
          requestModel.crmFacilityId = this.selectedFacility.crmFacilityId;
          requestModel.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        }
        else {
          requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
        }
      }
      else {
        requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
      }

      if (this.selectedEvent == "O") {
        var selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        if (selectedFacilityTimeZone != null && selectedFacilityTimeZone != undefined) {
          if (selectedFacilityTimeZone.crmTimeZone != "") {
            requestModel.selectedFacilityTimeZone = selectedFacilityTimeZone.crmTimeZone;
          }
          else {
            requestModel.selectedFacilityTimeZone = "UTC-5";
          }
        }

      }
    }

    requestModel.crmRemoteFacilityId = this.selectedEvent == "WOT" && this.RemoteAndFacility == 'Both' ? this.selectedFacilityRemote.crmFacilityId : null;
    requestModel.isRemoteAndFacility = this.selectedEvent == "WOT" && this.RemoteAndFacility == 'Both' ? true : false;
    requestModel.crmIsHoliday = this.selectedEvent1 == "NA";
    requestModel.crmIsRecurring = this.selecteRec;
    requestModel.crmIsRoadShow = this.selectedEvent == "R";
    requestModel.isRemoteAssistant = this.selectedEvent == "WOT" && this.RemoteAndFacility == 'RemoteOnly';
    requestModel.crmRecurringFrequency = this.selecteRec ? this.selectedOccurrence.code : 1;
    requestModel.crmRecurringType = this.selecteRec ? this.selectedEventSeries.value : 1;

    requestModel.crmTherapistCustomEndDateString = moment(this.customEndDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomEndTimeString = moment(this.customEndTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistCustomStartDateString = moment(this.customStartDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomStartTimeString = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistId = this.OrgtherapistId;
    requestModel.crmTherpistCustomAvailabilityId = this.availableId;
    if (this.isEditEvent) {
      requestModel.customId = this.customId;
    }
    requestModel.crmIsExactDay = this.isExactDate;
    requestModel.crmCustomDays = "";
    requestModel.isOffice = this.selectedEvent == "O";
    requestModel.modifiedByUserInfoId = this.loggedInUserId;
    requestModel.isFullDay = this.isFullDay;
    if (this.weekDays.length > 0) {
      this.weekDays.forEach((value) => {
        if (value.isSelected)
          requestModel.crmCustomDays += value.value + ',';
      })
    }

    if (this.selectedEventSeries) {
      if (this.selectedEventSeries.name == "Monthly") {
        requestModel.monPattern = 4095;
      }

      if (this.selectedExactDate) {
        requestModel.exactDay = this.selectedExactDate.code;
      }
      else {
        requestModel.exactDay = null;
      }

      if (this.selectedEventSeries.name == "Yearly") {
        requestModel.yearFrequcncy = this.selectedOccurrence.code;
      }
    }

    if (!this.isEditEvent) {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        if (this.availableId == 0)
          this.SetAvailability(requestModel);
        else
          this.UpdateAvailability(requestModel);
      }

      else if (this.errorcode == 3) {
        this.dialougeService.open(ConfirmDialogComponent, {
          data:
          {
            message: 'There is already availability set for this period, do you wish to override the availability.',
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            if (this.availableId == 0)
              this.SetAvailability(requestModel);
            else
              this.UpdateAvailability(requestModel);
          }
        });
      }

      else if (this.errorcode == 4) {
        if (this.availableId == 0)
          this.SetAvailability(requestModel);
        else
          this.UpdateAvailability(requestModel);
        //this.dialougeService.open(InformationDialogComponent, {
        //  data:
        //  {
        //    message: 'Series can not be booked from ' + moment(this.customStartDate).format("MM/DD/yyyy") + ' - ' + moment(this.customEndDate).format("MM/DD/yyyy") + ' as there are holidays on the same dates.',
        //  },
        //  header: 'Confirmation'
        //}).onClose.subscribe((response: boolean) => {
        //  if (response) {
        //    return;
        //  }
        //});
      }
    }
    else {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        this.SetCustomAvailability(requestModel);
      }
      else if (this.errorcode == 3) {
        this.dialougeService.open(ConfirmDialogComponent, {
          data:
          {
            message: 'There is already availability set for this period, do you wish to override the availability.',
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            this.SetCustomAvailability(requestModel);
          }
        });
      }
      else if (this.errorcode == 4) {
        //this.dialougeService.open(InformationDialogComponent, {
        //  data:
        //  {
        //    message: 'Series can not be booked from ' + moment(this.customStartDate).format("MM/DD/yyyy") + ' - ' + moment(this.customEndDate).format("MM/DD/yyyy") + ' as there are holidays on the same dates.',
        //  },
        //  header: 'Confirmation'
        //}).onClose.subscribe((response: boolean) => {
        //  if (response) {
        //    return;
        //  }
        //});
        this.SetCustomAvailability(requestModel);
      }
    }

  }

  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.setAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  SetCustomAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.setCustomAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.updateAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability updated successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onCancel() {
    this.showPopup = false;
    this.onClear();
    this.getTherapistAvailability(true);
  }

  validateForm(): boolean {
    debugger;
    if (!this.selectedEvent1) {
      this.showWarningMessage('Please select Events Type.');
      return false;
    }
    if (this.selectedEvent1 == "A") {
      if (!this.selectedEvent) {
        this.showWarningMessage('Please select Appointment Type.');
        return false;
      }
    }
    if (this.selectedEvent1 == "NA") {
      if (!this.description) {
        this.showWarningMessage('Please enter description.');
        return false;
      }
    }

    switch (this.selectedEvent) {
      case "WOT":

        if (this.RemoteAndFacility == 'FacilityOnly') {

          if (!this.selectedFacility) {
            this.showWarningMessage('Please select a facility.');
            return false;
          }
        }
        else if (this.RemoteAndFacility == 'RemoteOnly') {

          if (!this.selectedFacilityRemote) {
            this.showWarningMessage('Please select a remote facility.');
            return false;
          }
        }
        else if (this.RemoteAndFacility == 'Both') {
          if (!this.selectedFacility) {
            this.showWarningMessage('Please select a facility.');
            return false;
          }
          if (!this.selectedFacilityRemote) {
            this.showWarningMessage('Please select a remote facility.');
            return false;
          }
        }
        break;
      case "T":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "O":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      case "R":
        if (!this.AppointmentStartLocation) {
          this.showWarningMessage('Please select Appointment Start Location.');
          return false;
        }
        if (this.AppointmentStartLocation == 'isStartFromDefaultFacility' && !this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      default:
        break;

    }
    switch (this.selectedEvent1) {
      case "W":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      default:
        break;

    }

    if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select Days of Week.');
      return false;
    }

    if (!this.customStartDate) {
      this.showWarningMessage('Please select Start Date.');
      return false;
    }
    if (!this.customEndDate) {
      this.showWarningMessage('Please select End Date.');
      return false;
    }
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be larger than Start Date.');
      return false;
    }
    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    if (new Date(commonDate + ' ' + this.customEndTime) < new Date(commonDate + ' ' + this.customStartTime)) {
      this.showWarningMessage('End Time should be larger than Start Time.');
      return false;
    }
    if (this.selecteRec) {
      if (!this.selectedOccurrence) {
        this.showWarningMessage('Please select Occurrence Type.');
        return false;
      }
      if (this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
        this.showWarningMessage('Please select event series.');
        return false;
      }
    }
    if (this.isBreakHours) {

      //if (!this.breakStartTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      //if (!this.breakEndTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      if (new Date(commonDate + ' ' + this.breakEndTime) < new Date(commonDate + ' ' + this.breakStartTime)) {
        this.showWarningMessage('Break End Time should be larger than Break Start Time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakStartTime) > new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakStartTime) < new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break Start time should be between start time and end time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakEndTime) > new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakEndTime) < new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break End time should be between start time and end time');
        return false;
      }

    }
    //if(!this.validateIsHolidayDate()){
    //  this.showWarningMessage('Invalid Request');
    //  return false;
    //}
    return true;
  }

  async validateRequest(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    await this.therapistService.validateAvailability(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {

        if (result && result.errorCode == 3) {
          this.errorcode = result.errorCode
        }
        else if (result && result.errorCode == 4) {
          this.errorcode = result.errorCode;
          //this.showWarningMessage(result.message);
        }
        else if (result && result.errorCode != 0) {
          this.showWarningMessage(result.message);
          this.errorcode = -1;
        }
        else {
          this.errorcode = 0;
        }

      })
      .catch((httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });
  }

  RedirectcalendarActions() {

    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByFacility")
      this.router.navigate(['/admin/calendar/view-by-facility']);
    else if (this.selectedcalendarActions.code == "ViewByTherapist")
      this.router.navigate(['/admin/calendar/view-by-therapist']);
    else if (this.selectedcalendarActions.code == "ViewByDate")
      this.router.navigate(['/admin/calendar/view-by-date']);
    else if (this.selectedcalendarActions.code == "ViewByAvailableSlots")
      this.router.navigate(['/admin/calendar/view-by-available-slots']);

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        //draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dateChange() {

    this.getTherapistAvailability(true);
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }


    if (this.progress)
      this.progress.complete();
  }

  private async getTherapistAvailability(isRefersh: boolean) {
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.therapistService.GetTherapistAvailabilityCalendar(this.therapistId, selectedDate, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => {
        this.loadingCustom = false;
        this.cdr.detectChanges();
        this.progress.next(false);
      }))
      .toPromise()
      .then((result) => {
        this.processResult<TherapistAvailability[]>(result, () => {
          this.therapitsAvailability = result.responseModel;
          var obj: Array<any> = [];
          for (var i = 0; i < this.therapitsAvailability.length; i++) {
            if (!this.therapitsAvailability[i].isHoliday && !this.therapitsAvailability[i].isOffice) {
              var event: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: moment(this.therapitsAvailability[i].startTime).toDate(),
                end: moment(this.therapitsAvailability[i].endTime).toDate(),
                title: !this.therapitsAvailability[i].isFullDay ? moment(this.therapitsAvailability[i].startTime).format("hh:mm A").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday,
                isRemoteAndFacility: this.therapitsAvailability[i].isRemoteAndFacility
              }
              obj.push(event);
            }
            else {
              var event1: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: moment(this.therapitsAvailability[i].startTime).toDate(),
                end: moment(this.therapitsAvailability[i].endTime).toDate(),
                title: !this.therapitsAvailability[i].isFullDay ? moment(this.therapitsAvailability[i].startTime).format("hh:mm A").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday,
                isRemoteAndFacility: this.therapitsAvailability[i].isRemoteAndFacility
              }
              obj.push(event1);
            }
          }
          this.events = obj;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onEventClick(event1, event: CalendarEvent, overlaypanel: OverlayPanel, desc: string) {

    this.availableId = Number(event.id.toString().split(',')[1]);
    this.customId = Number(event.id.toString().split(',')[0]);
    this.description = desc;
    overlaypanel.toggle(event1);
    this.cdr.detectChanges();
    this.refresh.next();
  }

  EditSeries(event, element): void {
    element.hide(event);
    this.description = "";
    this.onClear();
    this.getAvailability();
    this.cdr.detectChanges();
    this.refresh.next();
  }

  Delete(event, element): void {
    element.hide(event);
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.therapistService.DeleteCustomDate(Number(this.customId))
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage("Availability deleted successfully.");
            this.getTherapistAvailability(true);
            this.cdr.detectChanges();
            this.refresh.next();
          }
          else {
            let _errorMessage = "";
            _errorMessage = result.message;

            if (_errorMessage == undefined || _errorMessage == "null" || _errorMessage == "")
              _errorMessage = 'Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)';

            this.showWarningMessage(_errorMessage);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });

    });

  }

  Edit(event, element): void {
    element.hide(event);
    this.onClear();
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.GetCustomDate(Number(this.customId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); }))
      .subscribe((result) => {
        if (result.isSuccess) {

          this.therapistCustomDates = result.responseModel;
          this.setForm();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);

      });

    this.cdr.detectChanges();
    this.refresh.next();
  }

  setForm() {
    debugger;
    this.therapistId = this.therapistCustomDates.enUserInfoId;
    if (this.therapistCustomDates.crmIsHoliday) {
      this.selectedEvent1 = "NA";
      this.SetEvents1();

    }
    else if (this.therapistCustomDates.crmIsRoadShow) {
      this.selectedEvent1 = "A";
      this.selectedEvent = "R";
      if (this.therapistCustomDates.isStartFromDefaultFacility)
        this.AppointmentStartLocation = 'isStartFromDefaultFacility';
      else if (this.therapistCustomDates.isStartFromHomeAddress)
        this.AppointmentStartLocation = 'isStartFromHomeAddress';
      else
        this.AppointmentStartLocation = '';

      this.SetEvents();

      if (this.therapistCustomDates.isStartFromDefaultFacility) {
        this.getFacilitiesForRoadHomeVisit();
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }

    }
    else if (this.therapistCustomDates.isOffice) {
      this.selectedEvent1 = "A";
      this.selectedEvent = "O";
      this.SetEvents();
    }
    else {
      if (this.therapistCustomDates.isRemoteAndFacility) {
        this.RemoteAndFacility = "Both";
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.SetEvents();
        this.selecteRec = false;
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.crmRemoteFacilityId);
        this.SetEventsFacilityOption();
      }
      else if (this.therapistCustomDates.isRemoteAssistant) {
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.RemoteAndFacility = "RemoteOnly";
        this.SetEvents();
        this.SetEventsFacilityOption();
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
      }
      else {
        this.selectedEvent1 = "A";
        this.selectedEvent = "WOT";
        this.RemoteAndFacility = "FacilityOnly";
        this.SetEvents();
        this.selecteRec = false;
        this.SetEventsFacilityOption();
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }
    }
    if (this.therapistCustomDates.crmBreakEndTimeString) {
      this.breakEndTime = this.therapistCustomDates.crmBreakEndTimeString.toString().split(':')[0] + ':' + this.therapistCustomDates.crmBreakEndTimeString.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistCustomDates.crmBreakStartTimeString) {
      this.breakStartTime = this.therapistCustomDates.crmBreakStartTimeString.toString().split(':')[0] + ':' + this.therapistCustomDates.crmBreakStartTimeString.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    this.customEndDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customEndTime = this.therapistCustomDates.crmEndTimeString.split(':')[0] + ':' + this.therapistCustomDates.crmEndTimeString.split(':')[1];
    this.customStartDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customStartTime = this.therapistCustomDates.crmStartTimeString.split(':')[0] + ':' + this.therapistCustomDates.crmStartTimeString.split(':')[1];
    this.description = this.therapistCustomDates.description;

    var day = new Date(this.therapistCustomDates.crmCustomDate).getDay();
    var selectedDay = 0;
    if (day == 0) {
      selectedDay = Math.pow(2, 6);
    } else {
      selectedDay = Math.pow(2, (day - 1));
    }

    this.weekDays.forEach((value) => {
      if (selectedDay == value.value) {
        value.isSelected = true;
      }
    });

    this.isFullDay = this.therapistCustomDates.isFullDay;
    this.isEditEvent = true;
    this.showPopup = true;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  onSubmit() {

    this.therapistCustomDates.crmCustomDate = this.customDate;
    this.therapistCustomDates.crmEndTimeDate = this.endTime;
    this.therapistCustomDates.crmStartTimeDate = this.startTime;
    if (this.event == "W") {
      this.therapistCustomDates.crmIsHoliday = true;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "R") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = true;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "T") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = true;
    }
    this.therapistService.UpdateCustomDate(this.therapistCustomDates)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Availability updated successfully');
          this.editEvent = false;
          this.getTherapistAvailability(true);
          this.cdr.detectChanges();
          this.refresh.next();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private async getTherapist() {
    if (!this.therapistId) {
      return;
    }

    this.progress.next(true);
    await this.therapistService.GetTherapistByTherapistId(this.therapistId.toString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results || !results.responseModel) {
          return;
        }
        this.OrgtherapistId = results.responseModel.appUserInfoId;
        this.title = 'Manage Therapist Availability - ' + results.responseModel.appUserLname + ', ' + results.responseModel.appUserFname;
        if (results.responseModel.therapistDefaultFacility && results.responseModel.therapistDefaultFacility[0] && this.allfacilities) {
          this.defaultFacility = results.responseModel.therapistDefaultFacility[0].crmFacilityId;
          let defFac = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
          if (defFac)
            this.defaultFacilityName = defFac.crmFacilityName;
          if (this.availableId <= 0) {
            this.selectedFacility = results.responseModel.therapistDefaultFacility[0];
            this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
            this.getTime();
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBack() {
    this.location.back();
  }

  ManageAvailability(date) {
    debugger;
    // if(avEvents.filter(x=>x.isGlobalHoliday ==true).length > 0){
    //   return;
    // }
    //if (!date)
    //date = new Date();

    if (date) {
     let firstDtofMonth = moment(this.viewDate).startOf('month').toDate();
      if (new Date(date) < firstDtofMonth) {
        return false;
      }

    }
    this.isEditEvent = false;
    this.onClear();
    this.showPopup = true;
    if (date) {
      this.customStartDate = new Date(date);
      this.customEndDate = new Date(date);
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date(date)).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    else {
      this.customStartDate = new Date();
      this.customEndDate = new Date();
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date()).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    this.cdr.detectChanges();
    this.refresh.next();
    this.availableId = 0;
    this.selectedEvent1 = "A";
    this.RemoteAndFacility = "FacilityOnly";
    this.selectedEvent = "WOT";
    this.SetEvents1();
    if (this.availableId <= 0) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.selectedFacilityRemote = this.facilitiesRemote.find(x => x.crmFacilityId == this.defaultFacility);
        this.getTime();
      }
    }
  }

  onClear() {

    this.selecteRec = false;
    this.selectedEvent = null;
    this.selectedEvent1 = null;
    this.SetEvents();
    this.SetEvents1();
    this.selectedFacility = null;
    this.selectedFacilityTimeZone = "";
    this.breakEndTime = null;
    this.breakEndTime = null;
    this.breakStartTime = null;
    this.description = "";
    this.selectedOccurrence = null;
    this.customEndDate = null;
    this.customEndTime = "08:00 PM";
    this.customStartDate = null;
    this.customStartTime = "08:00 AM";
    this.selectedEventSeries = null;
    this.selectedValue = "";
    this.weekDays.forEach((value) => {
      value.isSelected = false;
    });
    if (this.eventSeries) {
      this.eventSeries.forEach((value) => {
        value.isSelected = false;
      });
    }
    this.eventText = "";
    this.isFullDay = false;
    this.isEditEvent = false;
  }

  SetDays() {

    if (this.customStartDate && this.customEndDate) {
      var startDate = this.customStartDate.getDate();
      var endDate = this.customEndDate.getDate();

      if (this.weekDays) {
        this.weekDays.forEach((value) => {
          value.isSelected = false;
        });
      }

      var dayStart = 1;
      for (var i = startDate; i <= endDate; i++) {
        var date = new Date(this.customStartDate.getFullYear(), this.customStartDate.getMonth(), i);
        if (date.getDay() == 0) {
          dayStart = Math.pow(2, 6);
        }
        else {
          var power = date.getDay() - 1;
          dayStart = Math.pow(2, power);
        }

        if (this.weekDays) {
          this.weekDays.forEach((value) => {
            if (value.value == dayStart) {
              value.isSelected = true;
            }
          });
        }

      }

    }
  }

  closeOverlay() {

  }
  private async getHolidayList(isRefersh: boolean) {
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.therapistService.GetHoliday(selectedDate, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.progress.next(false); this.cdr.detectChanges(); }))
      .toPromise()
      .then((result) => {
        this.processResult<HolidayResponse[]>(result, () => {
          const holidayData = result.responseModel;
          var obj: Array<any> = [];
          for (var i = 0; i < holidayData.length; i++) {
            var event: Object = {
              start: holidayData[i].appHolidayStartDateTime,
              end: holidayData[i].appHolidayEndDateTime,
            }
            obj.push(event);
          }

          this.holidays = obj;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  validateIsHolidayDate() {
    let result = true;
    const startDate = moment(this.customStartDate).format("yyyy-MM-DD");
    const enddate = moment(this.customEndDate).format("yyyy-MM-DD");
    const startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    const endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm")
    this.holidays.forEach(function (value) {
      if (value.start.split("T")[0] >= startDate || value.end.split("T")[0] <= enddate) {
        if (startTime >= value.start.split("T")[1] || endTime <= value.end.split("T")[1]) {
          result = false;
        }
      }
    });
    return result;
  }

  EnableForAvaliablity(avEvents) {
    if (avEvents.filter(x => x.isGlobalHoliday == true).length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  filterDates = (date: Date): boolean => {
    let today = new Date();
    return date < today;
  }
}
