import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.sass'],
  providers: [DialogService]
})
export class AppointmentComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsResponseModel: Array<SettingsResponseModel>;
  remoteAppointmentTypeResponseModel: Array<RemoteAppointmentTypeModel>;
  settingsRequestModel: Array<SettingsRequestModel>;
  loggedInUserId: number;
  telemedEditStatus: boolean = false;
  telemedSelected: boolean = false;
  OnlineMeetingEditStatus: boolean = false;
  OnlineMeetingSelected: boolean = false;
  defaultwipneededdate: string;
  SelfGuidedEditStatus: boolean = false;
  SelfGuidedSelected: boolean = false;
  hideUpdatePODStatusToBrightTree: boolean = false;

  TelemedTitle: string = 'Telemed';
  OnlineMeetingTitle: string = 'Online Meeting';
  SelfGuidedTitle: string = 'Self-guided';

  OrigionalTelemedTitle: string = 'Telemed';
  OrigionalOnlineMeetingTitle: string = 'Online Meeting';
  OrigionalSelfGuidedTitle: string = 'Self-guided';

  formFields = {
    expiryPeriodOfAppointmentLink: 'expiryPeriodOfAppointmentLink',
    allowTherapistToUpdateAppointmentStatus: 'allowTherapistToUpdateAppointmentStatus',
    allowTherapistToUpdateLicense: 'allowTherapistToUpdateLicense',
    telemedTitle: 'telemedTitle',
    onlineMeetingTitle: 'onlineMeetingTitle',
    selfGuidedTitle: 'selfGuidedTitle',
    telemedSelected: 'telemedSelected',
    onlineMeetingSelected: 'onlineMeetingSelected',
    selfGuidedSelected: 'selfGuidedSelected',
    allowUpdateAppointmentToBrightTree: 'allowUpdateAppointmentToBrightTree',
    patientsdefaultappointmentlocation: 'patientsdefaultappointmentlocation',
    defaultWIPNeededdate: 'defaultWIPNeededdate',
    pause: 'pause',
    allowBlackoutForAppointment: 'allowBlackoutForAppointment',
    autoReconcileOfAppointment: 'autoReconcileOfAppointment',
    defaultModeofAppointment: 'defaultModeofAppointment',
    sameOnlineLinkForMultipleRT: 'sameOnlineLinkForMultipleRT',
    allowUpdatePODStatusToBrightTree: 'allowUpdatePODStatusToBrightTree',
    allowAppendOrderNoteToBrightTree: 'allowAppendOrderNoteToBrightTree',
    allowDMEtoUpdateDeliveryNoteToBrightTree:'allowDMEtoUpdateDeliveryNoteToBrightTree',
    allowDMEtoUpdateOrderNoteToBrightTree:'allowDMEtoUpdateOrderNoteToBrightTree',
    allowDMEtoUpdateDeliveryAddressToBrightTree:'allowDMEtoUpdateDeliveryAddressToBrightTree',
    allowDMEtoUpdateWipStatusRescheduled:'allowDMEtoUpdateWipStatusRescheduled',
    checkTherapistStateLicense:'checkTherapistStateLicense'
  };

  validationMessages = {
    expiryPeriodOfAppointmentLink: {
      required: 'Expiry period of appointment link is required',
      validNumber: 'Only positive numbers are allowed'
    },
    pause: {
      required: 'Pause duration for auto calls is required',
      validNumber: 'Only positive numbers are allowed'
    },
    allowTherapistToUpdateAppointmentStatus: {
      required: 'Allow therapist to update appointment status is required',
      //validCharacters: 'Enter valid contact name'
    },
    allowTherapistToUpdateLicense: {
      required: 'Allow therapist to update license and certificate details is required',
    },
    selfGuidedTitle: {
      required: 'Self Guided Title is required',
    },
    telemedTitle: {
      required: 'Telemed Title is required',

    },
    onlineMeetingTitle: {
      required: 'Online Meeting Title is required',
    },
    allowUpdateAppointmentToBrightTree: {
      required: 'Allow Update into the Brightree system on the Appointment confirmation is required',
    },
    patientsdefaultappointmentlocation: {
      required: 'Patients Default Appointment Location is required',
    },
    defaultWIPNeededdate: {
      required: 'Default WIP Date Needed field is required',
    },
    allowBlackoutForAppointment: {
      required: 'Allow Black out Days on the Appointment confirmation is required',
    },
    defaultModeofAppointment: {
      required: 'Default Mode of Appointment is required',
    },
    allowUpdatePODStatusToBrightTree: {
      required: 'Allow Update into POD Status the Brightree system on the Appointment confirmation is required',
    },
    allowAppendOrderNoteToBrightTree: {
      required: 'Allow Append Order Note into the Brightree system on the Appointment confirmation is required',
    },
    allowDMEtoUpdateDeliveryNoteToBrightTree:
    {
      required:'Allow DME To Update Delivery Note into the Brightree System is required'
    },
    allowDMEtoUpdateOrderNoteToBrightTree:{
      required:'Allow DME to Update Order Note into the Brightree System is required'
    },
    allowDMEtoUpdateDeliveryAddressToBrightTree:{
      required:'Allow DME to Update Delivery Note into the Brightree System is required'
    },
    allowDMEtoUpdateWipStatusRescheduled:{
      required:'Allow DME to Update Wip Status on Rescheduled is required'
    },
    checkTherapistStateLicense:
    {
      required:'Check Therapist State License is required'
    }
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getApplicationSettings();
  }

  private getApplicationSettings() {
    this.loading = true;

    this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, async () => {
          this.settingsResponseModel = result.responseModel;
          this.setValuesToForm();
          this.getRemoteAppointmentType();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getRemoteAppointmentType() {
    this.loading = true;

    this.settingsService.getRemoteAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.remoteAppointmentTypeResponseModel = result.responseModel;
          const form = this.form;

          if (this.remoteAppointmentTypeResponseModel.length > 0) {

            let telemed = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'Telemed')
            if (telemed) {
              this.OrigionalTelemedTitle = telemed.crmRemoteAppointmentTitle;
              this.TelemedTitle = telemed.crmRemoteAppointmentTitle;
              this.telemedSelected = telemed.isActive;
              form.get(this.formFields.telemedSelected).setValue(telemed.isActive);
              form.get(this.formFields.telemedTitle).setValue(telemed.crmRemoteAppointmentTitle);
            }

            let onlineMeeting = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'OnlineMeeting')
            if (onlineMeeting) {
              this.OrigionalOnlineMeetingTitle = onlineMeeting.crmRemoteAppointmentTitle;
              this.OnlineMeetingTitle = onlineMeeting.crmRemoteAppointmentTitle;
              this.OnlineMeetingSelected = onlineMeeting.isActive;
              form.get(this.formFields.onlineMeetingSelected).setValue(onlineMeeting.isActive);
              form.get(this.formFields.onlineMeetingTitle).setValue(onlineMeeting.crmRemoteAppointmentTitle);
            }

            let SelfGuided = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'SelfGuided')
            if (SelfGuided) {
              this.OrigionalSelfGuidedTitle = SelfGuided.crmRemoteAppointmentTitle;
              this.SelfGuidedTitle = SelfGuided.crmRemoteAppointmentTitle;
              this.SelfGuidedSelected = SelfGuided.isActive;
              form.get(this.formFields.selfGuidedSelected).setValue(SelfGuided.isActive);
              form.get(this.formFields.selfGuidedTitle).setValue(SelfGuided.crmRemoteAppointmentTitle);
            }



          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  EditTelemedTitle() {
    this.telemedEditStatus = !this.telemedEditStatus;
  }

  EditOnlineMeetingTitle() {
    this.OnlineMeetingEditStatus = !this.OnlineMeetingEditStatus;
  }

  EditSelfGuidedTitle() {
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
  }

  saveTelemedTitle() {
    this.telemedEditStatus = !this.telemedEditStatus;
    //if (!this.TelemedTitle) {
    //  this.showWarningMessage('Please add email address');
    //  return;
    //}

    //this.dialougeService.open(ConfirmDialogComponent, {
    //  data:
    //  {
    //    message: 'Are you sure you want to update Telemed Title?',
    //  },
    //  header: 'Confirmation'
    //}).onClose.subscribe((response: boolean) => {
    //  if (!response) {
    //    return;
    //  }

    //  let remoteupdateModel = new RemoteAppointmentTypeModel();
    //  remoteupdateModel.crmRemoteAppointmentTitle = this.TelemedTitle;
    //  remoteupdateModel.crmRemoteAppointmentType = 'Telemed';
    //  remoteupdateModel.isActive = this.telemedSelected;
    //  this.loading = true;
    //  this.settingsService.updatRemoteAppointmentType(remoteupdateModel)
    //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //    .subscribe((result) => {
    //      if (result.responseModel) {
    //        this.getRemoteAppointmentType();
    //        this.showSuccessMessage('Telemed Title updated successfully');
    //        this.telemedEditStatus = !this.telemedEditStatus;

    //      }
    //      else {
    //        if (result.message)
    //          this.showErrorMessage(result.message);
    //        else
    //          this.showErrorMessage("There was problem updating Telemed Title. Please contact administrator");
    //        return;
    //      }
    //    });
    //});
  }

  saveOnlineMeetingTitle() {
    this.OnlineMeetingEditStatus = !this.OnlineMeetingEditStatus;
    //if (!this.OnlineMeetingTitle) {
    //  this.showWarningMessage('Please add email address');
    //  return;
    //}

    //this.dialougeService.open(ConfirmDialogComponent, {
    //  data:
    //  {
    //    message: 'Are you sure you want to update Online Meeting Title?',
    //  },
    //  header: 'Confirmation'
    //}).onClose.subscribe((response: boolean) => {
    //  if (!response) {
    //    return;
    //  }

    //  let remoteupdateModel = new RemoteAppointmentTypeModel();
    //  remoteupdateModel.crmRemoteAppointmentTitle = this.OnlineMeetingTitle;
    //  remoteupdateModel.crmRemoteAppointmentType = 'OnlineMeeting';
    //  remoteupdateModel.isActive = this.telemedSelected;


    //  this.loading = true;
    //  this.settingsService.updatRemoteAppointmentType(remoteupdateModel)
    //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //    .subscribe((result) => {
    //      if (result.responseModel) {
    //        this.getRemoteAppointmentType();
    //        this.showSuccessMessage('Online Meeting Title updated successfully');
    //        this.OnlineMeetingEditStatus = !this.OnlineMeetingEditStatus;

    //      }
    //      else {
    //        if (result.message)
    //          this.showErrorMessage(result.message);
    //        else
    //          this.showErrorMessage("There was problem updating Online Meeting Title. Please contact administrator");
    //        return;
    //      }
    //    });
    //});
  }

  saveSelfGuidedTitle() {
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
    //if (!this.SelfGuidedTitle) {
    //  this.showWarningMessage('Please add email address');
    //  return;
    //}

    //this.dialougeService.open(ConfirmDialogComponent, {
    //  data:
    //  {
    //    message: 'Are you sure you want to update Telemed Title?',
    //  },
    //  header: 'Confirmation'
    //}).onClose.subscribe((response: boolean) => {
    //  if (!response) {
    //    return;
    //  }

    //  let remoteupdateModel = new RemoteAppointmentTypeModel();
    //  remoteupdateModel.crmRemoteAppointmentTitle = this.SelfGuidedTitle;
    //  remoteupdateModel.crmRemoteAppointmentType = 'SelfGuided';
    //  remoteupdateModel.isActive = this.telemedSelected;


    //  this.loading = true;
    //  this.settingsService.updatRemoteAppointmentType(remoteupdateModel)
    //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //    .subscribe((result) => {
    //      if (result.responseModel) {
    //        this.getRemoteAppointmentType();
    //        this.showSuccessMessage('Self Guided Title updated successfully');
    //        this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
    //      }
    //      else {
    //        if (result.message)
    //          this.showErrorMessage(result.message);
    //        else
    //          this.showErrorMessage("There was problem updating Self Guided Title. Please contact administrator");
    //        return;
    //      }
    //    });
    //});
  }

  cancelTelemedTitle() {
    const form = this.form;
    form.get(this.formFields.telemedTitle).setValue(this.OrigionalTelemedTitle);
    this.TelemedTitle = this.OrigionalTelemedTitle;
    this.telemedEditStatus = !this.telemedEditStatus;
  }

  cancelOnlineMeetingTitle() {
    const form = this.form;
    form.get(this.formFields.onlineMeetingTitle).setValue(this.OrigionalOnlineMeetingTitle);
    this.OnlineMeetingTitle = this.OrigionalOnlineMeetingTitle;
    this.OnlineMeetingEditStatus = !this.OnlineMeetingEditStatus;
  }

  cancelSelfGuidedTitle() {
    const form = this.form;
    form.get(this.formFields.selfGuidedTitle).setValue(this.OrigionalSelfGuidedTitle);
    this.SelfGuidedTitle = this.OrigionalSelfGuidedTitle;
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    //56933
    if (!this.telemedSelected && !this.OnlineMeetingSelected && !this.SelfGuidedSelected) {
      this.showWarningMessage('Atleast one Remote Appointment type should be enabled');
      return;
    }

    var applicationGeneralRequestModel = this.getValuesFromForm();
    let modeofAppointment = applicationGeneralRequestModel.find(x => x.schSettingKey == Constants.defaultModeofAppointment).schSettingValue;
    this.webStorageService.removedefaultModeofAppointment();
    this.webStorageService.setdefaultModeofAppointment(modeofAppointment);
    this.telemedEditStatus = false;
    this.OnlineMeetingEditStatus = false;
    this.SelfGuidedEditStatus = false;
    const manageApplicationGeneral = this.settingsService.updateSettings(applicationGeneralRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.saveRemoteAppointmentTypeInfo();
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  saveRemoteAppointmentTypeInfo() {

    var RemoteApptTypeModel = this.getValuesFromRemoteApptTypeForm();    

    const manageApplicationGeneral = this.settingsService.updatRemoteAppointmentTypes(RemoteApptTypeModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.getRemoteAppointmentType();
          this.showSuccessMessage('Appointment settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): Array<SettingsRequestModel> {
    
    const form = this.form;
    let value = this.defaultwipneededdate;
    var lstSettingsResponseModel = new Array<SettingsResponseModel>();
    var allowTherapistToUpdateAppointmentStatusRequestModel = new SettingsResponseModel();
    allowTherapistToUpdateAppointmentStatusRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.allowTherapistToUpdateAppointmentStatus);
    allowTherapistToUpdateAppointmentStatusRequestModel.schSettingValue = form.get(this.formFields.allowTherapistToUpdateAppointmentStatus).value;
    allowTherapistToUpdateAppointmentStatusRequestModel.dateTimeLastModified = new Date();
    allowTherapistToUpdateAppointmentStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowTherapistToUpdateAppointmentStatusRequestModel);

    var allowTherapistToUpdateLicence = new SettingsResponseModel();
    allowTherapistToUpdateLicence = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowTherapistToUpdateLicenseInformation);
    allowTherapistToUpdateLicence.schSettingValue = form.get(this.formFields.allowTherapistToUpdateLicense).value;
    allowTherapistToUpdateLicence.dateTimeLastModified = new Date();
    allowTherapistToUpdateLicence.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowTherapistToUpdateLicence);

    var allowUpdateAppointmentToBrightTree = new SettingsResponseModel();
    allowUpdateAppointmentToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowUpdateAppointmentToBrightTreeSystem);
    allowUpdateAppointmentToBrightTree.schSettingValue = form.get(this.formFields.allowUpdateAppointmentToBrightTree).value;
    allowUpdateAppointmentToBrightTree.dateTimeLastModified = new Date();
    allowUpdateAppointmentToBrightTree.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowUpdateAppointmentToBrightTree);

    var patientsdefaultappointmentlocation = new SettingsResponseModel();
    patientsdefaultappointmentlocation = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientsDefaultAppointmentLocation);
    patientsdefaultappointmentlocation.schSettingValue = form.get(this.formFields.patientsdefaultappointmentlocation).value;
    patientsdefaultappointmentlocation.dateTimeLastModified = new Date();
    patientsdefaultappointmentlocation.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(patientsdefaultappointmentlocation);

    var defaultwipneededdateModel = new SettingsResponseModel();
    defaultwipneededdateModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DefaultWIPNeededDate);
    defaultwipneededdateModel.schSettingValue = form.get(this.formFields.defaultWIPNeededdate).value;
    defaultwipneededdateModel.dateTimeLastModified = new Date();
    defaultwipneededdateModel.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(defaultwipneededdateModel);

    var expiryPeriodOfAppointmentLinkRequestModel = new SettingsResponseModel();
    expiryPeriodOfAppointmentLinkRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.expiryPeriodOfAppointmentLink);
    expiryPeriodOfAppointmentLinkRequestModel.schSettingValue = form.get(this.formFields.expiryPeriodOfAppointmentLink).value;
    expiryPeriodOfAppointmentLinkRequestModel.dateTimeLastModified = new Date();
    expiryPeriodOfAppointmentLinkRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(expiryPeriodOfAppointmentLinkRequestModel);

    var pauseModel = new SettingsResponseModel();
    pauseModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.pause);
    pauseModel.schSettingValue = form.get(this.formFields.pause).value;
    pauseModel.dateTimeLastModified = new Date();
    pauseModel.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(pauseModel);

    var allowBlackoutForAppointment = new SettingsResponseModel();
    allowBlackoutForAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowBlackOutDaysForRescheduledAppointment);
    allowBlackoutForAppointment.schSettingValue = form.get(this.formFields.allowBlackoutForAppointment).value;
    allowBlackoutForAppointment.dateTimeLastModified = new Date();
    allowBlackoutForAppointment.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowBlackoutForAppointment);

    var autoReconcileOfAppointment = new SettingsResponseModel();
    autoReconcileOfAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EnableTheAutoReconcileOfAppointment);
    autoReconcileOfAppointment.schSettingValue = form.get(this.formFields.autoReconcileOfAppointment).value;
    autoReconcileOfAppointment.dateTimeLastModified = new Date();
    autoReconcileOfAppointment.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(autoReconcileOfAppointment);

    var defaultModeofAppointment = new SettingsResponseModel();
    defaultModeofAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DefaultModeofAppointment);
    defaultModeofAppointment.schSettingValue = form.get(this.formFields.defaultModeofAppointment).value;
    defaultModeofAppointment.dateTimeLastModified = new Date();
    defaultModeofAppointment.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(defaultModeofAppointment);

    var onlineLinkForMultipleRT = new SettingsResponseModel();
    onlineLinkForMultipleRT = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.SameOnlineLinkForMultipleRT);
    onlineLinkForMultipleRT.schSettingValue = form.get(this.formFields.sameOnlineLinkForMultipleRT).value;
    onlineLinkForMultipleRT.dateTimeLastModified = new Date();
    onlineLinkForMultipleRT.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(onlineLinkForMultipleRT);

    var allowUpdatePODStatusAppointmentToBrightTree = new SettingsResponseModel();
    allowUpdatePODStatusAppointmentToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowUpdatePODStatusToBrightTreeSystem);
    allowUpdatePODStatusAppointmentToBrightTree.schSettingValue = form.get(this.formFields.allowUpdatePODStatusToBrightTree).value;
    allowUpdatePODStatusAppointmentToBrightTree.dateTimeLastModified = new Date();
    allowUpdatePODStatusAppointmentToBrightTree.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowUpdatePODStatusAppointmentToBrightTree);

    var allowAppendOrderNoteToBrightTree = new SettingsResponseModel();
    allowAppendOrderNoteToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowAppendOrderNoteToBrightTreeSystem);
    allowAppendOrderNoteToBrightTree.schSettingValue = form.get(this.formFields.allowAppendOrderNoteToBrightTree).value;
    allowAppendOrderNoteToBrightTree.dateTimeLastModified = new Date();
    allowAppendOrderNoteToBrightTree.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(allowAppendOrderNoteToBrightTree);

    var AllowDMEtoUpdateDeliveryNote = new SettingsResponseModel();
    AllowDMEtoUpdateDeliveryNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateDeliveryNote);
    AllowDMEtoUpdateDeliveryNote.schSettingValue = form.get(this.formFields.allowDMEtoUpdateDeliveryNoteToBrightTree).value;
    AllowDMEtoUpdateDeliveryNote.dateTimeLastModified = new Date();
    AllowDMEtoUpdateDeliveryNote.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(AllowDMEtoUpdateDeliveryNote);

    var AllowDMEtoUpdateOrderNote = new SettingsResponseModel();
    AllowDMEtoUpdateOrderNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateOrderNote);
    AllowDMEtoUpdateOrderNote.schSettingValue = form.get(this.formFields.allowDMEtoUpdateDeliveryNoteToBrightTree).value;
    AllowDMEtoUpdateOrderNote.dateTimeLastModified = new Date();
    AllowDMEtoUpdateOrderNote.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(AllowDMEtoUpdateOrderNote);

    var AllowDMEtoUpdateDeliveryAddress = new SettingsResponseModel();
    AllowDMEtoUpdateDeliveryAddress = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateOrderNote);
    AllowDMEtoUpdateDeliveryAddress.schSettingValue = form.get(this.formFields.allowDMEtoUpdateDeliveryAddressToBrightTree).value;
    AllowDMEtoUpdateDeliveryAddress.dateTimeLastModified = new Date();
    AllowDMEtoUpdateDeliveryAddress.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(AllowDMEtoUpdateDeliveryAddress);

    var AllowDMEtoUpdateWipStatus = new SettingsResponseModel();
    AllowDMEtoUpdateWipStatus = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateOrderNote);
    AllowDMEtoUpdateWipStatus.schSettingValue = form.get(this.formFields.allowDMEtoUpdateWipStatusRescheduled).value;
    AllowDMEtoUpdateWipStatus.dateTimeLastModified = new Date();
    AllowDMEtoUpdateWipStatus.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(AllowDMEtoUpdateWipStatus);

    var CheckTherapistStateLicense = new SettingsResponseModel();
    CheckTherapistStateLicense = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.CheckTherapistStateLicense);
    CheckTherapistStateLicense.schSettingValue = form.get(this.formFields.checkTherapistStateLicense).value;
    CheckTherapistStateLicense.dateTimeLastModified = new Date();
    CheckTherapistStateLicense.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(CheckTherapistStateLicense);

    return lstSettingsResponseModel;
  }

  private getValuesFromRemoteApptTypeForm(): Array<RemoteAppointmentTypeModel> {
    const form = this.form;
    var lstRemoteAppointmentTypeModel = new Array<RemoteAppointmentTypeModel>();
    var telemedRemoteAppointmentTypeModel = new RemoteAppointmentTypeModel();
    telemedRemoteAppointmentTypeModel.isActive = this.telemedSelected;
    telemedRemoteAppointmentTypeModel.crmRemoteAppointmentType = 'Telemed';
    telemedRemoteAppointmentTypeModel.crmRemoteAppointmentTitle = form.get(this.formFields.telemedTitle).value;
    telemedRemoteAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(telemedRemoteAppointmentTypeModel);

    var onlineMeetinfRemoteAppointmentTypeModel = new RemoteAppointmentTypeModel();
    onlineMeetinfRemoteAppointmentTypeModel.isActive = this.OnlineMeetingSelected;
    onlineMeetinfRemoteAppointmentTypeModel.crmRemoteAppointmentType = 'OnlineMeeting';
    onlineMeetinfRemoteAppointmentTypeModel.crmRemoteAppointmentTitle = form.get(this.formFields.onlineMeetingTitle).value;
    onlineMeetinfRemoteAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(onlineMeetinfRemoteAppointmentTypeModel);

    var selfGuidedfRemoteAppointmentTypeModel = new RemoteAppointmentTypeModel();
    selfGuidedfRemoteAppointmentTypeModel.isActive = this.SelfGuidedSelected;
    selfGuidedfRemoteAppointmentTypeModel.crmRemoteAppointmentType = 'SelfGuided';
    selfGuidedfRemoteAppointmentTypeModel.crmRemoteAppointmentTitle = form.get(this.formFields.selfGuidedTitle).value;
    selfGuidedfRemoteAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(selfGuidedfRemoteAppointmentTypeModel);   
    
    return lstRemoteAppointmentTypeModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsResponseModel) {
      return;
    }
    var allowTherapistToUpdateAppointmentStatus = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.allowTherapistToUpdateAppointmentStatus);
    var allowTherapistToUpdateLicense = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowTherapistToUpdateLicenseInformation);
    var expiryPeriodOfAppointmentLink = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.expiryPeriodOfAppointmentLink);
    var pauseModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.pause);
    var allowUpdateAppointmentToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowUpdateAppointmentToBrightTreeSystem);
    var patientsdefaultappointmentlocation = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientsDefaultAppointmentLocation);
    var defaultwipneededdate = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DefaultWIPNeededDate);
    var allowBlackoutForAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowBlackOutDaysForRescheduledAppointment);
    var autoReconcileOfAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EnableTheAutoReconcileOfAppointment);
    var defaultModeofAppointment = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DefaultModeofAppointment);
    var onlineLinkForMultipleRT = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.SameOnlineLinkForMultipleRT);
    var allowUpdatePODStatusToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowUpdatePODStatusToBrightTreeSystem);
    var allowAppendOrderNoteToBrightTree = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowAppendOrderNoteToBrightTreeSystem);
    var allowDMEtoUpdateOrderNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateOrderNote);
    var allowDMEtoUpdateDeliveryNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateDeliveryNote);
    var allowDMEtoUpdateDeliveryAddress = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.AllowDMEtoUpdateDeliveryAddress);
    var allowDMEtoUpdateWipStatusonReschedule = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.UpdateWipStateOnRescheduled);
    var checkTherapistStateLicensee = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.CheckTherapistStateLicense);

    const form = this.form;
    form.get(this.formFields.allowTherapistToUpdateAppointmentStatus).setValue(allowTherapistToUpdateAppointmentStatus?.schSettingValue);
    form.get(this.formFields.expiryPeriodOfAppointmentLink).setValue(expiryPeriodOfAppointmentLink?.schSettingValue);
    form.get(this.formFields.pause).setValue(pauseModel?.schSettingValue);
    form.get(this.formFields.allowTherapistToUpdateLicense).setValue(allowTherapistToUpdateLicense?.schSettingValue);
    form.get(this.formFields.allowUpdateAppointmentToBrightTree).setValue(allowUpdateAppointmentToBrightTree?.schSettingValue);
    form.get(this.formFields.patientsdefaultappointmentlocation).setValue(patientsdefaultappointmentlocation?.schSettingValue);
    form.get(this.formFields.defaultWIPNeededdate).setValue(defaultwipneededdate?.schSettingValue);
    form.get(this.formFields.allowBlackoutForAppointment).setValue(allowBlackoutForAppointment?.schSettingValue);
    form.get(this.formFields.autoReconcileOfAppointment).setValue(autoReconcileOfAppointment?.schSettingValue);
    form.get(this.formFields.defaultModeofAppointment).setValue(defaultModeofAppointment?.schSettingValue);
    form.get(this.formFields.sameOnlineLinkForMultipleRT).setValue(onlineLinkForMultipleRT?.schSettingValue);
    form.get(this.formFields.allowUpdatePODStatusToBrightTree).setValue(allowUpdatePODStatusToBrightTree?.schSettingValue);
    form.get(this.formFields.allowAppendOrderNoteToBrightTree).setValue(allowAppendOrderNoteToBrightTree?.schSettingValue);
    form.get(this.formFields.allowDMEtoUpdateOrderNoteToBrightTree).setValue(allowDMEtoUpdateOrderNote?.schSettingValue);
    form.get(this.formFields.allowDMEtoUpdateDeliveryNoteToBrightTree).setValue(allowDMEtoUpdateDeliveryNote?.schSettingValue);
    form.get(this.formFields.allowDMEtoUpdateDeliveryAddressToBrightTree).setValue(allowDMEtoUpdateDeliveryAddress?.schSettingValue);
    form.get(this.formFields.allowDMEtoUpdateWipStatusRescheduled).setValue(allowDMEtoUpdateWipStatusonReschedule?.schSettingValue);
    form.get(this.formFields.checkTherapistStateLicense).setValue(checkTherapistStateLicensee?.schSettingValue);
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.expiryPeriodOfAppointmentLink, this.formBuilder.control('', { validators: [CustomValidator.positiveDecimalNumber, Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.pause, this.formBuilder.control('', { validators: [CustomValidator.positiveDecimalNumber, Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.allowTherapistToUpdateAppointmentStatus, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowTherapistToUpdateLicense, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));

    formGroup.addControl(this.formFields.telemedTitle, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.onlineMeetingTitle, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.selfGuidedTitle, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.telemedSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.onlineMeetingSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.selfGuidedSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowUpdateAppointmentToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.patientsdefaultappointmentlocation, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.defaultWIPNeededdate, this.formBuilder.control('', { validators: [Validators.required] }));
    formGroup.addControl(this.formFields.allowBlackoutForAppointment, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.autoReconcileOfAppointment, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.defaultModeofAppointment, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.sameOnlineLinkForMultipleRT, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.allowUpdatePODStatusToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowAppendOrderNoteToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowDMEtoUpdateOrderNoteToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowDMEtoUpdateDeliveryNoteToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowDMEtoUpdateDeliveryAddressToBrightTree, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.allowDMEtoUpdateWipStatusRescheduled, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.checkTherapistStateLicense, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));
    this.form = formGroup;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
