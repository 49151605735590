export class AppointmentCallRecordingUploadHistoryRequestModel {
    SchAppointmentCallRecordingUploadId: number;
    SchCallRecordingUploadBy: number;
    SchCallRecordingUploadDateTime: Date;
    SchCallRecordingUploadFromDate: Date;
    SchCallRecordingUploadToDate: Date;
    SchCallRecordingUploadCount: number;
    SchCallRecordingUploadData: string;
    SchCallRecordingFailedUploadCount: number;
    SchCallRecordingFailedUploadData: string;
    SchCallRecordingUploadFtp: string;
    SchCallRecordingUploadType: number;
  }