import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { UserService } from '../servicies/user.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Router, ActivatedRoute } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { tr } from 'date-fns/locale';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.sass'],  
  providers: [DialogService]
})
export class UsersComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  title = 'Users';
  private hasPageHistory: boolean;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  users: UserResponseModel[];
  userStatus: { label: string, status: boolean }[];
  selectedStatus: { label: string, status: boolean };
  selectedName: string;
  searchRequestModel: SearchRequestModel;
  totalRecords: number;
  totalPages: number;
  selectedUser: UserResponseModel;
  noRecordsFoundMessage: string = "No records founds";
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  //global search
  searchText: string;
  isGlobalSearch: boolean = false;
  searchFor: string;
  orderObj: any;
  appDateFormat: DateFormatModel;
  constructor(private userService: UserService,
    private router: Router,
    private globalSearchService: GlobalSearchService,
    private activatedRoute: ActivatedRoute,
    messageService: MessageService,
    private dialougeService: DialogService,
    private webStorageService: WebStorageService) {
    super(messageService);
    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      if (currentNavigationState.searchRequestModel) {
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] != null) {
          // tslint:disable-next-line: max-line-length
          this.selectedStatus = { label: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) === true ? 'Active' : 'Inactive', status: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) };
        } else {
          // this.selectedStatus = { label : 'All' , status : true };
          this.selectedStatus = { label: 'Active', status: true };
        }
        // if (this.searchRequestModel.dictionaryFilter['SearchText'] != null) {
        //   this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'];
        // }
        this.hasPageHistory = true;
      }
    }
    this.appDateFormat = this.webStorageService.getDateFormat();
  }
  ngAfterViewInit() {
    // this.setFilters();
    this.hasPageHistory = false;
  }
  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
  }

  async ngOnInit() {

    this.initializeSearchRequestModel();
    this.userStatus = [
      { label: 'All', status: true },
      { label: 'Active', status: true },
      { label: 'Inactive', status: false },
    ];
    await this.getBranches();
    // 
    // if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
    //   this.selectedStatus = { label : "Active" , status: true}
    // }
    if (this.searchRequestModel) {
      if (this.searchRequestModel.crmBranchId != null) {
        this.selectedbranch = this.branchResponseModel.find(x => x.crmBranchId === this.searchRequestModel.crmBranchId);
      }
      if (this.searchRequestModel.dictionaryFilter['SearchText'] !== null) {
        // tslint:disable-next-line: max-line-length
        this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'] !== undefined ? this.searchRequestModel.dictionaryFilter['SearchText'] : '';
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'true') {
        this.selectedStatus = { label: 'Active', status: true };
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'false') {
        this.selectedStatus = { label: 'Inactive', status: false };
      }

      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == '') {
        this.selectedStatus = { label: 'All', status: true };
      }
    }
  }


  private async getBranches() {
    this.progress.next(true);
    await this.userService.getAllBranches()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.branchResponseModel = result.responseModel;
        //let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
        //if (selectedBranch)
        //  this.selectedbranch = selectedBranch;
        //else
        this.branchResponseModel = new Array();
        let branch = new BranchResponseModel();
        branch.crmBranchId = 0;
        branch.crmBranchName = "All Branches";
        this.branchResponseModel.push(branch);
        result.responseModel.forEach(value => {
          this.branchResponseModel.push(value);
        });
        this.selectedbranch = this.branchResponseModel[0];
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  onBranchChange(event) {
    if (event.value) {
      this.searchRequestModel.crmBranchId = event.value.crmBranchId;
      // this.resetPageIndex();
      // this.getAllUsers(true);
    }
  }
  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  onView(userId: string) {
    this.router.navigate(['/admin/users/detail/', userId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getAllUsers(true);
  }

  onSearchButtonClick() {
    // this.searchRequestModel.dictionaryFilter = {};
    //this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // let selSatatus = this.selectedStatus;
    // if (selSatatus)
    //   this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.wipStatesName;

    // this.resetPageIndex();
    // this.getAllUsers(true);
  }

  onStatusChange(event) {
    // this.searchRequestModel.dictionaryFilter = {};
    // if (this.searchBox.nativeElement.value)
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    if (event.value != null) {
      if (event.value.label !== "All")
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status.toString();
     else
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] ='';
    }
  
    // this.resetPageIndex();
    // this.getAllUsers(true);
  }

  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getAllUsers(false);
  }

  onEdit(userId: string) {
    this.router.navigate(['/admin/users/edit/', userId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onDelete(userId: string) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.userService.deleteUser(userId.toString(), false)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('User deleted successfully');
            this.getAllUsers(true);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });

  }

  selectuser(event, user: UserResponseModel, overlaypanel: OverlayPanel) {
    this.selectedUser = user;
    overlaypanel.toggle(event);
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {
    if (this.hasPageHistory) {
      return;
    }
    this.searchRequestModel = new SearchRequestModel();
    if (this.isGlobalSearch) {
      this.searchRequestModel.dictionaryFilter = {}

      if (this.searchFor === "C") {
        this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
        this.searchRequestModel.dictionaryFilter['SearchRole'] = "CS Operator";
      }
      else if (this.searchFor === "D") {
        this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
        this.searchRequestModel.dictionaryFilter['SearchRole'] = "DME Admin";
      }
    }


    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "AppUserFname";
    if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = 'true';
      this.selectedStatus = { label: 'Active', status: true };
    }
  }

  private getAllUsers(isRefresh: boolean) {
    this.loading = true;
    this.searchRequestModel.dictionaryFilter['SearchRoleUserPage'] = "Therapist";
    this.userService.GetAllUsers(this.searchRequestModel, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<UserResponseModel>>) => {
        this.processResult<PagedResponseModel<UserResponseModel>>(result, () => {
          const pagedResponse = result.responseModel;
          if (pagedResponse) {
            this.users = pagedResponse.results;
            this.users.forEach(value =>
              value.crmBranchName = value.crmBranchName.replace('Sandwich', '').trim()
            )
            this.totalRecords = pagedResponse.totalRecords;
            this.totalPages = pagedResponse.totalPages;

            // Set page size to 'totalRecords' when page size is 'All'
            if (!this.searchRequestModel.PageSize) {
              this.searchRequestModel.PageSize = pagedResponse.totalRecords;
            }
            this.loading = false;
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onFilterSearch() {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else
      this.searchRequestModel.dictionaryFilter['SearchText'] = '';
    this.resetPageIndex();
    this.getAllUsers(true);
  }

  onClearFilterSearch(){
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value ='';
    this.searchRequestModel.dictionaryFilter['ActiveStatus'] ='';
    this.selectedbranch = this.branchResponseModel[0];
    this.searchRequestModel.crmBranchId = Number(0);
    this.selectedStatus = this.userStatus[0];
    this.resetPageIndex();
    this.getAllUsers(true);
  }
}
