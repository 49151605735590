import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { FacilityService } from '../servicies/facility.service';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { FacilityResponseModel, LinkedTherapistWithCurrentFacilitiesRequestModel, LinkedTherapistWithCurrentFacilitiesResponseModel, SelectedFacilityResponseModel } from 'src/app/shared/models/response/facility/facility-response-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CommonNextDayScheduleRequest, CommonNextDayScheduleId } from 'src/app/shared/models/request/facility/common-nextday-schedule';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import * as moment from 'moment';
import { element } from 'protractor';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.sass'],
  providers: [DialogService]
})
export class FacilitiesComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  title = 'Facilities';
  searchRequestModel: SearchRequestModel;
  facilities: FacilityResponseModel[];
  facilityStatus: { label: string, status: boolean }[];
  selectedStatus: { label: string, status: boolean };
  totalRecords: number;
  totalPages: number;
  selectedFacilities: FacilityResponseModel[];
  selectedFacility: FacilityResponseModel;
  commonNextDayScheduleIds: CommonNextDayScheduleId[] = [];
  commonNextDayScheduleId: CommonNextDayScheduleId;
  commonNextDayScheduleRequest: CommonNextDayScheduleRequest;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  //global search
  searchText: string;
  searchFor: string;
  isGlobalSearch: boolean = false;
  orderObj: any;
  currentUserBranch: number;
  linkedTherapistWithCurrentFacilityDetails: LinkedTherapistWithCurrentFacilitiesResponseModel[];
  linkedTherapistWithCurrentFacilityActiveDetails: LinkedTherapistWithCurrentFacilitiesResponseModel[];
  ConfirmToChangeSelfScheulingStatus: boolean = false;
  selectedFacilitiesCount: number;
  linkedTherapistWithCurrentFacilityCount: number;
  isEnableSelfSchedulingForSelected: boolean;

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private facilityService: FacilityService,
    messageService: MessageService,
    private appointmentService: AppointmentService,
    private userService: UserService,
    private dialougeService: DialogService) {
    super(messageService);
    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;

    this.currentUserBranch = this.webStorageService.getUserBranch();

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      if (currentNavigationState.searchRequestModel) {
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] != null) {
          // tslint:disable-next-line: max-line-length
          this.selectedStatus = { label: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) === true ? 'Active' : 'Inactive', status: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) };
        } else {
          // this.selectedStatus = { label : 'All' , status : true };
          this.selectedStatus = { label: 'Active', status: true };
        }
        this.hasPageHistory = true;
      }
      // else {
      //   this.initializeSearchRequestModel();
      // }
    }

  }

  headerCheckboxToggle(e) {
    this.selectedFacilities.forEach(value => {
    });
  }

  async ngOnInit() {
    //this.activatedRoute.queryParamMap.subscribe(params => {
    //  this.orderObj = { ...params.keys, ...params };
    //  if (this.orderObj.params && this.orderObj.params.searchtext) {
    //    this.searchText = this.orderObj.params.searchtext;
    //    this.isGlobalSearch = true;
    //  }
    //});

    //this.globalSearchService.searchTerm.subscribe((newValue: GlobalSearchModel) => {

    //  // this is where you would apply your existing filtering.

    //  if (newValue) {

    //    this.searchText = newValue.searchText;
    //    this.isGlobalSearch = true;
    //    this.searchFor = newValue.searchFor;
    //    if (this.isGlobalSearch && this.searchFor && this.searchFor == 'F') {
    //      this.initializeSearchRequestModel();
    //      this.getFacilities();
    //    }

    //  }
    //});


    this.initializeSearchRequestModel();
    this.facilityStatus = [
      { label: 'All', status: true },
      { label: 'Active', status: true },
      { label: 'Inactive', status: false },
    ];
    await this.getBranches();
    if (this.searchRequestModel) {
      if (this.searchRequestModel.crmBranchId != null) {
        this.selectedbranch = this.branchResponseModel.find(x => x.crmBranchId === this.searchRequestModel.crmBranchId);
      }
      if (this.searchRequestModel.dictionaryFilter['SearchText'] !== null) {
        // tslint:disable-next-line: max-line-length
        this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'] !== undefined ? this.searchRequestModel.dictionaryFilter['SearchText'] : '';
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'true') {
        this.selectedStatus = { label: 'Active', status: true };
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'false') {
        this.selectedStatus = { label: 'Inactive', status: false };
      }

      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == '') {
        this.selectedStatus = { label: 'All', status: true };
      }
    }
  }

  ngAfterViewInit() {
    // this.setFilters();
    this.hasPageHistory = false;
  }

  private async getBranches() {
    this.progress.next(true);
    await this.userService.getAllBranches()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            //let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
            //if (selectedBranch)
            //  this.selectedbranch = selectedBranch;
            //else
            this.branchResponseModel = new Array();
            let branch = new BranchResponseModel();
            branch.crmBranchId = 0;
            branch.crmBranchName = "All Branches";
            this.branchResponseModel.push(branch);
            result.responseModel.forEach(value => {
              this.branchResponseModel.push(value);
            });
            this.selectedbranch = this.branchResponseModel[0];
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  onBranchChange(event) {
    if (event.value) {
      this.searchRequestModel.crmBranchId = event.value.crmBranchId;
      // this.resetPageIndex();
      // this.getFacilities(true);
    }
  }


  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  onView(facilityId) {
    //this.router.navigate(['/admin/facilities/detail/', facilityId], { state: { searchRequestModel: this.searchRequestModel } });
    this.router.navigate(['/admin/facilities/edit/', facilityId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onEdit(facilityId: string) {
    this.router.navigate(['/admin/facilities/edit/', facilityId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onDelete(doctorId: string) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.facilityService.removeFacility(doctorId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {

          if (result.isSuccess) {
            this.showSuccessMessage('Facility deleted successfully.');
            this.getFacilities(true);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getFacilities(true);
  }

  onSearchButtonClick() {
    // //this.searchRequestModel.dictionaryFilter = {};
    // this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // // let selSatatus = this.selectedStatus;
    // // if (selSatatus)
    // //   this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.wipStatesName;
    // this.resetPageIndex();
    // this.getFacilities(true);
  }

  onStatusChange(event) {
    // this.searchRequestModel.dictionaryFilter = {};
    // if (this.searchBox.nativeElement.value) {
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // }

    if (event.value != null) {
      if (event.value.label !== "All") {
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status.toString();
      }
      else {
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
      }
    }
    // this.resetPageIndex();
    // this.getFacilities(true);
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
    }
    this.getFacilities(true);
  }

  selectFacility(event, facility: FacilityResponseModel, overlaypanel: OverlayPanel) {
    this.selectedFacility = facility;
    overlaypanel.toggle(event);
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {
    if (this.hasPageHistory) {
      return;
    }
    this.searchRequestModel = new SearchRequestModel();
    //this.searchRequestModel.crmBranchId = this.currentUserBranch;

    if (this.isGlobalSearch && this.searchFor == 'F') {
      this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = 'CrmFacilityName';

    if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = 'true';
      this.selectedStatus = { label: 'Active', status: true };
    }
  }

  private getFacilities(isRefresh: boolean) {
    this.loading = true;
    this.facilityService.getAllFacilities(this.searchRequestModel, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<FacilityResponseModel>>) => {
        this.processResult<PagedResponseModel<FacilityResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.facilities = pagedResponse.results;

                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize) {
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  SendInvitation() {

    if (!this.selectedFacilities || this.selectedFacilities.length <= 0) {
      this.showWarningMessage('Please select facilities');
      return;
    }
    this.commonNextDayScheduleIds = [];
    this.commonNextDayScheduleRequest = new CommonNextDayScheduleRequest();
    this.commonNextDayScheduleRequest.lstId = [];
    this.selectedFacilities.forEach((value) => {
      this.commonNextDayScheduleId = new CommonNextDayScheduleId();
      this.commonNextDayScheduleId.intId = value.crmFacilityId;
      this.commonNextDayScheduleIds.push(this.commonNextDayScheduleId);
    });

    this.commonNextDayScheduleRequest.lstId = this.commonNextDayScheduleIds;
    this.commonNextDayScheduleRequest.dtDate = moment(new Date()).toDate();
    this.appointmentService.sendFacilitySchedule(this.commonNextDayScheduleRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel) {
          this.showSuccessMessage('Schedule sent for selected facilities');
          this.selectedFacilities = [];
        }
        else {
          this.showWarningMessage('Error occurred.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onEmail(crmFacilityId: number) {

    if (!crmFacilityId) {
      this.showWarningMessage('Please select facilities');
      return;
    }
    this.commonNextDayScheduleIds = [];
    this.commonNextDayScheduleRequest = new CommonNextDayScheduleRequest();
    this.commonNextDayScheduleRequest.lstId = [];
    this.commonNextDayScheduleId = new CommonNextDayScheduleId();
    this.commonNextDayScheduleId.intId = crmFacilityId;
    this.commonNextDayScheduleIds.push(this.commonNextDayScheduleId);
    this.commonNextDayScheduleRequest.lstId = this.commonNextDayScheduleIds;
    this.commonNextDayScheduleRequest.dtDate = moment(new Date()).toDate();
    this.appointmentService.sendFacilitySchedule(this.commonNextDayScheduleRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel) {
          this.showSuccessMessage('Schedule sent for selected facilities');
          this.selectedFacilities = [];
        }
        else {
          this.showWarningMessage('Error occurred.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onFilterSearch() {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    this.resetPageIndex();
    this.getFacilities(true);
  }

  onClearSearchFilter() {
    this.searchRequestModel.dictionaryFilter['ActiveStatus'] = 'true';
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value = '';
    this.selectedbranch = this.branchResponseModel[0];
    this.selectedStatus = this.facilityStatus[1];
    this.searchRequestModel.crmBranchId = Number(0);
  }

  handleFacilitySelfScheduleAppointmentStatusChange(id, e) {
    let isChecked = e.checked;
    //this.FacilitySelfScheduleAppointmentStatus(id, isChecked);
    let facilityIds: number[] = [];
    facilityIds.push(id);
    this.selectedFacilities = [];
    this.selectedFacilities.push(this.facilities.find(x => x.crmFacilityId == id));

    this.CheckLinkTherapistWithSelectedFacilities(facilityIds, isChecked);
  }

  FacilitySelfScheduleAppointmentStatus(facilityId, value) {
    let msg: string;
    if (value) {
      msg = 'Are you sure you want to enable Self-scheduling?';
    }
    else {
      msg = 'Are you sure you want to disable Self-scheduling?';
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: msg,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.facilities.find(x => x.crmFacilityId == facilityId).crmIsAllowSelfScheduleAppointments = !value;
        return;
      }
      this.loading = true;

      const facilityResponseModel = new FacilityResponseModel();
      facilityResponseModel.crmFacilityId = facilityId;
      facilityResponseModel.crmIsAllowSelfScheduleAppointments = value;
      const manageFacilityStatus = this.facilityService.UpdateFacilitySelfScheduleAppointmentsStatus(facilityResponseModel);
      manageFacilityStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            if (value) {
              this.showSuccessMessage('Self-Scheduling has been activated');
            }
            else {
              this.showSuccessMessage('Self-Scheduling has been deactivated');
            }
            this.getFacilities(true);

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  SelectedFacilitiesSelfScheduleAppointmentStatus(value) {
    if (!this.selectedFacilities || this.selectedFacilities.length <= 0) {
      this.showWarningMessage('Please select Facility.');
      return;
    }

    let facilityIds: number[] = [];
    this.selectedFacilities.forEach(element => {
      facilityIds.push(element.crmFacilityId)
    });

    this.CheckLinkTherapistWithSelectedFacilities(facilityIds, value);

    // let msg: string;
    // if (value) {
    //   msg = 'Are you sure you want to enable Self-scheduling?';
    // }
    // else {
    //   msg = 'Are you sure you want to disable Self-scheduling?';
    // }
    // this.dialougeService.open(ConfirmDialogComponent, {
    //   data:
    //   {
    //     message: msg,
    //   },
    //   header: 'Confirmation'
    // }).onClose.subscribe((response: boolean) => {
    //   if (!response) {
    //     return;
    //   }
    //   this.loading = true;
    //   let selectedFacilityResponseModel = new SelectedFacilityResponseModel();
    //   selectedFacilityResponseModel.isEnable = value;
    //   selectedFacilityResponseModel.facilityResponseModels = this.selectedFacilities;
    //   const manageFacilityStatus = this.facilityService.UpdateSelectedFacilitySelfScheduleAppointmentsStatus(selectedFacilityResponseModel);
    //   manageFacilityStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //     .subscribe((result: Result) => {
    //       if (result && result.isSuccess) {
    //         if (value) {
    //           this.showSuccessMessage('Self-Scheduling has been activated');
    //         }
    //         else {
    //           this.showSuccessMessage('Self-Scheduling has been deactivated');
    //         }
    //         this.getFacilities(true);
    //         this.selectedFacilities = [];
    //       } else {
    //         this.loading = false;
    //         this.showErrorMessage(result.message);
    //       }

    //     }, (httpResponseError) => {
    //       this.loading = false;
    //       this.showErrorMessage(httpResponseError.message);
    //     });
    // });
  }

  CheckLinkTherapistWithSelectedFacilities(facilityIds: number[], value: boolean) {
    let linkedTherapistWithCurrentFacilitiesRequestModel: LinkedTherapistWithCurrentFacilitiesRequestModel = {
      isEnableSelfSchedulingForSelected: value,
      FacilityIds: facilityIds,
      TherapistIds: []
    };

    this.facilityService.GetAllLinkedTherapistWithCurrentFacilityDetails(linkedTherapistWithCurrentFacilitiesRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          this.ConfirmToChangeSelfScheulingStatus = true;
          this.isEnableSelfSchedulingForSelected = value;
          this.linkedTherapistWithCurrentFacilityDetails = result.responseModel;
          this.selectedFacilitiesCount = this.selectedFacilities.length;

          if (this.linkedTherapistWithCurrentFacilityDetails == null) {
            this.linkedTherapistWithCurrentFacilityCount = 0;
            return;
          }

          this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityDetails;
// //           let AvailableTherapists = [...new Set(this.linkedTherapistWithCurrentFacilityDetails.map(x => x.appUserInfoId))];
// //           AvailableTherapists.forEach(element => {
// //             var AvailableTherapistDetails = this.linkedTherapistWithCurrentFacilityDetails.filter(x => x.appUserInfoId == element);
// //             if (AvailableTherapistDetails.length > 1) {
// // var result = AvailableTherapistDetails.filter(x=>x.crmFacilityIsAllowSelfScheduleAppointments==!value && );
// //             }
// //           }
// //           );
//           //this.linkedTherapistWithCurrentFacilityDetails = this.linkedTherapistWithCurrentFacilityDetails.filter(item => !facilityIds.map(x => x).includes(item.crmFacilityId));
//           var ignoredAlreadyTurnOnOffTherapistList = this.linkedTherapistWithCurrentFacilityDetails.filter(x => x.crmTharapistIsAllowSelfScheduleAppointments == value && x.crmFacilityIsAllowSelfScheduleAppointments == value);
//           if (ignoredAlreadyTurnOnOffTherapistList != undefined && ignoredAlreadyTurnOnOffTherapistList != null && ignoredAlreadyTurnOnOffTherapistList.length > 0) {
//             this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityDetails.filter(item => !ignoredAlreadyTurnOnOffTherapistList.map(x => x.appUserInfoId).includes(item.appUserInfoId));
//           }
//           else {
//             this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityDetails;
//           }

//           var ignoreTherapistList = this.linkedTherapistWithCurrentFacilityActiveDetails.filter(x => x.crmTharapistIsAllowSelfScheduleAppointments == !value &&
//             !facilityIds.map(x => x).includes(x.crmFacilityId));

//           //this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityDetails.filter(x => x.crmTharapistIsAllowSelfScheduleAppointments == !value && x.crmFacilityIsAllowSelfScheduleAppointments == value);

//           //let isFilteredList: boolean=false;
//           if (ignoreTherapistList != undefined && ignoreTherapistList != null && ignoreTherapistList.length > 0 && this.linkedTherapistWithCurrentFacilityActiveDetails.length > 0) {
//             //isFilteredList = true;
//             this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityActiveDetails.filter(item => !ignoreTherapistList.map(x => x.appUserInfoId).includes(item.appUserInfoId));
//           }
//           // else if (this.linkedTherapistWithCurrentFacilityActiveDetails.length == 0 && ignoreTherapistList.length == 0) {
//           //   this.linkedTherapistWithCurrentFacilityActiveDetails = this.linkedTherapistWithCurrentFacilityDetails;
//           // }
//           else if (this.linkedTherapistWithCurrentFacilityActiveDetails.length == 0 && ignoreTherapistList.length > 0) {
//             this.linkedTherapistWithCurrentFacilityActiveDetails = ignoreTherapistList.filter(item => facilityIds.map(x => x).includes(item.crmFacilityId));;
//           }

//           // if (this.linkedTherapistWithCurrentFacilityDetails.length == ignoreTherapistList.length && !isFilteredList) {
//           //   this.linkedTherapistWithCurrentFacilityActiveDetails = ignoreTherapistList;
//           // }

          if (this.linkedTherapistWithCurrentFacilityActiveDetails != undefined && this.linkedTherapistWithCurrentFacilityActiveDetails != null) {
            if (this.linkedTherapistWithCurrentFacilityActiveDetails.length > 0) {
              this.linkedTherapistWithCurrentFacilityCount = this.linkedTherapistWithCurrentFacilityActiveDetails.length;
            }
            // else if (this.linkedTherapistWithCurrentFacilityDetails.length != this.linkedTherapistWithCurrentFacilityActiveDetails.length) {
            //   this.linkedTherapistWithCurrentFacilityCount = 0;
            // }
            else {
              this.linkedTherapistWithCurrentFacilityCount = 0;
            }
          }

          // if (this.linkedTherapistWithCurrentFacilityDetails == null) {
          //   this.linkedTherapistWithCurrentFacilityCount = 0;
          // }
          // else {
          //   this.linkedTherapistWithCurrentFacilityCount = this.linkedTherapistWithCurrentFacilityDetails.length;
          // }
        }
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  CancelSelfSchedulingChanges(isCancel: boolean) {
    this.ConfirmToChangeSelfScheulingStatus = false;
    if (isCancel) {
      this.getFacilities(true);
    }

    this.selectedFacilities = [];
    this.linkedTherapistWithCurrentFacilityDetails = null;
    this.selectedFacilitiesCount = 0;
    this.linkedTherapistWithCurrentFacilityCount = 0
  }

  ConfirmSelfSchedulingChanges() {
    this.loading = true;
    let selectedFacilityResponseModel = new SelectedFacilityResponseModel();
    selectedFacilityResponseModel.isEnable = this.isEnableSelfSchedulingForSelected;
    selectedFacilityResponseModel.facilityResponseModels = this.selectedFacilities;

    let therapistIds: number[] = null;
    if (!this.linkedTherapistWithCurrentFacilityActiveDetails != undefined && this.linkedTherapistWithCurrentFacilityActiveDetails != null) {
      therapistIds = [];
      this.linkedTherapistWithCurrentFacilityActiveDetails.forEach(element => {
        therapistIds.push(element.appUserInfoId)
      });
    }

    selectedFacilityResponseModel.linkedTherapists = therapistIds;
    const manageFacilityStatus = this.facilityService.UpdateSelectedFacilitySelfScheduleAppointmentsStatus(selectedFacilityResponseModel);
    manageFacilityStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          if (this.isEnableSelfSchedulingForSelected) {
            this.showSuccessMessage('Self-Scheduling has been activated');
          }
          else {
            this.showSuccessMessage('Self-Scheduling has been deactivated');
          }
          this.getFacilities(true);
          this.selectedFacilities = [];
          this.CancelSelfSchedulingChanges(false);
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
