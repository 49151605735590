<div class="form-content-outer">

  <div class="form-outer">
<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <!-- <h2 class="first"></h2> -->
        <div class="col">
          <div class="form__group">
            <label class="form__label">Current Password</label>
            <input class="form__control" formControlName="oldPassword" type="password"  pInputText maxlength="25">
            <span class="invalid-feedback" *ngIf="hasError(formFields.oldPassword, 'required')" >{{validationMessages.oldPassword.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.oldPassword, 'validStrongPassword')"  >{{validationMessages.oldPassword.invalid}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">New Password</label>
            <input class="form__control" formControlName="newPassword" type="password" pInputText maxlength="25">
            <span class="invalid-feedback" *ngIf="hasError(formFields.newPassword, 'required')">{{validationMessages.newPassword.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.newPassword, 'validStrongPassword')">{{validationMessages.newPassword.invalid}}</span>

            <a href="javascript:void(0)" class="pass-info">
              <i class="info-new"></i>

              <span class="password-info">
                Password must contain minimum 8 characters length,<br> at least one upper case letter,<br> one lower case letter, <br>one special character, <br>one numeric character.
              </span>
            </a>
          </div>

          <div class="form__group">
            <label class="form__label">Confirm New Password</label>
            <input class="form__control" formControlName="confirmPassword" type="password" pInputText appConfirmEqualValidator='newPassword' maxlength="25">
            <span class="invalid-feedback" *ngIf="hasError(formFields.confirmPassword, 'required')"
                 >{{validationMessages.confirmPassword.required}}</span>
            <!--<span class="invalid-feedback" *ngIf="hasError(formFields.confirmPassword, 'validStrongPassword')"
                 >{{validationMessages.confirmPassword.invalid}}</span><br />-->
            <span class="invalid-feedback" *ngIf="form.hasError('validPasswordMatch')"
                  >{{validationMessages.confirmPassword.validPasswordMatch}}</span>
          </div>
        </div>
       
        <div class="set-custom-date-btn">
          <button class="btn btn--primary">Update</button>
          <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
        </div>
      </form>
  </div>



</div>

