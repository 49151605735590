import { BranchResponseModel } from './branch-response-model';

export class UserBranchResponseModel {
  appUserBranchId: number;
  appUserId: number;
  crmBranchId: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranch: BranchResponseModel;
}
