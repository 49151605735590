export class TherapistCertificationResponseModel {
therapistCertificationId: number;
therapistId: number;
deviceName: string;
certificateNumber: string;
isActive: boolean;
modifiedByUserInfoId: number;
dateTimeCreated: Date;
dateTimeLastModified: Date;
}
