<div class="content-area">
    <p-tabView class="appointments-tab-outer setting-tab-menu admin-application-setting" (onChange)="tabChangeHelper($event)">
        <div class="title-block">
            <h1>Time Slot Availability Summary</h1>
            <div class="title-block__right">
                <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
            </div>
        </div>

        <!-- <div class="reports-outer">
            <p>timeslot-availability-summary works!</p>
        </div> -->

        <p-tabPanel header="By Facility">
            <div class="form-content-outer">
                <div class="form-outer">
                    <div class="form">
                        <div class="col">
                            <div class="form__group">
                                <label class="form__label">Facility Name</label>
                                <!-- <p-multiSelect class="select-box filter-status" placeholder="Select"
                                    [options]="TherapistName" [(ngModel)]="selectedTherapist" optionLabel="name">
                                </p-multiSelect> -->
                                <p-multiSelect class="select-box filter-status" [(ngModel)]="selectedFacility"
                                    [options]="facilityList" defaultLabel="Select Facility"
                                    optionLabel="crmFacilityName" [filter]="true"
                                    selectedItemsLabel="{0} facility(s) Selected" resetFilterOnHide="true"></p-multiSelect>
                            </div>
                            <div class="form__group report-availablity-calenadr">
                                <label class="form__label">Date</label>
                                <input matInput class="form__control " ngxDaterangepickerMd placeholder="From - To"
                                    [alwaysShowCalendars]="true"
                                    [customRangeDirection]="true" [(ngModel)]="FSelectedDateRange" 
                                    [showClearButton]="true" [showCancel]="true" [linkedCalendars]="true"
                                    [isInvalidDate]="isInvalidDate"
                                    [locale]="callocales" [keepCalendarOpeningWithRange]="true"
                                    [showRangeLabelOnInput]="true" />
                            </div>
                        </div>
                        <div class="col">
                            <div class="reportby-block__redio-btn">
                                <p-radioButton name="group2" [(ngModel)]="defaultMinutes" value="30"
                                    label="30 Minutes Time Slot Duration">
                                </p-radioButton>
                                <p-radioButton name="group2" [(ngModel)]="defaultMinutes" value="60"
                                    label="60 Minutes Time Slot Duration">
                                </p-radioButton>
                            </div>
                        </div>
                        <div class="btns">
                            <button class="btn btn--primary" (click)="onFacilityExport()">Export Report</button>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>

        <p-tabPanel header="By Therapist">
            <div class="form-content-outer">
                <div class="form-outer">
                    <div class="form">
                        <div class="col">
                            <div class="form__group">
                                <label class="form__label">Therapist Name</label>
                                <!-- <p-multiSelect class="select-box filter-status" placeholder="Select"
                                    [options]="TherapistName" [(ngModel)]="selectedTherapist" optionLabel="name">
                                </p-multiSelect> -->
                                <p-multiSelect class="select-box filter-status" [(ngModel)]="selectedTherapist"
                                    [options]="therapists" defaultLabel="Select Therapist" optionLabel="name"
                                    [filter]="true" selectedItemsLabel="{0} therapist(s) Selected" resetFilterOnHide="true"></p-multiSelect>
                                <!-- (onChange)="onTherapistChange($event)" -->
                            </div>
                            <div class="form__group report-availablity-calenadr">
                                <label class="form__label">Date</label>
                                <input matInput class="form__control " ngxDaterangepickerMd placeholder="From - To"
                                    [alwaysShowCalendars]="true"
                                    [customRangeDirection]="true" [(ngModel)]="TSelectedDateRange" 
                                    [showClearButton]="true" [showCancel]="true" [linkedCalendars]="true"
                                    [isInvalidDate]="isInvalidDate"
                                    [locale]="callocales" [keepCalendarOpeningWithRange]="true"
                                    [showRangeLabelOnInput]="true" />
                            </div>
                        </div>

                        <div class="col">
                            <div class="form__group full-width">
                                <span class="availability">
                                    <i class="info-new mr-10"></i>
                                    The availability count is calculated based on the 15 minutes time slot duration.
                                </span>
                            </div>
                        </div>

                        <div class="btns">
                            <button class="btn btn--primary" (click)="onTherapistExport()">Export Report</button>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>

    </p-tabView>
    <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
            animationDuration=".8s">
        </p-progressSpinner>
    </div>
</div>