import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass']
})
export class ConfirmDialogComponent implements OnInit {
  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
  }

  ngOnInit() {   
  }

  onYes() {
    this.dynamicDialogRef.close(true);
  }

  onNo() {
    this.dynamicDialogRef.close(false);
  }
}
