import { Component, OnInit, Output, EventEmitter, HostListener, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery'
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AppointmentTypeResponseModel } from 'src/app/shared/models/response/appointment-type-response-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TheraistAppointmentService } from '../services/theraistappointment.service';
import { MobileSchedulerList, SchedulerResponseModel, SchedulerTherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import * as moment from 'moment';
import { PatientSideBarInfo } from 'src/app/shared/models/response/appointment/therapist-appointment-by-appointment_type';


@Component({
  selector: 'app-week-view-mobile',
  templateUrl: './week-view-mobile.component.html',
  styleUrls: ['./week-view-mobile.component.sass']
})
export class WeekViewMobileComponent extends BaseComponent implements OnInit {
  refresh: Subject<any> = new Subject();
  schedulerList: SchedulerResponseModel[];
  selectedTimeZone: any;
  timeZone: { name: string; orgcode: string; shortName: string; }[];
  AppointmentsType: { name: string; code: string; }[];
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  appointmentTypeResponseModel: AppointmentTypeResponseModel[];
  selectedappointmentType: AppointmentTypeResponseModel;
  therapistId: number;
  viewDate: Date = new Date();
  appDateFormat: DateFormatModel;
  weekDateArr: weekDate[];
  activeDate: string;
  defaultAppointmentType: number = 0;
  canLoad = false;
  pendingLoad = false;
  canLoadUp = false;
  pendingLoadUp = false;
  mobileSchedulerList: MobileSchedulerList[];
  origionalSchedulerList: MobileSchedulerList[];
  patientSidebar: PatientSideBarInfo;
  patientDetailSidebar: boolean = false;
  salesOrderId: number = 0;
  throttle = 150;
  scrollDistance = 1;
  scrollUpDistance = 2;
  selectedDate: string;
  @Input() openMobileCalender: boolean;
  checkDateforMultipleCall: string;
  isappointmentChanged: boolean = false;
  istimeZoneChagned: boolean = false;
  HeaderDate: Date = new Date();;

  constructor(
    private webStorageService: WebStorageService,
    private router: Router,
    private theraistAppointmentService: TheraistAppointmentService,
    messageService: MessageService,
    private http: HttpClient,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(messageService);

    this.timeZone = [
      { name: 'Eastern Time (UTC -5:00)', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC -6:00)', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC -7:00)', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC -7:00)', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC -8:00)', orgcode: 'UTC-8', shortName: 'PST' },
    ];
    this.appDateFormat = this.webStorageService.getDateFormat();
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.selectedTimeZone = currentUserToken.crmTimeZone;
      var aapType = new AppointmentTypeResponseModel();
      aapType.crmAppointmentTypeId = 0;
      this.selectedappointmentType = aapType;
    }
    this.getTherapistAppointment(this.weekcurrentDate, this.defaultAppointmentType);
    this.weekDateArr = [];
    this.mobileSchedulerList = []
    this.origionalSchedulerList = [];
  }

  async ngOnInit() {

    var self = this;
    this.getAllAppointmentType();
    self.selectedDate = moment(new Date()).format("YYYY-MM-DD");
    await this.getDatesofWeek(this.viewDate);


    $(document).on('click', '.navigation a', function (e) {
      e.preventDefault();
      var tghsh = $(this).attr('rel');
      var doffset = $('#' + tghsh).offset().top - 150;

      $('html, body').animate({ scrollTop: doffset }, 500, 'swing', function () {
        location.hash = '';
      });
      return false;
    });

    $(function () {
      const nav = $('.navigation');
      if (nav.length) {
        pageScrolling();
      }

      setTimeout(function () {
        const target = (<any>$(".page-section.on"));
        $('html, body').animate({
          scrollTop: (<any>$(".page-section.on")).offset().top - 150
        }, 'slow');
      }, 500);

    });

    function pageScrolling() {
      $(window).on('scroll', function (e) {
        e.preventDefault();
        $('.page-section').each(function (i) {
          const scrollDistance = (<any>$(window)).scrollTop() + 165;
          if ($(this).position().top <= scrollDistance) {
            $('.navigation a.active').removeClass('active');
            $('.navigation a').eq(i).addClass('active');
          }
        });
      });
    }

  }

  private async getAllAppointmentType() {
    this.progress.next(true);
    await this.theraistAppointmentService.GetAllAppointmentType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.appointmentTypeResponseModel = result.responseModel;
            var appType = new AppointmentTypeResponseModel();
            appType.crmAppointmentTypeId = 0;
            appType.crmAppointmentTitle = "All Appointment"
            this.appointmentTypeResponseModel.splice(0, 0, appType);
          }
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  get weekcurrentDate(): string {
    return this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
  }

  private async getTherapistAppointment(weekcurrentDate: string, appointmentType: number) {
    this.loading = true;
    if (this.therapistId === undefined || weekcurrentDate === undefined) {
      return;
    }
    await this.theraistAppointmentService.GetTherapistAppointmentForMobileCalender(this.therapistId, weekcurrentDate, appointmentType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel.length > 0) {
              for (let i = 0; i <= result.responseModel.length; i++) {
                let result1 = new MobileSchedulerList();
                result1.schAppointmentId = result.responseModel[i].schAppointmentId;
                result1.startTime = result.responseModel[i].startTime;
                result1.patientFirstName = result.responseModel[i].patientFirstName;
                result1.patientLastName = result.responseModel[i].patientLastName;
                result1.duration = result.responseModel[i].duration;
                result1.crmPatientTimeZone = result.responseModel[i].crmPatientTimeZone;
                result1.crmSalesOrdeId = result.responseModel[i].crmSalesOrdeId;
                result1.appointmentType = result.responseModel[i].appointmentType;
                result1.appointmentTypeId = result.responseModel[i].appointmentTypeId;
                result1.crmTimeZone = result.responseModel[i].crmTimeZone;
                result1.schWIPStatus=result.responseModel[i].schWIPStatus;
                let index = this.mobileSchedulerList.find(object => object.schAppointmentId == result.responseModel[i].schAppointmentId);
                if (index == undefined) {
                  this.mobileSchedulerList.push(result1);
                  this.origionalSchedulerList.push(result1);
                }
              };
            }
            // this.checkDateforMultipleCall = weekcurrentDate;
            // this.isappointmentChanged = false;
            //this.schedulerList = result.responseModel;
          }

        });
        this.changeDetectorRef.detectChanges();
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  getDatesofWeek(dt) {
    this.weekDateArr = [];
    for (let i = 1; i <= 7; i++) {
      var weekDt = new weekDate();
      let first = dt.getDate() - dt.getDay() - 1 + i
      let day = new Date(dt.setDate(first)).toISOString().slice(0, 10)
      weekDt.dates = day;
      let today = moment(new Date()).format("YYYY-MM-DD");
      if (day == today) {
        this.activeDate = day;
        weekDt.isActiveDate = true;
      }
      else {
        weekDt.isActiveDate = false;
      }
      let indexof = this.weekDateArr.find(x => x.dates === day)
      if (indexof == null) {
        this.weekDateArr.push(weekDt);
      }
    }
    this.weekDateArr.sort((a, b) => (a.dates > b.dates) ? 1 : ((b.dates > a.dates) ? -1 : 0));
  }
  getSelectedDateData(dt: string) {
    this.activeDate = dt;
    // this.weekDateArr.forEach(x => x.isActiveDate = false);
    // let updateItem = this.weekDateArr.find(x => x.dates === this.activeDate);
    // let index = this.weekDateArr.indexOf(updateItem);
    // this.weekDateArr[index].isActiveDate = true;
    this.selectedDate = moment(dt).format('YYYY-MM-DD');
    this.HeaderDate = new Date(dt);
    this.getTherapistAppointment(dt, this.defaultAppointmentType)
  }
  onTimeZoneChange(event) {
    if (event) {
      this.loading = true;
      setTimeout(() => {
        // this.selectedTimeZone = '';
        //this.selectedTimeZone = event.value.shortName;
        this.mobileSchedulerList = [];
        this.mobileSchedulerList = this.removeDuplicates(this.origionalSchedulerList, "schAppointmentId");
        let code = this.timeZone.find(x => x.shortName == event.value.shortName).orgcode;
        let filterData = this.mobileSchedulerList.filter(x => x.crmPatientTimeZone == code);
        this.mobileSchedulerList = [];
        this.mobileSchedulerList = filterData;
        //this.getTherapistAppointment(event.value.shortName, this.activeDate, this.defaultAppointmentType);
        this.loading = false;
      }, 3000);


    }
  }
  async onAppointmentTypeChange(event) {
    if (event) {
      this.loading = true;
      //setTimeout(async () => {
      this.defaultAppointmentType = event.value.crmAppointmentTypeId;
      let appointmentType = event.value.crmAppointmentTitle.toString();
      this.isappointmentChanged = true;
      await this.getTherapistAppointment(this.selectedDate, this.defaultAppointmentType);
      if (this.defaultAppointmentType == 0) {
        let filterData = this.mobileSchedulerList.filter(x => x.schAppointmentId != 0);
        this.mobileSchedulerList = [];
        this.mobileSchedulerList = filterData;
      }
      else {
        let filterData = this.mobileSchedulerList.filter(x => x.appointmentTypeId == this.defaultAppointmentType);
        this.mobileSchedulerList = [];
        this.mobileSchedulerList = filterData;
      }
      this.loading = false;
      //}, 3000);
    }
  }

  async nextButtonClick() {
    let lastDate = this.weekDateArr[this.weekDateArr.length - 1].dates;
    var startDate = moment(lastDate).startOf('week').format('YYYY-MM-DD');
    var endDate = moment(lastDate).endOf('week').format("YYYY-MM-DD");
    if (lastDate === endDate) {
      let end = new Date(endDate);
      end = new Date(end.setDate(end.getDate() + 1));
      this.getDatesofWeek(end);
    }
    else if (lastDate === startDate) {
      let start = new Date(startDate);
      start = new Date(start.setDate(start.getDate() - 1));
      this.getDatesofWeek(start);
    }

    this.weekDateArr.forEach(x => x.isActiveDate = false);
    let updateItem = this.weekDateArr.find(x => x.dates === this.weekDateArr[0].dates);
    let index = this.weekDateArr.indexOf(updateItem);
    this.weekDateArr[index].isActiveDate = true;
    this.selectedDate = this.weekDateArr[0].dates;
    this.HeaderDate = new Date(this.weekDateArr[0].dates);

    this.canLoad = false;
    this.pendingLoad = false;

    let convertdate = new Date(lastDate);
    let convertdateplus = new Date(convertdate.setDate(convertdate.getDate() + 1));
    let finalDt = moment(convertdateplus).format("YYYY-MM-DD")
    await this.getTherapistAppointment(finalDt, this.defaultAppointmentType)

  }

  async previousButtonClick() {
    let lastDate = this.weekDateArr[0].dates;
    var startDate = moment(lastDate).startOf('week').format('YYYY-MM-DD');
    var endDate = moment(lastDate).endOf('week').format("YYYY-MM-DD");
    if (lastDate === endDate) {
      let end = new Date(endDate);
      end = new Date(end.setDate(end.getDate() + 1));
      this.getDatesofWeek(end);
    }
    else if (lastDate === startDate) {
      let start = new Date(startDate);
      start = new Date(start.setDate(start.getDate() - 1));
      this.getDatesofWeek(start);
    }
    this.weekDateArr.forEach(x => x.isActiveDate = false);
    let updateItem = this.weekDateArr.find(x => x.dates === this.weekDateArr[0].dates);
    let index = this.weekDateArr.indexOf(updateItem);
    this.weekDateArr[index].isActiveDate = true;
    this.selectedDate = this.weekDateArr[0].dates;
    this.HeaderDate = new Date(this.weekDateArr[0].dates);

    let convertdate = new Date(lastDate);
    let convertdateplus = new Date(convertdate.setDate(convertdate.getDate() - 1));
    let finalDt = moment(convertdateplus).format("YYYY-MM-DD")
    this.getTherapistAppointment(finalDt, this.defaultAppointmentType)

  }

  onPatientDetailSidebarClick(patientDetail) {
    this.patientDetailSidebar = true;
    this.patientSidebar = new PatientSideBarInfo();
    let patientDetailobj = this.mobileSchedulerList.find(x => x.schAppointmentId == patientDetail.schAppointmentId)
    this.patientSidebar.patientName = patientDetailobj.patientLastName + ', ' + patientDetailobj.patientFirstName;
    this.patientSidebar.appointmentDate = patientDetailobj.startTime;
    this.patientSidebar.patientTimeZone = patientDetailobj.crmTimeZone;
    this.patientSidebar.visitType = patientDetailobj.appointmentType;

    // let apphours = Math.floor(parseInt(patientDetailobj.duration) / 60);
    // let appMinutes = parseInt(patientDetailobj.duration) % 60;
    // let appTotalHours = (apphours > 0 ? (apphours + 'h ') : '') + (appMinutes > 0 ? (appMinutes + 'm') : '')

    this.patientSidebar.duration = patientDetailobj.duration + 'm';
    this.patientSidebar.therapistID = this.therapistId;
    this.salesOrderId = patientDetailobj.crmSalesOrdeId;
    this.patientSidebar.status=patientDetailobj.schWIPStatus;
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }
  onCalenderDateChange() {
    var dt = this.HeaderDate.toDateString();
    var startDate = moment(dt).format('YYYY-MM-DD');
    this.getDatesofWeek(new Date(startDate));

    this.weekDateArr.forEach(x => x.isActiveDate = false);
    let updateItem = this.weekDateArr.find(x => x.dates === startDate);
    let index = this.weekDateArr.indexOf(updateItem);
    this.weekDateArr[index].isActiveDate = true;
    this.selectedDate = startDate;
    this.getTherapistAppointment(startDate, this.defaultAppointmentType)

    setTimeout(() => {
      const target = (<any>$(".page-section.on"));
      $('html, body').animate({
        scrollTop: (<any>$(".page-section.on")).offset().top - 150
      }, 'slow');
    }, 500);

  }

}
export class weekDate {
  dates: string;
  isActiveDate: boolean;
}
