
export class TherapistAppointmentsByAppointmenType {
    therapistAppointmentsForClinicRemoteResponseModels: TherapistAppointmentsForClinicRemoteResponseModel[];
    therapistAppointmentsForRoadHomeResponseModels: TherapistAppointmentsForRoadHomeResponseModel[];
}

export class TherapistAppointmentsForClinicRemoteResponseModel {
    SchAppointmentId: number
    AppointmentType: string
    CrmAddress: string
    facilityCrmAddress: string
    facilityName: string
    TherapistId: number
    FacilityId: number
    StartTime: Date
    EndTime: Date
    StartTimePT: Date
    EndTimePT: Date
    appointmentDuration: string
    PatientFirstName: string
    PatientLastName: string
    IsAppointment: boolean
    CrmSalesOrdeId: number
    CrmSalesorderAppId: string
    RcmPatientId: number
    RcmPatientBrightreeID: number
    CrmPatientPhoneNo: string
    crmClassification: string
    SchWIPStatus: string
    SchComments: string
    CrmPatientTimeZone: string
  CrmTimeZone: string
  dateTimeLastModified:Date
}

export class TherapistAppointmentsForRoadHomeResponseModel {
    schAppointmentId: number
    appointmentType: string
    crmAddress: string
    facilityCrmAddress: string
    facilityName: string
    therapistId: number
    facilityId: number
    startTime: Date
    endTime: Date
    startTimePT: Date
    endTimePT: Date
    appointmentDuration: string
    patientFirstName: string
    patientLastName: string
    isAppointment: boolean
    crmSalesOrdeId: number
    crmSalesorderAppId: string
    rcmPatientId: number
    rcmPatientBrightreeID: number
    crmPatientPhoneNo: string
    crmClassification: string
    schWIPStatus: string
    schComments: string
    crmPatientTimeZone: string
    crmTimeZone: string
    crmPatientLat: string
    crmPatientLong: string
    therapistLat: string
    therapistLong: string
    distance: string
    duration: string
    EstimatedTime: string
    startLocationName: string
    totalMiles: number
    totalETA: number
    therapistDefaultLocation: string
    appointmentOrder: number
    isRescheduled: boolean
    therapistAddress: string
  crmPatientAddress: string
  dateTimeLastModified: Date
}

export class PatientSideBarInfo {
    patientName: string;
    appointmentDate: Date;
    patientTimeZone: string;
    visitType: string;
    duration: string;
    therapistID: number;
  status: string;
  datetimeLastModified: Date;
}
