import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../servicies/settings.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { SettingsGeneralResponseModel } from 'src/app/shared/models/response/settings/settings-general-response-model';
import { SettingsGeneralRequestModel } from 'src/app/shared/models/request/settings/settings-general-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { CompanyService } from 'src/app/shared/services/company.service';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import * as moment from 'moment';
@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.sass']
})
export class GeneralComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('uploadE1') uploadElRef1: ElementRef;
  @ViewChild('uploadE2') uploadElRef2: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsGeneralResponseModel: SettingsGeneralResponseModel;
  settingsGeneralRequestModel: SettingsGeneralRequestModel;
  companyId: number;
  title: string;
  companyLogo: string;
  companyFavicon: string;
  private domainUrl: string = '';
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  faviconBase64: any;
  isFaviconSaved: boolean;

  formFields = {
    companyName: 'companyName',
    contactName: 'contactName',
    address: 'address',
    DMENumber: 'DMENumber',
    DMEEmail: 'DMEEmail'
  };
  validationMessages = {
    companyName: {
      required: 'Company name is required',
      validCharacters: 'Enter valid company name'
    },
    contactName: {
      required: 'Contact name is required',
      validCharacters: 'Enter valid contact name'
    },
    address: {
      required: 'Address is required',
    },
    DMENumber: {
      required: 'DME Phone Number is required',
    },
    DMEEmail: {
      required: 'DME Email is required',
      email: 'Enter valid email address',
    }
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService,
    private sanitizer: DomSanitizer,
    private companyService: CompanyService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    this.title = "General Settings";
    const companyDetails = this.webStorageService.getClientConfig();
    this.getCompany(companyDetails.companyId);
  }

  uploadLogoFile(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const allowed_types = ['image/png'];
      // const allowed_types = ['image/png', 'image/jpeg'];

      // // for testing
      // const max_size = 20971520;
      // const max_height = 15200;
      // const max_width = 25600;

      const max_size = 300000;
      const max_height = 120;
      const max_width = 65;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Kb';
        this.showErrorMessage(this.imageError);

        return false;
      }

      var isAllowedType: boolean = false;
      allowed_types.forEach((type) => {
        if (type === fileInput.target.files[0].type) {
          isAllowedType = true;
        }
      });

      if (!isAllowedType) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        this.imageError = 'Only png images are allowed.';
        this.showErrorMessage(this.imageError);
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          // console.log(img_height, img_width);


          if (img_height > max_height && img_width > max_width) {
            this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
            this.showErrorMessage(this.imageError);
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            const indexOfComma = imgBase64Path.indexOf(',', 0);
            var logoString = this.cardImageBase64.slice(indexOfComma + 1);
            this.companyLogo = logoString;
            this.isImageSaved = true;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  uploadFavIcon(fileData: any) {
    this.imageError = null;
    if (fileData.target.files && fileData.target.files[0]) {
      const allowed_types = ['image/x-icon'];

      var isAllowedType: boolean = false;
      allowed_types.forEach((type) => {
        if (type === fileData.target.files[0].type) {
          isAllowedType = true;
        }
      });

      if (!isAllowedType) {
        this.imageError = 'Only ico images are allowed.';
        this.showErrorMessage(this.imageError);
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];

          const imgBase64Path = e.target.result;
          this.faviconBase64 = imgBase64Path;
          const indexOfComma = imgBase64Path.indexOf(',', 0);
          var favlogoString = this.faviconBase64.slice(indexOfComma + 1);
          this.companyFavicon = favlogoString;
          this.faviconBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(imgBase64Path);
          this.isFaviconSaved = true;
        };
      };
      reader.readAsDataURL(fileData.target.files[0]);
    }
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.companyLogo = null;
    this.isImageSaved = false;
    this.uploadElRef1.nativeElement.value = '';
  }

  removeFavIcon() {
    this.faviconBase64 = null;
    this.companyFavicon = null;
    this.isFaviconSaved = false;
    this.uploadElRef2.nativeElement.value = '';
  }

  private getCompany(companyId: string) {
    if (!companyId) {
      return;
    }


    this.loading = true;

    this.settingsService.getGeneralSettings(companyId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<SettingsGeneralResponseModel>) => {
        this.processResult<SettingsGeneralResponseModel>(result, () => {
          this.settingsGeneralResponseModel = result.responseModel;
          this.setValuesToForm();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onCancel() {
    this.buildForm;
    // add pagedRequestModel as state in navigation while redirecting to maintain state
    this.router.navigate(['/admin/general']);
  }

  onSubmit() {
    let dmeNumber = this.form.get(this.formFields.DMENumber).value.replaceAll('-','').replaceAll('_','');
    if(dmeNumber.length==0){
      this.form.get(this.formFields.DMENumber).setValue('');
    }

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const companyRequestModel = this.getValuesFromForm();
    const manageCompany = this.settingsService.updateGeneralSettings(companyRequestModel);

    this.loading = true;
    manageCompany.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.showSuccessMessage('Company is updated successfully');
          this.domainUrl = location.origin.replace("http://", "").replace("https://", "");
          this.authenticationService.getClinetDetailsCustom(this.domainUrl).pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
            .subscribe((result: ResultOfT<ConfigResponseModel>) => {
              this.processResult<ConfigResponseModel>(result, () => {
                if (result.isSuccess) {
                  if (result.responseModel) {
                    this.webStorageService.deleteClientConfig();
                    this.webStorageService.storeClientConfig(result.responseModel);
                    this.companyService.sendcompanyfaviconImage(result.responseModel.companyFavicon);
                    this.companyService.sendcompanylogoImage(result.responseModel.companyLogo);
                  }
                }
              });
            });
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): SettingsGeneralRequestModel {
    const form = this.form;
    var settingsGeneralRequestModel = new SettingsGeneralRequestModel();
    //settingsGeneralRequestModel = this.settingsGeneralResponseModel;
    settingsGeneralRequestModel.companyId = this.settingsGeneralResponseModel.companyId;
    settingsGeneralRequestModel.companyCode = this.settingsGeneralResponseModel.companyCode;
    settingsGeneralRequestModel.companyWebsite = this.settingsGeneralResponseModel.companyWebsite;
    settingsGeneralRequestModel.companyPhone = this.settingsGeneralResponseModel.companyPhone;
    settingsGeneralRequestModel.companyContactLastName = this.settingsGeneralResponseModel.companyContactLastName;
    settingsGeneralRequestModel.companyContactPhone = this.settingsGeneralResponseModel.companyContactPhone;
    settingsGeneralRequestModel.companyContactEmail = this.settingsGeneralResponseModel.companyContactEmail;
    settingsGeneralRequestModel.companyBillingAddress = this.settingsGeneralResponseModel.companyBillingAddress;
    settingsGeneralRequestModel.isActive = this.settingsGeneralResponseModel.isActive;
    settingsGeneralRequestModel.modifiedByUserInfoId = this.settingsGeneralResponseModel.modifiedByUserInfoId;
    settingsGeneralRequestModel.dateTimeCreated = this.settingsGeneralResponseModel.dateTimeCreated;
    settingsGeneralRequestModel.dateTimeLastModified = this.settingsGeneralResponseModel.dateTimeLastModified;
    settingsGeneralRequestModel.companyName = form.get(this.formFields.companyName).value;
    settingsGeneralRequestModel.companyContactFirstName = form.get(this.formFields.contactName).value;
    settingsGeneralRequestModel.companyAddress = form.get(this.formFields.address).value;
    settingsGeneralRequestModel.companyLogo = (this.companyLogo == null && !this.isImageSaved) ? this.companyLogo : (this.companyLogo != null && this.isImageSaved) ? this.companyLogo : this.settingsGeneralResponseModel.companyLogo;
    settingsGeneralRequestModel.companyFavicon = (this.companyFavicon == null && !this.isFaviconSaved) ? this.companyFavicon : (this.companyFavicon != null && this.isFaviconSaved) ? this.companyFavicon : this.settingsGeneralResponseModel.companyFavicon;

    var settingRequest = Array<SettingsRequestModel>();

    this.settingsGeneralResponseModel.settingsResponses.forEach(x => {
      var pushtoRequest = new SettingsRequestModel();
      if (x.schSettingKey == 'DMENumber') {
        x.schSettingValue = form.get(this.formFields.DMENumber).value;
      }
      if (x.schSettingKey == 'DMEEmail') {
        x.schSettingValue = form.get(this.formFields.DMEEmail).value;
      }
      pushtoRequest.schSettingId = parseInt(x.schSettingId);
      pushtoRequest.schSettingDisplayValue = x.schSettingDisplayValue;
      pushtoRequest.schSettingKey = x.schSettingKey;
      pushtoRequest.schSettingValue = x.schSettingValue;
      pushtoRequest.schSettingDescription = x.schSettingDescription;
      pushtoRequest.schSettingIsEnable = x.schSettingIsEnable == 'true' ? true : false;
      pushtoRequest.isActive = x.isActive == 'true' ? true : false;;
      pushtoRequest.modifiedByUserInfoId = parseInt(x.modifiedByUserInfoId);
      pushtoRequest.dateTimeCreated = moment(x.dateTimeCreated).toDate();
      pushtoRequest.dateTimeLastModified = moment(x.dateTimeLastModified).toDate();
      settingRequest.push(pushtoRequest);
    });
    settingsGeneralRequestModel.settingRequest = settingRequest;
    return settingsGeneralRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsGeneralResponseModel) {
      return;
    }

    const form = this.form;
    form.get(this.formFields.companyName).setValue(this.settingsGeneralResponseModel.companyName);
    form.get(this.formFields.contactName).setValue(this.settingsGeneralResponseModel.companyContactFirstName);
    form.get(this.formFields.address).setValue(this.settingsGeneralResponseModel.companyAddress);
    this.settingsGeneralResponseModel.settingsResponses.forEach(x => {
      if (x.schSettingKey == 'DMEEmail')
        form.get(this.formFields.DMEEmail).setValue(x.schSettingValue);
      if (x.schSettingKey == 'DMENumber')
        form.get(this.formFields.DMENumber).setValue(x.schSettingValue);
    })

    // this.cardImageBase64 = 'data:image/png;base64,' + this.settingsGeneralResponseModel.companyLogo;

    const image = new Image();
    image.src = 'data:image/png;base64,' + this.settingsGeneralResponseModel.companyLogo;
    image.onload = () => {
      const imgBase64Path = 'data:image/png;base64,' + this.settingsGeneralResponseModel.companyLogo;
      this.cardImageBase64 = imgBase64Path;
      const indexOfComma = imgBase64Path.indexOf(',', 0);
      this.companyLogo = logoString;
      var logoString = this.cardImageBase64.slice(indexOfComma + 1);
      this.isImageSaved = true;
    };

    const fvimage = new Image();
    fvimage.src = 'data:image/x-icon;base64,' + this.settingsGeneralResponseModel.companyFavicon;
    fvimage.onload = () => {
      const imgBase64Path = 'data:image/x-icon;base64,' + this.settingsGeneralResponseModel.companyFavicon;
      this.faviconBase64 = imgBase64Path;
      const indexOfComma = imgBase64Path.indexOf(',', 0);
      this.companyFavicon = logoString;
      var logoString = this.faviconBase64.slice(indexOfComma + 1);
      this.faviconBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(imgBase64Path);
      this.isFaviconSaved = true;
    };

  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.companyName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.contactName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.address, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.DMENumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.DMEEmail, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    this.form = formGroup;
  }

}
