export class RoleFeatureModulePermissionResponseModel {
  appRoleId: number;
  appRoleName: string;
  appModuleFeatureGroupId: number;
  appModuleFeatureGroup: string;
  appModuleFeatureId: number;
  appModuleFeatureName: string;
  appIsPermission: boolean
  appPermission: string;
  isAllFeatureGroup: boolean;
}

export class RoleFeatureModelPermission {
  appRoleId: number;
  appRoleName: string;
  canRoleDelete: boolean;
  moduleFeatureGroups: RoleFeatureModulePermissionResponseModel[]
}

export class KPIReportResponseModel {
  kpiReportId: number;
  kpiReportName: string;
  kpiReportDescription: string;
  kpiReportType: string;
  orgReportName: string;
  isKPIPermissionChecked: boolean
}