import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DayViewSchedulerComponent } from './day-view-scheduler.component';
import { TherapistDayViewSchedulerComponent } from './component';
import { SharedModule } from '../../../shared/shared.module';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import {AccordionModule} from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PipesModule } from 'src/app/pipes/pipes/pipes.module';
import { MatOption, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [TherapistDayViewSchedulerComponent, DayViewSchedulerComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    MultiSelectModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MenuModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    TooltipModule,
    AccordionModule,
    TabViewModule,
    MatTableModule,
    MatSortModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    InputSwitchModule,
    NgxMaterialTimepickerModule,
    SelectButtonModule,PipesModule,
    MatFormFieldModule
  ],
  exports: [TherapistDayViewSchedulerComponent, MultiSelectModule],
})
export class ViewAppointmentByTherapistModule { }
