export class ReassignTherapistAvailabilitySearchRequestModel {
  appointmentDate: string;
  crmNewTherapistId: number = 0;
  crmTherapistId: number;
  schAppointmentIds: number[];
  crmBranchIds: number[];
  isMakeUnAvailable: boolean;
  modifiedById: number;
  crmPatientTimeZone: string;
  crmFacilityTimeZone: string;
}
