import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
//ConfirmDate
import { isSameDay, isSameMonth, isBefore } from 'date-fns';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject, Subscription, interval, Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/admin/servicies/notification.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { AppoinmentDialogueParametersObject, TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { FacilityTimeSlotRequest } from 'src/app/shared/models/request/facility-slots-request';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment/appointment-confirmation';
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { DisplaySlots, DisplaySlotsTime, Months } from 'src/app/shared/models/response/appointment/display-facility-slots';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { FacilityAvailableDays } from 'src/app/shared/models/response/appointment/facility-available-days';
import { FacilitySearchResponseModel, FacilityTimeSlotResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { FacilityTherapistAvalabilityModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-model';
import { FacilityTherapistAvalabilityRequestModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-request-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { displayDistanceMatrixData, GoogleMapDistanceMatrixResponse, PatientOriginData, RoadShowTimeSlotMainResponseModel, RoadShowTimeSlotResponseModel, RoadShowTimeSlotsResponseModel, RoadShowTimeSlotTherapistModel } from 'src/app/shared/models/response/appointment/roadshow-timeslot-main-response-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SettingsResponseModel, AdminCustomSettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
//import { HeaderComponent } from 'src/app/shared/header/header.component';
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ResultOfT } from '../../shared/models/response/result';
import { AppointmentService } from '../servicies/appointment.service';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { TwillioCallService } from '../servicies/twillioCall.service';
import { SettingsService } from '../servicies/settings.service';
import { WipStatusBTmapping } from '../../shared/models/common/wip-status';
declare const Twilio: any;
import { ReminderSettingResponseModel } from 'src/app/shared/models/response/settings/settings-reminder-model'
import { SalesOrderInBTResponse } from 'src/app/shared/models/response/sales-order-bt-response';
import { AppointmentConfirmationInfo } from 'src/app/shared/models/response/appointment/appointment-confirmation-info';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { AppointmentHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-history-response-model'
import { AddressUpdateRequestModel } from 'src/app/shared/models/request/address-update-request-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { AppointmentFacilityResponseModel } from 'src/app/shared/models/response/appointment/appointment-facility-response-model';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { AppointmentEmailHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-emalhistory-response-model';
import { AppointmentSMSHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-smshistory-response-model';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { InvetoryLocationResponseModel } from '../../shared/models/response/invetory-location-response-model';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { ASCDESCString, SortOrder } from 'src/app/shared/enums';
import { MapInfoWindow, MapMarker, MapDirectionsService } from '@angular/google-maps';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { RoadHomeVisitHistoryRequestModel } from 'src/app/shared/models/request/roadHomeVisitHistory-request';
import { Device } from '@twilio/voice-sdk';
import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };

@Component({
  selector: 'app-book-appointment-info',
  templateUrl: './book-appointment-info.component.html',
  styleUrls: ['./book-appointment-info.component.sass'],
  providers: [DialogService]
})
export class BookAppointmentInfoComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  connection: any;
  callSID: string;
  data1: any;
  device: any;
  isCallStarted: boolean = false;
  isDrLiepertOnly: boolean = false;
  isDrFacilityVisitOnly: boolean = false;
  isDrRemoteVisitOnly: boolean = false;
  isDrRoadHomeVisitOnly: boolean = false;
  callConfigModel: CallConfigModel;
  salesOrder: SalesOrderInfoResponseModel;
  selectedTab: number;
  isAppointment: boolean;
  isTelehealth: boolean;
  duration: string;
  scheduledApptDuration: string;
  appDateFormat: DateFormatModel;
  salesOrderId: number;
  schAppointmentId: number;
  selectedAppointmentTypePopUp: string = "";
  actionType: string = "S";
  appointment: AppointmentInfoReponseModel;
  EmailEditStatus: boolean = false;
  PhoneEditStatus: boolean = false;
  MobileEditStatus: boolean = false;
  crmIsAppoinment: boolean = false;
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  selectedContactNumber: string = "";
  subscription: Subscription;
  indexActionTab: number = 0;
  formCancelSubmitted: boolean = false;
  formAppointmentCallSubmitted: boolean = false;
  formEmailSubmitted: boolean = false;
  formPhoneNumberSubmitted: boolean = false;
  formVoidAppointmentSubmitted: boolean = false;
  formDeliveryAddressSubmitted: boolean = false;
  schedulerResponseModel: SchedulerResponseModel[];
  remoteAppointmentTypeModel: RemoteAppointmentTypeModel[];
  appointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel[];
  appointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel[];
  firstAvailabilityDate: Date;
  maxMonthAvailability: Date;
  minAvailabilityDate: Date;
  crmSalesorderOrderNote: string;
  crmSalesorderDeliveryNote: string;
  isSchedulecallBack: boolean = false;
  displayPhoneCallLog: boolean = false;
  displayVoiceCallLog: boolean = false;
  previousCallbackDate: Date;
  currentDate: Date;
  currentTime: string;
  callnote: string;
  calldate: Date;
  calltime: string;
  callId: number;
  smsTextNumber: string;
  selectedRemoteAppointmentType: string;
  customStartTime: string;
  isValid: boolean = false;
  loggedInUserId: number;
  reminderFrequency: string;
  remindersModel: ReminderSettingResponseModel[];
  //Confirm Date Screen Code
  monthNames: string[];
  monthNamesNewFormat: string[];
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
  apptDuration: { name: string; code: number; }[];
  selectedApptDuration: { name: string; code: number; };
  selfGuidedapptDuration: { name: string; code: number; }[];
  selectedselfGuidedApptDuration: { name: string; code: number; };
  selectedFacility: FacilitySearchResponseModel[] = [];
  selectedFacilityLocal: FacilitySearchResponseModel;
  reminderDays: string;
  monthsToShow: number;
  daysToShow: number;
  selectedMonth: Months;
  previousSelectedMonth: Months;
  facilitySlotDate: Date = new Date();
  showPrev: boolean = false;
  showNext: boolean = true;
  crmFacilityDistanceInMile: string;
  crmFacilityAddress: string;
  crmIsDoctor: boolean = false;
  crmFacilityWeekDaysAvailability: string;
  crmFacilityWeekendAvailability: string;
  facilityAvailableWeekDays: FacilityAvailableDays[];
  facilityAvailableWeekend: FacilityAvailableDays[];
  selectedFacilityWeekDayMinDate: FacilityAvailableDays;
  selectedFacilityWeekDayMaxDate: FacilityAvailableDays;
  selectedFacilityWeekendMinDate: FacilityAvailableDays;
  selectedFacilityWeekendMaxDate: FacilityAvailableDays;
  facilityTherapistAvalabilityModel: FacilityTherapistAvalabilityModel[];
  filteredTherapist: FacilityTherapistAvalabilityModel[];
  selectedTherapist: FacilityTherapistAvalabilityModel;
  //selectedTherapistAvalabilityModel: FacilityTherapistAvalabilityModel;
  isRescheduledRequest: boolean = false;
  isInProgresscall: boolean = false;

  today: Date;
  displaySlots: DisplaySlots[] = [];
  facilityTherapistAvalabilityRequestModel: FacilityTherapistAvalabilityRequestModel;
  facilitySlotRequest: FacilityTimeSlotRequest;
  facilitySlotResponse: FacilitySearchResponseModel;
  facilitySlotForFilter: FacilityTimeSlotResponseModel[];
  facilitySlotFiltered: FacilityTimeSlotResponseModel[];
  displaySlotsMobile: DisplaySlots[] = [];
  displaySlot: DisplaySlots;
  displaySlotsTime: DisplaySlotsTime;
  prevdisplaySlotsTime: DisplaySlotsTime;
  prevdisplayDate: DisplaySlots;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedOrigStartDate: Date;
  selectedOrigEndDate: Date;
  selectedTherapistId: number;
  selectedTherapistName: string;
  selectedtherapistBasedTimeZone: string;
  selectedPractionerId: number;
  selectedDeliveryTechnicianId: number;
  showConfirm: boolean = false;
  facilityId: Number;
  months: Months[] = [];
  orginalmonths: Months[] = [];
  month: Months;
  loadingCustom: boolean;
  loadingCancel: boolean;
  loadingVoid: boolean;
  loadingDeliveryAddress: boolean;
  isClosable: boolean = true;
  reminderOpted: boolean = false;
  OpenCheck: boolean = false;
  ConfirmAppointmentPopup: boolean = false;
  appointmentRequest: AppointmentRequestModel;
  appointmentRequestBT: BTSalesOrderUpdateRequestModel;
  appointmentConfirmation: AppointmentConfirmation;
  appointmentConfirmationInfo: AppointmentConfirmationInfo;
  isAppointmentTypeAvailable: boolean = true;
  islotAvailable: boolean = true;
  islotAvailableForTheDay: boolean = true;
  isDeliveryAddressAvailable: boolean = true;
  appointmentCallResponseModel: AppointmentCallResponseModel;
  scheduleActionePopUp: string;
  crmSalesOrderId: number;
  isVoidOrder: false;
  isCancelOrder: false;
  isCall: false;
  isScheduleOrder: false;
  isAppoinmentBookByCalendar: false;
  isRemoteAndFacility: boolean = false;
  remoteFacilityId: number;
  isFacilityselected: boolean = false;
  salesOrderdate: Date;
  crmTherapsitId: number;
  crmTherapsitName: string;
  crmPractionerId: number;
  crmDeliveryTechnicianId: number;
  minimumDate = new Date();
  isAppointmentAppoinmentPage: boolean;
  isAppointmentAppoinmentDetailPage: boolean;
  isAppointmentSalesOrderDetailPage: boolean;
  isAppointmentAppoinmentLockPage: boolean;
  isTwoNumberExist: boolean;
  isPhoneNumberExist: boolean;
  reschduleDate: Date;
  selectedRoadShowDate: Date;
  roadShowTimeSlotMainResponseModel: RoadShowTimeSlotMainResponseModel;
  roadShowTimeSlotsResponseModel: RoadShowTimeSlotsResponseModel[];
  selectedRoadShowTimeSlotTherapistModel: RoadShowTimeSlotTherapistModel[];
  selectedAppointmentSlot: RoadShowTimeSlotResponseModel;
  BlockDescription: any;
  BlockedBy: any;
  voidFrom: string;
  features: featureResponseModel[];
  isSalesOrderUpdated: boolean = false;
  statsModel: StateMasterResponseModel[];
  selectedState: StateMasterResponseModel;
  selectedDateSlot: Date;
  isSession: boolean = true;
  sessionObject: { isSession: boolean, isUpdated: boolean, appointmentDate: Date };
  crmFirstAvailableStartDate: Date;
  isUpdateDelieryAddressToBrightree: boolean = false;
  isLicenseCheckEnabled: boolean = false;
  // Added Paramater for new Pop up function
  OpenCheckOld: boolean = false;
  ScheduledDateStatus: boolean;
  ScheduledTimeStatus: boolean;
  WIPStateStatus: any;
  WIPDateNeededStatus: boolean;
  PractitionerIDStatus: boolean;
  PractitionerNameStatus: any;
  OrderNoteStatus: boolean;
  DeliveryNoteStatus: boolean;
  value: Date;
  customStartDate: Date;
  deliveryNoteOverwrite: boolean = true;
  orderNoteOverwrite: boolean = true;
  loadingEmail: boolean;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  reasonsResponseModel: CancelVoidResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  selectedwipStatusBTMappingResponseModel: WipStatusBTmapping;
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];
  brightreeEmailAddress: boolean;
  brightreePhone1: boolean;
  brightreePhone2: boolean;
  displayOrderNote: boolean;
  displayDeliveryNote: boolean;
  patientDetials: boolean;
  patientdetailsEdit: boolean;
  patientdetialsDeliveryNote: boolean;
  patientdetialsEditOrderNone: any;
  currentAppointmentDetailsBox: boolean;
  displayScheduleCallBack: boolean;
  crmRediretFrom: string;
  visibleSidebar1;
  displayCancelAppointment: boolean;
  displayVoidAppointment: boolean;
  displayDeliveryAddress: boolean;
  confirmDetialsPage: boolean;
  isBrightreeSystemAppUpdate: boolean = false;
  isBillingAddress: boolean = false;
  defaultWIPNeededDate: string;
  isDefaultWIPNeededDateAppointmentdate: boolean = false;
  twillioDeviceConnectionObject: TwilioDeviceObject;
  appointmentFacilityResponseModel: AppointmentFacilityResponseModel;
  isMedicareinsurance: boolean;
  isInsuranceStateNotFound: boolean;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  smsdisplay: boolean;
  emaildisplay: boolean;
  isInitial: boolean = false;
  isGlobalHoliday: boolean = false;
  globalHolidayMsg: string;
  isAvailableSlot: boolean = true;
  brightreeInternalUserId: number;
  WIPStateAssignmentType: string;
  UpdateWipStateOnRescheduled: boolean = false;
  InvetoryLocationOptions: InvetoryLocationResponseModel[];
  crmInvetoryLocationName: string;
  patientTimezone: string;
  voiceCallToNumber: string;
  defaultBufferTime: string;
  voiceMailNumber: string;
  isBrightreeSystemAppUpdateVoidCancel: Boolean = false;
  isBrightreeSystemAppUpdateVoidCancelSetting: Boolean = false;
  //timeZoneList: TimeZoneAvailableResponseModel[];
  //_selectedTimeZone: TimeZoneAvailableResponseModel;
  _schTimezone: string;
  recordingCallSid: string;
  recordingCallUrl: string;
  CallCounter: number = 0;
  CallCounterMax: number = 10;
  IsCallHnageByDme: boolean = false;
  minAllowDateToBookAppoitment: Date = new Date();
  maxAllowDateToBookAppoitment: Date = new Date();
  isProvisional: boolean;
  patientsDefaultAppointmentLocation: string;
  previousPatientsDefaultAppointmentLocation: string;
  crmSalesorderClassificationType: number;
  isInsuranceStateFound: boolean;
  defaultModeofAppointment: string;
  isAscendingSort: boolean = false;
  sortingTooltip: string = 'Maximum booked appointments will be shown first in the list';
  slotSortOrder: string = "DESC"
  isPODStatusBrightreeSystemAppUpdate: boolean = false;
  isAppendOrderNoteBrightreeSystemAppUpdate: boolean = false;
  distanceMatrixResponse: GoogleMapDistanceMatrixResponse;
  displaydistanceMatrixData: displayDistanceMatrixData;
  displayData = [];
  therapistInfo: UserInfoResponseModel;
  display: any;
  loading: boolean = false;
  center: google.maps.LatLngLiteral;
  circleCenter: google.maps.LatLngLiteral;
  radius: number;
  markers = [];
  infoContent = ''
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 19,
    minZoom: 8,
  };
  directionsResults: Observable<google.maps.DirectionsResult | undefined>;

  rendererOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
    //suppressInfoWindows: true,
    // markerOptions: {
    //   draggable: false,
    //   clickable: true,
    //   animation: google.maps.Animation.DROP,
    // },
    // polylineOptions:{
    //   strokeColor: '#9966ff',
    //   strokeOpacity: 0,
    //   icons: [{
    //     icon: {
    //       path: google.maps.SymbolPath.CIRCLE,
    //       fillOpacity: 1,
    //       scale: 3
    //     },
    //   offset: '0',
    //   repeat: '1px'
    //   }],
    // }
  };
  circleOption: google.maps.CircleOptions = {
    strokeWeight: 1,
    strokeColor: "#ff00005e",
    fillColor: '#9280eb',
    fillOpacity: 0.1,
  }
  infoWindowOptions: google.maps.InfoWindowOptions = {
    maxWidth: 245
  }
  zoom = 19;
  selectedPatientLatLong: string;
  patientDistance: string;
  patientDuration: string;
  roadHomeVisitRequest: RoadHomeVisitHistoryRequestModel[];
  startLocationPatient: string = '';
  endLocationPatient: string = '';
  nextLocationPatient: string = '';
  startLocationAddress: string = '';
  endLocationAddress: string = '';
  nextLocationAddress: string = '';
  selectedTherapistCoverageArea: number;
  isShowLocationInfo: boolean = false;
  isGoogleMap: boolean = false;
  schReminderOpted: boolean = false;
  schIsReminderOptedSms: boolean = false;
  schIsReminderOptedEmail: boolean = false;
  hasCallbackDatePassed: boolean = false;
  SMSSidebarDisplay: boolean = false;
  EmailSidebarDisplay: boolean = false;
  updateStatusforminfo: statusInfo;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
    private webStorageService: WebStorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private userService: UserService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private mapDirectionsService: MapDirectionsService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.appointmentSMSHistoryResponseModel = new Array();
    this.appointmentEmailHistoryResponseModel = new Array();
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.selectedRoadShowDate = moment(new Date()).toDate();
    this.isClosable = config.closable;
    this.appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    this.setPermissionforActions();

    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
    this.isGoogleMap = provisionalSalesOrder.isGoogleMap;

    this.getCallConfig();
    this.getSettingOnInit();
    //this.getDeviceConnectionForCall();
    ref.onClose.subscribe(() => {
      this.onHide();
    });

    //this.timeZoneList = this.webStorageService.getTimeZoneList();
  }

  close() {
    this.sessionObject.isSession = this.isSession;
    this.sessionObject.appointmentDate = this.isRescheduledRequest ? this.reschduleDate : null;
    this.sessionObject.isUpdated = this.isSalesOrderUpdated;
    this.ref.close(this.sessionObject);
    //this.ref.close(this.crmIsAppoinment);
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  CloseDialogue() {

    this.sessionObject.isSession = this.isSession;
    this.sessionObject.appointmentDate = this.isRescheduledRequest ? this.reschduleDate : null;
    this.sessionObject.isUpdated = this.isSalesOrderUpdated;
    if (this.isCallStarted) {
      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'Voice calls in progress. Do you want to hang up the call?',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          return;
        }
        this.HangupClose();
        this.ref.close(this.sessionObject);
      });
    }
    else
      this.ref.close(this.sessionObject);


  }

  async appointmentBookedHandler() {
    this.confirmDetialsPage = false;
    this.getDeviceConnectionForCall();
    this.CloseDialogue();
  }



  async ngOnInit() {
    this.sessionObject = {
      isSession: this.webStorageService.getIsSession(),
      isUpdated: false,
      appointmentDate: null
    };
    this.subscription = interval(1000)
      .subscribe((x) => {
        if (!this.webStorageService.getIsSession()) {
          this.ref.close(this.sessionObject);
          this.subscription.unsubscribe();
        }
      });
    this.isInitial = true;
    this.buildForm();
    this.initializeAllDefaltModel();
    this.isTelehealth = false;


    await this.getStates();
    await this.getWipStatusBTmapping();

    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.appointmentDetailInfo.facilityInfo = new FacilityInfoResponseModel();
    this.crmSalesOrderId = this.config.data.crmSalesOrderId;
    this.isAppointment = this.config.data.isAppointment;
    this.isRescheduledRequest = this.config.data.isRescheduledRequest;
    this.isInProgresscall = this.config.data.isInProgresscall;
    this.schAppointmentId = this.config.data.schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    this.isVoidOrder = this.config.data.isVoidOrder;
    this.isCancelOrder = this.config.data.isCancelOrder;
    this.isCall = this.config.data.isCall;
    this.isScheduleOrder = this.config.data.isScheduleOrder;
    this.isAppoinmentBookByCalendar = this.config.data.isAppoinmentBookByCalendar;
    this.isRemoteAndFacility = this.config.data.isRemoteAndFacility;
    this.isFacilityselected = this.config.data.isFacilityselected;
    this.remoteFacilityId = this.config.data.remoteFacilityId;
    this.crmTherapsitId = this.config.data.crmTherapsitId;
    this.crmPractionerId = this.config.data.crmPractionerId;
    this.crmDeliveryTechnicianId = this.config.data.crmDeliveryTechnicianId;
    this.salesOrderdate = this.config.data.salesOrderdate;
    this.crmTherapsitName = this.config.data.crmTherapsitName;
    this.isAppointmentAppoinmentPage = this.config.data.isAppointmentAppoinmentPage;
    this.isAppointmentAppoinmentDetailPage = this.config.data.isAppointmentAppoinmentDetailPage;
    this.isAppointmentSalesOrderDetailPage = this.config.data.isAppointmentSalesOrderDetailPage;
    this.isAppointmentAppoinmentLockPage = this.config.data.isAppointmentAppoinmentLockPage;
    this.defaultModeofAppointment = this.config.data.defaultModeofAppointment;

    if (this.isAppoinmentBookByCalendar && this.isRemoteAndFacility) {
      this.selectedAppointmentTypePopUp = 'Telehealth';
    }
    else if (this.isAppoinmentBookByCalendar && this.isFacilityselected) {
      this.selectedAppointmentTypePopUp = 'Facility Visit';
    }

    if (this.isAppoinmentBookByCalendar) {
      this.facilityId = this.config.data.facilityId;
      this.selectedPractionerId = this.crmPractionerId;
      this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
    }

    if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'remotesetup') {
      this.selectedAppointmentTypePopUp = 'Telehealth';
    }
    else if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'facilityvisit') {
      this.selectedAppointmentTypePopUp = 'Facility Visit';
    }
    else if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'roadhomevisit') {
      this.selectedAppointmentTypePopUp = 'Home Visit';
    }
    // else
    //   this.selectedAppointmentTypePopUp = "Telehealth";



    if (this.isAppointmentAppoinmentPage)
      this.crmRediretFrom = "Appointments";
    else if (this.isAppointmentAppoinmentDetailPage)
      this.crmRediretFrom = "Appoinment Detail";
    else if (this.isAppointmentSalesOrderDetailPage)
      this.crmRediretFrom = "Sales Order Detail";
    else if (this.isAppointmentAppoinmentLockPage)
      this.crmRediretFrom = "Assigned Calls";
    else
      this.crmRediretFrom = "Calendar";

    this.voidFrom = (this.config.data.voidFrom == "" || this.config.data.voidFrom == null) ? 'A' : this.config.data.voidFrom;


    if (this.isRescheduledRequest) {
      this.getAppointmentInfo(this.schAppointmentId)
    }
    else {
      setTimeout(() => {
        this.getSalesOrderInfo(this.crmSalesOrderId);
      }, 1500);

    }





    if (this.isInProgresscall) {
      this.manageAlreadyStratedcall(this.config.data.connection, this.config.data.voiceCallToNumber, this.config.data.device, this.config.data.voiceMailNumber)
    }
  }



  private async getSettingOnInit() {
    this.loading = true;
    await this.settingsService.GetAllSettingsBookAppointmentOnInit()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.setInvetoryLocation(result.responseModel.invetoryResponse);
              this.setRemoteAppintmentTypes(result.responseModel.remoteApointmentTypesResponse);
              this.setCancelVoidReasons(result.responseModel.cancelVoidReasonsResponse);

              this.setRCMConfigurationUrl(result.responseModel.rcmConfigurationUrlResponse);
              this.SetDefaultsettings(result.responseModel.adminSetingResponse);

            }
          }
          this.loading = false;
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private SetDefaultsettings(settingsResponseModel: AdminCustomSettingsResponseModel) {

    if (settingsResponseModel != undefined && settingsResponseModel != null) {
      this.daysToShow = settingsResponseModel.numOfMonths;
      this.monthsToShow = this.daysToShow / 30;
      this.maxAllowDateToBookAppoitment.setDate(this.maxAllowDateToBookAppoitment.getDate() + this.daysToShow);
      this.isBrightreeSystemAppUpdate = settingsResponseModel.allowUpdateIntoBTOnConfirmationScreen;
      this.isPODStatusBrightreeSystemAppUpdate = settingsResponseModel.allowUpdatePODStatusToBrightTree;
      this.isAppendOrderNoteBrightreeSystemAppUpdate = settingsResponseModel.allowAppendOrderNoteToBrightTree;
      this.isBillingAddress = settingsResponseModel.isBillingAddress;

      if (this.isBillingAddress) {
        this.patientsDefaultAppointmentLocation = this.previousPatientsDefaultAppointmentLocation = "BillingAddress";
      }
      else
        this.patientsDefaultAppointmentLocation = this.previousPatientsDefaultAppointmentLocation = "DeliveryAddress";



      this.isUpdateDelieryAddressToBrightree = settingsResponseModel.allowDMEtoUpdateDeliveryAddress;
      this.isLicenseCheckEnabled = settingsResponseModel.checkTherapistStateLicense;
      this.isDefaultWIPNeededDateAppointmentdate = settingsResponseModel.isDefaultWIPNeededDateAppointmentdate;
      this.WIPStateAssignmentType = settingsResponseModel.wIPStateAssignmentType;
      this.UpdateWipStateOnRescheduled = settingsResponseModel.updateWipStateOnRescheduled;
      this.defaultBufferTime = settingsResponseModel.defaultBufferTime;
    }
  }


  private setInvetoryLocation(_lstInvetoryLocationResponseModel: InvetoryLocationResponseModel[]) {

    if (_lstInvetoryLocationResponseModel && _lstInvetoryLocationResponseModel.length > 0) {
      this.InvetoryLocationOptions = _lstInvetoryLocationResponseModel;
    }
  }

  private setSettingsForWIPStateAssignmentType(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.WIPStateAssignmentType = _SettingsResponseModel.schSettingValue;
    }
  }

  private setSettingsForUpdateWipStateOnRescheduled(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.UpdateWipStateOnRescheduled = _SettingsResponseModel.schSettingValue.toLowerCase() == 'true';
    }
  }


  private setReminderDays(_SettingsResponseModel: SettingsResponseModel) {


    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.reminderDays = _SettingsResponseModel.schSettingValue;
    }
  }

  private setRemoteAppintmentTypes(_lstRemoteAppointmentType: RemoteAppointmentTypeModel[]) {

    if (_lstRemoteAppointmentType && _lstRemoteAppointmentType.length > 0) {
      this.remoteAppointmentTypeModel = _lstRemoteAppointmentType;
    }
  }

  private setgetNumberOfMonths(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.daysToShow = parseInt(_SettingsResponseModel.schSettingValue, 10);
      this.monthsToShow = this.daysToShow / 30;
      this.maxAllowDateToBookAppoitment.setDate(this.maxAllowDateToBookAppoitment.getDate() + this.daysToShow);
    }
  }

  private setRCMConfigurationUrl(_RCMCofigurationUrl: RCMCofigurationUrl) {

    if (_RCMCofigurationUrl != undefined && _RCMCofigurationUrl != null) {
      let jsonData = _RCMCofigurationUrl;
      this.patientBrighttreeURL = jsonData.patientRCMURL;
      this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
    }
  }

  private setSettings(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.isBrightreeSystemAppUpdate = _SettingsResponseModel.schSettingValue.toLowerCase() == 'true';
    }
  }

  private setSettingsForDeliveryAddress(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.patientsDefaultAppointmentLocation = _SettingsResponseModel.schSettingValue;
      this.previousPatientsDefaultAppointmentLocation = _SettingsResponseModel.schSettingValue;
      if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress')
        this.isBillingAddress = true;
      else
        this.isBillingAddress = false;
    }
  }

  private setSettingsForUpdateDeliveryAddressInBT(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.isUpdateDelieryAddressToBrightree = _SettingsResponseModel.schSettingValue.toLowerCase() == 'true';
    }
  }

  private setSettingsLicenseCheckEnabled(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.isLicenseCheckEnabled = _SettingsResponseModel.schSettingValue.toLowerCase() == 'true';
    }
  }

  private setSettingsForDefaulWipNeededDate(_SettingsResponseModel: SettingsResponseModel) {

    if (_SettingsResponseModel != undefined && _SettingsResponseModel != null) {
      this.defaultWIPNeededDate = _SettingsResponseModel.schSettingValue;
      if (this.defaultWIPNeededDate.toLowerCase() == Constants.SettingValues.appointmentdate)
        this.isDefaultWIPNeededDateAppointmentdate = true;
      else
        this.isDefaultWIPNeededDateAppointmentdate = false;
    }
  }

  private setCancelVoidReasons(_lstCancelVoidResponse: CancelVoidResponseModel[]) {
    if (_lstCancelVoidResponse && _lstCancelVoidResponse.length > 0) {
      this.reasonsResponseModel = _lstCancelVoidResponse;
      const myClonedArray = [];
      this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
      this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
      this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Cancel');
    }
  }
  private async getInvetoryLocation() {
    this.loading = true;
    await this.settingsService.getInvetoryLocation()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<InvetoryLocationResponseModel[]>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.InvetoryLocationOptions = result.responseModel;
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setInventoryLocationName(_crmInvetoryLocationId: number) {
    if (Number(_crmInvetoryLocationId) > 0) {
      let InvetoryLocationModel: InvetoryLocationResponseModel;

      if (this.InvetoryLocationOptions != null && this.InvetoryLocationOptions != undefined) {
        InvetoryLocationModel = this.InvetoryLocationOptions.find(x => x.invetoryLocationId === Number(_crmInvetoryLocationId));
        this.crmInvetoryLocationName = InvetoryLocationModel ? InvetoryLocationModel.invetoryLocationName : '';
      }
      else {
        this.crmInvetoryLocationName = "";
      }
    }
    else {
      this.crmInvetoryLocationName = "";
    }
  }

  public async getStates() {
    this.progress.next(true);

    await this.appointmentService.GetStates()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<StateMasterResponseModel[]>) => {
        this.processResult<StateMasterResponseModel[]>(result, () => {
          if (!result) {
            return;
          }
          if (result.responseModel != undefined && result.responseModel != null) {
            this.statsModel = result.responseModel;
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getCallConfig() {
    this.loading = true;
    await this.settingsService.getCallConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<CallConfigModel>) => {
        this.processResult<any>(result, async () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.callConfigModel = result.responseModel;
              if (this.callConfigModel) {
                await this.getDeviceConnectionForCall();
              }
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  async getDeviceConnectionForCall() {
    this.loadingCustom = true;

    const deviceOptions = {
      edge: 'ashburn',
      enableRingingState: true,
      fakeLocalDTMF: true,
      debug: true,
      tokenRefreshMs: 3000000
    }


    if (this.callConfigModel != undefined) {
      await this.http.get(this.callConfigModel.twiMLFunctionURL).subscribe((data) => {
        this.data1 = data;
        if (this.data1) {
          this.loadingCustom = false
        }
        const device = new Device(this.data1.token, deviceOptions);
        device.on('ready', function (device) {
          console.log('Twilio.Device Ready!');
        });
        device.on('error', function (error) {
          console.log('Twilio.Device Error: ' + error.message);
        });
        device.on('connect', function (conn) {
          console.log('Successfully established call!');
        });
        device.on('disconnect', function (conn) {
          console.log('Call ended.');
        });
        device.on('ringing', function (conn) {
          console.log('still ringing...');
        });

      });
    }

  }


  convertUTCToTimezone(utcDt, utcDtFormat, timezone) {
    return moment.utc(utcDt, utcDtFormat).tz(timezone);
  }
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }


  initializeAllDefaltModel() {

    this.monthNames = new Array(12);
    this.monthNames[0] = "JAN";
    this.monthNames[1] = "FEB";
    this.monthNames[2] = "MAR";
    this.monthNames[3] = "APR";
    this.monthNames[4] = "MAY";
    this.monthNames[5] = "JUN";
    this.monthNames[6] = "JUL";
    this.monthNames[7] = "AUG";
    this.monthNames[8] = "SEP";
    this.monthNames[9] = "OCT";
    this.monthNames[10] = "NOV";
    this.monthNames[11] = "DEC";

    this.monthNamesNewFormat = new Array(12);
    this.monthNamesNewFormat[1] = "JAN";
    this.monthNamesNewFormat[2] = "FEB";
    this.monthNamesNewFormat[3] = "MAR";
    this.monthNamesNewFormat[4] = "APR";
    this.monthNamesNewFormat[5] = "MAY";
    this.monthNamesNewFormat[6] = "JUN";
    this.monthNamesNewFormat[7] = "JUL";
    this.monthNamesNewFormat[8] = "AUG";
    this.monthNamesNewFormat[9] = "SEP";
    this.monthNamesNewFormat[10] = "OCT";
    this.monthNamesNewFormat[11] = "NOV";
    this.monthNamesNewFormat[12] = "DEC";


    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);

    this.apptDuration = [
      { name: '15 Minutes', code: 15 },
      { name: '30 Minutes', code: 30 },
      { name: '45 Minutes', code: 45 },
      { name: '1 Hour', code: 60 },
      { name: '1 Hour and 15 Minutes', code: 75 },
      { name: '1 Hour and 30 Minutes', code: 90 },
      { name: '1 Hour and 45 Minutes', code: 105 },
      { name: '2 Hours', code: 120 },
    ];


    this.selfGuidedapptDuration = [
      { name: '15 Minutes', code: 15 },
      { name: '30 Minutes', code: 30 },

    ];

  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private getSalesOrderInfo(SalesOrderId) {

    this.loading = true;
    this.progress.next(true);
    this.appointmentService.GetSalesOrderById(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrder = result.responseModel;
              this.isTelehealth = result.responseModel.crmIsTelehealth;
              this.duration = this.salesOrder.crmClassificationDuration == 1 || this.salesOrder.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrder.crmClassificationDuration.toString() + " Minutes";


              if (this.salesOrder.crmDoctorId != undefined && this.salesOrder.crmDoctorId != null) {
                if (this.salesOrder.crmIsFacilityVisitSelected)
                  this.isDrFacilityVisitOnly = true;

                if (this.salesOrder.crmIsRemoteVisitSelected)
                  this.isDrRemoteVisitOnly = true;

                if (this.salesOrder.crmIsRoadHomeVisitSelected)
                  this.isDrRoadHomeVisitOnly = true;

                this.SetDefaultModeofAppointmentBasedOnDoctorAvailablity();
              }
              else {
                this.isDrFacilityVisitOnly = true;
                this.isDrRemoteVisitOnly = true;
                this.isDrRoadHomeVisitOnly = true;
              }


              if (this.apptDuration != null && this.apptDuration != undefined) {
                this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmClassificationDuration);
              }

              this.crmSalesorderOrderNote = this.salesOrder.crmSalesorderOrderNote;
              this.crmSalesorderDeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
              this.crmSalesorderClassificationType = this.salesOrder.crmSalesorderClassificationType;

              if (this.salesOrder.calllogs != null && this.salesOrder.calllogs != undefined) {
                let callbackDetails = this.salesOrder.calllogs.filter(x => x.schNextCallDueDate != null);
                if (callbackDetails != null && callbackDetails != undefined && callbackDetails.length > 0) {
                  this.isSchedulecallBack = true;
                  this.previousCallbackDate = callbackDetails[0].schNextCallDueDate;
                  this._schTimezone = callbackDetails[0].schTimeZone;
                  if (this.previousCallbackDate != undefined) {
                    let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
                    let currentDate = moment(dateCurrent).toDate();
                    let targetDate = new Date(this.previousCallbackDate);
                    this.hasCallbackDatePassed = targetDate < currentDate;
                  }
                  else {
                    this.hasCallbackDatePassed = false;
                  }
                }
              }

              this.setInventoryLocationName(this.salesOrder ? Number(this.salesOrder.crmInvetoryLocationId) : 0);

              if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "";
              }
              if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }

              if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }

              if (!this.salesOrder.patientInfo.crmPatientPhoneNo && !this.salesOrder.patientInfo.crmPatientMobileNumber)
                this.isPhoneNumberExist = false;
              else
                this.isPhoneNumberExist = true;

              if (this.salesOrder.patientInfo.crmPatientPhoneNo && this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.isTwoNumberExist = true;

              }
              else {
                this.isTwoNumberExist = false;
              }

              if (this.salesOrder.patientInfo.crmPatientMobileNumber)

                this.smsTextNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              else
                this.smsTextNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;

              this.isRescheduledRequest = false;
              this.schAppointmentId = 0;

              if (this.isCall) {
                setTimeout(() => {
                  if (this.salesOrder.patientInfo.crmPatientMobileNumber)
                    this.Phonecall(this.salesOrder.patientInfo.crmPatientMobileNumber, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName);
                  else
                    this.Phonecall(this.salesOrder.patientInfo.crmPatientPhoneNo, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName);
                }, 1000);
              }

              if (this.isAppoinmentBookByCalendar) {
                this.BookSlotFromCalendar();
              }
              else {
                this.facilityId = 0;
                this.handleFacilityVisitOptions();
              }

              this.SetPatientTimezone();
              this.setOnLoadPatientMarker();
              //this._cdr.detectChanges();
            }
          }
          this.loading = false;
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private BookSlotFromCalendar() {
    this.loading = true;

    this.appointmentService.GetTherapistAvailabilityByDate(this.salesOrderdate.toDateString(), this.crmTherapsitId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SchedulerResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.schedulerResponseModel = result.responseModel;
              let format = 'hh:mm:ss'
              for (let value of this.schedulerResponseModel) {
                let startDate = moment(value.start).toDate();
                let endDate = moment(value.end).toDate();
                let beforeTime = moment(startDate, format);
                let afterTime = moment(endDate, format);
                let time = moment(this.salesOrderdate, format);
                let isInBtween = time.isBetween(beforeTime, afterTime);

                let isSameOrAfter = time.isSameOrAfter(beforeTime);
                let isSameOrAfterEndTime = time.isSameOrAfter(afterTime);


                if ((isInBtween || (isSameOrAfter && !isSameOrAfterEndTime)) && value.availabilityType.toLowerCase() != 'workoff') {
                  this.facilityId = value.facilityId;
                  let RemoteAndFacility = value.isRemoteAndFacility;
                  if (RemoteAndFacility && !this.isFacilityselected) {
                    value.availabilityType = 'remotesetup';
                  }
                  if (value.availabilityType.toLowerCase() == 'remotesetup') {
                    this.selectedAppointmentTypePopUp = "Telehealth";
                    this.selectedRemoteAppointmentType = this.appointment.schRemoteAppointmentType;

                  }
                  else if (value.availabilityType.toLowerCase() == 'roadshow') {
                    this.selectedAppointmentTypePopUp = "Home Visit";
                    if (this.apptDuration != null && this.apptDuration != undefined) {
                      this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRoadHomeClassificationDuration);
                    }
                  }
                  else if (value.availabilityType.toLowerCase() == 'facilityvisit') {
                    this.selectedAppointmentTypePopUp = "Facility Visit";
                    if (this.apptDuration != null && this.apptDuration != undefined) {
                      this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmFacilityClassificationDuration);
                    }
                  }
                  break;
                }
                else {
                  if (value.availabilityType.toLowerCase() == 'facilityvisit')
                    this.facilityId = value.facilityId;
                }
              }

              this.SetDefaultModeofAppointmentBasedOnDoctorAvailablity();

              if (this.selectedAppointmentTypePopUp.toLowerCase() == "telehealth") {
                let checkTeleMed = this.remoteAppointmentTypeModel.find(x => x.crmRemoteAppointmentType == 'Telemed');
                if (checkTeleMed)
                  this.selectedRemoteAppointmentType = 'Telemed';
                else
                  this.selectedRemoteAppointmentType = 'OnlineMeeting';

                this.getRemoteFacilitiesByAddress(this.salesOrder.crmSalesorderId);
                if (this.apptDuration != null && this.apptDuration != undefined) {
                  this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRemoteClassificationDuration);
                }
              }
              else if (this.selectedAppointmentTypePopUp.toLowerCase() == "home visit") {
                if (this.apptDuration != null && this.apptDuration != undefined) {
                  this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRoadHomeClassificationDuration);
                }
                this.getRoadShowFirstAvailable();
              }
              else if (this.selectedAppointmentTypePopUp.toLowerCase() == "facility visit") {
                if (this.apptDuration != null && this.apptDuration != undefined) {
                  this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmFacilityClassificationDuration);
                }
                console.log('GetTherapistAvailabilityByDate facilityId: ' + this.facilityId);

                this.getFacilitiesByAddress(this.salesOrder.crmSalesorderId);
              }
            }
          }
          this.loading = false;
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  manageRemoteAppointment(remoteAppintmentType: string) {
    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    let selectedApptDuration: any;
    if (remoteAppintmentType == "SelfGuided") {
      this.selectedselfGuidedApptDuration = this.selfGuidedapptDuration.find(x => x.code == 15);
      selectedApptDuration = this.selectedselfGuidedApptDuration;
    }
    else {
      selectedApptDuration = this.selectedApptDuration;
    }
    this.slotLockBasedOnAppoitmentDurationNew(comparetime, selectedApptDuration);

    this.clearSelection();
  }

  timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    if (rhours > 0 && rminutes > 0)
      return rhours + " Hour " + rminutes + " Minutes";
    else if (rhours > 0)
      return rhours + " Hour";
    else
      return rminutes + " Minutes";
  }

  private getAppointmentInfo(AppoinmentId) {
    this.loading = true;
    this.progress.next(true);
    this.appointmentService.GetAppointmentById(AppoinmentId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentInfoReponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointment = result.responseModel;
              this.schReminderOpted = this.appointment.schReminderOpted;
              this.schIsReminderOptedSms = this.appointment.schIsReminderOptedSms;
              this.schIsReminderOptedEmail = this.appointment.schIsReminderOptedEmail;
              this.salesOrder = result.responseModel.salesOrderInfo;
              this.crmSalesorderClassificationType = this.salesOrder.crmSalesorderClassificationType;
              //this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
              //this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;
              this.isTelehealth = this.salesOrder.crmIsTelehealth;
              this.duration = this.salesOrder.crmClassificationDuration == 60 || this.salesOrder.crmClassificationDuration == 1 ? '1 Hour' : this.salesOrder.crmClassificationDuration.toString() + ' Minutes'
              this.scheduledApptDuration = this.appointment.schAppointmentDuration + ' Minutes';
              //this.scheduledApptDuration = this.timeConvert(this.appointment.schAppointmentDuration)


              if (this.salesOrder.crmDoctorId != undefined && this.salesOrder.crmDoctorId != null) {
                if (this.salesOrder.crmIsFacilityVisitSelected)
                  this.isDrFacilityVisitOnly = true;

                if (this.salesOrder.crmIsRemoteVisitSelected)
                  this.isDrRemoteVisitOnly = true;

                if (this.salesOrder.crmIsRoadHomeVisitSelected)
                  this.isDrRoadHomeVisitOnly = true;

                this.SetDefaultModeofAppointmentBasedOnDoctorAvailablity();
              }
              else {
                this.isDrFacilityVisitOnly = true;
                this.isDrRemoteVisitOnly = true;
                this.isDrRoadHomeVisitOnly = true;
              }


              if (this.apptDuration != null && this.apptDuration != undefined) {
                this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmClassificationDuration);
              }

              //this.selectedAppointmentTypePopUp = '';

              this.crmSalesorderOrderNote = this.salesOrder.crmSalesorderOrderNote;
              this.crmSalesorderDeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;

              this.setInventoryLocationName(this.salesOrder ? Number(this.salesOrder.crmInvetoryLocationId) : 0);
              if (this.appointment.appointmentHistoryInfo != null && this.appointment.appointmentHistoryInfo != undefined) {
                var callbackDetails = this.appointment.appointmentHistoryInfo.filter(x => x.isCall && x.schNextCallDueDate != null);
                //this.appointment.appointmentHistoryInfo.reverse();
                //let callbackDetails = this.appointment.appointmentHistoryInfo.find(x => x.isCall && x.schNextCallDueDate != null);
                if (callbackDetails != null && callbackDetails != undefined && callbackDetails.length > 0) {
                  this.isSchedulecallBack = true;
                  this.previousCallbackDate = callbackDetails[0].schNextCallDueDate;
                  this._schTimezone = callbackDetails[0].schTimeZone;

                  if (this.previousCallbackDate != undefined) {
                    let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
                    let currentDate = moment(dateCurrent).toDate();
                    let targetDate = new Date(this.previousCallbackDate);
                    this.hasCallbackDatePassed = targetDate < currentDate;
                  }
                  else {
                    this.hasCallbackDatePassed = false;
                  }
                }
              }

              if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "";
              }
              if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }
              if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }

              if (!this.salesOrder.patientInfo.crmPatientPhoneNo && !this.salesOrder.patientInfo.crmPatientMobileNumber)
                this.isPhoneNumberExist = false;
              else
                this.isPhoneNumberExist = true;


              if (this.salesOrder.patientInfo.crmPatientPhoneNo && this.salesOrder.patientInfo.crmPatientMobileNumber)
                this.isTwoNumberExist = true;
              else
                this.isTwoNumberExist = false;


              if (this.salesOrder.patientInfo.crmPatientMobileNumber)
                this.smsTextNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              else
                this.smsTextNumber = this.salesOrder.patientInfo.crmPatientPhoneNo;

              this.isRescheduledRequest = true;
              this.schAppointmentId = AppoinmentId;


              if (this.isAppoinmentBookByCalendar) {
                this.BookSlotFromCalendar();
              } else {
                this.facilityId = 0;
                //this.selectedAppointmentTypePopUp = "Facility Visit";
                //if (this.isDrLiepertOnly)
                //  this.selectedAppointmentTypePopUp = "Facility Visit";
                //else
                //  this.selectedAppointmentTypePopUp = "Telehealth";

                this.handleFacilityVisitOptions();
              }

              if (this.isCall) {
                if (this.salesOrder.patientInfo.crmPatientMobileNumber)
                  this.Phonecall(this.salesOrder.patientInfo.crmPatientMobileNumber, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName);
                else
                  this.Phonecall(this.salesOrder.patientInfo.crmPatientPhoneNo, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName);
              }
              //if (this.isCall)
              //  this.Phonecall(this.salesOrder.patientInfo.crmPatientPhoneNo, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName);

              this.SetPatientTimezone();
              //this._cdr.detectChanges();
            }
          }
          this.loading = false;
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  SetDefaultModeofAppointmentBasedOnDoctorAvailablity() {
    if (this.isDrFacilityVisitOnly && !this.isDrRemoteVisitOnly && !this.isDrRoadHomeVisitOnly)
      this.selectedAppointmentTypePopUp = 'Facility Visit';
    else if (this.isDrRemoteVisitOnly && !this.isDrFacilityVisitOnly && !this.isDrRoadHomeVisitOnly)
      this.selectedAppointmentTypePopUp = 'Telehealth';
    else if (this.isDrRoadHomeVisitOnly && !this.isDrFacilityVisitOnly && !this.isDrRemoteVisitOnly)
      this.selectedAppointmentTypePopUp = 'Home Visit';

    if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'remotesetup' && !this.isDrRemoteVisitOnly) {
      if (this.isDrFacilityVisitOnly)
        this.selectedAppointmentTypePopUp = 'Facility Visit';
      else if (this.isDrRoadHomeVisitOnly)
        this.selectedAppointmentTypePopUp = 'Home Visit';
      else
        this.selectedAppointmentTypePopUp = 'Telehealth';
    }
    else if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'facilityvisit' && !this.isDrFacilityVisitOnly) {
      if (this.isDrRemoteVisitOnly)
        this.selectedAppointmentTypePopUp = 'Telehealth';
      else if (this.isDrRoadHomeVisitOnly)
        this.selectedAppointmentTypePopUp = 'Home Visit';
      else
        this.selectedAppointmentTypePopUp = 'Facility Visit';
    }
    else if (this.defaultModeofAppointment != undefined && this.defaultModeofAppointment.toLowerCase() == 'roadhomevisit' && !this.isDrRoadHomeVisitOnly) {
      if (this.isDrRoadHomeVisitOnly)
        this.selectedAppointmentTypePopUp = 'Home Visit';
      else if (this.isDrRemoteVisitOnly)
        this.selectedAppointmentTypePopUp = 'Telehealth';
      else
        this.selectedAppointmentTypePopUp = 'Facility Visit';
    }
  }

  // Confirm Appointment popup
  ScheduledDate() {
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }

  SaveScheduledDate() {
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }
  CancelScheduledDate() {

    this.appointmentRequestBT.ScheduledDeliveryStartDate = this.selectedStartDate;
    this.ScheduledDateStatus = !this.ScheduledDateStatus;
  }

  ScheduledTime() {
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  SaveScheduledTime() {
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  CancelScheduledTime() {

    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.selectedStartDate.toTimeString(), 'hh:mm A').format("hh:mm A");
    this.ScheduledTimeStatus = !this.ScheduledTimeStatus;
  }

  WIPStateClick() {
    this.WIPStateStatus = !this.WIPStateStatus;
  }

  SaveWIPStateClick() {
    this.WIPStateStatus = !this.WIPStateStatus;
  }

  CancelWIPStateClick() {
    let AppoinmentType: string = this.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');
    if (AppoinmentType.toLowerCase() == "telehealth") {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'bookedremote')
        if (selectedWipStaus)
          this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
      }

    }
    else {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
        if (selectedWipStaus)
          this.selectedwipStatusBTMappingResponseModel = selectedWipStaus;
      }

    }
    if (this.selectedwipStatusBTMappingResponseModel != null && this.selectedwipStatusBTMappingResponseModel != undefined) {
      this.appointmentRequestBT.WIPStateName = this.selectedwipStatusBTMappingResponseModel.crmWipstatusNameBt;
      this.appointmentRequestBT.WIPStateKey = this.selectedwipStatusBTMappingResponseModel.wipStatusRCMId;
      this.appointmentRequestBT.WIPAssignedToKey = this.selectedwipStatusBTMappingResponseModel.crmWIPAssignedToKey;
    }

    this.WIPStateStatus = !this.WIPStateStatus;

  }

  WIPDateNeededClick() {
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }

  SaveWIPDateNeededClick() {
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }

  CancelWIPDateNeededClick() {
    this.appointmentRequestBT.WIPDateNeeded = moment().toDate();
    this.WIPDateNeededStatus = !this.WIPDateNeededStatus;
  }

  PractitionerIDClick() {
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }

  SavePractitionerIDClick() {
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }

  CancelPractitionerIDClick() {
    this.appointmentRequestBT.PractitionerId = this.selectedPractionerId;
    this.PractitionerIDStatus = !this.PractitionerIDStatus;
  }

  PractitionerNameClick() {
    this.PractitionerNameStatus = !this.PractitionerNameStatus;
  }

  SavePractitionerNameClick() {
    this.PractitionerNameStatus = !this.PractitionerNameStatus;
  }

  CancelPractitionerNameClick() {
    this.appointmentRequestBT.PractitionerName = this.selectedTherapistName;
    this.PractitionerNameStatus = !this.PractitionerNameStatus;
  }

  OrderNoteClick() {
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  SaveOrderNoteClick() {
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  CancelOrderNoteClick() {
    this.appointmentRequestBT.OrderNote = "";
    this.appointmentRequestBT.IsOrderNoteOverride = true;
    this.OrderNoteStatus = !this.OrderNoteStatus;
  }

  DeliveryNoteClick() {
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  SaveDeliveryNoteClick() {
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  CancelDeliveryNoteClick() {
    this.appointmentRequestBT.DeliveryNote = "";
    this.appointmentRequestBT.IsDeliveryNoteOverride = true;
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  brightreeEmailClick() {
    this.brightreeEmailAddress = !this.brightreeEmailAddress;
  }

  brightreePhoneClick1() {
    this.brightreePhone1 = !this.brightreePhone1;
  }

  brightreePhoneClick2() {
    this.brightreePhone2 = !this.brightreePhone2;
  }

  showDialogOrderNote() {
    this.displayOrderNote = true;
  }

  SaveOrderNoteBTClick() {

    if (!this.crmSalesorderOrderNote) {
      this.showWarningMessage("Please enter order note.");
      return false;
    }

    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.CrmSalesOrderId = Number(this.crmSalesOrderId);
    appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = this.crmSalesorderOrderNote;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = null;
    appointmentRequestBT.WIPDateNeeded = null;
    appointmentRequestBT.WIPStateName = null;
    appointmentRequestBT.WIPStateKey = 0;
    this.appointmentService.AddOrderDeliveryNote(appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.displayOrderNote = false;
            let resultModel: SalesOrderInBTResponse = result.responseModel;

            if (resultModel.isSucesss) {
              this.salesOrder.crmSalesorderOrderNote = resultModel.orderNote;
              //this.salesOrder.crmSalesorderDeliveryNote = resultModel.deliveryNote;
              this.showSuccessMessage("Order Note has been saved successfully.");
            } else {
              this.crmSalesorderOrderNote = this.salesOrder.crmSalesorderOrderNote;
              this.showErrorMessage("There was problem in saving Order Note.");
            }
          }
        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in adding.");
        }
      });

    this.OrderNoteStatus = !this.OrderNoteStatus;
  }
  CancelOrderNoteBTClick() {
    this.crmSalesorderOrderNote = this.salesOrder.crmSalesorderOrderNote;
    this.displayOrderNote = !this.displayOrderNote;
  }

  SaveDeliveryNoteBTClick() {
    if (!this.crmSalesorderDeliveryNote) {
      this.showWarningMessage("Please enter delivery note.");
      return false;
    }
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.CrmSalesOrderId = Number(this.crmSalesOrderId);
    appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = this.crmSalesorderDeliveryNote;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = null;
    appointmentRequestBT.WIPDateNeeded = null;
    appointmentRequestBT.WIPStateName = null;
    appointmentRequestBT.WIPStateKey = 0;
    this.appointmentService.AddOrderDeliveryNote(appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            let resultModel: SalesOrderInBTResponse = result.responseModel;
            if (resultModel.isSucesss) {
              this.salesOrder.crmSalesorderDeliveryNote = resultModel.deliveryNote;
              this.showSuccessMessage("Delivery Note has been saved successfully.");
            } else {
              this.showErrorMessage("There was problem in saving delivery note.");
              this.crmSalesorderDeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
            }
          }
        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in saving delivery note.");
        }
      });
    this.displayDeliveryNote = !this.displayDeliveryNote;
  }

  CancelDeliveryNoteBTClick() {
    this.crmSalesorderDeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
    this.displayDeliveryNote = !this.displayDeliveryNote;
  }



  showDialogDeliveryNote() {
    this.displayDeliveryNote = true;
  }

  showDialogDeliveryAddress() {

    this.setValuesToDeliveryAddressForm();
    this.displayDeliveryAddress = true;
  }

  public setValuesToDeliveryAddressForm() {
    this.formDeliveryAddress.reset();

    const form = this.formDeliveryAddress;
    form.get(this.formFields.deliveryAddressLine1).setValue(this.salesOrder.crmDeliveryAddressLine1);
    form.get(this.formFields.deliveryAddressLine2).setValue(this.salesOrder.crmDeliveryAddressLine2);
    form.get(this.formFields.deliveryAddressCity).setValue(this.salesOrder.crmDeliveryAddressCity);
    form.get(this.formFields.deliveryAddressZipcode).setValue(this.salesOrder.crmDeliveryAddressZipcode);
    this.getStateByCode(this.salesOrder.crmDeliveryAddressState);


  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }
  public async getPluginConfiguration() {
    this.loading = true;
    this.settingsService.getPluginConfiguration(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = JSON.parse(result.responseModel.appPluginSettingsValue);
              this.patientBrighttreeURL = jsonData.PatientBrighttreeURL;
              this.salesOrderBrighttreeURL = jsonData.SalesOrderBrighttreeURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }
  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);

    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }
  getStateByCode(StateName: string) {
    if (!StateName) {
      return null;
    }
    if (this.statsModel != null && this.statsModel != undefined) {
      let foundBranch = this.statsModel.find(x => x.appStateCode == StateName);
      if (foundBranch)
        this.selectedState = foundBranch;
      else {

        let foundBranchName = this.statsModel.find(x => x.appStateName == StateName);
        if (foundBranchName)
          this.selectedState = foundBranchName;

      }
    }


    const form = this.formDeliveryAddress;
    form.get(this.formFields.deliveryAddressState).setValue(this.selectedState);
  }

  onHide() {

  }

  formFields = {
    reason: 'reason',
    reasonComments: 'reasonComments',
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
    calldate: 'calldate',
    calltime: 'calltime',
    callnote: 'callnote',
    email: 'email',
    phoneNumber: 'phoneNumber',
    deliveryAddressLine1: 'deliveryAddressLine1',
    deliveryAddressLine2: 'deliveryAddressLine2',
    deliveryAddressCity: 'deliveryAddressCity',
    deliveryAddressState: 'deliveryAddressState',
    deliveryAddressCountry: 'deliveryAddressCountry',
    deliveryAddressZipcode: 'deliveryAddressZipcode',
    /*timeZone: "timeZone",*/
  };

  validationMessages = {
    reason: {
      required: 'Cancel reason is required',
    },
    reasonComments: {
      required: 'Comment is required',
    },
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },

    calldate: {
      required: 'Call date is required',
    },
    calltime: {
      required: 'Call time is required',
    },
    callnote: {
      required: 'Call note is required',
    },
    phoneNumber: {
      required: 'Phone number is required',
      invalid: 'Enter valid phone number'
    },
    deliveryAddressLine1: {
      required: 'Address1 is required',
    },
    deliveryAddressCity: {
      required: 'City is required',
    },
    deliveryAddressState: {
      required: 'State is required',
    },
    deliveryAddressZipcode: {
      required: 'Zip Code is required',
      validNumber: 'Enter valid zip code',
    },
    //timeZone: {
    //  required: "Time Zone is required"
    //}
  };

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formGroupEmail = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length


    formGroupEmail.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length

    const formGroupPhoneNumber = this.formBuilder.group({});

    formGroupPhoneNumber.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formCallAppointment = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formCallAppointment.addControl(this.formFields.calldate, this.formBuilder.control('', { validators: [Validators.required] }));
    // tslint:disable-next-line: max-line-length
    //formCallAppointment.addControl(this.formFields.calltime, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formCallAppointment.addControl(this.formFields.callnote, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formDeliveryAddressGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formDeliveryAddressGroup.addControl(this.formFields.deliveryAddressLine1, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formDeliveryAddressGroup.addControl(this.formFields.deliveryAddressLine2, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));

    formDeliveryAddressGroup.addControl(this.formFields.deliveryAddressCity, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formDeliveryAddressGroup.addControl(this.formFields.deliveryAddressState, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formDeliveryAddressGroup.addControl(this.formFields.deliveryAddressZipcode, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));

    //formDeliveryAddressGroup.addControl(
    //  this.formFields.timeZone,
    //  this.formBuilder.control("", {
    //    validators: [Validators.required],
    //    updateOn: Constants.formControlUpdateStrategy.change
    //  })
    //);

    this.form = this.formBuilder.group({
      formCancel: formGroup,
      formEmail: formGroupEmail,
      formPhoneNumber: formGroupPhoneNumber,
      formCallAppointment: formCallAppointment,
      formVoidAppointment: formVoidGroup,
      formDeliveryAddress: formDeliveryAddressGroup
    });
  }




  get fCancel() { return (<FormGroup>this.form.get('formCancel')).controls; }

  get fEmail() { return (<FormGroup>this.form.get('formEmail')).controls; }

  get fPhoneNumber() { return (<FormGroup>this.form.get('formPhoneNumber')).controls; }

  get fCallAppointment() { return (<FormGroup>this.form.get('formCallAppointment')).controls; }

  get fVoid() { return (<FormGroup>this.form.get('formVoidAppointment')).controls; }

  get fDeliveryAddress() { return (<FormGroup>this.form.get('formDeliveryAddress')).controls; }

  get formCancel() {
    return this.form.get('formCancel');
  }

  get formEmail() {
    return this.form.get('formEmail');
  }

  get formPhoneNumber() {
    return this.form.get('formPhoneNumber');
  }
  get formCallAppointment() {
    return this.form.get('formCallAppointment');
  }

  get formVoidAppointment() {
    return this.form.get('formVoidAppointment');
  }

  get formDeliveryAddress() {
    return this.form.get('formDeliveryAddress');
  }
  cancelAppointment() {

    this.formCancelSubmitted = true;
    /*  if (this.formCancel.invalid) {
       this.markFormAsTouched();
       return;
     } */
    this.formCancelSubmitted = false;
    this.formEmailSubmitted = false;
    this.formAppointmentCallSubmitted = false;
    this.formPhoneNumberSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    this.formDeliveryAddressSubmitted = false;
    const cancelRequestModel = this.getValuesFromUpdateStautsForm(); //this.getValuesFromCancelForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.appointment.schAppointmentId;

    this.appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    this.appointmentRequestBT.IsUpdateToBrightree = false;
    this.appointmentRequestBT.IsDeliveryNoteOverride = true;
    this.appointmentRequestBT.IsOrderNoteOverride = true;
    this.appointmentRequestBT.OrderNote = null;
    this.appointmentRequestBT.DeliveryNote = null;
    this.appointmentRequestBT.PractitionerName = null;
    this.appointmentRequestBT.PractitionerId = 0;
    this.appointmentRequestBT.DeliveryTechnicianId = 0;
    this.appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartDate = null;
    this.appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    this.appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')

      if (selectedWipStaus != null && selectedWipStaus != undefined) {
        this.appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        this.appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    this.isBrightreeSystemAppUpdateVoidCancel = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    this.appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdateVoidCancel;
    cancelRequestModel.salesOrderUpdateRequestModelBT = this.appointmentRequestBT;


    this.loadingCancel = true;
    //this._cdr.detectChanges();
    this.appointmentService.CancelAppointment(cancelRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCancel = false; }))
      .subscribe((result) => {
        this.isSalesOrderUpdated = true;
        if (result.responseModel) {

          this.showSuccessMessage("Appointment has been canceled.");
          const form = this.formCancel;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.reason).setValue('');
          form.get(this.formFields.reasonComments).setValue('');
          form.reset();
          this.displayCancelAppointment = false;
          this._cdr.detectChanges();
          this.loadingCancel = false;
        }
        else {
          this.loadingCancel = false;
          this._cdr.detectChanges();
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

  }

  voidAppointment() {

    this.formVoidAppointmentSubmitted = true;
    /* if (this.formVoidAppointment.invalid) {
      this.markFormAsTouched();
      return;
    } */
    this.formCancelSubmitted = false;
    this.formEmailSubmitted = false;
    this.formAppointmentCallSubmitted = false;
    this.formPhoneNumberSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromUpdateStautsForm();//this.getValuesFromVoidForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.isRescheduledRequest ? this.appointment.schAppointmentId : this.salesOrder.crmSalesorderId;
    cancelRequestModel.voidFrom = this.voidFrom;

    this.appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    this.appointmentRequestBT.IsUpdateToBrightree = false;
    this.appointmentRequestBT.IsDeliveryNoteOverride = true;
    this.appointmentRequestBT.IsOrderNoteOverride = true;
    this.appointmentRequestBT.OrderNote = null;
    this.appointmentRequestBT.DeliveryNote = null;
    this.appointmentRequestBT.PractitionerName = null;
    this.appointmentRequestBT.PractitionerId = 0;
    this.appointmentRequestBT.DeliveryTechnicianId = 0;
    this.appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartDate = null;
    this.appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    this.appointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (cancelRequestModel.voidFrom === 'P') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
        if (selectedWipStaus != null && selectedWipStaus != undefined) {
          this.appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          this.appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }
    }
    else {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
        if (selectedWipStaus != null && selectedWipStaus != undefined) {
          this.appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          this.appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          this.appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          this.appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

    }
    this.isBrightreeSystemAppUpdateVoidCancel = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    this.appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdateVoidCancel;
    cancelRequestModel.salesOrderUpdateRequestModelBT = this.appointmentRequestBT;

    this.loadingVoid = true;
    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.VoidAppointment(cancelRequestModel) : this.appointmentService.VoidSalesOrder(cancelRequestModel);
    //this.appointmentService.CancelAppointment(cancelRequestModel)
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingVoid = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.isSalesOrderUpdated = true;
          this.showSuccessMessage("Appointment has been voided");
          const form = this.formVoidAppointment;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.voidReason).setValue('');
          form.get(this.formFields.voidReasonComments).setValue('');
          form.reset();
          this.displayVoidAppointment = false;
          this._cdr.detectChanges();
          this.loadingVoid = false
        }
        else {
          this.loadingVoid = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

  }

  handleActionChange(e) {
    this.formCancelSubmitted = false;
    this.formEmailSubmitted = false;
    this.formAppointmentCallSubmitted = false;
    this.formPhoneNumberSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    this.indexActionTab = e.index;
    if (this.indexActionTab == 0) {
      this.actionType = "S";
    }
    if (this.indexActionTab == 1) {
      this.actionType = "C";
    }
    if (this.indexActionTab == 2) {
      this.actionType = "";
    }
  }

  handleFacilityVisitOptions() {
    this.isShowLocationInfo = false;
    this.directionsResults = null;
    this.isAppointmentTypeAvailable = true;
    this.displaySlots = [];
    this.roadShowTimeSlotsResponseModel = [];
    this.selectedRoadShowTimeSlotTherapistModel = [];
    this.selectedFacilityLocal = new FacilitySearchResponseModel;
    this.facilityAvailableWeekDays = [];
    this.facilityAvailableWeekend = [];
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    this.showConfirm = false;
    this.reminderOpted = false;
    this.islotAvailable = true;
    this.islotAvailableForTheDay = true;
    this.minAvailabilityDate = null;
    this.selectedRemoteAppointmentType = 'Telemed';
    this.clearSelection();
    if (this.isRescheduledRequest) {
      this.facilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    }
    else
      this.facilityId = 0;

    if (this.selectedAppointmentTypePopUp.toLowerCase() == "telehealth") {
      if (this.remoteAppointmentTypeModel != null && this.remoteAppointmentTypeModel != undefined) {
        let checkTeleMed = this.remoteAppointmentTypeModel.find(x => x.crmRemoteAppointmentType == 'Telemed');
        if (checkTeleMed)
          this.selectedRemoteAppointmentType = 'Telemed';
        else
          this.selectedRemoteAppointmentType = 'OnlineMeeting';
      }
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRemoteClassificationDuration);
      }
      this.getRemoteFacilitiesByAddress(this.salesOrder.crmSalesorderId);

    }
    else if (this.selectedAppointmentTypePopUp.toLowerCase() == "home visit") {
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRoadHomeClassificationDuration);
      }
      this.getRoadShowFirstAvailable();

    }
    else {
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmFacilityClassificationDuration);
      }
      this.getFacilitiesByAddress(this.salesOrder.crmSalesorderId);

    }
  }

  handleBillingDeliveryAddressonUpdate() {
    if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress')
      this.isBillingAddress = true;
    else
      this.isBillingAddress = false;

    if (this.isAppoinmentBookByCalendar) {
      this.handleFacilityAndRemoteVisitOptions();
    }
    else {
      this.handleFacilityVisitOptions();
    }
    this.SetPatientTimezone();
  }

  handleBillingDeliveryAddress(event) {
    if (this.previousPatientsDefaultAppointmentLocation.toLowerCase() == this.patientsDefaultAppointmentLocation.toLowerCase()) {
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to change appointment location?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.patientsDefaultAppointmentLocation = this.previousPatientsDefaultAppointmentLocation;
        return;
      }
      this.previousPatientsDefaultAppointmentLocation = this.patientsDefaultAppointmentLocation;
      if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress')
        this.isBillingAddress = true;
      else
        this.isBillingAddress = false;

      if (this.isAppoinmentBookByCalendar) {
        this.handleFacilityAndRemoteVisitOptions();
      }
      else {
        this.handleFacilityVisitOptions();
      }
      this.SetPatientTimezone();
    });

  }

  handleFacilityAndRemoteVisitOptions() {
    this.roadShowTimeSlotsResponseModel = [];
    this.selectedRoadShowTimeSlotTherapistModel = [];
    this.isAppointmentTypeAvailable = true;
    this.displaySlots = [];
    this.selectedFacilityLocal = new FacilitySearchResponseModel;
    this.facilityAvailableWeekDays = [];
    this.facilityAvailableWeekend = [];
    //this.selectedStartDate = null;
    //this.selectedEndDate = null;
    this.showConfirm = false;
    this.reminderOpted = false;
    this.islotAvailable = true;
    this.minAvailabilityDate = null;
    this.islotAvailableForTheDay = true;
    this.clearSelectionRemoteAndFacility();
    this.selectedRemoteAppointmentType = 'Telemed';

    if (this.selectedAppointmentTypePopUp.toLowerCase() == "telehealth") {
      if (this.remoteAppointmentTypeModel != null && this.remoteAppointmentTypeModel != undefined) {
        let checkTeleMed = this.remoteAppointmentTypeModel.find(x => x.crmRemoteAppointmentType == 'Telemed');
        if (checkTeleMed)
          this.selectedRemoteAppointmentType = 'Telemed';
        else
          this.selectedRemoteAppointmentType = 'OnlineMeeting';
      }
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRemoteClassificationDuration);
      }
      this.getRemoteFacilitiesByAddress(this.salesOrder.crmSalesorderId);
    }
    else if (this.selectedAppointmentTypePopUp.toLowerCase() == "home visit") {
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmRoadHomeClassificationDuration);
      }
      this.getRoadShowFirstAvailable();
    }
    else {
      if (this.apptDuration != null && this.apptDuration != undefined) {
        this.selectedApptDuration = this.apptDuration.find(x => x.code == this.salesOrder.crmFacilityClassificationDuration);
      }
      this.getFacilitiesByAddress(this.salesOrder.crmSalesorderId);
    }

  }

  async ScheduleFutureCallBackAppointemnt() {

    this.formAppointmentCallSubmitted = true;
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    var time = new Date(commonDate + ' ' + this.customStartTime);
    //59528: get the correct appointment time based on timezone set in Appointment360
    var today = new DatePipe(this.locale).transform(new Date(), 'MM dd yyyy' + " HH:mm:ss", this.appDateFormat.timeZone);
    var currentDate = new Date(today);
    // time set by user
    //var setDatetime = new DatePipe(this.locale).transform(new Date(this.calldate.toDateString() + ' ' + this.customStartTime), 'MM dd yyyy' + " HH:mm:ss", this.appDateFormat.timeZone);
    //var setDate = new Date(setDatetime);


    if (this.calldate.toDateString() === currentDate.toDateString() && new Date(this.calldate.toDateString() + ' ' + this.customStartTime).getTime() <= currentDate.getTime()) {
      this.showWarningMessage('Time should be greater to current time');
      return;
    }

    if (!time.getDate()) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
    if (this.formCallAppointment.invalid) {
      this.markFormAsTouched();
      return;
    }
    if (!this.isValid) {
      return;
    }
    this.formCancelSubmitted = false;
    this.formEmailSubmitted = false;
    this.formAppointmentCallSubmitted = false;
    this.formPhoneNumberSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    //if (this.callId) {
    //  await this.UpdateCall();
    //  await this.SaveCall("C");
    //}
    //else {
    //  await this.SaveCall("C");
    //}
    this.SaveCallScheduleCallback();
    this.displayScheduleCallBack = !this.displayScheduleCallBack;

  }

  CancelScheduleFutureCallBackAppointemnt() {
    this.displayScheduleCallBack = !this.displayScheduleCallBack;
  }

  private getValuesFromCancelForm(): AppointmentCancelRequest {

    const form = this.formCancel;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.reason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.reasonComments).value;
    return cancelRequestModel;
  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const form = this.formVoidAppointment;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.voidReason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    return cancelRequestModel;
  }

  private getValuesFromUpdateStautsForm(): AppointmentCancelRequest {
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.schCancelReason = this.updateStatusforminfo?.reason;
    cancelRequestModel.schComments = this.updateStatusforminfo?.reasonComment;
    return cancelRequestModel;
  }

  private getValuesFromDeliveryAddressForm(): AddressUpdateRequestModel {
    const form = this.formDeliveryAddress;
    let state = this.selectedState.appStateCode;
    let stateFullName = this.selectedState.appStateName;
    const deliveryAddressRequestModel = new AddressUpdateRequestModel();
    deliveryAddressRequestModel.isUpdateToBrightree = this.isUpdateDelieryAddressToBrightree;
    deliveryAddressRequestModel.CrmSalesOrderId = Number(this.crmSalesOrderId);
    deliveryAddressRequestModel.CrmPatientId = this.salesOrder.patientInfo.crmPatientId;
    deliveryAddressRequestModel.CrmDeliveryAddressLine1 = form.get(this.formFields.deliveryAddressLine1).value;
    deliveryAddressRequestModel.CrmDeliveryAddressLine2 = form.get(this.formFields.deliveryAddressLine2).value;
    deliveryAddressRequestModel.CrmDeliveryAddressCity = form.get(this.formFields.deliveryAddressCity).value;
    deliveryAddressRequestModel.CrmDeliveryAddressState = state;
    deliveryAddressRequestModel.CrmDeliveryAddressStateFullName = stateFullName;
    deliveryAddressRequestModel.CrmDeliveryAddressZipcode = form.get(this.formFields.deliveryAddressZipcode).value;
    return deliveryAddressRequestModel;
  }

  onTimeZoneChange() {
    //console.log(this.selectedTimeZone);
  }

  onSelfGuidedApptDurationChange() {
    //console.log(this.selectedselfGuidedApptDuration.code);
    this.GetRemoteFirstAvailableDateTimeByDuration(this.facilitySlotDate, this.selectedselfGuidedApptDuration.code);
    this.getSelfGuidedRemoteFacilitySlots(this.facilitySlotDate, true);
    this.clearSelection();

    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    this.slotLockBasedOnAppoitmentDurationNew(comparetime, this.selectedselfGuidedApptDuration);
  }

  onSelfGuidedApptDurationChangeSlot() {
    this.clearSelection();

    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    this.slotLockBasedOnAppoitmentDurationNew(comparetime, this.selectedselfGuidedApptDuration);
  }

  onApptDurationChange(tInitialcall: boolean) {
    if (!tInitialcall) {
      if (this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth')
        this.GetRemoteFirstAvailableDateTimeByDuration(this.facilitySlotDate, this.selectedApptDuration.code);
      else if (this.selectedAppointmentTypePopUp.toLowerCase() == "home visit") {
        this.GetRoadShowFirstAvailableDateTimeByDuration(this.facilitySlotDate, this.selectedApptDuration.code);
      }
      else
        this.GetFacilityFirstAvailableDateTimeByDuration(this.facilitySlotDate, this.selectedApptDuration.code);
    }

    this.clearSelection();

    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    this.slotLockBasedOnAppoitmentDurationNew(comparetime, this.selectedApptDuration);

  }

  //Old method

  slotLockBasedOnAppoitmentDuration(comparetime: any, selectedApptDuration: any) {
    if (this.roadShowTimeSlotMainResponseModel) {
      let duration = 15;
      for (let i = 0; i < this.roadShowTimeSlotMainResponseModel.timeslots.length; i++) {
        let timeSlot = this.roadShowTimeSlotMainResponseModel.timeslots[i];
        for (let j = 0; j < timeSlot.therapist.length; j++) {
          let therapist = this.roadShowTimeSlotMainResponseModel.timeslots[i].therapist[j];
          for (let k = 0; k < therapist.timeSlotAppointmentInfo.length; k++) {
            if (selectedApptDuration.code == 30 || selectedApptDuration.code == 45 ||
              selectedApptDuration.code == 60 || selectedApptDuration.code == 75 ||
              selectedApptDuration.code == 90 || selectedApptDuration.code == 105 || selectedApptDuration.code == 120) {

              let nextDuration;
              let kplusduration;
              if (selectedApptDuration.code == 30) {
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 1] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 1].startTime).format("hh:mm:ss") : '';
              }
              if (selectedApptDuration.code == 45) {
                duration = 30;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 2] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 2].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 60) {
                duration = 45;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 3] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 3].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 75) {
                duration = 60;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 4] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 4].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 90) {
                duration = 75;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 5] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 5].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 105) {
                duration = 90;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 6] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 6].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 120) {
                duration = 105;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 7] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 7].startTime).format("hh:mm:ss") : '';
              }


              if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null ||
                therapist.timeSlotAppointmentInfo[k + 6] == null || therapist.timeSlotAppointmentInfo[k + 7] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 120) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null ||
                therapist.timeSlotAppointmentInfo[k + 6] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 105) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 90) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 75) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 60) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 45) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || kplusduration != nextDuration) && this.selectedApptDuration.code == 30) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }

              if (therapist.timeSlotAppointmentInfo[k + 7] != null && selectedApptDuration.code == 120) {
                if (((therapist.timeSlotAppointmentInfo[k + 7].isAppointment && therapist.timeSlotAppointmentInfo[k + 7].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 7].isBlock || therapist.timeSlotAppointmentInfo[k + 7].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 6].isAppointment && !therapist.timeSlotAppointmentInfo[k + 6].isBlock && !therapist.timeSlotAppointmentInfo[k + 6].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 6].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 6].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 6].isAppointment && therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 6].isBlock || therapist.timeSlotAppointmentInfo[k + 6].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 6] != null && selectedApptDuration.code == 105) {
                if (((therapist.timeSlotAppointmentInfo[k + 6].isAppointment && therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 6].isBlock || therapist.timeSlotAppointmentInfo[k + 6].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 5] != null && selectedApptDuration.code == 90) {
                if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 4] != null && selectedApptDuration.code == 75) {
                if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 3] != null && selectedApptDuration.code == 60) {
                if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 2] != null && selectedApptDuration.code == 45) {
                if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 1] != null && selectedApptDuration.code == 30) {
                if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                } else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }
            } else {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
            }
            //var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
            if (moment(therapist.timeSlotAppointmentInfo[k].startTime).toDate() < moment(comparetime).toDate()) {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
            }

            let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
            let currentDate = moment(dateCurrent).toDate();
            let date = moment(therapist.timeSlotAppointmentInfo[k].startTime).toDate();
            let issameDaye = isSameDay(date, currentDate);
            let isBeforedate = isBefore(date, currentDate);
            if (isBeforedate) {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
            }
          }
        }
      }
    }
  }
//END


//New method
  slotLockBasedOnAppoitmentDurationNew(comparetime: any, selectedApptDuration: any) {

    if (this.roadShowTimeSlotMainResponseModel) {
      let duration = 15;
      for (let i = 0; i < this.roadShowTimeSlotMainResponseModel.timeslots.length; i++) {
        let timeSlot = this.roadShowTimeSlotMainResponseModel.timeslots[i];
        for (let j = 0; j < timeSlot.therapist.length; j++) {
          let therapist = this.roadShowTimeSlotMainResponseModel.timeslots[i].therapist[j];
          for (let k = 0; k < therapist.timeSlotAppointmentInfo.length; k++) {
            // if( therapist.timeSlotAppointmentInfo[k].startTime.toString() =="2024-08-12T13:15:00"){
            //   debugger;
            // }
            if (selectedApptDuration.code == 30 || selectedApptDuration.code == 45 ||
              selectedApptDuration.code == 60 || selectedApptDuration.code == 75 ||
              selectedApptDuration.code == 90 || selectedApptDuration.code == 105 || selectedApptDuration.code == 120) {
              let nextDuration;
              let kplusduration;
              if (selectedApptDuration.code == 30) {
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 1] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 1].startTime).format("hh:mm:ss") : '';
              }
              if (selectedApptDuration.code == 45) {
                duration = 30;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 2] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 2].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 60) {
                duration = 45;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 3] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 3].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 75) {
                duration = 60;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 4] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 4].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 90) {
                duration = 75;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 5] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 5].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 105) {
                duration = 90;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 6] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 6].startTime).format("hh:mm:ss") : '';
              }
              else if (selectedApptDuration.code == 120) {
                duration = 105;
                nextDuration = moment(therapist.timeSlotAppointmentInfo[k].startTime).add(duration, 'minutes').format("hh:mm:ss");
                kplusduration = (therapist.timeSlotAppointmentInfo[k + 7] != null) ? moment(therapist.timeSlotAppointmentInfo[k + 7].startTime).format("hh:mm:ss") : '';
              }


              if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null ||
                therapist.timeSlotAppointmentInfo[k + 6] == null || therapist.timeSlotAppointmentInfo[k + 7] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 120) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null ||
                therapist.timeSlotAppointmentInfo[k + 6] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 105) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || therapist.timeSlotAppointmentInfo[k + 5] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 90) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || therapist.timeSlotAppointmentInfo[k + 4] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 75) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null ||
                therapist.timeSlotAppointmentInfo[k + 3] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 60) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || therapist.timeSlotAppointmentInfo[k + 2] == null || kplusduration != nextDuration)
                && selectedApptDuration.code == 45) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }
              else if ((therapist.timeSlotAppointmentInfo[k + 1] == null || kplusduration != nextDuration) && this.selectedApptDuration.code == 30) {
                therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
              }

              if (therapist.timeSlotAppointmentInfo[k + 7] != null && selectedApptDuration.code == 120) {
                if (((therapist.timeSlotAppointmentInfo[k + 7].isAppointment &&
                   therapist.timeSlotAppointmentInfo[k + 7].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 7].isBlock || therapist.timeSlotAppointmentInfo[k + 7].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 7].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 6].isAppointment && !therapist.timeSlotAppointmentInfo[k + 6].isBlock && !therapist.timeSlotAppointmentInfo[k + 6].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 6].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 6].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 6].isAppointment && therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 6].isBlock || therapist.timeSlotAppointmentInfo[k + 6].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 6] != null && selectedApptDuration.code == 105) {
                if (((therapist.timeSlotAppointmentInfo[k + 6].isAppointment && therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 6].isBlock || therapist.timeSlotAppointmentInfo[k + 6].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 6].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 5].isAppointment && !therapist.timeSlotAppointmentInfo[k + 5].isBlock && !therapist.timeSlotAppointmentInfo[k + 5].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 5].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 5] != null && selectedApptDuration.code == 90) {
                if (((therapist.timeSlotAppointmentInfo[k + 5].isAppointment && therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 5].isBlock || therapist.timeSlotAppointmentInfo[k + 5].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 5].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 4].isAppointment && !therapist.timeSlotAppointmentInfo[k + 4].isBlock && !therapist.timeSlotAppointmentInfo[k + 4].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 4].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 4] != null && selectedApptDuration.code == 75) {
                if (((therapist.timeSlotAppointmentInfo[k + 4].isAppointment && therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 4].isBlock || therapist.timeSlotAppointmentInfo[k + 4].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 4].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 3].isAppointment && !therapist.timeSlotAppointmentInfo[k + 3].isBlock && !therapist.timeSlotAppointmentInfo[k + 3].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 3].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment && therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 3] != null && selectedApptDuration.code == 60) {

                if (((therapist.timeSlotAppointmentInfo[k + 3].isAppointment &&
                   therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 3].isBlock || therapist.timeSlotAppointmentInfo[k + 3].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
               if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 3].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                  //
                  else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 2].isAppointment && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else if ((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                    && !therapist.timeSlotAppointmentInfo[k + 2].isBlock && !therapist.timeSlotAppointmentInfo[k + 2].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 2].isBlock1 = false;
                  }
                  //
                  if(therapist.timeSlotAppointmentInfo[k + 1].isBlock1 && therapist.timeSlotAppointmentInfo[k + 2].isBlock1
                    && therapist.timeSlotAppointmentInfo[k + 3].isBlock1)
                    {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment &&
                      therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock &&
                       !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                     //
                     else if( therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock
                    && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                   //
                   if(therapist.timeSlotAppointmentInfo[k + 1].isBlock1 && therapist.timeSlotAppointmentInfo[k + 2].isBlock1)
                    {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment &&
                  therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                    //
                    if(therapist.timeSlotAppointmentInfo[k + 1].isBlock1)
                      {
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                }
                 else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 2] != null && selectedApptDuration.code == 45) {
                if (((therapist.timeSlotAppointmentInfo[k + 2].isAppointment && therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 2].isBlock || therapist.timeSlotAppointmentInfo[k + 2].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 2].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  if (!therapist.timeSlotAppointmentInfo[k + 1].isAppointment && !therapist.timeSlotAppointmentInfo[k + 1].isBlock && !therapist.timeSlotAppointmentInfo[k + 1].isBreak) {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k + 1].isBlock1 = false;
                  }
                    //
                    if(therapist.timeSlotAppointmentInfo[k + 1].isBlock1 && therapist.timeSlotAppointmentInfo[k + 2].isBlock1)
                      {
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                }
                else if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)
                  && therapist.timeSlotAppointmentInfo[k].isBlock == false) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                   //
                   else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                    && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                  else {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                  }
                  //
                  if(therapist.timeSlotAppointmentInfo[k + 1].isBlock1)
                    {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                  //
                }
                else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }

              else if (therapist.timeSlotAppointmentInfo[k + 1] != null && selectedApptDuration.code == 30) {
                if (((therapist.timeSlotAppointmentInfo[k + 1].isAppointment && therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId)
                  || therapist.timeSlotAppointmentInfo[k + 1].isBlock || therapist.timeSlotAppointmentInfo[k + 1].isBreak)) {
                  if (!therapist.timeSlotAppointmentInfo[k].isAppointment && !therapist.timeSlotAppointmentInfo[k].isBlock && !therapist.timeSlotAppointmentInfo[k].isBreak) {
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                  }
                    //
                    else if( therapist.timeSlotAppointmentInfo[k + 1].appointmentId != this.appointment.schAppointmentId
                      && !therapist.timeSlotAppointmentInfo[k].isBlock&& !therapist.timeSlotAppointmentInfo[k].isBreak){
                      therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
                    }
                    //
                }
                 else {
                  if (kplusduration == nextDuration)
                    therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
                }
              }
            } else {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = false;
            }
            //var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
            if (moment(therapist.timeSlotAppointmentInfo[k].startTime).toDate() < moment(comparetime).toDate()) {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
            }

            let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
            let currentDate = moment(dateCurrent).toDate();
            let date = moment(therapist.timeSlotAppointmentInfo[k].startTime).toDate();
            let issameDaye = isSameDay(date, currentDate);
            let isBeforedate = isBefore(date, currentDate);
            if (isBeforedate) {
              therapist.timeSlotAppointmentInfo[k].isBlock1 = true;
            }
          }
        }
      }
    }
  }

  onApptDurationChangeSlot() {
    this.clearSelection();
    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
    //var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    let selectedApptDuration: any;
    if (this.selectedRemoteAppointmentType == 'SelfGuided') {
      selectedApptDuration = this.selectedselfGuidedApptDuration;
    }
    else {
      selectedApptDuration = this.selectedApptDuration;
    }
    this.slotLockBasedOnAppoitmentDurationNew(comparetime, selectedApptDuration);
  }

  sameAsBillingAddress: boolean;

  handleBillingAddress(isChecked) {
    if (isChecked.checked) {

      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'Are you sure you want to update delivery address as billing address?',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          this.sameAsBillingAddress = false;
          return;
        }

        this.loadingCustom = true;

        let btNoteID = this.salesOrder.btNoteID != null ? this.salesOrder.btNoteID : 0;
        this.appointmentService.UpdateDeliveryAddressAsBillingAddressById(this.crmSalesOrderId, this.salesOrder.patientInfo.crmPatientId, this.isUpdateDelieryAddressToBrightree, btNoteID, this.isProvisional)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
          .subscribe((result) => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.salesOrder.crmPatientDeliveryAddress = this.salesOrder.patientInfo.crmPatientAddress;
                this.salesOrder.crmDeliveryAddressLine1 = this.salesOrder.patientInfo.crmAddressLine1;
                this.salesOrder.crmDeliveryAddressLine2 = this.salesOrder.patientInfo.crmAddressLine2;
                this.salesOrder.crmDeliveryAddressCity = this.salesOrder.patientInfo.crmCity;
                this.salesOrder.crmDeliveryAddressState = this.salesOrder.patientInfo.crmState;
                this.salesOrder.crmDeliveryAddressZipcode = this.salesOrder.patientInfo.crmPatientZipCode;
                this.salesOrder.crmDeliveryAddressTimeZone = this.salesOrder.patientInfo.crmBillingAddressTimeZone;

                if (this.salesOrder.patientInfo.crmPatientZipCode == null || this.salesOrder.patientInfo.crmPatientZipCode == "")
                  this.showWarningMessage('Zip Code is missing. Please set zip code in the address.');

                this.showSuccessMessage('Patient delivery address updated successfully.');
                this.handleBillingDeliveryAddressonUpdate();
              }
            }
            else {
              if (result.message)
                this.showErrorMessage(result.message);
              else
                this.showErrorMessage("There was problem updating your email. Please contact administrator");
              return;
            }
          });
      });
    }
  }

  UpdateDeliveryAddress() {
    this.formDeliveryAddressSubmitted = true;
    if (this.formDeliveryAddress.invalid) {
      this.markFormAsTouched();
      return;
    }

    this.formCancelSubmitted = false;
    this.formEmailSubmitted = false;
    this.formAppointmentCallSubmitted = false;
    this.formPhoneNumberSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    this.formDeliveryAddressSubmitted = false;
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Delivery Address?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.sameAsBillingAddress = false;
        this.formDeliveryAddressSubmitted = true;
        return;
      }

      this.loadingDeliveryAddress = true;
      const cancelRequestModel = this.getValuesFromDeliveryAddressForm();
      cancelRequestModel.ModifiedByUserInfoId = this.loggedInUserId;
      cancelRequestModel.btNoteId = this.salesOrder.btNoteID != null ? this.salesOrder.btNoteID : 0;
      cancelRequestModel.isProvisional = this.isProvisional;
      let _stateName: string = '';
      if (this.statsModel != null && this.statsModel != undefined) {
        if (cancelRequestModel.CrmDeliveryAddressState != null && cancelRequestModel.CrmDeliveryAddressState != undefined) {
          let SelectedState = this.statsModel.find(x => x.appStateCode == cancelRequestModel.CrmDeliveryAddressState);
          _stateName = SelectedState.appStateName;
        }
      }


      //if (this._selectedTimeZone) {
      //  cancelRequestModel.CrmDeliveryAddressTimeZone = this._selectedTimeZone.appTimeZoneOffSet;
      //}

      this.appointmentService.ValidateZipcodeWithState(_stateName, Number(cancelRequestModel.CrmDeliveryAddressZipcode))
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentService.UpdateSalesOrderDeliveryAddress(cancelRequestModel)
                .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingDeliveryAddress = false))
                .subscribe((result) => {
                  if (result.responseModel) {
                    this.displayDeliveryAddress = false;
                    this.sameAsBillingAddress = false;
                    this.salesOrder.crmDeliveryAddressLine1 = cancelRequestModel.CrmDeliveryAddressLine1;
                    this.salesOrder.crmDeliveryAddressLine2 = cancelRequestModel.CrmDeliveryAddressLine2;
                    this.salesOrder.crmDeliveryAddressCity = cancelRequestModel.CrmDeliveryAddressCity;
                    this.salesOrder.crmDeliveryAddressState = cancelRequestModel.CrmDeliveryAddressState;
                    this.salesOrder.crmDeliveryAddressZipcode = cancelRequestModel.CrmDeliveryAddressZipcode;

                    if (result.responseModel[0] != undefined && result.responseModel[0] != null)
                      this.salesOrder.crmPatientDeliveryAddress = result.responseModel[0];
                    if (result.responseModel[1] != undefined && result.responseModel[1] != null)
                      this.salesOrder.crmDeliveryAddressTimeZone = result.responseModel[1];

                    this.showSuccessMessage('Patient delivery address updated successfully.');
                    this.loadingDeliveryAddress = false;
                    this.handleBillingDeliveryAddressonUpdate();
                  }
                  else {
                    this.sameAsBillingAddress = false;
                    if (result.message)
                      this.showErrorMessage(result.message);
                    else
                      this.showErrorMessage("There was problem updating your email. Please contact administrator");

                    this.loadingDeliveryAddress = false;
                    return;
                  }
                });
            }
            else {
              this.showWarningMessage('Invalid zip code for ' + _stateName);
              this.loadingDeliveryAddress = false;
            }
          }
          else {
            this.showErrorMessage(result.message);
            this.loadingDeliveryAddress = false;
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loadingDeliveryAddress = false;
        });


    });

  }

  onFacilityChangeEvent(event) {
    this.GetFacilityFirstAvailableDateTimeByDurationIntial(this.facilitySlotDate, this.selectedApptDuration.code);
  }

  setMonthAvailability(crmFirstAvailableStartDate) {
    let firstAvailabilityDate = crmFirstAvailableStartDate;
    this.firstAvailabilityDate = firstAvailabilityDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate)
    this.today = this.facilitySlotDate;
    // var iCounter = this.facilitySlotDate.getMonth();
    // this.selectedMonth = new Months();
    // this.selectedMonth.month = this.monthNames[iCounter] + ", " + new Date(this.facilitySlotDate).getFullYear().toString();
    //this.previousSelectedMonth = this.selectedMonth;
    //this.getFacilitySlots(this.facilitySlotDate, true);
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    this.showConfirm = false;
    this.reminderOpted = false;
    this.clearSelection();
  }

  onRemoteFacilityChangeEvent(event) {


    let firstAvailabilityDate = this.facilitySlotDate;
    this.setRemoteFacilityData(firstAvailabilityDate);
    this.firstAvailabilityDate = firstAvailabilityDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate);

    this.today = this.facilitySlotDate;
    var iCounter = this.facilitySlotDate.getMonth();

    if (this.selectedRemoteAppointmentType == 'SelfGuided') {
      if (this.selfGuidedapptDuration != null && this.selfGuidedapptDuration != undefined) {
        this.selectedselfGuidedApptDuration = this.selfGuidedapptDuration.find(x => x.code == 15);
        this.getSelfGuidedRemoteFacilitySlots(this.facilitySlotDate, true);
      }
    }
    else {
      //this.GetRemoteMinAvailableDateTimeByDurationInitial(15);
      this.GetRemoteFirstAvailableDateTimeByDurationInitial(this.selectedApptDuration.code);
    }

    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    this.showConfirm = false;
    this.reminderOpted = false;
    this.clearSelection();
  }

  private async getFacilitiesByAddress(crmSalesOrderId) {
    if (!this.isBillingAddress && (!this.salesOrder.crmPatientDeliveryAddress || this.salesOrder.crmPatientDeliveryAddress == '')) {
      this.isDeliveryAddressAvailable = false;
      this.patientDetials = true;
      return;
    }
    this.isDeliveryAddressAvailable = true;

    this.loadingCustom = true;
    let facilitySearchRequest: FacilitySearchRequestModel = new FacilitySearchRequestModel();
    facilitySearchRequest.crmSalesOrderId = crmSalesOrderId;
    facilitySearchRequest.isBillingAddress = this.isBillingAddress;
    facilitySearchRequest.isAppointment = this.isAppointment;
    facilitySearchRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    //for time zone changes
    facilitySearchRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.appointmentService.SearchClinicsForAppointment(facilitySearchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .subscribe(async (result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.appointmentFacilityResponseModel = result.responseModel;
            this.isMedicareinsurance = this.appointmentFacilityResponseModel.isMedicareinsurance;
            this.isInsuranceStateNotFound = this.appointmentFacilityResponseModel.isInsuranceStateNotFound;
            this.isAppointmentTypeAvailable = this.appointmentFacilityResponseModel.isFacilitiesFound;
            if (this.appointmentFacilityResponseModel.isFacilitiesFound) {
              this.islotAvailable = true;
              this.islotAvailableForTheDay = true;
              this.selectedFacility = this.appointmentFacilityResponseModel.facilities;

            }
            else {
              this.isAppointmentTypeAvailable = this.appointmentFacilityResponseModel.isFacilitiesFound;
              this.islotAvailable = false;
              this.islotAvailableForTheDay = false;

            }

            console.log('isAppointmentTypeAvailable: ' + this.isAppointmentTypeAvailable);
            console.log('facilityId: ' + this.facilityId);

            if (this.isAppointmentTypeAvailable) {
              if (this.facilityId) {
                if (this.selectedFacility) {
                  let selectedFac = this.selectedFacility.find(x => x.crmFacilityId == this.facilityId);
                  console.log('selected Fac name: ' + selectedFac.crmFacilityId);
                  if (selectedFac) {

                    console.log('selected Fac name found: ' + selectedFac.crmFacilityId);

                    this.selectedFacilityLocal = selectedFac;
                  }
                  else
                    this.selectedFacilityLocal = this.selectedFacility[0];
                }

              }
              else
                this.selectedFacilityLocal = this.selectedFacility[0];

              if (this.selectedFacilityLocal) {
                //this.setFacilityData();
                console.log('selectedFacilityLocal: ' + this.selectedFacilityLocal.crmFacilityId);
                // for 60 min duration
                this.GetFacilityFirstAvailableDateTimeByDurationIntial(this.facilitySlotDate, this.selectedApptDuration.code);
                //this.getFacilitySlots(this.facilitySlotDate, true);
              }
            }
          }
        }
      });
  }

  private async getRemoteFacilitiesByAddress(crmSalesOrderId) {
    if (!this.isBillingAddress && (!this.salesOrder.crmPatientDeliveryAddress || this.salesOrder.crmPatientDeliveryAddress == '')) {
      this.isDeliveryAddressAvailable = false;
      this.patientDetials = true;
      return;
    }
    this.isDeliveryAddressAvailable = true;
    //this.loading = true;
    let facilitySearchRequest: FacilitySearchRequestModel = new FacilitySearchRequestModel();
    facilitySearchRequest.crmSalesOrderId = crmSalesOrderId;
    facilitySearchRequest.isBillingAddress = this.isBillingAddress;
    facilitySearchRequest.isAppointment = this.isAppointment;
    facilitySearchRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    this.patientTimezone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    facilitySearchRequest.patientTimeZone = this.patientTimezone;
    this.loading = true;
    this.appointmentService.SearchRemoteFacilitiesForAppointment(facilitySearchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.appointmentFacilityResponseModel = result.responseModel;
            this.isMedicareinsurance = this.appointmentFacilityResponseModel.isMedicareinsurance;
            this.isInsuranceStateNotFound = this.appointmentFacilityResponseModel.isInsuranceStateNotFound;
            this.isAppointmentTypeAvailable = this.appointmentFacilityResponseModel.isFacilitiesFound;
            if (this.appointmentFacilityResponseModel.isFacilitiesFound) {
              this.islotAvailable = true;
              this.islotAvailableForTheDay = true;
              this.selectedFacility = this.appointmentFacilityResponseModel.facilities;

            }
            else {
              this.isAppointmentTypeAvailable = this.appointmentFacilityResponseModel.isFacilitiesFound;
              this.islotAvailable = false;
              this.islotAvailableForTheDay = false;

            }

            if (this.isAppointmentTypeAvailable) {
              if (this.isRemoteAndFacility) {
                if (this.selectedFacility != null && this.selectedFacility != undefined) {
                  let selectedFac = this.selectedFacility.find(x => x.crmFacilityId == this.remoteFacilityId);
                  if (selectedFac != null && selectedFac != undefined)
                    this.selectedFacilityLocal = selectedFac;
                  else
                    this.selectedFacilityLocal = this.selectedFacility[0];
                }

              }
              else if (this.facilityId) {
                if (this.selectedFacility != null && this.selectedFacility != undefined) {
                  let selectedFac = this.selectedFacility.find(x => x.crmFacilityId == this.facilityId);
                  if (selectedFac != null && selectedFac != undefined)
                    this.selectedFacilityLocal = selectedFac;
                  else
                    this.selectedFacilityLocal = this.selectedFacility[0];
                }

              }
              else
                this.selectedFacilityLocal = this.selectedFacility[0];

              //for 60 min duration remote
              this.GetRemoteFirstAvailableDateTimeByDurationInitial(this.selectedApptDuration.code);

            }
          }
          else {
            this.islotAvailable = false;
            this.islotAvailableForTheDay = false;
          }
        }
        this.loading = false;
      });
  }


  private async getRoadShowFirstAvailable() {
    let CurrentDate: Date = new Date();
    this.minAvailabilityDate = null;
    this.islotAvailable = false;
    let datepass = (this.salesOrderdate != null && this.salesOrderdate != undefined && this.isAppoinmentBookByCalendar) ? moment(this.salesOrderdate).toDate() : moment(CurrentDate).toDate();
    //for Road/Show duration by 30
    //this.GetRoadShowMinAvailableDateTimeByDuration(datepass, 30);
    //for Road/Show duration by 60
    this.GetRoadShowFirstAvailableDateTimeByDurationInitial(datepass, this.selectedApptDuration.code);
  };

  private getRoadShowSlotsByTherapistId(facilityDate: Date, tInitialcall: boolean) {
    if (!this.isBillingAddress && (!this.salesOrder.crmPatientDeliveryAddress || this.salesOrder.crmPatientDeliveryAddress == '')) {
      this.isDeliveryAddressAvailable = false;
      this.patientDetials = true;
      return;
    }
    this.isDeliveryAddressAvailable = true;

    this.isGlobalHoliday = false;
    this.globalHolidayMsg = "";

    this.islotAvailable = true;
    this.islotAvailableForTheDay = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    this.facilitySlotRequest.AppointmentDateTime = facilityDate;
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal == undefined ? "" : this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = 30;
    this.facilitySlotRequest.isBillingAddress = this.isBillingAddress;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    this.facilitySlotRequest.SortOrder = this.slotSortOrder;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.loadingCustom = true;
    this.loading = true;
    let appointmentServiceRequest = this.isGoogleMap ? this.appointmentService.GetRoadShowTimeSlotsByTherapistsGoogleMap(this.facilitySlotRequest) : this.appointmentService.GetRoadShowTimeSlotsByTherapistsDME(this.facilitySlotRequest);

    appointmentServiceRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .toPromise()
      .then((result) => {
        if (result.isSuccess) {
          //this.loadingCustom = false
          if (result.responseModel != undefined && result.responseModel != null) {
            this.displaySlots = [];
            this.roadShowTimeSlotMainResponseModel = result.responseModel;
            this.roadShowTimeSlotsResponseModel = result.responseModel.timeslots;

            //if (this.roadShowTimeSlotsResponseModel.length > 0)
            //  this.islotAvailable = true;
            //else
            //  this.islotAvailable = false;

            if (this.roadShowTimeSlotMainResponseModel.isRTLicenseCheck == true && this.roadShowTimeSlotMainResponseModel.isInsuranceStateFound == false) {
              this.isInsuranceStateFound = false;
              this.islotAvailableForTheDay = false;
              this.islotAvailable = false;
              //this.disableNexPrevious();
              this.loading = false;
              this.loadingCustom = false;
              return;
            }
            else {
              this.isInsuranceStateFound = true;
              if (this.roadShowTimeSlotsResponseModel.length > 0)
                this.islotAvailable = true;
              else
                this.islotAvailable = false;

            }

            this.onApptDurationChangeSlot();
            let selectedDate = moment(this.salesOrderdate).toDate();
            let compareAppointmentDate = this.getFormat(facilityDate);
            let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)), maxDate = moment.min(moments);
            let passFormatDate1 = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
            let compareAppointmentDate1 = new DatePipe(this.locale).transform(facilityDate, 'MMM dd yyyy');
            var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
            //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
            if (selectedDate && this.isAppoinmentBookByCalendar) {

              let passFormatDate = this.getFormat(selectedDate);

              this.roadShowTimeSlotsResponseModel.forEach(value => {

                let compareDate = this.getFormat(value.appintmentDate);

                if (compareDate == passFormatDate) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;
                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      if (isSame) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                  this.isShowLocationInfo = true;
                  if (this.isGoogleMap)
                    this.setEstimatedTimeForGoogleMap(this.selectedStartDate, this.selectedEndDate, this.crmTherapsitId);
                }
                else if (compareDate == compareAppointmentDate && tInitialcall == true) {
                  this.isInitial = false;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;
                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      if (isSame) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else {
                  let compareDateNew = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                  let isCompare = true;
                  if (compareDateNew == compareDate1) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;
                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                    isCompare = false;
                  }
                  else if (compareDateNew == passFormatDate1 && isCompare) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;

                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                  }
                }

              });

              //condition to check whether the selected date from calendar have availability if not select the availability date
              if (this.roadShowTimeSlotsResponseModel.length > 0) {
                let format = "MM-DD-YYYY";
                let startDate = moment(this.firstAvailabilityDate).toDate();
                let endDate = moment(this.salesOrderdate).toDate();
                let startDateFormat = moment(startDate, format);
                let endDateFormat = moment(endDate, format);

                let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
                if (isSameOrAfter) {
                  this.salesOrderdate = this.firstAvailabilityDate;
                }
              }


            } else {

              this.loading = true;
              let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)),
                maxDate = moment.min(moments)
              let passFormatDate = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
              var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
              this.roadShowTimeSlotsResponseModel.forEach(value => {
                let compareDate = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                let isCompare = true;
                if (compareDate == compareDate1) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  isCompare = false;
                }
                else if (compareDate == passFormatDate && isCompare) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
                //added code for redirect first available date --10/05/2021
                else if (compareDate == compareAppointmentDate1 && tInitialcall == true) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
              });
              this.loading = false;
              this.loadingCustom = false;
            }

            if (this.crmFirstAvailableStartDate == undefined) {
              this.isAvailableSlot = false;
              //return;
            }
            else {
              this.isAvailableSlot = true;
            }
          }
          else {

            //changes made for Road/Home show Hide details for time slots
            this.islotAvailable = false;
            this.islotAvailableForTheDay = false;
          }
        }
        else {
          //changes made for Road/Home show Hide details for time slots
          this.islotAvailable = false;
          this.islotAvailableForTheDay = false;
        }
        this.loading = false;
        this.loadingCustom = false;
      }).catch((httpResponseError) => {
        this.loading = false;
        this.loadingCustom = false;
      });
  }

  private getFacilitySlots(facilityDate: Date, tInitialcall: boolean) {
    console.log('getFacilitySlots selectedFacilityLocal: ' + this.selectedFacilityLocal.crmFacilityId);
    console.log('getFacilitySlots facilityId: ' + this.facilityId);
    // for date selection and time slection
    this.loading = true;
    this.isGlobalHoliday = false;
    this.globalHolidayMsg = "";
    this.islotAvailable = true;
    this.islotAvailableForTheDay = true;
    this.isInsuranceStateFound = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal == undefined ? 0 : this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmClassificationDuration = 30;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    this.facilitySlotRequest.SortOrder = this.slotSortOrder;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;

    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal == undefined ? "" : this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmFacilityState = this.selectedFacilityLocal == undefined ? "" : this.selectedFacilityLocal.crmFacilityState;
    console.log('getFacilitySlots selectedFacilityLocal changed: ' + this.selectedFacilityLocal.crmFacilityId);

    this.appointmentService.GetFacilitySlotsByTherapistsDME(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.displaySlots = [];

            this.roadShowTimeSlotMainResponseModel = result.responseModel;
            this.roadShowTimeSlotsResponseModel = result.responseModel.timeslots;
            //if (this.roadShowTimeSlotsResponseModel.length > 0)
            //  this.islotAvailable = true;
            //else
            //  this.islotAvailable = false;


            if (this.roadShowTimeSlotMainResponseModel.isRTLicenseCheck == true && this.roadShowTimeSlotMainResponseModel.isInsuranceStateFound == false) {
              this.isInsuranceStateFound = false;
              this.islotAvailableForTheDay = false;
              this.islotAvailable = false;
              return;
            }
            else {
              this.isInsuranceStateFound = true;
              if (this.roadShowTimeSlotsResponseModel.length > 0)
                this.islotAvailable = true;
              else
                this.islotAvailable = false;

            }

            this.onApptDurationChangeSlot();
            let selectedDate = moment(this.salesOrderdate).toDate();
            let compareAppointmentDate = this.getFormat(facilityDate);

            let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)),
              maxDate = moment.min(moments)
            let passFormatDate1 = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
            let compareAppointmentDate1 = new DatePipe(this.locale).transform(facilityDate, 'MMM dd yyyy');
            var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
            //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
            if (selectedDate && this.isAppoinmentBookByCalendar) {
              let passFormatDate = this.getFormat(selectedDate);
              this.roadShowTimeSlotsResponseModel.forEach(value => {
                let compareDate = this.getFormat(value.appintmentDate);
                if (compareDate == passFormatDate) {

                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  var minutes = this.selectedApptDuration.code;
                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;

                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);

                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }

                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }

                }
                else if (compareDate == compareAppointmentDate && tInitialcall == true) {
                  this.isInitial = false;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;
                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else {
                  let compareDateNew = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                  let isCompare = true;
                  if (compareDateNew == compareDate1) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;
                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }

                    isCompare = false;
                  }
                  else if (compareDateNew == passFormatDate1 && isCompare) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;

                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                  }
                }

              });


              //condition to check whether the selected date from calendar have availability if not select the availability date
              if (this.roadShowTimeSlotsResponseModel.length > 0) {
                let format = "MM-DD-YYYY";
                let startDate = moment(this.firstAvailabilityDate).toDate();
                let endDate = moment(this.salesOrderdate).toDate();
                let startDateFormat = moment(startDate, format);
                let endDateFormat = moment(endDate, format);

                let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
                if (isSameOrAfter) {
                  this.salesOrderdate = this.firstAvailabilityDate;
                }
              }
            } else {
              this.loading = true;
              let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)),
                maxDate = moment.min(moments)
              let passFormatDate = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
              let compareAppointmentDate1 = new DatePipe(this.locale).transform(facilityDate, 'MMM dd yyyy');
              var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
              //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
              this.roadShowTimeSlotsResponseModel.forEach(value => {
                let compareDate = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                let isCompare = true;
                if (compareDate == compareDate1) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  isCompare = false;
                }
                else if (compareDate == passFormatDate && isCompare) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;

                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
                else if (compareDate == compareAppointmentDate1 && tInitialcall == true) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }

              });
              this.loading = false;

            }

            if (this.crmFirstAvailableStartDate == undefined) {
              this.isAvailableSlot = false;
              //return;
            }
            else {
              this.isAvailableSlot = true;
            }
          }
        }

      });
    //this.salesOrderdate = null;

    //this.loading = false;
  }

  private getRemoteFacilitySlots(facilityDate: Date, tInitialcall: boolean) {
    this.isGlobalHoliday = false;
    this.globalHolidayMsg = "";
    this.loadingCustom = true;
    this.islotAvailable = true;
    this.islotAvailableForTheDay = true;
    this.isInsuranceStateFound = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();

    this.facilitySlotRequest.AppointmentDateTime = facilityDate;
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = 30;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    this.facilitySlotRequest.SortOrder = this.slotSortOrder;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    if (this.isAppoinmentBookByCalendar) {
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    }

    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.appointmentService.GetRemoteSlotsByTherapistsDME(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .subscribe((result) => {

        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.displaySlots = [];
            this.roadShowTimeSlotMainResponseModel = result.responseModel;
            this.roadShowTimeSlotsResponseModel = result.responseModel.timeslots;

            if (this.roadShowTimeSlotMainResponseModel.isRTLicenseCheck == true && this.roadShowTimeSlotMainResponseModel.isInsuranceStateFound == false) {
              this.isInsuranceStateFound = false;
              this.islotAvailableForTheDay = false;
              this.islotAvailable = false;
              this.loadingCustom = false;
              return;
            }
            else {
              this.isInsuranceStateFound = true;
              if (this.roadShowTimeSlotsResponseModel.length > 0)
                this.islotAvailable = true;
              else
                this.islotAvailable = false;

            }

            this.onApptDurationChangeSlot();
            let selectedDate = moment(this.salesOrderdate).toDate();
            let compareAppointmentDate = this.getFormat(facilityDate);
            let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)), maxDate = moment.min(moments);
            let passFormatDate1 = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
            let compareAppointmentDate1 = new DatePipe(this.locale).transform(facilityDate, 'MMM dd yyyy');
            // var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
            var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
            if (selectedDate && this.isAppoinmentBookByCalendar) {

              let passFormatDate = this.getFormat(selectedDate);
              this.roadShowTimeSlotsResponseModel.forEach(value => {

                let compareDate = this.getFormat(value.appintmentDate);
                if (compareDate == passFormatDate) {
                  ;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;

                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;
                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;

                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {

                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        // default selection of the time if the booking is from calendar
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));

                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else if (compareDate == compareAppointmentDate && tInitialcall == true) {
                  this.isInitial = false;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;

                  let dateSelection = new Date(selectedDate);
                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else {
                  let compareDateNew = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                  let isCompare = true;
                  if (compareDateNew == compareDate1) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;
                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }

                    isCompare = false;
                  }
                  else if (compareDateNew == passFormatDate1 && isCompare) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;

                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                  }
                }
              });

              //condition to check whether the selected date from calendar have availability if not select the availability date
              if (this.roadShowTimeSlotsResponseModel.length > 0) {
                let format = "MM-DD-YYYY";
                let startDate = moment(this.firstAvailabilityDate).toDate();
                let endDate = moment(this.salesOrderdate).toDate();
                let startDateFormat = moment(startDate, format);
                let endDateFormat = moment(endDate, format);

                let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
                if (isSameOrAfter) {
                  this.salesOrderdate = this.firstAvailabilityDate;
                }
              }
              this.loadingCustom = false;
            } else {



              let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)),
                maxDate = moment.min(moments)
              let passFormatDate = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
              var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
              //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
              this.roadShowTimeSlotsResponseModel.forEach(value => {
                let compareDate = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                let isCompare = true;
                if (compareDate == compareDate1) {
                  ;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;

                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  isCompare = false;
                }
                else if (compareDate == passFormatDate && isCompare) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;

                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
                else if (compareDate == compareAppointmentDate1 && tInitialcall == true) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
              });
              this.loadingCustom = false;

            }

            if (this.crmFirstAvailableStartDate == undefined) {
              this.isAvailableSlot = false;
              //return;
            }
            else {
              this.isAvailableSlot = true;
            }
          }
        }
        this.loadingCustom = false;
      });
    //this.salesOrderdate = null;
    //this.loadingCustom = false;
  }

  private getSelfGuidedRemoteFacilitySlots(facilityDate: Date, tInitialcall: boolean) {
    this.isGlobalHoliday = false;
    this.globalHolidayMsg = "";

    this.loading = true;
    this.islotAvailable = true;
    this.islotAvailableForTheDay = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    this.facilitySlotRequest.AppointmentDateTime = facilityDate;
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = this.selectedselfGuidedApptDuration.code;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;

    this.appointmentService.GetSelfGuidedRemoteSlotsByTherapistsDME(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.displaySlots = [];

            this.roadShowTimeSlotMainResponseModel = result.responseModel;
            this.roadShowTimeSlotsResponseModel = result.responseModel.timeslots;
            if (this.roadShowTimeSlotsResponseModel.length > 0)
              this.islotAvailable = true;
            else
              this.islotAvailable = false;
            //this.onApptDurationChangeSlot();
            this.onSelfGuidedApptDurationChangeSlot();
            let selectedDate = moment(this.salesOrderdate).toDate();
            let compareAppointmentDate = this.getFormat(facilityDate);
            let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)), maxDate = moment.min(moments);
            let passFormatDate1 = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
            let compareAppointmentDate1 = new DatePipe(this.locale).transform(facilityDate, 'MMM dd yyyy');
            var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
            //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
            if (selectedDate && this.isAppoinmentBookByCalendar) {

              let passFormatDate = this.getFormat(selectedDate);
              this.roadShowTimeSlotsResponseModel.forEach(value => {

                let compareDate = this.getFormat(value.appintmentDate);
                if (compareDate == passFormatDate) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;

                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }

                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedselfGuidedApptDuration.code//this.selectedApptDuration.code;

                  let dateSelection = new Date(selectedDate);

                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {
                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else if (compareDate == compareAppointmentDate && tInitialcall == true) {
                  this.isInitial = false;
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
                  var minutes = this.selectedApptDuration.code;

                  let dateSelection = new Date(selectedDate);
                  this.selectedTherapistId = this.crmTherapsitId;
                  this.selectedTherapistName = this.crmTherapsitName;
                  this.selectedPractionerId = this.crmPractionerId;
                  this.selectedDeliveryTechnicianId = this.crmDeliveryTechnicianId;
                  let format = 'hh:mm:ss'
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    for (let value1 of this.selectedRoadShowTimeSlotTherapistModel[0].timeSlotAppointmentInfo) {
                      let startDate = moment(value1.startTime).toDate();
                      let endDate = moment(value1.endTime).toDate();
                      let beforeTime = moment(startDate, format);
                      let afterTime = moment(endDate, format);
                      let time = moment(dateSelection, format);
                      let isSame = time.isSame(beforeTime);
                      // to check if the its in the same day or not if selection is from calendar
                      let isToday = time.isSame(beforeTime, 'day');

                      // to check if the time selected from calendar is more than the first avalability time
                      let inTimeRange = time.isSameOrAfter(beforeTime)
                      if (isSame || (isToday && inTimeRange)) {
                        this.selectedEndDate = new Date(new Date(selectedDate).setMinutes(new Date(selectedDate).getMinutes() + minutes));
                        this.selectedStartDate = new Date(selectedDate);
                        this.selectedOrigEndDate = new Date(new Date(value1.origStartTime).setMinutes(new Date(value1.origStartTime).getMinutes() + minutes));
                        this.selectedOrigStartDate = new Date(value1.origStartTime);
                      }
                      if (isSame && (value1.isBlock || value1.isBlock1 || value1.isAppointment)) {

                        this.selectedEndDate = null;
                        this.selectedStartDate = null;
                        this.selectedOrigEndDate = null;
                        this.selectedOrigStartDate = null;
                        break;
                      }
                    }
                }
                else {
                  let compareDateNew = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                  let isCompare = true;
                  if (compareDateNew == compareDate1) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;
                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                    isCompare = false;
                  }
                  else if (compareDateNew == passFormatDate1 && isCompare) {
                    this.selectedRoadShowDate = value.appintmentDate;
                    this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                    if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                      this.islotAvailableForTheDay = true;

                    else
                      this.islotAvailableForTheDay = false;

                    if (value.isHoliday) {
                      this.isGlobalHoliday = true;
                      this.globalHolidayMsg = value.holidayMessage;
                    }
                  }
                }
              });

              //condition to check whether the selected date from calendar have availability if not select the availability date
              if (this.roadShowTimeSlotsResponseModel.length > 0) {
                let format = "MM-DD-YYYY";
                let startDate = moment(this.firstAvailabilityDate).toDate();
                let endDate = moment(this.salesOrderdate).toDate();
                let startDateFormat = moment(startDate, format);
                let endDateFormat = moment(endDate, format);

                let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
                if (isSameOrAfter) {
                  this.salesOrderdate = this.firstAvailabilityDate;
                }
              }


            } else {

              this.loading = true;
              let moments = this.roadShowTimeSlotsResponseModel.map(d => moment(d.appintmentDate)),
                maxDate = moment.min(moments)
              let passFormatDate = new DatePipe(this.locale).transform(maxDate.toDate(), 'MMM dd yyyy');
              var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code);
              //var compareDate1 = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.appDateFormat.timeZone);
              this.roadShowTimeSlotsResponseModel.forEach(value => {
                let compareDate = new DatePipe(this.locale).transform(value.appintmentDate, 'MMM dd yyyy');
                let isCompare = true;
                if (compareDate == compareDate1) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                  isCompare = false;
                }
                else if (compareDate == passFormatDate && isCompare) {
                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;
                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
                else if (compareDate == compareAppointmentDate1 && tInitialcall == true) {

                  this.selectedRoadShowDate = value.appintmentDate;
                  this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
                  if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
                    this.islotAvailableForTheDay = true;
                  else
                    this.islotAvailableForTheDay = false;

                  if (value.isHoliday) {
                    this.isGlobalHoliday = true;
                    this.globalHolidayMsg = value.holidayMessage;
                  }
                }
              });
              this.loading = false;

            }

            //this disable months
            //this.disabledMonths(this.facilitySlotDate);

            if (this.crmFirstAvailableStartDate == undefined) {
              this.isAvailableSlot = false;
              //return;
            }
            else {
              this.isAvailableSlot = true;
            }
          }
        }

      });
    //this.salesOrderdate = null;
    //this.loading = false;
  }

  private GetRemoteFirstAvailableDateTimeByDuration(facilityDate: Date, duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;

    this.appointmentService.GetRemoteFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.crmFirstAvailableStartDate = result.responseModel;
            this.minAllowDateToBookAppoitment = new Date(result.responseModel);
          }
        }
        this.loading = false;
      });
    //this.salesOrderdate = null;
  }

  private GetRemoteFirstAvailableDateTimeByDurationInitial(duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(new Date(), 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    var currentDate = new Date();
    this.facilitySlotRequest.AppointmentDateTimeString = currentDate.toDateString() + ' ' + currentDate.toTimeString().split(' ')[0];
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;

    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    if (this.isAppoinmentBookByCalendar) {
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    }

    this.appointmentService.GetRemoteFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {

        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.minAllowDateToBookAppoitment = new Date(result.responseModel);
            this.minAvailabilityDate = result.responseModel;
            this.crmFirstAvailableStartDate = result.responseModel;
            this.setRemoteFirstAvailableDateandFetchSlotonDuration(this.crmFirstAvailableStartDate);
          }
          else {
            this.minAllowDateToBookAppoitment = new Date();
          }
        }
        this.loading = false;
      });
  }



  private GetRemoteMinAvailableDateTimeByDurationInitial(duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(new Date(), 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    var currentDate = new Date();
    this.facilitySlotRequest.AppointmentDateTimeString = currentDate.toDateString() + ' ' + currentDate.toTimeString().split(' ')[0];
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    if (this.isAppoinmentBookByCalendar) {
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    }
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;

    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    this.appointmentService.GetRemoteFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.minAvailabilityDate = result.responseModel;
          }
        }
        this.loading = false;
      });
  }

  private GetFacilityFirstAvailableDateTimeByDuration(facilityDate: Date, duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;

    this.appointmentService.GetFacilityFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {

          if (result.responseModel != undefined && result.responseModel != null) {
            this.crmFirstAvailableStartDate = result.responseModel;
            this.minAllowDateToBookAppoitment = new Date(result.responseModel);
          }
        }
        this.loading = false;
      });
  }


  private GetRoadShowFirstAvailableDateTimeByDuration(facilityDate: Date, duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmTimeZone : '';
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    //this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.appointmentService.GetRoadShowFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {

            this.crmFirstAvailableStartDate = result.responseModel;
            this.minAllowDateToBookAppoitment = new Date(result.responseModel);
            //check for the previous and next icons
            //this.checkforthePrevFlag(facilityDate, this.minAvailabilityDate);
          }
          else {
            this.crmFirstAvailableStartDate = this.minAvailabilityDate;
            this.minAllowDateToBookAppoitment = new Date(this.minAvailabilityDate);
          }
        }
        this.loading = false;
      });
    //this.salesOrderdate = null;
  }


  private GetRoadShowFirstAvailableDateTimeByDurationInitial(facilityDate: Date, duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal == undefined ? "" : this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrder.crmDeliveryAddressState;

    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;

    this.appointmentService.GetRoadShowFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {

        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.minAvailabilityDate = result.responseModel;
            this.crmFirstAvailableStartDate = result.responseModel;

          }
          else {
            this.isInsuranceStateFound = false;
            this.islotAvailable = false;
            this.isAppointmentTypeAvailable = false;
            this.crmFirstAvailableStartDate = null;
            this.loading = false;
            return;

          }

          let firstAvailabilityDate = this.crmFirstAvailableStartDate;
          //let firstAvailabilityDate = this.crmFirstAvailableStartDate;
          if (this.isAppoinmentBookByCalendar && this.salesOrderdate) {

            let format = 'DD-MMM-yyyy';
            let crmFirstAvailableStartDate = moment(this.crmFirstAvailableStartDate).toDate();
            let salesOrderdate = moment(this.salesOrderdate).toDate();
            let beforeTime = moment(crmFirstAvailableStartDate, format);
            let afterTime = moment(salesOrderdate, format);
            let time = moment(this.salesOrderdate, format);
            let isSameOrAfter = time.isSameOrAfter(beforeTime);
            if (isSameOrAfter)
              firstAvailabilityDate = this.salesOrderdate;
          }
          this.firstAvailabilityDate = firstAvailabilityDate;
          this.facilitySlotDate = new Date(firstAvailabilityDate)
          this.today = this.facilitySlotDate;
          var iCounter = this.facilitySlotDate.getMonth();


          if (this.crmFirstAvailableStartDate == undefined) {
            this.isAvailableSlot = false;
            //return;
          }
          else {
            this.isAvailableSlot = true;
          }


          this.minAllowDateToBookAppoitment = new Date(result.responseModel);
          this.setMonthAvailability(this.firstAvailabilityDate);
          this.getRoadShowSlotsByTherapistId(this.firstAvailabilityDate, true);
          this.facilitySlotDate = new Date(this.firstAvailabilityDate)
          this.today = this.facilitySlotDate;
          var iCounter = this.facilitySlotDate.getMonth();
        }
        this.loading = false;
      });
    //this.salesOrderdate = null;
  }


  getFormat(date) {
    return moment(date).format('LL')
  }

  isSlotDisabled(date) {
    let currentDate = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.patientTimezone).code);
    //let currentDate = new DatePipe(this.locale).transform(new Date(), 'MMM DD YYYY', this.appDateFormat.timeZone);
    if (moment(date).toDate() < moment(currentDate).toDate())
      return true;
    else
      return false;
  }

  isSlotDefaultSelected(date) {
    let selectedDate = moment(this.salesOrderdate).toDate();
    if (selectedDate && this.isAppoinmentBookByCalendar) {
      let slotDate = moment(date).toDate();
      if (slotDate.toDateString() == selectedDate.toDateString() && slotDate.toTimeString() == selectedDate.toTimeString()) {
        return true;
      }
      else
        return false;
    }
    else
      return false;
  }

  isSlotSelected(date, therapistId, therapistFullName) {
    let selectedDate = moment(this.salesOrderdate).toDate();
    if (selectedDate) {
      let slotDate = moment(date).toDate();
      if (slotDate.toDateString() == selectedDate.toDateString() && slotDate.toTimeString() == selectedDate.toTimeString()
        && this.selectedTherapistId == therapistId
      ) {
        return true;
      }
      else
        return false;
    }
    else
      return false;
  }

  isSlotsSelect(date, therapistId) {
    let selectedDate = moment(this.salesOrderdate).toDate();
    if (selectedDate) {
      let slotDate = moment(date).toDate();
      if (slotDate >= this.selectedStartDate && slotDate < this.selectedEndDate && this.selectedTherapistId == therapistId) {
        let result = true;

        let therapist = this.selectedRoadShowTimeSlotTherapistModel.find(e => e.therapistId == therapistId);
        therapist.timeSlotAppointmentInfo.forEach(element => {
          if (moment(element.startTime).toDate() >= this.selectedStartDate && moment(element.startTime).toDate() < this.selectedEndDate &&
            (element.isBlock || element.isBreak)) {
            result = false;
          }
        });
        return result;
      }
      else
        return false;
    }
    else
      return false;
  }

  isSlotsSelectRt(date, therapistId) {
    let selectedDate = moment(this.salesOrderdate).toDate();
    if (selectedDate) {
      let slotDate = moment(date).toDate();
      if (slotDate >= this.selectedStartDate && slotDate < this.selectedEndDate && this.selectedTherapistId == therapistId) {
        let result = true;

        let therapist = this.selectedRoadShowTimeSlotTherapistModel.find(e => e.therapistId == therapistId);
        therapist.timeSlotAppointmentInfo.forEach(element => {
          if (moment(element.startTime).toDate() >= this.selectedStartDate && moment(element.startTime).toDate() < this.selectedEndDate &&
            (element.isBlock || element.isBreak)) {
            result = false;
          }
        });
        return result;
      }
      else
        return false;
    }
    else
      return false;
  }

  openAppointmentSlot(event, selectedAppointmentSlot: RoadShowTimeSlotResponseModel, overlaypanel: OverlayPanel) {
    this.selectedAppointmentSlot = selectedAppointmentSlot;
    overlaypanel.toggle(event);
  }

  openBlockSlotDescription(event, BlockedBy: any, blockDescription: any, overlaypanel: OverlayPanel) {
    this.BlockedBy = BlockedBy ? BlockedBy : 'Appoint360';
    this.BlockDescription = blockDescription ? blockDescription : 'Slot not available due to timezone effect';
    overlaypanel.toggle(event);
  }
  // onRoadShowTimeSelect(date, therapistId, therapistFullName, practionarId) {
  //   this.salesOrderdate = date;
  //   // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
  //   var minutes = this.selectedApptDuration.code;
  //   if (this.selectedRemoteAppointmentType && this.selectedRemoteAppointmentType.toLowerCase() == 'selfguided') {
  //     minutes = this.selectedselfGuidedApptDuration.code;
  //   }

  //   this.selectedStartDate = moment(date).toDate();
  //   this.selectedEndDate = moment(date).add(minutes, 'minutes').toDate();
  //   //this.selectedEndDate = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + minutes));
  //   this.selectedTherapistId = therapistId;
  //   this.selectedTherapistName = therapistFullName;
  //   this.selectedPractionerId = practionarId;
  // }

   handleTimeSlotTooltipClick(event: MouseEvent, item1: any, Appointmentinfo: any, Blockinfo: any): void {
     if (item1.isAppointment && this.appointment.schAppointmentId !== item1.appointmentId || item1.isBlock1) {
       this.openAppointmentSlot(event, item1, Appointmentinfo);
      } else {
        this.onRoadShowTimeSelectNew(item1);
      }
      if (item1.isBlock) {
        this. openBlockSlotDescription(event, item1.blockName, item1.blockReason, Blockinfo);
      }
}


  onRoadShowTimeSelectNew(item1: RoadShowTimeSlotResponseModel) {

    let date = item1.startTime;
    let origDate = item1.origStartTime;
    let therapistId = item1.therapistId;
    let therapistFullName = item1.therapistFullName;
    let practionarId = item1.practitionerId;

    this.salesOrderdate = date;
    // var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
    var minutes = this.selectedApptDuration.code;
    if (this.selectedRemoteAppointmentType && this.selectedRemoteAppointmentType.toLowerCase() == 'selfguided') {
      minutes = this.selectedselfGuidedApptDuration.code;
    }

    this.selectedStartDate = moment(date).toDate();
    this.selectedEndDate = moment(date).add(minutes, 'minutes').toDate();

    this.selectedOrigStartDate = moment(origDate).toDate();
    this.selectedOrigEndDate = moment(origDate).add(minutes, 'minutes').toDate();

    //this.selectedEndDate = new Date(new Date(date).setMinutes(new Date(date).getMinutes() + minutes));
    this.selectedTherapistId = therapistId;
    this.selectedTherapistName = therapistFullName;
    this.selectedPractionerId = practionarId;
    this.selectedDeliveryTechnicianId = item1.deliveryTechnicianId;
    this.selectedtherapistBasedTimeZone = item1.slotTimeZone;
    if (this.selectedAppointmentTypePopUp == 'Home Visit' && this.islotAvailable && this.isGoogleMap) {
      this.isShowLocationInfo = true;
      this.setEstimatedTimeForGoogleMap(this.selectedStartDate, this.selectedEndDate, item1.therapistId);
    }

  }

  setSelectedDateAndTherapist(appointmentDate: Date) {

    this.isGlobalHoliday = false;
    this.globalHolidayMsg = "";
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedTherapistId = null;
    this.selectedPractionerId = null;
    this.selectedDeliveryTechnicianId = null;
    this.selectedTherapistName = null;
    let passFormatDate = this.getFormat(appointmentDate);
    this.roadShowTimeSlotsResponseModel.forEach(value => {

      let compareDate = this.getFormat(value.appintmentDate);
      if (compareDate == passFormatDate) {
        this.selectedRoadShowDate = value.appintmentDate;
        this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
        if (this.selectedRoadShowTimeSlotTherapistModel.length > 0)
          this.islotAvailableForTheDay = true;
        else
          this.islotAvailableForTheDay = false;


        if (value.isHoliday) {
          this.isGlobalHoliday = true;
          this.globalHolidayMsg = value.holidayMessage;
        }
        return;
      }

    });
    //let defaultSeletedDate = this.roadShowTimeSlotsResponseModel.find(x => this.getFormat(x.appintmentDate) >= this.getFormat(appointmentDate))
    //if (defaultSeletedDate) {
    //  this.selectedRoadShowDate = defaultSeletedDate.appintmentDate;
    //  this.selectedRoadShowTimeSlotTherapistModel = defaultSeletedDate.therapist;
    //}
    //console.log('week slot Vailable' + this.islotAvailable);
    //console.log('Day slot Vailable' + this.islotAvailableForTheDay);
  }

  setFacilityData(crmFirstAvailableStartDate) {

    this.crmFacilityDistanceInMile = this.selectedFacilityLocal.crmFacilityDistanceInMiles;
    this.crmFacilityAddress = this.selectedFacilityLocal.crmFacilityAddress;
    this.crmIsDoctor = this.selectedFacilityLocal.isDoctorFacility;

    this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal.crmFacilityWeekDaysAvailability;
    this.crmFacilityWeekendAvailability = this.selectedFacilityLocal.crmFacilityWeekendAvailability;

    let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;
    this.firstAvailabilityDate = crmFirstAvailableStartDate;

    this.facilityAvailableWeekDays = this.selectedFacilityLocal.facilityAvailableWeekDays;
    this.facilityAvailableWeekend = this.selectedFacilityLocal.facilityAvailableWeekend;

    let mostMinDate = new Date(Math.min.apply(null, this.facilityAvailableWeekDays.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMinDate.getTime())) {
      this.selectedFacilityWeekDayMinDate = this.facilityAvailableWeekDays.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMinDate.getDate();
      })[0];
    }

    let mostMaxDate = new Date(Math.max.apply(null, this.facilityAvailableWeekDays.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMaxDate.getTime())) {
      this.selectedFacilityWeekDayMaxDate = this.facilityAvailableWeekDays.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMaxDate.getDate();
      })[0];
    }

    let mostMinWeekendDate = new Date(Math.min.apply(null, this.facilityAvailableWeekend.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMinWeekendDate.getTime())) {
      this.selectedFacilityWeekendMinDate = this.facilityAvailableWeekend.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMinWeekendDate.getDate();
      })[0];
    }


    let mostMaxWeekendDate = new Date(Math.max.apply(null, this.facilityAvailableWeekend.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMaxWeekendDate.getTime())) {
      this.selectedFacilityWeekendMaxDate = this.facilityAvailableWeekend.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMaxWeekendDate.getDate();
      })[0];
    }

    this.facilitySlotDate = new Date(crmFirstAvailableStartDate);
  }

  setRemoteFacilityData(crmFirstAvailableStartDate) {
    this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal.crmFacilityWeekDaysAvailability;
    this.crmFacilityWeekendAvailability = this.selectedFacilityLocal.crmFacilityWeekendAvailability;

    let firstAvailabilityDate = crmFirstAvailableStartDate;
    this.firstAvailabilityDate = firstAvailabilityDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate);
  }

  clearSelectionRemoteAndFacility() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    this.showConfirm = false;

  }

  clearSelection() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    this.selectedTherapistId = null;
    this.selectedPractionerId = null;
    this.selectedDeliveryTechnicianId = null;
    this.selectedTherapistName = null;
    this.showConfirm = false;

  }

  onDateSelection() {
    this.isShowLocationInfo = false;
    this.directionsResults = null;
    this.markers = [];
    if (this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth') {
      if (this.selectedRemoteAppointmentType && this.selectedRemoteAppointmentType.toLowerCase() == 'selfguided') {
        this.getSelfGuidedRemoteFacilitySlots(this.facilitySlotDate, false);
      } else {
        this.getRemoteFacilitySlots(this.facilitySlotDate, false);
      }
    }
    else if (this.selectedAppointmentTypePopUp.toLowerCase() == "home visit") {
      this.getRoadShowSlotsByTherapistId(this.facilitySlotDate, false);
    }
    else
      this.getFacilitySlots(this.facilitySlotDate, false);

    this.clearSelection();
  }

  onTimeSelect(time: string, item: DisplaySlotsTime, itemDate: DisplaySlots) {

    var minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
    //if (this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth') {
    //  minutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
    //}
    //else
    if (this.selectedRemoteAppointmentType && this.selectedRemoteAppointmentType.toLowerCase() == 'selfguided') {
      minutes = this.selectedselfGuidedApptDuration.code;
    }
    else
      minutes = this.selectedApptDuration.code;

    //this.selectedStartDate = new Date(time);
    var date = moment(time).tz('America/New_York').format('DD-MMM-yyyy hh:mm:ss');
    this.selectedStartDate = moment(date).toDate();
    this.selectedEndDate = new Date(new Date(time).setMinutes(new Date(time).getMinutes() + minutes));
    if (this.prevdisplaySlotsTime) {
      this.prevdisplaySlotsTime.isSelected = false;
    }
    if (this.prevdisplayDate) {
      this.prevdisplayDate.isToday = false;
    }
    item.isSelected = true;
    itemDate.isToday = true;
    this.prevdisplaySlotsTime = item;
    this.prevdisplayDate = itemDate;
    this.showConfirm = false;

    this.reminderOpted = false;

    //this.facilityTherapistAvalabilityRequestModel = new FacilityTherapistAvalabilityRequestModel()
    //this.facilityTherapistAvalabilityRequestModel.tIsDefault = false;
    //this.facilityTherapistAvalabilityRequestModel.facilityID = this.selectedFacilityLocal.crmFacilityId;
    //this.facilityTherapistAvalabilityRequestModel.appointmentDateStartTime = this.selectedStartDate;
    //this.facilityTherapistAvalabilityRequestModel.appointmentDateEndTime = this.selectedEndDate;
    //this.GetAvailableTherapistByFacility();
  }

  onConfirm() {

    if (!this.selectedStartDate || !this.selectedEndDate) {
      this.showWarningMessage("Please select appointment date & time.");
      return;
    }
    let AppoinmentType: string = this.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');

    if (AppoinmentType == "Telehealth" && !this.selectedRemoteAppointmentType) {
      this.showWarningMessage("Please select remote setup type.");
      return;
    }


    if (this.isBillingAddress) {
      if (this.salesOrder.patientInfo.crmBillingAddressTimeZone == undefined || this.salesOrder.patientInfo.crmBillingAddressTimeZone == null || this.salesOrder.patientInfo.crmBillingAddressTimeZone == "") {
        this.showWarningMessage("Zip Code Is Missing In Billing Address.");
        return;
      }
    }
    else {
      if (this.salesOrder.crmDeliveryAddressTimeZone == undefined || this.salesOrder.crmDeliveryAddressTimeZone == null || this.salesOrder.crmDeliveryAddressTimeZone == "") {
        this.showWarningMessage("Zip Code Is Missing In Delivery Address.");
        return;
      }
    }


    if (!this.isRescheduledRequest) {
      //need to change here patientTimezone
      let appointmentRequest = this.appointmentService.CheckPerDayAppointmentOfApptByClassification(this.crmSalesOrderId, this.selectedOrigStartDate.toDateString(), true, this.selectedtherapistBasedTimeZone);
      this.loadingCustom = true;

      appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
        .subscribe((result) => {
          if (!result.responseModel) {
            const refcheck = this.dialougeService.open(InformationDialogComponent, {
              data:
              {
                //message: 'This classification type has reached the maximum number of appointments per day. If you want to increase the limit for the appointment per day. Please go to the Settings => Brightree => Classification Settings.',
                message: `The classification "${this.salesOrder.crmClassification}" has reached the maximum number of appointments for ${moment(this.selectedOrigStartDate).format('MM/DD/YYYY')}.`,
              },
              header: 'Information'
            });

            refcheck.onClose.subscribe((response: boolean) => {
              if (response) {
                return;
                this.confirmDetialsPage = false;
                this.OpenCheck = false;
              }
            });
            return;
          }
          else {
            this.confirmNavigation();
          }
        })

    } else {
      this.confirmNavigation();
    }

  }

  confirmNavigation() {

    let AppoinmentType: string = this.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');
    let crmFacilityAddress = '';
    let crmFacilityName = '';
    let distance = '';
    let duration = '';
    if (AppoinmentType.toLowerCase() == "homevisit") {
      crmFacilityAddress = this.salesOrder.patientInfo.crmPatientAddress;
      crmFacilityName = 'Home Visit';
      distance = '';
      duration = this.selectedApptDuration.name;
    }
    else if (AppoinmentType.toLowerCase() == "telehealth") {
      crmFacilityAddress = '';
      crmFacilityName = this.selectedFacilityLocal.crmFacilityName;
      distance = '';
      duration = this.selectedRemoteAppointmentType == 'SelfGuided' ? this.selectedselfGuidedApptDuration.name : this.selectedApptDuration.name;
    }
    else {
      duration = this.selectedApptDuration.name;
      crmFacilityAddress = this.selectedFacilityLocal.crmFacilityAddress;
      crmFacilityName = this.selectedFacilityLocal.crmFacilityName;
      distance = this.selectedFacilityLocal.crmFacilityDistanceInMiles;
    }

    let facilityId: number = null;
    if (AppoinmentType.toLowerCase() != "homevisit")
      facilityId = this.selectedFacilityLocal.crmFacilityId;

    this.appointmentConfirmationInfo = new AppointmentConfirmationInfo();
    this.appointmentConfirmationInfo.isAppoinment = this.isAppointment;


    this.appointmentConfirmationInfo.schReminderOpted = this.schReminderOpted;
    this.appointmentConfirmationInfo.schIsReminderOptedSms = this.schIsReminderOptedSms;
    this.appointmentConfirmationInfo.schIsReminderOptedEmail = this.schIsReminderOptedEmail;


    this.appointmentConfirmationInfo.isRescheduledRequest = this.isRescheduledRequest;
    this.appointmentConfirmationInfo.facilityId = facilityId;
    this.appointmentConfirmationInfo.crmSalesOrderId = this.crmSalesOrderId;
    this.appointmentConfirmationInfo.schAppointmentId = this.schAppointmentId;
    this.appointmentConfirmationInfo.therapistId = this.selectedTherapistId;
    this.appointmentConfirmationInfo.crmRediretFrom = this.crmRediretFrom;
    this.appointmentConfirmationInfo.crmWIPStatusNameBT = this.salesOrder.crmWIPStatusNameBT;
    this.appointmentConfirmationInfo.crmWIPAssignedToKey = this.salesOrder.crmWIPAssignedToKey;
    this.appointmentConfirmationInfo.WIPStateAssignmentType = this.WIPStateAssignmentType;
    this.appointmentConfirmationInfo.UpdateWipStateOnRescheduled = this.UpdateWipStateOnRescheduled;

    this.appointmentConfirmationInfo.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
    this.appointmentConfirmationInfo.OrderNote = this.salesOrder.crmSalesorderOrderNote;
    this.appointmentConfirmationInfo.isDefaultWIPNeededDateAppointmentdate = this.isDefaultWIPNeededDateAppointmentdate;
    if (this.isAppoinmentBookByCalendar && this.crmTherapsitId > 0)
      this.appointmentConfirmationInfo.therapistId = this.crmTherapsitId;

    if (this.remoteAppointmentTypeModel != null && this.remoteAppointmentTypeModel != undefined) {
      let remoteType = this.remoteAppointmentTypeModel.find(x => x.crmRemoteAppointmentType == this.selectedRemoteAppointmentType);
      if (remoteType)
        this.appointmentConfirmationInfo.schRemoteAppointmentTypeDisplayName = remoteType.crmRemoteAppointmentTitle;
    }


    this.appointmentConfirmationInfo.crmPractionerId = this.selectedPractionerId ? Number(this.selectedPractionerId) : 0;
    this.appointmentConfirmationInfo.crmDeliveryTechnicianId = this.selectedDeliveryTechnicianId ? Number(this.selectedDeliveryTechnicianId) : 0;
    this.appointmentConfirmationInfo.schRemoteAppointmentType = this.selectedRemoteAppointmentType;

    this.appointmentConfirmationInfo.selectedAppointmentTypePopUp = this.selectedAppointmentTypePopUp;
    this.appointmentConfirmationInfo.crmPatientname = this.salesOrder.patientInfo.crmPatientFirstName + ' ' + this.salesOrder.patientInfo.crmPatientLastName;
    this.appointmentConfirmationInfo.crmTherapistname = this.selectedTherapistName;//this.selectedTherapistAvalabilityModel.crmTherapistName;//this.selectedTherapist.crmTherapistName;
    this.appointmentConfirmationInfo.appointmentStartDate = this.selectedStartDate;
    this.appointmentConfirmationInfo.appointmentEndDate = this.selectedEndDate;
    this.appointmentConfirmationInfo.appointmentOrigStartDate = this.selectedOrigStartDate;
    this.appointmentConfirmationInfo.appointmentOrigEndDate = this.selectedOrigEndDate;
    this.appointmentConfirmationInfo.appointmentTimeZone = this.selectedTimeZone.name;
    this.appointmentConfirmationInfo.appointmentTimeZoneShortName = this.selectedTimeZone.shortName;
    this.appointmentConfirmationInfo.appointmentTimeZoneCode = this.selectedTimeZone.code;
    this.appointmentConfirmationInfo.facilityAddress = crmFacilityAddress;
    this.appointmentConfirmationInfo.facilityName = crmFacilityName;
    this.appointmentConfirmationInfo.distance = distance;
    this.appointmentConfirmationInfo.duration = duration;
    this.appointmentConfirmationInfo.description = this.salesOrder.crmClassificationDescription;
    this.appointmentConfirmationInfo.dueAmount = this.salesOrder.amountDue;
    this.appointmentConfirmationInfo.isBillingAddress = this.isBillingAddress;
    this.appointmentConfirmationInfo.crmPatientDeliveryAddress = this.salesOrder.crmPatientDeliveryAddress;
    this.appointmentConfirmationInfo.durationInMinutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
    this.appointmentConfirmationInfo.schSMSCommunicationType = this.appointment.appointmentDetailInfo.schSMSCommunicationType;
    this.appointmentConfirmationInfo.schSMSCommunicationNumber = this.appointment.appointmentDetailInfo.schSMSCommunicationNumber;
    this.appointmentConfirmationInfo.schCommunicationEmailAddress = this.appointment.appointmentDetailInfo.schCommunicationEmailAddress;
    this.confirmDetialsPage = true;
    this.OpenCheck = true;
    this.addRoadHomeVisitHistory();

  }
  setMobileViewDate(date: Date, itemDate: DisplaySlots) {

    if (this.displaySlots) {
      this.displaySlotsMobile = this.displaySlots.filter(x => x.date == date);
    }
    if (this.prevdisplayDate) {
      this.prevdisplayDate.isToday = false;
    }
    itemDate.isToday = true;
    this.prevdisplayDate = itemDate;
  }

  onBookAppointment() {



    if (!this.selectedStartDate || !this.selectedEndDate) {
      this.showWarningMessage("Please select appointment date & time.");
      return;
    }
    let AppoinmentType: string = this.selectedAppointmentTypePopUp;
    AppoinmentType = AppoinmentType.replace(' ', '');
    if (AppoinmentType == "HomeVisit" && !this.selectedTherapistId) {
      this.showWarningMessage("Please select therapist.");
      return;
    }
    if (AppoinmentType == "Telehealth" && !this.selectedRemoteAppointmentType) {
      this.showWarningMessage("Please select remote setup type.");
      return;
    }


    this.OpenCheck = false;

    let facilityId: number = null;
    if (AppoinmentType != "HomeVisit")
      facilityId = this.selectedFacilityLocal.crmFacilityId;

    this.appointmentRequest = new AppointmentRequestModel();
    this.appointmentRequest.CrmFacilityId = facilityId;
    this.appointmentRequest.SchAppointmentTypeName = AppoinmentType;
    this.appointmentRequest.CrmPatientEmailAddress = this.salesOrder.patientInfo.crmPatientEmailAddress;
    this.appointmentRequest.CrmPatientId = parseInt(this.salesOrder.crmPatientId, 10);
    this.appointmentRequest.CrmPatientPhoneNo = this.smsTextNumber ? this.smsTextNumber : this.salesOrder.patientInfo.crmPatientMobileNumber ? this.salesOrder.patientInfo.crmPatientMobileNumber : this.salesOrder.patientInfo.crmPatientPhoneNo;
    this.appointmentRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.appointmentRequest.IsAppointmentBookedByPatient = false;
    this.appointmentRequest.SchAppointmentBookedBy = this.loggedInUserId;
    this.appointmentRequest.CrmTherapistId = this.selectedTherapistId;
    //this.appointmentRequest.SchAppointmentDateStartTimeString = moment(this.selectedStartDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    //this.appointmentRequest.SchAppointmentDateEndTimeString = moment(this.selectedEndDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");

    this.appointmentRequest.SchAppointmentDateStartTimeString = this.selectedStartDate.toDateString() + ' ' + this.selectedStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateEndTimeString = this.selectedEndDate.toDateString() + ' ' + this.selectedEndDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateStartTime = this.selectedStartDate
    this.appointmentRequest.SchAppointmentDateEndTime = this.selectedEndDate
    this.appointmentRequest.SchAppointmentId = Number(this.schAppointmentId);
    this.appointmentRequest.PatientTimeZone = this.selectedTimeZone.shortName;
    this.appointmentRequest.CrmFacilityTimeZone = this.selectedtherapistBasedTimeZone
    if (AppoinmentType == "Telehealth") {
      this.appointmentRequest.SchRemoteAppointmentType = this.selectedRemoteAppointmentType;

    }

    //this.appointmentRequest.SchAppointmentTypeId = null;
    if (this.isAppoinmentBookByCalendar && this.crmTherapsitId > 0)
      this.appointmentRequest.CrmTherapistId = this.crmTherapsitId;


    this.appointmentRequestBT.PractitionerId = Number(this.appointmentRequestBT.PractitionerId);
    this.appointmentRequestBT.DeliveryTechnicianId = Number(this.appointmentRequestBT.DeliveryTechnicianId);
    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.appointmentRequestBT.ScheduledDeliveryStartTimeString, 'hh:mm A').format("HH:mm");
    this.appointmentRequestBT.WIPDateNeededString = this.appointmentRequestBT.WIPDateNeeded.toDateString();
    if (this.selectedwipStatusBTMappingResponseModel) {
      this.appointmentRequestBT.WIPStateName = this.selectedwipStatusBTMappingResponseModel.crmWipstatusNameBt;
      this.appointmentRequestBT.WIPStateKey = this.selectedwipStatusBTMappingResponseModel.wipStatusRCMId;
      this.appointmentRequestBT.WIPAssignedToKey = this.selectedwipStatusBTMappingResponseModel.crmWIPAssignedToKey;
    }
    this.appointmentRequestBT.OrderNote = this.appointmentRequestBT.OrderNote;
    this.appointmentRequestBT.DeliveryNote = this.appointmentRequestBT.DeliveryNote;
    this.appointmentRequestBT.InvetoryLocationId = this.salesOrder ? Number(this.salesOrder.crmInvetoryLocationId) : 0;

    this.appointmentRequest.salesOrderUpdateRequestModelBT = this.appointmentRequestBT;

    this.appointmentRequest.SchReminderOpted = this.reminderOpted;

    this.appointmentRequest.btNoteId = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
    this.appointmentRequest.isProvisional = this.isProvisional;



    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.ReScheduleAppointment(this.appointmentRequest) : this.appointmentService.ScheduleAppointment(this.appointmentRequest);

    //this.appointmentService.ScheduleAppointment(this.appointmentRequest)
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.isSalesOrderUpdated = true;
            let crmFacilityAddress = '';
            let crmFacilityName = '';
            let distance = '';
            if (AppoinmentType.toLowerCase() == "homevisit") {
              crmFacilityAddress = this.salesOrder.patientInfo.crmPatientAddress;
              crmFacilityName = 'Home Visit';
              distance = '';
            }
            else if (AppoinmentType == "telehealth") {
              crmFacilityAddress = '';
              crmFacilityName = this.selectedFacilityLocal.crmFacilityName;
              distance = '';
            }
            else {
              crmFacilityAddress = this.selectedFacilityLocal.crmFacilityAddress;
              crmFacilityName = this.selectedFacilityLocal.crmFacilityName;
              distance = this.selectedFacilityLocal.crmFacilityDistanceInMiles;
            }

            this.appointmentConfirmation = new AppointmentConfirmation();
            this.appointmentConfirmation.crmPatientname = this.salesOrder.patientInfo.crmPatientFirstName + ' ' + this.salesOrder.patientInfo.crmPatientLastName;
            //this.appointmentConfirmation.crmTherapistname = crmTherapistName;//this.selectedTherapistAvalabilityModel.crmTherapistName;//this.selectedTherapist.crmTherapistName;
            this.appointmentConfirmation.appointmentStartDate = this.selectedStartDate;
            this.appointmentConfirmation.appointmentEndDate = this.selectedEndDate;
            this.appointmentConfirmation.appointmentTimeZone = this.selectedTimeZone.name;
            this.appointmentConfirmation.appointmentTimeZoneCode = this.selectedTimeZone.code; this.crmIsAppoinment
            this.appointmentConfirmation.facilityAddress = crmFacilityAddress;
            this.appointmentConfirmation.facilityName = crmFacilityName;
            this.appointmentConfirmation.distance = distance;
            this.appointmentConfirmation.duration = this.duration;
            this.appointmentConfirmation.description = this.salesOrder.crmClassificationDescription;
            this.appointmentConfirmation.dueAmount = this.salesOrder.amountDue
            this.appointmentConfirmation.durationInMinutes = this.salesOrder.crmClassificationDuration == 1 ? 60 : this.salesOrder.crmClassificationDuration;
            this.reschduleDate = this.selectedStartDate;
            this.ConfirmAppointmentPopup = true;

            if (this.isRescheduledRequest == false) {
              //this.showSuccessMessage('Appointment has been successfully scheduled.');
              //this.isRescheduledRequest = true;
              this.isAppointment = true;
              this.crmIsAppoinment = true;
              this.schAppointmentId = Number(result.message);
            }
            //else
            // this.showSuccessMessage('Appointment has been successfully rescheduled.');

            //this.getAppointmentInfo(this.schAppointmentId);
          }
        }
        else {
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in scheduling your appointment. Please contact administrator");
        }
      });
  }


  CloseWindow() {

    this.ConfirmAppointmentPopup = false;
    this.getAppointmentInfo(this.schAppointmentId);

  }

  HangupCloseWindow() {
    this.ConfirmAppointmentPopup = false;
    this.Hangup();
    this.ref.close(this.crmIsAppoinment);

  }

  PhonecallOptions() {
    if (this.isTwoNumberExist) {

    }
    else {
      if (this.salesOrder.patientInfo.crmPatientPhoneNo)
        this.Phonecall(this.salesOrder.patientInfo.crmPatientPhoneNo, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName)
      else
        this.Phonecall(this.salesOrder.patientInfo.crmPatientMobileNumber, this.salesOrder.patientInfo.crmPatientFirstName, this.salesOrder.patientInfo.crmPatientLastName)

    }

  }



  async Phonecall(phoneNumber: string, fName: string, lName: string) {
    //this.device.destroy();

    const deviceOptions = {
      edge: 'ashburn',
      enableRingingState: true,
      fakeLocalDTMF: true,
      debug: true,
      tokenRefreshMs: 3000000,
    }
    this.voiceCallToNumber = '+1' + phoneNumber;
    this.voiceMailNumber = phoneNumber;
    //var params = { "To": '+1' + phoneNumber };
    //var params = { "To": this.voiceCallToNumber, "Record": true };
    const device = new Device(this.data1.token, deviceOptions);
    this.device = device;
    this.callId = 0;
    this.callSID = '';
    if (device) {
      this.CallCounter = 0;
      this.IsCallHnageByDme = false;
      console.log("start CallCounter");
      console.log(this.CallCounter);
      //console.log("end CallCounter");
      console.log(device);
      console.log('Phone call...');
      console.log('Initiating the call');


      let crmSalesOrderId = this.crmSalesOrderId;
      let isAppointment = this.isAppointment;
      let isRescheduledRequest = this.isRescheduledRequest;
      let schAppointmentId = this.schAppointmentId;
      let isVoidOrder = this.isVoidOrder;
      let isCancelOrder = this.isCancelOrder;
      let isCall = this.isCall;
      let isScheduleOrder = this.isScheduleOrder;
      let isAppoinmentBookByCalendar = this.isAppoinmentBookByCalendar;
      let crmTherapsitId = this.crmTherapsitId;
      let salesOrderdate = this.salesOrderdate;
      let crmTherapsitName = this.crmTherapsitName;
      let isAppointmentAppoinmentPage = this.isAppointmentAppoinmentPage;
      let isAppointmentAppoinmentDetailPage = this.isAppointmentAppoinmentDetailPage;
      let isAppointmentSalesOrderDetailPage = this.isAppointmentSalesOrderDetailPage;
      let isAppointmentAppoinmentLockPage = this.isAppointmentAppoinmentLockPage;

      try {
        const call = await device.connect({ params: { To: this.voiceCallToNumber, "Record": 'true', ringTone: "us" } });
        let that = this;
        let statsus = call.status();
        console.log(statsus);
        call.on('ringing', function () {
          that.isCallStarted = true;
          console.log('Ringing...');
        });
        //let connection = this.device.connect(params);
        call.on("accept", () => {

          console.log('accept...');

          that.isCallStarted = true;
          that.callSID = call.parameters.CallSid;
          let twillioDeviceConnection = new TwilioDeviceObject();
          twillioDeviceConnection = new TwilioDeviceObject();
          twillioDeviceConnection.connection = call;
          twillioDeviceConnection.device = device;
          twillioDeviceConnection.isCallinProgress = true;
          twillioDeviceConnection.voiceCallToNumber = this.voiceCallToNumber;
          twillioDeviceConnection.voiceMailNumber = this.voiceMailNumber;
          twillioDeviceConnection.callSID = call.parameters.CallSid;
          twillioDeviceConnection.status = call.status();


          let appoinmentDialogueParametersObject = new AppoinmentDialogueParametersObject();
          appoinmentDialogueParametersObject.crmSalesOrderId = crmSalesOrderId;
          appoinmentDialogueParametersObject.isAppointment = isAppointment;
          appoinmentDialogueParametersObject.isRescheduledRequest = isRescheduledRequest;
          appoinmentDialogueParametersObject.schAppointmentId = schAppointmentId;
          appoinmentDialogueParametersObject.isVoidOrder = isVoidOrder;
          appoinmentDialogueParametersObject.isCancelOrder = isCancelOrder;
          appoinmentDialogueParametersObject.isCall = isCall;
          appoinmentDialogueParametersObject.isScheduleOrder = isScheduleOrder;
          appoinmentDialogueParametersObject.isAppoinmentBookByCalendar = isAppoinmentBookByCalendar;
          appoinmentDialogueParametersObject.crmTherapsitId = crmTherapsitId;
          appoinmentDialogueParametersObject.salesOrderdate = salesOrderdate;
          appoinmentDialogueParametersObject.crmTherapsitName = crmTherapsitName;
          appoinmentDialogueParametersObject.isAppointmentAppoinmentPage = isAppointmentAppoinmentPage;
          appoinmentDialogueParametersObject.isAppointmentAppoinmentDetailPage = isAppointmentAppoinmentDetailPage;
          appoinmentDialogueParametersObject.isAppointmentSalesOrderDetailPage = isAppointmentSalesOrderDetailPage;
          appoinmentDialogueParametersObject.isAppointmentAppoinmentLockPage = isAppointmentAppoinmentLockPage;
          appoinmentDialogueParametersObject.patientName = fName + ', ' + lName;


          twillioDeviceConnection.appoinmentDialogueParametersObject = appoinmentDialogueParametersObject;
          this.twillioDeviceConnectionObject = twillioDeviceConnection;
          that.webStorageService.storeTwilioDeviceObject(this.twillioDeviceConnectionObject);
          that.twillioCallService.twillioCall.next(twillioDeviceConnection);

          setTimeout(function () { that.startRecordcall(twillioDeviceConnection.callSID); }, 3000);
          //that.startRecordcall(twillioDeviceConnection.callSID);

          //let header = new HeaderComponent(this.router, this.webStorageService, this.activatedRoute, this.FormBuilder, this.UserService, this.notificationService,
          //  this.messageService, this.dialougeService, this.globalSearchService,this._cdr);
          //header.showCallInfo();
        });
        call.on('reject', () => {
          console.log('The call was rejected.');
        });
        call.on('error', (error) => {
          console.log('Device Error: ' + error);
          that.CallCounter = that.CallCounterMax;
          that.isCallStarted = false;
          device.disconnectAll();
          that.disconnectCall(error);

        });
        call.on('disconnect', function (conn) {
          let status = call.status()
          that.CallCounter = that.CallCounterMax;
          that.isCallStarted = false;

          if (!that.IsCallHnageByDme) {
            console.log("call ened By user");
            that.SaveCall("Hangup");
            device.disconnectAll();
            that.getDeviceConnectionForCall();
            //that._cdr.detectChanges();
          }
          that.webStorageService.deleteTwilioDeviceObject();
          that.twillioCallService.twillioCall.next(null);
          console.log('Call ended.');


        });
      }
      catch (e: any) {

        if (typeof e === "string") {
          e.toUpperCase() // works, `e` narrowed to string
        } else if (e instanceof Error) {
          e.message // works, `e` narrowed to Error
        }
        if (e.message == 'A Connection is already active') {
          this.dialougeService.open(ConfirmDialogComponent, {
            data:
            {
              message: 'Voice calls in progress. Do you want to hang up the call?',
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: boolean) => {
            if (!response) {
              return;
            }
            this.HangupClose();

          });
          // that.getDeviceConnectionForCall();
          //that.CloseDialogue();
          //that.isCallStarted = true;
        }
        console.log(e.message);
      }
      //  }
    }
    else {

    }
  }

  manageAlreadyStratedcall(connection: any, voiceCallToNumber: string, device: any, voiceMailNumber: string) {

    this.connection = connection;
    this.device = device;
    this.callSID = connection.parameters.CallSid;
    this.voiceCallToNumber = voiceCallToNumber;
    this.voiceMailNumber = voiceMailNumber;
    this.isCallStarted = true;
    connection.on('error', function (error) {
      this.CallCounter = this.CallCounterMax;
      this.isCallStarted = false;
      this.device.disconnectAll();
      this.disconnectCall(error);
      console.log('Device Error: ' + error.message);
    });
    connection.on('disconnect', function (conn) {
      this.CallCounter = this.CallCounterMax;
      this.isCallStarted = false;

      if (!this.IsCallHnageByDme) {
        console.log("call ened By user");
        this.SaveCall("Hangup");
        this.device.disconnectAll();
        this.getDeviceConnectionForCall();
        //that._cdr.detectChanges();
      }
      this.webStorageService.deleteTwilioDeviceObject();
      this.twillioCallService.twillioCall.next(null);
      console.log('Call ended.');


    });



  }



  startRecordcall(CallSid: string) {
    this.appointmentService.RecordCall(CallSid)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != null && result.responseModel != undefined) {
            console.log("Start isRecordEnable Status");
            console.log(result.responseModel.isRecordEnable);
            console.log("End isRecordEnable Status");

            console.log(result.responseModel);
            this.appointmentCallResponseModel = new AppointmentCallResponseModel();
            this.appointmentCallResponseModel = result.responseModel;
            this.recordingCallSid = this.appointmentCallResponseModel.recordingSID;
            this.recordingCallUrl = this.appointmentCallResponseModel.recordCallUrl;

            if (this.recordingCallSid == null) {
              if (this.CallCounter <= this.CallCounterMax) {
                this.CallCounter = this.CallCounter + 1;
                //console.log("Call Counter");
                //console.log(this.CallCounter);
                //console.log("Start functions");
                this.startRecordcall(CallSid);
              }
            }
          }
        }
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async startFetchRecordingcall(recordingSID: any) {
    if (recordingSID) {
      this.appointmentService.FetchRecordingCall(recordingSID)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess) {
            if (result.responseModel != null && result.responseModel != undefined) {

            }
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  disconnectCall(error) {

    this.isCallStarted = false;
    this._cdr.detectChanges();
    if (error.code == "31005") {
      this.showInfoMessage('Hangup by Patient.')
    }
    else {
      this.showWarningMessage(error.message);
    }

  }

  displayHangUpConfirm: boolean = false;

  CancelHangUp() {
    this.displayHangUpConfirm = true;
  }

  async Hangup() {
    console.log('Hanging up...');
    if (this.device) {
      this.IsCallHnageByDme = true;
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
    if (this.callId == 0) {
      await this.SaveCall("Hangup");
      this._cdr.detectChanges();
    }
    else {
      await this.UpdateCall();
    }
    this.twillioDeviceConnectionObject = null;
    this.webStorageService.deleteTwilioDeviceObject();
    this.twillioCallService.twillioCall.next(null);
    this.getDeviceConnectionForCall();
  }

  async HangupClose() {
    console.log('Hanging up...');
    if (this.connection) {
      console.log("still connections HangupClose");
    }
    if (this.device) {
      this.IsCallHnageByDme = true;
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
    if (this.callId == 0) {
      await this.SaveCall("Hangup");
      this._cdr.detectChanges();
    }
    else {
      await this.UpdateCall();
    }
    this.twillioDeviceConnectionObject = null;
    this.webStorageService.deleteTwilioDeviceObject();
    this.twillioCallService.twillioCall.next(null);

  }


  async HangupVoiceMail() {
    console.log('Hanging up...');
    if (this.device) {
      this.IsCallHnageByDme = true;
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
    if (this.callId == 0) {
      await this.SaveCall("HangupVoiceMail");
    }
    else {
      await this.UpdateCall();
    }

    //await this.SendVoiceMail();
    await this.SendVoiceMailToNumber();


    this.twillioDeviceConnectionObject = null;
    this.webStorageService.deleteTwilioDeviceObject();
    this.twillioCallService.twillioCall.next(null);
    this.getDeviceConnectionForCall();
  }
  async SaveCall(saveDate: string) {
    this.CallCounter = this.CallCounterMax;
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (saveDate == "HangupVoiceMail") {
      this.appointmentCallResponseModel.schAppointmentCallNote = "Voice Call with Voicemail";
    }
    if (saveDate == "Hangup") {
      this.appointmentCallResponseModel.schAppointmentCallNote = "Voice Call";
    }
    if (this.connection)
      this.callSID = this.connection.parameters.CallSid;
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      if (saveDate !== "S" && saveDate !== "HangupVoiceMail" && saveDate !== "Hangup") {
        var time = new Date(this.calldate.toDateString() + ' ' + this.customStartTime);
        if (this.calldate && time) {

          this.calltime = moment(time).format("YYYY-MM-DD HH:mm:ss");
          this.appointmentCallResponseModel.schNextCallDueDateString = this.calltime;
        }
        if (this.callnote)
          this.appointmentCallResponseModel.schAppointmentCallNote = this.callnote;
        else
          this.appointmentCallResponseModel.schAppointmentCallNote = "Not answered";
      }
      this.appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      //this.isRescheduledRequest ? this.appointment.schAppointmentId : this.salesOrder.crmSalesOrderId;
      if (this.isRescheduledRequest)
        this.appointmentCallResponseModel.schAppointmentId = this.appointment.schAppointmentId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallBy = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallByPerson = currentUserToken.userFullName;
      this.appointmentCallResponseModel.schAppointmentCallByRole = currentUserToken.roleName;
      this.appointmentCallResponseModel.schAppointmentCallListId = 0;
      this.appointmentCallResponseModel.callSID = this.callSID;
      this.appointmentCallResponseModel.schAppointmentCallToNumber = "";
      this.appointmentCallResponseModel.schAppointmentCallToNumber = this.voiceCallToNumber;
      this.appointmentCallResponseModel.recordingSID = this.recordingCallSid;
      this.appointmentCallResponseModel.recordCallUrl = this.recordingCallUrl;
      //changes done here for time zone
      await this.appointmentService.SaveCall(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              if (this.calltime) {
                this.showSuccessMessage('Future callback has been scheduled.');
              }
              this.isSalesOrderUpdated = true;
              this.callId = result.responseModel.schAppointmentCallId;
              //this._cdr.detectChanges();

              this.startFetchRecordingcall(this.recordingCallSid);
              //this.getsalesorders();
              this.formCallAppointment.reset();
              this.customStartTime = "";
            }

          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }



        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async SaveCallScheduleCallback() {
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {

      var time = new Date(this.calldate.toDateString() + ' ' + this.customStartTime);
      if (this.calldate && time) {

        this.calltime = moment(time).format("YYYY-MM-DD HH:mm:ss");
        this.appointmentCallResponseModel.schNextCallDueDateString = this.calltime;
      }
      if (this.callnote)
        this.appointmentCallResponseModel.schAppointmentCallNote = this.callnote;
      else
        this.appointmentCallResponseModel.schAppointmentCallNote = "Not answered";

      this.appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      if (this.isRescheduledRequest)
        this.appointmentCallResponseModel.schAppointmentId = this.appointment.schAppointmentId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallBy = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallByPerson = currentUserToken.userFullName;
      this.appointmentCallResponseModel.schAppointmentCallByRole = currentUserToken.roleName;
      this.appointmentCallResponseModel.schAppointmentCallListId = 0;
      this.appointmentCallResponseModel.callSID = this.callSID;
      this.appointmentCallResponseModel.schAppointmentCallToNumber = this.voiceCallToNumber;

      if (this.isBillingAddress)
        this.appointmentCallResponseModel.schTimeZone = this.salesOrder.patientInfo.crmBillingAddressTimeZone;
      else
        this.appointmentCallResponseModel.schTimeZone = this.salesOrder.crmDeliveryAddressTimeZone;


      //changes done here for time zone
      await this.appointmentService.SaveCall(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage('Future callback has been scheduled.');
              this.isSchedulecallBack = true;
              this.previousCallbackDate = result.responseModel.schNextCallDueDate;
              this._schTimezone = this.appointmentCallResponseModel.schTimeZone;
              if (this.previousCallbackDate != undefined) {
                let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
                let currentDate = moment(dateCurrent).toDate();
                let targetDate = new Date(this.previousCallbackDate);
                this.hasCallbackDatePassed = targetDate < currentDate;
              }
              else {
                this.hasCallbackDatePassed = false;
              }

              this.isSalesOrderUpdated = true;
              this._cdr.detectChanges();
              this.formCallAppointment.reset();
              this.customStartTime = "";
            }

          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async SaveManualCall() {
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      if (this.isRescheduledRequest)
        this.appointmentCallResponseModel.schAppointmentId = this.appointment.schAppointmentId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallBy = currentUserToken.userId;
      this.appointmentCallResponseModel.schAppointmentCallByPerson = currentUserToken.userFullName;
      this.appointmentCallResponseModel.schAppointmentCallByRole = currentUserToken.roleName;
      this.appointmentCallResponseModel.schAppointmentCallListId = 0;
      this.appointmentCallResponseModel.schAppointmentCallToNumber = this.voiceCallToNumber;
      //changes done here for time zone
      await this.appointmentService.SaveCall(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.isSalesOrderUpdated = true;
              this.showSuccessMessage('Manual Call logged.');
              this.callId = result.responseModel.schAppointmentCallId;
              this._cdr.detectChanges();
            }
          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async UpdateCall() {
    this.CallCounter = this.CallCounterMax;
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    this.appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (currentUserToken && currentUserAuthToken.token) {
      this.appointmentCallResponseModel.schAppointmentCallId = this.callId;
      //this.isRescheduledRequest ? this.appointment.schAppointmentId : this.salesOrder.crmSalesOrderId;
      if (this.isRescheduledRequest)
        this.appointmentCallResponseModel.schAppointmentId = this.appointment.schAppointmentId;
      else
        this.appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      this.appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      this.appointmentCallResponseModel.callSID = this.callSID;
      await this.appointmentService.UpdateCallStatus(this.appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {

          if (result.responseModel) {
            //this.showSuccessMessage('Future callback has been scheduled.');
            //this.isCallStarted = false;
            // this.callPopup = false;
            this._cdr.detectChanges();
            this.isSalesOrderUpdated = true;
            // this.getsalesorders();
            this.formCallAppointment.reset();
            this.customStartTime = "";

          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }

        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  async SendVoiceMail() {

    await this.appointmentService.SendVoiceMail(this.callId.toString(), this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel) {
          this.showSuccessMessage('Voice mail has been Sent to Patient.');
          // this.callPopup = false;
          this.isCallStarted = false;
          this._cdr.detectChanges();
        }
        else {
          this.showWarningMessage('Error occurred while sending voice mail.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }



  async SendVoiceMailToNumber() {

    await this.appointmentService.SendVoiceMailToNumber(this.voiceMailNumber, this.crmSalesOrderId, this.callId.toString(), this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel) {
          this.showSuccessMessage('Voice mail has been Sent to Patient.');
          // this.callPopup = false;
          this.isCallStarted = false;
          this._cdr.detectChanges();
        }
        else {
          this.showWarningMessage('Error occurred while sending voice mail.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }



  CheckTime() {

    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    var time = new Date(commonDate + ' ' + this.customStartTime);
    if (time.getDate()) {
      this.isValid = true;
    }
    else {
      this.isValid = false;
    }

  }

  //Permission Check
  isSendInvitationPermission: boolean = true;
  isAppointmentScheduledPermission: boolean = true;
  isAppointmentRescheduledPermission: boolean = true;
  isAppointmentCancelPermission: boolean = true;
  isAppointmentMovedPermission: boolean = true;
  isAppointmentOverduePermission: boolean = true;
  isAppointmentNoshowPermission: boolean = true;
  isAppointmentCompletedPermission: boolean = true;
  isAppointmentVoidPermission: boolean = true;
  isFacilitySchedulePermission: boolean = true;
  setPermissionforActions() {

    this.features = this.webStorageService.getPermissions();
    if (this.features != null && this.features != undefined) {
      if (this.features && this.features.length > 0) {

        let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
        if (inPer && inPer.isActive == true)
          this.isSendInvitationPermission = true;
        else
          this.isSendInvitationPermission = false;

        let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
        if (inPer1 && inPer1.isActive == true) {
          this.isAppointmentScheduledPermission = true;
          this.isAppointmentRescheduledPermission = true;
        }
        else {
          this.isAppointmentScheduledPermission = false;
          this.isAppointmentRescheduledPermission = false;
        }
        let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
        if (this.isRescheduledRequest) {
          if (inPer2 && inPer2.isActive == true)
            this.isAppointmentCancelPermission = true;
        }

        else {
          this.isAppointmentCancelPermission = true;
        }

        //let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
        //if (inPer3 && inPer3.isActive == true)
        //  this.isAppointmentRescheduledPermission = true;


        let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
        if (inPer4 && inPer4.isActive == true)
          this.isAppointmentMovedPermission = true;
        else
          this.isAppointmentMovedPermission = false;

        let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
        if (inPer5 && inPer5.isActive == true)
          this.isAppointmentOverduePermission = true;
        else
          this.isAppointmentOverduePermission = false;

        let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
        if (inPer6 && inPer6.isActive == true)
          this.isAppointmentNoshowPermission = true;
        else
          this.isAppointmentNoshowPermission = false;

        let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
        if (inPer7 && inPer7.isActive == true)
          this.isAppointmentCompletedPermission = true;
        else
          this.isAppointmentCompletedPermission = false;

        let inPer8 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentvoid');
        if (this.voidFrom === "P") {
          if (inPer8 && inPer8.isActive == true)
            this.isAppointmentVoidPermission = true;
        } else {
          this.isAppointmentVoidPermission = false;
        }
      }
    }
  }


  clickpatientDetials() {
    this.patientDetials = !this.patientDetials;
  }

  patientdetailsEditFields() {
    this.patientdetailsEdit = !this.patientdetailsEdit;
  }

  savePatientGeneralInformation() {

    const TEXT_REGEXPEmail = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    const TEXT_REGEXPPhone = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    //if (!this.patientEmail) {
    //  this.showWarningMessage('Please add email address');
    //  return;
    //}
    if (this.patientEmail && !TEXT_REGEXPEmail.test(this.patientEmail)) {
      this.showWarningMessage('Please add proper email address.');
      return;
    }

    //if (!this.patientPhone) {
    //  this.showWarningMessage('Please provide phone number');
    //  return;
    //}
    if (this.patientPhone && !TEXT_REGEXPPhone.test(this.patientPhone)) {
      this.showWarningMessage('Please provide valid phone number.');
      return;
    }
    //if (!this.patientMobileNumber) {
    //  this.showWarningMessage('Please provide mobile number');
    //  return;
    //}
    if (this.patientMobileNumber && !TEXT_REGEXPPhone.test(this.patientMobileNumber)) {
      this.showWarningMessage('Please provide valid mobile number.');
      return;
    }

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update patient general information?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.loading = true;
      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.salesOrder.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.responseModel) {
            let re = /\-/gi;
            this.salesOrder.patientInfo.crmPatientEmailAddress = this.patientEmail;
            this.showSuccessMessage('Patient general information updated successfully');
            this.patientdetailsEdit = !this.patientdetailsEdit;
            this.salesOrder.patientInfo.crmPatientEmailAddress = this.patientEmail;
            this.salesOrder.patientInfo.crmPatientPhoneNo = this.patientPhone.replace(re, "");
            this.salesOrder.patientInfo.crmPatientMobileNumber = this.patientMobileNumber.replace(re, "");
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });


  }

  cancelPatientGeneralInformation() {
    if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
      this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
    }
    else {
      this.patientEmail = "";
    }
    if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
      this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
    }
    else {
      this.patientPhone = "";
    }
    if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
      this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
    }
    else {
      this.patientMobileNumber = "";
    }

    this.patientdetailsEdit = !this.patientdetailsEdit;
  }



  patientdetialsEditFeildsOrderNone() {
    this.patientdetialsEditOrderNone = !this.patientdetialsEditOrderNone;
  }

  patientdetialsEditFeildsDeliveryNote() {
    this.patientdetialsDeliveryNote = !this.patientdetialsDeliveryNote;
  }

  currentAppointmentDetails() {
    this.currentAppointmentDetailsBox = !this.currentAppointmentDetailsBox;
  }

  scheduleCallBack() {
    let tz = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    //var comparetime = new DatePipe(this.locale).transform(new Date(), 'shortTime', this.timeZone.find(x => x.orgcode == tz).code);
    var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    this.currentDate = moment(comparetime).toDate();
    this.currentTime = new DatePipe(this.locale).transform(new Date(), "h:mm a", this.appDateFormat.timeZone);
    this.currentTime = this.currentTime.toLowerCase();
    this.displayScheduleCallBack = true;


  }

  calllogs: AppointmentCallResponseModel[];

  appointmentHistoryInfo: AppointmentHistoryResponseModel[];

  showPhoneCallLogs() {
    let logCount = 0;
    if (!this.isAppointment) {
      this.loading = true;
      this.progress.next(true);
      this.appointmentService.GetCallLogsBySalesOrderId(this.crmSalesOrderId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<AppointmentCallResponseModel[]>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.calllogs = result.responseModel;
                let calllogs = this.calllogs.filter(x => x.callSID == null);
                logCount = calllogs.length;
                if (logCount > 0)
                  this.displayPhoneCallLog = true;
                //this._cdr.detectChanges();
              }
            }
            this.loading = false;
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    } else {

      this.loading = true;
      this.progress.next(true);
      this.appointmentService.GetCallLogsByAppintmentId(this.schAppointmentId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<AppointmentHistoryResponseModel[]>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.appointmentHistoryInfo = result.responseModel;
                let apptcalllogs = this.appointmentHistoryInfo.filter(x => x.callSID == null && x.isCall == true);
                logCount = apptcalllogs.length;
                if (logCount > 0)
                  this.displayPhoneCallLog = true;
                //this._cdr.detectChanges();
              }
            }
            this.loading = false;
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });

    }

    //if (!this.isAppointment) {
    //  let calllogs = this.salesOrder.calllogs.filter(x => x.callSID == null);
    //  logCount = calllogs.length;
    //} else {
    //  let apptcalllogs = this.appointment.appointmentHistoryInfo.filter(x => x.callSID == null && x.isCall == true);
    //  logCount = apptcalllogs.length;
    //}
    //if (logCount > 0)
    //  this.displayPhoneCallLog = true;
  }



  showVoiceCallLogs() {

    let logCount = 0;
    if (!this.isAppointment) {
      this.loading = true;
      this.progress.next(true);
      this.appointmentService.GetCallLogsBySalesOrderId(this.crmSalesOrderId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<AppointmentCallResponseModel[]>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.calllogs = result.responseModel;
                let calllogs = this.calllogs.filter(x => x.callSID != null);
                logCount = calllogs.length;
                if (logCount > 0)
                  this.displayVoiceCallLog = true;
                //this._cdr.detectChanges();
              }
            }
            this.loading = false;
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    } else {

      this.loading = true;
      this.progress.next(true);
      this.appointmentService.GetCallLogsByAppintmentId(this.schAppointmentId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<AppointmentHistoryResponseModel[]>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.appointmentHistoryInfo = result.responseModel;
                let apptcalllogs = this.appointmentHistoryInfo.filter(x => x.callSID != null);
                logCount = apptcalllogs.length;
                if (logCount > 0)
                  this.displayVoiceCallLog = true;
                //this._cdr.detectChanges();
              }
            }
            this.loading = false;
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });

    }


    //let logCount = 0;
    //if (!this.isAppointment) {
    //  let calllogs = this.salesOrder.calllogs.filter(xCancelAppointment => x.callSID != null);
    //  logCount = calllogs.length;
    //} else {
    //  let apptcalllogs = this.appointment.appointmentHistoryInfo.filter(x => x.callSID != null);
    //  logCount = apptcalllogs.length;
    //}
    //if (logCount > 0)
    //  this.displayVoiceCallLog = true;
  }

  cancelAppointmentpopup() {
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')

      if (selectedWipStaus != null && selectedWipStaus != undefined) {
        this.isBrightreeSystemAppUpdateVoidCancelSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdateVoidCancel = !this.isBrightreeSystemAppUpdateVoidCancelSetting;
      }
    }
    //this.displayCancelAppointment = true;

    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data: {
        action: 'Cancel',
        popupHeader: 'Update Cancel Status of Appointment(s)',
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: this.cancellationReasonsResponseModel,
        isMultiSelected: 'False',
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {
      if (response.action == 'Confirm') {
        this.updateStatusforminfo = response;
        this.cancelAppointment();
      }
      if (response.action == 'Cancel') {
        this.hideAppointmentpopup();
      }
    });
    /** End Code */
  }

  hideAppointmentpopup() {
    const form = this.formCancel;
    form.get(this.formFields.reason).setValue('');
    form.get(this.formFields.reasonComments).setValue('');
    form.reset();
    this.displayCancelAppointment = false;
  }

  VoidAppointmentpopup(voidFrom: string) {
    this.voidFrom = voidFrom;
    let action: string = "Void";
    let popupHeader: string = 'Update Void Status of Appointment(s)';
    if (this.voidFrom === 'P') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
        if (selectedWipStaus != null && selectedWipStaus != undefined) {
          this.isBrightreeSystemAppUpdateVoidCancelSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdateVoidCancel = !this.isBrightreeSystemAppUpdateVoidCancelSetting;
        }
      }
    }
    else {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview');
        action = "Void review";
        popupHeader = 'Update Void Review Status of Appointment(s)';

        if (selectedWipStaus != null && selectedWipStaus != undefined) {
          this.isBrightreeSystemAppUpdateVoidCancelSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdateVoidCancel = !this.isBrightreeSystemAppUpdateVoidCancelSetting;
        }
      }

    }

    //this.displayVoidAppointment = true;
    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data: {
        action: action,
        popupHeader: popupHeader,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: this.voidReasonsResponseModel,
        isMultiSelected: 'False',
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {
      if (response.action == 'Confirm') {
        this.updateStatusforminfo = response;
        this.voidAppointment();
      }
      if (response.action == 'Cancel') {
        this.cancelVoidAppointment();
      }
    });
    /** End Code */

  }

  cancelVoidAppointment() {
    this.displayVoidAppointment = false;
  }

  cancelDeliveryAddress() {
    this.displayDeliveryAddress = false;
  }

  onConfirmNextBack() {
    this.confirmDetialsPage = false;
  }

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdateVoidCancel = true;
    }
    else {
      this.isBrightreeSystemAppUpdateVoidCancel = false;
    }

  }

  private GetNotificationSMSLogsBySalesOrderId(SalesOrderId) {

    this.loadingEmail = true;
    this.appointmentService.GetNotificationSMSLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingEmail = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentSMSHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentSMSHistoryResponseModel = result.responseModel;
              if (this.appointmentSMSHistoryResponseModel.length > 0)
                //this.smsdisplay = true;
                this.SMSSidebarDisplay = true;
              else {
                //this.smsdisplay = false;
                this.SMSSidebarDisplay = false;
                this.showInfoMessage("No record found");
              }
            }
          }
          this.loadingEmail = false;
        });
      }).catch((httpResponseError) => {
        this.loadingEmail = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private GetNotificationEmailLogsBySalesOrderId(SalesOrderId) {
    // this.emaildisplay = true;
    this.loadingEmail = true;
    this.appointmentService.GetNotificationEmailLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingEmail = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentEmailHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.appointmentEmailHistoryResponseModel = result.responseModel;
              if (this.appointmentEmailHistoryResponseModel.length > 0)
                //this.emaildisplay = true;
                this.EmailSidebarDisplay = true;
              else {
                //this.emaildisplay = false;
                this.EmailSidebarDisplay = false;
                this.showInfoMessage("No record found");
              }
            }
          }
          this.loadingEmail = false;

        });
      }).catch((httpResponseError) => {
        this.loadingEmail = false;

        this.showErrorMessage(httpResponseError.message);
      });
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  expandedIndex: number = -1;
  Collapse(index: number) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  smsDialog() {
    this.GetNotificationSMSLogsBySalesOrderId(this.crmSalesOrderId);

  }
  emailDialog() {
    this.GetNotificationEmailLogsBySalesOrderId(this.crmSalesOrderId);
  }

  SMSSidebar() {
    this.GetNotificationSMSLogsBySalesOrderId(this.crmSalesOrderId);
  }

  EmailSidebar() {
    this.GetNotificationEmailLogsBySalesOrderId(this.crmSalesOrderId);
  }

  checkIforMonthChangeisSameOrAfter(facilitySlotDate: Date, crmFirstAvailableStartDate: Date) {
    // let format = "MM-DD-YYYY";

    // let startDate = moment(crmFirstAvailableStartDate).toDate();
    // let endDate = moment(facilitySlotDate).toDate();
    // let startDateFormat = moment(startDate, format);
    // let endDateFormat = moment(endDate, format);

    // let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
    // // let isSameOrbefore = startDateFormat.isSameOrBefore(endDateFormat);
    // let issameDaye = endDateFormat.isSame(startDateFormat, 'day');

    // if (isSameOrAfter || issameDaye) {
    //   this.facilitySlotDate = crmFirstAvailableStartDate;
    // }
  }

  // to fetch the duration on bases of 60 min
  private GetFacilityFirstAvailableDateTimeByDurationIntial(facilityDate: Date, duration: number) {
    this.loading = true;
    this.islotAvailable = true;
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.AppointmentDateTimeString = this.formatDate(facilityDate?.toString());
    this.facilitySlotRequest.CrmSalesorderId = this.salesOrder.crmSalesorderId;
    this.facilitySlotRequest.CrmFacilityTimeZone = this.selectedFacilityLocal.crmTimeZone;
    this.facilitySlotRequest.CrmClassificationDuration = duration;
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    this.facilitySlotRequest.isAppointment = this.isAppointment;
    this.facilitySlotRequest.CrmSalesorderClassificationType = this.crmSalesorderClassificationType;
    if (this.isAppoinmentBookByCalendar)
      this.facilitySlotRequest.CrmTherapistId = this.crmTherapsitId;
    //for time zone changes
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    this.appointmentService.GetFacilityFirstAvailableDateTimeByDuration(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.minAllowDateToBookAppoitment = new Date(result.responseModel);
            this.minAvailabilityDate = result.responseModel;
            this.crmFirstAvailableStartDate = result.responseModel;
            this.setFacilityData(this.crmFirstAvailableStartDate);
            this.setMonthAvailability(this.crmFirstAvailableStartDate);
            this.setFacilityFirstAvailableDateandFetchSlotonDuration(this.crmFirstAvailableStartDate);
          }
          else {
            this.minAllowDateToBookAppoitment = new Date();
          }
        }
        this.loading = false;
      });
    //this.loading = false;
  }

  MonthDifference(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    months = (d2.getMonth() - d1.getMonth()) + 1 + (12 * (d2.getFullYear() - d1.getFullYear()));
    return months <= 0 ? 0 : months;

  }

  private setFacilityFirstAvailableDateandFetchSlotonDuration(crmFirstAvailableStartDate: Date) {

    console.log('setFacilityFirstAvailableDateandFetchSlotonDuration: ' + this.selectedFacilityLocal.crmFacilityId);


    let firstAvailabilityDate = crmFirstAvailableStartDate;
    //let firstAvailabilityDate = this.crmFirstAvailableStartDate;
    //let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;
    if (this.isAppoinmentBookByCalendar && this.salesOrderdate) {
      let format = 'DD-MMM-yyyy';
      let crmFirstAvailableStartDate = moment(this.crmFirstAvailableStartDate).toDate();
      let salesOrderdate = moment(this.salesOrderdate).toDate();
      let beforeTime = moment(crmFirstAvailableStartDate, format);
      let afterTime = moment(salesOrderdate, format);
      let time = moment(this.salesOrderdate, format);
      let isSameOrAfter = time.isSameOrAfter(beforeTime);
      if (isSameOrAfter)
        firstAvailabilityDate = this.salesOrderdate;

      if (this.facilityId) {
        if (this.selectedFacility) {
          let selectedFac = this.selectedFacility.find(x => x.crmFacilityId == this.facilityId);
          console.log('selected Fac name 1: ' + selectedFac.crmFacilityId);
          if (selectedFac) {

            console.log('selected Fac name found 1 : ' + selectedFac.crmFacilityId);

            this.selectedFacilityLocal = selectedFac;
          }
          else
            this.selectedFacilityLocal = this.selectedFacility[0];
        }

      }
      else
        this.selectedFacilityLocal = this.selectedFacility[0];


    }
    this.firstAvailabilityDate = firstAvailabilityDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate)
    this.today = this.facilitySlotDate;
    var iCounter = this.facilitySlotDate.getMonth();


    //this.checkforthePrevFlag(facilityDate, this.crmFirstAvailableStartDate);

    this.getFacilitySlots(this.facilitySlotDate, true)
    if (crmFirstAvailableStartDate == null || crmFirstAvailableStartDate == undefined)
      this.islotAvailable = false;
  }

  private setRemoteFirstAvailableDateandFetchSlotonDuration(crmFirstAvailableStartDate: Date) {
    this.setRemoteFacilityData(crmFirstAvailableStartDate);
    let firstAvailabilityDate = crmFirstAvailableStartDate;
    //let firstAvailabilityDate = this.crmFirstAvailableStartDate;
    if (this.isAppoinmentBookByCalendar && this.salesOrderdate) {

      let format = 'DD-MMM-yyyy';
      let crmFirstAvailableStartDate = moment(this.crmFirstAvailableStartDate).toDate();
      let salesOrderdate = moment(this.salesOrderdate).toDate();
      let beforeTime = moment(crmFirstAvailableStartDate, format);
      let afterTime = moment(salesOrderdate, format);
      let time = moment(this.salesOrderdate, format);
      let isSameOrAfter = time.isSameOrAfter(beforeTime);
      if (isSameOrAfter)
        firstAvailabilityDate = this.salesOrderdate;
    }
    this.firstAvailabilityDate = firstAvailabilityDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate)
    this.today = this.facilitySlotDate;
    var iCounter = this.facilitySlotDate.getMonth();


    if (this.crmFirstAvailableStartDate == undefined) {
      this.isAvailableSlot = false;
      //return;
    }
    else {
      this.isAvailableSlot = true;
    }

    if (crmFirstAvailableStartDate)
      this.getRemoteFacilitySlots(this.facilitySlotDate, true);
    else
      this.islotAvailable = false;


    //this.checkforthePrevFlag(facilityDate, this.crmFirstAvailableStartDate);
  }

  SetPatientTimezone() {

    if (this.isBillingAddress)
      this.patientTimezone = this.salesOrder.patientInfo.crmBillingAddressTimeZone ? this.salesOrder.patientInfo.crmBillingAddressTimeZone : this.salesOrder.crmDeliveryAddressTimeZone;
    else
      this.patientTimezone = this.salesOrder.crmDeliveryAddressTimeZone ? this.salesOrder.crmDeliveryAddressTimeZone : this.salesOrder.patientInfo.crmBillingAddressTimeZone;

    if (!this.isBillingAddress && (!this.salesOrder.crmPatientDeliveryAddress || this.salesOrder.crmPatientDeliveryAddress == '')) {
      this.isDeliveryAddressAvailable = false;
      this.patientDetials = true;
      return;
    }

    //add selected time zone on patient time zone
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.timeZone.find(x => x.orgcode == this.patientTimezone).code);

    if (this.selectedtherapistBasedTimeZone == undefined || this.selectedtherapistBasedTimeZone == "null" || this.selectedtherapistBasedTimeZone == "")
      this.selectedtherapistBasedTimeZone = this.patientTimezone;

  }

  setEstimatedTimeForGoogleMap(selectedStartDate, selectedEndDate, therapistId) {
    this.crmTherapsitId = therapistId;
    this.selectedPatientLatLong = this.salesOrder.patientInfo.crmLatitude + ',' + this.salesOrder.patientInfo.crmLongitude;
    let isPreviousSlot: boolean = false;
    let isAfterSlot: boolean = false;
    this.nextLocationPatient = null;
    this.nextLocationAddress = null;
    this.markers = [];
    this.roadShowTimeSlotsResponseModel.forEach(value => {
      this.selectedRoadShowTimeSlotTherapistModel = value.therapist;
      if (this.selectedRoadShowTimeSlotTherapistModel != null) {
        let crmSelectedTherapist = this.selectedRoadShowTimeSlotTherapistModel.find(x => x.therapistId == therapistId);
        let therapistlatLong = (crmSelectedTherapist.therapistAddressLat == null ? 0 : crmSelectedTherapist.therapistAddressLat) + ',' + (crmSelectedTherapist.therapistAddressLong == null ? 0 : crmSelectedTherapist.therapistAddressLong); //set this to starting point of map
        this.selectedTherapistCoverageArea = crmSelectedTherapist.crmCoverageArea;
        this.setTherapistPinPoint(crmSelectedTherapist.therapistAddressLat, crmSelectedTherapist.therapistAddressLong, crmSelectedTherapist.therapistFullName, crmSelectedTherapist.crmCoverageArea, crmSelectedTherapist.therapistAddress)
        let therapistFullName = crmSelectedTherapist.therapistFullName;
        let previousSlot = crmSelectedTherapist.timeSlotAppointmentInfo.filter(x => moment(x.startTime).toDate() < moment(selectedStartDate).toDate() && x.isAppointment);
        let afterSlot = crmSelectedTherapist.timeSlotAppointmentInfo.filter(x => moment(x.startTime).toDate() > moment(selectedStartDate).toDate() && x.isAppointment);
        let patientOriginData = [];
        if (previousSlot.length > 0) {
          isPreviousSlot = true;

          for (let value of previousSlot) {
            var patientData = new PatientOriginData;
            patientData.appointmentId = value.appointmentId;
            patientData.salesOrderId = value.salesOrderId;
            patientData.patientLatLong = value.crmPatientLat + ',' + value.crmPatientLong;
            patientData.patientName = value.patientFullName;
            patientData.patientAddress = value.patientFullAddress;
            patientData.compareTime = moment(value.endTime);
            patientData.isOnlyForMarker = true;
            patientData.isStartingLocation = false;
            patientData.isAfterLocation = false;
            patientOriginData.push(patientData);
          }

          if (this.isRescheduledRequest) {
            let indexof = patientOriginData.find(x => x.salesOrderId === this.salesOrder.crmSalesorderId)
            if (indexof != null) {
              const index = patientOriginData.indexOf(indexof);
              patientOriginData.splice(index, 1);
              previousSlot.splice(index, 1);
            }
          }
          let takeLast = previousSlot[previousSlot.length - 1];
          let updateItem = patientOriginData.find(x => x.appointmentId === takeLast.appointmentId);
          if (previousSlot.length > 1 && updateItem != undefined) {
            let index = patientOriginData.indexOf(updateItem);
            patientOriginData[index].isOnlyForMarker = false;
            patientOriginData[index].isStartingLocation = true;
            this.startLocationPatient = takeLast.patientFullName;
            this.startLocationAddress = takeLast.patientFullAddress;
          }
          else {
            if (patientOriginData.length > 0) {
              patientOriginData[0].isOnlyForMarker = false;
              patientOriginData[0].isStartingLocation = true;
              this.startLocationPatient = patientOriginData[0].patientName;
              this.startLocationAddress = patientOriginData[0].patientAddress;
            }
            else if (this.isRescheduledRequest && patientOriginData.length == 0) {
              var patientData = new PatientOriginData;
              patientData.appointmentId = 0;
              patientData.salesOrderId = this.salesOrder.crmSalesorderId;
              patientData.patientLatLong = therapistlatLong;
              patientData.patientName = crmSelectedTherapist.therapistDefaultWorkLocation;
              patientData.patientAddress = crmSelectedTherapist.therapistAddress;
              patientData.compareTime = 0;
              patientData.isOnlyForMarker = false;
              patientData.isStartingLocation = true;
              patientData.isAfterLocation = false;
              patientOriginData.push(patientData);
            }
          }
        }
        else {
          var patientData = new PatientOriginData;
          patientData.appointmentId = 0;
          patientData.salesOrderId = 0;
          patientData.patientLatLong = therapistlatLong;
          patientData.patientName = crmSelectedTherapist.therapistDefaultWorkLocation;
          patientData.patientAddress = crmSelectedTherapist.therapistAddress;
          patientData.compareTime = 0;
          patientData.isOnlyForMarker = false;
          patientData.isStartingLocation = true;
          patientData.isAfterLocation = false;
          patientOriginData.push(patientData);
        }

        if (this.isRescheduledRequest) {
          let indexof = afterSlot.find(x => x.salesOrderId === this.salesOrder.crmSalesorderId)
          if (indexof != null) {
            const index = afterSlot.indexOf(indexof);
            afterSlot.splice(index, 1);
          }
        }

        if (afterSlot.length > 0) {
          isAfterSlot = true;
          for (let value of afterSlot) {
            var patientData = new PatientOriginData;
            patientData.appointmentId = value.appointmentId;
            patientData.salesOrderId = value.salesOrderId;
            patientData.patientLatLong = value.crmPatientLat + ',' + value.crmPatientLong;
            patientData.patientName = value.patientFullName;
            patientData.patientAddress = value.patientFullAddress;
            patientData.compareTime = moment(value.startTime);
            patientData.isOnlyForMarker = true;
            patientData.isStartingLocation = false;
            patientData.isAfterLocation = false;
            patientOriginData.push(patientData);
          }

          let updateItem = patientOriginData.find(x => x.appointmentId === afterSlot[0].appointmentId);
          let index = patientOriginData.indexOf(updateItem);
          patientOriginData[index].isOnlyForMarker = false;
          patientOriginData[index].isAfterLocation = true;
          this.endLocationPatient = afterSlot[0].patientFullName;
          this.endLocationAddress = afterSlot[0].patientFullAddress;
        }
        else {
          this.endLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
          this.endLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
        }

        if (this.isRescheduledRequest && patientOriginData.length == 1) {
          let dataForReschedule = patientOriginData.find(x => x.salesOrderId === this.salesOrder.crmSalesorderId)
          if (dataForReschedule != undefined) {
            let index = patientOriginData.indexOf(dataForReschedule);
            if (dataForReschedule != null && dataForReschedule.isStartingLocation) {
              this.nextLocationPatient = null;
              this.nextLocationAddress = null;
              this.startLocationPatient = crmSelectedTherapist.therapistDefaultWorkLocation;
              this.startLocationAddress = crmSelectedTherapist.therapistAddress;
              patientOriginData[index].patientLatLong = therapistlatLong;
            }
            if (this.isRescheduledRequest && dataForReschedule.isAfterLocation) {
              patientOriginData[index].patientLatLong = therapistlatLong;
              this.nextLocationPatient = null;
              this.nextLocationAddress = null;
              this.startLocationPatient = crmSelectedTherapist.therapistDefaultWorkLocation;
              this.startLocationAddress = crmSelectedTherapist.therapistAddress;
            }
            if (this.isRescheduledRequest && isPreviousSlot) {
              //if (patientOriginData.find(x => x.salesOrderId === this.salesOrder.crmSalesorderId) === undefined) {
              this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
              this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
              //}
            }
          }
          else {
            if (!isPreviousSlot) {
              this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
              this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
              this.startLocationPatient = crmSelectedTherapist.therapistDefaultWorkLocation;
              this.startLocationAddress = crmSelectedTherapist.therapistAddress;
            }
            if (isAfterSlot) {
              this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
              this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
            }

          }

        }




        if (this.endLocationPatient == null && (isPreviousSlot || isAfterSlot)) {
          this.endLocationPatient = this.startLocationPatient;
          this.endLocationAddress = this.startLocationAddress;
          this.startLocationPatient = crmSelectedTherapist.therapistFullName;
          this.startLocationAddress = crmSelectedTherapist.therapistAddress;
        }
        if (!isPreviousSlot && !isAfterSlot) {
          this.nextLocationPatient = null;
          this.nextLocationAddress = null;
          this.endLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
          this.endLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;;
          this.startLocationPatient = crmSelectedTherapist.therapistFullName;
          this.startLocationAddress = crmSelectedTherapist.therapistAddress;
          if (therapistlatLong != "0,0")
            this.getSingleDistanceMatrix(therapistlatLong, this.selectedPatientLatLong, crmSelectedTherapist.therapistDefaultWorkLocation);
        }
        else {
          this.displayData = [];
          if (!this.isRescheduledRequest && isAfterSlot) {
            this.nextLocationPatient = null;
            this.nextLocationAddress = null;
            this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
            this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
          }
          else if (!this.isRescheduledRequest && !isAfterSlot) {
            this.nextLocationPatient = null;
            this.nextLocationAddress = null;
          }
          if (!this.isRescheduledRequest && !isPreviousSlot) {
            this.nextLocationPatient = null;
            this.nextLocationAddress = null;
            this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
            this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
            this.startLocationPatient = crmSelectedTherapist.therapistDefaultWorkLocation;
            this.startLocationAddress = crmSelectedTherapist.therapistAddress;
          }
          if (this.isRescheduledRequest && isPreviousSlot && isAfterSlot) {
            this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
            this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
          }
          if (this.isRescheduledRequest && !isPreviousSlot && isAfterSlot) {
            this.startLocationPatient = crmSelectedTherapist.therapistDefaultWorkLocation;
            this.startLocationAddress = crmSelectedTherapist.therapistAddress;
            this.nextLocationPatient = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
            this.nextLocationAddress = this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress;
          }
          // if (this.isRescheduledRequest && isPreviousSlot && !isAfterSlot) {
          //   this.startLocationPatient = patientOriginData[0].patientName;
          //   this.startLocationAddress = patientOriginData[0].patientAddress;
          // }
          this.getDistanceMatrix(patientOriginData, this.selectedPatientLatLong, selectedStartDate, therapistlatLong, selectedEndDate);
        }
      }
    });
  }

  async getDistanceMatrix(origin: PatientOriginData[], destination: string, selectedStartDate: any, therapistlatLong: string, selectedEndDate: any) {
    let waypts = [];
    let patientMarker = [];
    this.displayData = [];
    let j = 0;

    if (origin.length > 0) {
      for (let i = 0; i < origin.length; i++) {
        await this.appointmentService.getDistanceMatrix(origin[i].patientLatLong, destination).pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false))).toPromise().then((results) => {
          if (!results) {
            return;
          }
          this.loading = false;
          if (results.isSuccess)
            if (results.responseModel != undefined && results.responseModel != null) {
              this.distanceMatrixResponse = results.responseModel;
              if (this.distanceMatrixResponse != null) {
                if (this.distanceMatrixResponse.rows[0].elements[0].status == 'ZERO_RESULTS') {
                  this.showWarningMessage('The origin lat,long not found, Please update the address of origin.');
                  return;
                }
                if (!origin[i].isOnlyForMarker) {
                  let data = new displayDistanceMatrixData;
                  data.appointmentId = origin[i].appointmentId;
                  data.patientName = origin[i].patientName;
                  data.miles = this.patientDistance = (Number(this.distanceMatrixResponse.rows[0].elements[0].distance.value) / 1609.34).toFixed(2).toString();
                  data.googleTime = this.distanceMatrixResponse.rows[0].elements[0].duration.text;
                  data.isStartingLocation = origin[i].isStartingLocation;
                  data.duration = (Number(this.distanceMatrixResponse.rows[0].elements[0].duration.value) / 60).toString();
                  this.patientDuration = (Number(this.distanceMatrixResponse.rows[0].elements[0].duration.value) / 60).toString();
                  let therapistGetFreeTime = moment(origin[i].compareTime);
                  var selectedTime = moment(selectedStartDate);
                  var selectedEndTime = moment(selectedEndDate);
                  let compareTime;
                  if (origin[i].isAfterLocation) {
                    compareTime = moment.duration(therapistGetFreeTime.diff(selectedEndTime)).asMinutes();
                  }
                  else {
                    compareTime = moment.duration(selectedTime.diff(therapistGetFreeTime)).asMinutes();
                  }

                  let ComapareTimeTo = Number(this.distanceMatrixResponse.rows[0].elements[0].duration.value) / 60;

                  if (compareTime >= ComapareTimeTo) {
                    data.color = "green";
                    data.DiaplayText = "Recommended"
                  }
                  else if (compareTime < ComapareTimeTo) {
                    data.color = "red";
                    data.DiaplayText = "Not Recommended"
                  }
                  else if (compareTime == 0) {
                    data.color = "green";
                    data.DiaplayText = "Recommended"
                  }
                  if (this.displayData.find((t) => t.appointmentId === origin[i].appointmentId) === undefined) {
                    this.displayData.push(data);
                  }

                }


                let waypt = origin[i].patientLatLong.split(',');

                if (origin[i].isOnlyForMarker) {
                  patientMarker.push({
                    position: ({
                      lat: Number(waypt[0]),
                      lng: Number(waypt[1]),
                    }),
                    address: '<h4 class="patient-name-location">' + origin[i].patientName + '</h4>' + origin[i].patientAddress,
                    url: "assets/img/patient-location-grey.png"
                  })
                }
                else {
                  if (origin[i].salesOrderId != this.salesOrder.crmSalesorderId) {
                    if (origin[i].patientName == "Home" || origin[i].patientName == "Clinic") {
                      patientMarker.push({
                        position: ({
                          lat: Number(waypt[0]),
                          lng: Number(waypt[1]),
                        }),
                        address: "<h4 class='therapist-name-location'>Therapist</h4> <h5>" + this.selectedTherapistName + "</h5>" + " " + origin[i].patientAddress,
                        url: "assets/img/therapist-location.png"
                      })
                    }
                    if (origin[i].isStartingLocation && !(origin[i].patientName == "Home" || origin[i].patientName == "Clinic")) {
                      patientMarker.push({
                        position: ({
                          lat: Number(waypt[0]),
                          lng: Number(waypt[1]),
                        }),
                        address: '<h4 class="patient-name-location">' + origin[i].patientName + '</h4>' + origin[i].patientAddress,
                        url: "assets/img/start-location-icon.svg"
                      })
                    }
                    if (origin[i].isAfterLocation && !(origin[i].patientName == "Home" || origin[i].patientName == "Clinic")) {
                      patientMarker.push({
                        position: ({
                          lat: Number(waypt[0]),
                          lng: Number(waypt[1]),
                        }),
                        address: '<h4 class="patient-name-location">' + origin[i].patientName + '</h4>' + origin[i].patientAddress,
                        url: "assets/img/end-location-icon.svg"
                      })
                    }
                    // if(!(origin[i].isStartingLocation && origin[i].isAfterLocation) && !(origin[i].patientName == "Home" || origin[i].patientName == "Clinic")) {
                    //   patientMarker.push({
                    //     position: ({
                    //       lat: Number(waypt[0]),
                    //       lng: Number(waypt[1]),
                    //     }),
                    //     address: '<h4 class="patient-name-location">' + origin[i].patientName + '</h4>' + origin[i].patientAddress,
                    //     url: "assets/img/patient-location.png"
                    //   })
                    // }

                  }
                }
              }
              j = j + 1
              if (therapistlatLong && origin.length == j) {
                //this.displayData.sort((a, b) => (a.isStartingLocation === b.isStartingLocation ? -1 : 1));
                let tletLong = therapistlatLong.split(',');
                let latlongOfStartLocation = origin.filter(x => x.isStartingLocation);
                let latlongofEndLocation = origin.filter(x => x.isAfterLocation);
                let startLocation = latlongOfStartLocation.length > 0 ? latlongOfStartLocation[0].patientLatLong.split(',') : 0;
                let pletLong = latlongofEndLocation.length > 0 ? latlongofEndLocation[0].patientLatLong.split(',') : 0;
                let destletLong = destination.split(',');
                patientMarker.push({
                  position: ({
                    lat: Number(destletLong[0]),
                    lng: Number(destletLong[1]),
                  }),
                  address: '<h4 class="patient-name-location">' + this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName + '</h4>' + (this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress),
                  url: "assets/img/current-patient-location.png"
                })
                if (latlongofEndLocation.length != 0) {
                  waypts.push({
                    location: ({
                      lat: Number(destletLong[0]),
                      lng: Number(destletLong[1]),
                    }),
                    stopover: true
                  });
                }
                else {
                  pletLong = destination.split(',');
                }
                if (startLocation == 0 && pletLong != 0) {
                  if (!this.isRescheduledRequest) {
                    waypts.push({
                      location: ({
                        lat: Number(pletLong[0]),
                        lng: Number(pletLong[1]),
                      }),
                      stopover: true
                    });
                  }
                  //if (this.isRescheduledRequest) {
                  //  startLocation = tletLong;
                  //  patientMarker = [];
                  //  patientMarker.push({
                  //    position: ({
                  //      lat: Number(destletLong[0]),
                  //      lng: Number(destletLong[1]),
                  //    }),
                  //    address: '<h4 class="patient-name-location">' + this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName + '</h4>' + (this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress),
                  //    url: "assets/img/current-patient-location.png"
                  //  })
                  //}
                  //else {
                  startLocation = latlongofEndLocation[0].patientLatLong.split(',');
                  //}
                  pletLong = destination.split(',');
                }
                //if (startLocation != 0 && latlongofEndLocation.length == 0 && this.isRescheduledRequest) {
                //  startLocation = tletLong;
                //  patientMarker = [];
                //  patientMarker.push({
                //    position: ({
                //      lat: Number(destletLong[0]),
                //      lng: Number(destletLong[1]),
                //    }),
                //    address: '<h4 class="patient-name-location">' + this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName + '</h4>' + (this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress),
                //    url: "assets/img/current-patient-location.png"
                //  })
                //}
                this.addPatientsMarker(patientMarker);
                this.setDirection(startLocation[0], startLocation[1], pletLong[0], pletLong[1], waypts);
              }

            }
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
      }
    }
  }

  getSingleDistanceMatrix(origin: any, destination: string, therapistDefaultWorkLocation: any) {
    let patientMarker = [];

    this.startLocationPatient = therapistDefaultWorkLocation;
    this.appointmentService.getDistanceMatrix(origin, destination).pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false))).toPromise().then((results) => {
      if (!results) {
        return;
      }
      this.loading = false;
      if (results.isSuccess)
        if (results.responseModel != undefined && results.responseModel != null) {
          this.distanceMatrixResponse = results.responseModel;
          if (this.distanceMatrixResponse != null) {
            //let startDate = moment(selectedStartDate);
            //let endDate = moment(selectedEnDate);
            //let bufferDuration = moment.duration(endDate.diff(startDate));
            //let bufferDurationMinutes = bufferDuration.asMinutes();
            //let buffer = bufferDurationMinutes
            //let travelMinutes = Number(buffer);
            // let travelTime = '';
            // travelTime = (Math.floor(travelMinutes / 60) + 'h:' + travelMinutes % 60 + 'm')
            let data = new displayDistanceMatrixData;
            this.displayData = [];
            data.patientName = this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName;
            data.miles = this.patientDistance = (Number(this.distanceMatrixResponse.rows[0].elements[0].distance.value) / 1609.34).toFixed(2).toString();
            //data.time = this.patientDuration = travelTime;
            data.googleTime = this.distanceMatrixResponse.rows[0].elements[0].duration.text;
            data.duration = (Number(this.distanceMatrixResponse.rows[0].elements[0].duration.value) / 60).toString();
            this.patientDuration = (Number(this.distanceMatrixResponse.rows[0].elements[0].duration.value) / 60).toString();
            data.color = "green";
            data.DiaplayText = "Recommended"
            if (this.displayData.find((t) => t.patientName === this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName) === undefined) {
              this.displayData.push(data);
            }

            let tletLong = origin.split(',');
            let pletLong = destination.split(',');
            patientMarker.push({
              position: ({
                lat: Number(pletLong[0]),
                lng: Number(pletLong[1]),
              }),
              address: '<h4 class="patient-name-location">' + this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName + '</h4>' + (this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress),
              url: "assets/img/current-patient-location.png"
            })
            this.addPatientsMarker(patientMarker);
            this.setDirection(tletLong[0], tletLong[1], pletLong[0], pletLong[1], []);
          }
        }
    }).catch((httpResponseError) => {
      this.loading = false;
      this.showErrorMessage(httpResponseError.message);
    });
  }

  private setTherapistPinPoint(theralat, theralng, therapistName, crmCoverageArea, therapistAddress) {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   this.center = {
    //     lat: Number(theralat),
    //     lng: Number(theralng)
    //   };

    // });
    this.addTherapistMarker(theralat, theralng, therapistName, crmCoverageArea, therapistAddress);
  }

  addTherapistMarker(theralat, theralng, therapistName, crmCoverageArea, therapistAddress) {
    this.markers.push({
      position: {
        lat: Number(theralat),
        lng: Number(theralng)
      },
      icon: {
        url: "assets/img/therapist-location.png"
      },
      info: "<h4 class='therapist-name-location'>Therapist</h4> <h5>" + therapistName + "</h5>" + " " + therapistAddress,
      options: {
        animation: google.maps.Animation.DROP,
      },
    });
    this.circleCenter = {
      lat: Number(theralat),
      lng: Number(theralng)
    };
    this.radius = Number(crmCoverageArea) * 1609.344 //112654; //70 miles to meter
  }


  addPatientsMarker(patientDetail: any[]) {
    if (patientDetail.length > 0) {
      for (let i = 0; i < patientDetail.length; i++) {
        this.markers.push({
          position: patientDetail[i].position,
          icon: {
            url: patientDetail[i].url
          },
          info: patientDetail[i].address.toString(),
          options: {
            animation: google.maps.Animation.DROP,
          },
        });
      }
    }

  }

  setDirection(originLat, originLong, destinationLat, destinationLong, waypts) {
    const request: google.maps.DirectionsRequest = {
      origin: {
        lat: Number(originLat),
        lng: Number(originLong)
      },
      destination: {
        lat: Number(destinationLat),
        lng: Number(destinationLong)
      },
      waypoints: waypts,
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: true,
      provideRouteAlternatives: true,
    };
    this.directionsResults = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  openInfoWindow(marker: MapMarker, content: string) {
    if (this.infoWindow != undefined) {
      this.infoContent = content
      this.infoWindow.open(marker)
    }
  }

  addRoadHomeVisitHistory() {
    if (this.displayData.length > 1) {
      this.roadHomeVisitRequest = [];
      for (let i = 0; i < this.displayData.length; i++) {
        var roadhomehistory = new RoadHomeVisitHistoryRequestModel();
        let patientLAtLong = this.selectedPatientLatLong.split(",");
        roadhomehistory.CrmPatientId = this.salesOrder.patientInfo.crmPatientId;
        roadhomehistory.CrmAppointmentId = this.displayData[i].appointmentId;
        roadhomehistory.CrmTherapistId = this.crmTherapsitId;
        roadhomehistory.SchAppointmentStartDate = this.selectedStartDate;
        roadhomehistory.SchAppointmentEndDate = this.selectedEndDate;
        roadhomehistory.PatientAddressLat = patientLAtLong[0];
        roadhomehistory.PatientAddressLong = patientLAtLong[1];
        roadhomehistory.Distance = this.displayData[i].miles;
        roadhomehistory.Duration = this.displayData[i].duration;
        roadhomehistory.IsAppointmentBooked = true;
        roadhomehistory.AppointmentOrder = 0;
        roadhomehistory.IsRoadHomeAppointment = this.selectedAppointmentTypePopUp.toLowerCase() == 'home visit' ? true : false;
        this.roadHomeVisitRequest.push(roadhomehistory);
      }
    }
    else {
      this.roadHomeVisitRequest = [];
      var roadhomehistory = new RoadHomeVisitHistoryRequestModel();
      let patientLAtLong = this.selectedPatientLatLong.split(",");
      roadhomehistory.CrmPatientId = this.salesOrder.patientInfo.crmPatientId;
      roadhomehistory.CrmAppointmentId = this.schAppointmentId;
      roadhomehistory.CrmTherapistId = this.crmTherapsitId;
      roadhomehistory.SchAppointmentStartDate = this.selectedStartDate;
      roadhomehistory.SchAppointmentEndDate = this.selectedEndDate;
      roadhomehistory.PatientAddressLat = patientLAtLong[0];
      roadhomehistory.PatientAddressLong = patientLAtLong[1];
      roadhomehistory.Distance = this.displayData[0].miles;
      roadhomehistory.Duration = this.displayData[0].duration;
      roadhomehistory.IsAppointmentBooked = true;
      roadhomehistory.AppointmentOrder = 0;
      roadhomehistory.IsRoadHomeAppointment = this.selectedAppointmentTypePopUp.toLowerCase() == 'home visit' ? true : false;
      this.roadHomeVisitRequest.push(roadhomehistory);
    }

  }

  setOnLoadPatientMarker() {
    let patientMarker = [];
    patientMarker.push({
      position: ({
        lat: Number(this.salesOrder.patientInfo.crmLatitude),
        lng: Number(this.salesOrder.patientInfo.crmLongitude),
      }),
      address: '<h4 class="patient-name-location">' + this.salesOrder.patientInfo.crmPatientLastName + ', ' + this.salesOrder.patientInfo.crmPatientFirstName + '</h4>' + (this.isBillingAddress ? this.salesOrder.patientInfo.crmPatientAddress : this.salesOrder.patientInfo.crmPatientDeliveryAddress)
    })
    this.addPatientsMarker(patientMarker);
    this.setDirection(this.salesOrder.patientInfo.crmLatitude, this.salesOrder.patientInfo.crmLongitude, this.salesOrder.patientInfo.crmLatitude, this.salesOrder.patientInfo.crmLongitude, []);


  }
  sortingClick() {
    this.isAscendingSort = !this.isAscendingSort;
    this.isAscendingSort ? this.sortingTooltip = ASCDESCString.Min : this.sortingTooltip = ASCDESCString.Max
    if (this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth') {
      this.slotSortOrder = SortOrder.Ascending;
      this.getRemoteFacilitySlots(this.facilitySlotDate, false);
    }
    else if (!this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'telehealth') {
      this.slotSortOrder = SortOrder.Descending;
      this.getRemoteFacilitySlots(this.facilitySlotDate, false);
    }
    if (this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'facility visit') {
      this.slotSortOrder = SortOrder.Ascending;
      this.getFacilitySlots(this.facilitySlotDate, false);
    }
    else if (!this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'facility visit') {
      this.slotSortOrder = SortOrder.Descending;
      this.getFacilitySlots(this.facilitySlotDate, false);
    }
    if (this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'home visit') {
      this.slotSortOrder = SortOrder.Ascending;
      this.getRoadShowSlotsByTherapistId(this.facilitySlotDate, false);
    }
    else if (!this.isAscendingSort && this.selectedAppointmentTypePopUp.toLowerCase() == 'home visit') {
      this.slotSortOrder = SortOrder.Descending;
      this.getRoadShowSlotsByTherapistId(this.facilitySlotDate, false);
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const mm = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const dd = date.getDate().toString().padStart(2, '0');
    const yyyy = date.getFullYear().toString();
    const hh = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');

    return `${mm}-${dd}-${yyyy} ${hh}:${min}:${ss}`;
  }
}
