<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>
  <div class="form-content-outer">
    <div class="form-outer">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="col">
          <div class="form__group">
            <label class="form__label">First Name</label>
            <input formControlName="firstName" type="text" class="form__control" pInputText>
            <!-- <span *ngIf="hasError(formFields.firstName, 'required') || hasError(formFields.firstName, 'whitespace')">{{validationMessages.firstName.required}}</span> -->
            <span class="invalid-feedback" *ngIf="hasError(formFields.firstName, 'whitespace')">{{validationMessages.firstName.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.firstName, 'validCharacters')">{{validationMessages.firstName.validCharacters}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Last Name</label>
            <input formControlName="lastName" type="text" class="form__control" pInputText>
            <!-- <span *ngIf="hasError(formFields.lastName, 'required') || hasError(formFields.lastName, 'whitespace')" >{{validationMessages.lastName.required}}</span> -->
            <span class="invalid-feedback" *ngIf="hasError(formFields.lastName, 'whitespace')">{{validationMessages.lastName.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.lastName, 'validCharacters')">{{validationMessages.lastName.validCharacters}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">Email</label>
            <input formControlName="email" type="text" class="form__control" pInputText>
            <!-- <span *ngIf="hasError(formFields.email,'required')">{{commonValidationMessages.email.required}}</span> -->
            <span class="invalid-feedback" *ngIf="hasError(formFields.email,'email')">{{commonValidationMessages.email.email}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Phone Number</label>
            <!-- <input formControlName="phoneNumber" type="text" class="form__control" pInputText> -->
            <p-inputMask mask="999-999-9999" formControlName="phoneNumber" type="text" class="form__phone" pInputText maxlength="15"></p-inputMask>
            <!-- <span *ngIf="hasError(formFields.phoneNumber,'required')||hasError(formFields.phoneNumber,'whitespace')">{{validationMessages.phoneNumber.required}}</span> -->
            <span class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber,'whitespace')">{{validationMessages.phoneNumber.required}}</span>
            <!-- <span class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber,'validPhoneNumber')">{{validationMessages.phoneNumber.invalid}}</span> -->
          </div>
        </div>
        <div class="col full-width">
          <div class="form__group">
            <label class="form__label">Doctor's Preferred Appointment Mode</label>
            <div class="facility-checkbox">
                <p-checkbox name="groupname" value="val1" [(ngModel)]="isFacilityVisitSelected"
                        binary="true" [ngModelOptions]="{standalone: true}"
                        label="Facility"></p-checkbox>

                <p-checkbox name="groupname" value="val1" [(ngModel)]="isRemoteVisitSelected"
                            binary="true" [ngModelOptions]="{standalone: true}"
                            label="Remote"></p-checkbox>
                <p-checkbox name="groupname" value="val1" [(ngModel)]="isRoadHomeVisitSelected"
                            binary="true" [ngModelOptions]="{standalone: true}"
                            label="Road/Home"></p-checkbox>
            </div>
            <span class="invalid-feedback" *ngIf="isSubmitForm && !isFacilityVisitSelected && !isRemoteVisitSelected && !isRoadHomeVisitSelected">{{validationMessages.AppointmentModeSelectionRequired.required}}</span>
          </div>
        </div>
        <div class="col" *ngIf="isFacilityVisitSelected">
          <div class="form__group">
            <!-- <p-checkbox name="groupname" value="val1" [(ngModel)]="patientAtDocLocation"
                        binary="true" [ngModelOptions]="{standalone: true}"
                        label="Only Patient at Doctor's Location"></p-checkbox> -->
            <label class="form__label">Setup Facility</label>
            <span class="switchBtn" style="margin: 0 0 8px 1px;">
              <!-- <p-inputSwitch [(ngModel)]="patientAtDocLocation" [ngModelOptions]="{standalone: true}">
              </p-inputSwitch> {{patientAtDocLocation ? 'Only Facility' : 'All Facility' }} -->
              <p-radioButton name="group1" [value]="false" label="All Facilites" [ngModelOptions]="{standalone: true}"
                                 [(ngModel)]="patientAtDocLocation">
              </p-radioButton>
              <p-radioButton name="group1" [value]="true" label="Choose Facility" [ngModelOptions]="{standalone: true}"
                                 [(ngModel)]="patientAtDocLocation">
              </p-radioButton>
            </span>
            <p *ngIf="!patientAtDocLocation">All available facilites will be selected with this.</p>
            <p-multiSelect class="select-box" [(ngModel)]="selectedFacilities" *ngIf="patientAtDocLocation"
                           [ngModelOptions]="{standalone: true}" optionLabel="label" [options]="facilities"
                           dataKey="label" placeholder="Select" (onChange)="onFacilityChange($event)"
                           selectedItemsLabel="{0} Facilities Selected" [filter]="true">
            </p-multiSelect>
            <span class="invalid-feedback" *ngIf="isSubmitForm && patientAtDocLocation && selectedFacilities?.length==0">{{validationMessages.facilities.required}}</span>
          </div>
        </div>

        <!-- <div class="form__group">
          <span class="switchBtn" style="margin-left: 1px;">
            <p-inputSwitch [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}"></p-inputSwitch> {{isActive ? 'Active' : 'Inactive' }}
          </span>
        </div> -->

        <div class="btns">
          <button class="btn btn--primary">Submit</button>
          <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
        </div>
      </form>
    </div>

    <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="form-content-outer__content">
       
      </div>
    </p-scrollPanel> -->
  </div>
</div>
<!-- End Content -->
