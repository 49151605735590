<div class="plugin-outer">
  <div class="form-outer">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="col">
          <div class="form__group">
            <label class="form__label">Service</label>
            <p-dropdown class="select-box" formControlName="service" [options]="pluginServices" optionLabel="pluginName" [showClear]="true">
            </p-dropdown>
            <span class="invalid-feedback" *ngIf="hasError(formFields.service, 'required') || hasError(formFields.service, 'whitespace')">{{validationMessages.service.required}}</span>
          </div>
          <!--<div class="form__group">
            <label class="form__label">Connection Name</label>
            <input type="text" formControlName="connectionName" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.connectionName, 'required') || hasError(formFields.connectionName, 'whitespace')">{{validationMessages.connectionName.required}}</span>
          </div>-->
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">Username</label>
            <input type="text" formControlName="userName" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.userName, 'required') || hasError(formFields.userName, 'whitespace')">{{validationMessages.userName.required}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Password</label>
            <input type="password" formControlName="password" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.password, 'required') || hasError(formFields.password, 'whitespace')">{{validationMessages.password.required}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">Sales Order API URL</label>
            <input type="text" formControlName="salesOrderApiUrl" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.salesOrderApiUrl, 'required') || hasError(formFields.salesOrderApiUrl, 'whitespace')">{{validationMessages.salesOrderApiUrl.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.salesOrderApiUrl, 'validURL')">{{validationMessages.salesOrderApiUrl.validURL}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Patient API URL</label>
            <input type="text" formControlName="patientApiUrl" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.patientApiUrl, 'required') || hasError(formFields.patientApiUrl, 'whitespace')">{{validationMessages.patientApiUrl.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.patientApiUrl, 'validURL')">{{validationMessages.patientApiUrl.validURL}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Doctor API URL</label>
            <input type="text" formControlName="doctorApiUrl" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.doctorApiUrl, 'required') || hasError(formFields.password, 'whitespace')">{{validationMessages.doctorApiUrl.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.doctorApiUrl, 'validURL')">{{validationMessages.doctorApiUrl.validURL}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Facility API URL</label>
            <input type="text" formControlName="facilityApiUrl" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.facilityApiUrl, 'required') || hasError(formFields.facilityApiUrl, 'whitespace')">{{validationMessages.facilityApiUrl.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.facilityApiUrl, 'validURL')">{{validationMessages.facilityApiUrl.validURL}}</span>
          </div>
        </div>

        <div class="col">
          <div class="form__group">
            <label class="form__label">Sales Order URL</label>
            <input type="text" formControlName="salesOrderBrighttreeApiURL" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.salesOrderBrighttreeApiURL, 'required') || hasError(formFields.salesOrderBrighttreeApiURL, 'whitespace')">{{validationMessages.salesOrderBrighttreeApiURL.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.salesOrderBrighttreeApiURL, 'validURL')">{{validationMessages.salesOrderBrighttreeApiURL.validURL}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Patient URL</label>
            <input type="text" formControlName="patientBrighttreeApiURL" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.patientBrighttreeApiURL, 'required') || hasError(formFields.patientBrighttreeApiURL, 'whitespace')">{{validationMessages.patientBrighttreeApiURL.required}}</span>
            <span class="invalid-feedback" *ngIf="hasError(formFields.patientBrighttreeApiURL, 'validURL')">{{validationMessages.patientBrighttreeApiURL.validURL}}</span>
          </div>
        </div>
        <div class="btns">
          <button class="btn btn--primary mb-0" routerLink="/admin/settings/plugins">Save</button>
          <button class="btn btn--secondary mb-0" routerLink="/admin/settings">Cancel</button>
        </div>
      </form>
  </div>
</div>
  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
