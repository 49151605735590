import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize, retry } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsBrightreeRequestModel } from 'src/app/shared/models/request/settings/setting-brightree-request-model';
import { SettingsBrightreeResponseModel, SettingsBrightreeModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { NotificationTemplate } from 'src/app/shared/enums';
import { CommonFunctions } from 'src/app/shared/common-functions';
import { SettingsNotificationResponseModel, NotificationTemplateModel, SettingsGroupNotificationListResponseModel } from 'src/app/shared/models/response/settings/settings-notification-response-model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SARemiderLeadTime, NotSARemiderLeadTime, RoleId } from 'src/app/shared/enums';
import { SettingsGeneralRequestModel } from 'src/app/shared/models/request/settings/settings-general-request-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CompanyService } from 'src/app/shared/services/company.service';
import { SettingsNotificationRequestModel } from 'src/app/shared/models/request/settings/settings-notification-request-model';
import { PhonePipe } from 'src/app/pipes/usnumberformat.pipe';
@Component({
  selector: 'app-therapist',
  templateUrl: './therapist.component.html',
  styleUrls: ['./therapist.component.sass']
})
export class TherapistComponent extends BaseComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  public editor = {
    emailText: ''
  };
  viewEmailDialog: boolean;
  viewSMSDialog: boolean;

  @ViewChild('editorText', { static: false }) editorText: ClassicEditor;
  @ViewChild('editorTextSMS', { static: false }) editorTextSMS: any;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  //settingsNotificationResponseModel: SettingsNotificationResponseModel[];
  settingsGroupNotificationListResponseModel: SettingsGroupNotificationListResponseModel[];
  settingsBrightreeRequestModel: SettingsBrightreeRequestModel;
  loggedInUserId: number;
  emailTextArea: string;
  emailTitle: string;
  smsTextArea: string;
  smsTitle: string;
  selectedEmailTemplate: SettingsNotificationResponseModel;
  selectedSMSTemplate: SettingsNotificationResponseModel;
  enumSAReminderLeadTime = SARemiderLeadTime;
  enumNSAReminderLeadTime = NotSARemiderLeadTime;
  sAReminderLeadTimeOptions = [];
  nSAReminderLeadTimeOptions = [];
  SAReminderLeadTime: any;
  NSAReminderLeadTime: any;
  settingsResponseModel: Array<SettingsResponseModel>;
  settingsRequestModel: Array<SettingsRequestModel>;
  enumEmailModel: EnumModel[] = [];
  enumSMSModel: EnumModel[] = [];
  selectedEnumModel: EnumModel;
  selectedEnumModelSMS: EnumModel;
  emailTextValidation: boolean = false;
  subject: string;
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  editEmailDialog: boolean;
  editSMSDialog: boolean;

  formFields = {
    subject: 'subject',
    //emailTextArea: 'emailTextArea',
    smsTextArea: 'smsTextArea'
  };
  validationMessages = {
    subject: {
      required: 'Subject is required',
    },
    //emailTextArea: {
    //  required: 'Email message is required',
    //},
    smsTextArea: {
      required: 'SMS message is required',
    }
  };


  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    private companyService: CompanyService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    this.getNotificationFields();
    this.sAReminderLeadTimeOptions = CommonFunctions.enumSelector(this.enumSAReminderLeadTime); //Object.keys(this.enumTeamName);
    this.nSAReminderLeadTimeOptions = CommonFunctions.enumSelector(this.enumNSAReminderLeadTime);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getAllNotifications(-1);
    this.getApplicationSettings();
    this.LoadCompanyLogo();
  }

  private async LoadCompanyLogo() {
    this.configResponseModel = new ConfigResponseModel();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = 'data:image/png;base64, ' + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;

      this.companyService.clogoSubject.subscribe((dta) => {
        //alert(dta);
        this.imageData = Constants.imagePrefix + dta;
        this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      });
    }
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private getApplicationSettings() {

    this.loading = true;

    this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          this.settingsResponseModel = result.responseModel;
          var modelSAReminderLeadTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.therapistScheduleAppointmentReminderLeadTime);
          var modelNSAReminderLeadTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.therapistNotScheduleAppointmentReminderLeadTime)
          this.SAReminderLeadTime = CommonFunctions.enumSelector(this.enumSAReminderLeadTime).find(a => a.value === modelSAReminderLeadTime.schSettingValue)
          this.NSAReminderLeadTime = CommonFunctions.enumSelector(this.enumNSAReminderLeadTime).find(a => a.value === modelNSAReminderLeadTime.schSettingValue)
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getNotificationFields() {
    this.loading = true;
    this.settingsService.getNotificationFields()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<EnumModel[]>) => {
        this.processResult<any>(result, () => {
          this.enumEmailModel = result.responseModel;
          let SMSExcludeKeys = ['DeliveryNote'];
          let regex = new RegExp(SMSExcludeKeys.join('|'), 'i');
          this.enumSMSModel = result.responseModel.filter((item) => !regex.test(item.name));
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  emailReset() {
    this.emailTextArea = "";
    this.subject = "";
    this.editor.emailText = "";
  }

  emailCancel() {
    this.editEmailDialog = false;
    //overlaypanel.hide();
  }

  smsCancel() {
    this.editSMSDialog = false;
    //overlaypanel.hide();
  }

  smsReset() {
    this.smsTextArea = "";
  }

  emailToggel(event, appNotificationEventName, template: SettingsNotificationResponseModel) {
    this.formEmail.reset();
    this.editEmailDialog = true;
    const form = this.formEmail;
    this.emailTextArea = "";
    this.editor.emailText = "";
    this.subject = "";
    this.emailTitle = this.formatString(appNotificationEventName);
    this.selectedEmailTemplate = template;
    var templateEmail = template.notificationTemplate.find(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == RoleId.Therapist);
    if (templateEmail) {
      //this.emailTextArea = templateEmail.appNotificationTemplateFormat;
      //this.subject = templateEmail.appNotificationTemplateSubject;
      form.get(this.formFields.subject).setValue(templateEmail.appNotificationTemplateSubject);
      //form.get(this.formFields.emailTextArea).setValue(templateEmail.appNotificationTemplateFormat);
      this.editor.emailText = templateEmail.appNotificationTemplateFormat;
    }
    // else {
    //   var notificationRequestModel = new NotificationTemplateModel();
    //   notificationRequestModel.appNotificationId = template.appNotificationId;
    //   notificationRequestModel.appRoleId = RoleId.Therapist;
    //   notificationRequestModel.appEnumnotificationType = NotificationTemplate.Email;
    //   notificationRequestModel.appNotificationTemplateFormat = "";
    //   notificationRequestModel.appNotificationTemplateSubject = template.appNotificationEventName;
    //   notificationRequestModel.isActive = false;
    //   notificationRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //   template.notificationTemplate.push(notificationRequestModel);
    //   this.selectedEmailTemplate = template;
    // }
    //this.settingsNotificationResponseModel.find(where => where.appModuleId == this.selectedEmailTemplate.appModuleId).appNotificationInEmail = template.appNotificationInEmail;
    //if (template.appNotificationInEmail)
    //overlaypanel.toggle(event);
  }

  smsToggle(event, appNotificationEventName, template: SettingsNotificationResponseModel) {

    this.smsTextArea = "";
    this.formSMS.reset();
    this.editSMSDialog = true;
    const form = this.formSMS;

    this.smsTitle = this.formatString(appNotificationEventName);
    this.selectedSMSTemplate = template;
    var templareSMS = template.notificationTemplate.find(where => where.appEnumnotificationType == NotificationTemplate.SMS
      && where.appRoleId == RoleId.Therapist);
    if (templareSMS) {
      this.smsTextArea = templareSMS.appNotificationTemplateFormat;
      form.get(this.formFields.smsTextArea).setValue(templareSMS.appNotificationTemplateFormat);
    }
    // else {
    //   var notificationRequestModel = new NotificationTemplateModel();
    //   notificationRequestModel.appNotificationId = template.appNotificationId;
    //   notificationRequestModel.appRoleId = RoleId.Therapist;
    //   notificationRequestModel.appEnumnotificationType = NotificationTemplate.SMS;
    //   notificationRequestModel.appNotificationTemplateFormat = "";
    //   notificationRequestModel.appNotificationTemplateSubject = template.appNotificationEventName;
    //   notificationRequestModel.isActive = false;
    //   notificationRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //   template.notificationTemplate.push(notificationRequestModel);
    //   this.selectedEmailTemplate = template;
    // }
    //this.settingsNotificationResponseModel.find(where => where.appModuleId == this.selectedSMSTemplate.appModuleId).appNotificationInSms = template.appNotificationInSms;
    //if (template.appNotificationInSms)
    //overlaypanel.toggle(event);

  }

  OnEmailTemplateSave(event) {
    this.emailTextValidation = false;
    this.formEmailSubmitted = true;
    if (this.formEmail.invalid) {
      this.markFormAsTouched();
      return;
    }

    let emailtext = this.editor.emailText;

    if (!emailtext || emailtext == "") {
      this.emailTextValidation = true;
      return;
    }

    this.formEmailSubmitted = false;
    this.formSMSSubmitted = false;
    const form = this.formEmail;

    //var quill = this.textEditor.getQuill()
    //let emailtext = this.editor.emailText;
    //if (emailtext.includes('<p><br></p>')) {
    //  emailtext = emailtext.replace('<p><br></p>', ' ');
    //}
    //emailtext = emailtext.replace('</p><p>', '</span><br><span style=margin:0;padding:0;>');
    //emailtext = emailtext.replace('<p>', '<span style=margin:0;padding:0;>');
    //emailtext = emailtext.replace('</p>', '</span>');
    //emailtext = emailtext.replace('</span><span>', '</span><br><span style=margin:0;padding:0;>');
    let subject = form.get(this.formFields.subject).value;

    var indexTemplate = this.selectedEmailTemplate.notificationTemplate.findIndex(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == RoleId.Therapist);

    let groupIndex: number = 0;
    let indexFeature: number = 0;

    if (indexTemplate >= 0) {
      for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
        let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == this.selectedEmailTemplate.appNotificationGroupEventName &&
          where.appModuleId == this.selectedEmailTemplate.appModuleId);
        if (result >= 0) {
          groupIndex = i;
          indexFeature = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == this.selectedEmailTemplate.appNotificationId);
          break;
        }
      }

      //if (this.selectedEmailTemplate.appNotificationInEmail) {
      this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = emailtext;
      this.selectedEmailTemplate.notificationTemplate[indexTemplate].isActive = this.selectedEmailTemplate.appNotificationInEmail;
      this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateSubject = subject;
      this.selectedEmailTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
      this.selectedEmailTemplate.modifiedByUserInfoId = this.loggedInUserId;
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      //}
      //else {
      //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = this.emailTextArea;
      //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].isActive = false;
      //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].appNotificationTemplateSubject = this.subject;
      //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
      //  this.selectedEmailTemplate.modifiedByUserInfoId = this.loggedInUserId;
      //}

      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature].notificationTemplate[indexTemplate] = this.selectedEmailTemplate.notificationTemplate[indexTemplate];
    }
    else {
      var notificationRequestModel = new NotificationTemplateModel();
      notificationRequestModel.appNotificationId = this.selectedEmailTemplate.appNotificationId;
      notificationRequestModel.appRoleId = RoleId.Therapist;
      notificationRequestModel.appEnumnotificationType = NotificationTemplate.Email;
      notificationRequestModel.appNotificationTemplateFormat = emailtext;
      notificationRequestModel.appNotificationTemplateSubject = this.selectedEmailTemplate.appNotificationEventName;
      notificationRequestModel.isActive = this.selectedEmailTemplate.appNotificationInEmail;
      notificationRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      this.selectedEmailTemplate.notificationTemplate.push(notificationRequestModel);

      for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
        let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == this.selectedEmailTemplate.appNotificationGroupEventName &&
          where.appModuleId == this.selectedEmailTemplate.appModuleId);
        if (result >= 0) {
          groupIndex = i;
          indexFeature = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == this.selectedEmailTemplate.appNotificationId);
          break;
        }
      }
    }
    var requestModel = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature];

    const manageEmailTemplate = this.settingsService.UpdateNotificationIndividual(requestModel);
    this.loading = true;
    manageEmailTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.editEmailDialog = false;
          this.showSuccessMessage('Email template saved successfully.')
          //overlaypanel.hide();
          this.getAllNotifications(groupIndex);
        } else {
          this.editEmailDialog = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.editEmailDialog = false;
        this.showErrorMessage(httpResponseError.message);
      });


    // overlaypanel.toggle(event);
    //overlaypanel.hide();
  }

  OnSMSTemplateSave(event) {

    this.formSMSSubmitted = true;
    if (this.formSMS.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.formEmailSubmitted = false;
    this.formSMSSubmitted = false;
    const form = this.formSMS;

    //var quill = this.textEditor.getQuill()
    let smsTextArea = form.get(this.formFields.smsTextArea).value;

    var indexTemplate = this.selectedSMSTemplate.notificationTemplate.findIndex(where => where.appEnumnotificationType == NotificationTemplate.SMS
      && where.appRoleId == RoleId.Therapist);

    let groupIndex: number = 0;
    let indexFeature: number = 0;

    if (indexTemplate >= 0) {
      for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
        let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == this.selectedSMSTemplate.appNotificationGroupEventName &&
          where.appModuleId == this.selectedSMSTemplate.appModuleId);
        if (result >= 0) {
          groupIndex = i;
          indexFeature = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == this.selectedSMSTemplate.appNotificationId);
          break;
        }
      }

      // if (this.selectedSMSTemplate.appNotificationInSms) {
      this.selectedSMSTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = smsTextArea;
      this.selectedSMSTemplate.notificationTemplate[indexTemplate].isActive = this.selectedSMSTemplate.appNotificationInSms;
      this.selectedSMSTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
      this.selectedSMSTemplate.modifiedByUserInfoId = this.loggedInUserId;
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature].modifiedByUserInfoId = this.loggedInUserId;
      //}
      //else {
      //  this.selectedSMSTemplate.notificationTemplate[indexTemplate].appNotificationTemplateFormat = this.smsTextArea;
      //  this.selectedSMSTemplate.notificationTemplate[indexTemplate].isActive = false;
      //  this.selectedEmailTemplate.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
      //  this.selectedEmailTemplate.modifiedByUserInfoId = this.loggedInUserId;
      //}

      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature].notificationTemplate[indexTemplate] = this.selectedSMSTemplate.notificationTemplate[indexTemplate];
    }
    else {
      var notificationRequestModel = new NotificationTemplateModel();
      notificationRequestModel.appNotificationId = this.selectedSMSTemplate.appNotificationId;
      notificationRequestModel.appRoleId = RoleId.Therapist;
      notificationRequestModel.appEnumnotificationType = NotificationTemplate.SMS;
      notificationRequestModel.appNotificationTemplateFormat = smsTextArea;
      notificationRequestModel.appNotificationTemplateSubject = this.selectedSMSTemplate.appNotificationEventName;
      notificationRequestModel.isActive = this.selectedSMSTemplate.appNotificationInSms;
      notificationRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      this.selectedSMSTemplate.notificationTemplate.push(notificationRequestModel);

      for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
        let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == this.selectedSMSTemplate.appNotificationGroupEventName &&
          where.appModuleId == this.selectedSMSTemplate.appModuleId);
        if (result >= 0) {
          groupIndex = i;
          indexFeature = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == this.selectedSMSTemplate.appNotificationId);
          break;
        }
      }
    }
    var requestModel = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[indexFeature];

    const manageEmailTemplate = this.settingsService.UpdateNotificationIndividual(requestModel);
    this.loading = true;
    manageEmailTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.editSMSDialog = false;
          this.showSuccessMessage('SMS template saved successfully.')
          //overlaypanel.hide();
          this.getAllNotifications(groupIndex);
        } else {
          this.editSMSDialog = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.editSMSDialog = false;
        this.showErrorMessage(httpResponseError.message);
      });
    // overlaypanel.toggle(event);
    //overlaypanel.hide();
  }

  emailCheckClick(event, template: SettingsNotificationResponseModel) {
    let groupIndex: number = 0;
    let index: number = 0;

    for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
      let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == template.appNotificationGroupEventName &&
        where.appModuleId == template.appModuleId);
      if (result >= 0) {
        groupIndex = i;
        index = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == template.appNotificationId);
        break;
      }
    }

    if (template.notificationTemplate.length <= 0) {
      //this.showInfoMessage('To activate the email notification you need add template first.');
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInEmail = false;
      return;
    }

    var indexTemplate = template.notificationTemplate.findIndex(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == RoleId.Therapist);

    if (indexTemplate < 0 || (template.notificationTemplate[indexTemplate].appNotificationTemplateFormat == "" || template.notificationTemplate[indexTemplate].appNotificationTemplateSubject == "")) {
      //this.showInfoMessage('To activate the email notification you need add template first.');
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInEmail = false;
      return;
    }

    if (indexTemplate >= 0) {
      if (template.appNotificationInEmail) {
        template.notificationTemplate[indexTemplate].isActive = true;
        template.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
      else {
        template.notificationTemplate[indexTemplate].isActive = false;
        template.notificationTemplate[indexTemplate].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
    } else {
      // template.notificationTemplate = new Array<NotificationTemplateModel>();
      if (template.appNotificationInEmail) {
        template.notificationTemplate[0].isActive = true;
        template.notificationTemplate[0].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
      else {
        template.notificationTemplate[0].isActive = false;
        template.notificationTemplate[0].modifiedByUserInfoId = this.loggedInUserId;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
    }

    this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].notificationTemplate[indexTemplate] = template.notificationTemplate[indexTemplate];
    var requestModel = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index];

    const manageEmailTemplate = this.settingsService.UpdateNotificationIndividual(requestModel);
    this.loading = true;
    manageEmailTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          if (this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInEmail)
            this.showSuccessMessage('Email notification activated successfully.')
          else
            this.showSuccessMessage('Email notification deactivated successfully.')
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  smsCheckClick(event, template: SettingsNotificationResponseModel) {
    let groupIndex: number = 0;
    let index: number = 0;

    for (let i = 0; i < this.settingsGroupNotificationListResponseModel.length; i++) {
      let result = this.settingsGroupNotificationListResponseModel[i].notificationTemplateList.findIndex(where => where.appNotificationGroupEventName == template.appNotificationGroupEventName &&
        where.appModuleId == template.appModuleId);
      if (result >= 0) {
        groupIndex = i;
        index = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList.findIndex(where => where.appNotificationId == template.appNotificationId);
        break;
      }
    }

    if (template.notificationTemplate.length <= 0) {
      //this.showInfoMessage('To activate the SMS notification you need add template first.');
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInSms = false;
      return;
    }

    var indexTemplate = template.notificationTemplate.findIndex(where => where.appEnumnotificationType == NotificationTemplate.SMS
      && where.appRoleId == RoleId.Therapist);

    if (indexTemplate < 0 || (template.notificationTemplate[indexTemplate].appNotificationTemplateFormat == "" || template.notificationTemplate[indexTemplate].appNotificationTemplateSubject == "")) {
      //this.showInfoMessage('To activate the SMS notification you need add template first.');
      this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInSms = false;
      return;
    }

    if (indexTemplate >= 0) {
      if (template.appNotificationInSms) {
        template.notificationTemplate[indexTemplate].isActive = true;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      } else {
        template.notificationTemplate[indexTemplate].isActive = false;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
    } else {
      // template.notificationTemplate = new Array<NotificationTemplateModel>();
      if (template.appNotificationInSms) {
        template.notificationTemplate[0].isActive = true;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      } else {
        template.notificationTemplate[0].isActive = false;
        this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].modifiedByUserInfoId = this.loggedInUserId;
      }
    }

    this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].notificationTemplate[indexTemplate] = template.notificationTemplate[indexTemplate];

    var requestModel = this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index];

    const manageSMSTemplate = this.settingsService.UpdateNotificationIndividual(requestModel);
    this.loading = true;
    manageSMSTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          if (this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInSms)
            this.showSuccessMessage('SMS notification activated successfully.');
          else
            this.showSuccessMessage('SMS notification deactivated successfully.');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  emailGroupCheckClick(event, template: SettingsGroupNotificationListResponseModel) {
    template.notificationTemplateList.forEach(value =>

      value.notificationTemplate.filter(where => where.appEnumnotificationType === NotificationTemplate.Email
        && where.appRoleId === RoleId.Therapist).forEach(item => {
          item.isActive = template.isEmailSelectAllCheck;
          item.modifiedByUserInfoId = this.loggedInUserId;
          value.modifiedByUserInfoId = this.loggedInUserId;

          if (item.appEnumnotificationType === NotificationTemplate.Email && item.isActive === true) {
            value.appNotificationInEmail = true;
          }
          else {
            value.appNotificationInEmail = false;
          }
        }));

    const manageEmailTemplate = this.settingsService.UpdateNotificationSettings(template.notificationTemplateList as Array<SettingsNotificationRequestModel>);
    this.loading = true;
    manageEmailTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          // if (this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInEmail)
          //   this.showSuccessMessage('Email notification activated successfully.')
          // else
          //   this.showSuccessMessage('Email notification deactivated successfully.')

        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  smsGroupCheckClick(event, template: SettingsGroupNotificationListResponseModel) {
    template.notificationTemplateList.forEach(value =>

      value.notificationTemplate.filter(where => where.appEnumnotificationType === NotificationTemplate.SMS
        && where.appRoleId === RoleId.Therapist).forEach(item => {
          item.isActive = template.isSMSSelectAllCheck;
          item.modifiedByUserInfoId = this.loggedInUserId;
          value.modifiedByUserInfoId = this.loggedInUserId;

          if (item.isActive === true) {
            value.appNotificationInSms = true;
          }
          else {
            value.appNotificationInSms = false;
          }
        }));

    const manageEmailTemplate = this.settingsService.UpdateNotificationSettings(template.notificationTemplateList as Array<SettingsNotificationRequestModel>);
    this.loading = true;
    manageEmailTemplate.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          // if (this.settingsGroupNotificationListResponseModel[groupIndex].notificationTemplateList[index].appNotificationInEmail)
          //   this.showSuccessMessage('Email notification activated successfully.')
          // else
          //   this.showSuccessMessage('Email notification deactivated successfully.')

        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getAllNotifications(GroupIndex: number) {
    this.loading = true;

    // this.settingsService.getAllNotificationsByRole(2)
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .subscribe((result: ResultOfT<any>) => {
    //     this.processResult<any>(result, () => {

    //       this.settingsNotificationResponseModel = result.responseModel;
    //       this.settingsNotificationResponseModel.forEach(function (setting) {

    //         var templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.Therapist);
    //         var templEmail = templ.find(where => where.appEnumnotificationType === NotificationTemplate.Email && where.isActive === true);
    //         setting.appNotificationInEmail = templEmail ? true : false;

    //         var templSMS = templ.find(where => where.appEnumnotificationType === NotificationTemplate.SMS && where.isActive === true);
    //         setting.appNotificationInSms = templSMS ? true : false;

    //         // setting.notificationTemplate = templ;
    //       });
    //       //this.setValuesToForm();
    //     });
    //   }, (httpResponseError) => {

    //     this.showErrorMessage(httpResponseError.message);
    //   });

    this.settingsService.getAllGroupNotificationsByRole(2)
      //this.settingsService.getAllNotifications()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.settingsGroupNotificationListResponseModel = result.responseModel;
          this.settingsGroupNotificationListResponseModel.forEach((x, indexValue) => {
            if (GroupIndex == -1) {
              if (indexValue == 0)
                x.isGroupCollapse = false;
              else
                x.isGroupCollapse = true;
            }
            else {
              if (indexValue == GroupIndex)
                x.isGroupCollapse = false;
              else
                x.isGroupCollapse = true;
            }

            x.notificationTemplateList.forEach(
              function (setting) {
                var templ = setting.notificationTemplate.filter(where => where.appRoleId === RoleId.Therapist);
                var templEmail = templ.find(where => where.appEnumnotificationType === NotificationTemplate.Email && where.isActive === true);
                setting.appNotificationInEmail = templEmail ? true : false;
                var templSMS = templ.find(where => where.appEnumnotificationType === NotificationTemplate.SMS && where.isActive === true);
                setting.appNotificationInSms = templSMS ? true : false;
                // setting.notificationTemplate = templ;
              });

            var EmailNotifiationTemplates = x.notificationTemplateList.filter(where => where.appNotificationInEmail);

            x.isEmailSelectAllCheck = EmailNotifiationTemplates.length == x.notificationTemplateList.length ? true : false;
            var SMSNotifiationTemplates = x.notificationTemplateList.filter(where => where.appNotificationInSms);
            x.isSMSSelectAllCheck = SMSNotifiationTemplates.length == x.notificationTemplateList.length ? true : false;
          });
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  // onSave() {

  //   const manageApplicationGeneral = this.settingsService.UpdateNotificationSettings(this.settingsNotificationResponseModel);

  //   this.loading = true;
  //   manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //     .subscribe((result: Result) => {
  //       if (result && result.isSuccess) {
  //         var lstSettingsResponseModel = new Array<SettingsResponseModel>();
  //         var leadTimeRequestModel = new SettingsResponseModel();
  //         leadTimeRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.therapistScheduleAppointmentReminderLeadTime);
  //         leadTimeRequestModel.schSettingValue = this.SAReminderLeadTime.value;
  //         leadTimeRequestModel.modifiedByUserInfoId = this.loggedInUserId;
  //         lstSettingsResponseModel.push(leadTimeRequestModel);

  //         var notScheduleLeadTimeRequestModel = new SettingsResponseModel();
  //         notScheduleLeadTimeRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.therapistNotScheduleAppointmentReminderLeadTime);
  //         notScheduleLeadTimeRequestModel.schSettingValue = this.NSAReminderLeadTime.value;
  //         notScheduleLeadTimeRequestModel.modifiedByUserInfoId = this.loggedInUserId;
  //         lstSettingsResponseModel.push(notScheduleLeadTimeRequestModel);

  //         const manageApplicationGeneralLead = this.settingsService.updateSettings(lstSettingsResponseModel);


  //         manageApplicationGeneralLead.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //           .subscribe((result: Result) => {

  //             if (result.isSuccess) {

  //               this.showSuccessMessage('Therapist notification settings is updated successfully');
  //               this.getAllNotifications();
  //             } else {
  //               this.showErrorMessage(result.message);
  //             }

  //           }, (httpResponseError) => {
  //             this.showErrorMessage(httpResponseError.message);
  //           });
  //       } else {
  //         this.showErrorMessage(result.message);
  //       }

  //     }, (httpResponseError) => {
  //       this.showErrorMessage(httpResponseError.message);
  //     });
  // }
  onChange(selectedTags) {

    const form = this.formEmail;
    //var quill = this.textEditor.getQuill()
    let emailtext = this.editor.emailText;
    var selectedTag = this.enumEmailModel.find(where => where.value == selectedTags.value);
    let Newtext = ' ##' + selectedTag.name + '## ';
    emailtext = emailtext + Newtext;
    //form.get(this.formFields.emailTextArea).setValue(emailtext);
    //this.editor.emailText = emailtext;

    const selection = this.editorText.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
    this.editorText.editorInstance.model.change(writer => {
      writer.insert(Newtext, range.start);
    });

  }

  onChangeSMS(selectedTags) {

    const form = this.formSMS;
    //var quill = this.textEditor.getQuill()
    let smstext = form.get(this.formFields.smsTextArea).value;
    var selectedTag = this.enumSMSModel.find(where => where.value == selectedTags.value);
    smstext = smstext + ' ' + selectedTag.name + ' ';
    let newText = ' ' + selectedTag.name + ' ';
    //form.get(this.formFields.smsTextArea).setValue(smstext);
    const start = this.editorTextSMS.nativeElement.selectionStart;
    const end = this.editorTextSMS.nativeElement.selectionEnd;
    const text = this.editorTextSMS.nativeElement.value;
    const before = text.substring(0, start)
    const after = text.substring(end, text.length)
    let newtextContent = (before + newText + after)
    form.get(this.formFields.smsTextArea).setValue(newtextContent);
    this.editorTextSMS.nativeElement.selectionStart = this.editorTextSMS.nativeElement.selectionEnd = start + newText.length;
    this.editorTextSMS.nativeElement.focus()

    //this.smsTextArea = this.smsTextArea + ' ' + this.selectedEnumModelSMS.name;
  }
  get fEmail() { return (<FormGroup>this.form.get('formEmail')).controls; }

  get fSMS() { return (<FormGroup>this.form.get('formSMS')).controls; }

  get formEmail() {
    return this.form.get('formEmail');
  }

  get formSMS() {
    return this.form.get('formSMS');
  }

  formEmailSubmitted: boolean = false;
  formSMSSubmitted: boolean = false;


  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.subject, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    //formGroup.addControl(this.formFields.emailTextArea, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));


    const formGroupSMS = this.formBuilder.group({});

    formGroupSMS.addControl(this.formFields.smsTextArea, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = this.formBuilder.group({
      formEmail: formGroup,
      formSMS: formGroupSMS,
    });
  }

  viewEmail(event, template: SettingsNotificationResponseModel) {
    this.subject = "";
    this.emailTitle = this.formatString(template.appNotificationEventName);
    this.selectedEmailTemplate = template;
    var templateEmail = template.notificationTemplate.find(where => where.appEnumnotificationType == NotificationTemplate.Email
      && where.appRoleId == RoleId.Therapist);
    if (templateEmail) {
      let textArea = templateEmail.appNotificationTemplateFormat;
      let DMENumber = this.settingsResponseModel.find(x => x.schSettingKey == 'DMENumber');
      if (DMENumber) {
        let number = DMENumber.schSettingValue.replaceAll('-', '').replaceAll('_','');
        if (number.length > 0) {
          let phonePipe = new PhonePipe();
          textArea = textArea.replace('DMEPhone', phonePipe.transform(number));
        }
      }

      let DMEEmail = this.settingsResponseModel.find(x => x.schSettingKey == 'DMEEmail');
      if (DMEEmail && DMEEmail.schSettingValue.length > 0) {
        textArea = textArea.replace('DMEEmail', DMEEmail.schSettingValue);
      }
      textArea = textArea.replaceAll('##', '');
      this.emailTextArea = textArea;
      this.subject = templateEmail.appNotificationTemplateSubject;
      this.viewEmailDialog = true;
    }
  }

  viewSMS(event, template: SettingsNotificationResponseModel) {
    this.smsTitle = this.formatString(template.appNotificationEventName);
    this.selectedSMSTemplate = template;
    var templareSMS = template.notificationTemplate.find(where => where.appEnumnotificationType == NotificationTemplate.SMS
      && where.appRoleId == RoleId.Therapist);
    if (templareSMS) {
      let textArea = templareSMS.appNotificationTemplateFormat;
      let DMENumber = this.settingsResponseModel.find(x => x.schSettingKey == 'DMENumber');
      if (DMENumber) {
        let number = DMENumber.schSettingValue.replaceAll('-', '').replaceAll('_','');
        if (number.length > 0) {
          let phonePipe = new PhonePipe();
          textArea = textArea.replace('DMEPhone', phonePipe.transform(number));
        }
      }

      let DMEEmail = this.settingsResponseModel.find(x => x.schSettingKey == 'DMEEmail');
      if (DMEEmail && DMEEmail.schSettingValue.length > 0) {
        textArea = textArea.replace('DMEEmail', DMEEmail.schSettingValue);
      }
      textArea = textArea.replaceAll('##', '');
      this.smsTextArea = textArea;
      this.viewSMSDialog = true;
    }
  }

  ReminderclickEvent(template: SettingsGroupNotificationListResponseModel) {
    /*template.isGroupCollapse = !template.isGroupCollapse;*/
    this.settingsGroupNotificationListResponseModel.forEach(x => {
      if (x.appModuleDisplayFeatureName == template.appModuleDisplayFeatureName) {
        x.isGroupCollapse = template.isGroupCollapse ? false : true;
        //template.isGroupCollapse = template.isGroupCollapse ? false : true;
      }
      else
        x.isGroupCollapse = true;
    });
  }

  DisaplyViewButton(template: SettingsNotificationResponseModel, ViewType: string) {
    if (template?.notificationTemplate?.length == 0) {
      return false;
    }

    if (ViewType == "Email") {
      if (!template?.notificationTemplate?.find(x => x.appEnumnotificationType == NotificationTemplate.Email)) {
        return false;
      }
    }
    else if (ViewType == "SMS") {
      if (!template?.notificationTemplate?.find(x => x.appEnumnotificationType == NotificationTemplate.SMS)) {
        return false;
      }
    }
    return true;
  }
}
