export class RoadHomeVisitHistoryRequestModel {
    Id: number;
    CrmPatientId: number;
    CrmAppointmentId: number;
    CrmTherapistId: number;
    SchAppointmentStartDate: Date;
    SchAppointmentEndDate: Date;
    PatientAddressLat: string;
    PatientAddressLong: string;
    Distance: string;
    Duration: string;
    IsAppointmentBooked: boolean;
    AppointmentOrder: number;
    IsActive: boolean;
    IsRescheduled: boolean;
    OldAppointmentID: number;
    IsRoadHomeAppointment:boolean;

}

export class RoadHomeVisitHistoryResponseModel {
    id: number;
    crmPatientId: number;
    crmAppointmentId: number;
    crmTherapistId: number;
    schAppointmentStartDate: Date;
    schAppointmentEndDate: Date;
    patientAddressLat: string;
    patientAddressLong: string;
    distance: string;
    duration: string;
    isAppointmentBooked: boolean;
    appointmentOrder: number;
    isActive: boolean;
}