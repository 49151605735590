import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, EventEmitter, Inject, Injectable, Input} from '@angular/core';



@Injectable()

@Component({
  selector: 'mwl-week-view-scheduler',
  styleUrls: ['./week-view-scheduler.component.sass'],
  templateUrl: './week-view-scheduler.component.html'
})

export class WeekViewSchedulerComponent {
  title = 'Mark Wood';
 
  constructor() {
  }
}
