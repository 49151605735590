<div class="notification-outer">
<div class="form__group staff-role">
  <label class="form__label">Role</label>
  <p-dropdown (onChange)="staffRoleIdOnChange($event)" optionValue="title" optionLabel="value"
    class="staffRole select-box" [options]="staffRole" dataKey="1" [showClear]="true" [(ngModel)]="selectedRole">
  </p-dropdown>
</div>
<div class="noti-patient-outer">
  <p-table class="table-outer notifications" [value]="settingsNotificationResponseModel"
    responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th class="table-outer__th">List of Features</th>
        <th class="table-outer__th">Email (Template)</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
      <tr>
        <td class="table-outer__td">
          <span class="application-left-text"><i class="ic right-arrow"></i>
            {{formatString(rowData.appNotificationEventName)}} </span>
        </td>
        <td class="table-outer__td center">
          <p-checkbox name="groupEmail" binary="true" inputId="email" (click)="emailCheckClick(rowData)"
            [(ngModel)]="rowData.appNotificationInEmail"></p-checkbox>
          <span class="appointment-actionmenu">
            <a href="javascript:void(0)"
              (click)="emailToggel($event,rowData.appNotificationEventName,rowData,EmailTemplate)">View / Edit
              Template</a>
          </span>


        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div class="btns">
  <button class="btn btn--primary" (click)="onSave()">Save</button>
  <button class="btn btn--secondary" routerLink="/admin/settings">Cancel</button>
</div>
</div>


<p-overlayPanel styleClass="overly-noti" appendTo="body"
  class="search-popup notificationPop notification-settings-email-overly" #EmailTemplate [showCloseIcon]="true">
  <div class="book-appointment-search">
    <h3>{{emailTitle}}</h3>
    <form class="form" [formGroup]="formEmail" autocomplete="off">
      <div class="form__group">
        <label class="form__label">Subject</label>
        <input type="text" class="form__control" formControlName="subject" pInputText maxlength="100">

        <div *ngIf="formEmailSubmitted && fEmail.subject.errors" class="invalid-feedback-error">
          <span *ngIf="fEmail.subject.errors.required || fEmail.subject.errors.whitespace">
            {{validationMessages.subject.required}}</span>
        </div>

      </div>
      <div class="form__group">
        <label class="form__label">Email Message</label>
        <p-editor formControlName="emailTextArea" #editorText [style]="{'height':'250px'}"></p-editor>
        <div *ngIf="formEmailSubmitted && fEmail.emailTextArea.errors" class="invalid-feedback-error">
          <span *ngIf="fEmail.emailTextArea.errors.required || fEmail.emailTextArea.errors.whitespace">
            {{validationMessages.emailTextArea.required}}</span>
        </div>
      </div>
      <div class="form__group">
        <label class="form__label">Tags</label>
        <p-dropdown optionValue="value" optionLabel="name" class="select-box" placeholder="Select Tags"
          [options]="enumModel" (onChange)="onChange($event)">
        </p-dropdown>
      </div>

      <div class="btns">
        <button class="btn btn--primary" (click)="OnEmailTemplateSave($event,EmailTemplate)">Save</button>
        <button class="btn btn--secondary" (click)="emailCancel(EmailTemplate)">Cancel</button>
        <!-- <button class="btn btn--secondary" (click)="emailReset()">Reset</button> -->
      </div>
    </form>
  </div>
</p-overlayPanel>