<div class="content-area auditlog-outer">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{titleName}}</h1>     
    </div>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>
  
    <div class="therapist-tab-info">
      <p-tabView styleClass="admin-therapists-manage" [activeIndex]="indexTab" (onChange)="handleChange($event)">
        <p-tabPanel header="Genaral">
          <ng-template pTemplate="header">
            <!--<img class="facility-ic" src="assets/img/bio-info-ic.svg" width="23" height="23" alt="Genaral History">-->
            <span>General</span>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="page-scroll therapist-history">
              <div class="table-outer-main">
                <p-table #nsdl class="table-outer" [value]="generalLogs" [paginator]="totalGeneralRecords>0?true:false" sortMode="single"
                         sortField="EventDate" [first]="searchRequestModelGeneralAuditLogs.FirstRecordIndex" [sortOrder]="0"
                         [rows]="getGeneralTotalRows()" [totalRecords]="totalGeneralRecords" [lazy]="true" [customSort]="true"
                         (onLazyLoad)="onGeneralLazyLoad($event)" responsiveLayout="scroll" [scrollable]="true"
                         scrollHeight="calc(100vh - 260px)">
                  <ng-template pTemplate="header">
                    <tr>
                      <th  class="table-outer__th" pSortableColumn="EventDate">
                        Action Date <p-sortIcon field="EventDate"></p-sortIcon>
                      </th>
                      <th class="table-outer__th" pSortableColumn="CreatedUser">
                        Action by <p-sortIcon field="CreatedUser"></p-sortIcon>
                      </th>
                      <th class="table-outer__th" pSortableColumn="ChangedScreen">
                        Module <p-sortIcon field="ChangedScreen"></p-sortIcon>
                      </th>
                      <th class="table-outer__th">
                        Action
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-log>
                    <tr>
                      <td  class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.eventDate}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.createdUser}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.changedScreen}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left" [innerHTML]="log.transaction.replaceAll('\r\n', '<br/>')"></span>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr class="primeng-table__body" *ngIf="!loading">
                      <td colspan="4" class="text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="paginatorleft" class="table-pagination">
                    <span class="table-pagination__records">
                      Total Records : &nbsp;<strong> {{totalGeneralRecords}}</strong>
                    </span>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-template>
        </p-tabPanel>

        <p-tabPanel header="Availability">
          <ng-template pTemplate="header">
            <!--<img class="facility-ic" src="assets/img/service-coverage-ic.svg" alt="doctor-icons" width="27" height="27">-->
            <span>Availability</span>
          </ng-template>
          <ng-template pTemplate="content">
            <div class="page-scroll therapist-history">
              <div class="table-outer-main">
                <p-table #nsdl1 class="table-outer" [value]="availabilityLogs" [paginator]="totalavailabilityRecords>0?true:false" sortMode="single"
                         sortField="EventDate" [first]="searchRequestModelAvailabilityAuditLogs.FirstRecordIndex" [sortOrder]="0"
                         [rows]="getAvailabilityTotalRows()" [totalRecords]="totalavailabilityRecords" [lazy]="true" [customSort]="true"
                         (onLazyLoad)="onAvailabilityLazyLoad($event)" responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy="eventGuid"
                         [scrollable]="true"
                         scrollHeight="calc(100vh - 260px)">
                  <ng-template pTemplate="header">
                    <tr>
                      <th class="table-outer__th" pSortableColumn="EventDate">
                       Action Date<p-sortIcon field="EventDate"></p-sortIcon>
                      </th>
                      <th class="table-outer__th" pSortableColumn="CreatedUser">
                        Action by <p-sortIcon field="CreatedUser"></p-sortIcon>
                      </th>
                      <th class="table-outer__th" pSortableColumn="ChangedScreen">
                        Module <p-sortIcon field="ChangedScreen"></p-sortIcon>
                      </th>
                      <th class="table-outer__th" pSortableColumn="EventType">
                        Action <p-sortIcon field="EventType"></p-sortIcon>
                      </th>
                      <th class="table-outer__th">
                        Action Details
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-log let-rowgroup="rowgroup" let-rowspan="rowspan">
                    <!-- <tr>
                      <td  class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.eventDate}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.createdUser}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{log.changedScreen}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left">{{formatString(log.eventType)}}</span>
                      </td>
                      <td class="table-outer__td">
                        <span class="facilityName" tooltipPosition="left" [innerHTML]="log.transaction.replaceAll('\r\n', '<br/>')"></span>
                      </td>
                    </tr> -->

                      <tr>
                        <td class="table-outer__td">
                          <span class="facilityName" tooltipPosition="left">{{log.eventDate}}</span>
                        </td>
                        <td class="table-outer__td" *ngIf="rowgroup" [attr.rowspan]="rowspan">
                          <span class="facilityName" tooltipPosition="left">{{log.createdUser}}</span>
                        </td>
                        <td class="table-outer__td" *ngIf="rowgroup" [attr.rowspan]="rowspan">
                          <span class="facilityName" tooltipPosition="left">{{log.changedScreen}}</span>
                        </td>
                        <td class="table-outer__td" *ngIf="rowgroup" [attr.rowspan]="rowspan">
                          <span class="facilityName" tooltipPosition="left">{{formatString(log.eventType)}}</span>
                        </td>
                        <td class="table-outer__td">
                          <span class="facilityName" tooltipPosition="left" [innerHTML]="log.transaction.replaceAll('\r\n', '<br/>')"></span>
                        </td>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr class="primeng-table__body" *ngIf="!loading">
                      <td colspan="4" class="text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="paginatorleft" class="table-pagination">
                    <span class="table-pagination__records">
                      Total Records : &nbsp;<strong> {{totalavailabilityRecords}}</strong>
                    </span>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </ng-template>

        </p-tabPanel>

      
      </p-tabView>
    </div>
    <div class="loader-block" *ngIf="loading" style="z-index: 99999999 !important;">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
   
  
</div>
