import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { DoctorService } from '../../servicies/doctor.service';
import { ResultOfT } from 'src/app/shared/models/response/result';

@Component({
  selector: 'app-doctor-detail',
  templateUrl: './doctor-detail.component.html',
  styleUrls: ['./doctor-detail.component.sass']
})
export class DoctorDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private doctorId: string;
  title = 'Doctor Detail';
  searchRequestModel: SearchRequestModel;
  doctorDetail: DoctorResponseModel;
  facilityNames: string[]=[];
  toggleLabel: string = '+ More';

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private doctorService: DoctorService,
    messageService: MessageService) {
    super(messageService);

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
   }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
     }
  }

  ngOnInit() {
    this.doctorDetail = new DoctorResponseModel();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.doctorId = params['id'];
        this.getDoctorDetail();
      });
  }

  onEdit() {
    this.router.navigate(['/admin/doctors/edit/', this.doctorId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack()
  {
    this.router.navigate(['admin/doctors'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  BranchName: boolean = false;
  BranchNameclickEvent(){
      this.BranchName = !this.BranchName;  
      this.toggleLabel = this.BranchName === false ? '+ More' : '- Less';     
  }

  private getDoctorDetail() {
    if (!this.doctorId) {
      return;
    }
    this.loading = true;
    this.doctorService.getDoctorById(Number(this.doctorId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<DoctorResponseModel>) => {
        this.processResult<DoctorResponseModel>(result, () =>
        {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.doctorDetail = result.responseModel;
              if(result.responseModel?.facilityName.trim().length>0){
                this.facilityNames = result.responseModel?.facilityName.split(',');
              }
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
