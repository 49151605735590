<div class="content-area">

    <div class="title-block">
        <h1>Export Transaction Data</h1>
        <div class="title-block__right">
            <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
        </div>
    </div>

    <div class="reports-outer">
        <div class="export-transaction">
            <div class="form__group">
                <label class="form__label">Export Date</label>
                <input matInput class="form__control " ngxDaterangepickerMd placeholder="Export Date"
                    [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
                    [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
                    [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate"
                    [locale]="callocales" [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
            </div>
            <div class="form__group btn-block">
                <button class="btn btn--primary" (click)="onExport()" style="margin-right: 1.5rem;">Export</button>
                <button class="btn btn--secondary" (click)="OnClear()">Clear</button>
            </div>
        </div>

    </div>

    <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
            animationDuration=".8s">
        </p-progressSpinner>
    </div>
</div>