import { FacilityInfoResponseModel } from './facility/facilityinfo-response-model';

export class DoctorFacilityResponseModel {
  crmDoctorFacilityId: number;
  crmDoctorId: number;
  crmFacilityId: number;
  crmDoctorFacilityIsAllowed: boolean;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;

  crmFacility: FacilityInfoResponseModel;
  // public FacilityResponseModelInApp CrmFacility { get; set; }
}
