<!-- Start Content -->
<div class="content-area admin-calendar-outer availability-calendar-main-outer">
    <div class="title-block">
      <div class="title-block__left">
        <h1>{{title}} <span>(Therapist Availability)</span></h1>
      </div>
      <div class="calendar-top-right" *ngIf="false">
        <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
          [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()" placeholder="Select Calendar View"
          optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>
      </div>
      <div class="table-filter__sort-by">
        <a href="javascript:void(0);" class="information-icon" (click)="LegendsClick()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 18 18" fill="none">
            <path d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z" fill="#3E3E3E"/>
            <path d="M10.0463 7.4115L7.47001 7.73438L7.37776 8.16188L7.88401 8.25525C8.21476 8.334 8.28001 8.45325 8.20801 8.78288L7.37776 12.6844C7.15951 13.6935 7.49588 14.1683 8.28676 14.1683C8.89988 14.1683 9.61201 13.8848 9.93488 13.4955L10.0339 13.0275C9.80888 13.2255 9.48038 13.3043 9.26213 13.3043C8.95276 13.3043 8.84026 13.0871 8.92013 12.7046L10.0463 7.4115ZM10.125 5.0625C10.125 5.36087 10.0065 5.64702 9.7955 5.85799C9.58452 6.06897 9.29838 6.1875 9.00001 6.1875C8.70164 6.1875 8.41549 6.06897 8.20451 5.85799C7.99353 5.64702 7.87501 5.36087 7.87501 5.0625C7.87501 4.76413 7.99353 4.47798 8.20451 4.267C8.41549 4.05603 8.70164 3.9375 9.00001 3.9375C9.29838 3.9375 9.58452 4.05603 9.7955 4.267C10.0065 4.47798 10.125 4.76413 10.125 5.0625Z" fill="#3E3E3E"/>
          </svg>
          Legends
        </a>
  
        <!-- (click)="ManageAvailability(null)" -->
        <a href="javascript:void(0);" pTooltip="Therapist Availability" tooltipPosition="left" class="add-plus"
          (click)="ManageAvailability(null,null)"><img src="assets/img/add-plus.svg" width="17" alt="filters"></a>
        <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
      </div>
  
    </div>
  
    <div class="calendra-top-btn">
      <div class="btn-group calendra-next-prev-btn">
  
        <div class="mwl-calendar">
          <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 2.42857L3.75 8.5L10 14.5714L8.75 17L0 8.5L8.75 0L10 2.42857Z" fill="#868686" />
            </svg>
          </div>
          <div class="btn btn--primary todyas" mwlCalendarToday (viewDateChange)="closeOpenMonthViewDay()"
            [(viewDate)]="viewDate" (click)="dateChange()">
            Today
          </div>
          <h4 class="dates">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h4>
          <div class="btn btn--arrow next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M-1.69504e-06 14.5714L6.25 8.5L-6.33474e-07 2.42857L1.25 -7.64949e-07L10 8.5L1.25 17L-1.69504e-06 14.5714Z"
                fill="#868686" />
            </svg>
          </div>
  
        </div>
      </div>
      <div class="calendra-top-btn__facility-box" *ngIf="false"> <!-- *ngIf="false" -->
        <p-dropdown class="select-box select-facility month-calendar" [options]="facilities"
          [(ngModel)]="selectedFacility" placeholder="Facility" optionLabel="crmFacilityName" (onChange)="dateChange()">
        </p-dropdown>
      </div>
    </div>
  
    <!-- Legends informetionm-->
    <div class="legends-outer" [ngClass]="LegendsisActive ? 'expand':'collapse' ">
      <a href="javascript:void(0);" class="legend-close" (click)="LegendsClickClose()">
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
          <path d="M18.2116 2.8568L2.78845 18.1432" stroke="#3E3E3E" stroke-width="2.5"/>
          <path d="M18.143 18.2116L2.85657 2.78844" stroke="#3E3E3E" stroke-width="2.5"/>
        </svg>
      </a>
      <app-legends-info></app-legends-info>
    </div>
    <!-- Calendar Outer-->
    <div class="calendar-outer availability-calendar">
      <div class="view-by-available-slots">
  
        <ng-template #monthCellTemplate let-day="day" let-locale="locale">
          <div class="cal-cell-top">
            <span class="add-icon" *ngIf="EnableForAvaliablity(day.events)"
              (click)="ManageAvailability(day.date,day.events)" pTooltip="Add Therapist Availability">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="10" fill="white" />
                <path
                  d="M10 0C4.47719 0 0 4.47719 0 10C0 15.5231 4.47719 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47719 15.5231 0 10 0ZM10 18.7697C5.17531 18.7697 1.25 14.8247 1.25 9.99996C1.25 5.17527 5.17531 1.24996 10 1.24996C14.8247 1.24996 18.75 5.17529 18.75 9.99996C18.75 14.8246 14.8247 18.7697 10 18.7697ZM14.375 9.375H10.625V5.625C10.625 5.28 10.345 5 10 5C9.655 5 9.375 5.28 9.375 5.625V9.375H5.625C5.28 9.375 5 9.655 5 10C5 10.345 5.28 10.625 5.625 10.625H9.375V14.375C9.375 14.72 9.655 15 10 15C10.345 15 10.625 14.72 10.625 14.375V10.625H14.375C14.72 10.625 15 10.345 15 10C15 9.655 14.72 9.375 14.375 9.375Z"
                  fill="#4e4e4e" />
              </svg>
              <!-- <i class="ic plus small" pTooltip="Add Therapist Availability"></i>  -->
            </span>
            <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
          </div>
          <div class="scrolling-event">
            <div class="available-slots-outer" *ngFor="let item of day.events">
              <div class="available-slots_event" *ngIf="!item.isGlobalHoliday" [ngClass]="item.isFullDay && item.isOffice?'off-outer':'available-slots_event'"
                (click)="onEventClick($event,item,RescheduleAppointment,item)">
                <span *ngIf="!item.isHoliday && item.isFullDay && item.isOffice"
                  [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event off':'view-by-available-slots_event'"
                  pTooltip="{{ item.title1 }}">
                  <span class="cut-text">{{ item.title }}</span>
                </span>
  
                <span *ngIf="item.isHoliday && !item.isFullDay"
                  [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event off':'view-by-available-slots_event'"
                  pTooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}">
                  <span class="cut-text">{{ item.title }}</span>
                </span>
  
                <span *ngIf="!item.isHoliday && !item.isFullDay"
                  [ngClass]="item.isHoliday?'view-by-available-slots_event off':item.isFacility?'view-by-available-slots_event facility':item.isRoadShow?'view-by-available-slots_event road-show':item.isRemote?'view-by-available-slots_event remote':item.isOffice?'view-by-available-slots_event off':'view-by-available-slots_event'"
                  pTooltip="{{ item.title1 }} - {{ item.start | date:'shortTime' | lowercase }} - {{ item.end | date:'shortTime' | lowercase }}">
                  <span class="cut-text">{{ item.title }}</span>
                  <small class="event-type event-type__series" *ngIf="item.isSeries && !item.isCustom">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M14.2372 4.70012C13.7292 3.53244 12.8196 2.56812 11.6485 1.9557C10.4774 1.34329 9.10965 1.11675 7.75608 1.31099C6.4025 1.50522 5.13814 2.10946 4.15776 3.03062C3.17738 3.95178 2.53537 5.13876 2.33063 6.40872M1.21761 3.74342L1.95849 6.50843L4.93556 5.71073M2.69937 9.27344C3.20734 10.4411 4.11695 11.4054 5.28809 12.0179C6.45923 12.6303 7.82693 12.8568 9.18051 12.6626C10.5341 12.4683 11.7984 11.8641 12.7788 10.9429C13.7592 10.0218 14.4012 8.8348 14.606 7.56485M15.719 10.2301L14.9781 7.46513L12.001 8.26284"
                        stroke="#262626" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </small>
                  <small class="event-type event-type__series-custom" *ngIf="item.isSeries && item.isCustom">
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Group 117">
                        <path id="Vector"
                          d="M14.2372 5.54241C13.7292 4.37473 12.8196 3.41041 11.6485 2.79799C10.4774 2.18557 9.10965 1.95903 7.75608 2.15327C6.4025 2.34751 5.13814 2.95175 4.15776 3.87291C3.17738 4.79407 2.53537 5.98105 2.33063 7.25101M1.21761 4.58571L1.95849 7.35072L4.93556 6.55301M2.69937 10.1157C3.20734 11.2834 4.11695 12.2477 5.28809 12.8602C6.45923 13.4726 7.82693 13.6991 9.18051 13.5049C10.5341 13.3106 11.7984 12.7064 12.7788 11.7852C13.7592 10.8641 14.4012 9.67709 14.606 8.40713M15.719 11.0724L14.9781 8.30742L12.001 9.10512"
                          stroke="#9F9F9F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <line id="Line 83" x1="15.6387" y1="0.586482" x2="1.06646" y2="15.1587" stroke="#9F9F9F"
                          stroke-width="1.5" />
                      </g>
                    </svg>
                  </small>
                  <small class="event-type event-type__event" *ngIf="!item.isSeries">
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.115 14.4392C9.5787 14.4392 9.121 14.2497 8.74187 13.8705C8.36336 13.4926 8.1741 13.0356 8.1741 12.4993C8.1741 11.963 8.36367 11.5053 8.74279 11.1262C9.1213 10.7477 9.5787 10.5584 10.115 10.5584C10.6513 10.5584 11.1087 10.748 11.4872 11.1271C11.8657 11.5056 12.0549 11.963 12.0549 12.4993C12.0549 13.0356 11.8654 13.493 11.4862 13.8715C11.1083 14.25 10.6513 14.4392 10.115 14.4392ZM0.130859 17.1909V2.51516H3.2357V0.469727H4.22357V2.51516H10.7846V0.469727H11.7018V2.51516H14.8066V17.1909H0.130859ZM1.0481 16.2737H13.8894V7.66545H1.0481V16.2737Z"
                        fill="#262626" />
                    </svg>
                  </small>
                </span>
              </div>
  
              <div class="available-slots_event" *ngIf="item.isGlobalHoliday">
                <span class="view-by-available-slots_event">{{ item.title }}</span>
              </div>
            </div>
          </div>
        </ng-template>
  
        <p-overlayPanel class="table-action-popup facilities-action" styleClass="availability-menu" #RescheduleAppointment
          [dismissable]="true" (onHide)="closeOverlay(RescheduleAppointment)">
          <div class="availability-menus">
            <span class="availability-menus__availability" *ngIf="isRemoteEvent">Remote Setup</span>
            <span class="availability-menus__availability" *ngIf="isFacilityEvent">Facility Visit</span>
            <span class="availability-menus__availability" *ngIf="isRemoteAndFacilityEvent">Both Facility & Remote</span>
            <span class="availability-menus__availability" *ngIf="isRoadShowEvent">Road / Home Visit</span>
            <span class="availability-menus__availability" *ngIf="isOfficeEvent">Office / Not Available</span>
  
            <h4 class="availability-menus__name">{{description}}</h4>
            <p class="availability-menus__time">{{ clickStartDate | date:'shortTime' | lowercase }} to {{ clickEndDate |
              date:'shortTime' | lowercase }}</p>
          </div>
  
          <!-- Break Hours Slot-->
  
          <!-- <div class="availability-menus break-hours">
            <h4 class="availability-menus__name">Break Hours :</h4>
            <p class="availability-menus__time">01:00 pm to 06:00 pm</p>
          </div>
          <div class="availability-menus block-slots" #targetEl>
            <a class="block-slot-menu" href="javascript:void(0)" (click)="BlockSlots.show($event, targetEl)">
              Block Slots
              <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.7062 2.21296L6.71027 7.20469C6.61738 7.29826 6.50687 7.37254 6.38511 7.42322C6.26335 7.47391 6.13275 7.5 6.00085 7.5C5.86895 7.5 5.73835 7.47391 5.61659 7.42322C5.49483 7.37254 5.38432 7.29826 5.29144 7.20469L0.295555 2.21296C0.201904 2.12015 0.127569 2.00973 0.0768422 1.88808C0.0261154 1.76642 -4.9653e-08 1.63593 -4.38921e-08 1.50413C-3.81312e-08 1.37234 0.0261154 1.24185 0.0768423 1.12019C0.127569 0.998536 0.201904 0.888118 0.295555 0.795309C0.388442 0.701735 0.498951 0.627464 0.620709 0.576779C0.742469 0.526095 0.873067 0.5 1.00497 0.5C1.13687 0.5 1.26747 0.526095 1.38923 0.576779C1.51099 0.627464 1.6215 0.701735 1.71438 0.795309L6.00085 5.0882L10.2873 0.795309C10.4755 0.607317 10.7307 0.501704 10.9967 0.501704C11.1285 0.501704 11.2589 0.527632 11.3807 0.578009C11.5024 0.628386 11.613 0.702225 11.7062 0.795309C11.8943 0.983301 12 1.23827 12 1.50413C12 1.63578 11.974 1.76613 11.9236 1.88775C11.8732 2.00937 11.7993 2.11988 11.7062 2.21296Z" fill="#6F6F6F"/>
              </svg>                
            </a>
  
            <p-overlayPanel class="table-action-popup facilities-action" styleClass="availability-menu" #BlockSlots [dismissable]="true" (onHide)="closeOverlay()">
              <ul class="quick-link-menu">
                <li><a href="javascript:void(0)">09:00 am - 09:30 am</a></li>
                <li><a href="javascript:void(0)">01:30 pm - 02:30 pm</a></li>
                <li><a href="javascript:void(0)">03:00 pm - 03:30 pm</a></li>
              </ul>
            </p-overlayPanel>
          </div> -->
  
          <div class="availability-menus block-slots" #targetEl1 *ngIf="eventBlockSlotsList?.length>0">
            <a class="block-slot-menu" href="javascript:void(0)" (click)="BlockSlots.toggle($event, targetEl1)">
              Block Slots
              <span>
                <!-- Count Number -->
  
                <small>{{eventBlockSlotsList?.length}}</small>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.7062 2.21296L6.71027 7.20469C6.61738 7.29826 6.50687 7.37254 6.38511 7.42322C6.26335 7.47391 6.13275 7.5 6.00085 7.5C5.86895 7.5 5.73835 7.47391 5.61659 7.42322C5.49483 7.37254 5.38432 7.29826 5.29144 7.20469L0.295555 2.21296C0.201904 2.12015 0.127569 2.00973 0.0768422 1.88808C0.0261154 1.76642 -4.9653e-08 1.63593 -4.38921e-08 1.50413C-3.81312e-08 1.37234 0.0261154 1.24185 0.0768423 1.12019C0.127569 0.998536 0.201904 0.888118 0.295555 0.795309C0.388442 0.701735 0.498951 0.627464 0.620709 0.576779C0.742469 0.526095 0.873067 0.5 1.00497 0.5C1.13687 0.5 1.26747 0.526095 1.38923 0.576779C1.51099 0.627464 1.6215 0.701735 1.71438 0.795309L6.00085 5.0882L10.2873 0.795309C10.4755 0.607317 10.7307 0.501704 10.9967 0.501704C11.1285 0.501704 11.2589 0.527632 11.3807 0.578009C11.5024 0.628386 11.613 0.702225 11.7062 0.795309C11.8943 0.983301 12 1.23827 12 1.50413C12 1.63578 11.974 1.76613 11.9236 1.88775C11.8732 2.00937 11.7993 2.11988 11.7062 2.21296Z"
                    fill="#6F6F6F" />
                </svg>
              </span>
            </a>
  
            <p-overlayPanel class="table-action-popup facilities-action" styleClass="block-break-slots" #BlockSlots
              [dismissable]="true" (onHide)="closeOverlay(BlockSlots)">
              <ul class="quick-link-menu">
                <li><a href="javascript:void(0)" *ngFor="let item of eventBlockSlotsList; let i = index">
                    {{ item.crmStartTime | date:'shortTime' | lowercase }} - {{ item.crmEndTime | date:'shortTime' |
                    lowercase }}
                  </a></li>
              </ul>
            </p-overlayPanel>
          </div>
  
          <div class="availability-menus block-slots" #targetEl2 *ngIf="eventBreakSlotsList?.length>0">
            <a class="block-slot-menu" href="javascript:void(0)" (click)="BreakSlots.toggle($event, targetEl2)">
              Break Hours
  
              <span>
                <!-- Count Number -->
                <small>{{eventBreakSlotsList?.length}}</small>
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.7062 2.21296L6.71027 7.20469C6.61738 7.29826 6.50687 7.37254 6.38511 7.42322C6.26335 7.47391 6.13275 7.5 6.00085 7.5C5.86895 7.5 5.73835 7.47391 5.61659 7.42322C5.49483 7.37254 5.38432 7.29826 5.29144 7.20469L0.295555 2.21296C0.201904 2.12015 0.127569 2.00973 0.0768422 1.88808C0.0261154 1.76642 -4.9653e-08 1.63593 -4.38921e-08 1.50413C-3.81312e-08 1.37234 0.0261154 1.24185 0.0768423 1.12019C0.127569 0.998536 0.201904 0.888118 0.295555 0.795309C0.388442 0.701735 0.498951 0.627464 0.620709 0.576779C0.742469 0.526095 0.873067 0.5 1.00497 0.5C1.13687 0.5 1.26747 0.526095 1.38923 0.576779C1.51099 0.627464 1.6215 0.701735 1.71438 0.795309L6.00085 5.0882L10.2873 0.795309C10.4755 0.607317 10.7307 0.501704 10.9967 0.501704C11.1285 0.501704 11.2589 0.527632 11.3807 0.578009C11.5024 0.628386 11.613 0.702225 11.7062 0.795309C11.8943 0.983301 12 1.23827 12 1.50413C12 1.63578 11.974 1.76613 11.9236 1.88775C11.8732 2.00937 11.7993 2.11988 11.7062 2.21296Z"
                    fill="#6F6F6F" />
                </svg>
              </span>
            </a>
  
            <p-overlayPanel class="table-action-popup facilities-action" styleClass="block-break-slots" #BreakSlots
              [dismissable]="true" (onHide)="closeOverlay(BreakSlots)">
              <ul class="quick-link-menu">
                <li><a href="javascript:void(0)" *ngFor="let item of eventBreakSlotsList; let i = index">
                    {{ item.crmStartTime | date:'shortTime' | lowercase }} - {{ item.crmEndTime | date:'shortTime' |
                    lowercase }}
                  </a></li>
              </ul>
            </p-overlayPanel>
          </div>
  
          <ul class="quick-link-menu">
            <li *ngIf="isSeries">
              <a href="javascript:void(0)" (click)="EditSeries($event, RescheduleAppointment)">
                <i class="edit-icon-new"></i>Edit Series
              </a>
            </li>
            <li *ngIf="isSeries">
              <a href="javascript:void(0)" (click)="DeleteSeries($event, RescheduleAppointment)"><i
                  class="delete-icon-new"></i>Delete Series</a>
            </li>
            <li class="spacing" *ngIf="isSeries"></li>
            <li>
              <a href="javascript:void(0)" (click)="Edit($event, RescheduleAppointment)">
                <i class=" edit-icon-new"></i>Edit Event
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="Delete($event, RescheduleAppointment)">
                <i class="delete-icon-new"></i>Delete Event
              </a>
            </li>
  
          </ul>
        </p-overlayPanel>
  
        <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
  
        <div [ngSwitch]="view" class="day-view">
          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [cellTemplate]="monthCellTemplate">
          </mwl-calendar-month-view>
  
        </div>
        <!-- </p-scrollPanel> -->
      </div>
      <ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
        <div class="loader-block">
          <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
            animationDuration=".8s">
          </p-progressSpinner>
        </div>
      </ng-template>
  
      <div class="loader-block" *ngIf="loadingCustom===true" style="z-index:99999999 !important">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  
    <p-dialog class="therapist-availability-popup" [(visible)]="showPopup" [modal]="true">
      <div class="popup-content therapist-availability-set">
        <div class="therapist-availability-set__header">
          <h2>Therapist Availability</h2>
          <!-- <span class="therapist-availability-set__event-series">
            <p-radioButton name="group3" value="A" label="Available" [(ngModel)]="selectedEvent1"
              (click)="setEventsExtended()"></p-radioButton>
            <p-radioButton name="group3" value="NA" label="Not Available" [(ngModel)]="selectedEvent1"
              (click)="setEventsExtended()"></p-radioButton>
          </span> -->
        </div>
  
        <div class="therapist-availability-set__content">
  
          <!-- <div class="therapist-availability-set__col" *ngIf="selectedEvent1=='A'">
            <div class="redio-btn-tab">
              <div class="redio-btn-tab__border">
                <p-radioButton name="group2" value="WOT" label="Facility & Remote Setup" [(ngModel)]="selectedEvent"
                  (click)="SetEvents()"></p-radioButton>
                <p-radioButton name="group2" value="R" label="Road/Home Appt" [(ngModel)]="selectedEvent"
                  (click)="SetEvents()"></p-radioButton>
                <p-radioButton name="group2" value="O" label="Office" [(ngModel)]="selectedEvent" (click)="SetEvents()">
                </p-radioButton>
              </div>
            </div>
          </div> -->
          <div class="scroll-bar-outer">
  
            <p-scrollPanel styleClass="therapist-availabilitycontent-scroll-bar"
              [style]="{width: '100%', height: '100%'}">
              <div class="therapist-availability-set__col" *ngIf="RemoteAndFacility!='R' && RemoteAndFacility!='O'">
                <div class="select-facility">
                  <p-radioButton name="group4" value="FacilityOnly" label="Facility" (click)="SetEventsFacilityOption()"
                    [(ngModel)]="RemoteAndFacility"></p-radioButton>
                  <p-radioButton name="group4" value="RemoteOnly" label="Remote Setup" (click)="SetEventsFacilityOption()"
                    [(ngModel)]="RemoteAndFacility"></p-radioButton>
                  <p-radioButton name="group4" value="Both" label="Both Facility & Remote"
                    (click)="SetEventsFacilityOption()" [(ngModel)]="RemoteAndFacility"></p-radioButton>
                </div>
  
                <div class="therapist-availability-set__two-column">
                  <div class="form__group" *ngIf="RemoteAndFacility=='FacilityOnly'">
                    <label class="form__label">Facility</label>
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                      optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                      (onChange)="setFacilityName();getTime();">
                    </p-dropdown>
                  </div>
  
                  <!-- <div class="form__group" *ngIf="RemoteAndFacility=='RemoteOnly'">
                    <label class="form__label">Remote Facility</label>
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacilityRemote"
                      placeholder="Select" optionLabel="crmFacilityName" [options]="facilitiesRemote"
                      dataKey="crmFacilityId" [showClear]="true">
                    </p-dropdown>
                  </div> -->
  
                  <div class="form__group" *ngIf="RemoteAndFacility=='Both'">
                    <label class="form__label">Facility</label>
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                      optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                      (onChange)="setFacilityName();getTime()">
                    </p-dropdown>
                  </div>
  
                  <div class="form__group" *ngIf="RemoteAndFacility=='Both'">
                    <label class="form__label">Remote Facility</label>
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacilityRemote"
                      placeholder="Select" optionLabel="crmFacilityName" [options]="facilitiesRemote"
                      dataKey="crmFacilityId" [showClear]="true">
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <div class="therapist-availability-set__col" *ngIf="RemoteAndFacility=='R'">
                <div class="select-facility">
                  <strong style="margin-bottom: 1.2rem; display: inline-block;">Appointment Start Location</strong>
                  <br />
                  <p-radioButton name="group5" value="isStartFromDefaultFacility"
                    (click)="SetFacilityEventsForRoadShowOption()" label="Facility"
                    [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                    [(ngModel)]="AppointmentStartLocation"></p-radioButton>
  
                  <p-radioButton name="group5" value="isStartFromHomeAddress"
                    (click)="SetFacilityEventsForRoadShowOption()" label="Home Address"
                    [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                    [(ngModel)]="AppointmentStartLocation"></p-radioButton>
  
                </div>
                <div class="therapist-availability-set__two-column"
                  *ngIf="AppointmentStartLocation=='isStartFromDefaultFacility'">
                  <div class="form__group">
                    <label class="form__label">Facility</label>
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacility" placeholder="Select"
                      [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                      optionLabel="crmFacilityName" [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                      (onChange)="setFacilityName();getTime()">
                    </p-dropdown>
                  </div>
  
                </div>
              </div>
              <div class="therapist-availability-set__col event-description" *ngIf="RemoteAndFacility == 'O'">
                <div class="form__group" style="display: block;">
                  <label class="form__label">Event Description</label>
                  <input type="text" class="form__control" pInputText maxlength="50" [(ngModel)]="description">
                </div>
              </div>
  
              <div class="therapist-availability-set__col" *ngIf="RemoteAndFacility == 'O'">
                <div class="form__group">
                  <p-checkbox name="groupFull" binary="true" label="Full Day?" [(ngModel)]="isFullDay"></p-checkbox>
                </div>
              </div>
  
              <div class="therapist-availability-set__two-column justify-content">
  
                <div class="therapist-availability-set__date-time">
                  <strong>Date</strong>
                  <div class="datepikar">
                    <div class="date-pika">
                      <label>From</label>
                      <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customStartDate"
                        [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                        [disabled]="isEditEvent" appendTo="body"></p-calendar>
                    </div>
                    <div class="date-pika">
                      <label>To</label>
  
                      <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customEndDate"
                        [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                        [disabled]="isEditEvent" appendTo="body"></p-calendar>
  
                    </div>
                  </div>
  
                </div>
  
                <div class="therapist-availability-set__date-time" *ngIf="!isFullDay">
                  <strong>Time<ng-container
                      *ngIf="selectedFacilityTimeZone != '' && (RemoteAndFacility=='FacilityOnly' || RemoteAndFacility=='Both')">
                      <small style="color: #474747;font-weight: 400;"> | {{selectedFacilityTimeZone |
                        timezone}}</small></ng-container></strong>
                  <div class="datepikar">
                    <div class="date-pika">
                      <label>From</label>
                      <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15"
                        [controlOnly]="true" [ngModelOptions]="{standalone: true}">
  
                      </ngx-timepicker-field>
                    </div>
                    <div class="date-pika">
                      <label>To</label>
                      <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                        [ngModelOptions]="{standalone: true}">
  
                      </ngx-timepicker-field>
                    </div>
                  </div>
                  <!-- <span class="time-note">Please do not use the 0 prefixes in Hours and Minutes</span> -->
                </div>
              </div>
  
              <div class="therapist-availability-set__col">
  
                <ul class="days">
                  <li>
                    <strong>Days</strong>
                  </li>
                  <li class="event-series">
                    <strong>Weekday</strong>
                    <span class="event-series-right">
                      <ul>
                        <li class="day-check-box" *ngFor="let item of weekDays; let i = index">
  
                          <a *ngIf="i<5" class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                            [ngClass]="item.isSelected ? 'active' : '' ">
                            <p-checkbox name="group[i]" binary="true" label="{{item.name}}" value="val1"
                              [(ngModel)]="item.isSelected"
                              [disabled]="item.isDisabled || isSingleDay(item.value)"></p-checkbox>
                          </a>
                        </li>
                      </ul>
                    </span>
                  </li>
  
                  <li class="event-series border-bottom">
                    <strong>Weekend</strong>
                    <span class="event-series-right">
                      <ul class="weekend-ul">
                        <li [ngClass]="i>4 ? 'day-check-box' : ''" *ngFor="let item of weekDays; let i = index">
                          <a *ngIf="i>4" class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                            [ngClass]="item.isSelected ? 'active' : '' ">
                            <p-checkbox name="group[i]" binary="true" label="{{item.name}}" value="val1"
                              [(ngModel)]="item.isSelected"
                              [disabled]="item.isDisabled || isSingleDay(item.value)"></p-checkbox>
                          </a>
                        </li>
  
  
                      </ul>
                    </span>
                  </li>
                </ul>
              </div>
  
              <div class="therapist-availability-set__col therapist-availability-set__col--break-hours-block"
                *ngIf="isBreakHoursDisplayed">
                <span class="event-series">
                  <p-checkbox value="R" label="Break Hours?" binary="true" [(ngModel)]="isBreakHours"></p-checkbox>
                </span>
  
                <div class="therapist-availability-set__col" *ngIf="isBreakHours">
                  <div class="therapist-availability-set__date-time break-hours">
                    <div class="datepikar">
                      <label>From</label>
                      <ngx-timepicker-field [(ngModel)]="breakStartTime" [format]="12" minutesGap="15"
                        [controlOnly]="true" [ngModelOptions]="{standalone: true}">
  
                      </ngx-timepicker-field>
                    </div>
  
                    <div class="datepikar">
                      <label>To</label>
                      <ngx-timepicker-field [(ngModel)]="breakEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                        [ngModelOptions]="{standalone: true}">
  
                      </ngx-timepicker-field>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="therapist-availability-set__col therapist-availability-set__col--set-frequency"
                *ngIf="isSetRecurrenceDisplayed  && !isEditEvent">
                <span class="event-series">
                  <p-checkbox value="R" label="Set Frequency?" binary="true" [(ngModel)]="selecteRec"></p-checkbox>
                </span>
              </div>
  
              <div class="therapist-availability-set__col" *ngIf="selecteRec">
                <ul class="selecteRec-event-series">
                  <li class="event-series">
                    <span class="event-series-right">
                      <ul>
                        <li class="day-check-box" *ngFor="let item of eventSeries;">
                          <a class="days-of-weeks" href="javascript:void(0)" [ngClass]="item.isSelected ? 'active' : '' ">
                            <p-radioButton name="group1" label="{{item.name}}" (click)="onChangeEventSeries(item)"
                              value="{{item.value}}" [(ngModel)]="selectedValue"></p-radioButton>
                          </a>
                        </li>
                      </ul>
                    </span>
                  </li>
                </ul>
              </div>
  
              <div class="therapist-availability-set__col">
                <span class="event-series-select set-frequency">
                  <!-- <div class="form__group" *ngIf="selecteRec">
                    <p-dropdown class="select-box  drop-dwon-bottom" appendTo="body" [options]="Occurrence"
                      optionLabel="name" placeholder="Select" [(ngModel)]="selectedOccurrence"
                      [ngModelOptions]="{standalone: true}" (onChange)="onEventOccurrenceChange($event)"></p-dropdown>
                  </div> -->
  
  
                  <div class="form__group" *ngIf="isExactDate && selecteRec">
                    <!-- <label class="form__label">Exact Day</label> -->
                    <p-dropdown class="select-box  drop-dwon-bottom" appendTo="body" [options]="ExactDate"
                      optionLabel="name" placeholder="Select" [(ngModel)]="selectedExactDate"
                      (onChange)="onExactDateChange()" [ngModelOptions]="{standalone: true}">
                    </p-dropdown>
                  </div>
                </span>
              </div>
              <div *ngIf="selecteRec" class="event-date-of-week-month">{{eventText}}</div>
            </p-scrollPanel>
  
          </div>
        </div>
  
        <div class="set-custom-date-btn">
          <button class="btn btn--primary" (click)="onSave()" type="button">Submit</button>
          <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
        </div>
  
      </div>
    </p-dialog>
  
  </div>
  <!-- End Content -->
  
  <!-- Manage Availability Sidebar -->
  <p-sidebar [(visible)]="ManageAvailabilitySidebar" styleClass="manage-availability-sidebar" position="right"
    [transitionOptions]="'300ms'">
  
    <div class="manage-availability-block">
      <div class="manage-availability-block__header">
        <h2>{{title}} <span *ngIf="isEditEvent || isEditSeries">(Edit Availability)</span> <span
            *ngIf="!isEditEvent && !isEditSeries">(Add Availability)</span></h2>
        <!-- <span class="therapist-availability-set__event-series">
            <p-radioButton name="group3" value="A" label="Available" [(ngModel)]="selectedEvent1"
                           (click)="setEventsExtended()"></p-radioButton>
            <p-radioButton name="group3" value="NA" label="Not Available" [(ngModel)]="selectedEvent1"
                           (click)="setEventsExtended()"></p-radioButton>
          </span> -->
      </div>
  
      <div class="manage-availability-block__content-block">
  
        <div class="manage-availability-block__main-series" *ngIf="isEditSeries && isCustom">
          <div class="current-series">
            <h3 class="current-series__title">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.83594 0.910156C4.86794 0.910156 0.835938 4.94216 0.835938 9.91016C0.835938 14.8782 4.86794 18.9102 9.83594 18.9102C14.8039 18.9102 18.8359 14.8782 18.8359 9.91016C18.8359 4.94216 14.8039 0.910156 9.83594 0.910156ZM10.7359 14.4102H8.93594V9.01016H10.7359V14.4102ZM10.7359 7.21016H8.93594V5.41016H10.7359V7.21016Z"
                  fill="#2D2D2D" />
              </svg>
  
              Main Series
            </h3>
            <div class="current-series__table table-outer">
              <table class="table-outer__table">
                <thead>
                  <tr>
                    <th class="table-outer__th">Appointment Type</th>
                    <th class="table-outer__th">Date Range</th>
                    <th class="table-outer__th">Time</th>
                    <th class="table-outer__th">Break Hours</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="table-outer__td">{{mainSeriesData?.mainAppointmentType}}</td>
                    <td class="table-outer__td">
                      {{mainSeriesData?.mainDateRange}}
                      <!-- <span>Mon, Tue</span> -->
                    </td>
                    <td class="table-outer__td">{{mainSeriesData?.mainTime}}</td>
                    <td class="table-outer__td">{{mainSeriesData?.mainBreakhours}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
  
          <p class="current-series-text">* Current Series is applicable from {{mainSeriesData?.currentSeriesDateRange}}.
          </p>
        </div>
        <div class="fetch-series" *ngIf="isEditSeries && isCustom">
          <a *ngIf="isFetchFromMainSeries" class="fetch-series__link" href="javascript:void(0);"
            (click)="updateMainSeriesData()">
            <svg style="margin-top: -3px;" width="17" height="20" viewBox="0 0 17 20" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.3936 6.93457H14.2754C14.7745 6.93457 15.2531 7.11475 15.606 7.43548C15.959 7.7562 16.1572 8.1912 16.1572 8.64477V17.5378C16.1572 17.9914 15.959 18.4264 15.606 18.7471C15.2531 19.0678 14.7745 19.248 14.2754 19.248H2.98437C2.48528 19.248 2.00663 19.0678 1.65372 18.7471C1.3008 18.4264 1.10254 17.9914 1.10254 17.5378V8.64477C1.10254 8.1912 1.3008 7.7562 1.65372 7.43548C2.00663 7.11475 2.48528 6.93457 2.98437 6.93457H4.86621"
                stroke="#373737" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.86621 11.039L8.62988 14.4594L12.3936 11.039M8.62988 1.46191V13.7753" stroke="#373737"
                stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
  
            Fetch Main Series</a>
          <a *ngIf="!isFetchFromMainSeries" class="fetch-series__link" href="javascript:void(0);"
            (click)="resetMainSeriesData()">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.3239 5.52116C14.1547 3.05449 11.6047 1.35449 8.65723 1.35449C7.67231 1.35449 6.69704 1.54849 5.7871 1.9254C4.87716 2.30231 4.05037 2.85475 3.35393 3.55119C2.65749 4.24763 2.10504 5.07442 1.72813 5.98437C1.35122 6.89431 1.15723 7.86958 1.15723 8.85449C1.15723 9.83941 1.35122 10.8147 1.72813 11.7246C2.10504 12.6346 2.65749 13.4614 3.35393 14.1578C4.05037 14.8542 4.87716 15.4067 5.7871 15.7836C6.69704 16.1605 7.67231 16.3545 8.65723 16.3545C10.6463 16.3545 12.554 15.5643 13.9605 14.1578C15.367 12.7513 16.1572 10.8436 16.1572 8.85449M16.1572 1.35449V6.35449H11.1572"
                stroke="#787878" stroke-width="1.5" />
            </svg>
            Reset Current Series</a>
        </div>
  
        <div class="manage-availability-block__tabs">
          <div class="manage-availability-block__redio-btn-tab">
            <p-radioButton name="group4" value="RemoteOnly" label="Remote Setup" (click)="SetEventsFacilityOption()"
              [(ngModel)]="RemoteAndFacility"></p-radioButton>
            <p-radioButton name="group4" value="FacilityOnly" label="Facility Visit" (click)="SetEventsFacilityOption()"
              [(ngModel)]="RemoteAndFacility"></p-radioButton>
            <p-radioButton name="group4" value="Both" label="Both Facility & Remote" (click)="SetEventsFacilityOption()"
              [(ngModel)]="RemoteAndFacility"></p-radioButton>
  
            <!-- <p-radioButton name="group2" value="WOT" label="Both Remote & Facility" [(ngModel)]="selectedEvent" (click)="SetEvents()"></p-radioButton> -->
            <p-radioButton name="group4" value="R" label="Road / Home Visit" [(ngModel)]="RemoteAndFacility"
              (click)="SetEventsFacilityOption()"></p-radioButton>
            <p-radioButton name="group4" value="O" label="Office / Not Available" [(ngModel)]="RemoteAndFacility"
              (click)="SetEventsFacilityOption()"></p-radioButton>
          </div>
        </div>
  
  
        <div class="availability-blocks">
          <!-- Working time zone -->
          <!-- <span class="working-time-zone" *ngIf="RemoteAndFacility=='Both'">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 13.5H8.1V8.1H9.9V13.5ZM9.9 6.3H8.1V4.5H9.9V6.3Z" fill="#2D2D2D"/>
            </svg>                
            <strong>Working Time</strong> : 08:00 am - 05:00 pm <span>EST</span>
          </span> -->
  
          <!-- Both Facility & Remote -->
          <div class="availability-blocks__column-block" *ngIf="RemoteAndFacility != 'R' && RemoteAndFacility != 'O'">
            <!-- Remote Facility -->
            <div class="availability-blocks__col-block" *ngIf="RemoteAndFacility=='FacilityOnly'">
              <div class="availability-blocks__label-block align">Facility
              <span
                    *ngIf="RemoteAndFacility=='FacilityOnly'"  style="color:red"
                    pTooltip="The availability can be added for facilities for which the therapist possesses the state license.">
                    <svg style="margin: 0 0 -3px 5px;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                      viewBox="0 0 18 18" fill="none">
                      <path
                        d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
                        fill="#3E3E3E" />
                      <path
                        d="M10.0463 7.4115L7.47001 7.73438L7.37776 8.16188L7.88401 8.25525C8.21476 8.334 8.28001 8.45325 8.20801 8.78288L7.37776 12.6844C7.15951 13.6935 7.49588 14.1683 8.28676 14.1683C8.89988 14.1683 9.61201 13.8848 9.93488 13.4955L10.0339 13.0275C9.80888 13.2255 9.48038 13.3043 9.26213 13.3043C8.95276 13.3043 8.84026 13.0871 8.92013 12.7046L10.0463 7.4115ZM10.125 5.0625C10.125 5.36087 10.0065 5.64702 9.7955 5.85799C9.58452 6.06897 9.29838 6.1875 9.00001 6.1875C8.70164 6.1875 8.41549 6.06897 8.20451 5.85799C7.99353 5.64702 7.87501 5.36087 7.87501 5.0625C7.87501 4.76413 7.99353 4.47798 8.20451 4.267C8.41549 4.05603 8.70164 3.9375 9.00001 3.9375C9.29838 3.9375 9.58452 4.05603 9.7955 4.267C10.0065 4.47798 10.125 4.76413 10.125 5.0625Z"
                        fill="#3E3E3E" />
                    </svg>
                  </span>
                </div>
              <div class="availability-blocks__label-block-right">
                <p-dropdown class="select-box" appendTo="body" panelStyleClass="facilitydropdown"
                  [(ngModel)]="selectedFacility" placeholder="Select" optionLabel="crmFacilityName" [options]="facilities"
                  dataKey="crmFacilityId" [showClear]="true" (onChange)="setFacilityName();getTime();">
  
                </p-dropdown>
              </div>
            </div>
            <!-- Facility Visit -->
            <!-- <div class="availability-blocks__col-block" *ngIf="RemoteAndFacility=='RemoteOnly'">
              <div class="availability-blocks__label-block align">Remote Facility</div>
              <div class="availability-blocks__label-block-right">
                <p-dropdown class="select-box" appendTo="body" panelStyleClass="facilitydropdown" [(ngModel)]="selectedFacilityRemote" placeholder="Select"
                  optionLabel="crmFacilityName" [options]="facilitiesRemote" dataKey="crmFacilityId" [showClear]="true">
                </p-dropdown>
              </div>
            </div> -->
  
            <!-- Both Facility & Remote -->
            <div class="availability-blocks__remote-setup-facility" *ngIf="RemoteAndFacility=='Both'">

              <span class="includes-both">* This includes both <strong>Facility & Remote Visit</strong>
              appointments.</span>

              <div class="availability-blocks__col-block">
                <div class="availability-blocks__label-block align">
                  Facility
                  <span pTooltip="The availability can be added for facilities for which the therapist possesses the state license." tooltipClass="customtooltip">
                    <svg style="margin: 0 0 -3px 5px;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         viewBox="0 0 18 18" fill="none">
                      <path d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
                            fill="#3E3E3E" />
                      <path d="M10.0463 7.4115L7.47001 7.73438L7.37776 8.16188L7.88401 8.25525C8.21476 8.334 8.28001 8.45325 8.20801 8.78288L7.37776 12.6844C7.15951 13.6935 7.49588 14.1683 8.28676 14.1683C8.89988 14.1683 9.61201 13.8848 9.93488 13.4955L10.0339 13.0275C9.80888 13.2255 9.48038 13.3043 9.26213 13.3043C8.95276 13.3043 8.84026 13.0871 8.92013 12.7046L10.0463 7.4115ZM10.125 5.0625C10.125 5.36087 10.0065 5.64702 9.7955 5.85799C9.58452 6.06897 9.29838 6.1875 9.00001 6.1875C8.70164 6.1875 8.41549 6.06897 8.20451 5.85799C7.99353 5.64702 7.87501 5.36087 7.87501 5.0625C7.87501 4.76413 7.99353 4.47798 8.20451 4.267C8.41549 4.05603 8.70164 3.9375 9.00001 3.9375C9.29838 3.9375 9.58452 4.05603 9.7955 4.267C10.0065 4.47798 10.125 4.76413 10.125 5.0625Z"
                            fill="#3E3E3E" />
                    </svg>
                  </span>

                </div>
                <div class="availability-blocks__label-block-right">
                  <p-dropdown class="select-box" appendTo="body" panelStyleClass="facilitydropdown"
                              [(ngModel)]="selectedFacility" placeholder="Select" optionLabel="crmFacilityName"
                              [options]="facilities" dataKey="crmFacilityId" [showClear]="true"
                              (onChange)="setFacilityName();getTime()">
                  </p-dropdown>
                </div>
              </div>
  
              <!-- <div class="availability-blocks__col-block">
      <div class="availability-blocks__label-block align">Remote Facility</div>
      <div class="availability-blocks__label-block-right">
        <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedFacilityRemote" placeholder="Select"
          optionLabel="crmFacilityName" [options]="facilitiesRemote" dataKey="crmFacilityId" [showClear]="true">
        </p-dropdown>
      </div>
    </div> -->
            </div>
          </div>
  
          <!-- Road / Home Visit -->
          <div class="availability-blocks__column-block" *ngIf="RemoteAndFacility=='R'">
  
  
            <div class="availability-blocks__col-block appointment-start-location">
              <div class="availability-blocks__label-block">Appt. Start Location</div>
              <div class="availability-blocks__label-block-right">
                <p-radioButton name="group5" value="isStartFromDefaultFacility"
                  (click)="SetFacilityEventsForRoadShowOption()" label="Facility"
                  [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                  [(ngModel)]="AppointmentStartLocation"></p-radioButton>
  
                <p-radioButton name="group5" value="isStartFromHomeAddress" (click)="SetFacilityEventsForRoadShowOption()"
                  [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                  label="Home Address" [(ngModel)]="AppointmentStartLocation"></p-radioButton>
  
  
                <span class="appointment-start-location"
                  *ngIf="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)">
                  * Changing the '<strong>Appointment start location</strong>' is not allowed for Road / Home Visit.
                </span>
              </div>
            </div>
  
            <div class="availability-blocks__col-block" *ngIf="AppointmentStartLocation=='isStartFromDefaultFacility'">
              <div class="availability-blocks__label-block align">Facility</div>
              <div class="availability-blocks__label-block-right">
                <p-dropdown class="select-box" panelStyleClass="facilitydropdown" appendTo="body"
                  [(ngModel)]="selectedFacility" placeholder="Select" optionLabel="crmFacilityName" [options]="facilities"
                  dataKey="crmFacilityId" [showClear]="true"
                  [disabled]="isRoadShow && isAppointmentWithRoadHome && (isEditEvent || isEditSeries)"
                  (onChange)="setFacilityName();getTime()">
                </p-dropdown>
              </div>
            </div>
  
          </div>
  
          <!-- Office / Not Available -->
          <div class="availability-blocks__column-block" *ngIf="RemoteAndFacility == 'O'">
            <div class="availability-blocks__col-block">
              <div class="availability-blocks__label-block">Description </div>
              <div class="availability-blocks__label-block-right">
                <!-- <input type="text" class="form__control" pInputText maxlength="50" [(ngModel)]="description"> -->
                <textarea class="form__textarea" pInputTextarea [(ngModel)]="description" maxlength="50"></textarea>
                <span style="width: 100%;display: block;font-size: 13px;color: #7a7a7a;"> (Max 50 Characters)</span>
  
                 <!-- "Not available for full day? -->
                <div class="form__group" *ngIf="RemoteAndFacility == 'O'" style="width: 100%;display: block; margin: 0; padding-top: 2rem;">
                  <p-checkbox name="groupFull" binary="true" label="Full Day" [(ngModel)]="isFullDay"></p-checkbox>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="add-break-hours" *ngIf="isCustom">
            <p-checkbox value="R" label="Click here to fetch the details from the main series" binary="true" [(ngModel)]="isFetchFromMainSeries"
              ></p-checkbox>
          </div> -->
          <!-- Date Range -->
          <div class="availability-blocks__column-block">
            <div class="availability-blocks__col-block">
              <div class="availability-blocks__label-block flex">
                Date Range
                <span class="tooltip-icon" pTooltip="Main Series : {{mainSeriesData?.mainDateRange}}"
                  *ngIf="isEditSeries && isCustom">
                  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9.83594 0.910156C4.86794 0.910156 0.835938 4.94216 0.835938 9.91016C0.835938 14.8782 4.86794 18.9102 9.83594 18.9102C14.8039 18.9102 18.8359 14.8782 18.8359 9.91016C18.8359 4.94216 14.8039 0.910156 9.83594 0.910156ZM10.7359 14.4102H8.93594V9.01016H10.7359V14.4102ZM10.7359 7.21016H8.93594V5.41016H10.7359V7.21016Z"
                      fill="#2D2D2D" />
                  </svg>
                </span>
              </div>
              <div class="availability-blocks__label-block-right">
  
                <div class="datepikar">
                  <div class="date-pika">
                    <label class="form__label">Start Date</label>
                    <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customStartDate"
                      [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                      [disabled]="isEditEvent" appendTo="body" [minDate]="todayDate"></p-calendar>
                  </div>
                  <div class="date-pika">
                    <label class="form__label">End Date</label>
                    <p-calendar class="start-date" [showIcon]="true" [(ngModel)]="customEndDate"
                      [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()"
                      [disabled]="isEditEvent" appendTo="body" [minDate]="todayDate"></p-calendar>
  
                  </div>
                </div>
  
                <!-- Days -->
                <span class="event-series-right" *ngIf="selectedValue == '1' || selectedValue == '2'">
                  <ul class="event-series-right__list">
                    <li class="event-series-right__item" *ngFor="let item of weekDays; let i = index">
  
                      <a *ngIf="i<7" class="event-series-right__days" href="javascript:void(0)" ngDefaultControl
                        [ngClass]="item.isSelected ? 'active' : ''">
                        <p-checkbox name="group[i]" binary="true" label="{{item.name}}" value="val1"
                          [(ngModel)]="item.isSelected"
                          [disabled]="item.isDisabled || isSingleDay(item.value)"></p-checkbox>
                      </a>
                    </li>
  
                    <!-- <li [ngClass]="i>0 ? 'day-check-box' : ''" *ngFor="let item of weekDays; let i = index">
                        <a *ngIf="i>0" class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                          [ngClass]="item.isSelected ? 'active' : '' ">
                          <p-checkbox name="group[i]" binary="true" label="{{item.displayName}}" value="val1"
                            [(ngModel)]="item.isSelected"></p-checkbox>
                        </a>
                      </li> -->
                  </ul>
                </span>
  
              </div>
            </div>
          </div>
  
          <!-- Time Range -->
          <div class="availability-blocks__column-block" *ngIf="!isFullDay">
  
            <div class="availability-blocks__col-block">
              <div class="availability-blocks__label-block">
                <strong>Time
                  <small
                    *ngIf="selectedFacilityTimeZone != '' && (RemoteAndFacility=='FacilityOnly' || RemoteAndFacility=='Both')"
                    style="color: #474747;font-weight: 400;"> | {{selectedFacilityTimeZone | timezone}}</small>
  
                  <!-- default time zone -->
  
                  <span
                    *ngIf="RemoteAndFacility !='FacilityOnly' && RemoteAndFacility !='Both' && RemoteAndFacility !='O'"
                    pTooltip="The availability will be configured based on the time zone of the therapist's default facility.">
                    <svg style="margin: 0 0 -3px 5px;" xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                      viewBox="0 0 18 18" fill="none">
                      <path
                        d="M9 16.875C6.91142 16.875 4.90838 16.0453 3.43153 14.5685C1.95468 13.0916 1.125 11.0886 1.125 9C1.125 6.91142 1.95468 4.90838 3.43153 3.43153C4.90838 1.95468 6.91142 1.125 9 1.125C11.0886 1.125 13.0916 1.95468 14.5685 3.43153C16.0453 4.90838 16.875 6.91142 16.875 9C16.875 11.0886 16.0453 13.0916 14.5685 14.5685C13.0916 16.0453 11.0886 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
                        fill="#3E3E3E" />
                      <path
                        d="M10.0463 7.4115L7.47001 7.73438L7.37776 8.16188L7.88401 8.25525C8.21476 8.334 8.28001 8.45325 8.20801 8.78288L7.37776 12.6844C7.15951 13.6935 7.49588 14.1683 8.28676 14.1683C8.89988 14.1683 9.61201 13.8848 9.93488 13.4955L10.0339 13.0275C9.80888 13.2255 9.48038 13.3043 9.26213 13.3043C8.95276 13.3043 8.84026 13.0871 8.92013 12.7046L10.0463 7.4115ZM10.125 5.0625C10.125 5.36087 10.0065 5.64702 9.7955 5.85799C9.58452 6.06897 9.29838 6.1875 9.00001 6.1875C8.70164 6.1875 8.41549 6.06897 8.20451 5.85799C7.99353 5.64702 7.87501 5.36087 7.87501 5.0625C7.87501 4.76413 7.99353 4.47798 8.20451 4.267C8.41549 4.05603 8.70164 3.9375 9.00001 3.9375C9.29838 3.9375 9.58452 4.05603 9.7955 4.267C10.0065 4.47798 10.125 4.76413 10.125 5.0625Z"
                        fill="#3E3E3E" />
                    </svg>
                  </span>
  
                </strong>
              </div>
              <div class="availability-blocks__label-block-right">
  
                <div class="datepikar">
                  <div class="date-pika">
                    <label class="form__label">Start Time</label>
                    <!-- <p-dropdown class="select-box" appendTo="body" [options]="TimeSelections" optionLabel="name"></p-dropdown> -->
  
                    <p-dropdown class="select-box" panelStyleClass="timedropdown" appendTo="body"
                      [options]="StartTimeSelections" optionLabel="name" [(ngModel)]="customStartTime"
                      id="startTimeDropdown" placeholder="Select" optionValue="name"
                      (onChange)="updateEndTimeOptions($event.value, false)" [filter]="true"
                      [resetFilterOnHide]="true"></p-dropdown>
  
                    <!-- <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                      [ngModelOptions]="{standalone: true}"></ngx-timepicker-field> -->
                  </div>
                  <div class="date-pika">
                    <label class="form__label">End Time</label>
                    <!-- <p-dropdown class="select-box" appendTo="body" [options]="TimeSelections" optionLabel="name"></p-dropdown> -->
                    <p-dropdown class="select-box" panelStyleClass="timedropdown" appendTo="body"
                      [options]="EndTimeSelections" optionLabel="name" [(ngModel)]="customEndTime" id="endTimeDropdown"
                      placeholder="Select" optionValue="name"
                      (onChange)="updateBreakEndTimeOptions(customStartTime,$event.value)" [filter]="true"
                      [resetFilterOnHide]="true"></p-dropdown>
  
                    <!-- <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                      [ngModelOptions]="{standalone: true}"></ngx-timepicker-field> -->
                  </div>
                </div>
                <!-- <span class="time-note">Please do not use the 0 prefixes in Hours and Minutes</span> -->
  
              </div>
  
              <!--Break Hours Check box -->
              <div class="add-break-hours" *ngIf="RemoteAndFacility != 'O' && isBreakHoursDisplayed">
                <p-checkbox value="R" label="Add Break Hours?" binary="true" [(ngModel)]="isBreakHours"
                  (onChange)="updateBreakEndTimeOptions(customStartTime,customEndTime)"></p-checkbox>
              </div>
  
              <!-- <div class="fetch-main-series">
                <p-checkbox label="Click here to fetch the details from the main series." binary="true"></p-checkbox>
                <span></span>
              </div> -->
            </div>
  
          </div>
  
  
          <!-- Break Hours -->
          <div class="availability-blocks__column-block" *ngIf="RemoteAndFacility != 'O' && isBreakHours && isBreakHoursDisplayed">
            <div class="availability-blocks__col-block">
              <div class="availability-blocks__label-block">Break Hours</div>
              <div class="availability-blocks__label-block-right">
                <div class="datepikar">
                  <div class="date-pika">
                    <label class="form__label">Start Time</label>
                    <p-dropdown class="select-box" appendTo="body" panelStyleClass="timedropdown"
                      [options]="StartBreakTimeSelections" optionLabel="name" [(ngModel)]="breakStartTime"
                      id="breakStartTimeDropdown" placeholder="Select" optionValue="name"
                      (onChange)="updateEndTimeOptions($event.value, true)" [filter]="true"></p-dropdown>
  
                    <!-- <ngx-timepicker-field [(ngModel)]="breakStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                      [ngModelOptions]="{standalone: true}"></ngx-timepicker-field> -->
                  </div>
                  <div class="date-pika">
                    <label class="form__label">End Time</label>
                    <p-dropdown class="select-box" appendTo="body" panelStyleClass="timedropdown"
                      [options]="EndBreakTimeSelections" optionLabel="name" [(ngModel)]="breakEndTime"
                      id="breakEndTimeDropdown" placeholder="Select" optionValue="name" [filter]="true"></p-dropdown>
                    <!-- <ngx-timepicker-field [(ngModel)]="breakEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                      [ngModelOptions]="{standalone: true}"></ngx-timepicker-field> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <!-- Set Frequency? -->
          <div class="availability-blocks__column-block" *ngIf="isSetRecurrenceDisplayed && !isEditEvent">
            <div class="availability-blocks__col-block set-frequency-block">
              <div class="availability-blocks__label-block">Recurrence</div>
              <div class="availability-blocks__label-block-right">
  
                <div class="therapist-availability-set__col">
                  <ul class="selecteRec-event-series">
                    <li class="day-check-box" *ngFor="let item of eventSeries;">
                      <a class="days-of-weeks" href="javascript:void(0)" [ngClass]="item.isSelected ? 'active' : '' ">
                        <p-radioButton name="group1" label="{{item.name}}" (click)="onChangeEventSeries(item)"
                          value="{{item.value}}" [(ngModel)]="selectedValue"></p-radioButton>
                      </a>
                    </li>
                  </ul>
                </div>
  
                <!-- Weekly-->
                <!-- <div class="weekly-recurrence-every">
                  <div class="form__group">
                    <label class="form__label">Recurrence every</label>
                    <input type="text" class="form__control" />
                    <span>week(s) :</span>
                  </div>
                </div> -->
  
                <!-- Monthly-->
                <!-- <div class="monthly-recurrence-every">
                  <div class="form__group">
                    <p-radioButton name="groupname" value="Day" label="Day"></p-radioButton>
                    <input type="text" class="form__control" />
                    <span>of every</span>
                    <input type="text" class="form__control" />
                    <span>month(s) :</span>
                  </div>
                </div> -->
  
                <!-- Yearly-->
                <div class="yearly-recurrence-every" *ngIf="selectedValue != '1'">
  
                  <div class="form__group" *ngIf="selectedValue != '3'">
                    <label class="form__label">Recurrence every</label>
                    <input type="text" [(ngModel)]="selectedOccurrenceNumber" class="form__control" />
                    <span *ngIf="selectedValue == '2'">week(s) :</span>
                    <span *ngIf="selectedValue == '4'">years(s) :</span>
                  </div>
  
                  <div class="form__group" *ngIf="selectedValue == '3'">
                    <span>Day</span>
                    <!-- <input type="text" [(ngModel)]="selectedOccurrenceNumber" class="form__control" /> -->
                    <p-dropdown class="select-box  drop-dwon-bottom" panelStyleClass="daysdropdown" appendTo="body"
                      [options]="ExactDate" optionLabel="name" placeholder="Select" [(ngModel)]="selectedExactDate"
                      (onChange)="onExactDateChange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
                    <span>of every</span>
                    <input type="text" [(ngModel)]="selectedOccurrenceNumber" class="form__control" />
                    <span>month(s) :</span>
                  </div>
  
                  <div class="form__group border-top" *ngIf="selectedValue == '4'">
                    <span>On</span>
                    <!-- <p-radioButton name="groupname" value="On" label="On"></p-radioButton> -->
                    <!-- <p-dropdown class="select-box" appendTo="body" [options]="Monthly" optionLabel="name"></p-dropdown> -->
                    <p-dropdown class="select-box  drop-dwon-bottom" panelStyleClass="monthsdropdown" appendTo="body"
                      [options]="Monthly" optionLabel="name" placeholder="Select" [(ngModel)]="selectedExactMonth"
                      [ngModelOptions]="{standalone: true}" (onChange)="onMonthChange()"></p-dropdown>
                    <!-- <input type="text" class="form__control" /> -->
                    <p-dropdown class="select-box  drop-dwon-bottom" panelStyleClass="monthsdropdown" appendTo="body"
                      [options]="ExactDate" optionLabel="name" placeholder="Select" [(ngModel)]="selectedExactDate"
                      (onChange)="onExactDateChange()" [ngModelOptions]="{standalone: true}"></p-dropdown>
                  </div>
                </div>
  
                <!-- <div *ngIf="selecteRec" class="event-date-of-week-month">{{eventText}}</div> -->
  
              </div>
            </div>
          </div>
  
        </div>
  
      </div>
  
      <div class="manage-availability-block__bottom">
        <button class="btn btn--primary" (click)="onSave()" type="button">Save</button>
        <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
      </div>
  
    </div>
  </p-sidebar>
  
  <!-- Confirm Fetching Main Serie Popup-->
  <p-dialog [(visible)]="FetchMainSeriesPopup" [modal]="true">
    <div class="cd-popup" role="alert">
      <h2>Confirm Fetching Main Series</h2>
      <div class="cd-popup-container text-center">
        <p>This action will retrieve details from main series & override the current series details.</p>
        <p>Are you sure you want to perform this action?</p>
  
        <div class="cd-buttons">
          <button class="btn  btn--secondary" (click)="onCancelPopup()">No, Cancel</button>
          <button class="btn btn--primary mr-2" (click)="ConfirmFatch()">Yes, Confirm</button>
        </div>
      </div>
    </div>
  </p-dialog>
  
  <!-- Confirm Fetching Main Serie Popup-->
  <p-dialog [(visible)]="YesResetPopup" [modal]="true">
    <div class="cd-popup" role="alert">
      <h2>Reset Current Series</h2>
      <div class="cd-popup-container text-center">
        <p>This action will revert the details to current series.</p>
        <p>Are you sure you want to perform this action?</p>
  
        <div class="cd-buttons">
          <button class="btn  btn--secondary" (click)="onCancelPopup()">No, Cancel</button>
          <button class="btn btn--primary mr-2" (click)="ResetSeriesDataPopup()">Yes, Reset</button>
        </div>
      </div>
    </div>
  </p-dialog>
  
  <!-- Legends/information Popup-->
  <!-- <p-sidebar [(visible)]="InfoSidebar" styleClass="information-sidebar-outer" position="right"
    [transitionOptions]="'300ms'">
      <div class="info-block">
          <app-legends-info></app-legends-info>
      </div>
  </p-sidebar> -->
