import { FacilityResponseModel } from './facility/facility-response-model';
import { TherapistCertificationResponseModel } from './therapist/therapist-certification-response-model';
import { UserRoleResponseModel } from './user-role-response-model';
import { TherapistLicenseResponseModel } from './therapist/therapist-license-response-model';
import { TherapistDefaultFacilityResponseModel } from './therapist/therapist-default-facility-response-model';
import { UserBranchResponseModel } from './user-branch-response-model';
import * as moment from 'moment';
import { TherapistContactInfoResponseModel, TherapistInfoModel } from 'src/app/shared/models/response/therapist/therapist-info-model';
import { TherapistDefaultFacilityModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-model';
import { TherapistDeviceInfoModel } from 'src/app/shared/models/response/therapist/therapist-device-info-model';
import { TherapistLicenseInfoModel } from 'src/app/shared/models/response/therapist/therapist-licenseinfo-model';
import { TherapistOnlineMeetingModel, TherapistOnlineMeetingResponseModel } from 'src/app/shared/models/response/therapist/therapist-onlinemeeting-model';
export class UserInfoResponseModel {
  appUserInfoId: number;
  appUserEmail: string;
  appUserFname: string;
  appUserLname: string;
  appUserPhoneNumber: string;
  appUserpassword: string;
  appUserIsReset: boolean;
  appUserStatus: boolean;
  appUserPasswordExpiry: Date;
  dateTimeUserLastLogin: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  facilityInfo: FacilityResponseModel;
  appRoleId: number;
  appRoleName: string;
  crmFacilityId: number;
  crmFacilityName: string;
  crmBrightreeUserId: number;
  enUserInfoId: string;
  therapistCertification: TherapistCertificationResponseModel[];
  userRoleInfo: UserRoleResponseModel;
  therapistLicenseInfo: TherapistLicenseResponseModel[];
  therapistDefaultFacility: TherapistDefaultFacilityResponseModel;
  therapistContactInfo: TherapistContactInfoResponseModel;
  therapistOnlineMeetingInfo: TherapistOnlineMeetingResponseModel;
  userBranch: UserBranchResponseModel;
  userRole: UserRoleResponseModel;
}
