export const colors: any = {
  green: {
    primary: '#7ff7bd',
    secondary: '#7ff7bd',
  },
  purple: {
    primary: '#e3deff',
    secondary: '#e3deff',
  },
  blue: {
    primary: '#f5f6fa',
    secondary: '#f5f6fa',
  },
  grey: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  lightGrey: {
    primary: '#dbdbdb',
    secondary: '#dbdbdb',
  },
  darkGrey: {
    primary: '#cbcbcb',
    secondary: '#cbcbcb',
  },
  transparent: {
    primary: '#ffffff00',
    secondary: '#ffffff00',
  },
  orange: {
    primary: '#ffbd00',
    secondary: '#ffbd00',
  },
  orange1: {
    primary: '#fffaf4',
    secondary: '#fffaf4',
  },
  appointment: {
    primary: '#EDEAFF',
    secondary: '#EDEAFF',
  }
};
