import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';
import { SettingsResponseModel, CustomSettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { RemoteAppointmentTypeModel,OnlineAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { AdminCustomSettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
@Injectable({
  providedIn: 'root',
})
export class SettingsService extends BaseService {

  constructor(httpService: HttpClient,
    private router: Router,
    webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getSettings(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Patient/GetSettingByKey?SettingKey=' + settingsKey);
  }

  getAllBranchNumbers(branchid:number) {
    return this.get<ResultOfT<BranchNumbersResponseModel>>('Patient/GetBranchNumbers?branchid=' + branchid);
  }

  getWipStatusBtmapping() {
    return this.get<ResultOfT<WipStatusBTmapping[]>>('Appointment/GetWipStateBTmapping');
  }
 

  getActiveRemoteAppointmentType() {
    return this.get<ResultOfT<RemoteAppointmentTypeModel[]>>('Patient/GetActiveRemoteAppointmentType');
  }

  getActiveOnlineAppointmentType() {
    return this.get<ResultOfT<OnlineAppointmentTypeModel[]>>('Patient/GetActiveOnlineAppointmentType');
  }
  getOnlineAppointmentType() {
    return this.get<ResultOfT<OnlineAppointmentTypeModel[]>>('Patient/GetOnlineAppointmentTypes');
  }
  getAllSettings() {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettings');
  }

  getDateFormatFromSetting(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetDateFormat?SettingKey=' + settingsKey);
  }

  getSettingsByKey(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey);
  }

  getPatientSalesOrdersSetings() {
    return this.get<ResultOfT<CustomSettingsResponseModel>>('Settings/GetPatientSalesOrdersSetings');
  }

  GetTimeZoneListAsync(){
    return this.get<ResultOfT<TimeZoneAvailableResponseModel[]>>('Users/GetTimeZoneAsync');
  }

  getAllSettingInit(settingsList: { key: string, value: string }[]) {
    var url = 'Settings/GetAllBookApointmentInit?';
    for (let settings of settingsList) {
      url = url + '&' + settings.key + '=' + settings.value;
    }
    return this.get<ResultOfT<any>>(url);
  }
}
