<div class="book-appointment-search">
  <!-- <h3>Search</h3> -->
  <form class="form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSearch()">
    <label class="form__label">Search</label>
    <div class="search-box">
      <input type="text" formControlName="searchtext" class="form__control" pInputText placeholder="Patient Name / ID"
        style="width:auto" #searchBox>
      <button class="search-btn">
        <i class="search-new"></i>
      </button>

      <span class="error-msg"
        *ngIf="hasError(formFields.searchtext, 'required') || hasError(formFields.searchtext, 'whitespace')">{{validationMessages.searchtext.required}}</span>
      <span class="error-msg"
        *ngIf="hasError(formFields.searchtext, 'validAlphaNumeric')">{{validationMessages.searchtext.validAlphaNumeric}}</span>
    </div>

    <div class="form__group btn-block">
      <button class="btn btn--primary">Search</button>
    </div>

  </form>
</div>