import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { DoctorResponseModel, DoctorExclusion } from 'src/app/shared/models/response/doctor-response-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { map } from 'rxjs/operators';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import * as moment from 'moment';
import { WebStorageService } from '../../shared/services/web-storage.service';
@Injectable({
  providedIn: 'root'
})
export class DoctorService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getAllDoctors(searchRequestModel: SearchRequestModel, isRefresh:boolean) {
    var url = 'Doctor/GetPagedDoctors';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;

    if (searchRequestModel.appUserInfoId) {
      url = url + '&appUserInfoId=' + searchRequestModel.appUserInfoId;
    }
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }

    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    if (searchRequestModel.dictionaryFilter) {
      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
      
    }
    return this.get<ResultOfT<PagedResponseModel<DoctorResponseModel>>>(url);

     //return this.post<ResultOfT<PagedResponseModel<DoctorResponseModel>>>('Doctor/GetPagedDoctors', searchRequestModel);
  }

  getDoctors() {
    return this.get<ResultOfT<DoctorExclusion[]>>('Doctor/GetAllDoctors');
  }

  getDoctorById(doctorId: number) {
    return this.get<ResultOfT<DoctorResponseModel>>('Doctor/GetDoctor/' + doctorId);
  }

  updateDoctor(updatedDoctor: DoctorResponseModel) {
    return this.post<ResultOfT<DoctorResponseModel>>('Doctor/Update', updatedDoctor);
  }

  removeDoctor(doctorId: string) {
    // return this.get<ResultOfT<boolean>>(`Doctor/ActiveInactive/${doctorId}/${status}`);
    return this.get<ResultOfT<boolean>>('Doctor/ActiveInactive/' + doctorId + '/' + false);
  }
}
