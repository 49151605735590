export class AppointmentCallListResponseModel {
  SchAppointmentCallListId: number;
  SchAppointmentId: number;
  CrmSalesorderId: number;
  AppUserInfoId: number;
  IsActive: boolean
  ModifiedByUserInfoId: number;
  DateTimeCreated: Date
  DateTimeLastModified: Date
  SchIsLocked: boolean
}

export class ViewByDateRequest {
  slotDuration: string;
  date: string;
  branchid: string;
  facilityId: string;
  onlyroad: string;
}
