<div class="login">
  <div class="login__header">
    <div class="login__header__left">

      <div class="login__header__logo ">
        <img src="assets/img/appoint360-logo.png" height="80" width="107" title="Appoint360" alt="Appoint360">
      </div>

      <div class="login__header__client-logo">
        <div class="login__header__client-logo__client ">
          <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" title="{{imageTitle}}" height="65">
        </div>
      </div>
    </div>
  </div>

  <div class="login__content">
    <div class="login__content__left">
      <div class="login-img">
        <img src="assets/img/login-img.png" class="login__content__left__img" alt="login img" width="581" height="510">
      </div>
    </div>
    <div class="login__content__right">
      <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <h2>{{title}}</h2>
        <div class="form__group" *ngIf="false">
          <input class="form__control" formControlName="email" type="email" placeholder="Email" required pInputText>
          <!--<span *ngIf="hasError(formFields.email,'required')"
          class="error-msg">{{commonValidationMessages.email.required}}</span>
          <span *ngIf="hasError(formFields.email,'email')"
          class="error-msg">{{commonValidationMessages.email.email}}</span>-->
        </div>
        <div class="form__group">
          <input class="form__control" formControlName="newPassword" type="password" placeholder="New password" required
            pInputText>
          <p>
            [Password must contain minimum 8 characters length, at least one upper case letter, one lower case letter,
            one special character, one numeric character.]
          </p>
          <span *ngIf="hasError(formFields.newPassword, 'required')"
            class="error-msg">{{validationMessages.newPassword.required}}</span>
          <span *ngIf="hasError(formFields.newPassword, 'validStrongPassword')"
            class="error-msg">{{validationMessages.newPassword.invalid}}</span>
        </div>
        <div class="form__group">
          <input class="form__control" formControlName="confirmPassword" type="password" placeholder="Confirm password"
            required pInputText appConfirmEqualValidator='newPassword'>
          <span *ngIf="hasError(formFields.confirmPassword, 'required')"
            class="error-msg">{{validationMessages.confirmPassword.required}}</span>
          <!-- <span *ngIf="hasError(formFields.confirmPassword, 'validStrongPassword')"
          class="error-msg">{{validationMessages.confirmPassword.invalid}}</span> -->
          <span *ngIf="form.hasError('validPasswordMatch')"
            class="error-msg">{{validationMessages.confirmPassword.validPasswordMatch}}</span>
        </div>
        <div class="form__group login-btn">
          <button class="btn btn--primary">Set Password</button>
        </div>
      </form>
    </div>
  </div>
  <div class="login__footer">
    <div class="login__footer__left mobile-support">
      <h4>Appoint360 is best viewed on resolution width 1024 or greater</h4>
      <p><strong>For Windows OS:</strong> Google Chrome 88+, Mozilla Firefox 84+, Microsoft Edge 101+ browsers.</p>
      <p><strong>For Mac OS:</strong> Safari 13.1+, Google Chrome 88+, Mozilla Firefox 84+ browsers.</p>
    </div>
    <!-- <p class="login__footer__left mobile-support">*Application best view in 1280px + resolutions in <strong>Google Chrome, Mozilla Firefox & Microsoft Edge</strong> browsers.</p> -->

    <div class="login__footer__right">
      <span>Powered By<img src="assets/img/appoint360-logo.svg" alt="Appoint360" title="Appoint360" width="20"
            height="13">Appoint360</span>
      </div>
    </div>
  </div>
