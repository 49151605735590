<div class="content-area">
  <div class="title-block">
    <h1>Create User Role & Permission</h1>

    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" routerLinkActive="active" (click)="onBack()"><i
          class="back-new"></i> Back</a>
    </div>

  </div>
  <div class="create-new-role-permission-outer">
    <div class="create-new-role-permission">
      <div class="create-new-role-permission__left">
        <form [formGroup]="form" autocomplete="off">
          <div class="form__group add-permissions">
            <label class="form__label">User Role</label>
            <input formControlName="roleName" type="text" placeholder="User Role" maxlength="30"
              [attr.disabled]="!roleFeatureModelPermission.canRoleDelete ? '' : null" class="form__control"
              [(ngModel)]="roleFeatureModelPermission.appRoleName" pInputText>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.roleName, 'required') || hasError(formFields.roleName, 'whitespace')">{{validationMessages.roleName.required}}</span>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.roleName, 'validCharacters')">{{validationMessages.roleName.validCharacters}}</span>
          </div>

          <div class="form__group all-permissions">
            <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="AllChecked"
              (onChange)="AddRemoveAllPermission($event)" label="All Permission"></p-checkbox>
          </div>
        </form>
      </div>
      <div class="create-new-role-permission__right">
        <button class="btn btn--primary" (click)="onSubmit()">Save User Role & Permission</button>
      </div>
    </div>
  </div>

  <div class="form-content-outer permissions-outer managePermission">
    <div class="permission-list-outer">
      <div class="permission-list-outer__column">
        <div class="permission-block">

          <div class="permission-block__column">
            <div class="permission-block__column-box"
              *ngFor="let moduleFeatureGroup of roleFeatureModelPermission.moduleFeatureGroups; let i = index">

              <h3 *ngIf="rowGroupMetadata[moduleFeatureGroup.appModuleFeatureGroup].index === i">
                <span>{{moduleFeatureGroup.appModuleFeatureGroup}}</span>
                <p-checkbox binary="true" [ngModel]="moduleFeatureGroup.isAllFeatureGroup"
                  (onChange)="AddRemoveFeatureGroupPermission(moduleFeatureGroup.appModuleFeatureGroup,$event)"></p-checkbox>
              </h3>

              <div class="permission-block__list">
                <span>
                  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.5614 6.22179C13.7078 6.07533 13.79 5.87672 13.79 5.66963C13.79 5.46253 13.7078 5.26392 13.5614 5.11746L9.14328 0.699366C9.07124 0.624773 8.98506 0.565275 8.88977 0.524344C8.79449 0.483413 8.692 0.461868 8.5883 0.460967C8.4846 0.460066 8.38176 0.479826 8.28578 0.519096C8.1898 0.558365 8.1026 0.616356 8.02927 0.689686C7.95594 0.763016 7.89795 0.850215 7.85868 0.946197C7.81941 1.04218 7.79965 1.14502 7.80055 1.24872C7.80145 1.35242 7.823 1.4549 7.86393 1.55019C7.90486 1.64547 7.96436 1.73165 8.03895 1.80369L11.1239 4.88863L0.781156 4.88863C0.574023 4.88863 0.375374 4.97091 0.228908 5.11738C0.082443 5.26384 0.000159999 5.46249 0.00015999 5.66962C0.000159981 5.87676 0.082443 6.07541 0.228908 6.22187C0.375374 6.36834 0.574023 6.45062 0.781156 6.45062L11.1239 6.45062L8.03895 9.53556C7.89669 9.68285 7.81797 9.88013 7.81975 10.0849C7.82153 10.2897 7.90366 10.4856 8.04846 10.6304C8.19327 10.7752 8.38915 10.8573 8.59393 10.8591C8.7987 10.8609 8.99598 10.7822 9.14328 10.6399L13.5614 6.22179Z"
                      fill="#919191" />
                  </svg>
                  {{moduleFeatureGroup?.appModuleFeatureName}}
                </span>
                <p-checkbox binary="true" [ngModel]="moduleFeatureGroup.appIsPermission"
                  (onChange)="AddRemoveFromPermission($event,moduleFeatureGroup.appModuleFeatureId,moduleFeatureGroup.appModuleFeatureGroupId)"></p-checkbox>
              </div>
            </div>
          </div>


        </div>

      </div>
      <!-- <div class="permission-list-outer__column">
        <div class="permission-block">
          <div class="permission-block__column">
            <div class="permission-block__column-box">
              <h3>
                <span>Premium Reports (KPI View)</span>
                <p-checkbox binary="true" [ngModel]="featureGroupChecked"
                  (onChange)="AddRemoveKPIGroupPermission($event)"></p-checkbox>
              </h3>

              <div class="permission-block__list" *ngFor="let kpi of kpiReportMasterDetails">
                <span>
                  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.5614 6.22179C13.7078 6.07533 13.79 5.87672 13.79 5.66963C13.79 5.46253 13.7078 5.26392 13.5614 5.11746L9.14328 0.699366C9.07124 0.624773 8.98506 0.565275 8.88977 0.524344C8.79449 0.483413 8.692 0.461868 8.5883 0.460967C8.4846 0.460066 8.38176 0.479826 8.28578 0.519096C8.1898 0.558365 8.1026 0.616356 8.02927 0.689686C7.95594 0.763016 7.89795 0.850215 7.85868 0.946197C7.81941 1.04218 7.79965 1.14502 7.80055 1.24872C7.80145 1.35242 7.823 1.4549 7.86393 1.55019C7.90486 1.64547 7.96436 1.73165 8.03895 1.80369L11.1239 4.88863L0.781156 4.88863C0.574023 4.88863 0.375374 4.97091 0.228908 5.11738C0.082443 5.26384 0.000159999 5.46249 0.00015999 5.66962C0.000159981 5.87676 0.082443 6.07541 0.228908 6.22187C0.375374 6.36834 0.574023 6.45062 0.781156 6.45062L11.1239 6.45062L8.03895 9.53556C7.89669 9.68285 7.81797 9.88013 7.81975 10.0849C7.82153 10.2897 7.90366 10.4856 8.04846 10.6304C8.19327 10.7752 8.38915 10.8573 8.59393 10.8591C8.7987 10.8609 8.99598 10.7822 9.14328 10.6399L13.5614 6.22179Z"
                      fill="#919191" />
                  </svg>
                  {{kpi.kpiReportName}}
                </span>
                <p-checkbox binary="true" [ngModel]="kpi.isKPIPermissionChecked"
                  (onChange)="AddRemoveKPIPermission($event,kpi.kpiReportId)"></p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>



<!-- <div class="permissions-outer__tablet-outer">
      <p-scrollPanel [style]="{width: '100%', height: '100%'}">

        <div class="manage-permission-scroll">
          <table class="custom-table edit-manage-permission">
            <thead>
              <tr>
                <th width="350">Permissions</th>
                <th width="250">
                  <form [formGroup]="form" autocomplete="off">
                    <div class="form__group add-permissions">

                      <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="AllChecked"
                        (onChange)="AddRemoveAllPermission($event)"></p-checkbox>
                      <input formControlName="roleName" type="text" placeholder="User Role" maxlength="30"
                        [attr.disabled]="!roleFeatureModelPermission.canRoleDelete ? '' : null" class="form__control"
                        [(ngModel)]="roleFeatureModelPermission.appRoleName" pInputText>
                      <span class="invalid-feedback"
                        *ngIf="hasError(formFields.roleName, 'required') || hasError(formFields.roleName, 'whitespace')">{{validationMessages.roleName.required}}</span>
                      <span class="invalid-feedback"
                        *ngIf="hasError(formFields.roleName, 'validCharacters')">{{validationMessages.roleName.validCharacters}}</span>
                    </div>
                  </form>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody *ngFor="let moduleFeatureGroup of roleFeatureModelPermission.moduleFeatureGroups; let i = index">

              <tr *ngIf="rowGroupMetadata[moduleFeatureGroup.appModuleFeatureGroup].index === i">
                <td [attr.colspan]="3">
                  <h3>{{moduleFeatureGroup.appModuleFeatureGroup}}</h3>
                </td>
              </tr>
              <tr>
                <td width="350">{{moduleFeatureGroup?.appModuleFeatureName}}</td>
                <td width="200">
                  <p-checkbox binary="true" [ngModel]="moduleFeatureGroup.appIsPermission"
                    (onChange)="AddRemoveFromPermission($event,moduleFeatureGroup.appModuleFeatureId)"></p-checkbox>
                </td>
                <td>&nbsp;</td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="save-permission">
          <div class="btns">
            <button class="btn btn--primary" (click)="onSubmit()">Save</button>
            <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
          </div>
        </div>

      </p-scrollPanel>
    </div> -->