<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>
  </div>
  <div class="dashboard">
    <div class="box">
      <a [routerLink]='["/admin/settings/general"]' class="box__link">
        <img src="assets/img/settings-general-icon.svg" width="40" height="40" alt="general">
        <span class="box__link__text-label">General</span>
      </a>
    </div>
    <div class="box">
      <a [routerLink]='["/admin/settings/application"]' class="box__link">
        <img src="assets/img/settings-application-icon.svg" width="45" height="45" alt="general">
        <span class="box__link__text-label">Application</span>
      </a>
    </div>
    <div class="box">
      <a [routerLink]='["/admin/settings/plugins"]' class="box__link">
        <img src="assets/img/settings-plugins-icon.svg" width="40" height="40" alt="general">
        <span class="box__link__text-label">Plugins</span>
      </a>
    </div>
    <div class="box">
      <a [routerLink]='["/admin/settings/brightree"]' class="box__link">
        <img src="assets/img/settings-brightree-icon.svg" width="40" height="40" alt="general">
        <span class="box__link__text-label">Brightree</span>
      </a>
    </div>
    <div class="box">
      <a [routerLink]='["/admin/settings/notifications"]' class="box__link">
        <img src="assets/img/settings-notifications-icon.svg" width="40" height="40" alt="general">
        <span class="box__link__text-label">Templates</span>
        <!-- <span class="box__link__text-label">Notifications</span> -->
      </a>
    </div>
    <!-- *ngIf="isBoardingDisplay" -->
    <div class="box" *ngIf="isBoardingDisplay">
      <a [routerLink]='["/admin/settings/onboarding"]' class="box__link">
        <img src="assets/img/settings-notifications-icon.svg" width="40" height="40" alt="general">
        <span class="box__link__text-label">On Boarding</span>
      </a>
    </div>
  </div>
</div>
