import { Time } from '@angular/common';

export class TherapistCustomAvailabilityResponseModel {
  crmTherpistCustomAvailabilityId: number;
  customId: number;
  crmIsRecurring: boolean;
  crmRecurringFrequency: number;
  crmRecurringType: number;
  crmIsRoadShow: boolean;
  crmTherapistId: number;
  crmIsOnLeave: boolean;
  crmIsHoliday: boolean;
  crmCustomDays: string;
  crmIsNotAvailable: boolean;
  crmTherapistCustomStartDate: Date;
  crmTherapistCustomEndDate: Date;
  crmTherapistCustomStartDateString: string;
  crmTherapistCustomEndDateString: string;
  crmTherapistCustomStartTime: Date;
  crmTherapistCustomEndTime: Date;
  crmTherapistCustomStartTimeString: string;
  crmTherapistCustomEndTimeString: string;
  crmTherapistCustomStartTimeDate: Date;
  crmTherapistCustomEndTimeDate: Date;
  modifiedByUserInfoId: number;
  crmFacilityId: number;
  isFullDay: boolean;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBreakStartTime: Time;
  crmBreakEndTime: Time;
  crmBreakStartTimeDate: Date;
  crmBreakEndTimeDate: Date;
  crmBreakStartTimeString: string;
  crmBreakEndTimeString: string;
  isActive: boolean;
  isRemoteAssistant: boolean;
  isBlockSlot: boolean;
  crmDescription: string;
  uuid: number;
  crmFacilityName: string;
  eventName: string;
  dayOfWeek: string;
  crmTherapistName: string;
  crmIsExactDay: boolean;
  crmExactday: number;
  crmExactMonth: number;
  monPattern: number;
  yearFrequcncy: number;
  exactDay: number;
  isOffice: boolean;
  isRemoteAndFacility: boolean;
  crmRemoteFacilityId: number;
  isStartFromDefaultFacility: boolean;
  isStartFromHomeAddress: boolean;
  selectedFacilityTimeZone: string;
  isBreakHoursUpdate: boolean = false;
  isBookedFromCalendar: boolean = false;
  isAddManageAvailabilityChanged: boolean = false;
  isAddBlockAvailabilityChanged: boolean = false;
  availablityType: string;
  isSeries: boolean = false;
  oldCrmTherpistCustomAvailabilityId: any[]
  isOverride: boolean = false;
  appointmentDates: customAppointmentDetails[];
  blockSlotDates: customBlockDetails[];
  customDates: customDateDetails[];
  isAppointmentWithRoadHome: boolean
  customStartTime: Time;
  customEndTime: Time;
  customStartTimeString: string;
  customEndTimeString: string;
  isCustom: boolean;
  operationType: string;
  isMergeWithExisting: boolean;
  therapistBlockAvailId: number;
  enUserInfoId:string
}

export class TherapistCustomAvailabilityResponseModelForMergeMessage {
  previousAvailabilityReponses: PreviousAvailabilityReponse[];
  currentAvailabilityReponses: CurrentAvailabilityResponse;
  appointmentDetail: AppointmentDetailResponse;
  isAllReccuringDatesMatched: boolean;
  isBreak: boolean;
  errorCode: number;
  message: string;

}

export class CurrentAvailabilityResponse {
  appointmentType: string;
  startDate: Date;
  endDate: Date;
  dateRange: string;
  time: string;
  startTime: string;
  endTime: string;
  selectedDays: string;
  isRecurring: boolean;
  recurringType: number;
  recurringTypeMessage: string;
}

export class PreviousAvailabilityReponse {
  appointmentType: string;
  startDate: Date;
  endDate: Date;
  dateRange: string;
  time: string;
  startTime: string;
  endTime: string;
  selectedDays: string;
  isRecurring: boolean;
  recurringType: number;
  recurringTypeMessage: string;
}

export class AppointmentDetailResponse {
  // appointmentCount: number;
  // appointmentDate: string[];
  // blockSlotCount: number;
  // blockSlotDate: string[];
  // customAvailability: number;
  // customAvailiblityDate: string[]
  customAppointmentDetails: customAppointmentDetails[];
  customBlockDetails: customBlockDetails[];
  customDateDetails: customDateDetails[];
}

export class customDateDetails {
  customCount: number;
  customDate: Date;
  customAvailiblityId: number;
}
export class customBlockDetails {
  blockSlotCount: number;
  blockSlotDate: Date;
  customAvailiblityId: number;
  customBlockId: number;
}
export class customAppointmentDetails {
  appointmentCount: number;
  appointmentDate: Date;
  appointmentId: number;
}

export class MainSeriesData {
  mainAppointmentType: string;
  mainDateRange: string;
  mainTime: string;
  mainBreakhours: string;
  currentSeriesDateRange: string;
}