<div class="plugin-outer">
  <div class="form-outer">
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Service</label>
          <p-dropdown class="select-box" formControlName="service" [options]="pluginServices" optionLabel="pluginName"
                      [showClear]="true">
          </p-dropdown>
          <span class="invalid-feedback"
                *ngIf="hasError(formFields.service, 'required') || hasError(formFields.service, 'whitespace')">{{validationMessages.service.required}}</span>
        </div>
        <!--<div class="form__group">
          <label class="form__label">Connection Name</label>
          <input type="text" formControlName="connectionName" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.connectionName, 'required') || hasError(formFields.connectionName, 'whitespace')">{{validationMessages.connectionName.required}}</span>
        </div>-->
        <div class="form__group">
          <label class="form__label">Team ID</label>
          <input type="text" formControlName="teamId" class="form__control" pInputText>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.teamId, 'required') || hasError(formFields.teamId, 'whitespace')">{{validationMessages.teamId.required}}</span>
          <small>[Located on the API Setting tab]</small>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Secret</label>
          <input type="text" formControlName="secret" class="form__control" pInputText>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.secret, 'required') || hasError(formFields.secret, 'whitespace')">{{validationMessages.secret.required}}</span>
          <small>[Located on the API Setting tab]</small>
        </div>
        <div class="form__group">
          <label class="form__label">Sender Number</label>
          <input type="text" formControlName="phoneNumber" class="form__control" pInputText>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.phoneNumber, 'required') || hasError(formFields.phoneNumber, 'whitespace')">{{validationMessages.phoneNumber.required}}</span>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.phoneNumber, 'validPhoneNumber')">{{validationMessages.phoneNumber.validPhoneNumber}}</span>
          <small>[The sender's phone number in E.164 format]</small>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Brand Id</label>
          <input type="text" formControlName="brandId" class="form__control" pInputText>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.brandId, 'required') || hasError(formFields.brandId, 'whitespace')">{{validationMessages.brandId.required}}</span>
          <small>[Located in your account's trust centre tab]</small>
        </div>
        <div class="form__group">
          <label class="form__label">Recording ID</label>
          <input type="text" formControlName="recordingId" class="form__control" pInputText>
          <span class="invalid-feedback-error"
                *ngIf="hasError(formFields.recordingId, 'required') || hasError(formFields.recordingId, 'whitespace')">{{validationMessages.recordingId.required}}</span>
          <small>[Located on in your account's recordings tab.]</small>
        </div>

      </div>
      <div class="form__group">
        <label class="form__label">Audio URL</label>
        <input type="text" formControlName="audioUrl" class="form__control" pInputText>
        <span class="invalid-feedback-error"
              *ngIf="hasError(formFields.audioUrl, 'required') || hasError(formFields.audioUrl, 'whitespace')">{{validationMessages.audioUrl.required}}</span>
        <span class="invalid-feedback-error"
              *ngIf="hasError(formFields.audioUrl, 'validURL')">{{validationMessages.audioUrl.validURL}}</span>
        <small>
          A url to the voicemail audio to play (mp3 or wav)<br>
          Example: https://example.com/your-file.mp3
        </small>
      </div>

      <div class="form__group">
        <label class="form__label">Audio Type</label>
        <div class="radios">
          <p-radioButton formControlName="audioType" value="mp3" label="MP3">
          </p-radioButton>
          <p-radioButton formControlName="audioType" value="wav" label="Wav">
          </p-radioButton>
        </div>
      </div>




      <div class="btns">
        <button class="btn btn--primary mb-0" routerLink="/admin/settings/plugins">Save</button>
        <button class="btn btn--secondary mb-0" *ngIf="false">Test Voice Mail</button>
      </div>
    </form>
    <div class="test-sms-btn">
      <button class="btn btn--secondary mb-0" (click)="onClickTest()">Test Voice Email</button>
    </div>

    <div *ngIf="isTestSMS" class="form__group">
      <br>
      <div class="form__group test-sms">
        <label class="form__label">Phone Number</label>
        <input type="text" class="form__control" pInputText [(ngModel)]="testNumber">
      </div>
      <div class="btns">
        <button class="btn btn--primary mb-0" (click)="SendVoicMail()">Send Voicemail</button>
        <button class="btn btn--secondary mb-0" (click)="CancelVoiceEmail()">Cancel</button>
      </div>
    </div>


  </div>

</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
