import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { Subject } from 'rxjs';
import { SettingsService } from '../../servicies/settings.service';
import { SettingsBrightreeResponseModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { WipStatus } from 'src/app/shared/models/common/wip-status';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Dropdown } from 'primeng/dropdown';


@Component({
  selector: 'app-brightree-new',
  templateUrl: './brightree-new.component.html',
  styleUrls: ['./brightree-new.component.sass'],
  providers: [DialogService]
})
export class BrightreeNewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('eMedAssistDropdown') eMedAssistDropdown: Dropdown;
  @ViewChild('brightreeDropdown') brightreeDropdown: Dropdown;
  title = 'Brightree Settings';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  loggedInUserId: number;
  switchActiveInactive: boolean;
  statusInformation: boolean;
  statusBtnTitle: string = "Update";
  statusEditMode: boolean;
  formStatusSubmitted: boolean = false;
  isUpdateToBT: Boolean = false;
  wipStatus: WipStatus[];
  wipAssignedToOptions: WIPStatesResponseModel[];
  eMedAssistStatusOptions: WIPStatesResponseModel[];
  brightreeStatusOptions: WIPStatesResponseModel[];
  selectedWipStatusModel: WipStatus;
  selectedWipAssignedTo: WIPStatesResponseModel;
  selectedEMedAssistStatus: WIPStatesResponseModel;
  selectedBrightreeStatus: WIPStatesResponseModel;
  displayWipStats: boolean;
  isProvisional: boolean;
  PODOptions:{ name: string; code: number; }[];
  SelectedPODOption:{ name: string; code: number; };
  isDisplayPODStatus:boolean=false;

  formFields = {
    eMedAssistStatus: 'eMedAssistStatus',
    brightreeStatus: 'brightreeStatus',
    displayName: 'displayName',
    wipAssignedToKey: 'wipAssignedToKey',
    podStatus: 'podStatus',
  };

  validationMessages = {
    eMedAssistStatus: {
      required: 'Appoint360 Status is required',
      // validCharacters: 'Enter valid eMedAssist Status'
    },
    brightreeStatus: {
      required: 'Brightree Status is required',
      // validCharacters: 'Enter valid brightree status'
    },
    displayName: {
      required: 'Display name is required',
      validCharacters: 'Enter valid display name'
    },
    wipAssignedToKey: {
      required: 'WIP Assigned To is required',
      // validCharacters: 'Enter valid brightree status'
    },
  };
  eMedAssistBrightree: boolean;
  index: number = 0;

  constructor(
    private dialogueService: DialogService,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    messageService: MessageService) {
    super(messageService);

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
    this.loggedInUserId = loggedInUser.userId;
  }

  handleChange(e: any) {
    this.index = e.index;
  }


  get fStatus() { return (<FormGroup>this.form.get('formStatus')).controls; }



  get formStatus() {
    return this.form.get('formStatus');
  }


  ngOnInit() {
    this.getBrightreeSettings();
    this.getAssignedToPerson();
    this.getWipStatusSA();
    this.getWipStatusBT();

    this.PODOptions = [
      { name: 'Cancelled', code: 1 },
      { name: 'Closed', code: 2 },
      { name: 'Exception', code: 3 },
      { name: 'Fullfilled', code: 4 },
      { name: 'Modified', code: 5 },
      { name: 'New', code: 6 },
      { name: 'None', code: 7 },
      { name: 'Open', code: 8 },
      { name: 'OverFullfilled', code: 9 },
      { name: 'PartiallyFullfilled', code: 10 },
      { name: 'Scheduled', code: 11 },
      { name: 'TransferredIn', code: 12 },
      { name: 'TransferredOut', code: 13 },
      { name: 'Voided', code: 14 },
    ];

    this.buildForm();


  }


  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }


  onUpdateToBTCheck(status) {
    this.isUpdateToBT = status.checked;
  }


  onStatusAdd() {
    this.formStatusSubmitted = true;
    if (this.formStatus.invalid) {
      this.markFormAsTouched();
      return;
    }

    const wipstatusModel = this.getValuesFromStatusForm();

    const wipstatus = this.wipStatus;
    if (wipstatus.length > 1) {
      if (this.statusEditMode) {
        // let currentStatus = wipstatus.find(x =>
        //   x.crmWipstatusNameBt === wipstatusModel.crmWipstatusNameBt
        //   && x.crmWipstatusNameSa === wipstatusModel.crmWipstatusNameSa
        //   && x.crmWipstatusId !== this.selectedWipStatusModel.crmWipstatusId);
        let currentStatus = wipstatus.find(x => x.crmWipstatusNameSa === wipstatusModel.crmWipstatusNameSa
          && x.crmWipstatusId !== this.selectedWipStatusModel.crmWipstatusId);
        if (currentStatus) {
          this.showErrorMessage("Status mapping for " + wipstatusModel.crmWipstatusNameSa + ' already exists.');
          return;
        }
      } else {
        // let currentStatus = wipstatus.find(x =>
        //   x.crmWipstatusNameBt === wipstatusModel.crmWipstatusNameBt
        //   && x.crmWipstatusNameSa === wipstatusModel.crmWipstatusNameSa);
        let currentStatus = wipstatus.find(x => x.crmWipstatusNameSa === wipstatusModel.crmWipstatusNameSa);
        if (currentStatus) {
          this.showErrorMessage("Status mapping for " + wipstatusModel.crmWipstatusNameSa + ' already exists.');
          return;
        }
      }
    }

    let manageStatus = this.settingsService.addNewWipStatus(wipstatusModel);
    if (this.statusEditMode) {
      wipstatusModel.crmWipstatusId = this.selectedWipStatusModel.crmWipstatusId;
      manageStatus = this.settingsService.updateWipStatus(wipstatusModel);
    }

    this.loading = true;

    manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.statusEditMode ? 'Status information has been updated successfully.'
            : 'Status information has been added successfully.');
          this.selectedWipStatusModel = new WipStatus();
          this.statusEditMode = false;
          this.resetValuesToStatusForm();
          // this.getUser(this.userInfoId);
          this.getBrightreeSettings();
          // this.getWipStatusSA();
          // this.getWipStatusBT();
          this.resetStatusDropdowns();
          this.statusInformation = !this.statusInformation;
          this.eMedAssistBrightree = !this.eMedAssistBrightree;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
    this.displayWipStats = false;

  }

  onStatusEdit(wipStatus: WipStatus) {
    // this.eMedAssistBrightree = this.eMedAssistBrightree;
    this.eMedAssistBrightree = true;
    this.statusBtnTitle = "Update";
    this.statusInformation = true;
    this.statusEditMode = true;
    this.formStatus.reset();
    this.selectedWipStatusModel = wipStatus;
    this.setValuesToStatusForm();
    this.displayWipStats = true;

  }

  onStatusDelete(wipStatus: WipStatus) {
    this.resetValuesToStatusForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete 'Status ${wipStatus.crmWipstatusNameSa}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      //const index = this.licenseResponseModel.indexOf(license, 0);
      //if (index > -1) {
      //  this.licenseResponseModel.splice(index, 1);
      //}
      this.loading = true;
      wipStatus.isActive = false;
      const manageStatus = this.settingsService.activeInactiveWipStatus(wipStatus);
      manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Status information has been deleted successfully.');
            this.getBrightreeSettings();
            this.resetStatusDropdowns();
          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  onStatusCancel() {
    this.resetValuesToStatusForm();
    this.resetStatusDropdowns();
    this.statusInformation = !this.statusInformation;
    this.eMedAssistBrightree = !this.eMedAssistBrightree;
    this.displayWipStats = !this.displayWipStats;
  }

  async resetStatusDropdowns() {
    this.eMedAssistStatusOptions = new Array<WIPStatesResponseModel>();
    await this.getWipStatusSA();

    //this.brightreeStatusOptions = new Array<WIPStatesResponseModel>();
    //await this.getWipStatusBT();
  }

  StatusInfoButton() {
    this.statusBtnTitle = "Add";
    this.statusInformation = true;
    this.resetValuesToStatusForm();

    this.displayWipStats = !this.displayWipStats;
    this.eMedAssistBrightree = !this.eMedAssistBrightree;
  }

  private getValuesFromStatusForm(): WipStatus {
    const form = this.formStatus;
    const wipstatus = new WipStatus();
    // wipstatus.crmWipstatusNameSa = form.get(this.formFields.eMedAssistStatus).value;
    // wipstatus.crmWipstatusNameBt = form.get(this.formFields.brightreeStatus).value;
    wipstatus.crmWipstatusNameSa = this.selectedEMedAssistStatus.wipStatesName;
    wipstatus.crmWipstatusNameBt = this.selectedBrightreeStatus.wipStatesName;
    wipstatus.appAlias = form.get(this.formFields.displayName).value;
    wipstatus.crmIsUpdateToBrigthree = this.isUpdateToBT;
    wipstatus.crmWIPAssignedToKey = this.selectedWipAssignedTo ? Number(this.selectedWipAssignedTo.wipStatesId) : 0;
    wipstatus.modifiedByUserInfoId = this.loggedInUserId;
    //wipstatus.crmPODStatus = this.SelectedPODOption.name;
    return wipstatus;
  }

  private async setValuesToStatusForm() {
    if (!this.selectedWipStatusModel) {
      return;
    }

    const form = this.formStatus;
    this.selectedEMedAssistStatus = { wipStatesId: this.selectedWipStatusModel.crmWipstatusId, wipStatesName: this.selectedWipStatusModel.crmWipstatusNameSa };

    this.selectedBrightreeStatus = { wipStatesId: this.selectedWipStatusModel.crmWipstatusId, wipStatesName: this.selectedWipStatusModel.crmWipstatusNameBt };
    this.selectedWipAssignedTo = this.wipAssignedToOptions.find(x => x.wipStatesId === this.selectedWipStatusModel.crmWIPAssignedToKey);
    this.isUpdateToBT = this.selectedWipStatusModel.crmIsUpdateToBrigthree;
    this.eMedAssistStatusOptions.push(this.selectedEMedAssistStatus);
    this.brightreeStatusOptions.push(this.selectedBrightreeStatus);
    this.eMedAssistDropdown.options = this.eMedAssistStatusOptions;
    this.brightreeDropdown.options = this.brightreeStatusOptions;
    this.SelectedPODOption = this.PODOptions.find(x => x.name === this.selectedWipStatusModel.crmPODStatus);

    form.get(this.formFields.displayName).setValue(this.selectedWipStatusModel.appAlias);
  }

  resetValuesToStatusForm() {
    const form = this.formStatus;
    form.get(this.formFields.eMedAssistStatus).setValue('');
    form.get(this.formFields.brightreeStatus).setValue('');
    form.get(this.formFields.displayName).setValue('');
    this.statusEditMode = false;
    this.isUpdateToBT = false;
    form.get(this.formFields.wipAssignedToKey).setValue('');
    form.get(this.formFields.podStatus).setValue('');
    this.formStatusSubmitted = false;
  }



  private async getAssignedToPerson() {
    this.loading = true;

    await this.settingsService.getAssignedToPerson()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {

          this.wipAssignedToOptions = result.responseModel;

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getBrightreeSettings() {

    this.loading = true;

    this.settingsService.GetAllWipStatus()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatus = result.responseModel;
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getWipStatusSA() {
    this.loading = true;

    await this.settingsService.getWipStatusSA()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {

          this.eMedAssistStatusOptions = result.responseModel;
          // this.setValuesToForm();

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getWipStatusBT() {
    this.loading = true;

    await this.settingsService.getWipStatusBT()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<WIPStatesResponseModel[]>) => {
        this.processResult<any>(result, () => {

          this.brightreeStatusOptions = result.responseModel;

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }



  private buildForm() {
    const formStatusGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formStatusGroup.addControl(this.formFields.eMedAssistStatus, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // tslint:disable-next-line: max-line-length
    formStatusGroup.addControl(this.formFields.brightreeStatus, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    // tslint:disable-next-line: max-line-length
    formStatusGroup.addControl(this.formFields.displayName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    formStatusGroup.addControl(this.formFields.wipAssignedToKey, this.formBuilder.control('',
      {
        validators: [],
      }));
    formStatusGroup.addControl(this.formFields.podStatus, this.formBuilder.control(''));
    this.form = this.formBuilder.group({
      formStatus: formStatusGroup,
    });
  }

}
