<div class="notification-outer">
  
  <div class="notifaction-templates">

    <div class="notifaction-templates__block" [ngClass]="groupName.isGroupCollapse ? '' : 'add-border'" *ngFor="let groupName of settingsGroupNotificationListResponseModel;let i = index">
      <div class="notifaction-templates__header" (click)="ReminderclickEvent(groupName)">
        <h3 class="notifaction-templates__title">{{groupName.appModuleDisplayFeatureName}}</h3>
        <a class="notifaction-templates__arrow" href="javascript:void(0);" [ngClass]="groupName.isGroupCollapse ? 'uparrow' : ''">
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.0155 8.3053L7.05413 3.2406L2.09279 8.3053L0.108259 7.29236L7.05413 0.201782L14 7.29236L12.0155 8.3053Z"
              fill="#575757" />
          </svg>
        </a>
      </div>
      <div class="notifaction-templates__content" [ngClass]="groupName.isGroupCollapse ? 'hideblock' : 'showblock'">
        <div class="notifaction-templates__template-header">
          <h3 class="notifaction-templates__template-header-title">Templates</h3>
          <div class="notifaction-templates__template-right">
            <div class="notifaction-templates__email-checkbox">
              <p-checkbox name="groupEmail" binary="true" label="Email" [(ngModel)]="groupName.isEmailSelectAllCheck"
                (click)="emailGroupCheckClick($event, settingsGroupNotificationListResponseModel[i])"></p-checkbox>
              <!-- <h3 class="notifaction-templates__template-header-title">Email</h3> -->
            </div>
            <div class="notifaction-templates__sms-checkbox">
              <p-checkbox name="groupSMS" binary="true" label="SMS" [(ngModel)]="groupName.isSMSSelectAllCheck"
                (click)="smsGroupCheckClick($event, settingsGroupNotificationListResponseModel[i])"></p-checkbox>
              <!-- <h3 class="notifaction-templates__template-header-title">SMS</h3> -->
            </div>
          </div>
        </div>

        <div class="notifaction-templates__template-body" *ngFor="let subgrpName of settingsGroupNotificationListResponseModel[i].notificationTemplateList">

          <h3 class="notifaction-templates__template-header-title">{{subgrpName?.appNotificationTemplateName}}</h3>
          <div class="notifaction-templates__template-right">
            <div class="notifaction-templates__email-checkbox">
              <p-checkbox name="groupEmail" binary="true" inputId="email"
                [(ngModel)]="subgrpName.appNotificationInEmail"
                (click)="emailCheckClick($event,subgrpName)" [disabled]="!DisaplyViewButton(subgrpName,'Email')"></p-checkbox>
              <a href="javascript:void(0);" class="notifaction-templates__view-icon"
                (click)="viewEmail($event,subgrpName)" [ngClass]="!DisaplyViewButton(subgrpName,'Email')?'disable':''">
                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.0627 7.43831C13.0627 8.96586 11.8244 10.2041 10.2969 10.2041C8.76936 10.2041 7.53107 8.96586 7.53107 7.43831C7.53107 5.91075 8.76936 4.6725 10.2969 4.6725C11.8244 4.6725 13.0627 5.91075 13.0627 7.43831Z"
                    stroke="#626262" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M1.5 7.43829C2.6748 3.69789 6.1692 0.984741 10.2973 0.984741C14.4254 0.984741 17.9198 3.69792 19.0946 7.43829C17.9198 11.1787 14.4254 13.8918 10.2973 13.8918C6.16919 13.8918 2.67478 11.1787 1.5 7.43829Z"
                    stroke="#626262" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <a href="javascript:void(0);" class="notifaction-templates__edit-icon"
                (click)="emailToggel($event,subgrpName.appNotificationEventName,subgrpName)">
                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                    <path id="Path_3" data-name="Path 3"
                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                      transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                    <path id="Path_4" data-name="Path 4"
                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                  </g>
                </svg>
              </a>
            </div>
            <div class="notifaction-templates__sms-checkbox">
              <p-checkbox name="groupEmail" binary="true" inputId="sms" [(ngModel)]="subgrpName.appNotificationInSms"
                (click)="smsCheckClick($event, subgrpName)" [disabled]="!DisaplyViewButton(subgrpName,'SMS')"></p-checkbox>
              <a href="javascript:void(0);" class="notifaction-templates__view-icon"
                (click)="viewSMS($event,subgrpName)" [ngClass]="!DisaplyViewButton(subgrpName,'SMS')?'disable':''">
                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.0627 7.43831C13.0627 8.96586 11.8244 10.2041 10.2969 10.2041C8.76936 10.2041 7.53107 8.96586 7.53107 7.43831C7.53107 5.91075 8.76936 4.6725 10.2969 4.6725C11.8244 4.6725 13.0627 5.91075 13.0627 7.43831Z"
                    stroke="#626262" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path
                    d="M1.5 7.43829C2.6748 3.69789 6.1692 0.984741 10.2973 0.984741C14.4254 0.984741 17.9198 3.69792 19.0946 7.43829C17.9198 11.1787 14.4254 13.8918 10.2973 13.8918C6.16919 13.8918 2.67478 11.1787 1.5 7.43829Z"
                    stroke="#626262" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </a>
              <a href="javascript:void(0);" class="notifaction-templates__edit-icon"
                (click)="smsToggle($event,subgrpName.appNotificationEventName,subgrpName)">
                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                    <path id="Path_3" data-name="Path 3"
                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                      transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                    <path id="Path_4" data-name="Path 4"
                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                  </g>
                </svg>
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="full-table full-table--no-margin" *ngIf="false">
    <div class="lead-time">
      <p class="lead-time__title">
        <strong>Reminder Lead Time</strong><br>
        <span>Override if customer has opted-out</span>
      </p>
      <!--<div class="lead-time__dropdown spinner">
        <p-spinner class="spinner spinner--facility" placeholder="9:00 AM" [step]="1.00" decimalSeparator="."></p-spinner>
      </div>-->
      <div class="lead-time__dropdown">
        <p-dropdown optionValue="value" optionLabel="title" class="select-box" [options]="sAReminderLeadTimeOptions"
          [(ngModel)]="SAReminderLeadTime" [showClear]="true" appendTo="body">
        </p-dropdown>
      </div>
      <span class="lead-time__prior">Prior to each appointment.</span>
    </div>
  </div>
  <div class="full-table full-table--last" *ngIf="false">
    <div class="lead-time">
      <p class="lead-time__title">
        <strong>Reminder Lead Time</strong><br>
        <span>Override if customer has opted-out</span>
      </p>
      <div class="lead-time__dropdown">
        <p-dropdown optionValue="value" optionLabel="title" class="select-box" [options]="nSAReminderLeadTimeOptions"
          [(ngModel)]="NSAReminderLeadTime" [showClear]="true" appendTo="body"> 
        </p-dropdown>
      </div>
      <!--<div class="lead-time__dropdown spinner">
        <p-spinner class="spinner spinner--facility" Placeholder="0" [min]="00" [max]="23" [step]="1.00" decimalSeparator="."></p-spinner>
      </div>-->
    </div>
  </div>
</div>

<!-- NEW View Email Template -->
<p-dialog header="Title" [(visible)]="viewEmailDialog" [modal]="true">
  <div class="popup-content-outer view-email">

    <div class="view-email__header">
      <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" height="65" title="{{imageTitle}}">
    </div>
    <div class="view-email__content">
      <h1>{{emailTitle}}</h1>
      <h3>{{subject}}</h3>
      <div [innerHtml]="emailTextArea" sty></div>
    </div>
  </div>
</p-dialog>

<!-- NEW View SMS Template -->
<p-dialog header="Title" [(visible)]="viewSMSDialog" [modal]="true">
  <div class="popup-content-outer view-email">

    <div class="view-email__header">
      <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" height="65" title="{{imageTitle}}">
    </div>
    <div class="view-email__content">
      <h1>{{smsTitle}}</h1>
      <div [innerHtml]="smsTextArea"></div>
    </div>
  </div>
</p-dialog>

<!-- EMAIL Template Popup -->
<p-dialog appendTo="body" [(visible)]="editEmailDialog" styleClass="overly-noti"
  class="search-popup notificationPop notification-settings-email-overly therapist" [modal]="true">
  <div class="notification-overlay-popup">
    <h3 class="notification-overlay-popup__title">{{emailTitle}}</h3>
    <form class="form" [formGroup]="formEmail" autocomplete="off">
      <div class="form__group">
        <label class="form__label">Subject</label>
        <input type="text" class="form__control" formControlName="subject" pInputText maxlength="100">

        <div *ngIf="formEmailSubmitted && fEmail.subject.errors" class="invalid-feedback-error">
          <span *ngIf="fEmail.subject.errors.required || fEmail.subject.errors.whitespace">
            {{validationMessages.subject.required}}</span>
        </div>

      </div>
      <div class="form__group">
        <label class="form__label">Email Message</label>
        <!--<p-editor formControlName="emailTextArea" [style]="{'height':'250px'}" #textEditor></p-editor>
  <div *ngIf="formEmailSubmitted && fEmail.emailTextArea.errors" class="invalid-feedback-error">
    <span *ngIf="fEmail.emailTextArea.errors.required || fEmail.emailTextArea.errors.whitespace"> {{validationMessages.emailTextArea.required}}</span>
  </div>-->
        <ckeditor [(ngModel)]="editor.emailText" #editorText [editor]="Editor" [ngModelOptions]="{standalone: true}"
          [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }"></ckeditor>
        <div *ngIf="formEmailSubmitted && emailTextValidation" class="invalid-feedback-error">
          <span>Email message is required</span>
        </div>
      </div>
      <div class="form__group">
        <label class="form__label">Tags</label>
        <p-dropdown optionValue="value" optionLabel="displayName" class="select-box" placeholder="Select Tags"
          [options]="enumEmailModel" (onChange)="onChange($event)" appendTo="body">
        </p-dropdown>
      </div>

      <div class="btns">
        <button class="btn btn--primary" (click)="OnEmailTemplateSave($event)">Save</button>
        <button class="btn btn--secondary" (click)="emailCancel()">Cancel</button>
        <!-- <button class="btn btn--secondary" (click)="emailReset()">Reset</button> -->
      </div>
    </form>
  </div>
</p-dialog>


<p-dialog appendTo="body" [(visible)]="editSMSDialog" styleClass="overly-sms"
  class="search-popup notificationPop notification-settings-sms-overly therapist" [modal]="true">
  <div class="notification-overlay-popup">
    <h3 class="notification-overlay-popup__title">{{smsTitle}}</h3>
    <form class="form" [formGroup]="formSMS" autocomplete="off">

      <div class="form__group">
        <label class="form__label">SMS Message</label>
        <textarea class="form__textarea" #editorTextSMS formControlName="smsTextArea" [(ngModel)]="smsTextArea"
          pInputTextarea #box (keyup)="0"></textarea>
        <div *ngIf="formSMSSubmitted && fSMS.smsTextArea.errors" class="invalid-feedback-error">
          <span *ngIf="fSMS.smsTextArea.errors.required || fEmail.smsTextArea.errors.whitespace">
            {{validationMessages.smsTextArea.required}}</span>
        </div>
        <!--<p class="character-text">{{250 - box.value?.length}} characters are left...</p>-->
      </div>


      <div class="form__group">
        <label class="form__label">Tags</label>
        <p-dropdown optionValue="value" optionLabel="displayName" class="select-box" placeholder="Select Tags"
          [options]="enumSMSModel" (onChange)="onChangeSMS($event)" appendTo="body"> 
        </p-dropdown>
      </div>

      <div class="btns">
        <button class="btn btn--primary" (click)="OnSMSTemplateSave($event)">Save</button>
        <button class="btn btn--secondary" (click)="smsCancel()">Cancel</button>
        <!-- <button class="btn btn--secondary" (click)="smsReset()">Reset</button> -->
      </div>
    </form>
  </div>
</p-dialog>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                    animationDuration=".8s">
  </p-progressSpinner>
</div>