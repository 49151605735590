export class TherapistContactInfoResponseModel {
  therapistContactInfoId: number;
  crmTherapistId: number;
  crmBioData: string;
  crmAddress: string;
  crmCity: string;
  crmState: string;
  crmZipCode: string;
  crmPractitionerId: number;
  crmDeliveryTechnicianId: number;
  crmAddressLat: string;
  crmAddressLong: string;
  crmProfileImage: string;
  isActive: string;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmProfileImageFormat: string;
  crmCompanyName: string;
  crmProfileImageSrc: string;
}

export class TherapistInfoModel {
  appUserInfoId: number;
  appUserEmail: string;
  appUserFname: string;
  appUserLname: string;
  appUserPhoneNumber: string;
  appUserpassword: string;
  appUserIsReset: boolean;
  appUserStatus: boolean;
  appUserPasswordExpiry: Date;
  dateTimeUserLastLogin: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBrightreeUserId: number;
  crmDeliveryTechnicianId: number;
  enAppUserInfoId: string;
  therapistContactInfoModel: TherapistContactInfoResponseModel;
}
