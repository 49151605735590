import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientSideBarInfo, TherapistAppointmentsForClinicRemoteResponseModel, TherapistAppointmentsForRoadHomeResponseModel } from 'src/app/shared/models/response/appointment/therapist-appointment-by-appointment_type';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TheraistAppointmentService } from '../services/theraistappointment.service';

@Component({
  selector: 'app-patient-detail-sidebar',
  templateUrl: './patient-detail-sidebar.component.html',
  styleUrls: ['./patient-detail-sidebar.component.sass']
})

export class PatientDetailSidebarComponent extends BaseComponent implements OnInit, OnChanges {
  AppointmentStatus: any;
  salesOrderType: string = 'AppointmentRequest';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  salesOrder: SalesOrderInfoResponseModel;
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  therapistClinicResult: TherapistAppointmentsForClinicRemoteResponseModel;
  therapistRoadHomeResult: TherapistAppointmentsForRoadHomeResponseModel;
  schWIPStatus: WIPStatesResponseModel;
  wipStatusModel: WIPStatesResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  @Input() patientDetail: PatientSideBarInfo;
  @Input() salesOrderid: number;
  patientHeaderDetail: PatientSideBarInfo;
  appDateFormat: DateFormatModel;
  appointmentNote: string;
  canUpdateStatue: boolean;
  isStatusUpdated: boolean = false;
  isStatusUpdatedinDB: boolean = false;
  displayStatus: string;
  AppointmentStatusUpdate: boolean;
  @Output() wipsStatusCreated = new EventEmitter<{ appWipStatus: string, schAppointmentId: number }>();
  isBrightreeSystemAppUpdate: Boolean = true;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  therapistId: number;
  therapistName: string;
  facilityTimeZone: string = '';
  isFutureAppt: boolean = false;

  //41466
  timeZoneList: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string }[];
  selectedPatientTimeZone: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string };

  constructor(private appointmentService: AppointmentService,
    messageService: MessageService,
    private theraistAppointmentService: TheraistAppointmentService,
    private webStorageService: WebStorageService,
    private featureGroupService: FeatureGroupService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit() {
    this.timeZoneList = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST', orgcode: 'UTC+5', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST', orgcode: 'UTC-5', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST', orgcode: 'UTC-6', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MST', orgcode: 'UTC-7', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST', orgcode: 'UTC-8', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];
    this.schWIPStatus = new WIPStatesResponseModel();
    this.patientHeaderDetail = new PatientSideBarInfo();

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.therapistName = currentUserToken.userFullName;
      this.facilityTimeZone = currentUserToken.crmTimeZone;
    }
    else {
      this.therapistId = 0;
      this.therapistName = '';
      this.facilityTimeZone = 'EST';
    }

    this.getSalesOrderStatus();
    this.getWipStatusBTmapping();

  }
  ngOnChanges(changes: SimpleChanges) {
    this.getUpdateStatusPermission();
    if (this.isAllowToSetNoShowStatus())
      this.isFutureAppt = true;
    else
      this.isFutureAppt = false;
   
    this.appointmentNote = "";
    for (let property in changes) {
      if (property === 'patientDetail') {
        if (this.patientDetail) {
          this.patientHeaderDetail = this.patientDetail;
          if (this.patientHeaderDetail.status == 'NoShow') {
            this.patientHeaderDetail.status = 'No Show';
          }
        }
      }
      if (property === 'salesOrderid') {
        this.getSalesOrderInfo(this.salesOrderid);
      }
    }

  }
  private getSalesOrderInfo(SalesOrderId) {
    this.loading = true;
    if (SalesOrderId == 0) {
      return;
    }
    this.appointmentService.GetSalesOrderById(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrder = result.responseModel;
              if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "-";
              }
              if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }
              if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }
              if (this.salesOrder.crmSalesorderStatus) {
                let status = this.wipStatusModel.find(x => x.wipStatesName == this.salesOrder.crmSalesorderStatus)
                if (status) {
                  this.isStatusUpdated = true;
                  this.displayStatus = status.wipStatesName;
                }
                else {
                  this.isStatusUpdated = false;
                }
                this.schWIPStatus = status;
              }
              this.loading = false;
            }
          }

        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getSalesOrderStatus() {
    this.progress.next(true);
    this.theraistAppointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          let salesOrderStatus = result.responseModel;
          this.wipStatusModel = salesOrderStatus.
            filter(x => x.wipStatesName.toLowerCase() == 'complete'
              || x.wipStatesName.toLowerCase() == 'no show'
            );
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async onUpdateAppointment(AppoinmentId) {

    if (this.schWIPStatus?.wipStatesName == undefined) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }
    if (!this.appointmentNote) {
      this.showWarningMessage("Please enter appointment note.");
      return;
    }
    //41466
    let message: string = "No Show status can not be set before Appointment time";
    if (this.schWIPStatus.wipStatesName.toLowerCase() == 'complete')
      message = "Complete status can not be set before Appointment time";

    if (((this.schWIPStatus.wipStatesName.toLowerCase() == 'complete') || (this.schWIPStatus.wipStatesName.toLowerCase() == 'no show')) &&
      (this.isAllowToSetNoShowStatus())) {
      this.showWarningMessage(message);
      return;
    }
    if (!this.isStatusUpdated) {
      this.updateAppointmentStatus();
    }

    //if (!this.isStatusUpdatedinDB)
    //  this.getSalesOrderInfo(this.salesOrderid);

    if (this.isStatusUpdated && AppoinmentId > 0) {
      this.loading = true;
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
      appointmentUpdateStatusRequestModel.SchComments = this.appointmentNote == undefined ? "" : this.appointmentNote;
      await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            if (result.responseModel) {
              this.getSalesOrderInfo(this.salesOrderid);
              this.showSuccessMessage("Appointment note has been updated.");
              this.AppointmentStatusUpdate = false;
            }

          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  updateAppointmentStatus() {
    if (!this.schWIPStatus) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }
    //this.wipStatus = selectedEvent.eventSalesOrderSelectedStatus.wipStatesName;
    let status = this.schWIPStatus;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    appointmentRequestBT.CrmModifiedBy = this.patientHeaderDetail.therapistID;
    let selectedWipStaus: WipStatusBTmapping;
    if (status.wipStatesName.toLowerCase() == 'complete') {
      this.patientHeaderDetail.status = 'Complete';
      selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    if (status.wipStatesName.toLowerCase() == 'no show') {
      this.patientHeaderDetail.status = 'No Show';
      selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    let statusId = selectedWipStaus.crmWipstatusId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.salesOrder.schAppointmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = statusId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.salesOrder.crmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.patientHeaderDetail.therapistID;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.patientHeaderDetail.therapistID;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatusRequestModel.dateTimeLastModified = this.patientHeaderDetail.datetimeLastModified;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    this.theraistAppointmentService.CheckAppointmentUpdatedStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false);  }))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            this.showInfoMessage("The appointment has been rescheduled or updated. The screen will be refreshed.");
            this.wipsStatusCreated.emit({ appWipStatus: selectedWipStaus.appAlias, schAppointmentId: this.salesOrder.schAppointmentId });
            /*this.isStatusUpdatedinDB = false;*/
            this.AppointmentStatusUpdate = false;
            this.getSalesOrderInfo(this.salesOrderid);
          }
          else {
            this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
              .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
              .toPromise()
              .then((result) => {
                this.processResult<boolean>(result, () => {
                  if (result.responseModel) {
                    if (this.salesOrder.schAppointmentId > 0) {
                      this.loading = true;
                      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
                      appointmentUpdateStatusRequestModel.SchAppointmentId = this.salesOrder.schAppointmentId;
                      appointmentUpdateStatusRequestModel.SchComments = this.appointmentNote == undefined ? "" : this.appointmentNote;
                      this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
                        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
                        .toPromise()
                        .then((result) => {
                          this.processResult<boolean>(result, () => {
                            if (result.responseModel) {
                              this.getSalesOrderInfo(this.salesOrderid);
                              this.showSuccessMessage("Appointment status and note has been updated.");
                              this.wipsStatusCreated.emit({ appWipStatus: selectedWipStaus.appAlias, schAppointmentId: this.salesOrder.schAppointmentId });
                              this.AppointmentStatusUpdate = false;

                            }

                          });
                        }).catch((httpResponseError) => {
                          this.loading = false;
                          this.showErrorMessage(httpResponseError.message);
                        });
                    }





                    
                    this.isStatusUpdatedinDB = true;
                    //this.showSuccessMessage("Appointment status has been updated.");
                  }

                });
              }).catch((httpResponseError) => {

                this.showErrorMessage(httpResponseError.message);
              });
          }
        });
      }).catch((httpResponseError) => {
       
        this.showErrorMessage(httpResponseError.message);
        return;
      });




    


  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.featureGroupService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatusBTMappingResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getUpdateStatusPermission() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.AllowTherapistToUpdateAppointmentStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.canUpdateStatue = (result.responseModel.schSettingValue.toLowerCase() === 'true');
          }
        });
      });
  }


  appointmentStatusDialog() {
    this.schWIPStatus = new WIPStatesResponseModel();       
    if (!this.isAllowToSetNoShowStatus()) //41466 
      this.AppointmentStatusUpdate = true;
    else {
      this.showWarningMessage("'No Show' or 'Complete' status can not be set before Appointment time");
    }
  }
  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }
  }
  //41466
  isAllowToSetNoShowStatus(): boolean {
    var currentDateTime;
    var appDateTime;

    if (this.patientDetail == undefined)
      return false;

    //41466: After marking No Show it allow user to change the availability of that therapist
    this.selectedPatientTimeZone = this.timeZoneList.find(x => x.orgcode == this.patientDetail?.patientTimeZone);
    var orgappDateTime = moment.tz(this.patientDetail?.appointmentDate, this.selectedPatientTimeZone.countryName);

    //convert appointment time to local timezone
    this.selectedPatientTimeZone = this.timeZoneList.find(x => x.code == this.appDateFormat.timeZone);
    var appDate = orgappDateTime.tz(this.selectedPatientTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    appDateTime = moment(appDate).toDate();

    // get curretn date time from local timezone
    var date = moment(new Date()).tz(this.selectedPatientTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    currentDateTime = moment(date).toDate();

    // if (moment(this.patientDetail?.appointmentDate).isAfter(moment(Date()).toDate())) 
    //   return true;

    return false;
  }

  changeWipStatus(event: any) {
    //41466
    this.schWIPStatus = event.value;
    let message: string = "No Show status can not be set before Appointment time";
    if (this.schWIPStatus.wipStatesName.toLowerCase() == 'complete')
      message = "Complete status can not be set before Appointment time";

    if (this.isAllowToSetNoShowStatus()) {
      this.showWarningMessage(message);
      return;
    }
    let result = [... this.wipStatusModel.filter(item => item.wipStatesName === this.schWIPStatus.wipStatesName)];
    if (result.length != null && result.length > 0) {
      if (this.schWIPStatus.wipStatesName.toLowerCase() == 'complete') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }

      if (this.schWIPStatus.wipStatesName.toLowerCase() == 'no show') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }
    }
  }


}
