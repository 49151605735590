import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, EventEmitter, Inject, Injectable, Input} from '@angular/core';



@Injectable()

@Component({
  selector: 'mwl-week-view-scheduler-v1',
  styleUrls: ['./week-view-scheduler-v1.component.sass'],
  templateUrl: './week-view-scheduler-v1.component.html'
})

export class WeekViewSchedulerV1Component {
  title = 'Mark Wood';
 
  constructor() {
  }
}
