import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SalesOrderService } from '../services/salesorder.service';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { PatientService } from '../services/patient.service';
import { Calendar } from 'primeng/calendar';
import { SalesOrderResponseModel } from '../../shared/models/response/sales-order-response';
import { SettingsResponseModel } from '../../shared/models/response/appointment/settings-response';
import * as moment from 'moment';
import { join } from '@angular/compiler-cli/src/ngtsc/file_system';
import { isThisMinute } from 'date-fns';
@Component({
  selector: 'app-choose-therapist',
  templateUrl: './choose-therapist.component.html',
  styleUrls: ['./choose-therapist.component.sass']
})
export class ChooseTherapistComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('pcalendar', { static: true }) calendar: Calendar;
  @Output() activeStepNoEmit:EventEmitter<number>= new EventEmitter();
  @Output() isFacilityOrTherapistSelectionChangedEmit:EventEmitter<boolean>= new EventEmitter();
  //@ViewChild('dateFilter', { static: true }) private dateFilter: any;
  private progress = new Subject<boolean>();
  facilityMoreInfo: boolean;
  dateFormat: string = '';
  appDateFormat: DateFormatModel;
  salesORderFromLocal: SalesOrderLocalModel;
  salesOrderData: SalesOrderResponseModel;
  therapistSearchResponseModel: TherapistResponseModel[];
  facilitySearchRequestModel: FacilitySearchRequestModel;
  isSearch: boolean = false;
  isImageSaved: boolean;
  cardImageBase64: string;
  profileLogo: string;
  crmProfileImageFormat: string = "";
  closeSidebar: boolean;
  private unsubscriber = new Subject<boolean>();
  duration: string = "";
  therapistlabelCount: string = "";
  appUserFullnameT: string = "";
  crmFacilityNameT: string = "";
  crmAddressT: string = "";
  crmBioDataT: string = "";
  crmProfileImageSrcT: string = "";
  isTherapistAvailablityT: boolean = false;
  crmFacilityDistanceInMilesT: string = "";
  selectedAppointmentFacilities: FacilityInfoResponseModel[]=[];
  facilityResponseModel: FacilityInfoResponseModel[];
  rangeDates: Date[] = [];
  crmFacilityFirstAvailableStartDateT: Date;
  description: string;
  onPopuptherapistSearchResponseModel: TherapistResponseModel;
  isResetEnable: boolean = false;
  @ViewChild('searchfacilityZipCode', { static: false }) searchfacilityZipCode: ElementRef;
  FilterEventStatus: boolean;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; };
  isBillingAddress: boolean = false;
  patientsDefaultAppointmentLocation: string;
  selectedDateRange: any;
  therapistIds: string[] = [];
  patientName: string;
  minimumDate = new Date();
  maxDate: any;
  facilityDateStartTime: Date;
  daysToShow: number;

  isChecked:boolean=true;
  sidebarInfo: boolean = false ;
  isEnableSearchButton: boolean;

  ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };
  selectedTherapist: TherapistResponseModel[];
  mobileFilter: boolean;
  mobileFilterOverly: boolean;

  datesUpdated(range): void {
    this.selectedDateRange = range;
  }

  constructor(
    private router: Router,
    private patientService: PatientService,
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private salesorderService: SalesOrderService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();

    let settingResult=this.webStorageService.getPatientSalesOrderSettings();
    if(settingResult){
      this.daysToShow = parseInt(settingResult.numOfMonths, 10);
      var today = new Date();
              this.maxDate = new Date(new Date().setDate(today.getDate() + this.daysToShow));

      this.patientsDefaultAppointmentLocation = settingResult.patientsDefaultAppointmentLocation;
      if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress') {
        this.isBillingAddress = true;
      }
      else {
        this.isBillingAddress = false;
      }
    }

  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  async ngOnInit() {
    this.loadingCustom = true;
    this.salesORderFromLocal = this.webStorageService.getSalesOrderId();
    this.facilitySearchRequestModel = new FacilitySearchRequestModel();
    this.facilitySearchRequestModel.crmSalesOrderId = Number(this.salesORderFromLocal.salesOrderId);
    this.facilitySearchRequestModel.crmPatientId = Number(this.salesORderFromLocal.patientId);
    this.patientName = this.salesORderFromLocal.patientName;
    this.initializeAllDefaultModel();
    this.getSalesOrderData();
    //this.GetDefaultFacilitiesByPatientTherapist();
    await this.getTherapistByPatient(this.facilitySearchRequestModel);
    //await this.availabilityBasedFacility();
    this.webStorageService.deleteSelectedTherapist();
    //this.selectedTherapist = this.webStorageService.getSelectedTherapist()[0];
    if (!this.selectedTherapist) {
      this.selectedTherapist = [];
    }

  }

  therapistInfoDialog(therapistSearchResponseModel: TherapistResponseModel) {
    this.sidebarInfo = true;
    this.facilityMoreInfo = true;
    this.onPopuptherapistSearchResponseModel = new TherapistResponseModel();
    this.onPopuptherapistSearchResponseModel.appUserFullname = therapistSearchResponseModel.appUserFullname;
    this.onPopuptherapistSearchResponseModel.crmFacilityName = therapistSearchResponseModel.crmFacilityName;
    this.onPopuptherapistSearchResponseModel.crmAddress = therapistSearchResponseModel.crmAddress;
    this.onPopuptherapistSearchResponseModel.crmState = therapistSearchResponseModel.crmState;
    this.onPopuptherapistSearchResponseModel.crmZipCode = therapistSearchResponseModel.crmZipCode;
    this.onPopuptherapistSearchResponseModel.crmBioData = therapistSearchResponseModel.crmBioData; 
    this.onPopuptherapistSearchResponseModel.crmProfileImageSrc = therapistSearchResponseModel.crmProfileImageSrc;
    this.onPopuptherapistSearchResponseModel.isTherapistAvailablity = therapistSearchResponseModel.isTherapistAvailablity;
    this.onPopuptherapistSearchResponseModel.crmFacilityDistanceInMiles = therapistSearchResponseModel.crmFacilityDistanceInMiles;
    this.onPopuptherapistSearchResponseModel.crmFacilityFirstAvailableStartDate = therapistSearchResponseModel.crmFacilityFirstAvailableStartDate;
    this.onPopuptherapistSearchResponseModel.appUserInfoId = therapistSearchResponseModel.appUserInfoId;

    // this.appUserFullnameT = therapistSearchResponseModel.appUserFullname;
    // this.crmFacilityNameT = therapistSearchResponseModel.crmFacilityName;
    // this.crmAddressT = therapistSearchResponseModel.crmAddress + therapistSearchResponseModel.crmState + therapistSearchResponseModel.crmZipCode;
    // this.crmBioDataT = therapistSearchResponseModel.crmBioData;
    // this.crmProfileImageSrcT = therapistSearchResponseModel.crmProfileImageSrc;
    // this.isTherapistAvailablityT = therapistSearchResponseModel.isTherapistAvailablity;
    // this.crmFacilityDistanceInMilesT = therapistSearchResponseModel.crmFacilityDistanceInMiles;
    // this.crmFacilityFirstAvailableStartDateT = therapistSearchResponseModel.crmFacilityFirstAvailableStartDate;
  }

  private async getSalesOrderData() {
    this.salesOrderData = this.webStorageService.getSalesOrderData();
    this.description = this.salesOrderData.crmClassificationDescription;
    this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
    // await this.salesorderService.getSalesOrderDetails(this.salesORderFromLocal.salesOrderId)
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .toPromise()
    //   .then((result) => {
    //     this.processResult<SalesOrderResponseModel>(result, () => {
    //       if (result.isSuccess) {
    //         if (result.responseModel) {
    //           const salesOrderData = result.responseModel;
    //           this.duration = salesOrderData.crmClassificationDuration == 1 || salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : salesOrderData.crmClassificationDuration.toString() + " Minutes";
    //         }
    //       }
    //     });
    //   });
  }

  private async getTherapistByPatient(facilitySearchModel: FacilitySearchRequestModel) {
    if (this.isBillingAddress)
      this.facilitySearchRequestModel.crmState = this.salesOrderData.patientInfo.crmState;
    else
      this.facilitySearchRequestModel.crmState = this.salesOrderData.crmDeliveryAddressState;

    //For Time Zone Parameter Passed
    this.facilitySearchRequestModel.patientTimeZone = this.isBillingAddress ? this.salesORderFromLocal.crmBillingAddressTimeZone : this.salesORderFromLocal.crmDeliveryAddressTimeZone;

    this.facilitySearchRequestModel.crmPatientZipCode = this.isBillingAddress ? this.salesOrderData.patientInfo.crmPatientZipCode : this.salesOrderData.crmDeliveryAddressZipcode;

    if (this.isSearch) {
      this.facilitySearchRequestModel.facilityZipCode = facilitySearchModel.facilityZipCode;
      this.facilitySearchRequestModel.crmCityTown = facilitySearchModel.crmCityTown;
      this.facilitySearchRequestModel.crmState = this.facilitySearchRequestModel.crmState;
      this.facilitySearchRequestModel.crmPatientId = facilitySearchModel.crmPatientId;
      this.facilitySearchRequestModel.crmSalesOrderId = facilitySearchModel.crmSalesOrderId;
      this.facilitySearchRequestModel.crmFacilityiIdsString = facilitySearchModel.crmFacilityiIdsString;
      this.facilitySearchRequestModel.radius = 3;
      this.facilitySearchRequestModel.schAppointmentDateStartTimeString = (facilitySearchModel.schAppointmentDateStartTime == undefined || facilitySearchModel.schAppointmentDateStartTime == null) ? null : moment(facilitySearchModel.schAppointmentDateStartTime, 'MM-DD-YYYY').format("MM-DD-YYYY");
      this.facilitySearchRequestModel.schAppointmentDateEndTimeString = (facilitySearchModel.schAppointmentDateEndTime == undefined || facilitySearchModel.schAppointmentDateEndTime == null) ? null : moment(facilitySearchModel.schAppointmentDateEndTime, 'MM-DD-YYYY').format("MM-DD-YYYY");
    }
    else {
      this.facilitySearchRequestModel.crmPatientId = facilitySearchModel.crmPatientId;
      this.facilitySearchRequestModel.crmSalesOrderId = facilitySearchModel.crmSalesOrderId;
      this.facilitySearchRequestModel.radius = 3;
      this.facilitySearchRequestModel.crmState = this.facilitySearchRequestModel.crmState;
      this.facilitySearchRequestModel.crmFacilityiIdsString = facilitySearchModel.crmFacilityiIdsString;
      this.facilitySearchRequestModel.schAppointmentDateStartTimeString = null;
      this.facilitySearchRequestModel.schAppointmentDateEndTimeString = null;

    }
    this.therapistIds = [];
    await this.patientService.searchTherapistByPatient(this.facilitySearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.isSearch = false;
          const image = new Image();
          this.therapistSearchResponseModel = result.responseModel;
          this.therapistlabelCount = this.therapistSearchResponseModel.length + " Therapists Found";
          if (this.therapistSearchResponseModel.length > 0) {
            this.therapistSearchResponseModel.forEach(x => {
              var appUserInfoId = x.appUserInfoId.toString();
              this.therapistIds.push(appUserInfoId);
              if (x.crmProfileImageSrc) {
                const image = new Image();
                  image.src = "data:image/png;base64," + x.crmProfileImageSrc;
                  image.onload = () => {
                    const imgBase64Path = "data:image/png;base64," + x.crmProfileImageSrc;
                    this.cardImageBase64 = imgBase64Path;
                    const indexOfComma = imgBase64Path.indexOf(",", 0);
                    this.profileLogo = logoString;
                    var logoString = this.cardImageBase64.slice(indexOfComma + 1);
                    x.crmProfileImageSrc = this.cardImageBase64;
                    this.isImageSaved = true;
                  };
              }
              else {
                x.crmProfileImageSrc = null
              }
            });
            this.availabilityBasedFacility();
          }
          // else {
          //   this.showWarningMessage("No therapist found on given search.");
          // }
        }
        this.loadingCustom = false;
      });

  }

  SelectTherapistClick(){
    //this.selectTherapist = !this.selectTherapist;
    this.isChecked = false;
    this.facilityMoreInfo = false;
  }

  onSelect(therapist: TherapistResponseModel, status: boolean) {
    var objTemp=this.selectedTherapist.filter(x=>x.isSelect);
    if(objTemp.length>0){
      objTemp.forEach(x=>{x.isSelect=false});
    }

    this.selectedTherapist=[];
    var objselected = this.selectedTherapist.find(x => x.appUserInfoId === therapist.appUserInfoId);
      if (!objselected) {
        therapist.isSelect=true;
        this.selectedTherapist.push(therapist);   
        this.isChecked=false;   
      }
    this.webStorageService.deleteSelectedTherapist();
    this.webStorageService.storeSelectedTherapist(this.selectedTherapist[0]);
  }

  // onBook(therapistSearchResponseModel: TherapistResponseModel) {
  //   this.sidebarInfo = false;
  //   this.webStorageService.storeSelectedTherapist(therapistSearchResponseModel);
  //   this.router.navigate(['/patient/choose-date-time']);
  // }

  onNext() {
     this.sidebarInfo = false;
    // this.router.navigate(['/patient/choose-date-time']);
    //this.activeStepNoEmit.emit(2);

    this.webStorageService.deleteSelectedTherapist();
    this.webStorageService.storeSelectedTherapist(this.selectedTherapist[0]);

    //this.router.navigate(['/patient/choose-date-time']);
    this.activeStepNoEmit.emit(2);
    this.isFacilityOrTherapistSelectionChangedEmit.emit(true);

  }

  onReset() {
    if (this.searchfacilityZipCode != null && this.searchfacilityZipCode != undefined) {
      this.searchfacilityZipCode.nativeElement.value = null;
    }
    this.selectedAppointmentFacilities = [];
    // if (this.dateFilter.nativeElement != null && this.dateFilter.nativeElement != undefined)
    // {
    //   this.dateFilter.nativeElement.value = null;
    // }
    this.calendar.value = '';
    this.facilityDateStartTime=null;
    this.isEnableSearchButton=false;
    this.onSearch();
    this.isResetEnable = false;
    this.therapistIds = [];
  }


  onSearch() {
    this.isSearch = true;
    this.loadingCustom = true;
    this.isResetEnable = !this.isResetEnable;
    let selectFacilityString = "";
    let facilityids: number[] = new Array<number>();
    let dateRange: Date[] = new Array<Date>();
    //const selectedDateRange = this.dateFilter.nativeElement.value
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });
    // if (selectedDateRange != null) {
    //   dateRange = selectedDateRange.split("-");
    // }
    let facilitySearchRequestModel: FacilitySearchRequestModel = new FacilitySearchRequestModel();
    facilitySearchRequestModel.crmSalesOrderId = Number(this.salesORderFromLocal.salesOrderId);
    facilitySearchRequestModel.crmPatientId = Number(this.salesORderFromLocal.patientId);
    facilitySearchRequestModel.crmPatientZipCode = this.salesORderFromLocal.zipCode;
    facilitySearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    // facilitySearchRequestModel.schAppointmentDateStartTime = dateRange[0];
    // facilitySearchRequestModel.schAppointmentDateEndTime = dateRange[1];
    facilitySearchRequestModel.schAppointmentDateStartTime = this.calendar.value;
    facilitySearchRequestModel.schAppointmentDateEndTime = this.calendar.value;
    if (this.searchfacilityZipCode != null && this.searchfacilityZipCode != undefined) {
      facilitySearchRequestModel.facilityZipCode = this.searchfacilityZipCode.nativeElement.value;
    }
    if ((facilitySearchRequestModel.schAppointmentDateStartTime == undefined || facilitySearchRequestModel.schAppointmentDateEndTime == undefined) && (facilitySearchRequestModel.crmFacilityiIdsString == '' || facilitySearchRequestModel.crmFacilityiIdsString == '') && (facilitySearchRequestModel.crmPatientZipCode == '' || facilitySearchRequestModel.crmPatientZipCode == undefined)) {
      this.isResetEnable = false;
    }
    else {
      if ((facilitySearchRequestModel.schAppointmentDateStartTime != undefined && facilitySearchRequestModel.schAppointmentDateEndTime != undefined) || (facilitySearchRequestModel.crmFacilityiIdsString != '' || facilitySearchRequestModel.crmFacilityiIdsString != '') || (facilitySearchRequestModel.crmPatientZipCode != '' || facilitySearchRequestModel.crmPatientZipCode != undefined)) {
        this.isResetEnable = true;
      }
    }

    this.getTherapistByPatient(facilitySearchRequestModel);
  }

  valuechange(newValue: string) {
    if(this.selectedAppointmentFacilities.length>0 || this.calendar.value){
      this.isEnableSearchButton=true;
    }
    else{
      this.isEnableSearchButton=false;
    }
  }

  clickEventFilter(){
    this.mobileFilter = !this.mobileFilter;
    this.mobileFilterOverly = !this.mobileFilterOverly;   
  }

  clickEventFilterClose(){
    this.mobileFilter = !this.mobileFilter;
    this.mobileFilterOverly = !this.mobileFilterOverly;     
  }

  onFacilityChange(event) {
    if (event.value) {
      this.selectedAppointmentFacilities = event.value;
    }
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(['patient/home/' + url]);
  }

  private async GetDefaultFacilitiesByPatientTherapist() {
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.progress.next(true);
    await this.patientService.GetDefaultFacilitiesByPatientTherapist()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {

        this.facilityResponseModel = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  FilterclickEvent() {
    this.FilterEventStatus = !this.FilterEventStatus;
  }

  initializeAllDefaultModel() {
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST' },
    ];
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);

  }

  private async availabilityBasedFacility() {
    const today = moment();
    var datepass = today.format('YYYY-MM-DD')
    var therpaist = "";
    if (this.therapistIds.length > 0) {
      this.therapistIds.forEach((value, index) => {
        therpaist = therpaist + "," + this.therapistIds[index];
      });
      //this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
      this.progress.next(true);
      await this.patientService.GetFacilitiesByPatientTherapistAvailability(datepass, therpaist)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {

          this.facilityResponseModel = result.responseModel;
        })
        .catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  SetDays(event: any) {
    this.facilityDateStartTime = event;
    this.facilitySearchRequestModel.schAppointmentDateStartTime = this.facilityDateStartTime;
    this.facilitySearchRequestModel.schAppointmentDateEndTime = this.facilityDateStartTime;
  }

 

}
