export class AppointmentConfirmation {
  appointmentStartDate: Date;
  appointmentEndDate: Date;
  appointmentTimeZone: string;
  appointmentTimeZoneCode: string;
  duration: string;
  description: string;
  facilityName: string;
  facilityAddress: string;
  facilityAddressLine1: string;
  facilityAddressLine2: string;
  facilityCity: string;
  facilityState: string;
  facilityCountry: string;
  facilityZipCode: string;
  facilityLatitude: number;
  facilityLongitude: number;
  crmBioData: string;
  distance: string;
  dueAmount: number;
  durationInMinutes: number;
  crmPatientname: string;
  crmPatientaddress: string;
  appointmentType: string;
  crmTherapistname: string;
  remoteType: string;
  selectedAppointmentTypePopUp: string;
  isTherapistFacilityType: boolean;
  isTherapistRemoteType: boolean;
  isTherapistRemoteAndFaciltyType: boolean;
  PatientEmail: string;
  PatientPhone: string;
  PatientMobileNumber: string;
  schAppointmentId: number;
  schIsReminderOptedSms: boolean;
  schIsReminderOptedEmail: boolean;

}
