<div class="main-outer" [ngClass]="isActive ? 'collapse':'' ">
  <div class="main-outer__sidebar">
    <a href="javascript:void(0)" class="sidebar-button" [ngClass]="{'active': isActive }" (click)="sidebarMenuClick()">
      <!-- <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg> -->
      <svg width="63" height="58" viewBox="0 0 63 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 20H43V22.551H20V20ZM20 27.7245H35.3333V30.2755H20V27.7245ZM20 35.449H43V38H20V35.449Z" fill="white"/>
      </svg>
    </a>
    <!-- Start Sidebar -->
    <app-sidebar></app-sidebar>
    <!-- End Sidebar -->
    <a href="javascript:void(0)" class="ic sidebar-arrow" [ngClass]="{'active': isActive }"
      (click)="isActive = !isActive;"></a>
  </div>
  <section class="main-outer__content-outer">
    <!-- Start Header -->
    <app-header></app-header>
    <!-- End Header -->
    
    <div class="content-area">
      <div class="deshboard-top">
        <div class="title-block">
          <h1>{{title}}</h1>
          <div class="title-block__right">
            <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/dashboard']"><i class="back-new"></i> Back</a>
          </div>
        </div>

        <div class="form-outer">
          <form class="form support-form" [formGroup]="form" autocomplete="off">

            <div class="col">
              <div class="form__group">
                <label class="form__label">Your Name <em>*</em></label>
                <input type="text" class="form__control" pInputText formControlName="RequestorName" maxlength="50" />
                <div class="invalid-feedback"
                  *ngIf="hasError(formFields.RequestorName, 'required') || hasError(formFields.RequestorName, 'whitespace')">
                  {{validationMessages.RequestorName.required}}</div>

              </div>
              <div class="form__group">
                <label class="form__label">Email <em>*</em></label>
                <input type="text" class="form__control" pInputText formControlName="RequestorEmail" maxlength="50" />
                <div class="invalid-feedback" *ngIf="hasError(formFields.RequestorEmail,'required')">
                  {{commonValidationMessages.email.required}}</div>
                <div class="invalid-feedback" *ngIf="hasError(formFields.RequestorEmail,'email')">
                  {{commonValidationMessages.email.email}}</div>
              </div>
            </div>

            <div class="col">
              <div class="form__group">
                <label class="form__label">Phone (optional)</label>
                <p-inputMask mask="999-999-9999" formControlName="RequestorPhone" class="form__phone" pInputText
                  maxlength="15"></p-inputMask>
              </div>

              <div class="form__group">
                <label class="form__label">Category *</label>
                <p-dropdown class="select-box" optionLabel="name" dataKey="name" formControlName="ProblemCategory"
                  [options]="supportCategories" [(ngModel)]="selectedSupportCategory" placeholder="Select Category">
                </p-dropdown>
                <div class="invalid-feedback" *ngIf="hasError(formFields.ProblemCategory, 'required')">
                  {{validationMessages.ProblemCategory.required}}</div>
              </div>
            </div>

            <div class="form__group">
              <label class="form__label">Where did you run into the problem? <em>*</em></label>
              <input type="text" class="form__control" pInputText formControlName="ProblemArea" maxlength="50" />
              <small>Please tell us the section in the program or a link to the page</small>
              <div class="invalid-feedback" *ngIf="hasError(formFields.ProblemArea, 'required')">
                {{validationMessages.ProblemArea.required}}</div>
            </div>

            <div class="form__group">
              <label class="form__label">Please describe in detail what happened. <em>*</em></label>
              <textarea class="form__textarea" formControlName="Problem" pInputTextarea maxlength="250" #box
                (keyup)="0"></textarea>
              <div class="invalid-feedback" *ngIf="hasError(formFields.Problem, 'required')">
                {{validationMessages.Problem.required}}</div>
            </div>

            <div class="col">
              <div class="form__group">
                <label class="form__label">Screenshot of Issue</label>
                <div class="browse-import-btn">
                  <input #uploadE1 class="browse-btn" #LogoFile type="file" placeholder="Choose file"
                    (change)="uploadLogoFile($event)" style="display:none;">
                  <button type="button" class="btn" (click)="LogoFile.click()">Browse</button>
                </div>
                <ng-container *ngIf="companyLogo">
                  <div class="upload-img-box">

                    <div class="upload-img-box__img">
                      {{companyLogoFileName}}
                    </div>
                    <a href="javascript:void(0);" class="remove-img" (click)="removeImage()"><i
                        class="ic delete small"></i> </a>
                    <ng-template #elseTemplate></ng-template>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="buttons set-custom-date-btn">
              <button class="btn btn--primary" (click)="onSubmit()">Submit</button>
              <button class="btn btn--secondary" routerLink="/admin/dashboard">Cancel</button>
            </div>
            <!-- </div> -->
          </form>
        </div>
      </div>
    </div>
  </section>
</div>