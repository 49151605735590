import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  clientId = 1;
  Authorization = 'Bearer 2123';

  constructor(private http: HttpClient) { }

  uploadtDoctor(formData)  {
    const headers = new HttpHeaders()
    .set('clientId', this.clientId.toString()).append('Authorization', this.Authorization);
    // tslint:disable-next-line: max-line-length
    return this.http.post(this.getApiUrl('doctor/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' });
  }

  uploadFacility(formData: FormData)  {
    const headers = new HttpHeaders()
    .set('clientId', this.clientId.toString()).append('Authorization', this.Authorization);
    // tslint:disable-next-line: max-line-length
    return this.http.post(this.getApiUrl('Facility/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' });
  }

  uploadTherapist(formData: FormData)  {
    const headers = new HttpHeaders()
    .set('clientId', this.clientId.toString()).append('Authorization', this.Authorization);
    // tslint:disable-next-line: max-line-length
    return this.http.post(this.getApiUrl('Therapist/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' });
  }

  private getApiUrl(url: string): string {
    return environment.apiBaseUrl + url;
  }
}
