import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DialogService } from 'primeng/dynamicdialog';
import { UserService } from '../../servicies/user.service';
import { ReportService } from '../../servicies/report.service';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { SettingsService } from '../../servicies/settings.service';
import { SearchRequestModel, SearchRequestModelReport } from 'src/app/shared/models/request/search-request-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { finalize, takeUntil } from 'rxjs/operators';
import { FailedToSyncSalesOrderReport, ReportSummaryResponseModel } from 'src/app/shared/models/response/report-summary-response-model';
import * as moment from 'moment';
import { ImportexportService } from '../../servicies/importexport.service';
import { BrightreeSettingsResponseModel } from '../../../shared/models/response/brightree-settings-response-model';
import { RetrySyncSalesOrderRequestModel, RetrySyncSalesOrderStatusResposeModel } from '../../../shared/models/request/retry-sync-sales-order-request-model';
import { Table, TableHeaderCheckbox } from "primeng/table";

import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
@Component({
  selector: 'app-autosync-salesorder',
  templateUrl: './autosync-salesorder.component.html',
  styleUrls: ['./autosync-salesorder.component.sass'],
  providers: [DialogService]
})
export class AutosyncSalesorderComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild("tableAppointment") private _tableAppointment: Table;
  @ViewChild("headerSCheckBox") private headerSCheckBox: TableHeaderCheckbox;
  @ViewChildren('headerSCheckBox') headerSCheckBoxchildrenComponent: QueryList<TableHeaderCheckbox>;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  loggedInUserId: number;
  totalRecords: number;
  totalPages: number;
  index: number = 0;
  private hasPageHistory: boolean;
  currentUserBranch: number;
  currentSelectedUserBranch: number;
  searchRequestModel: SearchRequestModel;
  searchRequest: SearchRequestModelReport;
  appDateFormat: DateFormatModel;
  isGlobalSearch: boolean = false;
  SSelectedDateRange: any;
  failedToSyncSalesOrderReport: FailedToSyncSalesOrderReport[];
  callocales: any;
  invalidDates: moment.Moment[] = [];
  selectedStatus: any;
  selectedProcessType: any;
  sRanges = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Today: [moment(), moment()],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  };

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }

  tooltips = [
    { date: moment(), text: 'Today is just unselectable' },
    { date: moment().add(2, 'days'), text: 'Yeeeees!!!' },
  ];

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };
  datesUpdated(range): void {
    this.SSelectedDateRange = range;
  }

  statusOption = [
    { name: 'All', code: '1' },
    /* { name: 'Success', code: '2' },*/
    { name: 'Failed', code: '3' },
    { name: 'InProcess', code: '4' }

  ]


  //      Auto,
  //      Manual,
  //      UpdatePODStaus,
  //      ScheduleAppointment,
  //      ReScheduleAppointment,
  //      UpdateAppointmentStatus,
  //UpdateSalesOrderStatus

  ProcessTypeOption = [
    { name: 'All', code: 'All' },
    { name: 'Auto', code: 'Auto Sync' },
    { name: 'Manual', code: 'Manual' },
    /* { name: 'UpdatePODStaus', code: 'Update POD Staus' },*/
    { name: 'ScheduleAppointment', code: 'Schedule Appointment' },
    { name: 'ReScheduleAppointment', code: 'ReSchedule Appointment' },
    { name: 'UpdateAppointmentStatus', code: 'Update Appointment Status' },
    { name: 'UpdateSalesOrderStatus', code: 'Update Sales Order Status' }

  ]
  brightreeSettingModel: BrightreeSettingsResponseModel;
  selectedFailedToSyncSalesOrder: FailedToSyncSalesOrderReport[];

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private userService: UserService,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService,
    private settingsService: SettingsService,
    private importExportService: ImportexportService,
    private _cdr: ChangeDetectorRef,
    private dialougeService: DialogService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService); {
      const loggedInUser = this.webStorageService.getCurrentUserToken();
      this.loggedInUserId = loggedInUser.userId;
      this.totalRecords = 0;
      this.totalPages = 1;
      this.hasPageHistory = false;
      this.currentUserBranch = this.webStorageService.getUserBranch();
      this.currentSelectedUserBranch = this.currentUserBranch ? this.currentUserBranch : 0;
      const currentNavigationState = this.router.getCurrentNavigation().extras.state;
      if (currentNavigationState) {
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        this.index = Number(currentNavigationState.selectedTab);
        this.hasPageHistory = true;
      }
      this.loading = false;
      // this.initializeSearchRequestModel();
      this.appDateFormat = this.webStorageService.getDateFormat();
      this.callocales = {
        format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
        displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
        direction: 'ltr', // could be rtl
        weekLabel: 'W',
        separator: ' To ', // default is ' - '
        cancelLabel: 'Cancel', // detault is 'Cancel'
        applyLabel: 'Apply', // detault is 'Apply'
        clearLabel: 'Clear', // detault is 'Clear'
        customRangeLabel: 'Custom range',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: 1 // first day is monday
      }
    }
  }

  private initializeSearchRequestModel() {
    this.searchRequest = new SearchRequestModelReport();
    if (this.currentSelectedUserBranch > 0) {
      this.searchRequest.branchId = new Array<number>();
      this.searchRequest.branchId[0] = this.currentSelectedUserBranch;
    } else {
      this.searchRequest.branchId = new Array<number>();
    }

    this.searchRequest.firstRecordIndex = 0;
    this.searchRequest.currentPageIndex = 0;
    this.searchRequest.pageSize = +this.pageSizes[0];
    this.searchRequest.sortOrder = 'DESC';
    this.searchRequest.sortByColumnName = 'TransactionDate';
    if (this.isGlobalSearch) {
      this.searchRequestModel.dictionaryFilter = {};
      //this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
  }


  ngOnInit() {
    let BtSettings = this.webStorageService.getBrightreeSettings();
    this.brightreeSettingModel = BtSettings;
    if (this.brightreeSettingModel.allowUpdatePODStatusToBrightTree) {
      let appliedFilter: { name: string, code: string } = { name: 'UpdatePODStaus', code: 'Update POD Staus' };
      this.ProcessTypeOption.push(appliedFilter);
    }



    this.selectedStatus = { name: 'All', code: '1' };
    this.selectedProcessType = { name: 'All', code: 'All' };
    const endDate = moment();
    const startDate = moment().subtract(29, 'days');
    this.SSelectedDateRange = { startDate: startDate, endDate: endDate };
    this.initializeSearchRequestModel();
    //this.getFailedToAutoSyncSalesOrders()
  }


  updateCheckedState() {
    debugger;
    this._cdr.markForCheck();

    if (this._tableAppointment.filteredValue && !this._tableAppointment.lazy) {
      const val = this._tableAppointment.filteredValue;
      return (val && val.length > 0 && this._tableAppointment.selection && this._tableAppointment.selection.length > 0);
    }
    else {
      const val = this._tableAppointment.value;
      const length = this._tableAppointment.lazy ? this._tableAppointment._totalRecords : val?.length;
      return (val && length > 0 && this._tableAppointment.selection && this._tableAppointment.selection.length > 0 && this._tableAppointment.selection.length === length);
    }
  }


  ngAfterViewInit() {

    this.headerSCheckBoxchildrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
      if (comps.length > 0) {
        //const orig_updateCheckedState = this.headerSCheckBox.updateCheckedState;
        const me = this;
        me.headerSCheckBox.updateCheckedState = function () {

          //const cars: any[] = me._tableAppointment.filteredValue || me._tableAppointment.value;
          //const selection: any[] = me._tableAppointment.selection;
          //if (cars != null) {
          //  for (const car of cars) {
          //    const selected = selection && selection.indexOf(car) >= 0;
          //    if (!selected) {
          //      return false;
          //    }

          //  }
          //}
          //if (selection && selection.length > 0)
          //  return true;


          if (me._tableAppointment.filteredValue && !me._tableAppointment.lazy) {
            const val = me._tableAppointment.filteredValue;
            return (val && val.length > 0 && me._tableAppointment.selection && me._tableAppointment.selection.length > 0);
          }
          else {
            const val = me._tableAppointment.value;

            let length = me._tableAppointment.lazy ? me._tableAppointment._totalRecords : val?.length;
            length = val?.length < me._tableAppointment._totalRecords ? val?.length : me._tableAppointment._totalRecords;

            let resultValue: boolean = false;
            resultValue = (val && length > 0 && me._tableAppointment.selection && me._tableAppointment.selection.length > 0 && me._tableAppointment.selection.length === length);
            console.log(resultValue);
            me.headerSCheckBox.checked = resultValue;
            me._cdr.detectChanges();
            return resultValue;
          }

        };
      }
    });

  }

  OnSearch() {
    this.getFailedToAutoSyncSalesOrders();
  }

  private getFailedToAutoSyncSalesOrders() {
    // this.initializeSearchRequestModel();

    //if (this.searchBox.nativeElement.value)
    //  this.searchRequest.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    //else
    //  this.searchRequest.dictionaryFilter['SearchText'] = '';


    if (this.SSelectedDateRange) {
      if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
        let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
        let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

        if (startDate && endDate) {
          if (moment(startDate).toDate() > moment(endDate).toDate()) {
            this.showWarningMessage("From Date should not be greater than To Date.");
            return;
          }
        }

        if (startDate && endDate) {
          this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

          this.searchRequest.searchFromDateString = startDate;
          this.searchRequest.searchToDateString = endDate;
        }
      }
    }
    if (this.selectedStatus != null) {
      this.searchRequest.appointmentStatus = this.selectedStatus.name;
    }

    if (this.selectedProcessType != null) {
      this.searchRequest.processType = this.selectedProcessType.name;
    }
    this.loading = true;

    this.reportService.getfailedtoSyncSalesOrders(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<FailedToSyncSalesOrderReport>>) => {
        this.processResult<PagedResponseModel<FailedToSyncSalesOrderReport>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.failedToSyncSalesOrderReport = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onLazyLoad(event: LazyLoadEvent) {

    this.selectedFailedToSyncSalesOrder = [];
    if (this.headerSCheckBox)
      this.headerSCheckBox.checked = false;
    //if (!this.hasPageHistory) {
    this.searchRequest.firstRecordIndex = event.first;
    this.searchRequest.currentPageIndex = event.first / 20;
    this.searchRequest.pageSize = 20;
    this.searchRequest.sortByColumnName = event.sortField;
    this.searchRequest.isAscendingOrder = event.sortOrder === 1 ? false : true;
    this.searchRequest.sortOrder = event.sortOrder === 1 ? 'DESC' : 'ASC';
    //}
    this.getFailedToAutoSyncSalesOrders();
  }

  getTotalRows() {
    if (this.searchRequest.pageSize) {
      return this.searchRequest.pageSize;
    }
    return this.totalRecords;
  }

  ReImportSO(crmSalesOrderAppId) {
    let _soId = crmSalesOrderAppId;
    this.loading = true;
    this.importExportService.SyncBySalesOrderId(_soId, false, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result.isSuccess) {
          let _gridmodel = result.responseModel;

          this.showSuccessMessage('Request execute successfully');
        }
        else {
          this.showErrorMessage('Failed to import');
        }
        this.getFailedToAutoSyncSalesOrders();
      }, (httpResponseError) => {

      });
  }
  RetrySalesOrderProcess(crmSalesOrderAppId) {
    this.loading = true;
    let SalesOrderIds: number[] = [];
    SalesOrderIds.push(parseInt(crmSalesOrderAppId));
    let retrySalesOrderIds = new RetrySyncSalesOrderRequestModel();
    retrySalesOrderIds.brightreeInfoIds = SalesOrderIds;
    retrySalesOrderIds.crmUpdatedBy = this.loggedInUserId;
    this.importExportService.RetrySyncSalesOrderByIds(retrySalesOrderIds)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result.isSuccess) {
          debugger;
          let reponse = result.responseModel;
          let exceptionResult: any;
          let IsExceptions: boolean = false;
          exceptionResult = "Retry process failed for Sales Order Id :";
          if (reponse = null && reponse != undefined) {

            let result = reponse;
            let exceptions = result.filter(x => x.isSuceess == false);
            if (exceptions.length > 0) {
              IsExceptions = true;
              exceptions.forEach(function (value) {
                exceptionResult = exceptionResult + value.brightreeId + ", ";
              });
            }
          }
          if (IsExceptions) {
            this.showErrorMessage(exceptionResult);
          }
          else {
            this.showSuccessMessage('Request executed successfully');
          }

          this.getFailedToAutoSyncSalesOrders();

          this.loading = false;
        }
        else {
          this.loading = false;
          this.showErrorMessage('Failed to execute request.');
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }


  UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId) {

    this.loading = true;
    this.importExportService.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result.isSuccess) {
          let reponse = result.responseModel;
          if (reponse.isSucesss)
            this.showSuccessMessage('Request execute successfully');
          else
            this.showErrorMessage(reponse.errorStatusMessage);

          this.getFailedToAutoSyncSalesOrders();

          this.loading = false;
        }
        else {

          this.loading = false;
          this.showErrorMessage('Failed to execute request.');
        }
      }, (httpResponseError) => {

        this.loading = false;
      });
  }


  RefreshDetails() {
    this.getFailedToAutoSyncSalesOrders();

  }

  async RetryBulkProcess() {



    if (!this.selectedFailedToSyncSalesOrder || this.selectedFailedToSyncSalesOrder.length <= 0) {
      this.showWarningMessage('Please select sales Orders(s).');
      return;
    }

    let IsRetrySalesOrder: boolean;
    let IsUpdateProcess: boolean;

    let SalesOrderIds: number[] = [];
    let BrightreeInfoIds: number[] = [];
    this.selectedFailedToSyncSalesOrder.forEach(item => {
      let ProcessTypeDisplayname = item.processType;
      let crmSalesOrderAppId = item.crmSalesOrderAppId;
      let crmSalesorderId = item.crmSalesorderId;
      let schappointmentId = item.schappointmentId;
      let schappointmentDetailId = item.schappointmentDetailId;
      let BrightreeInfoId = item.recordId;

      let Process = this.ProcessTypeOption.find(x => x.code == ProcessTypeDisplayname);
      if (Process) {
        let ProcessType = Process.name;
        switch (ProcessType) {
          case 'Auto': {
            SalesOrderIds.push(parseInt(crmSalesOrderAppId));
            IsRetrySalesOrder = true;
            break;
          }
          case 'Manual': {
            break;
          }
          case 'UpdatePODStaus':
          case 'ScheduleAppointment':
          case 'ReScheduleAppointment':
          case 'UpdateAppointmentStatus':
          case 'UpdateSalesOrderStatus': {
            BrightreeInfoIds.push(BrightreeInfoId);
            IsUpdateProcess = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    });


    let retrySalesOrderIds = new RetrySyncSalesOrderRequestModel();
    retrySalesOrderIds.brightreeInfoIds = SalesOrderIds;
    retrySalesOrderIds.crmUpdatedBy = this.loggedInUserId;

    let retryBrightreeInfoIds = new RetrySyncSalesOrderRequestModel();
    retryBrightreeInfoIds.brightreeInfoIds = BrightreeInfoIds;
    retryBrightreeInfoIds.crmUpdatedBy = this.loggedInUserId;


    if (IsRetrySalesOrder && IsUpdateProcess) {
      await forkJoin<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>, ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>
        (
          this.importExportService.RetrySalesOrderAppointmentDetailStatus(retryBrightreeInfoIds),
          this.importExportService.RetrySyncSalesOrderByIds(retrySalesOrderIds))
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((results) => {
          debugger;
          this.loading = false;
          if (!results) {
            return;
          }
          let exceptionResult: any;
          let IsExceptions: boolean = false;

          exceptionResult = "Retry process failed for Sales Order Id(s) :";
          if (results[0].responseModel != null && results[0].responseModel != undefined) {

            let result = results[0].responseModel;
            let exceptions = result.filter(x => x.isSuceess == false);
            if (exceptions.length > 0) {
              IsExceptions = true;
              exceptions.forEach(function (value) {
                exceptionResult = exceptionResult + value.brightreeId + ", ";
              });
            }
          }
          if (results[1].responseModel != null && results[1].responseModel != undefined) {
            let result = results[0].responseModel;
            let exceptions = result.filter(x => x.isSuceess == false);
            if (exceptions.length > 0) {
              IsExceptions = true;
              exceptions.forEach(function (value) {
                exceptionResult = exceptionResult + value.brightreeId + ", ";
              });
            }
          }
          if (IsExceptions) {
            this.showErrorMessage(exceptionResult);
          }
          else {
            this.showSuccessMessage('Request executed successfully');
          }
          this.selectedFailedToSyncSalesOrder = [];
          this.getFailedToAutoSyncSalesOrders();

        })
        .catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
    else if (IsRetrySalesOrder) {

      this.loading = true;
      this.importExportService.RetrySyncSalesOrderByIds(retrySalesOrderIds)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            debugger;
            let reponse = result.responseModel;
            let exceptionResult: any;
            let IsExceptions: boolean = false;
            exceptionResult = "Retry process failed for Sales Order Id(s) :";
            if (reponse != null && reponse != undefined) {

              let result = reponse;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (IsExceptions) {
              this.showErrorMessage(exceptionResult);
            }
            else {
              this.showSuccessMessage('Request executed successfully');
            }
            this.selectedFailedToSyncSalesOrder = [];
            this.getFailedToAutoSyncSalesOrders();

            this.loading = false;
          }
          else {
            this.loading = false;
            this.showErrorMessage('Failed to execute request.');
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });

    }
    else {

      this.loading = true;
      this.importExportService.RetrySalesOrderAppointmentDetailStatus(retryBrightreeInfoIds)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            debugger;
            let reponse = result.responseModel;
            let exceptionResult: any;
            let IsExceptions: boolean = false;
            exceptionResult = "Retry process failed for Sales Order Id(s) :";
            if (reponse != null && reponse != undefined) {

              let result = reponse;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (IsExceptions) {
              this.showErrorMessage(exceptionResult);
            }
            else {
              this.showSuccessMessage('Request executed successfully');
            }
            this.selectedFailedToSyncSalesOrder = [];
            this.getFailedToAutoSyncSalesOrders();

            this.loading = false;

          }
          else {

            this.loading = false;
            this.showErrorMessage('Failed to execute request.');
          }
        }, (httpResponseError) => {

          this.loading = false;
        });
    }

  }



  RetryProcess(item: FailedToSyncSalesOrderReport) {
    debugger;
    let ProcessTypeDisplayname = item.processType;
    let crmSalesOrderAppId = item.crmSalesOrderAppId;
    let crmSalesorderId = item.crmSalesorderId;
    let schappointmentId = item.schappointmentId;
    let schappointmentDetailId = item.schappointmentDetailId;
    let BrightreeInfoId = item.recordId;

    let Process = this.ProcessTypeOption.find(x => x.code == ProcessTypeDisplayname);
    if (Process) {
      let ProcessType = Process.name;
      switch (ProcessType) {
        case 'Auto': {
          //this.ReImportSO(crmSalesOrderAppId);/
          this.RetrySalesOrderProcess(crmSalesOrderAppId);
          break;
        }
        case 'Manual': {
          //this.ReImportSO(crmSalesOrderAppId);
          break;
        }
        case 'UpdatePODStaus': {
          this.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId);
          break;
        }
        case 'ScheduleAppointment': {
          this.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId);
          break;
        }
        case 'ReScheduleAppointment': {
          this.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId);
          break;
        }
        case 'UpdateAppointmentStatus': {
          this.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId);
          break;
        }
        case 'UpdateSalesOrderStatus': {
          this.UpdateSalesOrderAppointmentDetailStatus(BrightreeInfoId);
          break;
        }

        default: {
          break;
        }
      }
    }

  }




  async InactiveRecords() {
    if (!this.selectedFailedToSyncSalesOrder || this.selectedFailedToSyncSalesOrder.length <= 0) {
      this.showWarningMessage('Please select sales Orders(s).');
      return;
    }

    let IsRetrySalesOrder: boolean;
    let IsUpdateProcess: boolean;

    let SalesOrderIds: number[] = [];
    let BrightreeInfoIds: number[] = [];
    this.selectedFailedToSyncSalesOrder.forEach(item => {
      let ProcessTypeDisplayname = item.processType;
      let crmSalesOrderAppId = item.crmSalesOrderAppId;
      let crmSalesorderId = item.crmSalesorderId;
      let schappointmentId = item.schappointmentId;
      let schappointmentDetailId = item.schappointmentDetailId;
      let BrightreeInfoId = item.recordId;
      let recordId = item.id;
      let Process = this.ProcessTypeOption.find(x => x.code == ProcessTypeDisplayname);
      if (Process) {
        let ProcessType = Process.name;
        switch (ProcessType) {
          case 'Auto': {
            SalesOrderIds.push(parseInt(crmSalesOrderAppId));
            IsRetrySalesOrder = true;
            break;
          }
          case 'Manual': {
            SalesOrderIds.push(parseInt(crmSalesOrderAppId));
            IsRetrySalesOrder = true;
            break;
          }
          case 'UpdatePODStaus':
          case 'ScheduleAppointment':
          case 'ReScheduleAppointment':
          case 'UpdateAppointmentStatus':
          case 'UpdateSalesOrderStatus': {
            BrightreeInfoIds.push(BrightreeInfoId);
            IsUpdateProcess = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    });


    let retrySalesOrderIds = new RetrySyncSalesOrderRequestModel();
    retrySalesOrderIds.brightreeInfoIds = SalesOrderIds;
    retrySalesOrderIds.crmUpdatedBy = this.loggedInUserId;

    let retryBrightreeInfoIds = new RetrySyncSalesOrderRequestModel();
    retryBrightreeInfoIds.brightreeInfoIds = BrightreeInfoIds;
    retryBrightreeInfoIds.crmUpdatedBy = this.loggedInUserId;


    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete records?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }

    if (IsRetrySalesOrder && IsUpdateProcess) {
       forkJoin<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>, ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>
        (
          this.importExportService.InactiveSalesOrderAppointmentDetailStatus(retryBrightreeInfoIds),
          this.importExportService.InactiveFailedSalesOrderByIds(retrySalesOrderIds))
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((results) => {
          debugger;
          this.loading = false;
          if (!results) {
            return;
          }
          let exceptionResult: any;
          let IsExceptions: boolean = false;

          exceptionResult = "Deletion process failed for Sales Order Id(s) :";
          if (results[0].responseModel != null && results[0].responseModel != undefined) {

            let result = results[0].responseModel;
            let exceptions = result.filter(x => x.isSuceess == false);
            if (exceptions.length > 0) {
              IsExceptions = true;
              exceptions.forEach(function (value) {
                exceptionResult = exceptionResult + value.brightreeId + ", ";
              });
            }
          }
          if (results[1].responseModel != null && results[1].responseModel != undefined) {
            let result = results[0].responseModel;
            let exceptions = result.filter(x => x.isSuceess == false);
            if (exceptions.length > 0) {
              IsExceptions = true;
              exceptions.forEach(function (value) {
                exceptionResult = exceptionResult + value.brightreeId + ", ";
              });
            }
          }
          if (IsExceptions) {
            this.showErrorMessage(exceptionResult);
          }
          else {
            this.showSuccessMessage('Request executed successfully');
          }
          this.selectedFailedToSyncSalesOrder = [];
          this.getFailedToAutoSyncSalesOrders();

        })
        .catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
    else if (IsRetrySalesOrder) {

      this.loading = true;
      this.importExportService.InactiveFailedSalesOrderByIds(retrySalesOrderIds)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            debugger;
            let reponse = result.responseModel;
            let exceptionResult: any;
            let IsExceptions: boolean = false;
            exceptionResult = "Deletion process failed for Sales Order Id(s) :";
            if (reponse != null && reponse != undefined) {

              let result = reponse;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (IsExceptions) {
              this.showErrorMessage(exceptionResult);
            }
            else {
              this.showSuccessMessage('Request executed successfully');
            }
            this.selectedFailedToSyncSalesOrder = [];
            this.getFailedToAutoSyncSalesOrders();

            this.loading = false;
          }
          else {
            this.loading = false;
            this.showErrorMessage('Failed to execute request.');
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });

    }
    else {

      this.loading = true;
      this.importExportService.InactiveSalesOrderAppointmentDetailStatus(retryBrightreeInfoIds)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            debugger;
            let reponse = result.responseModel;
            let exceptionResult: any;
            let IsExceptions: boolean = false;
            exceptionResult = "Deletion process failed for Sales Order Id(s) :";
            if (reponse != null && reponse != undefined) {

              let result = reponse;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (IsExceptions) {
              this.showErrorMessage(exceptionResult);
            }
            else {
              this.showSuccessMessage('Request executed successfully');
            }
            this.selectedFailedToSyncSalesOrder = [];
            this.getFailedToAutoSyncSalesOrders();

            this.loading = false;

          }
          else {

            this.loading = false;
            this.showErrorMessage('Failed to execute request.');
          }
        }, (httpResponseError) => {

          this.loading = false;
        });
    }
    });
  }

  async InactiveRecord(item: FailedToSyncSalesOrderReport) {
    debugger;
    let ProcessTypeDisplayname = item.processType;
    let crmSalesOrderAppId = item.crmSalesOrderAppId;
    let crmSalesorderId = item.crmSalesorderId;
    let schappointmentId = item.schappointmentId;
    let schappointmentDetailId = item.schappointmentDetailId;
    let BrightreeInfoId = item.recordId;
    let recordId = item.id;

    let IsRetrySalesOrder: boolean;
    let IsUpdateProcess: boolean;

    let SalesOrderIds: number[] = [];
    let BrightreeInfoIds: number[] = [];
   
      let Process = this.ProcessTypeOption.find(x => x.code == ProcessTypeDisplayname);
      if (Process) {
        let ProcessType = Process.name;
        switch (ProcessType) {
          case 'Auto': {
            SalesOrderIds.push(parseInt(crmSalesOrderAppId));
            IsRetrySalesOrder = true;
            break;
          }
          case 'Manual': {
            SalesOrderIds.push(parseInt(crmSalesOrderAppId));
            IsRetrySalesOrder = true;
            break;
          }
          case 'UpdatePODStaus':
          case 'ScheduleAppointment':
          case 'ReScheduleAppointment':
          case 'UpdateAppointmentStatus':
          case 'UpdateSalesOrderStatus': {
            BrightreeInfoIds.push(BrightreeInfoId);
            IsUpdateProcess = true;
            break;
          }
          default: {
            break;
          }
        }
      }
    


    let retrySalesOrderIds = new RetrySyncSalesOrderRequestModel();
    retrySalesOrderIds.brightreeInfoIds = SalesOrderIds;
    retrySalesOrderIds.crmUpdatedBy = this.loggedInUserId;

    let retryBrightreeInfoIds = new RetrySyncSalesOrderRequestModel();
    retryBrightreeInfoIds.brightreeInfoIds = BrightreeInfoIds;
    retryBrightreeInfoIds.crmUpdatedBy = this.loggedInUserId;


    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete record?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }

      if (IsRetrySalesOrder && IsUpdateProcess) {
         forkJoin<ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>, ResultOfT<RetrySyncSalesOrderStatusResposeModel[]>>
          (
            this.importExportService.InactiveSalesOrderAppointmentDetailStatus(retryBrightreeInfoIds),
            this.importExportService.InactiveFailedSalesOrderByIds(retrySalesOrderIds))
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
          .toPromise()
          .then((results) => {
            debugger;
            this.loading = false;
            if (!results) {
              return;
            }
            let exceptionResult: any;
            let IsExceptions: boolean = false;

            exceptionResult = "Deletion process failed for Sales Order Id(s) :";
            if (results[0].responseModel != null && results[0].responseModel != undefined) {

              let result = results[0].responseModel;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (results[1].responseModel != null && results[1].responseModel != undefined) {
              let result = results[0].responseModel;
              let exceptions = result.filter(x => x.isSuceess == false);
              if (exceptions.length > 0) {
                IsExceptions = true;
                exceptions.forEach(function (value) {
                  exceptionResult = exceptionResult + value.brightreeId + ", ";
                });
              }
            }
            if (IsExceptions) {
              this.showErrorMessage(exceptionResult);
            }
            else {
              this.showSuccessMessage('Request executed successfully');
            }
            this.selectedFailedToSyncSalesOrder = [];
            this.getFailedToAutoSyncSalesOrders();

          })
          .catch((httpResponseError) => {
            this.loading = false;
            this.showErrorMessage(httpResponseError.message);
          });
      }
      else if (IsRetrySalesOrder) {

        this.loading = true;
        this.importExportService.InactiveFailedSalesOrderByIds(retrySalesOrderIds)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
          .subscribe((result) => {
            if (result.isSuccess) {
              debugger;
              let reponse = result.responseModel;
              let exceptionResult: any;
              let IsExceptions: boolean = false;
              exceptionResult = "Deletion process failed for Sales Order Id(s) :";
              if (reponse != null && reponse != undefined) {

                let result = reponse;
                let exceptions = result.filter(x => x.isSuceess == false);
                if (exceptions.length > 0) {
                  IsExceptions = true;
                  exceptions.forEach(function (value) {
                    exceptionResult = exceptionResult + value.brightreeId + ", ";
                  });
                }
              }
              if (IsExceptions) {
                this.showErrorMessage(exceptionResult);
              }
              else {
                this.showSuccessMessage('Request executed successfully');
              }
              this.selectedFailedToSyncSalesOrder = [];
              this.getFailedToAutoSyncSalesOrders();

              this.loading = false;
            }
            else {
              this.loading = false;
              this.showErrorMessage('Failed to execute request.');
            }
          }, (httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
            this.loading = false;
          });

      }
      else {

        this.loading = true;
        this.importExportService.InactiveSalesOrderAppointmentDetailStatus(retryBrightreeInfoIds)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
          .subscribe((result) => {
            if (result.isSuccess) {
              debugger;
              let reponse = result.responseModel;
              let exceptionResult: any;
              let IsExceptions: boolean = false;
              exceptionResult = "Deletion process failed for Sales Order Id(s) :";
              if (reponse != null && reponse != undefined) {

                let result = reponse;
                let exceptions = result.filter(x => x.isSuceess == false);
                if (exceptions.length > 0) {
                  IsExceptions = true;
                  exceptions.forEach(function (value) {
                    exceptionResult = exceptionResult + value.brightreeId + ", ";
                  });
                }
              }
              if (IsExceptions) {
                this.showErrorMessage(exceptionResult);
              }
              else {
                this.showSuccessMessage('Request executed successfully');
              }
              this.selectedFailedToSyncSalesOrder = [];
              this.getFailedToAutoSyncSalesOrders();

              this.loading = false;

            }
            else {

              this.loading = false;
              this.showErrorMessage('Failed to execute request.');
            }
          }, (httpResponseError) => {

            this.loading = false;
          });
      }
    });

  }


  onBack() {
    this.router.navigate(['admin/reports']);
  }

  onClear() {
    this.initializeSearchRequestModel();
    this.selectedStatus = { name: 'All', code: '1' };
    this.selectedProcessType = { name: 'All', code: 'All' };
    this.SSelectedDateRange = null;
    this.searchRequest.appointmentStatus = null;
    this.searchRequest.dictionaryFilter['CustomDateFilter'] = '';
    this.getFailedToAutoSyncSalesOrders();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.globalSearchService.changeGlobalBranch)
      this.globalSearchService.changeGlobalBranch.next(null);

    if (this.progress) {
      this.progress.complete();
    }
  }

  Export() {
    if (this.SSelectedDateRange) {
      if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
        let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
        let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

        if (startDate && endDate) {
          if (moment(startDate).toDate() > moment(endDate).toDate()) {
            this.showWarningMessage("From Date should not be greater than To Date.");
            return;
          }
        }

        if (startDate && endDate) {
          this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

          this.searchRequest.searchFromDateString = startDate;
          this.searchRequest.searchToDateString = endDate;
        }
      }
    }
    if (this.selectedStatus != null) {
      this.searchRequest.appointmentStatus = this.selectedStatus.name;
    }
    this.reportService.ExportFailedToAutoSyncSalesOrder(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'AutoSyncReport_' + new Date().getMilliseconds().toString() + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

}
