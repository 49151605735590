import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { TherapistService } from '../../servicies/therapist.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { TherapistSetAvailabilityRequestModel } from 'src/app/shared/models/response/therapist/therapist-availability-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormat } from 'src/app/shared/models/common/month-format';

@Component({
  selector: 'app-therapist-detail',
  templateUrl: './therapist-detail.component.html',
  styleUrls: ['./therapist-detail.component.sass']
})
export class TherapistDetailComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private therapistId: string;
  title = 'Therapist Detail';
  searchRequestModel: SearchRequestModel;
  therapistInfo: UserInfoResponseModel;
  therapistAvail: TherapistSetAvailabilityRequestModel;
  appDateFormat: DateFormatModel;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private therapistService: TherapistService,
    messageService: MessageService,
    private webStorageService: WebStorageService) {
    super(messageService);
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
    
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit() {
    this.therapistInfo = new UserInfoResponseModel();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = params['id'];
        this.getTherapist();
        //this.getTherapistAvailability();
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  onEdit() {
    this.router.navigate(['/admin/therapists/edit/', this.therapistId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack() {
    this.router.navigate(['admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  private getTherapist() {
    if (!this.therapistId) {
      return;
    }
    this.loading = true;
    this.therapistService.getTherapistById(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserInfoResponseModel>) => {
        this.processResult<UserInfoResponseModel>(result, () =>
        {
          if (result.isSuccess)
          {
            if (result.responseModel != undefined && result.responseModel != null)
            {
              this.therapistInfo = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getTherapistAvailability() {
    if (!this.therapistId) {
      return;
    }
    this.loading = true;
    this.therapistService.getTherapistAvailability(Number(this.therapistId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistSetAvailabilityRequestModel>) => {
        this.processResult<TherapistSetAvailabilityRequestModel>(result, () =>
        {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.therapistAvail = result.responseModel;
              //console.log(this.therapistAvail);
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ViewAvailability() {
    this.router.navigate(['/admin/calendar/view-therapist-availability/' + this.therapistId])
  }
}
