import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.sass']
})
export class InformationDialogComponent implements OnInit {
  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
  }

  ngOnInit() {
  }

  onYes() {
    this.dynamicDialogRef.close(true);
  }

  //onNo() {
  //  this.dynamicDialogRef.close(false);
  //}
}
