<header class="patient-header">
  <div class="patient-header__left">
    <div class="patient-header__brand">
      <img src="assets/img/appoint360-logo.svg" width="52" height="40" title="Appoint360" alt="Appoint360">
      <!-- <img src="assets/img/appoint360-patient-logo.svg" width="80" height="59" title="Appoint360" alt="Appoint360">-->
    </div>
    <div class="patient-header__client-logo">
      <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" title={{imageTitle}}>
    </div>
  </div>

  <div class="patient-header__right">
    <ul class="patient-header__email-phone">
      <li class="patient-header__phone branch-name">
        <span>{{DMEBranch}}</span>
      </li>
      <li class="patient-header__phone">
        <a href="tel:{{DMENumber}}">
          <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.28761 4.93081C1.04823 3.31754 2.15988 1.86843 3.85807 1.33751C4.15942 1.24323 4.48457 1.27007 4.76726 1.41256C5.04996 1.55504 5.26892 1.80246 5.37954 2.10439L5.92471 3.59198C6.01248 3.83129 6.02835 4.09198 5.97029 4.34059C5.91223 4.5892 5.78288 4.81438 5.59886 4.98724L3.97713 6.50689C3.89719 6.58195 3.83762 6.67693 3.80424 6.78256C3.77085 6.88819 3.76479 7.00087 3.78663 7.10961L3.80167 7.1763L3.84052 7.34301C4.04221 8.15304 4.34951 8.93161 4.75416 9.65775C5.19495 10.4294 5.74197 11.1321 6.3784 11.7442L6.42853 11.7904C6.50949 11.8639 6.60769 11.9147 6.71356 11.9379C6.81942 11.961 6.92934 11.9557 7.03261 11.9225L9.12933 11.2467C9.36762 11.1701 9.62283 11.1681 9.86221 11.241C10.1016 11.3139 10.3142 11.4583 10.4728 11.6557L11.4654 12.8881C11.879 13.4011 11.8289 14.1551 11.3539 14.6078C10.0543 15.8479 8.26708 16.1018 7.02384 15.0798C5.49989 13.8219 4.21519 12.2878 3.23394 10.5542C2.24472 8.8218 1.58396 6.91396 1.28635 4.93081H1.28761ZM5.10131 7.19425L6.44482 5.93237C6.81308 5.58681 7.072 5.13651 7.18835 4.63929C7.30469 4.14206 7.27315 3.6206 7.09778 3.14186L6.55386 1.65427C6.33121 1.04672 5.89054 0.548911 5.32166 0.262266C4.75278 -0.0243789 4.09849 -0.0782845 3.49211 0.111534C1.38285 0.771972 -0.310318 2.70712 0.0481182 5.12445C0.298773 6.8121 0.876532 8.95884 2.15111 11.2005C3.20976 13.0698 4.59557 14.7239 6.23929 16.08C8.10416 17.6125 10.5932 17.0893 12.2099 15.5478C13.1699 14.6322 13.2676 13.1113 12.4342 12.0751L11.4416 10.8414C11.1241 10.447 10.6987 10.1587 10.2199 10.0134C9.74119 9.86809 9.23094 9.87241 8.7546 10.0258L7.01381 10.5862C6.56435 10.112 6.17065 9.58565 5.84075 9.01783C5.52207 8.44378 5.27372 7.83174 5.10131 7.19554V7.19425Z" fill="white"/>
          </svg>
          <span>
            <!-- +1 (999) 123 - 4567 -->
            {{DMENumber | phone}}
          </span> 
        </a>
      </li>
      <li class="patient-header__email">
        <a href="mailto:{{DMEeMail}}">
          <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7143 0H1.28571C0.944722 0 0.617695 0.136964 0.376577 0.380761C0.135459 0.624558 0 0.955218 0 1.3V11.7C0 12.0448 0.135459 12.3754 0.376577 12.6192C0.617695 12.863 0.944722 13 1.28571 13H16.7143C17.0553 13 17.3823 12.863 17.6234 12.6192C17.8645 12.3754 18 12.0448 18 11.7V1.3C18 0.955218 17.8645 0.624558 17.6234 0.380761C17.3823 0.136964 17.0553 0 16.7143 0ZM15.3 1.3L9 5.707L2.7 1.3H15.3ZM1.28571 11.7V1.8915L8.63357 7.033C8.74118 7.10848 8.86903 7.14893 9 7.14893C9.13097 7.14893 9.25882 7.10848 9.36643 7.033L16.7143 1.8915V11.7H1.28571Z" fill="white"/>
          </svg>
          <span>
            <!-- mark.wood@example.com -->
            {{DMEeMail}}
          </span>
        </a>
      </li>
    </ul>
  </div>


  <div class="patient-header__branch-mobile">
    <strong>Branch:</strong> {{DMEBranch}}
  </div>
</header>
<p-toast position="bottom-right" [style]="{marginTop: '80px'}"></p-toast>

