import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ImportexportService } from '../../servicies/importexport.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { Subject } from 'rxjs';
import { UploadService } from '../../servicies/upload.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { WebStorageService } from '../../../shared/services/web-storage.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { OnBoardProcessHistoryModel, SettingsOnBoardingResponseModel } from 'src/app/shared/models/response/settings/settings-onboarding-response-model';
import { Result } from '../../../shared/models/response/result';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import * as moment from 'moment';
import { DateFormatModel } from '../../../shared/models/common/date-format-model';

@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.sass']
})
export class OnBoardingComponent extends BaseComponent implements OnInit, OnDestroy {
private unsubscriber = new Subject<boolean>();
progress: any;
selectedFile: any;
  @ViewChild('wipstateFile') wipstateFile: ElementRef;
  @ViewChild('doctorFile') doctorFile: ElementRef;
  @ViewChild('appointmentFile') appointmentFile: ElementRef;
  onUploadFinished: any;
  disableWipStateImportButton: boolean;
  wipStateFileProgress: number;
  verifyFilePath: any;
  historyModel: OnBoardProcessHistoryModel[];
  historyModelResponese: OnBoardProcessHistoryModel;
  templateDataStatusDefaultValue = "False";
  isRefresh: boolean = false;
  //for datatable//
  private hasPageHistory: boolean;
  searchRequestModel: SearchRequestModel;
  totalRecords: number = 0;
  appDateFormat: DateFormatModel;
  //for datatable//


  disableWipStateVerifyBtn: boolean = false;
  disableWipStateProcessBtn: boolean = false;
  disableDoctorVerifyBtn: boolean = false;
  disableDoctorProcessBtn: boolean = false;
  disableAppointmentVerifyBtn: boolean = false;
  disableAppointmentProcessBtn: boolean = false;
  loggedInUserId: any;

  constructor(private router: Router,
    private importExportService: ImportexportService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitzer: DomSanitizer,
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private formBuilder: FormBuilder
    ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.initializeSearchRequestModel();
  }

  async ngOnInit() {
    this.buildForm();
    this.setValuesToForm();

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;

    this.initializeSearchRequestModel();
    this.getHistory(this.isRefresh);
  }

  ngAfterViewInit() {
    // this.setFilters();
    this.hasPageHistory = false;
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
  formFields =
    {
      wipstateTemplateDataSelection: 'wipstateTemplateDataSelection',
      doctorTemplateDataSelection: 'doctorTemplateDataSelection',
      appointmentTemplateDataSelection:'appointmentTemplateDataSelection'
    }
  buildForm() {
    const formGroup = this.formBuilder.group({});
    this.form = formGroup;
    formGroup.addControl(this.formFields.wipstateTemplateDataSelection, this.formBuilder.control(''));
    formGroup.addControl(this.formFields.doctorTemplateDataSelection, this.formBuilder.control(''));
    formGroup.addControl(this.formFields.appointmentTemplateDataSelection, this.formBuilder.control(''));
  }

  private setValuesToForm() {
    this.form.reset();
    const form = this.form;
    form.get(this.formFields.wipstateTemplateDataSelection).setValue(this.templateDataStatusDefaultValue);
    form.get(this.formFields.doctorTemplateDataSelection).setValue(this.templateDataStatusDefaultValue);
    form.get(this.formFields.appointmentTemplateDataSelection).setValue(this.templateDataStatusDefaultValue);
  }

  commonPreVerifyCheck(event: any, files, module: string): boolean
  {
    let returnResult = false;
    if (files.length === 0) {
      return returnResult;
    }
    let fileExtension = files[0].name.split('.').pop();
    if (fileExtension !== "xlsx") {
      this.showErrorMessage("Please upload EXCEL file.");
      return returnResult;
    }

    let fileToUpload = <File>files[0];
    let fileName = fileToUpload.name;
    if (fileName.indexOf(module) > -1)
    {
      return returnResult = true;
    }
    else
    {
      fileToUpload = null;
      this.showErrorMessage("Please select valid template.");
      return returnResult;
    }

  }

  uploadAppointmentFile(event: any, files, module: string)
  {
    this.disableAppointmentVerifyBtn = false;
    this.disableAppointmentVerifyBtn = this.commonPreVerifyCheck(event, files, module);
  }

  uploadWipStateFile(event: any, files, module: string)
  {
    this.disableWipStateVerifyBtn = false;
    this.disableWipStateVerifyBtn=this.commonPreVerifyCheck(event, files, module);
  }

  uploadDoctorFile(event: any, files, module: string)
  {
    this.disableDoctorVerifyBtn = false;
    this.disableDoctorVerifyBtn =this.commonPreVerifyCheck(event, files, module);;
  }


  onUpload(event) {
    this.selectedFile = event.target.files[0];
  }

  private getApiUrl(url: string): string {
    return environment.apiBaseUrl + url;
  }

  onWipStateImport(event: any, files, module: string)
  {
    this.onSelectImport(event, files, module);
  }

  onDoctorImport(event: any, files, module: string)
  {
    this.onSelectImport(event, files, module);
  }

  onAppointmentImport(event: any, files, module: string)
  {
    this.onSelectImport(event, files, module);
  }

  onSelectImport(event: any, files, module: string)
  {

    if (files.length === 0) {
      return;
    }
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }

    let fileExtension = files[0].name.split('.').pop();
    if (fileExtension !== "xlsx")
    {
      this.showErrorMessage("Please upload EXCEL file.");
      return;
    }
    let fileToUpload = <File>files[0];
    const formData = new FormData();

    let fileName = fileToUpload.name;

    if (fileName.indexOf(module) > -1)
    {
      formData.append('file', fileToUpload, fileToUpload.name);
      formData.append('module', module);
      formData.append('loggedInUserId', this.loggedInUserId);
      
    }
    else
    {
      fileToUpload = null;
      this.showErrorMessage("Please select valid template.");
      return;
    }
    const headers = new HttpHeaders().set("clientId", clientId.toString()).append("Authorization", Authorization);
    this.http.post(this.getApiUrl('OnBoarding/VerifyImportData'), formData, { headers, reportProgress: true, observe: 'events' })
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(event => {

        if (event.type === HttpEventType.UploadProgress) {
          this.wipStateFileProgress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response)
        {
          this.totalRecords = this.totalRecords + 1;
          this.historyModelResponese = event.body["responseModel"];
          this.verifyFilePath = this.historyModelResponese.uploadFile;

          var responseModel = { uploadFile: this.historyModelResponese.uploadFile, logFile: this.historyModelResponese.logFile, sucessDataCount: this.historyModelResponese.sucessDataCount, badDataCount: this.historyModelResponese.badDataCount, dateTimeCreated: this.historyModelResponese.dateTimeCreated, totalrecords: this.totalRecords};
          this.historyModel.push(responseModel);
          this.disableProcessButtons(module, this.historyModelResponese.sucessDataCount, this.historyModelResponese.badDataCount);
      
        }
      });
  }

  disableProcessButtons(module: string, sucessDataCount: number, badDataCount: number)
  {
    this.showSuccessMessage(module + " verfication Result  " + ", Sucess record data :: " + sucessDataCount + " , Bad record data :: " + badDataCount + "");
    if (module == "WipStateTemplate")
    {
      if (badDataCount == 0)
      {
        this.disableWipStateProcessBtn = true;
      }
      else
      {
        this.disableWipStateProcessBtn = false;
      }

    }
    else if (module == "DoctorTemplate")
    {
      if (badDataCount == 0)
      {
        this.disableDoctorProcessBtn = true;
      }
      else {
        this.disableDoctorProcessBtn = false;
      }
    }
    else if (module == "AppointMentTemplate")
    {
      //this.disableAppointmentProcessBtn = true;
      if (badDataCount == 0)
      {
        this.disableAppointmentProcessBtn = true;
      }
      else
      {
        this.disableAppointmentProcessBtn = false;
      }
    }
  }

  getTemplateDataStatusValue(moduleName: string): any
  {
    let selectedvalue = "";
    switch (moduleName)
    {
        case 'WipStateTemplate':
        selectedvalue = this.form.get(this.formFields.wipstateTemplateDataSelection).value;
        break;
      case 'DoctorTemplate':
        selectedvalue = this.form.get(this.formFields.doctorTemplateDataSelection).value;
        break;
      case 'AppointMentTemplate':
        selectedvalue = this.form.get(this.formFields.appointmentTemplateDataSelection).value;
        break;
    }
    return selectedvalue;
  }

  processRecords(moduleName: string): void {

    let templateDataStatusValue = this.getTemplateDataStatusValue(moduleName);
    this.loading = true;
    const processRequest = this.importExportService.processImportData(this.verifyFilePath, moduleName, templateDataStatusValue);
    processRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess)
        {
          this.historyModelResponese = result["responseModel"];
          let processDataCount = this.historyModelResponese.sucessDataCount;
           this.showSuccessMessage(processDataCount +' Data(s) SucessFully Process');
        }
        else
        {
          this.loading = false;
          this.showErrorMessage(result.message);
        }
        console.log(result);

        this.afteProcessData(moduleName);

      }, (httpResponseError) =>
      {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
        this.afteProcessData(moduleName);
      });

  }

  afteProcessData(module: string)
  {
    if (module == "WIPStatusTemplate")
    {
      this.disableWipStateVerifyBtn = false;
        this.disableWipStateProcessBtn = false;
    }
    else if (module == "DoctorTemplate")
    {
        this.disableDoctorVerifyBtn = false;
        this.disableDoctorProcessBtn = false;
    }
    else if (module == "AppointMentTemplate")
    {
      this.disableAppointmentVerifyBtn = false;
      this.disableAppointmentProcessBtn = false;
    }
  }

  //history table data//

  private initializeSearchRequestModel() {

    if (this.hasPageHistory)
      return;

    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = 10; //+this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "processDate";
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 10;
      this.searchRequestModel.PageSize = 10;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    this.getHistory(false);
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'
    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }
    return this.totalRecords;
  }

  getHistory(isRefresh: boolean) {
    console.log(this.searchRequestModel);
    const processRequest = this.importExportService.GetOnBoardHistory(this.searchRequestModel, isRefresh);
    processRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.historyModel = result["responseModel"];
          console.log(this.historyModel);
          if (this.historyModel.length>0 && this.historyModel != undefined)
          {
            this.totalRecords = this.historyModel[0].totalrecords;
          }
        }
        else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      
      });
  }
     //history table data//

  downloadWipStateTemplate(templateName: string) {
    const data = { 'templateName': templateName};
    this.importExportService.onBoardWipStateTemplateDownload(data)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = "WIPStatusTemplate.xlsx"; //'FacilityTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadDoctorTemplate(templateName: string) {
    const data = { 'templateName': templateName };
    this.importExportService.onBoardDoctorTemplateDownload(data)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = templateName + ".xlsx"; //'FacilityTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadAppoitmentTemplate(templateName: string) {
    const data = { 'templateName': templateName };
    this.importExportService.onBoardAppoTemplateDownload(data)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = templateName + ".xlsx"; //'FacilityTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadFile(templateName: string, type: string) {
    const formData = new FormData();
    formData.append('templateName', templateName);
    formData.append('type', type);

   /* const data = { 'templateName': templateName,'type':type };*/
    this.importExportService.onBoardAppoFileDownload(formData)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = templateName + ".xlsx"; //'FacilityTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

}
