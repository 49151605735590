<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
      <a class="edit-btn" (click)="onEdit()">
        <i class="edit-icon-new"></i>
      </a>
    </h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <div class="form-outer">
    <div class="details-pages">
      <div class="details-pages__content">
        <span class="details-pages__content__block">
          <strong>Name</strong>
          <span class="value">
            {{userDetail.appUserLname}}, {{userDetail.appUserFname}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Role</strong>
          <span class="value" *ngFor="let userRole of userDetail?.userRole">
            {{userRole.role?.appRoleName}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Branch</strong>
          <!-- <span class="value" [ngClass]="BranchName ? 'show' : 'hide'" >
            <label *ngFor="let userBranch of userDetail?.userBranch; let i=index">
              {{i+1 }}.&nbsp;{{userBranch.crmBranch?.crmBranchName || '-'}}
              <span *ngIf="userDetail?.userBranch?.length>0">, &nbsp;</span>
            </label>
          </span> -->

          <span class="value value--table" >

            <ul [ngClass]="BranchName? 'show' : 'hide'">
              <li *ngFor="let userBranch of userDetail?.userBranch; let i=index"> {{userBranch.crmBranch?.crmBranchName || '-'}}</li>
            </ul>
          </span>

          <!-- <a href="javascript:void(0)" class="more-btn" (click)="BranchNameclickEvent()">+ More</a> -->
          <a href="javascript:void(0)" class="more-btn" *ngIf="userDetail?.userBranch?.length > 1" (click)="BranchNameclickEvent()"> {{toggleLabel}}</a>
        </span>
        <span class="details-pages__content__block">
          <strong>Email</strong>
          <span class="value">
            <a>{{userDetail?.appUserEmail || '-'}}</a>
            <!--<a href="userDetail?.appUserEmail">{{userDetail?.appUserEmail || '-'}}</a>-->
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Phone Number</strong>
          <!-- <span class="value">{{userDetail?.appUserPhoneNumber || '-'}}</span> -->
          <span class="value">
            {{userDetail?.appUserPhoneNumber | slice:0:3}}-{{userDetail?.appUserPhoneNumber | slice:3:6}}<span *ngIf="userDetail?.appUserPhoneNumber">-</span>{{userDetail?.appUserPhoneNumber | slice:6:10}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Status</strong>
          <!-- <span class="value">{{userDetail.isActive ? 'Active' : 'Inactive'}}</span> -->
          <span class="value" [ngClass]="userDetail.isActive ? 'user-active' : 'user-inactive'">{{userDetail.isActive? 'Active' : 'Inactive'}}</span>
        </span>
      </div>
    </div>
  </div>


  
</div>
<!-- End Content -->
