import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { UserAuthResponseModel, UserAuthResponseModelMain } from 'src/app/shared/models/response/access-token-response-model';
import { AuthenticationRequest } from 'src/app/shared/models/request/authentication-request-model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { FeatureGroupResponseModel, featureResponseModel, userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { CompanyService } from '../services/company.service';
import { UserService } from '../services/user.service';
import { TimeZoneAvailableResponseModel } from '../models/response/time-zone-available-response-model';
import { MobileDetectionService } from 'src/app/shared/services/mobiledetection.service';
import { BrightreeSettingsResponseModel } from '../models/response/brightree-settings-response-model';

declare var require: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent extends BaseComponent implements OnInit, OnDestroy {

  hashVersion: string = require('../../../../package.json').hashVersion;

  title = 'Sign In';

  private unsubscriber = new Subject<boolean>();
  private returnUrl: string = '';
  private domainUrl: string = '';
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  featureGroups: FeatureGroupResponseModel[];
  appDateFormat: DateFormatModel;
  userToken: UserAuthResponseModel;
  dateFormat: string = '';
  timeZone: string = '';
  dtFormat: string = '';
  isMobile: boolean;
  isRestrictMobileUsage: boolean = false;
  isGoogleMap: boolean = false;
  formFields =
    {
      username: 'username',
      password: 'password',
      rememberMe: 'rememberMe'
    };
  initialLoading: boolean = false;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    private titleService: Title,
    private featureGroupService: FeatureGroupService,
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    messageService: MessageService,
    private userService: UserService,
    private companyService: CompanyService,
    private mobileDetectionService: MobileDetectionService) {
    super(messageService);
    this.isMobile = this.mobileDetectionService.isMobile();

    this.titleService.setTitle("Appoint360");
    if (this.authenticationService.isAuthenticated()) {
      this.userToken = this.authenticationService.getCurrentUserToken();
      this.redirectAfterSignIn(this.authenticationService.getCurrentUserToken().roleName);
    }
    else
      this.buildForm();
  }

  async ngOnInit() {
    this.appDateFormat = new DateFormatModel();
    this.domainUrl = location.origin.replace("http://", "").replace("https://", "");
    this.authenticationService.getClinetDetails(this.domainUrl).pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<ConfigResponseModel>) => {
        this.processResult<ConfigResponseModel>(result, () => {
          if (result && result.isSuccess) {

            if (result.responseModel) {
              this.imageData = 'data:image/png;base64, ' + result.responseModel.companyLogo;
              this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
              this.imagealt = result.responseModel.companyName;
              this.imageTitle = result.responseModel.companyName;
              this.webStorageService.storeClientConfig(result.responseModel);
              this.isGoogleMap = result.responseModel.isGoogleMap;
            }
          }
        });
      });
    this.companyService.clogoSubject.subscribe((dta) => {
      //alert(dta);
      this.imageData = Constants.imagePrefix + dta;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
    });
    this.buildForm();
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(params => {
        this.returnUrl = params['returnUrl'];
      });

  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  onSubmit() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    this.loading = true;
    this.loadingCustom = true;
    const authRequest = new AuthenticationRequest();

    authRequest.userName = this.form.get(this.formFields.username).value;
    authRequest.password = this.form.get(this.formFields.password).value;
    const rememberMe = this.form.get(this.formFields.rememberMe).value;

    if (rememberMe) {
      const expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + 7);

      this.cookieService.set(Constants.cookie.username, authRequest.userName, expiredDate,undefined,undefined,true);
      this.cookieService.set(Constants.cookie.password, authRequest.password, expiredDate,undefined,undefined,true);
    }
    else {
      this.cookieService.delete(Constants.cookie.username);
      this.cookieService.delete(Constants.cookie.password);
    }

    this.authenticationService.signIn(authRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserAuthResponseModelMain>) => {
        if (!result.isSuccess) {
          this.loadingCustom = false;
        }
        this.processResult<UserAuthResponseModelMain>(result, async () => {
          if (result && result.isSuccess) {
            this.userToken = result.responseModel.userAuthResponseModel;
            this.webStorageService.storeCurrentUserToken(result.responseModel.userAuthResponseModel);
            this.webStorageService.storeCurrentUserAuthToken(result.responseModel.userAuthTokenResponse);
            this.webStorageService.storeUserBranch(result.responseModel.userAuthResponseModel.branchId);
            this.webStorageService.storeAppHashVersionValue(this.hashVersion);
            this.webStorageService.storeIsSession(true);
            await this.getDateFormat();
            await this.getTimeZone();
            await this.GetTimeZoneListAsync();

            this.appDateFormat = new DateFormatModel();
            this.appDateFormat.dateFormat = this.dateFormat;
            this.appDateFormat.timeZone = this.timeZone;
            this.appDateFormat.primeFormat = this.dtFormat;
            this.webStorageService.deleteDateFormat();
            this.webStorageService.storeDateFormat(this.appDateFormat);
            await this.getFeatureGroups();
            await this.getUserModuleFeatureGroups();
            await this.unlockAppts();
            await this.getDefaultModeofAppointment();
            await this.GetBrightreeSetings();
            this.loadingCustom = false;
            this.redirectAfterSignIn(result.responseModel.userAuthResponseModel.roleName);
            this.authenticationService.onSignInOut.next(result.responseModel.userAuthResponseModel.roleName);

          }
          else {
            this.showErrorMessage(result.message);
            this.loading = false;
            this.loadingCustom = false;
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.loadingCustom = false;
      });
  }


  private buildForm() {
    let username = '';
    let password = '';
    let rememberMe = false;

    const formGroup = this.formBuilder.group({});

    if (this.cookieService.check(Constants.cookie.username) &&
      this.cookieService.check(Constants.cookie.password)) {
      username = this.cookieService.get(Constants.cookie.username);
      password = this.cookieService.get(Constants.cookie.password);
      rememberMe = true;
    }

    formGroup.addControl(this.formFields.username, this.formBuilder.control(username, { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control(password, { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.rememberMe, this.formBuilder.control(rememberMe));

    this.form = formGroup;
  }

  private redirectAfterSignIn(userType: string) {
    if (this.returnUrl)
      this.router.navigate([this.returnUrl]);
    else {
      var url = "";
      if (this.userToken) {
        if (this.userToken.isDashboard) {
          url = 'dashboard';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isAppointments) {
          url = 'appointments';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isCalendar) {
          url = 'calendar';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isUsers) {
          url = 'users';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isDoctors) {
          url = 'doctors';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isTherapists) {
          url = 'therapists';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isFacilities) {
          url = 'facilities';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isImport) {
          url = 'importexport';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isReport) {
          url = 'reports';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }
        else if (this.userToken.isSettings) {
          url = 'settings';
          if (this.isMobile) {
            this.isRestrictMobileUsage = true;
          }
        }


        //if(this.isMobile && userType == 'Therapist' && !this.isGoogleMap){
        //  this.isRestrictMobileUsage = true;
        //}


        if (!this.isRestrictMobileUsage) {
          this.router.navigate([this.authenticationService.getRedirectUrlAfterSignIn(userType, url)]);
        }
      }
      else {
        this.router.navigate([this.authenticationService.getRedirectUrlAfterSignIn(userType, 'dashboard')]);
      }
    }
  }

  private async getDateFormat() {
    await this.settingsService.getDateFormatFromSetting(Constants.dateTimeFormat)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel) {
              this.dateFormat = result.responseModel.schSettingValue;
              this.dtFormat = result.responseModel.dtNgFormat;

            }
          }
        });
      });
  }

  private async getFeatureGroups() {

    await this.featureGroupService.getPermission()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<featureResponseModel[]>(result, () => {

          this.webStorageService.deletePermissions();
          this.webStorageService.storePermissions(result.responseModel);
        });
      });
  }

  private async getUserModuleFeatureGroups() {

    await this.featureGroupService.getUserPermission()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<userModuleFeatureResponseModel[]>(result, () => {

          this.webStorageService.deleteUserPermissions();
          this.webStorageService.storeUserPermissions(result.responseModel);
        });
      });
  }

  private async unlockAppts() {
    await this.settingsService.unlockAppointments()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel) {
          }
        }
      });
  }

  private GetPrimeNgFormat(dtFormats: string) {
    this.settingsService.getPrimeNgDate(dtFormats)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise().then((result) => {
        this.processResult<string>(result, () => {
          if (result && result.isSuccess) {

            if (result.responseModel) {
              this.dtFormat = result.responseModel;
            }
          }
        });
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getTimeZone() {
    await this.settingsService.getSettingsByKey(Constants.timeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel)
              this.timeZone = result.responseModel.schSettingValue;
          }
        });
      });
  }

  private async GetTimeZoneListAsync() {
    await this.userService.GetTimeZoneListAsync()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<TimeZoneAvailableResponseModel[]>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel)
              this.webStorageService.storeTimeZoneList(result.responseModel);
          }
        });
      });
  }
  private async getDefaultModeofAppointment() {
    await this.settingsService.getSettingsByKeyRefresh(Constants.defaultModeofAppointment)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              var defaultModeofAppointment = result.responseModel.schSettingValue;
            this.webStorageService.setdefaultModeofAppointment(defaultModeofAppointment);
          }
        });
      });
  }

  private async GetBrightreeSetings() {
    await this.settingsService.getBrightreeSetings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.initialLoading = false))
      .toPromise()
      .then((result) => {
        this.processResult<BrightreeSettingsResponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel)
              this.webStorageService.storeBrightreeSettings(result.responseModel);
          }
        });
      });
  }


  backToLogin() {
    this.isRestrictMobileUsage = false;
  }
}
