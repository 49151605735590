<!-- Start Header -->
<app-therapist-header></app-therapist-header>
<!-- End Header -->
<section class="therapist-module-outer">
    <div class="therapist-title-block">
      <h1 class="therapist-title-block__title">{{title}}</h1>
      <div class="therapist-title-block__right-block">
        <!--<button class="btn btn--primary" [routerLink]="['/therapist/my-availability']">My Availability</button>
          <span>   &nbsp;</span>-->
          <button class="btn btn--primary" (click)="back()">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.6111 1.40283L1 4.90109L4.6111 8.39935" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 4.90106L6.77777 4.90106C10.7666 4.90106 14 8.0334 14 11.8976V12.5972" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            My Appointments
          </button>
      </div>
    </div>
    
    <div class="therapist-content-block">
      <app-change-password class="therapist-changes-password"></app-change-password>     
    </div>
</section>
<p-toast position="bottom-right"></p-toast>
