import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { AppointmentConfirmationInfo } from 'src/app/shared/models/response/appointment/appointment-confirmation-info';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TwilioDeviceObject, AppoinmentDialogueParametersObject } from 'src/app/shared/models/common/twilio-device-object';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { TwillioCallService } from 'src/app/admin/servicies/twillioCall.service';
import { MessageService } from 'primeng/api';
import { Subject, Subscription, interval } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-appointment-thankyou',
  templateUrl: './appointment-thankyou.component.html',
  styleUrls: ['./appointment-thankyou.component.sass']
})
export class AppointmentThankyouComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() appointmentConfirmationInfo: AppointmentConfirmationInfo;
  @Input() salesOrder: SalesOrderInfoResponseModel;
  @Input() twillioDeviceConnectionObject: TwilioDeviceObject;
  @Output() appintmentBooked: EventEmitter<number> = new EventEmitter();
  appDateFormat: DateFormatModel;
  twilioDeviceConnection: TwilioDeviceObject;
  device: any;
  connection: any;
  connectionStatus: string;
  isCall: boolean = false;
  callId: number;
  callSID: string;
  displayHangUp: boolean = false;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  constructor(private webStorageService: WebStorageService, private appointmentService: AppointmentService,
    private twillioCallService: TwillioCallService,
    private globalSearchService: GlobalSearchService, messageService: MessageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit() {

    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {
      if (twillioObject) {
        this.twillioDeviceConnectionObject = twillioObject;
        this.isCall = true;
        this.twilioDeviceConnection = this.twillioDeviceConnectionObject;
        this.device = this.twillioDeviceConnectionObject.device;
        this.connection = this.twillioDeviceConnectionObject.connection;
        this.connectionStatus = this.twillioDeviceConnectionObject.status;
        this.callId = this.twillioDeviceConnectionObject.callId;
        this.callSID = this.twillioDeviceConnectionObject.callSID;
      }
      else {
        this.isCall = false;
        this.twilioDeviceConnection = null;
      }

    });

  }
  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === 'appointmentConfirmationInfo') {

        if (changes[property].currentValue)
          this.appointmentConfirmationInfo = changes[property].currentValue;
      }
      if (property === 'salesOrder') {
        if (changes[property].currentValue)
          this.salesOrder = changes[property].currentValue;
      }
      if (property === 'twillioDeviceConnectionObject') {
       
        if (changes[property].currentValue)
          this.twillioDeviceConnectionObject = changes[property].currentValue;
        if (this.twillioDeviceConnectionObject) {
          this.isCall = true;
          this.twilioDeviceConnection = this.twillioDeviceConnectionObject;
          this.device = this.twillioDeviceConnectionObject.device;
          this.connection = this.twillioDeviceConnectionObject.connection;
          this.connectionStatus = this.twillioDeviceConnectionObject.status;
          this.callId = this.twillioDeviceConnectionObject.callId;
          this.callSID = this.twillioDeviceConnectionObject.callSID;
        }
        else {
          this.isCall = false;
          this.twilioDeviceConnection = null;
        }
      }
    }
  }
  async Hangup() {
    console.log('Hanging up...');
    if (this.device) {
      this.device.disconnectAll();
    }
    //if (this.callId == 0) {
    //  await this.SaveCall("S");
    //}
    //else {
    //  await this.UpdateCall();
    //}
    this.displayHangUp = false;
    this.isCall = false;
    this.twillioDeviceConnectionObject = null;
    this.webStorageService.deleteTwilioDeviceObject();
    this.twillioCallService.twillioCall.next(null);

  }

  async SaveCall(saveDate: string) {

    let appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (this.connection)
      this.callSID = this.connection.parameters.CallSid;
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {

      appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      //this.isRescheduledRequest ? this.appointment.schAppointmentId : this.salesOrder.crmSalesOrderId;
      if (this.appointmentConfirmationInfo.isRescheduledRequest)
        appointmentCallResponseModel.schAppointmentId = this.appointmentConfirmationInfo.schAppointmentId;
      appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      appointmentCallResponseModel.schAppointmentCallBy = currentUserToken.userId;
      appointmentCallResponseModel.schAppointmentCallByPerson = currentUserToken.userFullName;
      appointmentCallResponseModel.schAppointmentCallByRole = currentUserToken.roleName;
      appointmentCallResponseModel.schAppointmentCallListId = 0;
      appointmentCallResponseModel.callSID = this.callSID;
      await this.appointmentService.SaveCall(appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.isSuccess)
          {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.callId = result.responseModel.schAppointmentCallId;
            }
          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }
  async UpdateCall() {
   
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    let appointmentCallResponseModel = new AppointmentCallResponseModel();
    if (currentUserToken && currentUserAuthToken.token) {
      appointmentCallResponseModel.schAppointmentCallId = this.callId;
      //this.isRescheduledRequest ? this.appointment.schAppointmentId : this.salesOrder.crmSalesOrderId;
      if (this.appointmentConfirmationInfo.isRescheduledRequest)
        appointmentCallResponseModel.schAppointmentId = this.appointmentConfirmationInfo.schAppointmentId;
      else
        appointmentCallResponseModel.crmSalesOrderId = this.salesOrder.crmSalesorderId;
      appointmentCallResponseModel.modifiedByUserInfoId = currentUserToken.userId;
      appointmentCallResponseModel.callSID = this.callSID;
      await this.appointmentService.UpdateCallStatus(appointmentCallResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.responseModel) {
          }
          else {
            this.showWarningMessage('Error occurred while updating call information.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }
  backToAppointmentScreen() {
    if (this.twillioDeviceConnectionObject && this.isCall)
      this.displayHangUp = true;
    else
      this.appintmentBooked.emit();
  }
}
