<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/settings']"><i class="back-new"></i>
        Back</a>
    </div>
  </div>
  <div class="form-content-outer">
    <div class="form-outer">
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="col">
          <div class="form__group">
            <label class="form__label placehold">Company Name</label>
            <input type="text" formControlName="companyName" class="form__control placehold" pInputText>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.companyName, 'required') || hasError(formFields.companyName, 'whitespace')">{{validationMessages.companyName.required}}</span>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.companyName, 'validCharacters')">{{validationMessages.companyName.validCharacters}}</span>
          </div>
          <div class="form__group">
            <label class="form__label placehold">Contact Name</label>
            <input type="text" formControlName="contactName" class="form__control placehold" pInputText>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.contactName, 'required') || hasError(formFields.contactName, 'whitespace')">{{validationMessages.contactName.required}}</span>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.contactName, 'validCharacters')">{{validationMessages.contactName.validCharacters}}</span>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label placehold">Address</label>
            <textarea class="form__textarea placehold" formControlName="address" pInputTextarea></textarea>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.address, 'required') || hasError(formFields.address, 'whitespace')">{{validationMessages.address.required}}</span>
          </div>
        </div>

        <div class="col">
          <div class="form__group">
            <label class="form__label placehold">Upload Logo </label>
            <div class="browse-import-btn">
              <!-- <input class="browse-btn" type="file" #LogoFile placeholder="Choose file" (change)="uploadLogoFile(LogoFile.files)" style="display:none;"> -->
              <input class="browse-btn placehold" #uploadE1 type="file" #LogoFile placeholder="Choose file"
                (change)="uploadLogoFile($event)" style="display:none;">
              <button type="button" class="btn placehold" (click)="LogoFile.click()">Browse</button>
            </div>
            <!-- <small>Logo Format : .jpg, .png, .SVG</small> -->
            <small class="placehold">Logo Format : .png</small>
            <!-- <small>Upload size : Max. 300KB</small> -->
            <ng-container *ngIf="isImageSaved">
              <div class="upload-img-box">
                <a href="javascript:void(0);" class="remove-img placehold" (click)="removeImage()"><i
                    class="ic delete small"></i> </a>

                <div class="upload-img-box__img placehold">
                  <img [(src)]="cardImageBase64" />
                </div>

                <ng-template #elseTemplate></ng-template>
              </div>
            </ng-container>

          </div>

          <div class="form__group">
            <label class="form__label placehold">Upload Favicon </label>
            <div class="browse-import-btn">
              <input class="browse-btn" #uploadE2 type="file" #FaviconFile placeholder="Choose file"
                (change)="uploadFavIcon($event)" style="display:none;">
              <button type="button" class="btn placehold" (click)="FaviconFile.click()">Browse</button>
            </div>
            <small class="placehold">Favicon Format : .ico</small>
            <ng-container *ngIf="isFaviconSaved">
              <div class="upload-img-box">
                <a href="javascript:void(0);" class="remove-img placehold" (click)="removeFavIcon()"><i
                    class="ic delete small"></i> </a>

                <div class="upload-img-box__img placehold">
                  <img [(src)]="faviconBase64" />
                </div>

                <ng-template #elseTemplate></ng-template>
              </div>
            </ng-container>

          </div>
        </div>

        <div class="col">
          <div class="form__group">
            <label class="form__label placehold">Default DME Email Address**</label>
            <input type="text" formControlName="DMEEmail" class="form__control placehold" pInputText>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.DMEEmail, 'required') || hasError(formFields.DMEEmail, 'whitespace')">{{validationMessages.DMEEmail.required}}</span>
            <span class="invalid-feedback"
              *ngIf="hasError(formFields.DMEEmail, 'email')">{{validationMessages.DMEEmail.email}}</span>
          </div>
          <div class="form__group">
            <label class="form__label placehold">Default DME Phone Number**</label>
            <p-inputMask mask="999-999-9999" formControlName="DMENumber" type="text" class="form__control placehold form__phone"
                         pInputText maxlength="15"></p-inputMask>
            <!--<input type="text" formControlName="DMENumber" class="form__control placehold" pInputText>-->
            <span class="invalid-feedback"
                  *ngIf="hasError(formFields.DMENumber, 'required') || hasError(formFields.DMENumber, 'whitespace')">{{validationMessages.DMENumber.required}}</span>
          </div>
          <small class="default-placehold-text">**These default values are used in notification templates for tag DME Email and DME Phone only if the branch level Email or Phone number is not available.</small>
        </div>

        <!-- <div class="col">
          
        </div> -->

        <div class="btns">
          <button class="btn btn--primary placehold" (click)="onSubmit()">Submit</button>
          <button class="btn btn--secondary placehold" routerLink="/admin/settings">Cancel</button>
        </div>
      </form>
    </div>


  </div>
</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>

<!-- End Content -->
