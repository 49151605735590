import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { WebStorageService } from '../services/web-storage.service';
import { UserPermissions } from '../enums';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService,
    private webStorageService: WebStorageService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
   
    if (this.authenticationService.isAuthenticated()) {

      if (!location.href.includes('login')) {
        if (location.href.includes('admin')) {
        
          if (this.webStorageService.getPermissions()) {
            const permission = this.webStorageService.getPermissions();
            let location1 = location.href.toLowerCase();
            location1 = location1.replace('-', '');
            location1 = location1.replace('-', '');
            let isActive = false;
            //permission.forEach((x) => {
            //  if (location1.includes(x.appModuleGroupFeatureName.toLowerCase())) {
            //    isActive = true;
            //  }
            //});
            const loggedInUser = this.webStorageService.getCurrentUserToken();
            let loggedInUserId = loggedInUser.userId;
            let userPerissionfeatures = this.webStorageService.getUserPermissions();
            if (userPerissionfeatures && userPerissionfeatures.length > 0) {

              let inPer1 = userPerissionfeatures.find(x => x.userId == loggedInUserId && x.appModuleFeatureName == UserPermissions.AccessToRecordingLogs);
              if (inPer1 && location1.includes('voicecallrecording'.toLowerCase())) {

                isActive = true;

              }

              let inPer2 = userPerissionfeatures.find(x => x.userId == loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewPatientNotifications
                || x.appModuleFeatureName == UserPermissions.AllowToViewSyncNotifications
                || x.appModuleFeatureName == UserPermissions.AllowToViewTherapistNotifications
                || x.appModuleFeatureName == UserPermissions.AllowToViewAlertNotifications);
              if (inPer2 && location1.includes('viewallnotifications'.toLowerCase())) {
                isActive = true;
              }
            }
            permission.every((x) => {
             
              if (location1.includes(x.appModuleFeatureName.toLowerCase()) || location1.includes(x.appModuleGroupFeatureName.toLowerCase())) {
                isActive = true;
                return false;
              }
              return true;
            });

           


            if (!isActive) {
              this.authenticationService.notAuthorized();
              return false;
            }
            else {
              return true;
            }
          }
          else {
            return true;
          }
        } else {
          return true;
        }
      }
      else {
        return true;
      }
    }
    else {
      this.authenticationService.signOut();
      return false;
    }
  }
}
