<app-patient-header></app-patient-header>
<section class="main-patient-outer">
  <div class="main-patient-outer__left">
    <div class="patient-sidebar">
      <div class="patient-sidebar__appointment-booking">
        <span class="patient-sidebar__appt-booking">Appointment Booking for</span>
        <h2 class="patient-sidebar__appt-patient-name">
          "{{patientName}}"
        </h2>
      </div>
      <div class="patient-sidebar__clinic-visit">
        <h3 class="patient-sidebar__title">{{appointmentTitle}}</h3>
      </div>

      <div *ngIf="patientBookingTypeId=='F' || patientBookingTypeId=='T'" class="patient-sidebar__steps-outer first-steps-complete second-steps-complete third-steps-complete">

        <ul class="steps">
          <li class="steps__item">
            <span class="steps__number">
              <span>1</span>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span class="steps__texts">
              <small>Step 1</small>
              {{appointmentSelectLabel}}
            </span>
          </li>
          <li class="steps__item">
            <span class="steps__number">
              <span>2</span>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span class="steps__texts">
              <small>Step 2</small>
              Select Date & Time
            </span>
          </li>
          <li class="steps__item">
            <span class="steps__number">
              <span>3</span>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span class="steps__texts">
              <small>Step 3</small>
              Appointment Summary
            </span>
          </li>
        </ul>

        <div class="process-bg">
          <div class="process-bar"></div>
        </div>
      </div>

      <div *ngIf="patientBookingTypeId=='R' || patientBookingTypeId=='SG'" class="patient-sidebar__steps-outer remote-steps first-steps-complete second-steps-complete third-steps-complete">

        <ul class="steps">
          <li class="steps__item">
            <span class="steps__number">
              <span>1</span>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span class="steps__texts">
              <small>Step 1</small>
              Select Date & Time
            </span>
          </li>
          <li class="steps__item">
            <span class="steps__number">
              <span>2</span>
              <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 7.86667L8 13L20 2" stroke="#ffffff" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </span>
            <span class="steps__texts">
              <small>Step 2</small>
              Appointment Summary
            </span>
          </li>
        </ul>

        <div class="process-bg">
          <div class="process-bar"></div>
        </div>
      </div>

    </div>
  </div>

  <div class="main-patient-outer__right">
    <!-- Title block -->
    <div class="page-title">
      <div class="page-title__title-left">
        <h1>
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0 15C0 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 0 15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4196 7.20644 30 11.0218 30 15C30 18.9782 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20644 28.4196 4.3934 25.6066C1.58035 22.7936 0 18.9782 0 15H0ZM14.144 21.42L22.78 10.624L21.22 9.376L13.856 18.578L8.64 14.232L7.36 15.768L14.144 21.422V21.42Z"
                  fill="#6F64A7" />
          </svg>

          Appointment Booked & Confirmed
        </h1>
      </div>
    </div>

    <!-- Facility block -->
    <div class="booked-appointment-outer">
      <div class="booked-appointment-block">
        <div class="booked-appointment-block__left">
          <div class="booked-appointment-block__your-appointment">
            <span class="booked-appointment-block__booked-info" *ngIf="patientBookingTypeId=='F'">Your Appointment has been booked at <strong>{{facilityName}}</strong>.</span>

            <!-- <span class="booked-appointment-block__booked-info" *ngIf="patientBookingTypeId == 'F'">Your appointment has been booked at <strong>{{facilityName}}</strong></span> -->
            <!-- Remote setup - Telemed Meeting booking -->
            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'R' && selectedRemoteAppointmentType == 'Telemed'">
              Your appointment has been
              booked via <strong>Remote Setup - {{selectedRemoteAppointmentType}}</strong>
            </span>

            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'R' && selectedRemoteAppointmentType == 'Telemed'">
              Therapist will call you on
              your registered mobile number on scheduled date & time.
            </span>

            <!-- Remote setup - Online Meeting booking -->
            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'R' && selectedRemoteAppointmentType == 'OnlineMeeting'">
              Your appointment has been
              booked via <strong>Remote Setup - {{selectedRemoteAppointmentType}}</strong>
            </span>

              <span class="booked-appointment-block__booked-info"
                    *ngIf="patientBookingTypeId == 'R' && selectedRemoteAppointmentType == 'OnlineMeeting'">
                We have sent you online meeting Link, Id and Password on your registered {{schIsReminderOptedEmail && schIsReminderOptedSms ? 'mobile number and email address': schIsReminderOptedEmail? 'email address':'mobile number'}}. <br />
                In case, you have not recieved it then, call us at <a href="tel:{{DMENumber}}">{{DMENumber | phone}}</a>
              </span>

            <!-- Remote setup -SElF Guided -->

            <span class="booked-appointment-block__booked-info" *ngIf="patientBookingTypeId == 'SG'">
              Your appointment has
              been booked via <strong>
                Remote Setup -
                Self Guided
              </strong>
            </span>

            <span class="booked-appointment-block__booked-info" *ngIf="patientBookingTypeId == 'SG'">
              Therapist will call
              you on your registered mobile number on scheduled
              date & time.
            </span>

            <!-- Therapist - Online Meeting / Facility  booking -->

            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'T' && isTherapistFacilityType">
              Your appointment has
              been booked at <strong>{{facilityName}}</strong> with <strong>{{therapistName}}.</strong>
            </span>

            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'T' && isTherapistRemoteType">
              Your appointment has
              been booked at <strong>{{facilityName}}</strong> with via <strong>
                Remote Setup -
                {{selectedRemoteAppointmentType}}
              </strong>
            </span>

            <span class="booked-appointment-block__booked-info"
                  *ngIf="patientBookingTypeId == 'T'  && selectedAppointmentTypePopUp =='Remote Setup'">
              Therapist will call
              you
              on your registered mobile number on scheduled
              date & time.
            </span>
          </div>

          <div class="booked-appointment-block__info">
            <h3 class="booked-appointment-block__title">Patient Booking Details are as below:</h3>

            <ul class="booked-appointment-block__patient-info">
              <li>
                <strong>Name</strong>
                {{patientName}}
              </li>
              <li>
                <strong>Email</strong>
                {{email}}
              </li>
              <li>
                <strong>Home Number</strong>
                {{patientPhone | phone}}
              </li>
              <li>
                <strong>Mobile Number</strong>
                {{patientMobileNumber | phone}}
              </li>
            </ul>

            <div class="booked-appointment-block__facility-info">
              <div class="appointment-status">
                <h4 class="date-time-title">Appointment Status</h4>
                <p class="date-times appointment-status__status">Booked</p>
              </div>
              <div *ngIf="isTherapistFacilityType || patientBookingTypeId=='F'">
                <span class="booked-appointment-block__miles">
                  {{distance}}
                </span>
                <h3 class="booked-appointment-block__facility-name">
                  {{facilityName}}
                </h3>
                <address class="booked-appointment-block__facility-address">
                  <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.09271 5.06006C7.50368 5.06006 6.92787 5.23272 6.43811 5.55621C5.94834 5.8797 5.56662 6.33948 5.34121 6.87743C5.11579 7.41537 5.05681 8.0073 5.17173 8.57838C5.28664 9.14946 5.57029 9.67402 5.9868 10.0857C6.40331 10.4975 6.93398 10.7779 7.51169 10.8914C8.08941 11.005 8.68823 10.9467 9.23243 10.7239C9.77662 10.5011 10.2418 10.1238 10.569 9.63963C10.8963 9.15549 11.0709 8.5863 11.0709 8.00404C11.07 7.22352 10.756 6.47522 10.1976 5.92331C9.63931 5.3714 8.88231 5.06095 8.09271 5.06006ZM8.09271 9.47603C7.7982 9.47603 7.51029 9.3897 7.26541 9.22795C7.02053 9.06621 6.82967 8.83632 6.71696 8.56734C6.60425 8.29837 6.57476 8.0024 6.63222 7.71687C6.68968 7.43133 6.8315 7.16905 7.03976 6.96318C7.24801 6.75732 7.51335 6.61713 7.8022 6.56033C8.09106 6.50353 8.39047 6.53269 8.66257 6.6441C8.93467 6.75551 9.16724 6.94418 9.33086 7.18624C9.49449 7.42831 9.58182 7.71291 9.58182 8.00404C9.58137 8.3943 9.42433 8.76844 9.14517 9.04439C8.866 9.32035 8.48751 9.47558 8.09271 9.47603Z"
                          fill="#868686" />
                    <path d="M13.8156 2.34254C12.4006 0.94417 10.5117 0.11348 8.51359 0.0107968C6.51544 -0.0918867 4.54965 0.540715 2.9956 1.78651C1.44155 3.0323 0.409411 4.80297 0.0983971 6.75678C-0.212617 8.71058 0.219536 10.709 1.31145 12.3664L6.93781 20.9045C7.06301 21.0945 7.23421 21.2506 7.43587 21.3587C7.63754 21.4667 7.86329 21.5233 8.09268 21.5233C8.32206 21.5233 8.54782 21.4667 8.74948 21.3587C8.95115 21.2506 9.12234 21.0945 9.24755 20.9045L14.8741 12.3664C15.8873 10.8289 16.335 8.99395 16.1423 7.16898C15.9496 5.34402 15.1281 3.64 13.8156 2.34254ZM13.6264 11.5629L8.0927 19.9601L2.55895 11.5629C0.865092 8.99249 1.22839 5.55263 3.42277 3.38337C4.03603 2.77715 4.76408 2.29626 5.56535 1.96818C6.36661 1.64009 7.22541 1.47122 8.0927 1.47122C8.95999 1.47122 9.81879 1.64009 10.6201 1.96818C11.4213 2.29626 12.1494 2.77715 12.7626 3.38337C14.957 5.55263 15.3203 8.99249 13.6264 11.5629Z"
                          fill="#868686" />
                  </svg>
                  <p class="mb-0" *ngIf="facilityAddressLine1"><span>{{facilityAddressLine1}}</span></p>
                  <p class="mb-0" *ngIf="facilityAddressLine2"><span>{{facilityAddressLine2}}</span></p>
                  <p class="mb-0" *ngIf="facilityCity ||facilityState"><span>{{facilityCity}}</span>, <span>{{facilityState}}</span></p>
                  <p class="mb-0" *ngIf="facilityCountry || facilityZipCode"><span>{{facilityCountry}}</span> - <span>{{facilityZipCode}}</span></p>
                  <!-- {{facilityAddress}} -->
                </address>

                <div class="booked-appointment-block__clinic-description-block">
                  <h3 class="booked-appointment-block__clinic-description-block-title">Clinic Description</h3>
                  <div class="booked-appointment-block__clinic-description-block-disc" [innerHTML]="crmBioData">
                    <!-- {{crmBioData}} -->
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="booked-appointment-block__date-time-duration">
            <div class="booked-appointment-block__date-time">
              <h4 class="date-time-title">Appointment Date & Time</h4>
              <p class="date-times">
                {{appointmentStartDate | date: dateFormat }} - {{appointmentStartDate | date: 'h:mm a' | lowercase}} <span class="timezone">{{appointmentTimeZone}}</span>
              </p>

            </div>
            <div class="booked-appointment-block__duration">
              <h4 class="date-time-title">Appointment Duration</h4>
              <p class="date-times">
                {{duration}}
              </p>
            </div>
            <div class="booked-appointment-block__therapist" *ngIf="patientBookingTypeId == 'T'">
              <h4 class="date-time-title">Therapist</h4>
              <p class="date-times">
                {{therapistName}}
              </p>
            </div>

          </div>

          <div class="booked-appointment-block__appt-calendar">
            <div class="appointment-amount" *ngIf="ShowDueAmount">
              <h4 class="appointment-amount__title">Your Due Amount: </h4>
              <p class="appointment-amount__amount">{{dueAmount | currency}}</p>
            </div>
            <h4 class="date-time-title">You can add this appointment to your calendar:</h4>
            <p-dropdown class="select-box" panelStyleClass="patient-select-box" [options]="Addtocalendar"
                        [(ngModel)]="selectedName" optionLabel="name" (onChange)="onEventClick()"></p-dropdown>
          </div>


          <div class="booked-appointment-block__delivery-note" *ngIf="ShowDeliveryNote && salesOrderData?.crmSalesorderDeliveryNote?.length > 0">
            <div class="booked-appointment-block__delivery-note-block">
              <h4>Delivery Note</h4>
              <p>{{salesOrderData?.crmSalesorderDeliveryNote}}</p>
            </div>
          </div>


        </div>

        <div class="booked-appointment-block__right">
          <div *ngIf="isTherapistFacilityType || patientBookingTypeId=='F'">
            <h3 class="booked-appointment-block__title">Google Map Route</h3>
            <div class="map-outer">
              <agm-map class="map-outer__map" [latitude]="patientLatitude" [longitude]="patientLongitude" [zoom]="zoom" [disableDefaultUI]="false"
                       [zoomControl]="false">

                <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker(m.label, i)"
                            [latitude]="m.lat" [longitude]="m.lng" [label]="m.type"
                            (dragEnd)="markerDragEnd(m, $event)">

                  <agm-info-window>
                    <div class="facility-info map-facility-popup" style=" width: 100%;max-width:345px;">
                      <div class="facility-info__dropdown">
                        <h3>{{m.label | titlecase}}</h3>
                      </div>
                      <p class="facility-info__address">
                        <i class="location-icon"></i> {{m.address}}
                      </p>
                    </div>
                  </agm-info-window>
                </agm-marker>

              </agm-map>
            </div>
          </div>
          <div class="appointment-documents">
            <h4 class="appointment-documents__title">Classification Instructions</h4>
            <div class="appointment-documents__classification-documents" [innerHtml]='salesOrderData.crmClassificationDocuments'></div>

          </div>
        </div>

        <div class="booked-appointment-block__cancel-reschedul">
          <div>
            <p class="booked-appointment-block__cancel-reschedul-text">Click on button below if you want to Reschedule
              or Cancel your appointment.</p>
          </div>
          <div class="booked-appointment-block__cancel-reschedul-btn">
            <button class="btn btn--primary" (click)="onBack()">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7083 3.66667H17.7222V4.88889H19.5556V18.3333H2.44446V4.88889H4.27779V3.66667H2.29168C2.14882 3.66905 2.00784 3.69956 1.87677 3.75644C1.74571 3.81333 1.62714 3.89547 1.52783 3.99819C1.42852 4.1009 1.35041 4.22218 1.29798 4.35508C1.24554 4.48799 1.21981 4.62992 1.22223 4.77278V18.4494C1.21981 18.5923 1.24554 18.7342 1.29798 18.8671C1.35041 19 1.42852 19.1213 1.52783 19.224C1.62714 19.3267 1.74571 19.4089 1.87677 19.4658C2.00784 19.5227 2.14882 19.5532 2.29168 19.5556H19.7083C19.8512 19.5532 19.9922 19.5227 20.1232 19.4658C20.2543 19.4089 20.3729 19.3267 20.4722 19.224C20.5715 19.1213 20.6496 19 20.702 18.8671C20.7545 18.7342 20.7802 18.5923 20.7778 18.4494V4.77278C20.7802 4.62992 20.7545 4.48799 20.702 4.35508C20.6496 4.22218 20.5715 4.1009 20.4722 3.99819C20.3729 3.89547 20.2543 3.81333 20.1232 3.75644C19.9922 3.69956 19.8512 3.66905 19.7083 3.66667Z"
                      fill="white" />
                <path d="M4.88889 8.55556H6.11111V9.77778H4.88889V8.55556Z" fill="white" />
                <path d="M8.55556 8.55556H9.77778V9.77778H8.55556V8.55556Z" fill="white" />
                <path d="M12.2222 8.55556H13.4444V9.77778H12.2222V8.55556Z" fill="white" />
                <path d="M15.8889 8.55556H17.1111V9.77778H15.8889V8.55556Z" fill="white" />
                <path d="M4.88889 11.6111H6.11111V12.8333H4.88889V11.6111Z" fill="white" />
                <path d="M8.55556 11.6111H9.77778V12.8333H8.55556V11.6111Z" fill="white" />
                <path d="M12.2222 11.6111H13.4444V12.8333H12.2222V11.6111Z" fill="white" />
                <path d="M15.8889 11.6111H17.1111V12.8333H15.8889V11.6111Z" fill="white" />
                <path d="M4.88889 14.6667H6.11111V15.8889H4.88889V14.6667Z" fill="white" />
                <path d="M8.55556 14.6667H9.77778V15.8889H8.55556V14.6667Z" fill="white" />
                <path d="M12.2222 14.6667H13.4444V15.8889H12.2222V14.6667Z" fill="white" />
                <path d="M15.8889 14.6667H17.1111V15.8889H15.8889V14.6667Z" fill="white" />
                <path d="M6.11111 6.11111C6.27319 6.11111 6.42863 6.04673 6.54323 5.93212C6.65784 5.81751 6.72222 5.66208 6.72222 5.5V1.83333C6.72222 1.67126 6.65784 1.51582 6.54323 1.40121C6.42863 1.28661 6.27319 1.22222 6.11111 1.22222C5.94903 1.22222 5.7936 1.28661 5.67899 1.40121C5.56438 1.51582 5.5 1.67126 5.5 1.83333V5.5C5.5 5.66208 5.56438 5.81751 5.67899 5.93212C5.7936 6.04673 5.94903 6.11111 6.11111 6.11111Z"
                      fill="white" />
                <path d="M15.8889 6.11111C16.051 6.11111 16.2064 6.04673 16.321 5.93212C16.4356 5.81751 16.5 5.66208 16.5 5.5V1.83333C16.5 1.67126 16.4356 1.51582 16.321 1.40121C16.2064 1.28661 16.051 1.22222 15.8889 1.22222C15.7268 1.22222 15.5714 1.28661 15.4568 1.40121C15.3422 1.51582 15.2778 1.67126 15.2778 1.83333V5.5C15.2778 5.66208 15.3422 5.81751 15.4568 5.93212C15.5714 6.04673 15.7268 6.11111 15.8889 6.11111Z"
                      fill="white" />
                <path d="M7.94444 3.66667H14.0556V4.88889H7.94444V3.66667Z" fill="white" />
              </svg>
              Reschedule Appointment
            </button>
            <button class="btn btn--secondary" (click)="cancelAppointmentBtn()">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#ffffff" stroke-width="2" />
                <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#ffffff" stroke-width="2" />
              </svg>
              Cancel Appointment
            </button>
          </div>
          <div class="booked-appointment-block__appt-queries">
            If you have any queries or concerns then call us at <a href="tel:{{DMENumber}}">{{DMENumber | phone}}</a>.
          </div>
        </div>


      </div>
      <app-patient-footer class="confirmed-footer"></app-patient-footer>
    </div>
  </div>

  <!-- <p-dialog [modal]="true" [baseZIndex]="10000" styleClass="patient-popup" [(visible)]="RescheduleAppointment">
    <div class="patient-popup__outer">
      <h2 class="patient-popup__title">Reschedule Appointment</h2>
      <div class="patient-popup__content">
        Are you sure you want to reschedule your appointment booking?
        <div class="patient-popup-popup-button">
          <button class="btn btn--secondary" (click)="RescheduleAppointment = false">No, Close</button>
          <button class="btn btn--primary" (click)="onBack()">Yes, Confirm</button>
        </div>
      </div>
    </div>
  </p-dialog> -->

  <p-dialog [modal]="true" [baseZIndex]="10000" styleClass="patient-popup" [(visible)]="CancelAppointment">
    <div class="patient-popup__outer">
      <h2 class="patient-popup__title">Cancel Appointment</h2>
      <div class="patient-popup__content">
        <p>Are you sure you want to cancel your appointment booking?</p>
        <div class="form__group mb-0">
          <label class="form__label">Comment</label>
          <textarea class="form__textarea" pInputTextarea [(ngModel)]="cancelnote" maxlength="200" style="height:110px"></textarea>
        </div>
        <div class="patient-popup-popup-button">
          <button class="btn btn--secondary" (click)="CancelAppointment = false">No, Close</button>
          <button class="btn btn--primary" (click)="onCancel()">Yes, Confirm</button>
        </div>
      </div>
    </div>
  </p-dialog>
  <!-- cancelled-appointment -->
</section>

<!-- Loader -->
<div class="loader-block" *ngIf="loadingCustom" style="z-index: 99999999 !important;">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
