import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { DateFormatModel } from '../models/common/date-format-model';
import { BaseComponent } from '../components/base.component';
import { MessageService } from 'primeng/api';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { WebStorageService } from '../services/web-storage.service';
import { AppointmentSMSHistoryResponseModel } from '../models/response/appointment/appointment-smshistory-response-model';
import { finalize, takeUntil } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ResultOfT } from '../models/response/result';

@Component({
  selector: 'app-sms-logs',
  templateUrl: './sms-logs.component.html',
  styleUrls: ['./sms-logs.component.sass']
})
export class SmsLogsComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  expandedIndex: number = -1;
  loadingSMS: boolean = false;
  indexActionTab: number = 0;
  @Input() appointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel[];
  @Input() schIsReminderOptedSms: boolean;
  @Input() isAppointment: boolean;
  @Input() schAppointmentId: number;
  @Output() onPreferenceChange = new EventEmitter<string>();
  appDateFormat: DateFormatModel;
  smsTo: string;
  @Input() smsLogCount: number;

  constructor(private webStorageService: WebStorageService,
    private appointmentService: AppointmentService,
    messageService: MessageService,
    private dialougeService: DialogService) {
    super(messageService);
    //this.appointmentEmailHistoryResponseModel = new Array();
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit(): void {
    this.expandedIndex = -1;
  }

  handleSmsOptInOut(e) {
    let isChecked = e.checked;
    // let messasge = isChecked ? 'Opt-In' : 'Opt-Out'
    let messagetoDisplay = isChecked ? 'Are you sure you want to subscribe to SMS communication?' : 'Are you sure you want to unsubscribe from SMS communication?'
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        // message: `Are you sure you want to ${messasge} sms communication preference?`,
        message: `${messagetoDisplay}`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.schIsReminderOptedSms = !e.checked;
        return;
      }
      this.appointmentService.ChangePatientCommunicationPreferences(this.schAppointmentId, "SMS", isChecked, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage('SMS Communication Preference has been changed.');
              this.schIsReminderOptedSms = isChecked;
              this.onPreferenceChange.emit(isChecked);
            }
          }
          else {
            this.loadingSMS = false;
            if (result.message != undefined && result.message.length > 0) {
              this.showErrorMessage(result.message);
            }
          }
        });
    });
  }
  Collapse(index: number) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  isDisabledSendNotification(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel): boolean {
    if (selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType != "Patient")
      return true;
    else if (this.schIsReminderOptedSms == false && selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType === "Patient")
      return false;
    else
      return true;

  }

  ReSendNotificationTitle(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel): string {

    if (selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType != "Patient")
      return 'Resend SMS';
    else if (this.schIsReminderOptedSms == false && selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType === "Patient")
      return 'Opt-Out SMS';
    else
      return 'Resend SMS';

  }
  ForwardNotificationTitle(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel): string {

    if (selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType != "Patient")
      return 'Forward SMS';
    else if (this.schIsReminderOptedSms == false && selectedAppointmentSMSHistoryResponseModel?.appNotificationRoleType === "Patient")
      return 'Opt-Out SMS';
    else
      return 'Forward SMS';

  }

  // IsEmailForward() {
  //   this.IsEmailForwardDisplay = true;
  // }

  ResendSMS(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel, event, element) {


    if (this.isAppointment && !this.schIsReminderOptedSms && selectedAppointmentSMSHistoryResponseModel.appNotificationSmsstatus == 'Pending' && selectedAppointmentSMSHistoryResponseModel.appNotificationSmsstatusReason == "OptOut") {
      this.showInfoMessage('Please Opt-In SMS Communication Preference.');
      return;
    }

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to resend this SMS?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loadingSMS = true;
      this.sendSMS(selectedAppointmentSMSHistoryResponseModel, event, element);
    });
  }

  ForwardSMS(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel, event, element) {
    this.smsTo = this.smsTo.replaceAll('-', '').replaceAll('_', '');
    if (this.smsTo == undefined || this.smsTo.trim() == '') {
      this.showErrorMessage('Please add proper number.');
      return;
    }

    this.loadingSMS = true;
    let AppointmentSMSHistoryResponseModel = { ...selectedAppointmentSMSHistoryResponseModel };
    AppointmentSMSHistoryResponseModel.appNotificationSmstoNumber = this.smsTo;
    this.sendSMS(AppointmentSMSHistoryResponseModel, event, element);
  }

  CancelForwardSMS() {
    this.smsTo = '';
  }

  sendSMS(selectedAppointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel, event, element) {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);

    if (selectedAppointmentSMSHistoryResponseModel.appNotificationSmstoNumber.length > 0) {
      const smsArray = selectedAppointmentSMSHistoryResponseModel.appNotificationSmstoNumber.split(';');
      for (let i = 0; i < smsArray.length; i++) {
        if (smsArray[i].trim().length != 10 || !TEXT_REGEXP.test(smsArray[i].trim())) {
          this.showErrorMessage('Please provide valid mobile number.');
          this.loadingSMS = false;
          return;
        }
      }
    }

    this.appointmentService.resendSMS(selectedAppointmentSMSHistoryResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.loadingSMS = false;
            this.smsTo = '';
            this.showSuccessMessage('SMS has been sent.');
            element.hide(event);
            this.GetNotificationSMSLogsBySalesOrderId(selectedAppointmentSMSHistoryResponseModel.crmRefSalesOrderId);
          }
        }
        else {
          this.loadingSMS = false;
          if (result.message != undefined && result.message.length > 0) {
            this.showErrorMessage(result.message);
          }
        }
      });
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private GetNotificationSMSLogsBySalesOrderId(SalesOrderId) {
    this.loadingSMS = true;

    this.appointmentService.GetNotificationSMSLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingSMS = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentSMSHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentSMSHistoryResponseModel = result.responseModel;
              if (this.appointmentSMSHistoryResponseModel.length > 0) {

              }
              else {
                this.showInfoMessage("No record found");
              }
            }
          }
          this.loadingSMS = false;

        });
      }).catch((httpResponseError) => {
        this.loading = false;

        this.showErrorMessage(httpResponseError.message);
      });
  }
}
