import { skipLast } from 'rxjs/operators';

export class Constants {
  static readonly currentUserToken = 'currentUserToken';
  static readonly twilioDeviceObject = 'twilioDeviceObject';
  TwilioDeviceObject
  static readonly currentClientConfig = 'currentClientConfig';
  static readonly currentUserProfile = 'currentUserProfile';
  static readonly currentSelectedFacilities = 'currentSelectedFacilities';
  static readonly currentSearchRequestModel = 'currentSearchRequestModel';
  static readonly currentAppointmentConfirmation = 'currentAppointmentConfirmation';
  static readonly currentSalesOrderId = 'currentSalesOrderId';
  static readonly currentBranchId = 'currentBranchId';
  static readonly currentSalesOrderData = 'currentSalesOrderData';
  static readonly currentRolePermission = 'currentRolePermission';
  static readonly currentFeatureGroups = 'currentFeatureGroups';
  static readonly currentUserPermission = 'currentUserPermission';
  static readonly currentCallIndex = 'currentCallIndex';
  static readonly discardChangesMessage = 'Any unsaved changes will be lost. Are you sure want to continue?';
  static readonly sessionTimeOutMessage = 'Your session is expired. You will be redirected to Sign In.';
  static readonly datasetSelectionSessionTimeOutOutMessage = 'Your session is expired. You will be redirected to Geography selection.';
  static readonly reminderdays = 'PatientScheduleAppointmentReminderLeadTime';
  static readonly dateTimeFormat = 'DateTimeFormat';
  static readonly daysOfWeek = 'DaysOfWeek';
  static readonly timeZone = 'TimeZone';
  static readonly leadTimeForOnlineCancellations = 'LeadTimeForOnlineCancellations';
  static readonly bookingSlotFrequency = 'BookingSlotFrequency';
  static readonly bookingSlotDisplay = 'BookingSlotDisplay';
  static readonly recipients = 'Recipients';
  static readonly bookingOverdue = 'BookingOverdue';
  static readonly expiryPeriodOfAppointmentLink = 'PatientInvitationLinkExpiry';
  static readonly pause = 'PauseCallDuartion';
  static readonly allowTherapistToUpdateAppointmentStatus = 'AllowTherapistToUpdateAppointmentStatus';
  static readonly pluginSettingFTP = 'FTPProvider';
  static readonly pluginSettingEmail = 'EmailCommunicationProvider';
  static readonly pluginSettingSMS = 'SMSCommunicationProvider';
  static readonly pluginSettingVoiceCall = 'VoiceCallProvider';
  static readonly pluginSettingVoiceMail = 'VoiceMailProvider';
  static readonly pluginSettingCRMApp = 'RCMProvider';
  static readonly brightTreeSetting = 'BrightTreeSetting';
  static readonly patientScheduleAppointmentReminderLeadTime = 'PatientScheduleAppointmentReminderLeadTime';
  static readonly patientNotScheduleAppointmentReminderLeadTime = 'PatientNotScheduleAppointmentReminderLeadTime';
  static readonly therapistScheduleAppointmentReminderLeadTime = 'TherapistScheduleAppointmentReminderLeadTime';
  static readonly therapistNotScheduleAppointmentReminderLeadTime = 'TherapistNotScheduleAppointmentReminderLeadTime';
  static readonly userselectedbranch = 'userselectedbranch';
  static readonly appDateFormat = 'appDateFormat';
  static readonly randomNumber = 'randomNumber';
  static readonly globalSearchText = 'globalSearchText';
  static readonly redirectTo = 'redirectto';
  static readonly isSession = 'isSession';
  static readonly isSessionValue = 'isSessionValue';
  static readonly imagePrefix = 'data:image/png;base64, ';
  static readonly seprator = '###';
  static readonly httpReplace = 'http://';
  static readonly httpsReplace = 'https://';
  static readonly equalTo = '=';
  static readonly readyForSchedule = 'ready for scheduling';
  static readonly booked = 'booked';
  static readonly cancel = 'cancel';
  static readonly noshow = 'no show';
  static readonly voidreview = 'void review';
  static readonly complete = 'complete';
  static readonly void = 'void';
  static readonly currentPatientBookingTypeId = 'currentPatientBookingTypeId';
  static readonly currenRoutertUrl = 'currenRoutertUrl';
  static readonly defaultTimeZone = 'defaultTimeZone';
  static readonly currentSelectedTherapist = 'currentSelectedTherapist';
  static readonly wipStatusBtMap = 'wipStatusBtMap';
  static readonly currentTimeZoneList = 'currentTimeZoneList';
  static readonly currentDMENumber = 'dmeNumber';
  static readonly patientName = 'patientName';

  static readonly allowToCreatePSO = 'AllowToCreatePSO'
  static readonly allowToMergePSO = 'AllowToMergePSO'
  static readonly patientNoteKey = 'PatientNoteKey'
  static readonly patientNoteSubject = 'PatientNoteSubject'
  static readonly provisionalSearch = 'provisionalSearch'
  static readonly defaultModeofAppointment = "DefaultModeofAppointment"

  static readonly onlineAppointmentTypeNames = "onlineAppointmentTypeNames";
  static readonly patientSalesOrderSettings = "patientSalesOrderSettings";

  static readonly userBranches = 'userBranches';
  static readonly brightreeSettings = 'BrightreeSettings';
  static readonly currentMenuState = 'currentMenuState';
  static readonly currentUserAuthToken = 'currentUserAuthToken';
  static cookie = class {
    static readonly email = 'email';
    static readonly username = 'username';
    static readonly password = 'password';
  };
  static readonly appHashVersion = 'appHashVersion';

  static authorizationErros = class {
    static readonly invalidGrant = 'invalid_grant';

    static readonly userAlreadyLoggedIn = 'user_already_logged_in';

    static readonly userNotExist = 'user_not_exist';

    static readonly userIsInactive = 'user_is_inactive';

  };

  static formControlUpdateStrategy = class {
    static readonly change = 'change';

    static readonly blur = 'blur';

    static readonly submit = 'submit';
  };

  static userType = class {
    static readonly none = 'None';
    static readonly Admin = 'DME Admin';
    static readonly Therapist = 'Therapist';
    static readonly CSOperator = 'CS Operator';
    static readonly Patient = 'Patient';
    static readonly SuperAdmin = 'Super Admin';
  };

  static featureGroup = class {
    static readonly Dashboard = 'Dashboard';
    static readonly Appointments = 'Appointments';
    static readonly Calendar = 'Calendar';
    static readonly Users = 'Users';
    static readonly Resources = 'Resources';
    static readonly Doctors = 'Doctors';
    static readonly Therapists = 'Therapists';
    static readonly Facilities = 'Facilities';
    static readonly ImportExport = 'ImportExport';
    static readonly ImportExportLabel = 'Import / Export';
    static readonly Reports = 'Reports';
    static readonly Settings = 'Settings';
  };

  static requestIdentifier = class {
    static readonly None = 'None';
    static readonly SalesOrderRequest = 'SalesOrderRequest';
    static readonly PatientRequest = 'PatientRequest';
    static readonly AppointmentRequest = 'AppointmentRequest';
    static readonly FacilityRequest = 'FacilityRequest';
    static readonly TherapistRequest = 'TherapistRequest';
    static readonly BranchRequest = 'BranchRequest';
    static readonly TelehealthRequest = 'TelehealthRequest';
    static readonly CSOperatorReuqest = 'CSOperatorReuqest';
    static readonly DMEAdminRequest = 'DMEAdminRequest';
    static readonly DoctorRquest = 'DoctorRquest';
  };

  static SettingKeys = class {
    static readonly AllowTherapistToUpdateAppointmentStatus = 'AllowTherapistToUpdateAppointmentStatus';
    static readonly AllowTherapistToUpdateLicenseInformation = 'AllowTherapistToUpdateLicenseInformation';
    static readonly AllowDMEtoUpdateOrderNote = 'AllowDMEtoUpdateOrderNote';
    static readonly AllowDMEtoUpdateDeliveryNote = 'AllowDMEtoUpdateDeliveryNote';
    static readonly MapView = 'MapView';
    static readonly NumOfMonths = 'NumOfMonths';
    static readonly DMENumber = 'DMENumber';
    static readonly DueAmount = 'DueAmount';
    static readonly DMEEmail = 'DMEEmail';
    static readonly ReminderDays = 'PatientScheduleAppointmentReminderLeadTime';
    static readonly PauseTime = 'PauseCallDuartion';
    static readonly FacilityApt = 'FacilityApt';
    static readonly BookingRemoteApt = 'BookingRemoteApt';
    static readonly dateFormat = 'DateTimeFormat';
    static readonly timeZone = 'TimeZone';
    static readonly PatientInvitationLinkExpiry = 'PatientInvitationLinkExpiry';
    static readonly AllowUpdateAppointmentToBrightTreeSystem = 'AllowUpdateintotheBrightreesystemontheAppointmentconfirmation';
    static readonly PatientsDefaultAppointmentLocation = 'PatientsDefaultAppointmentLocation';
    static readonly AllowDMEtoUpdateDeliveryAddress = 'AllowDMEtoUpdateDeliveryAddress';
    static readonly CheckTherapistStateLicense = 'CheckTherapistStateLicense';
    static readonly DefaultWIPNeededDate = 'DefaultWIPNeededDate';
    static readonly WIPStateAssignmentType = 'WIPStateAssignmentType';
    static readonly UpdateWipStateOnRescheduled = 'UpdateWipStateOnRescheduled';
    static readonly AllowBlackOutDaysForRescheduledAppointment = 'AllowBlackOutDaysForRescheduledAppointment';
    static readonly EnableTheAutoReconcileOfAppointment = 'EnableTheAutoReconcileOfAppointment';
    static readonly TimeZone = 'TimeZone';
    static readonly DefaultModeofAppointment = 'DefaultModeofAppointment';
    static readonly SameOnlineLinkForMultipleRT = 'SameOnlineLinkForMultipleRT';
    static readonly AllowUpdatePODStatusToBrightTreeSystem = 'AllowUpdatePODStatusintotheBrightreesystemontheAppointmentconfirmation';
    static readonly AllowAppendOrderNoteToBrightTreeSystem = 'AllowAppendOrderNoteintotheBrightreesystemontheAppointmentconfirmation';
    static readonly EmailSendStartTime = 'EmailSendStartTime';
    static readonly EmailSendEndTime = 'EmailSendEndTime';
    static readonly DeliveryNote = 'DeliveryNote';

    static readonly AllowOnlineRescheduleAppointment = 'AllowOnlineRescheduleAppointment';
    static readonly AllowOnlineCancelAppointment = 'AllowOnlineCancelAppointment';

    static readonly PatientDefaultApptSlotDuration = 'PatientDefaultApptSlotDuration';
    static readonly PatientDefaultSlotDurationIfClassificationNotMapped = 'PatientDefaultSlotDurationIfClassificationNotMapped';
    static readonly PatientApptBasedOnClassificationSlotDuration = 'PatientApptBasedOnClassificationSlotDuration';

  };
  static SettingValues = class {
    static readonly billingaddress = 'billingaddress';
    static readonly deliveryaddress = 'deliveryaddress';
    static readonly appointmentdate = 'appointmentdate';
    static readonly currentdate = 'currentdate';
  };
}
