<!-- Start Content -->
<div class="content-area appointment-detail">
  <div class="title-block">
    <h1>{{title}}</h1>
    <div class="title-block__right">
      <!-- <a class="btn btn--secondary refresh-block" (click)="RefreshAppointmentPatientInfo()">
        <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z"/></svg>
        Refresh 
      </a> -->
      <div class="re-scheduled" *ngIf="salesOrderStatus?.length>0">
        <p-dropdown class="select-box scheduled-slect" [options]="salesOrderStatus"
          [(ngModel)]="selectedAppointmentOrderStatus" #statusDropdown placeholder="Status" optionLabel="wipStatesName"
          [showClear]="true" (onChange)="onStatusChange($event)" [disabled]="isStatusCompleted"></p-dropdown>
      </div>
      <a class="back-btn" (click)="onBack(returnSaleOrderStatus)"><i class="back-new"></i>
        Back</a>
    </div>
  </div>

  <div class="appointments-table-filer">
    <!--<button [disabled]="isCallStarted" class="btn btn--primary" (click)="toggleScheduleAppointment($event,RescheduleAppointment)"><i class="ic schedule schedule--white"></i>Call/Reschedule Appointment</button>-->
    <div class="">
      <button class="btn btn--primary" (click)="showScheduleAppointment()" *ngIf="isRescheduleButtonHide">
        <i class="calendar-icon-white"></i>Reschedule Appointment
      </button>
    </div>

    <!--<div class="callhangup-btn">
      <button *ngIf="isCallStarted" class="btn btn--primary" (click)="Hangup()">Hang Up</button>
      <button *ngIf="isCallStarted" class="btn btn--secondary" (click)="HangupVoiceCall()">Hang Up/Voice Mail</button>
    </div>-->

    <div class="right-side-block">
      <a class="refresh-block" (click)="RefreshAppointmentPatientInfo()" pTooltip="Sync details from Brightree"
        *ngIf="!isStatusCompleted">
        <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path fill="#000000"
            d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z" />
        </svg>
        Sync
      </a>

      <a class="history-logs" (click)="HistoryEvent()">
        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24">
          <path d="M12,19c-3.9,0-7-3.1-7-7s3.1-7,7-7c2,0,3.9,0.9,5.2,2.3l-1.8,0.3c-0.5,0.1-0.9,0.6-0.8,1.2c0.1,0.5,0.5,0.8,1,0.8
              c0.1,0,0.1,0,0.2,0l4.2-0.8c0.5-0.1,0.9-0.6,0.8-1.2L20,3.5c-0.1-0.5-0.6-0.9-1.2-0.8c-0.5,0.1-0.9,0.6-0.8,1.2l0.3,1.7
              C16.6,4,14.4,3,12,3C8.7,3,5.8,4.8,4.2,7.5c0,0,0,0,0,0c0,0,0,0,0,0C3.4,8.9,3,10.4,3,12s0.4,3.1,1.2,4.5c0,0,0,0,0,0c0,0,0,0,0,0
              C5,17.9,6.1,19,7.5,19.8c0,0,0,0,0,0c0,0,0,0,0,0C8.9,20.6,10.4,21,12,21c0.4,0,0.8-0.3,0.9-0.6C13,20.3,13,20.1,13,20
              c0-0.1,0-0.3-0.1-0.4C12.8,19.3,12.4,19,12,19z" />
          <circle cx="20" cy="12" r="1" />
          <circle cx="18.9" cy="16" r="1" transform="rotate(-60 18.928 16)" />
          <circle cx="16" cy="18.9" r="1" transform="rotate(-30 15.999 18.929)" />
          <path d="M13,11.6V9c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.3,0.1,0.5,0.3,0.7l2,2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3
              c0.4-0.4,0.4-1,0-1.4L13,11.6z" />
        </svg>
        History
      </a>
      <!-- (click)="smsDialog()" -->
      <a class="sms-logs" (click)="SMSSidebar()">
        <svg id="text-message-4642" xmlns="http://www.w3.org/2000/svg" width="45.19" height="42.235"
          viewBox="0 0 45.19 42.235">
          <g id="Group_7" data-name="Group 7" transform="translate(0 0)">
            <path id="Path_13" data-name="Path 13"
              d="M40.56,44.426a18.031,18.031,0,0,0,4.629-12.114A18.213,18.213,0,0,0,32.21,14.9,18.158,18.158,0,1,0,4.629,38.516L.294,42.851A1,1,0,0,0,1,44.565H13.629a18.159,18.159,0,0,0,13.4,5.91H44.185a1,1,0,0,0,.71-1.714ZM6.74,39.245a1,1,0,0,0,0-1.42,16.154,16.154,0,1,1,11.423,4.732H3.428Zm20.287,9.222a16.147,16.147,0,0,1-10.519-3.9h1.654A18.155,18.155,0,0,0,34.169,17.83a16.147,16.147,0,0,1,4.28,25.9,1,1,0,0,0,0,1.42l3.311,3.311Z"
              transform="translate(0 -8.24)" />
            <path id="Path_14" data-name="Path 14" d="M68.223,62.2H52.3a1,1,0,1,1,0-2.008H68.223a1,1,0,0,1,0,2.008Z"
              transform="translate(-42.101 -50.876)" />
            <path id="Path_15" data-name="Path 15" d="M68.223,89.57H52.3a1,1,0,1,1,0-2.008H68.223a1,1,0,0,1,0,2.008Z"
              transform="translate(-42.101 -73.341)" />
            <path id="Path_16" data-name="Path 16" d="M68.223,116.942H52.3a1,1,0,1,1,0-2.008H68.223a1,1,0,0,1,0,2.008Z"
              transform="translate(-42.101 -95.807)" />
            <path id="Path_17" data-name="Path 17" d="M60.4,144.314H52.3a1,1,0,1,1,0-2.008h8.1a1,1,0,1,1,0,2.008Z"
              transform="translate(-42.101 -118.272)" />
          </g>
        </svg>
        SMS Logs
      </a>

      <!-- (click)="emailDialog()" -->
      <a class="email-logs" (click)="EmailSidebar()">
        <svg id="mail-letter-2971" xmlns="http://www.w3.org/2000/svg" width="45.099" height="42.357"
          viewBox="0 0 45.099 42.357">
          <g id="Group_8" data-name="Group 8" transform="translate(0)">
            <path id="Path_18" data-name="Path 18"
              d="M45.062,18.806c-.006-.021-.012-.041-.019-.061a.965.965,0,0,0-.09-.2c-.007-.011-.011-.022-.018-.032a.992.992,0,0,0-.16-.185c-.015-.014-.031-.026-.047-.039a.991.991,0,0,0-.183-.119c-.009,0-.016-.012-.025-.016l-.011,0a.994.994,0,0,0-.241-.071c-.022,0-.043,0-.064-.007a.915.915,0,0,0-.1-.01H37.154V8.651a.991.991,0,0,0-.991-.991H8.936a.991.991,0,0,0-.991.991v9.416H.991a.933.933,0,0,0-.1.01l-.062.006a1,1,0,0,0-.241.071l-.011,0c-.01,0-.017.012-.026.017a1.011,1.011,0,0,0-.181.117c-.016.013-.033.026-.048.04a.986.986,0,0,0-.159.185c-.007.011-.011.022-.018.033a.971.971,0,0,0-.089.2c-.007.021-.014.04-.019.061A.965.965,0,0,0,0,19.059V49.026a.991.991,0,0,0,.991.991H44.108a.991.991,0,0,0,.991-.991V19.059A.979.979,0,0,0,45.062,18.806ZM41.434,20.05l-4.28,3.68V20.05ZM9.927,9.643H35.172V25.434L22.549,36.286,9.927,25.434ZM7.944,23.73l-4.28-3.68h4.28ZM43.116,48.036H1.982V21.218L21.029,37.593l.349-1.053a.991.991,0,0,0-.106,1.4c.357.415,1.4.106,1.4.106L43.116,21.218V48.036Z"
              transform="translate(0 -7.66)" />
            <path id="Path_19" data-name="Path 19"
              d="M84.721,67.815H97.876a.991.991,0,1,0,0-1.982H84.721a.991.991,0,0,0,0,1.982Z"
              transform="translate(-68.749 -55.783)" />
            <path id="Path_20" data-name="Path 20"
              d="M84.721,98.285H97.876a.991.991,0,0,0,0-1.982H84.721a.991.991,0,0,0,0,1.982Z"
              transform="translate(-68.749 -80.801)" />
            <path id="Path_21" data-name="Path 21"
              d="M84.721,37.342H97.876a.991.991,0,1,0,0-1.982H84.721a.991.991,0,0,0,0,1.982Z"
              transform="translate(-68.749 -30.762)" />
          </g>
        </svg>
        Email Logs
      </a>

      <a *ngIf="isSendInvitationPermission && isAllowToSendInvite && !isStatusCompleted" class="invite-schedule"
        href="javascript:void(0)" (click)="SendInvitationforAppointment()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
          <path
            d="M60.86,13.26a1,1,0,0,0,0-.28s0,0,0,0,0-.05,0-.08a1,1,0,0,0-.11-.19l-.11-.11-.08-.08-.06,0-.14-.07-.17-.06-.15,0h-.3L3.86,24.49a1,1,0,0,0-.25,1.86l15.56,8.11,2.51,15.73a1,1,0,0,0,.09.28l0,.06a1,1,0,0,0,.19.24l0,0a1,1,0,0,0,.22.14h.06l.06,0a1,1,0,0,0,.3,0h0A1,1,0,0,0,23,51l.09,0,.14-.06,10.92-6.72,11.23,7.38a1,1,0,0,0,1.49-.49l14-37.42a1,1,0,0,0,.06-.32S60.86,13.28,60.86,13.26Zm-35,23.91a1,1,0,0,0-.15.14l0,0a1,1,0,0,0-.17.31l0,0-2.56,8L21.18,34.36l28.13-14.5ZM52.65,15.89,20.08,32.68,7,25.85ZM24.44,47.77l2.62-8.24L32.27,43Zm21,1.42-10.68-7h0l-6.46-4.24L57.75,16.1Z"
            data-name="Layer 2" />
        </svg>
        Send Invite
      </a>
    </div>
  </div>

  <div class="scheduled-detials" *ngIf="isDataLoaded">
    <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
    <div class="scheduled-detials__content">

      <div class="scheduled-detials__left">
        <div class="scheduled-detials__left__block">
          <h2 class="scheduled-detials__left__block__title">
            {{appointment.salesOrderInfo.patientInfo?.crmPatientLastName}},
            {{appointment.salesOrderInfo.patientInfo?.crmPatientFirstName}}
            <!-- <a class="refresh-block" pTooltip="Refresh"
                (click)="RefreshAppointmentPatientInfo()"><i class="ic refresh-ic "></i></a> -->
          </h2>
          <span class="scheduled-detials__left__block__date">
            {{appointment.salesOrderInfo.patientInfo?.crmPatientBirthDate | date: appDateFormat.dateFormat}}
          </span>
          <div class="scheduled-detials__left__block__sales-order">
            <!-- <span><strong>Sales Order ID :</strong> {{appointment.salesOrderInfo?.crmSalesorderAppId}}</span> -->
            <!-- <span><strong>Patient ID :</strong> {{appointment.salesOrderInfo?.crmPatientId}}</span> -->
            <span>
              <strong>Sales Order ID :</strong> <a
                (click)="onNavigateBrightTreeSalesOrderUrl(appointment.salesOrderInfo?.crmSalesorderAppId)"
                href="javascript:void(0)">{{appointment.salesOrderInfo?.crmSalesorderAppId}}</a>
            </span>
            <span>
              <strong>Patient ID :</strong><a
                (click)="onNavigateBrightTreePatientUrl(appointment.salesOrderInfo?.patientInfo?.crmRcmBrightreeID)"
                href="javascript:void(0)">{{appointment.salesOrderInfo?.crmPatientId}}</a>
            </span>
            <span>
              <strong>Branch :</strong>
              {{appointment.salesOrderInfo?.crmBranch}}
            </span>
          </div>


          <div class="input-edit-table" [ngClass]="EmailEditStatus ? 'edit-box' : ''">
            <i class="email-ic-new"></i>
            <input type="text" class="form__control" pInputText maxlength="100" [(ngModel)]="patientEmail" />

            <div class="action-icon">
              <a (click)="EditEamil()" class="edit-icon" *ngIf="!isStatusCompleted">
                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                    <path id="Path_3" data-name="Path 3"
                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                      transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                    <path id="Path_4" data-name="Path 4"
                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                  </g>
                </svg>
              </a>

              <a (click)="saveEamil()" class="save-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="#6F64A7"
                    d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                </svg>
              </a>
              <a (click)="cancelEmail()" class="cancel-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                  <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                </svg>
              </a>
            </div>
          </div>
          <div class="input-edit-table" [ngClass]="MobileEditStatus ? 'edit-box' : ''">
            <i class="phone-icon-new-primary"></i>

            <p-inputMask mask="999-999-9999" placeholder="___-___-____" [(ngModel)]="patientMobileNumber"
              class="form__phone" pInputText maxlength="15"></p-inputMask>

            <div class="action-icon">
              <a (click)="EditMobilePhone()" class="edit-icon" *ngIf="!isStatusCompleted">
                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                    <path id="Path_3" data-name="Path 3"
                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                      transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                    <path id="Path_4" data-name="Path 4"
                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                  </g>
                </svg>
              </a>

              <a (click)="saveMobilePhone()" class="save-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="#6F64A7"
                    d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                </svg>
              </a>
              <a (click)="cancelMobilePhone()" class="cancel-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                  <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                </svg>
              </a>
            </div>
          </div>


          <div class="input-edit-table" [ngClass]="PhoneEditStatus ? 'edit-box' : ''">
            <i class="voice-call-icon-new-primary"></i>

            <p-inputMask mask="999-999-9999" placeholder="___-___-____" [(ngModel)]="patientPhone" class="form__phone"
              pInputText maxlength="15"></p-inputMask>
            <div class="action-icon">
              <a (click)="EditPhone()" class="edit-icon" *ngIf="!isStatusCompleted">
                <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                  <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                    <path id="Path_3" data-name="Path 3"
                      d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                      transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                    <path id="Path_4" data-name="Path 4"
                      d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                      transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="8" />
                  </g>
                </svg>
              </a>

              <a (click)="savePhone()" class="save-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="#6F64A7"
                    d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                </svg>
              </a>
              <a (click)="cancelPhone()" class="cancel-icon">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                  <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                </svg>
              </a>
            </div>
          </div>


          <p class="input-edit-table input-edit-table--no-edit-table">
            <i class="location-icon"></i>
            {{appointment.salesOrderInfo.patientInfo?.crmPatientAddress}}
          </p>
        </div>

        <div class="scheduled-detials__left__block scheduled-detials__left__block--modify">

          <h3 class="scheduled-detials__left__block__title">
            Order Note &nbsp;
            <a pTooltip="Add Order Note" (click)="OrderNoteClick()" class="edit-icon" *ngIf="!isStatusCompleted">
              <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                  <path id="Path_3" data-name="Path 3"
                    d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                    transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="8" />
                  <path id="Path_4" data-name="Path 4"
                    d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                    transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="8" />
                </g>
              </svg>
            </a>
          </h3>

          <p style="margin: .5rem 0 1.5rem 0;">
            {{appointment.salesOrderInfo?.crmSalesorderOrderNote}}
          </p>

          <h3 class="scheduled-detials__left__block__title">
            Delivery Note
            &nbsp;
            <a pTooltip="Add Delivery Note" (click)="DeliveryNoteClick()" class="edit-icon" *ngIf="!isStatusCompleted">
              <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574" viewBox="0 0 103.901 98.574">
                <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                  <path id="Path_3" data-name="Path 3"
                    d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                    transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="8" />
                  <path id="Path_4" data-name="Path 4"
                    d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                    transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="8" />
                </g>
              </svg>
            </a>

          </h3>

          <p style="margin: .5rem 0 1.5rem 0;">
            {{appointment.salesOrderInfo?.crmSalesorderDeliveryNote }}
          </p>

        </div>

        <div class="scheduled-detials__left__block--modify" *ngIf="appointment.salesOrderInfo.doctorInfo">
          <h2 class="scheduled-detials__left__block__title-main">Doctor Information</h2>
          <h3 class="scheduled-detials__left__block__title">
            {{appointment.salesOrderInfo.doctorInfo?.crmDoctorLastName}},
            {{appointment.salesOrderInfo.doctorInfo?.crmDoctorFirstName}}
          </h3>
          <p class="input-edit-table input-edit-table--no-edit-table"
            *ngIf="appointment.salesOrderInfo.doctorInfo.crmDoctorAddress">
            <i class="location-icon"></i>
            {{appointment.salesOrderInfo.doctorInfo?.crmDoctorAddress || '-'}}
          </p>





          <!-- <p class="flex" *ngIf="appointment.salesOrderInfo.doctorInfo.crmDoctorContactEmailAddress">
              <i class="ic email"></i>{{appointment.salesOrderInfo.doctorInfo?.crmDoctorContactEmailAddress}} -->

          <p class="input-edit-table input-edit-table--no-edit-table"
            *ngIf="appointment.salesOrderInfo.doctorInfo.crmDoctorContactEmailAddress">
            <i class="email-ic-new"></i>
            {{appointment.salesOrderInfo.doctorInfo?.crmDoctorContactEmailAddress}}

          </p>

          <!-- <p class="flex" *ngIf="appointment.salesOrderInfo.doctorInfo.crmDoctorContactNo">

              <i class="ic phone"></i><span *ngIf="appointment.salesOrderInfo.doctorInfo?.crmDoctorContactNo">
                {{appointment.salesOrderInfo.doctorInfo?.crmDoctorContactNo                | phone                }}
              </span> -->

          <p class="input-edit-table input-edit-table--no-edit-table"
            *ngIf="appointment.salesOrderInfo.doctorInfo.crmDoctorContactNo">
            <i class="voice-call-icon-new-primary"></i>
            <span
              *ngIf="appointment.salesOrderInfo.doctorInfo?.crmDoctorContactNo">{{appointment.salesOrderInfo.doctorInfo?.crmDoctorContactNo
              | phone}}</span>
          </p>

          <p class="input-edit-table input-edit-table--no-edit-table"
            *ngFor="let docfac of appointment.salesOrderInfo.doctorFacility">
            <i class="location-icon"></i>
            <span>{{docfac.crmFacility.crmFacilityName}}<br>{{docfac.crmFacility.crmFacilityAddress}}</span>
          </p>
        </div>

      </div>

      <div class="scheduled-detials__middle">
        <div class="scheduled-detials__middle__block">
          <h2 class="scheduled-detials__middle__block__title">Insurance & Policy Information</h2>
          <p-tabView class="appointment-detail">
            <p-tabPanel *ngFor="let ins of appointment.salesOrderInfo.salesOrderInsuranceInfo"
              header="{{ins.crmPayorLevel}}">
              <div class="insurance-info" *ngIf="ins.crmPayorLevel === 'Primary'  || ins.crmPayorLevel === 'Secondary'">
                <div class="insurance-info__block">
                  <span *ngIf="ins.crmInsurancePolicyNumber">
                    <strong>Policy No.</strong>
                    {{ins.crmInsurancePolicyNumber}}
                  </span>
                  <span *ngIf="!ins.crmInsurancePolicyNumber"><strong>Policy No.</strong>-</span>
                  <span *ngIf="ins.crmPolicyHolderName">
                    <strong>Company</strong>{{ins.crmPolicyHolderName}}
                  </span>
                  <span *ngIf="!ins.crmPolicyHolderName">
                    <strong>Company</strong>-
                  </span>
                  <span *ngIf="ins.crmInsurancePolicyEffectiveDate">
                    <strong>Effective Date</strong>
                    {{ins.crmInsurancePolicyEffectiveDate | date:appDateFormat.dateFormat}}
                  </span>
                  <span *ngIf="!ins.crmInsurancePolicyEffectiveDate"><strong>Effective Date</strong>-</span>
                  <span><strong>Insurance Verified</strong>{{ins.crmInsuranceVerified ? 'Yes' : 'No'}} </span>
                  <span><strong>Coverage Verified</strong> {{ins.crmCoverageVerified ? 'Yes' : 'No'}} </span>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel>
              <div class="no-info-available" *ngIf='salesOrder.salesOrderInsuranceInfo.length === 0'>
                <strong>No information available.</strong>
              </div>
            </p-tabPanel>
            <div class="insurance-info insurance-info--modify">
              <h2 class="insurance-info__title">Classification Information</h2>
              <div class="patient-info-middle">

                <div class="patient-info-middle__boxs center classification-edit">
                  <strong>Classification Type</strong>
                  <!--{{appointment.salesOrderInfo.crmClassification}}-->
                  <div class="input-edit-table" [ngClass]="ClassificationTypeEditStatus ? 'edit-box' : ''">
                    <p-dropdown class="select-box" appendTo="body" [(ngModel)]="selectedClassificationType"
                      [options]="classificationType" [showClear]="true" (onChange)="onClassificationTypeChange($event)"
                      optionLabel="crmClassificationDisplayName">
                    </p-dropdown>
                    <div class="action-icon">
                      <a (click)="EditClassificationType()" class="edit-icon" *ngIf="!isStatusCompleted">
                        <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                          viewBox="0 0 103.901 98.574">
                          <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                            <path id="Path_3" data-name="Path 3"
                              d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                              transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                            <path id="Path_4" data-name="Path 4"
                              d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                              transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                          </g>
                        </svg>
                      </a>
                      <a (click)="saveClassificationType()" class="save-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                        </svg>
                      </a>
                      <a (click)="cancelClassificationType()" class="cancel-icon">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                          <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="patient-info-middle__boxs right">
                  <strong>Default Duration</strong>
                  {{duration}} <i class="info-new" style="top: 33px"
                    pTooltip="{{appointment.salesOrderInfo?.crmClassificationDescription}}"></i>
                </div>
              </div>
            </div>

            <div class="insurance-info insurance-info--modify">
              <div class="patient-info-middle--modify" *ngIf="appointment.salesOrderInfo.salesOrderItemInfo?.length>0">
                <div class="patient-info-middle__boxs left full-width">

                  <div class="item-number">
                    <div class="item-number__number"><strong>Item No.</strong></div>
                    <div class="item-number__name"><strong>Item Name</strong></div>
                    <div class="item-number__amount"><strong>Amount</strong></div>
                  </div>

                  <div class="item-number__list" *ngFor="let col of appointment.salesOrderInfo.salesOrderItemInfo">
                    <div class="item-number__list__number">{{ col.crmSalesorderItemItemId }}</div>
                    <div class="item-number__list__name">{{ col.crmSalesorderItemName }}</div>
                    <div class="item-number__list__amount">{{ col.crmSalesorderItemChargeAmt | currency}}</div>
                  </div>
                </div>

              </div>

              <div class="patient-info-middle__btn">
                <!-- Amount Due -->
                <a href="javascript:void(0)">

                  <i class="ic dollar"></i> {{
                  appointment.salesOrderInfo.amountDue | currency
                  }}


                </a>
              </div>
            </div>



          </p-tabView>
        </div>
      </div>

      <div class="scheduled-detials__right">
        <div class="scheduled-detials__right__block">
          <h2 class="scheduled-detials__right__block__title">Appointment Information</h2>
          <div class="scheduled-detials__right__block__appointment-information">
            <div class="insurance-info__block">
              <span *ngIf="appointment.schAppointmentType=='Telehealth'">
                <strong>Type</strong> {{schAppointmentType}} - {{appointment.schRemoteAppointmentTypeDisplayName}}

                <a *ngIf="appointment?.schAppointmentType=='Telehealth' && isChangeRemoteAppointmentType && ((appointment?.schRemoteAppointmentType| lowercase) =='telemed' || (appointment?.schRemoteAppointmentType| lowercase) =='onlinemeeting')"
                  class="sms-logs type-convert" (click)="convertAppointmentType()"
                  pTooltip="Change To {{convertedRemoteAppointmentType}}" tooltipPosition="top">


                  <svg *ngIf="(appointment?.schRemoteAppointmentType| lowercase) =='telemed'"
                    xmlns="http://www.w3.org/2000/svg" width="47.328" height="55.386" viewBox="0 0 47.328 55.386">
                    <g id="convert_to_zoom" data-name="convert to zoom" transform="translate(-542 -244.614)">
                      <g id="switch-to-video" transform="translate(542 243.614)">
                        <path id="Path_25" data-name="Path 25"
                          d="M1.889,9.232a34.293,34.293,0,0,1,40.791-.28L40,9.489a1.183,1.183,0,0,0,.231,2.344,1.168,1.168,0,0,0,.233-.023l4.93-.986a1.182,1.182,0,0,0,.928-.928l.986-4.93a1.183,1.183,0,0,0-2.32-.464l-.558,2.791a36.675,36.675,0,0,0-43.95.042,1.183,1.183,0,0,0,1.413,1.9Z"
                          transform="translate(0 1)" />
                        <path id="Path_26" data-name="Path 26"
                          d="M45.439,79.6a34.293,34.293,0,0,1-40.791.28l2.684-.537a1.183,1.183,0,0,0-.464-2.32l-4.93.986a1.183,1.183,0,0,0-.928.928l-.986,4.93a1.183,1.183,0,1,0,2.32.464L2.9,81.54a36.675,36.675,0,0,0,43.95-.042,1.183,1.183,0,0,0-1.413-1.9Z"
                          transform="translate(0 -32.446)" />
                        <path id="Path_27" data-name="Path 27"
                          d="M39.352,27.094l0,0,0,0L34.2,31.065V29.378A4.511,4.511,0,0,0,29.675,25h-19.4A4.381,4.381,0,0,0,5.9,29.378V42.956a4.381,4.381,0,0,0,4.378,4.378h19.4A4.612,4.612,0,0,0,34.2,42.508V40.821l5.132,3.959a.458.458,0,0,0,.35.259.791.791,0,0,0,.448-.093.443.443,0,0,0,.366-.209.8.8,0,0,0,.12-.438V27.587a.643.643,0,0,0-.12-.326.932.932,0,0,0-.355-.316l-.011-.005h-.013A1.67,1.67,0,0,0,39.352,27.094ZM34.025,33.3l0,0,0,0,5.141-3.966V43l-5.132-3.959a.458.458,0,0,0-.35-.259.791.791,0,0,0-.448.093.443.443,0,0,0-.366.209.8.8,0,0,0-.12.438v3.283a2.916,2.916,0,0,1-2.934,2.784h-19.4a2.782,2.782,0,0,1-2.784-2.784V29.229a2.783,2.783,0,0,1,2.734-2.784v.149H29.825a2.818,2.818,0,0,1,2.934,2.784V32.81a.643.643,0,0,0,.12.327.931.931,0,0,0,.355.316l.011.005h.013A1.671,1.671,0,0,0,34.025,33.3Z"
                          transform="translate(0.51 -8.332)" />
                      </g>
                    </g>
                  </svg>

                  <svg *ngIf="(appointment?.schRemoteAppointmentType| lowercase) =='onlinemeeting'"
                    xmlns="http://www.w3.org/2000/svg" width="47.328" height="55.386" viewBox="0 0 47.328 55.386">
                    <g id="convert_to_telemed" data-name="convert to telemed" transform="translate(-612 -245)">
                      <g id="switch-to-video" transform="translate(612 244)">
                        <path id="Path_25" data-name="Path 25"
                          d="M1.889,9.232a34.293,34.293,0,0,1,40.791-.28L40,9.489a1.183,1.183,0,0,0,.231,2.344,1.168,1.168,0,0,0,.233-.023l4.93-.986a1.182,1.182,0,0,0,.928-.928l.986-4.93a1.183,1.183,0,0,0-2.32-.464l-.558,2.791a36.675,36.675,0,0,0-43.95.042,1.183,1.183,0,0,0,1.413,1.9Z"
                          transform="translate(0 1)" />
                        <path id="Path_26" data-name="Path 26"
                          d="M45.439,79.6a34.293,34.293,0,0,1-40.791.28l2.684-.537a1.183,1.183,0,0,0-.464-2.32l-4.93.986a1.183,1.183,0,0,0-.928.928l-.986,4.93a1.183,1.183,0,1,0,2.32.464L2.9,81.54a36.675,36.675,0,0,0,43.95-.042,1.183,1.183,0,0,0-1.413-1.9Z"
                          transform="translate(0 -32.446)" />
                      </g>
                      <g id="switch-to-voice-1" transform="translate(621 251.937)">
                        <path id="Path_34" data-name="Path 34"
                          d="M10.2,30.2c.213.17,5.45,4.684,11.412,5.535a6.4,6.4,0,0,0,1.448.128A8.041,8.041,0,0,0,29.238,33.1a3.137,3.137,0,0,0,1.107-2,3.526,3.526,0,0,0-.894-2.725l-.085-.085-4.343-4.3a2.973,2.973,0,0,0-3.832-.3l-.128.128L18.933,25.9c-1.618-.937-6.472-4.088-8.857-8.857l1.575-1.575a3.48,3.48,0,0,0,1.15-2.427,3.462,3.462,0,0,0-1.107-2.555L7.309,6.232c-.043-.043-.085-.043-.085-.085a3.356,3.356,0,0,0-4.13.3A9.654,9.654,0,0,0,.24,15.685C.283,16.494,1.007,22.157,10.2,30.2ZM4.2,7.722a1.571,1.571,0,0,1,2-.213l4.258,4.216a1.625,1.625,0,0,1,.6,1.277,2.2,2.2,0,0,1-.6,1.235l-.043.043-2,2a.788.788,0,0,0-.17.937c2.938,6.6,10.092,10.347,10.39,10.517a.831.831,0,0,0,.979-.128l2.512-2.47c.937-.554,1.448-.085,1.575.043l.043.043,4.343,4.258a2.054,2.054,0,0,1,.511,1.405,1.7,1.7,0,0,1-.554.937l-.128.128a6.436,6.436,0,0,1-6,2.129h-.043c-5.493-.809-10.517-5.11-10.56-5.152C2.242,20.965,1.944,15.6,1.944,15.514v-.17A7.787,7.787,0,0,1,4.2,7.722Z"
                          transform="translate(0 0)" />
                      </g>
                    </g>
                  </svg>

                  <!-- <i *ngIf="(appointment?.schRemoteAppointmentType| lowercase) =='telemed'" class="convert-zoome-ic" ></i>
                    <i *ngIf="(appointment?.schRemoteAppointmentType| lowercase) =='onlinemeeting'" class="convert-phone-ic"></i> -->
                  <!-- <i class="new-ic appointments-new-ic"></i>  -->
                </a>
              </span>
              <span *ngIf="appointment.schAppointmentType!='Telehealth'">
                <strong>Type</strong> {{schAppointmentType}}
              </span>
              <span>
                <strong>Date & Time</strong>{{appointment.schAppointmentDateStartTime |
                date:appDateFormat.dateFormat}} |
                {{appointment?.schAppointmentDateStartTime | date:'shortTime' |lowercase }} |
                {{appointment?.appointmentDetailInfo.crmPatientTimeZone |timezone}}
              </span>
              <span>
                <strong>Duration</strong> <small class="hour">
                  {{scheduledApptDuration}} <i class="info-new"
                    pTooltip="{{appointment.salesOrderInfo.crmClassificationDescription}}"></i>
                </small>
              </span>
              <span>
                <strong>Therapist</strong> {{appointment.appointmentDetailInfo.therapistInfo?.appUserLname}},
                {{appointment.appointmentDetailInfo.therapistInfo?.appUserFname}}
              </span>
              <span class="facility"
                *ngIf="appointment.schAppointmentType=='Facility Visit' && appointment.appointmentDetailInfo.facilityInfo && !appointment?.salesOrderInfo?.crmIsTelehealth">

                <strong>Facility</strong> <span>
                  <b>{{appointment.appointmentDetailInfo.facilityInfo?.crmFacilityName}}</b>
                  {{appointment.appointmentDetailInfo.facilityInfo?.crmFacilityAddress}}
                </span>
              </span>
              <span class="facility"
                *ngIf="appointment.schAppointmentType=='Home Visit' && appointment?.salesOrderInfo?.patientInfo && !appointment?.salesOrderInfo?.crmIsTelehealth">

                <strong>Home Address</strong>
                <span *ngIf="appointment.schIsBillingAddress">
                  {{appointment?.salesOrderInfo?.patientInfo?.crmPatientAddress}}
                </span>
                <span *ngIf="appointment.schIsBillingAddress==false">
                  {{appointment?.salesOrderInfo?.crmPatientDeliveryAddress}}
                </span>
              </span>
              <span *ngIf="appointment.schAppointmentBookedBy==0">
                <strong></strong> <small class="hour"><i>*This appointment booked by patient.</i></small>
              </span>
            </div>
          </div>


        </div>
      </div>

    </div>
    <!-- </p-scrollPanel> -->
  </div>

</div>

<!-- End Content -->
<div class="loader-block" *ngIf="loading || loadingEmail">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>

<p-overlayPanel class="table-action-popup" #amountDue1 [showCloseIcon]="true">
  <div class="patient-info-middle--modify">
    <div class="patient-info-middle__boxs left">
      <strong>Item No.</strong>
      <ul>
        <li *ngFor="let col of appointment?.salesOrderInfo?.salesOrderItemInfo">
          {{ col.crmSalesorderItemItemId }}
        </li>

      </ul>
    </div>
    <div class="patient-info-middle__boxs left">
      <strong>Item Name</strong>
      <ul>
        <li *ngFor="let col of appointment?.salesOrderInfo?.salesOrderItemInfo">
          {{ col.crmSalesorderItemName }}
        </li>
      </ul>
    </div>
    <div class="patient-info-middle__boxs left">
      <strong>Amount</strong>
      <ul>
        <li *ngFor="let col of appointment?.salesOrderInfo?.salesOrderItemInfo">
          {{ col.crmSalesorderItemChargeAmt | currency}}
        </li>
      </ul>
    </div>
    <div class="patient-info-middle__btn">
      Amount Due <span>
        <i class="ic dollar"></i>
        {{appointment?.salesOrderInfo?.amountDue | currency}}
      </span>
    </div>
  </div>
</p-overlayPanel>

<!-- End Content -->
<!-- <p-dialog [(visible)]="soHistorydisplay" appendTo="body" [modal]="true" [responsive]="true">
  <app-sales-order-history *ngIf="soHistorydisplay" [salesOrderId]="appointment.crmSalesorderId">
  </app-sales-order-history>
</p-dialog> -->

<!-- SMS Log Popup-->
<p-dialog [(visible)]="smsdisplay" appendTo="body" [modal]="true" [responsive]="true">
  <div class="sms-log-popup-outer">
    <div class="popup-title">
      <h2>SMS Logs ({{appointmentSMSHistoryResponseModel.length}})</h2>

    </div>
    <div class="sms-popup-content">
      <ul class="sms-popup-content__list">
        <li class="sms-popup-content__item" [ngClass]="$index == expandedIndex ? 'active' : ''"
          *ngFor="let appointmentSMSHistory of appointmentSMSHistoryResponseModel; let $index = index">
          <div class="item-header">
            <div class="item-header__left">
              <h3>
                {{formatString(appointmentSMSHistory.appNotificationSubject)}}
                <span
                  [ngClass]="appointmentSMSHistory.appNotificationSmsstatus=='Sent' || appointmentSMSHistory.appNotificationSmsstatus=='Delivered'?'sent':''">{{appointmentSMSHistory.appNotificationSmsstatus}}</span>
                <button class="btn" (click)="ResendSMS(appointmentSMSHistory)">Resend</button>
              </h3>
              <p>
                Sent to {{appointmentSMSHistory.appNotificationSmstoNumber | phone}}
                on
                {{appointmentSMSHistory.appNotificationSmssentTime | date:appDateFormat.dateFormat}}
                at
                {{appointmentSMSHistory.appNotificationSmssentTime | date:'shortTime' | lowercase}}
              </p>
            </div>
            <div class="item-header__right">
              <a (click)="Collapse($index)" href="javascript:void(0)">
                {{$index == expandedIndex?'Hide Message':'Show Message'}}
              </a>
            </div>
          </div>
          <div class="item-content" *ngIf="$index == expandedIndex">
            <p>{{appointmentSMSHistory.appNotificationSmsbody}}</p>
          </div>
        </li>
      </ul>
    </div>

  </div>

</p-dialog>

<!-- Revemap UI for SMS Log -->
<p-sidebar [(visible)]="SMSSidebarDisplay" styleClass="email-sms-sidebar-outer" position="right">
  <app-sms-logs [appointmentSMSHistoryResponseModel]="appointmentSMSHistoryResponseModel"
    [schIsReminderOptedSms]="appointment.schIsReminderOptedSms" [isAppointment]="true"
    [schAppointmentId]="appointmentId" [smsLogCount]="smsLogCount"
    (onPreferenceChange)=onPreferenceChangeSMS($event)></app-sms-logs>

  <!-- <div class="loader-block" *ngIf="loadingEmail">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div> -->
</p-sidebar>

<!-- Email Log Popup-->
<p-dialog [(visible)]="emaildisplay" appendTo="body" [modal]="true" [responsive]="true">
  <div class="sms-log-popup-outer">
    <div class="popup-title">
      <h2>Email Logs ({{appointmentEmailHistoryResponseModel.length}}) </h2>
    </div>

    <div class="sms-popup-content">
      <ul class="sms-popup-content__list">
        <li class="sms-popup-content__item" [ngClass]="$index == expandedIndex ? 'active' : ''"
          *ngFor="let appointmentEmailHistory of appointmentEmailHistoryResponseModel; let $index = index">
          <div class="item-header">
            <div class="item-header__left">
              <h3>{{formatString(appointmentEmailHistory.appEmailsubject)}}
                <span
                  [ngClass]="appointmentEmailHistory.appNotificationEmailStatus=='Sent'?'sent':''">{{appointmentEmailHistory.appNotificationEmailStatus}}</span>
                <button class="btn" (click)="ResendEmail(appointmentEmailHistory)">Resend</button>
              </h3>
              <p>
                Sent to {{appointmentEmailHistory.appNotificationEmailToEmail}}
                on
                {{appointmentEmailHistory.appNotificationEmailSentTime | date:appDateFormat.dateFormat}}
                at
                {{appointmentEmailHistory.appNotificationEmailSentTime | date:'shortTime' | lowercase}}
              </p>
            </div>
            <div class="item-header__right">
              <a (click)="Collapse($index)" href="javascript:void(0)">
                {{$index == expandedIndex?'Hide Message':'Show Message'}}
              </a>
            </div>
          </div>
          <div class="item-content" *ngIf="$index == expandedIndex"
            [innerHTML]="appointmentEmailHistory.appEmailDisplaybody">
            <!-- <p>{{appointmentEmailHistory.appEmailbody}}</p> -->
          </div>
        </li>
      </ul>
    </div>

  </div>

  <div class="loader-block" *ngIf="loadingEmail">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>

</p-dialog>


<!-- Revemap UI for EMail Log -->
<p-sidebar [(visible)]="EmailSidebarDisplay" styleClass="email-sms-sidebar-outer" position="right">

  <app-email-logs [appointmentEmailHistoryResponseModel]='appointmentEmailHistoryResponseModel'
    [schIsReminderOptedEmail]="appointment.schIsReminderOptedEmail" [isAppointment]="true"
    [schAppointmentId]="appointmentId" [emailLogCount]="emailLogCount"
    (onPreferenceChange)=onPreferenceChangeEmail($event)></app-email-logs>
    
  <!-- <div class="loader-block" *ngIf="loadingEmail">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div> -->
</p-sidebar>

<p-dialog [(visible)]="displayOrderNote" styleClass="display-close-icon" [modal]="true" [responsive]="true"
  appendTo="body" [baseZIndex]="9999999">
  <div class="search-box-outer delivery">
    <h2 class="filter-title" style="padding: 0;">Order Note</h2>

    <div class="form__group order-note">
      <textarea class="form__textarea" maxlength="8000" [(ngModel)]="appointmentRequestBT.OrderNote"
        pInputTextarea> </textarea>
    </div>

    <div class="btn-block app-not-scheduled-appointments-btn">
      <button class="btn btn--primary" (click)="SaveOrderNoteClick()">Save</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)" (click)="CancelOrderNoteClick()"> Cancel </a>
    </div>

  </div>
  <div class="loader-block" *ngIf="loadingOrderNote">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayDeliveryNote" styleClass="display-close-icon" [modal]="true" [responsive]="true"
  appendTo="body" [baseZIndex]="9999999">
  <div class="search-box-outer delivery">
    <h2 class="filter-title" style="padding: 0;">Delivery Note</h2>

    <div class="form__group order-note">

      <textarea class="form__textarea" maxlength="500" [(ngModel)]="appointmentRequestBT.DeliveryNote"
        pInputTextarea></textarea>
    </div>

    <div class="btn-block app-not-scheduled-appointments-btn">
      <button class="btn btn--primary" (click)="SaveDeliveryNoteClick()">Save</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)" (click)="CancelDeliveryNoteClick()">
        Cancel
      </a>
    </div>

  </div>

  <div class="loader-block" *ngIf="loadingDeliveryNote">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</p-dialog>


<!-- Histroy Sidebar -->
<div class="history-sidebar" [ngClass]="SoHistory ? 'active' : 'hidden'">
  <div class="history-sidebar-header">
    <a class="close-icon" (click)="HistoryEventClose()">
      <img src="assets/img/cross-icon.svg" alt="close" width="15" />
    </a>
    <div class="history-sidebar-header__left">
      <h2>Appointment History</h2>
      <span><strong>Sales Order ID : </strong>{{appointment.salesOrderInfo?.crmSalesorderAppId}}</span>
    </div>
    <button class="btn btn--primary" (click)="saleOrderExportHistory(appointment.salesOrderInfo?.enCrmSalesorderId)"
      style=" display: flex; align-items: center;">
      <img src="assets/img/export-icon.svg" alt="export download" style="margin-right: 1.2rem;" />Export
    </button>

  </div>
  <div class="history-sidebar-content">

    <app-sales-order-history *ngIf="soHistorydisplay"
      [salesOrderId]="appointment.crmSalesorderId"></app-sales-order-history>

  </div>
</div>


<!-- New Histroy Popup -->
<!-- <p-dialog [(visible)]="AppointmentHistoryPopup" styleClass="display-close-icon" [modal]="true" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>Appointment History</h2>
    <div class="appointment-hstory">

    </div>
  </div>
</p-dialog> -->


<p-dialog [(visible)]="displayVoidAppointment" styleClass="display-close-icon" [modal]="true" (onHide)="cancelVoidAppointment()" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>Void Appointment</h2>
    <div class="schedulecall">
      <!--<ng-container *ngIf="displayVoidAppointment">-->
      <form class="form" [formGroup]="formVoidAppointment" autocomplete="off" *ngIf="formVoidAppointment">
        <div class="schedulecall">
          <div class="col">
            <div class="form__group full-width">
              <label class="form__label">Reason</label>

              <p-dropdown class="select-box" [options]="voidReasonsResponseModel" formControlName="voidReason"
                placeholder="Select Reason" optionLabel="salesOrderVoidReason" [showClear]="true"></p-dropdown>

              <div *ngIf="formVoidAppointmentSubmitted && fVoid.voidReason.errors" class="invalid-feedback-error">
                <span *ngIf="fVoid.voidReason.errors.required || fVoid.voidReason.errors.whitespace">
                  {{validationMessages.voidReason.required}}
                </span>

              </div>
            </div>
          </div>
          <div class="col">
            <div class="form__group full-width">
              <label class="form__label">Comment</label>
              <textarea class="form__textarea" pInputTextarea formControlName="voidReasonComments"
                maxlength="100"></textarea>
              <div *ngIf="formVoidAppointmentSubmitted && fVoid.voidReasonComments.errors"
                class="invalid-feedback-error">
                <span *ngIf="fVoid.voidReasonComments.errors.required || fVoid.voidReasonComments.errors.whitespace">
                  {{validationMessages.voidReasonComments.required}}
                </span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="check receive-reminder wip-state-will-not-update">
              <p-checkbox name="groupnamevoid" label="If box checked, the WIP State will not be updated in Brightree"
                [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
                [ngModelOptions]="{standalone: true}">
              </p-checkbox>
            </div>
          </div>
          <div class="call-popup-appointment">
            <button class="btn btn--primary" (click)="cancelVoidAppointmentUpdate(selectedVoidstatus)">Submit</button>
            <a href="javascript:void(0)" class="btn btn--secondary" (click)="cancelVoidAppointment()">Cancel</a>
          </div>
        </div>
      </form>
      <!--</ng-container>-->
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayCancelAppointment" styleClass="display-close-icon" [modal]="true" (onHide)="hideAppointmentpopup()" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>Cancel Appointment</h2>
    <div class="schedulecall">
      <!--<ng-container *ngIf="displayVoidAppointment">-->
      <form class="form" [formGroup]="formCancel" autocomplete="off" *ngIf="formCancel">
        <div class="schedulecall">
          <div class="col">
            <div class="form__group full-width">
              <label class="form__label">Reason</label>


              <p-dropdown class="select-box" [options]="cancellationReasonsResponseModel" formControlName="reason"
                placeholder="Select Reason" optionLabel="salesOrderVoidReason" [showClear]="true"></p-dropdown>


              <div *ngIf="formCancelSubmitted && fCancel.reason.errors" class="invalid-feedback-error">
                <span *ngIf="fCancel.reason.errors.required || fCancel.reason.errors.whitespace">
                  {{validationMessages.reason.required}}
                </span>

              </div>
            </div>
          </div>
          <div class="col">
            <div class="form__group full-width">
              <label class="form__label">Comment</label>



              <textarea class="form__textarea" pInputTextarea formControlName="reasonComments"
                maxlength="100"></textarea>


              <div *ngIf="formCancelSubmitted && fCancel.reasonComments.errors" class="invalid-feedback-error">
                <span *ngIf="fCancel.reasonComments.errors.required || fCancel.reasonComments.errors.whitespace">
                  {{validationMessages.reasonComments.required}}
                </span>
              </div>

            </div>
          </div>

          <div class="col">
            <div class="check receive-reminder wip-state-will-not-update">
              <p-checkbox name="groupnamecancel" label="If box checked, the WIP State will not be updated in Brightree"
                [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
                [ngModelOptions]="{standalone: true}">
              </p-checkbox>
            </div>
          </div>
          <div class="appointment-calcel-btn">
            <button class="btn btn--primary" (click)="cancelVoidAppointmentUpdate('cancel')">Submit</button>
            <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideAppointmentpopup()">Cancel</a>
          </div>
        </div>
      </form>
      <!--</ng-container>-->
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayNoShowAppointment" styleClass="display-close-icon" [modal]="true" (onHide)="hideNoShowPopup()" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>No Show Appointment</h2>
    <div class="schedulecall">

      <div class="col">
        <div class="form__group full-width">
          <label class="form__label">Are you sure you want to no show appointment ?</label>
        </div>
      </div>
      <div class="schedulecall">
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Comment</label>

            <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonCommentsNoShow"
              maxlength="100"></textarea>
          </div>
        </div>
        <div class="col">
          <div class="check receive-reminder wip-state-will-not-update">
            <p-checkbox name="groupnamenoshow" label="If box checked, the WIP State will not be updated in Brightree"
              [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
              [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
        </div>

        <div class="appointment-calcel-btn">
          <button class="btn btn--primary" (click)="NoShowAppointment('noshow')">Submit</button>
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideNoShowPopup()">Cancel</a>
      <!--    <a href="javascript:void(0)" class="test" (onHide)="!displayNoShowAppointment"></a>-->
        </div>
      </div>

    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayCompleteAppointment" styleClass="display-close-icon" [modal]="true" (onHide)="hideCompletePopup()" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>Complete Appointment</h2>
    <div class="schedulecall">

      <div class="schedulecall">
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Are you sure you want to complete appointment ?</label>
          </div>
        </div>
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Comment</label>

            <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonCommentsComplete"
              maxlength="100"></textarea>
          </div>
        </div>
        <div class="col">
          <div class="check receive-reminder wip-state-will-not-update">
            <p-checkbox name="groupnamecomplete" label="If box checked, the WIP State will not be updated in Brightree"
              [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
              [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
        </div>

        <div class="appointment-calcel-btn">
          <button class="btn btn--primary" (click)="CompleteAppointment('complete')">Submit</button>
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideCompletePopup()">Cancel</a>
        </div>
      </div>

    </div>
  </div>
</p-dialog>