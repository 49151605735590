import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { PatientBookAppointmentRequest } from 'src/app/shared/models/request/patient-book-appointment-request-model';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment-confirmation';
import { FacilitySearchResponseModel } from 'src/app/shared/models/response/facility-search-response';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { PatientService } from '../services/patient.service';
import { TimezonePipe } from 'src/app/pipes/timezone.pipe';

@Component({
  selector: 'app-appointment-summary',
  templateUrl: './appointment-summary.component.html',
  styleUrls: ['./appointment-summary.component.sass']
})
export class AppointmentSummaryComponent extends BaseComponent implements OnInit, OnDestroy {
  AgreeTermsConditionsPopup: boolean = false;
  patientBookingTypeId: string = '';
  @Output() activeStepNoEmit: EventEmitter<number> = new EventEmitter();
  private unsubscriber = new Subject<boolean>();
  @Input() patientBookAppointmentRequest: PatientBookAppointmentRequest;

  email: string = "";
  emailConfirm: string = "";
  emailRequired: boolean = false;
  emailConfirmRequired: boolean = false;
  emailMatch: boolean = false;
  emailRegex: boolean = false;
  emailConfirmRegex: boolean = false;
  patientPhone: string = "";
  patientMobileNumber: string = "";
  termCondtionSelected: boolean = false;
  reminderOpted: boolean = false;
  schIsReminderOptedType: string = 'Both';
  schReminderOpted: boolean = false;
  schIsReminderOptedSms: boolean = false;
  schIsReminderOptedEmail: boolean = false;
  showConfirm: boolean = false;
  appDateFormat: DateFormatModel;
  appointmentRequest: AppointmentRequestModel;

  salesOrderLocal: SalesOrderLocalModel;
  salesOrderData: SalesOrderResponseModel;
  appointmentConfirmation: AppointmentConfirmation;
  companyTermsAndCondition: string;
  appointmentType: string;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];

  constructor(private webStorageService: WebStorageService,
    private patientService: PatientService,
    private appointmentService: AppointmentService,
    private router: Router,
    messageService: MessageService) {
    super(messageService);
    this.patientBookingTypeId = this.webStorageService.getPatientBookingTypeId();
    this.appDateFormat = this.webStorageService.getDateFormat();

    const companyDetails = this.webStorageService.getClientConfig();
    if (companyDetails) {
      this.companyTermsAndCondition = companyDetails.companyTermsAndCondition;
    }

    let onlineAppointmentTypeResponseModel = this.webStorageService.getOnlineAppointmentTypeNames();

    if (onlineAppointmentTypeResponseModel.length > 0) {
      if (this.patientBookingTypeId == "T") {
        let TherapistVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
        if (TherapistVisit) {
          this.appointmentType = TherapistVisit.crmOnlineAppointmentTitle;
        }
      }
      else if (this.patientBookingTypeId == "F") {
        let FacilityVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
        if (FacilityVisit) {
          this.appointmentType = FacilityVisit.crmOnlineAppointmentTitle;
        }
      }
    }
  }

  ngOnInit(): void {

    this.salesOrderLocal = this.webStorageService.getSalesOrderId();
    if (!this.salesOrderLocal) {
      this.showWarningMessage("No sales order selected.");
    }
    this.email = this.salesOrderLocal?.patientEmail;
    this.patientPhone = this.salesOrderLocal?.patientMobile;
    this.patientMobileNumber = this.salesOrderLocal?.patientPhone;
    this.salesOrderData = this.webStorageService.getSalesOrderData();


    this.schReminderOpted = this.salesOrderData.schReminderOpted;
    this.schIsReminderOptedSms = this.salesOrderData.schIsReminderOptedSms;
    this.schIsReminderOptedEmail = this.salesOrderData.schIsReminderOptedEmail;

    if (this.schIsReminderOptedSms && this.schIsReminderOptedEmail)
      this.schIsReminderOptedType = "Both";
    if (!this.schIsReminderOptedSms && !this.schIsReminderOptedEmail)
      this.schIsReminderOptedType = "Both";
    else if (this.schIsReminderOptedSms && !this.schIsReminderOptedEmail)
      this.schIsReminderOptedType = "SMS";
    else if(this.schIsReminderOptedEmail && !this.schIsReminderOptedSms)
      this.schIsReminderOptedType = "Email";


    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.patientBookAppointmentRequest.selectedTimeZone.shortName= this.timeZone.find(x => x.orgcode == this.patientBookAppointmentRequest.selectedTimeZone.orgcode).shortName;

  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  AgreeTermsConditionsClick() {
    this.AgreeTermsConditionsPopup = true;
  }

  onSelectTerms() {
    if (this.termCondtionSelected)
      this.showConfirm = true;
    else
      this.showConfirm = false;
  }

  onBack() {
    if (this.patientBookingTypeId === 'F' || this.patientBookingTypeId === 'T') {
      //this.router.navigate(['/patient/choose-facility']);
      this.activeStepNoEmit.emit(2);
    }
    if (this.patientBookingTypeId === 'R' || this.patientBookingTypeId === 'SG') {
      this.activeStepNoEmit.emit(1);
    }
  }

  onBookAppointment() {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    if (!this.email) {
      this.emailRequired = true;
    }
    else {
      this.emailRequired = false;
    }
    if (!TEXT_REGEXP.test(this.email) && this.email) {
      this.emailRegex = true;
    }
    else {
      this.emailRegex = false;
    }

    if (!this.termCondtionSelected) {
      this.showWarningMessage("Please accept the Terms & Conditions");
      return;
    }

    if (this.emailMatch || this.emailConfirmRegex || this.emailRegex || this.emailConfirmRequired || this.emailConfirmRequired) {
      return;
    }
    this.loadingCustom = true;
    this.loading = true;
    let btNoteId = this.salesOrderData.btNoteID == undefined ? 0 : this.salesOrderData.btNoteID;
    let patientInfoRequestModel = new PatientInfoRequestModel();
    patientInfoRequestModel.patientid = this.salesOrderData.patientInfo.crmPatientId.toString();
    patientInfoRequestModel.email = this.email;
    patientInfoRequestModel.phone = this.patientPhone;
    patientInfoRequestModel.mobilephone = this.patientMobileNumber;
    patientInfoRequestModel.isUpdateByPatient = true;
    patientInfoRequestModel.branchId = this.salesOrderData?.crmBranchId;
    patientInfoRequestModel.crmSalesorderId = this.salesOrderData?.crmSalesorderId;
    patientInfoRequestModel.crmSalesorderAppId = this.salesOrderData?.crmSalesorderAppId;
    patientInfoRequestModel.schAppointmentId = this.salesOrderData.schAppointmentId;

    this.loading = true;
    this.appointmentService.updatePatientInformation(patientInfoRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {

        }
        else {
          this.loadingCustom = false;
          if (result.message) {
            this.showErrorMessage(result.message);
          }
          else {
            this.showErrorMessage("There was problem updating your email. Please contact administrator");
          }
          this.loading = false;
          return;
        }
      });

    this.appointmentRequest = new AppointmentRequestModel();
    let AppoinmentType: string = this.patientBookingTypeId == "T" ? "Therapist" : (this.patientBookingTypeId == "R" || this.patientBookingTypeId == "SG") ? "Telehealth" : "FacilityVisit";
    if (this.patientBookingTypeId == "T") {
      if (this.patientBookAppointmentRequest.selectedAppointmentTypePopUp.toLowerCase() == "ClinicVisit".toLowerCase()) {
        this.appointmentRequest.CrmFacilityId = this.patientBookAppointmentRequest.selectedFacilityLocalcrmFacilityId;
        this.appointmentRequest.CrmRemoteFacilityId = 0;
      }
      else {
        //this.appointmentRequest.CrmFacilityId = 0;
        this.appointmentRequest.CrmRemoteFacilityId = this.appointmentRequest.CrmFacilityId = this.patientBookAppointmentRequest.selectedFacilityLocalcrmRemoteFacilityId;
      }
    }
    else {
      this.appointmentRequest.CrmFacilityId = this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityId;
    }
    this.appointmentRequest.SchAppointmentTypeName = AppoinmentType;
    this.appointmentRequest.CrmPatientEmailAddress = this.email;
    this.appointmentRequest.CrmPatientId = this.salesOrderData.patientInfo.crmPatientId;
    this.appointmentRequest.CrmPatientPhoneNo = this.patientMobileNumber == "" ? this.patientPhone : this.patientMobileNumber;
    this.appointmentRequest.CrmSalesorderId = parseInt(this.salesOrderLocal.salesOrderId, 10);
    this.appointmentRequest.IsAppointmentBookedByPatient = true;
    this.appointmentRequest.SchAppointmentDateStartTime = this.patientBookAppointmentRequest.selectedStartDate;
    this.appointmentRequest.SchAppointmentDateEndTime = this.patientBookAppointmentRequest.selectedEndDate;
    this.appointmentRequest.SchAppointmentDateStartTimeString = this.patientBookAppointmentRequest.selectedStartDate.toDateString() + ' ' + this.patientBookAppointmentRequest.selectedStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateEndTimeString = this.patientBookAppointmentRequest.selectedEndDate.toDateString() + ' ' + this.patientBookAppointmentRequest.selectedEndDate.toTimeString().split(' ')[0];

    this.appointmentRequest.SchAppointmentDateStartTime = this.patientBookAppointmentRequest.selectedStartDate;
    this.appointmentRequest.SchAppointmentDateEndTime = this.patientBookAppointmentRequest.selectedEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTime = this.patientBookAppointmentRequest.selectedOrigStartDate;
    this.appointmentRequest.SchAppointmentOrigDateEndTime = this.patientBookAppointmentRequest.selectedOrigEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTimeString = this.patientBookAppointmentRequest.selectedOrigStartDate.toDateString() + ' ' + this.patientBookAppointmentRequest.selectedOrigStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentOrigDateEndTimeString = this.patientBookAppointmentRequest.selectedOrigEndDate.toDateString() + ' ' + this.patientBookAppointmentRequest.selectedOrigEndDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchSmscommunicationType = this.patientMobileNumber == "" ? "Phone" : "Mobile";
    this.appointmentRequest.SchSmscommunicationNumber = this.patientMobileNumber == "" ? this.patientPhone : this.patientMobileNumber;
    this.appointmentRequest.SchSmscommunicationNumber = this.appointmentRequest.SchSmscommunicationNumber.replace(/[^\d]/g, '');
    this.appointmentRequest.SchCommunicationEmailAddress = this.email;

    this.appointmentRequest.SchAppointmentId = this.salesOrderData.schAppointmentId;
    this.appointmentRequest.CrmTherapistId = this.patientBookingTypeId == "T" ? this.webStorageService.getSelectedTherapist().appUserInfoId : null;

    if (this.patientBookingTypeId == "SG" || this.patientBookingTypeId == "R") {
      this.appointmentRequest.CrmTherapistId = Number(this.patientBookAppointmentRequest.selectedTherapistId);
    }
    this.appointmentRequest.PatientTimeZone = this.patientBookAppointmentRequest.selectedTimeZone.shortName;
    this.appointmentRequest.SchReminderOpted = this.reminderOpted;

    this.appointmentRequest.SchReminderOpted = this.schReminderOpted;
    //this.appointmentRequest.SchIsReminderOptedSms = this.schIsReminderOptedSms;
    //this.appointmentRequest.SchIsReminderOptedEmail = this.schIsReminderOptedEmail;

    if (this.schIsReminderOptedType == "Both") {
      this.appointmentRequest.SchIsReminderOptedSms = true;
      this.appointmentRequest.SchIsReminderOptedEmail = true;
    }

    else if (this.schIsReminderOptedType == "SMS") {
      this.appointmentRequest.SchIsReminderOptedSms = true;
      this.appointmentRequest.SchIsReminderOptedEmail = false;
    }

    else {
      this.appointmentRequest.SchIsReminderOptedSms = false;
      this.appointmentRequest.SchIsReminderOptedEmail = true;
    }


    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = this.patientBookAppointmentRequest.selectedStartDate.toDateString();
    appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.patientBookAppointmentRequest.selectedStartDate.toTimeString(), 'hh:mm A').format("hh:mm A");
    appointmentRequestBT.ScheduledDeliveryStartDate = this.patientBookAppointmentRequest.selectedStartDate;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    let BTStatusSelected = null;
    if (this.patientBookingTypeId == "F") {
      BTStatusSelected = 'booked';
    }
    if (this.patientBookingTypeId == "R" || this.patientBookingTypeId == "SG") {
      BTStatusSelected = 'bookedremote';
    }
    if (this.patientBookingTypeId == "T") {
      if (this.patientBookAppointmentRequest.selectedAppointmentTypePopUp.toLowerCase() == "ClinicVisit".toLowerCase()) {
        BTStatusSelected = 'booked';
      }

      if (this.patientBookAppointmentRequest.selectedAppointmentTypePopUp.toLowerCase() == "Telehealth".toLowerCase()) {
        BTStatusSelected = 'bookedremote';
      }
    }

    if (this.salesOrderData.crmWIPAssignedToKey) {
      appointmentRequestBT.WIPAssignedToKey = this.salesOrderData.crmWIPAssignedToKey;
    }
    let selectedWipStaus = this.patientBookAppointmentRequest.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameBt.toLowerCase() == this.salesOrderData.crmWIPStatusNameBT.toLowerCase())
    if (selectedWipStaus) {
      appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
      appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
      appointmentRequestBT.IsUpdateToBrightree = this.patientBookAppointmentRequest.isBrightreeSystemAppUpdate;
      appointmentRequestBT.IsUpdatePODStatusToBrightree = this.patientBookAppointmentRequest.isUpdatePODStatusToBrightree;
      appointmentRequestBT.IsAppendOrderNoteToBrightree = this.patientBookAppointmentRequest.isAppendOrderNoteToBrightree;
      appointmentRequestBT.EmailAddress = this.email;
      appointmentRequestBT.PhoneNumber = this.patientPhone;
      appointmentRequestBT.crmPatientPhoneNumber = this.patientPhone;
      appointmentRequestBT.crmPatientMobileNumber = this.patientMobileNumber;
    }
    else {
      let selectedWipStaus = this.patientBookAppointmentRequest.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == BTStatusSelected)
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = this.patientBookAppointmentRequest.isBrightreeSystemAppUpdate;
        appointmentRequestBT.IsUpdatePODStatusToBrightree = this.patientBookAppointmentRequest.isUpdatePODStatusToBrightree;
        appointmentRequestBT.IsAppendOrderNoteToBrightree = this.patientBookAppointmentRequest.isAppendOrderNoteToBrightree;
        appointmentRequestBT.EmailAddress = this.email;
        appointmentRequestBT.PhoneNumber = this.patientPhone;
        appointmentRequestBT.crmPatientPhoneNumber = this.patientPhone;
        appointmentRequestBT.crmPatientMobileNumber = this.patientMobileNumber;
      }
    }
    appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(appointmentRequestBT.ScheduledDeliveryStartTimeString, 'hh:mm A').format("HH:mm");
    if (this.patientBookAppointmentRequest.isDefaultWIPNeededDateAppointmentdate) {
      appointmentRequestBT.WIPDateNeededString = moment(appointmentRequestBT.ScheduledDeliveryStartDate).toDate().toDateString();
      appointmentRequestBT.WIPDateNeeded = moment(appointmentRequestBT.ScheduledDeliveryStartDate).toDate();
    } else {
      appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
      appointmentRequestBT.WIPDateNeeded = moment().toDate();
    }

    this.appointmentRequest.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    this.appointmentRequest.PatientTimeZone = this.patientBookAppointmentRequest.patientTimeZone;
    this.appointmentRequest.CrmFacilityTimeZone = this.patientBookAppointmentRequest.selectedFacilityTimeZone;

    this.appointmentRequest.btNoteId = this.salesOrderData.btNoteID == null ? 0 : this.salesOrderData.btNoteID;
    this.appointmentRequest.isProvisional = this.patientBookAppointmentRequest.isProvisional;

    this.appointmentRequest.CrmFacilityName = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityName;
    this.appointmentRequest.AppointmentDuration = this.patientBookAppointmentRequest.duration == undefined ? '' : this.patientBookAppointmentRequest.duration;
    this.appointmentRequest.CrmTherapistName = this.patientBookAppointmentRequest.therapistName == undefined ? '' : this.patientBookAppointmentRequest.therapistName;
    this.appointmentRequest.AmountDue = this.patientBookAppointmentRequest.dueAmount == undefined ? Number(0) : this.patientBookAppointmentRequest.dueAmount;

    this.appointmentRequest.SchRemoteAppointmentType = this.patientBookAppointmentRequest.selectedRemoteAppointmentType;
    let appointmentRequest = this.salesOrderData.schAppointmentId > 0 ? this.patientService.ReScheduleAppointment(this.appointmentRequest) : this.patientService.ScheduleAppointment(this.appointmentRequest);

    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.loadingCustom = false;
          this.webStorageService.deleteAppointmentConfirmation();
          this.appointmentConfirmation = new AppointmentConfirmation();
          this.appointmentConfirmation.appointmentStartDate = this.patientBookAppointmentRequest.selectedStartDate;
          this.appointmentConfirmation.appointmentEndDate = this.patientBookAppointmentRequest.selectedEndDate;
          this.appointmentConfirmation.appointmentTimeZone = this.patientBookAppointmentRequest.selectedTimeZone.shortName;
          this.appointmentConfirmation.appointmentTimeZoneCode = this.patientBookAppointmentRequest.selectedTimeZone.code;
          this.appointmentConfirmation.facilityAddress = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityAddress;
          this.appointmentConfirmation.facilityAddressLine1 = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityAddressLine1;
          this.appointmentConfirmation.facilityAddressLine2 = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityAddressLine2;
          this.appointmentConfirmation.facilityCity = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityCity;
          this.appointmentConfirmation.facilityState = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityState;
          this.appointmentConfirmation.facilityCountry = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityCountry;
          this.appointmentConfirmation.facilityZipCode = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityZipCode;
          this.appointmentConfirmation.facilityName = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityName;
          this.appointmentConfirmation.facilityLatitude = this.patientBookAppointmentRequest.crmfacilityLatitude;
          this.appointmentConfirmation.facilityLongitude = this.patientBookAppointmentRequest.crmfacilityLongitude;
          this.appointmentConfirmation.crmBioData = this.patientBookAppointmentRequest.crmBioData;
          this.appointmentConfirmation.distance = this.patientBookAppointmentRequest.selectedFacilityLocal == undefined ? '' : this.patientBookAppointmentRequest.selectedFacilityLocal.crmFacilityDistanceInMiles;
          this.appointmentConfirmation.duration = this.patientBookAppointmentRequest.duration == undefined ? '' : this.patientBookAppointmentRequest.duration;
          this.appointmentConfirmation.description = this.patientBookAppointmentRequest.description == undefined ? '' : this.patientBookAppointmentRequest.description;
          this.appointmentConfirmation.dueAmount = this.patientBookAppointmentRequest.dueAmount == undefined ? Number(0) : this.patientBookAppointmentRequest.dueAmount;
          this.appointmentConfirmation.crmTherapistname = this.patientBookAppointmentRequest.therapistName == undefined ? '' : this.patientBookAppointmentRequest.therapistName;
          this.appointmentConfirmation.remoteType = this.patientBookAppointmentRequest.selectedRemoteAppointmentType;
          this.appointmentConfirmation.selectedAppointmentTypePopUp = this.patientBookAppointmentRequest.selectedAppointmentTypePopUp;
          this.appointmentConfirmation.durationInMinutes = this.salesOrderData.crmClassificationDuration == 1 ? 60 : this.salesOrderData.crmClassificationDuration;
          this.appointmentConfirmation.isTherapistFacilityType = this.patientBookAppointmentRequest.isTherapistFacilityType;
          this.appointmentConfirmation.isTherapistRemoteType = this.patientBookAppointmentRequest.isTherapistRemoteType;
          this.appointmentConfirmation.isTherapistRemoteAndFaciltyType = this.patientBookAppointmentRequest.isTherapistRemoteAndFaciltyType;
          this.appointmentConfirmation.PatientEmail = this.email;
          this.appointmentConfirmation.PatientPhone = this.patientPhone;
          this.appointmentConfirmation.PatientMobileNumber = this.patientMobileNumber;
          this.appointmentConfirmation.schAppointmentId = this.salesOrderData.schAppointmentId > 0 ? this.salesOrderData.schAppointmentId : result.responseModel;
          
          this.appointmentConfirmation.schIsReminderOptedEmail = this.appointmentRequest.SchIsReminderOptedEmail;
          this.appointmentConfirmation.schIsReminderOptedSms = this.appointmentRequest.SchIsReminderOptedSms;

          this.webStorageService.storeAppointmentConfirmation(this.appointmentConfirmation);

          //this.activeStepNoEmit.emit(4);
          this.router.navigate(['/patient/appointment-confirmation']);
        }
        else {
          if (result.message) {
            this.loadingCustom = false;
            this.showErrorMessage(result.message);
          }
          else {
            this.loadingCustom = false;
            this.showErrorMessage("There was problem in scheduling your appointment. Please contact administrator");
          }
        }
      });
  }
}
