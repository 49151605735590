<div class="plugin-outer">
  <div class="form-outer">
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Service</label>
          <p-dropdown class="select-box" formControlName="service" [options]="pluginServices" optionLabel="pluginName"
            [showClear]="true">
          </p-dropdown>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.service, 'required') || hasError(formFields.service, 'whitespace')">{{validationMessages.service.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Connection Name</label>
          <input type="text" formControlName="connectionName" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.connectionName, 'required') || hasError(formFields.connectionName, 'whitespace')">{{validationMessages.connectionName.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Username</label>
          <input type="text" formControlName="userName" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.userName, 'required') || hasError(formFields.userName, 'whitespace')">{{validationMessages.userName.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Password</label>
          <input type="password" formControlName="password" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.password, 'required') || hasError(formFields.password, 'whitespace')">{{validationMessages.password.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Account SID</label>
          <input type="password" formControlName="accountSID" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.accountSID, 'required') || hasError(formFields.accountSID, 'whitespace')">{{validationMessages.accountSID.required}}</span>
        </div>
        <!--<h3>Authentication Token</h3>-->
        <div class="form__group">
          <label class="form__label">Auth Token</label>
          <input type="password" formControlName="apiId" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.apiId, 'required') || hasError(formFields.apiId, 'whitespace')">{{validationMessages.apiId.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Phone Number</label>
          <input type="text" formControlName="phoneNumber" class="form__control" pInputText>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.phoneNumber, 'required') || hasError(formFields.phoneNumber, 'whitespace')">{{validationMessages.phoneNumber.required}}</span>
          <span class="invalid-feedback"
            *ngIf="hasError(formFields.phoneNumber, 'validPhoneNumber')">{{validationMessages.phoneNumber.validPhoneNumber}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Messaging Service SID</label>
          <input type="text" formControlName="messagingServiceSID" class="form__control" pInputText>
          <!--<span  class="invalid-feedback" *ngIf="hasError(formFields.messagingServiceSID, 'required') || hasError(formFields.messagingServiceSID, 'whitespace')">{{validationMessages.messagingServiceSID.required}}</span>-->
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">SMS Send Start Time</label>
          <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="24" minutesGap="15" [controlOnly]="true"
            [ngModelOptions]="{standalone: true}">

          </ngx-timepicker-field>
        </div>

        <div class="form__group">
          <label class="form__label">SMS Send End Time</label>
          <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="24" minutesGap="15" [controlOnly]="true"
            [ngModelOptions]="{standalone: true}">

          </ngx-timepicker-field>
        </div>
        <span class="time-note" style=" margin: -20px 0 2rem 0 !important; display: table;">Please do not use the 0 prefixes in Hours and Minutes</span>
      </div>

      <div class="btns">
        <button class="btn btn--primary" routerLink="/admin/settings/plugins">Save</button>
      </div>
    </form>
    <div class="test-sms-btn">
      <button class="btn btn--secondary" (click)="onClickTest()">Test SMS</button>
    </div>

    <div *ngIf="isTestSMS">
      <br>
      <div class="form__group test-sms">
        <label class="form__label">Phone Number</label>
        <input type="text" class="form__control" pInputText [(ngModel)]="testNumber">
      </div>
      <div class="btns">
        <button class="btn btn--primary" (click)="SendTestSMS()">Send SMS</button>
        <button class="btn btn--secondary" (click)="CancelSMS()">Cancel</button>
      </div>
    </div>
  </div>
</div>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
