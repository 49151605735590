<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>
  </div>
  <div class="table-filter">
    <div class="table-filter__search-block">
      <div class="search-box">
        <input type="text" class="form__control" pInputText placeholder="Search" #searchBox
               style="width:auto" (keyup.enter)="onSearchButtonClick()">
        <button class="search-btn" pButton type="button" >
          <i class="search-new" (click)="onSearchButtonClick()"></i>
        </button>
      </div>
      <p-dropdown class="select-box filter-status" #statusDropdown [options]="patientStatus"
                  (onChange)="onStatusChange($event)" placeholder="Status" optionLabel="label" [showClear]="true"></p-dropdown>
    </div>
  </div>
  <div class="page-scroll">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="table-outer-main">
        <p-table class="table-outer" #nsdl [value]="patients"
                 [responsive]="true"
                 [paginator]="true"
                 sortMode="single"
                 sortField="CrmPatientFirstName"
                 [first]="searchRequestModel.FirstRecordIndex"
                 [sortOrder]="1"
                 [rows]="getTotalRows()"
                 [totalRecords]="totalRecords"
                 [lazy]="true"
                 [customSort]="true"
                 (onLazyLoad)="onLazyLoad($event)">
          <ng-template pTemplate="header" >
            <tr *ngIf="totalRecords > 0">
              <th class="table-outer__th" pSortableColumn="crmPatientFirstName">Patient<p-sortIcon field="crmPatientFirstName"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="crmRcmPatientid">Patient Id<p-sortIcon field="crmRcmPatientid"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="crmPatientAddress">Address<p-sortIcon field="crmPatientAddress"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="crmPatientPhoneNo">Contact No<p-sortIcon field="crmPatientPhoneNo"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="crmPatientEmailAddress">Email<p-sortIcon field="crmPatientEmailAddress"></p-sortIcon></th>
              <th class="table-outer__th" pSortableColumn="isActive">Status<p-sortIcon field="isActive"></p-sortIcon></th>
              <!--<th width="120">Actions</th>-->
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-patient>
            <!-- <tr [pSelectableRow]="rowData"> -->
            <tr>
              <td class="table-outer__td">
                <span class="link">
                  {{patient.crmPatientFullName}}
                  <span class="sub-text">{{patient?.branchName}}</span>
                </span>
              </td>
              <td class="table-outer__td">{{patient.crmRcmPatientid}}</td>
              <td class="table-outer__td">{{patient.crmPatientAddress}}</td>
              <td class="table-outer__td">{{patient.crmPatientPhoneNo}}</td>
              <td class="table-outer__td">{{patient.crmPatientEmailAddress}}</td>
              <td class="table-outer__td">{{patient.isActive ? 'Active' : 'Inactive'}}</td>
              <!--<td class="table-outer__td">
                <div class="action-column">
                  <a class="actionmenu-btn" href="javascript:void(0)"
                     (click)="selectDoctor($event,doctor,therapistsAction)">
                    <i class="ic dot"></i>
                  </a>
                </div>
              </td>-->
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr class="primeng-table__body">
              <td colspan="9" class="table-outer__td no-records-found-appointment">
                {{noRecordsFoundMessage}}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft"
                       class="table-pagination">
            <span class="table-pagination__records">

              <!-- <p>Page : <strong>{{this.searchRequestModel.PageIndex + 1}} of {{totalPages}}</strong></p> -->
              <p>Total Records : &nbsp;<strong>{{totalRecords}}</strong></p>
              <!-- <select class="form__control form__control__custom"
                      (change)="onPageSizeChanged($event.target.value)">
                  <option *ngFor="let pageSize of pageSizes">{{pageSize}}</option>
              </select> -->
              <p> Page size: {{pageSizes}}</p>
            </span>
          </ng-template>
        </p-table>
      </div>
    </p-scrollPanel>
  </div>
</div>
<!--End Content -->

<p-overlayPanel class="table-action-popup facilities-action" #therapistsAction>
  <ul class="quick-link-menu">
    <!--<li><a href="javascript:void(0)" (click)="onEdit(selectedDoctor.crmDoctorId)"><i class="edit-icon-new"></i>Edit</a></li>
    <li><a href="javascript:void(0)" (click)="onDelete(selectedDoctor.crmDoctorId)"><i class="delete-icon-new"></i> Delete</a></li>-->
  </ul>
</p-overlayPanel>
