import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth, getDate, isAfter } from 'date-fns';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarEventTitleFormatter } from 'angular-calendar';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { TherapistService } from 'src/app/admin/servicies/therapist.service';
import { AvailableSlots, HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots'
import { TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots'
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { CustomEventTitleFormatter } from 'src/app/admin/calendar/view-therapist-availability/custom-event-title-formatter.provider';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { TherapistCustomDates } from 'src/app/shared/models/request/therapist/therapist-custom-dates';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Location } from '@angular/common';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdownList, FacilityResponseModelDefaultTime } from 'src/app/shared/models/response/facility/facility-response-model';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { MainSeriesData, TherapistCustomAvailabilityResponseModel, TherapistCustomAvailabilityResponseModelForMergeMessage } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import * as moment from 'moment';
import * as $ from 'jquery';
import { DeleteAvailabilitySeriesModel } from 'src/app/shared/models/response/therapist/therapist-availability-delete-series';
import { request } from 'http';
import { OverrideMergeDialogComponent } from 'src/app/shared/components/override-merge-dialog/override-merge-dialog.component';
import { ManageAvailabilityService } from '../../servicies/manage-availability.service';

@Component({
  selector: 'app-view-therapist-availability-v1',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './view-therapist-availability-v1.component.html',
  styleUrls: ['./view-therapist-availability-v1.component.sass'],
  providers: [DialogService,
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    },
  ]
})
export class ViewTherapistAvailabilityV1Component extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  availableSlots: AvailableSlots[] = [];
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  title = "";
  therapistId: any;
  therapitsAvailability: TherapistAvailability[] = [];
  appFormatDate: DateFormatModel;
  therapistCustomDates: TherapistCustomDates;
  availableId: number = 0;
  customId: number;
  customDate: Date;
  startTime: Date;
  endTime: Date;
  event: string;
  overlaypanel: OverlayPanel;
  editEvent: boolean = false;
  eventText: string = "";
  selectedValue: string = "0";
  defaultFacility: number = 0;
  defaultFacilityName: string = "";
  AppointmentStartLocation: string = "";
  isFullDay: boolean = false;
  isEditEvent: boolean = false;
  loadingCustom: boolean = false;
  SelectFacility1: string = 'Both Facility & Remote';
  holidays: any[] = [];
  timeselectorOpen = false;
  selectedFacilityTimeZone: string = "";
  clickStartDate: Date;
  clickEndDate: Date;
  deleteAvailabilitySeriesModel: DeleteAvailabilitySeriesModel;
  ManageAvailabilitySidebar: boolean = false;
  oldtherapistCustomAvailabilityId: any[];
  isEditSeries: boolean = false;
  isSeries: boolean = false;
  isRemoteEvent: boolean = false;
  isFacilityEvent: boolean = false;
  isRemoteAndFacilityEvent: boolean = false;
  isRoadShowEvent: boolean = false;
  isOfficeEvent: boolean = false;
  eventBlockSlotsList: any[];
  eventBreakSlotsList: any[];
  isCustom: boolean = false;
  isFetchFromMainSeries: boolean = false;
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();
  todayDate: Date = new Date();
  FetchMainSeriesPopup: boolean = false;
  YesResetPopup: boolean = false;
  OrgtherapistId: number = 0;
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  currentUserBranch: number;
  Facility: any[];
  SelectFacility: any[];
  RemoteAndFacility: string = '';
  defaultModeofAppointment: string = '';
  //Add Edit Events

  showPopup: boolean = false;
  allfacilities: FacilityResponseModelDropdownList[];
  allfacilitiesRoadHome: FacilityResponseModelDropdownList[];
  facilities: FacilityResponseModelDropdownList[];
  selectedFacility: FacilityResponseModelDropdownList;
  facilitiesRemote: FacilityResponseModelDropdownList[];
  selectedFacilityRemote: FacilityResponseModelDropdownList;
  time: FacilityResponseModelDefaultTime;
  weekDays: EnumModel[] = [];
  selectedWeekDays: EnumModel[] = [];
  selectedWeekDay: EnumModel;
  eventSeries: EnumModel[];
  prevEventSeries: EnumModel;
  selectedEventSeries: EnumModel;
  therapistAvailability: TherapistCustomAvailabilityResponseModel;
  breakStartTime: string;
  breakEndTime: string;
  description: string;
  currentUserToken: UserAuthResponseModel;
  customStartDate: Date;
  customStartTime: string;
  customEndDate: Date;
  customEndTime: string;
  loggedInUserId: number;
  selectFacility: string;
  selecteRec: boolean;
  isBreakHours: boolean = false;
  isBreakHoursDisplayed: boolean = false;
  isSetRecurrenceDisplayed: boolean = false;
  isFacilityDisplayed: boolean;
  isExactDate: boolean = false;
  errorcode: number;
  //selectedOccurrence: { name: string, code: number };
  selectedOccurrenceNumber: number;
  isAppointmentWithRoadHome: boolean = false;
  Occurrence = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
  ];
  selectedExactDate: { name: string, code: number };
  ExactDate = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
    { name: '6', code: 6 },
    { name: '7', code: 7 },
    { name: '8', code: 8 },
    { name: '9', code: 9 },
    { name: '10', code: 10 },
    { name: '11', code: 11 },
    { name: '12', code: 12 },
    { name: '13', code: 13 },
    { name: '14', code: 14 },
    { name: '15', code: 15 },
    { name: '16', code: 16 },
    { name: '17', code: 17 },
    { name: '18', code: 18 },
    { name: '19', code: 19 },
    { name: '20', code: 20 },
    { name: '21', code: 21 },
    { name: '22', code: 22 },
    { name: '23', code: 23 },
    { name: '24', code: 24 },
    { name: '25', code: 25 },
    { name: '26', code: 26 },
    { name: '27', code: 27 },
    { name: '28', code: 28 },
    { name: '29', code: 29 },
    { name: '30', code: 30 },
    { name: '31', code: 31 }
  ];
  TimeSelectionList: { name: string; }[] = [];
  StartTimeSelections: { name: string; }[];
  EndTimeSelections: { name: string; }[];
  StartBreakTimeSelections: { name: string; }[];
  EndBreakTimeSelections: { name: string; }[];
  Monthly: { name: string, code: number }[];
  selectedExactMonth: { name: string, code: number };
  therapistCustomAvailabilityMergeMessage: TherapistCustomAvailabilityResponseModelForMergeMessage;
  isRoadShow: boolean = false;
  oldcustomId: number = 0;
  therapistCustomAvailabilityInfo: TherapistCustomAvailabilityResponseModel;
  mainSeriesData: MainSeriesData;

  InfoSidebar: boolean = false;
  LegendsisActive: boolean;
  //Add Edit Events

  constructor(private modal: NgbModal,
    private router: Router,
    private location: Location,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    protected cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private therapistService: ManageAvailabilityService,
    private dialougeService: DialogService,
    private facilityService: FacilityService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.calendarActions = [
      { name: 'View By Facility', code: 'ViewByFacility' },
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'View By Available Slots', code: 'ViewByAvailableSlots' }
    ];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedTime = `${(hour % 12 || 12).toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${(hour < 12 ? 'am' : 'pm')}`;
        this.TimeSelectionList.push({ name: formattedTime });
      }
    }

    this.StartTimeSelections = [... this.TimeSelectionList];
    this.EndTimeSelections = [... this.TimeSelectionList];
    this.StartBreakTimeSelections = [... this.TimeSelectionList];
    this.EndBreakTimeSelections = [... this.TimeSelectionList];

    this.Monthly = [
      { name: 'January', code: 1 },
      { name: 'February', code: 2 },
      { name: 'March', code: 4 },
      { name: 'April', code: 8 },
      { name: 'May', code: 16 },
      { name: 'June', code: 32 },
      { name: 'July', code: 64 },
      { name: 'August', code: 128 },
      { name: 'September', code: 256 },
      { name: 'October', code: 512 },
      { name: 'November', code: 1024 },
      { name: 'December', code: 2048 }
    ];

    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.appFormatDate = this.webStorageService.getDateFormat();
    if (this.webStorageService.getCurrentUserToken())
      this.loggedInUserId = this.webStorageService.getCurrentUserToken().userId;
    // fix the default Appointment Mode issue
    this.RemoteAndFacility = "FacilityOnly";
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    if (this.defaultModeofAppointment != undefined) {
      if (this.defaultModeofAppointment.toLowerCase() == 'remotesetup') {
        this.RemoteAndFacility = 'RemoteOnly';
      }
      else if (this.defaultModeofAppointment.toLowerCase() == 'facilityvisit') {
        this.RemoteAndFacility = 'FacilityOnly';
      }
      else if (this.defaultModeofAppointment.toLowerCase() == 'roadhomevisit') {
        this.RemoteAndFacility = 'R';
      }
    }
    this.defaultModeofAppointment = this.RemoteAndFacility;

    this.getDaysOfWeek();
    this.getEventSeries()
  }

  async ngOnInit() {
    this.isFetchFromMainSeries = false;
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = params['id'];
      });
    await this.getFacilities();
    await this.getTherapist();
    await this.getFacilitiesRoadHomeVisit();
    await this.getTherapistAvailability(true);
    await this.getHolidayList(false);

    this.cdr.detectChanges();
    this.refresh.next();

    if (!this.LegendsisActive) {
      $("body").removeClass("show-legends");
    }
  }

  async getFacilities() {

    this.progress.next(true);
    this.facilityService.getAllFacilityDropDownforAvailability(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.allfacilities = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async getFacilitiesRoadHomeVisit() {
    this.progress.next(true);
    this.facilityService.GetAllFacilitiesForRoadHomeVisit(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.allfacilitiesRoadHome = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  setFacilityName() {
    this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
  }

  async getTime() {
    this.progress.next(true);
    var day = 1;
    if (new Date().getDay() == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = new Date().getDay() - 1;
      day = Math.pow(2, power);
    }
    let FacilityTimeZone = null;
    let selectedFacilityId = 0;

    if (this.selectedFacility?.crmFacilityId) {
      selectedFacilityId = this.selectedFacility?.crmFacilityId;
      FacilityTimeZone = this.selectedFacility.crmTimeZone
    }
    else if (this.selectedFacilityRemote) {
      selectedFacilityId = this.selectedFacilityRemote?.crmFacilityId;
      FacilityTimeZone = this.selectedFacilityRemote.crmTimeZone;
    }

    if (this.selectedFacilityTimeZone != null && this.selectedFacilityTimeZone != undefined && FacilityTimeZone == null) {
      FacilityTimeZone = this.selectedFacilityTimeZone;
    }
    if (FacilityTimeZone == null || FacilityTimeZone == "") {
      FacilityTimeZone = "null";
    }
    this.facilityService.getTimeWithTimeZone(selectedFacilityId, day, FacilityTimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result || !result.responseModel) {
          return;
        }
        this.time = result.responseModel;

        this.customStartTime = this.time.dtStart.toLowerCase();
        this.customEndTime = this.time.dtEnd.toLowerCase();
        this.updateEndTimeOptions(this.customStartTime, false);
        this.updateBreakEndTimeOptions(this.customStartTime, this.customEndTime);
        //this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getFacilitiesPopupeExt(isRemote: boolean) {

    this.facilities = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    if (this.availableId <= 0 && !isRemote) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.getTime();
      }
    }
    //this.selectedFacility = this.facilities[0];
  }

  getFacilitiesForRoadHomeVisit() {
    this.facilities = this.allfacilitiesRoadHome;
    if (this.availableId <= 0) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.getTime();
      }
    }
    //this.selectedFacility = this.facilities[0];
  }


  getFacilitiesPopupeRemote(isRemote: boolean) {

    this.facilitiesRemote = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    if (this.facilitiesRemote != null && this.facilitiesRemote.length > 0) {
      this.selectedFacilityRemote = this.facilitiesRemote[0]; //retrive 1st element
    }
    if (this.availableId <= 0 && !isRemote) {
      if (this.defaultFacility > 0) {
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.getTime();
      }
    }
  }

  SetEvents() {

    // this.isBreakHoursDisplayed = false;
    // this.isSetRecurrenceDisplayed = false;
    // this.isBreakHours = false;
    // this.selecteRec = false;
    // this.isFullDay = false;
    // switch (this.selectedEvent) {
    //   case "WOT":
    //     this.getFacilitiesPopupeExt(false);
    //     this.getFacilitiesPopupeRemote(true);
    //     this.isBreakHoursDisplayed = true;
    //     this.isExactDate = false;
    //     this.isSetRecurrenceDisplayed = true;
    //     break;
    //   case "R":
    //     this.getFacilitiesForRoadHomeVisit();
    //     this.isSetRecurrenceDisplayed = true;
    //     this.isBreakHoursDisplayed = true;
    //     this.isExactDate = false;
    //     this.selectedFacility = null;
    //     this.selectedFacilityTimeZone = "";

    //     break;
    //   case "O":
    //     this.isSetRecurrenceDisplayed = true;
    //     this.isBreakHoursDisplayed = true;
    //     this.isExactDate = false;
    //     this.selectedFacility = null;
    //     this.selectedFacilityTimeZone = "";
    //     break;
    //   default:
    //     this.isBreakHoursDisplayed = false;
    //     this.isExactDate = false;
    //     this.selectedFacility = null;
    //     this.selectedFacilityTimeZone = "";
    //     break;

    // }
  }
  SetEventsFacilityOption() {
    //this.AppointmentStartLocation = '';
    //this.isBreakHoursDisplayed = false;
    //this.isSetRecurrenceDisplayed = false;
    switch (this.RemoteAndFacility) {
      case "FacilityOnly":
        this.isBreakHoursDisplayed = true;
        this.getFacilitiesPopupeExt(false);
        break;
      case "RemoteOnly":
        this.isBreakHoursDisplayed = true;
        this.getFacilitiesPopupeRemote(true);
        break;
      case "Both":
        this.isBreakHoursDisplayed = true;
        this.getFacilitiesPopupeExt(false);
        this.getFacilitiesPopupeRemote(true);
        break;
      case "R":

        this.getFacilitiesForRoadHomeVisit();
        this.isSetRecurrenceDisplayed = true;
        this.isBreakHoursDisplayed = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;
      case "O":
        this.isSetRecurrenceDisplayed = true;
        this.isBreakHours = false;
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        this.selectedFacilityTimeZone = "";
        break;
      default:
        this.getFacilitiesPopupeExt(false);
        //this.getFacilitiesForRoadHomeVisit()
        break;
    }
  }
  SetFacilityEventsForRoadShowOption() {
    this.getFacilitiesForRoadHomeVisit();
  }


  // setEventsExtended() {
  //   if (this.selectedEvent1 == "A") {
  //     this.RemoteAndFacility = "FacilityOnly";
  //   }
  //   this.SetEvents1();
  // }
  SetEvents1() {
    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    this.isFullDay = false;

    if (this.RemoteAndFacility == 'RemoteOnly') {
      this.getFacilitiesPopupeRemote(true);
    }
    else {
      this.getFacilitiesPopupeExt(false);
    }


    this.isBreakHoursDisplayed = true;
    this.isExactDate = false;
    this.isSetRecurrenceDisplayed = true;
  }

  getDaysOfWeek() {
    this.progress.next(true);
    this.therapistService.getDaysOfWeek()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.weekDays = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setEventText() {

    // if (this.selectedOccurrence && this.selectedEventSeries) {
    //   if (this.selectedEventSeries.value == 2) {
    //     if (this.selectedOccurrence.code == 1) {
    //       this.eventText = 'Every Week';
    //     }
    //     else {
    //       this.eventText = 'Every ' + this.selectedOccurrence.code.toString() + ' Weeks';
    //     }
    //   }
    //   if (this.selectedEventSeries.value == 3) {
    //     if (this.selectedOccurrence.code == 1) {
    //       if (this.selectedExactDate && this.selectedExactDate.code == 1)
    //         this.eventText = this.selectedExactDate.code + 'st of Every Month';
    //       else if (this.selectedExactDate.code == 2)
    //         this.eventText = this.selectedExactDate.code + 'nd of Every Month';
    //       else if (this.selectedExactDate.code == 3)
    //         this.eventText = this.selectedExactDate.code + 'rd of Every Month';
    //       else
    //         this.eventText = this.selectedExactDate.code + 'th of Every Month';
    //     }
    //     else {
    //       if (this.selectedExactDate && this.selectedExactDate.code == 1)
    //         this.eventText = this.selectedExactDate.code + 'st of Every ' + this.selectedOccurrence.code.toString() + ' Months';
    //       else if (this.selectedExactDate && this.selectedExactDate.code == 2)
    //         this.eventText = this.selectedExactDate.code + 'nd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
    //       else if (this.selectedExactDate && this.selectedExactDate.code == 3)
    //         this.eventText = this.selectedExactDate.code + 'rd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
    //       else
    //         this.eventText = this.selectedExactDate.code + 'th of Every ' + this.selectedOccurrence.code.toString() + ' Months';
    //     }
    //   }
    //   if (this.selectedEventSeries.value == 4) {
    //     if (this.selectedExactDate && this.selectedOccurrence.code == 1) {
    //       if (this.selectedExactDate.code == 1)
    //         this.eventText = this.selectedExactDate.code + 'st Jan of Every Year';
    //       else if (this.selectedExactDate.code == 2)
    //         this.eventText = this.selectedExactDate.code + 'nd Jan of Every Year';
    //       else if (this.selectedExactDate.code == 3)
    //         this.eventText = this.selectedExactDate.code + 'rd Jan of Every Year';
    //       else
    //         this.eventText = this.selectedExactDate.code + 'th Jan of Every Year';
    //     }
    //     else {
    //       if (this.selectedExactDate && this.selectedExactDate.code == 1)
    //         this.eventText = this.selectedExactDate.code + 'st Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
    //       else if (this.selectedExactDate && this.selectedExactDate.code == 2)
    //         this.eventText = this.selectedExactDate.code + 'nd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
    //       else if (this.selectedExactDate && this.selectedExactDate.code == 3)
    //         this.eventText = this.selectedExactDate.code + 'rd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
    //       else
    //         this.eventText = this.selectedExactDate.code + 'th Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
    //     }
    //   }
    // }
  }

  getEventSeries() {
    this.progress.next(true);
    this.therapistService.getEventSeries()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.eventSeries = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onChangeEventSeries(item: EnumModel) {
    // 
    this.selectedEventSeries = item;
    item.isSelected = true;
    this.selectedEventSeries.isSelected = true;
    switch (item.name) {
      case "None":
        this.isExactDate = false;
        this.selecteRec = false;
        break;
      case "Weekly":
        this.isExactDate = false;
        this.selecteRec = true;
        break;
      case "Monthly":
        this.isExactDate = true;
        this.selecteRec = true;
        break;
      case "Yearly":
        this.isExactDate = true;
        this.selecteRec = true;
        break;
      default:
        break;

    }
    //this.setEventText();
  }

  onChangeDayOfWeek(item: EnumModel) {
    item.isSelected = !item.isSelected;
  }

  onEventOccurrenceChange(occurrence) {
    //this.selectedOccurrenceNumber = occurrence.value;
    //this.setEventText();
  }

  onExactDateChange() {
    //this.setEventText();
  }

  getAvailability(isFetchForMainSeries) {
    if (this.availableId && this.availableId <= 0) {
      return;
    }
    this.progress.next(true);

    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.getAvailabilityNewById(this.availableId, this.customId, isFetchForMainSeries)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.therapistAvailability = result.responseModel;
          this.isAppointmentWithRoadHome = result.responseModel.isAppointmentWithRoadHome;
          this.setValuesToForm();
          if (this.therapistAvailability.isCustom) {
            this.mainSeriesData = new MainSeriesData();
            this.therapistCustomAvailabilityInfo = this.therapistAvailability;
            if (this.therapistCustomAvailabilityInfo.isRemoteAndFacility) {
              this.mainSeriesData.mainAppointmentType = 'Both Facility & Remote'
            }
            if (this.therapistCustomAvailabilityInfo.isRemoteAssistant) {
              this.mainSeriesData.mainAppointmentType = 'Remote Setup'
            }
            if (this.therapistCustomAvailabilityInfo.crmIsRoadShow) {
              this.mainSeriesData.mainAppointmentType = 'Road / Home Visit'
            }
            if (this.therapistCustomAvailabilityInfo.isOffice) {
              this.mainSeriesData.mainAppointmentType = 'Office / Not Available'
            }
            if (this.therapistCustomAvailabilityInfo.crmFacilityId > 0 && (!this.therapistCustomAvailabilityInfo.isRemoteAndFacility && !this.therapistCustomAvailabilityInfo.isRemoteAssistant
              && !this.therapistCustomAvailabilityInfo.crmIsRoadShow && !this.therapistCustomAvailabilityInfo.isOffice)) {
              this.mainSeriesData.mainAppointmentType = 'Facility Visit'
            }
            this.mainSeriesData.mainDateRange = moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomStartDate).format('ddd') + ', ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomStartDate).format('MM/DD/yyyy') + ' - ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomEndDate).format('ddd') + ', ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomEndDate).format('MM/DD/yyyy');
            this.mainSeriesData.mainTime = this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.customStartTimeString) + ' - ' + this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.customEndTimeString) + (this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone ? (' | ' + this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone) : '')
            if (this.therapistCustomAvailabilityInfo.crmBreakStartTimeString) {
              this.mainSeriesData.mainBreakhours = this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmBreakStartTimeString) + ' - ' + this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmBreakEndTimeString) 
              // + ' | ' + this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone
            }
            else {
              this.mainSeriesData.mainBreakhours = "NA"
            }
            var getCustomDates = this.therapitsAvailability.filter(x => x.isCustom);
            if (getCustomDates.length != 0) {
              this.mainSeriesData.currentSeriesDateRange = moment(getCustomDates[0].startTime).format('ddd') + ', ' + moment(getCustomDates[0].startTime).format('MM/DD/yyyy') + ' - ' + moment(getCustomDates[getCustomDates.length - 1].startTime).format('ddd') + ', ' + moment(getCustomDates[getCustomDates.length - 1].startTime).format('MM/DD/yyyy');

            }


          }
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async setValuesToForm() {
    this.isRoadShow = false
    this.therapistId = this.therapistAvailability.enUserInfoId;

    if (this.therapistAvailability.crmIsHoliday) {
      this.SetEvents1();
    }
    else if (this.therapistAvailability.crmIsRoadShow) {
      this.RemoteAndFacility = "R";
      this.isRoadShow = true
      if (this.therapistAvailability.isStartFromDefaultFacility)
        this.AppointmentStartLocation = 'isStartFromDefaultFacility';
      else if (this.therapistAvailability.isStartFromHomeAddress)
        this.AppointmentStartLocation = 'isStartFromHomeAddress';
      else
        this.AppointmentStartLocation = '';

      this.SetEventsFacilityOption();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
      if (this.therapistAvailability.isStartFromDefaultFacility) {
        this.getFacilitiesRoadHomeVisit();
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }
    }
    else if (this.therapistAvailability.isOffice) {
      this.RemoteAndFacility = "O";
      this.SetEventsFacilityOption();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
    }
    else {
      if (this.therapistAvailability.isRemoteAndFacility) {
        this.RemoteAndFacility = "Both";
        this.selecteRec = false;
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmRemoteFacilityId);
        this.SetEventsFacilityOption();
      }
      else if (this.therapistAvailability.isRemoteAssistant) {
        this.RemoteAndFacility = "RemoteOnly";
        this.SetEventsFacilityOption();
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
      }
      else {
        this.RemoteAndFacility = "FacilityOnly";
        this.selecteRec = false;
        this.SetEventsFacilityOption();
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }


    }
    if (this.therapistAvailability.crmIsRecurring) {
      this.selecteRec = this.therapistAvailability.crmIsRecurring;
    }
    if (this.therapistAvailability.crmBreakEndTimeString) {
      this.breakEndTime = this.convertTo12HourFormat(this.therapistAvailability.crmBreakEndTimeString.toString());
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistAvailability.crmBreakStartTimeString) {
      this.breakStartTime = this.convertTo12HourFormat(this.therapistAvailability.crmBreakStartTimeString.toString());
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
      this.updateEndTimeOptions(this.breakStartTime, true);
    }

    this.description = this.therapistAvailability.crmDescription;
    this.selectedOccurrenceNumber = this.therapistAvailability.crmRecurringFrequency;
    this.selectedExactMonth = this.therapistAvailability.monPattern != null ? this.Monthly.find(x => x.code == this.therapistAvailability.monPattern) : null;
    this.customEndDate = new Date(this.therapistAvailability.crmTherapistCustomEndDate);
    this.customEndDate.setHours(0, 0, 0, 0);
    this.customEndTime = this.convertTo12HourFormat(this.therapistAvailability.crmTherapistCustomEndTimeString);
    this.customStartDate = new Date(this.therapistAvailability.crmTherapistCustomStartDate);
    this.customStartDate.setHours(0, 0, 0, 0);
    this.customStartTime = this.convertTo12HourFormat(this.therapistAvailability.crmTherapistCustomStartTimeString);
    //this.todayDate = this.customStartDate;
    this.updateEndTimeOptions(this.customStartTime, false);
    if (this.therapistAvailability.exactDay) {
      this.selectedExactDate = this.ExactDate.find(x => x.code == this.therapistAvailability.exactDay);
      this.isExactDate = true;
    }
    // this.weekDays.forEach((value) => {
    //   if (this.therapistAvailability.crmCustomDays.split(',').length > 0) {
    //     this.therapistAvailability.crmCustomDays.split(',').forEach((item) => {
    //       if (item == value.value.toString()) {
    //         value.isSelected = true;
    //         value.isDisabled = false;
    //       }
    //     });
    //   }
    // });
    this.SetDaysForEditSeries();

    this.selectedValue = this.therapistAvailability.crmRecurringType.toString();
    this.eventSeries.forEach((value) => {
      if (this.therapistAvailability.crmRecurringType == value.value) {
        value.isSelected = true;
        this.selectedEventSeries = value;
      }
    });

    if (this.selectedEventSeries) {
      switch (this.selectedEventSeries.name) {
        case "None":
          this.isExactDate = false;
          break;
        case "Weekly":
          this.isExactDate = false;
          break;
        case "Monthly":
          this.isExactDate = true;
          break;
        case "Yearly":
          this.isExactDate = true;
          break;
        default:
          break;

      }
    }
    //this.setEventText();
    this.isFullDay = this.therapistAvailability.isFullDay;
    this.isEditEvent = false;
    // this.showPopup = true;
    this.ManageAvailabilitySidebar = true;

    this.cdr.detectChanges();
    this.refresh.next();
  }

  async onSave() {
    if (!this.validateForm()) {
      return;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeString = this.isBreakHours ? moment(this.breakEndTime, 'hh:mm A').format("HH:mm") : null;
    requestModel.crmBreakStartTimeString = this.isBreakHours ? moment(this.breakStartTime, 'hh:mm A').format("HH:mm") : null;
    requestModel.isStartFromDefaultFacility = this.RemoteAndFacility == "R" && this.AppointmentStartLocation == 'isStartFromDefaultFacility' ? true : false;
    requestModel.isStartFromHomeAddress = this.RemoteAndFacility == "R" && this.AppointmentStartLocation == 'isStartFromHomeAddress' ? true : false;
    requestModel.crmDescription = this.RemoteAndFacility == "O" ? this.description : "";
    if (this.RemoteAndFacility == 'FacilityOnly' || this.RemoteAndFacility == 'Both') {
      requestModel.crmFacilityId = this.selectedFacility.crmFacilityId;
      requestModel.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
    }
    else if (this.RemoteAndFacility == 'RemoteOnly') {
      requestModel.crmFacilityId = this.selectedFacilityRemote.crmFacilityId;
      var selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
      if (selectedFacilityTimeZone != null && selectedFacilityTimeZone != undefined) {
        if (selectedFacilityTimeZone.crmTimeZone != null && selectedFacilityTimeZone.crmTimeZone != undefined && selectedFacilityTimeZone.crmTimeZone != "") {
          requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
        }
        else {
          requestModel.selectedFacilityTimeZone = "UTC-5";
        }
      }
      else {
        requestModel.selectedFacilityTimeZone = "UTC-5";
      }
    }
    else {
      requestModel.crmFacilityId = null;
      if (this.RemoteAndFacility == 'R') {
        if (requestModel.isStartFromDefaultFacility) {
          requestModel.crmFacilityId = this.selectedFacility.crmFacilityId;
          requestModel.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        }
        else if (this.defaultFacility != 0) {
          requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
        }
      }
      else {
        requestModel.selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility).crmTimeZone;
      }

      if (this.RemoteAndFacility == "O") {
        var selectedFacilityTimeZone = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        if (selectedFacilityTimeZone != null && selectedFacilityTimeZone != undefined) {
          if (selectedFacilityTimeZone.crmTimeZone != "") {
            requestModel.selectedFacilityTimeZone = selectedFacilityTimeZone.crmTimeZone;
          }
          else {
            requestModel.selectedFacilityTimeZone = "UTC-5";
          }
        }

      }
    }

    requestModel.crmRemoteFacilityId = this.RemoteAndFacility == 'Both' ? this.selectedFacilityRemote.crmFacilityId : null;
    requestModel.isRemoteAndFacility = this.RemoteAndFacility == 'Both' ? true : false;
    requestModel.crmIsHoliday = false;//this.selectedEvent1 == "NA";
    requestModel.crmIsRecurring = this.selecteRec;
    requestModel.crmIsRoadShow = this.RemoteAndFacility == "R";
    requestModel.isRemoteAssistant = this.RemoteAndFacility == 'RemoteOnly';
    requestModel.crmRecurringFrequency = this.selecteRec ? this.selectedOccurrenceNumber : 1;
    requestModel.crmRecurringType = this.selecteRec ? this.selectedEventSeries.value : 1;

    // var getCustomDates = this.therapitsAvailability.filter(x => x.isCustom);
    // if (getCustomDates.length != 0) {
    //   requestModel.crmTherapistCustomEndDateString = moment(getCustomDates[getCustomDates.length - 1].startTime).format("yyyy/MM/DD");
    //   requestModel.crmTherapistCustomStartDateString = moment(getCustomDates[0].startTime).format("yyyy/MM/DD");
    // }
    //else {
    requestModel.crmTherapistCustomEndDateString = moment(this.customEndDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomStartDateString = moment(this.customStartDate).format("yyyy/MM/DD");
    //}

    requestModel.crmTherapistCustomEndTimeString = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

    requestModel.crmTherapistCustomStartTimeString = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistId = this.OrgtherapistId;
    requestModel.crmTherpistCustomAvailabilityId = this.availableId;
    requestModel.isSeries = moment(this.customEndDate).diff(this.customStartDate) > 1 ? true : false;
    requestModel.oldCrmTherpistCustomAvailabilityId = this.oldtherapistCustomAvailabilityId;
    requestModel.operationType = (this.isEditEvent || this.isSeries || this.isEditSeries) ? 'Edit' : 'Add'
    if (this.isEditEvent) {
      requestModel.customId = this.customId;
    }
    requestModel.crmIsExactDay = this.isExactDate;
    requestModel.crmCustomDays = "";
    requestModel.isOffice = this.RemoteAndFacility == "O";
    requestModel.modifiedByUserInfoId = this.loggedInUserId;
    requestModel.isFullDay = this.isFullDay;
    if (this.weekDays.length > 0) {
      this.weekDays.forEach((value) => {
        if (value.isSelected) {
          requestModel.crmCustomDays += value.value + ',';
        }
      })
    }

    if (this.selectedEventSeries) {
      if (this.selectedEventSeries.name == "Monthly") {
        requestModel.monPattern = 4095;
        requestModel.crmCustomDays = "1,2,4,8,16,32,64,"; //Select all week days
      }

      if (this.selectedEventSeries.name != "Weekly" && this.selectedExactDate) {
        requestModel.exactDay = this.selectedExactDate.code;
      }
      else {
        requestModel.exactDay = null;
      }

      if (this.selectedEventSeries.name == "Yearly") {
        requestModel.yearFrequcncy = 1;//this.selectedOccurrenceNumber;
        requestModel.monPattern = this.selectedExactMonth?.code;
        requestModel.crmCustomDays = "1,2,4,8,16,32,64,"; //Select all week days
      }

      if (this.selectedEventSeries.name == "None") {
        requestModel.monPattern = undefined;
        requestModel.exactDay = undefined;
        requestModel.yearFrequcncy = undefined;
      }
    }



    if (!this.isEditEvent) {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        if (this.availableId == 0) {
          this.SetAvailability(requestModel);
        }
        else {
          requestModel.oldCrmTherpistCustomAvailabilityId = [];
          this.UpdateAvailability(requestModel);
        }

      }
      else if (this.errorcode == 2) {
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            //message: 'There is already availability set for this period, do you wish to override or merge the availability.',
            action: 'Validation',
            popupHeader: 'Alert',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Okay',
            weekDays: this.weekDays,
            isBreak: this.therapistCustomAvailabilityMergeMessage.isBreak
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: any) => {

        });
      }
      else if (this.errorcode == 3) {
        if (this.isEditSeries) {
          this.dialougeService.open(OverrideMergeDialogComponent, {
            data:
            {
              //message: 'There is already availability set for this period, do you wish to override or merge the availability.',
              action: 'Edit',
              popupHeader: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched ? 'Alert' : 'Confirm Edit Series',
              therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
              cancelButtonText: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched ? 'Okay' : 'No, Cancel',
              updateButtonText: 'Yes, Update',
              weekDays: this.weekDays,
              isAllReccuringDatesMatched: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: any) => {
            if (response == 'Update') {
              requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customAppointmentDetails;
              requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customBlockDetails;
              requestModel.customDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails;
              requestModel.oldCrmTherpistCustomAvailabilityId = [];
              this.UpdateAvailability(requestModel);
            }
          });
        }
        else {
          this.dialougeService.open(OverrideMergeDialogComponent, {
            data:
            {
              action: 'Add',
              popupHeader: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched ? 'Alert' : 'Confirm Availability',
              therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
              cancelButtonText: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched ? 'Okay' : 'Cancel',
              updateButtonText: 'Merge with Existing',
              weekDays: this.weekDays,
              isAllReccuringDatesMatched: this.therapistCustomAvailabilityMergeMessage.isAllReccuringDatesMatched
              //note:'you have option to manage your new availability by choosing any following actions:'
              //mergeExistingMessage:
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: any) => {
            if (response == true && this.availableId == 0) {
              requestModel.isOverride = true;
              requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customAppointmentDetails;
              requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customBlockDetails;
              requestModel.customDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails;
              // requestModel.crmTherpistCustomAvailabilityId = this.oldtherapistCustomAvailabilityId[0];
              this.OverrideAvailability(requestModel);
            }
            else if (response == 'Merge') {

              requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customAppointmentDetails;
              requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customBlockDetails;
              requestModel.customDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails;
              requestModel.isMergeWithExisting = true;
              this.SetAvailability(requestModel);
            }
          });
        }

      }

      else if (this.errorcode == 5) { // roadHome
        this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails = null;
        //here we dont required to show custom availbility as user will add same availbility 
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            action: 'Add',
            popupHeader: 'Confirm Availability',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Cancel',
            updateButtonText: 'Merge with Existing',
            weekDays: this.weekDays,
            ErrorCode: '5'
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            requestModel.isOverride = true;
            requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customAppointmentDetails;
            requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customBlockDetails;
            requestModel.customDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails;
            //requestModel.crmTherpistCustomAvailabilityId = this.oldtherapistCustomAvailabilityId[0];
            this.OverrideAvailability(requestModel);
          }
        });
      }

      else if (this.errorcode == 6) { //Overlapping
        this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails = null;
        //here we dont required to show custom availbility as user will add same availbility 
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            action: 'Edit',
            popupHeader: 'Alert',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Okay',
            updateButtonText: 'Merge with Existing',
            weekDays: this.weekDays,
            ErrorCode: '6'
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {

          }
        });
      }

      else if (this.errorcode == 4) {
        if (this.availableId == 0)
          this.SetAvailability(requestModel);
        else
          this.UpdateAvailability(requestModel);
        //this.dialougeService.open(InformationDialogComponent, {
        //  data:
        //  {
        //    message: 'Series can not be booked from ' + moment(this.customStartDate).format("MM/DD/yyyy") + ' - ' + moment(this.customEndDate).format("MM/DD/yyyy") + ' as there are holidays on the same dates.',
        //  },
        //  header: 'Confirmation'
        //}).onClose.subscribe((response: boolean) => {
        //  if (response) {
        //    return;
        //  }
        //});
      }
    }
    else {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        this.SetCustomAvailability(requestModel);
      }
      else if (this.errorcode == 2) {
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            //message: 'There is already availability set for this period, do you wish to override or merge the availability.',
            action: 'Validation',
            popupHeader: 'Alert',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Okay',
            weekDays: this.weekDays,
            isBreak: this.therapistCustomAvailabilityMergeMessage.isBreak
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: any) => {

        });
      }
      else if (this.errorcode == 3) {
        let PromptTitle = 'Confirm Edit Series';
        if (this.isEditEvent) {
          PromptTitle = 'Confirm Edit Event';
        }
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            //message: 'There is already availability set for this period, do you wish to override or merge the availability.',
            action: 'Edit',
            popupHeader: PromptTitle,
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'No, Cancel',
            updateButtonText: 'Yes, Update',
            weekDays: this.weekDays
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: any) => {
          requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage?.appointmentDetail?.customAppointmentDetails;
          requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage?.appointmentDetail?.customBlockDetails;
          requestModel.customDates = this.therapistCustomAvailabilityMergeMessage?.appointmentDetail?.customDateDetails;
          if (response == "Update" && this.availableId == 0) {
            // requestModel.isOverride = true;
            // requestModel.crmTherpistCustomAvailabilityId = this.oldtherapistCustomAvailabilityId;
            this.SetCustomAvailability(requestModel);
          }
          else if (response == "Update" && requestModel.oldCrmTherpistCustomAvailabilityId.length != 0) {
            this.SetCustomAvailability(requestModel);
          }
        });
      }
      else if (this.errorcode == 5) { // roadHome
        this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails = null;
        //here we dont required to show custom availbility as user will add same availbility 
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            action: 'Add',
            popupHeader: 'Confirm Availability',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Cancel',
            updateButtonText: 'Merge with Existing',
            weekDays: this.weekDays,
            ErrorCode: '5'
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            requestModel.isOverride = true;
            requestModel.appointmentDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customAppointmentDetails;
            requestModel.blockSlotDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customBlockDetails;
            requestModel.customDates = this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails;
            //requestModel.crmTherpistCustomAvailabilityId = this.oldtherapistCustomAvailabilityId[0];
            this.OverrideAvailability(requestModel);
          }
        });
      }
      else if (this.errorcode == 6) { //Overlapping
        this.therapistCustomAvailabilityMergeMessage.appointmentDetail.customDateDetails = null;
        //here we dont required to show custom availbility as user will add same availbility 
        this.dialougeService.open(OverrideMergeDialogComponent, {
          data:
          {
            action: 'Edit',
            popupHeader: 'Alert',
            therapistCustomAvailabilityMergeMessage: this.therapistCustomAvailabilityMergeMessage,
            cancelButtonText: 'Okay',
            updateButtonText: 'Merge with Existing',
            weekDays: this.weekDays,
            ErrorCode: '6'
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {

          }
        });
      }
      // else if (this.errorcode == 3) {
      //   this.dialougeService.open(ConfirmDialogComponent, {
      //     data:
      //     {
      //       message: 'There is already availability set for this period, do you wish to override the availability.',
      //     },
      //     header: 'Confirmation'
      //   }).onClose.subscribe((response: boolean) => {
      //     if (response) {
      //       this.SetCustomAvailability(requestModel);
      //     }
      //   });
      // }

      else if (this.errorcode == 4) {
        //this.dialougeService.open(InformationDialogComponent, {
        //  data:
        //  {
        //    message: 'Series can not be booked from ' + moment(this.customStartDate).format("MM/DD/yyyy") + ' - ' + moment(this.customEndDate).format("MM/DD/yyyy") + ' as there are holidays on the same dates.',
        //  },
        //  header: 'Confirmation'
        //}).onClose.subscribe((response: boolean) => {
        //  if (response) {
        //    return;
        //  }
        //});
        this.SetCustomAvailability(requestModel);
      }
    }

  }

  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    requestModel.isBookedFromCalendar = false;
    requestModel.isAddManageAvailabilityChanged = true;
    requestModel.isAddBlockAvailabilityChanged = this.isBreakHours;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.therapistService.setAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  SetCustomAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    this.cdr.detectChanges();
    requestModel.isBookedFromCalendar = false;
    requestModel.isAddManageAvailabilityChanged = true;
    requestModel.isAddBlockAvailabilityChanged = this.isBreakHours;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.therapistService.setCustomAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.cdr.detectChanges();
    requestModel.isBookedFromCalendar = false;
    requestModel.isAddManageAvailabilityChanged = true;
    requestModel.isAddBlockAvailabilityChanged = this.isBreakHours;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.therapistService.updateAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability updated successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onCancel() {
    // this.showPopup = false;
    this.ManageAvailabilitySidebar = false;

    this.onClear();
    this.getTherapistAvailability(true);
  }

  validateForm(): boolean {
    if (!this.RemoteAndFacility) {
      this.showWarningMessage('Please select Appointment Type.');
      return false;
    }
    // if (!this.selectedEvent1) {
    //   this.showWarningMessage('Please select Events Type.');
    //   return false;
    // }
    // if (this.selectedEvent1 == "A") {
    //   if (!this.RemoteAndFacility) {
    //     this.showWarningMessage('Please select Appointment Type.');
    //     return false;
    //   }
    // }
    // if (this.selectedEvent1 == "NA") {
    //   if (!this.description) {
    //     this.showWarningMessage('Please enter description.');
    //     return false;
    //   }
    // }

    switch (this.RemoteAndFacility) {
      case "FacilityOnly":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case 'RemoteOnly':
        if (!this.selectedFacilityRemote) {
          this.showWarningMessage('Please select a remote facility.');
          return false;
        }
        break;
      case 'Both':
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        if (!this.selectedFacilityRemote) {
          this.showWarningMessage('Please select a remote facility.');
          return false;
        }
        break;
      case "T":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "O":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      case "R":
        if (!this.AppointmentStartLocation) {
          this.showWarningMessage('Please select Appointment Start Location.');
          return false;
        }
        if (this.AppointmentStartLocation == 'isStartFromDefaultFacility' && !this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      default:
        break;
    }

    // switch (this.selectedEvent1) {
    //   case "W":
    //     if (!this.description) {
    //       this.showWarningMessage('Please enter description.');
    //       return false;
    //     }
    //     break;
    //   default:
    //     break;

    // }

    //Bug 7873: Days selection error - start date is grater than end date while adding availability
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be greater than Start Date.');
      return false;
    }
    if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select days of the week.');
      return false;
    }

    if (!this.customStartDate) {
      this.showWarningMessage('Please select Start Date.');
      return false;
    }
    if (!this.customEndDate) {
      this.showWarningMessage('Please select End Date.');
      return false;
    }
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be larger than Start Date.');
      return false;
    }
    if (this.customStartTime == null) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (this.customEndTime == null) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    if (new Date(commonDate + ' ' + this.customEndTime) < new Date(commonDate + ' ' + this.customStartTime)) {
      this.showWarningMessage('End Time should be greater than Start Time.');
      return false;
    }
    if (this.selecteRec) {
      if (!this.selectedOccurrenceNumber || this.selectedOccurrenceNumber == 0) {
        this.showWarningMessage('Please select Occurrence Type.');
        return false;
      }
      if (this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
        this.showWarningMessage('Please select event series.');
        return false;
      }
      if (this.selectedEventSeries.name == "Yearly") {
        if (this.selectedExactMonth == null) {
          this.showWarningMessage('Please select Occurrence month.');
          return false;
        }
      }
      if (this.selectedEventSeries.name == "Monthly" || this.selectedEventSeries.name == "Yearly") {
        if (this.selectedExactDate == null) {
          this.showWarningMessage('Please select Occurrence day.');
          return false;
        }
      }
    }
    if (this.isBreakHours) {
      if (this.breakStartTime == null) {
        this.showWarningMessage('Please select Break Start Time');
        return false;
      }
      if (this.breakEndTime == null) {
        this.showWarningMessage('Please select Break End Time');
        return false;
      }
      if (new Date(commonDate + ' ' + this.breakEndTime) < new Date(commonDate + ' ' + this.breakStartTime)) {
        this.showWarningMessage('Break End Time should be larger than Break Start Time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakStartTime) >= new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakStartTime) <= new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break Start time should be between start time and end time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakEndTime) >= new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakEndTime) <= new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break End time should be between start time and end time');
        return false;
      }

    }
    //if(!this.validateIsHolidayDate()){
    //  this.showWarningMessage('Invalid Request');
    //  return false;
    //}
    return true;
  }

  async validateRequest(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.cdr.detectChanges();

    await this.therapistService.validateAvailability(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        this.therapistCustomAvailabilityMergeMessage = new TherapistCustomAvailabilityResponseModelForMergeMessage;
        this.therapistCustomAvailabilityMergeMessage = result.responseModel;
        if (this.therapistCustomAvailabilityMergeMessage.previousAvailabilityReponses != null && this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses != null) {

          this.therapistCustomAvailabilityMergeMessage.previousAvailabilityReponses.forEach(x => {
            var preTime = x.time.split('-');
            let preSeprateTimeZone = preTime[1].split('|');
            x.startTime = this.convertTo12HourFormat(preTime[0]);
            x.endTime = this.convertTo12HourFormat(preSeprateTimeZone[0]);
            x.time = x.startTime + ' - ' + x.endTime + ' | ' + preSeprateTimeZone[1];
          })


          var currTime = this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.time.split('-');
          let currSeprateTimeZone = currTime[1].split('|');
          this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.startTime = this.convertTo12HourFormat(currTime[0]);
          this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.endTime = this.convertTo12HourFormat(currSeprateTimeZone[0]);
          this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.time = this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.startTime + ' - ' + this.therapistCustomAvailabilityMergeMessage.currentAvailabilityReponses.endTime + ' | ' + currSeprateTimeZone[1];
        }
        if (result && this.therapistCustomAvailabilityMergeMessage.errorCode == 2) {
          this.errorcode = this.therapistCustomAvailabilityMergeMessage.errorCode
        }
        else if (result && this.therapistCustomAvailabilityMergeMessage.errorCode == 3) {
          this.errorcode = this.therapistCustomAvailabilityMergeMessage.errorCode
        }
        else if (result && this.therapistCustomAvailabilityMergeMessage.errorCode == 4) {
          this.errorcode = this.therapistCustomAvailabilityMergeMessage.errorCode;
          //this.showWarningMessage(result.message);
        }
        else if (result && this.therapistCustomAvailabilityMergeMessage.errorCode == 5) {
          this.errorcode = this.therapistCustomAvailabilityMergeMessage.errorCode;
        }
        else if (result && this.therapistCustomAvailabilityMergeMessage.errorCode == 6) {
          this.errorcode = this.therapistCustomAvailabilityMergeMessage.errorCode;
        }
        else if (result && this.therapistCustomAvailabilityMergeMessage.errorCode != 0) {
          this.showWarningMessage(this.therapistCustomAvailabilityMergeMessage.message);
          this.errorcode = -1;
        }
        else {
          this.errorcode = 0;
        }

      })
      .catch((httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });
  }

  RedirectcalendarActions() {

    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByFacility")
      this.router.navigate(['/admin/calendar/view-by-facility']);
    else if (this.selectedcalendarActions.code == "ViewByTherapist")
      this.router.navigate(['/admin/calendar/view-by-therapist']);
    else if (this.selectedcalendarActions.code == "ViewByDate")
      this.router.navigate(['/admin/calendar/view-by-date']);
    else if (this.selectedcalendarActions.code == "ViewByAvailableSlots")
      this.router.navigate(['/admin/calendar/view-by-available-slots']);

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        //draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dateChange() {

    this.getTherapistAvailability(true);
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }


    if (this.progress)
      this.progress.complete();
  }

  private async getTherapistAvailability(isRefersh: boolean) {
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.therapistService.GetTherapistAvailabilityCalendar(this.therapistId, selectedDate, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => {
        this.loadingCustom = false;
        this.cdr.detectChanges();
        this.progress.next(false);
      }))
      .toPromise()
      .then((result) => {
        this.processResult<TherapistAvailability[]>(result, () => {
          this.therapitsAvailability = result.responseModel;
          var obj: Array<any> = [];
          for (var i = 0; i < this.therapitsAvailability.length; i++) {
            if (!this.therapitsAvailability[i].isHoliday && !this.therapitsAvailability[i].isOffice) {
              var event: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: moment(this.therapitsAvailability[i].startTime).toDate(),
                end: moment(this.therapitsAvailability[i].endTime).toDate(),
                title: !this.therapitsAvailability[i].isFullDay ? moment(this.therapitsAvailability[i].startTime).format("hh:mm A").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday,
                isRemoteAndFacility: this.therapitsAvailability[i].isRemoteAndFacility,
                isSeries: this.therapitsAvailability[i].isSeries,
                isCustom: this.therapitsAvailability[i].isCustom,
                therapistBlockAvailabilityList: this.therapitsAvailability[i].therapistBlockAvailabilities,
              }
              obj.push(event);
            }
            else {
              var event1: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: moment(this.therapitsAvailability[i].startTime).toDate(),
                end: moment(this.therapitsAvailability[i].endTime).toDate(),
                title: !this.therapitsAvailability[i].isFullDay ? moment(this.therapitsAvailability[i].startTime).format("hh:mm A").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday,
                isRemoteAndFacility: this.therapitsAvailability[i].isRemoteAndFacility,
                isSeries: this.therapitsAvailability[i].isSeries,
                isCustom: this.therapitsAvailability[i].isCustom,
                therapistBlockAvailabilityList: this.therapitsAvailability[i].therapistBlockAvailabilities,
              }
              obj.push(event1);
            }
          }
          this.events = obj;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onEventClick(event1, event: CalendarEvent, overlaypanel: OverlayPanel, item: any) {

    this.availableId = Number(event.id.toString().split(',')[1]);
    this.customId = Number(event.id.toString().split(',')[0]);
    this.description = item.title1;
    this.clickStartDate = event.start;
    this.clickEndDate = event.end;
    this.isSeries = item.isSeries;
    this.isRemoteEvent = item.isRemote;
    this.isFacilityEvent = item.isFacility;
    this.isRemoteAndFacilityEvent = item.isRemoteAndFacility;
    this.isRoadShowEvent = item.isRoadShow;
    this.isOfficeEvent = item.isOffice;
    this.isCustom = item.isCustom;
    if (item.therapistBlockAvailabilityList != null) {
      this.eventBlockSlotsList = item.therapistBlockAvailabilityList.filter(x => x.availablityType == 'Block');
    }
    else {
      this.eventBlockSlotsList = null;
    }
    if (item.therapistBlockAvailabilityList != null) {
      this.eventBreakSlotsList = item.therapistBlockAvailabilityList.filter(x => x.availablityType == 'Break');
    }
    else {
      this.eventBreakSlotsList = null;
    }

    if (event) {
      this.oldtherapistCustomAvailabilityId = []
      this.oldtherapistCustomAvailabilityId.push(Number(event.id.toString().split(',')[1]));
    }
    else {
      this.oldtherapistCustomAvailabilityId = [];
    }
    this.isEditSeries = false;
    let startDt = moment(this.clickStartDate).format('YYYY-MM-DD');
    let todayDt = moment(new Date()).format('YYYY-MM-DD');
    if (moment(startDt).diff(todayDt) >= 0) {
      overlaypanel.toggle(event1);
      this.cdr.detectChanges();
      this.refresh.next();
    }

  }

  EditSeries(event, element): void {
    element.hide(event);
    this.description = "";
    this.onClear();
    this.isEditSeries = true;
    //this.isFetchFromMainSeries = false;
    this.getAvailability(false);
    if (this.isCustom) {
      this.isFetchFromMainSeries = true;
      //this.getCustomAvailabilityTableInfo();
    }

    this.cdr.detectChanges();
    this.refresh.next();
  }

  Delete(event, element): void {
    element.hide(event);
    this.therapistService.GetDeleteAvailabilitySeries(Number(this.OrgtherapistId), false, this.clickStartDate.toString(), this.clickEndDate.toString(), this.availableId, this.customId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.deleteAvailabilitySeriesModel = result.responseModel;

          let data: any;
          if (this.deleteAvailabilitySeriesModel?.availabilitySeries?.length > 0) {
            if (this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.appointmentDetail?.customAppointmentDetails?.length > 0
              || this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.appointmentDetail?.customBlockDetails?.length > 0
              || this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.appointmentDetail?.customDateDetails?.length > 0) {
              data = {
                action: 'Delete',
                popupHeader: 'Alert',
                therapistCustomAvailabilityMergeMessage: this.deleteAvailabilitySeriesModel.availabilitySeries[0].appointmentDetail,
                cancelButtonText: 'Okay',
                appointmentType: this.deleteAvailabilitySeriesModel.availabilitySeries[0].appointmentType,
                dateRange: moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('MM/DD/yyyy') + ' - ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeRange).format('MM/DD/yyyy'),
                time: this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeStr) + ' - ' + this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeStr) + ' | ' + this.deleteAvailabilitySeriesModel.availabilitySeries[0].timeZone,
                weekDays: this.weekDays,
                selectedDays: this.deleteAvailabilitySeriesModel.availabilitySeries[0].selectedDays,
                isSeries: false
              }
            }
            else {
              data = {
                action: 'Delete',
                popupHeader: 'Confirm Delete Event',
                therapistCustomAvailabilityMergeMessage: this.deleteAvailabilitySeriesModel.availabilitySeries[0].appointmentDetail,
                cancelButtonText: this.deleteAvailabilitySeriesModel.availabilitySeries[0].isAllReccuringDatesMatched ? 'Cancel' : 'No, Cancel',
                updateButtonText: this.deleteAvailabilitySeriesModel.availabilitySeries[0].isAllReccuringDatesMatched ? '' : 'Yes, Delete',
                appointmentType: this.deleteAvailabilitySeriesModel.availabilitySeries[0].appointmentType,
                dateRange: moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('MM/DD/yyyy'),
                //moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeRange).format('MM/DD/yyyy') + ' - ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeRange).format('MM/DD/yyyy'),
                time: this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel.availabilitySeries[0].startTimeStr) + ' - ' + this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel.availabilitySeries[0].endTimeStr) + ' | ' + this.deleteAvailabilitySeriesModel.availabilitySeries[0].timeZone,
                weekDays: this.weekDays,
                //selectedDays: this.deleteAvailabilitySeriesModel.availabilitySeries[0].selectedDays,
                isSeries: false
              }
            }
          }

          this.dialougeService.open(OverrideMergeDialogComponent, {
            data: data,
            header: 'Confirmation'
          }).onClose.subscribe((response: boolean) => {
            if (!response)
              return;
            this.therapistService.DeleteCustomDate(Number(this.customId))
              .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
              .subscribe((result) => {
                if (result.isSuccess) {
                  this.showSuccessMessage("Availability deleted successfully.");
                  this.getTherapistAvailability(true);
                  this.cdr.detectChanges();
                  this.refresh.next();
                }
                else {
                  let _errorMessage = "";
                  _errorMessage = result.message;

                  if (_errorMessage == undefined || _errorMessage == "null" || _errorMessage == "")
                    _errorMessage = 'Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)';

                  this.showWarningMessage(_errorMessage);
                }
              }, (httpResponseError) => {
                this.showErrorMessage(httpResponseError.message);
                this.loading = false;
              });
          });

          //this.showSuccessMessage("Availability deleted successfully.");
          // this.cdr.detectChanges();
          // this.refresh.next();
        }
        else {
          let _errorMessage = "";
          _errorMessage = result.message;

          // if (_errorMessage == undefined || _errorMessage == "null" || _errorMessage == "")
          //   _errorMessage = 'Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)';

          this.showWarningMessage(_errorMessage);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }
  // Delete(event, element): void {
  //   element.hide(event);
  //   this.dialougeService.open(ConfirmDialogComponent, {
  //     data:
  //     {
  //       message: `Are you sure you want to delete?`,
  //     },
  //     header: 'Confirmation'
  //   }).onClose.subscribe((response: boolean) => {
  //     if (!response)
  //       return;
  //     this.therapistService.DeleteCustomDate(Number(this.customId))
  //       .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
  //       .subscribe((result) => {
  //         if (result.isSuccess) {
  //           this.showSuccessMessage("Availability deleted successfully.");
  //           this.getTherapistAvailability(true);
  //           this.cdr.detectChanges();
  //           this.refresh.next();
  //         }
  //         else {
  //           let _errorMessage = "";
  //           _errorMessage = result.message;

  //           if (_errorMessage == undefined || _errorMessage == "null" || _errorMessage == "")
  //             _errorMessage = 'Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)';

  //           this.showWarningMessage(_errorMessage);
  //         }
  //       }, (httpResponseError) => {
  //         this.showErrorMessage(httpResponseError.message);
  //         this.loading = false;
  //       });

  //   });

  // }

  Edit(event, element): void {
    element.hide(event);
    this.onClear();
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.GetCustomDate(Number(this.customId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); }))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.isAppointmentWithRoadHome = result.responseModel.isAppointmentWithRoadHome;
          this.therapistCustomDates = result.responseModel;
          this.setForm();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);

      });

    this.cdr.detectChanges();
    this.refresh.next();
  }

  setForm() {
    this.isRoadShow = false
    this.therapistId = this.therapistCustomDates.enUserInfoId;
    if (this.therapistCustomDates.crmIsHoliday) {
      this.RemoteAndFacility = "O";
      this.SetEventsFacilityOption();
    }
    else if (this.therapistCustomDates.crmIsRoadShow) {
      this.RemoteAndFacility = "R";
      this.isRoadShow = true
      if (this.therapistCustomDates.isStartFromDefaultFacility)
        this.AppointmentStartLocation = 'isStartFromDefaultFacility';
      else if (this.therapistCustomDates.isStartFromHomeAddress)
        this.AppointmentStartLocation = 'isStartFromHomeAddress';
      else
        this.AppointmentStartLocation = '';

      this.SetEventsFacilityOption();

      if (this.therapistCustomDates.isStartFromDefaultFacility) {
        this.getFacilitiesForRoadHomeVisit();
        this.selectedFacility = this.allfacilitiesRoadHome.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }

    }
    else if (this.therapistCustomDates.isOffice) {
      this.RemoteAndFacility = "O";
      this.SetEventsFacilityOption();
    }
    else {
      if (this.therapistCustomDates.isRemoteAndFacility) {
        this.RemoteAndFacility = "Both";
        this.selecteRec = false;
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.crmRemoteFacilityId);
        this.SetEventsFacilityOption();
      }
      else if (this.therapistCustomDates.isRemoteAssistant) {
        this.RemoteAndFacility = "RemoteOnly";
        this.SetEventsFacilityOption();
        this.selectedFacilityRemote = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
      }
      else {
        this.RemoteAndFacility = "FacilityOnly";
        this.selecteRec = false;
        this.SetEventsFacilityOption();
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
      }
    }
    if (this.therapistCustomDates.crmBreakEndTimeString) {
      this.breakEndTime = this.convertTo12HourFormat(this.therapistCustomDates.crmBreakEndTimeString.toString());
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistCustomDates.crmBreakStartTimeString) {
      this.breakStartTime = this.convertTo12HourFormat(this.therapistCustomDates.crmBreakStartTimeString.toString());
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
      this.updateEndTimeOptions(this.breakStartTime, true);
    }
    this.customEndDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customEndTime = this.convertTo12HourFormat(this.therapistCustomDates.crmEndTimeString);
    this.customStartDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customStartTime = this.convertTo12HourFormat(this.therapistCustomDates.crmStartTimeString);

    this.todayDate = this.customStartDate;
    this.updateEndTimeOptions(this.customStartTime, false);
    this.description = this.therapistCustomDates.description;

    var day = new Date(this.therapistCustomDates.crmCustomDate).getDay();
    var selectedDay = 0;
    if (day == 0) {
      selectedDay = Math.pow(2, 6);
    } else {
      selectedDay = Math.pow(2, (day - 1));
    }

    this.weekDays.forEach((value) => {
      if (selectedDay == value.value) {
        value.isSelected = true;
      }
      if (this.isSingleDay(null)) {
        value.isDisabled = true;
      }
    });

    this.isFullDay = this.therapistCustomDates.isFullDay;
    this.isEditEvent = true;
    this.isEditSeries = false;
    // this.showPopup = true;
    this.ManageAvailabilitySidebar = true;

    this.cdr.detectChanges();
    this.refresh.next();
  }

  onSubmit() {

    this.therapistCustomDates.crmCustomDate = this.customDate;
    this.therapistCustomDates.crmEndTimeDate = this.endTime;
    this.therapistCustomDates.crmStartTimeDate = this.startTime;
    if (this.event == "W") {
      this.therapistCustomDates.crmIsHoliday = true;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "R") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = true;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "T") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = true;
    }
    this.therapistService.UpdateCustomDate(this.therapistCustomDates)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Availability updated successfully');
          this.editEvent = false;
          this.getTherapistAvailability(true);
          this.cdr.detectChanges();
          this.refresh.next();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private async getTherapist() {
    if (!this.therapistId) {
      return;
    }

    this.progress.next(true);
    await this.therapistService.GetTherapistByTherapistId(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results || !results.responseModel) {
          return;
        }
        this.OrgtherapistId = results.responseModel.appUserInfoId;
        const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
        this.title = '' + capitalizeFirstLetter(results.responseModel.appUserLname) + ', ' + capitalizeFirstLetter(results.responseModel.appUserFname);
        if (results.responseModel.therapistDefaultFacility && results.responseModel.therapistDefaultFacility[0] && this.allfacilities) {
          this.defaultFacility = results.responseModel.therapistDefaultFacility[0].crmFacilityId;
          let defFac = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
          if (defFac)
            this.defaultFacilityName = defFac.crmFacilityName;
          if (this.availableId <= 0) {
            this.selectedFacility = results.responseModel.therapistDefaultFacility[0];
            this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
            this.getTime();
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBack() {
    this.location.back();
  }

  ManageAvailability(date, events) {
    // if(avEvents.filter(x=>x.isGlobalHoliday ==true).length > 0){
    //   return;
    // }
    //if (!date)
    //date = new Date();
    // if (events != null && events.length > 0) {
    //   this.oldtherapistCustomAvailabilityId = [];
    //   events.forEach(element => {
    //     this.oldtherapistCustomAvailabilityId.push(Number(element.id.toString().split(',')[1]));
    //   });

    // }
    // else {
    this.oldtherapistCustomAvailabilityId = [];
    //}

    if (date) {
      let firstDtofMonth = moment(this.viewDate).startOf('month').toDate();
      if (new Date(date) < firstDtofMonth) {
        return false;
      }
      let startDt = moment(date).format('MM-DD-yyyy');
      let todayDt = moment(new Date()).format('MM-DD-yyyy');
      if (moment(startDt).diff(todayDt) < 0) {
        return false;
      }
    }
    this.isEditEvent = false;
    this.isEditSeries = false;
    this.onClear();
    // this.showPopup = true;    
    this.ManageAvailabilitySidebar = true;

    if (date) {
      this.customStartDate = new Date(date);
      this.customEndDate = new Date(date);
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date(date)).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    else {
      this.customStartDate = new Date();
      this.customEndDate = new Date();
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date()).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    this.cdr.detectChanges();
    this.refresh.next();
    this.availableId = 0;
    this.RemoteAndFacility = this.defaultModeofAppointment;
    //this.SetEvents1();
    if (this.availableId <= 0 && this.RemoteAndFacility != 'RemoteOnly') {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.selectedFacilityTimeZone = this.selectedFacility.crmTimeZone;
        if (this.RemoteAndFacility != 'RemoteOnly') {
          this.selectedFacilityRemote = this.facilitiesRemote.find(x => x.crmFacilityId == this.defaultFacility);
        }
        setTimeout(() => {
          this.getTime();
        }, 800);

      }
    }
    else if (this.selectedFacilityRemote) {

      this.getTime();

    }
  }

  onClear() {

    this.selecteRec = false;
    this.SetEvents1();
    this.selectedFacility = null;
    this.selectedFacilityTimeZone = "";
    this.breakEndTime = null;
    this.breakEndTime = null;
    this.breakStartTime = null;
    this.description = "";
    this.selectedOccurrenceNumber = 0;
    this.selectedExactDate = null;
    this.selectedExactMonth = null;
    this.customEndDate = null;
    this.customEndTime = "08:00 pm";
    this.customStartDate = null;
    this.customStartTime = "08:00 am";
    this.selectedEventSeries = null;
    this.selectedValue = "1";
    this.weekDays.forEach((value) => {
      value.isSelected = false;
      value.isDisabled = true;
    });
    if (this.eventSeries) {
      this.eventSeries.forEach((value) => {
        value.isSelected = false;
      });
    }
    this.eventText = "";
    this.isFullDay = false;
    this.isEditEvent = false;
    this.isEditSeries = false;
    this.isSeries = false;
    this.isRemoteEvent = false;
    this.isFacilityEvent = false;
    this.isRemoteAndFacilityEvent = false;
    this.isRoadShowEvent = false;
    this.isOfficeEvent = false;
    this.eventBlockSlotsList = null;
    this.eventBreakSlotsList = null;
    this.todayDate = new Date();
    this.RemoteAndFacility = this.defaultModeofAppointment; //Reset to Default appointment
    this.AppointmentStartLocation = ''; // set to default
  }

  SetDays() {

    if (this.customStartDate && this.customEndDate) {
      var startDate = this.customStartDate.getDate();
      var endDate = this.customEndDate.getDate();

      if (this.weekDays) {
        this.weekDays.forEach((value) => {
          value.isSelected = false;
          value.isDisabled = true;
        });
      }

      var dayStart = 1;
      var start: Date = new Date(this.customStartDate);
      var end: Date = new Date(this.customEndDate);
      //end.setDate(end.getDate() + 1);

      for (var date = start; date <= end;) {
        var dayIndex = date.getDay();
        if (dayIndex !== 0) {
          dayStart = Math.pow(2, dayIndex - 1);
        }
        else {
          dayStart = 64;
        }

        if (this.weekDays) {
          this.weekDays.forEach((value) => {
            if (value.value == dayStart) {
              if (value.name != 'Sat' && value.name != 'Sun') {
                value.isSelected = true;
              }
              if (!this.isSingleDay(null)) {
                value.isDisabled = false;
              }
            }
          });
        }
        date = this.removeTime(date);
        date.setDate(date.getDate() + 1);
      }
    }

  }
  removeTime(date = new Date()) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }


  SetDaysForEditSeries() {

    if (this.customStartDate && this.customEndDate) {
      if (this.weekDays) {
        this.weekDays.forEach((value) => {
          value.isSelected = false;
          value.isDisabled = true;
        });
      }

      var dayStart = 1;
      var start: Date = moment(this.customStartDate).toDate();
      var end: Date = moment(this.customEndDate).toDate();

      for (var date = start; date <= end; date.setDate(date.getDate() + 1)) {
        var dayIndex = date.getDay();
        if (dayIndex !== 0) {
          dayStart = Math.pow(2, dayIndex - 1);
        }
        else {
          dayStart = 64;
        }

        if (this.weekDays) {
          this.weekDays.forEach((value) => {
            if (value.value == dayStart) {
              if (this.therapistAvailability.crmCustomDays.split(',').find(x => x === dayStart.toString())) {
                value.isSelected = true;
              }
              if (!this.isSingleDay(null)) {
                value.isDisabled = false;
              }
            }
          });
        }
      }
    }
  }

  closeOverlay(event: any) {
    event.hide();
  }

  private async getHolidayList(isRefersh: boolean) {
    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.therapistService.GetHoliday(selectedDate, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.progress.next(false); this.cdr.detectChanges(); }))
      .toPromise()
      .then((result) => {
        this.processResult<HolidayResponse[]>(result, () => {
          const holidayData = result.responseModel;
          var obj: Array<any> = [];
          for (var i = 0; i < holidayData.length; i++) {
            var event: Object = {
              start: holidayData[i].appHolidayStartDateTime,
              end: holidayData[i].appHolidayEndDateTime,
            }
            obj.push(event);
          }

          this.holidays = obj;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  validateIsHolidayDate() {
    let result = true;
    const startDate = moment(this.customStartDate).format("yyyy-MM-DD");
    const enddate = moment(this.customEndDate).format("yyyy-MM-DD");
    const startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    const endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm")
    this.holidays.forEach(function (value) {
      if (value.start.split("T")[0] >= startDate || value.end.split("T")[0] <= enddate) {
        if (startTime >= value.start.split("T")[1] || endTime <= value.end.split("T")[1]) {
          result = false;
        }
      }
    });
    return result;
  }

  EnableForAvaliablity(avEvents) {
    if (avEvents.filter(x => x.isGlobalHoliday == true).length > 0) {
      return false;
    }
    else {
      return true;
    }
  }
  filterDates = (date: Date): boolean => {
    let today = new Date();
    return date < today;
  }

  DeleteSeries(event, element): void {

    // var availCustId = this.therapitsAvailability.filter(x => moment(x.startTime).format("hh:mm") == moment(this.clickStartDate).format("hh:mm") &&
    //   moment(x.endTime).format("hh:mm") == moment(this.clickEndDate).format("hh:mm"));

    element.hide(event);
    // var custIdStr = "0";
    // if (availCustId) {
    //   availCustId.forEach(x => {
    //     if (custIdStr != '0') {
    //       custIdStr = custIdStr + "," + x.custDateId.toString()
    //     }
    //     else {
    //       custIdStr = x.custDateId.toString();
    //     }
    //   })
    // }

    this.therapistService.GetDeleteAvailabilitySeries(Number(this.OrgtherapistId), true, this.clickStartDate.toString(), this.clickEndDate.toString(), this.availableId, 0)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.deleteAvailabilitySeriesModel = result.responseModel;

          this.dialougeService.open(OverrideMergeDialogComponent, {
            data:
            {
              action: 'Delete',
              popupHeader: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.isAllReccuringDatesMatched ? 'Alert' : 'Confirm Delete Series',
              therapistCustomAvailabilityMergeMessage: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.appointmentDetail,
              cancelButtonText: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.isAllReccuringDatesMatched ? 'Okay' : 'No, Cancel',
              updateButtonText: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.isAllReccuringDatesMatched ? '' : 'Yes, Delete',
              appointmentType: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.appointmentType,
              dateRange: moment(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.startTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.startTimeRange).format('MM/DD/yyyy') + ' - ' + moment(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.endTimeRange).format('ddd') + ', ' + moment(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.endTimeRange).format('MM/DD/yyyy'),
              //Bug 7871: Display error for Delete Event details (Case - for Split availabilities)
              time: moment(this.clickStartDate).format("hh:mm A") + ' - ' + moment(this.clickEndDate).format("hh:mm A") + ' | ' + this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.timeZone,
              //this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.startTimeStr) + ' - ' + this.convertTo12HourFormat(this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.endTimeStr) + ' | ' + this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.timeZone,
              weekDays: this.weekDays,
              selectedDays: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.selectedDays,
              isSeries: true,
              isRecurring: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.isRecurring,
              recurringType: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.recurringType,
              recurringTypeMessage: this.deleteAvailabilitySeriesModel?.availabilitySeries[0]?.recurringTypeMessage
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: boolean) => {
            if (!response)
              return;
            let todayDate = moment(new Date()).format('YYYY-MM-DD');//Bug 7869: Delete Series - partially not working
            var modifiedSeries = this.deleteAvailabilitySeriesModel.availabilitySeries.filter(x => moment(x.startTime).format('YYYY-MM-DD') >= todayDate);
            //var modifiedseriesAppointmentDetail = this.deleteAvailabilitySeriesModel.availabilitySeries[0].appointmentDetail.customAppointmentDetails.filter(x => moment(x.appointmentDate).format('MM-DD-yyyy') >= todayDate);
            this.deleteAvailabilitySeriesModel = new DeleteAvailabilitySeriesModel;
            modifiedSeries.forEach(x => {
              if (x.recurringTypeMessage != "") {
                x.recurringTypeMessage = "";
              }
            });

            var filtermodifiedSeries = modifiedSeries.filter(x => moment(x.startTime).format("hh:mm") == moment(this.clickStartDate).format("hh:mm") &&
              moment(x.endTime).format("hh:mm") == moment(this.clickEndDate).format("hh:mm"));


            this.deleteAvailabilitySeriesModel.availabilitySeries = filtermodifiedSeries;
            //this.deleteAvailabilitySeriesModel.seriesAppointmentDetailModels = modifiedseriesAppointmentDetail;
            this.therapistService.DeleteAvailabilitySeries(this.deleteAvailabilitySeriesModel)
              .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loading = false; this.cdr.detectChanges(); }))
              .subscribe((result) => {
                if (result.isSuccess) {
                  this.showSuccessMessage("Availability Series deleted successfully.");
                  this.getTherapistAvailability(true);
                  this.cdr.detectChanges();
                  this.refresh.next();
                }
                else {
                  let _errorMessage = "";
                  _errorMessage = result.message;
                  this.showWarningMessage(_errorMessage);
                }
              }, (httpResponseError) => {
                this.showErrorMessage(httpResponseError.message);
                this.loading = false;
              });
          });

          //this.showSuccessMessage("Availability deleted successfully.");
          // this.cdr.detectChanges();
          // this.refresh.next();
        }
        else {
          let _errorMessage = "";
          _errorMessage = result.message;

          // if (_errorMessage == undefined || _errorMessage == "null" || _errorMessage == "")
          //   _errorMessage = 'Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)';

          this.showWarningMessage(_errorMessage);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });

  }

  convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const period = hours < 12 ? 'am' : 'pm';

    // Convert hours to 12hour format
    const hours12 = (hours % 12) || 12;

    // Formating time in to 12-hour format
    const formattedTime = `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
  }

  updateEndTimeOptions(selectedStartTime: string, isBreak: boolean) {
    if (isBreak) {
      this.updateBreakEndTimeOptions(selectedStartTime, this.customEndTime);
      this.EndBreakTimeSelections = this.StartBreakTimeSelections.slice(
        this.StartBreakTimeSelections.findIndex((time) => time.name === selectedStartTime) + 1
      );
      if (this.EndBreakTimeSelections.length == 0) {
        this.breakEndTime = null;
      }
    }
    else {
      this.EndTimeSelections = this.StartTimeSelections.slice(
        this.StartTimeSelections.findIndex((time) => time.name === selectedStartTime) + 1
      );

      if (this.EndTimeSelections.findIndex(x => x.name == this.customEndTime) == -1) {
        this.customEndTime = this.EndTimeSelections[0].name;
      }
      //Set break hours timing between Start Time and End Time
      this.updateBreakEndTimeOptions(selectedStartTime, this.customEndTime);
    }
  }

  updateBreakEndTimeOptions(selectedStartTime: string, selectedEndTime: string) {
    if (!this.isBreakHours || selectedStartTime == undefined || selectedEndTime == undefined || this.customStartTime == undefined || this.customEndTime == undefined) {
      return;
    }

    const startIndex = this.TimeSelectionList.findIndex((time) => time.name === this.customStartTime);
    const endIndex = this.TimeSelectionList.findIndex((time) => time.name === this.customEndTime);

    // Set break hours timing between Start Time and End Time
    this.StartBreakTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);
    this.EndBreakTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);

    if (this.breakStartTime != undefined) {
      this.EndBreakTimeSelections = this.StartBreakTimeSelections.slice(
        this.StartBreakTimeSelections.findIndex((time) => time.name === this.breakStartTime) + 1
      );
    }
  }


  isSingleDay(selectedWeekday: number): boolean {
    if (this.customStartDate != undefined && this.customEndDate != undefined
      && this.customStartDate.toDateString() === this.customEndDate.toDateString()) {
      return true;
    }
    else {
      return false;
    }
  }

  updateMainSeriesData() {
    this.FetchMainSeriesPopup = true;

    //element.hide(event);
    // this.dialougeService.open(ConfirmDialogComponent, {
    //   data:
    //   {
    //     message: `This action will retrieve details from main series & override the current series details. Are you sure you want to perform this action?`,
    //   },
    //   header: 'Confirm Fetching Main Series?'
    // }).onClose.subscribe((response: boolean) => {
    //   if (!response)
    //     return;

    //   this.oldcustomId = this.customId;
    //   this.customId = 0;
    //   this.isFetchFromMainSeries = false;
    //   this.getAvailability();
    // });

  }

  ConfirmFatch() {
    this.oldcustomId = this.customId;
    //this.customId = 0;
    this.isFetchFromMainSeries = false;
    this.getAvailability(true);
    this.FetchMainSeriesPopup = false;
  }

  resetMainSeriesData() {
    this.YesResetPopup = true;
  }

  ResetSeriesDataPopup() {
    this.customId = this.oldcustomId;
    this.isFetchFromMainSeries = true;
    this.getAvailability(false);
    this.YesResetPopup = false;
  }

  onCancelPopup() {
    this.FetchMainSeriesPopup = false;
    this.YesResetPopup = false;
  }

  getCustomAvailabilityTableInfo() {
    if (this.availableId && this.availableId <= 0) {
      return;
    }
    this.progress.next(true);

    this.loadingCustom = true;
    this.cdr.detectChanges();
    this.therapistService.getAvailabilityNewById(this.availableId, 0, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.mainSeriesData = new MainSeriesData();
          this.therapistCustomAvailabilityInfo = result.responseModel;
          if (this.therapistCustomAvailabilityInfo.isRemoteAndFacility) {
            this.mainSeriesData.mainAppointmentType = 'Both Facility & Remote'
          }
          if (this.therapistCustomAvailabilityInfo.isRemoteAssistant) {
            this.mainSeriesData.mainAppointmentType = 'Remote Setup'
          }
          if (this.therapistCustomAvailabilityInfo.crmIsRoadShow) {
            this.mainSeriesData.mainAppointmentType = 'Road / Home Visit'
          }
          if (this.therapistCustomAvailabilityInfo.isOffice) {
            this.mainSeriesData.mainAppointmentType = 'Office / Not Available'
          }
          if (this.therapistCustomAvailabilityInfo.crmFacilityId > 0 && (!this.therapistCustomAvailabilityInfo.isRemoteAndFacility && !this.therapistCustomAvailabilityInfo.isRemoteAssistant
            && !this.therapistCustomAvailabilityInfo.crmIsRoadShow && !this.therapistCustomAvailabilityInfo.isOffice)) {
            this.mainSeriesData.mainAppointmentType = 'Facility Visit'
          }
          this.mainSeriesData.mainDateRange = moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomStartDate).format('ddd') + ', ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomStartDate).format('MM/DD/yyyy') + ' - ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomEndDate).format('ddd') + ', ' + moment(this.therapistCustomAvailabilityInfo.crmTherapistCustomEndDate).format('MM/DD/yyyy');
          this.mainSeriesData.mainTime = this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmTherapistCustomStartTimeString) + ' - ' + this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmTherapistCustomEndTimeString) + (this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone ? (' | ' + this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone) : '')
          if (this.therapistCustomAvailabilityInfo.crmBreakStartTimeString) {
            this.mainSeriesData.mainBreakhours = this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmBreakStartTimeString) + ' - ' + this.convertTo12HourFormat(this.therapistCustomAvailabilityInfo.crmBreakEndTimeString) + ' | ' + this.therapistCustomAvailabilityInfo.selectedFacilityTimeZone
          }
          else {
            this.mainSeriesData.mainBreakhours = "NA"
          }
          var getCustomDates = this.therapitsAvailability.filter(x => x.isCustom);
          if (getCustomDates.length != 0) {
            this.mainSeriesData.currentSeriesDateRange = moment(getCustomDates[0].startTime).format('ddd') + ', ' + moment(getCustomDates[0].startTime).format('MM/DD/yyyy') + ' - ' + moment(getCustomDates[getCustomDates.length - 1].startTime).format('ddd') + ', ' + moment(getCustomDates[getCustomDates.length - 1].startTime).format('MM/DD/yyyy');

          }


        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  OverrideAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.loadingCustom = true;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.cdr.detectChanges();
    requestModel.isBookedFromCalendar = false;
    requestModel.isAddManageAvailabilityChanged = true;
    requestModel.isAddBlockAvailabilityChanged = this.isBreakHours;
    requestModel.availablityType = this.isBreakHours ? 'Break' : '';
    this.therapistService.overRideAvailability(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.loadingCustom = false; this.cdr.detectChanges(); this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability updated successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onMonthChange() {
    if (this.selectedExactMonth.name == "February") {
      this.getExactDate();
      this.ExactDate.splice(this.ExactDate.length - 2, 2);
    }
    else if (this.selectedExactMonth.name != "January" && this.selectedExactMonth.name != "February" && this.selectedExactMonth.name != "March" &&
      this.selectedExactMonth.name != "May" && this.selectedExactMonth.name != "July" && this.selectedExactMonth.name != "August"
      && this.selectedExactMonth.name != "October" && this.selectedExactMonth.name != "December") {
      this.getExactDate();
      this.ExactDate.splice(this.ExactDate.length - 1, 1);
    }
    else {
      this.getExactDate()
    }


  }

  getExactDate() {
    this.ExactDate = [];
    this.ExactDate = [
      { name: '1', code: 1 },
      { name: '2', code: 2 },
      { name: '3', code: 3 },
      { name: '4', code: 4 },
      { name: '5', code: 5 },
      { name: '6', code: 6 },
      { name: '7', code: 7 },
      { name: '8', code: 8 },
      { name: '9', code: 9 },
      { name: '10', code: 10 },
      { name: '11', code: 11 },
      { name: '12', code: 12 },
      { name: '13', code: 13 },
      { name: '14', code: 14 },
      { name: '15', code: 15 },
      { name: '16', code: 16 },
      { name: '17', code: 17 },
      { name: '18', code: 18 },
      { name: '19', code: 19 },
      { name: '20', code: 20 },
      { name: '21', code: 21 },
      { name: '22', code: 22 },
      { name: '23', code: 23 },
      { name: '24', code: 24 },
      { name: '25', code: 25 },
      { name: '26', code: 26 },
      { name: '27', code: 27 },
      { name: '28', code: 28 },
      { name: '29', code: 29 },
      { name: '30', code: 30 },
      { name: '31', code: 31 }
    ];
  }

  LegendsClick() {
    this.LegendsisActive = !this.LegendsisActive;
    $("body").toggleClass("show-legends");
  }

  LegendsClickClose() {
    this.LegendsisActive = !this.LegendsisActive;
    $("body").removeClass("show-legends");
  }

}
