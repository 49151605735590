<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>

    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" [routerLink]='["/admin/settings/application"]'><i
          class="back-new"></i> Back</a>
    </div>
  </div>

  <div class="form-content-outer">
    <div class="form-outer"
      [ngClass]="enableToAddHoliday ? 'enableToAddHoliday-form-active' : 'enableToAddHoliday-form-hide'">
      <div class="form">
        <div class="col">
          <div class="form__group">
            <label class="form__label">Year</label>
            <p-dropdown class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="selectedHolidayYear" [options]="year" placeholder="Select Year" optionLabel="name"
                        [virtualScroll]="true" selectedItemsLabel="{0} Status Selected" (onChange)="onYearSelection($event)">
            </p-dropdown>
            <div *ngIf="formStatusSubmitted && holidayYearReq" class="invalid-feedback">
              {{validationMessages.year.required}}
            </div>
          </div>
          <div class="form__group">
            <label class="form__label">Holiday Name</label>
            <input #displayName type="text" class="form__control" pInputText [(ngModel)]="holidayDetail.appHolidayName">
            <div *ngIf="formStatusSubmitted && displayNameReq" class="invalid-feedback">
              {{validationMessages.displayName.required}}
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form__group">
            <label class="form__label">From Date</label>
            <p-calendar class="datepicker" [minDate]="startYearDate" [maxDate]="endYearDate" [showIcon]="true" [(ngModel)]="customStartDate" placeholder="Holiday Start Date" dateFormat="mm/dd/yy"
                        [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()">
            </p-calendar>
            <div *ngIf="formStatusSubmitted  && startDateReq" class="invalid-feedback">
              {{validationMessages.startDate.required}}
            </div>
          </div>
          <div class="form__group">
            <label class="form__label">To Date</label>
            <p-calendar class="datepicker" [minDate]="startYearDate" [maxDate]="endYearDate" [showIcon]="true" [(ngModel)]="customEndDate" placeholder="Holiday End Date"
                        [ngModelOptions]="{standalone: true}" (onSelect)="SetDays()" (onInput)="SetDays()">
            </p-calendar>
            <div *ngIf="formStatusSubmitted && endDateReq" class="invalid-feedback">
              {{validationMessages.endDate.required}}
            </div>
            <div *ngIf="formStatusSubmitted &&  startEndDateReq" class="invalid-feedback">
              {{validationMessages.endDate.validation}}
            </div>
          </div>
        </div>
        <div *ngIf="false" class="col">
          <div class="form__group status-checkbox">
            <span class="event-series-right">
              <p-radioButton name="group3" value="1" label="Full Day" [(ngModel)]="selectedEvent"
                             (click)="SetEvents1(1)">
              </p-radioButton>
              <p-radioButton name="group3" value="0" label="Half Day" [(ngModel)]="selectedEvent"
                             (click)="SetEvents1(0)">
              </p-radioButton>
            </span>
            <div *ngIf="formStatusSubmitted && radioSelectionCheck" class="invalid-feedback">
              {{validationMessages.eventSelection.required}}
            </div>
          </div>
        </div>
        <div *ngIf="enableTime" class="col">
          <div class="form__group">
            <label class="form__label">Start Time</label>
            <div class="date-pikar-new">
              <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                    [ngModelOptions]="{standalone: true}">
              </ngx-timepicker-field>
            </div>
            <div *ngIf="formStatusSubmitted && startTimeReq" class="invalid-feedback">
              {{validationMessages.startTime.required}}
            </div>

          </div>
          <div class="form__group">
            <label class="form__label">End Time</label>
            <div class="date-pikar-new">
              <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                                    [ngModelOptions]="{standalone: true}">
              </ngx-timepicker-field>
            </div>
            <div *ngIf="formStatusSubmitted && endTimeReq" class="invalid-feedback">
              {{validationMessages.endTime.required}}
            </div>
            <div *ngIf="formStatusSubmitted && startEndTimeReq" class="invalid-feedback">
              {{validationMessages.endTime.validation}}
            </div>
          </div>
        </div>


        <div class="col">
          <div class="form__group">
            <label class="form__label">Exclude Facility</label>
            <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                           [(ngModel)]="selectedFacility" [options]="facilities" defaultLabel="Select Facility"
                           (onChange)="onFacilityChange($event)" optionLabel="crmFacilityName" [filter]="true" [virtualScroll]="true"
                           selectedItemsLabel="{0} facility(s) Selected"></p-multiSelect>

          </div>
        </div>


        <div class="btns">
          <div class="btns">
            <button class="btn btn--primary" (click)="onSubmit()">{{statusBtnTitle}}</button>
            <!--<button class="btn btn--secondary" [routerLink]='["/admin/settings/application"]'>Cancel</button>-->
            <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>