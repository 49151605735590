import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
@Injectable({
  providedIn: 'root'
})

export class GlobalSearchService {
  public searchTerm: BehaviorSubject<GlobalSearchModel> = new BehaviorSubject<GlobalSearchModel>(null);
  public changeGlobalBranch: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  //public twillioCall: BehaviorSubject<TwilioDeviceObject> = new BehaviorSubject<TwilioDeviceObject>(null);
}
