import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { Subject } from 'rxjs';
import { th } from 'date-fns/locale';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { DateFormat } from 'src/app/shared/models/common/month-format';
@Component({
  selector: 'app-patient-info-dialogue-component',
  templateUrl: './patient-info-dialogue-component.component.html',
  styleUrls: ['./patient-info-dialogue-component.component.sass'],

})
export class PatientInfoDialogueComponentComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  salesOrderId: number;
  schAppointmentId: number;
  scheduleAction: string;
  salesOrder: SalesOrderInfoResponseModel;
  isAppointment: boolean;
  isTelehealth: boolean;
  selectedAppointmentType: string;
  searchRequestModel: SearchRequestModel;
  selectedTab: number;
  appDateFormat: DateFormatModel;
  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private router: Router,
    private appointmentService: AppointmentService,
    messageService: MessageService,
    private webStorageService: WebStorageService,
  ) {

    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit() {
   
    this.isTelehealth = false;
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.salesOrderId = this.config.data.id;
    this.selectedTab = this.config.data.selectedTab;
    this.isAppointment = this.config.data.isAppointment;
    this.schAppointmentId = this.config.data.schAppointmentId;
    this.searchRequestModel = this.config.data.searchRequestModel;
    this.scheduleAction = this.isAppointment ?  'Reschedule': 'Schedule';
    this.getSalesOrderInfo(this.salesOrderId);

  }

  ViewSalesOrder() {
  
    if (this.isAppointment)
      this.router.navigate(['/admin/appointments/detail/', this.schAppointmentId], { state: { searchAppointmentRequestModel: this.searchRequestModel, selectedTab: this.selectedTab } });
    else
      this.router.navigate(['/admin/appointments/salesorders/detail/', this.salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab } });

    this.ref.close();
  }
  bookAppointment() {
   
    if (this.selectedAppointmentType == "FacilityVisit") {
      this.webStorageService.deleteSelectedFacilies();
      this.router.navigate(['/admin/book-appointment/search-nearby-facility/', this.salesOrderId]);
    }
    else if (this.selectedAppointmentType == "HomeVisit")
      this.router.navigate(['/admin/book-appointment/home-visit-confirm-date-time-slot/', this.salesOrderId]);
    else
      this.router.navigate(['/admin/book-appointment/telehealth-confirm-date-time-slot/', this.salesOrderId]);
    this.ref.close();
  }

  private getSalesOrderInfo(SalesOrderId) {
    this.progress.next(true);
    this.appointmentService.GetSalesOrderById(SalesOrderId,true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () =>
        {
          if (result.isSuccess)
          {
            if (result.responseModel != undefined && result.responseModel != null)
            {
              this.salesOrder = result.responseModel;
              this.isTelehealth = result.responseModel.crmIsTelehealth;
              this.selectedAppointmentType = this.isTelehealth ? 'Telehealth' : 'FacilityVisit';
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
