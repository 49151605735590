import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.sass']
})
export class NewPatientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
