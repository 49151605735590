
<div class="thankyou-outer">
  <div class="thankyou-outer__left">
    <div class="thankyou-outer-block">
      <div class="thankyou-outer-block__top">
        <h2>Appointment Confirmation of <span class="name">“{{salesOrder?.patientInfo?.crmPatientLastName}}, {{salesOrder?.patientInfo?.crmPatientFirstName}}”</span></h2>
        <p *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Facility Visit'">Appointment has been booked in <span>{{appointmentConfirmationInfo?.facilityName}}</span> facility with <span>{{appointmentConfirmationInfo?.crmTherapistname}}.</span></p>
        <p *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Home Visit'">Appointment has been booked as <span>Road/Home Visit at {{salesOrder?.patientInfo?.crmPatientAddress || '-' }}</span>  with <span>{{appointmentConfirmationInfo?.crmTherapistname}}</span></p>
        <p *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'">Appointment has been booked as <span>{{appointmentConfirmationInfo?.facilityName}} - {{ appointmentConfirmationInfo?.schRemoteAppointmentTypeDisplayName}}</span> booking with <span>{{appointmentConfirmationInfo?.crmTherapistname}}</span></p>

        <div class="">
          <!-- Remote setup - Online Meeting booking -->
          <p *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth' && appointmentConfirmationInfo?.schRemoteAppointmentType=='OnlineMeeting'">
            We have sent you {{appointmentConfirmationInfo?.schRemoteAppointmentTypeDisplayName}} Link, ID and Password in your registered email address. <br>
            In case, you haven’t received it then, call us our customer support team.
          </p>
          <!-- Remote setup - telemed booking -->
          <p *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth' && appointmentConfirmationInfo?.schRemoteAppointmentType=='Telemed'">Therapist will call you on your registered mobile number on scheduled date & time.</p>
        </div>
        <div class="">
          <div class="thankyou-outer-block__bottom">
            <button *ngIf="isCall" class="btn btn--secondary" (click)="Hangup()">Hang Up</button>
          </div>

        </div>
        </div>

        <div class="thankyou-outer-block__middle">
          <h3><img src="assets/img/thank-you-check.svg" width="55" height="55" alt="Appointment Booking Details"> Appointment Booking Details</h3>

          <div class="thankyou-outer-block__appt-details">
            <div class="thankyou-outer-block__column">
              <label class="form__lable"> {{appointmentConfirmationInfo?.isRescheduledRequest?'Rescheduled':'Scheduled'}} Date & Time</label>
              <span>{{appointmentConfirmationInfo?.appointmentStartDate | date: appDateFormat.dateFormat}} - {{appointmentConfirmationInfo?.appointmentStartDate | date: 'hh:mm a' | lowercase }}  |  {{appointmentConfirmationInfo?.appointmentTimeZoneShortName}} </span>
            </div>

            <div class="thankyou-outer-block__column">
              <label class="form__lable">Duration</label>
              <span>{{appointmentConfirmationInfo?.duration}} <i class="info-new" pTooltip="{{salesOrder?.crmClassificationDescription}}"></i></span>
            </div>

            <div class="thankyou-outer-block__column">
              <label class="form__lable">Therapist</label>
              <span>{{appointmentConfirmationInfo?.crmTherapistname}}</span>
            </div>
          </div>

          <div class="thankyou-outer-block__facility-details" *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Facility Visit'">
            <h4 class="thankyou-outer-block__facilityname">
              {{appointmentConfirmationInfo?.facilityName}}
            </h4>
            <div class="thankyou-outer-block__miles">{{appointmentConfirmationInfo?.distance}}</div>
            <div class="thankyou-outer-block__address">
              <i class="location-icon"></i>
              {{appointmentConfirmationInfo.facilityAddress}}
            </div>
          </div>
          <div class="thankyou-outer-block__facility-details" *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Home Visit'">
            <h4 class="thankyou-outer-block__facilityname">
              Road/Home Visit
            </h4>
            <!-- <div class="thankyou-outer-block__miles"></div> -->
            <div class="thankyou-outer-block__address" *ngIf="appointmentConfirmationInfo?.isBillingAddress">
              <i class="location-icon"></i>
              {{salesOrder?.patientInfo?.crmPatientAddress || '-'}}
            </div>
            <div class="thankyou-outer-block__address" *ngIf="appointmentConfirmationInfo?.isBillingAddress==false">
              <i class="location-icon"></i>
              {{salesOrder?.crmPatientDeliveryAddress || '-'}}
            </div>
            

          </div>
          <!-- <div class="thankyou-outer-block__facility-details" *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'">
            <h4 class="thankyou-outer-block__facilityname">
              {{appointmentConfirmationInfo?.facilityName}}
            </h4>
            <div class="thankyou-outer-block__miles"></div>
            <div class="thankyou-outer-block__address">
              {{appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'? appointmentConfirmationInfo?.schRemoteAppointmentTypeDisplayName:''}}
            </div>
          </div> -->
          <div class="thankyou-outer-block__due-amount"><strong>Due Amount :</strong> ${{appointmentConfirmationInfo?.dueAmount}}</div>
        </div>
        <div class="thankyou-outer-block__bottom">
          <button class="btn btn--confirm" (click)="backToAppointmentScreen()"><img src="assets/img/back-icon-white.svg" width="22" height="18" alt="Back"> Back to {{appointmentConfirmationInfo?.crmRediretFrom}}</button>
        </div>
      </div>
  </div>
  <div class="thankyou-outer__right">
    <img src="assets/img/thank-you-img.svg" width="684" height="684" alt="thank-you-img">
  </div>
</div>
<p-dialog [(visible)]="displayHangUp" styleClass="display-close-icon" [modal]="true" appendTo="body" [baseZIndex]="9999999">
  <div class="search-box-outer delivery">
    <h2 class="filter-title" style="padding: 0;">Voice Call</h2>
    <div class="form__group order-note">
      <p>Please hang up. Voice call in progress.</p>
    </div>
    <div class="btn-block app-not-scheduled-appointments-btn">
      <button class="btn btn--primary" (click)="Hangup()">Hang Up</button>
    </div>
  </div>
</p-dialog>
