import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass']
})
export class MyProfileComponent implements OnInit {

  title = 'My Profile';
  isGoogleMap:boolean=false;
  constructor(private webStorageService: WebStorageService,
    private router: Router) {
    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
  }



  ngOnInit() {
  }
  
  back(){
    if (this.isGoogleMap) {
      this.router.navigate(['/therapist/my-appointments-new']);
    }
    else {
      this.router.navigate(['/therapist/my-appointments']);
    }
  }

}
