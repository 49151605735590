import { Component, OnInit, OnDestroy } from "@angular/core";
import { BaseComponent } from "src/app/shared/components/base.component";
import { MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import {
  FacilityResponseModel,
  FacilityResponseModelSend
} from "src/app/shared/models/response/facility/facility-response-model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { FacilityService } from "../../servicies/facility.service";
import { takeUntil, finalize } from "rxjs/operators";
import { SearchRequestModel } from "src/app/shared/models/request/search-request-model";
import { BranchResponseModel } from "src/app/shared/models/response/branch-response-model";
import { CustomValidator } from "src/app/shared/custom-validator";
import { Constants } from "src/app/shared/constants";
import { TherapistService } from "src/app/shared/services/therapist.service";
import { ResultOfT } from "src/app/shared/models/response/result";
import { DoctorService } from "../../servicies/doctor.service";
import { DoctorExclusion } from "src/app/shared/models/response/doctor-response-model";
import { UserResponseModel } from "src/app/shared/models/response/user-response-model";
import { WorkingHour } from "src/app/shared/models/common/working-hour";
import { FacilityAvailabilityResponseModelSend } from "src/app/shared/models/response/facility/facility-availability-response-model";
import { FacilityCustomAvailabilityResponseModel } from "src/app/shared/models/response/facility/facility-custom-availability-response-model";
import { UserAuthResponseModel } from "src/app/shared/models/response/access-token-response-model";
import { WebStorageService } from "src/app/shared/services/web-storage.service";
import { DoctorFacilityResponseModel } from "src/app/shared/models/response/doctor-facility-response-model";
import { TherapistFacilityResponseModel } from "src/app/shared/models/response/therapist/therapist-facility-response-model";
import { StateMasterResponseModel } from "src/app/shared/models/common/state-master-response-model";
import { InsuranceInfoResponseModel } from "src/app/shared/models/response/insurance-info-response-model";
import { AddInsuranceDialogComponent } from "src/app/admin/facilities/add-insurance-dialog/add-insurance-dialog.component";
import * as moment from "moment";
import { Editor } from "primeng/editor";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { DateFormatModel } from "src/app/shared/models/common/date-format-model";
import { TimeZoneAvailableResponseModel } from "src/app/shared/models/response/time-zone-available-response-model";

@Component({
  selector: "app-facility-manage",
  templateUrl: "./facility-manage.component.html",
  styleUrls: ["./facility-manage.component.sass"],
  providers: [DialogService]
})
export class FacilityManageComponent extends BaseComponent
  implements OnInit, OnDestroy {
  public Editor = ClassicEditor;

  @ViewChild("textEditor")
  textEditor: Editor;
  @ViewChild("fname")
  fname: ElementRef;

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  public facilityId: string;
  title = "";
  customAvailabilityTitle = "Add";
  isActive: boolean = true;
  isEditMode: boolean = false;
  CustomEvent: boolean = false;
  facilityResponseModel: FacilityResponseModel;
  multipleCustomAvailability: FacilityCustomAvailabilityResponseModel[];
  branchesRequest: BranchResponseModel[];
  doctorsRequest: DoctorFacilityResponseModel[];
  therapistRequest: TherapistFacilityResponseModel[];
  insurancesRequest: InsuranceInfoResponseModel[];
  searchRequestModel: SearchRequestModel;
  currentUserToken: UserAuthResponseModel;
  selectedBranch: { label: string; value: string };
  selectedInsuanceInfo: { label: string; value: string }[];
  excludedDoctor: any[];
  excludedTherapist: any[];
  stateModel: StateMasterResponseModel[];
  selectedState: StateMasterResponseModel;
  selectedSpecialEvent: { name: string; code: number };
  selectedFacilityCustomAvailabilityId: number = 0;
  selectedOccurrence: { name: string; code: number };
  MonWorkingHours: WorkingHour;
  TueWorkingHours: WorkingHour;
  WedWorkingHours: WorkingHour;
  ThuWorkingHours: WorkingHour;
  FriWorkingHours: WorkingHour;
  SatWorkingHours: WorkingHour;
  SunWorkingHours: WorkingHour;
  breakHours: WorkingHour;
  isDaily: boolean;
  isWeekly: boolean;
  isMonthly: boolean;
  isYearly: boolean;
  once: boolean;
  MonDayOfWeek: boolean;
  TueDayOfWeek: boolean;
  WedDayOfWeek: boolean;
  ThuDayOfWeek: boolean;
  FriDayOfWeek: boolean;
  SatDayOfWeek: boolean;
  SunDayOfWeek: boolean;
  customStartDate: Date;
  customStartTime: string = "08:00 AM";
  customEndDate: Date;
  customEndTime: string = "08:00 PM";
  branch: { label: string; value: string }[];
  insuanceInfo: { label: string; value: string }[];
  doctors: DoctorExclusion[];
  Occurrence: { name: string; code: number }[];
  SpecialEvent: { name: string; code: string }[];
  therapists: DoctorExclusion[];
  TimeSelectionList: { name: string; }[] = [];
  WeekDayList: { name: string; isOn: any; StartTimeSelectionList: any[]; EndTimeSelectionList: any[]; DayWorkingHour: WorkingHour; }[];
  DayOnOffMon: { name: string; code: number };
  DayOnOffTue: { name: string; code: number };
  DayOnOffWed: { name: string; code: number };
  DayOnOffThu: { name: string; code: number };
  DayOnOffFri: { name: string; code: number };
  DayOnOffSat: { name: string; code: number };
  DayOnOffSun: { name: string; code: number };
  remoteAssistant: boolean = false;
  includeBreakHours: boolean = false;
  loadingCustom: boolean = false;

  formSubmitted: boolean;
  bioEditorText: string = "";

  indexTab: number = 0;
  profileLogo: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  crmProfileImageFormat: string;
  timeZone: string;
  timeZoneList: TimeZoneAvailableResponseModel[];
  selectedTimeZone: TimeZoneAvailableResponseModel;
  Day = [{ name: "On", code: 1 }, { name: "Off", code: 0 }];

  formFields = {
    facility: "facility",
    bioEditorText: "bioEditorText",
    address: "address",
    address2: "address2",
    city: "city",
    state: "state",
    zipCode: "zipCode",
    email: "email",
    branchName: "branchName",
    doctorExclusion: "doctorExclusion",
    therapistExclusion: "therapistExclusion",
    insuanceInfo: "insuanceInfo",
    room1: "room1",
    cpFName: "cpFName",
    cpLName: "cpLName",
    cpPhnNumber: "cpPhnNumber",
    remote: "remote",
    customSplEvent: "customSplEvent",
    customSplEventSeries: "customSplEventSeries",
    customOccurEvent: "customOccurEvent",
    dayOfWeek: "dayOfWeek",
    timeZone: "timeZone",

  };

  validationMessages = {
    facility: {
      required: "Facility is required",
      validCharacters: "Enter valid facility"
    },
    bioEditorText: {
      required: "Biodata / Skills is required",
      maxlength: 'Max 2000 character allowed'
    },
    address: {
      required: "Address is required",
      validCharacters: "Enter valid address"
    },
    zipCode: {
      required: "Zipcode is required",
      validNumber: "Enter valid zipcode"
    },
    branchName: {
      required: "Branch is required"
    },
    phoneNumber: {
      required: "Phone number is required"
    },
    email: {
      email: 'Enter valid email address'
    },


    cpPerson: {
      fNameRequired: "First name is required",
      lNameRequired: "Last name is required",
      validCharacters: "Enter valid name",
      city: "City is Required",
      state: "State is Required"
    },
    customSplEvent: {
      required: "Event is required"
    },
    customOccurEvent: {
      required: "Every is required"
    },
    customAvailStartDate: {
      required: "Custom Start Date is required"
    },
    customAvailEndDate: {
      required: "Custom End Date is required"
    },
    customAvailStartTime: {
      required: "Custom Start Time is required"
    },
    customAvailEndTime: {
      required: "Custom End Time is required"
    },
    customSplEventSeries: {
      required: "Event Series is required"
    },
    dayOfWeek: {
      required: "Day of Week  is required"

    },
    timeZone: {
      required: "Time Zone is required"
    }
  };
  dialogCustomeDate: boolean;
  clickEventFilterStatus: boolean;
  displayFacilityName: boolean = false;
  crmFacilityName: any = "";
  enableWorkingHrPopup: boolean = false;
  CrmFacilityId: number = 0;
  splEventError: boolean = false;
  splEventseriesError: boolean = false;
  customAvailStartDateError: boolean = false;
  customAvailEndDateError: boolean = false;
  customAvailStartTimeError: boolean = false;
  customAvailEndTimeError: boolean = false;
  selectedOccurrenceError: boolean = false;
  dayofWeekError: boolean = false;
  appDateFormat: DateFormatModel;
  isAllowSelfScheduleAppointments: boolean = false;
  isViewSelfScheduleAppointments: boolean = false;
  Times: { name: string; }[];
  selectedValues: string[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private therapistService: TherapistService,
    private docotrService: DoctorService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    messageService: MessageService,
    private dialougeService: DialogService
  ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }

    this.buildForm();
    this.timeZoneList = this.webStorageService.getTimeZoneList();
    // this.facilityCustomAvailability = null;


    this.Times = [
      { name: '12 am' },
      { name: '1 am' },
      { name: '2 am' },
      { name: '3 am' },
      { name: '4 am' },
      { name: '5 am' },
      { name: '6 am' },
      { name: '7 am' },
      { name: '8 am' },
      { name: '9 am' },
      { name: '10 am' },
      { name: '11 am' },
      { name: '12 pm' },
    ];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedTime = `${(hour % 12 || 12).toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${(hour < 12 ? 'am' : 'pm')}`;
        this.TimeSelectionList.push({ name: formattedTime });
      }
    }

    this.initializeWeekDayList();
  }

  async ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.facilityId = params["id"];
        if (Number(this.facilityId) > 0) {
          this.title = "Edit Facility";
        } else {
          this.title = "Add Facility";
        }
      });
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.initializeVariables();
    await this.getBranches();
    await this.getInsuranceInfoes();
    await this.getTherapists();
    await this.getDoctors();
    await this.getStates();

  }

  initializeWeekDayList(){
    this.WeekDayList = [
      {
        name: 'Monday',
        isOn: this.DayOnOffMon,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.MonWorkingHours != null ? this.MonWorkingHours : new WorkingHour()
      },
      {
        name: 'Tuesday',
        isOn: this.DayOnOffTue,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.TueWorkingHours != null ? this.TueWorkingHours : new WorkingHour()
      },
      {
        name: 'Wednesday',
        isOn: this.DayOnOffWed,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.WedWorkingHours != null ? this.WedWorkingHours : new WorkingHour()
      },
      {
        name: 'Thursday',
        isOn: this.DayOnOffThu,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.ThuWorkingHours != null ? this.ThuWorkingHours : new WorkingHour()
      },
      {
        name: 'Friday',
        isOn: this.DayOnOffFri,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.FriWorkingHours != null ? this.FriWorkingHours : new WorkingHour()
      },
      {
        name: 'Saturday',
        isOn: this.DayOnOffSat,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.SatWorkingHours != null ? this.SatWorkingHours : new WorkingHour()
      },
      {
        name: 'Sunday',
        isOn: this.DayOnOffSun,
        StartTimeSelectionList: [... this.TimeSelectionList],
        EndTimeSelectionList: [... this.TimeSelectionList],
        DayWorkingHour: this.SunWorkingHours != null ? this.SunWorkingHours : new WorkingHour()
      }
    ];
  }

  clickEvent() {
    this.CustomEvent = !this.CustomEvent;
  }

  uploadLogoFile(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      // const allowed_types = ['image/png'];
      const allowed_types = ["image/png", "image/jpeg"];

      const max_size = 600000;
      const max_height = 300;
      const max_width = 200;

      //if (fileInput.target.files[0].size > max_size) {
      //  this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Kb';
      //  this.showErrorMessage(this.imageError);

      //  return false;
      //}

      var isAllowedType: boolean = false;
      allowed_types.forEach(type => {
        if (type === fileInput.target.files[0].type) {
          isAllowedType = true;
        }
      });

      if (!isAllowedType) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        this.imageError = "Only png images are allowed.";
        this.showErrorMessage(this.imageError);
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          //const img_height = rs.currentTarget['height'];
          //const img_width = rs.currentTarget['width'];

          //// console.log(img_height, img_width);

          //if (img_height > max_height && img_width > max_width) {
          //  this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          //  this.showErrorMessage(this.imageError);
          //  return false;
          //} else {

          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          const indexOfComma = imgBase64Path.indexOf(",", 0);
          var logoString = this.cardImageBase64.slice(indexOfComma + 1);
          this.crmProfileImageFormat = fileInput.target.files[0].type;
          this.profileLogo = logoString;
          this.isImageSaved = true;
          this.indexTab = 0;
          //}
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  initializeVariablesOnEdit() {
    this.MonWorkingHours = new WorkingHour();
    this.TueWorkingHours = new WorkingHour();
    this.WedWorkingHours = new WorkingHour();
    this.ThuWorkingHours = new WorkingHour();
    this.FriWorkingHours = new WorkingHour();
    this.SatWorkingHours = new WorkingHour();
    this.SunWorkingHours = new WorkingHour();
    this.breakHours = new WorkingHour();

    this.breakHours.fromTime = null;
    this.breakHours.toTime = null;

    this.MonWorkingHours.fromTime = null;
    this.MonWorkingHours.toTime = null;
    this.TueWorkingHours.fromTime = null;
    this.TueWorkingHours.toTime = null;
    this.WedWorkingHours.fromTime = null;
    this.WedWorkingHours.toTime = null;
    this.ThuWorkingHours.fromTime = null;
    this.ThuWorkingHours.toTime = null;
    this.FriWorkingHours.fromTime = null;
    this.FriWorkingHours.toTime = null;

    this.SunWorkingHours.fromTime = null;
    this.SunWorkingHours.toTime = null;
    this.SatWorkingHours.fromTime = null;
    this.SatWorkingHours.toTime = null;

    this.DayOnOffMon = { name: "Off", code: 0 };
    this.DayOnOffTue = { name: "Off", code: 0 };
    this.DayOnOffWed = { name: "Off", code: 0 };
    this.DayOnOffThu = { name: "Off", code: 0 };
    this.DayOnOffFri = { name: "Off", code: 0 };
    this.DayOnOffSat = { name: "Off", code: 0 };
    this.DayOnOffSun = { name: "Off", code: 0 };
    this.selectedOccurrence = { name: "1", code: 1 };
  }
  initializeVariables() {
    this.Occurrence = [
      { name: "1", code: 1 },
      { name: "2", code: 2 },
      { name: "3", code: 3 },
      { name: "4", code: 4 },
      { name: "5", code: 5 }
    ];

    this.SpecialEvent = [
      { name: "Holiday", code: "holiday" },
      { name: "Shutdown", code: "shutdown" },
      { name: "Working", code: "working" }
    ];

    this.MonWorkingHours = new WorkingHour();
    this.TueWorkingHours = new WorkingHour();
    this.WedWorkingHours = new WorkingHour();
    this.ThuWorkingHours = new WorkingHour();
    this.FriWorkingHours = new WorkingHour();
    this.SatWorkingHours = new WorkingHour();
    this.SunWorkingHours = new WorkingHour();
    this.breakHours = new WorkingHour();

    this.breakHours.fromTime = null;
    this.breakHours.toTime = null;
    this.SunWorkingHours.fromTime = null;
    this.SunWorkingHours.toTime = null;
    this.SatWorkingHours.fromTime = null;
    this.SatWorkingHours.toTime = null;

    this.DayOnOffMon = { name: "On", code: 1 };
    this.DayOnOffTue = { name: "On", code: 1 };
    this.DayOnOffWed = { name: "On", code: 1 };
    this.DayOnOffThu = { name: "On", code: 1 };
    this.DayOnOffFri = { name: "On", code: 1 };
    this.DayOnOffSat = { name: "Off", code: 0 };
    this.DayOnOffSun = { name: "Off", code: 0 };
    this.selectedOccurrence = { name: "1", code: 1 };

    this.isDaily = false;
    this.isWeekly = false;
    this.isMonthly = false;
    this.isYearly = false;
    this.once = false;

    this.MonDayOfWeek = false;
    this.TueDayOfWeek = false;
    this.WedDayOfWeek = false;
    this.ThuDayOfWeek = false;
    this.FriDayOfWeek = false;
    this.SatDayOfWeek = false;
    this.SunDayOfWeek = false;

    // this.facilityCustomAvailability = new FacilityCustomAvailabilityResponseModel();
    //this.multipleCustomAvailability = new Array< FacilityCustomAvailabilityResponseModel>();
    //chnages done here//
    this.multipleCustomAvailability = [];
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  onRemoteAssistantSelect(remoteAssistantFlag) {
    this.setValidators(remoteAssistantFlag);
  }

  setValidators(remoteAssistantFlag) {
    if (remoteAssistantFlag) {
      this.form.controls["address"].clearValidators();
      this.form.controls["address"].updateValueAndValidity();

      //this.form.controls["address2"].clearValidators();
      //this.form.controls["address2"].updateValueAndValidity();

      this.form.controls["city"].clearValidators();
      this.form.controls["city"].updateValueAndValidity();

      this.form.controls["state"].clearValidators();
      this.form.controls["state"].updateValueAndValidity();

      this.form.controls["zipCode"].clearValidators();
      this.form.controls["zipCode"].updateValueAndValidity();

      this.form.controls["branchName"].clearValidators();
      this.form.controls["branchName"].updateValueAndValidity();

      this.form.controls["cpFName"].clearValidators();
      this.form.controls["cpFName"].updateValueAndValidity();

      this.form.controls["cpLName"].clearValidators();
      this.form.controls["cpLName"].updateValueAndValidity();

      this.form.controls["email"].clearValidators();
      this.form.controls["email"].updateValueAndValidity();

      this.form.controls["cpPhnNumber"].clearValidators();
      this.form.controls["cpPhnNumber"].updateValueAndValidity();

      this.form.controls["timeZone"].clearValidators();
      this.form.controls["timeZone"].updateValueAndValidity();
    } else {
      this.form.controls["address"].setValidators([
        Validators.required,
        CustomValidator.whitespace
      ]);
      //this.form.controls["address2"].setValidators([Validators.required, CustomValidator.whitespace]);
      this.form.controls["city"].setValidators([
        Validators.required,
        CustomValidator.whitespace,
        CustomValidator.onlyCharacters
      ]);
      this.form.controls["city"].updateValueAndValidity();

      this.form.controls["state"].setValidators([
        Validators.required,
      ]);

      this.form.controls["state"].updateValueAndValidity();

      this.form.controls["zipCode"].setValidators([
        Validators.required,
        CustomValidator.whitespace,
        CustomValidator.onlyNumbers
      ]);
      this.form.controls["zipCode"].updateValueAndValidity();

      this.form.controls["branchName"].setValidators([Validators.required]);
      this.form.controls["branchName"].updateValueAndValidity();

      this.form.controls["cpFName"].setValidators([
        CustomValidator.onlyCharacters
      ]);
      this.form.controls["cpFName"].updateValueAndValidity();

      this.form.controls["cpLName"].setValidators([
        CustomValidator.onlyCharacters
      ]);
      this.form.controls["cpLName"].updateValueAndValidity();

      this.form.controls["email"].setValidators([CustomValidator.email]);
      this.form.controls["email"].updateValueAndValidity();

      this.form.controls["timeZone"].setValidators([Validators.required]);
      this.form.controls["timeZone"].updateValueAndValidity();

    }
  }

  onBranchChange(branch) {
    this.selectedBranch = branch.value;
  }

  onDoctorChange(doctor) {
    this.excludedDoctor = doctor.value;
  }

  onTherapistChange(therapist) {
    this.excludedTherapist = therapist.value;
  }

  onEventOccurrenceChange(occurrence) {
    this.selectedOccurrence = occurrence.value;
  }

  onSpecialEventChange(specialEvent) {
    this.selectedSpecialEvent = specialEvent.value;
  }

  onCancel() {
    // let facility = new FacilityAvailabilityResponseModel();
    // const facilityAvailability: Array<FacilityAvailabilityResponseModel> = [];
    // if (this.MonWorkingHours) {
    //   if (this.MonWorkingHours.fromTime > this.MonWorkingHours.toTime) {
    //     return;
    //    }
    //   facility = new FacilityAvailabilityResponseModel();
    //   facility.crmFacilityAvailWeekDays = Week.Monday;
    //   facility.crmFacilityAvailFromTime = this.MonWorkingHours.fromTime;
    //   facility.crmFacilityAvailToTime = this.MonWorkingHours.toTime;
    //   facility.crmBreakStartTime = this.breakHours.fromTime;
    //   facility.crmBreakEndTime = this.breakHours.toTime;

    //   facilityAvailability.push(facility);
    //   console.log(facilityAvailability);
    // }

    this.facilityResponseModel = null;
    this.form.reset();

    // if (redirectToListing)
    this.router.navigate(["admin/facilities"], {
      state: { searchRequestModel: this.searchRequestModel }
    });
  }

  onAddCustom() {
    const facilityCustomAvailability = new FacilityCustomAvailabilityResponseModel();
    let commonDate =
      (new Date().getMonth() + 1).toString() +
      "/" +
      new Date().getDate().toString() +
      "/" +
      new Date().getFullYear().toString();
    if (this.once === true) {
      facilityCustomAvailability.crmIsRecurring = false;
      facilityCustomAvailability.crmRecurringFrequency = 1;
    } else {
      this.isDaily == true ? facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsDaily = true : facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsDaily = false;
      this.isWeekly == true ? facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsWeekly = true : facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsWeekly = false;
      this.isMonthly == true ? facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsMonthly = true : facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsMonthly = false;
      this.isYearly == true ? facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsYearly = true : facilityCustomAvailability.crmIsRecurring = facilityCustomAvailability.crmIsYearly = false;
    }
    if (this.facilityId) {
      facilityCustomAvailability.crmFacilityId = parseInt(this.facilityId, 10);
    }
    facilityCustomAvailability.modifiedByUserInfoId = this.currentUserToken.userId;
    facilityCustomAvailability.crmFacilityCustomAvailStartDate = this.customStartDate;
    facilityCustomAvailability.crmFacilityCustomAvailEndDate = this.customEndDate;
    facilityCustomAvailability.crmFacilityCustomAvailStartTimeString = moment(
      this.customStartTime,
      "hh:mm A"
    ).format("HH:mm");
    facilityCustomAvailability.crmFacilityCustomAvailEndTimeString = moment(
      this.customEndTime,
      "hh:mm A"
    ).format("HH:mm");
    facilityCustomAvailability.facilityCustomAvailStartTimeDate = this.customStartDate == undefined ? facilityCustomAvailability.crmFacilityCustomAvailStartDate : this.customStartDate == null ?
      facilityCustomAvailability.crmFacilityCustomAvailStartDate : new Date(facilityCustomAvailability.crmFacilityCustomAvailStartDate.toDateString() + " " + facilityCustomAvailability.crmFacilityCustomAvailStartTimeString);
    facilityCustomAvailability.facilityCustomAvailEndTimeDate = this.customEndDate == undefined ? facilityCustomAvailability.crmFacilityCustomAvailEndDate : this.customEndDate == null ? facilityCustomAvailability.crmFacilityCustomAvailEndDate :
      new Date(facilityCustomAvailability.crmFacilityCustomAvailEndDate.toDateString() + " " + facilityCustomAvailability.crmFacilityCustomAvailEndTimeString);

    if (this.selectedOccurrence) {
      facilityCustomAvailability.crmRecurringFrequency = this.selectedOccurrence.code;
    }
    if (this.selectedSpecialEvent !== undefined) {
      if (this.selectedSpecialEvent.name === "Holiday") {
        facilityCustomAvailability.isHoliday = true;
        facilityCustomAvailability.isShutdown = false;
      } else if (this.selectedSpecialEvent.name === "Shutdown") {
        facilityCustomAvailability.isHoliday = false;
        facilityCustomAvailability.isShutdown = true;
      } else {
        facilityCustomAvailability.isHoliday = false;
        facilityCustomAvailability.isShutdown = false;
      }
    }

    facilityCustomAvailability.crmFacilityCustomDays = "";
    if (this.MonDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays = "1";
    }
    if (this.TueDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",2";
    }
    if (this.WedDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",4";
    }
    if (this.ThuDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",8";
    }
    if (this.FriDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",16";
    }
    if (this.SatDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",32";
    }
    if (this.SunDayOfWeek) {
      facilityCustomAvailability.crmFacilityCustomDays =
        facilityCustomAvailability.crmFacilityCustomDays + ",64";
    }

    let result = this.validateCustomDateFields(facilityCustomAvailability);
    let checkTimeValidate = this.CheckTime('C');

    if (!result.includes(false) && !checkTimeValidate) {


      let facilityCustomAvailabilityModel = this.checkIfUpdateCustom(facilityCustomAvailability);
      this.multipleCustomAvailability.push(facilityCustomAvailabilityModel);
      this.resetValuesToCustom();
    }
  }

  checkIfUpdateCustom(facilityCustomAvailability: FacilityCustomAvailabilityResponseModel) {
    if (this.multipleCustomAvailability.filter(x => x.crmFacilityCustomAvailabilityId == this.selectedFacilityCustomAvailabilityId).length > 0) {
      this.multipleCustomAvailability = this.multipleCustomAvailability.filter(x => x.crmFacilityCustomAvailabilityId != this.selectedFacilityCustomAvailabilityId);
      facilityCustomAvailability.crmFacilityCustomAvailabilityId = this.selectedFacilityCustomAvailabilityId;
    }
    return facilityCustomAvailability;
  }

  validateCustomDateFields(facilityCustomAvailability: FacilityCustomAvailabilityResponseModel) {

    this.splEventError = this.splEventseriesError = this.customAvailStartDateError = this.customAvailEndDateError = this.customAvailStartTimeError = this.customAvailEndTimeError = this.selectedOccurrenceError = this.dayofWeekError = false;
    var IsCustomError = new Array<boolean>();
    if (this.selectedSpecialEvent === undefined || this.selectedSpecialEvent.code <= 0) {
      this.splEventError = true;
      IsCustomError.push(false);
    }

    if (this.isDaily == false && this.isWeekly == false && this.isMonthly == false && this.isYearly == false && this.once == false) {
      this.splEventseriesError = true;
      IsCustomError.push(false);
    }

    if (this.selectedOccurrence === undefined || this.selectedOccurrence.code <= 0) {
      this.selectedOccurrenceError = true;
      IsCustomError.push(false);
    }
    this.selectedOccurrenceError
    if (!this.MonDayOfWeek && !this.TueDayOfWeek && !this.WedDayOfWeek && !this.ThuDayOfWeek && !this.FriDayOfWeek && !this.SatDayOfWeek && !this.SunDayOfWeek) {
      this.dayofWeekError = true;
      IsCustomError.push(false);
    }

    if (facilityCustomAvailability.crmFacilityCustomAvailStartDate == undefined || facilityCustomAvailability.crmFacilityCustomAvailEndDate == undefined) {
      if (facilityCustomAvailability.crmFacilityCustomAvailStartDate == undefined) {
        this.customAvailStartDateError = true;
        IsCustomError.push(false);
      }
    }
    if (facilityCustomAvailability.crmFacilityCustomAvailEndDate == undefined) {
      this.customAvailEndDateError = true;
      IsCustomError.push(false);
    }
    if (facilityCustomAvailability.crmFacilityCustomAvailStartTimeString == 'Invalid date') {
      this.customAvailStartTimeError = true;
      IsCustomError.push(false);
    }

    if (facilityCustomAvailability.crmFacilityCustomAvailEndTimeString == 'Invalid date') {
      this.customAvailEndTimeError = true;
      IsCustomError.push(false);
    }

    return IsCustomError;
  }

  onCustomCancel() {
    // this.multipleCustomAvailability = null;
    this.resetValuesToCustom();
  }

  onChangeEventSeries(e) {
    switch (e.innerHTML) {
      case "Once":
        this.once = !this.once;
        this.isDaily = false;
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = false;
        break;
      case "Daily":
        this.isDaily = !this.isDaily;
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = false;
        this.once = false;
        break;
      case "Weekly":
        this.isDaily = false;
        this.isWeekly = !this.isWeekly;
        this.isMonthly = false;
        this.isYearly = false;
        this.once = false;
        break;
      case "Monthly":
        this.isDaily = false;
        this.isWeekly = false;
        this.isMonthly = !this.isMonthly;
        this.isYearly = false;
        this.once = false;
        break;
      case "Yearly":
        this.isDaily = false;
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = !this.isYearly;
        this.once = false;
        break;
      default:
        this.isDaily = false;
        this.isWeekly = false;
        this.isMonthly = false;
        this.isYearly = false;
        this.once = false;
    }
  }

  onChangeDayOfWeek(e) {
    switch (e.innerHTML) {
      case "Mon":
        this.MonDayOfWeek = !this.MonDayOfWeek;
        break;
      case "Tue":
        this.TueDayOfWeek = !this.TueDayOfWeek;
        break;
      case "Wed":
        this.WedDayOfWeek = !this.WedDayOfWeek;
        break;
      case "Thu":
        this.ThuDayOfWeek = !this.ThuDayOfWeek;
        break;
      case "Fri":
        this.FriDayOfWeek = !this.FriDayOfWeek;
        break;
      case "Sat":
        this.SatDayOfWeek = !this.SatDayOfWeek;
        break;
      case "Sun":
        this.SunDayOfWeek = !this.SunDayOfWeek;
        break;
      default:
        break;
    }
  }

  changeDayOnOffSun() {
    if (this.DayOnOffSun.code == 0) {
      this.SunWorkingHours.fromTime = null;
      this.SunWorkingHours.toTime = null;
    } else {
      this.SunWorkingHours.fromTime = "08:00 AM";
      this.SunWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffSat() {
    if (this.DayOnOffSat.code == 0) {
      this.SatWorkingHours.fromTime = null;
      this.SatWorkingHours.toTime = null;
    } else {
      this.SatWorkingHours.fromTime = "08:00 AM";
      this.SatWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffFri() {
    if (this.DayOnOffFri.code == 0) {
      this.FriWorkingHours.fromTime = null;
      this.FriWorkingHours.toTime = null;
    } else {
      this.FriWorkingHours.fromTime = "08:00 AM";
      this.FriWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffThu() {
    if (this.DayOnOffThu.code == 0) {
      this.ThuWorkingHours.fromTime = null;
      this.ThuWorkingHours.toTime = null;
    } else {
      this.ThuWorkingHours.fromTime = "08:00 AM";
      this.ThuWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffWed() {
    if (this.DayOnOffWed.code == 0) {
      this.WedWorkingHours.fromTime = null;
      this.WedWorkingHours.toTime = null;
    } else {
      this.WedWorkingHours.fromTime = "08:00 AM";
      this.WedWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffTue() {
    if (this.DayOnOffTue.code == 0) {
      this.TueWorkingHours.fromTime = null;
      this.TueWorkingHours.toTime = null;
    } else {
      this.TueWorkingHours.fromTime = "08:00 AM";
      this.TueWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOffMon() {
    if (this.DayOnOffMon.code == 0) {
      this.MonWorkingHours.fromTime = null;
      this.MonWorkingHours.toTime = null;
    } else {
      this.MonWorkingHours.fromTime = "08:00 AM";
      this.MonWorkingHours.toTime = "08:00 PM";
    }
  }

  changeDayOnOff(item: any) {
    if (item.name == 'Monday') {
      this.DayOnOffMon = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;

        this.MonWorkingHours.fromTime = null;
        this.MonWorkingHours.toTime = null;
      }
      else{
        this.MonWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.MonWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Tuesday') {
      this.DayOnOffTue = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.TueWorkingHours.fromTime = null;
        this.TueWorkingHours.toTime = null;
      }
      else{
        this.TueWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.TueWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Wednesday') {
      this.DayOnOffWed = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.WedWorkingHours.fromTime = null;
        this.WedWorkingHours.toTime = null;
      }
      else{
        this.WedWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.WedWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Thursday') {
      this.DayOnOffThu = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.ThuWorkingHours.fromTime = null;
        this.ThuWorkingHours.toTime = null;
      }
      else{
        this.ThuWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.ThuWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Friday') {
      this.DayOnOffFri = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.FriWorkingHours.fromTime = null;
        this.FriWorkingHours.toTime = null;
      }
      else{
        this.FriWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.FriWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Saturday') {
      this.DayOnOffSat = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.SatWorkingHours.fromTime = null;
        this.SatWorkingHours.toTime = null;
      }
      else{
        this.SatWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.SatWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }
    else if (item.name == 'Sunday') {
      this.DayOnOffSun = item.isOn;
      if (item.isOn?.code == 0) {
        item.DayWorkingHour.fromTime = null;
        item.DayWorkingHour.toTime= null;
        
        this.SunWorkingHours.fromTime = null;
        this.SunWorkingHours.toTime = null;
      }
      else{
        this.SunWorkingHours.fromTime = item.DayWorkingHour.fromTime;
        this.SunWorkingHours.toTime = item.DayWorkingHour.toTime;
      }
    }

    // if (this.DayOnOffSun.code == 0) {
    //   this.SunWorkingHours.fromTime = null;
    //   this.SunWorkingHours.toTime = null;
    // } else {
    //   this.SunWorkingHours.fromTime = "08:00 AM";
    //   this.SunWorkingHours.toTime = "08:00 PM";
    // }
    // if (this.DayOnOffSat.code == 0) {
    //   this.SatWorkingHours.fromTime = null;
    //   this.SatWorkingHours.toTime = null;
    // } else {
    //   this.SatWorkingHours.fromTime = "08:00 AM";
    //   this.SatWorkingHours.toTime = "08:00 PM";
    // }
    // if (this.DayOnOffFri.code == 0) {
    //   this.FriWorkingHours.fromTime = null;
    //   this.FriWorkingHours.toTime = null;
    // } else {
    //   this.FriWorkingHours.fromTime = "08:00 AM";
    //   this.FriWorkingHours.toTime = "08:00 PM";
    // }
    // if (this.DayOnOffThu.code == 0) {
    //   this.ThuWorkingHours.fromTime = null;
    //   this.ThuWorkingHours.toTime = null;
    // } else {
    //   this.ThuWorkingHours.fromTime = "08:00 AM";
    //   this.ThuWorkingHours.toTime = "08:00 PM";
    // }
    // if (this.DayOnOffWed.code == 0) {
    //   this.WedWorkingHours.fromTime = null;
    //   this.WedWorkingHours.toTime = null;
    // } else {
    //   this.WedWorkingHours.fromTime = "08:00 AM";
    //   this.WedWorkingHours.toTime = "08:00 PM";
    // }
    // if (this.DayOnOffTue.code == 0) {
    //   this.TueWorkingHours.fromTime = null;
    //   this.TueWorkingHours.toTime = null;
    // } else {
    //   this.TueWorkingHours.fromTime = "08:00 AM";
    //   this.TueWorkingHours.toTime = "08:00 PM";
    // }

  }

  onSubmit() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const facilityRequestmodel = this.getValuesFromForm();

    let _stateName: string = '';
    if (facilityRequestmodel.crmState) {
      let SelectedState = this.stateModel.find(x => x.appStateName == facilityRequestmodel.crmState);
      _stateName = SelectedState.appStateName;
    }
    if (facilityRequestmodel.isRemoteAssistance) {
      const manageFacility = this.isEditMode ? this.facilityService.updateFacility(facilityRequestmodel) : this.facilityService.addFacility(facilityRequestmodel);

      manageFacility.pipe(takeUntil(this.unsubscriber), finalize(() => (this.loading = false)))
        .subscribe(result => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage(this.isEditMode ? "Facility is updated successfully" : "Facility is created successfully");
              if (this.isEditMode) {
                this.hideDialogCustomeDate();
                this.onCancel();
              } else {
                this.timeZone = facilityRequestmodel.crmTimeZone;
                const crmFacilityIdRes = result.responseModel;
                this.enableWorkingHrPopup = true;
                const facilityResponseModel = new FacilityResponseModelSend();
                this.facilityId = crmFacilityIdRes.toString();
                this.facilityResponseModel.crmFacilityId = parseInt(this.facilityId, 10);
                facilityRequestmodel.facilityAvailability = this.getValuesFromAvailability();
                this.isEditMode = true;
              }
            }
          }
          else {
            this.showErrorMessage(result.message);
          }
        },
          httpResponseError => {
            this.showErrorMessage(httpResponseError.message);
          }
        );
    }
    else {
      this.facilityService.ValidateZipcodeWithState(_stateName, Number(facilityRequestmodel.crmFacilityZipCode), facilityRequestmodel.crmTimeZone)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            if (result.responseModel && result.responseModel != undefined && result.responseModel != null) {
              this.loading = false;
              if (result.responseModel[0] && result.responseModel[1]) {
                const manageFacility = this.isEditMode ? this.facilityService.updateFacility(facilityRequestmodel) : this.facilityService.addFacility(facilityRequestmodel);

                manageFacility.pipe(takeUntil(this.unsubscriber), finalize(() => (this.loading = false)))
                  .subscribe(result => {
                    if (result && result.isSuccess) {
                      if (result.responseModel != undefined && result.responseModel != null) {
                        this.showSuccessMessage(this.isEditMode ? "Facility is updated successfully" : "Facility is created successfully");
                        if (this.isEditMode) {
                          this.hideDialogCustomeDate();
                          this.onCancel();
                        } else {
                          this.timeZone = facilityRequestmodel.crmTimeZone;
                          const crmFacilityIdRes = result.responseModel;
                          this.enableWorkingHrPopup = true;
                          const facilityResponseModel = new FacilityResponseModelSend();
                          this.facilityId = crmFacilityIdRes.toString();
                          if (this.facilityResponseModel != null && this.facilityResponseModel != undefined) {
                            this.facilityResponseModel.crmFacilityId = parseInt(this.facilityId, 10);
                          }
                          else {
                            this.facilityResponseModel = new FacilityResponseModel();
                            this.facilityResponseModel.crmFacilityId = parseInt(this.facilityId, 10);
                          }
                          facilityRequestmodel.facilityAvailability = this.getValuesFromAvailability();
                          this.isEditMode = true;
                        }
                      }
                    }
                    else {
                      this.showErrorMessage(result.message);
                    }
                  },
                    httpResponseError => {
                      this.showErrorMessage(httpResponseError.message);
                    }
                  );
              }
              else {
                if (!result.responseModel[0]) {
                  this.showWarningMessage('Invalid zip code for ' + _stateName);
                }
                else {
                  if (!result.responseModel[1])
                    this.showWarningMessage('Invalid timezone for zip code ' + Number(facilityRequestmodel.crmFacilityZipCode));
                }
                this.loading = false;
              }
            }
            else {
              this.showErrorMessage(result.message);
              this.loading = false;
            }
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    }
  }

  private async getBranches() {
    this.loadingCustom = true;
    this.progress.next(true);
    await this.facilityService
      .getAllBranches()
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => this.progress.next(false))
      )
      .toPromise()
      .then(result => {
        if (!result) {
          return;
        }
        this.branch = [];
        if (result.responseModel != undefined && result.responseModel != null) {
          const branchArray = result.responseModel;
          branchArray.forEach((value: BranchResponseModel) => {
            this.branch.push({
              label: value.crmBranchName,
              value: value.crmBranchId.toString()
            });
          });
        }
      })
      .catch(httpResponseError => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }
  private async getInsuranceInfoes() {
    this.loadingCustom = true;
    this.progress.next(true);
    await this.facilityService
      .getAllInsuranceInfoes()
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => this.progress.next(false))
      )
      .toPromise()
      .then(result => {
        if (!result) {
          return;
        }
        this.insuanceInfo = [];
        if (result.responseModel != undefined && result.responseModel != null) {
          const branchArray = result.responseModel;
          branchArray.forEach((value: InsuranceInfoResponseModel) => {
            this.insuanceInfo.push({
              label: value.crmPolicyHolderName,
              value: value.crmInsuranceInfoId.toString()
            });
          });
        }
      })
      .catch(httpResponseError => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  private getBranchByCode(branchId: string) {
    if (!branchId) {
      return null;
    }

    let foundBranch = this.branch.find(x => x.value == branchId);

    //let foundBranch: { label: string, value: string };

    //for (const branch of this.branch) {
    //  if (branch.value === branchId) {
    //    foundBranch = branch;
    //    break;
    //  }
    //}

    return foundBranch;
  }

  private async getDoctors() {
    this.loadingCustom = true;
    this.progress.next(true);
    await this.docotrService
      .getDoctors()
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => this.progress.next(false))
      )
      .toPromise()
      .then(result => {
        if (!result) {
          return;
        }
        this.doctors = [];
        if (result.responseModel != undefined && result.responseModel != null) {
          this.doctors = result.responseModel;
          //doctorArray.forEach((value: DoctorResponseModel) => {
          //  this.doctors.push({ label: value.crmDoctorFirstName + ' ' + value.crmDoctorLastName, value: value.crmDoctorId.toString() });
          //});
        }
      })
      .catch(httpResponseError => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  private getDoctorByCode(doctorId: string) {
    if (!doctorId) {
      return null;
    }

    let foundDoctor: { label: string; value: string };
    if (this.doctors) {
      foundDoctor = this.doctors.find(x => x.value == doctorId);
    }
    return foundDoctor;
  }

  private async getTherapists() {
    this.loadingCustom = true;
    this.progress.next(true);
    await this.facilityService
      .getAllTherapist()
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => this.progress.next(false))
      )
      .toPromise()
      .then(result => {
        if (!result) {
          return;
        }
        this.therapists = [];
        if (result.responseModel != undefined && result.responseModel != null) {
          const therapistArray = result.responseModel;
          therapistArray.forEach((value: UserResponseModel) => {
            this.therapists.push({
              label: value.appUserLname + ", " + value.appUserFname,
              value: value.appUserInfoId.toString()
            });
          });
        }
      })
      .catch(httpResponseError => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  AddInsurance() {
    const ref = this.dialougeService.open(AddInsuranceDialogComponent, {
      showHeader: true,
      closable: true,
      data: {
        //id: crmSalesOrderId,
        //isAppointment: false,
        //searchRequestModel: this.searchRequestModel,
        //selectedTab: this.index
      }
    });

    ref.onClose.subscribe(() => {
      this.getInsuranceInfoes();
    });
  }

  private getTherapistByCode(therapistId: string) {
    if (!therapistId) {
      return null;
    }

    let foundTherapist: { label: string; value: string };

    if (this.therapists) {
      foundTherapist = this.therapists.find(x => x.value == therapistId);
    }
    return foundTherapist;
  }

  private getValuesFromAvailability(): FacilityAvailabilityResponseModelSend[] {
    let facility = new FacilityAvailabilityResponseModelSend();
    const facilityAvailability: Array<
      FacilityAvailabilityResponseModelSend
    > = [];
    let commonDate =
      (new Date().getMonth() + 1).toString() +
      "/" +
      new Date().getDate().toString() +
      "/" +
      new Date().getFullYear().toString();
    if (this.MonWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = parseInt(this.facilityId, 10);
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.MonWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.MonWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffMon.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 1;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.TueWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.TueWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.TueWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffTue.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 2;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.WedWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.WedWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.WedWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffWed.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 4;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.ThuWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.ThuWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.ThuWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffThu.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 8;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.FriWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.FriWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.FriWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffFri.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 16;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.SatWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.SatWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.SatWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffSat.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 32;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    if (this.SunWorkingHours.fromTime) {
      facility = new FacilityAvailabilityResponseModelSend();
      facility.crmFacilityId = this.isEditMode
        ? parseInt(this.facilityId, 10)
        : 0;
      facility.crmFacilityAvailFromTimeString = moment(
        this.SunWorkingHours.fromTime,
        "hh:mm A"
      ).format("HH:mm");
      facility.crmFacilityAvailToTimeString = moment(
        this.SunWorkingHours.toTime,
        "hh:mm A"
      ).format("HH:mm");
      if (this.includeBreakHours) {
        if (this.breakHours.fromTime !== null) {
          facility.crmBreakStartTimeString = moment(
            this.breakHours.fromTime,
            "hh:mm A"
          ).format("HH:mm");
        }
        if (this.breakHours.toTime !== null) {
          facility.crmBreakEndTimeString = moment(
            this.breakHours.toTime,
            "hh:mm A"
          ).format("HH:mm");
        }
      }
      facility.isActive = this.DayOnOffSun.code === 0 ? false : true;
      facility.crmFacilityAvailWeekDays = 64;
      facility.crmFacilityAvailFromDate = new Date();
      facility.crmFacilityAvailToDate = new Date();
      facilityAvailability.push(facility);
    }
    return facilityAvailability;
  }

  private getValuesFromForm() {
    const form = this.form;
    const facilityResponseModel = new FacilityResponseModelSend();
    let branch = new BranchResponseModel();
    let doctor = new DoctorFacilityResponseModel();
    let therapist = new TherapistFacilityResponseModel();
    // console.log(form.get(this.formFields.zipCode).value);
    facilityResponseModel.crmFacilityId = this.isEditMode
      ? parseInt(this.facilityId, 10)
      : 0;
    facilityResponseModel.crmFacilityName = form.get(
      this.formFields.facility
    ).value;
    facilityResponseModel.crmBioData = form.get(
      this.formFields.bioEditorText
    ).value;
    facilityResponseModel.crmTimeZone = this.timeZone;
    facilityResponseModel.crmFacilityContactEmailAddress = form.get(
      this.formFields.email
    ).value;
    facilityResponseModel.crmFacilityZipCode = form.get(
      this.formFields.zipCode
    ).value;
    // facilityResponseModel.crmFacilityCapacity = form.get(this.formFields.room).value;
    facilityResponseModel.crmAddressLine1 = form.get(
      this.formFields.address
    ).value;
    facilityResponseModel.crmAddressLine2 = form.get(
      this.formFields.address2
    ).value;
    facilityResponseModel.crmCity = form.get(this.formFields.city).value;
    facilityResponseModel.crmFacilityContactPersonFirstName = form.get(
      this.formFields.cpFName
    ).value;
    facilityResponseModel.crmFacilityContactPersonLastName = form.get(
      this.formFields.cpLName
    ).value;
    facilityResponseModel.isRemoteAssistance = this.remoteAssistant;
    if (this.selectedState !== undefined && this.selectedState !== null) {
      facilityResponseModel.crmState = this.selectedState.appStateName;
    }
    facilityResponseModel.isActive = this.isActive;
    facilityResponseModel.crmIsAllowSelfScheduleAppointments = this.isAllowSelfScheduleAppointments;
    // facilityResponseModel.crmFacilityContactNo = form.get(this.formFields.cpPhnNumber).value;
    var tempPhone = form.get(this.formFields.cpPhnNumber).value;
    if (tempPhone.indexOf("-") > -1) {
      facilityResponseModel.crmFacilityContactNo = tempPhone
        .split("-")
        .join("");
    } else {
      facilityResponseModel.crmFacilityContactNo = tempPhone;
    }

    if (
      facilityResponseModel.crmAddressLine1 !== "" &&
      facilityResponseModel.crmAddressLine2 !== "" &&
      facilityResponseModel.crmAddressLine1 != null &&
      facilityResponseModel.crmAddressLine2 != null
    ) {
      facilityResponseModel.crmFacilityAddress =
        facilityResponseModel.crmAddressLine1 +
        " " +
        facilityResponseModel.crmAddressLine2 +
        " " +
        facilityResponseModel.crmCity +
        " " +
        facilityResponseModel.crmState +
        " " +
        facilityResponseModel.crmFacilityZipCode;
    } else {
      if (
        facilityResponseModel.crmAddressLine1 !== "" &&
        facilityResponseModel.crmAddressLine1 !== null
      ) {
        facilityResponseModel.crmFacilityAddress =
          facilityResponseModel.crmAddressLine1;
      } else if (
        facilityResponseModel.crmAddressLine2 !== "" &&
        facilityResponseModel.crmAddressLine2 !== null
      ) {
        facilityResponseModel.crmFacilityAddress =
          facilityResponseModel.crmAddressLine2;
      }
    }
    facilityResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    if (this.selectedBranch) {
      facilityResponseModel.crmBranchId = parseInt(
        this.selectedBranch.value,
        10
      );
      branch.crmBranchId = parseInt(this.selectedBranch.value, 10);
      branch.crmBranchName = this.selectedBranch.label;
      branch.modifiedByUserInfoId = this.currentUserToken.userId;
      facilityResponseModel.crmBranch = branch;
    }
    if (this.excludedDoctor !== undefined) {
      this.doctorsRequest = [];
      this.excludedDoctor.forEach(result => {
        doctor = new DoctorFacilityResponseModel();
        doctor.crmDoctorId = parseInt(result.value, 10);
        doctor.modifiedByUserInfoId = this.currentUserToken.userId;
        this.doctorsRequest.push(doctor);
      });
      facilityResponseModel.doctorFacility = this.doctorsRequest;
    }
    if (this.excludedTherapist !== undefined) {
      this.therapistRequest = [];
      this.excludedTherapist.forEach(result => {
        therapist = new TherapistFacilityResponseModel();
        therapist.crmTherapistId = parseInt(result.value, 10);
        therapist.modifiedByUserInfoId = this.currentUserToken.userId;
        this.therapistRequest.push(therapist);
      });
      facilityResponseModel.therapistFacility = this.therapistRequest;
    }

    if (form.get(this.formFields.insuanceInfo).value) {
      let values: any[] = form.get(this.formFields.insuanceInfo).value;
      this.insurancesRequest = [];
      values.forEach(result => {
        let insurance = new InsuranceInfoResponseModel();
        insurance.crmInsuranceInfoId = parseInt(result.value);
        insurance.crmPolicyHolderName = result.label;
        this.insurancesRequest.push(insurance);
      });
      facilityResponseModel.crmInsurances = this.insurancesRequest;
    }

    facilityResponseModel.crmProfileImage =
      this.profileLogo == null && !this.isImageSaved
        ? this.profileLogo
        : this.profileLogo != null && this.isImageSaved
          ? this.profileLogo
          : this.facilityResponseModel.crmProfileImage;
    facilityResponseModel.crmProfileImageFormat = this.crmProfileImageFormat;


    //Adding time Zone functionality

    if (this.selectedTimeZone) {
      facilityResponseModel.crmTimeZone = this.selectedTimeZone.appTimeZoneOffSet;
    }
    return facilityResponseModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.facilityResponseModel) {
      return;
    }

    const form = this.form;
    this.timeZone = this.facilityResponseModel.crmTimeZone;
    form
      .get(this.formFields.facility)
      .setValue(this.facilityResponseModel.crmFacilityName);
    form
      .get(this.formFields.bioEditorText)
      .setValue(this.facilityResponseModel.crmBioData);
    form
      .get(this.formFields.email)
      .setValue(this.facilityResponseModel.crmFacilityContactEmailAddress);
    form
      .get(this.formFields.zipCode)
      .setValue(this.facilityResponseModel.crmFacilityZipCode);
    if (this.facilityResponseModel.crmBranchId) {
      this.selectedBranch = this.getBranchByCode(
        this.facilityResponseModel.crmBranchId.toString()
      );
      form.get(this.formFields.branchName).setValue(this.selectedBranch);
    }

    form.get(this.formFields.address).setValue(this.facilityResponseModel.crmAddressLine1);
    if (this.facilityResponseModel.crmAddressLine2 !== "" && this.facilityResponseModel.crmAddressLine2 !== null) {
      form.get(this.formFields.address2).setValue(this.facilityResponseModel.crmAddressLine2);
    }
    form.get(this.formFields.city).setValue(this.facilityResponseModel.crmCity);
    form.get(this.formFields.cpFName).setValue(this.facilityResponseModel.crmFacilityContactPersonFirstName);
    form.get(this.formFields.cpLName).setValue(this.facilityResponseModel.crmFacilityContactPersonLastName);
    this.remoteAssistant = this.facilityResponseModel.isRemoteAssistance;
    this.getStateByCode(this.facilityResponseModel.crmState);
    form.get(this.formFields.cpPhnNumber).setValue(this.facilityResponseModel.crmFacilityContactNo);
    this.isActive = this.facilityResponseModel.isActive;
    this.isAllowSelfScheduleAppointments = this.facilityResponseModel.crmIsAllowSelfScheduleAppointments;

    if (this.facilityResponseModel.crmTimeZone)
      this.selectedTimeZone = this.getTimeZoneByCode(this.facilityResponseModel.crmTimeZone);

    form.get(this.formFields.timeZone).setValue(this.selectedTimeZone);

    if (this.facilityResponseModel.crmProfileImage) {
      const image = new Image();
      image.src =
        "data:image/png;base64," + this.facilityResponseModel.crmProfileImage;
      image.onload = () => {
        const imgBase64Path =
          "data:image/png;base64," + this.facilityResponseModel.crmProfileImage;
        this.cardImageBase64 = imgBase64Path;
        const indexOfComma = imgBase64Path.indexOf(",", 0);
        this.profileLogo = logoString;
        var logoString = this.cardImageBase64.slice(indexOfComma + 1);
        this.isImageSaved = true;
      };
    }

    if (this.facilityResponseModel.doctorFacility.length === 1) {
      this.excludedDoctor = [];
      this.excludedDoctor[0] = this.getDoctorByCode(
        this.facilityResponseModel.doctorFacility[0].crmDoctorId.toString()
      );
    } else if (this.facilityResponseModel.doctorFacility.length >= 1) {
      this.excludedDoctor = [];
      this.facilityResponseModel.doctorFacility.forEach(result => {
        const tempDoctor = this.getDoctorByCode(result.crmDoctorId.toString());
        this.excludedDoctor.push(tempDoctor);
      });
    }
    this.selectedInsuanceInfo = [];
    if (this.facilityResponseModel.crmInsurances) {
      this.facilityResponseModel.crmInsurances.forEach(result => {
        this.selectedInsuanceInfo.push({
          label: result.crmPolicyHolderName,
          value: result.crmInsuranceInfoId.toString()
        });
      });
      form
        .get(this.formFields.insuanceInfo)
        .setValue(this.selectedInsuanceInfo);
    }

    if (this.facilityResponseModel.therapistFacility.length === 1) {
      this.excludedTherapist = [];
      this.excludedTherapist[0] = this.getTherapistByCode(
        this.facilityResponseModel.therapistFacility[0].crmTherapistId.toString()
      );
    } else if (this.facilityResponseModel.therapistFacility.length >= 1) {
      this.excludedTherapist = [];
      this.facilityResponseModel.therapistFacility.forEach(result => {
        const tempTherapist = this.getTherapistByCode(
          result.crmTherapistId.toString()
        );
        if (tempTherapist !== undefined) {
          this.excludedTherapist.push(tempTherapist);
        }
      });
    }
    this.setValuesToWorkingHoursForm();
  }

  setValuesToWorkingHoursForm() {
    this.initializeVariablesOnEdit();
    const facilityAvailability = this.facilityResponseModel
      .facilityAvailability;

    this.facilityResponseModel.facilityCustomAvailability.forEach(result => {
      result.crmFacilityCustomAvailStartTimeDate = new Date(
        new Date(result.crmFacilityCustomAvailStartDate).toDateString() +
        " " +
        result.crmFacilityCustomAvailStartTime
      );
      result.crmFacilityCustomAvailEndTimeDate = new Date(
        new Date(result.crmFacilityCustomAvailEndDate).toDateString() +
        " " +
        result.crmFacilityCustomAvailEndTime
      );
    });
    this.multipleCustomAvailability = this.facilityResponseModel.facilityCustomAvailability;
    if (facilityAvailability.length > 0) {
      if (facilityAvailability[0].crmBreakStartTimeString != null) {
        this.breakHours.fromTime =
          facilityAvailability[0].crmBreakStartTimeString;
      }
      if (facilityAvailability[0].crmBreakEndTimeString != null) {
        this.breakHours.toTime = facilityAvailability[0].crmBreakEndTimeString;
      }
      if (
        facilityAvailability[0].crmBreakStartTime != null &&
        facilityAvailability[0].crmBreakEndTime != null
      ) {
        this.includeBreakHours = true;
      }
    } else {
      this.includeBreakHours = false;
    }

    facilityAvailability.forEach(result => {
      switch (result.crmFacilityAvailWeekDays) {
        case 1:
          this.DayOnOffMon = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.MonWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.MonWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 2:
          this.DayOnOffTue = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.TueWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.TueWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 4:
          this.DayOnOffWed = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.WedWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.WedWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 8:
          this.DayOnOffThu = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.ThuWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.ThuWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 16:
          this.DayOnOffFri = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.FriWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.FriWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 32:
          this.DayOnOffSat = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.SatWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.SatWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        case 64:
          this.DayOnOffSun = {
            name: result.isActive ? "On" : "Off",
            code: result.isActive ? 1 : 0
          };
          if (
            result.crmFacilityAvailToTimeString &&
            result.crmFacilityAvailFromTimeString
          ) {
            this.SunWorkingHours.fromTime =
              result.crmFacilityAvailFromTimeString.toLocaleLowerCase();
            this.SunWorkingHours.toTime = result.crmFacilityAvailToTimeString.toLocaleLowerCase();
          }
          break;
        default:
          break;
      }
    });
    this.setValidators(this.remoteAssistant);
  }

  private resetValuesToCustom() {
    this.isDaily = false;
    this.isWeekly = false;
    this.isMonthly = false;
    this.isYearly = false;

    this.MonDayOfWeek = false;
    this.TueDayOfWeek = false;
    this.WedDayOfWeek = false;
    this.ThuDayOfWeek = false;
    this.FriDayOfWeek = false;
    this.SatDayOfWeek = false;
    this.SunDayOfWeek = false;

    this.customStartDate = null;
    this.customEndDate = null;
    this.customStartTime = null;
    this.customEndTime = null;

    this.selectedOccurrence = { name: "", code: 0 };
    this.selectedSpecialEvent = { name: "", code: 0 };
    this.splEventError = this.customAvailStartDateError = this.customAvailEndDateError = this.customAvailStartTimeError = this.customAvailEndTimeError = this.selectedOccurrenceError = false;
  }

  private getFacility(facilityId: string) {
    this.loadingCustom = true;
    if (!facilityId) {
      return;
    }

    this.isEditMode = true;
    this.loading = true;

    this.facilityService
      .getFacilityById(Number(facilityId))
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (result: ResultOfT<FacilityResponseModel>) => {
          this.processResult<FacilityResponseModel>(result, () => {
            if (result && result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.facilityResponseModel = result.responseModel;
                this.setValuesToForm();
              }
            }

          });
        },
        httpResponseError => {
          this.showErrorMessage(httpResponseError.message);
        }
      );
    this.loadingCustom = false;
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(
      this.formFields.facility,
      this.formBuilder.control("", {
        validators: [Validators.required, CustomValidator.whitespace],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.bioEditorText,
      this.formBuilder.control("", {
        validators: [Validators.required, Validators.maxLength(2000), CustomValidator.whitespace],
        updateOn: Constants.formControlUpdateStrategy.change
      })
    );
    formGroup.addControl(
      this.formFields.address,
      this.formBuilder.control("", {
        validators: [Validators.required, CustomValidator.whitespace],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.email,
      this.formBuilder.control("", {
        validators: [CustomValidator.email],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.zipCode,
      this.formBuilder.control("", {
        validators: [
          Validators.required,
          CustomValidator.whitespace,
          CustomValidator.onlyNumbers
        ],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.branchName,
      this.formBuilder.control("", {
        validators: [Validators.required],
        updateOn: Constants.formControlUpdateStrategy.change
      })
    );
    formGroup.addControl(
      this.formFields.city,
      this.formBuilder.control("", {
        validators: [
          Validators.required,
          CustomValidator.whitespace,
          CustomValidator.onlyCharacters
        ],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.state,
      this.formBuilder.control("", {
        validators: [
          Validators.required,
        ],
        updateOn: Constants.formControlUpdateStrategy.change
      })
    );
    formGroup.addControl(
      this.formFields.address2,
      this.formBuilder.control("", {
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.cpFName,
      this.formBuilder.control("", {
        validators: [CustomValidator.onlyCharacters],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.cpLName,
      this.formBuilder.control("", {
        validators: [CustomValidator.onlyCharacters],
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.cpPhnNumber,
      this.formBuilder.control("", {
        updateOn: Constants.formControlUpdateStrategy.blur
      })
    );
    formGroup.addControl(
      this.formFields.insuanceInfo,
      this.formBuilder.control(
        "",
        {
          //validators: [Validators.required, CustomValidator.whitespace],
          //updateOn: Constants.formControlUpdateStrategy.blur
        }
      )
    );

    formGroup.addControl(
      this.formFields.timeZone,
      this.formBuilder.control("", {
        validators: [Validators.required],
        updateOn: Constants.formControlUpdateStrategy.change
      })
    );

    this.form = formGroup;
  }

  getStates() {
    this.therapistService
      .GetStates()
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (result: ResultOfT<StateMasterResponseModel[]>) => {
          this.processResult<StateMasterResponseModel[]>(result, () => {
            if (result && result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.stateModel = result.responseModel;
                if (this.facilityId && Number(this.facilityId) > 0) {
                  this.getFacility(this.facilityId);
                }
              }
            }
          });
        },
        httpResponseError => {
          this.showErrorMessage(httpResponseError.message);
        }
      );
  }

  getStateByCode(StateName: string) {
    if (!StateName) {
      return null;
    }
    let foundBranch = this.stateModel.find(x => x.appStateName == StateName);
    this.selectedState = foundBranch;
  }

  CheckTime(day): boolean {
    let commonDate =
      (new Date().getMonth() + 1).toString() +
      "/" +
      new Date().getDate().toString() +
      "/" +
      new Date().getFullYear().toString();
    switch (day) {
      case "M":
        if (
          this.MonWorkingHours.fromTime != null &&
          this.MonWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.MonWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.MonWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else return false;
      case "T":
        if (
          this.TueWorkingHours.fromTime != null &&
          this.TueWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.TueWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.TueWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else return false;
      case "W":
        if (
          this.WedWorkingHours.fromTime != null &&
          this.WedWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.WedWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.WedWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else return false;
      case "Th":
        if (
          this.ThuWorkingHours.fromTime != null &&
          this.ThuWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.ThuWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.ThuWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else return false;
      case "F":
        if (
          this.FriWorkingHours.fromTime != null &&
          this.FriWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.FriWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.FriWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else return false;
      case "S":
        if (
          this.SatWorkingHours.fromTime != null &&
          this.SatWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.SatWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.SatWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      case "Sun":
        if (
          this.SunWorkingHours.fromTime != null &&
          this.SunWorkingHours.toTime
        ) {
          var from = new Date(commonDate + " " + this.SunWorkingHours.fromTime);
          var to = new Date(commonDate + " " + this.SunWorkingHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      case "B":
        if (this.breakHours.fromTime != null && this.breakHours.toTime) {
          var from = new Date(commonDate + " " + this.breakHours.fromTime);
          var to = new Date(commonDate + " " + this.breakHours.toTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      case "C":
        if (this.customStartTime && this.customEndTime) {
          var from = new Date(commonDate + " " + this.customStartTime);
          var to = new Date(commonDate + " " + this.customEndTime);
          if (from < to) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      default:
        break;
    }
  }

  results: any[];
  search(event) {
    let query = event.query;
    this.results = this.filterDoctor(query, this.doctors);
  }

  filterDoctor(query, doctors: DoctorExclusion[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < doctors.length; i++) {
      let doctor = doctors[i];
      if (doctor.label.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(doctor);
      }
    }
    return filtered;
  }

  resultsTherapists: any[];
  searchTherapist(event) {
    let query = event.query;
    this.resultsTherapists = this.filterTherapist(query, this.therapists);
  }

  filterTherapist(query, therapists: DoctorExclusion[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < therapists.length; i++) {
      let therapist = therapists[i];
      if (therapist.label.toLowerCase().includes(query.toLowerCase())) {
        filtered.push(therapist);
      }
    }
    return filtered;
  }

  showDialogCustomeDate(facilityId, timeZone) {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.dialogCustomeDate = true;
    this.displayFacilityName = true;
    this.crmFacilityName = this.fname.nativeElement.value;

    if (timeZone == "" || timeZone == null || timeZone == undefined) {
      timeZone = "UTC-5";
    }
    this.facilityService
      .GetFacilityWorkingHoursById(Number(facilityId), timeZone)
      .pipe(
        takeUntil(this.unsubscriber),
        finalize(() => (this.loading = false))
      )
      .subscribe(
        (result: ResultOfT<FacilityResponseModel>) => {
          this.processResult<FacilityResponseModel>(result, () => {
            if (result && result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                const facilityResponseWorkingHoursModel = result.responseModel;
                if (facilityResponseWorkingHoursModel.facilityAvailability != null && facilityResponseWorkingHoursModel.facilityAvailability != undefined) {
                  this.facilityResponseModel.facilityAvailability = facilityResponseWorkingHoursModel.facilityAvailability
                  this.setValuesToWorkingHoursForm();
                  this.initializeWeekDayList();
                }
              }
            }
          });
        },
        httpResponseError => {
          this.showErrorMessage(httpResponseError.message);
        }
      );
  }

  clickEventFilter() {
    this.clickEventFilterStatus = !this.clickEventFilterStatus;
  }
  hideDialogCustomeDate() {
    this.onCustomCancel();
    this.dialogCustomeDate = false;
    this.displayFacilityName = false;
    this.CustomEvent = false;


  }

  onDeleteCustomAvailability(customAvailability) {
    if (this.multipleCustomAvailability.filter(x => x.crmFacilityCustomAvailabilityId == customAvailability.crmFacilityCustomAvailabilityId).length > 0) {
      this.multipleCustomAvailability = this.multipleCustomAvailability.filter(x => x.crmFacilityCustomAvailabilityId != customAvailability.crmFacilityCustomAvailabilityId);
    }
  }

  onEditCustomAvailability(customAvailability) {
    this.CustomEvent = true;
    this.selectedFacilityCustomAvailabilityId = Number(customAvailability.crmFacilityCustomAvailabilityId);
    this.once = this.isDaily = this.isYearly = this.isMonthly = this.isWeekly = this.MonDayOfWeek = this.TueDayOfWeek = this.WedDayOfWeek = this.ThuDayOfWeek = this.FriDayOfWeek = this.SatDayOfWeek = this.SunDayOfWeek = false;
    if (customAvailability.crmFacilityCustomAvailabilityId > 0) {
      this.customAvailabilityTitle = "Update";
    }
    if (customAvailability.isHoliday) {
      this.selectedSpecialEvent = { name: this.SpecialEvent.filter(x => x.name == 'Holiday')[0].name, code: Number(this.SpecialEvent.filter(x => x.name == 'Holiday')[0].code) }
    }

    if (customAvailability.isShutdown) {
      this.selectedSpecialEvent = { name: this.SpecialEvent.filter(x => x.name == 'Shutdown')[0].name, code: Number(this.SpecialEvent.filter(x => x.name == 'Shutdown')[0].code) }
    }

    if (!customAvailability.isHoliday && !customAvailability.isShutdown) {
      this.selectedSpecialEvent = { name: this.SpecialEvent.filter(x => x.name == 'Working')[0].name, code: Number(this.SpecialEvent.filter(x => x.name == 'Working')[0].code) }
    }

    this.selectedOccurrence = { name: this.Occurrence.filter(x => x.name == customAvailability.crmRecurringFrequency)[0].name, code: Number(this.Occurrence.filter(x => x.name == customAvailability.crmRecurringFrequency)[0].code) }

    customAvailability.crmFacilityCustomDays.split(",").forEach(value => {
      if (value == "1") {
        this.MonDayOfWeek = true;
      }
      if (value == "2") {
        this.TueDayOfWeek = true;
      }
      if (value == "4") {
        this.WedDayOfWeek = true;
      }
      if (value == "8") {
        this.ThuDayOfWeek = true;
      }
      if (value == "16") {
        this.FriDayOfWeek = true;
      }
      if (value == "32") {
        this.SatDayOfWeek = true;
      }
      if (value == "64") {
        this.SunDayOfWeek = true;
      }
    });

    if (customAvailability.crmFacilityCustomAvailStartDate) {
      this.customStartDate = new Date(customAvailability.crmFacilityCustomAvailStartDate);
    }
    if (customAvailability.crmFacilityCustomAvailEndDate) {
      this.customEndDate = new Date(customAvailability.crmFacilityCustomAvailEndDate);
    }


    this.customEndTime = customAvailability.crmFacilityCustomAvailEndTimeString;
    //moment(customAvailability.facilityCustomAvailEndTimeDate.split('T')[1],"hh:mm A").format("HH:mm");
    this.customStartTime = customAvailability.crmFacilityCustomAvailStartTimeString;
    // moment(customAvailability.facilityCustomAvailStartTimeDate.split('T')[1],"hh:mm A").format("HH:mm");

    if (customAvailability.crmRecurringFrequency == 1) {
      this.once = true;
    }
    if (customAvailability.once)
      this.once = true;
    if (customAvailability.crmIsDaily)
      this.isDaily = true;

    if (customAvailability.crmIsWeekly)
      this.isWeekly = true;

    if (customAvailability.crmIsMonthly)
      this.isMonthly = true;

    if (customAvailability.crmIsYearly)
      this.isYearly = true;

  }


  OnWorkingHoursSubmit() {
    const facilityResponseModel = new FacilityResponseModelSend();
    facilityResponseModel.crmTimeZone = this.timeZone;
    facilityResponseModel.facilityAvailability = this.getValuesFromAvailability();
    facilityResponseModel.crmFacilityId = Number(this.facilityId);

    //changes for now as need to check and change the functinality from scratch 
    facilityResponseModel.facilityCustomAvailability = [];

    // this.multipleCustomAvailability.forEach(value => {
    //   let facilityCustomAvailabilitModel = new FacilityCustomAvailabilityResponseModel();
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailabilityId = value.crmFacilityCustomAvailabilityId;
    //   facilityCustomAvailabilitModel.crmIsRecurring = value.crmIsRecurring;
    //   facilityCustomAvailabilitModel.crmFacilityId = value.crmFacilityId;
    //   facilityCustomAvailabilitModel.crmIsWeekly = value.crmIsWeekly == null ? false : value.crmIsWeekly;
    //   facilityCustomAvailabilitModel.crmIsDaily = value.crmIsDaily == null ? false : value.crmIsDaily;
    //   facilityCustomAvailabilitModel.crmIsYearly = value.crmIsYearly == null ? false : value.crmIsYearly;
    //   facilityCustomAvailabilitModel.crmIsMonthly = value.crmIsMonthly == null ? false : value.crmIsMonthly;
    //   facilityCustomAvailabilitModel.crmFacilityCustomDays = value.crmFacilityCustomDays;
    //   facilityCustomAvailabilitModel.crmRecurringFrequency = value.crmRecurringFrequency;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailEndDate = value.crmFacilityCustomAvailEndDate;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailStartDate = value.crmFacilityCustomAvailStartDate;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailEndTime = value.crmFacilityCustomAvailEndTime;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailStartTime = value.crmFacilityCustomAvailStartTime;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailEndTimeDate = value.crmFacilityCustomAvailEndTimeDate;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailStartTimeDate = value.crmFacilityCustomAvailStartTimeDate;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailEndTimeString = value.crmFacilityCustomAvailEndTimeString;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailStartTimeString = value.crmFacilityCustomAvailStartTimeString;
    //   facilityCustomAvailabilitModel.modifiedByUserInfoId = value.modifiedByUserInfoId;
    //   facilityCustomAvailabilitModel.isActive = value.isActive;
    //   facilityCustomAvailabilitModel.dateTimeLastModified = value.dateTimeLastModified;
    //   facilityCustomAvailabilitModel.dateTimeCreated = value.dateTimeCreated;
    //   facilityCustomAvailabilitModel.isShutdown = value.isShutdown;
    //   facilityCustomAvailabilitModel.isHoliday = value.isHoliday;
    //   facilityCustomAvailabilitModel.crmBreakEndTime = value.crmBreakEndTime;
    //   facilityCustomAvailabilitModel.crmBreakStartTime = value.crmBreakStartTime;
    //   facilityCustomAvailabilitModel.crmBreakStartTimeDate = value.crmBreakStartTimeDate;
    //   facilityCustomAvailabilitModel.crmBreakEndTimeDate = value.crmBreakEndTimeDate;
    //   facilityCustomAvailabilitModel.crmBreakEndTimeString = value.crmBreakEndTimeString;
    //   facilityCustomAvailabilitModel.crmBreakStartTimeString = value.crmBreakStartTimeString;
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailStartDateString = value.crmFacilityCustomAvailStartDate.toLocaleDateString();
    //   facilityCustomAvailabilitModel.crmFacilityCustomAvailEndDateString = value.crmFacilityCustomAvailEndDate.toLocaleDateString();
    //   facilityResponseModel.facilityCustomAvailability.push(facilityCustomAvailabilitModel);
    // });
    const manageFacility = this.isEditMode
      ? this.facilityService.UpdateFacilityWorkingHours(facilityResponseModel)
      : this.facilityService.AddFacilityWorkingHours(facilityResponseModel);
    this.loading = true;
    manageFacility.pipe(takeUntil(this.unsubscriber), finalize(() => (this.loading = false))
    )
      .subscribe(
        (result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage(
              this.isEditMode
                ? "Facility is updated successfully"
                : "Facility is created successfully"
            );
            this.hideDialogCustomeDate();
            this.onCancel();
          } else {
            this.showErrorMessage(result.message);
          }
        },
        httpResponseError => {
          this.showErrorMessage(httpResponseError.message);
        }
      );
  }

  onTimeZoneChange(selectedTimeZone) {
    this.selectedTimeZone = selectedTimeZone;
  }

  private getTimeZoneByCode(timeZone: string) {
    if (!timeZone) {
      return null;
    }
    let selectedTimeZone = this.timeZoneList.find(x => x.appTimeZoneOffSet == timeZone);
    return selectedTimeZone;
  }
  onBack() {
    this.router.navigate(['/admin/facilities'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const period = hours < 12 ? 'am' : 'pm';

    // Convert hours to 12hour format
    const hours12 = (hours % 12) || 12;

    // Formating time in to 12-hour format
    const formattedTime = `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
  }
}
