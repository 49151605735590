export class RetrySyncSalesOrderRequestModel {
  crmUpdatedBy: number;
  brightreeInfoIds: number[];
}

export class RetrySyncSalesOrderStatusResposeModel {
  brightreeId: string;
  isSuceess: boolean;
  errorMessage: string;
}
