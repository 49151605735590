import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, NavigationEnd } from "@angular/router";
import { Router } from '@angular/router';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil, filter } from 'rxjs/operators';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { Subject } from 'rxjs';
import { DialogService } from 'primeng/dynamicdialog';
import { ProvisionalSearchRequestModel, SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
declare const Twilio: any;
import * as moment from 'moment';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { RouterExtService } from 'src/app/admin/servicies/routerext.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
//import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';

import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { SalesOrderInBTResponse } from 'src/app/shared/models/response/sales-order-bt-response';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component'
import { Constants } from 'src/app/shared/constants';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { AppointmentEmailHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-emalhistory-response-model';
import { AppointmentSMSHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-smshistory-response-model';
import { RefreshPatientInfoResponseModel } from '../../../shared/models/response/refreshPatientInfo-response-model';
import { AppointmentCallResponseModel } from '../../../shared/models/response/appointment/appointment-call-response-model';
import { NotificationVoicemailResponseModel } from '../../../shared/models/response/notificationVoicemail-response-model';
import { CancelVoidResponseModel, SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { AppointmentHistoryResponseModel } from '../../../shared/models/response/appointment/appointment-history-response-model';

import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { Location } from '@angular/common';
import { ImportexportService } from '../../servicies/importexport.service';
import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };

@Component({
  selector: 'app-sales-order-detail',
  templateUrl: './sales-order-detail.component.html',
  styleUrls: ['./sales-order-detail.component.sass'],
  providers: [DialogService]
})
export class SalesOrderDetailComponent extends BaseComponent implements OnInit {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  salesOrderId: string;
  salesOrder: SalesOrderInfoResponseModel;
  isAppointment: boolean;
  searchRequestModel: SearchRequestModel;
  selectedTab: string;
  selectedfilterIndex: number;
  isBookAppintment: boolean;
  salesOrderStatus: WIPStatesResponseModel[];
  selectedSalesOrderStatus: WIPStatesResponseModel;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  defaultSelectedSalesOrderStatus: WIPStatesResponseModel;
  brightreeInternalUserId: number;
  appointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel[];
  appointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel[];
  salesOrderHistoryResponseModel: AppointmentHistoryResponseModel[];
  loadingEmail: boolean;
  loadingHistory: boolean;
  salesOrderType: string;
  title = 'Appointment Detail';
  notScheduledStatus: { name: string; code: string; }[];
  selectedAppointmentType: string;
  duration: string;
  schIsLocked: boolean = true;
  appDateFormat: DateFormatModel;
  callId: number;
  scheduleAppointment: boolean = false;
  voiceMailAppointment: boolean = false;
  appointmentNextCall: boolean = false;
  isCallStarted: boolean = false;
  connection: any;
  callSID: string;
  device: any;
  callConfigModel: CallConfigModel;
  data1: any;
  event1: any;
  overlaypanel: OverlayPanel;
  crmPatientPhoneNo: string;

  callnote: string;
  calldate: Date;
  calltime: Date;

  schedulecall: boolean;

  previousUrl: string;
  EmailEditStatus: any;
  PhoneEditStatus: any;
  MobileEditStatus: boolean = false;
  patientEmail: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
  loggedInUserId: number;
  //Confirm Date Screen Code

  isDataLoaded: boolean = false;
  redirectFromSearch: boolean = false;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  smsdisplay: boolean;
  emaildisplay: boolean;
  soHistorydisplay: boolean;


  refreshPatientInfoResponseModel: RefreshPatientInfoResponseModel;
  ClassificationTypeEditStatus: any;
  classificationType: SettingsClassificationTypeResponseModel[];
  selectedClassificationType = new SettingsClassificationTypeResponseModel();
  crmClassification: string = "";
  SoHistory: boolean;
  provisionalsearchRequestModel = new ProvisionalSearchRequestModel();

  returnSaleOrderStatus: string = "";
  AppointmentHistoryPopup: boolean;
  isProvisional: boolean;
  displayVoidAppointment: boolean = false;
  displayCancelAppointment: boolean = false;
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];
  formVoidAppointmentSubmitted: boolean = false;
  formCancelSubmitted: boolean = false;
  valueOfEvent: any
  selectedVoidstatus: string = "";
  reasonsResponseModel: any;
  defaultModeofAppointment: string = "";
  isAllowToSendInvite: boolean = false;
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  isStatusCompleted: boolean = false;
  SMSSidebarDisplay: boolean = false;
  EmailSidebarDisplay: boolean = false;
  isScheduleButtonHide: boolean = false;
  updateStatusforminfo:statusInfo;

  smsLogCount:number;
  emailLogCount:number;
  
  constructor(private router: Router,
    private appointmentService: AppointmentService,
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private dialougeService: DialogService,
    private http: HttpClient,
    private routerService: RouterExtService,
    private settingsService: SettingsService,
    private globalSearchService: GlobalSearchService,
    private _location: Location,
    private formBuilder: FormBuilder,
    private importExportService: ImportexportService
  ) {

    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    this.loggedInUserId = loggedInUser.userId;
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.selectedTab = currentNavigationState.selectedTab;
      this.isBookAppintment = currentNavigationState.isBookAppintment;
      this.selectedfilterIndex = currentNavigationState.selectedfilterIndex;
      this.redirectFromSearch = currentNavigationState.redirectFromSearch;
    }
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.appointmentSMSHistoryResponseModel = new Array();
    this.appointmentEmailHistoryResponseModel = new Array();
    this.getClassificationSettings();

    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;

    this.getWipStatusBTmapping();

  }
  async ngOnInit() {
    //await this.getDefaultModeofAppointment();
    this.buildForm();
    this.salesOrderType = 'SalesOrderRequest';
    this.salesOrderId = this.activatedRoute.snapshot.paramMap.get('id');
    this.returnSaleOrderStatus = this.activatedRoute.snapshot.paramMap.get('isback');
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.selectedSalesOrderStatus = new WIPStatesResponseModel();
    await this.getSalesOrderStatus();
    await this.GetRCMConfigurationUrl();
    this.appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    this.appointmentRequestBT.CrmSalesOrderId = Number(this.salesOrderId);
    this.appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    this.getSalesOrderInfo(this.salesOrderId);
    this.webStorageService.deleteRedirectFrom();
    this.setPermissionforActions();
    await this.getCancelVoidReasons();
  }



  EditEamil() {
    this.EmailEditStatus = !this.EmailEditStatus;
  }
  saveEamil() {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

    if (this.patientEmail && !TEXT_REGEXP.test(this.patientEmail)) {
      this.showWarningMessage('Please add proper email address.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Email?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.salesOrder.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.showSuccessMessage('Email updated successfully')
              this.EmailEditStatus = !this.EmailEditStatus;
              this.salesOrder.patientInfo.crmPatientEmailAddress = this.patientEmail;
              this.salesOrder.patientInfo.crmPatientPhoneNo = this.patientPhone;
              this.salesOrder.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
            }
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });

  }

  EditPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;

  }

  savePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    //if (!this.patientPhone) {
    //  this.showWarningMessage('Please add phone number');
    //  return;
    //}
    if (this.patientPhone.length < 10 || !TEXT_REGEXP.test(this.patientPhone)) {
      this.showWarningMessage('Please add valid phone number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Phone Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      if (this.patientPhone.indexOf('-') > -1) {
        this.patientPhone = this.patientPhone.split('-').join('');
      } else {
        this.patientPhone = this.patientPhone;
      }


      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.salesOrder.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.showSuccessMessage('Phone updated successfully')
              this.PhoneEditStatus = !this.PhoneEditStatus;
              this.salesOrder.patientInfo.crmPatientEmailAddress = this.patientEmail;
              this.salesOrder.patientInfo.crmPatientPhoneNo = this.patientPhone;
              this.salesOrder.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
            }
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });


  }

  EditMobilePhone() {
    this.MobileEditStatus = !this.MobileEditStatus;

  }

  saveMobilePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);

    if (this.patientMobileNumber.length < 10 || !TEXT_REGEXP.test(this.patientMobileNumber)) {
      this.showWarningMessage('Please provide valid mobile number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update mobile Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      if (this.patientMobileNumber.indexOf('-') > -1) {
        this.patientMobileNumber = this.patientMobileNumber.split('-').join('');
      } else {
        this.patientMobileNumber = this.patientMobileNumber;
      }
      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.salesOrder.patientInfo.crmPatientId.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.btNoteID = this.salesOrder.btNoteID == null ? 0 : this.salesOrder.btNoteID;
      patientInfoRequestModel.isProvisional = this.isProvisional;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.showSuccessMessage('Mobile Number updated successfully')
              this.MobileEditStatus = !this.MobileEditStatus;
              this.salesOrder.patientInfo.crmPatientEmailAddress = this.patientEmail;
              this.salesOrder.patientInfo.crmPatientPhoneNo = this.patientPhone;
              this.salesOrder.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
            }

          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your mobile Number. Please contact administrator");
            return;
          }
        });
    });

  }
  cancelEmail() {
    this.EmailEditStatus = !this.EmailEditStatus;
    this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
  }
  cancelPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;
    this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
  }
  cancelMobilePhone() {
    if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
      this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
    }
    else {
      this.patientMobileNumber = "";
    }
    this.MobileEditStatus = !this.MobileEditStatus;
  }

  OrderNoteStatus: boolean = false;
  DeliveryNoteStatus: boolean = false;
  displayOrderNote: boolean = false;
  displayDeliveryNote: boolean = false;
  loadingOrderNote: boolean = false;
  loadingDeliveryNote: boolean = false;
  OrderNoteClick() {
    this.displayOrderNote = true;
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }

          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  SaveOrderNoteClick() {
    if (!this.appointmentRequestBT.OrderNote) {
      this.showWarningMessage("Please enter order note.");
      return false;
    }
    this.appointmentRequestBT.IsUpdateToBrightree = false;
    this.appointmentRequestBT.IsDeliveryNoteOverride = true;
    this.appointmentRequestBT.IsOrderNoteOverride = true;
    this.appointmentRequestBT.OrderNote = this.appointmentRequestBT.OrderNote;
    this.appointmentRequestBT.DeliveryNote = null;
    this.appointmentRequestBT.PractitionerName = null;
    this.appointmentRequestBT.PractitionerId = 0;
    this.appointmentRequestBT.DeliveryTechnicianId = 0;
    this.appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    this.appointmentRequestBT.ScheduledDeliveryStartDate = null;
    this.appointmentRequestBT.WIPDateNeededString = null;
    this.appointmentRequestBT.WIPDateNeeded = null;
    this.appointmentRequestBT.WIPStateName = null;
    this.appointmentRequestBT.WIPStateKey = 0;
    this.loadingOrderNote = true;
    this.appointmentService.AddOrderDeliveryNote(this.appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingOrderNote = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.displayOrderNote = false;
          if (result.responseModel != undefined && result.responseModel != null) {
            let resultModel: SalesOrderInBTResponse = result.responseModel;
            if (resultModel.isSucesss) {
              this.salesOrder.crmSalesorderOrderNote = resultModel.orderNote;
              this.appointmentRequestBT.OrderNote = resultModel.orderNote;
              this.showSuccessMessage("Order Note has been saved successfully.");
            }
          }
          else {
            this.showErrorMessage("There was problem in saving Order Note.");
          }
        }
        else {
          this.loadingOrderNote = false;
          this.displayOrderNote = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in adding.");
        }
      });


  }

  CancelOrderNoteClick() {
    this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;
    this.displayOrderNote = false;
  }
  DeliveryNoteClick() {
    this.displayDeliveryNote = true;
  }

  SaveDeliveryNoteClick() {
    if (!this.appointmentRequestBT.DeliveryNote) {
      this.showWarningMessage("Please enter delivery note.");
      return false;
    }
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.CrmSalesOrderId = Number(this.salesOrderId);
    appointmentRequestBT.CrmModifiedBy = this.loggedInUserId;
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = this.appointmentRequestBT.DeliveryNote;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = null;
    appointmentRequestBT.WIPDateNeeded = null;
    appointmentRequestBT.WIPStateName = null;
    appointmentRequestBT.WIPStateKey = 0;
    this.loadingDeliveryNote = true;
    this.appointmentService.AddOrderDeliveryNote(appointmentRequestBT)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingDeliveryNote = false))
      .subscribe((result) => {
        this.displayDeliveryNote = false;
        if (result.isSuccess) {
          this.displayDeliveryNote = false;
          if (result.responseModel != undefined && result.responseModel != null) {
            let resultModel: SalesOrderInBTResponse = result.responseModel;
            if (resultModel.isSucesss) {

              this.salesOrder.crmSalesorderDeliveryNote = resultModel.deliveryNote;
              this.appointmentRequestBT.DeliveryNote = resultModel.deliveryNote;
              this.showSuccessMessage("Delivery Note has been saved successfully.");
            }
            else {
              this.showErrorMessage("There was problem in saving delivery note.");
            }
          }
        }
        else {
          this.loadingDeliveryNote = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in saving delivery note.");
        }
      });
    this.displayDeliveryNote = false;
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
  }

  CancelDeliveryNoteClick() {
    this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
    this.DeliveryNoteStatus = !this.DeliveryNoteStatus;
    this.displayDeliveryNote = false;
  }

  onBack(returnSaleOrderStatus) {
    if (returnSaleOrderStatus == "void") {
      this.router.navigate(['admin/appointments/view-cancelled-appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: this.isBookAppintment, selectedfilterIndex: this.selectedfilterIndex } });
    }
    else if (returnSaleOrderStatus == "pos") {
      this.provisionalsearchRequestModel = new ProvisionalSearchRequestModel();
      this.provisionalsearchRequestModel.patientID = Number(this.salesOrder.crmPatientId);
      this.router.navigate(['admin/appointments/patients-detail'], { state: { provisionalsearchRequestModel: this.provisionalsearchRequestModel } })
    }
    else if (returnSaleOrderStatus == "notification") {
      this._location.back();
    }
    else {
      //this.router.navigate([this.previousUrl], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: this.isBookAppintment, selectedfilterIndex: this.selectedfilterIndex } });
      if (!this.redirectFromSearch)
        this.router.navigate(['admin/appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: this.isBookAppintment, selectedfilterIndex: this.selectedfilterIndex } });
      else
        this.router.navigate(['admin/appointments/search-results'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: this.isBookAppintment, selectedfilterIndex: this.selectedfilterIndex } });

    }

  }
  onStatusChange(event) {

    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    this.valueOfEvent = event.value;
    let status: string = this.valueOfEvent.wipStatesName;
    let statusUpdated: string = this.valueOfEvent.wipStatesName;
    let action = "Void";
    let popupHeader = 'Update Void Status of Appointment(s)';
    if (status.toLowerCase() == 'void') {
      status = 'void';
      statusUpdated = 'voided';
      appointmentUpdateStatusRequestModel.voidFrom = 'P';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }


    }
    else if (status.toLowerCase() == 'void review') {
      status = 'void review';
      statusUpdated = 'voided for review';
      action = "Void review";
      popupHeader = 'Update Void Review Status of Appointment(s)';
      appointmentUpdateStatusRequestModel.voidFrom = 'A';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    if (status.toLowerCase() == 'ready for scheduling') {
      status = 'ready for scheduling';
      statusUpdated = 'ready for scheduling';
    }

    if (status.toLowerCase() == "void" || status.toLowerCase() == "void review") {
      this.displayCancelAppointment = false;
      this.displayVoidAppointment = false;
      this.selectedVoidstatus = status;
      
      this.callUpdateStatusForm(action, popupHeader, status);

      return;
    }

    appointmentUpdateStatusRequestModel.CrmSalesOrderId = Number(this.salesOrderId);
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = this.valueOfEvent.wipStatesId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;


    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (appointmentUpdateStatusRequestModel.voidFrom === 'P') {

      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;




    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to ' + status + ' sales order ?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.selectedSalesOrderStatus = this.defaultSelectedSalesOrderStatus;


        return;
      }

      appointmentUpdateStatusRequestModel.CrmSalesOrderId = Number(this.salesOrderId);
      appointmentUpdateStatusRequestModel.SchAppointmentStatus = this.valueOfEvent.wipStatesId;
      appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;

      let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
      voidAppointmentRequestBT.IsUpdateToBrightree = false;
      voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
      voidAppointmentRequestBT.IsOrderNoteOverride = true;
      voidAppointmentRequestBT.OrderNote = null;
      voidAppointmentRequestBT.DeliveryNote = null;
      voidAppointmentRequestBT.PractitionerName = null;
      voidAppointmentRequestBT.PractitionerId = 0;
      voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
      voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
      voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
      if (appointmentUpdateStatusRequestModel.voidFrom === 'P') {

        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }
      else {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

      appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;

      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'Are you sure you want to ' + status + ' sales order ?',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          this.selectedSalesOrderStatus = this.defaultSelectedSalesOrderStatus;
          return;
        }

        this.loading = true;
        this.appointmentService.UpdateSalesOrderStatus(appointmentUpdateStatusRequestModel)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
          .subscribe((result: ResultOfT<boolean>) => {
            this.processResult<boolean>(result, () => {
              this.showSuccessMessage('Appointment has been ' + statusUpdated + ' successfully.');
              this.defaultSelectedSalesOrderStatus = this.valueOfEvent;
              //this.showSuccessMessage('Status has been updated successfully.');
              this.getSalesOrderInfo(this.salesOrderId)
            });

          }, (httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
          });
      });


    });
  }

  features: featureResponseModel[];
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features != null && this.features != undefined) {
      if (this.features && this.features.length > 0) {

        let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
        if (inPer && inPer.isActive == true)
          this.isSendInvitationPermission = true;

        let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
        if (inPer1 && inPer1.isActive == true) {
          this.isAppointmentScheduledPermission = true;
          this.isAppointmentRescheduledPermission = true;
        }

        let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
        if (inPer2 && inPer2.isActive == true)
          this.isAppointmentCancelPermission = true;

        let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
        if (inPer3 && inPer3.isActive == true)
          this.isAppointmentRescheduledPermission = true;


        let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
        if (inPer4 && inPer4.isActive == true)
          this.isAppointmentMovedPermission = true;

        let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
        if (inPer5 && inPer5.isActive == true)
          this.isAppointmentOverduePermission = true;

        let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
        if (inPer6 && inPer6.isActive == true)
          this.isAppointmentNoshowPermission = true;

        let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
        if (inPer7 && inPer7.isActive == true)
          this.isAppointmentCompletedPermission = true;
      }
    }
  }

  private async getSalesOrderStatus() {
    this.progress.next(true);
    await this.appointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrderStatus = result.responseModel;
              let salesOrderstatusObj = result.responseModel.filter(x => x.wipStatesName.toLowerCase() != 'overdue' && x.wipStatesName.toLowerCase() != 'shipping confirmed');
              this.salesOrderStatus = salesOrderstatusObj;

              if (this.returnSaleOrderStatus == "void") {

                this.salesOrderStatus = this.salesOrderStatus.filter(x => x.wipStatesName.toLowerCase() == 'void');

              }

            }
          }
        });
      }).catch((httpResponseError) => {
        console.error(httpResponseError.message);
        this.showErrorMessage(httpResponseError.message);
      });
  }

  SendInvitationforAppointment() {
    let salesOrderIds: any[];
    salesOrderIds = new Array()
    salesOrderIds.push(this.salesOrderId);

    this.loading = true;
    this.appointmentService.SendInvitationForNotScheduled(salesOrderIds)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Invitation for appointment booking has been sent.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});


  }

  private getSalesOrderInfo(SalesOrderId) {
    this.loading = true;
    this.isDataLoaded = false;
    this.progress.next(true);
    this.appointmentService.GetSalesOrderById(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrder = result.responseModel;
              if (this.salesOrder.crmSalesorderStatus == "Complete" || this.salesOrder.crmSalesorderStatus == "Void") {
                this.isStatusCompleted = true;
                this.isScheduleButtonHide = false;
              }
              else {
                this.isScheduleButtonHide = true;
              }
              if (this.salesOrder.patientInfo.crmPatientEmailAddress) {
                this.patientEmail = this.salesOrder.patientInfo.crmPatientEmailAddress;
              }
              else {
                this.patientEmail = "-";
              }
              if (this.salesOrder.patientInfo.crmPatientPhoneNo) {
                this.patientPhone = this.salesOrder.patientInfo.crmPatientPhoneNo;
              }
              else {
                this.patientPhone = "";
              }
              if (this.salesOrder.patientInfo.crmPatientMobileNumber) {
                this.patientMobileNumber = this.salesOrder.patientInfo.crmPatientMobileNumber;
              }
              else {
                this.patientMobileNumber = "";
              }
              this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
              this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;


              this.schIsLocked = this.salesOrder.schIsLocked;
              this.crmPatientPhoneNo = this.salesOrder.patientInfo.crmPatientPhoneNo;
              let isTelehealth = result.responseModel.crmIsTelehealth;
              //this.crmClassification = this.salesOrder.crmClassification;
              this.duration = this.salesOrder.crmClassificationDuration == 60 || this.salesOrder.crmClassificationDuration == 1 ? '1 Hour' : this.salesOrder.crmClassificationDuration.toString() + ' Minutes';
              this.selectedAppointmentType = isTelehealth ? 'Telehealth' : 'FacilityVisit';
              let objselected = this.salesOrderStatus.filter(x => x.wipStatesName.replace(/\s/g, "") === this.salesOrder.crmSalesorderStatus);
              if (objselected.length > 0) {
                this.selectedSalesOrderStatus = objselected[0];
                this.defaultSelectedSalesOrderStatus = objselected[0];
              }

              if (this.classificationType != null && this.classificationType != undefined) {
                this.selectedClassificationType = this.classificationType.find(x => x.crmClassificationTypeId == this.salesOrder.crmSalesorderClassificationType);
                if (this.selectedClassificationType == undefined || this.selectedClassificationType == null) {
                  var classificationModel = new SettingsClassificationTypeResponseModel();
                  classificationModel.crmClassificationTypeId = this.salesOrder.crmSalesorderClassificationType;
                  classificationModel.crmClassificationType = this.salesOrder.crmSalesorderClassification;
                  classificationModel.crmClassificationDisplayName = this.salesOrder.crmSalesorderClassification;
                  this.selectedClassificationType = classificationModel;
                  this.classificationType.push(classificationModel);
                }
              }

              if (!this.salesOrder.crmBranchIsAllowSelfScheduleAppointments) {
                this.isAllowToSendInvite = false;
              }
              else if (this.salesOrder.crmSalesorderStatus.toLowerCase() != 'complete' && this.salesOrder.crmSalesorderStatus.toLowerCase() != 'void') {
                this.isAllowToSendInvite = true;
              }

              this.appointmentRequestBT.OrderNote = this.salesOrder.crmSalesorderOrderNote;
              this.appointmentRequestBT.DeliveryNote = this.salesOrder.crmSalesorderDeliveryNote;
              this.loading = false;
              this.isDataLoaded = true;
            }
          }

        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.isDataLoaded = true;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private GetNotificationSMSLogsBySalesOrderId(SalesOrderId) {

    this.loadingEmail = true;
    //this.smsdisplay = true;
    this.appointmentService.GetNotificationSMSLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingEmail = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentSMSHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentSMSHistoryResponseModel = result.responseModel;
              this.smsLogCount = this.appointmentSMSHistoryResponseModel.filter(x => !x.isSMSSubscribe).length;
              if (this.appointmentSMSHistoryResponseModel.length > 0)
                //this.smsdisplay = true;
                this.SMSSidebarDisplay = true;
              else {
                //this.smsdisplay = false;
                this.SMSSidebarDisplay = false;
                this.showInfoMessage("No record found");
              }
              this.loadingEmail = false;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.loadingEmail = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private GetNotificationEmailLogsBySalesOrderId(SalesOrderId) {
    // this.emaildisplay = true;
    this.loadingEmail = true;

    this.appointmentService.GetNotificationEmailLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingEmail = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentEmailHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentEmailHistoryResponseModel = result.responseModel;
              this.emailLogCount=this.appointmentEmailHistoryResponseModel.filter(x=>!x.isEmailSubscribe).length;
              if (this.appointmentEmailHistoryResponseModel.length > 0)
                //this.emaildisplay = true;
                this.EmailSidebarDisplay = true;
              else {
                //this.emaildisplay = false;
                this.EmailSidebarDisplay = false;
                this.showInfoMessage("No record found");
              }

              this.loadingEmail = false;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.loading = false;

        this.showErrorMessage(httpResponseError.message);
      });
  }

  RefreshPatientInfo() {

    //this.isDataLoaded = false;
    //this.progress.next(true);
    // this.appointmentService.RefreshPatientInfo(parseInt(this.salesOrderId))
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    //   .toPromise()
    //   .then((result) => {
    //     this.processResult<RefreshPatientInfoResponseModel>(result, () => {
    //       if (result.isSuccess) {
    //         if (result.responseModel != undefined && result.responseModel != null) {
    //           this.refreshPatientInfoResponseModel = result.responseModel;

    //           if (this.refreshPatientInfoResponseModel.crmPatientEmailAddress) {
    //             this.patientEmail = this.refreshPatientInfoResponseModel.crmPatientEmailAddress;
    //           }
    //           else {
    //             this.patientEmail = "-";
    //           }
    //           if (this.refreshPatientInfoResponseModel.crmPatientPhoneNo) {
    //             this.patientPhone = this.refreshPatientInfoResponseModel.crmPatientPhoneNo;
    //           }
    //           else {
    //             this.patientPhone = "";
    //           }
    //           if (this.refreshPatientInfoResponseModel.crmPatientMobileNumber) {
    //             this.patientMobileNumber = this.refreshPatientInfoResponseModel.crmPatientMobileNumber;
    //           }
    //           else {
    //             this.patientMobileNumber = "";
    //           }

    //           if (this.refreshPatientInfoResponseModel.crmPatientFirstName) {
    //             this.salesOrder.patientInfo.crmPatientFirstName = this.refreshPatientInfoResponseModel.crmPatientFirstName;
    //           }
    //           if (this.refreshPatientInfoResponseModel.crmPatientLastName) {
    //             this.salesOrder.patientInfo.crmPatientLastName = this.refreshPatientInfoResponseModel.crmPatientLastName;
    //           }





    //           this.appointmentRequestBT.OrderNote = this.refreshPatientInfoResponseModel.crmSalesorderOrderNote;
    //           this.appointmentRequestBT.DeliveryNote = this.refreshPatientInfoResponseModel.crmSalesorderDeliveryNote;

    //           this.loading = false;
    //           //this.isDataLoaded = true;
    //         }
    //       }
    //     });
    //   }).catch((httpResponseError) => {
    //     this.loading = false;
    //     //this.isDataLoaded = true;
    //     this.showErrorMessage(httpResponseError.message);
    //   });

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update the latest information from Brightree? ',
        message1: 'If you click on "Yes" button, then all the information will be updated as per the Brightree database.',
      },
      header: 'Sync Details from Brightree'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.loading = true;
      this.importExportService.RefreshSalesOrderById(this.salesOrder.crmSalesorderAppId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<RefreshPatientInfoResponseModel>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.refreshPatientInfoResponseModel = result.responseModel;

                if (this.refreshPatientInfoResponseModel.isSuccess) {
                  if (this.refreshPatientInfoResponseModel.crmPatientEmailAddress) {
                    this.patientEmail = this.refreshPatientInfoResponseModel.crmPatientEmailAddress;
                  }
                  else {
                    this.patientEmail = "-";
                  }
                  if (this.refreshPatientInfoResponseModel.crmPatientPhoneNo) {
                    this.patientPhone = this.refreshPatientInfoResponseModel.crmPatientPhoneNo;
                  }
                  else {
                    this.patientPhone = "";
                  }
                  if (this.refreshPatientInfoResponseModel.crmPatientMobileNumber) {
                    this.patientMobileNumber = this.refreshPatientInfoResponseModel.crmPatientMobileNumber;
                  }
                  else {
                    this.patientMobileNumber = "";
                  }

                  if (this.refreshPatientInfoResponseModel.crmPatientFirstName) {
                    this.salesOrder.patientInfo.crmPatientFirstName = this.refreshPatientInfoResponseModel.crmPatientFirstName;
                  }
                  if (this.refreshPatientInfoResponseModel.crmPatientLastName) {
                    this.salesOrder.patientInfo.crmPatientLastName = this.refreshPatientInfoResponseModel.crmPatientLastName;
                  }
                  this.appointmentRequestBT.OrderNote = this.refreshPatientInfoResponseModel.crmSalesorderOrderNote;
                  this.appointmentRequestBT.DeliveryNote = this.refreshPatientInfoResponseModel.crmSalesorderDeliveryNote;
                  this.getSalesOrderInfo(this.salesOrderId);
                }
                else {
                  let status = this.refreshPatientInfoResponseModel.status;
                  if (status && status == "Already Exist") {
                    this.showWarningMessage("Sales order already exist");
                  }
                  if (status && status == "Error") {
                    this.showErrorMessage("Error occurred while syncing details from Brightree.");
                  }
                  if (status && status == "Not Found") {
                    this.showWarningMessage("Sales order not found in Brightree");
                  }

                }
                this.loading = false;
              }
              else
                this.loading = false;
            }
            else
              this.loading = false;
          });
        }).catch((httpResponseError) => {
          this.loading = false;
          //this.isDataLoaded = true;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  RefreshCallLogs() {
    //this.loading = true;
    //this.isDataLoaded = false;
    //this.progress.next(true);
    this.appointmentService.RefreshCallLogs(parseInt(this.salesOrderId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentCallResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrder.calllogs = result.responseModel;

              //this.loading = false;
              //this.isDataLoaded = true;
            }
          }
        });
      }).catch((httpResponseError) => {
        //this.loading = false;
        //this.isDataLoaded = true;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  RefreshVoiceMailDetails() {
    //this.loading = true;
    //this.isDataLoaded = false;
    //this.progress.next(true);
    this.appointmentService.RefreshVoiceMailDetails(parseInt(this.salesOrderId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<NotificationVoicemailResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrder.notificationVoiceMailInfo = result.responseModel;

              //this.loading = false;
              //this.isDataLoaded = true;
            }
          }
        });
      }).catch((httpResponseError) => {
        //this.loading = false;
        //this.isDataLoaded = true;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  expandedIndex: number = -1;
  Collapse(index: number) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  showScheduleAppointment() {

    //if (this.salesOrder.crmIsEnableAppointmentMaxLimit) {
    //  if (this.salesOrder.crmClassificationTypeBookedAppointmentCount >= this.salesOrder.crmMaxNoOfAppointment) {

    //    const refcheck = this.dialougeService.open(InformationDialogComponent, {
    //      data:
    //      {
    //        message: 'This classification type has reached the maximum number of appointments per day. If you want to increase the limit for the appointment per day. Please go to the Settings => Brightree => Classification Settings.',
    //      },
    //      header: 'Information'
    //    });

    //    refcheck.onClose.subscribe((response: boolean) => {
    //      if (response) {
    //        return;
    //      }
    //    });
    //    return;
    //  }
    //}
    if (!this.salesOrder.crmIsRemoteVisitSelected && !this.salesOrder.crmIsFacilityVisitSelected && !this.salesOrder.crmIsRoadHomeVisitSelected) {
      this.showWarningMessage("Doctor is not allowed to Schedule/Reschedule appointment. Please enable Doctor availability from Resource->Doctors section.");
      return;
    }

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: false,
      closable: false,
      styleClass: 'book-appt-popup-outer',

      data: {
        crmSalesOrderId: this.salesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: false,
        isCancelOrder: false,
        isAppointmentSalesOrderDetailPage: true,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },

    });

    ref.onClose.subscribe((crmIsAppoinment: boolean) => {
      this.getSalesOrderInfo(this.salesOrderId);

      if (crmIsAppoinment)
        this.router.navigate(['admin/appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.selectedTab, isBookAppintment: this.isBookAppintment, selectedfilterIndex: this.selectedfilterIndex } });
      //this.HangUpCall();

    });

  }

  getPluginConfiguration() {
    this.loading = true;
    this.settingsService.getPluginConfiguration(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              let jsonData = JSON.parse(result.responseModel.appPluginSettingsValue);
              this.patientBrighttreeURL = jsonData.PatientBrighttreeURL;
              this.salesOrderBrighttreeURL = jsonData.SalesOrderBrighttreeURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {
        console.error(httpResponseError.message);
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: string) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", Number(crmSalesOrderAPPId));
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: string) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", Number(crmPatientId));
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }


  smsDialog() {
    this.GetNotificationSMSLogsBySalesOrderId(this.salesOrderId);

  }
  emailDialog() {
    this.GetNotificationEmailLogsBySalesOrderId(this.salesOrderId);
  }

  SMSSidebar() {
    //this.SMSSidebarDisplay = true;
    this.GetNotificationSMSLogsBySalesOrderId(this.salesOrderId);
  }

  EmailSidebar() {
    //this.EmailSidebarDisplay = true;
    this.GetNotificationEmailLogsBySalesOrderId(this.salesOrderId);
  }

  EditClassificationType() {

    this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
    console.log(this.selectedClassificationType);
  }
  cancelClassificationType() {
    this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
    if (this.classificationType != null && this.classificationType != undefined) {
      this.selectedClassificationType = this.classificationType.find(x => x.crmClassificationTypeId == this.salesOrder.crmSalesorderClassificationType);
    }
  }

  saveClassificationType() {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Classification?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      let settingsClassificationTypeResponseModel = new SettingsClassificationTypeResponseModel();
      settingsClassificationTypeResponseModel = this.selectedClassificationType

      this.appointmentService.updateClassificationType(this.salesOrderId, settingsClassificationTypeResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<number>) => {
          this.processResult<number>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel != undefined && result.responseModel != null) {
                this.showSuccessMessage('Classification type has been changed successfully.');
                this.ClassificationTypeEditStatus = !this.ClassificationTypeEditStatus;
                this.salesOrder.crmSalesorderClassificationType = result.responseModel;
              }
            }
          });

        }, (httpResponseError) => {

          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    });

    //this.crmClassification = this.salesOrder.crmClassification;
    //this.crmClassification = this.selectedClassificationType[0].crmClassificationDisplayName;
  }
  private getClassificationSettings() {
    this.loading = true;
    this.settingsService.GetClassificationTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.classificationType = result.responseModel.filter(x => x.crmClassificationDisplayName !== null && x.crmClassificationType != null);
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onClassificationTypeChange(event) {
    this.selectedClassificationType = event.value;
    this.crmClassification = this.selectedClassificationType.crmClassificationDisplayName;
    //this.salesOrder.crmSalesorderClassificationType = this.selectedClassificationType.crmSalesorderClassificationType;
  }


  // HistoryDialog() {
  //   this.soHistorydisplay = true;
  // }

  HistoryEvent() {
    this.SoHistory = !this.SoHistory;
    this.soHistorydisplay = true;
  }
  HistoryEventClose() {
    this.SoHistory = !this.SoHistory;
    this.soHistorydisplay = false;
  }

  saleOrderExportHistory(saleorderId: string) {
    const formData = new FormData();
    formData.append('saleorderId', saleorderId.toString());
    this.loading = true;
    this.appointmentService.ExportSaleOrderHistroty(formData)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'SaleOrderHistory.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }


  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }


  cancelVoidAppointmentUpdate(status: string) {
    let displaystatus;
    if (status == "void review" || status == "void") {
      displaystatus = "voided";
      this.formVoidAppointmentSubmitted = true;
      /* if (this.formVoidAppointment.invalid) {
        this.markFormAsTouched();
        return;
      }
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false; */
    }
    else if (status == "cancel") {
      displaystatus = "canceled";
      this.formCancelSubmitted = true;
      if (this.formCancel.invalid) {
        this.markFormAsTouched();
        return;
      }
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false;
    }

    let cancelRequestModel = status == "void review" || status == "void" ? this.getValuesFromUpdateStautsForm() : this.getValuesFromCancelForm();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();

    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    appointmentUpdateStatusRequestModel.CrmSalesOrderId = Number(this.salesOrderId);
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = this.valueOfEvent.wipStatesId;
    appointmentUpdateStatusRequestModel.schCancelReason = cancelRequestModel.schCancelReason;
    appointmentUpdateStatusRequestModel.SchComments = cancelRequestModel.schComments;
    appointmentUpdateStatusRequestModel.isProvisional = this.isProvisional;

    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (status == "void") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;

      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;

    this.loading = true;
    this.appointmentService.UpdateSalesOrderStatus(appointmentUpdateStatusRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            this.showSuccessMessage('Appointment has been ' + this.valueOfEvent.wipStatesName + ' successfully.');
            this.defaultSelectedSalesOrderStatus = this.valueOfEvent;
            if (status == "cancel") {
              const formcan = this.formCancel;
              formcan.get(this.formFields.reason).setValue('');
              formcan.get(this.formFields.reasonComments).setValue('');
              formcan.reset();
            }
            else {
              const formvoid = this.formVoidAppointment;
              formvoid.get(this.formFields.voidReason).setValue('');
              formvoid.get(this.formFields.voidReasonComments).setValue('');
              formvoid.reset();
            }
            this.displayCancelAppointment = false;
            this.displayVoidAppointment = false;
            this.getSalesOrderInfo(this.salesOrderId)
          }
          else {
            this.loading = false

            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem in cancelling your appointment.");
          }
        });


      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formCancel: formGroup,
      formVoidAppointment: formVoidGroup
    });
  }

  get fCancel() { return (<FormGroup>this.form.get('formCancel')).controls; }

  get formCancel() {
    if (this.form != undefined) {
      return this.form.get('formCancel');
    }
  }
  hideAppointmentpopup() {
    const form = this.formCancel;
    form.get(this.formFields.reason).setValue('');
    form.get(this.formFields.reasonComments).setValue('');
    form.reset();
    this.displayCancelAppointment = false;
    this.selectedSalesOrderStatus = this.defaultSelectedSalesOrderStatus;
  }

  validationMessages = {
    reason: {
      required: 'Cancel reason is required',
    },
    reasonComments: {
      required: 'Comment is required',
    },
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },
  };
  formFields = {
    reason: 'reason',
    reasonComments: 'reasonComments',
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };
  get fVoid() {

    return (<FormGroup>this.form.get('formVoidAppointment')).controls;
  }

  get formVoidAppointment() {
    if (this.form != undefined) {
      return this.form.get('formVoidAppointment');
    } return;


  }
  cancelVoidAppointment() {

    const form = this.formVoidAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.selectedSalesOrderStatus = this.defaultSelectedSalesOrderStatus;
    this.displayVoidAppointment = false;

  }

  private getValuesFromUpdateStautsForm(): AppointmentCancelRequest {    
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.schCancelReason = this.updateStatusforminfo?.reason;
    cancelRequestModel.schComments = this.updateStatusforminfo?.reasonComment;
    return cancelRequestModel;
  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const cancelRequestModel = new AppointmentCancelRequest();
    const form = this.formVoidAppointment;
    if (form != undefined && form != null) {
      var reason = form.get(this.formFields.voidReason).value;
      cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
      cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    }
    return cancelRequestModel;
  }

  private getValuesFromCancelForm(): AppointmentCancelRequest {
    const form = this.formCancel;
    const cancelRequestModel = new AppointmentCancelRequest();
    if (form != undefined && form != null) {
      var reason = form.get(this.formFields.reason).value;
      cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
      cancelRequestModel.schComments = form.get(this.formFields.reasonComments).value;
    }
    return cancelRequestModel;
  }

  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.responseModel != undefined && result.responseModel != null) {
            this.reasonsResponseModel = result.responseModel;
            const myClonedArray = [];
            this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
            this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
            this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Cancel');
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  HistoryPopup() {
    this.AppointmentHistoryPopup = true;
    this.SoHistory = !this.SoHistory;
    this.soHistorydisplay = true;
  }

  callUpdateStatusForm(action:string, header:string, status:string) {

    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data:{
        action: action,
        popupHeader: header,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: action == 'Cancel' ? this.cancellationReasonsResponseModel : this.voidReasonsResponseModel,
        isMultiSelected: 'False', 
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {

      if (action == 'Cancel') {
        if (response.action == 'Confirm') {
          this.updateStatusforminfo  = response;          
          this.cancelVoidAppointmentUpdate(status.toLowerCase());

        }
        if (response.action == 'Cancel') {          
          this.hideAppointmentpopup();          
        }
      }
      else { //void/void review
        if (response.action == 'Confirm') {   
          this.updateStatusforminfo  = response;       
          this.cancelVoidAppointmentUpdate(status.toLowerCase());
        }
        if (response.action == 'Cancel') {          
          this.cancelVoidAppointment();
        }
      }
    });
    /** End Code */
  }


}
