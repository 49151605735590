<div class="content-area">

  <div class="title-block">
    <div class="title-block__left">
      <h1 *ngIf="!isVoiceCallReport && !isMissingEmailPhoneNumberReport">{{title}} Appointments</h1>
      <h1 *ngIf="isVoiceCallReport">Call Recording Logs</h1>
      <h1 *ngIf="isMissingEmailPhoneNumberReport">Missing Email/Phone/Mobile Appointments</h1>
      <!--<div *ngFor="let filter of selectedFilters">-->
      <!-- <p *ngIf="filter.filterType.includes('Date')">
          {{ filter.filterName | date:appDateFormat.dateFormat}}
        </p>
        <p *ngIf="!filter.filterType.includes('Date')">
          {{ filter.filterName}}
        </p> -->
      <!--{{ filter.filterName}}
      </div>-->

    </div>
    <div class="title-block__right">
      <!-- <button class="btn btn--primary" (click)="OnExport()">Export</button> -->
      <a href="javascript:void(0);" class="filter-show-hide" (click)="exportData.toggle($event)" pTooltip="Actions"
        tooltipPosition="top">
        <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>
      <p-overlayPanel appendTo="body" [dismissable]="true" #exportData class="search-filter report-exportData">
        <div class="quick-link-menu">
          <ul>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="OnExport()">Export</a></li>
          </ul>
        </div>
      </p-overlayPanel>

      <a href="javascript:void(0);" pTooltip="Filters" tooltipPosition="right" class="filter-block filter-show-hide"
        (click)="overlyFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>


      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <ul class="report-filters-list" >
    <li class="report-filters-list__item" *ngFor="let filter of selectedFilters">
      <a class="report-filters-list__clear" href="javascript:void(0);" (click)="OnClear(filter.filterType)"
        *ngIf="filter.filterType == 'ClearButton'">
        {{ filter.filterName}}
      </a>
      <!-- <span
        *ngIf="(filter.filterType == 'CustomDateFilter' || filter.filterType == 'CustomDateApptFilter' ||
        filter.filterType == 'CustomDate2Filter') && filter.filterType != 'ClearButton'">
        {{ filter.filterName | date:appDateFormat.dateFormat}}
      </span> -->
      <span *ngIf="filter.filterType != 'ClearButton'">
        {{ filter.filterName}}
      </span>
      <a href="javascript:void(0);" class="report-filters-list__link" (click)="OnClear(filter.filterType)">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#3E3E3E" stroke-width="2" />
          <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#3E3E3E" stroke-width="2" />
        </svg>
      </a>
    </li>
  </ul>

  <!-- <div class="table-filter reports-filter"> -->
   
  <div class="appointments-scroll reports-detail" [ngClass]="selectedFilters?.length == 0 ? 'remove-filter':'show-filter'">
    <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
    <div class="table-outer-main" *ngIf="!isVoiceCallReport">
      <p-table #sdl class="table-outer" [value]="reportSummaryResponseModel" responsiveLayout="scroll"
        [paginator]="totalRecords>0?true:false" sortMode="single" sortField="CrmPatientName"
        [first]="searchRequest.firstRecordIndex" [sortOrder]="1" [rows]="getTotalRows()" [totalRecords]="totalRecords"
        [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr *ngIf="totalRecords > 0">
            <th class="table-outer__th soid" width="70">
              S.O. ID
            </th>
            <th class="table-outer__th Patientid" pSortableColumn="CrmPatientName">
              Patient<p-sortIcon field="CrmPatientName"></p-sortIcon>
            </th>
            <th class="table-outer__th reportstatus" pSortableColumn="SchAppointmentStatus">
              Status<p-sortIcon field="SchAppointmentStatus"></p-sortIcon>
            </th>
            <th class="table-outer__th"
              *ngIf="((currentSelectedUserBranch == 0) || (selectedbranches?.length > 1)) && (title != 'Booked')"
              pSortableColumn="CrmBranch">
              Branch <p-sortIcon field="CrmBranch"></p-sortIcon>
            </th>
            <th class="table-outer__th"
              *ngIf="selectedAppointmentStatus?.length > 1 || title == 'Booked' || title =='Reconciled'"
              pSortableColumn="SchAppointmentType">
              Appointment Type <p-sortIcon field="SchAppointmentType"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Completed' || title == 'EmailPhoneMissingAppointments'"
              pSortableColumn="SOCreatedDate">
              S.O. Entry Date <p-sortIcon field="SOCreatedDate"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Scheduled' || title == 'Completed' || title == 'Cancelled' || title == 'No Show' 
            || title == 'Void' || title == 'Unreconciled' || title == 'Booked' || title == 'Rescheduled'"
              pSortableColumn="SchAppointmentBookedDateTime">
              Appt. Booked Date & Time <p-sortIcon field="SchAppointmentBookedDateTime"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title =='Reconciled'" pSortableColumn="ReconciledDateTime">
              Reconcile Date & Time <p-sortIcon field="ReconciledDateTime">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Completed'"
                pSortableColumn="schAppointmentCallByRole">
              Schedular Role <p-sortIcon field="schAppointmentCallByRole"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Scheduled'|| title == 'Unreconciled' || title == 'Rescheduled' || title == 'Completed'"
                pSortableColumn="SchAppointmentBookedByPerson">
              Schedular Name <p-sortIcon field="SchAppointmentBookedByPerson"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="(title != 'Awaiting' && title != 'EmailPhoneMissingAppointments')"
              pSortableColumn="SchAppointmentDateTime">
              Appt. Date & Time <p-sortIcon field="SchAppointmentDateTime">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Void'" pSortableColumn="DateTimeLastModified">
              Void Date<p-sortIcon field="dateTimeLastModified"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Void'" pSortableColumn="crmVoidedBy">
              Void Done By<p-sortIcon field="crmVoidedBy"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Void'" pSortableColumn="schReason">
              Reason<p-sortIcon field="schReason"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Scheduled' || title == 'Unreconciled'"
              pSortableColumn="CrmLagTime">
              Appt. Booking Lag
              Time <p-sortIcon field="CrmLagTime"></p-sortIcon>
            </th>

            <th class="table-outer__th" pSortableColumn="CrmClassification">
              Classification Type <p-sortIcon field="CrmClassification">
              </p-sortIcon>
            </th>

            <th class="table-outer__th email-column" *ngIf="title == 'EmailPhoneMissingAppointments'"
              pSortableColumn="CrmPatientEmail">
              Email <p-sortIcon field="CrmPatientEmail">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'EmailPhoneMissingAppointments'"
              pSortableColumn="CrmPatientPhoneNumber">
              Phone Number <p-sortIcon field="CrmPatientPhoneNumber">
              </p-sortIcon>
            </th>

            <th class="table-outer__th" *ngIf="title == 'EmailPhoneMissingAppointments'"
              pSortableColumn="CrmPatientMobileNumber">
              Mobile Number <p-sortIcon field="CrmPatientMobileNumber">
              </p-sortIcon>
            </th>

            <th class="table-outer__th" *ngIf="title == 'Completed'" pSortableColumn="DateTimeCompleteAppointmentDate">
              Completed Date
              <p-sortIcon field="dateTimeCompleteAppointmentDate"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Cancelled'" pSortableColumn="DateTimeCompleteAppointmentDate">
              Cancelled Date
              <p-sortIcon field="dateTimeCompleteAppointmentDate"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'No Show'" pSortableColumn="DateTimeCompleteAppointmentDate">
              No Show Date
              <p-sortIcon field="dateTimeCompleteAppointmentDate"></p-sortIcon>
            </th>

            <th class="table-outer__th" *ngIf="title == 'Cancelled'" pSortableColumn="crmVoidedBy">
              Cancel Done By<p-sortIcon field="crmVoidedBy"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Cancelled'" pSortableColumn="schReason">
              Reason<p-sortIcon field="schReason"></p-sortIcon>
            </th>

            <th class="table-outer__th"
              *ngIf="title != 'Awaiting' && title != 'Void' && title != 'Booked' && title !='Reconciled' && title != 'EmailPhoneMissingAppointments'"
              pSortableColumn="AppTherapistName">
              Therapist <p-sortIcon field="AppTherapistName"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Awaiting' || title == 'Void'" pSortableColumn="CrmCallCount">
              # of Call(s)<p-sortIcon field="CrmCallCount"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Rescheduled'"
              pSortableColumn="DateTimePreviousAppointmentDate">
              Previous Appointment
              Date <p-sortIcon field="DateTimePreviousAppointmentDate"></p-sortIcon>
            </th>
            <!-- <th class="table-outer__th" *ngIf="title == 'Cancelled'" pSortableColumn="DateTimeCancellationDate" >Cancellation Date <p-sortIcon field="DateTimeCancellationDate"></p-sortIcon></th> -->
            <th class="table-outer__th" *ngIf="title == 'Voided'" pSortableColumn="DateTimeCancellationDate">
              Cancellation Date <p-sortIcon field="DateTimeCancellationDate"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Booked'" pSortableColumn="SchedulerName">
              Scheduler Name<p-sortIcon field="SchedulerName"></p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title == 'Booked'" pSortableColumn="BookingType">
              Booking Type<p-sortIcon field="BookingType">
              </p-sortIcon>
            </th>

            <th class="table-outer__th" *ngIf="title != 'Booked' && title !='Reconciled'" pSortableColumn="AmountDue">
              Amount Due <p-sortIcon field="AmountDue">
              </p-sortIcon>
            </th>
            <!--<th class="table-outer__th" *ngIf="title !='Reconciled'" pSortableColumn="Comments">
  Notes <p-sortIcon field="Comments">
  </p-sortIcon>-->
            <th class="table-outer__th"
              *ngIf="title !='Reconciled' && title != 'Reschedule' && title != 'EmailPhoneMissingAppointments'"
              pSortableColumn="DateCreated">
              Date Created <p-sortIcon field="DateCreated">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title !='Reconciled'" pSortableColumn="WipState">
              Wip State <p-sortIcon field="WipState">
              </p-sortIcon>
            <th class="table-outer__th"
              *ngIf="title != 'Void' &&  title != 'Awaiting' && title !='Reconciled' && title != 'EmailPhoneMissingAppointments'"
              pSortableColumn="DefaultClinic">
              Default Clinic
              <p-sortIcon field="DefaultClinic">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title !='Reconciled'" pSortableColumn="crmPolicyHolderName">
              Primary Insurance<p-sortIcon field="crmPolicyHolderName">
              </p-sortIcon>
            </th>
            <th class="table-outer__th" *ngIf="title =='Reconciled'" pSortableColumn="schIsSystemGenerated">
              Reconcile Type<p-sortIcon field="schIsSystemGenerated">
              </p-sortIcon>
            </th>

            <th class="table-outer__th" pSortableColumn="crmFacilityTimeZone">
              Time Zone<p-sortIcon field="crmFacilityTimeZone">
              </p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <!-- <ng-template pTemplate="body" let-ScheduledTable> -->
        <ng-template pTemplate="body" let-Report>
          <tr>
            <td class="table-outer__td soid">
              <span class="table-checkbox">
                <!-- <p-tableCheckbox [value]="ScheduledTable"></p-tableCheckbox>&nbsp; -->
                {{Report.crmSalesOrderAPPId}}
              </span>
            </td>
            <td class="table-outer__td Patientid">
              <span class="patient">

                {{ Report.crmPatientName}}
                <p>{{Report.crmPatientId}}</p>
              </span>
            </td>
            <td class="table-outer__td reportstatus">{{Report.schAppointmentStatus}}</td>
            <td class="table-outer__td"
              *ngIf="((currentSelectedUserBranch == 0) || (selectedbranches?.length > 1)) && (title != 'Booked')">
              {{Report.crmBranch}}
            </td>
            <td class="table-outer__td"
              *ngIf="selectedAppointmentStatus?.length > 1 || title == 'Booked' || title =='Reconciled'">
              {{Report.schAppointmentType}}
            </td>
            <td class="table-outer__td" *ngIf="title == 'Completed' || title == 'EmailPhoneMissingAppointments'">
              <span class="date-time">
                {{Report.soCreatedDate | date:appDateFormat.dateFormat}}
                <p>{{Report.soCreatedDate | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td"
              *ngIf="title == 'Scheduled' || title == 'Completed' || title == 'Cancelled' || title == 'No Show' || title == 'Void' || title == 'Unreconciled' || title == 'Booked'|| title == 'Rescheduled'">
              <span class="date-time" *ngIf="Report.schAppointmentId >0">
                {{Report.schAppointmentBookedDateTime | date:appDateFormat.dateFormat}}
                <p>{{Report.schAppointmentBookedDateTime | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td" *ngIf="title == 'Reconciled'">
              <span class="date-time">
                {{Report.reconciledDateTime | date:appDateFormat.dateFormat}}
                <p>{{Report.reconciledDateTime | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td"
              *ngIf="title == 'Completed'">
              {{Report.schAppointmentCallByRole}} </td>

            <td class="table-outer__td"
              *ngIf="title == 'Scheduled' || title == 'Unreconciled' || title == 'Rescheduled' || title == 'Completed'">
              {{Report.schAppointmentBookedByPerson}} </td>
            <td class="table-outer__td" *ngIf="(title != 'Awaiting' && title != 'EmailPhoneMissingAppointments')">
              <span class="date-time" *ngIf="Report.schAppointmentId >0">
                {{Report.schAppointmentDateTime | date:appDateFormat.dateFormat}}
                <p>{{Report.schAppointmentDateTime | date:'shortTime' | lowercase }}</p>
              </span>

            </td>

            <td class="table-outer__td" *ngIf="title == 'Void'">{{Report.dateTimeLastModified |
              date:appDateFormat.dateFormat}}</td>
            <td class="table-outer__td" *ngIf="title == 'Void'">{{Report.crmVoidedBy}}</td>
            <td class="table-outer__td" *ngIf="title == 'Void'" pTooltip="{{Report.schReason}}">{{Report.schReason}}
            </td>

            <td class="table-outer__td" *ngIf="title == 'Scheduled' || title == 'Unreconciled'">{{Report.crmLagTime}}
              Day(s) </td>

            <td class="table-outer__td">{{Report.crmClassification}}</td>
            <td class="table-outer__td email-column" *ngIf="title == 'EmailPhoneMissingAppointments'"><span
                class="text-ellipsis" pTooltip="{{Report.crmPatientEmail}}">{{Report.crmPatientEmail}}</span></td>
            <td class="table-outer__td" *ngIf="title == 'EmailPhoneMissingAppointments'">
              {{Report.crmPatientPhoneNumber}}</td>
            <td class="table-outer__td" *ngIf="title == 'EmailPhoneMissingAppointments'">
              {{Report.crmPatientMobileNumber}}</td>

            <td class="table-outer__td" *ngIf="title == 'Completed' || title == 'Cancelled' || title == 'No Show'">
              <span class="date-time">
                {{Report?.dateTimeCompleteAppointmentDate | date:appDateFormat.dateFormat}}
                <p>{{Report.dateTimeCompleteAppointmentDate | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td" *ngIf="title == 'Cancelled'">{{Report.crmVoidedBy}}</td>
            <td class="table-outer__td" *ngIf="title == 'Cancelled'" pTooltip="{{Report.schReason}}">
              {{Report.schReason}}</td>

            <td class="table-outer__td"
              *ngIf="title != 'Awaiting' && title != 'Void' && title != 'Booked' && title !='Reconciled' && title != 'EmailPhoneMissingAppointments'">

              {{Report.appTherapistName}}
            </td>
            <td class="table-outer__td" *ngIf="title == 'Awaiting' || title == 'Void'">{{Report.crmCallCount}}</td>
            <td class="table-outer__td" *ngIf="title == 'Rescheduled'">
              <span class="date-time">
                {{Report.dateTimePreviousAppointmentDate | date:appDateFormat.dateFormat}}
                <p>{{Report.dateTimePreviousAppointmentDate | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <!-- <td class="table-outer__td" *ngIf="title == 'Cancelled'">
    <span class="patient date-time">
      {{Report.dateTimeCancellationDate | date:appDateFormat.dateFormat}}
      <p>{{Report.dateTimeCancellationDate | date:'shortTime' | lowercase }}</p>
    </span>
  </td> -->
            <td class="table-outer__td" *ngIf="title == 'Voided'">
              <span class="date-time">
                {{Report.dateTimeCancellationDate | date:appDateFormat.dateFormat}}
                <p>{{Report.dateTimeCancellationDate | date:'shortTime' | lowercase }}</p>
              </span>
            </td>

            <td class="table-outer__td" *ngIf="title == 'Booked'">{{Report.schedulerName}}</td>
            <td class="table-outer__td" *ngIf="title == 'Booked'">{{Report.bookingType}}</td>
            <td class="table-outer__td" *ngIf="title != 'Booked' && title !='Reconciled'">${{Report.amountDue}}</td>
            <!--<td class="table-outer__td" *ngIf="title !='Reconciled'">{{Report.comments}}</td>-->
            <td class="table-outer__td"
              *ngIf="title !='Reconciled' && title != 'Reschedule' && title != 'EmailPhoneMissingAppointments'">
              <span class="date-time">
                {{Report.dateCreated | date:appDateFormat.dateFormat}}
                <p>{{Report.dateCreated | date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td" *ngIf="title !='Reconciled'">{{Report.wipState}}</td>
            <td class="table-outer__td"
              *ngIf="title != 'Void' &&  title != 'Awaiting' && title !='Reconciled' && title != 'EmailPhoneMissingAppointments'">
              {{Report.defaultClinic}}</td>
            <td class="table-outer__td" *ngIf="title !='Reconciled'">{{Report.crmPolicyHolderName}}</td>
            <td class="table-outer__td" *ngIf="title =='Reconciled'">{{Report.schSystemGeneratedType}}</td>
            <td class="table-outer__td">{{Report.crmFacilityTimeZone }}</td>
            <!-- <td class="table-outer__td ready-for-scheduling">{{Report.schAppointmentStatus}}</td> -->
            <!-- <td class="table-outer__td">
    <div class="appointment-actionmenu">
      <a class="actionmenu-btn" href="javascript:void(0)"
        (click)="selectRecord($event,Report,ScheduledTableAction)">
        <a class="actionmenu-btn" href="javascript:void(0)"
        (click)="ScheduledTableAction.toggle($event)">
        <i class="ic dot"></i>
      </a>
    </div>
  </td> -->
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="!loading && totalRecords <= 0">
            <tr>
              <td class="table-outer__td no-records-found" [attr.colspan]="8">
                No records found
              </td>
            </tr>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>
    </div>

    <div class="table-outer-main" *ngIf="isVoiceCallReport">
      <p-table #sdl class="table-outer" [value]="appointmentHistoryResponseModel" responsiveLayout="scroll"
        [paginator]="totalRecords>0?true:false" sortMode="single" sortField="PatientFirstName"
        [first]="searchRequest.firstRecordIndex" [sortOrder]="1" [rows]="getTotalRows()" [totalRecords]="totalRecords"
        [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr *ngIf="totalRecords > 0">
            <th class="table-outer__th  soid" width="70">
              S.O. ID
            </th>
            <th class="table-outer__th Patientid" pSortableColumn="PatientLastName">
              Patient<p-sortIcon field="PatientFirstName"></p-sortIcon>
            </th>
            <th class="table-outer__th callername" pSortableColumn="SchAppointmentCallByPerson">
              Caller Name<p-sortIcon field="SchAppointmentCallByPerson"></p-sortIcon>
            </th>
            <th class="table-outer__th phonenumber" pSortableColumn="SchAppointmentCallToNumber">
              To Phone
              <p-sortIcon field="SchAppointmentCallToNumber"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="DateTimeHistory">
              Call Date & Time<p-sortIcon field="DateTimeHistory"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="CallSID">
              Twilio CallId<p-sortIcon field="CallSID"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="RecordingSID">
              Twilio RecId<p-sortIcon field="RecordingSID">
              </p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <!-- <ng-template pTemplate="body" let-ScheduledTable> -->
        <ng-template pTemplate="body" let-Report>
          <tr>
            <td class="table-outer__td soid">
              <span class="table-checkbox">
                {{Report.crmSalesOrderAppId}}
              </span>
            </td>
            <td class="table-outer__td Patientid">
              <span class="patient">
                {{Report.patientLastName}}, {{Report.patientFirstName}}
                <p>{{Report.crmPatientId}}</p>
              </span>
            </td>
            <td class="table-outer__td callername">
              {{Report.schAppointmentCallByPerson}}
            </td>
            <td class="table-outer__td">
              {{Report.schAppointmentCallToNumber}}
            </td>
            <td class="table-outer__td">
              <span class="date-time">
                {{Report.dateTimeHistory | date:appDateFormat.dateFormat}} <p>{{Report.dateTimeHistory |
                  date:'shortTime' | lowercase }}</p>
              </span>
            </td>
            <td class="table-outer__td">
              {{Report.callSID}}
            </td>
            <td class="table-outer__td">
              {{Report.recordingSID}}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <ng-container *ngIf="!loading && totalRecords <= 0">
            <tr>
              <td class="table-outer__td no-records-found" [attr.colspan]="8">
                No records found
              </td>
            </tr>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>
    </div>
    <!-- </p-scrollPanel> -->
  </div>

  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
  <!-- </div> -->

</div>

<p-overlayPanel class="add-column-popup" #ScheduledAddColumn>
  <ul class="quick-link-menu">
    <li>
      <a href="javascript:void(0)">Branch</a>
    </li>
    <li>
      <a href="javascript:void(0)">Birth Date</a>
    </li>
    <li>
      <a href="javascript:void(0)">Email</a>
    </li>
    <li>
      <a href="javascript:void(0)">Phone Number</a>
    </li>
    <li>
      <a href="javascript:void(0)">Doctor</a>
    </li>
    <li>
      <a href="javascript:void(0)">Facility</a>
    </li>
    <li>
      <a href="javascript:void(0)">Duration</a>
    </li>
    <li>
      <a href="javascript:void(0)">Amout Due</a>
    </li>
    <li>
      <a href="javascript:void(0)">Classification Type</a>
    </li>
    <li>
      <a href="javascript:void(0)">WIP State</a>
    </li>
  </ul>
</p-overlayPanel>
<p-overlayPanel class="table-action-popup" #ScheduledTableAction>
  <ul class="quick-link-menu">
    <!-- <li><a [routerLink]="['/admin/appointments/detail/1']" routerLinkActive="active"><i class="ic view"></i>View</a> -->
    <li>
      <a (click)="onView(selectedRecord.crmSalesorderId)"><i class="ic view"></i>View</a>
    </li>
    <!-- <li><a href="javascript:void(0)" (click)="SchedulePopupClick()">
            <i class="ic schedule"></i>Reschedule</a></li>
    <li><a href="javascript:void(0)" (click)="callPopupClick()">
            <i class="ic phone" style="width: 25px;"></i>Call</a></li>
    <li><a href="javascript:void(0)"><i class="ic calllogs"></i>Call
            Logs</a></li> -->
  </ul>
</p-overlayPanel>



<p-overlayPanel class="overly-filter" styleClass="report-overly" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer overly-filter__outer--report-filter">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">

      <div *ngIf="title != 'Booked' && title != 'Reconciled' && !isVoiceCallReport" class="form__group">
        <label class="form__label">Search</label>
        <div class="search-box">
          <input type="text" class="form__control" pInputText size="50" placeholder="S.O. ID / Patient" #searchBox
            style="width:auto">
          <button *ngIf="searchBox.value !=''" class="clear-btn" type="button" (click)="searchBox.value =''">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button">
            <i class="search-new" (click)="onSearchButtonClick()"></i>
          </button>
        </div>
      </div>

      <div *ngIf="title != 'Booked' && title != 'Reconciled' &&!isVoiceCallReport" class="form__group">
        <label class="form__label">Branch</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedbranches" [options]="branchResponseModel" placeholder="Select branch"
          (onChange)="onBranchChange($event)" optionLabel="crmBranchName" [filter]="true"
          selectedItemsLabel="{0} Branches Selected"></p-multiSelect>
      </div>

      <div class="form__group"
        *ngIf="title != 'Awaiting' && title != 'Void' && title != 'Booked' && title !='Reconciled' && !isVoiceCallReport && title != 'EmailPhoneMissingAppointments'">
        <label class="form__label">Appointment Type</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedAppointmentStatus" [options]="appointmentTypes" placeholder="Select Appointment Type"
          (onChange)="onStatusChange($event)" optionLabel="name" [filter]="true"
          selectedItemsLabel="{0} Status Selected"></p-multiSelect>
      </div>

      <div class="form__group" *ngIf="(title == 'Scheduled' ||  title == 'Booked'  || title == 'Rescheduled'
      || title == 'Cancelled' || title == 'No Show' || title == 'Void' || title == 'Completed' || title == 'Unreconciled')
      && !isVoiceCallReport">
        <label class="form__label" *ngIf="!isVoiceCallReport">Appt. Booked Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Booked Date"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
          [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>


      <div class="form__group"
        *ngIf="title == 'Scheduled' || title == 'Booked' || title == 'Rescheduled' || title == 'Cancelled' 
      || title == 'No Show' || title == 'Void' || title == 'Completed' || title == 'Unreconciled' ||  title == 'Reconciled' && !isVoiceCallReport">
        <label class="form__label">Appt. Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Appt. Date"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [(ngModel)]="SSelectedDateRangeAppt" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
          [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>

      <div class="form__group" *ngIf="(title == 'Cancelled' || title == 'Void' || title == 'Completed' || title =='No Show')
      && !isVoiceCallReport">
        <label class="form__label" *ngIf="(title == 'Cancelled')">Appt. Cancelled Date</label>
        <label class="form__label" *ngIf="(title == 'Void')">Void Date</label>
        <label class="form__label" *ngIf="(title == 'Completed')">Completed Date</label>
        <label class="form__label" *ngIf="(title == 'No Show')">Appt. NoShow Date</label>
        <!-- <label class="form__label" *ngIf="(title == 'No Show')">No Show Date</label> -->
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Date" [showCustomRangeLabel]="true"
          [alwaysShowCalendars]="true" [customRangeDirection]="true" [(ngModel)]="SSelectedTypeDateRange"
          [ranges]="sRanges" [showClearButton]="true" [showCancel]="true" [linkedCalendars]="true"
          [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>


      <div class="form__group" *ngIf="title == 'EmailPhoneMissingAppointments'">
        <label class="form__label" *ngIf="!isVoiceCallReport">SO. created Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="SO. created Date"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
          [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>



      <div class="form__group" *ngIf="title == 'Rescheduled' && !isVoiceCallReport">
        <label class="form__label">Appt. Booked By</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedusers" [options]="users" placeholder="Select Appt. Booked By"
          (onChange)="onStatusChange($event)" optionLabel="name" [filter]="true"
          selectedItemsLabel="{0} user Selected"></p-multiSelect>
      </div>

      <div class="form__group" *ngIf="isVoiceCallReport">
        <label class="form__label">Call Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Call Date"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
          [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>


      <div *ngIf="title == 'Booked' && !isVoiceCallReport" class="form__group">
        <label class="form__label">Classification Type</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedClassificationType" [options]="classificationType" placeholder="Select Classification"
          optionLabel="crmClassificationDisplayName" [filter]="true"
          selectedItemsLabel="{0} Classification Selected"></p-multiSelect>
      </div>
      <div class="form__group" *ngIf="title == 'Reconciled' && !isVoiceCallReport">
        <label class="form__label">Reconcile Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Reconcile Date"
          [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
          [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
          [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate" [locale]="callocales"
          [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>

      <div class="form__group" *ngIf="title == 'Reconciled' && !isVoiceCallReport">
        <label class="form__label">Reconcile Type</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedReconsileType" [options]="reconsileTypes" placeholder="Select Reconcile"
          optionLabel="name" [filter]="true" selectedItemsLabel="{0} Reconcile  Selected">
        </p-multiSelect>
      </div>
      <div class="form__group"
        *ngIf="title != 'Awaiting' && title != 'Void' &&  title != 'Booked' && title != 'Reconciled' && !isVoiceCallReport && title != 'EmailPhoneMissingAppointments'">
        <label class="form__label">Therapist</label>
        <p-dropdown class="select-box filter-status" [(ngModel)]="selectedTherapist" [options]="therapists"
          statusDropdown (onChange)="onTherapistChange($event)" placeholder="Therapists" optionLabel="name"
          [showClear]="true">
        </p-dropdown>
      </div>
      <div class="form__group" *ngIf="title == 'Awaiting' && !isVoiceCallReport">
        <label class="form__label">Calls</label>
        <p-dropdown class="select-box filter-status" [(ngModel)]="selectedNumberOfCalls"
          [options]="numberOfCallsOptions" statusDropdown (onChange)="onCallChange($event)" optionLabel="name"
          [showClear]="true">
        </p-dropdown>
      </div>
      <div class="form__group" *ngIf="!isVoiceCallReport">
        <label class="form__label">Time Zone</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedTimeZone" [options]="timeZoneList" placeholder="Select Time Zone"
          (onChange)="onTimeZoneChange($event.value)" optionLabel="appStandardTimeZoneName" [filter]="true"
          selectedItemsLabel="{0} Time Zone Selected"></p-multiSelect>
      </div>
    </div>

    <div class="table-filter__sort-by">
      <p-menu #updatemenu [popup]="true" class="update-status-menu"></p-menu>
    </div>

    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="OnSearch($event,overlyFilter)" style="margin-right: 1.5rem;">Search</button>
      <button class="btn btn--secondary" (click)="OnClear(null)">Clear</button>
    </div>
  </div>
</p-overlayPanel>
