import { BranchResponseModel } from '../branch-response-model';
import { DoctorFacilityResponseModel } from '../doctor-facility-response-model';
import { FacilityAvailabilityResponseModel, FacilityAvailabilityResponseModelSend } from './facility-availability-response-model';
import { FacilityCustomAvailabilityResponseModel } from './facility-custom-availability-response-model';
import { TherapistFacilityResponseModel } from '../therapist/therapist-facility-response-model';
import { InsuranceInfoResponseModel } from 'src/app/shared/models/response/insurance-info-response-model';
import { SelectedTherapistResponseModel } from '../therapist/therapist-default-facility-model';
export class FacilityResponseModel {
  crmFacilityId: number;
  crmFacilityAddress: string;
  crmFacilityZipCode: string;
  crmFacilityLatitude: string;
  crmFacilityLongitude: string;
  crmFacilityContactPersonFirstName: string;
  crmFacilityContactPersonLastName: string;
  crmFacilityContactEmailAddress: string;
  crmFacilityContactNo: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  crmFacilityCapacity: number;
  crmFacilityName: string;
  crmBioData: string;
  crmAddressLine1: string;
  crmAddressLine2: string;
  crmCity: string;
  crmState: string;
  crmCountry: string;
  isRemoteAssistance: boolean;
  excludedDoctors: string;
  excludedTherapists: string;
  excludedInsurances: string;
  crmBranchName: string;
  crmInsurances: InsuranceInfoResponseModel[];
  crmBranch: BranchResponseModel;
  doctorFacility: DoctorFacilityResponseModel[];
  facilityAvailability: FacilityAvailabilityResponseModel[];
  facilityCustomAvailability: FacilityCustomAvailabilityResponseModel[];
  therapistFacility: TherapistFacilityResponseModel[];
  crmProfileImage: string;
  crmTimeZone: string;
  crmIsAllowSelfScheduleAppointments: boolean;
}

export class FacilityResponseModelDropdown {
  crmFacilityId: number;
  crmFacilityName: string;

}
export class FacilityResponseModelDropdownList {
  crmFacilityId: number;
  crmFacilityName: string;
  crmIsRemoteFacility: boolean;
  crmTimeZone: string;
}

export class FacilityResponseModelDefaultTime {
  CrmFacilityId: number;
  dtStart: string;
  dtEnd: string;
}


export class FacilityResponseModelSend {
  crmFacilityId: number;
  crmFacilityAddress: string;
  crmFacilityZipCode: string;
  crmFacilityLatitude: string;
  crmFacilityLongitude: string;
  crmFacilityContactPersonFirstName: string;
  crmFacilityContactPersonLastName: string;
  crmFacilityContactEmailAddress: string;
  crmFacilityContactNo: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  crmFacilityCapacity: number;
  crmFacilityName: string;
  crmBioData: string;
  crmAddressLine1: string;
  crmAddressLine2: string;
  crmCity: string;
  crmState: string;
  crmCountry: string;
  isRemoteAssistance: boolean;
  crmProfileImage: string;
  crmProfileImageFormat: string;
  crmTimeZone: string;
  crmBranch: BranchResponseModel;
  crmInsurances: InsuranceInfoResponseModel[];
  doctorFacility: DoctorFacilityResponseModel[];
  facilityAvailability: FacilityAvailabilityResponseModelSend[];
  facilityCustomAvailability: FacilityCustomAvailabilityResponseModel[];
  therapistFacility: TherapistFacilityResponseModel[];
  crmIsAllowSelfScheduleAppointments: boolean;
}

export class SelectedFacilityResponseModel {
  isEnable: boolean;
  facilityResponseModels: FacilityResponseModel[];
  linkedTherapists: number[];
}

export class LinkedTherapistWithCurrentFacilitiesRequestModel {
  isEnableSelfSchedulingForSelected: boolean;
  FacilityIds: number[];
  TherapistIds: number[];
}

export class LinkedTherapistWithCurrentFacilitiesResponseModel {
  appUserInfoId: number;
  crmFacilityId: number;
  appUserLname: number;
  appUserFname: number;
  crmFacilityName: number;
  crmTharapistIsAllowSelfScheduleAppointments: boolean;
  crmFacilityIsAllowSelfScheduleAppointments: boolean;
  linkedFacilities: string;
}