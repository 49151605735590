<!-- Start Header -->
<app-therapist-header></app-therapist-header>
<!-- End Header -->
<section class="therapist-module-outer">
  <!-- Top Title-->
  <div class="therapist-title-block">
    <h1 class="therapist-title-block__title">Week View</h1>
    <h1 class="therapist-title-block__title mobile-view">My Calendar</h1>

    <div class="therapist-title-block__right-block">
      <button class="btn btn--secondary week-view" (click)="back()">
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.6111 1.40283L1 4.90109L4.6111 8.39935" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M1 4.90106L6.77777 4.90106C10.7666 4.90106 14 8.0334 14 11.8976V12.5972" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        My Appointments
      </button>
      <button class="btn btn--primary" [routerLink]="['/therapist/my-availability']">My Availability
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.82975 1.73611L13.2258 5.02604L9.82975 8.31597" stroke="white" stroke-width="1.6"
            stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.2258 5.02603L7.79211 5.02603C4.04083 5.02603 1 7.97183 1 11.6059V12.2639" stroke="white"
            stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
    </div>
    <!-- (click)="myAvailability()" -->

    <div class="therapist-title-block__calendra-next-prev">
      <div class="calendra-prev-arrow" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="dateOrViewChanged()" (click)="dateChange()">
        <i class="arrow-prev"></i>
      </div>
      <!-- <div class="btn btn--primary" mwlCalendarToday [(viewDate)]="viewDate">Today</div> -->
      <div class="therapist-title-block__week-date">{{ weekStartDate}} - {{weekEndDate}}, {{currentYear}}
      </div>

      <div class="calendra-next-arrow" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="dateOrViewChanged()" (click)="dateChange()">
        <i class="arrow-next"></i>
      </div>
    </div>
  </div>

  <!--================================-->
  <!-- Desktop View -->
  <!--================================-->
  <div class="therapist-week-view-calendar-outer therapist-desktop-view">
    <div class="therapist-week-view" [ngSwitch]="view">
      <ng-template #customEventTemplate let-event="event">
        <div class="custom-event" mwlCalendarTooltip="" [tooltipPlacement]="'top'">
          <!--Appintment Box-->
          <div class="calendar-box done" [ngClass]="event.event.meta.eventObject.className"
            *ngIf="event.event.id != 0 && event.event.id != -1 &&  !event.event.meta.eventObject.is15minSlot">
            <span class="patient-color"></span>

            <div class="calendar-box__title">
              <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
              <span class="classification" tooltip="{{event.event.meta.eventObject.crmClassificationDisplayName}}">
                {{event.event.meta.eventObject.crmClassificationDisplayName}}
              </span>

              <span class="duration">
                {{event.event.meta.eventObject.duration}} minutes
              </span>

              <!-- <span class="time" tooltip="{{  event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}} - {{  event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{ event.event.meta.eventObject.facilityTimeZone }}">
                        {{  event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}} - {{  event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{ event.event.meta.eventObject.facilityTimeZone }}
                      </span> -->

              <!-- <span class="soid">
                        SO# <a (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject.practitionerId)"
                               href="javascript:void(0)">
                          {{event.event.meta.eventObject.practitionerId}}
                        </a>
                      </span> -->

              <!-- <span class="classification" tooltip="{{event.event.meta.eventObject.crmClassificationDisplayName}}">
                        {{event.event.meta.eventObject.crmClassificationDisplayName}}
                      </span>

                      <span class="classification" tooltip="{{event.event.meta.eventObject.appointmentType}}">
                        {{event.event.meta.eventObject.appointmentType}}
                      </span> -->

            </div>

            <!-- <span class="valign-center edit-btn-block"><a (click)="editEvent(event.event.id)" href="javascript:void(0)"> <i class="edit-icon-new"></i></a></span> -->
          </div>

          <!--Half Box(15 min)-->
          <div class="calendar-box done half-slot"
            *ngIf="event.event.id != 0 && event.event.id != -1 && event.event.meta.eventObject.is15minSlot">
            <span class="patient-color"></span>

            <div class="calendar-box__title">
              <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>

              <span class="classification" tooltip="{{event.event.meta.eventObject.crmClassificationDisplayName}}">
                {{event.event.meta.eventObject.crmClassificationDisplayName}}
              </span>

              <span class="duration">
                {{event.event.meta.eventObject.duration}} minutes
              </span>

              <!-- <div class="soid">
                        SO# <a (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject.practitionerId)"
                               href="javascript:void(0)">{{event.event.meta.eventObject.practitionerId}}</a>
                      </div> -->
            </div>

            <!-- <span class="valign-center edit-btn-block"> <a (click)="editEvent(event.event.id)" href="javascript:void(0)"> <i class="edit-icon-new"></i></a></span> -->
          </div>

          <!--Block Slot-->
          <div class="calendar-box done isBlock" *ngIf="event.event.id == -1">
            <span class="patient-color"></span>
            <div class="calendar-box__title">
              <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
              <span class="time">{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |
                date:'shortTime' | lowercase }}</span>
            </div>
          </div>

          <!--Break Houur-->
          <div class="calendar-box done break-hours" *ngIf="event.event.id == 0">
            <span class="patient-color"></span>
            <div class="calendar-box__title">
              <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
              <span class="time">{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |
                date:'shortTime' | lowercase }}</span>
            </div>

          </div>

        </div>
      </ng-template>
      <calendar-scheduler-view class="week-view-calenadr" *ngSwitchCase="CalendarView.Week" [viewDays]="viewDays"
        [viewDate]="viewDate" [events]="events" [locale]="locale" [responsive]="true" [weekStartsOn]="weekStartsOn"
        [excludeDays]="excludeDays" [startsWithToday]="startsWithToday" [hourSegments]="hourSegments"
        [hourSegmentHeight]="130" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [dayModifier]="dayModifier"
        [hourModifier]="hourModifier" [segmentModifier]="segmentModifier" [eventModifier]="eventModifier"
        [showEventActions]="true" [showSegmentHour]="false" [zoomEventOnHover]="false"
        (viewDaysChanged)="viewDaysChanged($event)" (dayHeaderClicked)="dayHeaderClicked($event.day)"
        (hourClicked)="hourClicked($event.hour)" (segmentClicked)="segmentClicked('Clicked', $event.segment)"
        (eventClicked)="eventClicked('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)"
        [eventTemplate]="customEventTemplate" [refresh]="refresh">
      </calendar-scheduler-view>
    </div>
  </div>
</section>

<!-- <p-toast position="bottom-right"></p-toast> -->

<div class="loader-block" *ngIf="Customloading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>