<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
    </h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/importexport']"><i class="back-new"></i>
        Back</a>
    </div>
  </div>

  <div class="sync-page-outer">
    <div class="sync-page-outer__export">
      <div class="sync-page-outer__export__block">
        <form class="sync-page-outer__block form" [formGroup]="formSyncSalesOrder" autocomplete="off">
          <div class="form__group">
            <label class="form__label">State</label>
            <p-dropdown class="select-box" [options]="wipStatesResponseModel" formControlName="wipState"
              placeholder="Select WIP State" optionLabel="wipStatusNameBt"
              [(ngModel)]="selectedwipStatesResponseModel" [showClear]="true"></p-dropdown>
            <div *ngIf="formSyncSalesOrdersubmitted && fSyncSalesOrder.wipState.errors"
              class="invalid-feedback-error">
              <span
                *ngIf="fSyncSalesOrder.wipState.errors.required || fSyncSalesOrder.wipState.errors.whitespace">
                {{validationMessages.wipState.required}}</span>

            </div>
          </div>

          <div class="form__group">
            <label class="form__label">Branch</label>
            <p-dropdown class="select-box" [options]="branchResponseModel" formControlName="branch"
              placeholder="Select Branch" optionLabel="crmBranchName" [(ngModel)]="selectedbranch"
              [showClear]="true"></p-dropdown>
            <div *ngIf="formSyncSalesOrdersubmitted && fSyncSalesOrder.branch.errors"
              class="invalid-feedback-error">
              <span *ngIf="fSyncSalesOrder.branch.errors.required || fSyncSalesOrder.branch.errors.whitespace">
                {{validationMessages.wipState.required}}</span>

            </div>
          </div>


          <div class="form__group btns-block">
            <button class="btn btn--primary" (click)="SyncSalesOrders()">
              Sync
            </button>
            <button class="btn btn--secondary" (click)="GetSyncHistory()">
              View Status
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- <div class="sync-status-outer" *ngIf="showHistory"> -->
    <div class="sync-status-outer">
      <div class="sync-status" *ngIf="syncHistory?.length > 0 && showHistory">
        <div class="sync-status__title">
          <h3>Sync History</h3>
          <a *ngIf="syncHistory?.length > 0" href="javascript:void(0);" (click)="GetSyncHistory()" class="refresh-block">
            <i class="ic refresh-ic"></i>Refresh </a>
        </div>

        <div class="sync-status__scroll">
          <table class="table custom-table online-booking">
            <thead>
              <tr>
                <th rowspan="2" width="150">Status</th>
                <th rowspan="2" width="150">Sync Date</th>
                <th colspan="3" style="text-align: center;">
                  Sales Order
                </th>
              </tr>
              <tr>
                <th width="100" class="text-center" style="padding-left:1.3rem;"><strong
                    style="color: #4e4e4e;font-weight: bold;">Total</strong></th>
                <th width="100" class="text-center">New</th>
                <th width="120" class="text-center">Already Exist</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of syncHistory;">
                <td>{{item?.jobStatus}}</td>
                <td *ngIf="item?.jobEndTime">{{item?.jobEndTime | date:appDateFormat.dateFormat}} {{item?.jobEndTime |
                  date:'shortTime' | lowercase}}</td>
                <td *ngIf="!item?.jobEndTime">-</td>

                <td class="text-center" *ngIf="item?.jobStatus == 'Completed'">
                  <a class="actionmenu-btn" href="javascript:void(0)"
                    (click)="item?.totalRecordsFound>0 &&
                    showTotalSalesOrder($event,totalSalesOrder,item?.totalSalesOrderList)">{{item?.totalRecordsFound}}</a>
                </td>
                <td class="text-center" *ngIf="item?.jobStatus != 'Completed'">-</td>

                <td class="text-center" *ngIf="item?.jobStatus == 'Completed'">
                  <a class="actionmenu-btn" href="javascript:void(0)" (click)="item?.newRecordsProcessed>0 &&
                    showNewSalesOrder($event,newSalesOrder,item?.newSalesOrderList)">{{item?.newRecordsProcessed}}</a>
                </td>
                <td class="text-center" *ngIf="item?.jobStatus != 'Completed'">-</td>

                <td class="text-center" *ngIf="item?.jobStatus == 'Completed'">
                  <a class="actionmenu-btn" href="javascript:void(0)" (click)="item?.duplicateRecords>0 &&
                    showDupSalesOrder($event,dupSalesOrder,item?.dupSalesOrderList)">{{item?.duplicateRecords}}</a>
                </td>
                <td class="text-center" *ngIf="item?.jobStatus != 'Completed'">-</td>
              </tr>
              <tr *ngIf="syncHistory?.length <= 0">
                <td colspan="5"  class="text-center no-records-found-user">
                  No records found
                </td>
              </tr>
            </tbody>
          </table>
          <p-overlayPanel #newSalesOrder [dismissable]="true" [showCloseIcon]="true">
            <div class="overly-filter__outer">
              <h2 class="filter-title">New Sales Order</h2>
              <div class="overly-filter__scroll">
                <textarea rows="5" cols="20" class="form__textarea" readonly>{{newSOList}}</textarea>
              </div>           
            </div>
          </p-overlayPanel>
          <p-overlayPanel #dupSalesOrder [dismissable]="true" [showCloseIcon]="true">
            <div class="overly-filter__outer">
            <h2 class="filter-title">Already Exist</h2>
            <div class="overly-filter__scroll">
              <textarea rows="5" cols="20" class="form__textarea" readonly>{{dupSOList}}</textarea>
            </div>
            </div>
          </p-overlayPanel>
          <p-overlayPanel #totalSalesOrder [dismissable]="true" [showCloseIcon]="true">
            <div class="overly-filter__outer">
            <h2 class="filter-title">Total Sales Order</h2>
            <div class="overly-filter__scroll">
              <textarea rows="5" cols="20" class="form__textarea" readonly>{{totalSOList}}</textarea>
            </div>
            </div>
          </p-overlayPanel>
        </div>
      </div>
    </div>
  </div>

  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</div>
