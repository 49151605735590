import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { AuthenticationRequest } from 'src/app/shared/models/request/authentication-request-model';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { TherapistService } from 'src/app/shared/services/therapist.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { UserInfoRequestModel } from 'src/app/shared/models/request/userInfo-request-model';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { TherapistLicenseResponseModel } from 'src/app/shared/models/response/therapist/therapist-license-response-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { TherapistCertificationResponseModel } from 'src/app/shared/models/response/therapist/therapist-certification-response-model';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
//import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/admin/servicies/user.service';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.sass'],
  providers: [DialogService]
})
export class ProfileComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'My Profile';
  private unsubscriber = new Subject<boolean>();
  islicenseInformationdiv: boolean = false;
  isdeviceInformationdiv: boolean = false;

  userResponseModel: UserInfoResponseModel;
  statsModel: StateMasterResponseModel[];
  selectedState: StateMasterResponseModel;
  // selectedDevice: { name: string };
  selectedDevices: { name: string }[];
  selectedDeviceModel: TherapistCertificationResponseModel;
  selectedLicenseModel: TherapistLicenseResponseModel;

  certificationResponseModel: TherapistCertificationResponseModel[];
  licenseResponseModel: TherapistLicenseResponseModel[];
  licenseExpiryDate: Date;
  userInfoId: number;
  userType: string;
  isTherapist: boolean;
  isChangePassword: boolean = false;
  minimumDate = new Date();
  enuserInfoId: string;
  deviceInformation: boolean = false;
  licenseInformation: boolean = false;

  formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    role: 'role',
    branch: 'branch',
    email: 'email',
    phoneNumber: 'phoneNumber',
    deviceName: 'deviceName',
    deviceCertificate: 'deviceCertificate',
    licenseState: 'licenseState',
    licenseNumber: 'licenseNumber',
    licenseExpiry: 'licenseExpiry'
  };

  validationMessages = {
    firstName: {
      required: 'First name is required',
      validCharacters: 'Enter valid first name'
    },
    lastName: {
      required: 'Last name is required',
      validCharacters: 'Enter valid last name'
    },
    email: {
      required: 'Email address is required',
      email: 'Enter valid email address'
    },
    phoneNumber: {
      required: 'Phone number is required',
      invalid: 'Enter valid phone number'
    },
    deviceName: {
      required: 'Device name is required',
    },
    deviceCertificate: {
      required: 'Certificate is required',
    },
    licenseState: {
      required: 'License state is required',
    },
    licenseNumber: {
      required: 'License number is required',
    },
    licenseExpiry: {
      required: 'License expiry date is required',
    },
  };
  device = [
    { name: 'BIPAP' },
    { name: 'CPAP' },
    { name: 'General DME' },
    { name: 'Oxygen' },
    { name: 'Ventilators' },
    { name: 'OSA & Mask Fits' },
  ];
  formSubmitted: boolean = false;
  formDeviceSubmitted: boolean = false;
  formLicenseSubmitted: boolean = false;
  licenseEditMode: boolean = false;
  deviceEditMode: boolean = false;
  isUpdateDeviceLicenseInfo = false;
  certificationBtnTitle: string = "Update";
  licenseBtnTitle: string = "Add";
  dateFormat: string = '';
  timeZone: string = '';
  isGoogleMap: boolean = false;
  constructor(private router: Router,
    private dialougeService: DialogService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private therapistService: TherapistService,
    private webStorageService: WebStorageService,
    private featureGroupService: FeatureGroupService,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private userService: UserService,
    messageService: MessageService,
    private settingsService: SettingsService
  ) {
    super(messageService);
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.userInfoId = currentUserToken.userId;
      this.enuserInfoId = currentUserToken.enuserId;
      this.userType = currentUserToken.roleName;
      this.isTherapist = this.userType === Constants.userType.Therapist ? true : false;
    }
    else {
      this.userInfoId = 0;
    }
    if (this.isTherapist) {
      this.titleService.setTitle('Appoint360');
    }
    else {
      this.titleService.setTitle('Appoint360');
    }
    this.getDateFormat();
    this.getTimeZone();
    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
  }

  ngOnInit() {
    this.getUpdateStatusPermission();
    this.userResponseModel = new UserInfoResponseModel();
    this.certificationResponseModel = new Array<TherapistCertificationResponseModel>();
    this.licenseResponseModel = new Array<TherapistLicenseResponseModel>();

    if (this.isTherapist) {
      this.getStates();
    }

    this.buildForm();
    this.getUser(this.enuserInfoId);

    this.selectedDevices = [];

  }
  private async getUpdateStatusPermission() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.AllowTherapistToUpdateLicenseInformation)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.isUpdateDeviceLicenseInfo = (result.responseModel.schSettingValue.toLowerCase() === 'true');
            }
          }
        });
      });
  }
  getStates() {

    this.therapistService.GetStates()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<StateMasterResponseModel[]>) => {
        this.processResult<StateMasterResponseModel[]>(result, () => {

          this.statsModel = result.responseModel;

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  deviceInformationBtn() {
    this.certificationBtnTitle = "Add";
    this.deviceInformation = true;
    this.isdeviceInformationdiv = !this.isdeviceInformationdiv;
    this.reSetValuesToDeviceForm();
  }

  licenseInformationBtn() {
    this.licenseBtnTitle = "Add";
    this.licenseInformation = true;
    this.islicenseInformationdiv = !this.islicenseInformationdiv;
    this.resetValuesToLicenseForm();
  }

  OnDeviceNameChange(device) {
    this.selectedDevices = device.value;
  }

  private setValuesToForm() {

    this.formPersonal.reset();

    if (!this.userResponseModel) {
      return;
    }

    const form = this.formPersonal;
    form.get(this.formFields.firstName).setValue(this.userResponseModel.appUserFname);
    form.get(this.formFields.lastName).setValue(this.userResponseModel.appUserLname);
    form.get(this.formFields.email).setValue(this.userResponseModel.appUserEmail);
    form.get(this.formFields.phoneNumber).setValue(this.userResponseModel.appUserPhoneNumber);
    // tslint:disable-next-line: max-line-length
  }

  private setValuesToDeviceForm() {

    if (!this.selectedDeviceModel) {
      return;
    }
    const form = this.formDevice;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.deviceCertificate).setValue(this.selectedDeviceModel.certificateNumber);

    // tslint:disable-next-line: max-line-length
  }


  private reSetValuesToDeviceForm() {
    this.formDeviceSubmitted = false;
    const form = this.formDevice;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.deviceCertificate).setValue('');
    // this.selectedDevice = null;
    this.selectedDevices = [];
    this.selectedDeviceModel = new TherapistCertificationResponseModel();
    this.deviceEditMode = false;

  }

  onLicenseCancel() {
    this.resetValuesToLicenseForm();
    this.licenseInformation = !this.licenseInformation;
  }

  onCertificationCancel() {
    this.reSetValuesToDeviceForm();
    this.deviceInformation = !this.deviceInformation;
  }

  private resetValuesToLicenseForm() {
    const form = this.formLicense;
    this.selectedState = null;
    form.get(this.formFields.licenseNumber).setValue('');
    form.get(this.formFields.licenseExpiry).setValue('');
    this.selectedLicenseModel = new TherapistLicenseResponseModel();
    this.licenseEditMode = false;
    this.licenseBtnTitle = 'Add';
    this.formLicenseSubmitted = false;
  }

  private setValuesToLicenseForm() {
    if (!this.selectedLicenseModel) {
      return;
    }
    const form = this.formLicense;
    this.selectedState = this.selectedLicenseModel.stateMaster;
    form.get(this.formFields.licenseState).setValue(this.selectedState);
    form.get(this.formFields.licenseNumber).setValue(this.selectedLicenseModel.licenceNumber);
    this.licenseExpiryDate = new Date(this.selectedLicenseModel.expiryDate);
    form.get(this.formFields.licenseExpiry).setValue(this.licenseExpiryDate);

    // tslint:disable-next-line: max-line-length
  }

  scroll(el: HTMLElement) {
    //el.scrollIntoView();
  }

  onCertificationEdit(certification: TherapistCertificationResponseModel) {

    this.certificationBtnTitle = "Update";
    this.deviceInformation = true;
    this.deviceEditMode = true;
    this.formDevice.reset();
    // this.selectedDevice = this.device.find(x => x.name === certification.deviceName);

    if (this.selectedDevices == null) {
      this.selectedDevices = [];
    }

    var devices = [];
    if (certification.deviceName.indexOf(',') > -1) {
      devices = certification.deviceName.split(',');
    } else {
      devices[0] = certification.deviceName;
    }
    if (devices.length >= 1) {
      devices.forEach((device) => {
        this.selectedDevices.push({ name: device });
      });
    } else {
      this.selectedDevices.push({ name: devices[0] });
    }

    this.selectedDeviceModel = certification;
    this.setValuesToDeviceForm();
    this.isdeviceInformationdiv = true;
  }

  onLicenseEdit(license: TherapistLicenseResponseModel) {
    this.islicenseInformationdiv = true;
    this.licenseBtnTitle = "Update";
    this.licenseInformation = true;
    this.licenseEditMode = true;
    this.formLicense.reset();
    this.selectedState = this.statsModel.find(x => x.appStateMasterId === license.stateMasterId);
    this.selectedLicenseModel = license;
    this.setValuesToLicenseForm();
  }


  private getUser(userId) {
    if (!userId) {
      return;
    }
    this.loading = true;
    this.therapistService.getTherapistById(userId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<UserInfoResponseModel>) => {
        this.processResult<UserInfoResponseModel>(result, () => {
          this.userResponseModel = result.responseModel;
          this.certificationResponseModel = result.responseModel.therapistCertification;
          this.licenseResponseModel = result.responseModel.therapistLicenseInfo;
          this.setValuesToForm();
          this.loading = false;
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }


  //private getAdminUsers(userId: string) {
  //  if (!userId) {
  //    return;
  //  }


  //  this.loading = true;

  //  this.userService.GetUserById(userId)
  //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //    .subscribe((result: ResultOfT<UserResponseModel>) => {
  //      this.processResult<UserResponseModel>(result, () => {
  //        this.userResponseModel = result.responseModel;
  //        this.setValuesToForm();
  //      });
  //    }, (httpResponseError) => {
  //      this.showErrorMessage(httpResponseError.message);
  //    });

  //}



  get fPersonal() { return (<FormGroup>this.form.get('formPersonal')).controls; }

  get fDevice() { return (<FormGroup>this.form.get('formDevice')).controls; }

  get fLicense() { return (<FormGroup>this.form.get('formLicense')).controls; }

  get formPersonal() {
    return this.form.get('formPersonal');
  }

  get formDevice() {
    return this.form.get('formDevice');
  }

  get formLicense() {
    return this.form.get('formLicense');
  }

  onCertificationAdd() {

    this.formDeviceSubmitted = true;
    if (this.formDevice.invalid) {
      this.markFormAsTouched();
      return;
    }

    const deviceModel = this.getValuesFromDeviceForm();
    deviceModel.therapistId = this.userInfoId;

    var listOfDevicesFromGrid = [];
    var tempDevice;

    this.certificationResponseModel.forEach((certification) => {
      if (certification.deviceName.indexOf(',') > -1) {
        var deviceArray = certification.deviceName.split(',');
        deviceArray.forEach((result) => {
          listOfDevicesFromGrid.push(result);
        });
      } else {
        tempDevice = certification.deviceName;
        listOfDevicesFromGrid.push(tempDevice);
      }
    });

    var isDeviceIncluded;

    for (let search of this.selectedDevices) {
      isDeviceIncluded = listOfDevicesFromGrid.includes(search.name, 0);
      if (isDeviceIncluded) {
        // if (this.deviceEditMode) {
        //   break;
        // } else {
        //   this.showErrorMessage('Device ' + search.name + ' already exist.');
        //   return;
        // }
        if (!this.deviceEditMode) {
          this.showErrorMessage('Device ' + search.name + ' already exist.');
          return;
        }
      }
    }

    if (this.certificationResponseModel.length > 1) {
      if (this.deviceEditMode) {
        // // Used For single device
        // let currentDevice = this.certificationResponseModel.find(x => x.deviceName === deviceModel.deviceName
        //   && x.certificateNumber === deviceModel.certificateNumber
        //   && x.therapistCertificationId != this.selectedDeviceModel.therapistCertificationId);
        // if (currentDevice) {
        //   this.showErrorMessage("Device " + deviceModel.deviceName + ' is already exist.');
        //   return;
        // }

        // Used for multiple devices, if certificate number already exists
        var selectedDeviceModelForEditList = [];
        if (this.selectedDeviceModel.deviceName.indexOf(',') > -1) {
          var selectedDeviceModelArray = this.selectedDeviceModel.deviceName.split(',');
          selectedDeviceModelArray.forEach((result) => {
            selectedDeviceModelForEditList.push(result);
          });
        } else {
          tempDevice = this.selectedDeviceModel.deviceName;
          selectedDeviceModelForEditList.push(tempDevice);
        }

        // listOfDevicesFromGrid.splice(selectedDeviceModelForEditList);
        selectedDeviceModelForEditList.forEach((result) => {
          var indexOfDevice = listOfDevicesFromGrid.indexOf(result);
          listOfDevicesFromGrid.splice(indexOfDevice, 1);
        });

        for (let search of this.selectedDevices) {
          isDeviceIncluded = listOfDevicesFromGrid.includes(search.name, 0);
          if (isDeviceIncluded) {
            if (this.deviceEditMode) {
              this.showErrorMessage('Device ' + search.name + ' already exist.');
              return;
            }
          }
        }

        let currentDevice = this.certificationResponseModel.find(x => x.certificateNumber === deviceModel.certificateNumber
          && x.therapistCertificationId !== this.selectedDeviceModel.therapistCertificationId);
        if (currentDevice) {
          this.showErrorMessage('Certificate ' + deviceModel.certificateNumber + ' already exist.');
          return;
        }

      }
      else {
        // // Used For single device
        // let currentDevice = this.certificationResponseModel.find(x => x.deviceName === deviceModel.deviceName
        //   && x.certificateNumber === deviceModel.certificateNumber);
        // if (currentDevice) {
        //   this.showErrorMessage("Device " + deviceModel.deviceName + ' is already exist.');
        //   return;
        // }

        // Used for multiple devices, if certificate number already exists
        let currentDevice = this.certificationResponseModel.find(x => x.certificateNumber === deviceModel.certificateNumber);
        if (currentDevice) {
          this.showErrorMessage('Certificate ' + deviceModel.certificateNumber + ' already exist.');
          return;
        }
      }
    }

    deviceModel.therapistCertificationId = 0;
    let manageLicense = this.therapistService.AddTherapistCertification(deviceModel);
    if (this.deviceEditMode) {
      deviceModel.therapistCertificationId = this.selectedDeviceModel.therapistCertificationId;
      manageLicense = this.therapistService.UpdateTherapistCertification(deviceModel);
    }

    this.loading = true;
    manageLicense.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.deviceEditMode ? 'Your device information been updated successfully.'
            : 'Your device information been added successfully.');
          this.getUser(this.enuserInfoId);
          this.selectedLicenseModel = new TherapistLicenseResponseModel();
          this.deviceEditMode = false;
          this.reSetValuesToDeviceForm();
          this.deviceInformation = !this.deviceInformation;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

    //if (this.deviceEditMode) {
    //  this.certificationResponseModel.forEach((result) => {
    //    if (result.therapistCertificationId === this.selectedDeviceModel.therapistCertificationId) {
    //      const index = this.certificationResponseModel.indexOf(result, 0);
    //      if (index > -1) {
    //        this.certificationResponseModel.splice(index, 1);
    //      }
    //    }
    //  });
    //  deviceModel.therapistCertificationId = this.selectedDeviceModel.therapistCertificationId;
    //} else {

    //  if (this.certificationResponseModel.length > 0) {

    //    let maxiD = Math.max.apply(null, this.certificationResponseModel.map(e => {
    //      return e.therapistCertificationId;
    //    }));
    //    deviceModel.therapistCertificationId = maxiD + 1;

    //  } else {
    //    deviceModel.therapistCertificationId = 1;
    //  }

    //}


    //this.certificationResponseModel.push(deviceModel);
    //this.reSetValuesToDeviceForm();
  }

  onLicenseAdd() {
    this.formLicenseSubmitted = true;
    if (this.formLicense.invalid) {
      this.markFormAsTouched();
      return;
    }
    const licenseModel = this.getValuesFromLicenseForm();
    licenseModel.therapistId = this.userInfoId;
    if (this.licenseResponseModel.length > 1) {
      if (this.licenseEditMode) {
        let currentLicense = this.licenseResponseModel.find(x => x.licenceNumber === licenseModel.licenceNumber
          && x.stateMasterId === licenseModel.stateMasterId && x.therapistLicenseInfoId != this.selectedLicenseModel.therapistLicenseInfoId);
        if (currentLicense) {
          this.showErrorMessage("License number " + licenseModel.licenceNumber + ' is already exist.');
          return;
        }
      }
      else {
        let currentLicense = this.licenseResponseModel.find(x => x.licenceNumber === licenseModel.licenceNumber
          && x.stateMasterId === licenseModel.stateMasterId);
        if (currentLicense) {
          this.showErrorMessage("License number " + licenseModel.licenceNumber + ' is already exist.');
          return;
        }
      }

    }
    licenseModel.therapistLicenseInfoId = 0;
    let manageLicense = this.therapistService.AddTherapistLicense(licenseModel);
    if (this.licenseEditMode) {
      licenseModel.therapistLicenseInfoId = this.selectedLicenseModel.therapistLicenseInfoId;
      manageLicense = this.therapistService.UpdateTherapistLicense(licenseModel);
    }

    this.loading = true;
    manageLicense.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.licenseEditMode ? 'Your license information has been updated successfully.'
            : 'Your license information has been added successfully.');
          this.selectedLicenseModel = new TherapistLicenseResponseModel();
          this.licenseEditMode = false;
          this.resetValuesToLicenseForm();
          this.getUser(this.enuserInfoId);
          this.licenseInformation = !this.licenseInformation;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
    //if (this.licenseEditMode) {
    //  this.licenseResponseModel.forEach((result) => {
    //    if (result.therapistLicenseInfoId === this.selectedLicenseModel.therapistLicenseInfoId) {
    //      const index = this.licenseResponseModel.indexOf(result, 0);
    //      if (index > -1) {
    //        this.licenseResponseModel.splice(index, 1);
    //      }
    //    }
    //  });
    //  licenseModel.therapistLicenseInfoId = this.selectedLicenseModel.therapistLicenseInfoId;
    //} else {

    //  if (this.licenseResponseModel.length > 0) {

    //    let maxiD = Math.max.apply(null, this.licenseResponseModel.map(e => {
    //      return e.therapistLicenseInfoId;
    //    }));
    //    licenseModel.therapistLicenseInfoId = maxiD + 1;

    //  } else {
    //    licenseModel.therapistLicenseInfoId = 1;
    //  }

    //}
    //licenseModel.stateMasterId = this.selectedState.appStateMasterId;
    //this.licenseResponseModel.push(licenseModel);
    //this.resetValuesToLicenseForm();
  }

  onCertificationDelete(certification: TherapistCertificationResponseModel) {

    this.reSetValuesToDeviceForm();
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete '${certification.deviceName}' ?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      const manageDevice = this.therapistService.DeleteTherapistCertification(certification.therapistCertificationId);
      manageDevice.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            this.getUser(this.enuserInfoId);
            this.showSuccessMessage('Your device information has been deleted successfully.');
          } else {

            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  onLicenseDelete(license: TherapistLicenseResponseModel) {
    this.resetValuesToLicenseForm();
    //const index = this.licenseResponseModel.indexOf(license, 0);
    //if (index > -1) {
    //  this.licenseResponseModel.splice(index, 1);
    //}
    //const manageLicense = this.therapistService.DeleteTherapistLicense(license.therapistLicenseInfoId);
    //manageLicense.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //  .subscribe((result: Result) => {
    //    if (result && result.isSuccess) {
    //      this.showSuccessMessage('Your license information has been deleted successfully.');

    //    } else {
    //      this.loading = false;
    //      this.showErrorMessage(result.message);
    //    }

    //  }, (httpResponseError) => {
    //    this.loading = false;
    //    this.showErrorMessage(httpResponseError.message);
    //  });

    var selectedDate = new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString() + '-' + new Date().getDate().toString();
    this.loading = true;
    this.therapistService.ValidationToCheckTherapistLicensewithDefaultFacilityAndAvailiblity( this.userResponseModel.appUserInfoId.toString(), this.userResponseModel.therapistDefaultFacility[0].crmFacilityId.toString(), selectedDate,license.stateMasterId.toString() )
      .pipe(takeUntil(this.unsubscriber), finalize(() =>  this.loading = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {

          if (result.responseModel != undefined && result.responseModel != null) {
            if (result.responseModel == true) {
              this.showWarningMessage(`Deletion of the '${license.stateMaster.appStateName}' state license is not allowed as either the therapist has availability for this state facility or this is the state of the therapist's default facility.`);             
              this.loading = false;
              return;
            }
          }
          this.loading = false;
          this.dialougeService.open(ConfirmDialogComponent, {
            data:
            {
              message: `Are you sure you want to delete 'License ${license.licenceNumber} ' ?`,
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: boolean) => {
            if (!response) {
              return;
            }
            this.loading = true;
            const manageLicense = this.therapistService.DeleteTherapistLicense(license.therapistLicenseInfoId);
            manageLicense.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
              .subscribe((result: Result) => {
                if (result && result.isSuccess) {
                  this.getUser(this.enuserInfoId);
                  this.showSuccessMessage('Your license information has been deleted successfully.');
      
                } else {
                  this.loading = false;
                  this.showErrorMessage(result.message);
                }
      
              }, (httpResponseError) => {
                this.loading = false;
                this.showErrorMessage(httpResponseError.message);
              });
          });
          

        }
      });

    
    

  }




  private getValuesFromLicenseForm(): TherapistLicenseResponseModel {
    const form = this.formLicense;
    const therapistLicenseResponseModel = new TherapistLicenseResponseModel();
    therapistLicenseResponseModel.stateMaster = new StateMasterResponseModel();
    therapistLicenseResponseModel.licenceNumber = form.get(this.formFields.licenseNumber).value;
    therapistLicenseResponseModel.expiryDate = form.get(this.formFields.licenseExpiry).value;
    therapistLicenseResponseModel.expiryDate = this.licenseExpiryDate;
    therapistLicenseResponseModel.expiryDateString = this.licenseExpiryDate.toDateString();
    therapistLicenseResponseModel.stateMasterId = this.selectedState.appStateMasterId;
    therapistLicenseResponseModel.stateMaster.appStateName = this.selectedState.appStateName;//form.get(this.formFields.licenseState).value;
    return therapistLicenseResponseModel;
  }
  private getValuesFromDeviceForm(): TherapistCertificationResponseModel {
    const form = this.formDevice;
    const therapistCertificationResponseModel = new TherapistCertificationResponseModel();
    // therapistCertificationResponseModel.deviceName = this.selectedDevice.name;//form.get(this.formFields.deviceName).value;

    var tempDevices = new String();
    if (this.selectedDevices.length > 1) {
      this.selectedDevices.forEach((selectedDevice) => {
        tempDevices = tempDevices + selectedDevice.name + ',';
      });
      therapistCertificationResponseModel.deviceName = tempDevices.slice(0, -1);
    } else {
      therapistCertificationResponseModel.deviceName = this.selectedDevices[0].name;
    }

    therapistCertificationResponseModel.certificateNumber = form.get(this.formFields.deviceCertificate).value;
    return therapistCertificationResponseModel;
  }

  private getValuesFromForm(): UserInfoRequestModel {

    const form = this.formPersonal;
    const therapistResponseModel = new UserInfoRequestModel();

    therapistResponseModel.modifiedByUserInfoId = this.userInfoId;
    therapistResponseModel.appUserInfoId = this.userInfoId
    therapistResponseModel.appUserFname = form.get(this.formFields.firstName).value;
    therapistResponseModel.appUserLname = form.get(this.formFields.lastName).value;
    therapistResponseModel.appUserEmail = form.get(this.formFields.email).value;
    therapistResponseModel.appUserPhoneNumber = form.get(this.formFields.phoneNumber).value;
    therapistResponseModel.isActive = true;
    if (this.certificationResponseModel.length >= 1) {
      therapistResponseModel.therapistCertification = this.certificationResponseModel;
    }
    if (this.licenseResponseModel.length >= 1) {
      therapistResponseModel.therapistLicenseInfo = this.licenseResponseModel;
    }

    return therapistResponseModel;
  }


  onSubmit() {

    this.formSubmitted = true;
    if (this.formPersonal.invalid) {
      this.markFormAsTouched();
      return;
    }
    const userRequestModel = this.getValuesFromForm();
    userRequestModel.appUserInfoId = Number(this.userInfoId);
    const manageUser = this.therapistService.updateTherapist(userRequestModel)

    this.loading = true;
    manageUser.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = true))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {

          const currentUserToken = this.webStorageService.getCurrentUserToken();
          const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
          if (currentUserToken && currentUserAuthToken.token) {
            currentUserToken.userFullName = userRequestModel.appUserFname + ' ' + userRequestModel.appUserLname;
            this.webStorageService.storeCurrentUserToken(currentUserToken);
            this.webStorageService.storeCurrentUserAuthToken(currentUserAuthToken);
          }

          // this.onCancel();
          // this.onCancel();
          setTimeout(() => {
            this.showSuccessMessage('Your profile has been updated successfully');
            this.form.reset();
            this.formSubmitted = false;
            this.formDeviceSubmitted = false;
            this.formLicenseSubmitted = false;
            this.loading = false;
            this.onCancel();
          }, 3000);
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }
  onCancel() {
    this.buildForm();
    // add pagedRequestModel as state in navigation while redirecting to maintain state
    if (this.isTherapist) {
      if (this.isGoogleMap) {
        this.router.navigate(['/therapist/my-appointments-new']);
      }
      else {
        this.router.navigate(['/therapist/my-appointments']);
      }
    }
    else {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  private buildForm() {

    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.firstName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.lastName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    // formGroup.addControl(this.formFields.role, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    // formGroup.addControl(this.formFields.branch, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));

    //if (this.isChangePassword) {
    //  formGroup.addControl(this.formFields.oldPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    //  formGroup.addControl(this.formFields.newPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    //  // tslint:disable-next-line: max-line-length
    //  formGroup.addControl(this.formFields.confirmPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    //}

    const formDeviceGroup = this.formBuilder.group({});
    formDeviceGroup.addControl(this.formFields.deviceName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formDeviceGroup.addControl(this.formFields.deviceCertificate, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));


    const formLicenseGroup = this.formBuilder.group({});
    formLicenseGroup.addControl(this.formFields.licenseState, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formLicenseGroup.addControl(this.formFields.licenseNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    formLicenseGroup.addControl(this.formFields.licenseExpiry, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace] }));

    this.form = this.formBuilder.group({
      formPersonal: formGroup,
      formDevice: formDeviceGroup,
      formLicense: formLicenseGroup
    });



    // this.form = formGroup;
  }

  private async getDateFormat() {
    await this.settingsService.getSettingsByKey(Constants.dateTimeFormat)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.dateFormat = result.responseModel.schSettingValue;
            }
          }
        });
      });
  }

  private async getTimeZone() {
    await this.settingsService.getSettingsByKey(Constants.timeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.timeZone = result.responseModel.schSettingValue;
            }
          }
        });
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

}
