import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
//import { DatePipe } from '@angular/common';
//import { TimezonePipe } from '../../../pipes/timezone.pipe';
import { WebStorageService } from '../../services/web-storage.service';
import { DateFormatModel } from '../../models/common/date-format-model';
import { BaseComponent } from '../base.component';
import { MessageService } from 'primeng/api';
//import { TimezonePipe } from '../../../pipes/timezone.pipe';
//import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };

@Component({
  selector: 'app-update-status-of-appointments',
  templateUrl: './update-status-of-appointments.component.html',
  styleUrls: ['./update-status-of-appointments.component.sass']
})
export class UpdateStatusOfAppointmentsComponent  extends BaseComponent implements OnInit {

  restrictedApptForStatus: { crmSalesOrderAPPId: string, crmPatientName: string, schAppointmentDateTime: Date }[];
  action:string;
  reasonComment:string = '';
  isBrightreeSystemAppUpdate: boolean = true;
  totalSelectedItem: number;
  appointmentCount: number;
  appDateFormat: DateFormatModel;
  formSubmitted:boolean = false;
  popupHeader:string;
  message:string = 'You are about to update the status of the selected appointment(s) to';
  //timezone:string = 'EST';

  validationMessages = {
    reasonComment: {
      required: 'Comment is required',
    },
  };

  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
      super(messageService);
      this.appDateFormat = this.webStorageService.getDateFormat();
  }

  ngOnInit(): void {

    if (this.dynamicDialogConfig) {
      if (this.dynamicDialogConfig?.data?.restrictedApptForStatus != undefined){
        this.restrictedApptForStatus = this.dynamicDialogConfig.data.restrictedApptForStatus;  
      }
      
      if (this.dynamicDialogConfig?.data?.isBrightreeSystemAppUpdate != undefined) 
        this.isBrightreeSystemAppUpdate = this.dynamicDialogConfig.data.isBrightreeSystemAppUpdate;
      
      this.popupHeader = this.dynamicDialogConfig?.data?.popupHeader;
      if (this.dynamicDialogConfig?.data?.isMultiSelected != undefined) {
          if (this.dynamicDialogConfig?.data?.isMultiSelected == 'False'){
            this.popupHeader = this.dynamicDialogConfig?.data?.popupHeader;
            this.popupHeader = this.popupHeader.replace('Appointment(s)', 'Appointment');
            this.message = this.message.replace('appointment(s)', 'appointment'); 
          }         
      }   

      if (this.dynamicDialogConfig?.data?.action != undefined)
        this.action = this.dynamicDialogConfig.data.action;
      if (this.dynamicDialogConfig?.data?.totalSelectedItem != undefined)
        this.totalSelectedItem = this.dynamicDialogConfig.data.totalSelectedItem;

      this.appointmentCount = this.totalSelectedItem;
      if (this.restrictedApptForStatus?.length > 0)
        this.appointmentCount =  this.totalSelectedItem - this.restrictedApptForStatus.length;
      }   
    }
    

    onCancel() {
      var status= new statusInfo();
      status.action= 'Cancel';
      status.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;
      status.reasonComment = this.reasonComment;
      //console.log(status);// reasonComment
      this.dynamicDialogRef.close(status);    
    }
  
    onSubmit() {   
      
      this. formSubmitted = true;
    if (this.reasonComment == '' )
      return;
      var status= new statusInfo();
      status.action= 'Confirm';
      status.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;
      status.reasonComment = this.reasonComment;
      //console.log(status);// reasonComment
      this.dynamicDialogRef.close(status);
    }


  }
