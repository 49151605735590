import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, ElementRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ImportexportService } from '../servicies/importexport.service';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadService } from '../servicies/upload.service';
import { environment } from 'src/environments/environment';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ExportResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { LazyLoadEvent } from 'primeng/api';
import { SalesOrderSynceRequestModel } from 'src/app/shared/models/request/sync-sales-order-search-request-model';
@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.sass']
})

export class ImportExportComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('fubauto') fileInput: FileUpload;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onUploadFinished = new EventEmitter();

  public facilityFileProgress: number;
  public doctorFileProgress: number;
  public therapistFileProgress: number;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  disableDoctorImportButton: boolean = true;
  disableFacilityImportButton: boolean = true;
  disableTherapistImportButton: boolean = true;
  disableSalesOrderImportButton: boolean = true;
  loggedInUserId: number;
  fromDate: Date;
  toDate: Date;
  uploadedFiles: any[] = [];
  title = 'Sync';
  Status: { name: string; code: string; }[];
  selectedFile: null;
  facilityUploadMessage: string;
  doctorUploadMessage: string;
  therapistUploadMessage: string;
  facilityFilePath: string;
  doctorFilePath: string;
  therapistFilePath: string;
  salesorderFilePaths: string[];
  appDateFormat: DateFormatModel;
  totalRecords: number;
  selectedsalesOrdersCount: number;
  totalPages: number;
  searchRequestModel: SearchRequestModel;
  exportResponseModel: ExportResponseModel[];
  @ViewChild('doctorFile') doctorFile: ElementRef;
  @ViewChild('therapistFile') therapistFile: ElementRef;
  clickEventFilterStatus: boolean;


  constructor(private importExportService: ImportexportService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitzer: DomSanitizer,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.initializeSearchRequestModel();
  }

  ngOnInit() {
    this.Status = [
      { name: 'Ready for Scheduling', code: 'NY' },
      { name: 'Overdue', code: 'NY' },
      { name: 'Check-in Brightree', code: 'NY' }
    ];
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  onUpload(event) {
    // for (let file of event.files) {
    //     this.uploadedFiles.push(file);
    // }

    // this.messageService.add({severity: 'info', summary: 'File Uploaded', detail: ''});
    this.selectedFile = event.target.files[0];
  }

  onFacilityImport() {
    this.loading = true;
    this.importExportService.importFacilityFile(this.facilityFilePath)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Facility data imported successfully');
          this.disableFacilityImportButton = true;
          // this.facilityUploadMessage = 'Import success.';
          // setTimeout(() => {
          //     this.facilityUploadMessage = '';
          // }, 5000);
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onDoctorImport() {
    this.loading = true;
    this.importExportService.importDoctorFile(this.doctorFilePath)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Doctor data imported successfully');
          this.disableDoctorImportButton = true;
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onTherapistImport() {
    this.loading = true;
    this.importExportService.importTherapistFile(this.therapistFilePath)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Therapist data imported successfully');
          this.disableTherapistImportButton = true;
          // this.therapistUploadMessage = 'Import success.';
          // setTimeout(() => {
          //     this.therapistUploadMessage = '';
          // }, 5000);
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }
  SyncSalesOrders() {

    this.loading = true;
    
    let salesOrderSynceRequestModel: SalesOrderSynceRequestModel = new SalesOrderSynceRequestModel();
    const start = moment().format('YYYY-MM-DD 00:00:01');
    const end = moment().format('YYYY-MM-DD 23:59:59');
    salesOrderSynceRequestModel.CreateDateTimeStart = moment( start).toDate();
    salesOrderSynceRequestModel.CreateDateTimeEnd = moment(end).toDate();
    this.importExportService.synceRealTimeSalesOrder(salesOrderSynceRequestModel)
      // this.importExportService.importSalesOrderFiles(this.salesorderFilePaths[0], this.salesorderFilePaths[1])
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Sales order sync successfully');
          this.disableSalesOrderImportButton = true;
          // this.therapistUploadMessage = 'Import success.';
          // setTimeout(() => {
          //     this.therapistUploadMessage = '';
          // }, 5000);
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });

  }
  onSalesOrderImport() {
    this.loading = true;
    this.importExportService.importSalesOrderFiles(this.salesorderFilePaths)
      // this.importExportService.importSalesOrderFiles(this.salesorderFilePaths[0], this.salesorderFilePaths[1])
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Sales order data imported successfully');
          this.disableSalesOrderImportButton = true;
          // this.therapistUploadMessage = 'Import success.';
          // setTimeout(() => {
          //     this.therapistUploadMessage = '';
          // }, 5000);
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  public uploadFacilityFile = (event :any ,files) => {
    if (files.length === 0) {
      return;
    }
    let clientId = '';
    let Authorization = ''
    let fileExtension = files[0].name.split('.').pop();
    if (fileExtension !== "csv") {
      this.showErrorMessage("Please upload CSV file.");
      return;
    }
    
    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    // const clientId = 1;
    // const Authorization = 'Bearer 2123';
    const headers = new HttpHeaders()
      .set("clientId", clientId.toString()).append("Authorization", Authorization);
    this.http.post(this.getApiUrl('Facility/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' })
      // this.uploadService.uploadFacility(formData)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.facilityFileProgress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.facilityFilePath = event.body['fullPath'];
          this.onUploadFinished.emit(event.body);
          this.showSuccessMessage('Facility data uploaded successfully');
          this.disableFacilityImportButton = false;
          // this.facilityUploadMessage = 'Upload success.';
          // setTimeout(() => {
          //   this.facilityUploadMessage = '';
          // }, 5000);
        }
      });
  }

  public uploadDoctorFile = (event :any , files) => {
    //console.log('test');
    if (files.length === 0) {
      return;
    }
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }

    let fileExtension = files[0].name.split('.').pop();
    if (fileExtension !== "csv") {
      this.showErrorMessage("Please upload CSV file.");
      return;
    }
    const fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);

    //const clientId = 1;
    //const Authorization = 'Bearer 2123';
    const headers = new HttpHeaders().set("clientId", clientId.toString()).append("Authorization", Authorization);
    this.http.post(this.getApiUrl('doctor/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' })
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(event => {
        
        if (event.type === HttpEventType.UploadProgress) {
          this.doctorFileProgress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.doctorFilePath = event.body['fullPath'];
          this.onUploadFinished.emit(event.body);
          this.showSuccessMessage('Doctor data uploaded successfully');
          this.disableDoctorImportButton = false;
          // this.doctorUploadMessage = 'Upload success.';
          // setTimeout(() => {
          //   this.doctorUploadMessage = '';
          // }, 5000);
        }
      });
  }

  public uploadTherapistFile = (event :any ,files) => {
    if (files.length === 0) {
      return;
    }
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    let fileExtension = files[0].name.split('.').pop();
    if (fileExtension !== "csv") {
      this.showErrorMessage("Please upload CSV file.");
      return;
    }
    const fileToUpload = <File>files[0];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = new HttpHeaders().set("clientId", clientId.toString()).append("Authorization", Authorization);
    
    this.http.post(this.getApiUrl('Therapist/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' })
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.therapistFileProgress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.therapistFilePath = event.body['fullPath'];
          this.onUploadFinished.emit(event.body);
          this.showSuccessMessage('Therapist data uploaded successfully');
          this.disableTherapistImportButton = false;
          // this.therapistUploadMessage = 'Upload success.';
          // setTimeout(() => {
          //   this.therapistUploadMessage = '';
          // }, 5000);
        }
      });
  }

  public uploadSalesOrderFiles = (event :any ,files) => {
    if (files.length === 0) {
      return;
    }
    let clientId = '';
    let Authorization = ''
    const fileToUpload: File[] = files;
    const formData = new FormData();

    Array.from(fileToUpload).map((file, index) => {
      return formData.append('file' + index, file, file.name);
    });

    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const headers = new HttpHeaders()
      .set("clientId", clientId.toString()).append("Authorization", Authorization);
    this.http.post(this.getApiUrl('Appointment/SampleUpload'), formData, { headers, reportProgress: true, observe: 'events' })
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.facilityFileProgress = Math.round(100 * event.loaded / event.total);
        }
        else if (event.type === HttpEventType.Response) {
          this.salesorderFilePaths = event.body['filePaths'];
          this.onUploadFinished.emit(event.body);
          this.showSuccessMessage('SalesOrder data uploaded successfully');
          this.disableSalesOrderImportButton = false;
        }
      });
  }

  downloadFacilityTemplate() {
    this.importExportService.downloadFacilityTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'FacilityTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadDoctorTemplate() {
    this.importExportService.downloadDoctorTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'DoctorTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadTherapistTemplate() {
    this.importExportService.downloadTherapistTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'TherapistTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  downloadSalesOrderTemplates() {
    this.importExportService.downloadSalesOrderTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'SalesOrderTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

    this.importExportService.downloadSalesOrderItemTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'SalesOrderItemTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

    this.importExportService.downloadInsuranceTemplate()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'InsuranceTemplate.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  private getApiUrl(url: string): string {
    return environment.apiBaseUrl + url;
  }

  export() {
    if (!this.fromDate) {
      this.showWarningMessage('Please select from date');
      return;
    }
    if (!this.toDate) {
      this.showWarningMessage('Please select to date');
      return;
    }
    if (this.toDate < this.fromDate) {
      this.showWarningMessage('From date can not be greater than to date');
      return;
    }
    var search = new SearchRequestModel();
    search.searchFromDate = this.fromDate;
    search.searchToDate = this.toDate;
    this.importExportService.downloadExportData(search)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        if(result.type == "application/json"){
          this.showWarningMessage('No data within the date range');
          return;
        }
        const downloadedFile = new Blob([result], { type: result.type });
          this.getHistory();
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          a.download = 'ExportData_' + '.csv';
          document.body.appendChild(a);
          a.target = '_blank';
          a.href = URL.createObjectURL(downloadedFile);
          document.body.removeChild(a);
          a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
    //this.setDateValue();
  }

  downloadFromHistory(id: number, name: string) {

    this.importExportService.downloadfromHistory(id)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = name;
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  getHistory() {    
    this.loading = true;
    this.importExportService.getHistory(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ExportResponseModel>>) => {
        this.processResult<PagedResponseModel<ExportResponseModel>>(result, () => {
          const pagedResponse = result.responseModel;
          if (pagedResponse) {
            this.exportResponseModel = pagedResponse.results;
            this.totalRecords = pagedResponse.totalRecords;
            this.totalPages = pagedResponse.totalPages;
            if (!this.searchRequestModel.PageSize)
              this.searchRequestModel.PageSize = pagedResponse.totalRecords;
            this.setDateValue();
            this.loading = false;
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory)
      return;

    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize =10;
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = 10;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    this.getHistory();
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  setDateValue() {
    if (this.exportResponseModel.length>0) {
      let mostMaxDate = new Date(Math.max.apply(null, this.exportResponseModel.map(e => {
        return new Date(e.dateTimeCreated);
      })));
      mostMaxDate = moment(mostMaxDate).add(1, 'minutes').toDate();
      // this.fromDate = new Date();
      // this.toDate = mostMaxDate;
      this.fromDate = mostMaxDate;
      this.toDate = new Date();
    }
    else {
      this.fromDate = new Date();
      this.toDate = new Date();
    }
  }


  clickEventFilter(){
    this.clickEventFilterStatus = !this.clickEventFilterStatus;       
  }
}
