import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AutologoutService } from 'src/app/shared/services/autologout.service';
import * as $ from 'jquery';
import { WebStorageService } from '../shared/services/web-storage.service';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.sass']
})
export class AdminComponent extends BaseComponent implements OnInit {
  isActive: boolean = false;
  constructor(
    private autoLogout: AutologoutService,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
  }

  async ngOnInit() {
    this.isActive = this.webStorageService.getCurrentMenuStateValue();
  }
  
  sidebarMenuClick() {
    this.isActive = !this.isActive;
    this.webStorageService.storeCurrentMenuStateValue(this.isActive);
    $("body").toggleClass("sidebarmenu"); 
  }
}
