export class TherapistAppointmentSearchRequestModel {
  appointmentDate: string;
  crmTherapistsId: number[];
  crmBranchIds: number[];
  crmFacilityiIds: number[];
  isRoadShow: boolean;
  crmTherapistsIdString: string;
  crmBranchIdsString: string;
  crmFacilityiIdsString: string;
  crmselectedTimeZone: string;
}
