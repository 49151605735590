import { AppointmentInfoReponseModel, TherapistCustomDatesResponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
export class SchedulerResponseModel {
  id: number;
  practitionerId: number;
  deliveryTechnicianId: number;
  title: string;
  start: Date;
  end: Date;
  startLabel: Date;
  endLabel: Date;
  color: string;
  allDay: boolean;
  draggable: boolean;
  field: string;
  facilityId: number;
  isCallStart: boolean;
  isMoveStart: boolean;
  availabilityType: string;
  appointmentType: string;
  appointmentTypeId: number;
  crmPatientAddress: string;
  isRemoteAndFacility: boolean;
  remoteFacilityId: number;
  availId: number;
  therapistCustomAvailabilityId: number;
  isBlock: boolean;
  is15minSlot: boolean;
  rcmPatientBrightreeID: number;
  facilityTimeZone: string;
  crmPatientTimeZone: string;
  appointment: AppointmentInfoReponseModel;
  therapistAvailability: TherapistCustomDatesResponseModel[];
  duration: string;
  className: string;
  patientLastName: string;
  patientFirstName: string;
  schAppointmentId: number;
  startTime: Date;
  crmSalesOrdeId: number;
  crmTimeZone: string;
  schWIPStatus: string;
  customDatesId: number;
  isOffice: boolean;
  therapistCustomDateId: number;
  enUserInfoId: string;
}

export class MobileSchedulerList {
  schAppointmentId: number;
  startTime: Date;
  patientLastName: string;
  patientFirstName: string;
  appointmentType: string;
  appointmentTypeId: number;
  crmPatientTimeZone: string;
  duration: string;
  crmSalesOrdeId: number;
  crmTimeZone: string;
  schWIPStatus: string;
}

export class SchedulerTimeZoneResponseModel {

  timeZone: string;
  therpiastCount: number;
}

export class SchedulerTherapistResponseModel {
  schedulerResponseModel: SchedulerResponseModel[];
  schedulerTimeZoneResponseModel: SchedulerTimeZoneResponseModel[];
}

export class SchedulerResponseModelForExportFinal {
  schedulerResponseModelForExportDbs: SchedulerResponseModelForExport[];
  appointmentCountForExportPDFs: ExportAppointmentCount[];
}

export class SchedulerResponseModelForExport {
  dtStart: Date;
  dtEnd: Date;
  soId: string;
  patientName: string;
  classification: string;
  location: string;
  apptType: string;
  status: string;
  isAppt: boolean;
  crmPatientTimeZone: string;
  crmFacilityTimeZone: string;
}

export class ExportAppointmentCount {
  appointmentTitle: string;
  appointmentCount: number;
}

export class SchedulerMinMaxResponseModel {

  start: number;
  end: number;
  startMin: number;
  endMin: number;
  startTime: Date;
  endTime: Date;
}
