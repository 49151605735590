import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
@Injectable({
  providedIn: 'root'
})

export class TwillioCallService {
  observer = new Subject();
  //public twillioCall = this.observer.asObservable();
  //subscriber(data) {
  //  this.observer.next(data);
  //}
  public twillioCall: BehaviorSubject<TwilioDeviceObject> = new BehaviorSubject<TwilioDeviceObject>(null);
}
