export class WipStatus {
  crmWipstatusId: number;
  crmWipstatusNameSa: string;
  crmWipstatusNameBt: string;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  appAlias: string;
  crmIsUpdateToBrigthree: Boolean;
  crmWIPAssignedToKey: number;
  crmWIPAssignedToPerson: string;
  crmPODStatus: string;
}
export class WipStatusBTmapping {
  crmWipstatusId: number;
  crmWipstatusNameSa: string;
  crmWipstatusNameBt: string;
  wipStatusRCMId: number;
  appAlias: string;
  crmIsUpdateToBrigthree: Boolean;
  crmWIPAssignedToKey: number;
}
