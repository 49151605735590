
<app-patient-header></app-patient-header>
<div class="landing">
 
  <div class="landing__content">
    <div class="back-btn-link">
      <!-- <a class="back-btn" href="javascript:void(0);"
         (click)="onBack()"><i class="ic back"></i>Back</a> -->
         <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
    <div class="landing__content__left">
      <h1>Telehealth</h1>
      <!-- <h2>Instruction</h2> -->
      <span class="appointment">
        {{salesOrderDescription}}
      </span>

      <ul class="menu__block menu--top">
        <li class="menu__block__item book-appointment">
          <a class="btn btn--primary" href="tel:{{DMENumber}}">Call Us Now</a>

          <button class="btn btn--secondary" (click)="RequestForCallBack()">Request for Call Back</button>
        </li>
      </ul>
    </div>
    <div class="loader-block" *ngIf="loading">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </div>
  <div class="landing__footer">
 
</div>

<app-patient-footer></app-patient-footer>

