import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './shared/interceptors/http-request-interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor';
import { TimeoutInterceptor,DEFAULT_TIMEOUT } from './shared/interceptors/timeout-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Common Controls
import { SharedComponent } from './shared/shared.component';
import { LoginComponent } from './shared/login/login.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './shared/set-password/set-password.component';
import { MyProfileComponent } from './shared/my-profile/my-profile.component';
import { CheckboxModule } from 'primeng/checkbox';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { SidebarModule } from 'primeng/sidebar';
import { MessageService } from 'primeng/api';
import { AgmCoreModule } from '@agm/core';
import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TooltipModule } from 'ng2-tooltip-directive';
import { CookieService } from 'ngx-cookie-service';
import { GoogleMapsModule } from '@angular/google-maps'

// Modules
import { AdminModule } from 'src/app/admin/admin.module';
import { AdminRoutingModule } from 'src/app/admin/admin-routing.module';
import { PatientModule } from 'src/app/patient/patient.module';
import { PatientRoutingModule } from 'src/app/patient/patient-routing.module';
import { TherapistModule } from 'src/app/therapist/therapist.module';
import { TherapistRoutingModule } from 'src/app/therapist/therapist-routing.module';
import { OnDemandSupportRoutingModule } from './on-demand-support/on-demand-support-routing.module';
import { OnDemandSupportModule } from './on-demand-support/on-demand-support.module';
import { OnDemandSupportComponent } from './on-demand-support/on-demand-support.component';
import { PipesModule } from './pipes/pipes/pipes.module';


@NgModule({
  declarations: [
    AppComponent,
    SharedComponent,
    LoginComponent,
    ForgotPasswordComponent,
    SetPasswordComponent,
    MyProfileComponent,
    PageNotFoundComponent,

    OnDemandSupportComponent

   
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    SidebarModule,
    AdminModule, AdminRoutingModule,
    PatientModule, PatientRoutingModule,
    TherapistModule, TherapistRoutingModule,
    OnDemandSupportModule, OnDemandSupportRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAVrXzuNBG92XFdg__ib3mmulydsQ-ncow',
      libraries: ['places']
    }),    
    AccordionModule,
    AutoCompleteModule,
    ToastModule,
    PipesModule,
    GoogleMapsModule
  ],
  exports: [
    CheckboxModule,
    SidebarModule,
    AccordionModule,
    AutoCompleteModule,
    TooltipModule
  ],
  providers: [
    MessageService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 300000 }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


