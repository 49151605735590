import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { AppointmentService } from '../servicies/appointment.service';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Router, ActivatedRoute } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
@Component({
  selector: 'app-patients',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.sass']
})
export class PatientComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  title = 'Patients';
  private hasPageHistory: boolean;
  patientStatus: { label: string, status: boolean }[];
  patients: PatientInfoResponseModel[];
  searchRequestModel: SearchRequestModel;
  totalRecords: number;
  totalPages: number;
  selectedPatient: PatientInfoResponseModel;
  isFacilityIncluded: boolean = false;
  currentUserBranch: number;
  loggedInUserId: number;
  //global search
  searchText: string;
  searchFor: string;
  isGlobalSearch: boolean = false;
  orderObj: any;

  constructor(private appointmentService: AppointmentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private webStorageService: WebStorageService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;

    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.hasPageHistory = true;
    }
  }
  ngAfterViewInit() {
    this.hasPageHistory = false;
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.orderObj = { ...params.keys, ...params };
      if (this.orderObj.params && this.orderObj.params.searchtext) {
        this.searchText = this.orderObj.params.searchtext;
        this.isGlobalSearch = true;
      }
    });
    this.globalSearchService.searchTerm.subscribe((newValue: GlobalSearchModel) => {
      if (newValue) {
        this.searchText = newValue.searchText;
        this.isGlobalSearch = true;
        this.searchFor = newValue.searchFor;
        if (this.isGlobalSearch && this.searchFor && this.searchFor == 'P') {
          this.initializeSearchRequestModel();
          this.getPatients();
        }
      }
    });



    this.initializeSearchRequestModel();
    this.patientStatus = [
      { label: 'Active', status: true },
      { label: 'Inactive', status: false },
    ];
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  getTotalRows() {
    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  onView(patientId: string) {
    this.router.navigate(['/admin/patients/detail/', patientId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getPatients();
  }

  onSearchButtonClick() {
    this.searchRequestModel.dictionaryFilter = {};
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    this.resetPageIndex();
    this.getPatients();
  }

  onStatusChange(event) {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    if (event.value != null)
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status;
    this.resetPageIndex();
    this.getPatients();
  }

  onCheck() {
    if (this.isFacilityIncluded === true) {
      this.searchRequestModel.dictionaryFilter['FacilityIncluded'] = 'true';
      this.resetPageIndex();
      this.getPatients();
    } else {
      this.searchRequestModel.dictionaryFilter = {};
      this.resetPageIndex();
      this.getPatients();
    }
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getPatients();
  }


  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {
    this.searchRequestModel = new SearchRequestModel();
    if (this.isGlobalSearch && this.searchFor == 'P') {
      this.searchRequestModel.dictionaryFilter = {};
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
    this.searchRequestModel.crmBranchId = this.currentUserBranch;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "CrmPatientFirstName";
  }

  private getPatients() {
    this.loading = true;
    this.appointmentService.GetAllPatients(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<PatientInfoResponseModel>>) => {
        this.processResult<PagedResponseModel<PatientInfoResponseModel>>(result, () => {
          const pagedResponse = result.responseModel;
          if (pagedResponse) {
            this.patients = pagedResponse.results;
            this.totalRecords = pagedResponse.totalRecords;
            this.totalPages = pagedResponse.totalPages;
            if (!this.searchRequestModel.PageSize) {
              this.searchRequestModel.PageSize = pagedResponse.totalRecords;
            }
            this.loading = false;
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }
}
