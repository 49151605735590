export class SupportRequestModel {
  requestorName: string;
  requestorEmail: string;
  requestorPhone: string;
  problemCategory: string;
  problemArea: string;
  problem: string;
  problemSnapshot: string;
  problemSnapshotName: string;
  problemSnapshotFileType: string;
  companyId: number;
  companyName: string;
}
