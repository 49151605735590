import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef, Output, EventEmitter, NgZone, ChangeDetectorRef, ViewChildren, QueryList, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Subject } from 'rxjs';
import { FacilitySearchResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { GMapsService } from 'src/app/shared/services/google-maps.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { MapsAPILoader } from '@agm/core';
import { MessageService } from 'primeng/api';
import { takeUntil, finalize } from 'rxjs/operators';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SearchLocation } from 'src/app/shared/models/response/search-location';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import * as moment from 'moment';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { SalesOrderService } from '../services/salesorder.service';
import { Calendar } from 'primeng/calendar';
import { PatientService } from '../services/patient.service';
import { AppointmentService } from '../../admin/servicies/appointment.service';
import { FacilityAvailableDays } from 'src/app/shared/models/response/facility-available-days';

@Component({
  selector: 'app-search-nearby-facility',
  templateUrl: './search-nearby-facility.component.html',
  styleUrls: ['./search-nearby-facility.component.sass'],
  providers: [GMapsService]
})
export class SearchNearbyFacilityComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public Editor = ClassicEditor;
  @ViewChild('pcalendar', { static: true }) calendar: Calendar;
  @ViewChild('map', { static: true }) gmapElement: ElementRef;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('city', { static: false }) city: any;
  @ViewChildren('city') childrenComponent: QueryList<Input>;
  
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() activeStepNoEmit:EventEmitter<number>= new EventEmitter();
  @Output() isFacilityOrTherapistSelectionChangedEmit:EventEmitter<boolean>= new EventEmitter();

  private unsubscriber = new Subject<boolean>();
  map: google.maps.Map;
  // lat: any;
  // lng: any;
  // markers: any;
  latitude: number;
  minimumDate = new Date();
  longitude: number;
  zoom: number;
  isFacilityView: boolean = true;
  currentLatLang: google.maps.LatLng;
  closeSidebar: boolean;
  facilitySearchResponseModel: FacilitySearchResponseModel[];
  facilitySearchRequestModel: FacilitySearchRequestModel;
  salesOrderData: SalesOrderResponseModel;
  title = 'Search Nearby Facility';
  checked: boolean;
  searchPop: boolean = false;
  cityTown: string;
  // selectedFacility: FacilitySearchResponseModel;
  selectedFacility: FacilitySearchResponseModel[];
  selectedFacilityForMarker: FacilitySearchResponseModel;
  isMapView: boolean = false;
  dateFormat: string = '';
  searchFacility: string = '';
  searchByfacilityZipCode: string;
  searchDate: Date;
  searchFromTime: Date;
  searchToTime: Date;
  facilityStartDate: Date
  monthsToShow: number;
  daysToShow: number;
  salesORderFromLocal: SalesOrderLocalModel;
  isSearch: boolean = false;
  isResetEnable: boolean = false;
  facilityDateStartTime: Date;
  facilityDateEndTime: Date;
  maxAllowDateToBookAppoitment: Date = new Date();
  isEnableSearchButton: boolean;

  formFields = {
    zipcode: 'zipcode',
    city: 'city'
  };
  validationMessages = {
    zipcode: {
      invalid: 'Enter valid zipcode'
    },
    city: {
      invalid: 'Enter valid city name'
    }
  };
  State: { name: string; code: string; }[];

  searchLocation: SearchLocation;

  selectedState: { name: string; code: string };
  selectedCity: any;
  zipCode: string;
  iconUrl: any;
  patientName: string;
  patientAddress: string;
  markerOpenId: number;
  facilityMoreInfo: boolean;
  strURL: string;
  profileLogo: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  crmProfileImageFormat: string = "";
  crmFacilityNameP: string = "";
  crmFacilityDistanceInMilesP: string = "";
  crmFacilityAddressP: string = "";
  crmFacilityAddressLine1P: string = "";
  crmFacilityAddressLine2P: string = "";
  crmFacilityCityP: string = "";
  crmFacilityStateP: string = "";
  crmFacilityCountryP: string = "";
  crmFacilityZipCodeP: string = "";
  crmFacilityFirstAvailableStartDateP: Date;
  crmProfileImageP: string = "";
  appDateFormat: DateFormatModel;
  startWeekDayFullNameP: string = "";
  endWeekDayFullNameP: string = "";
  facilityAvailableWeekDaysp: FacilityAvailableDays[];
  weekEndTimeString: string;
  weekStartTimeString: string;
  crmdescription: string = "";
  facilitieslabelCount: string = "";
  timeZone: { name: string; code: string; orgcode: string; shortName: string; }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; };
  public editor = {
    emailText: ''
  };
  duration: string = "";
  description: string = "";
  FilterEventStatus: boolean;
  isBillingAddress: boolean = false;
  patientsDefaultAppointmentLocation: string;

  isChecked:boolean=false;
  sidebarInfo: boolean = false ;
  mobileFilter: any;
  mobileFilterOverly: boolean;

  constructor(private patientService: PatientService, private appointmentService: AppointmentService,
    private router: Router,
    private formBuilder: FormBuilder,
    private webStorageService: WebStorageService,
    private mapsAPILoader: MapsAPILoader,
    private gMapsService: GMapsService,
    private ngZone: NgZone,
    messageService: MessageService, private _cdr: ChangeDetectorRef
  ) {
    super(messageService);
    //this.getDefaultView();
    this.appDateFormat = this.webStorageService.getDateFormat();
    //this.getNumberOfMonths();

    let settingResult=this.webStorageService.getPatientSalesOrderSettings();
    if(settingResult){
      this.daysToShow = parseInt(settingResult.numOfMonths, 10);
      this.monthsToShow = this.daysToShow / 30;
      this.maxAllowDateToBookAppoitment.setDate(this.maxAllowDateToBookAppoitment.getDate() + this.daysToShow);

      this.patientsDefaultAppointmentLocation = settingResult.patientsDefaultAppointmentLocation;
      if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress') {
        this.isBillingAddress = true;
      }
      else {
        this.isBillingAddress = false;
      }
    }

  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<Input>) => {
      if (comps.length > 0) {
        if (this.facilitySearchRequestModel.crmCityTown)
          this.city.nativeElement.value = this.facilitySearchRequestModel.crmCityTown;

        this.autoCompletePlace();
      }
    });
    //  this.getPlaceAutocomplete();

  }

  toggleFacilityView() {

    this.facilitySearchResponseModel.forEach(value => {
      value.animation = '';
      value.isOpen = false;
    });
    this.isFacilityView = !this.isFacilityView;

  }

  async ngOnInit() {
    this.loading = true
    this.minimumDate.setHours(0, 0, 0, 0);
    this.State = [
      {
        "name": "Alabama",
        "code": "AL"
      },
      {
        "name": "Alaska",
        "code": "AK"
      },
      {
        "name": "American Samoa",
        "code": "AS"
      },
      {
        "name": "Arizona",
        "code": "AZ"
      },
      {
        "name": "Arkansas",
        "code": "AR"
      },
      {
        "name": "California",
        "code": "CA"
      },
      {
        "name": "Colorado",
        "code": "CO"
      },
      {
        "name": "Connecticut",
        "code": "CT"
      },
      {
        "name": "Delaware",
        "code": "DE"
      },
      {
        "name": "District Of Columbia",
        "code": "DC"
      },
      {
        "name": "Federated States Of Micronesia",
        "code": "FM"
      },
      {
        "name": "Florida",
        "code": "FL"
      },
      {
        "name": "Georgia",
        "code": "GA"
      },
      {
        "name": "Guam",
        "code": "GU"
      },
      {
        "name": "Hawaii",
        "code": "HI"
      },
      {
        "name": "Idaho",
        "code": "ID"
      },
      {
        "name": "Illinois",
        "code": "IL"
      },
      {
        "name": "Indiana",
        "code": "IN"
      },
      {
        "name": "Iowa",
        "code": "IA"
      },
      {
        "name": "Kansas",
        "code": "KS"
      },
      {
        "name": "Kentucky",
        "code": "KY"
      },
      {
        "name": "Louisiana",
        "code": "LA"
      },
      {
        "name": "Maine",
        "code": "ME"
      },
      {
        "name": "Marshall Islands",
        "code": "MH"
      },
      {
        "name": "Maryland",
        "code": "MD"
      },
      {
        "name": "Massachusetts",
        "code": "MA"
      },
      {
        "name": "Michigan",
        "code": "MI"
      },
      {
        "name": "Minnesota",
        "code": "MN"
      },
      {
        "name": "Mississippi",
        "code": "MS"
      },
      {
        "name": "Missouri",
        "code": "MO"
      },
      {
        "name": "Montana",
        "code": "MT"
      },
      {
        "name": "Nebraska",
        "code": "NE"
      },
      {
        "name": "Nevada",
        "code": "NV"
      },
      {
        "name": "New Hampshire",
        "code": "NH"
      },
      {
        "name": "New Jersey",
        "code": "NJ"
      },
      {
        "name": "New Mexico",
        "code": "NM"
      },
      {
        "name": "New York",
        "code": "NY"
      },
      {
        "name": "North Carolina",
        "code": "NC"
      },
      {
        "name": "North Dakota",
        "code": "ND"
      },
      {
        "name": "Northern Mariana Islands",
        "code": "MP"
      },
      {
        "name": "Ohio",
        "code": "OH"
      },
      {
        "name": "Oklahoma",
        "code": "OK"
      },
      {
        "name": "Oregon",
        "code": "OR"
      },
      {
        "name": "Palau",
        "code": "PW"
      },
      {
        "name": "Pennsylvania",
        "code": "PA"
      },
      {
        "name": "Puerto Rico",
        "code": "PR"
      },
      {
        "name": "Rhode Island",
        "code": "RI"
      },
      {
        "name": "South Carolina",
        "code": "SC"
      },
      {
        "name": "South Dakota",
        "code": "SD"
      },
      {
        "name": "Tennessee",
        "code": "TN"
      },
      {
        "name": "Texas",
        "code": "TX"
      },
      {
        "name": "Utah",
        "code": "UT"
      },
      {
        "name": "Vermont",
        "code": "VT"
      },
      {
        "name": "Virgin Islands",
        "code": "VI"
      },
      {
        "name": "Virginia",
        "code": "VA"
      },
      {
        "name": "Washington",
        "code": "WA"
      },
      {
        "name": "West Virginia",
        "code": "WV"
      },
      {
        "name": "Wisconsin",
        "code": "WI"
      },
      {
        "name": "Wyoming",
        "code": "WY"
      }
    ]


    this.initializeAllDefaultModel();
    this.salesORderFromLocal = this.webStorageService.getSalesOrderId();
    this.facilitySearchRequestModel = new FacilitySearchRequestModel();

    //fetching from local storage
    this.description = this.salesORderFromLocal.crmClassificationDescription;
    this.duration = this.salesORderFromLocal.crmClassificationDuration == 1 || this.salesORderFromLocal.crmClassificationDuration == 60 ? "1 Hour" : this.salesORderFromLocal.crmClassificationDuration.toString() + " Minutes";

    this.facilitySearchRequestModel.crmSalesOrderId = Number(this.salesORderFromLocal.salesOrderId);
    this.facilitySearchRequestModel.crmPatientId = Number(this.salesORderFromLocal.patientId);
    this.buildForm();
    this.webStorageService.deleteSelectedFacilies();
    this.selectedFacility = this.webStorageService.getSelectedFacilies();
    if (!this.selectedFacility) {
      this.selectedFacility = [];
    }
    //await this.getSalesOrderData();
    this.zipCode = this.salesORderFromLocal.zipCode;
    this.latitude = this.salesORderFromLocal.latitude;
    this.longitude = this.salesORderFromLocal.longitude;
    this.patientName = this.salesORderFromLocal.patientName;
    this.zoom = 11;
    this.patientAddress = this.salesORderFromLocal.patientAddress;
    this.iconUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';

    this.webStorageService.deleteSearchedRequestModel();

    this.facilitySearchRequestModel = this.webStorageService.getSearchedRequestModel();
    this.getSalesOrderData();

    if (!this.facilitySearchRequestModel) {
      this.facilitySearchRequestModel = new FacilitySearchRequestModel();
      this.facilitySearchRequestModel.crmSalesOrderId = Number(this.salesORderFromLocal.salesOrderId);
      this.facilitySearchRequestModel.crmPatientId = Number(this.salesORderFromLocal.patientId);
      this.facilitySearchRequestModel.crmPatientZipCode = this.zipCode;
    }
    else {

      this.zipCode = this.facilitySearchRequestModel.crmPatientZipCode;

      let objselected = this.State.filter(x => x.code === this.facilitySearchRequestModel.crmState);
      if (objselected.length > 0)
        this.selectedState = objselected[0];

      this.cityTown = this.facilitySearchRequestModel.crmCityTown;
      if (this.city)
        this.city.nativeElement.value = this.facilitySearchRequestModel.crmCityTown;

      if (this.facilitySearchRequestModel.schAppointmentDateStartTime) {
        this.searchDate = new Date(this.facilitySearchRequestModel.schAppointmentDateStartTime);
        this.searchFromTime = this.searchDate;
      }
      if (this.facilitySearchRequestModel.schAppointmentDateEndTime) {
        this.searchFromTime = new Date(this.facilitySearchRequestModel.schAppointmentDateEndTime);
      }
      this.facilitySearchRequestModel.crmState = this.selectedState.code;
    }

    //For Time Zone Parameter Passed
    this.facilitySearchRequestModel.patientTimeZone = this.isBillingAddress ? this.salesORderFromLocal.crmBillingAddressTimeZone : this.salesORderFromLocal.crmDeliveryAddressTimeZone;

    this.setCurrentLocation();
    await this.getFacility(this.facilitySearchRequestModel);
    this.checked = false;

  }

  sidebarClickEvent() {
    this.closeSidebar = !this.closeSidebar;
  }

  closeWindow(facility: FacilitySearchResponseModel) {
    this.facilitySearchResponseModel.forEach(value => {
      if (value.crmFacilityId == facility.crmFacilityId) {
        value.animation = '';
        value.isOpen = false;
      }
    });

  }
  previous;

  clickedMarker(infowindow, facility: FacilitySearchResponseModel) {

    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
    this.onFacilitySelection(facility, infowindow);
  }

  onFacilitySelection(facility: FacilitySearchResponseModel, infowindow) {

    this.facilitySearchResponseModel.forEach(value => {
      value.isOpen = false;
      this._cdr.detectChanges();
      if (value.crmFacilityId == facility.crmFacilityId) {

        value.animation = 'DROP';
        value.isOpen = true;
      }
    });

    //this.clickedMarker(infowindow);
    this._cdr.detectChanges();
  }

  onSelect(facility: FacilitySearchResponseModel, status: boolean) {
      var objselected = this.selectedFacility.find(x => x.crmFacilityId === facility.crmFacilityId);
      if (objselected) {
        const index = this.selectedFacility.indexOf(objselected, 0);
        if (index > -1) {
          //this.isChecked=true;
          this.selectedFacility[index].isSelect=!facility.isSelect;
          this.selectedFacility.splice(index, 1);
        } 
      }
      else
      {
        facility.isSelect=true;
        this.selectedFacility.push(facility);      
      }

    this.webStorageService.deleteSelectedFacilies();
    this.webStorageService.storeSelectedFacilies(this.selectedFacility);
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    this.loading = true;
    if (this.selectedFacility) {
      this.selectedFacility = [];
    }
    this.getAddress();
    this.searchPop = false;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  getAddress() {
    let searchAddress = this.facilitySearchRequestModel.crmCityTown + ' ' + this.facilitySearchRequestModel.crmState + ' ' + this.facilitySearchRequestModel.crmPatientZipCode;

    this.gMapsService.getLatLan(searchAddress)
      .subscribe(
        result => {
          this.ngZone.run(() => {

            if (!this.isEmpty(result)) {
              this.latitude = result.lat();
              this.longitude = result.lng();
              this.searchLocation = new SearchLocation();
              this.searchLocation.address = searchAddress;
              this.searchLocation.latitude = result.lat();
              this.searchLocation.longitude = result.lng();
              this.searchLocation.iconUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
            }

          })
        },
        error => console.log(error),
        () => console.log('Geocoding completed!')
      );
  }

  onNext() {

    this.webStorageService.deleteSelectedFacilies();
    this.webStorageService.storeSelectedFacilies(this.selectedFacility);

    this.webStorageService.deleteSearchedRequestModel();
    this.webStorageService.storeSearchedRequestModel(this.facilitySearchRequestModel);


    if (this.selectedFacility.length > 0) {
      //this.router.navigate(['/patient/choose-date-time']);
      this.activeStepNoEmit.emit(2);
      this.isFacilityOrTherapistSelectionChangedEmit.emit(true);
    }
    else {
      this.showWarningMessage('Please select facility');
    }
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(['patient/home/' + url]);
  }

  OnSearchClose(e: any) {
    this.buildForm();
    this.city.nativeElement.value = '';
    this.selectedState = null;
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  private async getFacility(facilitySearchModel: FacilitySearchRequestModel) {
    this.loading = true;
    if (this.isBillingAddress)
      this.facilitySearchRequestModel.crmState = this.salesOrderData.patientInfo.crmState;
    else
      this.facilitySearchRequestModel.crmState = this.salesOrderData.crmDeliveryAddressState;

    //this.facilitySearchRequestModel.crmPatientZipCode = this.salesOrderData.patientInfo.crmPatientZipCode;

    this.facilitySearchRequestModel.crmPatientZipCode = this.isBillingAddress ? this.salesOrderData.patientInfo.crmPatientZipCode : this.salesOrderData.crmDeliveryAddressZipcode;
    if (this.isSearch) {

      this.facilitySearchRequestModel.crmCityTown = facilitySearchModel.crmCityTown;
      this.facilitySearchRequestModel.crmPatientId = facilitySearchModel.crmPatientId;
      this.facilitySearchRequestModel.crmSalesOrderId = facilitySearchModel.crmSalesOrderId;
      this.facilitySearchRequestModel.searchText = facilitySearchModel.searchText;
      this.facilitySearchRequestModel.facilityZipCode = facilitySearchModel.facilityZipCode;
      this.facilitySearchRequestModel.radius = 3;

    }
    else {
      this.facilitySearchRequestModel.crmPatientId = facilitySearchModel.crmPatientId;
      this.facilitySearchRequestModel.crmSalesOrderId = facilitySearchModel.crmSalesOrderId;
      this.facilitySearchRequestModel.radius = 3;

      if (this.searchDate) {
        var month = this.searchDate.getMonth();
        var year = this.searchDate.getFullYear();
        var date = this.searchDate.getDate();

        let startDate = new Date(year, month, date);
        let endDate = new Date(year, month, date);

        if (this.searchFromTime) {
          this.facilitySearchRequestModel.schAppointmentDateStartTimeString = moment(startDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
        }

        if (this.searchToTime) {
          this.facilitySearchRequestModel.schAppointmentDateEndTimeString = moment(endDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
        }
      }
    }
    this.facilitySearchRequestModel.isAppointment = this.salesOrderData.schAppointmentId > 0 ? true : false;
    //For Time Zone Parameter Passed
    this.facilitySearchRequestModel.patientTimeZone = this.isBillingAddress ? this.salesORderFromLocal.crmBillingAddressTimeZone : this.salesORderFromLocal.crmDeliveryAddressTimeZone;
    this.patientService.searchFacilityByPatient(this.facilitySearchRequestModel, this.isSearch)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.facilitySearchResponseModel = result.responseModel;
          if (result.responseModel != null) {
            this.facilitieslabelCount = this.facilitySearchResponseModel.length + " Clinics Found";
            //let MinFacility = this.facilitySearchResponseModel.reduce((min, b) => Math.min(min, b.crmFacilityDistance), this.facilitySearchResponseModel[0].crmFacilityDistance);

            if (this.facilitySearchResponseModel.length > 0) {
              this.closeSidebar = false;
              let MinFacility = this.facilitySearchResponseModel.filter(x => x["crmFacilityDistance"] == Math.min(...this.facilitySearchResponseModel.map(x => x["crmFacilityDistance"])))
              this.latitude = Number(MinFacility[0].crmFacilityLatitude);
              this.longitude = Number(MinFacility[0].crmFacilityLongitude);
              //console.log(MinFacility[0].crmFacilityLatitude);
            }
            if (this.facilitySearchResponseModel.length > 0) {
              this.facilitySearchResponseModel.forEach(x => {
                if (x.crmProfileImage) {
                  const image = new Image();
                  image.src = "data:image/png;base64," + x.crmProfileImage;
                  image.onload = () => {
                    const imgBase64Path = "data:image/png;base64," + x.crmProfileImage;
                    this.cardImageBase64 = imgBase64Path;
                    const indexOfComma = imgBase64Path.indexOf(",", 0);
                    this.profileLogo = logoString;
                    var logoString = this.cardImageBase64.slice(indexOfComma + 1);
                    x.crmProfileImage = this.cardImageBase64;
                    this.isImageSaved = true;
                  };
                }
                else {
                  x.crmProfileImage = null
                }
              });
            }
          }
          else {
            this.facilitieslabelCount = 0 + " Clinics";
            this.closeSidebar = true;
            this.showWarningMessage("No Clinics found");
          }
        }
      });

  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      this.zoom = 11;

      navigator.geolocation.getCurrentPosition((position) => {

        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        //this.zoom = 11;
      });
    }
  }

  private autoCompletePlace() {

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.city.nativeElement, {
        componentRestrictions: { country: 'US' },
        types: ['(cities)']
      });
      autocomplete.addListener("place_changed", () => {

        this.ngZone.run(() => {
          //get the place result

          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.searchLocation = new SearchLocation();
          this.searchLocation.address = place.formatted_address;
          this.searchLocation.latitude = place.geometry.location.lat();
          this.searchLocation.longitude = place.geometry.location.lng();
          this.searchLocation.iconUrl = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';

          let address = place.address_components;
          let zipcode = '';
          let state = '';
          let country = ''

          for (var i = 0; i < address.length; i++) {
            if (address[i].types.includes("postal_code")) { zipcode = address[i].short_name; }
            if (address[i].types.includes("administrative_area_level_1")) { state = address[i].short_name; }
            if (address[i].types.includes("country")) { country = address[i].short_name; }
          }
          let objselected = this.State.filter(x => x.code === state);
          if (objselected.length > 0)
            this.selectedState = objselected[0];

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.zipCode = zipcode;

          this.searchLocation.zipcode = zipcode;
          this.searchLocation.state = state;
          this.searchLocation.country = country;
          this.cityTown = place.name;
          this.city.nativeElement.value = place.name;
        });
      });
    });
  }

  onChange(event) {
    this.zipCode = '';
    this.city.nativeElement.value = '';
  }



  private buildForm() {
    const formGroup = this.formBuilder.group({});
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.zipcode, this.formBuilder.control('', { validators: [CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.city, this.formBuilder.control('', { validators: [CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    this.form = formGroup;
  }

  onSearchPopup() {
    this.searchPop = true;

  }

  onSearchClose() {
    this.searchPop = false;
  }

  facilityInfoDialog(facilitySearchModel: FacilitySearchResponseModel) {
    this.sidebarInfo = true;
    
    this.facilityMoreInfo = true;
    this.crmFacilityNameP = facilitySearchModel.crmFacilityName;
    this.crmFacilityDistanceInMilesP = facilitySearchModel.crmFacilityDistanceInMiles;
    this.crmFacilityAddressP = facilitySearchModel.crmFacilityAddress;
    this.crmFacilityAddressLine1P = facilitySearchModel.crmFacilityAddressLine1;
    this.crmFacilityAddressLine2P = facilitySearchModel.crmFacilityAddressLine2;
    this.crmFacilityCityP = facilitySearchModel.crmFacilityCity;
    this.crmFacilityStateP = facilitySearchModel.crmFacilityState;
    this.crmFacilityCountryP = facilitySearchModel.crmFacilityCountry;
    this.crmFacilityZipCodeP = facilitySearchModel.crmFacilityZipCode;
    this.crmFacilityFirstAvailableStartDateP = facilitySearchModel.crmFacilityFirstAvailableStartDate;
    this.crmProfileImageP = facilitySearchModel.crmProfileImage;
    this.startWeekDayFullNameP = facilitySearchModel.facilityAvailableWeekDays[0].weekDayFullName;
    this.endWeekDayFullNameP = facilitySearchModel.facilityAvailableWeekDays[facilitySearchModel.facilityAvailableWeekDays.length - 1].weekDayFullName;
    this.weekStartTimeString = facilitySearchModel.facilityAvailableWeekDays[0].startTimeString;
    this.weekEndTimeString = facilitySearchModel.facilityAvailableWeekDays[facilitySearchModel.facilityAvailableWeekDays.length - 1].endTimeString;
    this.facilityAvailableWeekDaysp = facilitySearchModel.facilityAvailableWeekDays;
    this.crmdescription = facilitySearchModel.crmBioData;
  }

  SetDays(event: any) {
    this.facilityDateStartTime = event;
    this.facilitySearchRequestModel.schAppointmentDateStartTime = this.facilityDateStartTime;
    this.facilitySearchRequestModel.schAppointmentDateEndTime = this.facilityDateStartTime;
    //let schAppointmentDateStartTimeString = moment(event, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    //this.facilitySearchRequestModel.schAppointmentDateEndTimeString = moment(event, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  onSearch() {
    this.loading = true;
    this.isResetEnable = false;
    this.isSearch = true;
    this.selectedFacility = [];
    this.facilitySearchRequestModel = this.webStorageService.getSearchedRequestModel();
    //this.facilitySearchRequestModel.schAppointmentDateStartTimeString = moment(this.facilitySearchRequestModel.schAppointmentDateStartTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    //this.facilitySearchRequestModel.schAppointmentDateEndTimeString = moment(this.facilitySearchRequestModel.schAppointmentDateEndTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    if (!this.facilitySearchRequestModel) {
      this.facilitySearchRequestModel = new FacilitySearchRequestModel();
      this.facilitySearchRequestModel.crmSalesOrderId = Number(this.salesORderFromLocal.salesOrderId);
      this.facilitySearchRequestModel.crmPatientId = Number(this.salesORderFromLocal.patientId);
      this.facilitySearchRequestModel.facilityZipCode = this.searchByfacilityZipCode
      this.facilitySearchRequestModel.crmPatientZipCode = this.zipCode;

      this.facilitySearchRequestModel.searchText = this.searchFacility;
      this.facilitySearchRequestModel.schAppointmentDateStartTimeString = (this.calendar==undefined || this.calendar.value == null) ? null : moment(this.facilityDateStartTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
      this.facilitySearchRequestModel.schAppointmentDateEndTimeString = (this.calendar==undefined || this.calendar.value == null) ? null : moment(this.facilityDateStartTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    }
    else {
      this.facilitySearchRequestModel.searchText = null;
      this.zipCode = this.facilitySearchRequestModel.crmPatientZipCode;
      let objselected = this.State.filter(x => x.code === this.facilitySearchRequestModel.crmState);
      if (objselected.length > 0)
        this.selectedState = objselected[0];
      this.facilitySearchRequestModel.crmState = this.selectedState.code;
      this.facilitySearchRequestModel.schAppointmentDateStartTimeString = moment(this.facilityDateStartTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
      this.facilitySearchRequestModel.schAppointmentDateEndTimeString = moment(this.facilityDateStartTime, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    }
    if (this.facilitySearchRequestModel.schAppointmentDateStartTimeString == null && (this.facilitySearchRequestModel.searchText == '' || this.facilitySearchRequestModel.searchText == null) && (this.facilitySearchRequestModel.facilityZipCode == undefined || this.facilitySearchRequestModel.facilityZipCode == null || this.facilitySearchRequestModel.facilityZipCode == '')) {
      this.isResetEnable = false;
    }
    else {
      if (this.facilitySearchRequestModel.schAppointmentDateStartTimeString != null || this.facilitySearchRequestModel.searchText != '' || (this.facilitySearchRequestModel.facilityZipCode != undefined || this.facilitySearchRequestModel.facilityZipCode != '')) {
        //console.log(this.isResetEnable);
        this.isResetEnable = true;
      }
    }

    //For Time Zone Parameter Passed
    this.facilitySearchRequestModel.patientTimeZone = this.isBillingAddress ? this.salesORderFromLocal.crmBillingAddressTimeZone : this.salesORderFromLocal.crmDeliveryAddressTimeZone;

    this.getFacility(this.facilitySearchRequestModel);
    this.isSearch = false;
  }

  onReset() {
    this.searchByfacilityZipCode = null;
    this.searchFacility = null;
    this.facilityDateStartTime = null;
    this.selectedFacility = [];
    if(this.calendar != undefined){
      this.calendar.value == '';
    }
    this.onSearch();
    this.isResetEnable = false;
    this.isEnableSearchButton=false;
  }

  valuechange(newValue: string, callFrom: string) {
    if(callFrom=='ClinicName'){
      this.searchFacility=newValue;
    }
    else{
      this.searchByfacilityZipCode=newValue;
    }

    if(this.searchFacility.length>0 || this.searchByfacilityZipCode.length>0){
      this.isEnableSearchButton=true;
    }
    else{
      this.isEnableSearchButton=false;
    }
  }

  clickEventFilter(){
    this.mobileFilter = !this.mobileFilter;
    this.mobileFilterOverly = !this.mobileFilterOverly;
  }

  clickEventFilterClose(){
    this.mobileFilter = !this.mobileFilter;
    this.mobileFilterOverly = !this.mobileFilterOverly; 
  }

  FilterclickEvent() {
    this.FilterEventStatus = !this.FilterEventStatus;
  }

  initializeAllDefaultModel() {
    this.timeZone = [
      // { name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST' },
      // { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST' },
      // { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST' },
      // { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MT' },
      // { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST' },
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST' },

    ];
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);

  }


  private async getSalesOrderData() {
    this.salesOrderData = this.webStorageService.getSalesOrderData();
    //this.description = this.salesOrderData.crmClassificationDescription;
    //this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
  }
}

