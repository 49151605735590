<header class="admin-header">
  <div class="admin-header__left">
    <div class="admin-header__left__logo">
      <img src="assets/img/appoint360-logo.svg" width="52" height="40" title="Appoint360" alt="Appoint360">
    </div>

    <div class="admin-header__left__client-logo">
      <img *ngIf="imageUrl" [src]="imageShortUrl" width="45" height="45" alt="{{imagealt}}" title={{imageTitle}}
        class="clientLogo">
      <!--<img src="assets/img/msc-logo-small.png" width="45" height="45" title="Medical Service Company" title="Medical Service Company">-->
    </div>

    <div class="header-search-block active" [ngClass]="status ? 'active' : 'inactive'">
      <div class="header-search-block__search-box">
        <input type="text" pInputText er="Search" [(ngModel)]="searchText" placeholder="Search"
          class="ui-inputtext ui-corner-all ui-state-default ui-widget" (keyup)="textChange()"
          (keyup.enter)="searchClick()" />
          <!-- <button class="clear-btn" pButton type="button" *ngIf="showClear" (click)="searchCancelClick()">
            <i class="ic cancel"></i>
          </button> -->
        <button class="search-btn-block search-btn" (click)="searchClick()" type="button">
          <i class="search-new"></i>
        </button>
      </div>
    </div>

    <a class="book-appointment-block mobile-view " (click)="searchClickEvnet()">
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" />
        <path
          d="M22.5712 16.2881C22.5712 17.9558 21.909 19.5552 20.7302 20.7344C19.5514 21.9137 17.9527 22.5762 16.2856 22.5762C14.6186 22.5762 13.0198 21.9137 11.841 20.7344C10.6622 19.5552 10 17.9558 10 16.2881C10 14.6204 10.6622 13.021 11.841 11.8417C13.0198 10.6625 14.6186 10 16.2856 10C17.9527 10 19.5514 10.6625 20.7302 11.8417C21.909 13.021 22.5712 14.6204 22.5712 16.2881Z" />
        <path
          d="M25 25L20.7329 20.7312M22.5712 16.2881C22.5712 17.9558 21.909 19.5552 20.7302 20.7344C19.5514 21.9137 17.9527 22.5762 16.2856 22.5762C14.6186 22.5762 13.0198 21.9137 11.841 20.7344C10.6622 19.5552 10 17.9558 10 16.2881C10 14.6204 10.6622 13.021 11.841 11.8417C13.0198 10.6625 14.6186 10 16.2856 10C17.9527 10 19.5514 10.6625 20.7302 11.8417C21.909 13.021 22.5712 14.6204 22.5712 16.2881Z"
          stroke="white" stroke-width="1.5" stroke-linecap="round" />
      </svg>
    </a>


    <div class="search-with-select ">
      <p-dropdown class="select-box select-box--header-right-side" [options]="branches" [(ngModel)]="selectedbranch"
        er="All Branches" optionLabel="name" [showClear]="true" (onClick)="ClickBranch()"
        (onChange)="ChangeBranch()"></p-dropdown>
    </div>

    <div class="call-popup-header" *ngIf="isCall">
      <span style="align-items:center;display:flex" class="call-back loading"><img src="/assets/img/d-call-ic.svg"
          width="22" height="22" alt="callback"></span>
      <a href="javascript:void(0)" (click)="showDialogHeader()">
        Calling...
        {{appoinmentDialogueParametersObject?.patientName}}
      </a>
    </div>
  </div>



  <div class="admin-header__right">
    <a class="book-appointment-block rounded " *ngIf="isAppointments">
      <i (click)="bookAppointmentSearch()" class="book-appointment-heart"></i>
    </a>

    <a class="book-appointment-button btn btn--primary" (click)="SearchPatientfromBrightree()" *ngIf="isProvisional">
      <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.5 0C5.14886 0 0 5.14886 0 11.5C0 17.8511 5.14886 23 11.5 23C17.8511 23 23 17.8511 23 11.5C23 5.14886 17.8511 0 11.5 0ZM12.5455 15.6818C12.5455 15.9591 12.4353 16.225 12.2392 16.4211C12.0432 16.6171 11.7773 16.7273 11.5 16.7273C11.2227 16.7273 10.9568 16.6171 10.7608 16.4211C10.5647 16.225 10.4545 15.9591 10.4545 15.6818V12.5455H7.31818C7.04091 12.5455 6.77499 12.4353 6.57893 12.2392C6.38287 12.0432 6.27273 11.7773 6.27273 11.5C6.27273 11.2227 6.38287 10.9568 6.57893 10.7608C6.77499 10.5647 7.04091 10.4545 7.31818 10.4545H10.4545V7.31818C10.4545 7.04091 10.5647 6.77499 10.7608 6.57893C10.9568 6.38287 11.2227 6.27273 11.5 6.27273C11.7773 6.27273 12.0432 6.38287 12.2392 6.57893C12.4353 6.77499 12.5455 7.04091 12.5455 7.31818V10.4545H15.6818C15.9591 10.4545 16.225 10.5647 16.4211 10.7608C16.6171 10.9568 16.7273 11.2227 16.7273 11.5C16.7273 11.7773 16.6171 12.0432 16.4211 12.2392C16.225 12.4353 15.9591 12.5455 15.6818 12.5455H12.5455V15.6818Z"
          fill="white" />
      </svg>
      <span>Book Appointment</span>
    </a>

    <a class="support-menu " [routerLink]="['/support']" routerLinkActive="active">
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.1834 0.591675C10.7292 0.591675 8.33019 1.31941 6.28966 2.68285C4.24912 4.0463 2.65871 5.98421 1.71956 8.25154C0.780401 10.5189 0.534675 13.0138 1.01345 15.4207C1.49223 17.8277 2.67401 20.0387 4.40934 21.774C6.14468 23.5093 8.35563 24.6911 10.7626 25.1699C13.1696 25.6487 15.6645 25.4029 17.9318 24.4638C20.1991 23.5246 22.137 21.9342 23.5005 19.8937C24.8639 17.8532 25.5917 15.4541 25.5917 13C25.5917 9.7091 24.2844 6.553 21.9574 4.22599C19.6303 1.89898 16.4742 0.591675 13.1834 0.591675ZM13.1834 23.8573C11.036 23.8573 8.93684 23.2205 7.15137 22.0275C5.3659 20.8345 3.97429 19.1388 3.15253 17.1549C2.33077 15.171 2.11576 12.988 2.53469 10.8818C2.95362 8.77574 3.98768 6.84116 5.50609 5.32274C7.02451 3.80432 8.9591 2.77027 11.0652 2.35133C13.1713 1.9324 15.3543 2.14741 17.3383 2.96918C19.3222 3.79094 21.0178 5.18254 22.2108 6.96801C23.4039 8.75348 24.0406 10.8526 24.0406 13C24.0406 15.8795 22.8967 18.6411 20.8606 20.6773C18.8245 22.7134 16.0629 23.8573 13.1834 23.8573Z"
          fill="#3E3E3E" />
        <path
          d="M13.5351 7.00059C12.9009 6.99005 12.2721 7.12013 11.6935 7.38158C11.1149 7.64303 10.6006 8.02945 10.1873 8.51336C10.1299 8.56688 10.0837 8.63144 10.0515 8.70323C10.0194 8.77501 10.0018 8.85256 10 8.93128C9.99996 9.00391 10.0144 9.0758 10.0426 9.14268C10.0708 9.20956 10.112 9.27006 10.1639 9.32058C10.2157 9.37111 10.2772 9.41062 10.3446 9.43677C10.4119 9.46293 10.4838 9.47518 10.556 9.47281C10.7106 9.46896 10.8585 9.40823 10.9716 9.30211C11.2844 8.93345 11.6729 8.63731 12.1104 8.43409C12.5479 8.23086 13.024 8.12537 13.5059 8.12486C14.8871 8.12486 15.7475 9.01957 15.7475 10.1321V10.1615C15.7475 11.4624 14.7174 12.1864 13.0903 12.31C13.0244 12.3125 12.9598 12.3291 12.9007 12.3585C12.8416 12.3879 12.7894 12.4295 12.7474 12.4806C12.7054 12.5317 12.6746 12.5912 12.657 12.6551C12.6395 12.719 12.6355 12.786 12.6455 12.8515V14.4702C12.66 14.6159 12.7281 14.7509 12.8363 14.8489C12.9445 14.9469 13.0852 15.0007 13.2308 15H13.2952C13.44 14.9854 13.5743 14.9169 13.6717 14.8081C13.7691 14.6992 13.8226 14.5578 13.8219 14.4114V13.1988C15.5778 12.9516 17 12.0216 17 10.0909V10.0614C16.9824 8.29557 15.6129 7.00059 13.5351 7.00059Z"
          fill="#3E3E3E" />
        <path
          d="M13.0959 19.8635C13.8866 19.8635 14.5276 19.2225 14.5276 18.4317C14.5276 17.641 13.8866 17 13.0959 17C12.3051 17 11.6641 17.641 11.6641 18.4317C11.6641 19.2225 12.3051 19.8635 13.0959 19.8635Z"
          fill="#3E3E3E" />
      </svg>

    </a>

    <!-- (click)="notification.toggle($event)" -->
    <a class="notifiy-box" href="javascript:void(0)" (click)="NotifactionclickEvent()">
      <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.806 16.1872C18.2559 15.6861 17.7743 15.1117 17.3742 14.4793C16.9373 13.6065 16.6755 12.6533 16.604 11.6756V8.79616C16.6078 7.2606 16.0627 5.77648 15.071 4.62265C14.0794 3.46882 12.7095 2.72469 11.2188 2.53009V1.77816C11.2188 1.57178 11.1386 1.37385 10.9957 1.22792C10.8529 1.08198 10.6592 1 10.4572 1C10.2552 1 10.0615 1.08198 9.9187 1.22792C9.77588 1.37385 9.69564 1.57178 9.69564 1.77816V2.54175C8.21827 2.75038 6.86495 3.499 5.88634 4.64896C4.90773 5.79891 4.37015 7.27228 4.37315 8.79616V11.6756C4.30171 12.6533 4.03987 13.6065 3.60302 14.4793C3.2099 15.1102 2.73602 15.6846 2.19396 16.1872C2.13311 16.2418 2.08434 16.3091 2.0509 16.3844C2.01745 16.4598 2.0001 16.5416 2 16.6244V17.4171C2 17.5717 2.0601 17.72 2.16709 17.8293C2.27407 17.9386 2.41917 18 2.57047 18H18.4295C18.5808 18 18.7259 17.9386 18.8329 17.8293C18.9399 17.72 19 17.5717 19 17.4171V16.6244C18.9999 16.5416 18.9825 16.4598 18.9491 16.3844C18.9157 16.3091 18.8669 16.2418 18.806 16.1872ZM3.18658 16.8342C3.71735 16.3103 4.18467 15.7232 4.57852 15.0855C5.12881 14.0314 5.44988 12.8682 5.5198 11.6756V8.79616C5.49717 8.11303 5.60932 7.43224 5.84956 6.79432C6.08979 6.1564 6.45321 5.5744 6.91816 5.08297C7.38311 4.59155 7.9401 4.20075 8.55594 3.93385C9.17179 3.66695 9.8339 3.5294 10.5029 3.5294C11.1718 3.5294 11.8339 3.66695 12.4498 3.93385C13.0656 4.20075 13.6226 4.59155 14.0875 5.08297C14.5525 5.5744 14.9159 6.1564 15.1561 6.79432C15.3964 7.43224 15.5085 8.11303 15.4859 8.79616V11.6756C15.5558 12.8682 15.8769 14.0314 16.4272 15.0855C16.821 15.7232 17.2884 16.3103 17.8191 16.8342H3.18658Z"
          fill="#3E3E3E" />
        <path
          d="M10.5367 21.1097C11.1571 21.0956 11.7524 20.8651 12.2173 20.4588C12.6823 20.0526 12.987 19.4969 13.0775 18.89H7.89746C7.9905 19.5134 8.30942 20.0822 8.7949 20.4905C9.28038 20.8988 9.89937 21.1189 10.5367 21.1097Z"
          fill="#3E3E3E" />
      </svg>

      <!-- notifiy-box-green -->
      <span class="notifiy-box-grey" *ngIf="unReadCount>0">{{unReadCount}}</span>
      <!-- <span class="notifiy-box-grey" *ngIf="unReadCount ==0"></span> -->

      <!-- <span *ngIf="unReadCount>0" class="numbers-cunt">{{unReadCount}}</span> -->
    </a>

    <div class="user-avatar rounded ">
      <a class="user-avatar__link" (click)="useMenu.toggle($event)" href="javascript:void(0)">
        <img src="assets/img/user-img.svg" width="52" height="40">
      </a>

      <p-overlayPanel class="user-avatar-popup" #useMenu appendTo="body">
        <div class="profile-menu">
          <ul class="quick-link-menu">
            <li>
              <strong>{{userfullname}}</strong>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/admin/my-profile']" routerLinkActive="active">
                <img src="assets/img/user-icon.svg" class="user-icon" width="18" height="18" alt="user">
                Profile
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" [routerLink]="['/admin/change-password']" routerLinkActive="active">
                <img src="assets/img/password-icon.svg" class="key-ic" width="18" height="18" alt="user">
                Change Password
              </a>
            </li>

            <li class="logout-item">
              <a class="menu__block__item__link" href="javaScript:void(0);" (click)="onLogout()"
                routerLinkActive="active">
                <img src="assets/img/logout-icon.svg" width="20" height="20" alt="user">
                Logout
              </a>
            </li>
          </ul>
        </div>
      </p-overlayPanel>
    </div>

  </div>
</header>

<p-toast class="toast-msg" position="bottom-right" [style]="{marginTop: '80px'}"></p-toast>

<!-- <p-overlayPanel class="notification-popup" #notification [showCloseIcon]="true">
  <div class="notification-block" *ngIf="notificationInAppResponseModel?.length>0">
    <p-scrollPanel [style]="{width: '100%', height: '250px'}">
      <h2 class="notification-title">Notifications</h2>
      <ul>
        <li *ngFor="let notiy of notificationInAppResponseModel">
          <h3>{{notiy.appNotificationInAppDesc}}</h3>
          <p>{{notiy.appNotificationInApp}}</p>
          <p class="date-time">{{getNotificationDate(notiy.dateTimeCreated)}}</p>
        </li>
      </ul>
    </p-scrollPanel>
    <div class="view-btn" *ngIf="notificationInAppResponseModel?.length>0">
      <a *ngIf="unReadCount > 0" href="javascript:void(0)" (click)="ViewAllNotification($event,notification)">View All</a>
      <a *ngIf="unReadCount <= 0" href="javascript:void(0)" (click)="ViewAllNotification($event,notification)">View Past All</a>
    </div>
  </div>
  <div class="notification-block" *ngIf="unReadCount <= 0">
      <div class="no-notification">
        No new notification
      </div>   
  </div>
</p-overlayPanel> -->

<div class="notification-overly" [ngClass]="headerNotifaction ? 'show-noti' : ''">
  <div class="h-notification">
    <div class="h-notification__header">
      <h2 class="h-notification__title">Notifications</h2>
      <a class="h-notification__close-icon" href="javascript:void(0)" (click)="NotifactionclickEvent()">
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.2116 2.8568L2.78845 18.1432" stroke="#3E3E3E" stroke-width="2.5" />
          <path d="M18.143 18.2116L2.85657 2.78844" stroke="#3E3E3E" stroke-width="2.5" />
        </svg>
      </a>
    </div>
    <div class="h-notification__body">
      <div class="user-notification"
        *ngIf="allowToViewSalesOrderNotifications || allowToViewPatientNotifications || allowToViewTherapistNotifications">
        <p-tabView class="notification-tab-menu">
          <a href="javascript:void(0)" class="user-notification__mark-all" (click)="MarkAllNotoficationAsRead()">Mark
            all as read</a>
          <!--All Notifaction list -->
          <p-tabPanel header="All">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentBooked'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has booked the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentReschedule'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has rescheduled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentCancel'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has cancelled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='PersonalDetailUpdate'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has update
                        {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <strong>{{subitem.appLastName + ', '+subitem.appFirstName}}</strong> auto synced
                        <a href="javascript:void(0)"
                          (click)="selectedSalesOrderSyncInfoDialog(subitem.bellNotificationInAppDetailsSummary); patientsOverly.toggle($event)">{{subitem.appTotalSyncRecords}}
                          sales</a> orders successfully.
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Therapist' && subitem.appBellNotificationSubType=='TheapistUpdateStatus'">
                        <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                          '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                        {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                        status for the <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                          ','+subitem.appFirstName}}</a>.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <!-- Email -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                    && subitem.appBellNotificationSubType=='FailedNotification'
                    && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Email'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for schedule/rescheduled the appointment is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation Email'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation Email'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for appointment schedule/rescheduled invitation is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for appointment reminder is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>


                      </div>

                      <!-- SMS -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                    && subitem.appBellNotificationSubType=='FailedNotification'
                    && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='SMS'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for schedule/rescheduled the appointment is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation SMS'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation SMS'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for appointment schedule/rescheduled invitation is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for appointment reminder is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <!-- <a class="user-notification__re-try" (click)="ResendAlertNotification(subitem)">
                          <span>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect width="24" height="24" />
                              <path
                                d="M21.3687 13.5827C21.4144 13.3104 21.2306 13.0526 20.9583 13.0069C20.686 12.9612 20.4281 13.1449 20.3825 13.4173L21.3687 13.5827ZM12 20.5C7.30558 20.5 3.5 16.6944 3.5 12H2.5C2.5 17.2467 6.75329 21.5 12 21.5V20.5ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5V2.5C6.75329 2.5 2.5 6.75329 2.5 12H3.5ZM12 3.5C15.3367 3.5 18.2252 5.4225 19.6167 8.22252L20.5122 7.77748C18.9583 4.65062 15.7308 2.5 12 2.5V3.5ZM20.3825 13.4173C19.7081 17.437 16.2112 20.5 12 20.5V21.5C16.7077 21.5 20.6148 18.0762 21.3687 13.5827L20.3825 13.4173Z"
                                fill="#000000" />
                              <path d="M20.4716 2.42157V8.07843H14.8147" stroke="#000000" stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </span>
                        </a> -->
                      </div>

                      <!-- VoiceMail -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                      && subitem.appBellNotificationSubType=='FailedNotification'
                      && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Voicemail'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Voicemail'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Voicemail sending failed.
                        </span>
                      </div>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>

                    </div>
                    <!-- *ngIf="subitem.appBellNotificationType=='Alert'" -->
                    <a [ngClass]="subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationEnabled ? '' : 'disabled'"  class="user-notification__re-try" (click)="ResendAlertNotification(subitem)"
                      *ngIf="subitem.appBellNotificationType=='Alert'">
                      <span pTooltip="Resend {{subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationType}}" tooltipPosition="left">
                        <!-- <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect width="24" height="24" />
                          <path
                            d="M21.3687 13.5827C21.4144 13.3104 21.2306 13.0526 20.9583 13.0069C20.686 12.9612 20.4281 13.1449 20.3825 13.4173L21.3687 13.5827ZM12 20.5C7.30558 20.5 3.5 16.6944 3.5 12H2.5C2.5 17.2467 6.75329 21.5 12 21.5V20.5ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5V2.5C6.75329 2.5 2.5 6.75329 2.5 12H3.5ZM12 3.5C15.3367 3.5 18.2252 5.4225 19.6167 8.22252L20.5122 7.77748C18.9583 4.65062 15.7308 2.5 12 2.5V3.5ZM20.3825 13.4173C19.7081 17.437 16.2112 20.5 12 20.5V21.5C16.7077 21.5 20.6148 18.0762 21.3687 13.5827L20.3825 13.4173Z"
                            fill="#000000" />
                          <path d="M20.4716 2.42157V8.07843H14.8147" stroke="#000000" stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg> -->
                        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path _ngcontent-pqk-c80=""
                            d="M7.39347 10.6065L17 1M7.39347 10.6065L10.4501 16.7198C10.4884 16.8034 10.5499 16.8742 10.6273 16.9239C10.7047 16.9736 10.7948 17 10.8868 17C10.9787 17 11.0688 16.9736 11.1462 16.9239C11.2236 16.8742 11.2851 16.8034 11.3234 16.7198L17 1M7.39347 10.6065L1.28022 7.54991C1.19661 7.51159 1.12576 7.45007 1.07608 7.37267C1.02641 7.29526 1 7.20522 1 7.11325C1 7.02127 1.02641 6.93123 1.07608 6.85383C1.12576 6.77642 1.19661 6.7149 1.28022 6.67659L17 1"
                            stroke="#4A4A4A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>

                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>

          <!--Sales Order Notifaction list -->
          <p-tabPanel header="Sync Sales Order" *ngIf="allowToViewSalesOrderNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellSalesOrderNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <strong>{{subitem.appLastName + ', '+subitem.appFirstName}}</strong> auto synced
                        <a href="javascript:void(0)"
                          (click)="selectedSalesOrderSyncInfoDialog(subitem.bellNotificationInAppDetailsSummary); patientsOverly.toggle($event)">{{subitem.appTotalSyncRecords}}
                          sales</a> orders successfully.
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Patient Notifaction list -->
          <p-tabPanel header="Patient" *ngIf="allowToViewPatientNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellPatientNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentBooked'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has booked the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentReschedule'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has rescheduled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentCancel'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has cancelled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='PersonalDetailUpdate'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has update
                        {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Therapist Notifaction list -->
          <p-tabPanel header="Therapist" *ngIf="allowToViewTherapistNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellTherapistNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Therapist' && subitem.appBellNotificationSubType=='TheapistUpdateStatus'">
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus != 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                            ','+subitem.appFirstName}}</a>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus == 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <strong>{{subitem.appLastName + ','+subitem.appFirstName}}</strong>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Alert Notifaction list -->
          <p-tabPanel header="Alert" *ngIf="allowToViewAlertNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellAlertNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <!-- Email -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                                && subitem.appBellNotificationSubType=='FailedNotification'
                                && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Email'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for schedule/rescheduled the appointment is
                        failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation Email'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation Email'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for appointment schedule/rescheduled invitation is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for appointment reminder is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <!-- SMS -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                                && subitem.appBellNotificationSubType=='FailedNotification'
                                && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='SMS'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for schedule/rescheduled the appointment is
                        failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation SMS'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation SMS'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for appointment schedule/rescheduled invitation is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for appointment reminder is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <!-- VoiceMail -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                      && subitem.appBellNotificationSubType=='FailedNotification'
                      && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Voicemail'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Voicemail'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Voicemail sending failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <a  [ngClass]="subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationEnabled ? '' : 'disabled'" class="user-notification__re-try" (click)="ResendAlertNotification(subitem)">
                      <span pTooltip="Resend {{subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationType}}" tooltipPosition="left">
                        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path _ngcontent-pqk-c80=""
                            d="M7.39347 10.6065L17 1M7.39347 10.6065L10.4501 16.7198C10.4884 16.8034 10.5499 16.8742 10.6273 16.9239C10.7047 16.9736 10.7948 17 10.8868 17C10.9787 17 11.0688 16.9736 11.1462 16.9239C11.2236 16.8742 11.2851 16.8034 11.3234 16.7198L17 1M7.39347 10.6065L1.28022 7.54991C1.19661 7.51159 1.12576 7.45007 1.07608 7.37267C1.02641 7.29526 1 7.20522 1 7.11325C1 7.02127 1.02641 6.93123 1.07608 6.85383C1.12576 6.77642 1.19661 6.7149 1.28022 6.67659L17 1"
                            stroke="#4A4A4A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div class="h-notification__footer"
      *ngIf="allowToViewSalesOrderNotifications || allowToViewPatientNotifications || allowToViewTherapistNotifications || bellAlertNotificationInAppResponseModel">
      <a class="h-notification__view-all" href="javascript:void(0)" (click)="ViewAllNoticlickEvent()">View All
        Notifications</a>
    </div>



  </div>
</div>

<p-overlayPanel [style]="{width: '450px'}" #patientsOverly [dismissable]="true" [showCloseIcon]="true"
  styleClass="patient-info-popup-mask">
  <div class="notifi-overlay">
    <div class="notifi-overlay__sales-orders-detail">
      <h2>Patient Information</h2>
      <div class="notifi-overlay__orders-list" *ngFor="let item of selectedSalesOrderSyncDetails; let i=index">
        <div class="notifi-overlay__orders-item">
          <span class="notifi-overlay__orders-number">{{i+1}}</span>
          <strong>Patient ID</strong> - <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
            href="javascript:void(0)">{{item.appPatientId}}</a>,&nbsp;
          <strong>S/O ID</strong> - <a (click)="onNavigateBrightTreeSalesOrderUrl(item.appSalesOrderAppId)"
            href="javascript:void(0)">{{item.appSalesOrderAppId}}</a>
        </div>
        <!-- <div class="notifi-overlay__orders-item">
              <span class="notifi-overlay__orders-number">2</span><strong>Patient 1, P.ID</strong> - <a href="javascript:void(0)">10001</a>,&nbsp;<strong>S/O ID</strong> - 60001
          </div>
          <div class="notifi-overlay__orders-item">
              <span class="notifi-overlay__orders-number">3</span><strong>Patient 1, P.ID</strong> - <a href="javascript:void(0)">10001</a>,&nbsp;<strong>S/O ID</strong> - 60001
          </div>
          <div class="notifi-overlay__orders-item">
              <span class="notifi-overlay__orders-number">4</span><strong>Patient 1, P.ID</strong> - <a href="javascript:void(0)">10001</a>,&nbsp;<strong>S/O ID</strong> - 60001
           </div>
           <div class="notifi-overlay__orders-item">
              <span class="notifi-overlay__orders-number">5</span><strong>Patient 1, P.ID</strong> - <a href="javascript:void(0)">10001</a>,&nbsp;<strong>S/O ID</strong> - 60001
           </div>
           <div class="notifi-overlay__orders-item">
              <span class="notifi-overlay__orders-number">6</span><strong>Patient 1, P.ID</strong> - <a href="javascript:void(0)">10001</a>,&nbsp;<strong>S/O ID</strong> - 60001
           </div> -->
      </div>
    </div>
  </div>
</p-overlayPanel>

<!-- <p-dialog [(visible)]="displayPatientInfo" [modal]="true" appendTo="body" styleClass="header-patient-info-popup" maskStyleClass="header-patient-info-popup-mask">
  <div class="search-patient-popup">
    <h2 class="title">Patient Information</h2>
    
    <div class="patient-information">
      <table class="custom-table">
        <thead>
          <tr>
            <th>S/O ID</th>
            <th>Patient Name</th>
            <th>Classification Type</th>
            <th>Current Status </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1425434</td>
            <td>Hill, Bobby</td>
            <td>PAP Setup</td>
            <td>Awaiting First Call</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</p-dialog> -->
