import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppointmentDetailResponse, CurrentAvailabilityResponse, PreviousAvailabilityReponse, TherapistCustomAvailabilityResponseModelForMergeMessage } from '../../models/response/therapist/therapist-custom-availability-response-model';
import { DateFormatModel } from '../../models/common/date-format-model';
import * as moment from 'moment';
import { EnumModel } from '../../models/common/enum-model';

@Component({
  selector: 'app-override-merge-dialog',
  templateUrl: './override-merge-dialog.component.html',
  styleUrls: ['./override-merge-dialog.component.sass']
})
export class OverrideMergeDialogComponent implements OnInit {
  constructor(public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
  }
  therapistCustomAvailabilityMergeMessage: TherapistCustomAvailabilityResponseModelForMergeMessage;
  previousAvailabilityReponses: PreviousAvailabilityReponse[];
  currentAvailabilityReponses: CurrentAvailabilityResponse;
  appointmentDetail: AppointmentDetailResponse;
  appDateFormat: DateFormatModel;
  appointemntDatesStr: string
  appointemntCount: number = 0;
  appointemtnDates: string[]
  blockSlotDateStr: string;
  blockSlotDates: string[];
  blockCount: number = 0
  customDateStr: string;
  customCounts: number = 0;
  customDates: string[];
  weekDays: EnumModel[] = [];
  previousSelectedWeekDays: string = '';
  currentSelectedWeekDays: string = '';
  isAppointmentMore: boolean = false;
  isBlockMore: boolean = false;
  isCustomMore: boolean = false;
  ngOnInit() {
    if (this.dynamicDialogConfig) {
      this.therapistCustomAvailabilityMergeMessage = this.dynamicDialogConfig.data.therapistCustomAvailabilityMergeMessage;
      if (this.therapistCustomAvailabilityMergeMessage) {
        this.weekDays = this.dynamicDialogConfig?.data?.weekDays;
        //Previous
        this.previousAvailabilityReponses = this.therapistCustomAvailabilityMergeMessage?.previousAvailabilityReponses;
        if (this.previousAvailabilityReponses)
          this.previousAvailabilityReponses.forEach(z => {
            var previousDays = z?.selectedDays.split(',');
            if (previousDays && !z.isRecurring) {
              this.previousSelectedWeekDays = '';
              previousDays.forEach(x => {
                if (x != "") {
                  this.previousSelectedWeekDays = this.previousSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
                }
              })
              z.selectedDays = this.previousSelectedWeekDays.substring(1);
            }
            else if (z.isRecurring) {
              if (z?.recurringType == 2) {
                this.previousSelectedWeekDays = '';
                previousDays.forEach(x => {
                  if (x != "") {
                    this.previousSelectedWeekDays = this.previousSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
                  }
                })
                z.selectedDays = z.recurringTypeMessage + this.previousSelectedWeekDays.substring(1);
              }
              else {
                z.selectedDays = z.recurringTypeMessage;
              }
            }
          })

        //Current
        this.currentAvailabilityReponses = this.therapistCustomAvailabilityMergeMessage?.currentAvailabilityReponses;
        var currentDays = this.currentAvailabilityReponses?.selectedDays.split(',');
        if (currentDays && !this.currentAvailabilityReponses?.isRecurring) {
          currentDays.forEach(x => {
            if (x != "") {
              this.currentSelectedWeekDays = this.currentSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
            }
          })
          this.currentSelectedWeekDays = this.currentSelectedWeekDays.substring(1);
        }
        else if (this.currentAvailabilityReponses?.isRecurring) {
          if (this.currentAvailabilityReponses.recurringType == 2) {
            currentDays.forEach(x => {
              if (x != "") {
                this.currentSelectedWeekDays = this.currentSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
              }
            })
            this.currentSelectedWeekDays = this.currentAvailabilityReponses?.recurringTypeMessage + this.currentSelectedWeekDays.substring(1);
          }
          else {
            this.currentSelectedWeekDays = this.currentAvailabilityReponses?.recurringTypeMessage;
          }
        }


        //Delete 
        if (this.dynamicDialogConfig?.data?.selectedDays != undefined) {
          var deletepreviousDays = this.dynamicDialogConfig?.data?.selectedDays.split(',');
          if (deletepreviousDays && !this.dynamicDialogConfig?.data?.isRecurring) {
            deletepreviousDays.forEach(x => {
              if (x != "") {
                this.previousSelectedWeekDays = this.previousSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
              }
            })
            this.previousSelectedWeekDays = this.previousSelectedWeekDays.substring(1);
          }
          else if (this.dynamicDialogConfig?.data?.isRecurring) {
            if (this.dynamicDialogConfig.data.recurringType == 2) {
              deletepreviousDays.forEach(x => {
                if (x != "") {
                  this.previousSelectedWeekDays = this.previousSelectedWeekDays + ', ' + this.weekDays.find(y => y.value == parseInt(x)).name;
                }
              })
              this.previousSelectedWeekDays = this.dynamicDialogConfig?.data?.recurringTypeMessage + this.previousSelectedWeekDays.substring(1);
            }
            else {
              this.previousSelectedWeekDays = this.dynamicDialogConfig?.data?.recurringTypeMessage;
            }
          }
        }

        this.appointmentDetail = this.therapistCustomAvailabilityMergeMessage.appointmentDetail;

        if (this.appointmentDetail) {
          //Appointemnt
          this.isAppointmentMore = false;
          var appointmentDetails = this.appointmentDetail?.customAppointmentDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.appointmentDate).format("MM/DD/yyyy") == moment(val.appointmentDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.appointmentCount += val.appointmentCount
            return acc;
          }, []);
          if (appointmentDetails != undefined) {
            if (appointmentDetails?.length >= 2) {
              this.appointemntDatesStr = moment(appointmentDetails[0].appointmentDate).format("MM/DD/yyyy") + ' (' + appointmentDetails[0].appointmentCount + ')' + ', ' + moment(appointmentDetails[1].appointmentDate).format("MM/DD/yyyy") + ' (' + appointmentDetails[1].appointmentCount + ')'
              this.appointemtnDates = [];
              appointmentDetails.forEach(element => {
                this.appointemntCount = this.appointemntCount + element.appointmentCount;
                this.appointemtnDates.push(moment(element.appointmentDate).format("MM/DD/yyyy") + ' (' + element.appointmentCount + ')')
              });
              this.appointemtnDates.splice(0, 2);
              if (this.appointemtnDates.length > 0) {
                this.isAppointmentMore = true;
              }
            }
            else {
              this.appointemntCount = appointmentDetails[0].appointmentCount;
              this.appointemntDatesStr = moment(appointmentDetails[0].appointmentDate).format("MM/DD/yyyy") + '(' + appointmentDetails[0].appointmentCount + ')';
            }
          }

          //Block
          this.isBlockMore = false;
          var blockDetails = this.appointmentDetail?.customBlockDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.blockSlotDate).format("MM/DD/yyyy") == moment(val.blockSlotDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.blockSlotCount += val.blockSlotCount
            return acc;
          }, []);
          if (blockDetails != undefined) {
            if (blockDetails?.length >= 2) {
              this.blockSlotDateStr = moment(blockDetails[0].blockSlotDate).format("MM/DD/yyyy") + ' (' + blockDetails[0].blockSlotCount + ')' + ', ' + moment(blockDetails[1].blockSlotDate).format("MM/DD/yyyy") + ' (' + blockDetails[1].blockSlotCount + ')'
              this.blockSlotDates = [];
              blockDetails.forEach(element => {
                this.blockCount = this.blockCount + element.blockSlotCount;
                this.blockSlotDates.push(moment(element.blockSlotDate).format("MM/DD/yyyy") + ' (' + element.blockSlotCount + ')')
              });
              this.blockSlotDates.splice(0, 2);
              if (this.blockSlotDates.length > 0) {
                this.isBlockMore = true;
              }
            }
            else {
              this.blockCount = blockDetails[0].blockSlotCount
              this.blockSlotDateStr = moment(blockDetails[0].blockSlotDate).format("MM/DD/yyyy") + '(' + blockDetails[0].blockSlotCount + ')';
            }
          }

          //CustomDates
          this.isCustomMore = false;
          var customDetail = this.appointmentDetail?.customDateDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.customDate).format("MM/DD/yyyy") == moment(val.customDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.customCount += val.customCount
            return acc;
          }, []);
          if (customDetail != undefined) {
            if (customDetail?.length >= 2) {
              this.customDateStr = moment(customDetail[0].customDate).format("MM/DD/yyyy") + ' (' + customDetail[0].customCount + ')' + ', ' + moment(customDetail[1].customDate).format("MM/DD/yyyy") + ' (' + customDetail[1].customCount + ')'
              this.customDates = [];
              customDetail.forEach(element => {
                this.customCounts = this.customCounts + element.customCount;
                this.customDates.push(moment(element.customDate).format("MM/DD/yyyy") + ' (' + element.customCount + ')')
              });
              this.customDates.splice(0, 2);
              if (this.customDates.length > 0) {
                this.isCustomMore = true;
              }
            }
            else {
              this.customCounts = customDetail[0].customCount
              this.customDateStr = moment(customDetail[0].customDate).format("MM/DD/yyyy") + '(' + customDetail[0].customCount + ')';
            }
          }
        }
        else {
          this.isAppointmentMore = false;
          var appointmentDetails = this.dynamicDialogConfig.data.therapistCustomAvailabilityMergeMessage?.customAppointmentDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.appointmentDate).format("MM/DD/yyyy") == moment(val.appointmentDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.appointmentCount += val.appointmentCount
            return acc;
          }, []);
          if (appointmentDetails != undefined) {
            if (appointmentDetails?.length >= 2) {
              this.appointemntDatesStr = moment(appointmentDetails[0].appointmentDate).format("MM/DD/yyyy") + ' (' + appointmentDetails[0].appointmentCount + ')' + ', ' + moment(appointmentDetails[1].appointmentDate).format("MM/DD/yyyy") + ' (' + appointmentDetails[1].appointmentCount + ')'
              this.appointemtnDates = [];
              appointmentDetails.forEach(element => {
                this.appointemntCount = this.appointemntCount + element.appointmentCount;
                this.appointemtnDates.push(moment(element.appointmentDate).format("MM/DD/yyyy") + ' (' + element.appointmentCount + ')')
              });
              this.appointemtnDates.splice(0, 2);
              if (this.appointemtnDates.length > 0) {
                this.isAppointmentMore = true;
              }
            }
            else {
              this.appointemntCount = appointmentDetails[0].appointmentCount;
              this.appointemntDatesStr = moment(appointmentDetails[0].appointmentDate).format("MM/DD/yyyy") + '(' + appointmentDetails[0].appointmentCount + ')';
            }
          }
          //Block
          this.isBlockMore = false;
          var blockDetails = this.dynamicDialogConfig.data.therapistCustomAvailabilityMergeMessage?.customBlockDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.blockSlotDate).format("MM/DD/yyyy") == moment(val.blockSlotDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.blockSlotCount += val.blockSlotCount
            return acc;
          }, []);
          if (blockDetails != undefined) {
            if (blockDetails?.length >= 2) {
              this.blockSlotDateStr = moment(blockDetails[0].blockSlotDate).format("MM/DD/yyyy") + ' (' + blockDetails[0].blockSlotCount + ')' + ', ' + moment(blockDetails[1].blockSlotDate).format("MM/DD/yyyy") + ' (' + blockDetails[1].blockSlotCount + ')'
              this.blockSlotDates = [];
              blockDetails.forEach(element => {
                this.blockCount = this.blockCount + element.blockSlotCount;
                this.blockSlotDates.push(moment(element.blockSlotDate).format("MM/DD/yyyy") + ' (' + element.blockSlotCount + ')')
              });
              this.blockSlotDates.splice(0, 2);
              if (this.blockSlotDates.length > 0) {
                this.isBlockMore = true;
              }
            }
            else {
              this.blockCount = blockDetails[0].blockSlotCount
              this.blockSlotDateStr = moment(blockDetails[0].blockSlotDate).format("MM/DD/yyyy") + '(' + blockDetails[0].blockSlotCount + ')';
            }
          }

          //CustomDates
          this.isCustomMore = false;
          var customDetail = this.dynamicDialogConfig.data.therapistCustomAvailabilityMergeMessage?.customDateDetails?.reduce((acc: any, val: any) => {
            const element = acc.find(x => moment(x.customDate).format("MM/DD/yyyy") == moment(val.customDate).format("MM/DD/yyyy"))
            if (!element)
              acc.push(val)
            else
              element.customCount += val.customCount
            return acc;
          }, []);
          if (customDetail != undefined) {
            if (customDetail?.length >= 2) {
              this.customDateStr = moment(customDetail[0].customDate).format("MM/DD/yyyy") + ' (' + customDetail[0].customCount + ')' + ', ' + moment(customDetail[1].customDate).format("MM/DD/yyyy") + ' (' + customDetail[1].customCount + ')'
              this.customDates = [];
              customDetail.forEach(element => {
                this.customCounts = this.customCounts + element.customCount;
                this.customDates.push(moment(element.customDate).format("MM/DD/yyyy") + ' (' + element.customCount + ')')
              });
              this.customDates.splice(0, 2);
              if (this.customDates.length > 0) {
                this.isCustomMore = true;
              }
            }
            else {
              this.customCounts = customDetail[0].customCount
              this.customDateStr = moment(customDetail[0].customDate).format("MM/DD/yyyy") + '(' + customDetail[0].customCount + ')';
            }
          }
        }
      }
    }
  }

  onOverRide() {
    this.dynamicDialogRef.close(true);
  }
  OnMerge() {
    this.dynamicDialogRef.close('Merge');
  }
  OnUpdate() {
    this.dynamicDialogRef.close('Update');
  }
  OnDelete() {
    this.dynamicDialogRef.close(true);
  }
  onNo() {
    this.dynamicDialogRef.close(false);
  }
  RoadHomeOverRide() {
    this.dynamicDialogRef.close(true);
  }
}
