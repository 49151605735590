import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, EventEmitter, Inject, Injectable, Input, LOCALE_ID, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CalendarUtils, CalendarWeekViewComponent, CalendarWeekViewBeforeRenderEvent, DateAdapter, getWeekViewPeriod } from 'angular-calendar';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { WeekView, GetWeekViewArgs, WeekViewTimeEvent, EventColor, CalendarEvent, WeekViewHourColumn } from 'calendar-utils';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { TheraistAppointmentService } from 'src/app/therapist/services/theraistappointment.service';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { Subject } from 'rxjs';
import { finalize, takeUntil, map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
//import { debug } from 'util';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { environment } from 'src/environments/environment';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { SalesOrderInsuranceInfoResponseModel } from 'src/app/shared/models/response/appointment/salesorder-insurance-info-response-model'
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { DebugHelper } from 'protractor/built/debugger';
export interface User {
  id: number;
  name: string;
  field: string;
  color: EventColor;
}

interface DayViewScheduler extends WeekView {
  users: User[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
  users: User[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  menuopen: boolean = false;
  menuOpenEvent() {
    this.menuopen = !this.menuopen;
  }
  getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {

    const { period } = super.getWeekView(args);
    const view: DayViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      users: [...args.users],
    };

    view.users.forEach((user, columnIndex) => {

      const events = args.events.filter(
        (event) => event.meta.appointmentInfoReponseModel.field === user.field
      );
      const columnView = super.getWeekView({
        ...args,
        events,
      });
      view.hourColumns.push(columnView.hourColumns[0]);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }
}

@Component({
  selector: 'mwl-day-view-scheduler',
  templateUrl: './day-view-scheduler.component.html',
  styleUrls: ['./day-view-scheduler.component.sass'],
  providers: [DayViewSchedulerCalendarUtils],
})
export class DayViewSchedulerComponent extends CalendarWeekViewComponent implements OnChanges {

  title = 'Mark Wood';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  @Input() users: User[] = [];

  @Input() events: CalendarEvent[] = [];

  @Input() salesOrderStatus: WIPStatesResponseModel[] = [];
  @Output() eventClickedOverrided = new EventEmitter();
  @Output() eventMouseEnterCalled = new EventEmitter();

  //@Output() eventClicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @Output() userChanged = new EventEmitter();
  appointment: AppointmentInfoReponseModel;
  viewDate: Date = new Date();
  view: DayViewScheduler;
  private appointmentsalesOrderStatusMenuitems: MenuItem[];
  headers: HttpHeaders;
  clientId: string;
  Authorization: string;
  selectedAppointmentOrderStatus: WIPStatesResponseModel;
  daysInWeek = 1;
  loading: boolean;
  refresh: Subject<any> = new Subject();

  therapistId: number;
  @Input() dayStartHour: number;
  @Input() dayEndHour: number;
  @Input() hourSegments: number;
  @Input() hourSegmentHeight: number;
  @Input() patientBrighttreeURL: string;
  @Input() salesOrderBrighttreeURL: string;
  facilityInfo: FacilityInfoResponseModel;
  activeDayIsOpen: boolean = true;
  @Input() salesOrderStatusCurrent: WIPStatesResponseModel[];
  eventSalesOrderSelectedStatusCurrent: WIPStatesResponseModel;
  @Output() onPopupSelection = new EventEmitter();
  @Output() onAppointmentCommentSelection = new EventEmitter();
  @Input() canUpdateStatue: boolean;
  @Input() facilityTimeZone: string;
  comment: string;
  enabledTitle: any;
  brightreeInternalUserId: string;
  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: DayViewSchedulerCalendarUtils,
    private webStorageService: WebStorageService,
    private http: HttpClient,
    private theraistAppointmentService: TheraistAppointmentService,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter
  ) {
    super(cdr, utils, locale, dateAdapter);
    this.appointmentsalesOrderStatusMenuitems = new Array();

    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      this.clientId = currentClientConfig.companyId.toString();
    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    this.brightreeInternalUserId = currentUserToken.crmBrightreeUserId.toString();
    if (currentUserToken && currentUserAuthToken.token) {
      this.Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }

    this.headers = new HttpHeaders()
      .set("clientId", this.clientId).append("Authorization", this.Authorization);
  }

  async ngOnInit() {

    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.salesOrderInsuranceInfo = new Array();

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;

    }
    else {
      this.therapistId = 0;
    }
  }

  eventClickedOverride(view, event, sourceEvent: CalendarEvent) {
    if(event.meta.appointmentInfoReponseModel.field == 'SchWIPStatus' ? false : event.meta.appointmentInfoReponseModel.field == 'SchComments' ? false : true){
      this.eventClickedOverrided.emit({ view: view, action: event, event: event });
    }
  }
  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: string) {
   
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);

 
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: string) {

    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    
    window.open(patienKeyUrl, '_blank')
  }



  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.users) {
      this.refreshBody();
      this.emitBeforeViewRender();
    }
  }

  mouseEnterOnEventClicked(view, event) {
    this.eventMouseEnterCalled.emit({ view: view, event: event });
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.users.length);
  }

  dragMove(dayEvent: WeekViewTimeEvent, dragEvent: DragMoveEvent) {
    if (this.snapDraggedEvents) {
      const newUser = this.getDraggedUserColumn(dayEvent, dragEvent.x);
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        { ...dragEvent, x: 0 },
        this.dayColumnWidth,
        true
      );
      const originalEvent = dayEvent.event;
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta, user: newUser },
      };
      const tempEvents = this.events.map((event) => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );
    }
    this.dragAlreadyMoved = true;
  }

  dragEnded(
    weekEvent: WeekViewTimeEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ) {
    super.dragEnded(
      weekEvent,
      {
        ...dragEndEvent,
        x: 0,
      },
      dayWidth,
      useY
    );
    const newUser = this.getDraggedUserColumn(weekEvent, dragEndEvent.x);
    if (newUser && newUser !== weekEvent.event.meta.user) {
      this.userChanged.emit({ event: weekEvent.event, newUser });
    }
  }

  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      users: this.users,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      ),
    });
  }

  eventClick(event: CalendarEvent) {
    let AppoinmentId = event.meta.appointmentInfoReponseModel.id;
    this.appointment = event.meta.appointmentInfoReponseModel.appointment;
  }

  beforeWeekOrDayViewRender(event: CalendarWeekViewBeforeRenderEvent) {
    this.hourColumns = event.hourColumns;
    this.addSelectedDayViewClass();
  }

  SegmentClicked(date: Date) {

    this.selectedDayViewDate = date;
    this.addSelectedDayViewClass();
  }

  hourColumns: WeekViewHourColumn[];
  selectedDayViewDate: Date;
  private addSelectedDayViewClass() {
    this.hourColumns.forEach((column) => {
      column.hours.forEach((hourSegment) => {
        hourSegment.segments.forEach((segment) => {
          delete segment.cssClass;
          if (
            this.selectedDayViewDate &&
            segment.date.getTime() === this.selectedDayViewDate.getTime()
          ) {
            segment.cssClass = 'selected-row';
          }
        });
      });
    });
  }

  private getDraggedUserColumn(dayEvent: WeekViewTimeEvent, xPixels: number) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.users.findIndex(
      (user) => user === dayEvent.event.meta.user
    );
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.users[newIndex];
  }

  changeWipStatus(selectedEvent :any,event :any){
    selectedEvent.eventSalesOrderSelectedStatus= this.eventSalesOrderSelectedStatusCurrent== undefined ? this.salesOrderStatusCurrent.find(x => x.wipStatesName == event.target.value) : this.eventSalesOrderSelectedStatusCurrent;
    this.onPopupSelection.emit({ event: selectedEvent , event1: event});
  }

  savestatusComment(selectedEvent :any){
    selectedEvent.eventSalesOrderSelectedNote = selectedEvent.title;
    this.onAppointmentCommentSelection.emit({ event: selectedEvent});
  }
  changeStatusComment(event :any){
    this.comment=event.target.value;
  }

  clearStatusComment(event: any) {
    this.enabledTitle = '';
    //this.onenableClickNote(true)
  }

  enableDisableCommentSection(title: any) {
    if (this.enabledTitle == title) {
      return true;
      this.dayColumnWidth = 500;
    }
    return false;
  }
  
  enabledAddNote() {
    if (this.enabledTitle)
      return false;
    return true;
  }
  onenableClickNote(title: any) {
    this.enabledTitle = title;
  }
}

