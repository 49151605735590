import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsPluginResponseModel, PluginEmailModel } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import * as moment from 'moment';



@Component({
  selector: 'app-plugins',
  templateUrl: './plugins.component.html',
  styleUrls: ['./plugins.component.sass']
})
export class PluginsComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsPluginResponseModel: SettingsPluginResponseModel;
  settingsPluginRequestModel: SettingsPluginRequestModel;
  pluginModel: PluginEmailModel;
  loggedInUserId: number;
  title = 'Plugin Settings';
  clientId: number;
  isVoiceCallRecordingDisplay: boolean = false;
  customStartTime: string;
  customEndTime: string;
  settingsResponseModel: Array<SettingsResponseModel>;
  settingsRequestModel: Array<SettingsRequestModel>;

  formFields = {
    fromEmailAddress: 'fromEmailAddress',
    fromName: 'fromName',
    smtpHost: 'smtpHost',
    smtpPort: 'smtpPort',
    typeOfEncryption: 'typeOfEncryption',
    smtpUsername: 'smtpUsername',
    smtpPassword: 'smtpPassword'
  };

  validationMessages = {
    fromEmailAddress: {
      required: 'From Email Address is required',
      email: 'Enter valid from email address',
    },
    fromName: {
      required: 'From name is required',
      //validCharacters: 'Enter valid from name'
    },
    smtpHost: {
      required: 'SMTP host is required',
      validHostName: 'Enter valida host name',
    },
    smtpPort: {
      required: 'SMTP port is required',
      validNumber: 'Only numbers are allowed',
    },
    typeOfEncryption: {
      required: 'Type of encryption is required',
      //validCharacters: 'Enter valid contact name'
    },
    smtpUsername: {
      required: 'SMTP username is required',
      //validCharacters: 'Enter valid contact name'
    },
    smtpPassword: {
      required: 'SMTP password is required',
      //validCharacters: 'Enter valid contact name'
    },
  };


  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
    var clientdetail = this.webStorageService.getClientConfig();
    this.clientId = Number(clientdetail.companyId);

    var channelArray: Array<Number> = [9,12,7,13,14,15,18,20,21,1008]; //Allow to use Nums and Preprod clients
    this.isVoiceCallRecordingDisplay = channelArray.includes(this.clientId) ? true : false;
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getPluginConfiguration();
  }

  private getPluginConfiguration() {
    this.loading = true;

    this.settingsService.getPluginConfiguration(Constants.pluginSettingEmail)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, async () => {
          this.settingsPluginResponseModel = result.responseModel;
          this.setValuesToForm();
          await this.getApplicationSettings()
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }


  private async getApplicationSettings() {
    this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, async () => {
          this.settingsResponseModel = result.responseModel;
          this.settingsResponseModel.forEach(x => {
            if (x.schSettingKey == Constants.SettingKeys.EmailSendStartTime)
              this.customStartTime = x.schSettingValue//moment(x.schSettingValue).format("hh:mm").toString();
            if (x.schSettingKey == Constants.SettingKeys.EmailSendEndTime)
              this.customEndTime = x.schSettingValue//moment(x.schSettingValue).format("hh:mm").toString();
          })
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onSubmit() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }

    if (this.customStartTime != undefined && this.customEndTime != undefined) {
      var _startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
      var _endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

      if (_endTime == _startTime) {
        this.showWarningMessage('Start Time & End Time should not be equal');
        return;
      }
      else if (_endTime < _startTime) {
        this.showWarningMessage('End Time should be greater than Start Time');
        return;
      }
    }

    var applicationPluginRequestModel = this.getValuesFromForm();
    const manageApplicationGeneral = this.settingsService.updatePluginConfiguration(applicationPluginRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(async (result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          await this.saveSettings();
          this.showSuccessMessage('Plugin Email settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async saveSettings() {

    var lstSettingsResponseModel = new Array<SettingsResponseModel>();

    var EmailSendStartTime = new SettingsResponseModel();
    EmailSendStartTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EmailSendStartTime);
    EmailSendStartTime.schSettingValue = moment(this.customStartTime, 'hh:mm:ss').format("HH:mm:ss");;
    EmailSendStartTime.dateTimeLastModified = new Date();
    EmailSendStartTime.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(EmailSendStartTime);

    var EmailSendEndTime = new SettingsResponseModel();
    EmailSendEndTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EmailSendEndTime);
    EmailSendEndTime.schSettingValue = moment(this.customEndTime, 'hh:mm:ss').format("HH:mm:ss");;
    EmailSendEndTime.dateTimeLastModified = new Date();
    EmailSendEndTime.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(EmailSendEndTime);

    const manageApplicationGeneral = this.settingsService.updateSettings(lstSettingsResponseModel);
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {

        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private getValuesFromForm(): SettingsPluginRequestModel {
    const form = this.form;
    var settingPluginRequestModel = new SettingsPluginRequestModel();

    settingPluginRequestModel = this.settingsPluginResponseModel;

    this.pluginModel.AutoResponderEmailAddress = form.get(this.formFields.fromEmailAddress).value;
    this.pluginModel.ContactName = form.get(this.formFields.fromName).value;
    this.pluginModel.SMTPServer = form.get(this.formFields.smtpHost).value;
    this.pluginModel.SMTPPort = form.get(this.formFields.smtpPort).value;
    this.pluginModel.EnableSsl = form.get(this.formFields.typeOfEncryption).value == "SSL/TLS" || form.get(this.formFields.typeOfEncryption).value == 'STARTTLS' ? "true" : "false";
    this.pluginModel.SMTPUserName = form.get(this.formFields.smtpUsername).value;
    this.pluginModel.SMTPPassword = form.get(this.formFields.smtpPassword).value;
    this.pluginModel.EncryptionType = form.get(this.formFields.typeOfEncryption).value;

    settingPluginRequestModel.appPluginSettingsValue = JSON.stringify(this.pluginModel);
    settingPluginRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    return settingPluginRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsPluginResponseModel) {
      return;
    }

    this.pluginModel = JSON.parse(this.settingsPluginResponseModel.appPluginSettingsValue);

    const form = this.form;

    form.get(this.formFields.fromEmailAddress).setValue(this.pluginModel.AutoResponderEmailAddress);
    form.get(this.formFields.fromName).setValue(this.pluginModel.ContactName);
    form.get(this.formFields.smtpHost).setValue(this.pluginModel.SMTPServer);
    form.get(this.formFields.smtpPort).setValue(this.pluginModel.SMTPPort);
    form.get(this.formFields.typeOfEncryption).setValue(this.pluginModel.EnableSsl == "true" ? "SSL/TLS" : "None");
    form.get(this.formFields.smtpUsername).setValue(this.pluginModel.SMTPUserName);
    form.get(this.formFields.smtpPassword).setValue(this.pluginModel.SMTPPassword);
    form.get(this.formFields.typeOfEncryption).setValue(this.pluginModel.EncryptionType);

  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.fromEmailAddress, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.fromName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.smtpHost, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.hostName], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.smtpPort, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.typeOfEncryption, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.smtpUsername, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.smtpPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }
}
