<div class="detail-sidebar-outer">
    <div class="detail-sidebar-outer__title-block">
        <div class="detail-sidebar-outer__title-block-left">
            <h2 class="detail-sidebar-outer__title">{{patientHeaderDetail.patientName}}</h2>


        </div>

    </div>

    <div class="detail-sidebar-outer__patient-information">
        <div class="detail-sidebar-outer__scroll">
            <h3 class="detail-sidebar-outer__patient-information-title">Appointment Information</h3>
            <ul class="detail-sidebar-outer__info-block appointment-info">
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Date & Time</label>
                    <div class="detail-sidebar-outer__date-time-zone">
                        {{patientHeaderDetail.appointmentDate | date:appDateFormat.dateFormat | lowercase}}, <span
                            class="time">{{patientHeaderDetail.appointmentDate|date:'shortTime' | lowercase}}</span>
                        <span class="detail-sidebar-outer__time-zone">{{patientHeaderDetail.patientTimeZone|
                            timezone}}</span>
                    </div>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Duration</label>
                    <div class="detail-sidebar-outer__title-block-right">
                        <span class="duration">{{patientHeaderDetail.duration}}</span>
                    </div>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Type</label>
                    <div class="detail-sidebar-outer__title-block-right">
                        <span class="facility-name">{{patientHeaderDetail.visitType}}</span>
                    </div>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Status</label>
                    <div class="detail-sidebar-outer__title-block-right status-clumn" style="display: flex;">
                        <span class="facility-name">{{patientHeaderDetail.status}}</span>

                        <a href="javascript:void(0)" pTooltip="Update Status" *ngIf="canUpdateStatue && !isFutureAppt" (click)="appointmentStatusDialog()">
                            <svg width="16px" height="16px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <g fill-rule="evenodd">                                
                                <g fill-rule="nonzero">                                
                                <path d="M0 7a7 7 0 1114 0A7 7 0 010 7z"/>                                
                                <path fill="#FFF" d="M13 7A6 6 0 101 7a6 6 0 0012 0z" style="fill: var(--svg-status-bg, #fff);"/>                                
                                </g>                                
                                <circle cx="7" cy="7" r="1"/>                                
                                <circle cx="10" cy="7" r="1"/>                                
                                <circle cx="4" cy="7" r="1"/>                                
                                </g>                                
                            </svg>
                        </a>
                    </div>
                </li>

                <!-- <li class="detail-sidebar-outer__info-item">
                    <button class="btn btn--primary" *ngIf="canUpdateStatue" (click)="appointmentStatusDialog()">Update
                        Status</button>
                </li> -->
            </ul>

            <h3 class="detail-sidebar-outer__patient-information-title">Patient Information</h3>
            <ul class="detail-sidebar-outer__info-block">
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">S.O. ID / Patient ID</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.crmSalesorderAppId}} /
                        {{salesOrder?.crmPatientId}}</span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Date of Birth</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.patientInfo?.crmPatientBirthDate | date:
                        appDateFormat.dateFormat}}</span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Email ID</label>
                    <a class="detail-sidebar-outer__info-text"
                        href="mailto:richard.kent@example.com">{{salesOrder?.patientInfo?.crmPatientEmailAddress}}</a>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Mobile Number</label>
                    <a class="detail-sidebar-outer__info-text" href="tel:1234567890"
                        mask="999-999-9999">{{patientMobileNumber| phone}}</a>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Phone / Landline Number</label>
                    <a class="detail-sidebar-outer__info-text" href="tel:4567890123" mask="999-999-9999">{{patientPhone|
                        phone}}</a>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Order Note</label>
                    <span class="detail-sidebar-outer__info-text">
                        {{salesOrder?.crmSalesorderOrderNote}}
                    </span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Delivery Note</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.crmSalesorderDeliveryNote}}</span>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Home Address</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.patientInfo?.crmPatientAddress ||
                        '-'}}</span>
                </li>
            </ul>

            <h3 class="detail-sidebar-outer__patient-information-title">Doctor Information</h3>
            <ul class="detail-sidebar-outer__info-block">
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Name</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.doctorInfo?.crmDoctorLastName}},
                        {{salesOrder?.doctorInfo?.crmDoctorFirstName}}</span>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Mobile Number</label>
                    <a class="detail-sidebar-outer__info-text"
                        href="tel:1234567890">{{salesOrder?.doctorInfo?.crmDoctorContactNo
                        | phone}}</a>
                </li>
            </ul>

            <h3 class="detail-sidebar-outer__patient-information-title">Device Information</h3>
            <ul class="detail-sidebar-outer__info-block">
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Classification Type</label>
                    <span class="detail-sidebar-outer__info-text">{{salesOrder?.crmSalesorderClassification}}</span>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Due Amount</label>
                    <span class="detail-sidebar-outer__info-text">${{salesOrder?.amountDue}}</span>
                </li>
            </ul>

            <h3 class="detail-sidebar-outer__patient-information-title">Insurance & Policy Information</h3>
            <ul class="detail-sidebar-outer__info-block border-none">
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Policy Number</label>
                    <span
                        class="detail-sidebar-outer__info-text">{{salesOrder?.salesOrderInsuranceInfo[0]?.crmInsurancePolicyNumber}}</span>
                </li>

                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Company</label>
                    <span
                        class="detail-sidebar-outer__info-text">{{salesOrder?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName}}</span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Effective Date</label>
                    <span
                        class="detail-sidebar-outer__info-text">{{salesOrder?.salesOrderInsuranceInfo[0]?.crmInsurancePolicyEffectiveDate
                        | date: appDateFormat.dateFormat}}</span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Insurance Verified</label>
                    <span
                        class="detail-sidebar-outer__info-text">{{salesOrder?.salesOrderInsuranceInfo[0]?.crmInsuranceVerified
                        ? 'Yes' : 'No'}}</span>
                </li>
                <li class="detail-sidebar-outer__info-item">
                    <label class="detail-sidebar-outer__info-label">Coverage Verified</label>
                    <span
                        class="detail-sidebar-outer__info-text">{{salesOrder?.salesOrderInsuranceInfo[0]?.crmCoverageVerified
                        ? 'Yes' : 'No'}}</span>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- <p-toast position="bottom-right"></p-toast> -->

<p-dialog [(visible)]="AppointmentStatusUpdate" appendTo="body" [modal]="true" class="patient-popup"
    maskStyleClass="update-appointment-status" [baseZIndex]="10000">
    <div class="form-content-outer__content update-appointment-information">
        <h2>Update Appointment Information</h2>
        <!-- <h3 *ngIf="canUpdateStatue" class="detail-sidebar-outer__patient-information-title">Update Appointment Information</h3> -->
        <div class="form">
            <div class="form__group" *ngIf="!isStatusUpdated">
                <label class="form__label">Appointment Status</label>
                <p-dropdown class="select-box" [options]="wipStatusModel" placeholder="Select"
                    optionLabel="wipStatesName" [(ngModel)]="schWIPStatus" id="statusDropdown"
                    (onChange)="changeWipStatus($event)">
                </p-dropdown>
            </div>
            <div class="form__group {{displayStatus}}" *ngIf="isStatusUpdated">
                {{displayStatus}}
            </div>
            <div class="form__group">
                <label class="form__label">Appointment Note</label>
                <textarea class="form__textarea" id="w3review" name="w3review" rows="4" cols="50"
                    [(ngModel)]="appointmentNote"></textarea>
            </div>
            <div style="margin-bottom: 2rem !important;" class="therapist-calendar-status" *ngIf="!isStatusUpdated">
                <p-checkbox name="groupnamecomplete"
                    label="If box checked, the WIP State will not be updated in Brightree"
                    [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
                    [ngModelOptions]="{standalone: true}">
                </p-checkbox>
            </div>
            <div class="form__buttons">
                <button class="btn btn--primary"
                    (click)="onUpdateAppointment(salesOrder?.schAppointmentId)">Update</button>
            </div>
        </div>
    </div>


    <div class="loader-block" *ngIf="loading" style="z-index: 10000 !important;">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
            animationDuration=".8s">
        </p-progressSpinner>
    </div>

</p-dialog>