<!--<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                     animationDuration=".8s">
  </p-progressSpinner>
</div>-->
<!--<ng-template #currentTimeMarkerTemplate let-columnDate="columnDate" let-dayStartHour="dayStartHour"
             let-dayStartMinute="dayStartMinute" let-dayEndHour="dayEndHour" let-dayEndMinute="dayEndMinute"
             let-isVisible="isVisible" let-topPx="topPx">
  <div class="cal-current-time-marker" *ngIf="isVisible && showMarker" [style.top.px]="topPx"></div>
</ng-template>-->
<ng-template #customTitleTemplate let-event="event" let-view="view">
  <span *ngIf="event.meta.appointmentInfoReponseModel.field == 'SchWIPStatus' ? false : event.meta.appointmentInfoReponseModel.field == 'SchComments' ? false : event.meta.appointmentInfoReponseModel.field == 'crmPatientName'? false:event.meta.appointmentInfoReponseModel.field == 'CrmSalesorderAppId'?false:true " class="cal-event-title event-pointer " tooltip="{{ event.title }}">{{ event.title }}</span>
  <span class="cal-event-title event-pointer" *ngIf="event.meta.appointmentInfoReponseModel.field == 'SchWIPStatus' && event.meta.appointmentInfoReponseModel.title !=''" style="text-align: center;" tooltip="{{ event.title }}">{{ event.title }}</span>
  <ng-container *ngIf="canUpdateStatue && event.meta.appointmentInfoReponseModel.id>0">
    <ng-container *ngIf="event.meta.appointmentInfoReponseModel.field == 'SchWIPStatus' && event.meta.appointmentInfoReponseModel.title ==''">
      <div class="therapist-calendar-status therapist-calendar-status--selectbox">
        <select class="form__select" (change)="changeWipStatus(event,$event)">
          <option value="0" selected>Select</option>
          <option *ngFor="let summary of salesOrderStatusCurrent" value={{summary.wipStatesName}}>
            {{summary.wipStatesName}}
          </option>
        </select>
      </div>
    </ng-container>
    <ng-container *ngIf="event.meta.appointmentInfoReponseModel.field == 'SchComments'">
      <div class="add-note-btn" *ngIf="enabledAddNote()"><a href="javascript:void(0)" (click)="onenableClickNote(event.meta.appointmentInfoReponseModel.id)">Add Note</a></div>
      <div class="therapist-calendar-status" *ngIf="enableDisableCommentSection(event.meta.appointmentInfoReponseModel.id)">
        <textarea class="form__textarea" pInputTextarea maxlength="500" [(ngModel)]="event.title" (change)="changeStatusComment($event)"></textarea>
        <div class="icons-block">
          <a href="javaScript:void(0);" title="Save" (click)="savestatusComment(event)"><img src="assets/img/save-ic.svg" class="icons-block__ic" alt="" width="22" height="25" /></a>
          <a href="javaScript:void(0);" title="Close" (click)="clearStatusComment(event)"><img src="assets/img/close-ic.svg" class="icons-block__ic" alt="" width="22" height="25" /></a>
        </div>
      </div>
    </ng-container>

  </ng-container>
  <ng-container *ngIf="event.meta.appointmentInfoReponseModel.id>0">
    <ng-container *ngIf="event.meta.appointmentInfoReponseModel.field == 'crmPatientName'">
      <span class="cal-event-title event-pointer" tooltip="{{event.title}}">
        <a (click)="onNavigateBrightTreePatientUrl(event.meta.appointmentInfoReponseModel.rcmPatientBrightreeID)" href="javascript:void(0)">
          {{event.title}}<br />
          <small>{{event.startLable | date:'shortTime' | lowercase}} to {{event.endLabel | date:'shortTime' | lowercase}} | {{event.crmPatientTimeZone}}</small>
        </a>
      </span>
    </ng-container>
    <ng-container *ngIf="event.meta.appointmentInfoReponseModel.field == 'CrmSalesorderAppId'">
      <span class="cal-event-title event-pointer" tooltip="{{event.title}}">
        <a (click)="onNavigateBrightTreeSalesOrderUrl(event.title)"
           href="javascript:void(0)" tooltip="{{event.title}}">{{event.title}}</a>
      </span>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #customHourSegmentTemplate let-segment="segment" let-locale="locale">
  <div class="cal-cell-top"
       [class.cal-hour-start]="segment.isStart"
       [class.cal-after-hour-start]="!segment.isStart"
       [ngClass]="segment.cssClass">
    <div class="cal-time">{{ segment.date | date:'shortTime':locale }} </div>

  </div>
</ng-template>
<ng-template #tooltipTemplate let-event="event" let-view="view">
  <span style="font-size: 13px !important ; z-index: 99999 !important; background-color: #333 !important;
  color: #fff !important; padding: .75rem 1.2rem !important; border-radius: 3px;" *ngIf="event.meta.appointmentInfoReponseModel.field == 'CrmAddress' && event?.meta?.appointmentInfoReponseModel?.appointmentType == 'Facility Visit'" class="cal-event-title event-pointer" tooltip="{{ event?.meta?.appointmentInfoReponseModel?.title }}"> {{ event?.meta?.appointmentInfoReponseModel?.title }}</span>
  <span style="font-size: 13px !important ; z-index: 99999 !important; background-color: #333 !important;
  color: #fff !important; padding: .75rem 1.2rem !important; border-radius: 3px;" *ngIf="event.meta.appointmentInfoReponseModel.field == 'CrmAddress' && event?.meta?.appointmentInfoReponseModel?.appointmentType != 'Facility Visit'" class="cal-event-title event-pointer" tooltip="{{ event?.meta?.appointmentInfoReponseModel?.crmPatientAddress }}"> {{ event?.meta?.appointmentInfoReponseModel?.crmPatientAddress }}</span>


</ng-template>

<div class="calendar-outer-ui-scrollpanel-x therapist-calendar-block">
  <div class="cal-week-view" role="grid">
    
      <div class="cal-day-headers" role="row">
        <div class="cal-header">
          <div class="time-zone-filter">
            <strong tooltip="{{facilityTimeZone}}">{{facilityTimeZone}}</strong>
          </div>
        </div>
          <div class="cal-header therapist-header-module" *ngFor="let user of users; trackBy: trackById" tabindex="0" role="columnheader">
              <strong tooltip="{{user.name}}">{{ user.name }}</strong>
          </div>
      </div>
    <div class="cal-all-day-events" #allDayEventsContainer *ngIf="view.allDayEventRows.length > 0" mwlDroppable (dragEnter)="dragEnter('allDay')" (dragLeave)="dragLeave('allDay')">
      <div class="cal-day-columns">
        <div class="cal-time-label-column" [ngTemplateOutlet]="allDayEventsLabelTemplate"></div>
        <div class="cal-day-column" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" mwlDroppable dragOverClass="cal-drag-over" (drop)="eventDropped($event, day.date, true)"
             (dragEnter)="dateDragEnter(day.date)"></div>
      </div>
      <div *ngFor="let eventRow of view.allDayEventRows; trackBy: trackById" #eventRowContainer class="cal-events-row">
        <div *ngFor="let allDayEvent of eventRow.row;
                              trackBy: trackByWeekAllDayEvent" #event class="cal-event-container" [class.cal-draggable]="allDayEvent.event.draggable && allDayEventResizes.size === 0" [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
             [class.cal-ends-within-week]="!allDayEvent.endsAfterWeek" [ngClass]="allDayEvent.event?.cssClass" [style.width.%]="(100 / users.length) * allDayEvent.span"
             [style.marginLeft.%]="(100 / users.length) * allDayEvent.offset" mwlDraggable dragActiveClass="cal-drag-active" [dropData]="{ event: allDayEvent.event, calendarId: calendarId }" [dragAxis]="{
                              x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
                              y:
                                !snapDraggedEvents &&
                                allDayEvent.event.draggable &&
                                allDayEventResizes.size === 0
                            }" [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}" [validateDrag]="validateDrag"
             (dragStart)="dragStarted(eventRowContainer, event)" (dragging)="allDayEventDragMove()"
             (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)">
          <mwl-calendar-week-view-event [locale]="locale" [weekEvent]="allDayEvent"
                                        [customTemplate]="eventTemplate" [eventTitleTemplate]="eventTitleTemplate"
                                        [eventActionsTemplate]="eventActionsTemplate" [daysInWeek]="daysInWeek" (eventClicked)="
                                eventClicked.emit({
                                  event: allDayEvent.event,
                                  sourceEvent: $event.sourceEvent
                                })
                              ">
          </mwl-calendar-week-view-event>
        </div>
      </div>
    </div>
    <div class="cal-time-events" mwlDroppable (dragEnter)="dragEnter('time')" (dragLeave)="dragLeave('time')">
      <div class="cal-time-label-column" *ngIf="view.hourColumns.length > 0">
        <div *ngFor="
                              let hour of view.hourColumns[0].hours;
                              trackBy: trackByHour;
                              let odd = odd
                            " class="cal-hour" [class.cal-hour-odd]="odd">
          <mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                                               [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
                                               [locale]="locale" [customTemplate]="customHourSegmentTemplate" [isTimeLabel]="true">
          </mwl-calendar-week-view-hour-segment>
        </div>
      </div>
      <div class="cal-day-columns" [class.cal-resize-active]="timeEventResizes.size > 0" #dayColumns>
        <div class="cal-day-column" *ngFor="let column of view.hourColumns; trackBy: trackByHourColumn">
          <div *ngFor="let timeEvent of column.events; trackBy: trackByWeekTimeEvent" #event class="cal-event-container"
               [class.cal-draggable]="
                                timeEvent.event.draggable && timeEventResizes.size === 0
                              " [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
               [class.cal-ends-within-day]="!timeEvent.endsAfterDay" [ngClass]="timeEvent.event.cssClass"
               [hidden]="timeEvent.height === 0 && timeEvent.width === 0" [style.top.px]="timeEvent.top"
               [style.height.px]="timeEvent.height" [style.left.%]="timeEvent.left" [style.width.%]="timeEvent.width"
               mwlResizable [resizeSnapGrid]="{
                                left: dayColumnWidth,
                                right: dayColumnWidth,
                                top: eventSnapSize || hourSegmentHeight,
                                bottom: eventSnapSize || hourSegmentHeight
                              }" [validateResize]="validateResize" [allowNegativeResizes]="true"
               (resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
               (resizing)="timeEventResizing(timeEvent, $event)" (resizeEnd)="timeEventResizeEnded(timeEvent)" mwlDraggable
               dragActiveClass="cal-drag-active" [dropData]="{ event: timeEvent.event, calendarId: calendarId }" [dragAxis]="{
                                x: timeEvent.event.draggable && timeEventResizes.size === 0,
                                y: timeEvent.event.draggable && timeEventResizes.size === 0
                              }" [dragSnapGrid]="
                                snapDraggedEvents
                                  ? { x: dayColumnWidth, y: eventSnapSize || hourSegmentHeight }
                                  : {}
                              " [ghostDragEnabled]="!snapDraggedEvents" [validateDrag]="validateDrag"
               (dragStart)="dragStarted(dayColumns, event, timeEvent)" (dragging)="dragMove(timeEvent, $event)"
               (dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)">
            <div class="cal-resize-handle cal-resize-handle-before-start" *ngIf="
                                  timeEvent.event?.resizable?.beforeStart &&
                                  !timeEvent.startsBeforeDay
                                " mwlResizeHandle [resizeEdges]="{
                                  left: true,
                                  top: true
                                }">
            </div>
            <!--<mwl-calendar-week-view-event [locale]="locale" [weekEvent]="timeEvent"
                                          [tooltipTemplate]="tooltipTemplate"
                                          (mouseenter)="mouseEnterOnEventClicked(view.hourColumns,timeEvent.event) "
                                          [customTemplate]="eventTemplate" [eventTitleTemplate]="customTitleTemplate"
                                          [eventActionsTemplate]="eventActionsTemplate" [column]="column"
                                          (eventClicked)="eventClicked.emit({view:view.hourColumns, event: timeEvent.event,sourceEvent: $event.sourceEvent  }) ">

            </mwl-calendar-week-view-event>-->

            <mwl-calendar-week-view-event [locale]="locale" [weekEvent]="timeEvent"
                                          [tooltipTemplate]="tooltipTemplate" [tooltipPlacement]="'bottom'"
                                          (mouseenter)="mouseEnterOnEventClicked(view.hourColumns,timeEvent.event) "
                                          [tooltipDisabled]="true"
                                          [customTemplate]="eventTemplate" [eventTitleTemplate]="customTitleTemplate"
                                          [eventActionsTemplate]="eventActionsTemplate" [column]="column"
                                          (eventClicked)="eventClickedOverride(view.hourColumns, timeEvent.event, $event.sourceEvent)">

            </mwl-calendar-week-view-event>
            <div class="cal-resize-handle cal-resize-handle-after-end" *ngIf="
                                  timeEvent.event?.resizable?.afterEnd && !timeEvent.endsAfterDay
                                " mwlResizeHandle [resizeEdges]="{
                                  right: true,
                                  bottom: true
                                }"></div>
          </div>

          <div *ngFor="let hour of column.hours; trackBy: trackByHour; let odd = odd" class="cal-hour" [class.cal-hour-odd]="odd">
            <!--<div class="calendar-box">
              <span class="patient-name">Patient 1
              </span>
            </div>-->
            <mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                                                 [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
                                                 [locale]="locale" [customTemplate]="hourSegmentTemplate" (mwlClick)="SegmentClicked($event)"
                                                 [clickListenerDisabled]="hourSegmentClicked.observers.length === 0" mwlDroppable [dragOverClass]="!dragActive || !snapDraggedEvents ? 'cal-drag-over' : null" dragActiveClass="cal-drag-active" (drop)="eventDropped($event, segment.date, false)">
            </mwl-calendar-week-view-hour-segment>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

