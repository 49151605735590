import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from 'src/app/shared/constants';
import { SalesOrderLocalModel, BranchInfoContactModel } from 'src/app/shared/models/response/sales-order-current';
import { SalesOrderService } from 'src/app/patient/services/salesorder.service';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { Router } from '@angular/router';
import { takeUntil, finalize } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { BranchNumbersResponseModel } from '../models/response/branch-numbers-response-model';
import { BaseComponent } from '../components/base.component';
import { Subject } from 'rxjs';
import { GetPatientDetailsRequestModel } from '../models/request/get-patient-details-request';
import { SalesOrderResponseModel } from '../models/response/sales-order-response';
import { ResultOfT } from '../models/response/result';
@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.sass']
})
export class PatientHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() branchInfoContactModel: BranchInfoContactModel;
  private unsubscriber = new Subject<boolean>();
  domainUrl: string = '';
  patinetrequest: GetPatientDetailsRequestModel;
  configResponseModel: ConfigResponseModel;
  //branchInfoContactModel: BranchInfoContactModel;
  strPatientIdUrl;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  step: boolean = true;
  showStep: boolean = true;
  DMENumber: string;
  DMEeMail: string;
  DMEBranch: string
  salesorderids: SalesOrderLocalModel;
  salesOrderId: string;
  strURL: string;
  patientId: string;
  salesOrderData: SalesOrderResponseModel;
  patientName: string;
  description: string;
  duration: string;
  dueAmount: number;
  constructor(
    messageService: MessageService,
    private salesorderService: SalesOrderService,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private appointmentService: AppointmentService) {
    super(messageService);
  }

  async ngOnInit() {
    if (location.pathname.includes('search-nearby-facility'))
      this.step = true;
    else
      this.step = false;

    if (location.pathname.includes('appointment-confirmation'))
      this.showStep = false;
    else
      this.showStep = true;

    this.configResponseModel = new ConfigResponseModel();
    this.patinetrequest = new GetPatientDetailsRequestModel();
    this.patinetrequest.strLink = this.webStorageService.getPatientBookingUrl();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = Constants.imagePrefix + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;
    }

    //let salesOrderData = this.webStorageService.getSalesOrderData();
    //if (salesOrderData.crmBranchId > 0)
    //  this.getDMENumber(salesOrderData.crmBranchId);
    this.salesOrderData = this.webStorageService.getSalesOrderData();
    // this.branchInfoContactModel = new BranchInfoContactModel();
    // this.branchInfoContactModel = this.webStorageService.getBranchContactInfoById();
    // if (this.branchInfoContactModel) {
    //   this.DMENumber = this.branchInfoContactModel.DMENumber;
    //   this.DMEeMail = this.branchInfoContactModel.DMEEmail;
    //   this.DMEBranch = this.branchInfoContactModel.DMEBranch;
    // }
    this.getDMENumber();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let property in changes) {
      if (property === 'branchInfoContactModel' && this.branchInfoContactModel != undefined) {
        this.DMENumber = this.branchInfoContactModel.DMENumber;
        this.DMEeMail = this.branchInfoContactModel.DMEEmail;
        this.DMEBranch = this.branchInfoContactModel.DMEBranch;
      }
    }
  }

  private async getSalesOrderId() {
    await this.salesorderService.getSalesOrderId(this.patinetrequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<GetPatientDetailsRequestModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.salesOrderId = result.responseModel.strLink.split(Constants.seprator)[0];
              this.patientId = result.responseModel.strLink.split(Constants.seprator)[1];
            }
          }
        });
      });
  }

  private async getSalesOrderData() {
    await this.salesorderService.getSalesOrderDetails(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.salesOrderData = result.responseModel;
              this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
              this.description = this.salesOrderData.crmClassificationDescription;
              this.dueAmount = this.salesOrderData.amountDue;
              this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
              this.webStorageService.storeSalesOrderData(this.salesOrderData);
            }
          }
        });
      });
  }

  RequestForCallBack(strType: string) {

    this.loading = true;
    this.salesorderService.AddTelehealthSalesOrder(this.salesOrderData.crmSalesorderId.toString(), strType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            this.showSuccessMessage('Your request to call back  has been sent.');
            let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
            this.router.navigate(['patient/home/' + url]);
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  // private async getDMEeMail() {
  //   await this.settingsService.getSettings(Constants.SettingKeys.DMEEmail)
  //     .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //     .toPromise()
  //     .then((result) => {
  //       this.processResult<SettingsResponseModel>(result, () => {
  //         if (result.isSuccess) {
  //           if (result.responseModel)
  //             this.DMEeMail = result.responseModel.schSettingValue;
  //         }
  //       });
  //     });
  // }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }
  private getDMENumber() {
    if (!this.salesOrderData) {
      return
    }
    this.settingsService.getAllBranchNumbers(this.salesOrderData.crmBranchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<BranchNumbersResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.DMENumber = result?.responseModel?.dmephoneNumber;
              this.DMEeMail = result?.responseModel?.dmeemail;
              this.DMEBranch = result?.responseModel?.branch?.crmBranchName;
              this.webStorageService.saveDMENumber(this.DMENumber);
              this.branchInfoContactModel = new BranchInfoContactModel();
              if (this.webStorageService.getBranchContactInfoById()) {
                this.webStorageService.deleteBranchContactInfoById();
              }
              this.branchInfoContactModel.branchid = this.salesOrderData.crmBranchId;
              this.branchInfoContactModel.DMEEmail = result?.responseModel?.dmeemail;
              this.branchInfoContactModel.DMENumber = result?.responseModel?.dmephoneNumber;
              this.branchInfoContactModel.DMEBranch = result?.responseModel?.branch?.crmBranchName;
              this.webStorageService.storeBranchContactInfo(this.branchInfoContactModel);
            }
          }
        });
      });
  }
}
