import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, EventEmitter, Inject, Injectable, Input, LOCALE_ID, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CalendarUtils, CalendarWeekViewComponent, DateAdapter, getWeekViewPeriod } from 'angular-calendar';
import { WeekView, GetWeekViewArgs, WeekViewTimeEvent, EventColor, CalendarEvent } from 'calendar-utils';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TherapistService } from 'src/app/admin/servicies/therapist.service';
import * as moment from 'moment';
import "moment-timezone";
import { th } from 'date-fns/locale';
import { isSameDay, isSameMonth, isBefore, isAfter } from 'date-fns';
import { AppointmentInfoReponseModel, TherapistCustomDatesResponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
import { OverlayPanel } from 'primeng/overlaypanel';
import { finalize, takeUntil } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { element } from 'protractor';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
export class User {
  id: number;
  practionaerId: number;
  deliveryTechnicianId: number;
  name: string;
  field: string;
  start: Date;
  end: Date;
  origStart: Date;
  origEnd: Date;
  startDate: Date;
  endDate: Date;
  color: EventColor;
  isChecked: boolean;
  facilityTimeZone: string;
  remoteAppointmentType: string;
  therapistAvailability: TherapistCustomDatesResponseModel[];
  distinctTherapistAvailability: TherapistCustomDatesResponseModel[];
  therapistCustomAvailabilityId: number;
  customDatesId: number;
  therapistCustomDateId: number;
  enUserInfoId: string;
}

export class TimeZones {
  name: string;
  code: string;
}

interface DayViewScheduler extends WeekView {
  users: User[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
  users: User[];
}

@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  menuopen: boolean = false;
  menuOpenEvent() {
    this.menuopen = !this.menuopen;
  }
  getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {

    const { period } = super.getWeekView(args);
    const view: DayViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      users: [...args.users],
    };

    view.users.forEach((user, columnIndex) => {
      const events = args.events.filter(
        (event) => event.meta.appointmentInfoReponseModel.field === user.field
      );
      const columnView = super.getWeekView({
        ...args,
        events,
      });
      view.hourColumns.push(columnView.hourColumns[0]);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }
}

@Component({
  selector: 'mwl-day-view-scheduler-v1',
  templateUrl: './day-view-scheduler-v1.component.html',
  styleUrls: ['./day-view-scheduler-v1.component.sass'],
  providers: [DayViewSchedulerCalendarUtils, DialogService],

})
export class DayViewSchedulerV1Component extends CalendarWeekViewComponent implements OnChanges {
  private unsubscriber = new Subject<boolean>();
  title = 'Calendars';
  @Input() users: User[] = [];

  @Input() events: CalendarEvent[] = [];
  loading: boolean = false;
  @Input() patientBrighttreeURL: string;
  @Input() salesOrderBrighttreeURL: string;
  @Output() userChanged = new EventEmitter();
  @Output() popupCalled = new EventEmitter();
  @Output() PhonecallBackCalled = new EventEmitter();
  @Output() HangUpCallBackCalled = new EventEmitter();
  @Output() selectedAppoinmentCalled = new EventEmitter();
  @Output() hourSegmentClickedOverrited = new EventEmitter();
  @Output() selectedRowAppoinmentCalled = new EventEmitter();
  @Output() removeSlotCalled = new EventEmitter();
  @Output() editSlotCalled = new EventEmitter();
  @Output() removeBreakCalled = new EventEmitter();
  @Output() editBreakCalled = new EventEmitter();
  @Output() ontimeZoneChange = new EventEmitter<string>();
  @Input() timeZoneCode: string;
  calendarWidth: number = 221;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
  slotTimeZone: { name: string; code: string; shortName: string; displayName: string; countryName: string };
  appDateFormat: DateFormatModel;
  viewDate: Date = new Date();
  view: DayViewScheduler;
  availModel: TherapistCustomDatesResponseModel[];
  therapistName: string;
  brightreeInternalUserId: number;
  daysInWeek = 1;
  currentDate: Date;
  selectedCities: any;

  @Input() refresh: Subject<any> = new Subject();

  @Input() dayStartHour: number;
  @Input() dayEndHour: number;
  @Input() hourSegments: number;
  @Input() hourSegmentHeight: number;
  @Input() selectedAppointment: number[];
  @Input() selectedRowAppointment: string[];
  SelectedAppointments: AppointmentInfoReponseModel[];
  selfGuidedText: string = "SelfGuided".toLowerCase();

  activeDayIsOpen: boolean = true;
  DialogPopup: boolean = false;
  appointmentCalcelPopup: boolean = false;
  rescheduleAppointmentPopup: boolean = false;
  origionalEvents: any;
  timeZoneList: TimeZoneAvailableResponseModel[];

  facilitySelect: { name: string; code: string; }[];





  isNewManageAvailability: boolean = false;
  showDialogPopup(appointment) {
    this.popupCalled.emit({ isShow: true, appointment: appointment });

  }


  addToSelectedAppointment(isChecked, value) {
    //this.selectedRowAppointment = this.selectedRowAppointment.filter(x => x === value.meta.appointmentInfoReponseModel.field);
    var evnt = this.events.filter(x => x.meta.appointmentInfoReponseModel.field === value.meta.appointmentInfoReponseModel.field
      && x.id != 0
    );

    var evnt1 = this.events.filter(x => x.meta.appointmentInfoReponseModel.field != value.meta.appointmentInfoReponseModel.field
      && x.id != 0
    );

    evnt1.forEach(valueItem => {

      let selectedAppt = this.selectedRowAppointment.find(x => x === valueItem.id.toString());
      if (selectedAppt) {

        this.selectedRowAppointment = this.selectedRowAppointment.filter(x => x != valueItem.id.toString());
      }
    });


    this.users.forEach(valueItem => {
      valueItem.isChecked = false;
      if (this.selectedRowAppointment.length == evnt.length && valueItem.id == value.meta.appointmentInfoReponseModel.field) {
        valueItem.isChecked = true;
      }
    });

    this.SelectedAppointments = new Array<AppointmentInfoReponseModel>();
    this.selectedRowAppointment.forEach(appt => {
      let appts = this.events.find(x => x.id == appt);
      if (appts)
        this.SelectedAppointments.push(appts.meta.appointmentInfoReponseModel.appointment);
    });

    let selectedUser = new User();
    selectedUser = this.users.find(x => x.id == value.meta.appointmentInfoReponseModel.field);
    this.SelectedAppointments = this.sortData()
    this.selectedAppoinmentCalled.emit({ checked: isChecked, selectedUser: selectedUser, selectedAppoinment: this.selectedRowAppointment, selectedAppointmentsInformation: this.SelectedAppointments });
  }
  addToSelectedHeader(isChecked, value, therpaistId) {

    this.selectedRowAppointment = [];
    var evnt = this.events.filter(x => x.meta.appointmentInfoReponseModel.field === value);
    if (isChecked) {
      evnt.forEach(val => {
        if (val.id != 0) {
          this.selectedRowAppointment.push(val.id.toString());
        }
      });

    } else {
      this.selectedRowAppointment = [];
    }
    var unique = this.selectedRowAppointment.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })

    this.users.forEach(value => {
      value.isChecked = false;
      if (value.id == therpaistId) {
        value.isChecked = true;
      }
    });
    this.selectedRowAppointment = unique;
    this.refreshAll();

    this.SelectedAppointments = new Array<AppointmentInfoReponseModel>();
    this.selectedRowAppointment.forEach(appt => {
      let appts = this.events.find(x => x.id == appt);
      if (appts && appts[0].id > 0)
        this.SelectedAppointments.push(appts.meta.appointmentInfoReponseModel.appointment);
    });
    this.SelectedAppointments = this.sortData()

    let selectedUser = new User();
    selectedUser = this.users.find(x => x.id == therpaistId);
    //this.selectedRowAppoinmentCalled.emit({ checked:isChecked,therpaistId: therpaistId, selectedAppoinment: unique });
    this.selectedAppoinmentCalled.emit({ checked: isChecked, selectedUser: selectedUser, selectedAppoinment: this.selectedRowAppointment, selectedAppointmentsInformation: this.SelectedAppointments });
  }

  sortData() {

    return this.SelectedAppointments.sort((a, b) => {
      return <any>new Date(a.schAppointmentDateStartTime) - <any>new Date(b.schAppointmentDateStartTime);
    });
  }
  constructor(
    private router: Router,
    protected cdr: ChangeDetectorRef,
    protected utils: DayViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private dialougeService: DialogService,
    private webStorageService: WebStorageService,
    private therapistService: TherapistService
  ) {
    super(cdr, utils, locale, dateAdapter);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);
    var date = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss');
    this.currentDate = moment(date).toDate();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.facilitySelect = [
      { name: 'facility', code: 'FC' },
      { name: 'Online Meeting', code: 'OM' },
    ];

    var companyInfo = this.webStorageService.getClientConfig();
    this.isNewManageAvailability = companyInfo.isNewManageAvailability;
  }

  ngOnInit() {
    this.timeZoneList = this.webStorageService.getTimeZoneList();

  }
  onTimeZoneSelection(timezone: string) {
    this.ontimeZoneChange.emit(timezone);
  }

  idDateIsPassed(segmentdate: any, user: any) {

    let format = 'hh:mm:ss';
    this.slotTimeZone = this.timeZone.find(x => x.shortName == this.timeZoneCode);
    let datec = moment(new Date()).tz(this.slotTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
    let currentDate = moment(datec).toDate();
    let date = moment(segmentdate).toDate();
    let issameDaye = isSameDay(segmentdate, currentDate);
    let issameafter = isAfter(segmentdate, currentDate);
    let isBeforedate = isBefore(segmentdate, currentDate);
    let startDate = moment(user.start).toDate();
    let endDate = moment(user.end).toDate();

    if (isBeforedate)
      return false;
    else if ((issameDaye || issameafter) && date >= startDate && date <= endDate)
      return true;
    else
      return false;

  }
  GetAvailability(id, event, overlaypanel: OverlayPanel, name: string) {

    //this.router.navigate(['/admin/calendar/view-therapist-availability/' + id])
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.therapistService.GetAvailData(id, selectedDate)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistCustomDatesResponseModel[]>) => {
        this.processResult<TherapistCustomDatesResponseModel[]>(result, () => {
          if (result && result.responseModel && result.responseModel.length > 0) {
            this.availModel = result.responseModel;
            this.therapistName = name;
            this.cdr.detectChanges();
          }
          else {
            this.availModel = null;
          }
        });
      }, (httpResponseError) => {


      });
    overlaypanel.toggle(event);
    this.cdr.detectChanges()
  }

  hourSegmentClickedOverrite(date: Date, sourceEvent, userinfo: User) {



    this.hourSegmentClickedOverrited.emit({ date: date, sourceEvent: sourceEvent, userinfo: userinfo });

  }


  facilityNameLabel(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): string {
    if (therapistAvailability) {

      let facilityName = '';
      let startDate = moment(slotStartTime).toDate();
      var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
        && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
      if (facility) {
        if (facility.isRemoteAndFacility) {
          return `${facility.facilityName} & ${facility.remoteFacilityName}`;
        } else {
          return `${facility.facilityName}`;

        }
      }
      else
        return '';
    }
    else
      return '';
  }



  removeSlot(therapistCustomAvailabilityId: number, availId: number, customDatesId: number) {
    this.removeSlotCalled.emit({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId, customDatesId: customDatesId });
  }

  editSlot(therapistCustomAvailabilityId: number, availId: number, therapistCustomDateId: number) {
    this.editSlotCalled.emit({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId, therapistCustomDateId: therapistCustomDateId });
  }

  removeBreak(therapistCustomAvailabilityId: number, availId: number, customDatesId: number) {
    this.removeBreakCalled.emit({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId, customDatesId: customDatesId });
  }

  editBreak(therapistCustomAvailabilityId: number, availId: number, therapistCustomDateId: number) {
    this.editBreakCalled.emit({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId, therapistCustomDateId: therapistCustomDateId });
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: string) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId.toString());
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: string) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId.toString());
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  showAvail(id) {

    if (this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/view-therapist-availability-v1/' + id]);
    }
    else {
      this.router.navigate(['/admin/calendar/view-therapist-availability/' + id]);

    }

    

  }

  processResult<T>(result: ResultOfT<T>, onSuccess: () => void = null, onError: () => void = null) {
    // To handle when request is cancelled
    if (!result)
      return;

    if (result.isSuccess) {
      if (onSuccess)
        onSuccess();
    }
    else {
      if (onError)
        onError();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.users) {
      this.refreshBody();
      this.emitBeforeViewRender();
    }
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.users.length);
  }

  getcalenderColumnWidth(): number {
    return Math.floor(this.calendarWidth * this.users.length);
  }

  dragMove(dayEvent: WeekViewTimeEvent, dragEvent: DragMoveEvent) {



    if (this.snapDraggedEvents) {
      const newUser = this.getDraggedUserColumn(dayEvent, dragEvent.x);
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        { ...dragEvent, x: 0 },
        this.dayColumnWidth,
        true
      );
      const originalEvent = dayEvent.event;
      originalEvent.meta.appointmentInfoReponseModel.field = newUser.id.toString();
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta, user: newUser },
      };
      const tempEvents = this.events.map((event) => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );

    }

    this.dragAlreadyMoved = true;
  }

  dragEnded(
    weekEvent: WeekViewTimeEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ) {
    super.dragEnded(
      weekEvent,
      {
        ...dragEndEvent,
        x: 0,
      },
      dayWidth,
      useY
    );

    const newUser = this.getDraggedUserColumn(weekEvent, dragEndEvent.x);

    let alredayExist = false;

    //if (newUser && newUser !== weekEvent.event.meta.user) {

    this.userChanged.emit({ alredayExist: alredayExist, event: weekEvent.event, newUser });

    //}
  }

  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      users: this.users,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      ),
    });
  }

  private getDraggedUserColumn(dayEvent: WeekViewTimeEvent, xPixels: number) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.users.findIndex(
      (user) => user === dayEvent.event.meta.user
    );
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.users[newIndex];
  }

}
