import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { WeekViewHourColumn } from 'calendar-utils';
import { isSameDay, isSameMonth } from 'date-fns';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MessageService, MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subject } from 'rxjs';
import { finalize, map, filter, takeUntil } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { FacilityService } from 'src/app/admin/servicies/facility.service';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { TwillioCallService } from 'src/app/admin/servicies/twillioCall.service';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { TheraistAppointmentService } from 'src/app/admin/servicies/theraistappointment.service';
import { TherapistService } from 'src/app/admin/servicies/therapist.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model';
import { CommonNextDayScheduleId, CommonNextDayScheduleRequest } from 'src/app/shared/models/request/facility/common-nextday-schedule';
import { ReassignTherapistAvailabilitySearchRequestModel } from 'src/app/shared/models/request/therapist/reassign-therapist-availability-request-model';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment/appointment-confirmation';
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { AppointmentInfoReponseModel, TherapistEditBlockResponseModel, TherapistCustomDatesResponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { NearByPatientResponseModel } from 'src/app/shared/models/response/appointment/nearby-patient-response-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SalesOrderItemInfoResponseModel } from 'src/app/shared/models/response/appointment/salesorder-item-info-response-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { TherapistAvailabilityForReassignResponseModel } from 'src/app/shared/models/response/therapist/therapist-availabilityfor-reassign-response-model';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { environment } from 'src/environments/environment';
import { colors } from './colors.model';
import { User, TimeZones } from './day-view-scheduler.component';
declare const Twilio: any;
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component';
import { SettingsResponseModel, AdminCustomSettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { Constants } from 'src/app/shared/constants';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';
import { UpdateStatusOfAppointmentsComponent } from 'src/app/shared/components/update-status-of-appointments/update-status-of-appointments.component';
import { FilterClassificationTypeResponseModel } from 'src/app/shared/models/response/appointment/filter-classification-response-model';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };

//41466
export class tempNoShow { crmSalesOrderAPPId: string; crmPatientName: string; schAppointmentDateTime: Date; crmPatientTimeZone: string };

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './template.html',
  styleUrls: ['./day-view-scheduler.component.sass'],
  providers: [DialogService]
})

export class TherapistDayViewSchedulerComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('target') myScrollContainer: ElementRef;
  @ViewChildren('target') childrenComponent: QueryList<ElementRef>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  appointments: AppointmentInfoReponseModel[] = [];
  appointment: AppointmentInfoReponseModel;
  selectedAppointment: AppointmentInfoReponseModel;
  salesOrderItemInfo: SalesOrderItemInfoResponseModel[];
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  selectedbranches: BranchResponseModel[];
  twillioDeviceConnection: TwilioDeviceObject;
  facilityResponseModel: FacilityInfoResponseModel[];
  therapistResponseModel: TherapistResponseModel[];
  selectedTherapists: TherapistResponseModel[];
  selectedFacility: FacilityInfoResponseModel;
  selectedAppointmentFacilities: FacilityInfoResponseModel[];
  selectedFacilities: FacilityInfoResponseModel[];
  nearByPatients: NearByPatientResponseModel[];
  origionalNearByPatients: NearByPatientResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  overlaypanelinline: OverlayPanel;
  isDisabled: boolean = true;
  isDisabledReassignment: boolean = true;
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  isRightPanelEnabled: boolean = false;
  isRightPanelBlockEnabled: boolean = false;
  users: User[];
  timezones: TimeZones[];
  selectedUser: User;
  reassignTherapistAvailabilitySearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel;
  therapistAvailabilityForReassignResponseModel: TherapistAvailabilityForReassignResponseModel[];
  selectedtherapistAvailabilityForReassignResponseModel: TherapistAvailabilityForReassignResponseModel;
  schAppointmentId: number = 0;
  commonNextDayScheduleIds: CommonNextDayScheduleId[] = [];
  commonNextDayScheduleId: CommonNextDayScheduleId;
  appointmentConfirmation: AppointmentConfirmation;
  commonNextDayScheduleRequest: CommonNextDayScheduleRequest;
  currentUserBranch: number;
  checkAll: boolean = false;
  ConfirmAppointmentPupop: boolean;
  duration: string;
  reminderDays: string;
  appointmentStatus: SelectItem[];
  selectedAppointmentStatus: SelectItem;
  selectedFilSlotDateTime: Date;
  SchedulePopup: boolean = false;
  isCallStarted: boolean = false;
  isMoveStarted: boolean = false;
  connection: any;
  callSID: string;
  device: any;
  callConfigModel: CallConfigModel;
  callnote: string;
  calldate: Date;
  calltime: Date;
  callId: number;
  actionType: string;
  scheduleAppointment: boolean = false;
  schedulecall: boolean = false;
  isScheduleAppoinment: boolean;
  selectUser: User;
  isRescheduledRequest: boolean;
  reminderOpted: boolean = false;
  formAgreementSubmitted: boolean = false;
  appointmentCallResponseModel: AppointmentCallResponseModel;
  appDateFormat: DateFormatModel;
  formSubmitted: boolean = false;
  formAppointmentCallSubmitted: boolean = false;
  OpenCheck: boolean = false;
  showConfirm: boolean = false;
  termCondtionSelected: boolean = false;
  AgreeTermsConditionsPopup: boolean = false;
  IsRescheduleFacilityOption: boolean = false;
  rescheduleFacilityOption: string;
  currentDate: Date;
  loadingCustom: boolean = false;
  loadingBlockSlotBrakHours: boolean = false;
  IsRescheduleAppoinment: boolean = false;
  IsCancelAppoinment: boolean = false;
  displayedColumns: string[] = ['select', 'crmSalesorderAppId', 'crmPatientName', 'schAppointmentDateStartTime', 'crmPatientTimeZone', 'availableReAssgTherapist', 'isSetTounAvailability'];
  dataSource = new MatTableDataSource<AppointmentInfoReponseModel>();
  selection = new SelectionModel<AppointmentInfoReponseModel>(true, []);
  blockDesc: string;
  customStartTime: string;
  customEndTime: string;
  availId: number;
  therapistCustomAvailabilityId: number;
  therapisId: number;
  therapistAvailability: TherapistCustomAvailabilityResponseModel;
  fillSlotPopup: boolean;
  blockSlotPopup: boolean;
  twoFacilityPopup: boolean;
  selectedApptType: string = '';
  selectFacilityString: string = "0";
  selectBranchString: string = "0";
  selectTherapistString: string = "0";
  isBranchChange: boolean = false;
  dayStart: number;
  dayEnd: number;
  isBillingAddress: boolean = false;
  patientsDefaultAppointmentLocation: string;
  daysToShow: number;
  maxDateToDisplay: Date;
  prevBtnDisabled: boolean = false;
  nextBtnDisabled: boolean = false;
  crmselectedTimeZone: string = 'UTC-5';
  clientId: number;
  timeZoneList: TimeZoneAvailableResponseModel[];
  ESTTherapistCount: number = 0;
  CSTTherapistCount: number = 0;
  MSTTherapistCount: number = 0;
  AMSTTherapistCount: number = 0;
  PSTTherapistCount: number = 0;
  IsRemoteOrFacility: number = 0;
  defaultModeofAppointment: string = "";
  schAppointmentID: number = 0;
  isButtonDisabled: boolean = false;
  isChangeRemoteAppointmentType: boolean = false;
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  displayNoShowAppointment: boolean;
  displayCompleteAppointment: boolean;
  reasonCommentsNoShow: string;
  reasonCommentsComplete: string;
  //41466
  totalSelectedNoShowAppointments: number = 0;
  ConfirmSetStatusChange: boolean = false;
  restrictedApptFromNoShow: { crmSalesOrderAPPId: string, crmPatientName: string, schAppointmentDateTime: Date, crmPatientTimeZone: string }[];
  statusType: string;
  updateStatusforminfo:statusInfo;

  TimeSelectionList: { name: string; }[] = [];
  StartTimeSelections: { name: string; }[];
  EndTimeSelections: { name: string; }[];
  customDatesId: number
  InfoSidebar: boolean = false;
  LegendsisActive: boolean;
  isAllClssificationFilterSelected: boolean = true;
  isAllBranchFilterSelected: boolean = true;
  selectedFilterBranches: BranchResponseModel[] = [];
  filterClassificationList: FilterClassificationTypeResponseModel[];
  selectedClassificationType: FilterClassificationTypeResponseModel[] = [];
  isFilterSubmit: boolean = false;

  isNewManageAvailability: boolean = false;


  constructor(private modal: NgbModal,
    private router: Router,
    protected cdr: ChangeDetectorRef,
    private dialougeService: DialogService,
    private webStorageService: WebStorageService,
    private theraistAppointmentService: TheraistAppointmentService,
    messageService: MessageService,
    @Inject(LOCALE_ID) private locale: string,
    private userService: UserService,
    private appointmentService: AppointmentService,
    private facilityService: FacilityService,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private therapistService: TherapistService
  ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    //var date = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy HH:mm', this.appDateFormat.timeZone);
    //this.currentDate = moment(date).toDate();
    this.initializeAllDefaultModel();
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.calendarActions = [
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'Week View By Therapist', code: 'WeekByTherapist' },

    ];
    this.appointmentStatus = [
      { label: "Ready for Scheduling", value: 'ReadyForScheduling' },
      { label: "No Show", value: 'NoShow' },
      { label: "Cancel", value: 'Cancel' }
    ];
    //debugger;

    var defaultTimeZoneCodeVal = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);
    if (defaultTimeZoneCodeVal) {

      this.crmselectedTimeZone = defaultTimeZoneCodeVal.orgcode;
      this.timeZoneCode = defaultTimeZoneCodeVal.shortName;;
      this.timeZoneShortCode = defaultTimeZoneCodeVal.orgcode;
      this.defaultTimeZoneCode = defaultTimeZoneCodeVal.shortName;
    }


    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyId) {
      this.clientId = parseInt(currentClientConfig.companyId);
      //this.clientId = 10;
      //if (this.clientId == 10) {
      //  this.crmselectedTimeZone = 'UTC-8';
      //  this.timeZoneCode = 'PST';
      //  this.timeZoneShortCode = 'UTC-8';
      //  this.defaultTimeZoneCode = 'PST';
      //}
      //if (this.clientId == 17) {
      //  this.crmselectedTimeZone = 'UMST-7';
      //  this.timeZoneCode = 'AMST';
      //  this.timeZoneShortCode = 'UMST-7';
      //  this.defaultTimeZoneCode = 'AMST';
      //}
      //if (this.clientId == 12) {
      //  this.crmselectedTimeZone = 'UMST-7';
      //  this.timeZoneCode = 'AMST';
      //  this.timeZoneShortCode = 'UMST-7';
      //  this.defaultTimeZoneCode = 'AMST';
      //}
      //if (this.clientId == 1008) {
      //  this.crmselectedTimeZone = 'UMST-7';
      //  this.timeZoneCode = 'AMST';
      //  this.timeZoneShortCode = 'UMST-7';
      //  this.defaultTimeZoneCode = 'AMST';
      //}
    }
    
    this.isNewManageAvailability = currentClientConfig.isNewManageAvailability;
  }


  viewDate = new Date();
  DialogPopup: boolean = false;
  appointmentCalcelPopup: boolean = false;
  rescheduleAppointmentPopup: boolean = false;
  selectedAppointmentValues: string[] = [];
  showMarker = true;
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderSelectedStatusCurrent: WIPStatesResponseModel;
  salesOrderStatusCurrent: WIPStatesResponseModel[];
  remoteAppointmentTypeResponseModel: Array<RemoteAppointmentTypeModel>;
  selectedDayViewDate: Date;
  dayStartHour: number = 8;
  dayStartMinute: number = 0;
  dayEndHour: number = 19;
  dayEndMinute: number = 59;
  checked: boolean = false;
  hourColumns: WeekViewHourColumn[];
  selectedAppointments: string[];
  selectedAppointmentsInformation: AppointmentInfoReponseModel[];
  canUpdateStatue: boolean;
  selectedAppointmentType: string;
  isTelehealth: boolean;
  salesOrder: SalesOrderInfoResponseModel;
  cancelReasons: { name: string; code: string; }[];
  selectedCancelReason: { name: string; code: string; };
  events$: Observable<CalendarEvent<{ appointmentInfoReponseModel: SchedulerResponseModel }>[]>;
  appointmentsalesOrderStatusMenuitems: MenuItem[];
  selectedappointmentsalesOrderStatusMenuitems: MenuItem;
  cancelRequestModel: AppointmentCancelRequest;
  data1: any;
  redirectedDate: string;
  redirectedFacilityId: string;
  redirectedFacilityIdNumber: number;
  redirectedTimeZoneCode: string;
  appointmentRequest: AppointmentRequestModel;
  MastertherapistAppointmentSearchRequestModel = new Array<ReassignTherapistAvailabilitySearchRequestModel>();
  //MastertherapistAppointmentSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel[] = new Array();
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  refresh: Subject<any> = new Subject();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  en: any;
  activeDayIsOpen: boolean = true;
  facilityId: number;
  remoteFacilityId: number;
  facilityName: string;
  remoteFacilityName: string;
  isRemoteAndFacility: boolean = false;
  isRemoteOnly: boolean = false;
  isRoadHomeAppt: boolean = false;
  isFacilityselected: boolean = false;
  isStartFromDefaultFacility: boolean;
  isStartFromHomeAddress: boolean;
  searchedFacilityid: number = 0;
  selectedFacilityId: number = 0;
  salesOrderType: string;
  loggedInUserId: number;
  isBlankMessageshow: boolean = false;
  isBlankMessageforTherapistShow: boolean = false;
  isShowHomeVisit: boolean = false;
  isHoliday: boolean = false;
  holidayMessage: string;
  convertedRemoteAppointmentType: string;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  timeZoneCode: string = 'EST';
  defaultTimeZoneCode: string = 'EST';
  timeZoneShortCode: string = 'UTC-5';
  timeZoneCodeTherapistCount: number = 0;
  selectedFacilityTimeZone: string;
  isAlreadyGetTherapist: boolean = false;

  ontimeZoneChange(selectedTimeZone: any) {

    this.crmselectedTimeZone = selectedTimeZone;
    // this.GetAllTherapistForAppointments();
    this.timeZoneShortCode = selectedTimeZone;
    if (this.redirectedFacilityId != undefined && this.redirectedFacilityId == '9999') //7478
      this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    else
      this.GetAllTherapistForAppointmentsByTimeZone();
    this.timeZoneCode = this.timeZone.find(x => x.orgcode == selectedTimeZone).shortName;
    this.defaultTimeZoneCode = this.timeZoneCode;
    this.setTimeZoneOnBasedOfDropdown(this.timeZone.find(x => x.orgcode == selectedTimeZone).code);
  }

  initializeAllDefaultModel() {
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.setTimeZoneOnBasedOfDropdown(this.appDateFormat.timeZone);

  }
  setTimeZoneOnBasedOfDropdown(selectedTimeZone: string) {
    this.selectedTimeZone = this.timeZone.find(x => x.code == selectedTimeZone);
    var date = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss');
    this.currentDate = moment(date).toDate();

    if (this.viewDate < this.currentDate)
      this.isDisabledReassignment = true;
    else
      this.isDisabledReassignment = false;
  }
  async getWipStatusBTmapping() {

    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatusBTMappingResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }


  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          let jsonData = result.responseModel;
          this.patientBrighttreeURL = jsonData.patientRCMURL;
          this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  async getRemoteAppointmentType() {
    this.loading = true;
    this.settingsService.getRemoteAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.remoteAppointmentTypeResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  async ngOnInit(): Promise<void> {
    //await this.getDefaultModeofAppointment();
    this.redirectedDate = this.activatedRoute.snapshot.paramMap.get('date');
    this.redirectedFacilityId = this.activatedRoute.snapshot.paramMap.get('facilityid');
    this.redirectedTimeZoneCode = this.activatedRoute.snapshot.paramMap.get('timeZone');
    if (this.redirectedTimeZoneCode) {
      this.ontimeZoneChange(this.redirectedTimeZoneCode);
    }
    if (this.redirectedDate && this.redirectedFacilityId) {
      this.viewDate = moment(this.redirectedDate).toDate();
      this.redirectedFacilityIdNumber = Number(this.redirectedFacilityId);
    }
    this.salesOrderType = 'AppointmentRequest';
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.selectedFacilities = [];
    this.selectUser = new User();
    this.appointmentConfirmation = new AppointmentConfirmation();

    this.selectedcalendarActions = this.calendarActions.find(x => x.code == 'ViewByTherapist');
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: this.appDateFormat.dateFormat,
      weekHeader: 'Wk'
    };

    this.selectedAppointmentType = '';
    /*this.getCallConfig();*/
    this.setPermissionforActions();
    this.getSalesOrderStatus();
    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null && branchId >= 0) {
        this.currentUserBranch = branchId;
        this.setBranchSettings();
        //this.cdr.detectChanges();
        this.initAllMethods();
      }

    });


    this.appointment = new AppointmentInfoReponseModel();
    this.selectedAppointment = new AppointmentInfoReponseModel();
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.selectedTherapists = new Array<TherapistResponseModel>();
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.salesOrderInsuranceInfo = new Array();
    this.appointment.salesOrderInfo.salesOrderItemInfo = new Array();
    this.appointmentsalesOrderStatusMenuitems = new Array();
    this.facilityId = 0;

    await this.getWipStatusBTmapping();
    await this.getSettingInit();
    /*await this.GetRCMConfigurationUrl();*/
    //await this.getRemoteAppointmentType();
    //await this.getNumberOfMonths();
    /*await this.getSettingsForDeliveryAddress(Constants.SettingKeys.PatientsDefaultAppointmentLocation);*/
    await this.getBranches();
    await this.getAllClassificationType();
    if (this.redirectedFacilityId == "0") {
      this.isShowHomeVisit = true;
      this.onCheck();
      this.onFilterSearch();
    }
    else if (this.redirectedFacilityId == '9999') {
      this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    }
    this.webStorageService.deleteRedirectFrom();
  }
  private async initAllMethods() {
    await this.GetFacilitiesByBranchIds();
    await this.GetAllAvailableTherapistByBranchAndDate();
    // await this.GetAllTherapistForAppointments();
    await this.GetAllTherapistForAppointmentsByTimeZone();
  }
  private async getSettingInit() {
    this.loading = true;
    await this.settingsService.GetAllSettingsCalenderOnInit()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.setRemoteAppintmentTypes(result.responseModel.remoteApointmentTypesResponse);
              this.setRCMConfigurationUrl(result.responseModel.rcmConfigurationUrlResponse);
              this.SetDefaultsettings(result.responseModel.adminSetingResponse);
            }
          }

        });
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private setRemoteAppintmentTypes(_lstRemoteAppointmentType: RemoteAppointmentTypeModel[]) {

    if (_lstRemoteAppointmentType && _lstRemoteAppointmentType.length > 0) {
      this.remoteAppointmentTypeResponseModel = _lstRemoteAppointmentType;

    }
  }
  private setRCMConfigurationUrl(_RCMCofigurationUrl: RCMCofigurationUrl) {

    if (_RCMCofigurationUrl != undefined && _RCMCofigurationUrl != null) {
      let jsonData = _RCMCofigurationUrl;
      this.patientBrighttreeURL = jsonData.patientRCMURL;
      this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
    }
  }
  private SetDefaultsettings(settingsResponseModel: AdminCustomSettingsResponseModel) {

    if (settingsResponseModel != undefined && settingsResponseModel != null) {
      this.daysToShow = settingsResponseModel.numOfMonths;
      this.maxDateToDisplay = moment(new Date()).add(this.daysToShow, 'days').toDate();
      this.nextBtnDisabled = this.viewDate < this.maxDateToDisplay ? false : true;
      this.isBillingAddress = settingsResponseModel.isBillingAddress;
    }
  }


  private async getNumberOfMonths() {
    await this.settingsService.getSettings(Constants.SettingKeys.NumOfMonths)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel) {
              this.daysToShow = parseInt(result.responseModel.schSettingValue, 10);
              this.maxDateToDisplay = moment(new Date()).add(this.daysToShow, 'days').toDate();
              this.nextBtnDisabled = this.viewDate < this.maxDateToDisplay ? false : true;
            }
          }
        });
      });
  }
  private async getSettingsForDeliveryAddress(constantkey: any) {
    await this.settingsService.getSettings(constantkey)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel)
              if (constantkey.toLowerCase() == Constants.SettingKeys.PatientsDefaultAppointmentLocation.toLowerCase()) {
                this.patientsDefaultAppointmentLocation = result.responseModel.schSettingValue;
                if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress') {

                  this.isBillingAddress = true;
                }
                else {
                  this.isBillingAddress = false;
                }
              }

          }
        });
      });
  }

  private async getCallConfig() {
    this.progress.next(true);
    await this.settingsService.getCallConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<CallConfigModel>(result, () => {
          this.callConfigModel = result.responseModel;
          this.getDeviceConnectionForCall();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  async getDeviceConnectionForCall() {
    await this.http.get(this.callConfigModel.twiMLFunctionURL).subscribe((data) => {

      this.data1 = data;
      this.device = new Twilio.Device(this.data1.token, {
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
        codecPreferences: ['opus', 'pcmu'],
        // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
        // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
        // a second time and sending the tone twice. This will be default in 2.0.
        fakeLocalDTMF: true,
        // Use `enableRingingState` to enable the device to emit the `ringing`
        // state. The TwiML backend also needs to have the attribute
        // `answerOnBridge` also set to true in the `Dial` verb. This option
        // changes the behavior of the SDK to consider a call `ringing` starting
        // from the connection to the TwiML backend to when the recipient of
        // the `Dial` verb answers.
        enableRingingState: true
      });
      this.device.on('ready', function (device) {
        console.log('Twilio.Device Ready!');
      });
      this.device.on('error', function (error) {
        console.log('Twilio.Device Error: ' + error.message);
      });
      this.device.on('connect', function (conn) {
        console.log('Successfully established call!');
      });
      this.device.on('disconnect', function (conn) {
        console.log('Call ended.');
      });
      this.device.on('ringing', function (conn) {
        console.log('still ringing...');
      });

    });
  }

  async onAppointmentChange(filterPanel: OverlayPanel) {
    this.isFilterSubmit = true;
    // if(!this.isAllBranchFilterSelected || !this.isAllClssificationFilterSelected){
    //   if(this.selectedFilterBranches.length == 0 || this.selectedClassificationType.length == 0){
    //     return;
    //   }
    // }
    //let selectedAppointmentStatus = this.selectedAppointmentStatus.value;

    if (!this.isAllBranchFilterSelected && this.selectedFilterBranches.length == 0) {
      return;
    }
    if (!this.isAllClssificationFilterSelected && this.selectedClassificationType.length == 0) {
      return;
    }

    if (this.isAllBranchFilterSelected && this.selectedFilterBranches.length > 0) {
      this.selectedFilterBranches = [];
    }
    if (this.isAllClssificationFilterSelected && this.selectedClassificationType.length > 0) {
      this.selectedClassificationType = [];
    }
    let myClonedArray = [];
    this.origionalNearByPatients.forEach(val => myClonedArray.push(Object.assign({}, val)));

    if (!this.isAllBranchFilterSelected) {
      myClonedArray = myClonedArray.filter(x => this.selectedFilterBranches.map(x => x.crmBranchId).includes(x.crmBranchId));
    }
    if (!this.isAllClssificationFilterSelected) {
      myClonedArray = myClonedArray.filter(x => this.selectedClassificationType.map(x => x.crmClassificationTypeId).includes(x.crmSalesorderClassificationType));
    }

    const myFilterdArray = [];
    this.selectedAppointmentValues.forEach(val => {
      var values = myClonedArray.filter(x => x.crmSalesorderStatus == val);
      myFilterdArray.push(...values);
    });

    if (this.selectedAppointmentValues.length > 0) {
      this.nearByPatients = myFilterdArray;
    }
    else {
      this.nearByPatients = myClonedArray;
    };
    this.isFilterSubmit = false;
    filterPanel.hide();
  }

  onClearFilter() {
    this.selectedAppointmentValues = [];
    this.selectedClassificationType = [];
    this.selectedFilterBranches = [];
    this.isAllBranchFilterSelected = true;
    this.isAllClssificationFilterSelected = true;
    this.isFilterSubmit = false;
    let myClonedArray = [];
    this.origionalNearByPatients.forEach(val => myClonedArray.push(Object.assign({}, val)));
    this.nearByPatients = myClonedArray;
  }

  async GetNextDayAvailability() {
    let NextDate = moment(new Date(this.viewDate)).add(1, 'days').toDate();


    if (NextDate < this.maxDateToDisplay) {
      this.nextBtnDisabled = false;
    } else if (NextDate > this.maxDateToDisplay) {
      this.nextBtnDisabled = true;
    }

    if (NextDate < this.currentDate) {
      this.isDisabledReassignment = true;
    }
    else
      this.isDisabledReassignment = false;

    //await this.GetFacilitiesByBranchIds();
    if (this.isShowHomeVisit === true) {
      this.GetAllAvailableTherapistByBranchAndDateForRoadShow(0)
      //this.GetAllTherapistForAppointmentsForRoadShow(0);
      this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    } else {

      this.GetAllAvailableTherapistByBranchAndDate();
      //this.GetAllTherapistForAppointments();
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
  }

  async onCheck() {

    this.isBlankMessageforTherapistShow = false;
    this.redirectedFacilityIdNumber = null;
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedTherapists = new Array<TherapistResponseModel>();
    if (this.isShowHomeVisit === true) {
      this.GetAllAvailableTherapistByBranchAndDateForRoadShow(0)
    }
  }

  private async GetAllAvailableTherapistByBranchAndDateForRoadShow(roadshowId) {
    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });


    let facilityids: number[] = new Array<number>();
    selectFacilityString += roadshowId + ",";
    facilityids.push(roadshowId);


    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    this.progress.next(true);
    await this.facilityService.GetAllAvailableTherapistByBranchAndDate(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.therapistResponseModel = result.responseModel;
        //this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private async GetAllTherapistForAppointmentsForRoadShow(roadshowId) {
    this.isHoliday = false;
    this.holidayMessage = "";
    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });


    let facilityids: number[] = new Array<number>();
    selectFacilityString += roadshowId + ",";
    facilityids.push(roadshowId);

    let therapistAppointmentSearchRequestModelExt = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModelExt.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModelExt.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModelExt.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModelExt.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModelExt.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModelExt.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModelExt.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModelExt.isRoadShow = true;
    therapistAppointmentSearchRequestModelExt.crmselectedTimeZone = this.crmselectedTimeZone;

    this.selectedAppointments = Array();
    this.progress.next(true);
    await this.facilityService.GetAllTherapistForAppointments(therapistAppointmentSearchRequestModelExt)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.users = Array<User>();
        if (result.isSuccess) {
          result.responseModel.forEach(value => {
            let startDate = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            let startDateExt = moment(startDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');
            let endDate = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            let endDateDateExt = moment(endDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');
            let user = new User();
            user.id = value.id;
            user.field = value.field;
            user.name = value.title;
            user.practionaerId = value.practitionerId;
            user.deliveryTechnicianId = value.deliveryTechnicianId;
            user.start = moment(startDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.end = moment(endDateDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.origStart = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            user.origEnd = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            user.therapistAvailability = value.therapistAvailability;

            let removeBlankFacility = value.therapistAvailability.filter(x => x.facilityName != "");
            let uniqueUsersByID = _.uniqBy(removeBlankFacility, 'facilityName');

            let therapistAvailabilityFilter: TherapistCustomDatesResponseModel[];
            therapistAvailabilityFilter = [];
            for (let item of uniqueUsersByID) {
              let _model: TherapistCustomDatesResponseModel;
              _model = item;
              let facilityFilter = this.facilityResponseModel.find(x => x.crmFacilityId == item.facilityId);
              if (facilityFilter != undefined) {
                if (facilityFilter.isRemoteAssistance)
                  _model.facilityTimeZone = "";

              }
              else {
                _model.facilityTimeZone = "";

              }

              therapistAvailabilityFilter.push(_model);
            }

            user.distinctTherapistAvailability = therapistAvailabilityFilter;
            this.users.push(user);
          });
          if (this.users.length > 0) {
            this.isBlankMessageforTherapistShow = false;
          }
          else
            this.isBlankMessageforTherapistShow = true;

          let momentsStart = this.users.map(d => moment(d.origStart));
          let momentsEnd = this.users.map(d => moment(d.origEnd));
          let minDate = moment.min(momentsStart);
          let maxDate = moment.max(momentsEnd);

          let dayStartHour = minDate.toDate().getHours();
          let dayStartMintes = minDate.toDate().getMinutes();

          let DayeEndMinutes = maxDate.toDate().getMinutes();
          let DayeEndTime = maxDate.toDate().getHours();


          this.dayEnd = DayeEndTime;
          if (dayStartHour)
            this.dayStartHour = dayStartHour;

          if (dayStartMintes)
            this.dayStartMinute = dayStartMintes;

          if (DayeEndTime)
            this.dayEndHour = DayeEndTime;

          if (DayeEndMinutes)
            this.dayEndMinute = DayeEndMinutes;
        }
        else {
          this.isHoliday = true;
          this.holidayMessage = result.message;
        }
        this.fetchEvents(true);
        this.closeSideBar();
        this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private async GetAllTherapistForAppointmentsForRoadShowByTimeZone(roadshowId) {
    this.isHoliday = false;
    this.holidayMessage = "";
    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });


    let facilityids: number[] = new Array<number>();
    if (roadshowId > 0) {
      selectFacilityString += roadshowId + ",";
      facilityids.push(roadshowId);
    }

    let therapistAppointmentSearchRequestModelExt = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModelExt.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModelExt.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModelExt.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModelExt.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModelExt.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModelExt.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModelExt.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModelExt.isRoadShow = true;
    therapistAppointmentSearchRequestModelExt.crmselectedTimeZone = this.crmselectedTimeZone;

    this.ESTTherapistCount = 0;
    this.CSTTherapistCount = 0;
    this.MSTTherapistCount = 0;
    this.PSTTherapistCount = 0;
    this.AMSTTherapistCount = 0;
    this.selectedAppointments = Array();
    this.progress.next(true);
    await this.facilityService.GetAllTherapistForAppointmentsByTimeZone(therapistAppointmentSearchRequestModelExt)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.users = Array<User>();
        if (result.isSuccess) {

          let EstTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-5');
          if (EstTherapist)
            this.ESTTherapistCount = EstTherapist.therpiastCount;

          let CSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-6');
          if (CSTTherapist)
            this.CSTTherapistCount = CSTTherapist.therpiastCount;

          let MSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-7');
          if (MSTTherapist)
            this.MSTTherapistCount = MSTTherapist.therpiastCount;

          let AMSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UMST-7');
          if (AMSTTherapist)
            this.AMSTTherapistCount = AMSTTherapist.therpiastCount;


          let PST = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-8');
          if (PST)
            this.PSTTherapistCount = PST.therpiastCount;

          if (this.timeZoneShortCode == 'UTC-5')
            this.timeZoneCodeTherapistCount = this.ESTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-6')
            this.timeZoneCodeTherapistCount = this.CSTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-7')
            this.timeZoneCodeTherapistCount = this.MSTTherapistCount;
          if (this.timeZoneShortCode == 'UMST-7')
            this.timeZoneCodeTherapistCount = this.AMSTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-8')
            this.timeZoneCodeTherapistCount = this.PSTTherapistCount;


          result.responseModel.schedulerResponseModel.forEach(value => {
            let startDate = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            let startDateExt = moment(startDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');
            let endDate = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            let endDateDateExt = moment(endDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');
            let user = new User();
            user.id = value.id;
            user.field = value.field;
            user.name = value.title;
            user.practionaerId = value.practitionerId;
            user.deliveryTechnicianId = value.deliveryTechnicianId;
            user.start = moment(startDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.end = moment(endDateDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.origStart = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            user.origEnd = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            user.therapistAvailability = value.therapistAvailability;
            user.enUserInfoId = value.enUserInfoId

            let removeBlankFacility = value.therapistAvailability != null ? value.therapistAvailability.filter(x => x.facilityName == "Road/Home Appt") : null;
            let uniqueUsersByID = _.uniqBy(removeBlankFacility, 'facilityName');

            let therapistAvailabilityFilter: TherapistCustomDatesResponseModel[];
            therapistAvailabilityFilter = [];
            for (let item of uniqueUsersByID) {
              let _model: TherapistCustomDatesResponseModel;
              _model = item;
              let facilityFilter = this.facilityResponseModel.find(x => x.crmFacilityId == item.facilityId);
              if (facilityFilter != undefined) {
                if (facilityFilter.isRemoteAssistance)
                  _model.facilityTimeZone = "";
              }
              else
                _model.facilityTimeZone = "";

              therapistAvailabilityFilter.push(_model);
            }

            user.distinctTherapistAvailability = therapistAvailabilityFilter;
            if (user.therapistAvailability)
              this.users.push(user);
          });
          if (this.users.length > 0) {
            this.isBlankMessageforTherapistShow = false;
          }
          else
            this.isBlankMessageforTherapistShow = true;

          let momentsStart = this.users.map(d => moment(d.origStart));
          let momentsEnd = this.users.map(d => moment(d.origEnd));
          let minDate = moment.min(momentsStart);
          let maxDate = moment.max(momentsEnd);

          let dayStartHour = minDate.toDate().getHours();
          let dayStartMintes = minDate.toDate().getMinutes();

          let DayeEndMinutes = maxDate.toDate().getMinutes();
          let DayeEndTime = maxDate.toDate().getHours();


          this.dayEnd = DayeEndTime;
          if (dayStartHour)
            this.dayStartHour = dayStartHour;

          if (dayStartMintes)
            this.dayStartMinute = dayStartMintes;

          if (DayeEndTime)
            this.dayEndHour = DayeEndTime;

          if (DayeEndMinutes)
            this.dayEndMinute = DayeEndMinutes;
        }
        else {
          this.isHoliday = true;
          this.holidayMessage = result.message;
        }
        this.fetchEvents(true);
        this.closeSideBar();
        this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetFacilitiesByBranchIds() {
    let crmBranchIdsString: string = '';
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      crmBranchIdsString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = crmBranchIdsString;


    this.progress.next(true);
    await this.facilityService.GetFacilitiesByBranchIds(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {

        this.facilityResponseModel = result.responseModel;
        if (this.facilityResponseModel.length > 0) {
          if (this.redirectedFacilityIdNumber) {
            let faci = this.facilityResponseModel.find(x => x.crmFacilityId == this.redirectedFacilityIdNumber);
            if (faci) {
              this.selectedAppointmentFacilities.push(faci);
              this.selectedFacility = faci;
            }
            else
              this.selectedFacility = this.facilityResponseModel[0];
          }
          else
            this.selectedFacility = this.facilityResponseModel[0];

          this.facilityId = this.selectedFacility.crmFacilityId;

          this.isBlankMessageshow = false;
        } else {
          //this.cdr.detectChanges();
          this.isBlankMessageshow = true;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }



  private async GetAllAvailableTherapistByBranchAndDate() {
    //this.selectedTherapists = new Array<TherapistResponseModel>();
    let selectBranchString = "";
    let selectTherapistString = "";
    let selectFacilityString = "";
    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });
    let facilityids: number[] = new Array<number>();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;

    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    this.progress.next(true);
    await this.facilityService.GetAllAvailableTherapistByBranchAndDate(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.therapistResponseModel = result.responseModel;
        //this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetAllTherapistForAppointments() {
    this.isHoliday = false;
    this.holidayMessage = "";

    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });
    let facilityids: number[] = new Array<number>();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    this.selectedAppointments = Array();
    this.progress.next(true);
    await this.facilityService.GetAllTherapistForAppointments(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.users = Array<User>();



        if (result.isSuccess) {

          result.responseModel.forEach(value => {
            let startDate = moment(value.startLabel, 'YYYY-MM-DD HH:mm').toDate();
            let startDateExt = moment(startDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let endDate = moment(value.endLabel, 'YYYY-MM-DD HH:mm').toDate();
            let endDateDateExt = moment(endDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let user = new User();
            user.id = value.id;
            user.field = value.field;
            user.name = value.title;
            user.start = moment(startDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.end = moment(endDateDateExt, 'YYYY-MM-DD HH:mm').toDate();

            var facilityTimeZone = value.therapistAvailability.find(x => x.facilityTimeZone);
            if (facilityTimeZone)
              user.facilityTimeZone = facilityTimeZone.facilityTimeZone;




            user.startDate = moment(startDate, 'YYYY-MM-DD HH:mm').toDate();
            user.endDate = moment(endDate, 'YYYY-MM-DD HH:mm').toDate();



            user.origStart = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            user.origEnd = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            user.therapistAvailability = value.therapistAvailability;

            user.practionaerId = value.practitionerId;
            user.deliveryTechnicianId = value.deliveryTechnicianId;

            let removeBlankFacility = value.therapistAvailability.filter(x => x.facilityName != "");
            let uniqueUsersByID = _.uniqBy(removeBlankFacility, 'facilityName');

            let therapistAvailabilityFilter: TherapistCustomDatesResponseModel[];
            therapistAvailabilityFilter = [];
            for (let item of uniqueUsersByID) {
              let _model: TherapistCustomDatesResponseModel;
              _model = item;
              let facilityFilter = this.facilityResponseModel.find(x => x.crmFacilityId == item.facilityId);

              //check the Road/Home
              if (!_model.isStartFromDefaultFacility && !_model.isStartFromHomeAddress) {
                if (facilityFilter != undefined) {
                  if (facilityFilter.isRemoteAssistance)
                    _model.facilityTimeZone = "";
                }
                else {
                  _model.facilityTimeZone = "";
                }
              }
              therapistAvailabilityFilter.push(_model);
            }
            user.distinctTherapistAvailability = therapistAvailabilityFilter;
            this.users.push(user);
          });
          if (this.users.length > 0) {
            this.isBlankMessageforTherapistShow = false;
            let momentsStart = this.users.filter(X => X.facilityTimeZone == this.crmselectedTimeZone.replace(' ', '')).map(d => moment(d.startDate));
            let momentsEnd = this.users.filter(X => X.facilityTimeZone == this.crmselectedTimeZone.replace(' ', '')).map(d => moment(d.endDate));
            let minDate = moment.min(momentsStart);
            let maxDate = moment.max(momentsEnd);

            let dayStartHour = minDate.toDate().getHours();
            let dayStartMintes = minDate.toDate().getMinutes();

            let DayeEndMinutes = maxDate.toDate().getMinutes();
            let DayeEndTime = maxDate.toDate().getHours();

            //Issue: calendar showing incorrect start time when user click on the weekends on the calendar and select back to working day selection of day - e.g 9:44, 9:07 etc
            //Assigned default value as O value if dayStartMinute=0
            this.dayEnd = DayeEndTime;
            if (dayStartHour)
              this.dayStartHour = dayStartHour;

            if (dayStartMintes)
              this.dayStartMinute = dayStartMintes;
            else
              this.dayStartMinute = 0;

            if (DayeEndTime)
              this.dayEndHour = DayeEndTime;

            if (DayeEndMinutes)
              this.dayEndMinute = DayeEndMinutes;
            else
              this.dayEndMinute = 0;

          }
          else {
            this.isBlankMessageforTherapistShow = true;
            this.dayStartHour = 8;
            this.dayStartMinute = 0;
            this.dayEndHour = 20;
            this.dayEndMinute = 0;
          }

          //var filteredTimeZones = this.users.map(d => d.facilityTimeZone);
          //filteredTimeZones.forEach(value => {
          //  let timeZone = new TimeZones();
          //  timeZone.code = value;
          //  this.timezones.push(timeZone);
          //});


        }
        else {
          this.isHoliday = true;
          this.holidayMessage = result.message;
        }

        this.fetchEvents(true);
        this.closeSideBar();
        //this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  private async GetAllTherapistForAppointmentsByTimeZone() {
    this.isHoliday = false;
    this.holidayMessage = "";

    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value?.crmBranchId + ",";
      branchIds.push(value?.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });
    let facilityids: number[] = new Array<number>();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });
    this.ESTTherapistCount = 0;
    this.CSTTherapistCount = 0;
    this.MSTTherapistCount = 0;
    this.PSTTherapistCount = 0;
    this.AMSTTherapistCount = 0;

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    this.selectedAppointments = Array();
    this.progress.next(true);
    await this.facilityService.GetAllTherapistForAppointmentsByTimeZone(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.users = Array<User>();

        if (result.isSuccess) {

          let EstTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-5');
          if (EstTherapist)
            this.ESTTherapistCount = EstTherapist.therpiastCount;

          let CSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-6');
          if (CSTTherapist)
            this.CSTTherapistCount = CSTTherapist.therpiastCount;

          let MSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-7');
          if (MSTTherapist)
            this.MSTTherapistCount = MSTTherapist.therpiastCount;


          let AMSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UMST-7');
          if (AMSTTherapist)
            this.AMSTTherapistCount = AMSTTherapist.therpiastCount;


          let PST = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-8');
          if (PST)
            this.PSTTherapistCount = PST.therpiastCount;


          if (this.timeZoneShortCode == 'UTC-5')
            this.timeZoneCodeTherapistCount = this.ESTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-6')
            this.timeZoneCodeTherapistCount = this.CSTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-7')
            this.timeZoneCodeTherapistCount = this.MSTTherapistCount;
          if (this.timeZoneShortCode == 'UMST-7')
            this.timeZoneCodeTherapistCount = this.AMSTTherapistCount;
          if (this.timeZoneShortCode == 'UTC-8')
            this.timeZoneCodeTherapistCount = this.PSTTherapistCount;


          //CSTTherapistCount
          //MSTTherapistCount
          //PSTTherapistCount


          result.responseModel.schedulerResponseModel.forEach(value => {
            let startDate = moment(value.startLabel, 'YYYY-MM-DD HH:mm').toDate();
            let startDateExt = moment(startDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let endDate = moment(value.endLabel, 'YYYY-MM-DD HH:mm').toDate();
            let endDateDateExt = moment(endDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let user = new User();
            user.id = value.id;
            user.field = value.field;
            user.name = value.title;
            user.start = moment(startDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.end = moment(endDateDateExt, 'YYYY-MM-DD HH:mm').toDate();

            var facilityTimeZone = value.therapistAvailability.find(x => x.facilityTimeZone);
            if (facilityTimeZone)
              user.facilityTimeZone = facilityTimeZone.facilityTimeZone;




            user.startDate = moment(startDate, 'YYYY-MM-DD HH:mm').toDate();
            user.endDate = moment(endDate, 'YYYY-MM-DD HH:mm').toDate();



            user.origStart = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            user.origEnd = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            user.therapistAvailability = value.therapistAvailability;

            user.practionaerId = value.practitionerId;
            user.deliveryTechnicianId = value.deliveryTechnicianId;
            user.enUserInfoId = value.enUserInfoId

            let removeBlankFacility = value.therapistAvailability.filter(x => x.facilityName != "");
            let uniqueUsersByID = _.uniqBy(removeBlankFacility, 'facilityName');

            let therapistAvailabilityFilter: TherapistCustomDatesResponseModel[];
            therapistAvailabilityFilter = [];
            for (let item of uniqueUsersByID) {
              let _model: TherapistCustomDatesResponseModel;
              _model = item;
              let facilityFilter = this.facilityResponseModel.find(x => x.crmFacilityId == item.facilityId);

              //check the Road/Home
              if (!_model.isStartFromDefaultFacility && !_model.isStartFromHomeAddress) {
                if (facilityFilter != undefined) {
                  if (facilityFilter.isRemoteAssistance)
                    _model.facilityTimeZone = "";
                }
                else {
                  _model.facilityTimeZone = "";
                }
              }
              therapistAvailabilityFilter.push(_model);
            }
            user.distinctTherapistAvailability = therapistAvailabilityFilter;
            this.users.push(user);
          });
          if (this.users.length > 0) {
            this.isBlankMessageforTherapistShow = false;
            let momentsStart = this.users.filter(X => X.facilityTimeZone == this.crmselectedTimeZone.replace(' ', '')).map(d => moment(d.startDate));
            let momentsEnd = this.users.filter(X => X.facilityTimeZone == this.crmselectedTimeZone.replace(' ', '')).map(d => moment(d.endDate));
            let minDate = moment.min(momentsStart);
            let maxDate = moment.max(momentsEnd);

            let dayStartHour = minDate.toDate().getHours();
            let dayStartMintes = minDate.toDate().getMinutes();

            let DayeEndMinutes = maxDate.toDate().getMinutes();
            let DayeEndTime = maxDate.toDate().getHours();

            //Issue: calendar showing incorrect start time when user click on the weekends on the calendar and select back to working day selection of day - e.g 9:44, 9:07 etc
            //Assigned default value as O value if dayStartMinute=0
            this.dayEnd = DayeEndTime;
            if (dayStartHour)
              this.dayStartHour = dayStartHour;

            if (dayStartMintes)
              this.dayStartMinute = dayStartMintes;
            else
              this.dayStartMinute = 0;

            if (DayeEndTime)
              this.dayEndHour = DayeEndTime;

            if (DayeEndMinutes)
              this.dayEndMinute = DayeEndMinutes;
            else
              this.dayEndMinute = 0;

          }
          else {
            this.isBlankMessageforTherapistShow = true;
            this.dayStartHour = 8;
            this.dayStartMinute = 0;
            this.dayEndHour = 20;
            this.dayEndMinute = 0;
          }

          //var filteredTimeZones = this.users.map(d => d.facilityTimeZone);
          //filteredTimeZones.forEach(value => {
          //  let timeZone = new TimeZones();
          //  timeZone.code = value;
          //  this.timezones.push(timeZone);
          //});


        }
        else {
          this.isHoliday = true;
          this.holidayMessage = result.message;
        }

        this.fetchEvents(true);
        this.closeSideBar();
        //this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  //Permission Check
  features: featureResponseModel[];
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;

  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features && this.features.length > 0) {

      let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
      if (inPer && inPer.isActive == true)
        this.isSendInvitationPermission = true;

      let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
      if (inPer1 && inPer1.isActive == true) {
        this.isAppointmentScheduledPermission = true;
        this.isAppointmentRescheduledPermission = true;
      }

      let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
      if (inPer2 && inPer2.isActive == true)
        this.isAppointmentCancelPermission = true;

      //let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
      //if (inPer3 && inPer3.isActive == true)
      //  this.isAppointmentRescheduledPermission = true;


      let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
      if (inPer4 && inPer4.isActive == true)
        this.isAppointmentMovedPermission = true;

      let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
      if (inPer5 && inPer5.isActive == true)
        this.isAppointmentOverduePermission = true;

      let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
      if (inPer6 && inPer6.isActive == true)
        this.isAppointmentNoshowPermission = true;

      let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
      if (inPer7 && inPer7.isActive == true)
        this.isAppointmentCompletedPermission = true;

    }
  }

  RedirectcalendarActions() {

    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "WeekByTherapist" && !this.isNewManageAvailability) {
      this.router.navigate(['/admin/calendar/week-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "WeekByTherapist" && this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/week-therapist-appointments-v1']);
    }
    else if (this.selectedcalendarActions.code == "ViewByDate") {
      this.router.navigate(['/admin/calendar/view-by-date']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && !this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/view-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/view-therapist-appointments-v1']);
    }
  }

  showDialogPopup({ isShow, appointment }, overlaypanelContextMenu: OverlayPanel, overlaypanelBlockSlot: OverlayPanel) {

    this.selectedAppointment = appointment;
    if (this.selectedAppointment.crmAppointmentType.toLowerCase() == 'Telehealth'.toLowerCase()) {

      if (this.remoteAppointmentTypeResponseModel.length > 0) {
        if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'Telemed'.toLowerCase()) {
          let OnlineMeeting = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'OnlineMeeting')
          if (OnlineMeeting) {
            this.convertedRemoteAppointmentType = OnlineMeeting.crmRemoteAppointmentTitle;
            this.isChangeRemoteAppointmentType = true;
            if (this.selectedAppointment.schWIPStatus.toLowerCase() == 'booked') {
              this.isChangeRemoteAppointmentType = true;
            } else {
              this.isChangeRemoteAppointmentType = false;
            }
          }
          else
            this.isChangeRemoteAppointmentType = false;

        }
        else if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'OnlineMeeting'.toLowerCase()) {

          let telemed = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'Telemed')
          if (telemed) {
            this.convertedRemoteAppointmentType = telemed.crmRemoteAppointmentTitle;
            if (this.selectedAppointment.schWIPStatus.toLowerCase() == 'booked') {
              this.isChangeRemoteAppointmentType = true;
            } else {
              this.isChangeRemoteAppointmentType = false;
            }
          }
          else
            this.isChangeRemoteAppointmentType = false;
        }
      }
    }
    overlaypanelContextMenu.toggle(event);
    overlaypanelBlockSlot.hide();
    return;
  }


  showRescheduleDialogPopup() {


    let appointment = this.selectedAppointment;
    if (appointment.crmAppointmentType == "Facility Visit") {
      this.defaultModeofAppointment = 'facilityvisit';
    }
    else if (appointment.crmAppointmentType == "Telehealth") {
      this.defaultModeofAppointment = 'remotesetup';
    }
    else if (appointment.crmAppointmentType == "Home Visit") {
      this.defaultModeofAppointment = "roadhomevisit";
    }
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {

      showHeader: true,
      closable: false,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: this.selectedAppointment.salesOrderInfo.crmSalesorderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: appointment.schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        isScheduleOrder: true,
        voidFrom: "A",
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe((session: any) => {
      this.selectedAppointment = new AppointmentInfoReponseModel();
      if (!session) {
        this.fetchEvents(true);
      }
      else if (session.isSession) {

        if (session.appointmentDate) {
          this.viewDate = moment(session.appointmentDate).toDate();
          this.GetNextDayAvailability();
        } else {
          this.fetchEvents(true);
        }
      }
      //this.HangUpCall();
    });

  }

  convertAppointmentType() {

    let appointment = this.selectedAppointment;
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to change appointment type from ' + this.selectedAppointment.schRemoteAppointmentTypeDisplayName + ' to ' + this.convertedRemoteAppointmentType + '?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }
      this.loadingCustom = true;
      this.appointmentService.ConvertRemoteAppointment(appointment.schAppointmentId, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('The appointment type has been changed successfully.');
            this.fetchEvents(true);
            this.loadingCustom = false
            this.cdr.detectChanges();
          });

        }, (httpResponseError) => {

          this.loadingCustom = false
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }


  showDialog(crmSalesOrderId) {

    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: false,
      styleClass: 'book-appt-popup-outer',
      data: {
        id: crmSalesOrderId,
        isAppointment: true,
        //defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

  }
  selectedAppoinmentCalled({ checked, selectedUser, selectedAppoinment, selectedAppointmentsInformation }) {

    this.selectedAppointments = selectedAppoinment;
    //if (this.selectedAppointments.length > 0)
    //  this.isDisabled = false;
    //else
    //  this.isDisabled = true;

    if (selectedAppointmentsInformation.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;

    this.selectedAppointmentsInformation = selectedAppointmentsInformation;
    this.dataSource = new MatTableDataSource(this.selectedAppointmentsInformation);
    this.dataSource.sort = this.sort;
    this.selectedUser = selectedUser;


  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: AppointmentInfoReponseModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
  }



  selectedRowAppoinmentCalled({ checked, therpaistId, selectedAppoinment }) {
    this.selectedAppointments = Array();
    if (this.selectedAppointments.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;

    if (checked) {
      selectedAppoinment.forEach(valueItem => {

        if (valueItem.id != 0)
          this.selectedAppointments.push(valueItem.toString());
      })
    }


    this.users.forEach(value => {
      if (value.id == therpaistId) {
        value.isChecked = checked;
      }
    });

    this.fetchEvents(true);
    this.cdr.detectChanges();
  }

  removeSlotCalled({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId }, data: any) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to remove blocked slot?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      let faciltiytimeZone = data.find(x => x.availId == availId).facilityTimeZone;
      this.therapistService.removeSlotWithTimeZone(availId, faciltiytimeZone)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            if (result && result.responseModel) {
              this.showSuccessMessage('Availability removed successfully');
              this.fetchEvents(true);
              this.cdr.detectChanges();
            }
          });
        }, (httpResponseError) => {

        });
    });
  }

  editSlotCalled({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId }, data: any) {
    debugger;
    let faciltiytimeZone = data.find(x => x.availId == availId).facilityTimeZone;

    this.therapistService.EditSlot(availId, faciltiytimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistEditBlockResponseModel>) => {
        this.processResult<TherapistEditBlockResponseModel>(result, () => {
          if (result && result.responseModel) {
            this.labelFor = "Block Slot";
            this.isBlock = true;
            this.blockSlotPopup = true;
            this.selectedtherapist = result.responseModel.therapistName;
            this.facilityId = result.responseModel.facilityId;
            this.isRightPanelBlockEnabled = true;
            this.isRightPanelEnabled = false;
            this.blockDesc = result.responseModel.desc;
            this.searchDate = result.responseModel.crmCustomDate;
            let start = result.responseModel.crmStartTime;
            let end = result.responseModel.crmEndTime;
            this.customStartTime = moment(start).format("hh:mm a");
            this.customEndTime = moment(end).format("hh:mm a");
            this.availId = availId;
            this.therapistCustomAvailabilityId = therapistCustomAvailabilityId;
            this.therapisId = result.responseModel.therapistId;
            this.selectedFacilityTimeZone = faciltiytimeZone;
            this.cdr.detectChanges();
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getBranches() {

    this.branchResponseModel = this.webStorageService.getUserBrances();
    this.setBranchSettings();
    await this.initAllMethods();
  }

  setBranchSettings() {
    this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
    if (selectedBranch !== undefined) {

      if (selectedBranch.crmBranchId !== 0) {
        //this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
        this.selectedbranches = this.branchResponseModel.filter(x => x.crmBranchId === selectedBranch.crmBranchId);
      }
    } else {
      this.selectedbranches = this.branchResponseModel;
    }
  }

  fetchEvents(isRefresh: boolean): void {

 this.appointment = new AppointmentInfoReponseModel();
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();

    if (currentClientConfig && currentClientConfig.companyGuid) {
      clientId = currentClientConfig.companyGuid.toString();
      // headers.append("clientId", currentClientConfig.companyId.toString());

    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const headers = new HttpHeaders()
      .set("clientId", clientId).append("Authorization", Authorization);

    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";
    let branchIds: number[] = new Array();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array();
    this.selectedTherapists.forEach(value => {
      selectTherapistString += value.appUserInfoId + ",";
      therpistIds.push(value.appUserInfoId);
    });
    let facilityids: number[] = new Array();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });

    if (this.isShowHomeVisit === true) {
      this.selectFacilityString = "0";
      facilityids.push(0);
    } else {
      this.selectedAppointmentFacilities.forEach(value => {
        this.selectFacilityString += value.crmFacilityId + ",";
        facilityids.push(value.crmFacilityId);
      });
    }

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();;
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;

    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    //let datepass = moment(therapistAppointmentSearchRequestModel.appointmentDate).format('MM-DD-YYYY');
    var url = 'Therapist/GetAllAppointmentsByTherapists?appointmentDate=' + selectedDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + selectTherapistString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + selectBranchString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + selectFacilityString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    if (isRefresh)
      url = url + '&rnd=' + Math.random().toString();

    this.events$ = this.http
      //.post(environment.apiBaseUrl + 'Therapist/GetAllAppointmentsByTherapists', therapistAppointmentSearchRequestModel, { headers })
      .get(environment.apiBaseUrl + url, { headers })
      //.post(environment.apiBaseUrl + 'Therapist/GetTherapistAppointmentsForCalendar/' + this.viewDate.toDateString(), { headers })
      .pipe(
        map(({ responseModel }: { responseModel: SchedulerResponseModel[] }) => {
          return responseModel.map((appointmentInfoReponseModel: SchedulerResponseModel) => {


            let therapistId;
            let is15min: boolean = false;
            if (appointmentInfoReponseModel.appointment) {
              therapistId = appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmTherapistId;
              var then = moment(appointmentInfoReponseModel.start);
              var now = moment(appointmentInfoReponseModel.end);
              let Duration = moment.duration(now.diff(then));
              let diff = now.diff(then, 'minutes')
              if (Number(diff) <= 15) {
                is15min = true;
              }
            }

            let _facilityTimeZone: string = "";
            let _crmPatientTimeZone: string = "";
            let _crmTimezone: string = "";

            _facilityTimeZone = String(appointmentInfoReponseModel.facilityTimeZone);
            _crmPatientTimeZone = String(appointmentInfoReponseModel.crmPatientTimeZone);

            if (_crmPatientTimeZone != undefined && _crmPatientTimeZone != null && _crmPatientTimeZone != "null" && _crmPatientTimeZone != "")
              _crmTimezone = _crmPatientTimeZone;
            else
              _crmTimezone = _facilityTimeZone == "null" ? "" : _facilityTimeZone;

            return {
              id: appointmentInfoReponseModel.id,
              title: appointmentInfoReponseModel.title,
              start: moment(appointmentInfoReponseModel.start, 'YYYY-MM-DD HH:mm').toDate(),
              end: moment(appointmentInfoReponseModel.end, 'YYYY-MM-DD HH:mm').toDate(),
              startLabel: moment(appointmentInfoReponseModel.startLabel, 'YYYY-MM-DD HH:mm').toDate(),
              endLabel: moment(appointmentInfoReponseModel.endLabel, 'YYYY-MM-DD HH:mm').toDate(),
              //cssClass: appointmentInfoReponseModel.id == 0 ? 'breakhours' : 'breakhours-block',
              color: appointmentInfoReponseModel.id == 0 ? colors.grey : colors.blue,
              draggable: false,
              availId: appointmentInfoReponseModel.availId,
              therapistCustomAvailabilityId: appointmentInfoReponseModel.therapistCustomAvailabilityId,
              isBlock: appointmentInfoReponseModel.isBlock,
              is15minSlot: is15min,
              //crmPatientTimeZone: _crmTimezone,
              crmPatientTimeZone: (_crmPatientTimeZone == null || _crmPatientTimeZone == "null") ? "" : String(appointmentInfoReponseModel.crmPatientTimeZone),
              facilityTimeZone: (_facilityTimeZone == null || _facilityTimeZone == "null") ? "" : String(appointmentInfoReponseModel.facilityTimeZone),
              meta: {
                appointmentInfoReponseModel,
                user: this.users.find(x => x.id == therapistId),
              },
            };
          });
        })
      );
    this.cdr.detectChanges();
  }




  fetchEventsInternal(isStart, appointment): void {

    //this.appointment = new AppointmentInfoReponseModel();
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();

    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
      // headers.append("clientId", currentClientConfig.companyId.toString());

    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const headers = new HttpHeaders()
      .set("clientId", clientId).append("Authorization", Authorization);
    let branchIds: number[] = new Array();;
    this.selectedbranches.forEach(value => {
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array();;
    this.selectedTherapists.forEach(value => {
      therpistIds.push(value.appUserInfoId);
    });
    let facilityids: number[] = new Array();;
    this.selectedAppointmentFacilities.forEach(value => {
      facilityids.push(value.crmFacilityId);
    });

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();;
    therapistAppointmentSearchRequestModel.appointmentDate = this.viewDate.toDateString();
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;
    this.events$ = this.http
      .post(environment.apiBaseUrl + 'Therapist/GetTherapistAppointmentsForCalendar', therapistAppointmentSearchRequestModel, { headers })
      .pipe(
        map(({ responseModel }: { responseModel: SchedulerResponseModel[] }) => {
          return responseModel.map((appointmentInfoReponseModel: SchedulerResponseModel) => {
            let therapistId;
            if (appointmentInfoReponseModel.appointment) {
              if (appointmentInfoReponseModel.appointment.schAppointmentId == appointment.schAppointmentId) {
                if (this.isCallStarted) {
                  appointmentInfoReponseModel.isCallStart = isStart;
                }
                if (this.isMoveStarted) {
                  appointmentInfoReponseModel.isMoveStart = isStart;
                }
              }
              therapistId = appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmTherapistId;

            }

            return {

              id: appointmentInfoReponseModel.id,
              title: appointmentInfoReponseModel.title,
              start: moment(appointmentInfoReponseModel.start, 'YYYY-MM-DD HH:mm').toDate(),
              end: moment(appointmentInfoReponseModel.end, 'YYYY-MM-DD HH:mm').toDate(),
              // cssClass: appointmentInfoReponseModel.id == 0 || appointmentInfoReponseModel.id == -1  ? 'breakhours' : 'breakhours-block',
              color: appointmentInfoReponseModel.id == 0 ? colors.grey : appointmentInfoReponseModel.id == -1 ? colors.orange : colors.transparent,
              //color: appointmentInfoReponseModel.id > 0 ? colors.transparent : colors.green,
              draggable: appointmentInfoReponseModel.id == 0 || appointmentInfoReponseModel.id == -1 ? false : true,
              meta: {
                appointmentInfoReponseModel,
                user: this.users.find(x => x.id == therapistId),
              },
            };
          });
        })
      );
    this.cdr.detectChanges();
  }


  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    this.therapistAvailabilityForReassignResponseModel = [];
    this.isAlreadyGetTherapist = false;
    if (this.globalSearchService.changeGlobalBranch)
      this.globalSearchService.changeGlobalBranch.next(null);

    if (this.progress)
      this.progress.complete();
  }

  private getSalesOrderStatus() {
    this.progress.next(true);
    this.theraistAppointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          this.salesOrderStatus = result.responseModel;
          this.salesOrderStatusCurrent = this.salesOrderStatus.filter(x => x.wipStatesName.toLowerCase() != 'booked' && x.wipStatesName.toLowerCase() != 'void');

          this.salesOrderStatusCurrent.forEach(value => {

            if (this.isAppointmentCompletedPermission && value.wipStatesName.toLowerCase() == 'complete') {
              this.appointmentsalesOrderStatusMenuitems.push({
                id: value.wipStatesId.toString(),
                label: value.wipStatesName, command: (event) => {
                  this.onMenuSelection(event.item);
                }
              });
            }

            if (this.isAppointmentNoshowPermission && value.wipStatesName.toLowerCase() == 'no show') {
              this.appointmentsalesOrderStatusMenuitems.push({
                id: value.wipStatesId.toString(),
                label: value.wipStatesName, command: (event) => {
                  this.onMenuSelection(event.item);
                }
              });
            }

          });
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  hideNoShowPopup() {
    this.reasonCommentsNoShow = '';
    this.displayNoShowAppointment = false;
    this.ConfirmSetStatusChange = false;//41466
  }

  hideCompletePopup() {

    //this.fetchEvents(true);
    //this.cdr.detectChanges();
    this.reasonCommentsComplete = "";
    this.displayCompleteAppointment = false;
    this.ConfirmSetStatusChange = false;//41466
  }

  onMenuSelection(selectedMenuitem) {

    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();

    let status: string = selectedMenuitem.label;
    let statusUpdated: string = selectedMenuitem.label;
    this.selectedappointmentsalesOrderStatusMenuitems = selectedMenuitem;
    if (status.toLowerCase() == 'void') {
      status = 'void';
      statusUpdated = 'voided';
    }
    if (status.toLowerCase() == 'booked') {
      status = 'book';
      statusUpdated = 'booked';
    }
    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      status = 'complete';
      statusUpdated = 'completed';
      this.statusType = 'Complete';//41466
      this.reasonCommentsComplete = "";
    }
    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      status = 'no show';
      statusUpdated = 'no show';
      this.statusType = 'No Show'; //41466
    }

    //41466
    if ((status.toLowerCase() == 'complete') || (status.toLowerCase() == 'no show')){
      //this.checkIfStatusCanChange(status);
      this.totalSelectedNoShowAppointments = this.selectedAppointments.length;
      this.restrictedApptFromNoShow = [];
      this.reasonCommentsNoShow = '';
      let action: string = 'No Show';
      let popupHeader: string = 'Update No Show Status of Appointment(s)';
      if (status.toLowerCase() == 'complete') {
        action = 'Complete';
        popupHeader = 'Update Complete Status of Appointment(s)';
      }
      this.callUpdateStatusForm(action, popupHeader); 

    }
    //let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    //appointmentRequestBT.IsUpdateToBrightree = false;
    //appointmentRequestBT.IsDeliveryNoteOverride = true;
    //appointmentRequestBT.IsOrderNoteOverride = true;
    //appointmentRequestBT.OrderNote = null;
    //appointmentRequestBT.DeliveryNote = null;
    //appointmentRequestBT.PractitionerName = null;
    //appointmentRequestBT.PractitionerId = 0;
    //appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    //appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    //appointmentRequestBT.ScheduledDeliveryStartDate = null;
    //appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    //appointmentRequestBT.WIPDateNeeded = moment().toDate();

    //if (status.toLowerCase() == 'complete') {
    //  let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
    //  if (selectedWipStaus) {
    //    appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
    //    appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
    //    appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
    //    appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
    //  }
    //}
    //if (status.toLowerCase() == 'no show') {
    //  let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
    //  if (selectedWipStaus) {
    //    appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
    //    appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
    //    appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
    //    appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
    //  }
    //}

    //this.events$.forEach(value => {
    //  this.selectedAppointments.forEach(valueItem => {
    //    let appointment = value.find(x => x.id == valueItem);
    //    if (appointment) {
    //      appointmentUpdateStatusRequestModel.SchAppointmentId = appointment.meta.appointmentInfoReponseModel.appointment.schAppointmentId;
    //      appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(selectedMenuitem.id);
    //      appointmentUpdateStatusRequestModel.CrmFacilityId = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmFacilityId;
    //      appointmentUpdateStatusRequestModel.CrmTherapistId = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmTherapistId;
    //      appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //      appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    //      appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    //    }
    //  });

    //  this.dialougeService.open(ConfirmDialogComponent, {
    //    data:
    //    {
    //      message: 'Are you sure you want to ' + status + ' appointment ?',
    //    },
    //    header: 'Confirmation'
    //  }).onClose.subscribe((response: boolean) => {
    //    if (!response) {
    //      return;

    //    }
    //    this.loadingCustom = true;
    //    this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
    //      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
    //      .subscribe((result: ResultOfT<boolean>) => {
    //        this.processResult<boolean>(result, () => {
    //          this.showSuccessMessage('Status has been updated successfully.');
    //          this.selectedAppointments = []
    //          this.isDisabled = true;
    //          this.users.forEach(valueItem => {
    //            valueItem.isChecked = false;
    //          });
    //          this.fetchEvents(true);
    //          this.loadingCustom = false
    //          this.cdr.detectChanges();
    //        });

    //      }, (httpResponseError) => {

    //        this.loadingCustom = false
    //        this.showErrorMessage(httpResponseError.message);
    //      });
    //  });
    //});


  }

  //41466
  checkIfStatusCanChange(statustype: string) {
    //41466 start
    this.restrictedApptFromNoShow = [];
    this.totalSelectedNoShowAppointments = this.selectedAppointments.length;
    //this.ConfirmSetStatusChange = true;
    let count: number = 1;


    this.events$.forEach(value => {
      this.selectedAppointments.forEach(valueItem => {
        let appointment = value.find(x => x.id == valueItem);
        if (appointment) {

          var temp = new tempNoShow();
          temp.crmPatientName = appointment.title;
          var patientZone = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmPatientTimeZone;
          var zone = this.timeZone.find(x => x.orgcode == patientZone);
          temp.crmPatientTimeZone = patientZone;
          temp.crmSalesOrderAPPId = appointment.meta.appointmentInfoReponseModel.appointment.crmSalesorderAppId;
          temp.schAppointmentDateTime = appointment.meta.appointmentInfoReponseModel.start;//appointment.start;
          if (this.isAllowToChangeStatus(appointment.start, patientZone)) {
            console.log("Check");
            this.restrictedApptFromNoShow.push(temp);
          }
          if (count == this.totalSelectedNoShowAppointments) {
            //status = statustype;
            //statusUpdated = statustype;
            this.reasonCommentsNoShow = '';
            //this.ConfirmSetStatusChange = true;
            let action: string = 'No Show';
            let popupHeader: string = 'Update No Show Status of Appointment(s)';
            if (statustype.toLowerCase() == 'complete') {
              action = 'Complete';
              popupHeader = 'Update Complete Status of Appointment(s)';
            }
            this.callUpdateStatusForm(action, popupHeader);

          }
          count++;
        }

      });
    });
  }

  //41466: start
  isAllowToChangeStatus(schdApptDateTime, apptPatientTimeZone): boolean {

    // var apptPatientTimeZone = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmPatientTimeZone;          

    // var tempPatientTimeZone = this.timeZone.find(x => x.orgcode == apptPatientTimeZone);
    // var orgappDateTime = moment.tz(schdApptDateTime, tempPatientTimeZone.countryName);

    // //convert to application local timezone like selectedPatientTimeZone.countryName  or use from calendar dropdown selectedTimeZone.countryName
    // tempPatientTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);
    // var appDate = orgappDateTime.tz(tempPatientTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    // var appDateTime = moment(appDate).toDate();

    // // get curretn date time from application local timezone
    // var date = moment(new Date()).tz(tempPatientTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
    // var currentDateTime = moment(date).toDate();

    if (moment(schdApptDateTime).isAfter(moment(Date()).toDate()))
      return true;

    return false;
  }

  ConfirmSetStatusCancel() {
    this.ConfirmSetStatusChange = false;
    //this.displayNoShowAppointment = false;
  }
  ConfirmSetStatusAppoinments() {
    if (this.statusType == 'Complete')
      this.displayCompleteAppointment = true;
    else
      this.displayNoShowAppointment = true;
    this.reasonCommentsNoShow = '';
    this.ConfirmSetStatusChange = false;
  }
//41466: END

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }

  CompleteNoShowAppointment() {

    if (!this.selectedAppointments || this.selectedAppointments.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }
    let status: string = this.selectedappointmentsalesOrderStatusMenuitems.label;

    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    //let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    } else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    this.events$.forEach(value => {
      this.selectedAppointments.forEach(valueItem => {
        let appointment = value.find(x => x.id == valueItem);
        if (appointment) {
          //41466:
          // Appointment is not due yet so skip those record from updating status
          let bskippAppt = null;
          if (this.restrictedApptFromNoShow.length > 0)
            bskippAppt = this.restrictedApptFromNoShow.find(x => x.crmSalesOrderAPPId == appointment.meta.appointmentInfoReponseModel.appointment.crmSalesorderAppId);

          if (bskippAppt == null) {
            let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
            appointmentUpdateStatusRequestModel.SchAppointmentId = appointment.meta.appointmentInfoReponseModel.appointment.schAppointmentId;
            appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(this.selectedappointmentsalesOrderStatusMenuitems.id);
            appointmentUpdateStatusRequestModel.CrmFacilityId = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmFacilityId;
            appointmentUpdateStatusRequestModel.CrmTherapistId = appointment.meta.appointmentInfoReponseModel.appointment.appointmentDetailInfo.crmTherapistId;
            //appointmentUpdateStatusRequestModel.SchComments = status.toLowerCase() == 'no show' ? this.reasonCommentsNoShow : this.reasonCommentsComplete;
            appointmentUpdateStatusRequestModel.SchComments = this.updateStatusforminfo?.reasonComment;
            appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
            appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
            appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
          }
        }
      });


      this.loadingCustom = true;
      this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('Status has been updated successfully.');
            this.selectedAppointments = []
            this.isDisabled = true;
            this.reasonCommentsComplete = '';
            this.reasonCommentsNoShow = '';
            this.displayNoShowAppointment = false;
            this.displayCompleteAppointment = false;
            this.users.forEach(valueItem => {
              valueItem.isChecked = false;
            });
            this.fetchEvents(true);
            this.loadingCustom = false;
            this.ConfirmSetStatusChange = false;//41466
            this.cdr.detectChanges();
          });

        }, (httpResponseError) => {

          this.loadingCustom = false
          this.showErrorMessage(httpResponseError.message);
        });

    });
  }


  closeSideBar() {

    this.blockSlotPopup = false;
    this.fillSlotPopup = false;
    this.isRightPanelEnabled = false;
    this.isRightPanelBlockEnabled = false;
    this.nearByPatients = [];
    this.blockDesc = "";
  }

  hourSegmentClick({ date, sourceEvent, userinfo }, overlaypanelBlockSlot: OverlayPanel, overlaypanelBlockSlotConextMenu: OverlayPanel) {
    if (date < this.currentDate)
      return;

    if (date < userinfo.start)
      return;

    if (date > userinfo.end)
      return;

    this.searchDate = date;
    this.searchUser = userinfo;
    this.facilityId = this.getfacilityId(date, userinfo.distinctTherapistAvailability)
    let facilityInfo = this.getfacilityIdInfo(date, userinfo.therapistAvailability);
    this.facilityId = facilityInfo[0];
    this.facilityName = facilityInfo[1];
    this.isRemoteAndFacility = facilityInfo[2];
    this.remoteFacilityId = facilityInfo[3];
    this.remoteFacilityName = facilityInfo[4];
    this.isStartFromHomeAddress = facilityInfo[5];
    this.isStartFromDefaultFacility = facilityInfo[6];
    this.selectedFacilityTimeZone = facilityInfo[7];
    this.isRemoteOnly = facilityInfo[8];

    overlaypanelBlockSlot.toggle(event);
    overlaypanelBlockSlotConextMenu.hide();


    this.searchText = "";
    //this.getPatients();
  }
  getfacilityId(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): number {

    if (therapistAvailability) {
      let facilityName = '';
      let startDate = moment(slotStartTime).toDate();
      var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
        && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
      if (facility) {
        return facility.facilityId;
      }
      else
        return 0;
    }
    else
      return 0;
  }

  getfacilityIdInfo(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): any {

    if (therapistAvailability) {
      let startDate = moment(slotStartTime).toDate();
      var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
        && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
      if (facility) {
        return [facility.facilityId,
        facility.facilityName,
        facility.isRemoteAndFacility,
        facility.remoteFacilityId,
        facility.remoteFacilityName,
        facility.isStartFromHomeAddress,
        facility.isStartFromDefaultFacility,
        facility.facilityTimeZone,
        facility.isRemoteAssistant
        ];
      }
      else
        return [];
    }
    else
      return [];
  }
  showScheduleDialog(crmSalesOrderId, schAppointmentId, crmPatientAllowedOnlyOnMyFacility) {

    //this.isScheduleAppoinment = true;
    //this.getSalesOrderInfo(crmSalesOrderId);
    if (!this.isRemoteAndFacility && this.isRemoteOnly && !crmPatientAllowedOnlyOnMyFacility) {
      this.showWarningMessage('This patient allowed for facility only.');
      return;
    }
    if (this.isRemoteAndFacility && this.IsRemoteOrFacility == 2 && !crmPatientAllowedOnlyOnMyFacility) {
      this.showWarningMessage('This patient allowed for facility only.');
      return;
    }

    //this.getSalesOrderInfo(this.salesOrderId);
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: false,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: schAppointmentId > 0 ? true : false,
        isAppointment: schAppointmentId > 0 ? true : false,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        isScheduleOrder: true,
        isAppoinmentBookByCalendar: true,
        crmTherapsitId: this.searchUser.id,
        crmTherapsitName: this.searchUser.name,
        crmPractionerId: this.searchUser.practionaerId,
        crmDeliveryTechnicianId: this.searchUser.deliveryTechnicianId,
        salesOrderdate: this.searchDate,
        isRemoteAndFacility: this.isRemoteAndFacility,
        isFacilityselected: this.isFacilityselected,
        remoteFacilityId: this.remoteFacilityId,
        facilityId: this.facilityId,
        voidFrom: "A",
        //defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe((session: any) => {

      //this.HangUpCall();
      //this.closeSideBar();
      //this.fetchEvents(true);
      if (!session) {
        this.closeSideBar();
        this.fetchEvents(true);
      }
      else if (session.isSession) {

        this.closeSideBar();
        if (session.appointmentDate) {
          this.viewDate = moment(session.appointmentDate).toDate();
          this.GetNextDayAvailability();
        } else {
          this.fetchEvents(true);
        }
      }
    });

  }


  onChange(event) {
    if (event.value) {
      let appointmentId = event.value.wipStatesId;
      let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = this.appointment.schAppointmentId;
      appointmentUpdateStatusRequestModel.SchAppointmentStatus = appointmentId;
      appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
      appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;
      appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);

      const oldAppointment = this.appointment;
      this.progress.next(true);
      this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            // this.fetchEvents();
            this.showSuccessMessage("Appointment status has been updated.");
            this.appointment = oldAppointment;

          });
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  onBranchChange(event) {

    if (event.value) {
      this.isBlankMessageforTherapistShow = false;
      this.redirectedFacilityIdNumber = null;
      this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
      this.selectedTherapists = new Array<TherapistResponseModel>();
      this.isBranchChange = true;
      //this.initAllMethods();
      this.closeSideBar();
    }
  }

  onFacilityChange(event) {

    if (event.value) {
      //this.isShowHomeVisit = false;//59330
      this.GetAllAvailableTherapistByBranchAndDate();
      this.selectedTherapists = new Array<TherapistResponseModel>();
      this.closeSideBar();
    }
  }

  onTherapistChange(event) {

    if (event.value) {
      this.closeSideBar();
    }
  }





  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }



  events: CalendarEvent[] = [];

  eventTimesChanged({

    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.events = [...this.events];
  }


  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  onSelectTerms() {
    if (this.termCondtionSelected)
      this.showConfirm = true;
    else
      this.showConfirm = false;
  }

  CloseWindow() {
    this.appointmentConfirmation = new AppointmentConfirmation();
    this.isRightPanelEnabled = false;
    this.SchedulePopup = false;
    this.DialogPopup = false;
    this.isCallStarted = false;
    this.fetchEvents(true);
    this.cdr.detectChanges();
    this.ConfirmAppointmentPupop = false;
    this.OpenCheck = false;
    this.reminderOpted = false;
    this.isRightPanelBlockEnabled = false;

  }

  sendSchedule(event: Event, overlay: OverlayPanel) {

    this.commonNextDayScheduleIds = [];
    this.commonNextDayScheduleRequest = new CommonNextDayScheduleRequest();
    this.commonNextDayScheduleRequest.lstId = [];
    this.selectedFacilities.forEach((value) => {
      this.commonNextDayScheduleId = new CommonNextDayScheduleId();
      this.commonNextDayScheduleId.intId = value.crmFacilityId;
      this.commonNextDayScheduleIds.push(this.commonNextDayScheduleId);
    });
    this.commonNextDayScheduleRequest.lstId = this.commonNextDayScheduleIds;
    this.commonNextDayScheduleRequest.dtDate = this.viewDate;
    this.appointmentService.sendFacilitySchedule(this.commonNextDayScheduleRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.selectedFacilities = [];
          this.showSuccessMessage('Schedule sent for selected facilities');
        }
        else {
          this.showWarningMessage('Error occurred.');
        }
        this.checkAll = false;

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //overlay.toggle(event);
  }

  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<ElementRef>) => {

      if (comps.length > 0) {
        this.scrollToElement(comps[0] as HTMLElement)
      }
    });



  }

  scrollToElement(el: HTMLElement): void {

    if (this.myScrollContainer) {
      //this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      let top = this.myScrollContainer.nativeElement.scrollHeight;
      if (el)
        el.scrollIntoView();
      //this.myScrollContainer.nativeElement.scroll({
      //  top: this.myScrollContainer.nativeElement.scrollHeight,
      //  left: 0,
      //  behavior: 'smooth'
      //});
    }
  }

  ReassignAppointment: boolean = false;

  ReassignAppointmentDialog() {
    this.MastertherapistAppointmentSearchRequestModel = [];
    this.GetAllAvailableTherapistForReassignment();
  }

  cancel() {

    this.selection.clear();
    this.selectedAppointments = [];
    this.selectedAppointmentsInformation = new Array<AppointmentInfoReponseModel>();
    this.dataSource = new MatTableDataSource(this.selectedAppointmentsInformation);
    this.dataSource.sort = this.sort;
    this.users.forEach(valueItem => {
      valueItem.isChecked = false;
    });
    this.isDisabled = true;
    this.fetchEvents(true);
    this.cdr.detectChanges();
    this.isAlreadyGetTherapist = false;
    this.therapistAvailabilityForReassignResponseModel = [];
  }

  async GetAllAvailableTherapistForReassignmentInline(row: any): Promise<boolean> {
    if (row.schAppointmentId) {
      if (this.MastertherapistAppointmentSearchRequestModel.length == 0 || this.MastertherapistAppointmentSearchRequestModel == undefined) {
        let result = await this.GetTheResultOnTherapistClick(row);
        return result;
      }
      else {
        this.MastertherapistAppointmentSearchRequestModel.forEach(async value => {
          if (row.schAppointmentId == value.schAppointmentIds[0]) {
            return;
          }
          else {
            let result = await this.GetTheResultOnTherapistClick(row);
            return result;
          }
        });
      }
      //const isdatAlreadyexist = this.MastertherapistAppointmentSearchRequestModel.forEach(x => x.schAppointmentIds.forEach == row.schAppointmentId);
    }
  }

  async GetTheResultOnTherapistClick(row: any): Promise<boolean> {

    let therapistAppointmentSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel = new ReassignTherapistAvailabilitySearchRequestModel();
    therapistAppointmentSearchRequestModel.appointmentDate = this.viewDate.toDateString();
    therapistAppointmentSearchRequestModel.crmTherapistId = this.selectedUser.id;
    therapistAppointmentSearchRequestModel.crmFacilityTimeZone = row.appointmentDetailInfo.crmFacilityTimeZone;
    therapistAppointmentSearchRequestModel.crmPatientTimeZone = row.appointmentDetailInfo.crmPatientTimeZone;
    therapistAppointmentSearchRequestModel.schAppointmentIds = row.schAppointmentId;
    this.progress.next(true);
    let isTherapistFound: boolean = false;
    await this.facilityService.GetAllAvailableTherapistByDateToReassign(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel.length > 0) {
          isTherapistFound = true;
          //this.isAlreadyGetTherapist = true;
          this.therapistAvailabilityForReassignResponseModel = result.responseModel;
          this.masterToggle();
          this.selectedtherapistAvailabilityForReassignResponseModel = null;
          this.schAppointmentID = row.schAppointmentId;
          this.cdr.detectChanges();
        }
        else {
          this.isAlreadyGetTherapist = false;
          this.showWarningMessage('Therapist is not available for S.O ID:' + row.salesOrderInfo?.crmSalesorderAppId);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        isTherapistFound = false;
        return isTherapistFound;
      });
    return isTherapistFound;
  }

  getavailableThrepaist(schAppointmentId: any) {
    if (this.therapistAvailabilityForReassignResponseModel && this.therapistAvailabilityForReassignResponseModel.length > 0) {
      return this.therapistAvailabilityForReassignResponseModel.filter(x => x.schAppointmentId == schAppointmentId);
    }
    else {
      return this.therapistAvailabilityForReassignResponseModel;
    }

  }

  private async GetAllAvailableTherapistForReassignment() {
    if (this.selectedAppointments.length == 0) {
      this.showWarningMessage("Please select the appoinment(s).");
      return;
    }
    if (this.selectedAppointments.length == 1) {
      let result = await this.GetAllAvailableTherapistForReassignmentInline(this.selectedAppointmentsInformation[0]);
      this.ReassignAppointment = result;
    }
    else {
      this.ReassignAppointment = true;
    }
    this.cdr.detectChanges();
  }

  async ReassignAppointmentClick() {
    if (this.selection.selected.length == 0) {
      this.showWarningMessage("Please select the appoinment(s) from the list.");
      return;
    }
    this.isAlreadyGetTherapist = false;
    this.selection.selected.forEach(x => {
      let therapistAppointmentSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel = new ReassignTherapistAvailabilitySearchRequestModel();
      let AppointmentIds: number[] = new Array<number>();
      therapistAppointmentSearchRequestModel.appointmentDate = this.viewDate.toDateString();
      therapistAppointmentSearchRequestModel.crmTherapistId = x.appointmentDetailInfo.crmTherapistId;
      therapistAppointmentSearchRequestModel.crmNewTherapistId = x.appointmentDetailInfo.crmNewTherapistId;
      therapistAppointmentSearchRequestModel.isMakeUnAvailable = x.isMakeUnAvailable == undefined ? false : x.isMakeUnAvailable;
      AppointmentIds.push(x.schAppointmentId);
      therapistAppointmentSearchRequestModel.schAppointmentIds = AppointmentIds;
      therapistAppointmentSearchRequestModel.modifiedById = this.loggedInUserId;
      this.MastertherapistAppointmentSearchRequestModel.push(therapistAppointmentSearchRequestModel);
    });

    if (this.MastertherapistAppointmentSearchRequestModel.length == 0) {
      this.showWarningMessage("Please select the therapist.");
      return;
    }

    if (this.MastertherapistAppointmentSearchRequestModel.find(x => x.crmNewTherapistId == 0) != undefined) {
      this.showWarningMessage("Therapist Selection is Missing.");
      return;
    }

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Do you really want to reassign therapist ' + '"' + this.selectedUser.name + '" for selected appointment(s) ?'
        //message: 'Do you really want to reassign therapist ' + '"' + this.selectedUser.name + '" to ' + '"' + this.selectedtherapistAvailabilityForReassignResponseModel.appUserFullname + '" for selected appointment(s) ?'
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }

      // let therapistAppointmentSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel = new ReassignTherapistAvailabilitySearchRequestModel();
      // therapistAppointmentSearchRequestModel.appointmentDate = this.viewDate.toDateString();
      // therapistAppointmentSearchRequestModel.crmTherapistId = this.selectedUser.id;
      // therapistAppointmentSearchRequestModel.crmNewTherapistId = this.selectedtherapistAvailabilityForReassignResponseModel.appUserInfoId;
      // therapistAppointmentSearchRequestModel.isMakeUnAvailable = this.checked;
      // therapistAppointmentSearchRequestModel.modifiedById = this.loggedInUserId;
      // var selectedAppt = this.selection.selected.map(function (v) {
      //   return v.schAppointmentId;
      // });
      // therapistAppointmentSearchRequestModel.schAppointmentIds = selectedAppt;

      this.progress.next(true);
      this.facilityService.ReassignALLTherapistAppointments(this.MastertherapistAppointmentSearchRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result && result.errorCode == 0) {
            this.showErrorMessage(result.message);
          }
          else if (result && result.errorCode == 1) {
            this.showWarningMessage(result.message);
            this.selection.selected.forEach(val => {
              let selectedApp = this.selectedAppointmentsInformation.find(x => x.schAppointmentId == val.schAppointmentId);
              if (selectedApp) {
                let index = this.selectedAppointmentsInformation.indexOf(selectedApp);
                this.selectedAppointmentsInformation.splice(index, 1);
              }
            });


            this.cdr.detectChanges();
            this.selection.clear();
            this.selectedAppointments = [];
            this.dataSource = new MatTableDataSource(this.selectedAppointmentsInformation);
            this.dataSource.sort = this.sort;
          }
          else {

            this.facilityService.SetAvailabilityFromReAssignment(this.MastertherapistAppointmentSearchRequestModel)
              .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
              .toPromise().then((result1) => {
                this.showSuccessMessage('Selected appointment(s) are assigned to their therapist');
                //this.showSuccessMessage('selected appointment(s) is assigned to ' + this.selectedtherapistAvailabilityForReassignResponseModel.appUserFullname);
                //therapistAppointmentSearchRequestModel = null;
                if (this.selection.selected.length == this.selectedAppointments.length) {
                  this.ReassignAppointment = false;
                  this.checked = false;
                  this.selectedAppointmentsInformation = new Array<AppointmentInfoReponseModel>();
                  this.selectedtherapistAvailabilityForReassignResponseModel = new TherapistAvailabilityForReassignResponseModel();
                  this.selectedAppointments = [];
                  this.MastertherapistAppointmentSearchRequestModel = [];
                  this.selection.clear();
                  this.initAllMethods();
                }
                else {
                  this.showSuccessMessage('Selected appointment(s) are assigned to their therapist');
                  //this.showSuccessMessage('selected appointment(s) is assigned to ' + this.selectedtherapistAvailabilityForReassignResponseModel.appUserFullname);
                  this.selection.selected.forEach(val => {
                    let selectedApp = this.selectedAppointmentsInformation.find(x => x.schAppointmentId == val.schAppointmentId);
                    if (selectedApp) {
                      let index = this.selectedAppointmentsInformation.indexOf(selectedApp);
                      this.selectedAppointmentsInformation.splice(index, 1);
                    }
                    let selectedAppt = this.selectedAppointments.find(x => x === val.schAppointmentId.toString());
                    if (selectedAppt) {
                      let index = this.selectedAppointments.indexOf(selectedAppt);
                      this.selectedAppointments.splice(index, 1);
                    }
                  });
                  this.selection.clear();
                  this.cdr.detectChanges();
                  this.dataSource = new MatTableDataSource(this.selectedAppointmentsInformation);
                  this.dataSource.sort = this.sort;
                }

              })

          }
          this.cdr.detectChanges();
        })
        .catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }


  onReassignTherapistChange(row: AppointmentInfoReponseModel, event) {
    if (row) {
      var data = row.appointmentDetailInfo.crmNewTherapistId == event.value.appUserInfoId && row.appointmentDetailInfo.crmTherapistId == this.selectedUser.id;
      if (data) {
        return;
      }
    }
    if (event.value) {
      row.appointmentDetailInfo.crmTherapistId = this.selectedUser.id;
      row.appointmentDetailInfo.crmNewTherapistId = event.value.appUserInfoId;
      row.appointmentDetailInfo.modifiedByUserInfoId = this.loggedInUserId;
    }
  }


  onisMakeUnAvailable(row: AppointmentInfoReponseModel, event: MatCheckboxChange) {
    console.log("PQR checked: " + event.checked);
    row.isMakeUnAvailable = event.checked;
    //var data = this.MastertherapistAppointmentSearchRequestModel.find(x => x.schAppointmentIds.includes() == event.value.appUserInfoId && x.crmTherapistId == this.selectedUser.id);
  }


  TherapistPhonecall(phoneNumber: string) {

    if (this.device) {
      console.log('connection is null. Initiating the call');

      let that = this;
      var params = { "To": '+1' + phoneNumber };
      let connection = this.device.connect(params);
      connection.on('ringing', function () {
        console.log('Ringing...');
      });
      connection.on('accept', function (conn) {
        that.isCallStarted = true;
        that.cdr.detectChanges();

      });
      connection.on('disconnect', function (conn) {
        that.isCallStarted = false;
        that.cdr.detectChanges();

        console.log('disconnect!');
      });
      connection.on('error', function (error) {
        that.isCallStarted = false;
        that.cdr.detectChanges();
        that.device.disconnectAll();
        that.disconnectCall(error);
      });
    }
  }
  disconnectCall(error) {

    this.isCallStarted = false;
    this.cdr.detectChanges();
    this.showWarningMessage(error.message);
  }
  async TherapistHangup() {
    console.log('Hanging up...');
    if (this.device) {

      this.device.disconnectAll();
    }
    this.isCallStarted = false;

  }


  SearchPatient() {
    this.selectedAppointmentStatus = null;
    this.getPatients(this.searchedFacilityid, this.isFacilityselected, this.IsRemoteOrFacility);
  }


  searchText: string = "";
  searchDate: Date;
  searchUser: User;
  showClear: boolean = false;

  searchCloseClick() {
    this.searchText = "";
    this.showClear = false;
    this.selectedAppointmentStatus = null;
    this.getPatients(this.searchedFacilityid, this.isFacilityselected, this.IsRemoteOrFacility);
  }

  textChange() {
    if (this.searchText && this.searchText != "") {
      this.showClear = true;
    }
    else {
      this.showClear = false;
    }
  }

  getPatients(facilityId, isFacilityselected, IsRemoteOrFacility) {

    this.fillSlotPopup = true;
    this.isFacilityselected = isFacilityselected;
    this.searchedFacilityid = facilityId;

    if (this.facilityName == "Road/Home Appt") {

      this.selectedApptType = 'RoadHomeVisit';
    } else {

      if (IsRemoteOrFacility == 1) {
        this.selectedApptType = 'FacilityVisit';
      }
      else if (IsRemoteOrFacility == 2) {
        this.selectedApptType = 'RemoteSetup';
      }
      else {
        this.selectedApptType = isFacilityselected ? 'FacilityVisit' : (this.isRemoteOnly ? 'RemoteSetup' : 'FacilityVisit');
      }
    }

    this.loadingCustom = true;
    this.IsRemoteOrFacility = IsRemoteOrFacility;
    this.appointmentService.GetNearByPatientsLicense(facilityId, this.searchText, this.searchUser.id, this.isStartFromDefaultFacility, this.isStartFromHomeAddress, this.viewDate.toDateString(), this.isBillingAddress, this.selectedApptType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .toPromise().then((result) => {
        this.nearByPatients = result.responseModel;
        this.origionalNearByPatients = result.responseModel;
        this.selectedFilSlotDateTime = this.searchDate;
        if (this.nearByPatients.length > 0) {
          this.isRightPanelEnabled = true;
          this.isRightPanelBlockEnabled = false;
          this.selectUser = this.searchUser;
        }
        else {
          if (!this.searchText) {
            this.isRightPanelEnabled = false;
            this.isRightPanelBlockEnabled = false;
          }
          else {
            this.isRightPanelEnabled = true;
            this.isRightPanelBlockEnabled = false;
          }
          this.selectUser = null;
        }
        this.cdr.detectChanges();
        this.loadingCustom = false;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loadingCustom = false;
      });
    this.onClearFilter();
  }

  selectedtherapist: string;
  labelFor: string;
  isBlock: boolean = true;

  setBlock() {
    this.blockSlotPopup = true;
    this.isBlock = true;
    this.availId = 0;
    this.therapistCustomAvailabilityId = 0;
    this.labelFor = "Block Slot";
    this.therapisId = this.searchUser.id;
    this.selectedtherapist = this.searchUser.name;
    this.isRightPanelBlockEnabled = true;
    this.isRightPanelEnabled = false;
    let start = this.searchDate;
    let end = moment(this.searchDate).add(15, 'minutes').toDate();
    this.customStartTime = start.toTimeString().split(' ')[0].split(':')[0] + ':' + start.toTimeString().split(' ')[0].split(':')[1];
    this.customEndTime = end.toTimeString().split(' ')[0].split(':')[0] + ':' + end.toTimeString().split(' ')[0].split(':')[1];
  }

  setBreak() {
    this.blockSlotPopup = true;

    this.isBlock = false;
    this.availId = 0;
    this.therapistCustomAvailabilityId = 0;
    this.labelFor = "Break Hours";
    this.therapisId = this.searchUser.id;
    this.selectedtherapist = this.searchUser.name;
    this.isRightPanelBlockEnabled = true;
    this.isRightPanelEnabled = false;
    let start = this.searchDate;
    let end = moment(this.searchDate).add(30, 'minutes').toDate();
    this.customStartTime = start.toTimeString().split(' ')[0].split(':')[0] + ':' + start.toTimeString().split(' ')[0].split(':')[1];
    this.customEndTime = end.toTimeString().split(' ')[0].split(':')[0] + ':' + end.toTimeString().split(' ')[0].split(':')[1];
  }

  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }
  onSave() {
    if (!this.blockDesc && this.isBlock) {
      this.showWarningMessage('Please add description to block slot');
      return;
    }

    if (this.customStartTime != undefined && this.customEndTime != undefined) {
      var _startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
      var _endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

      if (_endTime == _startTime) {
        this.showWarningMessage('Start Time & End Time should not be equal');
        return;
      }
      else if (_endTime < _startTime) {
        this.showWarningMessage('End Time should be greater than Start Time');
        return;
      }
    }


    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeString = null;
    requestModel.crmBreakStartTimeString = null;
    requestModel.crmDescription = this.blockDesc;

    let facilityId = this.facilityId;
    let selectedFacilityTimeZone = this.selectedFacilityTimeZone;
    let remoteFacilityId = this.remoteFacilityId;
    if (this.isBlock) {
      if (this.facilityName == 'Road/Home Appt') {
        this.isRoadHomeAppt = true;

        if (this.isStartFromDefaultFacility) {
          facilityId = this.facilityId;
          remoteFacilityId = null;
        }
        else {
          facilityId = null;
          remoteFacilityId = null;
        }
      }
      else
        this.isRoadHomeAppt = false;
    }
    else {
      if (this.facilityName == 'Road/Home Appt') {
        this.isRoadHomeAppt = true;
      }
    }

    requestModel.crmIsHoliday = true;
    requestModel.crmIsRecurring = false;
    requestModel.crmIsRoadShow = this.isRoadHomeAppt == true ? true : false;;
    requestModel.isRemoteAssistant = false;
    requestModel.isBlockSlot = true;
    requestModel.crmRecurringFrequency = 1;
    requestModel.crmRecurringType = 1;

    requestModel.crmTherapistCustomEndDateString = moment(this.searchDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomEndTimeString = moment(this.customEndTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistCustomStartDateString = moment(this.searchDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomStartTimeString = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistId = this.therapisId;
    requestModel.crmTherpistCustomAvailabilityId = this.therapistCustomAvailabilityId;
    requestModel.customId = this.availId;
    requestModel.crmIsExactDay = false;
    requestModel.isOffice = false;
    requestModel.modifiedByUserInfoId = this.loggedInUserId;
    requestModel.isFullDay = false;
    requestModel.isStartFromDefaultFacility = this.isStartFromDefaultFacility;
    requestModel.isStartFromHomeAddress = this.isStartFromHomeAddress;
    requestModel.crmFacilityId = facilityId;// this.facilityId;
    requestModel.isRemoteAndFacility = this.isRemoteAndFacility;
    requestModel.selectedFacilityTimeZone = this.selectedFacilityTimeZone;
    
    if (this.isRemoteAndFacility)
      requestModel.crmRemoteFacilityId = remoteFacilityId;//this.remoteFacilityId;
    var day = moment(this.searchDate).toDate().getDay();
    if (day == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = day - 1;
      day = Math.pow(2, power);
    }
    requestModel.crmCustomDays = day.toString();
    if (this.isBlock) {
      if (this.availId == 0)
        this.SetAvailability(requestModel);
      else
        this.UpdateAvailability(requestModel);
    }
    else {
      if (this.availId == 0){
        this.SetBreakHours(requestModel);
      }
      else{
        requestModel.isBreakHoursUpdate=true;
        this.UpdateBrekHours(requestModel);
      }
        
    }
  }
  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {

    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.addBlockSlot(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Availability saved successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.updateBlock(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Availability updated successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  SetBreakHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.setBreakHours(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Break Hours added successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  UpdateBrekHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.isButtonDisabled = true;
    this.loadingBlockSlotBrakHours = true;
    this.progress.next(true);
    this.therapistService.updateBreakHours(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Break Hours updated successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  removeBreakCalled({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId }) {
    debugger;
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to remove break hours?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.therapistService.removeBreak(availId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            if (result && result.responseModel) {
              this.cdr.detectChanges();
              this.fetchEvents(true);
            }
          });
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }
  editBreakCalled({ therapistCustomAvailabilityId: therapistCustomAvailabilityId, availId: availId }, data: SchedulerResponseModel[]) {
    debugger;
    let faciltiytimeZone = data.find(x => x.availId == availId).facilityTimeZone;
    this.therapistService.EditBreak(availId, faciltiytimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistEditBlockResponseModel>) => {
        this.processResult<TherapistEditBlockResponseModel>(result, () => {
          if (result && result.responseModel) {
            this.blockSlotPopup = true;
            this.isBlock = false;
            this.labelFor = "Add Break Hours";
            this.selectedtherapist = result.responseModel.therapistName;
            this.isRightPanelBlockEnabled = true;
            this.isRightPanelEnabled = false;
            this.blockDesc = result.responseModel.desc;
            this.searchDate = result.responseModel.crmCustomDate;
            let start = result.responseModel.crmStartTime;
            let end = result.responseModel.crmEndTime;
            this.customStartTime = moment(start).format("hh:mm a");
            this.customEndTime = moment(end).format("hh:mm a");
            this.availId = availId;
            this.therapistCustomAvailabilityId = therapistCustomAvailabilityId;
            this.selectedFacilityTimeZone = faciltiytimeZone;
            this.therapisId = result.responseModel.therapistId;
            this.facilityId = result.responseModel.facilityId;
            this.cdr.detectChanges();
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  fillSlotPopupDialog() {
    this.fillSlotPopup = true;
  }

  twoFacilityPopupDialog() {
    this.twoFacilityPopup = true;
  }

  onFilterSearch() {
    if (this.isShowHomeVisit === true) {
      //this.GetAllTherapistForAppointmentsForRoadShow(0);
      this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    }
    if (this.isBranchChange) {
      this.GetAllAvailableTherapistByBranchAndDate();
      //this.GetAllTherapistForAppointments();
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
    else if (!this.isShowHomeVisit) {
      //this.GetAllTherapistForAppointments();
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
    this.isBranchChange = false;
    this.closeSideBar();
  }
  onClearFilterSearch() {
    this.isShowHomeVisit = false;
    this.isBranchChange = false;
    this.selectedTherapists = new Array<TherapistResponseModel>();
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedbranches = this.branchResponseModel;
    this.initAllMethods();
  }




  fetchReassignTherapist(event: any) {
    alert(event);
  }


  convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const period = hours < 12 ? 'am' : 'pm';

    // Convert hours to 12hour format
    const hours12 = (hours % 12) || 12;

    // Formating time in to 12-hour format
    const formattedTime = `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
  }

  updateEndTimeOptions(selectedStartTime: string) {
    this.EndTimeSelections = this.StartTimeSelections.slice(
      this.StartTimeSelections.findIndex((time) => time.name === selectedStartTime) + 1
    );
  }

  updateStartEndTimeOptions(selectedStartTime: string, selectedEndTime: string) {
    if (selectedStartTime == undefined || selectedEndTime == undefined) {
      return;
    }

    const startIndex = this.TimeSelectionList.findIndex((time) => time.name === selectedStartTime);
    const endIndex = this.TimeSelectionList.findIndex((time) => time.name === selectedEndTime);

    // Set hours timing between Start Time and End Time
    this.StartTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);
    this.EndTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);

    if (this.customStartTime != undefined) {
      this.EndTimeSelections = this.StartTimeSelections.slice(
        this.StartTimeSelections.findIndex((time) => time.name === this.customStartTime) + 1
      );
    }
  }

  // LegendsClick() {
  //   this.LegendsisActive = !this.LegendsisActive;
  //   $("body").toggleClass("show-legends");
  // }
  // LegendsClickClose() {
  //   this.LegendsisActive = !this.LegendsisActive;
  //   $("body").removeClass("show-legends");
  // }

  private async getAllClassificationType() {
    await this.appointmentService.GetAllClassificationType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<FilterClassificationTypeResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.filterClassificationList = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  RefreshPage(){
    this.onFilterSearch();
  }


  callUpdateStatusForm(action: string, header: string) {

   /*  if (this.totalSelectedNoShowAppointments == this.restrictedApptFromNoShow.length) {
      let message: string = `The ${action} status cannot be assigned to the selected appointments as the appointment time has not yet arrived.`;
      //this.showWarningMessage(message);      
       
      this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
        data: {
          action: 'Alert',
          popupHeader: 'Alert',
          IsAllowToComplete: false, 
          message: message
        },
      }).onClose.subscribe((response: any) => {
        if (response.action == 'Cancel') {
          return;
        }
      }); 
      return; 
    } */
    this.dialougeService.open(UpdateStatusOfAppointmentsComponent, {
      data: {
        action: action,
        popupHeader: header,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        restrictedApptForStatus: this.restrictedApptFromNoShow,
        totalSelectedItem: this.totalSelectedNoShowAppointments,
        isMultiSelected: this.totalSelectedNoShowAppointments == 1 ? 'False' : 'True',
      },
    }).onClose.subscribe((response: any) => {
      if (response.action == 'Confirm') {
        this.updateStatusforminfo = response;
        this.CompleteNoShowAppointment();
      }
      if (response.action == 'Cancel') {
        this.ConfirmSetStatusCancel();
      }
    });


  }



}
