export class AppointmentCallResponseModel {
  schAppointmentCallId: number;
  schAppointmentCallListId: number;
  crmSalesOrderId: number;
  schAppointmentId: number;
  schAppointmentCallBy: number;
  schAppointmentCallByRole: string;
  schAppointmentCallByPerson: string;
  schAppointmentCallStatus: string;
  schAppointmentCallNote: string;
  schNextCallDueDate: Date;
  schNextCallDueDateString: string;
  schAppointmentCallToNumber: string;
  callSID: string;
  modifiedByUserInfoId: number;
  schTimeZone: string;
  recordingSID: string;
  recordCallUrl: string;
  isRecordEnable: boolean;
}
