import { NumberValueAccessor } from '@angular/forms';
import { TherapistCertificationResponseModel } from './therapist-certification-response-model';

export class TherapistResponseModel {
  appUserInfoId: number;
  appUserLname: string;
  appUserFname: string;
  appUserFullname: string;
  crmBranchId: number;
  isActive: boolean;
  appUserRegistrationStatus: string;
  crmFacilityName: string;
  crmFacilityDistance: number;
  crmFacilityDistanceInMiles: string;
  deviceName: string;
  crmProfileImageSrc: string;
  crmAddress: string;
  crmBioData: string;
  crmState: string;
  crmZipCode: string;
  isTherapistAvailablity: boolean;
  therapistCertification: TherapistCertificationResponseModel[];
  crmFacilityFirstAvailableStartDate: Date;
  isSelect: boolean = false;
  crmIsAllowSelfScheduleAppointments: boolean;
  facilityTimeZone: string;
  enUserInfoId: string;
}


