import { FormGroup } from '@angular/forms';

export class CommonFunctions {
  public static isNullOrEmpty(value: string): boolean {
    return (value == null || value.length === 0);
  }

  public static isUndefinedOrEmpty(value: string): boolean {
    return (value === undefined || value.length === 0);
  }

  public static isNullOrUndefined(value: any): boolean {
    return (value === null || value === undefined);
  }

  public static findInvalidControls(formGroup: FormGroup) {
    const invalid = [];
    const controls = formGroup.controls;

    for (const name in controls)
      if (controls[name].invalid)
        invalid.push(name);

    return invalid;
  }

  public static sortArray(a: any, b: any, field: string): number {
    let valueA = a[field];
    if (valueA)
      valueA = valueA.toUpperCase();

    let valueB = b[field];
    if (valueB)
      valueB = valueB.toUpperCase();

    if (valueA < valueB)
      return -1;

    if (valueA > valueB)
      return 1;

    return 0;
  }

  public static sortArrayByAscOrDesc(a: any, b: any, field: string, order: number): number {
    if (order === 1) {
      if (typeof a[field] === 'string') {
        const sortDesc = a[field] < b[field] ? -1 : 0;
        return a[field] > b[field] ? 1 : sortDesc;
      }

      return a[field] - b[field];
    }
    else {
      if (typeof a[field] === 'string') {
        const sortDesc = a[field] < b[field] ? 1 : 0;
        return a[field] > b[field] ? -1 : sortDesc;
      }

      return b[field] - a[field];
    }
  }

  public static getValueOrDefault<T>(value: any, defaultValue: T): T {
    if (value === null || value === undefined || value.toString().length === 0)
      return defaultValue;

    return value as T;
  }

  public static numberWithCommas(targetNumber: number): string {
    return targetNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public static print(content: string) {
    const printWindow = window.open('', '');//, 'left=0,top=0,width=auto,height=auto,toolbar=0,scrollbars=0,status=0');
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  static enumSelector(definition): any {
    return Object.keys(definition)
      .map(key => ({ value: key, title: definition[key] }));
  }
}
