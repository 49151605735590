import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { BehaviorSubject } from 'rxjs';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { KPIReportFiltersResponseModel } from 'src/app/shared/models/response/kpi-report-filters-response-model';
import { KPIAddReportRequestModel, KPIReportSortOrderRequestModel, KPIReportVisualizationModel, UserReportFilterPrefrenceRequestModel } from 'src/app/shared/models/request/kpiaddreport-request-model';
import { CombinedChartModel, KPIReportDataTableResponse, KPIReportRolePermissionRequestModel, KPIReportRolePermissionResponseModel, KPIReportSearchRequestModel, KPIUserPrefrenceResponseModel, YearModel } from 'src/app/shared/models/response/kpi-report-response-model';
import { KPIReportResponseModel } from 'src/app/shared/models/response/role-feature-modeul-permission';
@Injectable({
    providedIn: 'root'
})
export class KPIReportService extends BaseService {

    private ReportTitle = new BehaviorSubject('');
    currentTitle = this.ReportTitle.asObservable();
    constructor(httpService: HttpClient, webStorageService: WebStorageService) {
        super(httpService, webStorageService);
    }

    getKPIReportFilters() {
        return this.get<ResultOfT<KPIReportFiltersResponseModel>>('KPIReport/GetKPIReportFilters');
    }

    AddKPIReport(lstRequestModel: KPIAddReportRequestModel) {
        return this.post<ResultOfT<any>>('KPIReport/KPIUserReportPrefrencesCrudOprations', lstRequestModel);
    }

    AddKPIReportUserReportFilterPrefrences(userReportFilterPrefrenceRequestModel: UserReportFilterPrefrenceRequestModel[]) {
        return this.post<ResultOfT<any>>('KPIReport/AddKPIReportUserReportFilterPrefrences', userReportFilterPrefrenceRequestModel);
    }

    getKPIUserPrefrencesReportList(userInforId: number) {
        return this.get<ResultOfT<KPIUserPrefrenceResponseModel[]>>('KPIReport/GetKPIUserPrefrencesReportList/' + userInforId);
    }

    GetKPIReportDetails(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<YearModel[]>>('KPIReport/GetKPIReport', KPISearchRequestModel);
    }

    GetKPIMovingReportDetails(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<YearModel[]>>('KPIReport/GetKPIMovingReport', KPISearchRequestModel);
    }

    GetKPIReportDataTable(KPISearchRequestModel: KPIReportSearchRequestModel) {
        //return this.post<ResultOfT<KPIReportDataTableResponse[]>>('KPIReport/GetKPIReportDataTable', KPISearchRequestModel);

        return this.post<ResultOfT<PagedResponseModel<KPIReportDataTableResponse>>>('KPIReport/GetKPIReportDataTable', KPISearchRequestModel);
    }

    GetKPIReportDataTableToExport(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<KPIReportDataTableResponse[]>>('KPIReport/GetKPIReportDataTableToExport', KPISearchRequestModel);
    }

    GetKPIReportDataTableString(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<any[]>>('KPIReport/GetKPIReportDataTableString', KPISearchRequestModel);
    }

    ExportKPIReportDataTable(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.downloadCsvFile('KPIReport/ExportKPIReportDataTable', KPISearchRequestModel);
    }

    UpdateReportName(kpiuserReportPrefrenceID: number, reportName: string) {
        return this.post<ResultOfT<boolean>>('KPIReport/UpdateReportName?kpiuserReportPrefrenceID=' + kpiuserReportPrefrenceID + '&reportName=' + reportName, null);
    }

    DeleteKPIReport(kpiuserReportPrefrenceID: number) {
        return this.post<ResultOfT<boolean>>('KPIReport/DeleteKPIReport?kpiuserReportPrefrenceID=' + kpiuserReportPrefrenceID, null);
    }

    DeleteKPIReportFilterPrefrences(kpiuserReportPrefrenceID: number, filterName: string) {
        return this.post<ResultOfT<boolean>>('KPIReport/DeleteKPIReportFilterPrefrences?kpiuserReportPrefrenceID=' + kpiuserReportPrefrenceID + '&filterName=' + filterName, null);
    }


    GetKPIReportMasterDetails() {
        return this.get<ResultOfT<KPIReportResponseModel[]>>('KPIReport/GetKPIReportMasterDetail');
    }

    GetKPIReportMasterDetailWithPermission(appRoleID: number) {
        return this.get<ResultOfT<KPIReportResponseModel[]>>('KPIReport/GetKPIReportMasterDetailWithPermission/' + appRoleID);
    }

    GetKPIReportMovingAverageDataTable(KPISearchRequestModel: KPIReportSearchRequestModel) {
        //return this.post<ResultOfT<KPIReportDataTableResponse[]>>('KPIReport/GetKPIReportMovingAverageDataTable', KPISearchRequestModel);
        return this.post<ResultOfT<PagedResponseModel<CombinedChartModel>>>('KPIReport/GetKPIReportMovingAverageDataTable', KPISearchRequestModel);
    }

    GetKPIReportMovingAverageDataTableToExport(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<KPIReportDataTableResponse[]>>('KPIReport/GetKPIReportMovingAverageDataTableToExport', KPISearchRequestModel);
    }

    GetKPIReportMovingAverageDataTableString(KPISearchRequestModel: KPIReportSearchRequestModel) {
        return this.post<ResultOfT<any[]>>('KPIReport/GetKPIReportMovingAverageDataTableString', KPISearchRequestModel);
    }

    UpdateKPIReportSortOrder(kpiReportSortOrderRequestModel: KPIReportSortOrderRequestModel[]) {
        return this.post<ResultOfT<KPIReportSortOrderRequestModel[]>>('KPIReport/UpdateKPIReportSortOrder', kpiReportSortOrderRequestModel);
    }

    AddRolePermissionKPIReport(KPISearchRequestModel: KPIReportRolePermissionRequestModel[]) {
        return this.post<ResultOfT<boolean>>('KPIReport/AddRolePermissionKPIReport', KPISearchRequestModel);
    }

    GetKPIReportRolePermissionDetail(appRoleId: number) {
        return this.get<ResultOfT<KPIReportRolePermissionResponseModel[]>>('KPIReport/GetKPIReportRolePermissionDetail/' + appRoleId);
    }

    AddEditUserReportLayoutPrefrences(kpiReportLayout: number, appUserInfoId: number) {
        return this.post<ResultOfT<boolean>>('KPIReport/AddEditUserReportLayoutPrefrences?kpiReportLayout=' + kpiReportLayout + '&appUserInfoId=' + appUserInfoId, null);
    }

    GetUserReportLayoutPrefrences(appUserInfoId: number) {
        return this.get<ResultOfT<number>>('KPIReport/GetUserReportLayoutPrefrences/' + appUserInfoId);
    }

    AddEditKPIReportVisualization(kpiReportVisualizationModel: KPIReportVisualizationModel[]) {
        return this.post<ResultOfT<any>>('KPIReport/AddEditKPIReportVisualization', kpiReportVisualizationModel);
    }

    UpdateColumnChartType(kpiuserReportPrefrenceID: number, columnChartType: string) {
        return this.post<ResultOfT<boolean>>('KPIReport/UpdateColumnChartType?kpiuserReportPrefrenceID=' + kpiuserReportPrefrenceID + '&columnChartType=' + columnChartType, null);
    }
}
