import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpStatusCode } from '../enums';
import { AuthenticationService } from '../services/authentication.service';

//import { DialogService } from '../servicies/dialog.service';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((responseError) => {
      if (responseError.status === HttpStatusCode.Unauthorized) {
        this.authenticationService.signOut();
        return of<HttpEvent<any>>(responseError);
      }
      else {
        if (responseError instanceof HttpErrorResponse &&
          responseError.error instanceof Blob &&
          responseError.error.type === "application/json") {
          return this.handleBlobErrorResponse(responseError);
        }
        else {
          try {
            const errorMessage = JSON.parse(responseError.error);
            return throwError(new HttpErrorResponse(
              {
                error: errorMessage,
                headers: responseError.headers,
                status: responseError.status,
                statusText: responseError.statusText,
                url: responseError.url,
              }));
          }
          catch (e) {
            return throwError(responseError);
          }
        }
      }

    }));
  }

  private handleBlobErrorResponse(responseError: HttpErrorResponse): Observable<any> {
    const reader = new FileReader();

    return from(new Promise<any>((resolve, reject) => {
      reader.onload = (e: Event) => {
        try {
          const errorMessage = JSON.parse((<any>e.target).result);
          reject(new HttpErrorResponse(
            {
              error: errorMessage,
              headers: responseError.headers,
              status: responseError.status,
              statusText: responseError.statusText,
              url: responseError.url,
            }));
        }
        catch (e) {
          reject(responseError);
        }
      };

      reader.onerror = (e) => { reject(responseError); };
      reader.readAsText(responseError.error);
    }));
  }
}
