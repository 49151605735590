import { DOCUMENT } from '@angular/common';
import { Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { SettingsService } from './admin/servicies/settings.service';
import { ResultOfT } from './shared/models/response/result';
import { SettingsGeneralResponseModel } from './shared/models/response/settings/settings-general-response-model';
import { CompanyService } from './shared/services/company.service';
import { WebStorageService } from './shared/services/web-storage.service';
import { VersionCheckService } from './shared/services/versioncheck.service';
import { ConfigResponseModel } from './shared/models/response/client-config-response';
import { Constants } from './shared/constants';
import { AuthenticationService } from './shared/services/authentication.service';
import { BaseComponent } from './shared/components/base.component';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  encapsulation: ViewEncapsulation.Emulated,
})


export class AppComponent extends BaseComponent implements OnInit {
  title = 'Appoint360';
  faviconBase64 = "";
  private unsubscriber = new Subject<boolean>();
  settingsGeneralResponseModel: SettingsGeneralResponseModel;
  domainUrl: string = '';
  loading: boolean;

  async ngOnInit() {
    let companyDetails = this.webStorageService.getClientConfig();
    
    if(!companyDetails){
      this.domainUrl = location.origin.replace(Constants.httpReplace, "").replace(Constants.httpsReplace, "");
      companyDetails = await this.getClinetDetails();
    }

    if (companyDetails) {
      const imgBase64Path = 'data:image/png;base64,' + companyDetails.companyFavicon;
      this.setFavicon(imgBase64Path);
    }
    this.companyService.cfavivonSubject.subscribe((data) => {
      const imgBase64Path = 'data:image/png;base64,' + companyDetails.companyFavicon;
      this.setFavicon(imgBase64Path);
    });
    // await this.getCompanyImage(companyDetails.companyId);
    //// commented for reload
    ////this.loadStyle(companyDetails.newThemeCss)
    ////const body = document.getElementsByTagName('body')[0];
    ////body.classList.add(companyDetails.companyId);
    if (companyDetails != null && companyDetails != undefined) {
      this.loadStyle(companyDetails.newThemeCss);
      const body = document.getElementsByTagName('body')[0];
      // body.classList.add(companyDetails.newThemeCss);
      let cssName = companyDetails.newThemeCss.substring(0, companyDetails.newThemeCss.indexOf('.'))
      body.classList.add(cssName);

      // let cssName = companyDetails.newThemeCss.substring(0, companyDetails.newThemeCss.indexOf('.'))
      // body.classList.add(cssName);
    }

    this.versionCheckService.initVersionCheck();
    //let domainUrl = location.origin.replace("http://", "").replace("https://", "");
    // if(domainUrl.indexOf('4200')>0){
    //   this.loadStyle('msc-client.css')
    //    const body = document.getElementsByTagName('body')[0];
    //    body.classList.add('sh');      
    // } 
    // else if(domainUrl.indexOf('4401')>0){
    //   this.loadStyle('sh-client.css')
    //   const body = document.getElementsByTagName('body')[0];
    //   body.classList.add('msc');
    // }

    //  const body = document.getElementsByTagName('body')[0];
    //  body.classList.add('sh');
  };

  constructor(
    private webStorageService: WebStorageService,
    private settingsService: SettingsService,
    private companyService: CompanyService,
    private versionCheckService: VersionCheckService,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    messageService: MessageService) {
      super(messageService);

    // this.router.events.subscribe(event => {
    //   if(event.constructor.name === "NavigationStart") {
    //     var $el;
    //     var $el2;
    //     var $el3;

    //     setTimeout(function() {
    //       $el = document.querySelectorAll('.placehold');
    //       for (var i = 0; i < $el.length; i++) {
    //         $el[i].classList.add('empty-view');
    //       }
    //     }, 1);

    //     setTimeout(function() {
    //       $el2 = document.querySelectorAll('.rounded');
    //       for (var j = 0; j < $el2.length; j++) {
    //         $el2[j].classList.add('empty-round');
    //       }
    //     }, 1);

    //     setTimeout(function() {
    //       $el3 = document.querySelectorAll('.empty-view');
    //       for (var k = 0; k < $el3.length; k++) {
    //         $el3[k].classList.remove('empty-round');
    //         $el3[k].classList.remove('empty-view');
    //       }
    //     }, 2000);
    //   }
    // });
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

  private async getCompanyImage(companyId) {
    await this.settingsService.getGeneralSettings(companyId.toString())
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((result) => {
        if (result.isSuccess) {
          const imgBase64Path = 'data:image/png;base64,' + result.responseModel.companyFavicon;
          this.setFavicon(imgBase64Path);
          this.companyService.sendcompanyfaviconImage(result.responseModel.companyFavicon);
          this.companyService.sendcompanylogoImage(result.responseModel.companyLogo);
        }
      }, (httpResponseError) => {
      });
  }

  setFavicon(url: any) {
    this.removeFavicon();
    var link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  removeFavicon() {
    var links = document.getElementsByTagName('link');
    var head = document.getElementsByTagName('head')[0];
    for (var i = 0; i < links.length; i++) {
      if (links[i].getAttribute('rel') === 'icon') {
        head.removeChild(links[i])
      }
    }
  }

  private async getClinetDetails():Promise<ConfigResponseModel> {
    this.loading = true;
    let configResponseModel: ConfigResponseModel;
    await this.authenticationService.getClinetDetails(this.domainUrl)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ConfigResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.webStorageService.storeClientConfig(result.responseModel);
              configResponseModel = result.responseModel;
            }
          }
        });
      });
      return configResponseModel;
  }
}



