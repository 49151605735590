import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment-confirmation';
import { MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { fromEvent, Subject } from 'rxjs';
import { NgAddToCalendarService, ICalendarEvent } from '@trademe/ng-add-to-calendar';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { finalize, takeUntil } from 'rxjs/operators';
import { FeatureGroupService } from 'src/app/shared/services/seetings.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { SalesOrderLocalModel } from '../../shared/models/response/sales-order-current';
import { Router } from '@angular/router';
import { MouseEvent } from '@agm/core';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { ResultOfT } from 'src/app/shared/models/response/result';
import * as moment from 'moment';
import { SettingsService } from '../services/settings.service';
import { PatientService } from '../services/patient.service';
@Component({
  selector: 'app-appointment-confirmation',
  templateUrl: './appointment-confirmation.component.html',
  styleUrls: ['./appointment-confirmation.component.sass']
})
export class AppointmentConfirmationComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  Addtocalendar: { name: string; code: string; }[];
  appointmentConfirmation: AppointmentConfirmation;
  appointmentStartDate: Date;
  appointmentEndDate: Date;
  appointmentTimeZone: string = "";
  appointmentTimeZoneCode: string;
  duration: string;
  description: string;
  facilityName: string;
  facilityAddress: string;
  facilityAddressLine1: string;
  facilityAddressLine2: string;
  facilityCity: string;
  facilityState: string;
  facilityCountry: string;
  facilityZipCode: string;
  facilityLatitude: number;
  facilityLongitude: number;
  crmBioData: string;
  distance: string;
  dueAmount: number;
  dateFormat: string;
  selectedName: { name: string; code: string; };
  durationInMinutes: number;
  therapistName: string;
  selectedRemoteAppointmentType: string;
  selectedAppointmentTypePopUp: string;
  patientBookingTypeId: string = '';
  isTherapistFacilityType: boolean;
  isTherapistRemoteType: boolean;
  isTherapistRemoteAndFaciltyType: boolean;
  DMENumber: string;
  ShowDueAmount: boolean = false;
  ShowDeliveryNote: boolean = false;
  showFacilityAddress: boolean = false;
  public googleCalendarEventUrl: SafeUrl;
  public newEvent: ICalendarEvent;
  salesOrderLocal: SalesOrderLocalModel;
  patientName: string;
  patientLatitude: number;
  patientLongitude: number;
  patientAddress: string;
  email: string = "";
  patientPhone: string = "";
  patientMobileNumber: string = "";
  salesOrderData: SalesOrderResponseModel;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  markers: marker[] = [];
  zoom: number = 8;
  loadingCustom: boolean;

  activeStepNo: number = 1;
  appointmentTitle: string;
  appointmentSelectLabel: string;

  // initial center position for the map
  lat: number = 51.673858;
  lng: number = 7.815982;
  RescheduleAppointment: boolean = false;
  CancelAppointment: boolean = false;
  cancelnote: string;
  schAppointmentId: number;
  schIsReminderOptedSms: boolean;
  schIsReminderOptedEmail: boolean;
  constructor(messageService: MessageService,
    private webStorageService: WebStorageService,
    private addToCalendarService: NgAddToCalendarService,
    private patientService: PatientService,
    private settingsService: SettingsService,
    private router: Router) {
    super(messageService);
    this.getWipStatusBTmapping();

    let settingResult = this.webStorageService.getPatientSalesOrderSettings();
    if (settingResult) {
      this.dateFormat = settingResult.dateFormat;
      if (settingResult.showDueAmount.toLowerCase() === "true")
        this.ShowDueAmount = true;
      else
        this.ShowDueAmount = false;

      if (settingResult.showDeliveryNote.toLowerCase() === "true")
        this.ShowDeliveryNote = true;
      else
        this.ShowDeliveryNote = false;
    }

    this.patientBookingTypeId = this.webStorageService.getPatientBookingTypeId();

    this.salesOrderData = this.webStorageService.getSalesOrderData();
    this.patientName = this.salesOrderData?.patientInfo?.crmPatientFirstName + " " + this.salesOrderData?.patientInfo?.crmPatientLastName;

    let onlineAppointmentTypeResponseModel = this.webStorageService.getOnlineAppointmentTypeNames();

    if (onlineAppointmentTypeResponseModel.length > 0) {
      if (this.patientBookingTypeId == "R") {
        let RemoteSetup = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
        if (RemoteSetup) {
          this.appointmentTitle = RemoteSetup.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Remote';
        }
      }
      else if (this.patientBookingTypeId == "T") {
        let TherapistVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
        if (TherapistVisit) {
          this.appointmentTitle = TherapistVisit.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Therapist';
        }
      }
      else if (this.patientBookingTypeId == "SG") {
        let SelfGuided = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
        if (SelfGuided) {
          this.appointmentTitle = SelfGuided.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Self Guided';
        }
      }
      else if (this.patientBookingTypeId == "F") {
        let FacilityVisit = onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
        if (FacilityVisit) {
          this.appointmentTitle = FacilityVisit.crmOnlineAppointmentTitle;
          this.appointmentSelectLabel = 'Select Clinic';
        }
      }
    }
    this.activeStepNo = 1;
  }

  ngOnInit() {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
      //this.showErrorMessage(`You can't make changes or go back at this time.`);
    });

    this.Addtocalendar = [
      { name: 'Select Calendar', code: 'S' },
      { name: 'Google', code: 'G' },
      { name: 'Yahoo', code: 'Y' },
      { name: 'Outlook', code: 'O' }
    ];
    this.salesOrderLocal = this.webStorageService.getSalesOrderId();
    if (!this.salesOrderLocal) {
      let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
      this.router.navigate(["/patient/home/" + url], { queryParams: { utm_source: 'email' } });
    }

    this.patientName = this.webStorageService.getPatientName();
    this.salesOrderData = this.webStorageService.getSalesOrderData();
    this.patientBookingTypeId = this.webStorageService.getPatientBookingTypeId();
    this.showFacilityAddress = (this.patientBookingTypeId == 'T' && !this.isTherapistRemoteType) ? true : this.patientBookingTypeId == 'F' ? true : false;

    if (!this.salesOrderLocal) {
      this.showWarningMessage("No sales order selected.");
    }
    this.email = this.salesOrderLocal?.patientEmail;
    this.patientPhone = this.salesOrderLocal?.patientPhone;
    this.patientMobileNumber = this.salesOrderLocal?.patientMobile;

    this.appointmentConfirmation = new AppointmentConfirmation();
    this.appointmentConfirmation = this.webStorageService.getAppointmentConfirmation();
    this.DMENumber = this.webStorageService.getDMENumber();
    this.loading = true;
    if (this.appointmentConfirmation) {
      this.appointmentStartDate = this.appointmentConfirmation.appointmentStartDate;
      this.appointmentEndDate = this.appointmentConfirmation.appointmentEndDate;
      this.appointmentTimeZone = this.appointmentConfirmation.appointmentTimeZone;
      this.appointmentTimeZoneCode = this.appointmentConfirmation.appointmentTimeZoneCode;
      this.description = this.appointmentConfirmation.description;
      this.distance = this.appointmentConfirmation.distance;
      this.duration = this.appointmentConfirmation.duration;
      this.facilityAddress = this.appointmentConfirmation.facilityAddress;
      this.facilityAddressLine1 = this.appointmentConfirmation.facilityAddressLine1;
      this.facilityAddressLine2 = this.appointmentConfirmation.facilityAddressLine2;
      this.facilityCity = this.appointmentConfirmation.facilityCity;
      this.facilityState = this.appointmentConfirmation.facilityState;
      this.facilityCountry = this.appointmentConfirmation.facilityCountry;
      this.facilityZipCode = this.appointmentConfirmation.facilityZipCode;
      this.facilityName = this.appointmentConfirmation.facilityName;
      this.facilityLatitude = this.appointmentConfirmation.facilityLatitude;
      this.facilityLongitude = this.appointmentConfirmation.facilityLongitude;
      this.crmBioData = this.appointmentConfirmation.crmBioData;
      this.dueAmount = this.appointmentConfirmation.dueAmount;
      this.durationInMinutes = this.appointmentConfirmation.durationInMinutes;
      this.therapistName = this.appointmentConfirmation.crmTherapistname;
      this.selectedRemoteAppointmentType = this.appointmentConfirmation.remoteType;
      this.isTherapistFacilityType = this.appointmentConfirmation.isTherapistFacilityType;
      this.isTherapistRemoteType = this.appointmentConfirmation.isTherapistRemoteType;
      this.isTherapistRemoteAndFaciltyType = this.appointmentConfirmation.isTherapistRemoteAndFaciltyType
      this.selectedAppointmentTypePopUp = this.appointmentConfirmation.selectedAppointmentTypePopUp.toLowerCase() == "ClinicVisit".toLowerCase() ? "Clinic Visit" : "Remote Setup";
      this.email = this.appointmentConfirmation.PatientEmail;
      this.patientPhone = this.appointmentConfirmation.PatientPhone.replaceAll('-', '');
      this.patientMobileNumber = this.appointmentConfirmation.PatientMobileNumber.replaceAll('-', '');
      this.patientLatitude = this.salesOrderData.patientInfo.crmLatitude;
      this.patientLongitude = this.salesOrderData.patientInfo.crmLongitude;
      this.patientAddress = this.salesOrderData.patientInfo.crmPatientAddress;
      this.schAppointmentId = this.appointmentConfirmation.schAppointmentId;
      this.schIsReminderOptedEmail = this.appointmentConfirmation.schIsReminderOptedEmail;
      this.schIsReminderOptedSms = this.appointmentConfirmation.schIsReminderOptedSms;

      if (this.isTherapistFacilityType || this.patientBookingTypeId == 'F') {
        this.markers = [
          {
            lat: this.facilityLatitude,
            lng: this.facilityLongitude,
            label: this.facilityName,
            draggable: false,
            type: 'F',
            address: this.facilityAddress
          },
          {
            lat: this.salesOrderData.patientInfo.crmLatitude,
            lng: this.salesOrderData.patientInfo.crmLongitude,
            label: this.patientName,
            draggable: false,
            type: 'P',
            address: this.patientAddress
          }
        ]
      }
    }
    else {
      this.showErrorMessage("No appointment is scheduled.");
    }

    this.webStorageService.deleteSelectedFacilies();
    this.webStorageService.deleteSalesOrderId();
    this.webStorageService.deleteAppointmentConfirmation();
    this.loading = false;
  }

  onEventClick() {

    if (this.selectedName.code === "S") {
      return;
    }
    this.newEvent = {
      // Event title
      title: 'Appointment booked',
      // Event start date
      start: new Date(this.appointmentStartDate),
      // Event duration (IN MINUTES)
      duration: this.durationInMinutes,
      // If an end time is set, this will take precedence over duration (optional)
      end: new Date(this.appointmentEndDate),
      // Event Address (optional)
      address: this.facilityAddress,
      // Event Description (optional)
      description: this.description
    };
    if (this.selectedName.code === "G")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.google, this.newEvent), '_blank');
    else if (this.selectedName.code === "O")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.outlookLive, this.newEvent), '_blank');
    else if (this.selectedName.code === "Y")
      window.open(this.addToCalendarService.getHrefFor(this.addToCalendarService.calendarType.yahoo, this.newEvent), '_blank');
    else
      return;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

  }

  /****** Google Map *******/
  // google maps zoom level

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }

  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(["/patient/home/" + url], { queryParams: { utm_source: 'rescheduleappointment' } });
  }

  onCancel() {
    this.loadingCustom = true;
    this.CancelAppointment = false;
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.modifiedByUserInfoId = this.salesOrderData.patientInfo.crmPatientId;
    cancelRequestModel.schAppointmentId = this.schAppointmentId;
    cancelRequestModel.schCancelReason = "Other";
    cancelRequestModel.schComments = this.cancelnote;

    let cancelAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    cancelAppointmentRequestBT.IsUpdateToBrightree = false;
    cancelAppointmentRequestBT.IsDeliveryNoteOverride = true;
    cancelAppointmentRequestBT.IsOrderNoteOverride = true;
    cancelAppointmentRequestBT.OrderNote = null;
    cancelAppointmentRequestBT.DeliveryNote = null;
    cancelAppointmentRequestBT.PractitionerName = null;
    cancelAppointmentRequestBT.PractitionerId = 0;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    cancelAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    cancelAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {

        cancelAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        cancelAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        cancelAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        cancelAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    cancelRequestModel.salesOrderUpdateRequestModelBT = cancelAppointmentRequestBT;
    cancelRequestModel.isCancelledByPatient = true;
    this.loading = true;
    this.patientService.CancelAppointment(cancelRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {

        if (result.responseModel) {

          this.showSuccessMessage("Appointment has been canceled.");
          this.loadingCustom = false;
          let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
          this.router.navigate(["/patient/home/" + url], { queryParams: { utm_source: 'email' } });
        }
        else {
          this.loadingCustom = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

    //let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  rescheduleAppointmentBtn() {
    this.RescheduleAppointment = true;
  }

  cancelAppointmentBtn() {
    this.CancelAppointment = true;
  }

}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  type: string;
  address: string;
}
