<div class="form-content-outer">
  <div class="form-outer">
    <h3 class="form-outer__title" *ngIf="isTherapist">General Information</h3>
    <form class="form" [formGroup]="formPersonal" autocomplete="off">
      <!-- <h2 class="first">Personal Information</h2> -->
      <div class="col">
        <div class="form__group">
          <label class="form__label">First Name</label>
          <input formControlName="firstName" type="text" class="form__control" pInputText maxlength="50">
          <!--<span *ngIf="hasError(formFields.firstName, 'required') || hasError(formFields.firstName, 'whitespace')">{{validationMessages.firstName.required}}</span>
          <span *ngIf="hasError(formFields.firstName, 'validCharacters')">{{validationMessages.firstName.validCharacters}}</span>-->
          <div *ngIf="formSubmitted && fPersonal.firstName.errors" class="invalid-feedback">
            <div *ngIf="fPersonal.firstName.errors.required || fPersonal.firstName.errors.whitespace"> {{validationMessages.firstName.required}}</div>
            <div *ngIf="fPersonal.firstName.errors.validCharacters"> {{validationMessages.firstName.validCharacters}}</div>
          </div>

        </div>
        <div class="form__group">
          <label class="form__label">Last Name</label>
          <input formControlName="lastName" type="text" class="form__control" pInputText maxlength="50">
          <div *ngIf="formSubmitted && fPersonal.lastName.errors" class="invalid-feedback">
            <div *ngIf="fPersonal.lastName.errors.required || fPersonal.lastName.errors.whitespace"> {{validationMessages.lastName.required}}</div>
            <div *ngIf="fPersonal.lastName.errors.validCharacters"> {{validationMessages.lastName.validCharacters}}</div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Email</label>
          <input formControlName="email" type="text" class="form__control disabled" disabled pInputText readonly maxlength="100">
          <div *ngIf="formSubmitted && fPersonal.email.errors" class="invalid-feedback">
            <div *ngIf="fPersonal.email.errors.required || fPersonal.email.errors.whitespace"> {{validationMessages.email.required}}</div>
            <div *ngIf="fPersonal.email.errors.email"> {{validationMessages.email.email}}</div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Phone Number</label>
          <!-- <input formControlName="phoneNumber" type="text" class="form__control" pInputText maxlength="15"> -->
          <p-inputMask mask="999-999-9999" formControlName="phoneNumber" type="text" class="form__control placehold form__phone"
                         pInputText maxlength="15"></p-inputMask>
          <div *ngIf="formSubmitted && fPersonal.phoneNumber.errors" class="invalid-feedback">
            <div *ngIf="fPersonal.phoneNumber.errors.required || fPersonal.phoneNumber.errors.whitespace"> {{validationMessages.phoneNumber.required}}</div>
            <div *ngIf="fPersonal.phoneNumber.errors.validPhoneNumber"> {{validationMessages.phoneNumber.invalid}}</div>
          </div>

        </div>
      </div>
      <div class="col" *ngIf="false">
        <div class="form__group">
          <label class="form__label">Address</label>
          <textarea class="form__textarea" pInputTextarea></textarea>
        </div>
      </div>
      <div class="col" *ngIf="false">
        <div class="form__group">
          <label class="form__label">City</label>
          <input type="text" class="form__control" pInputText />
        </div>
        <div class="form__group">
          <label class="form__label">State</label>
          <!--<p-dropdown class="select-box" [options]="state" placeholder="Select" optionLabel="state" [showClear]="true"></p-dropdown>-->
        </div>
        <div class="form__group">
          <label class="form__label">Zip Code</label>
          <input type="text" class="form__control" pInputText />
        </div>
      </div>

      <div class="buttons set-custom-date-btn">
        <button class="btn btn--primary" (click)="onSubmit()">Update</button>
        <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
      </div>

    </form>
  </div>
  <!-- <div class="therapist-login" *ngIf="isTherapist"> -->
  <div class="therapist-login" *ngIf="isTherapist">
    <div class="form-outer therapist-login-form">
      <div class="title-box">
        <h3 class="form-outer__title">License Information</h3>
        <div class="add-buttons-for-license" *ngIf="isUpdateDeviceLicenseInfo">
          <a class="last-box__add-btn" (click)="licenseInformationBtn(); scroll(targetLicenseInfo)" href="javascript:void(0)"><i class="ic plus"></i></a>
        </div>
      </div>
      <div #targetLicenseInfo></div>

      <div *ngIf="islicenseInformationdiv">
        
        <form *ngIf="isUpdateDeviceLicenseInfo" class="form" [ngClass]="licenseInformation ? 'license-information-show' : 'license-information'" [formGroup]="formLicense" autocomplete="off">
          <div class="form--parent">
            <div class="form__group">
              <label class="form__label">License State</label>
              <p-dropdown class="select-box" [options]="statsModel" [(ngModel)]="selectedState" placeholder="Select State" formControlName="licenseState"
                          optionLabel="appStateName" [showClear]="true"></p-dropdown>
              <div *ngIf="formLicenseSubmitted && fLicense.licenseState.errors" class="invalid-feedback">
                <div *ngIf="fLicense.licenseState.errors.required || fLicense.licenseState.errors.whitespace"> {{validationMessages.licenseState.required}}</div>
              </div>
            </div>
          </div>

          <div class="form--parent">
            <div class="form__group">
              <label class="form__label">License No.</label>
              <input type="text" class="form__control" pInputText formControlName="licenseNumber" maxlength="50" />
              <div *ngIf="formLicenseSubmitted && fLicense.licenseNumber.errors" class="invalid-feedback">
                <div *ngIf="fLicense.licenseNumber.errors.required || fLicense.licenseNumber.errors.whitespace"> {{validationMessages.licenseNumber.required}}</div>
              </div>
            </div>
            <div class="form__group last-box">
              <label class="form__label">License Expiry</label>
              <!--<input type="text" class="form__control" pInputText formControlName="licenseExpiry" />-->
              <p-calendar class="datepicker" [minDate]="minimumDate" dateFormat="M d, yy" [showIcon]="true"
                          [(ngModel)]="licenseExpiryDate"
                          formControlName="licenseExpiry"></p-calendar>

              <div *ngIf="formLicenseSubmitted && fLicense.licenseExpiry.errors" class="invalid-feedback">
                <div *ngIf="fLicense.licenseExpiry.errors.required || fLicense.licenseExpiry.errors.whitespace">{{validationMessages.licenseExpiry.required}}</div>
              </div>
              <!-- <a class="last-box__add-btn" href="javascript:void(0)" (click)="onLicenseAdd()"><i class="ic plus"></i></a> -->
            </div>
          </div>

          <div class="set-custom-date-btn">
            <button class="btn btn--primary" (click)="onLicenseAdd()">{{licenseBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onLicenseCancel()">Cancel</button>
          </div>
        </form>
      </div>

      <div class="table-scroll" *ngIf="licenseResponseModel?.length>0">
        <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
          <div class="form__vertical__table license-table">
            <table>
              <thead>
                <tr>
                  <th width="190">License State</th>
                  <th>License No.</th>
                  <th width="120">License Expiry</th>
                  <th width="80" *ngIf="isUpdateDeviceLicenseInfo">Action</th>
                </tr>
              </thead>
              <tr *ngFor="let license of licenseResponseModel; let last = last; let first = first;">
                <td><strong>{{license?.stateMaster?.appStateName}}</strong></td>
                <td>{{license?.licenceNumber}}</td>
                <td>{{license?.expiryDate  | date:dateFormat}}</td>
                <td *ngIf="isUpdateDeviceLicenseInfo">
                  <div class="action" *ngIf="isUpdateDeviceLicenseInfo">
                    <a class="form__vertical__table__add-btn" style="margin-left: 0;" href="javascript:void(0)" (click)="onLicenseEdit(license); scroll(targetLicenseInfo)"><i class="edit-icon-new"></i></a>
                    <a class="form__vertical__table__add-btn" href="javascript:void(0)" (click)="onLicenseDelete(license)"><i class="delete-icon-new"></i></a>
                  </div>
                </td>
              </tr>
              <!-- <tr>
                <td colspan="4" style="padding-left: 0; padding-right: 0; text-align: right;">
                  <a class="last-box__add-btn" (click)="licenseInformationBtn(); scroll(targetLicenseInfo)" href="javascript:void(0)"><i class="ic plus"></i></a>
                </td>
              </tr> -->
            </table>
          </div>
        <!-- </p-scrollPanel> -->
      </div>
      
    </div>

    <div class="form-outer">
      <div class="title-box">
        <h3 class="form-outer__title">Device Information</h3>
        <div class="add-buttons-for-license" *ngIf="isUpdateDeviceLicenseInfo">
          <a class="last-box__add-btn" (click)="deviceInformationBtn(); scroll(targetDeviceInfo)" href="javascript:void(0)"><i class="ic plus"></i></a>
        </div>
      </div>
      <div #targetDeviceInfo></div>
      <div *ngIf="isdeviceInformationdiv">
        <form *ngIf="isUpdateDeviceLicenseInfo" class="form" [ngClass]="deviceInformation ? 'device-information-show' : 'device-information'" [formGroup]="formDevice" autocomplete="off">
          <div class="form--parent">
            <div class="form__group">
              <label class="form__label">Device Name</label>
              <!-- <p-dropdown class="select-box" [options]="device" placeholder="Select" optionLabel="name" formControlName="deviceName"
                          [(ngModel)]="selectedDevice" [showClear]="true">
              </p-dropdown> -->
              <p-multiSelect class="select-box" [options]="device" placeholder="Select" optionLabel="name" (onChange)="OnDeviceNameChange($event)"
                          formControlName="deviceName" [(ngModel)]="selectedDevices" selectedItemsLabel="{0} Devices Selected">
              </p-multiSelect>
              <div *ngIf="formDeviceSubmitted && fDevice.deviceName.errors" class="invalid-feedback">
                <div *ngIf="fDevice.deviceName.errors.required || fDevice.deviceName.errors.whitespace"> {{validationMessages.deviceName.required}}</div>
              </div>
            </div>
            <div class="form__group last-box">
              <label class="form__label">Certificate</label>
              <input type="text" class="form__control" pInputText formControlName="deviceCertificate" maxlength="50" />
              <div *ngIf="formDeviceSubmitted && fDevice.deviceCertificate.errors" class="invalid-feedback">
                <div *ngIf="fDevice.deviceCertificate.errors.required || fDevice.deviceCertificate.errors.whitespace"> {{validationMessages.deviceCertificate.required}}</div>
              </div>
              <!-- <a class="last-box__add-btn" href="javascript:void(0)" (click)="onCertificationAdd()"><i class="ic plus"></i></a> -->
            </div>
          </div>

          <div class="set-custom-date-btn">
            <button class="btn btn--primary" (click)="onCertificationAdd()">{{certificationBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onCertificationCancel()">Cancel</button>
          </div>
        </form>
      </div>
      
      <div class="table-scroll" *ngIf="certificationResponseModel?.length>0">
        <!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->

          <div class="form__vertical__table license-table">
            <table>
              <thead>
                <tr>
                  <th width="190">Device Name</th>
                  <th>Certificate</th>
                  <th width="80" *ngIf="isUpdateDeviceLicenseInfo">Action</th>
                </tr>
              </thead>

              <tr *ngFor="let certification of certificationResponseModel; let last = last; let first = first;">
                <td><strong> {{certification?.deviceName}}</strong></td>
                <td>{{certification?.certificateNumber}}</td>
                <td *ngIf="isUpdateDeviceLicenseInfo">
                  <div class="action" *ngIf="isUpdateDeviceLicenseInfo">
                    <a class="form__vertical__table__add-btn" style="margin-left: 0;" href="javascript:void(0)" (click)="onCertificationEdit(certification); scroll(targetDeviceInfo)"><i class="edit-icon-new"></i></a>
                    <a class="form__vertical__table__add-btn" href="javascript:void(0)" (click)="onCertificationDelete(certification)"><i class="delete-icon-new"></i></a>
                  </div>
                </td>
              </tr>

              <!-- <tr>
                <td colspan="3" style="padding-left: 0; padding-right: 0; text-align: right;">
                  <a class="last-box__add-btn" (click)="deviceInformationBtn(); scroll(targetDeviceInfo)" href="javascript:void(0)"><i class="ic plus"></i></a>
                </td>
              </tr> -->
            </table>

          </div>
        <!-- </p-scrollPanel> -->
      </div>

    </div>
  </div>

</div>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                     animationDuration=".8s">
  </p-progressSpinner>
</div>
