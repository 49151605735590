import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { TheraistAppointmentService } from 'src/app/therapist/services/theraistappointment.service';
import { PatientSideBarInfo, TherapistAppointmentsByAppointmenType, TherapistAppointmentsForClinicRemoteResponseModel, TherapistAppointmentsForRoadHomeResponseModel } from 'src/app/shared/models/response/appointment/therapist-appointment-by-appointment_type';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { MapDirectionsService, MapInfoWindow, MapMarker } from '@angular/google-maps';
import * as $ from 'jquery'
import { Constants } from 'src/app/shared/constants';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { WeekViewMobileComponent } from '../week-view-mobile/week-view-mobile.component';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { ExportAppointmentCount, SchedulerResponseModelForExport, SchedulerResponseModelForExportFinal } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-my-appointments-new',
  templateUrl: './my-appointments-new.component.html',
  styleUrls: ['./my-appointments-new.component.sass'],
  providers: [DialogService]
})
export class MyAppointmentsNewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow | undefined;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  therapistAppointments: TherapistAppointmentsByAppointmenType;
  therapistClinicResult: TherapistAppointmentsForClinicRemoteResponseModel[];
  therapistRoadHomeResult: TherapistAppointmentsForRoadHomeResponseModel[];
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderStatusClinic: WIPStatesResponseModel[];
  salesOrderStatusRoad: WIPStatesResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  @Output() wipsStatusCreated = new EventEmitter<{ appWipStatus: string, schAppointmentId: number }>();

  status: { name: string; code: string; }[];
  ViewonMap: boolean = false;
  appointmentDetail: boolean = false;
  patientDetailSidebar: boolean = false;
  weekViewMobile: boolean = false;

  en: any;
  primeFormat: string = '';
  appDateFormat: DateFormatModel;
  therapistId: number;
  therapistName: string;
  facilityTimeZone: string = '';
  totalMiles: string = '';
  totalHours: string = ''
  salesOrderType: string = 'AppointmentRequest';
  selectedStatusClinic: WIPStatesResponseModel;
  selectedStatusRoad: WIPStatesResponseModel;
  therapistDefaultLocation: string = '';
  appointmentRoadHomeDetail: TherapistAppointmentsForRoadHomeResponseModel;
  startLocation: string = ''
  nextLocation: string = ''
  startLocationAddress: string = ''
  therapiststartLocationAddress: string = ''
  nextLocationAddress: string = ''
  center: google.maps.LatLngLiteral;
  //circleCenter: google.maps.LatLngLiteral;
  //radius: number;
  allPatientMarker = [];
  markers = [];
  infoContent = ''
  display: any;
  latitude: number;
  longitude: number;
  isChildWipStatusUpdated: boolean = false;
  childAppointmentID: number;
  salesOrderBrighttreeURL: any;
  brightreeInternalUserId: number;
  patientBrighttreeURL: any;
  exportDataRes: SchedulerResponseModelForExport[];
  exportAppointmentCnt: ExportAppointmentCount[];
  previousDate: string;
  private geoCoder;
  directionsResults: Observable<google.maps.DirectionsResult | undefined>;
  options: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 19,
    minZoom: 5,
  };
  rendererOptions: google.maps.DirectionsRendererOptions = {
    suppressMarkers: true,
  };
  infoWindowOptions: google.maps.InfoWindowOptions = {
    maxWidth: 245
  }
  zoom = 19;
  clinicCount: number = 0;
  roadCount: number = 0;
  gridId: number = 0;
  salesOrderId: number = 0;
  patientSidebar: PatientSideBarInfo;

  selectedCity: any;
  timeZone: { name: string; code: string; }[];
  AppointmentsType: { name: string; code: string; }[];

  ClinicRemoteStatus: boolean = false;
  HomeVisitStatus: boolean = false;
  canUpdateStatue: boolean
  isStatusUpdated: boolean = false;
  displayStatus: string;
  AppointmentStatusUpdate: boolean;
  appointmentNote: string;
  isBrightreeSystemAppUpdate: Boolean = true;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  therapistFacilityName: string;
  exportTherapistName: string;
  constructor(
    private webStorageService: WebStorageService,
    private router: Router,
    private theraistAppointmentService: TheraistAppointmentService,
    protected cdr: ChangeDetectorRef,
    private featureGroupService: FeatureGroupService,
    messageService: MessageService,
    private http: HttpClient,
    private dialougeService: DialogService,
    private mapDirectionsService: MapDirectionsService,
    private settingsService: SettingsService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.loading = false;
    this.status = [
      { name: 'In-progress', code: 'NY' },
      { name: 'Complete', code: 'RM' },
    ];

    this.timeZone = [
      { name: 'EST', code: 'NY' },
      { name: 'CST', code: 'RM' }
    ];

    this.AppointmentsType = [
      { name: 'All Appointments', code: 'NY' },
      { name: 'Clinic Visit', code: 'RM' },
      { name: 'Remote Setup', code: 'LDN' },
      { name: 'Home Visit', code: 'IST' }
    ];
  }
  viewDate = new Date();
  timeZoneList: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string };

  @ViewChild(WeekViewMobileComponent, { static: false }) childRef: WeekViewMobileComponent;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  async ngOnInit() {
    this.timeZoneList = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST', orgcode: 'UTC+5', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST', orgcode: 'UTC-5', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST', orgcode: 'UTC-6', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MST', orgcode: 'UTC-7', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST', orgcode: 'UTC-8', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.therapistName = currentUserToken.userFullName;
      this.facilityTimeZone = currentUserToken.crmTimeZone;
    }
    else {
      this.therapistId = 0;
      this.therapistName = '';
      this.facilityTimeZone = 'EST';
    }
    await this.GetRCMConfigurationUrl();
    this.getUpdateStatusPermission();
    this.setCurrentLocation();
    this.appointmentRoadHomeDetail = new TherapistAppointmentsForRoadHomeResponseModel();
    this.therapistRoadHomeResult = [];
    this.therapistClinicResult = [];
    this.exportData1(true);
    this.getSalesOrderStatus();
    this.getTherapistAppointment();
    this.getWipStatusBTmapping();



    $(function () {
      $('.open-mobile-calendar').on('click', function () {
        $('.calendar-modalbox').fadeIn('fast').addClass('open');
      });

      $('.close-mobile-calendar').on('click', function () {
        $('.calendar-modalbox').slideUp().removeClass('open');
      });
    });
    //this.exportPDF();
  }



  ngOnDestroy() {
    this.onDestroy.next();
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
  }

  async getTherapistAppointment() {
    this.totalMiles = '';
    this.totalHours = '';
    this.loading = true;
    await this.theraistAppointmentService.GetTherapistScheduledAppointment(this.therapistId, this.viewDate.toDateString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<TherapistAppointmentsByAppointmenType>(result, () => {
          if (result.responseModel) {
            this.therapistAppointments = result.responseModel;
            this.therapistClinicResult = this.therapistAppointments.therapistAppointmentsForClinicRemoteResponseModels;
            this.therapistRoadHomeResult = this.therapistAppointments.therapistAppointmentsForRoadHomeResponseModels;
            this.clinicCount = this.therapistClinicResult.length;
            this.roadCount = this.therapistRoadHomeResult.length;

            if (this.therapistClinicResult.length > 0) {
              for (let i = 0; i < this.therapistClinicResult.length; i++) {
                let apphours = Math.floor(parseInt(this.therapistClinicResult[i].appointmentDuration) / 60);
                let appMinutes = parseInt(this.therapistClinicResult[i].appointmentDuration) % 60;
                let appTotalHours = (apphours > 0 ? (apphours + 'h ') : '') + (appMinutes > 0 ? (appMinutes + 'm') : '')
                this.therapistClinicResult[i].appointmentDuration = appTotalHours;
              }
            }

            if (this.therapistRoadHomeResult.length > 0) {
              for (let i = 0; i < this.therapistRoadHomeResult.length; i++) {
                let rowHours = Math.floor(parseInt(this.therapistRoadHomeResult[i].duration) / 60);
                let rowMinutes = parseInt(this.therapistRoadHomeResult[i].duration) % 60;
                let rowTotalHours = rowHours + 'h ' + rowMinutes + 'm'
                this.therapistRoadHomeResult[i].duration = rowTotalHours;

                let apphours = Math.floor(parseInt(this.therapistRoadHomeResult[i].appointmentDuration) / 60);
                let appMinutes = parseInt(this.therapistRoadHomeResult[i].appointmentDuration) % 60;
                let appTotalHours = (apphours > 0 ? (apphours + 'h ') : '') + (appMinutes > 0 ? (appMinutes + 'm') : '')
                this.therapistRoadHomeResult[i].appointmentDuration = appTotalHours;
              }
              this.therapistFacilityName = this.therapistRoadHomeResult[0].facilityName;
              this.therapiststartLocationAddress = this.therapistRoadHomeResult[0].therapistAddress;
            }
            if (this.therapistRoadHomeResult.length > 1) {
              var lastIndex = this.therapistRoadHomeResult[this.therapistRoadHomeResult.length - 1];
              this.totalMiles = lastIndex.totalMiles.toString();
              let hours = Math.floor(lastIndex.totalETA / 60);
              let hoursMinutes = lastIndex.totalETA % 60;
              this.totalHours = hours + 'h ' + hoursMinutes + 'm'
              this.therapistDefaultLocation = lastIndex.therapistDefaultLocation;
            }
            else if (this.therapistRoadHomeResult.length == 1) {
              this.totalMiles = this.therapistRoadHomeResult[0].totalMiles.toString();
              let hours = Math.floor(this.therapistRoadHomeResult[0].totalETA / 60);
              let hoursMinutes = this.therapistRoadHomeResult[0].totalETA % 60;
              this.totalHours = hours + 'h ' + hoursMinutes + 'm'
              this.therapistDefaultLocation = this.therapistRoadHomeResult[0].therapistDefaultLocation;
            }
            //this.exportPDF();
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getSalesOrderStatus() {
    this.progress.next(true);
    this.theraistAppointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          this.salesOrderStatus = result.responseModel;
          this.salesOrderStatusClinic = this.salesOrderStatus.
            filter(x => x.wipStatesName.toLowerCase() == 'complete'
              || x.wipStatesName.toLowerCase() == 'no show'
            );
          this.salesOrderStatusRoad = this.salesOrderStatusClinic;
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.featureGroupService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatusBTMappingResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async onUpdateAppointment(AppoinmentId) {
    if (this.selectedStatusRoad.wipStatesId === undefined) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }
    if (!this.appointmentNote) {
      this.showWarningMessage("Please enter appointment note.");
      return;
    }
    if (!this.isStatusUpdated) {
      this.updateAppointmentStatus(AppoinmentId);
    }
    if (AppoinmentId > 0) {
      this.loading = true;
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
      appointmentUpdateStatusRequestModel.SchComments = this.appointmentNote == undefined ? "" : this.appointmentNote;
      await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            if (result.responseModel) {
              this.exportData1(true);
              this.showSuccessMessage("Appointment status and note has been updated.");
              this.AppointmentStatusUpdate = false;
              this.appointmentNote = "";
            }

          });
        }).catch((httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  updateAppointmentStatus(AppoinmentId) {
    if (!this.selectedStatusRoad) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }

    //this.wipStatus = selectedEvent.eventSalesOrderSelectedStatus.wipStatesName;
    let status = this.selectedStatusRoad;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    appointmentRequestBT.CrmModifiedBy = this.therapistId;
    let selectedWipStaus: WipStatusBTmapping;
    if (status.wipStatesName.toLowerCase() == 'complete') {
      this.displayStatus = 'Complete';
      selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    if (status.wipStatesName.toLowerCase() == 'no show') {
      this.displayStatus = 'No Show';
      selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    let statusId = selectedWipStaus.crmWipstatusId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = statusId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointmentRoadHomeDetail.facilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointmentRoadHomeDetail.therapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.appointmentRoadHomeDetail.therapistId;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            this.isStatusUpdated = true;
            this.exportData1(true);
            this.getTherapistAppointment();
            this.wipsStatusCreated.emit({ appWipStatus: selectedWipStaus.crmWipstatusNameSa, schAppointmentId: AppoinmentId });
            //this.showSuccessMessage("Appointment status has been updated.");
            this.isStatusUpdated = true;
            this.cdr.detectChanges();
          }

        });
      }).catch((httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });


  }

  onAppointmentDetail(roadHome) {
    this.startLocation = '';
    this.nextLocation = '';
    let startLat = '';
    let startLong = '';
    let patientMarker = [];
    let wayPoints = [];
    this.markers = [];
    this.setCurrentLocation();
    this.selectedStatusRoad = new WIPStatesResponseModel();
    if (roadHome) {
      let isFirstIndex = this.therapistRoadHomeResult.indexOf(roadHome);
      if (roadHome.appointmentOrder == 0) {
        this.startLocation = this.therapistDefaultLocation;
        this.startLocationAddress = this.therapistRoadHomeResult[0].therapistAddress;
        startLat = this.therapistRoadHomeResult[0].therapistLat;
        startLong = this.therapistRoadHomeResult[0].therapistLong;
        patientMarker.push({
          position: ({
            lat: Number(this.therapistRoadHomeResult[0].therapistLat),
            lng: Number(this.therapistRoadHomeResult[0].therapistLong),
          }),
          address: '<h4 class="patient-name-location">' + this.therapistName + '</h4>' + ' ' + this.therapistRoadHomeResult[0].therapistAddress,
          url: "assets/img/therapist-location.png"
        });
      }
      else if (isFirstIndex === 0) {
        this.startLocation = this.therapistDefaultLocation;
        this.startLocationAddress = this.therapistRoadHomeResult[0].therapistAddress;
        startLat = this.therapistRoadHomeResult[0].therapistLat;
        startLong = this.therapistRoadHomeResult[0].therapistLong;
        patientMarker.push({
          position: ({
            lat: Number(this.therapistRoadHomeResult[0].therapistLat),
            lng: Number(this.therapistRoadHomeResult[0].therapistLong),
          }),
          address: '<h4 class="patient-name-location">' + this.therapistName + '</h4>' + ' ' + this.therapistRoadHomeResult[0].therapistAddress,
          url: "assets/img/therapist-location.png"
        });
      }
      else {
        let prevIndex = this.therapistRoadHomeResult.indexOf(roadHome);
        let previous = this.therapistRoadHomeResult[prevIndex - 1];
        if (previous) {
          this.startLocation = previous.patientLastName + ', ' + previous.patientFirstName;
          this.startLocationAddress = previous.crmPatientAddress;
          startLat = previous.crmPatientLat;
          startLong = previous.crmPatientLong;
          patientMarker.push({
            position: ({
              lat: Number(previous.crmPatientLat),
              lng: Number(previous.crmPatientLong),
            }),
            address: '<h4 class="patient-name-location">' + this.startLocation + '</h4>' + ' ' + previous.crmPatientAddress,
            url: "assets/img/start-location-icon.svg"
          });
        }
      }

      patientMarker.push({
        position: ({
          lat: Number(roadHome.crmPatientLat),
          lng: Number(roadHome.crmPatientLong),
        }),
        address: '<h4 class="patient-name-location">' + roadHome.patientLastName + ', ' + roadHome.patientFirstName + '</h4>' + ' ' + roadHome.crmPatientAddress,
        url: "assets/img/current-patient-location.png"
      });

      let nextIndex = this.therapistRoadHomeResult.indexOf(roadHome);
      let next = this.therapistRoadHomeResult[nextIndex + 1];
      if (next) {
        this.nextLocation = next.patientLastName + ', ' + next.patientFirstName;
        this.nextLocationAddress = next.crmPatientAddress;
        patientMarker.push({
          position: ({
            lat: Number(next.crmPatientLat),
            lng: Number(next.crmPatientLong),
          }),
          address: '<h4 class="patient-name-location">' + next.patientLastName + ', ' + next.patientFirstName + '</h4>' + ' ' + next.crmPatientAddress,
          url: "assets/img/end-location-icon.svg"
        });
        wayPoints.push({
          location: ({
            lat: Number(roadHome.crmPatientLat),
            lng: Number(roadHome.crmPatientLong),
          }),
          stopover: true
        });
      }
      this.addAppintmentDetailMarker(patientMarker);
      if (next) {
        this.setDirection(Number(startLat), Number(startLong), Number(next.crmPatientLat), Number(next.crmPatientLong), wayPoints);
      }
      else {
        this.setDirection(Number(startLat), Number(startLong), Number(roadHome.crmPatientLat), Number(roadHome.crmPatientLong), []);
      }
      //if (!this.isChildWipStatusUpdated && roadHome.schAppointmentId != this.childAppointmentID) {
      if (roadHome.schWIPStatus == "Complete") {
        this.isStatusUpdated = true;
        this.displayStatus = roadHome.schWIPStatus;
      }
      else if (roadHome.schWIPStatus == "No Show") {
        this.isStatusUpdated = true;
        this.displayStatus = "No Show";
      }
      else if (roadHome.schWIPStatus == "Booked") {
        this.isStatusUpdated = false;
        this.displayStatus = roadHome.schWIPStatus;
      }
      else {
        this.isStatusUpdated = false;
        this.displayStatus = "";
      }
      //}


      this.appointmentDetail = true;
      this.appointmentRoadHomeDetail = roadHome;
    }
  }

  async WipStatusUpdatedOnSideBar(eventData: { appWipStatus: string, schAppointmentId: number }) {
    if (eventData) {
      // this.isChildWipStatusUpdated = true;
      // this.isStatusUpdated = true;
      // this.displayStatus = eventData.appWipStatus;
      // this.childAppointmentID = eventData.schAppointmentId;
      this.patientDetailSidebar = false;
      await this.getTherapistAppointment();

    }
  }

  openInfoWindow(marker: MapMarker, content: string) {
    if (this.infoWindow != undefined) {
      this.infoContent = content
      this.infoWindow.open(marker)
    }
  }

  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.display = event.latLng.toJSON();
  }

  addAppintmentDetailMarker(patientDetail: any[]) {
    if (patientDetail.length > 0) {
      for (let i = 0; i < patientDetail.length; i++) {
        this.markers.push({
          position: patientDetail[i].position,
          icon: {
            url: patientDetail[i].url
          },
          info: patientDetail[i].address.toString(),
          options: {
            animation: google.maps.Animation.DROP,
          },
        });
      }
    }
  }

  addAllPatientMarker(patientDetail: any[]) {
    if (patientDetail.length > 0) {
      for (let i = 0; i < patientDetail.length; i++) {
        this.allPatientMarker.push({
          position: patientDetail[i].position,
          icon: {
            url: patientDetail[i].url
          },
          info: patientDetail[i].address.toString(),
          options: {
            animation: google.maps.Animation.DROP,
          },
        });
      }
    }
  }

  setDirection(originLat: number, originLong: number, destinationLat: number, destinationLong: number, waypts: any[]) {
    const request: google.maps.DirectionsRequest = {
      origin: {
        lat: Number(originLat),
        lng: Number(originLong)
      },
      destination: {
        lat: Number(destinationLat),
        lng: Number(destinationLong)
      },
      waypoints: waypts,
      travelMode: google.maps.TravelMode.DRIVING,
      optimizeWaypoints: true,
      provideRouteAlternatives: true,
    };
    this.directionsResults = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    }
  }

  clickedNavigator(destinationLat: string, destinationLong: string) {
    let origin = this.latitude + ',' + this.longitude;
    let destination = destinationLat + ',' + destinationLong;
    var url = 'https://www.google.com/maps/dir/?api=1&origin=' + origin + '&destination=' + destination + '&travelmode=driving';
    window.open(url);
  }

  viewAlldatainMap(value: boolean) {
    let patientMarker = [];
    this.allPatientMarker = [];
    let startLat = '';
    let startLong = '';
    let endLat = '';
    let endLong = '';
    let wayPoints = [];
    if (this.therapistRoadHomeResult.length > 0) {
      for (let i = 0; i < this.therapistRoadHomeResult.length; i++) {
        let lastIndexData = this.therapistRoadHomeResult[this.therapistRoadHomeResult.length - 1];
        if (lastIndexData.crmSalesorderAppId == this.therapistRoadHomeResult[i].crmSalesorderAppId) {
          patientMarker.push({
            position: ({
              lat: Number(this.therapistRoadHomeResult[i].crmPatientLat),
              lng: Number(this.therapistRoadHomeResult[i].crmPatientLong),
            }),
            address: '<h4 class="patient-name-location">' + this.therapistRoadHomeResult[i].patientLastName + ', ' + this.therapistRoadHomeResult[i].patientFirstName + '</h4>' + ' ' + this.therapistRoadHomeResult[i].crmPatientAddress,
            url: "assets/img/end-location-icon.svg"
          });
        }
        else {
          patientMarker.push({
            position: ({
              lat: Number(this.therapistRoadHomeResult[i].crmPatientLat),
              lng: Number(this.therapistRoadHomeResult[i].crmPatientLong),
            }),
            address: '<h4 class="patient-name-location">' + this.therapistRoadHomeResult[i].patientLastName + ', ' + this.therapistRoadHomeResult[i].patientFirstName + '</h4>' + ' ' + this.therapistRoadHomeResult[i].crmPatientAddress,
            url: "assets/img/patient-location.png"
          });
        }

        wayPoints.push({
          location: ({
            lat: Number(this.therapistRoadHomeResult[i].crmPatientLat),
            lng: Number(this.therapistRoadHomeResult[i].crmPatientLong),
          }),
          stopover: true
        });
      }
      patientMarker.push({
        position: ({
          lat: Number(this.therapistRoadHomeResult[0].therapistLat),
          lng: Number(this.therapistRoadHomeResult[0].therapistLong),
        }),
        address: '<h4 class="patient-name-location">' + this.therapistName + '</h4>' + ' ' + this.therapistRoadHomeResult[0].therapistAddress,
        url: "assets/img/therapist-location.png"
      });
    }
    let lastIndexData = this.therapistRoadHomeResult[this.therapistRoadHomeResult.length - 1];
    if (lastIndexData == undefined) {
      return;
    }
    startLat = this.therapistRoadHomeResult[0].therapistLat;
    startLong = this.therapistRoadHomeResult[0].therapistLong;
    endLat = lastIndexData.crmPatientLat;
    endLong = lastIndexData.crmPatientLong;
    this.addAllPatientMarker(patientMarker);
    this.setDirection(Number(startLat), Number(startLong), Number(endLat), Number(endLong), wayPoints);
    this.ViewonMap = value;
  }

  onPatientDetailSidebarClick(gridId, patientDetail) {
    this.patientDetailSidebar = true;
    this.patientSidebar = new PatientSideBarInfo();

    this.patientSidebar.patientName = patientDetail.patientLastName + ', ' + patientDetail.patientFirstName;
    this.patientSidebar.appointmentDate = patientDetail.startTime;
    this.patientSidebar.patientTimeZone = patientDetail.crmTimeZone;
    this.patientSidebar.visitType = gridId == 1 ? patientDetail.appointmentType : 'Road/Home Appt';
    this.patientSidebar.duration = patientDetail.appointmentDuration;
    this.patientSidebar.therapistID = this.therapistId;
    this.salesOrderId = patientDetail.crmSalesOrdeId;
    this.patientSidebar.status = patientDetail.schWIPStatus;
    this.patientSidebar.datetimeLastModified = patientDetail.dateTimeLastModified
  }

  ClinicRemoteclickEvent() {
    this.ClinicRemoteStatus = !this.ClinicRemoteStatus;
  }

  HomeVisitclickEvent() {
    this.HomeVisitStatus = !this.HomeVisitStatus;
  }

  private async getUpdateStatusPermission() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.AllowTherapistToUpdateAppointmentStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.canUpdateStatue = (result.responseModel.schSettingValue.toLowerCase() === 'true');
          }
        });
      });
  }
  destroyChild() {
    if (this.childRef) {
      this.childRef.ngOnDestroy();
    }
  }
  resetData() {
    this.weekViewMobile = false;
    this.destroyChild();
  }

  appointmentStatusDialog() {
    this.selectedStatusRoad = new WIPStatesResponseModel();
    this.AppointmentStatusUpdate = true;
  }

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }
  }

  changeWipStatus(event: any) {
    this.selectedStatusRoad = event.value;
    let result = [... this.salesOrderStatusRoad.filter(item => item.wipStatesName === this.selectedStatusRoad.wipStatesName)];
    if (result.length != null && result.length > 0) {
      if (this.selectedStatusRoad.wipStatesName.toLowerCase() == 'complete') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }

      if (this.selectedStatusRoad.wipStatesName.toLowerCase() == 'no show') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }
    }
  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  async exportPDF() {
    await this.exportData1(true);
    setTimeout(() => {
      if (!this.exportDataRes) {
        this.showWarningMessage('No Appointments for selected Date.');
        return;
      }
      var data = document.getElementById('exportData');
      data.style["display"] = "block";
      html2canvas(data).then(canvas => {
        // Few necessary setting options  
        var imgWidth = 208;
        var imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        pdf.save('My Appointments.pdf'); // Generated PDF   
      });
      data.style["display"] = "none";
    }, 2000);

  }

  exportData1(isRefresh: boolean) {

    let therpistName = this.therapistName.split(/[ ,]+/).join(',');
    let splitName = therpistName.split(',');
    this.exportTherapistName = splitName[1] + ", " + splitName[0];

    if (this.previousDate == this.viewDate.toDateString()) {
      this.previousDate = this.viewDate.toDateString();
      return;
    }
    this.previousDate = this.viewDate.toDateString();
    this.theraistAppointmentService.GetTherapistAppointments(this.therapistId, this.viewDate.toDateString(), isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SchedulerResponseModelForExportFinal>(result, () => {
          //this.loading = false;
          if (result.responseModel.schedulerResponseModelForExportDbs != null) {
            var data = document.getElementById('exportData');
            data.style["display"] = "block";
            this.exportDataRes = result.responseModel.schedulerResponseModelForExportDbs;
            this.exportAppointmentCnt = result.responseModel.appointmentCountForExportPDFs;
            data.style["display"] = "none";
          }
        });
      }).catch((httpResponseError) => {
        //this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  exportCSV() {
    if (!this.exportDataRes) {
      this.showWarningMessage('No Appointments for selected Date.');
      return;
    }
    if (this.loading)
      this.loading = false;
    this.loading = true;
    this.theraistAppointmentService.DownloadTherapistAppointmentsCSV(this.therapistId, this.viewDate.toDateString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'My Appointments.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }


}
