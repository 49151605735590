import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SearchRequestModelDashboard, SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { AppointmentCountForDashboardResponseModel, AppointmentCountForTodaysDashboardResponseModel, NotScheduledCallCountResponseModel, TherapistDashboardStatisticsModel, TodaysScheduledAppointmentDashboardResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { NoOfCallResponseModel } from 'src/app/shared/models/response/report-summary-response-model';
import { WipStatus } from 'src/app/shared/models/common/wip-status';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getCountFacilities() {
    return this.get<ResultOfT<number>>('Dashboard/GetCountFacilities');
  }

  GetCountTotalTherapists() {
    return this.get<ResultOfT<number>>('Dashboard/GetCountTotalTherapists');
  }

  getCountDoctors() {
    return this.get<ResultOfT<number>>('Dashboard/GetCountDoctors');
  }

  getCountCSOperators() {
    return this.get<ResultOfT<number>>('Dashboard/GetCountCSOperators');
  }

  //getCallsCountNotScheduledAppointments(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<NoOfCallResponseModel>>('Dashboard/getCallsCountNotScheduledAppointments', searchRequest);
  //}

  getCallsCountNotScheduledAppointments(branchId: number) {
    return this.get<ResultOfT<NoOfCallResponseModel>>('Dashboard/getCallsCountNotScheduledAppointments?branchId=' + branchId);
  }

  getAppointemtCallsCountNoShowAppointments(searchRequest: SearchRequestModelDashboard) {
    return this.post<ResultOfT<NoOfCallResponseModel>>('Dashboard/getAppointemtCallsCountNoShowAppointments', searchRequest);
  }

  getCountNotScheduledAppointments(searchRequest: SearchRequestModelDashboard) {
    return this.post<ResultOfT<number>>('Dashboard/GetCountNotScheduledAppointment', searchRequest);
  }

  getCountNotScheduledAppointmentAndTelehealth(searchRequest: SearchRequestModelDashboard) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountNotScheduledAppointmentAndTelehealth', searchRequest);
  }

  //GetCountNotScheduledTelehealthCalls(searchRequest: SearchRequestModelDashboard) {
  //  // tslint:disable-next-line: max-line-length
  //  return this.post<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetCountNotScheduledTelehealthCalls', searchRequest);
  //}

  GetCountNotScheduledTelehealthCalls(branchId: number) {
    // tslint:disable-next-line: max-line-length
    return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetCountNotScheduledTelehealthCalls/' + branchId);
  }

  //GetPotentialVoid(searchRequest: SearchRequestModelDashboard) {
  //  // tslint:disable-next-line: max-line-length
  //  return this.post<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetPotentialVoid', searchRequest);
  //}
  GetPotentialVoid(branchid: number, isRefresh: boolean, userid: string) {
    // tslint:disable-next-line: max-line-length
    if (!isRefresh)
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetPotentialVoid?branchid=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetPotentialVoid?branchid=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  //getCountOpenAppointment(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountOpenAppointment', searchRequest);
  //}

  getCountOpenAppointment(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountOpenAppointment?branchid=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountOpenAppointment?branchid=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  //getCountNoShowAppointment(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountNoShowAppointment', searchRequest);
  //}

  getCountNoShowAppointment(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountNoShowSalesOrder?branchid=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountNoShowSalesOrder?branchid=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  getCountTherapists(searchRequest: SearchRequestModelDashboard) {
    return this.post<ResultOfT<number>>('Dashboard/GetCountTherapists', searchRequest);
  }

  getCountTherapistsNotAssignedFacility(searchRequest: SearchRequestModelDashboard) {
    return this.post<ResultOfT<number>>('Dashboard/GetCountTherapistsNotAssignedFacility', searchRequest);
  }

  getCountTherapistsLisenceExpired(searchRequest: SearchRequestModelDashboard) {
    return this.post<ResultOfT<number>>('Dashboard/GetCountTherapistsLisenceExpired', searchRequest);
  }

  //getUnReconciledAppointments(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetUnReconciledAppointments', searchRequest);
  //}

  getUnReconciledAppointments(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetUnReconciledAppointments?branchId=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetUnReconciledAppointments?branchId=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  //getCancelled(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCancelled', searchRequest);
  //}

  getCancelled(branchid: number, isRefresh: boolean, userid: number) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCancelled?branchId=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCancelled?branchId=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  //GetCountNotScheduledCalls(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetCountNotScheduledCalls', searchRequest);
  //}
  GetCountNotScheduledCalls(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetCountNotScheduledCalls?branchId=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetCountNotScheduledCalls?branchId=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  GetUrgentCallBackRequestCount(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetUrgentCallBackRequestCount?branchId=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<NotScheduledCallCountResponseModel>>('Dashboard/GetUrgentCallBackRequestCount?branchId=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }


  //GetTherapistDashboardStatistics(searchRequest: SearchRequestModelDashboard) {
  //  return this.post<ResultOfT<TherapistDashboardStatisticsModel>>('Dashboard/GetTherapistDashboardStatistics', searchRequest);
  //}

  GetTherapistDashboardStatistics(branchid: number, isRefresh: boolean) {
    if (!isRefresh)
      return this.get<ResultOfT<TherapistDashboardStatisticsModel>>('Dashboard/GetTherapistDashboardStatistics?branchId=' + branchid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<TherapistDashboardStatisticsModel>>('Dashboard/GetTherapistDashboardStatistics?branchId=' + branchid + '&rnd=' + randomNum);
    }
  }

  getStatus() {
    return this.get<ResultOfT<WipStatus[]>>('Appointment/GetDashboardWipStates');
  }

  getCountCancelSalesOrder(branchid: number, isRefresh: boolean, userid: string) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountCancelSalesOrder?branchId=' + branchid + '&userid=' + userid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForDashboardResponseModel>>('Dashboard/GetCountCancelSalesOrder?branchId=' + branchid + '&userid=' + userid + '&rnd=' + randomNum);
    }
  }

  GetTodaysDashboardCount(isRefresh: boolean, userid: string, filterdDate: string,branchid: number) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountForTodaysDashboardResponseModel>>('Dashboard/GetTodaysDashboardCount?userid=' + userid + '&filterdDate=' + filterdDate + '&branchId=' + branchid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<AppointmentCountForTodaysDashboardResponseModel>>('Dashboard/GetTodaysDashboardCount?userid=' + userid + '&filterdDate=' + filterdDate + '&branchId=' + branchid + '&rnd=' + randomNum);
    }
  }

  GetTodaysScheduleAppointmentDashboardCount(isRefresh: boolean, userid: string, filterdDate: string,branchid: number) {
    if (!isRefresh)
      return this.get<ResultOfT<TodaysScheduledAppointmentDashboardResponseModel>>('Dashboard/GetTodaysScheduleAppointmentDashboardCount?userid=' + userid + '&filterdDate=' + filterdDate + '&branchId=' + branchid);
    else {
      var randomNum = this.webStorageService.getRandomNumber();
      return this.get<ResultOfT<TodaysScheduledAppointmentDashboardResponseModel>>('Dashboard/GetTodaysScheduleAppointmentDashboardCount?userid=' + userid + '&filterdDate=' + filterdDate + '&branchId=' + branchid + '&rnd=' + randomNum);
    }
  }
}
