import { Component, OnInit, OnDestroy } from '@angular/core';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { TherapistService } from '../../servicies/therapist.service';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdownList, FacilityResponseModelDefaultTime } from 'src/app/shared/models/response/facility/facility-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { ManageAvailabilityService } from '../../servicies/manage-availability.service';

@Component({
  selector: 'app-manage-availability',
  templateUrl: './manage-availability.component.html',
  styleUrls: ['./manage-availability.component.sass'],
  providers: [DialogService]
})
export class ManageAvailabilityComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private therapistId: any;
  private availabilityId: number = 0;
  title = '';
  appDateFormat: DateFormatModel;
  allfacilities: FacilityResponseModelDropdownList[];
  facilities: FacilityResponseModelDropdownList[];
  selectedFacility: FacilityResponseModelDropdownList;
  time: FacilityResponseModelDefaultTime;
  weekDays: EnumModel[] = [];
  selectedWeekDays: EnumModel[] = [];
  selectedWeekDay: EnumModel;
  eventSeries: EnumModel[];
  prevEventSeries: EnumModel;
  selectedEventSeries: EnumModel;
  therapistAvailability: TherapistCustomAvailabilityResponseModel;
  breakStartTime: Date;
  breakEndTime: Date;
  description: string;
  currentUserToken: UserAuthResponseModel;
  customStartDate: Date;
  customStartTime: Date;
  customEndDate: Date;
  customEndTime: Date;
  loggedInUserId: number;
  selectedEvent: string;
  selecteRec: boolean;
  isBreakHours: boolean = false;
  isBreakHoursDisplayed: boolean = false;
  isSetRecurrenceDisplayed: boolean = false;
  isFacilityDisplayed: boolean;
  isExactDate: boolean = false;
  errorcode: number;
  selectedOccurrence: { name: string, code: number };
  Occurrence = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
  ];
  selectedExactDate: { name: string, code: number };
  ExactDate = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
    { name: '6', code: 6 },
    { name: '7', code: 7 },
    { name: '8', code: 8 },
    { name: '9', code: 9 },
    { name: '10', code: 10 },
    { name: '11', code: 11 },
    { name: '12', code: 12 },
    { name: '13', code: 13 },
    { name: '14', code: 14 },
    { name: '15', code: 15 },
    { name: '16', code: 16 },
    { name: '17', code: 17 },
    { name: '18', code: 18 },
    { name: '19', code: 19 },
    { name: '20', code: 20 },
    { name: '21', code: 21 },
    { name: '22', code: 22 },
    { name: '23', code: 23 },
    { name: '24', code: 24 },
    { name: '25', code: 25 },
    { name: '26', code: 26 },
    { name: '27', code: 27 },
    { name: '28', code: 28 },
    { name: '29', code: 29 },
    { name: '30', code: 30 },
    { name: '31', code: 31 }
  ];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private therapistService: ManageAvailabilityService,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    if (this.webStorageService.getCurrentUserToken())
      this.loggedInUserId = this.webStorageService.getCurrentUserToken().userId;
    this.getDaysOfWeek();
    this.getEventSeries()
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        if (params['id'])
          this.availabilityId = Number(params['id']);
        else
          this.availabilityId = 0;
        if (params['id1'])
          this.therapistId = Number(params['id1']);
        else
          this.therapistId = 0;
      });
    await this.getFacilities();
    //await this.getFacilitiesPopup("false");
    await this.getTherapist();
  }

  async getFacilities() {

    this.progress.next(true);
    this.facilityService.getAllFacilityDropDownforAvailability(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.allfacilities = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async getTime() {
    this.progress.next(true);
    var day = 1;
    if (new Date().getDay() == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = new Date().getDay() - 1;
      day = Math.pow(2, power);
    }
    this.facilityService.getTime(this.selectedFacility.crmFacilityId, day)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result || !result.responseModel) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.time = result.responseModel;
          this.customStartTime = new Date(this.time.dtStart);
          this.customEndTime = new Date(this.time.dtEnd);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getFacilitiesPopupeExt(isRemote: boolean) {

    this.facilities = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    this.selectedFacility = this.facilities[0];
  }

  SetEvents() {
    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isFacilityDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    switch (this.selectedEvent) {
      case "WO":
        this.isFacilityDisplayed = true;
        this.getFacilitiesPopupeExt(false);
        this.isBreakHoursDisplayed = true;
        this.isBreakHours = true;
        this.isExactDate = false;
        break;
      case "T":
        this.isFacilityDisplayed = true;
        this.getFacilitiesPopupeExt(true);
        this.isBreakHoursDisplayed = true;
        this.isBreakHours = true;
        this.isSetRecurrenceDisplayed = true;
        this.isExactDate = false;
        break;
      case "W":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        break;
      case "R":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = true;
        this.isBreakHours = true;
        this.isExactDate = false;
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isFacilityDisplayed = false;
        this.isExactDate = false;
        break;

    }
    //if (this.selectedEvent === "T") {
    //  this.getFacilitiesPopupeExt(true);
    //}
    //else {
    //  this.getFacilitiesPopup("false");
    //}
  }

  getDaysOfWeek() {
    this.progress.next(true);
    this.therapistService.getDaysOfWeek()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.weekDays = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getEventSeries() {
    this.progress.next(true);
    this.therapistService.getEventSeries()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.eventSeries = result.responseModel;
            this.getAvailability();
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onChangeEventSeries(item: EnumModel) {

    this.selectedEventSeries = item;
    this.selectedEventSeries.isSelected = true;
    switch (item.name) {
      case "Weekly":
        this.isExactDate = false;
        break;
      case "Monthly":
        this.isExactDate = true;
        break;
      case "Yearly":
        this.isExactDate = true;
        break;
      default:
        break;

    }
  }

  onChangeDayOfWeek(item: EnumModel) {
    item.isSelected = !item.isSelected;
  }

  onEventOccurrenceChange(occurrence) {
    this.selectedOccurrence = occurrence.value;
  }

  getAvailability() {
    if (this.availabilityId <= 0) {
      return;
    }
    this.progress.next(true);
    this.therapistService.getAvailabilityNewById(this.availabilityId, 0, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message && result.responseModel != undefined && result.responseModel != null) {
          this.therapistAvailability = result.responseModel;
          this.setValuesToForm();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async setValuesToForm() {

    this.therapistId = this.therapistAvailability.crmTherapistId;
    if (this.therapistAvailability.crmIsHoliday) {
      this.selectedEvent = "W";
      this.SetEvents();

    }
    else if (this.therapistAvailability.isRemoteAssistant) {
      this.selectedEvent = "T";
      this.SetEvents();
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
    }
    else if (this.therapistAvailability.crmIsRoadShow) {
      this.selectedEvent = "R";
      this.SetEvents();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
    }
    else {
      this.selectedEvent = "WO";
      this.SetEvents();
      this.selecteRec = false;
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);

    }
    if (this.therapistAvailability.crmIsRecurring) {
      this.selecteRec = this.therapistAvailability.crmIsRecurring;
    }
    //else {
    //  this.selectedEvent = "WO";
    //  this.SetEvents();
    //  this.selecteRec = false;
    //}
    //if (!this.therapistAvailability.isRemoteAssistant) {
    //  this.selectedFacility = this.facilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
    //}
    if (this.therapistAvailability.crmBreakEndTimeDate)
      this.breakEndTime = new Date(this.therapistAvailability.crmBreakEndTimeDate);
    if (this.therapistAvailability.crmBreakStartTimeDate)
      this.breakStartTime = new Date(this.therapistAvailability.crmBreakStartTimeDate);
    this.description = this.therapistAvailability.crmDescription;
    this.selectedOccurrence = this.Occurrence.find(x => x.code == this.therapistAvailability.crmRecurringFrequency);
    this.customEndDate = new Date(this.therapistAvailability.crmTherapistCustomEndDate);
    this.customEndTime = new Date(this.therapistAvailability.crmTherapistCustomEndTimeDate);
    this.customStartDate = new Date(this.therapistAvailability.crmTherapistCustomStartDate);
    this.customStartTime = new Date(this.therapistAvailability.crmTherapistCustomStartTimeDate);

    this.weekDays.forEach((value) => {

      if (this.therapistAvailability.crmCustomDays.split(',').length > 0) {
        this.therapistAvailability.crmCustomDays.split(',').forEach((item) => {
          if (item == value.value.toString()) {
            value.isSelected = true;
          }
        });
      }
    });

    this.eventSeries.forEach((value) => {
      if (this.therapistAvailability.crmRecurringType == value.value) {
        value.isSelected = true;
      }
    });
  }

  async onSave() {
    if (!this.validateForm()) {
      return;
    }
    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeDate = this.isBreakHours ? this.breakEndTime : null;
    requestModel.crmBreakStartTimeDate = this.isBreakHours ? this.breakStartTime : null;
    requestModel.crmDescription = this.selectedEvent == "W" ? this.description : "";
    requestModel.crmFacilityId = this.selectedEvent == "WO" || this.selectedEvent == "T" ? this.selectedFacility.crmFacilityId : null;
    requestModel.crmIsHoliday = this.selectedEvent == "W";
    requestModel.crmIsRecurring = this.selecteRec;
    requestModel.crmIsRoadShow = this.selectedEvent == "R";
    requestModel.isRemoteAssistant = this.selectedEvent == "T";
    requestModel.crmRecurringFrequency = this.selecteRec ? this.selectedOccurrence.code : 1;
    requestModel.crmRecurringType = this.selecteRec ? this.selectedEventSeries.value : 1;
    requestModel.crmTherapistCustomEndDate = this.customEndDate;
    requestModel.crmTherapistCustomEndTimeDate = this.customEndTime;
    requestModel.crmTherapistCustomStartDate = this.customStartDate;
    requestModel.crmTherapistCustomStartTimeDate = this.customStartTime;
    requestModel.crmTherapistId = this.therapistId;
    requestModel.crmTherpistCustomAvailabilityId = this.availabilityId;
    requestModel.crmIsExactDay = this.isExactDate;
    requestModel.crmCustomDays = "";
    requestModel.modifiedByUserInfoId = this.loggedInUserId;

    if (this.weekDays.length > 0) {
      this.weekDays.forEach((value) => {
        if (value.isSelected)
          requestModel.crmCustomDays += value.value + ',';
      })
    }

    if (this.selectedEventSeries) {
      if (this.selectedEventSeries.name == "Monthly") {
        requestModel.monPattern = 4095;
      }

      if (this.selectedExactDate) {
        requestModel.exactDay = this.selectedExactDate.code;
        //if (this.selectedEventSeries.name == "Monthly" || this.selectedEventSeries.name == "Yearly") {
        //  requestModel.crmCustomDays = null;
        //}
      }
      else {
        requestModel.exactDay = null;
      }

      if (this.selectedEventSeries.name == "Yearly") {
        requestModel.yearFrequcncy = this.selectedOccurrence.code;
      }
    }


    await this.validateRequest(requestModel)
    if (this.errorcode == 0) {
      if (this.availabilityId == 0)
        this.SetAvailability(requestModel);
      else
        this.UpdateAvailability(requestModel);
    }
    else if (this.errorcode == 3) {
      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'There is already availability set for this period, do you wish to override the facility.',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (response) {
          if (this.availabilityId == 0)
            this.SetAvailability(requestModel);
          else
            this.UpdateAvailability(requestModel);
        }
      });
    }
  }

  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.therapistService.setAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.therapistService.updateAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability updated successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onCancel() {
    this.router.navigate(['admin/calendar/view-therapist-availability/', this.therapistId]);
  }

  private async getTherapist() {
    if (!this.therapistId) {
      return;
    }

    this.progress.next(true);
    await this.therapistService.getTherapistById(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        if (!results || !results.responseModel) {
          return;
        }
        if (results.responseModel != undefined && results.responseModel != null) {
          const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
          this.title = 'Add Therapist Availability - ' + capitalizeFirstLetter(results.responseModel.appUserFname) + ' ' + capitalizeFirstLetter(results.responseModel.appUserLname);
          if (this.availabilityId <= 0) {
            if (results.responseModel.therapistDefaultFacility && results.responseModel.therapistDefaultFacility[0].crmFacilityId && this.allfacilities) {
              this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == results.responseModel.therapistDefaultFacility[0].crmFacilityId);
              this.getTime();
            }
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBack() {
    this.router.navigate(['admin/calendar/view-therapist-availability/', this.therapistId]);
  }

  validateForm(): boolean {

    if (!this.selectedEvent) {
      this.showWarningMessage('Please select Events Type.');
      return false;
    }


    switch (this.selectedEvent) {
      case "WO":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "T":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "W":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      case "R":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = true;
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isFacilityDisplayed = false;
        break;

    }

    if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select Days of Week.');
      return false;
    }

    //if (!this.selectedFacility) {
    //  this.showWarningMessage('Please select a facility.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && !this.description) {
    //  this.showWarningMessage('Please enter description.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
    //  this.showWarningMessage('Please select event series.');
    //  return false;
    //}
    //if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
    //  this.showWarningMessage('Please select week days.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && !this.selectedOccurrence) {
    //  this.showWarningMessage('Please select Occurrence.');
    //  return false;
    //}
    if (!this.customStartDate) {
      this.showWarningMessage('Please select Start Date.');
      return false;
    }
    if (!this.customEndDate) {
      this.showWarningMessage('Please select End Date.');
      return false;
    }
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be larger than Start Date.');
      return false;
    }
    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }
    if (this.customEndTime < this.customStartTime) {
      this.showWarningMessage('End Time should be larger than Start Time.');
      return false;
    }
    if (this.selecteRec) {
      if (!this.selectedOccurrence) {
        this.showWarningMessage('Please select Occurrence Type.');
        return false;
      }
      if (this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
        this.showWarningMessage('Please select event series.');
        return false;
      }
    }
    if (this.isBreakHours) {

      //if (!this.breakStartTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      //if (!this.breakEndTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      if (this.breakEndTime < this.breakStartTime) {
        this.showWarningMessage('Break End Time should be larger than Break Start Time');
        return false;
      }

      if (!(this.breakStartTime > this.customStartTime && this.breakStartTime < this.customEndTime)) {
        this.showWarningMessage('Break Start time should be between start time and end time');
        return false;
      }

      if (!(this.breakEndTime > this.customStartTime && this.breakEndTime < this.customEndTime)) {
        this.showWarningMessage('Break End time should be between start time and end time');
        return false;
      }
    }
    return true;
  }

  async validateRequest(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    await this.therapistService.validateAvailability(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {

        if (result && result.errorCode == 3) {
          this.errorcode = result.errorCode
        }
        else if (result && result.errorCode != 0) {
          this.showWarningMessage(result.message);
          this.errorcode = -1;
        }
        else {
          this.errorcode = 0;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
