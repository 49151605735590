import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { TherapistService } from '../../servicies/therapist.service';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { TherapistCertificationResponseModel } from 'src/app/shared/models/response/therapist/therapist-certification-response-model';
import { TherapistLicenseResponseModel } from 'src/app/shared/models/response/therapist/therapist-license-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { TherapistDefaultFacilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-response-model';
import { UserBranchResponseModel } from 'src/app/shared/models/response/user-branch-response-model';
import { Time } from '@angular/common';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { TherapistAvailabilityResponseModel, TherapistSetAvailabilityRequestModel, TherapistSetAvailabilityRequestModelSend, TherapistAvailabilityResponseModelSend } from 'src/app/shared/models/response/therapist/therapist-availability-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { AvailDisplayMode } from 'src/app/shared/models/response/therapist/therapist-availability-display-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { stringify } from 'querystring';

import { Editor } from "primeng/editor";
import { ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { TherapistContactInfoResponseModel, TherapistInfoModel } from 'src/app/shared/models/response/therapist/therapist-info-model';
import { TherapistDefaultFacilityModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-model';
import { TherapistDeviceInfoModel } from 'src/app/shared/models/response/therapist/therapist-device-info-model';
import { TherapistLicenseInfoModel } from 'src/app/shared/models/response/therapist/therapist-licenseinfo-model';
import { TherapistOnlineMeetingModel, TherapistOnlineMeetingResponseModel } from 'src/app/shared/models/response/therapist/therapist-onlinemeeting-model';
import * as moment from 'moment';
import { PagedResponseModel } from '../../../shared/models/response/paged-response-model';
import { EventLogResponseModel } from '../../../shared/models/common/event-log-response-model'
@Component({
  selector: 'app-therapist-audit-logs',
  templateUrl: './therapist-audit-logs.component.html',
  styleUrls: ['./therapist-audit-logs.component.sass'],
  providers: [DialogService]
})
export class TherapistAuditLogsComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private therapistId: string;
  searchRequestModel: SearchRequestModel;
  title = '';
  titleName = '';
  index: number = 0;
  indexTab: number = 0;
  returnback: string = "";
  searchRequestModelGeneralAuditLogs: SearchRequestModel;
  searchRequestModelAvailabilityAuditLogs: SearchRequestModel;
  totalGeneralRecords: number;
  totalavailabilityPages: number;
  totalavailabilityRecords: number;
  totalGeneralPages: number;
  generalLogs: EventLogResponseModel[];
  availabilityLogs: EventLogResponseModel[];
  enUserInfoId: string;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private therapistService: TherapistService,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }

  }
  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = params['id'];
        this.enUserInfoId = params['id'];
      });

    this.returnback = this.activatedRoute.snapshot.paramMap.get('isback');

    this.initializeSearchRequestModelGeneralAuditLogs();
    this.initializeSearchRequestModelAvailabilityAuditLogs();
    this.getTherapist(this.therapistId);
    //this.getTherapistGeneralAuditLogs(true);
  }
  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModelGeneralAuditLogs.PageSize = null;
    } else {
      this.searchRequestModelGeneralAuditLogs.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getTherapistGeneralAuditLogs(true);
  }

  onPageSizeAvailabilityChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModelAvailabilityAuditLogs.PageSize = null;
    } else {
      this.searchRequestModelAvailabilityAuditLogs.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getTherapistGeneralAuditLogs(true);
  }

  private resetPageIndex() {
    this.searchRequestModelGeneralAuditLogs.CurrentPageIndex = 0;
    this.searchRequestModelGeneralAuditLogs.FirstRecordIndex = 0;
  }
  private resetAvailabilityPageIndex() {
    this.searchRequestModelAvailabilityAuditLogs.CurrentPageIndex = 0;
    this.searchRequestModelAvailabilityAuditLogs.FirstRecordIndex = 0;
  }

  onGeneralLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request

    this.searchRequestModelGeneralAuditLogs.FirstRecordIndex = event.first;
    this.searchRequestModelGeneralAuditLogs.CurrentPageIndex = event.first / 20;
    this.searchRequestModelGeneralAuditLogs.PageSize = 20;
    this.searchRequestModelGeneralAuditLogs.SortByColumnName = event.sortField;
    this.searchRequestModelGeneralAuditLogs.IsAscendingOrder = event.sortOrder === 1 ? true : false;
    this.searchRequestModelGeneralAuditLogs.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';


    this.getTherapistGeneralAuditLogs(true);
  }

  onAvailabilityLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request

    this.searchRequestModelAvailabilityAuditLogs.FirstRecordIndex = event.first;
    this.searchRequestModelAvailabilityAuditLogs.CurrentPageIndex = event.first / 20;
    this.searchRequestModelAvailabilityAuditLogs.PageSize = 20;
    this.searchRequestModelAvailabilityAuditLogs.SortByColumnName = event.sortField;
    this.searchRequestModelAvailabilityAuditLogs.IsAscendingOrder = event.sortOrder === 1 ? true : false;
    this.searchRequestModelAvailabilityAuditLogs.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';


    this.getTherapistAvailabilityAuditLogs(true);
  }
  getGeneralTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModelGeneralAuditLogs.PageSize) {
      return this.searchRequestModelGeneralAuditLogs.PageSize;
    }

    return this.totalGeneralRecords;
  }

  getAvailabilityTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModelAvailabilityAuditLogs.PageSize) {
      return this.searchRequestModelAvailabilityAuditLogs.PageSize;
    }

    return this.totalavailabilityRecords;
  }

  private getTherapistGeneralAuditLogs(isRefresh: boolean) {
    this.loading = true;
    this.therapistService.GetTherapistGenertalEventLogs(this.searchRequestModelGeneralAuditLogs)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<EventLogResponseModel>>) => {
        this.processResult<PagedResponseModel<EventLogResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              const pagedResponse = result.responseModel;
              if (pagedResponse) {

                this.generalLogs = pagedResponse.results;
                this.totalGeneralRecords = pagedResponse.totalRecords;
                this.totalGeneralPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModelGeneralAuditLogs.PageSize) {
                  this.searchRequestModelGeneralAuditLogs.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getTherapistAvailabilityAuditLogs(isRefresh: boolean) {
    this.loading = true;
    this.therapistService.GetTherapistAvailabilityEventLogs(this.searchRequestModelAvailabilityAuditLogs)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<EventLogResponseModel>>) => {
        this.processResult<PagedResponseModel<EventLogResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              const pagedResponse = result.responseModel;
              if (pagedResponse) {

                this.availabilityLogs = pagedResponse.results;
                this.totalavailabilityRecords = pagedResponse.totalRecords;
                this.totalavailabilityPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModelGeneralAuditLogs.PageSize) {
                  this.searchRequestModelAvailabilityAuditLogs.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  handleChange(e) {

    this.indexTab = e.index;
    //this.scrollIssue();
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }
  onBack() {

    if (this.returnback == "threapistgrid") {
      this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
    }

    else if (this.returnback == "threapist") {
      this.router.navigate(['admin/therapists/edit/' + this.therapistId], { state: { searchRequestModel: this.searchRequestModel } });
    }
    else
      this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
  }
  public async getTherapist(therapistId: string) {
    if (!therapistId) {
      return;
    }


    const therapist = this.therapistService.getTherapistById(therapistId);


    this.progress.next(true);
    this.loading = true;
    await forkJoin<ResultOfT<UserInfoResponseModel>>(therapist)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        if (results[0].responseModel != undefined && results[0].responseModel != null) {
          const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
          this.titleName = 'History of ' + capitalizeFirstLetter(results[0].responseModel.appUserLname) + ', ' + capitalizeFirstLetter(results[0].responseModel.appUserFname);
          this.enUserInfoId = results[0].responseModel.enUserInfoId;
        }

        //this.therapistSetAvailabilityRequestModel = results[1].responseModel as TherapistSetAvailabilityRequestModel;
        //this.setValuesToAvailability();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private initializeSearchRequestModelGeneralAuditLogs() {

    this.searchRequestModelGeneralAuditLogs = new SearchRequestModel();
    this.searchRequestModelGeneralAuditLogs.enappUserInfoId = this.enUserInfoId;
    this.searchRequestModelGeneralAuditLogs.FirstRecordIndex = 0;
    this.searchRequestModelGeneralAuditLogs.CurrentPageIndex = 0;
    this.searchRequestModelGeneralAuditLogs.PageSize = +this.pageSizes[0];
    this.searchRequestModelGeneralAuditLogs.SortOrder = 'DESC';
    this.searchRequestModelGeneralAuditLogs.SortByColumnName = 'EventDate';

  }
  private initializeSearchRequestModelAvailabilityAuditLogs() {

    this.searchRequestModelAvailabilityAuditLogs = new SearchRequestModel();
    this.searchRequestModelAvailabilityAuditLogs.enappUserInfoId = this.enUserInfoId;
    this.searchRequestModelAvailabilityAuditLogs.FirstRecordIndex = 0;
    this.searchRequestModelAvailabilityAuditLogs.CurrentPageIndex = 0;
    this.searchRequestModelAvailabilityAuditLogs.PageSize = +this.pageSizes[0];
    this.searchRequestModelAvailabilityAuditLogs.SortOrder = 'DESC';
    this.searchRequestModelAvailabilityAuditLogs.SortByColumnName = 'EventDate';

  }

  formatString(formatStr) {
    if (formatStr == null) {
      return formatStr;
    }
    else {
      return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
  }

  getRowSpanForUserAndScreen(log: any): number {
    let span = 1;
    const currentIndex = this.availabilityLogs.filter(l => l.eventGuid === log.eventGuid);
    if (currentIndex != undefined && currentIndex != null) {
      span = currentIndex.length + 1;
      // for (let i = currentIndex - 1; i >= 0; i--) {
      //   if (this.availabilityLogs[i].eventGuid === log.eventGuid) {
      //     span++;
      //   } else {
      //     break;
      //   }
      // }
    }
    return span;
  }
}
