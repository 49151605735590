import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SearchRequestModel, SearchRequestModelForNearByPatients } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';

import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { AppointmentsCountsResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { FacilitySearchResponseModel, FacilityTimeSlotResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { FacilityTimeSlotRequest } from 'src/app/shared/models/request/facility-slots-request';
import { FacilityTherapistAvalabilityRequestModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-request-model';
import { FacilityTherapistAvalabilityModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-model';
import { TherapistRoadShowAvailabilityRequestModel } from 'src/app/shared/models/request/therapist-availability-request';
import { TherapistAvailabilityTimeslotsModel } from 'src/app/shared/models/response/appointment/therapist-availability-timeslots-model';
import { AvailableSlots, FaciltiyForSlots, TherapistAvailability, ViewByDateResponseTranspose } from 'src/app/shared/models/response/appointment/available-slots';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { CommonNextDayScheduleRequest } from 'src/app/shared/models/request/facility/common-nextday-schedule';
import { NearByPatientResponseModel } from 'src/app/shared/models/response/appointment/nearby-patient-response-model';
import { AppointmentCallListResponseModel, ViewByDateRequest } from 'src/app/shared/models/request/appointment-call-response-model';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model'
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { RoadShowTimeSlotMainResponseModel } from 'src/app/shared/models/response/appointment/roadshow-timeslot-main-response-model';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { FacilityResponseModelDropdownList } from 'src/app/shared/models/response/facility/facility-response-model';
import { PatientCallRequestModel } from 'src/app/shared/models/request/patient-call-request-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { SalesOrderInBTResponse } from 'src/app/shared/models/response/sales-order-bt-response';
import * as moment from 'moment';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { AppointmentHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-history-response-model'
import { AddressUpdateRequestModel } from 'src/app/shared/models/request/address-update-request-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { AppointmentFacilityResponseModel } from 'src/app/shared/models/response/appointment/appointment-facility-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';

@Injectable({
  providedIn: 'root'
})
export class PatientService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }


  searchFacilityByPatient(facilitysearchrequestModel: FacilitySearchRequestModel, isRefresh: boolean) {
    var url = 'Patient/SearchClinicsForPatient';
    if (facilitysearchrequestModel.crmSalesOrderId) {
      url = url + '?CrmSalesorderId=' + facilitysearchrequestModel.crmSalesOrderId;
    }
    if (facilitysearchrequestModel.crmPatientId) {
      url = url + '&CrmPatientId=' + facilitysearchrequestModel.crmPatientId;
    }

    if (facilitysearchrequestModel.crmPatientZipCode) {
      url = url + '&CrmPatientZipCode=' + facilitysearchrequestModel.crmPatientZipCode;
    }
    if (facilitysearchrequestModel.crmState) {
      url = url + '&CrmState=' + facilitysearchrequestModel.crmState;
    }
    if (facilitysearchrequestModel.radius) {
      url = url + '&Radius=' + facilitysearchrequestModel.radius;
    }
    if (facilitysearchrequestModel.schAppointmentDateStartTimeString) {
      url = url + '&SchAppointmentDateStartTime=' + facilitysearchrequestModel.schAppointmentDateStartTimeString;
    }
    if (facilitysearchrequestModel.schAppointmentDateEndTimeString) {
      url = url + '&SchAppointmentDateEndTime=' + facilitysearchrequestModel.schAppointmentDateEndTimeString;
    }
    
    if (facilitysearchrequestModel.isBillingAddress) {
      url = url + '&isBillingAddress=' + facilitysearchrequestModel.isBillingAddress;
    }
    if (facilitysearchrequestModel.searchText) {
      url = url + '&searchText=' + facilitysearchrequestModel.searchText;
    }
    if (facilitysearchrequestModel.facilityZipCode) {
      url = url + '&facilityZipCode=' + facilitysearchrequestModel.facilityZipCode;
    }
    if (facilitysearchrequestModel.patientTimeZone) {
      url = url + '&patientTimeZone=' + facilitysearchrequestModel.patientTimeZone;
    }
    url = url + '&isAppointment=' + facilitysearchrequestModel.isAppointment;
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    return this.get<ResultOfT<FacilitySearchResponseModel[]>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  getFacilityTimeSlots(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('Patient/GetFacilityTimeSlotsByPatient/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  getMultiFacilityTimeSlots(timeslotRequest: FacilityTimeSlotRequest[]) {
    return this.post<ResultOfT<FacilitySearchResponseModel[]>>('Patient/GetMultiFacilityTimeSlotsByPatient/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetDefaultFacilitiesByPatientTherapist() {
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>('Patient/GetDefaultFacilitiesByPatientTherapist');
  }


  GetFacilitiesByPatientTherapistAvailability(date: string, therapistIds: string) {
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>('Patient/GetFacilitiesByPatientTherapistAvailablity/' + date + "/" + therapistIds);
  }

  searchTherapistByPatient(facilitysearchrequestModel: FacilitySearchRequestModel)
  {
    var url = 'Patient/FetchTherapistByPatient';
    if (facilitysearchrequestModel.crmSalesOrderId) {
      url = url + '?CrmSalesorderId=' + facilitysearchrequestModel.crmSalesOrderId.toString();
    }
    if (facilitysearchrequestModel.crmPatientId) {
      url = url + '&CrmPatientId=' + facilitysearchrequestModel.crmPatientId.toString();
    }
    if (facilitysearchrequestModel.crmCityTown) {
      url = url + '&CrmCityTown=' + facilitysearchrequestModel.crmCityTown;
    }

    if (facilitysearchrequestModel.crmPatientZipCode) {
      url = url + '&CrmPatientZipCode=' + facilitysearchrequestModel.crmPatientZipCode;
    }
    if (facilitysearchrequestModel.facilityZipCode) {
      url = url + '&CrmFacilityZipCode=' + facilitysearchrequestModel.facilityZipCode;
    }
    if (facilitysearchrequestModel.radius) {
      url = url + '&Radius=' + facilitysearchrequestModel.radius.toString();
    }
    if (facilitysearchrequestModel.schAppointmentDateStartTimeString) {
      url = url + '&SchAppointmentDateStartTime=' + facilitysearchrequestModel.schAppointmentDateStartTimeString;
    }
    if (facilitysearchrequestModel.schAppointmentDateEndTimeString) {
      url = url + '&SchAppointmentDateEndTime=' + facilitysearchrequestModel.schAppointmentDateEndTimeString;
    }
    if (facilitysearchrequestModel.crmState) {
      url = url + '&CrmState=' + facilitysearchrequestModel.crmState;
    }
    if (facilitysearchrequestModel.isBillingAddress) {
      url = url + '&isBillingAddress=' + facilitysearchrequestModel.isBillingAddress;
    }
    if (facilitysearchrequestModel.crmFacilityiIdsString != null || facilitysearchrequestModel.crmFacilityiIdsString != undefined) {
      url = url + '&crmFacilityiIds=' + facilitysearchrequestModel.crmFacilityiIdsString;
    }
    if (facilitysearchrequestModel.patientTimeZone) {
      url = url + '&patientTimeZone=' + facilitysearchrequestModel.patientTimeZone;
    }
    return this.get<ResultOfT<TherapistResponseModel[]>>(url);
  }

  
  GetTimeSlotsAvailabilityByTherapist(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('Patient/GetTimeSlotsAvailabilityByTherapistForPatient/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

 
  getTherapistFacilityTypeDetails(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('Patient/GetTherapistFacilityTypeDetails/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRemoteFacilityTimeSlotsByPatient(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('Patient/GetRemoteFacilityTimeSlotsByPatient/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRemoteSelfGuidedTimeSlotsByPatient(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('Patient/GetRemoteSelfGuidedTimeSlotsByPatient/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  SearchRemoteFacilitiesForAppointment(facilitysearchrequestModel: FacilitySearchRequestModel) {
    return this.post<ResultOfT<AppointmentFacilityResponseModel>>('Patient/SearchRemoteFacilitiesForAppointment', facilitysearchrequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAppointmentById(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentInfoReponseModel>>('Patient/GetAppointmentById/' + AppointmentId);
  }

  ScheduleAppointment(appointmentRequest: AppointmentRequestModel) {
    return this.post<ResultOfT<number>>('Patient/scheduleappointment/', appointmentRequest)
      .pipe(map((result) => {
        return result;
      }));
  }
  
  ReScheduleAppointment(appointmentRequest: AppointmentRequestModel) {
    return this.post<ResultOfT<number>>('Patient/ReScheduleAppointment/', appointmentRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  CancelAppointment(appointmentCancelRequest: AppointmentCancelRequest) {
    return this.post<ResultOfT<boolean>>('Patient/CancelAppointment/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));
  }
}
