import { AppointmentDetailResponseModel } from './appointment-detail-response-model'
import { SalesOrderInfoResponseModel } from './sales-order-info-reponse-model'
import { AppointmentHistoryResponseModel } from './appointment-history-response-model'
export class AppointmentInfoReponseModel {
  appoinmentId: number;
  schAppointmentId: number;
  schAppointmentTypeId: number;
  schAppointmentType: string;
  schRemoteAppointmentType: string;
  schRemoteAppointmentTypeDisplayName: string;
  schAppointmentTypeDisplayName: string;
  schIsUnreconciledAppointment: boolean;
  crmSalesorderId: number;
  crmSalesorderAppId: string;
  crmPatientRcmBrightreeID: number;
  crmPatientId: number;
  schAppointmentBookedBy: number;
  schAppointmentStatus: number;
  schWIPStatus: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  crmBranch: string;
  crmBranchIsAllowSelfScheduleAppointments: boolean;
  schAppointmentDateStartTime: Date;
  schAppointmentDateEndTime: Date;
  schAppointmentDuration: number;
  schAppointmentDateStartTimeString: string;
  schAppointmentDateEndTimeString: string;
  schAppointmentDateStartDateString: string;
  schReminderOpted: boolean;
  isBreakHours: boolean;
  appointmentDetailInfo: AppointmentDetailResponseModel;
  salesOrderInfo: SalesOrderInfoResponseModel;
  appointmentHistoryInfo: AppointmentHistoryResponseModel[];
  crmAppointmentType: string;
  schIsBillingAddress: boolean;
  crmFacilityName: string;
  Is15min: boolean = false;
  isMakeUnAvailable: boolean = false;
  schIsReminderOptedSms: boolean;
  schIsReminderOptedEmail: boolean;
  
  //availableReAssgTherapist: string = "";
}

export class TherapistCustomDatesResponseModel {
  crmCustomDate: Date;
  crmStartTime: Date;
  crmEndTime: Date;
  facilityName: string;
  facilityId: number;
  facilityTimeZone: string;
  isRemoteAndFacility: boolean;
  isFacilityVisit: boolean;
  isRemoteVisit: boolean;
  remoteFacilityId: number;
  remoteFacilityName: string;
  remoteFacilityTimeZone: string;
  isStartFromDefaultFacility: boolean;
  isStartFromHomeAddress: boolean;
  isRemoteAssistant: boolean;
  custDateID: number;
  availID: number;
}
export class TherapistEditBlockResponseModel {
  crmCustomDate: Date;
  crmStartTime: Date;
  crmEndTime: Date;
  therapistName: string;
  therapistId: number;
  facilityId: number;
  desc: string;
  therapistStartTime: Date;
  therapistEndTime: Date;
}

export class TherapistBlockResponseModel {
  crmCustomDate: Date;
  crmStartTime: Date;
  crmEndTime: Date;
  availablityType: string;
  therapistId: number;
  facilityId: number;
  desc: string;
}