import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouterModule } from '@angular/router';
import { TherapistFooterComponent } from '../../../shared/footer/therapist-footer/therapist-footer.component';
import { DayViewSchedulerComponent } from './day-view-scheduler.component';
import { DemoComponent } from './component';
import { SharedModule } from '../../../shared/shared.module';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule as inlineCalendar } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
export const MyDefaultTooltipOptions: TooltipOptions = {
  'placement': 'right'
}
@NgModule({
  declarations: [TherapistFooterComponent, DemoComponent, DayViewSchedulerComponent],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    FormsModule,
    SharedModule,
    MenuModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AccordionModule,
    TabViewModule,
    ProgressSpinnerModule,
    inlineCalendar
  ],
  exports: [DemoComponent, TherapistFooterComponent, inlineCalendar],
})
export class DemoModule { }
