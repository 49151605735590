import { Time } from '@angular/common';
import { FacilityResponseModel } from '../facility/facility-response-model';
import { UserInfoResponseModel } from '../userInfo-response-model';
import { TherapistDefaultFacilityResponseModel } from './therapist-default-facility-response-model';
import { TherapistCustomAvailabilityResponseModel } from './therapist-custom-availability-response-model';

export class TherapistAvailabilityResponseModel {
  crmTherpistAvailabilityId: number;
  crmFacilityId: number;
  crmTherapistId: number;
  crmTherpistAvailToDate: Date;
  crmTherpistAvailFromDate: Date;
  crmTherpistAvailToTime: Date;
  crmTherpistAvailFromTime: Date;
  isActive: boolean;
  crmTherpistAvailWeekDays: number;
  dateTimeCreated: Date;
  modifiedByUserInfoId: number;
  isRemoteAssistant: boolean;
  DateTimeLastModified: Date;
  crmBreakEndTime: Date;
  crmBreakStartTime: Date;

  crmFacility: FacilityResponseModel;
  crmTherapist: UserInfoResponseModel;
  therpistCustomAvailability: TherapistCustomAvailabilityResponseModel;
  therapistDefaultFacility: TherapistDefaultFacilityResponseModel;
}
export class TherapistAvailabilityResponseModelSend {
  crmTherpistAvailabilityId: number;
  crmFacilityId: number;
  crmTherapistId: number;
  crmTherpistAvailToDate: Date;
  crmTherpistAvailFromDate: Date;
  crmTherpistAvailToTimeDate: Date;
  crmTherpistAvailFromTimeDate: Date;
  isActive: boolean;
  crmTherpistAvailWeekDays: number;
  dateTimeCreated: Date;
  modifiedByUserInfoId: number;
  isRemoteAssistant: boolean;
  DateTimeLastModified: Date;
  crmBreakEndTimeDate: Date;
  crmBreakStartTimeDate: Date;
  uuid: number;
  crmFacilityName: string;
  eventName: string;
  dayOfWeek: string;
}


export class TherapistSetAvailabilityRequestModel {
  therapistAvailabilityResponseModel: TherapistAvailabilityResponseModelSend[];
  crmTherapistId: number;
  therapistDefaultFacility: TherapistDefaultFacilityResponseModel;
  therpistCustomAvailability: TherapistCustomAvailabilityResponseModel[];
}

export class TherapistSetAvailabilityRequestModelSend {
  therapistAvailabilityResponseModel: TherapistAvailabilityResponseModelSend[];
  crmTherapistId: number;
  therapistDefaultFacility: TherapistDefaultFacilityResponseModel;
  therpistCustomAvailability: TherapistCustomAvailabilityResponseModel[];
}
