<div class="book-appointment-search">
  <h2>Add Insurance</h2>
  <form class="form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSearch()">
    <div class="form__group">
      <input type="text" formControlName="searchtext" class="form__control" pInputText placeholder="Insurance Name" style="width:100%" #searchBox>
      <span class="error-msg" *ngIf="hasError(formFields.searchtext, 'required') || hasError(formFields.searchtext, 'whitespace')">{{validationMessages.searchtext.required}}</span>
      
    </div>
    <button class="btn btn--primary">Add</button>
  </form>
</div>
