import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { Subject } from 'rxjs';
import { SettingsService } from '../../../servicies/settings.service';
import { SettingsBrightreeResponseModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { WipStatus } from 'src/app/shared/models/common/wip-status';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Dropdown } from 'primeng/dropdown';
import { BranchResponseModel, SelectedBranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { UserBranchRequestModel } from 'src/app/shared/models/request/user-branch-request-model';
@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.sass']
})
export class BranchComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'Brightree Settings';
  classificationBtnTitle: string = "Update";
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  loggedInUserId: number;
  formStatusSubmitted: boolean = false;
  branchResponseList: BranchResponseModel[];
  selectedbranches: BranchResponseModel[];
  loadingClassification: boolean = false;
  displayBranch: boolean = false;
  formBranchSubmitted: boolean = false;
  isActive: boolean = false;
  branchEditMode: boolean;
  selectedBranchModel: BranchResponseModel;
  branchInformation: boolean;
  isAllowSelfScheduleAppointments: boolean = false;
  searchRequestModel: SearchRequestModel;
  totalRecords: number;
  totalPages: number;
  searchText: string;
  isViewSelfScheduleAppointments: boolean = false;
  //userSelection: boolean;
  selectedUsers: { label: string, value: string }[];
  isSubmitForm: boolean = false;
  users: { label: string, value: string }[];
  userBranches: UserBranchRequestModel[]
  formFields = {
    branchName: 'branchName',
    phoneNumber: 'phoneNumber',
    email: 'email',
    brightreeId: 'brightreeId',
  };

  validationMessages = {

    branchName: {
      required: 'Branch name is required',
      validCharacters: 'Enter valid Branch name'
    },
    phoneNumber: {
      required: 'Phone number is required.',
      invalid: 'Enter valid phone number.'
    },
    email: {
      required: 'Email-Id is required',
      validCharacters: 'Enter valid Email-Id',
      email: 'Email-Id must be a valid email address.'
    },
    brightreeId: {
      invalid: 'Enter valid Bright Tree Id.'
    },
    user: {
      required: 'user is required.'
    }

  };

  constructor(private settingsService: SettingsService,
    private dialogueService: DialogService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }

  get fBranch() { return (<FormGroup>this.form.get('formClassificationType')).controls; }
  get formClassificationType() {
    return this.form.get('formClassificationType');
  }


  ngOnInit() {
    this.initializeSearchRequestModel();
    //this.getBranchList();
    this.buildForm();
    this.getAllDMEUsers()
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  private initializeSearchRequestModel() {

    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = 'CrmBranchName';
    //if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
    //  this.searchRequestModel.dictionaryFilter['ActiveStatus'] = 'true';

    //}
  }
  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getBranchList(true);
  }

  onBranchEdit(branchResponse: BranchResponseModel) {
    const form = this.formClassificationType;
    this.classificationBtnTitle = "Update";
    this.branchInformation = true;
    this.branchEditMode = true;
    this.formClassificationType.reset();
    //this.selectedBranchModel = branchResponse;
    form.get(this.formFields.branchName).clearValidators();
    form.get(this.formFields.branchName).updateValueAndValidity();

    this.getBranh(branchResponse.crmBranchId);

    //this.setValuesToBranchForm();
    //this.displayBranch = !this.displayBranch;
  }

  private getBranh(branchId: number) {
    if (!branchId) {
      return;
    }
    this.loading = true;
    this.settingsService.GetBranchById(branchId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<BranchResponseModel>) => {
        this.processResult<BranchResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.selectedBranchModel = result.responseModel;
              this.setValuesToBranchForm();
              this.displayBranch = !this.displayBranch;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  ClassificationInfoButton() {
    this.classificationBtnTitle = "Add";
    this.isActive = false;
    this.branchInformation = true;
    this.resetValuesToBranchForm();
    // this.ClassificationSettings = !this.ClassificationSettings;
    this.displayBranch = !this.displayBranch;

  }

  onClassificationAdd() {

  }

  onBranchCancel() {
    this.resetValuesToBranchForm();
    this.branchInformation = !this.branchInformation;
    this.displayBranch = !this.displayBranch;
  }


  onLazyLoad(event: LazyLoadEvent) {
    this.searchRequestModel.FirstRecordIndex = event.first;
    this.searchRequestModel.CurrentPageIndex = event.first / 20;
    this.searchRequestModel.PageSize = 20;
    this.searchRequestModel.SortByColumnName = event.sortField;
    this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
    this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';

    this.getBranchList(true);
  }
  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  private getBranchList(isRefresh: boolean) {
    this.loading = true;
    this.settingsService.getPagedBranchList(this.searchRequestModel, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<BranchResponseModel>>) => {
        this.processResult<PagedResponseModel<BranchResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.branchResponseList = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize) {
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  //private getBranchList() {
  //  this.loadingClassification = true;
  //  this.settingsService.GetBranchList()
  //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingClassification = false))
  //    .subscribe((result: ResultOfT<any>) => {
  //      this.processResult<any>(result, () => {
  //        this.branchResponseList = result.responseModel;
  //        this._cdr.detectChanges();
  //      });
  //    }, (httpResponseError) => {
  //      this.showErrorMessage(httpResponseError.message);
  //    });

  //}


  onBranchDelete(branchResponse: BranchResponseModel) {
    this.resetValuesToBranchForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete 'Branch ${branchResponse.crmBranchName}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      branchResponse.isActive = false;
      const manageBranch = this.settingsService.deleteBranchDetail(branchResponse);
      manageBranch.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('Branch information has been deleted successfully.');
            this.getBranchList(true);
          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }



  private buildForm() {
    const formClassificationGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formClassificationGroup.addControl(this.formFields.branchName, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formClassificationGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formClassificationGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    formClassificationGroup.addControl(this.formFields.brightreeId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    this.form = this.formBuilder.group({
      formClassificationType: formClassificationGroup

    });
  }

  resetValuesToBranchForm() {
    const form = this.formClassificationType;
    form.get(this.formFields.branchName).setValue('');
    form.get(this.formFields.phoneNumber).setValue('');
    form.get(this.formFields.email).setValue('');
    form.get(this.formFields.brightreeId).setValue('');
    this.selectedUsers = [];
    this.branchEditMode = false;
    this.formBranchSubmitted = false;
    form.reset();
  }

  private setValuesToBranchForm() {
    if (!this.selectedBranchModel) {
      return;
    }

    const form = this.formClassificationType;

    // tslint:disable-next-line: max-line-length
    form.get(this.formFields.branchName).setValue(this.selectedBranchModel.crmBranchName);
    form.get(this.formFields.phoneNumber).setValue(this.selectedBranchModel.crmBranchContactNo);
    form.get(this.formFields.email).setValue(this.selectedBranchModel.crmBranchEmailAddress);
    form.get(this.formFields.brightreeId).setValue(this.selectedBranchModel.crmRcmBranchId);
    this.isAllowSelfScheduleAppointments = this.selectedBranchModel.crmIsAllowSelfScheduleAppointments;
    this.isActive = this.selectedBranchModel.isActive;

    if (this.selectedBranchModel.userBranches.length > 0) {
      this.selectedUsers = [];
      this.selectedBranchModel.userBranches.forEach((result) => {
        let userName = this.users.find(x => x.value == result.appUserId.toString());
        if (userName != undefined) {
          const tempuser = { label: userName.label, value: result.appUserId.toString() };
          this.selectedUsers.push(tempuser);
        }
      });
    }
    else {
      this.selectedUsers = [];
    }

  }

  private getValuesFromBranchForm(branch: BranchResponseModel): BranchResponseModel {
    const form = this.formClassificationType;
    let userBranches = new UserBranchRequestModel();

    branch.crmBranchId = Number(0);
    branch.crmBranchName = form.get(this.formFields.branchName).value;
    branch.crmBranchContactNo = form.get(this.formFields.phoneNumber).value;
    branch.crmBranchEmailAddress = form.get(this.formFields.email).value;
    branch.crmRcmBranchId = Number(form.get(this.formFields.brightreeId).value);
    branch.modifiedByUserInfoId = Number(this.loggedInUserId);
    branch.crmIsAllowSelfScheduleAppointments = this.isAllowSelfScheduleAppointments;
    branch.isActive = this.isActive;

    if (this.selectedUsers !== undefined) {
      this.userBranches = [];
      this.selectedUsers.forEach((result) => {
        userBranches = new UserBranchRequestModel();
        userBranches.appUserId = Number(result.value);
        userBranches.crmBranchId = Number(0);
        this.userBranches.push(userBranches);
      });
      branch.userBranches = this.userBranches;
    }

    return branch;
  }

  onCheck(status) {
    this.isActive = status;
  }


  async onBranchAdd() {

    this.formBranchSubmitted = true;
    if (this.formClassificationType.invalid) {
      this.markFormAsTouched();
      return;
    }
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

    const branchModel = this.getValuesFromBranchForm(new BranchResponseModel());
    if (branchModel.crmBranchEmailAddress && !TEXT_REGEXP.test(branchModel.crmBranchEmailAddress)) {
      return;
    }
    let manageBranch
    if (this.branchEditMode) {
      branchModel.crmBranchId = this.selectedBranchModel.crmBranchId;
      manageBranch = this.settingsService.updateBranch(branchModel);
    }
    else {
      manageBranch = this.settingsService.addNewBranch(branchModel);
    }

    this.loading = true;
    await manageBranch.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.getBranchList(true);
          this.showSuccessMessage(this.branchEditMode ? 'Branch information has been updated successfully.' : 'Branch information has been added successfully.');
          this.selectedBranchModel = new BranchResponseModel();
          this.branchEditMode = false;
          this.resetValuesToBranchForm();
          this._cdr.detectChanges();
          this.branchInformation = !this.branchInformation;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
    this.displayBranch = false;
  }

  handleBranchSelfScheduleAppointmentStatusChange(id, e) {
    let isChecked = e.checked;
    this.BranchSelfScheduleAppointmentStatus(id, isChecked);
  }

  BranchSelfScheduleAppointmentStatus(branchId, value) {
    let msg: string;
    if (value) {
      msg = 'Are you sure you want to enable Self-scheduling?';
    }
    else {
      msg = 'Are you sure you want to disable Self-scheduling?';
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: msg,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.branchResponseList.find(x => x.crmBranchId == branchId).crmIsAllowSelfScheduleAppointments = !value;
        return;
      }
      this.loading = true;

      const branchResponseModel = new BranchResponseModel();
      branchResponseModel.crmBranchId = branchId;
      branchResponseModel.crmIsAllowSelfScheduleAppointments = value;
      const manageBranchStatus = this.settingsService.UpdateBranchSelfScheduleAppointmentsStatus(branchResponseModel);
      manageBranchStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            if (value) {
              this.showSuccessMessage('Self-Scheduling has been activated');
            }
            else {
              this.showSuccessMessage('Self-Scheduling has been deactivated');
            }
            this.getBranchList(true);

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  SelectedBranchSelfScheduleAppointmentStatus(value) {
    if (!this.selectedbranches || this.selectedbranches.length <= 0) {
      this.showWarningMessage('Please select Branch.');
      return;
    }

    let msg: string;
    if (value) {
      msg = 'Are you sure you want to enable Self-scheduling?';
    }
    else {
      msg = 'Are you sure you want to disable Self-scheduling?';
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: msg,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      let selectedBranchResponseModel = new SelectedBranchResponseModel();
      selectedBranchResponseModel.isEnable = value;
      selectedBranchResponseModel.branchResponseModels = this.selectedbranches;
      const manageFacilityStatus = this.settingsService.UpdateSelectedBranchSelfScheduleAppointmentsStatus(selectedBranchResponseModel);
      manageFacilityStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            if (value) {
              this.showSuccessMessage('Self-Scheduling has been activated');
            }
            else {
              this.showSuccessMessage('Self-Scheduling has been deactivated');
            }
            this.getBranchList(true);
            this.selectedbranches = [];
          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  private async getAllDMEUsers() {
    this.progress.next(true);
    await this.settingsService.getAllDMEUsers()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.users = [];
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            const therapistArray = result.responseModel;
            therapistArray.forEach((value: UserResponseModel) => {
              this.users.push({ label: value.appUserLname + ", " + value.appUserFname, value: value.appUserInfoId.toString() });
            });
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
