import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { AppointmentsCountsResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { FacilitySearchResponseModel, FacilityTimeSlotResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { FacilityTimeSlotRequest } from 'src/app/shared/models/request/facility-slots-request';
import { FacilityTherapistAvalabilityRequestModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-request-model';
import { FacilityTherapistAvalabilityModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-model';
import { TherapistRoadShowAvailabilityRequestModel } from 'src/app/shared/models/request/therapist-availability-request';
import { TherapistAvailabilityTimeslotsModel } from 'src/app/shared/models/response/appointment/therapist-availability-timeslots-model';
import { AvailableSlots, FaciltiyForSlots, TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TheraistAppointmentService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }
  GetAllWIPStatesBySalesOrderType(salesOrderType: string) {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Appointment/GetAllWIPStatesBySalesOrderType?salesOrderType=' + salesOrderType);
  }
 
  GetAllAppointmentsByTherapist(therapistId: string, appointmentDate: string) {
    return this.get<ResultOfT<AppointmentSummaryResponseModel[]>>('Appointment/GetAppointmentsTherapistWise/' + therapistId + '/' + appointmentDate);
  }

  GetTherapistAppointment(therapistId: number, appointmentDate: string) {
    return this.get<ResultOfT<AppointmentInfoReponseModel[]>>('Therapist/GetTherapistAppointment?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  GetTherapistsByBranch(branchId: number) {
    return this.get<ResultOfT<TherapistResponseModel[]>>('Therapist/GetTherapistsByBranch/' + branchId);

  }
  UpdateAppointmentStatus(salesorders: AppointmentUpdateStatusRequestModel[]) {

    return this.post<ResultOfT<boolean>>('Appointment/UpdateAppointmentStatus', salesorders);
  }
  GetAppointmentById(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentInfoReponseModel>>('Appointment/GetAppointmentById/' + AppointmentId);
  }

  GetTherapistAvailability(therapistId: number, dtDate: string) {
    return this.get<ResultOfT<TherapistAvailability[]>>('Appointment/GetTherapistAvailability/' + therapistId + '/' + dtDate);
  }

  GetTherapistFacility(therapistId: number, appointmentDate: string) {
    return this.get<ResultOfT<FacilityInfoResponseModel>>('Therapist/GetTherapistFacility?userInfoId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }
}
