import { ModuleFeatureResponseModel } from "./settings/settings-permission-response-model";

export class UserPermissionResponseModel {
  appUserPermissionId: number;
  appUserId: number;
  appUserModuleFeatureGroupId: number;
  appUserModuleFeatureId: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  userPermission: ModuleFeatureResponseModel
}