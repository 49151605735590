import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { PatientNoteConfigurationBrightree } from 'src/app/shared/models/response/patientNote-configuration-brightree';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result } from 'src/app/shared/models/response/result';
import { Router } from '@angular/router';

@Component({
  selector: 'app-patient-note',
  templateUrl: './patient-note.component.html',
  styleUrls: ['./patient-note.component.sass']
})
export class PatientNoteComponent extends BaseComponent implements OnInit, OnDestroy {
  
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  patientNoteConfig: PatientNoteConfigurationBrightree[];
  form: FormGroup;
  isProvisional: boolean;

  get ordersFormArray() {
    return this.form.get('orders') as FormArray;
  }

  constructor(private webStorageService: WebStorageService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    messageService: MessageService,
    private router: Router) {
    super(messageService);

    this.getPatientNoteConfig();
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;

  }

  ngOnInit() {

  }
  
  async getPatientNoteConfig() {
    this.loading = true;
    await this.settingsService.getPatientNoteConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<PatientNoteConfigurationBrightree[]>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.patientNoteConfig = result.responseModel;
            this.form = this.formBuilder.group({
              orders: this.formBuilder.array(this.patientNoteConfig.map(f => this.formBuilder.group(f)))
            });
            this.loading = false
          }
          else {
            this.loading = false
          }
        })
      });
  }

  submit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    const selectedOrderIds = this.form.value.orders;
    const updateResult = this.settingsService.updatePatientNoteConfig(selectedOrderIds);

    this.loading = true;
    updateResult.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage("Patient note updated sucessfully.");
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  cancel() {
    this.router.navigate(['/admin/settings']);
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

}
