<!-- Start Content -->
<div class="content-area admin-calendar-outer">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="calendar-top-right"> 
                   
      <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
        <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
          <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
          <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
        </svg> 
        <span class="loading">Refresh</span>
      </a> 

      <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
        [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()" placeholder="Select Calendar View"
        optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>

      <a href="javascript:void(0);" class="filter-block filter-show-hide" (click)="clanderFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>
  <div class="calendra-top-btn">
    <div class="btn-group calendra-next-prev-btn">
      <div class="next-date-left">

        <div class="mwl-calendar">
          <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 2.42857L3.75 8.5L10 14.5714L8.75 17L0 8.5L8.75 0L10 2.42857Z" fill="#868686"/>
              </svg>
          </div>

          <div class="btn btn--primary todyas" mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            Today
          </div>

          <h4 class="dates">
            {{ viewDate | calendarDate:(view + 'ViewTitle'):'en':weekStartsOn }}
          </h4>

          <div class="btn btn--arrow next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" (click)="dateChange()">
            <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M-1.69504e-06 14.5714L6.25 8.5L-6.33474e-07 2.42857L1.25 -7.64949e-07L10 8.5L1.25 17L-1.69504e-06 14.5714Z" fill="#868686"/>
            </svg>
          </div>

          
        </div>
      </div>
      <div class="calendar-right-sidebar">
        <div class="minutes-btn">
          <p-selectButton class="select-btn" [options]="minutes" [(ngModel)]="selectedMinute" optionLabel="label"
            (click)="onSlotChange()"></p-selectButton>
        </div>
      </div>
    </div>
  </div>
  <div class="view-by-date-outer">
    <div class="column-freeze-table view-by-date">
      <table class="mt-table calendar-table" mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="facilityName" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Facility Name </th>

          <td mat-cell *matCellDef="let element" class="facilityName-td">
            <span
              *ngIf="element.facilityName !='Available Slots' && element.timeZone !='' && element.facilityName != 'Road/Home Appt'"
              class="facilityName">{{element.facilityName}}<span class="timezone-code">{{element.timeZone |
                timezone}}</span></span>
            <span *ngIf="element.facilityName !='Available Slots' && element.timeZone ==''"
              class="facilityName">{{element.facilityName}}</span>
            <span *ngIf="element.facilityName !='Available Slots' && element.facilityName == 'Road/Home Appt'"
              class="facilityName">{{element.facilityName}}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="slotSun">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateSun,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateSun | date:'EEE'}} <br>
              <span>{{dtDateSun | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotSun<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateSun) ? '' : 'no-event'"
              (click)="element.slotSun>0 && isCheck(dtDateSun) && recdirectToFacilityCal(element.dtDateSun,element.facilityId, element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotSun}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotMon">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateMon,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateMon | date:'EEE'}} <br>
              <span>{{dtDateMon | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotMon<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateMon) ? '' : 'no-event'"
              (click)="element.slotMon>0 && isCheck(dtDateMon) && recdirectToFacilityCal(element.dtDateMon,element.facilityId,element.timeZone)">

              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotMon}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotTue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateTue,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateTue | date:'EEE'}} <br>
              <span>{{dtDateTue | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotTue<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateTue) ? '' : 'no-event'"
              (click)="element.slotTue>0 && isCheck(dtDateTue) && recdirectToFacilityCal(element.dtDateTue,element.facilityId,element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotTue}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>

            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotWed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateWed,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateWed | date:'EEE'}} <br><span>{{dtDateWed | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotWed<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateWed) ? '' : 'no-event'"
              (click)="element.slotWed>0 && isCheck(dtDateWed) && recdirectToFacilityCal(element.dtDateWed,element.facilityId,element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotWed}}
              </span>
              <span class="available-slots-text"
                *ngIf="element.facilityName =='Available Slots'">{{availableSlotText}}</span>

            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotThu">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateThu,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateThu | date:'EEE'}} <br><span>{{dtDateThu | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotThu<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateThu) ? '' : 'no-event'"
              (click)="element.slotThu>0 && isCheck(dtDateThu)  && recdirectToFacilityCal(element.dtDateThu,element.facilityId,element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotThu}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>

            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotFri">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateFri,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateFri | date:'EEE'}} <br><span>{{dtDateFri | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotFri<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateFri) ? '' : 'no-event'"
              (click)="element.slotFri>0 && isCheck(dtDateFri) && recdirectToFacilityCal(element.dtDateFri,element.facilityId,element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotFri}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="slotSat" mat-sort-header>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span (click)="recdirectToFacilityCal(dtDateSat,-1,'EST')" class="calendar-view-by-date-header">
              {{dtDateSat | date:'EEE'}} <br><span>{{dtDateSat | date:appDateFormat.dateFormat}}</span>
            </span>
          </th>

          <td mat-cell *matCellDef="let element" [ngClass]="element.slotSat<=0 ? 'available' : 'available'">
            <div class="table-left-sidebar check-arrow" [ngClass]="isCheck(dtDateSat) ? '' : 'no-event'"
              (click)="element.slotSat>0 && isCheck(dtDateSat) && recdirectToFacilityCal(element.dtDateSat,element.facilityId,element.timeZone)">
              <span class="available-slots" *ngIf="element.facilityName !='Available Slots'">
                {{element.slotSat}}
              </span>
              <span class="available-slots-text" *ngIf="element.facilityName =='Available Slots'"></span>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
<ng-template #loading style="height: 100%; position: absolute; width: 100%; text-align: center;">
  <div class="loader-block">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</ng-template>

<div class="loader-block" *ngIf="loadingCustom">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>

<!-- filter -->
<p-overlayPanel class="overly-filter" #clanderFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">
      <div class="road-show-checkbox">
        <p-checkbox name="groupname" [(ngModel)]="isShowHomeVisit" (change)="onCheck();" label="Road/Home Appt"
          binary="true"></p-checkbox>
      </div>
      <div class="form__group">
        <label class="form__label">Branch</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedbranches" [options]="branchResponseModel" defaultLabel="Select branch"
          (onChange)="onBranchChange($event)" optionLabel="crmBranchName" [filter]="true"
          selectedItemsLabel="{0} Branch(s) Selected"></p-multiSelect>
      </div>
      <div class="form__group">
        <label class="form__label">Facility</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedAppointmentFacilities" [options]="facilityResponseModel" defaultLabel="Select Facility"
          (onChange)="onFacilityChange($event)" optionLabel="crmFacilityName" [filter]="true"
          selectedItemsLabel="{0} facility(s) Selected"></p-multiSelect>
      </div>
      <div class="from__group full-width">
        <label class="form__label">Time Zone</label>
        <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
          [(ngModel)]="selectedTimeZone" [options]="timeZoneList" placeholder="Select"
          (onChange)="onTimeZoneChange($event.value)" optionLabel="appStandardTimeZoneName"
          selectedItemsLabel="{0} Time Zone Selected"></p-multiSelect>
      </div>
    </div>
    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
        (click)="onClearFilterSearch();onFilterSearch()">Clear</a>
    </div>
  </div>
</p-overlayPanel>