import { UserBranchRequestModel } from "../request/user-branch-request-model";

export class BranchResponseModel {
  crmBranchId: number;
  crmBranchName: string;
  crmBranchAddress: string;
  crmBranchContactName: string;
  crmBranchContactNo: string;
  crmBranchEmailAddress: string;
  crmBranchLogo: string;
  crmIsAllowSelfScheduleAppointments: boolean;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmRcmBranchId: number;
  userBranches: UserBranchRequestModel[];
}

export class SelectedBranchResponseModel {
  isEnable: boolean;
  branchResponseModels: BranchResponseModel[];
}