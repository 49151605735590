import { Component, Pipe } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe {
  transform(rawNum) {
    //rawNum = rawNum.charAt(0) != 0 ? "0" + rawNum : "" + rawNum;

    if (rawNum) {
      let newStr = "";
      let i = 0;

      for (; i < Math.floor(rawNum.length / 3) - 1; i++) {
        if (i == 0)
          newStr = "(" + newStr + rawNum.substr(i * 3, 3) + ") ";
        else
          newStr = newStr + rawNum.substr(i * 3, 3) + "-";
      }
      return "+1 " + newStr + rawNum.substr(i * 3);
      //return newStr + rawNum.substr(i * 3);
    } else
      return "";
  }
}
