import { Injectable } from '@angular/core';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots';
import { WebStorageService } from '../../shared/services/web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HolidayService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }
  _tabIndex: number = 0;

  _setTabIndex(tabIndex: number) {
    this._tabIndex = tabIndex;
  }
  _getTabIndex(): any {
    return this._tabIndex;
  }

  CopyPreviousYearHoliday(fromYear: number, toYear: number, isOveride: boolean, holidaysIds: string) {
    return this.get<ResultOfT<HolidayResponse[]>>('Settings/CopyPreviousYearHoliday/' + fromYear + '/' + toYear + '/' + isOveride + '/' + holidaysIds);
  }

  GetAllHolidays() {
    return this.get<ResultOfT<HolidayResponse[]>>('Settings/GetHolidayList');
  }

  GetHolidaysByYear(year: number) {
    return this.get<ResultOfT<HolidayResponse[]>>('Settings/GetHolidayByYear/' + year);
  }

  DeleteHoliday(appHolidayId: number) {
    return this.get<ResultOfT<boolean>>('Settings/DeleteHoliday/' + appHolidayId )
      .pipe(map((result) => {
        return result;
      }));
  }

   GetHolidayById(appHolidayId: number) {
    return this.get<ResultOfT<HolidayResponse>>('Settings/GetHolidayById/' + appHolidayId);
  }

  AddHoliday(holidayResponseModel: HolidayResponse) {
    return this.post<ResultOfT<boolean>>('Settings/AddHoliday', holidayResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateHoliday(holidayResponseModel: HolidayResponse) {
    return this.post<ResultOfT<boolean>>('Settings/UpdateHoliday', holidayResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }
  
}
