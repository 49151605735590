
export class SettingsClassificationTypeResponseModel {
  crmClassificationTypeId: number;
  crmClassificationType: string;
  crmClassificationDisplayName: string;
  crmClassificaitonduration: number;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmDescription: string;
  crmWIPStatusNameBT: string;
  crmWIPAssignedToKey: number;
  crmWIPAssignedToPerson: string;
  crmBlackOutDays: number;
  crmIsIncludeWeekend: boolean;
  crmInvetoryLocationId: number;
  crmIsEnableAppointmentMaxLimit: boolean;
  crmMaxNoOfAppointment: number;
  excludeBlackoutDaysRemote: boolean;
  excludeBlackoutDaysFacility: boolean;
  isExcludeBlackoutDaysRemote: boolean;
  isExcludeBlackoutDaysFacility: boolean;
  ExcludeBlackOutValue: boolean;
  crmClassificationDocuments: string;
  crmFacilityClassificationDuration: number;
  crmRemoteClassificationDuration: number;
  crmRoadHomeClassificationDuration: number;
}
export class CancelVoidResponseModel {
  salesOrderVoidReasonId: number;
  salesOrderVoidReason: string;
  salesOrderReasonType: string;
  modifiedByUserInfoId: number;
  isActive: Boolean;

}
