<div class="cd-popup appt-massages-outer" role="alert">
    <div class="cd-popup-container text-center appt-massages-block">
        <h2 class="appt-massages-block__title-box">{{popupHeader}}</h2>

        <div class="appt-massages-block__content-box">
            <div *ngIf="restrictedApptForStatus?.length>0">
                <p>You have selected ({{totalSelectedItem}}) appointment(s), yet ({{restrictedApptForStatus?.length}})
                    of them do
                    not qualify for the <strong>{{action}}</strong> status as their appointment
                    times have not arrived.
                </p>
                <p class="appt-massages-block__between-selected-period">The following appointments are not eligible for
                    the
                    {{action}} status.</p>

                <div class="appt-massages-block__table-block">
                    <div class="appt-massages-block__table-box">
                        <table class="appt-massages-block__custom-tables">
                            <thead>
                                <tr>
                                    <th class="appt-massages-block__th">S/O ID</th>
                                    <th class="appt-massages-block__th">Patient Name </th>
                                    <th class="appt-massages-block__th">Appointment Date & Time</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let restrictedAppointment of restrictedApptForStatus">
                                <tr>
                                    <td class="appt-massages-block__td">{{restrictedAppointment.crmSalesOrderAPPId}}
                                    </td>
                                    <td class="appt-massages-block__td">{{restrictedAppointment.crmPatientName |
                                        titlecase}}</td>
                                    <td class="appt-massages-block__td">
                                        {{restrictedAppointment.schAppointmentDateTime | date:appDateFormat.dateFormat}}
                                        <!-- {{restrictedAppointment.schAppointmentDateTime | date:'shortTime' | lowercase}} | {{restrictedAppointment.crmFacilityTimeZone}}  -->
                                        {{restrictedAppointment.schAppointmentDateTime | date:'shortTime' | lowercase}}
                                        | {{restrictedAppointment.crmPatientTimeZone | timezone}}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div *ngIf="restrictedApptForStatus?.length != totalSelectedItem">

                <div
                    *ngIf="restrictedApptForStatus?.length != 0 &&  restrictedApptForStatus?.length != totalSelectedItem">
                    <div>
                        <p>To proceed with updating the status of selected ({{restrictedApptForStatus?.length}}) of
                            appointment(s) to <strong>{{dynamicDialogConfig.data.action}}</strong>, please confirm by
                            clicking 'Yes, Update.'</p>
                    </div>
                </div>

                <div
                    *ngIf="restrictedApptForStatus?.length == 0 &&  restrictedApptForStatus?.length != totalSelectedItem">
                    <div>
                        <p>{{message}} <strong>{{dynamicDialogConfig.data.action}}</strong>.</p>
                    </div>
                </div>

                <div class="schedulecall">

                    <div class="col">
                        <div class="form__group full-width">
                            <label class="form__label">Comment</label>
                            <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonComment"
                                maxlength="100"></textarea>
                            <span class="invalid-feedback-error" *ngIf="(formSubmitted && reasonComment == '')"
                                style="color:red">{{validationMessages.reasonComment.required}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="check receive-reminder wip-state-will-not-update">
                            <p-checkbox name="groupnamecomplete"
                                label="If the check box is selected, the WIP state will not be updated in Brightree."
                                binary="true" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="isBrightreeSystemAppUpdate">
                            </p-checkbox>
                        </div>
                    </div>
                </div>

                <p style="padding-top: 2rem;font-weight: 500;">Would you like to proceed?</p>
            </div>
        </div>

        <div *ngIf="restrictedApptForStatus?.length != totalSelectedItem">
            <div class="cd-buttons">
                <button class="btn btn--secondary mr-2"
                    (click)="onCancel()">{{dynamicDialogConfig.data.cancelButtonText}}</button>
                <button class="btn btn--primary mr-2"
                    (click)="onSubmit()">{{dynamicDialogConfig.data.updateButtonText}}</button>
            </div>
        </div>

        <div *ngIf="restrictedApptForStatus?.length == totalSelectedItem">
            <div class="cd-buttons">
                <button class="btn btn--primary mr-2" (click)="onCancel()">Okay</button>
            </div>
        </div>

    </div>
</div>