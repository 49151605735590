import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, } from '@angular/core';
import { Router } from '@angular/router';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../servicies/appointment.service';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { DynamicCols } from 'src/app/shared/models/common/dynamic-cols';
import { SalesorderCallLogsComponent } from 'src/app/admin/appointments/salesorder-call-logs/salesorder-call-logs.component';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model'
import { AppointmentsCountsResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model'
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { AppointmentCallListResponseModel } from 'src/app/shared/models/request/appointment-call-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { Table, TableHeaderCheckbox } from "primeng/table";
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { TwillioCallService } from '../servicies/twillioCall.service';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserService } from 'src/app/admin/servicies/user.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BookAppointmentInfoComponent } from '../book-appointment-info/book-appointment-info.component';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { SettingsService } from '../servicies/settings.service';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { Constants } from 'src/app/shared/constants';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserPreferencesModuleColumnResponseModel } from 'src/app/shared/models/response/user-wise-grid-column-response-model';
import { UserWiseModuleColumn } from 'src/app/shared/models/request/user-wise-column-model-request';
import { ModuleType } from 'src/app/shared/enums';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.sass'],
  providers: [DialogService]
})
export class SearchResultsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  title: string;
  features: featureResponseModel[];
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  totalRecords: number;
  selectedsalesOrdersCount: number;
  totalPages: number;
  searchRequestModel: SearchRequestModel;
  salesOrders: SalesOrderSummaryResponseModel[];
  selectedsalesOrders: SalesOrderSummaryResponseModel[];
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderCountsResponseModel: SalesOrderCountsResponseModel;
  salesOrderCountModels: SalesOrdersCountModel[];
  salesSelectedOrderCountModels: SalesOrdersCountModel;
  appointmentCallListRequestModel: AppointmentCallListResponseModel[];
  dynamicNonSColumns: DynamicCols[];
  selectedNonSColumns: DynamicCols[];
  selectedSalesOrder: SalesOrderSummaryResponseModel;
  selectedTab: string;
  duration: string;
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  salesOrderType: string;
  AppointmentOrderType: string;
  callConfigModel: CallConfigModel;
  callPopup: boolean = false;
  callnote: string;
  calldate: Date;
  calltime: Date;
  callId: number;
  reschedulecallId: number;
  SchedulePopup: boolean = false;
  //Scheduled
  totalAppointmentRecords: number;
  totalAppointmentPages: number;
  searchAppointmentRequestModel: SearchRequestModel;
  appointmentsalesOrderStatus: WIPStatesResponseModel[];
  appointments: AppointmentSummaryResponseModel[];
  selectedAppointments: AppointmentSummaryResponseModel[];
  selectedAppointment: AppointmentSummaryResponseModel;
  appointment: AppointmentInfoReponseModel;
  appointmentsCounts: AppointmentsCountsResponseModel;
  selectedAppointmentStatus: WIPStatesResponseModel;
  dynamicColumns: DynamicCols[];
  selectedColumns: DynamicCols[];
  branchResponseModel: BranchResponseModel[];
  selectedNSbranch: BranchResponseModel;
  selectedSbranch: BranchResponseModel;
  appointmentCountModels: SalesOrdersCountModel[];
  appointmentSelectedCountModels: SalesOrdersCountModel;
  data1: any;
  dataExpressionloading: boolean = false;
  appDateFormat: DateFormatModel;
  @ViewChild("table") private _table: Table;
  @ViewChild('searchBoxAppointment') searchBoxAppointment: ElementRef;
  @ViewChild('statusDropdownAppointment') statusDropdownAppointment: ElementRef;
  @ViewChild("headerCheckBox") private headerCheckBox: TableHeaderCheckbox;
  @ViewChildren('headerCheckBox') childrenComponent: QueryList<TableHeaderCheckbox>;
  connection: any;
  callSID: string;
  device: any;
  mySubscription: any;
  minutes: SelectItem[];
  selectedMinute: SelectItem;
  rescheduleCallSID: string;
  isAppointmentDisabled: boolean = false;
  selectedNSFilter: QuickFilters;
  quickNSFilters: QuickFilters[];
  selectedSFilter: QuickFilters;
  quickSFilters: QuickFilters[];
  searchFromNSDate: Date;
  searchToNSDate: Date;
  searchFromSDate: Date;
  searchToSDate: Date;
  customSDate: boolean;
  customNSDate: boolean;
  NsheduledTableDefaltColumns: number = 6;
  sheduledTableDefaltColumns: number = 6;
  scheduleappointment: boolean = true;
  schedulecall: boolean = false;
  actionType: string = "S";
  scheduleAction: string;
  loggedInUserId: number;
  selectedAppointmentType: string = "FacilityVisit";
  appointmentCallResponseModel: AppointmentCallResponseModel;
  selectedfilterIndex = 0;
  selectedfilterSIndex = 1;
  status: boolean = false;
  currentUserBranch: number;
  currentSelectedNSUserBranch: number;
  currentSelectedSUserBranch: number;
  //global search
  searchText: string;
  orderObj: any;
  NSSelectedRange: any;
  NSSelectedDateRange: any;
  SSelectedDateRange: any;
  //added for popup
  voidFrom: string = "";
  salesOrderId: number;
  schAppointmentId: number;
  scheduleActionePopUp: string;
  salesOrder: SalesOrderInfoResponseModel;
  isAppointment: boolean;
  isTelehealth: boolean;
  selectedAppointmentTypePopUp: string;
  scheduleAppointment: boolean = false;
  voiceMailAppointment: boolean = false;
  appointmentNextCall: boolean = false;
  isCallStarted: boolean = false;
  IsRescheduleFacilityOption: boolean = false;
  rescheduleFacilityOption: string;
  callocales: any;
  selected: any;
  defaultModeofAppointment: string = "";
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  userPreferenceColumn: UserPreferencesModuleColumnResponseModel[];
  isOnlyFixedColumnVisible: boolean = false;
  isStatusCompleted: boolean = false;
  movies: any[];
  //istodaysSummaryDashboard: boolean = false;
  clickEvent(index: number) {

    //this.selectedfilterIndex = this.selectedfilterIndex === index ? -1 : index;
    this.selectedfilterIndex = index;
    //event.currentTarget.className = "appointments-top-block__box active";
    //this.status = false;
  }

  toggelScheduledClass(index: number) {

    //this.selectedfilterSIndex = this.selectedfilterSIndex === index ? -1 : index;
    this.selectedfilterSIndex = index;
    //event.currentTarget.className = "appointments-top-block__box active";
    //this.status = false;
  }

  //Freeze
  cols: any[];
  frozencols: any[];
  colsSch: any[];
  frozencolsSch: any[];
  height: string = "400px";
  height1: string = "400px";
  //Freeze
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private userService: UserService, private settingsService: SettingsService, private formBuilder: FormBuilder) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    this.setPermissionforActions();

    //Not schduled  Parameters
    this.totalRecords = 0;
    this.totalPages = 1;
    this.loading = false;
    this.salesOrderType = 'SalesOrderRequest';
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.currentSelectedSUserBranch = this.currentUserBranch;

    this.selectedSalesOrder = new SalesOrderSummaryResponseModel();
    this.selectedNonSColumns = new Array();
    this.dynamicNonSColumns = new Array();
    //const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    this.getUserPreferenceColumn();

    // if (currentNavigationState && currentNavigationState.istodaysSummaryDashboard) {
    //   this.searchAppointmentRequestModel = currentNavigationState.searchRequestModel;
    //   this.istodaysSummaryDashboard = currentNavigationState.istodaysSummaryDashboard;
    // }

    this.title = "Search Results";
  }



  async ngOnInit() {
    //await this.getDefaultModeofAppointment();
    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {
      if (branchId != null && branchId >= 0) {
        this.salesSelectedOrderCountModels = null;
        this.appointmentSelectedCountModels = null;
        this.selectedfilterIndex = 0;
        this.selectedfilterSIndex = -1;
        this.currentUserBranch = branchId;
        this.currentSelectedNSUserBranch = branchId;
        this.currentSelectedSUserBranch = branchId;
        this.initializeSearchRequestModel()
        this.getsalesorders();
        let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
        if (selectedBranch) {
          this.selectedNSbranch = selectedBranch;
          this.selectedSbranch = selectedBranch;
        }
        else {
          this.selectedNSbranch = this.branchResponseModel[0];
          this.selectedSbranch = this.branchResponseModel[0];;
        }

      }
    });
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.initializeSearchRequestModel();
    this.buildForm();
    await this.getCancelVoidReasons();
    await this.getWipStatusBTmapping();
  }

  onSelectionChange(selection: any[]) {

    for (let i = selection.length - 1; i >= 0; i--) {
      let data = selection[i];
      if (this.isRowDisabled(data)) {
        selection.splice(i, 1);
      }
    }
    this.selectedsalesOrders = selection;
  }

  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
      if (comps.length > 0) {
        const orig_updateCheckedState = this.headerCheckBox.updateCheckedState;
        const me = this;
        this.headerCheckBox.updateCheckedState = function () {

          const cars: any[] = me._table.filteredValue || me._table.value;
          const selection: any[] = me._table.selection;
          for (const car of cars) {
            if (!me.isRowDisabled(car)) {
              const selected = selection && selection.indexOf(car) >= 0;
              if (!selected) return false;
            }
          }
          if (selection && selection.length > 0)
            return true;
        };
      }
    });


    //this.loading = false;
    this.setFilters();
    // this.hasPageHistory = false;


    const frozenBody: HTMLElement | null = document.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea: HTMLElement | null = document.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody && scrollableArea) {
      frozenBody.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
        const canScrollUp = 0 < scrollableArea.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea.scrollTop -= scrollDelta;
        }
      });
    }
  }

  isRowDisabled(data: any): boolean {
    return data.schIsLocked === true
  }

  private setFilters() {
    if (!this.hasPageHistory)
      return;

    if (this.searchRequestModel.dictionaryFilter['SearchText'])
      this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'];

  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }

    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
      //this.globalSearchService.changeGlobalBranch.complete();
    }


    if (this.progress)
      this.progress.complete();
  }

  //Start : Non Scheduled Events

  onView(salesOrderId: number, apptId: number) {
    if (apptId == 0)
      this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: 0, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: 0, redirectFromSearch: true } });
    else
      this.router.navigate(['/admin/appointments/detail/', apptId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: 1, selectedfilterIndex: 1, redirectFromSearch: true } });
  }

  showAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;
    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe((session: any) => {
      if (!session) {
        this.getsalesorders();
      }
      else if (session.isSession) {
        this.getsalesorders();
      }
      this.HangUpCall();

    });


  }

  selectNonScheduleSalesOrder(event, selSalesOrder: SalesOrderSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedSalesOrder = selSalesOrder;
    overlaypanel.toggle(event);
  }

  AddNonSColumnInTable(event, col: UserPreferencesModuleColumnResponseModel[]) {
    this.loading = true;
    let isChecked = event.checked;

    let arruserModuleColumn = new Array();
    let IsAnyColVisible = col.filter(x => x.preferenceVisible === true);
    if (IsAnyColVisible.length == 0) {
      this.isOnlyFixedColumnVisible = true;
    }
    else {
      this.isOnlyFixedColumnVisible = false;
    }
    this.userPreferenceColumn.forEach(x => {
      x.visible = x.preferenceVisible;
    })
    col.forEach(x => {
      let userModuleColumn = new UserWiseModuleColumn();
      userModuleColumn.UserInfoId = this.loggedInUserId;
      userModuleColumn.ModuleId = x.moduleId;
      userModuleColumn.ColumnName = x.field;
      userModuleColumn.IsActive = x.preferenceVisible;
      userModuleColumn.Id = x.userModuleColumnId;
      userModuleColumn.UserPreferencesDisplayOrder = col.findIndex(y => y.id === x.id);
      arruserModuleColumn.push(userModuleColumn);
    });

    this.appointmentService.addUserPreferenceColumn(arruserModuleColumn)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage("Column display settings save successfully.")
            }
          }
        });

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize)
      return this.searchRequestModel.PageSize;

    return this.totalRecords;
  }

  onLazyLoad(event: LazyLoadEvent) {

    if (!this.hasPageHistory) {

      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getsalesorders();
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory)
      return;

    this.searchText = this.webStorageService.getGlobalSearch();
    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    if (this.searchText) {
      this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
    //this.webStorageService.deleteGlobalSearch();

    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";


    // if (this.istodaysSummaryDashboard) {
    //   this.searchRequestModel.dictionaryFilter = {};
    //   this.searchRequestModel.isTodaysBooking = this.searchAppointmentRequestModel.isTodaysBooking;
    //   this.searchRequestModel.AppointmentStatus = this.searchAppointmentRequestModel.AppointmentStatus;
    //   this.searchRequestModel.SearchRequestFromDate = this.searchAppointmentRequestModel.BookingSearchFromDate;
    //   this.searchRequestModel.SearchRequestToDate = this.searchAppointmentRequestModel.BookingSearchToDate;
    // }

  }

  private getsalesorders() {
    this.loading = true;
    this.dataExpressionloading = true;
    this.appointmentService.GetSearchResults(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;
                if (this.salesOrders != null)
                  this.salesOrders.forEach(x => {
                    if (x.crmSalesorderStatus == "Complete" || x.crmSalesorderStatus == "Void") {
                      x.isStatusCompleted = true;
                    }
                  })
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                this.dataExpressionloading = false;
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }

  AddColumnInTable(event, fieldNo) {

    this.colsSch.forEach(value => {

      if (value.fieldNo == fieldNo) {
        let isChecked = event.checked;
        if (isChecked)
          value.visible = true;
        else
          value.visible = false;
      }
    });

    this.selectedColumns = this.colsSch.filter(x => x.visible === true);
  }

  selectScheduleSalesOrder(event, selectedAppointment: AppointmentSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedAppointment = selectedAppointment;
    overlaypanel.toggle(event);
  }


  showDialog(crmSalesOrder: SalesOrderSummaryResponseModel, appointmentId) {
    let crmSalesOrderId = crmSalesOrder.crmSalesOrderId;
    //if (crmSalesOrder.crmIsEnableAppointmentMaxLimit) {
    //  if (crmSalesOrder.crmClassificationTypeBookedAppointmentCount >= crmSalesOrder.crmMaxNoOfAppointment) {

    //    const refcheck = this.dialougeService.open(InformationDialogComponent, {
    //      data:
    //      {
    //        message: 'This classification type has reached the maximum number of appointments per day. If you want to increase the limit for the appointment per day. Please go to the Settings => Brightree => Classification Settings.',
    //      },
    //      header: 'Information'
    //    });

    //    refcheck.onClose.subscribe((response: boolean) => {
    //      if (response) {
    //        return;
    //      }
    //    });
    //    return;
    //  }
    //}

    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = false;
    this.schAppointmentId = appointmentId;

    this.scheduleActionePopUp = this.schAppointmentId > 0 ? 'Reschedule' : 'Schedule';

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: this.schAppointmentId > 0,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: this.schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: "A",
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe((session: any) => {
      if (!session) {
        this.getsalesorders();
      }
      else if (session.isSession) {
        this.getsalesorders();
      }
      this.HangUpCall();
    });
  }



  async HangUpCall() {
    if (this.device) {
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
  }



  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {
      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }

  //Permission Check
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  isAppointmentVoidPermission: boolean = false;
  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features && this.features.length > 0) {

      let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
      if (inPer1 && inPer1.isActive == true) {
        this.isAppointmentScheduledPermission = true;
        this.isAppointmentRescheduledPermission = true;
      }

      let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
      if (inPer2 && inPer2.isActive == true)
        this.isAppointmentCancelPermission = true;

      let inPer8 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentvoid');
      if (inPer8 && inPer8.isActive == true)
        this.isAppointmentVoidPermission = true;

    }
  }

  formCancelSubmitted: boolean = false;
  formVoidAppointmentSubmitted: boolean = false;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  reasonsResponseModel: CancelVoidResponseModel[];
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];
  customloading: boolean = false;
  get fCancel() { return (<FormGroup>this.form.get('formCancel')).controls; }

  get formCancel() {
    return this.form.get('formCancel');
  }
  private getValuesFromCancelForm(): AppointmentCancelRequest {

    const form = this.formCancel;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.reason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.reasonComments).value;
    return cancelRequestModel;
  }

  cancelAppointment() {

    this.formCancelSubmitted = true;
    if (this.formCancel.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.formCancelSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromCancelForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.cancelschAppointmentId;
    let cancelAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    cancelAppointmentRequestBT.IsUpdateToBrightree = false;
    cancelAppointmentRequestBT.IsDeliveryNoteOverride = true;
    cancelAppointmentRequestBT.IsOrderNoteOverride = true;
    cancelAppointmentRequestBT.OrderNote = null;
    cancelAppointmentRequestBT.DeliveryNote = null;
    cancelAppointmentRequestBT.PractitionerName = null;
    cancelAppointmentRequestBT.PractitionerId = 0;
    cancelAppointmentRequestBT.DeliveryTechnicianId = 0;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    cancelAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    cancelAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
    if (selectedWipStaus) {

      cancelAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
      cancelAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
      cancelAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
      cancelAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
    }
    cancelAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = cancelAppointmentRequestBT;
    this.customloading = true;
    this.appointmentService.CancelAppointment(cancelRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.customloading = false))
      .subscribe((result) => {

        if (result.responseModel) {

          this.showSuccessMessage("Appointment has been canceled.");
          const form = this.formCancel;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.reason).setValue('');
          form.get(this.formFields.reasonComments).setValue('');
          form.reset();
          this.customloading = false;
          this.displayCancelAppointment = false;
          this.getsalesorders();
          this._cdr.detectChanges();
          this.cancelschAppointmentId = null;

        }
        else {
          this.customloading = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

  }
  showCancelAppointment(schAppointmentId) {
    this.cancelschAppointmentId = schAppointmentId;
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }

    this.displayCancelAppointment = true;
  }
  hideAppointmentpopup() {
    const form = this.formCancel;
    form.get(this.formFields.reason).setValue('');
    form.get(this.formFields.reasonComments).setValue('');
    form.reset();
    this.displayCancelAppointment = false;
  }

  onBTSelect(status: any) {
    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }

  get fVoid() { return (<FormGroup>this.form.get('formVoidAppointment')).controls; }

  get formVoidAppointment() {
    return this.form.get('formVoidAppointment');
  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const form = this.formVoidAppointment;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.voidReason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    return cancelRequestModel;
  }
  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }
  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.reasonsResponseModel = result.responseModel;
              const myClonedArray = [];
              this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
              this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
              this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Cancel');
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  formFields = {
    reason: 'reason',
    reasonComments: 'reasonComments',
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };

  validationMessages = {
    reason: {
      required: 'Cancel reason is required',
    },
    reasonComments: {
      required: 'Comment is required',
    },
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },


  };


  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formCancel: formGroup,
      formVoidAppointment: formVoidGroup
    });
  }

  cancelVoidAppointment() {
    const form = this.formVoidAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    this.displayVoidAppointment = false;
  }

  voidAppointment(voidFrom: string) {
    this.formVoidAppointmentSubmitted = true;
    if (this.formVoidAppointment.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.formCancelSubmitted = false;

    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromVoidForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.isRescheduledRequest ? this.voidschAppointmentId : this.voidSalesOrderId;
    cancelRequestModel.voidFrom = voidFrom;

    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.DeliveryTechnicianId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (cancelRequestModel.voidFrom === 'P') {

      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;

    this.customloading = true;
    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.VoidAppointment(cancelRequestModel) : this.appointmentService.VoidSalesOrder(cancelRequestModel);
    //this.appointmentService.CancelAppointment(cancelRequestModel)
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.customloading = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.showSuccessMessage("Appointment has been voided");
          const form = this.formVoidAppointment;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.voidReason).setValue('');
          form.get(this.formFields.voidReasonComments).setValue('');
          form.reset();
          this.getsalesorders();
          this.voidschAppointmentId = null; this.voidSalesOrderId = null;
          this.displayVoidAppointment = false;
          this._cdr.detectChanges();
          this.customloading = false;
        }
        else {
          this.customloading = false;
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in voiding your appointment.");
        }
      });

  }

  cancelschAppointmentId: number;
  voidSalesOrderId: number;
  voidschAppointmentId: number;
  isRescheduledRequest: boolean;
  displayCancelAppointment: boolean;
  displayVoidAppointment: boolean;

  showVoidAppointment(voidFrom, schAppointmentId) {
    this.isBrightreeSystemAppUpdateSetting = false;

    this.formVoidAppointmentSubmitted = false;
    this.voidFrom = voidFrom;
    this.voidschAppointmentId = schAppointmentId;

    if (voidFrom === 'P') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }

    this.isRescheduledRequest = true;
    this.displayVoidAppointment = true;
  }
  showVoidSalesOrder(voidFrom, crmSalesOrderId) {
    this.isBrightreeSystemAppUpdateSetting = false;
    this.formVoidAppointmentSubmitted = false;
    this.voidFrom = voidFrom;
    this.voidSalesOrderId = crmSalesOrderId;
    if (voidFrom === 'P') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }

    this.isRescheduledRequest = false;
    this.displayVoidAppointment = true;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }

  private async getUserPreferenceColumn() {
    this.loading = true;

    await this.appointmentService.getUserPreferenceMasterColumn(ModuleType.SearchResult, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.userPreferenceColumn = [];
            this.userPreferenceColumn = result.responseModel;
            this.userPreferenceColumn.forEach(x => {
              x.preferenceVisible = x.visible;
            })
            let IsAnyColVisible = this.userPreferenceColumn.filter(x => x.visible === true);
            if (IsAnyColVisible.length == 0) {
              this.isOnlyFixedColumnVisible = true;
            }
            else {
              this.isOnlyFixedColumnVisible = false;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }
  CancelNonSColumn(event, element) {
    element.hide(event);
    this.getUserPreferenceColumn();
  }
}
