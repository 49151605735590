import { TherapistInfoResponseModel } from '../therapist/therapistinfo-response-model';
import { FacilityInfoResponseModel } from '../facility/facilityinfo-response-model';

export class AppointmentDetailResponseModel {
  schAppointmentDetailId: number;
  schAppointmentId: number;
  crmFacilityId: number;
  crmTherapistId: number;
  crmNewTherapistId: number;
  schAppointmentWipstatusId: number;
  isActive: boolean
  modifiedByUserInfoId: number;
  schComments: string;
  crmPatientTimeZone: string;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  schAppointmentDateStartTime: Date;
  schAppointmentDateEndTime: Date;
  crmFacilityTimeZone: string;
  schSMSCommunicationType:string;
  schSMSCommunicationNumber:string;
  schCommunicationEmailAddress:string;
  therapistInfo: TherapistInfoResponseModel
  facilityInfo: FacilityInfoResponseModel
}
