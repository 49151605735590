<div class="application-outer permissions-outer">
  <div class="title-block">
    <h3>User Role & Permissions</h3>

    <!-- <a href="javascript:void(0);" (click)="ManageRoleRediection()" class="add-new-role"><i class="ic add-column"></i> Role</a> -->
    <a href="javascript:void(0);" pTooltip="Create User Role & Permission" tooltipPosition="left" class="add-plus"
      (click)="ManageRoleRediection()">
      <img src="assets/img/add-plus.svg" width="17" alt="filters">
    </a>
  </div>
  <div class="permissions-outer__tablet-outer">
    <p-table class="table-outer permissions-table-outer"
      [columns]="scrollableCols" 
      [value]="sales" 
      [scrollable]="true"
      scrollHeight="calc(100vh - 239px)" 
      dataKey="appModuleFeatureGroup"
      >

      <ng-template pTemplate="header" let-columns>
        <tr>
          <ng-container *ngFor="let col of columns">
            <th class="table-outer__th" width="350" *ngIf="col.field == 'appModuleFeatureName'" pFrozenColumn>
              <span>
                {{col.header}}
                <a href="javascript:void(0);" class="edit-new-role" (click)="selectRoles($event,col, editRole)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>

                <p-overlayPanel class="editRole" appendTo="body" #editRole>
                  <ul class="quick-link-menu">
                    <li>
                      <a href="javascript:void(0)" (click)="ManagePermission(selectedRole)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                          viewBox="0 0 103.901 98.574">
                          <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                            <path id="Path_3" data-name="Path 3"
                              d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                              transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                            <path id="Path_4" data-name="Path 4"
                              d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                              transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                          </g>
                        </svg>
                        Edit
                      </a>
                    </li>
                    <li *ngIf="selectedRole?.visible">
                      <a href="javascript:void(0)" (click)="onRoleDelete(selectedRole)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                          <g id="delete-icon-new" transform="translate(-3 -1)">
                            <path id="Path_1" data-name="Path 1"
                              d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                              fill="#6f64a7" />
                            <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                              fill="#6f64a7" />
                            <path id="Path_3" data-name="Path 3"
                              d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                              fill="#6f64a7" />
                            <path id="Path_4" data-name="Path 4"
                              d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                            <path id="Path_5" data-name="Path 5"
                              d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                            <path id="Path_6" data-name="Path 6"
                              d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                          </g>
                        </svg>
                        Delete
                      </a>
                    </li>
                  </ul>
                </p-overlayPanel>
              </span>
            </th>

            <th class="table-outer__th" *ngIf="col.field != 'appModuleFeatureName'">
              <span>
                {{col.header}}
                <a href="javascript:void(0);" class="edit-new-role" (click)="selectRoles($event,col, editRole)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>

                <p-overlayPanel class="editRole" appendTo="body" #editRole>
                  <ul class="quick-link-menu">
                    <li>
                      <a href="javascript:void(0)" class="edit-row" (click)="ManagePermission(selectedRole)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                          viewBox="0 0 103.901 98.574">
                          <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                            <path id="Path_3" data-name="Path 3"
                              d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                              transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                            <path id="Path_4" data-name="Path 4"
                              d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                              transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                          </g>
                        </svg>
                        Edit</a>
                    </li>
                    <li *ngIf="selectedRole?.visible">
                      <a href="javascript:void(0)" class="delete-row" (click)="onRoleDelete(selectedRole)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                          <g id="delete-icon-new" transform="translate(-3 -1)">
                            <path id="Path_1" data-name="Path 1"
                              d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                              fill="#6f64a7" />
                            <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                              fill="#6f64a7" />
                            <path id="Path_3" data-name="Path 3"
                              d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                              fill="#6f64a7" />
                            <path id="Path_4" data-name="Path 4"
                              d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                            <path id="Path_5" data-name="Path 5"
                              d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                            <path id="Path_6" data-name="Path 6"
                              d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                          </g>
                        </svg>
                        Delete</a>
                    </li>
                  </ul>
                </p-overlayPanel>
              </span>
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">

        <tr *ngIf="rowGroupMetadata[rowData.appModuleFeatureGroup].index === rowIndex">
          <td class="table-outer__td permission-title" width="350" pFrozenColumn>
            <h3>{{rowData.appModuleFeatureGroup}}</h3>
          </td>
          <ng-container *ngFor="let col of columns">
            <td class="table-outer__td permission-title" *ngIf="col.field!='appModuleFeatureName'"></td>
          </ng-container>
        </tr>

        <tr>
          <ng-container *ngFor="let col of columns">
            <td class="table-outer__td" width="350" *ngIf="col.field=='appModuleFeatureName'" pFrozenColumn>
              <span *ngIf="col.field=='appModuleFeatureName'" class="table-data">{{rowData[col.field]}}</span>
            </td>

            <td class="table-outer__td" *ngIf="col.field!='appModuleFeatureName'">
              <img *ngIf="col.field!='appModuleFeatureName' && rowData[col.field]" src="assets/img/tick-ic.svg" width="25" alt="check">
              <img *ngIf="col.field!='appModuleFeatureName' && !rowData[col.field]" src="assets/img/cross-ic-new1.svg" width="20" alt="cross">
            </td>

          </ng-container>

        </tr>

      </ng-template>

    </p-table>
  </div>
</div>