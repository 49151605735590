import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { SalesOrderAddSyncRequest } from 'src/app/shared/models/request/sync-sales-order-search-request-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { SyncSalesOrderStatus, SyncHistory } from 'src/app/shared/models/response/sync-sales-order-status';
import { WipStatusBTResponseModel } from 'src/app/shared/models/response/wip-statusbt-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ImportexportService } from '../../servicies/importexport.service';
import { UploadService } from '../../servicies/upload.service';
import { OverlayPanel } from 'primeng/overlaypanel';
@Component({
  selector: 'app-sync-rcm-salesorder',
  templateUrl: './sync-rcm-salesorder.component.html',
  styleUrls: ['./sync-rcm-salesorder.component.sass']
})
export class SyncRcmSalesorderComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'Sync Sales Order';
  Status: { name: string; code: string; }[];
  selectedWIPState: { name: string; code: string; };
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  disableSalesOrderImportButton: boolean;
  fromDate: Date = new Date();
  toDate: Date = new Date();
  appDateFormat: DateFormatModel;
  wipStatesResponseModel: WipStatusBTResponseModel[];
  selectedwipStatesResponseModel: WipStatusBTResponseModel;
  syncSalesOrderStatus: SyncSalesOrderStatus;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  formSyncSalesOrdersubmitted: boolean = false;
  loggedInUserId: number;
  syncHistory: SyncHistory[];
  newSOList: string;
  dupSOList: string;
  totalSOList: string;
  showHistory: boolean = false;
  error: any = { isError: false, errorMessage: '' };
  constructor(private importExportService: ImportexportService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitzer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }
  formFields = {

    //fromDate: 'fromDate',
    //Todate: 'Todate',
    wipState: 'wipState',
    branch: 'branch'
  };

  validationMessages = {
    wipState: {
      required: 'WIP State is required',
    },
    branch: {
      required: 'branch is required',
    },
    fromDate: {
      required: 'Created Start Date is required',
    },
    Todate: {
      required: 'Created End Date is required',
    },

  };


  async ngOnInit() {
    this.buildForm();
    await this.getWipStatus();
    await this.getBranches()
    this.fromDate = moment(moment().format('YYYY-MM-DD 00:00:00')).toDate();
    this.toDate = moment(moment().format('YYYY-MM-DD 23:59:59')).toDate();

    this.syncSalesOrderStatus = null;
  }
  //compareTwoDates() {

  //  if (new Date(this.fSyncSalesOrder[this.formFields.Todate].value) < new Date(this.fSyncSalesOrder[this.formFields.fromDate].value)) {
  //    this.error = {
  //      isError: true, errorMessage: 'Created start date should be less than created end date'
  //    };
  //  }

  //}
  get fSyncSalesOrder() { return (<FormGroup>this.form.get('formSyncSalesOrder')).controls; }

  get formSyncSalesOrder() {
    return this.form.get('formSyncSalesOrder');
  }

  private buildForm() {
    const formSyncSalesOrder = this.formBuilder.group({});
    // tslint:disable-next-line: max-line-length
    formSyncSalesOrder.addControl(this.formFields.wipState, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formSyncSalesOrder.addControl(this.formFields.branch, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    //formSyncSalesOrder.addControl(this.formFields.fromDate, this.formBuilder.control('', { validators: [Validators.required] }));
    //formSyncSalesOrder.addControl(this.formFields.Todate, this.formBuilder.control('', { validators: [Validators.required] }));
    // tslint:disable-next-line: max-line-length

    this.form = this.formBuilder.group({
      formSyncSalesOrder: formSyncSalesOrder
    });
  }
  async getWipStatus() {
    this.loading = true;
    this.importExportService.GetWipStatus()
      // this.importExportService.importSalesOrderFiles(this.salesorderFilePaths[0], this.salesorderFilePaths[1])
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          this.wipStatesResponseModel = result.responseModel;
          //if (this.wipStatesResponseModel.length > 0) {
          //  let sel = this.wipStatesResponseModel.find(x => x.wipStatusNameBt.toLowerCase() == 'scheduling - schedule rt');
          //  if (sel)
          //    this.selectedwipStatesResponseModel = sel;
          //}
        } else {
          this.wipStatesResponseModel = new Array<WipStatusBTResponseModel>();
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }


  async getBranches() {

    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.branchResponseModel = result.responseModel;
        //this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  SyncSalesOrders() {
    
    this.formSyncSalesOrdersubmitted = true;
    this.error = { isError: false, errorMessage: '' };
    if (this.formSyncSalesOrder.invalid) {
      this.markFormAsTouched();
      return;
    }
    //this.compareTwoDates();
    if (this.error.isError)
      return;

    this.formSyncSalesOrdersubmitted = false;


    this.disableSalesOrderImportButton = true;
    this.loading = true;
    const clientConifg = this.webStorageService.getClientConfig();
    let salesOrderSynceRequestModel: SalesOrderAddSyncRequest = new SalesOrderAddSyncRequest();
    salesOrderSynceRequestModel.WipState = this.selectedwipStatesResponseModel.wipStatusNameBt;
    salesOrderSynceRequestModel.CreateDateTimeStart = this.fromDate;
    salesOrderSynceRequestModel.CreateDateTimeEnd = this.toDate;
    salesOrderSynceRequestModel.BranchId = this.selectedbranch.crmBranchId.toString();
    salesOrderSynceRequestModel.BranchName = this.selectedbranch.crmBranchName;
    salesOrderSynceRequestModel.ClientId = Number(clientConifg.companyId);
    salesOrderSynceRequestModel.UserId = this.loggedInUserId;        
    this.importExportService.AddSyncRequest(salesOrderSynceRequestModel)
      //this.importExportService.synceRealTimeManualSalesOrder(salesOrderSynceRequestModel)
      // this.importExportService.synceRealTimeSalesOrder(salesOrderSynceRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Sync request submitted successfully');
        } else {
          this.disableSalesOrderImportButton = false;
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.disableSalesOrderImportButton = false;
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });

  }

  GetSyncHistory() {
    this.loading = true;
    this.importExportService.GetSyncHistory()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.syncHistory = result.responseModel;
          this.showHistory = true;
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loading = false;
  }

  showNewSalesOrder(event, overlaypanel: OverlayPanel, data: string) {
    this.newSOList = data;
    overlaypanel.toggle(event);
  }
  showDupSalesOrder(event, overlaypanel: OverlayPanel, data: string) {
    this.dupSOList = data;
    overlaypanel.toggle(event);
  }
  showTotalSalesOrder(event, overlaypanel: OverlayPanel, data: string) {
    this.totalSOList = data;
    overlaypanel.toggle(event);
  }

}
