import { Time } from "@angular/common";

export class BTSalesOrderUpdateRequestModel {
  CrmSalesOrderId: number = 0;
  CrmModifiedBy: number = 0;
  IsUpdateToBrightree: Boolean;
  IsUpdatePODStatusToBrightree: boolean = false;
  IsAppendOrderNoteToBrightree: boolean = false;
  IsDeliveryNoteOverride: Boolean;
  IsOrderNoteOverride: Boolean;
  DeliveryNote: string;
  OrderNote: string;
  WIPStateName: string;
  WIPStateKey: number;
  WIPDateNeeded: Date;
  WIPDateNeededString: string;
  WIPAssignedToKey: number;
  InvetoryLocationId: number;
  PractitionerName: string;
  PractitionerId: number = 0;
  DeliveryTechnicianId: number = 0;
  WIPAssignedToPerson: string = null;
  ScheduledDeliveryStartDate: Date;
  ScheduledDeliveryStartDateString: string;
  ScheduledDeliveryStartTimeString: string;
  ScheduledDeliveryStartTime: Time;
  IsMobileNumberUpdate: boolean;
  EmailAddress: string;
  PhoneNumber: string;
  crmPatientPhoneNumber: string;
  crmPatientMobileNumber: string;
  btNoteId: number;
  isProvisional: boolean;
}
