export class RoadShowTimeSlotResponseModel {
  appintmentDate: Date;
  startTime: Date;
  endTime: Date;
  origStartTime: Date;
  origEndTime: Date;
  therapistId: number;
  therapistFullName: string;
  appointmentId: number;
  salesOrderId: number;
  isAppointment: boolean;
  patientFullName: string;
  patientAddress1: string;
  patientCity: string;
  patientState: string;
  patientPostalCode: string;
  patientFullAddress: string;
  practitionerId: number;
  deliveryTechnicianId: number;
  isBlock: boolean;
  isBlock1: boolean = false;
  isBreak: boolean;
  slotTimeZone: string;
  crmPatientLat: string;
  crmPatientLong: string;
}

export class RoadShowTimeSlotTherapistModel {
  therapistId: number;
  therapistFullName: string;
  therapistLicenseStates: string;
  therapistDistance: number;
  therapistDistanceDisplayValue: string;
  appointmentCount: number;
  therapistAddressLat: string;
  therapistAddressLong: string;
  crmCoverageArea: number;
  therapistAddress: string;
  therapistDefaultWorkLocation: string;
  therapistFacilityName:string;
  timeSlotAppointmentInfo: RoadShowTimeSlotResponseModel[];
}
export class RoadShowTimeSlotsResponseModel {
  appintmentDate: Date;
  therapist: RoadShowTimeSlotTherapistModel[];
  isHoliday: boolean;
  holidayMessage: string;
}


export class RoadShowTimeSlotMainResponseModel {
  crmFacilityFirstAvailableStartDate: Date;
  crmFacilityFirstAvailableEndDate: Date;
  isRTLicenseCheck: boolean;
  isInsuranceStateFound: boolean;
  timeslots: RoadShowTimeSlotsResponseModel[];
}

export class GoogleMapDistanceMatrixResponse {
  destination_addresses: string[];
  origin_addresses: string[];
  rows: row[];
  status: string;
}

export class distance {
  text: string;
  value: string;
}
export class duration {
  text: string;
  value: string;
}
export class element {
  distance: distance;
  duration: duration;
  status: string;
}
export class row {
  elements: element[];
}

export class displayDistanceMatrixData {
  appointmentId: number;
  patientName: string;
  miles: string;
  time: string;
  googleTime: string;
  color: string;
  DiaplayText: string;
  isStartingLocation: boolean;
  duration:string;
}

export class PatientOriginData {
  appointmentId: number
  salesOrderId: number;
  patientLatLong: string;
  patientName: string;
  patientAddress: string;
  compareTime: any;
  isOnlyForMarker: boolean;
  isStartingLocation: boolean;
  isAfterLocation: boolean;
}