import { Injectable } from '@angular/core';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsGeneralRequestModel } from 'src/app/shared/models/request/settings/settings-general-request-model';
import { SettingsGeneralResponseModel } from 'src/app/shared/models/response/settings/settings-general-response-model';
import { BranchResponseModel, SelectedBranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { finalize, map, retry, takeUntil } from 'rxjs/operators';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { SettingsPluginResponseModel, RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsBrightreeResponseModel } from 'src/app/shared/models/response/settings/settings-brightree.response-model';
import { SettingsPluginServiceResponseModel } from 'src/app/shared/models/response/settings/settings-plugin-service-response-model';
import { SettingsBrightreeRequestModel } from 'src/app/shared/models/request/settings/setting-brightree-request-model';
import { SettingsGroupNotificationListResponseModel, SettingsNotificationResponseModel } from 'src/app/shared/models/response/settings/settings-notification-response-model';
import { SettingsNotificationRequestModel } from 'src/app/shared/models/request/settings/settings-notification-request-model';
import { SettingsPermissionResponseModel, RoleResponseModel, RoleFeatureResponseModel, ModuleFeatureResponseModel, RolePermissionResponseModel } from 'src/app/shared/models/response/settings/settings-permission-response-model';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { DateFormat, sendDateFormat } from 'src/app/shared/models/common/month-format';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { WipStatus, WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { SettingsClassificationTypeResponseModel, CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';
import { KPIReportResponseModel, RoleFeatureModelPermission, RoleFeatureModulePermissionResponseModel } from 'src/app/shared/models/response/role-feature-modeul-permission';
import { SupportRequestModel } from 'src/app/shared/models/request/support-request-model';
import { ReminderTypeModel, ReminderSettingModel } from 'src/app/shared/models/response/settings/settings-reminder-model'
import { HolidayResponse } from 'src/app/shared/models/response/appointment/available-slots';
import { RemoteAppointmentTypeModel, OnlineAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { Constants } from 'src/app/shared/constants';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { InvetoryLocationResponseModel } from '../../shared/models/response/invetory-location-response-model';
import { PatientNoteConfigurationBrightree } from 'src/app/shared/models/response/patientNote-configuration-brightree';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { BrightreeSettingsResponseModel } from 'src/app/shared/models/response/brightree-settings-response-model';
@Injectable({
  providedIn: 'root'
})
export class SettingsService extends BaseService {
  sendDateFormat: sendDateFormat;
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getAllBranchNumbers() {
    return this.get<ResultOfT<BranchNumbersResponseModel>>('Settings/GetAllBranchNumbers');
  }

  updateAllBranchNumbers(branchNumbersResponseModel: Array<BranchNumbersResponseModel>) {
    return this.post<ResultOfT<BranchNumbersResponseModel[]>>('Settings/UpdateAllBranchNumbers', branchNumbersResponseModel);
  }

  getPluginServices() {
    return this.get<ResultOfT<SettingsPluginServiceResponseModel>>('Settings/GetServices');
  }

  getAllNotifications() {
    return this.get<ResultOfT<Array<SettingsNotificationResponseModel>>>('Notification/GetAllNotifications');
  }

  getAllNotificationsByRole(roleId: number) {
    return this.get<ResultOfT<Array<SettingsNotificationResponseModel>>>('Notification/GetAllNotificationsByRoleId/' + roleId);
  }

  getAllGroupNotificationsByRole(roleId: number) {
    return this.get<ResultOfT<Array<SettingsGroupNotificationListResponseModel>>>('Notification/GetAllGroupNotificationsByRoleId/' + roleId);
  }

  getSettings(settingsKey: string) {

    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey);
  }
  getSettingsByKey(settingsKey: string) {
   
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey);
  }

  getSettingsByKeyRefresh(settingsKey: string) {
   
    return this.getRefresh<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey + '&rnd=' + Math.random().toString());
  }

  getDateFormatFromSetting(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetDateFormat?SettingKey=' + settingsKey);
  }

  getAllSettings() {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettings');
  }
  getAllSettingsReftresh() {
    return this.getRefresh<ResultOfT<SettingsResponseModel>>('Settings/GetSettings');
  }

  updateSettings(lstSettingsRequestModel: Array<SettingsRequestModel>) {
    return this.post<ResultOfT<SettingsResponseModel>>('Settings/Update', lstSettingsRequestModel);
  }

  getGeneralSettings(companyId: string) {
    return this.get<ResultOfT<SettingsGeneralResponseModel>>('Company/GetCompany/' + companyId);
  }

  updateGeneralSettings(settingsGeneralResponseModel: SettingsGeneralRequestModel) {
    return this.post<ResultOfT<SettingsGeneralRequestModel>>('Company/UpdateCompany', settingsGeneralResponseModel);
  }

  getPluginConfiguration(appPluginId: string) {
    return this.getRefresh<ResultOfT<SettingsPluginResponseModel>>('RCMProvider/GetPluginConfiguration/' + appPluginId);
  }

  GetRCMConfigurationUrl(appPluginId: string) {
    return this.get<ResultOfT<RCMCofigurationUrl>>('RCMProvider/GetRCMConfigurationUrl/' + appPluginId);
  }

  updatePluginConfiguration(settingsPluginRequestModel: SettingsPluginRequestModel) {
    return this.post<ResultOfT<SettingsPluginRequestModel>>('RCMProvider/UpdatePluginConfiguration', settingsPluginRequestModel);
  }

  getBrightreeSettings(settingKey: string) {
    return this.get<ResultOfT<SettingsBrightreeResponseModel>>('Settings/GetBrightreeSettings/' + settingKey);
  }
  GetClassificationTypes() {
    return this.get<ResultOfT<SettingsClassificationTypeResponseModel[]>>('Settings/GetClassificationTypes');
  }
  GetAllWipStatus() {
    return this.get<ResultOfT<WipStatus[]>>('Settings/GetAllWipStatus');
  }

  updateBrightreeSettings(settingsBrightreeRequestModel: SettingsBrightreeRequestModel) {
    return this.post<ResultOfT<SettingsBrightreeRequestModel>>('Settings/UpdateBrightreeSetting', settingsBrightreeRequestModel);
  }

  activeInactiveBrightreeSettings(settingsBrightreeRequestModel: SettingsBrightreeRequestModel) {
    return this.post<ResultOfT<SettingsBrightreeRequestModel>>('Settings/ActiveInactiveBrightreeSetting', settingsBrightreeRequestModel);
  }

  UpdateNotificationSettings(settingsNotificationRequestModel: Array<SettingsNotificationRequestModel>) {
    return this.post<ResultOfT<SettingsNotificationRequestModel>>('Notification/UpdateNotifications', settingsNotificationRequestModel);
  }

  UpdateNotificationIndividual(settingsNotificationRequestModel: SettingsNotificationRequestModel) {
    return this.post<ResultOfT<SettingsNotificationRequestModel>>('Notification/UpdateNotification', settingsNotificationRequestModel);
  }

  getRoles() {
    return this.get<ResultOfT<Array<RoleResponseModel>>>('Roles/GetRoles');
  }

  getAllPermissions() {
    return this.get<ResultOfT<Array<SettingsPermissionResponseModel>>>('FeatureGroup/GetModuleFeatureGroups');
  }

  setPermisssion(settingsPermissionResponseModel: Array<SettingsPermissionResponseModel>) {
    return this.post<ResultOfT<RoleFeatureResponseModel>>('Settings/SetPermission', settingsPermissionResponseModel);
  }

  getCallStatus(callSid: string) {
    return this.get<ResultOfT<boolean>>('Notification/GetCallStatus/' + callSid);
  }

  getCallConfig() {
    return this.get<ResultOfT<CallConfigModel>>('Notification/GetCallConfig');
  }

  getNotificationFields() {
    return this.get<ResultOfT<EnumModel[]>>('Notification/GetNotificationFields');
  }

  sendTestSMS(testNumber: string) {
    return this.get<ResultOfT<boolean>>('Notification/SendTestMessage/' + testNumber);
  }

  getDateFormat() {
    return this.get<ResultOfT<Array<DateFormat>>>('Settings/GetMonths' + '?rnd=' + Math.random().toString());
  }

  addNewRole(newRoleResponseModel: RoleResponseModel) {
    return this.post<ResultOfT<RoleResponseModel>>('Roles/Save', newRoleResponseModel);
  }

  getPrimeNgDate(dtFormat: string) {
    this.sendDateFormat = new sendDateFormat();
    this.sendDateFormat.dtFormats = dtFormat;
    return this.post<ResultOfT<string>>('Settings/GetPrimeNgFormat', this.sendDateFormat);
  }

  getWipStatusSA() {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Settings/GetWipStatusSA');
  }

  getWipStatusBT() {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Settings/GetWipStatusBT');
  }

  getAssignedToPerson() {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Settings/GetAssignedToPersopn');
  }

  getClassificationTypeBT() {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Settings/GetClassificationTypeBT');
  }

  addNewWipStatus(newWipStatusResponseModel: WipStatus) {
    return this.post<ResultOfT<WipStatus>>('Settings/SaveWipStatus', newWipStatusResponseModel);
  }
  AddReminderSetting(reminderSettingModel: ReminderSettingModel) {
    return this.post<ResultOfT<boolean>>('Settings/AddReminderSetting', reminderSettingModel);
  }
  UpdateReminderSetting(reminderSettingModel: ReminderSettingModel) {
    return this.post<ResultOfT<boolean>>('Settings/UpdateReminderSetting', reminderSettingModel);
  }

  activeInactiveReminder(reminderSettingModel: ReminderSettingModel) {
    return this.post<ResultOfT<boolean>>('Settings/ActiveInactiveReminders', reminderSettingModel);
  }
  getReminderSettings() {
    return this.get<ResultOfT<ReminderSettingModel[]>>('Settings/GetReminderSettings');
  }
  getReminderFrequency() {
    return this.get<ResultOfT<EnumModel[]>>('Settings/GetReminderFrequency');
  }

  getReminderType() {
    return this.get<ResultOfT<ReminderTypeModel[]>>('Settings/GetReminderType');
  }
  updateWipStatus(updateWipStatusResponseModel: WipStatus) {
    return this.post<ResultOfT<WipStatus>>('Settings/UpdateWipStatus', updateWipStatusResponseModel);
  }


  getCancelVoidReasons() {
    return this.get<ResultOfT<CancelVoidResponseModel[]>>('Settings/GetCancelVoidReasons');
  }

  getWipStatusBtmapping() {
    return this.get<ResultOfT<WipStatusBTmapping[]>>('Appointment/GetWipStateBTmapping');
  }

  addNewCancelVoidReasons(newSalesOrderVoidReasonResponseModel: CancelVoidResponseModel) {
    return this.post<ResultOfT<CancelVoidResponseModel>>('Settings/SaveCancelVoidReasons', newSalesOrderVoidReasonResponseModel);
  }
  updateCancelVoidReasons(salesOrderVoidReasonResponseModel: CancelVoidResponseModel) {
    return this.post<ResultOfT<CancelVoidResponseModel>>('Settings/UpdateCancelVoidReasons', salesOrderVoidReasonResponseModel);
  }
  activeInactiveReasons(salesOrderVoidReasonResponseModel: CancelVoidResponseModel) {

    return this.post<ResultOfT<CancelVoidResponseModel>>('Settings/ActiveInactiveCancelVoidReasons', salesOrderVoidReasonResponseModel);
  }


  activeInactiveWipStatus(updateWipStatusResponseModel: WipStatus) {
    return this.post<ResultOfT<WipStatus>>('Settings/ActiveInactiveWipStatus', updateWipStatusResponseModel);
  }

  addNewClassificationType(newWipStatusResponseModel: SettingsClassificationTypeResponseModel) {
    return this.post<ResultOfT<SettingsClassificationTypeResponseModel>>('Settings/SaveClassificationType', newWipStatusResponseModel);
  }

  updateClassificationType(updateWipStatusResponseModel: SettingsClassificationTypeResponseModel) {
    return this.post<ResultOfT<SettingsClassificationTypeResponseModel>>('Settings/UpdateClassificationType', updateWipStatusResponseModel);
  }

  activeInactiveClassificationType(updateWipStatusResponseModel: SettingsClassificationTypeResponseModel) {
    // tslint:disable-next-line: max-line-length
    return this.post<ResultOfT<SettingsClassificationTypeResponseModel>>('Settings/ActiveInactiveClassificationType', updateWipStatusResponseModel);
  }

  unlockAppointments() {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('AppointmentCalls/ReleaseCallListAfterADay');
  }

  getPermissionByRoleId(roleId: string) {
    return this.get<ResultOfT<ModuleFeatureResponseModel[]>>('Roles/GetPermission/' + roleId);
  }

  getRole(roleId: string) {
    return this.get<ResultOfT<RoleResponseModel>>('Roles/GetRole/' + roleId);
  }

  setRoleAndPermission(rolePermisions: RolePermissionResponseModel) {
    return this.post<ResultOfT<RoleFeatureResponseModel>>('Settings/SetRoleAndPermission', rolePermisions);
  }

  GetRoleFeatureModulePermissions() {
    return this.get<ResultOfT<RoleFeatureModelPermission[]>>('Settings/GetRoleFeatureModulePermissions');
  }

  GetRoleFeatureModulePermissionManagement(roleId: number) {
    return this.get<ResultOfT<RoleFeatureModelPermission>>('Settings/GetRoleFeatureModulePermissionManagement/' + roleId);
  }

  AddUpdateRoleAndPermission(roleFeatureModeulPermissionGroupResponseModel: RoleFeatureModelPermission) {
    return this.post<ResultOfT<RoleFeatureResponseModel>>('Settings/AddUpdateRoleAndPermission', roleFeatureModeulPermissionGroupResponseModel);
  }

  ActiveInaciveRoles(roleId: string, status: boolean) {
    return this.get<ResultOfT<RoleResponseModel>>('Roles/ActiveInactive/' + roleId + '/' + status);
  }
  submitSupportRequest(supportRequestModel: SupportRequestModel) {
    return this.post<ResultOfT<SupportRequestModel>>('Users/SendSupportRequest', supportRequestModel);
  }

  getCompanyLogo(companyId: string) {
    return this.get<ResultOfT<string>>('Company/GetCompanyLogo/' + companyId);
  }

  getHolidayList() {
    return this.get<ResultOfT<HolidayResponse[]>>('Settings/GetHolidayList');
  }
  getActiveRemoteAppointmentType() {
    return this.get<ResultOfT<RemoteAppointmentTypeModel[]>>('Settings/GetActiveRemoteAppointmentType');
  }
  getRemoteAppointmentTypes() {
    return this.get<ResultOfT<RemoteAppointmentTypeModel[]>>('Settings/GetRemoteAppointmentTypes');
  }

  updatRemoteAppointmentType(remoteAppointmentTypeModel: RemoteAppointmentTypeModel) {
    return this.post<ResultOfT<boolean>>('Settings/UpdatRemoteAppointmentType', remoteAppointmentTypeModel);
  }

  updatRemoteAppointmentTypes(remoteAppointmentTypeModel: RemoteAppointmentTypeModel[]) {
    return this.post<ResultOfT<boolean>>('Settings/UpdatRemoteAppointmentTypes', remoteAppointmentTypeModel);
  }

  getOnlineAppointmentTypes() {
    return this.get<ResultOfT<OnlineAppointmentTypeModel[]>>('Settings/GetOnlineAppointmentTypes');
  }
  updatOnlineAppointmentTypes(remoteAppointmentTypeModel: OnlineAppointmentTypeModel[]) {
    return this.post<ResultOfT<boolean>>('Settings/UpdatOnlineAppointmentTypes', remoteAppointmentTypeModel);
  }

  GetCompanyIconRefresh(companyId: string) {
    return this.get<ResultOfT<SettingsGeneralResponseModel>>('Company/GetCompany/' + companyId);
    //return this.get<ResultOfT<any>>('Company/GetCompanyIconRefresh/' + companyId);
  }



  GetBranchList() {
    return this.get<ResultOfT<BranchResponseModel[]>>('Settings/GetBranchList');
  }

  getPagedBranchList(searchRequestModel: SearchRequestModel, isRefresh: boolean) {
    var url = 'Settings/GetPagedBranchList';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }
    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<BranchResponseModel>>>(url);

   
  }

  addNewBranch(newBranchResponseModel: BranchResponseModel) {
    return this.post<ResultOfT<BranchResponseModel>>('Settings/AddBranchDetail', newBranchResponseModel);
  }

  updateBranch(updateBranchResponseModel: BranchResponseModel) {
    return this.post<ResultOfT<BranchResponseModel>>('Settings/UpdateBranchDetail', updateBranchResponseModel);
  }

  UpdateBranchSelfScheduleAppointmentsStatus(updateBranchResponseModel: BranchResponseModel) {
    return this.post<ResultOfT<boolean>>('Settings/UpdateBranchSelfScheduleAppointmentsStatus', updateBranchResponseModel);
  }

  UpdateSelectedBranchSelfScheduleAppointmentsStatus(selectedBranchResponseModel: SelectedBranchResponseModel) {
    return this.post<ResultOfT<boolean>>('Settings/UpdateSelectedBranchSelfScheduleAppointmentsStatus', selectedBranchResponseModel);
  }

  deleteBranchDetail(updateBranchResponseModel: BranchResponseModel) {
    return this.post<ResultOfT<BranchResponseModel>>('Settings/DeleteBranchDetail', updateBranchResponseModel);
  }

  getInvetoryLocation() {
    return this.get<ResultOfT<InvetoryLocationResponseModel[]>>('Settings/GetInvetoryLocation');
  }

  getSettingInit(settingsList: { key: string, value: string }[]) {
    var url = 'Settings/GetAllBookApointmentInit?';
    for (let settings of settingsList) {
      url = url + '&' + settings.key + '=' + settings.value;
    }
    return this.get<ResultOfT<any>>(url);
  }

  GetAllSettingsBookAppointmentOnInit() {
    var url = 'Settings/GetAllSettingsBookAppointmentOnInit';
    return this.get<ResultOfT<any>>(url);
  }
  GetAllSettingsCalenderOnInit() {
    var url = 'Settings/GetAllSettingsCalenderOnInit';
    return this.get<ResultOfT<any>>(url);
  }


  UpdateClassificationBlackOutStatus(model: SettingsClassificationTypeResponseModel) {
    return this.post<ResultOfT<boolean>>('Settings/UpdateClassificationBlackOutStatus', model);
  }

  getPatientNoteConfig(){
    return this.get<ResultOfT<PatientNoteConfigurationBrightree[]>>('Settings/GetPatientNoteConfig');  
  }

  updatePatientNoteConfig(lstpatientNoteModel: PatientNoteConfigurationBrightree[]) {
    return this.post<ResultOfT<boolean>>('Settings/UpdatePatientNoteConfig', lstpatientNoteModel);
  }

  SendVoiceMailToNumber(voiceCallToNumber: string, userId: number) {
    return this.get<ResultOfT<boolean>>('appointmentcalls/SendTestVoiceMailToNumber?phoneNumber=' + voiceCallToNumber + '&userId=' + userId)
  }

  getBrightreeSetings() {
    return this.get<ResultOfT<BrightreeSettingsResponseModel>>('Settings/GetBrightreeSetings');
  }
 
  getAllDMEUsers() {
    return this.get<ResultOfT<UserResponseModel[]>>('Users/GetAllDMEUsers');
  }

  GetBranchById(branchId: number) {
    return this.get<ResultOfT<BranchResponseModel>>('Settings/GetBranchById/' + branchId);
  }

  GetKPIReportDetail() {
    return this.get<ResultOfT<KPIReportResponseModel[]>>('KPIReport/GetKPIReportMasterDetail');
  }
}
