import { UserRoleRequestModel } from './user-role-request-model';
import { UserBranchRequestModel } from './user-branch-request-model';
import { TherapistLicenseRequestModel } from './therapist/therapist-license-request-model';
import { TherapistCertificationRequestModel } from './therapist/therapist-certification-request-model';
import { TherapistDefaultFAcilityRequestModel } from './therapist/therapist-default-facility-request-model';
import { FacilityRequestModel } from './facility/facility-request-model';

export class UserInfoRequestModel {
  appUserInfoId: number;
  appUserEmail: string;
  appUserFname: string;
  appUserLname: string;
  appUserPhoneNumber: string;
  appUserpassword: string;
  appUserIsReset: boolean;
  appUserStatus: boolean;
  appUserPasswordExpiry: Date;
  dateTimeUserLastLogin: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;

  userRole: UserRoleRequestModel;
  userBranch: UserBranchRequestModel;
  therapistLicenseInfo: TherapistLicenseRequestModel[];
  therapistCertification: TherapistCertificationRequestModel[];
  therapistDefaultFacility: TherapistDefaultFAcilityRequestModel;
  facilityInfo: FacilityRequestModel;


}
