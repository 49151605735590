import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { FeatureGroupService  } from '../../shared/services/seetings.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { Router } from '@angular/router';

declare var require: any;
@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  hashVersion: string = require('../../../../package.json').hashVersion;
  private unsubscriber = new Subject<boolean>();
  loading: boolean;
  constructor(private http: HttpClient, private settingsService: FeatureGroupService, private webStorageService: WebStorageService, private router: Router) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(frequency = 1000 * 60 * 5) {
    setInterval(() => {
      this.checkVersion();
    }, frequency);
  }
  private checkVersion() {
    console.log("satrt hashVersion");
    console.log(this.hashVersion);
    console.log("End hashVersion");

    let islocalStorage = true;

    if (this.router.url != null && this.router.url != undefined) {
      var getRoute = this.router.url.split("/")[1];
      if (getRoute != null && getRoute != undefined) {
        if (getRoute.toLowerCase() == "patient") {
          islocalStorage = false;
        }
      }
    }

    if (islocalStorage) {

      const appHashVersion = this.webStorageService.getAppHashVersionValue();

      if (appHashVersion != null && appHashVersion != undefined) {
        console.log("satrt appHashVersion");
        console.log(appHashVersion);
        console.log("End appHashVersion");

        if (appHashVersion !== this.hashVersion) {
          console.log("start localStorage");
          localStorage.clear();
          console.log("end localStorage");
          // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
          // for an example: location.reload();
          window.location.reload();
        }
      }
      else {
        console.log("First Time");
        localStorage.clear();
        window.location.reload();
      }
    }
  }
}
