import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PatientRoutingModule } from './patient-routing.module';
import { LandingComponent } from './landing/landing.component';
import { SearchNearbyFacilityComponent } from './search-nearby-facility/search-nearby-facility.component';
import { ConfirmDateTimeSlotComponent } from './confirm-date-time-slot/confirm-date-time-slot.component';
import { AppointmentConfirmationComponent } from './appointment-confirmation/appointment-confirmation.component';
import { InitiateShippingComponent } from './initiate-shipping/initiate-shipping.component';
import { TeleHealthBookingComponent } from './tele-health-booking/tele-health-booking.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TabViewModule } from 'primeng/tabview';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CalendarModule as inlineCalendar } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { AgmCoreModule } from '@agm/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';

import { ChooseTherapistComponent } from './choose-therapist/choose-therapist.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import { StepsComponent } from './steps/steps.component';
import {SidebarModule} from 'primeng/sidebar';
import { AppointmentSummaryComponent } from './appointment-summary/appointment-summary.component';
import { RescheduleAppointmentComponent } from './reschedule-appointment/reschedule-appointment.component';
import {ProgressBarModule} from 'primeng/progressbar';
import { CancelledComponent } from './cancelled/cancelled.component';
import { PatientAppointmentBookingComponent } from './patient-appointment-booking/patient-appointment-booking.component';

export const MyDefaultTooltipOptions: TooltipOptions = {
  'placement': 'right',
  'show-delay': 0,
  'hideDelayTouchscreen': 0,
  'displayTouchscreen': true,
  'hideDelayAfterClick': 0
}
@NgModule({
  declarations: [LandingComponent, SearchNearbyFacilityComponent, ConfirmDateTimeSlotComponent, AppointmentConfirmationComponent, InitiateShippingComponent, TeleHealthBookingComponent, ChooseTherapistComponent, StepsComponent, AppointmentSummaryComponent, RescheduleAppointmentComponent, CancelledComponent, PatientAppointmentBookingComponent],
  imports: [
    CommonModule,
    PatientRoutingModule,
    CheckboxModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TabViewModule,
    MultiSelectModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    inlineCalendar,
    ToastModule,
    NgAddToCalendarModule,
    ProgressSpinnerModule,
    SidebarModule,
    ProgressBarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBZl46zpvEJzJmwq_bmV4GpJYXP2JwrgH4',
      libraries: ['places']
    }),
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),

    NgxDaterangepickerMd.forRoot({
      applyLabel: 'Okay',
      firstDay: 3,
    }),    
  ],
  exports: [
    CheckboxModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    TabViewModule,
    MultiSelectModule,
    ButtonModule,
    DialogModule,
    TooltipModule,
    RadioButtonModule,
    InputSwitchModule,
    InputTextareaModule,
    inlineCalendar,
    ToastModule,
    SidebarModule,
    ProgressBarModule,
  ]

})
export class PatientModule { }
