<div class="brightree-settings-outer">
  <p-tabView class="appointments-tab-outer setting-tab-menu brightree-setting" #tabview
    (onChange)="handleChange($event)" dynamic="true" cache="true" [(activeIndex)]="index">
    <div class="title-block">
      <h1>{{title}}</h1>
      <div class="title-block__right">
        <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/settings']"><i
            class="back-new"></i>Back</a>
      </div>

    </div>

    <p-tabPanel header="Branch" #panel1 dynamic="true">

      <ng-template pTemplate="content">
        <app-branch *ngIf="index === 0"></app-branch>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel class="status-mapping-view" header="Status Mapping" #panel2 dynamic="true">
      <ng-template pTemplate="content">
        <div class="brightree-outer" *ngIf="index === 1">
          <div class="status-mapping">
            <div class="add-buttons-for-license" *ngIf="!isProvisional">
              <h3>Status Mapping</h3>
              <div class="title-block__right">
                <a href="javascript:void(0);" pTooltip="Add Status Mapping" tooltipPosition="left" class="add-plus"
                  (click)="StatusInfoButton()">
                  <img src="assets/img/add-plus.svg" width="17" alt="filters">
                </a>
              </div>
            </div>


            <div class="table-outer-main branchs-settings status-mapping">
              <p-table #nsdl class="table-outer" [value]="wipStatus" [scrollable]="true"
                scrollHeight="calc(100vh - 297px)" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                  <tr>
                    <th pFrozenColumn class="table-outer__th">Appoint360 Status</th>
                    <th class="table-outer__th">Brightree Status</th>
                    <th class="table-outer__th">Display Name</th>
                    <th class="table-outer__th">WIP Assigned To</th>
                    <th class="table-outer__th">Update to Brightree</th>
                    <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="80"
                      *ngIf="!isProvisional">Action</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-wipStatus>
                  <!-- *ngFor="let wipStatus of wipStatus" -->
                  <tr>
                    <td pFrozenColumn class="table-outer__td">{{wipStatus.crmWipstatusNameSa}}</td>
                    <td class="table-outer__td">{{wipStatus.crmWipstatusNameBt}}</td>
                    <td class="table-outer__td">{{wipStatus.appAlias}}</td>
                    <td class="table-outer__td">{{wipStatus.crmWIPAssignedToPerson}}</td>
                    <td class="table-outer__td">{{wipStatus.crmIsUpdateToBrigthree ? 'Yes' : 'No'}}</td>
                    <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column" width="80">

                      <div class="action-column" *ngIf="!isProvisional">
                        <a class="actionmenu-btn" href="javascript:void(0)" (click)="op.toggle($event)">
                          <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                          </svg>
                        </a>
                      </div>

                      <p-overlayPanel class="table-action-popup" appendTo="body" #op>
                        <ul class="quick-link-menu">
                          <li>
                            <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                              (click)="onStatusEdit(wipStatus)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                                viewBox="0 0 103.901 98.574">
                                <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                                  <path id="Path_3" data-name="Path 3"
                                    d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                                    transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="8" />
                                  <path id="Path_4" data-name="Path 4"
                                    d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                                    transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="8" />
                                </g>
                              </svg>
                              Edit</a>
                          </li>
                          <li>
                            <a class="delete-row" href="javascript:void(0)" (click)="onStatusDelete(wipStatus)">
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                                <g id="delete-icon-new" transform="translate(-3 -1)">
                                  <path id="Path_1" data-name="Path 1"
                                    d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                                    fill="#6f64a7" />
                                  <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                                    fill="#6f64a7" />
                                  <path id="Path_3" data-name="Path 3"
                                    d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                                    fill="#6f64a7" />
                                  <path id="Path_4" data-name="Path 4"
                                    d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                                  <path id="Path_5" data-name="Path 5"
                                    d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                                  <path id="Path_6" data-name="Path 6"
                                    d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                                </g>
                              </svg>
                              Delete</a>
                          </li>
                        </ul>
                      </p-overlayPanel>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </ng-template>
    </p-tabPanel>


    <p-tabPanel header="Classification" #panel3 dynamic="true">

      <ng-template pTemplate="content">
        <app-classification *ngIf="index === 2"></app-classification>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel header="Cancel/Void Reasons" #panel4 dynamic="true">
      <ng-template pTemplate="content">
        <app-cancel-void-reasons *ngIf="index === 3"></app-cancel-void-reasons>
      </ng-template>
    </p-tabPanel>

  </p-tabView>
</div>

<p-dialog [(visible)]="displayWipStats" styleClass="display-close-icon" [modal]="true" [responsive]="true"
  appendTo="body">
  <div class="popup-content-outer">
    <h2>{{statusBtnTitle}} WIP Status</h2>

    <form class="form form--wipstatus" [formGroup]="formStatus" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Appoint360 Status</label>
          <p-dropdown class="select-box" formControlName="eMedAssistStatus" placeholder="Select Appoint360 status"
            #eMedAssistDropdown [(ngModel)]="selectedEMedAssistStatus" [options]="eMedAssistStatusOptions"
            optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formStatusSubmitted && fStatus.eMedAssistStatus.errors" class="invalid-feedback">
            <div *ngIf="fStatus.eMedAssistStatus.errors.required || fStatus.eMedAssistStatus.errors">
              {{validationMessages.eMedAssistStatus.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Brightree Status</label>
          <p-dropdown class="select-box" formControlName="brightreeStatus" placeholder="Select brightree status"
            #brightreeDropdown [(ngModel)]="selectedBrightreeStatus" [options]="brightreeStatusOptions"
            optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formStatusSubmitted && fStatus.brightreeStatus.errors" class="invalid-feedback">
            <div *ngIf="fStatus.brightreeStatus.errors.required || fStatus.brightreeStatus.errors">
              {{validationMessages.brightreeStatus.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Display Name</label>
          <input type="text" formControlName="displayName" class="form__control" value="Ready for Schedule" pInputText>
          <div *ngIf="formStatusSubmitted && fStatus.displayName.errors" class="invalid-feedback">
            <div *ngIf="fStatus.displayName.errors.required">
              {{validationMessages.displayName.required}}
            </div>
            <div *ngIf="fStatus.displayName.errors.validCharacters">
              {{validationMessages.displayName.validCharacters}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">WIP Assigned To</label>
          <p-dropdown class="select-box" placeholder="Select WIP Assigned To" formControlName="wipAssignedToKey"
            [(ngModel)]="selectedWipAssignedTo" [options]="wipAssignedToOptions" [showClear]="true"
            optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formStatusSubmitted && fStatus.wipAssignedToKey.errors" class="invalid-feedback">
            <div *ngIf="fStatus.wipAssignedToKey.errors.required || fStatus.wipAssignedToKey.errors">
              {{validationMessages.wipAssignedToKey.required}}
            </div>
          </div>
        </div>
        <div class="form__group" *ngIf="isDisplayPODStatus">
          <label class="form__label">POD Status</label>
          <p-dropdown class="select-box" placeholder="Select POD status" formControlName="podStatus"
                      [(ngModel)]="SelectedPODOption" [options]="PODOptions" [showClear]="true" optionLabel="name">
          </p-dropdown>
          <!-- <div *ngIf="formStatusSubmitted && fStatus.wipAssignedToKey.errors" class="invalid-feedback">
            <div *ngIf="fStatus.wipAssignedToKey.errors.required || fStatus.wipAssignedToKey.errors">
              {{validationMessages.wipAssignedToKey.required}}
            </div>
          </div> -->
        </div>
        <div class="form__group status-checkbox">
          <label class="form__label"></label>
          <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="isUpdateToBT"
            label="Update to Brightree" (onChange)="onUpdateToBTCheck($event)">
          </p-checkbox>
        </div>
        <div class="form__group">
        </div>
        <div class="form__group button-box">
          <div class="btns">
            <button class="btn btn--primary" (click)="onStatusAdd()">{{statusBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onStatusCancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
