import { BranchResponseModel } from './branch-response-model';

export class BranchNumbersResponseModel {

  branchNumbersId: number;
  branchId: number;
  dmephoneNumber: string;
  dmeemail: string;
  callPhoneNumber: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;

  branch: BranchResponseModel;
}
