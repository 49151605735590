export class AppointmentConfirmationInfo {
  facilityId: number;
  crmSalesOrderId: number;
  schAppointmentId: number;
  therapistId: number;
  crmPractionerId: number;
  crmDeliveryTechnicianId: number;
  isAppoinment: boolean;
  isRescheduledRequest: boolean;
  isAppoinmentBookByCalendar: boolean;
  selectedAppointmentTypePopUp: string;
  schRemoteAppointmentType: string;
  schRemoteAppointmentTypeDisplayName: string;
  appointmentStartDate: Date;
  appointmentEndDate: Date;
  appointmentOrigStartDate: Date;
  appointmentOrigEndDate: Date;
  appointmentTimeZone: string;
  appointmentTimeZoneCode: string;
  appointmentTimeZoneShortName: string;
  duration: string;
  description: string;
  facilityName: string;
  facilityAddress: string;
  distance: string;
  dueAmount: number;
  durationInMinutes: number;
  crmPatientname: string;
  crmPatientaddress: string;
  appointmentType: string;
  crmTherapistname: string;
  crmRediretFrom: string;
  OrderNote: string;
  DeliveryNote: string;
  isBillingAddress: boolean;
  crmPatientDeliveryAddress: string;
  isDefaultWIPNeededDateAppointmentdate: boolean;
  crmWIPStatusNameBT: string;
  crmWIPAssignedToKey: number;
  WIPStateAssignmentType: string;
  UpdateWipStateOnRescheduled: boolean;
  schSMSCommunicationType:string;
  schSMSCommunicationNumber:string;
  schCommunicationEmailAddress: string;
  schReminderOpted: boolean;
  schIsReminderOptedSms: boolean;
  schIsReminderOptedEmail: boolean;
}
