import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { Constants } from 'src/app/shared/constants';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
@Component({
  selector: 'app-tele-health-booking',
  templateUrl: './tele-health-booking.component.html',
  styleUrls: ['./tele-health-booking.component.sass']
})
export class TeleHealthBookingComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  salesOrderId: string;
  salesOrderDescription: string;
  DMENumber: string;
  domainUrl: string;
  strURL: string;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  configResponseModel: ConfigResponseModel;
  salesORderFromLocal: SalesOrderLocalModel;
  constructor(private router: Router,
    messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private appointmentService: AppointmentService,
    private webStorageService: WebStorageService,
    private sanitizer: DomSanitizer
  ) {
    super(messageService);
 
    this.configResponseModel = new ConfigResponseModel();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = Constants.imagePrefix + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;
    }
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      this.salesOrderDescription = currentNavigationState.crmClassificationDescription;
      this.salesOrderId = currentNavigationState.salesOrderId;
      this.DMENumber = currentNavigationState.DMENumber;
      this.domainUrl = currentNavigationState.domainUrl;
      this.strURL = currentNavigationState.strURL
    }
    if (!currentNavigationState) {
      this.salesORderFromLocal = this.webStorageService.getSalesOrderId();
      if (this.salesORderFromLocal) {
        this.salesOrderDescription = this.salesORderFromLocal.crmClassificationDescription;
        this.salesOrderId = this.salesORderFromLocal.salesOrderId;
        this.DMENumber = this.salesORderFromLocal.DMENumber;
        this.domainUrl = this.salesORderFromLocal.domainUrl;
        this.strURL = this.salesORderFromLocal.strURL
      }
    }

  }

  ngOnInit() {

  }
  onBack() {

    this.router.navigate(['patient/home/' + this.strURL]);
  }
  RequestForCallBack() {

    this.loading = true;
    this.appointmentService.AddTelehealthSalesOrder(this.salesOrderId,'T')
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Your request to call back for Telehealth has been sent.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});
  }
}
