import { DateTime } from "rrule/dist/esm/datetime";
import { SearchRequestModel } from "../request/search-request-model";
import { QuickFilters } from "../common/custom-filiters";
import { KPIReportVisualizationModel } from "../request/kpiaddreport-request-model";

export class YearModel {
  year: string;
  count: number;
  status: string;
  month: MonthModel[];
  day: DayModel[];
  week: WeekModel[];
  appointmentDetails: AppointmentDetails[];
}

export class MonthModel {
  month: string;
  count: number;
  level3: MonthModel[];
}
export class DayModel {
  month: string;
  day: string;
  count: number;
}
export class WeekModel {
  month: string;
  week: string;
  count: number;
}
export class AppointmentDetails {
  status: string;
  count: number;
  crmSalesOrderAPPId: string;
  crmClassification: string;
  crmBranch: string;
  crmFacilityName: string;
  schAppointmentType: string;
  appTherapistName: string;
  schAppointmentBookedByPerson: string;
  insurance: string;
  year: string;
  month: string;
}

export class KPIReportSearchRequestModel {
  years: string;
  months: string;
  weeks: string;
  appointments: string;
  classification: string;
  scheduler: string;
  branch: string;
  facilities: string;
  therapist: string;
  insurance: string;
  typeofappointments: string;
  reportName: string;
  appUserInfoId: number;
  pageSize: number;
  currentPageIndex: number;
  xAxisList: KPIReportVisualizationModel[];
  columnType: string;
  combinedReportAverageBy: number;
  kpiUserReportPrefrenceId: number;
}

export class KPIReportModel {
  kpiUserReportPrefrenceId: number;
  reportName: string;
  orgReportName: string;
  selectedAppointmentStatus: string;
  selectedYear: string;
  selectedMonth: string;
  selectedWeeks: string;
  selectedDays: string;
  selectedSchedulers: string;
  selectedClassification: string;
  selectedBranches: string;
  selectedFacility: string;
  selectedTherapist: string;
  selectedInsurance: string;
  selectedAppointmentTypes: string;
  reportSortOrder: number;
  isDataTableView: boolean;
  isRenameClicked: boolean;
  isMaxMinimizeReport: boolean
  kpiReportDataTable: KPIReportDataTableResponse[];
  kpiMovingReportDataTable: CombinedChartModel[];
  kpiReportSearchRequestModel: SearchRequestModel;
  selectedpage: QuickFilters;
  pageSiges: QuickFilters[];
  totalRecords: number;
  totalPages: number;
  selectedXAxisList: KPIReportVisualizationModel[];
  columnType: string;
  isLoading: boolean;
  combinedReportAverageBy: number;
}

export class KPIUserPrefrenceResponseModel {
  kpiReportId: number;
  kpiuserReportPrefrenceID: number;
  orgReportName: string;
  reportName: string;
  reportSortOrder: string;
  userInfoID: number;
  columnType: string;
  combinedReportAverageBy: number;
  userReportFilterPrefrencesList: KPIUserReportFilterPrefrencesResponseModel[];
  kpiReportVisualizationList: KPIReportVisualizationModel[];
}


export class KPIUserReportFilterPrefrencesResponseModel {
  filterName: string;
  filterTimePeriod: string;
  filterValues: string;
  kpiUserReportFilterPrefrenceId: number;
  kpiUserReportPrefrenceId: number;
}

export class KPIReportDataTableResponse {
  year: number;
  month: string;
  //count: number;
  status: string;
  crmClassification: string;
  schAppointmentType: string;
  srmFacilityName: string;
  appTherapistName: string;
  schAppointmentBookedByPerson: string;
  crmBranch: string;
  insurance: string;
  appDate: number;
  movingAverage: number;
  crmSalesOrderAPPId: string;
}

// export class KPIReportMovingAverageDataTableResponse {
//   appDate: number;
//   count: number;
//   movingAverage: number;
//   status: string;
//   crmClassification: string;
//   schAppointmentType: string;
//   srmFacilityName: string;
//   appTherapistName: string;
//   schAppointmentBookedByPerson: string;
//   crmBranch: string;
//   insurance: string;
// }

export class CombinedChartModel {
  appDate: string;
  count: number;
  moving: number;
  status: string;
}

export class KPIReportResponseModel {
  kPIReportId: number;
  kPIReportName: string;
  kPIReportDescription: string;
}

export class KPIReportRolePermissionRequestModel {
  kPIReportId: number;
  appRoleId: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
}

export class KPIReportRolePermissionResponseModel {
  kpiReportRolePermissionId: number;
  kpiReportId: number;
  appRoleId: number;
  isActive: boolean;
}