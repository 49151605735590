import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { RouterModule } from '@angular/router';
import { WeekDemoComponent } from './component';
import { WeekViewSchedulerComponent } from './week-view-scheduler.component';
import { SharedModule } from '../../../shared/shared.module';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule as inlineCalendar } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

export const MyDefaultTooltipOptions: TooltipOptions = {
  'placement': 'right'
}
@NgModule({
  declarations: [WeekDemoComponent, WeekViewSchedulerComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange'}),
    SharedModule,
    MenuModule,
    ButtonModule,
    DialogModule,
    RadioButtonModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    AccordionModule,
    TabViewModule,
    ProgressSpinnerModule,
    inlineCalendar
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-US' }
  ],
  exports: [WeekDemoComponent, WeekViewSchedulerComponent, inlineCalendar],
})
export class WeekDemoModule { }

