export class PatientInfoResponseModel {
  crmPatientId: number;
  crmRcmPatientid: string;
  crmRcmBrightreeID: number;
  crmPatientFirstName: string;
  crmPatientLastName: string;
  crmPatientFullName: string;
  crmPatientAddress: string;
  crmPatientZipCode: string;
  crmPatientPhoneNo: string;
  crmPatientMobileNumber: string;
  crmPatientEmailAddress: string;
  crmPatientBirthDate: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date
  crmBranchId: number;
  crmSalesOrderId: number;
  crmLatitude: number;
  crmLongitude: number;
  crmBranchName: string;
  crmPatientDeliveryAddress: string;
  crmDeliveryAddressZipcode: string;
  crmDeliveryAddressLine1: string;
  crmDeliveryAddressLine2: string;
  crmDeliveryAddressCity: string;
  crmDeliveryAddressState: string;
  crmDeliveryAddressCountry: string;
  crmAddressLine1: string;
  crmAddressLine2: string;
  crmCity: string;
  crmState: string;
  crmCountry: string;
  zipcode: string;
  crmBillingAddressTimeZone: string;
  crmDeliveryAddressTimeZone: string;
}
