<div class="content-area">
    <div class="appointments-tab-outer">
        <div class="title-block">
            <h1>{{title}} </h1>
            <div class="title-block__right">

                <div class="from__group list-search-outer">
                    <div class="search-box">
                        <input type="text" class="form__control" pInputText placeholder="S/O ID, Patient & DOB"
                            #searchBox (keyup.enter)="onSearchButtonClick(false);">

                        <button *ngIf="searchBox.value !=''" class="clear-btn" type="button"
                            (click)="searchBox.value ='';onSearchButtonClick(true);">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M13.6524 1.54489L1.23901 13.5955M13.6524 13.5955L1.23901 1.54489L13.6524 13.5955Z"
                                    stroke="#363636" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                        <button class="search-btn" pButton type="button">
                            <i class="search-new"></i>
                        </button>
                    </div>
                </div>
                <a class="back-btn" href="javascript:void(0);" pTooltip="Export CSV" (click)="ExportAppointments();">
                    <svg _ngcontent-udr-c350="" fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px" height="25px" viewBox="0 0 548.29 548.291" xml:space="preserve" style="margin-right: 0;">
                        <g _ngcontent-udr-c350=""><path _ngcontent-udr-c350="" d="M486.2,196.121h-13.164V132.59c0-0.399-0.064-0.795-0.116-1.2c-0.021-2.52-0.824-5-2.551-6.96L364.656,3.677
                        c-0.031-0.034-0.064-0.044-0.085-0.075c-0.629-0.707-1.364-1.292-2.141-1.796c-0.231-0.157-0.462-0.286-0.704-0.419
                        c-0.672-0.365-1.386-0.672-2.121-0.893c-0.199-0.052-0.377-0.134-0.576-0.188C358.229,0.118,357.4,0,356.562,0H96.757
                        C84.893,0,75.256,9.649,75.256,21.502v174.613H62.093c-16.972,0-30.733,13.756-30.733,30.73v159.81
                        c0,16.966,13.761,30.736,30.733,30.736h13.163V526.79c0,11.854,9.637,21.501,21.501,21.501h354.777
                        c11.853,0,21.502-9.647,21.502-21.501V417.392H486.2c16.966,0,30.729-13.764,30.729-30.731v-159.81
                        C516.93,209.872,503.166,196.121,486.2,196.121z M96.757,21.502h249.053v110.006c0,5.94,4.818,10.751,10.751,10.751h94.973v53.861
                        H96.757V21.502z M258.618,313.18c-26.68-9.291-44.063-24.053-44.063-47.389c0-27.404,22.861-48.368,60.733-48.368
                        c18.107,0,31.447,3.811,40.968,8.107l-8.09,29.3c-6.43-3.107-17.862-7.632-33.59-7.632c-15.717,0-23.339,7.149-23.339,15.485
                        c0,10.247,9.047,14.769,29.78,22.632c28.341,10.479,41.681,25.239,41.681,47.874c0,26.909-20.721,49.786-64.792,49.786
                        c-18.338,0-36.449-4.776-45.497-9.77l7.38-30.016c9.772,5.014,24.775,10.006,40.264,10.006c16.671,0,25.488-6.908,25.488-17.396
                        C285.536,325.789,277.909,320.078,258.618,313.18z M69.474,302.692c0-54.781,39.074-85.269,87.654-85.269
                        c18.822,0,33.113,3.811,39.549,7.149l-7.392,28.816c-7.38-3.084-17.632-5.939-30.491-5.939c-28.822,0-51.206,17.375-51.206,53.099
                        c0,32.158,19.051,52.4,51.456,52.4c10.947,0,23.097-2.378,30.241-5.238l5.483,28.346c-6.672,3.34-21.674,6.919-41.208,6.919
                        C98.06,382.976,69.474,348.424,69.474,302.692z M451.534,520.962H96.757v-103.57h354.777V520.962z M427.518,380.583h-42.399
                        l-51.45-160.536h39.787l19.526,67.894c5.479,19.046,10.479,37.386,14.299,57.397h0.709c4.048-19.298,9.045-38.352,14.526-56.693
                        l20.487-68.598h38.599L427.518,380.583z"></path></g></svg>
                </a>
                <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
            </div>

        </div>
        <div>
            <div class="page-scroll appointments-list app-not-scheduled-list">
                <div class="appointments-scroll-inner-side">

                    <p-table #table class="table-outer appt-not-scheduled today-dash-list" sortMode="single" [sortOrder]="1"
                        sortField="crmPatientName" [paginator]="totalRecords>0?true:false" [rows]="getTotalRows()"
                        [totalRecords]="totalRecords" [lazy]="true" [customSort]="true"
                        [first]="searchRequestModel.FirstRecordIndex" [value]="salesOrders"
                        (onLazyLoad)="onLazyLoad($event)" [(selection)]="selectedsalesOrders" selectionMode=""
                        (selectionChange)="onSelectionChange($event)" [scrollable]="true"
                        scrollHeight="calc(100vh - 297px)" dataKey="crmSalesOrderId" scrollDirection="both">


                        <ng-template pTemplate="header">
                            <tr *ngIf="totalRecords > 0">
                                <!-- <th class="table-outer__th appt-action-column" pFrozenColumn></th> -->
                                <th class="table-outer__th appt-so-id" pFrozenColumn>
                                    <span class="header-soid__name">S/O ID</span>
                                </th>
                                <th class="table-outer__th appt-patient-name" pFrozenColumn
                                    pSortableColumn="crmPatientName">Patient
                                    details
                                    <p-sortIcon field="crmPatientName"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pFrozenColumn pSortableColumn="crmClassification">
                                    Classification Type
                                    <p-sortIcon field="crmClassification"></p-sortIcon>
                                </th>
                                <th class="table-outer__th appt-status" pFrozenColumn
                                    pSortableColumn="crmSalesorderStatus">Status
                                    <p-sortIcon field="crmSalesorderStatus"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmDoctorName">Doctor
                                    <p-sortIcon field="crmDoctorName"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmBranch">Branch
                                    <p-sortIcon field="crmBranch"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="dateTimeCreated">Entry Date
                                    <p-sortIcon field="dateTimeCreated"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="schAppointmentDateTime">Appt. Date & Time
                                    <p-sortIcon field="schAppointmentDateTime"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmCallBackDateTime">Request Call Back Date
                                    <p-sortIcon field="crmCallBackDateTime"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="amountDue">Amount Due
                                    <p-sortIcon field="amountDue"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmClassificaitonduration">Appt. Duration
                                    <p-sortIcon field="crmClassificaitonduration"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmPatientEmail">Email
                                    <p-sortIcon field="crmPatientEmail"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmPatientPhoneNumber">Phone Number
                                    <p-sortIcon field="crmPatientPhoneNumber"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="timeZone">Time Zone
                                    <p-sortIcon field="timeZone"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="lastCallDate">Last Call Date & Time
                                    <p-sortIcon field="lastCallDate"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="appointmentBookedBy">Appointment Booked By
                                    <p-sortIcon field="appointmentBookedBy"></p-sortIcon>
                                </th>
                                <th class="table-outer__th" pSortableColumn="crmPolicyHolderName">Primary Insurance
                                    <p-sortIcon field="crmPolicyHolderName"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="body" let-salesorder let-columns="columns">
                            <tr>
                                <td class="table-outer__td appt-so-id" pFrozenColumn>
                                    <span class="table-checkbox" pTooltip="{{salesorder.crmSalesOrderAPPId}}">
                                        {{salesorder.crmSalesOrderAPPId}}
                                    </span>

                                </td>
                                <td class="table-outer__td appt-patient-name" pFrozenColumn>
                                    <span class="patient">
                                        <a class="text-ellipsis" href="javascript:void(0)"
                                            (click)="onView(salesorder.crmSalesOrderId,salesorder.appointmentId)"
                                            pTooltip="{{salesorder.crmPatientName}}" tooltipPosition="top">
                                            {{salesorder.crmPatientName}}
                                        </a>
                                        <span class="bright-tree-patient-url">
                                            {{salesorder['crmPatientId']}}
                                            | {{salesorder['crmPatientBirthdate'] | date:appDateFormat.dateFormat }}
                                        </span>
                                    </span>

                                </td>
                                <td class="table-outer__td" pFrozenColumn><span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmClassification}}">{{salesorder.crmClassification}}</span>
                                </td>
                                <td class="table-outer__td appt-status" pFrozenColumn>
                                    <p [ngClass]="salesorder.crmSalesorderStatus=='Cancel'?'cancel':
                                    salesorder.crmSalesorderStatus=='No Show'?'no-show':
                                    salesorder.crmSalesorderStatus=='Booked'?'upcoming':
                                    salesorder.crmSalesorderStatus=='Complete'?'complete':
                                    salesorder.crmSalesorderStatus=='Void Review'?'voidreview':
                                    salesorder.crmSalesorderStatus=='Void'?'void' :
                                    'ready-for-scheduling'" class="status-block-box">
                                    </p>
                                </td>

                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmDoctorName}}">{{salesorder.crmDoctorName}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmBranch}}">{{salesorder.crmBranch}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="date-time">
                                        {{salesorder['dateTimeCreated'] | date:appDateFormat.dateFormat}}
                                    </span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="date-time" *ngIf="salesorder['schAppointmentDateTime']">
                                        <span>{{salesorder['schAppointmentDateTime'] |
                                            date:appDateFormat.dateFormat}}</span>
                                        <p>
                                            {{salesorder['schAppointmentDateTime'] | date:'shortTime' |
                                            lowercase}}<span class="timezone-code">{{salesorder['crmPatientTimeZone'] |
                                                timezone}} </span>
                                        </p>
                                    </span>

                                </td>
                                <!-- <td class="table-outer__td">
                                    <span class="date-time dateTimeCreated" *ngIf="salesorder['crmCallBackDateTime']">
                                        {{salesorder['crmCallBackDateTime'] | date:appDateFormat.dateFormat}}
                                        <p>
                                            {{salesorder['crmCallBackDateTime'] | date:'shortTime' | lowercase}}
                                            <span *ngIf="salesorder['crmCallBackDateTime'] != null"
                                                class="timezone-code">
                                                {{salesorder['timeZone'] | timezone}}
                                            </span>
                                        </p>
                                    </span>
                                </td> -->
                                <td class="table-outer__td">
                                    <span class="date-time dateTimeCreated"
                                        *ngIf="salesorder['crmRequestCallBackDate'] != '0001-01-01T00:00:00'">
                                        {{salesorder['crmRequestCallBackDate'] | date:appDateFormat.dateFormat}}
                                        <p>
                                            {{salesorder['crmRequestCallBackDate'] | date:'shortTime' | lowercase}}
                                            <span *ngIf="salesorder['crmRequestCallBackDate'] != null"
                                                class="timezone-code">
                                                {{salesorder['timeZone'] | timezone}}
                                            </span>
                                        </p>
                                    </span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.amountDue}}">{{salesorder.amountDue | currency}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmClassificaitonduration}}">{{salesorder.crmClassificaitonduration}} Minutes</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmPatientEmail}}">{{salesorder.crmPatientEmail}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmPatientPhoneNumber}}">{{salesorder.crmPatientPhoneNumber}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.timeZone | timezone}}">{{salesorder['timeZone'] | timezone}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="date-time dateTimeCreated"
                                        *ngIf="salesorder['lastCallDate'] != '0001-01-01T00:00:00'">
                                        {{salesorder['lastCallDate'] | date:appDateFormat.dateFormat}}
                                        <p>
                                            {{salesorder['lastCallDate'] | date:'shortTime' | lowercase}}
                                            <span *ngIf="salesorder['lastCallDate'] != null" class="timezone-code">
                                                {{salesorder['timeZone'] | timezone}}
                                            </span>
                                        </p>
                                    </span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.appointmentBookedBy}}">{{salesorder.appointmentBookedBy}}</span>
                                </td>
                                <td class="table-outer__td">
                                    <span class="text-ellipsis"
                                        pTooltip="{{salesorder.crmPolicyHolderName}}">{{salesorder.crmPolicyHolderName}}</span>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="paginatorleft" class="table-pagination">
                            <span class="table-pagination__records">
                                Total Records : &nbsp;<strong> {{totalRecords}}</strong>
                            </span>
                        </ng-template>
                    </p-table>

                    <div class="no-records-found-appointment" *ngIf="!loading  && totalRecords <= 0"
                        [attr.colspan]="NsheduledTableDefaltColumns">
                        No records found
                    </div>
                </div>
            </div>

            <!--</ng-template>-->
        </div>

    </div>

    <!--</p-tabView>-->
</div>

<div class="loader-block" *ngIf="loading" style="z-index: 99999999 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
</div>