<div class="application-outer">
  <div class="holiday-title">
    <div class="title-block__left">
      <h3>Holiday List</h3>

      <p-dropdown class="select-box filter-status"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="filterSelectedHolidayYear" [options]="filterYear"
                  placeholder="Filter by Year" optionLabel="name" [virtualScroll]="true" selectedItemsLabel="{0} Status Selected"
                  (onChange)="onFilterYearChange($event)">
      </p-dropdown>
    </div>
    <div class="title-block__right">
      <button class="btn btn--primary"
              (click)="SetValuesWithLIstonPopup();displayHolidayPopup=true">
        Copy From Previous Year
      </button>
      <a href="javascript:void(0);" pTooltip="Add Holiday" tooltipPosition="left" class="add-plus"
         routerLink="/admin/settings/application/holiday/add">
        <img src="assets/img/add-plus.svg" width="17"
             alt="filters">
      </a>
    </div>
  </div>

  <div class="table-outer-main holiday-table">
    <p-table #nsdl class="table-outer" [value]="filterHolidayList" responsiveLayout="scroll" [scrollable]="true" scrollHeight="calc(100vh - 239px)">

      <ng-template pTemplate="header" let-columns>
        <tr>
          <th  class="table-outer__th">Year</th>
          <th  class="table-outer__th">Holiday Name</th>
          <th  class="table-outer__th">From Date</th>
          <th  class="table-outer__th">To Date</th>
          <th  class="table-outer__th">Excluded Facilities</th>
          <!--<th class="table-outer__th">Start Time</th>
          <th class="table-outer__th">End Time</th>
          <th class="table-outer__th">Half Day / Full Day</th>-->
          <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="120">Actions</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-holiday>
        <!-- <tbody *ngIf="filterHolidayList?.length >=0"> -->
        <tr>
          <td class="table-outer__td">{{holiday.appHolidayYear}}</td>
          <td class="table-outer__td">{{holiday.appHolidayName}}</td>
          <td class="table-outer__td">{{holiday.appHolidayStartDateTime | date:appDateFormat.dateFormat}}</td>
          <td class="table-outer__td">{{holiday.appHolidayEndDateTime | date:appDateFormat.dateFormat}}</td>
          <!--<td class="table-outer__td" *ngIf="!holidayList.isFullDay">{{holiday.appHolidayStartDateTime | date:'shortTime' | lowercase}}</td>
          <td class="table-outer__td" *ngIf="!holidayList.isFullDay">{{holiday.appHolidayEndDateTime   | date:'shortTime' | lowercase}}</td>
          <td class="table-outer__td" *ngIf="holidayList.isFullDay"></td>
          <td class="table-outer__td" *ngIf="holidayList.isFullDay"></td>
          <td class="table-outer__td">{{!holidayList.isFullDay ? 'Half Day' : 'Full Day'}}</td>-->
          <td  class="table-outer__td">{{holiday.excludeFacilitiesNames}}</td>
          <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
            <div class="action-column">
              <a class="actionmenu-btn" href="javascript:void(0)" (click)="holidayAction.toggle($event)">
                <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                </svg>
              </a>
            </div>

            <p-overlayPanel class="table-action-popup" appendTo="body" #holidayAction>
              <ul class="quick-link-menu">
                <li>
                  <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                     (click)="onEdit(holiday.appHolidayId)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                         viewBox="0 0 103.901 98.574">
                      <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                        <path id="Path_3" data-name="Path 3"
                              d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                              transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                        <path id="Path_4" data-name="Path 4"
                              d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                              transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                              stroke-linejoin="round" stroke-width="8" />
                      </g>
                    </svg>
                    Edit
                  </a>
                </li>
                <li>
                  <a class="delete-row" href="javascript:void(0)"
                     (click)="onDelete(holiday.appHolidayId)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                      <g id="delete-icon-new" transform="translate(-3 -1)">
                        <path id="Path_1" data-name="Path 1"
                              d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                              fill="#6f64a7" />
                        <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                              fill="#6f64a7" />
                        <path id="Path_3" data-name="Path 3"
                              d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                              fill="#6f64a7" />
                        <path id="Path_4" data-name="Path 4"
                              d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                        <path id="Path_5" data-name="Path 5"
                              d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                        <path id="Path_6" data-name="Path 6"
                              d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                      </g>
                    </svg>
                    Delete
                  </a>
                </li>
              </ul>
            </p-overlayPanel>
          </td>
        </tr>
        <!-- <tr *ngIf="filterHolidayList?.length <=0">
          No records found
        <tr> -->

      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr class="primeng-table__body" *ngIf="!loading">
          <td colspan="4" class="table-outer__td text-center no-records-found-doctors">
            No records found
          </td>
        </tr>
      </ng-template>

    </p-table>
  </div>

</div>

<p-dialog [(visible)]="displayHolidayPopup" styleClass="display-close-icon" [modal]="true" [responsive]="true"
          appendTo="body">
  <div class="popup-content-outer copy-holiday">
    <h2>Copy Holiday</h2>
    <div class="col">
      <div class="form__group">
        <label class="form__label">From Year</label>
        <p-dropdown class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedHolidayYear" [options]="year" placeholder="Select Year" optionLabel="name"
                    [virtualScroll]="true" selectedItemsLabel="{0} Status Selected" (onChange)="onYearSelection($event)">
        </p-dropdown>
      </div>
      <div class="form__group">
        <label class="form__label">To Year</label>
        <p-dropdown class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="selectedToHolidayYear" [options]="toYear" placeholder="Select Year" optionLabel="name"
                    [virtualScroll]="true" selectedItemsLabel="{0} Status Selected" (onChange)="onToYearSelection($event)">
        </p-dropdown>
      </div>

    </div>


    <div class="copy-holiday__table">
      <table class="custom-table">
        <thead>
          <tr>
            <th width="20">
              <p-checkbox binary="true" name="groupname1" (onChange)="checkAllCheckBox($event)">
              </p-checkbox>
            </th>
            <th>Holiday Name</th>
            <th>From Date</th>
            <th>To Date</th>
          </tr>
        </thead>
        <tbody *ngIf="copyHolidayList?.length >=0">
          <tr *ngFor="let holiday of copyHolidayList">
            <td>
              <p-checkbox name="groupname" value="{{holiday.appHolidayId}}" [(ngModel)]="selectedHolidayList"
                          (onChange)="onChangeCheckBoxIndividual($event,holiday)">
              </p-checkbox>
            </td>
            <td>{{holiday.appHolidayName}}</td>
            <td>{{holiday.appHolidayStartDateTime | date:appDateFormat.dateFormat}}</td>
            <td>{{holiday.appHolidayEndDateTime | date:appDateFormat.dateFormat}}</td>
          </tr>
          <tr *ngIf="copyHolidayList?.length <=0">
            <td colspan="4">
              No records found
            </td>
          <tr>
        </tbody>
      </table>
    </div>


    <div class="form__group">
      <label class="form__label"></label>
      <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="isOveride"
                  label="Overwrite existing holiday" (onChange)="onCheck($event)">
      </p-checkbox>
    </div>

    <div class="form__group button-box">
      <div class="btns">
        <button class="btn btn--primary" (click)="onCopyPreviousYearHoliday()">Copy</button>
        <button class="btn btn--secondary" (click)="clearSelection();displayHolidayPopup=false;">Cancel</button>
      </div>
    </div>

  </div>
</p-dialog>
