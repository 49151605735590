import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SearchRequestModel, SearchRequestModelForNearByPatients } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { AppointmentCountResponseModel, AppointmentsCountsResponseModel, SalesOrderDependenciesDetails } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { FacilitySearchResponseModel, FacilityTimeSlotResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { FacilityTimeSlotRequest } from 'src/app/shared/models/request/facility-slots-request';
import { FacilityTherapistAvalabilityRequestModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-request-model';
import { FacilityTherapistAvalabilityModel } from 'src/app/shared/models/response/appointment/facility-therapist-avalability-model';
import { TherapistRoadShowAvailabilityRequestModel } from 'src/app/shared/models/request/therapist-availability-request';
import { TherapistAvailabilityTimeslotsModel } from 'src/app/shared/models/response/appointment/therapist-availability-timeslots-model';
import { AvailableSlots, FaciltiyForSlots, TherapistAvailability, ViewByDateResponseTranspose, ViewByDateResponseTransposeModel } from 'src/app/shared/models/response/appointment/available-slots';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { CommonNextDayScheduleRequest } from 'src/app/shared/models/request/facility/common-nextday-schedule';
import { NearByPatientResponseModel } from 'src/app/shared/models/response/appointment/nearby-patient-response-model';
import { AppointmentCallListResponseModel, ViewByDateRequest } from 'src/app/shared/models/request/appointment-call-response-model';
import { AppointmentCallResponseModel as AppointmentCallRequestModel } from 'src/app/shared/models/request/call-request-model'
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model'
import { SchedulerResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { RoadShowTimeSlotMainResponseModel } from 'src/app/shared/models/response/appointment/roadshow-timeslot-main-response-model';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { FacilityResponseModelDropdownList } from 'src/app/shared/models/response/facility/facility-response-model';
import { PatientCallRequestModel } from 'src/app/shared/models/request/patient-call-request-model';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { SalesOrderInBTResponse } from 'src/app/shared/models/response/sales-order-bt-response';
import * as moment from 'moment';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { AppointmentHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-history-response-model'
import { AddressUpdateRequestModel } from 'src/app/shared/models/request/address-update-request-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { AppointmentFacilityResponseModel } from 'src/app/shared/models/response/appointment/appointment-facility-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { AppointmentEmailHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-emalhistory-response-model';
import { AppointmentSMSHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-smshistory-response-model';
import { SalesorderStatusCountModel } from 'src/app/shared/models/response/appointment/salesorder-status-count-model';
import { RefreshPatientInfoResponseModel } from '../../shared/models/response/refreshPatientInfo-response-model';
import { AppointmentCallResponseModel } from '../../shared/models/response/appointment/appointment-call-response-model';
import { NotificationVoicemailResponseModel } from '../../shared/models/response/notificationVoicemail-response-model';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { AppointmentVoiceCallResponseModel } from '../../shared/models/response/appointment/appointment-voice-call-response-model';
import { Byte } from '@angular/compiler/src/util';
import { AppointmentCallRecordingUploadHistoryRequestModel } from '../../shared/models/request/appoitment-call-recording-upload-history-request-model';
import { AppointmentCallRecordingUploadHistoryResponseModel } from '../../shared/models/response/appointment/appoitment-call-recording-upload-history-response-model';
import { AppointmentCallRecordingPlayHistoryRequestModel } from '../../shared/models/request/appoitment-call-recording-play-history-request-model';
import { PrimaryInsuranceProviderResponseModel } from 'src/app/shared/models/response/appointment/primary-insurance-providers-response-model';

import { RoadHomeVisitHistoryRequestModel } from 'src/app/shared/models/request/roadHomeVisitHistory-request';

import { UserPreferencesModuleColumnResponseModel } from 'src/app/shared/models/response/user-wise-grid-column-response-model';
import { UserWiseModuleColumn } from 'src/app/shared/models/request/user-wise-column-model-request';
import { FilterClassificationTypeResponseModel } from 'src/app/shared/models/response/appointment/filter-classification-response-model';


@Injectable({
  providedIn: 'root'
})
export class AppointmentService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  GetAllSalesOrders(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllSalesOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
    url = url + '&ClassificationType=' + setClassificationType;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }

      //let json = JSON.stringify(searchRequestModel.dictionaryFilter);
      //json = json.replace(/"(\w+)"\s*:/g, '$1:');
      //let newArray = {};
      //newArray['dictionaryFilter'] = json;
      //let jsonNew = JSON.stringify(newArray);
      //jsonNew = jsonNew.replace(/"(\w+)"\s*:/g, '$1:');
      //url = url + '&dictionaryFilter=' + jsonNew;
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  GetAllSalesOrdersRefresh(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllSalesOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  GetAllUrgentCallBackSalesOrders(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllUrgentCallBackSalesOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }

      //let json = JSON.stringify(searchRequestModel.dictionaryFilter);
      //json = json.replace(/"(\w+)"\s*:/g, '$1:');
      //let newArray = {};
      //newArray['dictionaryFilter'] = json;
      //let jsonNew = JSON.stringify(newArray);
      //jsonNew = jsonNew.replace(/"(\w+)"\s*:/g, '$1:');
      //url = url + '&dictionaryFilter=' + jsonNew;
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }



  GetAllWIPStatesBySalesOrderType(salesOrderType: string) {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Appointment/GetAllWIPStatesBySalesOrderType?salesOrderType=' + salesOrderType);
  }
  GetSalesOrderCount(crmBranchId: number) {
    return this.get<ResultOfT<SalesOrderCountsResponseModel>>('Appointment/GetSalesOrderCount/' + crmBranchId);
  }
  GetSalesOrderById(SalesOrderId: number, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<SalesOrderInfoResponseModel>>('Appointment/GetSalesOrderById?SalesOrderId=' + SalesOrderId);
    }
    else {
      return this.get<ResultOfT<SalesOrderInfoResponseModel>>('Appointment/GetSalesOrderById?SalesOrderId=' + SalesOrderId + '&rnd=' + Math.random().toString());
    }
  }

  RefreshPatientInfo(SalesOrderId: number) {
    return this.get<ResultOfT<RefreshPatientInfoResponseModel>>('Appointment/RefreshPatientInfo?SalesOrderId=' + SalesOrderId);
  }

  RefreshCallLogs(SalesOrderId: number) {
    return this.get<ResultOfT<AppointmentCallResponseModel[]>>('Appointment/RefreshCallLogs?SalesOrderId=' + SalesOrderId);
  }

  RefreshVoiceMailDetails(SalesOrderId: number) {
    return this.get<ResultOfT<NotificationVoicemailResponseModel[]>>('Appointment/RefreshVoiceMailDetails?SalesOrderId=' + SalesOrderId);
  }

  GetCallLogsBySalesOrderId(SalesOrderId: number) {
    return this.get<ResultOfT<AppointmentCallRequestModel[]>>('Appointment/GetCallLogsBySalesOrderId?SalesOrderId=' + SalesOrderId);
  }

  GetCallLogsByAppintmentId(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentHistoryResponseModel[]>>('Appointment/GetCallLogsByAppintmentId?AppointmentId=' + AppointmentId);
  }
  GetAppointmentsCount(crmBranchId: number) {
    return this.get<ResultOfT<AppointmentsCountsResponseModel>>('Appointment/GetAppointmentsCount/' + crmBranchId);
  }

  IsSalesOrdersLockedByUser(schAppointmentLockedBy: number) {
    return this.get<ResultOfT<boolean>>('Appointment/IsSalesOrdersLockedByUser?schAppointmentLockedBy=' + schAppointmentLockedBy);
  }

  IsAppointmentLockedByUser(schAppointmentLockedBy: number) {
    return this.get<ResultOfT<boolean>>('Appointment/IsAppointmentLockedByUser/' + schAppointmentLockedBy);
  }

  AddTelehealthSalesOrder(SalesOrderId: string, strType: string) {
    return this.get<ResultOfT<boolean>>('appointment/AddTelehealthSalesOrder/' + SalesOrderId + '/' + strType)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAllAppointmentsRefresh(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllAppointments';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }
    if (searchRequestModel.selectedClassificationType) {
      let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
      url = url + '&ClassificationType=' + setClassificationType;
    }

    if (searchRequestModel.isUnreconciled != undefined) {
      url = url + '&IsUnreconciled=' + searchRequestModel.isUnreconciled;
    }
    else {
      url = url + '&IsUnreconciled=' + true;
    }

    if (searchRequestModel.isUpcoming) {
      url = url + '&IsUpcoming=' + searchRequestModel.isUpcoming;
    }
    else {
      url = url + '&IsUpcoming=' + false;
    }
    if (searchRequestModel.BookingSearchFromDate) {
      let datepass = moment(searchRequestModel.BookingSearchFromDate).format('MM-DD-YYYY');
      url = url + '&BookingSearchFromDate=' + datepass;
    }

    if (searchRequestModel.BookingSearchToDate) {
      let datepass = moment(searchRequestModel.BookingSearchFromDate).format('MM-DD-YYYY');
      url = url + '&BookingSearchToDate=' + datepass;
    }

    if (searchRequestModel.scheduledBy) {
      url = url + '&scheduledBy=' + searchRequestModel.scheduledBy;
    }
    if (searchRequestModel.isRescheduled) {
      url = url + '&isRescheduled=' + searchRequestModel.isRescheduled;
    }
    if (searchRequestModel.isTodaysBooking) {
      url = url + '&isTodaysBooking=' + searchRequestModel.isTodaysBooking;
    }

  

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }

      //let json = JSON.stringify(searchRequestModel.dictionaryFilter);
      //json = json.replace(/"(\w+)"\s*:/g, '$1:');
      //let newArray = {};
      //newArray['dictionaryFilter'] = json;
      //let jsonNew = JSON.stringify(newArray);
      //jsonNew = jsonNew.replace(/"(\w+)"\s*:/g, '$1:');
      //url = url + '&dictionaryFilter=' + jsonNew;
    }
    url = url + "&rid=" + Math.random().toString();
    return this.get<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>(url);
    //return this.post<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>('Appointment/GetAllAppointments', searchRequestModel);
  }

  GetAllAppointmentsByTherapist(therapistId: string, appointmentDate: string) {
    return this.get<ResultOfT<AppointmentSummaryResponseModel[]>>('Appointment/GetAppointmentsTherapistWise/' + therapistId + '/' + appointmentDate);
  }
  SendInvitationForNotScheduled(salesorders: any[]) {
    return this.post<ResultOfT<boolean>>('Appointment/SendInvitationForNotScheduled', salesorders);
  }
  UpdateAppointmentStatus(salesorders: AppointmentUpdateStatusRequestModel[]) {

    return this.post<ResultOfT<boolean>>('Appointment/UpdateAppointmentStatus', salesorders);
  }
  UpdateSalesOrderStatus(salesorders: AppointmentUpdateStatusRequestModel) {

    return this.post<ResultOfT<boolean>>('Appointment/UpdateSalesOrderStatus', salesorders);
  }

  GetAppointmentById(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentInfoReponseModel>>('Appointment/GetAppointmentById/' + AppointmentId);
  }

  RefreshAppointmentPatientInfo(AppointmentId: number) {
    return this.get<ResultOfT<RefreshPatientInfoResponseModel>>('Appointment/RefreshAppointmentPatientInfo/' + AppointmentId);
  }

  RefreshAppointmentHistory(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentHistoryResponseModel[]>>('Appointment/RefreshAppointmentHistory/' + AppointmentId);
  }

  GetSalesOrderCountByStatus(crmBranchId: number) {
    return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetSalesOrderCountByStatus/' + crmBranchId);
  }

  GetSalesOrderCountByStatusUserBranch(crmBranchId: number, appuserId: number, isRefersh: boolean) {
    if (!isRefersh)
      return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetSalesOrderCountByStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId);
    else
      return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetSalesOrderCountByStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId + '&rnd=' + Math.random().toString());
  }

  GetAvailableTherapistByFacility(facilityTherapistAvalabilityRequestModel: FacilityTherapistAvalabilityRequestModel) {
    return this.post<ResultOfT<FacilityTherapistAvalabilityModel[]>>('Appointment/GetTherapistByFacility', facilityTherapistAvalabilityRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAppointmentsCountByStatus(crmBranchId: number) {
    return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetAppointmentsByStatus/' + crmBranchId);
  }

  GetAppointmentsCountByStatusUserBranch(crmBranchId: number, appuserId: number, isRefresh: boolean) {
    if (!isRefresh)
      return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetAppointmentsByStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId);
    else
      return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetAppointmentsByStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId + '&rnd=' + Math.random().toString());
  }

  GetAppointmentsCountByStatusUserBranchRefresh(crmBranchId: number, appuserId: number) {
    return this.get<ResultOfT<SalesOrdersCountModel[]>>('Appointment/GetAppointmentsByStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId + '&rid=' + Math.random().toString());
  }

  searchFacilityByPatient(facilitysearchrequestModel: FacilitySearchRequestModel, isRefresh: boolean) {
    var url = 'Appointment/SearchClinicsForPatient';
    if (facilitysearchrequestModel.crmSalesOrderId) {
      url = url + '?CrmSalesorderId=' + facilitysearchrequestModel.crmSalesOrderId;
    }
    if (facilitysearchrequestModel.crmPatientId) {
      url = url + '&CrmPatientId=' + facilitysearchrequestModel.crmPatientId;
    }
    // if (facilitysearchrequestModel.crmCityTown) {
    //   url = url + '&CrmCityTown=' + facilitysearchrequestModel.crmCityTown;
    // }

    if (facilitysearchrequestModel.crmPatientZipCode) {
      url = url + '&CrmPatientZipCode=' + facilitysearchrequestModel.crmPatientZipCode;
    }
    if (facilitysearchrequestModel.radius) {
      url = url + '&Radius=' + facilitysearchrequestModel.radius;
    }
    if (facilitysearchrequestModel.schAppointmentDateStartTimeString) {
      url = url + '&SchAppointmentDateStartTime=' + facilitysearchrequestModel.schAppointmentDateStartTimeString;
    }
    if (facilitysearchrequestModel.schAppointmentDateEndTimeString) {
      url = url + '&SchAppointmentDateEndTime=' + facilitysearchrequestModel.schAppointmentDateEndTimeString;
    }
    // if (facilitysearchrequestModel.crmState) {
    //   url = url + '&CrmState=' + facilitysearchrequestModel.crmState;
    // }
    if (facilitysearchrequestModel.isBillingAddress) {
      url = url + '&isBillingAddress=' + facilitysearchrequestModel.isBillingAddress;
    }
    if (facilitysearchrequestModel.searchText) {
      url = url + '&searchText=' + facilitysearchrequestModel.searchText;
    }
    if (facilitysearchrequestModel.facilityZipCode) {
      url = url + '&facilityZipCode=' + facilitysearchrequestModel.facilityZipCode;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    return this.get<ResultOfT<FacilitySearchResponseModel[]>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  searchFacility(facilitysearchrequestModel: FacilitySearchRequestModel) {
    var url = 'Appointment/SearchClinicsForPatient';
    if (facilitysearchrequestModel.crmSalesOrderId) {
      url = url + '?CrmSalesorderId=' + facilitysearchrequestModel.crmSalesOrderId;
    }
    if (facilitysearchrequestModel.crmPatientId) {
      url = url + '&CrmPatientId=' + facilitysearchrequestModel.crmPatientId;
    }
    if (facilitysearchrequestModel.crmCityTown) {
      url = url + '&CrmCityTown=' + facilitysearchrequestModel.crmCityTown;
    }

    if (facilitysearchrequestModel.crmPatientZipCode) {
      url = url + '&CrmPatientZipCode=' + facilitysearchrequestModel.crmPatientZipCode;
    }
    if (facilitysearchrequestModel.radius) {
      url = url + '&Radius=' + facilitysearchrequestModel.radius;
    }
    if (facilitysearchrequestModel.schAppointmentDateStartTimeString) {
      url = url + '&SchAppointmentDateStartTime=' + facilitysearchrequestModel.schAppointmentDateStartTimeString;
    }
    if (facilitysearchrequestModel.schAppointmentDateEndTimeString) {
      url = url + '&SchAppointmentDateEndTime=' + facilitysearchrequestModel.schAppointmentDateEndTimeString;
    }
    if (facilitysearchrequestModel.crmState) {
      url = url + '&CrmState=' + facilitysearchrequestModel.crmState;
    }
    if (facilitysearchrequestModel.isBillingAddress) {
      url = url + '&isBillingAddress=' + facilitysearchrequestModel.isBillingAddress;
    }
    url = url + '&isAppointment=' + facilitysearchrequestModel.isAppointment;
    return this.get<ResultOfT<FacilitySearchResponseModel[]>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  SearchClinicsForAppointment(facilitysearchrequestModel: FacilitySearchRequestModel) {
    var url = 'Appointment/SearchClinicsForAppointment';
    if (facilitysearchrequestModel.crmSalesOrderId) {
      url = url + '?CrmSalesorderId=' + facilitysearchrequestModel.crmSalesOrderId;
    }
    if (facilitysearchrequestModel.crmPatientId) {
      url = url + '&CrmPatientId=' + facilitysearchrequestModel.crmPatientId;
    }
    if (facilitysearchrequestModel.crmCityTown) {
      url = url + '&CrmCityTown=' + facilitysearchrequestModel.crmCityTown;
    }

    if (facilitysearchrequestModel.crmPatientZipCode) {
      url = url + '&CrmPatientZipCode=' + facilitysearchrequestModel.crmPatientZipCode;
    }
    if (facilitysearchrequestModel.radius) {
      url = url + '&Radius=' + facilitysearchrequestModel.radius;
    }
    if (facilitysearchrequestModel.schAppointmentDateStartTimeString) {
      url = url + '&SchAppointmentDateStartTime=' + facilitysearchrequestModel.schAppointmentDateStartTimeString;
    }
    if (facilitysearchrequestModel.schAppointmentDateEndTimeString) {
      url = url + '&SchAppointmentDateEndTime=' + facilitysearchrequestModel.schAppointmentDateEndTimeString;
    }
    if (facilitysearchrequestModel.crmState) {
      url = url + '&CrmState=' + facilitysearchrequestModel.crmState;
    }
    if (facilitysearchrequestModel.isBillingAddress) {
      url = url + '&isBillingAddress=' + facilitysearchrequestModel.isBillingAddress;
    }
    url = url + '&isAppointment=' + facilitysearchrequestModel.isAppointment;
    if (facilitysearchrequestModel.patientTimeZone) {
      url = url + '&patientTimeZone=' + facilitysearchrequestModel.patientTimeZone;
    }
    if (facilitysearchrequestModel.CrmSalesorderClassificationType) {
      url = url + '&CrmSalesorderClassificationType=' + facilitysearchrequestModel.CrmSalesorderClassificationType;
    }
    return this.get<ResultOfT<AppointmentFacilityResponseModel>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  searchRemoteFacility(facilitysearchrequestModel: FacilitySearchRequestModel) {
    return this.post<ResultOfT<FacilitySearchResponseModel[]>>('Appointment/FecthRemoteFacilities', facilitysearchrequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }
  SearchRemoteFacilitiesForAppointment(facilitysearchrequestModel: FacilitySearchRequestModel) {
    return this.post<ResultOfT<AppointmentFacilityResponseModel>>('Appointment/SearchRemoteFacilitiesForAppointment', facilitysearchrequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  searchFacilitybyId(facilitysearchrequestModel: FacilitySearchRequestModel) {
    return this.post<ResultOfT<FacilitySearchResponseModel[]>>('Appointment/FetchAppointmentFacilitiesByFacilityId', facilitysearchrequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }
  ScheduleAppointment(appointmentRequest: AppointmentRequestModel) {
    return this.post<ResultOfT<boolean>>('appointment/scheduleappointment/', appointmentRequest)
      .pipe(map((result) => {
        return result;
      }));
  }
  ReScheduleAppointment(appointmentRequest: AppointmentRequestModel) {
    return this.post<ResultOfT<boolean>>('appointment/ReScheduleAppointment/', appointmentRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAllPrimaryInsuranceProviders(SalesOrderStatus: string, AppUserId: number) {
    return this.get<ResultOfT<PrimaryInsuranceProviderResponseModel[]>>('Patient/GetAllPrimaryInsuranceProviders/' + SalesOrderStatus + '/' + AppUserId);
  }
  GetAllPrimaryInsuranceProvidersbyUserId(AppUserId: number) {
    return this.get<ResultOfT<PrimaryInsuranceProviderResponseModel[]>>('Patient/GetAllPrimaryInsuranceProviders/' + AppUserId);
  }

  GetAllClassificationType() {
    return this.get<ResultOfT<FilterClassificationTypeResponseModel[]>>('appointment/GetFilterClassificationTypes');
  }

  getFacilityTimeSlots(timeslotRequest: FacilityTimeSlotRequest) {
    var url = 'Patient/GetFacilityTimeSlotsByPatient';
    if (timeslotRequest.AppointmentDateTimeString) {
      url = url + '?AppointmentDateTime=' + timeslotRequest.AppointmentDateTimeString;
    }
    if (timeslotRequest.CrmFacilityTimeZone) {
      url = url + '&CrmFacilityTimeZone=' + timeslotRequest.CrmFacilityTimeZone;
    }
    if (timeslotRequest.CrmFacilityId) {
      url = url + '&CrmFacilityId=' + timeslotRequest.CrmFacilityId;
    }

    if (timeslotRequest.CrmTherapistId) {
      url = url + '&CrmTherapistId=' + timeslotRequest.CrmTherapistId;
    }
    if (timeslotRequest.CrmSalesorderId) {
      url = url + '&CrmSalesorderId=' + timeslotRequest.CrmSalesorderId;
    }
    if (timeslotRequest.CrmClassificationDuration) {
      url = url + '&CrmClassificationDuration=' + timeslotRequest.CrmClassificationDuration;
    }
    if (timeslotRequest.CrmPatientStateCode) {
      url = url + '&CrmPatientStateCode=' + timeslotRequest.CrmPatientStateCode;
    }
    return this.get<ResultOfT<FacilitySearchResponseModel>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  // getRemoteFacilityTimeSlots(timeslotRequest: FacilityTimeSlotRequest) {
  //   return this.post<ResultOfT<FacilitySearchResponseModel>>('appointment/FecthRemoteFacilityAppointmentTimeSlots/', timeslotRequest)
  //     .pipe(map((result) => {
  //       return result;
  //     }));
  // }

  getRoadShowTimeSlots(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<FacilitySearchResponseModel>>('appointment/FecthRoadShowAppointmentTimeSlots/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRoadShowTimeSlotsByTherapistsDME(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<RoadShowTimeSlotMainResponseModel>>('appointment/GetRoadShowTimeSlotsByTherapists/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRoadShowTimeSlotsByTherapistsGoogleMap(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<RoadShowTimeSlotMainResponseModel>>('appointment/GetRoadShowTimeSlotsByTherapistsGoogleMap/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetFacilitySlotsByTherapistsDME(timeslotRequest: FacilityTimeSlotRequest) {
    var url = 'appointment/FecthFacilityAppointmentTimeSlots';
    if (timeslotRequest.AppointmentDateTimeString) {
      url = url + '?AppointmentDateTime=' + timeslotRequest.AppointmentDateTimeString;
    }
    if (timeslotRequest.CrmFacilityTimeZone) {
      url = url + '&CrmFacilityTimeZone=' + timeslotRequest.CrmFacilityTimeZone;
    }
    if (timeslotRequest.CrmFacilityId) {
      url = url + '&CrmFacilityId=' + timeslotRequest.CrmFacilityId;
    }

    if (timeslotRequest.CrmTherapistId) {
      url = url + '&CrmTherapistId=' + timeslotRequest.CrmTherapistId;
    }
    if (timeslotRequest.CrmSalesorderId) {
      url = url + '&CrmSalesorderId=' + timeslotRequest.CrmSalesorderId;
    }
    if (timeslotRequest.CrmClassificationDuration) {
      url = url + '&CrmClassificationDuration=' + timeslotRequest.CrmClassificationDuration;
    }
    if (timeslotRequest.CrmPatientStateCode) {
      url = url + '&CrmPatientStateCode=' + timeslotRequest.CrmPatientStateCode;
    }
    url = url + '&isAppointment=' + timeslotRequest.isAppointment;
    if (timeslotRequest.patientTimeZone) {
      url = url + '&patientTimeZone=' + timeslotRequest.patientTimeZone;
    }
    if (timeslotRequest.CrmSalesorderClassificationType) {
      url = url + '&CrmSalesorderClassificationType=' + timeslotRequest.CrmSalesorderClassificationType;
    }
    if (timeslotRequest.SortOrder) {
      url = url + '&SortOrder=' + timeslotRequest.SortOrder;
    }
    if (timeslotRequest.CrmFacilityState) {
      url = url + '&CrmFacilityState=' + timeslotRequest.CrmFacilityState;
    }

    return this.get<ResultOfT<RoadShowTimeSlotMainResponseModel>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRemoteSlotsByTherapistsDME(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<RoadShowTimeSlotMainResponseModel>>('appointment/FetchRemoteFacilityAppointmentTimeSlots/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetSelfGuidedRemoteSlotsByTherapistsDME(timeslotRequest: FacilityTimeSlotRequest) {
    return this.post<ResultOfT<RoadShowTimeSlotMainResponseModel>>('appointment/FecthRemoteSelfGuidedFacilityAppointmentTimeSlots/', timeslotRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRoadShowFirstAvailableDate(AppointmentDateTime: string) {
    return this.get<ResultOfT<Date>>('Appointment/GetRoadShowFirstAvailableDate/' + AppointmentDateTime);
  }

  GetRoadShowFirstAvailableDateBySalesOrderId(AppointmentDateTime: string, crmSalesOrderId: number, isAppointment: boolean, patientTimeZone: string) {
    return this.get<ResultOfT<Date>>('Appointment/GetRoadShowFirstAvailableDateBySalesOrderId/' + AppointmentDateTime + '/' + crmSalesOrderId + '/' + isAppointment + '/' + patientTimeZone);
  }

  GetTherapistAvailabilityByDate(AppointmentDate: string, crmTherapistId: number) {
    return this.get<ResultOfT<SchedulerResponseModel[]>>('Therapist/GetTherapistAvailabilityByDate/' + AppointmentDate + '/' + crmTherapistId);
  }


  // getFailityTimeSlotsByTherapist(timeslotRequest: FacilityTimeSlotRequest) {
  //   return this.post<ResultOfT<FacilitySearchResponseModel>>('appointment/GetFacilityTimeSlotsByTherapist/', timeslotRequest)
  //     .pipe(map((result) => {
  //       return result;
  //     }));
  // }

  GetRoadShowTherapistAvailabilty(SalesOrderId: number) {
    return this.get<ResultOfT<TherapistAvailabilityTimeslotsModel[]>>('Appointment/GetRoadShowTherapistAvailabilty/' + SalesOrderId);
  }

  // GetRoadShowTherapistTimeSlots(timeslotRequest: TherapistRoadShowAvailabilityRequestModel) {
  //   return this.post<ResultOfT<FacilityTimeSlotResponseModel[]>>('appointment/GetRoadShowTherapistTimeSlots/', timeslotRequest)
  //     .pipe(map((result) => {
  //       return result;
  //     }));
  // }

  GetAvailableSlotsMonthWise(facilityid: number, duration: number, dtDate: string) {
    return this.get<ResultOfT<AvailableSlots[]>>('Appointment/GetFacilityAvailableSlotCountForMonth/' + facilityid + '/' + duration + '/' + dtDate);
  }

  GetAllAvailableSlotsMonthWise(duration: number, dtDate: string, branchid: string, facilityId: string, onlyroad: boolean, selectedArrayTimeZone: string) {

    var url = 'Appointment/GetAllAvailableSlotsMonth';
    url = url + '?';
    if (branchid) {
      url = url + 'branchid=' + branchid;
    }
    if (duration) {
      url = url + '&slotDuration=' + duration;
    }
    if (dtDate) {
      url = url + '&date=' + dtDate;
    }
    if (facilityId) {
      url = url + '&facilityId=' + facilityId;
    }

    var isRoad = onlyroad ? "true" : "false";
    url = url + '&onlyroad=' + isRoad;

    if (selectedArrayTimeZone) {
      url = url + '&timeZone=' + selectedArrayTimeZone;
    }
    return this.get<ResultOfT<ViewByDateResponseTranspose>>(url);
  }
  GetAllAvailableSlotsViewByDate(dtDate: string, branchid: string, facilityId: string, onlyroad: boolean, selectedArrayTimeZone: string) {

    var url = 'Appointment/GetAllAvailableSlotsViewByDate';
    url = url + '?';
    if (branchid) {
      url = url + 'branchid=' + branchid;
    }
    if (dtDate) {
      url = url + '&date=' + dtDate;
    }
    if (facilityId) {
      url = url + '&facilityId=' + facilityId;
    }

    var isRoad = onlyroad ? "true" : "false";
    url = url + '&onlyroad=' + isRoad;

    if (selectedArrayTimeZone) {
      url = url + '&timeZone=' + selectedArrayTimeZone;
    }
    return this.get<ResultOfT<ViewByDateResponseTransposeModel>>(url);
  }

  GetViewByDate(dtDate: string, branch: number) {
    return this.get<ResultOfT<ViewByDateResponseTranspose>>('Appointment/GetViewbyDate/' + dtDate + '/' + branch);
  }

  GetAllFacilities() {
    return this.get<ResultOfT<FaciltiyForSlots[]>>('Facility/GetAllFacilities');
  }

  GetAllFacilitiesBranchWise(branch: number) {
    return this.get<ResultOfT<FaciltiyForSlots[]>>('Facility/GetAllFacilitiesBranch?branch=' + branch);
  }

  GetTherapistAvailability(therapistId: number, dtDate: string) {
    return this.get<ResultOfT<TherapistAvailability[]>>('Appointment/GetTherapistAvailability/' + therapistId + '/' + dtDate);
  }

  AddOrderDeliveryNote(appointmentRequestModel: BTSalesOrderUpdateRequestModel) {
    return this.post<ResultOfT<SalesOrderInBTResponse>>('Appointment/AddOrderDeliveryNote/', appointmentRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }


  CancelAppointment(appointmentCancelRequest: AppointmentCancelRequest) {
    return this.post<ResultOfT<boolean>>('Appointment/CancelAppointment/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));
  }
  VoidAppointment(appointmentCancelRequest: AppointmentCancelRequest) {
    return this.post<ResultOfT<boolean>>('appointment/VoidAppointment/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateSalesOrderByWipStatus(appointmentCancelRequest: AppointmentCancelRequest[]) {
    return this.post<ResultOfT<boolean>>('appointment/UpdateSalesOrderByWipStatus/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  VoidSalesOrder(appointmentCancelRequest: AppointmentCancelRequest) {
    return this.post<ResultOfT<boolean>>('appointment/VoidSalesOrder/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));

  }

  VoidUpateSalesOrderAppointments(appointmentCancelRequest: AppointmentCancelRequest) {
    return this.post<ResultOfT<boolean>>('appointment/VoidUpateSalesOrderAppointments/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  VoidUpdateSalesOrders(appointmentCancelRequest: AppointmentCancelRequest[]) {
    return this.post<ResultOfT<boolean>>('appointment/VoidUpdateSalesOrders/', appointmentCancelRequest)
      .pipe(map((result) => {
        return result;
      }));

  }

  MoveAppointment(appointmentRequest: AppointmentRequestModel) {
    return this.post<ResultOfT<boolean>>('appointment/moveappointment/', appointmentRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  sendFacilitySchedule(commonNextDayScheduleRequest: CommonNextDayScheduleRequest) {
    return this.post<ResultOfT<boolean>>('appointment/SendFacilitySchedule', commonNextDayScheduleRequest)
      .pipe(map((result) => {
        return result;
      }));
  }

  updatePatientEmail(patientid: string, email: string, phone: string, mobilePhone: string, btNoteID: number, isProvisional: boolean) {
    phone = phone.replace("+", "");
    mobilePhone = mobilePhone.replace("+", "");
    return this.get<ResultOfT<boolean>>('RCMProvider/UpdatePatientInfo/' + patientid + '/' + email + '/' + phone + '/' + mobilePhone + '/' + btNoteID + '/' + isProvisional)
      .pipe(map((result) => {
        return result;
      }));
  }

  updatePatientInformation(patientInfoRequestModel: PatientInfoRequestModel) {

    patientInfoRequestModel.phone = patientInfoRequestModel.phone.replace("+", "");
    patientInfoRequestModel.mobilephone = patientInfoRequestModel.mobilephone.replace("+", "");

    return this.post<ResultOfT<boolean>>('RCMProvider/UpdatePatientInformation', patientInfoRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateDeliveryAddressAsBillingAddress(crmPatientId: number) {
    return this.get<ResultOfT<boolean>>('Appointment/UpdateDeliveryAddressAsBillingAddress/' + crmPatientId);
  }

  UpdateDeliveryAddressAsBillingAddressById(crmSalesOrderId: number, crmPatientId: number, isUpdateDelieryAddressToBrightree: boolean, btNoteID: number, isProvisional: boolean) {
    return this.get<ResultOfT<boolean>>('Appointment/UpdateDeliveryAddressAsBillingAddressById/' + crmSalesOrderId + '/' + crmPatientId + '/' + isUpdateDelieryAddressToBrightree + '/' + btNoteID + '/' + isProvisional);
  }

  UpdateDeliveryAddress(addressUpdateRequestModel: AddressUpdateRequestModel) {
    return this.post<ResultOfT<string>>('Appointment/UpdateDeliveryAddress', addressUpdateRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateSalesOrderDeliveryAddress(addressUpdateRequestModel: AddressUpdateRequestModel) {
    return this.post<ResultOfT<string[]>>('Appointment/UpdateSalesOrderDeliveryAddress', addressUpdateRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetStates() {
    return this.get<ResultOfT<StateMasterResponseModel[]>>('Therapist/GetStates');
  }

  GetNearByPatients(crmFacilityId: number, searchText: string, crmTherapistId: number, isStartFromDefaultFacility: boolean, isStartFromHomeAddress: boolean, apptDate: string) {
    var model = new SearchRequestModelForNearByPatients();
    model.crmFacilityId = crmFacilityId;
    model.searchText = searchText;
    model.crmTherapistId = crmTherapistId;
    model.isStartFromDefaultFacility = isStartFromDefaultFacility;
    model.isStartFromHomeAddress = isStartFromHomeAddress;
    model.appointmentDate = apptDate;
    return this.post<ResultOfT<NearByPatientResponseModel[]>>('Appointment/SearchNearByPatients', model);
  }

  GetNearByPatientsLicense(crmFacilityId: number, searchText: string, crmTherapistId: number, isStartFromDefaultFacility: boolean, isStartFromHomeAddress: boolean, apptDate: string, isBillingAddress: boolean, selectedApptType: string) {
    var model = new SearchRequestModelForNearByPatients();
    model.crmFacilityId = crmFacilityId;
    model.searchText = searchText;
    model.crmTherapistId = crmTherapistId;
    model.isStartFromDefaultFacility = isStartFromDefaultFacility;
    model.isStartFromHomeAddress = isStartFromHomeAddress;
    model.appointmentDate = apptDate;
    model.isBillingAddress = isBillingAddress;
    model.selectedApptType = selectedApptType;
    return this.post<ResultOfT<NearByPatientResponseModel[]>>('Appointment/SearchNearByPatientsLicense', model);
  }

  SaveCall(appointmentCallResponseModel: AppointmentCallRequestModel) {
    return this.post<ResultOfT<AppointmentCallRequestModel>>('appointmentcalls/SaveCall/', appointmentCallResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  UpdateCallStatus(appointmentCallResponseModel: AppointmentCallRequestModel) {
    return this.post<ResultOfT<AppointmentCallRequestModel>>('appointmentcalls/UpdateCallStatus/', appointmentCallResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }
  LockAppointments(appointmentCallListResponseModels: AppointmentCallListResponseModel[]) {
    return this.post<ResultOfT<boolean>>('appointmentcalls/LockAppointments/', appointmentCallListResponseModels)
      .pipe(map((result) => {
        return result;
      }));
  }

  UnLockAppointments(appointmentCallListResponseModels: AppointmentCallListResponseModel[]) {
    return this.post<ResultOfT<boolean>>('appointmentcalls/ReleaseCallList/', appointmentCallListResponseModels)
      .pipe(map((result) => {
        return result;
      }));
  }
  GetLockedSalesOrders(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>('appointmentcalls/GetLockedSalesOrders', searchRequestModel);
  }

  RecordCall(callId: string) {
    return this.get<ResultOfT<AppointmentCallRequestModel>>('appointmentcalls/RecordCall/' + callId);
  }

  FetchRecordUrl(callId: string) {
    return this.get<ResultOfT<AppointmentCallRequestModel>>('appointmentcalls/FetchRecordUrl/' + callId);
  }

  SendVoiceMail(callId: string, userId: number) {
    return this.get<ResultOfT<boolean>>('appointmentcalls/SendVoiceMail/' + callId + '/' + userId)
  }

  BulkSendVoiceMail(saleOrderIds: string, userId: number) {
    return this.get<ResultOfT<boolean>>('appointmentcalls/BulkSendVoiceMail/' + saleOrderIds + '/' + userId)
  }

  SendVoiceMailToNumber(voiceCallToNumber: string, crmSalesOrderId: number, callId: string, userId: number) {
    return this.get<ResultOfT<boolean>>('appointmentcalls/SendVoiceMailToNumber?phoneNumber=' + voiceCallToNumber + '&crmSalesOrderId=' + crmSalesOrderId + '&CallId=' + callId + '&userId=' + userId)
  }

  GetAllPatients(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<PatientInfoResponseModel>>>('Appointment/GetAllPatients', searchRequestModel);
  }

  GetAllPotentialVoid(searchRequestModel: SearchRequestModel, isRefreshed: boolean) {
    var url = 'Appointment/GetAllPotentialVoidOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
    url = url + '&ClassificationType=' + setClassificationType;

    // if (searchRequestModel.isTodaysBooking) {
    //   url = url + '&isTodaysBooking=' + searchRequestModel.isTodaysBooking;
    // }
    
    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    if (isRefreshed) {
      let randomNum = new Date().getSeconds().toString();
      url = isRefreshed ? url + '&rnd=' + randomNum : url;
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  GetAllCancelled(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllCancelledAppointments';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex >= 0) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }

    return this.get<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>(url);
    //return this.post<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>('Appointment/GetAllCancelledAppointments', searchRequestModel);
  }

  GetAllVoiceCallRecordings(searchRequestModel: SearchRequestModel, isRefreshed: boolean) {
    var url = 'Appointment/GetAllVoiceCallRecordings';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    if (isRefreshed) {
      let randomNum = new Date().getSeconds().toString();
      url = isRefreshed ? url + '&rnd=' + randomNum : url;
    }
    return this.get<ResultOfT<PagedResponseModel<AppointmentVoiceCallResponseModel>>>(url);
  }

  ExportVoiceCallRecordings(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportVoiceCallRecordings', searchRequestModel);
  }

  GetAllCallRecordingsToUpload(searchRequestModel: SearchRequestModel, isRefreshed: boolean) {
    var url = 'Appointment/GetAllCallRecordingsToUpload';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    if (isRefreshed) {
      let randomNum = new Date().getSeconds().toString();
      url = isRefreshed ? url + '&rnd=' + randomNum : url;
    }
    return this.get<ResultOfT<AppointmentVoiceCallResponseModel[]>>(url);
  }

  UploadVoiceCallRecordingToFTP(appointmentVoiceCallResponseModel: AppointmentVoiceCallResponseModel[], recordingUploadType: string, fromDate: string, toDate: string, userEmailID: string) {
    var url = 'appointmentcalls/UploadVoiceCallRecordingToFTP';
    url = url + '?recordingUploadType=' + recordingUploadType;
    url = url + '&fromDate=' + fromDate;
    url = url + '&toDate=' + toDate;
    url = url + '&userEmailID=' + userEmailID;
    return this.post<ResultOfT<AppointmentVoiceCallResponseModel[]>>(url, appointmentVoiceCallResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  getBulkUploadHistory(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<AppointmentCallRecordingUploadHistoryResponseModel>>>('appointmentcalls/GetBulkUploadHistory', searchRequestModel);
  }

  FetchRecordingCall(recordingSID: string) {
    return this.get<ResultOfT<any>>('appointmentcalls/FetchTwilioRecordingCall/' + recordingSID);
  }

  GetVoiceCallRecordingPlayBase64String(appointmentRecordingSID: string) {
    return this.get<ResultOfT<Byte[]>>('appointmentcalls/GetVoiceCallRecordingPlayBase64String/' + appointmentRecordingSID)
      .pipe(map((result) => {
        return result;
      }));
  }

  UnLockSalesOrder(id: number) {
    return this.post<ResultOfT<boolean>>('appointmentcalls/ReleaseSalesOrder/', id)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAllDoctorFacility() {
    return this.get<ResultOfT<FacilityResponseModelDropdownList[]>>('Appointment/GetAllDoctorFacility');
  }

  GetAllDoctors() {
    return this.get<ResultOfT<DoctorResponseModel[]>>('Appointment/GetAllDoctors');
  }

  SavePatientCallBack(patientCallRequestModel: PatientCallRequestModel) {
    return this.post<ResultOfT<boolean>>('Appointment/SavePatientCallBack', patientCallRequestModel);
  }

  SendEmailToProvider(SalesOrderId: string) {
    return this.get<ResultOfT<boolean>>('appointment/SendEmailToProvider/' + SalesOrderId)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetSearchResults(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>('Appointment/GetSearchResults', searchRequestModel);
  }

  ExportSAppointments(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportAppointments', searchRequestModel);
  }

  ExportNSAppointments(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportSalesOrder', searchRequestModel);
  }

  ExportSaleOrderHistroty(formdata: FormData) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportSalesOrderHisotry', formdata);
  }

  GetRemoteFirstAvailableDateTimeByDuration(timeslotRequest: FacilityTimeSlotRequest) {
    var url = 'appointment/GetRemoteFirstAvailableDateTimeByDuration';
    if (timeslotRequest.AppointmentDateTimeString) {
      url = url + '?AppointmentDateTime=' + timeslotRequest.AppointmentDateTimeString;
    }
    if (timeslotRequest.CrmFacilityTimeZone) {
      url = url + '&CrmFacilityTimeZone=' + timeslotRequest.CrmFacilityTimeZone;
    }
    if (timeslotRequest.CrmFacilityId) {
      url = url + '&CrmFacilityId=' + timeslotRequest.CrmFacilityId;
    }

    if (timeslotRequest.CrmTherapistId) {
      url = url + '&CrmTherapistId=' + timeslotRequest.CrmTherapistId;
    }
    if (timeslotRequest.CrmSalesorderId) {
      url = url + '&CrmSalesorderId=' + timeslotRequest.CrmSalesorderId;
    }
    if (timeslotRequest.CrmClassificationDuration) {
      url = url + '&CrmClassificationDuration=' + timeslotRequest.CrmClassificationDuration;
    }
    url = url + '&isAppointment=' + timeslotRequest.isAppointment;
    if (timeslotRequest.patientTimeZone) {
      url = url + '&patientTimeZone=' + timeslotRequest.patientTimeZone;
    }
    return this.get<ResultOfT<Date>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetRoadShowFirstAvailableDateTimeByDuration(timeslotRequest: FacilityTimeSlotRequest) {
    var url = 'appointment/GetRoadShowFirstAvailableDateTimeByDuration';
    if (timeslotRequest.AppointmentDateTimeString) {
      url = url + '?AppointmentDateTime=' + timeslotRequest.AppointmentDateTimeString;
    }
    if (timeslotRequest.CrmFacilityTimeZone) {
      url = url + '&CrmFacilityTimeZone=' + timeslotRequest.CrmFacilityTimeZone;
    }
    if (timeslotRequest.CrmFacilityId) {
      url = url + '&CrmFacilityId=' + timeslotRequest.CrmFacilityId;
    }

    if (timeslotRequest.CrmTherapistId) {
      url = url + '&CrmTherapistId=' + timeslotRequest.CrmTherapistId;
    }
    if (timeslotRequest.CrmSalesorderId) {
      url = url + '&CrmSalesorderId=' + timeslotRequest.CrmSalesorderId;
    }
    if (timeslotRequest.CrmClassificationDuration) {
      url = url + '&CrmClassificationDuration=' + timeslotRequest.CrmClassificationDuration;
    }
    if (timeslotRequest.CrmPatientStateCode) {
      url = url + '&CrmPatientStateCode=' + timeslotRequest.CrmPatientStateCode;
    }
    url = url + '&isAppointment=' + timeslotRequest.isAppointment;

    if (timeslotRequest.patientTimeZone) {
      url = url + '&patientTimeZone=' + timeslotRequest.patientTimeZone;
    }
    return this.get<ResultOfT<Date>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetFacilityFirstAvailableDateTimeByDuration(timeslotRequest: FacilityTimeSlotRequest) {
    var url = 'appointment/GetFacilityFirstAvailableDateTimeByDuration';
    if (timeslotRequest.AppointmentDateTimeString) {
      url = url + '?AppointmentDateTime=' + timeslotRequest.AppointmentDateTimeString;
    }
    if (timeslotRequest.CrmFacilityTimeZone) {
      url = url + '&CrmFacilityTimeZone=' + timeslotRequest.CrmFacilityTimeZone;
    }
    if (timeslotRequest.CrmFacilityId) {
      url = url + '&CrmFacilityId=' + timeslotRequest.CrmFacilityId.toString();
    }

    if (timeslotRequest.CrmTherapistId) {
      url = url + '&CrmTherapistId=' + timeslotRequest.CrmTherapistId.toString();
    }
    if (timeslotRequest.CrmSalesorderId) {
      url = url + '&CrmSalesorderId=' + timeslotRequest.CrmSalesorderId.toString();
    }
    if (timeslotRequest.CrmClassificationDuration) {
      url = url + '&CrmClassificationDuration=' + timeslotRequest.CrmClassificationDuration.toString();
    }
    url = url + '&isAppointment=' + timeslotRequest.isAppointment;

    if (timeslotRequest.patientTimeZone) {
      url = url + '&patientTimeZone=' + timeslotRequest.patientTimeZone;
    }
    if (timeslotRequest.CrmSalesorderClassificationType) {
      url = url + '&CrmSalesorderClassificationType=' + timeslotRequest.CrmSalesorderClassificationType;
    }
    return this.get<ResultOfT<Date>>(url)
      .pipe(map((result) => {
        return result;
      }));
  }

  ExportVoidAppointments(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportVoidAppointments', searchRequestModel);
  }


  GetSalesOrderHistoryBySalesOrderId(SalesOrderId: number, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<AppointmentHistoryResponseModel[]>>('Appointment/GetSalesOrderHisotry?SalesOrderId=' + SalesOrderId);
    }
    else {
      return this.get<ResultOfT<AppointmentHistoryResponseModel[]>>('Appointment/GetSalesOrderHisotry?SalesOrderId=' + SalesOrderId + '&rnd=' + Math.random().toString());
    }
  }

  GetNotificationSMSLogsBySalesOrderId(SalesOrderId: number, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<AppointmentSMSHistoryResponseModel[]>>('Appointment/GetNotificationSMSLogsBySalesOrderId?SalesOrderId=' + SalesOrderId);
    }
    else {
      return this.get<ResultOfT<AppointmentSMSHistoryResponseModel[]>>('Appointment/GetNotificationSMSLogsBySalesOrderId?SalesOrderId=' + SalesOrderId + '&rnd=' + Math.random().toString());
    }
  }

  GetNotificationEmailLogsBySalesOrderId(SalesOrderId: number, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<AppointmentEmailHistoryResponseModel[]>>('Appointment/GetNotificationEmailLogsBySalesOrderId?SalesOrderId=' + SalesOrderId);
    }
    else {
      return this.get<ResultOfT<AppointmentEmailHistoryResponseModel[]>>('Appointment/GetNotificationEmailLogsBySalesOrderId?SalesOrderId=' + SalesOrderId + '&rnd=' + Math.random().toString());
    }
  }

  GetSalesOrderCountOnAppointmentStatus(crmBranchId: number, appuserId: number, isRefersh: boolean) {
    if (!isRefersh)
      return this.get<ResultOfT<SalesorderStatusCountModel>>('Appointment/GetSalesOrderCountOnAppointmentStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId);
    else
      return this.get<ResultOfT<SalesorderStatusCountModel>>('Appointment/GetSalesOrderCountOnAppointmentStatus?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId + '&rnd=' + Math.random().toString());
  }

  GetAppointmentsOnStatusCount(crmBranchId: number, appuserId: number, isRefresh: boolean) {
    if (!isRefresh)
      return this.get<ResultOfT<AppointmentCountResponseModel>>('Appointment/GetAppointmentsOnStatusCount?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId);
    else
      return this.get<ResultOfT<AppointmentCountResponseModel>>('Appointment/GetAppointmentsOnStatusCount?crmBranchId=' + crmBranchId + '&appUserId=' + appuserId + '&rnd=' + Math.random().toString());
  }

  ConvertRemoteAppointment(SchAppointmentId: number, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<boolean>>('Appointment/ConvertRemoteAppointment?SchAppointmentId=' + SchAppointmentId);
    }
    else {
      return this.get<ResultOfT<boolean>>('Appointment/ConvertRemoteAppointment?SchAppointmentId=' + SchAppointmentId + '&rnd=' + Math.random().toString());
    }
  }

  CheckPerDayAppointmentOfApptByClassification(crmSalesOrderId: number, AppointmentDate: string, isRefersh: boolean, patientTimezone: string) {
    return this.get<ResultOfT<boolean>>('Appointment/CheckPerDayAppointmentOfApptByClassification?SalesOrderId=' + crmSalesOrderId + '&AppointmentDate=' + AppointmentDate + '&patientTimezone=' + patientTimezone + '&rnd=' + Math.random().toString());
  }

  GetAllAppointments(searchRequestModel: SearchRequestModel) {

    var url = 'Appointment/GetAllAppointments';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;


    let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
    url = url + '&ClassificationType=' + setClassificationType;


    if (searchRequestModel.isUnreconciled != undefined) {
      url = url + '&IsUnreconciled=' + searchRequestModel.isUnreconciled;
    }
    else {
      url = url + '&IsUnreconciled=' + true;
    }

    if (searchRequestModel.isUpcoming) {
      url = url + '&IsUpcoming=' + searchRequestModel.isUpcoming;
    }
    else {
      url = url + '&IsUpcoming=' + false;
    }

    // if (searchRequestModel.BookingSearchFromDate) {
    //   url = url + '&BookingSearchFromDate=' + searchRequestModel.BookingSearchFromDate;
    // }

    // if (searchRequestModel.BookingSearchToDate) {
    //   url = url + '&BookingSearchToDate=' + searchRequestModel.BookingSearchToDate;
    // }

    // if (searchRequestModel.scheduledBy) {
    //   url = url + '&scheduledBy=' + searchRequestModel.scheduledBy;
    // }
    // if (searchRequestModel.isRescheduled) {
    //   url = url + '&isRescheduled=' + searchRequestModel.isRescheduled;
    // }
    // if (searchRequestModel.isTodaysBooking) {
    //   url = url + '&isTodaysBooking=' + searchRequestModel.isTodaysBooking;
    // }


    if (searchRequestModel.isUnreconciled) {

    } else {

    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }

    return this.get<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>(url);
    //return this.post<ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>>('Appointment/GetAllAppointments', searchRequestModel);
  }

  updateClassificationType(crmSalesOrderId: string, classificationTypeResponseModel: SettingsClassificationTypeResponseModel) {
    return this.post<ResultOfT<number>>('Appointment/UpdateClassificationType?crmSalesOrderId=' + crmSalesOrderId, classificationTypeResponseModel);
  }

  ValidateZipcodeWithState(stateName: string, zipCode: number) {
    return this.get<ResultOfT<boolean>>('Facility/ValidateZipcodeWithState/' + stateName + '/' + zipCode);
  }


  SaveAppointmentCallRecordingPlayHistory(appointmentCallRecordingPlayHistoryRequestModel: AppointmentCallRecordingPlayHistoryRequestModel) {
    return this.post<ResultOfT<AppointmentCallRecordingPlayHistoryRequestModel>>('appointmentcalls/SaveAppointmentCallRecordingPlayHistory/', appointmentCallRecordingPlayHistoryRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  SaveAppointmentCallRecordingUploadHistory(appointmentCallRecordingUploadHistoryRequestModel: AppointmentCallRecordingUploadHistoryRequestModel) {
    return this.post<ResultOfT<AppointmentCallRecordingUploadHistoryRequestModel>>('appointmentcalls/SaveAppointmentCallRecordingUploadHistory/', appointmentCallRecordingUploadHistoryRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  GetAllNotShowAppointment(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllNoShowOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex >= 0) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
    url = url + '&ClassificationType=' + setClassificationType;



    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  GetAllCancelAppointment(searchRequestModel: SearchRequestModel) {
    var url = 'Appointment/GetAllCancelOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex >= 0) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    let setPrimaryInsurance = searchRequestModel.selectedPrimaryInsurance == undefined ? "" : searchRequestModel.selectedPrimaryInsurance;
    url = url + '&SelectedPrimaryInsurance=' + setPrimaryInsurance;

    let setClassificationType = searchRequestModel.selectedClassificationType == undefined ? "" : searchRequestModel.selectedClassificationType;
    url = url + '&ClassificationType=' + setClassificationType;



    
    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  ExportCancelAppointments(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportCancelAppointments', searchRequestModel);
  }

  ExportNoShowAppointments(searchRequestModel: SearchRequestModel) {
    // tslint:disable-next-line: max-line-length
    return this.downloadCsvFile('appointment/ExportNoShowAppointments', searchRequestModel);
  }

  addUserPreferenceColumn(column: UserWiseModuleColumn[]) {
    return this.post<ResultOfT<boolean>>('appointment/AddUserPreferenceColumn', column);
  }

  updateUserPreferenceColumn(column: Array<UserWiseModuleColumn>) {
    return this.post<ResultOfT<UserWiseModuleColumn[]>>('appointment/UpdateUserPreferenceColumn', column);
  }

  getUserPreferenceMasterColumn(moduleId: number, loggedInUserId: number) {
    return this.get<ResultOfT<UserPreferencesModuleColumnResponseModel[]>>('appointment/GetUserPreferenceMasterColumn/' + moduleId + '/' + loggedInUserId);
  }
  getDistanceMatrix(origin: any, destination: any) {
    return this.get<ResultOfT<any>>('RoadShowGoogleMap/GetDrivingDistanceDetail?origin=' + origin + '&destination=' + destination);
  }

  AddRoadHomeVisitHistory(roadhomevisit: RoadHomeVisitHistoryRequestModel[]) {
    return this.post<ResultOfT<boolean>>('RoadShowGoogleMap/AddHomeVisitHistory/', roadhomevisit)
      .pipe(map((result) => {
        return result;
      }));
  }

  resendEmail(appointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel) {
    return this.post<ResultOfT<boolean>>('appointment/ResendEmail/', appointmentEmailHistoryResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  resendSMS(appointmentSMSHistoryResponseModel: AppointmentSMSHistoryResponseModel) {
    return this.post<ResultOfT<boolean>>('appointment/ResendSMS/', appointmentSMSHistoryResponseModel)
      .pipe(map((result) => {
        return result;
      }));
  }

  ChangePatientCommunicationPreferences(SchAppointmentId: number, SchPreferencesType: string, SchPreferences: boolean, isRefresh: boolean) {
    if (!isRefresh) {
      return this.get<ResultOfT<boolean>>('Appointment/ChangePatientCommunicationPreferences?SchAppointmentId=' + SchAppointmentId + '&SchPreferencesType=' + SchPreferencesType + '&SchPreferences=' + SchPreferences);
    }
    else {
      return this.get<ResultOfT<boolean>>('Appointment/ChangePatientCommunicationPreferences?SchAppointmentId=' + SchAppointmentId + '&SchPreferencesType=' + SchPreferencesType + '&SchPreferences=' + SchPreferences + '&rnd=' + Math.random().toString());
    }
  }

  GetTodaysDashboardListing(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>('Appointment/GetTodaysDashboardListing', searchRequestModel);
  }

  ExportTodaysDashboardListing(searchRequestModel: SearchRequestModel) {
    return this.downloadCsvFile('appointment/ExportTodaysDashboardListing', searchRequestModel);
  }

  GetSalesOrderDependenciesDetails(crmBranchId: number, appuserId: number, salesOrderStatus: string) {
    return this.get<ResultOfT<SalesOrderDependenciesDetails>>('Appointment/GetSalesOrderDependenciesDetails/' + crmBranchId + '/' + appuserId + '/' + salesOrderStatus);
  }
}

