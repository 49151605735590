import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OnDemandSupportRoutingModule } from './on-demand-support-routing.module';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { ThankyouComponent } from './new-patient/thankyou/thankyou.component';


@NgModule({
  declarations: [NewPatientComponent, ThankyouComponent],
  imports: [
    CommonModule,
    OnDemandSupportRoutingModule
  ]
})
export class OnDemandSupportModule { }
