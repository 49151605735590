import { Component, OnDestroy, OnInit, HostListener, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SalesOrderService } from 'src/app/patient/services/salesorder.service';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { FacilityDetailTimeSlotResponseModel, FacilitySearchResponseModel, FacilityTimeSlotResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { AppointmentRequestModel } from 'src/app/shared/models/request/appointment-request';
import { FacilityTimeSlotRequest } from 'src/app/shared/models/request/facility-slots-request';
import { DisplaySlots, DisplaySlotsTime, FacilityDisplaySlots, Months } from 'src/app/shared/models/response/appointment/display-facility-slots';
import { AppointmentConfirmation } from 'src/app/shared/models/response/appointment-confirmation';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { Constants } from 'src/app/shared/constants';
import { FacilityAvailableDays } from 'src/app/shared/models/response/appointment/facility-available-days';
import * as moment from 'moment';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { ResultOfT } from '../../shared/models/response/result';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { TherapistService } from 'src/app/shared/services/therapist.service';
import { isSameDay, isBefore } from 'date-fns';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { PatientService } from '../services/patient.service';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { TimezonePipe } from 'src/app/pipes/timezone.pipe';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as internal from 'stream';
import { PatientBookAppointmentRequest } from 'src/app/shared/models/request/patient-book-appointment-request-model';

import { MediaMatcher } from '@angular/cdk/layout';
@Component({
  selector: 'app-confirm-date-time-slot',
  templateUrl: './confirm-date-time-slot.component.html',
  styleUrls: ['./confirm-date-time-slot.component.sass'],
  providers: [DialogService]
})
export class ConfirmDateTimeSlotComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  title = "Select Date & Time";
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  facilitySearchResponseModel: FacilitySearchResponseModel[];
  facilitySearchRequestModel: FacilitySearchRequestModel;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  selectedwipStatusBTMappingResponseModel: WipStatusBTmapping;
  reminderDays: string;
  salesOrderLocal: SalesOrderLocalModel;
  salesOrderData: SalesOrderResponseModel;
  patientName: string;
  description: string;
  duration: string;
  durationInMinutes: number;
  dueAmount: number;
  selectedDate: string;
  selectedFromTime: string;
  selectedToTime: string;
  crmFacilityDistanceInMile: string;
  crmFacilityAddress: string;
  crmFacilityAddressLine1: string;
  crmFacilityAddressLine2: string;
  crmFacilityCity: string;
  crmFacilityState: string;
  crmFacilityCountry: string;
  crmFacilityZipCode: string;
  crmFacilityName: string;
  crmFacilityLatitude: string;
  crmFacilityLongitude: string;
  crmBioData: string;
  crmFacilityWeekDaysAvailability: string;
  crmFacilityWeekendAvailability: string;
  selectedFacility: Array<FacilitySearchResponseModel> = [];
  selectedTherapist: TherapistResponseModel;
  selectedFacilityLocal: FacilitySearchResponseModel;
  appointmentRequest: AppointmentRequestModel;
  selectedMonth: Months;
  selectedStartDate: Date;
  displaySelectedStartDate: Date;
  selectedOrigStartDate: Date;
  selectedOrigEndDate: Date;
  selectedTherapistId: string;
  selectedEndDate: Date;
  facilityAvailableWeekDays: FacilityAvailableDays[];
  facilityAvailableWeekend: FacilityAvailableDays[];
  selectedFacilityWeekDayMinDate: FacilityAvailableDays;
  selectedFacilityWeekDayMaxDate: FacilityAvailableDays;
  selectedFacilityWeekendMinDate: FacilityAvailableDays;
  selectedFacilityWeekendMaxDate: FacilityAvailableDays;
  reminderOpted: boolean = false;
  termCondtionSelected: boolean = false;
  facilitySlotRequest: FacilityTimeSlotRequest;
  facilitySlotResponse: FacilitySearchResponseModel;
  facilitySlotForFilter: FacilityTimeSlotResponseModel[];
  facilitySlotFiltered: FacilityTimeSlotResponseModel[];
  displaySlots: DisplaySlots[] = [];
  displaySlotsMobile: DisplaySlots[] = [];
  displaySlot: DisplaySlots;
  facilityDisplaySlot: FacilityDisplaySlots[];
  displaySlotsTime: DisplaySlotsTime;
  prevdisplaySlotsTime: DisplaySlotsTime;
  prevdisplayDate: DisplaySlots;
  facilitySlotDate: Date = new Date();
  months: Months[] = [];
  month: Months;
  showPrev: boolean = false;
  isDefaultWIPNeededDateAppointmentdate: boolean = false;
  today: Date;
  appointmentConfirmation: AppointmentConfirmation;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string; displayCode: string; }[];
  timeZone1: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string; displayCode: string; }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string; displayCode: string; };
  defaultselectedTimeZone: { name: string; code: string; shortName: string; displayName: string; countryName: string; displayCode: string; };
  AgreeTermsConditionsPopup: boolean = false;
  checkTimeZone: string;
  status: boolean;
  monthsToShow: number;
  daysToShow: number;
  OpenCheck: boolean = false;
  showConfirm: boolean = false;
  email: string = "";
  emailConfirm: string = "";
  emailRequired: boolean = false;
  emailConfirmRequired: boolean = false;
  emailMatch: boolean = false;
  emailRegex: boolean = false;
  emailConfirmRegex: boolean = false;
  dateFormat: string = '';
  patientBookingTypeId: string = '';
  currenRoutertUrl: string = '';
  selctedCrmClassificationDuration = 30;
  remoteAppointmentTypeModel: RemoteAppointmentTypeModel[];
  selectedRemoteAppointmentType: string = '';
  therapistName: string = '';
  islotAvailable: boolean = true;
  appDateFormat: DateFormatModel;
  isBillingAddress: boolean = false;
  patientsDefaultAppointmentLocation: string;
  previousPatientsDefaultAppointmentLocation: string;
  isTherapistFacilityType: boolean = false;
  isTherapistRemoteType: boolean = false;
  isTherapistRemoteAndFaciltyType: boolean = false;
  selectedAppointmentTypePopUp: string = '';
  selectedFacilityLocalcrmFacilityId: number = 0;
  selectedFacilityLocalcrmRemoteFacilityId: number = 0;
  patientPhone: string = "";
  isBrightreeSystemAppUpdate: boolean = false;
  patientMobileNumber: string = "";
  patientTimeZone: string = "";
  selectedFacilityTimeZone: string = "";
  //NEW fileds added for month drop-down
  maxMonthAvailability: Date;
  isAvailableSlot: boolean = true;
  crmFirstAvailableStartDate: Date;
  monthNames: string[];
  monthNamesNewFormat: string[];
  previousSelectedMonth: Months;
  minAvailabilityDate: Date;
  orginalmonths: Months[] = [];
  showNext: boolean = true;

  displayAddressToolTip: boolean = false;
  minAllowDateToBookAppoitment: Date = new Date();
  maxAllowDateToBookAppoitment: Date = new Date();
  isProvisional: boolean;
  date14: Date;
  isChecked: boolean = true;
  showsidebar: boolean = false;
  DMENumber: string = '';
  @Output() activeStepNoEmit: EventEmitter<number> = new EventEmitter();
  @Output() isFacilityOrTherapistSelectionChangedEmit: EventEmitter<boolean> = new EventEmitter();
  @Output() patientBookAppointmentRequestEmit: EventEmitter<PatientBookAppointmentRequest> = new EventEmitter();
  @Input() isFacilityOrTherapistSelectionChanged: boolean;

  isDrFacilityVisitOnly: boolean = false;
  isDrRemoteVisitOnly: boolean = false;
  isDrRoadHomeVisitOnly: boolean = false;
  isGlobalHoliday: boolean = false;
  globalHolidayMsg: string;
  
  slotDurationDefault:number;
  isSlotDurationBasedOnClassification:boolean = false;
  slotDurationIfClassificationNotMap:number;
  useThisSlotDuration:number = 0;
  mediaQueryListener: () => void;
  mdq: MediaQueryList;
  calendarView: number = 3;

  isPODStatusBrightreeSystemAppUpdate: boolean = false;
  isAppendOrderNoteBrightreeSystemAppUpdate: boolean = false;

  AgreeTermsConditionsClick() {
    this.AgreeTermsConditionsPopup = true;
  }
  onSelectTerms() {
    if (this.termCondtionSelected)
      this.showConfirm = true;
    else
      this.showConfirm = false;
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    //do nothing
  }
  constructor(
    private webStorageService: WebStorageService,
    private salesorderService: SalesOrderService,
    private router: Router,
    private appointmentService: AppointmentService,
    private patientService: PatientService,
    private settingService: SettingsService,
    @Inject(LOCALE_ID) private locale: string,
    messageService: MessageService
  ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.dateFormat = this.appDateFormat.dateFormat;
    this.DMENumber = this.webStorageService.getDMENumber();

    let settingResult = this.webStorageService.getPatientSalesOrderSettings();
    if (settingResult) {
      this.reminderDays = settingResult.reminderDays;
      this.daysToShow = parseInt(settingResult.numOfMonths, 10);
      this.monthsToShow = this.daysToShow / 30;
      this.maxAllowDateToBookAppoitment.setDate(this.maxAllowDateToBookAppoitment.getDate() + this.daysToShow);

      if (settingResult.defaultWIPNeededDate == Constants.SettingValues.appointmentdate) {
        this.isDefaultWIPNeededDateAppointmentdate = true;
      }
      else {
        this.isDefaultWIPNeededDateAppointmentdate = false;
      }

      this.patientsDefaultAppointmentLocation = settingResult.patientsDefaultAppointmentLocation;
      this.previousPatientsDefaultAppointmentLocation = settingResult.patientsDefaultAppointmentLocation;
      if (this.patientsDefaultAppointmentLocation.toLowerCase() == 'billingaddress') {
        this.isBillingAddress = true;
      }
      else {
        this.isBillingAddress = false;
      }

      this.isBrightreeSystemAppUpdate = settingResult.allowUpdateAppointmentToBrightTreeSystem.toLowerCase() == 'true';
      this.isPODStatusBrightreeSystemAppUpdate = settingResult.allowUpdatePODStatusToBrightTree.toLowerCase() == 'true';
      this.isAppendOrderNoteBrightreeSystemAppUpdate = settingResult.allowAppendOrderNoteToBrightTree.toLowerCase() == 'true';

      this.slotDurationDefault = settingResult.slotDurationDefault;
      this.isSlotDurationBasedOnClassification = settingResult.isSlotDurationBasedOnClassification.toLowerCase() == 'true';
      this.slotDurationIfClassificationNotMap = settingResult.slotDurationIfClassificationNotMap;      
    }

    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
  }

  clickEvent() {
    this.status = !this.status;
  }

  async ngOnInit() {
    this.loading = true;
    this.loadingCustom = false;
    if (this.webStorageService.getAppointmentConfirmation()) {
      this.router.navigate(['/patient/appointment-confirmation']);
      return;
    }
    this.salesOrderLocal = this.webStorageService.getSalesOrderId();
    //this.salesOrderData = this.webStorageService.getSalesOrderData();
    if (!this.salesOrderLocal) {
      this.showWarningMessage("No sales order selected.");
    }
    this.email = this.salesOrderLocal.patientEmail;
    this.patientPhone = this.salesOrderLocal.patientPhone;
    this.patientMobileNumber = this.salesOrderLocal.patientMobile;
    this.salesOrderData = this.webStorageService.getSalesOrderData();

    if (this.salesOrderData.crmDoctorId != undefined && this.salesOrderData.crmDoctorId != null) {
      if (this.salesOrderData.crmIsFacilityVisitSelected)
        this.isDrFacilityVisitOnly = true;

      if (this.salesOrderData.crmIsRemoteVisitSelected)
        this.isDrRemoteVisitOnly = true;

      if (this.salesOrderData.crmIsRoadHomeVisitSelected)
        this.isDrRoadHomeVisitOnly = true;
    }
    else {
      this.isDrFacilityVisitOnly = true;
      this.isDrRemoteVisitOnly = true;
      this.isDrRoadHomeVisitOnly = true;
    }

    //intalize the Time Zone List
    this.initializePatientTimeZone();
    this.initializeAllDefaultModel();

    this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
    this.description = this.salesOrderData.crmClassificationDescription;
    this.dueAmount = this.salesOrderData.amountDue;
    this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
    // this.durationInMinutes = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? 60 : this.salesOrderData.crmClassificationDuration;
  
    if (this.isSlotDurationBasedOnClassification == true)
        this.useThisSlotDuration = this.salesOrderData.crmClassificationDuration == 1 ? this.slotDurationIfClassificationNotMap : this.salesOrderData.crmClassificationDuration;
    else
      this.useThisSlotDuration = this.slotDurationDefault;

    //this.duration = this.useThisSlotDuration.toString() + " Minutes";
    this.durationInMinutes = this.useThisSlotDuration;


    this.currenRoutertUrl = this.webStorageService.getPatientBookingUrl();
    this.patientBookingTypeId = this.webStorageService.getPatientBookingTypeId();
    this.wipStatusBTMappingResponseModel = this.webStorageService.getWipStatusBtmap();

    await this.LoadSlots();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === 'isFacilityOrTherapistSelectionChanged') {
        if (changes[property].currentValue) {
          await this.LoadSlots();
        }
      }
    }
  }

  private async LoadSlots() {
    //for clinic facilities
    if (this.patientBookingTypeId === 'F') {
      this.selectedFacility = [];
      let selectedFacilities = this.webStorageService.getSelectedFacilies();
      selectedFacilities?.forEach(value => {
        if (value.crmFacilityId > 0) {
          this.selectedFacility.push(value)
        }

      });
      //this.webStorageService.deleteSelectedFacilies();
      this.fetchFacilityData();
    }

    //for remote facilities
    if (this.patientBookingTypeId === 'R') {
      await this.getRemoteAppintmentTypes();
      // this.selectedFacility = this.webStorageService.getSelectedFacilies();
    }

    //for self guided  facilities
    if (this.patientBookingTypeId === 'SG') {
      await this.getRemoteAppintmentTypes();
      this.duration = 15 + " Minutes";
      this.durationInMinutes = 15;
      // this.selectedFacility = this.webStorageService.getSelectedFacilies();
    }

    //for therapist facilities
    if (this.patientBookingTypeId === 'T') {
      this.selectedTherapist = this.webStorageService.getSelectedTherapist();
      this.therapistName = this.selectedTherapist?.appUserFullname;
      let therapistFirstAvailableDate = this.selectedTherapist?.crmFacilityFirstAvailableStartDate;
      this.crmFirstAvailableStartDate = this.selectedTherapist?.crmFacilityFirstAvailableStartDate;
      this.minAllowDateToBookAppoitment = new Date(this.crmFirstAvailableStartDate);
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      if (therapistFirstAvailableDate != undefined) {
        this.facilitySlotDate = new Date(therapistFirstAvailableDate);
        this.minAllowDateToBookAppoitment = this.facilitySlotDate != null ? new Date(this.facilitySlotDate) : new Date();
        this.GetTimeSlotsAvailabilityByTherapist(this.facilitySlotDate);
      }


      //this.getMonths();
    }
  }

  async fetchFacilityData() {
    if (!this.selectedFacility) {
      this.showWarningMessage("No facilities selected.");
    }
    else {
      this.selectedFacility.forEach((value) => {
        if (!value.crmFacilityId) {
          const index = this.selectedFacility.indexOf(value, 0);
          if (index > -1) {
            this.selectedFacility.splice(index, 1);
          }
        }
      });
    }
    //await this.getSalesOrderData();

    this.wipStatusBTMappingResponseModel = this.webStorageService.getWipStatusBtmap();

    //await this.getWipStatusBTmapping();

    this.selectedFacilityLocal = this.selectedFacility[0];
    this.FetchSelectedFacilityData();

    if (this.facilityAvailableWeekDays != undefined) {
      let mostMinDate = new Date(Math.min.apply(null, this.facilityAvailableWeekDays.map(e => {
        return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
      })));

      if (!isNaN(mostMinDate.getTime())) {
        this.selectedFacilityWeekDayMinDate = this.facilityAvailableWeekDays.filter(e => {
          var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
          return d.getDate() == mostMinDate.getDate();
        })[0];
      }

      let mostMaxDate = new Date(Math.max.apply(null, this.facilityAvailableWeekDays.map(e => {
        return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
      })));

      if (!isNaN(mostMaxDate.getTime())) {
        this.selectedFacilityWeekDayMaxDate = this.facilityAvailableWeekDays.filter(e => {
          var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
          return d.getDate() == mostMaxDate.getDate();
        })[0];
      }
    }

    if (this.facilityAvailableWeekend != undefined) {
      let mostMinWeekendDate = new Date(Math.min.apply(null, this.facilityAvailableWeekend.map(e => {
        return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
      })));

      if (!isNaN(mostMinWeekendDate.getTime())) {
        this.selectedFacilityWeekendMinDate = this.facilityAvailableWeekend.filter(e => {
          var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
          return d.getDate() == mostMinWeekendDate.getDate();
        })[0];
      }

      let mostMaxWeekendDate = new Date(Math.max.apply(null, this.facilityAvailableWeekend.map(e => {
        return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
      })));

      if (!isNaN(mostMaxWeekendDate.getTime())) {
        this.selectedFacilityWeekendMaxDate = this.facilityAvailableWeekend.filter(e => {
          var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
          return d.getDate() == mostMaxWeekendDate.getDate();
        })[0];
      }
    }
    //let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;


    this.facilitySlotResponse = new FacilitySearchResponseModel();
    this.facilitySlotResponse.timeSlotes = new FacilityDetailTimeSlotResponseModel();

    if (this.selectedFacility.length > 0) {
      let firstAvailabilityDate = new Date(Math.min.apply(null, this.selectedFacility.map(e => {
        return moment(e.crmFacilityFirstAvailableStartDate, 'YYYY-MM-DD HH:mm').toDate();
      })));

      //let firstAvailabilityDate = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate : new Date();
      this.facilitySlotDate = new Date(firstAvailabilityDate);
      this.minAllowDateToBookAppoitment = new Date(firstAvailabilityDate);
    }
    //this.minAllowDateToBookAppoitment = this.facilitySlotDate;
    //adding the min date
    this.minAvailabilityDate = this.facilitySlotDate;
    this.today = this.facilitySlotDate;
    //for clinic facility
    if (this.patientBookingTypeId === 'F') {
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      this.getFacilitySlots(this.facilitySlotDate);
      this.selectedFacility = [];
      let selectedFacilities = this.webStorageService.getSelectedFacilies();
      selectedFacilities?.forEach(value => {
        if (value.crmFacilityId > 0) {
          this.selectedFacility.push(value)
        }

      });
      //this.webStorageService.deleteSelectedFacilies();
      //this.selectedFacility = this.webStorageService.getSelectedFacilies();
    }

    if (this.patientBookingTypeId === 'R') {
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      this.getRemoteFacilitySlots(this.facilitySlotDate);
      //this.selectedFacility = this.webStorageService.getSelectedFacilies();
    }

    if (this.patientBookingTypeId === 'SG') {
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      this.getRemoteFacilitySlots(this.facilitySlotDate);
    }
  }

  FetchSelectedFacilityData() {
    this.crmFacilityDistanceInMile = this.selectedFacilityLocal?.crmFacilityDistanceInMiles;
    this.crmFacilityAddress = this.selectedFacilityLocal?.crmFacilityAddress;
    this.crmFacilityAddressLine1 = this.selectedFacilityLocal?.crmFacilityAddressLine1;
    this.crmFacilityAddressLine2 = this.selectedFacilityLocal?.crmFacilityAddressLine2;
    this.crmFacilityCity = this.selectedFacilityLocal?.crmFacilityCity;
    this.crmFacilityState = this.selectedFacilityLocal?.crmFacilityState;
    this.crmFacilityCountry = this.selectedFacilityLocal?.crmFacilityCountry;
    this.crmFacilityZipCode = this.selectedFacilityLocal?.crmFacilityZipCode;
    this.crmFacilityName = this.selectedFacilityLocal?.crmFacilityName;
    this.crmFacilityLatitude = this.selectedFacilityLocal?.crmFacilityLatitude;
    this.crmFacilityLongitude = this.selectedFacilityLocal?.crmFacilityLongitude;
    this.crmBioData = this.selectedFacilityLocal?.crmBioData;
    this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal?.crmFacilityWeekDaysAvailability;
    this.crmFacilityWeekendAvailability = this.selectedFacilityLocal?.crmFacilityWeekendAvailability;
    this.facilityAvailableWeekDays = this.selectedFacilityLocal?.facilityAvailableWeekDays;
    this.facilityAvailableWeekend = this.selectedFacilityLocal?.facilityAvailableWeekend;
  }

  async getRemoteAppintmentTypes() {
    this.loading = true;
    this.showsidebar = true;
    this.settingService.getActiveRemoteAppointmentType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.remoteAppointmentTypeModel = result.responseModel;
          if (this.remoteAppointmentTypeModel.length > 0) {
            let remoteAppointmentTypeModel = this.remoteAppointmentTypeModel.filter(x => x.crmRemoteAppointmentType != "SelfGuided");
            this.remoteAppointmentTypeModel = [];
            this.remoteAppointmentTypeModel = remoteAppointmentTypeModel;

            let checkTeleMed = this.remoteAppointmentTypeModel.find(x => x.crmRemoteAppointmentType == 'Telemed');
            if (checkTeleMed) {
              this.selectedRemoteAppointmentType = 'Telemed';
            }
            else {
              this.selectedRemoteAppointmentType = 'OnlineMeeting';
            }
          }

          if (!this.isTherapistRemoteType) {
            this.getRemoteFacilitiesByAddress(this.salesOrderLocal.salesOrderId);
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onTimeZoneChange() {
  }

  onDateSelection() {
    if (this.patientBookingTypeId === 'F') {
      this.getFacilitySlots(this.facilitySlotDate);
    }

    if (this.patientBookingTypeId === 'R' || this.patientBookingTypeId === 'SG') {
      this.getRemoteFacilitySlots(this.facilitySlotDate);
    }
    if (this.patientBookingTypeId === 'T') {
      this.GetTimeSlotsAvailabilityByTherapist(this.facilitySlotDate);
    }

    if (this.displaySlots) {
      this.displaySlotsMobile = this.displaySlots.filter(x => x.date == this.facilitySlotDate);
    }
    if (this.prevdisplayDate) {
      this.prevdisplayDate.isToday = false;
    }
    //this.displaySlotsMobile[0].isToday = true;
    this.prevdisplayDate = this.displaySlotsMobile[0];

    this.clearSelection();
  }

  onChange(event) {
    this.setFacilityData();

    //let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;
    //this.firstAvailabilityDate = firstAvailabilityDate;
    //this.facilitySlotDate = new Date(firstAvailabilityDate)
    //this.today = this.facilitySlotDate;
    //var iCounter = this.facilitySlotDate.getMonth();

    this.selectedFacilityLocal = event.value;
    let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;
    this.facilitySlotDate = new Date(firstAvailabilityDate)
    this.minAllowDateToBookAppoitment = new Date(firstAvailabilityDate);
    this.today = this.facilitySlotDate;
    this.crmFacilityName = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityName : '';
    this.crmFacilityDistanceInMile = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityDistanceInMiles : '';
    this.crmFacilityAddress = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityAddress : '';
    this.crmFacilityAddressLine1 = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityAddressLine1 : '';
    this.crmFacilityAddressLine2 = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityAddressLine2 : '';
    this.crmFacilityCity = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityCity : '';
    this.crmFacilityState = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityState : '';
    this.crmFacilityCountry = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityCountry : '';
    this.crmFacilityZipCode = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal?.crmFacilityZipCode : '';
    this.crmFacilityLatitude = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityLatitude : '0';
    this.crmFacilityLongitude = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityLongitude : '0';
    this.crmBioData = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmBioData : '';
    this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityWeekDaysAvailability : '';
    this.crmFacilityWeekendAvailability = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.crmFacilityWeekendAvailability : '';
    this.facilityAvailableWeekDays = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.facilityAvailableWeekDays : [];
    this.facilityAvailableWeekend = this.selectedFacilityLocal != undefined ? this.selectedFacilityLocal.facilityAvailableWeekend : [];
    if (this.patientBookingTypeId === 'F') {
      this.getFacilitySlots(this.facilitySlotDate);
    }

    if (this.patientBookingTypeId === 'R' || this.getRemoteFacilitySlots(this.facilitySlotDate)) {
      //this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      //this.selctedCrmClassificationDuration = this.selectedRemoteAppointmentType == "SelfGuided" ? 15 : this.salesOrderData.crmClassificationDuration;
      this.getRemoteFacilitySlots(this.facilitySlotDate);
    }

    if (this.patientBookingTypeId === 'T') {
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      this.facilitySlotDate = new Date();
      this.GetTimeSlotsAvailabilityByTherapist(this.facilitySlotDate);

    }

    // this.getFacilitySlots(this.facilitySlotDate);
    this.clearSelection();
  }

  private getFacilitySlots(facilityDate: Date) {
    this.loading = true;
    this.displaySlots = [];

    let facilityMultiSlotRequest: FacilityTimeSlotRequest[];
    facilityMultiSlotRequest = [];
    this.selectedFacility.forEach((value) => {
      this.facilitySlotRequest = new FacilityTimeSlotRequest();
      this.facilitySlotRequest.AppointmentDateTime = facilityDate;
      this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
      //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
      this.facilitySlotRequest.CrmFacilityId = value.crmFacilityId;
      this.facilitySlotRequest.CrmSalesorderId = parseInt(this.salesOrderLocal.salesOrderId, 10);
      this.facilitySlotRequest.CrmClassificationDuration = this.facilitySlotRequest.CrmClassificationDuration = this.selctedCrmClassificationDuration;
      if (this.isBillingAddress)
        this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.patientInfo.crmState;
      else
        this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.crmDeliveryAddressState;
      this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
      //this.checkTimeZone = this.facilitySlotRequest.patientTimeZone;
      this.checkTimeZone = this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code;
      this.facilitySlotRequest.CrmFacilityTimeZone = value.crmTimeZone;
      this.facilitySlotRequest.isAppointment = this.salesOrderData.schAppointmentId > 0 ? true : false;
      this.facilitySlotRequest.CrmFacilityState = value.crmFacilityState;
      facilityMultiSlotRequest.push(this.facilitySlotRequest);
    });

    this.patientService.getMultiFacilityTimeSlots(facilityMultiSlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            //this.minAllowDateToBookAppoitment = new Date(result.responseModel.crmFacilityFirstAvailableStartDate);
            //this.facilitySlotDate = new Date(result.responseModel.crmFacilityFirstAvailableStartDate);
            this.facilityDisplaySlot = [];
            result.responseModel.forEach((responsevalue) => {
              this.facilitySlotResponse = responsevalue;
              this.facilitySlotForFilter = responsevalue.timeSlotes.facilityTimeSlots;

              this.onApptDurationChangeSlot();

              var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
              this.slotLockBasedOnAppoitmentDuration(comparetime);

              let facilityslot = new FacilityDisplaySlots();
              facilityslot.facilityname = responsevalue.crmFacilityName;
              facilityslot.isGlobalHoliday = responsevalue.timeSlotes.isHoliday;
              facilityslot.globalHolidayMsg = responsevalue.timeSlotes.holidayMessage;
              facilityslot.displaySlot = null;
              facilityslot.facilitySlotForFilter = [];
              this.facilitySlotResponse.timeSlotes.facilityTimeSlots.forEach((value) => {
                this.facilitySlotFiltered = this.facilitySlotForFilter.filter(x => x.appintmentDate === value.appintmentDate);
                if (this.facilitySlotFiltered.length > 0) {
                  this.displaySlot = new DisplaySlots();
                  this.displaySlot.date = new Date(this.facilitySlotFiltered[0].appintmentDate);
                  var compareDate = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.checkTimeZone);
                  if (this.displaySlot.date.toDateString() == compareDate) {
                    this.displaySlot.isToday = true;
                    this.prevdisplayDate = this.displaySlot;
                    this.displaySlot.isDisabled = false;
                  }
                  else if (moment(compareDate).toDate() > this.displaySlot.date) {
                    this.displaySlot.isToday = false;
                    this.displaySlot.isDisabled = true;
                  }
                  else {
                    this.displaySlot.isToday = false;
                    this.displaySlot.isDisabled = false;
                  }
                  this.displaySlot.time = [];
                  this.facilitySlotFiltered.forEach((valueFilter) => {
                    this.displaySlotsTime = new DisplaySlotsTime();
                    this.displaySlotsTime.origStartTime = valueFilter.origStartTime
                    this.displaySlotsTime.origEndTime = valueFilter.origEndTime
                    this.displaySlotsTime.dayTime = new Date(valueFilter.startTime.toString());
                    this.displaySlotsTime.displayStartTime = new Date(valueFilter.displayStartTime.toString());
                    this.displaySlotsTime.therapistIds = valueFilter.therapistIds;
                    this.displaySlotsTime.isBlock1 = valueFilter.isBlock1 || valueFilter.isHideSlot?true:false;
                    this.displaySlotsTime.therapistTimeZone = valueFilter.therapistTimeZone;
                    var comparetime = new DatePipe(this.locale).transform(new Date(), this.dateFormat + " HH:mm", this.checkTimeZone);
                    this.displaySlotsTime.isDisabled = this.isSlotDisabled(this.displaySlotsTime.displayStartTime);

                    let comparedate = moment(comparetime).toDate();
                    if (this.displaySlotsTime.displayStartTime < comparedate) {
                      this.displaySlotsTime.isDisabled = true;
                    }
                    else {
                      this.displaySlotsTime.isDisabled = false;
                    }

                    if (valueFilter.isAppointment || valueFilter.isBreak || valueFilter.isBlock) {
                      this.displaySlotsTime.isBlock1 = true;
                      this.displaySlotsTime.isDisabled = true;
                    }

                    this.displaySlotsTime.isSelected = false;


                    let addSlot: boolean = true;
                    if (this.displaySlotsTime.isDisabled == true)
                      addSlot = false;

                    if (this.displaySlotsTime.isBlock1 == true)
                      addSlot = false;


                    if (addSlot)
                      this.displaySlot.time.push(this.displaySlotsTime);



                    const index: number = this.facilitySlotForFilter.indexOf(valueFilter);
                    if (index !== -1) {
                      this.facilitySlotForFilter.splice(index, 1);
                    }
                  });

                  this.displaySlots.push(this.displaySlot);
                  let flteredDisplaySlots = this.displaySlots.filter(x => x.isDisabled == false);
                  this.displaySlots = [];
                  this.displaySlots = flteredDisplaySlots;

                  facilityslot.displaySlot = this.displaySlot;
                  facilityslot.facilitySlotForFilter = this.facilitySlotForFilter;

                  let isAllSlotsBooked = facilityslot.displaySlot.time.filter(x => x.isDisabled);
                  if (isAllSlotsBooked != undefined && isAllSlotsBooked != null && isAllSlotsBooked?.length == facilityslot?.displaySlot?.time?.length) {
                    facilityslot.isAllSlotsBooked = true;
                  }
                  else {
                    facilityslot.isAllSlotsBooked = false;
                  }

                  if (this.displaySlot.isToday) {
                    this.displaySlotsMobile = [];
                    this.displaySlotsMobile.push(this.displaySlot);
                    this.displaySlotsMobile = this.displaySlotsMobile.filter(x => x.isDisabled == false);
                  }
                }
              });
              this.facilityDisplaySlot.push(facilityslot);

              //check for the previous and next icons
              this.checkforthePrevFlag(this.facilitySlotDate, this.minAvailabilityDate);
            });
          }
        }
        else {

        }
        this.loading = false;
      });
  }

 
  slotLockBasedOnAppoitmentDuration(comparetime: any) {
    if (this.facilitySlotForFilter) {
      for (let k = 0; k < this.facilitySlotForFilter.length; k++) {
        //let timeSlot = this.facilitySlotResponse.timeSlotes[k];        
        if (this.durationInMinutes == 30 || this.durationInMinutes == 45 ||
          this.durationInMinutes == 60 || this.durationInMinutes == 75 ||
          this.durationInMinutes == 90 || this.durationInMinutes == 105 || this.durationInMinutes == 120)  {
          
          if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null ||
            this.facilitySlotForFilter[k + 3] == null ||  this.facilitySlotForFilter[k + 4] == null ||  this.facilitySlotForFilter[k + 5] == null ||
              this.facilitySlotForFilter[k + 6] == null ||  this.facilitySlotForFilter[k + 7] == null) && this.durationInMinutes == 120) {
            this.facilitySlotForFilter[k].isBlock1 = true;
          } 
          else if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null ||
            this.facilitySlotForFilter[k + 3] == null ||  this.facilitySlotForFilter[k + 4] == null ||  this.facilitySlotForFilter[k + 5] == null ||
              this.facilitySlotForFilter[k + 6] == null) && this.durationInMinutes == 105) {
            this.facilitySlotForFilter[k].isBlock1 = true;
          }          
          else if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null ||
          this.facilitySlotForFilter[k + 3] == null ||  this.facilitySlotForFilter[k + 4] == null ||  this.facilitySlotForFilter[k + 5] == null) && this.durationInMinutes == 90) {
          this.facilitySlotForFilter[k].isBlock1 = true;
          }          
          else if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null ||
          this.facilitySlotForFilter[k + 3] == null ||  this.facilitySlotForFilter[k + 4] == null) && this.durationInMinutes == 75) {
          this.facilitySlotForFilter[k].isBlock1 = true;
          }          
          else if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null ||
            this.facilitySlotForFilter[k + 3] == null) && this.durationInMinutes == 60) {
            this.facilitySlotForFilter[k].isBlock1 = true;
          }
          else if ((this.facilitySlotForFilter[k + 1] == null || this.facilitySlotForFilter[k + 2] == null) && this.durationInMinutes == 45) {
            this.facilitySlotForFilter[k].isBlock1 = true;
          }
          else if (this.facilitySlotForFilter[k + 1] == null && this.durationInMinutes == 30) {
            this.facilitySlotForFilter[k].isBlock1 = true;
          }
          
          if (this.facilitySlotForFilter[k + 7] != null && this.durationInMinutes == 120) {
            if ((this.facilitySlotForFilter[k + 7].isAppointment || this.facilitySlotForFilter[k + 7].isBlock || this.facilitySlotForFilter[k + 7].isBreak)
            && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 5].isAppointment
                && !this.facilitySlotForFilter[k + 5].isBlock
                && !this.facilitySlotForFilter[k + 5].isBreak) {
                this.facilitySlotForFilter[k + 5].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 5].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 6].isAppointment
                && !this.facilitySlotForFilter[k + 6].isBlock
                && !this.facilitySlotForFilter[k + 6].isBreak) {
                this.facilitySlotForFilter[k + 6].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 6].isBlock1 = false;
              }
            }		
            else if ((this.facilitySlotForFilter[k + 6].isAppointment || this.facilitySlotForFilter[k + 6].isBlock || this.facilitySlotForFilter[k + 6].isBreak)
                        && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 5].isAppointment
                && !this.facilitySlotForFilter[k + 5].isBlock
                && !this.facilitySlotForFilter[k + 5].isBreak) {
                this.facilitySlotForFilter[k + 5].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 5].isBlock1 = false;
              }
            }			  
      
            else if ((this.facilitySlotForFilter[k + 5].isAppointment || this.facilitySlotForFilter[k + 5].isBlock || this.facilitySlotForFilter[k + 5].isBreak)
                  && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }				
            }
            else if ((this.facilitySlotForFilter[k + 4].isAppointment || this.facilitySlotForFilter[k + 4].isBlock || this.facilitySlotForFilter[k + 4].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
            }
    
            else if ((this.facilitySlotForFilter[k + 3].isAppointment || this.facilitySlotForFilter[k + 3].isBlock || this.facilitySlotForFilter[k + 3].isBreak)
                && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
            if (!this.facilitySlotForFilter[k].isAppointment
              && !this.facilitySlotForFilter[k].isBlock
              && !this.facilitySlotForFilter[k].isBreak) {
              this.facilitySlotForFilter[k].isBlock1 = true;
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
            if (!this.facilitySlotForFilter[k + 1].isAppointment
              && !this.facilitySlotForFilter[k + 1].isBlock
              && !this.facilitySlotForFilter[k + 1].isBreak) {
              this.facilitySlotForFilter[k + 1].isBlock1 = true;
            }
            else {
              this.facilitySlotForFilter[k + 1].isBlock1 = false;
            }
            }
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }
          
          if (this.facilitySlotForFilter[k + 6] != null && this.durationInMinutes == 105) {
            if ((this.facilitySlotForFilter[k + 6].isAppointment || this.facilitySlotForFilter[k + 6].isBlock || this.facilitySlotForFilter[k + 6].isBreak)
                        && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 5].isAppointment
                && !this.facilitySlotForFilter[k + 5].isBlock
                && !this.facilitySlotForFilter[k + 5].isBreak) {
                this.facilitySlotForFilter[k + 5].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 5].isBlock1 = false;
              }
            }			  
      
            else if ((this.facilitySlotForFilter[k + 5].isAppointment || this.facilitySlotForFilter[k + 5].isBlock || this.facilitySlotForFilter[k + 5].isBreak)
                  && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }				
            }
            else if ((this.facilitySlotForFilter[k + 4].isAppointment || this.facilitySlotForFilter[k + 4].isBlock || this.facilitySlotForFilter[k + 4].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
            }
    
            else if ((this.facilitySlotForFilter[k + 3].isAppointment || this.facilitySlotForFilter[k + 3].isBlock || this.facilitySlotForFilter[k + 3].isBreak)
                && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
            if (!this.facilitySlotForFilter[k].isAppointment
              && !this.facilitySlotForFilter[k].isBlock
              && !this.facilitySlotForFilter[k].isBreak) {
              this.facilitySlotForFilter[k].isBlock1 = true;
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
            if (!this.facilitySlotForFilter[k + 1].isAppointment
              && !this.facilitySlotForFilter[k + 1].isBlock
              && !this.facilitySlotForFilter[k + 1].isBreak) {
              this.facilitySlotForFilter[k + 1].isBlock1 = true;
            }
            else {
              this.facilitySlotForFilter[k + 1].isBlock1 = false;
            }
            }
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }

          if (this.facilitySlotForFilter[k + 5] != null && this.durationInMinutes == 90) {
            if ((this.facilitySlotForFilter[k + 5].isAppointment || this.facilitySlotForFilter[k + 5].isBlock || this.facilitySlotForFilter[k + 5].isBreak)
                        && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 4].isAppointment
                && !this.facilitySlotForFilter[k + 4].isBlock
                && !this.facilitySlotForFilter[k + 4].isBreak) {
                this.facilitySlotForFilter[k + 4].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 4].isBlock1 = false;
              }				
            }
           else if ((this.facilitySlotForFilter[k + 4].isAppointment || this.facilitySlotForFilter[k + 4].isBlock || this.facilitySlotForFilter[k + 4].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                && !this.facilitySlotForFilter[k + 3].isBlock
                && !this.facilitySlotForFilter[k + 3].isBreak) {
                this.facilitySlotForFilter[k + 3].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 3].isBlock1 = false;
              }
            }
          
            else if ((this.facilitySlotForFilter[k + 3].isAppointment || this.facilitySlotForFilter[k + 3].isBlock || this.facilitySlotForFilter[k + 3].isBreak)
                && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
                && this.facilitySlotForFilter[k].isBlock == false
              ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }

          if (this.facilitySlotForFilter[k + 4] != null && this.durationInMinutes == 75) {
            if ((this.facilitySlotForFilter[k + 4].isAppointment || this.facilitySlotForFilter[k + 4].isBlock || this.facilitySlotForFilter[k + 4].isBreak)
                    && this.facilitySlotForFilter[k].isBlock == false
            ) {
                if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                  this.facilitySlotForFilter[k].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k].isBlock1 = false;
                }
                if (!this.facilitySlotForFilter[k + 1].isAppointment
                  && !this.facilitySlotForFilter[k + 1].isBlock
                  && !this.facilitySlotForFilter[k + 1].isBreak) {
                  this.facilitySlotForFilter[k + 1].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k + 1].isBlock1 = false;
                }
                if (!this.facilitySlotForFilter[k + 2].isAppointment
                  && !this.facilitySlotForFilter[k + 2].isBlock
                  && !this.facilitySlotForFilter[k + 2].isBreak) {
                  this.facilitySlotForFilter[k + 2].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k + 2].isBlock1 = false;
                }
              if (!this.facilitySlotForFilter[k + 3].isAppointment
                  && !this.facilitySlotForFilter[k + 3].isBlock
                  && !this.facilitySlotForFilter[k + 3].isBreak) {
                  this.facilitySlotForFilter[k + 3].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k + 3].isBlock1 = false;
                }
              }
    
            else if ((this.facilitySlotForFilter[k + 3].isAppointment || this.facilitySlotForFilter[k + 3].isBlock || this.facilitySlotForFilter[k + 3].isBreak)
                  && this.facilitySlotForFilter[k].isBlock == false
              ) {
                if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                  this.facilitySlotForFilter[k].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k].isBlock1 = false;
                }
                if (!this.facilitySlotForFilter[k + 1].isAppointment
                  && !this.facilitySlotForFilter[k + 1].isBlock
                  && !this.facilitySlotForFilter[k + 1].isBreak) {
                  this.facilitySlotForFilter[k + 1].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k + 1].isBlock1 = false;
                }
                if (!this.facilitySlotForFilter[k + 2].isAppointment
                  && !this.facilitySlotForFilter[k + 2].isBlock
                  && !this.facilitySlotForFilter[k + 2].isBreak) {
                  this.facilitySlotForFilter[k + 2].isBlock1 = true;
                }
                else {
                  this.facilitySlotForFilter[k + 2].isBlock1 = false;
                }
              }
            else if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
                && this.facilitySlotForFilter[k].isBlock == false
              ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
            } 
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }

          if (this.facilitySlotForFilter[k + 3] != null && this.durationInMinutes == 60) {
            if ((this.facilitySlotForFilter[k + 3].isAppointment || this.facilitySlotForFilter[k + 3].isBlock || this.facilitySlotForFilter[k + 3].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 2].isAppointment
                && !this.facilitySlotForFilter[k + 2].isBlock
                && !this.facilitySlotForFilter[k + 2].isBreak) {
                this.facilitySlotForFilter[k + 2].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 2].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false 
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }

          if (this.facilitySlotForFilter[k + 2] != null && this.durationInMinutes == 45) {
            if ((this.facilitySlotForFilter[k + 2].isAppointment || this.facilitySlotForFilter[k + 2].isBlock || this.facilitySlotForFilter[k + 2].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
              if (!this.facilitySlotForFilter[k + 1].isAppointment
                && !this.facilitySlotForFilter[k + 1].isBlock
                && !this.facilitySlotForFilter[k + 1].isBreak) {
                this.facilitySlotForFilter[k + 1].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k + 1].isBlock1 = false;
              }
            }
            else if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment
                && !this.facilitySlotForFilter[k].isBlock
                && !this.facilitySlotForFilter[k].isBreak) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }

          else if (this.facilitySlotForFilter[k + 1] != null && this.durationInMinutes == 30) {
            if ((this.facilitySlotForFilter[k + 1].isAppointment || this.facilitySlotForFilter[k + 1].isBlock || this.facilitySlotForFilter[k + 1].isBreak)
              && this.facilitySlotForFilter[k].isBlock == false
            ) {
              if (!this.facilitySlotForFilter[k].isAppointment && !this.facilitySlotForFilter[k].isBlock) {
                this.facilitySlotForFilter[k].isBlock1 = true;
              }
              else {
                this.facilitySlotForFilter[k].isBlock1 = false;
              }
            }
            else {
              this.facilitySlotForFilter[k].isBlock1 = false;
            }
          }
        }
        else {
          this.facilitySlotForFilter[k].isBlock1 = false;
        }

        //var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
        if (moment(this.facilitySlotForFilter[k].startTime).toDate() < moment(comparetime).toDate()) {
          this.facilitySlotForFilter[k].isBlock1 = true;
        }

        let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
        let currentDate = moment(dateCurrent).toDate();
        let date = moment(this.facilitySlotForFilter[k].startTime).toDate();
        let issameDaye = isSameDay(date, currentDate);
        let isBeforedate = isBefore(date, currentDate);
        if (isBeforedate) {
          this.facilitySlotForFilter[k].isBlock1 = true;
        }
      }
    }
  } 
  
  getRemoteFacilitySlots(facilityDate: Date) {
    if (this.selectedFacilityLocal == undefined) {
      return;
    }
    this.loading = true;
    this.displaySlots = [];
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    this.facilitySlotRequest.AppointmentDateTime = facilityDate;
    this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    //this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.CrmFacilityId = this.selectedFacilityLocal?.crmFacilityId;
    this.facilitySlotRequest.CrmSalesorderId = parseInt(this.salesOrderLocal.salesOrderId, 10);
    this.facilitySlotRequest.CrmClassificationDuration = this.patientBookingTypeId == 'SG' ? 15 : this.selctedCrmClassificationDuration;
    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.crmDeliveryAddressState;
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
    //this.checkTimeZone = this.facilitySlotRequest.patientTimeZone;
    this.checkTimeZone = this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code;
    this.loadingCustom = true;
    let appointmentRemoteRequest = this.patientBookingTypeId == 'R' ? this.patientService.GetRemoteFacilityTimeSlotsByPatient(this.facilitySlotRequest) : this.patientService.GetRemoteSelfGuidedTimeSlotsByPatient(this.facilitySlotRequest);
    appointmentRemoteRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            //var compareDate = new DatePipe(this.locale).transform(new Date(), 'MMM DD YYYY', this.checkTimeZone);
            this.facilitySlotResponse = result.responseModel;
            this.facilitySlotForFilter = result.responseModel.timeSlotes.facilityTimeSlots;
            this.isGlobalHoliday = result.responseModel.timeSlotes.isHoliday;
            this.globalHolidayMsg = result.responseModel.timeSlotes.holidayMessage;

            if (this.facilitySlotForFilter.length > 0)
              this.islotAvailable = true;
            else
              this.islotAvailable = false;
            this.onApptDurationChangeSlot();

            let mostMinDate = new Date(Math.min.apply(null, this.facilityAvailableWeekDays.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMinDate.getTime())) {
              this.selectedFacilityWeekDayMinDate = this.facilityAvailableWeekDays.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMinDate.getDate();
              })[0];
            }

            let mostMaxDate = new Date(Math.max.apply(null, this.facilityAvailableWeekDays.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMaxDate.getTime())) {
              this.selectedFacilityWeekDayMaxDate = this.facilityAvailableWeekDays.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMaxDate.getDate();
              })[0];
            }

            let mostMinWeekendDate = new Date(Math.min.apply(null, this.facilityAvailableWeekend.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMinWeekendDate.getTime())) {
              this.selectedFacilityWeekendMinDate = this.facilityAvailableWeekend.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMinWeekendDate.getDate();
              })[0];
            }

            let mostMaxWeekendDate = new Date(Math.max.apply(null, this.facilityAvailableWeekend.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMaxWeekendDate.getTime())) {
              this.selectedFacilityWeekendMaxDate = this.facilityAvailableWeekend.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMaxWeekendDate.getDate();
              })[0];
            }


            var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
            this.slotLockBasedOnAppoitmentDuration(comparetime);

            this.facilitySlotResponse.timeSlotes.facilityTimeSlots.forEach((value) => {
              this.facilitySlotFiltered = this.facilitySlotForFilter.filter(x => x.appintmentDate === value.appintmentDate);
              if (this.facilitySlotFiltered.length > 0) {
                this.displaySlot = new DisplaySlots();
                this.displaySlot.date = new Date(this.facilitySlotFiltered[0].appintmentDate);
                var compareDate = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.checkTimeZone);
                if (this.displaySlot.date.toDateString() == compareDate) {
                  this.displaySlot.isToday = true;
                  this.prevdisplayDate = this.displaySlot;
                  this.displaySlot.isDisabled = false;
                }
                else if (moment(compareDate).toDate() > this.displaySlot.date) {
                  this.displaySlot.isToday = false;
                  this.displaySlot.isDisabled = true;
                }
                else {
                  this.displaySlot.isToday = false;
                  this.displaySlot.isDisabled = false;
                }
                this.displaySlot.time = [];
                this.facilitySlotFiltered.forEach((valueFilter) => {
                  this.displaySlotsTime = new DisplaySlotsTime();
                  this.displaySlotsTime.dayTime = new Date(valueFilter.startTime.toString());
                  this.displaySlotsTime.displayStartTime = new Date(valueFilter.displayStartTime.toString());
                  this.displaySlotsTime.origStartTime = valueFilter.origStartTime
                  this.displaySlotsTime.origEndTime = valueFilter.origEndTime
                  this.displaySlotsTime.isBlock1 = valueFilter.isBlock1 || valueFilter.isHideSlot?true:false;
                  this.displaySlotsTime.therapistIds = valueFilter.therapistIds;
                  this.displaySlotsTime.therapistTimeZone = valueFilter.therapistTimeZone;                  
                  var comparetime = new DatePipe(this.locale).transform(new Date(), this.dateFormat + " HH:mm", this.checkTimeZone);
                  if (this.displaySlotsTime.displayStartTime < moment(comparetime).toDate()) {
                    this.displaySlotsTime.isDisabled = true;
                  }
                  else {
                    this.displaySlotsTime.isDisabled = false;
                  }

                  if (valueFilter.isAppointment || valueFilter.isBreak || valueFilter.isBlock) {
                    this.displaySlotsTime.isBlock1 = true;
                    this.displaySlotsTime.isDisabled = true;
                  }
                  this.displaySlotsTime.isSelected = false;
                  this.displaySlot.time.push(this.displaySlotsTime);
                  const index: number = this.facilitySlotForFilter.indexOf(valueFilter);
                  if (index !== -1) {
                    this.facilitySlotForFilter.splice(index, 1);
                  }
                });
                this.displaySlots.push(this.displaySlot);
                let flteredDisplaySlots = this.displaySlots.filter(x => x.isDisabled == false);
                this.displaySlots = [];
                this.displaySlots = flteredDisplaySlots;

                if (this.displaySlot.isToday) {
                  this.displaySlotsMobile = [];
                  this.displaySlotsMobile.push(this.displaySlot);
                  this.displaySlotsMobile = this.displaySlotsMobile.filter(x => x.isDisabled == false);
                }
              }
            });


            //check for the previous and next icons
            this.checkforthePrevFlag(this.facilitySlotDate, this.minAvailabilityDate);
          }

        }
        else {

        }
        this.loading = false;
        this.loadingCustom = false;
      });

  }

  onApptDurationChangeSlot() {
    this.clearSelection();
    if (this.facilitySlotResponse) {
      for (let k = 0; k < this.facilitySlotForFilter.length; k++) {
        let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
        let currentDate = moment(dateCurrent).toDate();
        let date = moment(this.facilitySlotResponse.timeSlotes.facilityTimeSlots[k].displayStartTime).toDate();
        let issameDaye = isSameDay(date, currentDate);
        let isBeforedate = isBefore(date, currentDate);
        if (isBeforedate) {
          this.facilitySlotResponse.timeSlotes.facilityTimeSlots[k].isBlock1 = true;
        }
      }
    }
  }



  private async getRemoteFacilitiesByAddress(crmSalesOrderId) {
    this.loading = true;
    let facilitySearchRequest: FacilitySearchRequestModel = new FacilitySearchRequestModel();
    if (this.isBillingAddress)
      facilitySearchRequest.crmState = this.salesOrderData.patientInfo.crmState;
    else
      facilitySearchRequest.crmState = this.salesOrderData.crmDeliveryAddressState;
    facilitySearchRequest.crmPatientZipCode = this.isBillingAddress ? this.salesOrderData.patientInfo.crmPatientZipCode : this.salesOrderData.crmDeliveryAddressZipcode;
    facilitySearchRequest.facilityZipCode = null;
    facilitySearchRequest.crmSalesOrderId = Number(crmSalesOrderId);
    facilitySearchRequest.isBillingAddress = this.isBillingAddress
    //For Time Zone Parameter Passed
    facilitySearchRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
    //this.checkTimeZone = facilitySearchRequest.patientTimeZone;
    facilitySearchRequest.isAppointment = this.salesOrderData.schAppointmentId > 0 ? true : false;
    this.checkTimeZone = this.timeZone.find(x => x.orgcode == facilitySearchRequest.patientTimeZone).code;
    this.loadingCustom = true;
    this.patientService.SearchRemoteFacilitiesForAppointment(facilitySearchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.selectedFacility = [];
          let rdata = result.responseModel;

          this.loadingCustom = false;
          if (rdata.facilities != null) {
            let selectedFacilities = rdata.facilities;
            selectedFacilities.forEach(value => {
              if (value.crmFacilityId > 0) {
                this.selectedFacility.push(value)
              }
            });
            // this.selectedFacility = rdata.facilities;
            if (this.selectedFacility.length > 0) {
              this.facilitySlotDate = new Date(this.selectedFacility[0].crmFacilityFirstAvailableStartDate);
              this.minAllowDateToBookAppoitment = new Date(this.selectedFacility[0].crmFacilityFirstAvailableStartDate);
              this.fetchFacilityData();
            }
          }
          else {
            this.facilitySlotDate = new Date(rdata.crmFacilityFirstAvailableStartDate);
            this.minAllowDateToBookAppoitment = new Date(rdata.crmFacilityFirstAvailableStartDate);
            //this.fetchFacilityData();
            this.showsidebar = false;
          }
        }
      });
  }

  private GetTimeSlotsAvailabilityByTherapist(facilityDate: Date) {
    if (facilityDate.toString().toLowerCase() === 'invalid date')
      return;
    this.loading = true;
    this.displaySlots = [];
    this.facilitySlotRequest = new FacilityTimeSlotRequest();
    this.facilitySlotRequest.AppointmentDateTime = facilityDate;
    this.facilitySlotRequest.AppointmentDateTimeString = moment(facilityDate, 'MM-DD-YYYY HH:mm:ss').format("MM-DD-YYYY HH:mm:ss");
    this.facilitySlotRequest.CrmTherapistId = this.webStorageService.getSelectedTherapist()?.appUserInfoId;
    this.facilitySlotRequest.CrmSalesorderId = parseInt(this.salesOrderLocal.salesOrderId, 10);
    this.facilitySlotRequest.CrmClassificationDuration = this.selctedCrmClassificationDuration;

    if (this.isBillingAddress)
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.patientInfo.crmState;
    else
      this.facilitySlotRequest.CrmPatientStateCode = this.salesOrderData.crmDeliveryAddressState;
    this.facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
    this.checkTimeZone = this.timeZone.find(x => x.orgcode == this.facilitySlotRequest.patientTimeZone).code;
    this.patientService.GetTimeSlotsAvailabilityByTherapist(this.facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            this.facilitySlotResponse = result.responseModel;
            this.facilitySlotForFilter = result.responseModel.timeSlotes.facilityTimeSlots;
            this.isGlobalHoliday = result.responseModel.timeSlotes.isHoliday;
            this.globalHolidayMsg = result.responseModel.timeSlotes.holidayMessage;

            var comparetime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
            this.slotLockBasedOnAppoitmentDuration(comparetime);
            //this.facilitySlotDate = new Date(result.responseModel.crmFacilityFirstAvailableStartDate);

            this.facilitySlotResponse.timeSlotes.facilityTimeSlots.forEach((value) => {
              this.facilitySlotFiltered = this.facilitySlotForFilter.filter(x => x.appintmentDate === value.appintmentDate);
              if (this.facilitySlotFiltered.length > 0) {
                this.displaySlot = new DisplaySlots();
                this.displaySlot.date = new Date(this.facilitySlotFiltered[0].appintmentDate);
                //let unique = this.facilitySlotFiltered.filter((item, i, ar) => ar.indexOf(item) === i);
                let arrayUniqueByFacilityName = [...new Map(this.facilitySlotFiltered.map(item => [item['facilityName'], item.facilityName])).values()];
                let arrayUniqueByFacilityAddress = [...new Map(this.facilitySlotFiltered.map(item => [item['facilityAddress'], item.facilityAddress])).values()];
                var data = arrayUniqueByFacilityName.toString().replace(',', ' ');
                if (data.split('&').length > 1) {
                  this.displaySlot.facilityName = data.split('&')[0];
                  this.displaySlot.remoteFacilityName = data.split('&')[1];
                  this.displayAddressToolTip = true;
                }
                else {
                  this.displaySlot.facilityName = data;
                  this.displaySlot.remoteFacilityName = "";
                  if (data == "Remote Type") {
                    this.displayAddressToolTip = false;
                  } else {
                    this.displayAddressToolTip = true;
                  }

                }
                this.displaySlot.facilityAddress = arrayUniqueByFacilityAddress.toString();
                var compareDate = new DatePipe(this.locale).transform(new Date(), 'MMM DD YYYY', this.checkTimeZone);
                if (this.displaySlot.date.toDateString() == compareDate) {
                  this.displaySlot.isToday = true;
                  this.prevdisplayDate = this.displaySlot;
                  this.displaySlot.isDisabled = false;
                }
                else if (moment(compareDate).toDate() > this.displaySlot.date) {
                  this.displaySlot.isToday = false;
                  this.displaySlot.isDisabled = true;
                }
                else {
                  this.displaySlot.isToday = false;
                  this.displaySlot.isDisabled = false;
                }

                this.displaySlot.time = [];
                this.facilitySlotFiltered.forEach((valueFilter) => {
                  this.displaySlotsTime = new DisplaySlotsTime();
                  this.displaySlotsTime.dayTime = new Date(valueFilter.startTime.toString());
                  this.displaySlotsTime.displayStartTime = new Date(valueFilter.displayStartTime.toString());
                  this.displaySlotsTime.origStartTime = valueFilter.origStartTime
                  this.displaySlotsTime.origEndTime = valueFilter.origEndTime
                  this.displaySlotsTime.therapistIds = valueFilter.therapistIds;
                  this.displaySlotsTime.isBlock1 = valueFilter.isBlock1;
                  this.displaySlotsTime.therapistTimeZone = valueFilter.therapistTimeZone;
                  this.displaySlotsTime.facilityAddress = valueFilter.facilityAddress;
                  //changes done here to check the therapist time zone
                  //this.displaySlotsTime.isBlock1 = true;
                  if (this.patientBookingTypeId == "T") {
                    this.displaySlotsTime.slotType = valueFilter.slotType;
                    this.displaySlotsTime.isRemoteAndFacility = value.isRemoteAndFacility
                    if (valueFilter.isRemoteAndFacility) {
                      this.displaySlotsTime.remoteFacilityId = valueFilter.remoteFacilityId;
                      this.displaySlotsTime.facilityId = valueFilter.facilityid;
                    }
                    else {
                      this.displaySlotsTime.facilityId = valueFilter.facilityid;
                    }
                  }

                  // var comparetime = new DatePipe(this.locale).transform(new Date(), this.dateFormat + " HH:mm", this.checkTimeZone);
                  // if (this.displaySlotsTime.displayStartTime < moment(comparetime).toDate()) {
                  //   this.displaySlotsTime.isDisabled = true;
                  // }
                  // else {
                  //   this.displaySlotsTime.isDisabled = false;
                  // }
                  //this.displaySlotsTime.isDisabled = this.isSlotDisabled(this.displaySlotsTime.displayStartTime);

                  let dateCurrent = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
                  let currentDate = moment(dateCurrent).toDate();
                  let date = moment(this.displaySlotsTime.displayStartTime).toDate();

                  let isBeforedate = isBefore(date, currentDate);
                  if (isBeforedate) {
                    this.displaySlotsTime.isDisabled = true;
                  }
                  else {
                    this.displaySlotsTime.isDisabled = false;
                  }

                  if (this.patientBookingTypeId == "T") {
                    if ((valueFilter.slotType == 'Clinic Visit' && !this.isDrFacilityVisitOnly)
                      || (valueFilter.slotType == 'Remote Setup' && !this.isDrRemoteVisitOnly)) {
                      this.displaySlotsTime.isDisabled = true;
                    }
                  }

                  if (valueFilter.isAppointment || valueFilter.isBreak || valueFilter.isBlock) {
                    this.displaySlotsTime.isBlock1 = true;
                    this.displaySlotsTime.isDisabled = true;
                  }
                  this.displaySlotsTime.isSelected = false;
                  this.displaySlot.time.push(this.displaySlotsTime);
                  const index: number = this.facilitySlotForFilter.indexOf(valueFilter);
                  if (index !== -1) {
                    this.facilitySlotForFilter.splice(index, 1);
                  }
                });

                this.displaySlots = [];
                this.displaySlots.push(this.displaySlot);
                let flteredDisplaySlots = this.displaySlots.filter(x => x.time.find(y => !y.isDisabled));
                this.displaySlots = flteredDisplaySlots;

                if (this.displaySlot.isToday) {
                  this.displaySlotsMobile = [];
                  this.displaySlotsMobile.push(this.displaySlot);
                  this.displaySlotsMobile = this.displaySlotsMobile.filter(x => x.isDisabled == false);
                }
              }
            });

            //check for the previous and next icons
            this.checkforthePrevFlag(this.facilitySlotDate, this.minAvailabilityDate);
          }

        }
        //   //disable the months
        //   var iCounter = this.facilitySlotDate.getMonth();
        //   this.disabledMonths(true);
        //   this.selectedMonth = new Months();
        //   this.selectedMonth.month = this.monthNames[iCounter] + ", " + new Date(this.facilitySlotDate).getFullYear().toString();
        //   this.previousSelectedMonth = this.selectedMonth;
        //   this.loading = false;
      });

  }

  

  onTimeSelect(displayStartTime: string, time: string, item: DisplaySlotsTime, itemDate: any) {
    this.loading = true;
    this.selectedFacilityTimeZone = "";
    let origDate = item.origStartTime;
    this.isTherapistFacilityType = this.isTherapistRemoteType = this.isTherapistRemoteAndFaciltyType = false;
    this.selectedFacilityLocalcrmFacilityId = this.selectedFacilityLocalcrmRemoteFacilityId = 0;
    if (this.patientBookingTypeId == 'SG') {
      this.salesOrderData.crmClassificationDuration = 15;
    }
    var minutes = this.salesOrderData.crmClassificationDuration == 1 ? 60 : this.salesOrderData.crmClassificationDuration;
    this.displaySelectedStartDate = new Date(displayStartTime);
    this.selectedStartDate = new Date(displayStartTime);
    this.selectedEndDate = new Date(new Date(displayStartTime).setMinutes(new Date(displayStartTime).getMinutes() + minutes));
    this.selectedOrigStartDate = moment(origDate).toDate();
    this.selectedOrigEndDate = moment(origDate).add(minutes, 'minutes').toDate();

    if (this.patientBookingTypeId == 'F') {
      this.selectedFacilityLocal = this.selectedFacility.find(x => x.crmFacilityName == itemDate.facilityname);
      this.FetchSelectedFacilityData();
    }
    //changes for passing the time zone and therapist Id
    if (item.therapistIds && (this.patientBookingTypeId == 'SG' || this.patientBookingTypeId == 'R')) {
      this.selectedTherapistId = item.therapistIds.split(",")[0];
    }

    if (item.therapistTimeZone) {
      this.selectedFacilityTimeZone = item.therapistTimeZone.split(",")[0];
    }
    if (this.prevdisplaySlotsTime) {
      this.prevdisplaySlotsTime.isSelected = false;
    }
    if (this.prevdisplayDate) {
      this.prevdisplayDate.isToday = false;
    }
    item.isSelected = true;
    itemDate.isToday = true;
    this.prevdisplaySlotsTime = item;
    this.prevdisplayDate = itemDate;
    this.showConfirm = false;
    this.termCondtionSelected = false;
    this.reminderOpted = false;

    let facilitySlotRequest = new FacilityTimeSlotRequest();
    if (this.patientBookingTypeId == "T") {
      facilitySlotRequest.CrmFacilityId = item.facilityId;
      facilitySlotRequest.CrmTherapistId = this.webStorageService.getSelectedTherapist()?.appUserInfoId;
      facilitySlotRequest.CrmSalesorderId = this.facilitySlotRequest.CrmSalesorderId;
      facilitySlotRequest.CrmClassificationDuration = this.facilitySlotRequest.CrmClassificationDuration;
      facilitySlotRequest.AppointmentDateTimeString = this.facilitySlotRequest.AppointmentDateTimeString;
      facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
      if (item.slotType.toLocaleLowerCase() == "Clinic Visit".toLocaleLowerCase()) {
        this.isTherapistFacilityType = true;
        this.selectedFacilityLocalcrmFacilityId = facilitySlotRequest.CrmFacilityId;
        this.selectedAppointmentTypePopUp = "ClinicVisit";
        this.getTherapistFacilityTypeDetails(facilitySlotRequest);
      }
      else if (item.slotType.toLocaleLowerCase() == "Remote Setup".toLocaleLowerCase()) {
        this.isTherapistRemoteType = true;
        this.selectedFacilityLocalcrmFacilityId = item.facilityId;
        this.selectedFacilityLocalcrmRemoteFacilityId = item.remoteFacilityId;
        this.selectedAppointmentTypePopUp = "RemoteSetup";
        this.getRemoteAppintmentTypes();
      }
      else {
        this.isTherapistRemoteAndFaciltyType = true;
        this.showsidebar = true;
        this.facilitySlotRequest.CrmFacilityId = item.facilityId;
        this.facilitySlotRequest.RemoteFacilityId = item.remoteFacilityId;
        this.selectedFacilityLocalcrmRemoteFacilityId = item.remoteFacilityId;
        this.selectedFacilityLocalcrmFacilityId = item.facilityId;

        if (this.isDrFacilityVisitOnly && this.isDrRemoteVisitOnly) {
          this.selectedAppointmentTypePopUp = '';
        }
        else if (this.isDrFacilityVisitOnly) {
          this.selectedAppointmentTypePopUp = "ClinicVisit";
        }
        else if (this.isDrRemoteVisitOnly) {
          this.selectedAppointmentTypePopUp = "RemoteSetup";
        }

        if (this.selectedAppointmentTypePopUp != "" || this.selectedAppointmentTypePopUp != null || this.selectedAppointmentTypePopUp != undefined) {
          this.handleFacilityVisitOptions(this.selectedAppointmentTypePopUp);
        }
        //this.getRemoteAppintmentTypes();
      }
    }
    this.loading = false;

    this.isChecked = false;
  }

  async handleFacilityVisitOptions(value: string) {
    //this.loading = true;
    this.isTherapistRemoteType = this.isTherapistFacilityType = false;
    if (value.toLowerCase() == "ClinicVisit".toLowerCase()) {
      this.showsidebar = true;
      let facilitySlotRequest = new FacilityTimeSlotRequest();
      this.isTherapistFacilityType = true;
      facilitySlotRequest.CrmFacilityId = this.facilitySlotRequest.CrmFacilityId;
      facilitySlotRequest.CrmTherapistId = this.webStorageService.getSelectedTherapist()?.appUserInfoId;
      facilitySlotRequest.CrmSalesorderId = this.facilitySlotRequest.CrmSalesorderId;
      facilitySlotRequest.CrmClassificationDuration = this.facilitySlotRequest.CrmClassificationDuration;
      facilitySlotRequest.AppointmentDateTimeString = this.facilitySlotRequest.AppointmentDateTimeString;
      facilitySlotRequest.patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
      facilitySlotRequest.isBillingAddress = this.isBillingAddress;
      this.getTherapistFacilityTypeDetails(facilitySlotRequest);
    }
    else if (value.toLowerCase() == "Telehealth".toLowerCase()) {
      this.showsidebar = true;
      this.isTherapistRemoteType = true;
      this.getRemoteAppintmentTypes();
    }
    this.selectedAppointmentTypePopUp = value;
  }

  getTherapistFacilityTypeDetails(facilitySlotRequest: FacilityTimeSlotRequest) {
    this.loading = true;
    this.patientService.getTherapistFacilityTypeDetails(facilitySlotRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            //crmFacilityFirstAvailableStartDate
            this.selectedFacilityLocal = result.responseModel;
            this.crmFacilityDistanceInMile = result.responseModel.crmFacilityDistanceInMiles;
            this.crmFacilityAddress = result.responseModel.crmFacilityAddress;
            this.crmFacilityAddressLine1 = result.responseModel.crmFacilityAddressLine1;
            this.crmFacilityAddressLine2 = result.responseModel.crmFacilityAddressLine2;
            this.crmFacilityCity = result.responseModel.crmFacilityCity;
            this.crmFacilityState = result.responseModel.crmFacilityState;
            this.crmFacilityCountry = result.responseModel.crmFacilityCountry;
            this.crmFacilityZipCode = result.responseModel.crmFacilityZipCode;
            this.crmFacilityName = result.responseModel.crmFacilityName;
            this.crmFacilityLatitude = result.responseModel.crmFacilityLatitude;
            this.crmFacilityLongitude = result.responseModel.crmFacilityLongitude;
            this.crmBioData = result.responseModel.crmBioData;
            this.facilityAvailableWeekDays = this.facilityAvailableWeekDays == undefined ? result.responseModel.facilityAvailableWeekDays : result.responseModel.facilityAvailableWeekDays;
            this.facilityAvailableWeekend = this.facilityAvailableWeekend == undefined ? result.responseModel.facilityAvailableWeekend : result.responseModel.facilityAvailableWeekend;

            let mostMinDate = new Date(Math.min.apply(null, this.facilityAvailableWeekDays.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMinDate.getTime())) {
              this.selectedFacilityWeekDayMinDate = this.facilityAvailableWeekDays.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMinDate.getDate();
              })[0];
            }

            let mostMaxDate = new Date(Math.max.apply(null, this.facilityAvailableWeekDays.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMaxDate.getTime())) {
              this.selectedFacilityWeekDayMaxDate = this.facilityAvailableWeekDays.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMaxDate.getDate();
              })[0];
            }

            let mostMinWeekendDate = new Date(Math.min.apply(null, this.facilityAvailableWeekend.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMinWeekendDate.getTime())) {
              this.selectedFacilityWeekendMinDate = this.facilityAvailableWeekend.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMinWeekendDate.getDate();
              })[0];
            }

            let mostMaxWeekendDate = new Date(Math.max.apply(null, this.facilityAvailableWeekend.map(e => {
              return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
            })));

            if (!isNaN(mostMaxWeekendDate.getTime())) {
              this.selectedFacilityWeekendMaxDate = this.facilityAvailableWeekend.filter(e => {
                var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
                return d.getDate() == mostMaxWeekendDate.getDate();
              })[0];
            }

            //console.log(this.selectedFacilityWeekDayMinDate);


            // this.crmFacilityDistanceInMile = this.selectedFacilityLocal.crmFacilityDistanceInMiles;
            // this.crmFacilityAddress = this.selectedFacilityLocal.crmFacilityAddress;
            // this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal.crmFacilityWeekDaysAvailability;
            // this.crmFacilityWeekendAvailability = this.selectedFacilityLocal.crmFacilityWeekendAvailability;
            //this.facilityAvailableWeekDays = this.selectedFacilityLocal.facilityAvailableWeekDays;
            //this.facilityAvailableWeekend = this.selectedFacilityLocal.facilityAvailableWeekend;
          }
        }
      });
    this.loading = false;
  }

  onConfirm() {
    if (!this.selectedStartDate || !this.selectedEndDate) {
      this.showWarningMessage("Select appointment date & time.");
      return;
    }

    if (this.isTherapistFacilityType && !this.selectedAppointmentTypePopUp)

      if (this.isTherapistFacilityType && (this.selectedAppointmentTypePopUp == '' || this.selectedAppointmentTypePopUp == undefined)) {
        this.showWarningMessage("Select any appointment Type.");
        return;
      }
    if (this.isTherapistRemoteAndFaciltyType && (this.selectedAppointmentTypePopUp == '' || this.selectedAppointmentTypePopUp == undefined)) {
      this.showWarningMessage("Select any appointment Type.");
      return;
    }

    // this.OpenCheck = true;
    this.router.navigate(['/patient/appointment-summary']);
  }

  onBookAppointment() {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
    if (!this.email) {
      this.emailRequired = true;
    }
    else {
      this.emailRequired = false;
    }
    if (!TEXT_REGEXP.test(this.email) && this.email) {
      this.emailRegex = true;
    }
    else {
      this.emailRegex = false;
    }

    if (!this.termCondtionSelected) {
      this.showWarningMessage("Please accept the Terms & Conditions")
      return;
    }

    if (this.emailMatch || this.emailConfirmRegex || this.emailRegex || this.emailConfirmRequired || this.emailConfirmRequired) {
      return;
    }
    this.loading = true;
    this.appointmentService.updatePatientEmail(this.salesOrderData.patientInfo.crmPatientId.toString(), this.email, this.patientPhone, this.patientMobileNumber, this.salesOrderData.btNoteID, this.isProvisional)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.responseModel) {
        }
        else {
          if (result.message) {
            this.showErrorMessage(result.message);
          }
          else {
            this.showErrorMessage("There was problem updating your email. Please contact administrator");
          }
          this.loading = false;
          return;
        }
      });
    this.appointmentRequest = new AppointmentRequestModel();
    let AppoinmentType: string = this.patientBookingTypeId == "T" ? "Therapist" : (this.patientBookingTypeId == "R" || this.patientBookingTypeId == "SG") ? "Telehealth" : "FacilityVisit";
    if (this.patientBookingTypeId == "T") {
      if (this.selectedAppointmentTypePopUp.toLowerCase() == "ClinicVisit".toLowerCase()) {
        this.appointmentRequest.CrmFacilityId = this.selectedFacilityLocalcrmFacilityId;
        this.appointmentRequest.CrmRemoteFacilityId = 0;
      }
      else {
        this.appointmentRequest.CrmFacilityId = 0;
        this.appointmentRequest.CrmRemoteFacilityId = this.selectedFacilityLocalcrmRemoteFacilityId;
      }
    }
    else {
      this.appointmentRequest.CrmFacilityId = this.selectedFacilityLocal.crmFacilityId;
    }
    this.appointmentRequest.SchAppointmentTypeName = AppoinmentType;
    this.appointmentRequest.CrmPatientEmailAddress = this.email;
    this.appointmentRequest.CrmPatientId = this.salesOrderData.patientInfo.crmPatientId;
    this.appointmentRequest.CrmPatientPhoneNo = this.patientMobileNumber == "" ? this.patientPhone : this.patientMobileNumber;
    this.appointmentRequest.CrmSalesorderId = parseInt(this.salesOrderLocal.salesOrderId, 10);
    this.appointmentRequest.IsAppointmentBookedByPatient = true;
    this.appointmentRequest.SchAppointmentDateStartTime = this.selectedStartDate;
    this.appointmentRequest.SchAppointmentDateEndTime = this.selectedEndDate;
    this.appointmentRequest.SchAppointmentDateStartTimeString = this.selectedStartDate.toDateString() + ' ' + this.selectedStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentDateEndTimeString = this.selectedEndDate.toDateString() + ' ' + this.selectedEndDate.toTimeString().split(' ')[0];

    this.appointmentRequest.SchAppointmentDateStartTime = this.selectedStartDate;
    this.appointmentRequest.SchAppointmentDateEndTime = this.selectedEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTime = this.selectedOrigStartDate;
    this.appointmentRequest.SchAppointmentOrigDateEndTime = this.selectedOrigEndDate;

    this.appointmentRequest.SchAppointmentOrigDateStartTimeString = this.selectedOrigStartDate.toDateString() + ' ' + this.selectedOrigStartDate.toTimeString().split(' ')[0];
    this.appointmentRequest.SchAppointmentOrigDateEndTimeString = this.selectedOrigEndDate.toDateString() + ' ' + this.selectedOrigEndDate.toTimeString().split(' ')[0];


    this.appointmentRequest.SchAppointmentId = 0;
    this.appointmentRequest.CrmTherapistId = this.patientBookingTypeId == "T" ? this.webStorageService.getSelectedTherapist()?.appUserInfoId : null;
    if (this.patientBookingTypeId == "SG" || this.patientBookingTypeId == "R") {
      this.appointmentRequest.CrmTherapistId = Number(this.selectedTherapistId);
    }
    this.appointmentRequest.PatientTimeZone = this.selectedTimeZone.shortName;
    this.appointmentRequest.SchReminderOpted = this.reminderOpted;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = this.selectedStartDate.toDateString();
    appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(this.selectedStartDate.toTimeString(), 'hh:mm A').format("hh:mm A");
    appointmentRequestBT.ScheduledDeliveryStartDate = this.selectedStartDate;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    let BTStatusSelected = null;
    if (this.patientBookingTypeId == "F") {
      BTStatusSelected = 'booked';
    }
    if (this.patientBookingTypeId == "R" || this.patientBookingTypeId == "SG") {
      BTStatusSelected = 'bookedremote';
    }
    if (this.patientBookingTypeId == "T") {
      if (this.selectedAppointmentTypePopUp.toLowerCase() == "ClinicVisit".toLowerCase()) {
        BTStatusSelected = 'booked';
      }

      if (this.selectedAppointmentTypePopUp.toLowerCase() == "Telehealth".toLowerCase()) {
        BTStatusSelected = 'bookedremote';
      }
    }


    if (this.salesOrderData.crmWIPAssignedToKey) {
      appointmentRequestBT.WIPAssignedToKey = this.salesOrderData.crmWIPAssignedToKey;
    }
    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameBt.toLowerCase() == this.salesOrderData.crmWIPStatusNameBT.toLowerCase())
    if (selectedWipStaus) {
      appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
      appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
      appointmentRequestBT.IsUpdateToBrightree = this.isBrightreeSystemAppUpdate;
      appointmentRequestBT.EmailAddress = this.email;
      appointmentRequestBT.PhoneNumber = this.patientPhone;
      appointmentRequestBT.crmPatientPhoneNumber = this.patientPhone;
      appointmentRequestBT.crmPatientMobileNumber = this.patientMobileNumber;
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == BTStatusSelected)
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = this.isBrightreeSystemAppUpdate;
        appointmentRequestBT.EmailAddress = this.email;
        appointmentRequestBT.PhoneNumber = this.patientPhone;
        appointmentRequestBT.crmPatientPhoneNumber = this.patientPhone;
        appointmentRequestBT.crmPatientMobileNumber = this.patientMobileNumber;
      }
    }
    appointmentRequestBT.ScheduledDeliveryStartTimeString = moment(appointmentRequestBT.ScheduledDeliveryStartTimeString, 'hh:mm A').format("HH:mm");
    if (this.isDefaultWIPNeededDateAppointmentdate) {
      appointmentRequestBT.WIPDateNeededString = moment(appointmentRequestBT.ScheduledDeliveryStartDate).toDate().toDateString();
      appointmentRequestBT.WIPDateNeeded = moment(appointmentRequestBT.ScheduledDeliveryStartDate).toDate();
    } else {
      appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
      appointmentRequestBT.WIPDateNeeded = moment().toDate();
    }

    this.appointmentRequest.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    this.appointmentRequest.PatientTimeZone = this.patientTimeZone;
    this.appointmentRequest.CrmFacilityTimeZone = this.selectedFacilityTimeZone;

    this.appointmentRequest.btNoteId = this.salesOrderData.btNoteID == null ? 0 : this.salesOrderData.btNoteID;
    this.appointmentRequest.isProvisional = this.isProvisional;

    this.appointmentRequest.CrmFacilityName = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityName;
    this.appointmentRequest.AppointmentDuration = this.duration == undefined ? '' : this.duration;
    this.appointmentRequest.CrmTherapistName = this.therapistName == undefined ? '' : this.therapistName;
    this.appointmentRequest.AmountDue = this.dueAmount == undefined ? Number(0) : this.dueAmount;

    this.loadingCustom = true;
    this.appointmentService.ScheduleAppointment(this.appointmentRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.webStorageService.deleteAppointmentConfirmation();
          this.appointmentConfirmation = new AppointmentConfirmation();
          this.appointmentConfirmation.appointmentStartDate = this.selectedStartDate;
          this.appointmentConfirmation.appointmentEndDate = this.selectedEndDate;
          this.appointmentConfirmation.appointmentTimeZone = this.selectedTimeZone.shortName;
          this.appointmentConfirmation.appointmentTimeZoneCode = this.selectedTimeZone.code;
          this.appointmentConfirmation.facilityAddress = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityAddress;
          this.appointmentConfirmation.facilityAddressLine1 = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityAddressLine1;
          this.appointmentConfirmation.facilityAddressLine2 = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityAddressLine2;
          this.appointmentConfirmation.facilityCity = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityCity;
          this.appointmentConfirmation.facilityState = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityState;
          this.appointmentConfirmation.facilityCountry = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityCountry;
          this.appointmentConfirmation.facilityZipCode = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityZipCode;
          this.appointmentConfirmation.facilityName = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityName;
          this.appointmentConfirmation.facilityLatitude = this.selectedFacilityLocal == undefined ? 0 : Number(this.selectedFacilityLocal.crmFacilityLatitude);
          this.appointmentConfirmation.facilityLongitude = this.selectedFacilityLocal == undefined ? 0 : Number(this.selectedFacilityLocal.crmFacilityLongitude);
          this.appointmentConfirmation.crmBioData = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmBioData;
          this.appointmentConfirmation.distance = this.selectedFacilityLocal == undefined ? '' : this.selectedFacilityLocal.crmFacilityDistanceInMiles;
          this.appointmentConfirmation.duration = this.duration == undefined ? '' : this.duration;
          this.appointmentConfirmation.description = this.description == undefined ? '' : this.description;
          this.appointmentConfirmation.dueAmount = this.dueAmount == undefined ? Number(0) : this.dueAmount;
          this.appointmentConfirmation.crmTherapistname = this.therapistName == undefined ? '' : this.therapistName;
          this.appointmentConfirmation.remoteType = this.selectedRemoteAppointmentType;
          this.appointmentConfirmation.selectedAppointmentTypePopUp = this.selectedAppointmentTypePopUp
          this.appointmentConfirmation.durationInMinutes = this.salesOrderData.crmClassificationDuration == 1 ? 60 : this.salesOrderData.crmClassificationDuration;
          this.appointmentConfirmation.isTherapistFacilityType = this.isTherapistFacilityType;
          this.appointmentConfirmation.isTherapistRemoteType = this.isTherapistRemoteType;
          this.appointmentConfirmation.isTherapistRemoteAndFaciltyType = this.isTherapistRemoteAndFaciltyType;
          this.webStorageService.storeAppointmentConfirmation(this.appointmentConfirmation);
          this.webStorageService.deleteSelectedFacilies();
          this.webStorageService.deleteSalesOrderId();
          this.loadingCustom = false;

          this.router.navigate(['/patient/appointment-summary']);
          // this.router.navigate(['/patient/appointment-confirmation']);
        }
        else {
          if (result.message) {
            this.loadingCustom = false;
            this.showErrorMessage(result.message);

          }
          else {
            this.showErrorMessage("There was problem in scheduling your appointment. Please contact administrator");
          }
        }
      });
  }

  setMobileViewDate(date: Date, itemDate: DisplaySlots) {

    if (this.displaySlots) {
      this.displaySlotsMobile = this.displaySlots.filter(x => x.date == date);
    }
    if (this.prevdisplayDate) {
      this.prevdisplayDate.isToday = false;
    }
    itemDate.isToday = true;
    this.prevdisplayDate = itemDate;
  }

  manageRemoteAppointment(remoteAppintmentType: string) {
    if ((this.isTherapistRemoteAndFaciltyType) || (this.isTherapistRemoteType)) {

    }
    else {
      this.selctedCrmClassificationDuration = this.salesOrderData.crmClassificationDuration;
      //this.selctedCrmClassificationDuration = remoteAppintmentType == "SelfGuided" ? 15 : this.salesOrderData.crmClassificationDuration;
      //this.getRemoteFacilitySlots(this.facilitySlotDate);
      // if (this.selctedCrmClassificationDuration == 15) {
      //   this.duration = this.selctedCrmClassificationDuration.toString() + " Minutes";
      // }
      // else {
     this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
      // this.durationInMinutes = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? 60 : this.salesOrderData.crmClassificationDuration;
      //this.duration = this.useThisSlotDuration.toString() + " Minutes";
      this.durationInMinutes = this.useThisSlotDuration;
      //}

      //this.clearSelection();
    }
  }


  clearSelection() {
    this.selectedStartDate = null;
    this.selectedEndDate = null;
    this.showConfirm = false;
    this.termCondtionSelected = false;
    this.reminderOpted = false;
    this.selectedOrigStartDate = null;
    this.selectedOrigEndDate = null;
    // this.selectedAppointmentTypePopUp = '';
    // this.isTherapistRemoteAndFaciltyType = false;

    // this.isTherapistFacilityType = false;
    // this.isTherapistRemoteType = false;
    // this.selectedFacilityLocalcrmFacilityId = 0;
    // this.selectedFacilityLocalcrmRemoteFacilityId = 0;
  }

  onBack() {
    if (this.patientBookingTypeId === 'F') {
      //this.router.navigate(['/patient/choose-facility']);
      this.activeStepNoEmit.emit(1);
      this.isFacilityOrTherapistSelectionChangedEmit.emit(false);
    }
    if (this.patientBookingTypeId === 'R' || this.patientBookingTypeId === 'SG') {
      let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
      this.router.navigate(['patient/home/' + url]);
    }
    if (this.patientBookingTypeId === 'T') {
      //this.router.navigate(['/patient/choose-therapist']);
      this.activeStepNoEmit.emit(1);
      this.isFacilityOrTherapistSelectionChangedEmit.emit(false);
    }
  }

  onNext() {
    if (!this.selectedStartDate || !this.selectedEndDate) {
      this.showWarningMessage("Select appointment date & time.");
      return;
    }

    if (this.isTherapistFacilityType && !this.selectedAppointmentTypePopUp)

      if (this.isTherapistFacilityType && (this.selectedAppointmentTypePopUp == '' || this.selectedAppointmentTypePopUp == undefined)) {
        this.showWarningMessage("Select any appointment Type.");
        return;
      }
    if (this.isTherapistRemoteAndFaciltyType && (this.selectedAppointmentTypePopUp == '' || this.selectedAppointmentTypePopUp == undefined)) {
      this.showWarningMessage("Select any appointment Type.");
      return;
    }

    let patientBookAppointmentRequestModel: PatientBookAppointmentRequest;
    patientBookAppointmentRequestModel = new PatientBookAppointmentRequest();
    patientBookAppointmentRequestModel.isRescheduleAppointment = this.salesOrderData.schAppointmentId > 0 ? true : false;
    patientBookAppointmentRequestModel.schAppointmentId = this.salesOrderData.schAppointmentId;
    patientBookAppointmentRequestModel.displaySelectedStartDate = this.displaySelectedStartDate;
    patientBookAppointmentRequestModel.selectedTimeZone = this.selectedTimeZone;
    patientBookAppointmentRequestModel.description = this.description;
    patientBookAppointmentRequestModel.duration = this.duration;
    patientBookAppointmentRequestModel.therapistName = this.therapistName;
    patientBookAppointmentRequestModel.isTherapistRemoteType = this.isTherapistRemoteType;
    patientBookAppointmentRequestModel.isTherapistRemoteAndFaciltyType = this.isTherapistRemoteAndFaciltyType;
    patientBookAppointmentRequestModel.isTherapistFacilityType = this.isTherapistFacilityType;
    patientBookAppointmentRequestModel.crmFacilityDistanceInMile = this.crmFacilityDistanceInMile;
    patientBookAppointmentRequestModel.crmFacilityAddress = this.crmFacilityAddress;
    patientBookAppointmentRequestModel.crmFacilityAddressLine1 = this.crmFacilityAddressLine1;
    patientBookAppointmentRequestModel.crmFacilityAddressLine2 = this.crmFacilityAddressLine2;
    patientBookAppointmentRequestModel.crmFacilityCity = this.crmFacilityCity;
    patientBookAppointmentRequestModel.crmFacilityState = this.crmFacilityState;
    patientBookAppointmentRequestModel.crmFacilityCountry = this.crmFacilityCountry;
    patientBookAppointmentRequestModel.crmFacilityZipCode = this.crmFacilityZipCode;
    patientBookAppointmentRequestModel.crmFacilityName = this.crmFacilityName;
    patientBookAppointmentRequestModel.crmfacilityLatitude = Number(this.crmFacilityLatitude);
    patientBookAppointmentRequestModel.crmfacilityLongitude = Number(this.crmFacilityLongitude);
    patientBookAppointmentRequestModel.crmBioData = this.crmBioData;
    patientBookAppointmentRequestModel.selectedRemoteAppointmentType = this.selectedRemoteAppointmentType;
    patientBookAppointmentRequestModel.isProvisional = this.isProvisional;
    patientBookAppointmentRequestModel.selectedAppointmentTypePopUp = this.selectedAppointmentTypePopUp;
    patientBookAppointmentRequestModel.selectedFacilityLocalcrmFacilityId = this.selectedFacilityLocalcrmFacilityId;
    patientBookAppointmentRequestModel.selectedFacilityLocalcrmRemoteFacilityId = this.selectedFacilityLocalcrmRemoteFacilityId;
    patientBookAppointmentRequestModel.selectedFacilityLocal = this.selectedFacilityLocal;
    patientBookAppointmentRequestModel.selectedStartDate = this.selectedStartDate;
    patientBookAppointmentRequestModel.selectedEndDate = this.selectedEndDate;
    patientBookAppointmentRequestModel.selectedOrigStartDate = this.selectedOrigStartDate;
    patientBookAppointmentRequestModel.selectedOrigEndDate = this.selectedOrigEndDate;
    patientBookAppointmentRequestModel.selectedTherapistId = this.selectedTherapistId;
    patientBookAppointmentRequestModel.wipStatusBTMappingResponseModel = this.wipStatusBTMappingResponseModel;
    patientBookAppointmentRequestModel.isDefaultWIPNeededDateAppointmentdate = this.isDefaultWIPNeededDateAppointmentdate;
    patientBookAppointmentRequestModel.patientTimeZone = this.patientTimeZone;
    patientBookAppointmentRequestModel.selectedFacilityTimeZone = this.selectedFacilityTimeZone;
    patientBookAppointmentRequestModel.dueAmount = this.dueAmount;
    patientBookAppointmentRequestModel.salesOrderLocal = this.salesOrderLocal;
    patientBookAppointmentRequestModel.salesOrderData = this.salesOrderData;
    patientBookAppointmentRequestModel.appointmentConfirmation = this.appointmentConfirmation;
    patientBookAppointmentRequestModel.isBrightreeSystemAppUpdate = this.isBrightreeSystemAppUpdate;
    patientBookAppointmentRequestModel.isUpdatePODStatusToBrightree = this.isPODStatusBrightreeSystemAppUpdate;
    patientBookAppointmentRequestModel.isAppendOrderNoteToBrightree = this.isAppendOrderNoteBrightreeSystemAppUpdate;
    this.patientBookAppointmentRequestEmit.emit(patientBookAppointmentRequestModel);

    if (this.patientBookingTypeId === 'F') {
      //this.router.navigate(['/patient/choose-facility']);
      this.activeStepNoEmit.emit(3);
    }
    if (this.patientBookingTypeId === 'R' || this.patientBookingTypeId === 'SG') {
      // let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
      // this.router.navigate(['patient/home/' + url]);
      this.activeStepNoEmit.emit(2);
    }
    if (this.patientBookingTypeId === 'T') {
      //this.router.navigate(['/patient/choose-therapist']);
      this.activeStepNoEmit.emit(3);
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

  }

  initializeAllDefaultModel() {
    this.monthNames = new Array(12);
    this.monthNames[0] = "JAN";
    this.monthNames[1] = "FEB";
    this.monthNames[2] = "MAR";
    this.monthNames[3] = "APR";
    this.monthNames[4] = "MAY";
    this.monthNames[5] = "JUN";
    this.monthNames[6] = "JUL";
    this.monthNames[7] = "AUG";
    this.monthNames[8] = "SEP";
    this.monthNames[9] = "OCT";
    this.monthNames[10] = "NOV";
    this.monthNames[11] = "DEC";

    this.monthNamesNewFormat = new Array(12);
    this.monthNamesNewFormat[1] = "JAN";
    this.monthNamesNewFormat[2] = "FEB";
    this.monthNamesNewFormat[3] = "MAR";
    this.monthNamesNewFormat[4] = "APR";
    this.monthNamesNewFormat[5] = "MAY";
    this.monthNamesNewFormat[6] = "JUN";
    this.monthNamesNewFormat[7] = "JUL";
    this.monthNamesNewFormat[8] = "AUG";
    this.monthNamesNewFormat[9] = "SEP";
    this.monthNamesNewFormat[10] = "OCT";
    this.monthNamesNewFormat[11] = "NOV";
    this.monthNamesNewFormat[12] = "DEC";
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC+5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC-5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'Eastern Time', displayCode: 'UTC-5', countryName: 'America/New_York' },
      { name: 'Central Time (UTC-6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central Time', displayCode: 'UTC-6', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC-7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MT', displayName: 'Mountain Time', displayCode: 'UTC-7', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Arizona Mountain Time', displayCode: 'UTC-7', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC-8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific Time', displayCode: 'UTC-8', countryName: 'America/Los_Angeles' },
    ];
    //this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);

    this.selectedTimeZone = this.timeZone.find(x => x.orgcode == this.patientTimeZone);
  }

  initializePatientTimeZone() {
    let patientTimeZone = this.isBillingAddress ? this.salesOrderData.patientInfo.crmBillingAddressTimeZone : this.salesOrderData.crmDeliveryAddressTimeZone;
    this.patientTimeZone = patientTimeZone;
    let appointmentTimeZoneShortName = new TimezonePipe(this.webStorageService).transform(patientTimeZone);

    //add selected time zone on patient time zone
    //this.selectedTimeZone = this.timeZone.find(x => x.code == this.timeZone.find(x => x.orgcode == this.patientTimeZone).code);

    //this.selectedTimeZone = this.timeZone.find(x => x.shortName == appointmentTimeZoneShortName);

  }


  MonthDifference(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    months = (d2.getMonth() - d1.getMonth()) + 1 + (12 * (d2.getFullYear() - d1.getFullYear()));
    return months <= 0 ? 0 : months;

  }

  checkforthePrevFlag(facilitySlotDate: Date, crmFirstAvailableStartDate: Date) {
    let format = "MM-DD-YYYY";

    //if future date not set
    if (!this.isAvailableSlot && crmFirstAvailableStartDate == undefined) {
      crmFirstAvailableStartDate = facilitySlotDate;
    }

    // to check if there no availability for future dates
    //crmFirstAvailableStartDate == undefined ? this.facilitySlotDate : crmFirstAvailableStartDate;
    let startDate = moment(crmFirstAvailableStartDate).toDate();
    let endDate = moment(facilitySlotDate).toDate();
    let startDateFormat = moment(startDate, format);
    let endDateFormat = moment(endDate, format);

    // console.log(startDateFormat.isSame(endDateFormat, 'day'));
    // console.log(startDateFormat.isSame(endDateFormat, 'date'));

    let isSameOrAfter = startDateFormat.isSameOrAfter(endDateFormat);
    let isSameOrbefore = startDateFormat.isSameOrBefore(endDateFormat);
    let issameDaye = endDateFormat.isSame(startDateFormat, 'day');

    if (isSameOrAfter) {
      this.showPrev = false;
    }
    else if (issameDaye) {
      this.showPrev = false;
    }
    else
      this.showPrev = true;
  }

  isSlotDisabled(date) {
    //let datePipeString = formatDate(Date.now(), 'yyyy-MM-dd', this.locale, this.selectedTimeZone.code);
    let currentDate = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.selectedTimeZone.code);
    //let currentDate = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy', this.timeZone.find(x => x.orgcode == this.patientTimeZone).code);
    //let currentDate = new DatePipe(this.locale).transform(new Date(), 'MMM DD YYYY', this.appDateFormat.timeZone);
    if (moment(date).toDate() < moment(currentDate).toDate())
      return true;
    else
      return false;
  }
  
  isSlotsSelect(date) {
    let slotDate = moment(date).toDate();

    if (slotDate >= this.selectedStartDate && slotDate < this.selectedEndDate) {
      return true;
    }
    else
      return false;
  }

  setFacilityData() {

    this.crmFacilityDistanceInMile = this.selectedFacilityLocal.crmFacilityDistanceInMiles;
    this.crmFacilityAddress = this.selectedFacilityLocal.crmFacilityAddress;
    this.crmFacilityAddressLine1 = this.selectedFacilityLocal.crmFacilityAddressLine1;
    this.crmFacilityAddressLine2 = this.selectedFacilityLocal.crmFacilityAddressLine2;
    this.crmFacilityCity = this.selectedFacilityLocal.crmFacilityCity;
    this.crmFacilityState = this.selectedFacilityLocal.crmFacilityState;
    this.crmFacilityCountry = this.selectedFacilityLocal.crmFacilityCountry;
    this.crmFacilityZipCode = this.selectedFacilityLocal.crmFacilityZipCode;
    this.crmFacilityLatitude = this.selectedFacilityLocal.crmFacilityLatitude;
    this.crmFacilityLongitude = this.selectedFacilityLocal.crmFacilityLongitude;
    this.crmBioData = this.selectedFacilityLocal.crmBioData;

    this.crmFacilityWeekDaysAvailability = this.selectedFacilityLocal.crmFacilityWeekDaysAvailability;
    this.crmFacilityWeekendAvailability = this.selectedFacilityLocal.crmFacilityWeekendAvailability;

    let firstAvailabilityDate = this.selectedFacilityLocal.crmFacilityFirstAvailableStartDate;
    //this.firstAvailabilityDate = firstAvailabilityDate;

    this.facilityAvailableWeekDays = this.selectedFacilityLocal.facilityAvailableWeekDays;
    this.facilityAvailableWeekend = this.selectedFacilityLocal.facilityAvailableWeekend;

    let mostMinDate = new Date(Math.min.apply(null, this.facilityAvailableWeekDays.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMinDate.getTime())) {
      this.selectedFacilityWeekDayMinDate = this.facilityAvailableWeekDays.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMinDate.getDate();
      })[0];
    }

    let mostMaxDate = new Date(Math.max.apply(null, this.facilityAvailableWeekDays.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMaxDate.getTime())) {
      this.selectedFacilityWeekDayMaxDate = this.facilityAvailableWeekDays.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMaxDate.getDate();
      })[0];
    }

    let mostMinWeekendDate = new Date(Math.min.apply(null, this.facilityAvailableWeekend.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMinWeekendDate.getTime())) {
      this.selectedFacilityWeekendMinDate = this.facilityAvailableWeekend.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMinWeekendDate.getDate();
      })[0];
    }


    let mostMaxWeekendDate = new Date(Math.max.apply(null, this.facilityAvailableWeekend.map(e => {
      return moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
    })));

    if (!isNaN(mostMaxWeekendDate.getTime())) {
      this.selectedFacilityWeekendMaxDate = this.facilityAvailableWeekend.filter(e => {
        var d = moment(e.startTime, 'YYYY-MM-DD HH:mm').toDate();
        return d.getDate() == mostMaxWeekendDate.getDate();
      })[0];
    }

    this.facilitySlotDate = new Date(firstAvailabilityDate);
    this.minAllowDateToBookAppoitment = new Date(firstAvailabilityDate);
  }
}
