import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DashboardService } from '../../servicies/dashboard.service';
import { Router } from '@angular/router';
import { UserService } from '../../servicies/user.service';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { MessageService } from 'primeng/api';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
More(Highcharts);
import AnnotationsModule from 'highcharts/modules/annotations'
AnnotationsModule(Highcharts);
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data'
ExportData(Highcharts);
import highcharts3d from 'highcharts/highcharts-3d';
highcharts3d(Highcharts);
import highstock from 'highcharts/modules/stock';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentCountForTodaysDashboardResponseModel, TodaysScheduledAppointmentDashboardResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import * as moment from 'moment';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import html2canvas from 'html2canvas';
highstock(Highcharts);

@Component({
  selector: 'app-today-dashboard',
  templateUrl: './today-dashboard.component.html',
  styleUrls: ['./today-dashboard.component.sass']
})
export class TodayDashboardComponent extends BaseComponent implements OnInit {
  title = 'Dashboard - Todays Stattu';
  changesPage: { name: string; code: string; }[];
  TodayDates: string;
  checked: boolean = false;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  currentUserId: number;
  loggedInUserId: number;
  appointmentCountforTodaysDashboardResponseModel: AppointmentCountForTodaysDashboardResponseModel;
  todaysScheduledAppointmentDashboardResponseModel: TodaysScheduledAppointmentDashboardResponseModel;
  viewDate = new Date();
  appDateFormat: DateFormatModel;
  maxDateToDisplay: Date;
  todaysScheduledApptCnt: number;
  bookedAppointmentsCnt: number;
  //newSalesOrdersCnt: number;
  noShowCnt: number;
  completedCnt: number;
  voidCnt: number;
  currentUserBranch: number;
  searchRequestForRedirection: SearchRequestModel;
  iBookedId: number = 2;
  isTodaysSummaryTrue: boolean = false;
  todaysDate: string = `Day Glance View -`;
  statusViewOfSlectedDay: string = 'Today’s Status View';
  enloggedInUserId: string;

  constructor(private dashboardService: DashboardService,
    private router: Router,
    private webStorageService: WebStorageService,
    private userService: UserService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService) {
    super(messageService)
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.currentUserBranch = this.webStorageService.getUserBranch();
    var currentUserToken = this.webStorageService.getCurrentUserToken();
    this.currentUserId = currentUserToken.userId;
    this.loggedInUserId = currentUserToken.userId;
    this.enloggedInUserId = currentUserToken.enuserId;

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {

      if (currentNavigationState && currentNavigationState.searchRequestModel) {
       
       this.todaysDate = moment(currentNavigationState.searchRequestModel.BookingSearchFromDateString).format("MM/DD/yyyy");
        this.viewDate = new Date(moment(currentNavigationState.searchRequestModel.BookingSearchFromDateString).format("MM/DD/yyyy"));        
        if (this.todaysDate == moment(new Date()).format('MM/DD/YYYY')){
          this.statusViewOfSlectedDay = 'Today’s Status View';
          this.todaysDate = `Day Glance View -`;
        }
        else
          this.statusViewOfSlectedDay = this.todaysDate + ' - ' + 'Status View'; 
          this.checked = true;
      }
      this.isTodaysSummaryTrue = currentNavigationState.todaysSummary ? currentNavigationState.todaysSummary : false;
      if (this.isTodaysSummaryTrue) {
        this.checked = true;
      }
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    }
    else if (this.router.url == "/admin/today-dashboard") {
      this.checked = true;
    }
  }

  ngOnInit(): void {

    //this.TodayDates = today.getMonth() + '/' + today.getDate() + '/' + today.getFullYear();

    this.changesPage = [
      { name: 'Switch to Summay View', code: '' },
      { name: 'Switch Todays Status', code: '' }
    ];

    // this.getDMEPatientScheduled();
    // this.getDMEPatientRescheduled();
    // this.getDMEPatientCancelled();
    this.GetTodaysDashboardCount(false);
    this.GetTodaysScheduleAppointmentDashboardCount(false);

    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null) {
        this.currentUserBranch = branchId;
        this.GetTodaysDashboardCount(false);
        this.GetTodaysScheduleAppointmentDashboardCount(false);

      } else {
        //this.getAllFacilitiesBranch('0');
      }
    });
  }
  initializeSearchForRedirection() {
    this.searchRequestForRedirection = new SearchRequestModel();
    this.searchRequestForRedirection.crmBranchId = this.currentUserBranch;
    this.searchRequestForRedirection.appUserInfoId = this.currentUserId;
    this.searchRequestForRedirection.dictionaryFilter = {};
    this.searchRequestForRedirection.appUserInfoId = this.loggedInUserId;
    this.searchRequestForRedirection.FirstRecordIndex = 0;
    this.searchRequestForRedirection.CurrentPageIndex = 0;
    this.searchRequestForRedirection.PageSize = +this.pageSizes[0];
    this.searchRequestForRedirection.SortOrder = 'ASC';
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.searchRequestForRedirection.CurrentPageIndex = 0;
    this.searchRequestForRedirection.FirstRecordIndex = 0;
    this.searchRequestForRedirection.isFromDashboard = true;
  }
  /****** Todays Appointments ****/
  getSubtitle() {
    // const population = (getData(input.value)[0][1] / 1000000000).toFixed(2);
    return `<span class="highcharts-subtitle__title">${this.todaysScheduledApptCnt}</span>`;
  }
  getTodayAppointmentBooked() {

    // Create the chart
    const series: any[] = [];
    const data: any[] = [];
    var that = this;

    this.todaysScheduledAppointmentDashboardResponseModel?.todaysScheduledAppointmentLineCharts?.forEach(x => {
      data.push({
        name: x.appointmentStatus,
        y: x.apptCount,
        drilldown: x.appointmentStatus
      })
    })
    const options: any = {
      chart: {
        type: 'column'
      },
      colors: [
        '#FF6384',
        '#36A2EB',
        '#4BF266',
        '#9966FF',
        '#4BC0C0',
        '#FF9F40',
        '#C9CBCF',
        '#A38331'
      ],
      title: {
        align: 'left',
        text: ''
      },
      subtitle: {
        useHTML: true,
        // text: getSubtitle(),
        floating: true,
        align: 'right',
        verticalAlign: 'top',
        //y: 12,
        x: 0
      },
      accessibility: {
        announceNewData: {
          enabled: true
        }
      },
      xAxis: {
        type: 'category',
        min: 0
      },
      yAxis: {
        title: {
          text: '# of Appointments'
        },
        min: 0,
        stackLabels: {
          enabled: true
        }
        // minPadding: 0,
        // maxPadding: 0,
        // min: 0,
        // tickInterval: 1,
        // minRange: 1,
        // allowDecimals: false,
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}'
          }
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}'
      },
      series: [
        {
          name: 'Appointment',
          colorByPoint: true,
          data: data,
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                that.todayScheduledAppointmentClick(this.name);
              }
            }
          }
        }

      ],

      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    };

    Highcharts.chart('TodayAppointmentBooked', options);
  }
  /****** Scheduled Appt ****/

  getDMEPatientScheduled() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentScheduledCnt?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentScheduledCnt?.forEach(x => {
        data.push({
          name: x.schAppointmentBookedBy == 'DME' ? 'Scheduler' : 'Patient',
          y: x.scheduledApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Patient',
        y: 0
      }, {
        name: 'Scheduler',
        y: 0
      })
    }

    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },
     
      title: {
        text: '',
        align: 'left'
      },
      
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name}: {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
        }
      },
      plotOptions: {
        pie: {
          //allowPointSelect: true,
          borderWidth: 2,
          cursor: 'pointer',

          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 20,
            // style: {
            //     fontSize: '1.2em',
            //     textOutline: 'none',
            //     opacity: 1
            // }
          },
          showInLegend: true,
         
        }
      },

      series: [{
        //enableMouseTracking: false,
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.scheduledAppointmentClick(this.name);
            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientScheduled', options);
  }
  /****** Rescheduled  Appt ****/
  getDMEPatientRescheduled() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentRescheduledCount?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentRescheduledCount?.forEach(x => {
        data.push({
          name: x.reSchAppointmentBookedBy == 'DME' ? 'Scheduler' : 'Patient',
          y: x.rescheduledApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Patient',
        y: 0
      }, {
        name: 'Scheduler',
        y: 0
      })
    }

    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      // accessibility: {
      //   point: {
      //     valueSuffix: ''
      //   }
      // },
      // tooltip: {
      //   pointFormat: '{point.name}: <br>{point.percentage} %<br>value: {point.y}'
      // },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name}: {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      plotOptions: {
        pie: {
          //allowPointSelect: true,
          //borderWidth: 2,
          //cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 20,
          },
          showInLegend: true,
          //center: [null, null],
          //size: '100%'
        }
      },

      series: [{
        //enableMouseTracking: false,
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.rescheduledAppointmentClick(this.name);
            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientRescheduled', options);
  }
  /****** Cancelled   Appt ****/
  getDMEPatientCanceled() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentCancelledCount?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentCancelledCount?.forEach(x => {
        data.push({
          name: x.canAppointmentBookedBy == 'DME' ? 'Scheduler' : 'Patient',
          y: x.cancelledApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Patient',
        y: 0
      }, {
        name: 'Scheduler',
        y: 0
      })
    }


    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name} : {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 25,
          },
          showInLegend: true,
        }
      },

      series: [{
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.cancelAppointmentClick(this.name);
            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientCanceled', options);
  }

  /****** NoShow   Appt ****/
  getDMEPatientNoShow() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentNoShowCount?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentNoShowCount?.forEach(x => {
        data.push({
          name: x.noShowAppointmentBookedBy,
          y: x.noShowApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Therapist',
        y: 0
      }, {
        name: 'Scheduler',
        y: 0
      })
    }


    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name} : {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 25,
          },
          showInLegend: true,
        }
      },

      series: [{
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.noShowAppointmentClick(this.name);

            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientNoShow', options);
  }



  getDMEPatientComplete() {

    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentCompleteCount?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentCompleteCount?.forEach(x => {
        data.push({
          name: x.completeAppointmentBookedBy,
          y: x.completeApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Therapist',
        y: 0
      }, {
        name: 'Scheduler',
        y: 0
      })
    }


    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name} : {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 25,
          },
          showInLegend: true,
        }
      },

      series: [{
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.completeAppointmentClick(this.name);

            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientComplete', options);
  }

  getDMEPatientVoid() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentVoidCount?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentVoidCount?.forEach(x => {
        data.push({
          name: x.voidAppointmentBookedBy,
          y: x.voidApptCnt
        })
      })
    }
    else {
      data.push({
        name: 'Void',
        y: 0
      }, {
        name: 'VoidReview',
        y: 0
      })
    }


    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name} : {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 25,
          },
          showInLegend: true,
        }
      },

      series: [{
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.voidAppointmentClick(this.name);

            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientVoid', options);
  }

  getDMENewSalesOrder() {
    const data: any[] = [];
    var that = this;
    if (this.appointmentCountforTodaysDashboardResponseModel?.appointmentNewSalesOrderdCnt?.length > 0) {
      this.appointmentCountforTodaysDashboardResponseModel?.appointmentNewSalesOrderdCnt?.forEach(x => {
        data.push({
          name: x.syncBy,
          y: x.newSalesOrders
        })
      })
    }
    else {
      data.push({
        name: 'Auto',
        y: 0
      }, {
        name: 'Manual',
        y: 0
      })
    }


    const options: any = {
      colors: [
        '#FF9F40',
        '#36A2EB'
      ],
      chart: {
        type: 'pie',
        renderTo: 'container'
        // height: 400
      },

      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        headerFormat: '<span style="font-size:16px">{point.name}</span><br>',
        pointFormat: '{point.name} : {point.percentage:.1f} %<br>value: {point.y}',
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },

      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.y}',
            distance: 25,
          },
          showInLegend: true,
        }
      },

      series: [{
        animation: {
          duration: 2000
        },
        colorByPoint: true,
        data: data,
        cursor: 'pointer',
        point: {
          events: {
            click: function (event) {
              that.newSalesOrderClick();

            }
          }
        }
      }],
      legend: {
        padding: 3,
        itemMarginRight: 5,
        itemMarginLeft: 5,
        style: {
          fontSize: '1.2em',
          textOutline: 'none',
          opacity: 1
      }
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
    }
    Highcharts.chart('DMEPatientNewSalesOrder', options);
  }


  private async GetTodaysDashboardCount(isRefresh: boolean) {
    //this.loading = true;
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.dashboardService.GetTodaysDashboardCount(false, this.enloggedInUserId, selectedDate, this.currentUserBranch)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        this.loading = false;
        if (!results) {
          return;
        }

        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.appointmentCountforTodaysDashboardResponseModel = results.responseModel;
            this.bookedAppointmentsCnt = this.appointmentCountforTodaysDashboardResponseModel.bookedAppointment;
            // this.newSalesOrdersCnt = this.appointmentCountforTodaysDashboardResponseModel.newSalesOrders;
            // this.noShowCnt = this.appointmentCountforTodaysDashboardResponseModel.noShowApptCnt;
            //this.completedCnt = this.appointmentCountforTodaysDashboardResponseModel.completeApptCnt;
            //this.voidCnt = this.appointmentCountforTodaysDashboardResponseModel.voidApptCnt;

            this.getDMENewSalesOrder();
            this.getDMEPatientScheduled();
            this.getDMEPatientRescheduled();
            this.getDMEPatientCanceled();
            this.getDMEPatientNoShow();
            this.getDMEPatientComplete();
            this.getDMEPatientVoid();
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetTodaysScheduleAppointmentDashboardCount(isRefresh: boolean) {
    //this.loading = true;
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.dashboardService.GetTodaysScheduleAppointmentDashboardCount(false, this.enloggedInUserId, selectedDate, this.currentUserBranch)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        this.loading = false;
        if (!results) {
          return;
        }

        if (results.isSuccess) {
          if (results.responseModel != undefined && results.responseModel != null) {
            this.todaysScheduledAppointmentDashboardResponseModel = results.responseModel;
            this.TodayDates = this.todaysScheduledAppointmentDashboardResponseModel.dtRefreshDate;
            this.todaysScheduledApptCnt = this.todaysScheduledAppointmentDashboardResponseModel.scheduledApptCnt;
            this.getTodayAppointmentBooked();
          }
        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async OnDateChange() {
    let today = new Date();
    this.todaysDate = moment(this.viewDate).format('MM/DD/YYYY');
    if (this.todaysDate == moment(today).format('MM/DD/YYYY')){
      this.statusViewOfSlectedDay = 'Today’s Status View';
      this.todaysDate = `Day Glance View -`;
    }
    else
      this.statusViewOfSlectedDay = this.todaysDate + ' - ' + 'Status View'; 
    this.GetTodaysDashboardCount(false);
    this.GetTodaysScheduleAppointmentDashboardCount(false);
  }

  showTodaysStatus() {
    if (!this.checked) {
      this.router.navigate(['admin/dashboard']);
    }
  }

  async RefreshPage() {
    this.webStorageService.deleteRandomNumber();
    let irndNumber = Math.floor((Math.random() * 100) + 1);
    this.webStorageService.storeRandomNumber(irndNumber);
    await this.GetTodaysDashboardCount(true);
    await this.GetTodaysScheduleAppointmentDashboardCount(true);
    this.showInfoMessage("Dashboard data is refreshed");
  }

  bookedAppointmentClick() {
    this.initializeSearchForRedirection();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    this.searchRequestForRedirection.AppointmentStatus = 'Booked';
    this.searchRequestForRedirection.DisplayTitle = "Booked Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  noShowAppointmentClick(name) {
    this.initializeSearchForRedirection();
    if (name == "Scheduler") {
      this.searchRequestForRedirection.scheduledBy = 'Scheduler';
    }
    else {
      this.searchRequestForRedirection.scheduledBy = 'Therapist';
    }  
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.isUnreconciled = false;
    this.searchRequestForRedirection.AppointmentStatus = 'NoShow';
    this.searchRequestForRedirection.DisplayTitle = "No Show Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  completeAppointmentClick(name) {
    this.initializeSearchForRedirection();
    if (name == "Scheduler") {
      this.searchRequestForRedirection.scheduledBy = 'Scheduler';
    }
    else {
      this.searchRequestForRedirection.scheduledBy = 'Therapist';
    }   
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = 'Complete';
    this.searchRequestForRedirection.DisplayTitle = "Complete Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  voidAppointmentClick(name) {
    this.initializeSearchForRedirection();
    let apptType = 'Void';
    let title = 'Void Orders';
    if (name == "Void Review") {
      apptType = 'VoidReview';
      title = 'Potential Void Orders';
    }
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = apptType;
    this.searchRequestForRedirection.DisplayTitle = title;
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  newSalesOrderClick() {
    this.initializeSearchForRedirection();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = 'ReadyForScheduling';
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  rescheduledAppointmentClick(name) {
    this.initializeSearchForRedirection();
    if (name == "Patient") {
      this.searchRequestForRedirection.scheduledBy = 'Patient';
    }
    else {
      this.searchRequestForRedirection.scheduledBy = 'Scheduler';
    }

    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.isUnreconciled = false;
    this.searchRequestForRedirection.isRescheduled = true;
    this.searchRequestForRedirection.AppointmentStatus = 'Rescheduled';
    this.searchRequestForRedirection.DisplayTitle = "Rescheduled Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  scheduledAppointmentClick(name) {
    this.initializeSearchForRedirection();
    if (name == "Patient") {
      this.searchRequestForRedirection.scheduledBy = 'Patient';
    }
    else {
      this.searchRequestForRedirection.scheduledBy = 'Scheduler';
    }

    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = 'Scheduled';
    this.searchRequestForRedirection.DisplayTitle = "Scheduled Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  cancelAppointmentClick(name) {
    this.initializeSearchForRedirection();
    if (name == "Patient") {
      this.searchRequestForRedirection.scheduledBy = 'Patient';
    }
    else {
      this.searchRequestForRedirection.scheduledBy = 'Scheduler';
    }
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = 'Cancel';
    this.searchRequestForRedirection.DisplayTitle = "Canceled Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  todayAppointmentClick() {
    this.initializeSearchForRedirection();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
    let startDate = moment(selectedDate).format("yyyy/MM/DD");
    let endDate = moment(selectedDate).format("yyyy/MM/DD");
    this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
    this.searchRequestForRedirection.BookingSearchToDateString = endDate;
    this.searchRequestForRedirection.AppointmentStatus = 'Booked';
    this.searchRequestForRedirection.isTodaysBooking = true;
    this.searchRequestForRedirection.DisplayTitle = "Scheduled Appointments";
    this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
  }

  todayScheduledAppointmentClick(name) {
    if (name == "Rescheduled") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.isUnreconciled = false;
      this.searchRequestForRedirection.isRescheduled = true;
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.AppointmentStatus = 'Rescheduled';
      this.searchRequestForRedirection.DisplayTitle = "Rescheduled Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else if (name == "Canceled") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.AppointmentStatus = 'Cancel';
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.DisplayTitle = "Canceled Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else if (name == "No Show") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.AppointmentStatus = 'NoShow';
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.DisplayTitle = "NoShow Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else if (name == "Completed") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.AppointmentStatus = 'Complete';
      this.searchRequestForRedirection.DisplayTitle = "Complete Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else if (name == "Void") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.AppointmentStatus = 'Void';
      this.searchRequestForRedirection.DisplayTitle = "Void Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
    else if (name == "Void Review") {
      this.initializeSearchForRedirection();
      var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
      this.searchRequestForRedirection.SortByColumnName = "crmPatientName";
      let startDate = moment(selectedDate).format("yyyy/MM/DD");
      let endDate = moment(selectedDate).format("yyyy/MM/DD");
      this.searchRequestForRedirection.BookingSearchFromDateString = startDate;
      this.searchRequestForRedirection.BookingSearchToDateString = endDate;
      this.searchRequestForRedirection.isTodaysBooking = true;
      this.searchRequestForRedirection.AppointmentStatus = 'VoidReview';
      this.searchRequestForRedirection.DisplayTitle = "Void Review Appointments";
      this.router.navigate(['/admin/appointments/todays-dashboard-listing'], { state: { searchRequestModel: this.searchRequestForRedirection, selectedTab: 0, isBookAppintment: true, selectedfilterIndex: 0, istodaysSummaryDashboard: true } });
    }
  }

 
  onExport()
  {
    this.loading = true; 
    html2canvas(document.querySelector('#capture'))
      .then(canvas => {
        canvas.style.display = 'none';
        document.body.appendChild(canvas);
        return canvas;
      })
      .then(canvas => {
        //const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const image = canvas.toDataURL('image/png');
        const a = document.createElement('a');
        a.setAttribute('download', this.statusViewOfSlectedDay + '.png');
        a.setAttribute('href', image);
        a.click();
        canvas.remove();
        this.loading = false;
      })
  } 
}
