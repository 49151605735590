
import { AppointmentCallResponseModel } from './appointment-call-response-model';
export class SalesOrderSummaryResponseModel {
  crmSalesOrderAPPId: string;
  crmSalesOrderId: number;
  crmBranch: string;
  crmBranchIsAllowSelfScheduleAppointments: boolean;
  crmPatientId: number;
  crmPatientName: string;
  crmPatientBirthDate: Date;
  crmPatientFirstName: string;
  crmPatientLastName: string;
  crmPatientPhoneNumber: string;
  crmPatientMobileNumber: string;
  crmPatientEmail: string;
  crmSalesorderStatus: string;
  crmSalesorderClassificationType: number;
  crmDeviceName: string;
  crmClassification: string;
  crmClassificationDescription: string;
  amountDue: number;
  crmSalesOrderDate: Date;
  crmDoctorName: string;
  crmDoctorEmail: string;
  crmDoctorPhone: string;
  crmClassificaitonduration: number;
  crmOrderNote: string;
  crmDeliveryNote: string;
  calllogs: AppointmentCallResponseModel[];
  crmPrimaryInsurancePolicy: string;
  crmPrimaryInsuranceCompany: string;
  crmPrimaryInsuranceEffectiveDate: Date;
  crmPrimaryInsuranceVerified: boolean;
  crmPrimaryInsuranceConverageVerified: boolean;
  crmSecondaryInsurancePolicy: string;
  crmSecondaryInsuranceCompany: string;
  crmSecondaryInsuranceEffectiveDate: Date;
  crmSecondaryInsuranceVerified: boolean;
  crmSecondaryInsuranceConverageVerified: boolean;
  schIsLocked: boolean;
  schAppointmentCount: number;
  schAppointmentCallListId: number;
  schAppointmentLockedBy: number;
  schAppointmentLockedByPerson: string;
  crmNoShowCount: string;
  crmCancelCount: string;
  crmAwaitingCallCount: string;
  crmCallCount: number;
  dateTimeCreated: Date;
  appointmentId: number;
  crmIsCallBack: boolean;
  schAppointmentDateTime: Date;
  schAppointmentId: number;
  isScheduled: boolean;
  crmPatientBrightreeID: number;
  crmDaysInState: number;
  crmDaysInWIPState: string;
  crmFutureCallBackDate: Date;
  crmRequestCallBackDate: Date;
  timeZone: string;
  crmIsEnableAppointmentMaxLimit: boolean;
  crmMaxNoOfAppointment: number;
  crmClassificationTypeBookedAppointmentCount: number;
  schAppointmentBookedBy: number;
  schReason: string
  schComments: string
  crmFacilityTimeZone: string
  crmPatientTimeZone: string
  crmVoidedBy: string
  schRTAppointmentDateTime: Date;
  dateTimeLastModified: Date;
  crmPolicyHolderName: string;
  lastCallDate: Date;
  isStatusCompleted: boolean = false;
}
