import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { PatientService } from '../services/patient.service';
import { SettingsService } from '../services/settings.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.sass']
})
export class StepsComponent implements OnInit {
  @Input() patientName: string;
  appointmentType: string;
  appointmentTitle: string;
  appointmentSelectLabel: string;
  //patientName: string;
  salesORderFromLocal: any;
  appDateFormat: DateFormatModel;
  value: number = 16;
  
  constructor(private patientService: PatientService, private appointmentService: AppointmentService,
    private router: Router, messageService: MessageService, private _cdr: ChangeDetectorRef,
    private settingService: SettingsService, private webStorageService: WebStorageService,) { 

      this.appDateFormat = this.webStorageService.getDateFormat()
      this.appointmentType = this.webStorageService.getPatientBookingTypeId();      
      if(this.appointmentType=="R"){
        this.appointmentTitle="Remote Setup";
        this.appointmentSelectLabel="Select Remote";
      }
      else if(this.appointmentType=="T"){
        this.appointmentTitle="Book Therapist";
        this.appointmentSelectLabel="Select Therapist";
      }
      else if(this.appointmentType=="SG"){
        this.appointmentTitle="Self Guided";
        this.appointmentSelectLabel="Self Guided";
      }
      else if(this.appointmentType=="F"){
        this.appointmentTitle="Clinic Visit";
        this.appointmentSelectLabel="Select Clinic";
      }
    }

  ngOnInit(): void {
    // this.patientName = this.salesORderFromLocal.patientName;
  }

  onBack() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(['patient/home/' + url]);
  }

}
