import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/components/base.component';
import { DateFormatModel } from '../../../shared/models/common/date-format-model';
import { AppointmentHistoryResponseModel } from '../../../shared/models/response/appointment/appointment-history-response-model';
import { WebStorageService } from '../../../shared/services/web-storage.service';
import { AppointmentService } from '../../servicies/appointment.service';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model';
import { userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { UserPermissions } from 'src/app/shared/enums';

@Component({
  selector: 'app-sales-order-history',
  templateUrl: './sales-order-history.component.html',
  styleUrls: ['./sales-order-history.component.sass']
})
export class SalesOrderHistoryComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  loadingHistory: boolean;
  salesOrderHistoryResponseModel: AppointmentHistoryResponseModel[];
  soHistorydisplay: boolean;
  loggedInUserId: number;
  //salesOrderId: number;
  appDateFormat: DateFormatModel;
  @Input() salesOrderId: any;
  getSaleOrderId: number;
  appointmentCallResponseModel: AppointmentCallResponseModel;
  audioPlayerPopup: boolean = false;
  appointmentRecordingSID:number;
  appointmentCallerName:string;
  appointmentCallDateTime:Date;
  userPerissionfeatures:userModuleFeatureResponseModel[];
  allowToListenOthersRecording:boolean=false;
  allowToListenOwnRecording:boolean=false;
  clientIdNew: number;
  isVoiceCallRecordingDisplayNew: boolean = true;
  constructor(private router: Router,
    private appointmentService: AppointmentService,
    private webStorageService: WebStorageService,
    messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private dialougeService: DialogService,) {
    super(messageService);

    var clientdetail = this.webStorageService.getClientConfig();
    this.clientIdNew = Number(clientdetail.companyId);
    var channelArray: Array<Number> = [9,12,7,13,14,15,18,20,21,1008]; //Allow to use Nums and Preprod clients
    this.isVoiceCallRecordingDisplayNew = channelArray.includes(this.clientIdNew) ? true : false;

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.setUserPermissionforActions();
    
  }

  ngOnInit(): void {
    if (this.salesOrderId)
      this.getSaleOrderId = this.salesOrderId;
      this.GetSalesOrderHisotry();
  }

  private GetSalesOrderHisotry() {

    this.loadingHistory = true;
    this.appointmentService.GetSalesOrderHistoryBySalesOrderId(this.salesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingHistory = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentHistoryResponseModel[]>(result, () =>
        {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.salesOrderHistoryResponseModel = result.responseModel;
              if (this.salesOrderHistoryResponseModel.length > 0)
                this.soHistorydisplay = true;
              else {
                this.soHistorydisplay = false;
                this.showInfoMessage("No record found");
              }
              this.loadingHistory = false;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.loadingHistory = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setUserPermissionforActions() {
    this.userPerissionfeatures = this.webStorageService.getUserPermissions();
    if (this.userPerissionfeatures && this.userPerissionfeatures.length > 0) {
      let inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToListenOtherUsersRecordings);
      if (inPer1) {
        this.allowToListenOthersRecording = true;
      }

      inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AccessToRecordingLogs);
      if (inPer1) {
        this.allowToListenOwnRecording = true;
      }
    }
  }

  onRecordPlay(RecordId: string) {
    this.appointmentService.FetchRecordUrl(RecordId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != null && result.responseModel != undefined) {
            this.appointmentCallResponseModel = new AppointmentCallResponseModel();
            this.appointmentCallResponseModel = result.responseModel;

            if (this.appointmentCallResponseModel.recordCallUrl != null)
            {
              var recordCallUrl =this.appointmentCallResponseModel.recordCallUrl;
              let recordUrl = recordCallUrl.replace("json", "mp3");
              recordUrl = recordUrl;
              window.open(recordUrl, '_blank')
            }
            
          }
        }
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  //onRecordPlay(uri: string,) {
  //  let recordUrl = uri.replace("{{json}}", "mp3");
  //  recordUrl = recordUrl;
  //  window.open(recordUrl, '_blank')
  //}


  onPlayButtonClick(data:any){
    if(data.schAppointmentCallBy == this.loggedInUserId && !this.allowToListenOwnRecording)
    {
        this.showInfoMessage('Sorry!, You do not have required permission to play recordings.');
        this.audioPlayerPopup = false;
        return;
    }
    else if(data.schAppointmentCallBy != this.loggedInUserId && !this.allowToListenOthersRecording)
    {
        this.showInfoMessage('Sorry!, You do not have required permission to play other user recordings.');
        this.audioPlayerPopup = false;
        return;
    }

    if(data.recordingSID)
    {
        this.appointmentRecordingSID = data.recordingSID;
        this.appointmentCallDateTime = data?.dateTimeHistory;
        this.appointmentCallerName = data?.modifiedBy;
        this.audioPlayerPopup = true;
        return;
    }
    else
    {
        this.showInfoMessage('Sorry!, Recording details not found for this call.');
        this.audioPlayerPopup = false;
        return;
    }
  }

  GetChildData(data){
    this.audioPlayerPopup=data;
  }

}
