import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsGeneralRequestModel } from 'src/app/shared/models/request/settings/settings-general-request-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { CommonFunctions } from 'src/app/shared/common-functions';
import { BookingRecipients, BookingSlotDisplay, BookingSlotFrequency } from 'src/app/shared/enums';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';
import { OnlineAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
@Component({
  selector: 'app-online-booking',
  templateUrl: './online-booking.component.html',
  styleUrls: ['./online-booking.component.sass']
})
export class OnlineBookingComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsResponseModel: Array<SettingsResponseModel>;
  settingsRequestModel: Array<SettingsRequestModel>;
  onlineAppointmentTypeResponseModel: Array<OnlineAppointmentTypeModel>;
  loggedInUserId: number;

  facilityVisitEditStatus: boolean = false;
  remoteSetupEditStatus: boolean = false;
  therapistVisitEditStatus: boolean = false;
  SelfGuidedEditStatus: boolean = false;


  FaclityVisitTitle: string = 'Faclity Visit';
  RemoteSetupTitle: string = 'Remote Setup';
  TherapistVisitTitle: string = 'Therapist Visit';
  SelfGuidedTitle: string = 'Self-guided';

  FacilityVisitSelected: boolean = false;
  RemoteSetupSelected: boolean = false;
  TherapistVisitSelected: boolean = false;
  SelfGuidedSelected: boolean = false;

  OrigionalFaclityVisitTitle: string = 'Faclity Visit';
  OrigionaRemoteSetupTitle: string = 'Remote Setup';
  OrigionalTherapistVisitTitle: string = 'Therapist Visit';
  OrigionalSelfGuidedTitle: string = 'Self-guided';


  enumBookingRecipients = BookingRecipients;
  enumBookingSlotDisplay = BookingSlotDisplay;
  enumBookingSlotFrequency = BookingSlotFrequency;
  bookingSlotDisplayOptions = [];
  bookingSlotFrequencyOptions = [];
  bookingRecipientsOptions = [];
  branchNumbersRespinseModel: BranchNumbersResponseModel[];

  slotDurationWithClassification: { title: string, value: number };
  durationWithoutClassification: { title: string, value: number };
  classificationDurationOptions = [
    { title: '15 Minutes', value: 15 },
    { title: '30 Minutes', value: 30 },
    { title: '45 Minutes', value: 45 },
    { title: '60 Minutes', value: 60 },
    { title: '75 Minutes', value: 75 },
    { title: '90 Minutes', value: 90 },
    { title: '105 Minutes', value: 105 },
    { title: '120 Minutes', value: 120 }];
    slotPermission:boolean= false;

  isAllowOnlineRescheduleAppointment: boolean = true;
  isAllowOnlineCancelAppointment: boolean = true;


  formFields = {
    leadTime: 'leadTime',
    bookingSlotFrequency: 'bookingSlotFrequency',
    bookingSlotDisplay: 'bookingSlotDisplay',
    recipients: 'recipients',
    bookingOverdue: 'bookingOverdue',
    dmephone: 'dmephone',
    dmeemail: 'dmeemail',
    aptReminder: 'aptReminder',
    numMonth: 'numMonth',
    //defaultView: 'defaultView',
    facilityVisit: 'facilityVisit',
    dueAmount: 'dueAmount',
    deliveryNote: 'deliveryNote',
    branchNumbers: 'branchNumbers',
    remoteApt: 'remoteApt',
    faclityVisitTitle: 'faclityVisitTitle',
    remoteSetupTitle: 'remoteSetupTitle',
    therapistVisitTitle: 'therapistVisitTitle',
    selfGuidedTitle: 'selfGuidedTitle',
    faclityVisitSelected: 'faclityVisitSelected',
    remoteSetupSelected: 'remoteSetupSelected',
    therapistVisitSelected: 'therapistVisitSelected',

    selfGuidedSelected: 'selfGuidedSelected',
    defaultSlotDuration: 'defaultSlotDuration',
    slotDurationWithoutClassification: 'slotDurationWithoutClassification',
    selectAppointmentSlot:'selectAppointmentSlot',

  };

  validationMessages = {
    leadTime: {
      required: 'Lead time for online cancellations is required',
      positiveInt: 'Only positive numbers are allowed'
    },
    bookingSlotFrequency: {
      required: 'Booking slot frequency is required',
      //validCharacters: 'Enter valid contact name'
    },
    bookingSlotDisplay: {
      required: 'Booking slot display is required',
    },
    recipients: {
      required: 'Recipients is required',
    },
    bookingOverdue: {
      // required: 'Booking overdue is required',
      required: 'Reconcile booking is required',
      validNumber: 'Only numbers are allowed',
      positiveInt: 'Only postive numbers are allowed'
    },
    dmephone: {
      required: 'DME Phone number is required',
      validNumber: 'Enter valid phone number'
    },
    dmeemail: {
      required: 'DME Email is required',
      validemail: 'Enter valid email'
    },
    aptReminder: {
      required: 'Reminder days is required',
      validNumber: 'Only numbers are allowed',
      positiveInt: 'Only postive numbers are allowed'
    },
    numMonth: {
      required: 'Number of Months required',
      validNumber: 'Please enter valid number',
      positiveInt: 'Only postive numbers are allowed',
      range: 'Number should be in range from 1 to 12'
    },
    // defaultView: {
    //   required: 'Default view is required',
    // },
    facilityVisit: {
      required: 'Facility Visit view is required',
    },
    dueAmount: {
      required: 'Due amount is required',
    },
    deliveryNote: {
      required: 'Delivery note is required.'
    },
    remoteApt: {
      required: 'Booking appointment is required',
    },
    faclityVisitTitle: {
      required: 'faclity Visit Title is required',
    },
    remoteSetupTitle: {
      required: 'Remote Setup Title is required',
    },
    therapistVisitTitle: {
      required: 'Therapist Visit Title is required',

    },
    selfGuidedTitle: {
      required: 'Self Guided Title is required',
    },
    defaultSlotDuration: {
      required: 'Self Guided Title is required',
    },
    slotDurationWithoutClassification: {
      required: 'Slot Duration is required',
    },
    selectAppointmentSlot: {
      required: 'Slot is required',
    },

  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    this.bookingSlotFrequencyOptions = CommonFunctions.enumSelector(this.enumBookingSlotFrequency); //Object.keys(this.enumTeamName);
    this.bookingSlotDisplayOptions = CommonFunctions.enumSelector(this.enumBookingSlotDisplay);
    this.bookingRecipientsOptions = CommonFunctions.enumSelector(this.enumBookingRecipients);

    //this.bookingRecipientsOptions = _.orderBy(this.userRoleOptions, 'title', 'asc');
    //this.teamNameOptions = _.orderBy(this.teamNameOptions, 'title', 'asc');
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getApplicationSettings();
    this.getAllBranchNumbers();

  }

  private getApplicationSettings() {
    //if (!companyId) {
    //  return;
    //}


    this.loading = true;

    this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.settingsResponseModel = result.responseModel;
          this.setValuesToForm();

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getOnlineAppointmentType() {
    this.loading = true;

    await this.settingsService.getOnlineAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.onlineAppointmentTypeResponseModel = result.responseModel;
          const form = this.form;
          if (this.onlineAppointmentTypeResponseModel.length > 0) {

            let FacilityVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
            if (FacilityVisit) {
              this.OrigionalFaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
              this.FaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
              this.FacilityVisitSelected = FacilityVisit.isActive;
              form.get(this.formFields.faclityVisitSelected).setValue(FacilityVisit.isActive);
              form.get(this.formFields.faclityVisitTitle).setValue(FacilityVisit.crmOnlineAppointmentTitle);

            }

            let RemoteSetup = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
            if (RemoteSetup) {
              this.OrigionaRemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
              this.RemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
              this.RemoteSetupSelected = RemoteSetup.isActive;
              form.get(this.formFields.remoteSetupSelected).setValue(RemoteSetup.isActive);
              form.get(this.formFields.remoteSetupTitle).setValue(RemoteSetup.crmOnlineAppointmentTitle);

            }
            let TherapistVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
            if (TherapistVisit) {
              this.OrigionalTherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
              this.TherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
              this.TherapistVisitSelected = TherapistVisit.isActive;
              form.get(this.formFields.therapistVisitSelected).setValue(TherapistVisit.isActive);
              form.get(this.formFields.therapistVisitTitle).setValue(TherapistVisit.crmOnlineAppointmentTitle);
            }
            let SelfGuided = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
            if (SelfGuided) {
              this.OrigionalSelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
              this.SelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
              this.SelfGuidedSelected = SelfGuided.isActive;
              form.get(this.formFields.selfGuidedSelected).setValue(SelfGuided.isActive);
              form.get(this.formFields.selfGuidedTitle).setValue(SelfGuided.crmOnlineAppointmentTitle);
            }

          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  EditFacilityVisitTitle() {
    this.facilityVisitEditStatus = !this.facilityVisitEditStatus;
  }

  saveFacilityVisitTitle() {
    this.facilityVisitEditStatus = !this.facilityVisitEditStatus;

  }

  cancelFacilityVisitTitle() {
    const form = this.form;
    form.get(this.formFields.faclityVisitTitle).setValue(this.OrigionalFaclityVisitTitle);
    this.FaclityVisitTitle = this.OrigionalFaclityVisitTitle;
    this.facilityVisitEditStatus = !this.facilityVisitEditStatus;
  }

  EditRemoteSetupTitle() {
    this.remoteSetupEditStatus = !this.remoteSetupEditStatus;
  }

  saveRemoteSetupTitle() {
    this.remoteSetupEditStatus = !this.remoteSetupEditStatus;

  }

  cancelRemoteSetupTitle() {
    const form = this.form;
    form.get(this.formFields.remoteSetupTitle).setValue(this.OrigionaRemoteSetupTitle);
    this.RemoteSetupTitle = this.OrigionaRemoteSetupTitle;
    this.remoteSetupEditStatus = !this.remoteSetupEditStatus;
  }

  EditTherapistVisitTitle() {
    this.therapistVisitEditStatus = !this.therapistVisitEditStatus;
  }

  cancelTherapistVisitTitle() {
    const form = this.form;
    form.get(this.formFields.therapistVisitTitle).setValue(this.OrigionalTherapistVisitTitle);
    this.TherapistVisitTitle = this.OrigionalTherapistVisitTitle;
    this.therapistVisitEditStatus = !this.therapistVisitEditStatus;
  }

  saveTherapistVisitTitle() {
    this.therapistVisitEditStatus = !this.therapistVisitEditStatus;

  }

  EditSelfGuidedTitle() {
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
  }

  cancelSelfGuidedTitle() {
    const form = this.form;
    form.get(this.formFields.selfGuidedTitle).setValue(this.OrigionalSelfGuidedTitle);
    this.SelfGuidedTitle = this.OrigionalSelfGuidedTitle;
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;
  }

  saveSelfGuidedTitle() {
    this.SelfGuidedEditStatus = !this.SelfGuidedEditStatus;

  }




  private getAllBranchNumbers() {

    this.loading = true;

    this.settingsService.getAllBranchNumbers()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.branchNumbersRespinseModel = result.responseModel;

          // this.branchNumbersRespinseModel = this.branchNumbersRespinseModel.slice(0,2);
          this.setValuesToForm();
          this.getOnlineAppointmentType();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onSubmit() {

    console.log(this.form);
    if (this.form.invalid) {
      //const invalid = [];
      //const controls = this.form.controls;
      //for (const name in controls) {
      //  if (controls[name].invalid) {
      //    invalid.push(name);
      //  }
      //}
      //console.table(invalid);
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    var applicationGeneralRequestModel = this.getValuesFromForm();
    //var branchNumbersRequestModelModel = this.getValuesFromBranchNumbersForm();
    // const manageApplicationGeneral = this.settingsService.updateSettings(applicationGeneralRequestModel);
    var OnlineApptTypeModel = this.getValuesFromRemoteApptTypeForm();

    this.progress.next(true);
    forkJoin<ResultOfT<SettingsRequestModel[]>, ResultOfT<OnlineAppointmentTypeModel[]>>
      (this.settingsService.updateSettings(applicationGeneralRequestModel),
        //this.settingsService.updateAllBranchNumbers(branchNumbersRequestModelModel),
        this.settingsService.updatOnlineAppointmentTypes(OnlineApptTypeModel)
      )
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        this.loading = false;
        if (!results) {
          return;
        }

        if (results[0].isSuccess && results[1].isSuccess) {
          this.showSuccessMessage('Online booking settings is updated successfully');
        } else {
          if (!results[0].isSuccess && !results[1].isSuccess) {
            this.showErrorMessage(results[0].message + ' and ' + results[1].message);
          }
          if (!results[0].isSuccess) {
            this.showErrorMessage(results[0].message);
          } else
            if (!results[1].isSuccess) {
              this.showErrorMessage(results[1].message);
            }

        }
      })
      .catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });



    // this.loading = true;
    // manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .subscribe((result: Result) => {
    //     if (result && result.isSuccess) {
    //       this.showSuccessMessage('Online booking settings is updated successfully');
    //     } else {
    //       this.showErrorMessage(result.message);
    //     }

    //   }, (httpResponseError) => {
    //     this.showErrorMessage(httpResponseError.message);
    //   });

  }

  private getValuesFromBranchNumbersForm(): BranchNumbersResponseModel[] {

    // const form = this.form;
    const branchNumbers = new Array<BranchNumbersResponseModel>();
    var branchesAndEmails = this.formBranchNumbers.controls;
    var index = 0;


    if (branchesAndEmails !== undefined) {

      branchesAndEmails.forEach((result) => {
        const branchNumber = new BranchNumbersResponseModel();
        branchNumber.dmeemail = result.get('dmeEmailId').value;
        branchNumber.dmephoneNumber = result.get('dmePhoneNumber').value;
        branchNumber.modifiedByUserInfoId = this.loggedInUserId;
        var branch = this.branchNumbersRespinseModel[index];
        branchNumber.branchNumbersId = branch.branchNumbersId;
        branchNumber.branchId = branch.branchId;

        branchNumbers.push(branchNumber);
        index++;
      });
      return branchNumbers;
    }

    //   var that = this;

    //   if (branchesAndEmails !== undefined) {
    // 
    // branchesAndEmails.forEach((result) => {
    //   const mail = result.get('dmeEmailId').value;
    //   const phone = result.get('dmePhoneNumber').value;
    //       branchNumbers[index].dmeemail = mail;
    //       branchNumbers[index].dmephoneNumber = phone;
    //       branchNumbers[index].modifiedByUserInfoId = that.loggedInUserId;

    //       index++;
    //     });
    // return branchNumbers;
    //   }

  }

  private getValuesFromForm(): Array<SettingsRequestModel> {
    const form = this.form;
    var lstSettingsResponseModel = new Array<SettingsResponseModel>();
    var leadTimeRequestModel = new SettingsResponseModel();
    leadTimeRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.leadTimeForOnlineCancellations);
    if (leadTimeRequestModel != undefined) {
      leadTimeRequestModel.schSettingValue = form.get(this.formFields.leadTime).value;
      leadTimeRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(leadTimeRequestModel);
    }

    //var bookingSlotFrequencyRequestModel = new SettingsResponseModel();
    //bookingSlotFrequencyRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingSlotFrequency);
    //bookingSlotFrequencyRequestModel.schSettingValue = form.get(this.formFields.bookingSlotFrequency).value.value;
    //bookingSlotFrequencyRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //lstSettingsResponseModel.push(bookingSlotFrequencyRequestModel);

    //var bookingSlotDisplayRequestModel = new SettingsResponseModel();
    //bookingSlotDisplayRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingSlotDisplay);
    //bookingSlotDisplayRequestModel.schSettingValue = form.get(this.formFields.bookingSlotDisplay).value.value;
    //bookingSlotDisplayRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //lstSettingsResponseModel.push(bookingSlotDisplayRequestModel);

    //var recipientsRequestModel = new SettingsResponseModel();
    //recipientsRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.recipients);
    //recipientsRequestModel.schSettingValue = form.get(this.formFields.recipients).value.value;
    //recipientsRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    //lstSettingsResponseModel.push(recipientsRequestModel);

    var bookingOverdueRequestModel = new SettingsResponseModel();
    bookingOverdueRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingOverdue);
    if (bookingOverdueRequestModel != undefined) {
      bookingOverdueRequestModel.schSettingValue = form.get(this.formFields.bookingOverdue).value;
      bookingOverdueRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(bookingOverdueRequestModel);
    }

    //var dmeEmail = new SettingsResponseModel();
    //dmeEmail = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DMEEmail);
    //if (dmeEmail != undefined) {
    //  dmeEmail.schSettingValue = form.get(this.formFields.dmeemail).value;
    //  dmeEmail.modifiedByUserInfoId = this.loggedInUserId;
    //  lstSettingsResponseModel.push(dmeEmail);
    //}

    //var dmePhone = new SettingsResponseModel();
    //dmePhone = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DMENumber);
    //if (dmePhone != undefined) {
    //  dmePhone.schSettingValue = form.get(this.formFields.dmephone).value;
    //  dmePhone.modifiedByUserInfoId = this.loggedInUserId;
    //  lstSettingsResponseModel.push(dmePhone);
    //}

    var dueAmount = new SettingsResponseModel();
    dueAmount = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DueAmount);
    if (dueAmount != undefined) {
      dueAmount.schSettingValue = form.get(this.formFields.dueAmount).value;
      dueAmount.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(dueAmount);
    }

    var deliveryNote = new SettingsResponseModel();
    deliveryNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DeliveryNote);
    if (deliveryNote != undefined) {
      deliveryNote.schSettingValue = form.get(this.formFields.deliveryNote).value;
      deliveryNote.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(deliveryNote);
    }

    var facilityOnOff = new SettingsResponseModel();
    facilityOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.FacilityApt);
    if (facilityOnOff != undefined) {
      facilityOnOff.schSettingValue = form.get(this.formFields.facilityVisit).value;
      facilityOnOff.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(facilityOnOff);
    }

    var bookingRemoteOnOff = new SettingsResponseModel();
    bookingRemoteOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.BookingRemoteApt);
    if (bookingRemoteOnOff != undefined) {
      bookingRemoteOnOff.schSettingValue = form.get(this.formFields.remoteApt).value;
      bookingRemoteOnOff.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(bookingRemoteOnOff);
    }

    var numMonths = new SettingsResponseModel();
    numMonths = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.NumOfMonths);
    if (numMonths != undefined) {
      numMonths.schSettingValue = form.get(this.formFields.numMonth).value;
      numMonths.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(numMonths);
    }

    //var defaultView = new SettingsResponseModel();
    //defaultView = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.MapView);
    //if (defaultView != undefined) {
    //  defaultView.schSettingValue = form.get(this.formFields.defaultView).value;
    //  defaultView.modifiedByUserInfoId = this.loggedInUserId;
    //  lstSettingsResponseModel.push(defaultView);
    //}

   
    var selectAppointmentSlot = new SettingsResponseModel();
    selectAppointmentSlot = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientApptBasedOnClassificationSlotDuration);
    if (selectAppointmentSlot != undefined) {
      selectAppointmentSlot.schSettingValue = form.get(this.formFields.selectAppointmentSlot).value;
      selectAppointmentSlot.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(selectAppointmentSlot);
    }

    var defaultslot = new SettingsResponseModel();
    defaultslot = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientDefaultApptSlotDuration);
    if (defaultslot != undefined) {
      var test = form.get(this.formFields.defaultSlotDuration).value;
      defaultslot.schSettingValue = (test.value).toString();
      defaultslot.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(defaultslot);
    }

    var slotDurationWithoutClassification = new SettingsResponseModel();
    slotDurationWithoutClassification = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientDefaultSlotDurationIfClassificationNotMapped);
    if (slotDurationWithoutClassification != undefined) {
      var test = form.get(this.formFields.slotDurationWithoutClassification).value;
      slotDurationWithoutClassification.schSettingValue = (test.value).toString();
      slotDurationWithoutClassification.modifiedByUserInfoId = this.loggedInUserId;
      lstSettingsResponseModel.push(slotDurationWithoutClassification);
    }




    return lstSettingsResponseModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsResponseModel) {
      return;
    }
    var leadTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.leadTimeForOnlineCancellations);
    //var bookingSlotFrequency = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingSlotFrequency);
    //var bookingSlotDisplay = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingSlotDisplay);
    //var recipients = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.recipients);
    var bookingOverdue = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.bookingOverdue);

    var dmeEmail = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DMEEmail);
    var dmePhone = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DMENumber);
    var dueAmount = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DueAmount);
    var deliveryNote = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.DeliveryNote);
    var facilityOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.FacilityApt);
    var bookingAppOnOff = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.BookingRemoteApt);
    var nummonths = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.NumOfMonths);
    var mapview = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.MapView);

    var selectAppointmentSlot = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientApptBasedOnClassificationSlotDuration);
    this.slotPermission = selectAppointmentSlot.schSettingValue == 'True'? true:false;
    var defaultslot = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientDefaultApptSlotDuration);
    var slotDurationWithoutClassification = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.PatientDefaultSlotDurationIfClassificationNotMapped);

    const form = this.form;
    form.get(this.formFields.leadTime).setValue(leadTime?.schSettingValue);
    //form.get(this.formFields.bookingSlotFrequency).setValue(CommonFunctions.enumSelector(this.enumBookingSlotFrequency).find(a => a.value === bookingSlotFrequency.schSettingValue));
    //form.get(this.formFields.bookingSlotDisplay).setValue(CommonFunctions.enumSelector(this.enumBookingSlotDisplay).find(a => a.value === bookingSlotDisplay.schSettingValue));
    //form.get(this.formFields.recipients).setValue(CommonFunctions.enumSelector(this.enumBookingRecipients).find(a => a.value === recipients.schSettingValue));
    form.get(this.formFields.bookingOverdue).setValue(bookingOverdue?.schSettingValue);

    //form.get(this.formFields.dmeemail).setValue(dmeEmail?.schSettingValue);
    //form.get(this.formFields.dmephone).setValue(dmePhone?.schSettingValue);

    form.get(this.formFields.facilityVisit).setValue(facilityOnOff?.schSettingValue);
    form.get(this.formFields.remoteApt).setValue(bookingAppOnOff?.schSettingValue);
    form.get(this.formFields.numMonth).setValue(nummonths?.schSettingValue);
    //form.get(this.formFields.defaultView).setValue(mapview?.schSettingValue);
    form.get(this.formFields.dueAmount).setValue(dueAmount?.schSettingValue);
    form.get(this.formFields.deliveryNote).setValue(deliveryNote?.schSettingValue);


    this.slotDurationWithClassification = this.classificationDurationOptions.find(x => x.value === Number(defaultslot.schSettingValue));
    form.get(this.formFields.defaultSlotDuration).setValue(this.slotDurationWithClassification );
    
    this.durationWithoutClassification = this.classificationDurationOptions.find(x => x.value === Number(slotDurationWithoutClassification.schSettingValue));
    form.get(this.formFields.slotDurationWithoutClassification).setValue(this.durationWithoutClassification);

    form.get(this.formFields.selectAppointmentSlot).setValue(selectAppointmentSlot?.schSettingValue);






    const that = this;
    //var index = 0;

    //var branchNumbers = this.branchNumbersRespinseModel;
    //if (branchNumbers !== undefined) {
    //  branchNumbers.forEach((value) => {

    //    if (index > 0)
    //      (<FormArray>form.get('branchNumbers')).push(that.AddBranchNumbersControlsFormGroup());

    //    that.formBranchNumbers.controls[index].get('dmePhoneNumber').setValue(value.dmephoneNumber);
    //    that.formBranchNumbers.controls[index].get('dmeEmailId').setValue(value.dmeemail);

    //    index++;
    //  });
    //}
    //if (this.onlineAppointmentTypeResponseModel.length > 0) {

    //  let FacilityVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'FacilityVisit')
    //  if (FacilityVisit) {
    //    this.OrigionalFaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
    //    this.FaclityVisitTitle = FacilityVisit.crmOnlineAppointmentTitle;
    //    this.FacilityVisitSelected = FacilityVisit.isActive;
    //    form.get(this.formFields.faclityVisitSelected).setValue(FacilityVisit.isActive);
    //    form.get(this.formFields.faclityVisitTitle).setValue(FacilityVisit.crmOnlineAppointmentTitle);

    //  }

    //  let RemoteSetup = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'RemoteSetup')
    //  if (RemoteSetup) {
    //    this.OrigionaRemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
    //    this.RemoteSetupTitle = RemoteSetup.crmOnlineAppointmentTitle;
    //    this.RemoteSetupSelected = RemoteSetup.isActive;
    //    form.get(this.formFields.remoteSetupSelected).setValue(RemoteSetup.isActive);
    //    form.get(this.formFields.remoteSetupTitle).setValue(RemoteSetup.crmOnlineAppointmentTitle);

    //  }
    //  let TherapistVisit = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'TherapistVisit')
    //  if (TherapistVisit) {
    //    this.OrigionalTherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
    //    this.TherapistVisitTitle = TherapistVisit.crmOnlineAppointmentTitle;
    //    this.TherapistVisitSelected = TherapistVisit.isActive;
    //    form.get(this.formFields.therapistVisitSelected).setValue(TherapistVisit.isActive);
    //    form.get(this.formFields.therapistVisitTitle).setValue(TherapistVisit.crmOnlineAppointmentTitle);
    //  }
    //  let SelfGuided = this.onlineAppointmentTypeResponseModel.find(x => x.crmOnlineAppointmentType == 'SelfGuided')
    //  if (SelfGuided) {
    //    this.OrigionalSelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
    //    this.SelfGuidedTitle = SelfGuided.crmOnlineAppointmentTitle;
    //    this.SelfGuidedSelected = SelfGuided.isActive;
    //    form.get(this.formFields.selfGuidedSelected).setValue(SelfGuided.isActive);
    //    form.get(this.formFields.selfGuidedTitle).setValue(SelfGuided.crmOnlineAppointmentTitle);
    //  }

    //}


  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.leadTime, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.positiveDecimalNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.numMonth, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers, CustomValidator.positiveDecimalNumber, CustomValidator.range], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.bookingSlotFrequency, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.bookingSlotDisplay, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.recipients, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.bookingOverdue, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers, CustomValidator.positiveDecimalNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.aptReminder, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    //formGroup.addControl(this.formFields.dmephone, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    // formGroup.addControl(this.formFields.dmeemail, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.facilityVisit, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.remoteApt, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.dueAmount, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.deliveryNote, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    //formGroup.addControl(this.formFields.defaultView, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.faclityVisitTitle, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.remoteSetupTitle, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.therapistVisitTitle, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.selfGuidedTitle, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.faclityVisitSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.remoteSetupSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.therapistVisitSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.selfGuidedSelected, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));
    
    formGroup.addControl(this.formFields.defaultSlotDuration, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.slotDurationWithoutClassification, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formGroup.addControl(this.formFields.selectAppointmentSlot, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    //formGroup.addControl(this.formFields.branchNumbers, this.formBuilder.array([this.AddBranchNumbersControlsFormGroup()]));
    this.form = formGroup;
  }

  get formBranchNumbers() {
    return <FormArray>this.form.get('branchNumbers');
  }


  AddBranchNumbersControlsFormGroup(): FormGroup {
    return this.formBuilder.group({
      dmePhoneNumber: new FormControl('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }),
      dmeEmailId: new FormControl('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur })
    });
  }

  saveOnlineAppointmentTypeInfo() {

    var OnlineApptTypeModel = this.getValuesFromRemoteApptTypeForm();

    const manageApplicationGeneral = this.settingsService.updatOnlineAppointmentTypes(OnlineApptTypeModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.getOnlineAppointmentType();
          //this.showSuccessMessage('Appointment settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }
  private getValuesFromRemoteApptTypeForm(): Array<OnlineAppointmentTypeModel> {

    const form = this.form;
    var lstRemoteAppointmentTypeModel = new Array<OnlineAppointmentTypeModel>();
    var facilityVisitAppointmentTypeModel = new OnlineAppointmentTypeModel();
    facilityVisitAppointmentTypeModel.isActive = this.FacilityVisitSelected;
    facilityVisitAppointmentTypeModel.crmOnlineAppointmentType = 'FacilityVisit';
    facilityVisitAppointmentTypeModel.crmOnlineAppointmentTitle = form.get(this.formFields.faclityVisitTitle).value;
    facilityVisitAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(facilityVisitAppointmentTypeModel);

    var RemoteSetUpAppointmentTypeModel = new OnlineAppointmentTypeModel();
    RemoteSetUpAppointmentTypeModel.isActive = this.RemoteSetupSelected;
    RemoteSetUpAppointmentTypeModel.crmOnlineAppointmentType = 'RemoteSetup';
    RemoteSetUpAppointmentTypeModel.crmOnlineAppointmentTitle = form.get(this.formFields.remoteSetupTitle).value;
    RemoteSetUpAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(RemoteSetUpAppointmentTypeModel);


    var TherapistVisitAppointmentTypeModel = new OnlineAppointmentTypeModel();
    TherapistVisitAppointmentTypeModel.isActive = this.TherapistVisitSelected;
    TherapistVisitAppointmentTypeModel.crmOnlineAppointmentType = 'TherapistVisit';
    TherapistVisitAppointmentTypeModel.crmOnlineAppointmentTitle = form.get(this.formFields.therapistVisitTitle).value;
    TherapistVisitAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(TherapistVisitAppointmentTypeModel);

    var selfGuidedfRemoteAppointmentTypeModel = new OnlineAppointmentTypeModel();
    selfGuidedfRemoteAppointmentTypeModel.isActive = this.SelfGuidedSelected;
    selfGuidedfRemoteAppointmentTypeModel.crmOnlineAppointmentType = 'SelfGuided';
    selfGuidedfRemoteAppointmentTypeModel.crmOnlineAppointmentTitle = form.get(this.formFields.selfGuidedTitle).value;
    selfGuidedfRemoteAppointmentTypeModel.modifiedByUserInfoId = this.loggedInUserId;
    lstRemoteAppointmentTypeModel.push(selfGuidedfRemoteAppointmentTypeModel);



    return lstRemoteAppointmentTypeModel;
  }

  setDurationBasedClassification()
  {
    this.slotPermission = true;
  }

  reSetDurationBasedClassification(){
    this.slotPermission = false;
  }

}
