import { Time } from '@angular/common';
import { FacilityCustomAvailabilityResponseModel } from './facility-custom-availability-response-model';

export class FacilityAvailabilityResponseModel {
  crmFacilityAvailabilityId: number;
  crmFacilityId: number;
  crmFacilityAvailFromDate: Date;
  crmFacilityAvailToDate: Date;
  crmFacilityAvailFromTime: Date;
  crmFacilityAvailToTime: Date;
  crmFacilityAvailFromTimeString: string;
  crmFacilityAvailToTimeString: string;
  crmFacilityAvailWeekDays: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBreakStartTime: Date;
  crmBreakEndTime: Date;
  crmBreakStartTimeString: string;
  crmBreakEndTimeString: string;
  crmBreakStartTimeDate: Date;
  crmBreakEndTimeDate: Date;

  facilityCustomAvailability: FacilityCustomAvailabilityResponseModel[];
}

export class FacilityAvailabilityResponseModelSend {
  crmFacilityAvailabilityId: number;
  crmFacilityId: number;
  crmFacilityAvailFromDate: Date;
  crmFacilityAvailToDate: Date;
  crmFacilityAvailFromTimeDate: Date;
  crmFacilityAvailToTimeDate: Date;
  crmFacilityAvailFromTimeString: string;
  crmFacilityAvailToTimeString: string;
  crmFacilityAvailWeekDays: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBreakStartTime: Date;
  crmBreakEndTime: Date;
  crmBreakStartTimeDate: Date;
  crmBreakEndTimeDate: Date;
  crmBreakStartTimeString: string;
  crmBreakEndTimeString: string;

}
