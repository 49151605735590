import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { BookAppoinmentDialogComponent } from 'src/app/shared/components/book-appoinment-dialog/book-appoinment-dialog.component';
import { Constants } from 'src/app/shared/constants';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { FeatureGroupResponseModel, featureResponseModel, userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { Result } from 'src/app/shared/models/response/result';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { WebStorageService } from '../services/web-storage.service';
import { UserPermissions } from '../enums';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
  providers: [DialogService]
})
export class SidebarComponent extends BaseComponent implements OnInit, OnDestroy {
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  private unsubscriber = new Subject<boolean>();
  leftmenu: MenuItem[];
  // Sidebar
  visibleSidebar1: any;
  featureGroups: FeatureGroupResponseModel[];
  userToken: UserAuthResponseModel;
  features: featureResponseModel[];
  isDashboard: boolean = false;
  isAppointments: boolean = false;
  isCalendar: boolean = false;
  isUsers: boolean = false;
  isResources: boolean = false;
  isDoctors: boolean = false;
  isTherapists: boolean = false;
  isFacilities: boolean = false;
  isImportExport: boolean = false;
  isReports: boolean = false;
  isSettings: boolean = false;
  userfullname: string;
  isMenuLoaded: boolean = false;
  resourceActiveReset: boolean = false;
  userPerissionfeatures: userModuleFeatureResponseModel[];
  allowToOpenCallRecordingLogs: boolean = false;
  loggedInUserId: number;

  items: MenuItem[];
  isChangePass: boolean = false;
  isProfile: boolean = false;
  clientId: number;
  isVoiceCallRecordingDisplay: boolean = false;

  // @ViewChild(Tooltip) tooltip!: Tooltip;

  constructor(private authenticationService: AuthenticationService,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    private sanitizer: DomSanitizer,
    private featureGroupService: FeatureGroupService,
    messageService: MessageService) {
    super(messageService);

    var clientdetail = this.webStorageService.getClientConfig();
    this.clientId = Number(clientdetail.companyId);
    var channelArray: Array<Number> = [9,12,7,13,14,15,18,20,21,1008]; //Allow to use Nums and Preprod clients
    this.isVoiceCallRecordingDisplay = channelArray.includes(this.clientId) ? true : false;

    if (this.webStorageService.getCurrentUserToken() && !this.isMenuLoaded) {

      this.userToken = this.webStorageService.getCurrentUserToken();
      this.userfullname = this.userToken.userFullName;
      this.isDashboard = this.userToken.isDashboard;
      this.isAppointments = this.userToken.isAppointments;
      this.isCalendar = this.userToken.isCalendar;
      this.isDoctors = this.userToken.isDoctors;
      this.isFacilities = this.userToken.isFacilities;
      this.isImportExport = this.userToken.isImport;
      this.isReports = this.userToken.isReport;
      this.isResources = this.userToken.isResources;
      this.isSettings = this.userToken.isSettings;
      this.isTherapists = this.userToken.isTherapists;
      this.isUsers = this.userToken.isUsers;
      this.isMenuLoaded = true;

      if (this.isDoctors || this.isTherapists || this.isFacilities) {
        this.leftmenu = [
          {
            label: 'Resources',
            icon: 'new-ic resources-new-ic',
            items: [
              { label: Constants.featureGroup.Doctors, routerLink: '/admin/doctors', routerLinkActiveOptions: "{exact: true}", visible: this.isDoctors },
              { separator: true },
              { label: Constants.featureGroup.Therapists, routerLink: '/admin/therapists', routerLinkActiveOptions: "{exact: true}", visible: this.isTherapists },
              { separator: true },
              { label: Constants.featureGroup.Facilities, routerLink: '/admin/facilities', routerLinkActiveOptions: "{exact: true}", visible: this.isFacilities }
            ]
          }
        ];
      }
    }

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.setUserPermissionforActions();
  }

  setUserPermissionforActions() {
    this.userPerissionfeatures = this.webStorageService.getUserPermissions();
    if (this.userPerissionfeatures && this.userPerissionfeatures.length > 0) {

      let inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AccessToRecordingLogs);
      if (inPer1) {
        this.allowToOpenCallRecordingLogs = true;
      }
    }
  }

  async ngOnInit() {
    this.configResponseModel = new ConfigResponseModel();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = Constants.imagePrefix + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;
    }

    this.items = [
      { label: 'Profile', routerLink: '/admin/my-profile', routerLinkActiveOptions: "{exact: true}" },
      { label: 'Change Password', routerLink: '/admin/change-password', routerLinkActiveOptions: "{exact: true}" }
    ];
    if (window.location.pathname == '/admin/doctors' || window.location.pathname == '/admin/therapists' || window.location.pathname == '/admin/facilities')
      this.resourceActiveReset = true;
  }
  bookAppointmentSearch() {
    const ref = this.dialougeService.open(BookAppoinmentDialogComponent, {
      showHeader: true,
      closable: true,
      data: {
        //id: crmSalesOrderId,
        //isAppointment: false,
        //searchRequestModel: this.searchRequestModel,
        //selectedTab: this.index
      },
    });
  }

  onLogout() {

    this.featureGroupService.logout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.authenticationService.signOut();
        }

        else
          this.showErrorMessage(result.message);

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  setDefaultState() {
    if (this.leftmenu) {
      this.leftmenu.forEach((value) => {
        value.expanded = false;
      });

    }
    this.resourceActiveReset = false;
  }

  onResourceActiveReset() {
    this.resourceActiveReset = true;
  }

}
