import { Component, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, TemplateRef, OnInit, Input, Output, OnDestroy, LOCALE_ID, Inject, Injectable } from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarEventTitleFormatter } from 'angular-calendar';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { finalize, takeUntil } from 'rxjs/operators';
import { TherapistService } from 'src/app/admin/servicies/therapist.service';
import { AvailableSlots, FaciltiyForSlots } from 'src/app/shared/models/response/appointment/available-slots'
import { TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots'
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { TherapistCustomDates } from 'src/app/shared/models/request/therapist/therapist-custom-dates';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Location } from '@angular/common';
import { FacilityService } from 'src/app/admin/servicies/facility.service';
import { FacilityResponseModelDropdownList, FacilityResponseModelDefaultTime } from 'src/app/shared/models/response/facility/facility-response-model';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import * as moment from 'moment';
import { Title } from "@angular/platform-browser";
import { ManageAvailabilityService } from 'src/app/admin/servicies/manage-availability.service';

@Component({
  selector: 'app-my-availability-new',
  templateUrl: './my-availability-new.component.html',
  styleUrls: ['./my-availability-new.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService,
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter
    },
  ]
})
export class MyAvailabilityNewComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'My Availability';
  @ViewChild(ContextMenuComponent, { static: true }) public basicMenu: ContextMenuComponent;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  availableSlots: AvailableSlots[] = [];
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  therapistId: any = 0;
  therapitsAvailability: TherapistAvailability[] = [];
  appFormatDate: DateFormatModel;
  therapistCustomDates: TherapistCustomDates;
  availableId: number = 0;
  customId: number;
  customDate: Date;
  startTime: Date;
  endTime: Date;
  event: string;
  overlaypanel: OverlayPanel;
  editEvent: boolean = false;
  eventText: string = "";
  selectedValue: string = "";
  defaultFacility: number = 0;
  isFullDay: boolean = false;
  isEditEvent: boolean = false;
  loadingCustom: boolean = false;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  currentUserBranch: number;
  Facility: any[];
  SelectFacility: any[];

  //Add Edit Events

  showPopup: boolean = false;
  allfacilities: FacilityResponseModelDropdownList[];
  facilities: FacilityResponseModelDropdownList[];
  selectedFacility: FacilityResponseModelDropdownList;
  time: FacilityResponseModelDefaultTime;
  weekDays: EnumModel[] = [];
  selectedWeekDays: EnumModel[] = [];
  selectedWeekDay: EnumModel;
  eventSeries: EnumModel[];
  prevEventSeries: EnumModel;
  selectedEventSeries: EnumModel;
  therapistAvailability: TherapistCustomAvailabilityResponseModel;
  breakStartTime: string;
  breakEndTime: string;
  description: string;
  currentUserToken: UserAuthResponseModel;
  customStartDate: Date;
  customStartTime: string;
  customEndDate: Date;
  customEndTime: string;
  loggedInUserId: number;
  selectedEvent: string;
  selectedEvent1: string;
  selecteRec: boolean;
  isBreakHours: boolean = false;
  isBreakHoursDisplayed: boolean = false;
  isSetRecurrenceDisplayed: boolean = false;
  isFacilityDisplayed: boolean;
  isExactDate: boolean = false;
  errorcode: number;
  selectedOccurrence: { name: string, code: number };
  isGoogleMap: boolean = false;
  entherapistId: string;
  Occurrence = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
  ];
  selectedExactDate: { name: string, code: number };
  ExactDate = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
    { name: '6', code: 6 },
    { name: '7', code: 7 },
    { name: '8', code: 8 },
    { name: '9', code: 9 },
    { name: '10', code: 10 },
    { name: '11', code: 11 },
    { name: '12', code: 12 },
    { name: '13', code: 13 },
    { name: '14', code: 14 },
    { name: '15', code: 15 },
    { name: '16', code: 16 },
    { name: '17', code: 17 },
    { name: '18', code: 18 },
    { name: '19', code: 19 },
    { name: '20', code: 20 },
    { name: '21', code: 21 },
    { name: '22', code: 22 },
    { name: '23', code: 23 },
    { name: '24', code: 24 },
    { name: '25', code: 25 },
    { name: '26', code: 26 },
    { name: '27', code: 27 },
    { name: '28', code: 28 },
    { name: '29', code: 29 },
    { name: '30', code: 30 },
    { name: '31', code: 31 }
  ];



  //Add Edit Events

  constructor(private modal: NgbModal,
    private router: Router,
    private location: Location,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    protected cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private therapistService: ManageAvailabilityService,
    private dialougeService: DialogService,
    private facilityService: FacilityService,
    private titleService: Title,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.titleService.setTitle("Appoint360");
    this.calendarActions = [
      { name: 'View By Facility', code: 'ViewByFacility' },
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'View By Available Slots', code: 'ViewByAvailableSlots' }
    ];
    this.currentUserBranch = this.webStorageService.getUserBranch();
    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
    this.appFormatDate = this.webStorageService.getDateFormat();
    if (this.webStorageService.getCurrentUserToken())
      this.loggedInUserId = this.webStorageService.getCurrentUserToken().userId;
  }

  async ngOnInit() {
    this.loadingCustom = true;
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.entherapistId = currentUserToken.enuserId;
    }
    else {
      this.therapistId = 0;
    }
    await this.getTherapistAvailability();
    this.loadingCustom = false;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  async getTime() {
    this.progress.next(true);
    var day = 1;
    if (new Date().getDay() == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = new Date().getDay() - 1;
      day = Math.pow(2, power);
    }
    this.facilityService.getTime(this.selectedFacility.crmFacilityId, day)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result || !result.responseModel) {
          return;
        }
        this.time = result.responseModel;
        this.customStartTime = this.time.dtStart;
        this.customEndTime = this.time.dtEnd;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getFacilitiesPopupeExt(isRemote: boolean) {

    this.facilities = this.allfacilities.filter(x => x.crmIsRemoteFacility === isRemote);
    if (this.availableId <= 0 && !isRemote) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.getTime();
      }
    }
    //this.selectedFacility = this.facilities[0];
  }

  SetEvents() {
    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isFacilityDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    this.isFullDay = false;
    switch (this.selectedEvent) {
      case "WO":
        this.isFacilityDisplayed = true;
        this.getFacilitiesPopupeExt(false);
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isExactDate = false;
        //this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        break;
      case "T":
        this.isFacilityDisplayed = true;
        this.getFacilitiesPopupeExt(true);
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isSetRecurrenceDisplayed = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      case "W":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      case "R":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      case "O":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isFacilityDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;

    }
    //if (this.selectedEvent === "T") {
    //  this.getFacilitiesPopupeExt(true);
    //}
    //else {
    //  this.getFacilitiesPopup("false");
    //}
  }

  SetEvents1() {
    this.isBreakHoursDisplayed = false;
    this.isSetRecurrenceDisplayed = false;
    this.isFacilityDisplayed = false;
    this.isBreakHours = false;
    this.selecteRec = false;
    this.isFullDay = false;
    switch (this.selectedEvent1) {
      case "WO":
        this.isFacilityDisplayed = true;
        this.selectedEvent = "WO";
        this.getFacilitiesPopupeExt(false);
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isExactDate = false;
        break;
      case "T":
        this.isFacilityDisplayed = true;
        this.getFacilitiesPopupeExt(true);
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isSetRecurrenceDisplayed = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      case "W":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      case "R":
        this.isSetRecurrenceDisplayed = true;
        this.isFacilityDisplayed = false;
        this.isBreakHoursDisplayed = true;
        //this.isBreakHours = true;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;
      default:
        this.isBreakHoursDisplayed = false;
        this.isFacilityDisplayed = false;
        this.isExactDate = false;
        this.selectedFacility = null;
        break;

    }

    //if (this.selectedEvent === "T") {
    //  this.getFacilitiesPopupeExt(true);
    //}
    //else {
    //  this.getFacilitiesPopup("false");
    //}
  }

  getDaysOfWeek() {
    this.progress.next(true);
    this.therapistService.getDaysOfWeek()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.weekDays = result.responseModel;
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  setEventText() {

    if (this.selectedOccurrence && this.selectedEventSeries) {
      if (this.selectedEventSeries.value == 2) {
        if (this.selectedOccurrence.code == 1) {
          this.eventText = 'Every Week';
        }
        else {
          this.eventText = 'Every ' + this.selectedOccurrence.code.toString() + ' Weeks';
        }
      }
      if (this.selectedEventSeries.value == 3) {
        if (this.selectedOccurrence.code == 1) {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st of Every Month';
          else if (this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd of Every Month';
          else if (this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd of Every Month';
          else
            this.eventText = this.selectedExactDate.code + 'th of Every Month';
        }
        else {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else if (this.selectedExactDate && this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else if (this.selectedExactDate && this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd of Every ' + this.selectedOccurrence.code.toString() + ' Months';
          else
            this.eventText = this.selectedExactDate.code + 'th of Every ' + this.selectedOccurrence.code.toString() + ' Months';
        }
      }
      if (this.selectedEventSeries.value == 4) {
        if (this.selectedExactDate && this.selectedOccurrence.code == 1) {
          if (this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st Jan of Every Year';
          else if (this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd Jan of Every Year';
          else if (this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd Jan of Every Year';
          else
            this.eventText = this.selectedExactDate.code + 'th Jan of Every Year';
        }
        else {
          if (this.selectedExactDate && this.selectedExactDate.code == 1)
            this.eventText = this.selectedExactDate.code + 'st Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else if (this.selectedExactDate && this.selectedExactDate.code == 2)
            this.eventText = this.selectedExactDate.code + 'nd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else if (this.selectedExactDate && this.selectedExactDate.code == 3)
            this.eventText = this.selectedExactDate.code + 'rd Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
          else
            this.eventText = this.selectedExactDate.code + 'th Jan of Every ' + this.selectedOccurrence.code.toString() + ' Years';
        }
      }
    }
  }

  getEventSeries() {
    this.progress.next(true);
    this.therapistService.getEventSeries()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.eventSeries = result.responseModel;
        //this.getAvailability();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onChangeEventSeries(item: EnumModel) {
    // 
    this.selectedEventSeries = item;
    item.isSelected = true;
    this.selectedEventSeries.isSelected = true;
    switch (item.name) {
      case "Weekly":
        this.isExactDate = false;
        break;
      case "Monthly":
        this.isExactDate = true;
        break;
      case "Yearly":
        this.isExactDate = true;
        break;
      default:
        break;

    }
    this.setEventText();
  }

  onChangeDayOfWeek(item: EnumModel) {
    item.isSelected = !item.isSelected;
  }

  onEventOccurrenceChange(occurrence) {
    this.selectedOccurrence = occurrence.value;
    this.setEventText();
  }

  onExactDateChange() {
    this.setEventText();
  }

  getAvailability() {
    if (this.availableId && this.availableId <= 0) {
      return;
    }
    this.progress.next(true);
    this.therapistService.getAvailabilityNewById(this.availableId, this.customId, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.therapistAvailability = result.responseModel;
          this.setValuesToForm();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async setValuesToForm() {

    this.therapistId = this.therapistAvailability.crmTherapistId;
    if (this.therapistAvailability.crmIsHoliday) {
      this.selectedEvent1 = "W";
      this.SetEvents1();

    }
    else if (this.therapistAvailability.isRemoteAssistant) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "T";
      this.SetEvents();
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
    }
    else if (this.therapistAvailability.crmIsRoadShow) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "R";
      this.SetEvents();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
    }
    else if (this.therapistAvailability.isOffice) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "O";
      this.SetEvents();
      if (this.therapistAvailability.crmIsRecurring) {
        this.selecteRec = this.therapistAvailability.crmIsRecurring;
      }
    }
    else {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "WO";
      this.SetEvents();
      this.selecteRec = false;
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);

    }
    if (this.therapistAvailability.crmIsRecurring) {
      this.selecteRec = this.therapistAvailability.crmIsRecurring;
    }
    //else {
    //  this.selectedEvent = "WO";
    //  this.SetEvents();
    //  this.selecteRec = false;
    //}
    //if (!this.therapistAvailability.isRemoteAssistant) {
    //  this.selectedFacility = this.facilities.find(x => x.crmFacilityId == this.therapistAvailability.crmFacilityId);
    //}
    if (this.therapistAvailability.crmBreakEndTime) {
      this.breakEndTime = this.therapistAvailability.crmBreakEndTime.toString().split(':')[0] + ':' + this.therapistAvailability.crmBreakEndTime.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistAvailability.crmBreakStartTime) {
      this.breakStartTime = this.therapistAvailability.crmBreakStartTime.toString().split(':')[0] + ':' + this.therapistAvailability.crmBreakStartTime.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    this.description = this.therapistAvailability.crmDescription;
    this.selectedOccurrence = this.Occurrence.find(x => x.code == this.therapistAvailability.crmRecurringFrequency);
    this.customEndDate = new Date(this.therapistAvailability.crmTherapistCustomEndDate);
    this.customEndTime = this.therapistAvailability.crmTherapistCustomEndTimeString.split(':')[0] + ':' + this.therapistAvailability.crmTherapistCustomEndTimeString.split(':')[1];
    this.customStartDate = new Date(this.therapistAvailability.crmTherapistCustomStartDate);
    this.customStartTime = this.therapistAvailability.crmTherapistCustomStartTimeString.split(':')[0] + ':' + this.therapistAvailability.crmTherapistCustomStartTimeString.split(':')[1];
    if (this.therapistAvailability.exactDay) {
      this.selectedExactDate = this.ExactDate.find(x => x.code == this.therapistAvailability.exactDay);
      this.isExactDate = true;
    }
    this.weekDays.forEach((value) => {

      if (this.therapistAvailability.crmCustomDays.split(',').length > 0) {
        this.therapistAvailability.crmCustomDays.split(',').forEach((item) => {
          if (item == value.value.toString()) {
            value.isSelected = true;

          }
        });
      }
    });

    this.selectedValue = this.therapistAvailability.crmRecurringType.toString();
    this.eventSeries.forEach((value) => {
      if (this.therapistAvailability.crmRecurringType == value.value) {
        value.isSelected = true;
        this.selectedEventSeries = value;
      }
    });

    if (this.selectedEventSeries) {
      switch (this.selectedEventSeries.name) {
        case "Weekly":
          this.isExactDate = false;
          break;
        case "Monthly":
          this.isExactDate = true;
          break;
        case "Yearly":
          this.isExactDate = true;
          break;
        default:
          break;

      }
    }
    this.setEventText();
    this.isFullDay = this.therapistAvailability.isFullDay;
    this.isEditEvent = false;
    this.showPopup = true;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  async onSave() {
    this.loadingCustom = true;
    if (!this.validateForm()) {
      return;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeDate = this.isBreakHours ? new Date(commonDate + ' ' + this.breakEndTime) : null;
    requestModel.crmBreakStartTimeDate = this.isBreakHours ? new Date(commonDate + ' ' + this.breakStartTime) : null;
    requestModel.crmDescription = this.selectedEvent1 == "W" || this.selectedEvent == "O" ? this.description : "";
    requestModel.crmFacilityId = this.selectedEvent == "WO" || this.selectedEvent == "T" ? this.selectedFacility.crmFacilityId : null;
    requestModel.crmIsHoliday = this.selectedEvent1 == "W";
    requestModel.crmIsRecurring = this.selecteRec;
    requestModel.crmIsRoadShow = this.selectedEvent == "R";
    requestModel.isRemoteAssistant = this.selectedEvent == "T";
    requestModel.crmRecurringFrequency = this.selecteRec ? this.selectedOccurrence.code : 1;
    requestModel.crmRecurringType = this.selecteRec ? this.selectedEventSeries.value : 1;
    requestModel.crmTherapistCustomEndDate = this.customEndDate;
    requestModel.crmTherapistCustomEndTimeDate = new Date(commonDate + ' ' + this.customEndTime);
    requestModel.crmTherapistCustomStartDate = this.customStartDate;
    requestModel.crmTherapistCustomStartTimeDate = new Date(commonDate + ' ' + this.customStartTime);
    requestModel.crmTherapistId = this.therapistId;
    requestModel.crmTherpistCustomAvailabilityId = this.availableId;
    if (this.isEditEvent) {
      requestModel.customId = this.customId;
    }
    requestModel.crmIsExactDay = this.isExactDate;
    requestModel.crmCustomDays = "";
    requestModel.isOffice = this.selectedEvent == "O";
    requestModel.modifiedByUserInfoId = this.loggedInUserId;
    requestModel.isFullDay = this.isFullDay;
    if (this.weekDays.length > 0) {
      this.weekDays.forEach((value) => {
        if (value.isSelected)
          requestModel.crmCustomDays += value.value + ',';
      })
    }

    if (this.selectedEventSeries) {
      if (this.selectedEventSeries.name == "Monthly") {
        requestModel.monPattern = 4095;
      }

      if (this.selectedExactDate) {
        requestModel.exactDay = this.selectedExactDate.code;
      }
      else {
        requestModel.exactDay = null;
      }

      if (this.selectedEventSeries.name == "Yearly") {
        requestModel.yearFrequcncy = this.selectedOccurrence.code;
      }
    }

    if (!this.isEditEvent) {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        if (this.availableId == 0)
          this.SetAvailability(requestModel);
        else
          this.UpdateAvailability(requestModel);
      }
      else if (this.errorcode == 3) {
        this.dialougeService.open(ConfirmDialogComponent, {
          data:
          {
            message: 'There is already availability set for this period, do you wish to override the availability.',
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            if (this.availableId == 0)
              this.SetAvailability(requestModel);
            else
              this.UpdateAvailability(requestModel);
          }
        });
      }
    } else {
      await this.validateRequest(requestModel);
      if (this.errorcode == 0) {
        this.SetCustomAvailability(requestModel);
      }
      else if (this.errorcode == 3) {
        this.dialougeService.open(ConfirmDialogComponent, {
          data:
          {
            message: 'There is already availability set for this period, do you wish to override the availability.',
          },
          header: 'Confirmation'
        }).onClose.subscribe((response: boolean) => {
          if (response) {
            this.SetCustomAvailability(requestModel);
          }
        });
      }
    }
    this.loadingCustom = false;
  }

  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.therapistService.setAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  SetCustomAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.therapistService.setCustomAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability saved successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    this.therapistService.updateAvailabilityNew(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.showSuccessMessage('Availability updated successfully');
          this.onCancel();
        }
        else {
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  onCancel() {
    this.showPopup = false;
    this.onClear();
    this.getTherapistAvailability();
  }

  validateForm(): boolean {
    if (!this.selectedEvent1) {
      this.showWarningMessage('Please select Events Type.');
      return false;
    }
    if (this.selectedEvent1 == "WO") {
      if (!this.selectedEvent) {
        this.showWarningMessage('Please select Appointment Type.');
        return false;
      }
    }


    switch (this.selectedEvent) {
      case "WO":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "T":
        if (!this.selectedFacility) {
          this.showWarningMessage('Please select a facility.');
          return false;
        }
        break;
      case "W":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      case "R":
        break;
      default:
        break;

    }
    switch (this.selectedEvent1) {
      case "W":
        if (!this.description) {
          this.showWarningMessage('Please enter description.');
          return false;
        }
        break;
      default:
        break;

    }

    if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select Days of Week.');
      return false;
    }

    //if (!this.selectedFacility) {
    //  this.showWarningMessage('Please select a facility.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && !this.description) {
    //  this.showWarningMessage('Please enter description.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
    //  this.showWarningMessage('Please select event series.');
    //  return false;
    //}
    //if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
    //  this.showWarningMessage('Please select week days.');
    //  return false;
    //}
    //if (this.selectedEvent === "W" && !this.selectedOccurrence) {
    //  this.showWarningMessage('Please select Occurrence.');
    //  return false;
    //}
    if (!this.customStartDate) {
      this.showWarningMessage('Please select Start Date.');
      return false;
    }
    if (!this.customEndDate) {
      this.showWarningMessage('Please select End Date.');
      return false;
    }
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be larger than Start Date.');
      return false;
    }
    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }
    let commonDate = (new Date().getMonth() + 1).toString() + '/' + new Date().getDate().toString() + '/' + new Date().getFullYear().toString();
    if (new Date(commonDate + ' ' + this.customEndTime) < new Date(commonDate + ' ' + this.customStartTime)) {
      this.showWarningMessage('End Time should be larger than Start Time.');
      return false;
    }
    if (this.selecteRec) {
      if (!this.selectedOccurrence) {
        this.showWarningMessage('Please select Occurrence Type.');
        return false;
      }
      if (this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
        this.showWarningMessage('Please select event series.');
        return false;
      }
    }
    if (this.isBreakHours) {

      //if (!this.breakStartTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      //if (!this.breakEndTime) {
      //  this.showWarningMessage('Please select Break End Time');
      //  return false;
      //}
      if (new Date(commonDate + ' ' + this.breakEndTime) < new Date(commonDate + ' ' + this.breakStartTime)) {
        this.showWarningMessage('Break End Time should be larger than Break Start Time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakStartTime) > new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakStartTime) < new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break Start time should be between start time and end time');
        return false;
      }

      if (!(new Date(commonDate + ' ' + this.breakEndTime) > new Date(commonDate + ' ' + this.customStartTime) && new Date(commonDate + ' ' + this.breakEndTime) < new Date(commonDate + ' ' + this.customEndTime))) {
        this.showWarningMessage('Break End time should be between start time and end time');
        return false;
      }
    }
    return true;
  }

  async validateRequest(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.progress.next(true);
    await this.therapistService.validateAvailability(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {

        if (result && result.errorCode == 3) {
          this.errorcode = result.errorCode
        }
        else if (result && result.errorCode != 0) {
          this.showWarningMessage(result.message);
          this.errorcode = -1;
        }
        else {
          this.errorcode = 0;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  RedirectcalendarActions() {

    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByFacility")
      this.router.navigate(['/admin/calendar/view-by-facility']);
    else if (this.selectedcalendarActions.code == "ViewByTherapist")
      this.router.navigate(['/admin/calendar/view-by-therapist']);
    else if (this.selectedcalendarActions.code == "ViewByDate")
      this.router.navigate(['/admin/calendar/view-by-date']);
    else if (this.selectedcalendarActions.code == "ViewByAvailableSlots")
      this.router.navigate(['/admin/calendar/view-by-available-slots']);

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        //draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dateChange() {

    this.getTherapistAvailability();
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }


    if (this.progress)
      this.progress.complete();
  }

  private async getTherapistAvailability() {
    this.loadingCustom = true;
    this.progress.next(true);
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    await this.therapistService.GetTherapistAvailabilityCalendar(this.entherapistId, selectedDate, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<TherapistAvailability[]>(result, () => {

          this.therapitsAvailability = result.responseModel;
          var obj: Array<any> = [];
          for (var i = 0; i < this.therapitsAvailability.length; i++) {
            if (!this.therapitsAvailability[i].isGlobalHoliday && !this.therapitsAvailability[i].isHoliday && !this.therapitsAvailability[i].isOffice) {
              var event: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: new Date(this.therapitsAvailability[i].startTime),
                end: new Date(this.therapitsAvailability[i].endTime),
                title: !this.therapitsAvailability[i].isHoliday ? moment(this.therapitsAvailability[i].startTime).format("hh:mm").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday
              }
              obj.push(event);
            }
            else if (this.therapitsAvailability[i].isGlobalHoliday) {
              var event1: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: new Date(this.therapitsAvailability[i].startTime),
                end: new Date(this.therapitsAvailability[i].endTime),
                title: this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday
              }
              obj.push(event1);
            }
            else {
              var event1: Object = {
                id: this.therapitsAvailability[i].custDateId + ',' + this.therapitsAvailability[i].availId,
                start: new Date(this.therapitsAvailability[i].startTime),
                end: new Date(this.therapitsAvailability[i].endTime),
                title: !this.therapitsAvailability[i].isHoliday ? moment(this.therapitsAvailability[i].startTime).format("hh:mm").toLowerCase()
                  + ' - ' + this.therapitsAvailability[i].title : this.therapitsAvailability[i].title,
                title1: this.therapitsAvailability[i].title,
                isHoliday: this.therapitsAvailability[i].isHoliday,
                isRoadShow: this.therapitsAvailability[i].isRoadShow,
                isRemote: this.therapitsAvailability[i].isRemote,
                isFacility: this.therapitsAvailability[i].isFacility,
                isOffice: this.therapitsAvailability[i].isOffice,
                isFullDay: this.therapitsAvailability[i].isFullDay,
                isGlobalHoliday: this.therapitsAvailability[i].isGlobalHoliday
              }
              obj.push(event1);
            }
          }
          this.loadingCustom = false;
          this.events = obj;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onEventClick(event1, event: CalendarEvent, overlaypanel: OverlayPanel, desc: string) {

    this.availableId = Number(event.id.toString().split(',')[1]);
    this.customId = Number(event.id.toString().split(',')[0]);
    this.description = desc;
    overlaypanel.toggle(event1);
    this.cdr.detectChanges();
    this.refresh.next();
  }

  EditSeries(): void {
    //this.router.navigate(['admin/therapists/manageavailability/', this.availableId, this.therapistId]);
    this.loadingCustom = true;
    this.description = "";
    this.onClear();
    this.getAvailability();
    this.loadingCustom = false;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  Delete(): void {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.therapistService.DeleteCustomDate(Number(this.customId))
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage("Availability deleted successfully.");
            this.getTherapistAvailability();
            this.cdr.detectChanges();
            this.refresh.next();
          } else {
            this.showWarningMessage('Thers are appointment(s) scheduled for this day, you can not delete before rescheduling those appointment(s)');
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });

  }

  Edit(): void {
    this.loadingCustom = true;
    this.onClear();
    this.therapistService.GetCustomDate(Number(this.customId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {

          this.therapistCustomDates = result.responseModel;
          this.setForm();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
    this.loadingCustom = false;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  setForm() {
    this.therapistId = this.therapistCustomDates.therapistid;
    if (this.therapistCustomDates.crmIsHoliday) {
      this.selectedEvent1 = "W";
      this.SetEvents1();

    }
    else if (this.therapistCustomDates.isRemoteAssistant) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "T";
      this.SetEvents();
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);
    }
    else if (this.therapistCustomDates.crmIsRoadShow) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "R";
      this.SetEvents();
    }
    else if (this.therapistCustomDates.isOffice) {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "O";
      this.SetEvents();
    }
    else {
      this.selectedEvent1 = "WO";
      this.selectedEvent = "WO";
      this.SetEvents();
      this.selecteRec = false;
      this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.therapistCustomDates.facilityid);

    }
    if (this.therapistCustomDates.crmBreakEndTime) {
      this.breakEndTime = this.therapistCustomDates.crmBreakEndTime.toString().split(':')[0] + ':' + this.therapistCustomDates.crmBreakEndTime.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    if (this.therapistCustomDates.crmBreakStartTime) {
      this.breakStartTime = this.therapistCustomDates.crmBreakStartTime.toString().split(':')[0] + ':' + this.therapistCustomDates.crmBreakStartTime.toString().split(':')[1];
      this.isBreakHours = true;
      this.isBreakHoursDisplayed = true;
    }
    this.customEndDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customEndTime = new Date(this.therapistCustomDates.crmEndTimeDate).toTimeString().split(':')[0] + ':' + new Date(this.therapistCustomDates.crmEndTimeDate).toTimeString().split(':')[1];
    this.customStartDate = new Date(this.therapistCustomDates.crmCustomDate);
    this.customStartTime = new Date(this.therapistCustomDates.crmStartTimeDate).toTimeString().split(':')[0] + ':' + new Date(this.therapistCustomDates.crmStartTimeDate).toTimeString().split(':')[1];


    var day = new Date(this.therapistCustomDates.crmCustomDate).getDay();
    var selectedDay = 0;
    if (day == 0) {
      selectedDay = Math.pow(2, 6);
    } else {
      selectedDay = Math.pow(2, (day - 1));
    }

    this.weekDays.forEach((value) => {
      if (selectedDay == value.value) {
        value.isSelected = true;
      }
    });

    this.isFullDay = this.therapistCustomDates.isFullDay;
    this.isEditEvent = true;
    this.showPopup = true;
    this.cdr.detectChanges();
    this.refresh.next();
  }

  onSubmit() {

    this.therapistCustomDates.crmCustomDate = this.customDate;
    this.therapistCustomDates.crmEndTimeDate = this.endTime;
    this.therapistCustomDates.crmStartTimeDate = this.startTime;
    if (this.event == "W") {
      this.therapistCustomDates.crmIsHoliday = true;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "R") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = true;
      this.therapistCustomDates.isRemoteAssistant = false;
    }
    else if (this.event == "T") {
      this.therapistCustomDates.crmIsHoliday = false;
      this.therapistCustomDates.crmIsRoadShow = false;
      this.therapistCustomDates.isRemoteAssistant = true;
    }
    this.therapistService.UpdateCustomDate(this.therapistCustomDates)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.showSuccessMessage('Availability updated successfully');
          this.editEvent = false;
          this.getTherapistAvailability();
          this.cdr.detectChanges();
          this.refresh.next();
        } else {
          this.showWarningMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }



  onBack() {
    this.location.back();
  }

  ManageAvailability(date) {
    //this.router.navigate(['admin/therapists/manageavailability', "0", this.therapistId]);
    this.isEditEvent = false;
    this.onClear();
    this.showPopup = true;
    if (date) {
      this.customStartDate = new Date(date);
      this.customEndDate = new Date(date);
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date(date)).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    else {
      this.customStartDate = new Date();
      this.customEndDate = new Date();
      if (this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          let checkDate = moment(new Date()).day();
          if (checkDate == 0) {
            if (value.value === Math.pow(2, 6)) {
              value.isSelected = true;
            }
          } else {
            if (value.value === Math.pow(2, (checkDate - 1))) {
              value.isSelected = true;
            }
          }
        });
      }
    }
    this.cdr.detectChanges();
    this.refresh.next();
    this.availableId = 0;
    if (this.availableId <= 0) {
      if (this.defaultFacility > 0) {
        this.selectedFacility = this.allfacilities.find(x => x.crmFacilityId == this.defaultFacility);
        this.getTime();
      }
    }
  }

  onClear() {

    this.selecteRec = false;
    this.selectedEvent = null;
    this.selectedEvent1 = null;
    this.SetEvents();
    this.SetEvents1();
    this.selectedFacility = null;
    this.breakEndTime = null;
    this.breakEndTime = null;
    this.breakStartTime = null;
    this.description = "";
    this.selectedOccurrence = null;
    this.customEndDate = null;
    this.customEndTime = "05:00 PM";
    this.customStartDate = null;
    this.customStartTime = "08:00 AM";
    this.selectedEventSeries = null;
    this.selectedValue = "";
    this.weekDays.forEach((value) => {
      value.isSelected = false;
    });
    if (this.eventSeries) {
      this.eventSeries.forEach((value) => {
        value.isSelected = false;
      });
    }
    this.eventText = "";
    this.isFullDay = false;
    this.isEditEvent = false;
  }

  SetDays() {

    if (this.customStartDate && this.customEndDate) {
      var startDate = this.customStartDate.getDate();
      var endDate = this.customEndDate.getDate();

      if (this.weekDays) {
        this.weekDays.forEach((value) => {
          value.isSelected = false;
        });
      }

      var dayStart = 1;
      for (var i = startDate; i <= endDate; i++) {
        var date = new Date(this.customStartDate.getFullYear(), this.customStartDate.getMonth(), i);
        if (date.getDay() == 0) {
          dayStart = Math.pow(2, 6);
        }
        else {
          var power = date.getDay() - 1;
          dayStart = Math.pow(2, power);
        }

        if (this.weekDays) {
          this.weekDays.forEach((value) => {
            if (value.value == dayStart) {
              value.isSelected = true;
            }
          });
        }

      }

      //var dayStart = 1;
      //var dayEnd = 1;
      //if (startDate == 0) {
      //  dayStart = Math.pow(2, 6);
      //}
      //else {
      //  var power = startDate - 1;
      //  dayStart = Math.pow(2, power);
      //}
      //if (endDate == 0) {
      //  dayEnd = Math.pow(2, 6);
      //}
      //else {
      //  var power = endDate - 1;
      //  dayEnd = Math.pow(2, power);
      //}
      //if (this.weekDays) {
      //  this.weekDays.forEach((value) => {
      //    if (value.value == dayStart || value.value == dayEnd) {
      //      value.isSelected = true;
      //    }
      //    else {
      //      value.isSelected = false;
      //    }
      //  });
      //}


    }
  }

  closeOverlay() {

  }
  back() {
    if (this.isGoogleMap) {
      this.router.navigate(['/therapist/my-appointments-new']);
    }
    else {
      this.router.navigate(['/therapist/my-appointments']);
    }
  }


}
