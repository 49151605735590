<header class="therapist-header">
    <div class="therapist-header__left">       
        <div class="therapist-header__logo">
            <img src="assets/img/appoint360-logo.svg" width="52" height="40" title="Appoint360" alt="Appoint360">
          </div>
          <div class="therapist-header__client-logo">
             <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" title={{imageTitle}}  class="clientLogo">         
          </div>
    </div>    
    <div class="therapist-header__right">
        <a class="therapist-header__link" (click)="Profile.toggle($event)">
            <span class="user-img">
                <img src="assets/img/user-img.svg" width="52" height="40" alt="{{userfullname}}"
                    title="{{userfullname}}">
            </span>
            <!-- <small class="user-name">{{userfullname}}</small> -->
        </a>        
    </div>
</header>

<p-overlayPanel class="user-avatar-popup" styleClass="therapist-profile-menu" #Profile appendTo="body">
    <div class="profile-menu">
        <span class="therapist-name">{{userfullname}}</span>
      <ul class="quick-link-menu">
        <li>
            <a [routerLink]="['/therapist/my-profile']" routerLinkActive="active">
                <img src="assets/img/user-icon.svg" class="user-icon" width="18" height="18" alt="user">
                My Profile</a>
        </li>
        <li>
            <a [routerLink]="['/therapist/change-password']" routerLinkActive="active">
                <img src="assets/img/password-icon.svg" class="key-ic" width="18" height="18" alt="user">
                Change Password</a>
        </li>
        <!-- <li class="therapist-header-menu__menu__item"><a href="javascript:void(0)">Change Password</a></li> -->
        <li class="logout-item">
            <a (click)="onLogout()" href="javaScript:void(0);" routerLinkActive="active">
                <img src="assets/img/logout-icon.svg" width="20" height="20" alt="user">
                Logout</a>
        </li>
      </ul>
    </div>
  </p-overlayPanel>
