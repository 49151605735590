<!-- Title block -->
<div class="page-title">
	<div class="page-title__title-left">
		<span>Step 1/3</span>
		<h1>Choose Clinic</h1>
	</div>
	<div class="page-title__next-btn">
		<a href="javascript:void(0);" class="mobile-filter" (click)="clickEventFilter()">
			<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.889 1.22782C15.2901 1.31483 15.6208 1.40126 15.8853 1.47872L9.83029 8.46311C9.72783 8.5813 9.67143 8.73247 9.67143 8.88889V13.8162L7.32857 14.9595V8.88889C7.32857 8.73247 7.27217 8.5813 7.16971 8.46311L1.11473 1.47872C1.37922 1.40126 1.70986 1.31483 2.11099 1.22782C3.42811 0.942143 5.50668 0.65 8.5 0.65C11.4933 0.65 13.5719 0.942143 14.889 1.22782Z" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>						
		</a>
		<button class="btn btn--get-started" [disabled]="!(selectedFacility?.length>1)" (click)="onNext()">
			Next
			<svg class="ml-10 mr-0" width="15" height="14" viewBox="0 0 15 14" fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<path d="M9.82978 1.73608L13.2258 5.02601L9.82978 8.31594" stroke="white" stroke-width="1.6"
					stroke-linecap="round" stroke-linejoin="round" />
				<path d="M13.2258 5.026L7.79214 5.026C4.04086 5.026 1.00003 7.9718 1.00003 11.6059V12.2638"
					stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</button>
	</div>
</div>

<!-- Filter block -->
<div class="patient-filter" [ngClass]="mobileFilter ? 'show' : 'hide'">
	<div class="mobile-filter-title">
		<h3 class="show-mobile">Filter</h3>
		<a href="javascript:void(0);" class="close-filter" (click)="clickEventFilter()">
			<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M13.8755 2.17661L2.12453 13.8234" stroke="#3E3E3E" stroke-width="2" />
				<path d="M13.8232 13.8755L2.17637 2.12453" stroke="#3E3E3E" stroke-width="2" />
			</svg>
		</a>
	</div>

	<div class="patient-filter__filter-left">
		<div class="form__group">
			<label class="form__label">Clinic Name</label>
			<input type="text" class="form__control" placeholder="Search" pInputText [(ngModel)]="searchFacility" (ngModelChange)="valuechange($event,'ClinicName')"
				maxlength="50">
		</div>
		<!-- <div class="form__group form__group--datepicker">
			<p-calendar #pcalendar class="datepicker" [(ngModel)]="facilityDateStartTime" placeholder="Select Date"
				[showTime]="false" [showIcon]="true" [readonlyInput]="true" (onSelect)="SetDays($event)"
				[minDate]="minimumDate" [maxDate]="maxAllowDateToBookAppoitment">
			</p-calendar>
		</div> -->

		<div class="form__group form__group--zipcode">
			<label class="form__label">Zip Code</label>
			<input type="text" class="form__control" placeholder="Search" pInputText (ngModelChange)="valuechange($event,'ZipCode')"
				[(ngModel)]="searchByfacilityZipCode" (keypress)="numberOnly($event)" maxlength="10">
		</div>

		<div class="form__group search-icon">
			<button class="btn btn--secondary" (click)="clickEventFilterClose()">
                Cancel
            </button>
			<button class="btn btn--primary" (click)="onSearch()" [disabled]="!isEnableSearchButton">
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M15 15L10.676 10.6707M12.3333 6.66667C12.3333 8.16956 11.7363 9.6109 10.6736 10.6736C9.6109 11.7363 8.16956 12.3333 6.66667 12.3333C5.16377 12.3333 3.72243 11.7363 2.65973 10.6736C1.59702 9.6109 1 8.16956 1 6.66667C1 5.16377 1.59702 3.72243 2.65973 2.65973C3.72243 1.59702 5.16377 1 6.66667 1C8.16956 1 9.6109 1.59702 10.6736 2.65973C11.7363 3.72243 12.3333 5.16377 12.3333 6.66667Z"
						stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" />
				</svg>
				<span class="btn-search-text">Search</span>
			</button>

			<div *ngIf="isResetEnable" class="clear-box">
				<a href="javascript:void(0);" class="more-link" (click)="onReset()">
					<img src="assets/img/close-new.svg" width="16" height="16" alt="Crose" title="Crose">
				</a>
			</div>
		</div>


	</div>
	<div class="patient-filter__filter-right">
		<span>({{facilitieslabelCount}})</span>
	</div>
</div>

<!-- Facility block -->
<div class="facility-content">
	<div class="facility-content__row" *ngIf="isFacilityView">
		<div class="facility-content__block" *ngFor="let facility of facilitySearchResponseModel">
			<div class="facility-content__img-block">
				<figure *ngIf="facility.crmProfileImage">
					<img [(src)]="facility.crmProfileImage"  alt="{{facility.crmFacilityName}}" title="{{facility.crmFacilityName}}">
				</figure>
				<figure *ngIf="facility.crmProfileImage == null">
					<img src="assets/img/placeholder-img.png" alt="{{facility.crmFacilityName}}" title="{{facility.crmFacilityName}}">
				</figure>
			</div>
			<div class="facility-content__facility-informetion">
				<span class="facility-content__facility-miles">{{facility.crmFacilityDistanceInMiles}}</span>
				<h3 class="facility-content__facility-name">{{facility.crmFacilityName}}</h3>
				<address class="facility-content__facility-address">
					<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.09271 5.06006C7.50368 5.06006 6.92787 5.23272 6.43811 5.55621C5.94834 5.8797 5.56662 6.33948 5.34121 6.87743C5.11579 7.41537 5.05681 8.0073 5.17173 8.57838C5.28664 9.14946 5.57029 9.67402 5.9868 10.0857C6.40331 10.4975 6.93398 10.7779 7.51169 10.8914C8.08941 11.005 8.68823 10.9467 9.23243 10.7239C9.77662 10.5011 10.2418 10.1238 10.569 9.63963C10.8963 9.15549 11.0709 8.5863 11.0709 8.00404C11.07 7.22352 10.756 6.47522 10.1976 5.92331C9.63931 5.3714 8.88231 5.06095 8.09271 5.06006ZM8.09271 9.47603C7.7982 9.47603 7.51029 9.3897 7.26541 9.22795C7.02053 9.06621 6.82967 8.83632 6.71696 8.56734C6.60425 8.29837 6.57476 8.0024 6.63222 7.71687C6.68968 7.43133 6.8315 7.16905 7.03976 6.96318C7.24801 6.75732 7.51335 6.61713 7.8022 6.56033C8.09106 6.50353 8.39047 6.53269 8.66257 6.6441C8.93467 6.75551 9.16724 6.94418 9.33086 7.18624C9.49449 7.42831 9.58182 7.71291 9.58182 8.00404C9.58137 8.3943 9.42433 8.76844 9.14517 9.04439C8.866 9.32035 8.48751 9.47558 8.09271 9.47603Z"
							fill="#868686" />
						<path
							d="M13.8156 2.34254C12.4006 0.94417 10.5117 0.11348 8.51359 0.0107968C6.51544 -0.0918867 4.54965 0.540715 2.9956 1.78651C1.44155 3.0323 0.409411 4.80297 0.0983971 6.75678C-0.212617 8.71058 0.219536 10.709 1.31145 12.3664L6.93781 20.9045C7.06301 21.0945 7.23421 21.2506 7.43587 21.3587C7.63754 21.4667 7.86329 21.5233 8.09268 21.5233C8.32206 21.5233 8.54782 21.4667 8.74948 21.3587C8.95115 21.2506 9.12234 21.0945 9.24755 20.9045L14.8741 12.3664C15.8873 10.8289 16.335 8.99395 16.1423 7.16898C15.9496 5.34402 15.1281 3.64 13.8156 2.34254ZM13.6264 11.5629L8.0927 19.9601L2.55895 11.5629C0.865092 8.99249 1.22839 5.55263 3.42277 3.38337C4.03603 2.77715 4.76408 2.29626 5.56535 1.96818C6.36661 1.64009 7.22541 1.47122 8.0927 1.47122C8.95999 1.47122 9.81879 1.64009 10.6201 1.96818C11.4213 2.29626 12.1494 2.77715 12.7626 3.38337C14.957 5.55263 15.3203 8.99249 13.6264 11.5629Z"
							fill="#868686" />
					</svg>
					<p class="mb-0" *ngIf="facility?.crmFacilityAddressLine1"><span>{{facility?.crmFacilityAddressLine1}}</span></p>
                	<p class="mb-0" *ngIf="facility?.crmFacilityAddressLine2"><span>{{facility?.crmFacilityAddressLine2}}</span></p>
                	<p class="mb-0" *ngIf="facility?.crmFacilityCity || facility?.crmFacilityState"><span>{{facility?.crmFacilityCity}}</span>, <span>{{facility?.crmFacilityState}}</span></p>
	                <p class="mb-0" *ngIf="facility?.crmFacilityCountry || facility?.crmFacilityZipCode"><span>{{facility?.crmFacilityCountry}}</span> - <span>{{facility?.crmFacilityZipCode}}</span></p>
					<!-- {{facility.crmFacilityAddress}} -->
				</address>
				<div class="facility-content__facility-learn-more">
					<!-- (click)="facilityInfoDialog(facility)" -->
					<a href="javascript:void(0);" class="more-link" (click)="facilityInfoDialog(facility)">
						Learn More
						<svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M14 5H1M14 5L10 9M14 5L10 1" stroke="#B0A3EF" stroke-width="1.5"
								stroke-linecap="round" stroke-linejoin="round" />
						</svg>

					</a>
				</div>
				<div class="facility-content__facility-select-btn">
					<div class="select-btn">
						<button class="btn btn--primary" [disabled]="facility.isSelect?'':'disabled'">
							<svg width="23" height="22" viewBox="0 0 23 22" fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M11.5 21C10.1868 21 8.88642 20.7413 7.67317 20.2388C6.45991 19.7362 5.35752 18.9997 4.42893 18.0711C3.50035 17.1425 2.76375 16.0401 2.2612 14.8268C1.75866 13.6136 1.5 12.3132 1.5 11C1.5 9.68678 1.75866 8.38642 2.2612 7.17317C2.76375 5.95991 3.50035 4.85752 4.42893 3.92893C5.35752 3.00035 6.45991 2.26375 7.67317 1.7612C8.88642 1.25866 10.1868 1 11.5 1C14.1522 1 16.6957 2.05357 18.5711 3.92893C20.4464 5.8043 21.5 8.34784 21.5 11C21.5 13.6522 20.4464 16.1957 18.5711 18.0711C16.6957 19.9464 14.1522 21 11.5 21Z"
									fill="white" />
								<path
									d="M6.5 11L10.7857 14.5714L16.5 7.42857M11.5 21C10.1868 21 8.88642 20.7413 7.67317 20.2388C6.45991 19.7362 5.35752 18.9997 4.42893 18.0711C3.50035 17.1425 2.76375 16.0401 2.2612 14.8268C1.75866 13.6136 1.5 12.3132 1.5 11C1.5 9.68678 1.75866 8.38642 2.2612 7.17317C2.76375 5.95991 3.50035 4.85752 4.42893 3.92893C5.35752 3.00035 6.45991 2.26375 7.67317 1.7612C8.88642 1.25866 10.1868 1 11.5 1C14.1522 1 16.6957 2.05357 18.5711 3.92893C20.4464 5.8043 21.5 8.34784 21.5 11C21.5 13.6522 20.4464 16.1957 18.5711 18.0711C16.6957 19.9464 14.1522 21 11.5 21Z"
									stroke="#6F64A7" stroke-width="1.3" />
							</svg>
							Select Clinic
						</button>
						<p-checkbox (onChange)="onSelect(facility,$event)" class="select-facilitys" name="groupname" 
							value="{{facility.crmFacilityName}}" [(ngModel)]="selectedFacility"></p-checkbox>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>


<div class="loader-block" *ngIf="loading" style="z-index: 99999999 !important;">
	<p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
	</p-progressSpinner>
</div>

<p-toast position="bottom-right" [style]="{marginTop: '80px'}"></p-toast>

<div [ngClass]="mobileFilterOverly ? 'show' : 'hide'" class="filter-overly"></div>

<!-- [transitionOptions]="'200ms'" -->
<p-sidebar styleClass="facility-sidebar" [transitionOptions]="'300ms'" appendTo="body" [(visible)]="sidebarInfo" position="right"
	[baseZIndex]="10000">
	<div class="facility-sidebar-outer">
		<div class="facility-sidebar-outer__img-block">
			<figure *ngIf="crmProfileImageP">
				<img [(src)]="crmProfileImageP" alt="{{crmFacilityNameP}}" title="{{crmFacilityNameP}}">
			</figure>
			<figure *ngIf="crmProfileImageP == null">
				<img src="assets/img/placeholder-img.png" alt="Clinic" title="Clinic">
			</figure>
		</div>
		<div class="facility-sidebar-outer__facility-info-block">
			<span class="facility-sidebar-outer__miles">{{crmFacilityDistanceInMilesP}}</span>
			<h3 class="facility-sidebar-outer__title">{{crmFacilityNameP}}</h3>
			<address class="facility-sidebar-outer__facility-address">
				<svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8.09271 5.06006C7.50368 5.06006 6.92787 5.23272 6.43811 5.55621C5.94834 5.8797 5.56662 6.33948 5.34121 6.87743C5.11579 7.41537 5.05681 8.0073 5.17173 8.57838C5.28664 9.14946 5.57029 9.67402 5.9868 10.0857C6.40331 10.4975 6.93398 10.7779 7.51169 10.8914C8.08941 11.005 8.68823 10.9467 9.23243 10.7239C9.77662 10.5011 10.2418 10.1238 10.569 9.63963C10.8963 9.15549 11.0709 8.5863 11.0709 8.00404C11.07 7.22352 10.756 6.47522 10.1976 5.92331C9.63931 5.3714 8.88231 5.06095 8.09271 5.06006ZM8.09271 9.47603C7.7982 9.47603 7.51029 9.3897 7.26541 9.22795C7.02053 9.06621 6.82967 8.83632 6.71696 8.56734C6.60425 8.29837 6.57476 8.0024 6.63222 7.71687C6.68968 7.43133 6.8315 7.16905 7.03976 6.96318C7.24801 6.75732 7.51335 6.61713 7.8022 6.56033C8.09106 6.50353 8.39047 6.53269 8.66257 6.6441C8.93467 6.75551 9.16724 6.94418 9.33086 7.18624C9.49449 7.42831 9.58182 7.71291 9.58182 8.00404C9.58137 8.3943 9.42433 8.76844 9.14517 9.04439C8.866 9.32035 8.48751 9.47558 8.09271 9.47603Z"
						fill="#868686" />
					<path
						d="M13.8156 2.34254C12.4006 0.94417 10.5117 0.11348 8.51359 0.0107968C6.51544 -0.0918867 4.54965 0.540715 2.9956 1.78651C1.44155 3.0323 0.409411 4.80297 0.0983971 6.75678C-0.212617 8.71058 0.219536 10.709 1.31145 12.3664L6.93781 20.9045C7.06301 21.0945 7.23421 21.2506 7.43587 21.3587C7.63754 21.4667 7.86329 21.5233 8.09268 21.5233C8.32206 21.5233 8.54782 21.4667 8.74948 21.3587C8.95115 21.2506 9.12234 21.0945 9.24755 20.9045L14.8741 12.3664C15.8873 10.8289 16.335 8.99395 16.1423 7.16898C15.9496 5.34402 15.1281 3.64 13.8156 2.34254ZM13.6264 11.5629L8.0927 19.9601L2.55895 11.5629C0.865092 8.99249 1.22839 5.55263 3.42277 3.38337C4.03603 2.77715 4.76408 2.29626 5.56535 1.96818C6.36661 1.64009 7.22541 1.47122 8.0927 1.47122C8.95999 1.47122 9.81879 1.64009 10.6201 1.96818C11.4213 2.29626 12.1494 2.77715 12.7626 3.38337C14.957 5.55263 15.3203 8.99249 13.6264 11.5629Z"
						fill="#868686" />
				</svg>
				<p class="mb-0" *ngIf="crmFacilityAddressLine1P"><span>{{crmFacilityAddressLine1P}}</span></p>
                <p class="mb-0" *ngIf="crmFacilityAddressLine2P"><span>{{crmFacilityAddressLine2P}}</span></p>
                <p class="mb-0" *ngIf="crmFacilityCityP || crmFacilityStateP"><span>{{crmFacilityCityP}}</span>, <span>{{crmFacilityStateP}}</span></p>
	            <p class="mb-0" *ngIf="crmFacilityCountryP || crmFacilityZipCodeP"><span>{{crmFacilityCountryP}}</span> - <span>{{crmFacilityZipCodeP}}</span></p>
				<!-- {{crmFacilityAddressP}} -->
			</address>
			<div class="facility-sidebar-outer__times">
				<h3>Timings</h3>
				<!-- <strong>{{startWeekDayFullNameP}} - {{endWeekDayFullNameP}}</strong>
				<span>{{weekStartTimeString}} - {{weekEndTimeString}}</span> -->
				<div *ngFor="let week of facilityAvailableWeekDaysp">
				<p><strong>{{week.weekDayFullName}} </strong>{{week.startTimeString}} - {{week.endTimeString}}</p>
				</div>
			</div>
			<div class="facility-sidebar-outer__clinic-information">
				<h3>Clinic Information</h3>
				<div [innerHtml]="crmdescription"></div>
			</div>

			<!-- <div class="facility-sidebar-outer__select-facility-btn">
				<div class="select-btn">
					<button class="btn btn--get-started" (click)="sidebarInfo = false">
						<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M11.5 21C10.1868 21 8.88642 20.7413 7.67317 20.2388C6.45991 19.7362 5.35752 18.9997 4.42893 18.0711C3.50035 17.1425 2.76375 16.0401 2.2612 14.8268C1.75866 13.6136 1.5 12.3132 1.5 11C1.5 9.68678 1.75866 8.38642 2.2612 7.17317C2.76375 5.95991 3.50035 4.85752 4.42893 3.92893C5.35752 3.00035 6.45991 2.26375 7.67317 1.7612C8.88642 1.25866 10.1868 1 11.5 1C14.1522 1 16.6957 2.05357 18.5711 3.92893C20.4464 5.8043 21.5 8.34784 21.5 11C21.5 13.6522 20.4464 16.1957 18.5711 18.0711C16.6957 19.9464 14.1522 21 11.5 21Z"
								fill="white" />
							<path
								d="M6.5 11L10.7857 14.5714L16.5 7.42857M11.5 21C10.1868 21 8.88642 20.7413 7.67317 20.2388C6.45991 19.7362 5.35752 18.9997 4.42893 18.0711C3.50035 17.1425 2.76375 16.0401 2.2612 14.8268C1.75866 13.6136 1.5 12.3132 1.5 11C1.5 9.68678 1.75866 8.38642 2.2612 7.17317C2.76375 5.95991 3.50035 4.85752 4.42893 3.92893C5.35752 3.00035 6.45991 2.26375 7.67317 1.7612C8.88642 1.25866 10.1868 1 11.5 1C14.1522 1 16.6957 2.05357 18.5711 3.92893C20.4464 5.8043 21.5 8.34784 21.5 11C21.5 13.6522 20.4464 16.1957 18.5711 18.0711C16.6957 19.9464 14.1522 21 11.5 21Z"
								stroke="#6F64A7" stroke-width="1.3" />
						</svg>
						Select Clinic
					</button>
				</div>
			</div> -->

		</div>
	</div>
</p-sidebar>
