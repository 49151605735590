import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { SyncSalesOrderStatus, SyncHistory } from 'src/app/shared/models/response/sync-sales-order-status';
import { WipStatusBTResponseModel } from 'src/app/shared/models/response/wip-statusbt-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ImportexportService } from '../../servicies/importexport.service';
import { UploadService } from '../../servicies/upload.service';
import { OverlayPanel } from 'primeng/overlaypanel';

class DynamicGrid {
  salesOrderId: string;
  status: string;
  constructor(salesOrderId: string, status: string) {
    this.salesOrderId = salesOrderId;
    this.status = status;
  }
}

@Component({
  selector: 'app-reimport-salesorder-byid',
  templateUrl: './reimport-salesorder-byid.component.html',
  styleUrls: ['./reimport-salesorder-byid.component.sass']
})
export class ReimportSalesorderByidComponent extends BaseComponent implements OnInit, OnDestroy {
  SalesOrderId: string = "";
  IsUpdateWIPStatus: boolean = false;
  title = 'Re-Import Sales Order ID';
  Buttontitle = 'Re-Import';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;

  wipStatesResponseModel: WipStatusBTResponseModel[];
  selectedwipStatesResponseModel: WipStatusBTResponseModel;
  syncSalesOrderStatus: SyncSalesOrderStatus;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  formSyncSalesOrdersubmitted: boolean = false;
  loggedInUserId: number;
  syncHistory: SyncHistory[];
  newSOList: string;
  dupSOList: string;
  totalSOList: string;
  showHistory: boolean = false;
  isValueRequire: boolean = false;
  error: any = { isError: false, errorMessage: '' };
  isSubmitBtnDisabled: boolean = false;
  isShowTable: boolean = false;

  lstGrid: DynamicGrid[];
  lstSalesOrder: string[];

  constructor(private importExportService: ImportexportService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitzer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }

  async ngOnInit() {
    this.lstGrid = [];
  }

  ReImportSalesOrders() {
    this.isShowTable = false;
    this.isValueRequire = false;
    this.isSubmitBtnDisabled = true;
    this.Buttontitle = "In Progress";
    if (this.SalesOrderId != null && this.SalesOrderId != "") {
      this.lstGrid = [];
      this.lstSalesOrder = this.SalesOrderId.split(',');

      this.lstSalesOrder = this.lstSalesOrder.filter(function (el) {
        return el != "";
      });

      if (this.lstSalesOrder.length > 0) {
        if (this.lstSalesOrder.length > 10) {
          this.showSuccessMessage('Maximum 10 sales orders are alllow to import!!');
        }
        else {
          for (var i = 0; i < this.lstSalesOrder.length; i++) {
            let _soId = this.lstSalesOrder[i];
            this.lstGrid.push(new DynamicGrid(this.lstSalesOrder[i], "Queue"))
          }
          this.isShowTable = true;
          this.ImportSalesOrder();
        }

      }
      else {
        this.SalesOrderId = "";
        this.showSuccessMessage('Minimum one sales order is require!!');
        this.isShowTable = false;
        this.isSubmitBtnDisabled = false;
        this.Buttontitle = "Re-Import";
      }
    }
    else {
      this.isShowTable = false;
      this.isSubmitBtnDisabled = false;
      this.Buttontitle = "Re-Import";
      this.isValueRequire = true;
    }
  }

  ImportSalesOrder() {
    if (this.lstSalesOrder.length > 0) {
      let _soId = this.lstSalesOrder[0];

      for (let item of this.lstGrid) {
        if (item.salesOrderId == _soId) {
          item.status = 'In Progress';
        }
      }
      this.lstSalesOrder.shift();
      this.loading = true;
      this.importExportService.ReImportSalesOrderById(_soId, this.IsUpdateWIPStatus)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            let _gridmodel = result.responseModel;
            for (let item of this.lstGrid) {
              if (item.salesOrderId == _soId) {
                item.status = _gridmodel.status;
              }
            }
            this.ImportSalesOrder();
          }
          else {
            for (let item of this.lstGrid) {
              if (item.salesOrderId == _soId) {
                item.status = "Fail to Import";
              }
            }
            this.ImportSalesOrder();
          }
        }, (httpResponseError) => {
          for (let item of this.lstGrid) {
            if (item.salesOrderId == _soId) {
              item.status = "Error(" + httpResponseError.message + ")";
            }
          }
          this.ImportSalesOrder();
        });
    }
    else {
      this.loading = false;
      this.SalesOrderId = "";
      this.isSubmitBtnDisabled = false;
      this.Buttontitle = "Re-Import";
      this.showSuccessMessage('Request execute successfully');
    }
  }

  onUpdateWIPStatus(status) {
    this.IsUpdateWIPStatus = status.checked;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
}
