<!-- <p-scrollPanel [style]="{width: '100%', height: '100%'}"> -->
<div class="notification-outer">

  <div class="status-mapping">
    <div class="add-buttons-for-license">
      <h3>Schedule the Reminder</h3>

      <!-- <a class="last-box__add-btn" (click)="ReminderInfoButton()" href="javascript:void(0)">
          <i class="ic plus"></i>
        </a> -->
      <a href="javascript:void(0);" pTooltip="Add Schedule the Reminder" tooltipPosition="left" class="add-plus"
        (click)="ReminderInfoButton()"><img src="assets/img/add-plus.svg" width="17" alt="filters"></a>
    </div>

    <div class="noti-patient-outer">
      <p-table class="table-outer notifications" [value]="remindersModel" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th class="table-outer__th">Reminder Type</th>
            <th class="table-outer__th">Reminder Mode</th>
            <th class="table-outer__th">Reminder Lead Time</th>
            <th class="table-outer__th table-dot-column" width="120">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reminder>
          <!-- *ngFor="let reminder of remindersModel" -->
          <tr>
            <td class="table-outer__td">{{reminder.crmWipStatusDisplayValue}}</td>
            <td class="table-outer__td">{{ reminder.crmReminderType.replace("And", " & ")}}</td>
            <td class="table-outer__td">{{reminder.crmReminderFrequency}} {{reminder.crmReminderFrequencyType}}</td>
            <td class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)" (click)="userAction.toggle($event)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>


              <p-overlayPanel class="table-action-popup" appendTo="body" #userAction>
                <ul class="quick-link-menu">
                  <li> <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                      (click)="onReminderEdit(reminder)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit</a></li>
                  <li><a class="delete-row" href="javascript:void(0)" (click)="onStatusDelete(reminder)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete</a></li>
                </ul>
              </p-overlayPanel>


            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<!-- </p-scrollPanel> -->


<p-dialog header="Title" [(visible)]="EditReminderDialog" [modal]="true" appendTo="body">
  <div class="popup-content-outer schedule-reminder-dialog">
    <h3 class="notification-overlay-popup__title">{{reminderBtnTitle}} Reminder</h3>
    <!-- [ngClass]="eMedAssistReminder ? 'eMedAssistBrightree-form-active' : 'eMedAssistBrightree-form-hide'" -->
    <form class="form" [formGroup]="formReminder" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Reminder Type</label>
          <p-dropdown appendTo="body" class="select-box" formControlName="brightreeStatus" placeholder="Select Reminder Type"
            #brightreeDropdown [(ngModel)]="selectedBrightreeStatus" [options]="wipStatus" optionLabel="appAlias">
          </p-dropdown>
        </div>
        <div class="form__group">
          <label class="form__label">Reminder Mode</label>
          <p-dropdown class="select-box" formControlName="remindertype" placeholder="Select Reminder Mode"
            [(ngModel)]="selectedreminderTypeModelOptions" [options]="reminderTypeModelOptions"
            optionLabel="crmReminderTypeDisplayValue" appendTo="body">
          </p-dropdown>
          <div *ngIf="formReminderSubmitted && fReminder.remindertype.errors" class="invalid-feedback">
            <div *ngIf="fReminder.remindertype.errors.required || fReminder.remindertype.errors">
              {{validationMessages.remindertype.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Frequency Type</label>
          <p-dropdown class="select-box" formControlName="frequencyType" placeholder="Select Frequency Type"
            [(ngModel)]="selectedreminderFrequencyTypeOptions" [options]="reminderFrequencyTypeOptions"
            optionLabel="displayName" appendTo="body">
          </p-dropdown>
          <div *ngIf="formReminderSubmitted && fReminder.frequencyType.errors" class="invalid-feedback">
            <div *ngIf="fReminder.frequencyType.errors.required || fReminder.frequencyType.errors">
              {{validationMessages.frequencyType.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Reminder Lead Time</label>
          <input type="text" formControlName="frequency" class="form__control" pInputText>
          <div *ngIf="formReminderSubmitted && fReminder.frequency.errors" class="invalid-feedback">
            <div *ngIf="fReminder.frequency.errors.required">
              {{validationMessages.frequency.required}}
            </div>
            <div *ngIf="fReminder.frequency.errors.validNumber">
              {{validationMessages.frequency.validNumber}}
            </div>
          </div>
        </div>

        <div class="form__group button-box">
          <div class="btns">
            <button class="btn btn--primary" (click)="onReminderAdd()">{{reminderBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onReminderCancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
