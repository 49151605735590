import { DatePipe, Location } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { UserPermissions } from 'src/app/shared/enums';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { BellNotificationInAppDetailResponseModel, BellNotificationInAppDetailsSummaryResponseModel, BellNotificationInAppReadStatusRequestModel, BellNotificationInAppResponseModel, BellNotificationRequestModel } from 'src/app/shared/models/response/bell-notification-response-model';
import { NotificationService } from '../servicies/notification.service';
import { SettingsService } from '../servicies/settings.service';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { AppointmentService } from '../servicies/appointment.service';

@Component({
  selector: 'app-view-all-notifications',
  templateUrl: './view-all-notifications.component.html',
  styleUrls: ['./view-all-notifications.component.sass']
})
export class ViewAllNotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedInUserId: number;
  unReadCount: number;
  loading: boolean;
  bellNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellSalesOrderNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellPatientNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellTherapistNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellAlertNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  selectedSalesOrderSyncDetails: BellNotificationInAppDetailsSummaryResponseModel[];
  appDateFormat: DateFormatModel;
  patientBrighttreeURL: any;
  brightreeInternalUserId: number;
  salesOrderBrighttreeURL: any;
  userPerissionfeatures: userModuleFeatureResponseModel[];
  allowToViewSalesOrderNotifications: boolean;
  allowToViewPatientNotifications: boolean;
  allowToViewTherapistNotifications: boolean;
  allowToViewAlertNotifications: boolean;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; };
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  selectedBranchCode: number;

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private appointmentService: AppointmentService,
    messageService: MessageService,
    private _location: Location,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;

    this.GetRCMConfigurationUrl();
  }

  ngOnInit(): void {
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST' },
    ];
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);
    this.selectedBranchCode = this.webStorageService.getUserBranch();
    this.setUserPermissionforActions();
    this.getNotifications();
  }

  ngOnDestroy(): void {

    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  private getNotifications() {
    this.unReadCount = 0;
    this.loading = true;
    let bellNotificationRequestModel = new BellNotificationRequestModel();
    bellNotificationRequestModel.userInfoId = this.loggedInUserId;
    let userPermission: string = '';
    if (this.allowToViewSalesOrderNotifications)
      userPermission = 'SalesOrder,';
    if (this.allowToViewPatientNotifications)
      userPermission += 'Patient,';
    if (this.allowToViewTherapistNotifications)
      userPermission += 'Therapist,';
    if (this.allowToViewAlertNotifications)
      userPermission += 'Alert,';
    userPermission = userPermission.substring(0, userPermission.length - 1);
    bellNotificationRequestModel.userPermissions = userPermission;
    bellNotificationRequestModel.branchId = this.selectedBranchCode;
    bellNotificationRequestModel.isWeekView = false;

    this.loadingCustom = true;
    if (userPermission.trim().length > 0) {
      this.notificationService.getBellNotifications(bellNotificationRequestModel, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<any>) => {
          this.processResult<any>(result, () => {
            this.loadingCustom = false;
            this.bellNotificationInAppResponseModel = result.responseModel;
            this.LoadOtherTabsNotifications();
          });
        }, (httpResponseError) => {
          this.loadingCustom = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  LoadOtherTabsNotifications() {
    this.unReadCount = this.bellNotificationInAppResponseModel.reduce((sum, unReadCount) => sum + unReadCount.bellNotificationInAppDetail.filter(x => !x.appIsRead).length, 0);
    this.bellSalesOrderNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'SalesOrder') }
    });
    this.bellPatientNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Patient') }
    });
    this.bellTherapistNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Therapist') }
    });
    this.bellAlertNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Alert') }
    });
  }

  setUserPermissionforActions() {
    this.userPerissionfeatures = this.webStorageService.getUserPermissions();
    if (this.userPerissionfeatures && this.userPerissionfeatures.length > 0) {

      let inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewSyncNotifications);
      if (inPer1) {
        this.allowToViewSalesOrderNotifications = true;
      }

      let inPer2 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewPatientNotifications);
      if (inPer2) {
        this.allowToViewPatientNotifications = true;
      }

      let inPer3 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewTherapistNotifications);
      if (inPer3) {
        this.allowToViewTherapistNotifications = true;
      }

      let inPer4 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewAlertNotifications);
      if (inPer4) {
        this.allowToViewAlertNotifications = true;
      }
    }
  }

  getNotificationDate(notificationDate: Date) {
    var notificationDateWithoutTime = moment(notificationDate).format('MM-DD-YYYY');

    var currentDateTime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    var currentDate = new Date(currentDateTime);
    let yesterdayNotificationDateWithoutTime = new Date().setDate(currentDate.getDate() - 1);
    if (notificationDateWithoutTime == (moment(currentDate).format('MM-DD-YYYY'))) {
      return 'Today';
    }
    else if (notificationDateWithoutTime == (moment(yesterdayNotificationDateWithoutTime).format('MM-DD-YYYY'))) {
      return 'Yesterday';
    } else {
      return moment(notificationDate).format('MM/DD/YYYY');
    }
  }

  getNotificationTime(notificationDate: Date) {
    // var notificationDateWithoutTime = moment(notificationDate).format('MM-DD-YYYY');
    // //var currentDate = new Date();

    // var currentDateTime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    // var currentDate = new Date(currentDateTime);

    // if (notificationDateWithoutTime == (moment(currentDate).format('MM-DD-YYYY'))) {

    //   var diff = currentDate.getTime() - new Date(notificationDate).getTime();
    //   var duration = this.msToTime(diff);
    //   return duration;
    // } else {
    //   return moment(notificationDate).format('MM/DD/YYYY | hh:mm a | ') + this.selectedTimeZone.shortName;
    // }
    return moment(notificationDate).format('MM/DD/YYYY | hh:mm a | ') + this.selectedTimeZone.shortName;
  }

  msToTime(millisec) {
    var seconds = parseInt((millisec / 1000).toFixed(0));
    var minutes = parseInt((millisec / (1000 * 60)).toFixed(0));
    var hours = parseInt((millisec / (1000 * 60 * 60)).toFixed(0));
    //var days = parseInt((millisec / (1000 * 60 * 60 * 24)).toFixed(0));

    if (seconds < 60) {
      return "1 minute ago";
    } else if (minutes < 60) {
      return minutes + " minutes ago";
    } else if (hours < 24) {
      return hours + " hour ago";
    }
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  IsNotificationRead(item: any) {
    item.appIsRead = true;
    this.LoadOtherTabsNotifications();

    let bellNotificationInAppReadStatusRequestModel = new BellNotificationInAppReadStatusRequestModel();
    bellNotificationInAppReadStatusRequestModel.appBellNotificationInAppId = item.appBellNotificationInAppId;
    bellNotificationInAppReadStatusRequestModel.appIsRead = item.appIsRead;
    bellNotificationInAppReadStatusRequestModel.appUserInfoId = this.loggedInUserId;
    bellNotificationInAppReadStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;

    let bellNotificationInAppReadStatusRequestModelList: BellNotificationInAppReadStatusRequestModel[];
    bellNotificationInAppReadStatusRequestModelList = [];
    bellNotificationInAppReadStatusRequestModelList.push(bellNotificationInAppReadStatusRequestModel);
    this.UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModelList);
  }

  MarkAllNotoficationAsRead() {
    let bellNotificationInAppReadStatusRequestModelList: BellNotificationInAppReadStatusRequestModel[];
    bellNotificationInAppReadStatusRequestModelList = [];

    this.bellNotificationInAppResponseModel.forEach(element => {
      element.bellNotificationInAppDetail.forEach(item => {
        if (!item.appIsRead) {
          let bellNotificationInAppReadStatusRequestModel = new BellNotificationInAppReadStatusRequestModel();
          bellNotificationInAppReadStatusRequestModel.appBellNotificationInAppId = item.appBellNotificationInAppId;
          item.appIsRead = true;
          bellNotificationInAppReadStatusRequestModel.appIsRead = item.appIsRead;
          bellNotificationInAppReadStatusRequestModel.appUserInfoId = this.loggedInUserId;
          bellNotificationInAppReadStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
          bellNotificationInAppReadStatusRequestModelList.push(bellNotificationInAppReadStatusRequestModel);
        }
      })
    });

    this.UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModelList);
    this.LoadOtherTabsNotifications();
  }

  async UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModel: BellNotificationInAppReadStatusRequestModel[]) {
    if (bellNotificationInAppReadStatusRequestModel.length == 0)
      return;

    this.notificationService.updateBellNotificationInAppReadStatus(bellNotificationInAppReadStatusRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result) {

          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  LoadSalesOrdersThoseNotConfiguredAddress(bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[]): BellNotificationInAppDetailsSummaryResponseModel[] {
    let bellNotificationInAppDetailsSummaryTemp = bellNotificationInAppDetailsSummary.filter((subElement) => !subElement.appIsDeliveryAddressConfigured);
    return bellNotificationInAppDetailsSummaryTemp;
  }

  LoadSalesOrdersThoseNotConfiguredEmails(bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[]): BellNotificationInAppDetailsSummaryResponseModel[] {
    let bellNotificationInAppDetailsSummaryTemp = bellNotificationInAppDetailsSummary.filter((subElement) => !subElement.appIsEmailIdConfigured);
    return bellNotificationInAppDetailsSummaryTemp;
  }

  LoadSalesOrdersThoseNotConfiguredPhoneNo(bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[]): BellNotificationInAppDetailsSummaryResponseModel[] {
    let bellNotificationInAppDetailsSummaryTemp = bellNotificationInAppDetailsSummary.filter((subElement) => !subElement.appIsPhoneNoConfigured);
    return bellNotificationInAppDetailsSummaryTemp;
  }

  LoadSalesOrdersThoseNotConfiguredClassification(bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[]): BellNotificationInAppDetailsSummaryResponseModel[] {
    let bellNotificationInAppDetailsSummaryTemp = bellNotificationInAppDetailsSummary.filter((subElement) => !subElement.appIsClssificationDetailsConfigured);
    return bellNotificationInAppDetailsSummaryTemp;
  }

  LoadSalesOrdersThoseNotConfiguredBranch(bellNotificationInAppDetailsSummary: BellNotificationInAppDetailsSummaryResponseModel[]): BellNotificationInAppDetailsSummaryResponseModel[] {
    let bellNotificationInAppDetailsSummaryTemp = bellNotificationInAppDetailsSummary.filter((subElement) => !subElement.appIsBranchDetailsConfigured);
    return bellNotificationInAppDetailsSummaryTemp;
  }

  onBack() {
    this._location.back();
  }

  onPatientNotificationView(item: BellNotificationInAppDetailResponseModel) {
    let schAppointmentId = item.bellNotificationInAppDetailsSummary[0].schAppointmentId;
    let salesOrderId = item.bellNotificationInAppDetailsSummary[0].appSalesOrderId;

    this.appointmentService.GetSalesOrderById(Number(salesOrderId), true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              if (result.responseModel.crmSalesorderStatus.toLowerCase() !== Constants.complete
                && result.responseModel.crmSalesorderStatus.toLowerCase() !== Constants.voidreview) {
                let salesOrder = result.responseModel;
                schAppointmentId = salesOrder.schAppointmentId;
                if (schAppointmentId > 0) {
                  let setUrl = '/admin/appointments/detail/' + schAppointmentId + '/notification';
                  this.redirectToPage(setUrl);
                }
                else {
                  let setUrl = '/admin/appointments/salesorders/detail/' + salesOrderId + '/notification';
                  this.redirectToPage(setUrl);
                }
              }
              else{
                this.showErrorMessage("You are not redirected to the Appointment details page because the selected Appointment is either Completed or marked as Void Review.");
              }
            }
          }
          else {
            this.loading = false;
          }
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  redirectToPage(url) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url])
    );
  }

  ResendAlertNotification(item: any) {
    var NotificationType = item.bellNotificationInAppDetailsSummary[0].appNotificationType;
    var NotificationSendId = item.bellNotificationInAppDetailsSummary[0].appNotificationSendId;

    this.notificationService.ResendAlertNotification(NotificationSendId, NotificationType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result) {
            this.loadingCustom = false;
            this.getNotifications();
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
