import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { ProvisionalSearchRequestModel, SearchRequestModel } from '../models/request/search-request-model';
import { ProvisionalPatientInfoResponseModel, ProvisionalPatientInforResponseModel } from '../models/response/appointment/provisional-patient-Info-reponse-model';
import { SalesOrderSummaryResponseModel } from '../models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from '../models/response/paged-response-model';
import { ResultOfT } from '../models/response/result';
import { BaseService } from './base.service';
import { WebStorageService } from './web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProvisionalsalesorderService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getPatientbyId(PatientId: number) {
    return this.get<ResultOfT<ProvisionalPatientInfoResponseModel>>('ProvisionalSalesOrder/GetPatientInfo?PatientId=' + PatientId);
  }

  GetPatientInfoByBrightreeID(BrightreeID: number) {
    return this.get<ResultOfT<ProvisionalPatientInfoResponseModel>>('ProvisionalSalesOrder/GetPatientInfoByBrightreeID?BrightreeID=' + BrightreeID);
  }

  getPatientbyName(provisionalsearchRequestModel: ProvisionalSearchRequestModel) {
    var url = 'ProvisionalSalesOrder/PatientSearchByFilter';
    if (provisionalsearchRequestModel.firstName && provisionalsearchRequestModel.lastName && provisionalsearchRequestModel.dob) {
      url = url + '?FirstName=' + provisionalsearchRequestModel.firstName;
      url = url + '&LastName=' + provisionalsearchRequestModel.lastName;
      url = url + '&DateofBirth=' + provisionalsearchRequestModel.dob;
    }
    else if(provisionalsearchRequestModel.firstName && provisionalsearchRequestModel.lastName){
      url = url + '?FirstName=' + provisionalsearchRequestModel.firstName;
      url = url + '&LastName=' + provisionalsearchRequestModel.lastName;
    }
    else if(provisionalsearchRequestModel.lastName && provisionalsearchRequestModel.dob){
      url = url + '?LastName=' + provisionalsearchRequestModel.lastName;
      url = url + '&DateofBirth=' + provisionalsearchRequestModel.dob;
    }
    else if(provisionalsearchRequestModel.firstName && provisionalsearchRequestModel.dob){
      url = url + '?FirstName=' + provisionalsearchRequestModel.lastName;
      url = url + '&DateofBirth=' + provisionalsearchRequestModel.dob;
    }
    else if(provisionalsearchRequestModel.firstName){
      url = url + '?FirstName=' + provisionalsearchRequestModel.firstName;
    }
    else if (provisionalsearchRequestModel.lastName) {
      url = url + '?LastName=' + provisionalsearchRequestModel.lastName;
    }
    else if (provisionalsearchRequestModel.dob) {
      url = url + '?DateofBirth=' + provisionalsearchRequestModel.dob;
    }
    if (provisionalsearchRequestModel.PageSize) {
      url = url + '&PageSize=' + provisionalsearchRequestModel.PageSize;
    }
    if (provisionalsearchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + provisionalsearchRequestModel.CurrentPageIndex;
    }
    return this.get<ResultOfT<PagedResponseModel<ProvisionalPatientInforResponseModel>>>(url);
  }

  GetProvisionalSalesOrders(searchRequestModel: SearchRequestModel) {
    var url = 'ProvisionalSalesOrder/GetAllProvisionalSalesOrders';
    url = url + '?appUserInfoId=' + searchRequestModel.appUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }
    if (searchRequestModel.crmPatientId) {
      url = url + '&PatientID=' + searchRequestModel.crmPatientId;
    }

    let selTimeZone = searchRequestModel.selectedTimeZone == undefined ? "" : searchRequestModel.selectedTimeZone;
    url = url + '&SelectedTimeZone=' + selTimeZone;

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>>(url);
  }

  CreateProvisionalSalesOrder(patientInfoRequestModel : ProvisionalPatientInfoResponseModel) {
    return this.post<ResultOfT<string>>('ProvisionalSalesOrder/CreateProvisionalSalesOrder/', patientInfoRequestModel)
      .pipe(map((result) => {
        return result;
      }));
  }
}
