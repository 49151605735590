<div class="patient-info">
  <div class="patient-info-top">
    <div class="patient-info-top__left">
      <h2>
        {{salesOrder.patientInfo.crmPatientFirstName}}, {{salesOrder.patientInfo.crmPatientLastName}}
        <i class="info-new" pTooltip="SO # : {{salesOrder.crmSalesorderAppId}}, Patient # : {{salesOrder.crmPatientId}}"></i>
      </h2>
      <span>{{salesOrder.patientInfo.crmPatientBirthDate | date: appDateFormat.dateFormat}}</span>
      <ul>
        <li><a href="mailto:{{salesOrder.patientInfo.crmPatientEmailAddress}}" pTooltip="{{salesOrder.patientInfo.crmPatientEmailAddress}}"><i class="email-ic-new"></i></a></li>
        <li><a href="tel:{{salesOrder.patientInfo.crmPatientPhoneNo}}" pTooltip="{{salesOrder.patientInfo.crmPatientPhoneNo}}"><i class="phone-icon-new-primary"></i></a></li>
        <li *ngIf="salesOrder.patientInfo.crmPatientAddress"><a href="javascript:void(0)" pTooltip="{{salesOrder.patientInfo.crmPatientAddress }}"><i class="location-icon"></i></a></li>
      </ul>
      <ul *ngIf="salesOrder.crmSalesorderOrderNote || salesOrder.crmSalesorderDeliveryNote">
        <li *ngIf="salesOrder.crmSalesorderOrderNote"><a href="javascript:void(0)" pTooltip="{{salesOrder.crmSalesorderOrderNote}}">Order Note</a></li>
        <li *ngIf="salesOrder.crmSalesorderDeliveryNote"><a href="javascript:void(0)" pTooltip="{{salesOrder.crmSalesorderDeliveryNote}}">Delivery Note</a></li>

      </ul>
    </div>
    <div class="patient-info-top__right" *ngIf="salesOrder.doctorInfo">
      <h2>Doctor Info.  </h2>
      <h3>{{salesOrder.doctorInfo.crmDoctorFirstName}}, {{salesOrder.doctorInfo.crmDoctorLastName}}</h3>
      <ul *ngIf="salesOrder.doctorInfo.crmDoctorContactEmailAddress || salesOrder.doctorInfo.crmDoctorContactNo">
        <li *ngIf="salesOrder.doctorInfo.crmDoctorContactEmailAddress"><a href="mailto:{{salesOrder.doctorInfo.crmDoctorContactEmailAddress}}" pTooltip="{{salesOrder.doctorInfo.crmDoctorContactEmailAddress}}"><i class="email-ic-new"></i></a></li>
        <li *ngIf="salesOrder.doctorInfo.crmDoctorContactNo"><a href="tel:{{salesOrder.doctorInfo.crmDoctorContactNo}}" pTooltip="{{salesOrder.doctorInfo.crmDoctorContactNo}}"><i class="phone-icon-new-primary"></i></a></li>
      </ul>
    </div>
  </div>
  <div class="patient-info-middle">
    <h2>Device Info.</h2>
    <div class="patient-info-middle__boxs left">
      <strong>Device</strong>
      <small>{{salesOrder.crmDeviceName}}</small>
    </div>
    <div class="patient-info-middle__boxs center">
      <strong>Classification Type</strong>
      <small>{{salesOrder.crmClassification}}</small>
    </div>
    <div class="patient-info-middle__boxs right">
      <strong> Duration</strong>
      <small>{{salesOrder.crmClassificationDuration}}<i class="info-new" pTooltip="{{salesOrder.crmClassificationDescription}}"></i></small>
    </div>
    <div class="patient-info-middle__btn">
      <button class="btn btn--secondary small" (click)="ViewSalesOrder()">View More</button>

      <span (click)="amountDue.toggle($event)"><i class="ic dollar"></i> {{salesOrder.amountDue | currency}} </span>
    </div>
  </div>

  <div class="patient-info-bottom">
    <h2>Appointment Type</h2>
    <div class="ui-g">
      <p-radioButton name="group1" value="FacilityVisit" label="Facility Visit" [(ngModel)]="selectedAppointmentType"  ></p-radioButton>

      <p-radioButton name="group1" value="HomeVisit" label="Home Visit" [(ngModel)]="selectedAppointmentType" >
      </p-radioButton>

      <p-radioButton name="group1" value="Telehealth" label="Remote Setup" [(ngModel)]="selectedAppointmentType" >
      </p-radioButton>
    </div>
    <button class="btn btn--primary" (click)="bookAppointment()">{{scheduleAction}} Appointment</button>
  </div>
</div>


<p-overlayPanel class="table-action-popup" #amountDue [showCloseIcon]="true">
  <div class="patient-info-middle--modify">
    <div class="patient-info-middle__boxs left">
      <strong>Item No.</strong>
      <ul>
        <li>CIR 1000482</li>
        <li>CIR 1000484</li>
        <li>CIR 1000485</li>

      </ul>
    </div>
    <div class="patient-info-middle__boxs left">
      <strong>Item Name</strong>
      <ul>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
    </div>
    <div class="patient-info-middle__boxs left">
      <strong>Amount</strong>
      <ul>
        <li>$10</li>
        <li>$15</li>
        <li>$5</li>
      </ul>
    </div>
  </div>
  <div class="patient-info-middle__btn">
    Amount Due <span><i class="ic dollar"></i> $50</span>
  </div>
</p-overlayPanel>
