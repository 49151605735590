export const colors: any = {
  green: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  purple: {
    primary: '#e3deff',
    secondary: '#e3deff',
  },
  blue: {
    primary: '#EDEAFF',
    secondary: '#EDEAFF',
  },
  grey: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  lightGrey: {
    primary: '#dbdbdb',
    secondary: '#dbdbdb',
  },
  darkGrey: {
    primary: '#cbcbcb',
    secondary: '#cbcbcb',
  },
  transparent: {
    primary: '#ffffff00',
    secondary: '#ffffff00',
  },
  orange: {
    primary: '#F9F9F9',
    secondary: '#F9F9F9',
  },
};
