import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { FacilityResponseModel, FacilityResponseModelSend, FacilityResponseModelDropdown, FacilityResponseModelDropdownList, FacilityResponseModelDefaultTime, SelectedFacilityResponseModel, LinkedTherapistWithCurrentFacilitiesResponseModel, LinkedTherapistWithCurrentFacilitiesRequestModel } from 'src/app/shared/models/response/facility/facility-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { TherapistInfoResponseModel } from 'src/app/shared/models/response/therapist/therapistinfo-response-model';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { SchedulerResponseModel, SchedulerTherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';
import { ReassignTherapistAvailabilitySearchRequestModel } from 'src/app/shared/models/request/therapist/reassign-therapist-availability-request-model';
import { TherapistAvailabilityForReassignResponseModel } from 'src/app/shared/models/response/therapist/therapist-availabilityfor-reassign-response-model';
import { InsuranceInfoResponseModel } from 'src/app/shared/models/response/insurance-info-response-model';
import * as moment from 'moment';
import { WebStorageService } from '../../shared/services/web-storage.service';
@Injectable({
  providedIn: 'root'
})
export class FacilityService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getAllFacilities(searchRequestModel: SearchRequestModel, isRefresh: boolean) {
    var url = 'Facility/GetAllPagedFacilities';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;

    if (searchRequestModel.appUserInfoId) {
      url = url + '&appUserInfoId=' + searchRequestModel.appUserInfoId;
    }
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }

    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }
    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }
    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<FacilityResponseModel>>>(url);
    // return this.post<ResultOfT<PagedResponseModel<FacilityResponseModel>>>('Facility/GetAllPagedFacilities', searchRequestModel);
  }

  getAllFacility() {
    return this.get<ResultOfT<FacilityResponseModel[]>>('Facility/GetAllFacilities');
  }

  getAllFacilitiesBranch(branchId: Array<string>) {
    return this.post<ResultOfT<FacilityResponseModel[]>>('Facility/GetAllFacilitiesBranch' , branchId);
  }

  getAllFacilitiesBranches(branches: Array<BranchResponseModel>) {
    return this.post<ResultOfT<FacilityResponseModel[]>>('Facility/GetAllFacilitiesBranches', branches);
  }

  getAllFacilityDropDown() {
    return this.get<ResultOfT<FacilityResponseModel[]>>('Facility/GetAllFacilitiesDropDown');
  }
  getAllFacilityDropDownRemote(isRemote: string) {
    return this.get<ResultOfT<FacilityResponseModelDropdown[]>>('Facility/GetAllFacilitiesDropDownRemote/' + isRemote);
  }

  getAllFacilityDropDownforAvailability(therapistId: string) {
    return this.get<ResultOfT<FacilityResponseModelDropdownList[]>>('Facility/GetAllFacilitiesForAvailability/' + therapistId);
  }

  GetAllFacilitiesForRoadHomeVisit(therapistId: string) {
    return this.get<ResultOfT<FacilityResponseModelDropdownList[]>>('Facility/GetAllFacilitiesForRoadHomeVisit/' + therapistId);
  }

  getTime(facilityId: number, day: number) {
    return this.get<ResultOfT<FacilityResponseModelDefaultTime>>('Facility/GetFacilityTime/' + facilityId + '/' + day);
  }
  getTimeWithTimeZone(facilityId: number, day: number,timeZone:string) {
    return this.get<ResultOfT<FacilityResponseModelDefaultTime>>('Facility/GetFacilityTimeWithTimeZone/' + facilityId + '/' + day + '/' + timeZone);
  }

  getFacilityById(facilityId: number) {
    return this.get<ResultOfT<FacilityResponseModel>>('Facility/GetFacility/' + facilityId);
  }

  addFacility(newFacility: FacilityResponseModelSend) {
    return this.post<ResultOfT<number>>('Facility/Add', newFacility);
  }

  updateFacility(updatedFacility: FacilityResponseModelSend) {
    return this.post<ResultOfT<number>>('Facility/Update', updatedFacility);
  }

  removeFacility(facilityId: string) {
    return this.get<ResultOfT<boolean>>('Facility/ActiveInactive/' + facilityId + '/' + false);
  }

  getAllBranches() {
    return this.get<ResultOfT<BranchResponseModel[]>>('Users/GetBranches');
  }

  getAllInsuranceInfoes() {
    return this.get<ResultOfT<InsuranceInfoResponseModel[]>>('Facility/GetAllInsuranceInfo');
  }

  getAllTherapist() {
    return this.get<ResultOfT<UserResponseModel[]>>('Therapist/GetAllTherapists');
  }

  GetFacilitiesByBranch(branchId: number) {
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>('Facility/GetFacilitiesByBranch/' + branchId);

  }
  GetTherapistAppointmentsByFacility(facilityId: number, appointmentDate: string) {
    return this.get<ResultOfT<SchedulerResponseModel[]>>('Appointment/GetTherapistAppointmentsByFacility/' + facilityId + '/' + appointmentDate);
  }

  GetAllTherapistForAppointmentsCalendar(appointmentDate: string) {
    return this.get<ResultOfT<SchedulerResponseModel[]>>('Therapist/GetAllTherapistForAppointmentsCalendar/' + appointmentDate);
  }

  GetFacilitiesWithTherapistExclusion(therapistId: string) {
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>('Facility/GetFacilitiesWithTherapistExclusion/' + therapistId);
  }

  GetFacilitiesWithDoctorExclusion(doctorId: number) {
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>('Facility/GetFacilitiesWithDoctorExclusion/' + doctorId);
  }

  importFile() {
    return this.get<ResultOfT<boolean>>('Facility/ImportFacility');
  }

  GetAllAvailableTherapistByBranchAndDate(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Therapist/GetAllAvailableTherapistByBranchAndDate?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;
    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      //let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;
    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    return this.get<ResultOfT<TherapistResponseModel[]>>(url);
  }

  GetFacilitiesByBranchIds(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {

    var url = 'Therapist/GetFacilitiesByBranch?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }
    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      //let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      //let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    //return this.post<ResultOfT<FacilityInfoResponseModel[]>>('Therapist/GetFacilitiesByBranch', therapistAppointmentSearchRequestModel);
    return this.get<ResultOfT<FacilityInfoResponseModel[]>>(url
    );
  }

  GetFacilitiesByBranchIdsForCalendar(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    return this.post<ResultOfT<FacilityInfoResponseModel[]>>('Therapist/GetFacilitiesByBranchCalendar', therapistAppointmentSearchRequestModel);

  }

  GetAllTherapistForAppointments(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Therapist/GetAllTherapistForAppointments?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  GetAllTherapistForAppointmentsByTimeZone(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Therapist/GetAllTherapistForAppointmentsByTimeZone?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerTherapistResponseModel>>(url);
  }


  GetAllAvailableTherapistByDateToReassign(therapistReassignSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel) {

    let datepass = moment(therapistReassignSearchRequestModel.appointmentDate).format('MM-DD-YYYY');
    var url = 'Therapist/GetAllAvailableTherapistByDateToReassign?appointmentDate=' + datepass;

    if (therapistReassignSearchRequestModel.crmTherapistId) {
      url = url + '&crmTherapistId=' + therapistReassignSearchRequestModel.crmTherapistId;
    }
    if (therapistReassignSearchRequestModel.crmNewTherapistId) {
      url = url + '&crmNewTherapistId=' + therapistReassignSearchRequestModel.crmNewTherapistId;
    }
    if (therapistReassignSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistReassignSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + json;
    }
    if (therapistReassignSearchRequestModel.schAppointmentIds) {
      let json = JSON.stringify(therapistReassignSearchRequestModel.schAppointmentIds);
      url = url + '&schAppointmentIds=' + json;
    }
    if (therapistReassignSearchRequestModel.isMakeUnAvailable)
      url = url + '&isMakeUnAvailable=' + therapistReassignSearchRequestModel.isMakeUnAvailable;

    url = url + '&crmFacilityTimeZone=' + therapistReassignSearchRequestModel.crmFacilityTimeZone;

    url = url + '&crmPatientTimeZone=' + therapistReassignSearchRequestModel.crmPatientTimeZone;

    // therapistAppointmentSearchRequestModel.crmFacilityTimeZone = this.selectedAppointmentsInformation[0].appointmentDetailInfo.crmFacilityTimeZone;
    // therapistAppointmentSearchRequestModel.crmPatientTimeZone = this.selectedAppointmentsInformation[0].appointmentDetailInfo.crmPatientTimeZone;
    return this.get<ResultOfT<TherapistAvailabilityForReassignResponseModel[]>>(url);
  }

  ReassignTherapistAppointments(therapistReassignSearchRequestModel: ReassignTherapistAvailabilitySearchRequestModel) {
    return this.post<ResultOfT<number>>('Therapist/ReassignTherapistAppointments', therapistReassignSearchRequestModel);
  }

  ReassignALLTherapistAppointments(therapistReassignSearchRequestModel: Array<ReassignTherapistAvailabilitySearchRequestModel>) {
    return this.post<ResultOfT<number>>('Therapist/ReassignALLTherapistAppointmentsFromDMECalender', therapistReassignSearchRequestModel);
  }
  SetAvailabilityFromReAssignment(therapistReassignSearchRequestModel: Array<ReassignTherapistAvailabilitySearchRequestModel>) {
    return this.post<ResultOfT<number>>('Therapist/SetAvailabilityFromReAssignment', therapistReassignSearchRequestModel);
  }

  AddInsurance(newInsurance: InsuranceInfoResponseModel) {
    return this.post<ResultOfT<InsuranceInfoResponseModel>>('Facility/AddInsurance', newInsurance);
  }

  AddFacilityWorkingHours(updatedFacility: FacilityResponseModelSend) {
    return this.post<ResultOfT<boolean>>('Facility/AddFacilityWorkingHours', updatedFacility);
  }

  UpdateFacilityWorkingHours(updatedFacility: any) {
    return this.post<ResultOfT<boolean>>('Facility/UpdateFacilityWorkingHours', updatedFacility);
  }

  GetFacilityWorkingHoursById(facilityId: number, timeZone: string) {
    return this.get<ResultOfT<FacilityResponseModel>>('Facility/GetFacilityWorkingHours/' + facilityId + '/' + timeZone);
  }

  ValidateZipcodeWithState(stateName: string, zipCode: number, crmTimeZone: string) {
    return this.get<ResultOfT<boolean[]>>('Facility/ValidateZipcodeWithTimezone/' + stateName + '/' + zipCode + '/' + crmTimeZone);
  }

  UpdateFacilitySelfScheduleAppointmentsStatus(facilityResponseModel: FacilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Facility/UpdateFacilitySelfScheduleAppointmentsStatus', facilityResponseModel);
  }

  UpdateSelectedFacilitySelfScheduleAppointmentsStatus(selectedFacilityResponseModel: SelectedFacilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Facility/UpdateSelectedFacilitySelfScheduleAppointmentsStatus', selectedFacilityResponseModel);
  }

  GetAllLinkedTherapistWithCurrentFacilityDetails(LinkedTherapistWithCurrentFacilitiesRequestModel: LinkedTherapistWithCurrentFacilitiesRequestModel) {
    return this.post<ResultOfT<LinkedTherapistWithCurrentFacilitiesResponseModel[]>>('Facility/GetAllLinkedTherapistWithCurrentFacilityDetails',LinkedTherapistWithCurrentFacilitiesRequestModel);
  }

  GetAllAvailableTherapistByBranchAndDateWeekViewDME(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Therapist/GetAllAvailableTherapistByBranchAndDateWeekViewDME?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;
    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      //let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;
    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    return this.get<ResultOfT<TherapistResponseModel[]>>(url);
  }
}
