export class TherapistOnlineMeetingResponseModel {
  crmTherapistOnlineMeetingInfoId: number;
  crmTherapistId: number;
  crmMeetingUrl: string;
  crmMeetingId: string;
  crmMeetingPasscode: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}

export class TherapistOnlineMeetingModel {
  appUserInfoId: string;
  modifiedByUserInfoId: number;
  therapistOnlineMeetingInfoModel: TherapistOnlineMeetingResponseModel;
}
