<!-- Title block -->
<div class="page-title">
  <div class="page-title__title-left">
    <span *ngIf="patientBookingTypeId=='R' || patientBookingTypeId=='SG'">Step 2/2</span>
    <span *ngIf="patientBookingTypeId=='F' || patientBookingTypeId=='T'">Step 3/3</span>
    <h1>Appointment Summary</h1>
  </div>
  <div class="page-title__next-btn">
    <button class="btn btn--secondary mr-0" (click)="onBack()">
      <svg class="ml-0" width="14" height="13" viewBox="0 0 14 13" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path d="M4.33334 1.33331L1 4.56249L4.33334 7.79167" stroke="white" stroke-width="1.6"
              stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 4.56247L6.33335 4.56247C10.0154 4.56247 13 7.45387 13 11.0208V11.6667"
              stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      Back
    </button>
  </div>
</div>

<!-- Facility block -->
<div class="appointment-summary-outer">
  <div class="appointment-summary-outer__left">
    <h3 class="appointment-summary-outer__title" *ngIf="(patientBookAppointmentRequest?.isTherapistRemoteType && patientBookingTypeId =='T') || (patientBookingTypeId =='R')">
      Appointment Type: <span>Remote Setup - {{patientBookAppointmentRequest?.selectedRemoteAppointmentType}}</span>
    </h3>

    <h3 class="appointment-summary-outer__title" *ngIf="patientBookingTypeId =='SG'">
      Appointment Type: <span>Remote Setup - Self Guided</span>
    </h3>

    <h3 class="appointment-summary-outer__title" *ngIf="(!patientBookAppointmentRequest?.isTherapistRemoteType && patientBookingTypeId =='T')  || (patientBookingTypeId =='F')">
      Appointment Type: <span>{{appointmentType}}</span>
    </h3>

    <div *ngIf="(patientBookAppointmentRequest?.isTherapistFacilityType && patientBookingTypeId =='T') || (patientBookingTypeId =='F')">
      <span class="appointment-summary-outer__miles">{{patientBookAppointmentRequest?.crmFacilityDistanceInMile}}</span>
      <h3 class="appointment-summary-outer__facility-name">{{patientBookAppointmentRequest?.crmFacilityName}}</h3>
      <address class="appointment-summary-outer__facility-address">
        <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.09271 5.06006C7.50368 5.06006 6.92787 5.23272 6.43811 5.55621C5.94834 5.8797 5.56662 6.33948 5.34121 6.87743C5.11579 7.41537 5.05681 8.0073 5.17173 8.57838C5.28664 9.14946 5.57029 9.67402 5.9868 10.0857C6.40331 10.4975 6.93398 10.7779 7.51169 10.8914C8.08941 11.005 8.68823 10.9467 9.23243 10.7239C9.77662 10.5011 10.2418 10.1238 10.569 9.63963C10.8963 9.15549 11.0709 8.5863 11.0709 8.00404C11.07 7.22352 10.756 6.47522 10.1976 5.92331C9.63931 5.3714 8.88231 5.06095 8.09271 5.06006ZM8.09271 9.47603C7.7982 9.47603 7.51029 9.3897 7.26541 9.22795C7.02053 9.06621 6.82967 8.83632 6.71696 8.56734C6.60425 8.29837 6.57476 8.0024 6.63222 7.71687C6.68968 7.43133 6.8315 7.16905 7.03976 6.96318C7.24801 6.75732 7.51335 6.61713 7.8022 6.56033C8.09106 6.50353 8.39047 6.53269 8.66257 6.6441C8.93467 6.75551 9.16724 6.94418 9.33086 7.18624C9.49449 7.42831 9.58182 7.71291 9.58182 8.00404C9.58137 8.3943 9.42433 8.76844 9.14517 9.04439C8.866 9.32035 8.48751 9.47558 8.09271 9.47603Z"
                fill="#868686" />
          <path d="M13.8156 2.34254C12.4006 0.94417 10.5117 0.11348 8.51359 0.0107968C6.51544 -0.0918867 4.54965 0.540715 2.9956 1.78651C1.44155 3.0323 0.409411 4.80297 0.0983971 6.75678C-0.212617 8.71058 0.219536 10.709 1.31145 12.3664L6.93781 20.9045C7.06301 21.0945 7.23421 21.2506 7.43587 21.3587C7.63754 21.4667 7.86329 21.5233 8.09268 21.5233C8.32206 21.5233 8.54782 21.4667 8.74948 21.3587C8.95115 21.2506 9.12234 21.0945 9.24755 20.9045L14.8741 12.3664C15.8873 10.8289 16.335 8.99395 16.1423 7.16898C15.9496 5.34402 15.1281 3.64 13.8156 2.34254ZM13.6264 11.5629L8.0927 19.9601L2.55895 11.5629C0.865092 8.99249 1.22839 5.55263 3.42277 3.38337C4.03603 2.77715 4.76408 2.29626 5.56535 1.96818C6.36661 1.64009 7.22541 1.47122 8.0927 1.47122C8.95999 1.47122 9.81879 1.64009 10.6201 1.96818C11.4213 2.29626 12.1494 2.77715 12.7626 3.38337C14.957 5.55263 15.3203 8.99249 13.6264 11.5629Z"
                fill="#868686" />
        </svg>
        <p class="mb-0" *ngIf="patientBookAppointmentRequest?.crmFacilityAddressLine1"><span>{{patientBookAppointmentRequest?.crmFacilityAddressLine1}}</span></p>
        <p class="mb-0" *ngIf="patientBookAppointmentRequest?.crmFacilityAddressLine2"><span>{{patientBookAppointmentRequest?.crmFacilityAddressLine2}}</span></p>
        <p class="mb-0" *ngIf="patientBookAppointmentRequest?.crmFacilityCity || patientBookAppointmentRequest?.crmFacilityState"><span>{{patientBookAppointmentRequest?.crmFacilityCity}}</span>, <span>{{patientBookAppointmentRequest?.crmFacilityState}}</span></p>
        <p class="mb-0" *ngIf="patientBookAppointmentRequest?.crmFacilityCountry || patientBookAppointmentRequest?.crmFacilityZipCode"><span>{{patientBookAppointmentRequest?.crmFacilityCountry}}</span> - <span>{{patientBookAppointmentRequest?.crmFacilityZipCode}}</span></p>
        <!-- {{patientBookAppointmentRequest?.crmFacilityAddress}} -->
      </address>
    </div>

    <div class="appointment-summary-outer__date-time-duration">
      <div class="appointment-summary-outer__date-time">
        <h4 class="date-time-title">Appointment Date & Time</h4>
        <p class="date-times">
          {{patientBookAppointmentRequest?.displaySelectedStartDate | date: appDateFormat.dateFormat }} - {{
patientBookAppointmentRequest?.displaySelectedStartDate | date:
					'h:mm a' | lowercase
          }}<span class="timezone">{{patientBookAppointmentRequest?.selectedTimeZone?.shortName}}</span>
        </p>
      </div>

      <div class="appointment-summary-outer__duration">
        <h4 class="date-time-title">Appointment Duration</h4>
        <p class="date-times">{{patientBookAppointmentRequest?.duration}} <i class="info-new" tooltip="{{patientBookAppointmentRequest?.description}}"></i></p>
      </div>
      <!-- select therapist -->
      <div class="appointment-summary-outer__therapist" *ngIf="patientBookingTypeId =='T'">
        <h4 class="date-time-title">Therapist</h4>
        <p class="date-times">{{patientBookAppointmentRequest?.therapistName}}</p>
      </div>

    </div>
  </div>
  <div class="appointment-summary-outer__right">
    <h3 class="appointment-summary-outer__title">Confirm Your Details</h3>
    <div class="form">
      <div class="form__group">
        <label class="form__label">Email</label>
        <input type="text" class="text" class="form__control" [(ngModel)]="email" pInputText maxlength="100">
        <span class="error-msg" *ngIf="emailRequired">{{commonValidationMessages.email.required}}</span>
        <span class="error-msg" *ngIf="emailRegex">{{commonValidationMessages.email.email}}</span>
      </div>
      <div class="full-width"></div>
      <div class="form__group">
        <label class="form__label">Home Number</label>
        <p-inputMask mask="999-999-9999" placeholder="___-___-____" class="form__phone" pInputText
                     maxlength="15" [(ngModel)]="patientPhone"></p-inputMask>
      </div>
      <div class="form__group mr-0">
        <label class="form__label">Mobile Number</label>
        <p-inputMask mask="999-999-9999" placeholder="___-___-____" class="form__phone" pInputText
                     maxlength="15" [(ngModel)]="patientMobileNumber"></p-inputMask>
      </div>

      <div class="form__checkbox">
        <p-checkbox name="groupname" [(ngModel)]="termCondtionSelected" binary="true" (click)="onSelectTerms()"></p-checkbox>
        I accept the <a href="javascript:void(0)" (click)="AgreeTermsConditionsClick()">terms &amp; conditions</a>
      </div>
      <div class="opt-out-reminders">
        <div class="form__checkbox">
          <label class="appt-details__label">Choose your preferred communication method for receiving Appointment Reminders and Messages:</label>
        </div>

        <div class="form__checkbox choose-your">
          <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="SMS"
                         value="SMS" [(ngModel)]="schIsReminderOptedType">
          </p-radioButton>
          <label for="SMS">SMS</label>
        </div>

        <div class="form__checkbox choose-your">
          <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="Email"
                         value="Email" [(ngModel)]="schIsReminderOptedType">
          </p-radioButton>
          <label for="Email">Email</label>

        </div>
        <div class="form__checkbox choose-your">
          <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="Both"
                         value="Both" [(ngModel)]="schIsReminderOptedType">
          </p-radioButton>
          <label for="Both">Both (SMS & Email)</label>

        </div>
      </div>
      <div class="form__checkbox">
        <p-checkbox name="groupname" [(ngModel)]="schReminderOpted" binary="true"></p-checkbox>
        Click here if you want to opt out of reminders
      </div>

      <div class="form__btns">
        <button class="btn btn--primary" [ngClass]="!showConfirm?'disabled':''" (click)="onBookAppointment()">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7362 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 9.68678 1.25866 8.38642 1.7612 7.17317C2.26375 5.95991 3.00035 4.85752 3.92893 3.92893C4.85752 3.00035 5.95991 2.26375 7.17317 1.7612C8.38642 1.25866 9.68678 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11C21 13.6522 19.9464 16.1957 18.0711 18.0711C16.1957 19.9464 13.6522 21 11 21Z"
                  fill="white" />
            <path d="M6 11L10.2857 14.5714L16 7.42857M11 21C9.68678 21 8.38642 20.7413 7.17317 20.2388C5.95991 19.7362 4.85752 18.9997 3.92893 18.0711C3.00035 17.1425 2.26375 16.0401 1.7612 14.8268C1.25866 13.6136 1 12.3132 1 11C1 9.68678 1.25866 8.38642 1.7612 7.17317C2.26375 5.95991 3.00035 4.85752 3.92893 3.92893C4.85752 3.00035 5.95991 2.26375 7.17317 1.7612C8.38642 1.25866 9.68678 1 11 1C13.6522 1 16.1957 2.05357 18.0711 3.92893C19.9464 5.8043 21 8.34784 21 11C21 13.6522 19.9464 16.1957 18.0711 18.0711C16.1957 19.9464 13.6522 21 11 21Z"
                  stroke="#6F64A7" stroke-width="1.3" />
          </svg>
          Book Appointment
        </button>

        <!-- [ngClass]="showConfirm?'btn btn--primary btn-next':'btn btn--primary btn-next disbaled'"
        (click)="onBookAppointment()" -->
      </div>
    </div>
  </div>
</div>


<!-- Terms & Conditions Popup -->
<p-dialog [(visible)]="AgreeTermsConditionsPopup" [modal]="true" [responsive]="true" class="patient-popup">
  <div class="patient-popup__outer terms-condition">
    <h2 class="patient-popup__title">Terms &amp; Conditions</h2>
    <div class="patient-popup__content " [innerHtml]='companyTermsAndCondition'>
    </div>
  </div>
</p-dialog>

<!-- Loader -->
<div class="full-screen-loader" *ngIf="loadingCustom">
  <div class="full-screen-loader__outer">
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 27C0 19.8392 2.84463 12.9716 7.90812 7.90812C12.9716 2.84463 19.8392 0 27 0C34.1608 0 41.0284 2.84463 46.0919 7.90812C51.1554 12.9716 54 19.8392 54 27C54 34.1608 51.1554 41.0284 46.0919 46.0919C41.0284 51.1554 34.1608 54 27 54C19.8392 54 12.9716 51.1554 7.90812 46.0919C2.84463 41.0284 0 34.1608 0 27H0ZM25.4592 38.556L41.004 19.1232L38.196 16.8768L24.9408 33.4404L15.552 25.6176L13.248 28.3824L25.4592 38.5596V38.556Z" />
    </svg>
    <h3>Progress..!</h3>
    <p>
      Your Appointment booking on <span class="date-times">
        {{patientBookAppointmentRequest.displaySelectedStartDate | date: appDateFormat.dateFormat }} - {{
patientBookAppointmentRequest.displaySelectedStartDate | date:
            'h:mm a' | lowercase
        }}
      </span> | <span class="timezone">{{patientBookAppointmentRequest.selectedTimeZone?.shortName}}</span> is in progress...
    </p>
  </div>
</div>
