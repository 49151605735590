
<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
      <a class="edit-btn" (click)="onEdit()">
        <i class="edit-icon-new"></i>
      </a>
    </h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <div class="form-outer">
    <div class="details-pages">
      <div class="details-pages__content">
        <span class="details-pages__content__block">
          <strong>Name</strong>
          <span class="value">
            {{doctorDetail?.crmDoctorLastName}}, {{doctorDetail?.crmDoctorFirstName}}

          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="facilityNames?.length>0">
          <strong>Location</strong>
          <!-- <span class="value"  [ngClass]="BranchName ? 'show' : 'hide'">
            <label *ngFor="let facility of doctorDetail?.facility">{{facility?.crmFacilityName }}, &nbsp;</label>
          </span> -->
          <span class="value value--table" [ngClass]="BranchName ? 'show' : 'hide'">
            
            <!-- <table class="table" >
              <tr *ngFor="let facility of doctorDetail?.facility; let i=index">
                <th>{{i+1 }}</th>
                <td>{{facility?.crmFacilityName }}</td>
              </tr>
            </table> -->

            <ul [ngClass]="BranchName? 'show' : 'hide'">
              <li *ngFor="let facility of facilityNames; let i=index"> {{facility}}</li>
            </ul>
          </span>


          <a href="javascript:void(0)" class="more-btn"  *ngIf="facilityNames?.length > 1"(click)="BranchNameclickEvent()">{{toggleLabel}}</a>
        </span>
        <span class="details-pages__content__block">
          <strong>Email</strong>
          <span class="value">
            <a href="{{doctorDetail?.crmDoctorContactEmailAddress}}"></a>
            {{doctorDetail?.crmDoctorContactEmailAddress || '-'}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Phone Number</strong>
          <!-- <span class="value">{{doctorDetail?.crmDoctorContactNo || '-'}}</span> -->
          <span class="value">
            {{doctorDetail?.crmDoctorContactNo | slice:0:3}}-{{doctorDetail?.crmDoctorContactNo | slice:3:6}}<span *ngIf="doctorDetail?.crmDoctorContactNo">-</span>{{doctorDetail?.crmDoctorContactNo | slice:6:10}}
          </span>
        </span>
        <!-- <span class="details-pages__content__block">
          <strong>Only Patient's at Doctor's Location</strong>
          <span class="value">{{doctorDetail?.crmIsPatientAllowedOnlyOnMyFacility ? 'Yes' : 'No'}}</span>
        </span> -->
        <!-- <span class="details-pages__content__block">
          <strong>Status</strong>
          <span class="value" [ngClass]="doctorDetail.isActive ? 'user-active' : 'user-inactive'">{{doctorDetail.isActive? 'Active' : 'Inactive'}}</span>
        </span> -->
      </div>
      </div>
    </div>

  <!-- <div class="page-scroll details-pages">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="details-pages__content">
        <span class="details-pages__content__block">
          <strong>Name</strong>
          <span class="value">
            {{doctorDetail?.crmDoctorLastName}}, {{doctorDetail?.crmDoctorFirstName}}

          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="doctorDetail?.facility?.length>0">
          <strong>Facility</strong>
         
          <span class="value value--table" [ngClass]="BranchName ? 'show' : 'hide'">
            <table class="table" >
              <tr *ngFor="let facility of doctorDetail?.facility; let i=index">
               
                <td>{{facility?.crmFacilityName }}</td>
              </tr>
            </table>
          </span>


          <a href="javascript:void(0)" class="more-btn"  *ngIf="doctorDetail?.facility.length > 1"(click)="BranchNameclickEvent()">{{toggleLabel}}</a>
        </span>
        <span class="details-pages__content__block">
          <strong>Email</strong>
          <span class="value">
            <a href="{{doctorDetail?.crmDoctorContactEmailAddress}}"></a>
            {{doctorDetail?.crmDoctorContactEmailAddress || '-'}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Phone Number</strong>
          
          <span class="value">
            {{doctorDetail?.crmDoctorContactNo | slice:0:3}}-{{doctorDetail?.crmDoctorContactNo | slice:3:6}}<span *ngIf="doctorDetail?.crmDoctorContactNo">-</span>{{doctorDetail?.crmDoctorContactNo | slice:6:10}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Only Patient's at Doctor's Location</strong>
          <span class="value">{{doctorDetail?.crmIsPatientAllowedOnlyOnMyFacility ? 'Yes' : 'No'}}</span>
        </span>
        <span class="details-pages__content__block">
          <strong>Status</strong>
          
          <span class="value" [ngClass]="doctorDetail.isActive ? 'user-active' : 'user-inactive'">{{doctorDetail.isActive? 'Active' : 'Inactive'}}</span>
        </span>
      </div>
    </p-scrollPanel>
  </div> -->
</div>
