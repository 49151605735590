import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebStorageService } from '../services/web-storage.service';

@Injectable({
  providedIn: 'root'
})

export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private webStorageService: WebStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {   

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      request = request.clone(
        {
          headers: request.headers.append('Authorization', currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token)),
        });
    }
    const currentClientConfig = this.webStorageService.getClientConfig();
    if (currentClientConfig && currentClientConfig.companyGuid) {
      request = request.clone(
        {
          headers: request.headers.append('clientId', currentClientConfig.companyGuid.toString()),
        });
    }

    return next.handle(request);
  }
}
