export class RemoteAppointmentTypeModel {
  crmRemoteAppointmentTypeId: number;
  crmRemoteAppointmentType: string;
  crmRemoteAppointmentTitle: string;
  isActive:boolean
  modifiedByUserInfoId: number;
}

export class OnlineAppointmentTypeModel {
  crmOnlineAppointmentTypeId: number;
  crmOnlineAppointmentType: string;
  crmOnlineAppointmentTitle: string;
  isActive: boolean
  modifiedByUserInfoId: number;
}
