import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { SalesOrderService } from 'src/app/patient/services/salesorder.service';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GetPatientDetailsRequestModel } from 'src/app/shared/models/request/get-patient-details-request';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
@Component({
  selector: 'app-initiate-shipping',
  templateUrl: './initiate-shipping.component.html',
  styleUrls: ['./initiate-shipping.component.sass']
})
export class InitiateShippingComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  domainUrl: string = '';
  imageUrl: SafeResourceUrl;
  strPatientIdUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  patinetrequest: GetPatientDetailsRequestModel;
  salesOrderId: string;
  patientId: string;
  strURL: string;
  salesOrderData: SalesOrderResponseModel;
  patientName: string;
  description: string;
  duration: string;
  dueAmount: number;
  patientURLgenDate: Date;
  salesorderids: SalesOrderLocalModel;
  appointmentType: string = 'F';
  DMENumber: string;
  ShowDueAmount: boolean;
  LinkExpiryDays: number;
  DMEeMail: string;
  validSalesOrder: boolean=true;
  teleHealthPopup: boolean;
  telehealthSuccess: boolean;
  telehealthBooked: boolean;
  initiateShipping: boolean;
  disclaimerPopup: boolean = false;
  emailToProviderSuccess: boolean;
  disclaimerClick() {
    this.disclaimerPopup = true;
  }
  constructor(
    private webStorageService: WebStorageService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer,
    private appointmentService: AppointmentService,
    private salesorderService: SalesOrderService,
    private router: Router,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }


  async ngOnInit() {
    
   
    this.loading = true;
    this.domainUrl = location.origin.replace(Constants.httpReplace, "").replace(Constants.httpsReplace, "");
    this.strURL = location.href;

   

    this.patinetrequest = new GetPatientDetailsRequestModel();
    this.patinetrequest.strLink = this.strURL.substring(this.strURL.lastIndexOf('/') + 1) + Constants.equalTo;
    this.strPatientIdUrl = this.strURL.substring(this.strURL.lastIndexOf('/') + 1);
    await this.getClinetDetails();
    await this.getDMENumber();
    await this.getDueAmontVisible();
    await this.getInviteLinkExpiry();
    await this.getSalesOrderId();
    await this.getDMEeMail();



    if (this.patientURLgenDate > new Date(new Date().getDate() + this.LinkExpiryDays)) {
      //TODO: return to expiry page
    }
    await this.getSalesOrderData();
    if (this.salesOrderData.crmSalesorderStatus.toLowerCase() !== Constants.readyForSchedule) {
      this.validSalesOrder = false;
    } else {
      this.validSalesOrder = true;
    }

  }
  private async getClinetDetails() {
    await this.authenticationService.getClinetDetails(this.domainUrl)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ConfigResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.imageData = Constants.imagePrefix + result.responseModel.companyLogo;
              this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
              this.imagealt = result.responseModel.companyName;
              this.imageTitle = result.responseModel.companyName;
              this.webStorageService.storeClientConfig(result.responseModel);
            }
          }
        });
      });
  }

  private async getDMENumber() {
    await this.settingsService.getSettings(Constants.SettingKeys.DMENumber)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.DMENumber = result.responseModel.schSettingValue;
          }
        });
      });
  }

  private async getDueAmontVisible() {
    await this.settingsService.getSettings(Constants.SettingKeys.DueAmount)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              if (result.responseModel.schSettingValue.toLowerCase() === "true")
                this.ShowDueAmount = true;
              else
                this.ShowDueAmount = false;
            }
          }
        });
      });
  }

  private async getDMEeMail() {
    await this.settingsService.getSettings(Constants.SettingKeys.DMEEmail)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.DMEeMail = result.responseModel.schSettingValue;
          }
        });
      });
  }

  private async getInviteLinkExpiry() {
    await this.settingsService.getSettings(Constants.SettingKeys.PatientInvitationLinkExpiry)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.LinkExpiryDays = parseInt(result.responseModel.schSettingValue, 10);
          }
        });
      });
  }

  private async getSalesOrderId() {
    await this.salesorderService.getSalesOrderId(this.patinetrequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<GetPatientDetailsRequestModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.salesOrderId = result.responseModel.strLink.split(Constants.seprator)[0];
              this.patientId = result.responseModel.strLink.split(Constants.seprator)[1];
              //this.patientURLgenDate = new Date(result.responseModel.strLink.split(Constants.seprator)[2]);
            }
          }
        });
      });
  }
  private async getSalesOrderData() {
    await this.salesorderService.getSalesOrderDetails(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderResponseModel>(result, () => {
       
          if (result.isSuccess) {
            if (result.responseModel) {
              this.salesOrderData = result.responseModel;
              this.patientName = this.salesOrderData.patientInfo.crmPatientFirstName + " " + this.salesOrderData.patientInfo.crmPatientLastName;
              this.description = this.salesOrderData.crmClassificationDescription;
              this.dueAmount = this.salesOrderData.amountDue;
              this.duration = this.salesOrderData.crmClassificationDuration == 1 || this.salesOrderData.crmClassificationDuration == 60 ? "1 Hour" : this.salesOrderData.crmClassificationDuration.toString() + " Minutes";
            }
          }
        });
      });
  }
  EmailToProvider() {

    this.loading = true;
    this.appointmentService.SendEmailToProvider(this.salesOrderId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.emailToProviderSuccess = true;
          //this.showSuccessMessage('Your request has been sent to respective provider.');
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});
  }
}
