import { TherapistAvailabilityModel } from 'src/app/shared/models/response/therapist/therapist-availability-model'
export class TherapistAvailabilityForReassignResponseModel {
  schAppointmentId:number;
  appUserInfoId: number;
  appUserFname: string;
  appUserLname: string;
  appUserFullname: string;
  appUserPhoneNumber: string;
  crmBranchId: number;
  crmFacilityId: number;
  crmFacilityName: string;
  crmStartTime: Date;
  crmEndTime: Date;
  isAvailable: boolean;
  schAppoinmtmentCount: number;
  therapistAvailabilityModels: TherapistAvailabilityModel[];
}
