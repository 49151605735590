import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
@Component({
  selector: 'app-book-appoinment-dialog',
  templateUrl: './book-appoinment-dialog.component.html',
  styleUrls: ['./book-appoinment-dialog.component.sass']
})
export class BookAppoinmentDialogComponent extends BaseComponent implements OnInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  searchRequestModel: SearchRequestModel;
  selectedTab: string;
  formFields = {
    searchtext: 'searchtext',
  };

  validationMessages = {
    searchtext: {
      required: 'Patient name / Patient ID is required',
      validAlphaNumeric:"Enter valid patient name / Patient ID."
    },
  };
  constructor(
    private router: Router,
    messageService: MessageService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig) {
    super(messageService);
  }

  ngOnInit() {
    this.buildForm();
    this.searchRequestModel = new SearchRequestModel()
  }
  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.searchtext, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.alphaNumeric]}));
    // tslint:disable-next-line: max-line-length
  


    this.form = formGroup;
  }
  onSearch() {
   
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const form = this.form;
    let searchText = form.get(this.formFields.searchtext).value;

    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = 20;
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
    this.searchRequestModel.dictionaryFilter = {}
    this.searchRequestModel.dictionaryFilter['SearchText'] = searchText;
   // this.router.navigate(['admin/appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: "0", isBookAppintment: true  } });
    this.redirectTo();
    this.dynamicDialogRef.close(true);
  }

  onNo() {
    this.dynamicDialogRef.close(false);
  }

  redirectTo() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admin/appointments'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: "0", isBookAppintment: true } })
      );
  }
}
