<div class="content-area">

  <div class="title-block">
    <div class="title-block__left">
      <h1>Brightree API Process Status</h1>
    </div>
    <div class="title-block__right">

      <div class="right-side-block">
        <a class="refresh-block" (click)="RefreshDetails()" pTooltip="Refresh">
          <!--<svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z" /></svg>-->

      <svg fill="#000000" style="width: 16px; height: 16px;" height="16px" width="16px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.698 489.698" xml:space="preserve">
          <path d="M468.999,227.774c-11.4,0-20.8,8.3-20.8,19.8c-1,74.9-44.2,142.6-110.3,178.9c-99.6,54.7-216,5.6-260.6-61l62.9,13.1
			c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-123.7-26c-7.2-1.7-26.1,3.5-23.9,22.9l15.6,124.8
			c1,10.4,9.4,17.7,19.8,17.7c15.5,0,21.8-11.4,20.8-22.9l-7.3-60.9c101.1,121.3,229.4,104.4,306.8,69.3
			c80.1-42.7,131.1-124.8,132.1-215.4C488.799,237.174,480.399,227.774,468.999,227.774z"></path>
          <path d="M20.599,261.874c11.4,0,20.8-8.3,20.8-19.8c1-74.9,44.2-142.6,110.3-178.9c99.6-54.7,216-5.6,260.6,61l-62.9-13.1
			c-10.4-2.1-21.8,4.2-23.9,15.6c-2.1,10.4,4.2,21.8,15.6,23.9l123.8,26c7.2,1.7,26.1-3.5,23.9-22.9l-15.6-124.8
			c-1-10.4-9.4-17.7-19.8-17.7c-15.5,0-21.8,11.4-20.8,22.9l7.2,60.9c-101.1-121.2-229.4-104.4-306.8-69.2
			c-80.1,42.6-131.1,124.8-132.2,215.3C0.799,252.574,9.199,261.874,20.599,261.874z"></path>
      </svg>
          Refresh
        </a>
      </div>

      <a href="javascript:void(0);" class="filter-show-hide" (click)="exportData.toggle($event)"
         pTooltip="Actions" tooltipPosition="top">
        <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
          <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
        </svg>
      </a>
      <p-overlayPanel #exportData class="search-filter report-exportData">
        <div class="quick-link-menu">
          <ul>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="Export()">Export</a></li>
            <li *ngIf="false"><a class="invite-schedule" href="javascript:void(0)" (click)="RetryBulkProcess()">Retry Process</a></li>
            <li><a class="invite-schedule" href="javascript:void(0)" (click)="InactiveRecords()">Delete</a></li>

          </ul>
        </div>
      </p-overlayPanel>

      <a href="javascript:void(0);" pTooltip="Filters" tooltipPosition="right"
         class="filter-block filter-show-hide" (click)="overlyFilter.toggle($event)">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
      <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/reports']">
        <i class="back-new"></i>
        Back
      </a>
    </div>
  </div>

  <div class="appointments-scroll reports-detail remove-filter">

    <div class="table-outer-main">
      <p-table #tableAppointment class="table-outer"
               [responsive]="true"
               [paginator]="totalRecords>0?true:false"
               sortMode="single"
               sortField="TransactionDate"
               [first]="searchRequest.firstRecordIndex"
               [sortOrder]="1"
               [value]="failedToSyncSalesOrderReport"
               [(selection)]="selectedFailedToSyncSalesOrder"
               selectionMode=""
               [rows]="getTotalRows()"
               [totalRecords]="totalRecords"
               [lazy]="true"
               [customSort]="true"
               (onLazyLoad)="onLazyLoad($event)"
               responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr *ngIf="totalRecords > 0">
            <th class="table-outer__th table-checkbox-column" width="30">
              <span class="table-checkbox">
                <p-tableHeaderCheckbox #headerSCheckBox></p-tableHeaderCheckbox>
              </span>
            </th>
            <th class="table-outer__th soid" width="100">
              S.O. ID
            </th>

            <th class="table-outer__th" pSortableColumn="ProcessType">
              Process Type<p-sortIcon field="ProcessType"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="TransactionDate">
              Transaction Date<p-sortIcon field="TransactionDate"></p-sortIcon>
            </th>
            <!--<th class="table-outer__th" pSortableColumn="ErrorType">
                Error<p-sortIcon field="ErrorType"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="ErrorDescription">
                Error Description <p-sortIcon field="ErrorDescription"></p-sortIcon>
            </th>-->
            <th class="table-outer__th" pSortableColumn="RetryAttempt">
              Retry Count <p-sortIcon field="RetryAttempt"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="ProcessStatus">
              Status <p-sortIcon field="ProcessStatus"></p-sortIcon>
            </th>
            <th class="table-outer__th">
              Action
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-Report>
          <tr>
            <td pFrozenColumn class="table-outer__td table-checkbox-column" style="vertical-align: middle;">
              <p-tableCheckbox [value]="Report"></p-tableCheckbox>
            </td>
            <td class="table-outer__td soid">
              <span class="table-checkbox">
                {{Report.crmSalesOrderAppId}}
              </span>
            </td>

            <td class="table-outer__td Patientid">
              <span class="patient">
                <p>{{Report.processType}}</p>
              </span>
            </td>
            <td class="table-outer__td">{{Report.transactionDate | date:appDateFormat.dateFormat}} {{Report.transactionDate | date:'shortTime' | lowercase}}</td>
            <!--<td class="table-outer__td">{{Report.errorType}}</td>
            <td class="table-outer__td">
              {{Report.errorDescription}}
            </td>-->
            <td class="table-outer__td">

              {{Report.retryAttempt}}
            </td>
            <td class="table-outer__td">
              {{Report.processStatus}}
            </td>
            <td class="table-outer__td">
              <span *ngIf="false" class="raise-ticket-outer">
                <a class="raise-ticket-outer__link" *ngIf="Report.isActiveAppointment" href="javascript:void(0);" (click)="RetryProcess(Report)" pTooltip="Retry Process">
                  <svg width="20px" height="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#6F64A7" d="M784.512 230.272v-50.56a32 32 0 1 1 64 0v149.056a32 32 0 0 1-32 32H667.52a32 32 0 1 1 0-64h92.992A320 320 0 1 0 524.8 833.152a320 320 0 0 0 320-320h64a384 384 0 0 1-384 384 384 384 0 0 1-384-384 384 384 0 0 1 643.712-282.88z"></path>
                  </svg>
                </a>
                <label *ngIf="Report.processStatus=='Failed'" class="raise-ticket-outer__text">er Raise a Ticket</label>
              </span>

              <a href="javascript:void(0)" class="delete-row" (click)="InactiveRecord(Report)" pTooltip="Delete">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 30">
                  <g id="delete-icon-new" transform="translate(-3 -1)">
                    <path id="Path_1" data-name="Path 1"
                          d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                          fill="#6f64a7" />
                    <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                          fill="#6f64a7" />
                    <path id="Path_3" data-name="Path 3"
                          d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                          fill="#6f64a7" />
                    <path id="Path_4" data-name="Path 4"
                          d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                    <path id="Path_5" data-name="Path 5"
                          d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                    <path id="Path_6" data-name="Path 6"
                          d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                  </g>
                </svg>

              </a>

              <!-- <button class="btn btn--primary" type="button" >ReImport</button> -->
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="!loading && totalRecords <= 0">
            <tr>
              <td class="table-outer__td no-records-found" [attr.colspan]="8">
                No records found
              </td>
            </tr>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>
    </div>
  </div>

  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                       animationDuration=".8s">
    </p-progressSpinner>
  </div>
</div>

<p-overlayPanel class="overly-filter" styleClass="report-overly" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer overly-filter__outer--report-filter">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">

      <div *ngIf="false" class="form__group">
        <label class="form__label">Search</label>
        <div class="search-box">
          <input type="text" class="form__control" pInputText size="50" placeholder="S.O. ID " #searchBox
                 style="width:auto">
          <button *ngIf="searchBox.value !=''" class="clear-btn" type="button" (click)="searchBox.value =''">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button">
            <i class="search-new"></i>
          </button>
        </div>
      </div>


      <div class="form__group">
        <label class="form__label">Search Date</label>
        <input matInput class="form__control " ngxDaterangepickerMd placeholder="Transaction Date"
               [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [customRangeDirection]="true"
               [(ngModel)]="SSelectedDateRange" [ranges]="sRanges" [showClearButton]="true" [showCancel]="true"
               [linkedCalendars]="true" [isTooltipDate]="isTooltipDate" [isInvalidDate]="isInvalidDate"
               [locale]="callocales" [keepCalendarOpeningWithRange]="true" [showRangeLabelOnInput]="true" />
      </div>
      <div class="form__group">
        <label class="form__label">Status</label>
        <p-dropdown class="select-box filter-status" [(ngModel)]="selectedStatus" [options]="statusOption"
                    statusDropdown placeholder="Select" optionLabel="name" [showClear]="true">
        </p-dropdown>
      </div>

      <div class="form__group">
        <label class="form__label">Process Type</label>
        <p-dropdown class="select-box filter-status" [(ngModel)]="selectedProcessType" [options]="ProcessTypeOption"
                    statusDropdown placeholder="Select" optionLabel="code" [showClear]="true">
        </p-dropdown>
      </div>
    </div>


    <div class="table-filter__sort-by">
      <p-menu #updatemenu [popup]="true" class="update-status-menu"></p-menu>
    </div>

    <div class="form__group btn-block">
      <button class="btn btn--primary" style="margin-right: 1.5rem;" (click)="OnSearch()">Search</button>
      <button class="btn btn--secondary" (click)="onClear()">Clear</button>
    </div>
  </div>
</p-overlayPanel>
