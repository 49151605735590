import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'myfilter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filter: Object): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        const filteredValues = items.filter(item => item.startTime.indexOf(filter) !== -1);
        return filteredValues.length > 0 ? filteredValues : [];
    }
}