export class SettingsResponseModel {
  schSettingId: number;
  schSettingDisplayValue: string;
  schSettingKey: string;
  schSettingValue: string;
  schSettingDescription: string;
  schSettingIsEnable: Boolean;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  dtNgFormat: string;
}

export class AdminCustomSettingsResponseModel {
  numOfMonths: number;
  allowUpdateIntoBTOnConfirmationScreen: boolean;
  allowDMEtoUpdateDeliveryAddress: boolean;
  checkTherapistStateLicense: boolean;
  isBillingAddress: boolean;
  isDefaultWIPNeededDateAppointmentdate: boolean;
  updateWipStateOnRescheduled: boolean;
  wIPStateAssignmentType: string;
  defaultModeofAppointment: string;
  allowUpdatePODStatusToBrightTree: boolean;
  allowAppendOrderNoteToBrightTree: boolean;
  defaultBufferTime: string;
}


