<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
    </h1>

  </div>
  <app-change-password></app-change-password>
</div>
<!-- End Content -->


