<div class="sms-email-log-sidebar">
  <div class="sms-email-log-sidebar__header">
    <h2>SMS Logs ({{smsLogCount}})</h2>
    <span *ngIf="isAppointment" class="switchBtn sms-email">
      <p-inputSwitch [(ngModel)]="schIsReminderOptedSms" [ngModelOptions]="{standalone: true}"
        (onChange)="handleSmsOptInOut($event)"></p-inputSwitch>
      <label>{{schIsReminderOptedSms ? 'Subscribed' : 'Unsubscribed'
        }}</label>
    </span>
  </div>
  <div class="sms-email-log-sidebar__content">
    <div class="sms-popup-content">
      <ul class="sms-popup-content__list">

        <li [ngClass]="$index == expandedIndex ? 'active' : ''"
          *ngFor="let appointmentSMSHistory of appointmentSMSHistoryResponseModel; let $index = index">
          <!--Unsubscribed content-->
          <div class="subscribed-unsubscribed" *ngIf="appointmentSMSHistory.isSMSSubscribe">
            <span>{{appointmentSMSHistory.appNotificationSubject}}
              {{appointmentSMSHistory.appNotificationSmssentTime | date:appDateFormat.dateFormat}},
              {{appointmentSMSHistory.appNotificationSmssentTime | date:'shortTime' | lowercase}}
              {{appointmentSMSHistory.appNotificationSmsbody}}</span>
          </div>

          <!--Subscribed content-->
          <div class="sms-popup-content__item" *ngIf="!appointmentSMSHistory.isSMSSubscribe" 
            [ngClass]="(appointmentSMSHistory.appNotificationSmsstatusReason =='OptOut') && appointmentSMSHistory.appNotificationRoleType == 'Patient' ? 'not-sent' : ''">
            <div class="item-header">
              <span 
                class="item-header__role-name">{{appointmentSMSHistory.appNotificationRoleType=="Patient"?'P':'T'}}</span>
              <div class="item-header__left">
                <div class="item-header__title" (click)="Collapse($index)">
                  <span class="item-header__collapsed-arrow">
                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.85714 0.5L6.5 4.875L11.1429 0.500001L13 1.375L6.5 7.5L-4.1821e-07 1.375L1.85714 0.5Z"
                        fill="#4D4D4D" />
                    </svg>
                  </span>
                  <h3>
                    {{formatString(appointmentSMSHistory.appNotificationSubject)}}
                  </h3>
                  <span [ngClass]="(appointmentSMSHistory.appNotificationSmsstatusReason =='OptOut') && appointmentSMSHistory.appNotificationRoleType == 'Patient' ? 'not-sent':'not-sent-none' ">Not Sent</span>

                  <span class="item-header__status-arrow" 
                    [ngClass]="appointmentSMSHistory.appNotificationSmsstatus=='Sent' || appointmentSMSHistory.appNotificationSmsstatus=='Delivered'?'item-header__status-arrow sent':'item-header__status-arrow'">
                    <svg class="send-svg" width="19" height="11" viewBox="0 0 19 11" fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      *ngIf="appointmentSMSHistory.appNotificationSmsstatus=='Sent'|| appointmentSMSHistory.appNotificationSmsstatus=='Delivered'">
                      <path d="M1 5.8L5.33333 10L14 1" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M5 5.8L9.33333 10L18 1" stroke="#6A6A6A" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>

                    <svg class="pending-svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      *ngIf="(appointmentSMSHistory.appNotificationSmsstatusReason !='OptOut')
                      && (appointmentSMSHistory.appNotificationSmsstatus=='Pending'|| appointmentSMSHistory.appNotificationSmsstatus=='Undelivered' || appointmentSMSHistory.appNotificationSmsstatus=='Failed' || appointmentSMSHistory.appNotificationSmsstatus=='Accepted' || appointmentSMSHistory.appNotificationSmsstatus=='Queued')">
                      <path
                        d="M15.0114 1.45058C14.9273 1.08056 14.4564 0.945999 14.1873 1.21511L13.3463 2.05609C10.201 -0.769591 5.37384 -0.685494 2.34632 2.3252C-0.782107 5.45363 -0.782107 10.5331 2.34632 13.6616C2.44724 13.7625 2.54816 13.8466 2.63225 13.9475C2.80045 14.0989 3.00228 14.1661 3.20412 14.1661C3.43959 14.1661 3.67506 14.0652 3.84326 13.8802C4.16283 13.527 4.12919 12.9888 3.77598 12.6692L3.54051 12.4505C1.10168 9.9949 1.10168 5.99185 3.55733 3.5362C5.91206 1.18147 9.66281 1.11419 12.1353 3.26709L11.2102 4.19217C10.9411 4.46128 11.0757 4.93222 11.4457 5.01632L15.3814 5.97503C15.7347 6.05913 16.071 5.73956 15.9869 5.36953L15.0114 1.45058Z"
                        fill="#6A6A6A" />
                      <path
                        d="M10.8234 8.76704L8.85548 7.52239V4.37714C8.85548 3.9062 8.46863 3.51935 7.99769 3.51935C7.52674 3.51935 7.13989 3.9062 7.13989 4.37714V7.97652C7.13989 8.26245 7.29127 8.54838 7.54356 8.69976L9.89829 10.1967C10.0328 10.2808 10.201 10.3313 10.3524 10.3313C10.6384 10.3313 10.9075 10.1967 11.0757 9.92758C11.3448 9.54073 11.227 9.01933 10.8234 8.76704Z"
                        fill="#6A6A6A" />
                      <path
                        d="M6.36631 14.0652L6.04673 13.9643C5.59261 13.8129 5.10484 14.0652 4.97029 14.5025C4.81891 14.9567 5.0712 15.4444 5.50851 15.579C5.64307 15.6294 5.77762 15.6631 5.91218 15.6967C5.97946 15.7135 6.06355 15.7304 6.13083 15.7304C6.50086 15.7304 6.85407 15.4781 6.95499 15.0912C7.08954 14.6539 6.82043 14.183 6.36631 14.0652Z"
                        fill="#6A6A6A" />
                      <path
                        d="M9.27584 14.1493L8.93945 14.1998C8.4685 14.2671 8.14893 14.7044 8.21621 15.1753C8.28349 15.5958 8.65352 15.9154 9.05719 15.9154C9.09083 15.9154 9.14129 15.9154 9.19174 15.8986C9.3263 15.8818 9.47768 15.8481 9.61223 15.8313C10.0832 15.7304 10.3691 15.2931 10.285 14.8221C10.1841 14.368 9.72997 14.0652 9.27584 14.1493Z"
                        fill="#6A6A6A" />
                      <path
                        d="M11.8999 12.9383L11.6307 13.1402C11.2439 13.4093 11.1598 13.9475 11.4289 14.3343C11.5971 14.5698 11.8662 14.7044 12.1353 14.7044C12.3035 14.7044 12.4717 14.6539 12.6231 14.553C12.7408 14.4689 12.8586 14.3848 12.9595 14.3007C13.3295 14.0148 13.3968 13.4597 13.094 13.0897C12.8249 12.7029 12.2699 12.6524 11.8999 12.9383Z"
                        fill="#6A6A6A" />
                    </svg>

                  </span>

                </div>
                <p>
                  {{appointmentSMSHistory.appNotificationSmstoNumber | phone}} <br>
                  {{appointmentSMSHistory.appNotificationSmssentTime | date:appDateFormat.dateFormat}} - 
                  {{appointmentSMSHistory.appNotificationSmssentTime | date:'shortTime' | lowercase}}
                </p>
              </div>
              <div class="item-header__right">
                <a class="btn-send" [ngClass]="isDisabledSendNotification(appointmentSMSHistory) ? '' : 'disabled' "
                  (click)="ResendSMS(appointmentSMSHistory,$event, forwordSMS)" title="Resend SMS">
                  <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.39347 10.6065L17 1M7.39347 10.6065L10.4501 16.7198C10.4884 16.8034 10.5499 16.8742 10.6273 16.9239C10.7047 16.9736 10.7948 17 10.8868 17C10.9787 17 11.0688 16.9736 11.1462 16.9239C11.2236 16.8742 11.2851 16.8034 11.3234 16.7198L17 1M7.39347 10.6065L1.28022 7.54991C1.19661 7.51159 1.12576 7.45007 1.07608 7.37267C1.02641 7.29526 1 7.20522 1 7.11325C1 7.02127 1.02641 6.93123 1.07608 6.85383C1.12576 6.77642 1.19661 6.7149 1.28022 6.67659L17 1"
                      stroke="#4A4A4A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </a>
                <a class="btn-forward" [ngClass]="isDisabledSendNotification(appointmentSMSHistory) ? '' : 'disabled' "
                  (click)="forwordSMS.toggle($event)" title="Forward SMS">
                  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.9107 0.209475C10.0471 0.0753412 10.2319 0 10.4247 0C10.6174 0 10.8022 0.0753412 10.9386 0.209475L15.7873 4.98459C15.9235 5.11889 16 5.30094 16 5.49075C16 5.68056 15.9235 5.86261 15.7873 5.99691L10.9386 10.772C10.872 10.8424 10.7917 10.8988 10.7025 10.938C10.6133 10.9771 10.517 10.9982 10.4193 10.9999C10.3217 11.0016 10.2247 10.9839 10.1341 10.9479C10.0436 10.9118 9.9613 10.8582 9.89224 10.7902C9.82317 10.7222 9.76873 10.6412 9.73215 10.552C9.69557 10.4628 9.67761 10.3673 9.67933 10.2711C9.68105 10.1749 9.70243 10.0801 9.74218 9.99222C9.78193 9.90435 9.83924 9.82528 9.9107 9.7597L13.5181 6.20702H5.57598C4.88456 6.20702 3.83046 6.41712 2.9703 7.02738C2.14796 7.60995 1.4546 8.58885 1.4546 10.2659C1.4546 10.4558 1.37798 10.638 1.24158 10.7723C1.10519 10.9067 0.920194 10.9821 0.727302 10.9821C0.534409 10.9821 0.349417 10.9067 0.213022 10.7723C0.0766261 10.638 0 10.4558 0 10.2659C0 8.1228 0.923188 6.71318 2.12081 5.86417C3.28062 5.04189 4.65182 4.77448 5.57598 4.77448H13.5181L9.9107 1.2218C9.7745 1.0875 9.698 0.905448 9.698 0.715637C9.698 0.525826 9.7745 0.343775 9.9107 0.209475Z"
                      fill="#404040" />
                  </svg>
                </a>

                <p-overlayPanel #forwordSMS class="overly-filter" [dismissable]="true" [showCloseIcon]="true">
                  <div class="overly-filter__outer forword-email-outer">
                    <h2 class="filter-title">{{formatString(appointmentSMSHistory.appNotificationSubject)}}</h2>
                    <div class="forword-email-block">
                      <div class="forword-email-block__box">
                        <span>
                          <svg width="14" height="10" viewBox="0 0 14 10" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M8.67186 0.671388C8.79119 0.561643 8.95293 0.5 9.12158 0.5C9.29022 0.5 9.45197 0.561643 9.57129 0.671388L13.8139 4.5783C13.9331 4.68818 14 4.83713 14 4.99243C14 5.14773 13.9331 5.29668 13.8139 5.40657L9.57129 9.31348C9.51303 9.37106 9.44277 9.41724 9.36471 9.44927C9.28665 9.4813 9.20238 9.49852 9.11693 9.49991C9.03148 9.5013 8.9466 9.48682 8.86736 9.45735C8.78812 9.42787 8.71614 9.384 8.65571 9.32835C8.59528 9.27271 8.54764 9.20642 8.51563 9.13344C8.48362 9.06047 8.46791 8.98231 8.46941 8.90363C8.47092 8.82494 8.48962 8.74734 8.52441 8.67545C8.55919 8.60356 8.60934 8.53886 8.67186 8.48521L11.8284 5.57847H4.87898C4.27399 5.57847 3.35165 5.75037 2.59901 6.24968C1.87947 6.72632 1.27278 7.52724 1.27278 8.89935C1.27278 9.05477 1.20573 9.20383 1.08638 9.31374C0.967038 9.42364 0.80517 9.48538 0.636389 9.48538C0.467608 9.48538 0.30574 9.42364 0.186394 9.31374C0.0670479 9.20383 0 9.05477 0 8.89935C0 7.14592 0.80779 5.9926 1.85571 5.29795C2.87054 4.62518 4.07034 4.4064 4.87898 4.4064H11.8284L8.67186 1.49965C8.55269 1.38977 8.48575 1.24082 8.48575 1.08552C8.48575 0.930221 8.55269 0.781271 8.67186 0.671388Z"
                              fill="#404040" />
                          </svg>
                          Forward SMS
                        </span>

                        <!-- <small>Semi colon (;) separated</small> -->
                      </div>

                      <div class="form__group">
                        <label class="form__label">Mobile</label>
                        <!-- <input type="text" class="form__control" [(ngModel)]="smsTo" (keydown.space)="$event.preventDefault()"/> -->
                        <p-inputMask mask="999-999-9999" class="form__control" [(ngModel)]="smsTo"
                          (keydown.space)="$event.preventDefault()" type="text"
                          class="form__control placehold form__phone" pInputText maxlength="15"></p-inputMask>
                      </div>

                      <div class="btn-block">
                        <button class="btn btn--secondary"
                          (click)="CancelForwardSMS(); forwordSMS.hide()">Cancel</button>
                        <button class="btn btn--primary" (click)="ForwardSMS(appointmentSMSHistory,$event, forwordSMS);"
                          [disabled]="loadingSMS">Send</button>
                      </div>
                    </div>
                  </div>
                </p-overlayPanel>

              </div>
            </div>
            <div class="item-content" *ngIf="$index == expandedIndex">
              <h4 class="content-title">SMS Message</h4>
              <div class="sms-contents-block" [innerHTML]="appointmentSMSHistory.appNotificationSmsbody"></div>
            </div>
          </div>

        </li>
      </ul>
    </div>
  </div>

</div>

<div class="loader-block" *ngIf="loadingSMS">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
