import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { FacilityService } from '../../servicies/facility.service';
import { InsuranceInfoResponseModel } from 'src/app/shared/models/response/insurance-info-response-model';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
@Component({
  selector: 'app-add-insurance-dialog',
  templateUrl: './add-insurance-dialog.component.html',
  styleUrls: ['./add-insurance-dialog.component.sass']
})
export class AddInsuranceDialogComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  @ViewChild('searchBox') searchBox: ElementRef;
  insuranceModel: InsuranceInfoResponseModel;
  currentUserToken: UserAuthResponseModel;
  selectedTab: string;
  formFields = {
    searchtext: 'searchtext',
  };

  validationMessages = {
    searchtext: {
      required: 'Insurance name is required',
      //validAlphaNumeric: "Enter valid patient name / Patient ID."
    },
  };
  constructor(private router: Router,
    messageService: MessageService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService
  ) {

    super(messageService);
  }

  ngOnInit() {
    this.buildForm();
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
  }
  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.searchtext, this.formBuilder.control('', { validators: [Validators.required] }));
    // tslint:disable-next-line: max-line-length



    this.form = formGroup;
  }

  onSearch() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    const form = this.form;
    let searchText = form.get(this.formFields.searchtext).value;

    this.insuranceModel = new InsuranceInfoResponseModel;
    this.insuranceModel.crmPolicyHolderName = searchText;
    this.insuranceModel.modifiedByUserInfoId =this.currentUserToken.userId;
    
    const manageInsurance = this.facilityService.AddInsurance(this.insuranceModel)
    this.loading = true;
    manageInsurance.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage('Insurance is created successfully.');
          this.dynamicDialogRef.close(true);
         
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onNo() {
    this.dynamicDialogRef.close(false);
  }
}
