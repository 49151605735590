import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { SyncSalesOrderStatus, SyncHistory } from 'src/app/shared/models/response/sync-sales-order-status';
import { WipStatusBTResponseModel } from 'src/app/shared/models/response/wip-statusbt-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ImportexportService } from '../../servicies/importexport.service';
import { UploadService } from '../../servicies/upload.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ImportSalesOrderByIds } from 'src/app/shared/models/response/bell-notification-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';

class DynamicGrid {
  salesOrderId: string;
  status: string;
  isPatientDeliveryAddressConfigured: boolean;
  isPatientPhoneNoConfigured: boolean;
  isPatientEmailIdConfigured: boolean;
  appUserFullName: string;
  constructor(salesOrderId: string, status: string) {
    this.salesOrderId = salesOrderId;
    this.status = status;
  }
}

@Component({
  selector: 'app-sync-salesorder-byid',
  templateUrl: './sync-salesorder-byid.component.html',
  styleUrls: ['./sync-salesorder-byid.component.sass']
})
export class SyncSalesorderByidComponent extends BaseComponent implements OnInit, OnDestroy {
  SalesOrderAppId: string = "";
  IsUpdateWIPStatus: boolean = false;
  title = 'Import Sales Order ID';
  Buttontitle = 'Import';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;

  wipStatesResponseModel: WipStatusBTResponseModel[];
  selectedwipStatesResponseModel: WipStatusBTResponseModel;
  syncSalesOrderStatus: SyncSalesOrderStatus;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  formSyncSalesOrdersubmitted: boolean = false;
  loggedInUserId: number;
  loggedInUserName: string;
  syncHistory: SyncHistory[];
  newSOList: string;
  dupSOList: string;
  totalSOList: string;
  showHistory: boolean = false;
  isValueRequire: boolean = false;
  error: any = { isError: false, errorMessage: '' };
  isSubmitBtnDisabled: boolean = false;
  isShowTable: boolean = false;

  lstGrid: ImportSalesOrderByIds[];
  lstSalesOrder: string[];
  IsSendInvitationLink: boolean = false;



  constructor(private importExportService: ImportexportService,
    private uploadService: UploadService,
    private http: HttpClient,
    private sanitzer: DomSanitizer,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.loggedInUserName = loggedInUser.userFullName;
  }

  async ngOnInit() {
    this.lstGrid = [];
  }

  SyncSalesOrders() {
    this.isShowTable = false;
    this.isValueRequire = false;
    this.isSubmitBtnDisabled = true;
    this.Buttontitle = "In Progress";
    if (this.SalesOrderAppId != null && this.SalesOrderAppId != "") {
      this.lstGrid = [];
      this.lstSalesOrder = this.SalesOrderAppId.split(',');

      this.lstSalesOrder = this.lstSalesOrder.filter(function (el) {
        return el != "";
      });

      if (this.lstSalesOrder.length > 0) {
        if (this.lstSalesOrder.length > 10) {
          this.showSuccessMessage('Maximum 10 sales orders are alllow to import!!');
        }
        else {
          for (var i = 0; i < this.lstSalesOrder.length; i++) {
            let _soId = this.lstSalesOrder[i];
            let importSalesOrderByIds = new ImportSalesOrderByIds();
            importSalesOrderByIds.appSalesOrderAppId = this.lstSalesOrder[i];
            importSalesOrderByIds.status = "Queue";
            importSalesOrderByIds.appUserinfoid = this.loggedInUserId;
            importSalesOrderByIds.modifiedByUserInfoId = this.loggedInUserId;
            importSalesOrderByIds.appUserFullName = this.loggedInUserName;
            this.lstGrid.push(importSalesOrderByIds)
          }
          this.isShowTable = true;
          this.ImportSalesOrder();
          //this.AddBellSalesOrderSyncNotification();

        }

      }
      else {
        this.SalesOrderAppId = "";
        this.showSuccessMessage('Minimum one sales order is require!!');
        this.isShowTable = false;
        this.isSubmitBtnDisabled = false;
        this.Buttontitle = "Import";
      }
    }
    else {
      this.isShowTable = false;
      this.isSubmitBtnDisabled = false;
      this.Buttontitle = "Import";
      this.isValueRequire = true;
    }
  }

  ImportSalesOrder() {
    if (this.lstSalesOrder.length > 0) {
      let _soId = this.lstSalesOrder[0];

      for (let item of this.lstGrid) {
        if (item.appSalesOrderAppId == _soId) {
          item.status = 'In Progress';
        }
      }

      this.lstSalesOrder.shift();
      this.loading = true;
      this.importExportService.SyncBySalesOrderId(_soId, this.IsUpdateWIPStatus,this.IsSendInvitationLink)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
        .subscribe((result) => {
          if (result.isSuccess) {
            let _gridmodel = result.responseModel;
            for (let item of this.lstGrid) {
              if (item.appSalesOrderAppId == _soId) {
                item.status = _gridmodel.status;
                item.branchId = _gridmodel.branchId;
                item.appUserinfoid = this.loggedInUserId;
                item.modifiedByUserInfoId = this.loggedInUserId;
                item.appSyncHistoryId = _gridmodel.appSyncHistoryId;
                item.salesOrderId = _gridmodel.salesOrderId;
                item.appPatientId = _gridmodel.appPatientId;
                item.isPatientDeliveryAddressConfigured = _gridmodel.isPatientDeliveryAddressConfigured;
                item.isPatientPhoneNoConfigured = _gridmodel.isPatientPhoneNoConfigured;
                item.isPatientEmailIdConfigured = _gridmodel.isPatientEmailIdConfigured;
                item.isClassificationDetailsConfigured = _gridmodel.isClassificationDetailsConfigured;
                item.isBranchDetailsConfigured = _gridmodel.isBranchDetailsConfigured;
              }
            }
            this.ImportSalesOrder();
          }
          else {
            for (let item of this.lstGrid) {
              if (item.appSalesOrderAppId == _soId) {
                item.status = "Fail to Import";
              }
            }
            this.ImportSalesOrder();
          }
        }, (httpResponseError) => {
          for (let item of this.lstGrid) {
            if (item.appSalesOrderAppId == _soId) {
              item.status = "Error(" + httpResponseError.message + ")";
            }
          }
          this.ImportSalesOrder();
        });
    }
    else {
      this.loading = false;
      this.SalesOrderAppId = "";
      this.isSubmitBtnDisabled = false;
      this.Buttontitle = "Import";
      this.AddBellSalesOrderSyncNotification();
      this.showSuccessMessage('Request execute successfully');
    }
  }

  AddBellSalesOrderSyncNotification() {
    this.importExportService.AddBellSalesOrderSyncNotification(this.lstGrid)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {

        });
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onUpdateWIPStatus(status) {
    this.IsUpdateWIPStatus = status.checked;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
}

