import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { CalendarEvent } from 'angular-calendar';
import { WeekViewHourColumn } from 'calendar-utils';
import { isSameDay, isSameMonth } from 'date-fns';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, MenuItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Constants } from 'src/app/shared/constants';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { ExportAppointmentCount, SchedulerResponseModel, SchedulerResponseModelForExport, SchedulerResponseModelForExportFinal } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { TheraistAppointmentService } from 'src/app/therapist/services/theraistappointment.service';
import { environment } from 'src/environments/environment';
import { colors } from './colors.model';
import { User } from './day-view-scheduler.component';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SettingsPluginResponseModel, RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { QuickFilters } from '../../../shared/models/common/custom-filiters';
import { TimezonePipe } from '../../../pipes/timezone.pipe';
const users: User[] = [
  {
    id: 0,
    field: 'CrmSalesorderAppId',
    name: 'Sales Order ID',
    color: colors.transparent,
  },
  //{
  //  id: 1,
  //  field: 'CrmPatientId',
  //  name: 'Patient ID',
  //  color: colors.transparent,
  //},
  {
    id: 2,
    name: 'Patient Name / Patient ID',
    field: 'crmPatientName',
    color: colors.transparent,
  },
  {
    id: 3,
    name: 'Classification Type',
    field: 'crmClassification',
    color: colors.transparent,
  },
  {
    id: 4,
    name: 'Location',
    field: 'CrmAddress',
    color: colors.transparent,
  }
  ,
  {
    id: 5,
    field: 'AppointmentType',
    name: 'Appointment Type',
    color: colors.transparent,
  },
  {
    id: 6,
    field: 'SchWIPStatus',
    name: 'Status',
    color: colors.transparent,
  },
  {
    id: 7,
    field: 'SchComments',
    name: 'Note',
    color: colors.transparent,
  }
];

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./day-view-scheduler.component.sass'],
  templateUrl: './template.html',
  providers: [DialogService]
})

export class DemoComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  exportDataRes: SchedulerResponseModelForExport[];
  appointments: AppointmentInfoReponseModel[] = [];
  appointment: AppointmentInfoReponseModel;
  selectedAppointment: AppointmentInfoReponseModel;
  facilityInfo: FacilityInfoResponseModel;
  appointmentsalesOrderStatusMenuitems: MenuItem[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  therapistId: number;
  therapistName: string;
  duration: string;
  comments: string;
  appDateFormat: DateFormatModel;
  wipStatus: any;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  brightreeInternalUserId: number;
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  twoFacilityPopup: boolean;
  _patientTimezone: string = '';
  facilityTimeZone: string = '';
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  displayCompleteAppointment: boolean;
  selectedAppointmentEvent: any

  exportTherapistName: string;

  exportAppointmentCnt: ExportAppointmentCount[];

  constructor(
    private webStorageService: WebStorageService,
    private router: Router,
    private theraistAppointmentService: TheraistAppointmentService,
    private featureGroupService: FeatureGroupService,
    protected cdr: ChangeDetectorRef,
    messageService: MessageService,
    private titleService: Title,
    private http: HttpClient,
    private dialougeService: DialogService) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.loading = false;
    this.appointmentsalesOrderStatusMenuitems = new Array();
    this.titleService.setTitle("Appoint360");

    this.calendarActions = [
      { name: 'View By Date', code: 'ViewByDate' },
      { name: 'View By Week', code: 'ViewByWeek' }
    ];
  }

  viewDate = new Date();

  users = users;
  isDisabled: boolean = false;
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderSelectedStatusCurrent: WIPStatesResponseModel;
  salesOrderStatusCurrent: WIPStatesResponseModel[];
  showMarker = true;
  selectedDayViewDate: Date;
  dayStartHour: number = 7;
  dayEndHour: number = 21;
  hourColumns: WeekViewHourColumn[];
  canUpdateStatue: boolean;
  en: any;
  events$: Observable<CalendarEvent<{ appointmentInfoReponseModel: SchedulerResponseModel }>[]>;
  currentSelectedView: any;
  dateFormat: string = '';
  primeFormat: string = '';
  timeZone: string = '';
  Customloading: boolean = false;
  myOptions = {
    'placement': 'right',
    'show-delay': 500
  };
  timeZoneList: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string };



  ngOnInit(): void {
    this.timeZoneList = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', shortName: 'IST', orgcode: 'UTC+5', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST', orgcode: 'UTC-5', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST', orgcode: 'UTC-6', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MT', orgcode: 'UTC-7', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST', orgcode: 'UTC-8', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];


    this.selectedcalendarActions = this.calendarActions.find(x => x.code == 'ViewByDate');
    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: 'mm/dd/yy',
      weekHeader: 'Wk'
    };

    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.salesOrderInsuranceInfo = new Array();
    this.duration = '';
    this.dayStartHour = 7;//this.facilityInfo.crmFacilityStartTime;
    this.dayEndHour = 19;//this.fac
    this.salesOrderType = 'AppointmentRequest';
    this.facilityInfo = new FacilityInfoResponseModel();
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.therapistName = currentUserToken.userFullName;
      this.facilityTimeZone = currentUserToken.crmTimeZone;
    }
    else {
      this.therapistId = 0;
      this.therapistName = '';
      this.facilityTimeZone = 'EST';
    }

    this.getUpdateStatusPermission();
    this.getSalesOrderStatus();
    this.fetchEvents(true);
    this.exportData1(true);
    this.getWipStatusBTmapping();
    this.GetRCMConfigurationUrl();
  }

  GetAppointments() {

    this.fetchEvents(true);
    this.exportData1(true);
  }

  async getWipStatusBTmapping() {
    this.loading = true;
    this.featureGroupService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.wipStatusBTMappingResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.featureGroupService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.responseModel) {

            let jsonData = result.responseModel;
            this.patientBrighttreeURL = jsonData.patientRCMURL;
            this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }
  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {

    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(patienKeyUrl, '_blank')
  }

  private async getUpdateStatusPermission() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.AllowTherapistToUpdateAppointmentStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)

              this.canUpdateStatue = (result.responseModel.schSettingValue.toLowerCase() === 'true');
          }
        });
      });
  }


  //private async getGlobalTimeZone() {
  //  await this.featureGroupService.getSettings(Constants.SettingKeys.TimeZone)
  //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
  //    .toPromise()
  //    .then((result) => {
  //      this.processResult<SettingsResponseModel>(result, () => {
  //        if (result.isSuccess) {
  //          if (result.responseModel) {
  //            this.selectedTimeZone = this.timeZoneList.find(x => x.code == result.responseModel.schSettingValue);
  //            this.facilityTimeZone = this.selectedTimeZone.shortName;
  //          }
  //        }
  //      });
  //    });
  //}

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }


    if (this.progress)
      this.progress.complete();
  }

  private getSalesOrderStatus() {
    this.progress.next(true);
    this.theraistAppointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          this.salesOrderStatus = result.responseModel;
          this.salesOrderStatusCurrent = this.salesOrderStatus.
            filter(x => x.wipStatesName.toLowerCase() == 'complete'
              || x.wipStatesName.toLowerCase() == 'no show'
            );


          this.salesOrderStatus.forEach(value => {

            if (value.wipStatesName != 'Booked' && value.wipStatesName != 'Void' && value.wipStatesName != 'unreconcile') {
              this.appointmentsalesOrderStatusMenuitems.push({
                id: value.wipStatesId.toString(),
                label: value.wipStatesName, command: (event) => {
                  this.onMeuSelection(event.item);
                }
              });
            }
          });

        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async onStatusChange() {

    let resResult = false;
    if (!this.salesOrderSelectedStatusCurrent) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }
    let appointmentId = this.salesOrderSelectedStatusCurrent.wipStatesId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.appointment.schAppointmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = appointmentId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
    //appointmentUpdateStatusRequestModel.SchComments = this.comments;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    this.Customloading = true;
    await this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.Customloading = false))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          this.displayCompleteAppointment = false;
          if (result.responseModel) {
            //this.addSelectedDayViewClass(this.currentSelectedView);
            resResult = true;
          }

        });
      }).catch((httpResponseError) => {
        this.Customloading = false;
        this.showErrorMessage(httpResponseError.message);
      });

    if (resResult) {
      this.showSuccessMessage("Appointment status has been updated.");
      this.cdr.detectChanges();
      this.appointment = new AppointmentInfoReponseModel();
      this.removeSelectedDayViewClass(this.currentSelectedView);
      this.currentSelectedView = [];
      this.fetchEvents(true);
      this.exportData1(true);
      this.refresh.next();
    }
  }

  onMeuSelection(selectedMenuitem) {

    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.appointment.schAppointmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = selectedMenuitem.id;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
    appointmentUpdateStatusRequestModel.SchComments = this.comments;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    const oldAppointment = this.appointment;
    this.progress.next(true);
    this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          this.displayCompleteAppointment = false;
          this.fetchEvents(true);
          this.exportData1(true);
          this.showSuccessMessage("Appointment status has been updated.");
          this.isDisabled = selectedMenuitem.label.toLowerCase() != "booked" ? true : false;
          this.appointment = oldAppointment;
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });


  }

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  refresh: Subject<any> = new Subject();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  activeDayIsOpen: boolean = true;
  salesOrderType: string;

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  fetchEvents(isRefresh: boolean): void {

    this.appointment = new AppointmentInfoReponseModel();
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();

    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
      // headers.append("clientId", currentClientConfig.companyId.toString());

    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const headers = new HttpHeaders()
      .set("clientId", clientId).append("Authorization", Authorization);


    let api = '';
    if (!isRefresh)
      api = 'Therapist/GetTherapistAppointment?therapistId=' + this.therapistId + '&appointmentDate=' + this.viewDate.toDateString();
    else
      api = 'Therapist/GetTherapistAppointment?therapistId=' + this.therapistId + '&appointmentDate=' + this.viewDate.toDateString() + '&rnd=' + Math.random().toString();


    this.events$ = this.http
      .get(environment.apiBaseUrl + api, { headers })
      .pipe(
        map(({ responseModel }: { responseModel: SchedulerResponseModel[] }) => {
          //if (responseModel != undefined && responseModel.length > 0) {
          //  this.facilityTimeZone = responseModel[0].facilityTimeZone;
          //}
          this.SetMinMaxHourForCalander(responseModel);
          return responseModel.map((appointmentInfoReponseModel: SchedulerResponseModel) => {
            return {
              title: appointmentInfoReponseModel.title,
              start: moment(appointmentInfoReponseModel.start, 'YYYY-MM-DD HH:mm').toDate(),
              startLable: moment(appointmentInfoReponseModel.startLabel, 'YYYY-MM-DD HH:mm').toDate(),
              end: moment(appointmentInfoReponseModel.end, 'YYYY-MM-DD HH:mm').toDate(),
              crmPatientTimeZone: appointmentInfoReponseModel.crmPatientTimeZone,
              endLabel: moment(appointmentInfoReponseModel.endLabel, 'YYYY-MM-DD HH:mm').toDate(),
              cssClass: appointmentInfoReponseModel.id == 0 ? 'breakhours' : appointmentInfoReponseModel.id == -1 ? 'blockhours' : 'breakhours-block',
              color: appointmentInfoReponseModel.id == 0 ? colors.grey : colors.appointment,
              meta: {
                appointmentInfoReponseModel,

              },
            };
          });
        })
      );

  }

  SetMinMaxHourForCalander(lstScheduler: SchedulerResponseModel[]) {
    let StartHour: number = this.dayStartHour;
    let EndHour: number = this.dayEndHour;

    lstScheduler.forEach(function (item) {
      var _startTime = moment(item.start, 'YYYY-MM-DD HH:mm').toDate();
      var _endTime = moment(item.end, 'YYYY-MM-DD HH:mm').toDate();

      let _StartHour: number = _startTime.getHours();
      let _EndHour: number = _endTime.getHours();

      if (_StartHour < StartHour)
        StartHour = _StartHour;

      if (_EndHour > EndHour)
        EndHour = _EndHour;
    });


    if (StartHour < this.dayStartHour)
      this.dayStartHour = StartHour;

    if (EndHour > this.dayEndHour)
      this.dayEndHour = EndHour;
  }

  async getTherapistById() {
    await this.theraistAppointmentService.GetTherapistFacility(this.therapistId, this.viewDate.toDateString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<FacilityInfoResponseModel>(result, () => {
          this.facilityInfo = result.responseModel;
          this.dayStartHour = 8;//this.facilityInfo.crmFacilityStartTime;
          this.dayEndHour = 19;//this.facilityInfo.crmFacilityEndTime;
          this.cdr.detectChanges();
          this.refresh.next();
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  mouseEnterhandleEvent({ view, event }): void {
    if (event.id === 0) {
      return;
    }
    this.selectedDayViewDate = event.start;
    this.addSelectedDayViewClass(view);
    this.currentSelectedView = view;

    this.twoFacilityPopup = true;

  }

  handleEvent(view, action: string, event: CalendarEvent): void {

    this.twoFacilityPopup = true;

    if (event.id === 0) {
      //return;
    }

    let AppoinmentId = event.meta.appointmentInfoReponseModel.id;
    if (AppoinmentId > 0) {
      this._patientTimezone = '';
      this.Customloading = true;
      this.theraistAppointmentService.GetAppointmentById(AppoinmentId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.Customloading = false))
        .toPromise()
        .then((result) => {
          this.processResult<AppointmentInfoReponseModel>(result, () => {
            let apt = result.responseModel;
            this.appointment = apt;
            this.cdr.detectChanges();
            this.duration = this.appointment.schAppointmentDuration.toString() + " Minutes";
            this.isDisabled = this.appointment.schWIPStatus.toLowerCase() != "booked" ? true : false;
            this.salesOrderSelectedStatusCurrent = this.salesOrderStatusCurrent.find(x => x.wipStatesId == this.appointment.schAppointmentStatus);
            this.comments = this.appointment.appointmentDetailInfo.schComments;
            if (this.appointment.appointmentDetailInfo.crmPatientTimeZone != undefined && this.appointment.appointmentDetailInfo.crmPatientTimeZone != '') {
              this._patientTimezone = new TimezonePipe(this.webStorageService).transform(this.appointment.appointmentDetailInfo.crmPatientTimeZone);
            }
            this.selectedDayViewDate = event.start;
            this.addSelectedDayViewClass(view);
            this.currentSelectedView = view;

          });
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
    else {
      this.appointment = new AppointmentInfoReponseModel();
      this.removeSelectedDayViewClass(view);
    }



  }

  private addSelectedDayViewClass(view) {
    view.forEach((column) => {
      column.events.forEach((hourSegment) => {
        let eve = hourSegment;
        let AppoinmentId = eve.event.meta.appointmentInfoReponseModel.id;
        if (AppoinmentId > 0) {
          delete eve.event.cssClass;
          if (
            this.selectedDayViewDate &&
            eve.event.start.getTime() === this.selectedDayViewDate.getTime()
          ) {
            eve.event.cssClass = 'selected-row';
          }
        }
        //});
      });
    });
  }

  private removeSelectedDayViewClass(view) {
    view.forEach((column) => {
      column.events.forEach((hourSegment) => {
        let eve = hourSegment;
        let AppoinmentId = eve.event.meta.appointmentInfoReponseModel.id;
        if (AppoinmentId > 0) {
          delete eve.event.cssClass;
          if (
            this.selectedDayViewDate &&
            eve.event.start.getTime() === this.selectedDayViewDate.getTime()
          ) {
            eve.event.cssClass = '';
          }
        }
        //});
      });
    });
  }

  closeSideBar() {

    this.appointment = new AppointmentInfoReponseModel();
    this.removeSelectedDayViewClass(this.currentSelectedView);
    this.currentSelectedView = [];
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  exportPDF() {
    if (!this.exportDataRes) {
      this.showWarningMessage('No Appointments for selected Date.');
      return;
    }
    var data = document.getElementById('exportData');
    data.style["display"] = "block";
    html2canvas(data).then(canvas => {
      // Few necessary setting options  
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('My Appointments.pdf'); // Generated PDF   
    });
    data.style["display"] = "none";
  }

  exportData1(isRefresh: boolean) {
    let therpistName = this.therapistName.split(/[ ,]+/).join(',');
    let splitName = therpistName.split(',');
    this.exportTherapistName = splitName[1] + ", " + splitName[0];
    this.theraistAppointmentService.GetTherapistAppointments(this.therapistId, this.viewDate.toDateString(), isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SchedulerResponseModelForExportFinal>(result, () => {
          if (result.responseModel.schedulerResponseModelForExportDbs.length  > 0) {
            var data = document.getElementById('exportData');
            data.style["display"] = "block";
            this.exportDataRes = result.responseModel.schedulerResponseModelForExportDbs;
            this.exportAppointmentCnt = result.responseModel.appointmentCountForExportPDFs;
            data.style["display"] = "none";
          }
        });
      }).catch((httpResponseError) => {
        //this.showErrorMessage(httpResponseError.message);
      });
  }

  exportCSV() {
    if (!this.exportDataRes) {
      this.showWarningMessage('No Appointments for selected Date.');
      return;
    }
    if (this.loading)
      this.loading = false;
    this.loading = true;
    this.theraistAppointmentService.DownloadTherapistAppointmentsCSV(this.therapistId, this.viewDate.toDateString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'My Appointments.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  myAvailability() {
    this.router.navigate(['/therapist/my-availability']);
  }

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }
  }

  hideCompletePopup() {
    this.fetchEvents(true);
    this.exportData1(true);
    this.refresh.next();
    this.displayCompleteAppointment = false;
    this.cdr.detectChanges();
  }

  //41466
  isAllowToSetNoShowStatus(): boolean {

    var currentDateTime;
    var appDateTime;

    if ((this.wipStatus.toLowerCase() == 'no show') || (this.wipStatus.toLowerCase() == 'complete')) {
      //41466: After marking No Show it allow user to change the availability of that therapist
      this.selectedTimeZone = this.timeZoneList.find(x => x.shortName == this.selectedAppointmentEvent.crmPatientTimeZone);
      var orgappDateTime = moment.tz(this.selectedAppointmentEvent.start, this.selectedTimeZone.countryName);

      //convert appointment time to local timezone
      this.selectedTimeZone = this.timeZoneList.find(x => x.code == this.appDateFormat.timeZone);
      var appDate = orgappDateTime.tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
      appDateTime = moment(appDate).toDate();

      // get curretn date time from local timezone
      var date = moment(new Date()).tz(this.selectedTimeZone.countryName).format('DD-MMM-yyyy hh:mm:ss A');
      currentDateTime = moment(date).toDate();

      // if (moment(this.appointment.schAppointmentDateStartTime).isAfter(moment(Date()).toDate())) {
      //   return true;
      // }
    }
    return false;
  }

  onPopupSelection(selectedEvent: any, event: any) {
    if (!selectedEvent.eventSalesOrderSelectedStatus) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }
    //41466
    let tempStatus = this.salesOrderStatusCurrent;
    let prevStatus = this.selectedAppointmentEvent;
    this.selectedAppointmentEvent = selectedEvent;
    this.wipStatus = selectedEvent.eventSalesOrderSelectedStatus.wipStatesName;
    let status = this.wipStatus;
    //41466
    let message: string = "No Show status can not be set before Appointment time";
    if (status.toLowerCase() == 'complete')
      message = "Complete status can not be set before Appointment time";

    if (this.isAllowToSetNoShowStatus()) {
      this.showWarningMessage(message);
      this.selectedAppointmentEvent = prevStatus;
      this.wipStatus = tempStatus;
      selectedEvent.eventSalesOrderSelectedStatus = prevStatus;
      return;
    }
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    appointmentRequestBT.CrmModifiedBy = this.therapistId;
    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    this.cdr.detectChanges();
    this.displayCompleteAppointment = true;
    return;

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to mark this appointment as ' + this.wipStatus.concat('?'),
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        event.target.value = "0";
        return;
      }
      let resResult = false;
      let statusId = selectedEvent.eventSalesOrderSelectedStatus.wipStatesId;
      let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = selectedEvent.meta.appointmentInfoReponseModel.id;
      appointmentUpdateStatusRequestModel.SchAppointmentStatus = statusId;
      appointmentUpdateStatusRequestModel.CrmFacilityId = selectedEvent.meta.appointmentInfoReponseModel.facilityId;
      appointmentUpdateStatusRequestModel.CrmTherapistId = this.therapistId;
      appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
      appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
      appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
      appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
      this.Customloading = true;
      appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
      this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            this.displayCompleteAppointment = false;
            if (result.responseModel) {
              //this.addSelectedDayViewClass(this.currentSelectedView);
              this.showSuccessMessage("Appointment status has been updated.");
              this.cdr.detectChanges();
              this.currentSelectedView = [];
              this.fetchEvents(true);
              this.exportData1(true);
              this.refresh.next();
              this.Customloading = false;
            }

          });
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });


      //let AppoinmentId = selectedEvent.meta.appointmentInfoReponseModel.id;
      //if (AppoinmentId > 0) {

      //  this.Customloading = true;
      //  this.theraistAppointmentService.GetAppointmentById(AppoinmentId)
      //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.Customloading = false))
      //    .toPromise()
      //    .then((result) => {
      //      this.processResult<AppointmentInfoReponseModel>(result, () => {

      //        let apt = result.responseModel;
      //        this.appointment = apt;
      //        this.eventStatusChange(selectedEvent);
      //      });
      //    }).catch((httpResponseError) => {
      //      this.Customloading = false;
      //      this.showErrorMessage(httpResponseError.message);
      //    });
      //}
    });
  }
  CompleteNoShowAppointment() {
    let status = this.wipStatus;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    appointmentRequestBT.CrmModifiedBy = this.therapistId;
    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;

      }
    }
    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;

      }
    }
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    let resResult = false;
    let statusId = this.selectedAppointmentEvent.eventSalesOrderSelectedStatus.wipStatesId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.selectedAppointmentEvent.meta.appointmentInfoReponseModel.id;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = statusId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.selectedAppointmentEvent.meta.appointmentInfoReponseModel.facilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.therapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatusRequestModel.dateTimeLastModified = this.selectedAppointmentEvent.meta.appointmentInfoReponseModel.dateTimeLastModified;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;

    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    this.Customloading = true;

    this.theraistAppointmentService.CheckAppointmentUpdatedStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {

            this.displayCompleteAppointment = false;
            this.showInfoMessage("The appointment has been rescheduled or updated. The screen will be refreshed.");
            this.cdr.detectChanges();
            this.currentSelectedView = [];
            this.fetchEvents(true);
            this.exportData1(true);
            this.refresh.next();
            this.Customloading = false;
            return;
          }
          else {
            this.Customloading = true;
            this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
              .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
              .toPromise()
              .then((result) => {
                this.processResult<boolean>(result, () => {
                  this.displayCompleteAppointment = false;
                  if (result.responseModel) {
                    //this.addSelectedDayViewClass(this.currentSelectedView);
                    this.showSuccessMessage("Appointment status has been updated.");
                    this.cdr.detectChanges();
                    this.currentSelectedView = [];
                    this.fetchEvents(true);
                    this.exportData1(true);
                    this.refresh.next();
                    this.Customloading = false;
                  }

                });
              }).catch((httpResponseError) => {
                this.Customloading = false;
                this.showErrorMessage(httpResponseError.message);
              });
          }

        });
      }).catch((httpResponseError) => {
        this.Customloading = false;
        this.showErrorMessage(httpResponseError.message);
        return;
      });




  }

  async onAppointmentCommentSelection(selectedEvent: any) {

    if (selectedEvent.id === 0) {
      //return;
    }
    let AppoinmentId = selectedEvent.meta.appointmentInfoReponseModel.id;
    if (AppoinmentId > 0) {
      this.Customloading = true;
      let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = AppoinmentId;
      appointmentUpdateStatusRequestModel.SchComments = selectedEvent.eventSalesOrderSelectedNote == undefined ? "" : selectedEvent.eventSalesOrderSelectedNote;
      await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.Customloading = false; }))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            if (result.responseModel) {
              this.showSuccessMessage("Appointment Note has been updated.");
              this.cdr.detectChanges();
              this.currentSelectedView = [];
              this.fetchEvents(true);
              this.exportData1(true);
              this.Customloading = false;
              this.refresh.next();
            }

          });
        }).catch((httpResponseError) => {
          this.Customloading = false;
          this.showErrorMessage(httpResponseError.message);
        });



      //this.Customloading = true;
      //this.theraistAppointmentService.GetAppointmentById(AppoinmentId)
      //  .pipe(takeUntil(this.unsubscriber), finalize(() => this.Customloading = false))
      //  .toPromise()
      //  .then((result) => {
      //    this.processResult<AppointmentInfoReponseModel>(result, () => {

      //      let apt = result.responseModel;
      //      this.appointment = apt;
      //      this.eventCommentChange(selectedEvent);
      //    });
      //  }).catch((httpResponseError) => {
      //    this.Customloading = false;
      //    this.showErrorMessage(httpResponseError.message);
      //  });
    }

  }

  async eventCommentChange(event: any) {
    let resResult = false;
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.appointment.schAppointmentId;
    appointmentUpdateStatusRequestModel.SchComments = event.eventSalesOrderSelectedNote == undefined ? "" : event.eventSalesOrderSelectedNote;
    await this.theraistAppointmentService.UpdateAppointmentDetailComment(appointmentUpdateStatusRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            resResult = true;
          }

        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

    if (resResult) {
      this.showSuccessMessage("Appointment Note has been updated.");
      this.cdr.detectChanges();
      this.currentSelectedView = [];
      this.fetchEvents(true);
      this.exportData1(true);
      this.refresh.next();
    }

  }

  async eventStatusChange(event: any) {

    let resResult = false;
    if (!event.eventSalesOrderSelectedStatus) {
      this.showWarningMessage("Please select appointment status.");
      return;
    }

    this.wipStatus = event.eventSalesOrderSelectedStatus.wipStatesName;
    let status = this.wipStatus;
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.DeliveryTechnicianId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    let appointmentId = event.eventSalesOrderSelectedStatus.wipStatesId;
    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
    appointmentUpdateStatusRequestModel.SchAppointmentId = this.appointment.schAppointmentId;
    appointmentUpdateStatusRequestModel.SchAppointmentStatus = appointmentId;
    appointmentUpdateStatusRequestModel.CrmFacilityId = this.appointment.appointmentDetailInfo.crmFacilityId;
    appointmentUpdateStatusRequestModel.CrmTherapistId = this.appointment.appointmentDetailInfo.crmTherapistId;
    appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.therapistId;
    appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
    appointmentUpdateStatusRequestModel.isUpdateByTherapist = true;
    appointmentUpdateStatusRequestModel.TherapistName = this.therapistName;
    appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    await this.theraistAppointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<boolean>(result, () => {
          this.displayCompleteAppointment = false;
          if (result.responseModel) {
            //this.addSelectedDayViewClass(this.currentSelectedView);
            resResult = true;
          }

        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

    if (resResult) {
      this.showSuccessMessage("Appointment status has been updated.");
      this.cdr.detectChanges();
      this.currentSelectedView = [];
      this.fetchEvents(true);
      this.exportData1(true);
      this.refresh.next();
    }
  }

  RedirectcalendarActions() {
    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "ViewByDate")
      this.router.navigate(['/therapist/my-appointments']);
    else if (this.selectedcalendarActions.code == "ViewByWeek")
      this.router.navigate(['/therapist/my-appointments-week']);

  }



  twoFacilityPopupDialog() {
    // this.twoFacilityPopup = true;
  }

}
