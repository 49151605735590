import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject, LOCALE_ID } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NotificationService } from 'src/app/admin/servicies/notification.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { NotificationInAppDetailResponseModel } from 'src/app/shared/models/response/notificationinapp-detail-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { TwillioCallService } from 'src/app/admin/servicies/twillioCall.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { OverlayPanel } from 'primeng/overlaypanel';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { TwilioDeviceObject, AppoinmentDialogueParametersObject } from 'src/app/shared/models/common/twilio-device-object';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { BookAppoinmentDialogComponent } from 'src/app/shared/components/book-appoinment-dialog/book-appoinment-dialog.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { Result } from 'src/app/shared/models/response/result';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component'
import { CompanyService } from '../services/company.service';
import * as signalR from '@aspnet/signalr';
import { SearchPatientBrightreeComponent } from '../search-patient-brightree/search-patient-brightree.component';
import { BellNotificationInAppDetailResponseModel, BellNotificationInAppDetailsSummaryResponseModel, BellNotificationInAppReadStatusRequestModel, BellNotificationInAppResponseModel, BellNotificationRequestModel } from '../models/response/bell-notification-response-model';
import { DateFormatModel } from '../models/common/date-format-model';
import { DatePipe } from '@angular/common';
import { RCMCofigurationUrl } from '../models/response/settings/settings-plugin-response-model';
import { forEachChild } from 'typescript';
import { userModuleFeatureResponseModel } from '../models/response/feature-group-response';
import { UserPermissions } from '../enums';
import { SearchRequestModel } from '../models/request/search-request-model';
import { SalesOrderInfoResponseModel } from '../models/response/appointment/sales-order-info-reponse-model';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
interface Name {
  name: string;
  code: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  providers: [DialogService]
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {

  appointment: Name[];
  branches: Name[];
  selectedResource: Name;
  selectedbranch: Name;
  selectedBranchCode: number;
  domainUrl: string = '';
  country: any;
  countries: any[];
  filteredCountriesSingle: any[];
  filteredCountriesMultiple: any[];
  defaultModeofAppointment: string = "";
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  notificationInAppResponseModel: Array<NotificationInAppDetailResponseModel>;
  loggedInUserId: number;
  unReadCount: number;
  twilioDeviceConnection: TwilioDeviceObject;
  device: any;
  connection: any;
  connectionStatus: string;
  isCallStarted: boolean = false;
  voiceCallToNumber: string;
  voiceMailNumber: string;
  appoinmentDialogueParametersObject: AppoinmentDialogueParametersObject = new AppoinmentDialogueParametersObject();
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageShortUrl: SafeResourceUrl;
  imageData: string;
  shortImageData: string;
  imagealt: string;
  imageTitle: string;
  searchText: string = "";
  currentUrl: string;
  showClear: boolean = false;
  isCall: boolean = false;
  branchSelectBox: boolean = false;
  status: boolean;
  userfullname: string;
  isMenuLoaded: boolean = false;
  isAppointments: boolean = false;
  userToken: UserAuthResponseModel;
  subs: Subscription;
  signalRToken: string;
  isProvisional: boolean;
  bellNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellSalesOrderNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellPatientNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellTherapistNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  bellAlertNotificationInAppResponseModel: BellNotificationInAppResponseModel[];
  selectedSalesOrderSyncDetails: BellNotificationInAppDetailsSummaryResponseModel[];
  appDateFormat: DateFormatModel;
  patientBrighttreeURL: any;
  brightreeInternalUserId: number;
  salesOrderBrighttreeURL: any;
  userPerissionfeatures: userModuleFeatureResponseModel[];
  allowToViewSalesOrderNotifications: boolean;
  allowToViewPatientNotifications: boolean;
  allowToViewTherapistNotifications: boolean;
  allowToViewAlertNotifications: boolean;
  timeZone: { name: string; code: string; orgcode: string; shortName: string; }[];
  selectedTimeZone: { name: string; code: string; orgcode: string; shortName: string; };

  branchEvent() {
    this.branchSelectBox = !this.branchSelectBox;
  }

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
    messageService: MessageService,
    private dialougeService: DialogService,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    private _cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private featureGroupService: FeatureGroupService,
    private sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private companyService: CompanyService,
    private appointmentService: AppointmentService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    super(messageService);
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.configResponseModel = new ConfigResponseModel();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = Constants.imagePrefix + this.configResponseModel.companyLogo;
      this.shortImageData = Constants.imagePrefix + this.configResponseModel.companyFavicon;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imageShortUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.shortImageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;

      this.companyService.cfavivonSubject.subscribe((data) => {


        //console.log(data);

        this.shortImageData = Constants.imagePrefix + data;
        this.imageShortUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.shortImageData);
      });

    }

    if (this.webStorageService.getCurrentUserToken() && !this.isMenuLoaded) {

      this.userToken = this.webStorageService.getCurrentUserToken();
      this.userfullname = this.userToken.userFullName;
      this.isAppointments = this.userToken.isAppointments;
      this.isMenuLoaded = true;
    }

    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST' },
    ];
    this.selectedTimeZone = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);

    this.appointment = [
      { name: 'Select', code: '' },
      { name: 'Sales Order', code: 'SalesOrderRequest' },
      { name: 'Appointments', code: 'AppointmentRequest' },
      { name: 'Facility', code: 'FacilityRequest' },
      { name: 'Therapist', code: 'TherapistRequest' },
      { name: 'Doctor', code: 'DoctorRquest' },
      { name: 'CS Operator', code: 'CSOperatorReuqest' },
      { name: 'DME Manager', code: 'DMEAdminRequest' }
    ];
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;
    this.GetRCMConfigurationUrl();
  }

  searchClickEvnet() {
    this.status = !this.status;
  }

  bookAppointmentSearch() {
    const ref = this.dialougeService.open(BookAppoinmentDialogComponent, {
      showHeader: true,
      closable: true,
      data: {
        //id: crmSalesOrderId,
        //isAppointment: false,
        //searchRequestModel: this.searchRequestModel,
        //selectedTab: this.index
      },
    });
  }

  SearchPatientfromBrightree() {
    const ref = this.dialougeService.open(SearchPatientBrightreeComponent, {
      showHeader: true,
      closable: true,
    });
  }


  onLogout() {

    this.featureGroupService.logout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.authenticationService.signOut();
        }
        else
          this.showErrorMessage(result.message);

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  ngOnDestroy(): void {

    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    //if (this.globalSearchService.searchTerm) {
    //  this.globalSearchService.searchTerm.next(null);
    //}

    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;

    this.domainUrl = environment.apiBaseUrlSignalR;
    //Below Code is commnted due to SignalR 
    //const connection = new signalR.HubConnectionBuilder()
    //  .configureLogging(signalR.LogLevel.Information)
    //  .withUrl(this.domainUrl + "notify", {
    //    skipNegotiation: true,
    //    transport: signalR.HttpTransportType.WebSockets 
    //  }) 
    //  .build();

    //var that = this;

    //connection.start().then(function () {
    //  console.log('Connected!');

    //  connection.invoke('getConnectionId')
    //    .then(function (connectionId) {
    //      console.log(connectionId);
    //      that.signalRToken = connectionId;
    //      that.SendSalesOrderNotification2and3Call()
    //    }).catch(err => console.error(err.toString()));


    //}).catch(function (err) {
    //  console.error(err);
    //});

    //connection.on("BroadcastMessage", () => {
    //  console.log('success');
    //  this.getNotifications();
    //});

    await this.getUserBranches();
    //await this.SendSalesOrderNotificationFirstCall();
    //await this.SendSalesOrderNotification2and3Call();
    await this.setUserPermissionforActions();
    await this.getNotifications(true);



    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      let twillioObjectNew = this.webStorageService.getTwilioDeviceObject();
      if (twillioObjectNew) {
        this.isCall = true;
        this.twilioDeviceConnection = twillioObjectNew;
        this.device = twillioObjectNew.device;
        this.connection = twillioObjectNew.connection;
        this.connectionStatus = twillioObjectNew.status;
        this.isCallStarted = twillioObjectNew.isCallinProgress;
        this.voiceCallToNumber = twillioObjectNew.voiceCallToNumber;
        this.voiceMailNumber = twillioObjectNew.voiceMailNumber;
        this.appoinmentDialogueParametersObject = twillioObjectNew.appoinmentDialogueParametersObject;
      }
      else {
        this.isCall = false;
        this.appoinmentDialogueParametersObject = null;
      }

    });


  }
  redirectToPage(url) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url])
    );
  }
  private async getUserBranches() {
    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.branches = [];
        const branchArray = result.responseModel;
        this.webStorageService.storeUserBrances(result.responseModel);
        branchArray.forEach((value: BranchResponseModel) => {
          this.branches.push({ name: value.crmBranchName, code: value.crmBranchId.toString() });
        });
        this.selectedBranchCode = this.webStorageService.getUserBranch();
        if (this.selectedBranchCode != null && this.selectedBranchCode >= 0) {
          let selectedStorageBranch = this.branches.find(x => x.code == this.selectedBranchCode.toString());
          if (selectedStorageBranch)
            this.selectedbranch = this.branches[0];
          else
            this.selectedbranch = this.branches[0];
        }
        else
          this.selectedbranch = this.branches[0];

        this.webStorageService.storeUserBranch(Number(this.selectedbranch.code));
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ChangeBranch() {

    if (this.selectedbranch) {
      //  this.globalSearchService.searchTerm.next(null);
      this.webStorageService.storeUserBranch(Number(this.selectedbranch.code));
      //this.router.navigate([this.currentUrl]);

      //if (this.currentUrl.indexOf('appointments') > -1) {
      //  let searchModel = new GlobalSearchModel();
      //  searchModel.branchId = Number(this.selectedbranch.code);
      //  this.globalSearchService.searchTerm.next(searchModel);
      //  return;
      //}


      if (this.currentUrl.indexOf('appointments') > -1) {

        this.globalSearchService.changeGlobalBranch.next(Number(this.selectedbranch.code));
        return;
      }

      if (this.currentUrl.indexOf('dashboard') > -1) {

        this.globalSearchService.changeGlobalBranch.next(Number(this.selectedbranch.code));
        return;
      }

      if (this.currentUrl.indexOf('calendar') > -1) {
        this.globalSearchService.changeGlobalBranch.next(Number(this.selectedbranch.code));
        return;
      }

      if (this.currentUrl.indexOf('reports') > -1) {
        this.globalSearchService.changeGlobalBranch.next(Number(this.selectedbranch.code));
        return;
      }


      if (this.currentUrl.indexOf('user') > -1 || this.currentUrl.indexOf('doctor') > -1 || this.currentUrl.indexOf('therapist') > -1
        || this.currentUrl.indexOf('facilities') > -1 || this.currentUrl.indexOf('import') > -1 || this.currentUrl.indexOf('settings') > -1
      )
        return;

      this.redirectToPage(this.currentUrl);
    }
  }

  ClickBranch() {
    const selectedBranches = this.webStorageService.getUserBrances();
    if (selectedBranches != null) {
      this.branches = [];
      let check = selectedBranches.find(x => x.crmBranchName == 'All Branches');
      if (!check) {
        this.branches.push({ name: 'All Branches', code: '0' });
      }
      selectedBranches.forEach((value: BranchResponseModel) => {
        this.branches.push({ name: value.crmBranchName, code: value.crmBranchId.toString() });
      });

    }
  }

  private async SendSalesOrderNotificationFirstCall() {
    //this.loading = true;
    //await this.notificationService.SendSalesOrderNotificationFirstCall(this.loggedInUserId)
    //  .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //  .subscribe((result: ResultOfT<any>) => {
    //    this.processResult<any>(result, () => {

    //    });
    //  }, (httpResponseError) => {
    //    this.showErrorMessage(httpResponseError.message);
    //  });
  }

  private async SendSalesOrderNotification2and3Call() {
    this.loading = true;
    //console.log('From method: ' + this.signalRToken);
    await this.notificationService.SendSalesOrderNotification2and3Call(this.loggedInUserId, this.signalRToken)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getNotifications(isRefreshed: boolean) {
    this.unReadCount = 0;
    //return;

    this.loading = true;
    // this.notificationService.getUnReadNotifications(this.loggedInUserId)
    //   .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .subscribe((result: ResultOfT<any>) => {
    //     this.processResult<any>(result, () => {

    //       this.notificationInAppResponseModel = result.responseModel;
    //       this.unReadCount = this.notificationInAppResponseModel.length;
    //     });
    //   }, (httpResponseError) => {
    //     this.showErrorMessage(httpResponseError.message);
    //   });
    let bellNotificationRequestModel = new BellNotificationRequestModel();
    bellNotificationRequestModel.userInfoId = this.loggedInUserId;
    let userPermission: string = '';
    if (this.allowToViewSalesOrderNotifications)
      userPermission = 'SalesOrder,';
    if (this.allowToViewPatientNotifications)
      userPermission += 'Patient,';
    if (this.allowToViewTherapistNotifications)
      userPermission += 'Therapist,';
    if (this.allowToViewAlertNotifications)
      userPermission += 'Alert,';
    userPermission = userPermission.substring(0, userPermission.length - 1);
    bellNotificationRequestModel.userPermissions = userPermission;
    bellNotificationRequestModel.branchId = this.selectedBranchCode;
    bellNotificationRequestModel.isWeekView = true;

    if (userPermission.trim().length > 0) {
      this.notificationService.getBellNotifications(bellNotificationRequestModel, isRefreshed)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<any>) => {
          this.processResult<any>(result, () => {

            this.bellNotificationInAppResponseModel = result.responseModel;
            this.LoadOtherTabsNotifications();
          });
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  LoadOtherTabsNotifications() {
    this.unReadCount = this.bellNotificationInAppResponseModel.reduce((sum, unReadCount) => sum + unReadCount.bellNotificationInAppDetail.filter(x => !x.appIsRead).length, 0);
    this.bellSalesOrderNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'SalesOrder') }
    });
    this.bellPatientNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Patient') }
    });
    this.bellTherapistNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Therapist') }
    });
    this.bellAlertNotificationInAppResponseModel = this.bellNotificationInAppResponseModel.map((element) => {
      return { ...element, bellNotificationInAppDetail: element.bellNotificationInAppDetail.filter((subElement) => subElement.appBellNotificationType == 'Alert') }
    });
  }

  setUserPermissionforActions() {
    this.userPerissionfeatures = this.webStorageService.getUserPermissions();
    if (this.userPerissionfeatures && this.userPerissionfeatures.length > 0) {

      let inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewSyncNotifications);
      if (inPer1) {
        this.allowToViewSalesOrderNotifications = true;
      }

      let inPer2 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewPatientNotifications);
      if (inPer2) {
        this.allowToViewPatientNotifications = true;
      }

      let inPer3 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewTherapistNotifications);
      if (inPer3) {
        this.allowToViewTherapistNotifications = true;
      }

      let inPer4 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToViewAlertNotifications);
      if (inPer4) {
        this.allowToViewAlertNotifications = true;
      }
    }
  }

  getNotificationDate(notificationDate: Date) {
    var notificationDateWithoutTime = moment(notificationDate).format('MM-DD-YYYY');

    var currentDateTime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    var currentDate = new Date(currentDateTime);
    let yesterdayNotificationDateWithoutTime = new Date().setDate(currentDate.getDate() - 1);
    if (notificationDateWithoutTime == (moment(currentDate).format('MM-DD-YYYY'))) {
      return 'Today';
    }
    else if (notificationDateWithoutTime == (moment(yesterdayNotificationDateWithoutTime).format('MM-DD-YYYY'))) {
      return 'Yesterday';
    } else {
      return moment(notificationDate).format('MM/DD/YYYY');
    }
  }

  getNotificationTime(notificationDate: Date) {
    // var notificationDateWithoutTime = moment(notificationDate).format('MM-DD-YYYY');
    // //var currentDate = new Date();

    // var currentDateTime = new DatePipe(this.locale).transform(new Date(), 'MMM dd yyyy' + " HH:mm", this.appDateFormat.timeZone);
    // var currentDate = new Date(currentDateTime);

    // if (notificationDateWithoutTime == (moment(currentDate).format('MM-DD-YYYY'))) {
    //   var diff = currentDate.getTime() - new Date(notificationDate).getTime();
    //   var duration = this.msToTime(diff);
    //   return duration;
    // } else {
    //   return moment(notificationDate).format('MM/DD/YYYY | hh:mm a | ') + this.selectedTimeZone.shortName;
    // }
    return moment(notificationDate).format('MM/DD/YYYY | hh:mm a | ') + this.selectedTimeZone.shortName;
  }

  msToTime(millisec) {
    var seconds = parseInt((millisec / 1000).toFixed(0));
    var minutes = parseInt((millisec / (1000 * 60)).toFixed(0));
    var hours = parseInt((millisec / (1000 * 60 * 60)).toFixed(0));
    //var days = parseInt((millisec / (1000 * 60 * 60 * 24)).toFixed(0));

    if (seconds < 60) {
      return "1 minute ago";
    } else if (minutes < 60) {
      return minutes + " minutes ago";
    } else if (hours < 24) {
      return hours + " hour ago";
    }
  }

  onchange() {
    //do nothing
  }
  redirectToPageWithParams(url, params) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([url], { queryParams: params })
    );
  }
  searchClick() {
    //let searchModel = new GlobalSearchModel();
    //searchModel.searchText = this.searchText;
    //searchModel.searchFor = 'S';    
    //if (this.globalSearchService.searchTerm) {
    //  this.globalSearchService.searchTerm.next(null);
    //}
    //this.globalSearchService.searchTerm.next(searchModel);
    if (this.searchText.trim().length == 0) {
      return;
    }

    this.webStorageService.storeGlobalSearch(this.searchText);
    this.searchText = "";
    this.showClear = false;
    //this.router.navigate(['/admin/search-results/']);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/admin/appointments/search-results/']);
    });
  }

  searchCloseClick() {
    this.searchText = "";
    let searchModel = new GlobalSearchModel();
    searchModel.searchText = "";
    searchModel.searchFor = 'S';
    this.globalSearchService.searchTerm.next(searchModel);
    this.router.navigate(['/admin/appointments/']);
    this.showClear = false;
  }

  searchCancelClick() {
    this.searchText = "";
    let searchModel = new GlobalSearchModel();
    searchModel.searchText = "";
    searchModel.searchFor = 'S';
    this.globalSearchService.searchTerm.next(searchModel);
    this.showClear = false;
  }

  textChange() {
    if (this.searchText && this.searchText != "") {
      this.showClear = true;
    }
    else {
      this.showClear = false;
    }
  }

  ViewAllNotification(event, overlaypanel: OverlayPanel) {
    //if (this.notificationInAppResponseModel.length > 0) {
    this.notificationService.readInAppNotification(this.notificationInAppResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          overlaypanel.hide();
          this.getNotifications(false);
          this.router.navigate(['/admin/notifications']);
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //}


  }

  showCallInfo() {

    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (twillioObject) {
        this.isCall = true;
        this.twilioDeviceConnection = twillioObject;
        this.device = twillioObject.device;
        this.connection = twillioObject.connection;
        this.connectionStatus = twillioObject.status;
        this.isCallStarted = twillioObject.isCallinProgress;
        this.appoinmentDialogueParametersObject = twillioObject.appoinmentDialogueParametersObject;
      }
      else {
        this.isCall = false;
        this.twilioDeviceConnection = null;
      }

    });
  }

  hideCallInfo() {
    this.isCall = false;
    this.twilioDeviceConnection = null;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (!twillioObject) {
        this.isCall = false;
        this.twilioDeviceConnection = null;

      }
    });
    this._cdr.detectChanges();
  }

  showDialogHeader() {
    if (this.appoinmentDialogueParametersObject) {

      const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
        showHeader: false,
        closable: false,
        styleClass: 'book-appt-popup-outer',
        data: {
          crmSalesOrderId: this.appoinmentDialogueParametersObject.crmSalesOrderId,
          isRescheduledRequest: this.appoinmentDialogueParametersObject.isRescheduledRequest,
          isScheduleOrder: this.appoinmentDialogueParametersObject.isScheduleOrder,
          isAppointment: this.appoinmentDialogueParametersObject.isAppointment,
          schAppointmentId: this.appoinmentDialogueParametersObject.schAppointmentId,
          isVoidOrder: this.appoinmentDialogueParametersObject.isVoidOrder,
          isCancelOrder: this.appoinmentDialogueParametersObject.isCancelOrder,
          isCall: this.appoinmentDialogueParametersObject.isCall,
          isAppoinmentBookByCalendar: this.appoinmentDialogueParametersObject.isAppoinmentBookByCalendar,
          crmTherapsitId: this.appoinmentDialogueParametersObject.crmTherapsitId,
          crmTherapsitName: this.appoinmentDialogueParametersObject.crmTherapsitName,
          salesOrderdate: this.appoinmentDialogueParametersObject.salesOrderdate,
          defaultModeofAppointment: this.defaultModeofAppointment,
          isInProgresscall: true,
          connection: this.connection,
          voiceCallToNumber: this.voiceCallToNumber,
          device: this.device,
          voiceMailNumber: this.voiceMailNumber
        },
      });
      ref.onClose.subscribe(() => {

        this.HangUpCall();

      });
    }
    //this.SchedulePopup = true;
    // this.cal = true;
  }

  async HangUpCall() {
    if (this.device) {
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
  }

  headerNotifaction: boolean = false;
  NotifactionclickEvent() {
    this.headerNotifaction = !this.headerNotifaction;
    this.getNotifications(true);
  }

  ViewAllNoticlickEvent() {
    this.headerNotifaction = !this.headerNotifaction;
    this.router.navigate(['/admin/view-all-notifications']);
  }

  displayPatientInfo: boolean = false;
  patientInfoDialog() {
    this.displayPatientInfo = true;
  }

  selectedSalesOrderSyncInfoDialog(selectedSalesOrderSyncRecord: any) {
    this.selectedSalesOrderSyncDetails = selectedSalesOrderSyncRecord;
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  IsNotificationRead(item: any) {
    if (item.appIsRead) {
      return;
    }

    item.appIsRead = true;
    this.LoadOtherTabsNotifications();

    let bellNotificationInAppReadStatusRequestModel = new BellNotificationInAppReadStatusRequestModel();
    bellNotificationInAppReadStatusRequestModel.appBellNotificationInAppId = item.appBellNotificationInAppId;
    bellNotificationInAppReadStatusRequestModel.appIsRead = item.appIsRead;
    bellNotificationInAppReadStatusRequestModel.appUserInfoId = this.loggedInUserId;
    bellNotificationInAppReadStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;

    let bellNotificationInAppReadStatusRequestModelList: BellNotificationInAppReadStatusRequestModel[];
    bellNotificationInAppReadStatusRequestModelList = [];
    bellNotificationInAppReadStatusRequestModelList.push(bellNotificationInAppReadStatusRequestModel);
    this.UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModelList);
  }

  MarkAllNotoficationAsRead() {
    let bellNotificationInAppReadStatusRequestModelList: BellNotificationInAppReadStatusRequestModel[];
    bellNotificationInAppReadStatusRequestModelList = [];

    this.loadingCustom = true;
    this.bellNotificationInAppResponseModel.forEach(element => {
      element.bellNotificationInAppDetail.forEach(item => {
        if (!item.appIsRead) {
          let bellNotificationInAppReadStatusRequestModel = new BellNotificationInAppReadStatusRequestModel();
          bellNotificationInAppReadStatusRequestModel.appBellNotificationInAppId = item.appBellNotificationInAppId;
          item.appIsRead = true;
          bellNotificationInAppReadStatusRequestModel.appIsRead = item.appIsRead;
          bellNotificationInAppReadStatusRequestModel.appUserInfoId = this.loggedInUserId;
          bellNotificationInAppReadStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
          bellNotificationInAppReadStatusRequestModelList.push(bellNotificationInAppReadStatusRequestModel);
        }
      })
    });
    this.loadingCustom = false;

    this.UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModelList);
    this.LoadOtherTabsNotifications();
  }

  async UpdateBellNotificationReadStatus(bellNotificationInAppReadStatusRequestModel: BellNotificationInAppReadStatusRequestModel[]) {
    if (bellNotificationInAppReadStatusRequestModel.length == 0)
      return;

    this.loadingCustom = true;
    this.loading = true;
    await this.notificationService.updateBellNotificationInAppReadStatus(bellNotificationInAppReadStatusRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result) {
            this.loadingCustom = false;
            //this.getNotifications(true);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onPatientNotificationView(item: BellNotificationInAppDetailResponseModel) {
    let schAppointmentId = item.bellNotificationInAppDetailsSummary[0].schAppointmentId;
    let salesOrderId = item.bellNotificationInAppDetailsSummary[0].appSalesOrderId;
    this.headerNotifaction = !this.headerNotifaction;
    this.getNotifications(true);

    this.appointmentService.GetSalesOrderById(Number(salesOrderId), true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderInfoResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              if (result.responseModel.crmSalesorderStatus.toLowerCase() !== Constants.complete
                && result.responseModel.crmSalesorderStatus.toLowerCase() !== Constants.voidreview) {
                let salesOrder = result.responseModel;
                schAppointmentId = salesOrder.schAppointmentId;
                if (schAppointmentId > 0) {
                  let setUrl = '/admin/appointments/detail/' + schAppointmentId + '/notification';
                  this.redirectToPage(setUrl);
                }
                else {
                  let setUrl = '/admin/appointments/salesorders/detail/' + salesOrderId + '/notification';
                  this.redirectToPage(setUrl);
                }
              }
              else {
                this.showErrorMessage("You are not redirected to the Appointment details page because the selected Appointment is either Completed or marked as Void Review.");
              }
            }
          }
          else {
            this.loading = false;
          }
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ResendAlertNotification(item: any) {
    var NotificationType = item.bellNotificationInAppDetailsSummary[0].appNotificationType;
    var NotificationSendId = item.bellNotificationInAppDetailsSummary[0].appNotificationSendId;

    this.notificationService.ResendAlertNotification(NotificationSendId, NotificationType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result) {
            this.loadingCustom = false;
            this.showSuccessMessage('Notification has been sent.');
            this.getNotifications(true);
          }
        });
      }, (httpResponseError) => {
        this.loading = false;
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
