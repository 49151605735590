import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { Subject } from 'rxjs';
import { SettingsService } from '../../../servicies/settings.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-cancel-void-reasons',
  templateUrl: './cancel-void-reasons.component.html',
  styleUrls: ['./cancel-void-reasons.component.sass']
})
export class CancelVoidReasonsComponent extends BaseComponent implements OnInit, OnDestroy {


  title = 'Brightree Settings';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();

  loggedInUserId: number;
  cancellationStatusBtnTitle: string = "Add";
  cancellationEditMode: boolean;
  formCancellationSubmitted: boolean = false;
  eMedAssistCancellation: boolean = false;
  eMedAssistVoid: boolean = false;

  voidBtnTitle: string = "Add";
  voidEditMode: boolean;
  formVoidReasonSubmitted: boolean = false;

  reasonsResponseModel: CancelVoidResponseModel[];
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];

  selectedCancellationModel: CancelVoidResponseModel;
  selectedVoidReasonModel: CancelVoidResponseModel;


  // selectedClassification: SettingsClassificationTypeResponseModel;
  selectedDuration: { title: string, value: number };


  formFields = {
    cancellationReason: 'cancellationReason',
    voidReason: 'voidReason',
  };

  validationMessages = {
    cancellationReason: {
      required: 'Reason is required',
      // validCharacters: 'Enter valid eMedAssist Status'
    },
    voidReason: {
      required: 'Reason is required',
      // validCharacters: 'Enter valid brightree status'
    },
    
  };
  


  constructor(
    private dialogueService: DialogService,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    messageService: MessageService) {
    super(messageService);
   

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }

  get fCancellation() { return (<FormGroup>this.form.get('formCancellation')).controls; }

  get fVoid() { return (<FormGroup>this.form.get('formVoid')).controls; }

  get formCancellation() {
    return this.form.get('formCancellation');
  }

  get formVoid() {
    return this.form.get('formVoid');
  }


  ngOnInit() {
    this.getCanclVoidReasons();
    this.buildForm();
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }


  onCancellationReasonAdd() {

    this.formCancellationSubmitted = true;
    if (this.formCancellation.invalid) {
      this.markFormAsTouched();
      return;
    }
    const wipstatusModel = this.getValuesFromCancellationForm();
    wipstatusModel.modifiedByUserInfoId = this.loggedInUserId;
    wipstatusModel.salesOrderReasonType = 'Cancel';
    const cancellationResons = this.cancellationReasonsResponseModel;
    if (cancellationResons.length > 0) {
      if (this.cancellationEditMode) {
       
        let currentStatus = cancellationResons.find(x => x.salesOrderVoidReason === wipstatusModel.salesOrderVoidReason
          && x.salesOrderVoidReasonId !== this.selectedCancellationModel.salesOrderVoidReasonId);
        if (currentStatus) {
          this.showErrorMessage("Reason mapping for " + wipstatusModel.salesOrderVoidReason + ' already exists.');
          return;
        }
      } else {
        let currentStatus = cancellationResons.find(x => x.salesOrderVoidReason === wipstatusModel.salesOrderVoidReason);
        if (currentStatus) {
          this.showErrorMessage("Reason mapping for " + wipstatusModel.salesOrderVoidReason + ' already exists.');
          return;
        }
      }
    }

    let manageStatus = this.settingsService.addNewCancelVoidReasons(wipstatusModel);
    if (this.cancellationEditMode) {
      wipstatusModel.salesOrderVoidReasonId = this.selectedCancellationModel.salesOrderVoidReasonId;
      manageStatus = this.settingsService.updateCancelVoidReasons(wipstatusModel);
    }

    this.loading = true;

    manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.cancellationEditMode ? 'Cancellation reason has been updated successfully.'
            : 'Cancellation reason has been added successfully.');
          this.selectedCancellationModel = new CancelVoidResponseModel();
          this.cancellationEditMode = false;
          this.resetValuesToCancellationForm();
          // this.getUser(this.userInfoId);
          this.getCanclVoidReasons();
        
          this.eMedAssistCancellation = !this.eMedAssistCancellation;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });


  }

  onCancellationStatusEdit(cancellationModel: CancelVoidResponseModel) {
    // this.eMedAssistCancellation = this.eMedAssistCancellation;
    this.eMedAssistCancellation = true;
    this.cancellationStatusBtnTitle = "Update";
    this.cancellationEditMode = true;
    this.formCancellation.reset();
    this.selectedCancellationModel = cancellationModel;
    this.setValuesToCancellationForm();


  }

  onCancellationDelete(wipStatus: CancelVoidResponseModel) {
    this.resetValuesToCancellationForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete 'Reason ${wipStatus.salesOrderVoidReason}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      
      this.loading = true;
      wipStatus.modifiedByUserInfoId = this.loggedInUserId;
      wipStatus.isActive = false;
      const manageStatus = this.settingsService.activeInactiveReasons(wipStatus);
      manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Cancellation reason has been deleted successfully.');
            this.getCanclVoidReasons();
          
          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  onCancellationCancel() {
   
    this.resetValuesToCancellationForm();
    this.eMedAssistCancellation = !this.eMedAssistCancellation;
  }


  CancellationStatusInfoButton() {
    this.cancellationStatusBtnTitle = "Add";
    this.resetValuesToCancellationForm();
    this.eMedAssistCancellation = !this.eMedAssistCancellation;
  }

  private getValuesFromCancellationForm(): CancelVoidResponseModel {
    const form = this.formCancellation;
    const wipstatus = new CancelVoidResponseModel();
    wipstatus.salesOrderVoidReason = form.get(this.formFields.cancellationReason).value;
   
    return wipstatus;
  }

  private async setValuesToCancellationForm() {
    if (!this.selectedCancellationModel) {
      return;
    }
    const form = this.formCancellation;
    form.get(this.formFields.cancellationReason).setValue(this.selectedCancellationModel.salesOrderVoidReason);
  }

  resetValuesToCancellationForm() {
    const form = this.formCancellation;
    form.get(this.formFields.cancellationReason).setValue('');
    this.formCancellationSubmitted = false;
  }

  onVoidAdd() {
    this.formVoidReasonSubmitted = true;
    if (this.formVoid.invalid) {
      this.markFormAsTouched();
      return;
    }

    const classificationModel = this.getValuesFromVoidForm();
    classificationModel.modifiedByUserInfoId = this.loggedInUserId;
    classificationModel.salesOrderReasonType = 'Void';


    const voidResons = this.voidReasonsResponseModel;
    if (voidResons.length > 0) {
      if (this.voidEditMode) {

        let currentStatus = voidResons.find(x => x.salesOrderVoidReason === classificationModel.salesOrderVoidReason
          && x.salesOrderVoidReasonId !== this.selectedVoidReasonModel.salesOrderVoidReasonId);
        if (currentStatus) {
          this.showErrorMessage("Reason mapping for " + classificationModel.salesOrderVoidReason + ' already exists.');
          return;
        }
      } else {
        let currentStatus = voidResons.find(x => x.salesOrderVoidReason === classificationModel.salesOrderVoidReason);
        if (currentStatus) {
          this.showErrorMessage("Reason mapping for " + classificationModel.salesOrderVoidReason + ' already exists.');
          return;
        }
      }
    }

    let manageClassification = this.settingsService.addNewCancelVoidReasons(classificationModel);
    if (this.voidEditMode) {
      classificationModel.salesOrderVoidReasonId = this.selectedVoidReasonModel.salesOrderVoidReasonId;
      manageClassification = this.settingsService.updateCancelVoidReasons(classificationModel);
    }

    this.loading = true;

    manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.voidEditMode ? 'Void reason has been updated successfully.'
            : 'Void reason has been added successfully.');
          this.selectedVoidReasonModel = new CancelVoidResponseModel();
          this.voidEditMode = false;
          this.resetValuesToVoidReasonForm();
          this.getCanclVoidReasons();
          this.eMedAssistVoid = !this.eMedAssistVoid;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onVoidEdit(classification: CancelVoidResponseModel) {
    const form = this.formVoid;
    this.voidBtnTitle = "Update";
    this.voidEditMode = true;
    this.eMedAssistVoid = true;
    this.formVoid.reset();
    // this.selectedState = this.statsModel.find(x => x.appStateMasterId === license.stateMasterId);
    this.selectedVoidReasonModel = classification;
    

    form.get(this.formFields.voidReason).clearValidators();
  

    this.setValuesToVoidForm();
  }

  onVoidDelete(classification: CancelVoidResponseModel) {
   
    this.resetValuesToVoidReasonForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete 'Reason ${classification.salesOrderVoidReason}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
     
      this.loading = true;
      classification.isActive = false;
      classification.modifiedByUserInfoId = this.loggedInUserId;
      const manageClassification = this.settingsService.activeInactiveReasons(classification);
      manageClassification.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Classification type information has been deleted successfully.');
            this.getCanclVoidReasons();
           
          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  onVoidCancel() {
    this.resetValuesToVoidReasonForm();
    this.eMedAssistVoid = !this.eMedAssistVoid;
  }



  ClassificationInfoButton() {
    this.voidBtnTitle = "Add";
    this.resetValuesToVoidReasonForm();
    this.eMedAssistVoid = !this.eMedAssistVoid;
    
  }

  private getValuesFromVoidForm(): CancelVoidResponseModel {
    const form = this.formVoid;
    const voidReason = new CancelVoidResponseModel();
    voidReason.salesOrderVoidReason = form.get(this.formFields.voidReason).value;
    return voidReason;
  }

  private setValuesToVoidForm() {
    if (!this.selectedVoidReasonModel) {
      return;
    }
    const form = this.formVoid;
    
   
    form.get(this.formFields.voidReason).setValue(this.selectedVoidReasonModel.salesOrderVoidReason);
  }

  resetValuesToVoidReasonForm() {
    const form = this.formVoid;

    form.get(this.formFields.voidReason).setValue('');
    
    this.formVoidReasonSubmitted = false;

    form.get(this.formFields.voidReason).setValidators([Validators.required]);
  }



  private getCanclVoidReasons() {
    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.reasonsResponseModel = result.responseModel;
          const myClonedArray = [];
          this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
          this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
          this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType  === 'Cancel');
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private buildForm() {
    const formCancellationGroup = this.formBuilder.group({});
    
    formCancellationGroup.addControl(this.formFields.cancellationReason, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formVoidGroup = this.formBuilder.group({});

   
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = this.formBuilder.group({
      formVoid: formVoidGroup,
      formCancellation: formCancellationGroup,
    });
  }

}
