import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { PatientService } from '../services/patient.service';
import { SettingsService } from '../services/settings.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';

@Component({
  selector: 'app-cancelled',
  templateUrl: './cancelled.component.html',
  styleUrls: ['./cancelled.component.sass']
})
export class CancelledComponent implements OnInit {
  patientName: string;
  salesORderFromLocal: any;
  appDateFormat: DateFormatModel;
  value: number = 16;
  DMENumber: string;

  patientBookingTypeId: string = '';
  appointmentTitle: string;
  appointmentSelectLabel: string;
  
  constructor(private patientService: PatientService, private appointmentService: AppointmentService,
    private router: Router, messageService: MessageService, private _cdr: ChangeDetectorRef,
    private settingService: SettingsService, private webStorageService: WebStorageService,) { 

      this.appDateFormat = this.webStorageService.getDateFormat()
      this.DMENumber = this.webStorageService.getDMENumber();

      this.patientBookingTypeId=this.webStorageService.getPatientBookingTypeId();

      if(this.patientBookingTypeId=="R"){
        this.appointmentTitle="Remote Setup";
        this.appointmentSelectLabel="Select Remote";
      }
      else if(this.patientBookingTypeId=="T"){
        this.appointmentTitle="Book Therapist";
        this.appointmentSelectLabel="Select Therapist";
      }
      else if(this.patientBookingTypeId=="SG"){
        this.appointmentTitle="Self Guided";
        this.appointmentSelectLabel="Self Guided";
      }
      else if(this.patientBookingTypeId=="F"){
        this.appointmentTitle="Clinic Visit";
        this.appointmentSelectLabel="Select Clinic";
      }

    }

  ngOnInit(): void {
    this.patientName = this.webStorageService.getPatientName();
  }

  bookAppointment() {
    let url = this.webStorageService.getPatientBookingUrl().split('?')[0].split("home/")[1];
    this.router.navigate(["/patient/home/" + url],{ queryParams: { utm_source: 'cancelappointment' } });
  }

}
