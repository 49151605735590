<div class="form-outer">
    <div class="field-brightree">
        <label class="form__label">Update Booking Confirmation details in Brightree</label>
        <!-- <p-inputSwitch [(ngModel)]="isActive" [ngModelOptions]="{standalone: true}" onchange="isActiveChange()">
        </p-inputSwitch>
        <span class="toggle-label">{{isActive ? 'Yes' : 'No'}}</span> *ngFor="let order of ordersFormArray.controls; let i = index"-->
    </div>
    <div *ngIf="form">
        <form class="form checkbox-m" [formGroup]="form" autocomplete="off">
            <div class="col" formArrayName="orders">
                
                    <div class="form-checkbox" *ngFor="let order of ordersFormArray.controls; let i = index" [formGroupName]="i">
                        <p-checkbox name="group7" binary="true" label="" formControlName="isEnable"></p-checkbox>
                        <label class="form__label">{{order.value.displayText}} </label>
                    </div>
                
            </div>
            <div class="btns">
                <button class="btn btn--primary" (click)="submit()">Save</button>
                <button class="btn btn--secondary" (click)="cancel()">Cancel</button>
            </div>
        </form>
    </div>
</div>
<div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
</div>