import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsPluginResponseModel, PluginSMSModel } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsPluginServiceResponseModel } from 'src/app/shared/models/response/settings/settings-plugin-service-response-model';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Device } from '@twilio/voice-sdk';

@Component({
  selector: 'app-voice-call',
  templateUrl: './voice-call.component.html',
  styleUrls: ['./voice-call.component.sass']
})
export class VoiceCallComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsPluginResponseModel: SettingsPluginResponseModel;
  settingsPluginRequestModel: SettingsPluginRequestModel;
  pluginModel: PluginSMSModel;
  loggedInUserId: number;
  pluginServices: SettingsPluginServiceResponseModel[];
  data1: any;
  connection: any;
  device: any;
  callConfigModel: CallConfigModel;
  testNumber: string;
  isTestSMS: boolean;
  IsrecordEncryption: boolean = false;
  IsrecordEncryptionError: boolean = false;
  recordingEncryptionMessage: string = "Recording Encryption is required";
  hideContainerSettingsOptions: boolean = false;
  hideVoiceCallEncryptionOption: boolean = false;
  formFields = {
    service: 'service',
    connectionName: 'connectionName',
    userName: 'userName',
    password: 'password',
    accountSID: 'accountSID',
    apiId: 'apiId',
    phoneNumber: 'phoneNumber',
    callRecording: 'callRecording',
    callEncryption: 'callEncryption',
    containerName: 'containerName',
    containerDirName: 'containerDirName',
    recordingKeyVaultKey: 'recordingKeyVaultKey',
    recordingKeyVaultsecret: 'recordingKeyVaultsecret'
  };

  validationMessages = {
    service: {
      required: 'Service is required',
      //validCharacters: 'Enter valid from email address'
    },
    connectionName: {
      required: 'Connection name is required',
      //validCharacters: 'Enter valid from name'
    },
    userName: {
      required: 'User name is required',
    },
    password: {
      required: 'Password is required',
      //validCharacters: 'Enter valid contact name'
    },
    accountSID: {
      required: 'Account SID is required',
      //validCharacters: 'Enter valid contact name'
    },
    apiId: {
      required: 'API id is required',
      //validCharacters: 'Enter valid contact name'
    },
    phoneNumber: {
      required: 'Phone number is required',
      validPhoneNumber: 'Enter valid phone number'
    },
    callRecording: {
      required: 'callRecording is required',
      //validCharacters: 'Enter valid contact name'
    },
    containerName: {
      required: 'Container name is required',
      //validCharacters: 'Enter valid from email address'
    },
    containerDirName: {
      required: 'Container directory name is required',
      //validCharacters: 'Enter valid from name'
    },
    recordingKeyVaultKey: {
      required: 'Key valult key is required',
    },
    recordingKeyVaultsecret: {
      required: 'Key vault secret key is required',
      //validCharacters: 'Enter valid contact name'
    },
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private http: HttpClient,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getPluginServices();

    await this.getCallConfig();
    await this.getDeviceConnectionForCall();

  }

  async getDeviceConnectionForCall() {
    const deviceOptions = {
      edge: 'ashburn',
      enableRingingState: true,
      fakeLocalDTMF: true,
      debug: true,
      tokenRefreshMs: 3000000
    }


    if (this.callConfigModel != undefined) {
      await this.http.get(this.callConfigModel.twiMLFunctionURL).subscribe((data) => {
        this.data1 = data;
        if (this.data1) {
          this.loadingCustom = false
        }
        const device = new Device(this.data1.token, deviceOptions);
        device.on('ready', function (device) {
          console.log('Twilio.Device Ready!');
        });
        device.on('error', function (error) {
          console.log('Twilio.Device Error: ' + error.message);
        });
        device.on('connect', function (conn) {
          console.log('Successfully established call!');
        });
        device.on('disconnect', function (conn) {
          console.log('Call ended.');
        });
        device.on('ringing', function (conn) {
          console.log('still ringing...');
        });

      });
    }
  }

  private async getCallConfig() {
    this.progress.next(true);
    await this.settingsService.getCallConfig()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<CallConfigModel>(result, () => {
          this.callConfigModel = result.responseModel;
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getPluginServices() {
    this.loading = true;

    this.settingsService.getPluginServices()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.pluginServices = result.responseModel;
          this.pluginServices = this.pluginServices.filter(where => where.pluginName == 'Twilio')
          this.getPluginConfiguration();
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getPluginConfiguration() {
    this.loading = true;

    this.settingsService.getPluginConfiguration(Constants.pluginSettingVoiceCall)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          this.settingsPluginResponseModel = result.responseModel;
          this.setValuesToForm();
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    var applicationPluginRequestModel = this.getValuesFromForm();
    if (String(this.pluginModel.callRecording) == "true") {
      if (this.pluginModel.callEncryption == null) {
        this.IsrecordEncryptionError = true;
        return;
      }
      else {
        this.IsrecordEncryptionError = false;
      }
    }


    const manageApplicationGeneral = this.settingsService.updatePluginConfiguration(applicationPluginRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.getCallConfig();
          this.getDeviceConnectionForCall();
          this.showSuccessMessage('Plugin Voice Call settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): SettingsPluginRequestModel {
    const form = this.form;
    var settingPluginRequestModel = new SettingsPluginRequestModel();

    settingPluginRequestModel = this.settingsPluginResponseModel;

    settingPluginRequestModel.appPluginSettingsDisplayValue = form.get(this.formFields.service).value.pluginName;
    this.pluginModel.twiMLFunctionURL = form.get(this.formFields.connectionName).value;
    this.pluginModel.userName = form.get(this.formFields.userName).value;
    this.pluginModel.password = form.get(this.formFields.password).value;
    this.pluginModel.accountSID = form.get(this.formFields.accountSID).value;
    this.pluginModel.authToken = form.get(this.formFields.apiId).value;
    this.pluginModel.fromNumber = form.get(this.formFields.phoneNumber).value;
    this.pluginModel.callRecording = form.get(this.formFields.callRecording).value;
    this.pluginModel.callEncryption = form.get(this.formFields.callEncryption).value;

    this.pluginModel.voiceContainerName = form.get(this.formFields.containerName).value;
    this.pluginModel.voiceContainerDirName = form.get(this.formFields.containerDirName).value;
    this.pluginModel.voiceRecordingKeyVaultKey = form.get(this.formFields.recordingKeyVaultKey).value;
    this.pluginModel.voiceTwilioRecordingKeyVaultSecret = form.get(this.formFields.recordingKeyVaultsecret).value;

    settingPluginRequestModel.appPluginSettingsValue = JSON.stringify(this.pluginModel);
    settingPluginRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    return settingPluginRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsPluginResponseModel) {
      return;
    }
    this.pluginModel = JSON.parse(this.settingsPluginResponseModel.appPluginSettingsValue);

    const form = this.form;
    var selectedValue = this.pluginServices.find(where => where.pluginName == this.settingsPluginResponseModel.appPluginSettingsDisplayValue);
    form.get(this.formFields.service).setValue(selectedValue);
    form.get(this.formFields.connectionName).setValue(this.pluginModel.twiMLFunctionURL);
    form.get(this.formFields.userName).setValue(this.pluginModel.userName);
    form.get(this.formFields.password).setValue(this.pluginModel.password);
    form.get(this.formFields.accountSID).setValue(this.pluginModel.accountSID);
    form.get(this.formFields.apiId).setValue(this.pluginModel.authToken);
    form.get(this.formFields.phoneNumber).setValue(this.pluginModel.fromNumber);
    form.get(this.formFields.callRecording).setValue(this.pluginModel.callRecording);
    form.get(this.formFields.callEncryption).setValue(this.pluginModel.callEncryption);

    form.get(this.formFields.containerName).setValue(this.pluginModel.voiceContainerName);
    form.get(this.formFields.containerDirName).setValue(this.pluginModel.voiceContainerDirName);
    form.get(this.formFields.recordingKeyVaultKey).setValue(this.pluginModel.voiceRecordingKeyVaultKey);
    form.get(this.formFields.recordingKeyVaultsecret).setValue(this.pluginModel.voiceTwilioRecordingKeyVaultSecret);

    let callRecording = this.pluginModel.callRecording;
    if (String(callRecording) == "true") {
      this.IsrecordEncryption = true;
    }
    else {
      this.IsrecordEncryption = false;
    }
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.service, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.connectionName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.userName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.accountSID, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.apiId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.callRecording, this.formBuilder.control('', { validators: [Validators.required] }));
    formGroup.addControl(this.formFields.callEncryption, this.formBuilder.control(''));

    formGroup.addControl(this.formFields.containerName, this.formBuilder.control(''));
    formGroup.addControl(this.formFields.containerDirName, this.formBuilder.control(''));
    formGroup.addControl(this.formFields.recordingKeyVaultKey, this.formBuilder.control(''));
    formGroup.addControl(this.formFields.recordingKeyVaultsecret, this.formBuilder.control(''));

    this.form = formGroup;
  }

  async Phonecall() {
    const deviceOptions = {
      edge: 'ashburn',
      enableRingingState: true,
      fakeLocalDTMF: true,
      debug: true,
      tokenRefreshMs: 3000000,
    }
    const device = new Device(this.data1.token, deviceOptions);
    this.device = device;
    if (this.device) {
      console.log('connection is null. Initiating the call');
      var params = this.testNumber;
      const call = await device.connect({ params: { To: params, "Record": 'true' } });
      let that = this;
      call.on('ringing', function () {
        console.log('Ringing...');
      });
    }
  }

  async Hangup() {
    console.log('Hanging up...');
    if (this.device) {
      this.device.disconnectAll();
    }
  }

  onClickTest() {
    this.isTestSMS = true;
    this.testNumber = '';
  }

  CancelCall() {
    this.isTestSMS = false;
    this.testNumber = '';
  }

  callRecording(selectValue: boolean) {
    if (selectValue) {
      this.IsrecordEncryption = true;
    }
    else {
      this.IsrecordEncryption = false;
    }
  }

  recordingEncryption(selectValue: boolean) {
  }
}
