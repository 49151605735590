import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { AppointmentService } from '../servicies/appointment.service';
import { HttpClient } from '@angular/common/http';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { ProvisionalSearchRequestModel, SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { ProvisionalsalesorderService } from 'src/app/shared/services/provisionalsalesorder.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProvisionalPatientInfoResponseModel, ProvisionalPatientInforResponseModel } from 'src/app/shared/models/response/appointment/provisional-patient-Info-reponse-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { PatientInfoRequestModel } from 'src/app/shared/models/request/patient-request-model';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { SettingsService } from '../servicies/settings.service';
import { FormsModule } from '@angular/forms'
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component'
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { Constants } from 'src/app/shared/constants';

interface City {
  name: string,
  code: string
}

@Component({
  selector: 'app-search-patient-brightree-detail',
  templateUrl: './search-patient-brightree-detail.component.html',
  styleUrls: ['./search-patient-brightree-detail.component.sass'],
  providers: [DialogService]
})
export class SearchPatientBrightreeDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('searchBox') searchBox: ElementRef;
  private unsubscriber = new Subject<boolean>();
  orderObj: any;
  isGlobalSearch: boolean = false;
  searchText: string;
  patientEmail: string = "";
  patientPhone: string = "";
  doctorPhone: string = "";
  EmailEditStatus: boolean;
  PhoneEditStatus: boolean;
  ConfirmToCreateProvisional: boolean = false;
  provisionalsearchRequestModel: ProvisionalSearchRequestModel;
  searchRequestModel: SearchRequestModel;
  posInfoResponseModel: ProvisionalPatientInfoResponseModel;
  posInfoResponsearr: ProvisionalPatientInforResponseModel[];
  appDateFormat: DateFormatModel;
  private progress = new Subject<boolean>();
  salesOrders: SalesOrderSummaryResponseModel[];
  totalRecords: number;
  totalPages: number;
  patienttotalRecords: number;
  patienttotalPages: number;
  loggedInUserId: number;
  cols: any[];
  //patientCols: any[];
  patientBrighttreeURL: any;
  brightreeInternalUserId: number;
  isAppointmentDisabled: boolean = false;
  selectedfilterIndex = 0;
  salesOrderBrighttreeURL: any;
  currentSelectedNSUserBranch: number;
  timeZoneList: TimeZoneAvailableResponseModel[];
  dataExpressionloading: boolean = false;
  frozencols: any[];
  frozencolsSch: any[];
  private hasPageHistory: boolean = false;
  currentUserBranch: number;
  currentSelectedSUserBranch: number;
  isPatientByName: boolean = false;
  patientMobileNumber: string = "";
  MobileEditStatus: boolean = false;
  branchResponseModel: BranchResponseModel[];
  selectedSbranch: BranchResponseModel;
  selectedClassificationType = new SettingsClassificationTypeResponseModel();
  classificationType: SettingsClassificationTypeResponseModel[];
  orderNote: any = "";
  deliveryNote: any = "";
  ptloading: boolean = false;
  salesOrderId: string;
  salesOrder: SalesOrderInfoResponseModel;
  defaultModeofAppointment: string = "";
  constructor(
    private router: Router,
    private webStorageService: WebStorageService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    messageService: MessageService,
    private posService: ProvisionalsalesorderService,
    private dialougeService: DialogService,
    private userService: UserService,
    private settingsService: SettingsService,
  ) {
    super(messageService);
    this.totalRecords = 0;
    this.totalPages = 1;
    this.loading = false;
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.loggedInUserId = loggedInUser.userId;

    this.getBranches();
    this.getClassificationSettings();
    this.posInfoResponseModel = new ProvisionalPatientInfoResponseModel();
    const provisionalSalesOrderPastSearch = this.webStorageService.getProvisionalSearchItem();
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState.provisionalResponseModel != undefined && currentNavigationState.provisionalResponseModel != null) {
      this.posInfoResponseModel = currentNavigationState.provisionalResponseModel;
    }
    if (currentNavigationState && currentNavigationState.provisionalsearchRequestModel) {
      this.provisionalsearchRequestModel = currentNavigationState.provisionalsearchRequestModel;
      if (this.provisionalsearchRequestModel && !this.provisionalsearchRequestModel.patientID) {
        this.isPatientByName = true;
      }
      this.webStorageService.setProvisionalSearchItem(this.provisionalsearchRequestModel);
      this.hasPageHistory = true;
    }
    else if (provisionalSalesOrderPastSearch) {
      this.provisionalsearchRequestModel = provisionalSalesOrderPastSearch;
      if (this.provisionalsearchRequestModel && !this.provisionalsearchRequestModel.patientID) {
        this.isPatientByName = true;
      }
      this.hasPageHistory = true;
    }
    this.initializeSearchRequestModel();
    this.cols = [

      { freezCoulmn: true, field: 'crmSalesOrderAPPId', header: 'S/O ID', active: true, fixedColumn: true, visible: true },
      { freezCoulmn: true, field: 'crmPatientName', header: 'Patient Details', active: true, fixedColumn: true, visible: true, sortFieldName: 'crmPatientName' },
      { freezCoulmn: true, fieldNo: 9, visible: true, field: 'crmClassification', header: 'Classification Type', active: true, fixedColumn: false, sortFieldName: 'crmClassification' },
      { freezCoulmn: true, fieldNo: 10, visible: true, field: 'crmSalesorderStatus', header: 'Status', active: true, fixedColumn: true, sortFieldName: 'crmSalesorderStatus' },
      { freezCoulmn: false, fieldNo: 10, visible: true, field: 'crmIsCallBack', header: 'Current Status', active: true, fixedColumn: true, sortFieldName: 'crmIsCallBack' },
      { freezCoulmn: false, fieldNo: 2, field: 'crmDoctorName', sortFieldName: 'CrmDoctorName', header: 'Doctor', visible: true, fixedColumn: false, style: 'width:8em', type: 'string', },
      { freezCoulmn: false, fieldNo: 6, visible: true, field: 'crmBranch', header: 'Branch', active: true, fixedColumn: false, sortFieldName: 'crmBranch' },
      { freezCoulmn: false, fieldNo: 7, visible: true, field: 'dateTimeCreated', header: 'Entry Date', active: true, fixedColumn: false, sortFieldName: 'DateTimeCreated' }
    ];

    this.timeZoneList = this.webStorageService.getTimeZoneList();
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.currentSelectedSUserBranch = this.currentUserBranch;
  }

  async ngOnInit() {
    //await this.getDefaultModeofAppointment();
    if (this.provisionalsearchRequestModel && this.provisionalsearchRequestModel.patientID) {
      if (this.posInfoResponseModel != null && this.posInfoResponseModel.brightreeID != 0 && this.posInfoResponseModel.brightreeID != undefined) {
        this.setPatientInfo();
      }
      else {
        await this.getPatientDetailById(this.provisionalsearchRequestModel.patientID);
      }
      this.getsalesorders();
    }
    else {
      if (this.provisionalsearchRequestModel) {
        this.provisionalsearchRequestModel.FirstRecordIndex = 0;
        this.provisionalsearchRequestModel.CurrentPageIndex = 0;
        this.provisionalsearchRequestModel.PageSize = +this.pageSizes[0];
        await this.getPatientDetailByName(this.provisionalsearchRequestModel);
      }
    }
  }

  async getPatientDetailById(patientId: number) {
    this.loading = true;
    this.provisionalsearchRequestModel.patientID = patientId;
    await this.posService.getPatientbyId(patientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ProvisionalPatientInfoResponseModel>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.posInfoResponseModel = result.responseModel;
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientEmail = this.posInfoResponseModel.billingContactInfo.emailAddress;
            }
            else {
              this.patientEmail = "-";
            }
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientPhone = this.posInfoResponseModel.billingContactInfo.phoneNumber;
            }
            else {
              this.patientPhone = "-";
            }
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientMobileNumber = this.posInfoResponseModel.billingContactInfo.mobilePhoneNumber;
            }
            else {
              this.patientMobileNumber = "-";
            }
            if (this.posInfoResponseModel.doctorInfo) {
              this.doctorPhone = this.posInfoResponseModel.doctorInfo.phone;
            }
            else {
              this.doctorPhone = "-"
            }
            this.loading = false;
            this.isPatientByName = false;
          }
          else {
            this.showWarningMessage(result.message);
          }
        })
      });
  }

  async getPatientDetailByBrightreeId(BrightreeID: number) {
    this.loading = true;
    this.provisionalsearchRequestModel.patientID = BrightreeID;
    await this.posService.GetPatientInfoByBrightreeID(BrightreeID)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ProvisionalPatientInfoResponseModel>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.posInfoResponseModel = result.responseModel;
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientEmail = this.posInfoResponseModel.billingContactInfo.emailAddress;
            }
            else {
              this.patientEmail = "-";
            }
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientPhone = this.posInfoResponseModel.billingContactInfo.phoneNumber;
            }
            else {
              this.patientPhone = "";
            }
            if (this.posInfoResponseModel.billingContactInfo) {
              this.patientMobileNumber = this.posInfoResponseModel.billingContactInfo.mobilePhoneNumber;
            }
            else {
              this.patientMobileNumber = "";
            }
            if (this.posInfoResponseModel.doctorInfo) {
              this.doctorPhone = this.posInfoResponseModel.doctorInfo.phone;
            }
            else {
              this.doctorPhone = ""
            }
            this.loading = false;
            this.isPatientByName = false;
          }
          else {
            this.showWarningMessage(result.message);
          }
        })
      });
  }

  async getPatientDetailByName(provisionalsearchRequestModel) {
    this.loading = true;
    this.posService.getPatientbyName(provisionalsearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ProvisionalPatientInforResponseModel>>) => {
        this.processResult<PagedResponseModel<ProvisionalPatientInforResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                this.posInfoResponsearr = pagedResponse.results;
                this.patienttotalRecords = pagedResponse.totalRecords;
                this.patienttotalPages = pagedResponse.totalPages;
                this.isPatientByName = true;
                if (!this.searchRequestModel.PageSize)
                  this.provisionalsearchRequestModel.PageSize = pagedResponse.totalRecords;
              }
              this.loading = false;
            }
          }
          else {
            this.loading = false;
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  setPatientInfo() {
    if (this.posInfoResponseModel.billingContactInfo) {
      this.patientEmail = this.posInfoResponseModel.billingContactInfo.emailAddress;
    }
    else {
      this.patientEmail = "-";
    }
    if (this.posInfoResponseModel.billingContactInfo) {
      this.patientPhone = this.posInfoResponseModel.billingContactInfo.phoneNumber;
    }
    else {
      this.patientPhone = "-";
    }
    if (this.posInfoResponseModel.billingContactInfo) {
      this.patientMobileNumber = this.posInfoResponseModel.billingContactInfo.mobilePhoneNumber;
    }
    else {
      this.patientMobileNumber = "-";
    }
    if (this.posInfoResponseModel.doctorInfo) {
      this.doctorPhone = this.posInfoResponseModel.doctorInfo.phone;
    }
    else {
      this.doctorPhone = "-"
    }
    this.isPatientByName = false;
  }

  EditEamil() {
    this.EmailEditStatus = !this.EmailEditStatus;
  }
  cancelEmail() {
    this.EmailEditStatus = !this.EmailEditStatus;
  }

  saveEamil() {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

    if (this.patientEmail && !TEXT_REGEXP.test(this.patientEmail)) {
      this.showWarningMessage('Please add proper email address.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Email?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.provisionalsearchRequestModel.patientID.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Email updated successfully');
            this.EmailEditStatus = !this.EmailEditStatus;
            //this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            //this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            //this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });

  }


  EditPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;
  }

  cancelPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;
  }

  savePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    if (this.patientPhone.length < 10 || !TEXT_REGEXP.test(this.patientPhone)) {
      this.showWarningMessage('Please add valid phone number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update Phone Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      if (this.patientPhone.indexOf('-') > -1) {
        this.patientPhone = this.patientPhone.split('-').join('');
      } else {
        this.patientPhone = this.patientPhone;
      }
      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.provisionalsearchRequestModel.patientID.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('Phone updated successfully')
            this.PhoneEditStatus = !this.PhoneEditStatus;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result && result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your email. Please contact administrator");
            return;
          }
        });
    });
  }


  CreateProvisionalDialog() {

    this.ConfirmToCreateProvisional = true;

  }

  YesConfirmBookAppointment() {
    this.posInfoResponseModel.branchName = this.selectedSbranch.crmBranchName;
    this.posInfoResponseModel.crmClassificaitonduration = this.selectedClassificationType.crmClassificaitonduration;
    this.posInfoResponseModel.crmSalesorderClassification = this.selectedClassificationType.crmClassificationDisplayName;
    this.posInfoResponseModel.crmSalesorderClassificationType = this.selectedClassificationType.crmClassificationTypeId;
    this.posInfoResponseModel.orderNote = this.orderNote;
    this.posInfoResponseModel.deliveryNote = this.deliveryNote;
    this.loading = true;
    this.posService.CreateProvisionalSalesOrder(this.posInfoResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<string>) => {
        this.processResult<string>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.salesOrderId = result.responseModel;
            this.showSuccessMessage('Sales order created successfully.');
            this.showScheduleAppointment();
          }
          this.loading = false;
        });

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
    this.ConfirmToCreateProvisional = false;
  }

  showScheduleAppointment() {
    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: false,
      closable: false,
      styleClass: 'book-appt-popup-outer',

      data: {
        crmSalesOrderId: this.salesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: false,
        isCancelOrder: false,
        isAppointmentSalesOrderDetailPage: true,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },

    });

    ref.onClose.subscribe((crmIsAppoinment: boolean) => {
      this.getsalesorders();
      this.router.navigate(['admin/appointments/patients-detail'], { state: { provisionalsearchRequestModel: this.provisionalsearchRequestModel } })
    });
  }

  private getsalesorders() {

    this.dataExpressionloading = true;
    this.loading = true;
    //this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = this.selectedStatusStr;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.crmPatientId = this.provisionalsearchRequestModel.patientID;
    this.posService.GetProvisionalSalesOrders(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;
                this.searchRequestModel.totalRecords = this.totalRecords;
                this.hasPageHistory = false;
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;

              }
            }
          }
          this.loading = false;
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });

  }

  private initializeSearchRequestModel() {
    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
    this.searchRequestModel.crmPatientId = this.provisionalsearchRequestModel ? this.provisionalsearchRequestModel.patientID : 0;
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);

    window.open(patienKeyUrl, '_blank')
  }

  onView(salesOrderId: string, salesorderStatus: string, schAppointmentId: number) {
    if (schAppointmentId > 0) {
      let setUrl = '/admin/appointments/detail/' + schAppointmentId + '/pos';
      this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel, selectedTab: 0, selectedfilterIndex: this.selectedfilterIndex } });
    }
    else {
      let setUrl = '/admin/appointments/salesorders/detail/' + salesOrderId + '/pos';
      this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel, selectedTab: 0, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });
    }

  }

  onViewByPatient(patinetID: number) {

  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory && !this.isPatientByName) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    // else {
    //   this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
    // }
    this.getsalesorders();
  }
  onPatientLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {

      this.provisionalsearchRequestModel.FirstRecordIndex = event.first;
      this.provisionalsearchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.provisionalsearchRequestModel.PageSize = 20;
      //this.provisionalsearchRequestModel.SortByColumnName = event.sortField;
      //this.provisionalsearchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      //this.provisionalsearchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    else {
      this.provisionalsearchRequestModel.CurrentPageIndex = event.first / event.rows;
    }

    this.getPatientDetailByName(this.provisionalsearchRequestModel);
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  getTotalRows() {
    if (this.searchRequestModel != null && this.searchRequestModel != undefined) {
      return this.searchRequestModel.PageSize;
    }
    return this.totalRecords;
  }

  getPatientSearchTotalRows() {
    if (this.provisionalsearchRequestModel != null && this.provisionalsearchRequestModel != undefined) {
      return this.provisionalsearchRequestModel.PageSize;
    }
    return this.patienttotalRecords;
  }

  onPatientView(brightreeID: number) {
    this.getPatientDetailByBrightreeId(brightreeID);
  }

  EditMobilePhone() {
    this.MobileEditStatus = !this.MobileEditStatus;

  }

  saveMobilePhone() {
    const TEXT_REGEXP = new RegExp(/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/);
    //if (!this.patientMobileNumber) {
    //  this.showWarningMessage('Please provide mobile number');
    //  return;
    //}
    if (this.patientMobileNumber.length < 10 || !TEXT_REGEXP.test(this.patientMobileNumber)) {
      this.showWarningMessage('Please provide valid mobile number.');
      return;
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to update mobile Number?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      if (this.patientMobileNumber.indexOf('-') > -1) {
        this.patientMobileNumber = this.patientMobileNumber.split('-').join('');
      } else {
        this.patientMobileNumber = this.patientMobileNumber;
      }

      let patientInfoRequestModel = new PatientInfoRequestModel();
      patientInfoRequestModel.patientid = this.provisionalsearchRequestModel.patientID.toString();
      patientInfoRequestModel.email = this.patientEmail;
      patientInfoRequestModel.phone = this.patientPhone;
      patientInfoRequestModel.mobilephone = this.patientMobileNumber;
      patientInfoRequestModel.crmSalesorderId = this.salesOrder.crmSalesorderId;
      this.loading = true;
      this.appointmentService.updatePatientInformation(patientInfoRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Mobile Number updated successfully')
            this.MobileEditStatus = !this.MobileEditStatus;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientEmailAddress = this.patientEmail;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientPhoneNo = this.patientPhone;
            // this.appointment.salesOrderInfo.patientInfo.crmPatientMobileNumber = this.patientMobileNumber;
          }
          else {
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem updating your mobile Number. Please contact administrator");
            return;
          }
        });
    });

  }

  cancelMobilePhone() {
    this.MobileEditStatus = !this.MobileEditStatus;
  }

  private async getBranches() {
    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel.filter(x => x.crmBranchName !== 'All Branches');
            let selectedBranch = this.branchResponseModel.find(x => x.crmBranchName == this.posInfoResponseModel.branchName);
            if (selectedBranch) {
              //this.selectedNSbranch = selectedBranch;
              this.selectedSbranch = selectedBranch;
            }
            else {
              //this.selectedNSbranch = this.branchResponseModel[0];
              this.selectedSbranch = this.branchResponseModel[0];;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getClassificationSettings() {
    this.loading = true;
    this.settingsService.GetClassificationTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.classificationType = result.responseModel.filter(x => x.crmClassificationDisplayName !== null && x.crmClassificationType != null);
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onClassificationTypeChange(event) {
    this.selectedClassificationType = event.value;
  }

  onBranchSChange(event) {
    if (event.value) {
      this.selectedSbranch = event.value;
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    this.webStorageService.deleteProvisionalSearchItem();
  }

  

}
