<div class="cd-popup appt-massages-outer" role="alert">
  <div *ngIf="!isRestrctedMessageOnly && dynamicDialogConfig?.data?.action != 'Alert'">
    <div class="cd-popup-container text-center appt-massages-block">
      <h2 class="appt-massages-block__title-box">
        <!-- <svg class="alert-icon" *ngIf="dynamicDialogConfig?.data?.popupHeader == 'Alert'" width="26" height="22"
              viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0 22.0003L12.5295 0.358398L25.0591 22.0003H0ZM3.92972 19.7222H21.1294L12.5295 4.9146L3.92972 19.7222ZM12.5295 18.5832C12.8523 18.5832 13.123 18.4738 13.3417 18.2551C13.5604 18.0364 13.6693 17.7661 13.6686 17.4441C13.6686 17.1214 13.5592 16.8511 13.3405 16.6331C13.1218 16.4152 12.8515 16.3058 12.5295 16.3051C12.2068 16.3051 11.9365 16.4144 11.7185 16.6331C11.5006 16.8518 11.3913 17.1222 11.3905 17.4441C11.3905 17.7669 11.4998 18.0376 11.7185 18.2563C11.9372 18.475 12.2076 18.5839 12.5295 18.5832ZM11.3905 15.166H13.6686V9.47079H11.3905V15.166Z"
                  fill="#525252" />
              </svg> -->
        {{popupHeader}}

      </h2>
      <div class="appt-massages-block__content-box">
        <p>{{message}}
          <strong>{{dynamicDialogConfig.data.action}}</strong>.
        </p>

        <!-- <form class="form"> -->
        <div class="schedulecall">
          <div *ngIf="dynamicDialogConfig.data.action != 'No Show' && dynamicDialogConfig.data.action !== 'Complete'">
            <div class="col">
              <div class="form__group full-width">
                <label class="form__label">Reason</label>
                <p-dropdown class="select-box" [options]="reasonsResponseModel" [(ngModel)]="reason"
                  placeholder="Select Reason" optionLabel="salesOrderVoidReason" [showClear]="true"></p-dropdown>
                <span class="invalid-feedback-error" *ngIf="(formSubmitted && reason == undefined)"
                  style="color:red">{{validationMessages.reason.required}}</span>
              </div>
            </div>
            <div class="col">
              <div class="form__group full-width">
                <label class="form__label">Comment</label>
                <textarea class="form__textarea" pInputTextarea [(ngModel)]="reasonComment" maxlength="100"></textarea>
                <span class="invalid-feedback-error" *ngIf="(formSubmitted && reasonComment == '')"
                  style="color:red">{{validationMessages.reasonComment.required}}</span>
              </div>
            </div>
            <div class="col">
              <div class="check receive-reminder wip-state-will-not-update">
                <p-checkbox name="groupnamecomplete"
                  label="If the check box is selected, the WIP state will not be updated in Brightree."
                  [(ngModel)]="isBrightreeSystemAppUpdate" [ngModelOptions]="{standalone: true}" binary="true">
                </p-checkbox>
              </div>
            </div>
            <p style="padding-top: 2rem;font-weight: 500;">Would you like to proceed?</p>
          </div>
          <!-- </form> -->

          <!-- <p>If the check box is selected, the WIP state will not be updated in Brightree. Would you like to proceed?</p> -->
        </div>

        <div class="cd-buttons">
          <button class="btn btn--secondary mr-2"
            (click)="onCancel()">{{dynamicDialogConfig.data.cancelButtonText}}</button>
          <button class="btn btn--primary mr-2"
            (click)="onSubmit()">{{dynamicDialogConfig.data.updateButtonText}}</button>
        </div>
      </div>
    </div>
  </div>
  <!--to displa restricted message only for No show and Complete -->
  <div *ngIf="isRestrctedMessageOnly && dynamicDialogConfig?.data?.action != 'Alert'">
    <div class="cd-popup-container text-center appt-massages-block">
      <h2 class="appt-massages-block__title-box">

        {{popupHeader}}

      </h2>
      <div class="appt-massages-block__content-box">
        <p>You have selected ({{totalSelectedItem}}) appointment(s), yet ({{restrictedApptForStatus?.length}}) of them
          do
          not qualify for the <strong>{{action}}</strong> status as their appointment times have not arrived.</p>

      </div>

      <div class="cd-buttons">
        <button class="btn btn--primary mr-2"
          (click)="onCancel()">{{dynamicDialogConfig.data.cancelButtonText}}</button>
      </div>
    </div>
  </div>


  <div class="cd-popup-container text-center appt-massages-block"
    *ngIf="dynamicDialogConfig?.data?.action == 'Alert' && !dynamicDialogConfig?.data?.IsAllowToComplete">
    <h2 class="appt-massages-block__title-box">
      <svg class="alert-icon" width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 22.0003L12.5295 0.358398L25.0591 22.0003H0ZM3.92972 19.7222H21.1294L12.5295 4.9146L3.92972 19.7222ZM12.5295 18.5832C12.8523 18.5832 13.123 18.4738 13.3417 18.2551C13.5604 18.0364 13.6693 17.7661 13.6686 17.4441C13.6686 17.1214 13.5592 16.8511 13.3405 16.6331C13.1218 16.4152 12.8515 16.3058 12.5295 16.3051C12.2068 16.3051 11.9365 16.4144 11.7185 16.6331C11.5006 16.8518 11.3913 17.1222 11.3905 17.4441C11.3905 17.7669 11.4998 18.0376 11.7185 18.2563C11.9372 18.475 12.2076 18.5839 12.5295 18.5832ZM11.3905 15.166H13.6686V9.47079H11.3905V15.166Z"
          fill="#525252" />
      </svg>
      {{dynamicDialogConfig?.data?.popupHeader}}
    </h2>
    <div class="appt-massages-block__content-box">
      <p>{{dynamicDialogConfig?.data?.message}}</p>
    </div>

    <div class="cd-buttons">
      <button class="btn btn--primary mr-2" (click)="onCancel()">Okay</button>
    </div>
  </div>
</div>