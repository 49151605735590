import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Inject, LOCALE_ID, HostListener, ViewChild, AfterViewInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';
import { endOfDay, addMonths } from 'date-fns';
import * as moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageService, SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DAYS_IN_WEEK, SchedulerViewDay, SchedulerViewHour, SchedulerViewHourSegment, CalendarSchedulerEvent, CalendarSchedulerEventAction, startOfPeriod, endOfPeriod, addPeriod, subPeriod, SchedulerDateFormatter, SchedulerEventTimesChangedEvent, CalendarSchedulerViewComponent, CalendarSchedulerEventStatus } from 'angular-calendar-scheduler';
import { CalendarDateFormatter, CalendarEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { TheraistAppointmentService } from 'src/app/therapist/services/theraistappointment.service';
import { ExportAppointmentCount, SchedulerMinMaxResponseModel, SchedulerResponseModel, SchedulerResponseModelForExport, SchedulerResponseModelForExportFinal } from '../../../shared/models/response/therapist/therapist-appointments-response-model';
import { map, takeUntil, finalize } from 'rxjs/operators';
import { AppointmentInfoReponseModel, TherapistCustomDatesResponseModel, TherapistEditBlockResponseModel } from '../../../shared/models/response/appointment/appointment-info-reponse-model';
import { WIPStatesResponseModel } from '../../../shared/models/response/appointment/wip-states-response-model';
import { Constants } from '../../../shared/constants';
import { RCMCofigurationUrl } from '../../../shared/models/response/settings/settings-plugin-response-model';
import { ResultOfT } from '../../../shared/models/response/result';
import { FeatureGroupService } from '../../../shared/services/featuregroup.service';
import { AppointmentUpdateStatusRequestModel } from '../../../shared/models/response/appointment/appointment-updatestatus-request-model';
import { BTSalesOrderUpdateRequestModel } from '../../../shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from '../../../shared/models/common/wip-status';
import { QuickFilters } from '../../../shared/models/common/custom-filiters';
import { DomSanitizer, SafeResourceUrl, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { EventAction } from 'calendar-utils';
import { Accordion } from 'primeng/accordion';
import { TimezonePipe } from '../../../pipes/timezone.pipe';
import { AdminCustomSettingsResponseModel, SettingsResponseModel } from '../../../shared/models/response/appointment/settings-response';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';
import { FacilityService } from '../../servicies/facility.service';
import { AppointmentService } from '../../servicies/appointment.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { NearByPatientResponseModel } from 'src/app/shared/models/response/appointment/nearby-patient-response-model';
import { User } from '../therapist-day-view-scheduler/day-view-scheduler.component';
import { BookAppointmentInfoComponent } from '../../book-appointment-info/book-appointment-info.component';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { TwillioCallService } from '../../servicies/twillioCall.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { isSameDay, isSameMonth, isBefore, isAfter } from 'date-fns';
import { SettingsService } from '../../servicies/settings.service';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { TherapistService } from '../../servicies/therapist.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { FilterClassificationTypeResponseModel } from 'src/app/shared/models/response/appointment/filter-classification-response-model';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  //blue: {
  //  primary: '#1e90ff',
  //  secondary: '#D1E8FF',
  //},
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  purple: {
    primary: '#e3deff',
    secondary: '#e3deff',
  },
  blue: {
    primary: '#EDEAFF',
    secondary: '#EDEAFF',
  },
  grey: {
    primary: '#D0F6DB',
    secondary: '#D0F6DB',
  },
  lightGrey: {
    primary: '#dbdbdb',
    secondary: '#dbdbdb',
  },
  darkGrey: {
    primary: '#cbcbcb',
    secondary: '#cbcbcb',
  },
  transparent: {
    primary: '#ffffff00',
    secondary: '#ffffff00',
  },
  orange: {
    primary: '#F9F9F9',
    secondary: '#F9F9F9',
  },
  blokSlot: {
    primary: '#d1e8ff',
    secondary: '#d1e8ff',
  },
};

@Component({
  selector: 'mwl-weekdemo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './template.html',
  styleUrls: ['./week-view-scheduler.component.sass'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: SchedulerDateFormatter
    },
    DialogService]
})

export class WeekViewTherapistComponent extends BaseComponent implements OnInit {
  schedulerList: SchedulerResponseModel[];
  appointmentInfoReponseModel: SchedulerResponseModel;
  appointment: AppointmentInfoReponseModel;
  duration: string;
  comments: string;
  wipStatus: any;
  wipStatesId: number;
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderStatusCurrent: WIPStatesResponseModel[];
  salesOrderType: string = 'AppointmentRequest';
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  brightreeInternalUserId: number;
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  _patientTimezone: string = '';

  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;
  viewDate: Date = new Date();
  viewDays: number = DAYS_IN_WEEK;
  refresh: Subject<any> = new Subject();
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  locale: string = 'en';
  hourSegments: number = 4;
  weekStartsOn: number = 0;
  weekStartsOn1: number = DAYS_OF_WEEK.SUNDAY;
  startsWithToday: boolean = false;
  activeDayIsOpen: boolean = true;
  excludeDays: number[] = [];
  weekendDays: number[] = [0, 6];
  dayStartHour: number = 7;
  dayEndHour: number = 19;
  selectedAppointments: string[];

  minDate: Date = new Date();
  maxDate: Date = endOfDay(addMonths(new Date(), 1));

  dayModifier: Function;
  hourModifier: Function;
  segmentModifier: Function;
  eventModifier: Function;

  prevBtnDisabled: boolean = true;
  nextBtnDisabled: boolean = true;

  therapistId: number;
  therapistName: string;
  appDateFormat: DateFormatModel;
  selectedDayViewDate: Date;
  en: any;
  currentSelectedView: any;
  dateFormat: string = '';
  primeFormat: string = '';
  timeZone: string = '';
  Customloading: boolean = false;
  isShowModel: boolean = false;
  isShowStatusDropdown: boolean = false;
  canStatusIsUpate: boolean = false;
  selectedcalendarActions: QuickFilters;
  calendarActions: QuickFilters[];
  twoFacilityPopup: boolean = false;
  activeState: boolean[] = [true, false, false, false];
  facilityTimeZone: string = '';
  isBrightreeSystemAppUpdate: Boolean = true;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  selectedTherapists: TherapistResponseModel;
  selectedbranches: BranchResponseModel[];
  selectedAppointmentFacilities: FacilityInfoResponseModel[];
  therapistResponseModel: TherapistResponseModel[];
  crmselectedTimeZone: string = 'UTC-5';
  isShowHomeVisit: boolean = false;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  facilityResponseModel: FacilityInfoResponseModel[];
  selectedFacilities: FacilityInfoResponseModel[];
  isBlankMessageforTherapistShow: boolean = false;
  isBranchChange: boolean = false;
  redirectedFacilityIdNumber: number;
  selectedFacility: FacilityInfoResponseModel;
  pageTitle: string;
  facilityId: number;
  isBlankMessageshow: boolean = false;
  currentUserBranch: number;
  selectedApptType: string = '';
  fillSlotPopup: boolean = false;
  isFacilityselected: boolean = false;
  searchedFacilityid: number = 0;
  facilityName: string;
  remoteFacilityName: string;
  isRemoteOnly: boolean = false;
  IsRemoteOrFacility: number = 0;
  searchDate: Date;
  currentDate: Date;
  isRemoteAndFacility: boolean = false;
  remoteFacilityId: number;
  isStartFromDefaultFacility: boolean;
  isStartFromHomeAddress: boolean;
  selectedFacilityTimeZone: string;
  nearByPatients: NearByPatientResponseModel[];
  origionalNearByPatients: NearByPatientResponseModel[];
  selectedFilSlotDateTime: Date;
  isRightPanelEnabled: boolean = false;
  isRightPanelBlockEnabled: boolean = false;
  selectUser: User;
  searchText: string = "";
  searchUser: User;
  showClear: boolean = false;
  isBillingAddress: boolean = false;
  blockSlotPopup: boolean;
  isBlock: boolean;
  availId: number;
  therapistCustomAvailabilityId: number;
  labelFor: string;
  therapisId: number;
  selectedtherapist: string;
  customStartTime: string;
  customEndTime: string;
  selectedAppointment: AppointmentInfoReponseModel;
  defaultModeofAppointment: string = "";
  maxDateToDisplay: Date;
  isDisabledReassignment: boolean = true;
  selectFacilityString: string = "0";
  selectBranchString: string = "0";
  selectTherapistString: string = "0";
  events$: Observable<CalendarEvent<{ appointmentInfoReponseModel: SchedulerResponseModel }>[]>;
  users: User[];
  selectedUser: User;
  isHoliday: boolean = false;
  holidayMessage: string;
  ESTTherapistCount: number = 0;
  CSTTherapistCount: number = 0;
  MSTTherapistCount: number = 0;
  PSTTherapistCount: number = 0;
  AMSTTherapistCount: number = 0;
  timeZoneShortCode: string = 'UTC-5';
  timeZoneCodeTherapistCount: number = 0;
  dayStartMinute: number = 0;
  dayEndMinute: number = 59;
  dayEnd: number;
  dayStart: number;
  isChangeRemoteAppointmentType: boolean = false;
  convertedRemoteAppointmentType: string;
  remoteAppointmentTypeResponseModel: Array<RemoteAppointmentTypeModel>;
  selectedAppointmentStatus: SelectItem;
  selectedAppointmentValues: string[] = [];
  blockDesc: string;
  isButtonDisabled: boolean = false;
  isRoadHomeAppt: boolean = false;
  loggedInUserId: number;
  loadingBlockSlotBrakHours: boolean = false;
  daysToShow: number;
  weekStartDate: string;
  pdfWeekStartDate:string;
  pdfWeekEndDate:string;
  weekEndDate: string;
  currentYear: string;
  isNextButtonDisable: boolean = false;
  previousDate: string;
  exportDataRes: SchedulerResponseModelForExport[];



  isNewManageAvailability: boolean = false;

  isAllClssificationFilterSelected: boolean = true;
  isAllBranchFilterSelected: boolean = true;
  selectedFilterBranches: BranchResponseModel[] = [];
  filterClassificationList: FilterClassificationTypeResponseModel[];
  selectedClassificationType: FilterClassificationTypeResponseModel[] = [];
  isFilterSubmit: boolean = false;

  exportAppointmentCnt: ExportAppointmentCount[];



  myOptions = {
    'placement': 'right',
    'show-delay': 500
  };

  actions: EventAction[] = [
    {
      label: '<span class="valign-center edit-btn-block"><i class="edit-icon-new"></i></span>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //this.editEvent(Number(event.id));
      },
    }
  ];

  events: CalendarEvent[];

  @ViewChild('calendarScheduler', { static: false }) calendarScheduler: CalendarSchedulerViewComponent;
  @ViewChild('accordion') accordion: Accordion;

  timeZoneList: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string }[];
  selectedTimeZone: { name: string; code: string; shortName: string; orgcode: string; displayName: string; countryName: string };
  slotTimeZone: { name: string; code: string; shortName: string; displayName: string; countryName: string };

  constructor(
    protected cdr: ChangeDetectorRef,
    private webStorageService: WebStorageService,
    private router: Router,
    messageService: MessageService,
    private titleService: Title,
    private dialougeService: DialogService,
    private theraistAppointmentService: TheraistAppointmentService,
    @Inject(LOCALE_ID) locale: string,
    private sanitizer: DomSanitizer,
    private featureGroupService: FeatureGroupService,
    private facilityService: FacilityService,
    private appointmentService: AppointmentService,
    private twillioCallService: TwillioCallService,
    private http: HttpClient,
    private settingsService: SettingsService,
    private therapistService: TherapistService
  ) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.currentUserBranch = this.webStorageService.getUserBranch();

    this.appDateFormat = this.webStorageService.getDateFormat();
    this.loading = false;
    this.titleService.setTitle("Appoint360");

    this.locale = locale;

    this.segmentModifier = ((segment: SchedulerViewHourSegment): void => {
      segment.isDisabled = false;
      /*segment.backgroundColor = "#fff";*/
    }).bind(this);

    this.eventModifier = ((event: CalendarSchedulerEvent): void => {
      event.isDisabled = false;
    }).bind(this);

    this.calendarActions = [
      { name: 'Week View By Therapist', code: 'WeekByTherapist' },
      { name: 'View By Therapist', code: 'ViewByTherapist' },
      { name: 'View By Date', code: 'ViewByDate' },
    ];
    var companyInfo = this.webStorageService.getClientConfig();
    this.isNewManageAvailability = companyInfo.isNewManageAvailability;
  }

  async ngOnInit(): Promise<void> {
    this.fillSlotPopup = false;
    let currentDate = new Date(this.weekcurrentDate.replace(/-/g, "/"));
    let weekStart = moment(currentDate).startOf('week').toDate();
    let weekEnd = moment(currentDate).endOf('week').toDate();
    this.weekStartDate = moment(weekStart).format("MMMM DD");
    this.weekEndDate = moment(weekEnd).format("MMMM DD");
    this.pdfWeekStartDate = moment(weekStart).format("MMM DD");
    this.pdfWeekEndDate = moment(weekEnd).format("MMM DD");
    this.currentYear = moment(weekEnd).format('YYYY');

    this.timeZoneList = [
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', shortName: 'EST', orgcode: 'UTC-5', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', shortName: 'CST', orgcode: 'UTC-6', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', shortName: 'MST', orgcode: 'UTC-7', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', shortName: 'PST', orgcode: 'UTC-8', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

    this.selectedcalendarActions = this.calendarActions.find(x => x.code == 'WeekByTherapist');

    this.en = {
      firstDayOfWeek: 0,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear',
      dateFormat: this.appDateFormat.dateFormat,
      weekHeader: 'Wk'
    };

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.therapistId = currentUserToken.userId;
      this.therapistName = currentUserToken.userFullName;
      this.facilityTimeZone = currentUserToken.crmTimeZone;
    }

    else {
      this.therapistId = 0;
      this.therapistName = '';
      this.facilityTimeZone = this.selectedTherapists.facilityTimeZone;
    }
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.events = [];
    this.selectedTherapists = null;

    await this.getSettingInit();
    await this.getBranches();
    await this.getAllClassificationType();
    await this.GetFacilitiesByBranchIds();
    await this.GetAllAvailableTherapistByBranchAndDate();
    await this.GetAllTherapistForAppointmentsByTimeZone();
    this.getGlobalTimeZone();
    this.GetRCMConfigurationUrl();
    this.exportData1(true);
  }
  private async getSettingInit() {
    this.loading = true;
    await this.settingsService.GetAllSettingsCalenderOnInit()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              this.setRemoteAppintmentTypes(result.responseModel.remoteApointmentTypesResponse);
              this.setRCMConfigurationUrl(result.responseModel.rcmConfigurationUrlResponse);
              this.SetDefaultsettings(result.responseModel.adminSetingResponse);
            }
          }

        });
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private setRemoteAppintmentTypes(_lstRemoteAppointmentType: RemoteAppointmentTypeModel[]) {

    if (_lstRemoteAppointmentType && _lstRemoteAppointmentType.length > 0) {
      this.remoteAppointmentTypeResponseModel = _lstRemoteAppointmentType;

    }
  }
  private setRCMConfigurationUrl(_RCMCofigurationUrl: RCMCofigurationUrl) {

    if (_RCMCofigurationUrl != undefined && _RCMCofigurationUrl != null) {
      let jsonData = _RCMCofigurationUrl;
      this.patientBrighttreeURL = jsonData.patientRCMURL;
      this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
    }
  }
  private SetDefaultsettings(settingsResponseModel: AdminCustomSettingsResponseModel) {

    if (settingsResponseModel != undefined && settingsResponseModel != null) {
      this.daysToShow = settingsResponseModel.numOfMonths;
      this.maxDateToDisplay = moment(new Date()).add(this.daysToShow, 'days').toDate();
      this.nextBtnDisabled = this.viewDate < this.maxDateToDisplay ? false : true;
      this.isBillingAddress = settingsResponseModel.isBillingAddress;
    }
  }
  private async getGlobalTimeZone() {
    await this.featureGroupService.getSettings(Constants.SettingKeys.TimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.selectedTimeZone = this.timeZoneList.find(x => x.code == result.responseModel.schSettingValue);
              //document.getElementsByClassName("material-icons md-32")[0].innerHTML = this.facilityTimeZone ? this.facilityTimeZone : "";
            }
          }
        });
      });
  }

  private GetRCMConfigurationUrl() {
    this.loading = true;
    this.featureGroupService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.responseModel) {
            let jsonData = result.responseModel;
            this.patientBrighttreeURL = jsonData.patientRCMURL;
            this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            //this.GetAllTherapistForAppointmentsByTimeZone();
            //this.fetchEvents(true);
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  RedirectcalendarActions() {
    if (!this.selectedcalendarActions.code) {
      return;
    }
    if (this.selectedcalendarActions.code == "WeekByTherapist" && !this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/week-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "WeekByTherapist" && this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/week-therapist-appointments-v1']);
    }
    else if (this.selectedcalendarActions.code == "ViewByDate"){
      this.router.navigate(['/admin/calendar/view-by-date']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && !this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/view-therapist-appointments']);
    }
    else if (this.selectedcalendarActions.code == "ViewByTherapist" && this.isNewManageAvailability){
      this.router.navigate(['/admin/calendar/view-therapist-appointments-v1']);
    }
  }

  async dateOrViewChanged() {
    //this.isDateViewChanged = true;
    //this.previouslySelectedTherapist = null;
    //this.previouslySelectedTherapist = this.selectedTherapists;

    await this.GetAllTherapistForAppointmentsByTimeZone();
    this.exportData1(true);

  }


  SetMinMaxHourForCalander(lstScheduler: SchedulerResponseModel[]) {
    let StartHour: number = this.dayStartHour;
    let EndHour: number = this.dayEndHour;

    lstScheduler.forEach(function (item) {
      var _startTime = moment(item.start, 'YYYY-MM-DD HH:mm').toDate();
      var _endTime = moment(item.end, 'YYYY-MM-DD HH:mm').toDate();

      let _StartHour: number = _startTime.getHours();
      let _EndHour: number = _endTime.getHours();

      if (_StartHour < StartHour)
        StartHour = _StartHour;

      if (_EndHour > EndHour)
        EndHour = _EndHour;
    });


    if (StartHour < this.dayStartHour)
      this.dayStartHour = StartHour;

    if (EndHour > this.dayEndHour)
      this.dayEndHour = EndHour;

  }

  async dateChange(): Promise<void> {
    var weekcurrentDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    let currentDate = new Date(this.weekcurrentDate.replace(/-/g, "/"));
    let weekStart = moment(currentDate).startOf('week').toDate();
    let weekEnd = moment(currentDate).endOf('week').toDate();
    this.weekStartDate = moment(weekStart).format("MMMM DD");
    this.weekEndDate = moment(weekEnd).format("MMMM DD");
    this.pdfWeekStartDate = moment(weekStart).format("MMM DD");
    this.pdfWeekEndDate = moment(weekEnd).format("MMM DD");
    this.currentYear = moment(weekEnd).format('YYYY');
  }

  get weekcurrentDate(): string {
    return this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  viewDaysChanged(viewDays: number): void {
    this.viewDays = viewDays;
  }

  dayHeaderClicked(day: SchedulerViewDay): void {
  }

  hourClicked(hour: SchedulerViewHour): void {
  }

  segmentClicked(action: string, segment: SchedulerViewHourSegment): void {
  }

  eventClicked(action: string, event: CalendarSchedulerEvent): void {
  }

  eventTimesChanged({ event, newStart, newEnd, type }: SchedulerEventTimesChangedEvent): void {
    const ev: CalendarEvent = this.events.find(e => e.id === event.id);
    ev.start = newStart;
    ev.end = newEnd;
    this.refresh.next();
  }

  closePopup() {
    //console.log('calling on close');
    if (this.accordion && this.accordion.tabs) {
      this.accordion.tabs.forEach(tab => tab.selected = false);
    }
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(patienKeyUrl, '_blank')
  }

  changeWipStatus(event: any) {
    this.wipStatus = event.target.value;
    let result = [... this.salesOrderStatusCurrent.filter(item => item.wipStatesName === this.wipStatus)];
    if (result.length != null && result.length > 0) {
      this.wipStatesId = Number(result[0].wipStatesId);
      this.canStatusIsUpate = true;

      if (this.wipStatus.toLowerCase() == 'complete') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }

      if (this.wipStatus.toLowerCase() == 'no show') {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }


    }
    else {
      this.wipStatesId = 0;
      this.canStatusIsUpate = false;
    }
  }

  private async GetAllAvailableTherapistByBranchAndDate() {
    //this.selectedTherapists = new Array<TherapistResponseModel>();
    let selectBranchString = "";
    let selectTherapistString = "";
    let selectFacilityString = "";
    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    // this.selectedTherapists.forEach(value => {
    //   selectTherapistString += value.appUserInfoId + ",";
    //   therpistIds.push(value.appUserInfoId);
    // });
    //therpistIds.push(this.selectedTherapists.appUserInfoId);
    let facilityids: number[] = new Array<number>();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;

    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.crmselectedTimeZone;

    this.loadingCustom = true;
    await this.facilityService.GetAllAvailableTherapistByBranchAndDateWeekViewDME(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .toPromise().then((result) => {
        this.loadingCustom = false;
        this.therapistResponseModel = result.responseModel;
        this.selectedTherapists = null;
        // if (this.previouslySelectedTherapist != null) {
        //   let isTherapistAvailable = this.therapistResponseModel.find(x => x.appUserInfoId == this.previouslySelectedTherapist.appUserInfoId);
        //   if (isTherapistAvailable) {
        //     this.selectedTherapists = this.previouslySelectedTherapist;
        //   }
        //   else {
        //     this.selectedTherapists = this.therapistResponseModel[0];
        //   }

        //   this.previouslySelectedTherapist = null;
        // } else {
        this.selectedTherapists = this.therapistResponseModel[0];
        //}
        document.getElementsByClassName("material-icons md-32")[0].innerHTML = this.timeZoneList.find(x => x.orgcode == this.selectedTherapists.facilityTimeZone).shortName;
        this.cdr.detectChanges();
      })
      .catch((httpResponseError) => {
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async onCheck() {

    this.isBlankMessageforTherapistShow = false;
    this.redirectedFacilityIdNumber = null;
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedTherapists = null;
    if (this.isShowHomeVisit === true) {
      //this.GetAllAvailableTherapistByBranchAndDateForRoadShow(0)
    }
  }
  onFilterSearch() {
    // if (this.isShowHomeVisit === true) {
    //   this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    // }
    if (this.isBranchChange) {
      this.GetAllAvailableTherapistByBranchAndDate();
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
    else if (!this.isShowHomeVisit) {
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
    this.isBranchChange = false;
    this.closeSideBar();
  }

  onBranchChange(event) {

    if (event.value) {
      this.isBlankMessageforTherapistShow = false;
      this.redirectedFacilityIdNumber = null;
      this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
      this.selectedTherapists = null;
      this.isBranchChange = true;
      //this.initAllMethods();
      this.closeSideBar();
    }
  }
  onFacilityChange(event) {

    if (event.value) {
      this.isShowHomeVisit = false;
      this.GetAllAvailableTherapistByBranchAndDate();
      this.selectedTherapists = null;
      this.closeSideBar();
    }
  }
  async onTherapistChange(event) {

    if (event.value) {
      this.closeSideBar();
    }
    this.timeZoneShortCode = this.selectedTherapists.facilityTimeZone;
    this.crmselectedTimeZone = this.selectedTherapists.facilityTimeZone;
    this.selectedUser = this.users.find(x => x.id == event.value.appUserInfoId);
    //document.getElementsByClassName("material-icons md-32")[0].innerHTML = this.timeZoneList.find(x => x.orgcode == this.crmselectedTimeZone).shortName;
    await this.GetAllTherapistForAppointmentsByTimeZone();
    this.exportData1(true);
  }
  onClearFilterSearch() {
    this.isShowHomeVisit = false;
    this.isBranchChange = false;
    this.selectedTherapists = null;
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedFacilities = new Array<FacilityInfoResponseModel>();
    this.selectedbranches = this.branchResponseModel;
    //this.initAllMethods();
  }
  closeSideBar() {

    this.blockSlotPopup = false;
    this.fillSlotPopup = false;
    this.isRightPanelEnabled = false;
    this.isRightPanelBlockEnabled = false;
    this.nearByPatients = [];
    this.blockDesc = "";
    if (this.selectedFilterBranches.length > 0) {
      this.selectedFilterBranches = [];
    }
    if (this.selectedClassificationType.length > 0) {
      this.selectedClassificationType = [];
    }
  }

  private async GetFacilitiesByBranchIds() {
    let crmBranchIdsString: string = '';
    this.selectedAppointmentFacilities = new Array<FacilityInfoResponseModel>();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      crmBranchIdsString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = crmBranchIdsString;


    this.progress.next(true);
    await this.facilityService.GetFacilitiesByBranchIds(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {

        this.facilityResponseModel = result.responseModel;
        if (this.facilityResponseModel.length > 0) {
          if (this.redirectedFacilityIdNumber) {
            let faci = this.facilityResponseModel.find(x => x.crmFacilityId == this.redirectedFacilityIdNumber);
            if (faci) {
              this.selectedAppointmentFacilities.push(faci);
              this.selectedFacility = faci;
            }
            else
              this.selectedFacility = this.facilityResponseModel[0];
          }
          else
            this.selectedFacility = this.facilityResponseModel[0];

          this.facilityId = this.selectedFacility.crmFacilityId;

          this.isBlankMessageshow = false;
        } else {
          //this.cdr.detectChanges();
          this.isBlankMessageshow = true;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private async getBranches() {

    this.branchResponseModel = this.webStorageService.getUserBrances();
    this.setBranchSettings();
  }

  setBranchSettings() {
    this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
    if (selectedBranch !== undefined) {

      if (selectedBranch.crmBranchId !== 0) {
        //this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
        this.selectedbranches = this.branchResponseModel.filter(x => x.crmBranchId === selectedBranch.crmBranchId);
      }
    } else {
      this.selectedbranches = this.branchResponseModel;
    }
  }

  hourSegmentClick({ date, sourceEvent, userinfo }, overlaypanelBlockSlot: OverlayPanel, overlaypanelBlockSlotConextMenu: OverlayPanel) {

    userinfo = this.selectedUser

    if (date < this.currentDate)
      return;

    if (date < userinfo.start)
      return;

    if (date > userinfo.end)
      return;

    this.searchDate = date;
    this.searchUser = userinfo;
    this.facilityId = this.getfacilityId(date, userinfo.distinctTherapistAvailability)
    let facilityInfo = this.getfacilityIdInfo(date, userinfo.therapistAvailability);
    this.facilityId = facilityInfo[0];
    this.facilityName = facilityInfo[1];
    this.isRemoteAndFacility = facilityInfo[2];
    this.remoteFacilityId = facilityInfo[3];
    this.remoteFacilityName = facilityInfo[4];
    this.isStartFromHomeAddress = facilityInfo[5];
    this.isStartFromDefaultFacility = facilityInfo[6];
    this.selectedFacilityTimeZone = facilityInfo[7];
    this.isRemoteOnly = facilityInfo[8];
    overlaypanelBlockSlot.toggle(event);
    overlaypanelBlockSlotConextMenu.hide();
    this.searchText = "";
    //this.getPatients();
  }

  getfacilityId(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): number {

    if (therapistAvailability) {
      let facilityName = '';
      let startDate = moment(slotStartTime).toDate();
      var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
        && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
      if (facility) {
        return facility.facilityId;
      }
      else
        return 0;
    }
    else
      return 0;
  }

  getfacilityIdInfo(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): any {

    if (therapistAvailability) {
      let startDate = moment(slotStartTime).toDate();
      var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
        && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
      if (facility) {
        return [facility.facilityId,
        facility.facilityName,
        facility.isRemoteAndFacility,
        facility.remoteFacilityId,
        facility.remoteFacilityName,
        facility.isStartFromHomeAddress,
        facility.isStartFromDefaultFacility,
        facility.facilityTimeZone,
        facility.isRemoteAssistant
        ];
      }
      else
        return [];
    }
    else
      return [];
  }

  SearchPatient() {
    this.selectedAppointmentStatus = null;
    this.getPatients(this.searchedFacilityid, this.isFacilityselected, this.IsRemoteOrFacility);
  }

  textChange() {
    if (this.searchText && this.searchText != "") {
      this.showClear = true;
    }
    else {
      this.showClear = false;
    }
  }

  searchCloseClick() {
    this.searchText = "";
    this.showClear = false;
    this.selectedAppointmentStatus = null;
    this.getPatients(this.searchedFacilityid, this.isFacilityselected, this.IsRemoteOrFacility);
  }

  showScheduleDialog(crmSalesOrderId, schAppointmentId, crmPatientAllowedOnlyOnMyFacility) {

    //this.isScheduleAppoinment = true;
    //this.getSalesOrderInfo(crmSalesOrderId);
    if (!this.isRemoteAndFacility && this.isRemoteOnly && !crmPatientAllowedOnlyOnMyFacility) {
      this.showWarningMessage('This patient allowed for facility only.');
      return;
    }
    if (this.isRemoteAndFacility && this.IsRemoteOrFacility == 2 && !crmPatientAllowedOnlyOnMyFacility) {
      this.showWarningMessage('This patient allowed for facility only.');
      return;
    }

    //this.getSalesOrderInfo(this.salesOrderId);
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: false,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: schAppointmentId > 0 ? true : false,
        isAppointment: schAppointmentId > 0 ? true : false,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        isScheduleOrder: true,
        isAppoinmentBookByCalendar: true,
        crmTherapsitId: this.searchUser.id,
        crmTherapsitName: this.searchUser.name,
        crmPractionerId: this.searchUser.practionaerId,
        crmDeliveryTechnicianId: this.searchUser.deliveryTechnicianId,
        salesOrderdate: this.searchDate,
        isRemoteAndFacility: this.isRemoteAndFacility,
        isFacilityselected: this.isFacilityselected,
        remoteFacilityId: this.remoteFacilityId,
        voidFrom: "A",
        //defaultModeofAppointment: this.defaultModeofAppointment
      },
    });
    this.fillSlotPopup = false;

    ref.onClose.subscribe((session: any) => {

      //this.HangUpCall();
      //this.closeSideBar();
      //this.fetchEvents(true);
      if (!session) {
        this.closeSideBar();
        this.fetchEvents(true);
      }
      else if (session.isSession) {

        this.closeSideBar();
        if (session.appointmentDate) {
          this.viewDate = moment(session.appointmentDate).toDate();
          this.GetNextDayAvailability();
        } else {
          this.fetchEvents(true);
        }
      }
    });
  }

  async onAppointmentChange(filterPanel: OverlayPanel) {
    this.isFilterSubmit = true;
    // if (!this.isAllBranchFilterSelected || !this.isAllClssificationFilterSelected) {
    //   if (this.selectedFilterBranches.length == 0 || this.selectedClassificationType.length == 0) {
    //     return;
    //   }
    // }
    //let selectedAppointmentStatus = this.selectedAppointmentStatus.value;
    if (!this.isAllBranchFilterSelected && this.selectedFilterBranches.length == 0) {
      return;
    }
    if (!this.isAllClssificationFilterSelected && this.selectedClassificationType.length == 0) {
      return;
    }

    if (this.isAllBranchFilterSelected && this.selectedFilterBranches.length > 0) {
      this.selectedFilterBranches = [];
    }
    if (this.isAllClssificationFilterSelected && this.selectedClassificationType.length > 0) {
      this.selectedClassificationType = [];
    }
    let myClonedArray = [];
    this.origionalNearByPatients.forEach(val => myClonedArray.push(Object.assign({}, val)));

    if (!this.isAllBranchFilterSelected) {
      myClonedArray = myClonedArray.filter(x => this.selectedFilterBranches.map(x => x.crmBranchId).includes(x.crmBranchId));
    }
    if (!this.isAllClssificationFilterSelected) {
      myClonedArray = myClonedArray.filter(x => this.selectedClassificationType.map(x => x.crmClassificationTypeId).includes(x.crmSalesorderClassificationType));
    }

    const myFilterdArray = [];
    this.selectedAppointmentValues.forEach(val => {
      var values = myClonedArray.filter(x => x.crmSalesorderStatus == val);
      myFilterdArray.push(...values);
    });

    if (this.selectedAppointmentValues.length > 0) {
      this.nearByPatients = myFilterdArray;
    }
    else {
      this.nearByPatients = myClonedArray;
    };
    this.isFilterSubmit = false;
    filterPanel.hide();
  }

  onClearFilter() {
    this.selectedAppointmentValues = [];
    this.selectedClassificationType = [];
    this.selectedFilterBranches = [];
    this.isAllBranchFilterSelected = true;
    this.isAllClssificationFilterSelected = true;
    this.isFilterSubmit = false;
    let myClonedArray = [];
    this.origionalNearByPatients.forEach(val => myClonedArray.push(Object.assign({}, val)));
    this.nearByPatients = myClonedArray;
  }

  getPatients(facilityId, isFacilityselected, IsRemoteOrFacility) {

    this.fillSlotPopup = true;
    this.isFacilityselected = isFacilityselected;
    this.searchedFacilityid = facilityId;

    if (this.facilityName == "Road/Home Appt") {

      this.selectedApptType = 'RoadHomeVisit';
    } else {

      if (IsRemoteOrFacility == 1) {
        this.selectedApptType = 'FacilityVisit';
      }
      else if (IsRemoteOrFacility == 2) {
        this.selectedApptType = 'RemoteSetup';
      }
      else {
        this.selectedApptType = isFacilityselected ? 'FacilityVisit' : (this.isRemoteOnly ? 'RemoteSetup' : 'FacilityVisit');
      }
    }
    this.Customloading = true;
    this.IsRemoteOrFacility = IsRemoteOrFacility;
    this.appointmentService.GetNearByPatientsLicense(facilityId, this.searchText, this.selectedUser.id, this.isStartFromDefaultFacility, this.isStartFromHomeAddress, this.viewDate.toDateString(), this.isBillingAddress, this.selectedApptType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        this.Customloading = false;
        this.nearByPatients = result.responseModel;
        this.origionalNearByPatients = result.responseModel;
        this.selectedFilSlotDateTime = this.searchDate;
        if (this.nearByPatients.length > 0) {
          this.isRightPanelEnabled = true;
          this.isRightPanelBlockEnabled = false;
          this.selectUser = this.searchUser;
        }
        else {
          if (!this.searchText) {
            this.isRightPanelEnabled = false;
            this.isRightPanelBlockEnabled = false;
          }
          else {
            this.isRightPanelEnabled = true;
            this.isRightPanelBlockEnabled = false;
          }
          this.selectUser = null;
        }
        this.cdr.detectChanges();

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.Customloading = false;
      });
    this.onClearFilter();
  }

  setBlock() {
    this.blockSlotPopup = true;
    this.isBlock = true;
    this.availId = 0;
    this.therapistCustomAvailabilityId = 0;
    this.labelFor = "Block Slot";
    this.therapisId = this.searchUser.id;
    this.selectedtherapist = this.searchUser.name;
    this.isRightPanelBlockEnabled = true;
    this.isRightPanelEnabled = false;
    let start = this.searchDate;
    let end = moment(this.searchDate).add(15, 'minutes').toDate();
    this.customStartTime = start.toTimeString().split(' ')[0].split(':')[0] + ':' + start.toTimeString().split(' ')[0].split(':')[1];
    this.customEndTime = end.toTimeString().split(' ')[0].split(':')[0] + ':' + end.toTimeString().split(' ')[0].split(':')[1];
  }

  setBreak() {
    this.blockSlotPopup = true;

    this.isBlock = false;
    this.availId = 0;
    this.therapistCustomAvailabilityId = 0;
    this.labelFor = "Break Hours";
    this.therapisId = this.searchUser.id;
    this.selectedtherapist = this.searchUser.name;
    this.isRightPanelBlockEnabled = true;
    this.isRightPanelEnabled = false;
    let start = this.searchDate;
    let end = moment(this.searchDate).add(30, 'minutes').toDate();
    this.customStartTime = start.toTimeString().split(' ')[0].split(':')[0] + ':' + start.toTimeString().split(' ')[0].split(':')[1];
    this.customEndTime = end.toTimeString().split(' ')[0].split(':')[0] + ':' + end.toTimeString().split(' ')[0].split(':')[1];
  }

  showRescheduleDialogPopup() {


    let appointment = this.selectedAppointment;
    if (appointment.crmAppointmentType == "Facility Visit") {
      this.defaultModeofAppointment = 'facilityvisit';
    }
    else if (appointment.crmAppointmentType == "Telehealth") {
      this.defaultModeofAppointment = 'remotesetup';
    }
    else if (appointment.crmAppointmentType == "Home Visit") {
      this.defaultModeofAppointment = "roadhomevisit";
    }
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {

      showHeader: true,
      closable: false,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: this.selectedAppointment.salesOrderInfo.crmSalesorderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: appointment.schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        isScheduleOrder: true,
        voidFrom: "A",
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe((session: any) => {
      this.selectedAppointment = new AppointmentInfoReponseModel();
      if (!session) {
        this.fetchEvents(true);
      }
      else if (session.isSession) {

        if (session.appointmentDate) {
          this.viewDate = moment(session.appointmentDate).toDate();
          this.GetNextDayAvailability();
        } else {
          this.fetchEvents(true);
        }
      }
      //this.HangUpCall();
    });

  }
  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }
  async GetNextDayAvailability() {
    let NextDate = moment(new Date(this.viewDate)).add(1, 'days').toDate();


    if (NextDate < this.maxDateToDisplay) {
      this.nextBtnDisabled = false;
    } else if (NextDate > this.maxDateToDisplay) {
      this.nextBtnDisabled = true;
    }

    if (NextDate < this.currentDate) {
      this.isDisabledReassignment = true;
    }
    else {
      this.isDisabledReassignment = false;
    }



    if (this.isShowHomeVisit === true) {
      // this.GetAllAvailableTherapistByBranchAndDateForRoadShow(0)
      // this.GetAllTherapistForAppointmentsForRoadShowByTimeZone(0);
    } else {
      this.GetAllAvailableTherapistByBranchAndDate();
      this.GetAllTherapistForAppointmentsByTimeZone();
    }
  }

  async fetchEvents(isRefresh: boolean) {

    this.appointment = new AppointmentInfoReponseModel();
    let clientId = '';
    let Authorization = ''
    const currentClientConfig = this.webStorageService.getClientConfig();

    if (currentClientConfig && currentClientConfig.companyId) {
      clientId = currentClientConfig.companyId.toString();
    }

    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      Authorization = currentUserAuthToken.tokentype.concat(' ').concat(currentUserAuthToken.token);
    }
    const headers = new HttpHeaders()
      .set("clientId", clientId).append("Authorization", Authorization);

    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";
    let branchIds: number[] = new Array();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array();

    this.selectedTherapists ? therpistIds.push(this.selectedTherapists.appUserInfoId) : therpistIds;
    therpistIds.forEach(value => {
      selectTherapistString += value + ",";
      //therpistIds.push(value);
    });
    let facilityids: number[] = new Array();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });

    if (this.isShowHomeVisit === true) {
      this.selectFacilityString = "0";
      facilityids.push(0);
    } else {
      this.selectedAppointmentFacilities.forEach(value => {
        this.selectFacilityString += value.crmFacilityId + ",";
        facilityids.push(value.crmFacilityId);
      });
    }

    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();;
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;

    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.selectedTherapists ? this.selectedTherapists.facilityTimeZone : this.crmselectedTimeZone;

    //let datepass = moment(therapistAppointmentSearchRequestModel.appointmentDate).format('MM-DD-YYYY');
    this.loadingCustom = true;
    await this.theraistAppointmentService.GetWeekViewTherapistAppointmentWeekViewDME(selectedDate, therapistAppointmentSearchRequestModel, selectTherapistString, selectBranchString, selectFacilityString, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .toPromise()
      .then((result) => {
        this.processResult<SchedulerResponseModel[]>(result, () => {
          if (result.isSuccess) {
            let lstCalendarSchedulerEvent: CalendarEvent[] = [];
            let checkIsAppointment = result.responseModel.find(x => x.isBlock || x.appointment != null)
            if (checkIsAppointment != undefined) {
              this.isBlankMessageforTherapistShow = false;
            }
            result.responseModel.forEach(x => {

              let therapistId;
              let is15min: boolean = false;
              if (x.appointment) {
                therapistId = x.appointment.appointmentDetailInfo.crmTherapistId;
                var then = moment(x.start);
                var now = moment(x.end);
                let Duration = moment.duration(now.diff(then));
                let diff = now.diff(then, 'minutes')
                if (Number(diff) <= 15) {
                  is15min = true;
                }
              }

              let _facilityTimeZone: string = "";
              let _crmPatientTimeZone: string = "";
              let _crmTimezone: string = "";

              _facilityTimeZone = String(x.facilityTimeZone);
              _crmPatientTimeZone = String(x.crmPatientTimeZone);

              if (_crmPatientTimeZone != undefined && _crmPatientTimeZone != null && _crmPatientTimeZone != "null" && _crmPatientTimeZone != "")
                _crmTimezone = _crmPatientTimeZone;
              else
                _crmTimezone = _facilityTimeZone == "null" ? "" : _facilityTimeZone;



              x.is15minSlot = is15min;
              let eventObject = x;
              eventObject.startLabel = moment(x.startLabel, 'YYYY-MM-DD HH:mm').toDate();
              eventObject.endLabel = moment(x.endLabel, 'YYYY-MM-DD HH:mm').toDate();

              lstCalendarSchedulerEvent.push({
                id: String(x.id),
                start: moment(x.start, 'YYYY-MM-DD HH:mm').toDate(),
                end: moment(x.end, 'YYYY-MM-DD HH:mm').toDate(),
                title: x.title,
                meta: {
                  eventObject,
                  appointmentInfoReponseModel: x,
                  user: this.selectedUser,
                  // availId: x.availId,
                  // therapistCustomAvailabilityId:x.therapistCustomAvailabilityId,
                  // isBlock: x.isBlock,
                  // crmPatientTimeZone: (_crmPatientTimeZone == null || _crmPatientTimeZone == "null") ? "" : String(x.crmPatientTimeZone),
                  // facilityTimeZone: (_facilityTimeZone == null || _facilityTimeZone == "null") ? "" : String(x.facilityTimeZone),
                },
                color: x.id == 0 ? colors.grey : x.id == -1 ? colors.blokSlot : colors.blue,
                actions: Number(x.id) > 0 ? this.actions : null,
              });
            })
            //if (lstCalendarSchedulerEvent.length > 0)
            this.events = lstCalendarSchedulerEvent;
            this.cdr.detectChanges();
            if (document.getElementsByClassName("material-icons md-32")[0] != undefined) {
              document.getElementsByClassName("material-icons md-32")[0].innerHTML = this.timeZoneList.find(x => x.orgcode == this.selectedTherapists.facilityTimeZone).shortName;
            }
          }
        });
      });

  }

  private async GetAllTherapistForAppointmentsByTimeZone() {
    this.isHoliday = false;
    this.holidayMessage = "";

    let selectBranchString = "";
    let selectFacilityString = "";
    let selectTherapistString = "";

    this.selectedAppointments = Array();
    let branchIds: number[] = new Array<number>();
    this.selectedbranches.forEach(value => {
      selectBranchString += value.crmBranchId + ",";
      branchIds.push(value.crmBranchId);
    });
    let therpistIds: number[] = new Array<number>();
    // this.selectedTherapists.forEach(value => {
    //   selectTherapistString += value.appUserInfoId + ",";
    //   therpistIds.push(value.appUserInfoId);
    // });
    if (this.selectedTherapists)
      therpistIds.push(this.selectedTherapists.appUserInfoId);


    let facilityids: number[] = new Array<number>();
    this.selectedAppointmentFacilities.forEach(value => {
      selectFacilityString += value.crmFacilityId + ",";
      facilityids.push(value.crmFacilityId);
    });
    this.ESTTherapistCount = 0;
    this.CSTTherapistCount = 0;
    this.MSTTherapistCount = 0;
    this.PSTTherapistCount = 0;


    let therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel = new TherapistAppointmentSearchRequestModel();
    var selectedDate = this.viewDate.getFullYear().toString() + '-' + (this.viewDate.getMonth() + 1).toString() + '-' + this.viewDate.getDate().toString();
    therapistAppointmentSearchRequestModel.appointmentDate = selectedDate;
    therapistAppointmentSearchRequestModel.crmBranchIds = branchIds;
    therapistAppointmentSearchRequestModel.crmFacilityiIds = facilityids;
    therapistAppointmentSearchRequestModel.crmTherapistsId = therpistIds;
    therapistAppointmentSearchRequestModel.crmBranchIdsString = selectBranchString;
    therapistAppointmentSearchRequestModel.crmFacilityiIdsString = selectFacilityString;
    therapistAppointmentSearchRequestModel.crmTherapistsIdString = selectTherapistString;
    therapistAppointmentSearchRequestModel.crmselectedTimeZone = this.selectedTherapists ? this.selectedTherapists.facilityTimeZone : this.crmselectedTimeZone;

    this.selectedAppointments = Array();
    this.loadingCustom = true;
    await this.theraistAppointmentService.GetAllTherapistForAppointmentsWeekViewDME(therapistAppointmentSearchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
      .toPromise().then((result) => {
        this.users = Array<User>();

        if (result.isSuccess) {
          this.loadingCustom = false;
          if (result.responseModel?.schedulerTimeZoneResponseModel) {
            let EstTherapist = result.responseModel?.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-5');
            if (EstTherapist)
              this.ESTTherapistCount = EstTherapist.therpiastCount;

            let CSTTherapist = result.responseModel?.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-6');
            if (CSTTherapist)
              this.CSTTherapistCount = CSTTherapist.therpiastCount;

            let MSTTherapist = result.responseModel?.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-7');
            if (MSTTherapist)
              this.MSTTherapistCount = MSTTherapist.therpiastCount;

            let PST = result.responseModel?.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UTC-8');
            if (PST)
              this.PSTTherapistCount = PST.therpiastCount;


            let AMSTTherapist = result.responseModel.schedulerTimeZoneResponseModel.find(x => x.timeZone == 'UMST-7');
            if (AMSTTherapist)
              this.AMSTTherapistCount = AMSTTherapist.therpiastCount;

            if (this.timeZoneShortCode == 'UTC-5')
              this.timeZoneCodeTherapistCount = this.ESTTherapistCount;
            if (this.timeZoneShortCode == 'UTC-6')
              this.timeZoneCodeTherapistCount = this.CSTTherapistCount;
            if (this.timeZoneShortCode == 'UTC-7')
              this.timeZoneCodeTherapistCount = this.MSTTherapistCount;
            if (this.timeZoneShortCode == 'UTC-8')
              this.timeZoneCodeTherapistCount = this.PSTTherapistCount;
            if (this.timeZoneShortCode == 'UMST-7')
              this.timeZoneCodeTherapistCount = this.AMSTTherapistCount;
          }

          //CSTTherapistCount
          //MSTTherapistCount
          //PSTTherapistCount


          result.responseModel.schedulerResponseModel.forEach(value => {
            let startDate = moment(value.startLabel, 'YYYY-MM-DD HH:mm').toDate();
            let startDateExt = moment(startDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let endDate = moment(value.endLabel, 'YYYY-MM-DD HH:mm').toDate();
            let endDateDateExt = moment(endDate, 'YYYY-MM-DD HH:mm').add(-1, 'minutes');

            let user = new User();
            user.id = value.id;
            user.field = value.field;
            user.name = value.title;
            user.start = moment(startDateExt, 'YYYY-MM-DD HH:mm').toDate();
            user.end = moment(endDateDateExt, 'YYYY-MM-DD HH:mm').toDate();

            var facilityTimeZone = value.therapistAvailability.find(x => x.facilityTimeZone);
            if (facilityTimeZone)
              user.facilityTimeZone = facilityTimeZone.facilityTimeZone;

            user.startDate = moment(startDate, 'YYYY-MM-DD HH:mm').toDate();
            user.endDate = moment(endDate, 'YYYY-MM-DD HH:mm').toDate();
            user.origStart = moment(value.start, 'YYYY-MM-DD HH:mm').toDate();
            user.origEnd = moment(value.end, 'YYYY-MM-DD HH:mm').toDate();
            user.therapistAvailability = value.therapistAvailability;

            user.practionaerId = value.practitionerId;
            user.deliveryTechnicianId = value.deliveryTechnicianId;

            let removeBlankFacility = value.therapistAvailability.filter(x => x.facilityName != "");
            //let uniqueUsersByID = _.uniqBy(removeBlankFacility, 'facilityName');

            let therapistAvailabilityFilter: TherapistCustomDatesResponseModel[];
            therapistAvailabilityFilter = [];
            for (let item of removeBlankFacility) {
              let _model: TherapistCustomDatesResponseModel;
              _model = item;
              let facilityFilter = this.facilityResponseModel.find(x => x.crmFacilityId == item.facilityId);

              //check the Road/Home
              if (!_model.isStartFromDefaultFacility && !_model.isStartFromHomeAddress) {
                if (facilityFilter != undefined) {
                  if (facilityFilter.isRemoteAssistance)
                    _model.facilityTimeZone = "";
                }
                else {
                  _model.facilityTimeZone = "";
                }
              }
              therapistAvailabilityFilter.push(_model);
            }
            user.distinctTherapistAvailability = therapistAvailabilityFilter;
            this.users.push(user);
          });
          if (this.users.length > 0) {
            this.isBlankMessageforTherapistShow = false;

            var selecteduser = this.users.find(x => x.id == this.selectedTherapists.appUserInfoId);
            if (selecteduser) {

              //let momentsStart = selecteduser.distinctTherapistAvailability.map(d => moment(d.crmStartTime));
              //let momentsEnd = selecteduser.distinctTherapistAvailability.map(d => moment(d.crmEndTime));

              //let minDate = moment.min(momentsStart);
              //let maxDate = moment.max(momentsEnd);

              //let dayStartHour = minDate.toDate().getHours();
              //let dayStartMintes = minDate.toDate().getMinutes();

              //let DayeEndMinutes = maxDate.toDate().getMinutes();
              //let DayeEndTime = maxDate.toDate().getHours();

              ////Issue: calendar showing incorrect start time when user click on the weekends on the calendar and select back to working day selection of day - e.g 9:44, 9:07 etc
              ////Assigned default value as O value if dayStartMinute=0
              //this.dayEnd = DayeEndTime;
              //if (dayStartHour)
              //  this.dayStartHour = dayStartHour;

              //if (dayStartMintes)
              //  this.dayStartMinute = dayStartMintes;
              //else
              //  this.dayStartMinute = 0;

              //if (DayeEndTime)
              //  this.dayEndHour = DayeEndTime;

              //if (DayeEndMinutes)
              //  this.dayEndMinute = DayeEndMinutes;
              //else
              //  this.dayEndMinute = 0;

              let CustomData: SchedulerMinMaxResponseModel[];
              CustomData = new Array();
              let StartHour: number = this.dayStartHour;
              let EndHour: number = this.dayEndHour;
              let currDate = moment(new Date()).format("YYYY-MM-DD");
              let defaultStartTime: Date = moment(currDate + ' ' + '07:00:00', 'YYYY-MM-DD HH:mm').toDate();
              let defaultEndTime: Date = moment(currDate + ' ' + '20:00:00', 'YYYY-MM-DD HH:mm').toDate();

              selecteduser.distinctTherapistAvailability.forEach(function (item) {

                let startTime = moment(item.crmStartTime).format("HH:mm:ss");
                let endTime = moment(item.crmEndTime).format("HH:mm:ss");
                let startTimeN = moment(currDate + ' ' + startTime, 'YYYY-MM-DD HH:mm').toDate();
                let endTimeN = moment(currDate + ' ' + endTime, 'YYYY-MM-DD HH:mm').toDate();

                let CustomData1 = new SchedulerMinMaxResponseModel();

                CustomData1.startTime = startTimeN;
                CustomData1.endTime = endTimeN;
                CustomData.push(CustomData1);
              });

              let momentsStartTime = CustomData.map(d => (d.startTime));
              let momentsEndTime = CustomData.map(d => (d.endTime));

              if (momentsStartTime.length > 0 && momentsEndTime.length > 0) {
                let mnDate = momentsStartTime?.reduce(function (a, b) {
                  return a < b ? a : b;
                });

                let mxDate = momentsEndTime?.reduce(function (a, b) {
                  return a > b ? a : b;
                });


                var _startTimeN = moment(mnDate, 'YYYY-MM-DD HH:mm').toDate();
                var _endTimeN = moment(mxDate, 'YYYY-MM-DD HH:mm').toDate();

                // if (_startTimeN <= defaultStartTime)
                //   _startTimeN = mnDate;
                // else
                //   _startTimeN = defaultStartTime;


                // if (_endTimeN >= defaultEndTime)
                //   _endTimeN = mxDate;
                // else
                //   _endTimeN = defaultEndTime;

                let _StartHourN: number = _startTimeN.getHours();
                let _startMinN: number = _startTimeN.getMinutes();

                let _EndHourN: number = _endTimeN.getHours();
                let _endMinN: number = _endTimeN.getMinutes();

                this.dayStartHour = _StartHourN;
                this.dayStartMinute = _startMinN;

                this.dayEndHour = _EndHourN;
                this.dayEndMinute = _endMinN;

              }
              else {
                this.dayStartHour = 8;
                this.dayStartMinute = 0;
                this.dayEndHour = 20;
                this.dayEndMinute = 0;
              }


            }
            else {

              this.dayStartHour = 8;
              this.dayStartMinute = 0;
              this.dayEndHour = 20;
              this.dayEndMinute = 0;
            }



          }
          else {
            this.isBlankMessageforTherapistShow = true;
            this.dayStartHour = 8;
            this.dayStartMinute = 0;
            this.dayEndHour = 20;
            this.dayEndMinute = 0;
          }

        }
        else {
          this.isHoliday = true;
          this.holidayMessage = result.message;
        }
        if (this.selectedTherapists) {
          this.selectedUser = null;
          this.selectedUser = this.users.find(x => x.id == this.selectedTherapists.appUserInfoId);
        }
        else {
          this.selectedUser = this.users[0];
        }

        let currentDate = new Date(this.weekcurrentDate.replace(/-/g, "/"));
        let weekStart = moment(currentDate).startOf('week').toDate();
        let weekEnd = moment(currentDate).endOf('week').toDate();
        //this.isNextButtonDisable = false;
        if (this.selectedUser) {
          const maxDate = this.selectedUser.therapistAvailability.filter(X => X.facilityTimeZone == this.crmselectedTimeZone.replace(' ', '')).map(d => moment(d.crmEndTime));
          let maxdt = moment.max(maxDate)
          if (new Date(maxdt.toDate().toDateString()) < new Date(new Date().toDateString())) {
            this.isBlankMessageforTherapistShow = true;
          }
        }
        else {
          this.isBlankMessageforTherapistShow = true;
        }

        this.fetchEvents(true);
        this.closeSideBar();

      })
      .catch((httpResponseError) => {
        this.loadingCustom = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  convertAppointmentType() {

    let appointment = this.selectedAppointment;
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to change appointment type from ' + this.selectedAppointment.schRemoteAppointmentTypeDisplayName + ' to ' + this.convertedRemoteAppointmentType + '?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }
      this.loadingCustom = true;
      this.appointmentService.ConvertRemoteAppointment(appointment.schAppointmentId, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingCustom = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('The appointment type has been changed successfully.');
            this.fetchEvents(true);
            this.exportData1(true);
            this.loadingCustom = false
            this.cdr.detectChanges();
          });

        }, (httpResponseError) => {

          this.loadingCustom = false
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  idDateIsPassed(segmentdate: any) {

    let format = 'hh:mm:ss';
    this.slotTimeZone = this.timeZoneList.find(x => x.shortName == this.facilityTimeZone);
    let datec = moment(new Date()).tz(this.slotTimeZone.countryName).format('DD-MMM-yyyy HH:mm:ss');
    let currentDate = moment(datec).toDate();
    let date = moment(segmentdate).toDate();
    let issameDaye = isSameDay(segmentdate, currentDate);
    let issameafter = isAfter(segmentdate, currentDate);
    let isBeforedate = isBefore(segmentdate, currentDate);
    // let startDate = moment(this.selectedtherapist.start).toDate();
    // let endDate = moment(user.end).toDate();

    if (isBeforedate)
      return false;
    else if ((issameDaye || issameafter))//&& date >= startDate && date <= endDate
      return true;
    else
      return false;

  }

  facilityNameLabel(slotStartTime: Date, therapistAvailability: TherapistCustomDatesResponseModel[]): string {
    if (therapistAvailability) {

      let facilityName = '';
      let startDate = moment(slotStartTime).toDate();
      if (this.idDateIsPassed(slotStartTime)) {
        var facility = therapistAvailability.find(x => startDate >= moment(x.crmStartTime).add(-1, 'minutes').toDate()
          && startDate <= moment(x.crmEndTime).add(-1, 'minutes').toDate())
        if (facility) {
          if (facility.isRemoteAndFacility) {
            return `${facility.facilityName} & ${facility.remoteFacilityName}`;
          } else {
            return `${facility.facilityName}`;

          }
        }
        else {
          return '';
        }
      }
      else {
        return '';
      }

    }
    else {
      return '';
    }

  }

  async getRemoteAppointmentType() {
    this.loading = true;
    this.settingsService.getRemoteAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.remoteAppointmentTypeResponseModel = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  showDialogPopup(isShow, appointment, overlaypanelContextMenu: OverlayPanel, overlaypanelBlockSlot: OverlayPanel) {

    this.selectedAppointment = appointment;
    if (this.selectedAppointment.crmAppointmentType.toLowerCase() == 'Telehealth'.toLowerCase()) {

      if (this.remoteAppointmentTypeResponseModel.length > 0) {
        if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'Telemed'.toLowerCase()) {
          let OnlineMeeting = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'OnlineMeeting')
          if (OnlineMeeting) {
            this.convertedRemoteAppointmentType = OnlineMeeting.crmRemoteAppointmentTitle;
            this.isChangeRemoteAppointmentType = true;
            if (this.selectedAppointment.schWIPStatus.toLowerCase() == 'booked') {
              this.isChangeRemoteAppointmentType = true;
            } else {
              this.isChangeRemoteAppointmentType = false;
            }
          }
          else
            this.isChangeRemoteAppointmentType = false;

        }
        else if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'OnlineMeeting'.toLowerCase()) {

          let telemed = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'Telemed')
          if (telemed) {
            this.convertedRemoteAppointmentType = telemed.crmRemoteAppointmentTitle;
            if (this.selectedAppointment.schWIPStatus.toLowerCase() == 'booked') {
              this.isChangeRemoteAppointmentType = true;
            } else {
              this.isChangeRemoteAppointmentType = false;
            }
          }
          else
            this.isChangeRemoteAppointmentType = false;
        }
      }
    }
    overlaypanelContextMenu.toggle(event);
    overlaypanelBlockSlot.hide();
    return;
  }

  onSave() {
    if (!this.blockDesc && this.isBlock) {
      this.showWarningMessage('Please add description to block slot');
      return;
    }

    if (this.customStartTime != undefined && this.customEndTime != undefined) {
      var _startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
      var _endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

      if (_endTime == _startTime) {
        this.showWarningMessage('Start Time & End Time should not be equal');
        return;
      }
      else if (_endTime < _startTime) {
        this.showWarningMessage('End Time should be greater than Start Time');
        return;
      }
    }


    var requestModel = new TherapistCustomAvailabilityResponseModel();
    requestModel.crmBreakEndTimeString = null;
    requestModel.crmBreakStartTimeString = null;
    requestModel.crmDescription = this.blockDesc;

    let facilityId = this.facilityId;
    let selectedFacilityTimeZone = this.selectedFacilityTimeZone;
    let remoteFacilityId = this.remoteFacilityId;
    if (this.isBlock) {
      if (this.facilityName == 'Road/Home Appt') {
        this.isRoadHomeAppt = true;

        if (this.isStartFromDefaultFacility) {
          facilityId = this.facilityId;
          remoteFacilityId = null;
        }
        else {
          facilityId = null;
          remoteFacilityId = null;
        }
      }
      else
        this.isRoadHomeAppt = false;
    }
    else {
      if (this.facilityName == 'Road/Home Appt') {
        this.isRoadHomeAppt = true;
      }
    }

    requestModel.crmIsHoliday = true;
    requestModel.crmIsRecurring = false;
    requestModel.crmIsRoadShow = this.isRoadHomeAppt == true ? true : false;;
    requestModel.isRemoteAssistant = false;
    requestModel.isBlockSlot = true;
    requestModel.crmRecurringFrequency = 1;
    requestModel.crmRecurringType = 1;

    requestModel.crmTherapistCustomEndDateString = moment(this.searchDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomEndTimeString = moment(this.customEndTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistCustomStartDateString = moment(this.searchDate).format("yyyy/MM/DD");
    requestModel.crmTherapistCustomStartTimeString = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
    requestModel.crmTherapistId = this.therapisId;
    requestModel.crmTherpistCustomAvailabilityId = this.therapistCustomAvailabilityId;
    requestModel.customId = this.availId;
    requestModel.crmIsExactDay = false;
    requestModel.isOffice = false;
    requestModel.modifiedByUserInfoId = this.loggedInUserId;
    requestModel.isFullDay = false;
    requestModel.isStartFromDefaultFacility = this.isStartFromDefaultFacility;
    requestModel.isStartFromHomeAddress = this.isStartFromHomeAddress;
    requestModel.crmFacilityId = facilityId;// this.facilityId;
    requestModel.isRemoteAndFacility = this.isRemoteAndFacility;
    requestModel.selectedFacilityTimeZone = this.selectedFacilityTimeZone;
    if (this.isRemoteAndFacility)
      requestModel.crmRemoteFacilityId = remoteFacilityId;//this.remoteFacilityId;
    var day = moment(this.searchDate).toDate().getDay();
    if (day == 0) {
      day = Math.pow(2, 6);
    }
    else {
      var power = day - 1;
      day = Math.pow(2, power);
    }
    requestModel.crmCustomDays = day.toString();
    if (this.isBlock) {
      if (this.availId == 0)
        this.SetAvailability(requestModel);
      else
        this.UpdateAvailability(requestModel);
    }
    else {
      if (this.availId == 0) {
        this.SetBreakHours(requestModel);
      }
      else {
        requestModel.isBreakHoursUpdate = true;
        this.UpdateBrekHours(requestModel);
      }
    }
  }

  SetAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {

    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.addBlockSlot(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Availability saved successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  UpdateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.updateBlock(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); }))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.blockSlotPopup = false;
          this.loadingBlockSlotBrakHours = false;
          this.showSuccessMessage('Availability updated successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.blockSlotPopup = false;
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.blockSlotPopup = false;
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  SetBreakHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.loadingBlockSlotBrakHours = true;
    this.isButtonDisabled = true;
    this.progress.next(true);
    this.therapistService.setBreakHours(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.blockSlotPopup = false;
          this.showSuccessMessage('Break Hours added successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.blockSlotPopup = false;
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.blockSlotPopup = false;
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  UpdateBrekHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    this.isButtonDisabled = true;
    this.loadingBlockSlotBrakHours = true;
    this.progress.next(true);
    this.therapistService.updateBreakHours(requestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess && !result.message) {
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.blockSlotPopup = false;
          this.showSuccessMessage('Break Hours updated successfully');
          this.closeSideBar();
          this.cdr.detectChanges();
          this.fetchEvents(true);
        }
        else {
          this.blockSlotPopup = false;
          this.isButtonDisabled = false;
          this.loadingBlockSlotBrakHours = false;
          this.cdr.detectChanges();
          this.showWarningMessage(result.message);
        }
      })
      .catch((httpResponseError) => {
        this.blockSlotPopup = false;
        this.isButtonDisabled = false;
        this.loadingBlockSlotBrakHours = false;
        this.cdr.detectChanges();
        this.showErrorMessage(httpResponseError.message);
      });
    this.loadingCustom = false;
  }

  editSlotCalled(therapistCustomAvailabilityId, availId, faciltiytimeZone) {

    //let faciltiytimeZone = data.eventObject.find(x => x.facilityTimeZone);

    this.therapistService.EditSlot(availId, faciltiytimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistEditBlockResponseModel>) => {
        this.processResult<TherapistEditBlockResponseModel>(result, () => {
          if (result && result.responseModel) {
            this.labelFor = "Block Slot";
            this.isBlock = true;
            this.blockSlotPopup = true;
            this.selectedtherapist = result.responseModel.therapistName;
            this.facilityId = result.responseModel.facilityId;
            this.isRightPanelBlockEnabled = true;
            this.isRightPanelEnabled = false;
            this.blockDesc = result.responseModel.desc;
            this.searchDate = result.responseModel.crmCustomDate;
            let start = result.responseModel.crmStartTime;
            let end = result.responseModel.crmEndTime;
            this.customStartTime = moment(start).format("hh:mm a");
            this.customEndTime = moment(end).format("hh:mm a");
            this.availId = availId;
            this.therapistCustomAvailabilityId = therapistCustomAvailabilityId;
            this.therapisId = result.responseModel.therapistId;
            this.selectedFacilityTimeZone = faciltiytimeZone;
            this.cdr.detectChanges();
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  removeSlotCalled(therapistCustomAvailabilityId, availId, data) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to remove blocked slot?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      let faciltiytimeZone = data;
      this.therapistService.removeSlotWithTimeZone(availId, faciltiytimeZone)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            if (result && result.responseModel) {
              this.showSuccessMessage('Availability removed successfully');
              this.fetchEvents(true);
              this.cdr.detectChanges();
            }
          });
        }, (httpResponseError) => {

        });
    });
  }

  editBreakCalled(therapistCustomAvailabilityId, availId, facilityTimeZone) {

    //let faciltiytimeZone = //data.find(x => x.availId == availId).facilityTimeZone;
    this.therapistService.EditBreak(availId, facilityTimeZone)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<TherapistEditBlockResponseModel>) => {
        this.processResult<TherapistEditBlockResponseModel>(result, () => {
          if (result && result.responseModel) {
            this.blockSlotPopup = true;
            this.isBlock = false;
            this.labelFor = "Add Break Hours";
            this.selectedtherapist = result.responseModel.therapistName;
            this.isRightPanelBlockEnabled = true;
            this.isRightPanelEnabled = false;
            this.blockDesc = result.responseModel.desc;
            this.searchDate = result.responseModel.crmCustomDate;
            let start = result.responseModel.crmStartTime;
            let end = result.responseModel.crmEndTime;
            this.customStartTime = moment(start).format("hh:mm a");
            this.customEndTime = moment(end).format("hh:mm a");
            this.availId = availId;
            this.therapistCustomAvailabilityId = therapistCustomAvailabilityId;
            this.selectedFacilityTimeZone = facilityTimeZone;
            this.therapisId = result.responseModel.therapistId;
            this.facilityId = result.responseModel.facilityId;
            this.cdr.detectChanges();
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  removeBreakCalled(therapistCustomAvailabilityId, availId, data) {

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to remove break hours?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.therapistService.removeBreak(availId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            if (result && result.responseModel) {
              this.cdr.detectChanges();
              this.fetchEvents(true);
            }
          });
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }
  exportPDF() {
    this.exportData1(true);
    setTimeout(() => {
      if (!this.exportDataRes) {
        this.showWarningMessage('No Appointments for selected Date.');
        return;
      }
      var data = document.getElementById('exportData');
      data.style["display"] = "block";
      html2canvas(data).then(canvas => {
        // Few necessary setting options  
        var imgWidth = 208;
        var imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png')
        let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
        var position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
        let pdfName = this.selectedTherapists.appUserFullname + ".pdf"
        pdf.save(pdfName); // Generated PDF   
      });
      data.style["display"] = "none";
    }, 2000);

  }

  exportData1(isRefresh: boolean) {
    this.loading = true;
    this.therapistName = this.selectedTherapists.appUserFullname;
    this.theraistAppointmentService.GetTherapistAppointmentForExportWeekViewDME(this.selectedTherapists.appUserInfoId, this.viewDate.toDateString(), isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SchedulerResponseModelForExportFinal>(result, () => {
          this.loading = false;

          if (result.responseModel != undefined) {
            this.exportDataRes = [];
            this.exportAppointmentCnt = [];
            if (result.responseModel.schedulerResponseModelForExportDbs != null) {

              var data = document.getElementById('exportData');
              data.style["display"] = "block";
              this.exportDataRes = result.responseModel.schedulerResponseModelForExportDbs;
              this.exportAppointmentCnt = result.responseModel.appointmentCountForExportPDFs;
              data.style["display"] = "none";
            }
          }
        });
      }).catch((httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  exportCSV() {
    if (this.loading)
      this.loading = false;
    this.loading = true;
    this.theraistAppointmentService.GetTherapistAppointmentsExportCSVWeekViewDME(this.selectedTherapists.appUserInfoId, this.viewDate.toDateString())
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        let pdfName = this.selectedTherapists.appUserFullname + ".csv"
        a.download = pdfName;
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }


  private async getAllClassificationType() {
    await this.appointmentService.GetAllClassificationType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<FilterClassificationTypeResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.filterClassificationList = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  convertTo12HourFormat(time24) {
    const [hours, minutes, seconds] = time24.split(':').map(Number);
    const period = hours < 12 ? 'am' : 'pm';

    // Convert hours to 12hour format
    const hours12 = (hours % 12) || 12;

    // Formating time in to 12-hour format
    const formattedTime = `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    return formattedTime;
  }

  // updateEndTimeOptions(selectedStartTime: string) {
  //   this.EndTimeSelections = this.StartTimeSelections.slice(
  //     this.StartTimeSelections.findIndex((time) => time.name === selectedStartTime) + 1
  //   );
  // }

  // updateStartEndTimeOptions(selectedStartTime: string, selectedEndTime: string) {
  //   if (selectedStartTime == undefined || selectedEndTime == undefined) {
  //     return;
  //   }

  //   const startIndex = this.TimeSelectionList.findIndex((time) => time.name === selectedStartTime);
  //   const endIndex = this.TimeSelectionList.findIndex((time) => time.name === selectedEndTime);

  //   // Set hours timing between Start Time and End Time
  //   this.StartTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);
  //   this.EndTimeSelections = this.TimeSelectionList.slice(startIndex, endIndex + 1);

  //   if (this.customStartTime != undefined) {
  //     this.EndTimeSelections = this.StartTimeSelections.slice(
  //       this.StartTimeSelections.findIndex((time) => time.name === this.customStartTime) + 1
  //     );
  //   }
  // }

  // LegendsClick() {
  //   this.LegendsisActive = !this.LegendsisActive;
  //   $("body").toggleClass("show-legends"); 
  // }
  // LegendsClickClose (){
  //   this.LegendsisActive = !this.LegendsisActive;
  //   $("body").removeClass("show-legends"); 
  // }

  RefreshPage(){
    this.onFilterSearch();
  }

}
