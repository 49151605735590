import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { TherapistService } from '../../servicies/therapist.service';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { FacilityService } from '../../servicies/facility.service';
import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { TherapistCertificationResponseModel } from 'src/app/shared/models/response/therapist/therapist-certification-response-model';
import { TherapistLicenseResponseModel } from 'src/app/shared/models/response/therapist/therapist-license-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { TherapistDefaultFacilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-response-model';
import { UserBranchResponseModel } from 'src/app/shared/models/response/user-branch-response-model';
import { Time } from '@angular/common';
import { TherapistCustomAvailabilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { TherapistAvailabilityResponseModel, TherapistSetAvailabilityRequestModel, TherapistSetAvailabilityRequestModelSend, TherapistAvailabilityResponseModelSend } from 'src/app/shared/models/response/therapist/therapist-availability-response-model';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { AvailDisplayMode } from 'src/app/shared/models/response/therapist/therapist-availability-display-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { stringify } from 'querystring';

import { Editor } from "primeng/editor";
import { ViewChild } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { TherapistContactInfoResponseModel, TherapistInfoModel } from 'src/app/shared/models/response/therapist/therapist-info-model';
import { TherapistDefaultFacilityModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-model';
import { TherapistDeviceInfoModel } from 'src/app/shared/models/response/therapist/therapist-device-info-model';
import { TherapistLicenseInfoModel } from 'src/app/shared/models/response/therapist/therapist-licenseinfo-model';
import { TherapistOnlineMeetingModel, TherapistOnlineMeetingResponseModel } from 'src/app/shared/models/response/therapist/therapist-onlinemeeting-model';
import * as moment from 'moment';
import { PagedResponseModel } from '../../../shared/models/response/paged-response-model';

@Component({
  selector: 'app-therapist-manage-new',
  templateUrl: './therapist-manage-new.component.html',
  styleUrls: ['./therapist-manage-new.component.sass'],
  providers: [DialogService]
})
export class TherapistManageNewComponent extends BaseComponent implements OnInit, OnDestroy {

  public Editor = ClassicEditor;

  @ViewChild('textEditor') textEditor: Editor;


  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private therapistId: string;
  title = '';
  titleName = '';
  companyName = '';
  isEditMode: boolean = false;
  isActive: boolean = true;
  appDateFormat: DateFormatModel;
  // New availability popup variables
  SetAvailabilityPopup: boolean = false;
  isTabDisabled: boolean = true;
  isWorking: boolean = true;
  selectedWorking: string = 'W';
  facilities: FacilityResponseModelDropdown[];
  selectedFacilities: FacilityResponseModelDropdown;
  weekDays: EnumModel[] = [];
  selectedWeekDays: EnumModel[] = [];
  selectedWeekDay: EnumModel;
  eventSeries: EnumModel[];
  prevEventSeries: EnumModel;
  selectedEventSeries: EnumModel;
  multiAvailability: TherapistAvailabilityResponseModelSend[] = [];
  multipleTherapistCustomAvailability: TherapistCustomAvailabilityResponseModel[] = [];
  breakStartTime: Date;
  breakEndTime: Date;
  description: string;
  defaultFacility: string;
  isRoadShow: boolean = false;
  availDisplayMode: AvailDisplayMode[] = [];
  localEditId: number = 0;
  localUUID: number;
  loading: boolean = false;
  showSetAvailButton: boolean = false;
  isAllowSelfScheduleAppointments: boolean = false;
  // New availability popup variables
  searchRequestModelGeneralAuditLogs: SearchRequestModel;
  // from vijaybhai's code
  statsModel: StateMasterResponseModel[];
  minimumDate = new Date();
  formSubmitted: boolean = false;
  formDeviceSubmitted: boolean = false;
  formLicenseSubmitted: boolean = false;
  formServiceSubmitted: boolean = false;
  formMeetingSubmitted: boolean = false;
  licenseEditMode: boolean = false;
  deviceEditMode: boolean = false;
  isUpdateDeviceLicenseInfo = false;
  index: number = 0;
  indexTab: number = 0;
  profileLogo: string;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  crmProfileImageFormat: string;
  bioEditorText: string = '';


  //common
  therapistResponseModel: UserInfoResponseModel;
  selectedState: StateMasterResponseModel;
  selectedStateBio: StateMasterResponseModel;
  selectedDevice: { name: string };
  selectedDeviceModel: TherapistCertificationResponseModel;
  selectedLicenseModel: TherapistLicenseResponseModel;
  certificationResponseModel: TherapistCertificationResponseModel[];
  licenseResponseModel: TherapistLicenseResponseModel[];
  licenseExpiryDate: Date;

  selectedDevices: { name: string }[];
  isLicenseChecked: boolean;

  // from my code
  isRemoteAssistant: boolean = false;
  isRemoteAssistantCustom: boolean = false;
  selectedOccurrence: { name: string, code: number };
  selectedSpecialEvent: { name: string, code: string };
  therapistAvailability: TherapistAvailabilityResponseModel;
  therapistSetAvailabilityRequestModel: TherapistSetAvailabilityRequestModel;

  //facilities: { label: string, value: string }[];
  selectedDefaultFacility: { label: string, value: string };
  searchRequestModel: SearchRequestModel;
  selectedLicenseState: StateMasterResponseModel;
  certificateNumber: string;
  licenseNumber: string;
  currentUserToken: UserAuthResponseModel;
  stateMaster: StateMasterResponseModel[];
  facilityList: FacilityResponseModelDropdown[];
  selectedFacility: FacilityResponseModelDropdown;

  AvailabilityStartDate: Date;
  AvailabilityEndDate: Date;
  customStartDate: Date;
  customStartTime: Date;
  customEndDate: Date;
  customEndTime: Date;
  isViewSelfScheduleAppointments: boolean = false;
  isNewManageAvailability: boolean = false;
  formFields = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    phoneNumber: 'phoneNumber',
    address: 'address',
    state: 'state',
    city: 'city',
    zipcode: 'zipcode',
    facilityList: 'facilityList',
    deviceName: 'deviceName',
    deviceCertificate: 'deviceCertificate',
    licenseState: 'licenseState',
    licenseNumber: 'licenseNumber',
    licenseExpiry: 'licenseExpiry',
    coverageArea: 'coverageArea',
    practitionerId: 'practitionerId',
    meetingUrl: 'meetingUrl',
    meetingId: 'meetingId',
    meetingPasscode: 'meetingPasscode',
    bioEditorText: 'bioEditorText',
    brightreeInternalUserId: 'brightreeInternalUserId',
    deliveryTechnicianId: 'deliveryTechnicianId'
  };

  disablebutton = {
    DeviceButton: true,
    LicenseButton: true
  };

  validationMessages =
    {
      firstName: {
        required: 'First name is required',
        validCharacters: 'First name should contain only characters'
      },
      lastName: {
        required: 'Last name is required',
        validCharacters: 'Last name should contain only characters'
      },
      email: {
        required: 'Email address is required',
        email: 'Enter valid email address'
      },
      phoneNumber: {
        required: 'Phone Number is required',
        // invalid: 'Phone Number is invalid'
      },
      address: {
        required: 'Address is required',
        validCharacters: 'Enter valid address',
        maxlength: 'Max 1000 characters allowed'
      },
      state: {
        required: 'State is required',
        // invalid: 'Phone Number is invalid'
      },
      zipcode: {
        required: 'Zip code is required',
        validNumber: 'Enter valid zip code',
      },
      practitionerId: {
        required: 'Practitioner Id is required',
        validNumber: 'Enter valid Practitioner Id',
      },
      city: {
        required: 'city is required',
        // invalid: 'Phone Number is invalid'
      },
      bioEditorText: {
        required: 'Biodata / Skills is required',
        maxlength: 'Max 2000 characters allowed'
      },
      facilityList: {
        required: 'Default facility is required',
      },
      coverageArea: {
        required: 'Coverage area is required',
        validNumber: 'Enter valid coverage Area',
      },
      meetingUrl: {
        required: 'Online meeting link is required',
        validZoomURL: 'Please enter valid url',
      },
      meetingId: {
        required: 'Meeting ID is required',
        validNumber: 'Enter valid meeting id',
      },
      meetingPasscode: {
        required: 'Passcode is required',
      },
      deviceName: {
        required: 'Device name is required',
      },
      deviceCertificate: {
        required: 'Device certificate is required',
      },
      licenseState: {
        required: 'License state is required',
      },
      licenseNumber: {
        required: 'License number is required',
      },
      licenseExpiry: {
        required: 'License expiry date is required',
      },

      brightreeInternalUserId: {
        required: 'Brightree Internal User Id is required',
        validNumber: 'Enter valid brightree Internal User Id',
      },

      deliveryTechnicianId: {
        required: 'Delivery Technician Id is required',
        validNumber: 'Enter valid Delivery Technician Id',
      },
    };

  device = [
    // { name: 'Select' },
    { name: 'BIPAP' },
    { name: 'CPAP' },
    { name: 'General DME' },
    { name: 'Oxygen' },
    { name: 'Ventilators' },
    { name: 'OSA & Mask Fits' },
  ];

  Occurrence = [
    { name: '1', code: 1 },
    { name: '2', code: 2 },
    { name: '3', code: 3 },
    { name: '4', code: 4 },
    { name: '5', code: 5 },
  ];
  clickEventFilterStatus: boolean;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private therapistService: TherapistService,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
    this.appDateFormat = this.webStorageService.getDateFormat();
    var companyInfo = this.webStorageService.getClientConfig();
    this.companyName = companyInfo.companyName;
    this.isNewManageAvailability = companyInfo.isNewManageAvailability;
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.progress) {
      this.progress.complete();
    }
  }
  onViewHistory() {
    let setUrl = '/admin/therapists/history/' + this.therapistId + '/threapist';
    this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel } });
  }
  uploadLogoFile(fileInput: any) {


    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      // const allowed_types = ['image/png'];
      const allowed_types = ['image/png', 'image/jpeg'];


      const max_size = 600000;
      const max_height = 300;
      const max_width = 200;

      //if (fileInput.target.files[0].size > max_size) {
      //  this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Kb';
      //  this.showErrorMessage(this.imageError);

      //  return false;
      //}

      var isAllowedType: boolean = false;
      allowed_types.forEach((type) => {
        if (type === fileInput.target.files[0].type) {
          isAllowedType = true;
        }
      });

      if (!isAllowedType) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        this.imageError = 'Only png images are allowed.';
        this.showErrorMessage(this.imageError);
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          //const img_height = rs.currentTarget['height'];
          //const img_width = rs.currentTarget['width'];

          //// console.log(img_height, img_width);


          //if (img_height > max_height && img_width > max_width) {
          //  this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          //  this.showErrorMessage(this.imageError);
          //  return false;
          //} else {

          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          const indexOfComma = imgBase64Path.indexOf(',', 0);
          var logoString = this.cardImageBase64.slice(indexOfComma + 1);
          this.crmProfileImageFormat = fileInput.target.files[0].type;
          this.profileLogo = logoString;
          this.isImageSaved = true;
          this.indexTab = 0;
          //}
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  handleChange(e) {

    this.indexTab = e.index;
    //this.scrollIssue();
  }

  async ngOnInit() {


    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.therapistId = params['id'];
      });
    if (this.therapistId) {
      this.title = 'Edit Therapist';
      this.showSetAvailButton = true;
    }
    else {
      this.title = 'Add Therapist';
    }
    //New popup
    this.getFacilitiesPopup("false");
    this.getDaysOfWeek();
    this.getEventSeries();
    //New popup
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    this.initializeVariables();
    // this.selectedCertificate = new TherapistCertificationResponseModel();
    // this.selectedLicense = new TherapistLicenseResponseModel();
    this.certificationResponseModel = new Array<TherapistCertificationResponseModel>();
    this.licenseResponseModel = new Array<TherapistLicenseResponseModel>();
    this.builForm();
    await this.getStates();
    if (this.therapistId != undefined) {
      await this.getFacilities(this.therapistId);
    } else {
      //await this.getFacilities(-1);
    }
    await this.getTherapist(this.therapistId);
    this.index = 1;
    this.selectedDevices = [];
  }

  initializeVariables() {
    this.selectedOccurrence = { name: '1', code: 1 };
    this.therapistAvailability = new TherapistAvailabilityResponseModel();
    this.multipleTherapistCustomAvailability = new Array<TherapistCustomAvailabilityResponseModel>();
    this.multiAvailability = new Array<TherapistAvailabilityResponseModelSend>();
  }

  get fPersonal() { return (<FormGroup>this.form.get('formPersonal')).controls; }

  get fService() { return (<FormGroup>this.form.get('formService')).controls; }

  get fMeeting() { return (<FormGroup>this.form.get('formMeeting')).controls; }

  get fDevice() { return (<FormGroup>this.form.get('formDevice')).controls; }

  get fLicense() { return (<FormGroup>this.form.get('formLicense')).controls; }

  get formPersonal() {
    return this.form.get('formPersonal');
  }

  get formService() {
    return this.form.get('formService');
  }

  get formMeeting() {
    return this.form.get('formMeeting');
  }

  get formDevice() {
    return this.form.get('formDevice');
  }

  get formLicense() {
    return this.form.get('formLicense');
  }

  onSpecialEventChange(specialEvent) {
    this.selectedSpecialEvent = specialEvent.value;
  }

  onCancel() {
    this.router.navigate(['admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onResetPersonalForm() {
    this.formSubmitted = false;
    const form = this.formPersonal;
    if (this.isEditMode === false) {
      form.reset();
      form.get(this.formFields.firstName).setValue('');
      form.get(this.formFields.lastName).setValue('');
      form.get(this.formFields.email).setValue('');
      form.get(this.formFields.phoneNumber).setValue('');
      form.get(this.formFields.address).setValue('');
      form.get(this.formFields.city).setValue('');
      form.get(this.formFields.zipcode).setValue('');
      form.get(this.formFields.bioEditorText).setValue('');
      form.get(this.formFields.practitionerId).setValue('');
      form.get(this.formFields.deliveryTechnicianId).setValue('');
      this.selectedStateBio = new StateMasterResponseModel();
    }
    else {
      this.setValuesToPersonalForm();
    }
    // 48659_1589
    this.router.navigate(['admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onResetServieForm() {
    this.formServiceSubmitted = false;
    const form = this.formService;
    if (this.isEditMode === false) {
      form.reset();
      form.get(this.formFields.coverageArea).setValue('');
      this.selectedFacility = new FacilityResponseModelDropdown();
    }
    else {
      this.setValuesToServiceForm();
    }
  }

  onResetOnlineMeeingForm() {
    this.formMeetingSubmitted = false;
    const form = this.formMeeting;
    if (this.isEditMode === false) {
      form.reset();
      form.get(this.formFields.meetingId).setValue('');
      form.get(this.formFields.meetingPasscode).setValue('');
      form.get(this.formFields.meetingUrl).setValue('');
    }
    else {
      this.setValuesToMeetingForm();
    }
  }



  onBack() {
    this.router.navigate(['/admin/therapists'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onChangeEventSeries(item: EnumModel) {
    item.isSelected = !item.isSelected;
    if (this.prevEventSeries && this.prevEventSeries.name !== item.name) {
      this.prevEventSeries.isSelected = false;
    }
    this.prevEventSeries = item;
  }

  onChangeDayOfWeek(item: EnumModel) {
    item.isSelected = !item.isSelected;
  }

  onEventOccurrenceChange(occurrence) {
    this.selectedOccurrence = occurrence.value;
  }

  OnDeviceNameChange(device) {
    this.selectedDevices = device.value;
  }

  onCertificationAdd() {
    this.formSubmitted = false;
    this.formServiceSubmitted = false;
    this.formMeetingSubmitted = false;
    this.formDeviceSubmitted = true;
    this.formLicenseSubmitted = false;
    if (this.formDevice.invalid) {
      this.markFormAsTouched();
      return;
    }

    const deviceModel = this.getValuesFromDeviceForm();

    var listOfDevicesFromGrid = [];
    var tempDevice;

    this.certificationResponseModel.forEach((certification) => {
      if (certification.deviceName.indexOf(',') > -1) {
        var deviceArray = certification.deviceName.split(',');
        deviceArray.forEach((result) => {
          // selectedDevicesToAdd.push({name: y});
          listOfDevicesFromGrid.push(result);
        });
      } else {
        tempDevice = certification.deviceName;
        // selectedDevicesToAdd.push({name: device});
        listOfDevicesFromGrid.push(tempDevice);
      }
    });

    var isDeviceIncluded;

    for (let search of this.selectedDevices) {
      isDeviceIncluded = listOfDevicesFromGrid.includes(search.name, 0);
      if (isDeviceIncluded) {
        // if (this.deviceEditMode) {
        //   break;
        // } else {
        //   this.showErrorMessage('Device ' + search.name + ' already exist.');
        //   return;
        // }
        if (!this.deviceEditMode) {
          this.showErrorMessage('Device ' + search.name + ' already exist.');
          return;
        }
      }
    }
    if (this.certificationResponseModel.length > 0) {
      if (this.deviceEditMode) {
        // // Used For single device
        // let currentDevice = this.certificationResponseModel.find(x => x.deviceName === deviceModel.deviceName
        //   && x.certificateNumber === deviceModel.certificateNumber
        //   && x.therapistCertificationId !== this.selectedDeviceModel.therapistCertificationId);
        // if (currentDevice) {
        //   this.showErrorMessage("Device " + deviceModel.deviceName + ' is already exist.');
        //   return;
        // }

        // Used for multiple devices, if certificate number already exists
        var selectedDeviceModelForEditList = [];
        if (this.selectedDeviceModel.deviceName.indexOf(',') > -1) {
          var selectedDeviceModelArray = this.selectedDeviceModel.deviceName.split(',');
          selectedDeviceModelArray.forEach((result) => {
            selectedDeviceModelForEditList.push(result);
          });
        } else {
          tempDevice = this.selectedDeviceModel.deviceName;
          selectedDeviceModelForEditList.push(tempDevice);
        }

        // listOfDevicesFromGrid.splice(selectedDeviceModelForEditList);
        selectedDeviceModelForEditList.forEach((result) => {
          var indexOfDevice = listOfDevicesFromGrid.indexOf(result);
          listOfDevicesFromGrid.splice(indexOfDevice, 1);
        });

        for (let search of this.selectedDevices) {
          isDeviceIncluded = listOfDevicesFromGrid.includes(search.name, 0);
          if (isDeviceIncluded) {
            if (this.deviceEditMode) {
              this.showErrorMessage('Device ' + search.name + ' already exist.');
              return;
            }
          }
        }

        let currentDevice = this.certificationResponseModel.find(x => x.certificateNumber === deviceModel.certificateNumber
          && x.therapistCertificationId !== this.selectedDeviceModel.therapistCertificationId);
        if (currentDevice) {
          this.showErrorMessage('Certificate ' + deviceModel.certificateNumber + ' already exist.');
          return;
        }

      }
      else {
        // // Used For single device
        // let currentDevice = this.certificationResponseModel.find(x => x.deviceName === deviceModel.deviceName
        //   && x.certificateNumber === deviceModel.certificateNumber);
        // if (currentDevice) {
        //   this.showErrorMessage("Device " + deviceModel.deviceName + ' is already exist.');
        //   return;
        // }

        // Used for multiple devices, if certificate number already exists
        let currentDevice = this.certificationResponseModel.find(x => x.certificateNumber === deviceModel.certificateNumber);
        if (currentDevice) {
          this.showErrorMessage('Certificate ' + deviceModel.certificateNumber + ' already exist.');
          return;
        }
      }

    }

    if (this.deviceEditMode) {
      this.certificationResponseModel.forEach((result) => {
        if (result.therapistCertificationId === this.selectedDeviceModel.therapistCertificationId) {
          const index = this.certificationResponseModel.indexOf(result, 0);
          if (index > -1) {
            this.certificationResponseModel.splice(index, 1);
          }
        }
      });
      deviceModel.therapistCertificationId = this.selectedDeviceModel.therapistCertificationId;
    } else {

      if (this.certificationResponseModel.length > 0) {

        let maxiD = Math.max.apply(null, this.certificationResponseModel.map(e => {
          return e.therapistCertificationId;
        }));
        deviceModel.therapistCertificationId = maxiD + 1;

      } else {
        deviceModel.therapistCertificationId = 1;
      }

    }

    //deviceModel.certificateNumber = this.selectedDevice.name;
    this.certificationResponseModel.push(deviceModel);
    this.selectedDeviceModel = new TherapistCertificationResponseModel();
    this.deviceEditMode = false;
    //this.formDevice.reset()
    this.reSetValuesToDeviceForm();
    this.HideShowDeviceButton();
  }

  onCertificationEdit(certification: TherapistCertificationResponseModel) {

    this.deviceEditMode = true;
    this.formDevice.reset();

    // this.selectedDevice = this.device.find(x => x.name === certification.deviceName);
    if (this.selectedDevices == null) {
      this.selectedDevices = [];
    }

    var devices = [];
    if (certification.deviceName.indexOf(',') > -1) {
      devices = certification.deviceName.split(',');
    } else {
      devices[0] = certification.deviceName;
    }
    if (devices.length >= 1) {
      devices.forEach((device) => {
        this.selectedDevices.push({ name: device });
      });
    } else {
      this.selectedDevices.push({ name: devices[0] });;
    }
    this.selectedDeviceModel = certification;

    this.setValuesToDeviceForm();
  }

  onCertificationDelete(certi: TherapistCertificationResponseModel) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to delete?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (response) {
        this.reSetValuesToDeviceForm();
        const index = this.certificationResponseModel.indexOf(certi, 0);
        if (index > -1) {
          this.certificationResponseModel.splice(index, 1);
        }
        this.HideShowDeviceButton();
      }
    });
  }

  HideShowDeviceButton() {
    if (this.certificationResponseModel?.length > 0)
      this.disablebutton.DeviceButton = false;
    else
      this.disablebutton.DeviceButton = true;
  }

  onNotifyLicenseCheck(status) {
    this.licenseResponseModel.forEach((license) => {
      license.isLicenseCheck = status.checked;
    });
  }

  onLicenseAdd() {
    this.formSubmitted = false;
    this.formServiceSubmitted = false;
    this.formMeetingSubmitted = false;
    this.formDeviceSubmitted = false;
    this.formLicenseSubmitted = true;
    if (this.formLicense.invalid) {
      this.markFormAsTouched();
      return;
    }
    const licenseModel = this.getValuesFromLicenseForm();
    if (this.licenseResponseModel.length > 1) {
      if (this.licenseEditMode) {
        let currentLicense = this.licenseResponseModel.find(x => x.licenceNumber === licenseModel.licenceNumber
          && x.stateMasterId === licenseModel.stateMasterId && x.therapistLicenseInfoId != this.selectedLicenseModel.therapistLicenseInfoId);
        if (currentLicense) {
          this.showErrorMessage("License number " + licenseModel.licenceNumber + ' is already exist.');
          return;
        }
      }
      else {
        let currentLicense = this.licenseResponseModel.find(x => x.licenceNumber === licenseModel.licenceNumber
          && x.stateMasterId === licenseModel.stateMasterId);
        if (currentLicense) {
          this.showErrorMessage("License number " + licenseModel.licenceNumber + ' is already exist.');
          return;
        }
      }

    }
    if (this.licenseEditMode) {
      this.licenseResponseModel.forEach((result) => {
        if (result.therapistLicenseInfoId === this.selectedLicenseModel.therapistLicenseInfoId) {
          const index = this.licenseResponseModel.indexOf(result, 0);
          if (index > -1) {
            this.licenseResponseModel.splice(index, 1);
          }
        }
      });
      licenseModel.therapistLicenseInfoId = this.selectedLicenseModel.therapistLicenseInfoId;
    } else {

      if (this.licenseResponseModel.length > 0) {

        let maxiD = Math.max.apply(null, this.licenseResponseModel.map(e => {
          return e.therapistLicenseInfoId;
        }));
        licenseModel.therapistLicenseInfoId = maxiD + 1;

      } else {
        licenseModel.therapistLicenseInfoId = 1;
      }

    }
    licenseModel.stateMasterId = this.selectedState.appStateMasterId;
    this.licenseResponseModel.push(licenseModel);
    this.selectedLicenseModel = new TherapistLicenseResponseModel();
    this.licenseEditMode = false;
    //this.formLicense.reset({})
    this.reValuesToLicenseForm();
    this.HideShowLicenseButton();
  }

  onLicenseEdit(license: TherapistLicenseResponseModel) {
    this.licenseEditMode = true;
    this.formLicense.reset();
    this.selectedState = this.statsModel.find(x => x.appStateMasterId === license.stateMasterId);
    this.selectedLicenseModel = license;
    this.setValuesToLicenseForm();
  }

  onLicenseDelete(lic: TherapistLicenseResponseModel) {
    var selectedDate = new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString() + '-' + new Date().getDate().toString();
    this.loading = true;
    this.therapistService.ValidationToCheckTherapistLicensewithDefaultFacilityAndAvailiblity( this.therapistResponseModel.appUserInfoId.toString(), this.therapistResponseModel.therapistDefaultFacility[0].crmFacilityId.toString(), selectedDate,lic.stateMasterId.toString() )
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result && result.isSuccess) {

          if (result.responseModel != undefined && result.responseModel != null) {
            if (result.responseModel == true) {
              this.showWarningMessage(`Deletion of the '${lic.stateMaster.appStateName}' state license is not allowed as either the therapist has availability for this state facility or this is the state of the therapist's default facility.`);             
              this.loading = false;
              return;
            }
          }
          this.loading = false;
          this.dialougeService.open(ConfirmDialogComponent, {
            data:
            {
              message: 'Are you sure you want to delete?',
            },
            header: 'Confirmation'
          }).onClose.subscribe((response: boolean) => {
            if (response) {
              this.reValuesToLicenseForm();
              const index = this.licenseResponseModel.indexOf(lic, 0);
              if (index > -1) {
                this.licenseResponseModel.splice(index, 1);
              }
              this.HideShowLicenseButton();
            }
          });
          

        }
      });
   
    
    
  }

  HideShowLicenseButton() {
    if (this.licenseResponseModel?.length > 0)
      this.disablebutton.LicenseButton = false;
    else
      this.disablebutton.LicenseButton = true;
  }

  onSubmit() {
    this.formSubmitted = true;
    this.formServiceSubmitted = false;
    this.formMeetingSubmitted = false;
    this.formDeviceSubmitted = false;
    this.formLicenseSubmitted = false;
    if (this.formPersonal.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const therapistResponseModel = this.getValuesFromFormPersonal();

    let _stateName: string = '';
    if (therapistResponseModel.therapistContactInfoModel) {
      let contactmodel: TherapistContactInfoResponseModel;
      contactmodel = therapistResponseModel.therapistContactInfoModel;
      let foundBranch = this.statsModel.find(x => x.appStateName == contactmodel.crmState);
      _stateName = foundBranch.appStateName;
    }
    this.therapistService.ValidateZipcodeWithState(_stateName, Number(therapistResponseModel.therapistContactInfoModel.crmZipCode))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            if (result.responseModel == false) {
              this.showWarningMessage('Invalid zip code for ' + _stateName);
              this.loading = false;
              return;
            }
            if (this.isEditMode === true) {
              this.therapistService.UpdateTherapistInfo(therapistResponseModel)
                .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
                .subscribe((result) => {
                  if (result && result.isSuccess) {
                    this.showSuccessMessage('Therapist updated successfully.');
                    this.getTherapist(this.therapistId);
                  } else {
                    this.showErrorMessage(result.message);
                  }
                }, (httpResponseError) => {
                  this.showErrorMessage(httpResponseError.message);
                });
            } else {
              this.therapistService.AddTherapistInfo(therapistResponseModel)
                .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
                .subscribe((result) => {
                  if (result && result.isSuccess) {
                    this.therapistId = result.responseModel.toString();

                    if (this.therapistId.length > 0) {
                      this.showSetAvailButton = true;
                      this.isTabDisabled = false;
                      this.getTherapist(this.therapistId);
                    }
                    this.showSuccessMessage('Therapist added successfully.');

                  } else {
                    this.showErrorMessage(result.message);
                  }
                }, (httpResponseError) => {
                  this.showErrorMessage(httpResponseError.message);
                });
            }
          }
          else {
            this.showWarningMessage('Invalid zip code for ' + _stateName);
            this.loading = false;
          }

        } else {
          this.showErrorMessage(result.message);
          this.loading = false;
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onDeviceSubmit() {
    this.loading = true;
    const therapistResponseModel = this.getValuesFromFormForDevice();

    if (this.isEditMode === true) {
      this.therapistService.UpdateTherapistDeviceInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('Device information updated successfully.');
            this.getTherapist(this.therapistId);
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    } else {
      this.therapistService.AddTherapistDeviceInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {

            this.showSuccessMessage('Device information added successfully.');
            this.getTherapist(this.therapistId);
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  onLicenseSubmit() {
    this.loading = true;
    const therapistResponseModel = this.getValuesFromFormForLicense();

    if (this.isEditMode === true) {
      this.therapistService.UpdateTherapistLicenseInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('License information updated successfully.');
            this.getTherapist(this.therapistId);
            this.reValuesToLicenseForm();
            this.getFacilities(this.therapistId);
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    } else {
      this.therapistService.AddTherapistLicenseInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {

            this.showSuccessMessage('License information added successfully.');
            this.getTherapist(this.therapistId);
            this.getFacilities(this.therapistId);
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  onServiceSubmit() {

    this.formSubmitted = false;
    this.formServiceSubmitted = true;
    this.formMeetingSubmitted = false;
    this.formDeviceSubmitted = false;
    this.formLicenseSubmitted = false;
    if (this.formService.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const therapistResponseModel = this.getValuesFromFormForDefaultFacility();
    var selectedDate = new Date().getFullYear().toString() + '-' + (new Date().getMonth() + 1).toString() + '-' + new Date().getDate().toString();
    this.therapistService.CheckTherapistAvailabilityOnDefaulFacility(therapistResponseModel.appUserInfoId.toString(), therapistResponseModel.therapistDefaultFacility.crmFacilityId.toString(), selectedDate)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(true)))
      .subscribe((result) => {
        if (result && result.isSuccess) {
          let isFacilityChanged = false;

          if (this.isEditMode === true
            && this.therapistResponseModel.crmFacilityId != null && this.therapistResponseModel.crmFacilityId != undefined
            && this.therapistResponseModel.crmFacilityId != therapistResponseModel.therapistDefaultFacility.crmFacilityId) {
            isFacilityChanged = true;
          }

          if (result.responseModel != undefined && result.responseModel != null && (result.responseModel != true || !isFacilityChanged)) {
            if (this.isEditMode === true) {
              this.therapistService.UpdateTherapistDefaultFacility(therapistResponseModel)
                .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
                .subscribe((result) => {
                  if (result && result.isSuccess) {
                    this.showSuccessMessage('Service information updated successfully.');
                    this.getTherapist(this.therapistId);
                  } else {
                    this.showErrorMessage(result.message);
                  }
                }, (httpResponseError) => {
                  this.showErrorMessage(httpResponseError.message);
                });
            } else {
              this.therapistService.AddTherapistDefaultFacility(therapistResponseModel)
                .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
                .subscribe((result) => {
                  if (result && result.isSuccess) {
                    this.getTherapist(this.therapistId);
                    this.showSuccessMessage('Service information added successfully.');
                    this.formServiceSubmitted = false;
                  } else {
                    this.showErrorMessage(result.message);
                  }
                }, (httpResponseError) => {
                  this.showErrorMessage(httpResponseError.message);
                });
            }
          }
          else {
            this.showErrorMessage('Please remove your existing availability to change facility.');
            this.loading = false;
          }
        }
      });




  }

  onMeetingSubmit() {
    this.formSubmitted = false;
    this.formServiceSubmitted = false;
    this.formMeetingSubmitted = true;
    this.formDeviceSubmitted = false;
    this.formLicenseSubmitted = false;
    if (this.formMeeting.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const therapistResponseModel = this.getValuesFromFormForOnlinemeeting();

    if (this.isEditMode === true) {
      this.therapistService.UpdateTherapistonlineMeetingInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.showSuccessMessage('Online meeting information updated successfully.');
            this.getTherapist(this.therapistId);
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    } else {
      this.therapistService.AddTherapistonlineMeetingInfo(therapistResponseModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.getTherapist(this.therapistId);
            this.showSuccessMessage('Online meeting information added successfully.');
            this.formMeetingSubmitted = false;
          } else {
            this.showErrorMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  public getValuesFromDeviceForm(): TherapistCertificationResponseModel {
    const form = this.formDevice;
    const therapistCertificationResponseModel = new TherapistCertificationResponseModel();
    // //form.get(this.formFields.deviceName).value;
    // therapistCertificationResponseModel.deviceName = this.selectedDevice.name;

    var tempDevices = new String();
    if (this.selectedDevices.length > 1) {
      this.selectedDevices.forEach((selectedDevice) => {
        tempDevices = tempDevices + selectedDevice.name + ',';
      });
      therapistCertificationResponseModel.deviceName = tempDevices.slice(0, -1);
    } else {
      therapistCertificationResponseModel.deviceName = this.selectedDevices[0].name;
    }

    therapistCertificationResponseModel.certificateNumber = form.get(this.formFields.deviceCertificate).value;
    return therapistCertificationResponseModel;
  }

  public reSetValuesToDeviceForm() {
    this.formDeviceSubmitted = false;
    const form = this.formDevice;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.deviceCertificate).setValue('');
    // this.selectedDevice = null;
    this.selectedDevices = [];
  }

  public getValuesFromLicenseForm(): TherapistLicenseResponseModel {
    const form = this.formLicense;
    const therapistLicenseResponseModel = new TherapistLicenseResponseModel();
    therapistLicenseResponseModel.stateMaster = new StateMasterResponseModel();
    therapistLicenseResponseModel.licenceNumber = form.get(this.formFields.licenseNumber).value;
    therapistLicenseResponseModel.expiryDate = form.get(this.formFields.licenseExpiry).value;
    therapistLicenseResponseModel.expiryDate = this.licenseExpiryDate;
    therapistLicenseResponseModel.stateMasterId = this.selectedState.appStateMasterId;
    therapistLicenseResponseModel.stateMaster.appStateName = this.selectedState.appStateName;//form.get(this.formFields.licenseState).value;
    return therapistLicenseResponseModel;
  }

  public reValuesToLicenseForm() {
    this.formLicenseSubmitted = false;
    const form = this.formLicense;
    this.selectedState = new StateMasterResponseModel();
    form.get(this.formFields.licenseNumber).setValue('');
    form.get(this.formFields.licenseExpiry).setValue('');

  }

  public setValuesToLicenseForm() {
    if (!this.selectedLicenseModel) {
      return;
    }
    const form = this.formLicense;
    //form.get(this.formFields.licenseState).setValue(this.selectedLicenseModel.stateMasterId);
    form.get(this.formFields.licenseNumber).setValue(this.selectedLicenseModel.licenceNumber);
    form.get(this.formFields.licenseExpiry).setValue(new Date(this.selectedLicenseModel.expiryDate));

    // tslint:disable-next-line: max-line-length
  }

  public setValuesToDeviceForm() {

    if (!this.selectedDeviceModel) {
      return;
    }

    const form = this.formDevice;
    // form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.deviceCertificate).setValue(this.selectedDeviceModel.certificateNumber);
    // tslint:disable-next-line: max-line-length
  }



  public async getStates() {
    this.progress.next(true);

    await this.therapistService.GetStates()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<StateMasterResponseModel[]>) => {
        this.processResult<StateMasterResponseModel[]>(result, () => {
          if (!result) {
            return;
          }
          if (result.responseModel != undefined && result.responseModel != null) {
            this.statsModel = result.responseModel;
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public async getTherapist(therapistId: string) {
    if (!therapistId) {
      return;
    }

    this.isEditMode = true;
    this.isTabDisabled = false;

    const therapist = this.therapistService.getTherapistById(therapistId);
    //const therapistAvailability = this.therapistService.getTherapistAvailability(Number(therapistId));

    this.progress.next(true);
    this.loading = true;
    await forkJoin<ResultOfT<UserInfoResponseModel>>(therapist)
      .pipe(takeUntil(this.unsubscriber), finalize(() => { this.progress.next(false); this.loading = false; }))
      .toPromise()
      .then((results) => {
        if (!results) {
          return;
        }
        if (results[0].responseModel != undefined && results[0].responseModel != null) {
          this.therapistResponseModel = results[0].responseModel as UserInfoResponseModel;
          const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
          this.titleName = 'Edit ' + capitalizeFirstLetter(this.therapistResponseModel.appUserLname) + ', ' + capitalizeFirstLetter(this.therapistResponseModel.appUserFname);
          this.certificationResponseModel = this.therapistResponseModel.therapistCertification;
          this.licenseResponseModel = this.therapistResponseModel.therapistLicenseInfo;
          this.setValuesToForm();
        }

        //this.therapistSetAvailabilityRequestModel = results[1].responseModel as TherapistSetAvailabilityRequestModel;
        //this.setValuesToAvailability();
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public async getFacilitiesForAddingTherapist() {
    this.progress.next(true);
    await this.facilityService.getAllFacilityDropDown()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.facilityList = result.responseModel;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public async getFacilities(id: string) {
    this.progress.next(true);
    await this.facilityService.GetFacilitiesWithTherapistExclusion(this.therapistId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.facilityList = result.responseModel;
        }
        // console.log(result.responseModel);
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public getValuesFromForm(): UserInfoResponseModel {
    const form = this.formPersonal;
    const formService = this.formService;
    const therapistResponseModel = new UserInfoResponseModel();
    let facilityResponseModel = new FacilityResponseModelDropdown();
    const therapistDefaultfacilityResponseModel = new TherapistDefaultFacilityResponseModel();
    const userBranch = new UserBranchResponseModel();

    facilityResponseModel = formService.get(this.formFields.facilityList).value;
    if (this.therapistId) {
      therapistDefaultfacilityResponseModel.crmTherapistId = Number(this.therapistId);
    }

    therapistDefaultfacilityResponseModel.crmFacilityId = facilityResponseModel.crmFacilityId;
    therapistDefaultfacilityResponseModel.crmIsDefault = true;
    therapistDefaultfacilityResponseModel.isActive = true;

    therapistResponseModel.therapistDefaultFacility = therapistDefaultfacilityResponseModel;

    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? Number(this.therapistId) : 0;
    therapistResponseModel.appUserFname = form.get(this.formFields.firstName).value;
    therapistResponseModel.appUserLname = form.get(this.formFields.lastName).value;
    therapistResponseModel.appUserEmail = form.get(this.formFields.email).value;
    therapistResponseModel.isActive = this.isActive;
    // therapistResponseModel.appUserPhoneNumber = form.get(this.formFields.phoneNumber).value;

    var tempPhone = form.get(this.formFields.phoneNumber).value;
    if (tempPhone.indexOf('-') > -1) {
      therapistResponseModel.appUserPhoneNumber = tempPhone.split('-').join('');
    } else {
      therapistResponseModel.appUserPhoneNumber = tempPhone;
    }

    userBranch.crmBranchId = 1;
    therapistResponseModel.userBranch = userBranch;

    if (this.certificationResponseModel.length >= 1) {
      therapistResponseModel.therapistCertification = this.certificationResponseModel;
    }
    if (this.licenseResponseModel.length >= 1) {
      therapistResponseModel.therapistLicenseInfo = this.licenseResponseModel;
    }

    this.HideShowLicenseButton();
    this.HideShowDeviceButton();
    return therapistResponseModel;
  }

  public getValuesFromFormPersonal(): TherapistInfoModel {
    const form = this.formPersonal;
    const therapistResponseModel = new TherapistInfoModel();

    const therapistContactInfoResponseModel = new TherapistContactInfoResponseModel();
    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? Number(this.therapistResponseModel.appUserInfoId) : 0;
    therapistResponseModel.enAppUserInfoId = this.therapistId;
    therapistResponseModel.appUserFname = form.get(this.formFields.firstName).value;
    therapistResponseModel.appUserLname = form.get(this.formFields.lastName).value;
    therapistResponseModel.appUserEmail = form.get(this.formFields.email).value;
    therapistResponseModel.isActive = this.isActive;
    if (form.get(this.formFields.brightreeInternalUserId).value)
      therapistResponseModel.crmBrightreeUserId = Number(form.get(this.formFields.brightreeInternalUserId).value);
    // therapistResponseModel.appUserPhoneNumber = form.get(this.formFields.phoneNumber).value;
    var tempPhone = form.get(this.formFields.phoneNumber).value;
    if (tempPhone.indexOf('-') > -1) {
      therapistResponseModel.appUserPhoneNumber = tempPhone.split('-').join('');
    } else {
      therapistResponseModel.appUserPhoneNumber = tempPhone;
    }
    therapistContactInfoResponseModel.crmAddress = form.get(this.formFields.address).value;
    therapistContactInfoResponseModel.crmCity = form.get(this.formFields.city).value;
    let stateModel: StateMasterResponseModel = form.get(this.formFields.state).value;
    if (stateModel)
      therapistContactInfoResponseModel.crmState = stateModel.appStateName;

    therapistContactInfoResponseModel.crmBioData = form.get(this.formFields.bioEditorText).value;
    therapistContactInfoResponseModel.crmTherapistId = this.isEditMode ? Number(this.therapistResponseModel.appUserInfoId) : 0;
    therapistContactInfoResponseModel.crmZipCode = form.get(this.formFields.zipcode).value;
    therapistContactInfoResponseModel.crmPractitionerId = this.formFields.practitionerId ? Number(form.get(this.formFields.practitionerId).value) : 0;
    therapistContactInfoResponseModel.crmDeliveryTechnicianId = this.formFields.deliveryTechnicianId ? Number(form.get(this.formFields.deliveryTechnicianId).value) : 0;
    therapistContactInfoResponseModel.crmProfileImageSrc = (this.profileLogo == null && !this.isImageSaved) ? this.profileLogo : (this.profileLogo != null && this.isImageSaved) ? this.profileLogo : this.therapistResponseModel.therapistContactInfo[0].crmProfileImageSrc;
    therapistContactInfoResponseModel.crmProfileImageFormat = this.crmProfileImageFormat;
    therapistContactInfoResponseModel.crmCompanyName = this.companyName;
    therapistResponseModel.therapistContactInfoModel = therapistContactInfoResponseModel;
    return therapistResponseModel;
  }

  public getValuesFromFormForDevice(): TherapistDeviceInfoModel {

    const therapistResponseModel = new TherapistDeviceInfoModel();
    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? this.therapistId : "0";
    if (this.certificationResponseModel.length >= 1) {
      therapistResponseModel.therapistCertification = this.certificationResponseModel;
    }

    return therapistResponseModel;
  }

  public getValuesFromFormForLicense(): TherapistLicenseInfoModel {

    const therapistResponseModel = new TherapistLicenseInfoModel();
    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? this.therapistId : "";
    if (this.licenseResponseModel.length >= 1) {
      therapistResponseModel.therapistLicenseInfo = this.licenseResponseModel;
    }

    return therapistResponseModel;
  }

  public getValuesFromFormForDefaultFacility(): TherapistDefaultFacilityModel {

    const therapistResponseModel = new TherapistDefaultFacilityModel();
    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? this.therapistId : "0";
    const therapistDefaultfacilityResponseModel = new TherapistDefaultFacilityResponseModel();
    let facilityResponseModel = new FacilityResponseModelDropdown();
    const formService = this.formService;
    facilityResponseModel = formService.get(this.formFields.facilityList).value;
    if (this.therapistId) {
      therapistDefaultfacilityResponseModel.crmTherapistId = Number(this.therapistId);
    }

    therapistDefaultfacilityResponseModel.crmFacilityId = facilityResponseModel.crmFacilityId;
    therapistDefaultfacilityResponseModel.crmIsDefault = true;
    let crmArea = formService.get(this.formFields.coverageArea).value;
    therapistDefaultfacilityResponseModel.crmCoverageArea = Number(crmArea);
    therapistDefaultfacilityResponseModel.isActive = true;
    therapistDefaultfacilityResponseModel.crmIsAllowSelfScheduleAppointments = this.isAllowSelfScheduleAppointments;
    therapistResponseModel.therapistDefaultFacility = therapistDefaultfacilityResponseModel;


    return therapistResponseModel;
  }

  public getValuesFromFormForOnlinemeeting(): TherapistOnlineMeetingModel {
    const therapistResponseModel = new TherapistOnlineMeetingModel();
    therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
    therapistResponseModel.appUserInfoId = this.isEditMode ? this.therapistId : "0";
    const therapistDefaultfacilityResponseModel = new TherapistOnlineMeetingResponseModel();
    let facilityResponseModel = new FacilityResponseModelDropdown();
    const formService = this.formMeeting;

    if (this.therapistId) {
      therapistDefaultfacilityResponseModel.crmTherapistId = this.therapistAvailability.crmTherapistId;
    }

    therapistDefaultfacilityResponseModel.crmMeetingUrl = formService.get(this.formFields.meetingUrl).value;
    therapistDefaultfacilityResponseModel.crmMeetingId = formService.get(this.formFields.meetingId).value;
    therapistDefaultfacilityResponseModel.crmMeetingPasscode = formService.get(this.formFields.meetingPasscode).value;
    therapistDefaultfacilityResponseModel.isActive = true;
    therapistResponseModel.therapistOnlineMeetingInfoModel = therapistDefaultfacilityResponseModel;
    return therapistResponseModel;
  }

  getStateByCode(StateName: string) {
    if (!StateName) {
      return null;
    }
    let foundBranch = this.statsModel.find(x => x.appStateName == StateName);
    this.selectedStateBio = foundBranch;
  }


  public setValuesToPersonalForm() {
    this.formPersonal.reset();
    if (!this.therapistResponseModel) {
      return;
    }
    const form = this.formPersonal;
    form.get(this.formFields.firstName).setValue(this.therapistResponseModel.appUserFname);
    form.get(this.formFields.lastName).setValue(this.therapistResponseModel.appUserLname);
    form.get(this.formFields.email).setValue(this.therapistResponseModel.appUserEmail);
    form.get(this.formFields.phoneNumber).setValue(this.therapistResponseModel.appUserPhoneNumber);
    form.get(this.formFields.brightreeInternalUserId).setValue(this.therapistResponseModel.crmBrightreeUserId);
    this.isActive = this.therapistResponseModel.isActive;
    if (this.therapistResponseModel.therapistContactInfo[0]) {
      let contactmodel: TherapistContactInfoResponseModel;
      contactmodel = this.therapistResponseModel.therapistContactInfo[0];
      this.getStateByCode(contactmodel.crmState);
      form.get(this.formFields.state).setValue(this.selectedStateBio);
      form.get(this.formFields.address).setValue(contactmodel.crmAddress);
      form.get(this.formFields.city).setValue(contactmodel.crmCity);
      form.get(this.formFields.zipcode).setValue(contactmodel.crmZipCode);
      form.get(this.formFields.bioEditorText).setValue(contactmodel.crmBioData);
      form.get(this.formFields.practitionerId).setValue(contactmodel.crmPractitionerId);
      form.get(this.formFields.deliveryTechnicianId).setValue(contactmodel.crmDeliveryTechnicianId);
      const image = new Image();
      image.src = 'data:image/png;base64,' + contactmodel.crmProfileImageSrc;
      image.onload = () => {
        const imgBase64Path = 'data:image/png;base64,' + contactmodel.crmProfileImageSrc;
        this.cardImageBase64 = imgBase64Path;
        const indexOfComma = imgBase64Path.indexOf(',', 0);
        this.profileLogo = logoString;
        var logoString = this.cardImageBase64.slice(indexOfComma + 1);
        this.isImageSaved = true;
      };
    }
  }

  public setValuesToServiceForm() {
    this.formService.reset();
    if (!this.therapistResponseModel) {
      return;
    }
    const formService = this.formService;
    if (this.therapistResponseModel.therapistDefaultFacility[0]) {
      const foundFacility = this.getFacilityByCode(this.therapistResponseModel.therapistDefaultFacility[0].crmFacilityId);
      this.defaultFacility = foundFacility.crmFacilityName;
      formService.get(this.formFields.facilityList).setValue(foundFacility);
      formService.get(this.formFields.coverageArea).setValue(this.therapistResponseModel.therapistDefaultFacility[0].crmCoverageArea);
      this.isAllowSelfScheduleAppointments = this.therapistResponseModel.therapistDefaultFacility[0].crmIsAllowSelfScheduleAppointments;
    }

  }

  public setValuesToMeetingForm() {
    this.formMeeting.reset();
    if (!this.therapistResponseModel) {
      return;
    }
    const formMeeting = this.formMeeting;
    if (this.therapistResponseModel.therapistOnlineMeetingInfo[0]) {
      let conractMeetingmodel: TherapistOnlineMeetingResponseModel;
      conractMeetingmodel = this.therapistResponseModel.therapistOnlineMeetingInfo[0];
      formMeeting.get(this.formFields.meetingUrl).setValue(conractMeetingmodel.crmMeetingUrl);
      formMeeting.get(this.formFields.meetingId).setValue(conractMeetingmodel.crmMeetingId);
      formMeeting.get(this.formFields.meetingPasscode).setValue(conractMeetingmodel.crmMeetingPasscode);
    }

  }

  public setValuesToForm() {

    this.setValuesToPersonalForm();
    this.setValuesToServiceForm();
    this.setValuesToMeetingForm();

    if (this.therapistResponseModel.therapistLicenseInfo.length > 0) {
      if (this.therapistResponseModel.therapistLicenseInfo[0].isLicenseCheck !== null) {
        this.isLicenseChecked = this.therapistResponseModel.therapistLicenseInfo[0].isLicenseCheck;
      } else {
        this.isLicenseChecked = false;
      }
    }

    this.HideShowDeviceButton();
    this.HideShowLicenseButton();

    // if (this.selectedDefaultFacility) {
    //   this.selectedDefaultFacility.label = this.therapistResponseModel.facilityInfo.crmFacilityName;
    //   this.selectedDefaultFacility.value = this.therapistResponseModel.facilityInfo.crmFacilityId.toString();
    // }
  }



  public getFacilityByCode(facilityId: number): FacilityResponseModelDropdown {
    if (!facilityId) {
      return null;
    }

    let foundFacility: FacilityResponseModelDropdown;
    for (const facility of this.facilityList) {
      if (facility.crmFacilityId === facilityId) {
        foundFacility = facility;
        break;
      }
    }
    return foundFacility == undefined ? new FacilityResponseModelDropdown() : foundFacility;
  }

  public builForm() {
    // const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    //const reg = '/^https:\/\/company\.zoom\.us\/j\/\S*\?pwd=[^\s;]*$/';
    const reg = '/^https:\/\/\S*\.zoom\.us\/j\/\S*\?pwd=[^\s;]*$/';

    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.firstName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.lastName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    // formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.address, this.formBuilder.control('', { validators: [Validators.required, Validators.maxLength(1000), CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.city, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.zipcode, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.state, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));

    formGroup.addControl(this.formFields.practitionerId, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.bioEditorText, this.formBuilder.control('', { validators: [Validators.required, Validators.maxLength(2000), CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.change }));

    formGroup.addControl(this.formFields.deliveryTechnicianId, this.formBuilder.control('', { validators: [CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.brightreeInternalUserId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formServiceGroup = this.formBuilder.group({});
    formServiceGroup.addControl(this.formFields.facilityList, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formServiceGroup.addControl(this.formFields.coverageArea, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.change }));


    const formMeetingGroup = this.formBuilder.group({});
    formMeetingGroup.addControl(this.formFields.meetingUrl, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.ZoomURL  /*Validators.pattern(reg)*/], updateOn: Constants.formControlUpdateStrategy.change }));
    formMeetingGroup.addControl(this.formFields.meetingId, this.formBuilder.control('', { updateOn: Constants.formControlUpdateStrategy.change }));
    formMeetingGroup.addControl(this.formFields.meetingPasscode, this.formBuilder.control('', { updateOn: Constants.formControlUpdateStrategy.change }));


    const formDeviceGroup = this.formBuilder.group({});
    // tslint:disable-next-line: max-line-length
    formDeviceGroup.addControl(this.formFields.deviceName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formDeviceGroup.addControl(this.formFields.deviceCertificate, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));


    const formLicenseGroup = this.formBuilder.group({});
    // tslint:disable-next-line: max-line-length
    formLicenseGroup.addControl(this.formFields.licenseState, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formLicenseGroup.addControl(this.formFields.licenseNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    // tslint:disable-next-line: max-line-length
    formLicenseGroup.addControl(this.formFields.licenseExpiry, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace] }));

    this.form = this.formBuilder.group({
      formPersonal: formGroup,
      formService: formServiceGroup,
      formMeeting: formMeetingGroup,
      formDevice: formDeviceGroup,
      formLicense: formLicenseGroup
    });
  }

  // New availability popup methods

  SetAvailabilityPopupClick() {
    if (this.defaultFacility == undefined || this.defaultFacility == null || this.defaultFacility == "") {
      this.indexTab = 2;
      this.showWarningMessage("Please Select a Default Facility");
      return;
    }
    if (this.isNewManageAvailability) {
      this.router.navigate(['admin/calendar/view-therapist-availability-v1/', this.therapistId]);
    }
    else {
      this.router.navigate(['admin/calendar/view-therapist-availability/', this.therapistId]);
    }
  }

  onCancelPopup() {
    this.SetAvailabilityPopup = false;
  }

  onRemoteAssistantChange(status) {
    if (status) {
      this.isRemoteAssistant = true;
      this.isRoadShow = false;
      this.getFacilitiesPopup("true");
    }
    else
      this.getFacilitiesPopup("false");

  }

  onRoadShowChange(status) {
    if (status) {
      this.isRemoteAssistant = false;
      this.isRoadShow = true;
      this.getFacilitiesPopup("false");
    }
  }

  getFacilitiesPopup(isRemote: string) {
    this.progress.next(true);
    this.facilityService.getAllFacilityDropDownRemote(isRemote)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.selectedFacilities = null;
          this.facilities = result.responseModel;
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  SetWorking() {
    if (this.selectedWorking === 'N') {
      this.isRoadShow = false;
      this.isRemoteAssistant = false;
      this.getFacilitiesPopup("false");
    }
    if (this.selectedWorking === 'W') {
      this.isRoadShow = false;
      this.isRemoteAssistant = false;
      this.getFacilitiesPopup("false");
    }
  }

  getDaysOfWeek() {
    this.progress.next(true);
    this.therapistService.getDaysOfWeek()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.weekDays = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getEventSeries() {
    this.progress.next(true);
    this.therapistService.getEventSeries()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.eventSeries = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public setValuesToAvailability() {
    if (!this.therapistSetAvailabilityRequestModel) {
      return;
    }
    this.multiAvailability = this.therapistSetAvailabilityRequestModel.therapistAvailabilityResponseModel;
    this.multipleTherapistCustomAvailability = this.therapistSetAvailabilityRequestModel.therpistCustomAvailability;
  }

  onAddAvailability(): boolean {
    if (!this.selectedFacilities) {
      this.showWarningMessage('Please select a facility.');
      return false;
    }
    if (this.selectedWorking === "N" && !this.description) {
      this.showWarningMessage('Please enter description.');
      return false;
    }
    if (this.selectedWorking === "N" && this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select event series.');
      return false;
    }
    if (this.weekDays.filter(x => x.isSelected === true).length <= 0) {
      this.showWarningMessage('Please select week days.');
      return false;
    }
    if (this.selectedWorking === "N" && !this.selectedOccurrence) {
      this.showWarningMessage('Please select Occurrence.');
      return false;
    }
    if (!this.customStartDate) {
      this.showWarningMessage('Please select Start Date.');
      return false;
    }
    if (!this.customEndDate) {
      this.showWarningMessage('Please select End Date.');
      return false;
    }
    if (this.customEndDate < this.customStartDate) {
      this.showWarningMessage('End Date should be larger than Start Date.');
      return false;
    }
    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }
    if (this.customEndTime < this.customStartTime) {
      this.showWarningMessage('End Time should be larger than Start Time.');
      return false;
    }
    if (!this.breakStartTime) {
      this.showWarningMessage('Please select Break End Time');
      return false;
    }
    if (!this.breakEndTime) {
      this.showWarningMessage('Please select Break End Time');
      return false;
    }
    if (this.breakEndTime < this.breakStartTime) {
      this.showWarningMessage('Break End Time should be larger than Break Start Time');
      return false;
    }

    if (!(this.breakStartTime > this.customStartTime && this.breakStartTime < this.customEndTime)) {
      this.showWarningMessage('Break Start time should be between start time and end time');
      return false;
    }

    if (!(this.breakEndTime > this.customStartTime && this.breakEndTime < this.customEndTime)) {
      this.showWarningMessage('Break End time should be between start time and end time');
      return false;
    }

    let display = new AvailDisplayMode();
    let therapistCustomAvailability = new TherapistCustomAvailabilityResponseModel();
    let therapistAvailability = new TherapistAvailabilityResponseModelSend();
    if (this.multipleTherapistCustomAvailability === undefined || this.multipleTherapistCustomAvailability == null) {
      this.multipleTherapistCustomAvailability = new Array<TherapistCustomAvailabilityResponseModel>();
    }
    if (this.multiAvailability === undefined || this.multiAvailability == null) {
      this.multiAvailability = new Array<TherapistAvailabilityResponseModelSend>();
    }
    let uuid = 1;
    if (this.availDisplayMode && this.availDisplayMode.length > 0) {
      uuid = this.availDisplayMode.length + 1;
    }

    if (this.selectedWorking === "W" && this.eventSeries.filter(x => x.isSelected === true).length <= 0) {
      if (this.weekDays && this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          if (value.isSelected) {
            therapistAvailability = new TherapistAvailabilityResponseModelSend();
            therapistAvailability.crmBreakEndTimeDate = this.breakEndTime;
            therapistAvailability.crmBreakStartTimeDate = this.breakStartTime;
            therapistAvailability.crmTherpistAvailFromDate = this.customStartDate;
            therapistAvailability.crmTherpistAvailFromTimeDate = this.customStartTime;
            therapistAvailability.crmTherpistAvailToDate = this.customEndDate;
            therapistAvailability.crmTherpistAvailToTimeDate = this.customEndTime;
            therapistAvailability.crmFacilityId = this.selectedFacilities.crmFacilityId;
            therapistAvailability.crmTherapistId = Number(this.therapistId);
            therapistAvailability.crmTherpistAvailabilityId = 0;
            therapistAvailability.crmTherpistAvailWeekDays = value.value;
            therapistAvailability.isRemoteAssistant = this.isRemoteAssistant;
            therapistAvailability.modifiedByUserInfoId = this.currentUserToken.userId;
            therapistAvailability.crmFacilityName = this.selectedFacilities.crmFacilityName;
            therapistAvailability.dayOfWeek = value.name;
            therapistAvailability.uuid = uuid;
            this.multiAvailability.push(therapistAvailability);
          }
        });
      }

    }
    else if ((this.selectedWorking === "N" || this.selectedWorking === "W") && this.eventSeries.filter(x => x.isSelected === true).length > 0) {
      if (this.weekDays && this.weekDays.length > 0) {
        this.weekDays.forEach((value) => {
          if (value.isSelected) {
            therapistCustomAvailability = new TherapistCustomAvailabilityResponseModel();
            therapistCustomAvailability.crmTherapistCustomStartDate = this.customStartDate;
            therapistCustomAvailability.crmTherapistCustomEndDate = this.customEndDate;
            therapistCustomAvailability.crmTherapistCustomStartTimeDate = this.customStartTime;
            therapistCustomAvailability.crmTherapistCustomEndTimeDate = this.customEndTime;
            therapistCustomAvailability.crmBreakEndTimeDate = this.breakEndTime;
            therapistCustomAvailability.crmBreakStartTimeDate = this.breakStartTime;
            therapistCustomAvailability.crmTherapistId = parseInt(this.therapistId, 10);
            therapistCustomAvailability.modifiedByUserInfoId = this.currentUserToken.userId;
            therapistCustomAvailability.crmFacilityId = this.selectedFacilities.crmFacilityId;
            therapistCustomAvailability.crmFacilityName = this.selectedFacilities.crmFacilityName;
            therapistCustomAvailability.dayOfWeek = value.name;
            therapistCustomAvailability.eventName = this.eventSeries.find(x => x.isSelected === true).name;
            therapistCustomAvailability.isRemoteAssistant = this.isRemoteAssistant;
            therapistCustomAvailability.crmRecurringFrequency = this.selectedOccurrence.code;
            if (this.eventSeries.find(x => x.isSelected === true).name === "Once") {
              therapistCustomAvailability.crmIsRecurring = false;
            }
            else {
              therapistCustomAvailability.crmIsRecurring = true;
            }
            if (this.selectedWorking === "N") {
              therapistCustomAvailability.crmDescription = this.description;
              therapistCustomAvailability.crmIsHoliday = true;
            }
            if (this.selectedWorking === "W") {
              therapistCustomAvailability.crmDescription = '';
              therapistCustomAvailability.crmIsHoliday = false;
              therapistCustomAvailability.crmIsRoadShow = this.isRoadShow;
              therapistCustomAvailability.isRemoteAssistant = this.isRemoteAssistant;
            }
            therapistCustomAvailability.crmRecurringType = this.eventSeries.find(x => x.isSelected === true).value;
            therapistCustomAvailability.crmTherpistCustomAvailabilityId = 0;
            therapistCustomAvailability.crmCustomDays = value.value.toString();

            therapistCustomAvailability.crmIsOnLeave = false;
            therapistCustomAvailability.crmIsNotAvailable = false;
            therapistCustomAvailability.uuid = uuid;
            this.multipleTherapistCustomAvailability.push(therapistCustomAvailability);
          }
        });
      }
    }
    if (this.localEditId >= 0 && this.availDisplayMode && this.availDisplayMode.length > 0) {
      this.availDisplayMode.splice(this.localEditId, 1);
    }

    const events = [];
    const days = [];
    if (this.eventSeries) {
      this.eventSeries.forEach((value) => {
        events.push(Object.assign({}, value));
      });
    }
    if (this.weekDays) {
      this.weekDays.forEach((value) => {
        days.push(Object.assign({}, value));
      });
    }

    display.breakEnd = this.breakEndTime;
    display.breakStart = this.breakStartTime;
    display.endDate = this.customEndDate;
    display.endTime = this.customEndTime;
    display.eventSeries = events;
    if (this.selectedWorking === "N") {
      display.description = this.description;
      display.isHoliday = true;
    }
    if (this.selectedWorking === "W") {
      display.description = '';
      display.isHoliday = false;
      display.isRoadShow = this.isRoadShow;
      display.isRemoteAssistant = this.isRemoteAssistant;
    }
    display.occurrence = this.selectedOccurrence.name;
    display.selectedFacilities = this.selectedFacilities;
    display.selectedWorking = this.selectedWorking;
    display.startDate = this.customStartDate;
    display.startTime = this.customStartTime;
    display.weekDays = days;
    display.uuid = uuid;
    this.availDisplayMode.push(display);
    this.resetValuesToCustom();
    //console.log(this.multiAvailability);
    //console.log(this.multipleTherapistCustomAvailability);
    return true;
  }

  onCustomCancel() {
    this.resetValuesToCustom();
    this.SetAvailabilityPopup = false;
  }

  private resetValuesToCustom() {
    this.customStartDate = null;
    this.customEndDate = null;
    this.customStartTime = null;
    this.customEndTime = null;
    this.selectedOccurrence = { name: '', code: 0 };
    this.isRemoteAssistant = false;
    this.selectedWorking = 'W';
    this.breakEndTime = null;
    this.breakStartTime = null;
    this.isRoadShow = false;
    if (this.eventSeries) {
      this.eventSeries.forEach((value) => {
        value.isSelected = false;
      });
    }
    if (this.weekDays) {
      this.weekDays.forEach((value) => {
        value.isSelected = false;
      });
    }
    this.selectedFacilities = this.selectedFacility;
    this.localEditId = -1;

  }

  onCustomEdit(display: AvailDisplayMode, i: number) {
    const events = [];
    const days = [];
    if (display.eventSeries) {
      display.eventSeries.forEach((value) => {
        events.push(Object.assign({}, value));
      });
    }
    if (display.weekDays) {
      display.weekDays.forEach((value) => {
        days.push(Object.assign({}, value));
      });
    }
    this.breakEndTime = display.breakEnd;
    this.breakStartTime = display.breakStart;
    this.customEndDate = display.endDate;
    this.customEndTime = display.endTime;
    this.eventSeries = events;
    if (display.isHoliday) {
      this.description = display.description;
      this.selectedWorking = "N";
    }
    if (this.selectedWorking === "W") {
      this.isRoadShow = display.isRoadShow;
      this.isRemoteAssistant = display.isRemoteAssistant;
    }
    this.selectedOccurrence.name = display.occurrence;
    this.selectedFacilities = display.selectedFacilities;
    this.selectedWorking = display.selectedWorking;
    this.customStartDate = display.startDate;
    this.customStartTime = display.startTime;
    this.weekDays = days;
    this.localEditId = i;
    this.localUUID = display.uuid;
  }

  onCustomDelete1(item: AvailDisplayMode, index: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to delete',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (response) {
        if (item.uuid > 0) {
          const localAvail = [];
          const localCustomAvail = [];
          if (this.multiAvailability) {
            this.multiAvailability.forEach((value) => {
              localAvail.push(Object.assign({}, value));
            });
          }
          if (this.multipleTherapistCustomAvailability) {
            this.multipleTherapistCustomAvailability.forEach((value) => {
              localCustomAvail.push(Object.assign({}, value));
            });
          }
          if (localAvail && localAvail.length > 0) {
            localAvail.forEach((value) => {
              if (value.uuid === item.uuid) {
                let i = this.multiAvailability.indexOf(value);
                if (i !== -1) {
                  this.multiAvailability.splice(i, 1);
                }
              }
            });
          }
          if (localCustomAvail && localCustomAvail.length > 0) {
            localCustomAvail.forEach((value) => {
              if (value.uuid === item.uuid) {
                let i = this.multipleTherapistCustomAvailability.indexOf(value);
                if (i !== -1) {
                  this.multipleTherapistCustomAvailability.splice(i, 1);
                }
              }
            });
          }
        }
        this.availDisplayMode.splice(index, 1);
        //console.log(this.multiAvailability);
        //console.log(this.multipleTherapistCustomAvailability);
      }
    });

  }

  onCustomDelete(index: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to delete',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (response) {
        this.multipleTherapistCustomAvailability.splice(index, 1);
      }
    });

  }

  onDelete(index: number) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to delete',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (response) {
        this.multiAvailability.splice(index, 1);
      }
    });
  }

  public addUpdateAvailability() {

    if ((!this.multiAvailability && !this.multipleTherapistCustomAvailability) &&
      (this.multiAvailability.length <= 0 && this.multipleTherapistCustomAvailability.length <= 0)) {
      this.showWarningMessage('Please set at least one availability');
      return;
    }

    let therapistSetAvailability = new TherapistSetAvailabilityRequestModelSend();
    therapistSetAvailability.therapistAvailabilityResponseModel = this.multiAvailability;
    therapistSetAvailability.therpistCustomAvailability = this.multipleTherapistCustomAvailability;
    therapistSetAvailability.crmTherapistId = Number(this.therapistId);
    this.loading = true;

    const manageAvailability = this.isEditMode ? this.therapistService.updateTherapistAvailability(therapistSetAvailability)
      : this.therapistService.setTherapistAvailability(therapistSetAvailability);

    manageAvailability.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage('Therapist is availability set successfully');
          this.formSubmitted = false;
          this.formDeviceSubmitted = false;
          this.formLicenseSubmitted = false;
          this.form.reset();
          this.index = 1;
          this.SetAvailabilityPopup = false;

        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onAdd() {
    this.index = 0;
  }

  onResetIndex(i: number) {
    this.index = i;
  }

  onSave() {
    if (this.onAddAvailability()) {
      this.addUpdateAvailability();
    }
  }


  clickEventFilter() {
    this.clickEventFilterStatus = !this.clickEventFilterStatus;
  }

  // New availability popup variables
}
