<div class="landing">
  <div class="landing__header">
    <div class="landing__header__left">
      <img [src]="imageUrl" alt="{{imagealt}}" title={{imageTitle}} width="117" height="62">

    </div>
    <div class="login__header__right">
      <img src="assets/img/emedassist-small-logo.png" alt="Analytix eMedAssist" title="Analytix eMedAssist" width="142" height="20">
    </div>
  </div>
  <div class="landing__content">
    <div class="landing__content__left">
      <h1>
        Hi, <span class="first">{{patientName}},</span> <br>
        Welcome to <span>eMedAssist.</span>
      </h1>
      <!-- <h2>Follow 2 steps to book an appointment</h2> -->

      <span class="appointment" *ngIf="!emailToProviderSuccess && validSalesOrder">
        Do you want to initiate shipping?
      </span>

      <ul class="menu__block menu--top">
        <li class="menu__block__item book-appointment" *ngIf="!emailToProviderSuccess && validSalesOrder">
          <button class="btn btn--primary" (click)="EmailToProvider()">Send an email to provider</button>
          <a class="btn btn--primary" href="tel:{{DMENumber}}">Call Us Now</a>

          <div class="disclaimer">
            <a href="javascript:void(0);" (click)="disclaimerClick()">Disclaimer</a>
          </div>
        </li>
        <li class="menu__block__item book-appointment" *ngIf="emailToProviderSuccess">
          <span class="appointment-already-booked">Your request has been sent to the respective provider.</span>
        </li>

        <li class="menu__block__item book-appointment" *ngIf="!validSalesOrder">
          <span class="appointment-already-booked">Your request for initiate shipping <br>has already been received.</span>
        </li>
        <li [ngClass]="!emailToProviderSuccess && validSalesOrder?'menu__block__item or':'menu__block__item or hide'"><span>OR</span></li>

        <li class="menu__block__item call-customer-service">
          <p>Contact our customer support center for further assistance.</p>
          <a href="tel:{{DMENumber}}" class="call-link"><i class="ic call-us"></i> {{DMENumber}}</a>
          <a href="mailto:{{DMEeMail}}" class="btn btn--primary send-email"> <i class="ic email"> </i>Send us an email</a>
        </li>
      </ul>
    </div>
    <div class="loader-block" *ngIf="loading">
      <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                         animationDuration=".8s">
      </p-progressSpinner>
    </div>
  </div>
  <div class="landing__footer">
    <div class="poweredby">
      <span>Powered By Analytix Solutions</span>
    </div>
  </div>
</div>

<!-- Disclaimer Popup -->
<p-dialog [(visible)]="disclaimerPopup" [modal]="true" [responsive]="true" class="patient-popup">
  <div class="form-content-outer__content">
    <h2>Disclaimer</h2>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
    <p>When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
    <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  </div>
</p-dialog>
