<div class="main-outer dme-outer" [ngClass]="isActive ? 'collapse':'' ">
  <aside class="main-outer__sidebar">
    <div class="sidebar-menu-btn">
      <a href="javascript:void(0)" class="sidebar-menu-btn__sidebar-button" [ngClass]="{'active': isActive }" (click)="sidebarMenuClick()">
        <!-- <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 12H19M19 12L13 6M19 12L13 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg> -->
        <svg width="63" height="58" viewBox="0 0 63 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 20H43V22.551H20V20ZM20 27.7245H35.3333V30.2755H20V27.7245ZM20 35.449H43V38H20V35.449Z" fill="white"/>
        </svg>
      </a>
      <span class="sidebar-menu-btn__text">Menu</span>
    </div>
    <!-- Start Sidebar -->
    <app-sidebar></app-sidebar>
    <!-- End Sidebar -->    
  </aside>

  <section class="main-outer__content-outer">
    <!-- Start Header -->
    <app-header></app-header>
    <!-- End Header -->

    <!-- Start Content -->
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </section>
</div>