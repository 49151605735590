import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { BaseComponent } from '../components/base.component';
import { SearchRequestModel } from '../models/request/search-request-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { Result, ResultOfT } from '../models/response/result';
import { PagedResponseModel } from '../models/response/paged-response-model';
import { AppointmentVoiceCallResponseModel } from '../models/response/appointment/appointment-voice-call-response-model';
// import '../../../assets/audio-js/audio-player';
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DateFormatModel } from '../models/common/date-format-model';
import { WebStorageService } from '../services/web-storage.service';
import { AppointmentCallRecordingPlayHistoryRequestModel } from '../models/request/appoitment-call-recording-play-history-request-model';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.sass']
  
})

export class AudioPlayerComponent extends BaseComponent implements OnInit{
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  salesOrders:AppointmentVoiceCallResponseModel[];
  searchRequestModel: SearchRequestModel;
  audioUrl: SafeResourceUrl;
  appDateFormat: DateFormatModel;
  loadingVoiceCallRecording: boolean;
  loggedInUserId: number;
  loggedInUserName: string;
  @Input() appointmentRecordingSID: any;
  @Input() appoitmentCallerName: string;
  @Input() appoitmentCallDateTime: string;
  @Output() audioPlayerPopup:EventEmitter<boolean>= new EventEmitter();
  
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    messageService: MessageService,
    private appointmentService: AppointmentService,
    private sanitizer:DomSanitizer,
    private webStorageService: WebStorageService) { 
      super(messageService);
      this.appDateFormat = this.webStorageService.getDateFormat();
      const loggedInUser = this.webStorageService.getCurrentUserToken();
      this.loggedInUserId = loggedInUser.userId;
      this.loggedInUserName = loggedInUser.userFullName;
    }

    ngOnInit(): void {
      if(this.appointmentRecordingSID)
      {
        this.GetAppoitmentVoiceCallRecording(this.appointmentRecordingSID);
      }
    }

    async GetAppoitmentVoiceCallRecording(appointmentRecordingSID:string)
    {
      this.loadingVoiceCallRecording=true;
      await this.appointmentService.GetVoiceCallRecordingPlayBase64String(appointmentRecordingSID)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            let imageData:string="data:audio/wav;base64,"+result.responseModel;
            this.audioUrl=this.sanitizer.bypassSecurityTrustResourceUrl(imageData);
            this.loadingVoiceCallRecording=false;
            this.AddVoiceCallRecordingPlayHistory();
          }
          else
          {
            this.loadingVoiceCallRecording=false;
            this.audioPlayerPopup.emit(false);
            this.showErrorMessage('Recording file removed or not yet processed');
          }
        }

      })
      .catch((httpResponseError) => {
        this.loadingVoiceCallRecording=false;
        this.showErrorMessage(httpResponseError.message);
      });
    }

    async AddVoiceCallRecordingPlayHistory()
    {
      let callRecordingPlayHistory = new AppointmentCallRecordingPlayHistoryRequestModel();
      callRecordingPlayHistory.SchRecordingSid=this.appointmentRecordingSID;
      callRecordingPlayHistory.SchCallRecordingPlayBy=this.loggedInUserId;
      callRecordingPlayHistory.SchCallRecordingPlayByUserName=this.loggedInUserName;
      callRecordingPlayHistory.SchCallRecordingPlayDateTime=new Date();

      await this.appointmentService.SaveAppointmentCallRecordingPlayHistory(callRecordingPlayHistory)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
          .toPromise().then((result) => {
            if (result.isSuccess)
            {
              if (result.responseModel != undefined && result.responseModel != null)
              {
                //this.showSuccessMessage('Recording play successfully');
              }
            }
            else {
              this.showWarningMessage('Error occurred while adding play logs');
            }

          }).catch((httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
          });
    }
}

