import { FacilityResponseModelDropdown } from 'src/app/shared/models/response/facility/facility-response-model';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
export class AvailDisplayMode {
  selectedWorking: string;
  isRemoteAssistant: boolean;
  isRoadShow: boolean;
  isHoliday: boolean;
  selectedFacilities: FacilityResponseModelDropdown;
  weekDays: EnumModel[];
  eventSeries: EnumModel[];
  description: string;
  occurrence: string;
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  breakStart: Date;
  breakEnd: Date;
  uuid: number;
}
