<div class="cd-popup" role="alert">
  <div class="patient-info calenadar-appointment">
  
    <div class="patient-info-middle">
      <h2>Appointment Info.</h2>
      <div class="patient-info-middle__boxs left">
        <strong>Date & Time</strong>
        <small>{{schAppointmentDateStartTime | date:appDateFormat.dateFormat:appDateFormat.timeZone}} </small>
        <small>{{schAppointmentDateStartTime | date:'shortTime':appDateFormat.timeZone | uppercase}}</small>
      </div>
      <div class="patient-info-middle__boxs center">
        <strong>Facility</strong>
        <small>{{facility?.crmFacilityName}}</small>
      </div>
      <div class="patient-info-middle__boxs right">
        <strong>Therapist</strong>
        <small> {{therapistName}}</small>
      </div>
    </div>

    <p>{{dynamicDialogConfig.data.message}}</p>

    <div class="cd-buttons">
      <button class="btn btn--primary mr-2" (click)="onYes()">Yes</button>
      <button class="btn  btn--secondary" (click)="onNo()">No</button>
    </div>
    <!-- <ul class="cd-buttons">
      <li></li>
      <li></li>
  </ul> -->
  </div>
</div>
