import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Subject } from 'rxjs';
import { FacilityResponseModel } from 'src/app/shared/models/response/facility/facility-response-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { FacilityService } from '../../servicies/facility.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import * as moment from 'moment';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

@Component({
  selector: 'app-facility-detail',
  templateUrl: './facility-detail.component.html',
  styleUrls: ['./facility-detail.component.sass']
})
export class FacilityDetailComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private facilityId: string;
  title = 'Facility Detail';
  searchRequestModel: SearchRequestModel;
  facilityDetail: FacilityResponseModel;
  isRemoteFacility: boolean;
  appDateFormat: DateFormatModel;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
    }
  }

  ngOnInit() {
    this.facilityDetail = new FacilityResponseModel();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.facilityId = params['id'];
        this.getFacilityDetail();
      });
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  onEdit() {
    this.router.navigate(['/admin/facilities/edit/', this.facilityId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onBack() {
    this.router.navigate(['admin/facilities'], { state: { searchRequestModel: this.searchRequestModel } });
  }

  private getFacilityDetail() {
    if (!this.facilityId) {
      return;
    }
    this.loading = true;
    this.facilityService.getFacilityById(Number(this.facilityId))
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<FacilityResponseModel>) => {
        this.processResult<FacilityResponseModel>(result, () =>
        {
          if (result && result.isSuccess)
          {
            if (result.responseModel != undefined && result.responseModel != null)
            {
              this.facilityDetail = result.responseModel;
              if (this.facilityDetail.crmBranch)
                this.facilityDetail.crmBranch.crmBranchName = this.facilityDetail.crmBranch.crmBranchName.replace('Sandwich', '').trim();

              this.isRemoteFacility = this.facilityDetail.isRemoteAssistance;
              this.facilityDetail.facilityAvailability.forEach(val => {

                moment().utc(true);
                var cdt1 = moment(val.crmFacilityAvailFromTime, 'HH:mm');
                val.crmFacilityAvailFromTime = cdt1.toDate();

                var cdt2 = moment(val.crmFacilityAvailToTime, 'HH:mm');
                val.crmFacilityAvailToTime = cdt2.toDate();

                if (val.crmBreakStartTime) {
                  var cdt3 = moment(val.crmBreakStartTime, 'HH:mm');
                  val.crmBreakStartTime = cdt3.toDate();
                }

                if (val.crmBreakEndTime) {
                  var cdt4 = moment(val.crmBreakEndTime, 'HH:mm');
                  val.crmBreakEndTime = cdt4.toDate();
                }

              })
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

}
