import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { AppointmentCallRecordingUploadHistoryRequestModel } from 'src/app/shared/models/request/appoitment-call-recording-upload-history-request-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { AppointmentVoiceCallResponseModel } from 'src/app/shared/models/response/appointment/appointment-voice-call-response-model';
import { AppointmentCallRecordingUploadHistoryResponseModel } from 'src/app/shared/models/response/appointment/appoitment-call-recording-upload-history-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { AppointmentService } from '../../servicies/appointment.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.sass']
})
export class BulkUploadComponent extends BaseComponent implements OnInit {
  title = 'Bulk Upload';
  clickEventFilterStatus: boolean;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  appDateFormat: DateFormatModel;
  maxDate = new Date();
  selectedFromDate = new Date();
  selectedToDate = new Date();
  searchRequestModel: SearchRequestModel;
  salesOrders: AppointmentVoiceCallResponseModel[];
  exportHistory:AppointmentCallRecordingUploadHistoryResponseModel[];
  callRecordingUploadHistory: AppointmentCallRecordingUploadHistoryRequestModel;
  loading: boolean;
  totalRecords: number;
  totalPages:number;
  loggedInUserId: number;
  loggedInUserEmail: string;
  totalUploadedFilesList:string;
  totalFailedUploadFilesList:string;

  constructor(
    private appointmentService: AppointmentService,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    @Inject(LOCALE_ID) private locale: string
  ) { 
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.loggedInUserEmail = loggedInUser.userEmail;
    this.maxDate.setMonth(this.maxDate.getMonth() - 3);
    this.selectedFromDate=this.maxDate;
    this.selectedToDate=this.maxDate;
    this.initializeSearchRequestModel();
  }

  ngOnInit(): void {
  }

  clickEventFilter(){
    this.clickEventFilterStatus = !this.clickEventFilterStatus;       
  }

  async onUploadButtonClick(){
    // this.searchRequestModel = new SearchRequestModel();
    // this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    if (this.selectedFromDate > this.selectedToDate) {
      this.showWarningMessage("From Date should not be greater than To Date.");
      return;
    }

    this.searchRequestModel.dictionaryFilter = {};
    this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
    this.searchRequestModel.searchFromDate=this.selectedFromDate;
    this.searchRequestModel.searchToDate=this.selectedToDate;
    //this.searchRequestModel.dictionaryFilter['AppointmentCallFilter']='0,3'.toString();
    await this.getallcallrecordingstoupload();

    if(this.salesOrders.length>0)
    {
      let result=await this.FileUploadToFTP(this.salesOrders);
      if(result.length>0)
      {
        this.callRecordingUploadHistory = new AppointmentCallRecordingUploadHistoryRequestModel();
        this.callRecordingUploadHistory.SchCallRecordingUploadBy=this.loggedInUserId;
        this.callRecordingUploadHistory.SchCallRecordingUploadDateTime=new Date();
        this.callRecordingUploadHistory.SchCallRecordingUploadFromDate=this.selectedFromDate;
        this.callRecordingUploadHistory.SchCallRecordingUploadToDate=this.selectedToDate;

        let json = JSON.stringify(result);
        this.callRecordingUploadHistory.SchCallRecordingUploadCount=result.length;
        this.callRecordingUploadHistory.SchCallRecordingUploadData=json.toString();

        let FailedFiles=result.filter((x)=>x.schRecordingFileStage!=2);
        json = JSON.stringify(FailedFiles);
        this.callRecordingUploadHistory.SchCallRecordingFailedUploadCount=FailedFiles.length;
        this.callRecordingUploadHistory.SchCallRecordingFailedUploadData=json.toString();
        this.callRecordingUploadHistory.SchCallRecordingUploadFtp='';
        this.callRecordingUploadHistory.SchCallRecordingUploadType=1;

        await this.appointmentService.SaveAppointmentCallRecordingUploadHistory(this.callRecordingUploadHistory)
            .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
            .toPromise().then((result) => {
              if (result.isSuccess)
              {
                if (result.responseModel != undefined && result.responseModel != null)
                {
                  this.getHistory();
                  this.showSuccessMessage('Recording file(s) uploaded to FTP successfully');
                }
              }
              else {
                this.showWarningMessage('Error occurred while adding upload logs');
                this.loading = false;
              }

            }).catch((httpResponseError) => {
              this.loading = false;
              this.showErrorMessage(httpResponseError.message);
            });
          this.loading = false;
      }
    }
    else
    {
      this.loading = false;
      this.showWarningMessage('No recording file(s) found to upload within the selected timeframe');
    }
  }

  async getallcallrecordingstoupload() {
    this.loading = true;
      await this.appointmentService.GetAllCallRecordingsToUpload(this.searchRequestModel, false)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.salesOrders = result.responseModel;
          }
          else
          {
            this.loading=false;
            this.showErrorMessage('No recording file(s) found to upload within the selected timeframe');
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading=false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  async FileUploadToFTP(appointmentVoiceCallResponseModel:AppointmentVoiceCallResponseModel[]): Promise<AppointmentVoiceCallResponseModel[]>
  {
    let appointmentVoiceCallResponseModelList: AppointmentVoiceCallResponseModel[];

    this.loading = true;
    await this.appointmentService.UploadVoiceCallRecordingToFTP(appointmentVoiceCallResponseModel,'Bulk',new DatePipe(this.locale).transform(this.selectedFromDate, 'MM/dd/yyyy'),new DatePipe(this.locale).transform(this.selectedToDate, 'MM/dd/yyyy'),this.loggedInUserEmail)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    .toPromise().then((result) => {
        if (result && result.isSuccess && !result.message) {
          if (result.responseModel != undefined && result.responseModel != null){
            appointmentVoiceCallResponseModelList=result.responseModel;
          }
        }
        else {
          this.loading = false;
          this.showErrorMessage(result.message);
          return false;
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
        return false;
      });
      return appointmentVoiceCallResponseModelList;
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory)
      return;

    this.searchRequestModel = new SearchRequestModel();
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize =10;
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "SchCallRecordingUploadDateTime";
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = 10;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    this.getHistory();
  }
  
  getHistory() {    
    this.loading = true;
    this.appointmentService.getBulkUploadHistory(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<AppointmentCallRecordingUploadHistoryResponseModel>>) => {
        this.processResult<PagedResponseModel<AppointmentCallRecordingUploadHistoryResponseModel>>(result, () => {
          const pagedResponse = result.responseModel;
          if (pagedResponse) {
            this.exportHistory = pagedResponse.results;
            this.totalRecords = pagedResponse.totalRecords;
            this.totalPages = pagedResponse.totalPages;
            if (!this.searchRequestModel.PageSize)
              this.searchRequestModel.PageSize = pagedResponse.totalRecords;
            //this.setDateValue();
            this.loading = false;
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  showTotalUploadedFiles(event, overlaypanel: OverlayPanel, data: string) {
    let uploadedFiles:AppointmentVoiceCallResponseModel[]= JSON.parse(data);
    this.totalUploadedFilesList = uploadedFiles.map(i=>i.schAppointmentCallId).toString();
    overlaypanel.toggle(event);
  }

  showTotalFailedUploadFiles(event, overlaypanel: OverlayPanel, data: string) {
    let failedUploadedFiles:AppointmentVoiceCallResponseModel[]= JSON.parse(data);
    this.totalFailedUploadFilesList = failedUploadedFiles.map(i=>i.schAppointmentCallId).toString();
    overlaypanel.toggle(event);
  }
}
