import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ViewChildren, QueryList, } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { AppointmentService } from '../servicies/appointment.service';
import { SettingsService } from '../servicies/settings.service';
import { DynamicCols } from 'src/app/shared/models/common/dynamic-cols';
import { SalesorderCallLogsComponent } from './salesorder-call-logs/salesorder-call-logs.component';
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { HttpClient } from '@angular/common/http';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { AppointmentCallListResponseModel } from 'src/app/shared/models/request/appointment-call-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
declare const Twilio: any;
import * as moment from 'moment';
import { Table, TableHeaderCheckbox } from "primeng/table";
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { TwillioCallService } from '../servicies/twillioCall.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { UserService } from 'src/app/admin/servicies/user.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { WipStatusBTmapping } from '../../shared/models/common/wip-status';
import { Constants } from 'src/app/shared/constants';
import { BookAppointmentInfoComponent } from 'src/app/admin/book-appointment-info/book-appointment-info.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointmentdetail-response-model';
import { AppointmentCountResponseModel, AppointmentsCountsResponseModel, SalesOrderDependenciesDetails } from 'src/app/shared/models/response/appointment/appointments-counts-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { FacilityResponseModelDropdownList } from 'src/app/shared/models/response/facility/facility-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { DashboardService } from '../servicies/dashboard.service';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SalesorderStatusCountModel } from 'src/app/shared/models/response/appointment/salesorder-status-count-model';
import { RemoteAppointmentTypeModel } from 'src/app/shared/models/response/appointment/remote-appointment-type-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { debug } from 'console';
import { PrimaryInsuranceProviderResponseModel } from 'src/app/shared/models/response/appointment/primary-insurance-providers-response-model';
import { ModuleType } from 'src/app/shared/enums';
import { UserPreferencesModuleColumnResponseModel } from 'src/app/shared/models/response/user-wise-grid-column-response-model';
import { UserWiseModuleColumn } from 'src/app/shared/models/request/user-wise-column-model-request';
import { FilterClassificationTypeResponseModel } from 'src/app/shared/models/response/appointment/filter-classification-response-model'

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { tr } from 'date-fns/locale';

import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';
import { UpdateStatusOfAppointmentsComponent } from 'src/app/shared/components/update-status-of-appointments/update-status-of-appointments.component';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.sass'],
  providers: [DialogService],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class AppointmentsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;

  facilityVisitTypes: SelectItem[];
  facilityVisitSelectedType: string;
  appointmentsalesOrderStatusMenuitems: MenuItem[];
  selectedappointmentsalesOrderStatusMenuitems: MenuItem;
  isDisabled: boolean = true;
  isDisabledLock: boolean = true;
  features: featureResponseModel[];
  //Not Scheduled
  isLockedAppointments: boolean = false;
  //selectedStatus: WIPStatesResponseModel;
  totalRecords: number;
  selectedsalesOrdersCount: number;
  totalPages: number;
  searchRequestModel: SearchRequestModel;
  salesOrders: SalesOrderSummaryResponseModel[];
  selectedsalesOrders: SalesOrderSummaryResponseModel[];
  salesOrderStatus: WIPStatesResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  salesOrderCountsResponseModel: SalesOrderCountsResponseModel;
  salesOrderCountModels: SalesOrdersCountModel[];
  salesSelectedOrderCountModels: SalesOrdersCountModel;
  appointmentCallListRequestModel: AppointmentCallListResponseModel[];
  dynamicNonSColumns: DynamicCols[];
  selectedNonSColumns: DynamicCols[];
  selectedSalesOrder: SalesOrderSummaryResponseModel;
  selectedTab: string;
  isRescheduledRequestFromNS: boolean;
  duration: string;
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  salesOrderType: string;
  AppointmentOrderType: string;
  reasonsResponseModel: CancelVoidResponseModel[];
  cancellationReasonsResponseModel: CancelVoidResponseModel[];
  voidReasonsResponseModel: CancelVoidResponseModel[];
  reschedulecallId: number;
  SchedulePopup: boolean = false;
  brightreeInternalUserId: number;
  totalAppointmentRecords: number;
  totalAppointmentPages: number;
  searchAppointmentRequestModel: SearchRequestModel;
  appointmentsalesOrderStatus: WIPStatesResponseModel[];
  appointments: AppointmentSummaryResponseModel[];
  nonsechduleappointments: AppointmentSummaryResponseModel[];
  selectedAppointments: AppointmentSummaryResponseModel[];
  selectedAppointment: AppointmentSummaryResponseModel;
  appointment: AppointmentInfoReponseModel;
  appointmentsCounts: AppointmentsCountsResponseModel;
  selectedAppointmentStatus: WIPStatesResponseModel;
  dynamicColumns: DynamicCols[];
  selectedColumns: DynamicCols[];
  branchResponseModel: BranchResponseModel[];
  selectedNSbranch: BranchResponseModel;
  selectedSbranch: BranchResponseModel;
  appointmentCountModels: SalesOrdersCountModel[];
  appointmentSelectedCountModels: SalesOrdersCountModel;
  dataExpressionloading: boolean = false;
  isSalesOrderBackHistory: boolean = false;
  isAppointmentBackHistory: boolean = false;
  appDateFormat: DateFormatModel;
  salesorderStatusCountModel: SalesorderStatusCountModel;
  appointmentsStatusCount: AppointmentCountResponseModel;
  @ViewChild("table") private _table: Table;
  @ViewChild("tableAppointment") private _tableAppointment: Table;
  @ViewChild('searchBoxAppointment') searchBoxAppointment: ElementRef;
  @ViewChild('statusDropdownAppointment') statusDropdownAppointment: ElementRef;
  @ViewChild("headerCheckBox") private headerCheckBox: TableHeaderCheckbox;
  @ViewChild("headerSCheckBox") private headerSCheckBox: TableHeaderCheckbox;
  selectedpage: QuickFilters;
  selectedpageNS: QuickFilters;
  @ViewChildren('headerCheckBox') childrenComponent: QueryList<TableHeaderCheckbox>;
  @ViewChildren('headerSCheckBox') headerSCheckBoxchildrenComponent: QueryList<TableHeaderCheckbox>;
  iBookedId: number = 2;
  index: number = 0;
  connection: any;
  callSID: string;
  data1: any;
  device: any;
  mySubscription: any;
  facilityResponseModelDropdownList: FacilityResponseModelDropdownList[];
  selectedfacilityResponseModel: FacilityResponseModelDropdownList;
  doctorResponseModel: DoctorResponseModel[];
  selectedDoctorResponseModel: DoctorResponseModel;
  minutes: SelectItem[];
  selectedMinute: SelectItem;
  rescheduleCallSID: string;
  isAppointmentDisabled: boolean = false;
  selectedNSFilter: QuickFilters;
  quickNSFilters: QuickFilters[];
  selectedSFilter: QuickFilters;
  quickSFilters: QuickFilters[];
  searchFromNSDate: Date;
  searchToNSDate: Date;
  searchFromSDate: Date;
  searchToSDate: Date;
  customSDate: boolean;
  customNSDate: boolean;
  NsheduledTableDefaltColumns: number = 6;
  sheduledTableDefaltColumns: number = 6;
  scheduleappointment: boolean = true;
  schedulecall: boolean = false;
  actionType: string = "S";
  scheduleAction: string;
  loggedInUserId: number;
  formCancelSubmitted: boolean = false;
  formVoidAppointmentSubmitted: boolean = false;
  appointmentCallResponseModel: AppointmentCallResponseModel;
  // selectedfilterIndex = -1;
  // selectedfilterSIndex = -1;
  selectedfilterIndex = 0;
  selectedfilterSIndex = 1;
  status: boolean = false;
  currentUserBranch: number;
  currentSelectedNSUserBranch: number;
  currentSelectedSUserBranch: number;
  //global search
  searchText: string;
  isGlobalSearch: boolean = false;
  orderObj: any;
  NSSelectedRange: any;
  NSSelectedDateRange: any;
  SSelectedDateRange: any;
  SalesOrderFound: boolean = false;
  AppointmentFound: boolean = false;
  //added for popup
  iNoShowId: number = 5;
  iCancelId: number = 4;
  iCompleteId: Number = 6;
  windowheight: number = window.innerHeight;
  tableheight: any = 297;
  isUnreconciled: boolean = false;
  salesOrderId: number;
  schAppointmentId: number;
  scheduleActionePopUp: string;
  salesOrder: SalesOrderInfoResponseModel;
  isAppointment: boolean;
  isTelehealth: boolean;
  displayCancelAppointment: boolean;
  displayVoidAppointment: boolean;
  displayNoShowAppointment: boolean;
  displayCompleteAppointment: boolean;
  reasonCommentsNoShow: string;
  reasonCommentsComplete: string;
  scheduleAppointment: boolean = false;
  voiceMailAppointment: boolean = false;
  appointmentNextCall: boolean = false;
  isCallStarted: boolean = false;
  IsRescheduleFacilityOption: boolean = false;
  rescheduleFacilityOption: string;
  callocales: any;
  selected: any;
  selectedCity: any;
  invalidDates: moment.Moment[] = [];
  showRangeLabelOnInput: boolean;
  filterShowHide: boolean = false;
  filterShowHideSchedule: boolean = false;
  hideSchItem: boolean = false;
  convertedRemoteAppointmentType: string;
  remoteAppointmentTypeResponseModel: Array<RemoteAppointmentTypeModel>;
  selectedTimeZone: TimeZoneAvailableResponseModel[];
  timeZoneList: TimeZoneAvailableResponseModel[];
  primaryInsuranceProviderList: PrimaryInsuranceProviderResponseModel[];
  selectedPrimaryInsuranceProvider: PrimaryInsuranceProviderResponseModel[];
  btSalesOrderUpdateRequestModel: BTSalesOrderUpdateRequestModel[];
  filterClassificationList: FilterClassificationTypeResponseModel[];
  selectedClassificationType: FilterClassificationTypeResponseModel[];
  salesOrderAppIds: any[];
  appointmentType: string;
  voidFrom: string = "";
  isSaleOrder: boolean = false;
  selectedStatusStr: string = 'Ready For Scheduling';

  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  ConfirmInvitePatients: boolean = false;
  restrictedSalesOrderIdToSendInvite: { crmSalesOrderAPPId: string, crmPatientName: string, crmBranch: string }[];
  salesOrderIdToSendInvite: any[];
  totalSelectedSendInviteSalesOrderIds: number = 0;


  userNonSPreferenceColumn: UserPreferencesModuleColumnResponseModel[];
  userSPreferenceColumn: UserPreferencesModuleColumnResponseModel[];
  isOnlyNonSFixedColumnVisible: boolean = false;
  isOnlySFixedColumnVisible: boolean = false;

  ref: DynamicDialogRef | undefined;

  //41466
  selectedTimeZones: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string };
  timeZone: { name: string; code: string; orgcode: string; shortName: string; displayName: string; countryName: string }[];
  ConfirmSetStatusChange: boolean = false;
  restrictedApptForStatus: { crmSalesOrderAPPId: string, crmPatientName: string, schAppointmentDateTime: Date }[];
  tempNoShowAppts: any[];
  totalSelectedNoShowAppointments: number = 0;
  statusType: string;
  updateStatusforminfo: statusInfo;
  header: string = 'Status of Appointment';
  isMultiSelected: String = 'False';

  ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  sRanges = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Today: [moment(), moment()],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  };

  tooltips = [
    { date: moment(), text: 'Today is just unselectable' },
    { date: moment().add(2, 'days'), text: 'Yeeeees!!!' },
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };
  cities: { name: string; code: string; }[];
  clickEventFilterStatus: boolean;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  enableNsDateRangeClrIcon: boolean = false;
  enableSchDateRangeClrIcon: boolean = false;


  rangeClicked(range): void {
    this.NSSelectedRange = range.label;

  }

  datesUpdated(range): void {
    this.NSSelectedDateRange = range;
    this.onCustomDateNSSearchButtonClick();
  }

  pageSiges: QuickFilters[];
  //Freeze
  cols: any[];
  frozencols: any[];
  colsSch: any[];
  frozencolsSch: any[];
  height: string = "400px";
  height1: string = "400px";
  defaultModeofAppointment: string = "";
  //Freeze  
  private subscriptionService: any;
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private userService: UserService,
    private settingsService: SettingsService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder
  ) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.initializeAllDefaultModel();//41466
    //Not schduled  Parameters
    this.totalRecords = 0;
    this.totalPages = 1;
    this.loading = false;
    this.totalAppointmentRecords = 0;
    this.totalAppointmentPages = 1;
    this.AppointmentOrderType = 'AppointmentRequest';
    this.appointmentsalesOrderStatusMenuitems = new Array();
    this.salesOrderType = 'SalesOrderRequest';
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    this.pageSiges = [
      { name: '20', code: '20' },
      { name: '25', code: '25' },
      { name: '50', code: '50' },
      { name: '100', code: '100' },
    ];
    this.selectedpage = { name: '20', code: '20' };
    this.selectedpageNS = { name: '20', code: '20' };
    this.facilityVisitTypes = [
      { label: '"Same Facility', value: 'SF' },
      { label: 'Other Facility', value: 'OF' },
    ];

    this.minutes = [
      { label: "Schedule Appointment", value: 30 },
      { label: "Schedule Future Callback", value: 60 }
    ];

    this.setPermissionforActions();
    //this.getAppointmentSalesOrderStatus();
    this.selectedMinute = { label: "Schedule Appointment", value: 30 };
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.currentSelectedSUserBranch = this.currentUserBranch;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    this.getWipStatusBTmapping();
    if (currentNavigationState && currentNavigationState.searchRequestModel) {

      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.index = Number(currentNavigationState.selectedTab);
      this.selectedfilterIndex = currentNavigationState.selectedfilterIndex == 4 ? 0 : currentNavigationState.selectedfilterIndex;
      this.totalRecords = this.searchRequestModel.totalRecords;
      this.isAppointmentDisabled = currentNavigationState.isBookAppintment ? currentNavigationState.isBookAppintment : false;
      this.hasPageHistory = true;
      this.isSalesOrderBackHistory = true;
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };

    }

    if (currentNavigationState && currentNavigationState.searchAppointmentRequestModel) {

      this.searchAppointmentRequestModel = currentNavigationState.searchAppointmentRequestModel;
      this.index = Number(currentNavigationState.selectedTab);
      this.totalAppointmentRecords = this.searchAppointmentRequestModel.totalRecords;
      this.selectedfilterSIndex = currentNavigationState.selectedfilterIndex;
      this.hasPageHistory = true;
      this.isAppointmentBackHistory = true;
    }
    this.getNonSUserPreferenceColumn();
    this.getSUserPreferenceColumn();
    this.callocales = {
      format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Apply', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
    }

    this.quickNSFilters = [
      { name: 'Quick Filters', code: '' },
      { name: 'Today', code: 'Today' },
      { name: 'Yesterday', code: 'Yesterday' },
      { name: 'Last Week', code: 'LastWeek' },
      { name: 'Last Month', code: 'LastMonth' },
      { name: 'Custom Date', code: 'CustomDate' }
    ];

    this.quickSFilters = [
      // { name: 'Quick Appt. Date Filters', code: '' },
      { name: 'Quick Filters', code: '' },
      { name: 'Today', code: 'Today' },
      { name: 'Yesterday', code: 'Yesterday' },
      { name: 'Last Week', code: 'LastWeek' },
      { name: 'Last Month', code: 'LastMonth' },
      { name: 'Tommorrow', code: 'Tommorrow' },
      { name: 'Next Week', code: 'NextWeek' },
      { name: 'Next Month', code: 'NextMonth' },
      { name: 'Custom Date', code: 'CustomDate' }
    ];

    this.selectedSalesOrder = new SalesOrderSummaryResponseModel();
    this.selectedNonSColumns = new Array();
    this.dynamicNonSColumns = new Array();
    this.dynamicNonSColumns = [

      { fieldNo: 1, field: 'amountDue', sortFieldName: 'AmountDue', header: 'Amount Due', visible: false, style: 'width:15%', type: 'number', },
      { fieldNo: 3, field: 'crmClassificaitonduration', sortFieldName: 'CrmClassificaitonduration', header: 'Appt. Duration', visible: false, style: 'width:8em', type: 'number', },
      { fieldNo: 4, field: 'crmPatientEmail', sortFieldName: 'CrmPatientEmail', header: 'Email', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 5, field: 'crmPatientPhoneNumber', sortFieldName: 'CrmPatientPhoneNumber', header: 'Phone Number', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 13, field: 'timeZone', sortFieldName: 'timeZone', header: 'Time Zone', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 17, field: 'crmPolicyHolderName', sortFieldName: 'crmPolicyHolderName', header: 'Primary Insurance', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 14, field: 'lastCallDate', header: 'Last Call Date & Time', visible: false, sortFieldName: 'lastCallDate', style: 'width:15%', type: 'Date' }
    ];

    // Scheduled Paramaters

    // Get page info passed from edit page
    // so can use it to maintain paging and sorting

    this.selectedColumns = new Array();
    this.dynamicColumns = new Array();
    this.dynamicColumns = [

      { fieldNo: 2, field: 'crmFacilityName', sortFieldName: 'CrmFacilityName', header: 'Facility', visible: false, style: 'width:15%', type: 'string', },
      { fieldNo: 3, field: 'amountDue', sortFieldName: 'AmountDue', header: 'Amount Due', visible: false, style: 'width:15%', type: 'number', },
      { fieldNo: 5, field: 'crmClassificaitonduration', sortFieldName: 'CrmClassificaitonduration', header: 'Appt. Duration', visible: false, style: 'width:8em', type: 'number', },
      { fieldNo: 6, field: 'crmPatientEmail', sortFieldName: 'CrmPatientEmail', header: 'Email', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 7, field: 'crmPatientPhoneNumber', sortFieldName: 'CrmPatientPhoneNumber', header: 'Phone Number', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 14, field: 'crmFacilityTimeZone', sortFieldName: 'crmFacilityTimeZone', header: 'Facility Time Zone', visible: false, style: 'width:15%', type: 'string', },
      { fieldNo: 15, field: 'crmPatientTimeZone', sortFieldName: 'crmPatientTimeZone', header: 'Patient Time Zone', visible: false, style: 'width:15%', type: 'string', },

      { fieldNo: 17, field: 'crmPolicyHolderName', sortFieldName: 'crmPolicyHolderName', header: 'Primary Insurance', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 18, visible: false, field: 'lastCallDate', header: 'Last Call Date & Time', sortFieldName: 'lastCallDate', style: 'width:15%', type: 'Date' },
      { fieldNo: 19, field: 'appointmentBookedBy', sortFieldName: 'appointmentBookedBy', header: 'Appointment Booked By', visible: false, style: 'width:15%', type: 'string', },
    ];

    if (this.index == 0) {
      this.initializeSearchRequestModel();
    }
    else if (this.index == 1) {
      this.initializeSearchAppointmentRequestModel();
    }
    this.timeZoneList = this.webStorageService.getTimeZoneList();
  }
  initializeAllDefaultModel() { //41466    
    this.timeZone = [
      //{ name: 'Indian Standard Time (UTC +5:30)', code: 'UTC +5:30', orgcode: 'UTC+5', shortName: 'IST', displayName: 'IST', countryName: 'Asia/Kolkata' },
      { name: 'Eastern Time (UTC -5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST', displayName: 'EST', countryName: 'America/New_York' },
      { name: 'Central Time (UTC -6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST', displayName: 'Central', countryName: 'America/Chicago' },
      { name: 'Mountain Time (UTC -7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST', displayName: 'Mountain', countryName: 'America/Boise' },
      { name: 'Arizona Mountain Time (UTC -7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST', displayName: 'Mountain', countryName: 'America/Phoenix' },
      { name: 'Pacific Time (UTC -8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST', displayName: 'Pacific', countryName: 'America/Los_Angeles' },
    ];

  }

  title = 'Appointments';
  async getWipStatusBTmapping() {
    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {

          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
              if (this.wipStatusBTMappingResponseModel && this.wipStatusBTMappingResponseModel.length > 0) {
                let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked')
                if (selectedWipStaus) {
                  this.iBookedId = selectedWipStaus.crmWipstatusId;
                }
              }
            }

          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }
  async ngOnInit() {
    //await this.getDefaultModeofAppointment();
    await this.getStatus();
    //await this.getStatus();

    this.setPermissionforActions();
    await this.getRemoteAppointmentType();
    this.buildForm();
    await this.getAppointmentSalesOrderStatus();
    await this.getCancelVoidReasons();
    await this.GetRCMConfigurationUrl();
    this.activatedRoute.queryParamMap.subscribe(params => {
      this.orderObj = { ...params.keys, ...params };
      if (this.orderObj.params && this.orderObj.params.searchtext) {
        this.searchText = this.orderObj.params.searchtext;
        this.isGlobalSearch = true;
      }
    });

    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null) {
        this.salesSelectedOrderCountModels = null;
        this.appointmentSelectedCountModels = null;
        // this.selectedfilterIndex = -1;
        this.selectedfilterIndex = 0;
        this.selectedfilterSIndex = -1;
        this.currentUserBranch = branchId;
        this.currentSelectedNSUserBranch = branchId;
        this.currentSelectedSUserBranch = branchId;
        //this.getSalesOrderCountByStatus(true);
        this.GetSalesOrderCountOnAppointmentStatus(true);
        this.getsalesordersRefresh();
        this.initializeSearchAppointmentRequestModel();
        //this.getAppointmentOrderCountByStatus(true);
        this.getAppointmentsOnStatusCount(true);
        this.getappointmentsRefresh();

        let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
        if (selectedBranch) {
          this.selectedNSbranch = selectedBranch;
          this.selectedSbranch = selectedBranch;
        }
        else {
          this.selectedNSbranch = this.branchResponseModel[0];
          this.selectedSbranch = this.branchResponseModel[0];;
        }

      }
    });



    this.mySubscription = this.globalSearchService.searchTerm.subscribe((newValue: GlobalSearchModel) => {

      //setTimeout(() => {
      //  if (this._cdr && !(this._cdr as ViewRef).destroyed) {
      //    this._cdr.detectChanges();
      //  }
      //}, 250);

      if (newValue) {
        this.salesOrder = new SalesOrderInfoResponseModel();
        this.salesOrder.patientInfo = new PatientInfoResponseModel();
        this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();

        this.searchText = newValue.searchText;
        this.isGlobalSearch = true;

        if (this.isGlobalSearch) {
          this.hasPageHistory = false;
          this.initializeSearchAppointmentRequestModel();
          //this.getTimeZone();
          this.getappointments();
          this.initializeSearchRequestModel();
          this.getsalesorders();

        }
        else {

          if (newValue.branchId >= 0) {
            this.salesSelectedOrderCountModels = null;
            this.appointmentSelectedCountModels = null;
            // this.selectedfilterIndex = -1;
            this.selectedfilterIndex = 0;
            this.selectedfilterSIndex = -1;
            this.currentUserBranch = newValue.branchId;
            this.currentSelectedNSUserBranch = newValue.branchId;
            this.currentSelectedSUserBranch = newValue.branchId;
            //this.getSalesOrderCountByStatus(false);
            this.GetSalesOrderCountOnAppointmentStatus(false);
            this.getsalesorders();
            this.initializeSearchAppointmentRequestModel();
            //this.getAppointmentOrderCountByStatus(false);
            this.getAppointmentsOnStatusCount(false);
            //this.getTimeZone();
            this.getappointments();

            let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
            if (selectedBranch) {
              this.selectedNSbranch = selectedBranch;
              this.selectedSbranch = selectedBranch;
            }
            else {
              this.selectedNSbranch = this.branchResponseModel[0];
              this.selectedSbranch = this.branchResponseModel[0];;
            }

            this._cdr.detectChanges();

          }

        }

        if (this.isGlobalSearch) {
          if (this.SalesOrderFound && this.AppointmentFound) {
            this.index = 0;
          }
          else if (this.SalesOrderFound) {
            this.index = 0;
          }
          else if (this.AppointmentFound) {
            this.index = 1;
          }
        }
      }
    });

    this.isTelehealth = false;
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.appointmentDetailInfo.facilityInfo = new FacilityInfoResponseModel();
    this.selectedDoctorResponseModel = new DoctorResponseModel();
    this.selectedfacilityResponseModel = new FacilityResponseModelDropdownList();
    if (!this.isSalesOrderBackHistory)
      this.initializeSearchRequestModel();
    this.isSalesOrderBackHistory = false;
    await this.getSalesOrderStatus();

    //await this.getSalesOrderCountByStatus(false);
    await this.GetSalesOrderCountOnAppointmentStatus(false);

    if (!this.isAppointmentBackHistory)
      this.initializeSearchAppointmentRequestModel();

    this.isAppointmentBackHistory = false;

    //await this.getAppointmentOrderCountByStatus(true);
    //await this.getAppointmentsOnStatusCount(true);
    //await this.getBranches();
    //await this.getAllSalesOrderDoctors();
    //await this.getAllSalesOrderDoctorFacility();
    //await this.IsSalesOrdersLockedByUser();
    //await this.getAllPrimaryInsuranceProviders();
    //await this.getAllClassificationType();

    await this.getSalesOrderDependenciesDetails();

    this.webStorageService.deleteRedirectFrom();
    if (this.searchRequestModel) {
      if (this.searchRequestModel.crmBranchId != null) {
        this.selectedNSbranch = this.branchResponseModel.find(x => x.crmBranchId === this.searchRequestModel.crmBranchId);
      }
      if (this.searchRequestModel.dictionaryFilter != null && this.searchRequestModel.dictionaryFilter.OrderingDoctor != null) {
        this.selectedDoctorResponseModel = this.doctorResponseModel.find(x => x.crmDoctorId === parseInt(this.searchRequestModel.dictionaryFilter.OrderingDoctor));
      }
      if (this.searchRequestModel.dictionaryFilter != null && this.searchRequestModel.dictionaryFilter.OrderingDoctorFacility != null) {
        this.selectedfacilityResponseModel = this.facilityResponseModelDropdownList.find(x => x.crmFacilityId === parseInt(this.searchRequestModel.dictionaryFilter.OrderingDoctorFacility));
      }
      if (this.searchRequestModel.selectedTimeZone != undefined) {
        let timeZones = new Array<string>();
        let selectedTime = new Array<TimeZoneAvailableResponseModel>();
        timeZones = this.searchRequestModel.selectedTimeZone.split(',');
        timeZones.forEach(x => {
          let t = this.timeZoneList.find(y => y.appTimeZoneOffSet === x);
          selectedTime.push(t);
        });
        this.selectedTimeZone = selectedTime;
      }
      if (this.searchRequestModel.selectedPrimaryInsurance != undefined) {
        let insurnace = new Array<string>();
        let selectedInsurance = new Array<PrimaryInsuranceProviderResponseModel>();
        insurnace = this.searchRequestModel.selectedPrimaryInsurance.split(',');
        insurnace.forEach(x => {
          let t = this.primaryInsuranceProviderList.find(y => y.crmPolicyHolderName === x);
          selectedInsurance.push(t);
        });
        this.selectedPrimaryInsuranceProvider = selectedInsurance;
      }
      if (this.searchRequestModel.selectedClassificationType != undefined) {
        let classification = new Array<string>();
        let selectedClassification = new Array<FilterClassificationTypeResponseModel>();
        classification = this.searchRequestModel.selectedClassificationType.split(',');
        classification.forEach(x => {
          let t = this.filterClassificationList.find(y => y.crmClassificationTypeId === parseInt(x));
          selectedClassification.push(t);
        });
        this.selectedClassificationType = selectedClassification;
      }

      if (this.searchRequestModel.searchFromDate != null && this.searchRequestModel.searchToDate != null) {
        let dt = { 'startDate': this.searchRequestModel.searchFromDate, 'endDate': this.searchRequestModel.searchToDate };
        this.NSSelectedDateRange = dt;
      }

    }
    if (this.searchAppointmentRequestModel) {
      if (this.searchRequestModel.crmBranchId != null) {
        this.selectedSbranch = this.branchResponseModel.find(x => x.crmBranchId === this.searchAppointmentRequestModel.crmBranchId);
      }
      if (this.searchAppointmentRequestModel.selectedTimeZone != undefined) {
        let timeZones = new Array<string>();
        let selectedTime = new Array<TimeZoneAvailableResponseModel>();
        timeZones = this.searchAppointmentRequestModel.selectedTimeZone.split(',');
        timeZones.forEach(x => {
          let t = this.timeZoneList.find(y => y.appTimeZoneOffSet === x);
          selectedTime.push(t);
        });
        this.selectedTimeZone = selectedTime;
      }
      if (this.searchAppointmentRequestModel.selectedPrimaryInsurance != undefined) {
        let insurnace = new Array<string>();
        let selectedInsurance = new Array<PrimaryInsuranceProviderResponseModel>();
        insurnace = this.searchAppointmentRequestModel.selectedPrimaryInsurance.split(',');
        insurnace.forEach(x => {
          let t = this.primaryInsuranceProviderList.find(y => y.crmPolicyHolderName === x);
          selectedInsurance.push(t);
        });
        this.selectedPrimaryInsuranceProvider = selectedInsurance;
      }
      if (this.searchAppointmentRequestModel.selectedClassificationType != undefined) {
        let classification = new Array<string>();
        let selectedClassification = new Array<FilterClassificationTypeResponseModel>();
        classification = this.searchAppointmentRequestModel.selectedClassificationType.split(',');
        classification.forEach(x => {
          let t = this.filterClassificationList.find(y => y.crmClassificationTypeId === parseInt(x));
          selectedClassification.push(t);
        });
        this.selectedClassificationType = selectedClassification;
      }
      if (this.searchAppointmentRequestModel.searchFromDate != null && this.searchAppointmentRequestModel.searchToDate != null) {
        let dt = { 'startDate': this.searchAppointmentRequestModel.searchFromDate, 'endDate': this.searchAppointmentRequestModel.searchToDate };
        this.SSelectedDateRange = dt;
      }
    }
  }

  async getRemoteAppointmentType() {
    this.loading = true;
    this.settingsService.getRemoteAppointmentTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.remoteAppointmentTypeResponseModel = result.responseModel;
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private async getBranches() {
    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
            if (selectedBranch) {
              this.selectedNSbranch = selectedBranch;
              this.selectedSbranch = selectedBranch;
            }
            else {
              this.selectedNSbranch = this.branchResponseModel[0];
              this.selectedSbranch = this.branchResponseModel[0];;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllSalesOrderDoctorFacility() {
    this.progress.next(true);
    await this.appointmentService.GetAllDoctorFacility()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.facilityResponseModelDropdownList = result.responseModel;
          }
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllSalesOrderDoctors() {
    this.progress.next(true);
    await this.appointmentService.GetAllDoctors()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {

            this.doctorResponseModel = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onCustomDateSSearchButtonClick() {
    if (!this.SSelectedDateRange || this.SSelectedDateRange == undefined || this.SSelectedDateRange.startDate == null) {
      // this.searchAppointmentRequestModel.dictionaryFilter = {};
      return;
    }

    //this.searchAppointmentRequestModel.dictionaryFilter = {};
    this.enableSchDateRangeClrIcon = false;
    if (this.searchBoxAppointment != undefined) {
      if (this.searchBoxAppointment.nativeElement.value) {
        this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
      }
      else {
        this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = '';
      }

    }

    let selSatatus = this.appointmentSelectedCountModels;
    if (selSatatus)
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.salesOrderStatusDisplayble;



    let startDate = this.SSelectedDateRange.startDate;
    let endDate = this.SSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }

    if (startDate && endDate) {
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchAppointmentRequestModel.searchFromDate = startDate;
      this.searchAppointmentRequestModel.searchToDate = endDate;
      this.enableSchDateRangeClrIcon = true;
    }

    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    //this.getappointments();
  }


  onCustomDateNSSearchButtonClick() {
    this.enableNsDateRangeClrIcon = false;
    if (!this.NSSelectedDateRange || this.NSSelectedDateRange == undefined || this.NSSelectedDateRange.startDate == null) {
      //this.searchRequestModel.dictionaryFilter = {};
      return;
    }

    //this.searchRequestModel.dictionaryFilter = {}
    // if (this.searchBox != undefined) {
    //   if (this.searchBox.nativeElement.value) {
    //     this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    //   }
    // }

    // let selSatatus = this.salesSelectedOrderCountModels;
    // if (selSatatus) {
    //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
    //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
    //   }
    //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
    //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
    //   }
    //   else
    //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderViewStatus;
    // }

    // if (this.selectedDoctorResponseModel != undefined) {
    //   if (this.selectedDoctorResponseModel.crmDoctorId > 0)
    //     this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();
    // }


    // if (this.selectedfacilityResponseModel != undefined) {
    //   if (this.selectedfacilityResponseModel.crmFacilityId > 0)
    //     this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();
    // }

    let startDate = this.NSSelectedDateRange.startDate;
    let endDate = this.NSSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    if (startDate && endDate) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchRequestModel.searchFromDate = startDate;
      this.searchRequestModel.searchToDate = endDate;
      this.enableNsDateRangeClrIcon = true;
    }

    //this.resetPageIndex();
    //his.getsalesorders();

  }



  onSustomDateNSSearchButtonClick() {
    if (!this.selectedNSFilter.code) {

      return;
    }
    let startDate = this.searchFromNSDate
    let endDate = this.searchToNSDate;
    if (!startDate) {
      this.showWarningMessage("Please Select From Date.");
      return;
    }
    if (!endDate) {
      this.showWarningMessage("Please Select To Date.");
      return;
    }
    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();
    if (startDate > endDate) {
      this.showWarningMessage("From Date should not be greater than To Date.");
      return;
    }


    this.searchRequestModel.dictionaryFilter = {}
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    let selSatatus = this.salesSelectedOrderCountModels;
    if (selSatatus) {
      if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
        this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      }
      else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
        this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      }
      else
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
    }

    let selCustomDates = this.selectedNSFilter;
    if (selCustomDates) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = selCustomDates.code;
    }
    this.searchRequestModel.searchFromDate = startDate;
    this.searchRequestModel.searchToDate = endDate;
    this.resetPageIndex();
    this.getsalesorders();

  }

  onSustomDateSSearchButtonClick() {
    if (!this.selectedSFilter.code) {
      return;
    }

    let startDate = this.searchFromSDate
    let endDate = this.searchToSDate;
    if (!startDate) {
      this.showWarningMessage("Please Select From Date.");
      return;
    }
    if (!endDate) {
      this.showWarningMessage("Please Select To Date.");
      return;
    }
    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();
    if (startDate > endDate) {
      this.showWarningMessage("From Date should not be greater than To Date.");
      return;
    }

    this.searchAppointmentRequestModel.dictionaryFilter = {};
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

    let selSatatus = this.appointmentSelectedCountModels;
    if (selSatatus)
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.salesOrderStatusDisplayble;


    let selCustomDates = this.selectedSFilter;
    if (selCustomDates) {
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = selCustomDates.code;
    }
    this.searchAppointmentRequestModel.searchFromDate = startDate;
    this.searchAppointmentRequestModel.searchToDate = endDate;

    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();


  }

  ShowHideNSFilter(e) {
    this.customNSDate = false;
    if (!this.selectedNSFilter.code) {
      this.searchRequestModel.dictionaryFilter = {};
      if (this.searchBox.nativeElement.value)
        this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      let selSatatus = this.salesSelectedOrderCountModels;
      if (selSatatus) {
        if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
          this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
        }
        else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
          this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
        }
        else
          this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      }
      this.resetPageIndex();
      this.getsalesorders();
      return;
    }
    if (this.selectedNSFilter.code == 'CustomDate') {
      this.customNSDate = true;
      return;
    }
    this.searchRequestModel.dictionaryFilter = {};
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    let selSatatus = this.salesSelectedOrderCountModels;
    if (selSatatus) {
      if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
        this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      }
      else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
        this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      }
      else
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
    }

    let selCustomDates = this.selectedNSFilter;
    if (selCustomDates) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = selCustomDates.code;
    }

    this.resetPageIndex();
    this.getsalesorders();

  }

  ShowHideSFilter(e) {

    this.customSDate = false;
    if (!this.selectedSFilter.code) {
      this.searchAppointmentRequestModel.dictionaryFilter = {};
      if (this.searchBoxAppointment.nativeElement.value)
        this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
      let selSatatus = this.appointmentSelectedCountModels;
      if (selSatatus)
        this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.salesOrderStatusDisplayble;
      this.resetAppoinmentPageIndex();
      //this.getTimeZone();
      this.getappointments();
      return;
    }
    if (this.selectedSFilter.code == 'CustomDate') {
      this.customSDate = true;
      return;
    }

    this.searchAppointmentRequestModel.dictionaryFilter = {};
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

    let selSatatus = this.appointmentSelectedCountModels;
    if (selSatatus)
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.salesOrderStatusDisplayble;

    let selCustomDates = this.selectedSFilter;
    if (selCustomDates) {
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = selCustomDates.code;
    }

    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();

  }

  handleChange(e: any) {
    this.index = e.index;
    this.getNonSUserPreferenceColumn();
    this.getSUserPreferenceColumn();
    this.getAllPrimaryInsuranceProviders();
    this.getAllClassificationType()
  }

  headerCheckboxToggle(e) {

    this.selectedsalesOrders.forEach(value => {

      value.schIsLocked = false;

    });
  }

  //Permission Check
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isAppointmentVoidPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features != null && this.features != undefined) {
      if (this.features && this.features.length > 0) {

        let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
        if (inPer && inPer.isActive == true)
          this.isSendInvitationPermission = true;

        let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
        if (inPer1 && inPer1.isActive == true) {
          this.isAppointmentScheduledPermission = true;
          this.isAppointmentRescheduledPermission = true;
        }

        let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
        if (inPer2 && inPer2.isActive == true)
          this.isAppointmentCancelPermission = true;

        //let inPer3 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentrescheduled');
        //if (inPer3 && inPer3.isActive == true)
        //  this.isAppointmentRescheduledPermission = true;


        let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
        if (inPer4 && inPer4.isActive == true)
          this.isAppointmentMovedPermission = true;

        let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
        if (inPer5 && inPer5.isActive == true)
          this.isAppointmentOverduePermission = true;

        let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
        if (inPer6 && inPer6.isActive == true)
          this.isAppointmentNoshowPermission = true;

        let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
        if (inPer7 && inPer7.isActive == true)
          this.isAppointmentCompletedPermission = true;

        let inPer8 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentvoid');
        if (inPer8 && inPer8.isActive == true)
          this.isAppointmentVoidPermission = true;
      }
    }
  }
  onBranchSChange(event) {

    if (event.value) {
      this.currentSelectedSUserBranch = Number(event.value.crmBranchId);
      this.searchAppointmentRequestModel.crmBranchId = Number(event.value.crmBranchId);
      // this.searchAppointmentRequestModel.dictionaryFilter = {}
      // if (this.searchBoxAppointment.nativeElement.value)
      //   this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;


      // let selSatatus = this.selectedAppointmentStatus;
      // if (selSatatus)
      //   this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.wipStatesId.toString();

      // let startDate = this.SSelectedDateRange.startDate;
      // let endDate = this.SSelectedDateRange.endDate;

      // if (startDate && endDate) {
      //   startDate = moment(startDate).toDate();
      //   endDate = moment(endDate).toDate();
      //   if (startDate > endDate) {
      //     this.showWarningMessage("From Date should not be greater than To Date.");
      //     return;
      //   }
      // }

      // if (startDate && endDate) {
      //   this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

      //   this.searchAppointmentRequestModel.searchFromDate = startDate;
      //   this.searchAppointmentRequestModel.searchToDate = endDate;
      // }


      // this.resetAppoinmentPageIndex();
      // this.getAppointmentOrderCountByStatus(true);
      // this.getTimeZone();
      // this.getappointments();
    }



  }

  onBranchChange(event) {
    if (event.value) {
      this.currentSelectedNSUserBranch = Number(event.value.crmBranchId);
      this.searchRequestModel.crmBranchId = Number(event.value.crmBranchId);
      // this.searchRequestModel.dictionaryFilter = {}
      // if (this.searchBox.nativeElement.value)
      //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // let selSatatus = this.salesSelectedOrderCountModels;
      // if (selSatatus) {
      //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      //   }
      //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      //   }
      //   else
      //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      // }
      // if (this.selectedDoctorResponseModel.crmDoctorId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();


      // if (this.selectedfacilityResponseModel.crmFacilityId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();

      // let startDate = this.NSSelectedDateRange.startDate;
      // let endDate = this.NSSelectedDateRange.endDate;

      // if (startDate && endDate) {
      //   startDate = moment(startDate).toDate();
      //   endDate = moment(endDate).toDate();
      //   if (startDate > endDate) {
      //     this.showWarningMessage("From Date should not be greater than To Date.");
      //     return;
      //   }
      // }
      // if (startDate && endDate) {
      //   this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      //   this.searchRequestModel.searchFromDate = startDate;
      //   this.searchRequestModel.searchToDate = endDate;
      // }

      // this.resetPageIndex();
      // this.getSalesOrderCountByStatus(true);
      // this.getsalesorders();


    }
  }

  onDoctorChange(event) {
    if (event.value) {

      //this.searchRequestModel.dictionaryFilter = {}
      // if (this.searchBox.nativeElement.value)
      //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // let selSatatus = this.salesSelectedOrderCountModels;
      // if (selSatatus) {
      //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      //   }
      //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      //   }
      //   else
      //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      // }

      // this.searchRequestModel.crmBranchId = this.selectedNSbranch.crmBranchId;
      this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = event.value.crmDoctorId.toString();


      // if (this.selectedfacilityResponseModel.crmFacilityId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();

      // let startDate = this.NSSelectedDateRange.startDate;
      // let endDate = this.NSSelectedDateRange.endDate;

      // if (startDate && endDate) {
      //   startDate = moment(startDate).toDate();
      //   endDate = moment(endDate).toDate();
      //   if (startDate > endDate) {
      //     this.showWarningMessage("From Date should not be greater than To Date.");
      //     return;
      //   }
      // }
      // if (startDate && endDate) {
      //   this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      //   this.searchRequestModel.searchFromDate = startDate;
      //   this.searchRequestModel.searchToDate = endDate;
      // }

      // this.resetPageIndex();
      // this.getSalesOrderCountByStatus(true);
      // this.getsalesorders();


    }
  }


  onFacilityChange(event) {

    if (event.value) {

      // this.searchRequestModel.dictionaryFilter = {}
      // if (this.searchBox.nativeElement.value)
      //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // let selSatatus = this.salesSelectedOrderCountModels;
      // if (selSatatus) {
      //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      //   }
      //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      //   }
      //   else
      //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      // }

      // if (this.selectedDoctorResponseModel.crmDoctorId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();


      // this.searchRequestModel.crmBranchId = this.selectedNSbranch.crmBranchId;
      this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = event.value.crmFacilityId.toString();

      // let startDate = this.NSSelectedDateRange.startDate;
      // let endDate = this.NSSelectedDateRange.endDate;

      // if (startDate && endDate) {
      //   startDate = moment(startDate).toDate();
      //   endDate = moment(endDate).toDate();
      //   if (startDate > endDate) {
      //     this.showWarningMessage("From Date should not be greater than To Date.");
      //     return;
      //   }
      // }
      // if (startDate && endDate) {
      //   this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      //   this.searchRequestModel.searchFromDate = startDate;
      //   this.searchRequestModel.searchToDate = endDate;
      // }

      // this.resetPageIndex();
      // this.getSalesOrderCountByStatus(true);
      // this.getsalesorders();


    }
  }

  onSelectionChange(selection: any[]) {

    for (let i = selection.length - 1; i >= 0; i--) {
      let data = selection[i];
      if (this.isRowDisabled(data)) {
        selection.splice(i, 1);
      }
    }
    this.selectedsalesOrders = selection;
    if (this.selectedsalesOrders.length > 0)
      this.isDisabledLock = false;
    else
      this.isDisabledLock = true;
  }

  onSelectionSChange(selection: any[]) {
    this.selectedAppointments = selection;
    if (this.selectedAppointments.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  updateCheckedState() {
    this._cdr.markForCheck();

    if (this._tableAppointment.filteredValue && !this._tableAppointment.lazy) {
      const val = this._tableAppointment.filteredValue;
      return (val && val.length > 0 && this._tableAppointment.selection && this._tableAppointment.selection.length > 0);
    }
    else {
      const val = this._tableAppointment.value;
      const length = this._tableAppointment.lazy ? this._tableAppointment._totalRecords : val?.length;
      return (val && length > 0 && this._tableAppointment.selection && this._tableAppointment.selection.length > 0 && this._tableAppointment.selection.length === length);
    }
  }

  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
      if (comps.length > 0) {
        const orig_updateCheckedState = this.headerCheckBox.updateCheckedState;
        const me = this;
        this.headerCheckBox.updateCheckedState = function () {

          const cars: any[] = me._table.filteredValue || me._table.value;
          const selection: any[] = me._table.selection;
          if (cars != null) {
            for (const car of cars) {
              if (!me.isRowDisabled(car)) {
                const selected = selection && selection.indexOf(car) >= 0;
                if (!selected) return false;
              }
            }
          }
          if (selection && selection.length > 0)
            return true;
        };
      }
    });

    this.headerSCheckBoxchildrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
      if (comps.length > 0) {
        const orig_updateCheckedState = this.headerSCheckBox.updateCheckedState;
        const me = this;
        this.headerSCheckBox.updateCheckedState = function () {

          const cars: any[] = me._tableAppointment.filteredValue || me._tableAppointment.value;
          const selection: any[] = me._tableAppointment.selection;
          if (cars != null) {
            for (const car of cars) {
              if (!me.isRowDisabled(car)) {
                const selected = selection && selection.indexOf(car) >= 0;
                if (!selected) return false;
              }
            }
          }
          if (selection && selection.length > 0)
            return true;
        };
      }
    });

    this.setFilters();
    // this.hasPageHistory = false;

    var y = document.querySelector(".dummyClassNSch");
    const frozenBody: HTMLElement | null = y.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea: HTMLElement | null = y.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody && scrollableArea) {
      frozenBody.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
        const canScrollUp = 0 < scrollableArea.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea.scrollTop -= scrollDelta;
        }
      });
    }


    var x = document.querySelector(".dummyClassSch");
    const frozenBody1: HTMLElement | null = x.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea1: HTMLElement | null = x.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody1 && scrollableArea1) {
      frozenBody1.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea1.scrollTop < (scrollableArea1.scrollHeight - scrollableArea1.clientHeight);
        const canScrollUp = 0 < scrollableArea1.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea1.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea1.scrollTop -= scrollDelta;
        }
      });
    }
  }

  scrollIssue() {

    var x = document.querySelector(".dummyClassSch");

    const frozenBody1: HTMLElement | null = x.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea1: HTMLElement | null = x.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody1 && scrollableArea1) {
      frozenBody1.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea1.scrollTop < (scrollableArea1.scrollHeight - scrollableArea1.clientHeight);
        const canScrollUp = 0 < scrollableArea1.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea1.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea1.scrollTop -= scrollDelta;
        }
      });
    }
  }

  isRowDisabled(data: any): boolean {
    return data.schIsLocked === true
  }

  private setFilters() {
    if (!this.hasPageHistory)
      return;

    if (this.index === 0) {
      if (this.searchRequestModel.dictionaryFilter['SearchText'])
        this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'];
    }
    else {

      if (this.searchAppointmentRequestModel.dictionaryFilter['SearchText'])
        this.searchBoxAppointment.nativeElement.value = this.searchAppointmentRequestModel.dictionaryFilter['SearchText'];

    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }


    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
      //this.globalSearchService.changeGlobalBranch.complete();
    }

    if (this.globalSearchService.searchTerm) {
      this.globalSearchService.searchTerm.next(null);
    }

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }



    //if (this.subscriptionService) {
    //  //if (this.globalSearchService.searchTerm) {
    //  //  this.globalSearchService.searchTerm.next(null);
    //  //}
    //  this.subscriptionService.unsubscribe();
    //  this.subscriptionService.complete();
    //}

    if (this.progress)
      this.progress.complete();
  }

  //Start : Non Scheduled Events

  onView(salesOrderId: string, salesorderStatus: string, schAppointmentId: number) {
    if ((salesorderStatus.toUpperCase() == "NO SHOW" || salesorderStatus.toUpperCase() == "CANCEL") && schAppointmentId == 0)
      this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });
    else if ((salesorderStatus.toUpperCase() == "NO SHOW" || salesorderStatus.toUpperCase() == "CANCEL") && schAppointmentId > 0)
      this.router.navigate(['/admin/appointments/detail/', schAppointmentId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterIndex } });
    else
      // this.router.navigate(['/admin/appointments/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel } });
      this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });
  }

  showDialog(crmSalesOrder: SalesOrderSummaryResponseModel) {
    let crmSalesOrderId = crmSalesOrder.crmSalesOrderId;
    //if (crmSalesOrder.crmIsEnableAppointmentMaxLimit) {
    //  if (crmSalesOrder.crmClassificationTypeBookedAppointmentCount >= crmSalesOrder.crmMaxNoOfAppointment) {

    //    const refcheck = this.dialougeService.open(InformationDialogComponent, {
    //      data:
    //      {
    //        message: 'This classification type has reached the maximum number of appointments per day. If you want to increase the limit for the appointment per day. Please go to the Settings => Brightree => Classification Settings.',
    //      },
    //      header: 'Information'
    //    });

    //    refcheck.onClose.subscribe((response: boolean) => {
    //      if (response) {
    //        return;
    //      }
    //    });
    //    return;
    //  }
    //}



    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = false;
    this.schAppointmentId = 0;

    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getSalesOrderInfo(this.salesOrderId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: 0,
        isAppointmentAppoinmentPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      if (this.index == 0) {
        //this.getSalesOrderCountByStatus(true);
        this.GetSalesOrderCountOnAppointmentStatus(true);
        this.getsalesorders();
      }
      else {
        //this.getAppointmentOrderCountByStatus(true);
        this.getAppointmentsOnStatusCount(true);
        //this.getTimeZone();
        this.getappointments();
      }


      //this.HangUpCall();

    });


    //this.SchedulePopup = true;
    // this.cal = true;
  }

  showVoidSalesOrderDialog(crmSalesOrderId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = false;
    this.schAppointmentId = 0;

    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getSalesOrderInfo(this.salesOrderId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: false,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: true,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      if (this.index == 0) {
        //this.getSalesOrderCountByStatus(true);
        this.GetSalesOrderCountOnAppointmentStatus(true);
        this.getsalesorders();
      }
      else {
        //this.getAppointmentOrderCountByStatus(true);
        this.getAppointmentsOnStatusCount(true);
        //this.getTimeZone();
        this.getappointments();
      }


      //this.HangUpCall();

    });



  }

  showCallSalesOrderDialog(crmSalesOrderId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = false;
    this.schAppointmentId = 0;

    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getSalesOrderInfo(this.salesOrderId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: false,
        isScheduleOrder: false,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      if (this.index == 0) {
        //this.getSalesOrderCountByStatus(true);
        this.GetSalesOrderCountOnAppointmentStatus(true);
        this.getsalesorders();
      }
      else {
        //this.getAppointmentOrderCountByStatus(true);
        this.getAppointmentsOnStatusCount(true);
        //this.getTimeZone();
        this.getappointments();
      }


      //this.HangUpCall();

    });



  }

  showAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;

    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isAppointmentAppoinmentPage: true,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.getAppointmentOrderCountByStatus(true);
      //this.getTimeZone();
      this.getappointments();
      //this.HangUpCall();

    });


  }
  get fCancel() { return (<FormGroup>this.form.get('formCancel')).controls; }

  get formCancel() {
    if (this.form != null && this.form != undefined) {
      return this.form.get('formCancel');
    }
    return;
  }
  private getValuesFromCancelForm(): AppointmentCancelRequest {

    const form = this.formCancel;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.reason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.reasonComments).value;
    return cancelRequestModel;
  }

  cancelAppointment() {

    //bulk void/cancel saleorder/appoitmnet//
    if (this.salesOrderAppIds != null && this.salesOrderAppIds.length > 0) {
      /*  this.formCancelSubmitted = true;
       if (this.formCancel.invalid) {
         this.markFormAsTouched();
         return;
       } */
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false;

      this.CancelVoidSaleOrderSubmit();
      return;
    }
    //bulk void/cancel saleorder/appoitmnet//

    this.formCancelSubmitted = true;
    /* if (this.formCancel.invalid) {
      this.markFormAsTouched();
      return;
    } */
    this.formCancelSubmitted = false;
    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromUpdateStautsForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.cancelschAppointmentId;
    let cancelAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    cancelAppointmentRequestBT.IsUpdateToBrightree = false;
    cancelAppointmentRequestBT.IsDeliveryNoteOverride = true;
    cancelAppointmentRequestBT.IsOrderNoteOverride = true;
    cancelAppointmentRequestBT.OrderNote = null;
    cancelAppointmentRequestBT.DeliveryNote = null;
    cancelAppointmentRequestBT.PractitionerName = null;
    cancelAppointmentRequestBT.PractitionerId = 0;
    cancelAppointmentRequestBT.DeliveryTechnicianId = 0;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    cancelAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    cancelAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    cancelAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {

        cancelAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        cancelAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        cancelAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        cancelAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo.isBrightreeSystemAppUpdate;
    cancelAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = cancelAppointmentRequestBT;

    this.loading = true;

    cancelRequestModel.salesOrderUpdateRequestModelBT = cancelAppointmentRequestBT;
    this.appointmentService.CancelAppointment(cancelRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {

        if (result.responseModel) {
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
          this.showSuccessMessage("Appointment has been canceled.");
          const form = this.formCancel;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.reason).setValue('');
          form.get(this.formFields.reasonComments).setValue('');
          form.reset();
          this.displayCancelAppointment = false;
          this.getappointmentsRefresh();
          //this.getAppointmentOrderCountByStatus(true);
          this.getAppointmentsOnStatusCount(true);
          this._cdr.detectChanges();
          this.cancelschAppointmentId = null;
          this.loading = false
        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

  }
  showCancelAppointment(schAppointmentId) {
    this.isBrightreeSystemAppUpdateSetting = false;
    this.cancelschAppointmentId = schAppointmentId;
    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    //this.displayCancelAppointment = true;      
    let action = 'Cancel';
    let popupHeader = 'Update Cancel Status of Appointment(s)';
    this.callUpdateStatusForm(action, popupHeader, this.voidFrom);
  }
  hideAppointmentpopup() {
    const form = this.formCancel;
    form.get(this.formFields.reason).setValue('');
    form.get(this.formFields.reasonComments).setValue('');
    form.reset();
    this.displayCancelAppointment = false;
    this.ConfirmSetStatusChange = false;//41466
  }

  onBTSelect(status: any) {

    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }


  get fVoid() { return (<FormGroup>this.form.get('formVoidAppointment')).controls; }

  get formVoidAppointment() {
    if (this.form != null && this.form != undefined) {
      return this.form.get('formVoidAppointment');
    } return;

  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const form = this.formVoidAppointment;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.voidReason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    return cancelRequestModel;
  }

  private getValuesFromUpdateStautsForm(): AppointmentCancelRequest {
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.schCancelReason = this.updateStatusforminfo?.reason;
    cancelRequestModel.schComments = this.updateStatusforminfo?.reasonComment;
    return cancelRequestModel;
  }

  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.reasonsResponseModel = result.responseModel;
            const myClonedArray = [];
            this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
            this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
            this.cancellationReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Cancel');
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  formFields = {
    reason: 'reason',
    reasonComments: 'reasonComments',
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };

  validationMessages = {
    reason: {
      required: 'Cancel reason is required',
    },
    reasonComments: {
      required: 'Comment is required',
    },
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },


  };


  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.reasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formCancel: formGroup,
      formVoidAppointment: formVoidGroup
    });
  }

  cancelVoidAppointment() {
    const form = this.formVoidAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    this.displayVoidAppointment = false;
  }

  voidAppointment(voidFrom: string) {

    //this code for bulk cancel/void //
    if (this.salesOrderAppIds != null && this.salesOrderAppIds.length > 0) {
      this.formVoidAppointmentSubmitted = true;
      /* if (this.formVoidAppointment.invalid) {
        this.markFormAsTouched();
        return;
      } */
      this.formCancelSubmitted = false;
      this.formVoidAppointmentSubmitted = false;
      this.CancelVoidSaleOrderSubmit();
      return;
    }
    //this code for bulk cancel/void //

    /* this.formVoidAppointmentSubmitted = true;
    if (this.formVoidAppointment.invalid) {
      this.markFormAsTouched();
      return;
    } */
    this.formCancelSubmitted = false;

    this.formVoidAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromUpdateStautsForm();//this.getValuesFromVoidForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.isRescheduledRequest ? this.voidschAppointmentId : this.voidSalesOrderId;
    cancelRequestModel.voidFrom = voidFrom;
    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.DeliveryTechnicianId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (cancelRequestModel.voidFrom === 'P') {

      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;

      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }

    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;

    this.loading = true;
    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.VoidAppointment(cancelRequestModel) : this.appointmentService.VoidSalesOrder(cancelRequestModel);
    //this.appointmentService.CancelAppointment(cancelRequestModel)
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.responseModel) {
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
          this.showSuccessMessage("Appointment has been voided");
          const form = this.formVoidAppointment;
          //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
          form.get(this.formFields.voidReason).setValue('');
          form.get(this.formFields.voidReasonComments).setValue('');
          form.reset();

          if (this.isRescheduledRequest) {
            this.getappointmentsRefresh();

            //this.getAppointmentOrderCountByStatus(true);
            this.getAppointmentsOnStatusCount(true);
          }
          else {
            //this.getSalesOrderCountByStatus(true);
            this.GetSalesOrderCountOnAppointmentStatus(true);
            this.getsalesordersRefresh();
          }
          if (this.isRescheduledRequestFromNS) {
            this.GetSalesOrderCountOnAppointmentStatus(true);
            this.getsalesordersRefresh();
          }

          this.voidschAppointmentId = null; this.voidSalesOrderId = null;
          this.displayVoidAppointment = false;
          this._cdr.detectChanges();
          this.loading = false
        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in voiding your appointment.");
        }
      });

  }

  cancelschAppointmentId: number;
  voidSalesOrderId: number;
  voidschAppointmentId: number;
  isRescheduledRequest: boolean;


  showVoidAppointment(voidFrom, schAppointmentId, isRescheduledRequestFromNS) {
    this.isBrightreeSystemAppUpdateSetting = false;
    this.voidFrom = voidFrom;
    this.isRescheduledRequestFromNS = isRescheduledRequestFromNS;
    this.voidschAppointmentId = schAppointmentId;
    let action = 'Void review';
    let popupHeader = 'Update Void Review Status of Appointment(s)';

    if (voidFrom === 'P') {
      action = 'Void';
      popupHeader = 'Update Void Status of Appointment(s)';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    this.isRescheduledRequest = true;

    //this.displayVoidAppointment = true;
    this.displayVoidAppointment = false;
    //this.displayCancelAppointment = true;     
    this.callUpdateStatusForm(action, popupHeader, this.voidFrom);

  }
  showVoidSalesOrder(voidFrom, crmSalesOrderId) {
    this.isBrightreeSystemAppUpdateSetting = false;
    this.voidFrom = voidFrom;
    this.voidSalesOrderId = crmSalesOrderId;
    let action = 'Void review';
    let popupHeader = 'Update Void Review Status of Sales Order(s)';

    if (voidFrom === 'P') {
      action = 'Void';
      popupHeader = 'Update Void Status of Sales Order(s)';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    else if (voidFrom === 'A') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
    }
    this.isRescheduledRequest = false;

    //this.displayVoidAppointment = true;
    this.callUpdateStatusForm(action, popupHeader, this.voidFrom);

  }
  checkSelectedRecord() {
    this.isMultiSelected = 'False';
    this.header = 'Status of Appointment';
    if ((this.selectedsalesOrders != undefined && this.selectedsalesOrders.length > 1) ||
      (this.selectedAppointments != undefined && this.selectedAppointments.length > 1)) {
      this.header = 'Status of Appointment(s)';
      this.isMultiSelected = 'True';
    }
  }

  hideNoShowPopup() {
    this.reasonCommentsNoShow = '';
    this.displayNoShowAppointment = false;
    this.ConfirmSetStatusChange = false;//41466
  }

  hideCompletePopup() {
    this.reasonCommentsComplete = "";
    this.displayCompleteAppointment = false;
    this.displayNoShowAppointment = false;//41466
    this.ConfirmSetStatusChange = false;
  }


  showCancelAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;

    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: true,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      //this.getAppointmentOrderCountByStatus(true);
      this.getAppointmentsOnStatusCount(true);
      //this.getTimeZone();
      this.getappointments();
      //this.HangUpCall();

    });


  }

  showVoidAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;

    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: true,
        isCancelOrder: false,
        isCall: false,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      //this.getAppointmentOrderCountByStatus(true);
      this.getAppointmentsOnStatusCount(true);
      //this.getTimeZone();
      this.getappointments();
      //this.HangUpCall();
    });


  }

  showCallAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;

    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        voidFrom: 'A',
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {

      //this.getAppointmentOrderCountByStatus(true);
      this.getAppointmentsOnStatusCount(true);
      //this.getTimeZone();
      this.getappointments();
      //this.HangUpCall();

    });


  }

  async Unlock(data: SalesOrderSummaryResponseModel, isOther: boolean) {
    if (!isOther) {
      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'Are you sure you want to unlock appointment ?',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          return;
          //this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
        }
        this.progress.next(true);
        this.appointmentService.UnLockSalesOrder(data.schAppointmentCallListId)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
          .toPromise()
          .then((result) => {
            this.processResult<boolean>(result, () => {
              this.showSuccessMessage('Sales order unlocked successfully.');
              data.schIsLocked = false;
              this.isRowDisabled(data);
              this.IsSalesOrdersLockedByUser();
            });
          }).catch((httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
          });
      });
    }
    else {
      this.progress.next(true);
      await this.appointmentService.UnLockSalesOrder(data.schAppointmentCallListId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('Sales order unlocked successfully.');
            data.schIsLocked = false;
            this.isRowDisabled(data);
            this.IsSalesOrdersLockedByUser();
          });
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }


  showCallLogsDialog(CallLogs, PatientName) {

    const ref = this.dialougeService.open(SalesorderCallLogsComponent, {
      showHeader: true,
      closable: true,
      data: {
        callLogs: CallLogs,
        patientName: PatientName
      },
    });

  }

  selectNonScheduleSalesOrder(event, selSalesOrder: SalesOrderSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedSalesOrder = selSalesOrder;
    overlaypanel.toggle(event);
  }

  AddNonSColumnInTable(event, col: UserPreferencesModuleColumnResponseModel[]) {
    this.loading = true;
    let isChecked = event.checked;

    let arruserModuleColumn = new Array();
    let IsAnyColVisible = col.filter(x => x.preferenceVisible === true);
    if (IsAnyColVisible.length == 0) {
      this.isOnlyNonSFixedColumnVisible = true;
    }
    else {
      this.isOnlyNonSFixedColumnVisible = false;
    }
    this.userNonSPreferenceColumn.forEach(x => {
      x.visible = x.preferenceVisible;
    })
    col.forEach(x => {
      let userModuleColumn = new UserWiseModuleColumn();
      userModuleColumn.UserInfoId = this.loggedInUserId;
      userModuleColumn.ModuleId = x.moduleId;
      userModuleColumn.ColumnName = x.field;
      userModuleColumn.IsActive = x.preferenceVisible;
      userModuleColumn.Id = x.userModuleColumnId;
      userModuleColumn.UserPreferencesDisplayOrder = col.findIndex(y => y.id === x.id);
      arruserModuleColumn.push(userModuleColumn);
    });



    this.appointmentService.addUserPreferenceColumn(arruserModuleColumn)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage("Column display settings save successfully.")
            }
          }
        });

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }
  AddSColumnInTable(event, col: UserPreferencesModuleColumnResponseModel[]) {
    this.loading = true;
    let isChecked = event.checked;

    let arruserModuleColumn = new Array();
    let IsAnyColVisible = col.filter(x => x.preferenceVisible === true);
    if (IsAnyColVisible.length == 0) {
      this.isOnlySFixedColumnVisible = true;
    }
    else {
      this.isOnlySFixedColumnVisible = false;
    }
    this.userSPreferenceColumn.forEach(x => {
      x.visible = x.preferenceVisible;
    })
    col.forEach(x => {
      let userModuleColumn = new UserWiseModuleColumn();
      userModuleColumn.UserInfoId = this.loggedInUserId;
      userModuleColumn.ModuleId = x.moduleId;
      userModuleColumn.ColumnName = x.field;
      userModuleColumn.IsActive = x.preferenceVisible;
      userModuleColumn.Id = x.userModuleColumnId;
      userModuleColumn.UserPreferencesDisplayOrder = col.findIndex(y => y.id === x.id);
      arruserModuleColumn.push(userModuleColumn);
    });



    this.appointmentService.addUserPreferenceColumn(arruserModuleColumn)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage("Column display settings save successfully.")
            }
          }
        });

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onSalesOrderCountClick(salesOrderCount: SalesOrdersCountModel, index: number) {
    // this.selectedfilterIndex = this.selectedfilterIndex === index ? -1 : index;
    this.selectedfilterIndex = this.selectedfilterIndex === index ? 0 : index;
    //this.selectedfilterIndex = index;
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    this.searchRequestModel.dictionaryFilter = {}
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;



    if (this.selectedfilterIndex != -1) {
      this.salesSelectedOrderCountModels = salesOrderCount;
      if (salesOrderCount.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
        this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      }
      else if (salesOrderCount.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
        this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      }
      else if (salesOrderCount.salesOrderStatus.toUpperCase() == "NOSHOW") {
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = salesOrderCount.salesOrderViewStatus;
      }
      else if (salesOrderCount.salesOrderStatus.toUpperCase() == "CANCEL") {
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = salesOrderCount.salesOrderViewStatus;
      }

      // else
      //this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = salesOrderCount.salesOrderViewStatus;
    }
    else
      this.salesSelectedOrderCountModels = null;

    let startDate = this.NSSelectedDateRange.startDate;
    let endDate = this.NSSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    if (startDate && endDate) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchRequestModel.searchFromDate = startDate;
      this.searchRequestModel.searchToDate = endDate;
    }

    this.resetPageIndex();
    this.getsalesorders();
    // if(salesOrderCount.salesOrderStatus.toLowerCase() =="noshow"){

    //   let selSatatus = this.appointmentsalesOrderStatus.filter(x=>x.wipStatesName == salesOrderCount.salesOrderViewStatus && salesOrderCount.isVisible);
    //   if (selSatatus)
    //     this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus[0].wipStatesId.toString();
    //     this.resetTab();
    //     this.getappointments();
    // }
    // else{
    //   if(this.selectedfilterIndex == 0 ){

    //     this.searchRequestModel.dictionaryFilter['AppointmentStatus'] = "181";

    //   }
    //   this.resetTab();

    // }
  }

  onSearchButtonClick() {
    if (!this.searchRequestModel.isFromDashboard) {
      this.searchRequestModel.dictionaryFilter = {}
    }
    //this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    //this.searchRequestModel.dictionaryFilter = {}
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else
      this.searchRequestModel.dictionaryFilter['SearchText'] = '';
    // else
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = null;
    // let selSatatus = this.salesSelectedOrderCountModels;
    // if (selSatatus) {
    //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
    //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
    //   }
    //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
    //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
    //   }
    //   else
    //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderViewStatus;
    // }
    // let startDate = this.NSSelectedDateRange.startDate;
    // let endDate = this.NSSelectedDateRange.endDate;

    // if (startDate && endDate) {
    //   startDate = moment(startDate).toDate();
    //   endDate = moment(endDate).toDate();
    //   if (startDate > endDate) {
    //     this.showWarningMessage("From Date should not be greater than To Date.");
    //     return;
    //   }
    // }
    // if (startDate && endDate) {
    //   this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
    //   this.searchRequestModel.searchFromDate = startDate;
    //   this.searchRequestModel.searchToDate = endDate;
    // }

    this.resetPageIndex();
    this.getsalesorders();

  }

  getTotalRows() {

    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize)
      return this.searchRequestModel.PageSize;

    return this.totalRecords;
  }

  onStatusChange(event) {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    if (event.value != null)
      this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = event.value.wipStatesName;
    this.resetPageIndex();
    this.getsalesorders();
  }

  onLazyLoad(event: LazyLoadEvent) {

    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {

      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = Number(this.selectedpage.code);//event.rows;

      //this.searchRequestModel.FirstRecordIndex = event.first;
      //this.searchRequestModel.CurrentPageIndex = event.first / 20;
      //this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getsalesorders();
  }

  LockAppoinments() {

    if (!this.selectedsalesOrders || this.selectedsalesOrders.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }

    let appointmentUpdateStatus: AppointmentCallListResponseModel[] = new Array();
    this.selectedsalesOrders.forEach(value => {

      let appointmentUpdateStatusRequestModel = new AppointmentCallListResponseModel();
      appointmentUpdateStatusRequestModel.SchAppointmentId = 0;
      appointmentUpdateStatusRequestModel.CrmSalesorderId = value.crmSalesOrderId;
      appointmentUpdateStatusRequestModel.AppUserInfoId = this.loggedInUserId;
      appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
    })

    this.loading = true;
    this.appointmentService.LockAppointments(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Sales order has been locked.');
          //this.router.navigate(['/admin/appointments/view-locked-appointments/'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterIndex } });
          //this.getsalesorders();
          this.IsSalesOrdersLockedByUser();
          this.isDisabledLock = true;
          this.selectedsalesOrders = [];
          this.getsalesordersRefresh();
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  SendInvitationforAppointment() {
    if (!this.selectedsalesOrders || this.selectedsalesOrders.length <= 0) {
      this.showWarningMessage('Please select sales order(s) to send an invitation.');
      this._cdr.detectChanges();
      return;
    }

    this.ConfirmInvitePatients = true;
    this.salesOrderIdToSendInvite = new Array()
    this.totalSelectedSendInviteSalesOrderIds = this.selectedsalesOrders.length;
    this.restrictedSalesOrderIdToSendInvite = [];
    this.selectedsalesOrders.forEach(value => {
      if (value.crmBranchIsAllowSelfScheduleAppointments) {
        this.salesOrderIdToSendInvite.push(value.crmSalesOrderId.toString());
      }
      else {
        this.restrictedSalesOrderIdToSendInvite.push(value);
      }
    });
  }

  ConfirmSendInvitePatients() {
    this.loading = true;
    this.appointmentService.SendInvitationForNotScheduled(this.salesOrderIdToSendInvite)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Invitation for appointment booking has been sent.');
          this.selectedsalesOrders = [];
          this.selectedAppointments = [];
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.ConfirmInvitePatients = false;
  }

  ConfirmInvitePatientsCancel() {
    this.ConfirmInvitePatients = false;
  }

  SendInvitationforScheduledAppointment() {
    if (!this.selectedAppointments || this.selectedAppointments.length <= 0) {
      this.showWarningMessage('Please select sales order(s) to send an invitation.');
      this._cdr.detectChanges();
      return;
    }

    this.ConfirmInvitePatients = true;
    this.salesOrderIdToSendInvite = new Array()
    this.totalSelectedSendInviteSalesOrderIds = this.selectedAppointments.length;
    this.restrictedSalesOrderIdToSendInvite = [];
    this.selectedAppointments.forEach(value => {
      if (value.crmBranchIsAllowSelfScheduleAppointments) {
        this.salesOrderIdToSendInvite.push(value.crmSalesorderId.toString());
      }
      else {
        this.restrictedSalesOrderIdToSendInvite.push(value);
      }
    });
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private resetAppoinmentPageIndex() {
    this.searchAppointmentRequestModel.CurrentPageIndex = 0;
    this.searchAppointmentRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory && this.index === 0)
      return;

    if (this.searchRequestModel) {
      if (!this.searchRequestModel.isFromDashboard) {
        this.searchRequestModel = new SearchRequestModel();
      }
    } else {
      this.searchRequestModel = new SearchRequestModel();
    }
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;;
    if (this.isGlobalSearch) {
      this.searchRequestModel.dictionaryFilter = {}
      if (this.searchText)
        this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }

    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
  }

  private async getSalesOrderStatus() {
    this.progress.next(true);
    await this.appointmentService.GetAllWIPStatesBySalesOrderType(this.salesOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrderStatus = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getSalesOrderCountByStatus(isRefersh: boolean) {
    this.progress.next(true);
    // await this.appointmentService.GetSalesOrderCountByStatus(this.currentUserBranch)
    await this.appointmentService.GetSalesOrderCountByStatusUserBranch(this.currentSelectedNSUserBranch, this.loggedInUserId, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrdersCountModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesOrderCountModels = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  private getsalesordersRefresh() {
    if (this.loading)
      this.loading = false;

    this.loading = true;
    this._cdr.detectChanges();
    this.dataExpressionloading = true;
    this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = this.selectedStatusStr;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.dataExpressionloading = true;
    this.appointmentService.GetAllSalesOrdersRefresh(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                if (pagedResponse.totalRecords > 0) {
                  if (this.isGlobalSearch && this.searchText) {
                    this.SalesOrderFound = true;
                    if (this.isGlobalSearch) {
                      if (this.SalesOrderFound && this.AppointmentFound) {
                        this.index = 0;
                      }
                      else if (this.SalesOrderFound) {
                        this.index = 0;
                      }
                      else if (this.AppointmentFound) {
                        this.index = 1;
                      }
                    }
                  }
                  else {
                    this.SalesOrderFound = false;
                  }
                }
                else {
                  this.SalesOrderFound = false;
                }
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;
                this.searchRequestModel.totalRecords = this.totalRecords;
                this.hasPageHistory = false;
                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;

              }
            }
          }
          this.dataExpressionloading = false;
          this.loading = false;
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }


  private getsalesorders() {
    if (this.loading)
      this.loading = false;

    this.loading = true;
    this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = this.selectedStatusStr;
    this._cdr.detectChanges();
    this.dataExpressionloading = true;
    this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = this.selectedStatusStr;
    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.appointmentService.GetAllSalesOrders(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                if (pagedResponse.totalRecords > 0) {
                  if (this.isGlobalSearch && this.searchText) {
                    this.SalesOrderFound = true;
                    if (this.isGlobalSearch) {
                      if (this.SalesOrderFound && this.AppointmentFound) {
                        this.index = 0;
                      }
                      else if (this.SalesOrderFound) {
                        this.index = 0;
                      }
                      else if (this.AppointmentFound) {
                        this.index = 1;
                      }
                    }
                  }
                  else {
                    this.SalesOrderFound = false;
                  }
                }
                else {
                  this.SalesOrderFound = false;
                }
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;
                this.searchRequestModel.totalRecords = this.totalRecords;
                this.hasPageHistory = false;
                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
              }
            }
          }
          this.dataExpressionloading = false;
          this.loading = false;
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });

  }

  ViewLockedAppoinments() {
    //this.router.navigate(['/admin/appointments/view-locked-appointments/'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index } });
    this.router.navigate(['/admin/appointments/view-locked-appointments/'], { state: { selectedTab: this.index } });
  }

  AddColumnInTable(event, fieldNo) {

    this.colsSch.forEach(value => {

      if (value.fieldNo == fieldNo) {
        let isChecked = event.checked;
        if (isChecked)
          value.visible = true;
        else
          value.visible = false;
      }
    });

    this.selectedColumns = this.colsSch.filter(x => x.visible === true);
    //this.clickedMarker(infowindow);
    // this._cdr.detectChanges();
  }

  onAppointmentView(salesOrderId: string) {
    this.router.navigate(['/admin/appointments/detail/', salesOrderId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterSIndex } });
  }

  selectScheduleSalesOrder(event, selectedAppointment: AppointmentSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedAppointment = selectedAppointment;
    if (this.selectedAppointment.apptType.toLowerCase() == 'Telehealth'.toLowerCase()) {
      if (this.remoteAppointmentTypeResponseModel != null) {
        if (this.remoteAppointmentTypeResponseModel.length > 0) {
          if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'Telemed'.toLowerCase()) {
            let OnlineMeeting = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'OnlineMeeting')
            if (OnlineMeeting) {

              this.convertedRemoteAppointmentType = OnlineMeeting.crmRemoteAppointmentTitle;

            }

          }
          else if (this.selectedAppointment.schRemoteAppointmentType.toLowerCase() == 'OnlineMeeting'.toLowerCase()) {

            let telemed = this.remoteAppointmentTypeResponseModel.find(x => x.crmRemoteAppointmentType == 'Telemed')
            if (telemed) {
              this.convertedRemoteAppointmentType = telemed.crmRemoteAppointmentTitle;

            }
          }
        }
      }
    }

    overlaypanel.toggle(event);
  }

  convertAppointmentType(selectedAppointment: AppointmentSummaryResponseModel) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to change appointment type from ' + selectedAppointment.schRemoteAppointmentTypeDisplay + ' to ' + this.convertedRemoteAppointmentType + '?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;

      }
      this.loading = true;
      this.appointmentService.ConvertRemoteAppointment(selectedAppointment.schAppointmentId, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<boolean>) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('Appointment type has been changed successfully.');
            this.getappointmentsRefresh();
          });

        }, (httpResponseError) => {

          this.loading = false
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }
  onScheduledSearchButtonClick() {
    if (!this.searchAppointmentRequestModel.isFromDashboard) {
      this.searchAppointmentRequestModel.dictionaryFilter = {}
    }
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
    else
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = '';
    // this.searchAppointmentRequestModel.crmBranchId = this.currentSelectedSUserBranch;
    // this.searchAppointmentRequestModel.dictionaryFilter = {}
    // this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

    // let selSatatus = this.selectedAppointmentStatus;
    // if (selSatatus) {
    //   this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.wipStatesId.toString();
    // }


    // let startDate = this.SSelectedDateRange.startDate;
    // let endDate = this.SSelectedDateRange.endDate;

    // if (startDate && endDate) {
    //   startDate = moment(startDate).toDate();
    //   endDate = moment(endDate).toDate();
    //   if (startDate > endDate) {
    //     this.showWarningMessage("From Date should not be greater than To Date.");
    //     return;
    //   }
    // }

    // if (startDate && endDate) {
    //   this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

    //   this.searchAppointmentRequestModel.searchFromDate = startDate;
    //   this.searchAppointmentRequestModel.searchToDate = endDate;
    // }

    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();

  }

  private initializeSearchAppointmentRequestModel() {

    if (this.hasPageHistory && this.index === 1)
      return;

    if (this.searchAppointmentRequestModel) {
      if (!this.searchAppointmentRequestModel.isFromDashboard) {
        this.searchAppointmentRequestModel = new SearchRequestModel();
      }
    }
    else {
      this.searchAppointmentRequestModel = new SearchRequestModel();
    }

    this.searchAppointmentRequestModel.crmBranchId = this.currentSelectedSUserBranch;
    this.searchAppointmentRequestModel.appUserInfoId = this.loggedInUserId;

    if (this.isGlobalSearch) {
      this.searchAppointmentRequestModel.dictionaryFilter = {}
      if (this.searchText)
        this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchText;

    }
    this.searchAppointmentRequestModel.FirstRecordIndex = 0;
    this.searchAppointmentRequestModel.CurrentPageIndex = 0;
    this.searchAppointmentRequestModel.PageSize = +this.pageSizes[0];
    this.searchAppointmentRequestModel.SortOrder = 'ASC';
    this.searchAppointmentRequestModel.SortByColumnName = "CrmPatientName";

    if (this.isGlobalSearch == false) {
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = this.iBookedId.toString();

    }
    let objselected = this.appointmentsalesOrderStatus.filter(x => x.wipStatesId === this.iBookedId);
    if (objselected.length > 0) {
      this.selectedAppointmentStatus = objselected[0];
    } else {
      this.selectedAppointmentStatus = null;
    }
    if (this.isGlobalSearch == false)
      this.selectedfilterSIndex = 1;
    else
      this.selectedfilterSIndex = 0;
  }

  private async getStatus() {
    this.progress.next(true);
    await this.dashboardService.getStatus()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        if (results.responseModel != undefined && results.responseModel != null) {
          var iNoShow = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow');
          if (iNoShow != null && iNoShow != undefined) {
            this.iNoShowId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow').crmWipstatusId;
          }
          //this.iBookedId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked').crmWipstatusId;
          var iCancel = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel');
          if (iCancel != null && iCancel != undefined) {
            this.iCancelId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel').crmWipstatusId;
          }
          var iComplete = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete');
          if (iComplete != null && iComplete != undefined) {
            this.iCompleteId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete').crmWipstatusId;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  getAppointmentTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'


    if (this.searchAppointmentRequestModel.PageSize)
      return this.searchAppointmentRequestModel.PageSize;

    return this.totalAppointmentRecords;
  }

  onAppointmentCountClick(salesOrderCount: SalesOrdersCountModel, index: number) {


    this.selectedfilterSIndex = this.selectedfilterSIndex === index ? -1 : index;
    //this.selectedfilterSIndex = index;
    this.searchAppointmentRequestModel.crmBranchId = this.currentSelectedSUserBranch;
    if (this.searchAppointmentRequestModel.isFromDashboard) {
      this.searchAppointmentRequestModel.dictionaryFilter = {}
    }
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
    if (this.selectedfilterSIndex != -1) {
      let objselected = this.appointmentsalesOrderStatus.filter(x => x.wipStatesName === salesOrderCount.salesOrderViewStatus);
      if (objselected.length > 0) {
        this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = objselected[0].wipStatesId.toString();
        this.selectedAppointmentStatus = objselected[0];
      } else {
        this.selectedAppointmentStatus = null;
      }
    } else {
      this.selectedAppointmentStatus = null;
    }

    let startDate = this.SSelectedDateRange.startDate;
    let endDate = this.SSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }

    if (startDate && endDate) {
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchAppointmentRequestModel.searchFromDate = startDate;
      this.searchAppointmentRequestModel.searchToDate = endDate;
    }


    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();

  }

  onAppointmentStatusChange(event) {
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

    if (event.value != null)
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = event.value.wipStatesId;
    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();
  }

  onAppointmentLazyLoad(event: LazyLoadEvent) {

    this.loading = false;
    if (!this.hasPageHistory) {
      this.searchAppointmentRequestModel.FirstRecordIndex = event.first;
      this.searchAppointmentRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = Number(this.selectedpageNS.code);
      this.searchAppointmentRequestModel.SortByColumnName = event.sortField;
      this.searchAppointmentRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchAppointmentRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    //this.getTimeZone();

    this.getappointments();
  }

  onMenuSelection(selectedMenuitem) {

    if (!this.selectedAppointments || this.selectedAppointments.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }
    this.selectedappointmentsalesOrderStatusMenuitems = selectedMenuitem;
    let status: string = selectedMenuitem.label;
    let statusUpdated: string = selectedMenuitem.label;
    let popupHeader: string = 'Update No Show Status of Appointment(s)';

    if (status.toLowerCase() == 'complete') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      //this.displayCompleteAppointment = true;
      this.reasonCommentsComplete = "";
      //status = 'complete';
      //statusUpdated = 'completed';
      this.statusType = 'Complete';//41466
      popupHeader = 'Update Complete Status of Appointment(s)';



    }
    if (status.toLowerCase() == 'no show') {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      this.statusType = 'No Show';
    }

    //41466:
    this.restrictedApptForStatus = [];

    // this.ConfirmSetStatusChange = true;


    this.totalSelectedNoShowAppointments = this.selectedAppointments.length;
    var bOldWorkingCode = false;

    if (bOldWorkingCode != false) {
      this.selectedAppointments.forEach(value => {

        if (this.isAllowToSetNoShowStatus(value.schAppointmentDateTime))
          this.restrictedApptForStatus.push(value);
      });
    }
    else {
      // 8905 remove check for future date
      //this.checkIfStatusCanChange();
      var noshowCount = this.restrictedApptForStatus.length;
      this.reasonCommentsNoShow = '';
      //this.ConfirmSetStatusChange = true;
      if (status.toLowerCase() == 'no show') {
        status = 'no show';
        statusUpdated = 'no show';
      }
      else {
        status = 'complete';
        statusUpdated = 'completed';
      }
    }
    this.checkSelectedRecord();

    if (this.totalSelectedNoShowAppointments == this.restrictedApptForStatus.length) {
      let message: string = `The ${this.statusType} status cannot be assigned to the selected appointments as the appointment time has not yet arrived.`;

      this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
        data: {
          action: 'Alert',
          popupHeader: 'Alert',
          IsAllowToComplete: false,
          message: message
        },
      }).onClose.subscribe((response: any) => {
        if (response.action == 'Cancel') {
          return;
        }
      });
      return;
    }

    this.dialougeService.open(UpdateStatusOfAppointmentsComponent, {
      data: {
        action: this.statusType,
        popupHeader: popupHeader,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        restrictedApptForStatus: this.restrictedApptForStatus,
        totalSelectedItem: this.totalSelectedNoShowAppointments,
        isMultiSelected: this.isMultiSelected,
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {
      if (response.action == 'Cancel') {

        if (this.statusType == 'Complete')
          this.hideCompletePopup();
        else
          this.hideNoShowPopup();

        // or call for both
        // this.ConfirmSetStatusCancel();
      }
      if (response.action == 'Confirm') {

        this.updateStatusforminfo = response;
        if (this.statusType == 'Complete')
          this.CompleteAppointment();
        else
          this.NoShowAppointment();

        //or call for both 
        // this.ConfirmSetStatusAppoinments();
      }

    });
    /** End Code */
  }

  //41466: After marking No Show it allow user to change the availability of that therapist 
  isAllowToSetNoShowStatus(schdApptDateTime): boolean {

    // TODO: Convert schAppointmentDateTime to local appdateFormat timezone from Patient timezone
    // compare with local current time 

    // get appointment time 
    var appDateTime = moment(schdApptDateTime, 'YYYY-MM-DD HH:mm').toDate(); //displays per Appt time zone  

    // get curretn date time from local timezone
    var date = moment(new Date()).format('DD-MMM-yyyy hh:mm:ss');
    var currentDateTime = moment(date).toDate();

    if (moment(this.appointment.schAppointmentDateStartTime).isAfter(moment(Date()).toDate()))
      return true;

    return false;
  }

  //41466: After marking No Show it allow user to change the availability of that therapist 
  checkIfStatusCanChange() {

    this.tempNoShowAppts = new Array();
    this.restrictedApptForStatus = [];


    this.selectedAppointments.forEach(value => {
      this.tempNoShowAppts.push(value);
    });

    this.tempNoShowAppts.forEach(value => {

      console.log("Test");
      //41466: After marking No Show it allow user to change the availability of that therapist
      this.selectedTimeZones = this.timeZone.find(x => x.orgcode == value.crmPatientTimeZone);
      var orgappDateTime = moment.tz(value.schAppointmentDateTime, this.selectedTimeZones.countryName);

      //convert appointment time to local timezone
      this.selectedTimeZones = this.timeZone.find(x => x.code == this.appDateFormat.timeZone);
      var appDate = orgappDateTime.tz(this.selectedTimeZones.countryName).format('DD-MMM-yyyy hh:mm:ss A');
      var appDateTime = moment(appDate).toDate();

      // get curretn date time from local timezone
      var date = moment(new Date()).tz(this.selectedTimeZones.countryName).format('DD-MMM-yyyy hh:mm:ss A');
      var currentDateTime = moment(date).toDate();

      if ((appDateTime != undefined) && (currentDateTime != undefined) && (appDateTime > currentDateTime))
        this.restrictedApptForStatus.push(value);
    });
  }

  //41466:
  ConfirmSetStatusCancel() {
    this.ConfirmSetStatusChange = false;
  }

  //41466:
  ConfirmSetStatusAppoinments() {
    this.reasonCommentsNoShow = '';
    if (this.statusType.toLocaleLowerCase() == 'complete')
      this.displayCompleteAppointment = true;
    else
      this.displayNoShowAppointment = true;
    this.ConfirmSetStatusChange = false;
  }

  NoShowAppointment() {

    if (!this.selectedAppointments || this.selectedAppointments.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }
    let status: string = this.selectedappointmentsalesOrderStatusMenuitems.label;
    let statusUpdated: string = this.selectedappointmentsalesOrderStatusMenuitems.label;
    if (status.toLowerCase() == 'voided') {
      status = 'void';
      statusUpdated = 'voided';
    }
    if (status.toLowerCase() == 'booked') {
      status = 'book';
      statusUpdated = 'booked';
    }
    if (status.toLowerCase() == 'complete') {

      status = 'complete';
      statusUpdated = 'completed';
    }
    if (status.toLowerCase() == 'no show') {
      status = 'no show';
      statusUpdated = 'no show';
    }


    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (status.toLowerCase() == 'no show') {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow')
        if (selectedWipStaus) {
          appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }

    }
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    this.selectedAppointments.forEach(value => {
      //41466: After marking No Show it allow user to change the availability of that therapist
      let bskippAppt = null;
      if (this.restrictedApptForStatus.length > 0)
        bskippAppt = this.restrictedApptForStatus.find(x => x.crmSalesOrderAPPId == value.crmSalesOrderAPPId);

      if (bskippAppt == null) {
        let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
        appointmentUpdateStatusRequestModel.SchAppointmentId = value.schAppointmentId;
        appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(this.selectedappointmentsalesOrderStatusMenuitems.id);
        appointmentUpdateStatusRequestModel.CrmFacilityId = value.crmFacilityId;
        appointmentUpdateStatusRequestModel.SchComments = this.reasonCommentsNoShow;
        appointmentUpdateStatusRequestModel.CrmTherapistId = value.crmTherapistId;
        appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
        appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = value.schIsUnreconciledAppointment;
        appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
        appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
      }
    });

    this.loading = true;
    this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Appointment has been ' + statusUpdated + ' successfully.');
          //this.showSuccessMessage('Status has been updated successfully.');
          this.displayNoShowAppointment = false;
          this.ConfirmSetStatusChange = false;
          this.selectedAppointments = [];
          this.getappointmentsRefresh();
          //this.getAppointmentOrderCountByStatus(true);
          this.getAppointmentsOnStatusCount(true);
          if (this.selectedAppointments.length > 0)
            this.isDisabled = false;
          else
            this.isDisabled = true;
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }
  CompleteAppointment() {

    if (!this.selectedAppointments || this.selectedAppointments.length <= 0) {
      this.showWarningMessage('Please select sales order(s)');
      return;
    }
    let status: string = this.selectedappointmentsalesOrderStatusMenuitems.label;
    let statusUpdated: string = this.selectedappointmentsalesOrderStatusMenuitems.label;
    if (status.toLowerCase() == 'voided') {
      status = 'void';
      statusUpdated = 'voided';
    }
    if (status.toLowerCase() == 'booked') {
      status = 'book';
      statusUpdated = 'booked';
    }
    if (status.toLowerCase() == 'complete') {

      status = 'complete';
      statusUpdated = 'completed';
    }
    if (status.toLowerCase() == 'no show') {
      status = 'no show';
      statusUpdated = 'no show';
    }


    let appointmentUpdateStatus: AppointmentUpdateStatusRequestModel[] = new Array();
    let appointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    appointmentRequestBT.IsUpdateToBrightree = false;
    appointmentRequestBT.IsDeliveryNoteOverride = true;
    appointmentRequestBT.IsOrderNoteOverride = true;
    appointmentRequestBT.OrderNote = null;
    appointmentRequestBT.DeliveryNote = null;
    appointmentRequestBT.PractitionerName = null;
    appointmentRequestBT.PractitionerId = 0;
    appointmentRequestBT.ScheduledDeliveryStartDateString = null;
    appointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    appointmentRequestBT.ScheduledDeliveryStartDate = null;
    appointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    appointmentRequestBT.WIPDateNeeded = moment().toDate();

    if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'complete')
      if (selectedWipStaus) {
        appointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        appointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        appointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        appointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    appointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    this.selectedAppointments.forEach(value => {
      //41466: After marking No Show it allow user to change the availability of that therapist
      let bskippAppt = null;
      if (this.restrictedApptForStatus.length > 0)
        bskippAppt = this.restrictedApptForStatus.find(x => x.crmSalesOrderAPPId == value.crmSalesOrderAPPId);

      if (bskippAppt == null) {
        let appointmentUpdateStatusRequestModel = new AppointmentUpdateStatusRequestModel();
        appointmentUpdateStatusRequestModel.SchAppointmentId = value.schAppointmentId;
        appointmentUpdateStatusRequestModel.SchAppointmentStatus = Number(this.selectedappointmentsalesOrderStatusMenuitems.id);
        appointmentUpdateStatusRequestModel.CrmFacilityId = value.crmFacilityId;
        appointmentUpdateStatusRequestModel.SchComments = this.reasonCommentsComplete;
        appointmentUpdateStatusRequestModel.CrmTherapistId = value.crmTherapistId;
        appointmentUpdateStatusRequestModel.modifiedByUserInfoId = this.loggedInUserId;
        appointmentUpdateStatusRequestModel.schIsUnreconciledAppointment = value.schIsUnreconciledAppointment;
        appointmentUpdateStatusRequestModel.salesOrderUpdateRequestModelBT = appointmentRequestBT;
        appointmentUpdateStatus.push(appointmentUpdateStatusRequestModel);
      }
    });

    this.loading = true;
    this.appointmentService.UpdateAppointmentStatus(appointmentUpdateStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {


          this.showSuccessMessage('Appointment has been ' + statusUpdated + ' successfully.');
          this.displayCompleteAppointment = false;
          this.ConfirmSetStatusChange = false;
          //this.showSuccessMessage('Status has been updated successfully.');
          this.selectedAppointments = [];
          this.getappointmentsRefresh();
          //this.getAppointmentOrderCountByStatus(true);
          this.getAppointmentsOnStatusCount(true);
          if (this.selectedAppointments.length > 0)
            this.isDisabled = false;
          else
            this.isDisabled = true;
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }



  private async IsSalesOrdersLockedByUser() {
    this.progress.next(true);
    this.appointmentService.IsSalesOrdersLockedByUser(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.isLockedAppointments = result.responseModel;
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });



  }

  private async getAppointmentOrderCountByStatus(isRefresh: boolean) {
    this.progress.next(true);
    //await this.appointmentService.GetAppointmentsCountByStatus(this.currentUserBranch)
    await this.appointmentService.GetAppointmentsCountByStatusUserBranch(this.currentSelectedSUserBranch, this.loggedInUserId, isRefresh)

      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrdersCountModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentCountModels = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAppointmentOrderCountByStatusRefresh() {
    this.progress.next(true);
    //await this.appointmentService.GetAppointmentsCountByStatus(this.currentUserBranch)
    await this.appointmentService.GetAppointmentsCountByStatusUserBranchRefresh(this.currentSelectedSUserBranch, this.loggedInUserId)

      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrdersCountModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentCountModels = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAppointmentSalesOrderStatus() {
    this.progress.next(true);
    await this.appointmentService.GetAllWIPStatesBySalesOrderType(this.AppointmentOrderType)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<WIPStatesResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentsalesOrderStatus = result.responseModel;
              this.appointmentsalesOrderStatus.forEach(value => {

                if (value.wipStatesName.toLowerCase() != 'booked') {

                  if (this.isAppointmentCompletedPermission && value.wipStatesName.toLowerCase() == 'complete') {
                    this.appointmentsalesOrderStatusMenuitems.push({
                      id: value.wipStatesId.toString(),
                      label: value.wipStatesName, command: (event) => {
                        this.onMenuSelection(event.item);
                      }
                    });
                  }

                  if (this.isAppointmentNoshowPermission && value.wipStatesName.toLowerCase() == 'no show') {
                    this.appointmentsalesOrderStatusMenuitems.push({
                      id: value.wipStatesId.toString(),
                      label: value.wipStatesName, command: (event) => {
                        this.onMenuSelection(event.item);
                      }
                    });
                  }

                }
              });
              this.appointmentsalesOrderStatusMenuitems;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getappointments() {
    this.loading = true;
    this.dataExpressionloading = true;

    if (this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] != null && this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] != undefined) {
      if (this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] == "-999") {
        this.isUnreconciled = true;
      }
    }
    this.appointmentService.GetAllAppointments(this.searchAppointmentRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<AppointmentSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                if (pagedResponse.totalRecords > 0) {
                  if (this.isGlobalSearch && this.searchText) {
                    this.AppointmentFound = true;
                    if (this.isGlobalSearch) {
                      if (this.SalesOrderFound && this.AppointmentFound) {
                        this.index = 0;
                      }
                      else if (this.SalesOrderFound) {
                        this.index = 0;
                      }
                      else if (this.AppointmentFound) {
                        this.index = 1;
                      }
                    }
                  }
                  else {
                    this.AppointmentFound = false;
                  }
                }
                else {
                  this.AppointmentFound = false;
                }
                this.loading = false;
                this.dataExpressionloading = false;
                // this.appointments = pagedResponse.results;
                this.totalAppointmentRecords = pagedResponse.totalRecords;
                this.totalAppointmentPages = pagedResponse.totalPages;
                this.searchAppointmentRequestModel.totalRecords = this.totalAppointmentRecords;
                this.hasPageHistory = false;
                this.appointments = pagedResponse.results;
                this.scrollIssue();
                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchAppointmentRequestModel.PageSize) {
                  this.searchAppointmentRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.hideSchItem = this.CheckSchItemToDisable();
              }
            }
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
    //this.getPluginConfiguration();
  }

  private getappointmentsRefresh() {

    this.loading = true;
    this.dataExpressionloading = true;
    this.appointmentService.GetAllAppointmentsRefresh(this.searchAppointmentRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<AppointmentSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<AppointmentSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;

              if (pagedResponse) {
                if (pagedResponse.totalRecords > 0) {
                  if (this.isGlobalSearch && this.searchText) {
                    this.AppointmentFound = true;
                    if (this.isGlobalSearch) {
                      if (this.SalesOrderFound && this.AppointmentFound) {
                        this.index = 0;
                      }
                      else if (this.SalesOrderFound) {
                        this.index = 0;
                      }
                      else if (this.AppointmentFound) {
                        this.index = 1;
                      }
                    }
                  }
                  else {
                    this.AppointmentFound = false;
                  }
                }
                else {
                  this.AppointmentFound = false;
                }
                this.loading = false;
                this.dataExpressionloading = false;
                this.appointments = pagedResponse.results;
                this.totalAppointmentRecords = pagedResponse.totalRecords;
                this.totalAppointmentPages = pagedResponse.totalPages;
                this.searchAppointmentRequestModel.totalRecords = this.totalAppointmentRecords;
                this.hasPageHistory = false;
                this.scrollIssue();
                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchAppointmentRequestModel.PageSize) {
                  this.searchAppointmentRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.hideSchItem = this.CheckSchItemToDisable();
              }
            }
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }



  ViewSalesOrder() {
    if (this.isAppointment)
      this.router.navigate(['/admin/appointments/detail/', this.schAppointmentId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterSIndex } });
    else
      this.router.navigate(['/admin/appointments/salesorders/detail/', this.salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });

  }


  filterShowHideEvent() {
    this.filterShowHide = !this.filterShowHide;
    if (this.filterShowHide) {
      this.height = "450px";
    }
    else {
      this.height = "400px";
    }
    //if (this.filterShowHide == false)
    //  this.clearSalesOrderFilters();
  }

  filterShowHideEventSchedule() {
    this.filterShowHideSchedule = !this.filterShowHideSchedule;
    if (this.filterShowHideSchedule) {
      this.height1 = "450px";
    }
    else {
      this.height1 = "400px";
    }
    //if (this.filterShowHideSchedule == false)
    //  this.clearAppointmentFiltersFilters();
  }

  public clearSalesOrderFilters() {
    this.searchBox.nativeElement.value = '';
    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    this.NSSelectedDateRange = null;
    this.selectedDoctorResponseModel = new DoctorResponseModel();
    this.selectedfacilityResponseModel = new FacilityResponseModelDropdownList();

    if (this.branchResponseModel != null) {
      let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentSelectedNSUserBranch);
      if (selectedBranch) {
        this.selectedNSbranch = selectedBranch;
      }
      else {
        this.selectedNSbranch = this.branchResponseModel[0];

      }
    }



    this.searchRequestModel.dictionaryFilter = {}
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    let selSatatus = this.salesSelectedOrderCountModels;
    if (selSatatus) {
      if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
        this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      }
      else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
        this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      }
      else
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderViewStatus;
    }

    this.resetPageIndex();
    //this.getSalesOrderCountByStatus(true);
    this.GetSalesOrderCountOnAppointmentStatus(true);
    this.getsalesorders();


  };

  public clearAppointmentFilters() {
    this.searchBox.nativeElement.value = '';
    //this.SSelectedDateRange = null;
    this.currentSelectedSUserBranch = this.currentUserBranch;
    this.searchAppointmentRequestModel.crmBranchId = this.currentUserBranch;
    if (this.branchResponseModel != null && this.branchResponseModel != undefined) {
      let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentSelectedSUserBranch);
      if (selectedBranch) {

        this.selectedSbranch = selectedBranch;
      }
      else {
        this.selectedSbranch = this.branchResponseModel[0];
      }
    }


    this.searchAppointmentRequestModel.dictionaryFilter = {}
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

    let selSatatus = this.selectedAppointmentStatus;
    if (selSatatus)
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.wipStatesId.toString();

    this.resetAppoinmentPageIndex();
    //this.getAppointmentOrderCountByStatus(true);
    this.getAppointmentsOnStatusCount(true);
    //this.getTimeZone();
    this.getappointments();

  };

  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {
      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }

  ExportS() {
    if (this.loading)
      this.loading = false;
    let schSalesOrderIds: any[];
    if (this.selectedAppointments != undefined && this.selectedAppointments.length > 0) {

      schSalesOrderIds = new Array()
      this.selectedAppointments.forEach(value => {
        schSalesOrderIds.push(value.crmSalesOrderAPPId.toString());
      })
    }
    if (schSalesOrderIds != undefined && schSalesOrderIds.length > 0) {
      if (schSalesOrderIds.length == 1) {
        this.searchAppointmentRequestModel.dictionaryFilter['SelectedSchSalesOrderIds'] = schSalesOrderIds.toString();
      }
      else {
        this.searchAppointmentRequestModel.dictionaryFilter['SelectedSchSalesOrderIds'] = schSalesOrderIds.toString();
      }
    }

    if (!this.searchAppointmentRequestModel.isUnreconciled && !this.searchAppointmentRequestModel.isUpcoming) {
      this.searchAppointmentRequestModel.isUnreconciled = true;
    }
    this.loading = true;
    this._cdr.detectChanges();
    this.dataExpressionloading = true;
    this.appointmentService.ExportSAppointments(this.searchAppointmentRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'ScheduledAppointments.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  ExportNS() {
    if (this.loading)
      this.loading = false;
    let salesOrderIds: any[];
    if (this.selectedsalesOrders != undefined && this.selectedsalesOrders.length > 0) {

      salesOrderIds = new Array()
      this.selectedsalesOrders.forEach(value => {
        salesOrderIds.push(value.crmSalesOrderAPPId.toString());
      })
    }
    if (salesOrderIds != undefined && salesOrderIds.length > 0) {
      if (salesOrderIds.length == 1) {
        //salesOrderIds.push(',');
        this.searchRequestModel.dictionaryFilter['SelectedSalesOrderIds'] = salesOrderIds.toString();
      }
      else {
        this.searchRequestModel.dictionaryFilter['SelectedSalesOrderIds'] = salesOrderIds.toString();
      }
    }
    //console.log(salesOrderIds);
    this.loading = true;
    this._cdr.detectChanges();
    this.dataExpressionloading = true;
    this.appointmentService.ExportNSAppointments(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'NotScheduledAppointments.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  onPageChangeNS(event) {

    if (event.value) {
      this.selectedpageNS = this.pageSiges.find(x => x.code == event.value.code);
      this.searchAppointmentRequestModel.PageSize = Number(this.selectedpageNS.code);
      //this.searchAppointmentRequestModel.dictionaryFilter = {};
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;

      let selSatatus = this.selectedAppointmentStatus;
      if (selSatatus)
        this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.wipStatesId.toString();


      if (!this.SSelectedDateRange) {
        return;
      }
      let startDate = this.SSelectedDateRange.startDate;
      let endDate = this.SSelectedDateRange.endDate;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }

      if (startDate && endDate) {
        this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

        this.searchAppointmentRequestModel.searchFromDate = startDate;
        this.searchAppointmentRequestModel.searchToDate = endDate;
      }
      this.resetAppoinmentPageIndex();
      //this.getAppointmentOrderCountByStatus(true);
      this.getAppointmentsOnStatusCount(true);
      //this.getTimeZone();
      this.getappointments();
    }

  }

  onPageChange(event) {

    if (event.value) {
      this.selectedpage = this.pageSiges.find(x => x.code == event.value.code);
      this.searchRequestModel.PageSize = Number(this.selectedpage.code);
      //this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      let selSatatus = this.salesSelectedOrderCountModels;
      if (selSatatus) {
        if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
          this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
        }
        else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
          this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
        }
        else
          this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      }
      if (this.selectedDoctorResponseModel.crmDoctorId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();


      if (this.selectedfacilityResponseModel.crmFacilityId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();

      let startDate = this.NSSelectedDateRange.startDate;
      let endDate = this.NSSelectedDateRange.endDate;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }
      if (startDate && endDate) {
        this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchRequestModel.searchFromDate = startDate;
        this.searchRequestModel.searchToDate = endDate;
      }

      this.resetPageIndex();
      //this.getSalesOrderCountByStatus(true);
      this.GetSalesOrderCountOnAppointmentStatus(true);
      this.getsalesorders();
    }

  }

  clickEventFilter() {
    this.clickEventFilterStatus = !this.clickEventFilterStatus;
    if (!this.clickEventFilterStatus) {
      this.tableheight = 297;
    }
    else {
      this.tableheight = 370;
    }

  }

  onWindowResize(event) {
    this.windowheight = event.target.innerHeight;
  }

  onTimeZoneChange(event) {
    let timeZones = new Array<string>();
    if (event !== undefined && event != null) {
      this.selectedTimeZone.forEach((value: TimeZoneAvailableResponseModel) => {
        timeZones.push(value.appTimeZoneOffSet);
      });
      let arrayTimeZone = timeZones.join();
      if (this.index == 0)
        this.searchRequestModel.selectedTimeZone = arrayTimeZone;
      else
        this.searchAppointmentRequestModel.selectedTimeZone = arrayTimeZone;
    }
  }

  onPrimaryInsurationProviderChange(event) {
    let primaryInsuranceProvider = new Array<string>();
    if (event !== undefined && event != null) {
      this.selectedPrimaryInsuranceProvider.forEach((value: PrimaryInsuranceProviderResponseModel) => {
        primaryInsuranceProvider.push(value.crmPolicyHolderName);
      });
      let arrayprimaryInsuranceProvider = primaryInsuranceProvider.join();
      if (this.index == 0)
        this.searchRequestModel.selectedPrimaryInsurance = arrayprimaryInsuranceProvider;
      else
        this.searchAppointmentRequestModel.selectedPrimaryInsurance = arrayprimaryInsuranceProvider;
    }
  }

  onFilterSearch() {
    if (!this.searchRequestModel.isFromDashboard) {
      this.searchRequestModel.dictionaryFilter = {};
    }

    let selSatatus = this.salesSelectedOrderCountModels;
    this.enableNsDateRangeClrIcon = false;
    if (selSatatus) {
      if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
        this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      }
      else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
        this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      }
      else
        this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderViewStatus;
    }

    if (this.selectedDoctorResponseModel != undefined) {
      if (this.selectedDoctorResponseModel.crmDoctorId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();
    }

    if (this.selectedfacilityResponseModel != undefined) {
      if (this.selectedfacilityResponseModel.crmFacilityId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();
    }

    if (this.NSSelectedDateRange != null && (this.NSSelectedDateRange.startDate != null || this.NSSelectedDateRange.endDate != null)) {
      let startDate = this.NSSelectedDateRange.startDate;
      let endDate = this.NSSelectedDateRange.endDate;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }
      if (startDate && endDate) {
        this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchRequestModel.searchFromDate = startDate;
        this.searchRequestModel.searchToDate = endDate;
        this.enableNsDateRangeClrIcon = true;
      }
    }
    else {
      this.NSSelectedDateRange = null;
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = '';
      this.searchRequestModel.searchFromDate = null;
      this.searchRequestModel.searchToDate = null;
    }


    // if (this.searchBox.nativeElement.value)
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // else
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = '';
    this.resetPageIndex();
    //this.getSalesOrderCountByStatus(true);
    this.GetSalesOrderCountOnAppointmentStatus(true);
    //this.getSalesOrderCountByStatus(true);
    this.getsalesorders();

  }

  onClearNSFilterSearch() {
    if (!this.searchRequestModel.isFromDashboard) {
      this.searchRequestModel.dictionaryFilter = {};
    }
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value = '';
    this.currentSelectedNSUserBranch = Number(0);
    this.searchRequestModel.crmBranchId = Number(0);
    this.selectedNSbranch = this.branchResponseModel[0];
    this.selectedDoctorResponseModel = new DoctorResponseModel();
    this.selectedfacilityResponseModel = new FacilityResponseModelDropdownList();
    this.selectedPrimaryInsuranceProvider = new Array<PrimaryInsuranceProviderResponseModel>();
    this.NSSelectedDateRange = null;
    this.selectedTimeZone = new Array<TimeZoneAvailableResponseModel>();
    this.enableNsDateRangeClrIcon = false;
    this.searchRequestModel.selectedTimeZone = null;
    this.searchRequestModel.selectedPrimaryInsurance = null;
    this.selectedClassificationType = new Array<FilterClassificationTypeResponseModel>();
    this.searchRequestModel.selectedClassificationType = null;
  }

  onSCHSearch() {
    if (!this.searchAppointmentRequestModel.isFromDashboard) {
      this.searchAppointmentRequestModel.dictionaryFilter = {};
    }

    // if (this.searchBoxAppointment.nativeElement.value)
    //   this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
    // else
    //   this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = '';
    if (this.searchAppointmentRequestModel.selectedTimeZone == undefined || this.searchAppointmentRequestModel.selectedTimeZone == null) {
      this.selectedTimeZone = [];
    }

    if (this.searchAppointmentRequestModel.selectedPrimaryInsurance == undefined || this.searchAppointmentRequestModel.selectedPrimaryInsurance == null) {
      this.selectedPrimaryInsuranceProvider = [];
    }

    if (this.searchAppointmentRequestModel.selectedClassificationType == undefined || this.searchAppointmentRequestModel.selectedClassificationType == null) {
      this.selectedClassificationType = [];
    }

    // if(this.searchAppointmentRequestModel.selectedTimeZone == undefined){
    //   this.selectedTimeZone=[];
    // }
    let selSatatus = this.selectedAppointmentStatus;
    if (selSatatus) {
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.wipStatesId.toString();
    }

    if (this.SSelectedDateRange != null && (this.SSelectedDateRange.startDate != null || this.SSelectedDateRange.endDate != null)) {

      let startDate = this.SSelectedDateRange.startDate;
      let endDate = this.SSelectedDateRange.endDate;
      this.enableSchDateRangeClrIcon = false;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }

      if (startDate && endDate) {
        this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchAppointmentRequestModel.searchFromDate = startDate;
        this.searchAppointmentRequestModel.searchToDate = endDate;
        this.enableSchDateRangeClrIcon = true;
      }
    }
    else {
      this.SSelectedDateRange = null;
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = '';
      this.searchAppointmentRequestModel.searchFromDate = null;
      this.searchAppointmentRequestModel.searchToDate = null;
    }

    this.resetAppoinmentPageIndex();
    //this.getAppointmentOrderCountByStatus(true);
    this.getAppointmentsOnStatusCount(true);
    //this.getTimeZone();
    this.getappointments();

  }

  onClearSHFilterSearch() {
    if (!this.searchAppointmentRequestModel.isFromDashboard) {
      this.searchAppointmentRequestModel.dictionaryFilter = {};
    }
    this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value = '';
    this.currentSelectedSUserBranch = Number(0);
    this.searchAppointmentRequestModel.crmBranchId = Number(0);
    this.selectedSbranch = this.branchResponseModel[0];
    this.selectedPrimaryInsuranceProvider = new Array<PrimaryInsuranceProviderResponseModel>();
    this.selectedTimeZone = new Array<TimeZoneAvailableResponseModel>();
    this.selectedPrimaryInsuranceProvider = [];
    this.searchAppointmentRequestModel.selectedTimeZone = undefined;
    this.searchAppointmentRequestModel.selectedPrimaryInsurance = undefined;
    this.SSelectedDateRange = null;
    this.enableSchDateRangeClrIcon = false;
    this.selectedClassificationType = new Array<FilterClassificationTypeResponseModel>();
    this.selectedClassificationType = [];
    this.searchAppointmentRequestModel.selectedClassificationType = null;
  }

  //getPluginConfiguration() {
  //  this.loading = true;
  //  this.settingsService.getPluginConfiguration(Constants.pluginSettingCRMApp)
  //    .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
  //    .subscribe((result: ResultOfT<any>) => {
  //      this.processResult<any>(result, () => {

  //        let jsonData = JSON.parse(result.responseModel.appPluginSettingsValue);
  //        this.patientBrighttreeURL = jsonData.PatientBrighttreeURL;
  //        this.salesOrderBrighttreeURL = jsonData.SalesOrderBrighttreeURL;
  //      });
  //    }, (httpResponseError) => {

  //      this.showErrorMessage(httpResponseError.message);
  //    });

  //}

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  CheckSchItemToDisable() {
    var check = false;
    if (this.appointments != null || this.appointments != undefined) {
      if (this.appointments.length > 0 && this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] == this.iNoShowId.toString()) {
        check = this.appointments.filter(x => x.schAppointmentStatus.toLowerCase() == 'noshow').length == this.appointments.length;
      }
      if (this.appointments.length > 0 && this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] == this.iCompleteId.toString()) {
        check = this.appointments.filter(x => x.schAppointmentStatus.toLowerCase() == 'complete').length == this.appointments.length;
      }
    }

    return check;
  }
  CheckItemToDisable(itemId: string) {

    var check = true;
    if (this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] == itemId && (itemId == this.iNoShowId.toString())) {
      check = false;
    }
    return check;
  }

  // onAppointmentFilterStatus(selectedStatus: string) {
  //   this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selectedStatus;
  //   this.getsalesorders();
  // }

  onAppointmentFilterStatus(selectedStatus) {
    this.selectedStatusStr = selectedStatus;
    //this.selectedfilterIndex = this.selectedfilterIndex === index ? 0 : index;
    this.selectedfilterIndex = 0;
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    //if (!this.searchRequestModel.isFromDashboard) { // Removed this condition as click event gives wrong count becouse of filter get not empty--Prashant.Solanki 28/6/22
    this.searchRequestModel.dictionaryFilter = {}
    //}

    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;



    if (this.selectedfilterIndex != -1) {
      this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selectedStatus;
    }
    else
      this.salesSelectedOrderCountModels = null;

    let startDate = this.NSSelectedDateRange.startDate;
    let endDate = this.NSSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    if (startDate && endDate) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchRequestModel.searchFromDate = startDate;
      this.searchRequestModel.searchToDate = endDate;
    }

    this.resetPageIndex();
    this.getsalesorders();

    this.selectedPrimaryInsuranceProvider = new Array<PrimaryInsuranceProviderResponseModel>();
    this.searchRequestModel.selectedPrimaryInsurance = null;
    this.selectedClassificationType = new Array<FilterClassificationTypeResponseModel>();
    this.searchRequestModel.selectedClassificationType = null;
    this.getAllPrimaryInsuranceProviders();
    this.getAllClassificationType()
  }

  private async GetSalesOrderCountOnAppointmentStatus(isRefersh: boolean) {
    this.progress.next(true);
    await this.appointmentService.GetSalesOrderCountOnAppointmentStatus(this.currentSelectedNSUserBranch, this.loggedInUserId, isRefersh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesorderStatusCountModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.salesorderStatusCountModel = result.responseModel;
              //console.log(this.salesorderStatusCountModel);
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAppointmentsOnStatusCount(isRefresh: boolean) {
    this.progress.next(true);
    await this.appointmentService.GetAppointmentsOnStatusCount(this.currentSelectedSUserBranch, this.loggedInUserId, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentCountResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentsStatusCount = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


  onAppointmentStatusCountClick(selectedAppStatus: string) {
    this.selectedfilterSIndex = 0;
    this.searchAppointmentRequestModel.isUpcoming = false;
    this.searchAppointmentRequestModel.isUnreconciled = false;
    //this.selectedfilterSIndex = index;
    this.searchAppointmentRequestModel.crmBranchId = this.currentSelectedSUserBranch;
    this.searchAppointmentRequestModel.dictionaryFilter = {}
    if (this.searchBoxAppointment.nativeElement.value)
      this.searchAppointmentRequestModel.dictionaryFilter['SearchText'] = this.searchBoxAppointment.nativeElement.value;
    if (this.selectedfilterSIndex != -1) {
      let objselected = this.appointmentsalesOrderStatus.filter(x => x.wipStatesName === selectedAppStatus);
      if (objselected.length > 0) {
        this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = objselected[0].wipStatesId.toString();
        this.selectedAppointmentStatus = objselected[0];
      } else {
        this.selectedAppointmentStatus = null;
      }
    } else {
      this.selectedAppointmentStatus = null;
    }

    let startDate = this.SSelectedDateRange.startDate;
    let endDate = this.SSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    this.isUnreconciled = false;
    if (selectedAppStatus == 'Booked') {
      this.searchAppointmentRequestModel.isUpcoming = true;
      this.searchAppointmentRequestModel.isUnreconciled = false;
    }
    else if (selectedAppStatus == 'unReconcile') {
      this.isUnreconciled = true;
      this.searchAppointmentRequestModel.isUpcoming = false;
      this.searchAppointmentRequestModel.isUnreconciled = true;
      this.searchAppointmentRequestModel.dictionaryFilter['AppointmentStatus'] = '-999';
    }

    if (startDate && endDate) {
      this.searchAppointmentRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchAppointmentRequestModel.searchFromDate = startDate;
      this.searchAppointmentRequestModel.searchToDate = endDate;
    }


    this.resetAppoinmentPageIndex();
    //this.getTimeZone();
    this.getappointments();

  }


  CancelVoidAllAppointmentS(type: string, isSaleOrder: boolean) {

    this.isSaleOrder = isSaleOrder;
    this.appointmentType = type;

    this.salesOrderAppIds = new Array()

    if (isSaleOrder) {
      this.salesOrderAppIds = this.selectedsalesOrders;
    }
    else {
      this.salesOrderAppIds = this.selectedAppointments;
    }

    if (!this.salesOrderAppIds || this.salesOrderAppIds.length <= 0) {
      if (isSaleOrder) {
        this.showWarningMessage('Please select sales order(s).');
      } else {
        this.showWarningMessage('Please select appointment(s).');
      }
      this._cdr.detectChanges();
      return;
    }

    let action = 'Void review';
    let popupHeader = 'Update Void Review Status of Appointment(s)';

    if (isSaleOrder)
      popupHeader = 'Update Void Review Status of Sales Order(s)';

    this.isRescheduledRequest = false;
    if (type == "voidreview") {
      this.voidFrom = 'A';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      this.displayCancelAppointment = false;
      // this.displayVoidAppointment = true;   
    }
    else if (type == "void") {
      this.voidFrom = 'P';
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      this.displayCancelAppointment = false;
      // this.displayVoidAppointment = true;
      action = 'Void';
      if (isSaleOrder)
        popupHeader = 'Update Void Status of Sales Order(s)';
      else
        popupHeader = 'Update Void Status of Appointment(s)';
    }
    else {
      if (this.wipStatusBTMappingResponseModel != null && this.wipStatusBTMappingResponseModel != undefined) {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
        if (selectedWipStaus) {
          this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
          this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
        }
      }
      this.displayVoidAppointment = false;
      //this.displayCancelAppointment = true;
      action = 'Cancel';
      if (isSaleOrder)
        popupHeader = 'Update Cancel Status of Sales Order(s)';
      else
        popupHeader = 'Update Cancel Status of Appointment(s)';
    }

    // call update status form
    this.callUpdateStatusForm(action, popupHeader, this.voidFrom);
  }

  callUpdateStatusForm(action: string, header: string, voiform: string) {
    this.checkSelectedRecord();
    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data: {
        action: action,
        popupHeader: header,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: action == 'Cancel' ? this.cancellationReasonsResponseModel : this.voidReasonsResponseModel,
        isMultiSelected: this.isMultiSelected,
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {

      if (action == 'Cancel') {
        if (response.action == 'Confirm') {
          this.updateStatusforminfo = response;
          this.cancelAppointment();
        }
        if (response.action == 'Cancel') {
          this.hideAppointmentpopup();
        }
      }
      else { //void/void review
        if (response.action == 'Confirm') {
          this.updateStatusforminfo = response;
          this.voidAppointment(voiform);
        }
        if (response.action == 'Cancel') {
          this.cancelVoidAppointment();
        }
      }
    });
    /** End Code */
  }

  CancelVoidSaleOrderSubmit() {

    let statusMessage = this.appointmentType == "voidreview" ? "voided for review" : this.appointmentType == "void" ? "voided" : "cancel";
    let statusType = this.isSaleOrder ? "saleorder(s) ?" : "appointment(s) ?";

    this.loading = true;
    const cancelRequestList = [];
    this.salesOrderAppIds.forEach((value, index) => {

      let cancelRequestModel = this.appointmentType == "voidreview" || this.appointmentType == "void" ? this.getValuesFromUpdateStautsForm() : this.getValuesFromUpdateStautsForm();

      cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      cancelRequestModel.schAppointmentId = this.salesOrderAppIds[index]["schAppointmentId"];
      cancelRequestModel.crmSalesOrderId = this.isSaleOrder ? (this.salesOrderAppIds[index]["crmSalesOrderId"]) : (this.salesOrderAppIds[index]["crmSalesorderId"]);
      cancelRequestModel.SalesOrderStatus = this.appointmentType == "voidreview" ? "VoidReview" : this.appointmentType == "void" ? "Void" : "Cancel";

      let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
      voidAppointmentRequestBT.IsUpdateToBrightree = false;
      voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
      voidAppointmentRequestBT.IsOrderNoteOverride = true;
      voidAppointmentRequestBT.OrderNote = null;
      voidAppointmentRequestBT.DeliveryNote = null;
      voidAppointmentRequestBT.PractitionerName = null;
      voidAppointmentRequestBT.PractitionerId = 0;
      voidAppointmentRequestBT.DeliveryTechnicianId = 0;
      voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
      voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
      voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();

      if (this.appointmentType == "voidreview") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      } else if (this.appointmentType == "void") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      } else if (this.appointmentType == "cancel") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }
      this.isBrightreeSystemAppUpdate = this.updateStatusforminfo.isBrightreeSystemAppUpdate;
      voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
      cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;
      cancelRequestList.push(cancelRequestModel);
    });

    if (this.salesOrderAppIds != null && this.salesOrderAppIds.length > 0) {
      let appointmentRequest = this.appointmentService.UpdateSalesOrderByWipStatus(cancelRequestList);
      appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {

            const formvoid = this.formVoidAppointment;
            formvoid.get(this.formFields.voidReason).setValue('');
            formvoid.get(this.formFields.voidReasonComments).setValue('');
            formvoid.reset();

            const formcan = this.formCancel;
            formcan.get(this.formFields.reason).setValue('');
            formcan.get(this.formFields.reasonComments).setValue('');
            formcan.reset();
            this.displayCancelAppointment = false;
            this.displayVoidAppointment = false;

            this.salesOrderAppIds = [];
            this.selectedsalesOrders = [];
            this.selectedAppointments = [];

            this.showSuccessMessage("Appointments has been " + statusMessage);
            this.GetSalesOrderCountOnAppointmentStatus(true);
            this.getAppointmentsOnStatusCount(true);
            this.getsalesordersRefresh();
            this.getappointmentsRefresh();
            this._cdr.detectChanges();
            this.loading = false

          }
          else {
            this.loading = false
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem in cancelling your appointment.");
          }
        });
    }
  }

  async SendVoiceMail(isSaleOrder: boolean) {
    this.isSaleOrder = isSaleOrder;
    this.salesOrderAppIds = new Array()

    if (isSaleOrder) {
      this.salesOrderAppIds = this.selectedsalesOrders;
    }
    else {
      this.salesOrderAppIds = this.selectedAppointments;
    }

    console.log(this.salesOrderAppIds);

    if (!this.salesOrderAppIds || this.salesOrderAppIds.length <= 0) {
      if (isSaleOrder) {
        this.showWarningMessage('Please select sales order(s).');
      }
      else {
        this.showWarningMessage('Please select appointment(s).');
      }
      this._cdr.detectChanges();
      return;
    }

    let salesorderIds = "";

    if (this.salesOrderAppIds.length > 0 && this.salesOrderAppIds != undefined) {
      this.salesOrderAppIds.forEach((value, index) => {
        let crmSalesOrderId = this.isSaleOrder ? (this.salesOrderAppIds[index]["crmSalesOrderId"]) : (this.salesOrderAppIds[index]["crmSalesorderId"]);
        if (salesorderIds != "") {
          salesorderIds = salesorderIds + "," + crmSalesOrderId
        }
        else {
          salesorderIds = crmSalesOrderId;
        }
      });

      await this.appointmentService.BulkSendVoiceMail(salesorderIds, this.loggedInUserId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.responseModel) {
            this.showSuccessMessage('Voice mail has been Sent to Patients.');
            // this.callPopup = false;
            this.isCallStarted = false;
            this.salesOrderAppIds = [];
            this.selectedsalesOrders = [];
            this.selectedAppointments = [];
            this._cdr.detectChanges();
          }
          else {
            this.showWarningMessage('Error occurred while sending voice mail.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  private async getAllPrimaryInsuranceProviders() {
    let SalesOrderStatus: string;
    if (this.index == 0) {
      SalesOrderStatus = this.selectedStatusStr;
    }
    else {
      SalesOrderStatus = 'Booked';
    }
    await this.appointmentService.GetAllPrimaryInsuranceProviders(SalesOrderStatus, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<PrimaryInsuranceProviderResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.primaryInsuranceProviderList = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getNonSUserPreferenceColumn() {
    this.loading = true
    let moduleType;

    await this.appointmentService.getUserPreferenceMasterColumn(ModuleType.AppointmentNotSchedule, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.userNonSPreferenceColumn = [];
            this.userNonSPreferenceColumn = result.responseModel;
            this.userNonSPreferenceColumn.forEach(x => {
              x.preferenceVisible = x.visible;
            })
            let IsAnyColVisible = this.userNonSPreferenceColumn.filter(x => x.visible === true);
            if (IsAnyColVisible.length == 0) {
              this.isOnlyNonSFixedColumnVisible = true;
            }
            else {
              this.isOnlyNonSFixedColumnVisible = false;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getSUserPreferenceColumn() {
    this.loading = true;
    let moduleType;


    await this.appointmentService.getUserPreferenceMasterColumn(ModuleType.AppointmentSchedule, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.userSPreferenceColumn = [];
            this.userSPreferenceColumn = result.responseModel;
            this.userSPreferenceColumn.forEach(x => {
              x.preferenceVisible = x.visible;
            })
            let IsAnyColVisible = this.userSPreferenceColumn.filter(x => x.visible === true);
            if (IsAnyColVisible.length == 0) {
              this.isOnlySFixedColumnVisible = true;
            }
            else {
              this.isOnlySFixedColumnVisible = false;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllClassificationType() {
    await this.appointmentService.GetAllClassificationType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<FilterClassificationTypeResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.filterClassificationList = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onClassificationTypeChange(event) {
    let classificationType = new Array<number>();
    if (event !== undefined && event != null) {
      this.selectedClassificationType.forEach((value: FilterClassificationTypeResponseModel) => {
        classificationType.push(value.crmClassificationTypeId);
      });
      let arrayClassificationType = classificationType.join();
      if (this.index == 0)
        this.searchRequestModel.selectedClassificationType = arrayClassificationType;
      else
        this.searchAppointmentRequestModel.selectedClassificationType = arrayClassificationType;
    }
  }

  dropNon(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.userNonSPreferenceColumn, event.previousIndex, event.currentIndex);
  }
  dropS(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.userSPreferenceColumn, event.previousIndex, event.currentIndex);
  }
  CancelNonSColumn(event, element) {
    element.hide(event);
    this.getNonSUserPreferenceColumn();
  }

  CancelSColumn(event, element) {
    element.hide(event);
    this.getSUserPreferenceColumn();
  }

  private async getSalesOrderDependenciesDetails() {
    this.progress.next(true);
    let SalesOrderStatus: string;
    if (this.index == 0) {
      SalesOrderStatus = this.selectedStatusStr;
    }
    else {
      SalesOrderStatus = 'Booked';
    }

    await this.appointmentService.GetSalesOrderDependenciesDetails(this.currentSelectedSUserBranch, this.loggedInUserId, SalesOrderStatus)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<SalesOrderDependenciesDetails>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              //await this.getAppointmentsOnStatusCount(true);
              this.appointmentsStatusCount = result.responseModel.appointmentCountResponseModel;

              //await this.getBranches();
              this.branchResponseModel = result.responseModel.branchResponseModelInApps;
              let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
              if (selectedBranch) {
                this.selectedNSbranch = selectedBranch;
                this.selectedSbranch = selectedBranch;
              }
              else {
                this.selectedNSbranch = this.branchResponseModel[0];
                this.selectedSbranch = this.branchResponseModel[0];;
              }

              //await this.getAllSalesOrderDoctors();
              this.doctorResponseModel = result.responseModel.doctorInfoResponseModelInApps;

              //await this.getAllSalesOrderDoctorFacility();
              this.facilityResponseModelDropdownList = result.responseModel.facilityResponseModelInAppDropDowns;;

              //await this.IsSalesOrdersLockedByUser();
              this.isLockedAppointments = result.responseModel.isSalesOrdersLockedByUser;

              //await this.getAllPrimaryInsuranceProviders();
              this.primaryInsuranceProviderList = result.responseModel.primaryInsuranceProviderResponseModels;

              //await this.getAllClassificationType();
              this.filterClassificationList = result.responseModel.filterClassificationTypeResponseModels;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
