import { ChangeDetectorRef, Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy, AfterViewInit } from '@angular/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Subject } from 'rxjs';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { AppointmentService } from '../servicies/appointment.service';
import { Product, ProductService1 } from './productservice1';
import { AppointmentVoiceCallResponseModel } from 'src/app/shared/models/response/appointment/appointment-voice-call-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { SettingsService } from '../servicies/settings.service';
import { Constants } from 'src/app/shared/constants';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { UserPermissions } from 'src/app/shared/enums';
import { DatePipe } from '@angular/common';
import { Table, TableHeaderCheckbox } from "primeng/table";
@Component({
  selector: 'app-voice-call-recordings',
  templateUrl: './voice-call-recordings.component.html',
  styleUrls: ['./voice-call-recordings.component.sass'],
  
})
export class VoiceCallRecordingsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean;
  title = 'Voice Call Recordings';
  @ViewChild("table") private _table: Table;
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild("headerCheckBox") private headerCheckBox: TableHeaderCheckbox;
  @ViewChildren('headerCheckBox') childrenComponent: QueryList<TableHeaderCheckbox>;
  cols1: any [];
  products2:any [];
  products: Product[];
  index: number = 0;

  totalRecords: number;
  totalPages: number;
  loading: boolean;
  dataExpressionloading: boolean = false;
  isBulkRefreshed: boolean = false;
  searchRequestModel: SearchRequestModel;
  salesOrders: AppointmentVoiceCallResponseModel[];
  selectedsalesOrders: AppointmentVoiceCallResponseModel[];
  selectedfilterIndex = 0;
  isAppointmentDisabled: boolean = false;
  mySubscription: any;
  selectedFacilities: any;
  searchValue: string='';
  FromDate:Date;
  ToDate:Date;
  loggedInUserId: number;
  loggedInUserEmail: string;
  cols: any[];
  pageSiges: QuickFilters[];
  selectedpage: QuickFilters;
  isDisabledLock: boolean = true;
  NsheduledTableDefaltColumns: number = 8;
  salesOrderBrighttreeURL: any;
  patientBrighttreeURL: any;
  brightreeInternalUserId: number;
  appDateFormat: DateFormatModel;
  appointmentVoiceCallResponseModel: AppointmentVoiceCallResponseModel[];
  audioPlayerPopup: boolean = false;
  appointmentRecordingSID:number;
  appointmentCallerName:string;
  appointmentCallDateTime:any;
  userPerissionfeatures:userModuleFeatureResponseModel[];
  allowToListenOthersRecording:boolean=false;
  callRecordingStatus: { label: string, status: number }[];
  selectedCallRecordingStatus: { label: string, status: number };
  maxDate = new Date();
  
  constructor(private productService1: ProductService1,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appointmentService: AppointmentService,
    private settingsService: SettingsService,
    messageService: MessageService,
    private webStorageService: WebStorageService,
    private _cdr: ChangeDetectorRef,
    @Inject(LOCALE_ID) private locale: string) { 
    super(messageService);
      this.appDateFormat = this.webStorageService.getDateFormat();
      this.totalRecords = 0;
      this.totalPages = 1;
      this.loading = false;
      const loggedInUser = this.webStorageService.getCurrentUserToken();
      this.loggedInUserId = loggedInUser.userId;
      this.loggedInUserEmail = loggedInUser.userEmail;
      this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
      
      this.pageSiges = [
        { name: '20', code: '20' },
        { name: '25', code: '25' },
        { name: '50', code: '50' },
        { name: '100', code: '100' },
      ];
      this.selectedpage = { name: '20', code: '20' };
      
      this.callRecordingStatus = [
        { label: 'Available Recordings', status: 2 },
        { label: 'Removed Recordings', status: 3 },
      ];
      
      const currentNavigationState = this.router.getCurrentNavigation().extras.state;
      if (currentNavigationState && currentNavigationState.searchRequestModel) {
  
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        this.index = Number(currentNavigationState.selectedTab);
        this.selectedfilterIndex = currentNavigationState.selectedfilterIndex;
        this.totalRecords = this.searchRequestModel.totalRecords;
        this.isAppointmentDisabled = currentNavigationState.isBookAppintment ? currentNavigationState.isBookAppintment : false;
        this.hasPageHistory = true;
  
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
        this.mySubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            // Trick the Router into believing it's last link wasn't previously loaded
            this.router.navigated = false;
          }
        });
      }
      this.GetRCMConfigurationUrl();
      this.initializeSearchRequestModel();
      this.setUserPermissionforActions();
      // this.cols = [
      //   //{ fieldNo: 6, visible: true, field: 'crmPatientBirthdate', header: 'Date of Birth', active: true, fixedColumn: false, sortFieldName: 'CrmPatientBirthdate' }, 
  
      //   { freezCoulmn: true, fieldNo: 1, visible: true, field: 'schAppointmentCallId', header: '', active: true, fixedColumn: true },
      //   { freezCoulmn: true, fieldNo: 2, visible: true, field: 'schAppointmentCallId', header: 'Rec. ID', active: true, fixedColumn: true,sortFieldName: 'SchAppointmentCallId' },
      //   { freezCoulmn: true, fieldNo: 3, visible: true, field: 'crmSalesOrderAppId', header: 'SO / Patient ID', active: true, fixedColumn: true, sortFieldName: 'CrmSalesOrderAppId' },  
      //   { freezCoulmn: true, fieldNo: 4, visible: true, field: 'appCallerName', header: 'Caller', active: true, fixedColumn: true, sortFieldName: 'AppCallerName' },
      //   { freezCoulmn: true, fieldNo: 9, visible: false, field: 'CallerNameFields', header: '', active: true, fixedColumn: true },
        
      //   { freezCoulmn: false, fieldNo: 5, visible: true, field: 'schAppointmentCallToNumber', header: 'To Phone', active: true, fixedColumn: false, sortFieldName: 'SchAppointmentCallToNumber' },
      //   { freezCoulmn: false, fieldNo: 6, visible: true, field: 'schAppointmentCallDateTime', header: 'Rec. Date & Time', active: true, fixedColumn: false, sortFieldName: 'SchAppointmentCallDateTime' },
      //   { freezCoulmn: false, fieldNo: 7,  visible: true, field: 'schRecordingURL', sortFieldName: 'schRecordingURL', header: 'Audio', active: true, fixedColumn: false, type: 'string', },
      //   { freezCoulmn: false, fieldNo: 10, visible: true, field: 'schRecordingUploadType', header: 'Upload Status', active: true, fixedColumn: false, sortFieldName: 'SchRecordingUploadType' },
      //   { freezCoulmn: false, fieldNo: 8, visible: true, field: 'schRecordingUploadStatus', header: 'Upload', active: true, fixedColumn: false, sortFieldName: 'SchRecordingUploadStatus' },
        
      // ];
    }

    private initializeSearchRequestModel() {

      if (this.hasPageHistory && this.index === 0)
        return;
  
        this.searchRequestModel = new SearchRequestModel();

        if (this.searchBox)
          this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
  
      this.searchRequestModel.appUserInfoId = this.loggedInUserId;
      this.searchRequestModel.FirstRecordIndex = 0;
      this.searchRequestModel.CurrentPageIndex = 0;
      this.searchRequestModel.PageSize = +this.pageSizes[0];
      this.searchRequestModel.SortOrder = 'ASC';
      this.searchRequestModel.SortByColumnName = "SchAppointmentCallId";

      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
        this.searchRequestModel.dictionaryFilter = {};
        this.selectedCallRecordingStatus = { label: 'Available Recordings', status: 2 };
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedCallRecordingStatus.status.toString();
      }
    }

    async ngOnInit(){
    //this.productService1.getProductsWithOrdersSmall().then(data => this.products = data);
    
    //this.getallvoicecallrecordingappointments();
  }

  setUserPermissionforActions() {
    this.userPerissionfeatures = this.webStorageService.getUserPermissions();
    if (this.userPerissionfeatures && this.userPerissionfeatures.length > 0) {

      let inPer1 = this.userPerissionfeatures.find(x => x.userId == this.loggedInUserId && x.appModuleFeatureName == UserPermissions.AllowToListenOtherUsersRecordings);
      if (inPer1) {
        this.allowToListenOthersRecording = true;
      }
    }
  }

  isRowDisabled(data: any): boolean {
    if (data.schRecordingFileStage == 3 || data.schRecordingFileStage == 0 || data.schRecordingFileStage == 2)
      return true;
    else {

      let TodaysDate: Date = new Date();
      TodaysDate.setMonth(TodaysDate.getMonth() - 3);
      let recordDate: Date = new Date(data.schAppointmentCallDateTime);
      let IsRecordDate= recordDate >= TodaysDate;

      if (IsRecordDate && data.schRecordingFileStage == 1) //0 - Not yet downloaded | 3 - Supressed
        return true;
      else if (IsRecordDate === false && data.schRecordingFileStage == 1)
        return false;
      else
        return false;

    }
  }
  strRowDisabledToolTip(data: any): string {
    if (data.schRecordingFileStage == 3)
      return 'Recording Removed';
    if ( data.schRecordingFileStage == 0 || data.schRecordingFileStage == 2)
      return 'Recording file not yet processed';
    if ( data.schRecordingFileStage == 2)
      return 'Recording file already uploaded';
    else {

      let TodaysDate: Date = new Date();
      TodaysDate.setMonth(TodaysDate.getMonth() - 3);
      let recordDate: Date = new Date(data.schAppointmentCallDateTime);
      let IsRecordDate = recordDate >= TodaysDate;

      if (IsRecordDate && data.schRecordingFileStage == 1) //0 - Not yet downloaded | 3 - Supressed
        return 'You can only upload 3 month older data';
      else if (IsRecordDate === false && data.schRecordingFileStage == 1)
        return '';
      else
        return '';

    }
  }


  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress)
      this.progress.complete();
  }
  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
    
      if (comps.length > 0) {
        const orig_updateCheckedState = this.headerCheckBox.updateCheckedState;
        const me = this;
        this.headerCheckBox.updateCheckedState = function () {

          const cars: any[] = me._table.filteredValue || me._table.value;
          const selection: any[] = me._table.selection;
          if (cars != null) {
            for (const car of cars) {
              if (!me.isRowDisabled(car)) {
                const selected = selection && selection.indexOf(car) >= 0;
                if (!selected) return false;
              }
            }
          }
          if (selection && selection.length > 0)
            return true;
        };
      }
    });

    //var y = document.querySelector(".dummyClassNSch");
    //const frozenBody: HTMLElement | null = y.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    //const scrollableArea: HTMLElement | null = y.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    //if (frozenBody && scrollableArea) {
    //  frozenBody.addEventListener('wheel', e => {
    //    const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
    //    const canScrollUp = 0 < scrollableArea.scrollTop;

    //    if (!canScrollDown && !canScrollUp) {
    //      return;
    //    }

    //    const scrollingDown = e.deltaY > 0;
    //    const scrollingUp = e.deltaY < 0;
    //    const scrollDelta = 100;

    //    if (canScrollDown && scrollingDown) {
    //      e.preventDefault();
    //      scrollableArea.scrollTop += scrollDelta;
    //    } else if (canScrollUp && scrollingUp) {
    //      e.preventDefault();
    //      scrollableArea.scrollTop -= scrollDelta;
    //    }
    //  });
    //}


    //var x = document.querySelector(".dummyClassSch");
    //const frozenBody1: HTMLElement | null = x.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    //const scrollableArea1: HTMLElement | null = x.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    //if (frozenBody1 && scrollableArea1) {
    //  frozenBody1.addEventListener('wheel', e => {
    //    const canScrollDown = scrollableArea1.scrollTop < (scrollableArea1.scrollHeight - scrollableArea1.clientHeight);
    //    const canScrollUp = 0 < scrollableArea1.scrollTop;

    //    if (!canScrollDown && !canScrollUp) {
    //      return;
    //    }

    //    const scrollingDown = e.deltaY > 0;
    //    const scrollingUp = e.deltaY < 0;
    //    const scrollDelta = 100;

    //    if (canScrollDown && scrollingDown) {
    //      e.preventDefault();
    //      scrollableArea1.scrollTop += scrollDelta;
    //    } else if (canScrollUp && scrollingUp) {
    //      e.preventDefault();
    //      scrollableArea1.scrollTop -= scrollDelta;
    //    }
    //  });
    //}
  }


  async getallvoicecallrecordingappointments() {
    this.loading = true;
    this.dataExpressionloading = true;
    this._cdr.detectChanges();
    // this.searchRequestModel.dictionaryFilter = {};
    //   this.searchRequestModel.dictionaryFilter['SearchText'] = "";//this.searchBox.nativeElement.value"";
    await this.appointmentService.GetAllVoiceCallRecordings(this.searchRequestModel, this.isBulkRefreshed)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .subscribe((result: ResultOfT<PagedResponseModel<AppointmentVoiceCallResponseModel>>) => {
        this.processResult<PagedResponseModel<AppointmentVoiceCallResponseModel>>(result, () =>
        {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                if (pagedResponse.totalRecords > 0) {
                  // if (this.isGlobalSearch && this.searchText) {
                  //   this.SalesOrderFound = true;
                  //   if (this.isGlobalSearch) {
                  //     if (this.SalesOrderFound && this.AppointmentFound) {
                  //       this.index = 0;
                  //     }
                  //     else if (this.SalesOrderFound) {
                  //       this.index = 0;
                  //     }
                  //     else if (this.AppointmentFound) {
                  //       this.index = 1;
                  //     }
                  //   }
                  // }
                  // else {
                  //   this.SalesOrderFound = false;
                  // }
                }
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                //let lockedAppointments = this.salesOrders.filter(x => x.schIsLocked == true && x.schAppointmentLockedBy == this.loggedInUserId);
                //if (lockedAppointments.length > 0)
                //  this.isLockedAppointments = true;
                //else
                //  this.isLockedAppointments = false;
                this.isBulkRefreshed = this.dataExpressionloading = this.loading = false;
                this._cdr.detectChanges();
              }
            }
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }
  
  getTotalRows() {

    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize)
      return this.searchRequestModel.PageSize;

    return this.totalRecords;
  }

  onSearchButtonClick(){
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else
      this.searchRequestModel.dictionaryFilter['SearchText'] = '';

    this.isBulkRefreshed=true;
    this.resetPageIndex();
    this.getallvoicecallrecordingappointments();
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  onFilterSearch() {
      this.searchRequestModel.dictionaryFilter = {};
      if(this.FromDate && this.ToDate)
      {
        if (this.FromDate > this.ToDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }

        this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchRequestModel.searchFromDate = this.FromDate;
        this.searchRequestModel.searchToDate = this.ToDate;
      }
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = this.selectedCallRecordingStatus.status.toString();
      this.isBulkRefreshed=true;
      this.resetPageIndex();
      this.getallvoicecallrecordingappointments();
  }

  onClearFilterSearch() {
    this.searchRequestModel.dictionaryFilter = {};
      this.FromDate=null;
      this.ToDate=null;


  this.resetPageIndex();
  this.initializeSearchRequestModel();
  this.getallvoicecallrecordingappointments();

}

  onLazyLoad(event: LazyLoadEvent) {

    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {

      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
      this.searchRequestModel.PageSize = Number(this.selectedpage.code);//event.rows;

      //this.searchRequestModel.FirstRecordIndex = event.first;
      //this.searchRequestModel.CurrentPageIndex = event.first / 20;
      //this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getallvoicecallrecordingappointments();
  }
  
  onSelectionChange(selection: any[]) {

    for (let i = selection.length - 1; i >= 0; i--) {
      let data = selection[i];
      if (this.isRowDisabled(data)) {
        selection.splice(i, 1);
      }
    }
    this.selectedsalesOrders = selection;
    if (this.selectedsalesOrders.length > 0)
      this.isDisabledLock = false;
    else
      this.isDisabledLock = true;
  }

  isUploadDisabled(data: any): boolean {
    let TodaysDate:Date=new Date();
    TodaysDate.setMonth(TodaysDate.getMonth() - 3);
    let recordDate:Date=new Date(data.schAppointmentCallDateTime);
    if(recordDate>=TodaysDate) //0 - Not yet downloaded | 3 - Supressed
      return true;
    else
      return false;

    //return data.schRecordingFileStage==3? true:false;
  }

  onPageChange(event) {

    if (event.value) {
      this.selectedpage = this.pageSiges.find(x => x.code == event.value.code);
      this.searchRequestModel.PageSize = Number(this.selectedpage.code);
      //this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      // let selSatatus = this.salesSelectedOrderCountModels;
      // if (selSatatus) {
      //   if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
      //   }
      //   else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
      //     this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
      //   }
      //   else
      //     this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      // }
      // if (this.selectedDoctorResponseModel.crmDoctorId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();


      // if (this.selectedfacilityResponseModel.crmFacilityId > 0)
      //   this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();

      // let startDate = this.NSSelectedDateRange.startDate;
      // let endDate = this.NSSelectedDateRange.endDate;

      // if (startDate && endDate) {
      //   startDate = moment(startDate).toDate();
      //   endDate = moment(endDate).toDate();
      //   if (startDate > endDate) {
      //     this.showWarningMessage("From Date should not be greater than To Date.");
      //     return;
      //   }
      // }
      // if (startDate && endDate) {
      //   this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      //   this.searchRequestModel.searchFromDate = startDate;
      //   this.searchRequestModel.searchToDate = endDate;
      // }

      this.resetPageIndex();
      //this.getSalesOrderCountByStatus(true);
      this.getallvoicecallrecordingappointments();
    }

  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel;
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(salesOrderUrl);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    //console.log(patienKeyUrl);
    window.open(patienKeyUrl, '_blank')
  }

  OnUpload(data: any)
  {
    //this.appointmentVoiceCallResponseModel.map(data);
    this.appointmentVoiceCallResponseModel = []
    this.appointmentVoiceCallResponseModel.push(data);
    this.FileUploadToFTP(this.appointmentVoiceCallResponseModel);
  }

  async onUploadButtonClick()
  {
    if (!this.selectedsalesOrders || this.selectedsalesOrders.length <= 0) {
      this.showWarningMessage('Please select Voice recording(s) to upload on FTP.');
      return;
    }

    await this.FileUploadToFTP(this.selectedsalesOrders);
  }

  async FileUploadToFTP(appointmentVoiceCallResponseModel:AppointmentVoiceCallResponseModel[])
  {
    this.loading = true;
    await this.appointmentService.UploadVoiceCallRecordingToFTP(appointmentVoiceCallResponseModel,'Manual',new DatePipe(this.locale).transform(new Date(), 'MM/dd/yyyy'),new DatePipe(this.locale).transform(new Date(), 'MM/dd/yyyy'),this.loggedInUserEmail)
    .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
    .toPromise().then((result) => {
        if (result && result.isSuccess && !result.message) {
          if (result.responseModel != undefined && result.responseModel != null){
            //appointmentVoiceCallResponseModelList=result.responseModel;
            this.showSuccessMessage('Recording file uploaded to FTP successfully');
            this.isBulkRefreshed=true;
            this.getallvoicecallrecordingappointments();
            this._cdr.detectChanges();
          }
        }
        else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onPlayButtonClick(data:any){
    if(data.schAppointmentCallBy!=this.loggedInUserId && !this.allowToListenOthersRecording)
    {
        this.showInfoMessage('Sorry!, You do not have required permission to play other user recordings.');
        this.audioPlayerPopup = false;
        return;
    }

    if(data.recordingSID)
    {
      this.appointmentRecordingSID = data.recordingSID;
      this.appointmentCallDateTime = data.schAppointmentCallDateTime;
      this.appointmentCallerName = data.appCallerName;
      this.audioPlayerPopup = true;
      return;
    }
    else
    {
        this.showInfoMessage('Sorry!, Recording details not found for this call.');
        this.audioPlayerPopup = false;
        return;
    }
  }

  GetChildData(data){
    this.audioPlayerPopup=data;
  }

  ExportVoiceCallRecordingDetails() {
    this.loading=true;
    this.appointmentService.ExportVoiceCallRecordings(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'Voice_Call_Recordings.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
        this.loading=false;
      }, (httpResponseMessage) => {
        this.loading=false;
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  onStatusChange(event) {
    if (event.value != null) {
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status.toString();
    }
  }
}
