<div class="plugin-outer">
  <div class="form-outer">
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Protocol</label>
          <!-- <p-dropdown class="select-box" formControlName="service" [options]="pluginServices" optionLabel="pluginName" [showClear]="true">
          </p-dropdown> -->
          <p-dropdown class="select-box" formControlName="protocol" [options]="ftpProtocol"
          placeholder="value" optionLabel="protocolName"
          [showClear]="true">
          </p-dropdown>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.protocol, 'required') || hasError(formFields.protocol, 'whitespace')">{{validationMessages.protocol.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Hostname</label>
          <input type="text" formControlName="hostname" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.hostname, 'required') || hasError(formFields.hostname, 'whitespace')">{{validationMessages.hostname.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Username</label>
          <input type="text" formControlName="userName" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.userName, 'required') || hasError(formFields.userName, 'whitespace')">{{validationMessages.userName.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Password</label>
          <input type="password" formControlName="password" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.password, 'required') || hasError(formFields.password, 'whitespace')">{{validationMessages.password.required}}</span>
        </div>
        </div>
        <div class="col">
        <div class="form__group">
          <label class="form__label">Port</label>
          <input type="text" formControlName="port" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.port, 'required') || hasError(formFields.port, 'whitespace')">{{validationMessages.port.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Root Directory Path</label>
          <input type="text" formControlName="rootDirPath" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.rootDirPath, 'required') || hasError(formFields.rootDirPath, 'whitespace')">{{validationMessages.rootDirPath.required}}</span>
        </div>
        </div>
        <!-- <div class="col">
        <div class="form__group">
          <label class="form__label">Phone Number</label>
          <input type="text" formControlName="phoneNumber" class="form__control" pInputText>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber, 'required') || hasError(formFields.phoneNumber, 'whitespace')">{{validationMessages.phoneNumber.required}}</span>
          <span  class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber, 'validPhoneNumber')">{{validationMessages.phoneNumber.validPhoneNumber}}</span>
        </div>
      </div> -->

      <div class="btns">
        <button class="btn btn--primary" routerLink="/admin/settings/plugins">Save</button>
      </div>
    </form>
    <!-- <div class="test-sms-btn">
      <button class="btn btn--secondary" (click)="onClickTest()">Test SMS</button>
    </div> -->

    <div *ngIf="isTestSMS">
      <br>
      <div class="form__group test-sms">
        <label class="form__label">Phone Number</label>
        <input type="text" class="form__control" pInputText [(ngModel)]="testNumber">
      </div>
      <div class="btns">
      <button class="btn btn--primary" (click)="SendTestSMS()">Send SMS</button>
      <button class="btn btn--secondary" (click)="CancelSMS()">Cancel</button>
    </div>
    </div>
  </div>
</div>
