<div class="content-area">
  <div class="title-block">
    <h1><a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="ic back"></i> </a> {{title}}</h1>
    <div class="table-filter__sort-by">
      <button class="btn btn--primary" (click)="ManageAvailability()"><i class="ic plus-white"></i> Add Availability</button>
    </div>
  </div>
  <div class="table-filter therapist-filter">
    <div class="table-filter__search-block">
      <div class="search-box" *ngIf="false">
        <input type="text" class="form__control" pInputText placeholder="Search" #searchBox
               style="width:auto" (keyup.enter)="onSearchButtonClick()">
        <button class="search-btn" pButton type="button">
          <i class="ic search" (click)="onSearchButtonClick()"></i>
        </button>
      </div>
      <!--<p-dropdown class="select-box filter-status" [options]="branchResponseModel" [(ngModel)]="selectedbranch" id="branchDropdown"
                  placeholder="Select Branch" optionLabel="crmBranchName" [showClear]="true" (onChange)="onBranchChange($event)"></p-dropdown>-->
      <!--<p-dropdown class="select-box filter-status" #statusDropdown [options]="therapistStatus" placeholder="Status"
                  optionLabel="label" [showClear]="true" (onChange)="onStatusChange($event)"></p-dropdown>-->
    </div>
   
  </div>
  <div class="page-scroll viewavailability">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="table-outer-main">
        <p-table class="table-outer therapist" #nsdl [value]="availability"
                  responsiveLayout="scroll"
                 [paginator]="totalRecords>0?true:false"
                 sortMode="single"
                 sortField="CrmTherapistName"
                 [first]="searchRequestModel.FirstRecordIndex"
                 [sortOrder]="1"
                 [rows]="getTotalRows()"
                 [totalRecords]="totalRecords"
                 [lazy]="true"
                 [customSort]="true"
                 (onLazyLoad)="onLazyLoad($event)">
          <ng-template pTemplate="header">
            <tr *ngIf="totalRecords > 0">
              <!--<th pSortableColumn="crmTherapistName">Therapist<p-sortIcon field="crmTherapistName"></p-sortIcon></th>-->
              <!--<th pSortableColumn="crmTherapistCustomEndDate">End Date<p-sortIcon field="crmTherapistCustomEndDate"></p-sortIcon></th>-->
              <!--<th pSortableColumn="crmTherapistCustomEndTimeDate">End Time<p-sortIcon field="crmTherapistCustomEndDate"></p-sortIcon></th>-->
              <!--<th pSortableColumn="crmBreakEndTimeDate">Break End Time<p-sortIcon field="crmBreakEndTimeDate"></p-sortIcon></th>-->

              <th pSortableColumn="crmFacilityName">Facility<p-sortIcon field="crmFacilityName"></p-sortIcon></th>
              <th pSortableColumn="CrmCustomDays">Days<p-sortIcon field="CrmCustomDays"></p-sortIcon></th>
              <th pSortableColumn="CrmTherapistCustomStartDate">Date<p-sortIcon field="CrmTherapistCustomStartDate"></p-sortIcon></th>
              <th pSortableColumn="CrmTherapistCustomStartDate">Timings<p-sortIcon field="CrmTherapistCustomStartDate"></p-sortIcon></th>
              <th pSortableColumn="CrmBreakStartTimeDate">Break Timings<p-sortIcon field="CrmBreakStartTimeDate"></p-sortIcon></th>
              <th pSortableColumn="eventName">Recurring Frequency<p-sortIcon field="eventName"></p-sortIcon></th>
              <th pSortableColumn="CrmRecurringFrequency">Recurring Type<p-sortIcon field="CrmRecurringFrequency"></p-sortIcon></th>
              <th>Event</th>
              <th pSortableColumn="CrmDescription">Description<p-sortIcon field="CrmDescription"></p-sortIcon></th>
              <th width="80">Actions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-therapist>
            <!-- <tr [pSelectableRow]="rowData"> -->
            <tr>
              <!--<td>
                <span class="link">
                  <a (click)="onView(therapist.crmTherapistId)">{{therapist.crmTherapistName}}</a>
                </span>
              </td>-->
              <td>{{therapist.crmFacilityName}}</td>
              <td>{{GetDays(therapist.crmCustomDays)}}</td>
              <td>{{therapist.crmTherapistCustomStartDate | date:appDateFormat.dateFormat:appDateFormat.timeZone}} to {{therapist.crmTherapistCustomEndDate | date:appDateFormat.dateFormat:appDateFormat.timeZone}}</td>
              <td>{{therapist.crmTherapistCustomStartTimeDate | date:'hh:mm a':appDateFormat.timeZone | lowercase}} to {{therapist.crmTherapistCustomEndTimeDate | date:'hh:mm a':appDateFormat.timeZone | lowercase}}</td>
              <td><span *ngIf="!therapist.crmIsHoliday">{{therapist.crmBreakStartTimeDate | date:'hh:mm a':appDateFormat.timeZone | lowercase}} to {{therapist.crmBreakEndTimeDate | date:'hh:mm a':appDateFormat.timeZone | lowercase}}</span></td>
              <td>{{therapist.crmRecurringFrequency}}</td>
              <td>{{therapist.eventName}}</td>
              <td>{{therapist.crmIsHoliday?'Work Off':therapist.crmIsRoadShow?'Road Show':therapist.isRemoteAssistant?'Remote Setup':'Facility'}}</td>
              <td>{{therapist.crmDescription}}</td>
              <td>
                <div class="action-column">
                  <a class="actionmenu-btn" href="javascript:void(0)"
                     (click)="selectTherapist($event,therapist,therapistsAction)">
                     <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                        <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                        <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                      </svg>
                  </a>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <ng-container *ngIf="!loading && totalRecords <= 0">
              <tr>
                <td class="no-records-found-therapist" [attr.colspan]="6">
                  No records found for therapist.

              </tr>
            </ng-container>
          </ng-template>
          <ng-template pTemplate="paginatorleft" class="table-pagination">
            <span class="table-pagination__records">
              Total Records : &nbsp;<strong> {{totalRecords}}</strong>
            </span>
          </ng-template>
        </p-table>

        <div class="loader-block" *ngIf="loading">
          <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                             animationDuration=".8s">
          </p-progressSpinner>
        </div>
      </div>
    </p-scrollPanel>
  </div>
</div>
<p-overlayPanel class="table-action-popup facilities-action" #therapistsAction>
  <ul class="quick-link-menu">
    <li>
      <a href="javascript:void(0)" (click)="onView(selectedTherapist.crmTherapistId)"><i class="ic view"></i>View</a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="onEdit(selectedTherapist.crmTherpistCustomAvailabilityId,selectedTherapist.crmTherapistId)"><i class="edit-icon-new"></i>Edit</a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="onDelete(selectedTherapist.crmTherpistCustomAvailabilityId)">
        <i class="delete-icon-new"></i>
        Delete
      </a>
    </li>
  </ul>
</p-overlayPanel>
