import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { Subject } from 'rxjs';
import { SettingsService } from '../../../servicies/settings.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { WipStatus } from 'src/app/shared/models/common/wip-status';

//import { ReminderTypeModel, ReminderSettingModel } from 'src/app/shared/models/response/settings/settings-Reminder-model';

import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { WipStatusForReminder } from 'src/app/shared/enums';
import { ReminderSettingModel, ReminderTypeModel } from 'src/app/shared/models/response/settings/settings-reminder-model';

@Component({
  selector: 'app-reminder-setting',
  templateUrl: './reminder-setting.component.html',
  styleUrls: ['./reminder-setting.component.sass'],
  providers: [DialogService]
})
export class ReminderSettingComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'Notification Settings';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  loggedInUserId: number;
  reminderBtnTitle: string = "Update";
  reminderEditMode: boolean;
  formReminderSubmitted: boolean = false;
  eMedAssistReminder: boolean = false;
  emailTextValidation: boolean = false;
  wipStatus: WipStatus[];
  selectedBrightreeStatus: WipStatus;
  formFields = {
    remindertype: 'remindertype',
    frequency: 'frequency',
    brightreeStatus: 'brightreeStatus',
    frequencyType: 'frequencyType',

  };

  validationMessages = {
    remindertype: {
      required: 'Reminder Type is required',
      // validCharacters: 'Enter valid eMedAssist Status'
    },
    frequency: {
      required: 'Reminder Lead Time is required',
      validNumber: 'Enter valid Reminder Lead Time',

    },
    frequencyType: {
      required: 'Frequency Type is required',
    },
  };

  reminderTypeModelOptions: ReminderTypeModel[];
  reminderFrequencyTypeOptions: EnumModel[];
  selectedreminderTypeModelOptions: ReminderTypeModel;
  selectedreminderFrequencyTypeOptions: EnumModel;
  selectedReminderModel: ReminderSettingModel;
  remindersModel: ReminderSettingModel[];
  EditReminderDialog: boolean = false;

  constructor(
    private dialogueService: DialogService,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
    private formBuilder: FormBuilder,
    messageService: MessageService,
    private _cdr: ChangeDetectorRef
  ) {
    super(messageService);

    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
  }

  get fReminder() { return (<FormGroup>this.form.get('formReminder')).controls; }

  get formReminder() {
    return this.form.get('formReminder');
  }
  ngOnInit() {
    this.getBrightreeSettings();
    
    this.GetReminderType();
    this.GetReminderFrequency();
    this.buildForm();
  }

  private getReminderSettings() {

    this.loading = true;

    this.settingsService.getReminderSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.remindersModel = result.responseModel;
          this.remindersModel.forEach(y => {
            let selectedWipStatus = this.wipStatus.find(x => x.crmWipstatusNameSa == y.crmWipStatus);
            y.crmWipStatus = selectedWipStatus.crmWipstatusNameSa;
            if (selectedWipStatus.crmWipstatusNameSa == WipStatusForReminder.ReadyForScheduling) {
              y.crmWipStatusDisplayValue = 'Not Schedule Reminder ';
            }
            else if (selectedWipStatus.crmWipstatusNameSa == WipStatusForReminder.NoShow) {
              y.crmWipStatusDisplayValue = 'No Show Appointment Booking Reminder';
            }
            else if (selectedWipStatus.crmWipstatusNameSa == WipStatusForReminder.Cancel) {
              y.crmWipStatusDisplayValue = 'Cancelled Appointment Booking Reminder';
            }
            else if (selectedWipStatus.crmWipstatusNameSa == WipStatusForReminder.Booked) {
              y.crmWipStatusDisplayValue = 'Scheduled Appointment Reminder';
            }
          })
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }
  private async GetReminderType() {
    this.loading = true;
    await this.settingsService.getReminderType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<ReminderTypeModel[]>) => {
        this.processResult<any>(result, () => {
          this.reminderTypeModelOptions = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async GetReminderFrequency() {
    this.loading = true;
    await this.settingsService.getReminderFrequency()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<EnumModel[]>) => {
        this.processResult<any>(result, () => {
          this.reminderFrequencyTypeOptions = result.responseModel;
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private buildForm() {
    const formStatusGroup = this.formBuilder.group({});
    formStatusGroup.addControl(this.formFields.remindertype, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formStatusGroup.addControl(this.formFields.frequency, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    formStatusGroup.addControl(this.formFields.frequencyType, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.change }));
    formStatusGroup.addControl(this.formFields.brightreeStatus, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.change }));

    this.form = this.formBuilder.group({
      formReminder: formStatusGroup,
    });
  }
  onReminderAdd() {

    this.formReminderSubmitted = true;
    if (this.formReminder.invalid) {
      this.markFormAsTouched();
      return;
    }
    const wipstatusModel = this.getValuesFromStatusForm();
    let manageStatus = this.settingsService.AddReminderSetting(wipstatusModel);
    if (this.reminderEditMode) {
      wipstatusModel.crmReminderSettingId = this.selectedReminderModel.crmReminderSettingId;
      manageStatus = this.settingsService.UpdateReminderSetting(wipstatusModel);
    }

    this.loading = true;

    manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage(this.reminderEditMode ? 'Reminder information has been updated successfully.'
            : 'Reminder information has been added successfully.');
          this.selectedReminderModel = new ReminderSettingModel();
          this.reminderEditMode = false;
          this.resetValuesToReminderForm();
          this.getReminderSettings();
          this.eMedAssistReminder = !this.eMedAssistReminder;
          this.EditReminderDialog = false;
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });


  }

  onReminderCancel() {
    this.resetValuesToReminderForm();
    this.eMedAssistReminder = !this.eMedAssistReminder;
    this.EditReminderDialog = false;
  }
  onReminderEdit(ReminderModel: ReminderSettingModel) {
    this.EditReminderDialog = true;
    this.eMedAssistReminder = true;
    this.reminderBtnTitle = "Update";
    this.reminderEditMode = true;
    this.formReminder.reset();
    this.selectedReminderModel = ReminderModel;
    this.setValuesToStatusForm();


  }

  onStatusDelete(ReminderModel: ReminderSettingModel) {
    this.resetValuesToReminderForm();
    this.dialogueService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete Reminder '${ReminderModel.crmReminderType.replace("And", " & ")}'`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.loading = true;
      ReminderModel.isActive = false;
      ReminderModel.modifiedByUserInfoId = this.loggedInUserId;
      const manageStatus = this.settingsService.activeInactiveReminder(ReminderModel);
      manageStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            // this.getUser(this.userInfoId);
            this.showSuccessMessage('Reminder information has been deleted successfully.');
            this.getReminderSettings();

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  ReminderInfoButton() {
    this.reminderBtnTitle = "Add";
    this.resetValuesToReminderForm();
    this.eMedAssistReminder = !this.eMedAssistReminder;
    this.EditReminderDialog = true;
  }

  private getValuesFromStatusForm(): ReminderSettingModel {
    const form = this.formReminder;
    const reminderSettingModel = new ReminderSettingModel();

    reminderSettingModel.crmReminderType = this.selectedreminderTypeModelOptions.crmReminderType;
    reminderSettingModel.crmReminderFrequencyType = this.selectedreminderFrequencyTypeOptions.name;
    reminderSettingModel.crmReminderFrequency = Number(form.get(this.formFields.frequency).value);
    reminderSettingModel.crmWipStatus = this.selectedBrightreeStatus.crmWipstatusNameSa;
    reminderSettingModel.modifiedByUserInfoId = this.loggedInUserId;
    return reminderSettingModel;
  }

  private async setValuesToStatusForm() {
    if (!this.selectedReminderModel) {
      return;
    }
    const form = this.formReminder;
    this.selectedreminderFrequencyTypeOptions = new EnumModel;
    this.selectedreminderTypeModelOptions = new ReminderTypeModel();
    this.selectedBrightreeStatus = new WipStatus();

    let selectedFrequnceyType = this.reminderFrequencyTypeOptions.find(x => x.name == this.selectedReminderModel.crmReminderFrequencyType);
    if (selectedFrequnceyType)
      this.selectedreminderFrequencyTypeOptions = selectedFrequnceyType;

    let selectedReminderType = this.reminderTypeModelOptions.find(x => x.crmReminderType == this.selectedReminderModel.crmReminderType);
    if (selectedReminderType)
      this.selectedreminderTypeModelOptions = selectedReminderType;

    let selectedWipStatus = this.wipStatus.find(x => x.crmWipstatusNameSa == this.selectedReminderModel.crmWipStatus);
    if (selectedWipStatus) {
      this.selectedBrightreeStatus = selectedWipStatus;
    }

    form.get(this.formFields.frequency).setValue(this.selectedReminderModel.crmReminderFrequency);
    this._cdr.detectChanges();
  }

  resetValuesToReminderForm() {

    const form = this.formReminder;
    form.get(this.formFields.frequency).setValue('');
    form.get(this.formFields.frequencyType).setValue('');
    this.selectedreminderFrequencyTypeOptions = new EnumModel;
    this.selectedreminderTypeModelOptions = new ReminderTypeModel();
    form.get(this.formFields.remindertype).setValue('');
    form.get(this.formFields.brightreeStatus).setValue('');
    this.reminderEditMode = false;
    this.formReminderSubmitted = false;
  }

  private async getBrightreeSettings() {

    this.loading = true;

    await this.settingsService.GetAllWipStatus()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          let statusResponse = result.responseModel;
          this.wipStatus = []
          statusResponse.forEach(x => {
            if (x.crmWipstatusNameSa == WipStatusForReminder.ReadyForScheduling) {
              x.appAlias = 'Not Schedule Reminder ';
              this.wipStatus.push(x);
            }
            else if (x.crmWipstatusNameSa == WipStatusForReminder.NoShow) {
              x.appAlias = 'No Show Appointment Booking Reminder';
              this.wipStatus.push(x);
            }
            else if (x.crmWipstatusNameSa == WipStatusForReminder.Cancel) {
              x.appAlias = 'Cancelled Appointment Booking Reminder';
              this.wipStatus.push(x);
            }
            else if (x.crmWipstatusNameSa == WipStatusForReminder.Booked) {
              x.appAlias = 'Scheduled Appointment Reminder';
              this.wipStatus.push(x);
            }

          });
          this.getReminderSettings();
        });
      }, (httpResponseError) => {
        this.getReminderSettings();
        this.showErrorMessage(httpResponseError.message);
      });

  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
}
