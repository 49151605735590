<div class="search-patient-popup">
    <h2 class="title">Search Patient to Book an Appointment</h2>
    <p class="description">Find patient data from Brightree application.</p>

    <div class="from">
        <div class="form__group full-width">
            <p-radioButton name="group1" label="By Patient ID" (click)="SetEvents()" value="PatientByID"
                [(ngModel)]="selectedValue"></p-radioButton>
            <p-radioButton name="group1" label="By Patient Name & DOB" (click)="SetEvents()" value="PatientByName"
                [(ngModel)]="selectedValue"></p-radioButton>
        </div>

        <div class="search-by-patient-id" *ngIf="!isPatientByName">
            <div class="form__group">
                <label class="form__label">
                    Patient ID
                    <span>(6 numeric characters)</span>
                </label>
                <div class="search-box">
                    <input type="text" class="form__control" pInputText placeholder="xx0312" [(ngModel)]="patientID"
                        name="patientid" maxlength="6">
                    <button class="search-btn">
                        <i class="search-new"></i>
                    </button>
                </div>
            </div>
        </div>

        <div class="search-by-patient-name" *ngIf="isPatientByName">
            <div class="search-patient-name">
                <div class="form__group">
                    <label class="form__label">Patient First Name</label>
                    <input type="text" class="form__control" pInputText [(ngModel)]="firstName" name="firstName">
                </div>
                <div class="form__group">
                    <label class="form__label">Patient Last Name</label>
                    <input type="text" class="form__control" pInputText [(ngModel)]="lastName" name="lastName">
                </div>
                <div class="form__group">
                    <label class="form__label">Date of Birth</label>
                    <p-calendar appendTo="body" class="datepicker license-expiry-date" dateFormat="mm/dd/yy"
                        [yearNavigator]="true" [showIcon]="true" yearRange="1900:2050" [(ngModel)]="DateofBirth">
                    </p-calendar>
                </div>
            </div>
        </div>

        <div class="form__group btn-block">
            <button class="btn btn--primary" type="submit" (click)="SearchButton()">Search</button>
            <button class="btn btn--secondary" (click)="closePopup()">Cancel</button>
        </div>
    </div>
</div>
<div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
</div>
