import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyAvailabilityNewComponent} from './my-availability-new/my-availability-new.component';
import { DemoComponent } from './my-appointments/day-view-scheduler/component';
import { MyProfileComponent } from 'src/app/therapist/my-profile/my-profile.component';
import { MyChangePasswordComponent } from 'src/app/therapist/my-change-password/my-change-password.component';
import { AuthenticationGuard } from '../shared/guards/authentication.guard';
import { WeekDemoComponent } from './my-appointments/week-view-scheduler/component';

import { MyAppointmentsNewComponent } from './my-appointments-new/my-appointments-new.component';
import { WeekViewCalendarComponent } from './week-view-calendar/week-view-calendar.component';
import { WeekViewMobileComponent } from './week-view-mobile/week-view-mobile.component';

const routes: Routes = [
  {
    path: 'my-appointments-new',
    canActivate: [AuthenticationGuard],
    component: MyAppointmentsNewComponent
  },
  {
    path: 'my-appointments-week-view',
    canActivate: [AuthenticationGuard],
    component: WeekViewCalendarComponent
  },

  {
    path: 'my-appointments',
    canActivate: [AuthenticationGuard],
    component: DemoComponent
  },
  {
    path: 'my-availability',
    canActivate: [AuthenticationGuard],
    component: MyAvailabilityNewComponent
  },
  {
    path: 'my-profile',
    canActivate: [AuthenticationGuard],
    component: MyProfileComponent
  },
  {
    path: 'change-password',
    canActivate: [AuthenticationGuard],
    component: MyChangePasswordComponent
  },
  {
    path: 'mobile-week-view',
    canActivate: [AuthenticationGuard],
    component: WeekViewMobileComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'my-appointments-week',
    canActivate: [AuthenticationGuard],
    component: WeekDemoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TherapistRoutingModule { }
