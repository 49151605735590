<div class="mobile-week-view">
    <div class="mobile-week-view__top-block">
        <div class="mobile-week-view__title-block">
            <div class="mobile-week-view__calendar">
                <h2 class="mobile-week-view__title">My Calendar</h2>
                <div class="mobile-week-view__calendar-box">
                    <p-calendar class="datepicker" panelStyleClass="weekview-calenadr" inputId="basic"  
                    [(ngModel)]="HeaderDate" appendTo="body" [showIcon]="true" 
                    (onSelect)="onCalenderDateChange()" [readonlyInput]="true"></p-calendar>
                </div>
            </div>
            <a class="mobile-week-view__back-btn" href="javascript:void(0);" [routerLink]="['/therapist/my-appointments-new']">
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.6111 1.40283L1 4.90109L4.6111 8.39935" stroke="#b0a3ef" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 4.90106L6.77777 4.90106C10.7666 4.90106 14 8.0334 14 11.8976V12.5972" stroke="#b0a3ef" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Back
            </a>

          
        </div>
        
        <div class="mobile-week-view__date-block">
            <div class="mobile-week-view__date-block-center">
                <!-- (click)="getSelectedDateData(s.dates)" -->
                <a href="javascript:void(0);" class="mobile-week-view__prev-arrow" (click)="previousButtonClick()">
                    <svg width="104" height="184" viewBox="0 0 104 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M92 12L12 92L92 172" stroke="black" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                    
                </a>
                <div class="mobile-week-view__date-block-list navigation" id="mainNav">               
                    <div class="mobile-week-view__date-block-item date" *ngFor="let s of weekDateArr;let i = index">
                        <a [ngClass]="s.isActiveDate ? 'active' : ''" (click)="getSelectedDateData(s.dates)"
                            href="javascript:void(0);"
                            rel="{{s.dates|date:'YYYY'}}{{s.dates | date:'MM'}}{{s.dates |date:'dd'}}">
                            <span class="mobile-week-view__month-weeks">{{s.dates | date:'EEE'}}</span>
                            <h4 class="mobile-week-view__month-day">{{s.dates |date:'dd'}} </h4>
                            <!-- <span class="mobile-week-view__month-date">{{s.dates|date:'MMM'}}</span> -->
                        </a>
                    </div>
                </div>
                <a href="javascript:void(0);" class="mobile-week-view__next-arrow" (click)="nextButtonClick()">
                    <svg width="104" height="184" viewBox="0 0 104 184" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 12L92 92L12 172" stroke="black" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>                    
                </a>
            </div>
        </div>

        <div class="mobile-week-view__filter">
            <div class="week-view-filter-outer">
                <div class="week-view-filter-outer__left">
                    <span>{{selectedTimeZone}}</span>
                    <!-- <p-dropdown class="select-box" [options]="timeZone" [(ngModel)]="selectedTimeZone"
                        optionLabel="shortName" (onChange)="onTimeZoneChange($event)" appendTo="body"
                        panelStyleClass="my-calenadr-timezone-drop"></p-dropdown> -->
                </div>
                <div class="week-view-filter-outer__right">
                    <p-dropdown class="select-box" [options]="appointmentTypeResponseModel"
                        [(ngModel)]="selectedappointmentType" optionLabel="crmAppointmentTitle"
                        (onChange)="onAppointmentTypeChange($event)" appendTo="body"
                        panelStyleClass="my-calenadr-appt-drop"></p-dropdown>
                </div>
            </div>
        </div>

    </div>
    <!-- (scrolled)="onScrollDown()" 
            (scrolledUp)="onScrollUp()" -->
    <div class="mobile-week-view__appt-list">
        <section class="page-section" [ngClass]="w.isActiveDate ? 'on' : ''" *ngFor="let w of weekDateArr;let i = index"
            id="{{w.dates|date:'YYYY'}}{{w.dates | date:'MM'}}{{w.dates |date:'dd'}}">
            <div class="date-count">
                <h4 class="date-count__days">{{w.dates | date:'EEEE'}} -
                    <span>{{w.dates | date:appDateFormat.dateFormat}}</span>
                </h4>
            </div>
            <div class="week-view-appt-list" *ngFor="let s of mobileSchedulerList | myfilter:w.dates">

                <!-- <div class="week-view-appt-list__count-box">
                    <span class="week-view-appt-list__count">5</span>
                </div> -->

                <div class="week-view-appt-list__item" *ngIf="(mobileSchedulerList | myfilter : w.dates)?.length > 0">
                    <div class="week-view-appt-list__times">{{s.startTime|date:'shortTime'|lowercase}}</div>
                    <div class="week-view-appt-list__name-type">
                        <a class="week-view-appt-list__patient-name" href="javascript:void(0)"
                            (click)="onPatientDetailSidebarClick(s)">{{s.patientLastName}},
                            {{s.patientFirstName}}</a>
                        <div class="week-view-appt-list__appointment-duration">
                            <span class="week-view-appt-list__appointment-name">{{s.appointmentType}}</span>
                            <span class="week-view-appt-list__duration">{{s.duration}} minutes</span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="no-records-found-appointment" *ngIf="(mobileSchedulerList | myfilter : w.dates)?.length <= 0">
                No Appointments Available
            </div>
        </section>
    </div>
    <p-sidebar [(visible)]="patientDetailSidebar" [transitionOptions]="'300ms'" styleClass="patient-detail-sidebar"
        position="right" [baseZIndex]="99999">
        <app-patient-detail-sidebar [patientDetail]="patientSidebar"
            [salesOrderid]="salesOrderId"></app-patient-detail-sidebar>
    </p-sidebar>

</div>


<div class="loader-block" *ngIf="loading" style="z-index: 10000 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
</div>