import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './shared/login/login.component';
import { ForgotPasswordComponent } from './shared/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './shared/set-password/set-password.component';
//import { ChangePasswordComponent } from './shared/change-password/change-password.component';
import { MyProfileComponent } from './shared/my-profile/my-profile.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PageUnauthorizedComponent } from './shared/page-unauthorized/page-unauthorized.component';
import { SupportRequestComponent } from './shared/support-request/support-request.component';
import { SupportComponent } from './shared/support/support.component';
import { AuthenticationGuard } from '../app/shared/guards/authentication.guard';
import { importExpr } from '@angular/compiler/src/output/output_ast';

import { OnDemandSupportComponent } from './on-demand-support/on-demand-support.component';
import { NewPatientComponent } from './on-demand-support/new-patient/new-patient.component';
import { ThankyouComponent } from './on-demand-support/new-patient/thankyou/thankyou.component';
import { TwilloCallBackComponent } from './shared/twillo-call-back/twillo-call-back.component';
import { GoogleMapSharedComponent } from './shared/google-map-shared/google-map-shared.component';

const routes: Routes = [
  {
    path: 'on-demand-support',
    loadChildren: () => import('./on-demand-support/on-demand-support.module').then(m => m.OnDemandSupportModule)
  },
  {
    path: 'on-demand-support/landing',
    component: OnDemandSupportComponent,
  },

  {
    path: 'patient',
    //loadChildren: './patient/patient.module#PatientModule'
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
    
  },
  {
    path: 'therapist',
    loadChildren: () => import('./therapist/therapist.module').then(m => m.TherapistModule)
    //loadChildren: './therapist/therapist.module#TherapistModule'
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
    //loadChildren: './admin/admin.module#AdminModule'
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'set-password/:emailId',
    component: SetPasswordComponent,
  },
  {
    path: 'set-password/:emailId/:registrationstatus',
    component: SetPasswordComponent,
  },
  
  {
    path: 'my-profile',
    component: MyProfileComponent
  },
  {
    path: 'support',
    component: SupportRequestComponent
  },
  {
    path: 'support-request',
    component: SupportComponent
  },  
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'google-map',
    component: GoogleMapSharedComponent,
  },
  {
    path: '404',
    component: PageNotFoundComponent,
  },
  {
    path: '401',
    component: PageUnauthorizedComponent,
  },
  {
    path: 'recording-status-callback',
    component: TwilloCallBackComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
