export class SalesOrderLocalModel {
  salesOrderId: string;
  patientId: string;
  latitude: number;
  longitude: number;
  zipCode: string;
  patientName: string;
  patientAddress: string;
  patientEmail: string;
  patientPhone: string;
  patientMobile: string;
  DMENumber: string;
  domainUrl: string;
  crmClassificationDescription: string;
  crmClassificationDuration: number;
  strURL: string;
  branchid: number;
  crmDeliveryAddressTimeZone: string;
  crmBillingAddressTimeZone: string;
}

export class BranchInfoContactModel {
  DMENumber: string;
  DMEEmail: string;
  DMEBranch: string
  branchid: number;
}
