<div class="brightree-outer">
  <div class="status-mapping">
    <div class="add-buttons-for-license">

      <h3>Branch</h3>
      <div class="title-block__right">
        <a href="javascript:void(0);" class="filter-show-hide" (click)="QuickAction.toggle($event)" pTooltip="Actions"
          tooltipPosition="left">
          <svg width="21" height="5" viewBox="0 0 21 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
            <circle cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
            <circle cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
          </svg>
        </a>

        <p-overlayPanel #QuickAction appendTo="body" class="search-filter">
          <div class="quick-link-menu enable-disable-patient">
            <ul>
              <li><a class="invite-schedule" href="javascript:void(0)"
                  (click)="SelectedBranchSelfScheduleAppointmentStatus(true)">Enable Patient's Self Scheduling</a></li>
              <li><a class="invite-schedule" href="javascript:void(0)"
                  (click)="SelectedBranchSelfScheduleAppointmentStatus(false)">Disable Patient's Self Scheduling</a></li>
            </ul>
          </div>
        </p-overlayPanel>

        <a href="javascript:void(0);" pTooltip="Add Branch" tooltipPosition="left" class="add-plus"
          (click)="ClassificationInfoButton()">
          <img src="assets/img/add-plus.svg" width="17" alt="filters">
        </a>
      </div>
    </div>
    <div class="table-outer-main branchs-settings">
      <p-table #nsdl class="table-outer" [value]="branchResponseList" [scrollable]="true"
        [paginator]="totalRecords>0?true:false" sortMode="single" sortField="CrmBranchName"
        scrollHeight="calc(100vh - 297px)" responsiveLayout="scroll" [rows]="getTotalRows()"
        [(selection)]="selectedbranches" selectionMode="" [totalRecords]="totalRecords" [lazy]="true"
        [customSort]="true" (onLazyLoad)="onLazyLoad($event)">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn class="table-outer__th table-checkbox-column">
              <span class="table-checkbox">
                <p-tableHeaderCheckbox #headerCheckBox></p-tableHeaderCheckbox>
              </span>
            </th>
            <th pFrozenColumn class="table-outer__th" pSortableColumn="CrmBranchName">Branch Name <p-sortIcon
                field="CrmBranchName"></p-sortIcon></th>
            <th class="table-outer__th" width="200" pSortableColumn="CrmBranchContactNo">Phone Number <p-sortIcon
                field="CrmBranchContactNo"></p-sortIcon></th>
            <th class="table-outer__th" width="250" pSortableColumn="CrmBranchEmailAddress">Email <p-sortIcon
                field="CrmBranchEmailAddress"></p-sortIcon></th>
            <th class="table-outer__th brightreeid" width="120" pSortableColumn="CrmRcmBranchId">Brightree Id
              <p-sortIcon field="CrmRcmBranchId"></p-sortIcon></th>
            <th class="table-outer__th " width="200" pSortableColumn="CrmIsAllowSelfScheduleAppointments">Self
              Scheduling Permission <p-sortIcon field="CrmIsAllowSelfScheduleAppointments"></p-sortIcon></th>
            <th class="table-outer__th brightreeactive" width="80" pSortableColumn="IsActive">Active <p-sortIcon
                field="IsActive"></p-sortIcon></th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="80">Action</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-branchResponse>
          <!-- *ngFor="let branchResponse of branchResponseList" -->
          <tr>
            <td pFrozenColumn class="table-outer__td table-checkbox-column" style="vertical-align: middle;">
              <p-tableCheckbox [value]="branchResponse"></p-tableCheckbox>
            </td>
            <td pFrozenColumn class="table-outer__td">{{branchResponse.crmBranchName}}</td>
            <td class="table-outer__td">{{branchResponse.crmBranchContactNo}}</td>
            <td class="table-outer__td"><span class="text-ellipsis"
                pTooltip="{{branchResponse.crmBranchEmailAddress}}">{{branchResponse.crmBranchEmailAddress}}</span></td>
            <td class="table-outer__td brightreeid">{{branchResponse.crmRcmBranchId}}</td>
            <td class="table-outer__td">
              <span class="switchBtn">
                <p-inputSwitch id="{{branchResponse.crmIsAllowSelfScheduleAppointments}}"
                  [(ngModel)]="branchResponse.crmIsAllowSelfScheduleAppointments"
                  (onChange)="handleBranchSelfScheduleAppointmentStatusChange(branchResponse.crmBranchId,$event)">
                </p-inputSwitch>
              </span>
            </td>
            <td class="table-outer__td brightreeactive">{{branchResponse.isActive?'Yes':'No'}}</td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column" width="80">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)" (click)="op.toggle($event)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>
              <p-overlayPanel class="table-action-popup" appendTo="body" #op>
                <ul class="quick-link-menu">
                  <li>
                    <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                      (click)="onBranchEdit(branchResponse)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit
                    </a>
                  </li>
                  <li>
                    <a class="delete-row" href="javascript:void(0)" (click)="onBranchDelete(branchResponse)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete
                    </a>
                  </li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <ng-container *ngIf="!loading && totalRecords <= 0">
            <tr>
              <td class="no-records-found-therapist" [attr.colspan]="6">
                {{noRecordsFoundMessage}}
              </td>

            </tr>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>

    </div>
  </div>
  <div class="loader-block" *ngIf="loading" style="z-index: 10000 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
  <div class="loader-block" *ngIf="loadingClassification" style="z-index: 99999999 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</div>

<p-dialog [(visible)]="displayBranch" styleClass="display-close-icon" [modal]="true" [responsive]="true"
  appendTo="body">
  <div class="popup-content-outer">
    <h2>{{classificationBtnTitle}} Branch</h2>

    <form class="form form--classification" [formGroup]="formClassificationType">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Branch Name</label>
          <input type="text" formControlName="branchName" class="form__control" placeholder="Enter Branch Name"
            pInputText>
          <div *ngIf="formBranchSubmitted && fBranch.branchName.errors" class="invalid-feedback">
            <div *ngIf="fBranch.branchName.errors.required">
              {{validationMessages.branchName.required}}
            </div>
            <div *ngIf="fBranch.branchName.errors.validCharacters">
              {{validationMessages.branchName.validCharacters}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Brightree Id</label>
          <input type="text" formControlName="brightreeId" class="form__control" pInputText>
          <div *ngIf="formBranchSubmitted && fBranch.brightreeId.errors" class="invalid-feedback">
            <div *ngIf="fBranch.brightreeId.errors.validNumber">
              {{validationMessages.brightreeId.invalid}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Phone Number</label>
          <input type="text" formControlName="phoneNumber" class="form__control" placeholder="Enter Phone Number"
            pInputText>
          <div *ngIf="formBranchSubmitted && fBranch.phoneNumber.errors" class="invalid-feedback">
            <div *ngIf="fBranch.phoneNumber.errors.required || fBranch.phoneNumber.errors.whitespace">
              {{validationMessages.phoneNumber.required}}
            </div>
            <div *ngIf="fBranch.phoneNumber.errors.validPhoneNumber"> {{validationMessages.phoneNumber.invalid}}</div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Email</label>
          <input type="text" formControlName="email" class="form__control" placeholder="Enter Email Id" pInputText
            email="true">
          <div *ngIf="formBranchSubmitted && fBranch.email.errors" class="invalid-feedback">
            <div *ngIf="fBranch.email.errors.required">
              {{validationMessages.email.required}}
            </div>
            <div *ngIf="fBranch.email.errors.email"> {{validationMessages.email.email}}</div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Branch User</label>
          <!-- <span class="switchBtn" style="margin: 0 0 8px 1px;">
            <p-radioButton name="group1" [value]="false" label="All User" [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="userSelection">
            </p-radioButton>
            <p-radioButton name="group1" [value]="true" label="Choose User" [ngModelOptions]="{standalone: true}"
                               [(ngModel)]="userSelection">
            </p-radioButton>
          </span> -->
          <!-- <p *ngIf="!userSelection">All available facilites will be selected with this.</p> -->
          <p-multiSelect class="select-box"  panelStyleClass="branch-zindex" style=" margin-top: 1.2rem;" [(ngModel)]="selectedUsers"
                         [ngModelOptions]="{standalone: true}" optionLabel="label" [options]="users"
                         dataKey="label" placeholder="Select" 
                         selectedItemsLabel="{0} Users Selected" [filter]="true" appendTo="body">
          </p-multiSelect>
          <span class="invalid-feedback" *ngIf="isSubmitForm && selectedUsers?.length==0">{{validationMessages.user.required}}</span>
        </div>
        <div class="form__group" *ngIf="isViewSelfScheduleAppointments">
          <label class="form__label">Patient's Self Scheduling</label>
          <div style="width: 100%;" class="switchBtn">
            <p-inputSwitch [(ngModel)]="isAllowSelfScheduleAppointments"
              [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{
            isAllowSelfScheduleAppointments ? 'Active' :
            'Inactive'
            }}
          </div>
        </div>
        <div class="form__group" style=" padding-top: 2.5rem;">
          <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="isActive" label="Active"
            (onclick)="onCheck($event)">
          </p-checkbox>
        </div>
        <!-- <div class="form__group"></div> -->
        <div class="form__group button-box">
          <div class="btns">
            <button class="btn btn--primary" (click)="onBranchAdd()">{{classificationBtnTitle}}</button>
            <button class="btn btn--secondary" (click)="onBranchCancel()">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>