<section class="main-outer__content-outer therapist-module-outer old-rt">
  <!-- Start Header -->
  <app-therapist-header></app-therapist-header>
  <!-- End Header -->
  <!-- Start Content -->
  <div class="content-area">
    <div class="title-block">
      <div class="title-block__left">
        <h1>My Appointments</h1>
      </div>
      <div class="calendar-top-right" style="z-index:999 !important">
        <div class="calendar-top-right__pdf-csv">
          <!--<button class="btn btn--secondary" (click)="exportPDF()">Export PDF</button>
          <button class="btn btn--accent" (click)="exportCSV()">Export CSV</button>-->
          <button class="btn bg-transparent" (click)="exportPDF()" title="Export PDF"><img src="assets/img/pdf-ic.svg"
              width="28" height="28" title="Export PDF" alt="PDF"> PDF</button>
          <button class="btn bg-transparent" (click)="exportCSV()" title="Export CSV"><img src="assets/img/csv-ic.svg"
              width="22" height="22" title="Export CSV" alt="CSV"> CSV</button>
        </div>
        <button class="btn btn--primary" (click)="myAvailability()">My Availability</button>
        <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
          [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()"
          placeholder="Select Calendar View" optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>
      </div>
    </div>

    <div class="calendra-top-btn old-rt">
      <div class="btn-group calendra-next-prev-btn">
        <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="'day'" [(viewDate)]="viewDate"
          (viewDateChange)="GetAppointments()">
          <i class="arrow-prev"></i>
        </div>

        <div class="btn btn--primary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="GetAppointments()">
          Today
        </div>

        <div class="btn btn--arrow next" mwlCalendarNextView [view]="'day'" [(viewDate)]="viewDate"
          (viewDateChange)="GetAppointments()">
          <i class="arrow-next"></i>
        </div>
        <div class="calendar-day">
          <p-calendar class="datepicker therapist-select-date" dateFormat={{appDateFormat.primeFormat}}
            [(ngModel)]="viewDate" [locale]="en" [numberOfMonths]="1" [showIcon]="true" [readonlyInput]="true"
            (onSelect)="GetAppointments()">
          </p-calendar>
          <!-- <span class="weekday-span">{{ viewDate | calendarDate:('dayViewTitle') }}</span> -->
        </div>
      </div>
      <!-- <h3 class="facility-title">{{facilityInfo?.crmFacilityName }}</h3> -->
    </div>
    <ng-template #loading>
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>

    <div class="calendar-outer">
      <div class="admin-availability-calandar">
        <div class="admin-availabilty-calc">
          <div class="admin-availabilty-calc__left">
            <div class="therapist-availabilty-calc__left__block view-by-facility">

              <p-scrollPanel styleClass="therapist-module-calendar-scroll" [style]="{width: '100%', height: '100%'}">
                <div class="horizontal-scroll without-google-map" *ngIf="events$ | async; else loading; let events">
                  <mwl-day-view-scheduler [viewDate]="viewDate" [events]="events" [users]="users"
                    [salesOrderStatus]="salesOrderStatus"
                    (eventClicked)="handleEvent($event.view,'Clicked', $event.event)"
                    (eventClickedOverrided)="handleEvent($event.view,'Clicked', $event.event)" [refresh]="refresh"
                    [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour" [hourSegments]="4"
                    [patientBrighttreeURL]="patientBrighttreeURL" [salesOrderBrighttreeURL]="salesOrderBrighttreeURL"
                    [hourSegmentHeight]="60" [salesOrderStatusCurrent]="salesOrderStatusCurrent"
                    (onPopupSelection)="onPopupSelection($event.event,$event.event1)"
                    (onAppointmentCommentSelection)="onAppointmentCommentSelection($event.event)"
                    [canUpdateStatue]="canUpdateStatue" [facilityTimeZone]="facilityTimeZone">
                  </mwl-day-view-scheduler>
                </div>
              </p-scrollPanel>

            </div>
          </div>

          <!-- <div class="therapist-availabilty-calc__right therapist-information-right" *ngIf="appointment.schAppointmentId">
              <a class="link close-btn" href="javascript:void(0)" (click)="closeSideBar()">
                <img src="assets/img/popup-close-icon.svg" width="18" height="18" title="Close" title="Close">
              </a>
          </div> -->
        </div>
        <!-- </div> -->
      </div>
      <p-menu #updatemenu [popup]="true" class="update-status-menu view-by-calendar"
        [model]="appointmentsalesOrderStatusMenuitems"></p-menu>

      <div class="loader-block" *ngIf="Customloading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>

    </div>
  </div>

  <div class="landscape-msg">
    <p>Application does not support landscape mode</p>
  </div>

  <!-- End Content -->
  <!-- Start Footer -->
  <!-- <app-therapist-footer></app-therapist-footer> -->
  <!-- End Footer -->

  <style>
    .border {
      border-right: 1px solid #ccc;
      margin-right: 10px;
      padding-right: 10px;
    }

    .border:last-child {
      border: none;
    }
  </style>

  <div id="exportData" #exportData style="display:none; width: 1500px">

    <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;">
      <div style="display: flex; align-items: center; width: 80%;">
        <h2 style="font-size: 32px; margin-right: 3rem; margin-bottom: 0;">{{therapistName}}</h2>
        <div style="display: flex; width: 500px;" style="font-size: 20px;">
          <span class="border" *ngFor="let item1 of exportAppointmentCnt">
            {{item1.appointmentTitle}} : {{item1.appointmentCount}}
          </span>
        </div>
      </div>
      <h4 style="font-size: 26px;">{{viewDate | date:appDateFormat.dateFormat}}</h4>
    </div>
    <div>
      <table id="content" class="pdf-table" width="100%" height="100vh">
        <tr>
          <th width="230">Appointment Time</th>
          <th width="230">Patient Appointment Time</th>
          <th width="140">Sales Order Id</th>
          <th width="250">Patient Name / Patient Id</th>
          <th width="210">Classification</th>
          <th width="300">Location</th>
          <th width="200">Appointment Type</th>
          <th width="160">Status</th>
        </tr>
        <tr *ngFor="let item of exportDataRes">
          <td>
            {{item.dtStart | date:'shortTime' | lowercase}} to {{item.dtEnd | date:'shortTime' | lowercase}} |
            {{item.crmFacilityTimeZone}}
          </td>
          <td>
            {{item.crmPatientTimeZone}}
          </td>
          <td style="text-align: center;" *ngIf="item.isAppt">
            {{item.soId}}
          </td>
          <td [attr.colspan]="!item.isAppt ? '6' : ''">
            {{item.patientName}}
          </td>
          <td *ngIf="item.isAppt">
            {{item.classification}}
          </td>
          <td *ngIf="item.isAppt">
            {{item.location}}
          </td>
          <td *ngIf="item.isAppt">
            {{item.apptType}}
          </td>
          <td *ngIf="item.isAppt">
            {{item.status}}
          </td>
        </tr>
      </table>
    </div>
  </div>


  <style>
    .pdf-table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #e5e5e5;
      font-size: 17px;
    }

    .pdf-table th {
      height: 30px;
      border: 1px solid #e5e5e5;
      padding: 8px;
      vertical-align: middle;
      text-align: center;
      font-weight: 600;
      line-height: 24px;
    }

    .pdf-table td {
      border: 1px solid #e5e5e5;
      padding: 8px;
      vertical-align: top;
      text-align: left;
      line-height: 24px;
    }
  </style>

</section>

<p-overlayPanel class="table-action-popup rescheduleAppointment-popup" #op [showCloseIcon]="true">
  <div>
    Facility Address
  </div>
</p-overlayPanel>


<p-dialog appendTo="body" class="fill-slot-popup" [(visible)]="twoFacilityPopup" [modal]="true"
  *ngIf="appointment.schAppointmentId">
  <div class="select-options-facility">
    <div class="therapist-module-popup-detils">

      <div class="therapist-module-popup-detils__title-block">
        <h2 class="title">{{appointment?.salesOrderInfo?.patientInfo?.crmPatientLastName}},
          {{appointment?.salesOrderInfo?.patientInfo?.crmPatientFirstName}} </h2>
        <span class="date-of-birth">
          <i class="birthdate-new"></i>
          {{appointment?.salesOrderInfo?.patientInfo?.crmPatientBirthDate | date:appDateFormat.dateFormat}}
        </span>
      </div>

      <div class="therapist-availabilty-calc__right__block">
        <div class="therapist-availabilty__block">
          <div class="therapist-availabilty__block__sales-order sales-order-border">
            <span>
              <strong>Sales Order ID</strong>
              <a (click)="onNavigateBrightTreeSalesOrderUrl(appointment?.salesOrderInfo?.crmSalesorderAppId)"
                href="javascript:void(0)">{{appointment?.salesOrderInfo?.crmSalesorderAppId}}</a>

            </span>
            <span>
              <strong>Patient ID</strong>
              <a (click)="onNavigateBrightTreePatientUrl(appointment.salesOrderInfo?.patientInfo?.crmRcmBrightreeID)"
                href="javascript:void(0)">{{appointment?.salesOrderInfo?.crmPatientId}}</a>

            </span>
          </div>
          <ul class="therapist-availabilty__block__email-phone first">
            <li>
              <a href="mailto:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientEmailAddress}}">
                <i class="email-ic-new"></i>
                {{appointment?.salesOrderInfo?.patientInfo?.crmPatientEmailAddress}}
              </a>
            </li>
            <li>
              <a href="tel:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientMobileNumber}}">
                <i class="phone-icon-new-primary"></i>
                {{appointment?.salesOrderInfo?.patientInfo?.crmPatientMobileNumber }}
              </a>
            </li>
            <li>
              <a href="tel:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientPhoneNo}}">
                <i class="voice-call-icon-new-primary"></i>
                {{appointment?.salesOrderInfo?.patientInfo?.crmPatientPhoneNo }}
              </a>
            </li>

          </ul>
          <ul
            *ngIf="appointment?.salesOrderInfo?.crmSalesorderOrderNote || appointment?.salesOrderInfo?.crmSalesorderDeliveryNote"
            class="therapist-availabilty__block__email-phone">
            <li>
              <a *ngIf="appointment?.salesOrderInfo?.crmSalesorderOrderNote" class="link" href="javascript:void(0)"
                tooltip="{{appointment?.salesOrderInfo?.crmSalesorderOrderNote}}">
                Order Note
              </a>
            </li>
            <li>
              <a *ngIf="appointment?.salesOrderInfo?.crmSalesorderDeliveryNote" class="link" href="javascript:void(0)"
                tooltip="{{appointment?.salesOrderInfo?.crmSalesorderDeliveryNote}}">
                Delivery Note
              </a>
            </li>
          </ul>
        </div>
        <p-accordion class="accordion view-by-therapist">
          <p-accordionTab header="Appointment Information" [selected]="true">
            <div class="therapist-availabilty__block">
              <div class="therapist-availabilty__block__sales-order">
                <span><strong>Date</strong>{{appointment?.schAppointmentDateStartTime | date:appDateFormat.dateFormat
                  }}</span>
                <span>
                  <strong>Time</strong> {{appointment?.schAppointmentDateStartTime | date:'shortTime' |lowercase }} to
                  {{appointment?.schAppointmentDateEndTime | date:'shortTime' |lowercase }} | {{_patientTimezone}}
                </span>
                <span>
                  <strong>Duration</strong> <small class="hour">
                    {{duration}} <i class="info-new"
                      tooltip="{{appointment?.salesOrderInfo?.crmClassificationDescription}}"></i>
                  </small>
                </span>
                <span>
                  <strong *ngIf="appointment.schAppointmentType=='Facility Visit'">Facility</strong>
                  {{appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityName}}
                </span>
                <span *ngIf="appointment.schAppointmentType=='Home Visit'">
                  <strong>Home Address</strong>
                  <span *ngIf="appointment.schIsBillingAddress">
                    {{appointment?.salesOrderInfo?.patientInfo?.crmPatientAddress}}
                  </span>
                  <span *ngIf="appointment.schIsBillingAddress==false">
                    {{appointment?.salesOrderInfo?.crmPatientDeliveryAddress}}
                  </span>
                </span>
                <span *ngIf="appointment.schAppointmentType=='Facility Visit'">
                  <strong>Facility <br>Address</strong>
                  {{appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine1}}<br>
                  <ng-template *ngIf="appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine2">
                    {{appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine2}}
                  </ng-template>
                  <ng-template *ngIf="appointment?.appointmentDetailInfo?.facilityInfo?.crmCity">
                    {{appointment?.appointmentDetailInfo?.facilityInfo?.crmCity}}
                  </ng-template>
                  <ng-template *ngIf="appointment?.appointmentDetailInfo?.facilityInfo?.crmState">
                    {{appointment?.appointmentDetailInfo?.facilityInfo?.crmState}}
                  </ng-template>
                  {{appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityZipCode}}
                </span>
                <!--<button *ngIf="canUpdateStatue" class="btn btn--secondary" (click)="updatemenu.toggle($event)">Update Status </button>-->
                <!-- <span class="middel-center" *ngIf="canUpdateStatue">
    <strong>Status</strong>
    <p-dropdown class="select-box filter-status status-dropdown my-appointments" [options]="salesOrderStatusCurrent" [(ngModel)]="salesOrderSelectedStatusCurrent"
                placeholder="Select" optionLabel="wipStatesName" [showClear]="true"></p-dropdown>
  </span> -->
                <!-- <span *ngIf="canUpdateStatue">
    <strong>Notes</strong> -->
                <!--<input type="text" class="form__control" [(ngModel)]="comments" pInputText>-->
                <!-- <textarea class="form__textarea" pInputTextarea maxlength="500" [(ngModel)]="comments"></textarea>
  </span> -->
                <!-- <span *ngIf="canUpdateStatue">
    <strong>&nbsp;</strong>
    <button class="btn btn--primary" (click)="onStatusChange()">Update</button>
  </span> -->
                <!-- <div class="form__group" style="margin-top: 2rem;">
    <label class="form__label">Status</label>
    <p-dropdown *ngIf="canUpdateStatue" class="select-box filter-status status-dropdown my-appointments" [options]="salesOrderStatusCurrent" [(ngModel)]="salesOrderSelectedStatusCurrent"
                placeholder="Select" optionLabel="wipStatesName" [showClear]="true"></p-dropdown>

  </div> -->
                <!-- <div class="form__group" style="margin-top: 2rem;">
    <label class="form__label">Notes</label>
    <input type="text" class="form__control" [(ngModel)]="comments" pInputText>
  </div>
  <div class="form__group" style="margin-top: 2rem;">
    <label class="form__label"></label>
    <button class="btn btn--primary" (click)="onChange()">Update</button>
  </div> -->
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab *ngIf="appointment?.salesOrderInfo?.doctorInfo" header="Doctor Information">
            <div class="therapist-availabilty__block">
              <h3 class="scheduled-detials__left__block__title">
                {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorFirstName}}
                {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorLastName}}</h3>

              <!-- <h5 class="scheduled-detials__left__block__title">{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress}}</h5> -->
              <ul class="therapist-availabilty__block__email-phone"
                *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress">
                <li class="location-block">
                  <a href="javascript:void(0);" style="cursor: default; pointer-events: none;">
                    <i class="location-icon"></i>
                    {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress}}
                  </a>
                </li>
              </ul>
              <ul
                *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress || appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo"
                class="therapist-availabilty__block__email-phone mb-0">
                <li *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress">
                  <a href="mailto:{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress}}">
                    <i class="email-ic-new"></i>
                    {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress}}
                  </a>
                </li>
                <li *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo">
                  <a href="tel:{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo}}">
                    <i class="voice-call-icon-new-primary"></i>
                    {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo}}
                  </a>
                </li>
              </ul>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Device Information">
            <div class="therapist-availabilty__block">
              <div class="therapist-availabilty__block__sales-order">
                <span><strong>Classification</strong>{{appointment?.salesOrderInfo?.crmSalesorderClassification}}</span>
                <span><strong>Due Amount</strong> ${{appointment?.salesOrderInfo?.amountDue}}</span>
                <span
                  *ngIf="appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName"><strong>Insurance</strong>
                  {{ appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName}}</span>
                <span
                  *ngIf="!appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName"><strong>Insurance</strong>-</span>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Insurance &amp; Policy Information">
            <div class="therapist-availabilty__block therapist-availabilty__block--insurance">
              <p-tabView class="appointment-detail">
                <p-tabPanel [header]="item.crmPayorLevel"
                  *ngFor="let item of appointment.salesOrderInfo.salesOrderInsuranceInfo; let i = index"
                  [selected]="i == 0">
                  <div class="insurance-info">
                    <div class="insurance-info__block mb-0">
                      <span *ngIf="item.crmInsurancePolicyNumber"><strong>Policy No.</strong>
                        {{item.crmInsurancePolicyNumber}}</span>
                      <span *ngIf="!item.crmInsurancePolicyNumber"><strong>Policy No.</strong>-</span>
                      <span *ngIf="item.crmPolicyHolderName">
                        <strong>Company</strong>{{item.crmPolicyHolderName}}
                      </span>
                      <span *ngIf="!item.crmPolicyHolderName">
                        <strong>Company</strong>-
                      </span>
                      <span *ngIf="item.crmInsurancePolicyEffectiveDate"><strong>Effective Date</strong>
                        {{item.crmInsurancePolicyEffectiveDate | date:appDateFormat.dateFormat}}</span>
                      <span *ngIf="!item.crmInsurancePolicyEffectiveDate"><strong>Effective Date</strong>-</span>
                      <span><strong>Insurance Verified</strong>{{item.crmInsuranceVerified ? 'Yes' : 'No'}} </span>
                      <span><strong>Coverage Verified</strong> {{item.crmCoverageVerified ? 'Yes' : 'No'}} </span>
                    </div>
                  </div>
                </p-tabPanel>

              </p-tabView>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>

    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="displayCompleteAppointment" styleClass="display-close-icon old-rt-appointment-popup"
  [modal]="true" appendTo="body">
  <div class="popup-content-outer cancel-appointment">
    <h2>{{wipStatus}} Appointment</h2>
    <div class="schedulecall">

      <div class="schedulecall">
        <div class="col">
          <div class="form__group full-width">
            <label class="form__label">Are you sure you want to mark this appointment as {{wipStatus}} ?</label>
          </div>
        </div>
        <div class="col">
          <div class="check receive-reminder wip-state-will-not-update">
            <p-checkbox name="groupnamecomplete" label="If box checked, the WIP State will not be updated in Brightree"
              [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)"
              [ngModelOptions]="{standalone: true}">
            </p-checkbox>
          </div>
        </div>

        <div class="appointment-calcel-btn">
          <button class="btn btn--primary" (click)="CompleteNoShowAppointment()">Submit</button>
          <a href="javascript:void(0)" class="btn btn--secondary" (click)="hideCompletePopup()">Cancel</a>
        </div>
      </div>

    </div>
  </div>
</p-dialog>


<p-toast position="bottom-right"></p-toast>