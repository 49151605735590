import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BookingSlotDisplay, BookingSlotFrequency, ReportType } from 'src/app/shared/enums';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsService } from '../servicies/settings.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { Result } from 'src/app/shared/models/response/result';
import { SupportRequestModel } from 'src/app/shared/models/request/support-request-model';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import * as $ from 'jquery';
import { BrightreeSettingsResponseModel } from 'src/app/shared/models/response/brightree-settings-response-model';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.sass']
})
export class ReportsComponent extends BaseComponent implements OnInit {

  title = 'Reports';
  loading: boolean;
  private unsubscriber = new Subject<boolean>();
  IsKPIAccess: boolean;
  refreshGrid: boolean = false;
  loggedInUserId: number
  loggedInUserName: string
  loggedInuserEmail: string
  configResponseModel: ConfigResponseModel;
  companyId: number;
  companyName: string;
  brightreeSettingModel: BrightreeSettingsResponseModel;
  isVisibleBrightreeSalesOrderHistory: boolean;

  constructor(private router: Router, messageService: MessageService, private webStorageService: WebStorageService, private settingsService: SettingsService) {
    super(messageService);
    let BtSettings = webStorageService.getBrightreeSettings();
    const kpiAccess = this.webStorageService.getClientConfig();
    this.IsKPIAccess = kpiAccess.isKPIAccess;
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.loggedInUserName = loggedInUser.userFullName;
    this.loggedInuserEmail = loggedInUser.userEmail;
    this.configResponseModel = this.webStorageService.getClientConfig();
    this.companyId = Number(this.configResponseModel.companyId);
    this.companyName = this.configResponseModel.companyName;
    this.brightreeSettingModel = BtSettings;
  }


  ngOnInit() {

    this.isVisibleBrightreeSalesOrderHistory = false;
    if (this.brightreeSettingModel) {
      if (this.brightreeSettingModel.allowSystemToRetryToUpdateAppointment == true || this.brightreeSettingModel.allowSystemToRetryToUpdateAppointmentWIPState == true || this.brightreeSettingModel.allowSystemToRetrySyncSalesOrder == true)
        this.isVisibleBrightreeSalesOrderHistory = true;
    }

  }
  setReportTitle(IsKPI) {
    if (IsKPI) {
      this.title = 'Reports - Advanced KPI';
    }
    else {
      this.title = 'Reports';
    }

  }
  navigateToReportDetail() {
    this.router.navigate(['/admin/reports/detail']);
  }

  scheduledAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.ScheduledAppointmentReport]);
  }

  rescheduledAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.RescheduledAppointmentReport]);
  }

  cancelledAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.CancelledAppointmentReport]);
  }

  voidedAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.VoidedAppointmentReport]);
  }

  awaitingAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.AwaitingAppointmentReport]);
  }

  completedAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.CompletedAppointmentReport]);
  }

  unreconciledAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.UnreconciledAppointmentReport]);
  }

  bookedAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.BookedAppointmentReport]);
  }

  reconciledAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.ReconciledAppointmentReport]);
  }

  EmailPhoneMissingAppointmentsAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.EmailPhoneMissingAppointments]);
  }

  voiceCallLogReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.VoiceCallLogReport]);
  }

  saleOrderHistoryReport() {
    this.router.navigate(['/admin/reports/history/', ReportType.SalesOrderHistoryReport]);
  }

  noShowAppointmentReport() {
    this.router.navigate(['/admin/reports/detail/', ReportType.NoShowAppointmentReport]);
  }
  // calenderExportReport() {
  //   this.router.navigate(['/admin/reports/time-slot-availability-summary',ReportType.CalenderExport]);
  // }

  RequesttheAccessStatus: boolean = false;
  RequesttheAccess() {
    //this.RequesttheAccessStatus = !this.RequesttheAccessStatus;     
    var settingsGeneralRequestModel = new SupportRequestModel();

    settingsGeneralRequestModel.requestorName = this.loggedInUserName;
    settingsGeneralRequestModel.requestorEmail = this.loggedInuserEmail;
    settingsGeneralRequestModel.requestorPhone = null;
    settingsGeneralRequestModel.problemCategory = "KPI Report Access";
    settingsGeneralRequestModel.problemArea = "KPI Report Access";
    settingsGeneralRequestModel.problem = "KPI Report Access";
    settingsGeneralRequestModel.requestorPhone = null;
    settingsGeneralRequestModel.problemSnapshot = null;
    settingsGeneralRequestModel.problemSnapshotName = null;
    settingsGeneralRequestModel.problemSnapshotFileType = null;
    settingsGeneralRequestModel.companyId = this.companyId;
    settingsGeneralRequestModel.companyName = this.companyName;

    const manageCompany = this.settingsService.submitSupportRequest(settingsGeneralRequestModel);
    this.loading = true;
    manageCompany.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage('Your request has been submitted and one of our executive will get back to you');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
