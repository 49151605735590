<div class="form-content-outer__content confirm-popup-popup-height">
  <p-scrollPanel styleClass="confirm-popup-popup-height-scroll">
    <div class="confirm-appointment-popup">

      <div class="confirm-appointment-popup__left">
        <h2>Book Appointment Details</h2>

        <div class="appointment-type">
          <span>Appointment Type :</span>
          {{appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'?'Remote Setup':appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Home Visit'?'Road/Home Visit':appointmentConfirmationInfo?.selectedAppointmentTypePopUp}}
          {{appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'? ' - '+ appointmentConfirmationInfo?.schRemoteAppointmentTypeDisplayName:''}}
          <small>
            &nbsp;|&nbsp; {{crmPatientTimezone | timezone}}
          </small>
        </div>
        <div class="appointment-details">
          <div class="appt-details">
            <div class="appt-details__row">
              <div class="appt-details__column">
                <div class="appt-details__block appt-details__block--date-time">
                  <label class="appt-details__label">{{appointmentConfirmationInfo?.isRescheduledRequest?'Rescheduled':'Scheduled'}} Date & Time</label>
                  <div class="appt-details__group">
                    {{appointmentConfirmationInfo?.appointmentStartDate | date: appDateFormat.dateFormat}}
                    {{appointmentConfirmationInfo?.appointmentStartDate | date: 'hh:mm a' | lowercase }}
                    <small>
                      &nbsp;|&nbsp; {{crmPatientTimezone | timezone}}
                    </small>
                  </div>
                </div>

                <div class="appt-details__block last"
                     *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Facility Visit'">
                  <label class="appt-details__label">Facility</label>
                  <div class="appt-details__group">
                    <p class="crmfacilityname">{{appointmentConfirmationInfo?.facilityName}}</p>
                    <span class="appt-details__miles">{{appointmentConfirmationInfo?.distance}}</span>
                    <p>{{appointmentConfirmationInfo.facilityAddress}}</p>
                  </div>
                </div>
                <div class="appt-details__block last"
                     *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Home Visit'">
                  <label class="appt-details__label">Road/Home Visit</label>
                  <div class="appt-details__group">
                    <p *ngIf="appointmentConfirmationInfo?.isBillingAddress">
                      {{salesOrder?.patientInfo?.crmPatientAddress || '-'}}
                    </p>
                    <p *ngIf="appointmentConfirmationInfo?.isBillingAddress==false">
                      {{salesOrder?.crmPatientDeliveryAddress || '-'}}
                    </p>
                  </div>
                </div>
                <div class="appt-details__block last"
                     *ngIf="appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'">
                  <label class="appt-details__label">Remote Facility</label>
                  <div class="appt-details__group">
                    <p>{{appointmentConfirmationInfo?.facilityName}}</p>
                  </div>
                </div>

              </div>

              <div class="appt-details__column">
                <div class="appt-details__block appt-details__block--duration">
                  <label class="appt-details__label">Duration</label>
                  <div class="appt-details__group">
                    <small class="hour">
                      {{appointmentConfirmationInfo?.duration}} &nbsp;<i class="info-new"
                                                                         pTooltip="{{salesOrder?.crmClassificationDescription}}"></i>
                    </small>
                  </div>
                </div>

                <div class="appt-details__block ">
                  <label class="appt-details__label">Therapist</label>
                  <div class="appt-details__group">
                    {{appointmentConfirmationInfo?.crmTherapistname}}
                    <!-- <p class="appt-details__group__id">#{{appointmentConfirmationInfo?.crmPractionerId}}</p> -->

                  </div>
                </div>
                <div class="appt-details__block last">
                  <label class="appt-details__label">Classification Type</label>
                  <div class="appt-details__group">
                    <p>{{salesOrder?.crmClassification}}</p>
                  </div>
                </div>

              </div>
            </div>




          </div>
          <!--<div class="appt-details last" *ngIf="isPhoneNumberExist">-->
          <!-- <div class="appt-details__block appt-details__block--modifier border-none">
            <label class="appt-details__label">Select Patient’s SMS Number</label>
            <div class="appt-details__group">
              <div class="patient-number">
                <p-radioButton *ngIf="salesOrder?.patientInfo?.crmPatientPhoneNo" name="group3" class="phone-number"
                               [(ngModel)]="smsTextNumber" value="{{salesOrder?.patientInfo?.crmPatientPhoneNo}}"
                               label="{{salesOrder?.patientInfo?.crmPatientPhoneNo | phone}}"></p-radioButton>
                <p-radioButton *ngIf="salesOrder?.patientInfo?.crmPatientMobileNumber" name="group3" class="mobile-number"
                               [(ngModel)]="smsTextNumber" value="{{salesOrder?.patientInfo?.crmPatientMobileNumber}}"
                               label="{{salesOrder?.patientInfo?.crmPatientMobileNumber | phone}}"></p-radioButton>
              </div>
            </div>
          </div> -->
          <!--<div class="appt-details__block appt-details__block--modifier patient-checkbox border-none">
              <label class="appt-details__label">Patient will receive reminder prior to their appointment.</label>
              <div class="appt-details__group">
                <div class="check receive-reminder">
                  <p-checkbox name="groupname" [(ngModel)]="reminderOpted"
                              label="Click here if patient wants to opt out to reminders" binary="true"> </p-checkbox>
                </div>
              </div>
            </div>
          </div>-->
          <div class="appt-details last">
            <div class="appt-details__block appt-details__block--modifier patient-checkbox border-none">
              <label class="appt-details__label">Preferred Patient communication:</label>
              <div class="opt-out-reminders">
                <div class="check receive-reminder mr-15">
                  <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="SMS"
                                 value="SMS" [(ngModel)]="schIsReminderOptedType">
                  </p-radioButton>
                  <label for="SMS">SMS</label>
                </div>
                <div class="check receive-reminder mr-15">
                  <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="Email"
                                 value="Email" [(ngModel)]="schIsReminderOptedType">
                  </p-radioButton>
                  <label for="Email">Email</label>
                </div>
                <div class="check receive-reminder mr-15">
                  <p-radioButton name="preferredpatientcommunication" class="mobile-number" inputId="Both"
                                 value="Both" [(ngModel)]="schIsReminderOptedType">
                  </p-radioButton>
                  <label for="Both">Both (SMS & Email)</label>
                </div>
              </div>

              <div class="appt-details__group">
                <div class="check receive-reminder">
                  <p-checkbox name="groupname" [(ngModel)]="schReminderOpted"
                              label="Click here if patient wants to opt out to reminders" binary="true"> </p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="confirm-appointment-popup__right">
        <h2>Update Brightree Details</h2>
        <div class="appointment-details">
          <div class="appt-details">
            <div class="appt-details__block">
              <label class="appt-details__label appt-details__label--modifier">
                Scheduled Date
              </label>
              <div class="appt-details__group" [ngClass]="ScheduledDateStatus ? 'edit-box' : ''">
                <span class="datepikar">
                  <p-calendar class="start-date" [showIcon]="true"
                              [(ngModel)]="appointmentRequestBT.ScheduledDeliveryStartDate" placeholder="MM/DD/YY"
                              [ngModelOptions]="{standalone: true}"></p-calendar>
                </span>
                <div class="save-info">
                  <a class="save-icon" href="javascript:void(0);" (click)="SaveScheduledDate()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                    </svg>
                  </a>
                  <a class="cancel-icon" href="javascript:void(0);" (click)="CancelScheduledDate()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                      <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="appt-details__block">
              <label class="appt-details__label appt-details__label--modifier">
                Scheduled Time
                <!-- <span class="edit-info" [ngClass]="ScheduledTimeStatus ? 'edit-box' : ''">
                  <a class="edit-icon" href="javascript:void(0);" (click)="ScheduledTime()">
                    <i class="edit-icon-new"></i>
                  </a>
                </span> -->
              </label>
              <div class="appt-details__group scheduledtime" [ngClass]="ScheduledTimeStatus ? 'edit-box' : ''">
                <!--<ngx-timepicker-field class="time-control"
                                      [(ngModel)]="appointmentRequestBT.ScheduledDeliveryStartTimeString" [format]="12" minutesGap="15"
                                      [controlOnly]="true">
                </ngx-timepicker-field>-->
                <p> {{appointmentConfirmationInfo?.appointmentStartDate | date: 'hh:mm a' | lowercase }}</p>
                <div class="save-info">
                  <a class="save-icon" href="javascript:void(0);" (click)="SaveScheduledTime()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                    </svg>
                  </a>
                  <a class="cancel-icon" href="javascript:void(0);" (click)="CancelScheduledTime();">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                      <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="appt-details__block">
              <label class="appt-details__label appt-details__label--modifier">
                WIP State
                <span class="edit-info" [ngClass]="WIPStateStatus ? 'edit-box' : ''">
                  <a class="edit-icon" href="javascript:void(0);" (click)="WIPStateClick()">
                    <i class="edit-icon-new"></i>
                  </a>
                </span>
              </label>
              <div class="appt-details__group" [ngClass]="WIPStateStatus ? 'edit-box' : ''">
                <p-dropdown class="select-box" #DropdownMenu [options]="distinctWipStatusBTMappingResponseModel"
                            placeholder="Select" optionLabel="crmWipstatusNameBt"
                            [(ngModel)]="selectedwipStatusBTMappingResponseModel" [showClear]="true" appendTo="body"></p-dropdown>
                <div class="save-info">
                  <a class="save-icon" href="javascript:void(0);" (click)="SaveWIPStateClick()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                    </svg>
                  </a>
                  <a class="cancel-icon" href="javascript:void(0);" (click)="CancelWIPStateClick()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                      <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div class="appt-details__block">
              <label class="appt-details__label appt-details__label--modifier">
                WIP Date Needed
                <span class="edit-info" [ngClass]="WIPDateNeededStatus ? 'edit-box' : ''">
                  <a class="edit-icon" href="javascript:void(0);" (click)="WIPDateNeededClick()">
                    <i class="edit-icon-new"></i>
                  </a>
                </span>
              </label>
              <div class="appt-details__group" [ngClass]="WIPDateNeededStatus ? 'edit-box' : ''">
                <span class="datepikar">
                  <p-calendar class="start-date" [showIcon]="true" placeholder="MM/DD/YY"
                              [(ngModel)]="appointmentRequestBT.WIPDateNeeded" [ngModelOptions]="{standalone: true}"></p-calendar>
                </span>
                <div class="save-info">
                  <a class="save-icon" href="javascript:void(0);" (click)="SaveWIPDateNeededClick()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                    </svg>
                  </a>
                  <a class="cancel-icon" href="javascript:void(0);" (click)="CancelWIPDateNeededClick()">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                      <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div class="appt-details__block" *ngIf="crmInvetoryLocationName">
              <label class="appt-details__label appt-details__label--modifier">
                Inventory Location
              </label>
              <div class="appt-details__group scheduledtime">
                <p> {{crmInvetoryLocationName}}</p>
              </div>
            </div>

            <div class="appt-details__block full-width order-note">
              <label class="appt-details__label appt-details__label--modifier">
                Order Note
                <span class="edit-info">
                  <a class="edit-icon" href="javascript:void(0);" (click)="showDialogOrderNote()">
                    <i class="edit-icon-new"></i>
                  </a>
                </span>
              </label>
              <div class="address-content-scroll">
                {{appointmentRequestBT?.OrderNote}}
              </div>
            </div>
            <div class="appt-details__block full-width delivery-note">
              <label class="appt-details__label appt-details__label--modifier">
                Delivery Note
                <span class="edit-info">
                  <a class="edit-icon" href="javascript:void(0);" (click)="showDialogDeliveryNote()">
                    <i class="edit-icon-new"></i>
                  </a>
                </span>
              </label>
              <div class="address-content-scroll">
                {{appointmentRequestBT?.DeliveryNote}}
              </div>
            </div>

            <div class="appt-details__block full-width email-mb-2">
              <label class="appt-details__label appt-details__label--modifier">
                Email Address
                <span class="edit-info" [ngClass]="brightreeEmailAddress ? 'edit-box' : ''">
                  <a class="edit-icon" href="javascript:void(0);" (click)="brightreeEmailClick(false)">
                    <i class="edit-icon-new"></i>
                  </a>
                </span>
              </label>
              <div class="appt-details__group" [ngClass]="brightreeEmailAddress ? 'edit-box' : ''">
                <input type="text" class="controls input-text-box" [(ngModel)]="patientEmail"
                       pInputText />
                <div class="save-info">
                  <a class="save-icon" href="javascript:void(0);" (click)="brightreeEmailClick(true)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path fill="#6F64A7"
                            d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                    </svg>
                  </a>
                  <a class="cancel-icon" href="javascript:void(0);" (click)="brightreeEmailClick(false)">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                      <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div *ngIf="salesOrder?.patientInfo?.crmPatientPhoneNo || salesOrder?.patientInfo?.crmPatientMobileNumber"
                 class="appt-details__block appt-details__block--modifier border-none" style="padding: 0 0 8px 0;">
              <label class="appt-details__label">Select Patient’s SMS Number</label>
            </div>

            <div class="appt-details__block phone-number1" *ngIf="salesOrder?.patientInfo?.crmPatientMobileNumber">
              <div class="sms-redio-btn">
                <p-radioButton *ngIf="salesOrder?.patientInfo?.crmPatientMobileNumber" name="UpdateNumber" class="mobile-number"
                               value="{{crmPatientMobileNumber}}" [(ngModel)]="appointmentRequestBT.PhoneNumber" (click)="UpdateBrightTreePhoneNo(true,crmPatientMobileNumber)">
                </p-radioButton>

                <div class="input-edit-table" [ngClass]="brightreePhone2 ? 'edit-box' : ''">
                  <i class="phone-icon-new-primary"></i>

                  <p-inputMask mask="999-999-9999" placeholder="___-___-____" [(ngModel)]="crmPatientMobileNumber" class="form__phone" pInputText maxlength="15"></p-inputMask>

                  <div class="action-icon">
                    <a class="edit-icon" href="javascript:void(0);" (click)="OverwriteBTPatientMobileNoClick(crmPatientMobileNumber,false)">
                      <i class="edit-icon-new"></i>
                    </a>
                    <a class="save-icon" href="javascript:void(0);" (click)="OverwriteBTPatientMobileNoClick(crmPatientMobileNumber,true)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#6F64A7"
                              d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                      </svg>
                    </a>
                    <a class="cancel-icon" href="javascript:void(0);" (click)="OverwriteBTPatientMobileNoClick(crmPatientMobileNumber,false)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                        <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="appt-details__block phone-number2  mb-0" *ngIf="salesOrder?.patientInfo?.crmPatientPhoneNo">

              <div class="sms-redio-btn">
                <p-radioButton *ngIf="salesOrder?.patientInfo?.crmPatientPhoneNo" name="UpdateNumber" class="phone-number"
                               value="{{crmPatientPhoneNumber==crmPatientMobileNumber?'':crmPatientPhoneNumber}}" [(ngModel)]="appointmentRequestBT.PhoneNumber" (click)="UpdateBrightTreePhoneNo(false,crmPatientPhoneNumber)">
                </p-radioButton>

                <div class="input-edit-table" [ngClass]="brightreePhone1 ? 'edit-box' : ''">
                  <i class="voice-call-icon-new-primary"></i>
                  <p-inputMask mask="999-999-9999" placeholder="___-___-____" [(ngModel)]="crmPatientPhoneNumber" class="form__phone"
                               pInputText maxlength="15"></p-inputMask>


                  <div class="action-icon">
                    <a class="edit-icon" href="javascript:void(0);" (click)="OverwriteBTPatientPhoneNoClick(crmPatientPhoneNumber,false)">
                      <i class="edit-icon-new"></i>
                    </a>

                    <a class="save-icon" href="javascript:void(0);" (click)="OverwriteBTPatientPhoneNoClick(crmPatientPhoneNumber,true)">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#6F64A7"
                              d="M21.21,5.29a1,1,0,0,0-1.42,0L8.5,16.59l-4.29-4.3a1,1,0,0,0-1.42,1.42l5,5a1,1,0,0,0,1.42,0l12-12A1,1,0,0,0,21.21,5.29Z" />
                      </svg>
                    </a>
                    <a class="cancel-icon" href="javascript:void(0);" (click)="OverwriteBTPatientPhoneNoClick(crmPatientPhoneNumber,false)">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.8755 2.17661L2.12453 13.8234" stroke="#6F64A7" stroke-width="2" />
                        <path d="M13.8232 13.8755L2.17637 2.12453" stroke="#6F64A7" stroke-width="2" />
                      </svg>
                    </a>
                  </div>

                </div>
              </div>


            </div>
          </div>

          <div *ngIf="!isBrightreeSystemAppUpdate" class="appt-details last" style="margin-top: -5px;">
            <div class="appt-details__block appt-details__block--modifier" style="border: none;">
              <div class="appt-details__group">
                <div class="check receive-reminder">
                  <p-checkbox name="groupname" label="If box checked, the items above will NOT be updated in Brightree"
                              [(ngModel)]="appointmentRequestBT.IsUpdateToBrightree" binary="true"
                              (onChange)="onBTSelect($event)">
                  </p-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>
<!-- footer part -->
<div class="book-appt-outer-block__footer">
  <div class="book-appt-outer-block__footer__left">

  </div>
  <div class="book-appt-outer-block__footer__right">
    <!-- <button class="btn btn--primary btn-next" [ngClass]="showConfirm?'btn btn--primary btn-next':'btn btn--primary btn-next disbaled'" (click)="onBookAppointment()">Book Facility Visit <i class="ic btn-arrow-right"></i></button> -->
    <!--<button class="btn btn--confirm btn-next" (click)="onBookAppointment()"><i class="btn-check-arrow"></i> Book {{appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Telehealth'?'Remote Setup':appointmentConfirmationInfo?.selectedAppointmentTypePopUp=='Home Visit'?'Road/Home Visit':appointmentConfirmationInfo?.selectedAppointmentTypePopUp}}</button>-->
    <button class="btn btn--confirm btn-next" (click)="onBookAppointment()">
      <i class="btn-check-arrow"></i>Confirm Appointment
    </button>
  </div>
</div>
<!-- <div class="confirm-appointment-popup__btns">
  <button [ngClass]="showConfirm?'btn btn--primary btn-next':'btn btn--primary btn-next disbaled'"
    (click)="onBookAppointment()">
    Confirm Appointment
  </button>
</div> -->
<!-- Order Note Popup-->
<p-dialog [(visible)]="displayOrderNote" styleClass="display-close-icon" [modal]="true" appendTo="body" [baseZIndex]="9999999">

  <div class="search-box-outer delivery">
    <h2 class="filter-title" style="padding: 0;">Order Note</h2>
    <div class="form__group order-note">
      <textarea class="form__textarea" maxlength="8000" [(ngModel)]="appointmentRequestBT.OrderNote"
                pInputTextarea></textarea>
    </div>
    <div class="btn-block app-not-scheduled-appointments-btn">
      <button class="btn btn--primary" (click)="SaveDialogOrderNote()">Save</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)" (click)="CancelDialogOrderNote()">
        Cancel
      </a>
    </div>
  </div>
</p-dialog>
<!-- Delivery Note Popup-->
<p-dialog [(visible)]="displayDeliveryNote" styleClass="display-close-icon" [modal]="true" appendTo="body" [baseZIndex]="9999999">
  <div class="search-box-outer delivery">
    <h2 class="filter-title" style="padding: 0;">Delivery Note</h2>
    <div class="form__group order-note">
      <textarea class="form__textarea" maxlength="500" [(ngModel)]="appointmentRequestBT.DeliveryNote"
                pInputTextarea></textarea>
    </div>
    <div class="btn-block app-not-scheduled-appointments-btn">
      <button class="btn btn--primary" (click)="SaveDialogDeliveryNote()">Save</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)" (click)="CancelDialogDeliveryNote()">
        Cancel
      </a>
    </div>
  </div>
</p-dialog>
<!-- Thank Page -->
<div class="thankyou-page-outer" [ngClass]="thankyouPage ? 'active' : 'inactive'">
  <app-appointment-thankyou [appointmentConfirmationInfo]="appointmentConfirmationInfo" [salesOrder]="salesOrder"
                            [twillioDeviceConnectionObject]="twillioDeviceConnectionObject" (appintmentBooked)="appintmentBookedHandler()">
  </app-appointment-thankyou>
</div>


<div class="loader-block" *ngIf="loading" style="z-index: 99999999 !important;">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
<div class="loader-block" *ngIf="loadingCustom" style="z-index: 99999999 !important;">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
