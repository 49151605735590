<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>On Boarding</h1>
    </div>
  </div>

  <div class="sync-page-outer">
    <div class="sync-page-outer__import">
      <!-- <h2>On Boarding</h2> -->
      <form class="form" [formGroup]="form" autocomplete="off">
        <div class="col">
          <div class="sync-page-outer__block">
            <div class="from__group">
              <label class="form__label">Appointment</label>
              <div class="import__block__left__column-right">
                <button class="btn btn--download" (click)="downloadAppoitmentTemplate('AppointMentTemplate')">
                  <i class="ic download"></i>
                  Download Template
                </button>
                <div class="browse-import-btn">
                  <div class="radios" style="margin-bottom:10px">
                    <p-radioButton formControlName="appointmentTemplateDataSelection" value="True"
                      label="Reimport Data">
                    </p-radioButton>
                    <p-radioButton formControlName="appointmentTemplateDataSelection" value="False" label="Append Data">
                    </p-radioButton>
                  </div>
                  <div>
                    <input class="browse-btn" type="file" #appointmentFile placeholder="Choose file"
                      style="display:none;"
                      (change)="uploadAppointmentFile($event, appointmentFile.files,'AppointMentTemplate')">
                    <button type="button" class="btn btn--primary" (click)="appointmentFile.click()">Browse</button>
                    <button class="btn btn--primary"
                      (click)="onAppointmentImport($event, appointmentFile.files,'AppointMentTemplate')"
                      [disabled]="!disableAppointmentVerifyBtn">
                      Verify
                    </button>
                    <button class="btn btn--primary" (click)="processRecords('AppointMentTemplate')"
                      [disabled]="!disableAppointmentProcessBtn">
                      Process
                    </button>
                  </div>
                  <div class="facilityUploadMessage"> <!--{{doctorUploadMessage}}--></div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>

    <div class="on-boarding-table">
      <div class="table-outer-main">
        <h3>OnBoarding History</h3>
        <div class="on-boarding-table-table">
          <p-table class="table-outer" #nsdl [value]="historyModel" [paginator]="totalRecords>0?true:false"
            [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="1" [rows]="getTotalRows()"
            [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)"
            sortMode="single" sortField="app_OnboardId" scrollHeight="calc(100vh - 297px)" responsiveLayout="scroll">
            <ng-template pTemplate="header">
              <tr>
                <th class="table-outer__th" width="600" pSortableColumn="app_UploadFile">Upload File Name<p-sortIcon field="UploadFilePath"></p-sortIcon></th>
                <th class="table-outer__th" width="600" pSortableColumn="app_LogFile">Log File Name<p-sortIcon field="LogFilePath"></p-sortIcon></th>
                <th class="table-outer__th" pSortableColumn="dateTimeCreated">Process Date<p-sortIcon field="processdate"></p-sortIcon></th>
                <th class="table-outer__th verifydate" pSortableColumn="app_SucessCount">Verify Data Count<p-sortIcon field="SucessCount"></p-sortIcon></th>
                <th class="table-outer__th verifydate" pSortableColumn="app_BadCount">Bad Data Count<p-sortIcon field="BadCount"></p-sortIcon></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-history>
              <tr>
                <td class="table-outer__td">
                  <span class="link">
                    <a href="javascript:void(0)" (click)="downloadFile(history.uploadFile,'upload')">
                      {{history.uploadFile}}
                    </a>
                  </span>
                </td>
                <td class="table-outer__td">
                  <span class="link">
                    <a href="javascript:void(0)" (click)="downloadFile(history.logFile,'log')">
                      {{history.logFile}}
                    </a>
                  </span>
                </td>
                <td class="table-outer__td">
                  <span class="processDate">
                    {{history.dateTimeCreated | date:appDateFormat.dateFormat}}
                    {{history.dateTimeCreated | date:'shortTime':appDateFormat.timeZone | uppercase}}
                  </span>
                </td>
                <td class="table-outer__td verifydate">
                  <span class="sucessCount">
                    {{history.sucessDataCount}}
                  </span>
                </td>
                <td class="table-outer__td verifydate">
                  <span class="badCount">
                    {{history.badDataCount}}
                  </span>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr class="primeng-table__body">
                <td colspan="4" class="text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="paginatorleft" class="table-pagination">
              <span class="table-pagination__records">
                Total Records : &nbsp;<strong> {{totalRecords}}</strong>
              </span>
            </ng-template>
          </p-table>
          <div class="loader-block" *ngIf="loading">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
              animationDuration=".8s">
            </p-progressSpinner>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>