export class FacilityInfoResponseModel {
  crmFacilityId: number;
  crmFacilityName: string;
  crmFacilityAddress: string;
  crmFacilityZipCode: string;
  crmFacilityContactPersonFirstName: string;
  crmFacilityContactPersonLastName: string;
  crmFacilityContactEmailAddress: string;
  crmFacilityContactNo: string;
  crmFacilityStartTime: number;
  crmFacilityEndTime: number;
  crmAddressLine1: string;
  crmAddressLine2: string;
  crmCity: string;
  crmState: string;
  crmCountry: string;
  isRemoteAssistance: boolean;
  crmFacilityLatitude: number;
  crmFacilityLongitude: number;
}  
