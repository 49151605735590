import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GetPatientDetailsRequestModel } from 'src/app/shared/models/request/get-patient-details-request';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SalesOrderResponseModel } from 'src/app/shared/models/response/sales-order-response';
import { BaseService } from 'src/app/shared/services/base.service';
import { WebStorageService } from '../../shared/services/web-storage.service';
@Injectable({
  providedIn: 'root',
})
export class SalesOrderService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getSalesOrderDetails(salesOrder: string) {
    return this.get<ResultOfT<SalesOrderResponseModel>>('Patient/getsalesorderbyid?SalesOrderId=' + salesOrder);
  }
  getSalesOrderId(refernce: GetPatientDetailsRequestModel) {
    return this.get<ResultOfT<GetPatientDetailsRequestModel>>('authentication/patientlink?strLink=' + refernce.strLink);
  }

  AddTelehealthSalesOrder(SalesOrderId: string, strType: string) {
    return this.get<ResultOfT<boolean>>('appointment/AddTelehealthSalesOrder/' + SalesOrderId + '/' + strType);;
  }
}
