import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model';
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';
import { SchedulerResponseModelForExport, SchedulerResponseModel, SchedulerTherapistResponseModel, SchedulerResponseModelForExportFinal } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { TherapistAppointmentsByAppointmenType } from 'src/app/shared/models/response/appointment/therapist-appointment-by-appointment_type';
import { RoadHomeVisitHistoryResponseModel } from 'src/app/shared/models/request/roadHomeVisitHistory-request';
import { AppointmentTypeResponseModel } from 'src/app/shared/models/response/appointment-type-response-model';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';

@Injectable({
  providedIn: 'root'
})
export class TheraistAppointmentService extends BaseService {
  constructor(httpClinet: HttpClient, webStorageService: WebStorageService) {
    super(httpClinet, webStorageService);
  }

  GetAllWIPStatesBySalesOrderType(salesOrderType: string) {
    return this.get<ResultOfT<WIPStatesResponseModel[]>>('Appointment/GetAllWIPStatesBySalesOrderType?salesOrderType=' + salesOrderType);
  }

  GetAllAppointmentsByTherapist(therapistId: string, appointmentDate: string) {
    return this.get<ResultOfT<AppointmentSummaryResponseModel[]>>('Appointment/GetAppointmentsTherapistWise?therapistId=' + therapistId + '&date=' + appointmentDate);
  }

  GetTherapistAppointment(therapistId: number, appointmentDate: string) {
    return this.get<ResultOfT<AppointmentInfoReponseModel[]>>('Therapist/GetTherapistAppointment?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  UpdateAppointmentStatus(salesorders: AppointmentUpdateStatusRequestModel[]) {

    return this.post<ResultOfT<boolean>>('Appointment/UpdateAppointmentStatus', salesorders);
  }

  CheckAppointmentUpdatedStatus(salesorders: AppointmentUpdateStatusRequestModel[]) {

    return this.post<ResultOfT<boolean>>('Appointment/CheckAppointmentUpdatedStatus', salesorders);
  }
  GetAppointmentById(AppointmentId: number) {
    return this.get<ResultOfT<AppointmentInfoReponseModel>>('Appointment/GetAppointmentById/' + AppointmentId);
  }

  GetTherapistAvailability(therapistId: number, dtDate: string) {
    return this.get<ResultOfT<TherapistAvailability[]>>('Appointment/GetTherapistAvailability/' + therapistId + '/' + dtDate);
  }

  GetTherapistFacility(therapistId: number, appointmentDate: string) {
    return this.get<ResultOfT<FacilityInfoResponseModel>>('Therapist/GetTherapistFacility?userInfoId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  GetTherapistAppointments(therapistId: number, appointmentDate: string, isRefresh: boolean) {
    if (isRefresh)
      return this.get<ResultOfT<SchedulerResponseModelForExportFinal>>('Therapist/GetTherapistAppointmentForExport?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate + '&rnd=' + Math.random().toString());
    else
      return this.get<ResultOfT<SchedulerResponseModelForExportFinal>>('Therapist/GetTherapistAppointmentForExport?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  DownloadTherapistAppointmentsCSV(therapistId: number, appointmentDate: string) {
    return this.downloadCsvFile('Therapist/GetTherapistAppointmentExportCSV/' + therapistId + '/' + appointmentDate);
  }

  UpdateAppointmentDetailComment(appointment: AppointmentUpdateStatusRequestModel) {
    return this.post<ResultOfT<boolean>>('Appointment/UpdateAppointmentDetailComment', appointment);
  }

  GetWeekWiseAppointmentsOfTherapists(therapistId: number, weekcurrentDate: string) {
    var url = 'Therapist/GetTherapistAppointmentByWeek?therapistId=' + therapistId;
    url = url + '&weekcurrentDate=' + weekcurrentDate;
    return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  getRoadHomeHistory(therapistId: number, appoinemtnDate: string) {
    return this.get<ResultOfT<RoadHomeVisitHistoryResponseModel[]>>('RoadShowGoogleMap/GetRoadHomeVisitHistory?crmTherapistId=' + therapistId + '&appointmentStartDate=' + appoinemtnDate);
  }

  GetTherapistScheduledAppointment(therapistId: number, appointmentDate: string) {
    return this.get<ResultOfT<TherapistAppointmentsByAppointmenType>>('Therapist/GetTherapistScheduledAppointment?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  GetAllAppointmentType() {
    return this.get<ResultOfT<AppointmentTypeResponseModel[]>>('AppointmentType/GetAllAppointmentType');
  }

  GetTherapistAppointmentForMobileCalender(therapistId: number, weekcurrentDate: string, appointmentType: number) {
    return this.get<ResultOfT<SchedulerResponseModel[]>>('Therapist/GetTherapistAppointmentForMobileCalender?therapistId=' + therapistId + '&weekcurrentDate=' + weekcurrentDate + '&appointmentType=' + appointmentType);
  }

  GetWeekViewTherapistAppointment(selectedDate,therapistAppointmentSearchRequestModel:TherapistAppointmentSearchRequestModel,selectTherapistString,selectBranchString,selectFacilityString,isRefresh){
    var url = 'Therapist/GetAllAppointmentsByTherapists?appointmentDate=' + selectedDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + selectTherapistString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + selectBranchString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + selectFacilityString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    if (isRefresh)
      url = url + '&rnd=' + Math.random().toString();

      return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  GetAllTherapistForAppointmentsWeekViewDME(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Therapist/GetAllTherapistForAppointmentsWeekViewDME?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerTherapistResponseModel>>(url);
  }

  GetWeekViewTherapistAppointmentWeekViewDME(selectedDate,therapistAppointmentSearchRequestModel:TherapistAppointmentSearchRequestModel,selectTherapistString,selectBranchString,selectFacilityString,isRefresh){
    var url = 'Therapist/GetAppointmentsByTherapistsWeekViewDME?appointmentDate=' + selectedDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + selectTherapistString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + selectBranchString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + selectFacilityString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    if (isRefresh)
      url = url + '&rnd=' + Math.random().toString();

      return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  GetTherapistAppointmentForExportWeekViewDME(therapistId: number, appointmentDate: string, isRefresh: boolean) {
    if (isRefresh)
      return this.get<ResultOfT<SchedulerResponseModelForExportFinal>>('Therapist/GetTherapistAppointmentForExportWeekViewDME?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate + '&rnd=' + Math.random().toString());
    else
      return this.get<ResultOfT<SchedulerResponseModelForExportFinal>>('Therapist/GetTherapistAppointmentForExportWeekViewDME?therapistId=' + therapistId + '&appointmentDate=' + appointmentDate);
  }

  GetTherapistAppointmentsExportCSVWeekViewDME(therapistId: number, appointmentDate: string) {
    return this.downloadCsvFile('Therapist/GetTherapistAppointmentsExportCSVWeekViewDME/' + therapistId + '/' + appointmentDate);
  }

}
