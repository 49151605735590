<div class="content-area">
  <div class="notifications-outer">
    <div class="title-block">
      <h1>Notifications</h1>
      <div class="title-block__right">
        <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
      </div>
    </div>

    <div class="notifications-outer__block">
      <div class="user-notification"
        *ngIf="allowToViewSalesOrderNotifications || allowToViewPatientNotifications || allowToViewTherapistNotifications">
        <p-tabView class="notification-tab-menu">
          <!-- <a href="javascript:void(0)" class="user-notification__mark-all">Mark all as read</a> -->
          <!--All Notifaction list -->
          <p-tabPanel header="All">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentBooked'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                          ','+subitem.appFirstName}}</a> has booked the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentReschedule'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                          ','+subitem.appFirstName}}</a> has rescheduled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentCancel'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has cancelled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='PersonalDetailUpdate'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has update
                        {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <strong>{{subitem.appLastName + ', '+subitem.appFirstName}}</strong> auto synced
                        <strong>{{subitem.appTotalSyncRecords}} sales</strong> orders successfully.
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Therapist' && subitem.appBellNotificationSubType=='TheapistUpdateStatus'">
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus != 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                            ','+subitem.appFirstName}}</a>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus == 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <strong>{{subitem.appLastName + ','+subitem.appFirstName}}</strong>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                      </span>
                      <!-- <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span> -->

                      <div class="user-notification__sales-orders-detail"
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredAddress(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Delivery Address are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredEmails(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Email Id are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredPhoneNo(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Phone No are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>
                      </div>

                      <!-- Email -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                      && subitem.appBellNotificationSubType=='FailedNotification'
                      && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Email'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for schedule/rescheduled the appointment is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>


                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff Email for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>



                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation Email'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation Email'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for appointment schedule/rescheduled invitation is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder Email'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Email for appointment reminder is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>
                      </div>

                      <!-- SMS -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                        && subitem.appBellNotificationSubType=='FailedNotification'
                        && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='SMS'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for schedule/rescheduled the appointment is
                          failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Therapist SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for schedule/rescheduled the appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for void appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for no show appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for cancel appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Staff SMS for complete appointment is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation SMS'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation SMS'
                        || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for appointment schedule/rescheduled invitation is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>

                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder SMS'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> SMS for appointment reminder is failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>
                      </div>

                      <!-- VoiceMail -->
                      <div *ngIf="subitem.appBellNotificationType=='Alert' 
                      && subitem.appBellNotificationSubType=='FailedNotification'
                      && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Voicemail'">
                        <span
                          *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Voicemail'">
                          <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                            '+subitem.appFirstName}}</a> Voicemail sending failed.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </span>
                      </div>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                    <a [ngClass]="subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationEnabled ? '' : 'disabled'" class="user-notification__re-try" (click)="ResendAlertNotification(subitem)"
                      *ngIf="subitem.appBellNotificationType=='Alert'">
                      <span pTooltip="Resend {{subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationType}}" placeholder="Left">
                        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path _ngcontent-pqk-c80=""
                            d="M7.39347 10.6065L17 1M7.39347 10.6065L10.4501 16.7198C10.4884 16.8034 10.5499 16.8742 10.6273 16.9239C10.7047 16.9736 10.7948 17 10.8868 17C10.9787 17 11.0688 16.9736 11.1462 16.9239C11.2236 16.8742 11.2851 16.8034 11.3234 16.7198L17 1M7.39347 10.6065L1.28022 7.54991C1.19661 7.51159 1.12576 7.45007 1.07608 7.37267C1.02641 7.29526 1 7.20522 1 7.11325C1 7.02127 1.02641 6.93123 1.07608 6.85383C1.12576 6.77642 1.19661 6.7149 1.28022 6.67659L17 1"
                            stroke="#4A4A4A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Sales Order Notifaction list -->
          <p-tabPanel header="Sync Sales Order" *ngIf="allowToViewSalesOrderNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellSalesOrderNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <strong>{{subitem.appLastName + ', '+subitem.appFirstName}}</strong> auto synced
                        <strong>{{subitem.appTotalSyncRecords}} sales</strong> orders successfully.
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>

                      <div class="user-notification__sales-orders-detail"
                        *ngIf="subitem.appBellNotificationType=='SalesOrder' && subitem.appBellNotificationSubType=='SalesOrderSync'">
                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredAddress(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Delivery Address are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredEmails(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Email Id are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredPhoneNo(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Patients those are not configured Phone No are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredClassification(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Sales Order those are not configured Classification are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>

                        <ng-template ngFor let-summaryItems let-i="index"
                          [ngForOf]="LoadSalesOrdersThoseNotConfiguredBranch(subitem.bellNotificationInAppDetailsSummary)">
                          <h4 *ngIf="i==0">Sales Order those are not configured Branch are as below :</h4>
                          <div class="user-notification__orders-list">
                            <div class="user-notification__orders-item">
                              <span class="user-notification__orders-number">{{i+1}}</span><strong>Patient ID</strong> -
                              <a (click)="onNavigateBrightTreePatientUrl(item.appPatientId)"
                                href="javascript:void(0)">{{summaryItems.appPatientId}}</a>,&nbsp;
                              <strong>S/O ID</strong> - <a (click)="onPatientNotificationView(subitem)"
                                href="javascript:void(0)">{{summaryItems.appSalesOrderAppId}}</a>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Patient Notifaction list -->
          <p-tabPanel header="Patient" *ngIf="allowToViewPatientNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellPatientNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentBooked'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                          ','+subitem.appFirstName}}</a> has booked the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentReschedule'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                          ','+subitem.appFirstName}}</a> has rescheduled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='AppointmentCancel'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has cancelled the appointment.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        *ngIf="subitem.appBellNotificationType=='Patient' && subitem.appBellNotificationSubType=='PersonalDetailUpdate'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> has update
                        {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Therapist Notifaction list -->
          <p-tabPanel header="Therapist" *ngIf="allowToViewTherapistNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellTherapistNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''" (click)="IsNotificationRead(subitem)">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <div class="user-notification__name-info">
                      <span
                        *ngIf="subitem.appBellNotificationType=='Therapist' && subitem.appBellNotificationSubType=='TheapistUpdateStatus'">
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus != 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <a href="javascript:void(0)" class="client-name"
                            (click)="onPatientNotificationView(subitem)">{{subitem.appLastName +
                            ','+subitem.appFirstName}}</a>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                        <div *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus == 'Complete'">
                          <strong>{{subitem.bellNotificationInAppDetailsSummary[0].appTherapistLastName + ',
                            '+subitem.bellNotificationInAppDetailsSummary[0].appTherapistFirstName}}</strong> marked
                          {{subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus}}
                          status for the <strong>{{subitem.appLastName + ','+subitem.appFirstName}}</strong>.
                          (<strong>S/O ID</strong> -
                          {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                        </div>
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
          <!--Alert Notifaction list -->
          <p-tabPanel header="Alert" *ngIf="allowToViewAlertNotifications">
            <div class="user-notification__scrollbar">
              <div class="user-notification__group-notification"
                *ngFor="let item of bellAlertNotificationInAppResponseModel; let i=index">
                <div class="user-notification__list" *ngIf="item.bellNotificationInAppDetail?.length>0">
                  <div class="user-notification__days">{{getNotificationDate(item.appBellNotificationDate)}}</div>
                  <div class="user-notification__items" *ngFor="let subitem of item.bellNotificationInAppDetail"
                    [ngClass]="!subitem.appIsRead ? 'unread':''">
                    <a href="javascript:void(0)" class="user-notification__dots" *ngIf="!subitem.appIsRead"
                      (click)="IsNotificationRead(subitem)"></a>
                    <span class="user-notification__user-name">{{subitem.appShortName}}</span>
                    <!-- Email -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                                && subitem.appBellNotificationSubType=='FailedNotification'
                                && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Email'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist Email for schedule/rescheduled the appointment is
                        failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff Email for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation Email'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation Email'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for appointment schedule/rescheduled invitation is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder Email'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Email for appointment reminder is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <!-- SMS -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                                && subitem.appBellNotificationSubType=='FailedNotification'
                                && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='SMS'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for schedule/rescheduled the appointment is
                        failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Therapist SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Therapist SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for schedule/rescheduled the appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Void Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for void appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send No Show Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for no show appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Cancel Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for cancel appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Complete Schedule/Reschedule Staff SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Staff SMS for complete appointment is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Invitation SMS'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Reschedule Invitation SMS'
                      || subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Schedule Invitation SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for appointment schedule/rescheduled invitation is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Appointment Reminder SMS'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> SMS for appointment reminder is failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>

                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <!-- VoiceMail -->
                    <div class="user-notification__name-info" *ngIf="subitem.appBellNotificationType=='Alert' 
                      && subitem.appBellNotificationSubType=='FailedNotification'
                      && subitem.bellNotificationInAppDetailsSummary[0].appNotificationType=='Voicemail'">
                      <span
                        *ngIf="subitem.bellNotificationInAppDetailsSummary[0].appAppointmentStatus=='Send Voicemail'">
                        <a href="javascript:void(0)" class="client-name"
                          (click)="onPatientNotificationView(subitem)">{{subitem.appLastName + ',
                          '+subitem.appFirstName}}</a> Voicemail sending failed.
                        (<strong>S/O ID</strong> -
                        {{subitem.bellNotificationInAppDetailsSummary[0].appSalesOrderAppId}})
                      </span>
                      <span
                        class="user-notification__times">{{getNotificationTime(subitem.appBellNotificationTime)}}</span>
                    </div>

                    <a [ngClass]="subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationEnabled ? '' : 'disabled'" class="user-notification__re-try" (click)="ResendAlertNotification(subitem)">
                      <span pTooltip="Resend {{subitem?.bellNotificationInAppDetailsSummary[0]?.appNotificationType}}" placeholder="Left">
                        <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path _ngcontent-pqk-c80=""
                            d="M7.39347 10.6065L17 1M7.39347 10.6065L10.4501 16.7198C10.4884 16.8034 10.5499 16.8742 10.6273 16.9239C10.7047 16.9736 10.7948 17 10.8868 17C10.9787 17 11.0688 16.9736 11.1462 16.9239C11.2236 16.8742 11.2851 16.8034 11.3234 16.7198L17 1M7.39347 10.6065L1.28022 7.54991C1.19661 7.51159 1.12576 7.45007 1.07608 7.37267C1.02641 7.29526 1 7.20522 1 7.11325C1 7.02127 1.02641 6.93123 1.07608 6.85383C1.12576 6.77642 1.19661 6.7149 1.28022 6.67659L17 1"
                            stroke="#4A4A4A" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</div>

<div class="loader-block" *ngIf="loadingCustom">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
