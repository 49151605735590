<div class="calendar-outer admin-calendar-outer">

  <ng-template #customTitleTemplate let-event="event" let-view="view">
    <div *ngIf="event.id != 0 && event.id != -1 &&  !event.is15minSlot"
      [ngClass]="
        event.meta.appointmentInfoReponseModel.isMoveStart?'move-highlighted':event.meta.appointmentInfoReponseModel?.appointment?.schAppointmentBookedBy==0 ? 'calendar-box book-by-patient':'calendar-box done' && 
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Facility Visit' ? 'calendar-box facility-slot' : 'calendar-box' &&
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Telehealth' ? 'calendar-box remote-slot' : 'calendar-box' &&
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit' ? 'calendar-box road-home-slot' : 'calendar-box' ">

      <span class="patient-color"></span>

      <div class="calendar-box__time-zone">
        <span class="time">
          {{ event.startLabel | date:'shortTime' | lowercase}} - {{ event.endLabel | date:'shortTime'| lowercase }} |
          {{event.crmPatientTimeZone | timezone}}
        </span>
        <p-checkbox name="group2" value="{{event.id}}" [(ngModel)]="selectedRowAppointment"
          (onChange)="addToSelectedAppointment($event,event)"></p-checkbox>
      </div>

      <div class="calendar-box__title">
        <div class="calendar-box__title__box">
          <span class="classification"
            pTooltip="{{event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}">{{event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}</span>
          <span class="classification">
            SO#<a
              (click)="onNavigateBrightTreeSalesOrderUrl(event.meta.appointmentInfoReponseModel?.appointment?.crmSalesorderAppId)"
              href="javascript:void(0)">{{event.meta.appointmentInfoReponseModel?.appointment?.crmSalesorderAppId}}</a>
            | <span class="bright-tree-patient-url">
              P#<a
                (click)="onNavigateBrightTreePatientUrl(event.meta.appointmentInfoReponseModel?.appointment?.crmPatientRcmBrightreeID)"
                href="javascript:void(0)">{{event.meta.appointmentInfoReponseModel?.appointment?.crmPatientId}}</a>
            </span>
          </span>
          <h6 pTooltip="{{ event.title }}">{{ event.title }}</h6>
          <span class="address-box" *ngIf="event.meta.appointmentInfoReponseModel.appointment.schAppointmentType == 'Home Visit'">
            {{event.meta.appointmentInfoReponseModel.appointment.salesOrderInfo.patientInfo.crmPatientAddress}}</span>

            
            <span class="address-box" *ngIf="event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'">
              {{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
            </span>
          <!-- <i *ngIf="event.meta.appointmentInfoReponseModel.appointment.schAppointmentType == 'Home Visit'"
            class="location-icon"
            pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.salesOrderInfo.patientInfo.crmPatientAddress}}"></i> -->
        </div>
      </div>

      <div class="calendar-box__click-box">
        <div class="calendar-box__edit-delete">
          <a href="javascript:void(0)" (click)="showDialogPopup(event.meta.appointmentInfoReponseModel.appointment)"
            class="dots-btn">
            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
            </svg>
          </a>
        </div>
        <span *ngIf="event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType != 'Home Visit'"
          class="calendar-box__facility-name"
          pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}">
          {{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
        </span>
        <i *ngIf="event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'"
          class="location-icon" pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}"></i>
        <small class="time" *ngIf="event.meta.appointmentInfoReponseModel.isCallStart"> Call in Progress....</small>
      </div>
    </div>

    <!-- 15 min slot content-->
    <!-- [ngClass]="event.meta.appointmentInfoReponseModel.isMoveStart?'move-highlighted':''" -->
    <div class="calendar-box done half-slot" *ngIf="event.id != 0 && event.id != -1 && event.is15minSlot"
      [ngClass]="
        event.meta.appointmentInfoReponseModel.isMoveStart?'move-highlighted':event.meta.appointmentInfoReponseModel?.appointment?.schAppointmentBookedBy==0 ? 'calendar-box book-by-patient':'calendar-box done' && 
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Facility Visit' ? 'calendar-box facility-slot' : 'calendar-box' &&
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Telehealth' ? 'calendar-box remote-slot' : 'calendar-box' &&
        event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit' ? 'calendar-box road-home-slot' : 'calendar-box' ">
      <span class="patient-color"></span>

      <div class="calendar-box__time-zone">
        <span class="time">
          {{ event.startLabel | date:'shortTime' | lowercase}} - {{ event.endLabel | date:'shortTime'| lowercase }} |
          {{event.crmPatientTimeZone | timezone}}
        </span>
        <p-checkbox name="group2" value="{{event.id}}" [(ngModel)]="selectedRowAppointment"
          (onChange)="addToSelectedAppointment($event,event)"></p-checkbox>
      </div>

      <div class="calendar-box__title">
        <div class="calendar-box__title__box">
          <span class="classification"
            pTooltip="{{event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}">{{event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}</span>
          <span class="classification">
            SO#<a
              (click)="onNavigateBrightTreeSalesOrderUrl(event.meta.appointmentInfoReponseModel?.appointment?.crmSalesorderAppId)"
              href="javascript:void(0)">{{event.meta.appointmentInfoReponseModel?.appointment?.crmSalesorderAppId}}</a>
            | <span class="bright-tree-patient-url">
              P#<a
                (click)="onNavigateBrightTreePatientUrl(event.meta.appointmentInfoReponseModel?.appointment?.crmPatientRcmBrightreeID)"
                href="javascript:void(0)">{{event.meta.appointmentInfoReponseModel?.appointment?.crmPatientId}}</a>
            </span>
          </span>
          <h6 pTooltip="{{ event.title }}">{{ event.title }}</h6>
          <i *ngIf="event.meta.appointmentInfoReponseModel.appointment.schAppointmentType == 'Home Visit'"
            class="location-icon"
            pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.salesOrderInfo.patientInfo.crmPatientAddress}}"></i>
        </div>
      </div>

      <div class="calendar-box__click-box">
        <div class="calendar-box__edit-delete">
          <a href="javascript:void(0)" (click)="showDialogPopup(event.meta.appointmentInfoReponseModel.appointment)"
            class="dots-btn">
            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
            </svg>
          </a>
        </div>
        <span *ngIf="event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType != 'Home Visit'"
          class="calendar-box__facility-name"
          pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}">
          {{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
        </span>
        <i *ngIf="event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'"
          class="location-icon" pTooltip="{{event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}"></i>
        <small class="time" *ngIf="event.meta.appointmentInfoReponseModel.isCallStart"> Call in Progress....</small>
      </div>
    </div>
  </ng-template>


  <ng-template #customBlockHours let-event="event" let-view="view" styleClass="Blockslot">
    <div [ngClass]="event.isBlock ? 'calendar-box is-block' : 'calendar-box not-available' " *ngIf="event.id == -1">
      <span class="patient-color"></span>

      <div class="calendar-box__time-zone">
        <span class="time">
          {{ event.startLabel | date:'shortTime' | lowercase}} - {{ event.endLabel | date:'shortTime' | lowercase }} |
          {{event.facilityTimeZone | timezone}}
        </span>
      </div>
      <div class="calendar-box__title">
        <h6 pTooltip="{{ event.title }}">{{ event.title }}</h6>
      </div>

      <div class="calendar-box__click-box" *ngIf="event.isBlock">
        <div class="calendar-box__edit-delete">
          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Edit Block" tooltipPosition="top"
            (click)="editSlot(event.therapistCustomAvailabilityId, event.availId,event.therapistCustomDateId)"><i
              class="edit-icon-new"></i></a>
          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Remove Block" tooltipPosition="top"
            (click)="removeSlot(event.therapistCustomAvailabilityId,event.availId,event.customDatesId)"><i
              class="delete-icon-new"></i></a>
        </div>
      </div>
    </div>

  </ng-template>


  <ng-template #customBreakHours let-event="event" let-view="view">
    <!-- <div class="break-hours-block"> -->
    <div class="calendar-box done break-hours">
      <span class="patient-color"></span>
      <div class="calendar-box__time-zone">
        <span class="time">
          {{ event.startLabel | date:'shortTime' | lowercase}} - {{ event.endLabel | date:'shortTime' | lowercase }} |
          {{event.facilityTimeZone | timezone}}
        </span>
      </div>

      <div class="calendar-box__title">
        <h6>{{ event.title }}</h6>
      </div>
      <div class="calendar-box__click-box">
        <div class="calendar-box__edit-delete">
          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Edit Break" tooltipPosition="top"
            (click)="editBreak(event.therapistCustomAvailabilityId,event.availId,event.therapistCustomDateId)"><i
              class="edit-icon-new"></i></a>
          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Remove Break" tooltipPosition="top"
            (click)="removeBreak(event.therapistCustomAvailabilityId,event.availId,event.customDatesId)"><i
              class="delete-icon-new"></i></a>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </ng-template>

  <ng-template #customHourSegmentTemplate let-segment="segment" let-locale="locale">
    <div class="cal-cell-top" [class.cal-hour-start]="segment.isStart" [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass">
      <div class="cal-time"> {{ segment.date | date:'shortTime':locale }} </div>
    </div>
  </ng-template>

  <ng-template #customHourSegmentTemplate1 let-segment="segment" let-locale="locale" let-user="user">
    <div class="fill-slot" *ngIf="facilityNameLabel(locale.date,segment.therapistAvailability)">
      <a class="last-box__add-btn" href="javascript:void(0)">
        <img src="assets/img/facility-ic.svg" width="29" alt="down-arrow">
        <strong
          pTooltip="{{facilityNameLabel(locale.date,segment.therapistAvailability)}}">{{facilityNameLabel(locale.date,segment.therapistAvailability)}}</strong>
      </a>
    </div>

    <div *ngIf="facilityNameLabel(locale.date,segment.therapistAvailability)==''" class="fill-slot not-available"
      style="background-color: #F9F9F9 !important; text-align: center;">
      N/A
    </div>
  </ng-template>
  <!--Angular9-->
  <ng-template #customHourSegmentTemplate2 let-segment="segment" let-locale="locale">
    <div class="fill-slot" style="background-color: #F9F9F9 !important; text-align: center;">
      N/A
    </div>
  </ng-template>

  <div class="calendar-outer-ui-scrollpanel-x">
    <div class="cal-week-view" role="grid">
      <div class="cal-day-headers" role="row">
        <div class="cal-header">
          <!-- <div class="time-zone-filter">
              {{timeZoneCode}}
              <a class="time-zone-filter__click" href="javascript:void(0)" (click)="timeZoneOverlay.show($event)">
                {{timeZoneCode}} <img src="assets/img/down-arrow.svg" width="12" alt="down-arrow">
              </a>

              <p-overlayPanel appendTo="body" class="table-action-popup timezone-filter-overly" #timeZoneOverlay
                              [dismissable]="true">
                <ul class="quick-link-menu timezine-overly">
                  <li>
                    <a href="javascript:void(0)" value="UTC-5" (click)="onTimeZoneSelection('UTC-5')">EST</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" value="UTC-6" (click)="onTimeZoneSelection('UTC-6')">CST</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" value="UTC-7" (click)="onTimeZoneSelection('UTC-7')">MST</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" value="UTC-8" (click)="onTimeZoneSelection('UTC-8')">PST</a>
                  </li>
                </ul>
              <ul class="quick-link-menu timezine-overly">
                <li *ngFor="let zone of timezones;">
                  <a href="javascript:void(0)" value="zone.name" (click)="onTimeZoneSelection(zone.code)">{{zone.name}}</a>
                </li>

              </ul>
              </p-overlayPanel>
            </div> -->
        </div>

        <div class="cal-header" *ngFor="let user of users; trackBy: trackById" tabindex="0" role="columnheader">

          <div class="calendar-header-outer-box">
            <div class="calendar-header-outer-box__left">
              <strong pTooltip="{{ user.name }}"> {{ user.name }}</strong>
            </div>
            <div class="calendar-header-outer-box__right">
              <p-checkbox name="groupname{{user.id}}" binary="true" [(ngModel)]="user.isChecked"
                (onChange)="addToSelectedHeader($event,user.field,user.id)"></p-checkbox>
            </div>

            <span *ngFor="let avail of user.distinctTherapistAvailability" class="facilityName"
              (click)="GetAvailability(user.id,$event,availpopup,user.name);">
              <span pTooltip="{{avail.facilityName}}">{{avail.facilityName}}</span>
              <small *ngIf="avail.facilityTimeZone !== ''">&nbsp;({{avail.facilityTimeZone | timezone}})</small>
            </span>
          </div>

          <p-overlayPanel class="availpopup" styleClass="availpopup" appendTo="body" #availpopup>
            <div class="availpopup__block">

              <div class="availpopup__block__top">
                <strong>Today's Availability</strong> {{viewDate|date:appDateFormat.dateFormat}}<span></span>
              </div>

              <div class="availpopup__block__middle" *ngFor="let item of availModel">
                <a href="#">
                  <strong>
                    {{item.crmStartTime|date:'shortTime'|lowercase}} -
                    {{item.crmEndTime|date:'shortTime'|lowercase}}
                  </strong> {{
                  item.isRemoteAndFacility? item.facilityName +' & '+ item.remoteFacilityName :item.facilityName
                  }}
                </a>
              </div>
              <div class="availpopup__block__btn">

                <button class="btn btn--primary" type="button" (click)="showAvail(user.enUserInfoId)">
                  View Month
                  Availability
                </button>
              </div>
            </div>
          </p-overlayPanel>

        </div>
      </div>
      <div class="cal-all-day-events" #allDayEventsContainer *ngIf="view.allDayEventRows.length > 0" mwlDroppable
        (dragEnter)="dragEnter('allDay')" (dragLeave)="dragLeave('allDay')">
        <div class="cal-day-columns">
          <div class="cal-time-label-column" [ngTemplateOutlet]="allDayEventsLabelTemplate"></div>
          <div class="cal-day-column" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" mwlDroppable
            dragOverClass="cal-drag-over" (drop)="eventDropped($event, day.date, true)"
            (dragEnter)="dateDragEnter(day.date)"></div>
        </div>
        <div *ngFor="let eventRow of view.allDayEventRows; trackBy: trackById" #eventRowContainer
          class="cal-events-row">
          <div *ngFor="let allDayEvent of eventRow.row;
                          trackBy: trackByWeekAllDayEvent" #event class="cal-event-container"
            [class.cal-draggable]="allDayEvent.event.draggable && allDayEventResizes.size === 0"
            [class.cal-starts-within-week]="!allDayEvent.startsBeforeWeek"
            [class.cal-ends-within-week]="!allDayEvent.endsAfterWeek" [ngClass]="allDayEvent.event?.cssClass"
            [style.width.%]="(100 / users.length) * allDayEvent.span"
            [style.marginLeft.%]="(100 / users.length) * allDayEvent.offset" mwlDraggable
            dragActiveClass="cal-drag-active" [dropData]="{ event: allDayEvent.event, calendarId: calendarId }"
            [dragAxis]="{
                          x: allDayEvent.event.draggable && allDayEventResizes.size === 0,
                          y:
                            !snapDraggedEvents &&
                            allDayEvent.event.draggable &&
                            allDayEventResizes.size === 0
                        }" [dragSnapGrid]="snapDraggedEvents ? { x: dayColumnWidth } : {}"
            [validateDrag]="validateDrag" (dragStart)="dragStarted(eventRowContainer, event)"
            (dragging)="allDayEventDragMove()" (dragEnd)="dragEnded(allDayEvent, $event, dayColumnWidth)">
            <mwl-calendar-week-view-event [locale]="locale" [weekEvent]="allDayEvent"
              [tooltipPlacement]="tooltipPlacement" [tooltipTemplate]="tooltipTemplate"
              [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay" [customTemplate]="eventTemplate"
              [eventTitleTemplate]="eventTitleTemplate" [eventActionsTemplate]="eventActionsTemplate"
              [daysInWeek]="daysInWeek" (eventClicked)="
                            eventClicked.emit({
                              event: allDayEvent.event,
                              sourceEvent: $event.sourceEvent
                            })
                          ">
            </mwl-calendar-week-view-event>
          </div>
        </div>
      </div>
      <div class="cal-time-events" mwlDroppable (dragEnter)="dragEnter('time')" (dragLeave)="dragLeave('time')"
        [style.width.px]="getcalenderColumnWidth()">
        <div class="cal-time-label-column" *ngIf="view.hourColumns.length > 0">
          <div *ngFor="
                          let hour of view.hourColumns[0].hours;
                          trackBy: trackByHour;
                          let odd = odd
                        " class="cal-hour" [class.cal-hour-odd]="odd">
            <mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
              [style.height.px]="hourSegmentHeight" [segment]="segment" [segmentHeight]="hourSegmentHeight"
              [locale]="locale" [customTemplate]="customHourSegmentTemplate" [isTimeLabel]="true">
            </mwl-calendar-week-view-hour-segment>
          </div>
        </div>
        <div class="cal-day-columns" [class.cal-resize-active]="timeEventResizes.size > 0" #dayColumns>
          <div class="cal-day-column"
            *ngFor="let column of view.hourColumns; let i = index;  trackBy: trackByHourColumn">
            <div *ngFor="let timeEvent of column.events; trackBy: trackByWeekTimeEvent" #event
              class="cal-event-container" [class.cal-starts-within-day]="!timeEvent.startsBeforeDay"
              [class.cal-ends-within-day]="!timeEvent.endsAfterDay" [ngClass]="timeEvent.event.cssClass"
              [hidden]="timeEvent.height === 0 && timeEvent.width === 0" [style.top.px]="timeEvent.top"
              [style.height.px]="timeEvent.height" mwlResizable [resizeSnapGrid]="{
                            left: dayColumnWidth,
                            right: dayColumnWidth,
                            top: eventSnapSize || hourSegmentHeight,
                            bottom: eventSnapSize || hourSegmentHeight
                          }" [validateResize]="validateResize" [allowNegativeResizes]="true"
              (resizeStart)="timeEventResizeStarted(dayColumns, timeEvent, $event)"
              (resizing)="timeEventResizing(timeEvent, $event)" (resizeEnd)="timeEventResizeEnded(timeEvent)"
              mwlDraggable dragActiveClass="cal-drag-active"
              [dropData]="{ event: timeEvent.event, calendarId: calendarId }" [dragAxis]="{
                            x: timeEvent.event.draggable && timeEventResizes.size === 0,
                            y: timeEvent.event.draggable && timeEventResizes.size === 0
                          }" [dragSnapGrid]="
                            snapDraggedEvents
                              ? { x: dayColumnWidth, y: eventSnapSize || hourSegmentHeight }
                              : {}
                          " [ghostDragEnabled]="!snapDraggedEvents" [validateDrag]="validateDrag"
              (dragStart)="dragStarted(dayColumns, event, timeEvent)" (dragging)="dragMove(timeEvent, $event)"
              (dragEnd)="dragEnded(timeEvent, $event, dayColumnWidth, true)">
              <!--<div class="cal-resize-handle cal-resize-handle-before-start" *ngIf="
                              timeEvent.event?.resizable?.beforeStart &&
                              !timeEvent.startsBeforeDay
                            " mwlResizeHandle [resizeEdges]="{
                              left: true,
                              top: true
                            }">
                </div>-->
              <mwl-calendar-week-view-event [locale]="locale" [weekEvent]="timeEvent"
                [tooltipPlacement]="tooltipPlacement" [tooltipTemplate]="tooltipTemplate"
                [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDisabled]="dragActive || timeEventResizes.size > 0"
                [tooltipDelay]="tooltipDelay" [customTemplate]="eventTemplate"
                [eventTitleTemplate]="timeEvent.event.id==0? customBreakHours: timeEvent.event.id==-1?  customBlockHours:customTitleTemplate"
                [eventActionsTemplate]="eventActionsTemplate" [column]="column">
              </mwl-calendar-week-view-event>
              <div class="cal-resize-handle cal-resize-handle-after-end" *ngIf="
                              timeEvent.event?.resizable?.afterEnd && !timeEvent.endsAfterDay
                            " mwlResizeHandle [resizeEdges]="{
                              right: true,
                              bottom: true
                            }"></div>
            </div>



            <div *ngFor="let hour of column.hours;   trackBy: trackByHour; let odd = odd" class="cal-hour"
              [class.cal-hour-odd]="odd">

              <!--Angular9-->
              <mwl-calendar-week-view-hour-segment *ngFor="let segment of hour.segments; trackBy: trackByHourSegment"
                [style.height.px]="hourSegmentHeight" [segment]="view.users[i]" [segmentHeight]="hourSegmentHeight"
                [locale]="segment"
                [customTemplate]="idDateIsPassed(segment.date,view.users[i])  ? customHourSegmentTemplate1:customHourSegmentTemplate2"
                (mwlClick)="hourSegmentClickedOverrite(segment.date,$event,view.users[i])" mwlDroppable
                [dragOverClass]="!dragActive || !snapDraggedEvents ? 'cal-drag-over' : null"
                dragActiveClass="cal-drag-active" (drop)="eventDropped($event, segment.date, false)">

              </mwl-calendar-week-view-hour-segment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</div>