<div class="plugin-outer">
  <div class="form-outer">
    <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="col">
        <div class="form__group">
          <label class="form__label">Service</label>
          <p-dropdown class="select-box" formControlName="service" [options]="pluginServices" placeholder="Select" optionLabel="pluginName" [showClear]="true">
          </p-dropdown>
          <span class="invalid-feedback" *ngIf="hasError(formFields.service, 'required') || hasError(formFields.service, 'whitespace')">{{validationMessages.service.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Connection Name</label>
          <input type="text" formControlName="connectionName" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.connectionName, 'required') || hasError(formFields.connectionName, 'whitespace')">{{validationMessages.connectionName.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Username</label>
          <input type="text" formControlName="userName" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.userName, 'required') || hasError(formFields.userName, 'whitespace')">{{validationMessages.userName.required}}</span>
        </div>
        <div class="form__group">
          <label class="form__label">Password</label>
          <input type="password" formControlName="password" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.password, 'required') || hasError(formFields.password, 'whitespace')">{{validationMessages.password.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Account SID</label>
          <input type="text" formControlName="accountSID" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.accountSID, 'required') || hasError(formFields.accountSID, 'whitespace')">{{validationMessages.accountSID.required}}</span>
        </div>
        <!--<h3>Authentication Token</h3>-->
        <div class="form__group">
          <label class="form__label">Auth Token</label>
          <input type="text" formControlName="apiId" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.apiId, 'required') || hasError(formFields.apiId, 'whitespace')">{{validationMessages.apiId.required}}</span>
        </div>
      </div>
      <div class="col">
        <div class="form__group">
          <label class="form__label">Phone Number</label>
          <input type="text" formControlName="phoneNumber" class="form__control" pInputText>
          <span class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber, 'required') || hasError(formFields.phoneNumber, 'whitespace')">{{validationMessages.phoneNumber.required}}</span>
          <span class="invalid-feedback" *ngIf="hasError(formFields.phoneNumber, 'validPhoneNumber')">{{validationMessages.phoneNumber.validPhoneNumber}}</span>
        </div>
      </div>

      <div class="col">
        <div class="form__group">
          <label class="form__label">Voice Call Recording</label>
          <div class="radios">
            <p-radioButton formControlName="callRecording" value=false label="No" (click)="callRecording(false)">
            </p-radioButton>
            <p-radioButton formControlName="callRecording" value=true label="Yes" (click)="callRecording(true)">
            </p-radioButton>
            <span class="invalid-feedback" *ngIf="hasError(formFields.callRecording, 'required')">{{validationMessages.callRecording.required}}</span>
          </div>
        </div>

        <div class="form__group" *ngIf="IsrecordEncryption && hideVoiceCallEncryptionOption">
          <label class="form__label">Voice Call Encryption</label>
          <div class="radios">
            <p-radioButton formControlName="callEncryption" value=false label="No" (click)="recordingEncryption(false)">
            </p-radioButton>
            <p-radioButton formControlName="callEncryption" value=true label="Yes" (click)="recordingEncryption(true)">
            </p-radioButton>
            <span class="invalid-feedback" *ngIf="IsrecordEncryptionError">{{recordingEncryptionMessage}}</span>
          </div>
        </div>
        </div>

        <div class="col" *ngIf="hideContainerSettingsOptions">
          <div class="form__group">
            <label class="form__label">Container Name</label>
            <input type="text" formControlName="containerName" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.containerName, 'required') || hasError(formFields.containerName, 'whitespace')">{{validationMessages.containerName.required}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Container Directory Name</label>
            <input type="text" formControlName="containerDirName" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.containerDirName, 'required') || hasError(formFields.containerDirName, 'whitespace')">{{validationMessages.containerDirName.required}}</span>
          </div>
        </div>
        <div class="col" *ngIf="hideContainerSettingsOptions">
          <div class="form__group">
            <label class="form__label">Recording Key Vault Key Uri</label>
            <input type="text" formControlName="recordingKeyVaultKey" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.recordingKeyVaultKey, 'required') || hasError(formFields.recordingKeyVaultKey, 'whitespace')">{{validationMessages.recordingKeyVaultKey.required}}</span>
          </div>
          <div class="form__group">
            <label class="form__label">Recording Key Vault Secret Uri</label>
            <input type="text" formControlName="recordingKeyVaultsecret" class="form__control" pInputText>
            <span class="invalid-feedback" *ngIf="hasError(formFields.recordingKeyVaultsecret, 'required') || hasError(formFields.recordingKeyVaultsecret, 'whitespace')">{{validationMessages.recordingKeyVaultsecret.required}}</span>
          </div>
        </div>


        <div class="btns">
          <button class="btn btn--primary mb-0" routerLink="/admin/settings/plugins">Save</button>
        </div>
    </form>
      <div class="test-sms-btn">
        <button class="btn btn--secondary mb-0" (click)="onClickTest()">Test Voice Call</button>
      </div>
      
      <div *ngIf="isTestSMS" class="form__group">
        <br>
        <div class="form__group test-sms">
          <label class="form__label">Phone Number</label>
        <input type="text" class="form__control" pInputText [(ngModel)]="testNumber">
      </div>
      <div class="btns">
        <button class="btn btn--primary mb-0" (click)="Phonecall()">Call</button>
        <button class="btn btn--primary mb-0" (click)="Hangup()">Hangup</button>
        <button class="btn btn--secondary mb-0" (click)="CancelCall()">Cancel</button>
      </div>
      </div>
  </div>
</div>
<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
