import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result } from 'src/app/shared/models/response/result';
import { SupportRequestModel } from 'src/app/shared/models/request/support-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

interface SupportCategory {
  name: string;
  code: string;
}
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.sass']
})
export class SupportComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'Support Request';
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  supportRequestModel: SupportRequestModel;
  companyId: number;
  companyLogo: string;
  companyLogoFileName: string;
  supportCategories: SupportCategory[];
  selectedSupportCategory: SupportCategory;
  imageError: string;
  isImageSaved: boolean;
  cardImageBase64: string;
  isActive: boolean = false;
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  companyName: string;
  companyLogoFileType: string;
  formFields = {
    RequestorName: 'RequestorName',
    RequestorEmail: 'RequestorEmail',
    RequestorPhone: 'RequestorPhone',
    ProblemCategory: 'ProblemCategory',
    ProblemArea: 'ProblemArea',
    Problem: 'Problem'
  };
  validationMessages = {
    RequestorName: {
      required: 'Name is required',
    },
    RequestorEmail: {
      required: 'Email is required',
    },

    ProblemCategory: {
      required: 'Category is required',
    },
    ProblemArea: {
      required: 'Probem section is required',
    },
    Problem: {
      required: 'Problem description is required',
    }
  };
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService,
    private sanitizer: DomSanitizer) {
    super(messageService);

    this.supportCategories = [
      { name: 'Appointment Booking', code: 'Appointment Booking' },
      { name: 'Appointment Reschedule', code: 'Appointment Reschedule' },
      { name: 'Calling from App', code: 'Calling from App' },
      { name: 'Others', code: 'Others' },

    ];

  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
  async ngOnInit() {
    this.buildForm();
    //const loggedInUser = this.webStorageService.getCurrentUserToken();
    //const form = this.form;
    //form.get(this.formFields.RequestorName).setValue(loggedInUser.userFullName);
   // form.get(this.formFields.RequestorEmail).setValue(loggedInUser.userEmail);
    this.configResponseModel = this.webStorageService.getClientConfig();
    this.imageData = 'data:image/png;base64, ' + this.configResponseModel.companyLogo;
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
    this.imagealt = this.configResponseModel.companyName;
    this.imageTitle = this.configResponseModel.companyName;
    this.companyId = Number(this.configResponseModel.companyId);
    this.companyName = this.configResponseModel.companyName;

  }
  uploadLogoFile(fileInput: any) {
    this.imageError = null;

    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      const max_size = 3000000;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Kb';
        this.showErrorMessage(this.imageError);

        return false;
      }
      this.companyLogoFileName = fileInput.target.files[0].name;

      var isAllowedType: boolean = false;
      allowed_types.forEach((type) => {
        if (type === fileInput.target.files[0].type) {
          isAllowedType = true;
        }
      });

      if (!isAllowedType) {
        // this.imageError = 'Only Images are allowed ( JPG | PNG )';
        this.imageError = 'Only valid images, pdf and doc files are allowed.';
        this.showErrorMessage(this.imageError);
        return false;
      }
      this.companyLogoFileType = fileInput.target.files[0].type;
      const reader = new FileReader();
      reader.onload = (e: any) => {

        const imgBase64Path = e.target.result;
        this.cardImageBase64 = imgBase64Path;
        const indexOfComma = imgBase64Path.indexOf(',', 0);
        var logoString = this.cardImageBase64.slice(indexOfComma + 1);
        this.companyLogo = logoString;

      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  removeImage() {
    this.cardImageBase64 = null;
    this.companyLogo = null;
    this.isImageSaved = false;
    this.companyLogoFileName = null;
    this.companyLogoFileType = null;
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    const companyRequestModel = this.getValuesFromForm();
    const manageCompany = this.settingsService.submitSupportRequest(companyRequestModel);

    this.loading = true;
    manageCompany.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.showSuccessMessage('Your request has been submitted and one of our executive will get back to you');
          this.form.reset();
          this.companyLogo = '';
          this.companyLogoFileName = '';
          this.companyLogoFileType = '';
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): SupportRequestModel {
    const form = this.form;
    var settingsGeneralRequestModel = new SupportRequestModel();

    settingsGeneralRequestModel.requestorName = form.get(this.formFields.RequestorName).value;
    settingsGeneralRequestModel.requestorEmail = form.get(this.formFields.RequestorEmail).value;
    settingsGeneralRequestModel.requestorPhone = form.get(this.formFields.RequestorPhone).value;
    settingsGeneralRequestModel.problemCategory = form.get(this.formFields.ProblemCategory).value.name;
    settingsGeneralRequestModel.problemArea = form.get(this.formFields.ProblemArea).value;
    settingsGeneralRequestModel.problem = form.get(this.formFields.Problem).value;
    settingsGeneralRequestModel.requestorPhone = form.get(this.formFields.RequestorPhone).value;
    settingsGeneralRequestModel.problemSnapshot = this.companyLogo;
    settingsGeneralRequestModel.problemSnapshotName = this.companyLogoFileName;
    settingsGeneralRequestModel.problemSnapshotFileType = this.companyLogoFileType;
    settingsGeneralRequestModel.companyId = this.companyId;
    settingsGeneralRequestModel.companyName = this.companyName;
    return settingsGeneralRequestModel;
  }
  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.RequestorName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.RequestorEmail, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.email], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.RequestorPhone, this.formBuilder.control('', { }));

    formGroup.addControl(this.formFields.ProblemCategory, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.ProblemArea, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.Problem, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }

}
