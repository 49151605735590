<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1><a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="ic back"></i> </a> {{title}}</h1>
    </div>
  </div>
  <div class="form-content-outer">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <div class="form-content-outer__content custom-event">
        <!--<form class="form" [formGroup]="formPersonal" autocomplete="off"></form>-->
        <div class="col">
          <div class="working-redio-btn">
            <span class="event-series">Events</span>
            <span class="event-series-right">
              <p-radioButton name="group2" value="WO" label="Working" [(ngModel)]="selectedEvent" (click)="SetEvents()"></p-radioButton>
              <p-radioButton name="group2" value="W" label="Work Off" [(ngModel)]="selectedEvent" (click)="SetEvents()"></p-radioButton>
              <p-radioButton name="group2" value="R" label="Road Show" [(ngModel)]="selectedEvent" (click)="SetEvents()"></p-radioButton>
              <p-radioButton name="group2" value="T" label="Remote Setup" [(ngModel)]="selectedEvent" (click)="SetEvents()"></p-radioButton>
            </span>
          </div>
        </div>
        <div class="col" *ngIf="isFacilityDisplayed">
          <div class="popup-facility-block">
            <span class="event-series">
              Facility
            </span>
            <span class="event-series-right facility-event-series">
              <p-dropdown class="select-box" [(ngModel)]="selectedFacility"
                          placeholder="Select" optionLabel="crmFacilityName" [options]="facilities"
                          dataKey="crmFacilityId" [showClear]="true" (onChange)="getTime()">
              </p-dropdown>
            </span>
          </div>
        </div>
        <div class="col" *ngIf="selectedEvent == 'W'">
          <div class="popup-facility-block">
            <span class="event-series">
              Event Description
            </span>
            <span class="event-series-select">
              <input type="text" class="form__control" pInputText maxlength="50" [(ngModel)]="description">
            </span>
          </div>
        </div>
        <div class="col">
          <ul>
            <li class="event-series">
              <span class="event-series">
                Days of Week
              </span>
              <span class="event-series-right">
                <ul>
                  <li class="day-check-box" *ngFor="let item of weekDays; let i = index">

                    <a class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                       [ngClass]="item.isSelected ? 'active' : '' ">
                      <p-checkbox name="group[i]" binary="true" label="{{item.name}}" value="val1" [(ngModel)]="item.isSelected"></p-checkbox>
                    </a>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </div>
        <div class="col">
          <span class="event-series">
            Start Date
          </span>
          <span class="event-series-select">
            <span class="datepikar">
              <p-calendar appendTo="body" class="start-date" [showIcon]="true"
                          [(ngModel)]="customStartDate" [ngModelOptions]="{standalone: true}"></p-calendar>
              <small>End Date</small>
              <p-calendar appendTo="body" class="start-date" [showIcon]="true"
                          [(ngModel)]="customEndDate" [ngModelOptions]="{standalone: true}"></p-calendar>
            </span>
          </span>
        </div>
        <div class="col">
          <span class="event-series">
            Start Time
          </span>
          <span class="event-series-select select2">
            <span class="datepikar">
              <p-calendar class="date-pikar date-timer set-availability-time" [showIcon]="true" [timeOnly]="true" hourFormat="12"
                          [(ngModel)]="customStartTime" [stepMinute]="15"
                          [ngModelOptions]="{standalone: true}"></p-calendar>
              <small class="end-time">End Time</small>
              <p-calendar class="date-pikar date-timer set-availability-time last" [showIcon]="true" [timeOnly]="true" hourFormat="12"
                          [(ngModel)]="customEndTime" [stepMinute]="15"
                          [ngModelOptions]="{standalone: true}"></p-calendar>
            </span>
          </span>
        </div>
        <div class="col" *ngIf="isBreakHoursDisplayed">
          <span class="event-series">
            <p-checkbox value="R" label="Is Break Hours?" binary="true" [(ngModel)]="isBreakHours"></p-checkbox>
          </span>
        </div>
        <div class="col" *ngIf="selectedEvent != 'W' && isBreakHours">
          <span class="event-series">
            Break Start Time
          </span>
          <span class="event-series-select select2">
            <span class="datepikar">
              <p-calendar class="date-pikar date-timer set-availability-time" [showIcon]="true"
                          [timeOnly]="true" hourFormat="12" [stepMinute]="15"
                          [(ngModel)]="breakStartTime"></p-calendar>
              <small class="end-time">Break <br>End Time</small>
              <p-calendar class="date-pikar date-timer set-availability-time last" [showIcon]="true"
                          [timeOnly]="true" hourFormat="12" [stepMinute]="15"
                          [(ngModel)]="breakEndTime"></p-calendar>
            </span>
          </span>
        </div>
        <div class="col" *ngIf="isSetRecurrenceDisplayed">
          <span class="event-series">
            <p-checkbox value="R" label="Set Recurrence?" binary="true" [(ngModel)]="selecteRec"></p-checkbox>
          </span>
        </div>
        <div class="col" *ngIf="selecteRec">
          <ul>
            <li class="event-series">
              <span class="event-series">
                Event Series
              </span>
              <span class="event-series-right">
                <ul>
                  <li class="day-check-box" *ngFor="let item of eventSeries; let i = index">
                    <a class="days-of-weeks" href="javascript:void(0)" ngDefaultControl
                       [ngClass]="item.isSelected ? 'active' : '' ">
                      <p-radioButton name="group1" label="{{item.name}}" (click)="onChangeEventSeries(item)"></p-radioButton>
                    </a>
                  </li>
                </ul>
              </span>
            </li>
          </ul>
        </div>
        <div class="col" *ngIf="selecteRec">
          <span class="event-series">
            Every
          </span>
          <span class="event-series-select every-select">
            <p-dropdown class="select-box" [options]="Occurrence" optionLabel="name" placeholder="Select"
                        [(ngModel)]="selectedOccurrence" [ngModelOptions]="{standalone: true}"
                        (onChange)="onEventOccurrenceChange($event)"></p-dropdown>
          </span>
        </div>
        <div class="col" *ngIf="isExactDate">
          <span class="event-series">
            Exact Date
          </span>
          <span class="event-series-select every-select">
            <p-dropdown class="select-box" [options]="ExactDate" optionLabel="name" placeholder="Select"
                        [(ngModel)]="selectedExactDate" [ngModelOptions]="{standalone: true}"></p-dropdown>
          </span>
        </div>
        <div class="set-custom-date-btn">
          <button class="btn btn--primary" (click)="onSave()" type="button">Submit</button>
          <button class="btn btn--secondary" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </p-scrollPanel>
  </div>
