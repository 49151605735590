import { FacilityAvailableDays } from 'src/app/shared/models/response/appointment/facility-available-days'
export class FacilitySearchResponseModel {
  isDoctorFacility: boolean;
  crmFacilityDistance: number;
  crmFacilityDistanceInMiles: string;
  crmFacilityId: number;
  crmRemoteFacilityId: number = 0;
  crmFacilityName: string;
  crmFacilityAddress: string;
  crmFacilityAddressLine1: string;
  crmFacilityAddressLine2: string;
  crmFacilityCity: string;
  crmFacilityState: string;
  crmFacilityCountry: string;
  crmFacilityZipCode: string;
  crmFacilityLatitude: string;
  crmFacilityLongitude: string;
  crmBioData: string;
  crmFacilityBranch: string;
  crmFacilityWeekDaysAvailability: string;
  crmFacilityWeekendAvailability: string;
  crmFacilityFirstAvailableStartDate: Date;
  crmFacilityFirstAvailableEndDate: Date;
  timeSlotes: FacilityDetailTimeSlotResponseModel;

  timeZoneAvailable: string;
  facilityTimeSlotsModel: AppointmentSlots[];
  facilityAvailableWeekDays: FacilityAvailableDays[];
  facilityAvailableWeekend: FacilityAvailableDays[];
  animation: 'DROP' | 'BOUNCE' | '';
  isOpen: boolean = false;
  crmProfileImage: string;
  crmTimeZone: string;
  isSelect:boolean = false;
}
export class selectedFacilties {

  facilitySearchResponseModel: FacilitySearchResponseModel[];
}

export class FacilityTimeSlotResponseModel {
  appintmentDate: Date;
  startTime: Date;
  endTime: Date;
  therapistCount: number;
  facilityName: string;
  origStartTime: Date;
  origEndTime: Date;
  isFacility: boolean;
  isRemote: boolean;
  isRemoteAndFacility: boolean;
  isBlock1: boolean = false;
  isBlock: boolean;
  isBreak: boolean;
  isRoadShow: boolean;
  facilityid: number;
  remoteFacilityId: number;
  slotType: string;
  therapistIds: string;
  therapistTimeZone: string;
  displayStartTime: Date;
  displayEndTime: Date;
  medicareInsurancesExistForFacility: boolean;
  facilityAddress: string;
  isAppointment: boolean;
  isHideSlot:boolean;
}

export class FacilityDetailTimeSlotResponseModel{
  facilityTimeSlots: FacilityTimeSlotResponseModel[];
  isHoliday: boolean;
  holidayMessage: string;
}

export class AppointmentSlots {
  appintmentDate: Date;
  facilityTimeSlots: FacilityTimeSlotResponseModel[];

}
