export class FacilityTimeSlotRequest {
  CrmFacilityTimeZone: string;
  CalendarViewType: string;
  AppointmentDateTime: Date;
  AppointmentDateTimeString: string;
  CrmFacilityId: number;
  CrmTherapistId: number;
  CrmSalesorderId: number;
  CrmClassificationDuration: number;
  isBillingAddress: boolean;
  CrmPatientStateCode: string;
  isAppointment: boolean;
  RemoteFacilityId: number;
  patientTimeZone: string;
  isNearByFacility: boolean = false;
  CrmSalesorderClassificationType: number;
  SortOrder: string;
  CrmFacilityState: string;
}
