import { Component, OnInit } from '@angular/core';
import { HolidayService } from 'src/app/admin/servicies/holiday.service';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass']
})
export class SettingsComponent implements OnInit {

  title = 'Settings';
  useremail: string;
  isBoardingDisplay: boolean = false;

  constructor(private holidayService: HolidayService, private webStorageService: WebStorageService) { }

  ngOnInit() {
    this.holidayService._setTabIndex(0);
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      this.useremail = currentUserToken.userEmail;
      if (this.useremail == "pdtrapasia@analytix.com" || this.useremail == "piyush.trapasia@analytix.com" || this.useremail == "vijay.prajapat@analytix.com" || this.useremail == "vpprajapat@analytix.com")
      {
        this.isBoardingDisplay = true;
      }
         
    }
  }

}
