<div class="patient-sidebar">
    <div class="patient-sidebar__appointment-booking">
        <span class="patient-sidebar__appt-booking">Appointment Booking for</span>
        <h2 class="patient-sidebar__appt-patient-name">
            <!-- “John Doe” -->
            {{patientName}}
        </h2>
    </div>
    <div class="patient-sidebar__clinic-visit">
        <h3 class="patient-sidebar__title">{{appointmentTitle}}</h3>
        <a class="patient-sidebar__back" href="javascript:void(0);" (click)="onBack()">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1 6.35L7.28671 0.5V3.75C18.0217 3.75 19.2602 10.0407 18.962 13.5C18.5112 11.7547 18.3019 8.95 7.28671 8.95V12.2L1 6.35Z"
                    stroke="#868686" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            Back to All Appointment Types
        </a>
    </div>

    <div class="patient-sidebar__steps-outer">
        <ul class="steps" *ngIf="appointmentType=='T' || appointmentType=='F'">
            <li class="steps__item active">
                <span class="steps__number">1</span>
                <span class="steps__texts">
                    <small>Step 1</small>
                    {{appointmentSelectLabel}}
                </span>
            </li>
            <li class="steps__item">
                <span class="steps__number">2</span>
                <span class="steps__texts">
                    <small>Step 2</small>
                    Select Date & Time
                </span>
            </li>
            <li class="steps__item">
                <span class="steps__number">3</span>
                <span class="steps__texts">
                    <small>Step 3</small>
                    Appointment Summary
                </span>
            </li>
        </ul>

        <ul class="steps" *ngIf="appointmentType=='R' || appointmentType=='SG'">
            <li class="steps__item">
                <span class="steps__number">1</span>
                <span class="steps__texts">
                    <small>Step 1</small>
                    Select Date & Time
                </span>
            </li>
            <li class="steps__item">
                <span class="steps__number">2</span>
                <span class="steps__texts">
                    <small>Step 2</small>
                    Appointment Summary
                </span>
            </li>
        </ul>

        <div class="process-bg">
            <div class="process-bar" ></div>
        </div>
    </div>

</div>