import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { featureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { Result } from 'src/app/shared/models/response/result';
import { FeatureGroupService } from 'src/app/shared/services/featuregroup.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { WebStorageService } from '../../services/web-storage.service';
import { ConfigResponseModel } from 'src/app/shared/models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Constants } from 'src/app/shared/constants';


@Component({
  selector: 'app-therapist-header',
  templateUrl: './therapist-header.component.html',
  styleUrls: ['./therapist-header.component.sass']
})
export class TherapistHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  userfullname: string;
  features: featureResponseModel[];
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  imageData: string;
  imagealt: string;
  imageTitle: string;
  private unsubscriber = new Subject<boolean>();
  constructor(private authenticationService: AuthenticationService,
    private featureGroupService: FeatureGroupService,
    private webStorageService: WebStorageService,
    private sanitizer: DomSanitizer,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnInit() {

    this.configResponseModel = new ConfigResponseModel();
    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = Constants.imagePrefix + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;
    }

    this.getFeatureGroups();
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token)
      this.userfullname = currentUserToken.userFullName;



  }

  private async getFeatureGroups() {
   
    await this.featureGroupService.getPermission()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<featureResponseModel[]>(result, () => {
          this.features = result.responseModel;
          this.webStorageService.deletePermissions();
          this.webStorageService.storePermissions(this.features);
        });
      });
  }


  onLogout() {
    this.featureGroupService.logout().pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          this.authenticationService.signOut();
        }
        else
          this.showErrorMessage(result.message);

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }
  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  } 
}
