import { AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { ReportType, WipStatus } from 'src/app/shared/enums';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { SearchRequestModel, SearchRequestModelReport } from 'src/app/shared/models/request/search-request-model';
import { AppointmentTypeResponseModel } from 'src/app/shared/models/response/appointment/appointment-Type-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ReportSummaryResponseModel } from 'src/app/shared/models/response/report-summary-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SettingsClassificationTypeResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { UserResponseModel } from 'src/app/shared/models/response/user-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { AppointmentHistoryResponseModel } from '../../../shared/models/response/appointment/appointment-history-response-model';
import { FacilityService } from '../../servicies/facility.service';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { ReportService } from '../../servicies/report.service';
import { SettingsService } from '../../servicies/settings.service';
@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.sass']
})
export class ReportDetailComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  // title = 'Report Detail';
  title: string;
  private hasPageHistory: boolean;
  totalRecords: number;
  totalPages: number;
  index: number = 0;
  reportSummaryResponseModel: ReportSummaryResponseModel[];
  selectedRecord: ReportSummaryResponseModel;
  optionsAppointmentType: AppointmentTypeResponseModel[];
  appointmentHistoryResponseModel: AppointmentHistoryResponseModel[];
  appointmentTypes: QuickFilters[];
  reconsileTypes: QuickFilters[];
  // appointmentTypes: any[];
  selectedAppointmentsStatus: QuickFilters;
  therapists: QuickFilters[];
  users: QuickFilters[];
  selectedusers: QuickFilters[];
  selectedTherapist: QuickFilters;
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  currentUserBranch: number;
  currentSelectedUserBranch: number;
  loggedInUserId: number;
  selectedbranches: BranchResponseModel[];
  selectedAppointmentStatus: QuickFilters[];
  searchRequestModel: SearchRequestModel;
  searchRequest: SearchRequestModelReport;
  numberOfCallsOptions: QuickFilters[];
  selectedNumberOfCalls: QuickFilters;
  SSelectedDateRange: any;
  SSelectedTypeDateRange: any;
  SSelectedRangeAppt: any;
  SSelectedDateRangeAppt: any;
  callocales: any;
  SSelectedRange: any;
  invalidDates: moment.Moment[] = [];
  timeZones: QuickFilters[];
  selectedReconsileType: QuickFilters[];
  classificationType: SettingsClassificationTypeResponseModel[];
  selectedClassificationType: SettingsClassificationTypeResponseModel[];
  selectedTimeZone: TimeZoneAvailableResponseModel[];
  timeZoneList: TimeZoneAvailableResponseModel[];
  isVoiceCallReport: boolean = false;
  isMissingEmailPhoneNumberReport: boolean = false;
  selectedFilters: any[];
  isClearClick: boolean = false;
  sRanges = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Today: [moment(), moment()],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  };
  tooltips = [
    { date: moment(), text: 'Today is just unselectable' },
    { date: moment().add(2, 'days'), text: 'Yeeeees!!!' },
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  rangeClicked(range): void {

    this.SSelectedRange = range.label;

  }

  datesUpdated(range): void {

    this.SSelectedDateRange = range;
  }

  rangeClickedAppt(range): void {

    this.SSelectedRangeAppt = range.label;

  }

  datesUpdatedAppt(range): void {

    this.SSelectedDateRangeAppt = range;
  }

  // global search
  searchText: string;
  isGlobalSearch: boolean = false;
  searchFor: string;
  orderObj: any;
  appDateFormat: DateFormatModel;
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private userService: UserService,
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    private facilityService: FacilityService,
    private globalSearchService: GlobalSearchService,
    messageService: MessageService,
    private settingsService: SettingsService,
    @Inject(LOCALE_ID) private locale: string) {
    super(messageService);
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentSelectedUserBranch = this.currentUserBranch ? this.currentUserBranch : 0;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.index = Number(currentNavigationState.selectedTab);
      this.hasPageHistory = true;
    }
    this.loading = false;
    this.initializeSearchRequestModel();
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.callocales = {
      format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Apply', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
    }
    //this.SSelectedDateRange = {

    //  startDate: moment().set({ hours: 0, minutes: 0 }),
    //  endDate: moment().add(6, 'days').set({ hours: 23, minutes: 59 })
    //};
  }

  async ngOnInit() {
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.title = params['reportType'];
      });

    const endDate = moment();
    const startDate = moment().subtract(29, 'days');
    this.SSelectedDateRangeAppt = { startDate: startDate, endDate: endDate };

    await this.getClassificationSettings();

    this.numberOfCallsOptions = [
      { name: 'All', code: 'None' },
      { name: 'First', code: 'First' },
      { name: 'Second', code: 'Second' },
      { name: 'Third', code: 'Third' },
    ];

    this.activatedRoute.queryParamMap.subscribe(params => {
      this.orderObj = { ...params.keys, ...params };
      if (this.orderObj.params && this.orderObj.params.searchtext) {
        this.searchText = this.orderObj.params.searchtext;
        this.isGlobalSearch = true;
        this.searchFor = this.orderObj.params.searchfor;
      }
    });

    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null && branchId >= 0) {
        this.currentUserBranch = branchId;
        this.currentSelectedUserBranch = this.currentUserBranch ? this.currentUserBranch : 0;

        this.initializeSearchRequestModel();
        this.setBranchSettings();
        this.onReportSelection();
        this.index = 0;
      }

    });

    this.initializeSearchRequestModel();
    await this.getBranches();
    await this.getAppointmentType();
    await this.getTherapists();
    await this.getAllUsers();
    await this.getreconsileTypes();
    await this.getClassificationSettings();
    await this.getTimeZoneListAsync();
    //await this.getTimeZone();
  }

  setBranchSettings() {

    this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
    if (selectedBranch !== undefined) {

      if (selectedBranch.crmBranchId !== 0) {
        this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);

      }
    } else {
      this.selectedbranches = this.branchResponseModel;
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.globalSearchService.changeGlobalBranch)
      this.globalSearchService.changeGlobalBranch.next(null);

    if (this.progress) {
      this.progress.complete();
    }
  }

  ngAfterViewInit() {
    this.hasPageHistory = false;
  }

  SchedulePopup: boolean = false;
  SchedulePopupClick() {
    this.SchedulePopup = true;
  }

  callPopup: boolean = false;
  callPopupClick() {
    this.callPopup = true;
  }

  onBack() {
    this.router.navigate(['admin/reports']);
  }


  onView(salesOrderId: number) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['/admin/appointments/salesorders/detail/', salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index } });
  }

  // private async getBranches() {
  //   this.progress.next(true);
  //   await this.userService.getUserBranches(this.loggedInUserId)
  //     .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
  //     .toPromise().then((result) => {
  //       this.branchResponseModel = result.responseModel;
  //       let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
  //       if (selectedBranch) {
  //         this.selectedbranch = selectedBranch;
  //       } else {
  //         this.selectedbranch = this.branchResponseModel[0];
  //       }
  //     })
  //     .catch((httpResponseError) => {
  //       this.showErrorMessage(httpResponseError.message);
  //     });
  // }

  private async getBranches() {
    this.selectedbranches = new Array<BranchResponseModel>();
    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
            let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
            if (selectedBranch !== undefined) {
              // if (selectedBranch.crmBranchId === 0) {
              //   this.branchResponseModel = this.branchResponseModel.filter(x => x.crmBranchId !== 0);
              // }
              if (selectedBranch.crmBranchId !== 0) {
                this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
                // this.branchResponseModel = this.selectedbranches;
              }
            } else {
              this.selectedbranches = this.branchResponseModel;
            }
            if (this.selectedbranches !== undefined && this.selectedbranches.length > 0) {
              if (this.title != ReportType.BookedAppointmentReport && this.title != ReportType.ReconciledAppointmentReport && this.title != ReportType.VoiceCallLogReport) {
                let appliedFilter: { filterType: string, filterName: string, filterValue: string } = { filterType: '', filterName: '', filterValue: '' };
                let count = 0;
                this.selectedbranches.forEach(element => {
                  count = count + 1;
                });
                appliedFilter = {
                  filterType: 'Branch',
                  filterName: count == 1 ? count + ' Branch Selected' : count + ' Branches Selected',
                  filterValue: '1'
                };
                this.selectedFilters.push(appliedFilter);
              }
            }
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBranchChange(event) {

    // if (event.value) {
    //   this.searchRequestModel.crmBranchId = event.value.crmBranchId;
    //   this.currentSelectedUserBranch = event.value.crmBranchId;
    //   this.resetPageIndex();
    //   this.onReportSelection();

    // }
  }

  onCallChange(event) {
    if (event.value != null) {
      // this.searchRequest.numberOfCalls = parseInt(event.value.code, 10);
      this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = event.value.code;
    }
  }

  selectRecord(event, reportRecord: ReportSummaryResponseModel, overlaypanel: OverlayPanel) {
    this.selectedRecord = reportRecord;
    overlaypanel.toggle(event);
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    // if (this.searchRequestModel.PageSize) {
    //   return this.searchRequestModel.PageSize;
    // }
    if (this.searchRequest.pageSize) {
      return this.searchRequest.pageSize;
    }

    return this.totalRecords;
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequest.pageSize = null;
    } else {
      this.searchRequest.pageSize = pageSize;
    }

    this.resetPageIndex();
    this.onReportSelection();
  }

  onSearchButtonClick() {
    this.searchRequest = new SearchRequestModelReport();
    this.searchRequestModel.dictionaryFilter = {};
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    const selSatatus = this.selectedAppointmentsStatus;
    if (selSatatus) {
      this.searchRequestModel.dictionaryFilter['AppointmentStatus'] = selSatatus.name;
    }
    const selTherapist = this.selectedTherapist;
    if (selTherapist) {
      this.searchRequestModel.dictionaryFilter['TherapistFilter'] = selSatatus.code;
    }
    this.resetPageIndex();
    this.isClearClick = false;
    this.onReportSelection();
  }

  onStatusChange(event) {
    // if (event.value != null) {
    //   this.searchRequestModel.dictionaryFilter['AppointmentStatus'] = event.value.code;
    // }
    // this.resetPageIndex();
    // this.onReportSelection();
  }

  onTherapistChange(event) {
    if (event.value != null) {
      this.searchRequest.therapistId = Number(event.value.code);
    }
  }

  OnSearch(event, element) {
    this.searchRequest.dictionaryFilter = {};
    if (this.title != 'Booked' && this.title != ReportType.ReconciledAppointmentReport && this.title != ReportType.VoiceCallLogReport) {

      if (this.searchBox.nativeElement.value)
        this.searchRequest.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      else
        this.searchRequest.dictionaryFilter['SearchText'] = '';
    }
    // this.searchRequest.numberOfCalls = this.selectedNumberOfCalls ? parseInt(this.selectedNumberOfCalls.code, 10) : 0;
    if (this.title === 'Awaiting') {
      this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = this.selectedNumberOfCalls ? this.selectedNumberOfCalls.code : '';
    }

    if (this.selectedbranches !== undefined && this.selectedbranches.length > 0) {
      this.searchRequest.branchId = new Array<number>();
      this.selectedbranches.forEach((result) => {
        this.searchRequest.branchId.push(result.crmBranchId);
      });
    } else {
      this.searchRequest.branchId = new Array<number>();
    }

    if (this.selectedAppointmentStatus !== undefined && this.selectedAppointmentStatus.length > 0) {
      this.searchRequest.appointmentTypeId = new Array<number>();
      this.selectedAppointmentStatus.forEach((result) => {
        this.searchRequest.appointmentTypeId.push(parseInt(result.code, 10));
      });
    } else {
      this.searchRequest.appointmentTypeId = new Array<number>();
    }

    if (this.selectedReconsileType !== undefined && this.selectedReconsileType.length > 0) {
      this.searchRequest.selectedReconsileTypeId = new Array<number>();
      this.selectedReconsileType.forEach((result) => {
        this.searchRequest.selectedReconsileTypeId.push(parseInt(result.code, 10));
      });
    } else {
      this.searchRequest.selectedReconsileTypeId = new Array<number>();
    }
    this.isClearClick = false;
    element.hide(event);
    // this.searchRequest;
    this.onReportSelection();
  }
  onFilterApplied() {
    this.selectedFilters = [];
    let appliedFilter: { filterType: string, filterName: string, filterValue: string } = { filterType: '', filterName: '', filterValue: '' };
    if (this.searchRequest != undefined) {
      appliedFilter = {
        filterType: 'ClearButton',
        filterName: 'Clear all filters',
        filterValue: 'ClearButton'
      };
      this.selectedFilters.push(appliedFilter);

      if (this.searchRequest.dictionaryFilter['SearchText'] != undefined && this.searchRequest.dictionaryFilter['SearchText'].length > 0) {
        appliedFilter = {
          filterType: 'SearchText',
          filterName: this.searchRequest.dictionaryFilter['SearchText'].toString(),
          filterValue: this.searchRequest.dictionaryFilter['SearchText'].toString()
        };
        this.selectedFilters.push(appliedFilter);
      }
      if (this.searchRequest.dictionaryFilter['AppointmentCallFilter'] != undefined && this.searchRequest.dictionaryFilter['AppointmentCallFilter'].trim().length > 0) {
        appliedFilter = {
          filterType: 'AppointmentCallFilter',
          filterName: this.searchRequest.dictionaryFilter['AppointmentCallFilter'] == 'None' ? "All" : this.searchRequest.dictionaryFilter['AppointmentCallFilter'].toString(),
          filterValue: this.searchRequest.dictionaryFilter['AppointmentCallFilter'].toString()
        };
        this.selectedFilters.push(appliedFilter);
      }
      if (this.selectedbranches !== undefined && this.selectedbranches.length > 0) {
        if (this.title != ReportType.BookedAppointmentReport && this.title != ReportType.ReconciledAppointmentReport && this.title != ReportType.VoiceCallLogReport) {
          let count = 0;
          this.selectedbranches.forEach(element => {
            count = count + 1;
          });
          appliedFilter = {
            filterType: 'Branch',
            filterName: count == 1 ? count + ' Branch Selected' : count + ' Branches Selected',
            filterValue: '1'
          };
          this.selectedFilters.push(appliedFilter);
        }
      }
      if (this.selectedClassificationType !== undefined && this.selectedClassificationType.length > 0) {
        let checkIndex = this.selectedFilters.findIndex(x => x.filterType == "Classification")
        if (this.title == ReportType.BookedAppointmentReport && checkIndex == -1) {
          let count = 0;
          this.selectedClassificationType.forEach(element => {
            count = count + 1;
          });
          appliedFilter = {
            filterType: 'Classification',
            filterName: count == 1 ? count + ' Classification Selected' : count + ' Classifications Selected',
            filterValue: '1'
          };
          this.selectedFilters.push(appliedFilter);
        }
      }
      if (this.searchRequest.appointmentTypeId != undefined && this.searchRequest.appointmentTypeId.length > 0) {
        if (this.selectedAppointmentStatus !== undefined && this.selectedAppointmentStatus.length > 0) {
          let count = 0;
          this.selectedAppointmentStatus.forEach(element => {
            count = count + 1;
          });
          appliedFilter = {
            filterType: 'appointmentTypeId',
            filterName: count + ' Appointment Type Selected',
            filterValue: '1'
          };
          this.selectedFilters.push(appliedFilter);
        }
      }
      if (this.searchRequest.selectedReconsileTypeId != undefined && this.searchRequest.selectedReconsileTypeId.length > 0) {
        if (this.selectedReconsileType !== undefined && this.selectedReconsileType.length > 0) {
          this.selectedReconsileType.forEach(element => {
            appliedFilter = {
              filterType: 'selectedReconsileTypeId',
              filterName: element.name,
              filterValue: element.code
            };
            this.selectedFilters.push(appliedFilter);
          });
        }
      }
      if (this.searchRequest.appointmentBookedById != undefined && this.searchRequest.appointmentBookedById.length > 0) {
        if (this.selectedusers !== undefined && this.selectedusers.length > 0) {
          let count = 0;
          this.selectedusers.forEach(element => {
            count = count + 1;
          });
          appliedFilter = {
            filterType: 'appointmentBookedById',
            filterName: count == 1 ? count + ' User Selected' : count + ' Users Selected',
            filterValue: '3'
          };
          this.selectedFilters.push(appliedFilter);
        }
      }
      if (this.searchRequest.selectedTimeZone != undefined && this.searchRequest.selectedTimeZone.length > 0) {
        if (this.selectedTimeZone !== undefined && this.selectedTimeZone.length > 0) {
          let count = 0;
          this.selectedTimeZone.forEach(element => {
            count = count + 1;
          });
          appliedFilter = {
            filterType: 'selectedTimeZone',
            filterName: count + ' Time Zone Selected',
            filterValue: '1'
          };
          this.selectedFilters.push(appliedFilter);
        }
      }
      if (this.searchRequest.dictionaryFilter['CustomDateFilter'] != undefined && this.searchRequest.dictionaryFilter['CustomDateFilter'].length > 0) {
        if (this.title == ReportType.ReconciledAppointmentReport) {
          appliedFilter = {
            filterType: 'CustomDateFilter',
            filterName: "Recon. Dt: " + moment(this.searchRequest.searchFromDateString).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDateString).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDateString + ' To ' + this.searchRequest.searchToDateString
          };
        }
        else if (this.title == ReportType.EmailPhoneMissingAppointments) {
          appliedFilter = {
            filterType: 'CustomDateFilter',
            filterName: "SO. created Dt: " + moment(this.searchRequest.searchFromDateString).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDateString).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDateString + ' To ' + this.searchRequest.searchToDateString
          };
        }
        else if (this.title == ReportType.VoiceCallLogReport) {
          appliedFilter = {
            filterType: 'CustomDateFilter',
            filterName: "Call Dt: " + moment(this.searchRequest.searchFromDateString).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDateString).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDateString + ' To ' + this.searchRequest.searchToDateString
          };
        }
        else{
          appliedFilter = {
            filterType: 'CustomDateFilter',
            filterName: "Booked Dt: " + moment(this.searchRequest.searchFromDateString).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDateString).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDateString + ' To ' + this.searchRequest.searchToDateString
          };
        }
        this.selectedFilters.push(appliedFilter);
      }
      if (this.searchRequest.dictionaryFilter['CustomDateApptFilter'] != undefined && this.searchRequest.dictionaryFilter['CustomDateApptFilter'].length > 0) {
        appliedFilter = {
          filterType: 'CustomDateApptFilter',
          filterName: "Appt. Dt: " + moment(this.searchRequest.searchFromApptDateString).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToApptDateString).format("MM/DD/YYYY"),
          filterValue: this.searchRequest.searchFromApptDateString + ' To ' + this.searchRequest.searchToApptDateString
        };
        this.selectedFilters.push(appliedFilter);
      }
      if (this.searchRequest.dictionaryFilter['CustomDate2Filter'] != undefined && this.searchRequest.dictionaryFilter['CustomDate2Filter'].length > 0) {
        if (this.title == ReportType.CancelledAppointmentReport) {
          appliedFilter = {
            filterType: 'CustomDate2Filter',
            filterName: "Cancelled Dt: " + moment(this.searchRequest.searchFromDate2String).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDate2String).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDate2String + ' To ' + this.searchRequest.searchToDate2String
          };
        }
        else if (this.title == ReportType.CompletedAppointmentReport) {
          appliedFilter = {
            filterType: 'CustomDate2Filter',
            filterName: "Completed Dt: " + moment(this.searchRequest.searchFromDate2String).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDate2String).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDate2String + ' To ' + this.searchRequest.searchToDate2String
          };
        }
        else if (this.title == ReportType.VoidedAppointmentReport) {
          appliedFilter = {
            filterType: 'CustomDate2Filter',
            filterName: "Void Dt: " + moment(this.searchRequest.searchFromDate2String).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDate2String).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDate2String + ' To ' + this.searchRequest.searchToDate2String
          };
        }
        else if (this.title == ReportType.NoShowAppointmentReport) {
          appliedFilter = {
            filterType: 'CustomDate2Filter',
            filterName: "No Show Dt: " + moment(this.searchRequest.searchFromDate2String).format("MM/DD/YYYY") + ' To ' + moment(this.searchRequest.searchToDate2String).format("MM/DD/YYYY"),
            filterValue: this.searchRequest.searchFromDate2String + ' To ' + this.searchRequest.searchToDate2String
          };
        }
        this.selectedFilters.push(appliedFilter);
      }
      if (this.searchRequest.therapistId != undefined && this.searchRequest.therapistId > 0) {
        appliedFilter = {
          filterType: 'therapist',
          filterName: this.selectedTherapist.name,
          filterValue: this.selectedTherapist.code
        };
        this.selectedFilters.push(appliedFilter);


      }
    }
  }
  OnExport() {
    this.searchRequest.dictionaryFilter = {};
    if (this.title != 'Booked' && this.title != ReportType.ReconciledAppointmentReport && this.title != ReportType.VoiceCallLogReport) {

      if (this.searchBox.nativeElement.value)
        this.searchRequest.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      else
        this.searchRequest.dictionaryFilter['SearchText'] = '';
    }
    // this.searchRequest.numberOfCalls = this.selectedNumberOfCalls ? parseInt(this.selectedNumberOfCalls.code, 10) : 0;
    if (this.title === 'Awaiting') {
      this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = this.selectedNumberOfCalls ? this.selectedNumberOfCalls.code : '';
    }

    if (this.selectedbranches !== undefined && this.selectedbranches.length > 0) {
      this.searchRequest.branchId = new Array<number>();
      this.selectedbranches.forEach((result) => {
        this.searchRequest.branchId.push(result.crmBranchId);
      });
    } else {
      this.searchRequest.branchId = new Array<number>();
    }

    if (this.selectedAppointmentStatus !== undefined && this.selectedAppointmentStatus.length > 0) {
      this.searchRequest.appointmentTypeId = new Array<number>();
      this.selectedAppointmentStatus.forEach((result) => {
        this.searchRequest.appointmentTypeId.push(parseInt(result.code, 10));
      });
    } else {
      this.searchRequest.appointmentTypeId = new Array<number>();
    }

    if (this.selectedReconsileType !== undefined && this.selectedReconsileType.length > 0) {
      this.searchRequest.selectedReconsileTypeId = new Array<number>();
      this.selectedReconsileType.forEach((result) => {
        this.searchRequest.selectedReconsileTypeId.push(parseInt(result.code, 10));
      });
    } else {
      this.searchRequest.selectedReconsileTypeId = new Array<number>();
    }

    // this.searchRequest;
    this.onExportReportSelection();
  }

  OnClear(filterType) {
    if (this.title != 'Booked' && this.title != ReportType.ReconciledAppointmentReport && this.title != ReportType.VoiceCallLogReport) {
      this.searchBox.nativeElement.value = '';
    }
    if (filterType != null && filterType != 'ClearButton') {
      this.isClearClick = true;
      let filterToRemove = this.selectedFilters.findIndex(x => x.filterType == filterType);
      if (filterType == "SearchText") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.searchRequest.dictionaryFilter['SearchText'] = '';
        }
      }
      if (filterType == "AppointmentCallFilter") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = '';
        }
      }
      if (filterType == "Branch") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedbranches = new Array<BranchResponseModel>();
          this.searchRequest.branchId = new Array<number>();
        }
      }
      if (filterType == "appointmentTypeId") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedAppointmentStatus = new Array<QuickFilters>();
          this.searchRequest.appointmentTypeId = new Array<number>();
        }
      }
      if (filterType == "selectedReconsileTypeId") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedReconsileType = new Array<QuickFilters>();
          this.searchRequest.selectedReconsileTypeId = new Array<number>();
        }
      }
      if (filterType == "appointmentBookedById") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedusers = new Array<QuickFilters>();
          this.searchRequest.appointmentBookedById = new Array<number>();
        }
      }
      if (filterType == "selectedTimeZone") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedTimeZone = new Array<TimeZoneAvailableResponseModel>();
          this.searchRequest.selectedTimeZone = null;
        }
      }
      if (filterType == "CustomDateFilter") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.searchRequest.dictionaryFilter['CustomDateFilter'] = '';
          // this.searchRequest.searchFromDateString = null;
          // this.searchRequest.searchToDateString = null;
          this.SSelectedDateRange = null;
        }
      }
      if (filterType == "CustomDateApptFilter") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          delete (this.searchRequest.dictionaryFilter['CustomDateApptFilter']);
          // this.searchRequest.searchFromApptDateString = null;
          // this.searchRequest.searchToApptDateString = null;
          this.SSelectedDateRangeAppt = null;
        }
      }
      if (filterType == "CustomDate2Filter") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.searchRequest.dictionaryFilter['CustomDate2Filter'] = '';
          // this.searchRequest.searchFromDate2String = null;
          // this.searchRequest.searchToDate2String = null;
          this.SSelectedTypeDateRange = null;
        }
      }
      if (filterType == "therapist") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.searchRequest.therapistId = 0;
        }
      }
      if (filterType == "Classification") {
        if (filterToRemove != -1) {
          this.selectedFilters.splice(filterToRemove, 1);
          this.selectedClassificationType = [];
        }
      }

      if (this.selectedFilters.length == 1) {
        let removeClearFilter = this.selectedFilters.findIndex(x => x.filterType == "ClearButton")
        if (removeClearFilter != -1) {
          this.selectedFilters = [];
        }
      }

      this.onReportSelection();
    }
    else {

      this.selectedNumberOfCalls = { name: 'All', code: '0' };
      //this.selectedNumberOfCalls = { name: 'First', code: 'First' };
      this.SSelectedDateRange = null;
      this.SSelectedTypeDateRange = null;
      this.SSelectedDateRangeAppt = null;
      this.selectedTherapist = { name: '', code: '' };
      this.selectedbranches = new Array<BranchResponseModel>();
      let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId === this.currentUserBranch);
      if (selectedBranch !== undefined) {
        if (selectedBranch.crmBranchId !== 0) {
          // this.selectedbranches = new Array<BranchResponseModel>();
          this.selectedbranches[0] = this.branchResponseModel.find(x => x.crmBranchId === selectedBranch.crmBranchId);
          // this.branchResponseModel = this.selectedbranches;
        }
      } else {
        this.selectedbranches = this.branchResponseModel;
      }
      this.selectedAppointmentStatus = new Array<QuickFilters>();
      this.selectedusers = new Array<QuickFilters>();
      this.selectedTimeZone = new Array<TimeZoneAvailableResponseModel>();
      this.selectedNumberOfCalls = { name: 'All', code: '0' };
      this.searchRequest = new SearchRequestModelReport();
      this.selectedReconsileType = new Array<QuickFilters>();
      this.selectedClassificationType = this.classificationType;
      this.initializeSearchRequestModel();
      this.isClearClick = false;
      this.selectedFilters = [];
      this.onReportSelection();
    }
  }

  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {
      this.searchRequest.firstRecordIndex = event.first;
      this.searchRequest.currentPageIndex = event.first / 20;
      this.searchRequest.pageSize = 20;
      this.searchRequest.sortByColumnName = event.sortField;
      this.searchRequest.isAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequest.sortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    this.onReportSelection();
  }
  
  private onExportReportSelection() {
    switch (this.title) {
      case ReportType.ScheduledAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Booked;
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.ExportAppointments(ReportType.ScheduledAppointmentReport);
        break;
      case ReportType.RescheduledAppointmentReport:
        if (this.selectedusers !== undefined && this.selectedusers.length > 0) {
          this.searchRequest.appointmentBookedById = new Array<number>();
          this.selectedusers.forEach((result) => {
            this.searchRequest.appointmentBookedById.push(parseInt(result.code));
          });
        } else {
          this.searchRequest.appointmentBookedById = new Array<number>();
        }

        this.searchRequest.appointmentStatus = WipStatus.Booked;
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.ExportRescheduledAppointments();
        break;
      case ReportType.CancelledAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Cancel;

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.ExportAppointments(ReportType.CancelledAppointmentReport);
        break;
      case ReportType.VoidedAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Void;

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.ExportVoidAppointments(ReportType.VoidedAppointmentReport);
        break;
      case ReportType.AwaitingAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.ReadyForScheduling;
        this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = this.selectedNumberOfCalls ? this.selectedNumberOfCalls.code : '';

        if (this.SSelectedDateRange) {
          let startDate = this.SSelectedDateRange.startDate;
          let endDate = this.SSelectedDateRange.endDate;

          if (startDate && endDate) {
            startDate = moment(startDate).toDate();
            endDate = moment(endDate).toDate();
            if (startDate > endDate) {
              this.showWarningMessage("From Date should not be greater than To Date.");
              return;
            }
          }

          if (startDate && endDate) {
            this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

            this.searchRequest.searchFromDate = startDate;
            this.searchRequest.searchToDate = endDate;
          }
        }

        this.ExportAwaitingAppointments(ReportType.AwaitingAppointmentReport);
        break;
      case ReportType.CompletedAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Complete;

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.ExportAppointments(ReportType.CompletedAppointmentReport);
        break;
      case ReportType.UnreconciledAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Booked;
        this.searchRequest.isUnreconciled = true;

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        this.ExportAppointments(ReportType.UnreconciledAppointmentReport);
        break;
      case ReportType.BookedAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.ExportBookedAppointments(ReportType.BookedAppointmentReport);
        break;

      case ReportType.ReconciledAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.searchRequest.appointmentStatus = WipStatus.Complete;
        this.searchRequest.isUnreconciled = true;
        this.ExportReconciledAppointments(ReportType.ReconciledAppointmentReport);
        break;


      case ReportType.VoiceCallLogReport:
        if (this.SSelectedDateRange) {
          let startDate = this.SSelectedDateRange.startDate;
          let endDate = this.SSelectedDateRange.endDate;

          if (startDate && endDate) {
            startDate = moment(startDate).toDate();
            endDate = moment(endDate).toDate();
            if (startDate > endDate) {
              this.showWarningMessage("From Date should not be greater than To Date.");
              return;
            }
          }

          if (startDate && endDate) {
            this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

            this.searchRequest.searchFromDate = startDate;
            this.searchRequest.searchToDate = endDate;
          }
        }
        if (this.SSelectedDateRangeAppt) {


          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.ExportVoiceCallLog();
        break;
      case ReportType.NoShowAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.NoShow;

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.ExportAppointments(ReportType.NoShowAppointmentReport);
        break;
      case ReportType.EmailPhoneMissingAppointments:
        this.ExportMissingEmailPhoneNumberSalesOrders(ReportType.AwaitingAppointmentReport);
        break;



    }
  }
  private onReportSelection() {

    switch (this.title) {
      case ReportType.ScheduledAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        this.getScheduledAppointments();
        break;

      case ReportType.RescheduledAppointmentReport:
        this.searchRequest.appointmentStatus = WipStatus.Booked;
        if (this.selectedusers !== undefined && this.selectedusers.length > 0) {
          this.searchRequest.appointmentBookedById = new Array<number>();
          this.selectedusers.forEach((result) => {
            this.searchRequest.appointmentBookedById.push(parseInt(result.code));
          });
        } else {
          this.searchRequest.appointmentBookedById = new Array<number>();
        }

        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        this.getRescheduledAppointments();
        break;

      case ReportType.CancelledAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.getCancelledAppointments();
        break;

      case ReportType.VoidedAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.GetVoidAppointments();
        break;

      case ReportType.AwaitingAppointmentReport:
        this.searchRequest.dictionaryFilter['AppointmentCallFilter'] = this.selectedNumberOfCalls ? this.selectedNumberOfCalls.code : '';

        if (this.SSelectedDateRange) {
          let startDate = this.SSelectedDateRange.startDate;
          let endDate = this.SSelectedDateRange.endDate;

          if (startDate && endDate) {
            startDate = moment(startDate).toDate();
            endDate = moment(endDate).toDate();
            if (startDate > endDate) {
              this.showWarningMessage("From Date should not be greater than To Date.");
              return;
            }
          }

          if (startDate && endDate) {
            this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

            this.searchRequest.searchFromDate = startDate;
            this.searchRequest.searchToDate = endDate;
          }
        }

        this.getAwaitingAppointments();
        break;

      case ReportType.CompletedAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.getCompletedAppointments();
        break;
      case ReportType.UnreconciledAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        this.getUnreconciledAppointments();
        break;
      case ReportType.BookedAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        this.onClassificationChange();

        this.getBookedAppointments();
        break;

      case ReportType.ReconciledAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }
        this.GetReconciledAppointments();
        break;

      case ReportType.VoiceCallLogReport:
        if (this.SSelectedDateRange) {

          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }
        this.isVoiceCallReport = true;
        this.getVoiceCallLog();
        break;
      case ReportType.NoShowAppointmentReport:
        if (this.SSelectedDateRange) {
          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        if (this.SSelectedDateRangeAppt) {
          if (this.SSelectedDateRangeAppt.startDate && this.SSelectedDateRangeAppt.endDate) {
            let startDate = moment(this.SSelectedDateRangeAppt.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRangeAppt.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateApptFilter'] = 'CustomDate';

              this.searchRequest.searchFromApptDateString = startDate;
              this.searchRequest.searchToApptDateString = endDate;
            }
          }
        }

        if (this.SSelectedTypeDateRange) {
          if (this.SSelectedTypeDateRange.startDate && this.SSelectedTypeDateRange.endDate) {
            let startDate = moment(this.SSelectedTypeDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedTypeDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDate2Filter'] = 'CustomDate';

              this.searchRequest.searchFromDate2String = startDate;
              this.searchRequest.searchToDate2String = endDate;
            }
          }
        }

        this.getNoShowAppointments();
        break;

      case ReportType.EmailPhoneMissingAppointments:
        if (this.SSelectedDateRange) {

          if (this.SSelectedDateRange.startDate && this.SSelectedDateRange.endDate) {
            let startDate = moment(this.SSelectedDateRange.startDate).format("yyyy/MM/DD");
            let endDate = moment(this.SSelectedDateRange.endDate).format("yyyy/MM/DD");

            //let startDate = this.SSelectedDateRange.startDate;
            //let endDate = this.SSelectedDateRange.endDate;

            if (startDate && endDate) {

              if (moment(startDate).toDate() > moment(endDate).toDate()) {
                this.showWarningMessage("From Date should not be greater than To Date.");
                return;
              }
            }

            if (startDate && endDate) {
              this.searchRequest.dictionaryFilter['CustomDateFilter'] = 'CustomDate';

              this.searchRequest.searchFromDateString = startDate;
              this.searchRequest.searchToDateString = endDate;
            }
          }
        }

        this.isMissingEmailPhoneNumberReport = true;
        this.getMissingEmailPhoneNumberSalesOrders();
        break;
        
    }
    if (!this.isClearClick)
      this.onFilterApplied();
  }

  private async getAppointmentType() {
    this.progress.next(true);
    await this.reportService.getAllAppointmentType()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.appointmentTypes = [];
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            const appointmentTypeArray = result.responseModel;
            appointmentTypeArray.forEach((value: AppointmentTypeResponseModel) => {
              var statusName = value.crmAppointmentType.toLowerCase() == "home visit" ? "Road/Home Appt" :
                value.crmAppointmentType.toLowerCase() == "telehealth" ? 'Remote Setup' :
                  value.crmAppointmentType
              this.appointmentTypes.push({ name: statusName, code: value.crmAppointmentTypeId.toString() });
            });
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getTherapists() {
    this.progress.next(true);
    await this.facilityService.getAllTherapist()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.therapists = [];
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            const therapistArray = result.responseModel;
            therapistArray.forEach((value: UserResponseModel) => {
              this.therapists.push({ name: value.appUserLname + ", " + value.appUserFname, code: value.appUserInfoId.toString() });
            });
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllUsers() {
    this.progress.next(true);
    await this.reportService.getAllUsers()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.users = [];
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            const therapistArray = result.responseModel;
            therapistArray.forEach((value: UserResponseModel) => {
              this.users.push({ name: value.appUserLname + ", " + value.appUserFname, code: value.appUserInfoId.toString() });
            });
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getScheduledAppointments() {
    this.loading = true;
    // this.reportService.scheduledAppointments(this.searchRequestModel)
    this.reportService.getScheduledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {

                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getRescheduledAppointments() {
    this.loading = true;
    // this.reportService.scheduledAppointments(this.searchRequestModel)
    this.reportService.getRescheduledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {

                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getCancelledAppointments() {
    this.loading = true;
    // this.reportService.cancelledAppointments(this.searchRequestModel)
    this.reportService.getCancelledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }
  private GetVoidAppointments() {
    this.loading = true;

    this.reportService.GetVoidAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getAwaitingAppointments() {

    this.loading = true;

    this.reportService.getAwaitingAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }


  private getMissingEmailPhoneNumberSalesOrders() {

    this.loading = true;

    this.reportService.getAllMissingEmailPhoneNumberSalesOrders(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  ExportMissingEmailPhoneNumberSalesOrders(reportName) {

    this.reportService.ExportGetAllMissingEmailPhoneNumberSalesOrders(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'MissingEmailPhoneMobileAppointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  private getCompletedAppointments() {
    this.loading = true;
    // this.reportService.completedAppointments(this.searchRequestModel)
    this.reportService.getCompletedAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getUnreconciledAppointments() {
    this.loading = true;

    this.reportService.getUnreconciledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getNoShowAppointments() {
    this.loading = true;
    this.reportService.getNoShowAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {

          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  ExportAppointments(reportName) {

    this.reportService.ExportAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = reportName + 'Appointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  ExportRescheduledAppointments() {

    this.reportService.ExportRescheduledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'RescheduledAppointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  ExportVoiceCallLog() {
    if (this.searchRequest.sortByColumnName == "CrmPatientName") {
      this.searchRequest.sortByColumnName = 'PatientLastName';
    }
    this.reportService.ExportVoiceCallLog(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'VoiceCallLog' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }


  ExportAwaitingAppointments(reportName) {

    this.reportService.ExportAwaitingAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = reportName + 'Appointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  ExportVoidAppointments(reportName) {

    this.reportService.ExportVoidAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = reportName + 'Appointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  private initializeSearchRequestModel() {
    this.searchRequest = new SearchRequestModelReport();
    if (this.currentSelectedUserBranch > 0) {
      this.searchRequest.branchId = new Array<number>();
      this.searchRequest.branchId[0] = this.currentSelectedUserBranch;
    } else {
      this.searchRequest.branchId = new Array<number>();
    }
    this.searchRequest.appointmentTypeId = new Array<number>();
    this.searchRequest.therapistId = 0;
    // this.searchRequest.numberOfCalls = 0;
    this.searchRequest.firstRecordIndex = 0;
    this.searchRequest.currentPageIndex = 0;
    this.searchRequest.pageSize = +this.pageSizes[0];
    this.searchRequest.sortOrder = 'ASC';
    this.searchRequest.sortByColumnName = 'CrmPatientName';
    this.selectedNumberOfCalls = { name: 'First', code: 'First' };
    // if (this.isGlobalSearch && this.searchFor === 'S') {
    if (this.isGlobalSearch) {
      this.searchRequestModel.dictionaryFilter = {};
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }
  }

  private resetPageIndex() {
    this.searchRequest.currentPageIndex = 0;
    this.searchRequest.firstRecordIndex = 0;
  }

  private getBookedAppointments() {
    this.loading = true;
    this.reportService.getBookedAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {

                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }

        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private getVoiceCallLog() {
    this.loading = true;
    if (this.searchRequest.sortByColumnName == "CrmPatientName") {
      this.searchRequest.sortByColumnName = 'PatientLastName';
    }
    this.reportService.getVoiceCallLog(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<AppointmentHistoryResponseModel>>) => {
        this.processResult<PagedResponseModel<AppointmentHistoryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.appointmentHistoryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }

        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  ExportBookedAppointments(reportName) {
    this.reportService.ExportBookedAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = reportName + 'Appointments_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  private async getTimeZone() {
    this.progress.next(true);
    await this.userService.getAllTimeZones()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        this.timeZones = [];
        const timezoneArray = result.responseModel;
        timezoneArray.forEach((value: TimeZoneAvailableResponseModel) => {
          this.timeZones.push({ name: value.appStandardTimeZoneName, code: value.appTimeZoneOffSet.toString() });
        });
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  public async getTimeZoneListAsync() {
    this.timeZoneList = this.webStorageService.getTimeZoneList();
  }


  onTimeZoneChange(event) {
    this.searchRequest.selectedTimeZone = new Array<string>();
    if (event !== undefined && event != null) {

      this.selectedTimeZone.forEach((value: TimeZoneAvailableResponseModel) => {
        this.searchRequest.selectedTimeZone.push(value.appTimeZoneOffSet);
      });
    }
  }

  onClassificationChange() {
    if (this.selectedClassificationType !== undefined && this.selectedClassificationType !== null) {
      this.searchRequest.selectedClassificationType = new Array<number>();
      this.selectedClassificationType.forEach((value: any) => {
        this.searchRequest.selectedClassificationType.push(value.crmClassificationTypeId);
      });
    }
  }

  private GetReconciledAppointments() {
    this.loading = true;
    this.searchRequest.isUnreconciled = true;
    this.reportService.GetReconciledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<ReportSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<ReportSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.reportSummaryResponseModel = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequest.pageSize) {
                  this.searchRequest.pageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  private async getreconsileTypes() {
    this.reconsileTypes = [];
    this.reconsileTypes.push({ name: 'Manual', code: "0" });
    this.reconsileTypes.push({ name: 'Auto', code: "1" });
  }

  ExportReconciledAppointments(reportName) {
    this.reportService.ExportReconciledAppointments(this.searchRequest)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });

        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = reportName + 'Appointment_' + new Date().getMilliseconds().toString() + '.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });

  }

  private async getClassificationSettings() {
    this.progress.next(true);
    await this.settingsService.GetClassificationTypes()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.classificationType = result.responseModel.filter(x => x.crmClassificationDisplayName != null);
            this.selectedClassificationType = this.classificationType;
            if (this.selectedClassificationType !== undefined && this.selectedClassificationType.length > 0) {
              let appliedFilter: { filterType: string, filterName: string, filterValue: string } = { filterType: '', filterName: '', filterValue: '' };
              let checkIndex = this.selectedFilters.findIndex(x => x.filterType == "Classification")
              if (this.title == ReportType.BookedAppointmentReport && checkIndex == -1) {
                let count = 0;
                this.selectedClassificationType.forEach(element => {
                  count = count + 1;
                });
                appliedFilter = {
                  filterType: 'Classification',
                  filterName: count == 1 ? count + ' Classification Selected' : count + ' Classifications Selected',
                  filterValue: '1'
                };
                this.selectedFilters.push(appliedFilter);
              }
            }
          }
        }
      })
  }
}
