import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsPluginResponseModel, PluginCRMAppModel } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsPluginServiceResponseModel } from 'src/app/shared/models/response/settings/settings-plugin-service-response-model';

@Component({
  selector: 'app-crm-app',
  templateUrl: './crm-app.component.html',
  styleUrls: ['./crm-app.component.sass']
})
export class CrmAppComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsPluginResponseModel: SettingsPluginResponseModel;
  settingsPluginRequestModel: SettingsPluginRequestModel;
  pluginModel: PluginCRMAppModel;
  loggedInUserId: number;
  pluginServices: SettingsPluginServiceResponseModel[];

  formFields = {
    service: 'service',
    //connectionName: 'connectionName',
    userName: 'userName',
    password: 'password',
    salesOrderApiUrl: 'salesOrderApiUrl',
    patientApiUrl: 'patientApiUrl',
    doctorApiUrl: 'doctorApiUrl',
    facilityApiUrl: 'facilityApiUrl',
    salesOrder: 'salesOrder',
    patient: 'patient',
    patientBrighttreeApiURL: 'patientBrighttreeApiURL',
    salesOrderBrighttreeApiURL: 'salesOrderBrighttreeApiURL'
    //phoneNumber: 'phoneNumber',
  };

  validationMessages = {
    service: {
      required: 'Service is required',
    },
    //connectionName: {
    //  required: 'Connection name is required',
    //},
    userName: {
      required: 'User name is required',
    },
    password: {
      required: 'Password is required',
    },
    salesOrderApiUrl: {
      required: 'Sales order API URL is required',
      validURL: 'Enter valid sales order api URL'
    },
    patientApiUrl: {
      required: 'Patient API URL is required',
      validURL: 'Enter valid patient api URL'
    },
    doctorApiUrl: {
      required: 'Doctor API URL is required',
      validURL: 'Enter valid doctor api URL'
    },
    facilityApiUrl: {
      required: 'Facility API URL is required',
      validURL: 'Enter valid facility api URL'
    },
    salesOrderBrighttreeApiURL: {
      required: 'SalesOrder Bright URL is required',
      validURL: 'Enter valid SalesOrder Bright URL'
    },
    patientBrighttreeApiURL: {
      required: 'Patient Brightree URL is required',
      validURL: 'Enter valid Patient Bright URL'
    }
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getPluginServices();
    this.getPluginConfiguration();
  }

  private getPluginServices() {
    this.loading = true;

    this.settingsService.getPluginServices()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.pluginServices = result.responseModel;
          this.pluginServices = this.pluginServices.filter(where => where.pluginName == 'Brightree')
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getPluginConfiguration() {

    this.loading = true;
    this.settingsService.getPluginConfiguration(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.settingsPluginResponseModel = result.responseModel;
          this.setValuesToForm();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    var applicationPluginRequestModel = this.getValuesFromForm();
    const manageApplicationGeneral = this.settingsService.updatePluginConfiguration(applicationPluginRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.showSuccessMessage('Plugin CRM app settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): SettingsPluginRequestModel {
    const form = this.form;
    var settingPluginRequestModel = new SettingsPluginRequestModel();
    settingPluginRequestModel = this.settingsPluginResponseModel;
    settingPluginRequestModel.appPluginSettingsDisplayValue = form.get(this.formFields.service).value;
    this.pluginModel.UserName = form.get(this.formFields.userName).value;
    this.pluginModel.Password = form.get(this.formFields.password).value;
    this.pluginModel.SalesOrderURL = form.get(this.formFields.salesOrderApiUrl).value;
    this.pluginModel.PatientURL = form.get(this.formFields.patientApiUrl).value;
    this.pluginModel.DoctorURL = form.get(this.formFields.doctorApiUrl).value;
    this.pluginModel.RefrenceURL = form.get(this.formFields.facilityApiUrl).value;
    this.pluginModel.PatientBrighttreeURL = form.get(this.formFields.patientBrighttreeApiURL).value;
    this.pluginModel.SalesOrderBrighttreeURL = form.get(this.formFields.salesOrderBrighttreeApiURL).value;

    settingPluginRequestModel.appPluginSettingsValue = JSON.stringify(this.pluginModel);
    settingPluginRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    return settingPluginRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsPluginResponseModel) {
      return;
    }

    this.pluginModel = JSON.parse(this.settingsPluginResponseModel.appPluginSettingsValue);

    const form = this.form;
    form.get(this.formFields.service).setValue(this.settingsPluginResponseModel.appPluginSettingsDisplayValue);
    //form.get(this.formFields.connectionName).setValue(this.pluginModel.connectionName);
    form.get(this.formFields.userName).setValue(this.pluginModel.UserName);
    form.get(this.formFields.password).setValue(this.pluginModel.Password);
    form.get(this.formFields.salesOrderApiUrl).setValue(this.pluginModel.SalesOrderURL);
    form.get(this.formFields.patientApiUrl).setValue(this.pluginModel.PatientURL);
    form.get(this.formFields.doctorApiUrl).setValue(this.pluginModel.DoctorURL);
    form.get(this.formFields.facilityApiUrl).setValue(this.pluginModel.RefrenceURL);
    form.get(this.formFields.patientBrighttreeApiURL).setValue(this.pluginModel.PatientBrighttreeURL);
    form.get(this.formFields.salesOrderBrighttreeApiURL).setValue(this.pluginModel.SalesOrderBrighttreeURL);
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.service, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    //formGroup.addControl(this.formFields.connectionName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.userName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.salesOrderApiUrl, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.patientApiUrl, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.doctorApiUrl, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.facilityApiUrl, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.salesOrderBrighttreeApiURL, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.patientBrighttreeApiURL, this.formBuilder.control('', { validators: [CustomValidator.URL, Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }
}
