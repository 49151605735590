import { PatientInfoResponseModel } from './patient-info-response-model'
import { DoctorSummaryResponseModel } from './doctor-summary-response-model'
import { SalesOrderItemInfoResponseModel } from './salesorder-item-info-response-model'
import { SalesOrderInsuranceInfoResponseModel } from './salesorder-insurance-info-response-model'
import { AppointmentCallResponseModel } from './appointment-call-response-model'
import { SalesOrderDoctorFacilityInfoResponseModel } from './salesorder-doctorfacility-info-response-model'
import { NotificationVoicemailResponseModel } from '../notificationVoicemail-response-model'
export class SalesOrderInfoResponseModel {
  crmSalesorderId: number;
  crmSalesorderAppId: string;
  crmPatientId: string;
  crmFacilityId: number;
  crmDoctorId: number;
  wipstatusId: number;
  brightreeStatus: string;
  crmSalesorderStatus: string;
  crmSalesorderClassificationType: number;
  crmSalesorderOrderDate: Date;
  crmSalesorderComments: string;
  crmSalesorderClassification: string;
  crmSalesorderCreateDateTimeStart: Date;
  crmSalesorderCreateDateTimeEnd: Date;
  crmSalesorderConfirmDateTimeStart: Date;
  crmSalesorderConfirmDateTimeEnd: Date;
  crmSalesorderOrderNote: string;
  crmSalesorderDeliveryNote: string;
  crmPlaceOfServiceName: string;
  crmPlaceOfServiceId: string;
  isActive: boolean
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  //crmSalesOrderAPPId: string;
  //crmSalesOrderId: number;
  crmBranch: string;
  crmBranchIsAllowSelfScheduleAppointments: boolean;
  crmPatientName: string;
  crmDeviceName: string;
  crmClassification: string;
  amountDue: number;
  crmDeliveryEmailAddress: string;
  crmDeliveryPhoneNumber: string;
  crmDeliveryMobileNumber: string;
  crmPatientDeliveryAddress: string;
  crmDeliveryAddressLine1: string;
  crmDeliveryAddressLine2: string;
  crmDeliveryAddressCity: string;
  crmDeliveryAddressState: string;
  crmDeliveryAddressCountry: string;
  crmDeliveryAddressZipcode: string;
  crmIsTelehealth: boolean;
  crmIsAppoinment: boolean;
  schAppointmentId: number;
  crmClassificationDuration: number;
  schIsLocked: boolean;
  schAppointmentLockedBy: number;
  crmClassificationDescription: string;
  crmWIPStatusNameBT: string;
  crmWIPAssignedToKey: number;
  crmInvetoryLocationId: number;
  patientInfo: PatientInfoResponseModel;
  doctorInfo: DoctorSummaryResponseModel
  salesOrderItemInfo: SalesOrderItemInfoResponseModel[];
  salesOrderInsuranceInfo: SalesOrderInsuranceInfoResponseModel[];
  calllogs: AppointmentCallResponseModel[];
  doctorFacility: SalesOrderDoctorFacilityInfoResponseModel[];
  notificationVoiceMailInfo: NotificationVoicemailResponseModel[];
  schAppointmentDateStartTime: Date;
  facilityInfo: string;
  crmIsEnableAppointmentMaxLimit: boolean;
  crmMaxNoOfAppointment: number;
  crmClassificationTypeBookedAppointmentCount: number;
  crmDeliveryAddressTimeZone: string;
  schIsUnreconciledAppointment: number;
  btNoteID: number | 0;
  crmClassificationDocuments: string;
  crmIsFacilityVisitSelected: boolean;
  crmIsRemoteVisitSelected: boolean;
  crmIsRoadHomeVisitSelected: boolean;
  crmIsPatientAllowedOnlyOnMyFacility: boolean;
  crmFacilityClassificationDuration: number;
  crmRemoteClassificationDuration: number;
  crmRoadHomeClassificationDuration: number;
  enCrmSalesorderId: string;
}
