<div class="brightree-outer">
  <div class="status-mapping">
    <div class="add-buttons-for-license">

      <h3>Classification</h3>
      <a href="javascript:void(0);" pTooltip="Add Classification Setting" tooltipPosition="left" class="add-plus"
        (click)="ClassificationInfoButton()">
        <img src="assets/img/add-plus.svg" width="17" alt="filters">
      </a>
    </div>

    <div class="table-outer-main classification-settings">

      <p-table #nsdl class="table-outer" [value]="classificationType" [scrollable]="true"
        scrollHeight="calc(100vh - 297px)" responsiveLayout="scroll">

        <ng-template pTemplate="header">
          <tr>
            <th class="table-outer__th" width="180">Classification</th>
            <th class="table-outer__th" width="180">Display Name</th>
            <th class="table-outer__th">WIP States</th>
            <th class="table-outer__th">WIP Assigned To</th>
            <th class="table-outer__th duration-minutes">Patient Default Duration in Minutes</th>
            <th class="table-outer__th duration-minutes">Facility Duration in Minutes</th>
            <th class="table-outer__th duration-minutes">Remote Duration in Minutes</th>
            <th class="table-outer__th duration-minutes">Road/home Duration in Minutes</th>
            <th class="table-outer__th black-out-day" width="120">Black Out Day(s)</th>
            <th class="table-outer__th duration-minutes">Exclude Blackout Days | Remote</th>
            <th class="table-outer__th duration-minutes">Exclude Blackout Days | Facility</th>
            <th class="table-outer__th black-out-day" width="120">Include Weekend</th>
            <th class="table-outer__th">Inv. Location</th>
            <th class="table-outer__th black-out-day" width="120">Allowed Max Appt. Limit </th>
            <th class="table-outer__th black-out-day" width="120">No. of Appt. Per Day</th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="80">Action</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-classification>
          <tr>
            <td class="table-outer__td">{{classification.crmClassificationType}}</td>
            <td class="table-outer__td">{{classification.crmClassificationDisplayName}}</td>
            <td class="table-outer__td">{{classification.crmWIPStatusNameBT}} </td>
            <td class="table-outer__td">{{classification.crmWIPAssignedToPerson}}</td>
            <td class="table-outer__td duration-minutes">{{classification.crmClassificaitonduration}} &nbsp; <i class="info-new"
                pTooltip="{{classification.crmDescription}}"></i></td>
            <td class="table-outer__td duration-minutes">{{classification.crmFacilityClassificationDuration}} </td>
            <td class="table-outer__td duration-minutes">{{classification.crmRemoteClassificationDuration}} </td>
            <td class="table-outer__td duration-minutes">{{classification.crmRoadHomeClassificationDuration}} </td>
            <td class="table-outer__td black-out-day">{{classification.crmBlackOutDays}}</td>
            <td class="table-outer__td duration-minutes">
              <span class="switchBtn">
                <p-inputSwitch id="{{classification.crmClassificationTypeId}}_remote"
                  [(ngModel)]="classification.excludeBlackoutDaysRemote"
                  (onChange)="handleRemoteChange(classification.crmClassificationTypeId,$event)"></p-inputSwitch>
              </span>
            </td>
            <td class="table-outer__td duration-minutes">
              <span class="switchBtn">
                <p-inputSwitch id="{{classification.crmClassificationTypeId}}_facility"
                  [(ngModel)]="classification.excludeBlackoutDaysFacility"
                  (onChange)="handleFacilityChange(classification.crmClassificationTypeId,$event)"></p-inputSwitch>
              </span>
            </td>
            <td class="table-outer__td black-out-day">{{classification.crmIsIncludeWeekend?'Yes':'No'}}</td>
            <td class="table-outer__td">{{classification.crmInvetoryLocationId==null?'':getInventoryLocationName(classification.crmInvetoryLocationId)}}</td>
            <td class="table-outer__td black-out-day">{{classification.crmIsEnableAppointmentMaxLimit?'Yes':'No'}}</td>
            <td class="table-outer__td black-out-day">{{classification.crmMaxNoOfAppointment}}</td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)" (click)="op.toggle($event)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>

              <p-overlayPanel class="table-action-popup" appendTo="body" #op>
                <ul class="quick-link-menu">
                  <li>
                    <a class="edit-row" style="margin-left: 0;" href="javascript:void(0)"
                      (click)="onClassificationEdit(classification)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit</a>
                  </li>
                  <li>
                    <a class="edit-row" href="javascript:void(0)" (click)="onClassificationDelete(classification)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete</a>
                  </li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>

      </p-table>

    </div>
  </div>
  <div class="loader-block" *ngIf="loading" style="z-index: 10000 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
  <div class="loader-block" *ngIf="loadingClassification" style="z-index: 99999999 !important;">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>

</div>
<p-dialog [(visible)]="displayClassification" styleClass="display-close-icon" [modal]="true" [responsive]="true"
  appendTo="body">
  <div class="popup-content-outer">
    <h2>{{classificationBtnTitle}} Classification</h2>

    <form class="form form--classification" [formGroup]="formClassificationType">

      <div class="col scrollbar">
        <div class="form__group" *ngIf="!classificationEditMode">
          <label class="form__label">Classification</label>
          <p-dropdown class="select-box" formControlName="classification" placeholder="Select Classification"
            [(ngModel)]="selectedClassificationType" [options]="classificationTypeOptions" optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.classification.errors"
            class="invalid-feedback">
            <div
              *ngIf="fClassificationType.classification.errors.required || fClassificationType.classification.errors">
              {{validationMessages.classification.required}}
            </div>
          </div>
        </div>
        <div class="form__group" *ngIf="classificationEditMode">
          <label class="form__label">Classification</label>
          <input class="form__control" readonly value="{{selectedClassificationType.wipStatesName}}">
        </div>

        <div class="form__group">
          <label class="form__label">Display Name</label>
          <input type="text" formControlName="displayName" class="form__control" pInputText>
          <div *ngIf="formClassificationSubmitted && fClassificationType.displayName.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.displayName.errors.required || fClassificationType.displayName.errors">
              {{validationMessages.displayName.required}}
            </div>
            <div *ngIf="fClassificationType.description.errors.validCharacters">
              {{validationMessages.displayName.validCharacters}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">WIP States</label>
          <p-dropdown class="select-box" formControlName="brightreeStatus" placeholder="Select WIP States"
            #brightreeDropdown [(ngModel)]="selectedBrightreeStatus" [options]="brightreeStatusOptions"
            optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.brightreeStatus.errors"
            class="invalid-feedback">
            <div
              *ngIf="fClassificationType.brightreeStatus.errors.required || fClassificationType.brightreeStatus.errors">
              {{validationMessages.brightreeStatus.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Patient Default Duration</label>
          <p-dropdown class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="duration"
                      [(ngModel)]="selectedDuration" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.duration.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.duration.errors.required || fClassificationType.duration.errors">
              {{validationMessages.duration.required}}
            </div>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Facility Duration</label>
          <p-dropdown class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="facilityduration"
                      [(ngModel)]="selectedFacilityDuration" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.facilityduration.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.facilityduration.errors.required || fClassificationType.facilityduration.errors">
              {{validationMessages.facilityduration.required}}
            </div>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Remote Duration</label>
          <p-dropdown class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="remoteduration"
                      [(ngModel)]="selectedRemoteDuration" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.remoteduration.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.remoteduration.errors.required || fClassificationType.remoteduration.errors">
              {{validationMessages.remoteduration.required}}
            </div>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Road/home Duration</label>
          <p-dropdown class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="roadhomeduration"
                      [(ngModel)]="selectedRoadhomeDuration" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.roadhomeduration.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.roadhomeduration.errors.required || fClassificationType.roadhomeduration.errors">
              {{validationMessages.roadhomeduration.required}}
            </div>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Black Out Days</label>
          <input type="text" formControlName="blackOutDays" class="form__control" pInputText>
          <div *ngIf="formClassificationSubmitted && fClassificationType.blackOutDays.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.blackOutDays.errors.required">
              {{validationMessages.blackOutDays.required}}
            </div>
            <div *ngIf="fClassificationType.blackOutDays.errors.validNumber">
              {{validationMessages.blackOutDays.validNumber}}
            </div>
          </div>
        </div>
        <div class="form__group status-checkbox">
          <label class="form__label"></label>
          <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="includeWeekend"
            label="Include Weekend" (onChange)="onIncludeWeekend($event)">
          </p-checkbox>
        </div>
        <div class="form__group">
          <label class="form__label">Exclude Blackout Days | Remote</label>
          <span class="switchBtn">
            <p-inputSwitch [(ngModel)]="isRemote" [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{isRemote ?
            'Active' : 'Inactive'}}
          </span>
        </div>
        <div class="form__group">
          <label class="form__label">Exclude Blackout Days | Facility</label>
          <span class="switchBtn">
            <p-inputSwitch [(ngModel)]="isFacility" [ngModelOptions]="{standalone: true}"></p-inputSwitch>{{isFacility ?
            'Active' : 'Inactive'}}
          </span>
        </div>
        <div class="form__group desription-box">
          <small>
            Blackout days exclusion is not applicable for patient self scheduling.
          </small>
        </div>
        <div class="form__group desription-box">
          <label class="form__label">Description</label>
          <textarea class="form__textarea" pInputTextarea formControlName="description" maxlength="255"></textarea>
          <!--<input type="text" formControlName="description" class="form__control" pInputText>-->
          <div *ngIf="formClassificationSubmitted && fClassificationType.description.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.description.errors.required || fClassificationType.description.errors">
              {{validationMessages.description.required}}
            </div>
            <div *ngIf="fClassificationType.description.errors.validCharacters">
              {{validationMessages.description.validCharacters}}
            </div>
          </div>
        </div>

        <div class="form__group desription-box">
          <label class="form__label">Classification Documents</label>
          <!-- <textarea class="form__textarea" pInputTextarea formControlName="description" maxlength="255"></textarea> -->
          <ckeditor formControlName="classificationDocuments" [editor]="Editor"
            [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic' ] }"></ckeditor>
          <!-- <div *ngIf="formClassificationSubmitted && fClassificationType.description.errors" class="invalid-feedback">
            <div *ngIf="fClassificationType.description.errors.required || fClassificationType.description.errors">
              {{validationMessages.description.required}}
            </div>
            <div *ngIf="fClassificationType.description.errors.validCharacters">
              {{validationMessages.description.validCharacters}}
            </div>
          </div> -->
        </div>

        <div class="form__group">
          <label class="form__label">WIP Assigned To</label>
          <p-dropdown class="select-box" placeholder="Select WIP Assigned To" formControlName="wipAssignedToKey"
            [(ngModel)]="selectedWipAssignedTo" [options]="wipAssignedToOptions" [showClear]="true"
            optionLabel="wipStatesName">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.wipAssignedToKey.errors"
            class="invalid-feedback">
            <div
              *ngIf="fClassificationType.wipAssignedToKey.errors.required || fClassificationType.wipAssignedToKey.errors">
              {{validationMessages.wipAssignedToKey.required}}
            </div>
          </div>
        </div>
        <div class="form__group">
          <label class="form__label">Inventory Location</label>
          <p-dropdown class="select-box" placeholder="Select Inventory Location" formControlName="invetoryLocation"
            [(ngModel)]="selectedInvetoryLocation" [options]="InvetoryLocationOptions"
            optionLabel="invetoryLocationName">
          </p-dropdown>
          <div *ngIf="formClassificationSubmitted && fClassificationType.invetoryLocation.errors"
            class="invalid-feedback">
            <div
              *ngIf="fClassificationType.invetoryLocation.errors.required || fClassificationType.invetoryLocation.errors">
              {{validationMessages.invetoryLocation.required}}
            </div>
          </div>
        </div>
        <div class="form__group status-checkbox">
          <label class="form__label"></label>
          <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="allowedmaxAppointment"
            label="Allow Maximum Appt. Limit" (onChange)="onAllowedmaxAppointment($event)">
          </p-checkbox>

        </div>
        <div class="form__group">
          <label class="form__label">No. of Appt. Per Day</label>
          <input type="text" formControlName="maxNoOfAppointment" class="form__control" pInputText>
          <div *ngIf="formClassificationSubmitted && fClassificationType.maxNoOfAppointment.errors"
            class="invalid-feedback" style="bottom: inherit !important; top: 71px;">
            <div *ngIf="fClassificationType.maxNoOfAppointment.errors.required">
              {{validationMessages.maxNoOfAppointment.required}}
            </div>
            <div *ngIf="fClassificationType.maxNoOfAppointment.errors.validNumber">
              {{validationMessages.maxNoOfAppointment.validNumber}}
            </div>
            <div *ngIf="fClassificationType.maxNoOfAppointment.errors.numberGreaterThanZero">
              {{validationMessages.maxNoOfAppointment.numberGreaterThanZero}}
            </div>
          </div>
        </div>
        <div class="form__group desription-box">
          <small>
            This is the maximum number of appts. to be booked on particular appointment date.
          </small>
        </div>

        <!--<div class="form__group">
        </div>-->
      </div>

      <div class="form__group button-box">
        <div class="btns">
          <button class="btn btn--primary" (click)="onClassificationAdd()">{{classificationBtnTitle}}</button>
          <button class="btn btn--secondary" (click)="onClassificationCancel()">Cancel</button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
