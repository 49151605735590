import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { UserInfoRequestModel } from 'src/app/shared/models/request/userInfo-request-model';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { TherapistSetAvailabilityRequestModel, TherapistSetAvailabilityRequestModelSend } from 'src/app/shared/models/response/therapist/therapist-availability-response-model';
import { TherapistCustomAvailabilityResponseModel, TherapistCustomAvailabilityResponseModelForMergeMessage } from 'src/app/shared/models/response/therapist/therapist-custom-availability-response-model';
import { EnumModel } from 'src/app/shared/models/common/enum-model';
import { HolidayResponse, TherapistAvailability } from 'src/app/shared/models/response/appointment/available-slots';
import { TherapistCustomDates } from 'src/app/shared/models/request/therapist/therapist-custom-dates';
import { TherapistCustomDatesResponseModel, TherapistEditBlockResponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
import * as moment from 'moment';
import { TherapistContactInfoResponseModel, TherapistInfoModel } from 'src/app/shared/models/response/therapist/therapist-info-model';
import { SelectedTherapistResponseModel, TherapistDefaultFacilityModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-model';
import { TherapistDeviceInfoModel } from 'src/app/shared/models/response/therapist/therapist-device-info-model';
import { TherapistLicenseInfoModel } from 'src/app/shared/models/response/therapist/therapist-licenseinfo-model';
import { TherapistOnlineMeetingModel, TherapistOnlineMeetingResponseModel } from 'src/app/shared/models/response/therapist/therapist-onlinemeeting-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { LinkedTherapistWithCurrentFacilitiesRequestModel, LinkedTherapistWithCurrentFacilitiesResponseModel } from 'src/app/shared/models/response/facility/facility-response-model';
import { DeleteAvailabilitySeriesModel } from 'src/app/shared/models/response/therapist/therapist-availability-delete-series';
import { map } from 'rxjs/operators';
import { TherapistAppointmentSearchRequestModel } from 'src/app/shared/models/request/therapist/therapist-appointment-request-model';
import { SchedulerResponseModel, SchedulerTherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-appointments-response-model';

@Injectable({
  providedIn: 'root'
})
export class ManageAvailabilityService extends BaseService {

  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }
  getTherapists() {
    return this.get<ResultOfT<UserInfoResponseModel[]>>('Therapist/GetAllTherapist');
  }

  getAllTherapist(searchRequestModel: SearchRequestModel, isRefresh: boolean) {
    var url = 'Therapist/GetPagedTherapists';
    url = url + '?SortByColumnName=' + searchRequestModel.SortByColumnName;

    if (searchRequestModel.appUserInfoId) {
      url = url + '&appUserInfoId=' + searchRequestModel.appUserInfoId;
    }
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }

    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    if (isRefresh) {
      url = url + '&rnd=' + Math.random().toString();
    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<TherapistResponseModel>>>(url);

    //return this.post<ResultOfT<PagedResponseModel<TherapistResponseModel>>>('Therapist/GetPagedTherapists', searchRequestModel);
  }

  getTherapistById(therapistId: number) {
    return this.get<ResultOfT<UserInfoResponseModel>>('Therapist/GetTherapist/' + therapistId);
  }



  GetTherapistGenertalEventLogs(searchRequestModel: SearchRequestModel) {
    var url = 'Therapist/GetTherapistGenertalEventLogs';
    url = url + '?appUserInfoId=' + searchRequestModel.enappUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<object>>>(url);
  }
  GetTherapistAvailabilityEventLogs(searchRequestModel: SearchRequestModel) {
    var url = 'Therapist/GetTherapistAvailabilityEventLogs';
    url = url + '?appUserInfoId=' + searchRequestModel.enappUserInfoId;
    if (searchRequestModel.crmBranchId) {
      url = url + '&crmBranchId=' + searchRequestModel.crmBranchId;
    }
    if (searchRequestModel.SortByColumnName) {
      url = url + '&SortByColumnName=' + searchRequestModel.SortByColumnName;
    }
    if (searchRequestModel.SortOrder) {
      url = url + '&SortOrder=' + searchRequestModel.SortOrder;
    }

    if (searchRequestModel.PageSize) {
      url = url + '&PageSize=' + searchRequestModel.PageSize;
    }
    if (searchRequestModel.CurrentPageIndex) {
      url = url + '&CurrentPageIndex=' + searchRequestModel.CurrentPageIndex;
    }
    if (searchRequestModel.searchFromDate) {
      let datepass = moment(searchRequestModel.searchFromDate).format('MM-DD-YYYY');
      url = url + '&SearchFromDate=' + datepass;
    }
    if (searchRequestModel.searchToDate) {
      let datepass = moment(searchRequestModel.searchToDate).format('MM-DD-YYYY');
      url = url + '&searchToDate=' + datepass;
    }

    if (searchRequestModel.dictionaryFilter) {

      for (let key in searchRequestModel.dictionaryFilter) {
        let value = searchRequestModel.dictionaryFilter[key];
        if (value) {
          url = url + '&' + key + '=' + value;
        }
      }
    }
    return this.get<ResultOfT<PagedResponseModel<object>>>(url);
  }

  GetTherapistByTherapistId(userInfoId: number) {
    return this.get<ResultOfT<UserInfoResponseModel>>('Therapist/GetTherapistByTherapistId?userInfoId=' + userInfoId);
  }
  GetStates() {
    return this.get<ResultOfT<StateMasterResponseModel[]>>('Therapist/GetStates');
  }

  addTherapist(newtherapist: UserInfoResponseModel) {
    return this.post<ResultOfT<number>>('Therapist/AddTherapist', newtherapist);
  }
  // addTherapist(newtherapist: UserInfoRequestModel) {
  //   return this.post<ResultOfT<boolean>>('Therapist/Add', newtherapist);
  // }

  updateTherapist(updatedTherapist: UserInfoResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapist', updatedTherapist);
  }
  // updateTherapist(updatedTherapist: UserInfoRequestModel) {
  //   return this.post<ResultOfT<boolean>>('Therapist/Update', updatedTherapist);
  // }

  removeTherapist(therapistId: number) {
    return this.get<ResultOfT<boolean>>('Therapist/ActiveInactive/' + therapistId + '/' + false);
  }

  getTherapistAvailability(therapistId: number) {
    return this.get<ResultOfT<TherapistSetAvailabilityRequestModel>>('Therapist/GetTherapistAvailability/' + therapistId);
  }

  setTherapistAvailability(newTherapistAvailability: TherapistSetAvailabilityRequestModelSend) {
    return this.post<ResultOfT<boolean>>('Therapist/SetTherapistAvailability', newTherapistAvailability);
  }

  updateTherapistAvailability(updateTherapistAvailability: TherapistSetAvailabilityRequestModelSend) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistAvailability', updateTherapistAvailability);
  }

  getDaysOfWeek() {
    return this.get<ResultOfT<EnumModel[]>>('Therapist/GetDaysOfWeek');
  }
  getEventSeries() {
    return this.get<ResultOfT<EnumModel[]>>('Therapist/GetRecurringType');
  }

  validateAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<TherapistCustomAvailabilityResponseModelForMergeMessage>>('Availability/ValidateAvailability', requestModel);
  }

  setAvailabilityNew(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/SetAvailabilityNew', requestModel);
  }

  addBlockSlot(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/AddBlockSlot', requestModel);
  }

  setCustomAvailabilityNew(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/SetCustomAvailabilityNew', requestModel);
  }

  updateAvailabilityNew(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/UpdateAvailabilityNew', requestModel);
  }

  updateBlock(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/UpdateBlockSet', requestModel);
  }

  getAvailabilityNew(searchRequestModel: SearchRequestModel) {
    return this.post<ResultOfT<PagedResponseModel<TherapistCustomAvailabilityResponseModel>>>('Therapist/GetAvailabilityNew', searchRequestModel);
  }

  getAvailabilityNewById(id: number, id1: number, isFetchForMainSeries: boolean) {
    return this.get<ResultOfT<TherapistCustomAvailabilityResponseModel>>('Availability/GetAvailabilityById/' + id + '/' + id1 + '/' + isFetchForMainSeries);
  }
  delAvailabilityNew(id: number) {
    return this.get<ResultOfT<TherapistCustomAvailabilityResponseModel>>('Availability/DeleteAvailabilityById/' + id);
  }

  GetTherapistAvailabilityCalendar(therapistId: string, dtDate: string, isRefresh: boolean) {
    if (!isRefresh)
      return this.get<ResultOfT<TherapistAvailability[]>>('Availability/GetTherapistAvailabilityCalendar?therapistId=' + therapistId + '&dtDate=' + dtDate);
    else
      return this.get<ResultOfT<TherapistAvailability[]>>('Availability/GetTherapistAvailabilityCalendar?therapistId=' + therapistId + '&dtDate=' + dtDate + '&rnd=' + Math.random().toString());
  }

  DeleteCustomDate(Id: number) {
    return this.get<ResultOfT<boolean>>('Availability/DeleteCustomDate/' + Id);
  }

  GetCustomDate(Id: number) {
    return this.get<ResultOfT<TherapistCustomDates>>('Availability/getCustomDate/' + Id);
  }

  GetAvailData(Id: number, dt: string) {
    return this.get<ResultOfT<TherapistCustomDatesResponseModel[]>>('Therapist/GetAvailabilityForCalendar/' + Id + '/' + dt);
  }

  removeSlot(Id: number) {
    return this.get<ResultOfT<boolean>>('Availability/RemoveBlockSlot/' + Id);
  }

  removeSlotWithTimeZone(Id: number, facilitytimeZone: string) {
    return this.get<ResultOfT<boolean>>('Availability/RemoveBlockwithTimeZone/' + Id + "/" + facilitytimeZone);
  }

  EditSlot(Id: number, facilitytimeZone: string, customDatesId: number) {
    return this.get<ResultOfT<TherapistEditBlockResponseModel>>('Availability/EditBlockSlot/' + Id + '/' + facilitytimeZone + '/' + customDatesId);
  }

  removeBreak(Id: number) {
    return this.get<ResultOfT<boolean>>('Availability/RemoveBreak/' + Id);
  }

  UpdateCustomDate(object: TherapistCustomDates) {
    return this.post<ResultOfT<boolean>>('Therapist/updateCustomDate/', object);
  }

  setBreakHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/setBreakHours', requestModel);
  }

  updateBreakHours(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/updateBreakHours', requestModel);
  }

  EditBreak(Id: number, facilitytimeZone: string, therapistCustomDateId: number) {
    return this.get<ResultOfT<TherapistEditBlockResponseModel>>('Availability/EditBreak/' + Id + '/' + facilitytimeZone + '/' + therapistCustomDateId);
  }
  AddTherapistInfo(newtherapist: TherapistInfoModel) {
    return this.post<ResultOfT<number>>('Therapist/AddTherapistInfo', newtherapist);
  }
  AddTherapistDefaultFacility(newtherapist: TherapistDefaultFacilityModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistDefaultFacility', newtherapist);
  }
  AddTherapistLicenseInfo(newtherapist: TherapistLicenseInfoModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistLicenseInfo', newtherapist);
  }
  AddTherapistDeviceInfo(newtherapist: TherapistDeviceInfoModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistDeviceInfo', newtherapist);
  }
  AddTherapistonlineMeetingInfo(newtherapist: TherapistOnlineMeetingModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistonlineMeetingInfo', newtherapist);
  }
  UpdateTherapistInfo(updateTherapist: TherapistInfoModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistnfo', updateTherapist);
  }
  UpdateTherapistDefaultFacility(therapistDefaultFacilityModel: TherapistDefaultFacilityModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistDefaultFacility', therapistDefaultFacilityModel);
  }
  UpdateTherapistSelfScheduleAppointmentsStatus(therapistDefaultFacilityModel: TherapistDefaultFacilityModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistSelfScheduleAppointmentsStatus', therapistDefaultFacilityModel);
  }
  UpdateSelectedTherapistSelfScheduleAppointmentsStatus(selectedTherapistResponseModel: SelectedTherapistResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateSelectedTherapistSelfScheduleAppointmentsStatus', selectedTherapistResponseModel);
  }
  GetAllLinkedFacilitiesWithCurrentTherapistDetails(LinkedFacilitiesWithCurrentTherapistRequestModel: LinkedTherapistWithCurrentFacilitiesRequestModel) {
    return this.post<ResultOfT<LinkedTherapistWithCurrentFacilitiesResponseModel[]>>('Therapist/GetAllLinkedFacilitiesWithCurrentTharapistDetails', LinkedFacilitiesWithCurrentTherapistRequestModel);
  }
  UpdateTherapistLicenseInfo(newtherapist: TherapistLicenseInfoModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistLicenseInfo', newtherapist);
  }
  UpdateTherapistDeviceInfo(newtherapist: TherapistDeviceInfoModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistDeviceInfo', newtherapist);
  }
  UpdateTherapistonlineMeetingInfo(newtherapist: TherapistOnlineMeetingModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistonlineMeetingInfo', newtherapist);
  }

  GetHoliday(dtDate: string, isRefresh: boolean) {
    if (!isRefresh)
      return this.get<ResultOfT<HolidayResponse[]>>('Therapist/GetHolidayList?therapistId=' + '&dtDate=' + dtDate);
    else
      return this.get<ResultOfT<HolidayResponse[]>>('Therapist/GetHolidayList?therapistId=' + '&dtDate=' + dtDate + '&rnd=' + Math.random().toString());
  }

  ValidateZipcodeWithState(stateName: string, zipCode: number) {
    return this.get<ResultOfT<boolean>>('Therapist/ValidateZipcodeWithState/' + stateName + '/' + zipCode);
  }

  CheckTherapistAvailability(therapistId: string, dtDate: string) {
    return this.get<ResultOfT<boolean>>('Therapist/CheckTherapistAvailability/' + therapistId + '/' + dtDate);
  }
  CheckTherapistAvailabilityOnDefaulFacility(therapistId: string, facilityId: string, dtDate: string) {
    return this.get<ResultOfT<boolean>>('Therapist/CheckTherapistAvailabilityOnDefaulFacility/' + therapistId + '/' + facilityId + '/' + dtDate);
  }

  GetDeleteAvailabilitySeries(therapistId: number, isSeries: Boolean, startDate: string, endDate: string, availabilityId: number, customId: number) {
    var url = 'Availability/GetDeleteAvailabilitySeries?therapistId=' + therapistId;
    url = url + '&isSeries=' + isSeries;
    url = url + '&startDate=' + moment(startDate).format("yyyy-MM-DD");
    url = url + '&endDate=' + moment(endDate).format("yyyy-MM-DD");
    url = url + '&availabilityId=' + availabilityId;
    url = url + '&customId=' + customId
    return this.get<ResultOfT<DeleteAvailabilitySeriesModel>>(url);
  }

  DeleteAvailabilitySeries(requestModel: DeleteAvailabilitySeriesModel) {
    return this.post<ResultOfT<boolean>>('Availability/DeleteAvailabilitySeries', requestModel);
  }

  overRideAvailability(requestModel: TherapistCustomAvailabilityResponseModel) {
    return this.post<ResultOfT<boolean>>('Availability/OverrideAvailability', requestModel);
  }

  GetAllTherapistForAppointmentsWeekViewDME(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Availability/GetAllTherapistForAppointmentsWeekViewDME_V1?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerTherapistResponseModel>>(url);
  }

  GetWeekViewTherapistAppointmentWeekViewDME(selectedDate, therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel, selectTherapistString, selectBranchString, selectFacilityString, isRefresh) {
    var url = 'Availability/GetAppointmentsByTherapistsWeekViewDME_V1?appointmentDate=' + selectedDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + selectTherapistString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + selectBranchString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + selectFacilityString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    if (isRefresh)
      url = url + '&rnd=' + Math.random().toString();

    return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  GetAllAvailableTherapistByBranchAndDate(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Availability/GetAllAvailableTherapistByBranchAndDate_V1?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;
    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      //let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;
    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    return this.get<ResultOfT<TherapistResponseModel[]>>(url);
  }

  GetWeekViewTherapistAppointment(selectedDate, therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel, selectTherapistString, selectBranchString, selectFacilityString, isRefresh) {
    var url = 'Availability/GetAllAppointmentsByTherapists_V1?appointmentDate=' + selectedDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + selectTherapistString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + selectBranchString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + selectFacilityString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;

    if (isRefresh)
      url = url + '&rnd=' + Math.random().toString();

    return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

  GetAllTherapistForAppointmentsByTimeZone(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Availability/GetAllTherapistForAppointmentsByTimeZone_V1?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerTherapistResponseModel>>(url);
  }

  GetAllTherapistForAppointments(therapistAppointmentSearchRequestModel: TherapistAppointmentSearchRequestModel) {
    var url = 'Availability/GetAllTherapistForAppointments_V1?appointmentDate=' + therapistAppointmentSearchRequestModel.appointmentDate;

    if (therapistAppointmentSearchRequestModel.crmTherapistsId) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmTherapistsId);
      url = url + '&crmTherapistsId=' + therapistAppointmentSearchRequestModel.crmTherapistsIdString;
    }


    if (therapistAppointmentSearchRequestModel.crmBranchIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmBranchIds);
      url = url + '&crmBranchIds=' + therapistAppointmentSearchRequestModel.crmBranchIdsString;
    }


    if (therapistAppointmentSearchRequestModel.crmFacilityiIds) {
      let json = JSON.stringify(therapistAppointmentSearchRequestModel.crmFacilityiIds);
      url = url + '&crmFacilityiIds=' + therapistAppointmentSearchRequestModel.crmFacilityiIdsString;

    }
    if (therapistAppointmentSearchRequestModel.isRoadShow)
      url = url + '&isRoadShow=' + therapistAppointmentSearchRequestModel.isRoadShow;

    url = url + '&crmselectedTimeZone=' + therapistAppointmentSearchRequestModel.crmselectedTimeZone;
    return this.get<ResultOfT<SchedulerResponseModel[]>>(url);
  }

}
