import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';

@Component({
  selector: 'app-my-change-password',
  templateUrl: './my-change-password.component.html',
  styleUrls: ['./my-change-password.component.sass']
})
export class MyChangePasswordComponent implements OnInit {

  isGoogleMap: boolean = false;
  constructor(
    private webStorageService: WebStorageService,
    private router: Router
  ) {
    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
  }
  title = 'Change Password';
  ngOnInit() {
  }

  back(){
    if (this.isGoogleMap) {
      this.router.navigate(['/therapist/my-appointments-new']);
    }
    else {
      this.router.navigate(['/therapist/my-appointments']);
    }
  }

}
