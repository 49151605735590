export class SalesOrderSynceRequestModel {
  BrightreeId: string;
  ActualDeliveryDateTimeEnd: Date;
  ActualDeliveryDateTimeStart: Date;
  Classification: string;
  CreateDateTimeStart: Date;
  CreateDateTimeEnd: Date;
  FacilityId: string;
  BranchId: string;
  BranchName: string;
  WIPCreateDateStart: Date;
  WIPCreateDateEnd: Date;
  SalesOrderType: string;
  WipState:string;
  SalseOrderStatus: string ='NewSalesOrder';
  PatientId: string;
}

export class SalesOrderAddSyncRequest {
  CreateDateTimeStart: Date;
  CreateDateTimeEnd: Date;
  BranchId: string;
  BranchName: string;
  WipState: string;
  SalseOrderStatus: string = 'NewSalesOrder';
  ClientId: number;
  UserId: number;
}
