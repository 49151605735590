export class ReminderTypeModel {
  crmReminderTypeId: number;
  crmReminderType: string;
  crmReminderTypeDisplayValue: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}
export class ReminderSettingModel {
  crmReminderSettingId: number;
  crmReminderType: string;
  crmReminderFrequencyType: string;
  crmReminderFrequency: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmWipStatus: string;
  crmWipStatusDisplayValue: string;

}
export class ReminderSettingResponseModel {
  crmReminderSettingId: number;
  crmReminderType: string;
  crmReminderFrequencyType: string;
  crmReminderFrequency: number;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmReminderDurationInMinutes: number;
}
