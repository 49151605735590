import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ViewRef, ViewChildren, QueryList, } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { LazyLoadEvent, MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { SalesOrderSummaryResponseModel } from 'src/app/shared/models/response/appointment/salesorder-summary-response-model';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { AppointmentService } from '../../servicies/appointment.service';
import { SettingsService } from '../../servicies/settings.service';
import { WIPStatesResponseModel } from 'src/app/shared/models/response/appointment/wip-states-response-model';
import { SalesOrderCountsResponseModel } from 'src/app/shared/models/response/appointment/salesorder-counts-response-model';
import { DynamicCols } from 'src/app/shared/models/common/dynamic-cols';
import { SalesOrdersCountModel } from 'src/app/shared/models/response/appointment/salesorder-counts-model';
import { AppointmentSummaryResponseModel } from 'src/app/shared/models/response/appointment/appointment-summary-response-model'
import { AppointmentsCountsResponseModel } from 'src/app/shared/models/response/appointment/appointments-counts-response-model'
import { AppointmentCallResponseModel } from 'src/app/shared/models/request/call-request-model'
import { AppointmentUpdateStatusRequestModel } from 'src/app/shared/models/response/appointment/appointment-updatestatus-request-model';
import { CallConfigModel } from 'src/app/shared/models/response/call-config-model';
import { HttpClient } from '@angular/common/http';
import { QuickFilters } from 'src/app/shared/models/common/custom-filiters';
import { AppointmentCallListResponseModel } from 'src/app/shared/models/request/appointment-call-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
declare const Twilio: any;
import * as moment from 'moment';
import { Table, TableHeaderCheckbox } from "primeng/table";
import { AppointmentCancelRequest } from 'src/app/shared/models/request/appointment-cancel-request';
import { GlobalSearchService } from '../../servicies/globalSearch.service';
import { TwillioCallService } from '../../servicies/twillioCall.service';
import { SalesOrderInfoResponseModel } from 'src/app/shared/models/response/appointment/sales-order-info-reponse-model';
import { PatientInfoResponseModel } from 'src/app/shared/models/response/appointment/patient-info-response-model';
import { DoctorSummaryResponseModel } from 'src/app/shared/models/response/appointment/doctor-summary-response-model';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { UserService } from 'src/app/admin/servicies/user.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { AppointmentInfoReponseModel } from 'src/app/shared/models/response/appointment/appointment-info-reponse-model'
import { AppointmentDetailResponseModel } from 'src/app/shared/models/response/appointment/appointment-detail-response-model';
import { FacilityInfoResponseModel } from 'src/app/shared/models/response/facility/facilityinfo-response-model';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { TwilioDeviceObject } from 'src/app/shared/models/common/twilio-device-object';
import { FeatureGroupResponseModel, featureResponseModel } from 'src/app/shared/models/response/feature-group-response';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Constants } from 'src/app/shared/constants';
import { WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { BTSalesOrderUpdateRequestModel } from 'src/app/shared/models/request/salesorder-update-request-model-brightree';
import { BookAppointmentInfoComponent } from '../../book-appointment-info/book-appointment-info.component';
import { CancelVoidResponseModel } from 'src/app/shared/models/response/settings/settings-classificationtype-response-model';
import { RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { DashboardService } from '../../servicies/dashboard.service';
import { DoctorResponseModel } from 'src/app/shared/models/response/doctor-response-model';
import { FacilityResponseModelDropdownList } from 'src/app/shared/models/response/facility/facility-response-model';
import { TimeZoneAvailableResponseModel } from 'src/app/shared/models/response/time-zone-available-response-model';
import { PrimaryInsuranceProviderResponseModel } from 'src/app/shared/models/response/appointment/primary-insurance-providers-response-model';
import { ModuleType } from 'src/app/shared/enums';
import { UserPreferencesModuleColumnResponseModel } from 'src/app/shared/models/response/user-wise-grid-column-response-model';
import { UserWiseModuleColumn } from 'src/app/shared/models/request/user-wise-column-model-request';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UpdateStatusOfAppointmentComponent } from 'src/app/shared/components/update-status-of-appointment/update-status-of-appointment.component';

export class statusInfo { action: string; reasonComment: string; reason: string; isBrightreeSystemAppUpdate: boolean };
@Component({
  selector: 'app-appointment-urgentcallback',
  templateUrl: './appointment-urgentcallback.component.html',
  styleUrls: ['./appointment-urgentcallback.component.sass'],
  providers: [DialogService]

})
export class AppointmentUrgentCallbackComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  private hasPageHistory: boolean = false;
  patientBrighttreeURL: any;
  salesOrderBrighttreeURL: any;
  facilityVisitTypes: SelectItem[];
  facilityVisitSelectedType: string;
  appointmentsalesOrderStatusMenuitems: MenuItem[];
  isDisabled: boolean = true;
  features: featureResponseModel[];
  //Not Scheduled
  isLockedAppointments: boolean = false;
  //selectedStatus: WIPStatesResponseModel;
  totalRecords: number;
  selectedsalesOrdersCount: number;
  totalPages: number;
  searchRequestModel: SearchRequestModel;
  salesOrders: SalesOrderSummaryResponseModel[];
  selectedsalesOrders: SalesOrderSummaryResponseModel[];
  salesOrderStatus: WIPStatesResponseModel[];
  salesOrderCountsResponseModel: SalesOrderCountsResponseModel;
  voidReasonsResponseModel: CancelVoidResponseModel[];
  reasonsResponseModel: CancelVoidResponseModel[];
  salesOrderCountModels: SalesOrdersCountModel[];
  salesSelectedOrderCountModels: SalesOrdersCountModel;
  appointmentCallListRequestModel: AppointmentCallListResponseModel[];
  wipStatusBTMappingResponseModel: WipStatusBTmapping[];
  dynamicNonSColumns: DynamicCols[];
  selectedNonSColumns: DynamicCols[];
  selectedSalesOrder: SalesOrderSummaryResponseModel;
  selectedTab: string;
  brightreeInternalUserId: number;
  duration: string;
  @ViewChild('searchBox', { static: false }) searchBox: ElementRef;
  @ViewChild('statusDropdown', { static: false }) statusDropdown: ElementRef;
  salesOrderType: string;
  AppointmentOrderType: string;
  callConfigModel: CallConfigModel;
  callPopup: boolean = false;
  callnote: string;
  calldate: Date;
  calltime: Date;
  callId: number;
  reschedulecallId: number;
  SchedulePopup: boolean = false;
  //Scheduled
  totalAppointmentRecords: number;
  totalAppointmentPages: number;
  searchAppointmentRequestModel: SearchRequestModel;
  appointmentsalesOrderStatus: WIPStatesResponseModel[];
  appointments: AppointmentSummaryResponseModel[];
  selectedAppointments: AppointmentSummaryResponseModel[];
  selectedAppointment: AppointmentSummaryResponseModel;
  appointment: AppointmentInfoReponseModel;
  appointmentsCounts: AppointmentsCountsResponseModel;
  selectedAppointmentStatus: WIPStatesResponseModel;
  dynamicColumns: DynamicCols[];
  selectedColumns: DynamicCols[];
  branchResponseModel: BranchResponseModel[];
  selectedNSbranch: BranchResponseModel;
  doctorResponseModel: DoctorResponseModel[];
  selectedDoctorResponseModel: DoctorResponseModel;
  facilityResponseModelDropdownList: FacilityResponseModelDropdownList[];
  selectedfacilityResponseModel: FacilityResponseModelDropdownList;
  selectedTimeZone: TimeZoneAvailableResponseModel[];
  selectedSbranch: BranchResponseModel;
  appointmentCountModels: SalesOrdersCountModel[];
  appointmentSelectedCountModels: SalesOrdersCountModel;
  timeZoneList: TimeZoneAvailableResponseModel[];
  data1: any;
  dataExpressionloading: boolean = false;
  appDateFormat: DateFormatModel;
  @ViewChild("table", { static: false }) private _table: Table;
  @ViewChild('searchBoxAppointment', { static: false }) searchBoxAppointment: ElementRef;
  @ViewChild('statusDropdownAppointment', { static: false }) statusDropdownAppointment: ElementRef;
  @ViewChild("headerCheckBox", { static: false }) private headerCheckBox: TableHeaderCheckbox;
  @ViewChildren('headerCheckBox') childrenComponent: QueryList<TableHeaderCheckbox>;

  index: number = 0;
  connection: any;
  callSID: string;
  device: any;
  mySubscription: any;

  minutes: SelectItem[];
  selectedMinute: SelectItem;
  isBrightreeSystemAppUpdate: Boolean = false;
  isBrightreeSystemAppUpdateSetting: Boolean = false;
  rescheduleCallSID: string;
  isAppointmentDisabled: boolean = false;
  selectedNSFilter: QuickFilters;
  quickNSFilters: QuickFilters[];
  selectedSFilter: QuickFilters;
  quickSFilters: QuickFilters[];
  searchFromNSDate: Date;
  searchToNSDate: Date;
  searchFromSDate: Date;
  searchToSDate: Date;
  customSDate: boolean;
  customNSDate: boolean;
  NsheduledTableDefaltColumns: number = 6;
  sheduledTableDefaltColumns: number = 6;
  scheduleappointment: boolean = true;
  schedulecall: boolean = false;
  actionType: string = "S";
  scheduleAction: string;
  loggedInUserId: number;
  selectedAppointmentType: string = "FacilityVisit";
  appointmentCallResponseModel: AppointmentCallResponseModel;
  selectedfilterIndex = 0;
  selectedfilterSIndex = 1;
  status: boolean = false;
  currentUserBranch: number;
  currentSelectedNSUserBranch: number;
  currentSelectedSUserBranch: number;
  //global search
  searchText: string;
  isGlobalSearch: boolean = false;
  orderObj: any;
  NSSelectedRange: any;
  NSSelectedDateRange: any;
  SSelectedDateRange: any;
  SalesOrderFound: boolean = false;
  AppointmentFound: boolean = false;
  //added for popup

  salesOrderId: number;
  schAppointmentId: number;
  scheduleActionePopUp: string;
  salesOrder: SalesOrderInfoResponseModel;
  isAppointment: boolean;
  isTelehealth: boolean;
  selectedAppointmentTypePopUp: string;
  scheduleAppointment: boolean = false;
  voiceMailAppointment: boolean = false;
  appointmentNextCall: boolean = false;
  isCallStarted: boolean = false;
  IsRescheduleFacilityOption: boolean = false;
  rescheduleFacilityOption: string;
  callocales: any;
  selected: any;
  isBulkRefreshed: boolean = false;
  invalidDates: moment.Moment[] = [];
  iNoShowId: number = 5;
  iCancelId: number = 4;
  voidFrom: string = "";
  isSaleOrder: boolean = false;
  appointmentType: string;
  salesOrderAppIds: any[];
  displayCancelAppointment: boolean;
  primaryInsuranceProviderList: PrimaryInsuranceProviderResponseModel[];
  selectedPrimaryInsuranceProvider: PrimaryInsuranceProviderResponseModel[];
  defaultModeofAppointment: string = "";
  pageSiges: QuickFilters[];
  selectedpage: QuickFilters;

  isSalesOrderBackHistory: boolean = false;
  title: string;

  userPreferenceColumn: UserPreferencesModuleColumnResponseModel[];
  isOnlyFixedColumnVisible: boolean = false;

  ConfirmInvitePatients: boolean = false;
  restrictedSalesOrderIdToSendInvite: { crmSalesOrderAPPId: string, crmPatientName: string, crmBranch: string }[];
  salesOrderIdToSendInvite: any[];
  totalSelectedSendInviteSalesOrderIds: number = 0;
  updateStatusforminfo:statusInfo;
  isMultiSelected:String = 'False';

  ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
    'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  };

  sRanges = {
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    Today: [moment(), moment()],
    Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Next 7 Days': [moment(), moment().add(6, 'days')],
    'Next 30 Days': [moment(), moment().add(29, 'days')],
    'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
  };


  showRangeLabelOnInput: boolean;

  tooltips = [
    { date: moment(), text: 'Today is just unselectable' },
    { date: moment().add(2, 'days'), text: 'Yeeeees!!!' },
  ];

  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some((d) => d.isSame(m, 'day'));
  }

  isTooltipDate = (m: moment.Moment) => {
    const tooltip = this.tooltips.find((tt) => tt.date.isSame(m, 'day'));
    if (tooltip) {
      return tooltip.text;
    } else {
      return false;
    }
  };

  rangeClicked(range): void {
    this.NSSelectedRange = range.label;

  }

  datesUpdated(range): void {
    this.NSSelectedDateRange = range;
  }

  clickEvent(index: number) {

    //this.selectedfilterIndex = this.selectedfilterIndex === index ? -1 : index;
    this.selectedfilterIndex = index;
    //event.currentTarget.className = "appointments-top-block__box active";
    //this.status = false;
  }

  toggelScheduledClass(index: number) {
    this.selectedfilterSIndex = index;
  }

  //Freeze
  cols: any[];
  frozencols: any[];
  colsSch: any[];
  frozencolsSch: any[];
  height: string = "400px";
  height1: string = "400px";
  //Freeze
  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private dialougeService: DialogService,
    private appointmentService: AppointmentService,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private twillioCallService: TwillioCallService,
    messageService: MessageService, private _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private userService: UserService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private dashboardService: DashboardService) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
    this.defaultModeofAppointment = this.webStorageService.getdefaultModeofAppointment();
    //Not schduled  Parameters
    this.totalRecords = 0;
    this.totalPages = 1;
    this.loading = false;
    this.salesOrderType = 'SalesOrderRequest';
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.brightreeInternalUserId = loggedInUser.crmBrightreeUserId;
    this.pageSiges = [
      { name: '20', code: '20' },
      { name: '25', code: '25' },
      { name: '50', code: '50' },
      { name: '100', code: '100' },
    ];
    this.selectedpage = { name: '20', code: '20' };
    this.facilityVisitTypes = [
      { label: '"Same Facility', value: 'SF' },
      { label: 'Other Facility', value: 'OF' },

    ];

    this.minutes = [
      { label: "Schedule Appointment", value: 30 },
      { label: "Schedule Future Callback", value: 60 }
    ];
    this.selectedMinute = { label: "Schedule Appointment", value: 30 };

    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.currentSelectedSUserBranch = this.currentUserBranch;
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.searchRequestModel) {

      this.searchRequestModel = currentNavigationState.searchRequestModel;
      this.index = Number(currentNavigationState.selectedTab);
      this.selectedfilterIndex = currentNavigationState.selectedfilterIndex;
      this.isAppointmentDisabled = currentNavigationState.isBookAppintment ? currentNavigationState.isBookAppintment : false;
      this.hasPageHistory = true;
      this.isSalesOrderBackHistory = true;

      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.mySubscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // Trick the Router into believing it's last link wasn't previously loaded
          this.router.navigated = false;
        }
      });
    }


    this.callocales = {
      format: this.appDateFormat.dateFormat.toUpperCase(), // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: this.appDateFormat.dateFormat.toUpperCase(), // default is format value
      direction: 'ltr', // could be rtl
      weekLabel: 'W',
      separator: ' To ', // default is ' - '
      cancelLabel: 'Cancel', // detault is 'Cancel'
      applyLabel: 'Apply', // detault is 'Apply'
      clearLabel: 'Clear', // detault is 'Clear'
      customRangeLabel: 'Custom range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: 1 // first day is monday
    }

    this.quickNSFilters = [
      { name: 'Quick Filters', code: '' },
      { name: 'Today', code: 'Today' },
      { name: 'Yesterday', code: 'Yesterday' },
      { name: 'Last Week', code: 'LastWeek' },
      { name: 'Last Month', code: 'LastMonth' },
      { name: 'Custom Date', code: 'CustomDate' }
    ];
    this.quickSFilters = [
      // { name: 'Quick Appt. Date Filters', code: '' },
      { name: 'Quick Filters', code: '' },
      { name: 'Today', code: 'Today' },
      { name: 'Yesterday', code: 'Yesterday' },
      { name: 'Last Week', code: 'LastWeek' },
      { name: 'Last Month', code: 'LastMonth' },
      { name: 'Tommorrow', code: 'Tommorrow' },
      { name: 'Next Week', code: 'NextWeek' },
      { name: 'Next Month', code: 'NextMonth' },
      { name: 'Custom Date', code: 'CustomDate' }
    ];


    this.selectedSalesOrder = new SalesOrderSummaryResponseModel();
    this.selectedNonSColumns = new Array();
    this.dynamicNonSColumns = new Array();
    this.dynamicNonSColumns = [

      { fieldNo: 1, field: 'amountDue', sortFieldName: 'AmountDue', header: 'Amount Due', visible: false, style: 'width:15%', type: 'number', },
      { fieldNo: 3, field: 'crmClassificaitonduration', sortFieldName: 'CrmClassificaitonduration', header: 'Appt. Duration', visible: false, style: 'width:8em', type: 'number', },
      { fieldNo: 4, field: 'crmPatientEmail', sortFieldName: 'CrmPatientEmail', header: 'Email', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 5, field: 'crmPatientPhoneNumber', sortFieldName: 'CrmPatientPhoneNumber', header: 'Phone Number', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 17, field: 'crmPolicyHolderName', sortFieldName: 'crmPolicyHolderName', header: 'Primary Insurance', visible: false, style: 'width:8em', type: 'string', },
      { fieldNo: 18, visible: false, field: 'lastCallDate', header: 'Last Call Date & Time', sortFieldName: 'lastCallDate', style: 'width:15%', type: 'Date' },
    ];



    this.getUserPreferenceColumn();

    // Scheduled Paramaters

    this.totalAppointmentRecords = 0;
    this.totalAppointmentPages = 1;
    this.AppointmentOrderType = 'AppointmentRequest';
    this.appointmentsalesOrderStatusMenuitems = new Array();
    // Get page info passed from edit page
    // so can use it to maintain paging and sorting

    this.selectedColumns = new Array();
    this.dynamicColumns = new Array();


    this.selectedDoctorResponseModel = new DoctorResponseModel();
    this.selectedfacilityResponseModel = new FacilityResponseModelDropdownList();
    this.title = "Urgent Calls";
    this.timeZoneList = this.webStorageService.getTimeZoneList();
  }

  async getWipStatusBTmapping() {

    this.loading = true;
    this.settingsService.getWipStatusBtmapping()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.wipStatusBTMappingResponseModel = result.responseModel;
            }
          }

        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async GetRCMConfigurationUrl() {
    this.loading = true;
    this.settingsService.GetRCMConfigurationUrl(Constants.pluginSettingCRMApp)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RCMCofigurationUrl>) => {
        this.processResult<RCMCofigurationUrl>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              let jsonData = result.responseModel
              this.patientBrighttreeURL = jsonData.patientRCMURL;
              this.salesOrderBrighttreeURL = jsonData.salesOrderRCMURL;
            }
          }

        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async getBranches() {
    this.progress.next(true);
    await this.userService.getUserBranches(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result && result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
            if (selectedBranch) {
              this.selectedNSbranch = selectedBranch;
              this.selectedSbranch = selectedBranch;
            }
            else {
              this.selectedNSbranch = this.branchResponseModel[0];
              this.selectedSbranch = this.branchResponseModel[0];;
            }
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onNavigateBrightTreeSalesOrderUrl(crmSalesOrderAPPId: number) {
    let salesOrderUrl = this.salesOrderBrighttreeURL.replace("{{SalesOrderID}}", crmSalesOrderAPPId);
    salesOrderUrl = salesOrderUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);
    window.open(salesOrderUrl, '_blank')
  }

  onNavigateBrightTreePatientUrl(crmPatientId: number) {
    let patienKeyUrl = this.patientBrighttreeURL.replace("{{PatientKey}}", crmPatientId);
    patienKeyUrl = patienKeyUrl.replace("{{InternalUserID}}", this.brightreeInternalUserId);

    window.open(patienKeyUrl, '_blank')
  }


  handleChange(e) {

    this.index = e.index;
  }

  headerCheckboxToggle(e) {

    this.selectedsalesOrders.forEach(value => {

      value.schIsLocked = false;

    });
  }

  async ngOnInit() {
    //await this.getDefaultModeofAppointment();

    this.buildForm();
    await this.getStatus();
    await this.getCancelVoidReasons();
    await this.GetRCMConfigurationUrl();
    this.globalSearchService.changeGlobalBranch.subscribe((branchId: number) => {

      if (branchId != null && branchId >= 0) {
        this.salesSelectedOrderCountModels = null;
        this.appointmentSelectedCountModels = null;
        this.selectedfilterIndex = 0;
        this.selectedfilterSIndex = -1;
        this.currentUserBranch = branchId;
        this.currentSelectedNSUserBranch = branchId;
        this.currentSelectedSUserBranch = branchId;
        this.initializeSearchRequestModel();
        this.getsalesorders();

        let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentUserBranch);
        if (selectedBranch) {
          this.selectedNSbranch = selectedBranch;
          this.selectedSbranch = selectedBranch;
        }
        else {
          this.selectedNSbranch = this.branchResponseModel[0];
          this.selectedSbranch = this.branchResponseModel[0];;
        }

      }
    });

    this.setPermissionforActions();

    this.isTelehealth = false;
    this.salesOrder = new SalesOrderInfoResponseModel();
    this.salesOrder.patientInfo = new PatientInfoResponseModel();
    this.salesOrder.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment = new AppointmentInfoReponseModel();
    this.appointment.salesOrderInfo = new SalesOrderInfoResponseModel();
    this.appointment.salesOrderInfo.patientInfo = new PatientInfoResponseModel();
    this.appointment.salesOrderInfo.doctorInfo = new DoctorSummaryResponseModel();
    this.appointment.appointmentDetailInfo = new AppointmentDetailResponseModel();
    this.appointment.appointmentDetailInfo.facilityInfo = new FacilityInfoResponseModel();

    if (!this.isSalesOrderBackHistory) {
      this.initializeSearchRequestModel();
    }
    this.isSalesOrderBackHistory = false;

    await this.getWipStatusBTmapping();
    await this.getBranches();
    await this.getAllSalesOrderDoctors();
    await this.getAllSalesOrderDoctorFacility();
    this.webStorageService.deleteRedirectFrom();
    await this.getAllPrimaryInsuranceProviders();

    if (this.searchRequestModel) {
      if (this.searchRequestModel.crmBranchId != null && this.searchRequestModel.crmBranchId != 0) {
        this.selectedNSbranch = this.branchResponseModel.find(x => x.crmBranchId === this.searchRequestModel.crmBranchId);
      }
      if (this.searchRequestModel.dictionaryFilter != null && this.searchRequestModel.dictionaryFilter.OrderingDoctor != null) {
        this.selectedDoctorResponseModel = this.doctorResponseModel.find(x => x.crmDoctorId === parseInt(this.searchRequestModel.dictionaryFilter.OrderingDoctor));
      }
      if (this.searchRequestModel.dictionaryFilter != null && this.searchRequestModel.dictionaryFilter.OrderingDoctorFacility != null) {
        this.selectedfacilityResponseModel = this.facilityResponseModelDropdownList.find(x => x.crmFacilityId === parseInt(this.searchRequestModel.dictionaryFilter.OrderingDoctorFacility));
      }
      if (this.searchRequestModel.selectedTimeZone != undefined) {
        let timeZones = new Array<string>();
        let selectedTime = new Array<TimeZoneAvailableResponseModel>();
        timeZones = this.searchRequestModel.selectedTimeZone.split(',');
        timeZones.forEach(x => {
          let t = this.timeZoneList.find(y => y.appTimeZoneOffSet === x);
          selectedTime.push(t);
        });
        this.selectedTimeZone = selectedTime;
      }
      if (this.searchRequestModel.selectedPrimaryInsurance != undefined) {
        let insurnace = new Array<string>();
        let selectedInsurance = new Array<PrimaryInsuranceProviderResponseModel>();
        insurnace = this.searchRequestModel.selectedPrimaryInsurance.split(',');
        insurnace.forEach(x => {
          let t = this.primaryInsuranceProviderList.find(y => y.crmPolicyHolderName === x);
          selectedInsurance.push(t);
        });
        this.selectedPrimaryInsuranceProvider = selectedInsurance;
      }
      if (this.searchRequestModel.searchFromDate != null && this.searchRequestModel.searchToDate != null) {
        let dt = { 'startDate': this.searchRequestModel.searchFromDate, 'endDate': this.searchRequestModel.searchToDate };
        this.NSSelectedDateRange = dt;
      }
    }

  }
  //Permission Check
  isSendInvitationPermission: boolean = false;
  isAppointmentScheduledPermission: boolean = false;
  isAppointmentRescheduledPermission: boolean = false;
  isAppointmentCancelPermission: boolean = false;
  isAppointmentMovedPermission: boolean = false;
  isAppointmentOverduePermission: boolean = false;
  isAppointmentNoshowPermission: boolean = false;
  isAppointmentCompletedPermission: boolean = false;
  isFacilitySchedulePermission: boolean = false;
  isAppointmentVoidPermission: boolean = false;
  setPermissionforActions() {
    this.features = this.webStorageService.getPermissions();
    if (this.features && this.features.length > 0) {

      let inPer = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentinvitation');
      if (inPer && inPer.isActive == true)
        this.isSendInvitationPermission = true;

      let inPer1 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentscheduled');
      if (inPer1 && inPer1.isActive == true) {
        this.isAppointmentScheduledPermission = true;
        this.isAppointmentRescheduledPermission = true;
      }

      let inPer2 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcancel');
      if (inPer2 && inPer2.isActive == true)
        this.isAppointmentCancelPermission = true;

      let inPer4 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentmoved');
      if (inPer4 && inPer4.isActive == true)
        this.isAppointmentMovedPermission = true;

      let inPer5 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentoverdue');
      if (inPer5 && inPer5.isActive == true)
        this.isAppointmentOverduePermission = true;

      let inPer6 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentnoshow');
      if (inPer6 && inPer6.isActive == true)
        this.isAppointmentNoshowPermission = true;

      let inPer7 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentcompleted');
      if (inPer7 && inPer7.isActive == true)
        this.isAppointmentCompletedPermission = true;

      let inPer8 = this.features.find(x => x.appModuleFeatureName.toLowerCase() == 'appointmentvoid');
      if (inPer8 && inPer8.isActive == true)
        this.isAppointmentVoidPermission = true;

    }
  }


  onBranchChange(event) {

    if (event.value) {
      this.currentSelectedNSUserBranch = Number(event.value.crmBranchId);
      this.searchRequestModel.crmBranchId = Number(event.value.crmBranchId);
    }
  }

  onSelectionChange(selection: any[]) {

    for (let i = selection.length - 1; i >= 0; i--) {
      let data = selection[i];
      if (this.isRowDisabled(data)) {
        selection.splice(i, 1);
      }
    }
    this.selectedsalesOrders = selection;
    if (this.selectedsalesOrders.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  onSelectionSChange(selection: any[]) {
    this.selectedAppointments = selection;
    if (this.selectedAppointments.length > 0)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  ngAfterViewInit() {

    this.childrenComponent.changes.subscribe((comps: QueryList<TableHeaderCheckbox>) => {
      if (comps.length > 0) {
        const orig_updateCheckedState = this.headerCheckBox.updateCheckedState;
        const me = this;
        this.headerCheckBox.updateCheckedState = function () {

          const cars: any[] = me._table.filteredValue || me._table.value;
          const selection: any[] = me._table.selection;
          for (const car of cars) {
            if (!me.isRowDisabled(car)) {
              const selected = selection && selection.indexOf(car) >= 0;
              if (!selected) return false;
            }
          }
          if (selection && selection.length > 0)
            return true;
        };
      }
    });


    //this.loading = false;
    this.setFilters();
    // this.hasPageHistory = false;


    const frozenBody: HTMLElement | null = document.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea: HTMLElement | null = document.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody && scrollableArea) {
      frozenBody.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
        const canScrollUp = 0 < scrollableArea.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea.scrollTop -= scrollDelta;
        }
      });
    }
  }

  isRowDisabled(data: any): boolean {
    return data.schIsLocked === true
  }

  private setFilters() {
    if (!this.hasPageHistory)
      return;

    if (this.searchRequestModel.dictionaryFilter['SearchText'])
      this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'];

  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }

    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }

    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
    }


    if (this.progress)
      this.progress.complete();
  }

  //Start : Non Scheduled Events

  onView(salesOrderId: string, salesorderStatus: string, schAppointmentId: number) {
    if (schAppointmentId > 0) {
      let setUrl = '/admin/appointments/detail/' + schAppointmentId + '/urgentcalls';
      this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterIndex } });
    }
    else {
      let setUrl = '/admin/appointments/salesorders/detail/' + salesOrderId + '/urgentcalls';
      this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });
    }
  }

  showAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;
    this.indexActionTab = 0;
    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isScheduleOrder: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: this.voidFrom ? "P" : this.voidFrom,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();

    });


  }

  showCancelAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;
    this.indexActionTab = 0;
    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: true,
        isCall: false,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();

    });


  }

  showVoidAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;
    this.indexActionTab = 0;
    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: true,
        isCancelOrder: false,
        isCall: false,
        voidFrom: this.voidFrom ? "P" : this.voidFrom,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();

    });


  }

  showCallAppointmentDialog(crmSalesOrderId, schAppointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = true;
    this.indexActionTab = 0;
    this.schAppointmentId = schAppointmentId;
    this.scheduleActionePopUp = this.isAppointment ? 'Reschedule' : 'Schedule';
    //this.getAppointmentInfo(this.schAppointmentId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        isScheduleOrder: false,
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: true,
        isAppointment: true,
        schAppointmentId: schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.HangUpCall();

    });


  }

  recheduleFacilityVisitOptions() {

    if (this.selectedAppointmentTypePopUp == "FacilityVisit") {
      this.rescheduleFacilityOption = "SF";
      this.IsRescheduleFacilityOption = true;
    }
    else
      this.IsRescheduleFacilityOption = false;
  }

  selectNonScheduleSalesOrder(event, selSalesOrder: SalesOrderSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedSalesOrder = selSalesOrder;
    overlaypanel.toggle(event);
  }

  AddNonSColumnInTable(event, col: UserPreferencesModuleColumnResponseModel[]) {
    this.loading = true;
    let isChecked = event.checked;

    let arruserModuleColumn = new Array();
    let IsAnyColVisible = col.filter(x => x.preferenceVisible === true);
    if (IsAnyColVisible.length == 0) {
      this.isOnlyFixedColumnVisible = true;
    }
    else {
      this.isOnlyFixedColumnVisible = false;
    }
    this.userPreferenceColumn.forEach(x => {
      x.visible = x.preferenceVisible;
    })
    col.forEach(x => {
      let userModuleColumn = new UserWiseModuleColumn();
      userModuleColumn.UserInfoId = this.loggedInUserId;
      userModuleColumn.ModuleId = x.moduleId;
      userModuleColumn.ColumnName = x.field;
      userModuleColumn.IsActive = x.preferenceVisible;
      userModuleColumn.Id = x.userModuleColumnId;
      userModuleColumn.UserPreferencesDisplayOrder = col.findIndex(y => y.id === x.id);
      arruserModuleColumn.push(userModuleColumn);
    });

    this.appointmentService.addUserPreferenceColumn(arruserModuleColumn)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage("Column display settings save successfully.")
            }
          }
        });

      }, (httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });

  }



  onSearchButtonClick() {
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    //this.searchRequestModel.dictionaryFilter = {}
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    let startDate = this.NSSelectedDateRange.startDate;
    let endDate = this.NSSelectedDateRange.endDate;

    if (startDate && endDate) {
      startDate = moment(startDate).toDate();
      endDate = moment(endDate).toDate();
      if (startDate > endDate) {
        this.showWarningMessage("From Date should not be greater than To Date.");
        return;
      }
    }
    if (startDate && endDate) {
      this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
      this.searchRequestModel.searchFromDate = startDate;
      this.searchRequestModel.searchToDate = endDate;
    }

    this.resetPageIndex();
    this.getsalesorders();
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel != null && this.searchRequestModel != undefined)
      return this.searchRequestModel.PageSize;

    return this.totalRecords;
  }




  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    //if (!this.hasPageHistory) {

    this.searchRequestModel.FirstRecordIndex = event.first;
    this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
    this.searchRequestModel.PageSize = Number(this.selectedpage.code);

    //this.searchRequestModel.FirstRecordIndex = event.first;
    //this.searchRequestModel.CurrentPageIndex = event.first / 20;
    //this.searchRequestModel.PageSize = 20;
    this.searchRequestModel.SortByColumnName = event.sortField;
    this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
    this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    // }
    // else {
    //   this.searchRequestModel.CurrentPageIndex = event.first / event.rows;
    // }

    this.getsalesorders();
  }


  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory && this.index === 0)
      return;

    if (this.searchRequestModel) {
      this.searchRequestModel = new SearchRequestModel();
    }
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch != undefined ? this.currentSelectedNSUserBranch : 0;

    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;;
    if (this.isGlobalSearch) {
      this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    }

    this.searchRequestModel.appUserInfoId = this.loggedInUserId;
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = "crmPatientName";
  }



  private getsalesorders() {
    this.loading = true;
    this.dataExpressionloading = true;
    this.appointmentService.GetAllUrgentCallBackSalesOrders(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<SalesOrderSummaryResponseModel>>) => {
        this.processResult<PagedResponseModel<SalesOrderSummaryResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.salesOrders = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;


                if (!this.searchRequestModel.PageSize)
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;

                this.isBulkRefreshed = this.dataExpressionloading = this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
        this.dataExpressionloading = false;
      });
  }

  //ViewLockedAppoinments() {
  //  //this.router.navigate(['/admin/appointments/view-locked-appointments/'], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index } });
  //  this.router.navigate(['/admin/appointments/view-locked-appointments/'], { state: { selectedTab: this.index } });
  //}



  //AddColumnInTable(fieldNo) {
  //  this.selectedColumns = new Array();
  //  let objselected = this.dynamicColumns.filter(x => x.fieldNo === fieldNo);
  //  if (objselected.length > 0)
  //    this.selectedColumns.push(objselected[0]);
  //}

  AddColumnInTable(event, fieldNo) {
    //this.selectedNonSColumns = new Array();
    //let objselected = this.dynamicNonSColumns.filter(x => x.fieldNo === fieldNo);
    //if (objselected.length > 0)
    //  this.selectedNonSColumns.push(objselected[0]);


    this.colsSch.forEach(value => {

      if (value.fieldNo == fieldNo) {
        let isChecked = event.checked;
        if (isChecked)
          value.visible = true;
        else
          value.visible = false;
      }
    });

    this.selectedColumns = this.colsSch.filter(x => x.visible === true);
    //this.clickedMarker(infowindow);
    // this._cdr.detectChanges();
  }

  onAppointmentView(salesOrderId: number) {

    this.router.navigate(['/admin/appointments/detail/', salesOrderId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterSIndex } });
  }

  selectScheduleSalesOrder(event, selectedAppointment: AppointmentSummaryResponseModel, overlaypanel: OverlayPanel) {

    this.selectedAppointment = selectedAppointment;
    overlaypanel.toggle(event);
  }




  ViewSalesOrder() {
    if (this.isAppointment)
      this.router.navigate(['/admin/appointments/detail/', this.schAppointmentId], { state: { searchAppointmentRequestModel: this.searchAppointmentRequestModel, selectedTab: this.index, selectedfilterIndex: this.selectedfilterSIndex } });
    else
      this.router.navigate(['/admin/appointments/salesorders/detail/', this.salesOrderId], { state: { searchRequestModel: this.searchRequestModel, selectedTab: this.index, isBookAppintment: this.isAppointmentDisabled, selectedfilterIndex: this.selectedfilterIndex } });

  }

  //End : Non Scheduled Events

  filterShowHide: boolean = false;
  filterShowHideSchedule: boolean = false;
  filterShowHideEvent() {
    this.filterShowHide = !this.filterShowHide;
    if (this.filterShowHide) {
      this.height = "450px";
    }
    else {
      this.height = "400px";
    }
    //if (this.filterShowHide == false)
    //  this.clearSalesOrderFilters();
  }

  public clearSalesOrderFilters() {

    this.currentSelectedNSUserBranch = this.currentUserBranch;
    this.searchRequestModel.crmBranchId = this.currentSelectedNSUserBranch;
    this.NSSelectedDateRange = null;


    let selectedBranch = this.branchResponseModel.find(x => x.crmBranchId == this.currentSelectedNSUserBranch);
    if (selectedBranch) {
      this.selectedNSbranch = selectedBranch;

    }
    else {
      this.selectedNSbranch = this.branchResponseModel[0];

    }

    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    this.resetPageIndex();
    this.getsalesorders();
  };



  AppointmentBookingPopup: boolean = false;
  ReassignAppointmentDialog() {
    this.AppointmentBookingPopup = true;
  }
  EmailEditStatus: boolean = false;
  PhoneEditStatus: boolean = false;
  patientEmail: string = "";
  patientPhone: string = "";

  EditEamil() {
    this.EmailEditStatus = !this.EmailEditStatus;
  }

  EditPhone() {
    this.PhoneEditStatus = !this.PhoneEditStatus;
  }

  cancelEmail() { this.EmailEditStatus = !this.EmailEditStatus; }
  cancelPhone() { this.PhoneEditStatus = !this.PhoneEditStatus; }

  indexActionTab: number = 0;

  showDialog(crmSalesOrderId, appointmentId) {

    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.schAppointmentId = appointmentId;
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = this.schAppointmentId > 0 ? true : false;
    this.indexActionTab = 0;
    this.scheduleActionePopUp = this.schAppointmentId > 0 ? 'Reschedule' : 'Schedule';

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: this.schAppointmentId > 0,
        isScheduleOrder: true,
        isAppointment: false,
        schAppointmentId: this.schAppointmentId,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: false,
        voidFrom: this.voidFrom ? "P" : this.voidFrom,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.getsalesorders();
      this.HangUpCall();

    });
  }


  showVoidSalesOrderDialog(crmSalesOrderId, appointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = appointmentId > 0;
    this.schAppointmentId = appointmentId;
    this.indexActionTab = 0;
    this.scheduleActionePopUp = appointmentId > 0 ? 'Reschedule' : 'Schedule';
    //this.getSalesOrderInfo(this.salesOrderId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: appointmentId > 0,
        isScheduleOrder: false,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: true,
        isCancelOrder: false,
        isCall: false,
        voidFrom: this.voidFrom ? "P" : this.voidFrom,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.getsalesorders();
      this.HangUpCall();

    });

  }


  get fVoid() { return (<FormGroup>this.form.get('formCancelAppointment')).controls; }

  get formCancelAppointment() {
    if (this.form != undefined)
      return this.form.get('formCancelAppointment');
  }

  private getValuesFromVoidForm(): AppointmentCancelRequest {
    const form = this.formCancelAppointment;
    const cancelRequestModel = new AppointmentCancelRequest();
    var reason = form.get(this.formFields.voidReason).value;
    cancelRequestModel.schCancelReason = reason.salesOrderVoidReason;
    cancelRequestModel.schComments = form.get(this.formFields.voidReasonComments).value;
    return cancelRequestModel;
  }

  private getValuesFromUpdateStautsForm(): AppointmentCancelRequest {    
    const cancelRequestModel = new AppointmentCancelRequest();
    cancelRequestModel.schCancelReason = this.updateStatusforminfo?.reason;
    cancelRequestModel.schComments = this.updateStatusforminfo?.reasonComment;
    return cancelRequestModel;
  }

  async getCancelVoidReasons() {

    this.loading = true;
    this.settingsService.getCancelVoidReasons()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          if (result && result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.reasonsResponseModel = result.responseModel;
              const myClonedArray = [];
              this.reasonsResponseModel.forEach(val => myClonedArray.push(Object.assign({}, val)));
              this.voidReasonsResponseModel = myClonedArray.filter(x => x.salesOrderReasonType === 'Void');
            }
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  formFields = {
    voidReason: 'voidReason',
    voidReasonComments: 'voidReasonComments',
  };

  validationMessages = {
    voidReason: {
      required: 'Void reason is required',
    },
    voidReasonComments: {
      required: 'Comment is required',
    },
  };


  private buildForm() {

    const formVoidGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReason, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formVoidGroup.addControl(this.formFields.voidReasonComments, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));


    this.form = this.formBuilder.group({
      formCancelAppointment: formVoidGroup
    });
  }

  cancelVoidAppointment() {
    const form = this.formCancelAppointment;
    //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
    form.get(this.formFields.voidReason).setValue('');
    form.get(this.formFields.voidReasonComments).setValue('');
    form.reset();
    this.displayVoidAppointment = false;
    this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
  }

  voidAppointment() {

    if (this.salesOrderAppIds != null && this.salesOrderAppIds.length > 0) {
      this.formCancelAppointmentSubmitted = true;
      /* if (this.formCancelAppointment.invalid) {
        this.markFormAsTouched();
        return;
      } */
      this.CancelVoidSaleOrderSubmit();
      return;
    }
    this.formCancelAppointmentSubmitted = true;
    /* if (this.formCancelAppointment.invalid) {
      this.markFormAsTouched();
      return;
    } */
    /* this.formCancelAppointmentSubmitted = true;

    if (this.formCancelAppointment.invalid) {
      this.markFormAsTouched();
      return;
    } */
    this.formCancelAppointmentSubmitted = false;
    const cancelRequestModel = this.getValuesFromUpdateStautsForm();
    cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    cancelRequestModel.schAppointmentId = this.isRescheduledRequest ? this.voidschAppointmentId : this.voidSalesOrderId;
    cancelRequestModel.voidFrom = this.voidFrom == "A" ? this.voidFrom : "P";

    let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
    voidAppointmentRequestBT.IsUpdateToBrightree = false;
    voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
    voidAppointmentRequestBT.IsOrderNoteOverride = true;
    voidAppointmentRequestBT.OrderNote = null;
    voidAppointmentRequestBT.DeliveryNote = null;
    voidAppointmentRequestBT.PractitionerName = null;
    voidAppointmentRequestBT.PractitionerId = 0;
    voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
    voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
    voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
    voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();
    if (cancelRequestModel.voidFrom === 'P') {

      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    else {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
        voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
        voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
        voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
      }
    }
    this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
    voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
    cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;
    let appointmentRequest = this.isRescheduledRequest ? this.appointmentService.VoidAppointment(cancelRequestModel) : this.appointmentService.VoidSalesOrder(cancelRequestModel);
    //this.appointmentService.CancelAppointment(cancelRequestModel)
    this.loading = true;
    appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel) {
            this.showSuccessMessage("Appointment has been voided");
            const form = this.formCancelAppointment;
            //form.get(this.formFields.deviceName).setValue(this.selectedDeviceModel.deviceName);
            form.get(this.formFields.voidReason).setValue('');
            form.get(this.formFields.voidReasonComments).setValue('');
            form.reset();
            this.selectedsalesOrders = [];
            if (this.selectedsalesOrders.length > 0)
              this.isDisabled = false;
            else
              this.isBulkRefreshed = this.isDisabled = true;
            this.resetPageIndex();
            this.getsalesorders();

            this.voidschAppointmentId = null; this.voidSalesOrderId = null;
            this.displayVoidAppointment = false;
            this._cdr.detectChanges();
            this.loading = false
          }
        }
        else {
          this.loading = false
          if (result.message)
            this.showErrorMessage(result.message);
          else
            this.showErrorMessage("There was problem in cancelling your appointment.");
        }
      });

  }
  formCancelAppointmentSubmitted: boolean = false;
  displayVoidAppointment: boolean;
  cancelschAppointmentId: number;
  voidSalesOrderId: number;
  voidschAppointmentId: number;
  isRescheduledRequest: boolean;

  showVoidAppointment(schAppointmentId) {
    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
    if (selectedWipStaus) {
      this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
      this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    }
    this.voidschAppointmentId = schAppointmentId;
    this.isRescheduledRequest = true;
    //this.displayVoidAppointment = true;
    let action:string = 'Void';
    let popupHeader:string=  'Update Void Status of Appointment(s)';
    this.callUpdateStatusForm(action, popupHeader);
    
  }

  onBTSelect(status: any) {
    if (status.checked) {
      this.isBrightreeSystemAppUpdate = true;
    }
    else {
      this.isBrightreeSystemAppUpdate = false;
    }

  }

  showVoidSalesOrder(crmSalesOrderId) {
    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
    if (selectedWipStaus) {
      this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
      this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    }
    this.voidSalesOrderId = crmSalesOrderId;
    this.isRescheduledRequest = false;
    //this.displayVoidAppointment = true;
    let action:string = 'Void';
    let popupHeader:string=  'Update Void Status of Appointment(s)';
    this.callUpdateStatusForm(action, popupHeader);
  }

  async HangUpCall() {
    if (this.device) {
      this.device.disconnectAll();
      this.isCallStarted = false;
    }
  }
  showCallSalesOrderDialog(crmSalesOrderId, appointmentId) {
    if (!this.checkCall()) {
      this.showWarningMessage('Only one patient can be called at one time.');
      return;
    }
    this.salesOrderId = crmSalesOrderId;
    this.isAppointment = appointmentId > 0;
    this.schAppointmentId = appointmentId;
    this.indexActionTab = 0;
    this.scheduleActionePopUp = appointmentId > 0 ? 'Reschedule' : 'Schedule';
    //this.getSalesOrderInfo(this.salesOrderId);

    const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
      showHeader: true,
      closable: true,
      styleClass: 'book-appt-popup-outer',
      data: {
        crmSalesOrderId: crmSalesOrderId,
        isRescheduledRequest: appointmentId > 0,
        isScheduleOrder: false,
        isAppointment: false,
        schAppointmentId: 0,
        isVoidOrder: false,
        isCancelOrder: false,
        isCall: true,
        voidFrom: this.voidFrom ? "P" : this.voidFrom,
        defaultModeofAppointment: this.defaultModeofAppointment
      },
    });

    ref.onClose.subscribe(() => {
      this.getsalesorders();
      this.HangUpCall();
    });



  }

  checkCall(): boolean {
    let check = true;
    this.twillioCallService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

      if (twillioObject) {
        check = false;
      }
      else {
        check = true;
      }
    });
    return check;

  }

  ExportCancelAppointment() {
    if (this.loading)
      this.loading = false;

    let schSalesOrderIds: any[];
    if (this.selectedsalesOrders != undefined && this.selectedsalesOrders.length > 0) {

      schSalesOrderIds = new Array()
      this.selectedsalesOrders.forEach(value => {
        schSalesOrderIds.push(value.crmSalesOrderAPPId.toString());
      })
    }
    if (schSalesOrderIds != undefined && schSalesOrderIds.length > 0) {
      if (schSalesOrderIds.length == 1) {
        this.searchRequestModel.dictionaryFilter['SelectedSchSalesOrderIds'] = schSalesOrderIds.toString();
      }
      else {
        this.searchRequestModel.dictionaryFilter['SelectedSchSalesOrderIds'] = schSalesOrderIds.toString();
      }
    }
    this.loading = true;
    this._cdr.detectChanges();
    this.dataExpressionloading = true;
    this.appointmentService.ExportCancelAppointments(this.searchRequestModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Blob) => {
        const downloadedFile = new Blob([result], { type: result.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        a.download = 'CancelAppointments.csv';
        //a.download = 'ExportData_' + '.csv';
        document.body.appendChild(a);
        a.target = '_blank';
        a.href = URL.createObjectURL(downloadedFile);
        document.body.removeChild(a);
        a.click();
      }, (httpResponseMessage) => {
        this.showErrorMessage(this.getErrorMessage(httpResponseMessage));
      });
  }

  closeSearchFilter(event, element) {
    element.hide(event);
  }

  onSOFilterSearch() {

    if (this.NSSelectedDateRange) {
      /*this.searchRequestModel.dictionaryFilter = {}*/

      let startDate = this.NSSelectedDateRange.startDate;
      let endDate = this.NSSelectedDateRange.endDate;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }
      if (startDate && endDate) {
        this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchRequestModel.searchFromDate = startDate;
        this.searchRequestModel.searchToDate = endDate;
      }
    }
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    if (this.selectedDoctorResponseModel != undefined) {
      if (this.selectedDoctorResponseModel.crmDoctorId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();
    }

    if (this.selectedfacilityResponseModel != undefined) {
      if (this.selectedfacilityResponseModel.crmFacilityId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();
    }


    //else {
    //  this.searchRequestModel.dictionaryFilter = {};
    //}
    this.resetPageIndex();
    this.getsalesorders();
  }

  onClearSOFilterSearch() {
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value = '';
    this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = '';
    this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = '';

    this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = '';
    this.searchRequestModel.searchFromDate = null;
    this.searchRequestModel.searchToDate = null;
    this.currentSelectedNSUserBranch = Number(0);
    this.searchRequestModel.crmBranchId = Number(0);
    this.selectedNSbranch = this.branchResponseModel[0];
    this.selectedTimeZone = new Array<TimeZoneAvailableResponseModel>();
    this.selectedPrimaryInsuranceProvider = new Array<PrimaryInsuranceProviderResponseModel>();
    this.selectedDoctorResponseModel = new DoctorResponseModel();
    this.selectedfacilityResponseModel = new FacilityResponseModelDropdownList();
    this.NSSelectedDateRange = null;
  }
  private async getStatus() {
    this.progress.next(true);
    await this.dashboardService.getStatus()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((results) => {
        // To handle when request is cancelled
        if (!results) {
          return;
        }
        if (results.responseModel != undefined && results.responseModel != null) {
          var iNoShow = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow');
          if (iNoShow != null && iNoShow != undefined) {
            this.iNoShowId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'noshow').crmWipstatusId;
          }
          //this.iBookedId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'booked').crmWipstatusId;
          var iCancel = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel');
          if (iCancel != null && iCancel != undefined) {
            this.iCancelId = results.responseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel').crmWipstatusId;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onDoctorChange(event) {
    if (event.value) {
      this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = event.value.crmDoctorId.toString();
    }
  }

  onFacilityChange(event) {
    if (event.value) {
      this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = event.value.crmFacilityId.toString();
    }
  }

  onTimeZoneChange(event) {
    let timeZones = new Array<string>();
    if (event !== undefined && event != null) {
      this.selectedTimeZone.forEach((value: TimeZoneAvailableResponseModel) => {
        timeZones.push(value.appTimeZoneOffSet);
      });
      let arrayTimeZone = timeZones.join();
      this.searchRequestModel.selectedTimeZone = arrayTimeZone;
    }
  }

  onPrimaryInsurationProviderChange(event) {
    let primaryInsuranceProvider = new Array<string>();
    if (event !== undefined && event != null) {
      this.selectedPrimaryInsuranceProvider.forEach((value: PrimaryInsuranceProviderResponseModel) => {
        primaryInsuranceProvider.push(value.crmPolicyHolderName);
      });
      let arrayprimaryInsuranceProvider = primaryInsuranceProvider.join();
      this.searchRequestModel.selectedPrimaryInsurance = arrayprimaryInsuranceProvider;
    }
  }

  private async getAllSalesOrderDoctors() {
    this.progress.next(true);
    await this.appointmentService.GetAllDoctors()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {

            this.doctorResponseModel = result.responseModel;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getAllSalesOrderDoctorFacility() {
    this.progress.next(true);
    await this.appointmentService.GetAllDoctorFacility()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.facilityResponseModelDropdownList = result.responseModel;
          }
        }

      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  showVoidAppointmentA(schAppointmentId) {
    let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
    if (selectedWipStaus) {
      this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
      this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
    }
    this.voidFrom = 'A';
    this.voidschAppointmentId = schAppointmentId;
    this.isRescheduledRequest = true;
    //this.displayVoidAppointment = true;
    let action:string = 'Void review';
    let popupHeader:string=  'Update Void Review Status of Appointment(s)';
    this.callUpdateStatusForm(action, popupHeader);
  }

  async Unlock(data: SalesOrderSummaryResponseModel, isOther: boolean) {
    if (!isOther) {
      this.dialougeService.open(ConfirmDialogComponent, {
        data:
        {
          message: 'Are you sure you want to unlock appointment ?',
        },
        header: 'Confirmation'
      }).onClose.subscribe((response: boolean) => {
        if (!response) {
          return;
          //this.selectedAppointmentOrderStatus = this.defaultselectedAppointmentOrderStatus;
        }
        this.progress.next(true);
        this.appointmentService.UnLockSalesOrder(data.schAppointmentCallListId)
          .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
          .toPromise()
          .then((result) => {
            this.processResult<boolean>(result, () => {
              this.showSuccessMessage('Sales order unlocked successfully.');
              data.schIsLocked = false;
              this.isRowDisabled(data);

            });
          }).catch((httpResponseError) => {
            this.showErrorMessage(httpResponseError.message);
          });
      });
    }
    else {
      this.progress.next(true);
      await this.appointmentService.UnLockSalesOrder(data.schAppointmentCallListId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise()
        .then((result) => {
          this.processResult<boolean>(result, () => {
            this.showSuccessMessage('Sales order unlocked successfully.');
            data.schIsLocked = false;
            this.isRowDisabled(data);

          });
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }
  }

  CancelVoidAllAppointmentS(type: string, isSaleOrder: boolean) {
    this.isSaleOrder = isSaleOrder;
    this.appointmentType = type;

    this.salesOrderAppIds = new Array()

    if (isSaleOrder) {
      this.salesOrderAppIds = this.selectedsalesOrders;
    }
    else {
      this.salesOrderAppIds = this.selectedAppointments;
    }

    if (!this.salesOrderAppIds || this.salesOrderAppIds.length <= 0) {
      if (isSaleOrder) {
        this.showWarningMessage('Please select sales order(s).');
      } else {
        this.showWarningMessage('Please select appointment(s).');
      }
      this._cdr.detectChanges();
      return;
    }

    this.isRescheduledRequest = false;
    if (type == "voidreview") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      this.displayCancelAppointment = false;
      this.displayVoidAppointment = true;
      this.voidFrom = 'A';
    }
    else if (type == "void") {
      let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
      if (selectedWipStaus) {
        this.isBrightreeSystemAppUpdateSetting = selectedWipStaus.crmIsUpdateToBrigthree;
        this.isBrightreeSystemAppUpdate = !this.isBrightreeSystemAppUpdateSetting;
      }
      this.displayCancelAppointment = false;
      this.displayVoidAppointment = true;
      this.voidFrom = 'P';
    }
    else {
      this.displayVoidAppointment = false;
      this.displayCancelAppointment = true;
    }

    let action:string = 'Void';
    let popupHeader:string=  'Update Void Status of Appointment(s)';
    if (this.displayVoidAppointment == true){
      this.displayVoidAppointment = false;
      if (type == "voidreview"){
        action = 'Void review';
        popupHeader = 'Update Void Review Status of Appointment(s)';
      }       
      this.callUpdateStatusForm(action, popupHeader);

    }
  }

  checkSelectedRecord(){
    this.isMultiSelected = 'False'; 
    if ( (this.selectedsalesOrders != undefined && this.selectedsalesOrders.length > 1)  || 
        (this.selectedAppointments != undefined && this.selectedAppointments.length > 1)){
     this.isMultiSelected = 'True';
    }
  }
  callUpdateStatusForm(action:string, header:string) {
    this.checkSelectedRecord();
    this.dialougeService.open(UpdateStatusOfAppointmentComponent, {
      data:{
        action: action,
        popupHeader: header,
        updateButtonText: 'Yes, Update',
        cancelButtonText: 'No, Cancel',
        reasonsResponseModel: this.voidReasonsResponseModel,
        isMultiSelected: this.isMultiSelected, 
        isBrightreeSystemAppUpdate: this.isBrightreeSystemAppUpdate,
      },
    }).onClose.subscribe((response: any) => {
      if (response.action == 'Confirm') {  
        this.updateStatusforminfo  = response;        
        this.voidAppointment();
      }
      if (response.action == 'Cancel') {          
        this.cancelVoidAppointment();
      }
    });
    /** End Code */
  }

  async SendVoiceMailSelectedSales(isSaleOrder: boolean) {
    this.isSaleOrder = isSaleOrder;
    this.salesOrderAppIds = new Array()

    if (isSaleOrder) {
      this.salesOrderAppIds = this.selectedsalesOrders;
    }
    else {
      this.salesOrderAppIds = this.selectedAppointments;
    }

    console.log(this.salesOrderAppIds);

    if (!this.salesOrderAppIds || this.salesOrderAppIds.length <= 0) {
      if (isSaleOrder) {
        this.showWarningMessage('Please select sales order(s).');
      }
      else {
        this.showWarningMessage('Please select appointment(s).');
      }
      this._cdr.detectChanges();
      return;
    }

    let salesorderIds = "";

    if (this.salesOrderAppIds.length > 0 && this.salesOrderAppIds != undefined) {
      this.salesOrderAppIds.forEach((value, index) => {
        let crmSalesOrderId = this.isSaleOrder ? (this.salesOrderAppIds[index]["crmSalesOrderId"]) : (this.salesOrderAppIds[index]["crmSalesorderId"]);
        if (salesorderIds != "") {
          salesorderIds = salesorderIds + "," + crmSalesOrderId
        }
        else {
          salesorderIds = crmSalesOrderId;
        }
      });

      await this.appointmentService.BulkSendVoiceMail(salesorderIds, this.loggedInUserId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
        .toPromise().then((result) => {
          if (result.responseModel) {
            this.showSuccessMessage('Voice mail has been Sent to Patients.');
            // this.callPopup = false;
            this.isCallStarted = false;
            this.salesOrderAppIds = [];
            this.selectedsalesOrders = [];
            this.selectedAppointments = [];
            this._cdr.detectChanges();
          }
          else {
            this.showWarningMessage('Error occurred while sending voice mail.');
          }
        }).catch((httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
        });
    }

  }

  CancelVoidSaleOrderSubmit() {

    let statusMessage = this.appointmentType == "voidreview" ? "voided for review" : this.appointmentType == "void" ? "voided" : "cancel";
    let statusType = this.isSaleOrder ? "saleorder(s) ?" : "appointment(s) ?";

    this.loading = true;
    const cancelRequestList = [];
    this.salesOrderAppIds.forEach((value, index) => {

      let cancelRequestModel = this.getValuesFromUpdateStautsForm();
      cancelRequestModel.modifiedByUserInfoId = this.loggedInUserId;
      cancelRequestModel.schAppointmentId = this.salesOrderAppIds[index]["schAppointmentId"];
      cancelRequestModel.crmSalesOrderId = this.isSaleOrder ? (this.salesOrderAppIds[index]["crmSalesOrderId"]) : (this.salesOrderAppIds[index]["crmSalesorderId"]);
      cancelRequestModel.SalesOrderStatus = this.appointmentType == "voidreview" ? "VoidReview" : this.appointmentType == "void" ? "Void" : "Cancel";

      let voidAppointmentRequestBT = new BTSalesOrderUpdateRequestModel();
      voidAppointmentRequestBT.IsUpdateToBrightree = false;
      voidAppointmentRequestBT.IsDeliveryNoteOverride = true;
      voidAppointmentRequestBT.IsOrderNoteOverride = true;
      voidAppointmentRequestBT.OrderNote = null;
      voidAppointmentRequestBT.DeliveryNote = null;
      voidAppointmentRequestBT.PractitionerName = null;
      voidAppointmentRequestBT.PractitionerId = 0;
      voidAppointmentRequestBT.ScheduledDeliveryStartDateString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartTimeString = null;
      voidAppointmentRequestBT.ScheduledDeliveryStartDate = null;
      voidAppointmentRequestBT.WIPDateNeededString = moment().toDate().toDateString();
      voidAppointmentRequestBT.WIPDateNeeded = moment().toDate();

      if (this.appointmentType == "voidreview") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'voidreview')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      } else if (this.appointmentType == "void") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'void')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      } else if (this.appointmentType == "cancel") {
        let selectedWipStaus = this.wipStatusBTMappingResponseModel.find(x => x.crmWipstatusNameSa.toLowerCase() == 'cancel')
        if (selectedWipStaus) {
          voidAppointmentRequestBT.WIPStateName = selectedWipStaus.crmWipstatusNameBt;
          voidAppointmentRequestBT.WIPStateKey = selectedWipStaus.wipStatusRCMId;
          voidAppointmentRequestBT.IsUpdateToBrightree = selectedWipStaus.crmIsUpdateToBrigthree;
          voidAppointmentRequestBT.WIPAssignedToKey = selectedWipStaus.crmWIPAssignedToKey;
        }
      }
      this.isBrightreeSystemAppUpdate = this.updateStatusforminfo?.isBrightreeSystemAppUpdate;
      voidAppointmentRequestBT.IsUpdateToBrightree = !this.isBrightreeSystemAppUpdate;
      cancelRequestModel.salesOrderUpdateRequestModelBT = voidAppointmentRequestBT;
      cancelRequestList.push(cancelRequestModel);
    });

    if (this.salesOrderAppIds != null && this.salesOrderAppIds.length > 0) {
      let appointmentRequest = this.appointmentService.UpdateSalesOrderByWipStatus(cancelRequestList);
      appointmentRequest.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {

            const formvoid = this.formCancelAppointment;
            formvoid.get(this.formFields.voidReason).setValue('');
            formvoid.get(this.formFields.voidReasonComments).setValue('');
            formvoid.reset();

            this.displayCancelAppointment = false;
            this.displayVoidAppointment = false;

            this.salesOrderAppIds = [];
            this.selectedsalesOrders = [];
            this.selectedAppointments = [];

            this.showSuccessMessage("Appointments has been " + statusMessage);
            this.getsalesorders();
            this._cdr.detectChanges();
            this.loading = false

          }
          else {
            this.loading = false
            if (result.message)
              this.showErrorMessage(result.message);
            else
              this.showErrorMessage("There was problem in voiding your appointment.");
          }
        });
    }
  }

  private async getAllPrimaryInsuranceProviders() {
    await this.appointmentService.GetAllPrimaryInsuranceProvidersbyUserId(this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise()
      .then((result) => {
        this.processResult<PrimaryInsuranceProviderResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.primaryInsuranceProviderList = result.responseModel;
            }
          }
        });
      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  ConfirmInvitePatientsCancel() {
    this.ConfirmInvitePatients = false;
  }

  SendInvitationforAppointment() {
    if (!this.selectedsalesOrders || this.selectedsalesOrders.length <= 0) {
      this.showWarningMessage('Please select sales order(s) to send an invitation.');
      this._cdr.detectChanges();
      return;
    }

    this.ConfirmInvitePatients = true;
    this.salesOrderIdToSendInvite = new Array()
    this.totalSelectedSendInviteSalesOrderIds = this.selectedsalesOrders.length;
    this.restrictedSalesOrderIdToSendInvite = [];
    this.selectedsalesOrders.forEach(value => {
      if (value.crmBranchIsAllowSelfScheduleAppointments) {
        this.salesOrderIdToSendInvite.push(value.crmSalesOrderId.toString());
      }
      else {
        this.restrictedSalesOrderIdToSendInvite.push(value);
      }
    });
  }

  ConfirmSendInvitePatients() {
    this.loading = true;
    this.appointmentService.SendInvitationForNotScheduled(this.salesOrderIdToSendInvite)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Invitation for appointment booking has been sent.');
          this.selectedsalesOrders = [];
          this.selectedAppointments = [];
          this._cdr.detectChanges();
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    this.ConfirmInvitePatients = false;
  }

  onPageChange(event) {

    if (event.value) {
      this.selectedpage = this.pageSiges.find(x => x.code == event.value.code);
      this.searchRequestModel.PageSize = Number(this.selectedpage.code);
      //this.searchRequestModel.dictionaryFilter = {}
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
      let selSatatus = this.salesSelectedOrderCountModels;
      if (selSatatus) {
        if (selSatatus.salesOrderStatus.toUpperCase() == "TELEHEALTHREQUEST") {
          this.searchRequestModel.dictionaryFilter['Telehealth'] = 'true';
        }
        else if (selSatatus.salesOrderStatus.toUpperCase() == "CLINICAPPOINTMENTREQUEST") {
          this.searchRequestModel.dictionaryFilter['ClinicAppointmentCallback'] = 'true';
        }
        else
          this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.salesOrderStatusDisplayble;
      }
      if (this.selectedDoctorResponseModel.crmDoctorId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctor'] = this.selectedDoctorResponseModel.crmDoctorId.toString();


      if (this.selectedfacilityResponseModel.crmFacilityId > 0)
        this.searchRequestModel.dictionaryFilter['OrderingDoctorFacility'] = this.selectedfacilityResponseModel.crmFacilityId.toString();

      let startDate = this.NSSelectedDateRange.startDate;
      let endDate = this.NSSelectedDateRange.endDate;

      if (startDate && endDate) {
        startDate = moment(startDate).toDate();
        endDate = moment(endDate).toDate();
        if (startDate > endDate) {
          this.showWarningMessage("From Date should not be greater than To Date.");
          return;
        }
      }
      if (startDate && endDate) {
        this.searchRequestModel.dictionaryFilter['CustomDateFilter'] = 'CustomDate';
        this.searchRequestModel.searchFromDate = startDate;
        this.searchRequestModel.searchToDate = endDate;
      }

      this.resetPageIndex();
      this.getsalesorders();
    }

  }
  onBack() {
    this.router.navigate(['admin/dashboard']);
  }

  private async getUserPreferenceColumn() {
    this.loading = true;

    await this.appointmentService.getUserPreferenceMasterColumn(ModuleType.UrgentCall, this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.userPreferenceColumn = [];
            this.userPreferenceColumn = result.responseModel;
            this.userPreferenceColumn.forEach(x => {
              x.preferenceVisible = x.visible;
            })
            let IsAnyColVisible = this.userPreferenceColumn.filter(x => x.visible === true);
            if (IsAnyColVisible.length == 0) {
              this.isOnlyFixedColumnVisible = true;
            }
            else {
              this.isOnlyFixedColumnVisible = false;
            }
          }
        }

      })
      .catch((httpResponseError) => {
        this.loading = false
        this.showErrorMessage(httpResponseError.message);
      });
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.userPreferenceColumn, event.previousIndex, event.currentIndex);
  }
  CancelNonSColumn(event, element) {
    element.hide(event);
    this.getUserPreferenceColumn();
  }
}
