<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
      <a class="edit-btn" (click)="onEdit()">
        <i class="edit-icon-new"></i>
      </a>
    </h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" (click)="onBack()"><i class="back-new"></i> Back</a>
    </div>
  </div>

  <div class="form-outer">
    <div class="details-pages">
      <div class="details-pages__content">
        <span class="details-pages__content__block">
          <strong>Name</strong>
          <span class="value">
            {{facilityDetail?.crmFacilityName }}
          </span>
        </span>
        <span class="details-pages__content__block">
          <!-- <strong>Remote Assistant</strong> -->
          <strong>Remote Setup Facility</strong>
          <span class="value">{{facilityDetail?.isRemoteAssistance ? 'Yes' : 'No'}}</span>
        </span>
        <span class="details-pages__content__block" *ngIf="!isRemoteFacility">
          <strong>Address</strong>
          <span class="value">
            <p *ngIf="facilityDetail?.crmAddressLine1">{{facilityDetail?.crmAddressLine1 }}</p>
            <p *ngIf="facilityDetail?.crmAddressLine2">{{facilityDetail?.crmAddressLine2 }}</p>
            <p *ngIf="facilityDetail?.crmCity">{{facilityDetail?.crmCity  }}</p>
            <p *ngIf="facilityDetail?.crmState">{{facilityDetail?.crmState }}</p>
            <p *ngIf="facilityDetail?.crmFacilityZipCode">{{facilityDetail?.crmFacilityZipCode}}</p>
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="!isRemoteFacility">
          <strong>Contact Person</strong>
          <span class="value">
            {{facilityDetail?.crmFacilityContactPersonFirstName }}
            {{facilityDetail?.crmFacilityContactPersonLastName || '-'}}
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="!isRemoteFacility">
          <strong>Email</strong>
          <span class="value">
            <a href="javascript:void(0)">{{facilityDetail?.crmFacilityContactEmailAddress || '-'}}</a>
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="!isRemoteFacility">
          <strong>Phone Number</strong>
          <span class="value">
            {{facilityDetail?.crmFacilityContactNo | phone}}
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="!isRemoteFacility">
          <strong>Branch</strong>
          <span class="value">{{facilityDetail?.crmBranch?.crmBranchName || '-'}}</span>
        </span>
        <span class="details-pages__content__block">
          <strong>Doctor Exclusion</strong>
          <span class="value">{{facilityDetail?.excludedDoctors || '-'}}</span>
        </span>
        <span class="details-pages__content__block">
          <strong>Therapist Exclusion</strong>
          <span class="value">{{facilityDetail?.excludedTherapists || '-'}}</span>

          <!-- <span class="value value--table" [ngClass]="BranchName ? 'show' : 'hide'">
            <table class="table" >
              <tr *ngFor="let facilityDetail of excludedTherapists?.facilityDetail; let i=index">
                <th>{{i+1 }}</th>
                <td>{{facilityDetail?.excludedTherapists || '-'}}</td>
              </tr>
            </table>
          </span> -->


        </span>
        <span class="details-pages__content__block">
          <strong>Insurance Exclusion</strong>
          <span class="value">{{facilityDetail?.excludedInsurances || '-'}}</span>

        </span>
        <span class="details-pages__content__block">
          <strong>Working Hours</strong>
          <span class="value value--table">
            <span class="value__row"
                  *ngFor="let hours of facilityDetail?.facilityAvailability; let i=index">
              <div *ngIf="hours.crmFacilityAvailWeekDays == 1">
                <strong class="value__row__day">Monday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}} to
                {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 2">
                <strong class="value__row__day">Tuesday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}}
                to {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 4">
                <strong class="value__row__day">Wednesday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}}
                to {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 8">
                <strong class="value__row__day">Thursday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}}
                to {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 16">
                <strong class="value__row__day">Friday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}} to
                {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 32">
                <strong class="value__row__day">Saturday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}}
                to {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
              <div *ngIf="hours.crmFacilityAvailWeekDays == 64">
                <strong class="value__row__day">Saturday</strong>{{hours.crmFacilityAvailFromTimeString | lowercase}}
                to {{hours.crmFacilityAvailToTimeString | lowercase}}
              </div>
            </span>
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Break Hours</strong>
          <span class="value"
                *ngFor="let break of facilityDetail?.facilityAvailability; let i = index">
            <div *ngIf="i==0 && break.crmBreakStartTime">
              {{break?.crmBreakStartTimeString | lowercase }} to {{break.crmBreakEndTimeString | lowercase }}
            </div>
            <div *ngIf="i==0 && !break.crmBreakStartTime">
              No break hours
            </div>
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Status</strong>
          <span class="value">{{facilityDetail?.isActive ? 'Active' : 'Inactive'}}</span>
        </span>
      </div>
    </div>
  </div>

</div>
<!-- End Content -->
