import { TherapistDefaultFacilityResponseModel } from './therapist-default-facility-response-model';
import { TherapistResponseModel } from './therapist-response-model';
export class TherapistDefaultFacilityModel {
  appUserInfoId: string;
  modifiedByUserInfoId: number;
  therapistDefaultFacility: TherapistDefaultFacilityResponseModel;
}

export class SelectedTherapistResponseModel {
  isEnable: boolean;
  therapistResponseModels: TherapistResponseModel[];
}