import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient } from '@angular/common/http';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { UserInfoRequestModel } from 'src/app/shared/models/request/userInfo-request-model';
import { StateMasterResponseModel } from 'src/app/shared/models/common/state-master-response-model';
import { TherapistCertificationResponseModel } from 'src/app/shared/models/response/therapist/therapist-certification-response-model';
import { TherapistLicenseResponseModel } from 'src/app/shared/models/response/therapist/therapist-license-response-model';
import { TherapistResponseModel } from '../models/response/therapist/therapist-response-model';
import { FacilitySearchRequestModel } from '../models/request/facility-search-request';
import { WebStorageService } from './web-storage.service';
import { FacilityInfoResponseModel } from '../models/response/facility/facilityinfo-response-model';
import { FacilityTimeSlotRequest } from '../models/request/facility-slots-request';
import { FacilitySearchResponseModel } from 'src/app/shared/models/response/appointment/facility-search-response';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TherapistService extends BaseService {

  constructor(httpClinet: HttpClient, webStorageService: WebStorageService) {
    super(httpClinet, webStorageService);
  }

  getTherapists() {
    return this.get<ResultOfT<UserInfoResponseModel[]>>('Therapist/GetAllTherapist');
  }
  GetStates() {
    return this.get<ResultOfT<StateMasterResponseModel[]>>('Therapist/GetStates');
  }


  getTherapistById(therapistId: number) {
    return this.get<ResultOfT<UserInfoResponseModel>>('Therapist/GetTherapistById?userInfoId=' + therapistId + '&rnd=' + Math.random().toString());
  }

  addTherapist(newtherapist: UserInfoRequestModel) {
    return this.post<ResultOfT<boolean>>('Therapist/Add', newtherapist);
  }

  updateTherapist(updatedTherapist: UserInfoRequestModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapist', updatedTherapist);
  }

  removeTherapist(therapistId: number) {
    return this.get<ResultOfT<boolean>>('Therapist/ActiveInactive/' + therapistId + '/' + false);
  }

  AddTherapistLicense(newtherapist: TherapistLicenseResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistLicense', newtherapist);
  }
  UpdateTherapistLicense(newtherapist: TherapistLicenseResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistLicense', newtherapist);
  }
  DeleteTherapistLicense(therapistLicenseInfoId: number) {
    return this.get<ResultOfT<boolean>>('Therapist/DeleteTherapistLicense/' + therapistLicenseInfoId);
  }

  AddTherapistCertification(newtherapist: TherapistCertificationResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/AddTherapistCertification', newtherapist);
  }
  UpdateTherapistCertification(newtherapist: TherapistCertificationResponseModel) {
    return this.post<ResultOfT<boolean>>('Therapist/UpdateTherapistCertification', newtherapist);
  }
  DeleteTherapistCertification(therapistCertificationId: number) {
    return this.get<ResultOfT<boolean>>('Therapist/DeleteTherapistCertification/' + therapistCertificationId);
  }

  SearchTherapistFacilitiesForAppointment(therapistId: number, dtDate: string) {
    return this.get<ResultOfT<any[]>>('Availability/GetTherapistAvailabilityCalendar?therapistId=' + therapistId + '&dtDate=' + dtDate);
  }

  ValidationToCheckTherapistLicensewithDefaultFacilityAndAvailiblity(therapistId: string, facilityId: string, dtDate: string, stateCode:string) {
    return this.get<ResultOfT<boolean>>('Therapist/ValidationToCheckTherapistLicensewithDefaultFacilityAndAvailiblity/' + therapistId + '/' + facilityId + '/' +  dtDate + '/' +  stateCode);
  }

}