import { FacilityTimeSlotResponseModel } from "./facility-search-response";

export class DisplaySlots {
  isToday: boolean;
  date: Date;
  time: DisplaySlotsTime[];
  isDisabled: boolean;
  count: number;
  facilityName: string;
  remoteFacilityName: string;
  facilityAddress: string;
  IsAppointment: boolean;
}

export class FacilityDisplaySlots{
  facilityname:string;
  displaySlot:DisplaySlots;
  facilitySlotForFilter: FacilityTimeSlotResponseModel[];
  isGlobalHoliday: boolean;
  globalHolidayMsg: string;
  isAllSlotsBooked: boolean;
}


export class DisplaySlotsTime {
  dayTime: Date;
  origStartTime: Date;
  origEndTime: Date;
  dayTimeString: string;
  isSelected: boolean;
  isDisabled: boolean;
  isBlock1: boolean;
  count: number;
  slotType: string = '';
  facilityId: number;
  remoteFacilityId: number;
  isRemoteAndFacility: boolean;
  therapistIds: string;
  therapistTimeZone: string;
  displayStartTime: Date;
  displayEndTime: Date;
  facilityAddress: string;
  IsAppointment: boolean;
}



export class Months {
  month: string;
}
