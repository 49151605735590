import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subject} from 'rxjs';
import { GlobalSearchService } from 'src/app/admin/servicies/globalSearch.service';
import { TwilioDeviceObject, AppoinmentDialogueParametersObject } from 'src/app/shared/models/common/twilio-device-object';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  providers: [DialogService]
})
export class FooterComponent extends BaseComponent implements OnInit, OnDestroy  {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  twilioDeviceConnection: TwilioDeviceObject;
  device: any;
  connection: any;
  connectionStatus: string;
  isCallStarted: boolean = false;
  isCall: boolean = false;
  appoinmentDialogueParametersObject: AppoinmentDialogueParametersObject = new AppoinmentDialogueParametersObject();  
  constructor(messageService: MessageService,
    private dialougeService: DialogService,
    private globalSearchService: GlobalSearchService,
    private _cdr: ChangeDetectorRef) { super(messageService); }

  ngOnInit() {

    //this.globalSearchService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

    //  if (twillioObject) {
    //    this.isCall = true;
    //    this.twilioDeviceConnection = twillioObject;
    //    this.device = twillioObject.device;
    //    this.connection = twillioObject.connection;
    //    this.connectionStatus = twillioObject.status;
    //    this.isCallStarted = twillioObject.isCallinProgress;
    //    this.appoinmentDialogueParametersObject = twillioObject.appoinmentDialogueParametersObject;
    //  }
    //  else {
    //    this.isCall = false;
    //    this.twilioDeviceConnection = null;
    //  }

    //});

  }

  ngOnDestroy(): void {

    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    //if (this.globalSearchService.searchTerm) {
    //  this.globalSearchService.searchTerm.next(null);
    //}

    if (this.globalSearchService.changeGlobalBranch) {
      this.globalSearchService.changeGlobalBranch.next(null);
    }

    if (this.progress) {
      this.progress.complete();
    }
  }

  showCallInfo() {
    //
    //this.globalSearchService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

    //  if (twillioObject) {
    //    this.isCall = true;
    //    this.twilioDeviceConnection = twillioObject;
    //    this.device = twillioObject.device;
    //    this.connection = twillioObject.connection;
    //    this.connectionStatus = twillioObject.status;
    //    this.isCallStarted = twillioObject.isCallinProgress;
    //    this.appoinmentDialogueParametersObject = twillioObject.appoinmentDialogueParametersObject;
    //  }
    //  else {
    //    this.isCall = false;
    //    this.twilioDeviceConnection = null;
    //  }

    //});
  }

  hideCallInfo() {
    //this.isCall = false;
    //this.twilioDeviceConnection = null;
    //this.globalSearchService.twillioCall.subscribe((twillioObject: TwilioDeviceObject) => {

    //  if (!twillioObject) {
    //    this.isCall = false;
    //    this.twilioDeviceConnection = null;

    //  }
    //});
    //this._cdr.detectChanges();
  }

  showDialogHeader() {
    //if (this.twilioDeviceConnection) {

    //  const ref = this.dialougeService.open(BookAppointmentInfoComponent, {
    //    showHeader: true,
    //    closable: true,
    //    data: {
    //      crmSalesOrderId: this.appoinmentDialogueParametersObject.crmSalesOrderId,
    //      isRescheduledRequest: this.appoinmentDialogueParametersObject.isRescheduledRequest,
    //      isScheduleOrder: this.appoinmentDialogueParametersObject.isScheduleOrder,
    //      isAppointment: this.appoinmentDialogueParametersObject.isAppointment,
    //      schAppointmentId: this.appoinmentDialogueParametersObject.schAppointmentId,
    //      isVoidOrder: this.appoinmentDialogueParametersObject.isVoidOrder,
    //      isCancelOrder: this.appoinmentDialogueParametersObject.isCancelOrder,
    //      isCall: this.appoinmentDialogueParametersObject.isCall,
    //      isAppoinmentBookByCalendar: this.appoinmentDialogueParametersObject.isAppoinmentBookByCalendar,
    //      crmTherapsitId: this.appoinmentDialogueParametersObject.crmTherapsitId,
    //      crmTherapsitName: this.appoinmentDialogueParametersObject.crmTherapsitName,
    //      salesOrderdate: this.appoinmentDialogueParametersObject.salesOrderdate
    //    },
    //  });
    //  ref.onClose.subscribe(() => {

    //    this.HangUpCall();

    //  });
    //}
    //this.SchedulePopup = true;
    // this.cal = true;
  }

  async HangUpCall() {
    //if (this.device) {
    //  this.device.disconnectAll();
    //  this.isCallStarted = false;
    //}
  }

}
