import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { BaseComponent } from '../components/base.component';
import { MessageService } from 'primeng/api';
import { ProvisionalSearchRequestModel } from '../models/request/search-request-model';
import * as moment from 'moment';
import { ProvisionalPatientInfoResponseModel } from '../models/response/appointment/provisional-patient-Info-reponse-model';
import { ProvisionalsalesorderService } from '../services/provisionalsalesorder.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-search-patient-brightree',
  templateUrl: './search-patient-brightree.component.html',
  styleUrls: ['./search-patient-brightree.component.sass']
})
export class SearchPatientBrightreeComponent extends BaseComponent implements OnInit {
  @ViewChild('searchBox') searchBox: ElementRef;
  selectedValue: string = "PatientByID";
  firstName: string;
  lastName: string;
  patientID: number;
  provisionalsearchRequestModel: ProvisionalSearchRequestModel;
  DateofBirth: Date;
  minimumDate = new Date();
  formSearchPatient: boolean = false;
  isPatientByName: boolean = false;
  private unsubscriber = new Subject<boolean>();
  posInfoResponseModel: ProvisionalPatientInfoResponseModel;
  constructor(
    public ref: DynamicDialogRef,
    messageService: MessageService,
    private posService: ProvisionalsalesorderService,
    private router: Router,
  ) { super(messageService); }

  ngOnInit() {
    //this.buildForm();
    this.provisionalsearchRequestModel = new ProvisionalSearchRequestModel()
  }

  async SearchButton() {
    if (this.patientID) {
      this.provisionalsearchRequestModel.patientID = this.patientID;
      await this.getPatientDetailById(this.patientID);
      this.ref.close(true);
    }
    else if (this.firstName || this.lastName || this.DateofBirth) {
      this.provisionalsearchRequestModel.firstName = this.firstName;
      this.provisionalsearchRequestModel.lastName = this.lastName;
      this.provisionalsearchRequestModel.dob = this.DateofBirth != null ? moment(this.DateofBirth).format("MM/DD/YYYY") : '';
      this.redirectTo();
      this.ref.close(true);
    }
    else {
      this.showErrorMessage("Patient name / Patient ID is required")
      return;
    }

  }

  closePopup() {
    this.ref.close();
  }

  SetEvents() {
    switch (this.selectedValue) {
      case "PatientByID":
        this.isPatientByName = false;
        break;
      case "PatientByName":
        this.isPatientByName = true;
        break;
      default:
        this.isPatientByName = false;
        break;

    }
  }

  redirectTo() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admin/appointments/patients-detail'], { state: { provisionalsearchRequestModel: this.provisionalsearchRequestModel, provisionalResponseModel: this.posInfoResponseModel } })
    );
  }

  async getPatientDetailById(patientId: number) {
    this.loading = true;
    await this.posService.getPatientbyId(patientId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<ProvisionalPatientInfoResponseModel>(result, () => {
          if (result.responseModel != undefined && result.responseModel != null && result.responseModel.brightreeID != 0) {
            this.posInfoResponseModel = result.responseModel;
            this.loading = false
            this.redirectTo();
          }
          else {
            this.loading = false
            this.showErrorMessage('Patient detail not found.');
          }
        })
      });
  }

}
