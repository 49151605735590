import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Subject } from 'rxjs';
import { BaseComponent } from '../components/base.component';
import { MessageService } from 'primeng/api';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { CustomValidator } from '../custom-validator';
import { Constants } from '../constants';
import { UserResponseModel } from '../models/response/user-response-model';
import { takeUntil, finalize } from 'rxjs/operators';
import { WebStorageService } from '../services/web-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  title = 'Change Password';
  userId: number;
  email: string;
  isTherapist: boolean;
  isGoogleMap: boolean = false;
  formFields = {
    oldPassword: 'oldPassword',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  };

  validationMessages = {
    oldPassword: {
      required: 'Current Password is required',
      minlength: 'Current Password should be at least 6 characters',
      invalid: 'Enter valid password'
    },
    newPassword: {
      required: 'New Password is required',
      minlength: 'New Password should be at least 6 characters',
      invalid: 'Enter valid password'
    },
    confirmPassword: {
      required: 'Confirm New Password is required',
      invalid: 'Enter valid confirm new password',
      minlength: 'Password should be at least 6 characters',
      validPasswordMatch: 'Password doesn\'t match'
    }
  };

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private titleService: Title,
    private webStorageService: WebStorageService,
    messageService: MessageService) {
    super(messageService);
    this.buildForm();
    const webStore = this.webStorageService.getClientConfig();
    this.isGoogleMap = webStore.isGoogleMap;
  }

  ngOnInit() {
    const userToken = this.webStorageService.getCurrentUserToken();
    this.email = userToken.userEmail;
    this.userId = userToken.userId;
    this.isTherapist = userToken.roleName === Constants.userType.Therapist ? true : false;
    if (this.isTherapist) {
      this.titleService.setTitle("Appoint360");
    }
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const userResponseModel = new UserResponseModel();
    userResponseModel.appUserEmail = this.email;
    userResponseModel.appUserInfoId = this.userId;
    userResponseModel.appUserpassword = this.form.get(this.formFields.confirmPassword).value;
    userResponseModel.appUserOldPassword = this.form.get(this.formFields.oldPassword).value;

    // this.userService.validateOldPassword(userResponseModel).
    //   pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //   .subscribe((result) => {

    //if (result.isSuccess) {
    this.userService.changePassword(userResponseModel).
      pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.loading = false;
          this.showSuccessMessage('Your password has been changed sucessfully');
          this.buildForm();
          setTimeout(() => {
            this.onCancel();
          }, 5000);
        } else {
          this.showErrorMessage(result.message);
        }
      });
    // } else {
    //   this.showErrorMessage(result.message);
    // }
    //});
  }
  onCancel() {
    this.buildForm();
    // add pagedRequestModel as state in navigation while redirecting to maintain state
    if (this.isTherapist) {
      if (this.isGoogleMap) {
        this.router.navigate(['/therapist/my-appointments-new']);
      }
      else {
        this.router.navigate(['/therapist/my-appointments']);
      }
    }
    else {
      this.router.navigate(['/admin/dashboard']);
    }
  }
  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.oldPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));

    formGroup.addControl(this.formFields.newPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.confirmPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.setValidators(CustomValidator.passwordMatch('newPassword', 'confirmPassword'));
    this.form = formGroup;
  }

}
