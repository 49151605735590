import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private subject = new Subject<any>();
  private subject1 = new Subject<any>();
  public  cfavivonSubject = this.subject.asObservable();
  public  clogoSubject = this.subject1.asObservable();
  constructor() { }
  
  recivecompanyfaviconImage (): Observable<any> {
    return this.subject.asObservable();
  }

  sendcompanyfaviconImage(favicon: any) {
    this.subject.next(favicon)
  }

  sendcompanylogoImage(companyLogo: any) {
    this.subject1.next(companyLogo)
  }

}
