export class SearchLocation {
  latitude: number;
  longitude: number;
  iconUrl: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
}


