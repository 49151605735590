import { Injectable } from '@angular/core';
import { FacilitySearchRequestModel } from 'src/app/shared/models/request/facility-search-request';
import { Constants } from '../constants';
import { UserAuthResponseModel, UserAuthTokenResponse } from '../models/response/access-token-response-model';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { FacilitySearchResponseModel } from '../models/response/appointment/facility-search-response';
import { AppointmentConfirmation } from '../models/response/appointment/appointment-confirmation';
import { FeatureGroupResponseModel, featureResponseModel, userModuleFeatureResponseModel } from 'src/app/shared/models/response/feature-group-response';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model'
import { SalesOrderLocalModel, BranchInfoContactModel } from '../models/response/sales-order-current';
import { TherapistResponseModel } from '../models/response/therapist/therapist-response-model';
import { SalesOrderResponseModel } from '../models/response/sales-order-response';
import { TimeZoneAvailableResponseModel } from '../models/response/time-zone-available-response-model';

import { JsonHubProtocol } from '@aspnet/signalr';
import { ProvisionalSearchRequestModel } from '../models/request/search-request-model';

import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { TwilioDeviceObject, AppoinmentDialogueParametersObject } from 'src/app/shared/models/common/twilio-device-object';
import { OnlineAppointmentTypeModel } from '../models/response/appointment/remote-appointment-type-model';
import { CustomSettingsResponseModel } from '../models/response/settings-response';
import { BrightreeSettingsResponseModel } from '../models/response/brightree-settings-response-model';
import { EncryptionStorageService } from './encryption-storage.service';

@Injectable({
  providedIn: 'root'
})

export class WebStorageService {

  constructor(private storageService: EncryptionStorageService) { }



  getClientConfig(): ConfigResponseModel {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.currentClientConfig));
    //return JSON.parse(this.getItem(Constants.currentClientConfig));
  }

  getCurrentUserToken(): UserAuthResponseModel {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.currentUserToken));
    //return JSON.parse(this.getItem(Constants.currentUserToken));
  }

  storeCurrentUserToken(accessToken: UserAuthResponseModel) {
    this.storageService.secureStorage.setItem(Constants.currentUserToken, JSON.stringify(accessToken));
    //this.setItem(Constants.currentUserToken, JSON.stringify(accessToken));
  }

  getCurrentUserAuthToken(): UserAuthTokenResponse {
    return JSON.parse(this.getItem(Constants.currentUserAuthToken));
  }

  storeCurrentUserAuthToken(accessToken: UserAuthTokenResponse) {
    this.setItem(Constants.currentUserAuthToken, JSON.stringify(accessToken));
  }

  storeClientConfig(clientConfig: ConfigResponseModel) {
    this.storageService.secureStorage.setItem(Constants.currentClientConfig, JSON.stringify(clientConfig));
    //this.setItem(Constants.currentClientConfig, JSON.stringify(clientConfig));
  }
  deleteClientConfig() {
    this.removeItem(Constants.currentClientConfig);
  }

  getTwilioDeviceObject(): TwilioDeviceObject {
    return JSON.parse(this.getItem(Constants.twilioDeviceObject));
  }
  storeTwilioDeviceObject(accessToken: TwilioDeviceObject) {
    this.setItem(Constants.twilioDeviceObject, JSON.stringify(accessToken, this.getCircularReplacer()));
  }
  deleteTwilioDeviceObject() {
    this.removeItem(Constants.twilioDeviceObject);
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };


  deleteCurrentUserToken() {
    this.removeItem(Constants.currentUserToken);
  }
  deleteCurrentUserAuthToken() {
    this.removeItem(Constants.currentUserAuthToken);
  }

  clear() {
    localStorage.clear();
  }

  private setItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  private removeItem(key: string) {
    this.storageService.secureStorage.removeItem(key)
    localStorage.removeItem(key);
  }

  getSearchedRequestModel(): FacilitySearchRequestModel {
    return JSON.parse(this.getItem(Constants.currentSearchRequestModel));
  }

  storeSearchedRequestModel(facilityArray: FacilitySearchRequestModel) {
    this.setItem(Constants.currentSearchRequestModel, JSON.stringify(facilityArray));
  }
  deleteSearchedRequestModel() {
    this.removeItem(Constants.currentSearchRequestModel);
  }

  private getItem(key: string) {
    return localStorage.getItem(key);
  }

  getSelectedFacilies(): FacilitySearchResponseModel[] {
    return JSON.parse(this.getItem(Constants.currentSelectedFacilities));
  }

  storeSelectedFacilies(facilityArray: FacilitySearchResponseModel[]) {
    this.setItem(Constants.currentSelectedFacilities, JSON.stringify(facilityArray));
  }

  deleteSelectedFacilies() {
    this.removeItem(Constants.currentSelectedFacilities);
  }

  getAppointmentConfirmation(): AppointmentConfirmation {
    return JSON.parse(this.getItem(Constants.currentAppointmentConfirmation));
  }

  storeAppointmentConfirmation(salesOrderId: AppointmentConfirmation) {
    this.setItem(Constants.currentAppointmentConfirmation, JSON.stringify(salesOrderId));
  }

  deleteAppointmentConfirmation() {
    this.removeItem(Constants.currentAppointmentConfirmation);
  }

  getPermissions(): featureResponseModel[] {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.currentRolePermission));
    //return JSON.parse(this.getItem(Constants.currentRolePermission));
  }

  storePermissions(permissions: featureResponseModel[]) {
    this.storageService.secureStorage.setItem(Constants.currentRolePermission, JSON.stringify(permissions));
    //this.setItem(Constants.currentRolePermission, JSON.stringify(permissions));
  }
  storeUserBrances(userBranches: BranchResponseModel[]) {
    //this.setItem(Constants.userBranches, JSON.stringify(userBranches));
    this.storageService.secureStorage.setItem(Constants.userBranches, JSON.stringify(userBranches));
  }
  getUserBrances(): BranchResponseModel[] {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.userBranches));
    //return JSON.parse(this.getItem(Constants.userBranches));
  }
  deleteUserBrances() {
    this.removeItem(Constants.userBranches);
  }

  deletePermissions() {
    this.removeItem(Constants.currentRolePermission);
  }

  getUserPermissions(): userModuleFeatureResponseModel[] {
    //return JSON.parse(this.getItem(Constants.currentUserPermission));
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.currentUserPermission));
  }

  storeUserPermissions(permissions: userModuleFeatureResponseModel[]) {
    this.storageService.secureStorage.setItem(Constants.currentUserPermission, JSON.stringify(permissions));
    //this.setItem(Constants.currentUserPermission, JSON.stringify(permissions));
  }

  deleteUserPermissions() {
    this.removeItem(Constants.currentUserPermission);
  }

  getFeatureGroups(): FeatureGroupResponseModel[] {
    return JSON.parse(this.getItem(Constants.currentFeatureGroups));
  }

  storeFeatureGroups(features: FeatureGroupResponseModel[]) {
    this.setItem(Constants.currentFeatureGroups, JSON.stringify(features));
  }
  deleteFeatureGroups() {
    this.removeItem(Constants.currentFeatureGroups);
  }

  getAutoCallIndex(): number {
    return JSON.parse(this.getItem(Constants.currentCallIndex));
  }

  storeAutoCallIndex(call: number) {
    this.setItem(Constants.currentCallIndex, JSON.stringify(call));
  }
  deleteAutoCallIndex() {
    this.removeItem(Constants.currentCallIndex);
  }

  getUserBranch(): number {
    return JSON.parse(this.getItem(Constants.userselectedbranch));
  }

  storeUserBranch(branchId: number) {
    this.setItem(Constants.userselectedbranch, JSON.stringify(branchId));
  }
  deleteUserBranch() {
    this.removeItem(Constants.userselectedbranch);
  }

  getAppHashVersionValue(): string {
    return JSON.parse(this.getItem(Constants.appHashVersion));
  }
  storeAppHashVersionValue(appHashVersion: string) {
    this.setItem(Constants.appHashVersion, JSON.stringify(appHashVersion));
  }
  deleteAppHashVersionValue() {
    this.removeItem(Constants.appHashVersion);
  }

  getDateFormat(): DateFormatModel {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.appDateFormat));
    //return JSON.parse(this.getItem(Constants.appDateFormat));
  }
  storeDateFormat(dateFormat: DateFormatModel) {
    //this.setItem(Constants.appDateFormat, JSON.stringify(dateFormat));
    this.storageService.secureStorage.setItem(Constants.appDateFormat, JSON.stringify(dateFormat));
  }
  deleteDateFormat() {
    this.removeItem(Constants.appDateFormat);
  }


  getRedirectFrom(): string {
    return JSON.parse(this.getItem(Constants.redirectTo));
  }
  storeRedirectFrom(dateFormat: string) {
    this.setItem(Constants.redirectTo, JSON.stringify(dateFormat));
  }
  deleteRedirectFrom() {
    this.removeItem(Constants.redirectTo);
  }

  getSalesOrderId(): SalesOrderLocalModel {
    return JSON.parse(this.getItem(Constants.currentSalesOrderId));
  }

  getSalesOrderData(): SalesOrderResponseModel {
    return JSON.parse(this.getItem(Constants.currentSalesOrderData));
  }

  storeSalesOrderId(salesOrderId: SalesOrderLocalModel) {
    this.setItem(Constants.currentSalesOrderId, JSON.stringify(salesOrderId));
  }

  storeBranchContactInfo(branchInfo: BranchInfoContactModel) {
    this.setItem(Constants.currentBranchId, JSON.stringify(branchInfo));
  }

  getBranchContactInfoById(): BranchInfoContactModel {
    return JSON.parse(this.getItem(Constants.currentBranchId));
  }

  deleteBranchContactInfoById() {
    this.removeItem(Constants.currentBranchId);
  }


  storeSalesOrderData(salesOrderData: SalesOrderResponseModel) {
    this.setItem(Constants.currentSalesOrderData, JSON.stringify(salesOrderData));
  }

  saveDMENumber(dmeNumber: string) {
    this.setItem(Constants.currentDMENumber, JSON.stringify(dmeNumber));
  }

  getDMENumber(): string {
    return JSON.parse(this.getItem(Constants.currentDMENumber));
  }

  deleteSalesOrderId() {
    this.removeItem(Constants.currentSalesOrderId);
  }

  getRandomNumber(): number {
    return JSON.parse(this.getItem(Constants.randomNumber));
  }
  storeRandomNumber(randomNumber: number) {
    this.setItem(Constants.randomNumber, JSON.stringify(randomNumber));
  }
  deleteRandomNumber() {
    this.removeItem(Constants.randomNumber);
  }

  getGlobalSearch(): string {
    return JSON.parse(this.getItem(Constants.globalSearchText));
  }
  storeGlobalSearch(searchtext: string) {
    this.setItem(Constants.globalSearchText, JSON.stringify(searchtext));
  }
  deleteGlobalSearch() {
    this.removeItem(Constants.globalSearchText);
  }

  getIsSession(): boolean {
    return JSON.parse(this.getItem(Constants.isSession));
  }
  storeIsSession(isSession: boolean) {
    this.setItem(Constants.isSession, JSON.stringify(isSession));
  }
  deleteIsSession() {
    this.removeItem(Constants.isSession);
  }

  getIsSessionValue(): number {
    return JSON.parse(this.getItem(Constants.isSessionValue));
  }
  storeIsSessionValue(isSession: number) {
    this.setItem(Constants.isSessionValue, JSON.stringify(isSession));
  }
  deleteIsSessionValue() {
    this.removeItem(Constants.isSessionValue);
  }

  storePatientBookingTypeId(currentPatientBookingTypeId: string) {
    this.setItem(Constants.currentPatientBookingTypeId, JSON.stringify(currentPatientBookingTypeId));
  }
  getPatientBookingTypeId(): string {
    return JSON.parse(this.getItem(Constants.currentPatientBookingTypeId));
  }

  deletePatientBookingTypeId() {
    this.removeItem(Constants.currentPatientBookingTypeId);
  }

  storePatientBookingUrl(currenRoutertUrl: string) {
    this.setItem(Constants.currenRoutertUrl, JSON.stringify(currenRoutertUrl));
  }

  getPatientBookingUrl(): string {
    return JSON.parse(this.getItem(Constants.currenRoutertUrl));
  }

  storeSelectedTherapist(therapistSearchResponseModel: TherapistResponseModel) {
    this.setItem(Constants.currentSelectedTherapist, JSON.stringify(therapistSearchResponseModel));
  }

  getSelectedTherapist(): TherapistResponseModel {
    return JSON.parse(this.getItem(Constants.currentSelectedTherapist));
  }

  deleteSelectedTherapist() {
    this.removeItem(Constants.currentSelectedTherapist);
  }

  getWipStatusBtmap(): any {
    return JSON.parse(this.getItem(Constants.wipStatusBtMap));
  }
  storeWipStatusBtmap(wipStatusBtMap: any) {
    this.setItem(Constants.wipStatusBtMap, JSON.stringify(wipStatusBtMap));
  }
  deleteWipStatusBtmap() {
    this.removeItem(Constants.wipStatusBtMap);
  }

  storeTimeZoneList(timeZoneResult: TimeZoneAvailableResponseModel[]) {
    this.storageService.secureStorage.setItem(Constants.currentTimeZoneList, JSON.stringify(timeZoneResult));
    //this.setItem(Constants.currentTimeZoneList, JSON.stringify(timeZoneResult));
  }

  getTimeZoneList(): TimeZoneAvailableResponseModel[] {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.currentTimeZoneList));
    //return JSON.parse(this.getItem(Constants.currentTimeZoneList));
  }



  storeBrightreeSettings(timeZoneResult: BrightreeSettingsResponseModel) {
    this.storageService.secureStorage.setItem(Constants.brightreeSettings, JSON.stringify(timeZoneResult));
    //this.setItem(Constants.brightreeSettings, JSON.stringify(timeZoneResult));
  }

  getBrightreeSettings(): BrightreeSettingsResponseModel {
    return JSON.parse(this.storageService.secureStorage.getItem(Constants.brightreeSettings));
    //return JSON.parse(this.getItem(Constants.brightreeSettings));
  }
  deleteBrightreeSettings() {
    this.removeItem(Constants.brightreeSettings);
  }


  getPatientName(): string {
    return JSON.parse(this.getItem(Constants.patientName));
  }
  storePatientName(patientName: string) {
    this.setItem(Constants.patientName, JSON.stringify(patientName));
  }

  setProvisionalSearchItem(data: ProvisionalSearchRequestModel) {
    this.setItem(Constants.provisionalSearch, JSON.stringify(data));
  }
  getProvisionalSearchItem(): ProvisionalSearchRequestModel {
    return JSON.parse(this.getItem(Constants.provisionalSearch));
  }
  deleteProvisionalSearchItem() {
    this.removeItem(Constants.provisionalSearch);
  }


  storeDefaultTimeZone(defaultTimeZone: string) {

    this.setItem(Constants.defaultTimeZone, JSON.stringify(defaultTimeZone));
  }

  getDefaultTimeZone(): string {
    return JSON.parse(this.getItem(Constants.defaultTimeZone));
  }

  setdefaultModeofAppointment(appointmentMode: string) {
    this.setItem(Constants.defaultModeofAppointment, JSON.stringify(appointmentMode));
  }

  getdefaultModeofAppointment() {
    return JSON.parse(this.getItem(Constants.defaultModeofAppointment));
  }

  removedefaultModeofAppointment() {
    this.removeItem(Constants.defaultModeofAppointment);
  }

  storeOnlineAppointmentTypeNames(onlineAppointmentTypeNames: Array<OnlineAppointmentTypeModel>) {
    this.setItem(Constants.onlineAppointmentTypeNames, JSON.stringify(onlineAppointmentTypeNames));
  }

  getOnlineAppointmentTypeNames(): Array<OnlineAppointmentTypeModel> {
    return JSON.parse(this.getItem(Constants.onlineAppointmentTypeNames));
  }

  deleteOnlineAppointmentTypeNames() {
    this.removeItem(Constants.onlineAppointmentTypeNames);
  }

  storePatientSalesOrderSettings(data: CustomSettingsResponseModel) {
    this.setItem(Constants.patientSalesOrderSettings, JSON.stringify(data));
  }

  getPatientSalesOrderSettings(): CustomSettingsResponseModel {
    return JSON.parse(this.getItem(Constants.patientSalesOrderSettings));
  }

  deletePatientSalesOrderSettings() {
    this.removeItem(Constants.patientSalesOrderSettings);
  }

  getCurrentMenuStateValue(): boolean {
    return JSON.parse(this.getItem(Constants.currentMenuState));
  }

  storeCurrentMenuStateValue(menuState: boolean) {
    this.setItem(Constants.currentMenuState, JSON.stringify(menuState));
  }
}
