<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>{{title}}</h1>
  </div>

  <div class="form-content-outer notification-block">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">
      <ul class="notification">
        <li *ngFor="let notiy of notificationInAppResponseModel">
          <h3>{{notiy.appNotificationInAppDesc}}</h3>
          <p>{{getNotificationDate(notiy.dateTimeCreated)}}</p>
        </li>
      </ul>
    </p-scrollPanel>
  </div>
</div>
<!-- End Content -->
