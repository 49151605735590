import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FeatureGroupResponseModel, featureResponseModel } from '../models/response/feature-group-response';
import { Result, ResultOfT } from '../models/response/result';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { BaseService } from './base.service';
import { WebStorageService } from '../services/web-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureGroupService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getFeatureGroup() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<ResultOfT<FeatureGroupResponseModel[]>>('Roles/GetFeatureGroup/' + currentUserToken.roleId);
    }
  }
  getPermission(featureGroupName: string) {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<ResultOfT<featureResponseModel[]>>('Roles/GetPermission/' + currentUserToken.roleId + '/' + featureGroupName);
    }
  }
  getSettings(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey);
  }

  logout() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<Result>('Users/Logout/' + currentUserToken.userId);
    }
  }
}
