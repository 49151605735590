
export class SettingsNotificationResponseModel {
  appNotificationId: number;
  appModuleId: number;
  appNotificationEventName: string;
  appNotificationInApp: Boolean;
  appNotificationInSms: Boolean;
  appNotificationInEmail: Boolean;
  appNotificationInVoicemail: Boolean;
  appModuleDisplayFeatureName: string;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  appNotificationGroupEventName: string;
  appNotificationTemplateName: string;
  appNotificationGroupEventOrder: number;
  appNotificationTemplateOrder: number;
  notificationTemplate: Array<NotificationTemplateModel>;
}

export class NotificationTemplateModel {
  appNotificationTemplateId: number;
  appNotificationId: number;
  appRoleId: number;
  appEnumnotificationType: string;
  appNotificationTemplateFormat: string;
  appNotificationTemplateSubject: string;
  isActive: Boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
}

export class SettingsGroupNotificationListResponseModel {
  appModuleId: number;
  appNotificationEventName: string;
  appModuleDisplayFeatureName: string;
  notificationTemplateList: SettingsNotificationResponseModel[];
  isGroupCollapse: Boolean;
  isEmailSelectAllCheck: boolean;
  isSMSSelectAllCheck: boolean;
}
