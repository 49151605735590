<div class="login">
  <div class="login__header">
    <div class="login__header__left">

      <div class="login__header__logo">
        <img src="assets/img/appoint360-logo.png" height="80" width="105" title="Appoint360" alt="Appoint360">
      </div>

      <div class="login__header__client-logo">
        <div class="login__header__client-logo__client">
          <img *ngIf="imageUrl" [src]="imageUrl" alt="{{imagealt}}" height="65" title="{{imageTitle}}">
        </div>
      </div>
    </div>
  </div>

  <div class="login__content">
    <div class="login__content__left">
      <div class="login-img">
        <img src="assets/img/login-img.png" class="login__content__left__img" alt="login img" height="400">
      </div>
    </div>
    <div class="login__content__right">
      <form class="form support-form" [formGroup]="form" autocomplete="off">
        <div class="form-content-outer__content">
          <div class="form-content-outer__content__title">
            <h2>{{title}}</h2>
            <p>Enter below details to ask your queries</p>
          </div>
          <div class="scroll">
            <div class="scroll__content">
              <div class="form__group">
                <input type="text" class="form__control error" pInputText formControlName="RequestorName" maxlength="50"
                  placeholder="Name *" />
                <span class="error-msg"
                  *ngIf="hasError(formFields.RequestorName, 'required') || hasError(formFields.RequestorName, 'whitespace')">{{validationMessages.RequestorName.required}}</span>
              </div>
              <div class="form__group">
                <input type="text" class="form__control" pInputText formControlName="RequestorEmail" maxlength="50"
                  placeholder="Email *" />
                <span class="error-msg"
                  *ngIf="hasError(formFields.RequestorEmail,'required')">{{commonValidationMessages.email.required}}</span>
                <span class="error-msg"
                  *ngIf="hasError(formFields.RequestorEmail,'email')">{{commonValidationMessages.email.email}}</span>
              </div>

              <div class="form__group phone-number">
                <p-inputMask mask="999-999-9999" formControlName="RequestorPhone" class="input-mask" maxlength="15"
                  placeholder="Phone (optional)"></p-inputMask>
              </div>

              <div class="form__group select-box">
                <p-dropdown optionLabel="name" dataKey="name" formControlName="ProblemCategory"
                  [options]="supportCategories" [(ngModel)]="selectedSupportCategory" placeholder="Select Category *">
                </p-dropdown>
                <span class="error-msg"
                  *ngIf="hasError(formFields.ProblemCategory, 'required')">{{validationMessages.ProblemCategory.required}}</span>
              </div>

              <div class="form__group">
                <input type="text" class="form__control" pInputText formControlName="ProblemArea" maxlength="50"
                  placeholder="Where did you run into the problem? *" />
                <small>Please tell us the section in the program or a link to the page</small>
                <span class="error-msg"
                  *ngIf="hasError(formFields.ProblemArea, 'required')">{{validationMessages.ProblemArea.required}}</span>
              </div>

              <div class="form__group text-area-field">
                <textarea class="form__textarea" formControlName="Problem" pInputTextarea maxlength="250" #box
                  (keyup)="0" placeholder="Please describe in detail what happened. *"></textarea>
                <span class="error-msg"
                  *ngIf="hasError(formFields.Problem, 'required')">{{validationMessages.Problem.required}}</span>
              </div>

              <div class="form__group">

                <div class="browse-import-btn">
                  <input class="browse-btn" #LogoFile type="file" placeholder="Choose file"
                    (change)="uploadLogoFile($event)" style="display:none;">
                  <button type="button" class="btn" (click)="LogoFile.click()">Browse</button>
                  <small class="screen-shot">(Screenshot of Issue)</small>
                </div>
                <ng-container *ngIf="companyLogo">
                  <div class="upload-img-box">

                    <div class="upload-img-box__img">
                      {{companyLogoFileName}} &nbsp;&nbsp;
                      <a href="javascript:void(0);" class="remove-img" (click)="removeImage()"><i
                          class="ic delete small"></i> </a>
                    </div>

                    <ng-template #elseTemplate></ng-template>
                  </div>
                </ng-container>
              </div>

              <div class="form__group login-btn">
                <button class="btn btn--primary" (click)="onSubmit()">Submit</button>
                <div class="form__group forgot-password">
                  <a [routerLink]="['/login']" routerLinkActive="active">Back to Login</a>
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
  <div class="login__footer">
    <div class="login__footer__left mobile-support">
      <h4>Appoint360 is best viewed on resolution width 1024 or greater</h4>
      <p><strong>For Windows OS:</strong> Google Chrome 88+, Mozilla Firefox 84+, Microsoft Edge 101+ browsers.</p>
      <p><strong>For Mac OS:</strong> Safari 13.1+, Google Chrome 88+, Mozilla Firefox 84+ browsers.</p>
    </div>
    <!-- <p class="login__footer__left mobile-support">*Application best view in 1280px + resolutions in <strong>Google Chrome, Mozilla Firefox & Microsoft Edge</strong> browsers.</p> -->

    <div class="login__footer__right">
      <span>Powered By<img src="assets/img/appoint360-logo.svg" alt="Appoint360" title="Appoint360" width="20"
          height="13">Appoint360</span>
    </div>
  </div>
</div>