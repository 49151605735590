import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BaseComponent } from '../components/base.component';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from '../custom-validator';
import { Constants } from '../constants';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil, finalize } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserResponseModel } from '../models/response/user-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { ConfigResponseModel } from '../models/response/client-config-response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.sass']
})
export class SetPasswordComponent extends BaseComponent implements OnInit, OnDestroy {
  configResponseModel: ConfigResponseModel;
  imageUrl: SafeResourceUrl;
  private domainUrl: string = '';
  imageData: string;
  imagealt: string;
  imageTitle: string;
  private unsubscriber = new Subject<boolean>();
  title = 'Set Password';
  email: string;
  registrationstatus: string;
  // userResponseModel: UserResponseModel;
  formFields = {
    email: 'email',
    newPassword: 'newPassword',
    confirmPassword: 'confirmPassword'
  };

  validationMessages = {
    newPassword: {
      required: 'Password is required',
      minlength: 'Password should be at least 6 characters',
      invalid: 'Enter valid password'
    },
    confirmPassword: {
      required: 'Password is required',
      invalid: 'Enter valid password',
      minlength: 'Password should be at least 6 characters',
      validPasswordMatch: 'Password Doesn\'t match'
    }
  };

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private webStorageService: WebStorageService,
    private sanitizer: DomSanitizer,
    messageService: MessageService) {
    super(messageService);
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.email = params['emailId'];
        this.registrationstatus = params['registrationstatus'];
      });

    this.configResponseModel = this.webStorageService.getClientConfig();
    if (this.configResponseModel) {
      this.imageData = 'data:image/png;base64, ' + this.configResponseModel.companyLogo;
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
      this.imagealt = this.configResponseModel.companyName;
      this.imageTitle = this.configResponseModel.companyName;
    }
    else {
      this.domainUrl = location.origin.replace("http://", "").replace("https://", "");
      this.authenticationService.getClinetDetails(this.domainUrl).pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<ConfigResponseModel>) => {
          this.processResult<ConfigResponseModel>(result, () => {
            if (result.isSuccess) {
              if (result.responseModel) {
                this.imageData = 'data:image/png;base64, ' + result.responseModel.companyLogo;
                this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageData);
                this.imagealt = result.responseModel.companyName;
                this.imageTitle = result.responseModel.companyName;
                this.webStorageService.storeClientConfig(result.responseModel);
              }
            }
          });
        });

    }
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

  async onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    const userResponseModel = new UserResponseModel();

    // userResponseModel.AppUserEmail = this.form.get(this.formFields.email).value;
    userResponseModel.appUserEmail = this.email;
    userResponseModel.appUserpassword = this.form.get(this.formFields.newPassword).value;
    if (this.registrationstatus)
      userResponseModel.appUserRegistrationStatus = this.registrationstatus;

    await this.userService.setPassword(userResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          this.loading = false;
          this.showSuccessMessage("Password Set Successfully.");
          this.router.navigate(['/login']);
        }
        else {
          this.showInfoMessage(result.message);
        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.email, this.formBuilder.control(this.email));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.newPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.confirmPassword, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.passwordPattern], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.setValidators(CustomValidator.passwordMatch('newPassword', 'confirmPassword'));
    this.form = formGroup;
  }

}
