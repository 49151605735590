import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { RoleFeatureModelPermission } from 'src/app/shared/models/response/role-feature-modeul-permission';
import { HolidayService } from 'src/app/admin/servicies/holiday.service';
import { KPIReportResponseModel } from 'src/app/shared/models/response/role-feature-modeul-permission';
import { KPIReportService } from 'src/app/admin/servicies/kpireport.service';
import { KPIReportRolePermissionRequestModel, KPIReportRolePermissionResponseModel } from 'src/app/shared/models/response/kpi-report-response-model';

@Component({
  selector: 'app-manage-permissions',
  templateUrl: './manage-permissions.component.html',
  styleUrls: ['./manage-permissions.component.sass']
})
export class ManagePermissionsComponent extends BaseComponent implements OnInit, OnDestroy {

  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  roleId: string;
  applicationRoleId: number;
  isEditMode: boolean = false;
  AllChecked: boolean;
  featureGroupChecked: boolean;
  loggedInUserId: number;
  selectedTab: number;
  loading: boolean;
  rowGroupMetadata: any;
  roleFeatureModelPermission: RoleFeatureModelPermission;
  kpiReportMasterDetails: KPIReportResponseModel[];
  kpiReportRolePermissionResponseModel: KPIReportRolePermissionResponseModel[];
  formFields = {
    roleName: 'roleName',
  };

  validationMessages =
    {
      roleName: {
        required: 'Role name is required',
        validCharacters: 'Role name should contain only characters'
      }
    };


  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private webStorageService: WebStorageService,
      private holidayService: HolidayService,
      private kpiReportService: KPIReportService,

      messageService: MessageService,) {
    super(messageService);

    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.selectedTab) {
      this.selectedTab = Number(currentNavigationState.selectedTab);
    }
  }

  async ngOnInit() {
    var currentUserToken = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = currentUserToken.userId;
    this.roleFeatureModelPermission = new RoleFeatureModelPermission();
    this.buildForm();
    this.activatedRoute.params
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((params: Params) => {
        this.roleId = params['id'];
      });

    if (this.roleId) {
      this.applicationRoleId = Number(this.roleId);
    }
    else
      this.applicationRoleId = 0;
    this.getModuleRolePermission(this.applicationRoleId);
    this.getKPIReportMasterDetails()
  }

  private getModuleRolePermission(roleId) {
    this.loading = true;
    this.settingsService.GetRoleFeatureModulePermissionManagement(roleId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RoleFeatureModelPermission>) => {
        this.processResult<RoleFeatureModelPermission>(result, () => {
          this.roleFeatureModelPermission = result.responseModel;
          this.getKPIRolePermission(roleId);
          this.roleFeatureModelPermission.moduleFeatureGroups.forEach(x => {
            var allfeatureGroup = this.roleFeatureModelPermission.moduleFeatureGroups.filter(y => y.appModuleFeatureGroupId == x.appModuleFeatureGroupId);
            var isAllFeatureGroupTrue = this.roleFeatureModelPermission.moduleFeatureGroups.filter(z => z.appIsPermission == true && z.appModuleFeatureGroupId == x.appModuleFeatureGroupId);
            if (allfeatureGroup.length === isAllFeatureGroupTrue.length) {
              x.isAllFeatureGroup = true;
            }
          })

          var evnt = this.roleFeatureModelPermission.moduleFeatureGroups.filter(x => x.appIsPermission === true);
          if (evnt.length > 0 && evnt.length === this.roleFeatureModelPermission.moduleFeatureGroups.length) {
            this.AllChecked = true;
            //this.featureGroupChecked = true;
          }

          this.updateRowGroupMetaData();
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getKPIRolePermission(roleId) {
    this.loading = true;
    this.kpiReportService.GetKPIReportRolePermissionDetail(roleId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<KPIReportRolePermissionResponseModel[]>) => {
        this.processResult<KPIReportRolePermissionResponseModel[]>(result, () => {
          this.kpiReportRolePermissionResponseModel = result.responseModel;
          var evnt = this.kpiReportRolePermissionResponseModel.filter(x => x.isActive === true);
          if (evnt.length > 0) {
            evnt.forEach(x => {
              var ab = this.kpiReportMasterDetails.find(y => y.kpiReportId == x.kpiReportId);
              if (ab != undefined) {
                ab.isKPIPermissionChecked = true;
              }
            })
          }
          if (evnt.length > 0 && evnt.length === this.kpiReportRolePermissionResponseModel.length) {
            //this.AllChecked = true;
            this.featureGroupChecked = true;
          }
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }



  private async getKPIReportMasterDetails() {
    this.progress.next(true);
    await this.kpiReportService.GetKPIReportMasterDetails()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise().then((result) => {
        if (!result) {
          return;
        }
        if (result.responseModel != undefined && result.responseModel != null) {
          this.kpiReportMasterDetails = result.responseModel;

        }
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};
    if (this.roleFeatureModelPermission.moduleFeatureGroups) {
      for (let i = 0; i < this.roleFeatureModelPermission.moduleFeatureGroups.length; i++) {
        let rowData = this.roleFeatureModelPermission.moduleFeatureGroups[i];
        let representativeName = rowData.appModuleFeatureGroup;

        if (i == 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.roleFeatureModelPermission.moduleFeatureGroups[i - 1];
          let previousRowGroup = previousRowData.appModuleFeatureGroup;
          if (representativeName === previousRowGroup)
            this.rowGroupMetadata[representativeName].size++;
          else
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
        }
      }
    }
  }

  AddRemoveFromPermission(isChecked, appModuleFeatureId, appModuleFeatureGroupId) {
    let arrlength = this.roleFeatureModelPermission.moduleFeatureGroups.length;

    this.roleFeatureModelPermission.moduleFeatureGroups.forEach(value => {
      if (value.appModuleFeatureId == appModuleFeatureId) {
        if (isChecked.checked) {
          value.appIsPermission = true;
        } else {
          value.appIsPermission = false;
        }
      }
    });
    var getPerticularGroup = this.roleFeatureModelPermission.moduleFeatureGroups.filter(x => x.appModuleFeatureGroupId == appModuleFeatureGroupId);
    getPerticularGroup.forEach(value => {
      var allfeatureGroup = this.roleFeatureModelPermission.moduleFeatureGroups.filter(y => y.appModuleFeatureGroupId == appModuleFeatureGroupId);
      var isAllFeatureGroupTrue = this.roleFeatureModelPermission.moduleFeatureGroups.filter(z => z.appIsPermission == true && z.appModuleFeatureGroupId == appModuleFeatureGroupId);
      if (allfeatureGroup.length === isAllFeatureGroupTrue.length && isChecked.checked) {
        value.isAllFeatureGroup = true;
      }
      else {
        value.isAllFeatureGroup = false;
      }
    });
    var evnt = this.roleFeatureModelPermission.moduleFeatureGroups.filter(x => x.appIsPermission === true);
    if (evnt.length > 0 && evnt.length === arrlength)
      this.AllChecked = true;
    else
      this.AllChecked = false;
  }

  AddRemoveAllPermission(isChecked) {
    let arrlength = this.roleFeatureModelPermission.moduleFeatureGroups.length;
    this.roleFeatureModelPermission.moduleFeatureGroups.forEach(value => {

      if (isChecked.checked) {
        value.appIsPermission = true;
        value.isAllFeatureGroup = true;
      } else {
        value.appIsPermission = false;
        value.isAllFeatureGroup = false;
      }
    });

    this.kpiReportMasterDetails.forEach(x => {
      if (isChecked.checked) {
        x.isKPIPermissionChecked = true;
        this.featureGroupChecked = true;
      }
      else {
        x.isKPIPermissionChecked = false;
        this.featureGroupChecked = false;
      }
    })

    let selectModuleLength = 0;
    var evnt = this.roleFeatureModelPermission.moduleFeatureGroups.filter(x => x.appIsPermission === true);
    if (evnt.length > 0 && evnt.length === arrlength)
      this.AllChecked = true;
    else
      this.AllChecked = false;
  }

  AddRemoveFeatureGroupPermission(featureGroup: any, isChecked) {
    let arrlength = this.roleFeatureModelPermission.moduleFeatureGroups.length;
    this.roleFeatureModelPermission.moduleFeatureGroups.forEach(value => {
      if (featureGroup == value.appModuleFeatureGroup) {
        if (isChecked.checked) {
          value.appIsPermission = true;
        } else {
          value.appIsPermission = false;
        }
      }

    });
  }

  AddRemoveKPIPermission(isChecked, kpiReportId) {
    this.kpiReportMasterDetails.forEach(value => {
      if (value.kpiReportId == kpiReportId) {
        if (isChecked.checked) {
          value.isKPIPermissionChecked = true;
        } else {
          value.isKPIPermissionChecked = false;
        }
      }

    });
    var evnt = this.kpiReportMasterDetails.filter(x => x.isKPIPermissionChecked === true);
    if (evnt.length > 0 && evnt.length === this.kpiReportRolePermissionResponseModel.length) {
      this.featureGroupChecked = true;
      this.AllChecked = true;
    }
    else {
      this.featureGroupChecked = false;
      this.AllChecked = false;
    }
    // let arrlength = this.roleFeatureModelPermission.moduleFeatureGroups.length;
    // var evnt1 = this.roleFeatureModelPermission.moduleFeatureGroups.filter(x => x.appIsPermission === true);
    // if (evnt1.length > 0 && evnt1.length === arrlength)
    //   this.AllChecked = true;
    // else
    //   this.AllChecked = false;

  }

  AddRemoveKPIGroupPermission(isChecked) {
    this.kpiReportMasterDetails.forEach(value => {
      if (isChecked.checked) {
        value.isKPIPermissionChecked = true;
      } else {
        value.isKPIPermissionChecked = false;
      }
    });
  }

  onSubmit() {

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    this.loading = true;
    this.isEditMode = this.roleFeatureModelPermission.appRoleId > 0 ? true : false;
    this.settingsService.AddUpdateRoleAndPermission(this.roleFeatureModelPermission)
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          //this.AddUpdateKPIRolePermission();
          this.showSuccessMessage(this.isEditMode ? 'Role permission is updated successfully' : 'Role permission is created successfully');
          //this.onCancel();
          this.onBack();
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  async AddUpdateKPIRolePermission() {

    let kpiPermissionRequestModel = [];
    this.kpiReportMasterDetails.forEach(value => {
      let kpidata = new KPIReportRolePermissionRequestModel;
      kpidata.kPIReportId = value.kpiReportId;
      kpidata.appRoleId = parseInt(this.roleId);
      kpidata.isActive = value.isKPIPermissionChecked
      kpidata.modifiedByUserInfoId = this.loggedInUserId;
      kpiPermissionRequestModel.push(kpidata);
    });
    await this.kpiReportService.AddRolePermissionKPIReport(kpiPermissionRequestModel)
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onCancel() {
    this.router.navigate(['admin/settings/application'], { state: { selectedTab: 3 } });
  }

  private buildForm() {

    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.roleName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyCharacters], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }
  //46165_607
  onBack()  {   
    this.holidayService._setTabIndex(3); 
    this.router.navigate(['admin/settings/application'], { state: { selectedTab: 3 } });   
  }

}
