import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.sass'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SharedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
