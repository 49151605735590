<div class="content-area">
  <div class="title-block">
    <h1>
      {{title}}
    </h1>
    <div class="title-block__right">
      <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/importexport']">
        <i class="back-new"></i>
        Back
      </a>
    </div>
  </div>

  <div class="sync-page-outer">
    <div class="sync-page-outer__export">
      <div class="sync-page-outer__export__block">
        <form class="sync-page-outer__block form" autocomplete="off">
          <div class="col full-width flex">

            <div class="form__group">
              <label class="form__label">Enter Sales Order ID</label>
              <input type="text" class="form__control" pInputText [(ngModel)]="SalesOrderAppId" [ngModelOptions]="{standalone: true}">
              <div *ngIf="isValueRequire" class="invalid-feedback-error">
                <span *ngIf="isValueRequire">
                 Sales order(s) is required.
                </span> 
              </div>
            </div>
            <div class="form__group btns-block">
              <button class="btn btn--primary" (click)="SyncSalesOrders()" [disabled]="isSubmitBtnDisabled">
                {{Buttontitle}}
              </button>
            </div>
          </div>

          <div class="col mb-15">
            <div class="status-checkbox full-width mb-15">
              <p-checkbox binary="true" [ngModelOptions]="{standalone: true}" [(ngModel)]="IsUpdateWIPStatus"
                          label="If box checked, the sales order will import without updating WIP State" (onChange)="onUpdateWIPStatus($event)">
              </p-checkbox>
             
            </div>

            <div class="status-checkbox full-width">
              
              <p-checkbox binary="true" [ngModelOptions]="{standalone:true}" [(ngModel)]="IsSendInvitationLink"
                          label="If box checked, then only appointment invitation link will triggered."></p-checkbox>
            </div>
          </div>

          <div class="col full-width">
            <label class="form__label">Note: Enter maximum 10 Sales Order IDs separated by commas.</label>
          </div>

        </form>
      </div>
    </div>

    <div class="sync-status-outer" *ngIf="isShowTable">
      <div class="sync-status">
        <div class="sync-status__title">
          <h3>Import Status</h3>
        </div>

        <div class="custom-table-outer table-header-fix">
          
          <table class="custom-table">
            <thead>
              <tr>
                <th>Sales Order ID</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let dynamic of lstGrid">
                <td>{{dynamic?.appSalesOrderAppId}}</td>
                <td>{{dynamic?.status}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

  <div class="loader-block" *ngIf="loading">
    <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
    </p-progressSpinner>
  </div>
</div>
