import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthenticationGuard } from '../shared/guards/authentication.guard';
import { AuthorizationGuard } from '../shared/guards/authorization.guard';
import { Constants } from '../shared/constants';
import { DiscardChangesGuard } from '../shared/guards/discard-changes.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { CalendarComponent } from './calendar/calendar.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { TherapistsComponent } from './therapists/therapists.component';
import { FacilitiesComponent } from './facilities/facilities.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { UsersComponent } from './users/users.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingsComponent } from './settings/settings.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { AppointmentDetailComponent } from './appointments/appointment-detail/appointment-detail.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { DoctorDetailComponent } from './doctors/doctor-detail/doctor-detail.component';
import { TherapistDetailComponent } from './therapists/therapist-detail/therapist-detail.component';
import { FacilityDetailComponent } from './facilities/facility-detail/facility-detail.component';
import { ReportDetailComponent } from './reports/report-detail/report-detail.component';
import { GeneralComponent } from './settings/general/general.component';
import { ApplicationComponent } from './settings/application/application.component';
import { PluginsComponent } from './settings/plugins/plugins.component';
import { NotificationsComponent } from './settings/notifications/notifications.component';
import { NotificationViewAllComponent } from '../shared/notification/notification.component';
import { DoctorManageComponent } from './doctors/doctor-manage/doctor-manage.component';
import { UserManageComponent } from './users/user-manage/user-manage.component';
import { FacilityManageComponent } from './facilities/facility-manage/facility-manage.component';
import { ViewByDateComponent } from './calendar/view-by-date/view-by-date.component';
import { SalesOrderDetailComponent } from './appointments/sales-order-detail/sales-order-detail.component'
import { PatientComponent } from './patients/patient.component'
import { ViewAvailabilityComponent } from './therapists/view-availability/view-availability.component';
import { ManageAvailabilityComponent } from './therapists/manage-availability/manage-availability.component';
import { AppoinmentCallListComponent } from './appointments/appoinment-call-list/appoinment-call-list.component'
import { MyChangePasswordComponent } from './users/my-change-password/my-change-password.component'
import { MyProfileComponent } from './users/my-profile/my-profile.component'
import { AdminComponent } from './AdminComponent';
import { TherapistAvailability } from '../shared/models/response/appointment/available-slots';
import { ViewTherapistAvailabilityComponent } from './calendar/view-therapist-availability/view-therapist-availability.component';
import { TherapistDayViewSchedulerComponent } from './calendar/therapist-day-view-scheduler/component';
import { BrightreeNewComponent } from './settings/brightree-new/brightree-new.component';
import { AppointmetsCancelledComponent } from './appointments/appointmets-cancelled/appointmets-cancelled.component';
import { SyncRcmSalesorderComponent } from './import-export/sync-rcm-salesorder/sync-rcm-salesorder.component';
import { ManagePermissionsComponent } from './settings/application/permissions-new/manage-permissions/manage-permissions.component';
import { TherapistManageNewComponent } from './therapists/therapist-manage-new/therapist-manage-new.component';
import { HolidayManageComponent } from './settings/application/holiday/holiday-manage/holiday-manage.component';
import { SyncSalesorderByidComponent } from './import-export/sync-salesorder-byid/sync-salesorder-byid.component';
import { ReimportSalesorderByidComponent } from './import-export/reimport-salesorder-byid/reimport-salesorder-byid.component';
import { OnBoardingComponent } from './settings/on-boarding/on-boarding.component';
import { VoiceCallRecordingsComponent } from './voice-call-recordings/voice-call-recordings.component';
import { BulkUploadComponent } from './voice-call-recordings/bulk-upload/bulk-upload.component';
import { ExportReportComponent } from './reports/export-report/export-report.component';
import { AppointmentNoshowComponent } from './appointments/appointment-noshow/appointment-noshow.component';
import { AppointmentCancelOrderComponent } from './appointments/appointment-cancel-order/appointment-cancel-order.component';
import { SearchPatientBrightreeDetailComponent } from './search-patient-brightree-detail/search-patient-brightree-detail.component';
import { TimeslotAvailabilitySummaryComponent } from './reports/timeslot-availability-summary/timeslot-availability-summary.component';
import { ViewAllNotificationsComponent } from './view-all-notifications/view-all-notifications.component';
import { TherapistAuditLogsComponent } from './therapists/therapist-audit-logs/therapist-audit-logs.component'

import { WeekViewTherapistComponent } from './calendar/week-by-therapist-scheduler/component';

import { AppointmentUrgentCallbackComponent } from './appointments/appointment-urgent-callback/appointment-urgentcallback.component';
import { AutosyncSalesorderComponent } from './reports/autosync-salesorder/autosync-salesorder.component';

import { TodayDashboardComponent } from './dashboard/today-dashboard/today-dashboard.component';
import { TodaysDashboardListingComponent } from './todays-dashboard-listing/todays-dashboard-listing.component';

import { ViewTherapistAvailabilityV1Component } from './calendar/view-therapist-availability-v1/view-therapist-availability-v1.component';
import { TherapistDayViewSchedulerV1Component } from './calendar/therapist-day-view-scheduler-v1/component-v1';
import { WeekViewTherapistV1Component } from './calendar/week-by-therapist-scheduler-v1/component-v1';



const routes: Routes = [
  {
    path: '', component: AdminComponent,
    canActivate: [AuthenticationGuard],
    canLoad: [AuthenticationGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'today-dashboard',
        component: TodayDashboardComponent
      },
      {
        path: 'view-all-notifications',
        component: ViewAllNotificationsComponent
      },
      {
        path: 'appointments',
        component: AppointmentsComponent
      },      
      {
        path: 'appointments/detail/:id',
        component: AppointmentDetailComponent
      },
      {
        path: 'appointments/detail/:id/:isback',
        component: AppointmentDetailComponent
      },
      {
        path: 'appointments/view-locked-appointments',
        component: AppoinmentCallListComponent
      },
      {
        path: 'appointments/salesorders/detail/:id',
        component: SalesOrderDetailComponent
      },
      {
        path: 'appointments/salesorders/detail/:id/:isback',
        component: SalesOrderDetailComponent
      },

      {
        path: 'appointments/view-cancelled-appointments',
        component: AppointmetsCancelledComponent
      },
      {
        path: 'calendar/view-therapist-appointments',
        component: TherapistDayViewSchedulerComponent
      },
      {
        path: 'calendar/view-therapist-appointments/:date/:facilityid',
        component: TherapistDayViewSchedulerComponent
      },
      {
        path: 'calendar/view-therapist-appointments-v1/:date/:facilityid',
        component: TherapistDayViewSchedulerV1Component
      },
      {
        path: 'calendar/view-by-date',
        component: ViewByDateComponent
      },
      {
        path: 'calendar',
        component: ViewByDateComponent
      },
      {
        path: 'calendar/view-therapist-availability/:id',
        component: ViewTherapistAvailabilityComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'users/add',
        component: UserManageComponent
      },
      {
        path: 'users/edit/:id',
        component: UserManageComponent
      },
      {
        path: 'users/detail/:id',
        component: UserDetailComponent
      },
      {
        path: 'doctors',
        component: DoctorsComponent
      },
      {
        path: 'doctors/add',
        component: DoctorManageComponent
      },
      {
        path: 'doctors/edit/:id',
        component: DoctorManageComponent
      },
      {
        path: 'doctors/detail/:id',
        component: DoctorDetailComponent
      },
      
      {
        path: 'therapists',
        component: TherapistsComponent
      },
      {
        path: 'therapists/add',
        component: TherapistManageNewComponent
      },
      {
        path: 'therapists/edit/:id',
        component: TherapistManageNewComponent
      },
      {
        path: 'therapists/detail/:id',
        component: TherapistDetailComponent
      },
      {
        path: 'therapists/viewavailability/:id',
        component: ViewAvailabilityComponent
      },
      
      {
        path: 'therapists/manageavailability/:id/:id1',
        component: ManageAvailabilityComponent
      },
      {
        path: 'therapists/history/:id',
        component: TherapistAuditLogsComponent
      },
      {
        path: 'therapists/history/:id/:isback',
        component: TherapistAuditLogsComponent
      },
      {
        path: 'patients',
        component: PatientComponent,
      },
      {
        path: 'facilities',
        component: FacilitiesComponent,
      },
      {
        path: 'facilities/add',
        component: FacilityManageComponent
      },
      {
        path: 'facilities/edit/:id',
        component: FacilityManageComponent
      },
      {
        path: 'facilities/detail/:id',
        component: FacilityDetailComponent
      },
      {
        path: 'importexport',
        component: ImportExportComponent
      },
      {
        path: 'importexport/SyncRcmSalesOrder',
        component: SyncRcmSalesorderComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'reports/detail/:reportType',
        component: ReportDetailComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'settings/general',
        component: GeneralComponent
      },
      {
        path: 'settings/application',
        component: ApplicationComponent
      },
      {
        path: 'settings/application/permission/add',
        component: ManagePermissionsComponent
      },
      {
        path: 'settings/application/permission/edit/:id',
        component: ManagePermissionsComponent
      },
      {
        path: 'settings/plugins',
        component: PluginsComponent
      },
      {
        path: 'settings/brightree',
        component: BrightreeNewComponent
      },
      {
        path: 'settings/onboarding',
        component: OnBoardingComponent
      },
      {
        path: 'settings/notifications',
        component: NotificationsComponent
      },
      {
        path: 'notifications',
        component: NotificationViewAllComponent
      },
      {
        path: 'appointments/search-results',
        component: SearchResultsComponent
      },
      {
        path: 'appointments/view-urgentcallbacks',
        component: AppointmentUrgentCallbackComponent
      },
      
      {
        path: 'my-profile',
        component: MyProfileComponent
      },

      {
        path: 'change-password',
        component: MyChangePasswordComponent
      },
      {
        path: 'settings/application/holiday/add',
        component: HolidayManageComponent
      },
      {
        path: 'settings/application/holiday/edit/:id',
        component: HolidayManageComponent
      },
      {
        path: 'importexport/SyncSalesOrderById',
        component: SyncSalesorderByidComponent
      },
      {
        path: 'importexport/ReImportSalesOrderById',
        component: ReimportSalesorderByidComponent
      },
      {
        path: 'voice-call-recording',
        component: VoiceCallRecordingsComponent
      },
      {
        path: 'voice-call-recording/bulk-upload',
        component: BulkUploadComponent
      },
      {
        path: 'reports/history/:reportType',
        component: ExportReportComponent
      },
      {
        path: 'appointments/view-noshow-appointments',
        component: AppointmentNoshowComponent
      },
      {
        path: 'appointments/view-cancel-orders',
        component: AppointmentCancelOrderComponent
      },
      {
        path: 'appointments/patients-detail',
        component: SearchPatientBrightreeDetailComponent,
      },
      {
        path: 'reports/time-slot-availability-summary',
        component: TimeslotAvailabilitySummaryComponent
      },

      {
        path: 'calendar/week-therapist-appointments',
        component: WeekViewTherapistComponent
      },
      {
        path: 'reports/app-autosync-salesorder',
        component: AutosyncSalesorderComponent
      },

      {
        path: 'appointments/todays-dashboard-listing',
        component: TodaysDashboardListingComponent
      },


      {
        path: 'calendar/view-therapist-availability-v1/:id',
        component: ViewTherapistAvailabilityV1Component
      },
      {
        path: 'calendar/view-therapist-appointments-v1',
        component: TherapistDayViewSchedulerV1Component
      },
      {
        path: 'calendar/week-therapist-appointments-v1',
        component: WeekViewTherapistV1Component
      },

    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
