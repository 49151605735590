import { Time } from "@angular/common";
export class AvailableSlots {
  appointmentDate: Date;
  avalable30Slot: number;
  avalable60Slot: number;
  appointmentCount: number;
  therapistCount: number;
  facilityName: string;
  facilityId: number;
}

export class FaciltiyForSlots {
  crmFacilityId: number;
  crmFacilityName: string;
}

export class TherapistAvailability {
  startTime: Date;
  endTime: Date;
  title: string;
  custDateId: number;
  availId: number;
  isHoliday: boolean;
  isRoadShow: boolean;
  isRemote: boolean;
  isFacility: boolean;
  isOffice: boolean;
  isFullDay: boolean;
  isGlobalHoliday: boolean;
  isRemoteAndFacility: boolean;
  isOnDemand: boolean = false;
  isSeries: boolean = false;
  isCustom:boolean=false;
  customStartTime:Date;
  customEndTime:Date;
  therapistBlockAvailabilities: any[];
}

export class ViewByDateResponse {
  dtDate: Date;
  facilityId: number;
  therapistId: number;
  therapistName: string;
  facilityName: string;
  selected: boolean;
}

export class ViewByDateResponseTranspose {
  viewByDateResponseDataTrans: ViewByDateResponseDataTrans[];
  dtDates: Date[];
}

export class ViewByDateResponseDataTrans {
  dtDateSun: Date;
  dtDateMon: Date;
  dtDateTue: Date;
  dtDateWed: Date;
  dtDateThu: Date;
  dtDateFri: Date;
  dtDateSat: Date;
  slotSun: number;
  slotMon: number;
  slotTue: number;
  slotWed: number;
  slotThu: number;
  slotFri: number;
  slotSat: number;
  aptSun: Date;
  aptMon: Date;
  aptTue: Date;
  aptWed: Date;
  aptThu: Date;
  aptFri: Date;
  aptSat: Date;
  facilityId: number;
  therapistId: number;
  therapistName: string;
  facilityName: string;
  selected: boolean;
  timeZone: string;
}
export class ViewByDateResponseTransposeModel {
  viewByDateResponseDataTrans: ViewByDateResponseModel[];
  dtDates: Date[];
}
export class ViewByDateResponseModel {
  dtDateSun: Date;
  dtDateMon: Date;
  dtDateTue: Date;
  dtDateWed: Date;
  dtDateThu: Date;
  dtDateFri: Date;
  dtDateSat: Date;
  slot15Sun: number;
  slot15Mon: number;
  slot15Tue: number;
  slot15Wed: number;
  slot15Thu: number;
  slot15Fri: number;
  slot15Sat: number;
  slot30Sun: number;
  slot30Mon: number;
  slot30Tue: number;
  slot30Wed: number;
  slot30Thu: number;
  slot30Fri: number;
  slot30Sat: number;
  slot45Sun: number;
  slot45Mon: number;
  slot45Tue: number;
  slot45Wed: number;
  slot45Thu: number;
  slot45Fri: number;
  slot45Sat: number;
  slot60Sun: number;
  slot60Mon: number;
  slot60Tue: number;
  slot60Wed: number;
  slot60Thu: number;
  slot60Fri: number;
  slot60Sat: number
  facilityId: number;
  therapistId: number;
  therapistName: string;
  facilityName: string;
  selected: boolean;
  timeZone: string;
}

export class HolidayResponse {
  appHolidayId: number;
  appHolidayName: string;
  appHolidayYear: number;
  appHolidayStartDateTime: Date;
  appHolidayEndDateTime: Date;
  isFullDay: boolean;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  appHolidayStartDateTimeString: string;
  appHolidayEndDateTimeString: string;
  appHolidayStartTime: Time;
  appHolidayEndTime: Time;
  appHolidayStartDateString: string;
  appHolidayEndDateString: string;
  appHolidayStartTimeString: string;
  appHolidayEndTimeString: string;
  excludeFacilitiesIds: string;
  excludeFacilitiesNames: string;
}
