<div class="content-area">
    <div class="title-block">
        <div class="title-block__left">
            <h1>{{title}}</h1>
        </div>  
        <div class="title-block__right">
            <a class="back-btn" href="javascript:void(0);" [routerLink]='["/admin/voice-call-recording"]'><i
                class="back-new"></i> Back</a>
          </div>     
    </div>

    <div class="sync-page-outer__export">
        <div class="sync-page-outer__export__block">
          <!-- <div class="sync-page-outer__export__title">
            <h2></h2>
            <a class="filter-action" href="javascript:void(0);" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'" (click)="clickEventFilter()">View Upload History</a>
          </div> -->

          <div class="sync-page-outer__block">
            <a class="filter-action" href="javascript:void(0);" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'" (click)="clickEventFilter()">View Upload History</a>

            <div class="from__group">
              <!-- <label class="form__label sales-orders-lable">Upload</label> -->
              <div class="sync-page-outer__export__column-right">

                <div class="sync-page-outer__export__column">
                  <div class="sales-opders__block__right">
                    <div class="form__group">
                      <label class="form__label">From</label>
                      <p-calendar class="datepicker" [showIcon]="true" placeholder="MM/DD/YY" [(ngModel)]="selectedFromDate" [maxDate]="maxDate"></p-calendar>
                    </div>
                    <div class="form__group">
                      <label class="form__label">To</label>
                      <p-calendar class="datepicker" [showIcon]="true" placeholder="MM/DD/YY" [(ngModel)]="selectedToDate" [maxDate]="maxDate"></p-calendar>
                    </div>
                  </div>
                </div>
                <div class="sync-page-outer__export__column">
                  <small class="note">You can only upload 3 months old recording to your SFTP server</small>
                </div>

                <div class="sync-page-outer__export__column">
                  <button class="btn btn--primary sales-opders__block--btn" (click)="onUploadButtonClick()">Upload</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="tablet-scroll bulk-upload-table" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'">
        <div class="table-outer-main">
          <h3>Upload history</h3>
          <!-- <div class="history-table">
            <table class="table">
                <thead>
                    <tr>
                        <th width="320">Uploadedby User</th>
                        <th width="250">Uploaded Date</th>
                        <th width="200">Date Range</th>
                        <th width="200">Total Files</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>John Baki</td>
                        <td>04/12/2012 12:27 pm</td>
                        <td>Jan-2022</td>
                        <td>0</td>
                    </tr>
                </tbody>
            </table>         
          </div> -->

          <p-table class="table-outer admin-tablet" #nsdl [value]="exportHistory"
                     [paginator]="totalRecords>0?true:false" sortMode="single" sortField="SchCallRecordingUploadDateTime"
                     [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="-1" [rows]="getTotalRows()"
                     [totalRecords]="totalRecords" [lazy]="true" [customSort]="true" (onLazyLoad)="onLazyLoad($event)">
              <ng-template pTemplate="header">
                <tr>
                  <th class="table-outer__th" pSortableColumn="AppUserFName">
                    Uploaded By User<p-sortIcon field="AppUserFName"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="SchCallRecordingUploadDateTime">
                    Uploaded Date<p-sortIcon field="SchCallRecordingUploadDateTime"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="SchCallRecordingUploadFromDate">
                    Date Range<p-sortIcon field="SchCallRecordingUploadFromDate"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="SchCallRecordingUploadCount">
                    Total Records<p-sortIcon field="SchCallRecordingUploadCount"></p-sortIcon>
                  </th>
                  <th class="table-outer__th" pSortableColumn="SchCallRecordingFailedUploadCount">
                    Failed Records<p-sortIcon field="SchCallRecordingFailedUploadCount"></p-sortIcon>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-export>
                <tr>
                  <td class="table-outer__td">
                    <span class="link">
                        {{export.appUserFName}} {{export.appUserLName}}
                    </span>
                  </td>
                  <td class="table-outer__td">
                    <span class="facilityName">
                      {{export.schCallRecordingUploadDateTime | date:appDateFormat.dateFormat}} {{export.schCallRecordingUploadDateTime | date:'shortTime':appDateFormat.timeZone | uppercase}}
                    </span>
                  </td>
                  <td class="table-outer__td">
                    <span class="facilityName">
                      {{export.schCallRecordingUploadFromDate | date:appDateFormat.dateFormat}} -
                      {{export.schCallRecordingUploadToDate | date:appDateFormat.dateFormat}}
                    </span>
                  </td>
                  <td class="table-outer__td">
                    <a class="actionmenu-btn" href="javascript:void(0)"
                    (click)="export?.schCallRecordingUploadCount>0 &&
                    showTotalUploadedFiles($event,totalUploadedFiles,export?.schCallRecordingUploadData)">{{export?.schCallRecordingUploadCount}}</a>
                    <!-- <span class="facilityName">
                      {{export.schCallRecordingUploadCount}}
                    </span> -->
                  </td>
                  <td class="table-outer__td">
                    <a class="actionmenu-btn" href="javascript:void(0)"
                    (click)="export?.schCallRecordingFailedUploadCount>0 &&
                    showTotalFailedUploadFiles($event,totalFailedUploadFiles,export?.schCallRecordingFailedUploadData)">{{export?.schCallRecordingFailedUploadCount}}</a>
                    <!-- <span class="facilityName">
                      {{export.schCallRecordingUploadCount}}
                    </span> -->
                  </td>
                  <!--<td> <span class="facilityName"></span> </td>-->
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr class="primeng-table__body">
                  <td colspan="4" class="table-outer__td text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft" class="table-pagination">
                <span class="table-pagination__records">
                  Total Records : &nbsp;<strong> {{totalRecords}}</strong>
                </span>
              </ng-template>
            </p-table>
            <p-overlayPanel #totalUploadedFiles [dismissable]="true" [showCloseIcon]="true">
              <div class="overly-filter__outer">
              <h2 class="filter-title">Total Uploaded Files</h2>
              <div class="overly-filter__scroll">
                <textarea rows="5" cols="20" class="form__textarea" readonly>{{totalUploadedFilesList}}</textarea>
              </div>
              </div>
            </p-overlayPanel>
            <p-overlayPanel #totalFailedUploadFiles [dismissable]="true" [showCloseIcon]="true">
              <div class="overly-filter__outer">
              <h2 class="filter-title">Total Failed Upload Files</h2>
              <div class="overly-filter__scroll">
                <textarea rows="5" cols="20" class="form__textarea" readonly>{{totalFailedUploadFilesList}}</textarea>
              </div>
              </div>
            </p-overlayPanel>
        </div>
      </div>

      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    <!-- <div class="page-scroll bulk-upload-content">
        <form class="form">
            <div class="form__group">
                <label class="form__label">From</label>
                <p-calendar class="datepicker" [showIcon]="true" placeholder="MM/DD/YY" dateFormat="mm/dd/yy"></p-calendar>
            </div>
            <div class="form__group">
                <label class="form__label">To</label>
                <p-calendar class="datepicker" [showIcon]="true" placeholder="MM/DD/YY" dateFormat="mm/dd/yy"></p-calendar>
            </div>

            <div class="form__group btn-block">
                <button class="btn btn--primary">Search</button>
                <a class="btn btn--secondary clear-filter" href="javascript:void(0)">Clear</a>
            </div>
            
        </form>
        <div class="upload-history">
            <div class="upload-history__title">
                <h3>Upload History</h3>
                <a class="filter-action" href="javascript:void(0);" [ngClass]="clickEventFilterStatus ? 'active' : 'inactive'" (click)="clickEventFilter()">View Upload History</a>
            </div>
            <div class="upload-history__content">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Uploaded User</th>
                            <th>Uploaded Date</th>
                            <th>Date Range</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>John Baki</td>
                            <td>04/12/2012 12:27 pm</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> -->
</div>



