<div class="content-area">
  <div class="title-block">
    <div class="title-block__left">
      <h1>{{title}}</h1>
    </div>
    <div class="title-block__right">
      <a href="javascript:void(0);" class="filter-block filter-show-hide" (click)="overlyFilter.toggle($event)"
        pTooltip="Filters" tooltipPosition="right">
        <img src="assets/img/filter-ic.svg" width="18" alt="filters">
      </a>
    </div>
  </div>

  <div class="page-scroll admin-user">
    <div class="table-outer-main">
      <p-table #nsdl class="table-outer" [value]="doctors" [paginator]="totalRecords>0?true:false" sortMode="single"
        sortField="CrmDoctorLastName" [first]="searchRequestModel.FirstRecordIndex" [sortOrder]="1"
        [rows]="getTotalRows()" [totalRecords]="totalRecords" [lazy]="true" [customSort]="true"
        (onLazyLoad)="onLazyLoad($event)" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th pFrozenColumn class="table-outer__th" pSortableColumn="CrmDoctorLastName">Doctor <p-sortIcon
                field="CrmDoctorLastName"></p-sortIcon>
            </th>
            <th class="table-outer__th" pSortableColumn="FacilityName">Location <p-sortIcon
                field="FacilityName"></p-sortIcon>
            </th>
            <th pFrozenColumn alignFrozen="right" class="table-outer__th table-dot-column" width="120">Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-doctor>
          <tr>
            <td pFrozenColumn class="table-outer__td">
              <span class="link">
                <a href="javascript:void(0)" (click)="onView(doctor.crmDoctorId)">
                  {{doctor.crmDoctorLastName}}, {{doctor.crmDoctorFirstName}}
                </a>
                <span class="sub-text">{{doctor?.crmBranch?.branchName}}</span>
              </span>
            </td>
            <td class="table-outer__td"> <span class="facilityName" pTooltip="{{doctor.facilityName}}"
                tooltipPosition="left">{{doctor.facilityName}}</span> </td>
            <td pFrozenColumn alignFrozen="right" class="table-outer__td table-dot-column">
              <div class="action-column">
                <a class="actionmenu-btn" href="javascript:void(0)"
                  (click)="selectDoctor($event,doctor,therapistsAction)">
                  <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                    <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                  </svg>
                </a>
              </div>
              <p-overlayPanel [dismissable]="true" appendTo="body" class="table-action-popup table-right-action"
                #therapistsAction>
                <ul class="quick-link-menu">
                  <li>
                    <a href="javascript:void(0)" class="edit-row" (click)="onEdit(selectedDoctor.crmDoctorId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="103.901" height="98.574"
                        viewBox="0 0 103.901 98.574">
                        <g id="edit-icon-new" transform="translate(-52.983 -52.983)">
                          <path id="Path_3" data-name="Path 3"
                            d="M323.731,72.125l13.32,13.313ZM332.3,60.4,296.28,96.412a13.319,13.319,0,0,0-3.648,6.8l-3.327,16.653,16.653-3.333a13.305,13.305,0,0,0,6.8-3.641l36.016-36.016A11.655,11.655,0,1,0,332.3,60.4Z"
                            transform="translate(-202.246)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                          <path id="Path_4" data-name="Path 4"
                            d="M152.884,117.371v18.115a11.745,11.745,0,0,1-3.745,8.539,13.177,13.177,0,0,1-9.041,3.537H69.769a13.179,13.179,0,0,1-9.042-3.537,11.746,11.746,0,0,1-3.745-8.539V69.066a11.746,11.746,0,0,1,3.745-8.539,13.178,13.178,0,0,1,9.042-3.537H88.95"
                            transform="translate(0 -0.006)" fill="none" stroke="#6f64a7" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="8" />
                        </g>
                      </svg>
                      Edit
                    </a>
                  </li>
                  <li *ngIf="selectedDoctor?.isActive">
                    <a href="javascript:void(0)" class="delete-row" (click)="onDelete(selectedDoctor.crmDoctorId)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30">
                        <g id="delete-icon-new" transform="translate(-3 -1)">
                          <path id="Path_1" data-name="Path 1"
                            d="M24,31H8a3,3,0,0,1-3-3V9A1,1,0,0,1,7,9V28a1,1,0,0,0,1,1H24a1,1,0,0,0,1-1V9a1,1,0,0,1,2,0V28a3,3,0,0,1-3,3Z"
                            fill="#6f64a7" />
                          <path id="Path_2" data-name="Path 2" d="M28,7H4A1,1,0,0,1,4,5H28a1,1,0,0,1,0,2Z"
                            fill="#6f64a7" />
                          <path id="Path_3" data-name="Path 3"
                            d="M20,7a1,1,0,0,1-1-1V3H13V6a1,1,0,0,1-2,0V2a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1V6A1,1,0,0,1,20,7Z"
                            fill="#6f64a7" />
                          <path id="Path_4" data-name="Path 4"
                            d="M16,26a1,1,0,0,1-1-1V11a1,1,0,0,1,2,0V25A1,1,0,0,1,16,26Z" fill="#6f64a7" />
                          <path id="Path_5" data-name="Path 5"
                            d="M21,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,21,24Z" fill="#6f64a7" />
                          <path id="Path_6" data-name="Path 6"
                            d="M11,24a1,1,0,0,1-1-1V13a1,1,0,0,1,2,0V23A1,1,0,0,1,11,24Z" fill="#6f64a7" />
                        </g>
                      </svg>
                      Delete
                    </a>
                  </li>
                </ul>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr class="primeng-table__body" *ngIf="!loading">
            <td colspan="4" class="text-center no-records-found-doctors">{{noRecordsFoundMessage}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="paginatorleft" class="table-pagination">
          <span class="table-pagination__records">
            Total Records : &nbsp;<strong> {{totalRecords}}</strong>
          </span>
        </ng-template>
      </p-table>

      <div class="loader-block" *ngIf="loading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
</div>

<!-- filter -->
<p-overlayPanel class="overly-filter" #overlyFilter [showCloseIcon]="true">
  <div class="overly-filter__outer">
    <h2 class="filter-title">Filters</h2>
    <div class="overly-filter__scroll">
      <div class="form__group">
        <label class="form__label">Search</label>
        <div class="search-box">
          <input type="text" class="form__control" pInputText placeholder="Doctor" #searchBox style="width:auto"
            (keyup.enter)="onSearchButtonClick()">
          <button *ngIf="searchBox.value !=''" class="clear-btn" type="button" (click)="searchBox.value =''">
            <i class="ic cancel"></i>
          </button>
          <button class="search-btn" pButton type="button">
            <i class="search-new"></i>
          </button>
        </div>
      </div>
      <!-- <div class="form__group">
        <label class="form__label">Status</label>
        <p-dropdown class="select-box filter-status" #statusDropdown [options]="doctorStatus"
          [(ngModel)]="selectedStatus" (onChange)="onStatusChange($event)" placeholder="Status" optionLabel="label"
          [showClear]="true">
        </p-dropdown>
      </div> -->
      <div class="form__group">
        <!-- <p-dropdown class="select-box filter-status" [options]="doctorStatus" (onChange)="onStatusChange($event)" [(ngModel)]="selectedName" placeholder="Status" optionLabel="name" [showClear]="true"></p-dropdown>                         -->
      </div>
      <div class="form__group">
        <p-checkbox name="groupname" [(ngModel)]="isFacilityIncluded" (change)="onCheck()" label="Assigned Facility"
          binary="true"></p-checkbox>
      </div>
    </div>
    <div class="form__group btn-block">
      <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
      <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
        (click)="onClearFilterSearch();onFilterSearch();">Clear</a>
    </div>
  </div>
</p-overlayPanel>