import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { SearchRequestModel } from 'src/app/shared/models/request/search-request-model';
import { Router, ActivatedRoute } from '@angular/router';
import { TherapistService } from '../servicies/therapist.service';
import { takeUntil, finalize } from 'rxjs/operators';
import { Result, ResultOfT } from 'src/app/shared/models/response/result';
import { PagedResponseModel } from 'src/app/shared/models/response/paged-response-model';
import { TherapistResponseModel } from 'src/app/shared/models/response/therapist/therapist-response-model';
import { UserInfoResponseModel } from 'src/app/shared/models/response/userInfo-response-model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { UserService } from 'src/app/admin/servicies/user.service';
import { BranchResponseModel } from 'src/app/shared/models/response/branch-response-model';
import { GlobalSearchService } from '../servicies/globalSearch.service';
import { GlobalSearchModel } from 'src/app/shared/models/common/global.search'
import { SelectedTherapistResponseModel, TherapistDefaultFacilityModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-model';
import { TherapistDefaultFacilityResponseModel } from 'src/app/shared/models/response/therapist/therapist-default-facility-response-model';
import { UserAuthResponseModel } from 'src/app/shared/models/response/access-token-response-model';
import { LinkedTherapistWithCurrentFacilitiesRequestModel, LinkedTherapistWithCurrentFacilitiesResponseModel } from 'src/app/shared/models/response/facility/facility-response-model';
@Component({
  selector: 'app-therapists',
  templateUrl: './therapists.component.html',
  styleUrls: ['./therapists.component.sass'],
  providers: [DialogService]
})
export class TherapistsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('therapistBox', { static: true }) searchBox: ElementRef;
  @ViewChild('statusDropdown') statusDropdown: ElementRef;
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  title = 'Therapists';
  searchRequestModel: SearchRequestModel;
  selectedTherapist: TherapistResponseModel;
  therapists: TherapistResponseModel[];
  selectedTherapists: TherapistResponseModel[];

  hasPageHistory: boolean;
  totalRecords: number;
  totalPages: number;
  therapistStatus: { label: string, status: boolean }[];
  selectedStatus: { label: string, status: boolean };
  branchResponseModel: BranchResponseModel[];
  selectedbranch: BranchResponseModel;
  //global search
  searchText: string;
  searchFor: string;
  isGlobalSearch: boolean = false;
  orderObj: any;
  currentUserBranch: number;
  currentUserToken: UserAuthResponseModel;
  linkedFacilitiesWithCurrentTherapistDetails: LinkedTherapistWithCurrentFacilitiesResponseModel[];
  linkedFacilitiesWithCurrentTherapistActiveDetails: LinkedTherapistWithCurrentFacilitiesResponseModel[];
  ConfirmToChangeSelfScheulingStatus: boolean = false;
  selectedTherapistsCount: number;
  linkedFacilitiesWithCurrentTherapistCount: number;
  isEnableSelfSchedulingForSelected: boolean;

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private therapistService: TherapistService,
    messageService: MessageService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private dialougeService: DialogService) {
    super(messageService);

    this.totalRecords = 0;
    this.totalPages = 1;
    this.hasPageHistory = false;
    this.currentUserBranch = this.webStorageService.getUserBranch();
    this.currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState) {
      if (currentNavigationState.searchRequestModel) {
        this.searchRequestModel = currentNavigationState.searchRequestModel;
        if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] != null) {
          // tslint:disable-next-line: max-line-length
          this.selectedStatus = { label: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) === true ? 'Active' : 'Inactive', status: Boolean(this.searchRequestModel.dictionaryFilter['ActiveStatus']) };
        } else {
          // this.selectedStatus = { label : 'All' , status : true };
          this.selectedStatus = { label: 'Active', status: true };
        }
        this.hasPageHistory = true;
      } else {
        this.initializeSearchRequestModel();
      }
    }
  }

  async ngOnInit() {
    this.initializeSearchRequestModel();
    this.therapistStatus = [
      { label: 'All', status: true },
      { label: 'Active', status: true },
      { label: 'Inactive', status: false },
    ];
    //await this.getBranches();
    if (this.searchRequestModel) {
      if (this.searchRequestModel.dictionaryFilter['SearchText'] !== null) {
        this.searchBox.nativeElement.value = this.searchRequestModel.dictionaryFilter['SearchText'] !== undefined ? this.searchRequestModel.dictionaryFilter['SearchText'] : '';
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'true') {
        this.selectedStatus = { label: 'Active', status: true };
      }
      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == 'false') {
        this.selectedStatus = { label: 'Inactive', status: false };
      }

      if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == '') {
        this.selectedStatus = { label: 'All', status: true };
      }
    }
  }

  private async getBranches() {
    this.progress.next(true);
    await this.userService.getAllBranches()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.branchResponseModel = result.responseModel;
            this.branchResponseModel = new Array();
            let branch = new BranchResponseModel();
            branch.crmBranchId = 0;
            branch.crmBranchName = "All Branches";
            this.branchResponseModel.push(branch);
            result.responseModel.forEach(value => {
              this.branchResponseModel.push(value);
            });
            this.selectedbranch = this.branchResponseModel[0];
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onBranchChange(event) {
    if (event.value) {
      this.searchRequestModel.crmBranchId = event.value.crmBranchId;
      this.resetPageIndex();
      this.getTherapists(true);
    }
  }

  ngAfterViewInit() {
    // this.setFilters();
    this.hasPageHistory = false;
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  getTotalRows() {
    // Set page size to 'totalRecords' when page size is 'All'

    if (this.searchRequestModel.PageSize) {
      return this.searchRequestModel.PageSize;
    }

    return this.totalRecords;
  }

  onPageSizeChanged(pageSize: any) {
    if (pageSize === 'All') {
      this.searchRequestModel.PageSize = null;
    } else {
      this.searchRequestModel.PageSize = pageSize;
    }

    this.resetPageIndex();
    this.getTherapists(true);
  }

  onSearchButtonClick() {
    // // this.searchRequestModel.dictionaryFilter = {};
    // this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    // // let selSatatus = this.selectedStatus;
    // // if (selSatatus)
    // //   this.searchRequestModel.dictionaryFilter['SalesOrdeStatus'] = selSatatus.wipStatesName;
    // this.resetPageIndex();
    // this.getTherapists(true);
  }

  onStatusChange(event) {
    this.searchRequestModel.dictionaryFilter = {};
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;

    if (event.value != null) {
      if (event.value.label !== "All")
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = event.value.status.toString();
      else
        this.searchRequestModel.dictionaryFilter['ActiveStatus'] = '';
    }
    // this.resetPageIndex();
    // this.getTherapists(true);
  }

  onLazyLoad(event: LazyLoadEvent) {
    // If page history exist then don't use 'pageInfo.offset' because initially it will be 0
    // If doesn't exist then 'pageInfo.offset' which contains next page request
    if (!this.hasPageHistory) {
      this.searchRequestModel.FirstRecordIndex = event.first;
      this.searchRequestModel.CurrentPageIndex = event.first / 20;
      this.searchRequestModel.PageSize = 20;
      this.searchRequestModel.SortByColumnName = event.sortField;
      this.searchRequestModel.IsAscendingOrder = event.sortOrder === 1 ? true : false;
      this.searchRequestModel.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    this.getTherapists(this.hasPageHistory);
  }

  selectTherapist(event, therapist: TherapistResponseModel, overlaypanel: OverlayPanel) {

    this.selectedTherapist = therapist;
    overlaypanel.toggle(event);
  }


  onView(therapistId: string) {
    this.router.navigate(['/admin/therapists/edit/', therapistId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onEdit(therapistId: string) {
    this.router.navigate(['/admin/therapists/edit/', therapistId], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onViewHistory(therapistId: string) {
    let setUrl = '/admin/therapists/history/' + therapistId + '/threapistgrid';
    this.router.navigate([setUrl], { state: { searchRequestModel: this.searchRequestModel } });
  }

  onDelete(therapistId: string) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response)
        return;
      this.therapistService.removeTherapist(therapistId)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            this.showSuccessMessage('Therapist deleted successfully');
            this.getTherapists(true);
          } else {
            this.showWarningMessage(result.message);
          }
        }, (httpResponseError) => {
          this.showErrorMessage(httpResponseError.message);
          this.loading = false;
        });
    });
  }

  private resetPageIndex() {
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.FirstRecordIndex = 0;
  }

  private initializeSearchRequestModel() {

    if (this.hasPageHistory)
      return;


    this.searchRequestModel = new SearchRequestModel();
    // this.searchRequestModel.crmBranchId = this.currentUserBranch;
    //if (this.isGlobalSearch && this.searchFor == 'T') {
    //  this.searchRequestModel.dictionaryFilter = {}
    //  this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchText;
    //}
    this.searchRequestModel.FirstRecordIndex = 0;
    this.searchRequestModel.CurrentPageIndex = 0;
    this.searchRequestModel.PageSize = +this.pageSizes[0];
    this.searchRequestModel.SortOrder = 'ASC';
    this.searchRequestModel.SortByColumnName = 'AppUserFname';

    if (this.searchRequestModel.dictionaryFilter['ActiveStatus'] == null) {
      this.searchRequestModel.dictionaryFilter['ActiveStatus'] = 'true';
      this.selectedStatus = { label: 'Active', status: true };
    }
  }

  private getTherapists(isRefresh: boolean) {
    this.loading = true;
    this.therapistService.getAllTherapist(this.searchRequestModel, isRefresh)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<PagedResponseModel<TherapistResponseModel>>) => {
        this.processResult<PagedResponseModel<TherapistResponseModel>>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {

              const pagedResponse = result.responseModel;
              if (pagedResponse) {
                this.therapists = pagedResponse.results;
                this.totalRecords = pagedResponse.totalRecords;
                this.totalPages = pagedResponse.totalPages;

                // Set page size to 'totalRecords' when page size is 'All'
                if (!this.searchRequestModel.PageSize) {
                  this.searchRequestModel.PageSize = pagedResponse.totalRecords;
                }
                this.loading = false;
              }
            }
          }
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }

  onFilterSearch() {
    if (this.searchBox.nativeElement.value)
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    else
      this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value;
    this.resetPageIndex();
    this.getTherapists(true);
  }

  onClearFilterSearch() {
    this.searchRequestModel.dictionaryFilter['SearchText'] = this.searchBox.nativeElement.value = '';
    this.selectedStatus = this.therapistStatus[1];
  }

  handleTherapistSelfScheduleAppointmentStatusChange(id, e) {
    let isChecked = e.checked;
    //this.TherapistSelfScheduleAppointmentStatus(id, isChecked);
    let therapistIds: number[] = [];
    therapistIds.push(id);
    this.selectedTherapists = [];
    this.selectedTherapists.push(this.therapists.find(x => x.appUserInfoId == id));

    this.CheckLinkFacilitesWithSelectedTherapist(therapistIds, isChecked);
  }

  TherapistSelfScheduleAppointmentStatus(therapistId, value) {
    let msg: string;
    if (value) {
      msg = 'Are you sure you want to enable Self-scheduling?';
    }
    else {
      msg = 'Are you sure you want to disable Self-scheduling?';
    }
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: msg,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.therapists.find(x => x.appUserInfoId == therapistId).crmIsAllowSelfScheduleAppointments = !value;
        return;
      }
      this.loading = true;
      const therapistResponseModel = new TherapistDefaultFacilityModel();
      therapistResponseModel.modifiedByUserInfoId = this.currentUserToken.userId;
      therapistResponseModel.appUserInfoId = therapistId;
      const therapistDefaultfacilityResponseModel = new TherapistDefaultFacilityResponseModel();
      therapistDefaultfacilityResponseModel.crmIsAllowSelfScheduleAppointments = value;
      therapistResponseModel.therapistDefaultFacility = therapistDefaultfacilityResponseModel;

      const manageTherapistStatus = this.therapistService.UpdateTherapistSelfScheduleAppointmentsStatus(therapistResponseModel);
      manageTherapistStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: Result) => {
          if (result && result.isSuccess) {
            if (value) {
              this.showSuccessMessage('Self-Scheduling has been activated');
            }
            else {
              this.showSuccessMessage('Self-Scheduling has been deactivated');
            }
            this.getTherapists(true);

          } else {
            this.loading = false;
            this.showErrorMessage(result.message);
          }

        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  SelectedTherapistSelfScheduleAppointmentStatus(value) {
    if (!this.selectedTherapists || this.selectedTherapists.length <= 0) {
      this.showWarningMessage('Please select therapist(s)');
      return;
    }

    let therapistIds: number[] = [];
    this.selectedTherapists.forEach(element => {
      therapistIds.push(element.appUserInfoId)
    });

    this.CheckLinkFacilitesWithSelectedTherapist(therapistIds, value);

    // let msg: string;
    // if (value) {
    //   msg = 'Are you sure you want to enable Self-scheduling?';
    // }
    // else {
    //   msg = 'Are you sure you want to disable Self-scheduling?';
    // }
    // this.dialougeService.open(ConfirmDialogComponent, {
    //   data:
    //   {
    //     message: msg,
    //   },
    //   header: 'Confirmation'
    // }).onClose.subscribe((response: boolean) => {
    //   if (!response) {
    //     return;
    //   }
    //   this.loading = true;
    //   let selectedTherapistResponseModel = new SelectedTherapistResponseModel();
    //   selectedTherapistResponseModel.isEnable = value;
    //   selectedTherapistResponseModel.therapistResponseModels = this.selectedTherapists;
    //   const manageTherapistStatus = this.therapistService.UpdateSelectedTherapistSelfScheduleAppointmentsStatus(selectedTherapistResponseModel);
    //   manageTherapistStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
    //     .subscribe((result: Result) => {
    //       if (result && result.isSuccess) {
    //         if (value) {
    //           this.showSuccessMessage('Self-Scheduling has been activated');
    //         }
    //         else {
    //           this.showSuccessMessage('Self-Scheduling has been deactivated');
    //         }
    //         this.getTherapists(true);
    //         this.selectedTherapists = [];
    //       } else {
    //         this.loading = false;
    //         this.showErrorMessage(result.message);
    //       }

    //     }, (httpResponseError) => {
    //       this.loading = false;
    //       this.showErrorMessage(httpResponseError.message);
    //     });
    // });
  }

  CheckLinkFacilitesWithSelectedTherapist(therapistIds: number[], value: boolean) {
    if (value) {
      let linkedFacilitiesWithCurrentTherapistRequestModel: LinkedTherapistWithCurrentFacilitiesRequestModel = {
        isEnableSelfSchedulingForSelected: !value,
        FacilityIds: [],
        TherapistIds: therapistIds
      };

      this.therapistService.GetAllLinkedFacilitiesWithCurrentTherapistDetails(linkedFacilitiesWithCurrentTherapistRequestModel)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result && result.isSuccess) {
            this.ConfirmToChangeSelfScheulingStatus = true;
            this.isEnableSelfSchedulingForSelected = value;
            this.linkedFacilitiesWithCurrentTherapistDetails = result.responseModel;
            this.selectedTherapistsCount = this.selectedTherapists.length;

            if (this.linkedFacilitiesWithCurrentTherapistDetails == null) {
              this.linkedFacilitiesWithCurrentTherapistCount = 0;
              return;
            }

            this.linkedFacilitiesWithCurrentTherapistActiveDetails = this.linkedFacilitiesWithCurrentTherapistDetails;
            if (this.linkedFacilitiesWithCurrentTherapistActiveDetails != undefined && this.linkedFacilitiesWithCurrentTherapistActiveDetails != null) {
              if (this.linkedFacilitiesWithCurrentTherapistDetails.length != this.linkedFacilitiesWithCurrentTherapistActiveDetails.length) {
                this.linkedFacilitiesWithCurrentTherapistCount = 0;
              }
              else {
                this.linkedFacilitiesWithCurrentTherapistCount = this.linkedFacilitiesWithCurrentTherapistDetails.length;
              }
            }

            // this.selectedTherapistsCount = this.selectedTherapists.length;
            // if (this.linkedFacilitiesWithCurrentTherapistDetails == null) {
            //   this.linkedFacilitiesWithCurrentTherapistCount = 0;
            // }
            // else {
            //   this.linkedFacilitiesWithCurrentTherapistCount = this.linkedFacilitiesWithCurrentTherapistDetails.length;
            // }
          }
        }, (httpResponseError) => {
          this.loading = false;
          this.showErrorMessage(httpResponseError.message);
        });
    }
    else {
      this.ConfirmToChangeSelfScheulingStatus = true;
      this.linkedFacilitiesWithCurrentTherapistDetails = null;
      this.selectedTherapistsCount = this.selectedTherapists.length;
      this.linkedFacilitiesWithCurrentTherapistCount = 0;
      this.isEnableSelfSchedulingForSelected = value;
    }
  }

  CancelSelfSchedulingChanges(isCancel: boolean) {
    this.ConfirmToChangeSelfScheulingStatus = false;
    if (isCancel) {
      this.getTherapists(true);
    }

    this.selectedTherapists = [];
    this.linkedFacilitiesWithCurrentTherapistDetails = null;
    this.selectedTherapistsCount = 0;
    this.linkedFacilitiesWithCurrentTherapistCount = 0
  }

  ConfirmSelfSchedulingChanges() {
    this.loading = true;
    let selectedTherapistResponseModel = new SelectedTherapistResponseModel();
    selectedTherapistResponseModel.isEnable = this.isEnableSelfSchedulingForSelected;
    selectedTherapistResponseModel.therapistResponseModels = this.selectedTherapists;
    const manageTherapistStatus = this.therapistService.UpdateSelectedTherapistSelfScheduleAppointmentsStatus(selectedTherapistResponseModel);
    manageTherapistStatus.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        if (result && result.isSuccess) {
          if (this.isEnableSelfSchedulingForSelected) {
            this.showSuccessMessage('Self-Scheduling has been activated');
          }
          else {
            this.showSuccessMessage('Self-Scheduling has been deactivated');
          }
          this.getTherapists(true);
          this.selectedTherapists = [];
          this.CancelSelfSchedulingChanges(false);
        } else {
          this.loading = false;
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }
}
