import { UserBranchResponseModel } from '../response/user-branch-response-model';
import { UserPermissionResponseModel } from '../response/user-permission-response-model';
import { UserRoleResponseModel } from '../response/user-role-response-model';

export class UserRequestModel {
  appUserInfoId: number;
  appUserEmail: string;
  appUserFname: string;
  appUserLname: string;
  appUserPhoneNumber: string;
  appUserIsReset: boolean;
  appUserStatus: boolean;
  appUserPasswordExpiry: Date;
  dateTimeUserLastLogin: Date;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  appIsLoggedIn: boolean;
  message: string;
  appRoleName: string;
  appRoleId: number;
  crmBranchName: string;
  crmBranchId: number;
  appPermissionName: string;
  appPermissionId: number;
  crmBrightreeUserId: number;
  userBranch: UserBranchResponseModel[];
  userRole: UserRoleResponseModel;
  userPermission: UserPermissionResponseModel[];
  enAppUserInfoId: string;
  // userBranch: string;
  // userRole: string;
}
