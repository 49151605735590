import { Component, OnInit, OnDestroy,AfterViewInit } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SettingsService } from 'src/app/admin/servicies/settings.service';
import { Subject } from 'rxjs';
import { finalize, first, takeUntil } from 'rxjs/operators';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { RoleResponseModel } from 'src/app/shared/models/response/role-response-model';
import { SettingsPermissionResponseModel } from 'src/app/shared/models/response/settings/settings-permission-response-model';
import { RoleFeatureModelPermission, RoleFeatureModulePermissionResponseModel } from 'src/app/shared/models/response/role-feature-modeul-permission';
import { DynamicCols, DynamicColsWithFreez } from 'src/app/shared/models/common/dynamic-cols';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-permissions-new',
  templateUrl: './permissions-new.component.html',
  styleUrls: ['./permissions-new.component.sass'],
  providers: [DialogService]
})

export class PermissionsNewComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  cars2: any[];
  cars5: any[];
  virtualCars: any[];
  totalRecords: number;
  cols: DynamicCols[];
  selectedRole: DynamicCols;
  frozenCars: any[];
  frozenCols: any[];
  scrollableCols: any[];
  sales: any[];
  colspanValue: any = 1;
  loading: boolean;
  canDelete: boolean;
  rowGroupMetadata: any;
  loggedInUserId: number;
  therapistId: number;
  roleResponseModel: RoleResponseModel[];
  settingsPermissionResponseModel: SettingsPermissionResponseModel[];
  roleFeatureModelPermission: RoleFeatureModelPermission[];
  roleFeatureModulePermissionResponseModel: RoleFeatureModulePermissionResponseModel[];
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private webStorageService: WebStorageService,
    private settingsService: SettingsService,
    private dialougeService: DialogService,
    messageService: MessageService) {
    super(messageService);
  }


  ngOnInit() {
    this.frozenCols = [];
    let frozColumn = new DynamicCols();
    frozColumn.field = 'appModuleFeatureName'
    frozColumn.header = 'List of Features'
    this.frozenCols.push(frozColumn);
    this.roleFeatureModulePermissionResponseModel = new Array<RoleFeatureModulePermissionResponseModel>();
    this.scrollableCols = [];
    this.scrollableCols.push(frozColumn);
    this.sales = [];
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getAllPermission();
  }
  resetRoleModel() {
    this.selectedRole = new DynamicCols();
    this.frozenCols = [];
    let frozColumn = new DynamicCols();
    frozColumn.field = 'appModuleFeatureName'
    frozColumn.header = 'List of Features'
    this.frozenCols.push(frozColumn);
    this.roleFeatureModulePermissionResponseModel = new Array<RoleFeatureModulePermissionResponseModel>();
    this.scrollableCols = [];
    this.scrollableCols.push(frozColumn);
    this.sales = [];
  }

  selectRoles(event, selectedRole: DynamicCols, overlaypanel: OverlayPanel) {
    this.selectedRole = selectedRole;
    overlaypanel.toggle(event);
  }
  ManagePermission(selectedRole: DynamicCols) {

    this.router.navigate(['/admin/settings/application/permission/edit/', selectedRole.field], { state: { selectedTab: 3 } });

  }
  ManageRoleRediection() {
    this.router.navigate(['/admin/settings/application/permission/add/'], { state: { selectedTab: 3 } });
  }
  onRoleDelete(selectedRole: DynamicCols) {

    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: `Are you sure you want to delete?`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.loading = true;
      this.settingsService.ActiveInaciveRoles(selectedRole.field, false)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result: ResultOfT<any>) => {
          this.processResult<any>(result, () => {

            if (result.isSuccess) {
              this.resetRoleModel();

              this.getAllPermission();
              this.showSuccessMessage("Role deleted successfully.");
            }
            else
              this.showWarningMessage(result.message);
          });
        }, (httpResponseError) => {

          this.showErrorMessage(httpResponseError.message);
        });
    });
  }

  private getAllPermission() {
    this.loading = true;

    this.settingsService.GetRoleFeatureModulePermissions()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<RoleFeatureModelPermission[]>) => {
        this.processResult<RoleFeatureModelPermission[]>(result, () => {
          this.roleFeatureModelPermission = result.responseModel;
          this.cols = [];
          let firstColumn = new DynamicCols();
          firstColumn.field = 'appModuleFeatureName'
          firstColumn.header = 'List of Features'
          this.cols.push(firstColumn);
          let rolesArray = new Array<DynamicCols>();
          this.roleFeatureModelPermission.forEach(value => {
            let column = new DynamicCols();
            let roleC = new DynamicCols();
            column.field = value.appRoleId.toString();
            roleC.field = value.appRoleId.toString();
            column.header = value.appRoleName;
            column.visible = value.canRoleDelete;
            this.cols.push(column);
            this.scrollableCols.push(column);
            rolesArray.push(roleC);
            value.moduleFeatureGroups.forEach(vale1 => {
              this.roleFeatureModulePermissionResponseModel.push(vale1)
            })
          });
          this.colspanValue = this.cols.length;
          let moduleFeatureGroups = new Array<RoleFeatureModulePermissionResponseModel>();
          moduleFeatureGroups = this.roleFeatureModelPermission[0].moduleFeatureGroups;
          moduleFeatureGroups.forEach(value => {
            let item = {}
            item["appModuleFeatureName"] = value.appModuleFeatureName;
            item["appModuleFeatureGroup"] = value.appModuleFeatureGroup;
            rolesArray.forEach(x => {
              var rolePermission = this.roleFeatureModulePermissionResponseModel.find(xy => xy.appModuleFeatureId == value.appModuleFeatureId && xy.appRoleId.toString() == x.field);
              if (rolePermission) {
                item[x.field] = rolePermission.appIsPermission;
              }

            });
            this.sales.push(item);

          });
          this.updateRowGroupMetaData();
        });
      }, (httpResponseError) => {

        this.showErrorMessage(httpResponseError.message);
      });

  }

  updateRowGroupMetaData() {
    this.rowGroupMetadata = {};

    if (this.sales) {
      for (let i = 0; i < this.sales.length; i++) {
        let rowData = this.sales[i];
        let representativeName = rowData.appModuleFeatureGroup;

        if (i == 0) {
          this.rowGroupMetadata[representativeName] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.sales[i - 1];
          let previousRowGroup = previousRowData.appModuleFeatureGroup;
          if (representativeName === previousRowGroup)
            this.rowGroupMetadata[representativeName].size++;
          else
            this.rowGroupMetadata[representativeName] = { index: i, size: 1 };
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  ngAfterViewInit() {

    const frozenBody: HTMLElement | null = document.querySelector('.ui-table-frozen-view .ui-table-scrollable-body');
    const scrollableArea: HTMLElement | null = document.querySelector('.ui-table-scrollable-view.ui-table-unfrozen-view .ui-table-scrollable-body');
    if (frozenBody && scrollableArea) {
      frozenBody.addEventListener('wheel', e => {
        const canScrollDown = scrollableArea.scrollTop < (scrollableArea.scrollHeight - scrollableArea.clientHeight);
        const canScrollUp = 0 < scrollableArea.scrollTop;

        if (!canScrollDown && !canScrollUp) {
          return;
        }

        const scrollingDown = e.deltaY > 0;
        const scrollingUp = e.deltaY < 0;
        const scrollDelta = 100;

        if (canScrollDown && scrollingDown) {
          e.preventDefault();
          scrollableArea.scrollTop += scrollDelta;
        } else if (canScrollUp && scrollingUp) {
          e.preventDefault();
          scrollableArea.scrollTop -= scrollDelta;
        }
      });
    }
  }


}
