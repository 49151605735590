import { Component, OnInit, OnDestroy} from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../servicies/settings.service';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { DateFormat } from 'src/app/shared/models/common/month-format';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { CommonFunctions } from 'src/app/shared/common-functions';
import { BookingRecipients, BookingSlotDisplay, BookingSlotFrequency } from 'src/app/shared/enums';
import { DateFormatModel } from 'src/app/shared/models/common/date-format-model';
import { HolidayService } from 'src/app/admin/servicies/holiday.service';


@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.sass']
})
export class ApplicationComponent extends BaseComponent implements OnInit, OnDestroy {


  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsResponseModel: Array<SettingsResponseModel>;
  settingsRequestModel: Array<SettingsRequestModel>;
  loggedInUserId: number;
  enumBookingRecipients = BookingRecipients;
  enumBookingSlotDisplay = BookingSlotDisplay;
  enumBookingSlotFrequency = BookingSlotFrequency;
  appDateFormat: DateFormatModel;
  bookingSlotDisplayOptions = [];
  bookingSlotFrequencyOptions = [];
  bookingRecipientsOptions = [];
  timeZone = [];
  dateFormat: DateFormat[];
  index: number = 0;
  isProvisional: boolean;
  isHidePatientDetails: boolean = true;
  formFields = {
    dateTimeFormat: 'dateTimeFormat',
    dayOfWeek: 'dayOfWeek',
    timeZone: 'timeZone'
  };

  validationMessages = {
    dateTimeFormat: {
      required: 'Date / Time format is required',
      //validCharacters: 'Enter valid contact name'
    },
    dayOfWeek: {
      required: 'Days of week is required',
      validNumber: 'Only numbers are allowed'
    },
    timeZone: {
      required: 'Time zone is required',
    }
  };
  title = 'Application Settings';

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private holidayService: HolidayService,
    messageService: MessageService,
  ) {
    super(messageService);
    //this.getMonths();
    const currentNavigationState = this.router.getCurrentNavigation().extras.state;
    if (currentNavigationState && currentNavigationState.selectedTab) {
      this.index = Number(currentNavigationState.selectedTab);
    }
    const provisionalSalesOrder = this.webStorageService.getClientConfig();
    this.isProvisional = provisionalSalesOrder.salesOrderMethod === 'Provisional' ? true : false;

  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {

    await this.getMonths();
    this.bookingSlotFrequencyOptions = CommonFunctions.enumSelector(this.enumBookingSlotFrequency); //Object.keys(this.enumTeamName);
    this.bookingSlotDisplayOptions = CommonFunctions.enumSelector(this.enumBookingSlotDisplay);
    this.bookingRecipientsOptions = CommonFunctions.enumSelector(this.enumBookingRecipients);

    this.timeZone = [
      { name: 'Eastern Time (UTC-5:00)', code: 'UTC -5', orgcode: 'UTC-5', shortName: 'EST' },
      { name: 'Central Time (UTC-6:00)', code: 'UTC -6', orgcode: 'UTC-6', shortName: 'CST' },
      { name: 'Mountain Time (UTC-7:00)', code: 'UTC -7', orgcode: 'UTC-7', shortName: 'MST' },
      { name: 'Arizona Mountain Time (UTC-7:00)', code: 'UMST -7', orgcode: 'UMST-7', shortName: 'AMST' },
      { name: 'Pacific Time (UTC-8:00)', code: 'UTC -8', orgcode: 'UTC-8', shortName: 'PST' }
    ];

    //this.bookingRecipientsOptions = _.orderBy(this.userRoleOptions, 'title', 'asc');
    //this.teamNameOptions = _.orderBy(this.teamNameOptions, 'title', 'asc');
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.buildForm();
    await this.getApplicationSettings(false);
    this.index = this.holidayService._getTabIndex();
  }

  async getMonths() {
    this.loading = true;
    this.progress.next(true);
    await this.settingsService.getDateFormat()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result: ResultOfT<DateFormat[]>) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            console.log("getMonths");
            this.dateFormat = result.responseModel;
            this.loading = false;
          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });

  }

  private async getApplicationSettings(iSRefresh: boolean) {
    this.loading = true;
    this.progress.next(true);

    let manageApplicationGeneral = this.settingsService.getAllSettings();
    if (iSRefresh)
      manageApplicationGeneral = this.settingsService.getAllSettingsReftresh();

    manageApplicationGeneral
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result: ResultOfT<any>) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.settingsResponseModel = result.responseModel;
            this.setValuesToForm();
            this.loading = false;

          }
        }
      })
      .catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
        this.loading = false;
      });
  }


  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    var applicationGeneralRequestModel = this.getValuesFromForm();
    const manageApplicationGeneral = this.settingsService.updateSettings(applicationGeneralRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading=false;
        if (result && result.isSuccess) {
          this.getApplicationSettings(true);
          this.StoreDateFormat(applicationGeneralRequestModel);
          this.showSuccessMessage('Application general settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading=false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private StoreDateFormat(applicationGeneralRequestModel: SettingsRequestModel[]) {
    this.appDateFormat = new DateFormatModel();
    this.appDateFormat.dateFormat = applicationGeneralRequestModel[0].schSettingValue;
    this.appDateFormat.timeZone = applicationGeneralRequestModel[2].schSettingValue;
    //this.appDateFormat.primeFormat = applicationGeneralRequestModel[0].dtNgFormat;
    this.webStorageService.deleteDateFormat();
    this.webStorageService.storeDateFormat(this.appDateFormat);
  }

  private getValuesFromForm(): Array<SettingsRequestModel> {

    const form = this.form;
    var lstApplicationGeneralRequestModel = new Array<SettingsResponseModel>();
    var dateTimeFormatRequestModel = new SettingsResponseModel();
    dateTimeFormatRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.dateTimeFormat);
    dateTimeFormatRequestModel.schSettingValue = form.get(this.formFields.dateTimeFormat).value.settingValue;
    dateTimeFormatRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    lstApplicationGeneralRequestModel.push(dateTimeFormatRequestModel);

    var daysOfWeekRequestModel = new SettingsResponseModel();
    daysOfWeekRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.daysOfWeek);
    daysOfWeekRequestModel.schSettingValue = form.get(this.formFields.dayOfWeek).value;
    daysOfWeekRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    lstApplicationGeneralRequestModel.push(daysOfWeekRequestModel);

    var timeZoneRequestModel = new SettingsResponseModel();
    timeZoneRequestModel = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.timeZone);
    timeZoneRequestModel.schSettingValue = form.get(this.formFields.timeZone).value.code;
    timeZoneRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    lstApplicationGeneralRequestModel.push(timeZoneRequestModel);

    return lstApplicationGeneralRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsResponseModel) {
      return;
    }
    var dateTimeFormat = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.dateTimeFormat);
    var daysOfWeek = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.daysOfWeek);
    var timeZone = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.timeZone);
    const form = this.form;
    if (this.dateFormat != [] && this.dateFormat != null && this.dateFormat != undefined) {
      form.get(this.formFields.dateTimeFormat).setValue(this.dateFormat.find(z => z.settingValue == dateTimeFormat.schSettingValue));
    }
    form.get(this.formFields.dayOfWeek).setValue(daysOfWeek.schSettingValue);
    form.get(this.formFields.timeZone).setValue(this.timeZone.find(a => a.code === timeZone.schSettingValue));
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.dateTimeFormat, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.dayOfWeek, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.onlyNumbers], updateOn: Constants.formControlUpdateStrategy.blur }));
    // tslint:disable-next-line: max-line-length
    formGroup.addControl(this.formFields.timeZone, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));

    this.form = formGroup;
  }
}
