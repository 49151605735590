export class ProvisionalPatientInfoResponseModel {
    brightreeID: number;
    externalID: string;
    firstName: string;
    lastName: string;
    ssn: string;
    patientID: string;
    placeOfService: string;
    hipaaSignatureOnFile: string;
    facilityName: string;
    facilityId: string;
    accountOnHold: string;
    deliveryPhone: string;
    deliveryNote: string;
    gender: string;
    marketingRep: string;
    practitioner: string;
    dob: Date;
    height: number;
    weight: number;
    crmSalesorderClassificationType: number;
    crmSalesorderClassification: string;
    crmClassificaitonduration: number;
    branchName: string;
    isCopyBillingAddress: boolean;
    patientNoteKey: number;
    patientNoteName: string;
    deliveryAddress: AddressResponseModel;
    billingAddress: AddressResponseModel;
    doctorInfo: DoctorInfoResponseModel;
    billingContactInfo: ContactInfoResponseModel;
    patientInsuranceInfo: PatientInsuranceInfoResponseModel[];
    orderNote: string;
    fullAddress: string;
}

export class AddressResponseModel {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    country: string;
    county: string;
    postalCode: string;
    state: string;
    crmDeliveryAddressTimeZone: string;
    crmBillingAddressTimeZone: string;
}

export class ContactInfoResponseModel {
    emailAddress: string;
    faxAttention: string;
    faxNumber: string;
    mobilePhoneNumber: string;
    phoneNumber: string;
}

export class DoctorInfoResponseModel {
    doctorId: number;
    firstName: string;
    lastName: string;
    phone: string;
    npi: string;
    uPIN: string;
    fax: string;
    doctorGroupId: number;
    doctorGroupName: string;
    address: AddressResponseModel;
    fullAddress: string;
}

export class PatientInsuranceInfoResponseModel {
    PayorLevel: string;
    PayPercent: string;
    Verified: boolean;
    CoverageVerified: boolean;
    PolicyNumber: string;
    Phone: string;
    PayorName: string;
    EffectiveStartDate: string;
    EffectiveEndDate: string;
    PolicyHolderName: string;
    PayPercentEqualToZero: boolean;
}

export class ProvisionalPatientInforResponseModel {
    brightreeID: number;
    crmAddress: string;
    crmPatientName: string;
    patientID: string;
    placeOfService: string;
    facilityName: string;
    facilityId: string;
    dob: Date;
    crmSalesorderClassificationType: number;
    crmSalesorderClassification: string;
    crmClassificaitonduration: number;
    branchName: string;
} 
