export class TwilioDeviceObject {
  device: any
  connection: any;
  isCallinProgress: boolean
  status: string
  callId: number;
  callSID: string;
  voiceCallToNumber: string;
  voiceMailNumber: string;
  appoinmentDialogueParametersObject: AppoinmentDialogueParametersObject
}

export class AppoinmentDialogueParametersObject {
  
  crmSalesOrderId: number;
  isRescheduledRequest: boolean;
  isScheduleOrder: boolean;
  isAppointment: boolean;
  schAppointmentId: number;
  isVoidOrder: boolean;
  isCancelOrder: boolean;
  isCall: boolean;
  isAppoinmentBookByCalendar: boolean;
  crmTherapsitId: number;
  salesOrderdate: Date;
  crmTherapsitName: string;
  isAppointmentAppoinmentPage: boolean;
  isAppointmentAppoinmentDetailPage: boolean;
  isAppointmentSalesOrderDetailPage: boolean;
  isAppointmentAppoinmentLockPage: boolean;
  patientName: string;
}

