import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FeatureGroupResponseModel, featureResponseModel, userModuleFeatureResponseModel } from '../models/response/feature-group-response';
import { Result, ResultOfT } from '../models/response/result';
import { SettingsResponseModel } from '../models/response/appointment/settings-response';
import { BaseService } from './base.service';
import { WebStorageService } from '../services/web-storage.service';
import { WipStatus, WipStatusBTmapping } from 'src/app/shared/models/common/wip-status';
import { SettingsPluginResponseModel, RCMCofigurationUrl } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
@Injectable({
  providedIn: 'root'
})
export class FeatureGroupService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getFeatureGroup() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<ResultOfT<FeatureGroupResponseModel[]>>('Roles/GetFeatureGroup/' + currentUserToken.roleId);
    }
  }
  getPermission() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<ResultOfT<featureResponseModel[]>>('Roles/GetPermission?roleId=' + currentUserToken.roleId);
    }
  }

  getUserPermission() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<ResultOfT<userModuleFeatureResponseModel[]>>('Roles/GetUserPermission');
    }
  }

  getSettings(settingsKey: string) {
    return this.get<ResultOfT<SettingsResponseModel>>('Settings/GetSettingByKey?SettingKey=' + settingsKey);
  }


  getWipStatusBtmapping() {
    return this.get<ResultOfT<WipStatusBTmapping[]>>('Appointment/GetWipStateBTmapping');
  }

  GetRCMConfigurationUrl(appPluginId: string) {
    return this.get<ResultOfT<RCMCofigurationUrl>>('RCMProvider/GetRCMConfigurationUrl/' + appPluginId);
  }

  logout() {
    const currentUserToken = this.webStorageService.getCurrentUserToken();
    const currentUserAuthToken = this.webStorageService.getCurrentUserAuthToken();
    if (currentUserToken && currentUserAuthToken.token) {
      return this.get<Result>('Users/Logout/' + currentUserToken.userId);
    }
  }

  applicationlogout() {
    return this.post<Result>('authentication/Logout/', null);
  }
}
