<div class="application-outer">
  <div class="form-outer">
    <form class="form" [formGroup]="form" autocomplete="off" (ngSubmit)="onSubmit()">

      <div class="col">

        <div class="form__group">
          <label class="form__label">Lead Time For Online Cancellations</label>
          <input type="text" formControlName="leadTime" class="form__control" pInputText>
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.leadTime, 'required') || hasError(formFields.leadTime, 'whitespace')">{{validationMessages.leadTime.required}}</span>
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.leadTime, 'positiveInt')">{{validationMessages.leadTime.positiveInt}}</span>
          <small>
            What is the minimum time (in hours) customers
            can cancel before their scheduled Booking is due to start?
          </small>
        </div>

        <div class="form__group">
          <!-- <label class="form__label">Months for which booking can be done</label> -->
          <label class="form__label">Days for which booking can be done</label>
          <input type="text" formControlName="numMonth" class="form__control" pInputText maxlength="3">
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.numMonth, 'required') || hasError(formFields.numMonth, 'whitespace')">{{validationMessages.numMonth.required}}</span>
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.numMonth, 'positiveInt')">{{validationMessages.numMonth.positiveInt}}</span>
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.numMonth, 'range')">{{validationMessages.numMonth.range}}</span>
          <!-- <p>
              How many months from today you want to schedule booking?
            </p> -->
          <small>
            How many days from today you want to schedule booking?
          </small>
        </div>

        <div class="form__group" *ngIf="false">
          <label class="form__label">Booking Slot Display</label>
          <p-dropdown optionValue="value" optionLabel="title" formControlName="bookingSlotDisplay" class="select-box"
            [options]="bookingSlotDisplayOptions" placeholder="Select" [showClear]="true">
          </p-dropdown>
          <!--<span class="invalid-feedback-error" *ngIf="hasError(formFields.bookingSlotDisplay, 'required') ">{{validationMessages.bookingSlotDisplay.required}}</span>-->
          <small>
            Booking Slots can appear either daily or weekly. <br>
            What would you prefer?
          </small>
        </div>

        <div class="form__group" *ngIf="false">
          <label class="form__label">Booking Slot Frequency</label>
          <p-dropdown optionValue="value" optionLabel="title" formControlName="bookingSlotFrequency" class="select-box"
            [options]="bookingSlotFrequencyOptions" placeholder="Select" [showClear]="true">
          </p-dropdown>
          <!--<span class="invalid-feedback-error" *ngIf="hasError(formFields.bookingSlotFrequency, 'required')">{{validationMessages.bookingSlotFrequency.required}}</span>-->
          <small>In what increments would you like to offer Bookings?</small>
        </div>

        <div class="form__group" *ngIf="false">
          <label class="form__label">Booking Confirmation: Recipients</label>
          <p-dropdown optionValue="value" optionLabel="title" formControlName="recipients" class="select-box"
            [options]="bookingRecipientsOptions" placeholder="Select" [showClear]="true">
          </p-dropdown>
          <!--<span class="invalid-feedback-error" *ngIf="hasError(formFields.recipients, 'required')">{{validationMessages.recipients.required}}</span>-->
          <small>In addition to the Customer &amp; Therapist, who else should receive a Booking Confirmation
            email?</small>
        </div>

        <div class="form__group">
          <!-- <label class="form__label">Booking Overdue</label> -->
          <label class="form__label">Unreconciled</label>
          <input formControlName="bookingOverdue" type="text" class="form__control" pInputText>
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.bookingOverdue, 'required') || hasError(formFields.bookingOverdue, 'whitespace')">{{validationMessages.bookingOverdue.required}}</span>
          <!--<span class="invalid-feedback-error" *ngIf="hasError(formFields.bookingOverdue, 'validNumber')">{{validationMessages.bookingOverdue.validNumber}}</span>-->
          <span class="invalid-feedback-error"
            *ngIf="hasError(formFields.bookingOverdue, 'positiveInt')">{{validationMessages.bookingOverdue.positiveInt}}</span>
          <!-- <p>How many days would consider the overdue of Bookings?</p> -->
          <small>How many days until a booking is considered Unreconciled?</small>
        </div>

        <div class="form__group">
          <label class="form__label">Display "Delivery Note" on Self-Scheduling <span pTooltip="Delivery Note display on the appointment confirmation page and on the page where it shows existing appointment booked (same link of the self-scheduling)"><i class="info-new" style="margin-bottom: -4px;"></i></span></label>
          <div class="radios">
            <p-radioButton name="deliveryNote" formControlName="deliveryNote" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton name="deliveryNote" formControlName="deliveryNote" value="False" label="No">
            </p-radioButton>
          </div>
        </div>

        <div class="form__group" *ngIf="false">
          <label class="form__label">Appointment Reminder</label>
          <input type="text" formControlName="aptReminder" class="form__control" pInputText>
          <!--<span class="invalid-feedback-error" *ngIf="hasError(formFields.aptReminder, 'required') || hasError(formFields.aptReminder, 'whitespace')">{{validationMessages.aptReminder.required}}</span>
            <span class="invalid-feedback-error" *ngIf="hasError(formFields.aptReminder, 'positiveInt')">{{validationMessages.aptReminder.positiveInt}}</span>-->
          <small>
            How many days before the appointment date patient should get reminder?
          </small>
        </div>



      </div>

      <div class="col">
        <div class="form__group" *ngIf="false">
          <label class="form__label">Enable Online Booking for Facility Visit</label>
          <div class="radios">
            <p-radioButton name="facilityVisit" formControlName="facilityVisit" value="True" label="On">
              <!--  onClick="RadiofacilityVisitClick()" -->
            </p-radioButton>
            <p-radioButton name="facilityVisit" formControlName="facilityVisit" value="False" label="Off">
            </p-radioButton>
          </div>
        </div>

        <div class="form__group" *ngIf="false">
          <label class="form__label">Enable Patients to select Remote Appointments on their interface </label>
          <div class="radios">
            <p-radioButton name="remoteApt" formControlName="remoteApt" value="True" label="On">
            </p-radioButton>
            <p-radioButton name="remoteApt" formControlName="remoteApt" value="False" label="Off">
            </p-radioButton>
          </div>
        </div>

        <div class="form__group">
          <label class="form__label">Display Due Amount On Landing Page Of Patient </label>
          <div class="radios">
            <p-radioButton name="dueAmount" formControlName="dueAmount" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton name="dueAmount" formControlName="dueAmount" value="False" label="No">
            </p-radioButton>
          </div>
        </div>

        <!-- <div class="form__group">
            <label class="form__label">Default Facilities View </label>
            <div class="radios">
              <p-radioButton name="defaultView" formControlName="defaultView" value="True" label="Map View">
              </p-radioButton>
              <p-radioButton name="defaultView" formControlName="defaultView" value="False" label="List View">
              </p-radioButton>
            </div>
          </div> -->

        <div class="form__group enable-remote">
          <label class="form__label">Set Display Name For The Online Appointment</label>
          <div class="clearfix" style="max-width: 100%; width: 100%;"></div>
          <div class="full-width" *ngIf="FacilityVisitSelected">
            <div class="input-edit-table" [ngClass]="facilityVisitEditStatus ? 'edit-box' : ''">
              <!-- <p-checkbox name="group8" binary="true" label="" [(ngModel)]="FacilityVisitSelected"
                formControlName="faclityVisitSelected"></p-checkbox> -->
              <input type="text" class="form__control" formControlName="faclityVisitTitle" pInputText maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditFacilityVisitTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>
                <a href="javascript:void(0);" (click)="saveFacilityVisitTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelFacilityVisitTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.faclityVisitTitle, 'required') || hasError(formFields.faclityVisitTitle, 'whitespace')">{{validationMessages.faclityVisitTitle.required}}</span>
            </div>
          </div>

          <div class="full-width" *ngIf="RemoteSetupSelected">
            <div class="input-edit-table" [ngClass]="remoteSetupEditStatus ? 'edit-box' : ''">
              <!-- <p-checkbox name="group8" binary="true" label="" [(ngModel)]="RemoteSetupSelected"
                formControlName="remoteSetupSelected"></p-checkbox> -->
              <input type="text" class="form__control" formControlName="remoteSetupTitle" pInputText maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditRemoteSetupTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>

                <a href="javascript:void(0);" (click)="saveRemoteSetupTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelRemoteSetupTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.remoteSetupTitle, 'required') || hasError(formFields.remoteSetupTitle, 'whitespace')">{{validationMessages.remoteSetupTitle.required}}</span>
            </div>
          </div>
          <div class="full-width" *ngIf="TherapistVisitSelected">
            <div class="input-edit-table" [ngClass]="therapistVisitEditStatus ? 'edit-box' : ''">
              <!-- <p-checkbox name="group8" binary="true" label="" [(ngModel)]="TherapistVisitSelected"
                formControlName="therapistVisitSelected"></p-checkbox> -->
              <input type="text" class="form__control" formControlName="therapistVisitTitle" pInputText
                maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditTherapistVisitTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>

                <a href="javascript:void(0);" (click)="saveTherapistVisitTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelTherapistVisitTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.therapistVisitTitle, 'required') || hasError(formFields.therapistVisitTitle, 'whitespace')">{{validationMessages.therapistVisitTitle.required}}</span>
            </div>
          </div>
          <div class="full-width" *ngIf="SelfGuidedSelected">
            <div class="input-edit-table" [ngClass]="SelfGuidedEditStatus ? 'edit-box' : ''">
              <!-- <p-checkbox name="group8" binary="true" label="" [(ngModel)]="SelfGuidedSelected"
                formControlName="selfGuidedSelected"></p-checkbox> -->
              <input type="text" class="form__control" formControlName="selfGuidedTitle" pInputText maxlength="100" />

              <div class="action-icon">
                <a href="javascript:void(0);" (click)="EditSelfGuidedTitle()" class="edit-icon"><i
                    class="edit-icon-new"></i></a>

                <a href="javascript:void(0);" (click)="saveSelfGuidedTitle()" class="save-icon"><i
                    class="ic check-ic-small"></i></a>
                <a href="javascript:void(0);" (click)="cancelSelfGuidedTitle()" class="cancel-icon"><i
                    class="ic cancel-ic-small"></i></a>
              </div>
              <span class="invalid-feedback"
                *ngIf="hasError(formFields.selfGuidedTitle, 'required') || hasError(formFields.selfGuidedTitle, 'whitespace')">{{validationMessages.selfGuidedTitle.required}}</span>
            </div>
          </div>
        </div>

        <!-- Start new changes -->
        <!-- <div class="form__group">
          <label class="form__label">Appointment Slots Configuration</label>
        </div>
 -->
        <h3 class="title full-width">Appointment Slots Configuration</h3>

        <div class="form__group">
          <label  class="form__label">Default duration of appointment slots</label>
          <p-dropdown [disabled]="slotPermission" class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="defaultSlotDuration"
                      [(ngModel)]="slotDurationWithClassification" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>
        </div>

        <div class="form__group"></div>

        <div class="form__group">
          <label class="form__label">Appointment slots <br>(Based on the classification)</label>
          <div class="radios"> 
            <p-radioButton name="selectAppointmentSlot" formControlName="selectAppointmentSlot" (onClick)="setDurationBasedClassification()" value="True" label="Yes">
            </p-radioButton>
            <p-radioButton name="selectAppointmentSlot" formControlName="selectAppointmentSlot"  (onClick)="reSetDurationBasedClassification()" value="False" label="No">
            </p-radioButton>
            <small *ngIf="slotPermission">The slots will be displayed based on<br>the classification available in the sales orders.</small>           
          </div>
        </div>


        <div  *ngIf="slotPermission" class="form__group">
          <label class="form__label">Default duration of appointment slots<br>(If the classification is not mapped)</label>
          <p-dropdown class="select-box" placeholder="Select Duration" optionLabel="title" formControlName="slotDurationWithoutClassification"
                      [(ngModel)]="durationWithoutClassification" [options]="classificationDurationOptions" [showClear]="true">
          </p-dropdown>         
        </div>
        <!-- End new changes -->

      </div>
      <!-- <div class="col"> -->
      <!-- </div> -->

      <div *ngIf="false" class="branchNumbers">
        <h3 class="form-outer__title">Contact Information</h3>
        <table class="custom-table online-booking">
          <thead>
            <tr>
              <th>Branch</th>
              <th>Phone Number</th>
              <th>Email</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td>
                <p>Contact number for patient's to contact customer service operator.</p>
              </td>
              <td>
                <p>Contact email address for patient's to contact customer service operator.</p>
              </td>
            </tr>

            <tr formArrayName="branchNumbers"
              *ngFor="let branchNumbersControl of formBranchNumbers.controls; let i = index">
              <td>
                <h3 *ngIf="branchNumbersRespinseModel && branchNumbersRespinseModel.length>0">
                  {{branchNumbersRespinseModel[i].branch.crmBranchName}}</h3>
              </td>
              <td [formGroupName]="i">
                <div class="form__group">
                  <!-- <label class="form__label">DME Phone Number</label> -->
                  <input formControlName="dmePhoneNumber" type="text" class="form__control" pInputText maxlength="15">

                  <span *ngIf="branchNumbersControl.get('dmePhoneNumber').errors?.required"
                    class="invalid-feedback-error">
                    {{validationMessages.dmephone.required}}
                  </span>
                  <span *ngIf="branchNumbersControl.get('dmePhoneNumber').errors?.validPhoneNumber"
                    class="invalid-feedback-error">
                    {{validationMessages.dmephone.validNumber}}
                  </span>
                </div>
              </td>

              <td [formGroupName]="i">
                <div class="form__group">
                  <!-- <label class="form__label">DME Email</label> -->
                  <input formControlName="dmeEmailId" type="text" class="form__control" pInputText>
                  <span *ngIf="branchNumbersControl.get('dmeEmailId').errors?.required" class="invalid-feedback-error">
                    {{validationMessages.dmeemail.required}}
                  </span>
                  <span *ngIf="branchNumbersControl.get('dmeEmailId').errors?.email" class="invalid-feedback-error">
                    {{validationMessages.dmeemail.validemail}}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="btns">
        <button class="btn btn--primary mb-0"
          routerLink="/admin/settings/application">Save</button>
        <button class="btn btn--secondary mb-0" routerLink="/admin/settings">Cancel</button>
      </div>


    </form>
  </div>
</div>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
