import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { SettingsService } from 'src/app/patient/services/settings.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/settings-response';
import { Constants } from 'src/app/shared/constants';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { SalesOrderLocalModel } from 'src/app/shared/models/response/sales-order-current';
import { Router } from '@angular/router';
import { BranchNumbersResponseModel } from 'src/app/shared/models/response/branch-numbers-response-model';
@Component({
  selector: 'app-patient-footer',
  templateUrl: './patient-footer.component.html',
  styleUrls: ['./patient-footer.component.sass']
})
export class PatientFooterComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  DMENumber: string;
  DMEeMail: string;
  salesorderids: SalesOrderLocalModel;
  salesOrderId: string;
  constructor(private settingsService: SettingsService,
    messageService: MessageService,
    private router: Router,
    private appointmentService: AppointmentService) {
    super(messageService);
  }

  async ngOnInit() {
    //await this.getDMEeMail();
    // this.salesorderids = new SalesOrderLocalModel();
    // this.salesorderids = this.webStorageService.getSalesOrderId();
    // if (this.salesorderids)
    //   this.salesOrderId = this.salesorderids.salesOrderId;
    //await this.getDMENumber();
  }
  private async getDMENumber() {
    await this.settingsService.getAllBranchNumbers(this.salesorderids.branchid)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<BranchNumbersResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel) {
              this.DMENumber = result.responseModel.dmephoneNumber;
              this.DMEeMail = result.responseModel.dmeemail;
            }
          }
        });
      });
  }

  private async getDMEeMail() {
    await this.settingsService.getSettings(Constants.SettingKeys.DMEEmail)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .toPromise()
      .then((result) => {
        this.processResult<SettingsResponseModel>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel)
              this.DMEeMail = result.responseModel.schSettingValue;
          }
        });
      });
  }

  RequestForCallBack() {

    this.loading = true;
    this.appointmentService.AddTelehealthSalesOrder(this.salesOrderId, 'C')
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          this.showSuccessMessage('Your request to call back for Telehealth has been sent.');
          this.router.navigate(['patient/home/' + this.salesorderids.strURL]);
        });

      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
    //});
  }

  ngOnDestroy() {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
  }

}
