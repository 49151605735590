import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TherapistRoutingModule } from './therapist-routing.module';
import { MyAppointmentsComponent } from './my-appointments/my-appointments.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from '../shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AccordionModule } from 'primeng/accordion';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { DemoModule } from './my-appointments/day-view-scheduler/module';
import { WeekDemoModule } from './my-appointments/week-view-scheduler/module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MyProfileComponent } from 'src/app/therapist/my-profile/my-profile.component'
import { TherapistFooterComponent } from 'src/app/shared/footer/therapist-footer/therapist-footer.component';
import { MyChangePasswordComponent } from './my-change-password/my-change-password.component';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MyAvailabilityNewComponent } from './my-availability-new/my-availability-new.component';
import { PipesModule } from '../pipes/pipes/pipes.module';
import { MyAppointmentsNewComponent } from './my-appointments-new/my-appointments-new.component';
import {SidebarModule} from 'primeng/sidebar';
import { GoogleMapsModule } from '@angular/google-maps';
import { PatientDetailSidebarComponent } from './patient-detail-sidebar/patient-detail-sidebar.component';
import { WeekViewCalendarComponent } from './week-view-calendar/week-view-calendar.component';
import { WeekViewMobileComponent } from './week-view-mobile/week-view-mobile.component'
import { SchedulerModule } from 'angular-calendar-scheduler';
import { DialogModule } from 'primeng/dialog';

export const MyDefaultTooltipOptions: TooltipOptions = {
  'placement': 'right'
}
@NgModule({
  declarations: [MyAppointmentsComponent, MyProfileComponent, MyChangePasswordComponent, MyAvailabilityNewComponent,
    SetPasswordComponent,
    MyAppointmentsNewComponent,
    PatientDetailSidebarComponent,
    WeekViewCalendarComponent,
    WeekViewMobileComponent],
  imports: [
    CommonModule,
    TherapistRoutingModule,
    FormsModule,
    DropdownModule,
    SharedModule,
    NgbModalModule,
    ProgressSpinnerModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AccordionModule,
    TabViewModule,
    MenuModule,
    TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
    DemoModule,
    WeekDemoModule,
    PipesModule,
    SidebarModule,
    GoogleMapsModule,
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange'}),
    DialogModule
  ],

  exports: [
    DialogModule
  ]
})
export class TherapistModule { }
