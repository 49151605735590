import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppointmentEmailHistoryResponseModel } from 'src/app/shared/models/response/appointment/appointment-emalhistory-response-model';
import { DateFormatModel } from '../models/common/date-format-model';
import { WebStorageService } from '../services/web-storage.service';
import { AppointmentService } from 'src/app/admin/servicies/appointment.service';
import { MessageService } from 'primeng/api';
import { BaseComponent } from '../components/base.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { ResultOfT } from '../models/response/result';


@Component({
  selector: 'app-email-logs',
  templateUrl: './email-logs.component.html',
  styleUrls: ['./email-logs.component.sass']
})
export class EmailLogsComponent extends BaseComponent implements OnInit {
  private unsubscriber = new Subject<boolean>();
  expandedIndex: number = -1;
  loadingEmail: boolean = false;
  indexActionTab: number = 0;
  @Input() appointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel[];
  @Input() schIsReminderOptedEmail: boolean;
  @Input() isAppointment: boolean;
  @Input() schAppointmentId: number;
  @Output() onPreferenceChange = new EventEmitter<string>();
  appDateFormat: DateFormatModel;
  IsEmailForwardDisplay: boolean;
  EmailTo: string;
  EmailCc: string;
  emailBodyText: SafeResourceUrl;
  @Input() emailLogCount: number;

  constructor(private webStorageService: WebStorageService,
    private appointmentService: AppointmentService,
    messageService: MessageService,
    private dialougeService: DialogService,
    private sanitizer: DomSanitizer) {
    super(messageService);
    this.appDateFormat = this.webStorageService.getDateFormat();
  }

  async ngOnInit() {
    this.expandedIndex = -1;
  }

  Collapse(index: number) {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  ResendEmail(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel, event, element) {
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        message: 'Are you sure you want to resend this email?',
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.loadingEmail = true;
      this.sendEmail(selectedAppointmentEmailHistoryResponseModel, event, element);
    });
  }

  ForwardEmail(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel, event, element) {
    if (this.EmailTo == undefined || this.EmailTo.trim() == '') {
      this.showErrorMessage('Please add proper email address.');
      return;
    }

    this.loadingEmail = true;
    let AppointmentEmailHistoryResponseModel = { ...selectedAppointmentEmailHistoryResponseModel };
    AppointmentEmailHistoryResponseModel.appNotificationEmailToEmail = this.EmailTo;
    this.sendEmail(AppointmentEmailHistoryResponseModel, event, element);
  }

  handleSmsOptInOut(e) {
    let isChecked = e.checked;
    // let messasge = isChecked ? 'Subscribed to Email Communication' : 'Unsubscribed from Email Communication'
    let messagetoDisplay = isChecked ? 'Are you sure you want to subscribe to email communication?' : 'Are you sure you want to unsubscribe from email communication?'
    this.dialougeService.open(ConfirmDialogComponent, {
      data:
      {
        // message: `Are you sure you want to ${messasge} email communication preference?`,
        message: `${messagetoDisplay}`,
      },
      header: 'Confirmation'
    }).onClose.subscribe((response: boolean) => {
      if (!response) {
        this.schIsReminderOptedEmail = !e.checked;
        return;
      }

      this.appointmentService.ChangePatientCommunicationPreferences(this.schAppointmentId, "Email", isChecked, true)
        .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
        .subscribe((result) => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.showSuccessMessage('Email Communication Preference has been changed.');
              this.schIsReminderOptedEmail = isChecked;
              this.onPreferenceChange.emit(isChecked);
            }
          }
          else {

            if (result.message != undefined && result.message.length > 0) {
              this.showErrorMessage(result.message);
            }
          }
        });
    });
  }

  CancelForwardEmail() {
    this.EmailTo = '';
    this.EmailCc = '';
    this.IsEmailForwardDisplay = false;
  }

  isDisabledSendNotification(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel): boolean {
    if (selectedAppointmentEmailHistoryResponseModel.appNotificationRoleType != "Patient")
      return true;
    else if (this.schIsReminderOptedEmail == false && selectedAppointmentEmailHistoryResponseModel?.appNotificationRoleType === "Patient")
      return false;
    else
      return true;

  }


  ReSendNotificationTitle(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel): string {

    if (selectedAppointmentEmailHistoryResponseModel.appNotificationRoleType != "Patient")
      return 'Resend Email';
    else if (this.schIsReminderOptedEmail == false && selectedAppointmentEmailHistoryResponseModel?.appNotificationRoleType === "Patient")
      return 'Opt-Out Email';
    else
      return 'Resend Email';

  }


  ForwardNotificationTitle(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel): string {

    if (selectedAppointmentEmailHistoryResponseModel.appNotificationRoleType != "Patient")
      return 'Forward Email';
    else if (this.schIsReminderOptedEmail == false && selectedAppointmentEmailHistoryResponseModel?.appNotificationRoleType === "Patient")
      return 'Opt-Out Email';
    else
      return 'Forward Email';

  }

  sendEmail(selectedAppointmentEmailHistoryResponseModel: AppointmentEmailHistoryResponseModel, event, element) {
    const TEXT_REGEXP = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

    if (selectedAppointmentEmailHistoryResponseModel.appNotificationEmailToEmail) {
      const emailArray = selectedAppointmentEmailHistoryResponseModel.appNotificationEmailToEmail.split(';');
      for (let i = 0; i < emailArray.length; i++) {
        if (!TEXT_REGEXP.test(emailArray[i].trim())) {
          this.showErrorMessage('Please add proper email address.');
          this.loadingEmail = false;
          return;
        }
      }
    }

    if (this.isAppointment && !this.schIsReminderOptedEmail && selectedAppointmentEmailHistoryResponseModel.appNotificationEmailStatus == 'Pending' && selectedAppointmentEmailHistoryResponseModel.appNotificationEmailStatusReason == "OptOut") {
      this.showInfoMessage('Please Opt-In Email Communication Preference.');
      this.loadingEmail = false;
      return;
    }

    this.appointmentService.resendEmail(selectedAppointmentEmailHistoryResponseModel)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result) => {
        if (result.isSuccess) {
          if (result.responseModel != undefined && result.responseModel != null) {
            this.loadingEmail = false;
            this.EmailTo = '';
            this.EmailCc = '';
            this.IsEmailForwardDisplay = false;
            this.showSuccessMessage('Email has been sent.');
            element.hide(event);
            this.GetNotificationEmailLogsBySalesOrderId(selectedAppointmentEmailHistoryResponseModel.crmRefSalesOrderId);
          }
        }
        else {
          this.loadingEmail = false;
          if (result.message != undefined && result.message.length > 0) {
            this.showErrorMessage(result.message);
          }
        }
      });
  }

  formatString(formatStr) {
    return formatStr.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  private GetNotificationEmailLogsBySalesOrderId(SalesOrderId) {
    this.loadingEmail = true;

    this.appointmentService.GetNotificationEmailLogsBySalesOrderId(SalesOrderId, true)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loadingEmail = false))
      .toPromise()
      .then((result) => {
        this.processResult<AppointmentEmailHistoryResponseModel[]>(result, () => {
          if (result.isSuccess) {
            if (result.responseModel != undefined && result.responseModel != null) {
              this.appointmentEmailHistoryResponseModel = result.responseModel;
              if (this.appointmentEmailHistoryResponseModel.length > 0) {
              }
              else {
                this.showInfoMessage("No record found");
              }
            }
          }
          this.loadingEmail = false;

        });
      }).catch((httpResponseError) => {
        this.loading = false;

        this.showErrorMessage(httpResponseError.message);
      });
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  onKeyDown(event: KeyboardEvent): void {
    // Define an array of keys to be restricted
    const restrictedKeys = [' ', ';', ',']; // Space, semicolon, comma

    // Check if the pressed key is in the restricted list
    if (restrictedKeys.includes(event.key)) {
      // Prevent the default action (i.e., prevent the character from being entered)
      event.preventDefault();
    }
  }
}
