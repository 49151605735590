<section class="main-outer__content-outer">

  <div class="content-area">
    <div class="title-block">
      <div class="title-block__left">
        <h1>Calendar</h1>
      </div>
      <div class="calendar-top-right dme-weekview-therapist" style="z-index:999 !important">

        <a href="javascript:void(0);" class="refresh-block" (click)="RefreshPage()">
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.6261 11.1144L16.9 10.0924C17.8072 6.7065 15.7911 3.22351 12.3973 2.31413C11.4743 2.06561 10.5072 2.02847 9.56794 2.20547C8.62865 2.38248 7.74139 2.76906 6.9722 3.33645M3.41128 7.2805L3.13744 8.30249C2.22917 11.6922 4.24448 15.1728 7.63967 16.0825C8.56064 16.3278 9.52474 16.3646 10.4617 16.1902C11.3987 16.0157 12.285 15.6344 13.0559 15.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M1.73135 7.57401L3.37574 6.54666L4.90308 8.074" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
            <path d="M18.3474 11.873L16.2751 12.4819L15.0451 10.3967" stroke="#8B8B8B" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="square"/>
          </svg> 
          <span class="loading">Refresh</span>
        </a> 

        <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
          [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()"
          placeholder="Select Calendar View" optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>
        <!-- <label class="form__label">Therapist</label> -->


        <!-- <a href="javascript:void(0);" class="filter-block filter-show-hide" (click)="clanderFilter.toggle($event)">
          <img src="assets/img/filter-ic.svg" width="18" alt="filters">
        </a> -->
      </div>
    </div>
    <p-overlayPanel appendTo="body" class="table-action-popup calendar-action-menu facilities-action"
      styleClass="facilities-action" #BlockSlot [dismissable]="true">
      <ul class="quick-link-menu calendar-quick-menu">
        <li *ngIf="!isRemoteAndFacility">
          <a href="javascript:void(0)" (click)="getPatients(facilityId,false,0)">
            Fill Slot
          </a>
        </li>
        <li *ngIf="isRemoteAndFacility">
          <a href="javascript:void(0)" (click)="getPatients(facilityId,true,1)">
            Fill Slot For Facility Visit
          </a>
        </li>
        <li *ngIf="isRemoteAndFacility">
          <a href="javascript:void(0)" (click)="getPatients(remoteFacilityId,false,2)">
            Fill Slot For Remote Setup
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="setBlock()">
            Block Slot
          </a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="setBreak()">
            Break Hours
          </a>
        </li>
      </ul>
    </p-overlayPanel>
    <p-overlayPanel appendTo="body" class="table-action-popup calendar-action-menu facilities-action"
      styleClass="facilities-action" #ConextMenu [dismissable]="true">
      <ul class="quick-link-menu calendar-quick-menu">
        <li>
          <a href="javascript:void(0)" (click)="showRescheduleDialogPopup()">Reschedule</a>
        </li>
        <li
          *ngIf="selectedAppointment?.crmAppointmentType=='Telehealth' && isChangeRemoteAppointmentType && ((selectedAppointment?.schRemoteAppointmentType| lowercase) =='telemed' || (selectedAppointment?.schRemoteAppointmentType| lowercase) =='onlinemeeting')">
          <a (click)="convertAppointmentType()">
            Change To {{convertedRemoteAppointmentType}}
          </a>
        </li>

      </ul>
    </p-overlayPanel>

    <div class="calendra-top-btn">
      <div class="btn-group calendra-next-prev-btn">
        <div class="mwl-calendar">
          <div class="btn btn--arrow prev" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="dateOrViewChanged()" (click)="dateChange()">
            <i class="arrow-prev"></i>
          </div>

          <div class="btn btn--primary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="dateOrViewChanged()"
            (click)="dateChange()">
            Today
          </div>

          <div class="btn btn--arrow next" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="dateOrViewChanged()" (click)="dateChange()">
            <i class="arrow-next"></i>
          </div>

          <h4>
            <div class="therapist-title-block__week-date">{{ weekStartDate}} - {{weekEndDate}}, {{currentYear}}
            </div>

          </h4>
        </div>
      </div>

      <div class="calendra-top-btn__right-block">
        <a href="javascript:void(0)" class="h-right-action" (click)="filterCLick.toggle($event)">
          Export
          <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M4.29289 8.29289C4.68342 7.90237 5.31658 7.90237 5.70711 8.29289L12 14.5858L18.2929 8.29289C18.6834 7.90237 19.3166 7.90237 19.7071 8.29289C20.0976 8.68342 20.0976 9.31658 19.7071 9.70711L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L4.29289 9.70711C3.90237 9.31658 3.90237 8.68342 4.29289 8.29289Z"
              fill="#000000" />
          </svg>
        </a>

        <p-overlayPanel #filterCLick [appendTo]="'body'">
          <div class="quick-link-menu book-appt-quick-link">
            <ul>
              <li class="show-sm"><a class="invite-schedule" (click)="exportPDF()">
                  <svg style="margin-right: .8rem" fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 482.14 482.14" xml:space="preserve">
                    <g>
                      <path d="M142.024,310.194c0-8.007-5.556-12.782-15.359-12.782c-4.003,0-6.714,0.395-8.132,0.773v25.69
                        c1.679,0.378,3.743,0.504,6.588,0.504C135.57,324.379,142.024,319.1,142.024,310.194z" />
                      <path d="M202.709,297.681c-4.39,0-7.227,0.379-8.905,0.772v56.896c1.679,0.394,4.39,0.394,6.841,0.394
                        c17.809,0.126,29.424-9.677,29.424-30.449C230.195,307.231,219.611,297.681,202.709,297.681z" />
                      <path d="M315.458,0H121.811c-28.29,0-51.315,23.041-51.315,51.315v189.754h-5.012c-11.418,0-20.678,9.251-20.678,20.679v125.404
                        c0,11.427,9.259,20.677,20.678,20.677h5.012v22.995c0,28.305,23.025,51.315,51.315,51.315h264.223
                        c28.272,0,51.3-23.011,51.3-51.315V121.449L315.458,0z M99.053,284.379c6.06-1.024,14.578-1.796,26.579-1.796
                        c12.128,0,20.772,2.315,26.58,6.965c5.548,4.382,9.292,11.615,9.292,20.127c0,8.51-2.837,15.745-7.999,20.646
                        c-6.714,6.32-16.643,9.157-28.258,9.157c-2.585,0-4.902-0.128-6.714-0.379v31.096H99.053V284.379z M386.034,450.713H121.811
                        c-10.954,0-19.874-8.92-19.874-19.889v-22.995h246.31c11.42,0,20.679-9.25,20.679-20.677V261.748
                        c0-11.428-9.259-20.679-20.679-20.679h-246.31V51.315c0-10.938,8.921-19.858,19.874-19.858l181.89-0.19v67.233
                        c0,19.638,15.934,35.587,35.587,35.587l65.862-0.189l0.741,296.925C405.891,441.793,396.987,450.713,386.034,450.713z
                          M174.065,369.801v-85.422c7.225-1.15,16.642-1.796,26.58-1.796c16.516,0,27.226,2.963,35.618,9.282
                        c9.031,6.714,14.704,17.416,14.704,32.781c0,16.643-6.06,28.133-14.453,35.224c-9.157,7.612-23.096,11.222-40.125,11.222
                        C186.191,371.092,178.966,370.446,174.065,369.801z M314.892,319.226v15.996h-31.23v34.973h-19.74v-86.966h53.16v16.122h-33.42
                        v19.875H314.892z" />
                    </g>
                  </svg>
                  PDF</a></li>
              <li class="show-sm"><a class="invite-schedule" (click)="exportCSV()">
                  <svg style="margin-right: .8rem" fill="#000000" version="1.1" id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25px"
                    height="25px" viewBox="0 0 548.29 548.291" xml:space="preserve">
                    <g>
                      <path d="M486.2,196.121h-13.164V132.59c0-0.399-0.064-0.795-0.116-1.2c-0.021-2.52-0.824-5-2.551-6.96L364.656,3.677
                        c-0.031-0.034-0.064-0.044-0.085-0.075c-0.629-0.707-1.364-1.292-2.141-1.796c-0.231-0.157-0.462-0.286-0.704-0.419
                        c-0.672-0.365-1.386-0.672-2.121-0.893c-0.199-0.052-0.377-0.134-0.576-0.188C358.229,0.118,357.4,0,356.562,0H96.757
                        C84.893,0,75.256,9.649,75.256,21.502v174.613H62.093c-16.972,0-30.733,13.756-30.733,30.73v159.81
                        c0,16.966,13.761,30.736,30.733,30.736h13.163V526.79c0,11.854,9.637,21.501,21.501,21.501h354.777
                        c11.853,0,21.502-9.647,21.502-21.501V417.392H486.2c16.966,0,30.729-13.764,30.729-30.731v-159.81
                        C516.93,209.872,503.166,196.121,486.2,196.121z M96.757,21.502h249.053v110.006c0,5.94,4.818,10.751,10.751,10.751h94.973v53.861
                        H96.757V21.502z M258.618,313.18c-26.68-9.291-44.063-24.053-44.063-47.389c0-27.404,22.861-48.368,60.733-48.368
                        c18.107,0,31.447,3.811,40.968,8.107l-8.09,29.3c-6.43-3.107-17.862-7.632-33.59-7.632c-15.717,0-23.339,7.149-23.339,15.485
                        c0,10.247,9.047,14.769,29.78,22.632c28.341,10.479,41.681,25.239,41.681,47.874c0,26.909-20.721,49.786-64.792,49.786
                        c-18.338,0-36.449-4.776-45.497-9.77l7.38-30.016c9.772,5.014,24.775,10.006,40.264,10.006c16.671,0,25.488-6.908,25.488-17.396
                        C285.536,325.789,277.909,320.078,258.618,313.18z M69.474,302.692c0-54.781,39.074-85.269,87.654-85.269
                        c18.822,0,33.113,3.811,39.549,7.149l-7.392,28.816c-7.38-3.084-17.632-5.939-30.491-5.939c-28.822,0-51.206,17.375-51.206,53.099
                        c0,32.158,19.051,52.4,51.456,52.4c10.947,0,23.097-2.378,30.241-5.238l5.483,28.346c-6.672,3.34-21.674,6.919-41.208,6.919
                        C98.06,382.976,69.474,348.424,69.474,302.692z M451.534,520.962H96.757v-103.57h354.777V520.962z M427.518,380.583h-42.399
                        l-51.45-160.536h39.787l19.526,67.894c5.479,19.046,10.479,37.386,14.299,57.397h0.709c4.048-19.298,9.045-38.352,14.526-56.693
                        l20.487-68.598h38.599L427.518,380.583z" />
                    </g>
                  </svg>
                  CSV
                </a></li>
            </ul>
          </div>
        </p-overlayPanel>

        <div class="calendar-right-sidebar">
          <p-dropdown class="select-box select-branch filter-status" [(ngModel)]="selectedTherapists"
            [options]="therapistResponseModel" defaultLabel="Select Therapist" (onChange)="onTherapistChange($event)"
            optionLabel="appUserFullname" selectedItemsLabel="{0} therapist(s) Selected" [filter]="true"></p-dropdown>
          <!-- [filter]="true"  -->
        </div>
      </div>
    </div>

    <ng-template #loading>
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>

    <div class="calendar-outer">
      <div class="admin-availability-calandar dme-therapist-week-view-calendar">
        <div class="admin-availabilty-calc">
          <div class="admin-availabilty-calc__left">
            <div class="therapist-availabilty-calc__left__block view-by-facility">
              <!-- <p-scrollPanel styleClass="therapist-module-calendar-scroll" [style]="{width: '100%', height: '100%'}"> -->
              <div class="horizontal-scroll admin-appointments-week dme-therapist-calendar-scroll" [ngSwitch]="view">
                <ng-template #customEventTemplate let-event="event">
                  <div class="custom-event" mwlCalendarTooltip="" [tooltipPlacement]="'top'">

                    <div class="calendar-box done"
                      *ngIf="event.event.id != 0 && event.event.id != -1 &&  !event.event.meta.eventObject.is15minSlot">
                      <span class="patient-color"></span>
                      <div class="calendar-box__time-zone">
                        <span class="time">{{ event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}}
                          - {{ event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{
                          event.event.meta.eventObject.crmPatientTimeZone | timezone }}</span>
                      </div>
                      <div class="calendar-box__title">
                        <div class="calendar-box__title__box">

                          <span class="classification"
                            tooltip="{{event.event.meta.eventObject.crmClassificationDisplayName}}">
                            {{event.event.meta.eventObject.crmClassificationDisplayName}}
                          </span>

                          <span class="classification" tooltip="{{event.event.meta.eventObject.appointmentType}}">
                            {{event.event.meta.eventObject.appointmentType}}
                          </span>

                          <span class="soid">
                            <span class="classification"
                              pTooltip="{{event.event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}">{{event.event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}</span>
                            <span class="classification">
                              SO# <a
                                (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject?.appointment?.crmSalesorderAppId)"
                                href="javascript:void(0)">{{event.event.meta.eventObject?.appointment?.crmSalesorderAppId}}</a>
                              | <span class="bright-tree-patient-url">
                                P#<a
                                  (click)="onNavigateBrightTreePatientUrl(event.event.meta.appointmentInfoReponseModel?.appointment?.crmPatientRcmBrightreeID)"
                                  href="javascript:void(0)">{{event.event.meta.appointmentInfoReponseModel?.appointment?.crmPatientId}}</a>
                              </span>
                            </span>
                          </span>

                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
                          <span class="address-box" tooltip="{{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}"
                            *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'">
                            {{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
                          </span>
                        </div>

                      </div>


                      <div class="calendar-box__click-box">
                        <div class="calendar-box__edit-delete">
                          <a href="javascript:void(0)"
                            (click)="showDialogPopup(true,event.event.meta.eventObject?.appointment,ConextMenu,BlockSlot)"
                            class="dots-btn">
                            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            </svg>
                          </a>
                        </div>
                        <span
                          *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType != 'Home Visit'"
                          class="calendar-box__facility-name"
                          pTooltip="{{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}">
                          {{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
                        </span>
                        <i *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'"
                          class="location-icon"
                          pTooltip="{{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}"></i>
                        <small class="time" *ngIf="event.event.meta.appointmentInfoReponseModel.isCallStart"> Call in
                          Progress....</small>
                      </div>

                    </div>


                    <div class="calendar-box done half-slot"
                      *ngIf="event.event.id != 0 && event.event.id != -1 && event.event.meta.eventObject.is15minSlot">
                      <span class="patient-color"></span>
                      <div class="calendar-box__time-zone">
                        <span class="time">{{ event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}}
                          - {{ event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{
                          event.event.meta.eventObject.crmPatientTimeZone | timezone }}</span>
                      </div>

                      <div class="calendar-box__title">
                        <div class="calendar-box__title__box">
                          <div class="soid">
                            <span class="classification"
                              pTooltip="{{event.event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}">{{event.event.meta.appointmentInfoReponseModel?.appointment?.classificationDisplayName}}</span>
                            <span class="classification">
                              SO# <a
                                (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject?.appointment?.crmSalesorderAppId)"
                                href="javascript:void(0)">{{event.event.meta.eventObject?.appointment?.crmSalesorderAppId}}</a>
                              | <span class="bright-tree-patient-url">
                                P#<a
                                  (click)="onNavigateBrightTreePatientUrl(event.event.meta.appointmentInfoReponseModel?.appointment?.crmPatientRcmBrightreeID)"
                                  href="javascript:void(0)">{{event.event.meta.appointmentInfoReponseModel?.appointment?.crmPatientId}}</a>
                              </span>
                            </span>


                          </div>

                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
                          <!-- <span *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType != 'Home Visit'"
                            class="address-box">
                            {{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
                          </span> -->
                          <!-- <span class="address-box" 
                            *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'">
                            {{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}
                          </span> -->
                        </div>
                      </div>

                      <div class="calendar-box__click-box">
                        <div class="calendar-box__edit-delete">
                          <a href="javascript:void(0)"
                            (click)="showDialogPopup(true,event.event.meta.eventObject?.appointment,ConextMenu,BlockSlot)"
                            class="dots-btn">
                            <svg _ngcontent-nwa-c189="" width="21" height="5" viewBox="0 0 21 5" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle _ngcontent-nwa-c189="" cx="2.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="10.5" cy="2.5" r="2.5" fill="#707070"></circle>
                              <circle _ngcontent-nwa-c189="" cx="18.5" cy="2.5" r="2.5" fill="#707070"></circle>
                            </svg>
                          </a>
                        </div>

                        <!-- <i *ngIf="event.event.meta.appointmentInfoReponseModel.appointment.crmAppointmentType == 'Home Visit'"
                          class="location-icon"
                          pTooltip="{{event.event.meta.appointmentInfoReponseModel.appointment.crmFacilityName}}"></i> -->
                        <small class="time" *ngIf="event.event.meta.appointmentInfoReponseModel.isCallStart"> Call in
                          Progress....</small>
                      </div>
                    </div>


                    <div class="calendar-box done isBlock" *ngIf="event.event.id == -1">
                      <span class="patient-color"></span>

                      <div class="calendar-box__time-zone">
                        <span class="time">
                          {{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end | date:'shortTime'
                          |
                          lowercase }} |
                          {{event.event.meta.eventObject.facilityTimeZone | timezone}}
                        </span>
                      </div>
                      <div class="calendar-box__title">
                        <h6 pTooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
                      </div>

                      <div class="calendar-box__click-box" *ngIf="event.event.meta.eventObject.isBlock">
                        <div class="calendar-box__edit-delete">
                          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Edit Block"
                            tooltipPosition="top"
                            (click)="editSlotCalled(event.event.meta.eventObject.therapistCustomAvailabilityId, event.event.meta.eventObject.availId,event.event.meta.eventObject.facilityTimeZone)">
                            <i class="edit-icon-new"></i>
                          </a>
                          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Remove Block"
                            tooltipPosition="top"
                            (click)="removeSlotCalled(event.event.meta.eventObject.therapistCustomAvailabilityId,event.event.meta.eventObject.availId,event.event.meta.eventObject.facilityTimeZone)">
                            <i class="delete-icon-new"></i>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="calendar-box done break-hours" *ngIf="event.event.id == 0">
                      <span class="patient-color"></span>
                      <div class="calendar-box__time-zone">
                        <span class="time">
                          {{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end | date:'shortTime'
                          |
                          lowercase }} |
                          {{event.event.meta.eventObject.facilityTimeZone | timezone}}
                        </span>
                      </div>

                      <div class="calendar-box__title">
                        <h6>{{ event.event.title }}</h6>
                      </div>
                      <div class="calendar-box__click-box">
                        <div class="calendar-box__edit-delete">
                          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Edit Break"
                            tooltipPosition="top"
                            (click)="editBreakCalled(event.event.meta.eventObject.therapistCustomAvailabilityId, event.event.meta.eventObject.availId,event.event.meta.eventObject.facilityTimeZone)"><i
                              class="edit-icon-new"></i></a>
                          <a href="javascript:void(0)" class="remove-block-calendar" pTooltip="Remove Break"
                            tooltipPosition="top"
                            (click)="removeBreakCalled(event.event.meta.eventObject.therapistCustomAvailabilityId, event.event.meta.eventObject.availId,event.event.meta.eventObject.facilityTimeZone)"><i
                              class="delete-icon-new"></i></a>
                        </div>
                      </div>
                    </div>


                  </div>
                </ng-template>

                <ng-template #customHourSegmentTemplate1 let-day="day" let-segment="segment" let-locale="locale"
                  let-event="event">
                  <div class="fill-slot"
                    *ngIf="facilityNameLabel(segment.date,selectedUser?.therapistAvailability) !=''">
                    <a class="last-box__add-btn" href="javascript:void(0)"
                      (click)="hourSegmentClick(segment,BlockSlot, event)">
                      <img src="assets/img/facility-ic.svg" width="29" alt="down-arrow">
                      <strong pTooltip="{{facilityNameLabel(segment.date,selectedUser?.therapistAvailability)}}">
                        {{facilityNameLabel( segment.date,selectedUser?.therapistAvailability)}}</strong>
                    </a>
                  </div>
                  <div *ngIf="facilityNameLabel(segment.date,selectedUser?.therapistAvailability)==''"
                    class="fill-slot not-available" style="background-color: #F9F9F9 !important; text-align: center;">
                    N/A
                  </div>
                </ng-template>
                <div *ngIf="isBlankMessageforTherapistShow" class="calendar-outer no-records-found">
                  Therapist not available for selected week.
                </div>
                <div *ngIf="!isBlankMessageforTherapistShow">
                  <calendar-scheduler-view *ngSwitchCase="CalendarView.Week" [viewDays]="viewDays" [viewDate]="viewDate"
                    [events]="events" [locale]="locale" [responsive]="true" [weekStartsOn]="weekStartsOn"
                    [excludeDays]="excludeDays" [startsWithToday]="startsWithToday" [dayStartHour]="dayStartHour"
                    [dayEndHour]="dayEndHour" [dayModifier]="dayModifier" [dayStartMinute]="dayStartMinute"
                    [dayEndMinute]="dayEndMinute" [hourModifier]="hourModifier" [segmentModifier]="segmentModifier"
                    [eventModifier]="eventModifier" [showEventActions]="true" [showSegmentHour]="false"
                    [zoomEventOnHover]="false" (viewDaysChanged)="viewDaysChanged($event)"
                    (dayHeaderClicked)="dayHeaderClicked($event.day)" (hourClicked)="hourClicked($event.hour)"
                    (segmentClicked)="hourSegmentClick($event,BlockSlot, $event.meta.user)"
                    (eventClicked)="eventClicked('Clicked', $event.event)"
                    (eventTimesChanged)="eventTimesChanged($event)" [eventTemplate]="customEventTemplate"
                    [cellTemplate]="customHourSegmentTemplate1" [refresh]="refresh" [hourSegments]="4"
                    [hourSegmentHeight]="130">
                  </calendar-scheduler-view>
                </div>
              </div>
              <!-- </p-scrollPanel> -->
            </div>
          </div>
        </div>
      </div>
      <div class="loader-block" *ngIf="loadingCustom">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
          animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
  <!-- filter -->
  <p-overlayPanel class="overly-filter" #clanderFilter [showCloseIcon]="true">
    <div class="overly-filter__outer">
      <h2 class="filter-title">Filters</h2>
      <div class="overly-filter__scroll">
        <div class="road-show-checkbox">
          <p-checkbox name="groupname" [(ngModel)]="isShowHomeVisit" (change)="onCheck()" label="Road/Home Appt"
            binary="true"></p-checkbox>
        </div>
        <div class="form__group">
          <label class="form__label">Branch</label>
          <p-multiSelect class="select-box select-branch filter-status" [(ngModel)]="selectedbranches"
            [options]="branchResponseModel" defaultLabel="Select branch" (onChange)="onBranchChange($event)"
            optionLabel="crmBranchName" [filter]="true" selectedItemsLabel="{0} Branch(s) Selected"></p-multiSelect>
        </div>
        <div class="form__group">
          <label class="form__label">Facility</label>
          <p-multiSelect class="select-box select-branch filter-status" [(ngModel)]="selectedAppointmentFacilities"
            [options]="facilityResponseModel" defaultLabel="Select Facility" (onChange)="onFacilityChange($event)"
            optionLabel="crmFacilityName" [filter]="true" selectedItemsLabel="{0} facility(s) Selected"></p-multiSelect>
        </div>

      </div>
      <div class="form__group btn-block">
        <button class="btn btn--primary" (click)="onFilterSearch()">Search</button>
        <a class="btn btn--secondary clear-filter" href="javascript:void(0)"
          (click)="onClearFilterSearch();onFilterSearch();">Clear</a>
      </div>
    </div>
  </p-overlayPanel>
  <div class="landscape-msg">
    <p>Application does not support landscape mode</p>
  </div>
</section>
<!-- <p-toast position="bottom-right"></p-toast> -->

<!-- Fill Slot Popup-->
<p-dialog appendTo="body" class="fill-slot-popup" [(visible)]="fillSlotPopup" [modal]="true">
  <div class="fill-slot-popup__outer">
    <div class="therapist-availabilty__block">
      <div class="fill-slot-popup__title">
        <div class="choose-type" *ngIf="isRemoteAndFacility">
          <h2>Select Appointment Type</h2>
          <div class="form__group">
            <p-radioButton name="groupname" value="FacilityVisit" label="Facility Visit" [(ngModel)]="selectedApptType"
              (click)="getPatients(facilityId,true,1)">
            </p-radioButton>
            <p-radioButton name="groupname" value="RemoteSetup" label="Remote Setup" [(ngModel)]="selectedApptType"
              (click)="getPatients(remoteFacilityId,false,2)"></p-radioButton>
          </div>
        </div>

        <h2>Search Nearby Patient</h2>
      </div>
      <div class="search-fiter view-therapist-appointments-filter">
        <div class="form__group">
          <div class="search-box">
            <input type="text" class="form__control" pInputText placeholder="Patient Name, DOB, Zip Code"
              [(ngModel)]="searchText" (keyup)="textChange()" (keyup.enter)="SearchPatient()">

            <button class="search-btn" pButton type="button" *ngIf="showClear" (click)="searchCloseClick()">
              <i class="ic cancel"></i>
            </button>
            <button class="search-btn" pButton type="button" (click)="SearchPatient()">
              <i class="search-new"></i>
            </button>
          </div>

          <div class="filter-btn">
            <a href="javascript:void(0);" pTooltip="Filter" (click)="filterBox.show($event)">
              <img src="assets/img/filter.svg" width="22" alt="filter">
            </a>
          </div>
        </div>

        <p-overlayPanel #filterBox [dismissable]="true" [showCloseIcon]="true"
          styleClass="dme-therapist-search-by-filters">
          <div class="filter-checkbox choose-branch">
            <h3>Search By Filters</h3>
            <div class="filter-checkbox__branch">
              <label class="form__label">Branch</label>
              <span class="switchBtn" style="margin: 0 0 8px 1px;">
                <p-radioButton name="group1" [value]="true" label="All Branch" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllBranchFilterSelected">
                </p-radioButton>
                <p-radioButton name="group1" [value]="false" label="Choose Branch" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="isAllBranchFilterSelected">
                </p-radioButton>
              </span>
              <p class="filter-checkbox__info" *ngIf="isAllBranchFilterSelected">All available Branches will be selected with this.</p>
              <p-multiSelect class="select-box" [(ngModel)]="selectedFilterBranches" [ngModelOptions]="{standalone: true}"
              *ngIf="!isAllBranchFilterSelected" optionLabel="crmBranchName" [options]="branchResponseModel"
                dataKey="crmBranchId" placeholder="Select Branches" selectedItemsLabel="{0} Branches Selected"
                [filter]="true"></p-multiSelect>
                <span class="invalid-feedback" *ngIf="isFilterSubmit && !isAllBranchFilterSelected && selectedFilterBranches?.length==0">Kindly select at least one Branch</span>
            </div>
            <div class="filter-checkbox__branch">
              <label class="form__label">Classification</label>
              <span class="switchBtn" style="margin: 0 0 8px 1px;">
                <p-radioButton name="group1" [value]="true" label="All Classification"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="isAllClssificationFilterSelected">
                </p-radioButton>
                <p-radioButton name="group1" [value]="false" label="Choose Classification"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="isAllClssificationFilterSelected">
                </p-radioButton>
              </span>
              <p class="filter-checkbox__info" *ngIf="isAllClssificationFilterSelected">All available Classifications
                will be selected with this.</p>
              <p-multiSelect class="select-box filter-status" [ngModelOptions]="{standalone: true}"
                *ngIf="!isAllClssificationFilterSelected" [(ngModel)]="selectedClassificationType"
                [options]="filterClassificationList" placeholder="Select Classifications"
                optionLabel="crmClassificationDisplayName" [showToggleAll]="false"
                selectedItemsLabel="{0} Classifications Selected"></p-multiSelect>
              <span class="invalid-feedback" *ngIf="isFilterSubmit && !isAllClssificationFilterSelected && selectedClassificationType?.length==0">Select at least 1 classification</span>
            </div> 
            <div class="ui-g-12">
              <p-checkbox name="group1" value="ReadyForScheduling" label="Ready for Scheduling"
                [(ngModel)]="selectedAppointmentValues" inputId="ny"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="group1" value="NoShow" label="No Show" [(ngModel)]="selectedAppointmentValues"
                inputId="sf"></p-checkbox>
            </div>
            <div class="ui-g-12">
              <p-checkbox name="group1" value="Cancel" label="Cancel" [(ngModel)]="selectedAppointmentValues"
                inputId="la"></p-checkbox>
            </div>

            <div class="ui-g-12 buttons">
              <button class="btn btn--secondary  mb-0" style="margin-right: 1.5rem;" (click)="onClearFilter()">Clear</button>
              <button class="btn btn--primary mb-0" (click)="onAppointmentChange(filterBox)">Search</button>
            </div>
          </div>
        </p-overlayPanel>
      </div>

      <div class="fill-slot-popup__content">
        <p-scrollPanel [style]="{width: '100%', height: '100%'}">
          <div class="fill-slot-popup__content__block" *ngFor="let patient of nearByPatients">
            <div class="fill-slot-popup__content__block__link">
              <div class="fill-slot-popup__content__block__top">
                <h3 class="title">
                  <a href="javascript:void(0)" class="link"
                    (click)="showScheduleDialog(patient.crmSalesOrderId,patient.schAppointmentId,patient.crmPatientAllowedOnlyOnMyFacility)">
                    {{patient.crmPatientName}} <ng-container *ngIf="!selectedFacility.isRemoteAssistance">
                      |
                      {{patient.crmPatientTimeZone | timezone}}
                    </ng-container>
                  </a>
                </h3>
                <span *ngIf="!selectedFacility.isRemoteAssistance"
                  class="miles">{{patient.crmPatientDistanceInMiles}}</span>
              </div>
              <div class="fill-slot-popup__content__block__bottom">
                <span class="status-block-box"
                  [ngClass]="patient.crmSalesorderStatus=='ReadyForScheduling'?'ready-for-scheduling':patient.crmSalesorderStatus=='Cancel'?'cancel':patient.crmSalesorderStatus=='NoShow'?'no-show':''">{{patient.crmSalesorderStatusDisplay}}</span>

                <span class="phone-call">({{patient.crmPatientCallCount}}) Call</span>

              </div>
            </div>
          </div>
          <div class="fill-slot-popup__content__block" *ngIf="nearByPatients?.length==0">
            Patient(s) not found in therapist's licensed states.
          </div>
        </p-scrollPanel>
      </div>
    </div>
  </div>
</p-dialog>
<!-- Block Slot Popup-->
<p-dialog class="fill-slot-popup" appendTo="body" [(visible)]="blockSlotPopup" [modal]="true">
  <div class="fill-slot-popup__outer">
    <div class="therapist-availabilty__block">
      <div class="fill-slot-popup__title">
        <h2>{{labelFor}} for {{selectedtherapist}}</h2>
        <div class="fill-slot-popup__title__timeslot">
          <span class="time">
            {{searchDate|date:appDateFormat.dateFormat}}
            <!--<small>&nbsp;|&nbsp; EST</small>-->
          </span>
        </div>
      </div>

      <div class="fill-slot-popup__content fill-slot-popup__content--block-slot-content">
        <div class="block-slot-outer">
          <div class="search-box" *ngIf="isBlock">
            <label class="label">Description</label>
            <textarea pInputTextarea class="form__control" [(ngModel)]="blockDesc" maxlength="250"></textarea>
          </div>
          <div class="col">
            <div class="form__group">
              <span class="event-series">
                Start Time
              </span>
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customStartTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
            </div>
            <div class="form__group">
              <span class="event-series">End Time</span>
              <div class="date-pikar date-timer">
                <ngx-timepicker-field [(ngModel)]="customEndTime" [format]="12" minutesGap="15" [controlOnly]="true"
                  [ngModelOptions]="{standalone: true}">

                </ngx-timepicker-field>
              </div>
            </div>
          </div>
          <span class="time-note" style=" margin: -20px 0 2rem 0 !important; display: table;">Please do not use the 0
            prefixes in Hours and Minutes</span>

          <div class="set-custom-date-btn">
            <button class="btn btn--primary" [disabled]="isButtonDisabled" type="button"
              (click)="onSave()">Update</button>
            <button class="btn btn--secondary" (click)="closeSideBar()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-dialog>

<div class="loader-block" *ngIf="Customloading" style="z-index:99999999999">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>

<div class="loader-block" *ngIf="loadingBlockSlotBrakHours" style="z-index:99999999999 !important">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>

<div id="exportData" #exportData style="display:none;">
  <style>
    .border {
      border-right: 1px solid #ccc;
      margin-right: 10px;
      padding-right: 10px;
    }

    .border:last-child {
      border: none;
    }
  </style>
  <div style="padding: 2.5rem;">
    <div style="display: flex; justify-content: space-between; margin-bottom: 1.5rem;">
      <div style="display: flex; align-items: center; width: 80%;">
        <h2 style="font-size: 32px; margin-right: 3rem; margin-bottom: 0;">{{therapistName}}</h2>
        <div style="display: flex; width: 500px;" style="font-size: 20px;">
          <span class="border" *ngFor="let item1 of exportAppointmentCnt">
            {{item1.appointmentTitle}} : {{item1.appointmentCount}}
          </span>
        </div>
      </div>
      <h4 style="font-size: 26px;">{{ pdfWeekStartDate}} - {{pdfWeekEndDate}}, {{currentYear}}</h4>

    </div>
    <div>
      <table id="content" class="pdf-table" width="100%" height="100vh" style="border-spacing: 0;">
        <tr>
          <th width="240" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Appointment
            Date Time</th>
          <th width="230" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Patient
            Appointment Time</th>
          <th width="140" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Sales Order
            Id</th>
          <th width="250" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Patient Name
            / Patient Id</th>
          <th width="180" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Block / Break
          </th>
          <th width="250" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">
            Classification</th>
          <th width="330" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Location</th>
          <th width="200" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Appointment
            Type</th>
          <th width="120" style="text-align: left; border-bottom: 2px solid #b1a6e5; padding-bottom: 8px;">Status</th>
        </tr>
        <tr *ngFor="let item of exportDataRes">
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;">
            {{item.dtStart | date:appDateFormat.dateFormat}} <br>{{item.dtStart | date:'shortTime' | lowercase}} to
            {{item.dtEnd | date:'shortTime' | lowercase}} | {{item.crmFacilityTimeZone}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;">
            {{item.crmPatientTimeZone}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.soId}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.patientName}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">

          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="!item.isAppt" [attr.colspan]="!item.isAppt ? '2' : ''">

          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="!item.isAppt" [attr.colspan]="!item.isAppt ? '5' : ''">
            {{item.patientName}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.classification}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.location}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.apptType}}
          </td>
          <td style="text-align: left; border-bottom: 1px solid #d2d2d2; padding-top: 8px; padding-bottom: 8px;"
            *ngIf="item.isAppt">
            {{item.status}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>