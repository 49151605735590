import { BranchResponseModel } from './branch-response-model';
import { DoctorFacilityResponseModel } from './doctor-facility-response-model';
import { SalesOrderInfoResponseModel } from './appointment/sales-order-info-reponse-model';
import { FacilityResponseModel } from './facility/facility-response-model';

export class DoctorResponseModel {
  crmDoctorId: number;
  crmDoctorFirstName: string;
  doctorFullName: string;
  crmDoctorLastName: string;
  crmDoctorAddress: string;
  crmDoctorContactNo: string;
  crmDoctorContactEmailAddress: string;
  isActive: boolean;
  modifiedByUserInfoId: number;
  dateTimeCreated: Date;
  dateTimeLastModified: Date;
  crmBranchId: number;
  facilityName: string;
  crmIsPatientAllowedOnlyOnMyFacility: boolean;
  crmMyFacilityId: number;
  crmBranch: BranchResponseModel;
  crmIsFacilityVisitSelected: boolean;
  crmIsRemoteVisitSelected: boolean;
  crmIsRoadHomeVisitSelected: boolean;

  facility: FacilityResponseModel[];
  doctorFacility: DoctorFacilityResponseModel[];
  salesOrder: SalesOrderInfoResponseModel [];

}

export class DoctorExclusion {
  label: string;
  value: string;

}
