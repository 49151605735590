<!-- Start Content -->

<div class="application-settings-outer">
  <p-tabView class="appointments-tab-outer setting-tab-menu admin-application-setting" [(activeIndex)]="index">
    <div class="title-block">
      <h1>{{title}}</h1>

      <div class="title-block__right">
        <a class="back-btn" href="javascript:void(0);" [routerLink]="['/admin/settings']"><i class="back-new"></i> Back</a>
      </div>
    </div>


    <p-tabPanel header="General" [selected]="index == 0">
      <!-- <div class="content-area"> -->
        <div class="form-content-outer">
          <div class="form-outer" *ngIf="dateFormat!=null && timeZone!=null">
            <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
              <div class="col">
                <div class="form__group">
                  <label class="form__label">Date / Time Format</label>
                  <!--<input type="text" formControlName="dateTimeFormat" class="form__control" pInputText>-->
                  <p-dropdown class="select-box" formControlName="dateTimeFormat" [options]="dateFormat" placeholder="Select" optionLabel="displayValue" [showClear]="true">
                  </p-dropdown>
                  <span class="invalid-feedback-error" *ngIf="hasError(formFields.dateTimeFormat, 'required') || hasError(formFields.dateTimeFormat, 'whitespace')">{{validationMessages.dateTimeFormat.required}}</span>
                  <small> [The format in which dates and times appear depends on this setting.]</small>
                </div>

                <div class="form__group" *ngIf="false">
                  <!-- <div class="form__group"> -->
                  <label class="form__label">Days of Week</label>
                  <input type="text" formControlName="dayOfWeek" class="form__control" pInputText>
                  <span class="invalid-feedback-error" *ngIf="hasError(formFields.dayOfWeek, 'required') || hasError(formFields.dayOfWeek, 'whitespace')">{{validationMessages.dayOfWeek.required}}</span>
                  <span class="invalid-feedback-error" *ngIf="hasError(formFields.dayOfWeek, 'validNumber')">{{validationMessages.dayOfWeek.validNumber}}</span>
                </div>

                <div class="form__group">
                  <label class="form__label">Time Zone</label>
                  <p-dropdown class="select-box" formControlName="timeZone" [options]="timeZone" placeholder="Select" optionLabel="name" [showClear]="true">
                  </p-dropdown>
                  <!--<input type="text" formControlName="timeZone" class="form__control" pInputText>-->
                  <span class="invalid-feedback-error" *ngIf="hasError(formFields.timeZone, 'required') || hasError(formFields.timeZone, 'whitespace')">{{validationMessages.timeZone.required}}</span>
                  <small>
                    Select a time zone or return to the default time zone (Return to default time zone) for the current session. Upon the next login, the time zone setting reverts to the system default, or user profile setting for time zone.<br>
                    (UTC-5:00) Eastern Time (United State)
                  </small>
                </div>

              </div>

              <div class="btns">
                <button class="btn btn--primary" routerLink="/admin/settings/application">Save</button>
                <button class="btn btn--secondary" routerLink="/admin/settings">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      <!-- </div> -->
      
    </p-tabPanel>

    <p-tabPanel header="Online Booking" [selected]="index == 1">
      <app-online-booking></app-online-booking>
    </p-tabPanel>

    <p-tabPanel header="Appointment" [selected]="index == 2">
      <app-appointment></app-appointment>
    </p-tabPanel>

    <p-tabPanel header="User Role & Permission" [selected]="index == 3">
      <app-permissions-new></app-permissions-new>
    </p-tabPanel>

    <p-tabPanel header="Holidays" [selected]="index == 4">
      <app-holiday></app-holiday>
    </p-tabPanel>
    <p-tabPanel header="Patient Note" [selected]="index == 5" *ngIf="!isProvisional && !isHidePatientDetails">
      <app-patient-note></app-patient-note>
    </p-tabPanel>
  </p-tabView>
</div>

<div class="loader-block" *ngIf="loading">
  <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff" animationDuration=".8s">
  </p-progressSpinner>
</div>
