<section class="main-outer__content-outer therapist-module-outer old-rt">
  <app-therapist-header></app-therapist-header>
  <div class="content-area">
    <div class="title-block">
      <div class="title-block__left">
        <h1>{{pageTitle}}</h1>
      </div>
      <div class="calendar-top-right" style="z-index:999 !important" *ngIf="!isGoogleMap">
        <span class="show-mobile-view"></span>
        <button class="btn btn--primary" (click)="myAvailability()">My Availability</button>
        <p-dropdown class="select-box select-view-facility" [options]="calendarActions"
                    [(ngModel)]="selectedcalendarActions" (onChange)="RedirectcalendarActions()" placeholder="Select Calendar View"
                    optionLabel="name" [showClear]="true" appendTo="body"></p-dropdown>
      </div>
      <div class="therapist-title-block__right-block" *ngIf="isGoogleMap">         
        <button class="btn btn--secondary week-view" (click)="back()">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.6111 1.40283L1 4.90109L4.6111 8.39935" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 4.90106L6.77777 4.90106C10.7666 4.90106 14 8.0334 14 11.8976V12.5972" stroke="#ffffff" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          My Appointments
        </button>
        <button class="btn btn--primary ml-15" [routerLink]="['/therapist/my-availability']">My Availability
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.82975 1.73611L13.2258 5.02604L9.82975 8.31597" stroke="white" stroke-width="1.6"
              stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13.2258 5.02603L7.79211 5.02603C4.04083 5.02603 1 7.97183 1 11.6059V12.2639" stroke="white"
              stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>

    <div class="calendra-top-btn old-rt-calendar-top-right">
      <div class="btn-group calendra-next-prev-btn">
        <div class="btn btn--arrow prev"
             mwlCalendarPreviousView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="dateOrViewChanged()"
             (click)="dateChange()">
          <i class="arrow-prev"></i>
        </div>

        <div class="btn btn--primary"
             mwlCalendarToday
             [(viewDate)]="viewDate"
             (viewDateChange)="dateOrViewChanged()">
          Today
        </div>

        <div class="btn btn--arrow next"
             mwlCalendarNextView
             [view]="view"
             [(viewDate)]="viewDate"
             (viewDateChange)="dateOrViewChanged()"
             (click)="dateChange()">
          <i class="arrow-next"></i>
        </div>

        <div class="calendar-day">
          <p-calendar class="datepicker therapist-select-date" dateFormat={{appDateFormat.primeFormat}}
                      [(ngModel)]="viewDate" [locale]="en"
                      [numberOfMonths]="1" [showIcon]="true" [readonlyInput]="true"
                      (onSelect)="dateOrViewChanged()">
          </p-calendar>
        </div>
      </div>
    </div>
    <ng-template #loading>
      <div class="loader-block">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </ng-template>

    <div class="calendar-outer">
      <div class="admin-availability-calandar">
        <div class="admin-availabilty-calc">
          <div class="admin-availabilty-calc__left">
            <div class="therapist-availabilty-calc__left__block view-by-facility">
              <p-scrollPanel styleClass="therapist-module-calendar-scroll" [style]="{width: '100%', height: '100%'}">
                <div class="horizontal-scroll admin-appointments-week" [ngSwitch]="view">
                  <ng-template #customEventTemplate let-event="event">
                    <div class="custom-event" mwlCalendarTooltip=""
                         [tooltipPlacement]="'top'">
                      <!--Appintment Box-->
                      <div class="calendar-box done" *ngIf="event.event.id != 0 && event.event.id != -1 &&  !event.event.meta.eventObject.is15minSlot">
                        <span class="patient-color"></span>

                        <div class="calendar-box__title">
                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>
                          <!--<span class="time">{{  event.event.start | date:'shortTime' | lowercase}} - {{  event.event.end | date:'shortTime'| lowercase }} | {{ event.event.meta.eventObject.facilityTimeZone }}</span>-->


                          <span class="time" tooltip="{{  event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}} - {{  event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{ event.event.meta.eventObject.facilityTimeZone }}">{{  event.event.meta.eventObject.startLabel | date:'shortTime' | lowercase}} - {{  event.event.meta.eventObject.endLabel | date:'shortTime'| lowercase }} | {{ event.event.meta.eventObject.facilityTimeZone }}</span>

                          <span class="soid">
                            SO# <a (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject?.appointment?.crmSalesorderAppId)"
                                   href="javascript:void(0)">
                              {{event.event.meta.eventObject?.appointment?.crmSalesorderAppId}}
                            </a>
                          </span>

                          <span class="classification" tooltip="{{event.event.meta.eventObject.crmClassificationDisplayName}}">
                            {{event.event.meta.eventObject.crmClassificationDisplayName}}
                          </span>

                          <span class="classification" tooltip="{{event.event.meta.eventObject.appointmentType}}">
                            {{event.event.meta.eventObject.appointmentType}}
                          </span>

                        </div>

                        <span class="valign-center edit-btn-block"><a [attr.disabled]="!canChangeNoShowCompleteStatus" (click)="editEvent(event.event.id,event.event.meta.eventObject.dateTimeLastModified)" href="javascript:void(0)"> <i class="edit-icon-new"></i></a></span>
                      </div>

                      <!--Half Box(15 min)-->
                      <div class="calendar-box done half-slot" *ngIf="event.event.id != 0 && event.event.id != -1 && event.event.meta.eventObject.is15minSlot">
                        <span class="patient-color"></span>

                        <div class="calendar-box__title">
                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>

                          <div class="soid">
                            SO# <a (click)="onNavigateBrightTreeSalesOrderUrl(event.event.meta.eventObject?.appointment?.crmSalesorderAppId)"
                                   href="javascript:void(0)">{{event.event.meta.eventObject?.appointment?.crmSalesorderAppId}}</a>
                          </div>
                        </div>

                        <span class="valign-center edit-btn-block"> <a (click)="editEvent(event.event.id,event.event.meta.eventObject.dateTimeLastModified)" href="javascript:void(0)"> <i class="edit-icon-new"></i></a></span>
                      </div>

                      <!--Block Slot-->
                      <div class="calendar-box done isBlock" *ngIf="event.event.id == -1">
                        <span class="patient-color"></span>
                        <div class="calendar-box__title">
                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>

                          <span class="time" tooltip="{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |  date:'shortTime' | lowercase }}">{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |  date:'shortTime' | lowercase }}</span>

                        </div>
                      </div>

                      <!--Break Houur-->
                      <div class="calendar-box done break-hours" *ngIf="event.event.id == 0">
                        <span class="patient-color"></span>
                        <div class="calendar-box__title">
                          <h6 tooltip="{{ event.event.title }}">{{ event.event.title }}</h6>

                          <span class="time" tooltip="{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |  date:'shortTime' | lowercase }}">{{ event.event.start | date:'shortTime' | lowercase}} - {{ event.event.end |  date:'shortTime' | lowercase }}</span>

                        </div>

                      </div>

                    </div>
                  </ng-template>

                 
                  <calendar-scheduler-view #calendarScheduler  *ngSwitchCase="CalendarView.Week" 
                                           [viewDays]="viewDays"
                                           [viewDate]="viewDate"
                                           [events]="events"
                                           [locale]="locale"
                                           [responsive]="false"
                                           [weekStartsOn]="weekStartsOn"
                                           [excludeDays]="excludeDays"
                                           [startsWithToday]="startsWithToday"
                                           [hourSegments]="hourSegments"
                                           [hourSegmentHeight]="125"
                                           [dayStartHour]="dayStartHour"
                                           [dayEndHour]="dayEndHour"
                                           [dayStartMinute]="dayStartMinute"
                                           [dayEndMinute]="dayEndMinute"
                                           [dayModifier]="dayModifier"
                                           [hourModifier]="hourModifier"
                                           [segmentModifier]="segmentModifier"
                                           [eventModifier]="eventModifier"
                                           [showEventActions]="true"
                                           [showSegmentHour]="false"
                                           [zoomEventOnHover]="false"
                                           (viewDaysChanged)="viewDaysChanged($event)"
                                           (dayHeaderClicked)="dayHeaderClicked($event.day)"
                                           (hourClicked)="hourClicked($event.hour)"
                                           (segmentClicked)="segmentClicked('Clicked', $event.segment)"
                                           (eventClicked)="eventClicked('Clicked', $event.event)"
                                           (eventTimesChanged)="eventTimesChanged($event)"
                                           [eventTemplate]="customEventTemplate"
                                           
                                           [refresh]="refresh">
                  </calendar-scheduler-view>
                </div>
              </p-scrollPanel>
            </div>
          </div>

          <p-dialog appendTo="body" class="fill-slot-popup" [(visible)]="twoFacilityPopup" [modal]="true" (onHide)="closePopup()" *ngIf="isShowModel">
            <div class="select-options-facility">
              <div class="therapist-module-popup-detils">
                <div class="therapist-module-popup-detils__title-block">
                  <h2 class="title">{{appointment?.salesOrderInfo?.patientInfo?.crmPatientLastName}}, {{appointment?.salesOrderInfo?.patientInfo?.crmPatientFirstName}} </h2>
                  <span class="date-of-birth">
                    <i class="birthdate-new"></i>
                    {{appointment?.salesOrderInfo?.patientInfo?.crmPatientBirthDate | date:appDateFormat.dateFormat}}
                  </span>
                </div>
                <div class="therapist-availabilty-calc__right__block">
                  <div class="therapist-availabilty__block">
                    <div class="therapist-availabilty__block__sales-order sales-order-border">
                      <span>
                        <strong>Sales Order ID</strong>
                        <a (click)="onNavigateBrightTreeSalesOrderUrl(appointment?.salesOrderInfo?.crmSalesorderAppId)"
                           href="javascript:void(0)">{{appointment?.salesOrderInfo?.crmSalesorderAppId}}</a>
                      </span>
                      <span>
                        <strong>Patient ID</strong>
                        <a (click)="onNavigateBrightTreePatientUrl(appointment.salesOrderInfo?.patientInfo?.crmRcmBrightreeID)"
                           href="javascript:void(0)">{{appointment?.salesOrderInfo?.crmPatientId}}</a>
                      </span>
                    </div>
                    <div class="therapist-availabilty__block__sales-order sales-order-border">
                      <ul class="therapist-availabilty__block__email-phone first">
                        <li>
                          <a href="mailto:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientEmailAddress}}">
                            <i class="email-ic-new"></i>
                            {{appointment?.salesOrderInfo?.patientInfo?.crmPatientEmailAddress}}
                          </a>
                        </li>
                        <li>
                          <a href="tel:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientMobileNumber}}">
                            <i class="phone-icon-new-primary"></i>
                            {{appointment?.salesOrderInfo?.patientInfo?.crmPatientMobileNumber }}
                          </a>
                        </li>
                        <li>
                          <a href="tel:{{appointment?.salesOrderInfo?.patientInfo?.crmPatientPhoneNo}}">
                            <i class="voice-call-icon-new-primary"></i>
                            {{appointment?.salesOrderInfo?.patientInfo?.crmPatientPhoneNo }}
                          </a>
                        </li>
                      </ul>
                      <ul *ngIf="appointment?.salesOrderInfo?.crmSalesorderOrderNote || appointment?.salesOrderInfo?.crmSalesorderDeliveryNote"
                          class="therapist-availabilty__block__email-phone">
                        <li>
                          <a *ngIf="appointment?.salesOrderInfo?.crmSalesorderOrderNote"
                             class="link" href="javascript:void(0)" tooltip="{{appointment?.salesOrderInfo?.crmSalesorderOrderNote}}">
                            Order Note
                          </a>
                        </li>
                        <li>
                          <a *ngIf="appointment?.salesOrderInfo?.crmSalesorderDeliveryNote"
                             class="link" href="javascript:void(0)" tooltip="{{appointment?.salesOrderInfo?.crmSalesorderDeliveryNote}}">
                            Delivery Note
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="therapist-availabilty__block__sales-order sales-order-border">
                      <span>
                        <strong>Status</strong>
                        <div class="therapist-calendar-status therapist-calendar-status--selectbox" *ngIf="isShowStatusDropdown">
                          <select class="form__select" (change)="changeWipStatus($event)">
                            <option value="0" selected>Select</option>
                            <option *ngFor="let summary of salesOrderStatusCurrent" value={{summary.wipStatesName}}>
                              {{summary.wipStatesName}}
                            </option>
                          </select>
                        </div>
                        <span *ngIf="!isShowStatusDropdown">
                          {{wipStatus}}
                        </span>
                      </span>
                      <span>
                        <strong>Note</strong>
                        <div class="therapist-calendar-status">
                          <textarea [(ngModel)]="comments" class="form__textarea" pInputTextarea maxlength="500"></textarea>
                        </div>
                      </span>
                      <span>
                        <strong></strong>
                        <div style="margin-bottom: 1rem !important;" class="therapist-calendar-status" *ngIf="isShowStatusDropdown">
                          <p-checkbox name="groupnamecomplete" label="If box checked, the WIP State will not be updated in Brightree"
                                      [(ngModel)]="isBrightreeSystemAppUpdate" binary="true" (onChange)="onBTSelect($event)" [ngModelOptions]="{standalone: true}">
                          </p-checkbox>
                        </div>
                      
                      </span>

                      <span style="margin-bottom: 1rem !important;" >
                        <strong></strong>
                        <div class="add-note-btn"><a href="javascript:void(0)" class="btn btn--primary" (click)="UpdateStatusWithNote(appointment?.schAppointmentId,appointment?.appointmentDetailInfo?.crmFacilityId)">Update</a></div>
                      </span>
                    </div>
                  </div>
                  <p-accordion class="accordion view-by-therapist" #accordion>
                    <p-accordionTab header="Appointment Information" [selected]="true">
                      <div class="therapist-availabilty__block">
                        <div class="therapist-availabilty__block__sales-order">
                          <span><strong>Date</strong>{{appointment?.schAppointmentDateStartTime | date:appDateFormat.dateFormat }}</span>
                          <!--<span><strong>Time</strong> {{appointment?.appointmentDetailInfo?.schAppointmentDateStartTime | date:'shortTime' | lowercase}} to {{appointment?.appointmentDetailInfo?.schAppointmentDateEndTime | date:'shortTime' | lowercase}} | {{_patientTimezone}}</span>-->
                          <span>
                            <strong>Time</strong> {{appointment?.schAppointmentDateStartTime | date:'shortTime' |lowercase }} to
                            {{appointment?.schAppointmentDateEndTime | date:'shortTime' |lowercase }} | {{_patientTimezone}}
                          </span>
                          <span>
                            <strong>Duration</strong> <small class="hour">
                              {{duration}} <i class="info-new" tooltip="{{appointment?.salesOrderInfo?.crmClassificationDescription}}"></i>
                            </small>
                          </span>
                          <span>
                            <strong *ngIf="appointment.schAppointmentType=='Facility Visit'">Facility</strong> {{appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityName}}
                          </span>
                          <span>
                            <strong *ngIf="appointment.schAppointmentType=='Home Visit'">Home Address</strong>
                            <span *ngIf="appointment.schAppointmentType=='Home Visit'">
                              <span *ngIf="appointment.schIsBillingAddress">
                                {{appointment?.salesOrderInfo?.patientInfo?.crmPatientAddress}}
                              </span>
                              <span *ngIf="appointment.schIsBillingAddress==false">
                                {{appointment?.salesOrderInfo?.crmPatientDeliveryAddress}}
                              </span>
                            </span>
                            <strong *ngIf="appointment.schAppointmentType=='Facility Visit'">Facility Address</strong>
                            <span *ngIf="appointment.schAppointmentType=='Facility Visit'">
                              <span>{{appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine1}}</span>
                              <span *ngIf="appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine2">{{appointment?.appointmentDetailInfo?.facilityInfo?.crmAddressLine2}}</span>
                              <span>{{appointment?.appointmentDetailInfo?.facilityInfo?.crmCity}}</span>
                              <span>{{appointment?.appointmentDetailInfo?.facilityInfo?.crmState}}</span>
                              <span>{{appointment?.appointmentDetailInfo?.facilityInfo?.crmFacilityZipCode}}</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab *ngIf="appointment?.salesOrderInfo?.doctorInfo" header="Doctor Information">
                      <div class="therapist-availabilty__block">
                        <h3 class="scheduled-detials__left__block__title">{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorFirstName}} {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorLastName}}</h3>

                        <!-- <h5 class="scheduled-detials__left__block__title">{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress}}</h5> -->
                        <ul class="therapist-availabilty__block__email-phone" *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress">
                          <li class="location-block">
                            <a href="javascript:void(0);" style="cursor: default; pointer-events: none;">
                              <i class="location-icon"></i>
                              {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorAddress}}
                            </a>
                          </li>
                        </ul>
                        <ul *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress || appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo"
                            class="therapist-availabilty__block__email-phone mb-0">
                          <li *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress">
                            <a href="mailto:{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress}}">
                              <i class="email-ic-new"></i>
                              {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactEmailAddress}}
                            </a>
                          </li>
                          <li *ngIf="appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo">
                            <a href="tel:{{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo}}">
                              <i class="voice-call-icon-new-primary"></i>
                              {{appointment?.salesOrderInfo?.doctorInfo?.crmDoctorContactNo}}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="Device Information">
                      <div class="therapist-availabilty__block">
                        <div class="therapist-availabilty__block__sales-order">
                          <span><strong>Classification</strong>{{appointment?.salesOrderInfo?.crmSalesorderClassification}}</span>
                          <span><strong>Due Amount</strong> ${{appointment?.salesOrderInfo?.amountDue}}</span>
                          <span *ngIf="appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName"><strong>Insurance</strong> {{ appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName}}</span>
                          <span *ngIf="!appointment?.salesOrderInfo?.salesOrderInsuranceInfo[0]?.crmPolicyHolderName"><strong>Insurance</strong>-</span>
                        </div>
                      </div>
                    </p-accordionTab>
                    <p-accordionTab header="Insurance &amp; Policy Information">
                      <div class="therapist-availabilty__block therapist-availabilty__block--insurance">
                        <p-tabView class="appointment-detail">
                          <p-tabPanel [header]="item.crmPayorLevel" *ngFor="let item of appointment.salesOrderInfo.salesOrderInsuranceInfo; let i = index" [selected]="i == 0">
                            <div class="insurance-info">
                              <div class="insurance-info__block mb-0">
                                <span *ngIf="item.crmInsurancePolicyNumber"><strong>Policy No.</strong> {{item.crmInsurancePolicyNumber}}</span>
                                <span *ngIf="!item.crmInsurancePolicyNumber"><strong>Policy No.</strong>-</span>
                                <span *ngIf="item.crmPolicyHolderName">
                                  <strong>Company</strong>{{item.crmPolicyHolderName}}
                                </span>
                                <span *ngIf="!item.crmPolicyHolderName">
                                  <strong>Company</strong>-
                                </span>
                                <span *ngIf="item.crmInsurancePolicyEffectiveDate"><strong>Effective Date</strong> {{item.crmInsurancePolicyEffectiveDate | date:appDateFormat.dateFormat}}</span>
                                <span *ngIf="!item.crmInsurancePolicyEffectiveDate"><strong>Effective Date</strong>-</span>
                                <span><strong>Insurance Verified</strong>{{item.crmInsuranceVerified ? 'Yes' : 'No'}} </span>
                                <span><strong>Coverage Verified</strong> {{item.crmCoverageVerified ? 'Yes' : 'No'}} </span>
                              </div>
                            </div>
                          </p-tabPanel>
                        </p-tabView>
                      </div>
                    </p-accordionTab>
                  </p-accordion>
                </div>
              </div>
            </div>
          </p-dialog>

          <!--<div class="therapist-availabilty-calc__right therapist-information-right" *ngIf="isShowModel">
            <a class="link close-btn" href="javascript:void(0)" (click)="closeSideBar()">
              <img src="assets/img/popup-close-icon.svg" width="18" height="18" title="Close">
            </a>-->
           
            <!-- </p-scrollPanel> -->
          <!--</div>-->
        </div>
      </div>
      <div class="loader-block" *ngIf="Customloading">
        <p-progressSpinner [style]="{width: '50px', height: '50px'}" strokeWidth="8" fill="#ffffff"
                           animationDuration=".8s">
        </p-progressSpinner>
      </div>
    </div>
  </div>
  <div class="landscape-msg">
    <p>Application does not support landscape mode</p>
  </div>
</section>
<p-toast position="bottom-right"></p-toast>
