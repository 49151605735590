import { Injectable } from '@angular/core';
import { ResultOfT } from 'src/app/shared/models/response/result';
import { BaseService } from 'src/app/shared/services/base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationInAppDetailResponseModel } from 'src/app/shared/models/response/notificationinapp-detail-response-model';
import { WebStorageService } from '../../shared/services/web-storage.service';
import { BellNotificationInAppReadStatusRequestModel, BellNotificationInAppResponseModel, BellNotificationRequestModel } from 'src/app/shared/models/response/bell-notification-response-model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseService {
  constructor(httpService: HttpClient, webStorageService: WebStorageService) {
    super(httpService, webStorageService);
  }

  getNotifications(userInfoId: number) {
    return this.get<ResultOfT<NotificationInAppDetailResponseModel>>('Notification/GetNotificationsByUserId/' + userInfoId);
  }

  getUnReadNotifications(userInfoId: number) {
    return this.get<ResultOfT<NotificationInAppDetailResponseModel>>('Notification/GetUnReadNotificationsByUserId?userInfoId=' + userInfoId);
  }

  readInAppNotification(notificationInAppDetailResponseModel: Array<NotificationInAppDetailResponseModel>) {
    return this.post<ResultOfT<NotificationInAppDetailResponseModel>>('Notification/ReadInAppNotification', notificationInAppDetailResponseModel);
  }

  SendSalesOrderNotificationFirstCall(userInfoId: number) {
    return this.get<ResultOfT<any>>('Notification/SendSalesOrderNotificationFirstCall/' + userInfoId);
  }

  SendSalesOrderNotification2and3Call(userInfoId: number, token: string) {
    return this.get<ResultOfT<any>>('Notification/SendSalesOrderNotification2and3Call/' + userInfoId + '/' + token);
  }

  getBellNotifications(bellNotificationRequestModel: BellNotificationRequestModel, isRefreshed:boolean) {
    var url = 'Notification/GetBellNotificationsByUserId';
    url = url + '?UserInfoId=' + bellNotificationRequestModel.userInfoId;
    if (bellNotificationRequestModel.userPermissions) {
      url = url + '&UserPermissions=' + bellNotificationRequestModel.userPermissions;
    }
    if (bellNotificationRequestModel.branchId) {
      url = url + '&BranchId=' + bellNotificationRequestModel.branchId;
    }
    if (bellNotificationRequestModel.isWeekView) {
      url = url + '&IsWeekView=' + bellNotificationRequestModel.isWeekView;
    }
    if (isRefreshed) {
      let randomNum = new Date().getSeconds().toString();
      url = isRefreshed ? url + '&rnd=' + randomNum : url;
    }
    
    return this.get<ResultOfT<BellNotificationInAppResponseModel>>(url);
  }

  updateBellNotificationInAppReadStatus(bellNotificationInAppReadStatusRequestModel: BellNotificationInAppReadStatusRequestModel[]) {
    return this.post<ResultOfT<boolean>>('Notification/UpdateBellNotificationInAppReadStatus', bellNotificationInAppReadStatusRequestModel);
  }

  ResendAlertNotification(NotificationSendId: string, NotificationType: string) {
    return this.get<ResultOfT<any>>('Notification/ResendAlertNotification/' + NotificationSendId + '/' + NotificationType);
  }
}
