import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsPluginResponseModel, PluginSMSModel } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsPluginServiceResponseModel } from 'src/app/shared/models/response/settings/settings-plugin-service-response-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsResponseModel } from 'src/app/shared/models/response/appointment/settings-response';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import * as moment from 'moment';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.sass']
})
export class SmsComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsPluginResponseModel: SettingsPluginResponseModel;
  settingsPluginRequestModel: SettingsPluginRequestModel;
  pluginModel: PluginSMSModel;
  loggedInUserId: number;
  isTestSMS: boolean = false;
  testNumber: string;
  pluginServices: SettingsPluginServiceResponseModel[];
  customStartTime: string;
  customEndTime: string;
  settingsResponseModel: Array<SettingsResponseModel>;
  settingsRequestModel: Array<SettingsRequestModel>;

  formFields = {
    service: 'service',
    connectionName: 'connectionName',
    userName: 'userName',
    password: 'password',
    accountSID: 'accountSID',
    apiId: 'apiId',
    phoneNumber: 'phoneNumber',
    messagingServiceSID: 'messagingServiceSID'
  };

  validationMessages = {
    service: {
      required: 'Service is required',
      //validCharacters: 'Enter valid from email address'
    },
    connectionName: {
      required: 'Connection name is required',
      //validCharacters: 'Enter valid from name'
    },
    userName: {
      required: 'User name is required',
    },
    password: {
      required: 'Password is required',
      //validCharacters: 'Enter valid contact name'
    },
    accountSID: {
      required: 'Account SID is required',
      //validCharacters: 'Enter valid contact name'
    },
    apiId: {
      required: 'API id is required',
      //validCharacters: 'Enter valid contact name'
    },
    phoneNumber: {
      required: 'Phone number is required',
      validPhoneNumber: 'Enter valid phone number'
    },
    //messagingServiceSID: {
    //  required: 'Messaging Service SID is required',
    //}
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getPluginServices();
    this.getPluginConfiguration();

  }
  private getPluginServices() {
    this.loading = true;
    this.settingsService.getPluginServices()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.pluginServices = result.responseModel;
          this.pluginServices = this.pluginServices.filter(where => where.pluginName == 'Twilio')
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private async getApplicationSettings() {
    this.settingsService.getAllSettings()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, async () => {
          this.settingsResponseModel = result.responseModel;
          this.settingsResponseModel.forEach(x => {
            if (x.schSettingKey == Constants.SettingKeys.EmailSendStartTime)
              this.customStartTime = x.schSettingValue//moment(x.schSettingValue).format("hh:mm").toString();
            if (x.schSettingKey == Constants.SettingKeys.EmailSendEndTime)
              this.customEndTime = x.schSettingValue//moment(x.schSettingValue).format("hh:mm").toString();
          })
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getPluginConfiguration() {
    this.loading = true;
    this.settingsService.getPluginConfiguration(Constants.pluginSettingSMS)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, async () => {
          this.settingsPluginResponseModel = result.responseModel;
          this.setValuesToForm();
          await this.getApplicationSettings()
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  onSubmit() {
    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }

    if (!this.customStartTime) {
      this.showWarningMessage('Please select Start Time.');
      return false;
    }
    if (!this.customEndTime) {
      this.showWarningMessage('Please select End Time.');
      return false;
    }

    if (this.customStartTime != undefined && this.customEndTime != undefined) {
      var _startTime = moment(this.customStartTime, 'hh:mm A').format("HH:mm");
      var _endTime = moment(this.customEndTime, 'hh:mm A').format("HH:mm");

      if (_endTime == _startTime) {
        this.showWarningMessage('Start Time & End Time should not be equal');
        return;
      }
      else if (_endTime < _startTime) {
        this.showWarningMessage('End Time should be greater than Start Time');
        return;
      }
    }

    var applicationPluginRequestModel = this.getValuesFromForm();
    const manageApplicationGeneral = this.settingsService.updatePluginConfiguration(applicationPluginRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe(async (result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          await this.saveSettings();
          this.showSuccessMessage('Plugin SMS settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }
      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private async saveSettings() {

    var lstSettingsResponseModel = new Array<SettingsResponseModel>();

    var EmailSendStartTime = new SettingsResponseModel();
    EmailSendStartTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EmailSendStartTime);
    EmailSendStartTime.schSettingValue = moment(this.customStartTime, 'hh:mm:ss').format("HH:mm:ss");;
    EmailSendStartTime.dateTimeLastModified = new Date();
    EmailSendStartTime.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(EmailSendStartTime);

    var EmailSendEndTime = new SettingsResponseModel();
    EmailSendEndTime = this.settingsResponseModel.find(objWhere => objWhere.schSettingKey == Constants.SettingKeys.EmailSendEndTime);
    EmailSendEndTime.schSettingValue = moment(this.customEndTime, 'hh:mm:ss').format("HH:mm:ss");;
    EmailSendEndTime.dateTimeLastModified = new Date();
    EmailSendEndTime.modifiedByUserInfoId = this.loggedInUserId;
    lstSettingsResponseModel.push(EmailSendEndTime);

    const manageApplicationGeneral = this.settingsService.updateSettings(lstSettingsResponseModel);
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {

        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getValuesFromForm(): SettingsPluginRequestModel {
    const form = this.form;
    var settingPluginRequestModel = new SettingsPluginRequestModel();
    settingPluginRequestModel = this.settingsPluginResponseModel;
    settingPluginRequestModel.appPluginSettingsDisplayValue = form.get(this.formFields.service).value;
    this.pluginModel.twiMLFunctionURL = form.get(this.formFields.connectionName).value;
    this.pluginModel.userName = form.get(this.formFields.userName).value;
    this.pluginModel.password = form.get(this.formFields.password).value;
    this.pluginModel.accountSID = form.get(this.formFields.accountSID).value;
    this.pluginModel.authToken = form.get(this.formFields.apiId).value;
    this.pluginModel.fromNumber = form.get(this.formFields.phoneNumber).value;
    this.pluginModel.messagingServiceSID = form.get(this.formFields.messagingServiceSID).value;
    settingPluginRequestModel.appPluginSettingsValue = JSON.stringify(this.pluginModel);
    settingPluginRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    return settingPluginRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();
    if (!this.settingsPluginResponseModel) {
      return;
    }
    this.pluginModel = JSON.parse(this.settingsPluginResponseModel.appPluginSettingsValue);
    const form = this.form;
    form.get(this.formFields.service).setValue(this.settingsPluginResponseModel.appPluginSettingsDisplayValue);
    form.get(this.formFields.connectionName).setValue(this.pluginModel.twiMLFunctionURL);
    form.get(this.formFields.userName).setValue(this.pluginModel.userName);
    form.get(this.formFields.password).setValue(this.pluginModel.password);
    form.get(this.formFields.accountSID).setValue(this.pluginModel.accountSID);
    form.get(this.formFields.apiId).setValue(this.pluginModel.authToken);
    form.get(this.formFields.phoneNumber).setValue(this.pluginModel.fromNumber);
    form.get(this.formFields.messagingServiceSID).setValue(this.pluginModel.messagingServiceSID);
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});
    formGroup.addControl(this.formFields.service, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.connectionName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.userName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.password, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.accountSID, this.formBuilder.control('', { validators: [Validators.required], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.apiId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.messagingServiceSID, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    this.form = formGroup;
  }

  onClickTest() {
    this.isTestSMS = true;
    this.testNumber = '';
  }

  SendTestSMS() {
    if (!this.testNumber) {
      this.showWarningMessage('Please enter a number to send sms.');
      return;
    }
    this.loading = true;
    this.settingsService.sendTestSMS(this.testNumber)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<boolean>) => {
        this.processResult<boolean>(result, () => {
          if (result.responseModel) {
            this.showSuccessMessage('Test SMS sent successfully.');
          }
          else {
            this.showWarningMessage('Test SMS not sent.');
          }
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  CancelSMS() {
    this.isTestSMS = false;
    this.testNumber = '';
  }
}
