import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/shared/custom-validator';
import { Constants } from 'src/app/shared/constants';
import { SettingsService } from '../../../servicies/settings.service';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { ResultOfT, Result } from 'src/app/shared/models/response/result';
import { SettingsPluginRequestModel } from 'src/app/shared/models/request/settings/settings-plugin-request-model';
import { SettingsPluginResponseModel, PluginVoiceMailModel } from 'src/app/shared/models/response/settings/settings-plugin-response-model';
import { SettingsRequestModel } from 'src/app/shared/models/request/settings/settings-request-model';
import { WebStorageService } from 'src/app/shared/services/web-storage.service';
import { SettingsPluginServiceResponseModel } from 'src/app/shared/models/response/settings/settings-plugin-service-response-model';

@Component({
  selector: 'app-voice-mail',
  templateUrl: './voice-mail.component.html',
  styleUrls: ['./voice-mail.component.sass']
})
export class VoiceMailComponent extends BaseComponent implements OnInit, OnDestroy {
  private unsubscriber = new Subject<boolean>();
  private progress = new Subject<boolean>();
  settingsPluginResponseModel: SettingsPluginResponseModel;
  settingsPluginRequestModel: SettingsPluginRequestModel;
  pluginModel: PluginVoiceMailModel;
  loggedInUserId: number;
  pluginServices: SettingsPluginServiceResponseModel[];
  testNumber: string;
  isTestSMS: boolean;
  formFields = {
    service: 'service',
    //connectionName: 'connectionName',
    teamId: 'teamId',
    secret: 'secret',
    audioUrl: 'audioUrl',
    audioType: 'audioType',
    phoneNumber: 'phoneNumber',
    brandId: 'brandId',
    recordingId: 'recordingId'
  };

  validationMessages = {
    service: {
      required: 'Service is required',
    },
    //connectionName: {
    //  required: 'Connection name is required',
    //},
    teamId: {
      required: 'Team ID is required',
    },
    secret: {
      required: 'Secret is required',
    },
    audioUrl: {
      required: 'Audio URL is required',
      validURL: 'Enter valid audio URL'
    },
    audioType: {
      required: 'Audio type is required',
    },
    phoneNumber: {
      required: 'Phone number is required',
      validPhoneNumber: 'Enter valid phone number'
    },
    brandId: {
      required: 'Brand id is required',
    },
    recordingId: {
      required: 'Recording ID is required',
    },
  };

  constructor(private router: Router,
    private webStorageService: WebStorageService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    messageService: MessageService) {
    super(messageService);
  }

  ngOnDestroy(): void {
    if (this.unsubscriber) {
      this.unsubscriber.next();
      this.unsubscriber.complete();
    }
    if (this.progress) {
      this.progress.complete();
    }
  }

  async ngOnInit() {
    this.buildForm();
    const loggedInUser = this.webStorageService.getCurrentUserToken();
    this.loggedInUserId = loggedInUser.userId;
    this.getPluginServices();
    this.getPluginConfiguration();
  }

  private getPluginServices() {
    this.loading = true;

    this.settingsService.getPluginServices()
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          this.pluginServices = result.responseModel;
          this.pluginServices = this.pluginServices.filter(where => where.pluginName == 'Drop Cow Boy')
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }

  private getPluginConfiguration() {
    
    this.loading = true;

    this.settingsService.getPluginConfiguration(Constants.pluginSettingVoiceMail)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: ResultOfT<any>) => {
        this.processResult<any>(result, () => {
          
          this.settingsPluginResponseModel = result.responseModel;
          this.setValuesToForm();
        });
      }, (httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });

  }

  onSubmit() {
    this.CancelVoiceEmail();

    if (this.form.invalid) {
      this.markFormAsTouched();
      return;
    }
    
    var applicationPluginRequestModel = this.getValuesFromForm();
    const manageApplicationGeneral = this.settingsService.updatePluginConfiguration(applicationPluginRequestModel);

    this.loading = true;
    manageApplicationGeneral.pipe(takeUntil(this.unsubscriber), finalize(() => this.loading = false))
      .subscribe((result: Result) => {
        this.loading = false;
        if (result && result.isSuccess) {
          this.getPluginConfiguration();
          this.showSuccessMessage('Plugin Voice Mail settings is updated successfully');
        } else {
          this.showErrorMessage(result.message);
        }

      }, (httpResponseError) => {
        this.loading = false;
        this.showErrorMessage(httpResponseError.message);
      });

  }

  private getValuesFromForm(): SettingsPluginRequestModel {
    const form = this.form;
    var settingPluginRequestModel = new SettingsPluginRequestModel();
    
    settingPluginRequestModel = this.settingsPluginResponseModel;
    settingPluginRequestModel.appPluginSettingsDisplayValue = form.get(this.formFields.service).value;
    //this.pluginModel.service = form.get(this.formFields.service).value;
    //this.pluginModel.connectionName = form.get(this.formFields.connectionName).value;
    this.pluginModel.team_id = form.get(this.formFields.teamId).value;
    this.pluginModel.secret = form.get(this.formFields.secret).value;
    this.pluginModel.audio_url = form.get(this.formFields.audioUrl).value;
    this.pluginModel.audio_type = form.get(this.formFields.audioType).value;
    this.pluginModel.caller_id = form.get(this.formFields.phoneNumber).value;
    this.pluginModel.brand_id = form.get(this.formFields.brandId).value;
    this.pluginModel.recording_id = form.get(this.formFields.recordingId).value;

    settingPluginRequestModel.appPluginSettingsValue = JSON.stringify(this.pluginModel);
    settingPluginRequestModel.modifiedByUserInfoId = this.loggedInUserId;
    return settingPluginRequestModel;
  }

  private setValuesToForm() {
    this.form.reset();

    if (!this.settingsPluginResponseModel) {
      return;
    }

    this.pluginModel = JSON.parse(this.settingsPluginResponseModel.appPluginSettingsValue);

    const form = this.form;
    
    form.get(this.formFields.service).setValue(this.settingsPluginResponseModel.appPluginSettingsDisplayValue);
    //form.get(this.formFields.connectionName).setValue(this.pluginModel.connectionName);
    form.get(this.formFields.teamId).setValue(this.pluginModel.team_id);
    form.get(this.formFields.secret).setValue(this.pluginModel.secret);
    form.get(this.formFields.audioUrl).setValue(this.pluginModel.audio_url);
    form.get(this.formFields.audioType).setValue(this.pluginModel.audio_type);
    form.get(this.formFields.phoneNumber).setValue(this.pluginModel.caller_id);
    form.get(this.formFields.brandId).setValue(this.pluginModel.brand_id);
    form.get(this.formFields.recordingId).setValue(this.pluginModel.recording_id);
  }

  private buildForm() {
    const formGroup = this.formBuilder.group({});

    formGroup.addControl(this.formFields.service, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    //formGroup.addControl(this.formFields.connectionName, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.teamId, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.secret, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.audioUrl, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.URL], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.audioType, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.phoneNumber, this.formBuilder.control('', { validators: [Validators.required, CustomValidator.whitespace, CustomValidator.phoneNumber], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.brandId, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    formGroup.addControl(this.formFields.recordingId, this.formBuilder.control('', { validators: [], updateOn: Constants.formControlUpdateStrategy.blur }));
    this.form = formGroup;
  }


  onClickTest() {
    this.isTestSMS = true;
    this.testNumber = '';
  }

  CancelVoiceEmail() {
    this.isTestSMS = false;
    this.testNumber = '';
  }

  async SendVoicMail() {
    this.SendVoiceMailToNumber();
  }

  async SendVoiceMailToNumber() {

    await this.settingsService.SendVoiceMailToNumber(this.testNumber,  this.loggedInUserId)
      .pipe(takeUntil(this.unsubscriber), finalize(() => this.progress.next(false)))
      .toPromise().then((result) => {
        if (result.responseModel) {
          this.showSuccessMessage('Voice mail has been Sent to the phone number.');
        }
        else {
          this.showWarningMessage('Error occurred while sending voice mail.');
        }

      }).catch((httpResponseError) => {
        this.showErrorMessage(httpResponseError.message);
      });
  }


}
