export class DynamicCols {
  fieldNo: number;
  field: string;
  sortFieldName: string;
  header: string;
  visible: boolean;
  style: string;
  type: string;
}

export class DynamicColsWithFreez {
  fieldNo: number;
  field: string;
  sortFieldName: string;
  header: string;
  visible: boolean;
  style: string;
  type: string;
  freezCoulmn: boolean;
}
