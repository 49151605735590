<!-- Start Content -->
<div class="content-area">
  <div class="title-block">
    <h1>
      <a class="back-btn" (click)="onBack()">
        <i class="ic back"></i>
      </a>
      {{title}}
      <a class="edit-btn" (click)="onEdit()">
        <i class="edit-icon-new"></i>
      </a>
    </h1>
    <div class="re-scheduled">
      <button class="btn btn--primary" style="margin-bottom: 0;" (click)="ViewAvailability()">View Availability</button>
     </div>
  </div>
  <div class="page-scroll details-pages">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}">

      <h2>Personal Information</h2>
      <div class="details-pages__content">
        <span class="details-pages__content__block">
          <strong>Name</strong>
          <span class="value">
            {{therapistInfo.appUserLname}}, {{therapistInfo.appUserFname}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Email</strong>
          <span class="value">
            <a href="{{therapistInfo?.appUserEmail || '-'}}">{{therapistInfo?.appUserEmail || '-'}}</a>
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Phone Number</strong>
          <span class="value">
            <!-- {{therapistInfo?.appUserPhoneNumber || '-'}} -->
            {{therapistInfo?.appUserPhoneNumber | slice:0:3}}-{{therapistInfo?.appUserPhoneNumber | slice:3:6}}<span *ngIf="therapistInfo?.appUserPhoneNumber">-</span>{{therapistInfo?.appUserPhoneNumber | slice:6:10}}
          </span>
        </span>
        <span class="details-pages__content__block">
          <strong>Default Facility</strong>
          <span class="value">{{therapistInfo?.crmFacilityName || '-'}}</span>
        </span>
        <span class="details-pages__content__block">
          <strong>Status</strong>
          <span class="value">{{therapistInfo?.isActive ? 'Active' : 'Inactive'}}</span>
        </span>
        <h2 *ngIf="therapistInfo.therapistCertification?.length>0 || therapistInfo.therapistLicenseInfo?.length>0">Device &amp; License Information</h2>


        <span class="details-pages__content__block" *ngIf="therapistInfo.therapistCertification?.length>0">
          <strong>Device Information</strong>
          <span class="value value--table">


            <table class="table">
              <tr>
                <th><strong>Device Name</strong></th>
                <th><strong>Certificate</strong></th>
              </tr>

              <tr *ngFor="let certi of therapistInfo.therapistCertification">
                <td>{{certi.deviceName}}</td>
                <td>{{certi.certificateNumber}}</td>
              </tr>
            </table>

            <!-- <span class="value__row" *ngFor="let certi of therapistInfo.therapistCertification">
              <div>
                <strong class="value__row__day">Device Name</strong>
              </div>
              <div>
                <strong class="value__row__day">Certificate</strong>{{certi.certificateNumber}}
              </div>
            </span> -->
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="therapistInfo.therapistLicenseInfo?.length>0">
          <strong>License Information</strong>
          <span class="value value--table">
            <table class="table">
              <tr>
                <th><strong>License State</strong></th>
                <th><strong>License Number</strong></th>
                <th><strong>Expiry Date</strong></th>
              </tr>

              <tr *ngFor="let license of therapistInfo.therapistLicenseInfo">
                <td>{{license?.stateMaster.appStateName}}</td>
                <td>{{license.licenceNumber}}</td>
                <td>{{license.expiryDate | date:appDateFormat.dateFormat}}</td>
              </tr>
            </table>
          </span>
        </span>
        <!-- <span class="details-pages__content__block" *ngIf="false">
          <strong>Working Hours</strong>
          <span class="value value--table">
            <span class="value__row"
                  *ngFor="let hours of therapistAvail?.therapistAvailabilityResponseModel; let i=index">
              <div *ngIf="i == 0">
                <strong class="value__row__day">Monday</strong>{{hours.crmTherpistAvailFromTime}} to
                {{hours.crmTherpistAvailToTime}}
              </div>
              <div *ngIf="i == 1">
                <strong class="value__row__day">Tuesday</strong>{{hours.crmTherpistAvailFromTime}}
                to {{hours.crmTherpistAvailToTime}}
              </div>
              <div *ngIf="i == 2">
                <strong class="value__row__day">Wednesday</strong>{{hours.crmTherpistAvailFromTime}}
                to {{hours.crmTherpistAvailToTime}}
              </div>
              <div *ngIf="i == 3">
                <strong class="value__row__day">Thursday</strong>{{hours.crmTherpistAvailFromTime}}
                to {{hours.crmTherpistAvailToTime}}
              </div>
              <div *ngIf="i == 4">
                <strong class="value__row__day">Friday</strong>{{hours.crmTherpistAvailFromTime}} to
                {{hours.crmTherpistAvailToTime}}
              </div>
              <div *ngIf="i == 5">
                <strong class="value__row__day">Saturday</strong>{{hours.crmTherpistAvailFromTime}}
                to {{hours.crmTherpistAvailToTime}}
              </div>
            </span>
          </span>
        </span>
        <span class="details-pages__content__block" *ngIf="false">
          <strong>Break Hours</strong>
          <span class="value"
                *ngFor="let break of therapistAvail?.therapistAvailabilityResponseModel; let i = index">
            <div *ngIf="i==0">
              {{break.crmBreakStartTime}} to {{break.crmBreakEndTime}}
            </div>
          </span>
        </span> -->

      </div>
    </p-scrollPanel>
  </div>
</div>
<!-- End Content -->
